import React, { useState, Fragment, useRef } from 'react';
import { Row, Col, Button } from 'reactstrap';
/*import { faBan, faCalendar, faLock, faPencilAlt, faPlus, faSave, faTrash, faUnlock } from '@fortawesome/free-solid-svg-icons';*/
import { faDownload, faTrashAlt, faPencilAlt, faEdit, faTrash, faTruckLoading, faArrowAltCircleLeft, faPlus, faMinus, faSave, faBan, faCalendar, faUnlock, faLock, faCheckCircle, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isArrayWithLength, renderToastifyMsg } from '../../../utils';
import { IPropFundamentalsSpotlightWishr } from '../../../store/Weekly-ID-SHR/types';
import DatePicker, { getDay } from 'react-datepicker';
import moment from 'moment';
import { OrderList } from 'primereact/orderlist';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap'

type FundamentalsSpotlightProps = {
    FundamentalsSpotlightData: any,
    isAdmin: any,
    isLocked: boolean,
    EditLockforFundamentalsSpotlight: any,
    EnableAddforFundamentalsSpotlight: any,
    FundamentalsSpotlightDate: any,
    handleKeypress: any,
    MaxDate: any,
    MeetingDate: any,
    isFundamentalsSpotlightEditorAddEnabled: boolean,
    FundamentalsSpotlightvalueschange: any,
    EditFundamentalsSpotlightRows: any,
    DeleteFundamentalsSpotlightRow: any,
    SaveFundamentalsSpotlightData: any,
    cancelEditFundamentalsSpotlightRows: any,
    handleFundamentalsSpotlightDateChange: any,
    UpdateFundamentalsSpotlightOrder: any,
    CopyTableData: any
};

const FundamentalsSpotlight = React.memo<FundamentalsSpotlightProps>(({
    FundamentalsSpotlightData,
    isAdmin,
    isLocked,
    EditLockforFundamentalsSpotlight,
    EnableAddforFundamentalsSpotlight,
    FundamentalsSpotlightDate,
    MaxDate,
    MeetingDate,
    handleKeypress,
    isFundamentalsSpotlightEditorAddEnabled,
    FundamentalsSpotlightvalueschange,
    EditFundamentalsSpotlightRows,
    DeleteFundamentalsSpotlightRow,
    SaveFundamentalsSpotlightData,
    cancelEditFundamentalsSpotlightRows,
    handleFundamentalsSpotlightDateChange,
    UpdateFundamentalsSpotlightOrder,
    CopyTableData
}) => {

    const toastIdRef = useRef(null);

    const isThursday = (date) => {
        var day = new Date(moment(date).format('MM/DD/YYYY')).getDay();
        return day == 4;
    };
   
    const [presenterEdit, setpresenterEdit] = useState('')
    const [topicNameEdit, settopicNameEdit] = useState('')
    const [presentationLinkEdit, setpresentationLinkEdit] = useState('')    

    const callEditFundamentalsSpotlightRows = (e, row) => {
        setpresenterEdit(row.presenter)
        settopicNameEdit(row.topicName)
        setpresentationLinkEdit(row.presentationLink)
        EditFundamentalsSpotlightRows(e, row)
    }

    const callEnableAddforFundamentalsSpotlight = () => {
        setpresenterEdit('')
        settopicNameEdit('')
        setpresentationLinkEdit('')        
        EnableAddforFundamentalsSpotlight()
    }

    const callSaveFundamentalsSpotlightData = (e, row) => {                
        row.presenterEdit = presenterEdit
        row.topicNameEdit = topicNameEdit
        row.presentationLinkEdit = presentationLinkEdit
        SaveFundamentalsSpotlightData(e, row)
    }
    if (FundamentalsSpotlightData.filter(x => x.id === -1).length <= 0 && isAdmin == true) {
        FundamentalsSpotlightData.splice(0, 0, { id: -1 });
    } 

    if (FundamentalsSpotlightData.length <= 1) {
        FundamentalsSpotlightData.splice(1, 0, { id: -2 });
    }
    else {
        FundamentalsSpotlightData.filter((row) => { return row.id!=-2 })
    }

    const itemTemplate = (item) => {        
        if (!item.isEdit) {
            return (
                <React.Fragment>
                    {
                        (item.id == -1 || item.id == -2) ? (item.id == -1) ?
                            <tr>
                                <td style={{ width: '20%', height: '27px' }}><b>{(isFundamentalsSpotlightEditorAddEnabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Presenter</b></td>
                                <td style={{ width: '30%', height: '27px' }}><b>{(isFundamentalsSpotlightEditorAddEnabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Topic Name</b></td>
                                <td style={{ width: '40%', height: '27px' }}><b>{(isFundamentalsSpotlightEditorAddEnabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Link to presentation</b></td>
                                <td style={{ width: '10%', height: '27px', textAlign: 'center' }}><b>Actions</b></td>
                            </tr> :
                            <tr><td style={{ width: '100%', backgroundColor:'azure'}} className="clsnodatamessgefortable">No Data Available</td></tr>
                            :
                            <tr style={(isFundamentalsSpotlightEditorAddEnabled) ? { backgroundColor: 'azure',color:'black' } : {}}>
                                <td style={{ width: '20%' }}>{item.presenter}</td>
                                <td style={{ width: '30%' }}>{item.topicName}</td>
                                <td style={{ width: '40%' }}><a style={(isFundamentalsSpotlightEditorAddEnabled) ? { color: '#007ad9' } : {}} title="All links need to be accessible for all Identity viewers" href={item.presentationLink} target="_blank">{item.presentationLink}</a> </td>
                                <td style={{ width: '10%', textAlign: 'center' }}>
                                        <React.Fragment>
                                            <span onClick={e => callEditFundamentalsSpotlightRows(e, item)}>
                                                <FontAwesomeIcon title='Click to edit the Row' className="bluecoloricons Iconsstyles" icon={faPencilAlt} />
                                            </span> &nbsp;
                                                    <FontAwesomeIcon title='Click to Delete the Row' className="redcolorIcons Iconsstyles" icon={faTrash} onClick={e => DeleteFundamentalsSpotlightRow(e, item)} /> &nbsp;
                                            <span>
                                                <label htmlFor={'FundamentalsSpotlight' + item.id}><FontAwesomeIcon title='Click to Change the  Meeting Date' className="bluecoloricons Iconsstyles" icon={faCalendar} /></label>
                                                <DatePicker
                                                    id={"FundamentalsSpotlight" + item.id}
                                                    selected={FundamentalsSpotlightDate}
                                                    onChange={FundamentalsSpotlightDate => handleFundamentalsSpotlightDateChange(item, FundamentalsSpotlightDate)}
                                                    filterDate={isThursday}
                                                    maxDate={MaxDate}
                                                    className="datepickerCalendar"
                                                    popperPlacement="bottom-end"
                                                    popperModifiers={{
                                                        flip: {
                                                            enabled: false
                                                        },
                                                        offset: {
                                                            enabled: true,
                                                            offset: "55px, 5px"
                                                        },
                                                        preventOverflow: {
                                                            enabled: true,
                                                            escapeWithReference: false
                                                        }
                                                    }}
                                                />
                                            </span>                                               
                                        </React.Fragment>                                        
                                </td>
                            </tr>
                    }
                </React.Fragment>
            );
        }

        else {                
            return (
                <tr style={(isFundamentalsSpotlightEditorAddEnabled) ? { backgroundColor: 'azure',color:'black' } : {}}>
                    <td style={{ width: '20%' }}><textarea id="presenterEdit" className="form-control" onChange={e => setpresenterEdit(e.target.value)} value={presenterEdit} name="presenterEdit" rows={1} cols={50} /></td>
                    <td style={{ width: '30%' }}><textarea className="form-control" onChange={e => settopicNameEdit(e.target.value)} value={topicNameEdit} name="topicNameEdit" rows={1} cols={50} /></td>
                    <td style={{ width: '40%' }}><textarea className="form-control" onChange={e => setpresentationLinkEdit(e.target.value)} value={presentationLinkEdit} name="presentationLinkEdit" rows={1} cols={50} /></td>
                    <td style={{ width: '10%', textAlign: 'center' }}>
                        <FontAwesomeIcon title='Save Changes' className="bluecoloricons Iconsstyles" onClick={e => callSaveFundamentalsSpotlightData(e, item)} icon={faSave} /> &nbsp;
                        <FontAwesomeIcon title='Cancel Edit' className="Iconsstyles" icon={faBan} onClick={e => cancelEditFundamentalsSpotlightRows(e, item)} />
                    </td>
                </tr>
            );
        }
    };

    return (
        <React.Fragment>           
            <br />                        
            <Row style={{ paddingBottom: isAdmin ? "0px" : "14px" }}>
                <Col lg="8"><h5 className="headertext">Fundamentals Spotlight (10 minutes)</h5></Col>
                <Col lg="4">
                    {(isAdmin === true) ?
                    <div className="divaddnew">
                            <span style={{ display: isLocked ? 'inline' : 'none', color: 'red' }}>Locked By Admin </span>
                         <Button className='PIRAddbuttons' hidden={(FundamentalsSpotlightData.length > 1 ? false : true)} onClick={() => CopyTableData('funSpotLights')}> <FontAwesomeIcon icon={faCopy} /> &nbsp;Copy Table</Button>&nbsp;
                        <Button className='PIRAddbuttons' disabled={isLocked} onClick={callEnableAddforFundamentalsSpotlight}> <FontAwesomeIcon icon={faPlus} /> &nbsp;Add New Fundamentals Spotlight</Button>&nbsp;
                        <Button className='PIRAddbuttons' hidden={!isLocked} disabled={!isAdmin} onClick={EditLockforFundamentalsSpotlight}><FontAwesomeIcon icon={faLock} /></Button>
                        <Button className='PIRAddbuttons' hidden={isLocked} disabled={!isAdmin} onClick={EditLockforFundamentalsSpotlight}><FontAwesomeIcon icon={faUnlock} /></Button>
                        </div>
                        : <React.Fragment></React.Fragment>
                    }
                </Col>
            </Row>
            {(!isAdmin == true) ?
            <Row className="tblpadding">
                <Col lg="12">
                    <table id="tblPIR"  className="tblPIR tblborder">
                        <thead>
                            <tr>
                                <th className="thwidth280">{(isFundamentalsSpotlightEditorAddEnabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Presenter</th>
                                <th className="thwidth200">{(isFundamentalsSpotlightEditorAddEnabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Topic Name</th>
                                <th className="thwidth300">{(isFundamentalsSpotlightEditorAddEnabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Link to presentation</th>
                                {(isAdmin === true) ?
                                    <React.Fragment>
                                        <th className="thwidth200">{(isFundamentalsSpotlightEditorAddEnabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Order</th>
                                        <th className="thwidth200">Actions</th>
                                    </React.Fragment>
                                    : <React.Fragment></React.Fragment>
                                }                                                                
                            </tr>
                        </thead>
                        <tbody>
                            {!isArrayWithLength(FundamentalsSpotlightData)
                                ?
                                <React.Fragment>
                                    <tr>
                                        <td colSpan={7} className="clsnodatamessgefortable">
                                            No Data Available
                                        </td>
                                    </tr>
                                </React.Fragment>
                                : FundamentalsSpotlightData.map((row: IPropFundamentalsSpotlightWishr, index) => (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td>
                                                <div>{(row.isEdit) ? <textarea className="form-control" onChange={e => FundamentalsSpotlightvalueschange(e, row)} value={row.presenterEdit} name="presenterEdit" rows={1} cols={50} /> : row.presenter}</div>
                                            </td>
                                            <td>
                                                <div>{(row.isEdit) ? <textarea className="form-control" onChange={e => FundamentalsSpotlightvalueschange(e, row)} value={row.topicNameEdit} name="topicNameEdit" rows={1} cols={50} /> : row.topicName}</div>
                                            </td>
                                            <td className="tdwordbreak">
                                                <div>{(row.isEdit) ? <textarea className="form-control" onChange={e => FundamentalsSpotlightvalueschange(e, row)} value={row.presentationLinkEdit} name="presentationLinkEdit" rows={1} cols={50} /> : <a title="All links need to be accessible for all Identity viewers" href={row.presentationLink} target="_blank">{row.presentationLink}</a>}</div>

                                            </td>
                                            {
                                                (isAdmin === true) ?
                                                    <td>
                                                        <div>{(row.isEdit) ? <input type="number" min="0" max="32000" className="form-control" value={row.displayOrderEdit} name="displayOrderEdit" onKeyDown={handleKeypress} onChange={e => FundamentalsSpotlightvalueschange(e, row)} /> :
                                                            <div>{row.displayOrder}</div>
                                                        }</div>
                                                    </td>
                                                    : <React.Fragment></React.Fragment>
                                            }
                                            <td className="centericons" style={{ display: isAdmin ? 'block' : 'none' }}>
                                                {
                                                    (isAdmin === true) ? 
                                                    (!row.isEdit)
                                                        ?
                                                        <React.Fragment>
                                                            <span onClick={e => EditFundamentalsSpotlightRows(e, row)}>
                                                            <FontAwesomeIcon title='Click to edit the Row' className="bluecoloricons Iconsstyles" icon={faPencilAlt} /></span> &nbsp;
                                                            <FontAwesomeIcon title='Click to Delete the Row' className="redcolorIcons Iconsstyles" icon={faTrash} onClick={e => DeleteFundamentalsSpotlightRow(e, row)} /> &nbsp;                                                            
                                                             {(isAdmin === true) ?   
                                                                <span>
                                                                    <label htmlFor={'FundamentalsSpotlight' + index}><FontAwesomeIcon title='Click to Change the  Meeting Date' className="bluecoloricons Iconsstyles" icon={faCalendar} /></label>
                                                                    <DatePicker
                                                                        id={"FundamentalsSpotlight" + index}
                                                                        selected={FundamentalsSpotlightDate}
                                                                        onChange={FundamentalsSpotlightDate => handleFundamentalsSpotlightDateChange(row, FundamentalsSpotlightDate)}
                                                                        filterDate={isThursday}
                                                                        maxDate={MaxDate}
                                                                        className="datepickerCalendar"
                                                                        popperPlacement="bottom-end"
                                                                        popperModifiers={{
                                                                            flip: {
                                                                                enabled: false
                                                                            },
                                                                            offset: {
                                                                                enabled: true,
                                                                                offset: "55px, 5px"
                                                                            },
                                                                            preventOverflow: {
                                                                                enabled: true,
                                                                                escapeWithReference: false
                                                                            }
                                                                        }}
                                                                    />
                                                                </span>
                                                                : <React.Fragment></React.Fragment>
                                                             }
                                                    </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            <FontAwesomeIcon title='Save Changes' className="bluecoloricons Iconsstyles" onClick={e => SaveFundamentalsSpotlightData(e, row)} icon={faSave} /> &nbsp;
                                                            <FontAwesomeIcon title='Cancel Edit' className="Iconsstyles" icon={faBan} onClick={e => cancelEditFundamentalsSpotlightRows(e, row)} />
                                                            </React.Fragment>
                                                        :
                                                        <React.Fragment></React.Fragment>
                                                }
                                            </td>                                            
                                        </tr>
                                    </React.Fragment>
                                ))}
                        </tbody>
                    </table>
                </Col>
            </Row>
            :
            <Row>
                <div className={FundamentalsSpotlightData.length > 2 && !isFundamentalsSpotlightEditorAddEnabled ? "WeeklyIDSHRTableOuter" :  "WeeklyIDSHRTableOuter NoArrowOrderList"} >
                    <OrderList
                        value={FundamentalsSpotlightData}                        
                        id="WeeklyIDSHRTableid"
                        listStyle={{ height: "auto" }}
                        itemTemplate={itemTemplate}
                            onChange={(e) => { UpdateFundamentalsSpotlightOrder(e.value.filter((x) => { return x.id != -1 && x.id != -2  })) }}
                    ></OrderList>                     
                </div>
                </Row>
            }
            <br />
            <div className='fundSpotsDiv'></div>           
        </React.Fragment>
    );
});

FundamentalsSpotlight.displayName = 'Fundamentals Spotlight';

export default FundamentalsSpotlight;