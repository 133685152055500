
export interface IActionType {
    readonly RESET_STATE: string;
    readonly ADDED_VISITED_USER: string;
    readonly GET_REACHABILITYDATA_REQUEST: string;
    readonly GET_REACHABILITYDATA_SUCCESS: string;
    readonly GET_REACHABILITYDATA_FAIL: string;
    readonly GET_REACHABILITYERRORDATA_SUCCESS: string;
    readonly GET_REACHABILITYERRORDATA_FAIL: string;
    readonly SET_REACHABILITYMETRICS_SELECTEDDATE: string;
    readonly WEEKLY_DETAILS_DATA_RECEIVE: string;
    readonly WEEKLY_DETAILS_DATA_RECEIVE_FAIL: string;
    readonly CHANGE_SEARCHTEXT_REACHABILITY: string;
    readonly FILTER_REACHABILITYDATA_RED: string;
    readonly CHECK_HISTORY_REACHABILITY: string;
    readonly SORT_REACHABILITY_ERRORDATA: string;
    readonly SORT_REACHABILITY_DATA: string;
    readonly LOGIN_USERNAME_REACHABILITY: string;
    readonly SAVEBUTTON_DISABLE_REACHABILITY: string;
    readonly FILTER_WEEKDATA_REACHABILITY: string;
    readonly CONFIGVALUES_RECEIVE: string;
    readonly CONFIGVALUES_RECEIVE_FAIL: string;
    readonly ENABLE_EDITDATA_REACHABILITY: string;
    readonly CANCEL_EDITDATA_REACHABILITY: string;
    readonly KPI_HISTORY_REACHABILITY: string;
    readonly INPUT_VALUE_CHANGED_REACHABILITY: string;
    readonly SAVE_SUCCESSFULL_REACHABILITY: string;
    readonly CHECK_SAVE_RETAIN_PAGE: string;
    readonly SET_LOADING_FALSE: string;
    readonly IS_RETAIN_PAGE: string;
}
export type IReachabilityMetricsState = {
    readonly isLoading: boolean;
    readonly reachabilityData: IPropMsodsGridChart[];
    readonly reachabilityDataTemp: IPropMsodsGridChart[];
    readonly FilterreachabilityData: IPropMsodsGridChart[];
    readonly reachabilityErrorData: IPropReachabilityError[];
    readonly reachabilityErrorDataTemp: IPropReachabilityError[];
    readonly environmentkey: string;
    readonly selectedDate: Date;
    readonly disableSave: boolean;
    readonly serviceId: number;
    weekDetailsData: IWeekDetails;
    readonly searchText: string;
    readonly checkedFilterRedDailyData: boolean;
    readonly checkHistory: boolean;
    readonly Errorcolumn: string;
    readonly sortOrder: boolean;
    readonly IsEdit: string;
    readonly LoginuserId: string;
    readonly selectedWeekId: string;
    readonly Updated: boolean;
    readonly weekfilter: any;
    readonly Reachabilitycolumn: string;
    readonly reachabilitysortOrder: boolean;
    readonly ShowHistory1: boolean;
    readonly options: any;
    readonly ConfigValues: any;
    KPI_History: any;
    readonly chkRetainPage: boolean;
};

export type IPropMsodsGridChart = {
    readonly groupName: string;
    readonly serviceId: number;
    readonly parentReachabilityServiceId: string;
    readonly serviceName: string;
    readonly sslName: string;
    readonly kpi: string;
    readonly metricSource: boolean;
    readonly metricName: string;
    readonly environment: string;
    readonly targetName: string;
    readonly ml03Id: number;
    readonly week1: string;
    readonly week2: string;
    readonly week3: string;
    readonly week4: string;
    readonly week5: string;
    week1Edit: string;
    week2Edit: string;
    week3Edit: string;
    week4Edit: string;
    week5Edit: string;
    week1Sort: any;
    week2Sort: any;
    week3Sort: any;
    week4Sort: any;
    week5Sort: any;
    readonly week1Hiscount: number;
    readonly week2Hiscount: number;
    readonly week3Hiscount: number;
    readonly week4Hiscount: number;
    readonly week5Hiscount: number;
    readonly isUpdated: boolean;
    readonly comments: string;
    readonly spanKpi: number;
    readonly spanMetric: number;
    readonly isMetricRed: boolean;
    readonly kpiIndex: number;
    readonly history: IPropKpiHistory
    readonly displayType: boolean;
    readonly isChatForKpi: boolean;
    readonly isEditable: boolean;
    readonly minRange: string;
    readonly maxRange: string;
    readonly interval: string;
    readonly uptimeTarget: string;
    readonly serviceUptime: string;
    readonly uom: string;
    readonly envId: number;
    readonly qoSTarget: string;
    readonly qoS: string;
    readonly validationExpr: string;
    readonly entrySource: boolean;
    readonly perfPopupGrpId: number;
    readonly customerSla: string;
    readonly lsi: string;
    readonly config: number;
    readonly subEnv: number;
    readonly reachSort: number;
    rowIndex: number;
};

export type IPropKpiHistory = {
    readonly serviceId: number;
    readonly weekId: string;
    readonly oldVal: string;
    readonly metricGroupName: string;
    readonly metricName: string;
    readonly metricId: number;
    readonly kpiValue: string;
    readonly comments: string;
    readonly updatedDate: Date;
    readonly updatedBy: string;
    readonly uom: string;
    readonly versionId: number;
}

export type IPropReachabilityError = {
    readonly serviceId: number; 
    readonly serviceName: string;
    readonly parentReachabilityServiceId: string; 
    readonly environment: string; 
    readonly target: string; 
    totalErrors: any; 
    dnsError: any; 
    connectError: any; 
    sslError: any; 
    sendError: any; 
    receiveError: any; 
    httpError: any; 
    contentError: any; 
    rowIndex: number;
}

export interface IMonths {
    readonly name: string;
    readonly week: IWeeks[];

}
export interface IYear {
    readonly name: number;
    readonly month: IMonths[];

}
export interface IWeeks {
    readonly week1: string;
    readonly week1ID: number;
    readonly week2: string;
    readonly week2ID: number;
    readonly week3: string;
    readonly week3ID: number;
    readonly week4: string;
    readonly week4ID: number;
    readonly week5: string;
    readonly week5ID: number;

}

export interface IWeekDetails {
    readonly weekDet1: string;
    readonly weekDet2: string;
    readonly weekDet3: string;
    readonly weekDet4: string;
    readonly weekDet5: string;


}
export interface IWeekDetails {
    readonly selectedWeekId: number;
    readonly weeks: IWeeks;
    readonly weeksDetails: IWeekDetails;

}
const _namespace = 'ReachabilityMetrics';

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/reset`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    GET_REACHABILITYDATA_REQUEST: `${_namespace}/getreachabilitydatarequest`,
    GET_REACHABILITYDATA_SUCCESS: `${_namespace}/getreachabilitydatasuccess`,
    GET_REACHABILITYDATA_FAIL: `${_namespace}/getreachabilitydatafail`,
    GET_REACHABILITYERRORDATA_SUCCESS: `${_namespace}/getreachabilityerrordatasuccess`,
    GET_REACHABILITYERRORDATA_FAIL: `${_namespace}/getreachabilityerrordatafail`,
    SET_REACHABILITYMETRICS_SELECTEDDATE: `${_namespace}/setreachabilitymetricsselecteddate`,
    WEEKLY_DETAILS_DATA_RECEIVE: `${_namespace}/weeklydetailsdatareceive`,
    WEEKLY_DETAILS_DATA_RECEIVE_FAIL: `${_namespace}/weeklydetailsdatareceivefail`,
    CHANGE_SEARCHTEXT_REACHABILITY: `${_namespace}/changesearchtextreachability`,
    FILTER_REACHABILITYDATA_RED: `${_namespace}/filterreachabilitydatared`,
    CHECK_HISTORY_REACHABILITY: `${_namespace}/checkhistoryreachability`,
    SORT_REACHABILITY_ERRORDATA: `${_namespace}/sortreachabilityerrordata`,
    SORT_REACHABILITY_DATA: `${_namespace}/sortreachabilitydata`,
    LOGIN_USERNAME_REACHABILITY: `${_namespace}/loginusernamereachability`,
    SAVEBUTTON_DISABLE_REACHABILITY: `${_namespace}/savebuttondisablereachability`,
    FILTER_WEEKDATA_REACHABILITY: `${_namespace}/filterweekdatareachability`,
    CONFIGVALUES_RECEIVE: `${_namespace}/configvaluesreceive`,
    CONFIGVALUES_RECEIVE_FAIL: `${_namespace}/configvaluesreceivefail`,
    ENABLE_EDITDATA_REACHABILITY: `${_namespace}/enableeditdatareachability`,
    CANCEL_EDITDATA_REACHABILITY: `${_namespace}/canceleditdatareachability`,
    KPI_HISTORY_REACHABILITY: `${_namespace}/kpihistoryreachability`,
    INPUT_VALUE_CHANGED_REACHABILITY: `${_namespace}/inputvaluechangedreachability`,
    SAVE_SUCCESSFULL_REACHABILITY: `${_namespace}/savesuccessfulreachability`,
    CHECK_SAVE_RETAIN_PAGE: `${_namespace}/check_save_retain_page`,
    SET_LOADING_FALSE: `${_namespace}/setloadingfalse`,
    IS_RETAIN_PAGE: `${_namespace}/is_retain_page`,
});

