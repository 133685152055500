
export interface IActionType {
    readonly REQUEST: string;
    readonly RECEIVE: string;
    readonly RESET_STATE: string;
    readonly DROPDOWN_SELECT: string;
    readonly CHECK_SAMPLE_BOX: string;
    readonly VIEW_DROPDOWN_SELECT: string;
}
export type IViewDetails = {
    readonly ViewId: string;
    readonly ServiceID: string;
    ViewName: string;
    QueryForFixedCol: string;
    readonly QueryForVal: string;
    readonly totalsLabel: string;
    val1Label: string;
    readonly val2Label: string;
    readonly Source: string;
    readonly ColorSchemeID: string;
    readonly KustoDBName: string;
    readonly Server: string;
    readonly UpdatedBy: string;
    readonly UpdatedOn: string;
    readonly IsActive: string;
    readonly IsReachability: string;
    readonly CellColoringLogic: string;
    readonly NoOfDecimalId: string;
    readonly Val1Cap: string;
    readonly Val2Cap: string;
    readonly VolumeDisplayType: string;
    readonly IsMSERView: string;
    readonly MserViewUpdatedBy: string;
    readonly MserViewUpdatedDate: string;
    readonly IsMonthlyAvailability: string;
    readonly MonthlyAvailabilityUpdatedBy: string;
    readonly MonthlyAvailabilityUpdatedDate: string;
    readonly QueryForDrillDown: string;
    readonly ToEmailList: string;
    readonly CcEmailList: string;
    readonly AuthenticationType: string;
    readonly IsMSERPresentationView: string;
    readonly MserPresentationViewUpdatedBy: string;
    readonly MserPresentationViewUpdatedDate: string;
    readonly IsMonthlyAvailability_Qos: string;
    readonly MonthlyAvailabilityQosUpdatedBy: string;
    readonly MonthlyAvailabilityQosUpdatedDate: string;
    RenameViewLabel: string;
};

export type IFilterServiceHealthState = {
    readonly isLoading: boolean;
    readonly checkboxValue: boolean;
    readonly selectedDropdownOption: IDropdownOption;
  //  readonly selectedViewDropdownOption: IViewDropdownOption;
    readonly dailyViewData: IDropdownOption[];
};

export type IViewDailyData = {
    readonly viewId: number;
    readonly viewName: string;
};

export type IDropdownOption = {
     value: number;
     label: string;
};
export type IViewDropdownOption = {
    readonly viewId: number;
    readonly viewName: string;
};

const _namespace = 'filterServiceHealth';

export const ActionType = Object.freeze<IActionType>({
    REQUEST: `${_namespace}/fetch`,
    RECEIVE: `${_namespace}/receive`,
    RESET_STATE: `${_namespace}/resetState`,
    DROPDOWN_SELECT: `${_namespace}/dropdownSelect`,
    VIEW_DROPDOWN_SELECT: `${_namespace}/viewDropdownSelect`,
    CHECK_SAMPLE_BOX: `${_namespace}/checkSampleBox`,
});