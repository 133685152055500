
export interface IActionType {
    readonly RESET_STATE: string;
    readonly ADDED_VISITED_USER: string;
    readonly SET_LOADING_TRUE: string;
    readonly SET_LOADING_FALSE: string;
    readonly GET_USERSCENARIOCOMPLETION_REQUEST: string;
    readonly GET_USERSCENARIOCOMPLETION_SUCCESS: string;
    readonly GET_USERSCENARIOCOMPLETION_FAIL: string;
    readonly USERSCENARIO_ENDDATE_CHANGE: string;
    readonly USERSCENARIO_VALUE_CHANGE: string;
    readonly SET_USERSCENARIODATE: string;
    readonly GET_USERSCENARIO_SUCCESS: string;
    readonly GET_USERSCENARIO_FAIL: string;

}
export type IUserScenarioCompletionState = {
    readonly isLoading: boolean;
    readonly scenarioCompletionData: [];
    readonly selectedScenario: any;
    readonly endDate: Date;
    readonly maxDate: Date;
    readonly userScenario: UserScenarioType[];
    readonly scenarioDescription: string;
    readonly scenario: string;
    readonly tableHeader: [];
    readonly originalHeader: [];
    readonly countVal: number;
};

export type UserScenarioType = {
    readonly iD: number
    readonly userScenarioName: string;
    readonly userScenarioDescription; string;
    readonly overallScenarioUrl: string
    readonly tenantLevelUrl: string;
};
const _namespace = 'UserScenarioCompletion';

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/reset`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    SET_LOADING_TRUE: `${_namespace}/loadingtrue`,
    SET_LOADING_FALSE: `${_namespace}/loadingfalse`,
    USERSCENARIO_ENDDATE_CHANGE: `${_namespace}/UserScenarioenddatechange`,
    USERSCENARIO_VALUE_CHANGE: `${_namespace}/UserScenariovaluechange`,
    SET_USERSCENARIODATE: `${_namespace}/UserScenariosetdate`,
    GET_USERSCENARIOCOMPLETION_REQUEST: `${_namespace}/getUserScenarioCompletionrequest`,
    GET_USERSCENARIOCOMPLETION_SUCCESS: `${_namespace}/getUserScenarioCompletionsuccess`,
    GET_USERSCENARIOCOMPLETION_FAIL: `${_namespace}/getUserScenarioCompletionfail`,
    GET_USERSCENARIO_SUCCESS: `${_namespace}/getUserScenariosuccess`,
    GET_USERSCENARIO_FAIL: `${_namespace}/getUserScenariofail`
});

