import { BaseService } from './base.service';
import { IPropPublicServiceAnnouncementSecuritySHR, ISecurityAdminLock, IPropSuccessStoriesWishr } from '../store/Security-SHR';
/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class SecuritySHRService extends BaseService {
    private static _serviceGroupService: SecuritySHRService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): SecuritySHRService {
        return (this._serviceGroupService || (this._serviceGroupService = new this('SecuritySHR')));
    }
    public async GetSecurityAdminLock(): Promise<ISecurityAdminLock[]> {
        const { data } = await this.$http.get<ISecurityAdminLock[]>(`GetSecurityAdminLock`);
        return data;
    }
    public async PostSecurityAdminLock(lockType: number, lockedWeek: string, lockValue: number, userName: string): Promise<ISecurityAdminLock[]> {
        const { data } = await this.$http.get<ISecurityAdminLock[]>(`PostSecurityAdminLock?lockType=${lockType}&lockedWeek=${lockedWeek}&lockValue=${lockValue}&userName=${userName}`);
        return data;
    }
    //Public Service Announcement
    public async GetSecurityPubServiceAccData(Meetingdate: string): Promise<IPropPublicServiceAnnouncementSecuritySHR[]> {
        const { data } = await this.$http.get<IPropPublicServiceAnnouncementSecuritySHR[]>(`GetSecurityPubServiceAccData?selectedDate=${Meetingdate}`);
        return data;
    }
    public async DeletePubServiceAccSecuritySHRData(id: number, userName: string): Promise<number> {
        const { data } = await this.$http.get<number>(`DeleteSecurityPubServiceAccData?id=${id}&userName=${userName}`);
        return data;
    }
    public async saveSecurityPubServiceAccMeetingDate(id, KPIDate, userName): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateDateSecurityPubServiceAcc?id=${id}&dateKpi=${KPIDate}&UserName=${userName}`);
        return data;
    }
    public async AddorUpdateSecurityPubServiceAccData(row: IPropPublicServiceAnnouncementSecuritySHR): Promise<number> {
        const metric = {
            Id: row.id,
            Presenter: row.presenterEdit === undefined || null ? row.presenter : row.presenterEdit,
            TopicName: row.topicNameEdit === undefined || null ? row.topicName : row.topicNameEdit,
            TimeNeeded: 0,
            PresentationLink: row.presentationLinkEdit === undefined || null ? row.presentationLink : row.presentationLinkEdit,
            IsTopicApproved: row.isTopicApprovedEdit === undefined || null ? row.isTopicApproved : row.isTopicApprovedEdit,
            KpiDate: row.kpiDate,
            DisplayOrder: row.displayOrderEdit === null ? null : parseInt(row.displayOrderEdit.toString()),
            UserName: row.userName
        };
        if (row.id === 0) {
            const { data } = await this.$http.post<number>("AddSecurityPubServiceAccData", metric);
            return data;
        }
        else {
            const { data } = await this.$http.post<number>("UpdateSecurityPubServiceAccData", metric);
            return data
        }
    }
    //SuccessStories
    public async GetSecuritySuccessStorieData(Meetingdate: string): Promise<IPropSuccessStoriesWishr[]> {
        const { data } = await this.$http.get<IPropSuccessStoriesWishr[]>(`GetSecuritySuccessStorieData?selectedDate=${Meetingdate}`);
        return data;
    }
    public async DeleteSecuritySuccessStorieData(id: number, userName: string): Promise<number> {
        const { data } = await this.$http.get<number>(`DeleteSecuritySuccessStorieData?id=${id}&userName=${userName}`);
        return data;
    }
    public async saveSecuritySuccessStoriesMeetingDate(id, KPIDate, userName): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateDateForSecuritySuccessStories?id=${id}&dateKpi=${KPIDate}&UserName=${userName}`);
        return data;
    }
    public async AddorUpdateSecuritySuccessStoriesData(row: IPropSuccessStoriesWishr): Promise<number> {
        const metric = {
            Id: row.id,
            Presenter: row.presenterEdit === undefined || null ? row.presenter : row.presenterEdit,
            TopicName: row.topicNameEdit === undefined || null ? row.topicName : row.topicNameEdit,
            TimeNeeded: 0,
            PresentationLink: row.presentationLinkEdit === undefined || null ? row.presentationLink : row.presentationLinkEdit,
            IsTopicApproved: row.isTopicApprovedEdit === undefined || null ? row.isTopicApproved : row.isTopicApprovedEdit,
            KpiDate: row.kpiDate,
            DisplayOrder: row.displayOrderEdit === null ? null : parseInt(row.displayOrderEdit.toString()),
            UserName: row.userName
        };
        if (row.id === 0) {
            const { data } = await this.$http.post<number>("AddSecuritySuccessStoriesData", metric);
            return data;
        }
        else {
            const { data } = await this.$http.post<number>("UpdateSecuritySuccessStoriesData", metric);
            return data
        }
    }
}

export const SecuritySHRAPI = SecuritySHRService.Instance;
