import { BaseService } from './base.service';
import { IServiceGroup, IPropCalender } from '../store/home-tiles';
import { IPropServiceToolsConfig, IPropServiceGroupToolsConfig } from '../store/Configuration';

/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class ServiceGroupService extends BaseService {
    private static _serviceGroupService: ServiceGroupService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): ServiceGroupService {
        return (this._serviceGroupService || (this._serviceGroupService = new this('ServiceGroup')));
    }

    public async getServiceGroupAsyn(startDailyIndex: number): Promise<IServiceGroup[]> {
        const { data } = await this.$http.get<IServiceGroup[]>(`ServiceGroup?optionalHM=` + 0);
        return data;
    }
    public async GetDateForRefreshedData(serviceid: number, viewId: string): Promise<IPropCalender[]> {
        const { data } = await this.$http.get<IPropCalender[]>(`Get_Date_For_RefreshedData?serviceId=${serviceid}&viewId=${viewId}`);
        return data;
    }
    public async GetServiceGroups(): Promise<IServiceGroup[]> {
        const { data } = await this.$http.get<IServiceGroup[]>(`GetServiceGroups`);
        return data;
    }
    public async GetServiceGroupsWeeklySelfServe(): Promise<IServiceGroup[]> {
        const { data } = await this.$http.get<IServiceGroup[]>(`GetServiceGroupsWeeklySelfServe`);
        return data;
    }
    public async GetToolsConfigurationHeatMapServices(): Promise<IPropServiceToolsConfig[]> {
        const { data } = await this.$http.get<IPropServiceToolsConfig[]>(`GetToolsConfigurationHeatMapServices`);
        return data;
    }
    public async GetToolsConfigurationHeatMapServiceGroups(): Promise<IPropServiceGroupToolsConfig[]> {
        const { data } = await this.$http.get<IPropServiceGroupToolsConfig[]>(`GetToolsConfigurationHeatMapServiceGroups`);
        return data;
    }
}

export const ServiceGroupApi = ServiceGroupService.Instance;
