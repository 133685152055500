import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { RouteComponentProps } from 'react-router-dom';
import { actionCreators, reducer } from '../../store/Dublin-SHR';
import { DublinSHRDataTable } from '../Dublin-SHR/child-components';
import { isNullOrUndefined, isArrayWithLength, renderToastifyMsg } from '../../utils';
import { ToastId, toast } from 'react-toastify';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { DublinSHRAPI } from '../../api';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap'
import { ActionItemsReview, PostIncidentReviews, SpecialTopics } from './child-components';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { DeprecatedFunctionalityBanner } from '../../components';

type DublinSHRDataProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly username: string }
    & { readonly isAdmin: boolean }
    & RouteComponentProps<{}>;

const DublinSHRData: React.FC<DublinSHRDataProps> = ({
    isLoading,
    MeetingDate,
    DefaultDate,
    GetMeetingDate,
    handleMeetingDateChange,
    setloadingtrue,
    setloadingfalse,
    isAuthenticated,
    isAdmin,
    username,
    MaxDate,
    addUserVisit,
    GetDublinAdminLock,
    GetWeeklyDublinData,
    SpecialTopicsData,
    ActionItemsReviewData,
    accordiontoggle,
    setaccordiontoggledata,
    specialaccordiontoggle,
    setspecialaccordiontoggledata,
    piraccordiontoggle,
    setpiraccordiontoggledata,
    UpdateSpecialTopicsDataOrder,
    UpdatePIRdataOrder,
    EditPIRRow,
    CancelEditPIRRow,
    OnPIRChangevalues,
    isPIREditenabled,
    EnableAddNewRowforPIR,
    EditSpecialTopicRow,
    CancelEditSpecialTopicRow,
    isSpecialTopicEditorAddEnabled,
    OnPIRSpecialTopicChangevalues,
    EnableAddNewRowforSpecialTopics,
    isLockedPIR,
    EditLockforPIR,
    isLockedTopic,
    EditLockforSpecificTopic,
    isSpecialTopicLockDisabledOnEdit,
    isPIRLockDisabledOnEdit,
    PIRdata
}) => {
    useEffect(() => {
        if (isAuthenticated)
            addUserVisit(0, 'DublinSHR.tsx_v2');
    }, [isAuthenticated]);
    useEffect(() => {
        GetDublinAdminLock();
    }, [GetDublinAdminLock]);
    useEffect(() => {
        if (MeetingDate === null) {
            GetMeetingDate();
        }
        else {
            GetWeeklyDublinData(moment(MeetingDate).format('MM/DD/YYYY'), 4);
        }

    }, [GetMeetingDate, MeetingDate]);
    const toastIdRef = useRef<ToastId>('');

    const validateLink = (link) => {
        var valid = false;
        var regex = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
        var m;
        m = regex.exec(link);
        if (m !== null) {
            valid = true;
        }
        return valid;
    };

    //**************************************************************************** function Block for  PIR ****************************************************************************//
    //================ function to enable the Edit for selected PIR Row =====================//
    const EditPIRRows = (e, value) => {
        let val: number = value.id;
        EditPIRRow(val);
    }
    //==================function to Cancel the Edit for PIR Row ==================================//
    const CancelEditPIR = (e, value) => {
        if (window.confirm("Are you sure you want to Cancel this Edit?")) {
            let val: number = value.id;
            CancelEditPIRRow(val);
        }

    }
    //=======================function on textbox change while Editing arow in PIR ==============//
    const OnPIRTextboxchange = (e, value) => {
        let val: number = value.id;
        OnPIRChangevalues(e, val);
    }
    //=============function to Save for Edit/Add & Refresh  the  values for PIR====================//
    const SavePostIncidentData = (e, row, Isrefresh) => {
        row.userName = username;
        var existdisplayorder = false;
        // Logic for Duplicate DisplayOrder
        if (isAdmin === true && (row.id == 0)) {
            let filteredData = PIRdata.filter(function (x) { return x.id !== -1; });
            let highestDisplayOrder = Math.max(...filteredData.map(o => o.displayOrderEdit));
            PIRdata.find(x => x.id == 0).displayOrderEdit = highestDisplayOrder + 1;
        }
        if (Isrefresh === 'Refreshed') {
            row.pirEdit = row.pir;
            row.occuredRingEdit = row.occuredRing;
            row.displayOrderEdit = row.displayOrder;
            row.presenterEdit = row.presenter;
            row.displayOrderEdit = row.displayOrder;
            setloadingtrue();
            DublinSHRAPI.AddDublinPirData(row)
                .then((result: number) => {
                    if (result === 2 && row.id === 0) {
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Error in adding to agenda, PIR" + row.pir + " already added to agenda",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                        GetWeeklyDublinData(moment(MeetingDate).format('MM/DD/YYYY'), 1);
                        GetWeeklyDublinData(moment(MeetingDate).format('MM/DD/YYYY'), 4);
                    }
                    else if (result != -1) {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg('PIR Data for service ' + row.serviceName + ' ' + Isrefresh + '  successfully',
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                        GetWeeklyDublinData(moment(MeetingDate).format('MM/DD/YYYY'), 1);
                        GetWeeklyDublinData(moment(MeetingDate).format('MM/DD/YYYY'), 4);
                    }
                    else {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Entered PIR " + row.pirEdit + " does not exist in IcM kusto DB",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while updating",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
        else {
            if (isNullOrUndefined(row.presenterEdit)) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg('Please enter the Presenter Details',
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );

            }
            else if (isNullOrUndefined(row.occuredRingEdit)) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg('Please enter the Occured Ring',
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );

            }
            else if (isNullOrUndefined(row.pirEdit)) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg('Please enter the PIR number',
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );

            }
            else if (isAdmin === true && isNullOrUndefined(row.displayOrderEdit)) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg('Please enter the Display Order',
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );

            }
            else if (isAdmin === true && parseInt(row.displayOrderEdit) === 0) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg('Display order starts from 1',
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );

            }
            else if (isAdmin === true && parseInt(row.displayOrderEdit) > 32000) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg('Display order Exceeding the Limit',
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );

            }
            else {
                if (row.id === 0) {
                    row.kpiDate = moment(MeetingDate).format('MM/DD/YYYY');
                }
                setloadingtrue();
                DublinSHRAPI.AddDublinPirData(row)
                    .then((result: number) => {
                        if (result === 2 && row.id === 0) {
                            toastIdRef.current = toast.error(
                                renderToastifyMsg("Error in adding to agenda, PIR" + row.pir + " already added to agenda",
                                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                            );
                            GetWeeklyDublinData(moment(MeetingDate).format('MM/DD/YYYY'), 1);
                            GetWeeklyDublinData(moment(MeetingDate).format('MM/DD/YYYY'), 4);
                        }
                        else if (result != -1) {
                            toastIdRef.current = toast.success(
                                renderToastifyMsg('PIR Data for service ' + row.serviceName + ' ' + Isrefresh + '  successfully',
                                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                            );
                            GetWeeklyDublinData(moment(MeetingDate).format('MM/DD/YYYY'), 1);
                            GetWeeklyDublinData(moment(MeetingDate).format('MM/DD/YYYY'), 4);
                        }
                        else {
                            setloadingfalse();
                            toastIdRef.current = toast.error(
                                renderToastifyMsg("Entered PIR " + row.pirEdit + " does not exist in IcM kusto DB",
                                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                            );
                        }
                    }).catch(error => {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Error while updating",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    });
            }
        }
    }
    //================= function to modify the Meeting Date for PIR =======================//
    const handlePIRDateChange = (row, KPIDate) => {
        setloadingtrue();
        DublinSHRAPI.savePIRMeetingDate(row.id, moment(KPIDate).format('MM/DD/YYYY'), username)
            .then((result: number) => {
                if (result === -2) {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error in PIR!! PIR already present in Selected Meeting Date.",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
                else if (result === -3) {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error in Display Order!! Display Order already present in Selected Meeting Date. Change the Display Order and then Perform the Meeting Date Change Operation",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 }
                    );
                }
                else if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg('PIR Data for service ' + row.serviceName + ' Updated successfully',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    GetWeeklyDublinData(moment(MeetingDate).format('MM/DD/YYYY'), 1);
                    GetWeeklyDublinData(moment(MeetingDate).format('MM/DD/YYYY'), 4);
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Entered PIR " + row.pirEdit + " does not exist in IcM kusto DB",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });

    }
    //================= function to delete the selected Row for PIR =======================//
    const DeletePIRData = (e, row) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            let pirId = null;
            setloadingtrue();
            DublinSHRAPI.DeleteDublinPirData(row.id, username)
                .then((result: number) => {
                    if (result > 0) {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg("PIR Data for service " + row.serviceName + " Deleted successfully",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                        GetWeeklyDublinData(moment(MeetingDate).format('MM/DD/YYYY'), 1);
                        GetWeeklyDublinData(moment(MeetingDate).format('MM/DD/YYYY'), 4);
                    }

                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while updating",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
    }
    //=============== function to enable Add new row for PIR ====================================//
    const EnableAddforPIRData = () => {
        EnableAddNewRowforPIR();
    }

    const EditLockforPIRData = () => {
        if (isAdmin) {
            EditLockforPIR();
        }
    }

    //**************************************************************************** function Block for Special Topics ****************************************************************************//
    const EditLockforSpecificTopicData = () => {
        if (isAdmin) {
            EditLockforSpecificTopic();
        }
    }
    //================ function to enable the Edit for Special Topics Row =====================//
    const EditSpecialTopicsRows = (e, value) => {
        let val: number = value.id;
        EditSpecialTopicRow(val);
    }
    //==================function to Cancel the Edit for Special Topics Row ==================================//
    const cancelEditSpecialTopicRows = (e, value) => {
        if (window.confirm("Are you sure you want to Cancel this Edit?")) {
            let val: number = value.id;
            CancelEditSpecialTopicRow();
        }

    }
    //=======================function on textbox change while Editing arow in Special Topics==============//
    const Specialtopicvalueschange = (e, value) => {
        let val: number = value.id;
        OnPIRSpecialTopicChangevalues(e, val);
    }
    //================= function to delete the selected Row for Special Topics =======================//
    const DeleteSpecialTopicsRow = (e, row) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            setloadingtrue();
            DublinSHRAPI.DeleteDublinSpecialTopicsData(row.id, username)
                .then((result: number) => {
                    if (result > 0) {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg("Special Topics Data for presenter " + row.presenter + " Deleted successfully",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                        GetWeeklyDublinData(moment(MeetingDate).format('MM/DD/YYYY'), 2);
                    }

                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while deleting",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
    }
    //=============function to Save for Edit/Add   the  values for Commos only PIR====================//
    const SaveSpecialTopicData = (e, row) => {
        row.userName = username;
        var existdisplayorder = false;
        // Logic for Duplicate DisplayOrder
        if (isAdmin === true) {
            SpecialTopicsData.map((obj) => {
                if (obj.id !== row.id) {
                    if (obj.displayOrder === parseInt(row.displayOrderEdit)) {
                        existdisplayorder = true;
                    }
                }
            })
        }
        row.kpiDate = moment(MeetingDate).format('MM/DD/YYYY');
        if (isNullOrUndefined(row.presenterEdit) || !/\S/.test(row.presenterEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please enter the Presenter Details',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );

        }
        else if (isNullOrUndefined(row.topicNameEdit) || !/\S/.test(row.topicNameEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please enter the Topic Name',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );

        }
        else if (isNullOrUndefined(row.presentationLinkEdit) || !/\S/.test(row.presentationLinkEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please enter the Presentation Link',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );

        }
        else if (!validateLink(row.presentationLinkEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg("Please enter the valid Presentation Link",
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        else {
            if (row.presentationLinkEdit.toLowerCase().indexOf("http") === -1) {
                row.presentationLinkEdit = "https://" + row.presentationLinkEdit;
            }
            if (isNullOrUndefined(row.displayOrderEdit)) {
                row.displayOrderEdit = null;
            }
            else {
                row.displayOrderEdit = parseInt(row.displayOrderEdit);
            }
            setloadingtrue();
            let message = (row.id === 0) ? "special Topics Data for Presenter " + row.presenterEdit + " Saved successfully" : "Special Topics Data updated successfully";
            DublinSHRAPI.AddorupdateDublinSpecialTopicsData(row)
                .then((result: number) => {
                    if (result != -1) {
                        GetWeeklyDublinData(moment(MeetingDate).format('MM/DD/YYYY'), 2);
                        toastIdRef.current = toast.success(
                            renderToastifyMsg(message,
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                    else {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Error while adding the Special Topics Data",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while adding the Special Topics Data",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
    }
    //================= function to modify the Meeting Date for Special Topics =======================//
    const handleSpecialTopicsDateChange = (row, KPIDate) => {
        setloadingtrue();
        DublinSHRAPI.saveSpecialTopicsMeetingDate(row.id, moment(KPIDate).format('MM/DD/YYYY'), username)
            .then((result: number) => {
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg("special Topics Data for Presenter " + row.presenter + " Updated successfully",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    GetWeeklyDublinData(moment(MeetingDate).format('MM/DD/YYYY'), 2);
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while updating the Special Topics Data",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });

    }
    //=============== function to enable Add new row for PIR ====================================//
    const EnableAddNewforSpecialTopicsRow = () => {
        EnableAddNewRowforSpecialTopics();
    }
    //**************************************************************************** End Block for Special Topics ****************************************************************************//


    //============== function to accept only numbers for textbox keydown ==============================//
    const handleKeypress = (e) => {
        var isModifierkeyPressed = (e.metaKey || e.ctrlKey || e.shiftKey);
        var isCursorMoveOrDeleteAction = ([46, 8, 37, 38, 39, 40, 9].indexOf(e.keyCode) != -1);
        var isNumKeyPressed = (e.keyCode >= 48 && e.keyCode <= 58) || (e.keyCode >= 96 && e.keyCode <= 105);
        var vKey = 86, cKey = 67, aKey = 65;
        switch (true) {
            case isCursorMoveOrDeleteAction:
            case isModifierkeyPressed == false && isNumKeyPressed:
            case (e.metaKey || e.ctrlKey) && ([vKey, cKey, aKey].indexOf(e.keyCode) != -1):
                break;
            default:
                e.preventDefault();
        }
    }

    const createFunSpotLightTable = (tableName) => {
        const IDSHRDiv = document.querySelector("div.IDWSHRDiv") // Find the scoreboard div in our html
        while (IDSHRDiv.firstChild) IDSHRDiv.removeChild(IDSHRDiv.firstChild) // Remove all children from IDSHRs div (if any)
        let tableHeaders = [];

        if (tableName === 'actionItems') {
            tableHeaders = ["Presenter", "Task ID", "Title"];
        }
        else if (tableName === 'postIncidents') {
            tableHeaders = ["Presenter (must be GEM/EM)", "Owning Service", "Services Impacted", "Incident Id", "PIR Title", "Occurred in what Ring?", "TTD", "TTE", "TTM", "TTN", "Document Link", "PIR Number"];
        }
        else if (tableName === 'specialTopics') {
            tableHeaders = ["Presenter", "Topic Name", "Link to presentation"];
        }

        let IDSHRTable = document.createElement('table'); // Create the table itself
        IDSHRTable.setAttribute('id', 'IDSHRMainTable');
        IDSHRTable.className = 'IDSHRTable';
        let IDSHRTableHead = document.createElement('thead'); // Creates the table header group element
        IDSHRTableHead.className = 'IDSHRTable';
        let IDSHRTableHeaderRow = document.createElement('tr'); // Creates the row that will contain the headers
        IDSHRTableHeaderRow.className = 'IDSHRTableHeaderRow';
        // Will iterate over all the strings in the tableHeader array and will append the header cells to the table header row
        tableHeaders.forEach(header => {
            let scoreHeader = document.createElement('th') // Creates the current header cell during a specific iteration
            scoreHeader.innerText = header
            IDSHRTableHeaderRow.append(scoreHeader) // Appends the current header cell to the header row
        });
        IDSHRTableHead.append(IDSHRTableHeaderRow); // Appends the header row to the table header group element
        IDSHRTable.append(IDSHRTableHead);
        let IDSHRTableBody = document.createElement('tbody'); // Creates the table body group element
        IDSHRTableBody.className = "IDSHRTable-Body";
        IDSHRTable.append(IDSHRTableBody); // Appends the table body group element to the table
        IDSHRDiv.append(IDSHRTable); // Appends the table to the IDSHR div
    }
    // The function below will accept a single score and its index to create the global ranking
    const appendFunSpotLight = (singleWSHR, tableName) => {
        if (singleWSHR.id !== -1 && singleWSHR.id !== -2) {
            const IDSHRTable = document.querySelector('.IDSHRTable');// Find the table we created
            let IDSHRTableBodyRow = document.createElement('tr'); // Create the current table row
            IDSHRTableBodyRow.className = 'IDSHRTableBodyRow';

            if (tableName === 'actionItems') {
                // create the 3 column cells that will be appended to the current table row
                let presenterData = document.createElement('td');
                presenterData.innerText = singleWSHR.presenter;

                let createTaskIdAnchor = document.createElement('a');
                createTaskIdAnchor.href = "https://identitydivision.visualstudio.com/DefaultCollection/Engineering/_workitems/edit/" + singleWSHR.id;
                createTaskIdAnchor.innerText = singleWSHR.id;

                let topicId = document.createElement('td');
                topicId.appendChild(createTaskIdAnchor);

                let topicNameData = document.createElement('td');
                topicNameData.innerText = singleWSHR.topicName;

                IDSHRTableBodyRow.append(presenterData, topicId, topicNameData); // Append all 3 cells to the table row
            }
            else if (tableName === 'postIncidents') {
                // create the 12 column cells that will be appended to the current table row
                let presenterData = document.createElement('td');
                presenterData.innerText = singleWSHR.presenter;

                let serviceName = document.createElement('td');
                serviceName.innerText = singleWSHR.serviceName;

                let servicesImpacted = document.createElement('td');
                servicesImpacted.innerText = singleWSHR.servicesImpacted;

                let createincidentIdAnchor = document.createElement('a');
                createincidentIdAnchor.href = "https://portal.microsofticm.com/imp/v3/incidents/details/" + singleWSHR.incidentId + "/home"
                createincidentIdAnchor.innerText = "ICM# " + singleWSHR.incidentId;

                let incidentId = document.createElement('td');
                incidentId.appendChild(createincidentIdAnchor);

                let summary = document.createElement('td');
                summary.innerText = singleWSHR.summary;

                let occuredRing = document.createElement('td');
                occuredRing.innerText = singleWSHR.occuredRing;

                let ttd = document.createElement('td');
                ttd.innerText = singleWSHR.ttd;

                let tte = document.createElement('td');
                tte.innerText = singleWSHR.tte;

                let ttm = document.createElement('td');
                ttm.innerText = singleWSHR.ttm;

                let ttn = document.createElement('td');
                ttn.innerText = singleWSHR.ttn;

                let createDescriptionAnchor = document.createElement('a');
                createDescriptionAnchor.href = singleWSHR.resources[0].link;
                createDescriptionAnchor.innerText = singleWSHR.resources[0].description;

                let description = document.createElement('td');
                description.appendChild(createDescriptionAnchor);

                let createPIRAnchor = document.createElement('a');
                createPIRAnchor.href = "https://portal.microsofticm.com/imp/v3/incidents/postmortem/" + singleWSHR.pir;
                createPIRAnchor.innerText = singleWSHR.pir;

                let pir = document.createElement('td');
                pir.appendChild(createPIRAnchor);

                IDSHRTableBodyRow.append(presenterData, serviceName, servicesImpacted, incidentId, summary, occuredRing, ttd, tte, ttm, ttn, description, pir); // Append all 3 cells to the table row

            }
            else if (tableName === 'specialTopics') {
                // create the 3 column cells that will be appended to the current table row
                let presenterData = document.createElement('td');
                presenterData.innerText = singleWSHR.presenter;
                let topicNameData = document.createElement('td');
                topicNameData.innerText = singleWSHR.topicName;
                let presentationLinkData = document.createElement('td');
                presentationLinkData.innerText = singleWSHR.presentationLink;

                IDSHRTableBodyRow.append(presenterData, topicNameData, presentationLinkData); // Append all 3 cells to the table row
            }

            IDSHRTable.append(IDSHRTableBodyRow); // Append the current row to the IDSHR table body
        }
    }

    const CopyTableData = (tableName) => {
        createFunSpotLightTable(tableName); //Creates tables
        if (tableName === 'actionItems') {
            if (isArrayWithLength(ActionItemsReviewData)) {
                for (const item of ActionItemsReviewData) {
                    if (item.id != 0) {
                        appendFunSpotLight(item, tableName); // Creates and appends each row to the table body.
                    }
                }
            }
        }
        else if (tableName === 'postIncidents') {
            if (isArrayWithLength(PIRdata)) {
                for (const item of PIRdata) {
                    if (item.id != 0) {
                        appendFunSpotLight(item, tableName); // Creates and appends each row to the table body.
                    }
                }
            }
        }
        else if (tableName === 'specialTopics') {
            if (isArrayWithLength(SpecialTopicsData)) {
                for (const item of SpecialTopicsData) {
                    if (item.id != 0) {
                        appendFunSpotLight(item, tableName); // Creates and appends each row to the table body.
                    }
                }
            }
        }

        var el = document.getElementById('IDSHRMainTable');
        // var el = document.getElementById('WeeklyIDSHRTableid');
        var body = document.body, range, sel;
        if (document.createRange && window.getSelection) {
            range = document.createRange();
            sel = window.getSelection();
            sel.removeAllRanges();
            try {
                range.selectNodeContents(el);
                sel.addRange(range);
            } catch (e) {
                range.selectNode(el);
                sel.addRange(range);
            }
            document.execCommand("copy");
            if (el) el.parentNode.removeChild(el);
        }
        if (!toast.isActive(toastIdRef.current)) {
            toastIdRef.current = toast.success(
                renderToastifyMsg('Table content copied to clipboard',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
            );
        }
    };

    if (isAuthenticated) {
        return (
            <div>
                <DeprecatedFunctionalityBanner />
                <LoadingOverlay active={isLoading}
                    spinner
                    text='Loading your content...'
                >
                    <section className='section'>
                        <div className='container'>
                            <DublinSHRDataTable
                                MeetingDate={MeetingDate}
                                handleMeetingDateChange={handleMeetingDateChange}
                                MaxDate={MaxDate}
                            />
                            <br />
                            <Row>
                                <Col lg="12">
                                    <ActionItemsReview
                                        ActionItemsReviewData={ActionItemsReviewData}
                                        isAdmin={isAdmin}
                                        CopyTableData={CopyTableData}
                                        accordiontoggle={accordiontoggle}
                                        setaccordiontoggledata={(accordiontoggle) => setaccordiontoggledata(accordiontoggle)}
                                    />
                                </Col>
                            </Row>
                            <br /> <br />
                            <Row>
                                <Col lg="12">
                                    <h5 className="headertext">Service review (10 mins per Team)</h5>
                                    <ul style={{ color: 'black', fontWeight: 'inherit', paddingLeft: '3%' }}>
                                        <li>Ux</li>
                                        <li>MSI</li>
                                        <li>Hybrid</li>
                                    </ul>
                                </Col>
                            </Row>
                            <br /> <br />
                            <Row>
                                <Col lg="12">
                                    <h5 className="headertext">Livesite Dashboard Review (5 minutes)</h5>
                                    <a href="https://msit.powerbi.com/groups/me/reports/a7e16e87-e7a8-4b5d-9fbd-61494e66a95a/ReportSectionc55575d29def9577994d" target="_blank">Report</a> (aka.ms/idlivesite-review) for review of Repair Items, IcM Trends and DRI Health
                                </Col>
                            </Row>
                            <br /> <br />
                            <Row>
                                <Col lg="12">
                                    <SpecialTopics
                                        SpecialTopicsData={SpecialTopicsData}
                                        handleKeypress={handleKeypress}
                                        EditSpecialTopicsRows={(e, row) => EditSpecialTopicsRows(e, row)}
                                        DeleteSpecialTopicsRow={(e, row) => DeleteSpecialTopicsRow(e, row)}
                                        cancelEditSpecialTopicRows={(e, row) => cancelEditSpecialTopicRows(e, row)}
                                        Specialtopicvalueschange={(e, row) => Specialtopicvalueschange(e, row)}
                                        isSpecialTopicEditorAddEnabled={isSpecialTopicEditorAddEnabled}
                                        SaveSpecialTopicData={(e, row) => SaveSpecialTopicData(e, row)}
                                        EnableAddNewforSpecialTopicsRow={EnableAddNewforSpecialTopicsRow}
                                        DefaultDate={moment(DefaultDate).format('MM/DD/YYYY')}
                                        MeetingDate={moment(MeetingDate).format('MM/DD/YYYY')}
                                        SpecialTopicsDate={MeetingDate}
                                        MaxDate={MaxDate}
                                        handleSpecialTopicsDateChange={handleSpecialTopicsDateChange}
                                        isAdmin={isAdmin}
                                        isLocked={isLockedTopic}
                                        EditLockforSpecificTopic={EditLockforSpecificTopicData}
                                        UpdateSpecialTopicsDataOrder={UpdateSpecialTopicsDataOrder}
                                        CopyTableData={CopyTableData}
                                        isSpecialTopicLockDisabledOnEdit={isSpecialTopicLockDisabledOnEdit}
                                        specialaccordiontoggle={specialaccordiontoggle}
                                        setspecialaccordiontoggledata={(specialaccordiontoggle) => setspecialaccordiontoggledata(specialaccordiontoggle)}
                                    />
                                </Col>
                            </Row>
                            <br /> <br />
                            <Row>
                                <Col lg="12">
                                    <PostIncidentReviews
                                        PIRdata={PIRdata}
                                        valchange={(e, value) => EditPIRRows(e, value)}
                                        CancelEditPIRRow={(e, value) => CancelEditPIR(e, value)}
                                        OnPIRTextboxchange={(e, value) => OnPIRTextboxchange(e, value)}
                                        SavePostIncidentData={(e, row, Isrefresh) => SavePostIncidentData(e, row, Isrefresh)}
                                        DeletePIRData={(e, row) => DeletePIRData(e, row)}
                                        EnableAddforPIRData={EnableAddforPIRData}
                                        isPIREditenabled={isPIREditenabled}
                                        handleKeypress={handleKeypress}
                                        PIRDate={MeetingDate}
                                        MaxDate={MaxDate}
                                        MeetingDate={MeetingDate}
                                        handlePIRDateChange={handlePIRDateChange}
                                        isAdmin={isAdmin}
                                        isLocked={isLockedPIR}
                                        EditLockforPIR={EditLockforPIRData}
                                        UpdatePIRdataOrder={UpdatePIRdataOrder}
                                        CopyTableData={CopyTableData}
                                        isPIRLockDisabledOnEdit={isPIRLockDisabledOnEdit}
                                        piraccordiontoggle={piraccordiontoggle}
                                        setpiraccordiontoggledata={(piraccordiontoggle) => setpiraccordiontoggledata(piraccordiontoggle)}
                                    />
                                </Col>
                            </Row>

                            <br /> <br />
                            <div className='IDWSHRDiv'></div>
                        </div>
                    </section>
                </LoadingOverlay>

            </div>
        );
    }
    else {
        return (
            <div></div>
        );
    }
};

const mapStateToProps = (state: IApplicationState) => ({
    ...state.DublinSHR,
    isAuthenticated: state.authUser.isAuthenticated,
    username: state.authUser.login,
    isAdmin: state.authUser.isAdmin
})

export default connect(mapStateToProps, actionCreators)(DublinSHRData);



