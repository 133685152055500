


export type IActionItemsState = {
    readonly isLoading: boolean;   
    readonly weeklyStartDate?: Date;   
    readonly allRepairItemsData: IAllRepairItemsData[];   
    readonly allRepairItemsDataGraphoptions: string;
    readonly allRepairItemsGraphDataAggregated: [];
    readonly RepairItemGraphServicesoptions: string;
    readonly allRepairItemsGraphData: [];
    readonly checkAllService: boolean;
    readonly allRepairItemsDatatableheader: {};
    readonly actionItemsData: [];
    readonly Isdataloaded: boolean;

};
export type IPropRepairItemsAllServices = {
    readonly serviceId: number;
    readonly serviceName: string;
    readonly metricId: number;
    readonly kpiValues: string
    readonly weekId: number;
    readonly weekDisplayName: string;
    readonly weekReportDate: string;
    readonly userSk: string;  
};
export type IPropTicketReport = {
    readonly weekId: number;
    readonly weekName: string;
    readonly serviceId: number;
    readonly totalOpened?: number;
    readonly opened?: number;
    readonly closed?: number;
    readonly outOfSla?: number;
    readonly pirLt?: number;
    readonly pirMt?: number;
    readonly pirSt?: number;
    readonly userSk: string;
   
};
export type IGraphRow = {
    week_Name: string;
    totalOpened?: number;
    opened?: number;
    closed?: number;
    outOfSla?: number;
}
export type IPropTicketDetails = {
    readonly ticketId: number;
    readonly ticketTitle: string;
    readonly ticketStatus: string;
    readonly isLsi?: boolean
    readonly isDetected?: boolean;
    readonly severity: number;
    readonly metricId: number;
    readonly weekId: number;
    readonly kpiDate?: Date;
    readonly createdDate?: Date;
    readonly dueDate?: Date;
    readonly serviceId: number;
    readonly envId: number;
    readonly tfsId: number;
    readonly slaStatus: string;
    readonly pirReportId: string;
    readonly deletedBy: string;
     serviceName: string;
    readonly scrumOriginalEta?: Date;
    readonly scrumRevisedEta?: Date;
    readonly userName?: Date;
     sortcreatedDate?: Date;
     sortdueDate?: Date;

};
export type IAllRepairItemsData={
     serviceId: number;
     serviceName: string;
    totalopen: number;
    opened: number;
    closed: number;
    outofSLA: number;
    weekDisplayName: string;
    checked: boolean;
}

const _namespace = 'ActionItems';
export interface IActionType {
    readonly RESET_STATE: string;
    readonly GET_ACTIONITEMS_REQUEST: string;
    readonly GET_ACTIONITEMS_SUCCESS: string;
    readonly GET_ACTIONITEMS_FAILURE: string;
    readonly GET_WEEK_START_DATE: string;
    readonly GET_REPAIRITEMS_SUCCESS: string;
    readonly GET_REPAIRITEMS_FAILURE: string;
    readonly GET_ACTIONITEMS_DATA_SUCCESS: string;
    readonly GET_ACTIONITEMS_DATA_FAILURE: string;
    readonly ADDED_VISITED_USER: string;
   
}

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/resetState`,
    GET_WEEK_START_DATE: `${_namespace}/getweekstartdate`,
    GET_ACTIONITEMS_REQUEST: `${_namespace}/getactionitemsrequest`,
    GET_ACTIONITEMS_SUCCESS: `${_namespace}/getactionitemssuccess`,
    GET_ACTIONITEMS_FAILURE: `${_namespace}/getactionitemsfailuere`,
    GET_REPAIRITEMS_SUCCESS: `${_namespace}/getrepaititemssuccess`,
    GET_REPAIRITEMS_FAILURE: `${_namespace}/getrepairitemsfailure`,
    GET_ACTIONITEMS_DATA_SUCCESS: `${_namespace}/getactionitemsdatasuccess`,
    GET_ACTIONITEMS_DATA_FAILURE: `${_namespace}/getactionitemsdatafailure`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`
  
});

