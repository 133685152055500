import { IAppThunkAction, ReduxAction } from '../';
import { ActionType, IGemServiceMappingData, INodeType } from './types';
import { CreateAdoHierarchyAPI, CommonAPI } from '../../api';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../utils';
import { toast } from 'react-toastify';

export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId).
                then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }
    },
    GetServiceTreeGemListData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        CreateAdoHierarchyAPI.GetServiceTreeGemListData()
            .then((gemservicedata: IGemServiceMappingData[]) => {               
                let nodeData: INodeType[] = [{
                    value: "SelectAll",
                    label: "SelectAll",
                    checked: true,
                }];
                gemservicedata.forEach((aa) => {
                    if (nodeData.some(x => x.value == aa.gem)) {
                        if (nodeData[nodeData.map(e => e.value).indexOf(aa.gem)].children == undefined) {
                            nodeData[nodeData.map(e => e.value).indexOf(aa.gem)].children = [{
                                value: aa.serviceName,
                                label: aa.serviceName,
                                checked: true
                            }];
                        }
                        else
                        {
                            nodeData[nodeData.map(e => e.value).indexOf(aa.gem)].children.push({
                                value: aa.serviceName,
                                label: aa.serviceName,
                                checked: true
                            })
                        }
                    }
                    else {
                        nodeData.push({
                            value: aa.gem,
                            label: aa.gem,
                            checked: true,
                            children: [
                                {
                                    value: aa.serviceName,
                                    label: aa.serviceName,
                                    checked: true
                                }]
                        })
                    }
                });
                dispatch({
                    gemServiceMappingData: gemservicedata,
                    nodeData: nodeData,
                    type: ActionType.GET_GEMSERVICE_SUCCESS
                });

            }).catch(error => {
                dispatch({
                    type: ActionType.GET_GEMSERVICE_FAILURE
                });
            });
    },
    UpdateCheckedValues: (checkedValues): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            checkedServices: checkedValues,
            type: ActionType.SET_CHECKED_VALUES
        });
    },
    UpdateDescriptionValue: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            description: e,
            type: ActionType.SET_DESCRIPTION_VALUES
        });
    },
    UpdateChapterIDValue: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            chapterID: e.target.value,
            type: ActionType.SET_CHAPTERID_VALUES
        });
    },
    UpdateEpicTitleValue: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            epicTitle: e.target.value,
            type: ActionType.SET_EPICTITLE_VALUES
        });
    },
    UpdateEpicIDValue: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            epicID: e.target.value,
            type: ActionType.SET_EPICID_VALUES
        });
    },
    UpdateIterationPathValue: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            iterationPath: e.target.value,
            type: ActionType.SET_ITERATIONPATH_VALUES
        });
    },
    createAdoTree: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let errorMsg = '';        
        let id = '';
        let message = '';
        let previousTitle = '';
        let gemdata = getState().CreateAdoHierarchy.gemServiceMappingData;
        let checkedservices = getState().CreateAdoHierarchy.checkedServices;
        let finalList = [];
        let chapterID = getState().CreateAdoHierarchy.chapterID;
        let epicTitle = getState().CreateAdoHierarchy.epicTitle;
        let iterationPath = getState().CreateAdoHierarchy.iterationPath;
        let description = getState().CreateAdoHierarchy.description.toString('html');
        let epicID = getState().CreateAdoHierarchy.epicID;
        let success = 0;
        gemdata.forEach((row) => {
            if (checkedservices.includes(row.gem) || checkedservices.includes(row.serviceName)) {
                finalList.push({
                    'ServiceName': row.serviceName, 'TeamGroupName': row.teamGroupName, 'AreaPath': row.areaPath, 'GemEmail': row.gemEmail
                });
            }
        });        
        let inData = { 'ChapterId': chapterID, 'EpicTitle': epicTitle, 'IterationPath': iterationPath, 'Description': description, 'Slist': finalList, 'EpicID': epicID};
        CreateAdoHierarchyAPI.CreateADOTreeTask(inData).then((response) => {                        
            if (epicID != '' && epicTitle == '' && response.length == 0) {
                message = 'Updated EPIC Success';
                success = 1;
            }
            else if (epicID != '' && epicTitle == '' && response.length >= 1) {
                errorMsg = 'EPIC Updation Failed';
                if (response != undefined) {
                    response.forEach((row) => {
                        if (row.serviceName != 'EPIC Success') {
                            errorMsg = errorMsg + ' ' + row.serviceName + ":" + row.errorMessage
                        }
                    });
                }
            }
            else if (response.length == 1 && epicTitle != '' && epicID == '') {
                if (response[0].serviceName == 'EPIC Created') {
                    message = response[0].serviceName;
                    id = response[0].errorMessage;
                    previousTitle = epicTitle;
                    success = 1;
                }
                else {
                    errorMsg = response[0].serviceName;
                    errorMsg = errorMsg + ' ' + response[0].errorMessage;
                }
            }
            else if (response.length > 1 && epicID == '' && epicTitle != '') {
                if (response[0].serviceName == 'EPIC Created') {
                    message = response[0].serviceName;
                    id = response[0].errorMessage;
                    previousTitle = epicTitle;
                    success = 1;
                }
                else {
                    errorMsg = response[0].serviceName;
                    errorMsg = errorMsg + ' ' + response[0].errorMessage;
                }
                if (response != undefined) {
                    response.forEach((row) => {
                        if (row.serviceName != 'EPIC Created') {
                            errorMsg = errorMsg + ' ' + row.serviceName + ":" + row.errorMessage
                        }
                    });
                }                
            }
            if (success == 0) {
                toast.error(renderToastifyMsg(errorMsg,
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 7000 });
            }
            else {                
                toast.success(renderToastifyMsg(message,
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4000 });
            }
            dispatch({
                previousTitle: previousTitle,
                id: id,
                errorMsg: errorMsg,
                type: ActionType.CREATE_ADOTREE
            });            
        }).catch(error => {
            dispatch({
                errorMsg: "Please Retry Unable to create Create Epic",
                type: ActionType.CREATE_ADOTREE_FAILED
            });
        });       
    },
    UpdateNodeData: (value): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let node = [];
        if (isArrayWithLength(value))
            node = value.map(value => value.value)
        dispatch({
            checkedServices: node,
            type: ActionType.SET_CHECKED_VALUES
        });
    },
    UpdateSelectAll: (isSelect): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let node = [];
        let nodeData = getState().CreateAdoHierarchy.nodeData;
        let newnodeData = [];        
        if (isSelect) {
            nodeData.forEach((row) => {
                row.checked = true;
                if (row.children != undefined) {
                    row.children.forEach((r) => {
                        r.checked = true;
                    })
                }
                newnodeData.push(row);
                node.push(row.value);
            });
        }
        else {
            nodeData = nodeData.forEach((row) => {
                row.checked = false;
                if (row.children != undefined) {
                    row.children.forEach((r) => {
                        r.checked = false;
                    })
                }
                newnodeData.push(row);                
            });
        }        
        dispatch({
            nodeData: newnodeData,
            checkedServices: node,
            type: ActionType.SET_NODEDATA_VALUES
        });
    },
}