import React, { useState, Fragment, useRef } from 'react';
import { IPropfutureOnCallData, DropdownOption } from '../../../store/FutureOnCall';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { ReduxAction, IAppThunkAction } from '../../../store';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FontAwesomeIconMemo, Checkbox } from '../../../components';
import { isNullOrUndefined,isArrayWithLength, renderToastifyMsg } from '../../../utils';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import { Workbook } from 'exceljs';
import { Calendar } from 'primereact/calendar';
import {
    Card, Row, Col, Container, CardBody
} from 'reactstrap';

type FutureOnCallTableProps = {
    readonly futureOnCallDataFiltered: IPropfutureOnCallData[];
    searchText: any;
    readonly sortData: any;
    readonly sortcolumn: any;
    readonly reverseSort: any;
    TenantTeamNamesList: any;
    CloudNamesList: any;
    OpenEmailPOPUP: any;
    selectedTenant: DropdownOption;
    selectedTeam: DropdownOption;
    readonly handleTenantChange: (selectedTenant: DropdownOption) => IAppThunkAction<ReduxAction>;
    readonly handleTeamChange: (selectedTeam: DropdownOption) => IAppThunkAction<ReduxAction>;
    readonly handleCloudChange: (selectedCloud: DropdownOption) => IAppThunkAction<ReduxAction>;
    readonly handleServiceLifeCycleChange: (selectedServiceLifeCycle: DropdownOption) => IAppThunkAction<ReduxAction>;
    tenantList: any;
    teamList: any;
    getFilteredOnCallList: any;
    clearDropdownValues: any;
    selectedCloud: DropdownOption;
    ServiceLifeCycleList: any;
    selectedServiceLifeCycle: DropdownOption;
};

const FutureOnCallTable = React.memo<FutureOnCallTableProps>(({
    futureOnCallDataFiltered,
    searchText,
    sortData,
    sortcolumn,
    reverseSort,
    TenantTeamNamesList,
    CloudNamesList,
    OpenEmailPOPUP,
    handleTenantChange,
    handleTeamChange,
    selectedTenant,
    selectedTeam,
    tenantList,
    teamList,
    getFilteredOnCallList,
    clearDropdownValues,
    handleCloudChange,
    selectedCloud,
    ServiceLifeCycleList,
    selectedServiceLifeCycle,
    handleServiceLifeCycleChange
}) => {

    const [globalFilter, setGlobalFilter] = useState('');
    const [globalFilteredData, setglobalFilteredData] = useState([]);
    const setGlobalFilterValue = (event) => {
        setGlobalFilter(event.target.value);
    }
    const [date1, setDate1] = useState(null);
    const [date2, setDate2] = useState(null);
    const exportOnCallDetails = (futureOnCallDataFiltered) => {
        const header = ['Tenant Name', 'Team Name', 'Cloud', 'Service Life Cycle', 'Start Time', 'End Time', 'Rotation Start Time', 'Rotation End Time', 'Shift Start Time', 'Shift End Time', 'Primary OnCall', 'Work No', 'Cell No', 'BackUp OnCall', 'BackUp Work No'];
        const list = [];
        futureOnCallDataFiltered.map((obj) => {
            list.push({
                'Tenant Name': obj.tenantName, 'Team Name': obj.teamName, 'Cloud': obj.cloud, 'Service Life Cycle': obj.serviceLifeCycle, 'Start Time': (obj.startTime !== null) ? moment(obj.startTime).format('YYYY-MM-DD') : '',
                'End Time': obj.endTime, 'Rotation Start Time': obj.rotationStartTime, 'Rotation End Time': obj.rotationEndTime, 'Shift Start Time': obj.shiftStartTime,
                'Shift End Time': obj.shiftEndTime, 'Primary OnCall': obj.name, 'Work No': obj.workPhoneNo,
                'Cell No': obj.cellNo, 'BackUp OnCall': obj.backUpName, 'BackUp Work No': obj.bWorkPhoneNo, 'BackUp Cell No': obj.bCellNo
            });
        });
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('FutureOnCall');

        const headerRow = worksheet.addRow(header);

        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '5879FF' },
                bgColor: { argb: 'FFFFFF' }
            };
            cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            cell.font = {
                bold: true,
                color: { argb: 'FFFFFF' }
            };
        });

        for (let d of list) {
            worksheet.addRow(d);
        }

        worksheet.autoFilter = 'A1:M1';
        const fileTypes = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtensions = '.xlsx';

        workbook.xlsx.writeBuffer().then((dataList: any) => {
            const blob = new Blob([dataList], { type: fileTypes });
            FileSaver.saveAs(blob, 'FutureOnCallDetails' + fileExtensions);
        });
    }
    const dt = useRef(null);
    const fromDateChange = (value, name) => {
        setDate1(value);
    }
    const toDateChange = (value, name) => {
        setDate2(value);
    }
    const displayStartDate = (rowData) => {
        return (
            <div>{(rowData.startTime === null) ? '' : moment(rowData.startTime).format('YYYY-MM-DD hh:mm A')}</div>
        );
    }
    const displayEndDate = (rowData) => {
        return (
            <div>{(rowData.endTime === null) ? '' : moment(rowData.endTime).format('YYYY-MM-DD hh:mm A')}</div>
        );
    }
    const displayRStartDate = (rowData) => {
        return (
            <div>{(rowData.rotationStartTime === null) ? '' : moment(rowData.rotationStartTime).format('YYYY-MM-DD hh:mm A')}</div>
        );
    }
    const displayREndDate = (rowData) => {
        return (
            <div>{(rowData.rotationEndTime === null) ? '' : moment(rowData.rotationEndTime).format('YYYY-MM-DD hh:mm A')}</div>
        );
    }
    const displayShiftStartDate = (rowData) => {
        return (
            <div>{(rowData.shiftStartTime === null) ? '' : moment(rowData.shiftStartTime).format('YYYY-MM-DD hh:mm A')}</div>
        );
    }
    const displayShiftEndDate = (rowData) => {
        return (
            <div>{(rowData.shiftEndTime === null) ? '' : moment(rowData.shiftEndTime).format('YYYY-MM-DD hh:mm A')}</div>
        );
    }
    const ClearFilter = () => {
        setDate1('');
        setDate2('');
        clearDropdownValues();
        setGlobalFilter('');
        getFilteredOnCallList('', '');
    }
    return (
        <div>
            <Card className="cardstyle">
                <CardBody className="cardbodyhederstyle">
                    <Row>
                        <Col lg="4"></Col>
                        <Col lg="3" style={{ textAlign: 'center' }}>
                            <div>
                                <h3 className="headertext"> Future OnCall Details </h3>
                            </div>
                        </Col>
                        <Col lg="5" style={{ textAlign: 'right' }}>
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" />&nbsp;
                                <InputText style={{ width: '340px', verticalAlign: 'top' }} type="search" placeholder="Global Search" value={globalFilter} onChange={(e) => setGlobalFilterValue(e)} />&ensp;&ensp;&ensp;
                            </span>
                            <span>Total Records: {futureOnCallDataFiltered.length}&nbsp;&nbsp;<FontAwesomeIconMemo onClick={(e) => exportOnCallDetails(futureOnCallDataFiltered)} title="Download OnCall Details" icon={'download'} className='icon-format' /></span>&nbsp;
                            <FontAwesomeIcon title='Copy Email' onClick={() => OpenEmailPOPUP(globalFilteredData, globalFilter)} icon={'envelope'} className='icon-format-internalEscalation' />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <br />
            <div>
                <div className="card">
                    <div className="p-fluid p-grid">
                        <Row>                          
                            <div className="p-field p-col-4 dropdownstylingInternalEscalation" style={{ width: '20vw', paddingLeft: '1.5rem' }}>
                                <span className="p-float-label">
                                    <MultiSelect id={'tenant'} value={selectedTenant} options={TenantTeamNamesList.table} onChange={(e) => handleTenantChange(e.value)} optionLabel="label" />
                                    <label htmlFor="cloud" className="fontcolor">Tenant Name</label>
                                </span>
                            </div>
                            <div className="p-field p-col-4 dropdownstylingInternalEscalation" style={{ width: '20vw', paddingLeft: '1.5rem' }}>
                                <span className="p-float-label">
                                    <MultiSelect id={'team'} value={selectedTeam} options={TenantTeamNamesList.table1} onChange={(e) => handleTeamChange(e.value)} optionLabel="label" />
                                    <label htmlFor="cloud" className="fontcolor">Team Name</label>
                                </span>
                            </div>
                            <div className="p-field p-col-4 dropdownstylingInternalEscalation" style={{ width: '10vw', paddingLeft: '1.5rem' }}>
                                <span className="p-float-label">
                                    <MultiSelect id={'cloud'} value={selectedCloud} options={CloudNamesList} onChange={(e) => handleCloudChange(e.value)} optionLabel="label" />
                                    <label htmlFor="cloud" className="fontcolor">Cloud</label>
                                </span>
                            </div>
                            <div className="p-field p-col-4 dropdownstylingInternalEscalation" style={{ width: '10vw', paddingLeft: '1.5rem' }}>
                                <span className="p-float-label">
                                    <MultiSelect id={'cloud'} value={selectedServiceLifeCycle} options={ServiceLifeCycleList} onChange={(e) => handleServiceLifeCycleChange(e.value)} optionLabel="label" />
                                    <label htmlFor="cloud" className="fontcolor">Service Life Cycle</label>
                                </span>
                            </div>
                            <div className="p-field p-col-4 dropdownstylingInternalEscalation" style={{ width: '10vw', paddingLeft: '1.5rem' }}>
                                <span className="p-float-label">
                                    <Calendar value={date1} onChange={(e) => fromDateChange(e.value, 'from')} hideOnDateTimeSelect={true}></Calendar>
                                    <label htmlFor="cloud" className="fontcolor">Start Date</label>
                                </span>
                            </div>
                            <div className="p-field p-col-4 dropdownstylingInternalEscalation" style={{ width: '10vw', paddingLeft: '1.5rem' }}>
                                <span className="p-float-label">
                                    <Calendar value={date2} minDate={date1} onChange={(e) => toDateChange(e.value, 'to')} hideOnDateTimeSelect={true}></Calendar>
                                    <label htmlFor="cloud" className="fontcolor">End Date</label>
                                </span>
                            </div>
                            <div style={{ paddingTop: '1.5rem' }}>
                                <Button label="Filter" style={{ width: '5vw', marginRight:'3%' }} title="Filter the records based on the filter selection" icon="pi pi-filter" className="p-button-text" onClick={() => getFilteredOnCallList(date1, date2)} />&nbsp;&nbsp;&nbsp;
                                <Button label="Clear Filters" style={{ width: '7vw' }} title="Clear Filter" className="p-button-text" onClick={ClearFilter} />&ensp;
                            </div>
                        </Row>
                    </div>
                </div>
            </div>
            <br />

            <div>
                <DataTable ref={dt} value={futureOnCallDataFiltered} className="tblIDEsclation tblborder" sortMode="multiple" globalFilter={globalFilter}
                    paginator rows={25} rowsPerPageOptions={[25, 50, 100, 200, 500]} emptyMessage="No Future On Call Records found."
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records">
                    <Column style={{ width: '14%', wordBreak: 'break-word' }} field="tenantName" sortable header="Tenant Name" filter filterMatchMode="contains" filterPlaceholder="Search by Tenant" />
                    <Column style={{ width: '9%', wordBreak: 'break-word' }} field="teamName" sortable header="Team Name" filter filterMatchMode="contains" filterPlaceholder="Search by Team" />
                    <Column style={{ width: '5%', wordBreak: 'break-word' }} field="cloud" sortable header="Cloud" />
                    <Column style={{ width: '5%', wordBreak: 'break-word' }} field="serviceLifeCycle" sortable header="Service Life Cycle" />
                    <Column style={{ width: '7%', wordBreak: 'break-word' }} field="startTime" sortable header="Start Time" body={displayStartDate} />
                    <Column style={{ width: '7%', wordBreak: 'break-word' }} field="endTime" sortable header="End Time" body={displayEndDate} />
                    <Column style={{ width: '7%', wordBreak: 'break-word' }} field="rotationStartTime" sortable header="Rotation Start Time" body={displayRStartDate} />
                    <Column style={{ width: '7%', wordBreak: 'break-word' }} field="rotationEndTime" sortable header="Rotation End Time" body={displayREndDate} />
                    <Column style={{ width: '7%', wordBreak: 'break-word' }} field="shiftStartTime" sortable header="Shift Start Time" body={displayShiftStartDate} />
                    <Column style={{ width: '7%', wordBreak: 'break-word' }} field="shiftEndTime" sortable header="Shift End Time" body={displayShiftEndDate} />
                    <Column style={{ width: '7%', wordBreak: 'break-word' }} field="name" sortable header="Primary OnCall" />
                    <Column style={{ width: '5%', wordBreak: 'break-word' }} field="workPhoneNo" sortable header="Work No" />
                    <Column style={{ width: '5%', wordBreak: 'break-word' }} field="cellNo" sortable header="Cell No" />
                    <Column style={{ width: '8%', wordBreak: 'break-word' }} field="backUpName" sortable header="BackUp OnCall" />
                    <Column style={{ width: '5%', wordBreak: 'break-word' }} field="bWorkPhoneNo" sortable header="BackUp Work No" />
                </DataTable>
            </div>
        </div>
    )
});

FutureOnCallTable.displayName = 'FutureOnCallTable';
export default FutureOnCallTable;