import React, { useState, Fragment, useRef  } from 'react';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import { IPropPlatformPMTools } from '../../../store/PlatformTools';
import {
    Card, Row, Col, Container, CardBody
} from 'reactstrap';
type PlatformToolTableProps = { 
    platformToolData: any;
    IsplatformToolDataloaded: boolean;
};
const PlatformToolTable = React.memo<PlatformToolTableProps>(({
    platformToolData,
    IsplatformToolDataloaded
}) => {
    let tmpapplicationtype: any = '';
    const setDirectorName = (Name: any) => {
        tmpapplicationtype = Name;
    };
    let PlatformTools = useRef<HTMLInputElement>(null); 
    let PlatformReports = useRef<HTMLInputElement>(null);
    let LivesiteSSRSReports = useRef<HTMLInputElement>(null);
    let COGS = useRef<HTMLInputElement>(null);
    let TOP = useRef<HTMLInputElement>(null);
  
    const MovetoPlatformTool = (scrollPos) => {
        if (scrollPos == 'PlatformTools') {
            PlatformTools.current.scrollIntoView({ block: 'end', behavior: "smooth"});
           
        }
        else if (scrollPos == 'PlatformReports') {
            PlatformReports.current.scrollIntoView({ block: 'end',  behavior: "smooth" });
           

        }
        else if (scrollPos == 'SSRSReports') {
            LivesiteSSRSReports.current.scrollIntoView({ block: 'end', behavior: "smooth" });
           

        }
        else if (scrollPos == 'COGS') {
            COGS.current.scrollIntoView({ block: 'end',  behavior: "smooth"});
           

        }
        else if (scrollPos == 'TOP') {
            TOP.current.scrollIntoView({ block: 'end', behavior: "smooth" });


        }
    }
    return (
        <div>
            <Card className="cardstyle">
                <CardBody className="cardbodyhederstyle">
                    <Row>

                        <Col lg="12">
                            <div ref={TOP}>
                                <h3 className="headertext"> Platform Team Tools</h3>                                
                                </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <br />
            {isArrayWithLength(platformToolData)
                ?
                <React.Fragment>
                    <Row>
                        <Col sm="12">
                            <div style={{ float: 'right' }}>
                                <a className="btn btn-primary btn-sm filter-hmbutton pirscrollbuttons" onClick={() => MovetoPlatformTool('PlatformTools')}><strong>Platform Tools</strong></a>&nbsp;
								<a className="btn btn-primary btn-sm filter-hmbutton pirscrollbuttons" onClick={e => MovetoPlatformTool("PlatformReports")}><strong>Platform Reports</strong></a>&nbsp;
                        <a className="btn btn-primary btn-sm filter-hmbutton pirscrollbuttons" onClick={e => MovetoPlatformTool("SSRSReports")}><strong>SSRS Reports</strong></a>&nbsp;
                        <a className="btn btn-primary btn-sm filter-hmbutton pirscrollbuttons" onClick={e => MovetoPlatformTool("COGS")}><strong>COGS</strong></a>&nbsp;

                           </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="1"></Col>
                        <Col lg="10" sm="12" xs="12">
                            {isArrayWithLength(platformToolData) && platformToolData.map((hmd: IPropPlatformPMTools, val) => (
                                <React.Fragment key={val}>
                                    {
                                        (hmd.applicationType != tmpapplicationtype)
                                            ?
                                            <React.Fragment>
                                                <div id={hmd.applicationType.replace(' ', '')} style={{ width: '100%', textAlign: 'center' }} ref={hmd.applicationType.replace(' ', '') === 'PlatformTools' ? PlatformTools : (hmd.applicationType.replace(' ', '') === 'PlatformReports') ? PlatformReports : (hmd.applicationType.replace(/\s/g, '') === 'LivesiteSSRSReports') ? LivesiteSSRSReports : COGS}> {setDirectorName(hmd.applicationType)}
                                                    <div>
                                                        <br />
                                                        <u style={{ textUnderlinePosition: 'below', fontSize: '20px', color: '#148', fontWeight: 'bold' }}>{hmd.applicationType}</u>
                                                        <br />
                                                        <br />
                                                    </div>

                                                    <Row>
                                                        {platformToolData.map((sub: IPropPlatformPMTools, index) => (
                                                            <React.Fragment key={index}>
                                                                {
                                                                    (sub.applicationType === tmpapplicationtype)
                                                                        ?
                                                                        <React.Fragment>
                                                                            <Col xs="6" sm="4" lg="3" className="shadowcol">
                                                                                <div className="shadow">
                                                                                    <h5 className="linkHeader"><a className="shadowboxtext" href={sub.url} target="_blank">{sub.title}</a></h5>
                                                                                    <h6 className="linkbody textaligncenter">
                                                                                        {sub.description}
                                                                                    </h6>
                                                                                </div>
                                                                            </Col>
                                                                        </React.Fragment>
                                                                        : <React.Fragment></React.Fragment>
                                                                }
                                                            </React.Fragment>
                                                        ))}
                                                       
                                                       
                                                    </Row>                                                   
                                                </div>
                                            </React.Fragment>
                                            : <React.Fragment></React.Fragment>
                                    }
                                </React.Fragment>
                            ))}
                        </Col>
                        <Col lg="1"></Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <div style={{ float: 'right' }}>
                                <a className="btn btn-primary btn-sm filter-hmbutton pirscrollbuttons" onClick={() => MovetoPlatformTool('TOP')}><strong>Top</strong></a>&nbsp;								
                           </div>
                        </Col>
                    </Row>
                  
                </React.Fragment>
                : <React.Fragment></React.Fragment>
            }
         
        </div>
    )
});

PlatformToolTable.displayName = 'PlatformToolTable';
export default PlatformToolTable;