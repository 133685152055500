import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
    actionCreators, reducer, IComments, IDailyData, HmMetric,
    IBulkViewDetails, PropKPICalculationDetails, PropKpiCalculationLogic,
    PropConfigData, PropTicketDetails, PropBgm, PropExternalLinks, PropMetricGroups, PropTicketReport, PropKpiComments, PropPullRequestDetails, PropStackOverFlowDetails, PropOneDashHistoricalData, PropDeploymentBuildDetails
} from '../../store/service-health';
import { REPORT_TYPE_DROPDOWN } from '../../config/constants';
import { DailyData, ServiceHealthFilter, ServiceNavigator, WeeklyReport, MonthlyReport, WeeklyPerformanceReport, HeatmapConfigurationGuide, WeeklySelfServeData, MultipleHeatMapViewData } from './child-components';
import { isNullOrUndefined, isArrayWithLength, renderToastifyMsg } from '../../utils';
import moment from 'moment';
import { DailyDataApi, WeeklyAPI } from '../../api';
import {
    Row,
    Col, Card, CardText
} from 'reactstrap';
import { Modal, Button, Tabs,Tab } from 'react-bootstrap'
import bxloader from '../../assets/images/bxloader.gif'
import { ToastId, toast } from 'react-toastify';
import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faTrashAlt, faPencilAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import * as FileSaver from 'file-saver';
import { Workbook } from 'exceljs';
import cancel from '../../assets/images/cancel.png';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import savebuttonicon from '../../assets/images/save-buttonicon.png';
import RichTextEditor from 'react-rte';
import { SelectRef } from 'react-functional-select/dist/Select';
import Switch from 'react-switch'

import LoadingOverlay from 'react-loading-overlay';

type ServiceHealthProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly isProd: boolean }
    & { readonly username: string }
    & RouteComponentProps<{}>;


const ServiceHealth: React.FC<ServiceHealthProps> = ({
    isAuthenticated,
    isLoading,
    resetState,
    dailyData,
    uniqueDateHeaders,
    uniqueFixedHeaderColumns,
    requestDailyData,
    reportSelectOption,
    checkboxValue,
    handleOnCheck,
    datacentersList,
    additionalInfoData,
    chkMSER,
    handleMSEROCheck,
    chkMonltyAvailability,
    handleMonthlyAvailabilityOnCheck,
    getHeatmapView,
    selectedDropdownOption,
    viewSelectOption,
    selectedViewDropdownOption,
    dailyViewList,
    fleetTenantList,
    selectedTenant,
    tenantSelectOption,
    fleetRoleList,
    selectedRole,
    roleSelectOption,
    fleetCloudList,
    selectedCloud,
    cloudSelectOption,
    fleetTrafficGroupList,
    selectedTrafficGroup,
    trafficGroupSelectOption,
    getfleetDropdownData,
    getfleetHeatmapData,
    fleetheatmapData,
    Datelabels,
    IsfleetDataloaded,
    handleFleetStartDateChange,
    handleFleetEndDateChange,
    handleOnFleetFilterApplied,
    dailyFleetDataFilter,
    fleetMaxDate,
    fleetStartDate,
    fleetEndDate,
    setfleetDataloadedtrue,
    startDate,
    endDate,
    onweeklyStartDateChange,
    onmonthlyStartDateChange,
    handleStartDateChange,
    handleEndDateChange,
    viewdetailsdata,
    SelectedView,
    comments,
    colorScheme,
    IsdataLoaded,
    IsReachabilitydataLoaded,
    handleOnFilterApplied,
    dailyDataFilter,
    weeklyStartDate,
    weeklyDefaultDate,
    previousService,
    currentService,
    weeklyData,
    weekDetailsData,
    draftsData,
    ConfigValues,
    nextService,
    requestServiceGroupNav,
    handleOnNext,
    handleOnPrevious,
    selectedServiceId,
    ExternalLinks,
    getWeeklyData,
    getWeekDetailsData,
    getDraftData,
    GetConfigValues,
    setloadingtrue,
    setloadingfalse,
    GetDateForRefreshedData,
    Datefreshed,
    getExternalLinks,
    commentsData,
    GetComments,
    LoginuserId,
    monthlyStartDate,
    monthlyData,
    getMonthlyData,
    MonthlyheaderArr,
    MonthlyKPiArr,
    IsMonthlyDataLoaded,
    IsperformanceDataloaded,
    Performancedata,
    GetPerformanceData,
    chkBulkEdit,
    handleBulkEditCheck,
    chkHistory,
    handleOnCheckHistory,
    IsWeeklyDataloaded,
    IsWeeklyDataDisabled,
    ColorLogicsData,
    ColorshemesData,
    noofdecimalsdata,
    GetColoringLogic,
    GetColorSchemes,
    Get_NoOfDecimals,
    ConfigurationView,
    colorSchemeValue,
    coloringLogicValue,
    noOfDecimalValue,
    volumeDisplayTypeValue,
    authenticationTypeValue,
    setColorSchemevalue,
    setColorlogic,
    setnoofdecimalsvalue,
    setDisplayvolumevalue,
    setAuthenticationTypevalue,
    Disableviewconfigbutton,
    SetViewconfigvalues,
    Newview,
    ChangeNewView,
    onReachabilityCheck,
    checkedReachability,
    setDailydataFilterviewid,
    BindViewsDropdownbasedonTabselection,
    AllviewsData,
    ReachabilityData,
    ReachabilityuniqueDateHeaders,
    ReachabilityuniqueFixedHeaderColumns,
    Reachabilitycomments,
    ReachabilitycolorScheme,
    ReachabilitySelectedView,
    DailyDataTabstoggleData,
    activeDailyDataTab,
    toggleMSERCommentsView,
    showMSERComments,
    getReachabilityServices,
    reachabilityServices,
    reachabilityServiceOnSelectOption,
    selectedServiceDropdownOption,
    URLParam,
    checkedFilterYellowDailyData,
    checkedFilterRedDailyData,
    checkedFilterYellowFleetData,
    checkedFilterRedFleetData,
    onFilterYellowDailyData,
    onFilterRedDailyData,
    onFilterYellowFleetData,
    onFilterRedFleetData,
    allReachabilityviewsData,
    DailyDataTabstogglerechabilityData,
    copyurlrechabilityviewid,
    copyurlrechabilityserviceid,
    IsfromURLNavigation,
    IsshowDrilldownIcon,
    IsshowEmail,
    addUserVisit,
    seturlparamempty,
    isProd,
    drilldown,
    changeshowdrilldown,
    copyurlHeatMapServiceid,
    copyurlHeatMapViewid,
    dcMaxLength,
    serviceGroups,
    selectedServicegroupval,
    services,
    selectedServiceval,
    handleServiceGroupChange,
    handleServicesChange,
    metricGroups,
    selectedMetricgroupval,
    handleMetricGroupChange,
    activeMetrics,
    selectedMetric,
    handleMetricChange,
    handleEnvironmentChange,
    handledatasourceChange,
    environments,
    selectedEnvironment,
    datasource,
    authenticationTypes,
    selectedDatasource,
    metricDataSourceDetails,
    resetFilters,
    GetMetricDataSources,
    GetDropDownDataWeeklySelfServe,
    FilterMetricDataSources,
    username,
    serverName,
    newMetricName,
    newMetricGroupName,
    database,
    mdmMetricName,
    authenticationType,
    target,
    notes,
    queryOrUrl,
    serverNameUpdate,
    addNewMetric,
    addNewMetricGroup,
    databaseUpdate,
    mdmMetricNameUpdate,
    authenticationTypeUpdate,
    UpdatequeryOrUrl,
    Updatetarget,
    Updatenotes,
    clearMetricDataSourceData,
    targetTypes,
    EditMetricDataSourceRow,
    setselectedServiceIdempty,
    onboardNewMetric,
    setonboardNewMetric,
    liKPIValueKeys,
    getfleetColorLogic,
    fleetDatacolorscheme,
    setViewMetricDataSourceEnabled,
    setViewMetricDataSourceDisabled,
    setAddMetricDataSourceEnabled,
    setAddMetricDataSourceDisabled,
    ViewMetricDataSourceOpen,
    AddMetricDataSourceOpen,
    DailyDataFailure,
    UpdateLabelValues,
    UpdateViewName,
    MultipleViewSelected,
    MultipleViewSelectedChange,
    multipleviewSelectedOption,
    multipleviewOnSelectOption,
    requestMultipleViewData,
    mvdailyData,
    Multipleviewdetailsdata,
    mvcomments,
    mvcolorScheme,
    MVDailyDataFailure,
    MVIsdataLoaded,
    TSGValue,
    HeatmapInactive
}) => {
    // if (isAuthenticated) {    
        //Get param from query string and pass to daily data
        const search = window.location.search;
        const params = new URLSearchParams(search);
        let serviceId = parseInt(params.get('serviceid'));
        const toastIdRef = useRef<ToastId>('');
        let viewId = 0;
        //let history = useHistory();

        //function handleLogOut() {
        //    sessionStorage.setItem("userToken", '');
        //    sessionStorage.clear();
        //    history.push("/sigin"); // whichever component you want it to route to
        //}
        //To log the visited Page Info in to DB
        useEffect(() => {
            if (isAuthenticated) {
                addUserVisit(selectedServiceId, 'ServiceHealth.tsx_v2');
            }
        }, [isAuthenticated]);

        //Uppdate the query string service id when we navigate the PRE & NEXT services.
        useEffect(() => {
            let searchparam = new URLSearchParams(window.location.search);
            let name = searchparam.get('name');
            let newurl;
            if (selectedServiceId != 0) {
                if (searchparam.has('name') || !isNullOrUndefined(selectedDropdownOption.label)) {
                    newurl = window.location.origin + window.location.pathname + '?serviceid=' + selectedServiceId + '&name=' + selectedDropdownOption.label;
                }
                else {
                    newurl = window.location.origin + window.location.pathname + '?serviceid=' + selectedServiceId;
                }
            }
            window.history.pushState({ path: newurl }, '', newurl);
        }, [selectedServiceId]);
        //Call Dailydata method when view gets changed
        useEffect(() => {
            if (!isNullOrUndefined(dailyDataFilter)) {
                viewId = dailyDataFilter.viewId;
            }
            //By default start date should be current date - 15 days
            const momentStDate = moment(startDate, 'MM/DD/YYYY');
            const sStartDate = momentStDate.format('MM/DD/YYYY');

            const momentEnDate = moment(endDate, 'MM/DD/YYYY');
            const sEndDate = momentEnDate.format('MM/DD/YYYY');
            var days = moment(sEndDate).diff(sStartDate, 'days');
            let multiViewCollection = [];

            //JIT SERVICE SELECT ALL HEATMAP BY DEFAULT 
            if (selectedServiceId == 122) {
                dailyViewList.map((item, index) => {
                    if (index < 9) {
                        multiViewCollection.push(parseInt(item.value));
                    }
                });

                if (multiViewCollection.length > 0) {
                    multipleviewSelectedOption = multiViewCollection;
                }
            }

            if (days > 60) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg('Maximum days allowed is 60',
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                );
            }
            else if (MultipleViewSelected) {
                if (multipleviewSelectedOption !== null && multipleviewSelectedOption !== undefined) {
                    if (days > 15) {
                        toastIdRef.current = toast.warn(
                            renderToastifyMsg('Maximum days allowed is 15 if you are enabling Multiple View Selection',
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                        );
                    }
                    else if (multipleviewSelectedOption.length > 10) {
                        toastIdRef.current = toast.warn(
                            renderToastifyMsg('Maximum Views allowed is 10',
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                        );
                    }
                    else if (multipleviewSelectedOption.length === 0) {
                        toastIdRef.current = toast.warn(
                            renderToastifyMsg('Please select atleast one view to load the data !!',
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                        );
                    }
                    else {
                        let filteredViewData = [];
                        dailyViewList.map((item) => {
                            multipleviewSelectedOption.map((mvData) => {
                                if (parseInt(mvData) === parseInt(item.value)) {
                                    filteredViewData.push(parseInt(mvData));
                                }
                            });
                        });
                        if (filteredViewData.length > 0) {
                            requestMultipleViewData(selectedServiceId, sStartDate, sEndDate, filteredViewData);
                        }
                    }
                }
            }
            else {
                if (viewId > 0 && (activeDailyDataTab === 'heatmap')) {
                    requestDailyData(selectedServiceId, sStartDate, sEndDate, viewId, 'heatmap');
                }
                else if (viewId > 0 && activeDailyDataTab === 'reachability') {
                    if (!isNullOrUndefined(selectedServiceDropdownOption)) {
                        requestDailyData(selectedServiceDropdownOption.value, sStartDate, sEndDate, viewId, activeDailyDataTab);
                    }
                }
            }
        }, [selectedViewDropdownOption, dailyDataFilter, MultipleViewSelected]);
        //========== to set tabs as fleet/Reachability when navigating from v1 ======//
        useEffect(() => {
            if (activeDailyDataTab === 'Fleetutilizationheatmap' && URLParam === 'FleetUtilizationheatmap') {
                callFleetdata();
            }
            else if (activeDailyDataTab === 'ViewConfiguration' && URLParam === 'selfserveconfig') {
                if (currentService !== null) {
                    seturlparamempty();
                    getReachabilityServices(selectedServiceId, 0);
                    getHeatmapView(selectedServiceId, 'ViewConfiguration', 1,0);
                }
            }

        }, [currentService]);
        //Call load fleet heatmap method when tenant,role,cloud,trafficgroup gets changed
        useEffect(() => {
            if (!isNullOrUndefined(dailyFleetDataFilter)) {
                selectedTenant = dailyFleetDataFilter.tenant;
                selectedCloud = dailyFleetDataFilter.cloud;
                selectedRole = dailyFleetDataFilter.role;
                selectedTrafficGroup = dailyFleetDataFilter.trafficGroup;
                if (currentService === null || (selectedServiceId != currentService[0].serviceId))
                    setloadingtrue();
                requestServiceGroupNav(selectedServiceId);
            }

            const momentfStDate = moment(fleetStartDate, 'MM/DD/YYYY');
            const fStartDate = momentfStDate.format('MM/DD/YYYY');

            const momentfEnDate = moment(fleetEndDate, 'MM/DD/YYYY');
            const fEndDate = momentfEnDate.format('MM/DD/YYYY');

            var days = moment(fEndDate).diff(fStartDate, 'days');
            if (days > 60) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg('Maximum days allowed is 60',
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                );
                setloadingfalse();
                setfleetDataloadedtrue();
            }
            else {
                if (activeDailyDataTab === 'Fleetutilizationheatmap') {
                    if (dailyFleetDataFilter != null) {
                        getfleetHeatmapData(fStartDate, fEndDate, selectedTenant, selectedCloud, selectedTrafficGroup, selectedRole);
                        getfleetColorLogic(fStartDate, fEndDate, selectedTenant, selectedCloud, selectedTrafficGroup, selectedRole);
                    }
                }
            }
        }, [selectedTrafficGroup, selectedCloud, selectedRole, selectedTenant, dailyFleetDataFilter]);
        //========== Call Load MetricdataSource method when Service is changed ======//
        useEffect(() => {
            if (selectedServiceval !== null) {
                GetMetricDataSources(selectedServiceval);
            }
        }, [selectedServiceval]);

        //========== Filter MetricdataSources when MetricGroup, Metric,Environment and DataSource is changed ======//
        useEffect(() => {
            FilterMetricDataSources();
        }, [selectedMetricgroupval, selectedMetric, selectedEnvironment, selectedDatasource]);

        if (selectedServiceId !== 0) {
            serviceId = selectedServiceId;
        }
        else {
            selectedServiceId = serviceId;
        }

        useEffect(() => {
            //SET date current date - 15 on initial load and set the view id from state.
            //If one of the dependencies has changed since the last time, the effect will run again. 
            //So, initial load is default filter and this will effect again based on the filter applied -  parameters           
            //By default start date should be current date - 15 days
            const momentStDate = moment(startDate, 'MM/DD/YYYY');
            const sStartDate = momentStDate.format('MM/DD/YYYY');
            const momentEnDate = moment(endDate, 'MM/DD/YYYY');
            const sEndDate = momentEnDate.format('MM/DD/YYYY');
            //GET Dailyview (Heatmap views) per ServiceId
            //If one of the dependencies has changed since the last time, the effect will run again. 
            //So, initial load is default filter and this will effect again based on the filter applied -  parameters       
            if (currentService === null || (selectedServiceId != currentService[0].serviceId)) {
                setloadingtrue();
                requestServiceGroupNav(selectedServiceId);
            }
            else if (IsfromURLNavigation) { // === If you are navigating from home page need to call  eventhough serviceid is same, because of toggle actions .
                if(selectedServiceId != currentService[0].serviceId)
                    setloadingtrue();
                requestServiceGroupNav(selectedServiceId);
            }
            //LOAD view details in the dropdown.
            //GET Daily date 
            if (URLParam === '') {
                if (selectedDropdownOption.label === 'Daily') {
                    if (serviceId != 0) {
                        let newurl = window.location.origin + window.location.pathname + '?serviceid=' + serviceId + '&name=' + selectedDropdownOption.label;
                        window.history.pushState({ path: newurl }, '', newurl);
                    }
                    if (activeDailyDataTab === 'heatmap' || activeDailyDataTab === 'reachability' || activeDailyDataTab === 'ViewConfiguration') {
                        if (isArrayWithLength(AllviewsData) && isArrayWithLength(SelectedView)) {
                            if (selectedServiceId != SelectedView[0].serviceId) {
                                if (activeDailyDataTab === 'heatmap') {
                                    requestDailyData(selectedServiceId, sStartDate, sEndDate, viewId, activeDailyDataTab);
                                    getHeatmapView(selectedServiceId, activeDailyDataTab, 0,0);
                                    getReachabilityServices(selectedServiceId,0);
                                }
                            }
                        }
                        else {
                            if (activeDailyDataTab === 'heatmap') {
                                requestDailyData(selectedServiceId, sStartDate, sEndDate, viewId, activeDailyDataTab);
                                getHeatmapView(selectedServiceId, activeDailyDataTab, 0,0);
                                getReachabilityServices(selectedServiceId,0);
                            }
                            else {
                                getHeatmapView(selectedServiceId, activeDailyDataTab, 0,0);
                            }
                        }
                    }
                    else {
                        if (activeDailyDataTab === 'Fleetutilizationheatmap') {
                            callFleetdata();
                        }
                    }
                }
                else if (selectedDropdownOption.label === 'Weekly') {
                    if (serviceId != 0) {
                        let newurl = window.location.origin + window.location.pathname + '?serviceid=' + serviceId + '&name=Weekly';
                        window.history.pushState({ path: newurl }, '', newurl);
                    }

                    if (IsPerformanceSelected) {
                        GetPerformancePopupData();
                    }
                    if (isArrayWithLength(weeklyData)) {
                        if (selectedServiceId != weeklyData[0].serviceId || moment(weeklyStartDate).format('MM/DD/YYYY') != weeklyData[0].WeekDate) {
                            CallWeeklyMethods();
                        }
                        else {
                            GetDateForRefreshedData();
                            setloadingfalse();
                        }
                    }
                    else {
                        CallWeeklyMethods();
                    }

                }
                else if (selectedDropdownOption.label === 'Monthly') {
                    getMonthlyData(monthlyStartDate, serviceId);
                }

            }
            else if (URLParam === 'Weekly') {
                seturlparamempty();
                reportSelectOption({ value: 2, label: 'Weekly' });
            }
            else if (URLParam === 'Monthly') {
                seturlparamempty();
                getMonthlyData(monthlyStartDate, serviceId);
            }
            else if (URLParam === 'ServicePerformance') {
                seturlparamempty();
                setIsPerformanceSelected(true);
                reportSelectOption({ value: 2, label: 'Weekly' });

            }
            else if (URLParam === 'WeeklySelfServe') {
                seturlparamempty();
                setselectedServiceIdempty();
                //==================== function to get Weekly SelfServe Data for weekly Report =====================//
                setIsWeeklySelfServeSelected(true);
                resetFilters();
                GetDropDownDataWeeklySelfServe(6, null);
                setAddMetricDataSourceDisabled();
                setViewMetricDataSourceEnabled();
                reportSelectOption({ value: 2, label: 'Weekly' });
            }
            else if (URLParam === 'reachability') {
                // requestServiceGroupNav(1); 
                getReachabilityServices(copyurlrechabilityserviceid,0);
                requestDailyData(copyurlrechabilityserviceid, sStartDate, sEndDate, copyurlrechabilityviewid, activeDailyDataTab);
                getHeatmapView(copyurlrechabilityserviceid, activeDailyDataTab, 0, copyurlrechabilityviewid);
                seturlparamempty();

            }
            else if (URLParam === 'heatmap') {
                // requestServiceGroupNav(1);  
                getReachabilityServices(copyurlHeatMapServiceid, 0);
                //requestDailyData(copyurlHeatMapServiceid, sStartDate, sEndDate, copyurlHeatMapViewid, activeDailyDataTab);
                getHeatmapView(copyurlHeatMapServiceid, activeDailyDataTab, 0, copyurlHeatMapViewid);
                seturlparamempty();
            }
            else if (URLParam === 'selfserveconfig') {
                GetColoringLogic();
                GetColorSchemes(1);
                Get_NoOfDecimals();

            }
            else if (URLParam === 'FleetUtilizationheatmap') {
                setloadingtrue();
                getReachabilityServices(selectedServiceId, 0);
            }
            else if (URLParam === 'HMReachability') {
                // requestServiceGroupNav(1);
                seturlparamempty();
                getReachabilityServices(selectedServiceId, 0);
                requestDailyData(selectedServiceId, sStartDate, sEndDate, 0, activeDailyDataTab);
                getHeatmapView(selectedServiceId, activeDailyDataTab, 0,0);

            }
        }, [requestServiceGroupNav, selectedServiceId, selectedDropdownOption, weeklyStartDate, monthlyStartDate]);

        const callFleetdata = () => {
            if (currentService !== null) {
                if (selectedTenant === null) {
                    // Reseting the Date range to Default
                    var currDate = new Date();
                    fleetEndDate = new Date();
                    if (currDate.getDay() < 1) { //1- Monday
                        fleetEndDate.setDate(fleetEndDate.getDate() - fleetEndDate.getDay());
                        fleetEndDate.setDate(fleetEndDate.getDate() - 7 - 2);
                    }
                    else {
                        fleetEndDate.setDate(fleetEndDate.getDate() - fleetEndDate.getDay());
                        fleetEndDate.setDate(fleetEndDate.getDate() - 2);
                    }

                    fleetStartDate = new Date(
                        fleetEndDate.getFullYear(),
                        fleetEndDate.getMonth(),
                        fleetEndDate.getDate() - 6
                    );
                    fleetMaxDate = fleetEndDate;
                    //
                    getfleetDropdownData(fleetStartDate, fleetEndDate, currentService[0].serviceName);
                }
                else if (selectedTenant.value !== currentService[0].serviceName) {
                    // Reseting the Date range to Default
                    var currDate = new Date();
                    fleetEndDate = new Date();
                    if (currDate.getDay() < 1) { //1- Monday
                        fleetEndDate.setDate(fleetEndDate.getDate() - fleetEndDate.getDay());
                        fleetEndDate.setDate(fleetEndDate.getDate() - 7 - 2);
                    }
                    else {
                        fleetEndDate.setDate(fleetEndDate.getDate() - fleetEndDate.getDay());
                        fleetEndDate.setDate(fleetEndDate.getDate() - 2);
                    }

                    fleetStartDate = new Date(
                        fleetEndDate.getFullYear(),
                        fleetEndDate.getMonth(),
                        fleetEndDate.getDate() - 6
                    );
                    fleetMaxDate = fleetEndDate;
                    //
                    getfleetDropdownData(fleetStartDate, fleetEndDate, currentService[0].serviceName);
                }
            }
        }
        // ===== function to call weekly methods =============================//
        const CallWeeklyMethods = () => {
            getWeeklyData(selectedServiceId, weeklyStartDate);
            getWeekDetailsData(weeklyStartDate);
            getDraftData(selectedServiceId, weeklyStartDate, weeklyDefaultDate);
            if (!isArrayWithLength(ConfigValues))
                GetConfigValues();
            if (Datefreshed == '')
                GetDateForRefreshedData();
            if (!isArrayWithLength(ExternalLinks))
                getExternalLinks();
            GetComments(selectedServiceId, weeklyStartDate, 0);
        }
        const [show, setShow] = useState(false);
        const [showBulkEdit, setShowBulkEdit] = useState(false);
        const [ActiveCell, setActiveCell] = useState(null);
        const [ActiveView, setActiveView] = useState(null);
        const [Selected_View, setSelected_View] = useState(null);
        const [CommentObj, setCommentObj] = useState(null);
        const [BulkEditObjs, setBulkEditObjs] = useState(null);
        const [IncidentCheck, setIncidentCheck] = useState(false);
        const [Isloading, SetIsloading] = useState(false);
        const [BulkComment, SetBulkComment] = useState('');
        const [monthly_Data, setmonthlyData] = useState(monthlyData);
        const [BulkViews, SetBulkViews] = useState('');
        const [BulkViewIds, SetBulkViewIds] = useState([]);
        const [BulkCommentId, SetBulkCommentId] = useState('');
        const [selectedDataCenter, SetselectedDataCenter] = useState([]);
        const [effectiveDate, SeteffectiveDate] = useState(null);
        const [MSERCommentsPopup, setMSERCommentsPopup] = useState(false);
        const [AllCommentsPopup, setAllCommentsPopup] = useState(false);
        const [AdditionInfoId, setAdditionInfoId] = useState(0);
        const [impact, Setimpact] = useState(RichTextEditor.createEmptyValue());
        const [rootCause, SetrootCause] = useState(RichTextEditor.createEmptyValue());
        const [mitigation, Setmitigation] = useState(RichTextEditor.createEmptyValue());
        const [learningsAndRepairItems, SetlearningsAndRepairItems] = useState(RichTextEditor.createEmptyValue());
        const [Disablesavetbutton, setDisablesavetbutton] = useState(false);
        const [Disablecommentbutton, setDisablecommentbutton] = useState(true);
        const [Disablesubmitbutton, setDisablesubmitbutton] = useState(false);
        const [DisableDeletebutton, setDisableDeletebutton] = useState(false);
        const [DisableIncidentSubmitButton, setDisableIncidentSubmitButton] = useState(false);
        const [BulkEdit, SetBulkEdit] = useState(false);
        const [IsEdit, SetIsEdit] = useState(false);
        const [KPIHistory, SetKPIHistory] = useState([]);
        const [Historyshow, SetHistoryshow] = useState(false);
        const [weekly_Data, setweeklyData] = useState(weeklyData);
        const [BulkViewData, setBulkViewsData] = useState([]);
        const [KPIPopupdata, setKPIPopupdata] = useState([]);
        const [KPICalculatedlogic, setKPICalculatedlogic] = useState([]);
        const [KPILogic, setKPILogic] = useState(false);
        const [TFSTicketDetails, setTFSTicketDetails] = useState(false);
        const [metricName, setmetricName] = useState('');
        const [IsSev, setIsSev] = useState(false);
        const [IsRead, setIsRead] = useState(false);
        const [Config_Values, setConfig_Values] = useState(null);
        const [bAdd, setbAdd] = useState(false);
        const [Task_details, setTask_details] = useState([]);
        const [Ticket_details, setTicket_details] = useState([]);
        const [TFSLSITicketDetails, setTFSLSITicketDetails] = useState(false);
        const [LSITicket_details, setLSITicket_details] = useState([]);
        const [LSI_Ticket_details_History, setLSI_Ticket_details_History] = useState([]);
        const [NewRec, setNewRec] = useState(false);
        const [BGMPopup, setBGMPopup] = useState(false);
        const [BGMdata, setBGMdata] = useState([]);
        const [BGMFairFaxdata, setBGMFairFaxdata] = useState([]);
        const [BGMMooncakedata, setBGMMooncakedata] = useState([]);
        const [IsBgmEdit, setIsBgmEdit] = useState(false);
        const [ShowExternalBgmLink, setShowExternalBgmLink] = useState(false);
        const [NoBgmData, setNoBgmData] = useState(false);
        const [NewBGMPopup, setNewBGMPopup] = useState(false);
        const [KPIChart, setKPIChart] = useState(false);
        const [Options, setOptions] = useState(null);
        const [isAvailability, setisAvailability] = useState(false);
        const [AvailabilityData, setAvailabilityData] = useState([]);
        const [ExternalLink, setExternalLink] = useState('');
        const [External_link_NewBGM, setExternal_link_NewBGM] = useState('');
        const [CommentsPopup, setCommentsPopup] = useState(null);
        const [MetricGroupvalue, setMetricGroupvalue] = useState(null);
        const [Commentvalue, setCommentvalue] = useState(RichTextEditor.createEmptyValue());
        const [DBCommentvalue, setDBCommentvalue] = useState('');
        const [MetricGroup, setMetricGroup] = useState([]);
        const [BGMJsondata, setBGMJsondata] = useState([]);
        const [ExistingMetricGroupId, setExistingMetricGroupId] = useState(0);
        const [ShowExternalPerformanceLink, setShowExternalPerformanceLink] = useState(false);
        const [NoPerfData, setNoPerfData] = useState(false);
        const [LSICommentText, setLSICommentText] = useState('');
        const [LSIEditcount, SetLSIEditcount] = useState('');
        const [IsPerformanceSelected, setIsPerformanceSelected] = useState(false);
        const [IsHeatmapConfigUserGuideSelected, setIsHeatmapConfigUserGuideSelected] = useState(false);
        const [bulkcellcount, setbulkcellcount] = useState(0);
        const [IssendingEmail, setIssendingEmail] = useState(false);
        const [IsWeeklySelfServeSelected, setIsWeeklySelfServeSelected] = useState(false);
        const [IncidentIDPresent, setIncidentIDPresent] = useState(false);
        const [toICMorADO, setICMorADO] = useState(false);
        const [PullRequestSchemaDetails, setPullRequestSchemaDetails] = useState(false);
        const [BuildSchemaDetails, setBuildSchemaDetails] = useState(false);
        const [StackOverFlowDetails, setStackOverFlowDetails] = useState(false);
        const [PullRequest_schemaDetails, setPullRequest_schemaDetails] = useState([]);
        const [DeploymentBuild_schemaDetails, setDeploymentBuild_schemaDetails] = useState([]);
        const [StackOverFlow_ResponseDetails, setStackOverFlow_ResponseDetails] = useState([]);
        const [S360Details, setS360Details] = useState(false);
        const [S360Ticket_Details, setS360Ticket_Details] = useState([]);
        const [ICMTicketDetails, setICMTicketDetails] = useState(false);
        const [ICMTicket_details, setICMTicket_details] = useState([]);

        const ICMorADOChange = (value) => {
            if (value) {
                setICMorADO(true);
            }
            else {
                setICMorADO(false);
            }
        }

        //========== function to changes tabs in Daily Data=================//
        const DailyDataTabstoggle = tab => {
            // To make Drilldown false when tab changed 
            var hid = selectedServiceId;
            var rid;
            if (selectedServiceDropdownOption === null) {
                rid = 0;
            } else {
                rid=selectedServiceDropdownOption.value;
            }
            if (activeDailyDataTab !== tab) {
                if (previousService === null && currentService === null) {
                    setloadingtrue();
                    requestServiceGroupNav(selectedServiceId);
                }
                if (tab === 'reachability' && hid != rid) {
                    setloadingtrue();
                    if (rid === 0) {
                        getReachabilityServices(selectedServiceId, 0);
                    }
                    else {
                        reachabilityServiceOnSelectOption(selectedServiceDropdownOption);
                    }
                    DailyDataTabstogglerechabilityData(tab);
                }
                else {
                    DailyDataTabstoggleData(tab);
                }

                //============= function for binding the views based on the tab selection =============================================//
                // BindViewsDropdownbasedonTabselection(tab);
                if (tab === 'ViewConfiguration') {
                    if (!isArrayWithLength(ColorLogicsData))
                        GetColoringLogic();
                    if (!isArrayWithLength(ColorshemesData))
                        GetColorSchemes(0);
                    if (!isArrayWithLength(noofdecimalsdata))
                        Get_NoOfDecimals();
                }
                else if (tab === 'Fleetutilizationheatmap') {
                    var currDate = new Date();
                    var ffEndDate = new Date();
                    var ffStartDate = new Date();
                    if (currDate.getDay() < 1) { //1- Monday
                        ffEndDate.setDate(ffEndDate.getDate() - ffEndDate.getDay());
                        ffEndDate.setDate(ffEndDate.getDate() - 7 - 2);
                    }
                    else {
                        ffEndDate.setDate(ffEndDate.getDate() - ffEndDate.getDay());
                        ffEndDate.setDate(ffEndDate.getDate() - 2);
                    }

                    ffStartDate = new Date(
                        ffEndDate.getFullYear(),
                        ffEndDate.getMonth(),
                        ffEndDate.getDate() - 6
                    );

                    if (currentService !== null) {
                        if (selectedTenant === null || moment(ffEndDate, 'MM/DD/YYYY').format('MM/DD/YYYY') != moment(fleetEndDate, 'MM/DD/YYYY').format('MM/DD/YYYY')
                            || moment(ffStartDate, 'MM/DD/YYYY').format('MM/DD/YYYY') != moment(fleetStartDate, 'MM/DD/YYYY').format('MM/DD/YYYY')) {
                            fleetMaxDate = ffEndDate;
                            fleetEndDate = ffEndDate;
                            fleetStartDate = ffStartDate;
                            getfleetDropdownData(fleetStartDate, fleetEndDate, currentService[0].serviceName);
                        }
                        else if (selectedTenant.value !== currentService[0].serviceName) {
                            fleetMaxDate = ffEndDate;
                            fleetEndDate = ffEndDate;
                            fleetStartDate = ffStartDate;
                            getfleetDropdownData(fleetStartDate, fleetEndDate, currentService[0].serviceName);
                        }
                    }
                }

            };
        };

        //============== function to save View configuration Data=================//

        const AddorUpdateConfigViewData = () => {
            if (ConfigurationView.isMonthlyAvailability == null || ConfigurationView.isMonthlyAvailability == '' || ConfigurationView.isMonthlyAvailability == undefined) {
                ConfigurationView.isMonthlyAvailability = false;
            }
            let View = {
                'AuthenticationType': ConfigurationView.authenticationType,
                'CcEmailList': ConfigurationView.ccEmailList,
                'ColorLogicText': (ConfigurationView.viewId === 0) ? coloringLogicValue.name : ConfigurationView.colorLogicText,
                'ColorSchemeId': ConfigurationView.colorSchemeId,
                'ColoringLogicId': ConfigurationView.coloringLogicId,
                'DrillDownQuery': ConfigurationView.drillDownQuery,
                'FixedColQuery': ConfigurationView.fixedColQuery,
                'HasVal2': ConfigurationView.hasVal2,
                'IsMonthlyAvailability': ConfigurationView.isMonthlyAvailability,
                'IsMserPresentationView': ConfigurationView.isMserPresentationView,
                'IsMserView': ConfigurationView.isMserView,
                'IsReachability': ConfigurationView.isReachability,
                'KustoDbName': ConfigurationView.kustoDbName,
                'mdmMetricName': ConfigurationView.mdmMetricName,
                'KustoServer': ConfigurationView.kustoServer,
                'NoOfDecimalId': ConfigurationView.noOfDecimalId,
                'NoOfDecimalText': (ConfigurationView.viewId === 0) ? noOfDecimalValue.name : ConfigurationView.noOfDecimalText,
                'ServiceId': ConfigurationView.serviceId,
                'serviceName': ConfigurationView.serviceName,
                'Source': ConfigurationView.source,
                'ToEmailList': ConfigurationView.toEmailList,
                'TotalsLabel': ConfigurationView.totalsLabel,
                'UpdatedBy': LoginuserId,
                'UpdatedOn': new Date(),
                'Val1Cap': ConfigurationView.val1Cap,
                'Val1Label': ConfigurationView.val1Label,
                'Val2Cap': ConfigurationView.val2Cap,
                'Val2Label': ConfigurationView.val2Label,
                'ValQuery': ConfigurationView.valQuery,
                'ViewId': ConfigurationView.viewId,
                'ViewName': ConfigurationView.viewName,
                'VolumeType': ConfigurationView.volumeType,
                'RenameViewLabel': (ConfigurationView.RenameViewLabel === '' || ConfigurationView.RenameViewLabel === undefined || ConfigurationView.RenameViewLabel === null) ? ConfigurationView.viewName : ConfigurationView.RenameViewLabel,
                'TSG': ConfigurationView.tsg
            }
            DailyDataApi.AddUpdateHmView(View)
                .then((BulkupdateResponse: number) => {
                    if (BulkupdateResponse >= 1) {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg('View Added/Updated succesfully',
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                        );
                        let viewid = (View.IsReachability) ? (isArrayWithLength(ReachabilitySelectedView) ? ReachabilitySelectedView[0].viewId : 0) : (isArrayWithLength(SelectedView) ? SelectedView[0].viewId : 0);
                        let value = (View.IsReachability) ? 'reachability' : 'heatmap';
                        ConfigurationView.RenameViewLabel = '';
                        if (View.ViewId>0 && viewid=== View.ViewId) {
                            getHeatmapView(selectedServiceId, activeDailyDataTab, 1,0);
                            requestDailyData(selectedServiceId, moment(startDate, 'MM/DD/YYYY').format('MM/DD/YYYY'), moment(endDate, 'MM/DD/YYYY').format('MM/DD/YYYY'), View.ViewId, value);

                        }
                        else {
                            getHeatmapView(selectedServiceId, activeDailyDataTab, 1,0);
                        }
                        getReachabilityServices(selectedServiceId,1);
                    }
                    else {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg('No Change in Data or Error While Saving the Data, Please contact Admin!',
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                        );

                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg('No Change in Data or Error While Saving the Data, Please contact Admin!',
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                    );
                });
        }

        const ChangeHeatmapStatus = (row) => {
            setloadingtrue();
            let userId = LoginuserId;
            let viewId = ConfigurationView.viewId;
            let serviceID = ConfigurationView.serviceId;
            let status = row ? false : true;

            const momentStDate = moment(startDate, 'MM/DD/YYYY');
            const sStartDate = momentStDate.format('MM/DD/YYYY');

            const momentEnDate = moment(endDate, 'MM/DD/YYYY');
            const sEndDate = momentEnDate.format('MM/DD/YYYY');
            var days = moment(sEndDate).diff(sStartDate, 'days');

            if (viewId > 0) {
                DailyDataApi.HideHeatmap(status, viewId.toString(), userId)
                    .then((cnt: number) => {
                        setloadingtrue();
                        if (cnt > 0) {
                            requestDailyData(serviceID, sStartDate, sEndDate, viewId, activeDailyDataTab);
                            getHeatmapView(serviceID, activeDailyDataTab, 0, 0);
                            getReachabilityServices(serviceID, 0);
                            setloadingfalse();
                            toastIdRef.current = toast.success(
                                renderToastifyMsg('HeatMap Deactivated successfully!',
                                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                            );
                        }
                        else {
                            setloadingfalse();
                            toastIdRef.current = toast.error(
                                renderToastifyMsg('Error while hiding heatmap!',
                                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                            );
                        }

                    });
            }
        }

        const metricDataSourceInactive = (row) => {
            setloadingtrue();
            WeeklyAPI.updateMetricDataSourceInactive(row.serviceId, row.metricId, LoginuserId)
                .then((result: number) => {
                    if (result != -1) {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg('Metric DataSource data deleted Succesfully',
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                        if (selectedServiceval !== null) {
                            GetMetricDataSources(selectedServiceval);
                            resetFilters();
                            setloadingfalse();
                        }
                    }
                    else {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while updating",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
        const icmMetricDataSourceInactive = (row) => {
            setloadingtrue();
            WeeklyAPI.updateIcmMetricDataSourceInactive(row.serviceId, row.metricId, LoginuserId)
                .then((result: number) => {
                    if (result != -1) {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg('Metric DataSource data deleted Succesfully',
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                        if (selectedServiceval !== null) {
                            GetMetricDataSources(selectedServiceval);
                            resetFilters();
                            setloadingfalse();
                        }
                    }
                    else {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while updating",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }

        const addMetricDataSourceData = (row, operation) => {
            const authenticationValue = authenticationTypes.find(x => x.value == authenticationType).name;
            var dataConnectorId = 0;
            setloadingtrue();
            var trgt;
            if (target === null) {
                trgt = row.targetId;
            }
            else {
                if (typeof target !== "object") {
                    var targetelement = targetTypes.filter(item => item.name === target);
                    trgt = targetelement.length !== 0 ? targetelement[0].value : 1;
                }
                else {
                    trgt = target.value;
                }
            }
            if (authenticationValue == 'AAD AppId' || authenticationValue =='UserId') {
                dataConnectorId = 1;
            }
            else if (authenticationValue == 'Dsts Blackforest') {
                dataConnectorId = 6;
            }
            else if (authenticationValue == 'Dsts FairFax') {
                dataConnectorId = 8;
            }
            else if (authenticationValue == 'Dsts Mooncake') {
                dataConnectorId = 7;
            }

            const obj = {
                ServiceId: (selectedServiceval === null) ? row.serviceId : parseInt(selectedServiceval),
                MetricGroupId: (selectedMetricgroupval === null) ? row.metricGroupId : parseInt(selectedMetricgroupval),
                MetricId: (selectedMetric === null) ? row.metricId : parseInt(selectedMetric),
                EnvironmentId: (selectedEnvironment === null) ? row.environmentId : parseInt(selectedEnvironment),
                DataSourceId: (selectedDatasource === null) ? row.dataSourceId : parseInt(selectedDatasource),
                QueryOrUrl: queryOrUrl,
                AuthenicationType: typeof authenticationType !== "object" ? authenticationValue : authenticationType.value,
                KustoClusterName: serverName,
                kustoDBName: database,
                mdmMetricName: mdmMetricName,
                Notes: notes,
                UpdatedBy: LoginuserId,
                TargetId: trgt,
                NewMetricGroupName: newMetricGroupName,
                NewMetricsName: newMetricName,
                DataConnectorId: dataConnectorId
            };
            var insertorUpdateflag = operation === "UPDATE" ? 1 : 0;
            var toastmsg = operation === "UPDATE" ? "Updated" : "Added";
            let kustoquery = JSON.stringify({ "csl": queryOrUrl, "db": database });
            if (authenticationValue === 'AAD AppId') {
                DailyDataApi.ValidateKustoQuery({ "json": kustoquery, "apiName": serverName })
                    .then((result: string) => {
                        if (result.length > 0) {
                            setloadingfalse();
                            toastIdRef.current = toast.error(
                                renderToastifyMsg(result,
                                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4000 }
                            );
                        }
                        else {
                            skipKustoValidation(row, obj, toastmsg, insertorUpdateflag, operation);
                        }
                    });
            }
            else {
                skipKustoValidation(row, obj, toastmsg, insertorUpdateflag, operation);
            }
        }

        const skipKustoValidation = (row, obj, toastmsg, insertorUpdateflag, operation) => {
            if (isNaN(obj.MetricGroupId) && (obj.NewMetricGroupName != null) && isNaN(obj.MetricId)) {
                var metricGroupName = obj.NewMetricGroupName;
                AddNewMetricGroup(obj, metricGroupName);
            }
            else if (obj.MetricGroupId != null && isNaN(obj.MetricId)) {
                const metricobj = {
                    MetricDisplayName: obj.NewMetricsName,
                    MetricGroupId: obj.MetricGroupId,
                    TargetId: obj.TargetId,
                    DataSourceName: 'kusto',
                    EnvironmentId: obj.EnvironmentId
                }
                AddNewMetricName(obj, metricobj);
            }
            else {
                if (obj.MetricGroupId == 2 || obj.MetricGroupId == 3) {
                    WeeklyAPI.SaveICMDataSourceDetails(obj).then((result: number) => {
                        if (result != -1) {
                            toastIdRef.current = toast.success(
                                renderToastifyMsg('Metric DataSource data ' + toastmsg + ' Succesfully',
                                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                            );
                            if ((operation === "UPDATE" && row.targetId !== target.value) || (operation === "ADD")) {
                                WeeklyAPI.SaveTargetReferenceDetails(obj, insertorUpdateflag)
                                    .then((result: number) => {
                                        if (result === -1) {
                                            setloadingfalse();
                                            toastIdRef.current = toast.error(
                                                renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                                                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                                            );
                                        }
                                    }).catch(error => {
                                        setloadingfalse();
                                        toastIdRef.current = toast.error(
                                            renderToastifyMsg("Error while updating",
                                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                                        );
                                    });
                            }
                            GetMetricDataSources(selectedServiceval);
                            resetFilters();
                            clearMetricDataSourceData();
                            setloadingfalse();
                            setAddMetricDataSourceDisabled();
                            setViewMetricDataSourceEnabled();
                        }
                        else {
                            setloadingfalse();
                            toastIdRef.current = toast.error(
                                renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                            );
                        }
                    }).catch(error => {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Error while updating",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    });
                }
                else {
                    WeeklyAPI.SaveMetricDataSourceDetails(obj)
                        .then((result: number) => {
                            if (result != -1) {
                                toastIdRef.current = toast.success(
                                    renderToastifyMsg('Metric DataSource data ' + toastmsg + ' Succesfully',
                                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                                );
                                if ((operation === "UPDATE" && row.targetId !== target.value) || (operation === "ADD")) {
                                    WeeklyAPI.SaveTargetReferenceDetails(obj, insertorUpdateflag)
                                        .then((result: number) => {
                                            if (result === -1) {
                                                setloadingfalse();
                                                toastIdRef.current = toast.error(
                                                    renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                                                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                                                );
                                            }
                                        }).catch(error => {
                                            setloadingfalse();
                                            toastIdRef.current = toast.error(
                                                renderToastifyMsg("Error while updating",
                                                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                                            );
                                        });
                                }
                                GetMetricDataSources(selectedServiceval);
                                resetFilters();
                                clearMetricDataSourceData();
                                setloadingfalse();
                                setAddMetricDataSourceDisabled();
                                setViewMetricDataSourceEnabled();
                            }
                            else {
                                setloadingfalse();
                                toastIdRef.current = toast.error(
                                    renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                                );
                            }
                        }).catch(error => {
                            setloadingfalse();
                            toastIdRef.current = toast.error(
                                renderToastifyMsg("Error while updating",
                                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                            );
                        });
                }
            }

        }

        const AddNewMetricGroup = (obj, metricGroupName) => {
            WeeklyAPI.AddNewMetricGroup(metricGroupName)
                .then((result: number) => {
                    if (result > 0) {
                        const MetricGrpId = result;
                        const metricobj = {
                            MetricDisplayName: obj.NewMetricsName,
                            MetricGroupId: MetricGrpId,
                            TargetId: obj.TargetId,
                            DataSourceName: 'kusto',
                            EnvironmentId: obj.EnvironmentId
                        }
                        const objSaveMetricDate = obj;
                        AddNewMetricName(objSaveMetricDate, metricobj);
                    }
                    else {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Error while Adding New Metric Group",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                });
        }

        const AddNewMetricName = (objSaveMetricDate, metricobj) => {
            WeeklyAPI.AddNewMetric(metricobj)
                .then((result: number) => {
                    if (result > 1) {
                        const obj = {
                            ServiceId: objSaveMetricDate.ServiceId,
                            MetricGroupId: (objSaveMetricDate.MetricGroupId === null || isNaN(objSaveMetricDate.MetricGroupId)) ? metricobj.MetricGroupId : objSaveMetricDate.MetricGroupId,
                            MetricId: (objSaveMetricDate.MetricId === null || isNaN(objSaveMetricDate.MetricId)) ? result : objSaveMetricDate.MetricId,
                            EnvironmentId: objSaveMetricDate.EnvironmentId,
                            DataSourceId: objSaveMetricDate.DataSourceId,
                            QueryOrUrl: objSaveMetricDate.QueryOrUrl,
                            AuthenicationType: objSaveMetricDate.AuthenicationType,
                            KustoClusterName: objSaveMetricDate.KustoClusterName,
                            kustoDBName: objSaveMetricDate.kustoDBName,
                            mdmMetricName: objSaveMetricDate.mdmMetricName,
                            Notes: objSaveMetricDate.Notes,
                            UpdatedBy: objSaveMetricDate.UpdatedBy,
                            TargetId: objSaveMetricDate.TargetId,
                            DataConnectorId: objSaveMetricDate.DataConnectorId
                        }
                        if (obj.MetricGroupId == 2 || obj.MetricGroupId == 3) {
                            WeeklyAPI.SaveICMDataSourceDetails(obj).then((result: number) => {
                                if (result != -1) {
                                    toastIdRef.current = toast.success(
                                        renderToastifyMsg('Metric DataSource data added Succesfully',
                                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                                    );
                                    var insertorUpdateflag = 0;
                                    WeeklyAPI.SaveTargetReferenceDetails(obj, insertorUpdateflag)
                                        .then((result: number) => {
                                            if (result === -1) {
                                                setloadingfalse();
                                                toastIdRef.current = toast.error(
                                                    renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                                                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                                                );
                                            }
                                        }).catch(error => {
                                            setloadingfalse();
                                            toastIdRef.current = toast.error(
                                                renderToastifyMsg("Error while updating",
                                                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                                            );
                                        });
                                    const selectedServiceval = obj.ServiceId.toString();
                                    GetMetricDataSources(selectedServiceval);
                                    resetFilters();
                                    clearMetricDataSourceData();
                                    setloadingfalse();
                                    setAddMetricDataSourceDisabled();
                                    setViewMetricDataSourceEnabled();
                                }
                                else {
                                    setloadingfalse();
                                    toastIdRef.current = toast.error(
                                        renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                                    );
                                }
                            }).catch(error => {
                                setloadingfalse();
                                toastIdRef.current = toast.error(
                                    renderToastifyMsg("Error while updating",
                                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                                );
                            });
                        }
                        else {
                            WeeklyAPI.SaveMetricDataSourceDetails(obj)
                                .then((result: number) => {
                                    if (result != -1) {
                                        toastIdRef.current = toast.success(
                                            renderToastifyMsg('Metric DataSource data added Succesfully',
                                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                                        );
                                        var insertorUpdateflag = 0;
                                        WeeklyAPI.SaveTargetReferenceDetails(obj, insertorUpdateflag)
                                            .then((result: number) => {
                                                if (result === -1) {
                                                    setloadingfalse();
                                                    toastIdRef.current = toast.error(
                                                        renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                                                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                                                    );
                                                }
                                            }).catch(error => {
                                                setloadingfalse();
                                                toastIdRef.current = toast.error(
                                                    renderToastifyMsg("Error while updating",
                                                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                                                );
                                            });
                                        const selectedServiceval = obj.ServiceId.toString();
                                        GetMetricDataSources(selectedServiceval);
                                        resetFilters();
                                        clearMetricDataSourceData();
                                        setloadingfalse();
                                        setAddMetricDataSourceDisabled();
                                        setViewMetricDataSourceEnabled();
                                    }
                                    else {
                                        setloadingfalse();
                                        toastIdRef.current = toast.error(
                                            renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                                        );
                                    }
                                }).catch(error => {
                                    setloadingfalse();
                                    toastIdRef.current = toast.error(
                                        renderToastifyMsg("Error while updating",
                                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                                    );
                                });
                        }
                    }
                    else {
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Error while Adding New Metric",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                });
        }

        const UpdateViewConfigurationData = () => {
            if (ConfigurationView.drillDownQuery != '' && ConfigurationView.drillDownQuery != null) {
                if (ConfigurationView.drillDownQuery.indexOf('todatetime(<<datetime>>)') == -1) {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Drilldown query does not have date parameter to pass from Heatmap view to Kusto web Explorer. Please provide the date parameter in 'todatetime(<<datetime>>)' format",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4000 }
                    );
                    return false;
                } else if (ConfigurationView.drillDownQuery.indexOf('<<datacenter>>') == -1) {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Drilldown query does not have Key/Datacenter parameter to pass from Heatmap view to Kusto web Explorer. Please provide the key/datacenter parameter in '<<datacenter>>' format",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4000 }
                    );
                    return false;
                }
            }
            if (ConfigurationView.authenticationType == 'AAD AppId') {
                let kustoquery = JSON.stringify({ "csl": ConfigurationView.fixedColQuery, "db": ConfigurationView.kustoDbName });
                setloadingtrue();
                DailyDataApi.ValidateKustoQuery({ "json": kustoquery, "apiName": ConfigurationView.kustoServer })
                    .then((result: string) => {
                        if (result.length > 0) {
                            setloadingfalse();
                            toastIdRef.current = toast.error(
                                renderToastifyMsg(result,
                                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4000 }
                            );
                        }
                        else {
                            kustoquery = JSON.stringify({ "csl": ConfigurationView.valQuery, "db": ConfigurationView.kustoDbName });
                            DailyDataApi.ValidateKustoQuery({ "json": kustoquery, "apiName": ConfigurationView.kustoServer })
                                .then((result: string) => {
                                    if (result.length > 0) {
                                        setloadingfalse();
                                        toastIdRef.current = toast.error(
                                            renderToastifyMsg(result,
                                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4000 }
                                        );
                                    }
                                    else {
                                        setloadingtrue();
                                        AddorUpdateConfigViewData();
                                    }
                                });

                        }
                    });
            }
            else {
                setloadingtrue();
                AddorUpdateConfigViewData();
            }


        }
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' }
            ],
            BLOCK_TYPE_DROPDOWN: [
                { label: 'Normal', style: 'unstyled' },
                { label: 'Heading Large', style: 'header-one' },
                { label: 'Heading Medium', style: 'header-two' },
                { label: 'Heading Small', style: 'header-three' }
            ],
            BLOCK_TYPE_BUTTONS: [
                { label: 'UL', style: 'unordered-list-item' },
                { label: 'OL', style: 'ordered-list-item' }
            ]
        };

        //---------- get previous monday -------------//
        const getPreviousMonday = () => {
            var today = new Date(); // "07/23/2016"   
            if (!today.getDay()) {
                return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 15);
            }

            return new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 6);
        }

        const ChangeChkBulkEdit = (e) => {
            //SetBulkEdit(e.target.checked);
            handleBulkEditCheck(e);
            setbulkcellcount(0);
        }

        const valchange = (e, childkey, parentkey) => {
            dailyData.map((obj: IDailyData, parent) => {
                if (parentkey === parent) {
                    obj.metricList.map((hmm: HmMetric, child) => {
                        if (childkey === child) {
                            hmm.IsshowBulk = e.target.checked;
                        }

                    })
                }

            });
        }
        const RemoveBulkEditSelection = () => {
            SetBulkEdit(false);
            dailyData.map((obj: IDailyData) => {
                obj.metricList.map((hmm: HmMetric) => {
                    if (hmm.IsshowBulk) {
                        hmm.IsshowBulk = false;
                    }
                })
            });
        }
        const handleClose = () => setShow(false);
        const handleCloseBulkEdit = () => {

            SetBulkViewIds([]);
            handleBulkEditCheck(false);
            setShowBulkEdit(false);
        }
        //=========== function for enable or disble Single Popup buttons=====//
        const EnableordisableSinglePopupbuttons = (comment, qos, uptime, commentid) => {
            let view = (activeDailyDataTab == 'heatmap') ? SelectedView[0] : ReachabilitySelectedView[0];
            if (comment !== '' && comment !== undefined) {
                setDisablecommentbutton(false);
                setDisableDeletebutton(false);
            }
            else {
                setDisablecommentbutton(true);

            }
            if (commentid === null || commentid === 0 || commentid === '') {
                setDisableDeletebutton(true);
            }
            else {
                setDisableDeletebutton(false);
            }

            if (uptime) {
                if (view.hasVal2) {
                    if (qos) {
                        setDisablesubmitbutton(false);

                    }
                    else {
                        setDisablesubmitbutton(true);

                    }
                }
                else {
                    setDisablesubmitbutton(false);

                }
            } else {
                setDisablesubmitbutton(true);
            }
        }

        //=========== function for enable or disble Single Popup buttons related to Incident Details=====//
        const EnableordisableincidentRelatedPopupbuttons = (Incident, status, problemStatement, owningTeam) => {
            if (Incident === '' || Incident === undefined || Incident === null) {
                setDisableIncidentSubmitButton(false);
            }
            else {
                if (status === null || status === undefined || status === '') {
                    setDisableIncidentSubmitButton(true);
                }
                else if (problemStatement === null || problemStatement === undefined || problemStatement === '') {
                    setDisableIncidentSubmitButton(true);
                }
                else if (owningTeam === null || owningTeam === undefined || owningTeam === '') {
                    setDisableIncidentSubmitButton(true);
                }
                else {
                    setDisableIncidentSubmitButton(false);
                }
            }
        }

        //=== Opening Single Popup Window ========//
        const handleShow = (e, Item, datacenter, chkBulkEdit) => {
            const cellobj = [];
            cellobj.push(Item);
            setIncidentIDPresent(false);
            if (cellobj[0].commentId === null || cellobj[0].commentId === undefined) {
                Item.commentId = '';
            }
            const obj: any = [];

            if (cellobj[0].value.value2 === null || cellobj[0].value.value2 === undefined) {
                cellobj[0].value.value2 = '';
            }
            else {
                if (cellobj[0].value.value2 !== "") {
                    cellobj[0].value.value2 = parseFloat(Item.value.value2).toFixed(Item.value.displayDecimals);
                }
            }
            if (cellobj[0].updatedValue1 === undefined || cellobj[0].updatedValue1 === null) {
                cellobj[0].updatedValue1 = '';
            }
            if (cellobj[0].updatedValue2 === undefined || cellobj[0].updatedValue2 === null) {
                cellobj[0].updatedValue2 = '';
            }
            if (cellobj[0].lastUpdated !== undefined && cellobj[0].lastUpdated !== null && cellobj[0].lastUpdated !== '') {
                cellobj[0].lastUpdated = moment(cellobj[0].lastUpdated.lastUpdated).format('Do MMM YYYY').toString();
            }
            if (cellobj[0].incident == 0) {
                cellobj[0].incident = '';
            }
            else {
                if (cellobj[0].incident === null || cellobj[0].incident === undefined) {
                    setIncidentIDPresent(false);
                }
                else {
                    setIncidentIDPresent(true);
                }
            }
            if (cellobj[0].status === undefined || cellobj[0].status === null) {
                cellobj[0].status = '';
            }
            if (cellobj[0].problemStatement === undefined || cellobj[0].problemStatement === null) {
                cellobj[0].problemStatement = '';
            }
            if (cellobj[0].rootCause === undefined || cellobj[0].rootCause === null) {
                cellobj[0].rootCause = '';
            }
            if (cellobj[0].owningTeam === undefined || cellobj[0].owningTeam === null) {
                cellobj[0].owningTeam = '';
            }
            if (cellobj[0].impact === undefined || cellobj[0].impact === null) {
                cellobj[0].impact = '';
            }
            setIncidentCheck(false);
            obj.push({ 'dateKey': cellobj[0].dateKey, 'id': cellobj[0].id, 'datacenter': datacenter, 'commentId': cellobj[0].commentId, 'comment': cellobj[0].comment, 'incident': cellobj[0].incident, 'status': cellobj[0].status, 'problemStatement': cellobj[0].problemStatement, 'rootCause': cellobj[0].rootCause, 'owningTeam': cellobj[0].owningTeam, 'impact': cellobj[0].impact, 'author': cellobj[0].author, 'omodifieduptime': cellobj[0].updatedValue1, 'oModifiedQos': Item.updatedValue2, 'umodifieduptime': cellobj[0].updatedValue1, 'uModifiedQos': cellobj[0].updatedValue2 });
            setActiveCell(cellobj[0]);
            setCommentObj(obj[0]);
            setActiveView(viewdetailsdata[0]);
            if (activeDailyDataTab === 'heatmap') {
                setSelected_View(SelectedView[0]);
            }
            else if (activeDailyDataTab === 'reachability') {
                setSelected_View(ReachabilitySelectedView[0]);
            }

            //if (ActiveCell !== undefined && ActiveCell !== null) {
            //    ActiveCell.updatedValue1 = ActiveCell.value.value1;
            //    if (Selected_View.hasVal2) {
            //        if (ActiveCell.value.value2 !== "" && ActiveCell.value.value2 !== undefined && ActiveCell.value.value2 !== null) {
            //            ActiveCell.updatedValue2 = ActiveCell.value.value2;
            //        }
            //    }
            //    else {
            //        ActiveCell.updatedValue2 = '';
            //    }
            //}
            if (chkBulkEdit === true) {

                Item.IsshowBulk = !Item.IsshowBulk;
                if (Item.IsshowBulk===true) {
                    setbulkcellcount(bulkcellcount + 1);
                }
                else {
                    if (bulkcellcount > 0) {
                        setbulkcellcount(bulkcellcount-1);
                    }
                }


            }
            else {
                EnableordisableSinglePopupbuttons(cellobj[0].comment, cellobj[0].updatedValue2, cellobj[0].updatedValue1, cellobj[0].commentId);
                EnableordisableincidentRelatedPopupbuttons(cellobj[0].incident, cellobj[0].status, cellobj[0].problemStatement, cellobj[0].owningTeam);
                setShow(true);
            }


        }
        //============== function for   =================//
        const CommentSummary = (metric, key) => {

            if (metric !== undefined) {
                const commentId = metric.commentId;
                var result: any;
                if (commentId > 0) {
                    const filterByKey = [];
                    if (activeDailyDataTab === 'heatmap') {
                        if (isArrayWithLength(dailyData)) {
                            dailyData.map((DD: IDailyData, val) => {
                                if (DD.key === key) {
                                    filterByKey.push(DD);

                                }
                            })
                            // if (filterByKey !== undefined && filterByKey !== null) {
                            if (isArrayWithLength(filterByKey)) {
                                filterByKey[0].metricList.map((mt: HmMetric, val) => {
                                    if (moment(mt.dateKey, 'YYYY-MM-DD').isSame(moment(metric.dateKey).add(-1, 'days'), 'day') && mt.commentId === commentId) {
                                        return true;
                                    }

                                })
                            }
                            comments.map((com: IComments, val) => {
                                if (com.commentId === commentId) {
                                    result = val + 1;

                                }
                            })
                            return result;
                        }
                    }
                    else {
                        if (isArrayWithLength(ReachabilityData)) {
                            ReachabilityData.map((DD: IDailyData, val) => {
                                if (DD.key === key) {
                                    filterByKey.push(DD);

                                }
                            })
                            // if (filterByKey !== undefined && filterByKey !== null) {
                            if (isArrayWithLength(filterByKey)) {
                                filterByKey[0].metricList.map((mt: HmMetric, val) => {
                                    if (moment(mt.dateKey, 'YYYY-MM-DD').isSame(moment(metric.dateKey).add(-1, 'days'), 'day') && mt.commentId === commentId) {
                                        return true;
                                    }

                                })
                            }
                            Reachabilitycomments.map((com: IComments, val) => {
                                if (com.commentId === commentId) {
                                    result = val + 1;

                                }
                            })
                            return result;
                        }
                    }

                }
            }
        }

        //============== function for Multiple View HeatMap comment Summary  =================//
        const CommentSummaryMultiView = (metric, key, viewitem) => {
            if (metric !== undefined) {
                const commentId = metric.commentId;
                var result: any;
                if (commentId > 0) {
                    const filterByKey = [];
                    if (isArrayWithLength(mvdailyData)) {
                        mvdailyData.map((DD: IDailyData, val) => {
                            if (DD.key === key) {
                                filterByKey.push(DD);

                            }
                        })
                        // if (filterByKey !== undefined && filterByKey !== null) {
                        if (isArrayWithLength(filterByKey)) {
                            filterByKey[0].metricList.map((mt: HmMetric, val) => {
                                if (moment(mt.dateKey, 'YYYY-MM-DD').isSame(moment(metric.dateKey).add(-1, 'days'), 'day') && mt.commentId === commentId) {
                                    return true;
                                }

                            })
                        }
                        mvcomments.filter((ele) => {
                            if (parseInt(ele.viewId) === parseInt(viewitem.viewId)) {
                                return true
                            }
                        }).map((com: IComments, index) => {
                            if (com.commentId === commentId) {
                                result = index + 1;
                            }
                        });
                        return result;
                    }
                }
            }
        }

        const changeBulkComments = (event) => {
            SetBulkComment(event.target.value);
            if (event.target.value === undefined || event.target.value === '') {
                setDisablecommentbutton(true);
                SetBulkCommentId('');
                setDisablesubmitbutton(true);

            }
            else {
                setDisablecommentbutton(false);
                setDisablesubmitbutton(false);

            }
        }

        const changeBulkViewSelection = (event) => {
            SetBulkViews(event.target.value);


        }
        const changeBulkCommentsId = (event) => {
            if (event.target.selectedIndex > 0) {
                SetBulkComment(comments[event.target.selectedIndex - 1].comment);
                setDisablecommentbutton(false);
                setDisablesubmitbutton(false);

            }
            else {
                SetBulkComment('');
                setDisablecommentbutton(true);
                setDisablesubmitbutton(true);

            }
            SetBulkCommentId(event.target.value);


        }
        const ClearComment = () => {
            setCommentObj({ 'dateKey': CommentObj.dateKey, 'id': CommentObj.id, 'datacenter': CommentObj.datacenter, 'commentId': '', 'comment': '', 'incident': CommentObj.incident, 'status': CommentObj.status, 'problemStatement': CommentObj.problemStatement, 'rootCause': CommentObj.rootCause, 'owningTeam': CommentObj.owningTeam, 'impact': CommentObj.impact, 'author': CommentObj.author, 'omodifieduptime': CommentObj.omodifieduptime, 'oModifiedQos': CommentObj.oModifiedQos, 'umodifieduptime': CommentObj.umodifieduptime, 'uModifiedQos': CommentObj.uModifiedQos });
        }

        //===== function for validating the Incident and fetching the Incident related data if the ICM incident is Valid =========//
        const FetchIncidentDetails = () => {
            if (CommentObj.incident === '') {
                SetIsloading(true);
                setIncidentCheck(false);
                CommentObj.status = "";
                CommentObj.problemStatement = "";
                CommentObj.owningTeam = "";
                EnableordisableincidentRelatedPopupbuttons(CommentObj.incident, CommentObj.status, CommentObj.problemStatement, CommentObj.owningTeam);
                setCommentObj({ 'dateKey': CommentObj.dateKey, 'id': CommentObj.id, 'datacenter': CommentObj.datacenter, 'commentId': CommentObj.commentId, 'comment': CommentObj.comment, 'incident': CommentObj.incident, 'status': CommentObj.status, 'problemStatement': CommentObj.problemStatement, 'rootCause': CommentObj.rootCause, 'owningTeam': CommentObj.owningTeam, 'impact': CommentObj.impact, 'author': CommentObj.author, 'omodifieduptime': CommentObj.omodifieduptime, 'oModifiedQos': CommentObj.oModifiedQos, 'umodifieduptime': CommentObj.umodifieduptime, 'uModifiedQos': CommentObj.uModifiedQos });
                SetIsloading(false);
            }
            if (CommentObj.incident !== '' && CommentObj.incident !== undefined && CommentObj.incident !== null) {
                // Condition check when Ctrl+V done and an invalid Input for Incident
                if (!(/^[0-9]*$/.test(CommentObj.incident))) {
                    setIncidentCheck(true);
                }
                else {
                    SetIsloading(true);
                    DailyDataApi.HeatmapIncidentRelatedData(CommentObj, LoginuserId)
                        .then((IncidentCheckmetricdata: HmMetric) => {
                            if (IncidentCheckmetricdata.problemStatement === '') {
                                SetIsloading(false);
                                setIncidentCheck(true);
                            }
                            else {
                                setIncidentCheck(false);
                                if (CommentObj.comment == '') {
                                    CommentObj.comment = IncidentCheckmetricdata.problemStatement;
                                }
                                CommentObj.status = IncidentCheckmetricdata.status;
                                CommentObj.problemStatement =IncidentCheckmetricdata.problemStatement;
                                CommentObj.owningTeam =IncidentCheckmetricdata.owningTeam;
                            }
                            SetIsloading(false);
                            EnableordisableincidentRelatedPopupbuttons(CommentObj.incident, CommentObj.status, CommentObj.problemStatement, CommentObj.owningTeam);
                            setCommentObj({ 'dateKey': CommentObj.dateKey, 'id': CommentObj.id, 'datacenter': CommentObj.datacenter, 'commentId': CommentObj.commentId, 'comment': CommentObj.comment, 'incident': CommentObj.incident, 'status': CommentObj.status, 'problemStatement': CommentObj.problemStatement, 'rootCause': CommentObj.rootCause, 'owningTeam': CommentObj.owningTeam, 'impact': CommentObj.impact, 'author': CommentObj.author, 'omodifieduptime': CommentObj.omodifieduptime, 'oModifiedQos': CommentObj.oModifiedQos, 'umodifieduptime': CommentObj.umodifieduptime, 'uModifiedQos': CommentObj.uModifiedQos });
                        });
                }
            }
        }
        //===== function for change state variables for Single POPup date =========//
        const changeComments = (event, val) => {
            var uptime = CommentObj.umodifieduptime;
            var comment = CommentObj.comment;
            var commentID = CommentObj.commentId;
            var QOS = CommentObj.uModifiedQos;
            var Incident = CommentObj.incident;
            var status = CommentObj.status;
            var problemStatement = CommentObj.problemStatement;
            var rootCause = CommentObj.rootCause;
            var owningTeam = CommentObj.owningTeam;
            var impact = CommentObj.impact;
            //const numcheck =/^\d+(\.\d{1,5})?$/;
            if (val === 'CID') {
                commentID = event.target.value;
                if (event.target.selectedIndex > 0) {
                    if (activeDailyDataTab === 'reachability') {
                        comment = Reachabilitycomments[event.target.selectedIndex - 1].comment;
                        Incident = Reachabilitycomments[event.target.selectedIndex - 1].incident;
                        if (Incident !== 0 && Incident !== null) {
                            setIncidentIDPresent(true);
                        }
                        status = Reachabilitycomments[event.target.selectedIndex - 1].status;
                        problemStatement = Reachabilitycomments[event.target.selectedIndex - 1].problemStatement;
                        rootCause = Reachabilitycomments[event.target.selectedIndex - 1].rootCause;
                        owningTeam = Reachabilitycomments[event.target.selectedIndex - 1].owningTeam;
                        impact = Reachabilitycomments[event.target.selectedIndex - 1].impact;
                    }
                    else {
                        comment = comments[event.target.selectedIndex - 1].comment;
                        Incident = comments[event.target.selectedIndex - 1].incident;
                        if (Incident !== 0 && Incident !== null) {
                            setIncidentIDPresent(true);
                        }
                        status = comments[event.target.selectedIndex - 1].status;
                        problemStatement = comments[event.target.selectedIndex - 1].problemStatement;
                        rootCause = comments[event.target.selectedIndex - 1].rootCause;
                        owningTeam = comments[event.target.selectedIndex - 1].owningTeam;
                        impact = comments[event.target.selectedIndex - 1].impact;
                    }
                }
                else {
                    comment = '';
                }
            }
            else if (val === 'uptime') {
                //uptime = numcheck.test(event.target.value) ? event.target.value : '';
                // uptime = (uptime === undefined) ? '' : uptime;
                uptime = event.target.value;
                uptime = (uptime === undefined) ? '' : uptime;

            }
            else if (val === 'comments') {
                comment = event.target.value;

            }
            else if (val === 'QOS') {
                //QOS = numcheck.test(event.target.value) ? event.target.value : '';
                QOS = event.target.value;
                QOS = (QOS === undefined) ? '' : QOS;
            }
            else if (val === 'Incident') {
                Incident = event.target.value;
                setIncidentIDPresent(true);
                if (Incident === '' || Incident === null || Incident === undefined) {
                    setIncidentCheck(false);
                    setIncidentIDPresent(false);
                }
            }
            else if (val === 'status') {
                status = event.target.value;
            }
            else if (val === 'problemStatement') {
                problemStatement = event.target.value;
            }
            else if (val === 'rootCause') {
                rootCause = event.target.value;
            }
            else if (val === 'owningTeam') {
                owningTeam = event.target.value;
            }
            else if (val === 'impact') {
                impact = event.target.value;
            }
            EnableordisableSinglePopupbuttons(comment, QOS, uptime, commentID);
            EnableordisableincidentRelatedPopupbuttons(Incident, status, problemStatement, owningTeam);
            setCommentObj({ 'dateKey': CommentObj.dateKey, 'id': CommentObj.id, 'datacenter': CommentObj.datacenter, 'commentId': commentID, 'comment': comment, 'incident': Incident, 'status': status, 'problemStatement': problemStatement, 'rootCause': rootCause, 'owningTeam': owningTeam, 'impact': impact, 'author': CommentObj.author, 'omodifieduptime': CommentObj.omodifieduptime, 'oModifiedQos': CommentObj.oModifiedQos, 'umodifieduptime': uptime, 'uModifiedQos': QOS });
        }
        //==================== function to submit bulk comments -=========//
        const SubmitBulkComments = () => {

            var xml = '<List>';
            BulkEditObjs.map((obj) => {
                xml = xml + '<Row Id=\'' + obj.id + '\' Datacenter=\'' + obj.datacenter + '\'     />';

            });
            xml = xml + '</List>'
            var viewids = '';
            if (isArrayWithLength(BulkViewIds)) {
                BulkViewIds.map((obj) => {
                    if (viewids === '') {
                        viewids = obj.id;
                    }
                    else {
                        viewids = viewids + ',' + obj.id;
                    }
                });
            }
            else {
                viewids = SelectedView[0].viewId;
            }
            setloadingtrue();
            DailyDataApi.UpdateCommentsForBulkViews(selectedServiceId, BulkComment, (BulkCommentId === '') ? 0 : parseInt(BulkCommentId), viewids, xml, LoginuserId)
                .then((BulkupdateResponse: number) => {
                    if (BulkupdateResponse > 0) {
                        if (!toast.isActive(toastIdRef.current)) {
                            toastIdRef.current = toast.success(
                                renderToastifyMsg('Comments updated successfully',
                                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                            );

                        }
                        handleBulkEditCheck(false);
                        BulkupdateResponse = null;
                        requestDailyData(selectedServiceId, moment(startDate, 'MM/DD/YYYY').format('MM/DD/YYYY'), moment(endDate, 'MM/DD/YYYY').format('MM/DD/YYYY'), SelectedView[0].viewId, activeDailyDataTab);
                        SetBulkViewIds([]);
                        handleCloseBulkEdit();

                    }
                    else if (BulkupdateResponse !== null) {
                        setloadingfalse();
                        if (!toast.isActive(toastIdRef.current)) {
                            toastIdRef.current = toast.error(
                                renderToastifyMsg('Comments updated successfully',
                                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                            );

                        }
                        // alert('Error while updating');
                    }
                });

        }
        //================== Function to delete bulk comments ====================//
        const DeleteBulkComments = () => {

            var xml = '<List>';
            BulkEditObjs.map((obj) => {
                xml = xml + '<Row Id=\'' + obj.id + '\' Datacenter=\'' + obj.datacenter + '\'     />';

            });
            xml = xml + '</List>'
            var viewids = '';

            if (isArrayWithLength(BulkViewIds)) {
                BulkViewIds.map((obj) => {
                    if (viewids === '') {
                        viewids = obj.id;
                    }
                    else {
                        viewids = viewids + ',' + obj.id;
                    }
                });
            }
            else {
                viewids = SelectedView[0].viewId;
            }
            setloadingtrue();
            DailyDataApi.DeleteCommentsForBulkViews(selectedServiceId, BulkComment, (BulkCommentId === '') ? 0 : parseInt(BulkCommentId), viewids, xml, LoginuserId)
                .then((BulkupdateResponse: number) => {
                    if (BulkupdateResponse > 0) {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg('Comments Deleted successfully',
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                        );
                        handleBulkEditCheck(false);
                        BulkupdateResponse = null;
                        requestDailyData(selectedServiceId, moment(startDate, 'MM/DD/YYYY').format('MM/DD/YYYY'), moment(endDate, 'MM/DD/YYYY').format('MM/DD/YYYY'), SelectedView[0].viewId, activeDailyDataTab);
                        handleCloseBulkEdit();
                    }
                    else if (BulkupdateResponse !== null) {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg('Error while Deleting',
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                        );

                    }
                });

        }
        //==================== function to submit comments for single Edit ===========//
        const SubmitComments = () => {
            CommentObj.commentId = parseInt((CommentObj.commentId === '' || CommentObj.commentId === undefined) ? 0 : CommentObj.commentId);
            CommentObj.comment = (CommentObj.comment === undefined || CommentObj.comment === null) ? '' : CommentObj.comment;
            CommentObj.status = (CommentObj.status === undefined || CommentObj.status === null) ? '' : CommentObj.status;
            CommentObj.problemStatement = (CommentObj.problemStatement === undefined || CommentObj.problemStatement === null) ? '' : CommentObj.problemStatement;
            CommentObj.rootCause = (CommentObj.rootCause === undefined || CommentObj.rootCause === null) ? '' : CommentObj.rootCause;
            CommentObj.owningTeam = (CommentObj.owningTeam === undefined || CommentObj.owningTeam === null) ? '' : CommentObj.owningTeam;
            CommentObj.impact = (CommentObj.impact === undefined || CommentObj.impact === null) ? '' : CommentObj.impact;
            let serviceid;
            if (activeDailyDataTab === 'reachability') {
                serviceid = selectedServiceDropdownOption.value;
            }
            else {
                serviceid = selectedServiceId;
            }
            if (CommentObj.incident === null) {
                CommentObj.incident = '';
            }
            if (CommentObj.failureVolume === null) {
                CommentObj.failureVolume = '';
            }

            if (!(/^[0-9]*$/.test(CommentObj.incident))) { // Condition check when Ctrl+V done and an invalid Input for Incident
                setIncidentCheck(true);
            }
            else {
                if (ActiveCell.id !== 0) {
                    if (CommentObj.incident !== '' && CommentObj.incident !== undefined && CommentObj.incident !== null) {
                        SetIsloading(true);
                        DailyDataApi.HeatmapIncidentTitleData(CommentObj, LoginuserId)
                            .then((IncidentCheckmetricdata: HmMetric) => {
                                if (IncidentCheckmetricdata.comment === '') {
                                    SetIsloading(false);
                                    setIncidentCheck(true);
                                }
                                else {
                                    if (CommentObj.comment == '') {
                                        CommentObj.comment = IncidentCheckmetricdata.comment;
                                    }
                                    DailyDataApi.UpdateHeatmapCommentDataAsyn(CommentObj, LoginuserId)
                                        .then((metricReponse) => {
                                            if (metricReponse > 0) {
                                                toastIdRef.current = toast.success(
                                                    renderToastifyMsg('Comments updated successfully',
                                                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                                                );
                                                requestDailyData(serviceid, moment(startDate, 'MM/DD/YYYY').format('MM/DD/YYYY'), moment(endDate, 'MM/DD/YYYY').format('MM/DD/YYYY'), selectedViewDropdownOption.value, activeDailyDataTab);
                                                setShow(false);
                                            }
                                            else {
                                                toastIdRef.current = toast.error(
                                                    renderToastifyMsg('Error while updating',
                                                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                                                );
                                            }
                                            SetIsloading(false);
                                        });

                                }
                            });
                    }
                    else {
                        SetIsloading(true);
                        DailyDataApi.UpdateHeatmapCommentDataAsyn(CommentObj, LoginuserId)
                            .then((metricReponse) => {
                                if (metricReponse > 0) {
                                    toastIdRef.current = toast.success(
                                        renderToastifyMsg('Comments updated successfully',
                                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                                    );
                                    requestDailyData(serviceid, moment(startDate, 'MM/DD/YYYY').format('MM/DD/YYYY'), moment(endDate, 'MM/DD/YYYY').format('MM/DD/YYYY'), selectedViewDropdownOption.value, activeDailyDataTab);
                                    setShow(false);
                                }
                                else {
                                    toastIdRef.current = toast.error(
                                        renderToastifyMsg('Error while updating',
                                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                                    );
                                }

                                SetIsloading(false);
                            });
                    }


                }
                else {
                    if (CommentObj.incident !== '' && CommentObj.incident !== undefined && CommentObj.incident !== null) {
                        //HeatmapIncidentTitleData(ActiveCell);

                        SetIsloading(true);

                        DailyDataApi.HeatmapIncidentTitleData(CommentObj, LoginuserId)
                            .then((IncidentCheckmetricdata: HmMetric) => {
                                if (IncidentCheckmetricdata.comment === '') {
                                    SetIsloading(false);
                                    setIncidentCheck(true);
                                }
                                else {
                                    if (CommentObj.comment == '') {
                                        CommentObj.comment = IncidentCheckmetricdata.comment;
                                    }
                                    DailyDataApi.AddHeapmapCommentDataAsyn(CommentObj, serviceid, selectedViewDropdownOption.value, CommentObj.datacenter, LoginuserId)
                                        .then((metricReponse) => {
                                            if (metricReponse > 0) {
                                                toastIdRef.current = toast.success(
                                                    renderToastifyMsg('Comments updated successfully',
                                                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                                                );
                                                requestDailyData(serviceid, moment(startDate, 'MM/DD/YYYY').format('MM/DD/YYYY'), moment(endDate, 'MM/DD/YYYY').format('MM/DD/YYYY'), selectedViewDropdownOption.value, activeDailyDataTab);
                                                setShow(false);
                                            }
                                            else {
                                                toastIdRef.current = toast.error(
                                                    renderToastifyMsg('Error while updating',
                                                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                                                );
                                            }
                                            SetIsloading(false);
                                        });

                                }
                            });
                    }
                    else {
                        SetIsloading(true);
                        DailyDataApi.AddHeapmapCommentDataAsyn(CommentObj, serviceid, selectedViewDropdownOption.value, CommentObj.datacenter, LoginuserId)
                            .then((metricReponse) => {

                                if (metricReponse > 0) {
                                    toastIdRef.current = toast.success(
                                        renderToastifyMsg('Comments updated successfully',
                                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                                    );
                                    requestDailyData(serviceid, moment(startDate, 'MM/DD/YYYY').format('MM/DD/YYYY'), moment(endDate, 'MM/DD/YYYY').format('MM/DD/YYYY'), selectedViewDropdownOption.value, activeDailyDataTab);
                                    setShow(false);
                                }
                                else {
                                    toastIdRef.current = toast.error(
                                        renderToastifyMsg('Error while updating',
                                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                                    );
                                }
                                SetIsloading(false);
                            });
                    }

                }
            }
        }
        //================ FUNCTION TO OPEN bULK eDIT pOPUP=========//
        const OpenBulkEditPOPUP = () => {
            const list: any = [];
            var SelectedValues = false;
            var datacenters = '';
            dailyData.map((obj: IDailyData) => {
                obj.metricList.map((hmm: HmMetric) => {
                    if (hmm.IsshowBulk === true) {
                        if (datacenters === '') {
                            datacenters = hmm.id.toString();
                        }
                        else {
                            datacenters = datacenters + ',' + hmm.id.toString();
                        }
                        list.push({ 'id': hmm.id, 'date': moment(hmm.dateKey).format('MM/DD'), 'datacenter': obj.key, 'commentId': hmm.commentId, 'comment': hmm.comment, 'incident': hmm.incident, 'author': hmm.author, 'omodifieduptime': hmm.value.value1, 'oModifiedQos': hmm.value.value2 });
                        SelectedValues = true;
                    }
                })
            });
            if (SelectedValues) {
                setBulkEditObjs(list);
                setloadingtrue();
                let viewlist = [];
                DailyDataApi.HmGetViewDetailsForBulkEdit(selectedServiceId, datacenters)
                    .then((BulkViewDatas: IBulkViewDetails[]) => {
                        setloadingfalse();
                        if (isArrayWithLength(BulkViewDatas)) {
                            const BulkView_Data = [];
                            BulkViewDatas.map((obj: IBulkViewDetails) => {
                                BulkView_Data.push({ id: obj.Id, name: obj.Name })
                            });
                            setBulkViewsData(BulkView_Data);
                            setDisablecommentbutton(true)
                            setDisablesubmitbutton(true)
                            setShowBulkEdit(true);
                            setDisableDeletebutton(true);
                            SetBulkCommentId('');
                            SetBulkComment('');
                            SetBulkViews('');
                            viewlist.push({ 'id': selectedViewDropdownOption.value, 'name': selectedViewDropdownOption.label });
                            SetBulkViewIds(viewlist);
                        }
                    });
            }
            else {
                toastIdRef.current = toast.error(
                    renderToastifyMsg('Please select atleast one cell',
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                );

            }

        }
        //============= Function for Delete comments of Sinlge Popup===============//
        const DeleteComments = () => {
            DailyDataApi.DeleteHeatmapUpdatedData(ActiveCell, LoginuserId)
                .then((response: number) => {
                    if (response !== -1) {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg('Data Deleted succesfully',
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                        );
                        if (activeDailyDataTab === "reachability") {
                            requestDailyData(selectedServiceDropdownOption.value, moment(startDate, 'MM/DD/YYYY').format('MM/DD/YYYY'), moment(endDate, 'MM/DD/YYYY').format('MM/DD/YYYY'), selectedViewDropdownOption.value, activeDailyDataTab);
                        }
                        else {
                            requestDailyData(selectedServiceId, moment(startDate, 'MM/DD/YYYY').format('MM/DD/YYYY'), moment(endDate, 'MM/DD/YYYY').format('MM/DD/YYYY'), selectedViewDropdownOption.value, activeDailyDataTab);
                        }
                        handleClose();
                    } else {

                        toastIdRef.current = toast.error(
                            renderToastifyMsg('Data Can not be deleted succesfully, Please contact Admin!',
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                        );

                    }
                });
        }
        const selectMultipleView = (value) => {
            SetBulkViewIds(value);
        }
        //=============== function to open MSER Comments popup =========================//
        const onMSERComments = () => {
            setMSERCommentsPopup(true);
        }
        //=============== function to close MSER Comments popup =========================//
        const closesetMSERCommentsPopup = () => {
            setMSERCommentsPopup(false);
            SetselectedDataCenter([]);
            setAdditionInfoId(0);
            SeteffectiveDate(null);
            Setimpact(RichTextEditor.createEmptyValue());
            SetrootCause(RichTextEditor.createEmptyValue());
            Setmitigation(RichTextEditor.createEmptyValue());
            SetlearningsAndRepairItems(RichTextEditor.createEmptyValue());
        }
        //=============== function to open All Comments popup =========================//
        const viewAllComments = () => {
            setAllCommentsPopup(true);
        }
        //=============== function to close All Comments popup =========================//
        const closesetAllCommentsPopup = () => {
            setAllCommentsPopup(false);
        }

        const changedataCenterList = (value) => {
            SetselectedDataCenter(value);
        }

        // Filter additionInfo by addInfoId
        function filteraddInfo(additionInfoId) {
            return additionalInfoData.filter(el => el.additionInfoId === additionInfoId);
        }

        const changeadditionalInfoId = (event) => {
            var selectedAdditionInfoId = parseInt(event.target.value);
            if (selectedAdditionInfoId > 0) {
                SetselectedDataCenter([]);
                const newdatacenterslist = [];
                var res = filteraddInfo(selectedAdditionInfoId);
                if (res.length > 0) {
                    if (res[0].additionInfo != undefined) {
                        var dataCenters = [] = res[0].additionInfo.split(",");
                        for (var i = 0; dataCenters.length > i; i++) {
                            if (dataCenters[i] != "") {
                                newdatacenterslist.push({ id: dataCenters[i], name: dataCenters[i] })
                            }
                        }
                        SetselectedDataCenter(newdatacenterslist);
                    }
                    Setimpact(RichTextEditor.createValueFromString(res[0].impact, "html"));
                    SetrootCause(RichTextEditor.createValueFromString(res[0].rootCause, "html"));
                    Setmitigation(RichTextEditor.createValueFromString(res[0].mitigation, "html"));
                    SetlearningsAndRepairItems(RichTextEditor.createValueFromString(res[0].learningsAndRepairItems, "html"));
                    SeteffectiveDate(moment(res[0].effectiveDate).toDate());
                }
            }
            else {
                SetselectedDataCenter([]);
                setAdditionInfoId(0);
                SeteffectiveDate(null);
                Setimpact(RichTextEditor.createEmptyValue());
                SetrootCause(RichTextEditor.createEmptyValue());
                Setmitigation(RichTextEditor.createEmptyValue());
                SetlearningsAndRepairItems(RichTextEditor.createEmptyValue());
            }
            setAdditionInfoId(selectedAdditionInfoId);
        }
        //================ function to set MSER Comments Impact Value ========//
        const SetimpactValue = (e) => {
            Setimpact(e);
        }
        //================ function to set MSER Comments RootCause Value ========//
        const SetrootCauseValue = (e) => {
            SetrootCause(e);
        }
        //================ function to set MSER Comments Mitigation Value ========//
        const SetmitigationValue = (e) => {
            Setmitigation(e);
        }
        //================ function to set MSER Comments Learnings and Repair Items Value ========//
        const SetlearningsAndRepairItemsValue = (e) => {
            SetlearningsAndRepairItems(e);
        }

        //================ function to submit the Additional Info MSER Comments ========//
        const SubmitMSERAdditionalInfo = (data, status) => {
            var DataCenterList = '';

            for (var count = 0; count < selectedDataCenter.length; count++) {
                DataCenterList = (DataCenterList == '') ? selectedDataCenter[count].id : DataCenterList + ',' + selectedDataCenter[count].id;
            }

            var additionInfodata = {
                AdditionInfoId: (AdditionInfoId === null || AdditionInfoId === undefined) ? 0 : AdditionInfoId,
                ViewId: selectedViewDropdownOption.value,
                DataCenter: DataCenterList,
                EffectiveDate: effectiveDate,
                Impact: (impact === undefined || impact === null) ? "" : impact.toString("html"),
                RootCause: (rootCause === undefined || rootCause === null) ? "" : rootCause.toString("html"),
                Mitigation: (mitigation === undefined || mitigation === null) ? "" : mitigation.toString("html"),
                LearningsAndRepairItems: (learningsAndRepairItems === undefined || learningsAndRepairItems === null) ? "" : learningsAndRepairItems.toString("html"),
                UpdatedBy: LoginuserId
            };

            /// If 'EffectiveDate' is changed for an existing Comment, it should be considered as a NEW comment. 
            /// So 'AdditionInfoId' is reset to '0', since stored proc in database INSERTS new row if 'AdditionInfoId==0'
            if (additionInfodata.AdditionInfoId !== 0) {
                var selectedAddInfo = filteraddInfo(AdditionInfoId);
                if (selectedAddInfo[0].effectiveDate !== moment(effectiveDate).format('MM/DD/YYYY')) {
                    additionInfodata.AdditionInfoId = 0;
                }
            }
            if (status == "true") {
                for (var count = 0; count < data.length; count++) {
                    if (data[count].additionInfoId == AdditionInfoId) {
                        if (data[count].additionInfo != DataCenterList) {
                            var actualdata = data[count].additionInfo.split(',');
                            var selecteddata = DataCenterList.split(',');
                            var result = actualdata.filter(a => !selecteddata.includes(a));
                            additionInfodata.DataCenter = result.toString();
                        }
                    }
                }
            }
            DailyDataApi.UpdateHeatmapAdditionInfoData(additionInfodata, moment(effectiveDate).format('MM/DD/YYYY')).then((response: number) => {
                if (response !== -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg(status == 'true' ? 'Deleted Succesfully':'Data Saved succesfully',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                    );
                    requestDailyData(serviceId, moment(startDate, 'MM/DD/YYYY').format('MM/DD/YYYY'), moment(endDate, 'MM/DD/YYYY').format('MM/DD/YYYY'), selectedViewDropdownOption.value, activeDailyDataTab);
                    closesetMSERCommentsPopup();
                } else {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg('No Change in Data or Error While Saving the Data, Please contact Admin!',
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                    );
                }
            });

        }

        const DeleteMSERComments = (AdditionInfoId) => {
            var DataCenterList = '';

            for (var count = 0; count < selectedDataCenter.length; count++) {
                DataCenterList = (DataCenterList == '') ? selectedDataCenter[count].id : DataCenterList + ',' + selectedDataCenter[count].id;
            }

            for (var count = 0; count < additionalInfoData.length; count++) {
                if (AdditionInfoId == additionalInfoData[count].additionInfoId) {
                    if (DataCenterList == additionalInfoData[count].additionInfo) {
                        DailyDataApi.DeleteMSERComments(AdditionInfoId).then((response: number) => {
                            if (response !== -1) {
                                toastIdRef.current = toast.success(
                                    renderToastifyMsg('Deleted succesfully',
                                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                                );
                                requestDailyData(serviceId, moment(startDate, 'MM/DD/YYYY').format('MM/DD/YYYY'), moment(endDate, 'MM/DD/YYYY').format('MM/DD/YYYY'), selectedViewDropdownOption.value, activeDailyDataTab);
                                closesetMSERCommentsPopup();
                            } else {
                                toastIdRef.current = toast.error(
                                    renderToastifyMsg('No Change in Data or Error While Saving the Data, Please contact Admin!',
                                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                                );
                            }
                        });
                    }
                    else {
                        SubmitMSERAdditionalInfo(additionalInfoData,'true');
                    }
                }
            }
        }
        //================ function to validate the MSER save button ========//
        function ValidateMSERAdditionalInfo() {
            var isrequireddata = true;
            isrequireddata = selectedDataCenter.length == 0 ? false : true;
            if (isrequireddata == false) {
                return true;
            }
            isrequireddata = (effectiveDate == undefined || effectiveDate == null || effectiveDate == "") ? false : true;
            if (isrequireddata == false) {
                return true;
            }
            return false;
        };
        //================ function to enable/disable the MSER Delete button ========//
        function ValidateMSERCommentDeletebutton() {
            if (AdditionInfoId == 0) {
                return true;
            }
            else {
                return false;
            }
        }
        //==========================================================  Weekly Report =====================================================================//


        //=========== function for to set Isedit Property value ====================//
        const ChangeIsEditval = (e, val) => {
            const ConfigData_value = ConfigValues[0].configValue;
            const ConfigData_Weeks = ConfigValues[1].configValue;
            if (val === true) {
                const preMonday = getPreviousMonday();
                var preFriday = new Date(preMonday.getFullYear(), preMonday.getMonth(), preMonday.getDate() - 3);
                preFriday.setDate(preFriday.getDate() - (7 * ConfigData_Weeks));  // To Allow Past Number of Weeks for Edit from Selected Dates
                var date = new Date(weeklyStartDate);
                if (date < preFriday) {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg('History Data Can not be Edited',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                    );

                    return;
                }

                var currentDate = new Date();                                  // Current Date
                var targetDate = new Date(weeklyStartDate);
                targetDate.setDate(targetDate.getDate() + 12);              // Thursday of Current Week
                targetDate.setMinutes(targetDate.getMinutes() + (10 * 60));  // No Edit Before 10 AM on Thursday
                var diff = 0;
                if (targetDate > currentDate) {
                    diff = Math.abs(targetDate.getTime() - currentDate.getTime()) / 3600000;
                }

                if (diff < parseInt(ConfigData_value) && diff != 0) {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg('Data Can not be edited with in ' + parseInt(ConfigData_value).toString() + " hrs of Thursday Live Site Meeting.",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                    );

                    return;
                }
                SetIsEdit(val);
            }
            else {
                const group: any = [];
                for (var i = 0; i < weeklyData.length; i++) {

                    weeklyData[i].week5Edit = (weeklyData[i].week5 !== '' && weeklyData[i].week5 !== undefined && weeklyData[i].week5 !== null) ? weeklyData[i].week5.split('%')[0] : '';;
                    weeklyData[i].comments = '';
                    weeklyData[i].iscmtRed = false;
                    weeklyData[i].isvalRed = false;
                    group.push(weeklyData[i]);
                }
                setweeklyData(group);
                SetIsEdit(val);
            }

        }
        //============= function to set value for Last week cell Edit ================//
        const ChangeLastweekcellvalue = (e, index) => {
            const stateCopy = weeklyData;;
            const group: any = [];
            for (var i = 0; i < stateCopy.length; i++) {
                if (i == index) {
                    weeklyData[i].week5Edit = e.target.value;

                }
                group.push(weeklyData[i]);
            }
            setweeklyData(group);

        }

        //============= function to set value for comments Edit ================//
        const ChangeLastweekcellcomments = (e, index) => {

            const stateCopy = weeklyData;;
            const group: any = [];
            for (var i = 0; i < stateCopy.length; i++) {
                if (i == index) {
                    if (e.target.value !== '' && e.target.value !== undefined && e.target.value !== null) {
                        weeklyData[i].iscmtRed = false;
                    }
                    weeklyData[i].comments = e.target.value;
                }
                group.push(weeklyData[i]);
            }
            setweeklyData(group);

        }
        const Week5PercentageValue = (stringValue) => {
            if (stringValue != "") {
                var numbers = stringValue.split("/");
                if (numbers[0] != "0") {
                    var val = (numbers[1] / numbers[0]) * 100;
                    if (isNaN(val)) {
                        return (numbers[0] + "/" + "NA" + "%");
                    }

                    return (numbers[0] + "/" + parseFloat(val.toString()).toFixed(0) + "%");
                } else
                    return (numbers[0] + "/" + numbers[1] + "%");
            } else {
                return "";
            }
        }
        //------------ function to save weekly data ------------------------------//
        const saveAllWeeklyChanges = () => {
            const stateCopy = weeklyData;;
            const group: any = [];
            var Isvalidation = true;
            var counter = 1;
            var jsonData = [];
            var message = '';
            for (var i = 0; i < stateCopy.length; i++) {
                if (Isvalidation == true) {
                    const cmts = (stateCopy[i].comments === "" || stateCopy[i].comments === null || stateCopy[i].comments === undefined) ? '' : stateCopy[i].comments;
                    var newval = (stateCopy[i].week5Edit === "" || stateCopy[i].week5Edit === null || stateCopy[i].week5Edit === undefined) ? '' : stateCopy[i].week5Edit.split('%')[0];
                    var oldval = (stateCopy[i].week5 === "" || stateCopy[i].week5 === null || stateCopy[i].week5 === undefined) ? '' : stateCopy[i].week5.split('%')[0];
                    if (newval != oldval || (cmts != "" && cmts != null)) {
                        if ((cmts === "") && (stateCopy[i].entrySource && (selectedServiceId === 3 || selectedServiceId === 17 || selectedServiceId === 18))) {
                            stateCopy[i].iscmtRed = true;
                            Isvalidation = false;
                        }
                        else {
                            stateCopy[i].iscmtRed = false;
                        }


                        if (Isvalidation == false) {
                            message = 'Not Updating:\t Please provide valid data or comments';


                        }
                        else {
                            if (stateCopy[i].validationExpr) {
                                var re = new RegExp(stateCopy[i].validationExpr, "g");

                                if (newval.search(re) != 0 && newval != "") {
                                    Isvalidation = false;
                                    stateCopy[i].isvalRed = true;
                                    message = 'Not Updating:\t Value is not in correct format.';

                                }
                            }
                            if (stateCopy[i].kpi == "Monitoring"){
                                if (/^\d+\/\d+\-\d+\/\d+/.test(newval) && stateCopy[i].metricName == 'Sev.1/Sev.2 alerts - Sev.1 % noise/Sev.2 % noise' && Isvalidation === true) {
                                    if (newval != '') {
                                        let sevSplit = newval.split("-");
                                        let sevNums = sevSplit[0].split("/");
                                        let sevPers = sevSplit[1].split("/");
                                        let newvalSev1 = ''
                                        let newvalSev2 = ''
                                        //Sev1 and Sev1 noise % calculation
                                        if (isNaN(parseFloat(sevNums[0])) || isNaN(parseFloat(sevPers[0]))) {
                                            Isvalidation = false;
                                            stateCopy[i].isvalRed = true;
                                            message = 'Not Updating:\t Numerator Should be Numeric Only.';
                                        }
                                        else if (parseFloat(sevNums[0]) < parseFloat(sevPers[0])) {

                                            Isvalidation = false;
                                            stateCopy[i].isvalRed = true;
                                            message = 'Not Updating:\t Sev.1 Numerator Should be greater than Sev.1 % noise denominator.';
                                        }
                                        else {
                                            newvalSev1 = Week5PercentageValue(sevNums[0] + '/' + sevPers[0]);
                                        }

                                        //Sev2 and Sev2 noise % calculation
                                        if (isNaN(parseFloat(sevNums[1])) || isNaN(parseFloat(sevPers[1]))) {
                                            Isvalidation = false;
                                            stateCopy[i].isvalRed = true;
                                            message = 'Not Updating:\t Numerator Should be Numeric Only.';
                                        }
                                        else if (parseFloat(sevNums[1]) < parseFloat(sevPers[1])) {

                                            Isvalidation = false;
                                            stateCopy[i].isvalRed = true;
                                            message = 'Not Updating:\t Sev.2 Numerator Should be greater than Sev.2 % noise denominator.';
                                        }
                                        else {
                                            newvalSev2 = Week5PercentageValue(sevNums[1] + '/' + sevPers[1]);
                                        }

                                        if (newvalSev1 !== '' && newvalSev2 !== '') {
                                            let newSev1 = newvalSev1.split("/");
                                            let newSev2 = newvalSev2.split("/");
                                            newval = newSev1[0] + '/' + newSev2[0] + '-' + newSev1[1] + '/' + newSev2[1];
                                            oldval = Week5PercentageValue(oldval);
                                        }
                                    }
                                }
                                else if ((!/^\d+/.test(newval) && !/^\d+\/\d+\/\d+\/\d+/.test(newval)) && Isvalidation === true) {
                                    Isvalidation = false;
                                    stateCopy[i].isvalRed = true;
                                    message = 'Not Updating:\t Value is not in correct format.';
                                }
                            }
                        }
                        if (Isvalidation) {
                            jsonData.push({
                                serviceId: selectedServiceId,
                                weekId: weekDetailsData.weeks.week5ID,
                                metricGroupName: stateCopy[i].kpi,
                                metricId: stateCopy[i].ml03Id,
                                kpiValue: newval,
                                oldVal: oldval,
                                comments: cmts,
                                UOM: stateCopy[i].uom,
                                updatedBy: LoginuserId
                            });
                            counter++;
                        }

                    }
                }

                group.push(stateCopy[i]);
            }
            if (counter === 1 && Isvalidation == true) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg("No changes observed to Update. If you wish to cancel the update operation please click on the Cancel icon.",
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                );
            }
            else if (Isvalidation == false) {
                setweeklyData(group);
                toastIdRef.current = toast.error(
                    renderToastifyMsg(message,
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                );
            }
            else {
                setloadingtrue();
                WeeklyAPI.AddItemToKpiHistory(jsonData)
                    .then((response: string) => {
                        if (response !== "-1") {
                            getWeeklyData(selectedServiceId, weeklyStartDate);
                            toastIdRef.current = toast.success(
                                renderToastifyMsg(response + " Record saved succesfully",
                                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                            );
                            SetIsEdit(false);

                        } else {
                            setloadingfalse();
                            toastIdRef.current = toast.error(
                                renderToastifyMsg('Error while updating',
                                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                            );

                        }
                    });

            }

        }
        //============== function to open history Popup ===========================//
        const showHistorypopup = (index) => {
            SetKPIHistory(weeklyData[index].history);
            SetHistoryshow(true);
        }
        //=================== function to close the history Popup =================//
        const handlehistoryClose = () => {
            SetHistoryshow(false);
            SetKPIHistory([]);
        }

        //=============== function for to get the KPI data for Popup ===========================//
        const LoadKPICalculationData = (e, MetricName) => {
            setloadingtrue();
            if (MetricName === 'Availability' || MetricName === 'Secrets Management') {
                let MetricGrpId = MetricName === 'Availability' ? 1 : 19;
                WeeklyAPI.LoadKPICalculationData(MetricGrpId, selectedServiceId, moment(weeklyStartDate).format('MM/DD/YYYY'))
                    .then((KPIPopupdata: PropKPICalculationDetails[]) => {
                        setloadingfalse();
                        if (isArrayWithLength(KPIPopupdata)) {
                            KPIPopupdata.map((obj: PropKPICalculationDetails, key1) => {
                                obj.percentageSuccess = obj.percentageSuccess ? (parseFloat(obj.percentageSuccess) * 100).toFixed(4) + "%" : "";
                                obj.qoS = obj.qoS ? (parseFloat(obj.qoS) * 100).toFixed(4) + "%" : "";
                                if (obj.includedTestTraffic == "True") {
                                    obj.includedTestTraffic = "Yes";
                                } else if (obj.includedTestTraffic == "False") {
                                    obj.includedTestTraffic = "No";
                                }
                            });

                        }
                        setKPILogic(true);
                        setKPIPopupdata(KPIPopupdata);

                    });
            }
            else {
                setKPIPopupdata([]);
                MetricName = (MetricName).replace(/@@@/g, " ");
            }
            setmetricName(MetricName);
            WeeklyAPI.GetKPICalculationLogic(selectedServiceId, MetricName)
                .then((KpiLigic: PropKpiCalculationLogic[]) => {
                    setKPICalculatedlogic(KpiLigic);
                    if (MetricName !== 'Availability') {
                        setloadingfalse();
                        setKPILogic(true);
                    }
                });

        }


        //=================== function to close the KPICalculation POPup ============================//
        const KPILogicClose = () => {
            setloadingfalse();
            setKPILogic(false);
            setOptions([]);
            setKPIPopupdata([]);
            setKPICalculatedlogic([]);
        }
        //================== function to get data for RepairItem Graph======================//
        const LoadRepairItemGraph = () => {
            WeeklyAPI.GetTicketReportGraph(selectedServiceId, moment(weeklyStartDate).format('MM/DD/YYYY'))
                .then((ticketdata: PropTicketReport[]) => {
                    var _series: any = [];
                    var months: any = [];
                    var opened: any = [];
                    var closed: any = [];
                    var osla: any = [];
                    var mt: any = [];
                    var lt: any = [];
                    var st: any = [];
                    if (isArrayWithLength(ticketdata)) {
                        ticketdata.map((obj: PropTicketReport) => {
                            months.push(obj.weekName);
                            opened.push(obj.opened);// == 0 ? 0.001 : obj.opened);
                            closed.push(obj.closed);// == 0 ? 0.001 : obj.closed);
                            osla.push(obj.outOfSla);// == 0 ? 0.001 : obj.outOfSla);
                            mt.push(obj.pirMt);// ? 0.001 : obj.pir_MT);
                            lt.push(obj.pirLt);// ? 0.001 : obj.pir_LT);
                            st.push(obj.pirSt);// ? 0.001 : data[counter].pir_ST);
                        })
                    }
                    _series.push({
                        "name": "New ST opened",
                        "data": st,
                        type: "column",
                        stack: "column",
                        color: "#F48D26"
                    });
                    _series.push({
                        "name": "New MT opened",
                        "data": mt,
                        type: "column",
                        stack: "column",
                        color: "#CBCBC9"
                    });
                    _series.push({
                        "name": "New LT opened",
                        "data": lt,
                        type: "column",
                        stack: "column",
                        color: "#F4CB26"
                    });
                    _series.push({
                        "name": "Item Closed",
                        "data": closed,
                        color: "green",
                        connectNulls: true
                    });
                    _series.push({
                        "name": "Out of SLA",
                        "data": osla,
                        color: "#CD5C5C",
                        connectNulls: true
                    });
                    const options = {
                        chart: {
                            renderTo: 'container',
                            type: 'line',
                            width: 900,
                            height: 600
                        },
                        min: 0,
                        plotOptions: {
                            column: {
                                stacking: "normal"
                            }
                        },
                        title: {
                            style: {
                                "fontSize": "1.48em",
                                "top": "-10px",
                                "margin-left": "-35px",
                                "margin-top": "-10px"
                            },
                            useHTML: true,
                            x: -27,
                            y: 8,
                            text: "Repair Item Graph"
                        },
                        subtitle: {
                            text: ""
                        },
                        credits: {
                            enabled: false
                        },
                        series: _series,
                        xAxis: {
                            categories: months
                        }
                    };

                    setOptions(options);

                });

        }
        //=================== function to open the Popup for TFS task Details of Repair Items ========//
        const setrowcount = (e, count) => {
            const TFS_Task_details = [];
            setloadingtrue();
            setNewRec(true);
            SetLSIEditcount(count);
            const LSI_Ticket_details = [];
            if (weeklyData[count].metricName.toLowerCase().indexOf("sev") == 0 ||
                weeklyData[count].metricName.toLowerCase().indexOf("cris") == 0 ||
                weeklyData[count].metricName.toLowerCase().indexOf("partner") == 6) {
                setIsSev(true);
            } else {
                setIsSev(false);
            }
            // If Date is History Date (Last Week data), Disabled the check box, Save button and Comments box
            var preMonday = getPreviousMonday();
            var preFriday = new Date(preMonday.getFullYear(), preMonday.getMonth(), preMonday.getDate() - 3);
            preFriday.setDate(preFriday.getDate() - (7 * ConfigValues[1].configValue));  // To Allow Past Number of Weeks for Edit from Selected Dates
            var date = new Date(weeklyStartDate);
            if (date < preFriday) {
                setIsRead(true);
            }
            // To Get Configuration values Data
            WeeklyAPI.GetConfigData()
                .then((KpiLigic: PropConfigData) => {
                    setConfig_Values(KpiLigic);
                });

            /// If Service is "MSODS" then user Can ADD NEW LSI
            if (selectedServiceId != 3) {
                setbAdd(true);
            }
            //// Passing Current Date Time to Call Web API for each time click on row (For the Same Data)
            //// For TFS TASK
            if ((weeklyData[count].metricName.indexOf("Repair Items") !== -1) || (weeklyData[count].metricName.indexOf("Graph Onboarding Email") !== -1)) {
                WeeklyAPI.GetTFSDetails(weeklyData[count].ml03Id, moment(weeklyStartDate).format('MM/DD/YYYY'), selectedServiceId, weeklyData[count].envId)
                    .then((Taskdetails: PropTicketDetails[]) => {
                        setTFSTicketDetails(true);
                        setloadingfalse();
                        setTask_details(Taskdetails);
                        LoadRepairItemGraph();
                    });
            }
            else if (weeklyData[count].metricName.indexOf("Graph Onboarding Email") !== -1) {
                WeeklyAPI.GetPRDetails(weeklyData[count].ml03Id, moment(weeklyStartDate).format('MM/DD/YYYY'), selectedServiceId, weeklyData[count].envId)
                    .then((Taskdetails: PropTicketDetails[]) => {
                        setTFSTicketDetails(true);
                        setloadingfalse();
                        setTask_details(Taskdetails);
                        LoadRepairItemGraph();
                    });
            }
            else if (weeklyData[count].metricName.indexOf("Beta") !== -1) {
                WeeklyAPI.GetDeploymentBuildDetails(weeklyData[count].ml03Id, moment(weeklyStartDate).format('MM/DD/YYYY'), selectedServiceId, weeklyData[count].envId)
                    .then((BuildDetails: PropDeploymentBuildDetails[]) => {
                        setBuildSchemaDetails(true);
                        setloadingfalse();
                        setDeploymentBuild_schemaDetails(BuildDetails);
                    });
            }
            else if (weeklyData[count].metricName.indexOf("V1.0") !== -1) {
                WeeklyAPI.GetDeploymentBuildDetails(weeklyData[count].ml03Id, moment(weeklyStartDate).format('MM/DD/YYYY'), selectedServiceId, weeklyData[count].envId)
                    .then((BuildDetails: PropDeploymentBuildDetails[]) => {
                        setBuildSchemaDetails(true);
                        setloadingfalse();
                        setDeploymentBuild_schemaDetails(BuildDetails);
                    });
            }
            else if (weeklyData[count].metricName.indexOf("Pull Request") !== -1) {
                WeeklyAPI.GetSchemaDeploymentDetails(weeklyData[count].ml03Id, moment(weeklyStartDate).format('MM/DD/YYYY'), selectedServiceId, weeklyData[count].envId)
                    .then((PullRequestDetails: PropPullRequestDetails[]) => {
                        setPullRequestSchemaDetails(true);
                        setloadingfalse();
                        setPullRequest_schemaDetails(PullRequestDetails);
                    });
            }
            else if (weeklyData[count].metricName.indexOf("Stack Overflow") !== -1) {
                WeeklyAPI.GetStackOverFlowDetails(weeklyData[count].ml03Id, moment(weeklyStartDate).format('MM/DD/YYYY'), selectedServiceId, weeklyData[count].envId)
                    .then((StackOverFlowDetails: PropStackOverFlowDetails[]) => {
                        setStackOverFlowDetails(true);
                        setloadingfalse();
                        setStackOverFlow_ResponseDetails(StackOverFlowDetails);
                    });
            }
            else if (weeklyData[count].metricName.indexOf("S360 Tickets") !== -1) {
                var endDate = new Date(weeklyStartDate);
                endDate.setDate(endDate.getDate() + 6);
                WeeklyAPI.GetS360Tickets(moment(weeklyStartDate).format('MM/DD/YYYY'), moment(endDate).format('MM/DD/YYYY'))
                    .then((S360TicketsDetails: PropOneDashHistoricalData[]) => {
                        setS360Details(true);
                        setloadingfalse();
                        setS360Ticket_Details(S360TicketsDetails);
                    });
            }
            else if (weeklyData[count].metricName.indexOf("ICM") !== -1){
                WeeklyAPI.GetLSITicketDetails(weeklyData[count].ml03Id, moment(weeklyStartDate).format('MM/DD/YYYY'), selectedServiceId, weeklyData[count].envId)
                    .then((Taskdetails: PropTicketDetails[]) => {
                        setICMTicketDetails(true);
                        setloadingfalse();
                        setICMTicket_details(Taskdetails);
                    });
            }
            else { // For LSI TASK
                WeeklyAPI.GetLSITicketDetails(weeklyData[count].ml03Id, moment(weeklyStartDate).format('MM/DD/YYYY'), selectedServiceId, weeklyData[count].envId)
                    .then((Taskdetails: PropTicketDetails[]) => {
                        setTFSLSITicketDetails(true);
                        setloadingfalse();
                        setLSITicket_details(Taskdetails);
                        const copy = [];
                        if (isArrayWithLength(Taskdetails)) {
                            Taskdetails.map((obj) => {
                                copy.push({ 'isLsi': obj.isLsi });
                            })
                        }
                        setLSI_Ticket_details_History(copy);
                    });
            }

        };

        //=================== function to close the KPI except Availbility POPup ============================//
        const TFSTicketDetailsClose = () => {
            setTFSTicketDetails(false);
            setLSICommentText('');
            setTicket_details([]);
            setTask_details([]);
            setConfig_Values(null);
            setIsRead(false);
            setIsSev(false);
            setOptions([]);
        }

        //=================== function to close the Pull Request details Popup ============================//
        const PullRequestSchemaDetailsClose = () => {
            setPullRequestSchemaDetails(false);
        }

        //=================== function to close the Depployment Build Request details Popup ============================//
        const BuildSchemaDetailsClose = () => {
            setBuildSchemaDetails(false);
        }

        //=================== function to close the Stack OverFlow details popup ============================//
        const StackOverFlowDetailsClose = () => {
            setStackOverFlowDetails(false);
        }

        //=================== function to close the S360 Tickets popup ============================//
        const S360DetailsClose = () => {
            setS360Details(false);
        }

        //=================== function to close the LSI task details popup ============================//
        const TFSLSITicketDetailsClose = () => {
            setTFSLSITicketDetails(false);
            setLSITicket_details([]);
            setLSICommentText('');

        }

        //=================== function to close the ICM task details popup ============================//
        const ICMTicketDetailsClose = () => {
            setICMTicketDetails(false);
            setICMTicket_details([]);

        }
        //================ function to select Tfs checkboxes ========================================//
        const CheckTFSItem = (e, index) => {
            const lst = [];
            Task_details.map((obj, key) => {
                if (key === index) {
                    obj.chkRemove = e.target.checked;
                }
                lst.push(obj);
            });
            setTicket_details(lst);
        }
        //=================== Function for Remove Repair Item of weekly Report============================//
        const RemoveRepairItemRows = () => {
            if (window.confirm("Do You want to Delete the Repair Items Data?")) {
                setloadingtrue();
                var selectedTickets = [];
                var Deletedby = LoginuserId;
                var count = 0;
                Task_details.map((obj) => {
                    if (obj.chkRemove) {
                        selectedTickets.push({
                            tfsId: obj.tfsId,
                            slaStatus: obj.slaStatus,
                            serviceId: selectedServiceId,
                            kpiDate: weeklyStartDate,
                            deletedBy: Deletedby
                        });
                    }
                })


                if (selectedTickets.length != 0) {
                    WeeklyAPI.RemoveRepairItems(selectedTickets, moment(weeklyStartDate).format('MM/DD/YYYY'))
                        .then((result: string) => {
                            getWeeklyData(selectedServiceId, weeklyStartDate);
                            if (result != "-1") {
                                TFSTicketDetailsClose();
                                toastIdRef.current = toast.success(
                                    renderToastifyMsg(result + " Record deleted succesfully",
                                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                                );
                            }
                            else {
                                toastIdRef.current = toast.error(
                                    renderToastifyMsg(result + " Data Can not be deleted, Please try again",
                                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                                );
                            }
                        });

                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg(" Please select atleast one Item",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                    );
                }
            }
        };
        //=========== function to change LSI checkbox =================//
        const changeLSICheckbox = (e, key) => {
            const copy = [];

            LSITicket_details.map((obj, index) => {
                if (index === key) {
                    obj.isLsi = e.target.checked;
                }
                copy.push(obj);
            })
            setLSITicket_details(copy);
        }
        //=========== function to change LSI checkbox =================//
        const changeLSIcomments = (e) => {
            const copy = [];

            setLSICommentText(e.target.value);
        }
        //function to save LSI comments =====================//
        // To Save the Data for Pop up Changes
        const Save_Pop_Up_Changes = (chg) => {
            var nume = 0;
            var editLsi = "";
            var lsiCmnts = LSICommentText.trim();

            if (chg > 0) {
                var selectedLsi = [];
                var ticketIdLsi = [];

                if (isArrayWithLength(LSITicket_details)) {
                    LSITicket_details.map((obj, key) => {
                        // Update the data if IS_LSI Changed
                        if (obj.isLsi != LSI_Ticket_details_History[key].isLsi) {
                            selectedLsi.push({
                                PIRReportId: null,
                                TicketId: obj.ticketId.toString(),
                                TicketTitle: null,
                                TicketStatus: null,
                                IsLsi: obj.isLsi.toString(),
                                ServiceId: null,
                                Kpi: null,
                                WeekId: null,
                                MetricId: null,
                                Oldval: null,
                                NewVal: null,
                                LsiComment: null,
                                UpdatedBy: LoginuserId
                            });


                        }


                        nume = nume + 1;


                    })

                }
                editLsi = nume.toString();
                //// For New Row Insert Data
                //if (!$scope.NewRec) {
                //    if (document.getElementById("chkLSI").checked == true) {
                //        nume = nume + 1;

                //    }
                selectedLsi.push({
                    PIRReportId: null,
                    TicketId: null,
                    TicketTitle: null,
                    TicketStatus: null,
                    IsLsi: null,
                    ServiceId: selectedServiceId.toString(),
                    Kpi: weeklyData[LSIEditcount].kpi,
                    WeekId: weekDetailsData.weeks.week5ID.toString(),
                    MetricId: weeklyData[LSIEditcount].ml03Id.toString(),
                    Oldval: weeklyData[LSIEditcount].week5,
                    NewVal: editLsi,
                    LsiComment: lsiCmnts,
                    UpdatedBy: LoginuserId
                });

                //    ticketIdLsi.push(document.getElementById("txt_TktID").value.trim());
                //}

                // ticketIdLsi = $.unique(ticketIdLsi.sort()).sort();
                // Counting the number of records got changed
                var newchangevalue = 0;
                for (var i = 0; i < ticketIdLsi.length; i++) {
                    newchangevalue = newchangevalue + 1;
                }

                editLsi = nume.toString();
                // Now For All Changes we have to Add with user Message
                lsiCmnts = "System Generated Message:Change in Ticket Id(s) [" + ticketIdLsi + "] :" + lsiCmnts;


            }
            // Add LSI Changes to History
            WeeklyAPI.AddLsiChangesToKpiHistory(selectedLsi)
                .then((result: string) => {
                    if (result != "-1") {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg(result + " Record Modified succesfully",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                        );
                        TFSLSITicketDetailsClose();
                        getWeeklyData(selectedServiceId, weeklyStartDate);

                    } else {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg(" Record Modified succesfullData Can not be saved succesfully, please contact to Administrator",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                        );

                    }
                });


        };

        //=============== function to save LSI comments =======================//
        // Save TFS/ADO LSI Tickets
        const SaveValPopupEdit = () => {
            // var nume = 0;
            var chg = 0; // If user changed selection of check boxes
            // var EditLSI = "";
            document.getElementById("txt_LSI_Comment").style.borderColor = "grey";
            
            if (isArrayWithLength(LSITicket_details)) {
                var chg = 0;
                LSITicket_details.map((obj, key) => {
                    if (obj.isLsi != LSI_Ticket_details_History[key].isLsi) {
                        chg = chg + 1;
                    }
                })
            }

            // To Validate the Changes in check Boxes
            if (chg == 0) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg(" At least one change is required.",
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                );
                return false;
            }

            // To Validate the comments (comment is Blank or not)
            if (LSICommentText.trim() == "") {
                document.getElementById("txt_LSI_Comment").style.borderColor = "red";
                toastIdRef.current = toast.error(
                    renderToastifyMsg(" It seems you changed something. Please specify valid comments.",
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                );
                return false;
            }

            // To Validate the comment is Blank or not, in case of changes
            if (chg > 0 && LSICommentText.trim() == "") {
                document.getElementById("txt_LSI_Comment").style.borderColor = "red";
                toastIdRef.current = toast.error(
                    renderToastifyMsg(" It seems you changed something. Please specify valid comments.",
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                );
                return false;
            }

            Save_Pop_Up_Changes(chg);
            // }
        };
        //================== function to Export Excel Data ============================//
    const exportToCSV = (csvData, fileName) => {
            const header = ['ID', 'Ticket Title', 'Ticket Status', 'SLA Status', 'Created Date', 'Due Date', 'User Name', 'Original ETA', 'Revised ETA',];
            const list = [];
            csvData.map((obj) => {
                list.push({
                    'ID': obj.tfsId, 'Ticket Title': obj.ticketTitle, 'Ticket Status': obj.ticketStatus, 'SLA Status': obj.slaStatus,
                    'Created Date': (obj.createdDate !== null) ? moment(obj.createdDate).format('YYYY-MM-DD') : '', 'Due Date': (obj.dueDate !== null) ? moment(obj.dueDate).format('YYYY-MM-DD') : '',
                    'User Name': obj.userName, 'Original ETA': (obj.scrumOriginalEta !== null) ? moment(obj.scrumOriginalEta).format('YYYY-MM-DD') : '',
                    'Revised ETA': (obj.scrumRevisedEta !== null) ? moment(obj.scrumRevisedEta).format('YYYY-MM-DD') : ''
                });
            });

            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('TFSDetails');

            const headerRow = worksheet.addRow(header);

            headerRow.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '5879FF' },
                    bgColor: { argb: 'FFFFFF' }
                };
                cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                cell.font = {
                    bold: true,
                    color: { argb: 'FFFFFF' }
                };
            });
            for (let d of list) {
                worksheet.addRow(d);
            }

            worksheet.autoFilter = 'A1:G1';
            const fileTypes = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtensions = '.xlsx';

            workbook.xlsx.writeBuffer().then((dataList: any) => {
                const blob = new Blob([dataList], { type: fileTypes });
                FileSaver.saveAs(blob, 'TFSDetails' + fileExtensions);
            });
        }

        const calculate = (PrevVal, PresVal) => {
            var prevValue = parseInt(PrevVal.replace(/\,/g, ""));
            var presValue = parseInt(PresVal.replace(/\,/g, ""));
            var perc;
            if (isNaN(prevValue) || isNaN(presValue)) {
                perc = " ";
            } else {
                if (prevValue != 0) {
                    perc = parseFloat((((presValue - prevValue) / prevValue) * 100).toString()).toFixed(2);
                }
                else {
                    perc = parseFloat(((presValue - prevValue) * 100).toString()).toFixed(2);
                }
            }
            if (perc != " ") {
                var str = perc.toString();
                if (perc < 0)
                    perc = "  (" + str + "%)";
                else if (perc > 0)
                    perc = "  (+" + str + "%)";
                else if (str == '0.00') {
                    perc = "  (" + 0 + "%)";
                }
                else if (str == '0.0000') {
                    perc = "  (" + 0 + "%)";
                }
            }
            return perc;
        }

        //=========== function to load BGM Graph fro MSODS =====================//
        const LoadBGMGraphForMSODS = (bgm_data) => {
            const TT = [];
            const TU = [];
            const LU = [];
            const AU = [];

            if (isArrayWithLength(bgm_data)) {
                bgm_data.map((obg) => {
                    if (obg.users === "Total Tenants (millions)") {
                        TT.push(parseInt(obg.week1));
                        TT.push(parseInt(obg.week2));
                        TT.push(parseInt(obg.week3));
                        TT.push(parseInt(obg.week4));
                        TT.push(parseInt(obg.week5));
                    } else if (obg.users === "Total Users (millions)") {
                        TU.push(parseInt(obg.week1));
                        TU.push(parseInt(obg.week2));
                        TU.push(parseInt(obg.week3));
                        TU.push(parseInt(obg.week4));
                        TU.push(parseInt(obg.week5));
                    } else if (obg.users === "Licensed Users (millions)") {
                        LU.push(parseInt(obg.week1));
                        LU.push(parseInt(obg.week2));
                        LU.push(parseInt(obg.week3));
                        LU.push(parseInt(obg.week4));
                        LU.push(parseInt(obg.week5));
                    } else if (obg.users === "Active Users (millions)") {
                        AU.push(parseInt(obg.week1));
                        AU.push(parseInt(obg.week2));
                        AU.push(parseInt(obg.week3));
                        AU.push(parseInt(obg.week4));
                        AU.push(parseInt(obg.week5));
                    }

                })
            }
            const options = {
                chart: {
                    renderTo: 'container',
                    type: 'line'
                },
                title: {
                    text: "MSODS - BGM Graph"
                },
                xAxis: {
                    categories: [weekDetailsData.weeks.week1, weekDetailsData.weeks.week2, weekDetailsData.weeks.week3, weekDetailsData.weeks.week4, weekDetailsData.weeks.week5]
                },
                yAxis: [{
                    title: {
                        text: "Tenants (Millions)"
                    },
                    min: 0,
                    // max: 10,
                    tickInterval: 1
                },
                {
                    title: {
                        text: "Users"
                    },
                    min: 0,
                    // max: 750,
                    tickInterval: 150,
                    opposite: true
                }],
                credits: {
                    enabled: false
                },
                plotOptions: {
                    column: {
                        pointWidth: 2
                    }
                },
                series: [{
                    name: "Total Tenants",
                    yAxis: 0,
                    type: "column",
                    data: TT
                },
                {
                    name: "Total Users",
                    yAxis: 1,
                    data: TU
                },
                {
                    name: "Licensed Users",
                    yAxis: 1,
                    data: LU
                },
                {
                    name: "Active Users",
                    yAxis: 1,
                    data: AU
                }
                ]

            };

            setOptions(options);
        }
        //================= Load BGM Graph for gateway =================//
        const LoadBGMGraphForGateway = (bgm_data) => {
            var data = bgm_data;
            const publicCount = [];
            publicCount.push(parseFloat(data[0].week1.replace(new RegExp(",", "g"), "")),
                parseFloat(data[0].week2.replace(new RegExp(",", "g"), "")),
                parseFloat(data[0].week3.replace(new RegExp(",", "g"), "")),
                parseFloat(data[0].week4.replace(new RegExp(",", "g"), "")),
                parseFloat(data[0].week5.replace(new RegExp(",", "g"), "")));

            var qos = [];
            qos.push(parseFloat(data[1].week1.replace("%", "")),
                parseFloat(data[1].week2.replace("%", "")),
                parseFloat(data[1].week3.replace("%", "")),
                parseFloat(data[1].week4.replace("%", "")),
                parseFloat(data[1].week5.replace("%", "")));
            // Graph Part
            var min = MinRangevalues(qos, 99.992); // Finding Minimum for Service uptime
            const options = {
                chart: {
                    renderTo: 'container',
                    type: 'line'
                },
                title: {
                    text: "AAD Gateway - BGM Graph"
                },
                xAxis: {
                    categories: [weekDetailsData.weeks.week1, weekDetailsData.weeks.week2, weekDetailsData.weeks.week3, weekDetailsData.weeks.week4, weekDetailsData.weeks.week5],
                    crosshair: true
                },
                yAxis: [{
                    labels: {
                        format: "{value}",
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    title: {
                        text: "Count (Millions)"
                    },
                    min: 0,
                    // tickInterval: 1,
                },
                {
                    labels: {
                        format: "{value} %",
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    title: {
                        text: "Percentage"
                    },
                    min: min,
                    max: 100,
                    // tickInterval: 150,
                    opposite: true
                }],
                series: [{
                    name: data[0].users,
                    yAxis: 0,
                    type: "column",
                    data: publicCount

                },
                {
                    name: data[1].users,
                    yAxis: 1,
                    type: "spline",
                    data: qos,
                    connectNulls: true
                }
                ]

            };

            setOptions(options);

        }
        //============== Load BGM graph for MFA =======================//
        const LoadBGMDataForMFA = (bgm_data) => {
            var AuthData = [];
            var NonAuthData = [];
            //  var childDataCount = [{ users: "Auths Total", week1: '', week2: '', week3: '', week4: '', week5: '' }];
            var data = bgm_data;


            // Logic for Calculating the Percentage Increase / Decrease
            for (var i = 0; i < data.length; i++) {
                data[i].weekp1 = calculate(data[i].week0, data[i].week1);
                data[i].weekp2 = calculate(data[i].week1, data[i].week2);
                data[i].weekp3 = calculate(data[i].week2, data[i].week3);
                data[i].weekp4 = calculate(data[i].week3, data[i].week4);
                data[i].weekp5 = calculate(data[i].week4, data[i].week5);
            };

            for (var j = 0; j < data.length; j++) {
                if ((data[j].users == "Auths: Voice") || (data[j].users == "Auths: OATH") || (data[j].users == "Auths: SMS") || (data[j].users == "Auths: PhoneApp")) {
                    AuthData.push(data[j]);
                }
                else {
                    NonAuthData.push(data[j]);
                }
            };
            const BGM_datas = NonAuthData.concat(AuthData);

            //for (var l = 0; l < $scope.BGMData.length; l++) {
            //    if ($scope.BGMData[l].users == "Auths Total") {
            //        $scope.BGMData[l].displayOrder = 6;
            //    }
            //};
            BGM_datas.sort(function (char1, char2) {
                return char1.displayOrder - char2.displayOrder;
            });
            setBGMdata(BGM_datas);
        }
        //=================== Load BGM Data for Protection services ==============//
        const LoadBGMDataForSecurityProtectionServices = (bgm_data) => {

            var data = bgm_data;
            // Logic for Calculating the Percentage Increase / Decrease
            for (var i = 0; i < data.length; i++) {
                data[i].weekp1 = calculate(data[i].week0, data[i].week1);
                data[i].weekp2 = calculate(data[i].week1, data[i].week2);
                data[i].weekp3 = calculate(data[i].week2, data[i].week3);
                data[i].weekp4 = calculate(data[i].week3, data[i].week4);
                data[i].weekp5 = calculate(data[i].week4, data[i].week5);
            };
            const BGM_datas = data;
            BGM_datas.sort(function (char1, char2) {
                return char1.displayOrder - char2.displayOrder;
            });
            setBGMdata(BGM_datas);
        }
        //=========== function to load BGM Graph =====================//
        const LoadBGMGraph = (bgm_data, widthVal) => {
            // For formatting the Identity Security and Protection Services BGM Data
            
            if (isArrayWithLength(bgm_data)) {
                setNoBgmData(false);
                setShowExternalBgmLink(false);
                if (selectedServiceId === 3)
                    LoadBGMGraphForMSODS(bgm_data);
                else if (selectedServiceId === 1) {
                    LoadBGMGraphForGateway(bgm_data);
                }
                else {

                    var data = bgm_data;
                    var series = "";
                    var _series = [];
                    const AvailabilityData = [];
                    if (isArrayWithLength(data)) {
                        data.map((data) => {
                            // Config data is present in DB | 91 : BGM Line graph |92: BGM Bar Graph
                            if (data.config == 91 || data.config == 92) {
                                AvailabilityData.push(data);
                                _series.push({
                                    "name": data.users,
                                    "type": data.config == 91 ? "line" : "column",
                                    "data": [parseFloat(data.week1.replace(new RegExp(",", "g"), "")),
                                    parseFloat(data.week2.replace(new RegExp(",", "g"), "")),
                                    parseFloat(data.week3.replace(new RegExp(",", "g"), "")),
                                    parseFloat(data.week4.replace(new RegExp(",", "g"), "")),
                                    parseFloat(data.week5.replace(new RegExp(",", "g"), ""))]
                                    ,
                                    connectNulls: true
                                });
                            }
                        })
                    }
                    const options = {
                        chart: {
                            renderTo: 'container',
                            type: 'line',
                            width: (widthVal === true) ? 1500 : 900,
                            height: 520
                        },
                        plotOptions: {
                            column: {
                                pointWidth: 10
                            }
                        },
                        title: {
                            style: {
                                "fontSize": "1.48em",
                                "top": "-10px",
                                "margin-left": "-35px",
                                "margin-top": "-10px"
                            },
                            useHTML: true,
                            x: -27,
                            y: 8,
                            text: currentService[0].serviceName + " - BGM Graph"
                        },
                        subtitle: {
                            text: ""
                        },
                        credits: {
                            enabled: false
                        },
                        xAxis: {
                            categories: [weekDetailsData.weeks.week1, weekDetailsData.weeks.week2, weekDetailsData.weeks.week3, weekDetailsData.weeks.week4, weekDetailsData.weeks.week5]

                        },
                        yAxis: {
                            type: "logarithmic",
                            minorTickInterval: 0.1,
                            title: {
                                text: "Values"
                            }
                        },
                        tooltip: {
                            shared: true
                        },
                        series: _series

                    };

                    setOptions(options);
                }
                // For formatting the Azure MFA BGM Data
                if (selectedServiceId == 5) {
                    LoadBGMDataForMFA(bgm_data);
                }
                // For formatting the Identity Security and Protection Services BGM Data
                if (bgm_data.some(x => x.isBGMVariation == true)) {
                    LoadBGMDataForSecurityProtectionServices(bgm_data);
                }
            }
            else {
                // Show BGM Hyperlink for "Graph" Service Only
                if (selectedServiceId == 17 || selectedServiceId == 19) {
                    //ExternalLink(true);
                    setNoBgmData(false);
                    setShowExternalBgmLink(true);
                    if (isArrayWithLength(ExternalLinks)) {
                        ExternalLinks.map((obj) => {
                            if (obj.serviceId === selectedServiceId && obj.category === 'BGM') {
                                setExternalLink(obj.url);
                            }
                            if ((obj.serviceId === selectedServiceId && obj.category === "BGMNEW")) {
                                setExternal_link_NewBGM(obj.url);
                            }
                        })
                    }


                } else if (selectedServiceId == 1 || selectedServiceId == 2 || selectedServiceId == 18 || selectedServiceId == 21 || selectedServiceId == 22) {
                    setNoBgmData(true);
                    setShowExternalBgmLink(false);


                } else {
                    setNoBgmData(false);
                    setShowExternalBgmLink(false);

                }

            }

        }
        //=============== function to oepn BGM popup =========================//
        const OpenBGMPopup = () => {
            setloadingtrue();
            WeeklyAPI.Get_BGM_Data(selectedServiceId, moment(weeklyStartDate).format('MM/DD/YYYY'))
                .then((bgmdata: PropBgm[]) => {
                    if (isArrayWithLength(bgmdata)) {
                        bgmdata.map((obj: PropBgm) => {
                            obj.weekp1 = '';
                            obj.weekp2 = '';
                            obj.weekp3 = '';
                            obj.weekp4 = '';
                            obj.weekp5 = '';
                            obj.valuecolor = 'lightcyan';
                            obj.cmtcolor = 'lightcyan';
                        })
                    }
                    LoadBGMGraph(bgmdata, false);
                    setBGMdata(bgmdata);
                    setBGMPopup(true);
                    setloadingfalse();
                });
        }
        //=============== function to close BGM popup =========================//
        const closesetBGMPopup = () => {
            if (IsBgmEdit) {
                if (window.confirm("Do you want to close without saving the changes?")) {
                    setBGMdata([]);
                    setBGMPopup(false);
                    setOptions([]);
                    setIsBgmEdit(false);
                }

            }
            else {
                setBGMdata([]);
                setBGMPopup(false);
                setOptions([]);
                setIsBgmEdit(false);
            }

        }

        //=============== function to open New BGM popup =========================//
        const OpenNewBGMPopup = () => {
            setloadingtrue();
            WeeklyAPI.Get_NewBGM_Data(selectedServiceId, moment(weeklyStartDate).format('MM/DD/YYYY'))
                .then((bgmNewData: PropBgm[]) => {
                    if (isArrayWithLength(bgmNewData)) {                                             
                        bgmNewData.map((obj: PropBgm) => {
                            obj.weekp1 = '';
                            obj.weekp2 = '';
                            obj.weekp3 = '';
                            obj.weekp4 = '';
                            obj.weekp5 = '';
                            obj.valuecolor = 'lightcyan';
                            obj.cmtcolor = 'lightcyan';
                            if (obj.perfPopupGrpId === 0) {
                                obj.color = 'rgba(0,0,0,.05)';
                            }
                            if (obj.perfPopupGrpId === 1) {
                                obj.color = '#FFFF';
                            }
                        })
                    }
                    LoadBGMGraph(bgmNewData, true);
                    setBGMdata(bgmNewData.filter(x => x.envId == 1));
                    setBGMFairFaxdata(bgmNewData.filter(x => x.envId == 2));
                    setBGMMooncakedata(bgmNewData.filter(x => x.envId == 3))
                    setNewBGMPopup(true);
                    setloadingfalse();
                });
        }
        //=============== function to close New BGM popup =========================//
        const closesetNewBGMPopup = () => {
            if (IsBgmEdit) {
                if (window.confirm("Do you want to close without saving the changes?")) {
                    setBGMdata([]);
                    setNewBGMPopup(false);
                    setOptions([]);
                    setIsBgmEdit(false);
                }

            }
            else {
                setBGMdata([]);
                setNewBGMPopup(false);
                setOptions([]);
                setIsBgmEdit(false);
            }

        }
        //=========== function to enable bulk edit for BGM popup data===========//
        const enableBGMPopupEdit = () => {
            const ConfigData_value = ConfigValues[0].configValue;
            const ConfigData_Weeks = ConfigValues[1].configValue;
            const preMonday = getPreviousMonday();
            var preFriday = new Date(preMonday.getFullYear(), preMonday.getMonth(), preMonday.getDate() - 3);
            preFriday.setDate(preFriday.getDate() - (7 * ConfigData_Weeks));  // To Allow Past Number of Weeks for Edit from Selected Dates
            var date = new Date(weeklyStartDate);
            if (date < preFriday) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg('History Data Can not be Edited for Service Performance Details Pop up',
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                );

                return;
            }

            var currentDate = new Date();                                  // Current Date
            var targetDate = new Date(weeklyStartDate);
            targetDate.setDate(targetDate.getDate() + 12);              // Thursday of Current Week
            targetDate.setMinutes(targetDate.getMinutes() + (10 * 60));  // No Edit Before 10 AM on Thursday
            var diff = 0;
            if (targetDate > currentDate) {
                diff = Math.abs(targetDate.getTime() - currentDate.getTime()) / 3600000;
            }

            if (diff < parseInt(ConfigData_value) && diff != 0) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg('Data Can not be edited with in ' + parseInt(ConfigData_value).toString() + " hrs of Thursday Live Site Meeting for Service Performance Details Pop up.",
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                );

                return;
            }
            BGMdata.map((obj: PropBgm) => {
                obj.week5Edit = obj.week5;
                obj.comment = '';
                obj.cmtcolor = 'lightcyan';
                obj.valuecolor = 'lightcyan';
            })
            setBGMdata(BGMdata);
            BGMFairFaxdata.map((obj: PropBgm) => {
                obj.week5Edit = obj.week5;
                obj.comment = '';
                obj.cmtcolor = 'lightcyan';
                obj.valuecolor = 'lightcyan';
            })
            setBGMFairFaxdata(BGMFairFaxdata);
            BGMMooncakedata.map((obj: PropBgm) => {
                obj.week5Edit = obj.week5;
                obj.comment = '';
                obj.cmtcolor = 'lightcyan';
                obj.valuecolor = 'lightcyan';
            })
            setBGMMooncakedata(BGMMooncakedata);

            setIsBgmEdit(true);
        }
        const ChangeBGMLastweekcomment = (e, index, envId) => {
            const copy = [];
            if (envId == 1) {
                BGMdata.map((obj: PropBgm, key) => {
                    if (key === index)
                        obj.comment = e.target.value;
                    copy.push(obj);
                })
                setBGMdata(copy);
            }
            if (envId == 2) {
                BGMFairFaxdata.map((obj: PropBgm, key) => {
                    if (key === index)
                        obj.comment = e.target.value;
                    copy.push(obj);
                })
                setBGMFairFaxdata(copy);
            }
            if (envId == 3) {
                BGMMooncakedata.map((obj: PropBgm, key) => {
                    if (key === index)
                        obj.comment = e.target.value;
                    copy.push(obj);
                })
                setBGMMooncakedata(copy);
            }
            
        }
        const ChangeBGMLastweek = (e, index, envId) => {
            const copy = [];
            if (envId == 1) {
                BGMdata.map((obj: PropBgm, key) => {
                    if (key === index)
                        obj.week5Edit = e.target.value;
                    copy.push(obj);
                })
                setBGMdata(copy);
            }
            if (envId == 2) {
                BGMFairFaxdata.map((obj: PropBgm, key) => {
                    if (key === index)
                        obj.week5Edit = e.target.value;
                    copy.push(obj);
                })
                setBGMFairFaxdata(copy);
            }
            if (envId == 3) {
                BGMMooncakedata.map((obj: PropBgm, key) => {
                    if (key === index)
                        obj.week5Edit = e.target.value;
                    copy.push(obj);
                })
                setBGMMooncakedata(copy);
            }
            
        }
        const GenericValidation = () => {
            const jsonData = [];
            var counter = 1;
            var cnt = 0;
            var data = BGMdata;
            var isValidationFail = false;
            const copy = [];
            BGMdata.map((obj: PropBgm) => {
                var txtpopupWeek5 = null;
                var txtpopupcomment = null;
                var Isvalidation = true;
                if (!isValidationFail) {
                    var newval = (obj.week5Edit === '' || obj.week5Edit === undefined || obj.week5Edit === null) ? '' : obj.week5Edit;
                    var oldval = (obj.week5 === '' || obj.week5 === undefined || obj.week5 === null) ? '' : obj.week5;
                    newval = newval.replace(/,/g, "");
                    var cmnts = (obj.comment === '' || obj.comment === undefined || obj.comment === null) ? '' : obj.comment;
                    if (newval !== oldval.replace(/,/g, "")) {
                        if (newval !== '') {
                            if (cmnts == "" && (selectedServiceId == 3 || selectedServiceId == 17 || selectedServiceId == 18)) {
                                toastIdRef.current = toast.error(
                                    renderToastifyMsg("Not Updating:\t Please provide valid data or comments",
                                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                                );
                                obj.cmtcolor = "red";
                                obj.valuecolor = "red";
                                isValidationFail = true;
                            }
                            else {
                                obj.cmtcolor = "lightcyan";
                                obj.valuecolor = "lightcyan";
                            }
                            if (obj.validationExpr) {
                                var re = new RegExp(obj.validationExpr, "g");//new RegExp("^\d+\\\d+\%$", "g");
                                if (newval.search(re) != 0 && newval != "") {
                                    isValidationFail = true;
                                    obj.valuecolor = "red";
                                    toastIdRef.current = toast.error(
                                        renderToastifyMsg("Not Updating:\t Value is not in correct format.",
                                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                                    );

                                }
                                else {
                                    obj.valuecolor = "lightcyan";
                                }
                            }
                            else {
                                obj.valuecolor = "lightcyan";
                            }
                            if (!isValidationFail) {
                                jsonData.push({
                                    ServiceId: selectedServiceId,
                                    WeekId: weekDetailsData.weeks.week5ID,
                                    MetricGroupName: 0,
                                    MetricId: obj.metricId,
                                    KpiValue: (newval),
                                    OldVal: oldval,
                                    Comments: cmnts,
                                    UOM: obj.uom,
                                    UpdatedBy: LoginuserId
                                });
                                counter++;
                            }
                        }
                    }
                }
                copy.push(obj);


            })

            setBGMdata(copy);
            setBGMJsondata(jsonData);
            if (counter == 1 && !isValidationFail) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg("No changes observed to Update. If you wish to cancel the update operation please click on the Cancel icon.",
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                );

            }

            return isValidationFail;
        }
        //============= function to submit BGM Comments =========================//
        const Edit_Service_BGM_Popup_ToHistory = () => {
            const jsonData = [];
            var counter = 1;
            var cnt = 0;
            var data = BGMdata.concat(BGMFairFaxdata, BGMMooncakedata);
            var isValidationFail = false;
            const copy = [];
            data.map((obj: PropBgm) => {
                var txtpopupWeek5 = null;
                var txtpopupcomment = null;
                var Isvalidation = true;
                if (!isValidationFail) {
                    var newval = (obj.week5Edit === '' || obj.week5Edit === undefined || obj.week5Edit === null) ? '' : obj.week5Edit;
                    var oldval = (obj.week5 === '' || obj.week5 === undefined || obj.week5 === null) ? '' : obj.week5;
                    newval = newval.replace(/,/g, "");
                    var cmnts = (obj.comment === '' || obj.comment === undefined || obj.comment === null) ? '' : obj.comment;
                    if (newval !== oldval.replace(/,/g, "")) {
                        if (newval !== '') {
                            if (cmnts == "" && (selectedServiceId == 3 || selectedServiceId == 17 || selectedServiceId == 18)) {
                                toastIdRef.current = toast.error(
                                    renderToastifyMsg("Not Updating:\t Please provide valid data or comments",
                                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                                );
                                obj.cmtcolor = "red";
                                obj.valuecolor = "red";
                                isValidationFail = true;
                            }
                            else {
                                obj.cmtcolor = "lightcyan";
                                obj.valuecolor = "lightcyan";
                            }
                            if (obj.validationExpr) {
                                var re = new RegExp(obj.validationExpr, "g");//new RegExp("^\d+\\\d+\%$", "g");
                                if (newval.search(re) != 0 && newval != "") {
                                    isValidationFail = true;
                                    obj.valuecolor = "red";
                                    toastIdRef.current = toast.error(
                                        renderToastifyMsg("Not Updating:\t Value is not in correct format.",
                                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                                    );

                                }
                                else {
                                    obj.valuecolor = "lightcyan";
                                }
                            }
                            else {
                                obj.valuecolor = "lightcyan";
                            }

                        }
                        if (!isValidationFail) {
                            jsonData.push({
                                ServiceId: selectedServiceId,
                                WeekId: weekDetailsData.weeks.week5ID,
                                MetricGroupName: "",
                                MetricId: obj.metricId,
                                KpiValue: (newval),
                                OldVal: oldval,
                                Comments: cmnts,
                                Uom: obj.uom,
                                UpdatedBy: LoginuserId
                            });
                            counter++;
                        }
                    }
                }
                copy.push(obj);
            })


            if (counter == 1 && !isValidationFail) {
                setBGMdata(copy.filter(x => x.envId == 1));
                setBGMFairFaxdata(copy.filter(x => x.envId == 2));
                setBGMMooncakedata(copy.filter(x => x.envId == 3));
                toastIdRef.current = toast.error(
                    renderToastifyMsg("No changes observed to Update. If you wish to cancel the update operation please click on the Cancel icon.",
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                );

            }
            if (!isValidationFail && jsonData.length > 0) {
                setloadingtrue();
                WeeklyAPI.AddKPIHistory(jsonData)
                    .then((result: string) => {
                        const group = [];
                        if (result != "-1") {
                            if (NewBGMPopup === false) {
                                WeeklyAPI.Get_BGM_Data(selectedServiceId, moment(weeklyStartDate).format('MM/DD/YYYY'))
                                    .then((bgmdata: PropBgm[]) => {
                                        if (isArrayWithLength(bgmdata)) {
                                            bgmdata.map((obj: PropBgm) => {
                                                obj.weekp1 = '';
                                                obj.weekp2 = '';
                                                obj.weekp3 = '';
                                                obj.weekp4 = '';
                                                obj.weekp5 = '';
                                                obj.valuecolor = 'lightcyan';
                                                obj.cmtcolor = 'lightcyan';
                                            })
                                        }
                                        setBGMPopup(false);
                                        setIsBgmEdit(false);
                                        LoadBGMGraph(bgmdata, false);
                                        setBGMdata(bgmdata);
                                        setloadingfalse();
                                    });
                                toastIdRef.current = toast.success(
                                    renderToastifyMsg(result + " Record of BGM Data Updated succesfully",
                                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                                );
                            }
                            else {
                                WeeklyAPI.Get_NewBGM_Data(selectedServiceId, moment(weeklyStartDate).format('MM/DD/YYYY'))
                                    .then((bgmdata: PropBgm[]) => {
                                        if (isArrayWithLength(bgmdata)) {
                                            bgmdata.map((obj: PropBgm) => {
                                                obj.weekp1 = '';
                                                obj.weekp2 = '';
                                                obj.weekp3 = '';
                                                obj.weekp4 = '';
                                                obj.weekp5 = '';
                                                obj.valuecolor = 'lightcyan';
                                                obj.cmtcolor = 'lightcyan';
                                            })
                                        }
                                        setNewBGMPopup(false);
                                        setIsBgmEdit(false);
                                        LoadBGMGraph(bgmdata, true);
                                        setBGMdata(bgmdata);
                                        setloadingfalse();
                                    });
                                toastIdRef.current = toast.success(
                                    renderToastifyMsg(result + " Record of BGM Data Updated succesfully",
                                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                                );
                            }
                        } else {
                            toastIdRef.current = toast.error(
                                renderToastifyMsg("Data Can not be saved succesfully, please contact to Administrator",
                                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                            );

                        }
                    });

            }
        }
        //=========== function to disable bulk edit for BGM popup data===========//
        const cancelBGMpopupedit = () => {
            setIsBgmEdit(false);
        }
        //============== For formatting the Identity Security and Protection Services BGM Data===============//
        const calculateStyle = (isBGMVariation) => {
            if (isBGMVariation) {
                return { width: '16%' };
            }
            else {
                return { width: '' };
            }
        };
        const HideModalJQuery = () => {
            setBGMPopup(false);
            setNewBGMPopup(false);
        }
        const MinRangevalues = (data, min) => {

            if (data.week1 && parseFloat(data.week1) < min) {
                min = parseFloat(data.week1);
            }

            if (data.week2 && parseFloat(data.week2) < min) {
                min = parseFloat(data.week2);
            }

            if (data.week3 && parseFloat(data.week3) < min) {
                min = parseFloat(data.week3);
            }

            if (data.week4 && parseFloat(data.week4) < min) {
                min = parseFloat(data.week4);
            }

            if (data.week5 && parseFloat(data.week5) < min) {
                min = parseFloat(data.week5);
            }

            return min;
        }

        //============= To Load graph for KPIs based on Availability================//
        const loadChatKpi = (e, count, isKpiLevelChat,KpiName) => {
            var data = weeklyData;;
            var chatData = data.slice(count, count + data[count].spanKpi);
            var series = "";
            var _series = [];
            var min = (KpiName == "Availability")? 99.998: 0;
            var max = 100;
            var target = (KpiName == "Availability")? 99.998: 0;

            if (isKpiLevelChat) {
                target = (chatData[0].targetName ? chatData[0].targetName :
                    (chatData[1].targetName ? chatData[1].targetName :
                        (chatData[2].targetName ? chatData[2].targetName :
                            (chatData[3].targetName ? chatData[3].targetName : target))));
                target = parseFloat(target.toString());
                min = target;
                setisAvailability(true);
                const AvailabilityData = [];
                for (var counter = count; counter < count + chatData.length; counter++) {
                    series = data[counter].metricName + " (" + data[counter].environment + ")";
                    if (series != "" && (data[counter].isChatForKpi)) {
                        min = MinRangevalues(data[counter], min);
                        AvailabilityData.push(data[counter]);
                        _series.push({
                            "name": series,
                            "data": [parseFloat(data[counter].week1),
                            parseFloat(data[counter].week2),
                            parseFloat(data[counter].week3),
                            parseFloat(data[counter].week4),
                            parseFloat(data[counter].week5)],
                            connectNulls: true
                        });
                    }
                }
                setAvailabilityData(AvailabilityData);
            } else {
                setisAvailability(false);
                _series.push({
                    "name": data[count].environment,
                    "data": [parseFloat(data[count].week1),
                    parseFloat(data[count].week2),
                    parseFloat(data[count].week3),
                    parseFloat(data[count].week4),
                    parseFloat(data[count].week5)],
                    connectNulls: true
                });
                target = parseFloat(data[count].targetName);
                if (!data[count].min_Range) {
                    min = target;
                    min = MinRangevalues(data[count], min);

                } else {
                    min = parseFloat(parseFloat(data[count].min_Range).toFixed(4));
                    max = parseFloat(parseFloat(data[count].max_Range).toFixed(4));
                }
                // interval = parseFloat(data[count].interval).toFixed(2);
            };
            const options = {
                chart: {
                    renderTo: 'container',
                    type: 'line'
                },
                title: {
                    style: {
                        "fontSize": "1.48em",
                        "top": "-10px",
                        "margin-left": "-35px",
                        "margin-top": "-10px"
                    },
                    useHTML: true,
                    x: -27,
                    y: 8,
                    text: isKpiLevelChat ? data[count].kpi + "  Graph" : data[count].environment + " Graph"
                },
                xAxis: {
                    categories: [weekDetailsData.weeks.week1, weekDetailsData.weeks.week2, weekDetailsData.weeks.week3, weekDetailsData.weeks.week4, weekDetailsData.weeks.week5]
                },
                yAxis: {
                    min: min,
                    max: max,
                    labels: {
                        format: "{value:.4f}"
                    },
                    plotLines: [{
                        id: "Target",
                        color: "#FF0000",
                        width: 2,
                        value: target,// Need to set this probably as a var.
                        zIndex: 400,
                        dashStyle: "shortdash",
                        label: {
                            text: "Target",
                            align: "right",
                            style: {
                                color: "red"
                            }
                        }
                    }]

                },
                series: _series,

            };

            setOptions(options);
            setKPIChart(true);
        }
        //=========== function for to close the KPI graph popup -=============//
        const CloseKPIChart = () => {
            setKPIChart(false);

        }
        //======= Set color from availabilty metric pop up data====================//
        const ColorWeekAvailability = (value, target) => {
            value = (parseFloat(String(value)));
            if (value >= parseFloat(String(target))) {
                return { background: "#CEF8C1" };   //LightGreen
            } else if (value < parseFloat(String(target))) {
                return { background: "#FFD1D3" };   //LightPink
            }
        };
        //=============== function to open comments Popup ==============//
        const OpenCommentsPopup = () => {
            setloadingtrue();
            WeeklyAPI.GetMetricGroups()
                .then((metricgroup: PropMetricGroups[]) => {
                    const group = [];
                    if (isArrayWithLength(metricgroup)) {
                        metricgroup.map((obj: PropMetricGroups) => {
                            group.push({ 'id': obj.metricGroupId, 'name': obj.metricGroupName });
                        })
                    }
                    setMetricGroup(group);
                    setCommentsPopup(true);
                    setloadingfalse();
                    setDisablesubmitbutton(true);
                    setDisableDeletebutton(true);
                });

        }
        //================ function to close the KPI Comments Popup ========//
        const closeCommentsPopup = () => {
            setCommentsPopup(false);
            setDBCommentvalue('');
            setMetricGroupvalue(null);
            setDBCommentvalue('');
            setExistingMetricGroupId(0);
            setCommentvalue(RichTextEditor.createEmptyValue());
        }
        //================ function to set Comments for comment section ========//
        const SetServiceCommentvalue = (e) => {
            setCommentvalue(e);
            setDBCommentvalue(e.toString("html"));
            const MetricId = (MetricGroupvalue != undefined && MetricGroupvalue != null) ? MetricGroupvalue.id : 0;
            if (e.toString("html").replace("<p><br></p>", "") != "" && MetricId != 0) {
                setDisablesubmitbutton(false);
            }
            else {
                setDisablesubmitbutton(true);
            }

        }
        //================ function to set Metric Id for comment section ========//
        const setMetricGroupvalueData = (e) => {
            var Exists = false;
            if (isArrayWithLength(commentsData)) {
                commentsData.map((obj: PropKpiComments) => {
                    if (obj.metricGroupId === parseInt(e.id)) {
                        setCommentvalue(RichTextEditor.createValueFromString(obj.comments, "html"));
                        setDBCommentvalue(obj.comments);
                        setDisableDeletebutton(false);
                        setExistingMetricGroupId(parseInt(e.id));
                        Exists = true;
                        setDisablesubmitbutton(false);
                    }
                });
                if (Exists === false) {
                    setExistingMetricGroupId(0);
                    setDisableDeletebutton(true);
                    setDisablesubmitbutton(true);
                    setCommentvalue(RichTextEditor.createEmptyValue());
                    setDBCommentvalue('');
                }
            }
            else {
                setExistingMetricGroupId(0);
                setDisableDeletebutton(true);
                setDisablesubmitbutton(true);
                setCommentvalue(RichTextEditor.createEmptyValue());
                setDBCommentvalue('');
            }

            setMetricGroupvalue(e);

        }
        //================ function to submit the KPI Comments ========//
        const submitKPIComments = () => {
            var MetricValue = MetricGroupvalue;
            var Commentetxt = DBCommentvalue;
            setloadingtrue();
            var json = {
                ServiceId: selectedServiceId,
                MetricGroupId: MetricValue.id,
                WStartDate: weeklyStartDate,
                Comments: DBCommentvalue
            };
            WeeklyAPI.AddComments(json, moment(weeklyStartDate).format('MM/DD/YYYY'))
                .then((result: string) => {
                    const group = [];
                    if (result != "-1") {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg(result + " Record saved succesfully",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                        );
                        GetComments(selectedServiceId, weeklyStartDate, 1);
                        closeCommentsPopup();
                        //$scope.LoadComments();
                        //$scope.CloseComments();
                        //$(".modal").modal("hide");
                    } else {
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Data Can not be saved succesfully, Please provide comments",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                        );

                    }
                });
            // var val1 = Commentvalue.text().replace(/\s+/, "");

        }
        //================ function to delete the KPI Comments ========//
        const DeleteKPIComments = () => {
            if (DBCommentvalue.replace("<p><br></p>", "") != "") {
                setloadingtrue();
                if (window.confirm("Do You want to Delete the comment?")) {
                    WeeklyAPI.DeleteComments(selectedServiceId, moment(weeklyStartDate).format('MM/DD/YYYY'), ExistingMetricGroupId)
                        .then((result: string) => {
                            const group = [];
                            if (result != "-1") {
                                GetComments(selectedServiceId, weeklyStartDate, 1);
                                toastIdRef.current = toast.success(
                                    renderToastifyMsg(result + " Record deleted succesfully",
                                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                                );
                                closeCommentsPopup();
                                //$scope.LoadComments();
                                //$scope.CloseComments();
                                //$(".modal").modal("hide");
                            } else {
                                toastIdRef.current = toast.error(
                                    renderToastifyMsg("Data Can not be deleted, Please try again",
                                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                                );

                            }
                        });
                }

            }
            else {
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Comments cannot be empty",
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                );
            }

        }


        //==================== function to get Performance Data for weekly Report =====================//
        const GetPerformancePopupData = () => {
            GetPerformanceData(selectedServiceId, weeklyStartDate);
            setIsPerformanceSelected(true);
            // Show performance Hyperlink for 'Graph' Service Only
            if (selectedServiceId === 17) {
                if (isArrayWithLength(ExternalLinks)) {
                    ExternalLinks.map((obj: PropExternalLinks) => {
                        if (obj.serviceId === serviceId && obj.category == "PERFORMANCE") {
                            setExternalLink(obj.url);
                        }
                    })
                }

            }
            else if (selectedServiceId === 4 || selectedServiceId === 19) {
                setShowExternalPerformanceLink(false);
                setNoPerfData(true);

            }
            else {
                setShowExternalPerformanceLink(false);
                setNoPerfData(true);

            }


        }
        //==================== function to get Performance Data for weekly Report =====================//
        const hideperformancePopupData = () => {
            setIsPerformanceSelected(false);

        }

        //==================== function to show HeatMap Configuration user Guide Data for Daily Report =====================//
        const GetHeatmapConfigurationGuide = () => {
            setIsHeatmapConfigUserGuideSelected(true);
        }
        //==================== function to hide HeatMap Configuration user Guide Data for Daily Report =====================//
        const hideheatMapConfigUserGuideData = () => {
            setIsHeatmapConfigUserGuideSelected(false);
        }

        //==================== function to get Weekly SelfServe Data for weekly Report =====================//
        const GetWeeklySelfServeData = () => {
            setIsWeeklySelfServeSelected(true);
            handleServicesChange(null); // Empty Service Dropdown Value
            GetDropDownDataWeeklySelfServe(6, selectedServiceId.toString());
            clearMetricDataSourceData();
            setAddMetricDataSourceDisabled();
            setViewMetricDataSourceEnabled();
        }
        //==================== function to hide the weekly self serve and display Weekly Report =====================//
        const hideWeeklySelfServeData = () => {
            CallWeeklyMethods();
            setIsWeeklySelfServeSelected(false);
            setAddMetricDataSourceDisabled();
            setViewMetricDataSourceEnabled();
        }

        //=====================Color Logic for Weekly Report==========================//
        const TDCapactyMaagement = (value) => {
            var yellowTd = { css: "cm-yellow", value: "Yellow" }; //For capacity if value = 2 then yellow
            var greenTd = { css: "cm-green", value: "Green" };
            var redTd = { css: "cm-red", value: "Red" };
            var nRedTd = { css: "cm-red", value: "(Red)" };
            var grey = { css: "grey", value: "" };
            switch (value) {
                case "3":
                    return greenTd;
                    break;
                case "2":
                    return yellowTd;
                case "1":
                    return redTd;
                case "-1":
                    return nRedTd;
                default:
                    return grey;
            }
        }
        const SetColorForWeek = (weekVal, target, operator) => {

            var color = "";
            var wk1 = String(weekVal).split("/");
            for (var i = 0; i < wk1.length; i++) {
                if (wk1[i] == "") {
                    color = "grey";
                } else if (wk1[i] != "NA" && !isNaN(parseFloat(wk1[i]))) { // IF not a number or NA
                    if (operator == ">=") {
                        if (parseFloat(wk1[i]) > target) {
                            color = "red";
                            break;
                        } else {
                            color = "green";
                        }
                    } else if (operator == "<=") {
                        if (parseFloat(wk1[i]) < target) {
                            color = "red";
                            break;
                        } else {
                            color = "green";
                        }
                    } else if (operator == "<") {
                        if (parseFloat(wk1[i]) <= target) {
                            color = "red";
                            break;
                        } else {
                            color = "green";
                        }
                    }
                    else if (operator == "=<") {
                        if (parseFloat(wk1[i]) < target) {
                            color = "green";
                            break;
                        } else {
                            color = "red";
                        }
                    }
                }
                if (/[sec]{3}/.test(wk1[i])) {
                    break;
                }
            }

            return color;
        }
        const OperatorOverload = (data, target, weekColor, operator) => {
            data.week1 = String(data.week1).replace(/[(\)]/gi, "");
            data.week2 = String(data.week2).replace(/[(\)]/gi, "");
            data.week3 = String(data.week3).replace(/[(\)]/gi, "");
            data.week4 = String(data.week4).replace(/[(\)]/gi, "");
            data.week5 = String(data.week5).replace(/[(\)]/gi, "");
            weekColor.week1 = SetColorForWeek(data.week1, target, operator);
            weekColor.week2 = SetColorForWeek(data.week2, target, operator);
            weekColor.week3 = SetColorForWeek(data.week3, target, operator);
            weekColor.week4 = SetColorForWeek(data.week4, target, operator);
            weekColor.week5 = SetColorForWeek(data.week5, target, operator);
        }

        const SetColorForWeekWithLowerBound = (weekVal, lowerBound, upperBound, isLessOrEqual) => {
            if (weekVal == "") {
                return "grey";
            } else if (weekVal == "NA" || isNaN(parseFloat(weekVal))) {
                return "";
            } else {
                if (isLessOrEqual == 1) {
                    return parseFloat(weekVal) >= lowerBound && parseFloat(weekVal) <= upperBound ? "green" : "red";
                } else {
                    return parseFloat(weekVal) >= lowerBound && parseFloat(weekVal) < upperBound ? "green" : "red";
                }
            }
        }
        // To Format the weekly data having a certain range
        const OperatorOverload_Range = (data, weekColor, lowerBound, upperBound) => {
            if (data.targetName.indexOf("<=") >= 0) {
                weekColor.week1 = SetColorForWeekWithLowerBound(data.week1, lowerBound, upperBound, 1);
                weekColor.week2 = SetColorForWeekWithLowerBound(data.week2, lowerBound, upperBound, 1);
                weekColor.week3 = SetColorForWeekWithLowerBound(data.week3, lowerBound, upperBound, 1);
                weekColor.week4 = SetColorForWeekWithLowerBound(data.week4, lowerBound, upperBound, 1);
                weekColor.week5 = SetColorForWeekWithLowerBound(data.week5, lowerBound, upperBound, 1);
            } else if (data.targetName.indexOf("-") >= 0) {
                weekColor.week1 = SetColorForWeekWithLowerBound(data.week1, lowerBound, upperBound, 1);
                weekColor.week2 = SetColorForWeekWithLowerBound(data.week2, lowerBound, upperBound, 1);
                weekColor.week3 = SetColorForWeekWithLowerBound(data.week3, lowerBound, upperBound, 1);
                weekColor.week4 = SetColorForWeekWithLowerBound(data.week4, lowerBound, upperBound, 1);
                weekColor.week5 = SetColorForWeekWithLowerBound(data.week5, lowerBound, upperBound, 1);
            } else {
                weekColor.week1 = SetColorForWeekWithLowerBound(data.week1, lowerBound, upperBound, 0);
                weekColor.week2 = SetColorForWeekWithLowerBound(data.week2, lowerBound, upperBound, 0);
                weekColor.week3 = SetColorForWeekWithLowerBound(data.week3, lowerBound, upperBound, 0);
                weekColor.week4 = SetColorForWeekWithLowerBound(data.week4, lowerBound, upperBound, 0);
                weekColor.week5 = SetColorForWeekWithLowerBound(data.week5, lowerBound, upperBound, 0);
            }
        }

        const AddColorForFact = (data) => {
            var weekColor = {
                week1: "",
                week2: "",
                week3: "",
                week4: "",
                week5: "",
            };

            switch (data.kpi) {
                case "Availability":
                case "Secrets Management":
                    data.week1 = (parseFloat(data.week1) < 1 && parseFloat(data.week1) > 0) ? (data.week1 * 100).toFixed(4) + "%" : data.week1;
                    data.week2 = (parseFloat(data.week2) < 1 && parseFloat(data.week2) > 0) ? (data.week2 * 100).toFixed(4) + "%" : data.week2;
                    data.week3 = (parseFloat(data.week3) < 1 && parseFloat(data.week3) > 0) ? (data.week3 * 100).toFixed(4) + "%" : data.week3;
                    data.week4 = (parseFloat(data.week4) < 1 && parseFloat(data.week4) > 0) ? (data.week4 * 100).toFixed(4) + "%" : data.week4;
                    data.week5 = (parseFloat(data.week5) < 1 && parseFloat(data.week5) > 0) ? (data.week5 * 100).toFixed(4) + "%" : data.week5;
                    var reg = /[0-9][0-9]\.[0-9][%]/;
                    var availabilityTarget = parseFloat(data.targetName);
                    if (availabilityTarget != null) {
                        OperatorOverload(data, availabilityTarget, weekColor, "<=");
                    } else if (/[0-9]+/.test(data.targetName)) {
                        OperatorOverload(data, data.targetName, weekColor, ">=");
                    }

                    break;
                case "Customer notification - JIT pipeline":
                case "Customer notification - API pipeline":
                case "Response to JIT":
                case "Web API":
                    var reg = /[0-9][0-9]\.[0-9][%]/;
                    var availabilityTarget = parseFloat(data.targetName);
                    if (availabilityTarget != null) {
                        OperatorOverload(data, availabilityTarget, weekColor, "<=");
                    } else if (/[0-9]+/.test(data.targetName)) {
                        OperatorOverload(data, data.targetName, weekColor, ">=");
                    }

                    break;
                case "Monitoring":
                    var target;
                    if (/\(\d+\/\d+\/\d+\/\d+\/\d+\)/.test(data.targetName)) {
                        OperatorOverload(data, 0, weekColor, ">=");
                    } else if (/^\<\d*\.?\d*?\%$/.test(data.targetName)) {
                        target = data.targetName.match(/\d+\.?\d+?/);
                        OperatorOverload(data, target, weekColor, ">=");
                    } else if (/^\>\d*\.?\d*?\%$/.test(data.targetName)) {
                        target = data.targetName.match(/\d+\.?\d+?/);
                        OperatorOverload(data, target, weekColor, "<");

                    } else if (/^[0-9]+/.test(data.targetName)) {
                        OperatorOverload(data, data.targetName, weekColor, ">=");
                    }
                    else if (/[0-9]+/.test(data.targetName)) {
                        OperatorOverload(data, data.targetName.split('<')[1], weekColor, "=<");
                    }
                    break;
                case "Support":
                    if (/[0-9]+/.test(data.targetName)) {
                        OperatorOverload(data, data.targetName, weekColor, ">=");
                    }

                    break;
                case "Capacity":
                    if (isProd === true) {
                        var reg1 = /^\>\d*\.?\d*?\%.*\<\d*\.?\d?\%$/;
                        var reg2 = /\d+\-?\d+\%?/;
                        data.week1 = (data.week1).indexOf("%") > 0 ? parseFloat(data.week1).toFixed(2) + '%' : data.week1;
                        data.week2 = (data.week2).indexOf("%") > 0 ? parseFloat(data.week2).toFixed(2) + '%' : data.week2;
                        data.week3 = (data.week3).indexOf("%") > 0 ? parseFloat(data.week3).toFixed(2) + '%' : data.week3;
                        data.week4 = (data.week4).indexOf("%") > 0 ? parseFloat(data.week4).toFixed(2) + '%' : data.week4;
                        data.week5 = (data.week5).indexOf("%") > 0 ? parseFloat(data.week5).toFixed(2) + '%' : data.week5;
                        if (parseFloat(data.week1.replace(/%$/, '')) > 0) {
                            data.week1 = parseFloat(data.week1) < 1 ? (data.week1 * 100).toFixed(0) + '%' : data.week1;
                        }
                        if (parseFloat(data.week2.replace(/%$/, '')) > 0) {
                            data.week2 = parseFloat(data.week2) < 1 ? (data.week2 * 100).toFixed(0) + '%' : data.week2;
                        }
                        if (parseFloat(data.week3.replace(/%$/, '')) > 0) {
                            data.week3 = parseFloat(data.week3) < 1 ? (data.week3 * 100).toFixed(0) + '%' : data.week3;
                        }
                        if (parseFloat(data.week4.replace(/%$/, '')) > 0) {
                            data.week4 = parseFloat(data.week4) < 1 ? (data.week4 * 100).toFixed(0) + '%' : data.week4;
                        }
                        if (parseFloat(data.week5.replace(/%$/, '')) > 0) {
                            data.week5 = parseFloat(data.week5) < 1 ? (data.week5 * 100).toFixed(0) + '%' : data.week5;
                        }

                        if (reg1.test(data.targetName)) {
                            var lowerBound = data.targetName.match(/\d+\.?\d+?/);
                            var i = data.targetName.lastIndexOf('<');
                            var upperBound = data.targetName.substr(i).match(/\d+\.?\d+?/);
                            OperatorOverload_Range(data, weekColor, lowerBound, upperBound);
                        }
                        else if (reg2.test(data.targetName) && data.uom == 2) {
                            var lowerBound1 = 0;     //Fixed '0' for 'Capacity' in case of <10
                            var i = data.targetName.indexOf('<');
                            var upperBound = data.targetName.substr(i).match(/\d+\.?\d+?/);
                            OperatorOverload_Range(data, weekColor, lowerBound1, upperBound);
                        }
                        else if (reg2.test(data.targetName)) {
                            var arr = data.targetName.match(/\d+\.?\d+?/g);
                            var lowerBound = arr[0];
                            var upperBound = arr[1];
                            if (isNaN(upperBound)) {
                                var lowerBound2 = 0;     //Fixed '0' for 'Capacity' in case of <45%
                                var i = data.targetName.indexOf('<');
                                var upperBound = data.targetName.substr(i).match(/\d+\.?\d+?/);
                                OperatorOverload_Range(data, weekColor, lowerBound2, upperBound);
                            }
                            else {
                                OperatorOverload_Range(data, weekColor, lowerBound, upperBound);
                            }
                        }
                    }
                    break;
                case "Business Continuity":
                    if (/^\d*?\.?\d+?/.test(data.targetName)) {
                        var businessContinuitytarget = data.targetName.match(/\d+/);
                        businessContinuitytarget = businessContinuitytarget * 60;
                        OperatorOverload(data, businessContinuitytarget, weekColor, ">=");
                    }

                    break;
                case "Data Quality":
                    data.week1 = (parseFloat(data.week1) < 1 && parseFloat(data.week1) > 0) ? (data.week1 * 100).toFixed(4) + "%" : data.week1;
                    data.week2 = (parseFloat(data.week2) < 1 && parseFloat(data.week2) > 0) ? (data.week2 * 100).toFixed(4) + "%" : data.week2;
                    data.week3 = (parseFloat(data.week3) < 1 && parseFloat(data.week3) > 0) ? (data.week3 * 100).toFixed(4) + "%" : data.week3;
                    data.week4 = (parseFloat(data.week4) < 1 && parseFloat(data.week4) > 0) ? (data.week4 * 100).toFixed(4) + "%" : data.week4;
                    data.week5 = (parseFloat(data.week5) < 1 && parseFloat(data.week5) > 0) ? (data.week5 * 100).toFixed(4) + "%" : data.week5;
                    var reg = /[0-9][0-9]\.[0-9][%]/;
                    var qualityTarget = parseFloat(data.targetName);
                    if (qualityTarget != null) {
                        OperatorOverload(data, qualityTarget, weekColor, "<=");
                    } else if (/[0-9]+/.test(data.targetName)) {
                        OperatorOverload(data, data.targetName, weekColor, ">=");
                    }

                    break;
                case 'Deployment Cadence':
                    var reg = /[0-9][0-9]\.[0-9][%]/;
                    var target = data.targetName;
                    target = target.match(/[0-9]+/);
                    if (target != null && data.ml03Id == 1219) {
                        OperatorOverload(data, target, weekColor, '<=')
                    }
                    else if (target != null && data.ml03Id == 1221) {
                        OperatorOverload(data, target, weekColor, '>=')
                    }

                    break;
                case 'LiveSite Worker':
                    var reg = /[0-9][0-9]\.[0-9][%]/;
                    var liveSiteWorkertarget = parseFloat(data.targetName);
                    if (liveSiteWorkertarget != null) {
                        OperatorOverload(data, liveSiteWorkertarget, weekColor, '<=')
                    } else if (/[0-9]+/.test(data.targetName)) {
                        OperatorOverload(data, liveSiteWorkertarget, weekColor, ">=");
                    }

                    break;
                case "Performance":
                    if (/^\d*?\.?\d+?/.test(data.targetName)) {
                        var performanceTarget = data.targetName.match(/\d+/);
                        performanceTarget = performanceTarget * 1000;
                        OperatorOverload(data, performanceTarget, weekColor, ">=");
                    }

                    break;
                case "Security":
                    if (/\d+\/\d+\/\d+\/\d+/.test(data.targetName)) {
                        OperatorOverload(data, 0, weekColor, ">=");
                    } else {
                        OperatorOverload(data, 0, weekColor, ">=");
                    }
                case 'Client Health':
                    var reg = /[0-9][0-9]\.[0-8][%]/;
                    var clientHealthtarget = parseFloat(data.targetName);
                    if (clientHealthtarget != null) {
                        OperatorOverload(data, clientHealthtarget, weekColor, '<')
                    } else if (/[0-9]+/.test(data.targetName)) {
                        OperatorOverload(data, clientHealthtarget, weekColor, ">=");
                    }

                    break;
            }

            return weekColor;
        }
        const ColorRepireItem = (week5) => {
            if (!week5) {
                return "";
            }

            var res = week5.match(/\d+/g);
            var val = res[res.length - 1];
            if (parseInt(val) > 0) {
                res.splice(res.lastIndexOf(val), 1);
                return (res.toString().replace(/,/g, "/").replace(",", "/") + "/<font style='color:red'>" + val + "</font>");
            } else {
                return week5;
            }
        }
        //===================== end of Color Logic for Weekly Report==========================//

        //==========  end of Weekly Report ==========================//
        const handleKeypress = (e) => {
            const characterCode = e.key
            var key = e.which || e.keyCode; // keyCode detection
            var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false); // ctrl detection
            if (key == 86 && ctrl) return // Logic to allow Paste(Ctrl+V)
            if (characterCode === 'Backspace') return
            if (characterCode === "ArrowLeft") return
            if (characterCode === "keydown") return
            const characterNumber = Number(characterCode)
            if (characterNumber >= 0 && characterNumber <= 9) {
                if (e.currentTarget.value && e.currentTarget.value.length) {
                    return
                } else if (characterNumber === 0) {
                    e.preventDefault()
                }
            } else {
                e.preventDefault()
            }
        }
        const onDailyFilterSubmitted = () => {
            setbulkcellcount(0);
            handleOnFilterApplied();
            handleBulkEditCheck(false);
        }
        const OpenDetailedQuery = (e, data, metric) => {
            var kustoServer = data.kustoCluster.substring(8, data.kustoCluster.indexOf('.'));
            var drilldownQuery = data.queryForDrillDown;
            drilldownQuery = drilldownQuery.replace('todatetime(<<datetime>>)', 'datetime(' + metric.dateKey + ')');
            drilldownQuery = encodeURIComponent(drilldownQuery.replace('<<datacenter>>', "'" + data.key + "'"));

            window.open(
                'https://aka.ms/nkwe?cluster=' + kustoServer + '&database=' + data.kustoDb + '&query=' + drilldownQuery,
                '_blank'
            );

        };
        const sendEmail = () => {
            var tab_text = "<table border='1'>";

            var exportTable = document.body.querySelector('#tdDailydata').cloneNode(true);
            var divElement = document.createElement('div');
            divElement.appendChild(exportTable);
            var table = divElement.innerHTML;
            tab_text = tab_text + table;
            tab_text = tab_text + '</table></body></html>';
            let Email = {
                'ViewName': selectedViewDropdownOption.label,
                'ServiceName': currentService[0].serviceName,
                'EmailContent': tab_text,
                'ToEmailList': dailyData[0].toEmailList,
                'CcEmailList': dailyData[0].ccEmailList,
                'Pagename':'heatmap'
            };
            setIssendingEmail(true);
            setloadingtrue();
            DailyDataApi.HeatmapSendEmail(Email)
                .then((result: number) => {
                    setloadingfalse();
                    setIssendingEmail(false);
                    if (result != -1) {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg("Email sent succesfully",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                        );
                    } else {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg("There is error While sending email, Please contact Admin!",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                        );
                    }

                }).catch(error => {
                    setloadingfalse();
                    setIssendingEmail(false);
                    toastIdRef.current = toast.success(
                        renderToastifyMsg("There is error While sending email, Please contact Admin!",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                    );
                });

        }
        return (
            <LoadingOverlay active={isLoading}
                spinner
                text={(IssendingEmail)?'Sending Email':'Loading your content...'}
            >
                <div>
                    {
                        <section className='section'>
                            <div className='container'>
                                {
                                    (IsPerformanceSelected)
                                        ?
                                        (IsperformanceDataloaded)
                                            ?
                                            <WeeklyPerformanceReport
                                                serviceId={selectedServiceId}
                                                weeklyStartDate={weeklyStartDate}
                                                Performance_data={Performancedata}
                                                GetPerformanceData={GetPerformanceData}
                                                weekDetailsData={weekDetailsData}
                                                ExternalLink={ExternalLink}
                                                ShowExternalPerformanceLink={ShowExternalPerformanceLink}
                                                NoPerfData={NoPerfData}
                                                MainPerformanceData={Performancedata}
                                                envactive={(isArrayWithLength(Performancedata) ? Performancedata[0].environment : '')}
                                                subenvactive={(isArrayWithLength(Performancedata) ? Performancedata[0].subEnv.toString() : '')}
                                                CurrentService={currentService}
                                                hideperformancePopupData={hideperformancePopupData}
                                                LoginuserId={LoginuserId}
                                                setloadingtrue={setloadingtrue}
                                                setloadingfalse={setloadingfalse}
                                            />
                                            : <React.Fragment> </React.Fragment>
                                        : (IsWeeklySelfServeSelected) ?
                                            <WeeklySelfServeData
                                                LoginuserId={LoginuserId}
                                                setloadingtrue={setloadingtrue}
                                                setloadingfalse={setloadingfalse}
                                                serviceGroups={serviceGroups}
                                                selectedServicegroupval={selectedServicegroupval}
                                                services={services}
                                                selectedServiceval={selectedServiceval}
                                                hideWeeklySelfServeData={hideWeeklySelfServeData}
                                                handleServiceGroupChange={(selectedServicegroupval) => handleServiceGroupChange(selectedServicegroupval)}
                                                handleServicesChange={(selectedServiceval) => handleServicesChange(selectedServiceval)}
                                                metricGroups={metricGroups}
                                                selectedMetricgroupval={selectedMetricgroupval}
                                                handleMetricGroupChange={(selectedMetricgroupval) => handleMetricGroupChange(selectedMetricgroupval)}
                                                activeMetrics={activeMetrics}
                                                selectedMetric={selectedMetric}
                                                handleMetricChange={(selectedMetric) => handleMetricChange(selectedMetric)}
                                                environments={environments}
                                                selectedEnvironment={selectedEnvironment}
                                                handleEnvironmentChange={(selectedEnvironment) => handleEnvironmentChange(selectedEnvironment)}
                                                datasource={datasource}
                                                authenticationTypes={authenticationTypes}
                                                selectedDatasource={selectedDatasource}
                                                handledatasourceChange={(selectedDatasource) => handledatasourceChange(selectedDatasource)}
                                                metricDataSourceDetails={metricDataSourceDetails}
                                                metricDataSourceInactive={(rowData) => metricDataSourceInactive(rowData)}
                                                icmMetricDataSourceInactive={(rowData) => icmMetricDataSourceInactive(rowData)}
                                                serverName={serverName}
                                                newMetricName={newMetricName}
                                                newMetricGroupName={newMetricGroupName}
                                                database={database}
                                                mdmMetricName={mdmMetricName}
                                                authenticationType={authenticationType}
                                                target={target}
                                                notes={notes}
                                                queryOrUrl={queryOrUrl}
                                                serverNameUpdate={(event) => serverNameUpdate(event)}
                                                addNewMetric={(event) => addNewMetric(event)}
                                                addNewMetricGroup={(event) => addNewMetricGroup(event)}
                                                databaseUpdate={(event) => databaseUpdate(event)}
                                                mdmMetricNameUpdate={(event) => mdmMetricNameUpdate(event)}
                                                authenticationTypeUpdate={(event) => authenticationTypeUpdate(event)}
                                                UpdatequeryOrUrl={(event) => UpdatequeryOrUrl(event)}
                                                Updatetarget={(event) => Updatetarget(event)}
                                                Updatenotes={(event) => Updatenotes(event)}
                                                addMetricDataSourceData={addMetricDataSourceData}
                                                clearMetricDataSourceData={clearMetricDataSourceData}
                                                targetTypes={targetTypes}
                                                EditMetricDataSourceRow={(row) => EditMetricDataSourceRow(row)}
                                                onboardNewMetric={onboardNewMetric}
                                                setonboardNewMetric={(val) => setonboardNewMetric(val)}
                                                setViewMetricDataSourceEnabled={setViewMetricDataSourceEnabled}
                                                setViewMetricDataSourceDisabled={setViewMetricDataSourceDisabled}
                                                setAddMetricDataSourceEnabled={setAddMetricDataSourceEnabled}
                                                setAddMetricDataSourceDisabled={setAddMetricDataSourceDisabled}
                                                ViewMetricDataSourceOpen={ViewMetricDataSourceOpen}
                                                AddMetricDataSourceOpen={AddMetricDataSourceOpen}
                                            />
                                            : (IsHeatmapConfigUserGuideSelected) ?
                                                <HeatmapConfigurationGuide
                                                    hideheatMapConfigUserGuideData={hideheatMapConfigUserGuideData}
                                                />
                                                :
                                                <React.Fragment>
                                                    {
                                                        ((selectedDropdownOption.label === 'Daily') && ((activeDailyDataTab === 'reachability') || (activeDailyDataTab === 'Fleetutilizationheatmap')))
                                                            ?
                                                            <React.Fragment>
                                                            </React.Fragment>
                                                            :
                                                            <ServiceNavigator
                                                                previousService={previousService}
                                                                currentService={currentService}
                                                                nextService={nextService}
                                                                onPreClick={handleOnPrevious}
                                                                onNextClick={handleOnNext}
                                                            />
                                                    }
                                                    <ServiceHealthFilter
                                                        options={REPORT_TYPE_DROPDOWN}
                                                        onSelectOption={reportSelectOption}
                                                        selectedOption={selectedDropdownOption}
                                                        dailyViewList={dailyViewList}
                                                        fleetTenantList={fleetTenantList}
                                                        tenantSelectedOption={selectedTenant}
                                                        tenantOnSelectOption={tenantSelectOption}
                                                        fleetRoleList={fleetRoleList}
                                                        roleSelectedOption={selectedRole}
                                                        roleOnSelectOption={roleSelectOption}
                                                        fleetCloudList={fleetCloudList}
                                                        cloudSelectedOption={selectedCloud}
                                                        cloudOnSelectOption={cloudSelectOption}
                                                        fleetTrafficGroupList={fleetTrafficGroupList}
                                                        trafficGroupSelectedOption={selectedTrafficGroup}
                                                        trafficGroupOnSelectOption={trafficGroupSelectOption}
                                                        onFleetFilterSubmitted={handleOnFleetFilterApplied}
                                                        fleetMaxDate={fleetMaxDate}
                                                        onFleetStartDateChange={handleFleetStartDateChange}
                                                        onFleetEndDateChange={handleFleetEndDateChange}
                                                        fleetStartDate={fleetStartDate}
                                                        fleetEndDate={fleetEndDate}
                                                        fleetheatmapData={fleetheatmapData}
                                                        currentService={currentService}
                                                        viewOnSelectOption={viewSelectOption}
                                                        viewSelectedOption={selectedViewDropdownOption}
                                                        multipleviewSelectedOption={multipleviewSelectedOption}
                                                        multipleviewOnSelectOption={multipleviewOnSelectOption}
                                                        onCheck={handleOnCheck}
                                                        checked={checkboxValue}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        weeklyStartDate={weeklyStartDate}
                                                        onStartDateChange={handleStartDateChange}
                                                        onweeklyStartDateChange={onweeklyStartDateChange}
                                                        monthlyStartDate={monthlyStartDate}
                                                        onmonthlyStartDateChange={onmonthlyStartDateChange}
                                                        onEndDateChange={handleEndDateChange}
                                                        dailyFilter={dailyDataFilter}
                                                        onDailyFilterSubmitted={onDailyFilterSubmitted}
                                                        selectedServiceId={(activeDailyDataTab === 'reachability') ? (!isNullOrUndefined(selectedServiceDropdownOption) ? selectedServiceDropdownOption.value : '') : selectedServiceId}
                                                        GetPerformancePopupData={GetPerformancePopupData}
                                                        GetWeeklySelfServeData={GetWeeklySelfServeData}
                                                        OpenCommentsPopup={OpenCommentsPopup}
                                                        dailyData={dailyData}
                                                        ReachabilityData={ReachabilityData}
                                                        onMSERComments={onMSERComments}
                                                        viewAllComments={viewAllComments}
                                                        checkedMSER={chkMSER}
                                                        onMSERCheck={handleMSEROCheck}
                                                        checkedMonthlyAvailability={chkMonltyAvailability}
                                                        onMonthlyAvailabilityCheck={handleMonthlyAvailabilityOnCheck}
                                                        OpenBulkEditPOPUP={OpenBulkEditPOPUP}
                                                        BulkEdit={chkBulkEdit}
                                                        ChangeChkBulkEdit={ChangeChkBulkEdit}
                                                        OpenBGMPopup={OpenBGMPopup}
                                                        OpenNewBGMPopup={OpenNewBGMPopup}
                                                        checkHistory={chkHistory}
                                                        onCheckHistory={handleOnCheckHistory}
                                                        activeDailyDataTab={activeDailyDataTab}
                                                        Newview={Newview}
                                                        ChangeNewView={ChangeNewView}
                                                        onReachabilityCheck={onReachabilityCheck}
                                                        checkedReachability={checkedReachability}
                                                        ChangeViewName={SetViewconfigvalues}
                                                        ConfigurationView={ConfigurationView}
                                                        showMSERComments={showMSERComments}
                                                        toggleMSERCommentsView={toggleMSERCommentsView}
                                                        bulkcellcount={bulkcellcount}
                                                        reachabilityServices={reachabilityServices}
                                                        serviceOnSelectOption={reachabilityServiceOnSelectOption}
                                                        serviceSelectedOption={selectedServiceDropdownOption}
                                                        checkedFilterYellowDailyData={checkedFilterYellowDailyData}
                                                        checkedFilterRedDailyData={checkedFilterRedDailyData}
                                                        checkedFilterYellowFleetData={checkedFilterYellowFleetData}
                                                        checkedFilterRedFleetData={checkedFilterRedFleetData}
                                                        onFilterYellowDailyData={onFilterYellowDailyData}
                                                        onFilterRedDailyData={onFilterRedDailyData}
                                                        onFilterYellowFleetData={onFilterYellowFleetData}
                                                        onFilterRedFleetData={onFilterRedFleetData}
                                                        saveAllWeeklyChanges={saveAllWeeklyChanges}
                                                        IsEdit={IsEdit}
                                                        changeshowdrilldown={changeshowdrilldown}
                                                        IsshowDrilldownIcon={IsshowDrilldownIcon}
                                                        sendEmail={sendEmail}
                                                        IsshowEmail={IsshowEmail}
                                                        drilldown={drilldown}
                                                        IsProd={isProd}
                                                        MultipleViewSelected={MultipleViewSelected}
                                                        MultipleViewSelectedChange={MultipleViewSelectedChange}
                                                        TSGValue={TSGValue}
                                                        HeatmapInactive={HeatmapInactive}
                                                        ChangeHeatmapStatus={ChangeHeatmapStatus}
                                                    />
                                                    <React.Fragment>
                                                        {
                                                            (selectedDropdownOption.label === 'Daily') ?
                                                                (IsHeatmapConfigUserGuideSelected) ?
                                                                    <HeatmapConfigurationGuide
                                                                        hideheatMapConfigUserGuideData={hideheatMapConfigUserGuideData}
                                                                    />
                                                                    : (MultipleViewSelected) ?
                                                                        <MultipleHeatMapViewData
                                                                            GetHeatmapConfigurationGuide={GetHeatmapConfigurationGuide}
                                                                            mvdailyData={mvdailyData}
                                                                            Multipleviewdetailsdata={Multipleviewdetailsdata}
                                                                            mvComments={mvcomments}
                                                                            mvcolorScheme={mvcolorScheme}
                                                                            mvDailyDataFailure={MVDailyDataFailure}
                                                                            mvIsdataLoaded={MVIsdataLoaded}
                                                                            multipleviewSelectedOption={multipleviewSelectedOption}
                                                                            CommentSummaryMultiView={CommentSummaryMultiView}
                                                                        />
                                                                        : <DailyData
                                                                            dailyData={dailyData}
                                                                            uniqueDateHeaders={uniqueDateHeaders}
                                                                            uniqueFixedHeaderColumns={uniqueFixedHeaderColumns}
                                                                            handleShow={(e, Item, key, chkBulkEdit) => handleShow(e, Item, key, chkBulkEdit)}
                                                                            comments={comments}
                                                                            colorScheme={colorScheme}
                                                                            SelectedView={SelectedView}
                                                                            CommentSummary={CommentSummary}
                                                                            valchange={(e, childkey, parentkey) => valchange(e, childkey, parentkey)}
                                                                            serviceId={selectedServiceId}
                                                                            BulkEdit={chkBulkEdit}
                                                                            IsdataLoaded={IsdataLoaded}
                                                                            DailyDataFailure={DailyDataFailure}
                                                                            IsReachabilitydataLoaded={IsReachabilitydataLoaded}
                                                                            activeDailyDataTab={activeDailyDataTab}
                                                                            fleetheatmapData={fleetheatmapData}
                                                                            Datelabels={Datelabels}
                                                                            IsfleetDataloaded={IsfleetDataloaded}
                                                                            DailyDataTabstoggle={DailyDataTabstoggle}
                                                                            ColorLogicsData={ColorLogicsData}
                                                                            ColorshemesData={ColorshemesData}
                                                                            noofdecimalsdata={noofdecimalsdata}
                                                                            ConfigurationView={ConfigurationView}
                                                                            colorSchemeValue={colorSchemeValue}
                                                                            coloringLogicValue={coloringLogicValue}
                                                                            noOfDecimalValue={noOfDecimalValue}
                                                                            volumeDisplayTypeValue={volumeDisplayTypeValue}
                                                                            authenticationTypeValue={authenticationTypeValue}
                                                                            ChangeConfigViewValues={SetViewconfigvalues}
                                                                            setColorSchemevalue={setColorSchemevalue}
                                                                            setColorlogic={setColorlogic}
                                                                            setnoofdecimalsvalue={setnoofdecimalsvalue}
                                                                            setDisplayvolumevalue={setDisplayvolumevalue}
                                                                            setAuthenticationTypevalue={setAuthenticationTypevalue}
                                                                            disableupdatebutton={Disableviewconfigbutton}
                                                                            UpdateViewConfigurationData={UpdateViewConfigurationData}
                                                                            additionalInfoData={additionalInfoData}
                                                                            showMSERComments={showMSERComments}
                                                                            ReachabilityData={ReachabilityData}
                                                                            ReachabilityuniqueDateHeaders={ReachabilityuniqueDateHeaders}
                                                                            ReachabilityuniqueFixedHeaderColumns={ReachabilityuniqueFixedHeaderColumns}
                                                                            Reachabilitycomments={Reachabilitycomments}
                                                                            ReachabilitycolorScheme={ReachabilitycolorScheme}
                                                                            ReachabilitySelectedView={ReachabilitySelectedView}
                                                                            checkedFilterYellowDailyData={checkedFilterYellowDailyData}
                                                                            checkedFilterRedDailyData={checkedFilterRedDailyData}
                                                                            OpenDetailedQuery={(e, data, metric) => OpenDetailedQuery(e, data, metric)}
                                                                            drilldown={drilldown}
                                                                            checkedFilterYellowFleetData={checkedFilterYellowFleetData}
                                                                            checkedFilterRedFleetData={checkedFilterRedFleetData}
                                                                            GetHeatmapConfigurationGuide={GetHeatmapConfigurationGuide}
                                                                            volumeLabel={isArrayWithLength(SelectedView) ? (isNullOrUndefined(SelectedView[0].totalsLabel) ? 'Volume' : SelectedView[0].totalsLabel) : 'Volume'}
                                                                            dcMaxLength={dcMaxLength}
                                                                            fleetDatacolorscheme={fleetDatacolorscheme}
                                                                            UpdateLabelValues={UpdateLabelValues}
                                                                            UpdateViewName={UpdateViewName}
                                                                        />
                                                                : (selectedDropdownOption.label === 'Weekly') ?
                                                                    <WeeklyReport
                                                                        WeeklyData={weeklyData}
                                                                        weekDetailsData={weekDetailsData}
                                                                        draftData={draftsData}
                                                                        TDCapactyMaagement={TDCapactyMaagement}
                                                                        AddColorForFact={AddColorForFact}
                                                                        ColorRepireItem={ColorRepireItem}
                                                                        weeklyStartDate={weeklyStartDate}
                                                                        IsEdit={IsEdit}
                                                                        ChangeIsEditval={(e, val) => ChangeIsEditval(e, val)}
                                                                        ChangeLastweekcellvalue={(e, index, val) => ChangeLastweekcellvalue(e, index)}
                                                                        ChangeLastweekcellcomments={(e, index, val) => ChangeLastweekcellcomments(e, index)}
                                                                        serviceId={selectedServiceId}
                                                                        saveAllWeeklyChanges={saveAllWeeklyChanges}
                                                                        showHistorypopup={(val) => showHistorypopup(val)}
                                                                        LoadKPICalculationData={(e, MetricName) => LoadKPICalculationData(e, MetricName)}
                                                                        setrowcountData={(e, index) => setrowcount(e, index)}
                                                                        loadChatKpi={(e, count, isKpiLevelChat,KpiName) => loadChatKpi(e, count, isKpiLevelChat,KpiName)}
                                                                        OpenBGMPopup={OpenBGMPopup}
                                                                        commentsData={commentsData}
                                                                        GetPerformancePopupData={GetPerformancePopupData}
                                                                        showHistory={chkHistory}
                                                                        IsWeeklyDataloaded={IsWeeklyDataloaded}
                                                                        IsWeeklyDataDisabled={IsWeeklyDataDisabled}
                                                                        IsProd={isProd}
                                                                    />
                                                                    : (selectedDropdownOption.label === 'Monthly') ?
                                                                        (isArrayWithLength(monthlyData)) ?
                                                                            <MonthlyReport
                                                                                MonthlyData={monthlyData}
                                                                                MonthlyheaderArr={MonthlyheaderArr}
                                                                                MonthlyKPiArr={MonthlyKPiArr}
                                                                                monthlyStartDate={monthlyStartDate}
                                                                                liKPIValueKeys={liKPIValueKeys}
                                                                            /> : (IsMonthlyDataLoaded === true) ? <div style={{ color: 'Red', fontWeight: 'bold', textAlign: 'center' }}><br />No Monthly data available for the selected service</div> : <React.Fragment></React.Fragment>
                                                                        : <div></div>
                                                        }
                                                    </React.Fragment>
                                                </React.Fragment>
                                }
                            </div>
                        </section>
                    }
                    <Modal show={MSERCommentsPopup} onHide={closesetMSERCommentsPopup} size='lg' backdrop='static'>
                        <Modal.Header closeButton className="commonModalHeader">
                            <div style={{ width: '100%' }}>
                                <Row>
                                    <Col lg='12' style={{ textAlign: 'center' }}>
                                        <h4><b>  Add or Update MSER Comments </b></h4>
                                    </Col>
                                </Row>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col lg='6'>
                                    <div style={{ display: 'inline-block' }}>
                                        <label> Data Center:<span style={{ fontWeight: 'bold', color: 'red' }}>*</span></label>
                                        &emsp;&emsp;
                                    </div>
                                    <div style={{ display: 'inline-block', width: '200px' }}>
                                        <Picky
                                            id='picky'
                                            options={datacentersList}
                                            valueKey='id'
                                            labelKey='name'
                                            multiple={true}
                                            value={selectedDataCenter}
                                            includeSelectAll={true}
                                            includeFilter={false}
                                            onChange={changedataCenterList}
                                            dropdownHeight={200}
                                            numberDisplayed={1}
                                            placeholder='select DataCenter'
                                        />
                                    </div>
                                </Col>
                                <Col lg='6'>
                                    <div style={{ display: 'inline-block' }}>
                                        <label> Effective Date:<span style={{ fontWeight: 'bold', color: 'red' }}>*</span></label>
                                        &emsp;&emsp;
                                    </div>
                                    <div style={{ display: 'inline-block', width: '200px' }}>
                                        <DatePicker className='InputStyle'
                                            selected={effectiveDate}
                                            onChange={date => SeteffectiveDate(date)}
                                            maxDate={endDate}
                                            minDate={startDate}
                                            format={"dd-MM-yyyy hh:mm:ss a"}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: '15px' }}>
                                <Col lg='12'> Availability Issue:<small> (Either enter Additional Info or choose Additional Info)</small> </Col>
                            </Row>
                            <Row>
                                <Col lg='12'>
                                    <select className='form-control' value={AdditionInfoId} onChange={e => changeadditionalInfoId(e)}>
                                        <option value=''>--Choose Additional Info--</option>
                                        {isArrayWithLength(additionalInfoData) && additionalInfoData.map((e, key) => {
                                            return <option key={key} value={e.additionInfoId}>{e.index}{e.keyDate}</option>;
                                        })}
                                    </select>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: '15px' }}>
                                <Col lg='12'>
                                    <RichTextEditor
                                        value={impact}
                                        onChange={SetimpactValue}
                                        toolbarConfig={toolbarConfig}
                                        toolbarClassName="impact-toolbar"
                                        editorClassName="trix-editor"
                                        type="string"
                                        multiline
                                        variant="filled"
                                    />
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: '15px' }}>
                                <Col lg='12'>
                                    <RichTextEditor
                                        value={rootCause}
                                        onChange={SetrootCauseValue}
                                        toolbarConfig={toolbarConfig}
                                        toolbarClassName="rootCause-toolbar"
                                        editorClassName="trix-editor"
                                        type="string"
                                        multiline
                                        variant="filled"
                                    />
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: '15px' }}>
                                <Col lg='12'>
                                    <RichTextEditor
                                        value={mitigation}
                                        onChange={SetmitigationValue}
                                        toolbarConfig={toolbarConfig}
                                        toolbarClassName="mitigation-toolbar"
                                        editorClassName="trix-editor"
                                        type="string"
                                        multiline
                                        variant="filled"
                                    />
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: '15px' }}>
                                <Col lg='12'>
                                    <RichTextEditor
                                        value={learningsAndRepairItems}
                                        onChange={SetlearningsAndRepairItemsValue}
                                        toolbarConfig={toolbarConfig}
                                        toolbarClassName="learningsAndRepairItems-toolbar"
                                        editorClassName="trix-editor"
                                        type="string"
                                        multiline
                                        variant="filled"
                                    />
                                </Col>

                            </Row>
                            <Row style={{ paddingTop: '15px' }}>
                                <Col>
                                    <Button className='DailydataModelbuttons' style={{ float: 'right' }} onClick={(e) => SubmitMSERAdditionalInfo(additionalInfoData,'false')} disabled={ValidateMSERAdditionalInfo()}>Save</Button>
                                </Col>
                                <Col>
                                    <Button className='DailydataModelbuttons' onClick={(e) => DeleteMSERComments(AdditionInfoId)} disabled={ValidateMSERCommentDeletebutton()}>Delete</Button>
                                </Col>

                            </Row>
                        </Modal.Body>

                    </Modal>
                    <Modal show={AllCommentsPopup} onHide={closesetAllCommentsPopup} size='xl' backdrop='static'>
                        <Modal.Header closeButton className="commonModalHeader">
                            <div style={{ width: '100%' }}>
                                <Row>
                                    <Col lg='12' style={{ textAlign: 'center' }}>
                                        {(activeDailyDataTab === 'reachability') ? <h4><b> Reachability – All Comments</b></h4> : (activeDailyDataTab === 'heatmap') ? <h4><b> HeatMap – All Comments</b></h4> : <span></span>}
                                    </Col>
                                </Row>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                {(activeDailyDataTab === 'reachability') ?
                                    <React.Fragment>
                                        {(isArrayWithLength(Reachabilitycomments)) ?
                                            <Col lg='12'>
                                                <table className="tblCommentsDaily">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: '2%' }}>Id</th>
                                                            <th style={{ width: '20%' }}>Comments</th>
                                                            <th style={{ width: '10%' }}>Impacted DCs</th>
                                                            <th style={{ width: '6%' }}>Incident# / Workitem#</th>
                                                            <th style={{ width: '6%' }}>Status</th>
                                                            <th style={{ width: '11%' }}>Problem statement</th>
                                                            <th style={{ width: '8%' }}>Root Cause</th>
                                                            <th style={{ width: '11%' }}>Team owning root cause</th>
                                                            <th style={{ width: '8%' }}>Impact</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Reachabilitycomments.map((item: IComments, index) => (
                                                            <React.Fragment key={index}>
                                                                {
                                                                    <tr>
                                                                        <td style={{ width: '2%' }}>{index + 1}</td>
                                                                        <td style={{ width: '20%' }}>{item.comment}</td>
                                                                        <td style={{ width: '10%' }}>{item.keyDate}</td>
                                                                        <td style={{ width: '6%' }}>
                                                                            {
                                                                                (item.incident && (item.incident != '0') && item.incident.length > 8)
                                                                                    ?
                                                                                    <span>
                                                                                        <a href={'https://portal.microsofticm.com/imp/v3/incidents/details/' + item.incident + '/home'} target="_blank"> {item.incident}</a>
                                                                                    </span>
                                                                                    : (item.incident && (item.incident != '0') && item.incident.length < 8)
                                                                                        ?
                                                                                        <span>
                                                                                            <a href={'https://identitydivision.visualstudio.com/Engineering/_workitems/edit/' + item.incident} target="_blank"> {item.incident}</a>
                                                                                        </span>
                                                                                        :
                                                                                        <span></span>
                                                                            }
                                                                        </td>
                                                                        <td style={{ width: '6%' }}>{item.status}</td>
                                                                        <td style={{ width: '11%' }}>{item.problemStatement}</td>
                                                                        <td style={{ width: '8%' }}>{item.rootCause}</td>
                                                                        <td style={{ width: '11%' }}>{item.owningTeam}</td>
                                                                        <td style={{ width: '8%' }}>{item.impact}</td>
                                                                    </tr>
                                                                }
                                                            </React.Fragment>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </Col> :
                                            <Col lg='12' style={{ textAlign: 'center'}}>
                                                <span style={{ color: 'Red', fontWeight: 'bold'}}> <br />No Data Available!!</span>
                                            </Col>}
                                    </React.Fragment> :
                                    (activeDailyDataTab === 'heatmap') ?
                                        <React.Fragment>
                                            {(isArrayWithLength(comments)) ?
                                                <Col lg='12'>
                                                    <table className="tblCommentsDaily">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: '2%' }}>Id</th>
                                                                <th style={{ width: '20%' }}>Comments</th>
                                                                <th style={{ width: '10%' }}>Impacted DCs</th>
                                                                <th style={{ width: '6%' }}>Incident# / Workitem#</th>
                                                                <th style={{ width: '6%' }}>Status</th>
                                                                <th style={{ width: '11%' }}>Problem statement</th>
                                                                <th style={{ width: '8%' }}>Root Cause</th>
                                                                <th style={{ width: '11%' }}>Team owning root cause</th>
                                                                <th style={{ width: '8%' }}>Impact</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {comments.map((item: IComments, index) => (
                                                                <React.Fragment key={index}>
                                                                    {
                                                                        <tr>
                                                                            <td style={{ width: '2%' }}>{index + 1}</td>
                                                                            <td style={{ width: '20%' }}>{item.comment}</td>
                                                                            <td style={{ width: '10%' }}>{item.keyDate}</td>
                                                                            <td style={{ width: '6%' }}>
                                                                                {
                                                                                    (item.incident && (item.incident != '0') && item.incident.length > 8)
                                                                                        ?
                                                                                        <span>
                                                                                            <a href={'https://portal.microsofticm.com/imp/v3/incidents/details/' + item.incident + '/home'} target="_blank"> {item.incident}</a>
                                                                                        </span>
                                                                                        : (item.incident && (item.incident != '0') && item.incident.length < 8)
                                                                                            ?
                                                                                            <span>
                                                                                                <a href={'https://identitydivision.visualstudio.com/Engineering/_workitems/edit/' + item.incident} target="_blank"> {item.incident}</a>
                                                                                            </span>
                                                                                            :
                                                                                            <span></span>
                                                                                }
                                                                            </td>
                                                                            <td style={{ width: '6%' }}>{item.status}</td>
                                                                            <td style={{ width: '11%' }}>{item.problemStatement}</td>
                                                                            <td style={{ width: '8%' }}>{item.rootCause}</td>
                                                                            <td style={{ width: '11%' }}>{item.owningTeam}</td>
                                                                            <td style={{ width: '8%' }}>{item.impact}</td>
                                                                        </tr>
                                                                    }
                                                                </React.Fragment>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </Col>
                                                :
                                                <Col lg='12' style={{ textAlign: 'center' }}>
                                                    <span style={{ color: 'Red', fontWeight: 'bold'}}> <br />No Data Available!!</span>
                                                </Col>
                                            }
                                        </React.Fragment>
                                        : <React.Fragment></React.Fragment>}
                            </Row>
                        </Modal.Body>
                    </Modal>
                    <Modal show={show} onHide={handleClose} size='lg' backdrop='static'>
                        <Modal.Header closeButton className="commonModalHeader">
                            <div style={{ width: '100%' }}>
                                <Row>
                                    <Col lg='4'>
                                        <div style={{ float: 'right' }}> <b> Original {(ActiveView !== undefined && ActiveView !== null) ? ActiveView.val1Label : ''} (%) : </b></div>
                                    </Col>
                                    <Col lg='2'>
                                        <div style={{ float: 'left' }}>
                                            {(ActiveCell !== undefined && ActiveCell !== null) ? ActiveCell.value.value1 : ''}
                                        </div>
                                    </Col>
                                    <Col lg='3'>
                                        <div style={{ float: 'right' }}>
                                            {
                                                (Selected_View !== undefined && Selected_View !== null)
                                                    ?
                                                    (
                                                        (Selected_View.hasVal2 === true) ? <div><b> Original {ActiveView.val2Label} : </b></div> : <React.Fragment></React.Fragment>
                                                    )
                                                    : <React.Fragment></React.Fragment>
                                            }
                                        </div>
                                    </Col>
                                    <Col lg='1'>
                                        <div style={{ float: 'left' }}>
                                            {
                                                (Selected_View !== undefined && Selected_View !== null)
                                                    ?
                                                    (
                                                        (Selected_View.hasVal2 === true) ? <div> {(ActiveCell !== undefined && ActiveCell !== null) ? ActiveCell.value.value2 : ''} </div> : <React.Fragment></React.Fragment>
                                                    )
                                                    : <React.Fragment></React.Fragment>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col lg='4'><label> Modified {(ActiveView !== null) ? ActiveView.val1Label : ''}(%) </label></Col>
                                <Col lg='1'><b> :</b> </Col>
                                <Col lg='6'><input type='text' value={(CommentObj !== null) ? CommentObj.umodifieduptime : ''} onChange={e => changeComments(e, 'uptime')} className='form-control' placeholder='This must be a number with up to 5 decimal places' /></Col>
                                <Col lg='1'>  </Col>
                            </Row>
                            <div>
                                {
                                    (Selected_View !== undefined && Selected_View !== null)
                                        ?
                                        (
                                            (Selected_View.hasVal2 === true)
                                                ?
                                                <Row style={{ paddingTop: '15px' }}>
                                                    <Col lg='4'><label> Modified {(ActiveView !== null) ? ActiveView.val2Label : ''}(%) </label></Col>
                                                    <Col lg='1'><b> :</b> </Col>
                                                    <Col lg='6'><input type='text' value={(CommentObj !== null) ? CommentObj.uModifiedQos : ''} onChange={e => changeComments(e, 'QOS')} className='form-control' placeholder='This must be a number with up to 5 decimal places' /></Col>
                                                    <Col lg='1'>  </Col>
                                                </Row>
                                                :
                                                <React.Fragment></React.Fragment>
                                        )
                                        : <React.Fragment></React.Fragment>
                                }
                            </div>
                            <Row style={{ paddingTop: '9px' }}>
                                <Col lg='4'> Comment:<br /><small> (Either enter comments or choose comments)</small></Col>
                                <Col lg='1'> <b>:</b> </Col>
                                <Col lg='6'>
                                    {
                                        (CommentObj !== undefined && CommentObj !== null)
                                            ?
                                            <select className='form-control' value={CommentObj.commentId} name='comments' onChange={e => changeComments(e, 'CID')}>
                                                <option value=''>--Choose Comments--</option>
                                                {
                                                    (activeDailyDataTab === 'heatmap') ?
                                                        isArrayWithLength(comments) && comments.map((e, key) => {
                                                            return <option key={key} value={e.commentId}>{key + 1}. {e.comment}</option>;
                                                        })
                                                        : (activeDailyDataTab === 'reachability') ?
                                                            isArrayWithLength(Reachabilitycomments) && Reachabilitycomments.map((e, key) => {
                                                                return <option key={key} value={e.commentId}>{key + 1}. {e.comment}</option>;
                                                            })
                                                            : <React.Fragment></React.Fragment>
                                                }
                                            </select>
                                            : <React.Fragment></React.Fragment>
                                    }
                                </Col>
                                <Col lg='1'>  </Col>
                            </Row>
                            <Row>
                                <Col lg='4'></Col>
                                <Col lg='1'></Col>
                                <Col lg='6'>
                                    <textarea placeholder='Enter Comments' className='form-control' value={(CommentObj !== null && CommentObj !== undefined) ? CommentObj.comment : ''} onChange={e => changeComments(e, 'comments')} />
                                </Col>
                                <Col lg='1'>  </Col>
                            </Row>
                            <Row style={{ paddingTop: '9px' }}>
                                <Col lg='4'> Incident ID / ADO Workitem ID:<br /> <small>(Enter Incident ID or ADO Workitem ID) </small></Col>
                                <Col lg='1'><b> :</b> </Col>
                                <Col lg='6'>
                                    {IncidentCheck}
                                    <input type='text' maxLength={10} onKeyDown={handleKeypress} className='form-control' onChange={e => changeComments(e, 'Incident')} value={(CommentObj !== null && CommentObj !== undefined) ? CommentObj.incident : ''} onBlur={e => FetchIncidentDetails()} />
                                    {
                                        (IncidentCheck)
                                            ?
                                            <span style={{ fontWeight: 'bold', color: 'red' }}>Invalid ID</span>
                                            : <span></span>
                                    }
                                </Col>
                                <Col lg='1'>  </Col>
                            </Row>
                            <Row style={{ paddingTop: '9px' }}>
                                <Col lg='4'> Status
                                    {(IncidentIDPresent === true) ?
                                        <span style={{ fontWeight: 'bold', color: 'red' }}>*</span>
                                        : <React.Fragment></React.Fragment>
                                    }
                                </Col>
                                <Col lg='1'><b> :</b> </Col>
                                <Col lg='6'>
                                    <input type='text' maxLength={255} className='form-control' disabled={Isloading} onChange={e => changeComments(e, 'status')} value={(CommentObj !== null && CommentObj !== undefined) ? ((CommentObj.status !== null) ? CommentObj.status : '') : ''} />
                                </Col>
                                <Col lg='1'>  </Col>
                            </Row>
                            <Row style={{ paddingTop: '9px' }}>
                                <Col lg='4'> Problem Statement
                                    {(IncidentIDPresent === true) ?
                                        <span style={{ fontWeight: 'bold', color: 'red' }}>*</span>
                                        : <React.Fragment></React.Fragment>
                                    }
                                </Col>
                                <Col lg='1'><b> :</b> </Col>
                                <Col lg='6'>
                                    <textarea className='form-control' disabled={Isloading} value={(CommentObj !== null && CommentObj !== undefined) ? ((CommentObj.problemStatement !== null) ? CommentObj.problemStatement : '') : ''} onChange={e => changeComments(e, 'problemStatement')} maxLength={255} />
                                </Col>
                                <Col lg='1'>  </Col>
                            </Row>
                            <Row style={{ paddingTop: '9px' }}>
                                <Col lg='4'> Root Cause:</Col>
                                <Col lg='1'><b> :</b> </Col>
                                <Col lg='6'>
                                    <textarea className='form-control' value={(CommentObj !== null && CommentObj !== undefined) ? ((CommentObj.rootCause !== null) ? CommentObj.rootCause : '') : ''} onChange={e => changeComments(e, 'rootCause')} maxLength={255} />
                                </Col>
                                <Col lg='1'>  </Col>
                            </Row>
                            <Row style={{ paddingTop: '9px' }}>
                                <Col lg='4'> Team owning root cause
                                    {(IncidentIDPresent === true) ?
                                        <span style={{ fontWeight: 'bold', color: 'red' }}>*</span>
                                        : <React.Fragment></React.Fragment>
                                    }
                                </Col>
                                <Col lg='1'><b> :</b> </Col>
                                <Col lg='6'>
                                    <textarea className='form-control' disabled={Isloading} value={(CommentObj !== null && CommentObj !== undefined) ? ((CommentObj.owningTeam !== null) ? CommentObj.owningTeam : '') : ''} onChange={e => changeComments(e, 'owningTeam')} maxLength={255} />
                                </Col>
                                <Col lg='1'>  </Col>
                            </Row>
                            <Row style={{ paddingTop: '9px' }}>
                                <Col lg='4'> Impact: </Col>
                                <Col lg='1'><b> :</b> </Col>
                                <Col lg='6'>
                                    <input type='text' maxLength={255} className='form-control' value={(CommentObj !== null && CommentObj !== undefined) ? ((CommentObj.impact !== null) ? CommentObj.impact : '') : ''} onChange={e => changeComments(e, 'impact')}/>
                                </Col>
                                <Col lg='1'>  </Col>
                            </Row>
                            <div style={{ paddingTop: '9px' }}>
                                {
                                    (ActiveCell !== undefined && ActiveCell !== null)
                                        ?
                                        (ActiveCell.author !== '')
                                            ?
                                            <Row>
                                                <Col lg='4'>  Author: </Col>
                                                <Col lg='1'><b> :</b> </Col>
                                                <Col lg='6'>
                                                    {ActiveCell.author}
                                                </Col>
                                                <Col lg='1'>  </Col>
                                            </Row>
                                            : <React.Fragment></React.Fragment>
                                        : <React.Fragment></React.Fragment>
                                }
                            </div>
                            <div style={{ paddingTop: '9px' }}>
                                {
                                    (ActiveCell !== undefined && ActiveCell !== null)
                                        ?
                                        (ActiveCell.lastUpdated !== '' && ActiveCell.lastUpdated !== null && ActiveCell.lastUpdated !== undefined)
                                            ?
                                            <Row>
                                                <Col lg='4'>  Updated On: </Col>
                                                <Col lg='1'><b> :</b> </Col>
                                                <Col lg='6'>
                                                    {ActiveCell.lastUpdated}
                                                </Col>
                                                <Col lg='1'>  </Col>
                                            </Row>
                                            : <React.Fragment></React.Fragment>
                                        : <React.Fragment></React.Fragment>
                                }
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div style={{ width: '100%' }}>
                                <Row>
                                    <Col lg='1'></Col>
                                    <Col lg='3'>
                                        {
                                            (Isloading) ? <img src={bxloader} style={{ width: '30px', height: '30px' }} />
                                                : <Button className='DailydataModelbuttons' onClick={SubmitComments} disabled={Disablesubmitbutton || DisableIncidentSubmitButton || IncidentCheck}>Submit</Button>
                                        }
                                    </Col>
                                    <Col lg='4' style={{ textAlign: 'center' }}>
                                        <Button className='DailydataModelbuttons' onClick={DeleteComments} disabled={DisableDeletebutton}>Delete</Button>
                                    </Col>
                                    <Col lg='3'>
                                        <Button className='DailydataModelbuttons' disabled={Disablecommentbutton} onClick={ClearComment}>Clear Comment</Button>
                                    </Col>
                                    <Col lg='1'></Col>
                                </Row>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={showBulkEdit} onHide={handleCloseBulkEdit} size='lg' backdrop='static'>
                        <Modal.Header closeButton>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <b>BULK UPDATE</b>
                                <br />
                                <Row style={{ textAlign: 'left' }} className="alert alert-info">
                                    <Col lg="12">
                                        <div>
                                            A process for updating same comments to multiple cells at once. In Addition, if you want to cascade the same comments to different views under the same services, it can be done through the below
                                            <b>"Heatmap Views"</b>
                                            multiselect feature.
                                            <br />
                                            <i>
                                                <b>
                                                    <small style={{ width: '100%', textAlign: 'left', color: 'red', opacity: '0.9' }}>*Cascading same comments to multiple views are possible only if views have the same Data Center.</small>
                                                </b>
                                            </i>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row style={{ paddingTop: '10px' }}>
                                <Col lg='2'></Col>
                                <Col lg='8'>
                                    <span>Selected Cells</span>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: '10px' }}>
                                <Col lg='2'></Col>
                                <Col lg='8'>

                                    <Card body style={{ borderRadius: '8px', width: '100%' }}>
                                        <CardText>
                                            {isArrayWithLength(BulkEditObjs) && BulkEditObjs.map((fhc, key) => (
                                                <React.Fragment key={key}><span>{fhc.datacenter}</span> - <span>{fhc.date}</span><br /></React.Fragment>
                                            ))}
                                        </CardText>
                                    </Card>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: '15px' }}>
                                <Col lg='2'></Col>
                                <Col lg='8'>
                                    <span><b style={{ opacity: '0.8' }}>Heatmap Views : </b><small> (Shows only the matching Data Center views)</small></span>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: '10px' }}>
                                <Col lg='2'></Col>
                                <Col lg='8'>
                                    {
                                        (isArrayWithLength(BulkViewData))
                                            ?
                                            <Picky
                                                id='picky'
                                                options={BulkViewData}
                                                valueKey='id'
                                                labelKey='name'
                                                multiple={true}
                                                value={BulkViewIds}
                                                includeSelectAll={true}
                                                includeFilter={true}
                                                onChange={selectMultipleView}
                                                dropdownHeight={600}
                                                numberDisplayed={1}
                                                placeholder='select Views'
                                            />
                                            : <React.Fragment></React.Fragment>
                                    }
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: '15px' }}>
                                <Col lg='2'></Col>
                                <Col lg='8'>
                                    <span>Comments :<small> (Either enter comments or choose comments)</small> </span>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: '10px' }}>
                                <Col lg='2'></Col>
                                <Col lg='8'>
                                    {
                                        (CommentObj !== undefined && CommentObj !== null)
                                            ?
                                            <select className='form-control' value={BulkCommentId} onChange={e => changeBulkCommentsId(e)}>
                                                <option value=''>--Choose Comments--</option>
                                                {isArrayWithLength(comments) && comments.map((e, key) => {
                                                    return <option key={key} value={e.commentId}>{key + 1}. {e.comment}</option>;
                                                })}

                                            </select>
                                            : <React.Fragment></React.Fragment>
                                    }
                                    <div style={{ paddingTop: '10px' }}>
                                        <textarea placeholder='Enter Comments' className='form-control' value={BulkComment} onChange={e => changeBulkComments(e)} />
                                    </div>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <div style={{ width: '100%' }}>
                                <Row>
                                    <Col lg='1'></Col>
                                    <Col lg='3'>
                                        <Button className='DailydataModelbuttons' onClick={SubmitBulkComments} disabled={Disablesubmitbutton}>Submit</Button>
                                    </Col>
                                    <Col lg='4' style={{ textAlign: 'center' }}>
                                        <Button className='DailydataModelbuttons' onClick={DeleteBulkComments}>Delete</Button>
                                    </Col>
                                    <Col lg='3'>
                                        <Button className='DailydataModelbuttons' disabled={Disablecommentbutton}>Clear Comment</Button>
                                    </Col>
                                    <Col lg='1'></Col>
                                </Row>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={Historyshow} onHide={handlehistoryClose} size='lg' backdrop='static'>
                        <Modal.Header closeButton className="commonModalHeader">
                            <div style={{ width: '100%' }}>
                                <Row>
                                    <Col lg='12' style={{ textAlign: 'center' }}>
                                        <h4><b> Updated History </b></h4>
                                    </Col>
                                </Row>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col lg='12'>
                                    <table style={{ width: '100 %' }} className="table  table-striped  tblPIR tblborder">
                                        <thead>
                                            <tr>
                                                <th className="wklhisth">Metric Name</th>
                                                <th className="wklhisth">Value</th>
                                                <th className="wklhisth">Comments</th>
                                                <th className="wklhisth">Updated Date</th>
                                                <th className="wklhisth">Updated By</th>
                                                <th className="wklhisth">#Version</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isArrayWithLength(KPIHistory) && KPIHistory.map((his, key) => (
                                                <tr key={key}>
                                                    <td>{his.metricName}</td>
                                                    <td>{his.kpiValue}</td>
                                                    <td className="txtcmnt">{his.comments}</td>
                                                    <td>{moment(his.updatedDate).format('MM/DD/YYYY @ h:mma')}</td>
                                                    <td>{his.updatedBy}</td>
                                                    <td>{his.versionId}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                    <Modal show={KPILogic} onHide={KPILogicClose} size='lg' backdrop='static'>
                        <Modal.Header closeButton className="commonModalHeader">
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col lg='12'>
                                    <table className="table table-bordered table-striped  table-hover">
                                        <thead style={{ backgroundColor: 'darkcyan' }}>
                                            <tr>
                                                <th colSpan={2} className="KPIcalth" style={{ width: '8%' }}>{(metricName === 'Availability' || metricName === 'Secrets Management' || metricName === 'Customer notification - JIT pipeline' || metricName === 'Customer notification - API pipeline' || metricName === 'Response to JIT' || metricName === 'Web API') ? metricName + ' / Calculation Details' : metricName}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan={2} style={{ height: '10px' }}><b>Last Updated: {Datefreshed}</b></td>
                                            </tr>
                                            {isArrayWithLength(KPICalculatedlogic) && KPICalculatedlogic.map((obj, key) => (
                                                <tr>
                                                    <td>{obj.metric_Group_Display_Name}</td>
                                                    <td>
                                                        <div dangerouslySetInnerHTML={{ __html: obj.details }}></div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {
                                        (metricName === 'Availability' || metricName === 'Secrets Management' || metricName === 'Customer notification - JIT pipeline' || metricName === 'Customer notification - API pipeline' || metricName === 'Response to JIT' || metricName === 'Web API')
                                            ?
                                            <table className="table table-bordered table-striped table-hover">
                                                <thead style={{ backgroundColor: 'darkcyan' }}>
                                                    <tr>
                                                        <th style={{ width: '20%' }} className="KPIcalth">Metric Name</th>
                                                        <th style={{ width: '18%' }} className="KPIcalth">Environment</th>
                                                        <th style={{ width: '20 %' }} className="KPIcalth">Total Requests</th>
                                                        {
                                                            (selectedServiceId == 3)
                                                                ? <th style={{ width: '18%' }} className="KPIcalth">Total Failures</th>
                                                                : <React.Fragment></React.Fragment>
                                                        }
                                                        <th style={{ width: '10%' }} className="KPIcalth">Percentage Success</th>
                                                        {
                                                            (selectedServiceId == 3)
                                                                ? <th style={{ width: '10 %' }} className="KPIcalth">QOS</th>
                                                                : <th style={{ width: '10%' }} className="KPIcalth">Included test traffic</th>
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {isArrayWithLength(KPIPopupdata) && KPIPopupdata.map((D, key) => (
                                                        <tr>
                                                            {(D.spanMetric != -1) ? <td style={{ textAlign: 'center', verticalAlign: 'middle' }} ng-show="D.spanMetric != -1" rowSpan={D.spanMetric}>{D.metricDisplayName}</td> : <React.Fragment></React.Fragment>}
                                                            <td style={{ textAlign: 'left' }}>
                                                                {D.environment}
                                                            </td>
                                                            <td style={{ textAlign: 'right' }}>
                                                                {D.totalRequests.toLocaleString()}
                                                            </td>
                                                            {
                                                                (selectedServiceId == 3)
                                                                    ? <td style={{ textAlign: 'right' }}>
                                                                        {D.totalFailures.toLocaleString()}
                                                                    </td>
                                                                    : <React.Fragment></React.Fragment>
                                                            }
                                                            <td style={{ textAlign: 'right' }}>{D.percentageSuccess}</td>
                                                            {
                                                                (selectedServiceId == 3)
                                                                    ? <td style={{ textAlign: 'right' }}>{D.qoS}</td>
                                                                    : <td style={{ textAlign: 'right' }}>{D.includedTestTraffic}</td>
                                                            }
                                                        </tr>
                                                    ))}

                                                    {(KPIPopupdata.length == 0) ? <tr><td className='blankTable' colSpan={7}> No Data Found</td></tr> : <React.Fragment></React.Fragment>}
                                                </tbody>
                                            </table>
                                            : <React.Fragment>
                                            </React.Fragment>
                                    }
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                    <Modal show={TFSTicketDetails} onHide={TFSTicketDetailsClose} size='xl' backdrop='static'>
                        <Modal.Header closeButton className="commonModalHeader">
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col lg='12'>
                                    <React.Fragment>
                                        {(Config_Values !== null && Config_Values !== undefined) ? Config_Values.VSOTicketString : ''}
                                        <Tabs defaultActiveKey="DATA" id="uncontrolled-tab-example">
                                            <Tab eventKey="DATA" title="DATA" style={{ overflow: 'scroll', minHeight: '580px', maxHeight: '580px' }}>
                                                <table id="repairItemsTable" style={{ width: '98 %' }} className="table  table-striped  tblPIR tblborder">
                                                    <thead style={{ backgroundColor: 'darkcyan' }}>
                                                        <tr>
                                                            <th colSpan={10} style={{ textAlign: 'center', width: '8%', verticalAlign: 'middle' }} className="KPIcalth">Task Details</th>
                                                        </tr>
                                                    </thead>
                                                    {
                                                        (isArrayWithLength(Task_details))
                                                            ?
                                                            <tbody>
                                                                <tr style={{ height: '50px' }}>
                                                                    <td style={{ minWidth: '90px', maxWidth: '90px', height: '10px', color: '#007bff' }}> <b>ID</b></td>
                                                                    <td style={{ minWidth: '500px', maxWidth: '90px', height: '10px' }}> <b>Ticket Title</b></td>
                                                                    <td style={{ minWidth: '100px', maxWidth: '100px', height: '10px', color: '#007bff' }}> <b>Ticket Status</b></td>
                                                                    <td style={{ minWidth: '150px', maxWidth: '150px', height: '10px', color: '#007bff' }}> <b>SLA Status</b></td>
                                                                    <td style={{ minWidth: '100px', maxWidth: '100px', height: '10px', color: '#007bff' }}> <b>Created Date</b></td>
                                                                    <td style={{ minWidth: '100px', maxWidth: '100px', height: '10px', color: '#007bff' }}> <b>Due Date</b></td>
                                                                    <td style={{ minWidth: '200px', maxWidth: '200px', height: '10px', color: '#007bff' }}> <b>User Name</b></td>
                                                                    <td style={{ minWidth: '100px', maxWidth: '100px', height: '10px', color: '#007bff' }}> <b>Original ETA</b></td>
                                                                    <td style={{ minWidth: '100px', maxWidth: '100px', height: '10px', color: '#007bff' }}> <b>Revised ETA</b></td>
                                                                    <td style={{ minWidth: '70px', maxWidth: '70px', height: '10px', color: '#007bff' }}> <b>Action</b></td>
                                                                </tr>
                                                                {
                                                                    Task_details.map((TT: PropTicketDetails, key) => (
                                                                        <tr>
                                                                            <td style={{ minWidth: '90px', maxWidth: '90px' }}  ><a href={Config_Values.vsoTicketString + TT.tfsId} target="_blank">{TT.tfsId}</a></td>
                                                                            <td style={{ minWidth: '500px', maxWidth: '90px' }}>{TT.ticketTitle}</td>
                                                                            <td style={{ minWidth: '100px', maxWidth: '100px' }} >{TT.ticketStatus}</td>
                                                                            <td style={{ minWidth: '150px', maxWidth: '150px', backgroundColor: (TT.slaStatus == "In SLA") ? '#90ee90' : '#ffb6c1' }} >{TT.slaStatus}</td>
                                                                            <td style={{ minWidth: '100px', maxWidth: '100px' }}>{(TT.createdDate !== null) ? moment(TT.createdDate).format('YYYY-MM-DD') : ''}</td>
                                                                            <td style={{ minWidth: '100px', maxWidth: '100px' }}>{(TT.dueDate !== null) ? moment(TT.dueDate).format('YYYY-MM-DD') : ''}</td>
                                                                            <td style={{ minWidth: '200px', maxWidth: '200px' }} >{TT.userName}</td>
                                                                            <td style={{ minWidth: '100px', maxWidth: '100px' }}>{(TT.scrumOriginalEta !== null) ? moment(TT.scrumOriginalEta).format('YYYY-MM-DD') : ''}</td>
                                                                            <td style={{ minWidth: '100px', maxWidth: '100px' }}>{(TT.scrumRevisedEta !== null) ? moment(TT.scrumRevisedEta).format('YYYY-MM-DD') : ''}</td>
                                                                            <td style={{ minWidth: '70px', maxWidth: '70px' }}><input type="checkbox" checked={TT.chkRemove} onChange={e => CheckTFSItem(e, key)} /></td>
                                                                        </tr>
                                                                    ))
                                                                }

                                                            </tbody>
                                                            : <React.Fragment></React.Fragment>
                                                    }

                                                </table>
                                                <React.Fragment>
                                                    {
                                                        (isArrayWithLength(Task_details)) ?
                                                            <Row>
                                                                <Col lg="7"></Col>
                                                                <Col lg="4">
                                                                    <div style={{ float: 'right', paddingBottom: '10px' }}>
                                                                        <button style={{ height: '38px', fontFamily: 'Helvetica', width: '160px', color: 'white' }} className="btn btn-primary btn-sm filter-hmbutton" type="button" onClick={(e) => exportToCSV(Task_details, '')} title="Download the Repair Items Report"><FontAwesomeIcon title='Click to edit the last week data' icon={faDownload} /><strong style={{ color: 'white' }}> Export Report</strong></button>
                                                                        &nbsp; &nbsp; &nbsp;
                                                                        <button type="button" id="btnRemoveRow" className="btn btn-primary btn-sm filter-button" style={{ height: '38px', fontFamily: 'Helvetica', width: '160px', color: 'white' }} title="Click to Remove Row" onClick={RemoveRepairItemRows}>
                                                                            <FontAwesomeIcon title='Click to edit the last week data' icon={faTrashAlt} />&nbsp;
                                                                            <strong style={{ color: 'white' }}>Remove Item</strong>
                                                                        </button>
                                                                    </div>
                                                                    <br />
                                                                </Col>
                                                                <Col lg="1"></Col>
                                                            </Row>
                                                            :
                                                            <Row>
                                                                <Col lg="12" stylke={{ textAlign: 'center', color: 'red', fontSize: '14px' }}>
                                                                    No Data Found
                                                                </Col>
                                                            </Row>
                                                    }
                                                </React.Fragment>
                                            </Tab>
                                            <Tab eventKey="GRAPH" title="GRAPH">
                                                <div>
                                                    <HighchartsReact highcharts={Highcharts} options={Options} />
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </React.Fragment>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                    <Modal show={TFSLSITicketDetails} onHide={TFSLSITicketDetailsClose} size='lg' backdrop='static'>
                        <Modal.Header closeButton className="commonModalHeader">
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col lg='12'>
                                    <table style={{ width: '100 %' }} className="table  table-striped  tblPIR tblborder">
                                        <thead style={{ backgroundColor: 'darkcyan' }}>
                                            <tr>
                                                <th colSpan={IsSev ? 5 : 7} style={{ textAlign: 'center', width: '8%', verticalAlign: 'middle' }} className="KPIcalth">Ticket Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(isArrayWithLength(LSITicket_details))
                                                ?
                                                <tr >
                                                    <td style={{ height: '10px' }}><b>PIR ID</b></td>
                                                    <td style={{ height: '10px' }}><b>Ticket ID</b></td>
                                                    <td style={{ height: '10px' }}><b>Created Date</b></td>
                                                    <td style={{ height: '10px' }}><b>Ticket Details</b></td>
                                                    <td style={{ height: '10px' }}><b>Ticket Status</b></td>
                                                    {(!IsSev) ? <td style={{ height: '10px' }}><b>IS_LSI</b></td> : <React.Fragment></React.Fragment>}
                                                </tr>
                                                : <React.Fragment></React.Fragment>
                                            }
                                            {isArrayWithLength(LSITicket_details) && LSITicket_details.map((LS, key) => (
                                                <React.Fragment key={key}>
                                                    <tr>
                                                        <td>{LS.pirReportId}</td>
                                                        <td><a href={(!isNullOrUndefined(Config_Values)?Config_Values.icmTicketString:'') + LS.ticketId} target="_blank">{LS.ticketId}</a></td>
                                                        <td>{(LS.createdDate !== null) ? moment(LS.createdDate).format('YYYY-MM-DD') : ''}</td>
                                                        <td>{LS.ticketTitle}</td>
                                                        <td>{LS.ticketStatus}</td>
                                                        {(!IsSev) ? <td ng-hide="IsSev"><input type="checkbox" disabled={IsRead} value="" checked={LS.isLsi} onChange={e => changeLSICheckbox(e, key)} /></td> : <React.Fragment></React.Fragment>}
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                            {
                                                (!NewRec)
                                                    ?
                                                    <tr>
                                                        <td><input type="text" maxLength={10} id="txt_PIRID" style={{ width: '100 %' }} value="" disabled={true} /></td>
                                                        <td><input type="text" maxLength={10} id="txt_TktID" style={{ width: '100 %' }} value="" /></td>
                                                        <td><input type="text" maxLength={10} id="txt_TktDet" style={{ width: '100 %' }} value="" disabled={true} /></td>
                                                        <td><input type="text" maxLength={10} id="txt_TktDes" style={{ width: '100 %' }} value="" disabled={true} /></td>
                                                        <td><input type="checkbox" id="chkLSI" ng-disabled="IsRead" value="" ng-click="chkLSI_Click()" /></td>
                                                    </tr>
                                                    : <React.Fragment></React.Fragment>
                                            }
                                            {
                                                (!IsSev && isArrayWithLength(LSITicket_details))
                                                    ? <tr>
                                                        <td><b>Comments:</b></td>
                                                        <td colSpan={5}><input type="text" disabled={IsRead} value={LSICommentText} onChange={e => changeLSIcomments(e)} maxLength={250} id="txt_LSI_Comment" style={{ width: '100 %' }} /></td>
                                                    </tr>
                                                    : <React.Fragment></React.Fragment>
                                            }
                                            {
                                                (!IsSev && isArrayWithLength(LSITicket_details))
                                                    ? <tr style={{ textAlign: 'center' }}>
                                                        <td colSpan={6} style={{ paddingLeft: '0px;' }}>
                                                            <Row>
                                                                <Col lg="6"></Col>
                                                                <Col lg="6">
                                                                    <button type="button" id="btnAddRow" disabled={IsRead || bAdd} style={{ backgroundColor: '#2e6da4 ' }} className="btn btn-primary btn-sm btnAddLsi"
                                                                        title="Click to Add New Data for LSI" ng-click="AddLSI()">
                                                                        <Row>
                                                                            <Col lg="2"><div> <FontAwesomeIcon icon={faPencilAlt} className="iconAddLsi" /> </div></Col>
                                                                            <Col lg="8"><h6> <b> Add LSI </b></h6></Col>
                                                                        </Row>
                                                                    </button>&nbsp;&nbsp;
                                                                    <button type="button" disabled={IsRead} style={{ backgroundColor: '#2e6da4 ' }} className="btn btn-primary btn-sm btnSave" title="Save the changes to Database" onClick={SaveValPopupEdit}>
                                                                        <Row>
                                                                            <Col lg="2"><div> <FontAwesomeIcon icon={faSave} className="imgSave" /> </div> </Col>
                                                                            <Col lg="8"><h6> <b> Save </b></h6></Col>
                                                                        </Row>
                                                                    </button>
                                                                </Col>
                                                            </Row>
                                                        </td>
                                                    </tr>
                                                    : <React.Fragment></React.Fragment>
                                            }
                                            {
                                                (!isArrayWithLength(LSITicket_details))
                                                    ? <tr ><td className='blankTable' colSpan={5}> No Data Found</td></tr>
                                                    : <React.Fragment></React.Fragment>
                                            }

                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                    <Modal show={BuildSchemaDetails} onHide={BuildSchemaDetailsClose} size='xl' backdrop='static'>
                        <Modal.Header closeButton className="commonModalHeader">
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col lg='12'>
                                    <React.Fragment>
                                        <table id="repairItemsTable" style={{ width: '98 %' }} className="table  table-striped  tblPIR tblborder">
                                            <thead style={{ backgroundColor: 'darkcyan' }}>
                                                <tr>
                                                    <th colSpan={10} style={{ textAlign: 'center', width: '8%', verticalAlign: 'middle' }} className="KPIcalth">Deployment Details</th>
                                                </tr>
                                            </thead>
                                            {
                                                (isArrayWithLength(DeploymentBuild_schemaDetails))
                                                    ?
                                                    <tbody>
                                                        <tr style={{ height: '50px' }}>
                                                            <td style={{ minWidth: '200px', maxWidth: '200px', height: '10px', color: '#007bff' }}> <b>ApiVersion</b></td>                                                            
                                                            <td style={{ minWidth: '200px', maxWidth: '200px', height: '10px', color: '#007bff' }}> <b>SchemaVersion</b></td>
                                                            <td style={{ minWidth: '150px', maxWidth: '150px', height: '10px', color: '#007bff' }}> <b>Start Date</b></td>
                                                            <td style={{ minWidth: '150px', maxWidth: '150px', height: '10px', color: '#007bff' }}> <b>End Date</b></td>
                                                            <td style={{ minWidth: '150px', maxWidth: '150px', height: '10px', color: '#007bff' }}> <b>SLA Status</b></td>
                                                        </tr>
                                                        {
                                                            DeploymentBuild_schemaDetails.map((TT: PropDeploymentBuildDetails, key) => (
                                                                <tr>
                                                                    <td style={{ minWidth: '200px', maxWidth: '200px' }} >{TT.apiVersion}</td>                                                                    
                                                                    <td style={{ minWidth: '200x', maxWidth: '200px' }}>{TT.schemaVersion}</td>
                                                                    <td style={{ minWidth: '150px', maxWidth: '150px' }}>{(TT.startDate !== null) ? moment(TT.startDate).format('YYYY-MM-DD') : ''}</td>
                                                                    <td style={{ minWidth: '150px', maxWidth: '150px' }}>{(TT.endDate !== null) ? moment(TT.endDate).format('YYYY-MM-DD') : ''}</td>
                                                                    <td style={{ minWidth: '150px', maxWidth: '150px', backgroundColor: (TT.slaStatus == "In SLA") ? '#90ee90' : '#ffb6c1'}}>{TT.slaStatus}</td>
                                                                </tr>
                                                            ))
                                                        }

                                                    </tbody>
                                                    : <React.Fragment></React.Fragment>
                                            }
                                        </table>
                                    </React.Fragment>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                    <Modal show={PullRequestSchemaDetails} onHide={PullRequestSchemaDetailsClose} size='xl' backdrop='static'>
                        <Modal.Header closeButton className="commonModalHeader">
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col lg='12'>
                                    <React.Fragment>
                                        <table id="repairItemsTable" style={{ width: '98 %' }} className="table  table-striped  tblPIR tblborder">
                                            <thead style={{ backgroundColor: 'darkcyan' }}>
                                                <tr>
                                                    <th colSpan={10} style={{ textAlign: 'center', width: '8%', verticalAlign: 'middle' }} className="KPIcalth">Pull Request Details</th>
                                                </tr>
                                            </thead>
                                            {
                                                (isArrayWithLength(PullRequest_schemaDetails))
                                                    ?
                                                    <tbody>
                                                        <tr style={{ height: '50px' }}>
                                                            <td style={{ minWidth: '100px', maxWidth: '100px', height: '10px', color: '#007bff' }}> <b>Pull request ID</b></td>
                                                            <td style={{ minWidth: '100px', maxWidth: '100px', height: '10px', color: '#007bff' }}> <b>Code Review Id</b></td>
                                                            <td style={{ minWidth: '500px', maxWidth: '90px', height: '10px', color: '#007bff' }}> <b>Title</b></td>
                                                            <td style={{ minWidth: '100px', maxWidth: '100px', height: '10px', color: '#007bff' }}> <b>Cloud</b></td>
                                                            <td style={{ minWidth: '100px', maxWidth: '100px', height: '10px', color: '#007bff' }}> <b>Created Date</b></td>
                                                            <td style={{ minWidth: '200px', maxWidth: '200px', height: '10px', color: '#007bff' }}> <b>Created By</b></td>
                                                            <td style={{ minWidth: '150px', maxWidth: '150px', height: '10px', color: '#007bff' }}> <b>PR Status</b></td>
                                                            <td style={{ minWidth: '150px', maxWidth: '150px', height: '10px', color: '#007bff' }}> <b>SLA Status</b></td>
                                                        </tr>
                                                        {
                                                            PullRequest_schemaDetails.map((TT: PropPullRequestDetails, key) => (
                                                                <tr>
                                                                    <td style={{ minWidth: '90px', maxWidth: '90px' }}><a href={'https://msazure.visualstudio.com/One/_git/AD-AggregatorService-Workloads/pullrequest/' + TT.pullRequestId} target="_blank">{TT.pullRequestId}</a></td>
                                                                    <td style={{ minWidth: '100px', maxWidth: '100px' }} >{TT.codeReviewId}</td>
                                                                    <td style={{ minWidth: '500px', maxWidth: '90px' }}>{TT.title}</td>
                                                                    <td style={{ minWidth: '100px', maxWidth: '100px' }}>{TT.cloud}</td>
                                                                    <td style={{ minWidth: '100px', maxWidth: '100px' }}>{(TT.creationDate !== null) ? moment(TT.creationDate).format('YYYY-MM-DD') : ''}</td>
                                                                    <td style={{ minWidth: '200px', maxWidth: '200px' }} >{TT.createdBy}</td>
                                                                    <td style={{ minWidth: '150px', maxWidth: '150px' }} >{TT.status}</td>                                                                    
                                                                    <td style={{ minWidth: '150px', maxWidth: '150px', backgroundColor: (TT.slaStatus == "In SLA") ? '#90ee90' : '#ffb6c1' }}>{TT.slaStatus}</td>
                                                                </tr>
                                                            ))
                                                        }

                                                    </tbody>
                                                    : <React.Fragment></React.Fragment>
                                            }
                                        </table>
                                    </React.Fragment>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                    <Modal show={StackOverFlowDetails} onHide={StackOverFlowDetailsClose} size='xl' backdrop='static'>
                        <Modal.Header closeButton className="commonModalHeader">
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col lg='12'>
                                    <React.Fragment>
                                        <table id="repairItemsTable" style={{ width: '98 %' }} className="table  table-striped  tblPIR tblborder">
                                            <thead style={{ backgroundColor: 'darkcyan' }}>
                                                <tr>
                                                    <th colSpan={10} style={{ textAlign: 'center', width: '8%', verticalAlign: 'middle' }} className="KPIcalth">Stack OverFlow Details</th>
                                                </tr>
                                            </thead>
                                            {
                                                (isArrayWithLength(StackOverFlow_ResponseDetails))
                                                    ?
                                                    <tbody>
                                                        <tr style={{ height: '50px' }}>
                                                            <td style={{ minWidth: '100px', maxWidth: '100px', height: '10px', color: '#007bff' }}> <b>Question Id</b></td>
                                                            <td style={{ minWidth: '500px', maxWidth: '500px', height: '10px', color: '#007bff' }}> <b>Question Title</b></td>
                                                            <td style={{ minWidth: '90px', maxWidth: '90px', height: '10px', color: '#007bff' }}> <b>Created Date</b></td>
                                                            <td style={{ minWidth: '100px', maxWidth: '100px', height: '10px', color: '#007bff' }}> <b>Created By</b></td>
                                                            <td style={{ minWidth: '100px', maxWidth: '100px', height: '10px', color: '#007bff' }}> <b>Answered Count</b></td>
                                                            <td style={{ minWidth: '100px', maxWidth: '100px', height: '10px', color: '#007bff' }}> <b>In SLA</b></td>
                                                            <td style={{ minWidth: '100px', maxWidth: '100px', height: '10px', color: '#007bff' }}> <b>Out Of SLA</b></td>
                                                        </tr>
                                                        {
                                                            StackOverFlow_ResponseDetails.map((TT: PropStackOverFlowDetails, key) => (
                                                                <tr>
                                                                    <td style={{ minWidth: '90px', maxWidth: '90px' }}><a href={TT.questionUrl} target="_blank">{TT.questionId}</a></td>
                                                                    <td style={{ minWidth: '500px', maxWidth: '500px' }} >{TT.questionTitle}</td>
                                                                    <td style={{ minWidth: '80px', maxWidth: '80px' }}>{(TT.createdDate !== null) ? moment(TT.createdDate).format('YYYY-MM-DD') : ''}</td>
                                                                    <td style={{ minWidth: '100px', maxWidth: '90px' }}>{TT.questionCreatedBy}</td>
                                                                    <td style={{ minWidth: '80px', maxWidth: '80px' }} >{TT.answerCount}</td>
                                                                    <td style={{ minWidth: '80px', maxWidth: '80px', backgroundColor: (TT.inSLA) ? '#90ee90' : ''}} >{(TT.inSLA)? '1':'0'}</td>
                                                                    <td style={{ minWidth: '80px', maxWidth: '80px', backgroundColor: (TT.outOfSLA) ? '#ffb6c1' : ''}} >{(TT.outOfSLA)? '1':'0'}</td>
                                                                </tr>
                                                            ))
                                                        }

                                                    </tbody>
                                                    : <React.Fragment></React.Fragment>
                                            }
                                        </table>
                                    </React.Fragment>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                    <Modal show={S360Details} onHide={S360DetailsClose} size='xl' backdrop='static'>
                        <Modal.Header closeButton className="commonModalHeader">
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col lg='12'>
                                    <React.Fragment>
                                        <table id="repairItemsTable" style={{ width: '98 %' }} className="table  table-striped  tblPIR tblborder">
                                            <thead style={{ backgroundColor: 'darkcyan' }}>
                                                <tr>
                                                    <th colSpan={10} style={{ textAlign: 'center', width: '8%', verticalAlign: 'middle' }} className="KPIcalth">
                                                        <a href='https://vnext.s360.msftcloudes.com/blades/allup?blade=KPIType:EP;ActionItems;LaunchCriteria;Metric~_loc:allUp&def=&peopleBasedNodes=stevemutungi_team;brbusolo_team;biodero_team&global=4:67ca4ff4-11d9-466d-8232-599383116ea1' target="_blank">S360 Ticket Details</a></th>
                                                </tr>
                                            </thead>
                                            {
                                                (isArrayWithLength(S360Ticket_Details))
                                                    ?
                                                    <tbody>
                                                        <tr style={{ height: '50px' }}>
                                                            <td style={{ minWidth: '100px', maxWidth: '100px', height: '10px', color: '#007bff' }}> <b>Service Group</b></td>
                                                            <td style={{ minWidth: '100px', maxWidth: '100px', height: '10px', color: '#007bff' }}> <b>Service Name</b></td>
                                                            <td style={{ minWidth: '100px', maxWidth: '100px', height: '10px', color: '#007bff' }}> <b>KPI</b></td>
                                                            <td style={{ minWidth: '40px', maxWidth: '40px', height: '10px', color: '#007bff' }}> <b>Out Of Sla</b></td>
                                                            <td style={{ minWidth: '70px', maxWidth: '70px', height: '10px', color: '#007bff' }}> <b>Approaching Sla</b></td>
                                                            <td style={{ minWidth: '40px', maxWidth: '40px', height: '10px', color: '#007bff' }}> <b>In Sla</b></td>
                                                        </tr>
                                                        {
                                                            S360Ticket_Details.map((TT: PropOneDashHistoricalData, key) => (
                                                                <tr>
                                                                    <td style={{ minWidth: '90px', maxWidth: '90px' }}>{TT.serviceGroup}</td>
                                                                    <td style={{ minWidth: '100px', maxWidth: '100px' }}>{TT.serviceName}</td>
                                                                    <td style={{ minWidth: '100px', maxWidth: '100px' }}>{TT.kpi}</td>
                                                                    <td style={{ minWidth: '40px', maxWidth: '40px', backgroundColor: (TT.outOfSla) ? '#ffb6c1' : '' }} >{TT.outOfSla}</td>
                                                                    <td style={{ minWidth: '70px', maxWidth: '70px' }} >{TT.approachingSla}</td>
                                                                    <td style={{ minWidth: '40px', maxWidth: '40px', backgroundColor: (TT.inSla) ? '#90ee90' : '' }} >{TT.inSla}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                    : <React.Fragment></React.Fragment>
                                            }
                                        </table>
                                    </React.Fragment>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                    <Modal show={BGMPopup} onHide={closesetBGMPopup} size='lg' backdrop='static' className="BGMpopwidth">
                        <Modal.Header closeButton className="commonModalHeader" >
                            <div style={{ width: '100%' }}>
                                <Row>
                                    <Col lg='12' style={{ textAlign: 'center' }}>
                                        <h4><b>  Business Growth Metrics </b></h4>
                                    </Col>

                                </Row>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col lg='12'>
                                    <React.Fragment>
                                        {
                                            (ShowExternalBgmLink === false && NoBgmData === false && (weekDetailsData !== null && weekDetailsData !== undefined))
                                                ?
                                                <Tabs defaultActiveKey="BGM" id="uncontrolled-tab-example">
                                                    <Tab eventKey="BGM" title="BGM">
                                                        <table className="table  table-striped  tblPIR tblborder">
                                                            <thead style={{ backgroundColor: 'darkcyan' }}>
                                                                <tr>
                                                                    <th style={{ textAlign: 'center', width: '18%', verticalAlign: 'middle' }} className="KPIcalth">Metrics</th>
                                                                    <th style={{ textAlign: 'center', width: '11%', verticalAlign: 'middle' }} className="KPIcalth">{weekDetailsData.weeksDetails.weekDet1}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week1}</span></th>
                                                                    <th style={{ textAlign: 'center', width: '11%', verticalAlign: 'middle' }} className="KPIcalth">{weekDetailsData.weeksDetails.weekDet2}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week2}</span></th>
                                                                    <th style={{ textAlign: 'center', width: '10%', verticalAlign: 'middle' }} className="KPIcalth">{weekDetailsData.weeksDetails.weekDet3}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week3}</span></th>
                                                                    <th style={{ textAlign: 'center', width: '10%', verticalAlign: 'middle' }} className="KPIcalth">{weekDetailsData.weeksDetails.weekDet4}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week4}</span></th>
                                                                    <th style={{ textAlign: 'center', width: '10%', verticalAlign: 'middle' }} className="KPIcalth">
                                                                        {weekDetailsData.weeksDetails.weekDet5}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week5}</span>
                                                                        {
                                                                            isArrayWithLength(BGMdata)
                                                                                ?
                                                                                (!IsBgmEdit)
                                                                                    ?
                                                                                    <React.Fragment>
                                                                                        <span style={{ cursor: 'pointer' }}>
                                                                                            &nbsp;&nbsp; <FontAwesomeIcon onClick={enableBGMPopupEdit} title='Click to edit the last week data' className="Editiconsfontsize" icon={faPencilAlt} />
                                                                                        </span>
                                                                                    </React.Fragment>
                                                                                    : <React.Fragment>
                                                                                        <span onClick={cancelBGMpopupedit} style={{ cursor: 'pointer' }}>
                                                                                            &nbsp;&nbsp;  <img title='Click to Cancel Edit' src={cancel} style={{ width: '20px', height: '20px' }} />
                                                                                        </span>
                                                                                    </React.Fragment>
                                                                                : <React.Fragment></React.Fragment>
                                                                        }

                                                                    </th>
                                                                    {
                                                                        (IsBgmEdit)
                                                                            ? <th className="KPIcalth">Comments</th>
                                                                            : <React.Fragment></React.Fragment>
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {(isArrayWithLength(BGMdata) && BGMdata.map((D: PropBgm, key) => (
                                                                    <React.Fragment>
                                                                        <tr style={{ height: '50px' }}>
                                                                            <td style={{ 'width': (selectedServiceId == 5 || selectedServiceId == 31) ? '20%' : '' }}>{D.users}</td>
                                                                            <td className={D.weekp1.includes('-') ? 'bgm-red' : D.weekp1.includes('+') ? 'bgm-green' : ''} style={calculateStyle(D.isBGMVariation)}>{D.week1}{D.weekp1}</td>
                                                                            <td className={D.weekp2.includes('-') ? 'bgm-red' : D.weekp2.includes('+') ? 'bgm-green' : ''} style={calculateStyle(D.isBGMVariation)}>{D.week2}{D.weekp2}</td>
                                                                            <td className={D.weekp3.includes('-') ? 'bgm-red' : D.weekp3.includes('+') ? 'bgm-green' : ''} style={calculateStyle(D.isBGMVariation)}>{D.week3}{D.weekp3}</td>
                                                                            <td className={D.weekp4.includes('-') ? 'bgm-red' : D.weekp4.includes('+') ? 'bgm-green' : ''} style={calculateStyle(D.isBGMVariation)}>{D.week4}{D.weekp4}</td>
                                                                            <td className={D.weekp5.includes('-') ? 'bgm-red' : D.weekp5.includes('+') ? 'bgm-green' : ''} style={calculateStyle(D.isBGMVariation)}>
                                                                                {
                                                                                    (IsBgmEdit)
                                                                                        ? <input type='text' maxLength={15} value={D.week5Edit} onChange={e => ChangeBGMLastweek(e, key, D.envId)} className="form-control" style={{ borderColor: D.valuecolor.toString(), width: '122px' }} />
                                                                                        : <p>{D.week5}{D.weekp5}</p>
                                                                                }

                                                                            </td>
                                                                            {
                                                                                (IsBgmEdit)
                                                                                    ? <td className='data'><input type='text' value={D.comment} onChange={e => ChangeBGMLastweekcomment(e, key, D.envId)} className="form-control" style={{ borderColor: D.cmtcolor.toString() }} /></td>
                                                                                    : <React.Fragment></React.Fragment>
                                                                            }
                                                                        </tr>
                                                                    </React.Fragment>
                                                                )))}
                                                                {
                                                                    (IsBgmEdit)
                                                                        ?
                                                                        <tr ng-hide='!IsBgmEdit'>
                                                                            <td colSpan={10} align="center">
                                                                                <a onClick={Edit_Service_BGM_Popup_ToHistory} className='button-format fontsmall' target='_blank' rel='tooltip' title='Save all the changes to Database' aria-hidden='false'>
                                                                                    &nbsp; <FontAwesomeIcon icon={faSave} style={{ color: 'white', backgroundColor: 'None', border: 'none' }} /> &nbsp;Save
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                        : <React.Fragment></React.Fragment>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </Tab>
                                                    <Tab eventKey="graph" title="Graph">
                                                        <div>
                                                            <HighchartsReact highcharts={Highcharts} options={Options} />
                                                        </div>
                                                    </Tab>
                                                </Tabs>
                                                : <React.Fragment></React.Fragment>
                                        }
                                        {
                                            (ShowExternalBgmLink === true)
                                                ? <a href={ExternalLink} onClick={HideModalJQuery} target="_blank">Click here for BGM data in external system (Power BI).</a>
                                                : <React.Fragment></React.Fragment>
                                        }

                                        <br />
                                        {
                                            (ShowExternalBgmLink === true && selectedServiceId === 17)
                                                ? <a href={External_link_NewBGM} onClick={HideModalJQuery} target="_blank">Click here for New Dashboard of BGM data in external system (Power BI).</a>
                                                : <React.Fragment></React.Fragment>
                                        }
                                        {
                                            (selectedServiceId === 2 || selectedServiceId === 18 || selectedServiceId === 21 || selectedServiceId === 22)
                                                ? <h6 style={{ color: 'blue' }}>Business growth metrics data is not reported for the selected service.</h6>
                                                : <React.Fragment></React.Fragment>
                                        }
                                    </React.Fragment>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                    <Modal show={NewBGMPopup} onHide={closesetNewBGMPopup} size='xl' backdrop='static' className="BGMpopwidth">
                        <Modal.Header closeButton className="commonModalHeader" >
                            <div style={{ width: '100%' }}>
                                <Row>
                                    <Col lg='12' style={{ textAlign: 'center' }}>
                                        <h4><b>  Business Growth Metrics </b></h4>
                                    </Col>
                                </Row>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col lg='12'>
                                    <React.Fragment>
                                        {
                                            (ShowExternalBgmLink === false && NoBgmData === false && (weekDetailsData !== null && weekDetailsData !== undefined))
                                                ?
                                                <Tabs defaultActiveKey="BGM" id="uncontrolled-tab-example">
                                                    <Tab eventKey="BGM" title="BGM">
                                                        <table className="table tblPIR tblborder">
                                                            <thead style={{ backgroundColor: 'darkcyan' }}>
                                                                <tr>
                                                                    <th style={{ textAlign: 'center', width: '22%', verticalAlign: 'middle' }} className="KPIcalth">Metrics</th>
                                                                    <th style={{ textAlign: 'center', width: '10%', verticalAlign: 'middle' }} className="KPIcalth">{weekDetailsData.weeksDetails.weekDet1}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week1}</span></th>
                                                                    <th style={{ textAlign: 'center', width: '10%', verticalAlign: 'middle' }} className="KPIcalth">{weekDetailsData.weeksDetails.weekDet2}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week2}</span></th>
                                                                    <th style={{ textAlign: 'center', width: '10%', verticalAlign: 'middle' }} className="KPIcalth">{weekDetailsData.weeksDetails.weekDet3}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week3}</span></th>
                                                                    <th style={{ textAlign: 'center', width: '10%', verticalAlign: 'middle' }} className="KPIcalth">{weekDetailsData.weeksDetails.weekDet4}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week4}</span></th>
                                                                    <th style={{ textAlign: 'center', width: '10%', verticalAlign: 'middle' }} className="KPIcalth">
                                                                        {weekDetailsData.weeksDetails.weekDet5}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week5}</span>
                                                                        {
                                                                            isArrayWithLength(BGMdata)
                                                                                ?
                                                                                (!IsBgmEdit)
                                                                                    ?
                                                                                    <React.Fragment>
                                                                                        <span style={{ cursor: 'pointer' }}>
                                                                                            &nbsp;&nbsp; <FontAwesomeIcon onClick={enableBGMPopupEdit} title='Click to edit the last week data' className="Editiconsfontsize" icon={faPencilAlt} />
                                                                                        </span>
                                                                                    </React.Fragment>
                                                                                    : <React.Fragment>
                                                                                        <span onClick={cancelBGMpopupedit} style={{ cursor: 'pointer' }}>
                                                                                            &nbsp;&nbsp;  <img title='Click to Cancel Edit' src={cancel} style={{ width: '20px', height: '20px' }} />
                                                                                        </span>
                                                                                    </React.Fragment>
                                                                                : <React.Fragment></React.Fragment>
                                                                        }

                                                                    </th>
                                                                    {
                                                                        (IsBgmEdit)
                                                                            ? <th className="KPIcalth">Comments</th>
                                                                            : <React.Fragment></React.Fragment>
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {(isArrayWithLength(BGMdata) && BGMdata.map((D: PropBgm, key) => (
                                                                    <React.Fragment>
                                                                        <tr style={{ height: '50px' }}>
                                                                            <td style={{ 'width': (selectedServiceId == 5 || selectedServiceId == 31) ? '20%' : '', backgroundColor: D.color }}>{D.users}</td>
                                                                            <td className={D.weekp1.includes('-') ? 'bgm-red' : D.weekp1.includes('+') ? 'bgm-green' : ''} style={{ width: '16%', backgroundColor: D.color }}>{D.week1}{D.weekp1}</td>
                                                                            <td className={D.weekp2.includes('-') ? 'bgm-red' : D.weekp2.includes('+') ? 'bgm-green' : ''} style={{ width: '16%', backgroundColor: D.color }}>{D.week2}{D.weekp2}</td>
                                                                            <td className={D.weekp3.includes('-') ? 'bgm-red' : D.weekp3.includes('+') ? 'bgm-green' : ''} style={{ width: '16%', backgroundColor: D.color }}>{D.week3}{D.weekp3}</td>
                                                                            <td className={D.weekp4.includes('-') ? 'bgm-red' : D.weekp4.includes('+') ? 'bgm-green' : ''} style={{ width: '16%', backgroundColor: D.color }}>{D.week4}{D.weekp4}</td>
                                                                            <td className={D.weekp5.includes('-') ? 'bgm-red' : D.weekp5.includes('+') ? 'bgm-green' : ''} style={{ width: '16%', backgroundColor: D.color }}>
                                                                                {
                                                                                    (IsBgmEdit)
                                                                                        ? <input type='text' maxLength={15} value={D.week5Edit} onChange={e => ChangeBGMLastweek(e, key, D.envId)} className="form-control" style={{ borderColor: D.valuecolor.toString(), width: '188px' }} />
                                                                                        : <p>{D.week5}{D.weekp5}</p>
                                                                                }

                                                                            </td>
                                                                            {
                                                                                (IsBgmEdit)
                                                                                    ? <td className='data'><input type='text' value={D.comment} onChange={e => ChangeBGMLastweekcomment(e, key, D.envId)} className="form-control" style={{ borderColor: D.cmtcolor.toString() }} /></td>
                                                                                    : <React.Fragment></React.Fragment>
                                                                            }
                                                                        </tr>
                                                                    </React.Fragment>
                                                                )))}
                                                                {
                                                                    (IsBgmEdit)
                                                                        ?
                                                                        <tr ng-hide='!IsBgmEdit'>
                                                                            <td colSpan={10} align="center">
                                                                                <a onClick={Edit_Service_BGM_Popup_ToHistory} className='button-format fontsmall' target='_blank' rel='tooltip' title='Save all the changes to Database' aria-hidden='false'>
                                                                                    &nbsp; <FontAwesomeIcon icon={faSave} style={{ color: 'white', backgroundColor: 'None', border: 'none' }} /> &nbsp;Save
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                        : <React.Fragment></React.Fragment>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </Tab>
                                                    <Tab eventKey="BGM–Fairfax" title="BGM – Fairfax">
                                                        <table className="table tblPIR tblborder">
                                                            <thead style={{ backgroundColor: 'darkcyan' }}>
                                                                <tr>
                                                                    <th style={{ textAlign: 'center', width: '22%', verticalAlign: 'middle' }} className="KPIcalth">Metrics</th>
                                                                    <th style={{ textAlign: 'center', width: '10%', verticalAlign: 'middle' }} className="KPIcalth">{weekDetailsData.weeksDetails.weekDet1}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week1}</span></th>
                                                                    <th style={{ textAlign: 'center', width: '10%', verticalAlign: 'middle' }} className="KPIcalth">{weekDetailsData.weeksDetails.weekDet2}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week2}</span></th>
                                                                    <th style={{ textAlign: 'center', width: '10%', verticalAlign: 'middle' }} className="KPIcalth">{weekDetailsData.weeksDetails.weekDet3}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week3}</span></th>
                                                                    <th style={{ textAlign: 'center', width: '10%', verticalAlign: 'middle' }} className="KPIcalth">{weekDetailsData.weeksDetails.weekDet4}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week4}</span></th>
                                                                    <th style={{ textAlign: 'center', width: '10%', verticalAlign: 'middle' }} className="KPIcalth">
                                                                        {weekDetailsData.weeksDetails.weekDet5}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week5}</span>
                                                                        {
                                                                            isArrayWithLength(BGMFairFaxdata)
                                                                                ?
                                                                                (!IsBgmEdit)
                                                                                    ?
                                                                                    <React.Fragment>
                                                                                        <span style={{ cursor: 'pointer' }}>
                                                                                            &nbsp;&nbsp; <FontAwesomeIcon onClick={enableBGMPopupEdit} title='Click to edit the last week data' className="Editiconsfontsize" icon={faPencilAlt} />
                                                                                        </span>
                                                                                    </React.Fragment>
                                                                                    : <React.Fragment>
                                                                                        <span onClick={cancelBGMpopupedit} style={{ cursor: 'pointer' }}>
                                                                                            &nbsp;&nbsp;  <img title='Click to Cancel Edit' src={cancel} style={{ width: '20px', height: '20px' }} />
                                                                                        </span>
                                                                                    </React.Fragment>
                                                                                : <React.Fragment></React.Fragment>
                                                                        }

                                                                    </th>
                                                                    {
                                                                        (IsBgmEdit)
                                                                            ? <th className="KPIcalth">Comments</th>
                                                                            : <React.Fragment></React.Fragment>
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {(isArrayWithLength(BGMFairFaxdata) && BGMFairFaxdata.map((D: PropBgm, key) => (
                                                                    <React.Fragment>
                                                                        <tr style={{ height: '50px' }}>
                                                                            <td style={{ 'width': (selectedServiceId == 5 || selectedServiceId == 31) ? '20%' : '', backgroundColor: D.color }}>{D.users}</td>
                                                                            <td className={D.weekp1.includes('-') ? 'bgm-red' : D.weekp1.includes('+') ? 'bgm-green' : ''} style={{ width: '16%', backgroundColor: D.color }}>{D.week1}{D.weekp1}</td>
                                                                            <td className={D.weekp2.includes('-') ? 'bgm-red' : D.weekp2.includes('+') ? 'bgm-green' : ''} style={{ width: '16%', backgroundColor: D.color }}>{D.week2}{D.weekp2}</td>
                                                                            <td className={D.weekp3.includes('-') ? 'bgm-red' : D.weekp3.includes('+') ? 'bgm-green' : ''} style={{ width: '16%', backgroundColor: D.color }}>{D.week3}{D.weekp3}</td>
                                                                            <td className={D.weekp4.includes('-') ? 'bgm-red' : D.weekp4.includes('+') ? 'bgm-green' : ''} style={{ width: '16%', backgroundColor: D.color }}>{D.week4}{D.weekp4}</td>
                                                                            <td className={D.weekp5.includes('-') ? 'bgm-red' : D.weekp5.includes('+') ? 'bgm-green' : ''} style={{ width: '16%', backgroundColor: D.color }}>
                                                                                {
                                                                                    (IsBgmEdit)
                                                                                        ? <input type='text' maxLength={15} value={D.week5Edit} onChange={e => ChangeBGMLastweek(e, key, D.envId)} className="form-control" style={{ borderColor: D.valuecolor.toString(), width: '188px' }} />
                                                                                        : <p>{D.week5}{D.weekp5}</p>
                                                                                }

                                                                            </td>
                                                                            {
                                                                                (IsBgmEdit)
                                                                                    ? <td className='data'><input type='text' value={D.comment} onChange={e => ChangeBGMLastweekcomment(e, key, D.envId)} className="form-control" style={{ borderColor: D.cmtcolor.toString() }} /></td>
                                                                                    : <React.Fragment></React.Fragment>
                                                                            }
                                                                        </tr>
                                                                    </React.Fragment>
                                                                )))}
                                                                {
                                                                    (IsBgmEdit)
                                                                        ?
                                                                        <tr ng-hide='!IsBgmEdit'>
                                                                            <td colSpan={10} align="center">
                                                                                <a onClick={Edit_Service_BGM_Popup_ToHistory} className='button-format fontsmall' target='_blank' rel='tooltip' title='Save all the changes to Database' aria-hidden='false'>
                                                                                    &nbsp; <FontAwesomeIcon icon={faSave} style={{ color: 'white', backgroundColor: 'None', border: 'none' }} /> &nbsp;Save
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                        : <React.Fragment></React.Fragment>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </Tab>
                                                    <Tab eventKey="BGM–Mooncake" title="BGM – Mooncake">
                                                        <table className="table tblPIR tblborder">
                                                            <thead style={{ backgroundColor: 'darkcyan' }}>
                                                                <tr>
                                                                    <th style={{ textAlign: 'center', width: '22%', verticalAlign: 'middle' }} className="KPIcalth">Metrics</th>
                                                                    <th style={{ textAlign: 'center', width: '10%', verticalAlign: 'middle' }} className="KPIcalth">{weekDetailsData.weeksDetails.weekDet1}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week1}</span></th>
                                                                    <th style={{ textAlign: 'center', width: '10%', verticalAlign: 'middle' }} className="KPIcalth">{weekDetailsData.weeksDetails.weekDet2}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week2}</span></th>
                                                                    <th style={{ textAlign: 'center', width: '10%', verticalAlign: 'middle' }} className="KPIcalth">{weekDetailsData.weeksDetails.weekDet3}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week3}</span></th>
                                                                    <th style={{ textAlign: 'center', width: '10%', verticalAlign: 'middle' }} className="KPIcalth">{weekDetailsData.weeksDetails.weekDet4}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week4}</span></th>
                                                                    <th style={{ textAlign: 'center', width: '10%', verticalAlign: 'middle' }} className="KPIcalth">
                                                                        {weekDetailsData.weeksDetails.weekDet5}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week5}</span>
                                                                        {
                                                                            isArrayWithLength(BGMMooncakedata)
                                                                                ?
                                                                                (!IsBgmEdit)
                                                                                    ?
                                                                                    <React.Fragment>
                                                                                        <span style={{ cursor: 'pointer' }}>
                                                                                            &nbsp;&nbsp; <FontAwesomeIcon onClick={enableBGMPopupEdit} title='Click to edit the last week data' className="Editiconsfontsize" icon={faPencilAlt} />
                                                                                        </span>
                                                                                    </React.Fragment>
                                                                                    : <React.Fragment>
                                                                                        <span onClick={cancelBGMpopupedit} style={{ cursor: 'pointer' }}>
                                                                                            &nbsp;&nbsp;  <img title='Click to Cancel Edit' src={cancel} style={{ width: '20px', height: '20px' }} />
                                                                                        </span>
                                                                                    </React.Fragment>
                                                                                : <React.Fragment></React.Fragment>
                                                                        }

                                                                    </th>
                                                                    {
                                                                        (IsBgmEdit)
                                                                            ? <th className="KPIcalth">Comments</th>
                                                                            : <React.Fragment></React.Fragment>
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {(isArrayWithLength(BGMMooncakedata) && BGMMooncakedata.map((D: PropBgm, key) => (
                                                                    <React.Fragment>
                                                                        <tr style={{ height: '50px' }}>
                                                                            <td style={{ 'width': (selectedServiceId == 5 || selectedServiceId == 31) ? '20%' : '', backgroundColor: D.color }}>{D.users}</td>
                                                                            <td className={D.weekp1.includes('-') ? 'bgm-red' : D.weekp1.includes('+') ? 'bgm-green' : ''} style={{ width: '16%', backgroundColor: D.color }}>{D.week1}{D.weekp1}</td>
                                                                            <td className={D.weekp2.includes('-') ? 'bgm-red' : D.weekp2.includes('+') ? 'bgm-green' : ''} style={{ width: '16%', backgroundColor: D.color }}>{D.week2}{D.weekp2}</td>
                                                                            <td className={D.weekp3.includes('-') ? 'bgm-red' : D.weekp3.includes('+') ? 'bgm-green' : ''} style={{ width: '16%', backgroundColor: D.color }}>{D.week3}{D.weekp3}</td>
                                                                            <td className={D.weekp4.includes('-') ? 'bgm-red' : D.weekp4.includes('+') ? 'bgm-green' : ''} style={{ width: '16%', backgroundColor: D.color }}>{D.week4}{D.weekp4}</td>
                                                                            <td className={D.weekp5.includes('-') ? 'bgm-red' : D.weekp5.includes('+') ? 'bgm-green' : ''} style={{ width: '16%', backgroundColor: D.color }}>
                                                                                {
                                                                                    (IsBgmEdit)
                                                                                        ? <input type='text' maxLength={15} value={D.week5Edit} onChange={e => ChangeBGMLastweek(e, key, D.envId)} className="form-control" style={{ borderColor: D.valuecolor.toString(), width: '188px' }} />
                                                                                        : <p>{D.week5}{D.weekp5}</p>
                                                                                }

                                                                            </td>
                                                                            {
                                                                                (IsBgmEdit)
                                                                                    ? <td className='data'><input type='text' value={D.comment} onChange={e => ChangeBGMLastweekcomment(e, key, D.envId)} className="form-control" style={{ borderColor: D.cmtcolor.toString() }} /></td>
                                                                                    : <React.Fragment></React.Fragment>
                                                                            }
                                                                        </tr>
                                                                    </React.Fragment>
                                                                )))}
                                                                {
                                                                    (IsBgmEdit)
                                                                        ?
                                                                        <tr ng-hide='!IsBgmEdit'>
                                                                            <td colSpan={10} align="center">
                                                                                <a onClick={Edit_Service_BGM_Popup_ToHistory} className='button-format fontsmall' target='_blank' rel='tooltip' title='Save all the changes to Database' aria-hidden='false'>
                                                                                    &nbsp; <FontAwesomeIcon icon={faSave} style={{ color: 'white', backgroundColor: 'None', border: 'none' }} /> &nbsp;Save
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                        : <React.Fragment></React.Fragment>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </Tab>
                                                </Tabs>
                                                : <React.Fragment></React.Fragment>
                                        }
                                        {
                                            (ShowExternalBgmLink === true)
                                                ? <a href={ExternalLink} onClick={HideModalJQuery} target="_blank">Click here for BGM data in external system (Power BI).</a>
                                                : <React.Fragment></React.Fragment>
                                        }

                                        <br />
                                        {
                                            (ShowExternalBgmLink === true && selectedServiceId === 17)
                                                ? <a href={External_link_NewBGM} onClick={HideModalJQuery} target="_blank">Click here for New Dashboard of BGM data in external system (Power BI).</a>
                                                : <React.Fragment></React.Fragment>
                                        }
                                        {
                                            (selectedServiceId === 2 || selectedServiceId === 18 || selectedServiceId === 21 || selectedServiceId === 22)
                                                ? <h6 style={{ color: 'blue' }}>Business growth metrics data is not reported for the selected service.</h6>
                                                : <React.Fragment></React.Fragment>
                                        }
                                    </React.Fragment>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                    <Modal show={ICMTicketDetails} onHide={ICMTicketDetailsClose} size='lg' backdrop='static'>
                        <Modal.Header closeButton className="commonModalHeader">
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col lg='12'>
                                    <table style={{ width: '100%' }} className="table  table-striped  tblPIR tblborder">
                                        <thead style={{ backgroundColor: 'darkcyan' }}>
                                            <tr>
                                                <th colSpan={5} style={{ textAlign: 'center', width: '8%', verticalAlign: 'middle' }} className="KPIcalth">Ticket Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(isArrayWithLength(ICMTicket_details))
                                                ?
                                                <tr >
                                                    <td style={{ height: '10px' }}><b>Ticket ID</b></td>
                                                    <td style={{ height: '10px' }}><b>Created Date</b></td>
                                                    <td style={{ height: '10px' }}><b>Ticket Details</b></td>
                                                    <td style={{ height: '10px' }}><b>Ticket Status</b></td>
                                                    <td style={{ height: '10px' }}><b>Assignee</b></td>
                                                </tr>
                                                : <React.Fragment></React.Fragment>
                                            }
                                            {isArrayWithLength(ICMTicket_details) && ICMTicket_details.map((LS, key) => (
                                                <React.Fragment key={key}>
                                                    <tr>
                                                        <td><a href={(!isNullOrUndefined(Config_Values) ? Config_Values.icmTicketString : '') + LS.ticketId} target="_blank">{LS.ticketId}</a></td>
                                                        <td>{(LS.createdDate !== null) ? moment(LS.createdDate).format('YYYY-MM-DD') : ''}</td>
                                                        <td>{LS.ticketTitle}</td>
                                                        <td>{LS.ticketStatus}</td>
                                                        <td>{LS.assignee}</td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                            {
                                                (!isArrayWithLength(ICMTicket_details))
                                                    ? <tr ><td className='blankTable' colSpan={5}> No Data Found</td></tr>
                                                    : <React.Fragment></React.Fragment>
                                            }

                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                    <Modal show={KPIChart} onHide={CloseKPIChart} size='lg' backdrop='static'>
                        <Modal.Header closeButton className="commonModalHeader">
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col lg='12'>
                                    <div>
                                        <HighchartsReact highcharts={Highcharts} options={Options} />
                                    </div>
                                    {
                                        (isAvailability && (weekDetailsData !== null && weekDetailsData !== undefined))
                                            ?
                                            <table className="table  table-striped  tblPIR tblborder" id="availbilitydata">
                                                <thead style={{ backgroundColor: '#DEE9F5', color:'#000000' }}>
                                                    <tr>
                                                        <th style={{ textAlign: 'center', width: '20%', verticalAlign: 'middle' }} className="KPIcalth">Metric Name</th>
                                                        <th className="KPIAvailbilitydatath KPIcalth">Environment</th>
                                                        <th className="KPIAvailbilitydatath KPIcalth">Target</th>
                                                        <th className="KPIAvailbilitydatath KPIcalth">{weekDetailsData.weeksDetails.weekDet1}<br /><span className="fontsizexsmall">{weekDetailsData.weeks.week1}</span></th>
                                                        <th className="KPIAvailbilitydatath KPIcalth">{weekDetailsData.weeksDetails.weekDet2}<br /><span className="fontsizexsmall">{weekDetailsData.weeks.week2}</span></th>
                                                        <th className="KPIAvailbilitydatath KPIcalth">{weekDetailsData.weeksDetails.weekDet3}<br /><span className="fontsizexsmall">{weekDetailsData.weeks.week3}</span></th>
                                                        <th className="KPIAvailbilitydatath KPIcalth">{weekDetailsData.weeksDetails.weekDet4}<br /><span className="fontsizexsmall">{weekDetailsData.weeks.week4}</span></th>
                                                        <th className="KPIAvailbilitydatath KPIcalth">{weekDetailsData.weeksDetails.weekDet5}<br /><span className="fontsizexsmall">{weekDetailsData.weeks.week5}</span></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {isArrayWithLength(AvailabilityData) && AvailabilityData.map((D, key) => (
                                                        <tr>
                                                            <td>{D.metricName}</td>
                                                            <td className="KPIAvailbilitydata">{D.environment}</td>
                                                            <td className="KPIAvailbilitydata">{D.targetName}</td>
                                                            <td className="KPIAvailbilitydata" style={ColorWeekAvailability(D.week1, D.targetName)}>{D.week1}</td>
                                                            <td className="KPIAvailbilitydata" style={ColorWeekAvailability(D.week2, D.targetName)}>{D.week2}</td>
                                                            <td className="KPIAvailbilitydata" style={ColorWeekAvailability(D.week3, D.targetName)}>{D.week3}</td>
                                                            <td className="KPIAvailbilitydata" style={ColorWeekAvailability(D.week4, D.targetName)}>{D.week4}</td>
                                                            <td className="KPIAvailbilitydata" style={ColorWeekAvailability(D.week5, D.targetName)}>{D.week5}</td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>
                                            :
                                            <React.Fragment>
                                            </React.Fragment>
                                    }
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                    <Modal show={CommentsPopup} onHide={closeCommentsPopup} size='lg' backdrop='static'>
                        <Modal.Header closeButton className="commonModalHeader">
                            <div style={{ width: '100%' }}>
                                <Row>
                                    <Col lg='12' style={{ textAlign: 'center' }}>
                                        <h4><b>  Add Comments </b></h4>
                                    </Col>
                                </Row>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ height: '400px' }}>
                                {
                                    (MetricGroup)
                                        ?
                                        <Row>
                                            <Col lg='4'>
                                                <Picky
                                                    id='picky'
                                                    options={MetricGroup}
                                                    valueKey='id'
                                                    labelKey='name'
                                                    multiple={false}
                                                    value={MetricGroupvalue}
                                                    includeSelectAll={false}
                                                    includeFilter={false}
                                                    onChange={setMetricGroupvalueData}
                                                    dropdownHeight={300}
                                                    numberDisplayed={1}
                                                    placeholder='Select KPI'
                                                    keepOpen={false}

                                                />
                                            </Col>
                                            <Col lg='8'></Col>
                                        </Row>
                                        : <React.Fragment></React.Fragment>
                                }
                                <br />
                                <Row>
                                    <Col lg='12'>
                                        <RichTextEditor
                                            value={Commentvalue}
                                            onChange={SetServiceCommentvalue}
                                            toolbarConfig={toolbarConfig}
                                            type="string"
                                            multiline
                                            variant="filled"
                                            editorClassName="trix-editor-comments"
                                        />
                                    </Col>
                                </Row>
                                <br />
                                <div style={{ width: '100%' }}>
                                    <Row>
                                        <Col lg='1'></Col>
                                        <Col lg='3'>
                                            <Button className='DailydataModelbuttons' onClick={submitKPIComments} disabled={Disablesubmitbutton}>Submit</Button>
                                        </Col>
                                        <Col lg='4' style={{ textAlign: 'center' }}>
                                            <Button className='DailydataModelbuttons' onClick={DeleteKPIComments} disabled={DisableDeletebutton}>Delete</Button>
                                        </Col>
                                        <Col lg='1'></Col>
                                    </Row>
                                </div>
                                <br />
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </LoadingOverlay>
        );      
};

const mapStateToProps = (state: IApplicationState) => ({
    ...state.serviceHealth,
    isProd: state.authUser.isProd,
    isAuthenticated: state.authUser.isAuthenticated,
    username: state.authUser.login
});

export default connect(mapStateToProps, actionCreators)(ServiceHealth)
