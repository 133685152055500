import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IAckTrackingTemplateState } from './types';

const initialState = Object.freeze<IAckTrackingTemplateState>({
    isLoading: false,
    ackTemplateData: [],
    showackTypePopup: false,
    showAckFilterPopup: false,
    AckType: 0,
    ackNotificationData: [],
    templatedropdowndata: [],
    templateid: '',
    ackNotificationlist: [],
    ackFilterNamelist: [],
    ackFilterNameData: [],
    templateDescription: '',
    ackTypevalue: '',
    isAckTypeActive: true,
    isTemplateActive: true,
    Enableacktypesavebutton: false,
    showtemplatePopup: false,
    template: null,
    servicecolsList: [],
    changedColumns: [],
    columncontroltypeoptions: [],
    columncoloring: [],
    colorsData: [],
    templatesavebuttondisable:true
});

export const reducer = (
    state: IAckTrackingTemplateState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) { 
        case ActionType.ACKTEMPLATE_DATA_FETCH: 
            return {
                ...state,
                isLoading: true,
                ackTemplateData: action.ackTemplateData,
               
            };  
        case ActionType.ACKTEMPLATE_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                ackTemplateData: action.Acktemplatedata,

            }; 
        case ActionType.ACKTEMPLATE_DATA_FAIL:
            return {
                ...state,
                isLoading: false,
                ackTemplateData: [],

            }; 
        case ActionType.SHOWORHIDE_ACKTYPE_POPUP:
            return {
                ...state,               
                showackTypePopup: action.showackTypePopup,

            };
        case ActionType.SHOWORHIDE_ACK_FILTER_POPUP:
            return {
                ...state,               
                showAckFilterPopup: action.showAckFilterPopup,

            }; 
        case ActionType.SHOWORHIDE_TEMPLATE_POPUP:
            return {
                ...state,
                showtemplatePopup: action.showtemplatePopup,

            }; 
        case ActionType.ACKNOTIFICATION_DATA_FETCH:
            return {
                ...state,
                isLoading: true,
                ackNotificationData: [],

            };
        case ActionType.ACKNOTIFICATION_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                showackTypePopup: true,
                AckType: 0,
                ackTypevalue: '',  
                templateDescription: '',
                templateid: '',
                isAckTypeActive: true,
                isTemplateActive: true,
                Enableacktypesavebutton:true,
                ackNotificationlist: action.ackNotification,
                ackNotificationData: action.ackNotificationData,

            };
        case ActionType.ACK_FILTER_DATA_FETCH:
            return {
                ...state,
                isLoading: true,
                ackFilterNameData: []

            };
        case ActionType.ACK_FILTER_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false, 
                isAckTypeActive: action.isAckTypeActive,
                isTemplateActive: action.isTemplateActive,
                AckType: action.AckType,
                ackFilterNamelist: action.ackFilterNames,
                ackFilterNameData: action.ackFilterNameData,

            };
        case ActionType.ACK_FILTER_DATA_FAIL:
            return {
                ...state,
                isLoading: false,
                ackFilterNamelist: [],
                ackFilterNameData: [],

            };
        case ActionType.ACKNOTIFICATION_FILTER_DATA_FETCH:
            return {
                ...state,
                isLoading: true,
                ackFilterNameData: [],
            };
        case ActionType.ACKNOTIFICATION_FILTER_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                showAckFilterPopup: true,
                AckType: 0,
                ackTypevalue: '',                 
                ackNotificationlist: action.ackNotification,
                ackNotificationData: action.ackNotificationData,
            }; 
        case ActionType.ACKNOTIFICATION_FILTER_DATA_FAIL:
            return {
                ...state,
                isLoading: false,
                showAckFilterPopup: true,
                AckType: 0,
                ackTypevalue: '',
                ackNotificationlist: [],
                ackNotificationData: [],
            };
        case ActionType.TEMPLATE_DROPDOWN_SUCCESS:
            return {
                ...state,               
                templatedropdowndata: action.templatedropdowndata,

            }; 
        case ActionType.TEMPLATE_DROPDOWN_FETCH:
            return {
                ...state,
                templatedropdowndata: []

            }; 
        case ActionType.ACKTEMPLATE_DATA_FAIL:
            return {
                ...state,
                isLoading: false,
                showackTypePopup: true,
                AckType: 0,
                ackTypevalue: '',
                templateDescription: '',
                templateid:'',
                ackNotificationData: [],

            }; 
        case ActionType.ACKTYPE_VAL_CHANGE:
            return {
                ...state,             
                AckType: action.val,
                ackTypevalue: action.ackTypevalue,
                templateDescription: action.templateDescription,
                templateid: action.templateid,
                isAckTypeActive: action.isAckTypeActive,
                isTemplateActive: action.isTemplateActive,
                Enableacktypesavebutton: action.Enableacktypesavebutton

            };
        case ActionType.TEMPLATE_VAL_CHANGE:
            return {
                ...state,
                Enableacktypesavebutton: action.Enableacktypesavebutton,
                templateid: action.val,
                isTemplateActive: action.isTemplateActive

            };
        case ActionType.ACKTYPE_VALUE_CHANGE:
            return {
                ...state,
                Enableacktypesavebutton: action.Enableacktypesavebutton,
                ackTypevalue: action.ackTypevalue

            };
        case ActionType.ACK_TYPE_IS_ACTIVE_CHANGE:
            return {
                ...state,
                isAckTypeActive: action.isAckTypeActive
            };
       
        case ActionType.TEMPLATE_DESCRIPTION_CHANGE:
            return {
                ...state,
                Enableacktypesavebutton: action.Enableacktypesavebutton,
                templateDescription: action.templateDescription,

            };
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true,

            };
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false,

            };
        case ActionType.OPEN_TEMPLATE_POPUP:
            return {
                ...state,
                template: action.template,
                columncontroltypeoptions: action.columncontroltypeoptions,
                servicecolsList: action.servicecolsList,
                showtemplatePopup: true,
                colorsData: action.colorsData,
                columncoloring: action.columncoloring,
                changedColumns: action.changedColumns,
                templatesavebuttondisable: action.templatesavebuttondisable

            };
        case ActionType.SERVICETREE_COL_CHANGE:
            return {
                ...state,
                template: action.template,
                templatesavebuttondisable: action.templatesavebuttondisable

            };
        case ActionType.ADD_NEW_COLUMNS:
            return {
                ...state,               
                changedColumns: action.changedColumns,
                templatesavebuttondisable: action.templatesavebuttondisable

            };
        case ActionType.TEMPLATE_COLUMN_VALUES_CHANGE:
            return {
                ...state,
                columncoloring: action.columncoloring,
                changedColumns: action.changedColumns,
                templatesavebuttondisable: action.templatesavebuttondisable

            };
        case ActionType.COLOUR_PALLETE_VAL_CHANGE:
            return {
                ...state,
                columncoloring: action.columncoloring,
            };
        case ActionType.MOVEACKTEMPLATETOARCHIVE:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.MOVEACKTEMPLATETOARCHIVE_FAIL:
            return {
                ...state,
                isLoading: false
            };
        case ActionType.RESET_STATE:
            return initialState;

        default:
            return state;
    }
};
