import React, { useState, Fragment, useRef, useEffect } from 'react';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import { IDAcktrackingTemplate, IDAcktrackingFilter } from '../../../store/AckTrackingTemplate';
import {
    Card, Row, Col, Container, CardBody
} from 'reactstrap';
import { Modal, Button } from 'react-bootstrap'
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FontAwesomeIconMemo } from '../../../components';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Visibility } from 'semantic-ui-react';
import { RadioButton } from 'primereact/radiobutton';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';

type AckTemplateTableProps = {
    ackTemplateData: IDAcktrackingTemplate[];
    OpenorhideAckTypePopup: any;
    showackTypePopup: boolean;
    OpenorhideAckFilterPopup: any;
    showAckFilterPopup: boolean;
    hideAckFilterPopup: any;
    MoveAckTemplateToArchive: any;
    AckType: any;
    ackNotificationData: any;
    ackFilterNameData: any;
    handleAcktypeChange: any;
    handleAcktypeChangeOnFilters: any;
    handleTemplateChange: any;
    templatedropdowndata: any;
    templateid: string;
    hideAckTypePopup: any;
    templateDescription: any;
    ackTypevalue: any;
    isAckTypeActive: boolean;
    isTemplateActive: boolean;
    changeacktypetext: any;
    Enableacktypesavebutton: boolean;
    Templatedescriptionchange: any;
    SaveAckTypeData: any;
    showtemplatePopup: boolean;
    hidetemplatePopup: any;
    OpentemplatePopup: any;
    template: any;
    servicecolsList: any;
    templatedatachange: any;
    changeActiveStatus: any;
    changeFilterActiveStatus: any;
    AddcolumnstoTemplate: any;
    changedColumns: any;
    columncontroltypeoptions: any;
    templatecolumnvalueschange: any;
    DeleteTemplateColumn: any;
    columncoloring: any;
    colorsData: any;
    colourpalletecolourchane: any;
    customcolourchange: any;
    templatesavebuttondisable: boolean;
    SaveIDAckTrackTemplate: any;
    TemplateEdit: any;
    showAckTrackingUserGuide: any;
    isAdmin: any;
};
const AckTemplateTable = React.memo<AckTemplateTableProps>(({
    ackTemplateData,
    OpenorhideAckTypePopup,    
    showackTypePopup,
    OpenorhideAckFilterPopup,
    showAckFilterPopup,
    hideAckFilterPopup,
    AckType,
    ackNotificationData,
    ackFilterNameData,
    handleAcktypeChange,
    handleAcktypeChangeOnFilters,
    handleTemplateChange,
    MoveAckTemplateToArchive,
    templatedropdowndata,
    templateid,
    hideAckTypePopup,
    templateDescription,
    ackTypevalue,
    isAckTypeActive,
    isTemplateActive,
    changeacktypetext,
    Enableacktypesavebutton,
    Templatedescriptionchange,
    SaveAckTypeData,
    showtemplatePopup,
    hidetemplatePopup,
    OpentemplatePopup,
    template,
    servicecolsList,
    templatedatachange,
    changeActiveStatus,
    changeFilterActiveStatus,
    AddcolumnstoTemplate,
    changedColumns,
    columncontroltypeoptions,
    templatecolumnvalueschange,
    DeleteTemplateColumn,
    columncoloring,
    colorsData,
    colourpalletecolourchane,
    customcolourchange,
    templatesavebuttondisable,
    SaveIDAckTrackTemplate,
    TemplateEdit,
    showAckTrackingUserGuide,
    isAdmin
}) => {
    useEffect(() => {
        dt.current.filter(selectedisActiveTemplate, 'isActive', 'equals');
    }, [ackTemplateData]);

    const IdAckTemplateGuideURL = "https://identitydivision.visualstudio.com/IdentityWiki/_wiki/wikis/IdentityWiki.wiki/18590/ID-Ack-Tracking-User-Guide";
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);
    const setGlobalFilterValue = (event) => {
        setGlobalFilter(event.target.value);
    }
    const openUrlInNewTab = (url) => {
        if (url !== '') {
            window.open(url, "_blank");
        }
    }

    const colourpalletecolourchane1 = (e, row, index) => {
        if (e.target.value === 'Custom') {
            document.getElementById('color' + index).click();
        }
        else {
            colourpalletecolourchane(e, row);
        }  
    }
    const displayIsActive = (rowData) => {
        return (
            <div>{rowData.isActive == true ? 'True' : 'False'}</div>
        );
    }
    const displayDate = (rowData) => {
        return (
            <div>{(rowData.createdDate === null) ? '' : moment(rowData.createdDate).format('YYYY-MM-DD hh:mm A')}</div>
        );
    }

    const [selectedisActiveTemplate, setSelectedisActiveTemplate] = useState('True');
    const [archiveDialog, setArchiveDialog] = useState(false);
    const [selectedAckTemplateToArchive, setAckTemplateToArchive] = useState(null);

    const isActiveOptions = [
        'True', 'False'
    ];

    const onisActiveFilterChange = (event) => {
        dt.current.filter(event.value, 'isActive', 'equals');
        setSelectedisActiveTemplate(event.value);
    }


    const isActiveFilterElements = () => {
        return (
            <Dropdown value={selectedisActiveTemplate} options={isActiveOptions} itemTemplate={isActiveOptionTemplate} className="p-column-filter"
                onChange={onisActiveFilterChange} placeholder="Select a isActive" />
        );
    }
    const isActiveOptionTemplate = (option) => {
        return (
            <span>{option}</span>
        );
    }
    const isActiveFilterElement = isActiveFilterElements();

    const hideArchiveDialog = () => {
        setAckTemplateToArchive(null);
        setArchiveDialog(false);
    }
    const deleteArchiveConfirm = (TemplateID) => {
        setAckTemplateToArchive(TemplateID);
        setArchiveDialog(true);
    }
    const deleteArchiveConfirmed = () => {
        MoveAckTemplateToArchive(selectedAckTemplateToArchive);
        setArchiveDialog(false);
    }

    const archiveDialogFooter = (
        <React.Fragment>
            <span style={{ display: 'flex', justifyContent: 'center' }}>
                <Button className="p-button-text btn-primary" onClick={() => hideArchiveDialog()} >No</Button>
                <Button className="p-button-text btn-primary" onClick={() => deleteArchiveConfirmed()}>Yes</Button>
            </span>
        </React.Fragment>
    );
    const UpdateTemplateBodyTemplate = (row) => {
        let template = {
            'templateID': row.templateID, 'templateName': row.templateName, 'serviceTreeColsList': row.serviceTreeColsList, 'isActive': row.isActive, 'isUsed': row.isUsed, 'ackcount': row.ackcount, 'isServiceSpecific': row.isServiceSpecific, 'colsList': row.colsList,
            'modifiedBy': row.modifiedBy, 'modifiedDate': row.modifiedDate, 'templateJSON': row.templateJSON, 'notificationTitle': row.notificationTitle, 'mappedAckCount': row.mappedAckCount
        };
        return (
            <React.Fragment>
                {(!row.isEditable && (!isAdmin)) ?
                    <React.Fragment>
                        {!row.isActive && <FontAwesomeIcon title='Click to Archive the record' className="redcolorIcons Iconsstyles" cursor='pointer' icon={'archive'} onClick={e => deleteArchiveConfirm(row.templateID)} />}
                    </React.Fragment>
                :  <React.Fragment>
                        <FontAwesomeIcon cursor='pointer' icon={'pencil-alt'} onClick={e => TemplateEdit(template)} /> &nbsp;&nbsp;
                    {!row.isActive && <FontAwesomeIcon title='Click to Archive the record' className="redcolorIcons Iconsstyles" cursor='pointer' icon={'archive'} onClick={e => deleteArchiveConfirm(row.templateID)} />}
                </React.Fragment>
                }                
            </React.Fragment>
        );
    }
    const handleClick = () => {

    }
    return (
        <div>
            <Card className="cardstyle">
                <CardBody className="cardbodyhederstyle">
                    <Row>
                        <Col lg="4">
                        </Col>
                        <Col lg="4">
                            <h3 className="headertext">ID Ack Tracking Template</h3>
                        </Col>
                        <Col lg="4" style={{ textAlign: 'right' }}>
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" />&nbsp;
                                <InputText style={{ width: '340px', verticalAlign: 'top' }} type="search" placeholder="Global Search" value={globalFilter} onChange={(e) => setGlobalFilterValue(e)} />&ensp;&ensp;&ensp;
                            </span>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <br />           
            <Row >
                <Col lg="12">                   
                    <div className="alignrightitems">
                        <React.Fragment><span className='ConfigGuide' onClick={e => openUrlInNewTab(IdAckTemplateGuideURL)}>
                            ID Ack Tracking User Guide&nbsp; </span>
                         <FontAwesomeIconMemo style={{ verticalAlign: 'middle' }} onClick={e => openUrlInNewTab(IdAckTemplateGuideURL)} title='Ack Tracking User Guide' icon={'question-circle'} className='icon-format-config' />
                        </React.Fragment>
                        <button className="btn btn-primary btn-sm filter-hmbutton btnwidth150" onClick={OpentemplatePopup}><FontAwesomeIcon icon={'plus'} style={{ color: 'white', backgroundColor: 'None', border: 'none' }} /><strong className="txtcolorwhite"> New Template</strong></button>
				&nbsp;
				<button className="btn btn-primary btn-sm filter-hmbutton btnwidth150" onClick={OpenorhideAckTypePopup} ><FontAwesomeIcon icon={'plus'} style={{ color: 'white', backgroundColor: 'None', border: 'none' }} /><strong className="txtcolorwhite"> Ack Type</strong></button>
                        &nbsp;
				<button className="btn btn-primary btn-sm filter-hmbutton btnwidth150" onClick={OpenorhideAckFilterPopup} ><FontAwesomeIcon icon={'pen-square'} style={{ color: 'white', backgroundColor: 'None', border: 'none' }} /><strong className="txtcolorwhite"> Ack Filters</strong></button>
                    </div>
                </Col>
            </Row>
            <br />
            <Row>
                <Col lg="12">
                    <div>
                        <DataTable ref={dt} value={ackTemplateData} className="tblAcktemplate"
                            globalFilter={globalFilter} emptyMessage="No Data found.">
                            <Column className="thwidth12 ackListtextalignment" field="templateName" sortable header="Template Name" filter filterMatchMode="contains" filterPlaceholder="Search by Template Name" />
                            <Column className="thwidth20 ackListtextalignment" field="serviceTreeColsList" sortable header="Service Tree ColsList" filter filterMatchMode="contains" filterPlaceholder="Search by Service Tree Cols" />
                            <Column className="thwidth20 ackListtextalignment" field="colsList" sortable header="Cols List" filter filterMatchMode="contains" filterPlaceholder="Search by Cols List" />
                            <Column className="thwidth8" field="isActive" sortable header="Is Active" body={displayIsActive} filter filterPlaceholder="Search by IsActive" filterElement={isActiveFilterElement} />
                            <Column className="thwidth8" field="createdBy" sortable header="Created By" filter filterMatchMode="contains" filterPlaceholder="Search Created By" />
                            <Column className="thwidth11" field="createdDate" sortable header="Created Date" body={displayDate} filter filterMatchMode="contains" filterPlaceholder="Search by Created Date" />
                            <Column className="thwidth8" field="modifiedBy" sortable header="Modified By"  filter filterMatchMode="contains" filterPlaceholder="Search Modified By" />
                            <Column className="thwidth11" field="modifiedDate" sortable header="Modified Date" body={displayDate} filter filterMatchMode="contains" filterPlaceholder="Search by Modified Date" />
                            <Column className="thwidth4" field="templateId" header="Edit" body={UpdateTemplateBodyTemplate} />
                        </DataTable>
                    </div>
                </Col>
            </Row>
            <Modal show={showackTypePopup} onHide={hideAckTypePopup} size='lg' backdrop='static' id="Ackmodelpopup">
                <Modal.Header closeButton className="commonModalHeader">
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='12' style={{ textAlign: 'center' }}>
                                <h4><b>  New Ack Type </b></h4>
                            </Col>
                        </Row>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row>
                            <Col lg="3">
                                <span className="mandatorycolor">*</span>&nbsp;<span>Ack Type</span>
                            </Col>
                            <Col lg="1">
                                :
                        </Col>
                            <Col lg="6">
                                <span className="p-float-label acklables">
                                    <Dropdown id={'ddlidacktype'} value={AckType} options={ackNotificationData} onChange={(e) => handleAcktypeChange(e.value)} optionLabel="name" />                                   
                                </span>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col lg="3">
                                <span className="mandatorycolor">*</span>&nbsp;<span>New Ack Type</span>
                            </Col>
                            <Col lg="1">
                                :
                        </Col>
                            <Col lg="6">
                                <input type="text" className="form-control" placeholder="New Ack Type" value={ackTypevalue} readOnly={(AckType > 0) ? true : false} name="ackType" onChange={ e=>changeacktypetext(e)} />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col lg="3">
                                <span className="mandatorycolor">*</span>&nbsp;<span>Template </span>
                                {(!isTemplateActive) ? <span className="errotextBGstyle">(Disabled Template)</span>
                                    : <span className="errotextstyle"></span>
                                }
                            </Col>
                            <Col lg="1">
                                :
                        </Col>
                            <Col lg="6">
                                <span className="p-float-label acklables">
                                    <Dropdown id={'ddltemplate'} value={templateid} options={templatedropdowndata} onChange={(e) => handleTemplateChange(e.value)} optionLabel="name" />
                                    <label id="lbltemplate" htmlFor="servicegroup" className="fontcolor acklabletxt">Template</label>
                                </span>
                                
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col lg="3">
                                <span className="mandatorycolor">*</span>&nbsp;<span>Description</span>
                            </Col>
                            <Col lg="1">
                                :
                        </Col>
                            <Col lg="6">
                                <input type="text" className="form-control" placeholder="Description" value={templateDescription} onChange={e => Templatedescriptionchange(e)}/>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col lg="3">
                                <span className="mandatorycolor"></span><span>&nbsp;&nbsp;Is Active</span><br />               
                                {(!isAckTypeActive) ? <span className="errotextBGstyle">&nbsp;&nbsp;(Disable AckType - if IsActive Flag is unchecked)</span>
                                    : <span className="errotextstyle"></span>
                                }                               
                            </Col>
                            <Col lg="1">
                                :
                                        </Col>
                            <Col lg="6">
                                <input type="checkbox" checked={(isAckTypeActive && isTemplateActive) ? true : false} disabled={(AckType === 0) ? true : (!isTemplateActive) ? true: false} name="isActive" onChange={(e) => changeActiveStatus(e)} />
                            </Col>
                        </Row>
                   </div>
                   
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='8'></Col>
                           
                            <Col lg='3'>
                                <Button className='DailydataModelbuttons' disabled={Enableacktypesavebutton} onClick={ SaveAckTypeData} >Save</Button>
                            </Col>
                            <Col lg='1'></Col>
                        </Row>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal className="ackFillterModalContent" show={showAckFilterPopup} onHide={hideAckFilterPopup} size='lg' backdrop='static' id="Ackmodelpopup">
                <Modal.Header closeButton className="commonModalHeader">
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='12' style={{ textAlign: 'center' }}>
                                <h4><b>  Ack Filters </b></h4>
                            </Col>
                        </Row>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ minHeight: '300px', maxHeight: '700px', width: '100%' }}>
                        <Row>
                            <Col lg="3">
                                <span className="mandatorycolor">*</span>&nbsp;<span>Ack Type</span>
                                {(!isAckTypeActive) ? <span className="errotextBGstyle">(Disabled AckType)</span>
                                    : <span className="errotextstyle"></span>
                                }
                            </Col>
                            <Col lg="1">
                                :
                        </Col>
                            <Col lg="6">
                                <span className="p-float-label acklables">
                                    <Dropdown id={'ddlidacktype'} value={AckType} options={ackNotificationData} onChange={(e) => handleAcktypeChangeOnFilters(e.value)} optionLabel="name" />
                                </span>
                            </Col>
                        </Row>                       
                        <br />   
                   
                        {isArrayWithLength(ackFilterNameData) ?
                            <table className="tblAcktemplate" id="ackNameId">
                                <thead>
                                    <tr style={{  fontSize: '12px', padding: '3px' }}>                                        
                                        <th className='headerdisplay' style={{ width: '80%' }}>Filter Names</th>
                                        <th className='headerdisplay' style={{ width: '20%' }}>Is Active</th>                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        isArrayWithLength(ackFilterNameData) && ackFilterNameData.map((fn: IDAcktrackingFilter, val) => (
                                            <React.Fragment key={val}>
                                                <tr>                                                     
                                                    <td>{fn.filterName}</td>
                                                    <td style={{ textAlign: 'center' }} >
                                                        <input disabled={(!isAckTypeActive) ? true : false} type="checkbox" checked={fn.isActive} name="isActive" onChange={(e) => changeFilterActiveStatus(e, fn)} />
                                                    </td>                                                    
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                </tbody>
                            </table>
                            :
                            <React.Fragment>
                                <div style={{ color: 'Red', fontWeight: 'bold', textAlign: 'center' }}> <br />No Filer Names available!! </div>
                            </React.Fragment>
                        }
                    </div>

                </Modal.Body>                
            </Modal>

            <Modal show={showtemplatePopup} onHide={hidetemplatePopup} size='lg' backdrop='static' id="Ackmodelpopup">
                <Modal.Header closeButton className="commonModalHeader">
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='12' style={{ textAlign: 'center' }}>
                                <h4><b>  ID Ack Tracking Template </b></h4>
                            </Col>
                        </Row>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ width: '100%', height: '600px', overflow:'auto' }}>
                    {
                        !isNullOrUndefined(template)
                            ?
                            <React.Fragment>
                                <div>
                                    <br />
                                    <Row>
                                        <Col lg="4">
                                            <span className="mandatorycolor">*</span>&nbsp;<span>Ack Category</span><br />
                                            <span className="errotextstyle">Component selection will create a <b>Service</b> with <b>Component</b> specific ACK. Otherwise, <b>Service</b> specific ACK will be created.</span>
                                        </Col>
                                        <Col lg="1">
                                            :
                                        </Col>
                                        <Col lg="6">
                                              <RadioButton value='Service' name="ackcategory" onChange={(e) => templatedatachange(e)} checked={template.isServiceSpecific === true} />&nbsp; Service&emsp;
                                              <RadioButton value='Component' name="ackcategory" onChange={(e) => templatedatachange(e)} checked={template.isServiceSpecific === false} />&nbsp; Component&nbsp;
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col lg="4">
                                            <span className="mandatorycolor">*</span>&nbsp;<span>Template Name</span>
                                        </Col>
                                        <Col lg="1">
                                            :
                                        </Col>
                                        <Col lg="6">
                                            <input type="text" className="form-control" placeholder="Template Name" value={template.templateName} name="templateName" onChange={(e) => templatedatachange(e)}/>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col lg="4">
                                            <span className="mandatorycolor">*</span>&nbsp;<span>Servicetree Cols List</span>&nbsp;&nbsp;<br />
                                            <span className="errotextstyle">(GEM, Service selections are mandatory)</span>
                                        </Col>
                                        <Col lg="1">
                                            :
                                        </Col>
                                        <Col lg="6">
                                            <MultiSelect id={'servicetreecollist'} value={template.serviceTreeColsList} options={servicecolsList} onChange={(e) => templatedatachange(e)} name="serviceTreeColsList" />
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col lg="4">
                                            <span className="mandatorycolor"></span>&nbsp;<span>Is Active</span><br/>
                                            <span className="errotextstyle">(Template will be disabled if Is Active Flag is unchecked)</span>
                                        </Col>
                                        <Col lg="1">
                                            :
                                        </Col>
                                        <Col lg="6">
                                            <input type="checkbox" checked={template.isActive} disabled={template.templateID===0} name="isActive" onChange={(e) => templatedatachange(e)} />
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row className="columnsheader">
                                        <Col lg="3">
                                           
                                        </Col>
                                        <Col lg="6">
                                            List of Columns<span className="errotextstyle">&nbsp;&nbsp;(Atleast One column is mandatory)</span>
                                        </Col>
                                        <Col lg="3">
                                            <span title="Add Column" onClick={AddcolumnstoTemplate}> <FontAwesomeIcon icon={'plus'} className="ackediticon" /></span>
                                        </Col>
                                    </Row>
                                    <br />
                                    {
                                        isArrayWithLength(changedColumns)
                                            ?
                                            <React.Fragment>
                                                <Row className="fontsizeinsaw14" style={{ fontWeight:'bold' }}>
                                                <Col lg="2">
                                                        <span className="redcolourtxt">*</span>&nbsp;&nbsp;Column Name                                                        
                                                </Col>
                                                <Col lg="2">
                                                    <span className="redcolourtxt">*</span>&nbsp;&nbsp;Control Type
                                                </Col>
                                                <Col lg="2">
                                                    Control Value
                                                    <div className="errotextstyle">(Enter Values as Comma seperated)</div>
                                                </Col>
                                                <Col lg="2">
                                                    Is Coloring Required
                                                </Col>
                                                <Col lg="2">
                                                    <span className="redcolourtxt">*</span>&nbsp;&nbsp;Display Order
                                                     <div className="errotextstyle">( Display order starts from 1 )</div>
                                                </Col>
                                                <Col lg="1">
                                                    Is Required
                                                </Col>
                                                <Col lg="1">
                                                   
                                                </Col>
                                                </Row>
                                                <br />
                                                {changedColumns.map((row, index) => (
                                                    <React.Fragment key={ index}>

                                                        <Row style={{ paddingTop:'6px' }}>
                                                            <Col sm="2">
                                                                <div className='tooltipLS'>
                                                                    <span className="tooltiptextLS">Maximum 128 characters allowed and Comma not allowed</span>
                                                                <input type="text" maxLength={128} name="ColumnName" id={'temp' + index} value={row.ColumnName} className="form-control ackcolumninputtext" onChange={e => templatecolumnvalueschange(e, index)} />
                                                                </div>
                                                        </Col>
                                                        <Col sm="2">
                                                            <span className="p-float-label acklables">
                                                                    <Dropdown id={'ddlcontroltype'} value={row.ControlType} options={columncontroltypeoptions} onChange={(e) => templatecolumnvalueschange(e, index)} optionLabel="name" name='ControlType' />
                                                                <label id="lblcontrltype" htmlFor="servicegroup" className="fontcolor acklabletxt">Control Type</label>
                                                            </span>
                                                        </Col>
                                                        <Col sm="2">
                                                            {
                                                                (row.ControlType === 'Radio Button' || row.ControlType === 'CheckBox' || row.ControlType === 'Dropdown')
                                                                    ?
                                                                        <React.Fragment>
                                                                             <div className='tooltipLS'>
                                                                                <span className="tooltiptextLS">For multiple options, enter comma separated values for the CheckBox/Radio Button/Dropdown Control Type</span>
                                                                        <span className="redcolourtxt" >*</span>&nbsp;
                                                                        <input type="text"
                                                                                value={row.ControlValue} name="ControlValue" onChange={e => templatecolumnvalueschange(e, index)} className="txtcontrolvalues ackcolumninputtext" />
                                                                            </div>
                                                                    </React.Fragment>
                                                                    : <React.Fragment></React.Fragment>
                                                            }
                                                        </Col>
                                                        <Col sm="2">
                                                            {
                                                                (row.ControlType === 'Radio Button' || row.ControlType === 'CheckBox' || row.ControlType === 'Dropdown')
                                                                    ?
                                                                    <React.Fragment>
                                                                        {
                                                                            !isNullOrUndefined(row.ControlValue)
                                                                                ? <React.Fragment>
                                                                                        <input type="checkbox" name="IsColoringRequired" checked={row.IsColoringRequired} onChange={e => templatecolumnvalueschange(e, index)} />&nbsp;Yes
                                                                                </React.Fragment>
                                                                                : <React.Fragment></React.Fragment>
                                                                        }
                                                                    </React.Fragment>
                                                                    : <React.Fragment></React.Fragment>
                                                            }
                                                            
                                                        </Col>
                                                        <Col sm="2">
                                                                <input type="number" min="0" name="DisplayOrder" value={row.DisplayOrder} onChange={(e) => templatecolumnvalueschange(e, index)} className="form-control ackcolumndisplayorder"   />
                                                        </Col>
                                                            <Col sm="1">
                                                                <input type="checkbox" name="IsRequired" checked={row.IsRequired} onChange={e => templatecolumnvalueschange(e, index)} />&nbsp;Yes
                                                         </Col>
                                                            <Col sm="1">
                                                                <span title="Delete Column" onClick={e => DeleteTemplateColumn(index)}> <FontAwesomeIcon icon={'trash'} className="ackdeleteicon" /></span>
                                                          </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                ))}
                                                
                                                <br />
                                                {isArrayWithLength(columncoloring)
                                                    ?
                                                    <React.Fragment>
                                                        <Row className="colouringheader">
                                                            <Col lg="6">Coloring</Col>
                                                            <Col lg="6"></Col>
                                                        </Row>
                                                        <table className="table-bordered table-striped">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="colouringtdstyle">Column Name</td>
                                                                    <td className="colouringtdstyle">Control Value</td>
                                                                    <td className="colouringtdstyle">Color Pallete</td>
                                                                    <td></td>
                                                                </tr>
                                                               
                                                                    {
                                                                        columncoloring.map((row, index1) => (
                                                                            <React.Fragment key={index1}>
                                                                                <tr>
                                                                                    <td className="colouringtdtext textaligncenter">{row.ColumnName }</td>
                                                                                    <td className="colouringtdtext textaligncenter">{row.ControlValue }</td>
                                                                                    <td>
                                                                                        <span className="p-float-label acklables">
                                                                                            <Dropdown id={'colourpallete'} value={row.ColorPallete} options={colorsData} onChange={(e) => colourpalletecolourchane1(e, row, index1)} optionLabel="name" name='ColorPallete' />
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <input id={'color' + index1} type="color" value={row.colorpalettecolor} onChange={(e) => customcolourchange(e, row)} name='colorpalettecolor' />
                                                                                    </td>
                                                                                </tr>
                                                                            </React.Fragment>
                                                                        ))
                                                                    }
                                                                    
                                                               
                                                            </tbody>
                                                        </table>
                                                    </React.Fragment>
                                                    : <React.Fragment></React.Fragment>
                                                 }
                                            </React.Fragment>
                                            : <React.Fragment></React.Fragment>
                                    }
                                    
                                </div>
                            </React.Fragment>
                            : <React.Fragment></React.Fragment>
                    }
                    

                </Modal.Body>
                <Modal.Footer>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='8'></Col>

                            <Col lg='3'>
                                <Button className='DailydataModelbuttons' disabled={templatesavebuttondisable} onClick={SaveIDAckTrackTemplate} >Save</Button>
                            </Col>
                            <Col lg='1'></Col>
                        </Row>
                    </div>
                </Modal.Footer>
            </Modal>
            <Dialog visible={archiveDialog} style={{ width: '450px' }} header="Confirm" modal footer={archiveDialogFooter} onHide={hideArchiveDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', color: 'red' }} />
                    <span>Archiving hides this item permanently.<br></br> Are you sure you want to archive?</span>
                </div>
            </Dialog>
        </div>
    )
});

AckTemplateTable.displayName = 'AckTemplateTableProps';
export default AckTemplateTable;