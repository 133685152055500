import React, { useEffect } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import { actionCreators, reducer } from '../../store/MSERHeatmapTrend';
import { MSERHeatmapTrendTable } from '../MSERHeatmapTrend/child-components';
import LoadingOverlay from 'react-loading-overlay';
import { isArrayWithLength } from '../../utils';
import { IServiceGroup } from '../../store/home-tiles';
import { NewFunctionalityBanner } from '../../components';

type MSERHeatmapTrendProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly history: History }
    & typeof useParams;

const MSERHeatmapTrend: React.FC<MSERHeatmapTrendProps> = ({
    isLoading,
    addUserVisit,
    isAuthenticated,
    mserHeatmapTrendStartDate,
    onmserHeatmapTrendStartDateChange,
    getMSERHeatmapTrendData,
    mserHeatmapTrendData,
    MSERHeatmapTrendheaderArr,
    MSERHeatmapTrendKPiArr,
    IsMSERHeatmapTrendDataLoaded,
    ClearMSERHeatmapTrendDate,
    liKPIValueKeys,
     uniquev,
    uniquec,
    selectedidentityServiceClassification,
    serviceGroups,
    services,
    GetServicegroups,
    GetServices,
    servicegroupval,
    servicegroupvalchange,
    serviceval,
    serviceschange,
    selectedidentity_ServiceClassification,
    serviceidentityclassificationchange,
    globalsearch,
    searchtextboxchange,
    servicegroupvalstring,
    servicevalstring,
    clearfilters,
    isDataloaded

}) => {
    useEffect(() => {
        if (isAuthenticated) {
            GetServicegroups();
            GetServices();
            addUserVisit(0, 'MSERHeatmapTrend.tsx_v2');
        }
           
    }, [isAuthenticated]);
    
    const identityServiceClassification = [
        "All",
        "Mission Critical",
        "Business Critical"        
    ]
    useEffect(() => {
        if (servicegroupvalstring !== '' && servicevalstring!='') {
            getMSERHeatmapTrendData(mserHeatmapTrendStartDate, selectedidentityServiceClassification, servicegroupvalstring, servicevalstring);
        }
    }, [serviceGroups, services]);
    
    const handleidentityServiceClassification = (value) => {        
        selectedidentityServiceClassification = value;
        //getMSERHeatmapTrendData(mserHeatmapTrendStartDate, selectedidentityServiceClassification, servicegroupvalstring, servicevalstring);        
    }
    const FilterTrendData = () => {
        getMSERHeatmapTrendData(mserHeatmapTrendStartDate, selectedidentityServiceClassification, servicegroupvalstring, servicevalstring);       
    }
    const clearfilterdata = () => {
        const servicegroupvals = [];
        let servicegroupvalstrings = '';
        if (isArrayWithLength(serviceGroups)) {
            serviceGroups.map((item, key) => {
                servicegroupvals.push(item.value);
                servicegroupvalstrings = servicegroupvalstrings + ',' + item.value;
                
            })
        }
        const servicevals = [];
        let servicevalstrings = '';
        if (isArrayWithLength(services)) {
            services.map((item) => {
                servicevals.push(item.value);                
                servicevalstrings = servicevalstrings + ',' + item.value;
            })
        }
        clearfilters(servicegroupvals, servicegroupvalstrings, servicevals, servicevalstrings, 'All');
        getMSERHeatmapTrendData(mserHeatmapTrendStartDate, 'All', servicegroupvalstrings, servicevalstrings);
    }
    return (
        <div>
            <NewFunctionalityBanner />
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        <MSERHeatmapTrendTable
                            mserHeatmapTrendStartDate={mserHeatmapTrendStartDate}
                            onmserHeatmapTrendStartDateChange={onmserHeatmapTrendStartDateChange}
                            MSERHeatmapTrendData={mserHeatmapTrendData}
                            MSERHeatmapTrendheaderArr={MSERHeatmapTrendheaderArr}
                            MSERHeatmapTrendKPiArr={MSERHeatmapTrendKPiArr}
                            IsMSERHeatmapTrendDataLoaded={IsMSERHeatmapTrendDataLoaded}
                            ClearMSERHeatmapTrendDate={ClearMSERHeatmapTrendDate}
                            liKPIValueKeys={liKPIValueKeys}
                            uniquev={uniquev}
                            uniquec={uniquec}
                            selectedidentityServiceClassification={selectedidentityServiceClassification}
                            identityServiceClassification={identityServiceClassification}
                            handleidentityServiceClassification={handleidentityServiceClassification}
                            serviceGroups={serviceGroups}
                            services={services}
                            servicegroupval={servicegroupval}
                            servicegroupvalchange={servicegroupvalchange}
                            serviceval={serviceval}
                            serviceschange={serviceschange}
                            selectedidentity_ServiceClassification={selectedidentity_ServiceClassification}
                            serviceidentityclassificationchange={serviceidentityclassificationchange}
                            globalsearch={globalsearch}
                            searchtextboxchange={searchtextboxchange}
                            FilterTrendData={FilterTrendData}
                            clearfilterdata={clearfilterdata}
                            isDataloaded={isDataloaded}
                        />
                    </div>
                </section>
            </LoadingOverlay>

        </div>
    );

};
const mapStateToProps = (state: IApplicationState) => ({
    ...state.MSERHeatmapTrend,
    isAuthenticated: state.authUser.isAuthenticated

})

export default connect(mapStateToProps, actionCreators)(MSERHeatmapTrend);
