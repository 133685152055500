import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IInternalEscalationState } from './types';

const initialState = Object.freeze<IInternalEscalationState>({
    isLoading: false,
    onCallData: [],
    filteredonCallData: [],
    rbRequestAssistanceList: [],
    selectedCloud: 'All',
    serviceLifeCycle: [],
    selectedServiceLifeCycle: [],
    classificationList: [],
    selectedClassification: [],
    serviceGroupNameList: [],
    teamGroupNameList: [],
    selectedServiceGroupName: [],
    selectedTeamGroupName: [],
    tenantList: [],
    selectedTenant: [],
    selectedImorOce: 'All',
    copyEmailselectedImorOce: 'All',
    selectedIdentityServiceClassificationType: 'All',
    selectedService: [],
    defaultexpandrows: {},
    Isopenemailpopup: false,
    OnCallAliasEmailIDs: '',
    BackupOnCallAliasEmailIDs: ''
});

export const reducer = (
    state: IInternalEscalationState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false
            };
        case ActionType.GET_INTERNALESCALATION_REQUEST:
            return {
                ...state,
                isLoading: true,
                classificationList: [],
                serviceLifeCycle: [],
                serviceGroupNameList: [],
                teamGroupNameList: [],
                tenantList: [],
                selectedCloud: 'All',
                selectedImorOce: 'All',
                selectedIdentityServiceClassificationType: 'All',
                selectedServiceLifeCycle: [],
                selectedClassification: [],
                selectedServiceGroupName: [],
                selectedTeamGroupName: [],
                selectedTenant: [],
                selectedService: [],
                onCallData: [],
                filteredonCallData: []
            };
        case ActionType.GET_INTERNALESCALATION_SUCCESS:
            return {
                ...state,
                classificationList: action.classificationList,
                serviceLifeCycle: action.serviceLifeCycle,
                serviceGroupNameList: action.serviceGroupNameList,
                tenantList: action.tenantList,
                teamGroupNameList: action.teamGroupNameList
            };
        case ActionType.GET_INTERNALESCALATION_FAIL:
            return {
                ...state,
                isLoading: false,
                classificationList: [],
                serviceLifeCycle: [],
                serviceGroupNameList: [],
                tenantList: [],
                teamGroupNameList: [],
                selectedCloud: 'All',
                selectedImorOce: 'All',
                selectedIdentityServiceClassificationType: 'All',
                selectedServiceLifeCycle: [],
                selectedClassification: [],
                selectedServiceGroupName: [],
                selectedTeamGroupName: [],
                selectedTenant: [],
                selectedService: [],
                onCallData: [],
                filteredonCallData: []
            };
        case ActionType.CLOUD_CHANGE:
            return {
                ...state,
                selectedCloud: action.selectedCloud
            };
        case ActionType.SERVICELIFECYCLE_CHANGE:
            return {
                ...state,
                selectedServiceLifeCycle: action.selectedServiceLifeCycle
            };
        case ActionType.CLASSIFICATION_CHANGE:
            return {
                ...state,
                selectedClassification: action.selectedClassification
            };
        case ActionType.SERVICEGROUP_CHANGE:
            return {
                ...state,
                selectedServiceGroupName: action.selectedServiceGroupName
            };
        case ActionType.TENANT_CHANGE:
            return {
                ...state,
                selectedTenant: action.selectedTenant
            };
        case ActionType.TEAMGROUP_CHANGE:
            return {
                ...state,
                selectedTeamGroupName: action.selectedTeamGroupName
            };
        case ActionType.IMOROCE_CHANGE:
            return {
                ...state,
                selectedImorOce: action.selectedImorOce
            };
        case ActionType.SELECTEDIDENTITYSERVICECLASSIFICATIONTYPE_CHANGE:
            return {
                ...state,
                selectedIdentityServiceClassificationType: action.selectedIdentityServiceClassificationType
            };
        case ActionType.UPDATE_ONCALLDATA_SUCCESS:
            return {
                ...state,
                onCallData: action.onCallData,
                filteredonCallData: action.filteredonCallData,
                defaultexpandrows: action.defaultexpandrows,
                copyEmailselectedImorOce: action.copyEmailselectedImorOce
            };  
        case ActionType.OPEN_EMAIL_POPUP:
            return {
                ...state,
                OnCallAliasEmailIDs: action.OnCallAliasEmailIDs,
                BackupOnCallAliasEmailIDs: action.BackupOnCallAliasEmailIDs,
                Isopenemailpopup: true
            };
        case ActionType.EMAIL_POPUP_CLOSE:
            return {
                ...state,
                OnCallAliasEmailIDs: '',
                BackupOnCallAliasEmailIDs: '',
                Isopenemailpopup: false
            };
        case ActionType.UPDATE_RBREQUESTASSISTANCELIST_SUCCESS:
            return {
                ...state,
                rbRequestAssistanceList: action.rbRequestAssistanceList
            };    
        case ActionType.CLEAR_INTERNALESCALATION_DROPDOWNFILTERVALUES:
            return {
                ...state,
                selectedCloud: action.selectedCloud,
                selectedServiceLifeCycle: action.selectedServiceLifeCycle,
                selectedClassification: action.selectedClassification,
                selectedServiceGroupName: action.selectedServiceGroupName,
                selectedTeamGroupName: action.selectedTeamGroupName,
                selectedTenant: action.selectedTenant,
                selectedImorOce: action.selectedImorOce,
                selectedIdentityServiceClassificationType: action.selectedIdentityServiceClassificationType
            };    
        case ActionType.RESET_STATE:
            return initialState;

        default:
            return state;
    }
};
