import { IMonthlyReport } from '../service-health/types';
import { IServiceGroup } from '../home-tiles';

export interface ITargetJson {
    readonly serviceId: number;
    readonly serviceName: string;
    readonly originalTarget: string;
    readonly heatmapViewId: number;
    readonly updatedTarget: string;
    readonly justification: string;
    readonly viewId: number;
    readonly requestedBy: string;
    readonly approvalStatus: string;
    readonly approvedBy: string;
    readonly approvedDate: string;
    readonly reviewerComments: string;
}

export interface IActionType {
    readonly RESET_STATE: string;
    readonly ADDED_VISITED_USER: string;  
    readonly MONTHLY_START_DATE_CHANGE: string;
    readonly MONTHLYDATA_FETCH: string;
    readonly MONTHLYDATA_RECEIVE: string;
    readonly GET_SERVICEGROUPS_SUCCESS: string;
    readonly GET_SERVICEGROUPS_FAIL: string;
    readonly GET_SERVICELISTS_SUCCESS: string;
    readonly GET_SERVICELISTS_FAIL: string;
    readonly SERVICE_GROUPVAL_CHANGE: string;
    readonly SERVICE_VAL_CHANGE: string;
    readonly SEARCH_TEXTBOX_CHANGE: string;
    readonly CLEAR_FILTERS: string;
    readonly SET_LOADING_TRUE: string;
    readonly SET_LOADING_FALSE: string;
    readonly SET_URL_PARAM_EMPTY: string;
    readonly MONTHLYDATA_FILTERED: string;
    readonly SET_TARGET_POPUP: string;
    readonly SET_TARGET_POPUP_CLOSE: string;
    readonly UPDATEDTARGETDATASET: string;
    readonly UPDATEDTARGET: string;
    readonly UPDATEJUSTIFICATION: string;
    readonly CLEARTARGET: string;
    readonly EMAILCONFIRMATION: string;
}
export type IMonthlyAvailbilityState = {
    readonly isLoading: boolean;  
    monthlyStartDate: Date;
    readonly monthlyData: IMonthlyReport[];
    readonly MonthlyheaderArr: [];
    readonly MonthlyKPiArr: [];
    IsMonthlyDataLoaded: boolean;
    liKPIValueKeys: [];
    serviceGroups: IServiceGroup[];
    services: IServiceGroup[];
    servicegroupval: [];
    serviceval: [];
    globalsearch: string;
    servicegroupvalstring: string;
    servicevalstring: string;
    readonly isDataloaded: boolean;
    readonly urlparam: string;
    editedTargetData: ITargetJson[];
    TargetEditPop: boolean;
    originalTargetValue: string;
    updatedTargetValue: string;
    justificationDetails: string;
};

const _namespace = 'MonthlyAvailbility ';

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/reset`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    MONTHLY_START_DATE_CHANGE: `${_namespace}/monthlystartDateChange`,
    MONTHLYDATA_RECEIVE: `${_namespace}/monthlydatareceive`,
    MONTHLYDATA_FETCH: `${_namespace}/monthlydatafetech`,
    GET_SERVICEGROUPS_SUCCESS: `${_namespace}/getservicegroupsuccess`,
    GET_SERVICEGROUPS_FAIL: `${_namespace}/getservicegroupfail`,
    GET_SERVICELISTS_SUCCESS: `${_namespace}/getservicelistsuccess`,
    GET_SERVICELISTS_FAIL: `${_namespace}/getservicefail`,
    SERVICE_GROUPVAL_CHANGE: `${_namespace}/servicegroupvalchange`,
    SERVICE_VAL_CHANGE: `${_namespace}/servicevalchange`,
    SEARCH_TEXTBOX_CHANGE: `${_namespace}/searchtextboxchange`,
    CLEAR_FILTERS: `${_namespace}/clearfilters`,
    SET_LOADING_TRUE: `${_namespace}/loadingtrue`,
    SET_LOADING_FALSE: `${_namespace}/loadingfalse`,
    SET_URL_PARAM_EMPTY: `${_namespace}/seturlparamempty`,
    MONTHLYDATA_FILTERED: `${_namespace}/monthlydatafiltered`,
    SET_TARGET_POPUP: `${_namespace}/settargetpopup`,
    SET_TARGET_POPUP_CLOSE: `${_namespace}/settargetpopupclose`,
    UPDATEDTARGETDATASET: `${_namespace}/updatedtargetdataset`,
    UPDATEDTARGET: `${_namespace}/updatedtarget`,
    UPDATEJUSTIFICATION: `${_namespace}/updatedjustification`,
    CLEARTARGET: `${_namespace}/cleartarget`,
    EMAILCONFIRMATION: `${_namespace}/emailconfirmation`
});

