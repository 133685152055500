import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getToken } from '../adalConfig';
import { actionCreators,reducer, IAuthUser } from '../store/auth';
import { useParams } from 'react-router-dom';
import { IApplicationState } from '../store';

type AuthProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & typeof useParams;

const Auth: React.FC<AuthProps> = ({
    authUser,
    loginUserRequest,
    getServiceGroups,
    serviceGroup,    
    getDateRefreshed,    
    DataRefreshed,

}) => {

    const [user, setUser] = useState<IAuthUser>();
    const [values, setValues] = useState<string[]>([]);

    useEffect(() => {
        loginUserRequest();   
        getServiceGroups();
        getDateRefreshed();
    }, [loginUserRequest]);

    return (
        <div>          
            {authUser && authUser.isAuthenticated
                ? <div>Authenticated as {authUser.login}</div>
                : <div>&nbsp;</div>
            }
        </div>
    );
}

const mapStateToProps = (state: IApplicationState) => ({
    ...state.authUser
})

export default connect(mapStateToProps, actionCreators)(Auth)


