import { IPropCalender, IServiceGroup } from '../home-tiles';
export type DailyDataStatus = 'none' | 'process' | 'success' | 'fail';


//TODO : Add different approach
export const DailyDataStatusEnum = Object.freeze<{ [key: string]: DailyDataStatus }>({
    NONE: 'none',
    PROCESS: 'process',
    SUCCESS: 'success',
    FAIL: 'fail'
});

export interface IActionType {
    readonly REQUEST: string;
    readonly RECEIVE: string;
    readonly RESET_STATE: string;
    readonly VIEW_REQUEST: string;
    readonly VIEW_RECEIVE: string;
    readonly VIEW_RESET_STATE: string;
    readonly DROPDOWN_SELECT: string;
    /*readonly SET_WEEKLY_START_DATE: string;*/
    readonly CHECK_SAMPLE_BOX: string;
    readonly VIEW_DROPDOWN_SELECT: string;
    readonly START_DATE_CHANGE: string;
    readonly END_DATE_CHANGE: string;
    readonly DAILYDATA_UPDATE_SUCCESS: string;
    readonly DAILYDATA_UPDATE_FAIL: string;
    readonly DAILYDATA_ADD_SUCCESS: string;
    readonly DAILYDATA_ADD_FAIL: string;
    readonly BULKVIEWS_RECEIVE: string;
    readonly BULKVIEWS_FAIL: string;
    readonly COMMENT_INCIDENT_CHECK: string;
    readonly BULK_UPDATE_COMMENTS_SUCCESS: string;
    readonly DAILY_FILTER_APPLIED: string;
    readonly CURRENT_SERVICE_NAVIGATOR: string;
    readonly PREVIOUS_SERVICE_NAVIGATOR: string;
    readonly NEXT_SERVICE_NAVIGATOR: string;
    readonly DATA_REFRESH: string;
    readonly WEEKLY_START_DATE_CHANGE: string;
    readonly WEEKLYDATA_RECEIVE: string;
    readonly WEEKLY_DETAILS_DATA_RECEIVE: string;
    readonly DRAFTS_DATA_RECEIVE: string;
    readonly CONFIGVALUES_RECEIVE: string;
    readonly WEEKDATA_UPDATE_FAIL: string;
    readonly SET_LOADING_TRUE: string;
    readonly SET_LOADING_FALSE: string;
    readonly WEEKLYDATA_FETCH: string;
    readonly FLEET_DROPDOWN_DATA_FETCH: string;
    readonly FLEET_DROPDOWN_DATA_RECEIVE: string;
    readonly FLEET_DATA_FETCH: string;
    readonly FLEET_DATA_RECEIVE: string;
    readonly SET_FLEETDATALOADED_TRUE: string;
    readonly DAILY_FLEET_FILTER_APPLIED: string;
    readonly FLEET_START_DATE_CHANGE: string;
    readonly FLEET_END_DATE_CHANGE: string;
    readonly TENANT_DROPDOWN_SELECT: string;
    readonly ROLE_DROPDOWN_SELECT: string;
    readonly CLOUD_DROPDOWN_SELECT: string;
    readonly TRAFFICGROUP_DROPDOWN_SELECT: string;
    readonly SET_REFRESHDATE_SUCCESS: string;
    readonly CHECK_SAVE_MSER: string;
    readonly CHECK_MSER: string;
    readonly CHECK_MONTHLY_AVAILABILITY: string;
    readonly CHECK_SAVE_MONTHLY_AVAILABILITY: string;
    readonly CHECK_RED: string;
    readonly CHECK_YELLOW: string;
    readonly MONTHLY_START_DATE_CHANGE: string;
    readonly MONTHLYDATA_RECEIVE: string;
    readonly MONTHLYDATA_FETCH: string;
    readonly BGM_RECEIVE: string;
    readonly EXTERNALLINKS_RECEIVE: string;
    readonly COMMENTS_RECEIVE: string;
    readonly PERFORMANCE_REQUEST: string;
    readonly PERFORMANCE_RECEIVEDATA: string;
    readonly WEEKLY_PERFORMANCE_RECEIVE: string;
    readonly CHECK_BULK_EDIT: string;
    readonly VIEW_RECEIVE_FAIL: string;
    readonly WEEKLY_DETAILS_DATA_RECEIVE_FAIL: string;
    readonly SET_REFRESHDATE_FAIL: string;
    readonly COMMENTS_RECEIVE_FAIL: string;
    readonly DRAFTS_DATA_RECEIVE_FAIL: string;
    readonly CONFIGVALUES_RECEIVE_FAIL: string;
    readonly WEEKLYDATA_RECEIVE_FAIL: string;
    readonly BGM_RECEIVE_FAIL: string;
    readonly EXTERNALLINKS_RECEIVE_FAIL: string;
    readonly WEEKLY_PERFORMANCE_RECEIVE_FAIL: string;
    readonly DAILYDATAFAIL: string;
    readonly CHECK_HISTORY: string;
    readonly VIEWCONFIGURATION_NOOFDECIMALS: string;
    readonly VIEWCONFIGURATION_COLORSCHEMES: string;
    readonly VIEWCONFIGURATION_COLORLOGIC: string;
    readonly VIEWCONFIGURATION_VALUES_CHANGE: string;
    readonly COLOR_SCHEME_SELECT: string;
    readonly COLORLOGIC_SELECT: string;
    readonly NOOFDECIMALS_SELECT: string;
    readonly VOLUMEDISPLAY_SELECT: string;
    readonly AUTHENTOCATIONTYPE_SELECT: string;
    readonly SET_DISABLE_VIEWCONFIG_BUTTON: string;
    readonly CREATE_NEW_VIEW_CHANGE: string;
    readonly CHECK__REACHABILITY_CHANGE: string;
    readonly SET_DAILYDAT_FILTER_VIEWID: string;
    readonly SET_VIEW_DROPDOWN_BASEDON_TAB_SELECTION: string;
    readonly SET_DAILYDATA_ACTIVETAB: string;
    readonly SET_MSERCOMMENTS: string;
    readonly FETCH_REACHABILITY_SERVICES: string;
    readonly REACHABILITY_SERVICE_DROPDOWN_SELECT: string;
    readonly REACHABILITY_SERVICE_VIEW_FAIL: string;
    readonly REQUEST_REACHABILITY_SERVICES: string;
    readonly REACHABILITY_SERVICE_RECEIVE_FAIL: string;
    readonly DAILY_FILTER_YELLOW: string;
    readonly DAILY_FILTER_RED: string;
    readonly FLEET_FILTER_YELLOW: string;
    readonly FLEET_FILTER_RED: string;
    readonly ADDED_VISITED_USER: string;
    readonly SETURL_PARAM_EMPTY: string;
    readonly SET_DRILLDOWN_VALUE: string;
    readonly SET_NOVIEWS: string;
    readonly GET_WEEKLYSELFSERVE_REQUEST: string;
    readonly GET_SERVICEGROUPS_SUCCESS: string;
    readonly GET_SERVICEGROUPS_FAIL: string;
    readonly GET_SERVICELISTS_SUCCESS: string;
    readonly GET_SERVICELISTS_FAIL: string;
    readonly SERVICEGROUP_CHANGE: string;
    readonly SERVICES_CHANGE: string;
    readonly METRICGROUP_CHANGE: string;
    readonly METRIC_CHANGE: string;
    readonly ENVIRONMENT_CHANGE: string;
    readonly DATASOURCE_CHANGE: string;
    readonly GET_METRICGROUPS_SUCCESS: string;
    readonly GET_METRICGROUPS_FAIL: string;
    readonly GET_ENVIRONMENT_SUCCESS: string;
    readonly GET_ENVIRONMENT_FAIL: string;
    readonly GET_DATASOURCE_SUCCESS: string;
    readonly GET_AUTHENTICATIONTYPE_SUCCESS: string;
    readonly GET_DATASOURCE_FAIL: string;
    readonly GET_AUTHENTICATIONTYPE_FAIL: string;
    readonly GET_METRICDATASOURCE_REQUEST: string;
    readonly GET_METRICDATASOURCE_SUCCESS: string;
    readonly GET_METRICDATASOURCE_FAIL: string;
    readonly FILTER_METRICDATASOURCE_SUCCESS: string;
    readonly FILTER_METRICDATASOURCE_REQUEST: string;
    readonly METRICDATASOURCE_SERVER_CHANGE: string;
    readonly METRICDATASOURCE_METRICNAME_ADD: string;
    readonly METRICDATASOURCE_METRICGROUP_ADD: string;
    readonly METRICDATASOURCE_DATABASE_CHANGE: string;
    readonly MDM_METRICNAME_CHANGE: string;
    readonly METRICDATASOURCE_ONBOARD_CHANGE: string;
    readonly METRICDATASOURCE_AUTHENTICATIONTYPE_CHANGE: string;
    readonly METRICDATASOURCE_QUERYORURL_CHANGE: string;
    readonly METRICDATASOURCE_TARGET_CHANGE: string;
    readonly METRICDATASOURCE_NOTES_CHANGE: string;
    readonly CLEAR_METRICDATASOURCE_SECTION: string;
    readonly GET_TARGETTYPE_SUCCESS: string;
    readonly GET_TARGETTYPE_FAIL: string;
    readonly ENABLE_EDITORADD_METRICDATASOURCE: string;
    readonly SET_SELECTEDSERVICEID_EMPTY: string;
    readonly RESET_FILTER: string; 
    readonly COLORSCHEME_SUCCESS: string;
    readonly METRICDATASOURCE_VIEW_ENABLE: string;
    readonly METRICDATASOURCE_VIEW_DISABLE: string;
    readonly METRICDATASOURCE_ADD_ENABLE: string;
    readonly METRICDATASOURCE_ADD_DISABLE: string;
    readonly HEATMAP_DAILYDATA_FAILURE: string;
    readonly SET_DAILYDATA_UPDATELABELVALUES: string;
    readonly SET_VIEWNAME_UPDATEVALUE: string;
    readonly MULTIPLEVIEW_SELECT_CHANGE: string;
    readonly MULTIPLEVIEW_DROPDOWN_SELECT: string;
    readonly MULTIVIEW_RECEIVE: string;
    readonly MULTIVIEWDAILYDATAFAIL: string;
    readonly MULTIPLEVIEW_DATA_REFRESH: string;
    readonly MULTIVIEW_COLORSCHEME_RECEIVE: string;
}
  
export type IPropKpiHistory = {
    readonly serviceId: string;
    readonly weekId: number;
    readonly oldVal: string;
    readonly metricGroupName: string;
    readonly metricName: string;
    readonly metricId: number;
    readonly kpiValue: string;
    readonly Comments: string;
    readonly updatedDate: Date;
    readonly updatedBy: string;
    readonly uom: string;
    readonly versionId: number;
}
export interface IWeeks  {
    readonly weekId: number;
    readonly weekDisplayName: string;
    readonly weekStartDate: Date;
    readonly weekEndDate: Date;
    readonly weekDetail: string;
  

}

export interface IMonths  {
    readonly name: string;
    readonly week: IWeeks[];
   
}
export interface IYear  {
    readonly name: number;
    readonly month: IMonths[];

}
export interface IWeeks {
    readonly week1: string;
    readonly week1ID: number;
    readonly week2: string;
    readonly week2ID: number;
    readonly week3: string;
    readonly week3ID: number;
    readonly week4: string;
    readonly week4ID: number;
    readonly week5: string;
    readonly week5ID: number;

}
export interface IWeekDetails {
    readonly weekDet1: string;
    readonly weekDet2: string;
    readonly weekDet3: string;
    readonly weekDet4: string;
    readonly weekDet5: string;
   

}
export interface IWeekDetails {
    readonly selectedWeekId: number;
    readonly weeks: IWeeks;
    readonly weeksDetails: IWeekDetails;

}
export interface IPropDraft {
    readonly serviceId: number;
    readonly isFinalised: boolean;
    readonly finalisedDate: Date;
    readonly finalisedBy: string;
    readonly weekDate: Date;


}
export interface PropKPICalculationDetails {
   
    metricName: string;       
    metricDisplayName: string;
    environment: string;
    totalRequests : boolean;
    totalFailures : boolean;
    percentageSuccess: string;
    qoS: string;
    displayOrder:number;
    spanMetric:number;
    includedTestTraffic: string;
}
export interface PropKpiCalculationLogic {
    metricGroupDisplayName: string;
    details: string;     
    }
export interface PropConfigData {
    lsiTicketString: string;
    vsoTicketString: string;
    icmTicketString: string;
    liveSiteConnectionString: string;
    lsUserGrpId: string;
    lsAdminGrpId: string;
    aadAppId: string;
    aadAppKey: string;
    aadInstanceForKusto: string;
    tenant: string;
    isProduction: boolean;
}
export interface PropTicketDetails {
    ticketId: number;
    ticketTitle: string;
    ticketStatus: string;
    isLsi?: boolean;
    isDetected?: boolean;
    metricId: number;
    weekId: number;
    kpiDate?: Date;
    createdDate?: Date;
    dueDate?: Date;
    serviceId: number;
    envId: number;
    tfsId: number;
    slaStatus: string;
    pirReportId: string;
    deletedBy: string;
    scrumOriginalEta?: Date;
    scrumRevisedEta?: Date;
    userName: string;
    chkRemove: false;
    assignee: string;
}
export interface PropBgm {
    metricId: number;
    uom: string;
    metricSource: string;
    users: string;   
    validationExpr: string;
    week0: string;
    week1: string;
    week2: string;
    week3: string;
    week4: string;
    week5: string;
    week5Edit: string;
    comment: string;
    weekp1: string;
    weekp2: string;
    weekp3: string;
    weekp4: string;
    weekp5: string;
    displayOrder: number;
    config: number;
    cmtcolor: string;
    valuecolor: string;
    readonly perfPopupGrpId: number;
    color: string;
    isBGMVariation: boolean;
    envId: number;
}

export interface PropExternalLinks {
    serviceId: number;
    category: string;
    url: string;
  
}
export interface PropMetricGroups {
    metricGroupId: number;
    metricGroupName: string;
   
}
export interface PropTicketReport {
    weekName: string;
    weekId: number;
    serviceId: number;
    totalOpened?: number;
    opened?: number;
    closed?: number;
    outOfSla?: number;
    pirLt?: number;
    pirMt?: number;
    pirSt?: number;
    userSk: string;

}
export interface PropKpiComments {
    serviceId: number;
    metricGroupId: number;
    metricGroupName: string;
    wStartDate?: Date;
    comments: string;
   

}
export interface IWeeklyReport {
    readonly groupName: string;
     serviceId: number;
    readonly parentReachabilityServiceId: string;
    readonly serviceName: string;
    readonly sSLName: string;
    readonly kpi: string;
    readonly metricSource: string;
    readonly metricName: string;
    readonly environment: string;
    readonly targetName: string;
    readonly ml03Id: string;
    readonly week1: string;
    readonly week2: string;
    readonly week3: string;
    readonly week4: string;
    readonly week5: string;
     week1Edit: string;
     week2Edit: string;
     week3Edit: string;
     week4Edit: string;
     week5Edit: string;
     week1Sort: string;
     week2Sort: string;
     week3Sort: string;
     week5Sort: string;
    readonly week1Hiscount: number;
    readonly week2Hiscount: number;
    readonly week3Hiscount: number;
    readonly week4Hiscount: number;
    readonly week5Hiscount: number;
    readonly isUpdated: boolean;
    comments: string;
    readonly spanKpi?: number;
    readonly spanMetric?: number;
    readonly isMetricRed: boolean;
    readonly kpiIndex?: number;
    readonly history: IPropKpiHistory[];
    readonly displayType: boolean;
    readonly isChatForKpi: boolean;
    readonly isEditable: boolean;
    readonly minRange: string;
    readonly maxRange: string;
    readonly interval: string;
    readonly uptimeTarget: string;
     serviceUptime: string;
    readonly uom: string;
    readonly envId: string;
    readonly qoSTarget: string;
     qoS: string;
    readonly validationExpr: string;
    readonly entrySource: boolean;
    readonly perfPopupGrpId: number;
    readonly customerSla: string;
    readonly lsi: string;
    readonly config: number;
    readonly subEnv: number;
    readonly reachSort: number;
    readonly iscmtRed: boolean;
    readonly isvalRed: boolean;
    color: string;
    qoS_Target: string;
    index: number;
    week5ToolTip: string;
    cmtcolor: string;
    valuecolor: string;
    WeekDate: string;
}


export type IViewDetails = {
    readonly viewId: string;
    readonly serviceId: string;
    viewName: string;
     coloringLogicId: number;
    queryForFixedCol: string;
    readonly queryForVal: string;
    readonly totalsLabel: string;
    val1Label: string;
    readonly val2Label: string;
    readonly source: string;
    readonly colorSchemeId: string;
    readonly kustoDBName: string;
    readonly mdmMetricName: string;
    readonly server: string;
    readonly updatedBy: string;
    readonly updatedOn: string;
    readonly isActive: string;
    readonly isReachability: string;
    readonly cellColoringLogic: string;
    readonly noOfDecimalId: string;
    readonly val1Cap: string;
    readonly val2Cap: string;
    readonly volumeDisplayType: string;
    readonly isMSERView: string;
    readonly mserViewUpdatedBy: string;
    readonly mserViewUpdatedDate: string;
    readonly isMonthlyAvailability: string;
    readonly monthlyAvailabilityUpdatedBy: string;
    readonly monthlyAvailabilityUpdatedDate: string;
    readonly queryForDrillDown: string;
    readonly toEmailList: string;
    readonly ccEmailList: string;
    readonly volumeType: string;
    readonly authenticationType: string;
    readonly IsMSERPresentationView: string;
    readonly MserPresentationViewUpdatedBy: string;
    readonly MserPresentationViewUpdatedDate: string;
    readonly IsMonthlyAvailability_Qos: string;
    readonly MonthlyAvailabilityQosUpdatedBy: string;
    readonly MonthlyAvailabilityQosUpdatedDate: string;
    RenameViewLabel: string;
    TSG: string;
};
export type Pickytpe = {
    id: number;
    name: string;
}
export type IViewConfigColorSchemes = {
    colorSchemeId: number;
    name: string;
}
export type IFleetcolorSchemes= {
    Rangevalue: number;
    Colour: string;
}
export type IViewConfigColorLogics = {
    colorLogicId: number;
    colorLogicText: string;
}
export type IViewConfigNoofDecimals = {
    NoOfDecimalId: number;
    NoOfDecimalText: string;
}


export type IAllViewDetails = {
    readonly viewId: any;
    readonly serviceId: number;
    viewName: string;
    fixedColQuery: string;
    readonly valQuery: string;
    readonly colorSchemeId: number;
    readonly totalsLabel: string;
    val1Label: string;
    readonly val2Label: string;
    readonly val1Cap: string;
    readonly val2Cap: string;
    readonly source: string;
    readonly hasVal2: Boolean;
    readonly kustoDbName: string;
    readonly mdmMetricName: string;
    readonly kustoServer: string;
    readonly updatedBy: string;
    readonly updatedOn: string;
    readonly isReachability?: Boolean;
    readonly isActive: string;
    readonly serviceName: string;
    readonly coloringLogicId: number;
    readonly colorLogicText: string;
    readonly noOfDecimalId: number;
    readonly noOfDecimalText: string;
    readonly volumeType: string;
    readonly isMserView: boolean;
    readonly mserViewUpdatedBy: string;
    readonly mserViewUpdatedDate: string;
    readonly isMonthlyAvailability: boolean;
    readonly monthlyAvailabilityUpdatedBy: string;
    readonly monthlyAvailabilityUpdatedDate: string;
    readonly drillDownQuery: string;
    readonly toEmailList: string;
    readonly ccEmailList: string;
    readonly authenticationType: string;
    readonly isMserPresentationView: boolean;
    readonly isMonthlyAvailability_Qos: string;
    RenameViewLabel: string;
};

export type IMultiViewDetails = {
    readonly viewId: any;
    mvdailydataPresent: boolean;
    mvcommentPresent: boolean;
    mvdclength: any;
    mvVolumeLabel: any;
    readonly serviceId: number;
    mvuniqueDateHeaders: UniqueDateHeaders[];
    mvuniqueFixedHeaderColumns: UniqueFixedHeaderColumns[];
    viewName: string;
    fixedColQuery: string;
    readonly valQuery: string;
    readonly colorSchemeId: number;
    readonly totalsLabel: string;
    val1Label: string;
    readonly val2Label: string;
    readonly val1Cap: string;
    readonly val2Cap: string;
    readonly source: string;
    readonly hasVal2: Boolean;
    readonly kustoDbName: string;
    readonly mdmMetricName: string;
    readonly kustoServer: string;
    readonly updatedBy: string;
    readonly updatedOn: string;
    readonly isReachability?: Boolean;
    readonly isActive: string;
    readonly serviceName: string;
    readonly coloringLogicId: number;
    readonly colorLogicText: string;
    readonly noOfDecimalId: number;
    readonly noOfDecimalText: string;
    readonly volumeType: string;
    readonly isMserView: boolean;
    readonly mserViewUpdatedBy: string;
    readonly mserViewUpdatedDate: string;
    readonly isMonthlyAvailability: boolean;
    readonly monthlyAvailabilityUpdatedBy: string;
    readonly monthlyAvailabilityUpdatedDate: string;
    readonly drillDownQuery: string;
    readonly toEmailList: string;
    readonly ccEmailList: string;
    readonly authenticationType: string;
    readonly isMserPresentationView: boolean;
    readonly isMonthlyAvailability_Qos: string;
    RenameViewLabel: string;
};

export type IDailyData = {
    key: string;
    readonly fixedColumns: Column[];
    volume: any;
    failureVolume: any;
    sortVolume: number;
     sortFailureVolume: number;
    avg: HMValue;
    readonly metricList: HmMetric[];
    readonly isTotalVolume: boolean;
    readonly isAvgUptime: boolean;
    readonly highlighterColorType: string;
    readonly volumeDisplayType: string;
    readonly isDailyVolume: boolean;
    readonly isOutOfSlaUptime: boolean;
    readonly isRedRow: boolean;
    readonly isYellowRow: boolean;
    readonly queryForDrillDown: string;
    readonly kustoCluster: string;
    readonly kustoDb: string;
    readonly toEmailList: string;
    readonly ccEmailList: string;
    readonly isFailureVolume: boolean;
    viewId: any;
};

export type Column = {
    readonly key: string;
    readonly value: string;
};

export type IColorScheme = {
    readonly description: string;
    readonly colorType: string;
    viewId: any;
};

export type IMSERAdditionalInfo = {
    readonly additionInfoId: number;
    readonly viewId: number;
    readonly dataCenter: string;
    readonly impact: string;
    readonly effectiveDate?: Date;
    readonly rootCause: string;
    readonly mitigation: string;
    readonly learningsAndRepairItems: string;
    readonly updatedBy: string;
    readonly updatedDate?: Date;
    readonly keyDate?: string;
};

export type UniqueDateHeaders = {
    readonly key: string;
    readonly value: string;
};

export type UniqueFixedHeaderColumns = {
    readonly key: string;
    readonly value: string;
};

export interface PropPullRequestDetails {
    pullRequestId: number;
    codeReviewId: number;
    title: string;
    cloud: string;
    status: string;
    creationDate: Date;
    sourceRefName: string;
    targetRefName: string;
    createdBy: string;
    mergeStatus: string;
    releaseDate: string;
    completedDate: string;
    approvedDate: string;
    approvedBy: string;
    rejectedBy: string;
    commentDate: string;
    isOnboardingRequired: boolean;
    comments: string;
    slaStatus: string;
}

export interface PropDeploymentBuildDetails {
    apiVersion: string;    
    schemaVersion: string;
    startDate: Date;    
    endDate: Date;    
    slaStatus: string;
}

export interface PropStackOverFlowDetails {
    questionId: number;
    isAnswered: boolean;
    answerCount: number;
    createdDate: Date;
    questionUrl: string;
    questionTitle: string;
    questionCreatedBy: string;
    inSLA: boolean;
    outOfSLA: boolean;
}

export interface PropOneDashHistoricalData {
    organization: string;
    serviceGroup: string;
    serviceId: string;
    serviceName: string;
    kpi: string;
    reportDate: Date;
    securitySeverity: string;
    outOfSla: number;
    approachingSla: number;
    kpiId: string;
    inSla: number;
    updatedDate: Date;
}

export type HMValue = {
    percentage: string;
    readonly percentageField: string;
    value1: string;
    value2: string;
     colorType: string;
    readonly displayDecimals: number;
    readonly isRedRow: boolean;
    readonly isYellowRow: boolean;
}

export type IHmMetricReposnse = {
    readonly rowCount: number
}

export type HmMetric = {
    readonly dateKey: Date;
    SortdateKey: any;
    readonly value: HMValue;
    updatedValue1: string;
    updatedValue2: string;
    readonly isVisible: boolean;
    readonly volume: number;
    readonly id: number;
    readonly comment: string;
    incident: string;
    readonly author: string;
    lastUpdated: string;
    readonly commentId: number;
    readonly success: number;
    readonly successInSla: number;
    readonly failureVolume: number;
    tooltip: string;
    IsshowBulk: boolean;
    hasMserComment: boolean;
    status: string;
    problemStatement: string;
    rootCause: string;
    owningTeam: string;
    impact: string;
    updatedColorRules: any;
}
export type IBulkViewDetails = {
    readonly Id: number;
    readonly Name: string;
}

export type IComments = {
    readonly comment: string;
    readonly incident: string;
    readonly commentId: string;
    readonly keyDate: string;
     lastUpdated: string;
    lastUpdatedDateval: Date;
    viewId: any;
    status: string;
    problemStatement: string;
    rootCause: string;
    owningTeam: string;
    impact: string;
}

export type PropFleetutilizationHMList = {
    readonly objPropFleetutilizationHM: PropFleetutilizationHM[];
    readonly objDateLabels: DateLabels;
}

export type PropFleetutilizationHM = {
    readonly serviceName: string;
    readonly roleName: string;
    readonly cloud: string;
    readonly trafficGroup: string;
    readonly dataCentername: string;
    requestVolume: string;
    readonly fleetSize: string;
    readonly fleetCores: string;
    readonly recommendedFleetSize: string;
    readonly fleetUtlization: number;
    readonly dailyFleetHM: DailyFleet[];
    readonly color: string;
    readonly dateLbls: DateLabels[];
    readonly minFleetSize: string;
}

export type DailyFleet = {
    readonly date: Date;
    readonly daliyFleetHMValue: number;
    readonly color: string;
}

export type DateLabels = {
    readonly startdt: Date;
    readonly enddt: Date;
    readonly startwd: Date;
    readonly endwd: Date;
}

export type LookupData = {
    readonly value: string;
}

export type IServiceHealthState = {
    readonly isLoading: boolean;
    readonly dailyData: IDailyData[];
    readonly mvdailyData: IDailyData[];
    readonly uniqueDateHeaders: UniqueDateHeaders[];
    readonly uniqueFixedHeaderColumns: UniqueFixedHeaderColumns[];
    readonly datacentersList: [];
    readonly mvdatacentersList: [];
    readonly additionalInfoData: IMSERAdditionalInfo[];
    viewdetailsdata: IViewDetails[];
    readonly SelectedView: IAllViewDetails[];
    readonly ReachabilitySelectedView: IAllViewDetails[];
    readonly comments: IComments[];
    readonly colorScheme: IColorScheme[];
    readonly Multipleviewdetailsdata: any;
    readonly mvcomments: IComments[];
    readonly mvcolorScheme: IColorScheme[];
    readonly checkboxValue: boolean;
    readonly selectedDropdownOption: IDropdownOption;
    readonly selectedViewDropdownOption: IDropdownOption;
    readonly multipleviewSelectedOption: IDropdownOption;
    readonly dailyViewList: IDropdownOption[];
    readonly fleetTenantList: IDropdownOption[];
    readonly selectedTenant: IDropdownOption;
    readonly fleetRoleList: IDropdownOption[];
    readonly selectedRole: IDropdownOption;
    readonly fleetCloudList: IDropdownOption[];
    readonly selectedCloud: IDropdownOption;
    readonly fleetTrafficGroupList: IDropdownOption[];
    readonly selectedTrafficGroup: IDropdownOption;
    readonly fleetheatmapData: PropFleetutilizationHM[];
    readonly fleetheatmapDataOriginal: PropFleetutilizationHM[];
    readonly Datelabels: DateLabels;
    IsfleetDataloaded: boolean;
    dailyFleetDataFilter: IDailyFleetFilterApplied;
    readonly fleetStartDate: Date;
    readonly fleetEndDate: Date;
    fleetMaxDate: Date; 
    readonly startDate: Date;
    readonly endDate: Date;
    readonly BulkViewData: IBulkViewDetails[];
    readonly IncidentCheckmetricdata: HmMetric;
    previousService: IServiceNavInfo;
    currentService: IServiceNavInfo;
    nextService: IServiceNavInfo;
    dailyDataFilter: IDailyFilterApplied;
    readonly selectedServiceId: number;
    IsdataLoaded: boolean;
    DailyDataFailure: boolean;
    MVIsdataLoaded: boolean;
    MVDailyDataFailure: boolean;
    IsReachabilitydataLoaded: boolean;
     weeklyStartDate: Date;
     weeklyDefaultDate: Date;
    readonly weeklyData: IWeeklyReport[];
    weekDetailsData: IWeekDetails;
    draftsData: IPropDraft;
    readonly ConfigValues: IPropCalender[];
    readonly Datefreshed: string;
    readonly chkMSER: boolean;
    readonly chkMonltyAvailability: boolean;
    readonly chkRED: boolean;
    readonly chkYELLOW: boolean;
    monthlyStartDate: Date;
    monthlyDefaultDate: Date;
    readonly monthlyData: IMonthlyReport[];
    readonly MonthlyheaderArr: [];
    readonly MonthlyKPiArr: [];
    IsMonthlyDataLoaded: boolean;
    BGMdata: PropBgm[];
    ExternalLinks: PropExternalLinks[];
    commentsData: PropKpiComments[];
    LoginuserId: string;  
    Performancedata: [];
    IsperformanceDataloaded: boolean;
    readonly chkBulkEdit: boolean;
    IsWeeklyDataloaded: boolean,
    IsWeeklyDataDisabled: boolean,
    readonly chkHistory: boolean;
    ConfigurationView: IViewDetails;
    ColorLogicsData: [];
    ColorshemesData: [];
    noofdecimalsdata: [];
    colorSchemeValue: Pickytpe[];
    coloringLogicValue: Pickytpe[];
    noOfDecimalValue: Pickytpe[];
    volumeDisplayTypeValue: Pickytpe[];
    authenticationTypeValue: Pickytpe[];
    Disableviewconfigbutton: boolean;
    Newview: boolean;
    checkedReachability: boolean;
    AllviewsData: [];
    allReachabilityviewsData: [];
    ReachabilityData: [];
    readonly ReachabilityuniqueDateHeaders: UniqueDateHeaders[];
    readonly ReachabilityuniqueFixedHeaderColumns: UniqueFixedHeaderColumns[];
    readonly Reachabilitycomments: IComments[];
    readonly ReachabilitycolorScheme: IColorScheme[];
    activeDailyDataTab: string;
    showMSERComments: boolean;
    URLParam: string;
    reachabilityServices: IDropdownOption[];
    readonly selectedServiceDropdownOption: IDropdownOption;
    checkedFilterYellowDailyData: boolean;
    checkedFilterRedDailyData: boolean;
    checkedFilterYellowFleetData: boolean;
    checkedFilterRedFleetData: boolean;
    readonly copyurlrechabilityserviceid: string;
    readonly copyurlrechabilityviewid: number;
    readonly IsfromURLNavigation: boolean;
    readonly IsshowDrilldownIcon: boolean;
    readonly IsshowEmail: boolean;
    readonly drilldown: boolean;
    readonly copyurlHeatMapServiceid: string;
    readonly copyurlHeatMapViewid: number;
    readonly dcMaxLength: number;
    readonly mvdcMaxLength: number;
    readonly serviceGroups: [];
    readonly selectedServicegroupval: WeeklyDropdownOption;
    readonly services: [];
    readonly selectedServiceval: WeeklyDropdownOption;
    readonly metricGroups: [];
    readonly selectedMetricgroupval: WeeklyDropdownOption;
    readonly metricsRaw: [];
    readonly metricsRawService: [];
    readonly activeMetrics: [];
    readonly selectedMetric: any;
    readonly environments: [];
    readonly onboardNewMetric: boolean;
    readonly selectedEnvironment: WeeklyDropdownOption;
    readonly datasource: [];
    readonly authenticationTypes: [];
    readonly selectedDatasource: WeeklyDropdownOption;
    readonly metricDataSourceDetails: [];
    readonly metricDataSourceDetailsRaw: [];
    serverName: string;
    newMetricName: string;
    newMetricGroupName: string;
    database: string ;
    mdmMetricName: string ;
    authenticationType: string ;
    readonly target: WeeklyDropdownOption ;
    notes: string ;
    queryOrUrl: string;
    readonly targetTypes: [];
    readonly liKPIValueKeys: [];
    readonly fleetDatacolorscheme: IFleetcolorSchemes[];
    readonly ViewMetricDataSourceOpen: boolean;
    readonly AddMetricDataSourceOpen: boolean;
    readonly MultipleViewSelected: boolean;
    TSGValue: string;
    HeatmapInactive: boolean;
    DateRefreshedByDataLoader: [];
};

export type IDropdownOption = {
    value: number;
    label: string;
};

export type IViewDropdownOption = {
     viewId: number;
     viewName: string;
};

export type WeeklyDropdownOption = {
    name: string;
    value: number;
};

export type PropMetricType = {
    metricDisplayName: string;
    MetricId: number;
    metricGroupId: number;
    envId: number;
};

export type PropServiceDetail = {
    ServiceRingId: number;
    ServiceOwners_Email: string;
    ServiceGroupId: number;
    GroupName: string;
};

export type PropMetricDataSource = {
    serviceId: number;
    metricGroupId: number;
    metricGroupDisplayName: string;
    metricId: number;
    metricDisplayName: string;
    environmentId: number;
    environmentName: string;
    dataSourceId: number;
    dataSourceName: string;
    queryOrUrl: string;
    authenicationType: string;
    kustoClusterName: string;
    kustoDbName: string;
    comments: string;
    notes: string;
    updatedBy: string;
    updateOn: Date;
    isAutomated: string;
    dataConnectorName: string;
    targetId: number;
    newMetricGroupName: string;
    newMetricName: string;
    mdmMetricName: string;
};

export type PropEnvironment = {
    key: number;
    value: string;
};

export type PropDataSource = {
    SourceName: string;
    Id: number;
};

export type PropAuthenticationType = {
    Id: number;
    AuthenticationType: string;
}

export type PropTarget = {
    targetId: number;
    targetName: string;
};

export type IDailyFilterApplied = {
    startDate: Date;
    endDate: Date;
    viewId: number;
};

export type IDailyFleetFilterApplied = {
    tenant: string;
    cloud: string;
    trafficGroup: string;
    role: string;
};

export type IServiceNavInfo = {
    serviceId: number;
    serviceName: string;
};

export type IServices = {
    key: number;
    value: string;
    isReachability: boolean
};

export interface IMonthlyReport {
    readonly ServiceId: number;
    readonly serviceName: string;
    readonly viewName: string;
    readonly targetName: string;
    readonly viewId: string;
    readonly spanKpi?: number;
    readonly spanMetric?: number;
    readonly kpiIndex?: number;
    readonly liKPIValues: Record<string, object>;
    readonly LiUpdatedKPIs: Record<string, boolean>;
    serviceId: number;
    MonthStartDate: string;
    isReachability: boolean;
}

const _namespace = 'servicehealth';

export const ActionType = Object.freeze<IActionType>({
    REQUEST: `${_namespace}/fetch`,
    RECEIVE: `${_namespace}/receive`,
    RESET_STATE: `${_namespace}/resetState`,
    VIEW_REQUEST: `${_namespace}/viewFetch`,
    VIEW_RECEIVE: `${_namespace}/viewReceive`,
    VIEW_RESET_STATE: `${_namespace}/viewResetState`,
    DROPDOWN_SELECT: `${_namespace}/dropdownSelect`,
    VIEW_DROPDOWN_SELECT: `${_namespace}/viewDropdownSelect`,
    CHECK_SAMPLE_BOX: `${_namespace}/checkSampleBox`,
    START_DATE_CHANGE: `${_namespace}/startDateChange`,
    WEEKLY_START_DATE_CHANGE: `${_namespace}/weeklystartDateChange`,
    MONTHLY_START_DATE_CHANGE: `${_namespace}/monthlystartDateChange`,
    END_DATE_CHANGE: `${_namespace}/endDateChange`,
    DAILYDATA_UPDATE_SUCCESS: `${_namespace}/success`,
    DAILYDATA_UPDATE_FAIL: `${_namespace}/fail`,
    DAILYDATA_ADD_SUCCESS: `${_namespace}/success`,
    DAILYDATA_ADD_FAIL: `${_namespace}/fail`,
    BULKVIEWS_RECEIVE: `${_namespace}/bulkviewreceive`,
    BULKVIEWS_FAIL: `${_namespace}/bulkviewfail`,
    COMMENT_INCIDENT_CHECK: `${_namespace}/cmincidentcheck`,
    BULK_UPDATE_COMMENTS_SUCCESS: `${_namespace}/bulksuccess`,
    DAILY_FILTER_APPLIED: `${_namespace}/filterApplied`,
    CURRENT_SERVICE_NAVIGATOR: `${_namespace}/currentService`,
    PREVIOUS_SERVICE_NAVIGATOR: `${_namespace}/previousService`,
    NEXT_SERVICE_NAVIGATOR: `${_namespace}/nextService`,
    DATA_REFRESH: `${_namespace}/datarefresh`,
    FLEET_DROPDOWN_DATA_FETCH: `${_namespace}/fleetdropdowndatafetch`,
    FLEET_DROPDOWN_DATA_RECEIVE: `${_namespace}/fleetdropdowndatareceive`,
    FLEET_DATA_FETCH: `${_namespace}/fleetdatafetch`,
    FLEET_DATA_RECEIVE: `${_namespace}/fleetdatareceive`,
    DAILY_FLEET_FILTER_APPLIED: `${_namespace}/fleetfilterapplied`,
    FLEET_START_DATE_CHANGE: `${_namespace}/fleetstartDateChange`,
    FLEET_END_DATE_CHANGE: `${_namespace}/fleetendDateChange`,
    SET_FLEETDATALOADED_TRUE: `${_namespace}/fleetdataloadedTrue`,
    TENANT_DROPDOWN_SELECT: `${_namespace}/tenantDropdownSelect`,
    ROLE_DROPDOWN_SELECT: `${_namespace}/roleDropdownSelect`,
    CLOUD_DROPDOWN_SELECT: `${_namespace}/cloudDropdownSelect`,
    TRAFFICGROUP_DROPDOWN_SELECT: `${_namespace}/trafficgroupDropdownSelect`,
    WEEKLYDATA_RECEIVE: `${_namespace}/weeklydatareceive`,
    WEEKLYDATA_FETCH: `${_namespace}/weeklydatafetech`,
    WEEKLY_DETAILS_DATA_RECEIVE: `${_namespace}/weeklydetailsdatareceive`,
    DRAFTS_DATA_RECEIVE: `${_namespace}/draftsdatareceive`,
    CONFIGVALUES_RECEIVE: `${_namespace}/configvaluesreceive`,
    WEEKDATA_UPDATE_FAIL: `${_namespace}/weekdataupdatefail`,
    SET_LOADING_TRUE: `${_namespace}/setloadingtrue`,
    SET_LOADING_FALSE: `${_namespace}/setloadingfalse`,
    SET_REFRESHDATE_SUCCESS: `${_namespace}/setrefreshdatesuccess`,
    CHECK_SAVE_MSER: `${_namespace}/checkSaveMSER`,
    CHECK_SAVE_MONTHLY_AVAILABILITY: `${_namespace}/checkSaveMonthlyAvailability`,
    CHECK_MSER: `${_namespace}/checkMSER`,
    CHECK_MONTHLY_AVAILABILITY: `${_namespace}/checkMonthlyAvailability`,
    CHECK_RED: `${_namespace}/checkRED`,
    CHECK_YELLOW: `${_namespace}/checkYELLOW`,
    MONTHLYDATA_RECEIVE: `${_namespace}/monthlydatareceive`,
    MONTHLYDATA_FETCH: `${_namespace}/monthlydatafetech`,
    BGM_RECEIVE: `${_namespace}/bgmreceive`,
    EXTERNALLINKS_RECEIVE: `${_namespace}/externallinksreceive`,
    COMMENTS_RECEIVE: `${_namespace}/commentsreceive`,
    PERFORMANCE_RECEIVEDATA: `${_namespace}/performancereceive`,
    PERFORMANCE_REQUEST: `${_namespace}/performancerequest`,
    WEEKLY_PERFORMANCE_RECEIVE: `${_namespace}/weeklyperformancereceive`,
    CHECK_BULK_EDIT: `${_namespace}/check?BulkEdit`,
    VIEW_RECEIVE_FAIL: `${_namespace}/viewReceivefail`,
    WEEKLY_DETAILS_DATA_RECEIVE_FAIL: `${_namespace}/weeklydetailsdatareceivefail`,
    SET_REFRESHDATE_FAIL: `${_namespace}/setrefreshdatefail`,
    COMMENTS_RECEIVE_FAIL: `${_namespace}/commentsreceivefail`,
    DRAFTS_DATA_RECEIVE_FAIL: `${_namespace}/draftsdatareceivefail`,
    CONFIGVALUES_RECEIVE_FAIL: `${_namespace}/configvaluesreceivefail`,
    WEEKLYDATA_RECEIVE_FAIL: `${_namespace}/weeklydatareceivefail`,
    BGM_RECEIVE_FAIL: `${_namespace}/bgmreceivefail`,
    EXTERNALLINKS_RECEIVE_FAIL: `${_namespace}/externallinksreceivefail`,
    WEEKLY_PERFORMANCE_RECEIVE_FAIL: `${_namespace}/weeklyperformancereceivefail`,
    DAILYDATAFAIL: `${_namespace}/dailydatafail`,
    CHECK_HISTORY: `${_namespace}/checkHistory`,  
    VIEWCONFIGURATION_COLORLOGIC: `${_namespace}/viewconfiggurationcolorlogic`,
    VIEWCONFIGURATION_COLORSCHEMES: `${_namespace}/viewconfiggurationcolorschemes`,
    VIEWCONFIGURATION_NOOFDECIMALS: `${_namespace}/viewconfiggurationnoofdecimals`,
    VIEWCONFIGURATION_VALUES_CHANGE: `${_namespace}/viewconfiggurationvalueschange`,
    COLOR_SCHEME_SELECT: `${_namespace}/colorschemeselect`,
    COLORLOGIC_SELECT: `${_namespace}/colorlogicselect`,
    NOOFDECIMALS_SELECT: `${_namespace}/noofdecimalsselect`,
    VOLUMEDISPLAY_SELECT: `${_namespace}/volumedisplayselect`,
    AUTHENTOCATIONTYPE_SELECT: `${_namespace}/authenticationtypeselect`,
    SET_DISABLE_VIEWCONFIG_BUTTON: `${_namespace}/setdisableviewconfigbutton`,
    CREATE_NEW_VIEW_CHANGE: `${_namespace}/createnewviewchange`,
    CHECK__REACHABILITY_CHANGE: `${_namespace}/reachabilitychange`,
    SET_DAILYDAT_FILTER_VIEWID: `${_namespace}/setdailydatafilterviewid`,
    SET_VIEW_DROPDOWN_BASEDON_TAB_SELECTION: `${_namespace}/setviewdropdownbasedontabselection`,
    SET_DAILYDATA_ACTIVETAB: `${_namespace}/setdailydataactivetab`,
    SET_MSERCOMMENTS: `${_namespace}/setmsercomments`,
    FETCH_REACHABILITY_SERVICES: `${_namespace}/fetchReachabilityServices`,
    REQUEST_REACHABILITY_SERVICES: `${_namespace}/requestReachabilityServices`,
    REACHABILITY_SERVICE_DROPDOWN_SELECT: `${_namespace}/reachabilityServiceDropdownSelect`,
    REACHABILITY_SERVICE_RECEIVE_FAIL: `${_namespace}/reachabilityServiceReceiveFail`,
    DAILY_FILTER_YELLOW: `${_namespace}/dailyfilteryellow`,
    DAILY_FILTER_RED: `${_namespace}/dailyfilterred`,
    FLEET_FILTER_YELLOW: `${_namespace}/fleetfilteryellow`,
    FLEET_FILTER_RED: `${_namespace}/fleetfilterred`,
    REACHABILITY_SERVICE_VIEW_FAIL: `${_namespace}/reachabilityServiceViewFail`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    SETURL_PARAM_EMPTY: `${_namespace}/seturlparamempty`,
    SET_DRILLDOWN_VALUE: `${_namespace}/setdrilldownvalue`,
    SET_NOVIEWS: `${_namespace}/setnoviews`,
    GET_WEEKLYSELFSERVE_REQUEST: `${_namespace}/getweeklyselfserverequest`,
    GET_SERVICEGROUPS_SUCCESS: `${_namespace}/getservicegroupsuccess`,
    GET_SERVICEGROUPS_FAIL: `${_namespace}/getservicegroupfail`,
    GET_SERVICELISTS_SUCCESS:`${_namespace}/getservicelistsuccess`,
    GET_SERVICELISTS_FAIL: `${_namespace}/getservicefail`,
    SERVICEGROUP_CHANGE: '${_namespace}/servicegroupchange',
    SERVICES_CHANGE: '${_namespace}/serviceschange',
    METRICGROUP_CHANGE: '${_namespace}/metricgroupchange',
    METRIC_CHANGE: '${_namespace}/metricchange',
    ENVIRONMENT_CHANGE: '${_namespace}/environmentchange',
    DATASOURCE_CHANGE: '${_namespace}/datasourcechange',
    GET_METRICGROUPS_SUCCESS: `${_namespace}/getmetricgroupsuccess`,
    GET_METRICGROUPS_FAIL: `${_namespace}/getmetricgroupfail`,
    GET_ENVIRONMENT_SUCCESS: `${_namespace}/getenvironmentsuccess`,
    GET_ENVIRONMENT_FAIL: `${_namespace}/getenvironmentfail`,
    GET_DATASOURCE_SUCCESS: `${_namespace}/getdatasourcesuccess`,
    GET_AUTHENTICATIONTYPE_SUCCESS: `${_namespace}/getauthenticationtypesuccess`,
    GET_DATASOURCE_FAIL: `${_namespace}/getdatasourcefail`,
    GET_AUTHENTICATIONTYPE_FAIL: `${_namespace}/getauthenticationtypefail`,
    GET_METRICDATASOURCE_REQUEST: `${_namespace}/getdmetricatasourcerequest`,
    GET_METRICDATASOURCE_SUCCESS: `${_namespace}/getmetricdatasourcesuccess`,
    GET_METRICDATASOURCE_FAIL: `${_namespace}/getmetricdatasourcefail`,
    FILTER_METRICDATASOURCE_SUCCESS: `${_namespace}/filtermetricdatasourcesuccess`,
    FILTER_METRICDATASOURCE_REQUEST: `${_namespace}/filtermetricdatasourcerequest`,
    METRICDATASOURCE_METRICNAME_ADD: `${_namespace}/metricdatasourcemetricnameadd`,
    METRICDATASOURCE_METRICGROUP_ADD: `${_namespace}/metricdatasourcemetricgroupadd`,
    METRICDATASOURCE_SERVER_CHANGE: `${_namespace}/metricdatasourceserverchange`,
    METRICDATASOURCE_DATABASE_CHANGE: `${_namespace}/metricdatasourcedatabasechange`,
    MDM_METRICNAME_CHANGE: `${_namespace}/mdm_metricname_change`,
    METRICDATASOURCE_ONBOARD_CHANGE: `${_namespace}/metricdatasourceonboardchange`,
    METRICDATASOURCE_AUTHENTICATIONTYPE_CHANGE: `${_namespace}/metricdatasourceauthenticationtypechange`,
    METRICDATASOURCE_QUERYORURL_CHANGE: `${_namespace}/metricdatasourcequeryorurlchange`,
    METRICDATASOURCE_TARGET_CHANGE: `${_namespace}/metricdatasourcetargetchange`,
    METRICDATASOURCE_NOTES_CHANGE: `${_namespace}/metricdatasourcenoteschange`,
    CLEAR_METRICDATASOURCE_SECTION: `${_namespace}/clearmetricdatasourcesection`,
    GET_TARGETTYPE_SUCCESS: `${_namespace}/gettargettypesuccess`,
    GET_TARGETTYPE_FAIL: `${_namespace}/gettargettypefail`,
    ENABLE_EDITORADD_METRICDATASOURCE: `${_namespace}/enableeditoraddmetricdatasource`,
    SET_SELECTEDSERVICEID_EMPTY: `${_namespace}/setselectedserviceIdempty`,
    RESET_FILTER: `${_namespace}/resetfilter`,
    COLORSCHEME_SUCCESS: `${_namespace}/colourschemesuccess`,
    METRICDATASOURCE_VIEW_ENABLE: `${_namespace}/metricdatasourceviewenable`,
    METRICDATASOURCE_VIEW_DISABLE: `${_namespace}/metricdatasourceviewdisable`,
    METRICDATASOURCE_ADD_ENABLE: `${_namespace}/metricdatasourceaddenable`,
    METRICDATASOURCE_ADD_DISABLE: `${_namespace}/metricdatasourceadddisable`,
    HEATMAP_DAILYDATA_FAILURE: `${_namespace}/heatmapdailydatafailure`,
    SET_DAILYDATA_UPDATELABELVALUES: `${_namespace}/setdailydataupdatelabelvalues`,
    SET_VIEWNAME_UPDATEVALUE: `${_namespace}/setviewnameupdatevalue`,
    MULTIPLEVIEW_SELECT_CHANGE: `${_namespace}/multipleviewselectchange`,
    MULTIPLEVIEW_DROPDOWN_SELECT: `${_namespace}/multipleviewdropdownselectchange`,
    MULTIVIEW_RECEIVE: `${_namespace}/multipleviewdatareceive`,
    MULTIVIEWDAILYDATAFAIL: `${_namespace}/multipleviewdailydatafail`,
    MULTIPLEVIEW_DATA_REFRESH: `${_namespace}/multipleviewdatarefresh`,
    MULTIVIEW_COLORSCHEME_RECEIVE: `${_namespace}/multipleviewcolorschemedatareceive`
});


