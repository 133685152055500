import { actionCreators } from './action';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IPropConfigurationState } from './types';

const initialState = Object.freeze<IPropConfigurationState>({
    isLoading: false,
    services: [],
    tenants: [],
    tenantsalreadyOnboarded: [],
    selectedService: {serviceId: null, serviceName: null},
    servicesdata: [],
    service_ids: [],
    currentserviceId: null,
    selectedTenant: { tenantId: null, tenantname: null },
    serviceSelected: false,
    isEnabled: false,
    tenantshide: false,
    UpdatedBy: null,
    tenantsWithOnboarded: false,
    showMessage: false,
    addNewService: false,
    isheatMapValidated: false,
    showRBLMessage: false,
    showRBLOnboardedMessage: false,
    isRBLValidated: false,
    displayCCLSuccessMessage: false,
    isProd: null,
    questionarecclSelfConfiguration: null,
    questionarehighlevelInfo: null,
    questionareOncall: null,
    questionarecclFiltering: null,
    questionarecommDL: null,
    questionarecclPM: null,
    questionarecustomerImpacting: null,
    questionareGPMName: null,
    questionareIsTenantOrSubscriptionIdBased: null,
    questionarePartners: null,
    questionareComments: null
});

export const reducer = (
    state: IPropConfigurationState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true

            };
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false
            };
        case ActionType.UPDATE_LOGGEDIN_USER:
            return {
                ...state,
                UpdatedBy: action.UpdatedBy
            };    
        case ActionType.GET_SERVICEGROUPS_REQUEST:
            return {
                ...state,
                servicesdata: []
            };
        case ActionType.GET_SERVICEGROUPS_SUCCESS:
            return {
                ...state,
                servicesdata: action.servicesdata
            };
        case ActionType.GET_SERVICEGROUPS_FAIL:
            return {
                ...state,
                servicesdata: [],
            };
        case ActionType.GET_CONFIGURATION_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.GET_CONFIGURATION_SUCCESS:  // === for CONFIGURATION Data success ===============//
            return {
                ...state,
                services: action.services,
                selectedService: action.selectedService,
                tenants: action.tenants,
                tenantsalreadyOnboarded: action.tenantsalreadyOnboarded,
                serviceSelected: action.serviceSelected,
                isLoading: false
            };
        case ActionType.GET_CONFIGURATION_FAILURE:  // === for CONFIGURATION Data failure ===============//
            return {
                ...state,
                services: [],
                selectedService: {},
                tenants: [],
                tenantsalreadyOnboarded: [],
                isLoading: false
            };
        case ActionType.GET_CONFIGURATIONTENANT_SUCCESS:  // === for CONFIGURATION Tenant Data success ===============//
            return {
                ...state,
                tenants: action.tenants,
                tenantsalreadyOnboarded: action.tenantsalreadyOnboarded,
                tenantshide: action.tenantshide,
                tenantsWithOnboarded: action.tenantsWithOnboarded
            };
        case ActionType.UPDATE_CONFIGURATIONSERVICE_CHANGE: 
            return {
                ...state,
                serviceSelected: action.serviceSelected,
                isheatMapValidated: action.isheatMapValidated,
                tenants: action.tenants,
                tenantsalreadyOnboarded: action.tenantsalreadyOnboarded,
                isEnabled: action.isEnabled,
                showMessage: action.showMessage,
                addNewService: action.addNewService,
                tenantshide: action.tenantshide,
                isRBLValidated: action.isRBLValidated,
                showRBLMessage: action.showRBLMessage,
                showRBLOnboardedMessage: action.showRBLOnboardedMessage,
                tenantsWithOnboarded: action.tenantsWithOnboarded,
                displayCCLSuccessMessage: action.displayCCLSuccessMessage,
                questionarecclSelfConfiguration: action.questionarecclSelfConfiguration,
                questionarehighlevelInfo: action.questionarehighlevelInfo,
                questionareOncall: action.questionareOncall,
                questionarecclFiltering: action.questionarecclFiltering,
                questionarecommDL: action.questionarecommDL,
                questionarecclPM: action.questionarecclPM,
                questionarecustomerImpacting: action.questionarecustomerImpacting,
                service_ids: action.service_ids,
                currentserviceId: action.currentserviceId
            };
        case ActionType.HEATMAP_VALIDATE:
            return {
                ...state,
                addNewService: action.addNewService,
                showMessage: action.showMessage
            };
        case ActionType.RBL_VALIDATE:
            return {
                ...state,
                showRBLOnboardedMessage: action.showRBLOnboardedMessage,
                showRBLMessage: action.showRBLMessage
            };    
        case ActionType.SERVICE_SELECTED:
            return {
                ...state,
                selectedService: action.selectedService,
                selectedTenant: action.selectedTenant
            };
        case ActionType.TENANT_SELECTED:
            return {
                ...state,
                selectedTenant: action.selectedTenant,
                isLoading: true
            };       
        case ActionType.TENANT_CHANGED:
            return {
                ...state,
                questionarecclSelfConfiguration: action.questionarecclSelfConfiguration,
                isEnabled: action.isEnabled
            };   
        case ActionType.CONFIGURATION_QUESTIONAREHIGHLEVELINFO_CHANGE:
            return {
                ...state,
                questionarehighlevelInfo: action.questionarehighlevelInfo
            };   
        case ActionType.CONFIGURATION_QUESTIONAREONCALL_CHANGE:
            return {
                ...state,
                questionareOncall: action.questionareOncall
            };   
        case ActionType.CONFIGURATION_QUESTIONARECCLFILTERING_CHANGE:
            return {
                ...state,
                questionarecclFiltering: action.questionarecclFiltering
            };   
        case ActionType.CONFIGURATION_QUESTIONARECOMMDL_CHANGE:
            return {
                ...state,
                questionarecommDL: action.questionarecommDL
            };   
        case ActionType.CONFIGURATION_QUESTIONARECCLPM_CHANGE:
            return {
                ...state,
                questionarecclPM: action.questionarecclPM
            };   
        case ActionType.CONFIGURATION_QUESTIONARECUSTOMERIMPACTING_CHANGE:
            return {
                ...state,
                questionarecustomerImpacting: action.questionarecustomerImpacting
            };
        case ActionType.CONFIGURATION_QUESTIONAREGPMNAME_CHANGE:
            return {
                ...state,
                questionareGPMName: action.questionareGPMName
            };
        case ActionType.CONFIGURATION_QUESTIONAREISTENANTORSUBSCRIPTIONIDBASED_CHANGE:
            return {
                ...state,
                questionareIsTenantOrSubscriptionIdBased: action.questionareIsTenantOrSubscriptionIdBased
            }; 
        case ActionType.CONFIGURATION_COMMENTS_CHANGE:
            return {
                ...state,
                questionareComments: action.questionareComments
            }; 
        case ActionType.CONFIGURATION_PARTNERS_CHANGE:
            return {
                ...state,
                questionarePartners: action.questionarePartners
            };
        case ActionType.RESET_FILTERS_CONFIGURATION:
            return {
                ...state,
                questionarecclSelfConfiguration: action.questionarecclSelfConfiguration,
                questionarehighlevelInfo: action.questionarehighlevelInfo,
                questionareOncall: action.questionareOncall,
                questionarecclFiltering: action.questionarecclFiltering,
                questionarecommDL: action.questionarecommDL,
                questionarecclPM: action.questionarecclPM,
                questionarecustomerImpacting: action.questionarecustomerImpacting,
                questionareComments: action.questionareComments,
                questionarePartners: action.questionarePartners,
                questionareIsTenantOrSubscriptionIdBased: action.questionareIsTenantOrSubscriptionIdBased,
                questionareGPMName: action.questionareGPMName
            };   
        case ActionType.QUESTIONNAIRE_INFO_CCL:
            return {
                ...state,
                questionarehighlevelInfo: action.questionarehighlevelInfo,
                questionareOncall: action.questionareOncall,
                questionarecclFiltering: action.questionarecclFiltering,
                questionarecommDL: action.questionarecommDL,
                questionarecclPM: action.questionarecclPM,
                questionarecustomerImpacting: action.questionarecustomerImpacting,
                questionareComments: action.questionareComments,
                questionarePartners: action.questionarePartners,
                questionareIsTenantOrSubscriptionIdBased: action.questionareIsTenantOrSubscriptionIdBased,
                questionareGPMName: action.questionareGPMName,
                isLoading: false
            };   
        case ActionType.VALIDATE_CCL_SELFCONFIGURATION:
            return {
                ...state,
                questionarecclSelfConfiguration: action.questionarecclSelfConfiguration,
                isLoading: false
            };   
        case ActionType.ONBOARD_CCL_SUCCESS:
            return {
                ...state,
                displayCCLSuccessMessage: action.displayCCLSuccessMessage,
                isEnabled: action.isEnabled,
                isLoading: false
            };       
        case ActionType.ONBOARD_HEATMAP_SUCCESS:
            return {
                ...state,
                addNewService: action.addNewService,
                showMessage: action.showMessage,
                currentserviceId: action.currentserviceId
            };           
        case ActionType.ONBOARD_SERVICEHEATMAP_VALIDATED:
            return {
                ...state,
                showMessage: action.showMessage,
                isLoading: false
            };   
        case ActionType.ONBOARD_SERVICEHEATMAP_NOTVALIDATED:
            return {
                ...state,
                addNewService: action.addNewService,
                isLoading: false
            };   
        case ActionType.ONBOARD_SERVICEHEATMAP_VALIDATE_FAIL:
            return {
                ...state,
                isheatMapValidated: action.isheatMapValidated
            };   
        case '@@router/LOCATION_CHANGE': {
            const { search } = action.payload.location;
            var newurl = window.location.pathname;
            //Below conditions only for direct url access like bookmarks
            if (newurl.indexOf("Configuration") !== -1) {
                return {
                    ...state,
                    selectedService: { serviceId: null, serviceName: null },
                    serviceSelected: false
                }
            }
            else {
                return {
                    ...state
                }
            }
        };
        case ActionType.RESET_STATE:
            return initialState;
        default:
            return state;
    }
};