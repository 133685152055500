import { FunctionReturnTypes, ReduxAction } from '../';
import { actionCreators } from './actions';
import { ActionType, IServiceHealthState } from './types';
import { REPORT_TYPE_DROPDOWN } from '../../config/constants';
import { isNullOrUndefined } from '../../utils';
import moment from 'moment';


const initialState = Object.freeze<IServiceHealthState>({
    isLoading: false,
    dailyData: [],
    mvdailyData: [],
    uniqueDateHeaders: [],
    uniqueFixedHeaderColumns: [],
    datacentersList: [],
    mvdatacentersList: [],
    additionalInfoData: [],
    checkboxValue: false,
    selectedDropdownOption: REPORT_TYPE_DROPDOWN[0],
    selectedViewDropdownOption: null,
    multipleviewSelectedOption: null,
    dailyViewList: [],
    fleetTenantList: [],
    selectedTenant: null,
    fleetRoleList: [],
    selectedRole: null,
    fleetCloudList: [],
    selectedCloud: null,
    fleetTrafficGroupList: [],
    selectedTrafficGroup: null,
    fleetheatmapData: [],
    fleetheatmapDataOriginal: [],
    Datelabels: null,
    IsfleetDataloaded: false,
    dailyFleetDataFilter: null,
    fleetMaxDate: new Date(),
    fleetStartDate: new Date(),
    fleetEndDate: new Date(),
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 15),
    endDate: new Date(),
    viewdetailsdata: [],
    SelectedView: [],
    comments: [],
    colorScheme: [],
    Multipleviewdetailsdata: [],
    mvcomments: [],
    mvcolorScheme: [],
    BulkViewData: [],
    IncidentCheckmetricdata: null,
    previousService: null,
    currentService: null,
    nextService: null,
    dailyDataFilter: null,
    selectedServiceId: 0,
    IsdataLoaded: false,
    DailyDataFailure: false,
    MVIsdataLoaded: false,
    MVDailyDataFailure: false,
    IsReachabilitydataLoaded: false,
    weeklyStartDate: new Date(),
    weeklyDefaultDate: new Date(),
    weeklyData: [],
    weekDetailsData: null,
    draftsData: null,
    ConfigValues: [],
    Datefreshed: '',
    chkMSER: false,
    chkMonltyAvailability: false,
    chkRED: false,
    chkYELLOW: false,
    monthlyStartDate: new Date(),
    monthlyDefaultDate: new Date(),
    monthlyData: [],
    MonthlyheaderArr: [],
    MonthlyKPiArr: [],
    IsMonthlyDataLoaded: false,
    BGMdata: [],
    ExternalLinks: [],
    commentsData: [],
    LoginuserId: '',
    Performancedata: [],
    IsperformanceDataloaded: false,
    chkBulkEdit: false,
    chkHistory: false,
    IsWeeklyDataloaded: false,
    IsWeeklyDataDisabled: false,
    ConfigurationView: null,
    ColorLogicsData: [],
    ColorshemesData: [],
    noofdecimalsdata: [],
    colorSchemeValue: [],
    coloringLogicValue: [],
    noOfDecimalValue: [],
    volumeDisplayTypeValue: [],
    authenticationTypeValue: [],
    Disableviewconfigbutton: true,
    Newview: false,
    checkedReachability: false,
    AllviewsData: [],
    allReachabilityviewsData: [],
    ReachabilityData: [],
    ReachabilityuniqueDateHeaders: [],
    ReachabilityuniqueFixedHeaderColumns: [],
    Reachabilitycomments: [],
    ReachabilitycolorScheme: [],
    ReachabilitySelectedView: [],
    activeDailyDataTab: 'heatmap',
    showMSERComments: false,
    URLParam: '',
    reachabilityServices: [],
    selectedServiceDropdownOption: null,
    checkedFilterYellowDailyData: false,
    checkedFilterRedDailyData: false,
    checkedFilterYellowFleetData: false,
    checkedFilterRedFleetData: false,
    copyurlrechabilityserviceid: null,
    copyurlrechabilityviewid: null,
    IsfromURLNavigation: false,
    IsshowDrilldownIcon: false,
    IsshowEmail: false,
    drilldown: false,
    copyurlHeatMapServiceid: null,
    copyurlHeatMapViewid: null,
    dcMaxLength: 0,
    mvdcMaxLength: 0,
    serviceGroups: [],
    selectedServicegroupval: null,
    services: [],
    selectedServiceval: null,
    metricGroups: [],
    selectedMetricgroupval: null,
    metricsRaw: [],
    metricsRawService: [],
    activeMetrics: [],
    selectedMetric: null,
    environments: [],
    selectedEnvironment: null,
    datasource: [],
    authenticationTypes: [],
    selectedDatasource: null,
    metricDataSourceDetails: [],
    metricDataSourceDetailsRaw: [],
    serverName: '',
    newMetricName: '',
    newMetricGroupName:'',
    database: '',
    mdmMetricName: '',
    authenticationType: '',
    target: null,
    notes: '',
    queryOrUrl: '',
    targetTypes: [],
    onboardNewMetric: false,
    liKPIValueKeys: [],
    fleetDatacolorscheme: [],
    ViewMetricDataSourceOpen: true,
    AddMetricDataSourceOpen: false,
    MultipleViewSelected: false,
    TSGValue: '',
    HeatmapInactive: false,
    DateRefreshedByDataLoader: []
});

export const reducer = (
    state: IServiceHealthState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;
    switch (action.type) {
       
        case ActionType.WEEKLYDATA_RECEIVE:
            return {
                ...state,
                weeklyData: action.weeklyData,
                IsWeeklyDataloaded:true,
                IsWeeklyDataDisabled: action.isWeeklyDataDisabled,
                isLoading: false
            };
        case ActionType.WEEKLYDATA_RECEIVE_FAIL:
            return {
                ...state,
                weeklyData: [],
                IsWeeklyDataloaded: true,
                IsWeeklyDataDisabled: action.isWeeklyDataDisabled,
                isLoading: false
            };
        case ActionType.BGM_RECEIVE:
            return {
                ...state,
                BGMdata: action.BGMData
            };
        case ActionType.BGM_RECEIVE_FAIL:
            return {
                ...state,
                BGMdata: []
            };
        case ActionType.EXTERNALLINKS_RECEIVE:
            return {
                ...state,
                ExternalLinks: action.links
            };
        case ActionType.EXTERNALLINKS_RECEIVE_FAIL:
            return {
                ...state,
                ExternalLinks: []
            };
        case ActionType.MULTIPLEVIEW_SELECT_CHANGE: 
                return {
                    ...state,
                    MultipleViewSelected: action.MultipleViewSelected
                };            
        case ActionType.SET_DAILYDATA_UPDATELABELVALUES:
            return {
                ...state,
                ConfigurationView: action.view                
            };
        case ActionType.COMMENTS_RECEIVE:
            return {
                ...state,
                isLoading: (action.val === 1) ? false : state.isLoading,
                commentsData: action.comments
            }; 
        case ActionType.COMMENTS_RECEIVE_FAIL: 
            return {
                ...state,
                isLoading: (action.val === 1) ? false : state.isLoading,
                commentsData: []
            };
        case ActionType.FLEET_DROPDOWN_DATA_FETCH:
            return {
                ...state,
                fleetTenantList: [],
                selectedTenant: '',
                fleetRoleList: [],
                selectedRole: '',
                fleetCloudList: [],
                selectedCloud: '',
                fleetTrafficGroupList: [],
                selectedTrafficGroup: '',
                fleetheatmapData: [],
                isLoading: true,
                IsfleetDataloaded: false,
                dailyFleetDataFilter: null,
                checkedFilterYellowFleetData: false,
                checkedFilterRedFleetData: false
            };
        case ActionType.FLEET_DROPDOWN_DATA_RECEIVE:
            return {
                ...state,
                fleetTenantList: action.fleetTenantList,
                selectedTenant: action.selectedTenant,
                fleetRoleList: action.fleetRoleList,
                selectedRole: action.selectedRole,
                fleetCloudList: action.fleetCloudList,
                selectedCloud: action.selectedCloud,
                fleetTrafficGroupList: action.fleetTrafficGroupList,
                selectedTrafficGroup: action.selectedTrafficGroup,
                dailyFleetDataFilter: action.dailyFleetDataFilter,
                fleetStartDate: action.fleetStartDate,
                fleetEndDate: action.fleetEndDate,
                fleetMaxDate: action.fleetMaxDate
            };
        case ActionType.FLEET_DATA_FETCH:
            return {
                ...state,
                fleetheatmapData: []
            };
        case ActionType.FLEET_DATA_RECEIVE:
            return {
                ...state,
                fleetheatmapData: action.fleetheatmapData,
                Datelabels: action.Datelabels,
                isLoading: false,
                IsfleetDataloaded: true
            };
        case ActionType.DAILY_FLEET_FILTER_APPLIED:
            return {
                ...state,
                isLoading: true,
                IsfleetDataloaded: false,
                dailyFleetDataFilter: { tenant: state.selectedTenant.value, cloud: state.selectedCloud.value, role: state.selectedRole.value, trafficGroup: state.selectedTrafficGroup.value }
            };
        case ActionType.SET_FLEETDATALOADED_TRUE:
            return {
                ...state,
                IsfleetDataloaded: true
            };
        case ActionType.FLEET_START_DATE_CHANGE:
            return {
                ...state,
                fleetStartDate: action.fleetStartDate
            };
        case ActionType.FLEET_END_DATE_CHANGE:
            return {
                ...state,
                fleetEndDate: action.fleetEndDate
            };
        case ActionType.TENANT_DROPDOWN_SELECT:
            return {
                ...state,
                selectedTenant: action.selectedTenant,
                selectedRole: action.selectedRole,
                selectedCloud: action.selectedCloud,
                selectedTrafficGroup: action.selectedTrafficGroup,
                fleetRoleList: action.fleetRoleList,
                fleetCloudList: action.fleetCloudList,
                fleetTrafficGroupList: action.fleetTrafficGroupList,
                isLoading: true,
                IsfleetDataloaded: false,
                dailyFleetDataFilter: action.dailyFleetDataFilter,
                checkedFilterYellowFleetData: false,
                checkedFilterRedFleetData: false
            };
        case ActionType.ROLE_DROPDOWN_SELECT:        
            return {
                ...state,
                selectedRole: action.selectedRole,
                selectedCloud: action.selectedCloud,
                selectedTrafficGroup: action.selectedTrafficGroup,
                fleetCloudList: action.fleetCloudList,
                fleetTrafficGroupList: action.fleetTrafficGroupList,
                isLoading: true,
                IsfleetDataloaded: false,
                dailyFleetDataFilter: action.dailyFleetDataFilter,
                checkedFilterYellowFleetData: false,
                checkedFilterRedFleetData: false
            };
        case ActionType.CLOUD_DROPDOWN_SELECT:
            return {
                ...state,
                selectedCloud: action.selectedCloud,
                fleetTrafficGroupList: action.fleetTrafficGroupList,
                selectedTrafficGroup: action.selectedTrafficGroup,
                isLoading: true,
                IsfleetDataloaded: false,
                dailyFleetDataFilter: action.dailyFleetDataFilter,
                checkedFilterYellowFleetData: false,
                checkedFilterRedFleetData: false
            };
        case ActionType.TRAFFICGROUP_DROPDOWN_SELECT:
            return {
                ...state,
                selectedTrafficGroup: action.selectedTrafficGroup,
                isLoading: true,
                IsfleetDataloaded: false,
                dailyFleetDataFilter: action.dailyFleetDataFilter,
                checkedFilterYellowFleetData: false,
                checkedFilterRedFleetData: false
            };
        case ActionType.WEEKLYDATA_FETCH:
            return {
                ...state,
                weeklyData: [],
                isLoading: true,
                IsWeeklyDataloaded:false,
                IsWeeklyDataDisabled:false
            };
        case ActionType.WEEKLY_DETAILS_DATA_RECEIVE:
            return {
                ...state,                 
                weekDetailsData: action.weekDetailsData
            };
        case ActionType.WEEKLY_DETAILS_DATA_RECEIVE_FAIL:
            return {
                ...state,
                weekDetailsData: null
            };
        case ActionType.DRAFTS_DATA_RECEIVE:
            return {
                ...state,
                draftsData: action.draftData
            };
        case ActionType.DRAFTS_DATA_RECEIVE_FAIL:
            return {
                ...state,
                draftsData: null
            };
        case ActionType.CONFIGVALUES_RECEIVE:
            return {
                ...state,
                ConfigValues: action.ConfigValues
            };
        case ActionType.CONFIGVALUES_RECEIVE_FAIL:
            return {
                ...state,
                ConfigValues: []
            };
        case ActionType.MONTHLYDATA_RECEIVE:
            return {
                ...state,
                monthlyData: action.monthlyData,
                MonthlyheaderArr: action.MonthlyheaderArr,
                MonthlyKPiArr: action.MonthlyKPiArr,
                IsMonthlyDataLoaded: true,
                liKPIValueKeys: action.liKPIValueKeys,
                DateRefreshedByDataLoader: action.Datarefershedate,
                isLoading: false
            };
        case ActionType.SET_REFRESHDATE_SUCCESS:
            return {
                ...state,
                DateRefreshedByDataLoader: action.Datarefershedate
            };
        case ActionType.SET_REFRESHDATE_FAIL:
            return {
                ...state,
                DateRefreshedByDataLoader:[] 
            };
        case ActionType.MONTHLYDATA_FETCH:
            return {
                ...state,
                monthlyData: [],
                isLoading: true,
                IsMonthlyDataLoaded: false
            };
        case ActionType.DROPDOWN_SELECT:
            return {
                ...state,
                weeklyStartDate: action.weeklyStartDate,
                weeklyDefaultDate: action.weeklyDefaultDate,
                monthlyStartDate: action.monthlyStartDate,
                monthlyDefaultDate: action.monthlyDefaultDate,
                selectedDropdownOption: action.option || REPORT_TYPE_DROPDOWN[0],
                showMSERComments: false,               
                dailyData: action.dailyData,
                ReachabilityData: action.ReachabilityData,
                checkedFilterYellowDailyData: false,
                checkedFilterRedDailyData: false
                
            };

        case ActionType.CHECK_SAMPLE_BOX:
            return {
                ...state,
                checkboxValue: !!action.checked
            };
        case ActionType.CHECK_SAVE_MSER:
            return {
                ...state,
                ConfigurationView: action.view,
                chkMSER: !!action.checked
            };
        case ActionType.CHECK_SAVE_MONTHLY_AVAILABILITY:
            return {
                ...state,
                ConfigurationView: action.view,
                chkMonltyAvailability: !!action.checked
            };
        case ActionType.CHECK_RED:
            return {
                ...state,
                chkRED: !!action.checked
            };
        case ActionType.CHECK_YELLOW:
            return {
                ...state,
                chkYELLOW: !!action.checked
            };
        case ActionType.DAILY_FILTER_YELLOW:
            return {
                ...state,
                dailyData: action.dailyData,
                ReachabilityData: action.ReachabilityData,
                checkedFilterYellowDailyData: !!action.checked
            };
        case ActionType.FLEET_FILTER_RED:
            return {
                ...state,
                fleetheatmapData: action.fleetheatmapData,
                checkedFilterRedFleetData: !!action.checked,
                checkedFilterYellowFleetData: action.checkedFilterYellowFleetData
            };
        case ActionType.FLEET_FILTER_YELLOW:
            return {
                ...state,
                fleetheatmapData: action.fleetheatmapData,
                checkedFilterYellowFleetData: !!action.checked,
                checkedFilterRedFleetData: action.checkedFilterRedFleetData
            };
        case ActionType.DAILY_FILTER_RED:
            return {
                ...state,
                dailyData: action.dailyData,
                ReachabilityData: action.ReachabilityData,
                checkedFilterRedDailyData: !!action.checked
            };
        case ActionType.CHECK_BULK_EDIT:
            return {
                ...state,
                chkBulkEdit: action.checked
            };
        case ActionType.CHECK_HISTORY:
            return {
                ...state,
                chkHistory: !!action.checked
            };
        case ActionType.PREVIOUS_SERVICE_NAVIGATOR: {
            let sevId = state.nextService[0].serviceId;
            return {
                ...state,
                selectedServiceId: state.nextService[0].serviceId,
                dailyDataFilter: { startDate: state.startDate, endDate: state.endDate, viewId: 0 },
                dailyData: [],
                ReachabilityData: [],
                activeDailyDataTab: 'heatmap',
                AllviewsData: [],
                weeklyData: [],
                monthlyData: [],
                checkedFilterYellowDailyData: false,
                checkedFilterRedDailyData: false,
                chkHistory: false,
                MultipleViewSelected: (sevId == 122 ? true : false),
                Multipleviewdetailsdata: [],
                mvdailyData: [],
                mvcomments: [],
                mvcolorScheme: [],
                mvdatacentersList: [],
                multipleviewSelectedOption: null,
                MVIsdataLoaded: true,
                MVDailyDataFailure: false
            };
        }
        case ActionType.SET_DAILYDAT_FILTER_VIEWID:
            return {
                ...state,               
                dailyDataFilter: { startDate: state.startDate, endDate: state.endDate, viewId: 0 }
            };
        case ActionType.NEXT_SERVICE_NAVIGATOR:
            {
                let sevId = state.previousService[0].serviceId;

                return {
                    ...state,
                    dailyDataFilter: { startDate: state.startDate, endDate: state.endDate, viewId: 0 },
                    selectedServiceId: state.previousService[0].serviceId,
                    ReachabilityData: [],
                    activeDailyDataTab: 'heatmap',
                    checkedFilterYellowDailyData: false,
                    checkedFilterRedDailyData: false,
                    chkHistory: false,
                    MultipleViewSelected: (sevId == 122 ? true : false),
                    Multipleviewdetailsdata: [],
                    mvdailyData: [],
                    mvcomments: [],
                    mvcolorScheme: [],
                    mvdatacentersList: [],
                    multipleviewSelectedOption: null,
                    MVIsdataLoaded: true,
                    MVDailyDataFailure: false
                };
            }
        case ActionType.CURRENT_SERVICE_NAVIGATOR:
            const { previousService, currentService, nextService, LoginuserId } = action;
            return {
                ...state,               
                previousService,
                currentService,
                nextService,
                selectedServiceId: action.selectedServiceId,
                LoginuserId,               
                IsfromURLNavigation:false
               
            }; 
        case ActionType.DAILY_FILTER_APPLIED:
            return {
                ...state,
                showMSERComments: false,
                dailyDataFilter: { startDate: state.startDate, endDate: state.endDate, viewId:state.selectedViewDropdownOption.value }
            };
        case ActionType.START_DATE_CHANGE:
            return {
                ...state,
                startDate: action.startDate
            };
        case ActionType.WEEKLY_START_DATE_CHANGE:
            return {
                ...state,
                weeklyStartDate: action.weeklyStartDate
            };
        case ActionType.MONTHLY_START_DATE_CHANGE:
            return {
                ...state,
                monthlyStartDate: action.monthlyStartDate
            };
        case ActionType.END_DATE_CHANGE:
            return {
                ...state,
                endDate: action.endDate
            };
        case ActionType.VIEW_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false
            };
        case ActionType.DAILYDATA_UPDATE_SUCCESS:
            return {
                ...state
            };
        case ActionType.DAILYDATA_UPDATE_FAIL: {

        }
        case ActionType.DAILYDATA_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false
            };
        case ActionType.DATA_REFRESH:
            return {
                ...state,
                IsdataLoaded: false,
                DailyDataFailure: false,
                IsReachabilitydataLoaded: false,
                isLoading: true,
                dailyData: action.dailyData,
                ReachabilityData: action.ReachabilityData,
                showMSERComments: false,
                checkedFilterYellowDailyData: action.checkedFilterYellowDailyData,
                checkedFilterRedDailyData: action.checkedFilterRedDailyData
            };    
        case ActionType.BULKVIEWS_RECEIVE:
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly handle out-of-order responses.
            const { BulkViewData } = action;
            return {
                ...state,
                BulkViewData,
                isLoading: false
                
            };
        case ActionType.BULKVIEWS_FAIL:  
            return {
                ...state,
                BulkViewData:[],
                isLoading: false

            };
        case ActionType.DAILYDATA_ADD_FAIL: {

        }
        case ActionType.COMMENT_INCIDENT_CHECK: {   
            const { IncidentCheckmetricdata } = action;
            return {
                ...state,
                IncidentCheckmetricdata,
                isLoading:false
            }
        }
        case ActionType.DAILYDATA_ADD_FAIL: {

        }
        case ActionType.VIEW_RECEIVE:
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly handle out-of-order responses.
            const { dailyViewList } = action;
            const { viewdetailsdata } = action;
            return {
                ...state,
                Newview:false,
                dailyViewList,                
                viewdetailsdata,
                isLoading: (action.IscallforUpdate === 1) ? false : state.isLoading,
                ConfigurationView: action.Currentview,               
                selectedViewDropdownOption: action.selectedViewDropdownOption,
                coloringLogicValue: action.coloringLogicValue,
                noOfDecimalValue: action.noOfDecimalValue,
                authenticationTypeValue: action.authenticationTypeValue,
                Disableviewconfigbutton: action.Disableviewconfigbutton,
                volumeDisplayTypeValue: action.volumeDisplayTypeValue,
                chkMonltyAvailability: action.chkMonltyAvailability,
                checkedReachability: action.checkedReachability,
                chkMSER: action.chkMSER,
                TSGValue: action.TSGValue,
                AllviewsData: action.AllviewsData,
                VIEW_RECEIVE: action.VIEW_RECEIVE,
                
            };

        case ActionType.VIEW_RECEIVE_FAIL:           
            return {
                ...state,
                dailyViewList: [],               
                viewdetailsdata:[],
                selectedViewDropdownOption: null,
                ConfigurationView: null,                
                coloringLogicValue: [],
                noOfDecimalValue: [],
                authenticationTypeValue: [],
                volumeDisplayTypeValue: [],
                Disableviewconfigbutton: true,
                isLoading: (action.IscallforUpdate === 1) ? false : state.isLoading,

            };
        case ActionType.PERFORMANCE_REQUEST: {
            return {
                ...state,
                isLoading: true,
                IsperformanceDataloaded: false,
                Performancedata: []
            }
        }
        case ActionType.WEEKLY_PERFORMANCE_RECEIVE: { 
            return {
                ...state,
                isLoading: false,
                IsperformanceDataloaded: true,
                Performancedata: action.WeeklyPerformancedata
            }
        }
        case ActionType.WEEKLY_PERFORMANCE_RECEIVE_FAIL: {
            return {
                ...state,
                isLoading: false,
                IsperformanceDataloaded: true,
                Performancedata: []
            }
        } 
        case ActionType.PERFORMANCE_RECEIVEDATA: {     
            return {
                ...state,
                isLoading: false,
                IsperformanceDataloaded: true,
                Performancedata: action.Perforamncedata
            }
        }
        case ActionType.MULTIPLEVIEW_DROPDOWN_SELECT:
            return {
                ...state,
                multipleviewSelectedOption: action.multipleviewSelectedOption
            };
        case ActionType.VIEW_DROPDOWN_SELECT:
            return {
                ...state,
                selectedViewDropdownOption: action.viewData,
                ConfigurationView: action.ConfigurationView,
                Disableviewconfigbutton: action.Disableviewconfigbutton,
                dailyDataFilter: { startDate: state.startDate, endDate: state.endDate, viewId: action.viewData.value },
                chkMonltyAvailability: action.chkMonltyAvailability,
                chkMSER: action.chkMSER,
                TSGValue: action.TSGValue,
                checkedReachability: action.checkedReachability,
                coloringLogicValue: action.coloringLogicValue,
                noOfDecimalValue: action.noOfDecimalValue,
                colorSchemeValue: action.colorSchemeValue,
                authenticationTypeValue: action.authenticationTypeValue,
                volumeDisplayTypeValue: action.volumeDisplayTypeValue,
                showMSERComments: false,
                ReachabilityData: []
            };
        case ActionType.SET_NOVIEWS:
            return {
                ...state,
                selectedViewDropdownOption: [],              
                chkMonltyAvailability: false,
                chkMSER: false,
                TSGValue: null,
                checkedReachability: false,               
                showMSERComments: false,
                ReachabilityData: [],
                dailyData:[]
            };
        case ActionType.REQUEST: 
        case ActionType.RECEIVE:
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly handle out-of-order responses.
            const { datacentersList } = action;
            const { additionalInfoData } = action;           
            return {
                ...state,               
                dailyData: action.dailyData,               
                uniqueDateHeaders: action.uniqueDateHeaders,
                uniqueFixedHeaderColumns: action.uniqueFixedHeaderColumns,
                comments: action.comments,
                colorScheme: action.colorScheme,
                SelectedView: action.SelectedView,      
                ReachabilityData: action.ReachabilityData,
                ReachabilityuniqueDateHeaders: action.ReachabilityuniqueDateHeaders,
                ReachabilityuniqueFixedHeaderColumns: action.ReachabilityuniqueFixedHeaderColumns,
                Reachabilitycomments: action.Reachabilitycomments,
                ReachabilitycolorScheme: action.ReachabilitycolorScheme,
                ReachabilitySelectedView: action.ReachabilitySelectedView,
                datacentersList,
                additionalInfoData,
                isLoading: false,
                IsdataLoaded: true,
                DailyDataFailure: false,
                IsReachabilitydataLoaded: true,
                chkMSER: !!action.chkMSER,
                chkMonltyAvailability: !!action.chkMonltyAvailability,
                checkedFilterYellowDailyData: false,
                checkedFilterRedDailyData: false,
                IsshowDrilldownIcon: action.IsshowDrilldownIcon,
                IsshowEmail: action.IsshowEmail,
                drilldown: false,
                dcMaxLength: action.longestDC,
                TSGValue: action.TSGValue
            };
        case ActionType.DAILYDATAFAIL:
            return {
                ...state,
                dailyData: action.dailyData,
                uniqueDateHeaders: action.uniqueDateHeaders,
                uniqueFixedHeaderColumns: action.uniqueFixedHeaderColumns,
                comments: action.comments,
                colorScheme: action.colorScheme,
                SelectedView: action.SelectedView,
                ReachabilityData: action.ReachabilityData,
                ReachabilityuniqueDateHeaders: action.ReachabilityuniqueDateHeaders,
                ReachabilityuniqueFixedHeaderColumns: action.ReachabilityuniqueFixedHeaderColumns,
                Reachabilitycomments: action.Reachabilitycomments,
                ReachabilitycolorScheme: action.ReachabilitycolorScheme,
                ReachabilitySelectedView: action.ReachabilitySelectedView,
                datacentersList:[],
                additionalInfoData:[],                
                IsdataLoaded: true,
                DailyDataFailure: false,
                isLoading: false,
                IsReachabilitydataLoaded: true,
                chkMSER: false,
                TSGValue: action.TSGValue,
                chkMonltyAvailability: false,
                checkedFilterYellowDailyData: false,
                checkedFilterRedDailyData: false,
                IsshowDrilldownIcon:false
            };
        case ActionType.HEATMAP_DAILYDATA_FAILURE: {
            return {
                ...state,
                isLoading: false,
                DailyDataFailure: true
            }
        };   
        case ActionType.MULTIVIEW_COLORSCHEME_RECEIVE: {
            return {
                ...state,
                mvcolorScheme: action.mvcolorScheme,
                Multipleviewdetailsdata: action.Multipleviewdetailsdata
            }
        };    
        case ActionType.MULTIVIEW_RECEIVE: {
            return {
                ...state,
                mvdailyData: action.mvdailyData,
                mvcomments: action.mvcomments,
                mvcolorScheme: action.mvcolorScheme,
                Multipleviewdetailsdata: action.Multipleviewdetailsdata,
                mvdatacentersList: action.mvdatacentersList,
                isLoading: false,
                MVIsdataLoaded: true,
                MVDailyDataFailure: false,
                multipleviewSelectedOption: action.multipleviewSelectedOption
            }
        };
        case ActionType.MULTIVIEWDAILYDATAFAIL:
            return {
                ...state,
                mvdailyData: action.mvdailyData,
                mvcomments: action.mvcomments,
                mvcolorScheme: action.mvcolorScheme,
                Multipleviewdetailsdata: action.Multipleviewdetailsdata,
                mvdatacentersList: [],
                MVIsdataLoaded: true,
                MVDailyDataFailure: false,
                isLoading: false
            };
        case ActionType.MULTIPLEVIEW_DATA_REFRESH:
            return {
                ...state,
                MVIsdataLoaded: false,
                MVDailyDataFailure: false,
                isLoading: true,
                mvdailyData: action.mvdailyData,
                mvcolorScheme: action.mvcolorScheme
            };    
        case '@@router/LOCATION_CHANGE': {
            const { search } = action.payload.location;
            let URLParam;
            let copyurlrechabilityviewid = null;
            let copyurlrechabilityserviceid = null;
            let copyurlHeatMapViewid = 0;
            let copyurlHeatMapServiceid = null;
            let copyUrlFrDate = null;
            let copyUrlToDate = null;
            const params = new URLSearchParams(search);
            const serviceId = parseInt(params.get('serviceid'));
            let tempviewId = params.get('viewId');
            const viewId = parseInt(params.get('viewId'));
            let frDate = params.get('frDate');
            let CopyUrlMultipleViewSelected = params.get('multipleView');
            let CopyUrlmultipleviewSelectedOption = [];

            if (serviceId == 122) {
                CopyUrlMultipleViewSelected = "true";
            }

            if (!isNullOrUndefined(CopyUrlMultipleViewSelected)) {
                if (tempviewId !== null && tempviewId !== undefined) {
                    var multipleViewIds = tempviewId.split(',');
                    multipleViewIds.map((val) => {
                        CopyUrlmultipleviewSelectedOption.push(parseInt(val));
                    });
                }
            }


            var sDate = new Date();
            var eDate = new Date();
            //Below to code to set the from date based on the query string
            if (!isNullOrUndefined(frDate)) {
                var fDays = parseInt(frDate.substr(frDate.lastIndexOf("-") + 1, (frDate.length - frDate.lastIndexOf("-"))));
                copyUrlFrDate = moment(sDate).subtract(fDays, 'day').toDate(); // date object
            }
            let toDate = params.get('toDate');
            if (!isNullOrUndefined(toDate)) {
                var tDays = parseInt(toDate.substr(toDate.lastIndexOf("-") + 1, (toDate.length - toDate.lastIndexOf("-"))));
                copyUrlToDate = moment(sDate).subtract(tDays, 'day').toDate(); // date object
            }
            let name = params.get('name');
            let tab = params.get('tab');
            let ddselection;
            let activeDailyDataTab = 'heatmap';

            //Below conditions only for direct url access like bookmarks
            if (name == "Weekly") {
                ddselection = { value: 2, label: 'Weekly' };
                URLParam = 'Weekly';
            }
            else if (name == "Monthly") {
                ddselection = { value: 3, label: 'Monthly' };
                URLParam = 'Monthly';
            }
            else if (name == "selfserveconfig") {
                ddselection = { value: 1, label: 'Daily' };
                URLParam = 'selfserveconfig';
                activeDailyDataTab = 'ViewConfiguration';
            } else if (name == "FleetUtilizationheatmap") {
                ddselection = { value: 1, label: 'Daily' };
                URLParam = 'FleetUtilizationheatmap';
                activeDailyDataTab = 'Fleetutilizationheatmap';
            } else if (name == "HMReachability") {
                ddselection = { value: 1, label: 'Daily' };
                URLParam = 'HMReachability';
                activeDailyDataTab = 'reachability';
            }
            else if (name == "ServicePerformance") {
                ddselection = { value: 2, label: 'Weekly' };
                URLParam = 'ServicePerformance';
            }
            else if (name == "SelfServeConfig") {
                ddselection = { value: 2, label: 'Weekly' };
                URLParam = 'WeeklySelfServe';
            }
            else if (tab == "reachability") {
                ddselection = { value: 1, label: 'Daily' };
                activeDailyDataTab = 'reachability';
                URLParam = 'reachability';
                copyurlrechabilityviewid = viewId;
                copyurlrechabilityserviceid = serviceId;
                copyUrlFrDate = copyUrlFrDate;
                copyUrlToDate = copyUrlToDate;
            }
            else if ((tab == "heatmap") || ((isNullOrUndefined(tab)) && (viewId > 0) && (serviceId > 0))) {
                ddselection = { value: 1, label: 'Daily' };
                activeDailyDataTab = 'heatmap';
                URLParam = 'heatmap';
                copyurlHeatMapViewid = viewId;
                copyurlHeatMapServiceid = serviceId;              
                copyUrlFrDate = copyUrlFrDate;
                copyUrlToDate = copyUrlToDate;
            }           
            else {
                URLParam = '';
                ddselection = { value: 1, label: 'Daily' };  
            }
            copyUrlFrDate = (copyUrlFrDate !== null ? copyUrlFrDate : moment(new Date()).subtract(15, 'day').toDate());
            copyUrlToDate = (copyUrlToDate !== null ? copyUrlToDate : new Date());
            return {
                ...state,
                AllviewsData: [],
                SelectedView:[],               
                selectedServiceId: 0,
                selectedDropdownOption: ddselection,
                activeDailyDataTab: activeDailyDataTab,
                showMSERComments: false,
                chkBulkEdit: false,
                URLParam: URLParam,
                checkedFilterYellowDailyData: false,
                checkedFilterRedDailyData: false,
                checkedFilterYellowFleetData: false,
                checkedFilterRedFleetData: false,
                copyurlrechabilityviewid: copyurlrechabilityviewid,
                copyurlrechabilityserviceid: copyurlrechabilityserviceid,
                IsfromURLNavigation: true,
                copyurlHeatMapServiceid: copyurlHeatMapServiceid,
                copyurlHeatMapViewid: copyurlHeatMapViewid,
                startDate: copyUrlFrDate,
                endDate: copyUrlToDate,
                dailyDataFilter: { startDate: copyUrlFrDate, endDate: copyUrlToDate, viewId: copyurlHeatMapViewid },
                MultipleViewSelected: (serviceId == 122 ? true : CopyUrlMultipleViewSelected),
                Multipleviewdetailsdata: [],
                multipleviewSelectedOption: CopyUrlmultipleviewSelectedOption.length === 0 ? null : CopyUrlmultipleviewSelectedOption,
                MVIsdataLoaded: false,
                MVDailyDataFailure: false
            }
        }
        case ActionType.RESET_STATE: {
           return initialState;
        }            
        case ActionType.VIEWCONFIGURATION_COLORLOGIC:
            return {
                ...state,
                ColorLogicsData: action.ColorLogicsData               
            };
        case ActionType.VIEWCONFIGURATION_COLORSCHEMES:
            return {
                ...state,
                colorSchemeValue: action.colorSchemeValue,
                ColorshemesData: action.ColorshemesData,
                isLoading: (action.val == 1) ? state.isLoading : false
            };
        case ActionType.VIEWCONFIGURATION_NOOFDECIMALS:
            return {
                ...state,
                noofdecimalsdata: action.noofdecimalsdata               
            };
        case ActionType.VIEWCONFIGURATION_VALUES_CHANGE:
            return {
                ...state,
                ConfigurationView: action.View              
            };

        case ActionType.COLOR_SCHEME_SELECT:
            return {
                ...state,
                colorSchemeValue: action.option,
                ConfigurationView: action.ConfigurationView,
                Disableviewconfigbutton: action.Disableviewconfigbutton
            };
        case ActionType.COLORLOGIC_SELECT:
            return {
                ...state,
                coloringLogicValue: action.option,
                ConfigurationView: action.ConfigurationView,
                Disableviewconfigbutton: action.Disableviewconfigbutton
            };
        case ActionType.NOOFDECIMALS_SELECT:
            return {
                ...state,
                noOfDecimalValue: action.option,
                ConfigurationView: action.ConfigurationView,
                Disableviewconfigbutton: action.Disableviewconfigbutton
            };
        case ActionType.VOLUMEDISPLAY_SELECT:
            return {
                ...state,
                volumeDisplayTypeValue: action.option,
                ConfigurationView: action.ConfigurationView,
                Disableviewconfigbutton: action.Disableviewconfigbutton
            };
        case ActionType.AUTHENTOCATIONTYPE_SELECT:
            return {
                ...state,
                authenticationTypeValue: action.option,
                Disableviewconfigbutton: action.Disableviewconfigbutton,
                ConfigurationView: action.ConfigurationView
            };
        case ActionType.SET_DISABLE_VIEWCONFIG_BUTTON:
            return {
                ...state,
                Disableviewconfigbutton: action.Disableviewconfigbutton,
                ConfigurationView: action.view                
            };
        case ActionType.CREATE_NEW_VIEW_CHANGE:
            return {
                ...state,
                Newview: action.Newview,
                ConfigurationView: action.ConfigurationView,
                Disableviewconfigbutton: action.Disableviewconfigbutton,
                coloringLogicValue: action.coloringLogicValue,
                authenticationTypeValue: action.authenticationTypeValue,
                noOfDecimalValue: action.noOfDecimalValue,
                volumeDisplayTypeValue: action.volumeDisplayTypeValue,
                chkMonltyAvailability: action.chkMonltyAvailability,
                colorSchemeValue: action.colorSchemeValue,
                chkMSER: action.chkMSER,
                TSGValue: action.TSGValue,
                checkedReachability: action.checkedReachability
            };
        case ActionType.CHECK__REACHABILITY_CHANGE:
            return {
                ...state,
                checkedReachability: action.checked,
                ConfigurationView: action.view,
                colorSchemeValue: action.colorSchemeValue,
                coloringLogicValue: action.coloringLogicValue
            };
        case ActionType.SET_VIEW_DROPDOWN_BASEDON_TAB_SELECTION:
            return {
                ...state,
                viewdetailsdata: action.viewdetailsdata,
                dailyViewList: action.dailyViewList
            };
        case ActionType.SET_MSERCOMMENTS:
            return {
                ...state,
                showMSERComments: action.showMSERComments
            };
        case ActionType.SET_DAILYDATA_ACTIVETAB:
            return {
                ...state,
                Newview: false,
                ConfigurationView: action.ConfigurationView,
                activeDailyDataTab: action.tab,
                drilldown:false
            };
        case ActionType.FETCH_REACHABILITY_SERVICES:
            return {
                ...state,
                rechabilityServices: action.rechabilityServices,              
                selectedServiceDropdownOption: action.selectedReachabilityService
            };
        case ActionType.REACHABILITY_SERVICE_DROPDOWN_SELECT: {
            const { dailyViewList } = action;
            const { viewdetailsdata } = action;
            return {
                ...state,
                dailyViewList,
                viewdetailsdata,
                selectedViewDropdownOption: action.selectedViewDropdownOption,
                selectedServiceDropdownOption: action.selectedReachabilityService,
                dailyDataFilter: { startDate: state.startDate, endDate: state.endDate, viewId: action.selectedViewDropdownOption.value },
                allReachabilityviewsData: action.allReachabilityviewsData,
            }
        }
        case ActionType.REACHABILITY_SERVICE_RECEIVE_FAIL:
            return {
                ...state,
                rechabilityServices: []
            };
        case ActionType.REACHABILITY_SERVICE_VIEW_FAIL:
            return {
                ...state,
                dailyViewList: [],
                viewdetailsdata: [],
                selectedViewDropdownOption: null               
            };
        case ActionType.SETURL_PARAM_EMPTY:
            return {
                ...state,
                URLParam:''
               
            };
        case ActionType.SET_SELECTEDSERVICEID_EMPTY:
            return {
                ...state,
                selectedServiceId: 0
            };    
        case ActionType.GET_WEEKLYSELFSERVE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.GET_SERVICEGROUPS_FAIL:
            return {
                ...state,
                serviceGroups: [],
                isLoading: (action.count >= 6) ? false : state.isLoading
            };
        case ActionType.GET_SERVICEGROUPS_SUCCESS:
            return {
                ...state,
                serviceGroups: action.serviceGroups,
                isLoading: (action.count >= 6) ? false : state.isLoading
            };
        case ActionType.GET_SERVICELISTS_FAIL:
            return {
                ...state,
                services: [],
                isLoading: (action.count >= 6) ? false : state.isLoading
            };
        case ActionType.GET_SERVICELISTS_SUCCESS:
            return {
                ...state,
                services: action.services,
                selectedServiceval: action.selectedServiceval,
                isLoading: (action.count >= 6) ? false : state.isLoading
            };
        case ActionType.GET_TARGETTYPE_FAIL:
            return {
                ...state,
                targetTypes: [],
                isLoading: (action.count >= 6) ? false : state.isLoading
            };
        case ActionType.GET_TARGETTYPE_SUCCESS:
            return {
                ...state,
                targetTypes: action.targetTypes,
                isLoading: (action.count >= 6) ? false : state.isLoading
            };
        case ActionType.SERVICEGROUP_CHANGE:
            return {
                ...state,
                selectedServicegroupval: action.selectedServicegroupval
            };
        case ActionType.SERVICES_CHANGE:
            return {
                ...state,
                selectedServiceval: action.selectedServiceval,
                metricsRaw: action.metricsRaw,
                activeMetrics: action.activeMetrics,
                metricsRawService: action.metricsRawService,
                selectedDatasource: action.selectedDatasource,
                selectedServicegroupval: action.selectedServicegroupval
            };
        case ActionType.METRICGROUP_CHANGE:
            return {
                ...state,
                selectedMetricgroupval: action.selectedMetricgroupval,
                selectedDatasource: action.selectedDatasource,
                activeMetrics: action.activeMetrics
            };
        case ActionType.METRIC_CHANGE:
            return {
                ...state,
                selectedMetric: action.selectedMetric,
                selectedDatasource: action.selectedDatasource,
                selectedEnvironment: action.selectedEnvironment
            };
        case ActionType.ENVIRONMENT_CHANGE:
            return {
                ...state,
                selectedEnvironment: action.selectedEnvironment,
                activeMetrics: action.activeMetrics,
                selectedMetric: action.selectedMetric,
                selectedDatasource: action.selectedDatasource
            };
        case ActionType.DATASOURCE_CHANGE:
            return {
                ...state,
                selectedDatasource: action.selectedDatasource
            };
        case ActionType.GET_METRICGROUPS_FAIL:
            return {
                ...state,
                metricGroups: [],
                isLoading: (action.count >= 6) ? false : state.isLoading
            };
        case ActionType.GET_METRICGROUPS_SUCCESS:
            return {
                ...state,
                metricGroups: action.metricGroups,
                isLoading: (action.count >= 6) ? false : state.isLoading
            };
        case ActionType.GET_ENVIRONMENT_FAIL:
            return {
                ...state,
                environments: [],
                isLoading: (action.count >= 6) ? false : state.isLoading
            };
        case ActionType.GET_ENVIRONMENT_SUCCESS:
            return {
                ...state,
                environments: action.environments,
                isLoading: (action.count >= 6) ? false : state.isLoading
            };
        case ActionType.GET_DATASOURCE_FAIL:
            return {
                ...state,
                datasource: [],
                isLoading: (action.count >= 6) ? false : state.isLoading
            };
        case ActionType.GET_DATASOURCE_SUCCESS:
            return {
                ...state,
                datasource: action.datasource,
                isLoading: (action.count >= 6) ? false : state.isLoading
            };
        case ActionType.GET_AUTHENTICATIONTYPE_FAIL:
            return {
                ...state,
                authenticationTypes: []
            };
        case ActionType.GET_AUTHENTICATIONTYPE_SUCCESS:
            return {
                ...state,
                authenticationTypes: action.authenticationTypes
            };
        case ActionType.GET_METRICDATASOURCE_REQUEST:
            return {
                ...state,
                metricDataSourceDetails: [],
                metricDataSourceDetailsRaw: [],
                isLoading: true
            };
        case ActionType.GET_METRICDATASOURCE_FAIL:
            return {
                ...state,
                metricDataSourceDetails: [],
                metricDataSourceDetailsRaw: [],
                isLoading: false
            };
        case ActionType.GET_METRICDATASOURCE_SUCCESS:
            return {
                ...state,
                selectedServiceval: action.selectedServiceval,
                metricDataSourceDetails: action.metricDataSourceDetails,
                metricDataSourceDetailsRaw: action.metricDataSourceDetailsRaw,
                isLoading: false
            };
        case ActionType.GET_TARGETTYPE_SUCCESS:
            return {
                ...state,
                targetTypes: action.targetTypes
            };
        case ActionType.GET_TARGETTYPE_FAIL:
            return {
                ...state,
                targetTypes: [],
            };
        case ActionType.FILTER_METRICDATASOURCE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.FILTER_METRICDATASOURCE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                metricDataSourceDetails: action.metricDataSourceDetails
            };
        case ActionType.METRICDATASOURCE_METRICNAME_ADD:
            return {
                ...state,
                newMetricName: action.newMetricName
            };
        case ActionType.METRICDATASOURCE_METRICGROUP_ADD:
            return {
                ...state,
                newMetricGroupName: action.newMetricGroupName
            };
        case ActionType.METRICDATASOURCE_SERVER_CHANGE:
            return {
                ...state,
                serverName: action.serverName
            };
        case ActionType.METRICDATASOURCE_DATABASE_CHANGE:
            return {
                ...state,
                database: action.database
            };
        case ActionType.MDM_METRICNAME_CHANGE:
            return {
                ...state,
                mdmMetricName: action.mdmMetricName
            };
        case ActionType.METRICDATASOURCE_ONBOARD_CHANGE:
            return {
                ...state,
                onboardNewMetric: action.onboardNewMetric,
                activeMetrics: action.activeMetrics,
                selectedMetricgroupval: action.selectedMetricgroupval,
                selectedMetric: action.selectedMetric,
                selectedEnvironment: action.selectedEnvironment,
                selectedDatasource: action.selectedDatasource
            };    
        case ActionType.METRICDATASOURCE_AUTHENTICATIONTYPE_CHANGE:
            return {
                ...state,
                authenticationType: action.authenticationType
            };
        case ActionType.METRICDATASOURCE_QUERYORURL_CHANGE:
            return {
                ...state,
                queryOrUrl: action.queryOrUrl
            };
        case ActionType.METRICDATASOURCE_TARGET_CHANGE:
            return {
                ...state,
                target: action.target
            };
        case ActionType.METRICDATASOURCE_NOTES_CHANGE:
            return {
                ...state,
                notes: action.notes
            };
        case ActionType.CLEAR_METRICDATASOURCE_SECTION:
            return {
                ...state,
                serverName: '',
                newMetricName: '',
                newMetricGroupName:'',
                authenticationType: '',
                queryOrUrl: '',
                target: '',
                notes: '',
                database: '',
                selectedDatasource: null                
            };
        case ActionType.ENABLE_EDITORADD_METRICDATASOURCE:
            return {
                ...state,
                serverName: action.serverName,
                newMetricName: action.newMetricName,
                newMetricGroupName: action.newMetricGroupName,
                authenticationType: action.authenticationType,
                selectedEnvironment: action.selectedEnvironment,
                selectedMetric: action.selectedMetric,
                selectedMetricgroupval: action.selectedMetricgroupval,
                queryOrUrl: action.queryOrUrl,
                target: action.target,
                notes: action.notes,
                database: action.database,
                mdmMetricName: action.mdmMetricName,
                selectedDatasource: action.selectedDatasource
            };    
        case ActionType.RESET_FILTER:
            return {
                ...state,
                selectedServicegroupval: null,
                selectedMetricgroupval: null,
                selectedMetric: null,
                selectedEnvironment: null,
                selectedDatasource: null,
                metricDataSourceDetails: [],
                onboardNewMetric: false
            };
        case ActionType.SET_DRILLDOWN_VALUE:
            return {
                ...state,
                drilldown: action.drilldown
            };  
        case ActionType.COLORSCHEME_SUCCESS:
            return {
                ...state,
                fleetDatacolorscheme: action.fleetDatacolorscheme
            }; 
        case ActionType.METRICDATASOURCE_VIEW_ENABLE:
            return {
                ...state,
                ViewMetricDataSourceOpen: true
            };
        case ActionType.METRICDATASOURCE_VIEW_DISABLE:
            return {
                ...state,
                ViewMetricDataSourceOpen: false
            };
        case ActionType.METRICDATASOURCE_ADD_ENABLE:
            return {
                ...state,
                AddMetricDataSourceOpen: true
            };
        case ActionType.METRICDATASOURCE_ADD_DISABLE:
            return {
                ...state,
                AddMetricDataSourceOpen: false
            };
        case ActionType.SET_VIEWNAME_UPDATEVALUE:
            return {
                ...state,
                ConfigurationView: action.view
            };   
        default:
            return state;
    }
};


