import { actionCreators } from './action';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IADOTaskCreationState, IADOAreaPaths } from './types';
import RichTextEditor from 'react-rte';

const initialState = Object.freeze<IADOTaskCreationState>({
    isLoading: false,
    id: '',
    eta: null,
    description: RichTextEditor.createEmptyValue(),
    title: '',
    assignedTo: '',
    areaPath: '',
    approvalTasksDetails: [],
    approvalDisabled: true,
    previousTitle: '',
    errorMsg: '',
    tags: '',
    tagsValue: '',
    areaPathList: [],
    areaPathDropdown: [],
    areaPathSelected: ''
});

export const reducer = (
    state: IADOTaskCreationState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {
        case ActionType.RESET_STATE:
            return initialState;
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false
            };
        case ActionType.SET_AREAPATH:
            return {
                ...state,
                areaPathSelected: action.areaPathSelected,
                areaPath: action.areaPath,
                tagsValue: action.tagsValue
            };
        case ActionType.SET_ASSIGNEDTO:
            return {
                ...state,
                assignedTo: action.assignedTo
            };
        case ActionType.SET_DESCRIPTION:
            return {
                ...state,
                description: action.description
            };
        case ActionType.SET_ETA:
            return {
                ...state,
                eta: action.eta
            };
        case ActionType.SET_TITLE:
            return {
                ...state,
                title: action.title
            };
        case ActionType.SET_TAGS:
            return {
                ...state,
                tagsValue: action.tagsValue
            };
        case ActionType.GET_ADOTAGS_SUCCESS:
            return {
                ...state,
                tags: action.tags
            };
        case ActionType.GET_AREAPATH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                areaPathDropdown: action.areaPathDropdown,
                areaPathList: action.areaPathList
            };
        case ActionType.GET_APPROVALTASKSDETAILS:
            return {
                ...state,
                approvalDisabled: true,
                isLoading: false,
                approvalTasksDetails: action.approvalTasksDetails
            };
        case ActionType.SET_CHECKALL:
            return {
                ...state,
                approvalDisabled: action.approvalDisabled,
                approvalTasksDetails: action.approvalTasksDetails
            };
        case ActionType.UPDATE_CHECKVALUE:
            return {
                ...state,
                approvalDisabled: action.approvalDisabled,
                approvalTasksDetails: action.approvalTasksDetails
            };
        case ActionType.CREATE_ADOTASK:
            return {
                ...state,
                id: action.id,
                previousTitle: action.previousTitle,
                areaPath: { name: '' },
                title: '',
                errorMsg: action.errorMsg,
                description: RichTextEditor.createEmptyValue(),
                assignedTo: '',
                eta: null,
                tagsValue: '',
                isLoading: false
            };
        case ActionType.ADO_TASKCREATION_FAILURE:
            return {
                ...state,
                areaPath: action.areaPath,
                title: action.title,
                description: action.description,
                assignedTo: action.assignedTo,
                eta: action.eta,
                errorMsg: action.errorMsg,
                tagsValue: action.tagsValue,
                isLoading: false
            };
        default:
            return state;
    }
}