import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IServiceGroupState, IPropCalender } from './types';


const initialState = Object.freeze<IServiceGroupState>({
    //DataRefreshed: [],
    serviceGroup: [],
    isLoading: false
});

export const reducer = (
    state: IServiceGroupState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {
        case ActionType.REQUEST:
            const { startGroupIndex } = action;
            return {
                ...state,
                startGroupIndex,
                isLoading: true
            };
        case ActionType.TOGGLE_CHANGE:           
            return {
                ...state,
                serviceGroup: action.serviceGroup               
            };
        case ActionType.RECEIVE:
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly handle out-of-order responses.
            if (action.startGroupIndex === state.startGroupIndex) {
                const { serviceGroup, startGroupIndex } = action;
                return {                  
                    serviceGroup,
                    startGroupIndex,
                    isLoading: false
                };
            }
            return state;
        case ActionType.RESET_STATE:
            return initialState;
        default:
            return state;
    }
};
