export type IDublinSHRState = {
    readonly isLoading: boolean;
    readonly userId: string;
    readonly MeetingDate: Date;
    readonly MaxDate: Date;
    readonly DefaultDate: Date;
    readonly DublinAdminLock: IDublinAdminLock[];  
    readonly ActionItemsReviewData: IPropActionItemsDublinshr[];
    readonly accordiontoggle: boolean;
    readonly isLockedPIR: boolean;
    readonly isLockedPIRArray: Date[];
    readonly isLockedTopic: boolean;
    readonly isLockedTopicArray: Date[];
    readonly PIRdata: IPropAllPirData[];
    readonly SpecialTopicsData: IPropActionItemsDublinshr[];
    readonly isSpecialTopicLockDisabledOnEdit: boolean;
    readonly piraccordiontoggle: boolean;
    readonly isPIRLockDisabledOnEdit: boolean;
    readonly isPIREditenabled: boolean;
    readonly isSpecialTopicEditorAddEnabled: boolean;
    readonly specialaccordiontoggle: boolean;
};

export type IResourceDetail = {
    readonly link: string;
    readonly description: string;
}

export type IPropPublicServiceAnnouncementDublinSHR = {
    readonly id: number;
    readonly presenter: string;
    presenterEdit: string;
    readonly topicName: string;
    topicNameEdit: string;
    readonly timeNeeded: number;
    timeNeededEdit: number;
    readonly presentationLink: string;
    presentationLinkEdit: string;
    readonly isTopicApproved?: boolean;
    isTopicApprovedEdit?: boolean;
    kpiDate: string;
    readonly displayOrder: string;
    displayOrderEdit: string;
    isEdit: boolean;
    readonly occuredRingEdit: string;
    userName: string;
}

export type IDublinAdminLock = {
    readonly id: number;
    readonly lock_Type: number;
    readonly locked_Week: string;
}

export type IPropPresenterTable = {
    readonly id: number;
    readonly serviceName: string;
    serviceNameEdit: string;
    readonly title: string;
    titleEdit: string;
    readonly ttn: string;
    ttnEdit: string;
    readonly commsPirLink: string;
    commsPirLinkEdit: string;
    readonly pirNumber?: number;
    pirNumberEdit?: number;
    readonly displayOrder: string;
    displayOrderEdit: string;
    readonly kpiDate: string;
    isEdit: boolean;
    readonly presenter: string;
    presenterEdit: string;
};

export type IPropActionItemsDublinshr = {
    readonly id: number;
    readonly presenter: string;
    presenterEdit: string;
    readonly topicName: string;
    topicNameEdit: string;
    readonly timeNeeded: number;
    timeNeededEdit: number;
    readonly presentationLink: string;
    presentationLinkEdit: string;
    readonly isTopicApproved?: boolean;
    isTopicApprovedEdit?: boolean;
    readonly kpiDate: string;
    readonly displayOrder: string;
    displayOrderEdit: string;
    isEdit: boolean;
    readonly occuredRingEdit: string;
    userName: string;
};

export type IPropCommsOnlyPir = {
    readonly id: number;
    readonly serviceName: string;
    serviceNameEdit: string;
    readonly title: string;
    titleEdit: string;
    readonly ttn: string;
    ttnEdit: string;
    readonly commsPirLink: string;
    commsPirLinkEdit: string;
    readonly pirNumber?: number;
    pirNumberEdit?: number;
    readonly displayOrder: string;
    displayOrderEdit: string;
    readonly kpiDate: string;
    isEdit: boolean;
    readonly presenter: string;
    presenterEdit: string;
};

export type IPropSpecialTopicsWishr = {
    readonly id: number;
    readonly presenter: string;
    presenterEdit: string;
    readonly topicName: string;
    topicNameEdit: string;
    readonly timeNeeded: number;
    timeNeededEdit: number;
    readonly presentationLink: string;
    presentationLinkEdit: string;
    readonly isTopicApproved?: boolean;
    isTopicApprovedEdit?: boolean;
    kpiDate: string;
    readonly displayOrder: string;
    displayOrderEdit: string;
    isEdit: boolean;
    readonly occuredRingEdit: string;
    userName: string;
};

export type IPropSpecialTopicsDublinWishr = {
    readonly id: number;
    readonly presenter: string;
    presenterEdit: string;
    readonly topicName: string;
    topicNameEdit: string;
    readonly timeNeeded: number;
    timeNeededEdit: number;
    readonly presentationLink: string;
    presentationLinkEdit: string;
    readonly isTopicApproved?: boolean;
    isTopicApprovedEdit?: boolean;
    kpiDate: string;
    readonly displayOrder: string;
    displayOrderEdit: string;
    isEdit: boolean;
    readonly occuredRingEdit: string;
    userName: string;
};

export type IPropAllPirData = {
    readonly id: number;
    readonly serviceName: string;
    readonly servicesImpacted: string;
    readonly summary: string;
    readonly ttd: string;
    readonly ttm: string;
    readonly ttn: string;
    readonly pir: string;
    readonly pirEdit: string;
    readonly isAzure: boolean;
    kpiDate: string;
    userName: string;
    readonly tte: string;
    readonly occuredRing: string;
    readonly occuredRingEdit: string;
    readonly pirRereview: boolean;
    readonly isAddedToAgenda: boolean;
    readonly isValidPir: boolean;
    readonly resources: IResourceDetail[];
    readonly rootCauseCategory: string;
    readonly sharedLearning: string;
    readonly incidentId: string;
    isEdit: boolean;
    readonly displayOrder: string;
    displayOrderEdit: string;
    readonly presenter: string;
    presenterEdit: string;
};

const _namespace = 'DublinSHR';

export interface IActionType {
    readonly RESET_STATE: string;
    readonly SET_MEETINGDATE_DATE: string;
    readonly MEETINGDATE_DATE_CHANGE: string;
    readonly GET_WEEKLYIDSHR_REQUEST: string;
    readonly GET_WEEKLYIDSHR_SUCCESS: string;
    readonly GET_WEEKLYIDSHR_FAILURE: string;
    readonly SET_LOADING_TRUE: string;
    readonly SET_LOADING_FALSE: string;
    readonly ADDED_VISITED_USER: string;
    readonly SET_PIRLOCK: string;
    readonly GET_DUBLINADMINLOCK: string;
    readonly GET_SPECIALTOPIC_REQUEST: string;
    readonly GET_SPECIALTOPIC_SUCCESS: string;
    readonly GET_SPECIALTOPIC_FAILURE: string;
    readonly ENABLE_EDITORADD_PIR: string;
    readonly ENABLE_EDITORADD_SPECIALTOPIC: string;
    readonly SPECIALTOPIC_CHANGE: string;
    readonly SET_SPECIALTOPICLOCK: string;
    readonly GET_ACTION_ITEMS_REVIEW_SUCCESS: string;
    readonly GET_ACTION_ITEMS_REVIEW_FAILURE: string;
    readonly ACTIONREVIEWACCORDION_STATUS: string;
    readonly SPECIALTOPICACCORDION_STATUS: string;
    readonly PIRACCORDION_STATUS: string;
    readonly PIRREREVIEW_CHANGE: string;
}

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/reset`,
    SET_MEETINGDATE_DATE: `${_namespace}/setmeetingdate`,
    MEETINGDATE_DATE_CHANGE: `${_namespace}/meetingdatechange`,
    GET_WEEKLYIDSHR_REQUEST: `${_namespace}/getweelyidshrrequest`,
    GET_WEEKLYIDSHR_SUCCESS: `${_namespace}/getweelyidshrsuccess`,
    GET_WEEKLYIDSHR_FAILURE: `${_namespace}/getweelyidshrfailure`,
    SET_LOADING_TRUE: `${_namespace}/loadingtrue`,
    SET_LOADING_FALSE: `${_namespace}/loadingfalse`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    SET_PIRLOCK: `${_namespace}/setpirlock`,
    GET_DUBLINADMINLOCK: `${_namespace}/getpiradminlock`,
    PIRREREVIEW_CHANGE: `${_namespace}/pirreviewchange`,
    GET_SPECIALTOPIC_REQUEST: `${_namespace}/getspecialtopicrequest`,
    GET_SPECIALTOPIC_SUCCESS: `${_namespace}/getspecialtopicsuccess`,
    GET_SPECIALTOPIC_FAILURE: `${_namespace}/getspecialtopicfailure`,
    ENABLE_EDITORADD_PIR: `${_namespace}/enableeditoradd`,
    ENABLE_EDITORADD_SPECIALTOPIC: `${_namespace}/enableeditoraddspecialtopic`,
    SPECIALTOPIC_CHANGE: `${_namespace}/specialtopicchange`,
    SET_SPECIALTOPICLOCK: `${_namespace}/setspecialtopic`,
    GET_ACTION_ITEMS_REVIEW_SUCCESS: `${_namespace}/getactionitemsreviewsuccess`,
    GET_ACTION_ITEMS_REVIEW_FAILURE: `${_namespace}/getactionitemsreviewfailure`,
    ACTIONREVIEWACCORDION_STATUS: `${_namespace}actionreviewaccordionstatus`,
    SPECIALTOPICACCORDION_STATUS: `${_namespace}specialaccordionstatus`,
    PIRACCORDION_STATUS: `${_namespace}piraccordionstatus`
});

