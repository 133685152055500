import React from 'react';
import { IErrorMsg } from '../../../store/PIRRiskTool';
import { isArrayWithLength } from '../../../utils';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputText } from 'primereact/inputtext';
import {
    Row,
    Col, Button
} from 'reactstrap';

type PIRRiskToolProps = {
    titleValue: any;
    UpdateTitle: any;
    IterationPathValue: any;
    UpdateIterationPath: any;
    workItemTypeValue: any;
    UpdateworkItemType: any;
    tagsValue: any;
    UpdateTags: any;
    stateValue: any;
    UpdateState: any;
    includeLinks: any;
    includeLinkschange: any;
    includeattachments: any;
    includeAttachmentchange: any;
    createCopy: any;
    relations: any;
    isLinksActive: any;
    isAttachmentActive: any;
    errorMsg: any;
    errorMsgList: any;
    descriptionValue: any;
    IDClassificationvalue: any;
    IDClassificationList: any;
    customFields: any;
};

const PIRRiskToolTable = React.memo<PIRRiskToolProps>(({
    titleValue,
    UpdateTitle,
    IterationPathValue,
    UpdateIterationPath,
    workItemTypeValue,
    UpdateworkItemType,
    tagsValue,
    UpdateTags,
    stateValue,
    UpdateState,
    includeLinks,
    includeLinkschange,
    includeattachments,
    includeAttachmentchange,
    createCopy,
    relations,
    isLinksActive,
    isAttachmentActive,
    errorMsg,
    errorMsgList,
    descriptionValue,
    IDClassificationvalue,
    IDClassificationList,
    customFields
}) => {

    return (
        <div>

            <div className="row">
                <div className="col-lg-8">
                    &nbsp;&nbsp;&nbsp;
                    <Row>
                        <Col lg="3">
                            <h5>Title</h5>
                        </Col>
                        <Col lg="6">
                            <InputText style={{ width: '100%', fontWeight: 'bold' }} disabled value={titleValue} onChange={(e) => UpdateTitle(e)} />
                        </Col>
                    </Row>
                    &nbsp;&nbsp;&nbsp;
                    <Row>
                        <Col lg="3">
                            <h5>IterationPath</h5>
                        </Col>
                        <Col lg="6">
                            <InputText style={{ width: '100%', fontWeight: 'bold' }} disabled value={IterationPathValue} onChange={(e) => UpdateIterationPath(e)} />
                        </Col>
                    </Row>
                    &nbsp;&nbsp;&nbsp;
                    <Row>
                        <Col lg="3">
                            <h5>WorkItemType</h5>
                        </Col>
                        <Col lg="6">
                            <InputText style={{ width: '100%', fontWeight: 'bold' }} disabled value={workItemTypeValue} onChange={(e) => UpdateworkItemType(e)} />
                        </Col>
                    </Row>
                    &nbsp;&nbsp;&nbsp;
                    <Row>
                        <Col lg="3">
                            <h5>Tags</h5>
                        </Col>
                        <Col lg="6">
                            <InputText style={{ width: '100%', fontWeight: 'bold' }} disabled value={tagsValue} onChange={(e) => UpdateTags(e)} />
                        </Col>
                    </Row>
                    &nbsp;&nbsp;&nbsp;
                    <Row>
                        <Col lg="3">
                            <h5>State</h5>
                        </Col>
                        <Col lg="6">
                            <InputText style={{ width: '100%', fontWeight: 'bold' }} disabled value={stateValue} onChange={(e) => UpdateState(e)} />
                        </Col>
                    </Row>
                    &nbsp;&nbsp;&nbsp;
                    <Row>
                        <Col lg="3">
                            &nbsp;
                        </Col>
                        <Col lg="6">
                            <span>
                                <input type="checkbox" checked={includeLinks} name="includeLinks" disabled={!isLinksActive} onChange={(e) => includeLinkschange(e)} />
                                &nbsp; Include existing links
                            </span>
                            <span style={{ marginLeft: '2%' }}>
                                <input type="checkbox" checked={includeattachments} name="includeLinks" disabled={!isAttachmentActive} onChange={(e) => includeAttachmentchange(e)} />
                                &nbsp; Include existing attachments
                            </span>
                        </Col>
                    </Row>
                    &nbsp;&nbsp;&nbsp;
                    <Row>
                        <Col lg="3">
                            &nbsp;
                        </Col>
                        <Col lg="6">

                        </Col>
                    </Row>
                </div>
                <div className="col-lg-4" style={{ display: ((IterationPathValue != "" && IterationPathValue != null) && (workItemTypeValue != "" && workItemTypeValue != null)) ? 'inline' : 'none' }}>
                    <Button className="createCopyBtn" onClick={createCopy}> <FontAwesomeIcon icon={faCopy} /> &nbsp; Create copy of work item</Button>
                </div>
            </div>
            <div className="row">
                {isArrayWithLength(errorMsgList)
                    ?
                    <div className="col-lg-8">
                        <Row className="errorMsgHeader">
                            <Col lg="3">
                                <h5>Service Name</h5>
                            </Col>
                            <Col lg="6">
                                <h5>Message</h5>
                            </Col>
                            <Col lg="2">
                                <h5>Status</h5>
                            </Col>
                        </Row>

                        {errorMsgList.map((item: IErrorMsg, index) => (
                            <React.Fragment key={index}>
                                {
                                    <Row>
                                        <Col lg="3">{item["serviceName"]}</Col>
                                        <Col lg="6">{item["errorMessage"]}</Col>
                                        <Col lg="2">{item["status"]}</Col>
                                    </Row>
                                }
                            </React.Fragment>
                        ))}
                    </div>
                    :
                    <React.Fragment>
                    </React.Fragment>
                }
            </div>
        </div>
    )
});

PIRRiskToolTable.displayName = 'PIRRiskToolTable';
export default PIRRiskToolTable;