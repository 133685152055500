import { FunctionReturnTypes, ReduxAction } from '../';
import { actionCreators } from './actions';
import { ActionType, IEmailConfigState } from './types';


const initialState = Object.freeze<IEmailConfigState>({
    isLoading: false,
    selectedServiceId: 0,
    IsdataLoaded: false,
    serviceGroups: [],
    selectedServicegroupval: null,
    services: [],
    selectedServiceval: null,
    LoginuserId: '',
    selectedEmailConfigTypes: null,
    emailConfigDataSourceDetails: [],
    emailConfigDataSourceDetailsRaw: [],

    toEmailList: '',
    ccEmailList: '',

    selectedDatasourcePRI: null,
    serverNamePRI: '',
    databasePRI: '',
    authenticationTypePRI: '',
    commentsPRI: '',
    queryOrUrlPRI: '',

    selectedDatasourceCRI: null,
    serverNameCRI: '',
    databaseCRI: '',
    authenticationTypeCRI: '',
    commentsCRI: '',
    queryOrUrlCRI: '',

    selectedDatasourceDRI: null,
    serverNameDRI: '',
    databaseDRI: '',
    authenticationTypeDRI: '',
    commentsDRI: '',
    queryOrUrlDRI: '',

    selectedDatasourceSEV12: null,
    serverNameSEV12: '',
    databaseSEV12: '',
    authenticationTypeSEV12: '',
    commentsSEV12: '',
    queryOrUrlSEV12: '',

    IsValidPRIQuery:false,
    IsPRIDataSaved: false,

    IsQueryFailed: false,
    IsDataSaved: false,

    IsClearAll: false,

    onboardNewEmailConfigHeatmap: false,
    onboardNewEmailConfigPRI: false,
    onboardNewEmailConfigCRI: false,
    onboardNewEmailConfigDRI: false,
    onboardNewEmailConfigSEV: false,
    ViewEmailConfigDataSourceOpen: true,
    AddEmailConfigDataSourceOpen: false,
    AddEmailConfigPRIDataSourceOpen: false,
    viewSourceDetailsByService: [],
    viewTargetDetailsByService: [],
    emailConfigNames: [],
    datasource: [],

});

export const reducer = (
    state: IEmailConfigState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;
    switch (action.type) {                      
        case '@@router/LOCATION_CHANGE': {
            const { search } = action.payload.location;
            
        }
        case ActionType.RESET_STATE: {
            return initialState;
        }      
        case ActionType.GET_SERVICEGROUPS_FAIL:
            return {
                ...state,
                serviceGroups: [],
                isLoading: (action.count >= 6) ? false : state.isLoading
            };
        case ActionType.GET_SERVICEGROUPS_SUCCESS:
            return {
                ...state,
                serviceGroups: action.serviceGroups,
                isLoading: (action.count >= 6) ? false : state.isLoading
            };
        case ActionType.GET_SERVICELISTS_FAIL:
            return {
                ...state,
                services: [],
                isLoading: (action.count >= 6) ? false : state.isLoading
            };
        case ActionType.GET_SERVICELISTS_SUCCESS:
            return {
                ...state,
                services: action.services,
                selectedServiceval: action.selectedServiceval,
                isLoading: (action.count >= 6) ? false : state.isLoading
            };        
        case ActionType.SERVICEGROUP_CHANGE:
            return {
                ...state,
                toEmailList: '',
                ccEmailList: '',
                selectedServiceval: null,
                selectedEmailConfigTypes: null,
                emailConfigDataSourceDetails: [],
                emailConfigDataSourceDetailsRaw: [],
                viewSourceDetailsByService: [],
                viewTargetDetailsByService: [],
                onboardNewEmailConfigHeatmap: false,
                onboardNewEmailConfigPRI: false,
                onboardNewEmailConfigCRI: false,
                onboardNewEmailConfigDRI: false,
                onboardNewEmailConfigSEV: false,
                IsClearAll: false,
                selectedServicegroupval: action.selectedServicegroupval
            };
        case ActionType.SERVICES_CHANGE:
            return {
                ...state,
                toEmailList: '',
                ccEmailList: '',
                IsClearAll: false,
                selectedDatasourcePRI: null,
                selectedDatasourceCRI: null,
                selectedDatasourceDRI: null,
                selectedDatasourceSEV12: null,
                selectedServiceval: action.selectedServiceval,                               
                selectedServicegroupval: action.selectedServicegroupval
            };
        case ActionType.GET_EMAIL_CONFIG_NAMES_REQUEST:
            return {
                ...state,
                emailConfigDataSourceDetails: [],
                emailConfigDataSourceDetailsRaw: [],
                emailConfigNames:[],
                isLoading: true                                     
            };
        case ActionType.GET_EMAIL_CONFIG_NAMES_SUCCESS:
            return {
                ...state,
                emailConfigNames: action.emailConfigNames,  
                onboardNewEmailConfigHeatmap : action.onboardNewEmailConfigHeatmap,
                onboardNewEmailConfigPRI : action.onboardNewEmailConfigPRI,
                onboardNewEmailConfigCRI : action.onboardNewEmailConfigCRI,
                onboardNewEmailConfigDRI : action.onboardNewEmailConfigDRI,
                onboardNewEmailConfigSEV : action.onboardNewEmailConfigSEV,

                isLoading: false
            };
        case ActionType.GET_EMAIL_CONFIG_NAMES_FAIL:
            return {
                ...state,
                isLoading: false                                          
            };
        case ActionType.GET_VIEW_DETAILS_SUCCESS:
            return {
                ...state,
                toEmailList: action.toEmailList,
                ccEmailList: action.ccEmailList,
                viewSourceDetailsByService: action.viewSourceDetailsByService,                                         
                viewTargetDetailsByService: action.viewTargetDetailsByService                                         
            };
        case ActionType.GET_VIEW_DETAILS_FAIL:
            return {
                ...state,
                isLoading: false                                        
            };  
        case ActionType.GET_EMAIL_CONFIG_DATASOURCE_FAIL:
            return {
                ...state,
                isLoading: false
            };
        case ActionType.RESET_FILTER:
            return {
                ...state,
                selectedServicegroupval: null,   
                emailConfigDataSourceDetails: [],
                emailConfigDataSourceDetailsRaw: [],
            };   
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.CLEAR_CONFIG_DATA_SECTION:
            return {
                ...state,
                selectedServicegroupval: null,
                selectedServiceval: null,
                selectedEmailConfigTypes: null,
                emailConfigDataSourceDetails: [],
                emailConfigDataSourceDetailsRaw: [],
                viewSourceDetailsByService: [],
                viewTargetDetailsByService: [],
                onboardNewEmailConfigHeatmap: false,
                onboardNewEmailConfigPRI: false,
                onboardNewEmailConfigCRI: false,
                onboardNewEmailConfigDRI: false,
                onboardNewEmailConfigSEV: false,
                IsClearAll: action.IsClearAll,
                toEmailList: '',
                ccEmailList: '',
                selectedDatasourcePRI: null,
                selectedDatasourceCRI: null,
                selectedDatasourceDRI: null,
                selectedDatasourceSEV12: null

            };
        case ActionType.EMAIL_CONFIG_DATA_TYPE:
            return {
                ...state,
                selectedEmailConfigTypes: action.selectedEmailConfigTypes
            };
        case ActionType.GET_EMAIL_CONFIG_DATASOURCE_REQUEST:
            return {
                ...state,
                emailConfigDataSourceDetails: [],
                emailConfigDataSourceDetailsRaw: [],
                isLoading: true
            };
        case ActionType.GET_EMAIL_CONFIG_DATASOURCE_FAIL:
            return {
                ...state,
                emailConfigDataSourceDetails: [],
                emailConfigDataSourceDetailsRaw: [],
                isLoading: false
            };
        case ActionType.GET_EMAIL_CONFIG_DATASOURCE_SUCCESS:
            return {
                ...state,
                emailConfigDataSourceDetails: action.emailConfigDataSourceDetails,
                emailConfigDataSourceDetailsRaw: action.emailConfigDataSourceDetailsRaw,

                toEmailList: action.toEmailList,
                ccEmailList: action.ccEmailList,


                selectedDatasourcePRI:  action.selectedDatasourcePRI,
                serverNamePRI: action.serverNamePRI,
                databasePRI: action.databasePRI,
                authenticationTypePRI: action.authenticationTypePRI,
                queryOrUrlPRI: action.queryOrUrlPRI,
                commentsPRI: action.commentsPRI,

                selectedDatasourceCRI: action.selectedDatasourceCRI,
                serverNameCRI: action.serverNameCRI,
                databaseCRI: action.databaseCRI,
                authenticationTypeCRI: action.authenticationTypeCRI,
                queryOrUrlCRI: action.queryOrUrlCRI,
                commentsCRI: action.commentsCRI,

                selectedDatasourceDRI: action.selectedDatasourceDRI,
                serverNameDRI: action.serverNameDRI,
                databaseDRI: action.databaseDRI,
                authenticationTypeDRI: action.authenticationTypeDRI,
                queryOrUrlDRI: action.queryOrUrlDRI,
                commentsDRI: action.commentsDRI,

                selectedDatasourceSEV12: action.selectedDatasourceSEV12,
                serverNameSEV12: action.serverNameSEV12,
                databaseSEV12: action.databaseSEV12,
                authenticationTypeSEV12: action.authenticationTypeSEV12,
                queryOrUrlSEV12: action.queryOrUrlSEV12,
                commentsSEV12: action.commentsSEV12,

                isLoading: false
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_VIEW_ENABLE:
            return {
                ...state,
                ViewEmailConfigDataSourceOpen: true
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_VIEW_DISABLE:
            return {
                ...state,
                ViewEmailConfigDataSourceOpen: false
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_ADD_ENABLE:
            return {
                ...state,
                AddEmailConfigDataSourceOpen: true
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_ADD_DISABLE:
            return {
                ...state,
                AddEmailConfigDataSourceOpen: false
            };
        case ActionType.EMAIL_CONFIG_PRI_DATASOURCE_ADD_ENABLE:
            return {
                ...state,
                AddEmailConfigPRIDataSourceOpen: true
            };
        case ActionType.EMAIL_CONFIG_PRI_DATASOURCE_ADD_DISABLE:
            return {
                ...state,
                AddEmailConfigPRIDataSourceOpen: false
            };

        case ActionType.EMAIL_CONFIG_DATASOURCE_SERVER_CHANGE_UPDATE_PRI:
            return {
                ...state,
                serverNamePRI: action.serverNamePRI
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_TO_EMAIL_CHANGE_UPDATE:
            return {
                ...state,
                toEmailList: action.toEmailList
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_CC_EMAIL_CHANGE_UPDATE:
            return {
                ...state,
                ccEmailList: action.ccEmailList
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_DATABASE_CHANGE_UPDATE_PRI:
            return {
                ...state,
                databasePRI: action.databasePRI
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_AUTHENTICATIONTYPE_CHANGE_UPDATE_PRI:
            return {
                ...state,
                authenticationTypePRI: action.authenticationTypePRI
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_QUERYORURL_CHANGE_UPDATE_PRI:
            return {
                ...state,
                queryOrUrlPRI: action.queryOrUrlPRI
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_COMMENTS_CHANGE_UPDATE_PRI:
            return {
                ...state,
                commentsPRI: action.commentsPRI
            };

        case ActionType.EMAIL_CONFIG_DATASOURCE_SERVER_CHANGE_UPDATE_CRI:
            return {
                ...state,
                serverNameCRI: action.serverNameCRI
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_DATABASE_CHANGE_UPDATE_CRI:
            return {
                ...state,
                databaseCRI: action.databaseCRI
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_AUTHENTICATIONTYPE_CHANGE_UPDATE_CRI:
            return {
                ...state,
                authenticationTypeCRI: action.authenticationTypeCRI
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_QUERYORURL_CHANGE_UPDATE_CRI:
            return {
                ...state,
                queryOrUrlCRI: action.queryOrUrlCRI
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_COMMENTS_CHANGE_UPDATE_CRI:
            return {
                ...state,
                commentsCRI: action.commentsCRI
            };

        case ActionType.EMAIL_CONFIG_DATASOURCE_SERVER_CHANGE_UPDATE_DRI:
            return {
                ...state,
                serverNameDRI: action.serverNameDRI
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_DATABASE_CHANGE_UPDATE_DRI:
            return {
                ...state,
                databaseDRI: action.databaseDRI
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_AUTHENTICATIONTYPE_CHANGE_UPDATE_DRI:
            return {
                ...state,
                authenticationTypeDRI: action.authenticationTypeDRI
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_QUERYORURL_CHANGE_UPDATE_DRI:
            return {
                ...state,
                queryOrUrlDRI: action.queryOrUrlDRI
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_COMMENTS_CHANGE_UPDATE_DRI:
            return {
                ...state,
                commentsDRI: action.commentsDRI
            };

        case ActionType.EMAIL_CONFIG_DATASOURCE_SERVER_CHANGE_UPDATE_SEV12:
            return {
                ...state,
                serverNameSEV12: action.serverNameSEV12
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_DATABASE_CHANGE_UPDATE_SEV12:
            return {
                ...state,
                databaseSEV12: action.databaseSEV12
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_AUTHENTICATIONTYPE_CHANGE_UPDATE_SEV12:
            return {
                ...state,
                authenticationTypeSEV12: action.authenticationTypeSEV12
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_QUERYORURL_CHANGE_UPDATE_SEV12:
            return {
                ...state,
                queryOrUrlSEV12: action.queryOrUrlSEV12
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_COMMENTS_CHANGE_UPDATE_SEV12:
            return {
                ...state,
                commentsSEV12: action.commentsSEV12
            };
        case ActionType.VALIDATE_PRI_DATASOURCE_SUCCESS:
            return {
                ...state,
                IsValidPRIQuery: action.IsValidPRIQuery
            };
        case ActionType.VALIDATE_PRI_DATASOURCE_FAIL:
            return {
                ...state,
                isLoading: false,
                IsValidPRIQuery: action.IsValidPRIQuery
            };
        case ActionType.PRI_DATASOURCE_SAVE_SUCCESS:
            return {
                ...state,
                IsPRIDataSaved: action.IsPRIDataSaved
            };
        case ActionType.PRI_DATASOURCE_SAVE_FAIL:
            return {
                ...state,
                isLoading: false,
                IsPRIDataSaved: action.IsPRIDataSaved
            };
        case ActionType.VALIDATE_DATASOURCE_SUCCESS:
            return {
                ...state,
                IsQueryFailed: action.IsQueryFailed
            };
        case ActionType.VALIDATE_DATASOURCE_FAIL:
            return {
                ...state,
                isLoading: false,
                IsQueryFailed: action.IsQueryFailed
            };
        case ActionType.DATASOURCE_SAVE_SUCCESS:
            return {
                ...state,
                IsDataSaved: action.IsDataSaved
            };
        case ActionType.DATASOURCE_SAVE_FAIL:
            return {
                ...state,
                isLoading: false,
                IsDataSaved: action.IsDataSaved
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_ONBOARD_CHANGE:
            return {
                ...state,
                onboardNewEmailConfigHeatmap: action.onboardNewEmailConfigHeatmap,               
            };  
        case ActionType.EMAIL_CONFIG_DATASOURCE_ONBOARD_PRI_CHANGE:
            return {
                ...state,
                onboardNewEmailConfigPRI: action.onboardNewEmailConfigPRI,
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_ONBOARD_CRI_CHANGE:
            return {
                ...state,
                onboardNewEmailConfigCRI: action.onboardNewEmailConfigCRI,
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_ONBOARD_DRI_CHANGE:
            return {
                ...state,
                onboardNewEmailConfigDRI: action.onboardNewEmailConfigDRI,
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_ONBOARD_SEV_CHANGE:
            return {
                ...state,
                onboardNewEmailConfigSEV: action.onboardNewEmailConfigSEV,
            };
        
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false
            };
        case ActionType.ENABLE_EDIT_OR_ADD_EMAIL_CONFIG_DATASOURCE_PRI:
            return {
                ...state,
                serverNamePRI: action.serverNamePRI,
                authenticationTypePRI: action.authenticationTypePRI,
                queryOrUrlPRI: action.queryOrUrlPRI,  
                commentsPRI: action.commentsPRI,
                databasePRI: action.databasePRI
            }; 
        case ActionType.CLEAR_EMAIL_CONFIG_DATASOURCE_SECTION_HEATMAP:
            return {
                ...state,
                viewSourceDetailsByService: action.viewSourceDetailsByService,
                viewTargetDetailsByService: []               
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_CHANGE_PRI:
            return {
                ...state,
                selectedDatasourcePRI: action.selectedDatasourcePRI
            };
        case ActionType.CLEAR_EMAIL_CONFIG_DATASOURCE_SECTION_PRI:
            return {
                ...state,
                selectedDatasourcePRI: null,
                serverNamePRI: '',
                authenticationTypePRI: '',
                queryOrUrlPRI: '',                
                commentsPRI: '',
                databasePRI: ''
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_CHANGE_CRI:
            return {
                ...state,
                selectedDatasourceCRI: action.selectedDatasourceCRI
            };
        case ActionType.ENABLE_EDIT_OR_ADD_EMAIL_CONFIG_DATASOURCE_CRI:
            return {
                ...state,
                serverNameCRI: action.serverNameCRI,
                authenticationTypeCRI: action.authenticationTypeCRI,
                queryOrUrlCRI: action.queryOrUrlCRI,
                commentsCRI: action.commentsCRI,
                databaseCRI: action.databaseCRI
            };
        case ActionType.CLEAR_EMAIL_CONFIG_DATASOURCE_SECTION_CRI:
            return {
                ...state,
                selectedDatasourceCRI: null,
                serverNameCRI: '',
                authenticationTypeCRI: '',
                queryOrUrlCRI: '',
                commentsCRI: '',
                databaseCRI: ''
            };
        case ActionType.EMAIL_CONFIG_DATASOURCE_CHANGE_DRI:
            return {
                ...state,
                selectedDatasourceDRI: action.selectedDatasourceDRI
            };
        case ActionType.ENABLE_EDIT_OR_ADD_EMAIL_CONFIG_DATASOURCE_DRI:
            return {
                ...state,
                serverNameDRI: action.serverNameDRI,
                authenticationTypeDRI: action.authenticationTypeDRI,
                queryOrUrlDRI: action.queryOrUrlDRI,
                commentsDRI: action.commentsDRI,
                databaseDRI: action.databaseDRI
            };
        case ActionType.CLEAR_EMAIL_CONFIG_DATASOURCE_SECTION_DRI:
            return {
                ...state,
                selectedDatasourceDRI: null,
                serverNameDRI: '',
                authenticationTypeDRI: '',
                queryOrUrlDRI: '',
                commentsDRI: '',
                databaseDRI: ''
            };

        case ActionType.EMAIL_CONFIG_DATASOURCE_CHANGE_SEV12:
            return {
                ...state,
                selectedDatasourceSEV12: action.selectedDatasourceSEV12
            };
        case ActionType.ENABLE_EDIT_OR_ADD_EMAIL_CONFIG_DATASOURCE_SEV12:
            return {
                ...state,
                serverNameSEV12: action.serverNameSEV12,
                authenticationTypeSEV12: action.authenticationTypeSEV12,
                queryOrUrlSEV12: action.queryOrUrlSEV12,
                commentsSEV12: action.commentsSEV12,
                databaseSEV12: action.databaseSEV12
            };
        case ActionType.CLEAR_EMAIL_CONFIG_DATASOURCE_SECTION_SEV12:
            return {
                ...state,
                selectedDatasourceSEV12: null,
                serverNameSEV12: '',
                authenticationTypeSEV12: '',
                queryOrUrlSEV12: '',
                commentsSEV12: '',
                databaseSEV12: ''
            };
        case ActionType.GET_EMAIL_CONFIG_DATASOURCE_NAME_SUCCESS:
            return {
                ...state,
                datasource: action.datasource,
                isLoading: false
            };
        case ActionType.GET_EMAIL_CONFIG_DATASOURCE_NAME_FAIL:
            return {
                ...state,
                datasource: [],
                isLoading: false
            };
        default:
            return state;
    }
};


