import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IAdminSettingsState, PropAdminMetrics, metricObject, PropConfig, PropTarget, PropUsers, PropMetricChanges, PropEditHistory, PropMetricsChangeHistory, PropHeatMapMissingViews, PropCmMetric } from './types';

const initialState = Object.freeze<IAdminSettingsState>({
    services: [],
    isLoading: false,
    adminSettingsData: [],
    Isdataloaded: true,
    activeDailyDataTab: 'serviceConfiguration',
    selectedServicesval: null,
    selectedCrServicesval: null,
    selectedCMMetricGroupsval: null,
    selectedEnvironmentval: null,
    selectedHeatmapServiceval: null,
    selectedhistoryServicesval: null,
    selectedhistoryMetricGroupsval: null,
    serviceNames: [],
    metricGroups: [],
    CMMetricGroups: [],
    CMEnvirnoments: [],
    CMData: [],
    CMScoreCardData: [],
    selectedMetricGroupsval: null,
    adminMetrics: [],
    adminConfig: [],
    missingViewInfo: [],
    heatMapHistoryData: [],
    editHistory: [],
    metricChange: [],
    selectedCrMetricsval: null,
    targetDetails: [],
    selectedtargetDetailsval: null,
    crservice: "",
    crcomments: "",
    crrequestedBy: "",
    crcreatedBy: "",
    crtitle: "",
    crrequirment_Id: "",
    crnewValue: "",
    historyFrmCalVal: "",
    historyToCalVal: "",
    heatmapFromCalVal: "",
    heatmapToCalVal: "",
    newMetricName: "",
    newMetricDisplayName: "",
    newMetricGroup: "",
    newMetricEnvironment: "",
    metricConfigMetricId: 0,
    metricConfigGroupName: "",
    isEditableMetricConfig: false,
    isMetricGraphMetricConfig: false,
    minRangeMetricConfig: "",
    maxRangeMetricConfig: "",
    intervalMetricConfig: "",
    validationExprMetricConfig: "",
    targetUpdateApprovalRequestData: []
});

export const reducer = (
    state: IAdminSettingsState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {
        case ActionType.RESET_STATE:
            return initialState;
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true

            };
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false

            };
        case ActionType.GET_SERVICE_GROUP:
            return {
                ...state,
                activeDailyDataTab: action.tab,
                services: action.serviceGroup,
                isLoading: false,
                serviceNames: action.serviceNames
            };
        case ActionType.SET_ADMINDATA_ACTIVETAB:
            return {
                ...state,
                activeDailyDataTab: action.tab
            };
        case ActionType.GET_SERVICE_CONFIGURATION:
            return {
                ...state,
                serviceNames: action.serviceNames,
                isLoading: false,
                services: action.serviceGroup
            };
        case ActionType.SERVICE_CHANGE:
            return {
                ...state,
                selectedServicesval: action.selectedServicesval
            };
        case ActionType.CR_SERVICE_CHANGE:
            return {
                ...state,
                selectedCrServicesval: action.selectedCrServicesval
            };
        case ActionType.HEATMAP_SERVICE_CHANGE:
            return {
                ...state,
                heatMapHistoryData: action.heatMapHistoryData,
                selectedHeatmapServiceval: action.selectedHeatmapServiceval,
                isLoading: false
            };
        case ActionType.CR_METRICGROUP_CHANGE:
            return {
                ...state,
                selectedMetricGroupsval: action.selectedMetricGroupsval
            };
        case ActionType.GET_METRIC_GROUPS:
            return {
                ...state,
                activeDailyDataTab: action.tab,
                metricGroups: action.metricGroups
            };
        case ActionType.GET_ADMIN_METRICS:
            return {
                ...state,
                isLoading: false,
                adminMetrics: action.adminMetrics
            };
        case ActionType.GET_ADMIN_CONFIG:
            return {
                ...state,
                activeDailyDataTab: action.tab,
                adminConfig: action.adminConfig,
                isLoading: false
            };
        case ActionType.GET_UPDATEDADMIN_CONFIG:
            return {
                ...state,
                adminConfig: action.adminConfig
            };
        case ActionType.GET_TARGET_DETAILS:
            return {
                ...state,
                isLoading: false,
                targetDetails: action.targetDetails
            };
        case ActionType.GET_USERS:
            return {
                ...state,
                userDetails: action.userDetails
            };
        case ActionType.GET_EDIT_HISTORY:
            return {
                ...state,
                activeDailyDataTab: action.tab,
                metricGroups: action.metricGroups,
                historyFrmCalVal: action.historyFrmCalVal,
                historyToCalVal: action.historyToCalVal,
                selectedhistoryServicesval: action.selectedhistoryServicesval,
                selectedhistoryMetricGroupsval: action.selectedhistoryMetricGroupsval,
                editHistory: action.editHistory,
                isLoading: false
            };
        case ActionType.GET_METRIC_CHANGES:
            return {
                ...state,
                isLoading: false,
                metricChange: action.metricChange
            };
        case ActionType.CR_METRIC_CHANGE:
            return {
                ...state,
                selectedCrMetricsval: action.selectedCrMetricsval
            };
        case ActionType.UPDATE_REQUSTEDBY_VALUES:
            return {
                ...state,
                crrequestedBy: action.crrequestedBy
            };
        case ActionType.UPDATE_TITLE_VALUES:
            return {
                ...state,
                crtitle: action.crtitle
            };
        case ActionType.UPDATE_REQUIREMENT_VALUES:
            return {
                ...state,
                crrequirment_Id: action.crrequirment_Id
            };
        case ActionType.UPDATE_COMMENT_VALUES:
            return {
                ...state,
                crcomments: action.crcomments
            };
        case ActionType.HISTORY_FROM_CHANGE:
            return {
                ...state,
                editHistory: action.editHistory,
                historyFrmCalVal: action.historyFrmCalVal,
                isLoading: false
            };
        case ActionType.HISTORY_TO_CHANGE:
            return {
                ...state,
                editHistory: action.editHistory,
                historyToCalVal: action.historyToCalVal,
                isLoading: false
            };
        case ActionType.HEATMAP_FROM_CHANGE:
            return {
                ...state,
                heatmapFromCalVal: action.heatmapFromCalVal,
                heatMapHistoryData: action.heatMapHistoryData,
                isLoading: false
            };
        case ActionType.HEATMAP_TO_CHANGE:
            return {
                ...state,
                heatmapToCalVal: action.heatmapToCalVal,
                heatMapHistoryData: action.heatMapHistoryData,
                isLoading: false
            };
        case ActionType.GET_CAPACITY_METRICS:
            return {
                ...state,
                activeDailyDataTab: action.tab,
                CMData: action.CMData
            };

        case ActionType.GET_CAPACITY_METRIC_GROUP:
            return {
                ...state,
                CMMetricGroups: action.CMMetricGroups,
                CMData: action.CMData
            };
        case ActionType.GET_SCORECARD_DATA:
            return {
                ...state,
                CMScoreCardData: action.CMScoreCardData
            };
        case ActionType.GET_METRIC_ENVIRONMENT:
            return {
                ...state,
                isLoading: false,
                CMEnvirnoments: action.CMEnvirnoments,
                CMData: action.CMData
            };
        case ActionType.CM_METRICGROUP_CHANGE:
            return {
                ...state,
                CMData: action.CMData
            };
        case ActionType.UPDATED_CM_METRICS:
            return {
                ...state,
                CMData: action.CMData
            };      
        case ActionType.DELETE_CM_METRICS:
            return {
                ...state,
                CMData: action.CMData
            };
        case ActionType.CM_ENVIRONMENT_CHANGE:
            return {
                ...state,
                CMData: action.CMData
            };
        case ActionType.CR_TARGET_CHANGE:
            return {
                ...state,
                selectedtargetDetailsval: action.selectedtargetDetailsval
            };
        case ActionType.GET_MISSINGHEATMAP_DATA:
            return {
                ...state,
                activeDailyDataTab: action.tab,
                missingViewInfo: action.missingViewInfo,
                isLoading: false
            };
        case ActionType.GET_HEATMAPHISTORY_DATA:
            return {
                ...state,
                heatmapFromCalVal: action.heatmapFromCalVal,
                heatmapToCalVal: action.heatmapToCalVal,
                selectedHeatmapServiceval: action.selectedHeatmapServiceval,
                activeDailyDataTab: action.tab,
                heatMapHistoryData: action.heatMapHistoryData,
                isLoading: false
            };
        case ActionType.HISTORY_SERVICE_CHANGE:
            return {
                ...state,
                editHistory: action.editHistory,
                isLoading: false,
                selectedhistoryServicesval: action.selectedhistoryServicesval
            };
        case ActionType.HISTORY_METRICGROUP_CHANGE:
            return {
                ...state,
                selectedhistoryMetricGroupsval: action.selectedhistoryMetricGroupsval
            };
        case ActionType.CM_NEWMETRIC_ADD:
            return {
                ...state,
                newMetricName: action.newMetricName,
                newMetricDisplayName: action.newMetricDisplayName,
                newMetricEnvironment: action.newMetricEnvironment,
                newMetricGroup: action.newMetricGroup
            };
        case ActionType.HEATMAPCHANGEREQUESTDATA:
            return {
                ...state,
                isLoading: false,
                activeDailyDataTab: action.tab,
                targetUpdateApprovalRequestData: action.targetUpdateApprovalRequestData
            };
        case ActionType.EMAILCONFIRMATION:
            return {
                ...state,
                isLoading: false
            };
        case ActionType.CM_INSERT_METRIC:
            return {
                ...state,
                CMData: action.CMData
            };
        case ActionType.METRIC_ISEDIT_CHANGE:
            return {
                ...state,
                adminMetrics: action.adminMetrics
            };
        case ActionType.METRIC_ISGRAPH_CHANGE:
            return {
                ...state,
                adminMetrics: action.adminMetrics
            };
        case ActionType.METRIC_VALIDEXP_CHANGE:
            return {
                ...state,
                adminMetrics: action.adminMetrics
            };
        case ActionType.METRIC_MAXRANGE_CHANGE:
            return {
                ...state,
                adminMetrics: action.adminMetrics
            };
        case ActionType.METRIC_MINRANGE_CHANGE:
            return {
                ...state,
                adminMetrics: action.adminMetrics
            };
        case ActionType.METRIC_INTERVAL_CHANGE:
            return {
                ...state,
                adminMetrics: action.adminMetrics
            };
        case ActionType.METRIC_MINRANGE_UPDATE:
            return {
                ...state,
                isEditableMetricConfig: action.isEditableMetricConfig,
                isMetricGraphMetricConfig: action.isMetricGraphMetricConfig,
                minRangeMetricConfig: action.minRangeMetricConfig,
                maxRangeMetricConfig: action.maxRangeMetricConfig,
                intervalMetricConfig: action.intervalMetricConfig,
                validationExprMetricConfig: action.validationExprMetricConfig
            };
        default:
            return state;
    }

};
