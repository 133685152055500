import { IAppThunkAction, ReduxAction } from '../';
import { WZTNASHRAPI, CommonAPI } from '../../api';
import { isNullOrUndefined } from '../../utils';
import { ActionType, IPropAllPirData, IPropSpecialTopicsWishr, IPropAllPirDataReReview, IPropCommsOnlyPir, IPIRAdminLock, IPropFundamentalsSpotlightWishr, IPropActionItemsReviewWishr, IPropPublicServiceAnnouncementWishr } from './types';
import moment from 'moment';

export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    setloadingtrue: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
    },
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }

    },
    setloadingfalse: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_FALSE
        });
    },
    GetMeetingDate: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let userId = getState().authUser.login;
        const meetingdate = getMeetingDate();
        const maxdate = getMaxDate();
        dispatch({
            meetingdate,
            maxdate,
            userId,
            type: ActionType.SET_MEETINGDATE_DATE
        });
    },
    EditPIRRow: (val: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const weeklydata = getState().WZTNASHR.weeklyIDSHRData;
        const weekly = [];
        weeklydata.map((obj) => {
            if (obj.id === val) {
                obj.isEdit = true;
                obj.pirEdit = obj.pir;
                obj.occuredRingEdit = obj.occuredRing;
                obj.displayOrderEdit = obj.displayOrder;
                obj.presenterEdit = obj.presenter;
            }
            else {
                obj.isEdit = false;
            }
            if (obj.id > 0)
                weekly.push(obj);
        })
        dispatch({
            weeklyIDSHRData: weekly,
            isPIRLockDisabledOnEdit: true,
            type: ActionType.ENABLE_EDITORADD_PIR
        });
    },
    CancelEditPIRRow: (val: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const weeklydata = getState().WZTNASHR.weeklyIDSHRData;
        const weekly = [];
        weeklydata.map((obj) => {
            obj.isEdit = false;
            obj.pirEdit = obj.pir;
            obj.occuredRingEdit = obj.occuredRing;
            obj.displayOrderEdit = obj.displayOrder;
            if (obj.id > 0)
                weekly.push(obj);
        })
        let piraccordiontoggleStatus = weeklydata.length > 0 ? true : false;
        dispatch({
            weeklyIDSHRData: weekly,
            count: 4,
            isPIRLockDisabledOnEdit: false,
            piraccordiontoggle: true,
            type: ActionType.GET_WEEKLYIDSHR_SUCCESS
        });
    },
    OnPIRChangevalues: (e, id): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const weeklydata = getState().WZTNASHR.weeklyIDSHRData;
        let { value, name } = e.target;
        const weekly = [];
        if (value === undefined)
            value = '';
        weeklydata.map((obj) => {
            if (obj.id === id) {
                obj[name] = value;

            }
            weekly.push(obj);
        })
        dispatch({
            weeklyIDSHRData: weekly,
            type: ActionType.PIRREREVIEW_CHANGE
        });
    },
    EnableAddNewRowforPIR: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const weeklydata = getState().WZTNASHR.weeklyIDSHRData;
        const weekly = [];
        weeklydata.map((obj) => {
            obj.isEdit = false;
            obj.pirEdit = obj.pir;
            obj.occuredRingEdit = obj.occuredRing;
            obj.displayOrderEdit = obj.displayOrder;
            if (obj.id > 0)
                weekly.push(obj);
        })
        let NewPIR = {
            id: 0,
            serviceName: "",
            servicesImpacted: '',
            summary: '',
            ttd: '',
            ttm: '',
            ttn: '',
            tte: '',
            pir: '',
            isAzure: false,
            kpiDate: '',
            userName: '',
            occuredRing: '',
            pirRereview: false,
            isAddedToAgenda: false,
            isValidPir: false,
            rootCauseCategory: null,
            incidentId: '',
            displayOrderEdit: weekly.length + 1,
            resources: null,
            sharedLearning: '',
            isEdit: true,
            occuredRingEdit: '',
            pirEdit: ''
        }
        weekly.push(NewPIR);
        dispatch({
            weeklyIDSHRData: weekly,
            isPIRLockDisabledOnEdit: true,
            type: ActionType.ENABLE_EDITORADD_PIR
        });
    },
    EditSpecialTopicRow: (val: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SpecialTopicsData = getState().WZTNASHR.SpecialTopicsData;
        const spltopic = [];
        SpecialTopicsData.map((obj: IPropSpecialTopicsWishr) => {
            if (obj.id === val) {
                obj.isEdit = true;
                obj.presenterEdit = obj.presenter;
                obj.topicNameEdit = obj.topicName;
                obj.timeNeededEdit = obj.timeNeeded;
                obj.presentationLinkEdit = obj.presentationLink;
                obj.isTopicApprovedEdit = obj.isTopicApproved;
                obj.displayOrderEdit = obj.displayOrder;
            }
            else {
                obj.isEdit = false;
            }
            if (obj.id > 0)
                spltopic.push(obj);
        })
        dispatch({
            SpecialTopicsData: spltopic,
            isSpecialTopicLockDisabledOnEdit: true,
            type: ActionType.ENABLE_EDITORADD_SPECIALTOPIC
        });
    },
    EnableAddNewRowforSpecialTopics: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SpecialTopicsData = getState().WZTNASHR.SpecialTopicsData;
        const spltopic = [];
        SpecialTopicsData.map((obj: IPropCommsOnlyPir) => {
            if (obj.id > 0) {
                if (obj.isEdit = true)
                    obj.isEdit = false;
                spltopic.push(obj);
            }
        })
        let NewCOPIR = {
            id: 0,
            presenterEdit: '',
            topicNameEdit: '',
            timeNeededEdit: '',
            presentationLinkEdit: '',
            isTopicApprovedEdit: false,
            displayOrderEdit: spltopic.length + 1,
            isEdit: true
        }
        spltopic.push(NewCOPIR);
        dispatch({
            SpecialTopicsData: spltopic,
            isSpecialTopicLockDisabledOnEdit: true,
            type: ActionType.ENABLE_EDITORADD_SPECIALTOPIC
        });
    },
    CancelEditSpecialTopicRow: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SpecialTopicsData = getState().WZTNASHR.SpecialTopicsData;
        const spltopic = [];
        SpecialTopicsData.map((obj) => {
            obj.isEdit = false;
            obj.presenterEdit = obj.presenter;
            obj.topicNameEdit = obj.topicName;
            obj.timeNeededEdit = obj.timeNeeded;
            obj.presentationLinkEdit = obj.presentationLink;
            obj.isTopicApprovedEdit = obj.isTopicApproved;
            obj.displayOrderEdit = obj.displayOrder;
            if (obj.id > 0)
                spltopic.push(obj);
        })
        let specialaccordiontoggleStatus = SpecialTopicsData.length > 0 ? true : false;
        dispatch({
            SpecialTopicsData: spltopic,
            count: 4,
            isSpecialTopicLockDisabledOnEdit: false,
            specialaccordiontoggle: true,
            type: ActionType.GET_SPECIALTOPIC_SUCCESS
        });
    },
    OnPIRSpecialTopicChangevalues: (e, id): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SpecialTopicsData = getState().WZTNASHR.SpecialTopicsData;
        const spltopic = [];
        let { value, name, checked, type } = e.target;
        if (value === undefined)
            value = '';
        SpecialTopicsData.map((obj: IPropCommsOnlyPir) => {

            if (obj.id === id) {
                if (type === 'checkbox') {
                    obj[name] = checked;
                }
                else {
                    obj[name] = value;
                }
            }


            spltopic.push(obj);
        })
        dispatch({
            SpecialTopicsData: spltopic,
            type: ActionType.SPECIALTOPIC_CHANGE
        });
    },
    checkIsPirRereviewUser: (username): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        WZTNASHRAPI.IsPirRereviewUser(username)
            .then((IsPirRereviewUser: boolean) => {
                dispatch({
                    IsPirRereviewUser: IsPirRereviewUser,
                    type: ActionType.REREVIEWER_CHECK
                });
            }).catch(error => {
                dispatch({
                    IsPirRereviewUser: false,
                    type: ActionType.REREVIEWER_CHECK
                });
            });
    },
    setaccordiontoggledata: (status): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            accordiontoggle: status,
            type: ActionType.ACTIONREVIEWACCORDION_STATUS
        });
    },
    setfunaccordiontoggledata: (status): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            funaccordiontoggle: status,
            type: ActionType.FUNDAMENTALACCORDION_STATUS
        });
    },
    setpsaccordiontoggledata: (status): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            psaaccordiontoggle: status,
            type: ActionType.PSAACCORDION_STATUS
        });
    },
    setspecialaccordiontoggledata: (status): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            specialaccordiontoggle: status,
            type: ActionType.SPECIALTOPICACCORDION_STATUS
        });
    },
    setpiraccordiontoggledata: (status): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            piraccordiontoggle: status,
            type: ActionType.PIRACCORDION_STATUS
        });
    },
    GetWeeklyIDSHRPostIncidentsData: (Meetingdate: string, Callcount): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let userId = getState().authUser.login;
        dispatch({
            userId,
            type: ActionType.GET_WEEKLYIDSHR_REQUEST
        });
        let count = 0;
        if (Callcount != 4) {
            count = 4;
        }
        if (Callcount === 1 || Callcount === 4) {
            //==================== to get data for Post incident Reviews =================//
            WZTNASHRAPI.GetWeeklySHRData(Meetingdate)
                .then((weeklyIDSHRData: IPropAllPirData[]) => {
                    count = count + 1;
                    let piraccordiontoggleStatus = weeklyIDSHRData.length > 0 ? true : false;
                    dispatch({
                        weeklyIDSHRData,
                        isPIRLockDisabledOnEdit: false,
                        count: count,
                        piraccordiontoggle: true,
                        type: ActionType.GET_WEEKLYIDSHR_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_WEEKLYIDSHR_FAILURE
                    });
                });
        }
        if (Callcount === 2 || Callcount === 4) {
            //==================== to get data for PUBLIC SERVICE ANNOUNCEMENT =================//
            WZTNASHRAPI.GetPubServiceAccWishrData(Meetingdate)
                .then((PublicServiceAnnouncementData: IPropPublicServiceAnnouncementWishr[]) => {
                    count = count + 1;
                    let psaaccordiontoggleStatus = PublicServiceAnnouncementData.length > 0 ? true : false;
                    dispatch({
                        PublicServiceAnnouncementData,
                        count: count,
                        psaaccordiontoggle: true,
                        type: ActionType.GET_PUBLICSERVICEACC_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_PUBLICSERVICEACC_FAILURE
                    });
                });

        }
        if (Callcount === 3 || Callcount === 4) {
            //==================== to get data for Special Topics =================//
            WZTNASHRAPI.GetSpecialTopicsWishrData(Meetingdate)
                .then((SpecialTopicsData: IPropSpecialTopicsWishr[]) => {
                    count = count + 1;
                    let specialaccordiontoggleStatus = SpecialTopicsData.length > 0 ? true : false;
                    dispatch({
                        SpecialTopicsData,
                        count: count,
                        specialaccordiontoggle: true,
                        type: ActionType.GET_SPECIALTOPIC_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_SPECIALTOPIC_FAILURE
                    });
                });

        }
        if (Callcount === 4 || Callcount === 6) {
            WZTNASHRAPI.GetFundamentalsSpotlightWishrData(Meetingdate)
                .then((FundamentalsSpotlightData: IPropFundamentalsSpotlightWishr[]) => {
                    count = count + 1;
                    let funaccordiontoggleStatus = FundamentalsSpotlightData.length > 0 ? true : false;
                    dispatch({
                        FundamentalsSpotlightData,
                        count: count,
                        funaccordiontoggle: true,
                        type: ActionType.GET_FUNDAMENTALS_SPOTLIGHT_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_FUNDAMENTALS_SPOTLIGHT_FAILURE
                    });
                });           
        }
        if (Callcount === 4 || Callcount === 7) {
            WZTNASHRAPI.GetActionItemsReviewWZTNAshrData(Meetingdate)
                .then((ActionItemsReviewData: IPropActionItemsReviewWishr[]) => {
                    let actualdata: IPropActionItemsReviewWishr[] = [];
                    ActionItemsReviewData.forEach((row) => {
                        if (new Date(row.kpiDate).getDate() == new Date(Meetingdate).getDate() && new Date(row.kpiDate).getMonth() == new Date(Meetingdate).getMonth() && new Date(row.kpiDate).getFullYear() == new Date(Meetingdate).getFullYear()) {
                            actualdata.push(row)
                        }
                    });
                    let accordiontoggleStatus = actualdata.length > 0 ? true : false;
                    count = count + 1;
                    dispatch({
                        ActionItemsReviewData: actualdata,
                        count: count,
                        accordiontoggle: true,
                        type: ActionType.GET_ACTION_ITEMS_REVIEW_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_ACTION_ITEMS_REVIEW_FAILURE
                    });
                });
        }

    },

    handleMeetingDateChange: (MeetingDate: Date): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            MeetingDate: MeetingDate,
            isLockedPIR: getState().WZTNASHR.isLockedPIRArray.filter((e) => { if (new Date(e).toUTCString() == new Date(MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
            isLockedTopic: getState().WZTNASHR.isLockedTopicArray.filter((e) => { if (new Date(e).toUTCString() == new Date(MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
            isLockedFundamentalsSpotlight: getState().WZTNASHR.isLockedFundamentalsSpotlightArray.filter((e) => { if (new Date(e).toUTCString() == new Date(MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
            isLockedPubServiceAcc: getState().WZTNASHR.isLockedPubServiceAccArray.filter((e) => { if (new Date(e).toUTCString() == new Date(MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
            type: ActionType.MEETINGDATE_DATE_CHANGE
        })
    },

    EditLockforPIR: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let meetingDate = moment(getState().WZTNASHR.MeetingDate).format('YYYY-MM-DD');
        let lockValue = getState().WZTNASHR.isLockedPIR == true ? 0 : 1;
        WZTNASHRAPI.PostPirAdminLock(1, meetingDate, lockValue, getState().authUser.login).
            then((value: IPIRAdminLock[]) => {
                dispatch({
                    isLockedPIR: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WZTNASHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedTopic: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WZTNASHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedFundamentalsSpotlight: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WZTNASHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPubServiceAcc: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WZTNASHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPIRArray: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedTopicArray: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedFundamentalsSpotlightArray: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedPubServiceAccArray: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }),
                    type: ActionType.GET_PIRADMINLOCK
                });
            });
    },
    EditLockforPubServiceAcc: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let meetingDate = moment(getState().WZTNASHR.MeetingDate).format('YYYY-MM-DD');
        let lockValue = getState().WZTNASHR.isLockedPubServiceAcc == true ? 0 : 1;
        WZTNASHRAPI.PostPirAdminLock(4, meetingDate, lockValue, getState().authUser.login).
            then((value: IPIRAdminLock[]) => {
                dispatch({
                    isLockedPIR: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WZTNASHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedTopic: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WZTNASHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedFundamentalsSpotlight: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WZTNASHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPubServiceAcc: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WZTNASHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPIRArray: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedTopicArray: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedFundamentalsSpotlightArray: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedPubServiceAccArray: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }),
                    type: ActionType.GET_PIRADMINLOCK
                });
            });
    },
    EditLockforSpecificTopic: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let meetingDate = moment(getState().WZTNASHR.MeetingDate).format('YYYY-MM-DD');
        let lockValue = getState().WZTNASHR.isLockedTopic == true ? 0 : 1;
        WZTNASHRAPI.PostPirAdminLock(2, meetingDate, lockValue, getState().authUser.login).
            then((value: IPIRAdminLock[]) => {
                dispatch({
                    isLockedPIR: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WZTNASHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedTopic: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WZTNASHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedFundamentalsSpotlight: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WZTNASHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPubServiceAcc: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WZTNASHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPIRArray: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedTopicArray: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedFundamentalsSpotlightArray: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedPubServiceAccArray: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }),
                    type: ActionType.GET_PIRADMINLOCK
                });
            });
    },

    EditPubServiceAccRow: (val: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const PublicServiceAnnouncementData = getState().WZTNASHR.PublicServiceAnnouncementData;
        const publicServiceAcc = [];
        PublicServiceAnnouncementData.map((obj: IPropPublicServiceAnnouncementWishr) => {
            if (obj.id === val) {
                obj.isEdit = true;
                obj.presenterEdit = obj.presenter;
                obj.topicNameEdit = obj.topicName;
                obj.timeNeededEdit = obj.timeNeeded;
                obj.presentationLinkEdit = obj.presentationLink;
                obj.isTopicApprovedEdit = obj.isTopicApproved;
                obj.displayOrderEdit = obj.displayOrder;
            }
            else {
                obj.isEdit = false;
            }
            if (obj.id > 0)
                publicServiceAcc.push(obj);
        })
        dispatch({
            PublicServiceAnnouncementData: publicServiceAcc,
            isPSALockDisabledOnEdit: true,
            type: ActionType.ENABLE_EDITORADD_PUBLICSERVICEACC
        });
    },

    CancelEditPubServiceAccRow: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const PublicServiceAnnouncementData = getState().WZTNASHR.PublicServiceAnnouncementData;
        const publicServiceAcc = [];
        PublicServiceAnnouncementData.map((obj) => {
            obj.isEdit = false;
            obj.presenterEdit = obj.presenter;
            obj.topicNameEdit = obj.topicName;
            obj.timeNeededEdit = obj.timeNeeded;
            obj.presentationLinkEdit = obj.presentationLink;
            obj.isTopicApprovedEdit = obj.isTopicApproved;
            obj.displayOrderEdit = obj.displayOrder;
            if (obj.id > 0)
                publicServiceAcc.push(obj);
        })

        let psaaccordiontoggleStatus = PublicServiceAnnouncementData.length > 0 ? true : false;
        dispatch({
            PublicServiceAnnouncementData: publicServiceAcc,
            count: 4,
            isPSALockDisabledOnEdit: false,
            specialaccordiontoggle: true,
            type: ActionType.GET_PUBLICSERVICEACC_SUCCESS
        });
    },

    EnableAddNewRowforPubServiceAcc: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const PublicServiceAnnouncementData = getState().WZTNASHR.PublicServiceAnnouncementData;
        const publicServiceAcc = [];
        PublicServiceAnnouncementData.map((obj: IPropCommsOnlyPir) => {
            if (obj.id > 0) {
                if (obj.isEdit = true)
                    obj.isEdit = false;
                publicServiceAcc.push(obj);
            }
        })
        let NewCOPIR = {
            id: 0,
            presenterEdit: '',
            topicNameEdit: '',
            timeNeededEdit: '',
            presentationLinkEdit: '',
            isTopicApprovedEdit: false,
            displayOrderEdit: publicServiceAcc.length + 1,
            isEdit: true
        }
        publicServiceAcc.push(NewCOPIR);
        dispatch({
            PublicServiceAnnouncementData: publicServiceAcc,
            isPSALockDisabledOnEdit: true,
            type: ActionType.ENABLE_EDITORADD_PUBLICSERVICEACC
        });
    },

    OnPIRPubServiceChangevalues: (e, id): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const PublicServiceAnnouncementData = getState().WZTNASHR.PublicServiceAnnouncementData;
        const publicSerAcc = [];
        let { value, name, checked, type } = e.target;
        if (value === undefined)
            value = '';
        PublicServiceAnnouncementData.map((obj: IPropCommsOnlyPir) => {

            if (obj.id === id) {
                if (type === 'checkbox') {
                    obj[name] = checked;
                }
                else {
                    obj[name] = value;
                }
            }


            publicSerAcc.push(obj);
        })
        dispatch({
            PublicServiceAnnouncementData: publicSerAcc,
            type: ActionType.PUBLICSERVICEANNOUNCEMENT_CHANGE
        });
    },

    UpdatePubServiceAccDataOrder: (data: IPropPublicServiceAnnouncementWishr[]): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const PublicServiceAnnouncementData = getState().WZTNASHR.PublicServiceAnnouncementData;
        let callActualData: number = 0;
        data.forEach((row, index) => {
            if (PublicServiceAnnouncementData.some(x => x.id === row.id && x.displayOrder !== index + 1)) {
                Callsetloadingtrue(dispatch);
                callActualData = callActualData + 1
                row.displayOrderEdit = (index + 1).toString()
                row.kpiDate = moment(getState().WZTNASHR.MeetingDate).format('MM/DD/YYYY');
                row.userName = getState().authUser.login;
                WZTNASHRAPI.AddorUpdatePubServiceAccWishrData(row)
                    .then((result: number) => {
                        callActualData = callActualData - 1;
                        if (callActualData == 0) {
                            CallGetWeeklyIDSHRPostIncidentsData(dispatch, moment(getState().WZTNASHR.MeetingDate).format('MM/DD/YYYY'), 2)
                        }
                    });
            }
        })
    },
    GetPirAdminLock: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        WZTNASHRAPI.GetPirAdminLock().
            then((value: IPIRAdminLock[]) => {
                dispatch({
                    isLockedPIR: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WZTNASHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedTopic: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WZTNASHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedFundamentalsSpotlight: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WZTNASHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPubServiceAcc: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WZTNASHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPIRArray: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedTopicArray: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedFundamentalsSpotlightArray: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedPubServiceAccArray: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }),
                    type: ActionType.GET_PIRADMINLOCK
                });
            });
    },

    EditLockforFundamentalsSpotlight: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let meetingDate = moment(getState().WZTNASHR.MeetingDate).format('YYYY-MM-DD');
        let lockValue = getState().WZTNASHR.isLockedFundamentalsSpotlight == true ? 0 : 1;
        WZTNASHRAPI.PostPirAdminLock(3, meetingDate, lockValue, getState().authUser.login).
            then((value: IPIRAdminLock[]) => {
                dispatch({
                    isLockedPIR: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WZTNASHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedTopic: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WZTNASHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedFundamentalsSpotlight: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WZTNASHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPubServiceAcc: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WZTNASHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPIRArray: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedTopicArray: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedFundamentalsSpotlightArray: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedPubServiceAccArray: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }),
                    type: ActionType.GET_PIRADMINLOCK
                });
            });
    },

    EnableAddforFundamentalsSpotlight: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const FundamentalsSpotlightData = getState().WZTNASHR.FundamentalsSpotlightData;
        const fundamentalsSpotlight = [];
        FundamentalsSpotlightData.map((obj: IPropFundamentalsSpotlightWishr) => {
            if (obj.id > 0) {
                if (obj.isEdit = true)
                    obj.isEdit = false;
                fundamentalsSpotlight.push(obj);
            }
        })
        let NewfundamentalsSpotlight = {
            id: 0,
            topicNameEdit: '',
            timeNeededEdit: 0,
            presentationLinkEdit: '',
            isTopicApprovedEdit: false,
            displayOrderEdit: fundamentalsSpotlight.length + 1,
            isEdit: true,
        }
        fundamentalsSpotlight.push(NewfundamentalsSpotlight);
        dispatch({
            FundamentalsSpotlightData: fundamentalsSpotlight,
            isFunLockDisabledOnEdit: true,
            type: ActionType.ENABLE_EDITORADD_FUNDAMENTALS_SPOTLIGHT
        });
    },
    EditFundamentalsSpotlightRow: (val: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const FundamentalsSpotlightData = getState().WZTNASHR.FundamentalsSpotlightData;
        const fundamentalsSpotlight = [];
        FundamentalsSpotlightData.map((obj: IPropFundamentalsSpotlightWishr) => {
            if (obj.id === val) {
                obj.isEdit = true;
                obj.topicNameEdit = obj.topicName;
                obj.timeNeededEdit = obj.timeNeeded;
                obj.presentationLinkEdit = obj.presentationLink;
                obj.isTopicApprovedEdit = obj.isTopicApproved;
                obj.displayOrderEdit = obj.displayOrder;
            }
            else {
                obj.isEdit = false;
            }
            if (obj.id > 0)
                fundamentalsSpotlight.push(obj);
        })
        dispatch({
            FundamentalsSpotlightData: fundamentalsSpotlight,
            isFunLockDisabledOnEdit: true,
            type: ActionType.ENABLE_EDITORADD_FUNDAMENTALS_SPOTLIGHT
        });
    },
    CancelEditFundamentalsSpotlightRow: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const FundamentalsSpotlightData = getState().WZTNASHR.FundamentalsSpotlightData;
        const fundamentalsSpotlight = [];
        FundamentalsSpotlightData.map((obj) => {
            obj.isEdit = false;
            obj.topicNameEdit = obj.topicName;
            obj.timeNeededEdit = obj.timeNeeded;
            obj.presentationLinkEdit = obj.presentationLink;
            obj.isTopicApprovedEdit = obj.isTopicApproved;
            obj.displayOrderEdit = obj.displayOrder;
            if (obj.id > 0)
                fundamentalsSpotlight.push(obj);
        })
        let funaccordiontoggleStatus = FundamentalsSpotlightData.length > 0 ? true : false;
        dispatch({
            FundamentalsSpotlightData: fundamentalsSpotlight,
            count: 4,
            isFunLockDisabledOnEdit: false,
            funaccordiontoggle: true,
            type: ActionType.GET_FUNDAMENTALS_SPOTLIGHT_SUCCESS
        });
    },
    OnFundamentalsSpotlightChangevalues: (e, id): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const FundamentalsSpotlightData = getState().WZTNASHR.FundamentalsSpotlightData;
        const fundamentalsSpotlight = [];
        let { value, name } = e.target;
        if (value === undefined)
            value = '';
        FundamentalsSpotlightData.map((obj: IPropFundamentalsSpotlightWishr) => {
            if (obj.id === id) {
                obj[name] = value;

            }
            fundamentalsSpotlight.push(obj);
        })
        dispatch({
            FundamentalsSpotlightData: fundamentalsSpotlight,
            type: ActionType.FUNDAMENTALS_SPOTLIGHT_CHANGE
        });
    },
    UpdateFundamentalsSpotlightOrder: (data: IPropFundamentalsSpotlightWishr[]): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const actualFundamentalsSpotlightData = getState().WZTNASHR.FundamentalsSpotlightData;
        let callActualData: number = 0;
        data.forEach((row, index) => {
            if (actualFundamentalsSpotlightData.some(x => x.id === row.id && x.displayOrder !== index + 1)) {
                Callsetloadingtrue(dispatch);
                callActualData = callActualData + 1
                row.displayOrderEdit = (index + 1).toString()
                row.kpiDate = moment(getState().WZTNASHR.MeetingDate).format('MM/DD/YYYY');
                row.userName = getState().authUser.login
                WZTNASHRAPI.AddorupdateFundamentalsSpotlightWishrData(row)
                    .then((result: number) => {
                        callActualData = callActualData - 1;
                        if (callActualData == 0) {
                            CallGetWeeklyIDSHRPostIncidentsData(dispatch, moment(getState().WZTNASHR.MeetingDate).format('MM/DD/YYYY'), 6)
                        }
                    });
            }
        })
    },
    UpdateweeklyIDSHRDataOrder: (data: IPropAllPirData[]): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const actualweeklyIDSHRData = getState().WZTNASHR.weeklyIDSHRData;
        let callActualData: number = 0;
        data.forEach((row, index) => {
            if (actualweeklyIDSHRData.some(x => x.id === row.id && x.displayOrder !== index + 1)) {
                Callsetloadingtrue(dispatch);
                callActualData = callActualData + 1
                row.displayOrderEdit = (index + 1).toString()
                row.kpiDate = moment(getState().WZTNASHR.MeetingDate).format('MM/DD/YYYY');
                row.userName = getState().authUser.login
                WZTNASHRAPI.AddPirData(row)
                    .then((result: number) => {
                        callActualData = callActualData - 1;
                        if (callActualData == 0) {
                            CallGetWeeklyIDSHRPostIncidentsData(dispatch, moment(getState().WZTNASHR.MeetingDate).format('MM/DD/YYYY'), 1)
                        }
                    });
            }
        })
    },
    UpdateSpecialTopicsDataOrder: (data: IPropSpecialTopicsWishr[]): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const actualSpecialTopicsData = getState().WZTNASHR.SpecialTopicsData;
        let callActualData: number = 0;
        data.forEach((row, index) => {
            if (actualSpecialTopicsData.some(x => x.id === row.id && x.displayOrder !== index + 1)) {
                Callsetloadingtrue(dispatch);
                callActualData = callActualData + 1
                row.displayOrderEdit = (index + 1).toString()
                row.kpiDate = moment(getState().WZTNASHR.MeetingDate).format('MM/DD/YYYY');
                row.userName = getState().authUser.login
                WZTNASHRAPI.AddorupdateSpecialTopicsWishrData(row)
                    .then((result: number) => {
                        callActualData = callActualData - 1;
                        if (callActualData == 0) {
                            CallGetWeeklyIDSHRPostIncidentsData(dispatch, moment(getState().WZTNASHR.MeetingDate).format('MM/DD/YYYY'), 3)
                        }
                    });
            }
        })
    },
};
const getMeetingDate = () => {
    const today = new Date(); // "07/23/2016"   
    if (!today.getDay()) {
        let sundaydate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 15);  // change number 8 to 15 when current day is sunday      
        sundaydate.setDate(sundaydate.getDate() + 12);
        return sundaydate;
    }
    const pMonday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 6);
    const date = new Date(pMonday.getFullYear(), pMonday.getMonth(), pMonday.getDate() - 2);
    date.setDate(date.getDate() + 11);
    return date;

}
const getMaxDate = () => {
    const today = new Date(); // "07/23/2016"   
    if (!today.getDay()) {
        let sundaydate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 15);  // change number 8 to 15 when current day is sunday      
        sundaydate.setDate(sundaydate.getDate() + 27);
        return sundaydate;
    }
    const pMonday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 6);
    const date = new Date(pMonday.getFullYear() + 3, pMonday.getMonth(), pMonday.getDate() - 2);
    date.setDate(date.getDate() + 27);
    return date;

}
// function to call setloadingtrue action method
const Callsetloadingtrue = (dispatch) => {
    dispatch(actionCreators.setloadingtrue());
}
// function to call setloadingtrue action method
const CallGetWeeklyIDSHRPostIncidentsData = (dispatch, Meetingdate: string, Callcount) => {
    dispatch(actionCreators.GetWeeklyIDSHRPostIncidentsData(Meetingdate, Callcount));
}

