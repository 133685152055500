import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IEscalationDirectoryState } from './types';

const initialState = Object.freeze<IEscalationDirectoryState>({
    escalationDirectoryData: [],
    escalationDirectoryDataFiltered: [],
    isLoading: false,
    searchText: "",
    isactive: '1',
    sortcolumn: "title",
    reverseSort: false
});

export const reducer = (
    state: IEscalationDirectoryState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true

            };
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false

            };
        case ActionType.GET_ESCALATIONDIRECTORY_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.GET_ESCALATIONDIRECTORY_SUCCESS:  // === for Escalataion Directory Data success ===============//
            return {
                ...state,
                escalationDirectoryData: action.escalationDirectoryData,
                escalationDirectoryDataFiltered: action.escalationDirectoryDataFiltered,
                isLoading: false
            };
        case ActionType.GET_ESCALATIONDIRECTORY_FAILURE:  // === for Escalataion Directory Data failure ===============//
            return {
                ...state,
                escalationDirectoryData: [],
                escalationDirectoryDataFiltered: [],
                isLoading: false
            };
        case ActionType.CHANGE_SEARCHTEXT_ESCALATIONDIRECTORY:
            return {
                ...state,
                searchText: action.searchText
            };
        case ActionType.CHANGE_SORTING_ESCALATIONDIRECTORY:
            return {
                ...state,
                reverseSort: action.reverseSort,
                sortcolumn: action.sortcolumn,
                escalationDirectoryDataFiltered: action.escalationDirectoryDataFiltered
            };
        case ActionType.RESET_STATE:
            return initialState;
        default:
            return state;
    }
};