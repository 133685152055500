import { BaseService } from './base.service';
import { IPropIdentityTopOutagesTable, IPropIdentityTopOutages, IPropLastWeekIdentityTiles, IPropPirCompletion, IPropSTRepairItems, IPropLastWeekOnCall } from '../store/LastWeekIdNewsLetter';

/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class LastWeekIdNewsLetterService extends BaseService {
    private static _serviceGroupService: LastWeekIdNewsLetterService;

    private constructor(controllerName: string) {
        super(controllerName);
    }
    public static get Instance(): LastWeekIdNewsLetterService {
        return (this._serviceGroupService || (this._serviceGroupService = new this('LastWeekIdNewsLetter')));
    }

    public async GetIdentityNewsletterTopOutages(weekStartDate: string): Promise<IPropIdentityTopOutages[]> {
        const { data } = await this.$http.get<IPropIdentityTopOutages[]>(`GetIdentityNewsletterTopOutages?weekStartDate=${weekStartDate}`);
        return data;
    }

    public async GetIdentityNewsletterTopOutagesTable(weekStartDate: string): Promise<IPropIdentityTopOutagesTable[]> {
        const { data } = await this.$http.get<IPropIdentityTopOutagesTable[]>(`GetIdentityNewsletterTopOutagesTable?weekStartDate=${weekStartDate}`);
        return data;
    }

    public async SaveTopOutagesData(objTopOutage: IPropIdentityTopOutages): Promise<number> {
        const { data } = await this.$http.post<number>(`SaveTopOutagesData`, objTopOutage);
        return data;
    }

    public async DeleteTopOutageData(TopOutageID: number, updatedby: string): Promise<number> {
        const { data } = await this.$http.get<number>(`DeleteTopOutageData?TopOutageID=${TopOutageID}&updatedby=${updatedby}`);
        return data;
    }

    public async RefreshTopOutageData(TopOutageID: number, updatedby: string): Promise<number> {
        const { data } = await this.$http.get<number>(`RefreshTopOutageData?TopOutageID=${TopOutageID}&updatedby=${updatedby}`);
        return data;
    }

    public async GetIdentityNewsLettersTiles(weekStartDate: string): Promise<IPropLastWeekIdentityTiles[]> {
        const { data } = await this.$http.get<IPropLastWeekIdentityTiles[]>(`GetIdentityNewsLettersTiles?weekStartDate=${weekStartDate}`);
        return data;
    }

    public async SaveUpdatedTiles(updatedDetails): Promise<number> {
        let updatedTilesDetails = updatedDetails;
        const { data } = await this.$http.post<number>(`SaveUpdatedTiles`, updatedTilesDetails);
        return data;
    }
    
    public async GetPIRSloCompletion(weekStartDate: string): Promise<IPropPirCompletion[]> {
        const { data } = await this.$http.get<IPropPirCompletion[]>(`GetPIRSloCompletion?weekStartDate=${weekStartDate}`);
        return data;
    }

    public async NewsletterIdentity_SendEmail(RequestorMail, dateRange, tab_text): Promise<any> {
        var sendEmailData = { "Requestor": RequestorMail, "DateRange": dateRange, "EmailContent": tab_text };
        const { data } = await this.$http.post<any>(`IdentityNewsletterSendEmail`, sendEmailData);
        return data;
    }

    public async GetSTRepairItems(weekStartDate: string): Promise<IPropSTRepairItems[]> {
        const { data } = await this.$http.get<IPropSTRepairItems[]>(`GetSTRepairItems?weekStartDate=${weekStartDate}`);
        return data;
    }

    public async GetLastWeekOnCallDetails(weekStartDate: string): Promise<IPropLastWeekOnCall[]> {
        const { data } = await this.$http.get<IPropLastWeekOnCall[]>(`GetLastWeekOnCallDetails?weekStartDate=${weekStartDate}`);
        return data;
    }

}

export const LastWeekIdNewsLetterAPI = LastWeekIdNewsLetterService.Instance;
