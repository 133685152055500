import React, { useState, Fragment } from 'react';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import { IPropSpecialTopicsWishr } from '../../../store/Weekly-ID-SHR/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faTrashAlt, faPencilAlt, faEdit, faTrash, faTruckLoading, faArrowAltCircleLeft, faPlus, faMinus, faSave, faBan, faCalendar, faUnlock, faLock, faCopy, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import DatePicker, { getDay } from 'react-datepicker';
import moment from 'moment';
import { OrderList } from 'primereact/orderlist';
import { Accordion } from 'react-bootstrap'
import { Row, Col, Button } from 'reactstrap';

type SpecialTopicsProps = {
    SpecialTopicsData: any,
    isSpecialTopicEditorAddEnabled: boolean,
    EditSpecialTopicsRows: any,
    DeleteSpecialTopicsRow: any,
    cancelEditSpecialTopicRows: any,
    Specialtopicvalueschange: any,
    handleKeypress: any,
    SaveSpecialTopicData: any,
    EnableAddNewforSpecialTopicsRow: any,
    setshowaddrecurrence: any,
    deleterecurrence: any,
    DefaultDate: any,
    MeetingDate: any,
    SpecialTopicsDate: any,
    MaxDate: any,
    IsPirRereviewUser: boolean,
    isAdmin: any,
    handleSpecialTopicsDateChange: any,
    isLocked: boolean,
    EditLockforSpecificTopic: any,
    UpdateSpecialTopicsDataOrder: any,
    CopyTableData: any,
    isSpecialTopicLockDisabledOnEdit: boolean,
    specialaccordiontoggle: any,
    setspecialaccordiontoggledata: any
};

const SpecialTopics = React.memo<SpecialTopicsProps>(({
    SpecialTopicsData,
    EditSpecialTopicsRows,
    cancelEditSpecialTopicRows,
    Specialtopicvalueschange,
    isSpecialTopicEditorAddEnabled,
    DeleteSpecialTopicsRow,
    handleKeypress,
    SaveSpecialTopicData,
    EnableAddNewforSpecialTopicsRow,
    setshowaddrecurrence,
    deleterecurrence,
    DefaultDate,
    MeetingDate,
    IsPirRereviewUser,
    SpecialTopicsDate,
    MaxDate,
    handleSpecialTopicsDateChange,
    isAdmin,
    isLocked,
    EditLockforSpecificTopic,
    UpdateSpecialTopicsDataOrder,
    CopyTableData,
    isSpecialTopicLockDisabledOnEdit,
    specialaccordiontoggle,
    setspecialaccordiontoggledata
}) => {

    const isThursday = (date) => {
        var day = new Date(moment(date).format('MM/DD/YYYY')).getDay();
        return day == 2;
    };
    const [presenterEdit, setpresenterEdit] = useState('')
    const [topicNameEdit, settopicNameEdit] = useState('')
    const [presentationLinkEdit, setpresentationLinkEdit] = useState('')

    const callEditSpecialTopicsRows = (e, row) => {
        setpresenterEdit(row.presenter)
        settopicNameEdit(row.topicName)
        setpresentationLinkEdit(row.presentationLink)
        EditSpecialTopicsRows(e, row)
    }

    const callEnableAddNewforSpecialTopicsRow = () => {
        setpresenterEdit('')
        settopicNameEdit('')
        setpresentationLinkEdit('')
        EnableAddNewforSpecialTopicsRow()
    }

    const getMidWed = (year, month) => {
        var d = new Date(year, month - 1);
        d.setDate(d.getDate() + ((8 - d.getDay() + 1) % 7) + 7);
        return d;
    }

    const getSecondThursday = () => {
        let secweekDisable = [];
        const yearArray = Array.from({ length: 7 }, (item, index) => index + 2018);
        const monthsArray = Array.from({ length: 13 }, (item, index) => index + 1);
        let yearMonthArray = [];
        for (let year = 1; year < yearArray.length; year++) {
            for (let months = 1; months < monthsArray.length; months++) {
                yearMonthArray.push([yearArray[year], months]);
            }
        }
        yearMonthArray.forEach(
            function (a) {
                secweekDisable.push(getMidWed(a[0], a[1]));
            }
        );
        return secweekDisable;
    }

    const callSaveSpecialTopicData = (e, row) => {
        row.presenterEdit = presenterEdit
        row.topicNameEdit = topicNameEdit
        row.presentationLinkEdit = presentationLinkEdit.trim()
        SaveSpecialTopicData(e, row)
    }
    if (SpecialTopicsData.filter(x => x.id === -1).length <= 0 && isAdmin == true) {
        SpecialTopicsData.splice(0, 0, { id: -1 });
    }

    if (SpecialTopicsData.length <= 1) {
        SpecialTopicsData.splice(1, 0, { id: -2 });
    }
    else {
        SpecialTopicsData.filter((row) => { return row.id != -2 })
    }

    const itemTemplate = (item) => {
        return (
            <React.Fragment>
                {
                    (item.id == -1 || item.id == -2) ? (item.id == -1) ?
                        <tr>
                            <td style={{ width: '20%' }}><b>{(isSpecialTopicEditorAddEnabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Presenter</b></td>
                            <td style={{ width: '30%' }}><b>{(isSpecialTopicEditorAddEnabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Topic Name</b></td>
                            <td style={{ width: '40%' }}><b>{(isSpecialTopicEditorAddEnabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Link to presentation</b></td>
                            <td style={{ width: '10%', textAlign: 'center' }}><b>Actions</b></td>
                        </tr> :
                        <tr><td style={{ width: '100%', backgroundColor: 'azure' }} className="clsnodatamessgefortable">No Data Available</td></tr>
                        :
                        <tr style={(isSpecialTopicEditorAddEnabled) ? { backgroundColor: 'azure', color: 'black' } : {}}>



                            <td style={{ width: '20%' }}>
                                <div>{(item.isEdit) ? <textarea className="form-control" onChange={e => setpresenterEdit(e.target.value)} value={presenterEdit} name="presenterEdit" rows={1} cols={50} /> : item.presenter}</div>
                            </td>
                            <td style={{ width: '30%' }}>
                                <div>{(item.isEdit) ? <textarea className="form-control" onChange={e => settopicNameEdit(e.target.value)} value={topicNameEdit} name="topicNameEdit" rows={1} cols={50} /> : item.topicName}</div>
                            </td>
                            <td style={{ width: '40%' }} className="tdwordbreak">
                                <div>{(item.isEdit) ? <textarea className="form-control" onChange={e => setpresentationLinkEdit(e.target.value)} value={presentationLinkEdit} name="presentationLinkEdit" rows={1} cols={50} /> : <a style={(isSpecialTopicEditorAddEnabled) ? { color: '#007ad9' } : {}} title="All links need to be accessible for all Identity viewers" href={item.presentationLink} target="_blank">{item.presentationLink}</a>}</div>

                            </td>
                            <td style={{ width: '10%' }} className="centericons">
                                {
                                    (!item.isEdit)
                                        ?
                                        <React.Fragment>
                                            <span onClick={e => callEditSpecialTopicsRows(e, item)}>
                                                <FontAwesomeIcon title='Click to edit the item' className="bluecoloricons Iconsstyles" icon={faPencilAlt} />
                                            </span> &nbsp;
                                            <FontAwesomeIcon title='Click to Delete the item' className="redcolorIcons Iconsstyles" icon={faTrash} onClick={e => DeleteSpecialTopicsRow(e, item)} /> &nbsp;
                                            <span>
                                                <label htmlFor={'SpecialTopicdate' + item.id}><FontAwesomeIcon title='Click to Change the  Meeting Date' className="bluecoloricons Iconsstyles" icon={faCalendar} /></label>
                                                <DatePicker
                                                    id={"SpecialTopicdate" + item.id}
                                                    selected={SpecialTopicsDate}
                                                    onChange={SpecialTopicsDate => handleSpecialTopicsDateChange(item, SpecialTopicsDate)}
                                                    excludeDates={getSecondThursday()}
                                                    filterDate={isThursday}
                                                    maxDate={MaxDate}
                                                    className="datepickerCalendar"
                                                    popperPlacement="bottom-end"
                                                    popperModifiers={{
                                                        flip: {
                                                            enabled: false
                                                        },
                                                        offset: {
                                                            enabled: true,
                                                            offset: "55px, 5px"
                                                        },
                                                        preventOverflow: {
                                                            enabled: true,
                                                            escapeWithReference: false
                                                        }
                                                    }}
                                                />
                                            </span>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <FontAwesomeIcon title='Save Changes' className="bluecoloricons Iconsstyles" onClick={e => callSaveSpecialTopicData(e, item)} icon={faSave} /> &nbsp;
                                            <FontAwesomeIcon title='Cancel Edit' className="Iconsstyles" icon={faBan} onClick={e => cancelEditSpecialTopicRows(e, item)} />
                                        </React.Fragment>
                                }
                            </td>
                        </tr>
                }
            </React.Fragment>
        );
    };
    return (
        <React.Fragment>
            <br />
            <Accordion>
                <Row>
                    <Col lg="7" style={{ textAlign: 'left' }}><h5 className="headertext">Special Topics (10 minutes) to be reviewed in WISHR</h5></Col>
                    <Col lg="5">
                        <div className="divaddnew">
                            <span style={{ display: isLocked ? 'inline' : 'none', color: 'red' }}>Locked By Admin  </span>
                            <Button className='PIRAddbuttons' hidden={(SpecialTopicsData.length > 1 ? false : true)} onClick={() => CopyTableData('specialTopics')}> <FontAwesomeIcon icon={faCopy} /> &nbsp;Copy Table</Button>&nbsp;
                            <Button className='PIRAddbuttons' disabled={isLocked} onClick={callEnableAddNewforSpecialTopicsRow}> <FontAwesomeIcon icon={faPlus} /> &nbsp;Add New Topic</Button>&nbsp;
                            <Button className='PIRAddbuttons' hidden={!isLocked} disabled={!isAdmin || isSpecialTopicLockDisabledOnEdit} onClick={EditLockforSpecificTopic}><FontAwesomeIcon icon={faLock} /></Button>
                            <Button className='PIRAddbuttons' hidden={isLocked} disabled={!isAdmin || isSpecialTopicLockDisabledOnEdit} onClick={EditLockforSpecificTopic}><FontAwesomeIcon icon={faUnlock} /></Button>
                            <Accordion.Toggle as={Button} eventKey="0" onClick={e => setspecialaccordiontoggledata(!specialaccordiontoggle)} style={{ background: 'none' }}>
                                <span style={{ float: 'right', padding: '10px' }}>
                                    {(specialaccordiontoggle) ? <FontAwesomeIcon title='Collapse All' icon={'minus-circle'} className='icon-format-collapseinternalEscalation' /> : <FontAwesomeIcon title='Expand All' icon={'plus-circle'} className='icon-format-expandinternalEscalation' />
                                    }
                                </span>
                            </Accordion.Toggle>
                        </div>
                    </Col>
                </Row>
                <div style={{ display: (specialaccordiontoggle) ? 'block' : 'none' }}>
                    {(!isAdmin == true) ?
                        <Row className="tblpadding">
                            <Col lg="12">
                                <table id="tblPIR" className="tblPIR tblborder">
                                    <thead>
                                        <tr>
                                            <th className="thwidth280">{(isSpecialTopicEditorAddEnabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Presenter</th>
                                            <th className="thwidth200">{(isSpecialTopicEditorAddEnabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Topic Name</th>
                                            <th className="thwidth300">{(isSpecialTopicEditorAddEnabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Link to presentation</th>
                                            {(isAdmin === true) ?
                                                <th className="thwidth200">{(isSpecialTopicEditorAddEnabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Order</th>
                                                : <React.Fragment></React.Fragment>
                                            }
                                            <th className="thwidth200">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!isArrayWithLength(SpecialTopicsData)
                                            ?
                                            <React.Fragment>
                                                <tr>
                                                    <td colSpan={7} className="clsnodatamessgefortable">
                                                        No Data Available
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                            : SpecialTopicsData.map((row: IPropSpecialTopicsWishr, index) => (
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <td>
                                                            <div>{(row.isEdit) ? <textarea className="form-control" onChange={e => Specialtopicvalueschange(e, row)} value={row.presenterEdit} name="presenterEdit" rows={1} cols={50} /> : row.presenter}</div>
                                                        </td>
                                                        <td>
                                                            <div>{(row.isEdit) ? <textarea className="form-control" onChange={e => Specialtopicvalueschange(e, row)} value={row.topicNameEdit} name="topicNameEdit" rows={1} cols={50} /> : row.topicName}</div>
                                                        </td>
                                                        <td className="tdwordbreak">
                                                            <div>{(row.isEdit) ? <textarea className="form-control" onChange={e => Specialtopicvalueschange(e, row)} value={row.presentationLinkEdit} name="presentationLinkEdit" rows={1} cols={50} /> : <a title="All links need to be accessible for all Identity viewers" href={row.presentationLink} target="_blank">{row.presentationLink}</a>}</div>

                                                        </td>
                                                        {
                                                            (isAdmin === true) ?
                                                                <td>
                                                                    <div>{(row.isEdit) ? <input type="number" min="0" max="32000" className="form-control" value={row.displayOrderEdit} name="displayOrderEdit" onKeyDown={handleKeypress} onChange={e => Specialtopicvalueschange(e, row)} /> :
                                                                        <div>{row.displayOrder}</div>
                                                                    }</div>
                                                                </td>
                                                                : <React.Fragment></React.Fragment>
                                                        }
                                                        <td className="centericons">
                                                            {
                                                                (!row.isEdit)
                                                                    ?
                                                                    <React.Fragment>
                                                                        <span onClick={e => EditSpecialTopicsRows(e, row)}>
                                                                            <FontAwesomeIcon title='Click to edit the Row' className="bluecoloricons Iconsstyles" icon={faPencilAlt} /></span> &nbsp;
                                                                        <FontAwesomeIcon title='Click to Delete the Row' className="redcolorIcons Iconsstyles" icon={faTrash} onClick={e => DeleteSpecialTopicsRow(e, row)} /> &nbsp;
                                                                        <span>
                                                                            <label htmlFor={'SpecialTopicdate' + index}><FontAwesomeIcon title='Click to Change the  Meeting Date' className="bluecoloricons Iconsstyles" icon={faCalendar} /></label>
                                                                            <DatePicker
                                                                                id={"SpecialTopicdate" + index}
                                                                                selected={SpecialTopicsDate}
                                                                                onChange={SpecialTopicsDate => handleSpecialTopicsDateChange(row, SpecialTopicsDate)}
                                                                                excludeDates={getSecondThursday()}
                                                                                filterDate={isThursday}
                                                                                maxDate={MaxDate}
                                                                                className="datepickerCalendar"
                                                                                popperPlacement="bottom-end"
                                                                                popperModifiers={{
                                                                                    flip: {
                                                                                        enabled: false
                                                                                    },
                                                                                    offset: {
                                                                                        enabled: true,
                                                                                        offset: "55px, 5px"
                                                                                    },
                                                                                    preventOverflow: {
                                                                                        enabled: true,
                                                                                        escapeWithReference: false
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </span>
                                                                    </React.Fragment>
                                                                    :
                                                                    <React.Fragment>
                                                                        <FontAwesomeIcon title='Save Changes' className="bluecoloricons Iconsstyles" onClick={e => SaveSpecialTopicData(e, row)} icon={faSave} /> &nbsp;
                                                                        <FontAwesomeIcon title='Cancel Edit' className="Iconsstyles" icon={faBan} onClick={e => cancelEditSpecialTopicRows(e, row)} />
                                                                    </React.Fragment>
                                                            }
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        :
                        <Row>
                            <div className={SpecialTopicsData.length > 2 && !isSpecialTopicEditorAddEnabled ? "WeeklyIDSHRTableOuter" : "WeeklyIDSHRTableOuter NoArrowOrderList"}>
                                <OrderList
                                    value={SpecialTopicsData}
                                    id="WeeklyIDSHRTableid"
                                    listStyle={{ height: "auto" }}
                                    itemTemplate={itemTemplate}
                                    onChange={(e) => { UpdateSpecialTopicsDataOrder(e.value.filter((x) => { return x.id != -1 && x.id != -2 })) }}
                                ></OrderList>
                            </div>
                        </Row>
                    }
                </div>
            </Accordion>
        </React.Fragment>
    );
});

SpecialTopics.displayName = 'PostIncidentReviews';

export default SpecialTopics;