import { IAppThunkAction, ReduxAction } from '../';
import { ActionType, IPropHeatmapAdminData, IHeatmapAdminState } from './types';
import { DailyDataApi, CommonAPI, WeeklyIDSHRAPI, IdentityWeeklyNewsLetterAPI } from '../../api';
import { isNullOrUndefined, isArrayWithLength } from '../../utils';
export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    setloadingtrue: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
    },
    setloadingfalse: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_FALSE
        });
    },
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }

    },
    checkIsPirRereviewUser: (username): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        WeeklyIDSHRAPI.IsPirRereviewUser(username)
            .then((IsLivesitePMOrUser: boolean) => {
                dispatch({
                    IsLivesitePMOrUser: IsLivesitePMOrUser,
                    type: ActionType.USER_CHECK
                });
            }).catch(error => {
                dispatch({
                    IsLivesitePMOrUser: false,
                    type: ActionType.USER_CHECK
                });
            });
    },
    requestHeatmapAdminData: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        // Dispatch request
        dispatch({
            type: ActionType.GET_HEATMAPADMIN_REQUEST
        });
        var heatmapData = [];

        DailyDataApi.GetHeatMapAdminDataList()
            .then((heatmapAdminData: IPropHeatmapAdminData[]) => {
                heatmapAdminData.map((obj: IPropHeatmapAdminData) => {
                    heatmapData.push(obj);
                });
                dispatch({
                    heatmapAdminData: heatmapData,
                    type: ActionType.GET_HEATMAPADMIN_SUCCESS
                });
            })
            .catch(error => {
                dispatch({
                    type: ActionType.GET_HEATMAPADMIN_FAILURE
                });
            });

    },
    GetnewsLetterTeamGroups: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        var newsLetterTeamGroups = [];
        IdentityWeeklyNewsLetterAPI.GetIdentityWeeklyNewsLetterGroups('')
            .then((WeeklyNewsLetterGroupsDataRaw) => {
                if (isArrayWithLength(WeeklyNewsLetterGroupsDataRaw)) {
                    WeeklyNewsLetterGroupsDataRaw.map((obj) => {
                        newsLetterTeamGroups.push({ name: obj.groupName, value: obj.groupID });
                    });
                }
                dispatch({
                    newsLetterTeamGroups: newsLetterTeamGroups,
                    type: ActionType.GET_IDENTITYNEWSLETTER_TEAMGROUPS_SUCCESS
                });
            })
            .catch(error => {
                dispatch({
                    type: ActionType.GET_IDENTITYNEWSLETTER_TEAMGROUPS_FAILURE
                });
            });
    },
    ChangedSearchText: (e): IAppThunkAction<ReduxAction> => (dispatch) => {
        var search = e.target.value;
        dispatch({
            searchText: search,
            type: ActionType.CHANGE_SEARCHTEXT_HEATMAP_ADMIN
        });
    },
}




