import { BaseService } from './base.service';
import { IPropMsodsGridChart, IPropReachabilityError, IYear } from '../store/ReachabilityMetrics';
import { IPropKpiHistory } from '../store/availability';

/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class ReachabilityMetricsService extends BaseService {
    private static _serviceGroupService: ReachabilityMetricsService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): ReachabilityMetricsService {
        return (this._serviceGroupService || (this._serviceGroupService = new this('ReachabilityMetrics')));
    }

    public async GetCalendarData(): Promise<IYear[]> {
        const { data } = await this.$http.get<IYear[]>("GetCalendarData");
        return data;
    }

    public async GetReachabilityData(selectedDate: string): Promise<IPropMsodsGridChart[]> {
        const { data } = await this.$http.get<IPropMsodsGridChart[]>(`GetReachability?selectedDate=${selectedDate}`);
        return data;
    }

    public async GetReachabilityErrorData(selectedDate: string): Promise<IPropReachabilityError[]> {
        const { data } = await this.$http.get<IPropReachabilityError[]>(`GetReachabilityErrorData?selectedDate=${selectedDate}`);
        return data;
    }

    public async GetFilteredRedDataUi(reddata): Promise<IPropMsodsGridChart[]> {
        const { data } = await this.$http.post<IPropMsodsGridChart[]>(`GetFilteredRedDataUi`, reddata);
        return data;
    }

    public async AddKPIHistory(historydata: IPropKpiHistory[]): Promise<string> {
        const { data } = await this.$http.post<string>(`AddKPIHistory`, historydata);
        return data;
    }

    public async UpdateRetainPage(isRetain: boolean, pageName: string, updatedBy: string): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateRetainPage?pageName=${pageName}&isRetain=${isRetain}&updatedBy=${updatedBy}`);
        return data;
    }

    public async IsUserWantToRetainPage(pageName: string, updatedBy: string): Promise<number> {
        const { data } = await this.$http.get<number>(`IsUserWantToRetainPage?pageName=${pageName}&updatedBy=${updatedBy}`);
        return data;
    }
}

export const ReachabilityMetricsAPI = ReachabilityMetricsService.Instance;
