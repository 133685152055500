import React, { useRef, useState, useCallback} from 'react';
import { ReduxAction, IAppThunkAction } from '../../../store';
import { Select } from 'react-functional-select';
import { IDropdownOption } from '../../../store/filter-service-health';
import { FontAwesomeIconMemo, Checkbox } from '../../../components';
import DatePicker, { getDay} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';
import {
    Row, Col, Container
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isArrayWithLength, renderToastifyMsg, isNullOrUndefined } from '../../../utils';
import { IDailyFilterApplied, IAllViewDetails, PropFleetutilizationHM, IServiceNavInfo } from '../../../store/service-health';
import moment from 'moment';
import { toast, ToastId } from 'react-toastify';
import { DailyDataApi } from '../../../api';
import * as FileSaver from 'file-saver';
import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css';
import addDays from 'date-fns/addDays';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { InputSwitch } from 'primereact/inputswitch';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import { Modal, Button } from 'react-bootstrap'

type ServiceHealthFilterProps = {
    readonly themeConfig?: any;
    readonly selectRef?: any;
    readonly options: IDropdownOption[];
    readonly selectedOption: IDropdownOption;
    readonly onSelectOption: (option: IDropdownOption) => IAppThunkAction<ReduxAction>;

    readonly dailyViewList: IDropdownOption[];
    readonly reachabilityServices: IDropdownOption[];
    readonly activeDailyDataTab: any;

    readonly serviceSelectedOption: IDropdownOption;
    readonly serviceOnSelectOption: (option: IDropdownOption) => IAppThunkAction<ReduxAction>;

    readonly viewSelectedOption: IDropdownOption;
    readonly viewOnSelectOption: (option: IDropdownOption) => IAppThunkAction<ReduxAction>;

    readonly checked: boolean;
    readonly onCheck: (checked: boolean) => ReduxAction;

    readonly fleetTenantList: IDropdownOption[];
    readonly tenantSelectedOption: IDropdownOption;
    readonly fleetCloudList: IDropdownOption[];
    readonly cloudSelectedOption: IDropdownOption;
    readonly fleetRoleList: IDropdownOption[];
    readonly roleSelectedOption: IDropdownOption;
    readonly fleetTrafficGroupList: IDropdownOption[];
    readonly trafficGroupSelectedOption: IDropdownOption;
    readonly tenantOnSelectOption: (option: IDropdownOption) => IAppThunkAction<ReduxAction>;
    readonly cloudOnSelectOption: (option: IDropdownOption) => IAppThunkAction<ReduxAction>;
    readonly roleOnSelectOption: (option: IDropdownOption) => IAppThunkAction<ReduxAction>;
    readonly trafficGroupOnSelectOption: (option: IDropdownOption) => IAppThunkAction<ReduxAction>;
    readonly onFleetFilterSubmitted: () => ReduxAction;
    readonly fleetMaxDate: Date;
    readonly onFleetStartDateChange: (fleetStartDate: Date) => ReduxAction;
    readonly onFleetEndDateChange: (fleetEndDate: Date) => ReduxAction;
    readonly fleetStartDate: Date;
    readonly fleetEndDate: Date;
    readonly fleetheatmapData?: PropFleetutilizationHM[];
    currentService: IServiceNavInfo;

    readonly startDate: Date;
    readonly weeklyStartDate: Date;
    readonly monthlyStartDate: Date;
    readonly endDate: Date;
    readonly selectedServiceId: any;
    readonly onStartDateChange: (startDate: Date) => ReduxAction;
    readonly onEndDateChange: (endDate: Date) => ReduxAction;
    readonly onweeklyStartDateChange: (weeklyStartDate: Date) => ReduxAction;
    readonly onmonthlyStartDateChange: (monthlyStartDate: Date) => ReduxAction;
    dailyFilter: IDailyFilterApplied;
    readonly onDailyFilterSubmitted: any;
    GetPerformancePopupData: any;
    GetWeeklySelfServeData: any;
    OpenCommentsPopup: any;
    dailyData: any;
    ReachabilityData: any;
    onMSERComments: any;
    viewAllComments: any;
    readonly checkedMSER: boolean;
    readonly onMSERCheck: (checkedMSER: boolean) => IAppThunkAction<ReduxAction>;

    readonly checkedMonthlyAvailability: boolean;
    readonly onMonthlyAvailabilityCheck: (checkedMonthlyAvailability: boolean) => IAppThunkAction<ReduxAction>;

    OpenBulkEditPOPUP: any;
    readonly BulkEdit: boolean;
    //readonly ChangeChkBulkEdit: (BulkEdit: boolean) => IAppThunkAction<ReduxAction>;
    readonly ChangeChkBulkEdit: any;
    OpenBGMPopup: any;
    OpenNewBGMPopup: any;
    readonly checkHistory: boolean;
    readonly onCheckHistory: () => IAppThunkAction<ReduxAction>;
    Newview: boolean;
    ChangeNewView: any;
    onReachabilityCheck: any;
    checkedReachability: any;
    ChangeViewName: any;
    ConfigurationView: any;
    toggleMSERCommentsView: any;
    showMSERComments: any;
    bulkcellcount: number;

    readonly checkedFilterYellowDailyData: boolean;
    readonly checkedFilterRedDailyData: boolean;
    readonly checkedFilterYellowFleetData: boolean;
    readonly checkedFilterRedFleetData: boolean;
    readonly onFilterYellowDailyData: () => IAppThunkAction<ReduxAction>;
    readonly onFilterRedDailyData: () => IAppThunkAction<ReduxAction>;
    readonly onFilterYellowFleetData: () => IAppThunkAction<ReduxAction>;
    readonly onFilterRedFleetData: () => IAppThunkAction<ReduxAction>;

    saveAllWeeklyChanges: any;
    IsEdit: boolean;
    changeshowdrilldown: any;
    IsshowDrilldownIcon: any;
    sendEmail: any;
    IsshowEmail: any;
    drilldown: any;
    IsProd: boolean;
    MultipleViewSelected: boolean;
    MultipleViewSelectedChange: any;
    multipleviewSelectedOption: any;
    multipleviewOnSelectOption: any;
    TSGValue: any;
    HeatmapInactive: boolean;
    ChangeHeatmapStatus: any;
};

const FILTER_COL_PAD_TOP = '8px';

const ServiceHealthFilter = React.memo<ServiceHealthFilterProps>(({
    options,
    selectRef,
    themeConfig,
    selectedOption,
    onSelectOption,
    dailyViewList,
    activeDailyDataTab,
    serviceSelectedOption,
    serviceOnSelectOption,
    viewSelectedOption,
    viewOnSelectOption,
    multipleviewSelectedOption,
    multipleviewOnSelectOption,
    startDate,
    endDate,
    weeklyStartDate,
    monthlyStartDate,
    onStartDateChange,
    onEndDateChange,
    onDailyFilterSubmitted,
    selectedServiceId,
    onweeklyStartDateChange,
    onmonthlyStartDateChange,
    OpenCommentsPopup,
    GetPerformancePopupData,
    GetWeeklySelfServeData,
    dailyData,
    ReachabilityData,
    onMSERComments,
    viewAllComments,
    checkedMSER,
    onMSERCheck,
    checkedMonthlyAvailability,
    onMonthlyAvailabilityCheck,
    OpenBulkEditPOPUP,
    BulkEdit,
    ChangeChkBulkEdit,
    checkHistory,
    onCheckHistory,
    OpenBGMPopup,
    OpenNewBGMPopup,
    Newview,
    ChangeNewView,
    onReachabilityCheck,
    checkedReachability,
    ChangeViewName,
    ConfigurationView,
    showMSERComments,
    toggleMSERCommentsView,
    bulkcellcount,
    fleetTrafficGroupList,
    trafficGroupSelectedOption,
    trafficGroupOnSelectOption,
    fleetCloudList,
    cloudSelectedOption,
    cloudOnSelectOption,
    roleOnSelectOption,
    roleSelectedOption,
    fleetRoleList,
    fleetTenantList,
    tenantSelectedOption,
    tenantOnSelectOption,
    onFleetFilterSubmitted,
    fleetMaxDate,
    fleetStartDate,
    fleetEndDate,
    onFleetStartDateChange,
    onFleetEndDateChange,
    fleetheatmapData,
    currentService,
    reachabilityServices,
    checkedFilterYellowDailyData,
    checkedFilterRedDailyData,
    checkedFilterYellowFleetData,
    checkedFilterRedFleetData,
    onFilterYellowDailyData,
    onFilterRedDailyData,
    onFilterYellowFleetData,
    onFilterRedFleetData,
    saveAllWeeklyChanges,
    IsEdit,
    changeshowdrilldown,
    IsshowDrilldownIcon,
    sendEmail,
    IsshowEmail,
    drilldown,
    IsProd,
    MultipleViewSelected,
    MultipleViewSelectedChange,
    TSGValue,
    HeatmapInactive,
    ChangeHeatmapStatus
}) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let serviceId = parseInt(params.get('serviceid'));
    //alert(viewSelectedOption.label);
    const toastIdRef = useRef<ToastId>('');

    const [HeatmapDialog, sethideHeatmap] = useState(false);
    const [selectedhideHeatmap, setSelecthideHeatmap] = useState(null);

    const isWeekday = (date) => {
        var day = new Date(moment(date).format('MM/DD/YYYY')).getDay();
        return day == 6;
    };
    //const [selectedOption, setSelectedOption] = useState<dailyViewList | null>(null);
    //const onOptionChange = useCallback((option: dailyViewList | null): void => setSelectedOption(option), []);

    const copyReportURL = () => {
        var url = window.location.href;
        if (activeDailyDataTab === 'reachability') {
            url = url.split('?')[0]
            url = url + "?serviceid=" + selectedServiceId;
        }
        url += "&frDate=@Today-" + moment().diff(startDate, "days");
        url += "&toDate=@Today-" + moment().diff(endDate, "days");
        url += "&viewId=" + viewSelectedOption.value;
        url += "&tab=" + activeDailyDataTab;
        //if ($scope.sort.column) {
        //    url += "&sortColumn=" + $scope.sort.column;
        //    if ($scope.sort.descending) { // default sort to descending order
        //        url += "&order=desc";
        //    }
        //    else {
        //        url += "&order=asc";
        //    }
        //}

        var textField = document.createElement('textarea');
        textField.innerText = url;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();

        if (!toast.isActive(toastIdRef.current)) {
            toastIdRef.current = toast.success(
                renderToastifyMsg('Link copied to clipboard',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
            );
        }
    };

    const copyMultiViewReportURL = () => {
        //By default start date should be current date - 15 days
        const momentStDate = moment(startDate, 'MM/DD/YYYY');
        const sStartDate = momentStDate.format('MM/DD/YYYY');

        const momentEnDate = moment(endDate, 'MM/DD/YYYY');
        const sEndDate = momentEnDate.format('MM/DD/YYYY');
        var days = moment(sEndDate).diff(sStartDate, 'days');

        if (!isNullOrUndefined(multipleviewSelectedOption) && multipleviewSelectedOption !== "" && multipleviewSelectedOption !== null) {
            if (days > 15) {
                toastIdRef.current = toast.warn(
                    renderToastifyMsg('Maximum days allowed is 15 if you are enabling Multiple View Selection',
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                );
                return;
            }
            else if (multipleviewSelectedOption.length > 10) {
                toastIdRef.current = toast.warn(
                    renderToastifyMsg('Maximum Views allowed is 10',
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                );
                return;
            }
            else if (multipleviewSelectedOption.length === 0) {
                toastIdRef.current = toast.warn(
                    renderToastifyMsg('Please select atleast one view !!',
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                );
                return;
            }
            else {
                var url = window.location.href;
                url += "&frDate=@Today-" + moment().diff(startDate, "days");
                url += "&toDate=@Today-" + moment().diff(endDate, "days");
                url += "&viewId=" + multipleviewSelectedOption;
                url += "&tab=" + activeDailyDataTab;
                url += "&multipleView=true";

                var textField = document.createElement('textarea');
                textField.innerText = url;
                document.body.appendChild(textField);
                textField.select();
                document.execCommand('copy');
                textField.remove();

                if (!toast.isActive(toastIdRef.current)) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg('Link copied to clipboard',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                    );
                }
            }
        }
        else {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please select the view to copy URL!!',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
            );
            return;
        }
    };

    const downloadKustoQuery = () => {
        var fromDate = moment(startDate).format("MM-DD-YYYY");
        var toDate = moment(endDate).format("MM-DD-YYYY");
        var days = moment(toDate).diff(fromDate, "days");
        if (days > 60) {
            if (!toast.isActive(toastIdRef.current)) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg('Maximum date range allowed is 60 days!',
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                );
            }
        } else {
            DailyDataApi.GetHeatmapKustoData(selectedServiceId, viewSelectedOption.label)
                .then((data: IAllViewDetails) => {
                    var heatmap_KUSTO_Data = data;
                    var KUSTO_Query_Text;
                    if (heatmap_KUSTO_Data.source.toLowerCase() === "cosmos") {
                        KUSTO_Query_Text = "View Name : " + viewSelectedOption.label;
                        KUSTO_Query_Text += "\r\n Cosmos URL: " + heatmap_KUSTO_Data.valQuery;
                    } else {
                        KUSTO_Query_Text = "View Name : \r\n";
                        KUSTO_Query_Text = KUSTO_Query_Text + "\r\n";
                        KUSTO_Query_Text = KUSTO_Query_Text + heatmap_KUSTO_Data.viewName + "\r\n";
                        KUSTO_Query_Text = KUSTO_Query_Text + "\r\n";
                        KUSTO_Query_Text = KUSTO_Query_Text + "Fixed Column Query : \r\n";
                        KUSTO_Query_Text = KUSTO_Query_Text + "\r\n";
                        //KUSTO_Query_Text = KUSTO_Query_Text + "let startTime = datetime(" + moment($scope.fromDate).format("YYYY-MM-DD") + ");\r\n";
                        //KUSTO_Query_Text = KUSTO_Query_Text + "let endTime = datetime(" + moment($scope.toDate).format("YYYY-MM-DD") + ");\r\n";
                        KUSTO_Query_Text = KUSTO_Query_Text + heatmap_KUSTO_Data.fixedColQuery + "\r\n";
                        KUSTO_Query_Text = KUSTO_Query_Text + "\r\n";
                        KUSTO_Query_Text = KUSTO_Query_Text + "Value Query : \r\n";
                        KUSTO_Query_Text = KUSTO_Query_Text + "\r\n";
                        KUSTO_Query_Text = KUSTO_Query_Text + "let startTime = datetime(" + moment(fromDate).format("YYYY-MM-DD") + ");\r\n";
                        KUSTO_Query_Text = KUSTO_Query_Text + "let endTime = datetime(" + moment(toDate).format("YYYY-MM-DD") + ");\r\n";
                        KUSTO_Query_Text = KUSTO_Query_Text + heatmap_KUSTO_Data.valQuery + "\r\n";
                        KUSTO_Query_Text = KUSTO_Query_Text + "\r\n";
                        KUSTO_Query_Text = KUSTO_Query_Text + "DrillDown Query : \r\n";
                        KUSTO_Query_Text = KUSTO_Query_Text + "\r\n";
                        KUSTO_Query_Text = KUSTO_Query_Text + heatmap_KUSTO_Data.drillDownQuery + "\r\n";
                    }

                    var query = new Blob([KUSTO_Query_Text], {
                        type: "text/plain;charset=utf-8"
                    });

                    FileSaver.saveAs(query, "KUSTO_Query_Data.txt");
                });
        }
    };

    const exportHeatmapReport = () => {
        var data = activeDailyDataTab === 'heatmap' ? dailyData : activeDailyDataTab === 'reachability' ? ReachabilityData : fleetheatmapData;
        var id = activeDailyDataTab === 'heatmap' || activeDailyDataTab === 'reachability' ? 'tdDailydata' : 'tdfleetHeatmapData';
        var name = activeDailyDataTab === 'heatmap' || activeDailyDataTab === 'reachability' ? viewSelectedOption.label : tenantSelectedOption.value + ' '+ roleSelectedOption.value;
        if (isArrayWithLength(data)) {
            var tab_text = '<html xmlns:x="urn:schemas-microsoft-com:office:excel">';
            tab_text = tab_text + '<head><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>';
            tab_text = tab_text + '<x:Name>Sheet1</x:Name>';
            tab_text = tab_text + '<x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet>';
            tab_text = tab_text + '</x:ExcelWorksheets></x:ExcelWorkbook></xml></head><body>';
            tab_text = tab_text + "<table border='1px'>";
            var exportTable = document.body.querySelector('#'+ id).cloneNode(true);
            //exportTable.childNodes.forEach(function (node) { exportTable.removeChild(node); }, node => node.nodeName == 'input');
            var divElement = document.createElement('div');
            divElement.appendChild(exportTable);
            var table = divElement.innerHTML;
            tab_text = tab_text + table;
            //setTimeout(function () { }, 500);
            tab_text = tab_text + '</table></body></html>';
            var fileName = name + '.xls';

            // Save the file
            var blob = new Blob([tab_text], { type: "application/vnd.ms-excel;charset=utf-8" });

            FileSaver.saveAs(blob, fileName);
        }
        else {
            toastIdRef.current = toast.error(
                renderToastifyMsg('No Data Availble to download the Excel',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
            );
        }

    };

    const hideHeatmapDialog = () => {
        setSelecthideHeatmap(null);
        sethideHeatmap(false);
    }
    const deleteHeatmapConfirm = () => {
        setSelecthideHeatmap(viewSelectedOption.value);
        sethideHeatmap(true);
    }
    const deleteHeatmapConfirmed = () => {        
        ChangeHeatmapStatus(true);
        sethideHeatmap(false);
    }
    const hideHeatmapFooter = (
        <React.Fragment>
            <span style={{ display: 'flex', justifyContent: 'center' }}>
                <Button className="p-button-text btn-primary" onClick={() => hideHeatmapDialog()} >No</Button>
                <Button className="p-button-text btn-primary" onClick={() => deleteHeatmapConfirmed()}>Yes</Button>
            </span>
        </React.Fragment>
    );

    //Export Button Click Event for Fleet Utilization
    const ExportFleetUtilizationKustoQuery = () => {
        var fromDate = moment(fleetStartDate).format("MM-DD-YYYY");
        var toDate = moment(fleetEndDate).format("MM-DD-YYYY");
        var days = moment(toDate).diff(fromDate, "days");
        if (days > 60) {
            if (!toast.isActive(toastIdRef.current)) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg('Maximum date range allowed is 60 days!',
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                );
            }
        } else {
            // Include logic of getting the query
            var FleetUtilization_KUSTO_Query_Text = "FleetUtilization_KUSTO_Query : \r\n";
            FleetUtilization_KUSTO_Query_Text = FleetUtilization_KUSTO_Query_Text + "cluster('idsharedwus.kusto.windows.net').database('TrafficAnalytics')" + "\r\n";
            FleetUtilization_KUSTO_Query_Text = "\r\n\n" + FleetUtilization_KUSTO_Query_Text + "let lastweekstartdate = bin(now()-dayofweek(now())-7d, 1d); let lastweekenddate = lastweekstartdate + 6d; let opdata = DailyOperatingDataByDataCenter | where Date between (lastweekstartdate..lastweekenddate) | summarize request_Volume = sum(TotalRequests), fleet_Size = avg(ServersDeployed) by Tenant, Role, Cloud, TrafficGroup, LocationDC, Date | project Tenant, Role, Cloud, TrafficGroup, LocationDC, Date, request_Volume, fleet_Size; let peekdata = DailyPeakTransactionsByDataCenter | where Date between (lastweekstartdate..lastweekenddate) | summarize fleet_Utlization = max(AverageCPUAtPeak) by Tenant, Role, Cloud, TrafficGroup, LocationDC, Date; opdata | join kind = leftouter peekdata on Tenant, Role, Cloud, TrafficGroup, LocationDC, Date | summarize sum(request_Volume), avg(fleet_Utlization), fleet = avg(fleet_Size) by Tenant, Role, Cloud, TrafficGroup, LocationDC, Date | project Tenant, Role, Cloud, TrafficGroup, LocationDC, sum_request_Volume, avg_fleet_Utlization, fleet, Date" + "\r\n";
            var query = new Blob([FleetUtilization_KUSTO_Query_Text], {
                type: "text/plain;charset=utf-8"
            });
            FileSaver.saveAs(query, "FleetUtilization_KUSTO_Query_Text.txt");
        }
    };
    return (
        <div>
            <Container style={{ borderRadius: '8px', width: '100%', padding: '0', background: '#e7e7e9' }}>
                <Row>
                    <Col xs='1' className="paddingrightval">
                        {
                            <Picky id='picky'
                                options={options}
                                valueKey='value'
                                labelKey='label'
                                multiple={false}
                                value={selectedOption}
                                includeSelectAll={false}
                                onChange={onSelectOption}
                                dropdownHeight={300}
                                numberDisplayed={1}
                                placeholder='Select'
                                includeFilter={true}
                                keepOpen={false}

                            />
                        }
                    </Col>
                    {(selectedOption.label === 'Daily') ?
                        (activeDailyDataTab !== 'Fleetutilizationheatmap') ?
                            <React.Fragment>
                                {
                                    (activeDailyDataTab === 'reachability') ?
                                        <Col xs='2' className="paddingrightleft">
                                            {(isArrayWithLength(reachabilityServices)) ?
                                                <Picky id='picky'
                                                    options={reachabilityServices}
                                                    valueKey='value'
                                                    labelKey='label'
                                                    multiple={false}
                                                    value={serviceSelectedOption}
                                                    includeSelectAll={false}
                                                    onChange={serviceOnSelectOption}
                                                    dropdownHeight={300}
                                                    numberDisplayed={1}
                                                    placeholder='Services'
                                                    includeFilter={true}
                                                    keepOpen={false}
                                                    className="ReactPicky"

                                                />
                                                : <React.Fragment>&nbsp;&nbsp;<b>No Reachability Services</b></React.Fragment>
                                            }
                                        </Col>
                                        : <React.Fragment></React.Fragment>
                                }
                                <Col xs='2' className="paddingrightleft">
                                    {

                                        (!Newview)
                                            ?
                                            (isArrayWithLength(dailyViewList)) ?
                                                !(MultipleViewSelected) ?
                                                    <Picky id='picky'
                                                        options={dailyViewList}
                                                        valueKey='value'
                                                        labelKey='label'
                                                        multiple={false}
                                                        value={viewSelectedOption}
                                                        includeSelectAll={false}
                                                        onChange={viewOnSelectOption}
                                                        dropdownHeight={300}
                                                        numberDisplayed={1}
                                                        placeholder='Select View'
                                                        includeFilter={true}
                                                        keepOpen={false}
                                                        className="ReactPicky"
                                                    />
                                                    :
                                                    // MultiSelect For Multiple View Selection
                                                    <MultiSelect style={{ height: '35px' }} scrollHeight="300px" id={'multipleviewselect'} placeholder='Select View' filter={true} optionLabel="label" value={multipleviewSelectedOption} options={dailyViewList} onChange={(e) => multipleviewOnSelectOption(e.value)} name="multipleviewselect" />
                                                : (activeDailyDataTab === 'heatmap') ?
                                                    <div>&nbsp;&nbsp;No daily view</div> :
                                                    (activeDailyDataTab === 'reachability') ?
                                                        <div>&nbsp;&nbsp;No Reachability view</div>
                                                        :
                                                        (activeDailyDataTab === 'ViewConfiguration') ? <div>&nbsp;&nbsp;No Self Serve views</div>
                                                            : <React.Fragment></React.Fragment>
                                            : <React.Fragment>&nbsp;&nbsp;<b>New View</b></React.Fragment>
                                    }
                                </Col>
                            </React.Fragment>

                            : <div></div>
                        : <React.Fragment></React.Fragment>
                    }
                    {(selectedOption.label === 'Daily') ?
                        (activeDailyDataTab === 'heatmap') ?
                            <Col xs='9'>
                                <Row>
                                    {
                                        <React.Fragment>
                                            {
                                                (isArrayWithLength(dailyViewList)) ?
                                                    <React.Fragment>
                                                        {
                                                            <React.Fragment>
                                                                <Col xs='1' className="paddingrightleft">
                                                                    <DatePicker className='InputStyle'
                                                                        selected={startDate}
                                                                        onChange={onStartDateChange}
                                                                        maxDate={new Date()}
                                                                        calendarIcon={true}
                                                                        calendarAriaLabel={true}
                                                                        placeholderText='Start'
                                                                    //showPreviousMonths
                                                                    //monthsShown={12}
                                                                    //customInput={<button className="datePickerInput"> {moment(startDate).format("MM/DD/YYYY")} <FontAwesomeIconMemo icon={'table'} className="datePickerCalendarIcon" /></button>}
                                                                    />

                                                                </Col>&nbsp;
                                                                {BulkEdit ? <React.Fragment></React.Fragment> : <React.Fragment>&nbsp;&nbsp;</React.Fragment>}
                                                                <Col xs='1' className="paddingrightleft">
                                                                    <DatePicker className='InputStyle'
                                                                        selected={endDate}
                                                                        onChange={onEndDateChange}
                                                                        maxDate={addDays(new Date(), 2)}
                                                                        minDate={startDate}
                                                                        calendarIcon={true}
                                                                        calendarAriaLabel={true}
                                                                        placeholderText='End'
                                                                    //showPreviousMonths
                                                                    //monthsShown={2}
                                                                    //customInput={<button className="datePickerInput"> {moment(endDate).format("MM/DD/YYYY")} <FontAwesomeIconMemo icon={'table'} className="datePickerCalendarIcon" /></button>}
                                                                    />
                                                                </Col>&nbsp;
                                                                {BulkEdit ? <React.Fragment></React.Fragment> : <React.Fragment>&nbsp;&nbsp;</React.Fragment>}
                                                                <Col xs='3.5' style={{ paddingTop: '8px' }} >
                                                                    {BulkEdit ? <React.Fragment></React.Fragment> : <React.Fragment>&nbsp;&nbsp;</React.Fragment>}
                                                                    <FontAwesomeIconMemo onClick={onDailyFilterSubmitted} title="Filter the data" icon={'filter'} className='icon-format' />&nbsp;
                                                                    {(MultipleViewSelected) ?
                                                                        <React.Fragment>
                                                                            <FontAwesomeIconMemo onClick={copyMultiViewReportURL} title="Copy the URL" icon={'link'} className='icon-format' />&nbsp;
                                                                        </React.Fragment>
                                                                        : <React.Fragment></React.Fragment>
                                                                    }
                                                                    {!(MultipleViewSelected) ?
                                                                        <React.Fragment>
                                                                            <FontAwesomeIconMemo onClick={downloadKustoQuery} title="Download the Kusto query" icon={'download'} className='icon-format' />&nbsp;
                                                                            <FontAwesomeIconMemo onClick={copyReportURL} title="Copy the URL" icon={'link'} className='icon-format' />&nbsp;
                                                                            <FontAwesomeIconMemo onClick={exportHeatmapReport} title="Download the Heatmap Report" icon={'download'} className='icon-format' />&nbsp;
                                                                        </React.Fragment>
                                                                        : <React.Fragment></React.Fragment>}
                                                                    {!(MultipleViewSelected) ?
                                                                        <React.Fragment>
                                                                            <FontAwesomeIconMemo onClick={onFilterYellowDailyData} title="Filter the Yellow data" icon={'filter'} className={checkedFilterYellowDailyData ? 'icon-format-yellow' : 'icon-format-yellow-invert'} />&nbsp;
                                                                            <FontAwesomeIconMemo onClick={onFilterRedDailyData} title="Filter the Red data" icon={'filter'} className={checkedFilterRedDailyData ? 'icon-format-red' : 'icon-format-red-invert'} />&nbsp;
                                                                            <FontAwesomeIconMemo onClick={onMSERComments} title='Add/Update MSER Comments' icon={'edit'} className='icon-format' />&nbsp;
                                                                            <FontAwesomeIconMemo onClick={toggleMSERCommentsView} title='Toggle MSER Comments and Heatmap' icon={showMSERComments ? 'fire' : 'table'} className='icon-format' />&nbsp;
                                                                            <FontAwesomeIconMemo onClick={viewAllComments} title='View all comments' icon={'comment'} className='icon-format' />&nbsp;

                                                                            {(IsshowDrilldownIcon)
                                                                                ?
                                                                                <FontAwesomeIcon onClick={changeshowdrilldown} title='Show Drilldown' icon={'external-link-square-alt'} className={drilldown ? 'icon-format-blue' : 'icon-format'} />

                                                                                : <React.Fragment></React.Fragment>}
                                                                            &nbsp;
                                                                            {(IsshowEmail)
                                                                                ?
                                                                                <FontAwesomeIcon onClick={sendEmail} title='Send Email' icon={'envelope'} className='icon-format' />
                                                                                : <React.Fragment></React.Fragment>}
                                                                        </React.Fragment>
                                                                        : <React.Fragment></React.Fragment>}
                                                                </Col> &nbsp;&nbsp;
                                                                {!(MultipleViewSelected) ?
                                                                    <React.Fragment>
                                                                        <Col xs='1.25' style={{ paddingTop: '8px' }}>
                                                                            <Checkbox
                                                                                label="MSER View"
                                                                                checked={checkedMSER}
                                                                                onCheck={onMSERCheck}
                                                                            />
                                                                        </Col>&nbsp;&nbsp;
                                                                        <Col xs='1.5' style={{ paddingTop: '8px' }} >
                                                                            <Checkbox
                                                                                label="Monthly Availability"
                                                                                checked={checkedMonthlyAvailability}
                                                                                onCheck={onMonthlyAvailabilityCheck}
                                                                            />
                                                                        </Col>&nbsp;&nbsp;
                                                                        <Col xs='1.5' style={{ paddingTop: '8px' }}>
                                                                            <Checkbox
                                                                                label="Bulk Edit"
                                                                                checked={BulkEdit}
                                                                                onCheck={ChangeChkBulkEdit}
                                                                            />
                                                                        </Col>&nbsp;&nbsp;&nbsp;
                                                                        <Col xs='1.5' style={{ paddingTop: '8px' }}>
                                                                            {(activeDailyDataTab === 'heatmap' && BulkEdit) ?
                                                                                <a onClick={OpenBulkEditPOPUP} className="button-format sawfont" aria-hidden='false'>
                                                                                    {bulkcellcount > 0 ? 'Update (' + bulkcellcount + ')' : 'Update (0)'}
                                                                                </a>
                                                                                : <React.Fragment></React.Fragment>
                                                                            }&nbsp;&nbsp;
                                                                        </Col>
                                                                    </React.Fragment>
                                                                    : <React.Fragment><span style={{minWidth: '40vw', maxWidth: '40vw'}}></span></React.Fragment>
                                                                }
                                                                <Col xs='1.5' style={{ paddingTop: '8px',float: 'right' }}>
                                                                    <InputSwitch checked={MultipleViewSelected} onChange={(e) => MultipleViewSelectedChange(e.value)} />&nbsp;<span style={{ fontSize: '14px', verticalAlign: 'super' }}>Multiple View</span>
                                                                    &nbsp;&nbsp;
                                                                    {(!(MultipleViewSelected) && (!isNullOrUndefined(TSGValue) && TSGValue !== "")) ?
                                                                        <React.Fragment>
                                                                            <span style={{ verticalAlign: 'super' }}>&nbsp;<a href={TSGValue} target="_blank" title='Troubleshooting Guide'><b>TSG</b></a></span>
                                                                        </React.Fragment>
                                                                        : <React.Fragment></React.Fragment>
                                                                    }
                                                                </Col>

                                                            </React.Fragment>
                                                        }
                                                    </React.Fragment>
                                                    : <React.Fragment></React.Fragment>
                                            }
                                        </React.Fragment>
                                    }
                                </Row>
                            </Col>
                            : (activeDailyDataTab === 'reachability') ?
                                <React.Fragment>
                                    <Col xs='7'>
                                        <Row>
                                            {
                                                <React.Fragment>
                                                    {
                                                        (isArrayWithLength(reachabilityServices)) ?
                                                            <React.Fragment>
                                                                {
                                                                    <React.Fragment>
                                                                        <Col xs='1' className="paddingrightleft">
                                                                            <DatePicker className='InputStyle'
                                                                                selected={startDate}
                                                                                onChange={onStartDateChange}
                                                                                maxDate={new Date()}
                                                                                calendarIcon={true}
                                                                                calendarAriaLabel={true}
                                                                                placeholderText='Start'
                                                                            //peekNextMonth
                                                                            //dropdownMode="select"
                                                                            //customInput={<button className="datePickerInput"> {moment(startDate).format("MM/DD/YYYY")} <FontAwesomeIconMemo icon={'table'} className="datePickerCalendarIcon" /></button>}
                                                                            />

                                                                        </Col>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;;&nbsp;&nbsp;&nbsp;
                                                                        <Col xs='1' className="paddingrightleft">
                                                                            <DatePicker className='InputStyle'
                                                                                selected={endDate}
                                                                                onChange={onEndDateChange}
                                                                                maxDate={addDays(new Date(), 2)}
                                                                                minDate={startDate}
                                                                                calendarIcon={true}
                                                                                calendarAriaLabel={true}
                                                                                placeholderText='End'
                                                                            //peekNextMonth
                                                                            //dropdownMode="select"
                                                                            //customInput={<button className="datePickerInput"> {moment(endDate).format("MM/DD/YYYY")} <FontAwesomeIconMemo icon={'table'} className="datePickerCalendarIcon" /></button>}
                                                                            />
                                                                        </Col> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                        <Col xs='4' style={{ paddingTop: '8px' }} >
                                                                            <div style={{ width: '100%', textAlign: 'center' }}>
                                                                                <FontAwesomeIconMemo onClick={onDailyFilterSubmitted} title="Filter the data" icon={'filter'} className='icon-format' />&nbsp;
                                                                                <FontAwesomeIconMemo onClick={downloadKustoQuery} title="Download the Kusto query" icon={'download'} className='icon-format' />&nbsp;
                                                                                <FontAwesomeIconMemo onClick={copyReportURL} title="Copy the URL" icon={'link'} className='icon-format' />&nbsp;
                                                                                <FontAwesomeIconMemo onClick={exportHeatmapReport} title="Download the Reachability Report" icon={'download'} className='icon-format' />&nbsp;
                                                                                <FontAwesomeIconMemo onClick={onFilterYellowDailyData} title="Filter the Yellow data" icon={'filter'} className={checkedFilterYellowDailyData ? 'icon-format-yellow' : 'icon-format-yellow-invert'} />&nbsp;
                                                                                <FontAwesomeIconMemo onClick={onFilterRedDailyData} title="Filter the Red data" icon={'filter'} className={checkedFilterRedDailyData ? 'icon-format-red' : 'icon-format-red-invert'} />&nbsp;
                                                                                <FontAwesomeIconMemo onClick={viewAllComments} title='View all comments' icon={'comment'} className='icon-format' />&nbsp;
                                                                                {(IsshowDrilldownIcon)
                                                                                    ?
                                                                                    <FontAwesomeIcon onClick={changeshowdrilldown} title='Show Drilldown' icon={'external-link-square-alt'} className={drilldown ? 'icon-format-blue' : 'icon-format'} />
                                                                                    : <React.Fragment></React.Fragment>}
                                                                                &nbsp;
                                                                                {(IsshowEmail)
                                                                                    ?
                                                                                    <FontAwesomeIcon onClick={sendEmail} title='Send Email' icon={'envelope'} className='icon-format' />
                                                                                    : <React.Fragment></React.Fragment>}
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs='1.5' style={{ paddingTop: '8px' }}>
                                                                            <Checkbox
                                                                                label="MSER View"
                                                                                checked={checkedMSER}
                                                                                onCheck={onMSERCheck}
                                                                            />
                                                                        </Col>&nbsp;&nbsp;
                                                                        <Col xs='1.5' style={{ paddingTop: '8px' }} >
                                                                            <Checkbox
                                                                                label="Monthly Availability"
                                                                                checked={checkedMonthlyAvailability}
                                                                                onCheck={onMonthlyAvailabilityCheck}
                                                                            />
                                                                        </Col>
                                                                    </React.Fragment>
                                                                }
                                                            </React.Fragment>
                                                            : <React.Fragment></React.Fragment>
                                                    }
                                                </React.Fragment>
                                            }
                                        </Row>
                                    </Col>
                                </React.Fragment>
                                : (activeDailyDataTab === 'Fleetutilizationheatmap') ?
                                    <React.Fragment>
                                        <Col xs='9'>
                                            <Row>
                                                {
                                                    <React.Fragment>
                                                        {
                                                            (activeDailyDataTab === 'Fleetutilizationheatmap') ?
                                                                <Col xs='2' className="paddingrightleft">
                                                                    {
                                                                        isArrayWithLength(fleetTenantList) ?
                                                                            <React.Fragment>
                                                                                <Picky id='picky'
                                                                                    options={fleetTenantList}
                                                                                    valueKey='value'
                                                                                    labelKey='label'
                                                                                    multiple={false}
                                                                                    value={tenantSelectedOption}
                                                                                    includeSelectAll={false}
                                                                                    onChange={tenantOnSelectOption}
                                                                                    dropdownHeight={300}
                                                                                    numberDisplayed={1}
                                                                                    placeholder='Select Tenant'
                                                                                    includeFilter={true}
                                                                                    keepOpen={false}
                                                                                    className="ReactPickyFleet"
                                                                                />
                                                                            </React.Fragment>
                                                                            :
                                                                            <React.Fragment>
                                                                                <div>&nbsp;No Tenant Option</div>
                                                                            </React.Fragment>
                                                                    }
                                                                </Col>
                                                                : <React.Fragment></React.Fragment>
                                                        }
                                                        <Col xs='2' className="paddingrightleft">
                                                            {
                                                                isArrayWithLength(fleetRoleList) ?
                                                                    <React.Fragment>
                                                                        <Picky id='picky'
                                                                            options={fleetRoleList}
                                                                            valueKey='value'
                                                                            labelKey='label'
                                                                            multiple={false}
                                                                            value={roleSelectedOption}
                                                                            includeSelectAll={false}
                                                                            onChange={roleOnSelectOption}
                                                                            dropdownHeight={300}
                                                                            numberDisplayed={1}
                                                                            placeholder='Select Role'
                                                                            includeFilter={true}
                                                                            keepOpen={false}
                                                                            className="ReactPickyFleet"
                                                                        />
                                                                    </React.Fragment>
                                                                    :
                                                                    <React.Fragment>
                                                                        <div>&nbsp;No Role Option</div>
                                                                    </React.Fragment>
                                                            }
                                                        </Col>
                                                        {
                                                            (isArrayWithLength(fleetTenantList) && isArrayWithLength(fleetTrafficGroupList) && isArrayWithLength(fleetRoleList) && isArrayWithLength(fleetCloudList)) ?
                                                                <Col xs='1' className="paddingrightleft">
                                                                    <DatePicker className='InputStyle'
                                                                        selected={fleetStartDate}
                                                                        onChange={onFleetStartDateChange}
                                                                        maxDate={fleetMaxDate}
                                                                        calendarIcon={true}
                                                                        placeholderText='Start'
                                                                    //customInput={<button className="datePickerInput"> {moment(fleetStartDate).format("MM/DD/YYYY")} <FontAwesomeIconMemo icon={'table'} className="datePickerCalendarIcon" /></button>}
                                                                    />
                                                                </Col>
                                                                : <React.Fragment></React.Fragment>
                                                        }          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        {
                                                            (isArrayWithLength(fleetTenantList) && isArrayWithLength(fleetTrafficGroupList) && isArrayWithLength(fleetRoleList) && isArrayWithLength(fleetCloudList)) ?
                                                                <Col xs='1' className="paddingrightleft">
                                                                    <DatePicker className='InputStyle'
                                                                        selected={fleetEndDate}
                                                                        onChange={onFleetEndDateChange}
                                                                        maxDate={fleetMaxDate}
                                                                        calendarIcon={true}
                                                                        placeholderText='End'
                                                                    //customInput={<button className="datePickerInput"> {moment(fleetEndDate).format("MM/DD/YYYY")} <FontAwesomeIconMemo icon={'table'} className="datePickerCalendarIcon" /></button>}
                                                                    />
                                                                </Col>
                                                                : <React.Fragment></React.Fragment>
                                                        }          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <Col xs='1' className="paddingrightleft">
                                                            {
                                                                isArrayWithLength(fleetCloudList) ?
                                                                    <React.Fragment>
                                                                        <Picky id='picky'
                                                                            options={fleetCloudList}
                                                                            valueKey='value'
                                                                            labelKey='label'
                                                                            multiple={false}
                                                                            value={cloudSelectedOption}
                                                                            includeSelectAll={false}
                                                                            onChange={cloudOnSelectOption}
                                                                            dropdownHeight={300}
                                                                            numberDisplayed={1}
                                                                            placeholder='Select Cloud'
                                                                            includeFilter={true}
                                                                            keepOpen={false}
                                                                            className="ReactPickyFleetTrafficCloud"

                                                                        />
                                                                    </React.Fragment>
                                                                    :
                                                                    <React.Fragment>
                                                                        <div>No Cloud Option</div>
                                                                    </React.Fragment>
                                                            }
                                                        </Col>
                                                        <Col xs='1' className="paddingrightleft">
                                                            {
                                                                isArrayWithLength(fleetTrafficGroupList) ?
                                                                    <React.Fragment>
                                                                        <Picky id='picky'
                                                                            options={fleetTrafficGroupList}
                                                                            valueKey='value'
                                                                            labelKey='label'
                                                                            multiple={false}
                                                                            value={trafficGroupSelectedOption}
                                                                            includeSelectAll={false}
                                                                            onChange={trafficGroupOnSelectOption}
                                                                            dropdownHeight={300}
                                                                            numberDisplayed={1}
                                                                            placeholder='Select Traffic Group'
                                                                            includeFilter={true}
                                                                            keepOpen={false}
                                                                            className="ReactPickyFleetTrafficCloud"

                                                                        />
                                                                    </React.Fragment>
                                                                    :
                                                                    <React.Fragment>
                                                                        <div style={{ verticalAlign: 'inherit' }}>No TrafficGroup Option</div>
                                                                    </React.Fragment>
                                                            }
                                                        </Col>
                                                        {
                                                            (isArrayWithLength(fleetTenantList) && isArrayWithLength(fleetTrafficGroupList) && isArrayWithLength(fleetRoleList) && isArrayWithLength(fleetCloudList)) ?
                                                                <Col xs='3' style={{ paddingTop: '8px' }} >
                                                                    <FontAwesomeIconMemo onClick={onFleetFilterSubmitted} title="Filter the data" icon={'filter'} className='icon-format' />&nbsp;&nbsp;
                                                                    <FontAwesomeIconMemo onClick={ExportFleetUtilizationKustoQuery} title="Download the Fleet Utilization query" icon={'download'} className='icon-format' />&nbsp;&nbsp;
                                                                    <FontAwesomeIconMemo onClick={exportHeatmapReport} title="Download the Fleet Utilization Heatmap Report" icon={'download'} className='icon-format' />&nbsp;&nbsp;
                                                                    <FontAwesomeIconMemo onClick={onFilterYellowFleetData} title="Filter the Yellow data" icon={'filter'} className={checkedFilterYellowFleetData ? 'icon-format-yellow' : 'icon-format-yellow-invert'} />&nbsp;&nbsp;
                                                                    <FontAwesomeIconMemo onClick={onFilterRedFleetData} title="Filter the Red data" icon={'filter'} className={checkedFilterRedFleetData ? 'icon-format-red' : 'icon-format-red-invert'} />&nbsp;&nbsp;
                                                                </Col>
                                                                : <React.Fragment></React.Fragment>
                                                        }
                                                    </React.Fragment>
                                                }
                                            </Row>
                                        </Col>
                                    </React.Fragment>

                                    : (activeDailyDataTab === 'ViewConfiguration') ?
                                        <React.Fragment>
                                            <Col xs='9'>
                                                <Row>
                                                    {
                                                        <React.Fragment>
                                                            <React.Fragment>
                                                                {
                                                                    (!isNullOrUndefined(ConfigurationView))
                                                                        ?
                                                                        <React.Fragment>
                                                                            <Col xs='1.5' style={{ paddingTop: '8px' }}>
                                                                                <Checkbox
                                                                                    label="MSER View"
                                                                                    checked={checkedMSER}
                                                                                    onCheck={onMSERCheck}
                                                                                />
                                                                            </Col>&nbsp;&nbsp;
                                                                            <Col xs='1.5' style={{ paddingTop: '8px' }} >
                                                                                <Checkbox
                                                                                    label="Monthly Availability"
                                                                                    checked={checkedMonthlyAvailability}
                                                                                    onCheck={onMonthlyAvailabilityCheck}
                                                                                />
                                                                            </Col>&nbsp;&nbsp;
                                                                            <Col lg='1.5' style={{ paddingTop: '8px' }}>
                                                                                <Checkbox
                                                                                    label="Is Reachability"
                                                                                    checked={checkedReachability}
                                                                                    onCheck={onReachabilityCheck}
                                                                                />
                                                                            </Col> &nbsp;&nbsp;
                                                                        </React.Fragment>
                                                                        : <React.Fragment>  </React.Fragment>
                                                                }

                                                                <Col lg='2.5' style={{ paddingTop: '8px' }}>
                                                                    <Checkbox
                                                                        label="Create New View"
                                                                        checked={Newview}
                                                                        onCheck={ChangeNewView}
                                                                    />
                                                                </Col>&nbsp;&nbsp;
                                                                <React.Fragment>
                                                                    {(Newview) ?
                                                                        <Col lg='2'>
                                                                            {(Newview) ?
                                                                                <input type="text" placeholder="View Name" name="viewName" className="form-control" value={ConfigurationView.viewName} onChange={e => ChangeViewName(e, ConfigurationView)} />
                                                                                : <React.Fragment></React.Fragment>
                                                                            }
                                                                        </Col>
                                                                        : <React.Fragment></React.Fragment>
                                                                    }
                                                                </React.Fragment>
                                                                <React.Fragment>
                                                                    {(isArrayWithLength(dailyViewList) && (!Newview))  ?
                                                                        <Col lg='2' style={{ paddingTop: '8px' }}>
                                                                            <Checkbox
                                                                                label="Delete Heatmap"
                                                                                checked={HeatmapInactive}
                                                                                onCheck={e => deleteHeatmapConfirm()}
                                                                            />
                                                                        </Col>
                                                                        : <React.Fragment></React.Fragment>
                                                                    }
                                                                </React.Fragment>                                                                
                                                            </React.Fragment>
                                                        </React.Fragment>


                                                    }
                                                </Row>
                                            </Col>
                                        </React.Fragment>
                                        : <React.Fragment>  </React.Fragment>
                        : (selectedOption.label === 'Weekly') ?
                            <Col xs='11'>
                                <Row>
                                    <Col xs='2' className="paddingrightleft">
                                        <DatePicker className='InputStyle'
                                            selected={weeklyStartDate}
                                            onChange={onweeklyStartDateChange}
                                            filterDate={isWeekday}
                                            maxDate={new Date()}
                                        //customInput={<button className="datePickerInput"> {moment(weeklyStartDate).format("MM/DD/YYYY")} <FontAwesomeIconMemo icon={'table'} className="datePickerCalendarIcon" /></button>}
                                        />
                                    </Col>
                                    <Col xs='0.5'>
                                    </Col>
                                    <Col xs='1.5' className="paddingFilterIcons">
                                        <FontAwesomeIconMemo onClick={(selectedServiceId == 29) ? OpenNewBGMPopup : OpenBGMPopup} icon={'signal'} className='icon-format' />
                                        <a className='paddingFilterIconAnchor' onClick={(selectedServiceId == 29) ? OpenNewBGMPopup : OpenBGMPopup} title='Click to View the Business Growth Metrics data' aria-hidden='false'>
                                            &nbsp;BGM &nbsp;&nbsp;
                                        </a>

                                    </Col>
                                    <Col xs='1.5' className="paddingFilterIcons">
                                        <FontAwesomeIconMemo onClick={GetPerformancePopupData} icon={'external-link-square-alt'} className='icon-format' />
                                        <a className='paddingFilterIconAnchor' onClick={GetPerformancePopupData} target='_blank' rel='tooltip' title='Click to View the Performance Details' aria-hidden='false'>
                                            &nbsp; Performance&nbsp;
                                        </a>
                                    </Col>
                                    <Col xs='1.5' className="paddingFilterIcons">
                                        <FontAwesomeIconMemo onClick={OpenCommentsPopup} icon={'user-edit'} className='icon-format' />
                                        <a onClick={OpenCommentsPopup} className='paddingFilterIconAnchor' target='_blank' rel='tooltip' title='Click to Add/Update the KPI Comments' aria-hidden='false'>
                                            &nbsp; Comments  &nbsp;
                                        </a>
                                    </Col>
                                    <Col xs='1.5' className="paddingFilterIcons">
                                        <FontAwesomeIconMemo title='Click to View the Additional Info' icon={'info-circle'} className='icon-format' />
                                        <a href={IsProd ? 'https://livesite.idfun.msidentity.com/AddSpecifics?serviceid=' + selectedServiceId : 'https://livesite.idfun-test.msidentity.com/AddSpecifics?serviceid=' + selectedServiceId} className='paddingFilterIconAnchor' target='_self' title='Click to View the Additional Info'>
                                            &nbsp; Additional Info
                                        </a>
                                    </Col>&nbsp;&nbsp;
                                    <Col xs='1.5' className="paddingFilterIcons">
                                        <FontAwesomeIconMemo onClick={onCheckHistory} title="Show History" icon={'history'} className={checkHistory ? 'icon-format-history' : 'icon-format-history-invert'} />&nbsp;
                                        <label className='paddingFilterIconAnchor' onClick={onCheckHistory}>History</label>
                                    </Col>&nbsp;&nbsp;
                                    <Col xs='1.5' className="paddingFilterIcons">
                                        <FontAwesomeIconMemo onClick={GetWeeklySelfServeData} title="Weekly Self Serve" icon={'paper-plane'} className='icon-format' />
                                        <a onClick={GetWeeklySelfServeData} className='paddingFilterIconAnchor' target='_blank' rel='tooltip' title='Weekly Self Serve' aria-hidden='false'>
                                            &nbsp; Weekly Self-Serve  &nbsp;
                                        </a>
                                    </Col>&nbsp;
                                    <Col xs='1.5' style={{ paddingTop: '4px' }}>
                                        {(selectedServiceId == 13 || selectedServiceId == 12 || selectedServiceId == 105) ?
                                            <div style={{ paddingTop: '3px' }}>
                                                <FontAwesomeIconMemo title='Click to View the Daily report' icon={'table'} className='icon-format' />
                                                <a href={IsProd ? 'https://livesite.idfun.msidentity.com/DailyData?serviceId=' + selectedServiceId : 'https://livesite.idfun-test.msidentity.com/DailyData?serviceId=' + selectedServiceId} className='paddingFilterIconAnchor' target='_self' title='Click to View the Daily report'>
                                                    &nbsp; Daily Report
                                                </a>
                                            </div> : <React.Fragment></React.Fragment>}
                                    </Col>&nbsp;&nbsp;

                                    <Col xs='2' style={{ paddingTop: '4px' }}>
                                        {(IsEdit) ?
                                            <div style={{ paddingTop: '3px' }}>
                                                <a onClick={saveAllWeeklyChanges} className='button-format fontsmall' rel='tooltip' title='Save all the changes to Database' aria-hidden='false'>
                                                    &nbsp; <FontAwesomeIconMemo icon={'save'} style={{ color: 'white', backgroundColor: 'None', border: 'none' }} /> &nbsp;Save All Changes
                                                </a>
                                            </div>
                                            : <React.Fragment></React.Fragment>}
                                    </Col>
                                </Row>
                            </Col> : (selectedOption.label === 'Monthly') ?
                                <Col xs='11'>
                                    <Row>
                                        <Col xs='3' className="paddingrightleft">
                                            <DatePicker className='InputStyle'
                                                selected={monthlyStartDate}
                                                onChange={onmonthlyStartDateChange}
                                                maxDate={new Date()}
                                                minDate={new Date(2018, 11, 1)}
                                                dateFormat="MM/yyyy"
                                                showMonthYearPicker
                                            //customInput={<button className="datePickerInput"> {moment(monthlyStartDate).format("MM/YYYY")} <FontAwesomeIconMemo icon={'table'} className="datePickerCalendarIcon" /></button>}
                                            />
                                        </Col>
                                        <Col xs='7'>
                                        </Col>
                                        <Col xs='2' className="paddingFilterIcons" style={{ textAlign: 'right' }}>
                                            <FontAwesomeIconMemo title='Click to View the Additional Info' icon={'info-circle'} className='icon-format' />
                                            <a className='paddingFilterIconAnchor' href={IsProd ? 'https://livesite.idfun.msidentity.com/AddSpecifics?serviceid=' + selectedServiceId : 'https://livesite.idfun-test.msidentity.com/AddSpecifics?serviceid=' + selectedServiceId} target='_self' title='Click to View the Additional Info'>
                                                &nbsp; Additional Info &nbsp;&nbsp; </a>
                                        </Col>
                                    </Row>
                                </Col> : <div></div>
                    }
                </Row>
            </Container>
            <Dialog visible={HeatmapDialog} style={{ width: '450px' }} header="Confirm" modal footer={hideHeatmapFooter} onHide={hideHeatmapDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', color: 'red' }} />
                    <span>Are you sure you want to Delete the Heatmap?</span>
                </div>
            </Dialog>

        </div>
    );
});

ServiceHealthFilter.displayName = 'ServiceHealthFilter';

export default ServiceHealthFilter;
