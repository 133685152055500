import { BaseService } from './base.service';
import { UserScenarioType } from '../store/UserScenarioCompletion';

/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class UserScenarioCompletionService extends BaseService {
    private static _serviceGroupService: UserScenarioCompletionService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): UserScenarioCompletionService {
        return (this._serviceGroupService || (this._serviceGroupService = new this('UserScenarioCompletion')));
    }

    public async GetUserScenarioDetailsById(endDate, scenarioId): Promise<any> {
        const { data } = await this.$http.get<any>(`GetUserScenarioDetailsById?endDate=${endDate}&scenarioId=${scenarioId}`);
        return data;
    }

    public async GetUserScenario(): Promise<UserScenarioType[]> {
        const { data } = await this.$http.get<UserScenarioType[]>("GetUserScenario");
        return data;
    }
}

export const UserScenarioCompletionAPI = UserScenarioCompletionService.Instance;
