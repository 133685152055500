import { MSERHeatmapPreviewAPI, CommonAPI, ServiceGroupApi, DailyDataApi } from '../../api';
import { IAppThunkAction, ReduxAction } from '../';
import { ActionType } from './types';
import { IServiceGroup } from '../home-tiles';
import { IAllViewDetails, IViewDetails, IDailyData, IColorScheme, IMSERAdditionalInfo, HmMetric } from '../service-health/types';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../utils';
import moment from 'moment';
import IdleTimer from 'react-idle-timer';
import { toast } from "react-toastify";
import {
    IPropOnBoardedHMService, IPropOnBoardedMSERService
} from './types';
let dailyDataPresentationRaw: IDailyData[] = [];
export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }

    },
    handledashboardClose: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.DASHBOARD_POPUP_CLOSE
        });
    },
    GetHeatMapServicesOnboardedData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let HeatMapNotOnboarded;
        let HeatMapOnboarded;
        dispatch({          
            type: ActionType.HEATMAP_ONBOARD_FETCH
        }); 
        MSERHeatmapPreviewAPI.GetHeatMapServicesOnboardedData()
            .then((servicesonboarded: IPropOnBoardedHMService[]) => {
                if (isArrayWithLength(servicesonboarded)) {
                    HeatMapNotOnboarded = servicesonboarded[0].objHeatMapNotOnboarded;
                    HeatMapOnboarded = servicesonboarded[0].objHeatMapNotOnboarded;
                }
                dispatch({
                    HeatMapNotOnboarded,
                    HeatMapOnboarded,
                    type: ActionType.HEATMAP_ONBOARD_SUCCESS
                });
            });
    },
    GetMSERServicesOnboardedData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let objMSEROnboarded;
        let objMSERNotOnboarded;
        dispatch({
            type: ActionType.HEATMAP_MSER_ONBOARD_FETCH
        });       
        MSERHeatmapPreviewAPI.GetMSERServicesOnboardedData()
            .then((servicesonboarded: IPropOnBoardedMSERService[]) => {
                if (isArrayWithLength(servicesonboarded)) {
                    objMSEROnboarded = servicesonboarded[0].objMSEROnboarded;
                    objMSERNotOnboarded = servicesonboarded[0].objMSERNotOnboarded;
                }
                dispatch({
                    objMSEROnboarded,
                    objMSERNotOnboarded,
                    type: ActionType.HEATMAP_MSER_ONBOARD_SUCCESS
                });
            });
    },
    GetMserPresentationHeatViewList: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING
        });
        const now = new Date();
        const toDate = new Date(now.getFullYear(), now.getMonth(), 0);
        const fromDate = new Date(now.getFullYear() - (now.getMonth() > 0 ? 0 : 1), (now.getMonth() - 1 + 12) % 12, 1);
        MSERHeatmapPreviewAPI.GetMserPresentationHeatViewList()
            .then((MSERHeatmapViews: IAllViewDetails[]) => {
                let servicename = '';
                let viewname = '';
                let viewid = 0;
                let currentMSERVIEW = null;
                if (isArrayWithLength(MSERHeatmapViews)) {
                    currentMSERVIEW = MSERHeatmapViews[0];
                }
                dispatch({
                    MSERHeatmapViews: MSERHeatmapViews,                   
                    currentindex: 0,
                    lastindex: (isArrayWithLength(MSERHeatmapViews) ? MSERHeatmapViews.length - 1 : 0),
                    currentMSERVIEW: currentMSERVIEW,
                    fromDate,
                    toDate,                  
                    type: ActionType.GET_MSER_HEATMAPPRESENTATION_VIEWS_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    fromDate,
                    toDate,
                    type: ActionType.GET_MSER_HEATMAPPRESENTATION_VIEWS_SUCCESS
                });
            });
    },
    handleStartDateChange: (startDate: Date): ReduxAction => ({
        startDate: startDate,
        type: ActionType.START_DATE_CHANGE
    }),
    handleEndDateChange: (endDate: Date): ReduxAction => ({
        endDate: endDate,
        type: ActionType.END_DATE_CHANGE
    }),
    requestPresentationDailyData: (serviceId: number, stDate: string, enDate: string, viewId: number): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {

        dispatch({
            type: ActionType.MSER_HEATMAPDATA_PRESENTATION_FETCH
        });
        let SelectedView = null;
        const dayViews = ["35 Days", "7 Days"];
        let SelectChanged = false;
        const colorScheme = [];
        const additionalInfoData = [];
        let viewdetails;
        let volumeMeasure = "M";
        let failurevolumeMeasure = 'M';

        let currentview = getSate().MSERPresentationView.currentMSERPresentationVIEW;
        DailyDataApi.getViewDataAsyn(serviceId, viewId)
            .then((Allviewdetails_data: IViewDetails[]) => {
                SelectedView = Allviewdetails_data;
                viewdetails = SelectedView[0];
                if (isArrayWithLength(SelectedView)) {
                    DailyDataApi.getColorSchemeAsyn(SelectedView[0].ColorSchemeID)
                        .then((colorlogic: IColorScheme[]) => {
                            colorlogic.map(item => {
                                colorScheme.push(item);
                            });
                        });
                }
                DailyDataApi.getMSERCommentsAdditionalInfo(viewId, stDate, enDate)
                    .then((mserAdditionalInfo: IMSERAdditionalInfo[]) => {
                        if (isArrayWithLength(mserAdditionalInfo)) {
                            mserAdditionalInfo.map(row => {
                                additionalInfoData.push({
                                    additionInfo: row.dataCenter,
                                    additionInfoId: row.additionInfoId,
                                    keyDate: row.dataCenter + " - " + moment(row.effectiveDate).format("MM/DD"),
                                    impact: row.impact,
                                    rootCause: row.rootCause,
                                    mitigation: row.mitigation,
                                    learningsAndRepairItems: row.learningsAndRepairItems,
                                    lastUpdated: row.updatedDate,
                                    effectiveDate: moment(row.effectiveDate).format("MM/DD/YYYY")
                                });
                            });
                        }
                    });
                DailyDataApi.getDailyDataAsyn(serviceId, stDate, enDate, viewId)
                    .then((dailyData: IDailyData[]) => {
                        let selectedDayView = dayViews[0];

                        const comments = [];
                        let uniqueFixedHeaderColumns = [];
                        const uniqueDateHeaders = [];
                        const CHECKuniqueDateHeaders = [];
                        const originaldatacentersList = [];
                        dailyData.map((item, key) => {
                            // To get the List of Data Centers for displaying it in MSER Comments
                            if (item.key !== 'Total Volume' && item.key !== 'Daily Agg.') {
                                originaldatacentersList.push({ id: item.key, name: item.key })
                            }
                        })
                        // Logic to remove the Duplicates from an Object Array and Sort Alphabatically
                        let datacentersList = originaldatacentersList.filter((ele, ind) => ind === originaldatacentersList.findIndex(elem => elem.id === ele.id && elem.name === ele.name))
                        datacentersList.sort((a, b) => (a.id > b.id) ? 1 : -1);

                        //Get longest Datacenter name to set the fixed column width dynamically
                        let longestDC = datacentersList.reduce((a, b) => a > b.name.length ? a : b.name.length, 0);
                        //========== to get the fixed columns list
                        dailyData.map((item, parentkey) => {
                            let volume = 0;
                            let failureVolume = 0;
                            let datacenter = item.key;
                            if (item.fixedColumns !== undefined && item.fixedColumns !== null) {
                                item.fixedColumns.map(subItem => {
                                    if (uniqueFixedHeaderColumns.indexOf(subItem.key) === -1) {
                                        uniqueFixedHeaderColumns.push(subItem.key)
                                    }
                                })
                            }
                            if (!item.isOutOfSlaUptime && !item.isDailyVolume) {
                                item.metricList.map((subItem, subkey) => {
                                    //Flag for MSER Comment indicator
                                    subItem.hasMserComment = false;
                                    additionalInfoData.map((row, rowkey) => {
                                        if (row.additionInfo.split(',').includes(datacenter) && moment(row.effectiveDate).format("MM/DD/YYYY") == moment(subItem.dateKey).format("MM/DD/YYYY")) {
                                            subItem.hasMserComment = true;
                                        }
                                    });

                                    if (CHECKuniqueDateHeaders.indexOf(subItem.dateKey) === -1) {
                                        uniqueDateHeaders.push({
                                            'key': subItem.dateKey, 'value': subItem.dateKey, 'sortkey': (isNullOrUndefined(subItem.dateKey) ? '' : new Date(subItem.dateKey))
                                        });
                                        CHECKuniqueDateHeaders.push(subItem.dateKey);
                                    }
                                    subItem.SortdateKey = new Date(subItem.dateKey.toString());

                                    if (subItem.volume) {
                                        volume += subItem.volume;
                                    }

                                    if (subItem.failureVolume) {
                                        failureVolume += subItem.failureVolume;
                                    }
                                    //// Define the toolbar for each cell

                                    subItem.value.value1 = Calc2Decimal(subItem.value.value1);
                                    subItem.updatedValue1 = Calc2Decimal(subItem.updatedValue1);

                                    subItem.tooltip = '';
                                    subItem.IsshowBulk = false;

                                    if (subItem.volume && subItem.volume > 0) {
                                        subItem.tooltip = viewdetails.totalsLabel + ': ' + subItem.volume + '\n';
                                    }
                                    const noOfDecimals = subItem.value.displayDecimals;
                                    subItem.tooltip += 'Original ' + viewdetails.val1Label + ': ' + displayDecimals(subItem.value.value1, noOfDecimals);
                                    //$scope.selectedView.hasVal2 --> set value as true

                                    if (currentview.hasVal2) {

                                        subItem.tooltip += '\nOriginal ' + viewdetails.val2Label + ': ' + displayDecimals(subItem.value.value2, noOfDecimals);
                                    }
                                    if (subItem.value.value1 !== subItem.updatedValue1 && subItem.updatedValue1) {
                                        subItem.tooltip += '\nModified  ' + viewdetails.val1Label + ': ' + displayDecimals(subItem.updatedValue1, noOfDecimals);
                                    }

                                    if (subItem.value.value2 !== subItem.updatedValue2 && subItem.updatedValue2 && true) {
                                        subItem.tooltip += '\nModified  ' + viewdetails.val2Label + ': ' + displayDecimals(subItem.updatedValue2, noOfDecimals);
                                    }

                                    if (subItem.comment) {
                                        //subItem.lastUpdated = moment(subItem.lastUpdated).format('Do MMM YYYY').toString();
                                        subItem.incident = (subItem.incident === undefined || subItem.incident === null) ? '' : subItem.incident;
                                        subItem.tooltip += '\nComment:  ' + subItem.comment + '\nIncident:  ' + subItem.incident + '\nAuthor:    ' + subItem.author + '\nLast Updated:    ' + moment(subItem.lastUpdated).format('Do MMM YYYY').toString();
                                    }
                                    if (!subItem.updatedValue1) {
                                        subItem.updatedValue1 = subItem.value.value1;
                                    }
                                    if (!subItem.updatedValue2) {
                                        subItem.updatedValue2 = subItem.value.value2;
                                    }
                                    //subItem.updatedValue1 = subItem.value.value1;
                                    //subItem.updatedValue2 = subItem.value.value2;

                                    if (subItem.comment) {
                                        var value: any = null;
                                        comments.map(item => {
                                            if (item.commentId === subItem.commentId) {
                                                value = item;
                                            }
                                        })
                                        if (value != undefined) {
                                            value.keyDate += ", ( " + item.key + " - " + moment(subItem.dateKey).format("MM/DD") + " )";
                                            value.lastUpdated = subItem.lastUpdated;
                                        } else {
                                            comments.push({
                                                comment: subItem.comment,
                                                incident: subItem.incident,
                                                // index: autoIncrementIndex,
                                                commentId: subItem.commentId,
                                                keyDate: "( " + item.key + " - " + moment(subItem.dateKey).format("MM/DD") + " )",
                                                lastUpdated: subItem.lastUpdated,
                                                lastUpdatedDate: new Date(subItem.lastUpdated)
                                            });
                                        }

                                    }

                                    //})
                                })
                                const sortedActivities12 = item.metricList.sort((a, b) => a.SortdateKey - b.SortdateKey);

                                if (item.avg !== undefined && item.avg !== null) {
                                    item.avg.percentage = parseFloat(item.avg.percentage).toFixed(item.avg.displayDecimals);
                                }
                            }
                            if (item.volume < 1000000 && item.volumeDisplayType == 'd') {
                                volumeMeasure = "K";
                            }
                            else if (item.volumeDisplayType == 'm') {
                                volumeMeasure = "M";
                            } else if (item.volumeDisplayType == 'k') {
                                volumeMeasure = "K";
                            }

                            // If at least one volume is < 1M
                            if (item.failureVolume < 1000000 && item.volumeDisplayType == 'd') {
                                failurevolumeMeasure = "K";
                            }
                            else if (item.volumeDisplayType == 'm') {
                                failurevolumeMeasure = "M";
                            } else if (item.volumeDisplayType == 'k') {
                                failurevolumeMeasure = "K";
                            }

                            item.metricList.map(subItem => {
                                subItem.value.percentage = (subItem.value.percentage !== '' && subItem.value.percentage !== undefined) ? parseFloat(subItem.value.percentage).toFixed(subItem.value.displayDecimals) : '';
                            });
                            if (item.isTotalVolume) {
                                item.metricList.map(subItem => {
                                    subItem.value.percentage = subItem.value.percentage;
                                });

                            }
                            item.volume = volume;
                            item.failureVolume = failureVolume;
                            item.sortVolume = item.volume != null ? item.volume : item.failureVolume;
                            item.sortFailureVolume = item.failureVolume != null ? item.failureVolume : 0;


                        })

                        let finaldata = SetVolumeMeasure(volumeMeasure, dailyData);
                        const sortedActivities1 = comments.sort((a, b) => a.lastUpdatedDate - b.lastUpdatedDate);
                        const dates = uniqueDateHeaders.sort((a, b) => a.sortkey - b.sortkey);
                        dailyDataPresentationRaw = dailyData;
                        dispatch({
                            dailyData: finaldata,
                            uniqueFixedHeaderColumns: uniqueFixedHeaderColumns,
                            uniqueDateHeaders: uniqueDateHeaders,
                            SelectedView: SelectedView,
                            comments: comments,
                            colorScheme: colorScheme,
                            datacentersList,
                            additionalInfoData,
                            volumeMeasure,
                            failurevolumeMeasure,
                            longestDC,
                            type: ActionType.MSER_HEATMAP_PRESENTATION_DATA_RECEIVE
                        });
                    });

            });
    },
    onFilterYellowDailyData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let dailyDataRedYellow: IDailyData[] = [];
        let checkedFilterYellowDailyData = getState().MSERPresentationView.checkedFilterYellowDailyData;
        let checkedFilterRedDailyData = getState().MSERPresentationView.checkedFilterRedDailyData;
        if (isArrayWithLength(dailyDataPresentationRaw)) {
            checkedFilterYellowDailyData = !checkedFilterYellowDailyData;
            if (checkedFilterYellowDailyData && !checkedFilterRedDailyData) {
                dailyDataPresentationRaw.map((obj: IDailyData) => {
                    if (obj.isYellowRow && !obj.isTotalVolume && obj.key !== 'Daily Agg.') {
                        dailyDataRedYellow.push(obj);
                    }
                });
                dispatch({
                    dailyData: dailyDataRedYellow,
                    checked: checkedFilterYellowDailyData,
                    type: ActionType.DAILY_FILTER_YELLOW
                });

            }
            else if (!checkedFilterYellowDailyData && checkedFilterRedDailyData) {
                dailyDataPresentationRaw.map((obj: IDailyData) => {
                    if (obj.isRedRow && !obj.isTotalVolume && obj.key !== 'Daily Agg.') {
                        dailyDataRedYellow.push(obj);
                    }
                });
                dispatch({
                    dailyData: dailyDataRedYellow,
                    checked: checkedFilterYellowDailyData,
                    type: ActionType.DAILY_FILTER_YELLOW
                });

            }
            else if (checkedFilterYellowDailyData && checkedFilterRedDailyData) {
                dailyDataPresentationRaw.map((obj: IDailyData) => {
                    if ((obj.isYellowRow || obj.isRedRow) && !obj.isTotalVolume && obj.key !== 'Daily Agg.') {
                        dailyDataRedYellow.push(obj);
                    }
                });
                dispatch({
                    dailyData: dailyDataRedYellow,
                    checked: checkedFilterYellowDailyData,
                    type: ActionType.DAILY_FILTER_YELLOW
                });

            }
            else if (!checkedFilterYellowDailyData && !checkedFilterRedDailyData) {
                dispatch({
                    dailyData: dailyDataPresentationRaw,
                    checked: checkedFilterYellowDailyData,
                    type: ActionType.DAILY_FILTER_YELLOW
                });
            }
        }
    },
    onFilterRedDailyData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let dailyDataRedYellow: IDailyData[] = [];
        let checkedFilterYellowDailyData = getState().MSERPresentationView.checkedFilterYellowDailyData;
        let checkedFilterRedDailyData = getState().MSERPresentationView.checkedFilterRedDailyData;
        if (isArrayWithLength(dailyDataPresentationRaw)) {
            checkedFilterRedDailyData = !checkedFilterRedDailyData;
            if (checkedFilterRedDailyData && !checkedFilterYellowDailyData) {
                dailyDataPresentationRaw.map((obj: IDailyData) => {
                    if (obj.isRedRow && !obj.isTotalVolume && obj.key !== 'Daily Agg.') {
                        dailyDataRedYellow.push(obj);
                    }
                });
                dispatch({
                    dailyData: dailyDataRedYellow,
                    checked: checkedFilterRedDailyData,
                    type: ActionType.DAILY_FILTER_RED
                });

            }
            else if (!checkedFilterRedDailyData && checkedFilterYellowDailyData) {
                dailyDataPresentationRaw.map((obj: IDailyData) => {
                    if (obj.isYellowRow && !obj.isTotalVolume && obj.key !== 'Daily Agg.') {
                        dailyDataRedYellow.push(obj);
                    }
                });
                dispatch({
                    dailyData: dailyDataRedYellow,
                    checked: checkedFilterRedDailyData,
                    type: ActionType.DAILY_FILTER_RED
                });

            }
            else if (checkedFilterRedDailyData && checkedFilterYellowDailyData) {
                dailyDataPresentationRaw.map((obj: IDailyData) => {
                    if ((obj.isRedRow || obj.isYellowRow) && !obj.isTotalVolume && obj.key !== 'Daily Agg.') {
                        dailyDataRedYellow.push(obj);
                    }
                });
                dispatch({
                    dailyData: dailyDataRedYellow,
                    checked: checkedFilterRedDailyData,
                    type: ActionType.DAILY_FILTER_RED
                });

            }
            else if (!checkedFilterRedDailyData && !checkedFilterYellowDailyData) {
                dispatch({
                    dailyData: dailyDataPresentationRaw,
                    checked: checkedFilterRedDailyData,
                    type: ActionType.DAILY_FILTER_RED
                });
            }
        }
    },
    // Function to toggle MSER Comments // 
    toggleMSERCommentsView: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let state = getState();
        let showMSERComments = state.MSERPresentationView.showMSERComments;
        showMSERComments = !showMSERComments;
        dispatch({
            showMSERComments: showMSERComments,
            type: ActionType.SET_MSERCOMMENTS
        });
    },
    getNextService: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let currentindex = getState().MSERPresentationView.currentindex + 1;
        let MSERViews = getState().MSERPresentationView.MSERPresentationViews;
        let fromDate = getState().MSERPresentationView.startDate;
        let toDate = getState().MSERPresentationView.endDate;
        let currentMSERVIEW = null;
        if (isArrayWithLength(MSERViews)) {
            currentMSERVIEW = MSERViews[currentindex];
        }
        dispatch({
            MSERHeatmapViews: MSERViews,           
            currentindex: currentindex,           
            lastindex: (isArrayWithLength(MSERViews) ? MSERViews.length - 1 : 0),
            currentMSERVIEW: currentMSERVIEW,
            fromDate,
            toDate,
            type: ActionType.GET_MSER_HEATMAPPRESENTATION_VIEWS_SUCCESS
        });


     
    },
    getPreviousService: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let currentindex = getState().MSERPresentationView.currentindex - 1;
        let MSERViews = getState().MSERPresentationView.MSERPresentationViews;
        let fromDate = getState().MSERPresentationView.startDate;
        let toDate = getState().MSERPresentationView.endDate;
        let currentMSERVIEW = null;
        if (isArrayWithLength(MSERViews)) {
            currentMSERVIEW = MSERViews[currentindex];
        }
        dispatch({
            MSERHeatmapViews: MSERViews,           
            currentindex: currentindex,            
            lastindex: (isArrayWithLength(MSERViews) ? MSERViews.length - 1 : 0),
            currentMSERVIEW: currentMSERVIEW,
            fromDate,
            toDate,
            type: ActionType.GET_MSER_HEATMAPPRESENTATION_VIEWS_SUCCESS
        });
    },
};
const SetVolumeMeasure = (volumeMeasure, heatmapData) => {

    heatmapData.map((row: IDailyData) => {
        switch (volumeMeasure) {
            case "M":
                row.volume = parseFloat((row.volume / 1000000).toString()).toFixed(2); // .toLocaleString(undefined, { 'minimumFractionDigits': 1, 'maximumFractionDigits': 1 });
                //row.failureVolume = parseFloat(row.failureVolume / 1000000); 
                break;
            case "K":
                row.volume = parseFloat((row.volume / 1000).toString()).toFixed(2); // .toLocaleString(undefined, { 'minimumFractionDigits': 1, 'maximumFractionDigits': 1 });
                //row.failureVolume = parseFloat(row.failureVolume / 1000); 
                break;
        }
        row.failureVolume = parseFloat(row.failureVolume).toFixed(2);
        if (row.isTotalVolume) {
            row.metricList.map((metric: HmMetric) => {
                switch (volumeMeasure) {
                    case "M":
                        metric.value.percentage = (parseFloat(metric.value.percentage) / 1000000).toLocaleString(undefined, { "minimumFractionDigits": 1, "maximumFractionDigits": 1 });
                        break;
                    case "K":
                        metric.value.percentage = (parseFloat(metric.value.percentage) / 1000).toLocaleString(undefined, { "minimumFractionDigits": 1, "maximumFractionDigits": 1 });
                        break;
                }
            });
        }
    });
    return heatmapData;
};
const Calc2Decimal = (value: any) => {
    if (value) {
        const num = value;
        const with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
        return with2Decimals;
    }
};
const displayDecimals = (value: any, vals: any) => {
    return parseFloat(value).toFixed(parseInt(vals));
};
const converttoKMB = (val: any) => {
    if (val === 0) {
        val = 0;
    }
    else {
        if (val < 0) {
            val = (val).toFixed(2);
        }
        else if (val < 10 && val >= 1) {
            val = parseInt(val).toFixed(2);
        }
        else if (val >= 10 && val < 100) {
            val = parseInt(val).toFixed(1);
        }
        else if (val <= 999 && val >= 100) {// hundreds
            val = parseInt(val);
        }
        else if (val >= 1000 && val <= 999999) {// thousands
            val = (val / 1000);
            const length = parseInt(val).toString().length;
            if (length > 2) {
                val = parseInt(val) + 'K';
            }
            else if (length === 2) {
                val = parseInt(val).toFixed(1) + 'K';
            }
            else {
                val = parseInt(val).toFixed(2) + 'K';
            }

        }
        else if (val >= 1000000 && val <= 999999999) { // millions
            val = (val / 1000000);
            const length = parseInt(val).toString().length;
            if (length > 2) {
                val = parseInt(val) + 'M';
            }
            else if (length === 2) {
                val = val.toFixed(1) + 'M';
            }
            else {
                val = val.toFixed(2) + 'M';
            }

        }
        else if (val >= 1000000000 && val <= 999999999999) {// billions
            val = (val / 1000000000);
            const length = parseInt(val).toString().length;
            if (length > 2) {
                val = parseInt(val) + 'B';
            }
            else if (length === 2) {
                val = val.toFixed(1) + 'B';
            }
            else {
                val = val.toFixed(2) + 'B';
            }
        }
        else if (val >= 1000000000000) {// trillions
            val = (val / 1000000000000);
            const length = parseInt(val).toString().length;
            if (length > 2) {
                val = parseInt(val) + 'T';
            }
            else if (length === 2) {
                val = val.toFixed(1) + 'T';
            }
            else {
                val = val.toFixed(2) + 'T';
            }
        }
        else {
            val = val;
        }
    }
    return val;
}
