
export type IPropLastWeekIdNewsLetterData = {

}
export type ILastWeekIdNewsLetterState = {
    readonly isLoading: boolean;
    readonly WeekStartDate: string;
    readonly StartDate: string;
    readonly EndDate: string;
    readonly appSessionToken: string;
    readonly DateRange: string;
    readonly newsLettertopOutagesDataTable: [];
    readonly originalNewsLettertopOutagesData: [];
    readonly newsLettertopOutagesData: [];
    readonly NewsLetterTilesData: [];
    readonly NewsLetterTilesRawData: [];
    readonly NewsLetterTilesCount: number;
    readonly OoslaPIRs: TileDescription[];
    readonly OoslaSTRI: TileDescription[];
    readonly PirCompletionData: IPropPirCompletion[];
    readonly pirHTMLContent: string;
    readonly repairItemshtmlContent: string;
    readonly STRepairItemsData: IPropSTRepairItems[];
    readonly lastWeekOnCallData: IPropLastWeekOnCall[];
};

export type IPropPirCompletion = {
    readonly outageAutoID: number;
    readonly weekDate: string;
    readonly weekId: number;
    readonly incidentURL: string;
    readonly incidentId: number;
    readonly createDate: string;
    readonly mitigateDate: string;
    readonly incidentTitle: string;
    readonly owningTenantName: string;
    readonly owningTenantId: number;
    readonly owningTeamName: string;
    readonly owningTeamId: number;
    readonly outageIncidentSeverity: number;
    readonly isAutoDetected: boolean;
    readonly pirStatus: string;
    readonly causedByParentIncidentId: number;
    readonly notificationSourceType: string;
    readonly ttd: string;
    readonly ttE_First: string;
    readonly ttE_Last: string;
    readonly ttFix: string;
    readonly ttm: string;
    readonly ttn: string;
    readonly ttMString: string;
    readonly repairCount: number;
    readonly rootCauseCategory: string;
    readonly rootCauseDescription: string;
    readonly mitigation: string;
    readonly howFixed: string;
    readonly subCount: number;
    readonly owningContactAlias: string;
    readonly outageOwnerName: string;
    readonly pirLink: string;
    readonly daysSinceMitigation: string;
    readonly isCausedBy: boolean;
    readonly isAcmAutoComms: boolean;
    readonly impactedTenants: string;
    readonly impactedTenantsBool: boolean;
    readonly changeRelated: boolean;
    readonly ring_Detected: string;
    readonly total_Rings: string;
    readonly lens_IngestionTime: string;
    readonly ringDetected_Binary: boolean;
    readonly total_Rings_Binary: boolean;
    readonly piR_InSLO: number;
    readonly piR_InSLO_Text: string;
    readonly isActive: boolean;
    readonly updatedBy: string;
    readonly updatedDate: string;
}

export type IPropLastWeekIdentityTiles = {
    readonly kpI_Id: number;
    readonly weekDate: string;
    readonly weekId: number;
    readonly kpI_Section1_Value: string;
    readonly kpI_Section2_Value: string;
    readonly kpI_Section3_Value: string;
    readonly title: string;
    readonly description: string;
    readonly reportLink: string;
    readonly kpI_Section1: string;
    readonly kpI_Section2: string;
    readonly kpI_Section3: string;
    readonly kpI_Section1_Links: string;
    readonly kpI_Section2_Links: string;
    readonly kpI_Section3_Links: string;
    readonly updatedBy: string;
    readonly updatedDate: string;
    readonly htmlContent: string;
    readonly kpI_Header: string;
    readonly kpI_Footer: string;
    readonly kpI_Section1_DIFF: string;
    readonly kpI_Section2_DIFF: string;
    readonly kpI_Section1_SIGN: string;
    readonly kpI_Section2_SIGN: string;
}

export type IPropSTRepairItems = {
    readonly WorkItemId: number;
    readonly CreatedDate: string;
    readonly State: string;
    readonly IcMDeliveryType: string;
    readonly Tags: string;
    readonly AreaPath: string;
    readonly Age: string;
    readonly Sla: string;
    readonly ServiceGroupName: string;
    readonly ServiceId: string;
    readonly ServiceName: string;
    readonly TeamGroupId: string;
    readonly TeamGroupName: string;
    readonly AreaPathMapping: string;
    readonly Title: string;
    readonly AssignedToDisplayName: string;
    readonly IcMRepairItemType: string;
    readonly WorkItemType: string;
    readonly IterationPath: string;
    readonly DaysOpen: string;
    readonly OriginalETA: string;
    readonly RevisedETA: string;
    readonly ETA: string;
    readonly url: string;
    readonly InSlo: string;
    readonly ETABinary: string;
}

export type IPropIdentityTopOutages = {
    readonly topOutageID: number;
    readonly numberOfSev1: string;
    readonly numberOfSev2: string;
    readonly numberOfUniqueCCL: string;
    readonly weekDate: string;
    readonly weekId: number;
    readonly sev1Customer: string;
    readonly sev2Customer: string;
    readonly outageswithExternal: string;
    readonly incidentNotes: string;
    readonly notes: string;
    readonly isActive: boolean;
    readonly createdBy: string;
    readonly createdDate: string;
    readonly updatedBy: string;
    readonly updatedDate: string;
    readonly topOutageTable: string;
    readonly oncallTeamTable: string;
    readonly topOutageOpen: string;
    readonly pirClosed: string;
};

export type IPropIdentityTopOutagesTable = {
    readonly severity: string;
    readonly incidentId: string;
    readonly tenantteam: string;
    readonly teamName: string;
    readonly impactStartTimeUTC: string;
    readonly incidentCreateDateUTC: string;
    readonly outageDeclaredDateUTC: string;
    readonly notifiedTimeUTC: string;
    readonly mitigateDateUTC: string;
    readonly ttm: string;
    readonly ttn: string;
    readonly impactedtenants: string;
    readonly subCount: string;
    readonly pirStatus: string;
};

export type DropdownOption = {
    name: string;
    value: number;
};

export type TileDescription = {
    title: string;
    description: string;
    reportlink: string;
}

export type IPropLastWeekOnCall = {
    execOnCall: string;
    crisisManager: string;
    srimManager: string;
    execOnCallBackup: string;
    crisisManagerBackup: string;
    srimManagerBackup: string;
    execOnCallEmail: string;
    crisisManagerEmail: string;
    srimManagerEmail: string;
    execOnCallBackupEmail: string;
    crisisManagerBackupEmail: string;
    srimManagerBackupEmail: string;
}

const _namespace = 'LastWeekIdNewsLetter';

export interface IActionType {
    readonly RESET_STATE: string;
    readonly SET_LOADING_TRUE: string;
    readonly SET_LOADING_FALSE: string;
    readonly ADDED_VISITED_USER: string;
    readonly WEEKLY_START_DATE_CHANGE: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_SUCCESS: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_FAILURE: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_TABLE_SUCCESS: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_TABLE_FAILURE: string;
    readonly TOPOUTAGES_INPUT_CHANGE: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTERSEV1SEV2INCIDENTSDATA_SUCCESS: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTERSEV1SEV2INCIDENTSDATA_FAILURE: string;
    readonly DELETE_TOPOUTAGE_DATA_FAIL: string;
    readonly REFRESH_TOPOUTAGE_DATA_FAIL: string;
    readonly REFRESH_TOPOUTAGE_DATA_REQUEST: string;
    readonly DELETE_TOPOUTAGE_DATA_REQUEST: string;
    readonly GET_IDENTITYNEWSLTTERTILESDATA_SUCCESS: string;
    readonly GET_IDENTITYNEWSLTTERTILESDATA_FAILURE: string;
    readonly TILESINPUT_CHANGE: string;
    readonly TILESINPUT_CLEAR: string;
    readonly UPDATETILE_SUCCESS: string;
    readonly UPDATETILE_FAILURE: string;
    readonly GET_PIRCOMPLETIONDATA_SUCESS: string;
    readonly GET_PIRCOMPLETIONDATA_FAILURE: string;
    readonly SEND_EMAIL_REQUEST: string;
    readonly SEND_EMAIL_REQUEST_FAIL: string;
    readonly SEND_EMAIL_REQUEST_PASS: string;
    readonly SET_EMAIL_LOADING_TRUE: string;
    readonly GET_STREPAIRITEMS_SUCCESS: string;
    readonly GET_STREPAIRITEMS_FAILURE: string;
    readonly GET_LASTWEEKONCALLDATA_SUCCESS: string;
    readonly GET_LASTWEEKONCALLDATA_FAILURE: string;
}

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/reset`,
    SET_LOADING_TRUE: `${_namespace}/setloadingtrue`,
    SET_LOADING_FALSE: `${_namespace}/setloadingfalse`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    WEEKLY_START_DATE_CHANGE: `${_namespace}/weeklystartDateChange`,
    GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_SUCCESS: `${_namespace}/getidentityweeklynewslettertopoutagesdatasuccess`,
    GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_FAILURE: `${_namespace}/getidentityweeklynewslettertopoutagesdatafailure`,
    GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_TABLE_SUCCESS: `${_namespace}/getidentityweeklynewslettertopoutagesdatatablesuccess`,
    GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_TABLE_FAILURE: `${_namespace}/getidentityweeklynewslettertopoutagesdatatablefailure`,
    TOPOUTAGES_INPUT_CHANGE: `${_namespace}/topoutagesinputChange`,
    GET_IDENTITYWEEKLYNEWSLETTERSEV1SEV2INCIDENTSDATA_SUCCESS: `${_namespace}/getidentityweeklynewslettersev1sev2incidentsdatafailure`,
    GET_IDENTITYWEEKLYNEWSLETTERSEV1SEV2INCIDENTSDATA_FAILURE: `${_namespace}/getidentityweeklynewslettersev1sev2incidentsdatafailure`,
    DELETE_TOPOUTAGE_DATA_FAIL: `${_namespace}/deletetopoutagedatafail`,
    REFRESH_TOPOUTAGE_DATA_FAIL: `${_namespace}/refreshtopoutagedatafail`,
    REFRESH_TOPOUTAGE_DATA_REQUEST: `${_namespace}/refreshtopoutagedatarequest`,
    DELETE_TOPOUTAGE_DATA_REQUEST: `${_namespace}/deletetopoutagedatarequest`,
    GET_IDENTITYNEWSLTTERTILESDATA_SUCCESS: `${_namespace}/getidentitynewslettertilesdatasuccess`,
    GET_IDENTITYNEWSLTTERTILESDATA_FAILURE: `${_namespace}/getidentitynewslettertilesdatafailure`,
    TILESINPUT_CHANGE: `${_namespace}/tilesinputchange`,
    TILESINPUT_CLEAR: `${_namespace}/tilesinputclear`,
    UPDATETILE_SUCCESS: `${_namespace}/updatetilesuccess`,
    UPDATETILE_FAILURE: `${_namespace}/updatetilefailure`,
    GET_PIRCOMPLETIONDATA_SUCESS: `${_namespace}/getpircompletiondatasuccess`,
    GET_PIRCOMPLETIONDATA_FAILURE: `${_namespace}/getpircompletiondatafailure`,
    SEND_EMAIL_REQUEST: `${_namespace}/send_email_request`,
    SEND_EMAIL_REQUEST_FAIL: `${_namespace}/send_email_request_fail`,
    SEND_EMAIL_REQUEST_PASS: `${_namespace}/send_email_request_pass`,
    SET_EMAIL_LOADING_TRUE: `${_namespace}/set_email_loading_true`,
    GET_STREPAIRITEMS_SUCCESS: `${_namespace}/getstrepairitemssuccess`,
    GET_STREPAIRITEMS_FAILURE: `${_namespace}/getstrepairitemsfailure`,
    GET_LASTWEEKONCALLDATA_SUCCESS: `${_namespace}/getlastweekoncalldatasuccess`,
    GET_LASTWEEKONCALLDATA_FAILURE: `${_namespace}/getlastweekoncalldatafailure`
});

