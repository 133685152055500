export interface IActionType {
    readonly AVAILABILITY_SUMMARY_REQUEST: string;
    readonly AVAILABILITY_SUMMARY_RECEIVE: string;
    readonly RESET_STATE: string;
    readonly ADDED_VISITED_USER: string;
}

export type IAvailabilitySummary = {
    readonly groupName: string;
    readonly serviceId: number;
    readonly parentReachabilityServiceId: string;
    readonly serviceName: string;
    readonly SSLName: string;
    readonly kpi: string;
    readonly metricSource: boolean;
    readonly metricName: string;
    readonly environment: string;
    readonly targetName: string;
    readonly ml03Id: number;
    readonly week1: string;
    readonly week2: string;
    readonly week3: string;
    readonly week4: string;
    readonly week5: string;
    readonly week1Edit: string;
    readonly week2Edit: string;
    readonly week3Edit: string;
    readonly week4Edit: string;
    readonly week5Edit: string;
    readonly week1Sort: string;
    readonly week2Sort: string;
    readonly week3Sort: string;
    readonly week4Sort: string;
    readonly week5Sort: string;
    readonly week1Hiscount: number;
    readonly week2Hiscount: number;
    readonly week3Hiscount: number;
    readonly Week4Hiscount: number;
    readonly Week5Hiscount: number;
    readonly isUpdated: boolean;
    readonly comments: string;
    readonly spanKpi: number;
    readonly spanMetric: number;
    readonly isMetricRed: boolean;
    readonly kpiIndex: number;
    readonly history: IPropKpiHistory
    readonly displayType: boolean;
    readonly isChatForKpi: boolean;
    readonly isEditable: boolean;
    readonly minRange: string;
    readonly maxRange: string;
    readonly interval: string;
    readonly uptimeTarget: string;
    readonly serviceUptime: string;
    readonly uom: string;
    readonly envId: number;
    readonly qoSTarget: string;
    readonly qoS: string;
    readonly validationExpr: string;
    readonly entrySource: boolean;
    readonly perfPopupGrpId: number;
    readonly customerSla: string;
    readonly lsi: string;
    readonly config: number;
    readonly subEnv: number;
};

export type IPropKpiHistory = {
    readonly ierviceId: number;
    readonly weekId: string;
    readonly oldVal: string;
    readonly metricGroupName: string;
    readonly metricName: string;
    readonly metricId: number;
    readonly kpiValue: string;
    readonly comments: string;
    readonly updatedDate: Date;
    readonly updatedBy: string;
    readonly uom: string;
    readonly versionId: number;   
    
}

export type IAvailabilitySummaryState = {
    readonly isLoading: boolean;
    readonly availabilitySummary: IAvailabilitySummary[];
};

const _namespace = 'availability ';

export const ActionType = Object.freeze<IActionType>({
    AVAILABILITY_SUMMARY_REQUEST: `${_namespace}/availabityFetch`,
    AVAILABILITY_SUMMARY_RECEIVE: `${_namespace}/availabityReceive`,
    RESET_STATE: `${_namespace}/reset`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`
});

