import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, ISecuritySHRState } from './types';

const initialState = Object.freeze<ISecuritySHRState>({
    isLoading: false,
    userId: '',
    MeetingDate: null,
    DefaultDate: null,
    MaxDate: new Date(),
    isPubServiceAccEditorAddEnabled: false,
    isLockedSecurity: true,
    isLockedSecurityArray: [],
    isPSALockDisabledOnEdit: false,
    PublicServiceAnnouncementData: [],
    psaaccordiontoggle: true,
    SecurityAdminLock: [],
    SuccessStoriesData: [],
    isSuccessStoriesEditorAddEnabled: false,
    isLockedSuccessStories: true,
    isLockedSuccessStoriesArray: [],
    isSSLockDisabledOnEdit: false,
    ssaccordiontoggle: true
});

export const reducer = (
    state: ISecuritySHRState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {
        case ActionType.SET_MEETINGDATE_DATE:
            return {
                ...state,
                userId: action.userId,
                MeetingDate: action.meetingdate,
                DefaultDate: action.meetingdate,
                MaxDate: action.maxdate

            };
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true

            };
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false

            };
        case ActionType.GET_WEEKLYIDSHR_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.MEETINGDATE_DATE_CHANGE:
            return {
                ...state,
                isLockedSecurity: action.isLockedSecurity,
                isLockedSuccessStories: action.isLockedSuccessStories,
                MeetingDate: action.MeetingDate

            };
        case ActionType.SET_MEETINGDATE_DATE:
            return {
                ...state,
                MeetingDate: action.MeetingDate
            };
        case ActionType.GET_SECURITYADMINLOCK:
            return {
                ...state,
                isLockedSecurity: action.isLockedSecurity,
                isLockedSecurityArray: action.isLockedSecurityArray,
                isLockedSuccessStories: action.isLockedSuccessStories,
                isLockedSuccessStoriesArray: action.isLockedSuccessStoriesArray,
                isLoading: false
            }
        case ActionType.ENABLE_EDITORADD_PUBLICSERVICEACC:  // === Enable add or Edit for Public Service Announcement ===============//
            return {
                ...state,
                PublicServiceAnnouncementData: action.PublicServiceAnnouncementData,
                isPubServiceAccEditorAddEnabled: true,
                isPSALockDisabledOnEdit: action.isPSALockDisabledOnEdit,
                psaaccordiontoggle: true
            };
        case ActionType.GET_PUBLICSERVICEACC_SUCCESS:  // === for Public Service Announcement success ===============//
            return {
                ...state,
                PublicServiceAnnouncementData: action.PublicServiceAnnouncementData,
                isPubServiceAccEditorAddEnabled: false,
                isLoading: false,
                isPSALockDisabledOnEdit: action.isPSALockDisabledOnEdit,
                psaaccordiontoggle: action.psaaccordiontoggle
            };
        case ActionType.GET_PUBLICSERVICEACC_FAILURE:  // === for Public Service Announcement failure ===============//
            return {
                ...state,
                PublicServiceAnnouncementData: [],
                isLoading: false,
            };
        case ActionType.PUBLICSERVICEANNOUNCEMENT_CHANGE:  // === for Public Service Announcement value changes ===============//
            return {
                ...state,
                PublicServiceAnnouncementData: action.PublicServiceAnnouncementData,
            };
        case ActionType.ENABLE_EDITORADD_SUCCESSSTORIES:  // === Enable add or Edit for Success Stories ===============//
            return {
                ...state,
                SuccessStoriesData: action.SuccessStoriesData,
                isSuccessStoriesEditorAddEnabled: true,
                isSSLockDisabledOnEdit: action.isSSLockDisabledOnEdit,
                ssaccordiontoggle: true
            };
        case ActionType.GET_SUCCESSSTORIES_SUCCESS:  // === for Success Stories success ===============//
            return {
                ...state,
                SuccessStoriesData: action.SuccessStoriesData,
                isSuccessStoriesEditorAddEnabled: false,
                isLoading: false,
                isSSLockDisabledOnEdit: action.isSSLockDisabledOnEdit,
                ssaccordiontoggle: action.ssaccordiontoggle
            };
        case ActionType.GET_SUCCESSSTORIES_FAILURE:  // === for Success Stories failure ===============//
            return {
                ...state,
                SuccessStoriesData: [],
                isLoading: false,
            };
        case ActionType.SUCCESSSTORIES_CHANGE:  // === for Success Stories value changes ===============//
            return {
                ...state,
                SuccessStoriesData: action.SuccessStoriesData,
            };
        case ActionType.PSAACCORDION_STATUS:  // === Toggle Accordion Public announcement ===============//
            return {
                ...state,
                psaaccordiontoggle: action.psaaccordiontoggle
            };
        case ActionType.SSACCORDION_STATUS:  // === Toggle Accordion success stories ===============//
            return {
                ...state,
                ssaccordiontoggle: action.ssaccordiontoggle
            };
        case ActionType.RESET_STATE:
            return initialState;
        default:
            return state;
    }
};
