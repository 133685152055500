import { ServiceGroupApi, CommonAPI } from '../../api';
import { IAppThunkAction, ReduxAction } from '../';
import { ActionType, IServiceGroup, IPropCalender } from './types';
import { isNullOrUndefined } from '../../utils';
export const actionCreators = {

    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }

    },
    requestServiceGroup: (startGroupIndex: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        // If param startDateIndex === state.startDateIndex, do not performe action
        if (startGroupIndex === getState().serviceGroup.startGroupIndex) {
            return;
        }

        // Dispatch request
        dispatch({
            startGroupIndex,
            type: ActionType.REQUEST
        });

        // Build http request and success handler in Promise<void> wrapper
        ServiceGroupApi.getServiceGroupAsyn(startGroupIndex)
            .then((serviceGroup: IServiceGroup[]) => {                
                for (var i = 0; i < serviceGroup.length; i++) {
                    serviceGroup[i].IsDirectorChecked = true;
                    serviceGroup[i].IsTeamGroupChecked = true;
                    serviceGroup[i].IServiceGroupChecked = (serviceGroup[i].isWeeklyReport === 'False' && serviceGroup[i].isHeatMap === false)?false:true;
                    serviceGroup[i].IsToggle = true;
                    serviceGroup[i].enableservicescount = 2;
                }
                //ServiceGroupApi.GetDateForRefreshedData(startGroupIndex)
                //    .then((DataRefreshed: IPropCalender[]) => {
                       
                        dispatch({                           
                            serviceGroup,
                            startGroupIndex,
                            type: ActionType.RECEIVE
                        });
                  /*  });*/
            });
    },
    changetoggle: (e, Director, TemapGroup, Group, Service, Level): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const stateCopy = getState().serviceGroup.serviceGroup;
        //const stateCopy = getState().serviceGroup.serviceGroup;
        const group: any = [];
        let toggledirectorlist = [];
        let test = {};
        for (var i = 0; i < stateCopy.length; i++) {
            if (toggledirectorlist.indexOf(stateCopy[i].groupName) < 0) {
                toggledirectorlist.push(stateCopy[i].groupName);
                test[stateCopy[i].groupName] = 0;
            }
            if (Level === 0) {
                if (stateCopy[i].directorName === Director) {
                    stateCopy[i].IsDirectorChecked = e;
                    stateCopy[i].IServiceGroupChecked = (stateCopy[i].isWeeklyReport === 'False' && stateCopy[i].isHeatMap === false) ? stateCopy[i].IServiceGroupChecked: e;
                    stateCopy[i].IsTeamGroupChecked = e;
                    group.push(stateCopy[i]);
                }
                else {
                    group.push(stateCopy[i]);
                }
            }
            else if (Level === 1) {
                if (stateCopy[i].directorName === Director && stateCopy[i].teamGroupName === TemapGroup && stateCopy[i].groupName === Group) {
                    stateCopy[i].IServiceGroupChecked = (stateCopy[i].isWeeklyReport === 'False' && stateCopy[i].isHeatMap === false) ? stateCopy[i].IServiceGroupChecked : e;
                    stateCopy[i].IsTeamGroupChecked = e;
                    group.push(stateCopy[i]);
                }
                else {

                    group.push(stateCopy[i]);
                }
            }
            else if (Level === 2) {
                if (stateCopy[i].directorName === Director && stateCopy[i].teamGroupName === TemapGroup && stateCopy[i].groupName === Group && stateCopy[i].serviceName === Service) {
                    stateCopy[i].IServiceGroupChecked = (stateCopy[i].isWeeklyReport === 'False' && stateCopy[i].isHeatMap === false) ? stateCopy[i].IServiceGroupChecked : e;
                    group.push(stateCopy[i]);
                }
                else {
                    group.push(stateCopy[i]);
                }
            }
            if (group[i].IServiceGroupChecked) {//========= to count how many services are for each group
                test[group[i].groupName] = test[group[i].groupName] + 1;
            }
            

        }
        const directorList: any = [];
        const Groups: any = [];        
        group.map((dhT) => {
            dhT.enableservicescount = test[dhT.groupName];
            if (dhT.IServiceGroupChecked === false && dhT.isWeeklyReport !== 'False' && dhT.isHeatMap !== false) {
                directorList.push(dhT.directorName);
                Groups.push(dhT.groupName);
            }
        })

        if (directorList.length >= 1) {
            for (var i = 0; i < group.length; i++) {
                if (directorList.indexOf(group[i].directorName) >= 0) {
                    group[i].IsDirectorChecked = false;
                }
                if (Groups.indexOf(group[i].groupName) >= 0) {
                    group[i].IsTeamGroupChecked = false;
                }
                else {
                    group[i].IsTeamGroupChecked = true;
                }

            }
        }
        else {
            for (var i = 0; i < group.length; i++) {
                group[i].IsDirectorChecked = true;
                group[i].IsDirectorChecked = true;
                group[i].IsTeamGroupChecked = true;
            }
        }
        dispatch({           
            serviceGroup: group,         
            type: ActionType.TOGGLE_CHANGE
        });
    }
};
