import React, {  useState } from 'react';
import { IWeekDetails, IWeeklyReport, IPropDraft } from '../../../store/service-health';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'react-datepicker/dist/react-datepicker.css';

import { isNullOrUndefined, isArrayWithLength, renderToastifyMsg } from '../../../utils';
import info_icon from '../../../assets/images/info_icon.png';
import cancel from '../../../assets/images/cancel.png';
import hinew from '../../../assets/images/hinew.png';
import { Card, Row, Col, CardText, CardTitle, CardBody, CardHeader } from 'reactstrap';
import FontAwesomeIconMemo from '../../../components/FontAwesomeIconMemo';

import moment from 'moment';
import { ReduxAction } from '../../../store';

type WeeklyReportProps = {
     WeeklyData?: IWeeklyReport[];
    readonly weekDetailsData?: IWeekDetails;
    readonly draftData?: IPropDraft;
    TDCapactyMaagement: any;
    AddColorForFact: any;
    ColorRepireItem: any;
    weeklyStartDate: any;
    IsEdit: boolean;
    ChangeIsEditval: any;
    ChangeLastweekcellvalue: any;
    ChangeLastweekcellcomments: any;
    serviceId: any;
    saveAllWeeklyChanges: any;
    showHistorypopup: any;
    LoadKPICalculationData: any;
    setrowcountData: any;
    OpenBGMPopup: any;
    loadChatKpi: any;
    commentsData: any;
    GetPerformancePopupData: any;
    IsWeeklyDataloaded: boolean;
    IsWeeklyDataDisabled: boolean;
    IsProd: boolean;
   
    readonly showHistory: boolean;
};

const WeeklyReport = React.memo<WeeklyReportProps>(({
    WeeklyData,
    weekDetailsData,
    draftData,
    TDCapactyMaagement,
    AddColorForFact,
    ColorRepireItem,
    weeklyStartDate,
    IsEdit,
    ChangeIsEditval,
    ChangeLastweekcellvalue,
    ChangeLastweekcellcomments,
    serviceId,
    saveAllWeeklyChanges,
    showHistorypopup,
    LoadKPICalculationData,
    setrowcountData,
    OpenBGMPopup,
    loadChatKpi,
    commentsData,
    GetPerformancePopupData,
    IsWeeklyDataloaded,
    IsWeeklyDataDisabled,
    showHistory,
    IsProd

}) => {
    const datediff = () => {
        var now = moment();
        var selected = moment(weeklyStartDate);
        var diff = now.diff(selected, "day");
        return diff;
    }
    //const [ShowHistory, SetShowHistory] = useState(false);
    //const ChangeHistory = (e) => {
    //    SetShowHistory(e.target.checked);
    //}
    var HasAvailabilityGraph = false;
    var HasMonitoringGraph = false;
    var HasSupportGraph = false;
    if (isArrayWithLength(WeeklyData)) {
        WeeklyData.map(subItem => {
            if (subItem.isChatForKpi && (subItem.kpi == "Availability")) {
                HasAvailabilityGraph = true;
            }
            else if (subItem.isChatForKpi && (subItem.kpi == "Monitoring")) {
                HasMonitoringGraph = true;
            }
            else if (subItem.isChatForKpi && (subItem.kpi == "Support")) {
                HasSupportGraph = true;
            }
        })
    }
    const getsubdata = (wkrpt, key1) => {
        return (           
                <React.Fragment>
                {
                    (isArrayWithLength(WeeklyData))
                        ?                       
                        (IsProd === false && wkrpt.kpi == 'Capacity')
                            ?
                            <React.Fragment>
                                <td className='data'>{wkrpt.targetName}</td>
                                <td className={'data ' + TDCapactyMaagement(wkrpt.week1.toString()).css.toString()} >{TDCapactyMaagement(wkrpt.week1.toString()).value}</td>
                                <td className={'data ' + TDCapactyMaagement(wkrpt.week2.toString()).css.toString()} >{TDCapactyMaagement(wkrpt.week2.toString()).value}</td>
                                <td className={'data ' + TDCapactyMaagement(wkrpt.week3.toString()).css.toString()} >{TDCapactyMaagement(wkrpt.week3.toString()).value}</td>
                                <td className={'data ' + TDCapactyMaagement(wkrpt.week4.toString()).css.toString()} >{TDCapactyMaagement(wkrpt.week4.toString()).value}</td>
                                <td className={'data ' + TDCapactyMaagement(wkrpt.week5.toString()).css.toString()} >{TDCapactyMaagement(wkrpt.week5.toString()).value}</td>                               
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <td className='data'>{wkrpt.targetName}</td>
                                <td className={'data ' + AddColorForFact(wkrpt).week1.toString()}>{wkrpt.week1}</td>
                                <td className={'data ' + AddColorForFact(wkrpt).week2.toString()}>{wkrpt.week2}</td>
                                <td className={'data ' + AddColorForFact(wkrpt).week3.toString()}>{wkrpt.week3}</td>
                                <td className={'data ' + AddColorForFact(wkrpt).week4.toString()}>{wkrpt.week4}</td>
                                <td className={'data ' + AddColorForFact(wkrpt).week5.toString()}>
                                    {
                                        (wkrpt.history !== null && wkrpt.history.length > 1)
                                            ?
                                            <React.Fragment>
                                                {
                                                    (!isNaN(parseFloat(wkrpt.week5)) && (wkrpt.kpi == "Monitoring" || wkrpt.kpi == "Support"))
                                                        ?
                                                        <React.Fragment>
                                                            {
                                                                ((wkrpt.metricName.indexOf("Repair Items") !== -1))
                                                                    ?
                                                                    <React.Fragment>                                                                        
                                                                        {
                                                                            (IsEdit)
                                                                                ? <input style={{ borderColor: wkrpt.isvalRed ? 'red' :'lightcyan' }} type='text' id={'txtWeek5' + key1} value={wkrpt.week5Edit} onChange={e => ChangeLastweekcellvalue(e, key1)} />                                                                          
                                                                            : 
                                                                            <React.Fragment>
                                                                                    <p style={{ color: 'blue' }} title={wkrpt.week5ToolTip}>
                                                                                        <a style={{ textDecoration: 'underline', textAlign: 'center', color: '#007bff'}}  title='TFS Task Details' onClick={e => setrowcountData(e, key1)}>

                                                                                        <span dangerouslySetInnerHTML={{ __html: ColorRepireItem(wkrpt.week5) }}></span>
                                                                                    </a>
                                                                                </p>
                                                                            </React.Fragment>
                                                                    }                                                                       
                                                                    </React.Fragment>
                                                                    :
                                                                    <React.Fragment>                                                                      
                                                                    {
                                                                        (IsEdit)
                                                                                ? <input style={{ borderColor: wkrpt.isvalRed ? 'red' : 'lightcyan' }} type='text' id={'txtWeek5' + key1} value={wkrpt.week5Edit} onChange={e => ChangeLastweekcellvalue(e, key1)}/>
                                                                            :
                                                                            <React.Fragment>
                                                                                    <p style={{ color: 'blue' }} title={wkrpt.week5ToolTip}>
                                                                                        <a style={{ textDecoration: 'underline', textAlign: 'center', color: '#007bff' }}  title='Task Details' onClick={e => setrowcountData(e, key1)} > {wkrpt.week5}</a>
                                                                                </p>
                                                                            </React.Fragment>
                                                                    }
                                                                    </React.Fragment>
                                                            }
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>                                                           
                                                        {
                                                            (IsEdit)
                                                                    ? <input style={{ borderColor: wkrpt.isvalRed ? 'red' : 'lightcyan' }} type='text' id={'txtWeek5' + key1} value={wkrpt.week5Edit} onChange={e => ChangeLastweekcellvalue(e, key1)}/>
                                                                :
                                                                <React.Fragment>
                                                                        <p style={{ color: 'blue' }} title={wkrpt.week5ToolTip} >{wkrpt.week5}
                                                                    </p>
                                                                </React.Fragment>
                                                        }
                                                        </React.Fragment>
                                                }
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                {
                                                    (wkrpt.entrySource && !wkrpt.week5 && datediff() > 9 && datediff() <= 14)
                                                        ?
                                                    <React.Fragment>
                                                        <img title='Data is not available in Data Source' src={info_icon} style={{ width: '18px', backgroundColor: 'lightYellow', borderRadius: '18px'}} />                                                            
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                        </React.Fragment>
                                                }
                                                <React.Fragment>
                                                    {
                                                        (!isNaN(parseFloat(wkrpt.week5)) && (wkrpt.kpi == "Monitoring" || wkrpt.kpi == "Support" || wkrpt.kpi == "Support Query Response Time"
                                                            || wkrpt.kpi == "Schema deployment – API Versions"))
                                                            ?
                                                            <React.Fragment>
                                                                {
                                                                    ((wkrpt.metricName.indexOf("Repair Items") !== -1) || (wkrpt.metricName.indexOf("Graph Onboarding Email") !== -1))
                                                                        ?
                                                                        <React.Fragment>                                                                           
                                                                        {
                                                                            (IsEdit)
                                                                                    ? <input style={{ borderColor: wkrpt.isvalRed ? 'red' : 'lightcyan' }} type='text' id={'txtWeek5' + key1} value={wkrpt.week5Edit} onChange={e => ChangeLastweekcellvalue(e, key1)}/>
                                                                                :
                                                                                <React.Fragment>
                                                                                    <p>
                                                                                            <a style={{ textDecoration: 'underline', textAlign: 'center', color: '#007bff' }}  title='TFS Task Details' onClick={e => setrowcountData(e, key1)} >
                                                                                            <span dangerouslySetInnerHTML={{ __html: ColorRepireItem(wkrpt.week5) }}></span>
                                                                                        </a>
                                                                                    </p>
                                                                                </React.Fragment>
                                                                        }
                                                                        </React.Fragment>
                                                                        :
                                                                        <React.Fragment>                                                                           
                                                                        {
                                                                            (IsEdit)
                                                                                    ? <input style={{ borderColor: wkrpt.isvalRed ? 'red' : 'lightcyan' }} type='text' id={'txtWeek5' + key1} value={wkrpt.week5Edit} onChange={e => ChangeLastweekcellvalue(e, key1)}/>
                                                                                :
                                                                                <React.Fragment>
                                                                                    <p>
                                                                                            <a style={{ textDecoration: 'underline', textAlign: 'center', color: '#007bff' }} title={((wkrpt.kpi == "Schema deployment – API Versions") || (wkrpt.kpi == "Support Query Response Time")) ? 'Deployment details - Total/In SLA/Out Of SLA' : 'Ticket Details'} onClick={e => setrowcountData(e, key1)}> {wkrpt.week5}</a>
                                                                                    </p>
                                                                                </React.Fragment>
                                                                        }
                                                                        </React.Fragment>
                                                                }
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>                                                               
                                                            {
                                                                (IsEdit)
                                                                        ? <input style={{ borderColor: wkrpt.isvalRed ? 'red' : 'lightcyan' }} type='text' id={'txtWeek5' + key1} value={wkrpt.week5Edit} onChange={e => ChangeLastweekcellvalue(e, key1)}/>
                                                                    :
                                                                    <React.Fragment>
                                                                        <p>{wkrpt.week5}
                                                                        </p>
                                                                    </React.Fragment>
                                                            }
                                                            </React.Fragment>
                                                    }
                                                </React.Fragment>
                                            </React.Fragment>
                                    }
                                </td>
                            </React.Fragment>
                        : <React.Fragment> </React.Fragment>
                    }
                </React.Fragment>           
            );
    }
    return (
        <div id='table_wrapper' style={{width:'100%'}}>          
            <div>                
            </div>
            {
                (IsWeeklyDataDisabled)
                    ? <div style={{ color: 'Red', fontWeight: 'bold', textAlign: 'center' }}><br />Weekly report has been disabled. Please contact service owner for more details.</div>
                    :
                    (isArrayWithLength(WeeklyData))
                        ?
                        <React.Fragment>
                            <Row className="fontsize14">   
                                    <Col xs='7'>
                                    </Col>
                                    <Col xs='5' style={{ textAlign: 'right'}}>                                    
                                        <label className='legend-format'><FontAwesomeIconMemo icon={'stop'} className='icon-format-legend-green' />&nbsp;Value within range</label>&nbsp;                                  
                                        <label className='legend-format'> <FontAwesomeIconMemo icon={'stop'} className='icon-format-legend-red' />&nbsp;Value out of range</label>&nbsp;                                  
                                        <label className='legend-format'><FontAwesomeIconMemo icon={'stop'} className='icon-format-legend-gray' />&nbsp;No value</label>&nbsp;
                                    </Col>
                                </Row>
                            <Row>
                                <Col lg={isArrayWithLength(commentsData)?"10":"12"}>
                                    <table className='tableGrid'>
                                        <thead className='thead-inverse' id="AvailbilityGraphdata">
                                            <tr className="weeklytr">
                                                <th className="weeklytrth" style={{ width: '150px' }}>KPI's</th>
                                                <th className="weeklytrth" style={{ width: '500px' }}>Metric</th>
                                                <th className="weeklytrth" style={{ width: '150px' }}>Environment</th>
                                                <th className="weeklytrth">Target</th>
                                                <React.Fragment>
                                                    {
                                                        (weekDetailsData != undefined && weekDetailsData != null)
                                                            ?
                                                            <React.Fragment>
                                                                <th className="weeklytrth">{weekDetailsData.weeksDetails.weekDet1}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week1}</span></th>
                                                                <th className="weeklytrth">{weekDetailsData.weeksDetails.weekDet2}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week2}</span></th>
                                                                <th className="weeklytrth">{weekDetailsData.weeksDetails.weekDet3}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week3}</span></th>
                                                                <th className="weeklytrth">{weekDetailsData.weeksDetails.weekDet4}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week4}</span></th>
                                                                <th className="weeklytrth" style={{ width: '145px' }}>
                                                                    &nbsp;{weekDetailsData.weeksDetails.weekDet5}<br />
                                                                    {(draftData != undefined && draftData != null)
                                                                        ? (!draftData.isFinalised)
                                                                            ? <span title='DRAFT' style={{ fontSize: '7px' }} className='badge shoppingBadge shoppingBadge-custom'>DRAFT</span>
                                                                            : <React.Fragment></React.Fragment>
                                                                        : <React.Fragment></React.Fragment>}
                                                                    <span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week5}&nbsp;&nbsp;</span>
                                                                    <React.Fragment>
                                                                        {
                                                                            (IsEdit)
                                                                                ? <span onClick={e => ChangeIsEditval(e, false)} style={{ cursor: 'pointer' }}>
                                                                                    <img title='Click to Cancel Edit' src={cancel} style={{ width: '20px', height: '20px' }} />
                                                                                </span>
                                                                                : <span onClick={e => ChangeIsEditval(e, true)} style={{ cursor: 'pointer' }}>
                                                                                    <FontAwesomeIcon title='Click to edit the last week data' className="Editiconsfontsize" icon={'pencil-alt'} />
                                                                                </span>
                                                                        }
                                                                    </React.Fragment>
                                                                </th>
                                                            </React.Fragment>
                                                            : <React.Fragment> </React.Fragment>
                                                    }
                                                </React.Fragment>
                                                <React.Fragment>
                                                    {
                                                        (IsEdit)
                                                            ? <th className="weeklytrth">Comments</th>
                                                            : <React.Fragment></React.Fragment>
                                                    }
                                                </React.Fragment>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                isArrayWithLength(WeeklyData) && WeeklyData.map((wkrpt: IWeeklyReport, key1) => (
                                                    <React.Fragment key={key1}>
                                                        <tr className={wkrpt.spanKpi > 0 ? "newKPI" : "emptyKpi"}>
                                                            <React.Fragment>
                                                                {
                                                                    (wkrpt.spanKpi > 0)
                                                                        ?
                                                                        <td id={'tdkpi' + key1} className="kpi" rowSpan={wkrpt.spanKpi}>
                                                                            {
                                                                                (wkrpt.kpi === 'Availability')
                                                                                    ?
                                                                                    <React.Fragment>
                                                                                        <img title='Click on the KPI to view Calculation details' src={info_icon} style={{ width: '18px' }} />&nbsp;
                                                                    <a style={{ textDecoration: 'underline', color: '#0080D5', opacity: '0.8' }} onClick={e => LoadKPICalculationData(e, wkrpt.kpi)} title='Calculation details'>Availability</a>
                                                                                        {
                                                                                            (HasAvailabilityGraph)
                                                                                                ? <a onClick={e => loadChatKpi(e, key1, true, wkrpt.kpi)} title='Graph for week-wise comparison of all Metric'>&nbsp;<FontAwesomeIcon title='Click to edit the last week data' className='icon-format-graph' icon={'chart-line'} /></a>
                                                                                                : <React.Fragment></React.Fragment>
                                                                                        }
                                                                                    </React.Fragment>
                                                                                    :
                                                                                    (wkrpt.kpi === 'Secrets Management' || wkrpt.kpi === 'Customer notification - JIT pipeline' || wkrpt.kpi === 'Customer notification - API pipeline'
                                                                                        || wkrpt.kpi === 'Response to JIT' || wkrpt.kpi === 'Web API' || wkrpt.kpi === 'Schema deployment – API Versions' || wkrpt.kpi === 'Support Query Response Time' || wkrpt.kpi === 'Client Health')
                                                                                        ?
                                                                                        <React.Fragment>
                                                                                            <img title='Click on the KPI to view Calculation details' src={info_icon} style={{ width: '18px' }} />&nbsp;
                                                                                                <a style={{ textDecoration: 'underline', color: '#0080D5', opacity: '0.8' }} onClick={e => LoadKPICalculationData(e, wkrpt.kpi)} title={wkrpt.kpi}>{wkrpt.kpi}</a>
                                                                                        </React.Fragment>
                                                                                        :
                                                                                    (wkrpt.kpi === 'Monitoring')
                                                                                        ?
                                                                                        <React.Fragment>
                                                                                            <img title='Click on the KPI to view Monitoring details' src={info_icon} style={{ width: '18px' }} />&nbsp;
                                                                                                <a style={{ textDecoration: 'underline', color: '#0080D5', opacity: '0.8' }} onClick={e => LoadKPICalculationData(e, wkrpt.kpi)} title='Monitoring'>{wkrpt.kpi}</a>
                                                                                                {
                                                                                                    (HasMonitoringGraph)
                                                                                                        ? <a onClick={e => loadChatKpi(e, key1, true, wkrpt.kpi)} title='Graph for week-wise comparison of all Metric'>&nbsp;<FontAwesomeIcon title='Click to edit the last week data' className='icon-format-graph' icon={'chart-line'} /></a>
                                                                                                        : <React.Fragment></React.Fragment>
                                                                                                }
                                                                                        </React.Fragment>
                                                                                        :
                                                                                        (wkrpt.kpi === 'Support')
                                                                                            ?
                                                                                            <React.Fragment>
                                                                                                <img title='Click on the KPI to view Support details' src={info_icon} style={{ width: '18px' }} />&nbsp;
                                                                                                    <a style={{ textDecoration: 'underline', color: '#0080D5', opacity: '0.8' }} onClick={e => LoadKPICalculationData(e, wkrpt.kpi)} title='Support'>{wkrpt.kpi}</a>
                                                                                                    {
                                                                                                        (HasSupportGraph)
                                                                                                            ? <a onClick={e => loadChatKpi(e, key1, true, wkrpt.kpi)} title='Graph for week-wise comparison of all Metric'>&nbsp;<FontAwesomeIcon title='Click to edit the last week data' className='icon-format-graph' icon={'chart-line'} /></a>
                                                                                                            : <React.Fragment></React.Fragment>
                                                                                                    }
                                                                                            </React.Fragment>
                                                                                            :
                                                                                            (wkrpt.kpi === 'Capacity')
                                                                                                ?
                                                                                                <React.Fragment>
                                                                                                    <img title='Click on the KPI to view Capacity details' src={info_icon} style={{ width: '18px' }} />&nbsp;
                                                                                <a style={{ textDecoration: 'underline', color: '#0080D5', opacity: '0.8' }} onClick={e => LoadKPICalculationData(e, wkrpt.kpi)} title='Capacity' >{wkrpt.kpi}</a>
                                                                                                </React.Fragment>
                                                                                                :
                                                                                                (wkrpt.kpi === 'Business Continuity')
                                                                                                    ?
                                                                                                    <React.Fragment>
                                                                                                        <img title='Click on the KPI to view Business Continuity details' src={info_icon} style={{ width: '18px' }} />&nbsp;
                                                                                    <a style={{ textDecoration: 'underline', color: '#0080D5', opacity: '0.8' }} onClick={e => LoadKPICalculationData(e, wkrpt.kpi)} title='Business Continuity'>{wkrpt.kpi}</a>
                                                                                                    </React.Fragment>
                                                                                                    :
                                                                                                    (wkrpt.kpi === 'Security')
                                                                                                        ?
                                                                                                        <React.Fragment>
                                                                                                            <img title='Click on the KPI to view Security details' src={info_icon} style={{ width: '18px' }} />&nbsp;
                                                                                        <a style={{ textDecoration: 'underline', color: '#0080D5', opacity: '0.8' }} onClick={e => LoadKPICalculationData(e, wkrpt.kpi)} title='Security'>{wkrpt.kpi}</a>
                                                                                                        </React.Fragment>
                                                                                                        :
                                                                                                        (wkrpt.kpi === 'Data Quality')
                                                                                                            ?
                                                                                                            <React.Fragment>
                                                                                                                <img title='Click on the KPI to view Data Quality' src={info_icon} style={{ width: '18px' }} />&nbsp;
                                                                                            <a style={{ textDecoration: 'underline', color: '#0080D5', opacity: '0.8' }} onClick={e => LoadKPICalculationData(e, wkrpt.kpi)} title='Data Quality'>{wkrpt.kpi}</a>
                                                                                                            </React.Fragment>
                                                                                                            :
                                                                                                            (wkrpt.kpi === 'Deployment Cadence')
                                                                                                                ?
                                                                                                                <React.Fragment>
                                                                                                                    <img title='Click on the KPI to view Deployment Cadence' src={info_icon} style={{ width: '18px' }} />&nbsp;
                                                                                                <a style={{ textDecoration: 'underline', color: '#0080D5', opacity: '0.8' }} onClick={e => LoadKPICalculationData(e, wkrpt.kpi)} title='Deployment Cadence'>{wkrpt.kpi}</a>
                                                                                                                </React.Fragment>
                                                                                                                :
                                                                                                                (wkrpt.kpi === 'LiveSite Worker')
                                                                                                                    ?
                                                                                                                    <React.Fragment>
                                                                                                                        <img title='Click on the KPI to view LiveSite worker' src={info_icon} style={{ width: '18px' }} />&nbsp;
                                                                                                    <a style={{ textDecoration: 'underline', color: '#0080D5', opacity: '0.8' }} onClick={e => LoadKPICalculationData(e, wkrpt.kpi)} title='LiveSite worker'>{wkrpt.kpi}</a>
                                                                                                                    </React.Fragment>
                                                                                                                    :
                                                                                                                    <React.Fragment>
                                                                                                                        {wkrpt.kpi}
                                                                                                                    </React.Fragment>
                                                                            }
                                                                        </td>
                                                                        :
                                                                        <React.Fragment>
                                                                        </React.Fragment>
                                                                }
                                                            </React.Fragment>
                                                            <React.Fragment>
                                                                {
                                                                    (wkrpt.spanMetric >= 0)
                                                                        ?
                                                                        <td className='ml02' rowSpan={wkrpt.spanMetric}>
                                                                            {wkrpt.metricName}
                                                                        </td>
                                                                        :
                                                                        <React.Fragment>
                                                                        </React.Fragment>
                                                                }
                                                            </React.Fragment>
                                                            <td className='ml02'>
                                                                {wkrpt.environment}
                                                                <React.Fragment>{(wkrpt.isChatForKpi)
                                                                    ?
                                                                    <React.Fragment>
                                                                        <a title='Graph for week-wise comparison of Metric' onClick={e => loadChatKpi(e, key1, false, wkrpt.kpi)} className='graph' > <FontAwesomeIcon className='icon-format-graph' icon={'chart-line'} /></a>
                                                                    </React.Fragment>
                                                                    :
                                                                    <React.Fragment>
                                                                    </React.Fragment>
                                                                }
                                                                </React.Fragment>
                                                            </td>
                                                            {getsubdata(wkrpt, key1)}
                                                            <React.Fragment>
                                                                {
                                                                    (IsEdit)
                                                                        ? <td className='data'><input type='text' style={{ borderColor: wkrpt.iscmtRed ? 'red' : 'lightcyan' }} value={wkrpt.comments} onChange={e => ChangeLastweekcellcomments(e, key1)} /></td>
                                                                        : <React.Fragment></React.Fragment>
                                                                }
                                                            </React.Fragment>
                                                            {
                                                                (wkrpt.isUpdated && showHistory)
                                                                    ?
                                                                    <td style={{ width: '30px' }}>
                                                                        <div onClick={e => showHistorypopup(key1)}>  <img title='Click to view Updated History' src={hinew} style={{ width: '15px', height: '15px' }} /></div>
                                                                    </td>
                                                                    : <td></td>
                                                            }
                                                        </tr>
                                                    </React.Fragment>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </Col>
                                {isArrayWithLength(commentsData)
                                    ?
                                    <Col lg="2">
                                        <div style={{ maxHeight: '550px', overflowY: 'auto' }}>
                                        {isArrayWithLength(commentsData) && commentsData.map((C, key) => (
                                            <React.Fragment key={key}>                                          
                                                <Card>
                                                    <CardHeader style={{ padding: '0', background: '#337ab7', color: 'white' }}>{C.metricGroupName}</CardHeader>
                                                    <CardBody>
                                                        <div className="RTELIdots" dangerouslySetInnerHTML={{ __html: C.comments }}></div>
                                                    </CardBody>
                                                    </Card> 
                                            </React.Fragment>
                                        ))}
                                            </div>
                                    </Col>
                                    :
                                    <React.Fragment>
                                    </React.Fragment>
                                    }                           
                            </Row>                    
                          </React.Fragment>
                        : (IsWeeklyDataloaded)
                              ? <div style={{ color: 'Red', fontWeight: 'bold', textAlign: 'center' }}><br />Weekly report is not configured for the selected service.</div>
                               : <React.Fragment> </React.Fragment>
                }
            <br/><br/>
        </div>
    );
});

WeeklyReport.displayName = 'WeeklyReport';

export default WeeklyReport;