import { CommonAPI, UserScenarioCompletionAPI } from '../../api';
import { IAppThunkAction, ReduxAction } from '../';
import { ActionType, UserScenarioType } from './types';

import moment from 'moment';
import { isNullOrUndefined } from '../../utils';
import { grep } from 'highcharts';
export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }

    },
    GetUserScenarioEndDate: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        const endDate = getendDate();
        const maxDate = getmaxDate();
        dispatch({
            endDate,
            maxDate,
            type: ActionType.SET_USERSCENARIODATE
        });
    },
    onendDateChange: (endDate: Date): ReduxAction => ({
        endDate: endDate,
        type: ActionType.USERSCENARIO_ENDDATE_CHANGE
    }),
    handleUserScenarioChange: (value): ReduxAction => ({
        selectedScenario: value,
        type: ActionType.USERSCENARIO_VALUE_CHANGE
    }),
    GetUserScenario: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        var userScenario = [];
        UserScenarioCompletionAPI.GetUserScenario()
            .then((data: UserScenarioType[]) => {
                data.map((obj: UserScenarioType) => {
                    userScenario.push(obj);
                });

                var us = grep(userScenario, function (b) {
                    return b.id === 1;
                });

                var selectedScenario = us[0];
                var scenarioDescription = selectedScenario.userScenarioDescription;
                dispatch({
                    userScenario: userScenario,
                    selectedScenario: selectedScenario,
                    scenarioDescription: scenarioDescription,
                    type: ActionType.GET_USERSCENARIO_SUCCESS
                });
                dispatch({
                    type: ActionType.SET_LOADING_FALSE
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.GET_USERSCENARIO_FAIL
                });
            });
    },
    GetUserScenarioData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.GET_USERSCENARIOCOMPLETION_REQUEST
        });
        var endDate = getState().UserScenarioCompletion.endDate;
        endDate = moment(endDate).format('MM/DD/YYYY');
        var selectedScenario = getState().UserScenarioCompletion.selectedScenario;
        var scenario = selectedScenario !== null ? selectedScenario.userScenarioName: '';
        var scenarioDescription = selectedScenario !== null ? selectedScenario.userScenarioDescription : '';
        var countVal = getState().UserScenarioCompletion.countVal;
        var id = selectedScenario !== null ? selectedScenario.id : '';
        var scenarioCompletionData = [];
        var userScenarioDate = [];
        var tableHeader = [];
        var originalHeader = [];
        UserScenarioCompletionAPI.GetUserScenarioDetailsById(endDate.toString(), id.toString())
            .then((scenarioData: any) => {
                if (Object.keys(scenarioData).length !== 0) {
                    originalHeader.push(scenarioData.table);
                    userScenarioDate = Object.keys(scenarioData.table).map(function (it) {
                        return scenarioData.table[it];
                    });

                    tableHeader = [];
                    scenarioCompletionData = scenarioData.table1;
                    var tempData = scenarioData.table[0];
                    if (Object.keys(tempData).length !== 0) {
                        Object.keys(tempData).map((row) => {
                            if (row.indexOf('Completion Rate') != -1) {
                                tableHeader.push(row.toString().split('~')[0]);
                            }
                        });
                    }
                    scenarioCompletionData.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1);

                    dispatch({
                        originalHeader: originalHeader,
                        tableHeader: tableHeader,
                        scenario: scenario,
                        scenarioDescription: scenarioDescription,
                        scenarioCompletionData: scenarioCompletionData,
                        countVal: countVal + 1,
                        type: ActionType.GET_USERSCENARIOCOMPLETION_SUCCESS
                    });
                }
                else {
                    dispatch({
                        originalHeader: originalHeader,
                        tableHeader: tableHeader,
                        scenario: scenario,
                        scenarioDescription: scenarioDescription,
                        scenarioCompletionData: scenarioCompletionData,
                        countVal: countVal + 1,
                        type: ActionType.GET_USERSCENARIOCOMPLETION_SUCCESS
                    });
                }
            }).catch(error => {
                dispatch({
                    type: ActionType.GET_USERSCENARIOCOMPLETION_FAIL
                });
            });
    },
};

const getendDate = () => {
    const date = new Date();    
    return date;
}

const getmaxDate = () => {
    const myDate = new Date();   
    const date = new Date(
        myDate.getFullYear(),
        myDate.getMonth(),
        myDate.getDate()
    );
    return date;
}