
import { IAppThunkAction, ReduxAction } from '../';
import { WeeklyIDSHRAPI, CommonAPI } from '../../api';
import { isNullOrUndefined } from '../../utils';
import { ActionType, IPropAllPirData, IPropCommsOnlyPir, IPropSpecialTopicsWishr, IPropAllPirDataReReview, IPropSupportabilityWishr, IPIRAdminLock, IPropFundamentalsSpotlightWishr, IPropActionItemsReviewWishr, IPropPublicServiceAnnouncementWishr, IPropSuccessCaseStudiesWishr, IPropSuccessStoriesWishr, IPropOutageReviewWishr, IPropAppendixMaterialsWishr } from './types';
import moment from 'moment';
import { ActionItemsReview } from '../../containers/WeeklyIDSHRData/child-components';


export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    setloadingtrue: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
    },
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }

    },
    setloadingfalse: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_FALSE
        });
    },
    GetMeetingDate: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let userId = getState().authUser.login;
        const meetingdate = getMeetingDate();
        const maxdate = getMaxDate();
        dispatch({
            meetingdate,
            maxdate,
            userId,
            type: ActionType.SET_MEETINGDATE_DATE
        });
    },
    EditOutageReviewRow: (val: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const outReviewData = getState().WeeklyIDSHR.OutageReviewData;
        const outage = [];
        outReviewData.map((obj) => {
            if (obj.id === val) {
                obj.isEdit = true;
                obj.outageEdit = obj.pir;
                obj.presenterEdit = obj.presenter;
            }
            else {
                obj.isEdit = false;
            }
            if (obj.id > 0)
                outage.push(obj);
        })
        dispatch({
            OutageReviewData: outage,
            type: ActionType.ENABLE_EDITORADD_OUTAGE
        });
    },
    CancelEditOutageRow: (val: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const outReviewData = getState().WeeklyIDSHR.OutageReviewData;
        const outage = [];
        outReviewData.map((obj) => {
            obj.isEdit = false;
            obj.outageEdit = obj.pir;
            if (obj.id > 0)
                outage.push(obj);
        })
        let outageaccordiontoggleStatus = outReviewData.length > 0 ? true : false;
        dispatch({
            OutageReviewData: outage,
            count: 4,
            outageaccordiontoggle: true,
            type: ActionType.GET_OUTAGEREVIEW_SUCCESS
        });
    },
    OnOutageValueChange: (e, id): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const outReviewData = getState().WeeklyIDSHR.OutageReviewData;
        let { value, name } = e.target;
        const outage = [];
        if (value === undefined)
            value = '';
        outReviewData.map((obj) => {
            if (obj.id === id) {
                obj[name] = value;

            }
            outage.push(obj);
        })
        dispatch({
            OutageReviewData: outage,
            type: ActionType.OUTAGEREVIEW_CHANGE
        });
    },
    EditPIRRow: (val: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const weeklydata = getState().WeeklyIDSHR.weeklyIDSHRData;
        const weekly = [];
        weeklydata.map((obj) => {
            if (obj.id === val) {
                obj.isEdit = true;
                obj.pirEdit = obj.pir;
                obj.occuredRingEdit = obj.occuredRing;
                obj.displayOrderEdit = obj.displayOrder;
                obj.presenterEdit = obj.presenter;
            }
            else {
                obj.isEdit = false;
            }
            if (obj.id > 0)
                weekly.push(obj);
        })
        dispatch({
            weeklyIDSHRData: weekly,
            isPIRLockDisabledOnEdit: true,
            type: ActionType.ENABLE_EDITORADD_PIR
        });
    },
    CancelEditPIRRow: (val: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const weeklydata = getState().WeeklyIDSHR.weeklyIDSHRData;
        const weekly = [];
        weeklydata.map((obj) => {
            obj.isEdit = false;
            obj.pirEdit = obj.pir;
            obj.occuredRingEdit = obj.occuredRing;
            obj.displayOrderEdit = obj.displayOrder;
            if (obj.id > 0)
                weekly.push(obj);
        })
        let piraccordiontoggleStatus = weeklydata.length > 0 ? true : false;
        dispatch({
            weeklyIDSHRData: weekly,
            count: 4,
            isPIRLockDisabledOnEdit: false,
            piraccordiontoggle: true,
            type: ActionType.GET_WEEKLYIDSHR_SUCCESS
        });
    },
    OnPIRChangevalues: (e, id): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const weeklydata = getState().WeeklyIDSHR.weeklyIDSHRData;
        let { value, name } = e.target;
        const weekly = [];
        if (value === undefined)
            value = '';
        weeklydata.map((obj) => {
            if (obj.id === id) {
                obj[name] = value;

            }
            weekly.push(obj);
        })
        dispatch({
            weeklyIDSHRData: weekly,
            type: ActionType.PIRREREVIEW_CHANGE
        });
    },
    EnableAddNewRowforPIR: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const weeklydata = getState().WeeklyIDSHR.weeklyIDSHRData;
        const weekly = [];
        weeklydata.map((obj) => {
            obj.isEdit = false;
            obj.pirEdit = obj.pir;
            obj.occuredRingEdit = obj.occuredRing;
            obj.displayOrderEdit = obj.displayOrder;
            if (obj.id > 0)
                weekly.push(obj);
        })
        let NewPIR = {
            id: 0,
            serviceName: "",
            servicesImpacted: '',
            summary: '',
            ttd: '',
            ttm: '',
            ttn: '',
            tte: '',
            pir: '',
            isAzure: false,
            kpiDate: '',
            userName: '',
            occuredRing: '',
            pirRereview: false,
            isAddedToAgenda: false,
            isValidPir: false,
            rootCauseCategory: null,
            incidentId: '',
            displayOrderEdit: weekly.length + 1,
            resources: null,
            sharedLearning: '',
            isEdit: true,
            occuredRingEdit: '',
            pirEdit: ''
        }
        weekly.push(NewPIR);
        dispatch({
            weeklyIDSHRData: weekly,
            isPIRLockDisabledOnEdit: true,
            type: ActionType.ENABLE_EDITORADD_PIR
        });
    },
    EditComosonlyRow: (val: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const commosonlyPIR = getState().WeeklyIDSHR.CommosonlyPIRData;
        const cpir = [];
        commosonlyPIR.map((obj: IPropCommsOnlyPir) => {
            if (obj.id === val) {
                obj.isEdit = true;
                obj.serviceNameEdit = obj.serviceName;
                obj.titleEdit = obj.title;
                obj.ttnEdit = obj.ttn;
                obj.commsPirLinkEdit = obj.commsPirLink;
                obj.pirNumberEdit = obj.pirNumber;
                obj.displayOrderEdit = obj.displayOrder;
                obj.presenterEdit = obj.presenter;
            }
            else {
                obj.isEdit = false;
            }
            if (obj.id > 0)
                cpir.push(obj);
        })
        dispatch({
            CommosonlyPIRData: cpir,
            type: ActionType.ENABLE_EDITORADD_COMMOSONLYPIR
        });
    },
    CancelEditComosonlyPIRRow: (val: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const commosonlyPIR = getState().WeeklyIDSHR.CommosonlyPIRData;
        const cpir = [];
        commosonlyPIR.map((obj: IPropCommsOnlyPir) => {
            obj.serviceNameEdit = obj.serviceName;
            obj.titleEdit = obj.title;
            obj.ttnEdit = obj.ttn;
            obj.commsPirLinkEdit = obj.commsPirLink;
            obj.pirNumberEdit = obj.pirNumber;
            obj.displayOrderEdit = obj.displayOrder;
            obj.isEdit = false;
            if (obj.id > 0)
                cpir.push(obj);

        })
        dispatch({
            CommosonlyPIRData: cpir,
            count: 4,
            type: ActionType.GET_COOMOSONLYPIR_SUCCESS
        });
    },
    OnComosonlyPIRChangevalues: (e, id): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const commosonlyPIR = getState().WeeklyIDSHR.CommosonlyPIRData;
        const cpir = [];
        let { value, name } = e.target;
        if (value === undefined)
            value = '';
        commosonlyPIR.map((obj: IPropCommsOnlyPir) => {
            if (obj.id === id) {
                obj[name] = value;

            }
            cpir.push(obj);
        })
        dispatch({
            CommosonlyPIRData: cpir,
            type: ActionType.COMMOSONLY_PIRREREVIEW_CHANGE
        });
    },
    EnableAddNewRowforCommosonlyPIR: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const commosonlyPIR = getState().WeeklyIDSHR.CommosonlyPIRData;
        const cpir = [];
        commosonlyPIR.map((obj: IPropCommsOnlyPir) => {
            obj.serviceNameEdit = obj.serviceName;
            obj.titleEdit = obj.title;
            obj.ttnEdit = obj.ttn;
            obj.commsPirLinkEdit = obj.commsPirLink;
            obj.pirNumberEdit = obj.pirNumber;
            obj.displayOrderEdit = obj.displayOrder;
            obj.isEdit = false;
            if (obj.id > 0)
                cpir.push(obj);
        })
        let NewCOPIR = {
            id: 0,
            serviceName: '',
            serviceNameEdit: '',
            title: '',
            titleEdit: '',
            ttn: '',
            ttnEdit: '',
            commsPirLink: '',
            commsPirLinkEdit: '',
            pirNumber: '',
            pirNumberEdit: '',
            displayOrderEdit: '',
            displayOrder: '',
            kpiDate: '',
            isEdit: true
        }
        cpir.push(NewCOPIR);
        dispatch({
            CommosonlyPIRData: cpir,
            type: ActionType.ENABLE_EDITORADD_COMMOSONLYPIR
        });
    },
    CancelEditPIRRereviewRow: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const PIRRereviewData = getState().WeeklyIDSHR.PIRRereviewData;
        const rereview = [];
        PIRRereviewData.map((obj) => {
            obj.isEdit = false;
            obj.addToAgendaEdit = obj.addToAgenda;
            obj.addToAgendaEdit = obj.addToAgenda;
            obj.dueForReReviewOnEdit = obj.dueForReReviewOn;
            if (obj.id > 0)
                rereview.push(obj);
        })
        dispatch({
            PIRRereviewData: rereview,
            count: 4,
            type: ActionType.GET_PIRREREVIEW_SUCCESS
        });
    },
    EditPIrRereviewRow: (val: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const PIRRereviewData = getState().WeeklyIDSHR.PIRRereviewData;
        const rereview = [];
        PIRRereviewData.map((obj: IPropAllPirDataReReview) => {
            if (obj.id === val) {
                obj.isEdit = true;
                obj.dueForReReviewOnEdit = obj.dueForReReviewOn;
            }
            else {
                obj.isEdit = false;
            }
            if (obj.id > 0)
                rereview.push(obj);
        })
        dispatch({
            PIRRereviewData: rereview,
            type: ActionType.ENABLE_EDITORADD_PIRREVIEW
        });
    },
    OnPIRRereviewChangevalues: (e, id): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const PIRRereviewData = getState().WeeklyIDSHR.PIRRereviewData;
        const rereview = [];
        let { value, name } = e.target;
        if (value === undefined)
            value = '';
        PIRRereviewData.map((obj: IPropCommsOnlyPir) => {
            if (obj.id === id) {
                obj[name] = value;

            }
            rereview.push(obj);
        })
        dispatch({
            PIRRereviewData: rereview,
            type: ActionType.PIR_REREVIEW_CHANGE
        });
    },
    EditSpecialTopicRow: (val: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SpecialTopicsData = getState().WeeklyIDSHR.SpecialTopicsData;
        const spltopic = [];
        SpecialTopicsData.map((obj: IPropSpecialTopicsWishr) => {
            if (obj.id === val) {
                obj.isEdit = true;
                obj.presenterEdit = obj.presenter;
                obj.topicNameEdit = obj.topicName;
                obj.timeNeededEdit = obj.timeNeeded;
                obj.presentationLinkEdit = obj.presentationLink;
                obj.isTopicApprovedEdit = obj.isTopicApproved;
                obj.displayOrderEdit = obj.displayOrder;
            }
            else {
                obj.isEdit = false;
            }
            if (obj.id > 0)
                spltopic.push(obj);
        })
        dispatch({
            SpecialTopicsData: spltopic,
            isSpecialTopicLockDisabledOnEdit: true,
            type: ActionType.ENABLE_EDITORADD_SPECIALTOPIC
        });
    },
    EditAppendixMaterialRow: (val: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const AppendixMaterialsData = getState().WeeklyIDSHR.AppendixMaterialsData;
        const apxmaterial = [];
        AppendixMaterialsData.map((obj: IPropAppendixMaterialsWishr) => {
            if (obj.id === val) {
                obj.isEdit = true;
                obj.presenterEdit = obj.presenter;
                obj.topicNameEdit = obj.topicName;
                obj.presentationLinkEdit = obj.presentationLink;
                obj.isTopicApprovedEdit = obj.isTopicApproved;
                obj.displayOrderEdit = obj.displayOrder;
            }
            else {
                obj.isEdit = false;
            }
            if (obj.id > 0)
                apxmaterial.push(obj);
        })
        dispatch({
            AppendixMaterialsData: apxmaterial,
            isAppendixMaterialLockDisabledOnEdit: true,
            type: ActionType.ENABLE_EDITORADD_APPENDIXMATERIAL
        });
    },
    EnableAddNewRowforSpecialTopics: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SpecialTopicsData = getState().WeeklyIDSHR.SpecialTopicsData;
        const spltopic = [];
        SpecialTopicsData.map((obj: IPropCommsOnlyPir) => {
            if (obj.id > 0) {
                if (obj.isEdit = true)
                    obj.isEdit = false;
                spltopic.push(obj);
            }
        })
        let NewCOPIR = {
            id: 0,
            presenterEdit: '',
            topicNameEdit: '',
            timeNeededEdit: '',
            presentationLinkEdit: '',
            isTopicApprovedEdit: false,
            displayOrderEdit: spltopic.length + 1,
            isEdit: true
        }
        spltopic.push(NewCOPIR);
        dispatch({
            SpecialTopicsData: spltopic,
            isSpecialTopicLockDisabledOnEdit: true,
            type: ActionType.ENABLE_EDITORADD_SPECIALTOPIC
        });
    },
    EnableAddNewRowforAppendixMaterials: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const AppendixMaterialsData = getState().WeeklyIDSHR.AppendixMaterialsData;
        const apxmaterial = [];
        AppendixMaterialsData.map((obj: IPropAppendixMaterialsWishr) => {
            if (obj.id > 0) {
                if (obj.isEdit = true)
                    obj.isEdit = false;
                apxmaterial.push(obj);
            }
        })
        let NewCOPIR = {
            id: 0,
            presenterEdit: '',
            topicNameEdit: '',
            presentationLinkEdit: '',
            isTopicApprovedEdit: false,
            displayOrderEdit: apxmaterial.length + 1,
            isEdit: true
        }
        apxmaterial.push(NewCOPIR);
        dispatch({
            AppendixMaterialsData: apxmaterial,
            isAppendixMaterialLockDisabledOnEdit: true,
            type: ActionType.ENABLE_EDITORADD_APPENDIXMATERIAL
        });
    },
    CancelEditSpecialTopicRow: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SpecialTopicsData = getState().WeeklyIDSHR.SpecialTopicsData;
        const spltopic = [];
        SpecialTopicsData.map((obj) => {
            obj.isEdit = false;
            obj.presenterEdit = obj.presenter;
            obj.topicNameEdit = obj.topicName;
            obj.timeNeededEdit = obj.timeNeeded;
            obj.presentationLinkEdit = obj.presentationLink;
            obj.isTopicApprovedEdit = obj.isTopicApproved;
            obj.displayOrderEdit = obj.displayOrder;
            if (obj.id > 0)
                spltopic.push(obj);
        })
        let specialaccordiontoggleStatus = SpecialTopicsData.length > 0 ? true : false;
        dispatch({
            SpecialTopicsData: spltopic,
            count: 4,
            isSpecialTopicLockDisabledOnEdit: false,
            specialaccordiontoggle: true,
            type: ActionType.GET_SPECIALTOPIC_SUCCESS
        });
    },
    CancelEditAppendixMaterialRow: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const AppendixMaterialsData = getState().WeeklyIDSHR.AppendixMaterialsData;
        const apxmaterial = [];
        AppendixMaterialsData.map((obj) => {
            obj.isEdit = false;
            obj.presenterEdit = obj.presenter;
            obj.topicNameEdit = obj.topicName;
            obj.presentationLinkEdit = obj.presentationLink;
            obj.isTopicApprovedEdit = obj.isTopicApproved;
            obj.displayOrderEdit = obj.displayOrder;
            if (obj.id > 0)
                apxmaterial.push(obj);
        })
        let appendixaccordiontoggleStatus = AppendixMaterialsData.length > 0 ? true : false;
        dispatch({
            AppendixMaterialsData: apxmaterial,
            count: 4,
            isAppendixMaterialLockDisabledOnEdit: false,
            appendixaccordiontoggle: true,
            type: ActionType.GET_APPENDIXMATERIAL_SUCCESS
        });
    },
    OnPIRSpecialTopicChangevalues: (e, id): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SpecialTopicsData = getState().WeeklyIDSHR.SpecialTopicsData;
        const spltopic = [];
        let { value, name, checked, type } = e.target;
        if (value === undefined)
            value = '';
        SpecialTopicsData.map((obj: IPropCommsOnlyPir) => {

            if (obj.id === id) {
                if (type === 'checkbox') {
                    obj[name] = checked;
                }
                else {
                    obj[name] = value;
                }
            }


            spltopic.push(obj);
        })
        dispatch({
            SpecialTopicsData: spltopic,
            type: ActionType.SPECIALTOPIC_CHANGE
        });
    },
    OnPIRAppendixMaterialChangevalues: (e, id): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const AppendixMaterialsData = getState().WeeklyIDSHR.AppendixMaterialsData;
        const apxmaterial = [];
        let { value, name, checked, type } = e.target;
        if (value === undefined)
            value = '';
        AppendixMaterialsData.map((obj: IPropAppendixMaterialsWishr) => {

            if (obj.id === id) {
                if (type === 'checkbox') {
                    obj[name] = checked;
                }
                else {
                    obj[name] = value;
                }
            }


            apxmaterial.push(obj);
        })
        dispatch({
            AppendixMaterialsData: apxmaterial,
            type: ActionType.APPENDIXMATERIAL_CHANGE
        });
    },

    EditSupportabilityRow: (val: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SupportabilityData = getState().WeeklyIDSHR.SupportabilityData;
        const supportData = [];
        SupportabilityData.map((obj: IPropSupportabilityWishr) => {
            if (obj.id === val) {
                obj.isEdit = true;
                obj.presenterEdit = obj.presenter;
                obj.topicNameEdit = obj.topicName;
                obj.presentationLinkEdit = obj.presentationLink;
                obj.displayOrderEdit = obj.displayOrder;
            }
            else {
                obj.isEdit = false;
            }
            if (obj.id > 0)
                supportData.push(obj);
        })
        dispatch({
            SupportabilityData: supportData,
            type: ActionType.ENABLE_EDITORADD_SUPPORTABILITY
        });
    },
    EnableAddNewforSupportabilityRows: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SupportabilityData = getState().WeeklyIDSHR.SupportabilityData;
        const supportData = [];
        SupportabilityData.map((obj: IPropSupportabilityWishr) => {
            if (obj.id > 0)
                supportData.push(obj);
        })
        let NewSUPPORTABILITY = {
            id: 0,
            presenterEdit: '',
            topicNameEdit: '',
            presentationLinkEdit: '',
            displayOrderEdit: '',
            isEdit: true
        }
        supportData.push(NewSUPPORTABILITY);
        dispatch({
            SupportabilityData: supportData,
            type: ActionType.ENABLE_EDITORADD_SUPPORTABILITY
        });
    },
    cancelEditSupportabilityRow: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SupportabilityData = getState().WeeklyIDSHR.SupportabilityData;
        const supportData = [];
        SupportabilityData.map((obj) => {
            obj.isEdit = false;
            obj.presenterEdit = obj.presenter;
            obj.topicNameEdit = obj.topicName;
            obj.presentationLinkEdit = obj.presentationLink;
            obj.displayOrderEdit = obj.displayOrder;
            if (obj.id > 0)
                supportData.push(obj);
        })
        dispatch({
            SupportabilityData: supportData,
            type: ActionType.GET_SUPPORTABILITY_SUCCESS
        });
    },
    OnSupportabilityChangevalues: (e, id): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SupportabilityData = getState().WeeklyIDSHR.SupportabilityData;
        const supportdata = [];
        let { value, name, checked, type } = e.target;
        if (value === undefined)
            value = '';
        SupportabilityData.map((obj: IPropSupportabilityWishr) => {

            if (obj.id === id) {
                if (type === 'checkbox') {
                    obj[name] = checked;
                }
                else {
                    obj[name] = value;
                }
            }


            supportdata.push(obj);
        })
        dispatch({
            SupportabilityData: supportdata,
            type: ActionType.SUPPORTABILITY_CHANGE
        });
    },
    GetWeeklyIDSHRSupportabilityData: (Meetingdate: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {

        //==================== to get data for Supportability =================//
        WeeklyIDSHRAPI.GetWeeklySHRSupportabilityData(Meetingdate)
            .then((SupportabilityData: IPropSupportabilityWishr[]) => {

                dispatch({
                    SupportabilityData,
                    type: ActionType.GET_SUPPORTABILITY_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.GET_SUPPORTABILITY_FAILURE
                });
            });
    },

    checkIsPirRereviewUser: (username): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        WeeklyIDSHRAPI.IsPirRereviewUser(username)
            .then((IsPirRereviewUser: boolean) => {
                dispatch({
                    IsPirRereviewUser: IsPirRereviewUser,
                    type: ActionType.REREVIEWER_CHECK
                });
            }).catch(error => {
                dispatch({
                    IsPirRereviewUser: false,
                    type: ActionType.REREVIEWER_CHECK
                });
            });
    },
    setaccordiontoggledata: (status): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            accordiontoggle: status,
            type: ActionType.ACTIONREVIEWACCORDION_STATUS
        });
    },
    setssaccordiontoggledata: (status): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            ssaccordiontoggle: status,
            type: ActionType.SSACCORDION_STATUS
        });
    },
    setscsaccordiontoggledata: (status): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            scsaccordiontoggle: status,
            type: ActionType.SCSACCORDION_STATUS
        });
    },
    setpsaccordiontoggledata: (status): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            psaaccordiontoggle: status,
            type: ActionType.PSAACCORDION_STATUS
        });
    },
    setoutageaccordiontoggledata: (status): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            outageaccordiontoggle: status,
            type: ActionType.OUTAGEACCORDION_STATUS
        });
    },
    setspecialaccordiontoggledata: (status): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            specialaccordiontoggle: status,
            type: ActionType.SPECIALTOPICACCORDION_STATUS
        });
    },
    setpiraccordiontoggledata: (status): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            piraccordiontoggle: status,
            type: ActionType.PIRACCORDION_STATUS
        });
    },
    setappendixaccordiontoggledata: (status): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            appendixaccordiontoggle: status,
            type: ActionType.APPENDIXMATERIALACCORDION_STATUS
        });
    },
    GetWeeklyIDSHRPostIncidentsData: (Meetingdate: string, Callcount): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let userId = getState().authUser.login;
        dispatch({
            userId,
            type: ActionType.GET_WEEKLYIDSHR_REQUEST
        });
        let count = 0;
        if (Callcount != 4) {
            count = 4;
        }
        if (Callcount === 1 || Callcount === 4) {
            //==================== to get data for Post incident Reviews =================//
            WeeklyIDSHRAPI.GetWeeklySHRData(Meetingdate)
                .then((weeklyIDSHRData: IPropAllPirData[]) => {
                    count = count + 1;
                    let piraccordiontoggleStatus = weeklyIDSHRData.length > 0 ? true : false;
                    dispatch({
                        weeklyIDSHRData,
                        count: count,
                        piraccordiontoggle: true,
                        type: ActionType.GET_WEEKLYIDSHR_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_WEEKLYIDSHR_FAILURE
                    });
                });
        }
        if (Callcount === 2 || Callcount === 4) {
            //==================== to get data for Outage Review =================//
            WeeklyIDSHRAPI.GetOutageReviewData(Meetingdate)
                .then((OutageReviewData: IPropOutageReviewWishr[]) => {
                    count = count + 1;
                    let outageaccordiontoggleStatus = OutageReviewData.length > 0 ? true : false;
                    dispatch({
                        OutageReviewData,
                        count: count,
                        outageaccordiontoggle: true,
                        type: ActionType.GET_OUTAGEREVIEW_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_OUTAGEREVIEW_FAILURE
                    });
                });
        }
        if (Callcount === 3 || Callcount === 4) {
            //==================== to get data for Special Topics =================//
            WeeklyIDSHRAPI.GetSpecialTopicsWishrData(Meetingdate)
                .then((SpecialTopicsData: IPropSpecialTopicsWishr[]) => {
                    count = count + 1;
                    let specialaccordiontoggleStatus = SpecialTopicsData.length > 0 ? true : false;
                    dispatch({
                        SpecialTopicsData,
                        count: count,
                        specialaccordiontoggle: true,
                        type: ActionType.GET_SPECIALTOPIC_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_SPECIALTOPIC_FAILURE
                    });
                });

        }
        if (Callcount === 4 || Callcount === 5) {
            //    //==================== to get data for PIR Rereview =================//
            //    WeeklyIDSHRAPI.GetAllPirReReviewData()
            //        .then((PIRRereviewData: IPropAllPirDataReReview[]) => {
            //            count = count + 1;
            //            dispatch({
            //                PIRRereviewData,
            //                count: count,
            //                type: ActionType.GET_PIRREREVIEW_SUCCESS
            //            });
            //        }).catch(error => {
            //            count = count + 1;
            //            dispatch({
            //                count: count,
            //                type: ActionType.GET_PIRREREVIEW_FAILURE
            //            });
            //        });
            //==================== to get data for PUBLIC SERVICE ANNOUNCEMENT =================//
            WeeklyIDSHRAPI.GetPubServiceAccWishrData(Meetingdate)
                .then((PublicServiceAnnouncementData: IPropPublicServiceAnnouncementWishr[]) => {
                    count = count + 1;
                    let psaaccordiontoggleStatus = PublicServiceAnnouncementData.length > 0 ? true : false;
                    dispatch({
                        PublicServiceAnnouncementData,
                        count: count,
                        psaaccordiontoggle: true,
                        type: ActionType.GET_PUBLICSERVICEACC_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_PUBLICSERVICEACC_FAILURE
                    });
                });
        }
        //if (Callcount === 4 || Callcount === 6) {
        //    WeeklyIDSHRAPI.GetFundamentalsSpotlightWishrData(Meetingdate)
        //        .then((FundamentalsSpotlightData: IPropFundamentalsSpotlightWishr[]) => {
        //            count = count + 1;
        //            dispatch({
        //                FundamentalsSpotlightData,
        //                count: count,
        //                type: ActionType.GET_FUNDAMENTALS_SPOTLIGHT_SUCCESS
        //            });
        //        }).catch(error => {
        //            count = count + 1;
        //            dispatch({
        //                count: count,
        //                type: ActionType.GET_FUNDAMENTALS_SPOTLIGHT_FAILURE
        //            });
        //        });
        //}
        if (Callcount === 4 || Callcount === 7) {
            WeeklyIDSHRAPI.GetActionItemsReviewWishrData(Meetingdate)
                .then((ActionItemsReviewData: IPropActionItemsReviewWishr[]) => {
                    let actualdata: IPropActionItemsReviewWishr[] = [];
                    ActionItemsReviewData.forEach((row) => {
                        if (new Date(row.kpiDate).getDate() == new Date(Meetingdate).getDate() && new Date(row.kpiDate).getMonth() == new Date(Meetingdate).getMonth() && new Date(row.kpiDate).getFullYear() == new Date(Meetingdate).getFullYear()) {
                            actualdata.push(row)
                        }
                    });
                    count = count + 1;
                    let accordiontoggleStatus = actualdata.length > 0 ? true : false;
                    dispatch({
                        ActionItemsReviewData: actualdata,
                        count: count,
                        accordiontoggle: true,
                        type: ActionType.GET_ACTION_ITEMS_REVIEW_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_ACTION_ITEMS_REVIEW_FAILURE
                    });
                });
        }
        if (Callcount === 4 || Callcount === 8) {
            //==================== to get data for Success Case Studies =================//
            WeeklyIDSHRAPI.GetSuccessCaseStudiesWishrData(Meetingdate)
                .then((SuccessCaseStudiesData: IPropSuccessCaseStudiesWishr[]) => {
                    count = count + 1;
                    let scsaccordiontoggleStatus = SuccessCaseStudiesData.length > 0 ? true : false;
                    dispatch({
                        SuccessCaseStudiesData,
                        count: count,
                        scsaccordiontoggle: true,
                        type: ActionType.GET_SUCCESSCASESTUDIES_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_SUCCESSCASESTUDIES_FAILURE
                    });
                });
        }
        if (Callcount === 4 || Callcount === 9) {
            //==================== to get data for Success Stories =================//
            WeeklyIDSHRAPI.GetSuccessStoriesWishrData(Meetingdate)
                .then((SuccessStoriesData: IPropSuccessStoriesWishr[]) => {
                    count = count + 1;
                    let ssaccordiontoggleStatus = SuccessStoriesData.length > 0 ? true : false;
                    dispatch({
                        SuccessStoriesData,
                        count: count,
                        ssaccordiontoggle: true,
                        type: ActionType.GET_SUCCESSSTORIES_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_SUCCESSSTORIES_FAILURE
                    });
                });
        }
        if (Callcount === 4 || Callcount === 10) {
            //==================== to get data for Appendix Materials =================//
            WeeklyIDSHRAPI.GetAppendixMaterialsWishrData(Meetingdate)
                .then((AppendixMaterialsData: IPropAppendixMaterialsWishr[]) => {
                    count = count + 1;
                    let appendixaccordiontoggleStatus = AppendixMaterialsData.length > 0 ? true : false;
                    dispatch({
                        AppendixMaterialsData,
                        count: count,
                        appendixaccordiontoggle: true,
                        type: ActionType.GET_APPENDIXMATERIAL_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_APPENDIXMATERIAL_FAILURE
                    });
                });

        }
    },

    handleMeetingDateChange: (MeetingDate: Date): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            MeetingDate: MeetingDate,
            isLockedPIR: getState().WeeklyIDSHR.isLockedPIRArray.filter((e) => { if (new Date(e).toUTCString() == new Date(MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
            isLockedCommsPIR: getState().WeeklyIDSHR.isLockedCommsPIRArray.filter((e) => { if (new Date(e).toUTCString() == new Date(MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
            isLockedTopic: getState().WeeklyIDSHR.isLockedTopicArray.filter((e) => { if (new Date(e).toUTCString() == new Date(MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
            isLockedFundamentalsSpotlight: getState().WeeklyIDSHR.isLockedFundamentalsSpotlightArray.filter((e) => { if (new Date(e).toUTCString() == new Date(MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
            isLockedPubServiceAcc: getState().WeeklyIDSHR.isLockedPubServiceAccArray.filter((e) => { if (new Date(e).toUTCString() == new Date(MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
            isLockedSuccessCaseStudies: getState().WeeklyIDSHR.isLockedSuccessCaseStudiesArray.filter((e) => { if (new Date(e).toUTCString() == new Date(MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
            isLockedSuccessStories: getState().WeeklyIDSHR.isLockedSuccessStoriesArray.filter((e) => { if (new Date(e).toUTCString() == new Date(MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
            isLockedOutage: getState().WeeklyIDSHR.isLockedOutageArray.filter((e) => { if (new Date(e).toUTCString() == new Date(MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
            isLockedAppendix: getState().WeeklyIDSHR.isLockedAppendixArray.filter((e) => { if (new Date(e).toUTCString() == new Date(MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
            type: ActionType.MEETINGDATE_DATE_CHANGE
        })
    },

    EditLockforOutage: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let meetingDate = moment(getState().WeeklyIDSHR.MeetingDate).format('YYYY-MM-DD');
        let lockValue = getState().WeeklyIDSHR.isLockedOutage == true ? 0 : 1;
        WeeklyIDSHRAPI.PostPirAdminLock(8, meetingDate, lockValue, getState().authUser.login).
            then((value: IPIRAdminLock[]) => {
                dispatch({
                    isLockedPIR: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedCommsPIR: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedTopic: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedFundamentalsSpotlight: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPubServiceAcc: value.filter((e) => { if (e.lock_Type === 5) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSuccessCaseStudies: value.filter((e) => { if (e.lock_Type === 6) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSuccessStories: value.filter((e) => { if (e.lock_Type === 7) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedOutage: value.filter((e) => { if (e.lock_Type === 8) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedAppendix: value.filter((e) => { if (e.lock_Type === 9) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPIRArray: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedCommsPIRArray: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedTopicArray: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedFundamentalsSpotlightArray: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedPubServiceAccArray: value.filter((e) => { if (e.lock_Type === 5) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedSuccessCaseStudiesArray: value.filter((e) => { if (e.lock_Type === 6) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedSuccessStoriesArray: value.filter((e) => { if (e.lock_Type === 7) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedOutageArray: value.filter((e) => { if (e.lock_Type === 8) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedAppendixArray: value.filter((e) => { if (e.lock_Type === 9) { return e } }).map((e) => { return e.locked_Week }),
                    type: ActionType.GET_PIRADMINLOCK
                });
            });
    },

    EditLockforPIR: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let meetingDate = moment(getState().WeeklyIDSHR.MeetingDate).format('YYYY-MM-DD');
        let lockValue = getState().WeeklyIDSHR.isLockedPIR == true ? 0 : 1;
        WeeklyIDSHRAPI.PostPirAdminLock(1, meetingDate, lockValue, getState().authUser.login).
            then((value: IPIRAdminLock[]) => {
                dispatch({
                    isLockedPIR: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedCommsPIR: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedTopic: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedFundamentalsSpotlight: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPubServiceAcc: value.filter((e) => { if (e.lock_Type === 5) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSuccessCaseStudies: value.filter((e) => { if (e.lock_Type === 6) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSuccessStories: value.filter((e) => { if (e.lock_Type === 7) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedOutage: value.filter((e) => { if (e.lock_Type === 8) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedAppendix: value.filter((e) => { if (e.lock_Type === 9) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPIRArray: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedCommsPIRArray: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedTopicArray: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedFundamentalsSpotlightArray: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedPubServiceAccArray: value.filter((e) => { if (e.lock_Type === 5) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedSuccessCaseStudiesArray: value.filter((e) => { if (e.lock_Type === 6) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedSuccessStoriesArray: value.filter((e) => { if (e.lock_Type === 7) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedOutageArray: value.filter((e) => { if (e.lock_Type === 8) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedAppendixArray: value.filter((e) => { if (e.lock_Type === 9) { return e } }).map((e) => { return e.locked_Week }),
                    type: ActionType.GET_PIRADMINLOCK
                });
            });
    },

    EditLockforCommsPIR: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let meetingDate = moment(getState().WeeklyIDSHR.MeetingDate).format('YYYY-MM-DD');
        let lockValue = getState().WeeklyIDSHR.isLockedCommsPIR == true ? 0 : 1;
        WeeklyIDSHRAPI.PostPirAdminLock(2, meetingDate, lockValue, getState().authUser.login).
            then((value: IPIRAdminLock[]) => {
                dispatch({
                    isLockedPIR: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedCommsPIR: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedTopic: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedFundamentalsSpotlight: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPubServiceAcc: value.filter((e) => { if (e.lock_Type === 5) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSuccessCaseStudies: value.filter((e) => { if (e.lock_Type === 6) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSuccessStories: value.filter((e) => { if (e.lock_Type === 7) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedOutage: value.filter((e) => { if (e.lock_Type === 8) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedAppendix: value.filter((e) => { if (e.lock_Type === 9) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPIRArray: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedCommsPIRArray: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedTopicArray: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedFundamentalsSpotlightArray: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedPubServiceAccArray: value.filter((e) => { if (e.lock_Type === 5) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedSuccessCaseStudiesArray: value.filter((e) => { if (e.lock_Type === 6) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedSuccessStoriesArray: value.filter((e) => { if (e.lock_Type === 7) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedOutageArray: value.filter((e) => { if (e.lock_Type === 8) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedAppendixArray: value.filter((e) => { if (e.lock_Type === 9) { return e } }).map((e) => { return e.locked_Week }),
                    type: ActionType.GET_PIRADMINLOCK
                });
            });
    },

    EditLockforSpecificTopic: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let meetingDate = moment(getState().WeeklyIDSHR.MeetingDate).format('YYYY-MM-DD');
        let lockValue = getState().WeeklyIDSHR.isLockedTopic == true ? 0 : 1;
        WeeklyIDSHRAPI.PostPirAdminLock(3, meetingDate, lockValue, getState().authUser.login).
            then((value: IPIRAdminLock[]) => {
                dispatch({
                    isLockedPIR: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedCommsPIR: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedTopic: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedFundamentalsSpotlight: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPubServiceAcc: value.filter((e) => { if (e.lock_Type === 5) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSuccessCaseStudies: value.filter((e) => { if (e.lock_Type === 6) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSuccessStories: value.filter((e) => { if (e.lock_Type === 7) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedOutage: value.filter((e) => { if (e.lock_Type === 8) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedAppendix: value.filter((e) => { if (e.lock_Type === 9) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPIRArray: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedCommsPIRArray: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedTopicArray: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedFundamentalsSpotlightArray: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedPubServiceAccArray: value.filter((e) => { if (e.lock_Type === 5) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedSuccessCaseStudiesArray: value.filter((e) => { if (e.lock_Type === 6) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedSuccessStoriesArray: value.filter((e) => { if (e.lock_Type === 7) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedOutageArray: value.filter((e) => { if (e.lock_Type === 8) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedAppendixArray: value.filter((e) => { if (e.lock_Type === 9) { return e } }).map((e) => { return e.locked_Week }),
                    type: ActionType.GET_PIRADMINLOCK
                });
            });
    },
    EditLockforPubServiceAcc: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let meetingDate = moment(getState().WeeklyIDSHR.MeetingDate).format('YYYY-MM-DD');
        let lockValue = getState().WeeklyIDSHR.isLockedPubServiceAcc == true ? 0 : 1;
        WeeklyIDSHRAPI.PostPirAdminLock(5, meetingDate, lockValue, getState().authUser.login).
            then((value: IPIRAdminLock[]) => {
                dispatch({
                    isLockedPIR: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedCommsPIR: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedTopic: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedFundamentalsSpotlight: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPubServiceAcc: value.filter((e) => { if (e.lock_Type === 5) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSuccessCaseStudies: value.filter((e) => { if (e.lock_Type === 6) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSuccessStories: value.filter((e) => { if (e.lock_Type === 7) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedOutage: value.filter((e) => { if (e.lock_Type === 8) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedAppendix: value.filter((e) => { if (e.lock_Type === 9) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPIRArray: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedCommsPIRArray: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedTopicArray: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedFundamentalsSpotlightArray: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedPubServiceAccArray: value.filter((e) => { if (e.lock_Type === 5) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedSuccessCaseStudiesArray: value.filter((e) => { if (e.lock_Type === 6) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedSuccessStoriesArray: value.filter((e) => { if (e.lock_Type === 7) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedOutageArray: value.filter((e) => { if (e.lock_Type === 8) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedAppendixArray: value.filter((e) => { if (e.lock_Type === 9) { return e } }).map((e) => { return e.locked_Week }),
                    type: ActionType.GET_PIRADMINLOCK
                });
            });
    },
    EditLockforSuccessCaseStudies: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let meetingDate = moment(getState().WeeklyIDSHR.MeetingDate).format('YYYY-MM-DD');
        let lockValue = getState().WeeklyIDSHR.isLockedSuccessCaseStudies == true ? 0 : 1;
        WeeklyIDSHRAPI.PostPirAdminLock(6, meetingDate, lockValue, getState().authUser.login).
            then((value: IPIRAdminLock[]) => {
                dispatch({
                    isLockedPIR: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedCommsPIR: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedTopic: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedFundamentalsSpotlight: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPubServiceAcc: value.filter((e) => { if (e.lock_Type === 5) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSuccessCaseStudies: value.filter((e) => { if (e.lock_Type === 6) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSuccessStories: value.filter((e) => { if (e.lock_Type === 7) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedOutage: value.filter((e) => { if (e.lock_Type === 8) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedAppendix: value.filter((e) => { if (e.lock_Type === 9) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPIRArray: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedCommsPIRArray: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedTopicArray: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedFundamentalsSpotlightArray: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedPubServiceAccArray: value.filter((e) => { if (e.lock_Type === 5) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedSuccessCaseStudiesArray: value.filter((e) => { if (e.lock_Type === 6) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedSuccessStoriesArray: value.filter((e) => { if (e.lock_Type === 7) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedOutageArray: value.filter((e) => { if (e.lock_Type === 8) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedAppendixArray: value.filter((e) => { if (e.lock_Type === 9) { return e } }).map((e) => { return e.locked_Week }),
                    type: ActionType.GET_PIRADMINLOCK
                });
            });
    },
    EditLockforSuccessStories: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let meetingDate = moment(getState().WeeklyIDSHR.MeetingDate).format('YYYY-MM-DD');
        let lockValue = getState().WeeklyIDSHR.isLockedSuccessStories == true ? 0 : 1;
        WeeklyIDSHRAPI.PostPirAdminLock(7, meetingDate, lockValue, getState().authUser.login).
            then((value: IPIRAdminLock[]) => {
                dispatch({
                    isLockedPIR: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedCommsPIR: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedTopic: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedFundamentalsSpotlight: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPubServiceAcc: value.filter((e) => { if (e.lock_Type === 5) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSuccessCaseStudies: value.filter((e) => { if (e.lock_Type === 6) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSuccessStories: value.filter((e) => { if (e.lock_Type === 7) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedOutage: value.filter((e) => { if (e.lock_Type === 8) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedAppendix: value.filter((e) => { if (e.lock_Type === 9) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPIRArray: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedCommsPIRArray: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedTopicArray: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedFundamentalsSpotlightArray: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedPubServiceAccArray: value.filter((e) => { if (e.lock_Type === 5) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedSuccessCaseStudiesArray: value.filter((e) => { if (e.lock_Type === 6) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedSuccessStoriesArray: value.filter((e) => { if (e.lock_Type === 7) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedOutageArray: value.filter((e) => { if (e.lock_Type === 8) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedAppendixArray: value.filter((e) => { if (e.lock_Type === 9) { return e } }).map((e) => { return e.locked_Week }),
                    type: ActionType.GET_PIRADMINLOCK
                });
            });
    },
    EditLockforAppendixMaterial: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let meetingDate = moment(getState().WeeklyIDSHR.MeetingDate).format('YYYY-MM-DD');
        let lockValue = getState().WeeklyIDSHR.isLockedAppendix == true ? 0 : 1;
        WeeklyIDSHRAPI.PostPirAdminLock(9, meetingDate, lockValue, getState().authUser.login).
            then((value: IPIRAdminLock[]) => {
                dispatch({
                    isLockedPIR: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedCommsPIR: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedTopic: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedFundamentalsSpotlight: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPubServiceAcc: value.filter((e) => { if (e.lock_Type === 5) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSuccessCaseStudies: value.filter((e) => { if (e.lock_Type === 6) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSuccessStories: value.filter((e) => { if (e.lock_Type === 7) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedOutage: value.filter((e) => { if (e.lock_Type === 8) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedAppendix: value.filter((e) => { if (e.lock_Type === 9) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPIRArray: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedCommsPIRArray: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedTopicArray: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedFundamentalsSpotlightArray: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedPubServiceAccArray: value.filter((e) => { if (e.lock_Type === 5) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedSuccessCaseStudiesArray: value.filter((e) => { if (e.lock_Type === 6) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedSuccessStoriesArray: value.filter((e) => { if (e.lock_Type === 7) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedOutageArray: value.filter((e) => { if (e.lock_Type === 8) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedAppendixArray: value.filter((e) => { if (e.lock_Type === 9) { return e } }).map((e) => { return e.locked_Week }),
                    type: ActionType.GET_PIRADMINLOCK
                });
            });
    },
    EditPubServiceAccRow: (val: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const PublicServiceAnnouncementData = getState().WeeklyIDSHR.PublicServiceAnnouncementData;
        const publicServiceAcc = [];
        PublicServiceAnnouncementData.map((obj: IPropPublicServiceAnnouncementWishr) => {
            if (obj.id === val) {
                obj.isEdit = true;
                obj.presenterEdit = obj.presenter;
                obj.topicNameEdit = obj.topicName;
                obj.timeNeededEdit = obj.timeNeeded;
                obj.presentationLinkEdit = obj.presentationLink;
                obj.isTopicApprovedEdit = obj.isTopicApproved;
                obj.displayOrderEdit = obj.displayOrder;
            }
            else {
                obj.isEdit = false;
            }
            if (obj.id > 0)
                publicServiceAcc.push(obj);
        })
        dispatch({
            PublicServiceAnnouncementData: publicServiceAcc,
            isPSALockDisabledOnEdit: true,
            type: ActionType.ENABLE_EDITORADD_PUBLICSERVICEACC
        });
    },
    EditSuccessCaseStudiesRow: (val: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SuccessCaseStudiesData = getState().WeeklyIDSHR.SuccessCaseStudiesData;
        const successCaseStudies = [];
        SuccessCaseStudiesData.map((obj: IPropPublicServiceAnnouncementWishr) => {
            if (obj.id === val) {
                obj.isEdit = true;
                obj.presenterEdit = obj.presenter;
                obj.topicNameEdit = obj.topicName;
                obj.timeNeededEdit = obj.timeNeeded;
                obj.presentationLinkEdit = obj.presentationLink;
                obj.isTopicApprovedEdit = obj.isTopicApproved;
                obj.displayOrderEdit = obj.displayOrder;
            }
            else {
                obj.isEdit = false;
            }
            if (obj.id > 0)
                successCaseStudies.push(obj);
        })
        dispatch({
            SuccessCaseStudiesData: successCaseStudies,
            isSCSLockDisabledOnEdit: true,
            type: ActionType.ENABLE_EDITORADD_SUCCESSCASESTUDIES
        });
    },
    EditSuccessStoriesRow: (val: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SuccessStoriesData = getState().WeeklyIDSHR.SuccessStoriesData;
        const successStories = [];
        SuccessStoriesData.map((obj: IPropSuccessStoriesWishr) => {
            if (obj.id === val) {
                obj.isEdit = true;
                obj.presenterEdit = obj.presenter;
                obj.topicNameEdit = obj.topicName;
                obj.timeNeededEdit = obj.timeNeeded;
                obj.presentationLinkEdit = obj.presentationLink;
                obj.isTopicApprovedEdit = obj.isTopicApproved;
                obj.displayOrderEdit = obj.displayOrder;
            }
            else {
                obj.isEdit = false;
            }
            if (obj.id > 0)
                successStories.push(obj);
        })
        dispatch({
            SuccessStoriesData: successStories,
            isSSLockDisabledOnEdit: true,
            type: ActionType.ENABLE_EDITORADD_SUCCESSSTORIES
        });
    },
    CancelEditPubServiceAccRow: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const PublicServiceAnnouncementData = getState().WeeklyIDSHR.PublicServiceAnnouncementData;
        const publicServiceAcc = [];
        PublicServiceAnnouncementData.map((obj) => {
            obj.isEdit = false;
            obj.presenterEdit = obj.presenter;
            obj.topicNameEdit = obj.topicName;
            obj.timeNeededEdit = obj.timeNeeded;
            obj.presentationLinkEdit = obj.presentationLink;
            obj.isTopicApprovedEdit = obj.isTopicApproved;
            obj.displayOrderEdit = obj.displayOrder;
            if (obj.id > 0)
                publicServiceAcc.push(obj);
        })
        let psaaccordiontoggleStatus = PublicServiceAnnouncementData.length > 0 ? true : false;
        dispatch({
            PublicServiceAnnouncementData: publicServiceAcc,
            count: 4,
            isPSALockDisabledOnEdit: false,
            psaaccordiontoggle: true,
            type: ActionType.GET_PUBLICSERVICEACC_SUCCESS
        });
    },
    CancelEditSuccessCaseStudiesRow: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SuccessCaseStudiesData = getState().WeeklyIDSHR.SuccessCaseStudiesData;
        const successCaseStudies = [];
        SuccessCaseStudiesData.map((obj) => {
            obj.isEdit = false;
            obj.presenterEdit = obj.presenter;
            obj.topicNameEdit = obj.topicName;
            obj.timeNeededEdit = obj.timeNeeded;
            obj.presentationLinkEdit = obj.presentationLink;
            obj.isTopicApprovedEdit = obj.isTopicApproved;
            obj.displayOrderEdit = obj.displayOrder;
            if (obj.id > 0)
                successCaseStudies.push(obj);
        })
        let scsaccordiontoggleStatus = SuccessCaseStudiesData.length > 0 ? true : false;
        dispatch({
            SuccessCaseStudiesData: successCaseStudies,
            count: 4,
            isSCSLockDisabledOnEdit: false,
            scsaccordiontoggle: true,
            type: ActionType.GET_SUCCESSCASESTUDIES_SUCCESS
        });
    },
    CancelEditSuccessStoriesRow: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SuccessStoriesData = getState().WeeklyIDSHR.SuccessStoriesData;
        const successStories = [];
        SuccessStoriesData.map((obj) => {
            obj.isEdit = false;
            obj.presenterEdit = obj.presenter;
            obj.topicNameEdit = obj.topicName;
            obj.timeNeededEdit = obj.timeNeeded;
            obj.presentationLinkEdit = obj.presentationLink;
            obj.isTopicApprovedEdit = obj.isTopicApproved;
            obj.displayOrderEdit = obj.displayOrder;
            if (obj.id > 0)
                successStories.push(obj);
        })
        let ssaccordiontoggleStatus = SuccessStoriesData.length > 0 ? true : false;
        dispatch({
            SuccessStoriesData: successStories,
            count: 4,
            isSSLockDisabledOnEdit: false,
            ssaccordiontoggle: true,
            type: ActionType.GET_SUCCESSSTORIES_SUCCESS
        });
    },
    EnableAddNewRowforPubServiceAcc: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const PublicServiceAnnouncementData = getState().WeeklyIDSHR.PublicServiceAnnouncementData;
        const publicServiceAcc = [];
        PublicServiceAnnouncementData.map((obj: IPropCommsOnlyPir) => {
            if (obj.id > 0) {
                if (obj.isEdit = true)
                    obj.isEdit = false;
                publicServiceAcc.push(obj);
            }
        })
        let NewCOPIR = {
            id: 0,
            presenterEdit: '',
            topicNameEdit: '',
            timeNeededEdit: '',
            presentationLinkEdit: '',
            isTopicApprovedEdit: false,
            displayOrderEdit: publicServiceAcc.length + 1,
            isEdit: true
        }
        publicServiceAcc.push(NewCOPIR);
        dispatch({
            PublicServiceAnnouncementData: publicServiceAcc,
            isPSALockDisabledOnEdit: true,
            type: ActionType.ENABLE_EDITORADD_PUBLICSERVICEACC
        });
    },
    EnableAddNewRowforSuccessCaseStudies: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SuccessCaseStudiesData = getState().WeeklyIDSHR.SuccessCaseStudiesData;
        const successCaseStudies = [];
        SuccessCaseStudiesData.map((obj: IPropCommsOnlyPir) => {
            if (obj.id > 0) {
                if (obj.isEdit = true)
                    obj.isEdit = false;
                successCaseStudies.push(obj);
            }
        })
        let NewCOPIR = {
            id: 0,
            presenterEdit: '',
            topicNameEdit: '',
            timeNeededEdit: '',
            presentationLinkEdit: '',
            isTopicApprovedEdit: false,
            displayOrderEdit: successCaseStudies.length + 1,
            isEdit: true
        }
        successCaseStudies.push(NewCOPIR);
        dispatch({
            SuccessCaseStudiesData: successCaseStudies,
            isSCSLockDisabledOnEdit: true,
            type: ActionType.ENABLE_EDITORADD_SUCCESSCASESTUDIES
        });
    },
    EnableAddNewRowforSuccessStories: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SuccessStoriesData = getState().WeeklyIDSHR.SuccessStoriesData;
        const successStories = [];
        SuccessStoriesData.map((obj: IPropCommsOnlyPir) => {
            if (obj.id > 0) {
                if (obj.isEdit = true)
                    obj.isEdit = false;
                successStories.push(obj);
            }
        })
        let NewCOPIR = {
            id: 0,
            presenterEdit: '',
            topicNameEdit: '',
            timeNeededEdit: '',
            presentationLinkEdit: '',
            isTopicApprovedEdit: false,
            displayOrderEdit: successStories.length + 1,
            isEdit: true
        }
        successStories.push(NewCOPIR);
        dispatch({
            SuccessStoriesData: successStories,
            isSSLockDisabledOnEdit: true,
            type: ActionType.ENABLE_EDITORADD_SUCCESSSTORIES
        });
    },
    OnPIRPubServiceChangevalues: (e, id): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const PublicServiceAnnouncementData = getState().WeeklyIDSHR.PublicServiceAnnouncementData;
        const publicSerAcc = [];
        let { value, name, checked, type } = e.target;
        if (value === undefined)
            value = '';
        PublicServiceAnnouncementData.map((obj: IPropCommsOnlyPir) => {

            if (obj.id === id) {
                if (type === 'checkbox') {
                    obj[name] = checked;
                }
                else {
                    obj[name] = value;
                }
            }


            publicSerAcc.push(obj);
        })
        dispatch({
            PublicServiceAnnouncementData: publicSerAcc,
            type: ActionType.PUBLICSERVICEANNOUNCEMENT_CHANGE
        });
    },
    OnPIRSuccessCaseStudiesChangevalues: (e, id): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SuccessCaseStudiesData = getState().WeeklyIDSHR.SuccessCaseStudiesData;
        const successCaseStudies = [];
        let { value, name, checked, type } = e.target;
        if (value === undefined)
            value = '';
        SuccessCaseStudiesData.map((obj: IPropCommsOnlyPir) => {

            if (obj.id === id) {
                if (type === 'checkbox') {
                    obj[name] = checked;
                }
                else {
                    obj[name] = value;
                }
            }
            successCaseStudies.push(obj);
        })
        dispatch({
            SuccessCaseStudiesData: successCaseStudies,
            type: ActionType.SUCCESSCASESTUDIES_CHANGE
        });
    },
    OnPIRSuccessStoriesChangevalues: (e, id): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SuccessStoriesData = getState().WeeklyIDSHR.SuccessStoriesData;
        const successStories = [];
        let { value, name, checked, type } = e.target;
        if (value === undefined)
            value = '';
        SuccessStoriesData.map((obj: IPropCommsOnlyPir) => {

            if (obj.id === id) {
                if (type === 'checkbox') {
                    obj[name] = checked;
                }
                else {
                    obj[name] = value;
                }
            }
            successStories.push(obj);
        })
        dispatch({
            SuccessStoriesData: successStories,
            type: ActionType.SUCCESSSTORIES_CHANGE
        });
    },
    GetPirAdminLock: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        WeeklyIDSHRAPI.GetPirAdminLock().
            then((value: IPIRAdminLock[]) => {
                dispatch({
                    isLockedPIR: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedCommsPIR: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedTopic: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedFundamentalsSpotlight: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPubServiceAcc: value.filter((e) => { if (e.lock_Type === 5) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSuccessCaseStudies: value.filter((e) => { if (e.lock_Type === 6) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSuccessStories: value.filter((e) => { if (e.lock_Type === 7) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedOutage: value.filter((e) => { if (e.lock_Type === 8) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedAppendix: value.filter((e) => { if (e.lock_Type === 9) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPIRArray: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedCommsPIRArray: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedTopicArray: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedFundamentalsSpotlightArray: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedPubServiceAccArray: value.filter((e) => { if (e.lock_Type === 5) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedSuccessCaseStudiesArray: value.filter((e) => { if (e.lock_Type === 6) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedSuccessStoriesArray: value.filter((e) => { if (e.lock_Type === 7) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedOutageArray: value.filter((e) => { if (e.lock_Type === 8) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedAppendixArray: value.filter((e) => { if (e.lock_Type === 9) { return e } }).map((e) => { return e.locked_Week }),
                    type: ActionType.GET_PIRADMINLOCK
                });
            });
    },

    EditLockforFundamentalsSpotlight: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let meetingDate = moment(getState().WeeklyIDSHR.MeetingDate).format('YYYY-MM-DD');
        let lockValue = getState().WeeklyIDSHR.isLockedFundamentalsSpotlight == true ? 0 : 1;
        WeeklyIDSHRAPI.PostPirAdminLock(4, meetingDate, lockValue, getState().authUser.login).
            then((value: IPIRAdminLock[]) => {
                dispatch({
                    isLockedPIR: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedCommsPIR: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedTopic: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedFundamentalsSpotlight: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPubServiceAcc: value.filter((e) => { if (e.lock_Type === 5) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSuccessCaseStudies: value.filter((e) => { if (e.lock_Type === 6) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSuccessStories: value.filter((e) => { if (e.lock_Type === 7) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedOutage: value.filter((e) => { if (e.lock_Type === 8) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedAppendix: value.filter((e) => { if (e.lock_Type === 9) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().WeeklyIDSHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedPIRArray: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedCommsPIRArray: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedTopicArray: value.filter((e) => { if (e.lock_Type === 3) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedFundamentalsSpotlightArray: value.filter((e) => { if (e.lock_Type === 4) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedPubServiceAccArray: value.filter((e) => { if (e.lock_Type === 5) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedSuccessCaseStudiesArray: value.filter((e) => { if (e.lock_Type === 6) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedSuccessStoriesArray: value.filter((e) => { if (e.lock_Type === 7) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedOutageArray: value.filter((e) => { if (e.lock_Type === 8) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedAppendixArray: value.filter((e) => { if (e.lock_Type === 9) { return e } }).map((e) => { return e.locked_Week }),
                    type: ActionType.GET_PIRADMINLOCK
                });
            });
    },

    EnableAddforFundamentalsSpotlight: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const FundamentalsSpotlightData = getState().WeeklyIDSHR.FundamentalsSpotlightData;
        const fundamentalsSpotlight = [];
        FundamentalsSpotlightData.map((obj: IPropFundamentalsSpotlightWishr) => {
            if (obj.id > 0) {
                if (obj.isEdit = true)
                    obj.isEdit = false;
                fundamentalsSpotlight.push(obj);
            }
        })
        let NewfundamentalsSpotlight = {
            id: 0,
            presenterEdit: '',
            topicNameEdit: '',
            timeNeededEdit: 0,
            presentationLinkEdit: '',
            isTopicApprovedEdit: false,
            displayOrderEdit: fundamentalsSpotlight.length + 1,
            isEdit: true,
        }
        fundamentalsSpotlight.push(NewfundamentalsSpotlight);
        dispatch({
            FundamentalsSpotlightData: fundamentalsSpotlight,
            type: ActionType.ENABLE_EDITORADD_FUNDAMENTALS_SPOTLIGHT
        });
    },
    EditFundamentalsSpotlightRow: (val: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const FundamentalsSpotlightData = getState().WeeklyIDSHR.FundamentalsSpotlightData;
        const fundamentalsSpotlight = [];
        FundamentalsSpotlightData.map((obj: IPropFundamentalsSpotlightWishr) => {
            if (obj.id === val) {
                obj.isEdit = true;
                obj.presenterEdit = obj.presenter;
                obj.topicNameEdit = obj.topicName;
                obj.timeNeededEdit = obj.timeNeeded;
                obj.presentationLinkEdit = obj.presentationLink;
                obj.isTopicApprovedEdit = obj.isTopicApproved;
                obj.displayOrderEdit = obj.displayOrder;
            }
            else {
                obj.isEdit = false;
            }
            if (obj.id > 0)
                fundamentalsSpotlight.push(obj);
        })
        dispatch({
            FundamentalsSpotlightData: fundamentalsSpotlight,
            type: ActionType.ENABLE_EDITORADD_FUNDAMENTALS_SPOTLIGHT
        });
    },
    CancelEditFundamentalsSpotlightRow: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const FundamentalsSpotlightData = getState().WeeklyIDSHR.FundamentalsSpotlightData;
        const fundamentalsSpotlight = [];
        FundamentalsSpotlightData.map((obj) => {
            obj.isEdit = false;
            obj.presenterEdit = obj.presenter;
            obj.topicNameEdit = obj.topicName;
            obj.timeNeededEdit = obj.timeNeeded;
            obj.presentationLinkEdit = obj.presentationLink;
            obj.isTopicApprovedEdit = obj.isTopicApproved;
            obj.displayOrderEdit = obj.displayOrder;
            if (obj.id > 0)
                fundamentalsSpotlight.push(obj);
        })
        dispatch({
            FundamentalsSpotlightData: fundamentalsSpotlight,
            count: 4,
            type: ActionType.GET_FUNDAMENTALS_SPOTLIGHT_SUCCESS
        });
    },
    OnFundamentalsSpotlightChangevalues: (e, id): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const FundamentalsSpotlightData = getState().WeeklyIDSHR.FundamentalsSpotlightData;
        const fundamentalsSpotlight = [];
        let { value, name } = e.target;
        if (value === undefined)
            value = '';
        FundamentalsSpotlightData.map((obj: IPropFundamentalsSpotlightWishr) => {
            if (obj.id === id) {
                obj[name] = value;

            }
            fundamentalsSpotlight.push(obj);
        })
        dispatch({
            FundamentalsSpotlightData: fundamentalsSpotlight,
            type: ActionType.FUNDAMENTALS_SPOTLIGHT_CHANGE
        });
    },
    UpdateFundamentalsSpotlightOrder: (data: IPropFundamentalsSpotlightWishr[]): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const actualFundamentalsSpotlightData = getState().WeeklyIDSHR.FundamentalsSpotlightData;
        let callActualData: number = 0;
        data.forEach((row, index) => {
            if (actualFundamentalsSpotlightData.some(x => x.id === row.id && x.displayOrder !== index + 1)) {
                Callsetloadingtrue(dispatch);
                callActualData = callActualData + 1
                row.displayOrderEdit = (index + 1).toString()
                row.kpiDate = moment(getState().WeeklyIDSHR.MeetingDate).format('MM/DD/YYYY');
                row.userName = getState().authUser.login
                WeeklyIDSHRAPI.AddorupdateFundamentalsSpotlightWishrData(row)
                    .then((result: number) => {
                        callActualData = callActualData - 1;
                        if (callActualData == 0) {
                            CallGetWeeklyIDSHRPostIncidentsData(dispatch, moment(getState().WeeklyIDSHR.MeetingDate).format('MM/DD/YYYY'), 6)
                        }
                    });
            }
        })
    },
    UpdateweeklyIDSHRDataOrder: (data: IPropAllPirData[]): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const actualweeklyIDSHRData = getState().WeeklyIDSHR.weeklyIDSHRData;
        let callActualData: number = 0;
        data.forEach((row, index) => {
            if (actualweeklyIDSHRData.some(x => x.id === row.id && x.displayOrder !== index + 1)) {
                Callsetloadingtrue(dispatch);
                callActualData = callActualData + 1
                row.displayOrderEdit = (index + 1).toString()
                row.kpiDate = moment(getState().WeeklyIDSHR.MeetingDate).format('MM/DD/YYYY');
                row.userName = getState().authUser.login
                WeeklyIDSHRAPI.AddPirData(row)
                    .then((result: number) => {
                        callActualData = callActualData - 1;
                        if (callActualData == 0) {
                            CallGetWeeklyIDSHRPostIncidentsData(dispatch, moment(getState().WeeklyIDSHR.MeetingDate).format('MM/DD/YYYY'), 1)
                        }
                    });
            }
        })
    },
    UpdateSpecialTopicsDataOrder: (data: IPropSpecialTopicsWishr[]): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const actualSpecialTopicsData = getState().WeeklyIDSHR.SpecialTopicsData;
        let callActualData: number = 0;
        data.forEach((row, index) => {
            if (actualSpecialTopicsData.some(x => x.id === row.id && x.displayOrder !== index + 1)) {
                Callsetloadingtrue(dispatch);
                callActualData = callActualData + 1
                row.displayOrderEdit = (index + 1).toString()
                row.kpiDate = moment(getState().WeeklyIDSHR.MeetingDate).format('MM/DD/YYYY');
                row.userName = getState().authUser.login
                WeeklyIDSHRAPI.AddorupdateSpecialTopicsWishrData(row)
                    .then((result: number) => {
                        callActualData = callActualData - 1;
                        if (callActualData == 0) {
                            CallGetWeeklyIDSHRPostIncidentsData(dispatch, moment(getState().WeeklyIDSHR.MeetingDate).format('MM/DD/YYYY'), 3)
                        }
                    });
            }
        })
    },
    UpdatePubServiceAccDataOrder: (data: IPropPublicServiceAnnouncementWishr[]): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const PublicServiceAnnouncementData = getState().WeeklyIDSHR.PublicServiceAnnouncementData;
        let callActualData: number = 0;
        data.forEach((row, index) => {
            if (PublicServiceAnnouncementData.some(x => x.id === row.id && x.displayOrder !== index + 1)) {
                Callsetloadingtrue(dispatch);
                callActualData = callActualData + 1
                row.displayOrderEdit = (index + 1).toString()
                row.kpiDate = moment(getState().WeeklyIDSHR.MeetingDate).format('MM/DD/YYYY');
                row.userName = getState().authUser.login
                WeeklyIDSHRAPI.AddorUpdatePubServiceAccWishrData(row)
                    .then((result: number) => {
                        callActualData = callActualData - 1;
                        if (callActualData == 0) {
                            CallGetWeeklyIDSHRPostIncidentsData(dispatch, moment(getState().WeeklyIDSHR.MeetingDate).format('MM/DD/YYYY'), 5)
                        }
                    });
            }
        })
    },
    UpdateSuccessCaseStudiesDataOrder: (data: IPropPublicServiceAnnouncementWishr[]): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SuccessCaseStudiesData = getState().WeeklyIDSHR.SuccessCaseStudiesData;
        let callActualData: number = 0;
        data.forEach((row, index) => {
            if (SuccessCaseStudiesData.some(x => x.id === row.id && x.displayOrder !== index + 1)) {
                Callsetloadingtrue(dispatch);
                callActualData = callActualData + 1
                row.displayOrderEdit = (index + 1).toString()
                row.kpiDate = moment(getState().WeeklyIDSHR.MeetingDate).format('MM/DD/YYYY');
                row.userName = getState().authUser.login
                WeeklyIDSHRAPI.AddorUpdateSuccessCaseStudiesWishrData(row)
                    .then((result: number) => {
                        callActualData = callActualData - 1;
                        if (callActualData == 0) {
                            CallGetWeeklyIDSHRPostIncidentsData(dispatch, moment(getState().WeeklyIDSHR.MeetingDate).format('MM/DD/YYYY'), 8)
                        }
                    });
            }
        })
    },
    UpdateSuccessStoriesDataOrder: (data: IPropSuccessStoriesWishr[]): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SuccessStoriesData = getState().WeeklyIDSHR.SuccessStoriesData;
        let callActualData: number = 0;
        data.forEach((row, index) => {
            if (SuccessStoriesData.some(x => x.id === row.id && x.displayOrder !== index + 1)) {
                Callsetloadingtrue(dispatch);
                callActualData = callActualData + 1
                row.displayOrderEdit = (index + 1).toString()
                row.kpiDate = moment(getState().WeeklyIDSHR.MeetingDate).format('MM/DD/YYYY');
                row.userName = getState().authUser.login
                WeeklyIDSHRAPI.AddorUpdateSuccessStoriesWishrData(row)
                    .then((result: number) => {
                        callActualData = callActualData - 1;
                        if (callActualData == 0) {
                            CallGetWeeklyIDSHRPostIncidentsData(dispatch, moment(getState().WeeklyIDSHR.MeetingDate).format('MM/DD/YYYY'), 9)
                        }
                    });
            }
        })
    },
    UpdateAppendixMaterialsDataOrder: (data: IPropAppendixMaterialsWishr[]): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const actualAppendixMaterialsData = getState().WeeklyIDSHR.AppendixMaterialsData;
        let callActualData: number = 0;
        data.forEach((row, index) => {
            if (actualAppendixMaterialsData.some(x => x.id === row.id && x.displayOrder !== index + 1)) {
                Callsetloadingtrue(dispatch);
                callActualData = callActualData + 1
                row.displayOrderEdit = (index + 1).toString()
                row.kpiDate = moment(getState().WeeklyIDSHR.MeetingDate).format('MM/DD/YYYY');
                row.userName = getState().authUser.login
                WeeklyIDSHRAPI.AddorupdateAppendixMaterialsWishrData(row)
                    .then((result: number) => {
                        callActualData = callActualData - 1;
                        if (callActualData == 0) {
                            CallGetWeeklyIDSHRPostIncidentsData(dispatch, moment(getState().WeeklyIDSHR.MeetingDate).format('MM/DD/YYYY'), 10)
                        }
                    });
            }
        })
    }
};
const getMeetingDate = () => {
    const today = new Date(); // "07/23/2016"   
    const currentDayofWeek = today.getDay();
    const daysUntilNextTuesday = (2 + 7 - currentDayofWeek) % 7;

    const nextTuesday = new Date(today);
    nextTuesday.setDate(today.getDate() + daysUntilNextTuesday);
    nextTuesday.setHours(0, 0, 0, 0);
    return nextTuesday;

}
const getMaxDate = () => {
    const today = new Date(); // "07/23/2016"   
    const currentYear = today.getFullYear() + 3;
    const maxDateofYear = new Date(currentYear, 11, 31);
    return maxDateofYear;

}
// function to call setloadingtrue action method
const Callsetloadingtrue = (dispatch) => {
    dispatch(actionCreators.setloadingtrue());
}
// function to call setloadingtrue action method
const CallGetWeeklyIDSHRPostIncidentsData = (dispatch, Meetingdate: string, Callcount) => {
    dispatch(actionCreators.GetWeeklyIDSHRPostIncidentsData(Meetingdate, Callcount));
}

