import React from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../store';
import { actionCreators } from '../store/home-tiles';

type NavServiceProps = typeof actionCreators & { readonly isAuthenticated: boolean, ServiceGroup: any };

const NavServices: React.FC<NavServiceProps> = ({ isAuthenticated, ServiceGroup }) => {

    const groups: any = [];
    return (<ul>{
        ServiceGroup.serviceGroup.map((directorGroup, directorName) => {
            if (groups.indexOf(directorGroup.groupName) === -1) {
                groups.push(directorGroup.groupName);
                return (<li>
                    &nbsp;&nbsp;  {(directorGroup.groupName.split('<br/>').length > 1) ? directorGroup.groupName.split('<br/>')[1] : directorGroup.groupName.split('<br/>')[0]}
                    <ul> {
                        ServiceGroup.serviceGroup.map((subdirectorGroup, subdirectorName) => {
                            if (subdirectorGroup.groupName === directorGroup.groupName) {
                                return (<li style={{ color: 'orange' }}>{subdirectorGroup.serviceName}</li>)
                            }
                        })
                    }</ul>
                </li>)
            }
        })
    }</ul>)
};

const mapStateToProps = (state: IApplicationState) => ({
    ServiceGroup: state.serviceGroup
});

export default connect(mapStateToProps)(NavServices);
