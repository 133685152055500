import { IAppThunkAction, ReduxAction } from '../';
import { ActionType, IPropConfigurationState, IPropServiceToolsConfig, IPropServiceGroupToolsConfig } from './types';
import { ConfigurationAPI, CommonAPI, ServiceGroupApi, DailyDataApi } from '../../api';
import { IServiceGroup } from '../home-tiles';
import { toast } from "react-toastify";
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../utils';

export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    setloadingtrue: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
    },
    setloadingfalse: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_FALSE
        });
    },
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        let UpdatedBy;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            UpdatedBy = LoginuserId;
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                        dispatch({
                            UpdatedBy: UpdatedBy,
                            type: ActionType.UPDATE_LOGGEDIN_USER
                        });
                    }
                })
        }

    },
    GetHeatMapTabServicesData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.GET_SERVICEGROUPS_REQUEST
        });
        ServiceGroupApi.GetToolsConfigurationHeatMapServices()
            .then((ServicesRawData: IPropServiceToolsConfig[]) => {
                dispatch({
                    servicesdata: ServicesRawData,
                    type: ActionType.GET_SERVICEGROUPS_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.GET_SERVICEGROUPS_FAIL
                });
            });
    },
    GetConfigurationData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.GET_CONFIGURATION_REQUEST
        });
        let services = [];
        let tenants = [];
        let tenantsalreadyOnboarded = [];
        let selectedService = {};
        let serviceSelected = false;
        ConfigurationAPI.ServicesFromServiceTree()
            .then((ResponseData) => {
                ResponseData.map((obj) => {
                    services.push({ serviceId: obj.serviceOid, serviceName: obj.serviceName });
                });
                selectedService = { serviceId: null, serviceName: null };
                dispatch({
                    services: services,
                    selectedService: selectedService,
                    tenants: tenants,
                    tenantsalreadyOnboarded: tenantsalreadyOnboarded,
                    serviceSelected: serviceSelected,
                    type: ActionType.GET_CONFIGURATION_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.GET_CONFIGURATION_FAILURE
                });
            });
    },
    UpdatequestionarehighlevelInfo: (event): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let questionarehighlevelInfo = getState().Configuration.questionarehighlevelInfo;
        questionarehighlevelInfo = event.target.value;
        dispatch({
            questionarehighlevelInfo: questionarehighlevelInfo,
            type: ActionType.CONFIGURATION_QUESTIONAREHIGHLEVELINFO_CHANGE
        });
    },
    UpdatequestionareOncall: (event): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let questionareOncall = getState().Configuration.questionareOncall;
        questionareOncall = event.target.value;
        dispatch({
            questionareOncall: questionareOncall,
            type: ActionType.CONFIGURATION_QUESTIONAREONCALL_CHANGE
        });
    },
    setquestionarecclFiltering: (value): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let questionarecclFiltering = getState().Configuration.questionarecclFiltering;
        questionarecclFiltering = value;
        dispatch({
            questionarecclFiltering: questionarecclFiltering,
            type: ActionType.CONFIGURATION_QUESTIONARECCLFILTERING_CHANGE
        });
    },
    updatequestionarecommDL: (event): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let questionarecommDL = getState().Configuration.questionarecommDL;
        questionarecommDL = event.target.value;
        dispatch({
            questionarecommDL: questionarecommDL,
            type: ActionType.CONFIGURATION_QUESTIONARECOMMDL_CHANGE
        });
    },
    updatequestionarecclPM: (event): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let questionarecclPM = getState().Configuration.questionarecclPM;
        questionarecclPM = event.target.value;
        dispatch({
            questionarecclPM: questionarecclPM,
            type: ActionType.CONFIGURATION_QUESTIONARECCLPM_CHANGE
        });
    },
    updatequestionarecustomerImpacting: (event): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let questionarecustomerImpacting = getState().Configuration.questionarecustomerImpacting;
        questionarecustomerImpacting = event.target.value;
        dispatch({
            questionarecustomerImpacting: questionarecustomerImpacting,
            type: ActionType.CONFIGURATION_QUESTIONARECUSTOMERIMPACTING_CHANGE
        });
    },
    updatequestionareGPMName: (event): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let questionareGPMName = getState().Configuration.questionareGPMName;
        questionareGPMName = event.target.value;
        dispatch({
            questionareGPMName: questionareGPMName,
            type: ActionType.CONFIGURATION_QUESTIONAREGPMNAME_CHANGE
        });
    },
    updatequestionareIsTenantOrSubscriptionIdBased: (event): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let questionareIsTenantOrSubscriptionIdBased = getState().Configuration.questionareIsTenantOrSubscriptionIdBased;
        questionareIsTenantOrSubscriptionIdBased = event.target.value;
        dispatch({
            questionareIsTenantOrSubscriptionIdBased: questionareIsTenantOrSubscriptionIdBased,
            type: ActionType.CONFIGURATION_QUESTIONAREISTENANTORSUBSCRIPTIONIDBASED_CHANGE
        });
    },
    UpdatequestionareComments: (event): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let questionareComments = getState().Configuration.questionareComments;
        questionareComments = event.target.value;
        dispatch({
            questionareComments: questionareComments,
            type: ActionType.CONFIGURATION_COMMENTS_CHANGE
        });
    },
    updatequestionarePartners: (event): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let questionarePartners = getState().Configuration.questionarePartners;
        questionarePartners = event.target.value;
        dispatch({
            questionarePartners: questionarePartners,
            type: ActionType.CONFIGURATION_PARTNERS_CHANGE
        });
    },
    // Event: Service Change 
    serviceChange: (value): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let selectedService = getState().Configuration.selectedService;
        selectedService = value;
        let selectedTenant = { tenantId: null, tenantname: null };
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        dispatch({
            selectedService: selectedService,
            selectedTenant: selectedTenant,
            type: ActionType.SERVICE_SELECTED
        });
        let serviceSelected = true;
        let isheatMapValidated = true;
        let tenants = [];
        let tenantsalreadyOnboarded = [];
        let isEnabled = false;
        let showMessage = false;
        let addNewService = false;
        let tenantshide = false;
        let isRBLValidated = true;
        let showRBLMessage = false;
        let showRBLOnboardedMessage = false;
        let tenantsWithOnboarded = false;
        let displayCCLSuccessMessage = false;

        let servicesdata = getState().Configuration.servicesdata;
        let service_ids = getState().Configuration.service_ids;
        let currentserviceId = getState().Configuration.currentserviceId;

        //-------------- clear the CCL UI Data ----------------//
        let questionarecclSelfConfiguration = null;
        let questionarehighlevelInfo = '';
        let questionareOncall = '';
        let questionarecclFiltering = null;
        let questionarecommDL = null;
        let questionarecclPM = null;
        let questionarecustomerImpacting = '';

        service_ids = servicesdata.filter(ser => ser.serviceTreeID.trim() === selectedService.serviceId.trim());

        if (service_ids !== undefined && service_ids !== null && service_ids.length > 0) {
            currentserviceId = service_ids[0].serviceID;
        }
        else {
            currentserviceId = 1;
        }


        dispatch({
            serviceSelected: serviceSelected,
            isheatMapValidated: isheatMapValidated,
            tenants: tenants,
            tenantsalreadyOnboarded: tenantsalreadyOnboarded,
            isEnabled: isEnabled,
            showMessage: showMessage,
            addNewService: addNewService,
            tenantshide: tenantshide,
            isRBLValidated: isRBLValidated,
            showRBLMessage: showRBLMessage,
            showRBLOnboardedMessage: showRBLOnboardedMessage,
            tenantsWithOnboarded: tenantsWithOnboarded,
            displayCCLSuccessMessage: displayCCLSuccessMessage,
            questionarecclSelfConfiguration: questionarecclSelfConfiguration,
            questionarehighlevelInfo: questionarehighlevelInfo,
            questionareOncall: questionareOncall,
            questionarecclFiltering: questionarecclFiltering,
            questionarecommDL: questionarecommDL,
            questionarecclPM: questionarecclPM,
            questionarecustomerImpacting: questionarecustomerImpacting,
            service_ids: service_ids,
            currentserviceId: currentserviceId,
            type: ActionType.UPDATE_CONFIGURATIONSERVICE_CHANGE
        });
        if (selectedService) {
            ConfigurationAPI.TenantFromServiceTree(selectedService.serviceId)
                .then((TenantResponseData) => {
                    tenants = [];
                    tenantsalreadyOnboarded = [];
                    TenantResponseData.map((row) => {
                        if (!row.isCCLOnboarded) {
                            tenants.push({ tenantId: row.tenantId, tenantname: row.tenantName });
                        }
                        else {
                            tenantsalreadyOnboarded.push({ tenantId: row.tenantId, tenantname: row.tenantName });
                        }
                    });
                    if (tenants.length === 0) {
                        tenantshide = true;
                        if (tenantsalreadyOnboarded.length !== 0) {
                            tenantsWithOnboarded = true;
                        }
                    }
                    dispatch({
                        tenants: tenants,
                        tenantsalreadyOnboarded: tenantsalreadyOnboarded,
                        tenantshide: tenantshide,
                        tenantsWithOnboarded: tenantsWithOnboarded,
                        type: ActionType.GET_CONFIGURATIONTENANT_SUCCESS
                    });
                }).catch(error => {
                    dispatch({
                        type: ActionType.SET_LOADING_FALSE
                    });
                });
            if (isheatMapValidated) {
                CallHeatmapValidate(dispatch);
            }
            else {
                dispatch({
                    type: ActionType.SET_LOADING_FALSE
                });
            }
            if (isRBLValidated) {
                CallRBLValidate(dispatch);
            }
            else {
                dispatch({
                    type: ActionType.SET_LOADING_FALSE
                });
            }
        }
        else {
            dispatch({
                type: ActionType.SET_LOADING_FALSE
            });
        }

    },
    tenantChange: (value): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let selectedTenant = getState().Configuration.selectedTenant;
        selectedTenant = value;
        dispatch({
            selectedTenant: selectedTenant,
            type: ActionType.TENANT_SELECTED
        });
        let isEnabled = true;
        let selectedService = getState().Configuration.selectedService;
        let questionarecclSelfConfiguration = false;
        dispatch({
            questionarecclSelfConfiguration: questionarecclSelfConfiguration,
            isEnabled: isEnabled,
            type: ActionType.TENANT_CHANGED
        });
        ConfigurationAPI.QuestionaireInfoCCL(selectedService.serviceId, selectedTenant.tenantId).then((ResponseData) => {
            if (ResponseData !== undefined && ResponseData.length > 0) {
                var data = ResponseData[0];
                let questionarehighlevelInfo = data.highLevelInfo;
                let questionareOncall = data.onCallLivesiteDashboard;
                let questionarecclFiltering = data.cclFilteringEngagement === true ? 'true' : 'false';
                let questionarecommDL = data.cclOutageDlls;
                let questionarecclPM = data.scmConfigurationPMs;
                let questionarecustomerImpacting = data.customerImpacting;
                let questionareGPMName = data.gpmName;
                let questionareIsTenantOrSubscriptionIdBased = data.isTenantOrSubscriptionIdBased;
                let questionareComments = data.comments;
                let questionarePartners = data.partners;

                dispatch({
                    questionarehighlevelInfo: questionarehighlevelInfo,
                    questionareOncall: questionareOncall,
                    questionarecclFiltering: questionarecclFiltering,
                    questionarecommDL: questionarecommDL,
                    questionarecclPM: questionarecclPM,
                    questionarecustomerImpacting: questionarecustomerImpacting,
                    questionareGPMName: questionareGPMName,
                    questionareIsTenantOrSubscriptionIdBased: questionareIsTenantOrSubscriptionIdBased,
                    questionareComments: questionareComments,
                    questionarePartners: questionarePartners,
                    type: ActionType.QUESTIONNAIRE_INFO_CCL
                });
            }
        });
        ConfigurationAPI.ValidateCCLSelfConfiguration(selectedTenant.tenantname).then((ResponseData) => {
            if (ResponseData !== undefined) {
                if (ResponseData) {
                    questionarecclSelfConfiguration = true;
                }
                else {
                    questionarecclSelfConfiguration = false;
                }
            }
            dispatch({
                questionarecclSelfConfiguration: questionarecclSelfConfiguration,
                type: ActionType.VALIDATE_CCL_SELFCONFIGURATION
            });
        });
    },
    resetFilters: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let questionarehighlevelInfo = getState().Configuration.questionarehighlevelInfo;
        let questionareOncall = getState().Configuration.questionareOncall;
        let questionarecclFiltering = getState().Configuration.questionarecclFiltering;
        let questionarecommDL = getState().Configuration.questionarecommDL;
        let questionarecclPM = getState().Configuration.questionarecclPM;
        let questionarecustomerImpacting = getState().Configuration.questionarecustomerImpacting;
        let questionarecclSelfConfiguration = getState().Configuration.questionarecclSelfConfiguration;
        let questionareGPMName = getState().Configuration.questionareGPMName;
        let questionareIsTenantOrSubscriptionIdBased = getState().Configuration.questionareIsTenantOrSubscriptionIdBased;
        let questionareComments = getState().Configuration.questionareComments;
        let questionarePartners = getState().Configuration.questionarePartners;

        questionarecclSelfConfiguration = null;
        questionarehighlevelInfo = '';
        questionareOncall = '';
        questionarecclFiltering = null;
        questionarecommDL = null;
        questionarecclPM = null;
        questionarecustomerImpacting = '';
        questionareGPMName = '';
        questionareIsTenantOrSubscriptionIdBased = '';
        questionareComments = '';
        questionarePartners = '';
        dispatch({
            questionarecclSelfConfiguration: questionarecclSelfConfiguration,
            questionarehighlevelInfo: questionarehighlevelInfo,
            questionareOncall: questionareOncall,
            questionarecclFiltering: questionarecclFiltering,
            questionarecommDL: questionarecommDL,
            questionarecclPM: questionarecclPM,
            questionarecustomerImpacting: questionarecustomerImpacting,
            questionareGPMName: questionareGPMName,
            questionareIsTenantOrSubscriptionIdBased: questionareIsTenantOrSubscriptionIdBased,
            questionareComments: questionareComments,
            questionarePartners: questionarePartners,
            type: ActionType.RESET_FILTERS_CONFIGURATION
        });
    },
    HeatmapValidate: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let addNewService = false;
        let showMessage = false;
        dispatch({
            addNewService: addNewService,
            showMessage: showMessage,
            type: ActionType.HEATMAP_VALIDATE
        });
        CallOnBoard_Service_HeatMap(dispatch);
    },
    RBLValidate: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let showRBLMessage = false;
        let showRBLOnboardedMessage = false;
        let selectedService = getState().Configuration.selectedService;
        if (selectedService.serviceName !== null || selectedService.serviceName !== undefined) {
            DailyDataApi.ValidateLiveSiteService(selectedService.serviceId).then((ResponseData) => {
                if (ResponseData !== undefined) {
                    ConfigurationAPI.TenantFromServiceTree(selectedService.serviceId)
                        .then((TenantResponseData) => {
                            if (TenantResponseData.length > 0) {
                                showRBLOnboardedMessage = true;
                            }
                            else {
                                showRBLMessage = true;
                            }
                            dispatch({
                                showRBLOnboardedMessage: showRBLOnboardedMessage,
                                showRBLMessage: showRBLMessage,
                                type: ActionType.RBL_VALIDATE
                            });
                            dispatch({
                                type: ActionType.SET_LOADING_FALSE
                            });
                        }).catch(error => {
                            dispatch({
                                type: ActionType.SET_LOADING_FALSE
                            });
                        });
                }
                else {
                    dispatch({
                        type: ActionType.SET_LOADING_FALSE
                    });
                }
            }).catch(error => {
                dispatch({
                    type: ActionType.SET_LOADING_FALSE
                });
            });
        }
        else {
            dispatch({
                type: ActionType.SET_LOADING_FALSE
            });
            toast.error(renderToastifyMsg("Please Select the Service to Proceed Further",
                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 });
        }
    },
    OnBoard_Service_HeatMap: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let selectedService = getState().Configuration.selectedService;
        let showMessage = getState().Configuration.showMessage;
        let addNewService = getState().Configuration.addNewService;
        let isheatMapValidated = getState().Configuration.isheatMapValidated;
        if (selectedService.serviceName !== null || selectedService.serviceName !== undefined) {
            DailyDataApi.ValidateLiveSiteService(selectedService.serviceId).then((ResponseData) => {
                if (ResponseData !== undefined) {
                    if (ResponseData) {
                        showMessage = true;
                        dispatch({
                            showMessage: showMessage,
                            type: ActionType.ONBOARD_SERVICEHEATMAP_VALIDATED
                        });
                    }
                    else {
                        addNewService = true;
                        dispatch({
                            addNewService: addNewService,
                            type: ActionType.ONBOARD_SERVICEHEATMAP_NOTVALIDATED
                        });
                    }
                }
                else {
                    dispatch({
                        type: ActionType.SET_LOADING_FALSE
                    });
                }
            }).catch(error => {
                dispatch({
                    type: ActionType.SET_LOADING_FALSE
                });
            });
        }
        else {
            isheatMapValidated = true;
            dispatch({
                isheatMapValidated: isheatMapValidated,
                type: ActionType.ONBOARD_SERVICEHEATMAP_VALIDATE_FAIL
            });
            dispatch({
                type: ActionType.SET_LOADING_FALSE
            });
            toast.error(renderToastifyMsg("Please Select the Service to Proceed Further",
                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 });
        }
    },
    OnBoard_Service_CCL: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let selectedService = getState().Configuration.selectedService;
        let selectedTenant = getState().Configuration.selectedTenant;
        let UpdatedBy = getState().Configuration.UpdatedBy;
        let questionarehighlevelInfo = getState().Configuration.questionarehighlevelInfo;
        let questionareOncall = getState().Configuration.questionareOncall;
        let questionarecclFiltering = getState().Configuration.questionarecclFiltering;
        let questionarecommDL = getState().Configuration.questionarecommDL;
        let questionarecclPM = getState().Configuration.questionarecclPM;
        let questionarecustomerImpacting = getState().Configuration.questionarecustomerImpacting;
        let questionarecclSelfConfiguration = getState().Configuration.questionarecclSelfConfiguration;
        let questionareGPMName = getState().Configuration.questionareGPMName;
        let questionareIsTenantOrSubscriptionIdBased = getState().Configuration.questionareIsTenantOrSubscriptionIdBased;
        let questionareComments = getState().Configuration.questionareComments;
        let questionarePartners = getState().Configuration.questionarePartners;

        if (selectedService.serviceId === '' || selectedService.serviceId === undefined || selectedService.serviceId === null) {
            toast.error(renderToastifyMsg("Please Select the Service Name",
                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 });
            return false;
        }
        else if (selectedTenant.tenantId === '' || selectedTenant.tenantId === undefined || selectedTenant.tenantId === null) {
            toast.error(renderToastifyMsg("Please Select the Tenant",
                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 });
            return false;
        }
        else if (questionareOncall === '' || questionareOncall === undefined || questionareOncall === null) {
            toast.error(renderToastifyMsg("Please enter Oncall Livesite dashboards",
                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 });
            return false;
        }
        else if (questionarecclFiltering === '' || questionarecclFiltering === undefined || questionarecclFiltering === null) {
            toast.error(renderToastifyMsg("Please choose filtering for the engagement",
                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 });
            return false;
        }
        else if (questionarecommDL === '' || questionarecommDL === undefined || questionarecommDL === null) {
            toast.error(renderToastifyMsg("Please enter DLs",
                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 });
            return false;
        }
        else if (questionarecustomerImpacting === '' || questionarecustomerImpacting === undefined || questionarecustomerImpacting === null) {
            toast.error(renderToastifyMsg("Please enter high-level customer impacting scenarios",
                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 });
            return false;
        }
        else if (questionareGPMName === '' || questionareGPMName === undefined || questionareGPMName === null) {
            toast.error(renderToastifyMsg("Please Provide Service Owner details",
                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 });
            return false;
        }
        else if (questionareIsTenantOrSubscriptionIdBased === '' || questionareIsTenantOrSubscriptionIdBased === undefined || questionareIsTenantOrSubscriptionIdBased === null) {
            toast.error(renderToastifyMsg("Please Provide Tenant ID or Subscription ID based",
                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 });
            return false;
        }
        else if (questionarePartners === '' || questionarePartners === undefined || questionarePartners === null) {
            toast.error(renderToastifyMsg("Please enter Partners customers who will be impacted",
                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 });
            return false;
        }
        var serviceCCLDetails = {
            ServiceOid: selectedService.serviceId,
            TenantId: selectedTenant.tenantId,
            UpdatedBy: UpdatedBy,
            SelfConfiguration: questionarecclSelfConfiguration,
            GPMName: questionareGPMName,
            OnCallLivesiteDashboard: questionareOncall,
            CCLFilteringEngagement: questionarecclFiltering,
            CCLOutageDlls: questionarecommDL,
            ScmConfigurationPMs: questionarecclPM,
            CustomerImpacting: questionarecustomerImpacting,
            IsTenantOrSubscriptionIdBased: questionareIsTenantOrSubscriptionIdBased,
            Comments: questionareComments,
            Partners: questionarePartners
        };
        if (window.confirm("Are you sure you want to Onboard the Service to CCL")) {
            dispatch({
                type: ActionType.SET_LOADING_TRUE
            });
            ConfigurationAPI.InsertUpdateCCLService(serviceCCLDetails)
                .then((ResponseData) => {
                    if (ResponseData !== undefined) {
                        toast.success(renderToastifyMsg("Next Step: CCL overview meeting will be scheduled with the team and CCL monitoring will be enabled after that.",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 });
                        let displayCCLSuccessMessage = true;
                        let isEnabled = false;
                        dispatch({
                            displayCCLSuccessMessage: displayCCLSuccessMessage,
                            isEnabled: isEnabled,
                            type: ActionType.ONBOARD_CCL_SUCCESS
                        });
                        CallSendEmail(dispatch);
                    }
                    CallresetFilters(dispatch);
                }).catch(error => {
                    dispatch({
                        type: ActionType.SET_LOADING_FALSE
                    });
                });
        }
        else {
            CallresetFilters(dispatch);
        }
    },
    HeatMapServiceInsert: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let selectedService = getState().Configuration.selectedService;
        // Passing 0 Value for GroupId column under Services Table instead of selectedServiceGroup.GroupId
        let serviceGroupId = 0;
        DailyDataApi.AddLiveSiteService(selectedService.serviceId, selectedService.serviceName, serviceGroupId)
            .then((ResponseData) => {
                if (ResponseData !== undefined) {
                    toast.success(renderToastifyMsg("OnBoraded " + selectedService.serviceName + " Service successfully to HeatMap",
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 });
                    let showMessage = true;
                    let addNewService = false;
                    dispatch({
                        showMessage: showMessage,
                        addNewService: addNewService,
                        currentserviceId: ResponseData,
                        type: ActionType.ONBOARD_HEATMAP_SUCCESS
                    });
                }
            });
    },
    SendEmail: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var selfConfVal;
        var CCLFilterVal;
        let questionarecclSelfConfiguration = getState().Configuration.questionarecclSelfConfiguration;
        let questionarecclFiltering = getState().Configuration.questionarecclFiltering;
        let questionarehighlevelInfo = getState().Configuration.questionarehighlevelInfo;
        let questionareOncall = getState().Configuration.questionareOncall;
        let questionarecommDL = getState().Configuration.questionarecommDL;
        let questionarecclPM = getState().Configuration.questionarecclPM;
        let questionarecustomerImpacting = getState().Configuration.questionarecustomerImpacting;
        let UpdatedBy = getState().Configuration.UpdatedBy;
        let selectedService = getState().Configuration.selectedService;
        let selectedTenant = getState().Configuration.selectedTenant;
        let questionareGPMName = getState().Configuration.questionareGPMName;
        let questionareIsTenantOrSubscriptionIdBased = getState().Configuration.questionareIsTenantOrSubscriptionIdBased;
        let questionareComments = getState().Configuration.questionareComments;
        let questionarePartners = getState().Configuration.questionarePartners;
        if (questionarecclSelfConfiguration) {
            selfConfVal = 'YES';
        }
        else {
            selfConfVal = 'NO';
        }
        if (questionarecclFiltering === true || questionarecclFiltering === "true") {
            CCLFilterVal = 'YES';
        }
        else {
            CCLFilterVal = 'NO';
        }
        var tab_text = "Hi All," + '<br><br>' + "Here are the Details Provided by " + UpdatedBy + " to Onboard the below tenant to CCL. ";
        tab_text = tab_text + "Please review and take the next course of actions" + "<br><br>";
        tab_text = tab_text + "Service Name : <b>" + selectedService.serviceName + '</b>' + "<br>";
        tab_text = tab_text + "Tenant Name : <b>" + selectedTenant.tenantname + '</b>' + "<br><br>";
        tab_text = tab_text + "<table border='1'>";
        tab_text = tab_text + '<tr>' +
            '<th align="left">Questionnaire</th>' +
            '<th align="left">Answers</th>' +
            '</tr>' +
            '<tr>' +
            '<td>' + '1. Provide ICM Tenant Name: ' + '</td>' +
            '<td>' + selectedTenant.tenantname + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + '2. Did Self - Configuration Step Completed: ' + '</td>' +
            '<td>' + selfConfVal + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + ' 3. Provide link to OnCall Live Site dashboards' + '</td>' +
            '<td>' + questionareOncall + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + ' 4. By default, CCL proactively monitors incidents that match the following filters: ICM team, Environment = Prod, State = Active, Severity = 0, 1 or 2.' + '<br/>' + 'Are these filters applicable and correct for your service?' + '</td>' +
            '<td>' + CCLFilterVal + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + '5. In cases like outages or potential outages, CCL will send internal incident updates (aka ‘internal comms’) under the guidance of the Incident Manager. These are engineering-focussed emails aimed at the Identity LT and any other Identity or partner teams that would benefit from the information.' + '<br/>' + 'Please provide the Identity and/or partner DLs that should receive these emails by default (e.g. myserviceteam@) ' + '</td >' +
            '<td>' + questionarecommDL + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + '6. Name of the Service Owner' + '</td>' +
            '<td>' + questionareGPMName + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + "7. What are the high - level customer impacting scenarios for your service ? E.g.User can't login." + '</td>' +
            '<td>' + questionarecustomerImpacting + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + '8. In case of an outage, which partner’s customers would be impacted?' + '</td>' +
            '<td>' + questionarePartners + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + '9. Is the service tenant ID or subscription ID based?' + '</td>' +
            '<td>' + questionareIsTenantOrSubscriptionIdBased + '</td>' +
            '</tr>' +
            '<td>' + '10. Do you have any other question or comments?' + '</td>' +
            '<td>' + questionareComments + '</td>' +
            '</tr>';
        tab_text = tab_text + "<br> ";
        tab_text = tab_text + '</table></body></html>';
        tab_text = tab_text + '<br><br>' + "Thanks!";
        let LoginuserId = getState().authUser.login;

        ConfigurationAPI.CCL_SendEmail(LoginuserId, selectedTenant.tenantname, selectedService.serviceName, (tab_text))
            .then((result: number) => {
                if (result != -1) {
                    toast.success(
                        renderToastifyMsg("Email sent succesfully!! LiveSite PM Team will be in touch with you shortly!! ",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                    );
                } else {
                    toast.error(
                        renderToastifyMsg("There is error While sending email, Please contact Admin!",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                    );
                }

            }).catch(error => {
                toast.error(
                    renderToastifyMsg("There is error While sending email, Please contact Admin!",
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                );
            });
    }
};

// function to call HeatmapValidate action method
const CallHeatmapValidate = (dispatch) => {
    dispatch(actionCreators.HeatmapValidate());
}

// function to call RBLValidate action method
const CallRBLValidate = (dispatch) => {
    dispatch(actionCreators.RBLValidate());
}

// function to call OnBoard_Service_HeatMap action method
const CallOnBoard_Service_HeatMap = (dispatch) => {
    dispatch(actionCreators.OnBoard_Service_HeatMap());
}

// function to call resetFilters action method
const CallresetFilters = (dispatch) => {
    dispatch(actionCreators.resetFilters());
}

// function to call SendEmail action method
const CallSendEmail = (dispatch) => {
    dispatch(actionCreators.SendEmail());
}








