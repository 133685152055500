import React, { useState, useRef, Fragment } from 'react';
import { isArrayWithLength, renderToastifyMsg, isNullOrUndefined } from '../../../utils';
import { WeeklyAPI } from '../../../api';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { WeeklyDropdownOption } from '../../../store/service-health';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { AutoComplete } from 'primereact/autocomplete';
import { Checkbox } from 'primereact/checkbox';
import { toast } from 'react-toastify';
import LoadingOverlay from 'react-loading-overlay';

import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Row,
    Col
} from 'reactstrap';
import { ReduxAction, IAppThunkAction } from '../../../store';

type WeeklySelfServeProps = {
    LoginuserId,
    setloadingtrue,
    setloadingfalse,
    serviceGroups: any,
    selectedServicegroupval: WeeklyDropdownOption;
    services: any,
    selectedServiceval: WeeklyDropdownOption;
    readonly handleServiceGroupChange: (selectedServicegroupval: WeeklyDropdownOption) => IAppThunkAction<ReduxAction>;
    readonly handleServicesChange: (selectedServiceval: WeeklyDropdownOption) => IAppThunkAction<ReduxAction>;
    metricGroups: any;
    selectedMetricgroupval: any;
    readonly handleMetricGroupChange: (selectedMetricgroupval: WeeklyDropdownOption) => IAppThunkAction<ReduxAction>;
    activeMetrics: any;
    selectedMetric: any;
    readonly handleMetricChange: (selectedMetric: WeeklyDropdownOption) => IAppThunkAction<ReduxAction>;
    environments: any;
    selectedEnvironment: any;
    readonly handleEnvironmentChange: (selectedEnvironment: WeeklyDropdownOption) => IAppThunkAction<ReduxAction>;
    datasource: any;
    authenticationTypes: any;
    selectedDatasource: any;
    readonly handledatasourceChange: (selectedDatasource: WeeklyDropdownOption) => IAppThunkAction<ReduxAction>;
    metricDataSourceDetails: any;
    metricDataSourceInactive: any;
    icmMetricDataSourceInactive: any;
    serverName: any;
    newMetricName: any;
    newMetricGroupName: any;
    database: any;
    mdmMetricName: any;
    authenticationType: any;
    readonly addNewMetric: (newMetricName) => IAppThunkAction<ReduxAction>;
    readonly addNewMetricGroup: (newMetricGroupName) => IAppThunkAction<ReduxAction>;
    readonly serverNameUpdate: (serverName) => IAppThunkAction<ReduxAction>;
    readonly databaseUpdate: (database) => IAppThunkAction<ReduxAction>;
    readonly mdmMetricNameUpdate: (mdmMetricName) => IAppThunkAction<ReduxAction>;
    readonly authenticationTypeUpdate: (authenticationType) => IAppThunkAction<ReduxAction>;
    target: any;
    notes: any;
    queryOrUrl: any;
    readonly UpdatequeryOrUrl: (queryOrUrl) => IAppThunkAction<ReduxAction>;
    readonly Updatetarget: (target) => IAppThunkAction<ReduxAction>;
    readonly Updatenotes: (notes) => IAppThunkAction<ReduxAction>;
    addMetricDataSourceData: any;
    clearMetricDataSourceData: any;
    targetTypes: any;
    EditMetricDataSourceRow: any;
    hideWeeklySelfServeData: any;
    setonboardNewMetric: any;
    onboardNewMetric: any;
    setViewMetricDataSourceEnabled: any;
    setViewMetricDataSourceDisabled: any;
    setAddMetricDataSourceEnabled: any;
    setAddMetricDataSourceDisabled: any;
    ViewMetricDataSourceOpen: boolean;
    AddMetricDataSourceOpen: boolean;
};

const WeeklySelfServeData = React.memo<WeeklySelfServeProps>(({
    LoginuserId,
    setloadingtrue,
    setloadingfalse,
    serviceGroups,
    selectedServicegroupval,
    services,
    selectedServiceval,
    handleServiceGroupChange,
    handleServicesChange,
    metricGroups,
    selectedMetricgroupval,
    handleMetricGroupChange,
    activeMetrics,
    selectedMetric,
    handleMetricChange,
    environments,
    selectedEnvironment,
    handleEnvironmentChange,
    datasource,
    authenticationTypes,
    selectedDatasource,
    handledatasourceChange,
    metricDataSourceDetails,
    metricDataSourceInactive,
    icmMetricDataSourceInactive,
    serverName,
    newMetricName,
    newMetricGroupName,
    database,
    mdmMetricName,
    authenticationType,
    serverNameUpdate,
    addNewMetric,
    addNewMetricGroup,
    databaseUpdate,
    mdmMetricNameUpdate,
    authenticationTypeUpdate,
    target,
    notes,
    queryOrUrl,
    UpdatequeryOrUrl,
    Updatetarget,
    Updatenotes,
    addMetricDataSourceData,
    clearMetricDataSourceData,
    targetTypes,
    EditMetricDataSourceRow,
    hideWeeklySelfServeData,
    setonboardNewMetric,
    onboardNewMetric,
    setViewMetricDataSourceEnabled,
    setViewMetricDataSourceDisabled,
    setAddMetricDataSourceEnabled,
    setAddMetricDataSourceDisabled,
    ViewMetricDataSourceOpen,
    AddMetricDataSourceOpen
}) => {
    const toastIdRef = useRef(null);
    const [selfServe, setselfServe] = useState('Weekly');
    const [selectedmetricdatasource, setselectedmetricdatasource] = useState(null);
    const [confirmDialog, setconfirmDialog] = useState(null);
    const [rowData, setrowData] = useState(null);
    const [filteredTargets, setfilteredTargets] = useState([]);
    const [filteredAuths, setfilteredAuths] = useState([]);
    const [metricDataSourceAddorUpdatetext, setmetricDataSourceAddorUpdatetext] = useState("ADD");
    const [isAddGroup, setisAddGroup] = useState(false);
    const [isAddMetricName, setisAddMetricName] = useState(false);

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div style={{ verticalAlign: 'center' }}>
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editMetricDataSource(rowData)} /> &nbsp;
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteMetricDataSource(rowData)} />
                </div>
            </>
        );
    };

    const deleteMetricDataSourceFooter = (
        <>
            <div style={{ float: 'right' }}>
                <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => metricDataSourceInactiveFunc()} />
                <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteMetricDataSourceDialog()} />
            </div>
        </>
    );

    const editMetricDataSource = (row) => {
        setrowData(row);
        setAddMetricDataSourceEnabled();
        setViewMetricDataSourceDisabled();
        setmetricDataSourceAddorUpdatetext("UPDATE");
        EditMetricDataSourceRow(row);
    }

    const KustoAuthtypes = authenticationTypes;
    //const KustoAuthtypes = [
    //    { name: 'AAD AppId', value: 'AAD AppId' }
    //    //Commented out the below code as only AAD AppId is Configured as of now WeeklYSelfServe 
    //    //,{ name: 'DSTS Blackforest', value: 'Dsts Blackforest' },
    //    //{ name: 'DSTS Fairfax', value: 'Dsts Fairfax' },
    //    //{ name: 'DSTS Mooncake', value: 'Dsts Mooncake' },
    //    //{ name: 'UserId', value: 'UserId' },
    //    //{ name: 'Certificate', value: 'Certificate' }
    //];

    const searchTarget = (event: { query: string }) => {
        setTimeout(() => {
            let filterTargets;
            if (!event.query.trim().length) {
                filterTargets = [...targetTypes];
            }
            else {
                filterTargets = targetTypes.filter((target) => {
                    return target.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setfilteredTargets(filterTargets);
        }, 1000);
    }

    const searchAuthtype = (event: { query: string }) => {
  
        setTimeout(() => {
            let filterAuths = KustoAuthtypes;
            setfilteredAuths(filterAuths);
        //    let filterAuths;
        //    if (!event.query.trim().length) {
        //        filterAuths = KustoAuthtypes;
        //    }
        //    else {
        //        filterAuths = KustoAuthtypes.filter((auth) => {
        //            return auth.name.toLowerCase().startsWith(event.query.toLowerCase());
        //        });
        //    }

        //    setfilteredAuths(filterAuths);
        }, 250);
    }

    const confirmDeleteMetricDataSource = (row) => {
        setrowData(row);
        setconfirmDialog(true);
    }

    const closeMetricDataSourceData = () => {
        setonboardNewMetric(false);
        setAddMetricDataSourceDisabled();
        setViewMetricDataSourceEnabled();
        clearMetricDataSourceData();
    }

    const saveMetricDataSourceData = (data) => {
        var datasourceelement = metricDataSourceDetails.filter(item => item.metricId === parseInt(selectedMetric));
        var targetelement = targetTypes.filter(item => item.name === target);
        var authenticationTypeelement = KustoAuthtypes.filter(item => item.value === authenticationType);
        if (isAddGroup && (newMetricGroupName === null || newMetricGroupName === "")) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Metric Group Name cannot be null. Please enter the Metric Group',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        else if (isAddMetricName && (selectedMetricgroupval === null || selectedMetricgroupval === "")) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Metric Group Name cannot be null. Please enter the Metric Group',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        else if (serverName === null || serverName === "") {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Server Name cannot be null. Please enter the Server Name',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        else if (authenticationType === null || authenticationType === "") {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Authetication Type cannot be null. Please enter the Authetication Type',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        /*Commented to allow all authentication Types*/
        //else if (typeof authenticationType !== "object" && authenticationTypeelement.length === 0) {
        //    toastIdRef.current = toast.error(
        //        renderToastifyMsg('Weekly Self Serve is Configured only for AAD APP Id Authentication',
        //            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
        //    );
        //}
        else if (database === null || database === "") {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Database cannot be null. Please Enter the Database Details',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }      
        else if (queryOrUrl === null || queryOrUrl === "") {
            toastIdRef.current = toast.error(
                renderToastifyMsg('QueryOrUrl cannot be null. Please enter the QueryOrUrl Details',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        else if (target === null || target === "") {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Target cannot be null. Please enter the Target Details',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        else if (typeof target !== "object" && targetelement.length === 0) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('New Target Cannot be Added, Please select the existing value from Dropdown',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        else {
            if (metricDataSourceAddorUpdatetext === "ADD") {
                if (selectedServiceval === null) {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg('Service cannot be null. Please select the Service',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
                else if (selectedMetricgroupval === null) {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg('Metric Group cannot be null. Please select the Metric Group',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
                //This validation needs to be updated for the new Metric Group Creation
                //else if (selectedMetricgroupval !== null && selectedMetricgroupval !== 1 && selectedMetricgroupval !== 0) {
                //    toastIdRef.current = toast.error(
                //        renderToastifyMsg('Weekly Self serve is configured only for Availability Metric Group.',
                //            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                //    );
                //}
                else if (selectedMetric === null) {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg('Metric Name cannot be null. Please select the Metric Name',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
                else if (selectedMetric !== null && datasourceelement.length !== 0) {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg('Metric has been already onboarded for the selected service. Either edit existing metric or Add new metric',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
                else if (selectedEnvironment === null || selectedEnvironment === "") {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg('Environment cannot be null. Please select the Environment',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
                else if (selectedDatasource === null) {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg('DataSource cannot be null. Please select the DataSource',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
                else if (selectedDatasource !== null && selectedDatasource !== 1) {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg('Weekly Self serve is configured only for Kusto Data source.',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
                else if (selectedDatasource === null) {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg('DataSource cannot be null. Please select the DataSource',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
                else {
                    addMetricDataSourceData(data, metricDataSourceAddorUpdatetext);
                    setisAddMetricName(false);
                    setisAddGroup(false);
                }
            }
            else {
                addMetricDataSourceData(data, metricDataSourceAddorUpdatetext);
                setisAddMetricName(false);
                setisAddGroup(false);
            }
        }
    }

    const metricDataSourceInactiveFunc = () => {
        if (rowData.metricGroupId == 2 || rowData.metricGroupId == 3) {
            icmMetricDataSourceInactive(rowData);
        }
        else {
            metricDataSourceInactive(rowData);
        }
        setconfirmDialog(false);
    }

    const hideDeleteMetricDataSourceDialog = () => {
        setconfirmDialog(false);
    }

    const handleMetricGroupChangeNew = (value) => {
        if (value == "New") {
            selectedMetricgroupval = value;
            setAddMetricDataSourceDisabled();
            setViewMetricDataSourceEnabled();
            handleMetricGroupChange(value);
            handleMetricChangeNew(value);
            setisAddGroup(true);
        }
        else {
            selectedMetricgroupval = value;
            setonboardNewMetric(false);
            setAddMetricDataSourceDisabled();
            setViewMetricDataSourceEnabled();
            handleMetricGroupChange(value);
            setisAddGroup(false);
            setisAddMetricName(false);
        }
    }
    const handleMetricChangeNew = (value) => {
        if (value == "New") {
            newMetricOnboard(true);
            selectedMetric = "Add New Metric";
            setisAddMetricName(true);
        }
        else {
            setisAddMetricName(false);
        }
        handleMetricChange(value);
    }

    const handleServiceGroupChangeNew = (value) => {
        handleServiceGroupChange(value);
    }

    const handleServicesChangeNew = (value) => {
        setAddMetricDataSourceDisabled();
        setViewMetricDataSourceEnabled();
        handleServicesChange(value);
    }

    const newMetricOnboard = (value) => {
        setonboardNewMetric(value);
        if (value === true) {
            setAddMetricDataSourceEnabled(); //Add new section enabling
            setViewMetricDataSourceDisabled(); //Metric data source displayed based on Megric group selection
            setmetricDataSourceAddorUpdatetext("ADD"); //Metric source Add/Update button text change
            clearMetricDataSourceData(); //Clearig the Metric Data Source
        }
        else {
            setAddMetricDataSourceDisabled();
            setViewMetricDataSourceEnabled();
            clearMetricDataSourceData();
            setisAddGroup(false);
            setisAddMetricName(false);
        }

    }
    const setselfServeVal = (value) => {
        setselfServe(value);
        if (value === 'Daily') {
            if (selectedServiceval !== null) {
                // Logic to Navigate to Daily Self Serve Config
                window.location.href = "/ServiceHealth?serviceid=" + selectedServiceval + "&name=selfserveconfig";
                setloadingtrue();
            }
            else {
                toastIdRef.current = toast.error(
                    renderToastifyMsg('Service Name cannot be null. Please select the Service to Navigate to Daily Self Serve Feature',
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
                setselfServe('Weekly');
            }
        }
    }

    return (
        <div>
            <div style={{ width: '100%' }}>
                <br />
                <div>
                    <Row>
                        <Col lg='10'>
                            Self-Serve Feature: &nbsp;&nbsp;
                            <RadioButton value="Weekly" name="weekly" onChange={(e) => setselfServeVal(e.value)} checked={selfServe === 'Weekly'} />&nbsp; Weekly&nbsp;
                            <RadioButton value="Daily" name="daily" onChange={(e) => setselfServeVal(e.value)} checked={selfServe === 'Daily'} />&nbsp; Daily&nbsp;
                        </Col>
                        <Col lg='2'>
                            <Button label="Back to Weekly" className="bckweeklybtn" onClick={() => hideWeeklySelfServeData()} />
                        </Col>
                    </Row>
                </div>
                <div>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='2'>
                            </Col>
                            <Col lg='8' style={{ textAlign: 'center' }}>
                                <h4><b>Weekly Self Serve Configuration </b></h4>
                            </Col>
                            <Col lg='2'>
                            </Col>
                        </Row>
                        <br />
                        <Row className="alert alert-info">
                            <Col lg="12">
                                <div>
                                    Add our <b>AME Tenant</b> App ID&nbsp;
                                    <b>"12a0025a-c621-428f-87bd-fc2e5214cced"</b>
                                    to your Public Kusto Cluster with viewers permission, before creating a new view.
                                    For National Clouds, please provide
                                    <b>"Data Viewer Role DSTS Application"</b>&nbsp;access to our dSTS application&nbsp;
                                    <i>
                                        <b>
                                            Mooncake : ID_Fundamentals_Dashboard.chinacloudapi.cn &nbsp;
                                            Fairfax : ID_Fundamentals_Dashboard.usgovcloudapi.net
                                        </b>
                                        <br />
                                    </i>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <br />
                    <div className="card">
                        <div className="p-fluid p-grid">
                            <Row>
                                <Col lg='4'>
                                    <div className="p-field p-col-10 p-md-4">
                                        <span className="p-float-label">
                                            <Dropdown id={'servicegroup'} value={selectedServicegroupval} options={serviceGroups} onChange={(e) => handleServiceGroupChangeNew(e.value)} optionLabel="name" />
                                            <label htmlFor="servicegroup" className="fontcolor">Service Group</label>
                                        </span>
                                    </div>
                                </Col>
                                <Col lg='4'>
                                    <div className="p-field p-col-10 p-md-4">
                                        <span className="p-float-label">
                                            <Dropdown id={'service'} className="searchstyleselfserve" value={selectedServiceval} filter filterBy="name" options={services} onChange={(e) => handleServicesChangeNew(e.value)} optionLabel="name" />
                                            <label htmlFor="service" className="fontcolor">Service Name</label>
                                        </span>
                                    </div>
                                </Col>
                                <Col lg='4'>
                                    <div className="p-field p-col-10 p-md-4">
                                        <div className="divAddMetric">
                                            &emsp;
                                            <Checkbox id="newMetric" onChange={e => newMetricOnboard(e.checked)} checked={onboardNewMetric}></Checkbox>
                                            <label htmlFor="newMetric" className="metricnew fontcolor">&nbsp;&nbsp;Onboarding New Metric</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg='4'>
                                    <div className="p-field p-col-10 p-md-4">
                                        <span className="p-float-label">
                                            <Dropdown id={'metricgroup'} value={selectedMetricgroupval} options={metricGroups} onChange={(e) => handleMetricGroupChangeNew(e.value)} optionLabel="name" />
                                            <label htmlFor="metricgroup" className="fontcolor">Metric Group Name</label>
                                        </span>
                                    </div>
                                </Col>
                                <Col lg='4'>
                                    <div className="p-field p-col-10 p-md-4">
                                        <span className="p-float-label">
                                            <Dropdown id={'metric'} className="searchstyleselfserve" value={selectedMetric} filter filterBy="name" options={activeMetrics} onChange={(e) => handleMetricChangeNew(e.value)} optionLabel="name" />
                                            <label htmlFor="metric" className="fontcolor">Metric Name</label>
                                        </span>
                                    </div>
                                </Col>
                                <Col lg='4'>
                                    <div className="p-field p-col-4 p-md-4" style={{ width: '200px', float: 'left' }}>
                                        <span className="p-float-label">
                                            <Dropdown id={'environment'} value={selectedEnvironment} showClear options={environments} onChange={(e) => handleEnvironmentChange(e.value)} optionLabel="name" />
                                            <label htmlFor="environment" className="fontcolor">Environment</label>
                                        </span>
                                    </div>
                                    <div className="p-field p-col-4 p-md-4" style={{ width: '200px', float: 'left' }}>
                                        <span className="p-float-label">
                                            <Dropdown id={'datasource'} value={selectedDatasource} options={datasource} onChange={(e) => handledatasourceChange(e.value)} optionLabel="name" />
                                            <label htmlFor="datasource" className="fontcolor">Data Source</label>
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <br />
                    {
                        !(isNullOrUndefined(selectedServiceval)) ?
                            isArrayWithLength(metricDataSourceDetails) ?
                                <React.Fragment>
                                    {(ViewMetricDataSourceOpen === true) ?
                                        <div className="card addmetricdatasource">
                                            <div className="p-fluid p-grid">
                                                <div className="card">
                                                    <DataTable value={metricDataSourceDetails} selection={selectedmetricdatasource} onSelectionChange={(e) => setselectedmetricdatasource(e.value)}
                                                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Metrics">
                                                        <Column field="metricGroupDisplayName" header="Metric Group" sortable headerStyle={{ width: '9rem' }}></Column>
                                                        <Column field="metricDisplayName" header="Metric Name" sortable headerStyle={{ width: '9rem' }}></Column>
                                                        <Column field="environmentName" header="Environment" sortable headerStyle={{ width: '9rem' }}></Column>
                                                        <Column field="dataSourceName" header="DataSource" sortable headerStyle={{ width: '9rem' }}></Column>
                                                        <Column field="kustoClusterName" header="Server Name" sortable headerStyle={{ width: '9rem' }}></Column>
                                                        <Column field="kustoDbName" header="Database" sortable headerStyle={{ width: '8rem' }}></Column>
                                                        <Column field="queryOrUrl" header="QueryOrUrl" sortable headerStyle={{ width: '10rem' }}></Column>
                                                        <Column field="notes" header="Notes" headerStyle={{ width: '6rem' }} sortable></Column>
                                                        <Column field="updatedBy" header="Updated By" sortable headerStyle={{ width: '9rem' }}></Column>
                                                        <Column field="updateOn" header="Updated On" sortable headerStyle={{ width: '9rem' }}></Column>
                                                        <Column className="Colval" body={actionBodyTemplate} headerStyle={{ width: '7rem' }}></Column>
                                                    </DataTable>
                                                    <Dialog visible={confirmDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteMetricDataSourceFooter} onHide={hideDeleteMetricDataSourceDialog}>
                                                        <div className="confirmation-content">
                                                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                                            <span>Are you sure you want to delete {'?'}</span>
                                                        </div>
                                                    </Dialog>

                                                </div>
                                            </div>
                                        </div>
                                        : <React.Fragment></React.Fragment>
                                    }
                                </React.Fragment>
                                :
                                <React.Fragment>
                                </React.Fragment>

                            : <React.Fragment></React.Fragment>
                    }
                    <br />
                    {AddMetricDataSourceOpen === true ?
                        <div>
                            {
                                (isAddGroup || isAddMetricName) ?
                                    <div>
                                        <div className="card addmetricdatasource">
                                            <div className="p-fluid p-grid">
                                                <div>
                                                    <Row style={{ paddingLeft: '15px' }}>
                                                        <div style={{ display: isAddGroup ? "block" : "none", width: '350px' }} className="p-field p-col-12 p-md-4">
                                                            <span className="p-float-label" style={{ paddingTop: '5px' }}>
                                                                <InputText id="newMetricGroupName" value={newMetricGroupName} onChange={(e) => addNewMetricGroup(e)} />
                                                                <label htmlFor="newMetricGroupName" className="fontcolor">New Metric Group<span className="spnmandatoryIcons">*</span></label>
                                                            </span>
                                                        </div>
                                                        <div className="p-field p-col-12 p-md-4" style={{ width: '350px', display: isAddMetricName ? "block" : "none" }}>
                                                            <span className="p-float-label" style={{ paddingTop: '5px' }}>
                                                                <InputText id="newMetricName" value={newMetricName} onChange={(e) => addNewMetric(e)} />
                                                                <label htmlFor="newMetricName" className="fontcolor">New Metric Name<span className="spnmandatoryIcons">*</span></label>
                                                            </span>
                                                        </div>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <br />
                                    </div>
                                    :
                                    <div>
                                    </div>
                            }

                            <div className="card addmetricdatasource">
                                <div className="p-fluid p-grid">
                                    <div>
                                        <Row style={{ paddingLeft: '15px' }}>
                                            <div className="p-field p-col-12 p-md-4" style={{ width: '350px' }}>
                                                <span className="p-float-label" style={{ paddingTop: '5px' }}>
                                                    <InputText id="serverName" value={serverName ? serverName : ""} onChange={(e) => serverNameUpdate(e)} />
                                                    {selectedDatasource == 6 ?
                                                        <label htmlFor="serverName" className="fontcolor">Monitoring Account<span className="spnmandatoryIcons">*</span></label>
                                                        : <label htmlFor="serverName" className="fontcolor">Server Name<span className="spnmandatoryIcons">*</span></label>
                                                    }
                                                </span>
                                            </div>
                                            {selectedDatasource != 6 ?
                                                <div className="p-field p-col-12 p-md-4 " style={{ width: '350px' }}>
                                                    <span className="p-float-label" style={{ paddingTop: '5px' }}>
                                                        <Dropdown id={'authenticationType'} value={authenticationType} options={KustoAuthtypes} onChange={(e) => authenticationTypeUpdate(e.value)} optionLabel="name" />
                                                        {/*<AutoComplete dropdown id={'authenticationType'} field="name" value={authenticationType} suggestions={filteredAuths}*/}
                                                        {/*    completeMethod={searchAuthtype} onChange={(e) => authenticationTypeUpdate(e.value)} />*/}
                                                        <label htmlFor="authenticationType" className="fontcolor">Authentication Type<span className="spnmandatoryIcons">*</span></label>
                                                    </span>
                                                </div>
                                                : <div className="p-field p-col-12 p-md-4 " style={{ width: '313px' }}>
                                                    <span className="p-float-label" style={{ paddingTop: '5px' }}>
                                                        <InputText id="mdmMetricName" value={mdmMetricName ? mdmMetricName : ""} onChange={(e) => mdmMetricNameUpdate(e)} />                                                       
                                                        <label htmlFor="mdmMetricName" className="fontcolor"> MDM Metric Name<span className="spnmandatoryIcons">*</span></label>                                                                                                                    
                                                    </span>
                                                </div>
                                            }
                                            <div className="p-field p-col-12 p-md-4 " style={{ width: '313px' }}>
                                                <span className="p-float-label" style={{ paddingTop: '5px' }}>
                                                    <InputText id="database" value={database ? database : ""} onChange={(e) => databaseUpdate(e)} />
                                                    {selectedDatasource == 6 ?
                                                        <label htmlFor="database" className="fontcolor"> Metric Namespace<span className="spnmandatoryIcons">*</span></label>
                                                        : <label htmlFor="database" className="fontcolor">Database<span className="spnmandatoryIcons">*</span></label>
                                                    }
                                                </span>
                                            </div>
                                        </Row>
                                        <Row>
                                            <Col lg='8'>
                                                <Row>
                                                    <div className="p-field p-col-10 p-md-4 field">
                                                        {selectedDatasource != 6 ?
                                                            <label className="fontcolor">QueryOrUrl<span className="spnmandatoryIcons">*</span></label>
                                                            : <label className="fontcolor">Dimensions<span className="spnmandatoryIcons">*</span></label>
                                                        }
                                                        <InputTextarea rows={5} cols={120} value={queryOrUrl} onChange={(event) => UpdatequeryOrUrl(event)} />
                                                        <label className="fontcolor">Notes</label>
                                                        <InputTextarea rows={5} cols={120} value={notes} onChange={(event) => Updatenotes(event)} />
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col lg='2'>
                                                <div style={{ height: '33px' }}></div>
                                                <div className="p-field p-col-10 p-md-4">
                                                    <span className="p-float-label" style={{ paddingTop: '5px' }}>
                                                        <AutoComplete dropdown id={'target'} field="name" value={target} suggestions={filteredTargets}
                                                            completeMethod={searchTarget} onChange={(e) => Updatetarget(e.value)} />
                                                        <label htmlFor="target" className="fontcolor">Target<span className="spnmandatoryIcons">*</span></label>
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col lg='2'>
                                                <br />
                                                <br />
                                                <Row>
                                                    <Button label={metricDataSourceAddorUpdatetext} className="metricaddbtn" onClick={() => saveMetricDataSourceData(rowData)} />
                                                </Row>
                                                <br />
                                                <Row>
                                                    <Button label="CLEAR" className="metricclearbtn" onClick={() => clearMetricDataSourceData()} />
                                                </Row>
                                                <br />
                                                <Row>
                                                    <Button label="CLOSE" className="metricclosebtn" onClick={() => closeMetricDataSourceData()} />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <React.Fragment></React.Fragment>
                    }
                </div>
            </div>
        </div>
    );
});

WeeklySelfServeData.displayName = 'WeeklySelfServeData';

export default WeeklySelfServeData;