import { BaseService } from './base.service';

/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class InternalEscalationService extends BaseService {
    private static _serviceGroupService: InternalEscalationService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): InternalEscalationService {
        return (this._serviceGroupService || (this._serviceGroupService = new this('InternalEscalation')));
    }

    public async GetRequestAssistanceFilterClassificationLifeCycle(): Promise<any> {
        const { data } = await this.$http.get<any>("GetRequestAssistanceFilterClassificationLifeCycle");
        return data;
    }

    public async GetOnCallContactList(PropInternalEscalation: any): Promise<any> {
        const { data } = await this.$http.post<any>("GetOnCallContactList", PropInternalEscalation);
        return data;
    }

    public async GetRbAlerts(): Promise<any> {
        const { data } = await this.$http.get<any>("GetRbAlerts");
        return data;
    }
}

export const InternalEscalationAPI = InternalEscalationService.Instance;
