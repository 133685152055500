import { BaseService } from './base.service';

/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class FutureOnCallService extends BaseService {
    private static _serviceGroupService: FutureOnCallService;

    private constructor(controllerName: string) {
        super(controllerName);
    }
    public static get Instance(): FutureOnCallService {
        return (this._serviceGroupService || (this._serviceGroupService = new this('FutureOnCall')));
    }    
    public async GetTenantTeamNameList(): Promise<any> {
        const { data } = await this.$http.get<any>("GetTenantTeamNameList");
        return data;
    }
    public async GetCloudList(): Promise<any> {
        const { data } = await this.$http.get<any>("GetCloudList");
        return data;
    }
    public async GetServiceLifeCycleList(): Promise<any> {
        const { data } = await this.$http.get<any>("GetServiceLifeCycleList");
        return data;
    }
    public async GetFutureOnCall(StartDate: string, EndDate: string, TenantName: string, TeamName: string, CloudName: string, ServiceLifeCycle: string): Promise<any> {
        const { data } = await this.$http.get<any>(`GetFutureOnCall?startDate=${StartDate}&endDate=${EndDate}&tenantName=${TenantName}&teamName=${TeamName}&cloudName=${CloudName}&serviceLifeCycle=${ServiceLifeCycle}`);
        return data;
    }

}

export const FutureOnCallAPI = FutureOnCallService.Instance;
