import React, { useState, Fragment, useRef, useEffect } from 'react';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import { IPropOnCallDashboardTemplate,IDropdownOptions } from '../../../store/checklistTemplate';
import {
    Card, Row, Col, Container, CardBody, CardTitle
} from 'reactstrap';
import { Modal, Button } from 'react-bootstrap'
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faPencilAlt, faTrash, faClosedCaptioning, faTimes, faArchive} from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import {faChevronDown, faChevronUp, faQuestionCircle, faUnlock, faLock } from '@fortawesome/free-solid-svg-icons'
import { Accordion } from 'react-bootstrap';
import { OrderList } from 'primereact/orderlist';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { ReduxAction, IAppThunkAction } from '../../../store';

type ChecklistTemplateTableProps = {
    checklisttemplatedata: IPropOnCallDashboardTemplate[];
    OpentemplatePopup: any;
    showtemplatePopup: boolean;
    hidetemplatePopup: any;
    template: any;
    templatedatachange: any;
    AddQuestionstoTemplate: any;
    ChecklistTemplateEdit: any;
    MoveChecklistTemplateToArchive: any;
    questioncontroltypeoptions: any;
    sourcetypes: any;
    AuthenticationTypes: any;
    question: any;
    handlequestionChange: any;
    disableaddbuttonforquestion: boolean;
    ShowQuestionList: boolean;
    SaveQuestionnarie: any;
    showquestionlist: any;
    QuestionarrieList: any;
    ChecklistquestionEdit: any;
    questionlistvalueschange: any;
    istemplatesavebuttondisabled: any;
    SaveOnCallDashboardData: any;
    checkURL: any;
    templateTypes: any;
    UpdateQuestionaireDisplayOrder: any;
    isAdmin: any;
    LoginuserId: any;
    EditLockforChecklistTemplate: any;
    templateNameList: any;
    copiedTemplateQues: any;
    copyTempQuestiondrpChange: (copiedTemplateQues: IDropdownOptions) => IAppThunkAction<ReduxAction>;
    copyTempQuestions: any;
    isCopyTempQuesCheckVal: any;
    isCopyTemplateQuestion: any;
    copyTempQuesSelect: boolean;
    dynamicValidationTypes: any;
    templateRoles: any;
};
const ChecklistTemplateTable = React.memo<ChecklistTemplateTableProps>(({
    checklisttemplatedata,
    OpentemplatePopup,
    showtemplatePopup,
    hidetemplatePopup,
    template,
    templatedatachange,
    copyTempQuestiondrpChange,
    AddQuestionstoTemplate,
    ChecklistTemplateEdit,
    MoveChecklistTemplateToArchive,
    questioncontroltypeoptions,
    sourcetypes,
    AuthenticationTypes,
    question,
    handlequestionChange,
    disableaddbuttonforquestion,
    ShowQuestionList,
    SaveQuestionnarie,
    showquestionlist,
    QuestionarrieList,
    ChecklistquestionEdit,
    questionlistvalueschange,
    istemplatesavebuttondisabled,
    SaveOnCallDashboardData,
    checkURL,
    templateTypes,
    UpdateQuestionaireDisplayOrder,
    isAdmin,
    LoginuserId,
    EditLockforChecklistTemplate,
    templateNameList,
    copiedTemplateQues,
    copyTempQuestions,
    isCopyTempQuesCheckVal,
    isCopyTemplateQuestion,
    copyTempQuesSelect,
    dynamicValidationTypes,
    templateRoles
}) => {
    useEffect(() => {
        dt.current.filter(selectedisActiveTemplate, 'isActive', 'equals');
    }, [checklisttemplatedata]);
    const count = 1;
    const [questiontoggle, setquestiontoggle] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [createdDate, setCreatedDate] = useState(null);
    const [activeStatus, setActiveStatus] = useState(null);
    const ChecklistGuideURL = "https://identitydivision.visualstudio.com/IdentityWiki/_wiki/wikis/IdentityWiki.wiki/16549/Checklist-UserGuide";
    const dt = useRef(null);
    const setGlobalFilterValue = (event) => {
        setGlobalFilter(event.target.value);
    }
    //Pop out URL in new tab; for jarvis, kusto and data URLs
    const openUrlInNewTab = (url) => {
        if (url !== '') {
            window.open(url, "_blank");
        }
    }

    const setquestiontoggledata = (flag) => {
        setquestiontoggle(flag);

    }

    const templateStatus = [{ name: 'Yes', value: 'No' },
                            { name: 'No', value: 'No' }];

    const UpdateTemplateBodyTemplate = (rowData) => {
        let template = {
            'templateID': rowData.templateId, 'templateName': rowData.templateName, 'templateDescription': rowData.templateDescription, 'templateRole': rowData.templateRole,
            'templateNotes': rowData.templateNotes, 'templateType': rowData.templateType, 'isLocked': rowData.isLocked, CheckListCount: rowData.checklistCount, IsActive: rowData.isActive, createdBy: rowData.createdBy
        };
        return (
            <React.Fragment>
                <FontAwesomeIcon cursor='pointer' title='Click to Edit the record'  icon={faPencilAlt} onClick={e => ChecklistTemplateEdit(template)} />&nbsp;&nbsp;
                {!rowData.isActive && <FontAwesomeIcon title='Click to Archive the record' className="redcolorIcons Iconsstyles" cursor='pointer' icon={faArchive} onClick={e => deleteArchiveConfirm(rowData.templateId)} />}
            </React.Fragment>
        );
    }    

    const displayLastUpdatedDate = (rowData) => {
        return (
            <div>{(rowData.lastUpdatedDate === null) ? '' : moment(rowData.lastUpdatedDate).format('YYYY-MM-DD hh:mm A')}</div>
        );
    }

    const displayCreatedDate = (rowData) => {
        return (
            <div>{(rowData.createdDate === null) ? '' : moment(rowData.createdDate).format('YYYY-MM-DD hh:mm A')}</div>
        );
    }

    const statusItemTemplate = (option) =>{
        return <span>{option}</span>;
    }


    const displayIsActive = (rowData) => {
        return (
            <div>{rowData.isActive == true ? 'True' : 'False'}</div>
        );
    } 

    const [selectedisActiveTemplate, setSelectedisActiveTemplate] = useState('True');
    const [archiveDialog, setArchiveDialog] = useState(false);
    const [selectedCheckListTemplateToArchive, setCheckListTemplateToArchive] = useState(null);

    const isActiveOptions = [
        'True', 'False'
    ];

    const onisActiveFilterChange = (event) => {
        dt.current.filter(event.value, 'isActive', 'equals');
        setSelectedisActiveTemplate(event.value);
    }


    const isActiveFilterElements = () => {
        return (
            <Dropdown value={selectedisActiveTemplate} options={isActiveOptions} itemTemplate={isActiveOptionTemplate} className="p-column-filter"
                onChange={onisActiveFilterChange} placeholder="Select a isActive" />
        );
    }
    const isActiveOptionTemplate = (option) => {
        return (
            <span>{option}</span>
        );
    }
    const isActiveFilterElement = isActiveFilterElements();

    const hideArchiveDialog = () => {
        setCheckListTemplateToArchive(null);
        setArchiveDialog(false);
    }
    const deleteArchiveConfirm = (TemplateID) => {
        setCheckListTemplateToArchive(TemplateID);
        setArchiveDialog(true);
    }
    const deleteArchiveConfirmed = () => {
        MoveChecklistTemplateToArchive(selectedCheckListTemplateToArchive);
        setArchiveDialog(false);
    }

    const archiveDialogFooter = (
        <React.Fragment>
            <span style={{ display: 'flex', justifyContent: 'center' }}>
                <Button className="p-button-text btn-primary" onClick={() => deleteArchiveConfirmed()}>Yes</Button>
                <Button className="p-button-text btn-primary" onClick={() => hideArchiveDialog()} >No</Button>                
            </span>
        </React.Fragment>
    );

    const ValidateControlType = (templateType, question) => {
        let Index = question.Questionindex;
        let QuestionID = question.QuestionID;
        if (templateType === 'Checklist Only' && Index !== -1 && QuestionID !== 0) {
                return true;
        }
        else {
            return false;
        }
    }

    const dateFilter = <Calendar value={createdDate} onChange={(e) => onCreatedDateChange(e)} dateFormat="yy-mm-dd" className="p-column-filter" placeholder="Created Date" />;    
    const statusFilter = <Dropdown value={activeStatus} options={templateStatus} onChange={(e) => onActiveStatusChange(e)} itemTemplate={statusItemTemplate} placeholder="Select a Status" className="p-column-filter"/>;

    const onCreatedDateChange = (e) => {
        dt.current.filter(e.value, 'createdDate', 'custom');
        setCreatedDate(e.value);
        var l = createdDate
    }

    const onActiveStatusChange = (e) => {
        dt.current.filter(e.value, 'isActive', 'equals');
        setActiveStatus(e.value)
    }

    const filterDate = (value, filter) => {
        if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
            return true;
        }

        if (value === undefined || value === null) {
            return false;
        }
        return value === formatDate(filter);
    }

    const formatDate = (date) => {        
        let month = date.getMonth() + 1;
        let day = date.getDate();

        if (month < 10) {
            month = '0' + month;
        }

        if (day < 10) {
            day = '0' + day;
        }

        return date.getFullYear() + '-' + month + '-' + day;
    }


    if (QuestionarrieList.filter(x => x.id === -1).length <= 0) {
        QuestionarrieList.splice(0, 0, { id: -1 });
    }

    // Logic to add id column for Questionnaire List as we are avoiding map in OrderedList Template 
    QuestionarrieList.map((obj, index) => {
        if (obj.id !== -1) {
            obj.id = index;
        }
    });

    const questTempSelect = (tempId) => {
        copyTempQuestiondrpChange(tempId);
        copyTempQuestions(tempId);
    }
    
    const itemTemplate = (item) => {
        
        return (
            <React.Fragment>
                <table className="tblAcktemplate">                   
                    {(item.id === -1) ?
                        <thead>
                            <tr>
                               <th style={{ width: '12%' }} className="textaligncenter">Update</th>
                               <th style={{ width: '76%' }} className="textaligncenter">Question</th>
                               <th style={{ width: '12%' }} className="textaligncenter">IsActive</th>
                            </tr>
                        </thead>
                         :
                        <React.Fragment>
                            <tbody>
                            <tr>
                                    <td style={{ width: '12%' }} className="editiconstyle"><span onClick={e => ChecklistquestionEdit(item, item.id)}><FontAwesomeIcon icon={faPencilAlt} className="ackediticon" /></span></td>
                                    <td style={{ width: '76%' }} className="acktextalignment">{item.Question}
                                        {/*<input  type="text" className="form-control" placeholder="Control Values" value={item.Question} onChange={(e) => questionlistvalueschange(e, item.id)} name="Question" />*/}
                                     </td>
                                    <td style={{ width: '12%' }}className="editiconstyle">
                                    <input type="checkbox" checked={item.IsActive} onChange={(e) => questionlistvalueschange(e, item.id)} name="IsActive" />
                                </td>
                            </tr>
                                <tr></tr>
                            </tbody>
                            </React.Fragment>
                        }
                     </table>                
            </React.Fragment>
        );
    };

    const isCopySelected = (value) => {
        isCopyTempQuesCheckVal(value);
        if (!value) {
            questTempSelect('');
        }
    };
    return (
        <div>
            <Card className="cardstyle">
                <CardBody className="cardbodyhederstyle">
                    <Row>
                        <Col lg="4"></Col>
                        <Col lg="4" style={{ textAlign: 'center' }}>
                            <div>
                                <h3 className="headertext">Manage Templates</h3>
                            </div>
                        </Col>
                        <Col lg="4" style={{ textAlign: 'right' }}>
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" />&nbsp;
                                <InputText style={{ width: '340px', verticalAlign: 'top' }} type="search" placeholder="Global Search" value={globalFilter} onChange={(e) => setGlobalFilterValue(e)} />&ensp;&ensp;&ensp;
                            </span>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <br />
            <Row>
                <Col lg="12">
                    <div className="alignrightitems">
                        <React.Fragment><span className='ConfigGuide' onClick={e => openUrlInNewTab(ChecklistGuideURL)}>
                            CheckList User Guide
                            </span>
                            <FontAwesomeIcon style={{ cursor: 'pointer', fontWeight: 'bolder', fontSize: '15px', verticalAlign: 'top' }} onClick={e => openUrlInNewTab(ChecklistGuideURL)} title='CheckList User Guide' className='icon-format-config' icon={faQuestionCircle} />&ensp;
                        </React.Fragment>
                        <button className="btn btn-primary btn-sm filter-hmbutton btnwidth150" onClick={OpentemplatePopup}><FontAwesomeIcon icon={faPlus} style={{ color: 'white', backgroundColor: 'None', border: 'none' }} /><strong className="txtcolorwhite"> New Template</strong></button>				
                    </div>
                </Col>
            </Row>
            <br />
            <div>
                <DataTable ref={dt} value={checklisttemplatedata} className="tblListDisplay tblborder"
                    globalFilter={globalFilter} emptyMessage="No Data found.">
                    <Column className="thListwidth12" style={{ wordBreak:'break-word' }} field="templateName" sortable header="Template Name" filter filterMatchMode="contains" filterPlaceholder="Search by Template Name" />
                    <Column className="thListwidth20" style={{ wordBreak:'break-word' }} field="templateDescription" sortable header="Template Description" filter filterMatchMode="contains" filterPlaceholder="Search by Description" />
                    <Column className="thListwidth20" style={{ wordBreak: 'break-word' }} field="templateNotes" sortable header="Template Note" filter filterMatchMode="contains" filterPlaceholder="Search by Notes" />
                    <Column className="thListwidth8" field="templateRole" sortable header="Template Role" filter filterMatchMode="contains" filterPlaceholder="Search by Template Role" />
                    <Column className="thListwidth8" field="createdBy" sortable header="Created By" filter filterMatchMode="contains" filterPlaceholder="Search by Created" />
                    <Column className="thListwidth11" field="createdDate" sortable header="Created Date" body={displayCreatedDate} filter filterMatchMode="contains" filterPlaceholder="Search by Created Date" />
                    <Column className="thListwidth8" field="lastUpdatedBy" sortable header="Modified By" filter filterMatchMode="contains" filterPlaceholder="Search by Modified" />
                    <Column className="thListwidth11" field="lastUpdatedDate" sortable header="Modified Date" body={displayLastUpdatedDate} filter filterMatchMode="contains" filterPlaceholder="Search by Modified Date" />
                    <Column className="thListwidthCenter8" field="isActive" sortable header="Is Active" body={displayIsActive} filter filterElement={isActiveFilterElement}/>
                    <Column className="thListEditwidth4" field="templateId" header="Edit" body={UpdateTemplateBodyTemplate} />
                </DataTable>
            </div>
           
            <Modal show={showtemplatePopup} onHide={hidetemplatePopup} size='xl' backdrop='static' id="Ackmodelpopup">
                <Modal.Header closeButton className="commonModalHeader">
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='12' style={{ textAlign: 'center' }}>
                                <h4><b>  Create/Update Template </b></h4>
                            </Col>
                        </Row>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ width: '100%'}}>
                    {
                        !isNullOrUndefined(template)
                            ?
                            <React.Fragment>
                                {(((!isAdmin) && !(template.createdBy === LoginuserId) && (template.isLocked))) ?
                                    <React.Fragment>
                                        <div style={{ textAlign: 'center', paddingBottom: '5px' }}>
                                            <span className="checklistwarntextstyle">This page has been locked by Template Initiator/Admin. So, you cannot perform any Add/Update operations.</span>
                                        </div>
                                    </React.Fragment>
                                    : <React.Fragment></React.Fragment>
                                }
                                <div>
                                    <Row className="rowpaddingbottom">
                                        <Col lg="4">
                                            <span className="mandatorycolor">*</span>&nbsp;<span>Template Name</span>
                                        </Col>
                                        <Col lg="1">
                                            :
                                        </Col>
                                        <Col lg="6">
                                            <input type="text" className="form-control" placeholder="Template Name" value={template.templateName} name="templateName" onChange={(e) => templatedatachange(e)} />
                                        </Col>
                                    </Row>
                                    <Row className="rowpaddingbottom">
                                        <Col lg="4">
                                            <span className="mandatorycolor">*</span>&nbsp;<span>Template Description</span>
                                        </Col>
                                        <Col lg="1">
                                            :
                                        </Col>
                                        <Col lg="6">
                                            <textarea className="form-control" placeholder="Template Description" value={template.templateDescription} onChange={(e) => templatedatachange(e)} rows={2} cols={80} name="templateDescription" />
                                        </Col>
                                    </Row>
                                    <Row className="rowpaddingbottom">
                                        <Col lg="4">
                                            <span className="mandatorycolor">*</span>&nbsp;<span>Template Notes</span>
                                        </Col>
                                        <Col lg="1">
                                            :
                                        </Col>
                                        <Col lg="6">
                                            <textarea className="form-control" placeholder="Template Notes" value={template.templateNotes}  onChange={(e) => templatedatachange(e)} rows={4} cols={80} name="templateNotes" />
                                        </Col>
                                    </Row>
                                    <Row className="rowpaddingbottom">
                                        <Col lg="4">
                                            <span className="mandatorycolor">*</span>&nbsp;<span>Template Type</span>
                                        </Col>
                                        <Col lg="1">
                                            :
                                        </Col>
                                        <Col lg="6">
                                            <span className="p-float-label acklables">
                                                <Dropdown id={'ddlidacktype'} value={template.templateType} options={templateTypes} onChange={(e) => templatedatachange(e)} optionLabel="name" name="templateType" />
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className="rowpaddingbottom">
                                        <Col lg="4">
                                            <span className="mandatorycolor">*</span>&nbsp;<span>Template Role</span>
                                        </Col>
                                        <Col lg="1">
                                            :
                                        </Col>
                                        <Col lg="6">
                                            <span className="p-float-label acklables">
                                                <Dropdown id={'ddlidacktype'} value={template.templateRole} options={templateRoles} onChange={(e) => templatedatachange(e)} optionLabel="name" name="templateRole" />
                                            </span>
                                        </Col>
                                    </Row>
                                    {
                                        (template.templateID == 0)
                                            ?
                                            <Row className="rowpaddingbottom">
                                                <Col lg="4">
                                                    <span className="mandatorycolor"></span>&nbsp;<span>Copy Existing Template Questions</span>
                                                </Col>
                                                <Col lg="1">
                                                    :
                                                </Col>
                                                <Col lg="6">
                                                    <span className="p-float-label acklables">
                                                        <Checkbox id="IsCopyTemplateQuestion" onChange={e => isCopySelected(e.checked)} checked={isCopyTemplateQuestion}></Checkbox>
                                                    </span>
                                                </Col>
                                            </Row>
                                             :
                                            <React.Fragment></React.Fragment>
                                    }
                               
                                    {
                                        (copyTempQuesSelect && template.templateID == 0) ?
                                            <Row className="rowpaddingbottom">
                                                <Col lg="4">
                                                    <span className="mandatorycolor"></span>&nbsp;<span>Choose  Template</span>
                                                </Col>
                                                <Col lg="1">
                                                    :
                                                </Col>
                                                <Col lg="6">
                                                    <span className="p-float-label acklables">
                                                        <Dropdown id={'ddtempNameList'} value={copiedTemplateQues} options={templateNameList} onChange={(e) => questTempSelect(e.value)} optionLabel="name" name="templateNameList" />
                                                    </span>
                                                </Col>
                                            </Row>
                                             :
                                            <React.Fragment></React.Fragment>
                                    }                                 
                                    {
                                        (template.templateID > 0)
                                            ?
                                            <Row className="rowpaddingbottom">
                                                <Col lg="4">
                                                    &nbsp;<span>IsActive</span>
                                                </Col>
                                                <Col lg="1">
                                                    :
                                                </Col>
                                                <Col lg="6">
                                                    <input type="checkbox" checked={template.IsActive} onChange={(e) => templatedatachange(e)} name="IsActive" />

                                                </Col>
                                            </Row>  
                                            :
                                            <React.Fragment></React.Fragment>
                                    }
                                     
                                    <Row className="columnsheader rowpaddingbottom">
                                        <Col lg='4'>
                                            {((isAdmin === true) || (template.createdBy === LoginuserId)) ?
                                                <div style={{ float: 'left', paddingBottom: '5px', paddingTop: '5px' }}>
                                                    <Button className='ChecklistLockbutton' title="Locked By Admin/Template Creator" hidden={!template.isLocked} disabled={(!isAdmin) && !(template.createdBy === LoginuserId)} onClick={EditLockforChecklistTemplate}><FontAwesomeIcon icon={faLock} /></Button>
                                                    <Button className='ChecklistLockbutton' hidden={template.isLocked} disabled={(!isAdmin) && !(template.createdBy === LoginuserId)} onClick={EditLockforChecklistTemplate}><FontAwesomeIcon icon={faUnlock} /></Button>
                                            </div>
                                            : <React.Fragment></React.Fragment>
                                        }
                                        </Col>
                                        <Col lg='8'>
                                            <div className="addnewquestionchecklist" >
                                                <span className="errotextstyle">( should add atleast one question )&nbsp;&nbsp;</span><span title="Add Question" onClick={AddQuestionstoTemplate}> <FontAwesomeIcon icon={faPlus} style={{ backgroundColor: 'None', border: 'none' }} />&nbsp;Add Question</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Accordion defaultActiveKey={count.toString()} className="tempaccordianstyle">
                                        <table className="questiontable">
                                            <tbody>
                                            <tr>
                                                <td style={{ width: '90%' }}>
                                                    <h5 className="addquestiontitle"> {ShowQuestionList ? 'Questionnaire' : question.QuestionID > 0 ?'Update Question':'Add New Question'}</h5>
                                                            </td>
                                                <td style={{ width: '10%' }}>
                                                    <div className="floatright divprimary">
                                                        <Accordion.Toggle as={Button} eventKey={count.toString()} onClick={e => setquestiontoggledata(!questiontoggle)} style={{ background: 'none' }} className="btnaddquestionicon">
                                                            {(questiontoggle) ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />
                                                            }
                                                        </Accordion.Toggle>
                                                    </div>
                                                         
                                                   </td>
                                                </tr>
                                                </tbody>
                                                 </table>
                                                
                                        <Accordion.Collapse eventKey={count.toString()}>
                                            {
                                                (ShowQuestionList)
                                                    ?
                                                    <div className="TemplateQuestionaireTableOuter">
                                                        <OrderList
                                                            value={QuestionarrieList}
                                                            id="ChecklistTemplateQuestionaireTable"
                                                            listStyle={{ height: "auto" }}
                                                            itemTemplate={itemTemplate}
                                                            onChange={(e) => { UpdateQuestionaireDisplayOrder(e.value.filter((x) => { return x.id != -1 }))}}>
                                                        </OrderList>
                                                    </div>
                                                    :
                                                    <Row className="rowpaddingbottom">
                                                        <Col lg="12">
                                                            <br />
                                                            <Row className="rowpaddingbottom">
                                                                <Col lg="4">
                                                                    <span className="mandatorycolor labelpadding">*</span>&nbsp;<span>Question</span>
                                                                </Col>
                                                                <Col lg="1">
                                                                    :
                                                                </Col>
                                                                <Col lg="5">
                                                                    <textarea className="form-control" placeholder="Question" value={question.question} onChange={(e) => handlequestionChange(e)} rows={4} cols={5} name="question" />
                                                                </Col>
                                                            </Row>
                                                            <Row className="rowpaddingbottom">
                                                                <Col lg="4">
                                                                    <span className="mandatorycolor labelpadding">*</span>&nbsp;<span> Control Type</span>
                                                                </Col>
                                                                <Col lg="1">
                                                                    :
                                                                 </Col>
                                                                <Col lg="5">
                                                                    <span className="p-float-label acklables">
                                                                        <Dropdown id={'ddlidacktype'} disabled={ValidateControlType(template.templateType, question)} value={question.ControlType} options={questioncontroltypeoptions} onChange={(e) => handlequestionChange(e)} optionLabel="name" name="ControlType" />
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                            {
                                                                (question.ControlType === 'CheckBox' || question.ControlType === 'Radiobutton' || question.ControlType === 'Dropdown')
                                                                    ?
                                                                    <Row className="rowpaddingbottom">
                                                                        <Col lg="4">
                                                                            <span className="mandatorycolor labelpadding">*</span>&nbsp;<span> Control Values</span>
                                                                            <div className="errotextstyle labelpadding">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Enter Values as Comma seperated)</div>
                                                                        </Col>
                                                                        <Col lg="1">
                                                                            :
                                                                </Col>
                                                                        <Col lg="5">
                                                                            <input type="text" className="form-control" disabled={ValidateControlType(template.templateType, question)} placeholder="Control Values" value={question.controlValue} onChange={(e) => handlequestionChange(e)} name="controlValue" />
                                                                        </Col>
                                                                    </Row>
                                                                    : <React.Fragment></React.Fragment>
                                                            }

                                                            <Row className="rowpaddingbottom">
                                                                <Col lg="4">
                                                                    <span className="mandatorycolor labelpadding">*</span>&nbsp;<span> Source Type</span>
                                                                </Col>
                                                                <Col lg="1">
                                                                    :
                                                        </Col>
                                                                <Col lg="5">
                                                                    <span className="p-float-label acklables">
                                                                        <Dropdown id={'ddlidacktype'} value={question.sourceType} options={sourcetypes} onChange={(e) => handlequestionChange(e)} optionLabel="name" name="sourceType" />
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                            {
                                                                (question.sourceType == 'Kusto')
                                                                    ?
                                                                    <React.Fragment>
                                                                        <Row className="rowpaddingbottom">
                                                                            <Col lg="4">
                                                                                <span className="mandatorycolor labelpadding">*</span>&nbsp;<span> Server Name</span>
                                                                            </Col>
                                                                            <Col lg="1">
                                                                                :
                                                                    </Col>
                                                                            <Col lg="5">
                                                                                <input type="text" className="form-control" placeholder="Server Name" value={question.serverName} onChange={(e) => handlequestionChange(e)} name="serverName" />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="rowpaddingbottom">
                                                                            <Col lg="4">
                                                                                <span className="mandatorycolor labelpadding">*</span>&nbsp;<span> Database</span>
                                                                            </Col>
                                                                            <Col lg="1">
                                                                                :
                                                                    </Col>
                                                                            <Col lg="5">
                                                                                <input type="text" className="form-control" placeholder="Database" value={question.Database} onChange={(e) => handlequestionChange(e)} name="Database" />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="rowpaddingbottom">
                                                                            <Col lg="4">
                                                                                <span className="mandatorycolor labelpadding">*</span>&nbsp;<span>Query</span>
                                                                            </Col>
                                                                            <Col lg="1">
                                                                                :
                                                                    </Col>
                                                                            <Col lg="5">
                                                                                <textarea className="form-control" placeholder="Query" value={question.Query} onChange={(e) => handlequestionChange(e)} rows={5} cols={5} name="Query" />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="rowpaddingbottom">
                                                                            <Col lg="4">
                                                                                <span className="mandatorycolor labelpadding">*</span>&nbsp;<span> Kusto Authentication Type</span>
                                                                            </Col>
                                                                            <Col lg="1">
                                                                                :
                                                                            </Col>
                                                                            <Col lg="5">
                                                                                <span className="p-float-label acklables">
                                                                                    <Dropdown id={'ddlidacktype'} value={question.kustoauthenticationtype} options={AuthenticationTypes} onChange={(e) => handlequestionChange(e)} optionLabel="name" name="kustoauthenticationtype" />
                                                                                </span>
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                    : (question.sourceType == 'URL')
                                                                        ? <React.Fragment>
                                                                            <Row className="rowpaddingbottom">
                                                                                <Col lg="4">
                                                                                    <span className="mandatorycolor labelpadding">*</span>&nbsp;<span> Data URL</span>
                                                                                    <div className="errotextstyle labelpadding">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;( URL must start with 'https://' ) </div>
                                                                                </Col>
                                                                                <Col lg="1">
                                                                                    :
                                                                            </Col>
                                                                                <Col lg="5">
                                                                                    <input type="text" className="form-control" placeholder="Data URL" value={question.dataurl} onBlur={e => checkURL(question.dataurl)} onChange={(e) => handlequestionChange(e)} name="dataurl" />
                                                                                </Col>
                                                                            </Row>
                                                                        </React.Fragment>
                                                                        : <React.Fragment></React.Fragment>
                                                            }
                                                            <br />
                                                            <Row className="rowpaddingbottom">
                                                                <Col lg="4">
                                                                    <span className="mandatorycolor labelpadding">*</span>&nbsp;<span> Dynamic Validation</span>
                                                                </Col>
                                                                <Col lg="1">
                                                                    :
                                                                </Col>
                                                                <Col lg="5">
                                                                    <span className="p-float-label acklables">
                                                                        <Dropdown id={'ddlidacktype'} value={question.dynamicValidation} options={dynamicValidationTypes} onChange={(e) => handlequestionChange(e)} optionLabel="name" name="dynamicValidation" />
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                            <br />
                                                            <Row className="rowpaddingbottom">
                                                                <Col lg='8'></Col>
                                                                <Col lg='3'>
                                                                    <Button className='checkquestionbuttons' onClick={SaveQuestionnarie} disabled={disableaddbuttonforquestion}>{(question.Questionindex!==-1)?'Update':'Add'}</Button>&nbsp;&nbsp;
                                                             <Button className='checkquestionbuttons' onClick={showquestionlist} >Cancel</Button>
                                                                </Col>
                                                                <Col lg='1'></Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>

                                            }
                                            

                                                </Accordion.Collapse>
                                            </Accordion>
                                       
                                    <br/>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <Row>
                                        <Col lg='6' style={{ textAlign: 'right' }}>
                                            <Button className='DailydataModelbuttons' disabled={istemplatesavebuttondisabled || ((!isAdmin) && !(template.createdBy === LoginuserId) && (template.isLocked))} onClick={SaveOnCallDashboardData} >Save</Button>
                                        </Col>
                                        <Col lg='6'>
                                            <Button className='DailydataModelbuttons checklistcloseicon' onClick={hidetemplatePopup} id="cklclose"><FontAwesomeIcon title='Help' icon={faTimes} className="checklistclosefonticon" /> Cancel</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </React.Fragment>
                            : <React.Fragment></React.Fragment>
                    }


                </Modal.Body>
              
            </Modal>
            <Dialog visible={archiveDialog} style={{ width: '450px' }} header="Confirm" modal footer={archiveDialogFooter} onHide={hideArchiveDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', color: 'red' }} />
                    <span>Archiving hides this item permanently.<br></br> Are you sure you want to archive?</span>
                </div>
            </Dialog>
        </div>
    )
});

ChecklistTemplateTable.displayName = 'ChecklistTemplateTable';
export default ChecklistTemplateTable;