import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { RouteComponentProps } from 'react-router-dom';
import { actionCreators, reducer } from '../../store/Security-SHR';
import { SecuritySHRDataTable } from '../Security-SHR/child-components';
import { isNullOrUndefined, isArrayWithLength, renderToastifyMsg } from '../../utils';
import { ToastId, toast } from 'react-toastify';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { SecuritySHRAPI } from '../../api';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap'
import { PublicServiceAnnouncement, SuccessStories } from './child-components';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { DeprecatedFunctionalityBanner } from '../../components';

type SecuritySHRDataProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly username: string }
    & { readonly isAdmin: boolean }
    & RouteComponentProps<{}>;

const SecuritySHRData: React.FC<SecuritySHRDataProps> = ({
    isLoading,
    MeetingDate,
    DefaultDate,
    GetMeetingDate,
    handleMeetingDateChange,
    PublicServiceAnnouncementData,
    setloadingtrue,
    setloadingfalse,
    isAuthenticated,
    isAdmin,
    username,
    MaxDate,
    addUserVisit,
    isLockedSecurity,
    GetSecurityAdminLock,
    EditPubServiceAccRow,
    CancelEditPubServiceAccRow,
    OnPIRPubServiceChangevalues,
    EditLockforPubServiceAcc,
    EnableAddNewRowforPubServiceAcc,
    isPubServiceAccEditorAddEnabled,
    UpdatePubServiceAccDataOrder,
    isPSALockDisabledOnEdit,
    psaaccordiontoggle,
    setpsaccordiontoggledata,
    GetWeeklySecurityData,
    SuccessStoriesData,
    UpdateSuccessStoriesDataOrder,
    isSuccessStoriesEditorAddEnabled,
    EditLockforSuccessStories,
    isSSLockDisabledOnEdit,
    ssaccordiontoggle,
    setssaccordiontoggledata,
    isLockedSuccessStories,
    CancelEditSuccessStoriesRow,
    OnPIRSuccessStoriesChangevalues,
    EditSuccessStoriesRow,
    EnableAddNewRowforSuccessStories
}) => {
    useEffect(() => {
        if (isAuthenticated)
            addUserVisit(0, 'SecuritySHR.tsx_v2');

    }, [isAuthenticated]);
    useEffect(() => {
        GetSecurityAdminLock();
    }, [GetSecurityAdminLock]);
    useEffect(() => {
        if (MeetingDate === null) {
            GetMeetingDate();
        }
        else {
            GetWeeklySecurityData(moment(MeetingDate).format('MM/DD/YYYY'), 4);
        }

    }, [GetMeetingDate, MeetingDate]);
    const toastIdRef = useRef<ToastId>('');
    const [Disablesubmitbutton, setDisablesubmitbutton] = useState(true);

    const EditPubServiceAccRows = (e, value) => {
        let val: number = value.id;
        EditPubServiceAccRow(val);
    }

    //================= function to delete the selected Row for Public Service Announcement =======================//
    const DeletePubServiceAccRow = (e, row) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            setloadingtrue();
            SecuritySHRAPI.DeletePubServiceAccSecuritySHRData(row.id, username)
                .then((result: number) => {
                    if (result > 0) {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg("Public Service Announcement Data for presenter " + row.presenter + " Deleted successfully",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                        GetWeeklySecurityData(moment(MeetingDate).format('MM/DD/YYYY'), 1);
                    }

                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while deleting",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
    }

    //================= function to modify the Meeting Date for Public Service Announcement =======================//
    const handlePubServiceAccDateChange = (row, KPIDate) => {
        setloadingtrue();
        SecuritySHRAPI.saveSecurityPubServiceAccMeetingDate(row.id, moment(KPIDate).format('MM/DD/YYYY'), username)
            .then((result: number) => {
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg("Public Service Announcement Data for Presenter " + row.presenter + " Updated successfully",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    GetWeeklySecurityData(moment(MeetingDate).format('MM/DD/YYYY'), 1);
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while updating the Public Service Announcement Data",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });

    }

    //=============function to Save for Edit/Add   the  values for Public Service Announcement ====================//
    const SavePubServiceAccData = (e, row) => {
        row.userName = username;
        var existdisplayorder = false;
        // Logic for Duplicate DisplayOrder
        if (isAdmin === true && (row.id == 0)) {
            let filteredData = PublicServiceAnnouncementData.filter(function (x) { return x.id !== -1; });
            let highestDisplayOrder = Math.max(...filteredData.map(o => o.displayOrderEdit));
            PublicServiceAnnouncementData.find(x => x.id == 0).displayOrderEdit = highestDisplayOrder + 1;
        }
        row.kpiDate = moment(MeetingDate).format('MM/DD/YYYY');
        if (isNullOrUndefined(row.presenterEdit) || !/\S/.test(row.presenterEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please enter the Presenter Details',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );

        }
        else if (isNullOrUndefined(row.topicNameEdit) || !/\S/.test(row.topicNameEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please enter the Topic Name',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );

        }
        else if (!isNullOrUndefined(row.presentationLinkEdit) && !validateLink(row.presentationLinkEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg("Please enter the valid Presentation Link",
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        else {
            if (!isNullOrUndefined(row.presentationLinkEdit) && row.presentationLinkEdit.toLowerCase().indexOf("http") === -1) {
                row.presentationLinkEdit = "https://" + row.presentationLinkEdit;
            }
            if (isNullOrUndefined(row.displayOrderEdit)) {
                row.displayOrderEdit = null;
            }
            else {
                row.displayOrderEdit = parseInt(row.displayOrderEdit);
            }
            setloadingtrue();
            let message = (row.id === 0) ? "Public Service Announcement Data for Presenter " + row.presenterEdit + " Saved successfully" : "Public Service Announcement Data updated successfully";
            SecuritySHRAPI.AddorUpdateSecurityPubServiceAccData(row)
                .then((result: number) => {
                    if (result != -1) {
                        GetWeeklySecurityData(moment(MeetingDate).format('MM/DD/YYYY'), 1);
                        toastIdRef.current = toast.success(
                            renderToastifyMsg(message,
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                    else {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Error while adding Public Service Announcement Data",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while adding the Public Service Announcement Data",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
    }

    //==================function to Cancel the Edit for Public Service Announcement  Row ==================================//
    const cancelEditPubServiceAccRows = (e, value) => {
        if (window.confirm("Are you sure you want to Cancel this Edit?")) {
            let val: number = value.id;
            CancelEditPubServiceAccRow();
        }
    }

    //=======================function on textbox change while Editing arow in Public Service Announcement==============//
    const PubServiceAccvalueschange = (e, value) => {
        let val: number = value.id;
        OnPIRPubServiceChangevalues(e, val);
    }

    const EditLockforPubServiceAccData = () => {
        if (isAdmin) {
            EditLockforPubServiceAcc();
        }
    }

    //=============== function to enable Add new row for Public Service Announcement ====================================//
    const EnableAddNewforPubServiceAccRow = () => {
        EnableAddNewRowforPubServiceAcc();
    }
    const validateLink = (link) => {
        var valid = false;
        var regex = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
        var m;
        m = regex.exec(link);
        if (m !== null) {
            valid = true;
        }
        return valid;
    };
    //**************************************************************************** function Block for Success Stories ****************************************************************************//
    //================ function to enable the Edit for Success Stories Row =====================//
    const EditSuccessStoriesRows = (e, value) => {
        let val: number = value.id;
        EditSuccessStoriesRow(val);
    }

    //================= function to delete the selected Row for Success Stories =======================//
    const DeleteSuccessStoriesRow = (e, row) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            setloadingtrue();
            SecuritySHRAPI.DeleteSecuritySuccessStorieData(row.id, username)
                .then((result: number) => {
                    if (result > 0) {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg("Success Stories Data for presenter " + row.presenter + " Deleted successfully",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                        GetWeeklySecurityData(moment(MeetingDate).format('MM/DD/YYYY'), 2);
                    }

                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while deleting",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
    }

    //================= function to modify the Meeting Date for Success Stories =======================//
    const handleSuccessStoriesDateChange = (row, KPIDate) => {
        setloadingtrue();
        SecuritySHRAPI.saveSecuritySuccessStoriesMeetingDate(row.id, moment(KPIDate).format('MM/DD/YYYY'), username)
            .then((result: number) => {
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg("Success Stories Data for Presenter " + row.presenter + " Updated successfully",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    GetWeeklySecurityData(moment(MeetingDate).format('MM/DD/YYYY'), 2);
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while updating the Success Stories Data",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });

    }

    //=============function to Save for Edit/Add   the  values for Success Stories ====================//
    const SaveSuccessStoriesData = (e, row) => {
        row.userName = username;
        var existdisplayorder = false;
        // Logic for Duplicate DisplayOrder
        if (isAdmin === true && (row.id == 0)) {
            SuccessStoriesData.map((obj) => {
                if (obj.id !== row.id) {
                    if (obj.displayOrder === parseInt(row.displayOrderEdit)) {
                        existdisplayorder = true;
                    }
                }
            })
        }
        row.kpiDate = moment(MeetingDate).format('MM/DD/YYYY');
        if (isNullOrUndefined(row.presenterEdit) || !/\S/.test(row.presenterEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please enter the Presenter Details',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );

        }
        else if (isNullOrUndefined(row.topicNameEdit) || !/\S/.test(row.topicNameEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please enter the Topic Name',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );

        }
        else if (!isNullOrUndefined(row.presentationLinkEdit) && !validateLink(row.presentationLinkEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg("Please enter the valid Presentation Link",
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        else {
            if (!isNullOrUndefined(row.presentationLinkEdit) && row.presentationLinkEdit.toLowerCase().indexOf("http") === -1) {
                row.presentationLinkEdit = "https://" + row.presentationLinkEdit;
            }
            if (isNullOrUndefined(row.displayOrderEdit)) {
                row.displayOrderEdit = null;
            }
            else {
                row.displayOrderEdit = parseInt(row.displayOrderEdit);
            }
            setloadingtrue();
            let message = (row.id === 0) ? "Success Stories Data for Presenter " + row.presenterEdit + " Saved successfully" : "Success Stories Data updated successfully";
            SecuritySHRAPI.AddorUpdateSecuritySuccessStoriesData(row)
                .then((result: number) => {
                    if (result != -1) {
                        GetWeeklySecurityData(moment(MeetingDate).format('MM/DD/YYYY'), 2);
                        toastIdRef.current = toast.success(
                            renderToastifyMsg(message,
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                    else {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Error while adding Success Stories Data",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while adding the Success Stories Data",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
    }

    //==================function to Cancel the Edit for Success Stories Row ==================================//
    const cancelEditSuccessStoriesRows = (e, value) => {
        if (window.confirm("Are you sure you want to Cancel this Edit?")) {
            let val: number = value.id;
            CancelEditSuccessStoriesRow();
        }
    }

    //=======================function on textbox change while Editing arow in Success Stories==============//
    const SuccessStoriesvalueschange = (e, value) => {
        let val: number = value.id;
        OnPIRSuccessStoriesChangevalues(e, val);
    }

    const EditLockforSuccessStoriesData = () => {
        if (isAdmin) {
            EditLockforSuccessStories();
        }
    }

    //=============== function to enable Add new row for Success Case Studies ====================================//
    const EnableAddNewforSuccessStoriesRow = () => {
        EnableAddNewRowforSuccessStories();
    }

    //============== function to accept only numbers for textbox keydown ==============================//
    const handleKeypress = (e) => {
        var isModifierkeyPressed = (e.metaKey || e.ctrlKey || e.shiftKey);
        var isCursorMoveOrDeleteAction = ([46, 8, 37, 38, 39, 40, 9].indexOf(e.keyCode) != -1);
        var isNumKeyPressed = (e.keyCode >= 48 && e.keyCode <= 58) || (e.keyCode >= 96 && e.keyCode <= 105);
        var vKey = 86, cKey = 67, aKey = 65;
        switch (true) {
            case isCursorMoveOrDeleteAction:
            case isModifierkeyPressed == false && isNumKeyPressed:
            case (e.metaKey || e.ctrlKey) && ([vKey, cKey, aKey].indexOf(e.keyCode) != -1):
                break;
            default:
                e.preventDefault();
        }
    }

    const createFunSpotLightTable = (tableName) => {
        const IDSHRDiv = document.querySelector("div.IDWSHRDiv") // Find the scoreboard div in our html
        while (IDSHRDiv.firstChild) IDSHRDiv.removeChild(IDSHRDiv.firstChild) // Remove all children from IDSHRs div (if any)
        let tableHeaders = [];
        if (tableName === 'publicServiceAnnouncement') {
            tableHeaders = ["Presenter", "Brief overview of the topic", "Optional Link"];
        }
        else if (tableName === 'successStories') {
            tableHeaders = ["Presenter", "Brief overview of the topic", "Optional Link"];
        }


        let IDSHRTable = document.createElement('table'); // Create the table itself
        IDSHRTable.setAttribute('id', 'IDSHRMainTable');
        IDSHRTable.className = 'IDSHRTable';
        let IDSHRTableHead = document.createElement('thead'); // Creates the table header group element
        IDSHRTableHead.className = 'IDSHRTable';
        let IDSHRTableHeaderRow = document.createElement('tr'); // Creates the row that will contain the headers
        IDSHRTableHeaderRow.className = 'IDSHRTableHeaderRow';
        // Will iterate over all the strings in the tableHeader array and will append the header cells to the table header row
        tableHeaders.forEach(header => {
            let scoreHeader = document.createElement('th') // Creates the current header cell during a specific iteration
            scoreHeader.innerText = header
            IDSHRTableHeaderRow.append(scoreHeader) // Appends the current header cell to the header row
        });
        IDSHRTableHead.append(IDSHRTableHeaderRow); // Appends the header row to the table header group element
        IDSHRTable.append(IDSHRTableHead);
        let IDSHRTableBody = document.createElement('tbody'); // Creates the table body group element
        IDSHRTableBody.className = "IDSHRTable-Body";
        IDSHRTable.append(IDSHRTableBody); // Appends the table body group element to the table
        IDSHRDiv.append(IDSHRTable); // Appends the table to the IDSHR div
    }
    // The function below will accept a single score and its index to create the global ranking
    const appendFunSpotLight = (singleWSHR, tableName) => {
        if (singleWSHR.id !== -1 && singleWSHR.id !== -2) {
            const IDSHRTable = document.querySelector('.IDSHRTable');// Find the table we created
            let IDSHRTableBodyRow = document.createElement('tr'); // Create the current table row
            IDSHRTableBodyRow.className = 'IDSHRTableBodyRow';

            if (tableName === 'publicServiceAnnouncement') {
                // create the 3 column cells that will be appended to the current table row
                let presenterData = document.createElement('td');
                presenterData.innerText = singleWSHR.presenter;
                let topicNameData = document.createElement('td');
                topicNameData.innerText = singleWSHR.topicName;
                let presentationLinkData = document.createElement('td');
                presentationLinkData.innerText = singleWSHR.presentationLink;

                IDSHRTableBodyRow.append(presenterData, topicNameData, presentationLinkData); // Append all 3 cells to the table row
            }
            else if (tableName === 'successStories') {
                // create the 3 column cells that will be appended to the current table row
                let presenterData = document.createElement('td');
                presenterData.innerText = singleWSHR.presenter;
                let topicNameData = document.createElement('td');
                topicNameData.innerText = singleWSHR.topicName;
                let presentationLinkData = document.createElement('td');
                presentationLinkData.innerText = singleWSHR.presentationLink;

                IDSHRTableBodyRow.append(presenterData, topicNameData, presentationLinkData); // Append all 3 cells to the table row
            }
            IDSHRTable.append(IDSHRTableBodyRow); // Append the current row to the IDSHR table body
        }
    }

    const CopyTableData = (tableName) => {
        createFunSpotLightTable(tableName); //Creates tables
        if (tableName === 'publicServiceAnnouncement') {
            if (isArrayWithLength(PublicServiceAnnouncementData)) {
                for (const item of PublicServiceAnnouncementData) {
                    if (item.id != 0) {
                        appendFunSpotLight(item, tableName); // Creates and appends each row to the table body.
                    }
                }
            }
        }
        else if (tableName === 'successStories') {
            if (isArrayWithLength(SuccessStoriesData)) {
                for (const item of SuccessStoriesData) {
                    if (item.id != 0) {
                        appendFunSpotLight(item, tableName); // Creates and appends each row to the table body.
                    }
                }
            }
        }
        var el = document.getElementById('IDSHRMainTable');
        // var el = document.getElementById('WeeklyIDSHRTableid');
        var body = document.body, range, sel;
        if (document.createRange && window.getSelection) {
            range = document.createRange();
            sel = window.getSelection();
            sel.removeAllRanges();
            try {
                range.selectNodeContents(el);
                sel.addRange(range);
            } catch (e) {
                range.selectNode(el);
                sel.addRange(range);
            }
            document.execCommand("copy");
            if (el) el.parentNode.removeChild(el);
        }
        if (!toast.isActive(toastIdRef.current)) {
            toastIdRef.current = toast.success(
                renderToastifyMsg('Table content copied to clipboard',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
            );
        }
    };

    if (isAuthenticated) {
        return (
            <div>
                <DeprecatedFunctionalityBanner />
                <LoadingOverlay active={isLoading}
                    spinner
                    text='Loading your content...'
                >
                    <section className='section'>
                        <div className='container'>
                            <SecuritySHRDataTable
                                MeetingDate={MeetingDate}
                                handleMeetingDateChange={handleMeetingDateChange}
                                MaxDate={MaxDate}
                            />
                            <br />
                            <Row>
                                <Col lg="12">
                                    <h5 className="headertext">Service Health, Heatmaps, S360 and OneDash Review (30 mins):</h5>
                                    <a href="https://unifiedpmconsole.azurewebsites.net/" target="_blank">Unified PM Console- Home Page</a>
                                </Col>                                
                            </Row>
                            <br /> <br />
                            <Row>
                                <Col lg="12">
                                    <h5 className="headertext">Livesite Dashboard Review (5 minutes):</h5>
                                    <a href="https://msit.powerbi.com/groups/me/reports/a7e16e87-e7a8-4b5d-9fbd-61494e66a95a/ReportSectionc55575d29def9577994d" target="_blank">Report</a> (aka.ms/idlivesite-review) for review of Repair Items, IcM Trends and DRI Health
                                </Col>                               
                            </Row>
                            <br /> <br />
                            <Row>
                                <Col lg="12">
                                    <PublicServiceAnnouncement
                                        PublicServiceAnnouncementData={PublicServiceAnnouncementData}
                                        handleKeypress={handleKeypress}
                                        EditPubServiceAccRows={(e, row) => EditPubServiceAccRows(e, row)}
                                        DeletePubServiceAccRow={(e, row) => DeletePubServiceAccRow(e, row)}
                                        cancelEditPubServiceAccRows={(e, row) => cancelEditPubServiceAccRows(e, row)}
                                        PubServiceAccvalueschange={(e, row) => PubServiceAccvalueschange(e, row)}
                                        isPubServiceAccEditorAddEnabled={isPubServiceAccEditorAddEnabled}
                                        SavePubServiceAccData={(e, row) => SavePubServiceAccData(e, row)}
                                        EnableAddNewforPubServiceAccRow={EnableAddNewforPubServiceAccRow}
                                        DefaultDate={moment(DefaultDate).format('MM/DD/YYYY')}
                                        MeetingDate={moment(MeetingDate).format('MM/DD/YYYY')}
                                        PubServiceAccDate={MeetingDate}
                                        MaxDate={MaxDate}
                                        handlePubServiceAccDateChange={handlePubServiceAccDateChange}
                                        isAdmin={isAdmin}
                                        isLocked={isLockedSecurity}
                                        EditLockforPubServiceAcc={EditLockforPubServiceAccData}
                                        UpdatePubServiceAccDataOrder={UpdatePubServiceAccDataOrder}
                                        CopyTableData={CopyTableData}
                                        isPSALockDisabledOnEdit={isPSALockDisabledOnEdit}
                                        psaaccordiontoggle={psaaccordiontoggle}
                                        setpsaccordiontoggledata={(psaaccordiontoggle) => setpsaccordiontoggledata(psaaccordiontoggle)}
                                    />
                                </Col>
                            </Row>
                            <br /> <br />
                            <br /> <br />
                            <Row>
                                <Col lg="12">
                                    <SuccessStories
                                        SuccessStoriesData={SuccessStoriesData}
                                        handleKeypress={handleKeypress}
                                        EditSuccessStoriesRows={(e, row) => EditSuccessStoriesRows(e, row)}
                                        DeleteSuccessStoriesRow={(e, row) => DeleteSuccessStoriesRow(e, row)}
                                        cancelEditSuccessStoriesRows={(e, row) => cancelEditSuccessStoriesRows(e, row)}
                                        SuccessStoriesvalueschange={(e, row) => SuccessStoriesvalueschange(e, row)}
                                        isSuccessStoriesEditorAddEnabled={isSuccessStoriesEditorAddEnabled}
                                        SaveSuccessStoriesData={(e, row) => SaveSuccessStoriesData(e, row)}
                                        EnableAddNewforSuccessStoriesRow={EnableAddNewforSuccessStoriesRow}
                                        DefaultDate={moment(DefaultDate).format('MM/DD/YYYY')}
                                        MeetingDate={moment(MeetingDate).format('MM/DD/YYYY')}
                                        SuccessStoriesDate={MeetingDate}
                                        MaxDate={MaxDate}
                                        handleSuccessStoriesDateChange={handleSuccessStoriesDateChange}
                                        isAdmin={isAdmin}
                                        isLocked={isLockedSuccessStories}
                                        EditLockforSuccessStories={EditLockforSuccessStoriesData}
                                        UpdateSuccessStoriesDataOrder={UpdateSuccessStoriesDataOrder}
                                        CopyTableData={CopyTableData}
                                        isSSLockDisabledOnEdit={isSSLockDisabledOnEdit}
                                        ssaccordiontoggle={ssaccordiontoggle}
                                        setssaccordiontoggledata={(ssaccordiontoggle) => setssaccordiontoggledata(ssaccordiontoggle)}
                                    />
                                </Col>
                            </Row>
                            <br /> <br />
                            <br /> <br />
                            <div className='IDWSHRDiv'></div>
                        </div>
                    </section>
                </LoadingOverlay>

            </div>


        );
    }
    else {
        return (
            <div></div>
        );
    }
};

const mapStateToProps = (state: IApplicationState) => ({
    ...state.SecuritySHR,
    isAuthenticated: state.authUser.isAuthenticated,
    username: state.authUser.login,
    isAdmin: state.authUser.isAdmin
})

export default connect(mapStateToProps, actionCreators)(SecuritySHRData);



