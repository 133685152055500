import { BaseService } from './base.service';
import { IServiceGroup, PropMetricGroups, PropAdminMetrics, PropConfig, PropTarget, PropUsers, PropEditHistory, PropMetricChanges, PropMetricsChangeHistory, PropCmMetric, PropHeatMapMissingViews } from '../store/AdminSettings';

/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class AdminSettingsService extends BaseService {
    private static _serviceGroupService: AdminSettingsService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): AdminSettingsService {
        return (this._serviceGroupService || (this._serviceGroupService = new this('AdminSettings')));
    }

    public async getServiceGroupAsyn(startDailyIndex: number): Promise<IServiceGroup[]> {
        const { data } = await this.$http.get<IServiceGroup[]>(`ServiceGroup?optionalHM=` + 0);
        return data;
    }
    public async GetMetricGroups(): Promise<PropMetricGroups[]> {
        const { data } = await this.$http.get<PropMetricGroups[]>(`GetMetricGroups`);
        return data;
    }
    public async Get_Metrics_Admin(serviceId: number): Promise<PropAdminMetrics[]> {
        const { data } = await this.$http.get<PropAdminMetrics[]>(`GetAdminMetrics?serviceId=` + serviceId + ` & date=` + new Date);
        return data;
    }
    public async Get_Config(configId: number): Promise<PropConfig[]> {
        const { data } = await this.$http.get<PropConfig[]>(`GetConfig?configId=` + configId);
        return data;
    }
    public async Get_Targets(): Promise<PropTarget[]> {
        const { data } = await this.$http.get<PropTarget[]>(`GetTarget`);
        return data;
    }
    public async PostQueryChange(queryObj): Promise<string> {
        const { data } = await this.$http.post<string>(`PostQueryChange`, queryObj);
        return data;
    }
    public async PostTargetChange(targetObj): Promise<string> {
        const { data } = await this.$http.post<string>(`PostTargetChange`, targetObj);
        return data;
    }
    public async UpdateConfig(updateConfigValue): Promise<number> {
        let idVal = 1;
        const { data } = await this.$http.post<number>(`UpdateConfig?config?id=${idVal}&value=${updateConfigValue}`);
        return data;
    }
    public async SaveService(serviceId: number, isActive: boolean): Promise<number> {
        let isActiveStatus = isActive === true ? 1 : 0;
        const { data } = await this.$http.post<number>(`UpdateServiceStatus?serviceId=${serviceId}&isActive=${isActiveStatus}`);
        return data;
    }
    public async UpdateMetricsforAdminPage(serviceId: number, metricId: number, isEditable: boolean, isMetricgraph: boolean, targetId: number, minRange: string, maxRange: string, interval: string, validationExpr: string): Promise<number> {
        validationExpr = validationExpr.replace(/\+/g, "Plus");
        const isEditabled = isEditable === true ? 1 : 0;
        const isMetricgraphbool = isMetricgraph === true ? 1 : 0;
        const { data } = await this.$http.post<number>(`UpdateMetricsforAdminPage?serviceId=${serviceId}&metricId=${metricId}&isEditable=${isEditabled}&targetId=${targetId}&isMetricgraph=${isMetricgraphbool}&minRange=${minRange}&maxRange=${maxRange}&interval=${interval}&validationExpr=${validationExpr}`);
        return data;
    }
    public async UpdateCMMertic(cmMetric: object): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateMertic`, cmMetric);
        return data;
    }
    public async CMDeleteMetric(cmMetric: object): Promise<number> {
        const { data } = await this.$http.post<number>(`DeleteMetric`, cmMetric);
        return data;
    }
    public async InsertCMMertic(cmMetric: object): Promise<number> {
        const { data } = await this.$http.post<number>(`InsertMertic`, cmMetric);
        return data;
    }
    public async Get_Users(): Promise<PropUsers[]> {
        const { data } = await this.$http.get<PropUsers[]>(`GetUsers`);
        return data;
    }
    public async GetEditHistoryData(serviceId: number, fromDate: string, toDate: string): Promise<PropEditHistory[]> {
        const { data } = await this.$http.get<PropEditHistory[]>(`GetEditHistoryData?serviceId=` + serviceId + `&fromdate=` + fromDate + `&todate=` + toDate + `&date=` + new Date);
        return data;
    }
    public async GetHeatMapKUSTOHistoryData(serviceId: number, fromDate: string, toDate: string): Promise<object[]> {
        const { data } = await this.$http.get<object[]>(`GetKustoQueryChangeHistory?serviceid=${serviceId}&fromDate=${fromDate}&toDate=${toDate}`);
        return data;
    }
    public async Get_MetricsFromServiceMetricGroup(service: string, selectedMetricGroup: string): Promise<PropMetricChanges[]> {
        /*const { data } = await this.$http.get<PropMetricsChangeHistory[]>(`GetMetricChanges?service=` + service + `&metricGroup=` + selectedMetricGroup);*/
        const { data } = await this.$http.get<PropMetricChanges[]>(`GetMetrics?service=${service}&metricGroup=${selectedMetricGroup}`);
        return data;
    }
    public async GetCapacityMetrics(): Promise<PropCmMetric[]> {
        const { data } = await this.$http.get<PropCmMetric[]>(`GetCapacityMetrics`);
        return data;
    }
    public async GetCapacityMetricGroup(): Promise<object[]> {
        const { data } = await this.$http.get<object[]>(`GetCapacityMetricGroup`);
        return data;
    }
    public async GetAllScoreCardDetails(): Promise<object[]> {
        const { data } = await this.$http.get<object[]>(`GetAllScoreCardDetails`);
        return data;
    }
    public async GetMetricEnvirnoments(): Promise<object[]> {
        const { data } = await this.$http.get<object[]>(`GetMetricEnvirnoments`);
        return data;
    }
    public async GetHeatMapMissingViewData(): Promise<PropHeatMapMissingViews[]> {
        const { data } = await this.$http.get<PropHeatMapMissingViews[]>(`GetHeatMapMissingViewData`);
        return data;
    }
    public async GetHeatmapTargetUpdateRequests(): Promise<object[]> {
        const { data } = await this.$http.get<object[]>(`GetHeatmapTargetUpdateRequests`);
        return data;
    }
    public async ApproveTargetChangeRequest(row): Promise<number> {
        const { data } = await this.$http.post<number>("ApproveTargetChangeRequest", row);
        return data;
    }
    public async HmTargetApproveEmail(requestDetails): Promise<any> {        
        const { data } = await this.$http.post<any>(`HmTargetApproveEmail`, requestDetails);
        return data;
    }
    public async HmTargetRejectEmail(requestDetails): Promise<any> {
        const { data } = await this.$http.post<any>(`HmTargetRejectEmail`, requestDetails);
        return data;
    }
}

export const AdminSettingsAPI = AdminSettingsService.Instance;
