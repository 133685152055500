import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IMSERHeatmapTrendState } from './types';

const initialState = Object.freeze<IMSERHeatmapTrendState>({
    isLoading: false,
    mserHeatmapTrendStartDate: new Date(),
    mserHeatmapTrendData: [],
    MSERHeatmapTrendheaderArr: [],
    MSERHeatmapTrendKPiArr: [],
    IsMSERHeatmapTrendDataLoaded: false,
    liKPIValueKeys: [],
    uniquev: [],
    uniquec: [],
    selectedidentityServiceClassification: 'All',
    serviceGroups: [],
    services: [],
    servicegroupval: [],
    serviceval: [],
    selectedidentity_ServiceClassification: [],
    globalsearch: '',
    identityServiceClassification: [],
    servicegroupvalstring: '',
    servicevalstring: '',
    isDataloaded:false
});

export const reducer = (
    state: IMSERHeatmapTrendState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;    

    switch (action.type) {
        case ActionType.MSER_HEATMAP_TREND_START_DATE_CHANGE:
            return {
                ...state,
                mserHeatmapTrendStartDate: action.mserHeatmapTrendStartDate
            };
        case ActionType.MSER_HEATMAP_TRENDDATA_FETCH:
            return {
                ...state,
                mserHeatmapTrendData: [],
                isLoading: true,
                IsMSERHeatmapTrendDataLoaded: false,
                isDataloaded:false
            };
        case ActionType.MSER_HEATMAP_TRENDDATA_RECEIVE:
            return {
                ...state,
                mserHeatmapTrendData: action.mserHeatmapTrendData,
                MSERHeatmapTrendheaderArr: action.MSERHeatmapTrendheaderArr,
                MSERHeatmapTrendKPiArr: action.MSERHeatmapTrendKPiArr,
                IsMSERHeatmapTrendDataLoaded: true,
                liKPIValueKeys: action.liKPIValueKeys,
                isLoading: false,
                uniquev: action.uniquev,
                uniquec: action.uniquec,
                selectedidentityServiceClassification: action.selectedidentityServiceClassification,
                isDataloaded:true
            };
        case ActionType.GET_SERVICEGROUPS_SUCCESS:
            return {
                ...state,
                identityServiceClassification: action.identityServiceClassification,
                serviceGroups: action.serviceGroups, 
                servicegroupval: action.servicegroupval,
                servicegroupvalstring: action.servicegroupvalstring
            
            };
        case ActionType.GET_SERVICEGROUPS_FAIL:
            return {
                ...state,
                serviceGroups: []

            };
        case ActionType.GET_SERVICELISTS_SUCCESS:
            return {
                ...state,               
                services: action.services,
                serviceval: action.serviceval,
                servicevalstring: action.servicevalstring

            };
        case ActionType.GET_SERVICELISTS_FAIL:
            return {
                ...state,
                services: action.services,

            };
        case ActionType.SERVICE_GROUPVAL_CHANGE:
            return {
                ...state,
                servicegroupval: action.servicegroupval,
                servicegroupvalstring: action.servicegroupvalstring,
                serviceval: action.serviceval,
                servicevalstring: action.servicevalstring

            };
        case ActionType.SERVICE_VAL_CHANGE:
            return {
                ...state,
                serviceval: action.serviceval,
                servicevalstring: action.servicevalstring,
                servicegroupval: action.servicegroupval,
                servicegroupvalstring: action.servicegroupvalstring

            };
        case ActionType.SERVICE_IDENTITY_CLASIFICATION_CHANGE:
            return {
                ...state,
                selectedidentityServiceClassification: action.selectedidentityServiceClassification,

            };
        case ActionType.SEARCH_TEXTBOX_CHANGE:
            return {
                ...state,
                globalsearch: action.globalsearch,

            };
        case ActionType.CLEAR_FILTERS:
            return {
                ...state,
                servicegroupval: action.servicegroupval,
                servicegroupvalstring: action.servicegroupvalstring,
                serviceval: action.serviceval,
                servicevalstring: action.servicevalstring,
                selectedidentityServiceClassification: action.selectedidentityServiceClassification,
                globalsearch:''

            };
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true,

            };
        case ActionType.RESET_STATE:
            return initialState;

        default:
            return state;
    }
};
