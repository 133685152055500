import React, { useEffect, useRef, useState } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import { actionCreators, reducer } from '../../store/EmailConfiguration';
import { EmailConfigurationTable } from '../EmailConfiguration/child-components';
import { isArrayWithLength, renderToastifyMsg } from '../../utils';
import LoadingOverlay from 'react-loading-overlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import {
    Row,
    Col, Card, CardText
} from 'reactstrap';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap'
import { EmailConfigAPI, DailyDataApi, WeeklyAPI } from '../../api';
type EmailConfigurationProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly history: History }
    & typeof useParams;

const EmailConfiguration: React.FC<EmailConfigurationProps> = ({
    isLoading,
    addUserVisit,
    LoginuserId,
    isAuthenticated,
    setloadingtrue,
    setloadingfalse,
    serviceGroups,
    selectedServicegroupval,
    services,
    selectedServiceval,
    viewSourceDetailsByService,
    viewTargetDetailsByService,
    emailConfigNames,

    handleServiceGroupChange,
    handleServicesChange,
    GetDropDownDataForEmailConfig,
    GetEmailConfigDataSources,
    GetEmailConfigNames,
    GetEmailConfigDataSourceNames,

    clearConfigDataFilter,
    selectedEmailConfigTypes,
    handleEmailConfigTypesChange,
    emailConfigDataSourceDetails,

    toEmailList,
    ccEmailList,

 

    datasource,
    selectedDatasourcePRI,
    handledatasourceChangePRI,

    serverNamePRI,
    databasePRI,
    authenticationTypePRI,
    commentsPRI,
    queryOrUrlPRI,
    EditEmailConfigDataSourceRowPRI,
    clearEmailConfigDataSourceDataHeatmap,
    clearEmailConfigDataSourceDataPRI,


    selectedDatasourceCRI,
    handledatasourceChangeCRI,
    serverNameCRI,
    databaseCRI,
    authenticationTypeCRI,
    commentsCRI,
    queryOrUrlCRI,
    EditEmailConfigDataSourceRowCRI,
    clearEmailConfigDataSourceDataCRI,

    selectedDatasourceDRI,
    handledatasourceChangeDRI,
    serverNameDRI,
    databaseDRI,
    authenticationTypeDRI,
    commentsDRI,
    queryOrUrlDRI,
    EditEmailConfigDataSourceRowDRI,
    clearEmailConfigDataSourceDataDRI,

    selectedDatasourceSEV12,
    handledatasourceChangeSEV12,
    serverNameSEV12,
    databaseSEV12,
    authenticationTypeSEV12,
    commentsSEV12,
    queryOrUrlSEV12,
    EditEmailConfigDataSourceRowSEV12,
    clearEmailConfigDataSourceDataSEV12,

    changeToEmailList,
    changeCCEmailList,

    authenticationTypeUpdatePRI,
    serverNameUpdatePRI,
    databaseUpdatePRI,
    queryOrUrlUpdatePRI,
    commentsUpdatePRI,

    authenticationTypeUpdateCRI,
    serverNameUpdateCRI,
    databaseUpdateCRI,
    queryOrUrlUpdateCRI,
    commentsUpdateCRI,

    authenticationTypeUpdateDRI,
    serverNameUpdateDRI,
    databaseUpdateDRI,
    queryOrUrlUpdateDRI,
    commentsUpdateDRI,

    authenticationTypeUpdateSEV12,
    serverNameUpdateSEV12,
    databaseUpdateSEV12,
    queryOrUrlUpdateSEV12,
    commentsUpdateSEV12,

    SaveEmailConfigDataSource,
    IsValidPRIQuery,
    IsPRIDataSaved,

    IsQueryFailed,
    IsDataSaved,

    IsClearAll,
    
    onboardNewEmailConfigHeatmap,
    setonboardNewEmailConfigHeatmap,

    onboardNewEmailConfigPRI,
    setonboardNewEmailConfigPRI,

    onboardNewEmailConfigCRI,
    setonboardNewEmailConfigCRI,

    onboardNewEmailConfigDRI,
    setonboardNewEmailConfigDRI,

    onboardNewEmailConfigSEV,
    setonboardNewEmailConfigSEV,

    setViewEmailConfigDataSourceEnabled,
    setViewEmailConfigDataSourceDisabled,
    setAddEmailConfigDataSourceEnabled,
    setAddEmailConfigDataSourceDisabled,
    ViewEmailConfigDataSourceOpen,
    AddEmailConfigDataSourceOpen,

    setAddEmailConfigPRIDataSourceEnabled,
    setAddEmailConfigPRIDataSourceDisabled,
    AddEmailConfigPRIDataSourceOpen
    
}) => {
    const toastIdRef = useRef(null);
    const [IsRefreshPostdeleted, setIsRefreshPostdeleted] = useState(false);
    //const selectedServiceId=0
    useEffect(() => {
        if (isAuthenticated) {
            addUserVisit(0, 'EmailConfiguration.tsx_v2');           
            GetDropDownDataForEmailConfig(6, null);           
        }
    }, [isAuthenticated]);

    useEffect(() => {
            GetDropDownDataForEmailConfig(6, null);
    }, [IsClearAll]);

    //========== Call Load MetricdataSource method when Service is changed ======//
    useEffect(() => {
        if (selectedServiceval !== null) {
            GetEmailConfigDataSources(selectedServiceval);
            GetEmailConfigNames(selectedServiceval);
            GetEmailConfigDataSourceNames();
        }
    }, [selectedServiceval, IsRefreshPostdeleted]);

    const SaveEmailConfigData = (sObj) => {
        setloadingtrue();
        if (sObj.emailConfigName === "Heatmap") {
            EmailConfigAPI.SaveEmailConfigDataSourceDetails(sObj)
                .then((result: number) => {
                    setloadingfalse();
                    if (result != -1) {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg("Email Configuration Data saved successfully",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 });
                    }
                    else {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg('Error while saving the data',
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                        );
                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg('Error while saving the data',
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }

                    );
                });
        }
        else {
            let kustoquery = JSON.stringify({ "csl": sObj.queryOrUrl, "db": sObj.dbName });
            DailyDataApi.ValidateKustoQuery({ "json": kustoquery, "apiName": sObj.clusterName })
                .then((result: string) => {
                    if (result.length > 0) {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg('Invalid Query. Please verify',
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                        );
                    }
                    else {
                        EmailConfigAPI.SaveEmailConfigDataSourceDetails(sObj)
                            .then((result: number) => {
                                if (result != -1) {
                                    setloadingfalse();
                                    toastIdRef.current = toast.success(
                                        renderToastifyMsg("Email Configuration Data saved successfully",
                                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 });
                                }
                                else {
                                    setloadingfalse();
                                    toastIdRef.current = toast.error(
                                        renderToastifyMsg('Error while saving the data',
                                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                                    );
                                }
                            }).catch(error => {
                                setloadingfalse();
                                toastIdRef.current = toast.error(
                                    renderToastifyMsg('Error while saving the data',
                                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }

                                );
                            });
                    }
                  
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg('Error while performing the Query validation',
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }

                    );
                });
        }
       
    }

    const DeleteEmailConfigData = (sObj) => {
        setloadingtrue();

        EmailConfigAPI.SaveEmailConfigDataSourceDetails(sObj)
            .then((result: number) => {
                setloadingfalse();
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg("Record has been deleted successfully",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 });

                    if (IsRefreshPostdeleted) 
                        setIsRefreshPostdeleted(false);                    
                    else 
                        setIsRefreshPostdeleted(true);     
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg('Error while deleting the data',
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg('Error while deleting the data',
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }

                );
            });
    }
    const SaveEmailIds = () => {
        setloadingtrue();  
        if (toEmailList !== '') {
            EmailConfigAPI.SaveEmailIds(selectedServiceval, toEmailList, ccEmailList)
                .then((result: number) => {
                    setloadingfalse();
                    if (result != -1) {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg("Email Ids saved successfully",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 });
                    }
                    else {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg('Error while saving the data',
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                        );
                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg('Error while saving the data',
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }

                    );
                });
        }
        else {
            setloadingfalse();
            toastIdRef.current = toast.error(
                renderToastifyMsg('To Email cannot be blank',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
            );
        }
    }

    const addEmailConfigDataSourceData = (row, operation) => {
    //    setloadingtrue();
        
    //    const obj = {
    //        ServiceId: (selectedServiceval === null) ? row.serviceId : parseInt(selectedServiceval),           
    //        QueryOrUrl: queryOrUrl,
    //        AuthenicationType: typeof authenticationType !== "object" ? authenticationType : authenticationType.value,
    //        SourceClusterName: serverName,
    //        SourceDBName: database,
    //        Comments: comments,
    //        UpdatedBy: LoginuserId,           
    //    };
    //    var insertorUpdateflag = operation === "UPDATE" ? 1 : 0;
    //    var toastmsg = operation === "UPDATE" ? "Updated" : "Added";
    //    let kustoquery = JSON.stringify({ "csl": queryOrUrl, "db": database });
    //    DailyDataApi.ValidateKustoQuery({ "json": kustoquery, "apiName": serverName })
    //        .then((result: string) => {
    //            if (result.length > 0) {
    //                setloadingfalse();
    //                toastIdRef.current = toast.error(
    //                    renderToastifyMsg(result,
    //                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4000 }
    //                );
    //            }
    //            else {
    //                WeeklyAPI.SaveMetricDataSourceDetails(obj)
    //                    .then((result: number) => {
    //                        if (result != -1) {
    //                            toastIdRef.current = toast.success(
    //                                renderToastifyMsg('Metric DataSource data ' + toastmsg + ' Succesfully',
    //                                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
    //                            );
                               
    //                            GetEmailConfigDataSources(selectedServiceval);                              
    //                            clearEmailConfigDataSourceData();
    //                            setloadingfalse();
    //                            setAddEmailConfigDataSourceDisabled();
    //                            setViewEmailConfigDataSourceEnabled();
    //                        }
    //                        else {
    //                            setloadingfalse();
    //                            toastIdRef.current = toast.error(
    //                                renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
    //                                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
    //                            );
    //                        }
    //                    }).catch(error => {
    //                        setloadingfalse();
    //                        toastIdRef.current = toast.error(
    //                            renderToastifyMsg("Error while updating",
    //                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
    //                        );
    //                    });
    //            }
    //        });
    }

    const emailConfigDataSourceInactive = (row) => {
        setloadingtrue();
        WeeklyAPI.updateMetricDataSourceInactive(row.serviceId, row.metricId, LoginuserId)
            .then((result: number) => {
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg('Metric DataSource data deleted Succesfully',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    if (selectedServiceval !== null) {
                        GetEmailConfigDataSources(selectedServiceval);                       
                        setloadingfalse();
                    }
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });
    }


    return (
        <div>
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        <EmailConfigurationTable
                            LoginuserId={LoginuserId}
                            setloadingtrue={setloadingtrue}
                            setloadingfalse={setloadingfalse}
                            serviceGroups={serviceGroups}
                            selectedServicegroupval={selectedServicegroupval}
                            services={services}
                            selectedServiceval={selectedServiceval}
                            viewSourceDetailsByService={viewSourceDetailsByService}
                            viewTargetDetailsByService={viewTargetDetailsByService}
                            emailConfigNames={emailConfigNames}

                            selectedEmailConfigTypes={selectedEmailConfigTypes}
                            handleEmailConfigTypesChange={handleEmailConfigTypesChange}
                            handleServiceGroupChange={(selectedServicegroupval) => handleServiceGroupChange(selectedServicegroupval)}
                            handleServicesChange={(selectedServiceval) => handleServicesChange(selectedServiceval)}
                            clearConfigDataFilter={clearConfigDataFilter}
                            emailConfigDataSourceDetails={emailConfigDataSourceDetails}
                            emailConfigDataSourceInactive={(rowData) => emailConfigDataSourceInactive(rowData)}

                            toEmailList={toEmailList}
                            ccEmailList={ccEmailList}

                            datasource={datasource}

                            selectedDatasourcePRI={selectedDatasourcePRI}
                            handledatasourceChangePRI={(selectedDatasourcePRI) => handledatasourceChangePRI(selectedDatasourcePRI)}

                            serverNamePRI={serverNamePRI}
                            databasePRI={databasePRI}
                            authenticationTypePRI={authenticationTypePRI}                            
                            commentsPRI={commentsPRI}
                            queryOrUrlPRI={queryOrUrlPRI}

                            addEmailConfigDataSourceData={addEmailConfigDataSourceData}
                            clearEmailConfigDataSourceDataHeatmap={clearEmailConfigDataSourceDataHeatmap}
                            clearEmailConfigDataSourceDataPRI={clearEmailConfigDataSourceDataPRI}
                            EditEmailConfigDataSourceRowPRI={(row) => EditEmailConfigDataSourceRowPRI(row)}

                            selectedDatasourceCRI={selectedDatasourceCRI}
                            handledatasourceChangeCRI={(selectedDatasourceCRI) => handledatasourceChangeCRI(selectedDatasourceCRI)}

                            serverNameCRI={serverNameCRI}
                            databaseCRI={databaseCRI}
                            authenticationTypeCRI={authenticationTypeCRI}
                            commentsCRI={commentsCRI}
                            queryOrUrlCRI={queryOrUrlCRI}

                        
                            clearEmailConfigDataSourceDataCRI={clearEmailConfigDataSourceDataCRI}
                            EditEmailConfigDataSourceRowCRI={(row) => EditEmailConfigDataSourceRowCRI(row)}

                            selectedDatasourceDRI={selectedDatasourceDRI}
                            handledatasourceChangeDRI={(selectedDatasourceDRI) => handledatasourceChangeDRI(selectedDatasourceDRI)}

                            serverNameDRI={serverNameDRI}
                            databaseDRI={databaseDRI}
                            authenticationTypeDRI={authenticationTypeDRI}
                            commentsDRI={commentsDRI}
                            queryOrUrlDRI={queryOrUrlDRI}
                            IsPRIDataSaved={IsPRIDataSaved}
                            IsDataSaved={IsDataSaved}
                            IsClearAll={IsClearAll}

                        
                            clearEmailConfigDataSourceDataDRI={clearEmailConfigDataSourceDataDRI}
                            EditEmailConfigDataSourceRowDRI={(row) => EditEmailConfigDataSourceRowDRI(row)}

                            selectedDatasourceSEV12={selectedDatasourceSEV12}
                            handledatasourceChangeSEV12={(selectedDatasourceSEV12) => handledatasourceChangeSEV12(selectedDatasourceSEV12)}

                            serverNameSEV12={serverNameSEV12}
                            databaseSEV12={databaseSEV12}
                            authenticationTypeSEV12={authenticationTypeSEV12}
                            commentsSEV12={commentsSEV12}
                            queryOrUrlSEV12={queryOrUrlSEV12}

                            clearEmailConfigDataSourceDataSEV12={clearEmailConfigDataSourceDataSEV12}
                            EditEmailConfigDataSourceRowSEV12={(row) => EditEmailConfigDataSourceRowSEV12(row)}

                            changeToEmailList={(event) => changeToEmailList(event)}
                            changeCCEmailList={(event) => changeCCEmailList(event)}

                            serverNameUpdatePRI={(event) => serverNameUpdatePRI(event)}
                            databaseUpdatePRI={(event) => databaseUpdatePRI(event)}
                            authenticationTypeUpdatePRI={(event) => authenticationTypeUpdatePRI(event)}
                            queryOrUrlUpdatePRI={(event) => queryOrUrlUpdatePRI(event)}                            
                            commentsUpdatePRI={(event) => commentsUpdatePRI(event)}

                            serverNameUpdateCRI={(event) => serverNameUpdateCRI(event)}
                            databaseUpdateCRI={(event) => databaseUpdateCRI(event)}
                            authenticationTypeUpdateCRI={(event) => authenticationTypeUpdateCRI(event)}
                            queryOrUrlUpdateCRI={(event) => queryOrUrlUpdateCRI(event)}
                            commentsUpdateCRI={(event) => commentsUpdateCRI(event)}

                            serverNameUpdateDRI={(event) => serverNameUpdateDRI(event)}
                            databaseUpdateDRI={(event) => databaseUpdateDRI(event)}
                            authenticationTypeUpdateDRI={(event) => authenticationTypeUpdateDRI(event)}
                            queryOrUrlUpdateDRI={(event) => queryOrUrlUpdateDRI(event)}
                            commentsUpdateDRI={(event) => commentsUpdateDRI(event)}

                            serverNameUpdateSEV12={(event) => serverNameUpdateSEV12(event)}
                            databaseUpdateSEV12={(event) => databaseUpdateSEV12(event)}
                            authenticationTypeUpdateSEV12={(event) => authenticationTypeUpdateSEV12(event)}
                            queryOrUrlUpdateSEV12={(event) => queryOrUrlUpdateSEV12(event)}
                            commentsUpdateSEV12={(event) => commentsUpdateSEV12(event)}

                            SaveEmailConfigDataSource={(sObj) => SaveEmailConfigDataSource(sObj)}
                            IsValidPRIQuery={IsValidPRIQuery}
                            IsQueryFailed={IsQueryFailed}

                            SaveEmailConfigData={SaveEmailConfigData}
                            DeleteEmailConfigData={DeleteEmailConfigData}
                            SaveEmailIds={SaveEmailIds}

                            onboardNewEmailConfigHeatmap={onboardNewEmailConfigHeatmap}                            
                            setonboardNewEmailConfigHeatmap={(val) => setonboardNewEmailConfigHeatmap(val)}

                            onboardNewEmailConfigPRI={onboardNewEmailConfigPRI}
                            setonboardNewEmailConfigPRI={(val) => setonboardNewEmailConfigPRI(val)}

                            onboardNewEmailConfigCRI={onboardNewEmailConfigCRI}
                            setonboardNewEmailConfigCRI={(val) => setonboardNewEmailConfigCRI(val)}

                            onboardNewEmailConfigDRI={onboardNewEmailConfigDRI}
                            setonboardNewEmailConfigDRI={(val) => setonboardNewEmailConfigDRI(val)}

                            onboardNewEmailConfigSEV={onboardNewEmailConfigSEV}
                            setonboardNewEmailConfigSEV={(val) => setonboardNewEmailConfigSEV(val)}

                            setViewEmailConfigDataSourceEnabled={setViewEmailConfigDataSourceEnabled}
                            setViewEmailConfigDataSourceDisabled={setViewEmailConfigDataSourceDisabled}
                            ViewEmailConfigDataSourceOpen={ViewEmailConfigDataSourceOpen}

                            setAddEmailConfigDataSourceEnabled={setAddEmailConfigDataSourceEnabled}
                            setAddEmailConfigDataSourceDisabled={setAddEmailConfigDataSourceDisabled}                       
                            AddEmailConfigDataSourceOpen={AddEmailConfigDataSourceOpen}

                            setAddEmailConfigPRIDataSourceEnabled={setAddEmailConfigPRIDataSourceEnabled}
                            setAddEmailConfigPRIDataSourceDisabled={setAddEmailConfigPRIDataSourceDisabled} 
                            AddEmailConfigPRIDataSourceOpen={AddEmailConfigPRIDataSourceOpen}
                        />
                    </div>
                </section>
               
            </LoadingOverlay>

        </div>
    );
};
const mapStateToProps = (state: IApplicationState) => ({
    ...state.EmailConfiguration,
    isAuthenticated: state.authUser.isAuthenticated

})

export default connect(mapStateToProps, actionCreators)(EmailConfiguration);
