import React, { useState, Fragment } from 'react';
import DatePicker, { getDay } from 'react-datepicker'
import { ReduxAction, IAppThunkAction } from '../../../store';
import { FontAwesomeIconMemo, Checkbox } from '../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignal, faFire, faAsterisk, faChevronDown, faPencilAlt, faArrowAltCircleLeft, faRecycle, faRing, faRedo, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import { TabView, TabPanel } from 'primereact/tabview';
import classnames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputText } from 'primereact/inputtext';
import { faQuestionCircle, faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import {
    TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Card, CardBody, Button
} from 'reactstrap';
type ConfigurationProps = {
    selectedService: any;
    services: any;
    serviceChange: any;
    serviceSelected: any;
    tenantshide: any;
    displayCCLSuccessMessage: any;
    selectedTenant: any;
    tenantChange: any;
    tenants: any;
    questionarehighlevelInfo: any;
    readonly UpdatequestionarehighlevelInfo: (questionarehighlevelInfo) => IAppThunkAction<ReduxAction>;
    questionareOncall: any;
    readonly UpdatequestionareOncall: (questionareOncall) => IAppThunkAction<ReduxAction>;
    questionarecclFiltering: any;
    setquestionarecclFiltering: any;
    questionarecommDL: any;
    readonly updatequestionarecommDL: (questionarecommDL) => IAppThunkAction<ReduxAction>;
    questionarecclPM: any;
    readonly updatequestionarecclPM: (questionarecclPM) => IAppThunkAction<ReduxAction>;
    questionarecustomerImpacting: any;
    readonly updatequestionarecustomerImpacting: (questionarecustomerImpacting) => IAppThunkAction<ReduxAction>;
    tenantsWithOnboarded: any;
    OnBoard_Service_CCL: any;
    isEnabled: any;
    addNewService: any;
    showMessage: any;
    HeatMapServiceInsert: any;
    currentserviceId: any;
    isRBLValidated: any;
    openconfigurationImagesPopup: any;
    showRBLMessage: any;
    showRBLOnboardedMessage: any;
    HeatmapValidate: any;
    RBLValidate: any;
    questionareGPMName: any;
    questionareIsTenantOrSubscriptionIdBased: any;
    questionarePartners: any;
    questionareComments: any;
    readonly updatequestionareGPMName: (questionareGPMName) => IAppThunkAction<ReduxAction>;
    readonly updatequestionareIsTenantOrSubscriptionIdBased: (questionareIsTenantOrSubscriptionIdBased) => IAppThunkAction<ReduxAction>;
    readonly UpdatequestionareComments: (questionareComments) => IAppThunkAction<ReduxAction>;
    readonly updatequestionarePartners: (questionarePartners) => IAppThunkAction<ReduxAction>;
};

const ConfigurationTable = React.memo<ConfigurationProps>(({
    selectedService,
    services,
    serviceChange,
    serviceSelected,
    tenantshide,
    displayCCLSuccessMessage,
    selectedTenant,
    tenantChange,
    tenants,
    questionarehighlevelInfo,
    UpdatequestionarehighlevelInfo,
    questionareOncall,
    UpdatequestionareOncall,
    questionarecclFiltering,
    setquestionarecclFiltering,
    questionarecommDL,
    updatequestionarecommDL,
    questionarecclPM,
    updatequestionarecclPM,
    questionarecustomerImpacting,
    updatequestionarecustomerImpacting,
    tenantsWithOnboarded,
    OnBoard_Service_CCL,
    isEnabled,
    addNewService,
    showMessage,
    HeatMapServiceInsert,
    currentserviceId,
    isRBLValidated,
    openconfigurationImagesPopup,
    showRBLMessage,
    showRBLOnboardedMessage,
    HeatmapValidate,
    RBLValidate,
    questionareGPMName,
    questionareIsTenantOrSubscriptionIdBased,
    questionarePartners,
    updatequestionareGPMName,
    updatequestionareIsTenantOrSubscriptionIdBased,
    questionareComments,
    UpdatequestionareComments,
    updatequestionarePartners
}) => {

    const [activeIndex, setActiveIndex] = useState(0);

    const toggle = (Index) => {
        setActiveIndex(Index);
        if (Index === 1) {
            HeatmapValidate();
        }
        else if (Index === 2) {
            RBLValidate();
        }
    };

    return (
        <React.Fragment>
            <div>
                <br />

                <Card className="cardstyle">
                    <CardBody className="cardbodyhederstyle">
                        <Row>
                            <Col lg="4"></Col>
                            <Col lg="4" style={{ textAlign: 'center' }}>
                                <div>
                                    <h3 className="headertext"> Service Onboarding for different Tools</h3>
                                </div>
                            </Col>
                            <Col lg="4">

                            </Col>

                        </Row>
                    </CardBody>
                </Card>


                <br />
                <table style={{ textAlign: 'left' }}>
                    <tbody>
                        <tr>
                            <td style={{ textAlign: 'left', fontWeight: 'bold', verticalAlign: 'middle' }}>Service Name</td>&ensp;&ensp;
                            <td>
                                <div className="p-field p-col-4">
                                    <span className="p-float-label">
                                        <Dropdown id={'service'} style={{ width: '400px' }} placeholder="Select the Service" value={selectedService.serviceId === null ? '' : selectedService} options={services} filter filterBy="serviceName"  onChange={(e) => serviceChange(e.value)} optionLabel="serviceName" />
                                        <label htmlFor='service' className="fontcolor"></label>
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                {
                    (serviceSelected === true) ?
                        <React.Fragment>
                            <TabView style={{ width: '100%' }} activeIndex={activeIndex} onTabChange={(e) => toggle(e.index)}>
                                <TabPanel header="CCL (CRISIS, COMMUNICATIONS AND LOGISTICS)" headerClassName="PanelHeaderColoring">
                                    <div>
                                        {
                                            (serviceSelected === true) ?
                                                <React.Fragment>
                                                    {(tenantshide === false) ?
                                                        <React.Fragment>
                                                            <div>
                                                                <div style={{ paddingBottom: '20px' }}>
                                                                    <Row>
                                                                        <Col lg='11'>
                                                                            {(displayCCLSuccessMessage === true) ?
                                                                                <React.Fragment>
                                                                                    <div>
                                                                                        <br />
                                                                                        <i>
                                                                                            <b>
                                                                                                Next Step: CCL overview meeting will be scheduled with the team and CCL monitoring will be enabled after that.
                                                                                            </b>
                                                                                        </i>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                                : <React.Fragment></React.Fragment>
                                                                            }
                                                                        </Col>
                                                                        <Col lg='1'>
                                                                            <div style={{ textAlign: 'center' }}>
                                                                                <a href="https://identitydivision.visualstudio.com/IdentityWiki/_wiki/wikis/IdentityWiki.wiki/4081/CCL-Onboarding" target="_blank" title="Help">Help
                                                                                </a>
                                                                                <br />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col lg='12'>
                                                                            <div className="panel panel-default" style={{ border: '2px solid grey', backgroundColor: 'white' }}>
                                                                                <div className="panel-heading" style={{ backgroundColor: '#c7e3ff' }}>
                                                                                    <div style={{ verticalAlign: 'middle' }}>
                                                                                        <h5 className="panel-title" style={{ color: 'black' }}>
                                                                                            Step 1: Self-Configuration
                                                                                        </h5>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="panel-body">
                                                                                        <table>
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td style={{ width: '2vw' }}></td>
                                                                                                    <td style={{ width: '88vw' }}></td>
                                                                                                    <td style={{ width: '20vw' }}></td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td></td>
                                                                                                    <td></td>
                                                                                                    <td>

                                                                                                        <b>1. Provide OnCall Live Site Dashboard access to CCL team (Security Group: ID_CM)</b> <br />
                                                                                                        <b>2. Add the CCL Certificate in IcM</b><br />
                                                                                                        <div style={{ paddingLeft: '40px' }}>
                                                                                                            CCL relies on a dedicated portal to monitor incidents and take direct actions like acknowledging, sending internal incident updates ('internal comms'), engaging other IcM teams, updating the Outage hub, etc.
                                                                                                        </div>
                                                                                                        <div style={{ paddingLeft: '40px', width: '1500px' }}>
                                                                                                            For the incidents to show up in the portal, a dedicated Livesite cert needs to be configured in the service's IcM tenant.<br />
                                                                                                            Detailed instructions for certificate configuration can be found <a href="https://aka.ms/CCL_Onboarding_Instructions" target="_blank" title="CCLOnboarding">here</a>
                                                                                                        </div>
                                                                                                        <b> 3. Setup OCE and IM rotation in ICM.</b>  <a href="https://icmdocs.azurewebsites.net/administration/oncall/configureRotation.html" target="_blank" className="anchtextdecoration" title="Help"><FontAwesomeIcon title='Help' icon={faQuestionCircle} className="styhelpconfigurationicon" /></a><br />
                                                                                                        <b> 4. Create auto escalation rules for Sev1s and sev2s.</b> <a href="https://icmdocs.azurewebsites.net/administration/manageServices/escalationPolicy.html" target="_blank" className="anchtextdecoration" title="Help"><FontAwesomeIcon title='Help' icon={faQuestionCircle} className="styhelpconfigurationicon" /></a><br />
                                                                                                        <b> 5. Configure auto invite rules in IcM</b><br />
                                                                                                        <div style={{ paddingLeft: '40px' }}>
                                                                                                            The CCL team will proactively monitor the incidents for your service, and automatically engaged for Sev 2 or higher. Other roles must be engaged using IcM auto invite rules.
                                                                                                        </div>
                                                                                                        <div style={{ paddingLeft: '40px', width: '1500px' }}>
                                                                                                            Details for which roles need to have rules added can be found <a href="https://aka.ms/ID_IcM_Onboard" target="_blank" title="Checklist for new IcM service">here</a>
                                                                                                        </div>
                                                                                                        <b> 6. Read more about the KPIs that the service will be measured against: </b><br />
                                                                                                        <div style={{ paddingLeft: '50px' }}>
                                                                                                            Time to Acknowledge (TTA)  <a href="https://icmdocs.azurewebsites.net/reporting/TTA-Report.html" target="_blank" className="anchtextdecoration" title="Help"><FontAwesomeIcon title='Help' icon={faQuestionCircle} className="styhelpconfigurationicon" /></a><br />
                                                                                                            Time to Mitigate (TTM)  <a href="https://icmdocs.azurewebsites.net/reporting/TTM-Report.html" target="_blank" className="anchtextdecoration" title="Help"><FontAwesomeIcon title='Help' icon={faQuestionCircle} className="styhelpconfigurationicon" /></a><br />
                                                                                                            Time to Notify (TTN)  <a href="https://identitydivision.visualstudio.com/IdentityWiki/_wiki/wikis/IdentityWiki.wiki?pagePath=%2FIdentityWiki%2FTeams%2FCrisis%20Communications%20and%20Logistics%20(CCL)%20Team%2FTime%20to%20Notify%20(TTN)&pageId=3349&wikiVersion=GBwikiMaster" target="_blank" className="anchtextdecoration" title="Help"><FontAwesomeIcon title='Help' icon={faQuestionCircle} className="styhelpconfigurationicon" /></a><br />
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br />
                                                                            <div id="CCLQusetionareTable" className="panel panel-default" style={{ border: '2px solid grey', backgroundColor: 'white' }}>
                                                                                <div className="panel-heading" style={{ backgroundColor: '#c7e3ff' }}>
                                                                                    <div style={{ verticalAlign: 'middle' }}>
                                                                                        <h5 className="panel-title" style={{ color: 'black' }}>
                                                                                            Step 2: Questionnaire
                                                                                        </h5>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="panel-body">
                                                                                        <div style={{ paddingTop: '5px', paddingBottom: '5px', fontSize: '14px' }}>
                                                                                            <Row>
                                                                                                <Col lg='12'>
                                                                                                    <table>
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <div>
                                                                                                                        <Row>
                                                                                                                            <Col lg='6' style={{ paddingTop: '20px' }}>
                                                                                                                                <div>
                                                                                                                                    <p>
                                                                                                                                        &emsp;&ensp;
                                                                                                                                        1. Provide ICM Tenant Name:&emsp;
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                            <Col style={{ paddingTop: '20px' }}>
                                                                                                                                <div style={{ textAlign: 'left' }}>
                                                                                                                                    <div className="p-field p-col-4">
                                                                                                                                        <span className="p-float-label">
                                                                                                                                            <Dropdown id={'tenant'} style={{ width: '540px' }} value={selectedTenant.tenantId === null ? '' : selectedTenant} options={tenants} onChange={(e) => tenantChange(e.value)} optionLabel="tenantname" />
                                                                                                                                            <label htmlFor="tenant" className="fontcolor">Select the Tenant</label>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                            <Col>
                                                                                                                                <div style={{ textAlign: 'left' }}>
                                                                                                                                    <div style={{ paddingTop: '21px' }}>
                                                                                                                                        <span style={{ color: 'red', paddingTop: '50px' }}>*</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                        < br />
                                                                                                                        <Row>
                                                                                                                            <Col lg='6'>
                                                                                                                                <div>
                                                                                                                                    <p style={{ paddingLeft: '25px' }}>
                                                                                                                                        2. Provide link to OnCall Live Site dashboards
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                            <Col>
                                                                                                                                <div>
                                                                                                                                    <InputText id="questionareoncall" style={{ width: '540px' }} value={questionareOncall ? questionareOncall : ""} onChange={(e) => UpdatequestionareOncall(e)} />
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                            <Col>
                                                                                                                                <div style={{ textAlign: 'left' }}>
                                                                                                                                    <div>
                                                                                                                                        <span style={{ color: 'red', paddingTop: '50px' }}>*</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                        <Row>
                                                                                                                            <Col lg='6' style={{ paddingTop: '20px' }}>
                                                                                                                                <div style={{ paddingLeft: '42px' }}>
                                                                                                                                    <p style={{ textIndent: '-16px' }}>
                                                                                                                                        3. By default, CCL proactively monitors incidents that match the following filters: ICM team, Environment = Prod, State = Active, Severity = 0, 1 or 2.
                                                                                                                                    </p><br/>
                                                                                                                                    <p>
                                                                                                                                        Are these filters applicable and correct for your service?
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                            <Col style={{ paddingBottom: '20px', paddingRight: '190px' }}>
                                                                                                                                <div style={{ paddingTop: '20px' }}>
                                                                                                                                    <RadioButton value="true" name="Yes" onChange={(e) => setquestionarecclFiltering(e.value)} checked={questionarecclFiltering === 'true'} />&nbsp; Yes &nbsp;&nbsp;
                                                                                                                                    <RadioButton value="false" name="No" onChange={(e) => setquestionarecclFiltering(e.value)} checked={questionarecclFiltering === 'false'} />&nbsp; No&nbsp;
                                                                                                                                    <div style={{ paddingTop: '10px' }}>
                                                                                                                                        Yes: These filters are fine <br />No: CCL should use different filters for my service
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                            <Col style={{ paddingBottom: '20px' }}>
                                                                                                                                <div style={{ textAlign: 'left' }}>
                                                                                                                                    <div style={{ paddingBottom: '21px' }}>
                                                                                                                                        <span style={{ color: 'red', paddingTop: '50px', position: 'relative' }}>*</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                        </Row><br />
                                                                                                                        <Row>
                                                                                                                            <Col lg='6'>
                                                                                                                                <div style={{ paddingLeft: '42px' }}>
                                                                                                                                    <p style={{ textIndent: '-16px' }}>
                                                                                                                                        4.  In cases like outages or potential outages, CCL will send internal incident updates (aka ‘internal comms’) under the guidance of the Incident Manager. These are engineering-focussed emails aimed at the Identity LT and any other Identity or partner teams that would benefit from the information.
                                                                                                                                    </p><br/>
                                                                                                                                    <p>
                                                                                                                                        Please provide the Identity and/or partner DLs that should receive these emails by default (e.g. myserviceteam@)
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                            <Col>
                                                                                                                                <div style={{ paddingTop: '20px' }}>
                                                                                                                                    <InputText id="questionarecommDL" style={{ width: '540px' }} value={questionarecommDL ? questionarecommDL : ""} onChange={(e) => updatequestionarecommDL(e)} />
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                            <Col>
                                                                                                                                <div style={{ textAlign: 'left' }}>
                                                                                                                                    <div>
                                                                                                                                        <span style={{ color: 'red', paddingTop: '50px' }}>*</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                        </Row><br />
                                                                                                                        <Row>
                                                                                                                            <Col lg='6'>
                                                                                                                                <div>
                                                                                                                                    <p>
                                                                                                                                        &emsp;&ensp;
                                                                                                                                        5. Name of the Service Owner
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                            <Col>
                                                                                                                                <div>
                                                                                                                                    <InputText id="questionareGPMName" style={{ width: '540px' }} value={questionareGPMName ? questionareGPMName : ""} onChange={(e) => updatequestionareGPMName(e)} />
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                            <Col>
                                                                                                                                <div style={{ textAlign: 'left' }}>
                                                                                                                                    <div>
                                                                                                                                        <span style={{ color: 'red', paddingTop: '50px' }}>*</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                        <Row>
                                                                                                                            <Col lg='6' style={{ paddingTop: '20px' }}>
                                                                                                                                <div>
                                                                                                                                    <p>
                                                                                                                                        &emsp;&ensp;
                                                                                                                                        6. What are the high-level customer impacting scenarios for your service? E.g. User can't login.
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                            <Col>
                                                                                                                                <div style={{ paddingTop: '20px' }}>
                                                                                                                                    <InputText id="questionarecustomerimpacting" style={{ width: '540px' }} value={questionarecustomerImpacting ? questionarecustomerImpacting : ""} onChange={(e) => updatequestionarecustomerImpacting(e)} />
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                            <Col>
                                                                                                                                <div style={{ textAlign: 'left' }}>
                                                                                                                                    <div style={{ paddingTop: '21px' }}>
                                                                                                                                        <span style={{ color: 'red', paddingTop: '70px' }}>*</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                        <Row>
                                                                                                                            <Col lg='6' style={{ paddingTop: '20px' }}>
                                                                                                                                <div>
                                                                                                                                    <p>
                                                                                                                                        &emsp;&ensp;
                                                                                                                                        7. In case of an outage, which partner’s customers would be impacted?
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                            <Col>
                                                                                                                                <div style={{ paddingTop: '20px' }}>
                                                                                                                                    <InputText id="questionarecclPM" style={{ width: '540px' }} value={questionarePartners ? questionarePartners : ""} onChange={(e) => updatequestionarePartners(e)} placeholder="Azure, M365 and/or Dynamics, etc." />
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                            <Col>
                                                                                                                                <div style={{ textAlign: 'left' }}>
                                                                                                                                    <div style={{ paddingTop: '21px' }}>
                                                                                                                                        <span style={{ color: 'red', paddingTop: '50px' }}>*</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                        <Row>
                                                                                                                            <Col lg='6' style={{ paddingTop: '20px' }}>
                                                                                                                                <div>
                                                                                                                                    <p>
                                                                                                                                        &emsp;&ensp;
                                                                                                                                        8. Is the service tenant ID or subscription ID based?
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                            <Col>
                                                                                                                                <div style={{ paddingTop: '20px' }}>
                                                                                                                                    <InputText id="questionarecclPM" style={{ width: '540px' }} value={questionareIsTenantOrSubscriptionIdBased ? questionareIsTenantOrSubscriptionIdBased : ""} onChange={(e) => updatequestionareIsTenantOrSubscriptionIdBased(e)} />
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                            <Col>
                                                                                                                                <div style={{ textAlign: 'left' }}>
                                                                                                                                    <div style={{ paddingTop: '21px' }}>
                                                                                                                                        <span style={{ color: 'red', paddingTop: '50px' }}>*</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                        <Row>
                                                                                                                            <Col lg='6' style={{ paddingTop: '20px' }}>
                                                                                                                                <div>
                                                                                                                                    <p>
                                                                                                                                        &emsp;&ensp;
                                                                                                                                        9. Do you have any other question or comments?
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                            <Col style={{ paddingTop: '20px' }}>
                                                                                                                                <div>
                                                                                                                                    <InputText id="questionarecomments" style={{ width: '540px' }} value={questionareComments ? questionareComments : ""} onChange={(e) => UpdatequestionareComments(e)} />
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                        <Row style={{ padding: '10px 25px 10px 10px' }}>
                                                                                                                            <Col>
                                                                                                                                NOTE: Once all the steps above are completed, we will set up a 30min meeting with CCL, Livesite PM, and ID Comms to answer any questions, discuss customer impacting scenarios, and set a <b>target date for onboarding typically within 7 working days</b> from the meeting.  If your request is urgent, please state so in the comments above (question 9).
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                        : <React.Fragment></React.Fragment>
                                                    }
                                                    {(tenantshide === true && tenantsWithOnboarded === false) ?
                                                        <React.Fragment>
                                                            <div className="panel panel-default" style={{ border: '2px solid grey', backgroundColor: 'white' }}>
                                                                <div className="panel-heading" style={{ height: '50px', backgroundColor: '#c7e3ff' }}>
                                                                    <h5 className="panel-title" style={{ color: 'black' }}>
                                                                        Step 0: Prerequisites
                                                                    </h5>
                                                                </div>
                                                                <div>
                                                                    <div className="panel-body">
                                                                        <div style={{ paddingTop: '5px', paddingBottom: '5px', backgroundColor: 'white' }}>
                                                                            <Row>
                                                                                <Col lg='12'>
                                                                                    <i>
                                                                                        <b>
                                                                                            The Service does not have any Tenant Mapped. Please do the ICM Tenant mapping in ServiceTree MetaData page
                                                                                        </b>
                                                                                    </i>
                                                                                    &emsp;
                                                                                    <a onClick={e => openconfigurationImagesPopup()} className="anchtextdecoration" title="Help"><FontAwesomeIcon title='Help' icon={faQuestionCircle} className="styhelpconfigurationicon" /></a>

                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                        : <React.Fragment></React.Fragment>
                                                    }
                                                    {(tenantshide === true && tenantsWithOnboarded === true) ?
                                                        <React.Fragment>
                                                            <div>
                                                                <br />
                                                                <i>
                                                                    <b>
                                                                        This Service has been already Onboarded to COMMS Portal!!
                                                                    </b>
                                                                </i>
                                                            </div>
                                                        </React.Fragment>
                                                        : <React.Fragment></React.Fragment>
                                                    }
                                                    {(tenantshide === false) ?
                                                        <React.Fragment>
                                                            <Row>
                                                                <Col lg='4'></Col>
                                                                <Col lg='4' style={{ textAlign: 'center' }}>
                                                                    <button type="button" style={{ height: '40px', width: '80px' }} className="btn btn-primary btn-sm filter-button"
                                                                        onClick={() => OnBoard_Service_CCL()}>Submit</button>
                                                                </Col>
                                                                <Col lg='4'></Col>
                                                            </Row>
                                                        </React.Fragment>
                                                        : <React.Fragment></React.Fragment>
                                                    }
                                                </React.Fragment>
                                                : <React.Fragment></React.Fragment>
                                        }
                                    </div>
                                </TabPanel>
                                <TabPanel header="Livesite Tooling">
                                    {
                                        (addNewService === true) ?
                                            <React.Fragment>
                                                <br />
                                                <div>
                                                    <form name="form">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={2}>
                                                                        <h5><a>Confirm your service to onboard to the Identity Livesite Portal, Heatmaps, and other Livesite Tools</a></h5>
                                                                    </td>
                                                                </tr>
                                                                <tr style={{ height: '10px' }}></tr>
                                                                <tr>
                                                                    <td colSpan={2} style={{ height: '5px' }}></td>
                                                                </tr>
                                                                <tr>
                                                                    <th style={{ width: '110px', textAlign: 'left', border: 'none' }}> Service Name: </th>
                                                                    <td> {selectedService.serviceName}</td>
                                                                </tr>
                                                                <tr style={{ height: '10px' }}></tr>
                                                                <tr>
                                                                    <th style={{ width: '110px', textAlign: 'left', border: 'none' }}>Service Oid: </th>
                                                                    <td> {selectedService.serviceId}</td>
                                                                </tr>                                                             
                                                                <tr style={{ height: '15px' }}></tr>
                                                                <tr>
                                                                    <td colSpan={2} style={{ float: 'left' }}>
                                                                        <div>
                                                                            <button type="button" style={{ height: '35px', width: '80px' }} className="btn btn-primary btn-sm filter-button"
                                                                                onClick={() => HeatMapServiceInsert()}>Save</button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </form>
                                                </div>
                                            </React.Fragment>
                                            : <React.Fragment></React.Fragment>
                                    }
                                    {
                                        (showMessage === true) ?
                                            <React.Fragment>
                                                <br />
                                                <div>
                                                    <i>
                                                        <b>
                                                            Service has been OnBoarded to LiveSite. Please Navigate to the Below Url to add the HeatMap View for the service under ViewConfiguration Tab
                                                        </b>
                                                    </i>
                                                    <br />
                                                    <p>
                                                        <a href={"/ServiceHealth?serviceid=" + currentserviceId + "&name=selfserveconfig"} title="Navigate to ViewConfiguration Page in HeatMap View to Add View to the above selected Service">HeatMap View</a>
                                                    </p>
                                                </div>
                                            </React.Fragment>
                                            : <React.Fragment></React.Fragment>
                                    }
                                </TabPanel>
                                <TabPanel header="BRB (BIG RED BUTTON)">
                                    {
                                        (isRBLValidated === true) ?
                                            <React.Fragment>
                                                {(showRBLOnboardedMessage === true) ?
                                                    <React.Fragment>
                                                        <div>
                                                            <br />
                                                            <i>
                                                                <b>
                                                                    The Service has been already Onboarded to Red Button Alert System!!
                                                                </b>
                                                            </i>
                                                        </div>
                                                    </React.Fragment>
                                                    : <React.Fragment></React.Fragment>
                                                }
                                                {(showRBLMessage === true) ?
                                                    <React.Fragment>
                                                        <div>
                                                            <br />
                                                            <div className="panel panel-default" style={{ border: '2px solid grey', backgroundColor: 'white' }}>
                                                                <div className="panel-heading" style={{ height: '50px', backgroundColor: '#c7e3ff' }}>
                                                                    <h5 className="panel-title" style={{ color: 'black' }}>
                                                                        Step 0: Prerequisites
                                                                    </h5>
                                                                </div>
                                                                <div>
                                                                    <div className="panel-body">
                                                                        <div style={{ paddingTop: '5px', paddingBottom: '5px', backgroundColor: 'white' }}>
                                                                            <Row>
                                                                                <Col lg='12'>
                                                                                    <i>
                                                                                        <b>
                                                                                            The Service does not have any Tenant Mapped. Please do the ICM Tenant mapping in ServiceTree MetaData page
                                                                                        </b>
                                                                                    </i>
                                                                                    &emsp;
                                                                                    <a onClick={e => openconfigurationImagesPopup()} className="anchtextdecoration" title="Help"><FontAwesomeIcon title='Help' icon={faQuestionCircle} className="styhelpconfigurationicon" /></a>

                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </React.Fragment>
                                                    : <React.Fragment></React.Fragment>
                                                }
                                            </React.Fragment>
                                            : <React.Fragment></React.Fragment>
                                    }
                                </TabPanel>
                            </TabView>
                        </React.Fragment>
                        : <React.Fragment></React.Fragment>
                }
            </div>
        </React.Fragment>
    )

});

ConfigurationTable.displayName = 'ConfigurationTable';
export default ConfigurationTable;