import { CommonAPI, AcktrackingAPI } from '../../api';
import { IAppThunkAction, ReduxAction } from '../';
import { ActionType, IDAcktrackingTemplate, IDAcktrackingNotification, IDAcktrackingFilter } from './types';
import moment from 'moment';
import { IMonthlyReport } from '../service-health/types';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../utils';
import { debug } from 'console';
import { toast } from "react-toastify";
const servicesList = [{ 'id': 'service_Manager', 'label': 'service_Manager(GEM)' }, { 'id': 'serviceName', 'label': 'serviceName' },
{ 'id': 'componentName', 'label': 'componentName' },{ 'id': 'serviceType', 'label': 'serviceType' }, { 'id': 'contact_DevOwner', 'label': 'contact_DevOwner' },
{ 'id': 'contact_PMOwner', 'label': 'contact_PMOwner' }, { 'id': 'deploymentInfrastructure', 'label': 'deploymentInfrastructure' },
{ 'id': 'serviceLifeCycle', 'label': 'serviceLifeCycle' }, { 'id': 'serviceRing', 'label': 'serviceRing' },
{ 'id': 'identityServiceClassification', 'label': 'identityServiceClassification' }];
const columncontroltypeoptions = [{ name: 'TextBox', value: 'TextBox' },
{ name: 'Multiple Line Textbox', value: 'Multiple Line Textbox' },
{ name: 'Date', value: 'Date' },
{ name: 'CheckBox', value: 'CheckBox' },
{ name: 'Radio Button', value: 'Radio Button' },
{ name: 'Dropdown', value: 'Dropdown' }
];
const colors = [
    { name: '--Select Color--', value: '0', hexcode: '#ff0000' },
    { name: 'Red', value: 'red', hexcode: '#ff0000' },
    { name: 'Green', value: 'green', hexcode: '#00ff00' },
    { name: 'Yellow', value: 'yellow', hexcode: '#ffff00' },
    { name: 'Custom', value: 'Custom', hexcode: '' }
];
export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    setloadingtrue: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
    },
    setloadingfalse: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_FALSE
        });
    },
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }

    },
    GetAcktrackingTemplateData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let username = getState().authUser.login;
        let ackTemplateData = getState().AckTrackingTemplate.ackTemplateData;
        dispatch({
            ackTemplateData: ackTemplateData,
            type: ActionType.ACKTEMPLATE_DATA_FETCH
        });
        AcktrackingAPI.GetAckTrackingTemplateData()
            .then((Acktemplatedata: IDAcktrackingTemplate[]) => {
                Acktemplatedata.forEach((row, index) => {
                    if (row.createdBy == username.split('@')[0]) {
                        row.isEditable = true;
                    }
                });
                    dispatch({
                        Acktemplatedata,
                        type: ActionType.ACKTEMPLATE_DATA_SUCCESS
                    });
            }).catch(error => {
                dispatch({
                    type: ActionType.ACKTEMPLATE_DATA_FAIL
                });
            });

    },
    hideAckTypePopup: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let showackTypePopup = getState().AckTrackingTemplate.showackTypePopup;
        dispatch({
            showackTypePopup: !showackTypePopup,
            type: ActionType.SHOWORHIDE_ACKTYPE_POPUP
        });
    },
    hideAckFilterPopup: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let showAckFilterPopup = getState().AckTrackingTemplate.showAckFilterPopup;
        dispatch({
            showAckFilterPopup: !showAckFilterPopup,
            type: ActionType.SHOWORHIDE_ACK_FILTER_POPUP
        });
    },
    hidetemplatePopup: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let showtemplatePopup = getState().AckTrackingTemplate.showtemplatePopup;
        dispatch({
            showackTypePopup: !showtemplatePopup,
            type: ActionType.SHOWORHIDE_TEMPLATE_POPUP
        });
    },
    OpenorhideAckTypePopup: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let showackTypePopup = getState().AckTrackingTemplate.showackTypePopup;
        let username = getState().authUser.login;
        dispatch({            
            type: ActionType.ACKNOTIFICATION_DATA_FETCH
        });
        AcktrackingAPI.GetAckTrackingNotificationData()
            .then((ackNotification: IDAcktrackingNotification[]) => {
                ackNotification.forEach((row, index) => {
                    if (row.lastUpdatedBy == username) {
                        row.isEditable = true;
                    }
                });
                const ackNotificationData = [];
                ackNotificationData.push({ name: 'Create New Ack Type', value: 0 });
                if (isArrayWithLength(ackNotification)) {
                    ackNotification.map((item: IDAcktrackingNotification, key) => {
                        ackNotificationData.push({ name: item.title, value: item.notificationACKId });
                    })
                }
                dispatch({
                    ackNotification,
                    ackNotificationData,
                    type: ActionType.ACKNOTIFICATION_DATA_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.ACKNOTIFICATION_DATA_FAIL
                });
            });
        AcktrackingAPI.GetAckTrackingTemplateDetails()
            .then((templatedropdown: IDAcktrackingTemplate[]) => {
                const templatedropdowndata = [];
                if (isArrayWithLength(templatedropdown)) {
                    templatedropdown.map((item: IDAcktrackingTemplate, key) => {
                        templatedropdowndata.push({ name: item.templateName, value: item.templateID });
                    })
                }
                dispatch({
                    templatedropdowndata,
                    type: ActionType.TEMPLATE_DROPDOWN_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.TEMPLATE_DROPDOWN_FETCH
                });
            });
    },

    OpenorhideAckFilterPopup: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let showAckFilterPopup = getState().AckTrackingTemplate.showAckFilterPopup;
        dispatch({
            type: ActionType.ACKNOTIFICATION_FILTER_DATA_FETCH
        });
        AcktrackingAPI.GetAckTrackingNotificationData()
            .then((ackNotification: IDAcktrackingNotification[]) => {
                const ackNotificationData = [];
                //ackNotificationData.push({ name: 'Create New Ack Type', value: 0 });
                if (isArrayWithLength(ackNotification)) {
                    ackNotification.map((item: IDAcktrackingNotification, key) => {
                        ackNotificationData.push({ name: item.title, value: item.notificationACKId });
                    })
                }
                dispatch({
                    ackNotification,
                    ackNotificationData,
                    type: ActionType.ACKNOTIFICATION_FILTER_DATA_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.ACKNOTIFICATION_FILTER_DATA_FAIL
                });
            });        
    },

    OpentemplatePopup: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        // By default when clicked on New template Ack category is selected as "Service" Type
        let template = {
            'templateID': 0, 'templateName': '', 'serviceTreeColsList': [{ 'id': 'service_Manager', 'label': 'service_Manager(GEM)' }, { 'id': 'serviceName', 'label': 'serviceName' }],
            'originalServiceTreeColsList': [{ 'id': 'service_Manager', 'label': 'service_Manager(GEM)' }, { 'id': 'serviceName', 'label': 'serviceName' }], 'isActive':true, 'isServiceSpecific':true, 'mappedAckCount' : 0 
        };        
        let templatesavebuttondisable = checkAllData(template, []);
        dispatch({
            templatesavebuttondisable,
            template,
            colorsData: colors,
            columncontroltypeoptions,
            servicecolsList: servicesList,
            changedColumns: [],
            columncoloring:[],
            type: ActionType.OPEN_TEMPLATE_POPUP
        });
    },
    MoveAckTemplateToArchive: (TemplateID): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.MOVEACKTEMPLATETOARCHIVE
        });
        AcktrackingAPI.MoveAckTemplateToArchive(TemplateID)
            .then((isSuccess: number) => {
                toast.success(renderToastifyMsg('Archived Successfully. Loading Data Please Wait',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });

                AcktrackingAPI.GetAckTrackingTemplateData()
                    .then((Acktemplatedata: IDAcktrackingTemplate[]) => {

                        dispatch({
                            Acktemplatedata,
                            type: ActionType.ACKTEMPLATE_DATA_SUCCESS
                        });
                    }).catch(error => {
                        dispatch({
                            type: ActionType.ACKTEMPLATE_DATA_FAIL
                        });
                    });

            }).catch(error => {
                toast.error(renderToastifyMsg('Archived Process Failed. Please Try Again.',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
                dispatch({
                    type: ActionType.MOVEACKTEMPLATETOARCHIVE_FAIL
                });
            });
    },
    handleAcktypeChange: (val: any): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let notificationdata = getState().AckTrackingTemplate.ackNotificationData;
        let ackNotificationlist = getState().AckTrackingTemplate.ackNotificationlist;
        let username = getState().authUser.login.split('@')[0];
        let isAdminorNot = getState().authUser.isAdmin;
        let templateid = 0;
        let templateDescription = '';
        let ackTypevalue = '';
        let Enableacktypesavebutton = true;
        let isAckTypeActive = true;
        let isTemplateActive = true;
        let updatedBy = '';
        if (val === 0) {

        }
        else {
            if (isArrayWithLength(notificationdata)) {
                ackNotificationlist.map((item: IDAcktrackingNotification, key) => {
                    if (item.notificationACKId == val) {
                        templateid = item.templateId;
                        templateDescription = item.description;
                        ackTypevalue = item.title;
                        isAckTypeActive = item.isActive;
                        isTemplateActive = item.isTemplateActive;
                    }
                })
            }
        }
        Enableacktypesavebutton = acktypesavebuttonenable(val, ackTypevalue, templateid, templateDescription);
        if (val != 0) {
            if (ackNotificationlist.find(x => x.notificationACKId == val).lastUpdatedBy.indexOf('@') != '-1') {
                updatedBy = ackNotificationlist.find(x => x.notificationACKId == val).lastUpdatedBy.split('@')[0];
            }
            else {
                updatedBy = ackNotificationlist.find(x => x.notificationACKId == val).lastUpdatedBy;
            }

            if ((updatedBy != username) && (!isAdminorNot)) {
                Enableacktypesavebutton = true;
            }
        }
       
        dispatch({
            val,
            Enableacktypesavebutton,
            ackTypevalue,
            templateid,
            templateDescription,
            isAckTypeActive,
            isTemplateActive,
            type: ActionType.ACKTYPE_VAL_CHANGE
        });
    },
    handleAcktypeChangeOnFilters: (val: any): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let notificationdata = getState().AckTrackingTemplate.ackNotificationData;
        let ackNotificationlist = getState().AckTrackingTemplate.ackNotificationlist;  
        let AckType = val;
        let isAckTypeActive = true;
        let isTemplateActive = true;
        if (val !== 0) {     
            if (isArrayWithLength(notificationdata)) {
                ackNotificationlist.map((item: IDAcktrackingNotification, key) => {
                    if (item.notificationACKId == val) {                       
                        isAckTypeActive = item.isActive;
                        isTemplateActive = item.isTemplateActive;
                    }
                })
            }

            AcktrackingAPI.GetAckTrackingFilterDetails(val)
                .then((ackFilterNames: IDAcktrackingFilter[]) => {
                    const ackFilterNameData = [];
                    //ackNotificationData.push({ name: 'Create New Ack Type', value: 0 });
                    if (isArrayWithLength(ackFilterNames)) {
                        ackFilterNames.map((item: IDAcktrackingFilter, key) => {
                            ackFilterNameData.push({ id: item.id, filterName: item.filterName, isActive: item.isActive });
                        })
                    }
                    dispatch({
                        isAckTypeActive,
                        isTemplateActive,
                        ackFilterNames,
                        ackFilterNameData,
                        AckType,
                        type: ActionType.ACK_FILTER_DATA_SUCCESS
                    });
                }).catch(error => {
                    dispatch({
                        type: ActionType.ACK_FILTER_DATA_FAIL
                    });
                });       
        }
        else {
           
        }
       
    },
    handleTemplateChange: (val): IAppThunkAction<ReduxAction> => (dispatch, getState) => {        
        let AckType = getState().AckTrackingTemplate.AckType;
        let ackTypevalue = getState().AckTrackingTemplate.ackTypevalue;      
        let templateDescription = getState().AckTrackingTemplate.templateDescription;
        let ackTemplateData = getState().AckTrackingTemplate.ackTemplateData;
        let Enableacktypesavebutton = acktypesavebuttonenable(AckType, ackTypevalue, val, templateDescription);
        let isTemplateActive = true;
        let ackNotificationlist = getState().AckTrackingTemplate.ackNotificationlist;
        let username = getState().authUser.login.split('@')[0];
        let isAdminorNot = getState().authUser.isAdmin;
        let updatedBy = '';
        if (isArrayWithLength(ackTemplateData)) {
            ackTemplateData.map((item: IDAcktrackingTemplate, key) => {
                if (item.templateID == val) {  
                    isTemplateActive = item.isActive;
                }
            })
        }

        if (AckType != 0) {
            if (ackNotificationlist.find(x => x.notificationACKId == AckType).lastUpdatedBy.indexOf('@') != '-1') {
                updatedBy = ackNotificationlist.find(x => x.notificationACKId == AckType).lastUpdatedBy.split('@')[0];
            }
            else {
                updatedBy = ackNotificationlist.find(x => x.notificationACKId == AckType).lastUpdatedBy;
            }

            if ((updatedBy != username) && (!isAdminorNot)) {
                Enableacktypesavebutton = true;
            }
        }

        dispatch({
            val,
            isTemplateActive,
            Enableacktypesavebutton,
            type: ActionType.TEMPLATE_VAL_CHANGE
        });
    },
    changeacktypetext: (val): IAppThunkAction<ReduxAction> => (dispatch, getState) => {  
        let AckType = getState().AckTrackingTemplate.AckType;      
        let templateid = getState().AckTrackingTemplate.templateid;
        let templateDescription = getState().AckTrackingTemplate.templateDescription;
        let Enableacktypesavebutton = acktypesavebuttonenable(AckType, val.target.value, templateid, templateDescription);
        dispatch({
            Enableacktypesavebutton,
            ackTypevalue:val.target.value,
            type: ActionType.ACKTYPE_VALUE_CHANGE
        });
    },
    Templatedescriptionchange: (val): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let AckType = getState().AckTrackingTemplate.AckType;
        let templateid = getState().AckTrackingTemplate.templateid;
        let ackTypevalue = getState().AckTrackingTemplate.ackTypevalue;
        let Enableacktypesavebutton = acktypesavebuttonenable(AckType, ackTypevalue, templateid, val.target.value);
        let ackNotificationlist = getState().AckTrackingTemplate.ackNotificationlist;
        let username = getState().authUser.login.split('@')[0];
        let isAdminorNot = getState().authUser.isAdmin;
        let updatedBy = '';
        if (AckType != 0) {
            if (ackNotificationlist.find(x => x.notificationACKId == AckType).lastUpdatedBy.indexOf('@') != '-1') {
                updatedBy = ackNotificationlist.find(x => x.notificationACKId == AckType).lastUpdatedBy.split('@')[0];
            }
            else {
                updatedBy = ackNotificationlist.find(x => x.notificationACKId == AckType).lastUpdatedBy;
            }

            if ((updatedBy != username) && (!isAdminorNot)) {
                Enableacktypesavebutton = true;
            }
        }
        dispatch({
            Enableacktypesavebutton,
            templateDescription: val.target.value,
            type: ActionType.TEMPLATE_DESCRIPTION_CHANGE
        });
    },
    templatedatachange: (val:any): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const { name, value, checked } = val.target;
        let templatedata = getState().AckTrackingTemplate.template;
        let changedColumns = getState().AckTrackingTemplate.changedColumns;
        let template = {
            'templateID': templatedata.templateID, 'templateName': templatedata.templateName, 'serviceTreeColsList': templatedata.serviceTreeColsList, 'originalServiceTreeColsList': templatedata.originalServiceTreeColsList, 'isActive': templatedata.isActive, IsUsed: false, ackcount: 0, 'isServiceSpecific': templatedata.isServiceSpecific, 'mappedAckCount': templatedata.mappedAckCount 
        };
        if (name === 'templateName') {
            template.templateName = value;
        }
        else if (name === 'serviceTreeColsList') {
            template.serviceTreeColsList = val.value;
            let cnt = 0;
            template.serviceTreeColsList.map((item, key) => {
                if ((item.id === "componentName")) {
                    cnt = cnt + 1;
                }
            });
            // Changing the Ack category programatically to Component if user checks the ComponentName in the ServiceCols List
            if (template.isServiceSpecific !== null) {
                if (cnt === 1) {
                    template.isServiceSpecific = false;
                }
                else {
                    template.isServiceSpecific = true;
                }
            }
        }
        else if (name === 'isActive') {
            template.isActive = checked;
        }
        else if (name === 'ackcategory') {
            let cnt = 0;
            template.serviceTreeColsList.map((item, key) => {
                if ((item.id === "componentName")) {
                    cnt = cnt + 1;
                }
            });
            if (cnt === 1 && value === 'Service') {
                // Logic to Add the componentName Column to the ServiceColsList
                const serviceTreeColsList = [...template.serviceTreeColsList];
                const indexOfComponentCol = serviceTreeColsList.findIndex(
                    col => col.label === 'componentName'
                );
                serviceTreeColsList.splice(indexOfComponentCol, 1);
                template.serviceTreeColsList = serviceTreeColsList;
                template.isServiceSpecific = value == 'Service' ? true : (value == 'Component' ? false : null);
            }   
            else if (cnt === 0 && value === 'Component') {
                template.serviceTreeColsList.push({ 'id': 'componentName', 'label': 'componentName' });
                template.isServiceSpecific = value == 'Service' ? true : (value == 'Component' ? false : null);
            }
            else {
                template.isServiceSpecific = value == 'Service' ? true : (value == 'Component' ? false : null);
            }
        }
        let templatesavebuttondisable = checkAllData(template, changedColumns);
        dispatch({
            templatesavebuttondisable,
            template,
            type: ActionType.SERVICETREE_COL_CHANGE
        });       
    },

    changeActiveStatus: (val: any): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const { name, checked } = val.target;  
        let isAckTypeActive = false;
        if (name === 'isActive') {
            isAckTypeActive = checked;
        }        
        dispatch({
            isAckTypeActive,
            type: ActionType.ACK_TYPE_IS_ACTIVE_CHANGE
        });
    },
    
    AddcolumnstoTemplate: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const changedColumns = [];
        let changedColumnsdata = getState().AckTrackingTemplate.changedColumns;
        let template = getState().AckTrackingTemplate.template;
        if (isArrayWithLength(changedColumnsdata)) {
            changedColumnsdata.map((item) => {
                changedColumns.push(item);
            });
        }
        changedColumns.push({ action: 'i', ColumnName: '', ControlValue: '', DisplayOrder: '', IsRequired: false, ControlType: '', IsColoringRequired: false, ColoringValue: '', index: changedColumns.length });
        let templatesavebuttondisable = checkAllData(template, changedColumns);
        dispatch({
            templatesavebuttondisable,
            changedColumns,
            type: ActionType.ADD_NEW_COLUMNS
        });
    },

    templatecolumnvalueschange: (val: any, index): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const { value, name } = val.target;       
        const changedColumns = [];
        const columncoloring = [];
        let changedColumnsdata = getState().AckTrackingTemplate.changedColumns;
        let columncoloringdata = getState().AckTrackingTemplate.columncoloring;
        let template = getState().AckTrackingTemplate.template;
        if (isArrayWithLength(columncoloringdata)) {
            columncoloringdata.map((item, key) => {
                if (item.val !== index)
                 columncoloring.push({ ColumnName: item.ColumnName, ControlValue: item.ControlValue, ColorPallete: item.ColorPallete, colorpalettecolor: item.colorpalettecolor, val: item.val });
            });
        } 
        if (isArrayWithLength(changedColumnsdata)) {
            changedColumnsdata.map((item, pkey) => {
                if (pkey === index) {
                    if (name === 'ControlType') {
                        item[name] = val.value;
                        if (item.ControlType !== 'Radio Button' && item.ControlType !== 'CheckBox' && item.ControlType !== 'Dropdown') {
                            item.ControlValue = '';
                            item.IsColoringRequired = false;

                        }
                    }
                    else if (name === 'IsRequired' || name === 'IsColoringRequired') {
                        item[name] = val.target.checked;
                    }
                    else {
                        if (name === 'ColumnName') {                            
                            item[name] = value;// (value.charAt(0).toUpperCase() + value.substr(1).toLowerCase());
                            item[name] = item[name].replace(",", "");
                        }
                        else {
                            item[name] = value;
                        }
                    }
                    if (item.IsColoringRequired) {

                        let ValuesList = item.ControlValue.split(',');
                        if (isArrayWithLength(ValuesList)) {
                            ValuesList.map((lst, key) => {
                                let valuematched = false;
                                let ColorPallete = '0';
                                let colorpalettecolor = '';
                                if (lst.trim() !== '') {
                                    if (isArrayWithLength(columncoloringdata)) {
                                        columncoloringdata.map((subitem, subkey) => {
                                            if (subitem.val === index && subitem.ControlValue === lst) {
                                                valuematched = true;
                                                ColorPallete = subitem.ColorPallete;
                                                colorpalettecolor = subitem.colorpalettecolor;
                                            }                                           
                                        });
                                    }
                                    columncoloring.push({ ColumnName: item.ColumnName, ControlValue: lst, ColorPallete: ColorPallete, colorpalettecolor: colorpalettecolor, val: pkey });
                                }
                            });
                        }

                    }
                   
                }
               
                changedColumns.push(item);
            });
        }
       
        let templatesavebuttondisable = checkAllData(template,changedColumns);
        dispatch({
            templatesavebuttondisable,
            columncoloring,
            changedColumns,
            type: ActionType.TEMPLATE_COLUMN_VALUES_CHANGE
        });
    },
    DeleteTemplateColumn: (index): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const changedColumns = [];
        const columncoloring = [];
        let template = getState().AckTrackingTemplate.template;
        let columncoloringdata = getState().AckTrackingTemplate.columncoloring;
        let changedColumnsdata = getState().AckTrackingTemplate.changedColumns;
        if (isArrayWithLength(changedColumnsdata)) {
            changedColumnsdata.map((item, key) => {
                if (key !== index) {
                    changedColumns.push(item);
                    if (isArrayWithLength(columncoloringdata)) {
                        columncoloringdata.map((clitem, clkey) => {
                            if (item.ColumnName === clitem.ColumnName  && key == clitem.val) {
                                columncoloring.push({ ColumnName: clitem.ColumnName, ControlValue: clitem.ControlValue, ColorPallete: clitem.ColorPallete, colorpalettecolor: clitem.colorpalettecolor, val: changedColumns.length-1 });

                            }

                        });
                    } 
                }
                
            });
        }
        //if (isArrayWithLength(columncoloringdata)) {
        //    columncoloringdata.map((item, key) => {
        //        if (item['val'] !== index) {
        //            columncoloring.push(item);
        //        }
             
        //    });
        //} 
        let templatesavebuttondisable = checkAllData(template, changedColumns);
        dispatch({
            templatesavebuttondisable,
            columncoloring,
            changedColumns,
            type: ActionType.TEMPLATE_COLUMN_VALUES_CHANGE
        });
    },
    colourpalletecolourchane: (val, row): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const columncoloring = [];        
        let columncoloringdata = getState().AckTrackingTemplate.columncoloring;
        let colorsData = getState().AckTrackingTemplate.colorsData;
        let colour = '';
        colorsData.map((item, key) => {
            if (item.name.toLowerCase() === val.value.toLowerCase()) {
                colour = item.hexcode;
            }
        });
        if (isArrayWithLength(columncoloringdata)) {
            columncoloringdata.map((item, key) => {
                if (item['val'] === row.val && item.ControlValue === row.ControlValue) {
                    item.ColorPallete = val.value;
                    item.colorpalettecolor = colour;
                }
                columncoloring.push(item);
            });
        } 
        dispatch({
            columncoloring,
            type: ActionType.COLOUR_PALLETE_VAL_CHANGE
        });
    },
    customcolourchange: (val, row): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const columncoloring = [];
        let columncoloringdata = getState().AckTrackingTemplate.columncoloring;       
       
        if (isArrayWithLength(columncoloringdata)) {
            columncoloringdata.map((item, key) => {
                if (item['val'] === row.val && item.ControlValue === row.ControlValue) {                   
                    item.colorpalettecolor = val.target.value;
                    item.ColorPallete = 'Custom';
                }
                columncoloring.push(item);
            });
        }
        dispatch({
            columncoloring,
            type: ActionType.COLOUR_PALLETE_VAL_CHANGE
        });
    },
    TemplateEdit: (row): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var servicenames = row.serviceTreeColsList.split(',');
        let changedColumns = [];
        let selectServicesList = [];
        let columncoloring = [];
        if (isArrayWithLength(servicenames)) {
            servicenames.map((item, key) => {
                if (item === 'service_Manager') {
                    selectServicesList.push({ id: item, label: item + '(GEM)' });
                }
                else {
                    selectServicesList.push({ id: item, label: item });
                }
            });
        }
        let template = {
            'templateID': row.templateID, 'templateName': row.templateName, 'serviceTreeColsList': selectServicesList, 'originalServiceTreeColsList': selectServicesList, 'isActive': row.isActive, IsUsed: row.isUsed, ackcount: row.ackcount, 'isServiceSpecific': row.isServiceSpecific, 'mappedAckCount': row.mappedAckCount 
        };
        // Logic to check if the ServiceTreeCols List has 'componentName' column if yes then 'isServiceSpecific' flag is set to 'Component' else to 'Service'
        if (isArrayWithLength(template.serviceTreeColsList)) {
            let cnt = 0;
            template.serviceTreeColsList.map((item, key) => {
                if ((item.id === "componentName")) {
                    cnt = cnt + 1;
                }
            });
            if (cnt == 1) {
                template.isServiceSpecific = false;
            }
            else {
                template.isServiceSpecific = true;
            }
        }
        let colslist = JSON.parse(row.templateJSON);
        if (isArrayWithLength(colslist)) {
            colslist.map((item, key) => {
                changedColumns.push({ action: 'u', ColumnName: item.ColumnName, ControlValue: item.ControlValue, DisplayOrder: item.DisplayOrder, IsRequired: item.IsRequired, ControlType: item.ControlType, IsColoringRequired: item.IsColoringRequired, ColoringValue: item.ColoringValue, index: key });
                if (item.IsColoringRequired) {
                    let ValuesList = item.ControlValue.split(',');
                    let colourvalues = item.ColoringValue.split(',');
                    if (isArrayWithLength(ValuesList)) {
                        ValuesList.map((lst, ckey) => {
                            if (lst.trim() !== '') {
                                let palletval = 'Custom';
                                colors.map((cl, inde) => {
                                    if (cl.hexcode === colourvalues[ckey]) {
                                        palletval = cl.value;
                                    }
                                });
                                columncoloring.push({ ColumnName: item.ColumnName, ControlValue: lst, ColorPallete: palletval, colorpalettecolor: colourvalues[ckey], val: key});
                            }
                        });
                    }

                }
            });
        }
       
        let templatesavebuttondisable = checkAllData(template, changedColumns);
        dispatch({
            templatesavebuttondisable,
            template,
            colorsData: colors,
            columncontroltypeoptions,
            servicecolsList: servicesList,
            changedColumns: changedColumns,
            columncoloring: columncoloring,
            type: ActionType.OPEN_TEMPLATE_POPUP
        });
    },
};
const acktypesavebuttonenable = (acktype, newacktype, templateid, description) => {
    if (isNullOrUndefined(acktype)) {
        return true;
    }
    else if (isNullOrUndefined(newacktype)) {
        return true;
    }
    if (templateid === 0 || isNullOrUndefined(templateid)) {
        return true;
    }
    if (isNullOrUndefined(description)) {
        return true;
    }
    else {
        return false;
    }
}

const checkAllData = (template, changedColumns)=> {
    let validcolsdata = true;
    if (isNullOrUndefined(template.isServiceSpecific)) {
        return true;
    }
    if (isNullOrUndefined(template.templateName)) {
        return true;
    }
    if (!isArrayWithLength(template.serviceTreeColsList)) {
        return true;
    }
    else if (isArrayWithLength(template.serviceTreeColsList)) {
        let cnt = 0;
        template.serviceTreeColsList.map((item, key) => {
            if ((item.id === "service_Manager") || (item.id === "serviceName")) {
                cnt = cnt + 1;
            }
        });
        if (cnt !== 2) {
            return true;
        }
    }
    if (template.isServiceSpecific === false && isArrayWithLength(template.serviceTreeColsList)) {
        let cnt = 0;
        template.serviceTreeColsList.map((item, key) => {
            if ((item.id === "componentName")) {
                cnt = cnt + 1;
            }
        });
        // Checking the Ack category if it is "Component" and User not selected componentName in Service Cols List then Disabling Save
        if (cnt === 0) {
            return true;
        }
    }
    if (!isArrayWithLength(changedColumns)) {
        return true;
    }
    else if (isArrayWithLength(changedColumns)) {
        changedColumns.map((item, key) => {
            if (validcolsdata == true) {
                if (isNullOrUndefined(item.ColumnName)) {
                    validcolsdata = false;
                }
                else if (isNullOrUndefined(item.ControlType)) {
                    validcolsdata = false;
                }
                else if (item.DisplayOrder == undefined || item.DisplayOrder == '' || item.DisplayOrder == null) {
                    validcolsdata = false;
                }
                else if (item.ControlType === 'Radio Button' || item.ControlType === 'CheckBox' || item.ControlType === 'Dropdown') {
                    if (isNullOrUndefined(item.ControlValue)) {
                        validcolsdata = false;
                    }
                }
                
            }
       
        });
       
    }

    if (validcolsdata == true) {
        return false;
    }
    else {
        return true;
    }
};

