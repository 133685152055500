import { IAppThunkAction, ReduxAction } from '../';
import { ActionType, IADOApprovalTaskDetails, IADOAreaPaths, IDropdownOption } from './types';
import { ADOTaskCreationAPI, CommonAPI } from '../../api';
import { isNullOrUndefined, renderToastifyMsg } from '../../utils';
import { toast } from 'react-toastify';
import moment from 'moment';

export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId).
                then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }
    },
    setdescription: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            description: e,
            type: ActionType.SET_DESCRIPTION
        });
    },
    settitle: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            title: e,
            type: ActionType.SET_TITLE
        });
    },
    setAssignedTo: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            assignedTo: e,
            type: ActionType.SET_ASSIGNEDTO
        });
    },
    setareaPath: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const areaPath = getState().ADOTaskCreation.areaPathList.find(x => x.value == e.value).path;
        const selectedAreaPath = getState().ADOTaskCreation.areaPathList.find(x => x.value == e.value).value;
        const setTagValue = getState().ADOTaskCreation.tags.find(x => x.value == getState().ADOTaskCreation.areaPathList.find(x => x.value == e.value).tagId).value;
        const tagArray = [];
        tagArray.push(setTagValue);
        dispatch({
            areaPath: areaPath,
            areaPathSelected: selectedAreaPath,
            tagsValue: tagArray,
            type: ActionType.SET_AREAPATH
        });
    },
    setEta: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            eta: e,
            type: ActionType.SET_ETA
        });
    },
    handleTagsChange: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            tagsValue: e,
            type: ActionType.SET_TAGS
        });
    },
    getAdoTags: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        ADOTaskCreationAPI.GetAdoTags()
            .then((adoTags: object) => {
                dispatch({
                    tags: adoTags,
                    type: ActionType.GET_ADOTAGS_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.SET_LOADING_FALSE
                });
            });
    },
    getAreaPath: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        ADOTaskCreationAPI.GetAdoAreaPaths()
            .then((areaPath: IADOAreaPaths[]) => {
                let areaPaths = [];
                areaPath.map((obj: any) => {
                    areaPaths.push({ 'name': obj.name, 'value': obj.value })
                });      
                dispatch({
                    areaPathDropdown: areaPaths,
                    areaPathList: areaPath,
                    type: ActionType.GET_AREAPATH_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.SET_LOADING_FALSE
                });
            });
    },
    getApprovalTasksDetails: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        ADOTaskCreationAPI.ADOApprovalTaskDetails()
            .then((approvalTasksDetails: IADOApprovalTaskDetails[]) => {
                approvalTasksDetails = approvalTasksDetails.map((e) => {
                    e.isSelected = false;
                    return e;
                })
                dispatch({
                    approvalTasksDetails: approvalTasksDetails,
                    type: ActionType.GET_APPROVALTASKSDETAILS
                });
            }).catch(error => {
                dispatch({
                    approvalTasksDetails: [],
                    type: ActionType.GET_APPROVALTASKSDETAILS
                });
            });
    },
    checkAllValues: (check: boolean): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let approvalTasksDetails = getState().ADOTaskCreation.approvalTasksDetails;
        let approvalDisabled: boolean = true;
        approvalTasksDetails = approvalTasksDetails.map((r) => {
            r.isSelected = check;
            if (r.isSelected == true) {
                approvalDisabled= false;
            }
            return r;
        });
        dispatch({
            approvalDisabled: approvalDisabled,
            approvalTasksDetails: approvalTasksDetails,
            type: ActionType.SET_CHECKALL
        });
    },
    updatecheck: (row: IADOApprovalTaskDetails): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let approvalTasksDetails = getState().ADOTaskCreation.approvalTasksDetails;
        let approvalDisabled: boolean = true;
        approvalTasksDetails = approvalTasksDetails.map((r) => {
            if (r.id == row.id) { r.isSelected = !r.isSelected }
            if (r.isSelected == true) {
                approvalDisabled = false;
            }
            return r;
        });
        dispatch({
            approvalDisabled: approvalDisabled,
            approvalTasksDetails: approvalTasksDetails,
            type: ActionType.UPDATE_CHECKVALUE
        });
    },
    approveTask: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let approvalTasksDetails = getState().ADOTaskCreation.approvalTasksDetails;
        let temp = [];
        let failurecount = 0;
        let count = 0
        approvalTasksDetails.forEach((r) => {
            if (r.isSelected) {
                count = count + 1;
                ADOTaskCreationAPI.ApproveADOTask(r.id, r.taskId, r.assigendTo, r.isApproved, r.title, escape(r.emailcontent), getState().authUser.login)
                    .then((response: string) => {
                        count = count - 1;
                        temp.push({ 'taskId': r.taskId, 'message': response });
                        if (response != 'Success') {
                            failurecount = failurecount + 1;
                        }
                        if (count == 0) {
                            dispatch({
                                type: ActionType.SET_LOADING_FALSE
                            });
                            if (temp.length > 0 && failurecount > 0) {
                                toast.error(renderToastifyMsg(failurecount + " Out of " + approvalTasksDetails.length + "Task Approval failed. Please retry",
                                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4000 });
                            }
                            else {
                                toast.success(renderToastifyMsg("Task approved and sent email successfully",
                                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4000 });
                            }
                        }

                    })
            }
        });

    },
    CreateTask: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let areaPath = '';
        let id = '';
        let errorMsg = '';
        let selectedTags = '';
        let selectedareaPath = getState().ADOTaskCreation.areaPath;
        let selectedtitle= getState().ADOTaskCreation.title;
        let selecteddescription = getState().ADOTaskCreation.description;
        let selectedassignedTo = getState().ADOTaskCreation.assignedTo;
        let selectedtagsValue = getState().ADOTaskCreation.tagsValue;
        let selectedeta = getState().ADOTaskCreation.eta;

        if (getState().ADOTaskCreation.tagsValue != "") {
            getState().ADOTaskCreation.tagsValue.forEach((r) => {
                if (selectedTags == '') {
                    selectedTags = getState().ADOTaskCreation.tags.find(x => x.value == r).name.trim();
                }
                else {
                    selectedTags = selectedTags + ";" + getState().ADOTaskCreation.tags.find(x => x.value == r).name.trim();
                }
            })
        }
        ADOTaskCreationAPI.CreateTask(selectedareaPath, selectedtitle, selecteddescription.toString('html'), selectedassignedTo, getState().authUser.login, moment(selectedeta).format('YYYY-MM-DD'), selectedTags)
            .then((response) => {
                if (response.length > 1) {
                    if (response.length == 2) {
                        toast.error(renderToastifyMsg(response[0] + response[1] + " . Looks the inputted data is incorrect. Please verify and retry it.",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4000 });
                        errorMsg = response[0] + response[1] + " . Looks the inputted data is incorrect. Please verify and retry it.";
                        dispatch({
                            areaPath: selectedareaPath,
                            title: selectedtitle,
                            description: selecteddescription,
                            assignedTo: selectedassignedTo,
                            tagsValue: selectedtagsValue,
                            eta: selectedeta,
                            errorMsg: errorMsg,
                            type: ActionType.ADO_TASKCREATION_FAILURE
                        });
                    }
                    else {
                        id = response[2];
                        toast.success(renderToastifyMsg("Task created succesfully. Task ID: " + id,
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4000 });
                        toast.error(renderToastifyMsg(response[0] + response[1] + " Task created successfully but error occured while recording in DataBase. Please note the Task ID",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4000 });
                        errorMsg = response[0] + response[1] + " Task created successfully but error occured while recording in DataBase. Please note the Task ID";
                        dispatch({
                            id: id,
                            previousTitle: getState().ADOTaskCreation.title,
                            errorMsg: errorMsg,
                            type: ActionType.CREATE_ADOTASK
                        });
                    }
                }
                else if (response.length == 1) {
                    id = response[0];
                    toast.success(renderToastifyMsg("Task created succesfully. Task ID: " + id,
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4000 });
                    dispatch({
                        id: id,
                        previousTitle: getState().ADOTaskCreation.title,
                        errorMsg: errorMsg,
                        type: ActionType.CREATE_ADOTASK
                    });
                }
               
            }).catch(error => {
                dispatch({
                    areaPath: getState().ADOTaskCreation.areaPath,
                    title: getState().ADOTaskCreation.title,
                    description: getState().ADOTaskCreation.description,
                    assignedTo: getState().ADOTaskCreation.assignedTo,
                    tagsValue: getState().ADOTaskCreation.tagsValue,
                    eta: getState().ADOTaskCreation.eta,
                    type: ActionType.ADO_TASKCREATION_FAILURE
                });
            });
    }
}