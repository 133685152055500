import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IPlatformToolState } from './types';

const initialState = Object.freeze<IPlatformToolState>({ 
    isLoading: false,
    platformToolData: [],
    IsplatformToolDataloaded:false
});

export const reducer = (
    state: IPlatformToolState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) { 
        case ActionType.GET_PLATFORMTOOLS_REQUEST:
            return {
                ...state,
                isLoading: true,
                IsplatformToolDataloaded: false,
                platformToolData: []
            };
        case ActionType.GET_PLATFORMTOOLS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                IsplatformToolDataloaded: true,
                platformToolData: action.platformtooldata
            };
        case ActionType.GET_PLATFORMTOOLS_FAIL:
            return {
                ...state,
                isLoading: false,
                IsplatformToolDataloaded: true,
                platformToolData: []
            };
        case ActionType.RESET_STATE:
            return initialState;

        default:
            return state;
    }
};
