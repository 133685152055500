import React, { useState, Fragment, useRef } from 'react';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import {
    IPropAllPirData
} from '../../../store/WIP-SHR/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faTrashAlt, faPencilAlt, faEdit, faTrash, faRedoAlt, faTruckLoading, faArrowAltCircleLeft, faPlus, faSave, faBan, faCalendar, faUnlock, faLock, faCopy, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import DatePicker, { getDay } from 'react-datepicker'
import moment from 'moment';
import { OrderList } from 'primereact/orderlist';
import { Accordion } from 'react-bootstrap'
import { Row, Col, Button } from 'reactstrap';

type PostIncidentReviewsProps = {
    weeklyIDSHRData: any,
    valchange: any,
    CancelEditPIRRow: any,
    OnPIRTextboxchange: any,
    SavePostIncidentData: any,
    DeletePIRData: any,
    EnableAddforPIRData: any,
    EditPirReviewData: any,
    isPIREditenabled: boolean,
    handleKeypress: any,
    PIRDate: any,
    MaxDate: any,
    MeetingDate: any,
    handlePIRDateChange: any,
    isAdmin: any,
    IsPirRereviewUser: boolean,
    isLocked: boolean,
    EditLockforPIR: any,
    UpdateweeklyIDSHRDataOrder: any,
    CopyTableData: any,
    isPIRLockDisabledOnEdit: boolean,
    piraccordiontoggle: any,
    setpiraccordiontoggledata: any
};

const PostIncidentReviews = React.memo<PostIncidentReviewsProps>(({
    weeklyIDSHRData,
    valchange,
    CancelEditPIRRow,
    OnPIRTextboxchange,
    SavePostIncidentData,
    DeletePIRData,
    EnableAddforPIRData,
    EditPirReviewData,
    isPIREditenabled,
    handleKeypress,
    PIRDate,
    MaxDate,
    MeetingDate,
    handlePIRDateChange,
    isAdmin,
    IsPirRereviewUser,
    isLocked,
    EditLockforPIR,
    UpdateweeklyIDSHRDataOrder,
    CopyTableData,
    isPIRLockDisabledOnEdit,
    piraccordiontoggle,
    setpiraccordiontoggledata
}) => {

    const isThursday = (date) => {
        var day = new Date(moment(date).format('MM/DD/YYYY')).getDay();
        return day == 1;
    };

    const [presenterEdit, setpresenterEdit] = useState('')
    const [occuredRingEdit, setoccuredRingEdit] = useState('')
    const [pirEdit, setpirEdit] = useState('')

    const callvalchange = (e, row) => {
        setpresenterEdit(row.presenter)
        setoccuredRingEdit(row.occuredRing)
        setpirEdit(row.pir)
        valchange(e, row)
    }

    const callEnableAddforPIRData = () => {
        setpresenterEdit('')
        setoccuredRingEdit('')
        setpirEdit('')
        EnableAddforPIRData()
    }

    const callSavePostIncidentData = (e, row) => {
        row.presenterEdit = presenterEdit
        row.occuredRingEdit = occuredRingEdit
        row.pirEdit = pirEdit
        SavePostIncidentData(e, row, 'Updated')
    }

    if (weeklyIDSHRData.filter(x => x.id === -1).length <= 0 && isAdmin == true) {
        weeklyIDSHRData.splice(0, 0, { id: -1 });
    }

    if (weeklyIDSHRData.length <= 1) {
        weeklyIDSHRData.splice(1, 0, { id: -2 });
    }
    else {
        weeklyIDSHRData.filter((row) => { return row.id != -2 })
    }

    const itemTemplate = (item) => {
        return (
            <React.Fragment>
                {
                    (item.id == -1 || item.id == -2) ? (item.id == -1) ?
                        <tr>
                            <td style={{ width: '11%' }}><b>{(isPIREditenabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Presenter (must be GEM/EM)</b></td>
                            <td style={{ width: '11%' }}><b>Owning Service</b></td>
                            <td style={{ width: '8.5%' }}><b>Services Impacted</b></td>
                            <td style={{ width: '8.5%' }}><b>Incident Id</b></td>
                            <td style={{ width: '14%' }}><b>PIR Title</b></td>
                            <td style={{ width: '6%' }}><b>{(isPIREditenabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Occurred in what Ring?</b></td>
                            <td style={{ width: '4.5%' }}><b>TTD</b></td>
                            <td style={{ width: '4.5%' }}><b>TTE</b></td>
                            <td style={{ width: '4.5%' }}><b>TTM</b></td>
                            <td style={{ width: '4.5%' }}><b>TTN</b></td>
                            <td style={{ width: '8.5%' }}><b>Document Link</b></td>
                            <td style={{ width: '6%' }}><b>{(isPIREditenabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}PIR Number</b></td>
                            <td style={{ width: '8.5%', textAlign: 'center' }}><b>Actions</b></td>
                        </tr> :
                        <tr><td style={{ width: '100%', backgroundColor: 'azure' }} className="clsnodatamessgefortable">No Data Available</td></tr>
                        :
                        <tr style={(isPIREditenabled) ? { backgroundColor: 'azure', color: 'black' } : {}}>

                            <td style={{ width: '11%' }}>
                                <div>{(item.isEdit) ? <textarea className="form-control" onChange={e => setpresenterEdit(e.target.value)} value={presenterEdit} name="presenterEdit" rows={1} cols={50} /> : item.presenter}</div>
                            </td>
                            <td style={{ width: '11%' }}>{item.serviceName}</td>
                            <td style={{ width: '8.5%' }}>{item.servicesImpacted}</td>
                            <td style={{ width: '8.5%' }}>
                                <span>
                                    {(item.incidentId != null || item.incidentId != '') ? <a style={(isPIREditenabled) ? { color: 'black' } : {}}>ICM#</a> : ''}
                                    <a style={(isPIREditenabled) ? { color: '#007ad9' } : {}} href={"https://portal.microsofticm.com/imp/v3/incidents/details/" + item.incidentId + "/home"} target="_blank"> {item.incidentId}</a>
                                </span>
                            </td>
                            <td style={{ width: '14%' }}>{item.summary}</td>

                            <td style={{ width: '6%' }}>
                                <div className="Removepadding">{(item.isEdit) ? <input type="text" className="form-control" value={occuredRingEdit} name="occuredRingEdit" onChange={e => setoccuredRingEdit(e.target.value)} /> : item.occuredRing}</div>
                            </td>
                            <td style={{ width: '4.5%' }}>{item.ttd} </td>
                            <td style={{ width: '4.5%' }}>{item.tte}</td>
                            <td style={{ width: '4.5%' }}>{item.ttm}</td>
                            <td style={{ width: '4.5%' }}>{item.ttn}</td>
                            <td style={{ width: '8.5%' }}>
                                <React.Fragment>{isArrayWithLength(item.resources) ?
                                    <React.Fragment> {isArrayWithLength(item.resources[0].link.split(',')) ?
                                        <React.Fragment>{item.resources[0].link.split(',').map((his, key) => (
                                            <span>
                                                <a style={(isPIREditenabled) ? { color: '#007ad9' } : {}} href={his} target="_blank">{item.resources[0].description.split(',')[key]}</a>   <br />
                                            </span>))}
                                        </React.Fragment> : <React.Fragment></React.Fragment>

                                    } </React.Fragment> : <React.Fragment></React.Fragment>}</React.Fragment>
                            </td>
                            <td style={{ width: '6%' }}>
                                <div>{(item.isEdit) ? <input type="text" className="form-control" value={pirEdit} name="pirEdit" onKeyDown={handleKeypress} onChange={e => setpirEdit(e.target.value)} /> :
                                    <a style={(isPIREditenabled) ? { color: '#007ad9' } : {}} href={'https://portal.microsofticm.com/imp/v3/incidents/postmortem/' + item.pir} target="_blank"> {item.pir}</a>

                                }</div>
                            </td>
                            <td style={{ width: '8.5%' }} className="centericons">
                                {
                                    (!item.isEdit)
                                        ?
                                        <React.Fragment>
                                            <span ><span onClick={e => callvalchange(e, item)}>
                                                <FontAwesomeIcon title='Click to edit the item' className="bluecoloricons Iconsstyles" icon={faPencilAlt} /></span> </span> &nbsp;
                                            <span onClick={e => DeletePIRData(e, item)}> <FontAwesomeIcon title='Click to Delete the item' className="redcolorIcons Iconsstyles" icon={faTrash} /></span> &nbsp;
                                            <FontAwesomeIcon title='Click to Refresh the  item' className="bluecoloricons Iconsstyles" icon={faRedoAlt} onClick={e => SavePostIncidentData(e, item, 'Refreshed')} /> &nbsp;
                                            {(isAdmin === true) ?
                                                <span>
                                                    <label htmlFor={'PIRDate' + item.id}><FontAwesomeIcon title='Click to Change the  Meeting Date' className="bluecoloricons Iconsstyles" icon={faCalendar} /></label>
                                                    <DatePicker
                                                        selected={PIRDate}
                                                        id={"PIRDate" + item.id}
                                                        onChange={PIRDate => handlePIRDateChange(item, PIRDate)}
                                                        filterDate={isThursday}
                                                        maxDate={MaxDate}
                                                        className="datepickerCalendar"
                                                        popperPlacement="bottom-end"
                                                        popperModifiers={{
                                                            offset: {
                                                                enabled: true,
                                                                offset: "55px, 5px"
                                                            },
                                                            preventOverflow: {
                                                                enabled: true,
                                                                escapeWithReference: false,
                                                                boundariesElement: "viewport"
                                                            }
                                                        }}
                                                    />
                                                </span>
                                                : <React.Fragment></React.Fragment>
                                            }
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <FontAwesomeIcon title='Save Changes' className="bluecoloricons Iconsstyles" onClick={e => callSavePostIncidentData(e, item)} icon={faSave} /> &nbsp;
                                            <FontAwesomeIcon title='Cancel Edit' className="Iconsstyles" onClick={e => CancelEditPIRRow(e, item)} icon={faBan} />
                                        </React.Fragment>
                                }
                            </td>
                        </tr>
                }
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <br />
            <Accordion>
                <Row>
                    <Col lg="6">
                        <div><h5 className="headertext">Post Incident Reviews (PIRs) to be reviewed in Platform SHR</h5>
                        </div>
                        <div className='WSHRlinksText'>Presentation template can be found&nbsp;<a className='WSHRlinks' href='https://aka.ms/ID_PIR_Summary_WISHR' target="_blank">here</a>
                        </div>
                    </Col>

                    <Col lg="6">
                        <div style={{ height: '20px' }}><h5 className="headertext"></h5>
                        </div>
                        <div className="divaddnew">
                            <span style={{ display: isLocked ? 'inline' : 'none', color: 'red' }}>Locked By Admin  </span>
                            <Button className='PIRAddbuttons' hidden={(weeklyIDSHRData.length > 1 ? false : true)} onClick={() => CopyTableData('postIncidents')}> <FontAwesomeIcon icon={faCopy} /> &nbsp;Copy Table</Button>&nbsp;
                            <Button className='PIRAddbuttons' disabled={isLocked} onClick={callEnableAddforPIRData}> <FontAwesomeIcon icon={faPlus} /> &nbsp;Add New PIR</Button>&nbsp;
                            <Button className='PIRAddbuttons' hidden={!isLocked} disabled={!isAdmin || isPIRLockDisabledOnEdit} onClick={EditLockforPIR}><FontAwesomeIcon icon={faLock} /></Button>
                            <Button className='PIRAddbuttons' hidden={isLocked} disabled={!isAdmin || isPIRLockDisabledOnEdit} onClick={EditLockforPIR}><FontAwesomeIcon icon={faUnlock} /></Button>
                            <Accordion.Toggle as={Button} eventKey="0" onClick={e => setpiraccordiontoggledata(!piraccordiontoggle)} style={{ background: 'none' }}>
                                <span style={{ float: 'right', padding: '10px' }}>
                                    {(piraccordiontoggle) ? <FontAwesomeIcon title='Collapse All' icon={'minus-circle'} className='icon-format-collapseinternalEscalation' /> : <FontAwesomeIcon title='Expand All' icon={'plus-circle'} className='icon-format-expandinternalEscalation' />
                                    }
                                </span>
                            </Accordion.Toggle>
                        </div>
                    </Col>
                </Row>
                <div style={{ display: (piraccordiontoggle) ? 'block' : 'none' }}>
                    {(!isAdmin == true) ?
                        <Row className="tblpadding">
                            <Col lg="12">
                                <table id="tblPIR" className="tblPIR tblborder">
                                    <thead>
                                        <tr>
                                            <th className="thwidth280">{(isPIREditenabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Presenter (must be GEM/EM)</th>
                                            <th className="thwidth280">Owning Service</th>
                                            <th className="thwidth200">Services Impacted</th>
                                            <th className="thwidth200">Incident Id</th>
                                            <th className="thwidth300">PIR Title</th>
                                            <th className="thwidth120">{(isPIREditenabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Occurred in what Ring?</th>
                                            <th className="thwidth100">TTD</th>
                                            <th className="thwidth100">TTE</th>
                                            <th className="thwidth100">TTM</th>
                                            <th className="thwidth100">TTN</th>
                                            <th className="thwidth200">Document Link</th>
                                            <th className="thwidth120">{(isPIREditenabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}PIR Number</th>
                                            {(isAdmin === true) ?
                                                <th className="thwidth120">{(isPIREditenabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Order</th>
                                                : <React.Fragment></React.Fragment>
                                            }
                                            <th className="thwidth200">Actions</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!isArrayWithLength(weeklyIDSHRData)
                                            ?
                                            <React.Fragment>
                                                <tr>
                                                    <td colSpan={15} className="clsnodatamessgefortable">
                                                        No Data Available
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                            : weeklyIDSHRData.map((row: IPropAllPirData, index) => (
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <td>
                                                            <div>{(row.isEdit) ? <textarea className="form-control" onChange={e => OnPIRTextboxchange(e, row)} value={row.presenterEdit} name="presenterEdit" rows={1} cols={50} /> : row.presenter}</div>
                                                        </td>
                                                        <td>{row.serviceName}</td>
                                                        <td>{row.servicesImpacted}</td>
                                                        <td>
                                                            <span>
                                                                {(row.incidentId != null || row.incidentId != '') ? <a>ICM#</a> : ''}
                                                                <a href={"https://portal.microsofticm.com/imp/v3/incidents/details/" + row.incidentId + "/home"} target="_blank"> {row.incidentId}</a>
                                                            </span>
                                                        </td>
                                                        <td>{row.summary}</td>

                                                        <td>
                                                            <div className="Removepadding">{(row.isEdit) ? <input type="text" className="form-control" value={row.occuredRingEdit} name="occuredRingEdit" onChange={e => OnPIRTextboxchange(e, row)} /> : row.occuredRing}</div>
                                                        </td>
                                                        <td>{row.ttd} </td>
                                                        <td>{row.tte}</td>
                                                        <td>{row.ttm}</td>
                                                        <td>{row.ttn}</td>
                                                        <td>
                                                            <React.Fragment>{isArrayWithLength(row.resources) ?
                                                                <React.Fragment> {isArrayWithLength(row.resources[0].link.split(',')) ?
                                                                    <React.Fragment>{row.resources[0].link.split(',').map((his, key) => (
                                                                        <span>
                                                                            <a style={(isPIREditenabled) ? { color: '#007ad9' } : {}} href={his} target="_blank">{row.resources[0].description.split(',')[key]}</a>   <br />
                                                                        </span>))}
                                                                    </React.Fragment> : <React.Fragment></React.Fragment>

                                                                } </React.Fragment> : <React.Fragment></React.Fragment>}</React.Fragment>
                                                        </td>
                                                        <td>
                                                            <div>{(row.isEdit) ? <input type="text" className="form-control" value={row.pirEdit} name="pirEdit" onKeyDown={handleKeypress} onChange={e => OnPIRTextboxchange(e, row)} /> :
                                                                <a href={'https://portal.microsofticm.com/imp/v3/incidents/postmortem/' + row.pir} target="_blank"> {row.pir}</a>

                                                            }</div>
                                                        </td>
                                                        {
                                                            (isAdmin === true) ?
                                                                <td>


                                                                    <div>{(row.isEdit) ? <input type="number" min="0" max="32000" className="form-control" value={row.displayOrderEdit} name="displayOrderEdit" onKeyDown={handleKeypress} onChange={e => OnPIRTextboxchange(e, row)} /> :
                                                                        <div>{row.displayOrder}</div>
                                                                    }</div>
                                                                </td>
                                                                : <React.Fragment></React.Fragment>
                                                        }
                                                        <td className="centericons">
                                                            {
                                                                (!row.isEdit)
                                                                    ?
                                                                    <React.Fragment>
                                                                        <span ><span onClick={e => valchange(e, row)}>
                                                                            <FontAwesomeIcon title='Click to edit the Row' className="bluecoloricons Iconsstyles" icon={faPencilAlt} /></span> </span> &nbsp;
                                                                        <span onClick={e => DeletePIRData(e, row)}> <FontAwesomeIcon title='Click to Delete the Row' className="redcolorIcons Iconsstyles" icon={faTrash} /></span> &nbsp;
                                                                        <FontAwesomeIcon title='Click to Refresh the  Row' className="bluecoloricons Iconsstyles" icon={faRedoAlt} onClick={e => SavePostIncidentData(e, row, 'Refreshed')} /> &nbsp;
                                                                        {(isAdmin === true) ?
                                                                            <span>
                                                                                <label htmlFor={'PIRDate' + index}><FontAwesomeIcon title='Click to Change the  Meeting Date' className="bluecoloricons Iconsstyles" icon={faCalendar} /></label>
                                                                                <DatePicker
                                                                                    selected={PIRDate}
                                                                                    id={"PIRDate" + index}
                                                                                    onChange={PIRDate => handlePIRDateChange(row, PIRDate)}
                                                                                    filterDate={isThursday}
                                                                                    maxDate={MaxDate}
                                                                                    className="datepickerCalendar"
                                                                                    popperPlacement="bottom-end"
                                                                                    popperModifiers={{
                                                                                        offset: {
                                                                                            enabled: true,
                                                                                            offset: "55px, 5px"
                                                                                        },
                                                                                        preventOverflow: {
                                                                                            enabled: true,
                                                                                            escapeWithReference: false,
                                                                                            boundariesElement: "viewport"
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </span>
                                                                            : <React.Fragment></React.Fragment>
                                                                        }
                                                                    </React.Fragment>
                                                                    :
                                                                    <React.Fragment>
                                                                        <FontAwesomeIcon title='Save Changes' className="bluecoloricons Iconsstyles" onClick={e => SavePostIncidentData(e, row, 'Updated')} icon={faSave} /> &nbsp;
                                                                        <FontAwesomeIcon title='Cancel Edit' className="Iconsstyles" onClick={e => CancelEditPIRRow(e, row)} icon={faBan} />
                                                                    </React.Fragment>
                                                            }
                                                        </td>

                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        :
                        <Row>
                            <div className={weeklyIDSHRData.length > 2 && !isPIREditenabled ? "WeeklyIDSHRTableOuter" : "WeeklyIDSHRTableOuter NoArrowOrderList"}>
                                <OrderList
                                    value={weeklyIDSHRData}
                                    id="WeeklyIDSHRTableid"
                                    listStyle={{ height: "auto" }}
                                    itemTemplate={itemTemplate}
                                    onChange={(e) => { UpdateweeklyIDSHRDataOrder(e.value.filter((x) => { return x.id != -1 && x.id != -2 })) }}
                                ></OrderList>
                            </div>
                        </Row>
                    }
                </div>
            </Accordion >
        </React.Fragment>
    );
});

PostIncidentReviews.displayName = 'PostIncidentReviews';

export default PostIncidentReviews;