import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IWZTNASHRState } from './types';

const initialState = Object.freeze<IWZTNASHRState>({
    isLoading: false,
    userId:'',
    MeetingDate: null,
    DefaultDate: null,
    weeklyIDSHRData: [],
    SpecialTopicsData: [],
    PIRRereviewData: [],
    FundamentalsSpotlightData: [],
    ActionItemsReviewData: [],
    PIRAdminLock: [],
    isPIREditenabled: false,
    isSpecialTopicEditorAddEnabled: false,
    isFundamentalsSpotlightEditorAddEnabled: false,    
    MaxDate: new Date(),
    IsPirRereviewUser: false,
    isLockedPIR: true,
    isLockedTopic: true,
    isLockedFundamentalsSpotlight: true,    
    isLockedPIRArray: [],
    isLockedTopicArray: [],    
    isLockedFundamentalsSpotlightArray: [],
    isFunLockDisabledOnEdit: false,
    isSpecialTopicLockDisabledOnEdit: false,
    isPIRLockDisabledOnEdit: false,
    isPubServiceAccEditorAddEnabled: false,
    isLockedPubServiceAcc: true,
    isLockedPubServiceAccArray: [],
    isPSALockDisabledOnEdit: false,
    PublicServiceAnnouncementData: [],
    accordiontoggle: true,
    funaccordiontoggle: true,
    psaaccordiontoggle: true,
    specialaccordiontoggle: true,
    piraccordiontoggle: true
});

export const reducer = (
    state: IWZTNASHRState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {      
        case ActionType.SET_MEETINGDATE_DATE:
            return {
                ...state,
                userId: action.userId,
                MeetingDate: action.meetingdate,
                DefaultDate: action.meetingdate,
                MaxDate: action.maxdate
                
            };
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true              

            };
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false

            };
        case ActionType.MEETINGDATE_DATE_CHANGE:
            return {
                ...state,
                isLockedPIR: action.isLockedPIR,
                isLockedTopic: action.isLockedTopic,
                isLockedFundamentalsSpotlight: action.isLockedFundamentalsSpotlight,
                isLockedPubServiceAcc: action.isLockedPubServiceAcc,
                MeetingDate: action.MeetingDate

            };
        case ActionType.GET_WEEKLYIDSHR_REQUEST: 
            return {
                ...state,              
                isLoading:true
            };
        case ActionType.GET_WEEKLYIDSHR_SUCCESS:  // === for Post Incident Reviews success ===============//
            return {
                ...state,
                weeklyIDSHRData: action.weeklyIDSHRData,
                isPIREditenabled: false,
                isFunLockDisabledOnEdit: false,
                isPIRLockDisabledOnEdit: action.isPIRLockDisabledOnEdit,
                isLoading: (action.count >= 4) ? false : state.isLoading,
                piraccordiontoggle: action.piraccordiontoggle
            };
        case ActionType.ENABLE_EDITORADD_PIR:  // === Enable add or Edit for Post Incident Reviews ===============//
            return {
                ...state,
                weeklyIDSHRData: action.weeklyIDSHRData,
                isPIREditenabled: true,
                isPIRLockDisabledOnEdit: action.isPIRLockDisabledOnEdit,
                piraccordiontoggle: true
            };
        case ActionType.PIRREREVIEW_CHANGE:  // === for Post Incident Reviews success ===============//
            return {
                ...state,
                weeklyIDSHRData: action.weeklyIDSHRData                
            };
        case ActionType.GET_SPECIALTOPIC_SUCCESS:  // === for Special Topic success ===============//
            return {
                ...state,
                SpecialTopicsData: action.SpecialTopicsData,
                isSpecialTopicEditorAddEnabled: false,
                isLoading: (action.count >= 4) ? false : state.isLoading,
                isSpecialTopicLockDisabledOnEdit: action.isSpecialTopicLockDisabledOnEdit,
                specialaccordiontoggle: action.specialaccordiontoggle
            };
        case ActionType.SPECIALTOPIC_CHANGE:  // === for Special topic value changes ===============//
            return {
                ...state,
                SpecialTopicsData: action.SpecialTopicsData,
            };
        case ActionType.ENABLE_EDITORADD_SPECIALTOPIC:  // === Enable add or Edit for Post Incident Reviews ===============//
            return {
                ...state,
                SpecialTopicsData: action.SpecialTopicsData,
                isSpecialTopicEditorAddEnabled: true,
                isSpecialTopicLockDisabledOnEdit: action.isSpecialTopicLockDisabledOnEdit,
                specialaccordiontoggle: true
            };
        case ActionType.PIR_REREVIEW_CHANGE:  // === for Post Incident Reviews success ===============//
            return {
                ...state,
                PIRRereviewData: action.PIRRereviewData,
            };
        case ActionType.GET_WEEKLYIDSHR_FAILURE:  // === for Post Incident Reviews failure ===============//
            return {
                ...state,
                weeklyIDSHRData: [],
                isLoading: (action.count >= 4) ? false : state.isLoading,
            };
        case ActionType.GET_SPECIALTOPIC_FAILURE:  // === for special topic failure ===============//
            return {
                ...state,
                SpecialTopicsData: [],
                isLoading: (action.count >= 4) ? false : state.isLoading,
            };
        case ActionType.ENABLE_EDITORADD_PUBLICSERVICEACC:  // === Enable add or Edit for Public Service Announcement ===============//
            return {
                ...state,
                PublicServiceAnnouncementData: action.PublicServiceAnnouncementData,
                isPubServiceAccEditorAddEnabled: true,
                isPSALockDisabledOnEdit: action.isPSALockDisabledOnEdit,
                psaaccordiontoggle: true
            };
        case ActionType.GET_PUBLICSERVICEACC_SUCCESS:  // === for Public Service Announcement success ===============//
            return {
                ...state,
                PublicServiceAnnouncementData: action.PublicServiceAnnouncementData,
                isPubServiceAccEditorAddEnabled: false,
                isLoading: (action.count >= 4) ? false : state.isLoading,
                isPSALockDisabledOnEdit: action.isPSALockDisabledOnEdit,
                psaaccordiontoggle: action.psaaccordiontoggle
            };
        case ActionType.GET_PUBLICSERVICEACC_FAILURE:  // === for Public Service Announcement failure ===============//
            return {
                ...state,
                PublicServiceAnnouncementData: [],
                isLoading: (action.count >= 4) ? false : state.isLoading,
            };
        case ActionType.PUBLICSERVICEANNOUNCEMENT_CHANGE:  // === for Public Service Announcement value changes ===============//
            return {
                ...state,
                PublicServiceAnnouncementData: action.PublicServiceAnnouncementData,
            };
        case ActionType.GET_PIRREREVIEW_FAILURE:  // === for PIR Rereview failure ===============//
            return {
                ...state,
                PIRRereviewData: [],
                isLoading: (action.count >= 4) ? false : state.isLoading,
            };
        case ActionType.SET_MEETINGDATE_DATE:
            return {
                ...state,
                MeetingDate: action.MeetingDate
            };
        case ActionType.REREVIEWER_CHECK:
            return {
                ...state,
                IsPirRereviewUser: action.IsPirRereviewUser
            };        
        case ActionType.GET_PIRADMINLOCK:            
            return {
                ...state,
                isLockedPIR: action.isLockedPIR,
                isLockedTopic: action.isLockedTopic,
                isLockedFundamentalsSpotlight: action.isLockedFundamentalsSpotlight,
                isLockedPIRArray: action.isLockedPIRArray,
                isLockedTopicArray: action.isLockedTopicArray,
                isLockedFundamentalsSpotlightArray: action.isLockedFundamentalsSpotlightArray,
                isLockedPubServiceAcc: action.isLockedPubServiceAcc,
                isLockedPubServiceAccArray: action.isLockedPubServiceAccArray,
                isLoading: false
            }        
        case ActionType.GET_FUNDAMENTALS_SPOTLIGHT_SUCCESS:  // === for Fundamentals Spotlight success ===============//
            return {
                ...state,
                FundamentalsSpotlightData: action.FundamentalsSpotlightData,
                isFundamentalsSpotlightEditorAddEnabled: false,
                isFunLockDisabledOnEdit: action.isFunLockDisabledOnEdit,
                isLoading: (action.count >= 4) ? false : state.isLoading,
                funaccordiontoggle: action.funaccordiontoggle
            };
        case ActionType.FUNDAMENTALS_SPOTLIGHT_CHANGE:  // === for Fundamentals Spotlight value changes ===============//
            return {
                ...state,
                FundamentalsSpotlightData: action.FundamentalsSpotlightData,
            };
        case ActionType.ENABLE_EDITORADD_FUNDAMENTALS_SPOTLIGHT:  // === Enable add or Edit for Fundamentals Spotlight ===============//
            return {
                ...state,
                FundamentalsSpotlightData: action.FundamentalsSpotlightData,
                isFundamentalsSpotlightEditorAddEnabled: true,
                isFunLockDisabledOnEdit: action.isFunLockDisabledOnEdit,
                funaccordiontoggle: true
            };
        case ActionType.GET_FUNDAMENTALS_SPOTLIGHT_FAILURE:  // === for Fundamentals Spotlight failure ===============//
            return {
                ...state,
                FundamentalsSpotlightData: [],
                isLoading: (action.count >= 4) ? false : state.isLoading,
            };
        case ActionType.GET_ACTION_ITEMS_REVIEW_FAILURE:  // === Enable add or Edit for Action Items Review ===============//
            return {
                ...state,
                ActionItemsReviewData: [],
                isLoading: (action.count >= 4) ? false : state.isLoading,
            };
        case ActionType.GET_ACTION_ITEMS_REVIEW_SUCCESS:  // === for Action Items Review success ===============//
            return {
                ...state,
                ActionItemsReviewData: action.ActionItemsReviewData,                
                isLoading: (action.count >= 4) ? false : state.isLoading,
                accordiontoggle: action.accordiontoggle
            };
        case ActionType.ACTIONREVIEWACCORDION_STATUS:  // === Toggle Accordion Action Items Review ===============//
            return {
                ...state,
                accordiontoggle: action.accordiontoggle
            };
        case ActionType.FUNDAMENTALACCORDION_STATUS:  // === Toggle Accordion  Fundamental ===============//
            return {
                ...state,
                funaccordiontoggle: action.funaccordiontoggle
            };
        case ActionType.PSAACCORDION_STATUS:  // === Toggle Accordion Public announcement ===============//
            return {
                ...state,
                psaaccordiontoggle: action.psaaccordiontoggle
            };
        case ActionType.SPECIALTOPICACCORDION_STATUS:  // ===Toggle Accordion Special topics ===============//
            return {
                ...state,
                specialaccordiontoggle: action.specialaccordiontoggle
            };
        case ActionType.PIRACCORDION_STATUS:  // === Toggle Accordion PIR ===============//
            return {
                ...state,
                piraccordiontoggle: action.piraccordiontoggle
            };
        case ActionType.RESET_STATE:
            return initialState;
        default:
            return state;
    }
};
