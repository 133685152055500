import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type Route = {
    readonly path?: string;
    readonly exact?: boolean;
    readonly showInNav?: boolean;
    readonly pathAbsolute?: string;
    readonly displayName: string;
    readonly icon?: IconProp;
    readonly subMenu?: Route[];
    readonly href?: string;
    Istoggle?: boolean;
};

export type RoutesConfig = { [key: string]: Route };

export const RoutesConfig = Object.freeze<RoutesConfig>({
   
    HomeTiles: {
        path: '/',
        showInNav: true,
        displayName: 'Weekly SHR',
        Istoggle: false,
        subMenu: [
            {
                exact: true,
                href: '/WeeklyIDSHRData',
                showInNav: true,
                displayName: 'Weekly ID SHR Agenda',
            },
            {
                exact: true,
                href: '/WIP-SHR',
                showInNav: true,
                displayName: 'Platform BU SHR Agenda',
            },
            {
                exact: true,
                href: '/WZTNA-SHR',
                showInNav: true,
                displayName: 'WZTNA-SHR Agenda',
            },
            {
                exact: true,
                href: '/Security-SHR',
                showInNav: true,
                displayName: 'Security SHR',
            },
            {
                exact: true,
                href: '/Dublin-SHR',
                showInNav: true,
                displayName: 'Weekly Dublin SHR',
            },
            {
                exact: true,
                href: 'https://msit.powerbi.com/groups/me/reports/781a5405-35d6-47d8-82cf-1f91abe9d09b/ReportSection076916e45cf1bb37fb08',
                path: '/',
                showInNav: true,
                displayName: 'ICM PIR Repair ITems',
            }
        ]
    }, 
    ServiceHealth: {
        exact: true,
        path: '/servicehealth',
        showInNav: true,
        displayName: 'Service Metrics',
        Istoggle: false,
        subMenu: [
            {
                exact: true,
                href: 'https://aka.ms/IDNAOneDashReport',
                path: '/',
                showInNav: true,
                displayName: 'Identity KPI Report (OneDash)',
            },
            {
                exact: true,
                href: '/AvailabilityHeatmapBudgetWeekly',
                path: '/',
                showInNav: true,
                displayName: 'Weekly Availability',
            },
            {
                exact: true,
                href: '/AvailabilityHeatmapBudget',
                path: '/',
                showInNav: true,
                displayName: 'Monthly Availability',
            },
            {
                exact: true,
                href: '/AvailabilityQosHeatmapBudget',
                path: '/',
                showInNav: true,
                displayName: 'Monthly Availability Qos',
            },
            {
                exact: true,
                href: 'http://aka.ms/LiveSiteSupport',
                path: '/',
                showInNav: true,
                displayName: 'Supportability',
            },
            {
                exact: true,
                href: '/MetricDataSource',
                path: '/',
                showInNav: true,
                displayName: 'Service KPI Queries',
            },           
            {
                exact: true,
                href: '/DivisionalOKRs',
                path: '/',
                showInNav: true,
                displayName: 'Divisional Objectives and Key Results (OKR)',
            },
            {
                exact: true,
                href: '/LastWeekIdNewsLetter',
                path: '/',
                showInNav: true,
                displayName: 'Last week in Identity Livesite',
            },
        ]
    },
    MSER: {
        exact: true,
        path: '/servicehealth',
        showInNav: true,
        displayName: 'MSER',
        Istoggle: false,
        subMenu: [

            {
                exact: true,
                href: '/MSERHeatmapPreview',
                path: '/',
                showInNav: true,
                displayName: 'MSER Heatmap Preview',
            },
            {
                exact: true,
                href: '/MSERPresentationView',
                path: '/',
                showInNav: true,
                displayName: 'MSER Presentation View',
            },
            {
                exact: true,
                href: '/MSERHeatmapTrend',
                path: '/',
                showInNav: true,
                displayName: 'MSER Heatmap Trend Preview',
            },
            {
                exact: true,
                href: '/HeatmapAdmin',
                path: '/',
                showInNav: true,
                displayName: 'Heatmap Admin Management',
            },
            {
                exact: true,
                href: '/ADOTaskCreation',
                path: '/',
                showInNav: true,
                displayName: 'ADO Task Creation',
            },
        ]
    },
    CriticalChange: {
        exact: true,
        path: '/servicehealth',
        showInNav: true,
        displayName: 'No Fly',
        Istoggle: false,
        subMenu: [
            {
                exact: true,
                href: '/CriticalAdvisory',
                path: '/',
                showInNav: true,
                displayName: 'Critical Change Advisories',
            },
            {
                exact: true,
                href: 'https://safeflywebapp.azurewebsites.net/safe-fly-request/identity',
                path: '/',
                showInNav: true,
                displayName: 'ID Safe Fly',
            },
            {
                exact: true,
                href: 'https://msit.powerbi.com/groups/d9ea93ad-da2a-4b36-a6e9-ada897de91cd/reports/5804d143-c8bc-4514-baf8-4e4fe44e3ddf/ReportSection?clientSideAuth=0',
                path: '/',
                showInNav: true,
                displayName: 'CR Lockdowns',
            }

        ]
    },
       
    OnCall: {
        exact: true,
        path: '/servicehealth',
        showInNav: true,
        displayName: 'On-Call Readiness',
        Istoggle: false,
        subMenu: [
            {
                exact: true,
                href: '/OCEList',
                path: '/',
                showInNav: true,
                displayName: 'CheckList Dashboard',
            },
            {
                exact: true,
                href: '/ManageCheckList',
                path: '/',
                showInNav: true,
                displayName: 'Manage Service Checklists',
            },
            {
                exact: true,
                href: '/ManageTemplate',
                path: '/',
                showInNav: true,
                displayName: 'Manage Templates',
            },
            {
                exact: true,
                href: 'https://identitydivision.visualstudio.com/IdentityWiki/_wiki/wikis/IdentityWiki.wiki/16549/Checklist-UserGuide',
                path: '/',
                showInNav: true,
                displayName: 'CheckList User Guide',
            },
            {
                showInNav: true,
                displayName: 'divider',
            }, 
            {
                exact: true,
                href: '/EscalationDirectory',
                path: '/',
                showInNav: true,
                displayName: 'Partner Escalation',
            },
            {
                exact: true,
                href: '/InternalEscalation',
                path: '/',
                showInNav: true,
                displayName: 'ID (Internal) Escalation',
            },
            {
                exact: true,
                href: '/FutureOnCall',
                path: '/',
                showInNav: true,
                displayName: 'Future OnCall Details',
            },
            {
                exact: true,
                href: 'https://identitydivision.visualstudio.com/IdentityWiki/_wiki/wikis/IdentityWiki.wiki/5238/On-call-Certification-and-Resources',
                path: '/',
                showInNav: true,
                displayName: 'On-Call Wiki',
            },
            {
                exact: false,
                href: '/UIforOCEView',
                path: '/',
                showInNav: false,
                displayName: 'UI for OCE View',
            },
            {
                exact: false,
                href: '/UIforReadOnlyOCEView',
                path: '/',
                showInNav: false,
                displayName: 'UI for ReadOnly OCE View',
            },
            {
                exact: true,
                 href: '/CustomerImpactData',
                path: '/',
                showInNav: true,
                 displayName: 'Customer Impact Tool',
            },
            {
                exact: true,
                href: '/TenantId2SubIdConversion',
                path: '/',
                showInNav: true,
                displayName: 'TenantID2SubID Conv',
            },
            {
                exact: true,
                href: '/Sev1Bridge',
                path: '/',
                showInNav: true,
                displayName: 'Identity Sev 1 Bridge Details',
            },
        ]
    },
    PlatformPMTools: {
        exact: true,
        path: '/servicehealth',
        showInNav: true,
        displayName: 'Platform PM Tools',
        Istoggle: false,
        subMenu: [
            {
                exact: true,
                href: '/Identity_ACKTracking',
                path: '/',
                showInNav: true,
                displayName: 'ID Acktracking',
            },
            {
                exact: true,
                href: '/IDAckTrackingTemplate',
                path: '/',
                showInNav: true,
                displayName: 'ID Acktracking Template',
            },  
            {               
                showInNav: true,
                displayName: 'divider',
            },
            {                
                exact: true,
                href: '/PIRRiskTool',
                path: '/',
                showInNav: true,
                displayName: 'PIR Risk Tool',
            },
            {
                exact: true,
                href: '/CreateAdoHierarchy',
                path: '/',
                showInNav: true,
                displayName: 'Create ADO Hierarchy',
            },
            {
                exact: true,
                href: '/PlatformPM',
                path: '/',
                showInNav: true,
                displayName: 'Platform Team Tools'
            },                 
            {
                exact: true,
                href: '/Configuration',
                path: '/',
                showInNav: true,
                displayName: 'Tools Configuration',
            },
            {
                exact: false,
                href: '/EmailConfiguration',
                path: '/',
                showInNav: false,
                displayName: 'Email Configuration',
            },
            {
                exact: true,
                href: 'https://aka.ms/IdentityScenarioHealth',
                path: '/',
                showInNav: true,
                displayName: 'Identity Scenario Completion',
            },
            {
                exact: true,
                href: '/DCMapping',
                path: '/',
                showInNav: true,
                displayName: 'DC to Azure Region mapping',
            } 
        ]
    },
    AvailabilitySummary:  {
        path: '/AvailabilitySummary',
        showInNav: false,
        displayName: 'Availability Summary',
        Istoggle: false,
        subMenu: []        
    },
    AdminSettings: {
        path: '/AdminSettings',
        showInNav: false,
        displayName: 'Admin Settings    ',
        Istoggle: false,
        subMenu: []   
    }
});
