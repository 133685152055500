import { BaseService } from './base.service';
import {
    IMonthlyReport
} from '../store/service-health';
import { IPropCalender } from '../store/home-tiles';
/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class MonthlyService extends BaseService {
    private static _monthlyService: MonthlyService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): MonthlyService {
        return (this._monthlyService || (this._monthlyService = new this('MonthlyData')));
    }

    public async GetMonthlyData(weekStartDate: string, serviceId: number): Promise<IMonthlyReport[]> {
        const { data } = await this.$http.get<IMonthlyReport[]>(`GetAvailabilityBudgetHeatmapData?weekStartDate=${weekStartDate}&serviceId=${serviceId}`);
        return data;
    }
    public async GetMonthlyAvailbilityData(weekStartDate: string): Promise<IMonthlyReport[]> {
        const { data } = await this.$http.get<IMonthlyReport[]>(`GetMonthlyAvailbilityData?weekStartDate=${weekStartDate}`);
        return data;
    }
    public async GetAvailabilityQosBudgetHeatmapData(weekStartDate: string): Promise<IMonthlyReport[]> {
        const { data } = await this.$http.get<IMonthlyReport[]>(`GetAvailabilityQosBudgetHeatmapData?weekStartDate=${weekStartDate}`);
        return data;
    }
    public async SubmitHeatmapTargetApprovalRequest(requestobj): Promise<number> {
        const { data } = await this.$http.post<number>("SubmitHeatmapTargetApprovalRequest", requestobj);
        return data;
    }

    public async SendHeatmapUpdateRequestEmail(requestDetails): Promise<any> {
        const { data } = await this.$http.post<any>(`SendHeatmapUpdateRequestEmail`, requestDetails);
        return data;
    }
}

export const MonthlyAPI = MonthlyService.Instance;
