import { IDropdownOption } from '../store/service-health';
/**
 * react-functional-select 'themeConfig' property
 */
//export const THEME_CONFIG = {
//    color: {
//        primary: '#6399cd'
//    },
//    control: {
//        boxShadowColor: '#6399cd',
//        focusedBorderColor: '#63b1cd',
//        padding: '0.375rem 0.75rem',
//        Width: '300px'
//    },
//    menu: {
//        option: {
//            selectedColor: '#fff',
//            selectedBgColor: '#6399cd',
//            focusedBgColor: '#80b5e8'
//        }
//    }
//};

export const THEME_CONFIG_VIEW = {
    color: {
        border: '#ced4da',
        danger: '#dc3545',
        primary: '#007bff',
        disabled: '#e9ecef',
        placeholder: '#6E7276',
        dangerLight: 'rgba(220, 53, 69, 0.25)'
    },
    icon: {
        color: '#cccccc',
        hoverColor: '#A6A6A6',
        padding: '0 0.9375rem',
        clear: {
            width: '14px',
            height: '16px',
            animation: 'FADE_IN_KEYFRAMES 0.225s ease-in-out forwards',
            transition: 'color 0.15s ease-in-out'
        },
        caret: {
            size: '7px',
            transition: 'transform 0.25s ease-in-out, color 0.15s ease-in-out'
        }
    },
    control: {
        minHeight: '38px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '0.25rem',
        boxShadow: '0 0 0 0.2rem',
        padding: '0.375rem 0.75rem',
        boxShadowColor: 'rgba(0, 123, 255, 0.25)',
        focusedBorderColor: 'rgba(0, 123, 255, 0.75)',
        transition: 'box-shadow 0.15s ease-in-out, border-color 0.15s ease-in-out'
    },
    menu: {
        width: '100%',
        margin: '0.5rem 0',
        padding: '0.15rem 0',
        borderRadius: '0.25rem',
        backgroundColor: '#fff',
        animation: 'FADE_IN_KEYFRAMES 0.225s ease-in-out forwards',
        boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1)',
        option: {
            textAlign: 'left',
            selectedColor: '#fff',
            selectedBgColor: '#007bff',
            padding: '0.375rem 0.75rem',
            focusedBgColor: 'rgba(0, 123, 255, 0.20)'
        }
    },
    noOptions: {
        fontSize: '1.25rem',
        margin: '0.25rem 0',
        color: 'hsl(0, 0%, 60%)',
        padding: '0.375rem 0.75rem'
    },
    multiValue: {
        margin: '1px 2px',
        borderRadius: '0.25rem',
        backgroundColor: '#e7edf3',
        animation: 'FADE_IN_KEYFRAMES 0.225s ease-in-out forwards',
        label: {
            fontSize: '0.825em',
            borderRadius: '0.25rem',
            padding: '1px 2px 1px 6px'
        },
        clear: {
            fontWeight: 700,
            padding: '0 7px',
            fontSize: '0.67em',
            alignItems: 'center',
            borderRadius: '0.25rem',
            transition: 'color 0.15s ease-in-out, background-color 0.15s ease-in-out'
        }
    }
};

/**
 * Dropdown for filter  data
 */
export const REPORT_TYPE_DROPDOWN: IDropdownOption[] = [
    { value: 1, label: 'Daily' },
    { value: 2, label: 'Weekly' },
    { value: 3, label: 'Monthly' }
];

//export const VIEW_DEFAULT_DROPDOWN: IDropdownOption[] = [
//    { value: 0, label: 'Select the View' }

//];

export type UrlConfig = { [key: string]: string };

export const adminUrlConfig = Object.freeze<UrlConfig>({
    ADMIN_UI: 'https://livesite.idfun-test.msidentity.com/Admin',
});
