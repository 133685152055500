
export interface IActionType {
    readonly RESET_STATE: string; 
    readonly ADDED_VISITED_USER: string;
    readonly GET_PLATFORMTOOLS_REQUEST: string;
    readonly GET_PLATFORMTOOLS_SUCCESS: string;
    readonly GET_PLATFORMTOOLS_FAIL: string;
}
export type IPlatformToolState = {
    readonly isLoading: boolean; 
    readonly platformToolData: IPropPlatformPMTools[];
    readonly IsplatformToolDataloaded: boolean; 
};

export type IPropPlatformPMTools = {
    readonly applicationType: string;
    readonly title: string;
    readonly description: string;
    readonly url: string;
    readonly imageUrl: string;
    readonly owner: string;
    readonly contact: string;
};

const _namespace = 'PlatformTools ';

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/reset`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    GET_PLATFORMTOOLS_REQUEST: `${_namespace}/getplatformtoolrequest`,
    GET_PLATFORMTOOLS_SUCCESS: `${_namespace}/getplatformtoolsuccess`,
    GET_PLATFORMTOOLS_FAIL: `${_namespace}/getplatformtoolfail`,
});

