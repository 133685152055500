
export interface IActionType {
    readonly RESET_STATE: string;
    readonly ADDED_VISITED_USER: string; 
    readonly ACKTEMPLATE_DATA_FETCH: string; 
    readonly ACKTEMPLATE_DATA_SUCCESS: string;
    readonly ACKTEMPLATE_DATA_FAIL: string;
    readonly SHOWORHIDE_ACKTYPE_POPUP: string;
    readonly SHOWORHIDE_ACK_FILTER_POPUP: string;
    readonly ACKNOTIFICATION_DATA_FETCH: string;
    readonly ACKNOTIFICATION_DATA_SUCCESS: string;
    readonly ACKNOTIFICATION_DATA_FAIL: string;
    readonly ACKTYPE_VAL_CHANGE: string;
    readonly TEMPLATE_DROPDOWN_FETCH: string;
    readonly TEMPLATE_DROPDOWN_SUCCESS: string;
    readonly TEMPLATE_VAL_CHANGE: string;
    readonly ACKTYPE_VALUE_CHANGE: string;
    readonly TEMPLATE_DESCRIPTION_CHANGE: string;
    readonly SET_LOADING_TRUE: string;
    readonly SET_LOADING_FALSE: string;
    readonly SHOWORHIDE_TEMPLATE_POPUP: string;
    readonly OPEN_TEMPLATE_POPUP: string;
    readonly SERVICETREE_COL_CHANGE: string;
    readonly ADD_NEW_COLUMNS: string;
    readonly TEMPLATE_COLUMN_VALUES_CHANGE: string;
    readonly COLOUR_PALLETE_VAL_CHANGE: string;
    readonly ACK_TYPE_IS_ACTIVE_CHANGE: string;
    readonly ACKNOTIFICATION_FILTER_DATA_FETCH: string;
    readonly ACKNOTIFICATION_FILTER_DATA_SUCCESS: string;
    readonly ACKNOTIFICATION_FILTER_DATA_FAIL: string;
    readonly ACK_FILTER_DATA_FETCH: string;
    readonly ACK_FILTER_DATA_SUCCESS: string;
    readonly ACK_FILTER_DATA_FAIL: string;
    readonly MOVEACKTEMPLATETOARCHIVE: string;
    readonly MOVEACKTEMPLATETOARCHIVE_FAIL: string;

}
export type IAckTrackingTemplateState = {
    readonly isLoading: boolean;
    readonly ackTemplateData: IDAcktrackingTemplate[];
    readonly showackTypePopup: boolean;
    readonly showtemplatePopup: boolean;
    readonly showAckFilterPopup: boolean;
    readonly AckType: number;
    readonly ackNotificationData: IDAcktrackingNotification[];
    readonly templatedropdowndata: IDAcktrackingTemplate[];
    readonly templateid: string;
    readonly ackNotificationlist: [];
    readonly ackFilterNamelist: IDAcktrackingFilter[];
    readonly ackFilterNameData: [];
    readonly templateDescription: string;
    readonly ackTypevalue: string;
    readonly isAckTypeActive: boolean;
    readonly isTemplateActive: boolean;
    readonly Enableacktypesavebutton: boolean;
    readonly template: IDAcktrackingTemplate; 
    readonly servicecolsList: [];
    readonly changedColumns: [];
    readonly columncontroltypeoptions: [];
    readonly columncoloring: [];
    readonly colorsData: [];
    readonly templatesavebuttondisable: boolean;

};
export type IDAcktrackingTemplate = {
    readonly templateID?: number;
    readonly templateName: string;
    readonly colsList: string;
    readonly serviceTreeColsList: string;
    readonly isUsed: boolean
    readonly isActive: boolean;
    readonly createdBy: string;
    readonly createdDate: string;
    readonly modifiedBy: string;
    readonly modifiedDate: Date;
    readonly templateJSON: string;
    readonly notificationTitle: string;
    readonly ackcount: number;
    readonly originalserviceTreeColsList: string;
    readonly mappedAckCount: number;
    isEditable: boolean;
};

export type IDAcktrackingFilter = {
    readonly id: number;
    readonly filterName: string;
    readonly filters: string;   
    readonly isActive: boolean;
    readonly updatedBy: string;    
};


export type IDAcktrackingNotification = {
    readonly notificationACKId?: number;
    readonly title: string;
    readonly isActive: boolean;
    readonly isTemplateActive: boolean;
    readonly lastUpdatedBy: string;
    readonly lastUpdatedDate: Date
    readonly templateId: number;
    readonly templateName: string;
    readonly description: string;
     isEditable: boolean;
};



const _namespace = 'IdAcktrackingTemplate ';

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/reset`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    ACKTEMPLATE_DATA_FETCH: `${_namespace}/acktemplatedatafetch`,
    ACKTEMPLATE_DATA_SUCCESS: `${_namespace}/acktemplatedatasuccess`,
    ACKTEMPLATE_DATA_FAIL: `${_namespace}/acktemplatedatafail`,
    SHOWORHIDE_ACKTYPE_POPUP: `${_namespace}/showorhideacktypepopup`,
    SHOWORHIDE_ACK_FILTER_POPUP: `${_namespace}/showorhide_ack_filter_popup`,
    SHOWORHIDE_TEMPLATE_POPUP: `${_namespace}/showorhidetemplatepopup`,
    ACKNOTIFICATION_DATA_FETCH: `${_namespace}/acknotificationfetch`,
    ACKNOTIFICATION_DATA_SUCCESS: `${_namespace}/acknotificationsuccess`,
    ACKNOTIFICATION_DATA_FAIL: `${_namespace}/acknotificationfail`,
    ACKTYPE_VAL_CHANGE: `${_namespace}/acktypevalchange`,
    TEMPLATE_DROPDOWN_FETCH: `${_namespace}/templatedropdownfetch`,
    TEMPLATE_DROPDOWN_SUCCESS: `${_namespace}/templatedropdownsuccess`,
    TEMPLATE_VAL_CHANGE: `${_namespace}/templatevalchange`,
    ACKTYPE_VALUE_CHANGE: `${_namespace}/acktypevaluechange`,
    TEMPLATE_DESCRIPTION_CHANGE: `${_namespace}/templatedescriptionchange`,
    SET_LOADING_TRUE: `${_namespace}/loadingtrue`,
    SET_LOADING_FALSE: `${_namespace}/loadingfalse`,
    OPEN_TEMPLATE_POPUP: `${_namespace}/opentemplatepopup`,
    SERVICETREE_COL_CHANGE: `${_namespace}/servicetreecolchange`,
    ACK_TYPE_IS_ACTIVE_CHANGE: `${_namespace}/ack_type_is_active_change`,
    ADD_NEW_COLUMNS: `${_namespace}/addnewcolumnsdata`,
    TEMPLATE_COLUMN_VALUES_CHANGE: `${_namespace}/templatecolumnvalueschange`,
    COLOUR_PALLETE_VAL_CHANGE: `${_namespace}/colourpalletevalchange`,
    ACKNOTIFICATION_FILTER_DATA_FETCH: `${_namespace}/acknotification_filter_data_fetch`,
    ACKNOTIFICATION_FILTER_DATA_SUCCESS: `${_namespace}/acknotification_filter_data_success`,
    ACKNOTIFICATION_FILTER_DATA_FAIL: `${_namespace}/acknotification_filter_data_fail`,
    ACK_FILTER_DATA_FETCH: `${_namespace}/ack_filter_data_fetch`,
    ACK_FILTER_DATA_SUCCESS: `${_namespace}/ack_filter_data_success`,
    ACK_FILTER_DATA_FAIL: `${_namespace}/ack_filter_data_fail`,
    MOVEACKTEMPLATETOARCHIVE: `${_namespace}/moveacktemplatetoarchive`,
    MOVEACKTEMPLATETOARCHIVE_FAIL: `${_namespace}/moveacktemplatetoarchive_fail`
});

