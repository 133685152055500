import { BaseService } from './base.service';
import { IServiceGroup } from '../store/home-tiles';
import { IAllViewDetails } from '../store/service-health/types';
import { IPropOnBoardedHMService, IPropOnBoardedMSERService } from '../store/MSERPresentationView';
import { IMSERHeatmapTrendReport } from '../store/MSERHeatmapTrend';
/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class MSERHeatmapPreviewService extends BaseService {
    private static _serviceGroupService: MSERHeatmapPreviewService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): MSERHeatmapPreviewService {
        return (this._serviceGroupService || (this._serviceGroupService = new this('MSERHeatmapPreview')));
    }
    public async GetMserHeatViewList(): Promise<IAllViewDetails[]> {
        const { data } = await this.$http.get<IAllViewDetails[]>("GetMserHeatViewList");
        return data;
    }
    public async SaveIsMserPresentationView(isMserPresentationView: boolean, viewId: string, updatedBy:string): Promise<number> {
        const { data } = await this.$http.get<number>(`SaveIsMserPresentationView?isMserPresentationView=${isMserPresentationView}&viewId=${viewId}&updatedBy=${updatedBy}`);
        return data;
    }
    public async GetMserPresentationHeatViewList(): Promise<IAllViewDetails[]> {
        const { data } = await this.$http.get<IAllViewDetails[]>("GetMserPresentationHeatViewList");
        return data;
    }
    public async GetHeatMapServicesOnboardedData(): Promise<IPropOnBoardedHMService[]> {
        const { data } = await this.$http.get<IPropOnBoardedHMService[]>("GetHeatMapServicesOnboardedData");
        return data;
    }
    public async GetMSERServicesOnboardedData(): Promise<IPropOnBoardedMSERService[]> {
        const { data } = await this.$http.get<IPropOnBoardedMSERService[]>("GetMSERServicesOnboardedData");
        return data;
    }

    public async GetMSERHeatmapDataTrend(weekStartDate: string, identityServiceClassification: string, servicegroups: string, serviceids: string): Promise<IMSERHeatmapTrendReport[]> {
        const { data } = await this.$http.get<IMSERHeatmapTrendReport[]>(`GetMSERHeatmapDataTrend?weekStartDate=${weekStartDate}&identityServiceClassification=${identityServiceClassification}&servicegroups=${servicegroups}&serviceids=${serviceids}`);
        return data;
    }
    public async GetHeatmapTrendServices(): Promise<IServiceGroup[]> {
        const { data } = await this.$http.get<IServiceGroup[]>("GetHeatmapTrendServices");
        return data;
    }
    public async GetServiceGroupsHeatmapTrend(): Promise<IServiceGroup[]> {
        const { data } = await this.$http.get<IServiceGroup[]>("GetServiceGroupsHeatmapTrend");
        return data;
    }
}

export const MSERHeatmapPreviewAPI = MSERHeatmapPreviewService.Instance;
