import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IPIRRiskToolState } from './types';

const initialState = Object.freeze<IPIRRiskToolState>({
    isLoading: true,
    gemServiceMappingData: [],
    nodeData: [],
    checkedServices: [],
    workItemID: "",
    titleValue: "",
    IterationPathValue: "",
    workItemTypeValue: "",
    tagsValue: "",
    stateValue: "",
    includeLinks: false,
    includeattachments: false,
    relations: [],
    isLinksActive: false,
    isAttachmentActive: false,
    errorMsg: "",
    errorMsgList: [],
    descriptionValue: "",
    IDClassificationvalue: "",
    IDClassificationList: [],
    customFields: []    
});

export const reducer = (
    state: IPIRRiskToolState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {
        case ActionType.GET_GEMSERVICE_SUCCESS:
            return {
                ...state,
                gemServiceMappingData: action.gemServiceMappingData,
                nodeData: action.nodeData,
                checkedServices: action.checkedServices,
                isLoading: false
            };
        case ActionType.GET_GEMSERVICE_FAILURE:
            return {
                ...state,
                gemServiceMappingData: [],
                nodeData: []
            };
        case ActionType.SET_CHECKED_VALUES:
            return {
                ...state,
                checkedServices: action.checkedServices,
                IDClassificationvalue: action.IDClassificationvalue
            };
        case ActionType.SET_NODEDATA_VALUES:
            return {
                ...state,
                nodeData: action.nodeData,
                IDClassificationvalue: action.IDClassificationvalue,
                checkedServices: action.checkedServices
            };
        case ActionType.SET_WORKITEMID_VALUE:
            return {
                ...state,
                workItemID: action.workItemID,
                errorMsgList: []
            };
        case ActionType.SET_INCLUDE_LINKS:
            return {
                ...state,
                includeLinks: action.includeLinks

            };
        case ActionType.SET_INCLUDE_ATTACHMENTS:
            return {
                ...state,
                includeattachments: action.includeattachments
            };
        case ActionType.GET_WORKITEM_DETAILS:
            return {
                ...state,
                titleValue: action.titleValue,
                IterationPathValue: action.IterationPathValue,
                workItemTypeValue: action.workItemTypeValue,
                tagsValue: action.tagsValue,
                stateValue: action.stateValue,
                relations: action.relations,
                isLinksActive: action.isLinksActive,
                isAttachmentActive: action.isAttachmentActive,
                isLoading: false,
                includeattachments: false,
                includeLinks: false,
                errorMsgList: [],
                descriptionValue: action.descriptionValue,
                customFields: action.customFields
            };
        case ActionType.CREATE_ADOWORKITEM_FAILED:
            return {
                ...state,
                workItemID: "",
                titleValue: "",
                workItemTypeValue: "",
                errorMsg: action.errorMsg,
                IterationPathValue: "",
                tagsValue: "",
                stateValue: "",
                relations: "",
                isLinksActive: false,
                includeLinks: false,
                isAttachmentActive: false,
                isLoading: false,
                includeattachments: false,
                errorMsgList: action.errorMsgList
            };
        case ActionType.CREATE_ADOWORKITEM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                workItemID: "",
                titleValue: "",
                workItemTypeValue: "",
                errorMsg: action.errorMsg,
                IterationPathValue: "",
                tagsValue: "",
                stateValue: "",
                relations: "",
                isLinksActive: false,
                isAttachmentActive: false,
                includeLinks: false,
                includeattachments: false,
                errorMsgList: action.errorMsgList
            };
        case ActionType.CLEAR_ADOWORKITEM_FIELDS:
            return {
                ...state,
                workItemID: "",
                titleValue: "",
                workItemTypeValue: "",
                errorMsg: action.errorMsg,
                IterationPathValue: "",
                tagsValue: "",
                stateValue: "",
                relations: "",
                isLinksActive: false,
                isAttachmentActive: false,
                includeLinks: false,
                includeattachments: false,
                errorMsgList: []
            };
        case ActionType.IDCLASSIFICATION_CHANGE:
            return {
                ...state,
                nodeData: action.nodeData,
                gemServiceMappingData: action.gemServiceMappingData,
                checkedServices: action.checkedServices,
                IDClassificationvalue: action.IDClassificationvalue
            };
        case ActionType.FETCH_IDCLASSIFICATION_SUCCESS:
            return {
                ...state,
                IDClassificationList: action.IDClassificationList,
                IDClassificationvalue: action.IDClassificationvalue
            };
        case ActionType.FETCH_IDCLASSIFICATION_FAIL:
            return {
                ...state,
                IDClassificationList: []
            };
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false
            };
        case ActionType.RESET_STATE:
            return initialState;
        default:
            return state;
    }
};
