import React, { useState, Fragment } from 'react';
import DatePicker, { getDay } from 'react-datepicker'
import { ReduxAction, IAppThunkAction } from '../../../store';
import moment from 'moment';
import {Row, Col,Card,CardBody} from 'reactstrap';
type WZTNASHRDataTableProps = {
    MeetingDate: any;
    MaxDate: any;
    readonly handleMeetingDateChange: (MeetingDate: Date) => IAppThunkAction<ReduxAction>;
    
};

const WZTNASHRDataTable = React.memo<WZTNASHRDataTableProps>(({
    MeetingDate,
    MaxDate,
    handleMeetingDateChange   
}) => {   
    const isWednesday = (date) => {
        var day = new Date(moment(date).format('MM/DD/YYYY')).getDay();
        return day == 3;
    };   
    const getMaxdate = () => {
        let today = new Date(moment().clone().endOf('week').format('MM/DD/YYYY'));
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 5);
       
    };   
  
    return (
        <React.Fragment>
            <Card className="cardstyle">
                <CardBody className="cardbodystyle">
                    <Row>
                        <Col lg="1"></Col>
                        <Col lg="5">
                            <h3 className="headertext"> Weekly ZTNA Service Health Review </h3>
                        </Col>

                        <Col lg="4">
                            <span>Select Meeting Date : </span>
                            <DatePicker className='InputStyle'
                                selected={MeetingDate}
                                onChange={handleMeetingDateChange}                                
                                calendarIcon={true}
                                calendarAriaLabel={true}
                                filterDate={isWednesday}
                                maxDate={MaxDate}
                            />
                        </Col>
                        <Col lg="2"></Col>
                        </Row>
                    </CardBody>
            </Card>
            <div className="divpaddingtop">
                <Card>
                    <CardBody className="cardbodytext">
                        <Row>
                            <Col lg="6">
                                Identity PIR guidelines, read the wiki <a href="https://identitydivision.visualstudio.com/IdentityWiki/_wiki/wikis/IdentityWiki.wiki?pagePath=%2FIdentityWiki%2FFundamentals%2FLive%20Site%2FIdentity%20Postmortem%20Guidelines&pageId=2507&wikiVersion=GBwikiMaster" target="_blank">here</a><br />						    
						    Before presenting in the review read the presentation requirements <a href="https://identitydivision.visualstudio.com/IdentityWiki/_wiki/wikis/IdentityWiki.wiki?pagePath=%2FIdentityWiki%2FFundamentals%2FLive%20Site%2FIdentity%20Postmortem%20Guidelines%2FPIR%20Presentations%20in%20the%20Weekly%20Ideneity%20Service%20Health%20Review&pageId=2933&wikiVersion=GBwikiMaster" target="_blank">here</a>
                            </Col>
                            <Col lg="6">
                                <span className="label-info">Note: IcM data pulled from IcM Kusto DB which has up to 15 min data propagation delay.</span>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
       </React.Fragment>
    )
});

WZTNASHRDataTable.displayName = 'WZTNASHRDataTable';
export default WZTNASHRDataTable;