import { CommonAPI, FutureOnCallAPI } from '../../api';
import { IAppThunkAction, ReduxAction } from '../';
import { ActionType, IPropfutureOnCallData, IFutureOnCallState, DropdownOption } from './types';
import { isNullOrUndefined, isArrayWithLength } from '../../utils';

export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    setloadingtrue: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
    },
    setloadingfalse: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_FALSE
        });
    },
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }
    },
    handleTenantChange: (selectedTenant: DropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            selectedTenant,
            type: ActionType.TENANT_CHANGE
        });

    },
    handleTeamChange: (selectedTeam: DropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            selectedTeam,
            type: ActionType.TEAM_CHANGE
        });
    },
    handleCloudChange: (selectedCloud: DropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            selectedCloud,
            type: ActionType.CLOUD_CHANGE
        });
    },
    handleServiceLifeCycleChange: (selectedServiceLifeCycle: DropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            selectedServiceLifeCycle,
            type: ActionType.SERVICELIFECYCLE_CHANGE
        });
    },
    getFilteredOnCallList: (filterStartDate: any, filterEndDate: any): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var StartDate = filterStartDate ? ((filterStartDate.getMonth() + 1) + '/' + (filterStartDate.getDate()) + '/' + (filterStartDate.getFullYear())) : '';
        var EndDate = filterEndDate ? ((filterEndDate.getMonth() + 1) + '/' + (filterEndDate.getDate()) + '/' + (filterEndDate.getFullYear())) : '';
        var TenantName = getState().FutureOnCall.selectedTenant;
        var TeamName = getState().FutureOnCall.selectedTeam;
        var CloudName = getState().FutureOnCall.selectedCloud;
        var ServiceLifeCycle = getState().FutureOnCall.selectedServiceLifeCycle;

        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });

        var futureOnCallData = [];
        var futureOnCallDataFiltered = [];
        let copyEmailselectedImorOce = getState().FutureOnCall.copyEmailselectedImorOce;

        FutureOnCallAPI.GetFutureOnCall(StartDate, EndDate, TenantName, TeamName, CloudName, ServiceLifeCycle)
            .then((futureOnCallDataRaw: IPropfutureOnCallData[]) => {
                futureOnCallDataRaw.map((obj: IPropfutureOnCallData) => {
                    futureOnCallData.push(obj);
                });
                dispatch({
                    futureOnCallData: futureOnCallData,
                    futureOnCallDataFiltered: futureOnCallData,
                    copyEmailselectedImorOce: copyEmailselectedImorOce,
                    type: ActionType.GET_FUTUREONCALL_SUCCESS
                });
            })
            .catch(error => {
                dispatch({
                    type: ActionType.GET_FUTUREONCALL_FAILURE
                });
            });
    },
    GetFutureOncallList: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var TenantTeamNamesList = [];
        var CloudNames = [];
        var ServiceLifeCycleArray = [];

        FutureOnCallAPI.GetTenantTeamNameList()
            .then((TenantTeamNamesList: any) => {
                dispatch({
                    TenantTeamNamesList: TenantTeamNamesList,
                    type: ActionType.GET_TENANTTEAMNAME_DATA
                });
            });

        FutureOnCallAPI.GetCloudList()
            .then((CloudNames: any) => {
                let clouds = [];
                CloudNames.table.map((obj: any) => {
                    clouds.push({ 'label': obj.cloud, 'value': obj.cloud })
                });

                dispatch({
                    CloudNamesList: clouds,
                    type: ActionType.GET_CLOUDNAMES_DATA
                });
            });

        FutureOnCallAPI.GetServiceLifeCycleList()
            .then((ServiceLifeCycleArray: any) => {
                let ServiceLifeCycleNames = [];
                ServiceLifeCycleArray.table.map((obj: any) => {
                    ServiceLifeCycleNames.push({ 'label': obj.serviceLifeCycle, 'value': obj.serviceLifeCycle })
                });

                dispatch({
                    ServiceLifeCycleList: ServiceLifeCycleNames,
                    type: ActionType.GET_SERVICELIFECYCLE_LIST
                });
            });

        // Dispatch request
        dispatch({
            type: ActionType.GET_FUTUREONCALL_REQUEST
        });
        var currentDate = new Date;

        let StartDate = [currentDate.getMonth() + 1, currentDate.getDate(), currentDate.getFullYear()].join('/') + ' ' + [currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds()].join(':'); //'02/18/2021';
        let EndDate = '';
        let TenantName = '';
        let TeamName = '';
        let CloudName = '';
        let ServiceLifeCycle = '';

        var futureOnCallData = [];
        var futureOnCallDataFiltered = [];
        var reverseSort = getState().FutureOnCall.reverseSort;
        var sortcolumn = getState().FutureOnCall.sortcolumn;
        var isactive = getState().FutureOnCall.isactive;
        let copyEmailselectedImorOce = getState().FutureOnCall.copyEmailselectedImorOce;

        FutureOnCallAPI.GetFutureOnCall(StartDate, EndDate, TenantName, TeamName, CloudName, ServiceLifeCycle)
            .then((futureOnCallDataRaw: IPropfutureOnCallData[]) => {
                futureOnCallDataRaw.map((obj: IPropfutureOnCallData) => {
                    futureOnCallData.push(obj);
                });
                dispatch({
                    futureOnCallData: futureOnCallData,
                    futureOnCallDataFiltered: futureOnCallData,
                    copyEmailselectedImorOce: copyEmailselectedImorOce,
                    type: ActionType.GET_FUTUREONCALL_SUCCESS
                });
            })
            .catch(error => {
                dispatch({
                    type: ActionType.GET_FUTUREONCALL_FAILURE
                });
            });
    },

    clearDropdownValues: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const selectedTenant = '';
        const selectedTeam = '';
        const selectedCloud = '';
        const ServiceLifeCycleList = '';
        const selectedServiceLifeCycle = '';
        dispatch({
            selectedTeam: selectedTeam,
            selectedTenant: selectedTenant,
            selectedCloud: selectedCloud,
            ServiceLifeCycleList: ServiceLifeCycleList,
            selectedServiceLifeCycle: selectedServiceLifeCycle,
            type: ActionType.CLEAR_FILTER
        });
    },
    sortData: (e, column): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var reverseSort = getState().FutureOnCall.reverseSort;
        var sortcolumn = getState().FutureOnCall.sortcolumn;
        var futureOnCallData = getState().FutureOnCall.futureOnCallData;
        reverseSort = sortcolumn == column ? !reverseSort : false;
        sortcolumn = column;
        var futureOnCallDataFiltered = [];

        if (sortcolumn === "tenantName" || sortcolumn === "teamName" || sortcolumn === "name" || sortcolumn === "backupName" || sortcolumn === "cloud" || sortcolumn === "serviceLifeCycle" ) {
            // Logic to sort the array based on selected column and order having Html Content
            futureOnCallDataFiltered = (reverseSort === true)
                ? futureOnCallData.sort((a, b) => (a[column].replace(/<(.|\n)*?>/g, '').toLowerCase() > b[column].replace(/<(.|\n)*?>/g, '').toLowerCase()) ? -1 : 1)
                : futureOnCallData.sort((a, b) => (a[column].replace(/<(.|\n)*?>/g, '').toLowerCase() > b[column].replace(/<(.|\n)*?>/g, '').toLowerCase()) ? 1 : -1)
        }
        else {
            // Logic to sort the array based on selected column and order
            futureOnCallDataFiltered = (reverseSort === true)
                ? futureOnCallData.sort((a, b) => (a[column].toLowerCase() > b[column].toLowerCase()) ? -1 : 1)
                : futureOnCallData.sort((a, b) => (a[column].toLowerCase() > b[column].toLowerCase()) ? 1 : -1)
        }
        dispatch({
            reverseSort: reverseSort,
            sortcolumn: sortcolumn,
            futureOnCallDataFiltered: futureOnCallDataFiltered,
            type: ActionType.CHANGE_SORTING_FUTUREONCALL
        });
    },

    OpenEmailPOPUP: (globalFilteredData, globalFilter): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let OnCallAliasEmailIDs = "";
        let BackupOnCallAliasEmailIDs = "";
        let filteredonCallData = getState().FutureOnCall.futureOnCallDataFiltered;

        filteredonCallData.map((contact: any) => {
            if (contact.emailAddress !== undefined) {

                if (OnCallAliasEmailIDs === "") {
                    OnCallAliasEmailIDs = contact.emailAddress;
                }
                else if (OnCallAliasEmailIDs.indexOf(contact.emailAddress) === -1) {
                    OnCallAliasEmailIDs = OnCallAliasEmailIDs + " ; " + contact.emailAddress;
                }
            }
            if (contact.bEmailAddress && contact.bEmailAddress !== 'Not Defined') {
                if (BackupOnCallAliasEmailIDs === "") {
                    BackupOnCallAliasEmailIDs = contact.bEmailAddress;
                }
                else if (BackupOnCallAliasEmailIDs.indexOf(contact.bEmailAddress) === -1) {
                    BackupOnCallAliasEmailIDs = BackupOnCallAliasEmailIDs + " ; " + contact.bEmailAddress;
                }
            }
        });
        dispatch({
            OnCallAliasEmailIDs,
            BackupOnCallAliasEmailIDs,
            type: ActionType.OPEN_EMAIL_POPUP
        });
    },

    handleemailpopupClose: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.EMAIL_POPUP_CLOSE
        });
    }
};


