import React, { useState, useMemo, Fragment, useRef } from 'react';
import RichTextEditor from 'react-rte';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import DatePicker from 'react-datepicker';
import { MultiSelect } from 'primereact/multiselect';

type ADOTaskCreationTableProps = {  
    eta: Date;
    description: any;
    title: string;
    assignedTo: string;
    areaPath: { name: string };        
    setdescription: any;
    settitle: any;
    setAssignedTo: any;
    setareaPath: any;
    setEta: any;
    CreateTask: any;
    tags: any;
    handleTagsChange: any;
    tagsValue: any;
    areaPathList: any;
    areaPathDropdown: any;
    areaPathSelected: any;
};
const ADOTaskCreationTable = React.memo<ADOTaskCreationTableProps>(({  
    eta,
    description,
    title,
    assignedTo,
    areaPath,
    setdescription,
    settitle,
    setAssignedTo,
    setareaPath,
    setEta,
    CreateTask,
    tags,
    handleTagsChange,
    tagsValue,
    areaPathList,
    areaPathDropdown,
    areaPathSelected
}) => {   

    const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
            { label: 'Italic', style: 'ITALIC' },
            { label: 'Underline', style: 'UNDERLINE' }
        ],
        BLOCK_TYPE_DROPDOWN: [
            { label: 'Normal', style: 'unstyled' },
            { label: 'Heading Large', style: 'header-one' },
            { label: 'Heading Medium', style: 'header-two' },
            { label: 'Heading Small', style: 'header-three' }
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item' },
            { label: 'OL', style: 'ordered-list-item' }
        ]
    };
    
    const handleAreaPathChange = (e) => {        
        setareaPath(e);
    }
    const handleTitleChange = (e) => {
        settitle(e.target.value);
    }
    const UpdateDescriptionValue = (e) => {
        setdescription(e);
    }
    const handleAssignedToChange = (e) => {
        setAssignedTo(e.target.value);
    }
    
    
    return (
        <table className="adotaskcreationtable table-bordered table-striped">
            <tbody>
                <tr>
                    <th style={{ width: '10%', verticalAlign: 'middle' }}>
                        Area Path <span style={{ color: 'red' }}>*</span>
                    </th>
                    <td className="input-placeholder">
                        <Dropdown id={'areaPathid'} value={areaPathSelected} options={areaPathDropdown} onChange={(e) => handleAreaPathChange(e)} optionLabel="name" placeholder="&nbsp;&nbsp;&nbsp;Select Area Path" />
                    </td>
                </tr>
                <tr>
                    <th style={{ width: '10%', verticalAlign: 'middle' }}>
                        Title <span style={{ color: 'red' }}>*</span>
                    </th>
                    <td>
                        <InputText id="title" style={{ width: '100%' }} value={title} onChange={handleTitleChange} placeholder="Title" maxlength="75" tooltip="Title maximum 75 characters allowed" />
                    </td>
                </tr>
                <tr>
                    <th style={{ width: '10%', verticalAlign: 'middle' }}>
                        Description
                </th>
                    <td>
                        <RichTextEditor
                            value={description}
                            onChange={(e) => { UpdateDescriptionValue(e) }}
                            toolbarConfig={toolbarConfig}
                            editorClassName="trix-editorADO"
                            className="customRichEdit"
                            placeholder="Description..."
                            type="string"
                            multiline
                            variant="filled"
                        />
                    </td>
                </tr>
                <tr>
                    <th style={{ width: '10%', verticalAlign: 'middle' }}>
                        Assigned To
                    </th>
                    <td>
                        <InputText style={{ width: '30%' }} value={assignedTo} onChange={handleAssignedToChange} placeholder="Assigned To (alias@domain.com)" />
                    </td>
                </tr>
                <tr>
                    <th style={{ width: '10%', verticalAlign: 'middle' }}>
                        Tags
                    </th>
                    <td>
                        <MultiSelect id={'tags'} value={tagsValue} options={tags} onChange={(e) => handleTagsChange(e.value)} optionLabel="name" />
                    </td>
                </tr>
                <tr>
                    <th style={{ width: '10%', verticalAlign: 'middle' }}>
                        ETA
                    </th>
                    <td className="input-placeholder">
                        <DatePicker className='DateInputStyle'
                            placeholderText="&nbsp;&nbsp;Click to select a date"
                            selected={eta}
                            onChange={date => setEta(date)}
                            calendarIcon={true}
                            calendarAriaLabel={true}
                            title="ETA should be set to the next meeting occurrence"
                        />
                        <div className="placeholder" style={{ display: areaPath.name != 'Weekly' ? 'none' : eta == null ? 'inline-block' : 'none' }}><span>*</span></div>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} style={{ textAlign: 'center' }}>
                        <button disabled={areaPath.name == '' || (areaPath.name == 'Weekly' && eta == null) || title == ''} className="btn btn-primary filter-button" onClick={e => CreateTask()}>Create Task</button>
                    </td>
                </tr>
            </tbody>
        </table>                    
    )
});

ADOTaskCreationTable.displayName = 'ADOTaskCreationTable';
export default ADOTaskCreationTable;