import { CommonAPI, MonthlyAPI } from '../../api';
import { IAppThunkAction, ReduxAction } from '../';
import { ActionType } from './types';
import moment from 'moment';
import { IMonthlyReport } from '../service-health/types';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../utils';
export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }

    },
    onmonthlyStartDateChange: (monthlyStartDate: Date): ReduxAction => ({

        monthlyStartDate: monthlyStartDate,
        type: ActionType.MONTHLY_START_DATE_CHANGE
    }),
    ClearMonthlyDate: (): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {
        dispatch({
            monthlyStartDate: new Date(),
            type: ActionType.MONTHLY_START_DATE_CHANGE
        });
    },
   
    getMonthlyQOSData: (monthstartdate: string): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {
        monthstartdate = moment(monthstartdate).format('MM/DD/YYYY');
        let sid = getSate().serviceHealth.selectedServiceId;
        dispatch({
            type: ActionType.MONTHLYDATA_QOS_FETCH
        });
        // Build http request and success handler in Promise<void> wrapper      
        MonthlyAPI.GetAvailabilityQosBudgetHeatmapData(monthstartdate)
            .then((monthlyData: IMonthlyReport[]) => {
                var liKPIValueKeys = [];
                liKPIValueKeys = monthlyData.length > 0 ? Object.keys(monthlyData[0].liKPIValues) : [];
                var tempC = [];
                var tempV = [];
                for (var j = 0; j < liKPIValueKeys.length; j++) {
                    if (liKPIValueKeys[j].toLowerCase() != 'displayorder') {
                        tempC.push(liKPIValueKeys[j].split('~')[0]);
                        tempV.push(liKPIValueKeys[j].split('~')[1]);
                    }
                }
                let uniquec = tempC.filter(function (elem, index, self) {
                    return index === self.indexOf(elem);
                });

                let uniquev = tempV.filter(function (elem, index, self) {
                    return index === self.indexOf(elem);
                });
                for (var k = 0; k < uniquev.length; k++) {
                    if (uniquev[k] == 'Val1') {
                        uniquev[k] = "Availability";
                    } else if (uniquev[k] == 'Val2') {
                        uniquev[k] = "QoS";
                    }
                }

                const MonthlyKPiArr = [];
                dispatch({
                    monthlyData,
                    uniquev,
                    uniquec,
                    liKPIValueKeys,
                    type: ActionType.MONTHLYDATA_QOS_RECEIVE
                });


            });
    },
};

