export type ISecuritySHRState = {
    readonly isLoading: boolean;
    readonly userId: string;
    readonly MeetingDate: Date;
    readonly MaxDate: Date;
    readonly DefaultDate: Date;
    readonly PublicServiceAnnouncementData: IPropPublicServiceAnnouncementSecuritySHR[];
    readonly SecurityAdminLock: ISecurityAdminLock[];
    readonly isPubServiceAccEditorAddEnabled: boolean;
    readonly isLockedSecurity: boolean;
    readonly isLockedSecurityArray: Date[];
    readonly isPSALockDisabledOnEdit: boolean;
    readonly psaaccordiontoggle: boolean;
    readonly SuccessStoriesData: IPropSuccessStoriesWishr[];
    readonly isSuccessStoriesEditorAddEnabled: boolean;
    readonly isLockedSuccessStories: boolean;
    readonly isLockedSuccessStoriesArray: Date[];
    readonly isSSLockDisabledOnEdit: boolean;
    readonly ssaccordiontoggle: boolean;
};
export type IResourceDetail = {
    readonly link: string;
    readonly description: string;
}

export type IPropPublicServiceAnnouncementSecuritySHR = {
    readonly id: number;
    readonly presenter: string;
    presenterEdit: string;
    readonly topicName: string;
    topicNameEdit: string;
    readonly timeNeeded: number;
    timeNeededEdit: number;
    readonly presentationLink: string;
    presentationLinkEdit: string;
    readonly isTopicApproved?: boolean;
    isTopicApprovedEdit?: boolean;
    kpiDate: string;
    readonly displayOrder: string;
    displayOrderEdit: string;
    isEdit: boolean;
    readonly occuredRingEdit: string;
    userName: string;
}

export type IPropSuccessStoriesWishr = {
    readonly id: number;
    readonly presenter: string;
    presenterEdit: string;
    readonly topicName: string;
    topicNameEdit: string;
    readonly timeNeeded: number;
    timeNeededEdit: number;
    readonly presentationLink: string;
    presentationLinkEdit: string;
    readonly isTopicApproved?: boolean;
    isTopicApprovedEdit?: boolean;
    kpiDate: string;
    readonly displayOrder: string;
    displayOrderEdit: string;
    isEdit: boolean;
    readonly occuredRingEdit: string;
    userName: string;
}

export type ISecurityAdminLock = {
    readonly id: number;
    readonly lock_Type: number;
    readonly locked_Week: string;
}

export type IPropPresenterTable = {
    readonly id: number;
    readonly serviceName: string;
    serviceNameEdit: string;
    readonly title: string;
    titleEdit: string;
    readonly ttn: string;
    ttnEdit: string;
    readonly commsPirLink: string;
    commsPirLinkEdit: string;
    readonly pirNumber?: number;
    pirNumberEdit?: number;
    readonly displayOrder: string;
    displayOrderEdit: string;
    readonly kpiDate: string;
    isEdit: boolean;
    readonly presenter: string;
    presenterEdit: string;
};

const _namespace = 'SecuritySHR';
export interface IActionType {
    readonly RESET_STATE: string;
    readonly SET_MEETINGDATE_DATE: string;
    readonly MEETINGDATE_DATE_CHANGE: string;
    readonly GET_WEEKLYIDSHR_REQUEST: string;
    readonly GET_WEEKLYIDSHR_SUCCESS: string;
    readonly GET_WEEKLYIDSHR_FAILURE: string;
    readonly SET_LOADING_TRUE: string;
    readonly SET_LOADING_FALSE: string;
    readonly ADDED_VISITED_USER: string;
    readonly SET_PIRLOCK: string;
    readonly GET_SECURITYADMINLOCK: string;
    readonly ENABLE_EDITORADD_PUBLICSERVICEACC: string;
    readonly GET_PUBLICSERVICEACC_SUCCESS: string;
    readonly GET_PUBLICSERVICEACC_FAILURE: string;
    readonly PUBLICSERVICEANNOUNCEMENT_CHANGE: string;
    readonly PSAACCORDION_STATUS: string;
    readonly ENABLE_EDITORADD_SUCCESSSTORIES: string;
    readonly GET_SUCCESSSTORIES_SUCCESS: string;
    readonly GET_SUCCESSSTORIES_FAILURE: string;
    readonly SUCCESSSTORIES_CHANGE: string;
    readonly SSACCORDION_STATUS: string;
}


export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/reset`,
    SET_MEETINGDATE_DATE: `${_namespace}/setmeetingdate`,
    MEETINGDATE_DATE_CHANGE: `${_namespace}/meetingdatechange`,
    GET_WEEKLYIDSHR_REQUEST: `${_namespace}/getweelyidshrrequest`,
    GET_WEEKLYIDSHR_SUCCESS: `${_namespace}/getweelyidshrsuccess`,
    GET_WEEKLYIDSHR_FAILURE: `${_namespace}/getweelyidshrfailure`,
    SET_LOADING_TRUE: `${_namespace}/loadingtrue`,
    SET_LOADING_FALSE: `${_namespace}/loadingfalse`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    SET_PIRLOCK: `${_namespace}/setpirlock`,
    GET_SECURITYADMINLOCK: `${_namespace}/getpiradminlock`,
    ENABLE_EDITORADD_PUBLICSERVICEACC: `${_namespace}/enableeditoraddpublicserviceacc`,
    GET_PUBLICSERVICEACC_SUCCESS: `${_namespace}/getpublicserviceaccsuccess`,
    GET_PUBLICSERVICEACC_FAILURE: `${_namespace}/getpublicserviceaccfailure`,
    PUBLICSERVICEANNOUNCEMENT_CHANGE: `${_namespace}/publiceservuceaccchange`,
    PSAACCORDION_STATUS: `${_namespace}/psaaccordionstatus`,
    ENABLE_EDITORADD_SUCCESSSTORIES: `${_namespace}/enableeditoraddsuccessstories`,
    GET_SUCCESSSTORIES_SUCCESS: `${_namespace}/getsuccessstoriessuccess`,
    GET_SUCCESSSTORIES_FAILURE: `${_namespace}/getsuccessstoriesfailure`,
    SUCCESSSTORIES_CHANGE: `${_namespace}/successstorieschange`,
    SSACCORDION_STATUS: `${_namespace}ssaccordionstatus`
});

