import { CommonAPI, WeeklyDataAPI, MSERHeatmapPreviewAPI } from '../../api';
import { IAppThunkAction, ReduxAction } from '../';
import { ActionType } from './types';
import { IServiceGroup } from '../home-tiles';
import moment from 'moment';
import { IWeeklyDataReport } from '../WeeklyAvailability/types';
import React, { useEffect, useRef, useState } from 'react';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../utils';
import { toast } from 'react-toastify';

export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }

    },
    onweeklyStartDateChange: (weeklyStartDate: Date): ReduxAction => ({

        weeklyStartDate: weeklyStartDate,
        type: ActionType.WEEKLY_START_DATE_CHANGE
    }),
    ClearWeeklyDate: (): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {
        dispatch({
            weeklyStartDate: new Date(),
            type: ActionType.WEEKLY_START_DATE_CHANGE
        });
    },
    GetServicegroups: (): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        MSERHeatmapPreviewAPI.GetServiceGroupsHeatmapTrend()
            .then((serviceGroupRaw: IServiceGroup[]) => {
                const serviceGroups = [];
                const servicegroupval = [];
                let servicegroupvalstring = '';
                if (isArrayWithLength(serviceGroupRaw)) {
                    serviceGroupRaw.map((item: IServiceGroup, key) => {
                        var element = serviceGroups.filter(serGroup => serGroup.name === item.groupName.replace(new RegExp('<br/>', 'g'), ' – '));
                        if (element.length === 0) {
                            serviceGroups.push({ name: item.groupName.replace(new RegExp('<br/>', 'g'), ' – '), value: item.groupId });
                            servicegroupval.push(item.groupId);
                            servicegroupvalstring = servicegroupvalstring + ',' + item.groupId;
                        }
                    })
                }

                dispatch({
                    servicegroupval,
                    serviceGroups,
                    servicegroupvalstring,
                    type: ActionType.GET_SERVICEGROUPS_SUCCESS
                });
            }).catch(error => {

                dispatch({
                    type: ActionType.GET_SERVICEGROUPS_FAIL
                });
            });
    },
    GetServices: (): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {
        MSERHeatmapPreviewAPI.GetHeatmapTrendServices()
            .then((serviceRaw: IServiceGroup[]) => {
                const services = [];
                const serviceval = [];
                let servicevalstring = '';
                if (isArrayWithLength(serviceRaw)) {
                    serviceRaw.map((item: IServiceGroup) => {
                        serviceval.push(item.serviceId);
                        services.push({ name: item.serviceName, value: item.serviceId, groupName: item.groupName });
                        servicevalstring = servicevalstring + ',' + item.serviceId;
                    })
                }
                dispatch({
                    serviceval,
                    services,
                    servicevalstring,
                    type: ActionType.GET_SERVICELISTS_SUCCESS
                });

            }).catch(error => {
                dispatch({
                    type: ActionType.GET_SERVICELISTS_FAIL
                });
            });
    },
    servicegroupvalchange: (value): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {
        let services = getSate().WeeklyAvailability.services;
        let servicegroupvalstring = '';
        let serviceval = [];
        let servicevalstring = '';
        if (isArrayWithLength(value)) {
            value.map((obj) => {
                if (isArrayWithLength(services)) {
                    services.map((service) => {
                        if (obj === service.groupName) {
                            serviceval.push(service.value);
                            servicevalstring = servicevalstring + ',' + service.value;
                        }

                    })
                }
                servicegroupvalstring = servicegroupvalstring + ',' + obj;
            })
        }
        dispatch({
            servicegroupval: value,
            servicegroupvalstring,
            serviceval,
            servicevalstring,
            type: ActionType.SERVICE_GROUPVAL_CHANGE
        });
    },
    EditTarget: (e, targetName: any): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {
        let rowData = targetName;
        let targetJson = [{
            serviceId: rowData.serviceId,
            serviceName: rowData.serviceName,
            originalTarget: rowData.targetName,
            viewName: rowData.viewName,
            heatmapViewId: rowData.viewId,
            updatedTarget: '',
            justification: ''
        }];
        getSate().WeeklyAvailability.editedTargetData = targetJson;
        let originalTarget = rowData.targetName;
        dispatch({
            editedTargetData: getSate().WeeklyAvailability.editedTargetData,
            TargetEditPop: true,
            originalTargetValue: originalTarget,
            type: ActionType.SET_TARGET_POPUP
        });
    },
    TargetEditPopClose: (): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {
        dispatch({
            TargetEditPop: false,
            type: ActionType.SET_TARGET_POPUP_CLOSE
        });
    },
    ChangeTargetValue: (key: any): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {
        dispatch({
            updatedTargetValue: key,
            type: ActionType.UPDATEDTARGET
        });
    },
    ChangeJustification: (key: any): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {
        dispatch({
            justificationDetails: key,
            type: ActionType.UPDATEJUSTIFICATION
        });
    },
    ClearTarget: (): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {
        dispatch({
            justificationDetails: '',
            updatedTargetValue: '',
            type: ActionType.CLEARTARGET
        });
    },
    SubmitforApproval: (): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {
        let serviceId = getSate().WeeklyAvailability.editedTargetData.reduce(x => x).serviceId;
        let viewId = getSate().WeeklyAvailability.editedTargetData.reduce(x => x).heatmapViewId;
        let serviceName = getSate().WeeklyAvailability.editedTargetData.reduce(x => x).serviceName;
        let orginalTarget = getSate().WeeklyAvailability.editedTargetData.reduce(x => x).originalTarget;
        let viewName = getSate().WeeklyAvailability.editedTargetData.reduce(x => x).viewName;
        let updatedTarget = getSate().WeeklyAvailability.updatedTargetValue;
        let justification = getSate().WeeklyAvailability.justificationDetails;
        let requesterEmail = getSate().authUser.login;
        let requestedDate = new Date();

        const requestobj = {
            ServiceId: serviceId,
            ViewId: viewId,
            OrginalTarget: orginalTarget,
            NewTarget: updatedTarget,
            Justification: justification,
            RequestedBy: requesterEmail,
            RequestedDate: requestedDate,
            ApprovalStatus: 'Pending'
        };
        if (!isNullOrUndefined(updatedTarget)) {
            if (/[0-9][0-9]\.[0-9][0-9][%]/.test(updatedTarget)) {
                dispatch({
                    type: ActionType.SET_LOADING_TRUE
                });
                WeeklyDataAPI.SubmitHeatmapTargetApprovalRequest(requestobj)
                    .then((response: number) => {
                        if (response >= 1) {
                            toast.success(
                                renderToastifyMsg('Request is being processed',
                                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                            );
                            const requestDetails = {
                                viewName : viewName,
                                serviceName : serviceName,
                                OrginalTarget: orginalTarget,
                                NewTarget: updatedTarget,
                                RequestedBy: requesterEmail
                            }
                            WeeklyDataAPI.SendHeatmapUpdateRequestEmail(requestDetails)
                                .then((response: number) => {
                                    toast.success(
                                        renderToastifyMsg('Heatmap Target Change request email notification is sent',
                                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                                    );
                                }).catch(error => {
                                    toast.error(
                                        renderToastifyMsg("Failed to send email notification",
                                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                                    );
                                });
                            dispatch({
                                TargetEditPop: false,
                                type: ActionType.SET_TARGET_POPUP_CLOSE
                            });
                        }
                    });
            } else {
                toast.error(
                    renderToastifyMsg("Please enter value in required format",
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                );
            }
        }
        else {
            toast.error(
                renderToastifyMsg("Please enter New Target value",
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
            );
        }
    },
    serviceschange: (value): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {
        let servicegroupval = [];
        let servicegroupvalstring = '';
        let services = getSate().WeeklyAvailability.services;
        let servicevalstring = ''
        if (isArrayWithLength(value)) {
            value = value.filter(x => x);
            value.map((obj) => {
                services.map((service) => {
                    if (service.value === obj) {
                        if (servicegroupval.indexOf(service.groupName) === -1) {
                            servicegroupval.push(service.groupName);
                            servicegroupvalstring = servicegroupvalstring + ',' + service.groupName;
                        }
                    }
                });

                servicevalstring = servicevalstring + ',' + obj;
            })
        }
        dispatch({
            servicegroupval: servicegroupval,
            servicegroupvalstring: servicegroupvalstring,
            serviceval: value,
            servicevalstring,
            type: ActionType.SERVICE_VAL_CHANGE
        });
    },
    searchtextboxchange: (value): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {
        dispatch({
            globalsearch: value.target.value,
            type: ActionType.SEARCH_TEXTBOX_CHANGE
        });
    },
    clearfilters: (servicegroupval, servicegroupvalstring, serviceval, servicevalstring): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {
        dispatch({
            servicegroupval: servicegroupval,
            servicegroupvalstring: servicegroupvalstring,
            serviceval: serviceval,
            servicevalstring: servicevalstring,
            type: ActionType.CLEAR_FILTERS
        });
    },
    CopyURL: (): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {
        let sId = getSate().WeeklyAvailability.serviceval;
        let sDate = getSate().WeeklyAvailability.weeklyStartDate;
        let startDate = moment(sDate, 'MM/DD/YYYY');
        var url = window.location.href;
        let index = url.indexOf("?");
        if (index >= 0) {
            url = url.substring(0, index);
        }
        url += "?frDate=@Today-" + moment().diff(startDate, "days");
        url += "&sid=" + sId;
        var textField = document.createElement('textarea');
        textField.innerText = url;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        toast.success(
            renderToastifyMsg('Link copied to clipboard',
                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
        );
    },
    seturlparamempty: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_URL_PARAM_EMPTY
        });
    },

    getWeeklyData: (weekstartdate: string, serviceids: string): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {
        weekstartdate = moment(weekstartdate).format('MM/DD/YYYY');
        let sid = getSate().serviceHealth.selectedServiceId;
        dispatch({
            type: ActionType.WEEKLYDATA_FETCH
        });
        if (!isNullOrUndefined(serviceids)) {
            //Build http request and success handler in Promise<void> wrapper      
            WeeklyDataAPI.GetWeeklyAvailabilityData(weekstartdate, serviceids)
                .then((weekData: IWeeklyDataReport[]) => {
                    var liKPIValueKeys = [];
                    if (isArrayWithLength(weekstartdate)) {
                        weekData[0].serviceId = sid;
                        weekData[0].WeekStartDate = weekstartdate;
                    }
                    liKPIValueKeys = weekData.length > 0 ? Object.keys(weekData[0].liKPIValues) : [];
                    const WeeklyheaderArr = [];
                    var header = '';
                    for (var countKPI = 0; countKPI < liKPIValueKeys.length; countKPI++) {
                        header = liKPIValueKeys[countKPI];
                        if (countKPI == liKPIValueKeys.length) {
                            WeeklyheaderArr.push({ width: '145px', val: header.charAt(0).toUpperCase() + header.slice(1) });
                        }
                        else {
                            WeeklyheaderArr.push({ width: '5%', val: header.charAt(0).toUpperCase() + header.slice(1) });
                        }
                    }
                    const WeeklyKPiArr = [];
                    const WeeklyAggUpdate = [];
                    var url = window.location.href;
                    let index = url.indexOf("?");

                    if (index >= 0) {
                        let serviceidsarray = url.split('sid=')[1].split(',');
                        let weeklyFilteredData = [];
                        callservicechanges(dispatch, serviceidsarray);
                    }
                    else {
                        callservicechanges(dispatch, serviceids.split(','));
                    }
                    dispatch({
                        weekData,
                        WeeklyheaderArr,
                        WeeklyAggUpdate: WeeklyAggUpdate,
                        WeeklyKPiArr,
                        liKPIValueKeys,
                        type: ActionType.WEEKLYDATA_RECEIVE
                    });
                    dispatch({
                        type: ActionType.SET_URL_PARAM_EMPTY
                    });
                });
        }
        else {
            MSERHeatmapPreviewAPI.GetHeatmapTrendServices()
                .then((serviceRaw: IServiceGroup[]) => {
                    const services = [];
                    const serviceval = [];
                    let servicevalstring = '';
                    if (isArrayWithLength(serviceRaw)) {
                        serviceRaw.map((item: IServiceGroup) => {
                            serviceval.push(item.serviceId);
                            services.push({ name: item.serviceName, value: item.serviceId, groupName: item.groupName });
                            servicevalstring = servicevalstring + ',' + item.serviceId;
                        })
                    }
                    WeeklyDataAPI.GetWeeklyAvailabilityData(weekstartdate, servicevalstring)
                        .then((weekData: IWeeklyDataReport[]) => {
                            var liKPIValueKeys = [];
                            if (isArrayWithLength(weekstartdate)) {
                                weekData[0].serviceId = sid;
                                weekData[0].WeekStartDate = weekstartdate;
                            }
                            liKPIValueKeys = weekData.length > 0 ? Object.keys(weekData[0].liKPIValues) : [];
                            const WeeklyheaderArr = [];
                            var header = '';
                            for (var countKPI = 0; countKPI < liKPIValueKeys.length; countKPI++) {
                                header = liKPIValueKeys[countKPI];
                                if (countKPI == liKPIValueKeys.length) {
                                    WeeklyheaderArr.push({ width: '145px', val: header.charAt(0).toUpperCase() + header.slice(1) });
                                }
                                else {
                                    WeeklyheaderArr.push({ width: '5%', val: header.charAt(0).toUpperCase() + header.slice(1) });
                                }
                            }
                            const WeeklyKPiArr = [];
                            const WeeklyAggUpdate = [];
                            var url = window.location.href;
                            let index = url.indexOf("?");
                            if (index >= 0) {
                                let serviceidsarray = url.split('sid=')[1].split(',');
                                callservicechanges(dispatch, serviceidsarray);
                            }
                            dispatch({
                                weekData,
                                WeeklyheaderArr,
                                WeeklyAggUpdate: WeeklyAggUpdate,
                                WeeklyKPiArr,
                                liKPIValueKeys,
                                type: ActionType.WEEKLYDATA_RECEIVE
                            });
                            dispatch({
                                type: ActionType.SET_URL_PARAM_EMPTY
                            });
                        });
                    dispatch({
                        serviceval,
                        services,
                        servicevalstring,
                        type: ActionType.GET_SERVICELISTS_SUCCESS
                    });

                }).catch(error => {
                    dispatch({
                        type: ActionType.GET_SERVICELISTS_FAIL
                    });
                });
        }
    },
};

const callservicechanges = (dispatch, value) => {
    dispatch(actionCreators.serviceschange(value));
}