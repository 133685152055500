import { IViewDetails } from '../store/EmailConfiguration';
import { BaseService } from './base.service';


class EmailConfigurationService extends BaseService {

    private static _emailConfigurationService: EmailConfigurationService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): EmailConfigurationService {
        return (this._emailConfigurationService || (this._emailConfigurationService = new this('EmailConfiguration')));
    }

    public async GetEmailConfigDataSourceDetails(serviceId: number): Promise<object[]> {
        const { data } = await this.$http.get<object[]>(`GetEmailConfigDataSourceDetails?serviceId=${serviceId}`);
        return data;
    }

    public async GetEmailConfigNames(serviceId: number): Promise<object[]> {
        const { data } = await this.$http.get<object[]>(`GetEmailConfigNames?serviceId=${serviceId}`);
        return data;
    }
    public async SaveEmailConfigDataSourceDetails(obj): Promise<number> {
        const { data } = await this.$http.post<number>("SaveEmailConfigDataSourceDetails", obj);
        return data;
    }
    public async SaveEmailIds(serviceId: number, toEmailIds: string, ccEmailIds: string): Promise<number> {
        const { data } = await this.$http.post<number>(`SaveEmailIds?serviceId=${serviceId}&toEmailIds=${toEmailIds}&copyEmailIds=${ccEmailIds}`);
        return data;        
    }
}

export const EmailConfigAPI = EmailConfigurationService.Instance;