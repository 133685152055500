import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { ReduxAction, IAppThunkAction } from '../../../store';
import { isArrayWithLength, isNullOrUndefined } from '../../../utils';
import classnames from 'classnames';
import { FontAwesomeIconMemo } from '../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DatePicker, { getDay } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';
import { Checkbox } from '../../../components';
import applySortOn from "../../../utils/customSortMethod";

import { faQuestionCircle, faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import {
    IDailyData, UniqueDateHeaders, UniqueFixedHeaderColumns, HmMetric, IComments, IColorScheme, IAllViewDetails, IMSERAdditionalInfo,
    PropFleetutilizationHM, DateLabels, DailyFleet
} from '../../../store/service-health';
import {
    Card, Row, Col, Container, CardBody
} from 'reactstrap';
import { NavLink } from 'reactstrap';
import moment from 'moment';
type MSERPresentationViewTableProps = {
    currentMSERPresentationVIEW: any;
    currentindex: number;
    lastindex: number;
    readonly startDate: Date;
    readonly endDate: Date;
    readonly onStartDateChange: (startDate: Date) => ReduxAction;
    readonly onEndDateChange: (endDate: Date) => ReduxAction;
    HeatmapPresentationData: IDailyData[];
    PresentationuniqueDateHeaders: UniqueDateHeaders[];
    uniqueFixedHeaderColumns: UniqueFixedHeaderColumns[];
    datacentersList: any;
    additionalInfoData: IMSERAdditionalInfo[];
    Presentationviewdetailsdata: any;
    PresentationSelectedView: any;
    Presentationcomments: IComments[];
    PresentationcolorScheme: IColorScheme[];
    IsdataLoaded: boolean;
    volumeMeasure: string;
    failurevolumeMeasure: string;
    showMSERComments: boolean;
    CommentSummary: any;
    checkedFilterYellowDailyData: boolean;
    checkedFilterRedDailyData: boolean;
    filterHeatmapMSERData: any;
    onFilterYellowDailyData: any;
    onFilterRedDailyData: any;
    toggleMSERCommentsView: any;
    getPreviousService: any;
    getNextService: any;
    IsPresentationviewsloaded: any;
    openPresentationView: any;
    dcMaxLength: number;
};
const tdvalue = {
    paddingRight: '0px',
    fontSize: '11px',
    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans - serif!important'
}

const MSERPresentationViewTable = React.memo<MSERPresentationViewTableProps>(({
    currentMSERPresentationVIEW,
    currentindex,
    lastindex,
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange,
    HeatmapPresentationData,
    PresentationuniqueDateHeaders,
    uniqueFixedHeaderColumns,
    datacentersList,
    additionalInfoData,
    Presentationviewdetailsdata,
    PresentationSelectedView,
    Presentationcomments,
    PresentationcolorScheme,
    IsdataLoaded,
    volumeMeasure,
    failurevolumeMeasure,
    showMSERComments,
    CommentSummary,
    checkedFilterYellowDailyData,
    checkedFilterRedDailyData,
    filterHeatmapMSERData,
    onFilterYellowDailyData,
    onFilterRedDailyData,
    toggleMSERCommentsView,
    getPreviousService,
    getNextService,
    IsPresentationviewsloaded,
    openPresentationView,
    dcMaxLength

}) => {
    // Sort Logic Start
    //Removing Total Volume and Daily Agg. from the sorting
    var tempDailyData = [...HeatmapPresentationData];

    for (var i = 0; i < tempDailyData.length; i++) {
        if (tempDailyData[i].key == "Total Volume") {
            var dailyTotal = tempDailyData.splice(i, 1)[0]
        }
        if (tempDailyData[i].key == "Daily Agg.") {
            var dailyAvg = tempDailyData.splice(i, 1)[0]
        }
    }

    const { result: dailyResult, requestSort: dailyRequestSort, sortConfig: dailySortConfig } = applySortOn(tempDailyData)
    if (dailyTotal && dailyAvg) {
        dailyResult.push(dailyTotal, dailyAvg)
    }
    HeatmapPresentationData = [...dailyResult];



    //This is for styles to indicate order
    const getClassNamesForDaily = (name) => {
        if (!dailySortConfig) {
            return;
        }
        return dailySortConfig.key === name ? dailySortConfig.direction : undefined;
    };
    const Isfailurevolume = (val: any) => {
        if (val.length > 0) {
            for (var i = 0; i < val.length; i++) {
                if (val[i].isFailureVolume) {
                    return true;
                }
            }
            return false;
        }
    }
    //============= to set the volume number as comma seperated ===============//
    const thousands_separators = (num) => {
        var num_parts = num.toString().split(".");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return num_parts.join(".");
    }
    return (
        <div>
            <Card className="cardstyle">
                <CardBody className="cardbodyhederstyle">
                    <Row>

                        <Col lg="12">
                            <h3 className="headertext"> MSER PRESENTATION Heat Map View</h3>
                        </Col>
                    </Row>
                </CardBody>
            </Card>    
            <header id='header'>
                <nav>
                    <div className='content'>
                        {!(isNullOrUndefined(currentMSERPresentationVIEW)) ?
                            <React.Fragment>
                                <Card>
                                    <Row>
                                        <Col lg='4'>
                                            {(currentindex !== 0)
                                                ? <NavLink onClick={getPreviousService} className="prevservice">
                                                    <FontAwesomeIconMemo  icon={'angle-left'} className="leftrighticons" />&nbsp;&nbsp;Previous</NavLink>
                                                : <NavLink disabled={true} className="prevservicedisabled">Previous</NavLink>}



                                        </Col>
                                        <Col lg='4'>
                                            <NavLink className="currentservice">{currentMSERPresentationVIEW.serviceName}</NavLink>
                                        </Col>
                                        <Col lg='4'>
                                            {(currentindex !== lastindex)
                                                ?
                                                <NavLink disabled={currentindex === lastindex} onClick={getNextService}  className="nextservice" >Next&nbsp;&nbsp;
                                                        <FontAwesomeIconMemo icon={'angle-right'} className="leftrighticons" /></NavLink>
                                                : <NavLink disabled={true} className="nextservicedisabled">Next</NavLink>
                                            }

                                        </Col>
                                    </Row>
                                </Card>
                                <Container className="container2">
                                    <Row>
                                        <Col xs='1'>
                                            <span className="viewlabel">View Name : </span>

                                        </Col>
                                        <Col xs='2' className="paddingleft0">
                                            <input disabled={true} type="text" className="form-control" value={currentMSERPresentationVIEW.viewName} />
                                        </Col>
                                        <Col xs='1.25' className="paddingrightleft">
                                            <DatePicker className='InputStyle'
                                                selected={startDate}
                                                onChange={onStartDateChange}
                                                maxDate={new Date()}
                                                calendarIcon={true}
                                                calendarAriaLabel={true}
                                                placeholderText='Start'
                                            />

                                        </Col>&nbsp;&nbsp;&nbsp;
                                         <Col xs='1.25' className="paddingrightleft">
                                            <DatePicker className='InputStyle'
                                                selected={endDate}
                                                onChange={onEndDateChange}
                                                maxDate={new Date()}
                                                minDate={startDate}
                                                calendarIcon={true}
                                                calendarAriaLabel={true}
                                                placeholderText='End'
                                            />
                                        </Col>&nbsp;&nbsp;
                                        <Col xs='3.5' className="paddingtop8px" >
                                            <FontAwesomeIconMemo title="Filter the data" onClick={filterHeatmapMSERData} icon={'filter'} className='icon-format' />&nbsp;&nbsp;
                                            <FontAwesomeIconMemo title="Filter the Yellow data" onClick={onFilterYellowDailyData} icon={'filter'} className={checkedFilterYellowDailyData ? 'icon-format-yellow' : 'icon-format-yellow-invert'} />&nbsp;&nbsp;
                                            <FontAwesomeIconMemo title="Filter the Red data" icon={'filter'} onClick={onFilterRedDailyData} className={checkedFilterRedDailyData ? 'icon-format-red' : 'icon-format-red-invert'} />&nbsp;&nbsp;
                                            <FontAwesomeIconMemo title='Toggle MSER Comments and Heatmap' onClick={toggleMSERCommentsView} icon={showMSERComments ? 'fire' : 'table'}
                                                className='icon-format' />&nbsp;&nbsp;&nbsp;
                                              <FontAwesomeIconMemo title="Dashboard View" icon={'tachometer-alt'} onClick={openPresentationView} className='icon-format' />&nbsp;&nbsp;                                          
                                        </Col>
                                       
                                    </Row>
                                </Container>

                                <br />
                                <React.Fragment>
                                    {
                                        (showMSERComments) ?
                                            <div>
                                                {isArrayWithLength(additionalInfoData) ?
                                                    <table className='table is-fullwidth' id="msercomments">
                                                        <thead>
                                                            <tr style={{ backgroundColor: '#3875b2', fontSize: '12px', padding: '3px' }}>
                                                                <th className='headerdisplay' style={{ width: '10%' }}>Availability Issue</th>
                                                                <th className='headerdisplay' style={{ width: '20%' }}>Impact(Minor/Major)</th>
                                                                <th className='headerdisplay' style={{ width: '35%' }}>Root Cause</th>
                                                                <th className='headerdisplay' style={{ width: '15%' }}>Mitigation</th>
                                                                <th className='headerdisplay' style={{ width: '20%' }}>Learnings and Repair Items</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{ backgroundColor: 'rgba(234, 216, 128, 0.31)' }}>
                                                            {
                                                                isArrayWithLength(additionalInfoData) && additionalInfoData.map((aid: IMSERAdditionalInfo, val) => (
                                                                    <React.Fragment key={val}>
                                                                        <tr>
                                                                            <td className='msercomments'>{aid.keyDate}</td>
                                                                            <td className='msercomments' dangerouslySetInnerHTML={{ __html: aid.impact }} />
                                                                            <td className='msercomments' dangerouslySetInnerHTML={{ __html: aid.rootCause }} />
                                                                            <td className='msercomments' dangerouslySetInnerHTML={{ __html: aid.mitigation }} />
                                                                            <td className='msercomments' dangerouslySetInnerHTML={{ __html: aid.learningsAndRepairItems }} />
                                                                        </tr>
                                                                    </React.Fragment>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                    :
                                                    <React.Fragment>
                                                        <div style={{ color: 'Red', fontWeight: 'bold', textAlign: 'center' }}> <br />No MSER Comments available!! </div>
                                                    </React.Fragment>
                                                }
                                            </div>
                                            :
                                            <React.Fragment>
                                                {
                                                    (isArrayWithLength(HeatmapPresentationData))
                                                        ?
                                                        <React.Fragment>
                                                            
                                                            <div style={{ overflowX: 'auto' }} className='sticky-wrapper'>
                                                                <div className={(dcMaxLength < 20) ? ((currentMSERPresentationVIEW.totalsLabel.length > 6) ? 'sticky-scroller_vol_MSER' : 'sticky-scroller_MSER')
                                                                    : (dcMaxLength > 20 && dcMaxLength < 50) ? ((currentMSERPresentationVIEW.totalsLabel.length > 6) ? 'sticky-scroller-20_vol_MSER' : 'sticky-scroller-20_MSER')
                                                                        : ((currentMSERPresentationVIEW.totalsLabel.length > 6) ? 'sticky-scroller-50_vol_MSER' : 'sticky-scroller-50_MSER')}>
                                                                    <table className='sticky-table' id='tdDailydata'>
                                                                        <thead>
                                                                            <tr>
                                                                                <th className={dcMaxLength < 20 ? 'Dailydatath datacenterwidth sticky-fixed-col-th ' + getClassNamesForDaily('key') : (dcMaxLength > 20 && dcMaxLength < 50) ? 'Dailydatath datacenterwidth sticky-fixed-col-th-20 ' + getClassNamesForDaily('key') : 'Dailydatath datacenterwidth sticky-fixed-col-th-50 ' + getClassNamesForDaily('key')} onClick={() => dailyRequestSort('key')}>Data Center</th>
                                                                                <th className={(dcMaxLength < 20) ? (currentMSERPresentationVIEW.totalsLabel.length > 6 ? 'Dailydatath sticky-fixed-col2-th_vol_MSER ' + getClassNamesForDaily('sortVolume') : 'Dailydatath sticky-fixed-col2-th_MSER ' + getClassNamesForDaily('sortVolume'))
                                                                                    : (dcMaxLength > 20 && dcMaxLength < 50) ? ((currentMSERPresentationVIEW.totalsLabel.length > 6) ? 'Dailydatath sticky-fixed-col2-th-20_vol_MSER ' + getClassNamesForDaily('sortVolume') : 'Dailydatath sticky-fixed-col2-th-20_MSER ' + getClassNamesForDaily('sortVolume'))
                                                                                        : ((currentMSERPresentationVIEW.totalsLabel.length > 6) ? 'Dailydatath sticky-fixed-col2-th-50_vol_MSER ' + getClassNamesForDaily('sortVolume') : 'Dailydatath sticky-fixed-col2-th-50_MSER ' + getClassNamesForDaily('sortVolume'))}
                                                                                    onClick={() => dailyRequestSort('sortVolume')}>{currentMSERPresentationVIEW.totalsLabel}({volumeMeasure}) </th>
                                                                                {
                                                                                    (Isfailurevolume(HeatmapPresentationData) === true) ? <th className={'Dailydatath ' + getClassNamesForDaily('sortFailureVolume')} onClick={() => dailyRequestSort('sortFailureVolume')}>Failure Volume({failurevolumeMeasure}) </th> : <React.Fragment></React.Fragment>
                                                                                }
                                                                                {isArrayWithLength(uniqueFixedHeaderColumns) && uniqueFixedHeaderColumns.map((fhc: UniqueFixedHeaderColumns, key) => (
                                                                                    <th className='Dailydatath' key={key}>{fhc}</th>
                                                                                ))}


                                                                                <th className='Dailydatath'>Agg.</th>
                                                                                {isArrayWithLength(PresentationuniqueDateHeaders) && PresentationuniqueDateHeaders.map((dh: UniqueDateHeaders, key) => (

                                                                                    <th className='Dailydatath' key={key}>{moment(dh.key).format('MM/DD')}</th>
                                                                                ))}
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {

                                                                                isArrayWithLength(HeatmapPresentationData) && HeatmapPresentationData.map((hmd: IDailyData, val) => (
                                                                                    <React.Fragment key={val}>
                                                                                        {
                                                                                            (!hmd.isOutOfSlaUptime && !hmd.isDailyVolume) ? <tr style={{ border: 'none' }}>
                                                                                                <td className={dcMaxLength < 20 ? "datacenterwidth sticky-fixed-col" : (dcMaxLength > 20 && dcMaxLength < 50) ? "datacenterwidth sticky-fixed-col-20" : "datacenterwidth sticky-fixed-col-50"} style={{ fontSize: '14px', textAlign: 'left' }} key={val}><span style={{ paddingLeft: '4px', wordBreak: 'break-word' }}>{hmd.key}</span></td>
                                                                                                <td className={(dcMaxLength < 20) ? ((currentMSERPresentationVIEW.totalsLabel.length > 6) ? "sticky-fixed-col2_vol_MSER" : "sticky-fixed-col2_MSER")
                                                                                                    : (dcMaxLength > 20 && dcMaxLength < 50) ? ((currentMSERPresentationVIEW.totalsLabel.length > 6) ? "sticky-fixed-col2-20_vol_MSER" : "sticky-fixed-col2-20_MSER")
                                                                                                        : ((currentMSERPresentationVIEW.totalsLabel.length > 6) ? "sticky-fixed-col2-50_vol_MSER" : "sticky-fixed-col2-50_MSER")} style={{ fontSize: '14px' }}>{thousands_separators(hmd.volume)}</td>
                                                                                                {(Isfailurevolume(currentMSERPresentationVIEW.totalsLabel) === true) ? <td className="textalignright" style={{ fontSize: '12px' }}>{thousands_separators(hmd.failureVolume)}</td> : <React.Fragment></React.Fragment>}
                                                                                                {isArrayWithLength(hmd.fixedColumns) && hmd.fixedColumns.map((fc: UniqueDateHeaders, key) =>
                                                                                                    < td className="textalignleft" style={{ fontSize: '14px' }} key={key}>{fc.value}</td>
                                                                                                )}
                                                                                                {
                                                                                                    (hmd.key !== 'Total Volume' && hmd.key !== 'Daily Agg.') ?
                                                                                                        (hmd.avg === null) ? <td></td> :
                                                                                                            <td className="textAgg" style={JSON.parse((hmd.avg.colorType != null) ? hmd.avg.colorType.replace('#c6e0b4', '#CEF8C1') : hmd.avg.colorType)} ><span style={tdvalue}>{hmd.avg.percentage}</span></td> : <td></td>
                                                                                                }
                                                                                                {
                                                                                                    hmd.metricList.sort().map((hmm: HmMetric, key) =>
                                                                                                        <td className={dcMaxLength > 50 ? "tdvalue-50" : "tdvalue"} style={JSON.parse((hmm.value.colorType != null) ? hmm.value.colorType.replace('#c6e0b4', '#CEF8C1') : hmm.value.colorType)} key={key} >
                                                                                                            <div className={`rowdisplay ${hmm.comment ? 'note' : ''} ${hmm.hasMserComment ? 'mser' : ''}`} >

                                                                                                                {(hmm.comment)
                                                                                                                    ?
                                                                                                                    <div className='commentSummary' summary-content={CommentSummary(hmm, hmd.key)}>

                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    <React.Fragment></React.Fragment>}

                                                                                                                <span style={tdvalue} title={hmm.tooltip} >
                                                                                                                    {
                                                                                                                        (hmm.value.percentage === '' || hmm.value.percentage === undefined || hmm.value.percentage === null || hmm.value.percentage === 'NaN')
                                                                                                                            ? <React.Fragment>
                                                                                                                                <span style={{ textAlign: 'center' }}>--</span>
                                                                                                                            </React.Fragment>
                                                                                                                            : <React.Fragment><span>{hmm.value.percentage}</span>
                                                                                                                            </React.Fragment>
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </div>

                                                                                                        </td>

                                                                                                    )}

                                                                                            </tr> : <React.Fragment></React.Fragment>
                                                                                        }
                                                                                    </React.Fragment>
                                                                                ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <Row style={{ margin: '5px 0px 5px 5px', fontSize: 'small' }}>
                                                                <React.Fragment>
                                                                    {
                                                                        (isArrayWithLength(Presentationcomments))
                                                                            ?
                                                                            <Col lg='2'>
                                                                                <div style={{ position: 'absolute', borderLeft: '15px solid transparent', borderTop: '15px solid #f00' }}></div>
                                                                                <span style={{ marginLeft: '20px' }}>Daily comments</span>
                                                                            </Col>
                                                                            :
                                                                            <React.Fragment></React.Fragment>
                                                                    }
                                                                </React.Fragment>
                                                                <React.Fragment>
                                                                    {
                                                                        (isArrayWithLength(additionalInfoData))
                                                                            ?
                                                                            <Col lg='2'>
                                                                                <div style={{ position: 'absolute', borderLeft: '15px solid transparent', borderBottom: '15px solid #0089ff' }}></div><span style={{ marginLeft: '20px' }}>MSER comments</span>
                                                                            </Col>
                                                                            :
                                                                            <React.Fragment></React.Fragment>
                                                                    }
                                                                </React.Fragment>
                                                            </Row>
                                                            <Row>
                                                                {
                                                                    (isArrayWithLength(Presentationcomments))
                                                                        ?
                                                                        <Col lg='6'>

                                                                            {Presentationcomments.map((item: IComments, index) => (
                                                                                <React.Fragment key={index}>
                                                                                    {
                                                                                        <li style={{ listStyle: 'none' }} key={index}>{index + 1}. {item.comment} - {item.keyDate}
                                                                                            {(item.incident && (item.incident != '0'))
                                                                                                ?
                                                                                                <span>
                                                                                                    <a>ICM#</a>
                                                                                                    <a href="https://portal.microsofticm.com/imp/v3/incidents/details/{{item.incident}}/home" target="_blank"> {item.incident}</a>
                                                                                                </span>
                                                                                                :
                                                                                                <span></span>
                                                                                            }
                                                                                        </li>
                                                                                    }
                                                                                </React.Fragment>



                                                                            ))}

                                                                        </Col>
                                                                        :
                                                                        <React.Fragment></React.Fragment>

                                                                }
                                                                <React.Fragment>
                                                                    {
                                                                        (isArrayWithLength(PresentationcolorScheme))
                                                                            ?
                                                                            <Col lg='3'>
                                                                                <div>
                                                                                    {isArrayWithLength(PresentationcolorScheme) && PresentationcolorScheme.map((clr: IColorScheme, index) => (
                                                                                        <React.Fragment key={index}>
                                                                                            {
                                                                                                <Row>
                                                                                                    <Col lg='2' key={index}><div style={{ paddingBottom: '5px' }}><div className='DailyColrLogic' style={JSON.parse(clr.colorType)}>&nbsp;</div></div></Col>
                                                                                                    <Col lg='10'>{clr.description}</Col>
                                                                                                </Row>
                                                                                            }
                                                                                        </React.Fragment>


                                                                                    ))}


                                                                                </div>
                                                                            </Col>
                                                                            : <React.Fragment></React.Fragment>
                                                                    }
                                                                </React.Fragment>


                                                                <Col lg='3'>
                                                                    {(!isNullOrUndefined(currentMSERPresentationVIEW)) ?
                                                                        <React.Fragment>
                                                                            <Row>
                                                                                <Col lg='12'>  Display no. of Decimals :{currentMSERPresentationVIEW.noOfDecimalText}</Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col lg='12'>   Coloring logic :{currentMSERPresentationVIEW.colorLogicText} </Col>
                                                                            </Row>
                                                                        </React.Fragment>
                                                                        : <React.Fragment></React.Fragment>}
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                        : <React.Fragment>
                                                            {
                                                                (IsdataLoaded === true) ? <div style={{ color: 'Red', fontWeight: 'bold', textAlign: 'center' }}> <br />
                                                                    {(checkedFilterYellowDailyData === true && checkedFilterRedDailyData === true) ? <React.Fragment>No Red & Yellow Filter data available for the selected service</React.Fragment>
                                                                        : (checkedFilterYellowDailyData === true) ? <React.Fragment>No Yellow Filter data available </React.Fragment>
                                                                            : (checkedFilterRedDailyData === true) ? <React.Fragment>No Red Filter data available </React.Fragment>
                                                                                : <React.Fragment>No data available </React.Fragment>}
                                                                </div> : <React.Fragment></React.Fragment>
                                                            }

                                                        </React.Fragment>
                                                }
                                            </React.Fragment>
                                    }

                                </React.Fragment>
                            </React.Fragment>
                            : <React.Fragment>
                                {
                                    (IsPresentationviewsloaded ?
                                        <React.Fragment><div style={{ color: 'Red', fontWeight: 'bold', textAlign: 'center' }}><br />
                                           No Heatmap view available for MSER PRESENTATION View!
                                    </div></React.Fragment> : <React.Fragment></React.Fragment>)
                                }
                            </React.Fragment>
                        }
                    </div>
                </nav>
            </header>
        </div>

    )
});

MSERPresentationViewTable.displayName = 'MSERPresentationViewTable';
export default MSERPresentationViewTable;