import React, { useState, Fragment, useRef } from 'react';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import { IPropPlatformPMTools } from '../../../store/PlatformTools';
import { ReduxAction, IAppThunkAction } from '../../../store';
import { IMSERHeatmapTrendReport } from '../../../store/MSERHeatmapTrend';
import { FontAwesomeIconMemo } from '../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import applySortOn from "../../../utils/customSortMethod"
import { MultiSelect } from 'primereact/multiselect';
import {
    Card, Row, Col, Container, CardBody
} from 'reactstrap';
import DatePicker, { getDay } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';
import { Dropdown } from 'primereact/dropdown';
type MSERHeatmapTrendTableProps = {
    readonly mserHeatmapTrendStartDate: Date;
    readonly onmserHeatmapTrendStartDateChange: (mserHeatmapTrendStartDate: Date) => ReduxAction;
    MSERHeatmapTrendData: IMSERHeatmapTrendReport[];
    MSERHeatmapTrendheaderArr: any,
    MSERHeatmapTrendKPiArr: any,
    IsMSERHeatmapTrendDataLoaded: boolean,
    ClearMSERHeatmapTrendDate: any,
    liKPIValueKeys: any,
    uniquec: any,
    uniquev: any,
    selectedidentityServiceClassification: any,
    identityServiceClassification: any,
    handleidentityServiceClassification: any,
    serviceGroups: any,
    services: any;
    servicegroupval: any;
    servicegroupvalchange: any;
    serviceval: any;
    serviceschange: any;
    selectedidentity_ServiceClassification: any;
    serviceidentityclassificationchange: any;
    globalsearch: string;
    searchtextboxchange: any;
    FilterTrendData: any;
    clearfilterdata: any;
    isDataloaded: boolean;
};
const MSERHeatmapTrendTable = React.memo<MSERHeatmapTrendTableProps>(({
    mserHeatmapTrendStartDate,
    onmserHeatmapTrendStartDateChange,
    MSERHeatmapTrendData,
    MSERHeatmapTrendheaderArr,
    MSERHeatmapTrendKPiArr,
    IsMSERHeatmapTrendDataLoaded,
    ClearMSERHeatmapTrendDate,
    liKPIValueKeys,
    uniquec,
    uniquev,
    selectedidentityServiceClassification,
    identityServiceClassification,
    handleidentityServiceClassification,
    serviceGroups,
    services,
    servicegroupval,
    servicegroupvalchange,
    serviceval,
    serviceschange,
    selectedidentity_ServiceClassification,
    serviceidentityclassificationchange,
    globalsearch,
    searchtextboxchange,
    FilterTrendData,
    clearfilterdata,
    isDataloaded
}) => {
    const filterBasedSearch = (item) => {      
            if (item.serviceName.toLowerCase().includes(globalsearch.toLowerCase())) {
                return true
            }
            else {
                return false;
            }        
    };
    // To Compare the weekly data with target values
    const AddColorForFactAvailabilityBudget = (data, key, target) => {
        var weekColor = { Color: "" };
        data[key] = (parseFloat(data[key]) < 1 && parseFloat(data[key]) > 0) ? (data[key] * 100).toFixed(4) + "%" : data[key];
        var reg = /[0-9][0-9]\.[0-9][%]/;
        var availabilityTarget = parseFloat(target);
        if (availabilityTarget != null) {
            OperatorOverloadAvailabilityBudget(data, key, availabilityTarget, weekColor, "<=");
        } else if (/[0-9]+/.test(data.targetName)) {
            OperatorOverloadAvailabilityBudget(data, key, target, weekColor, ">=");
        }
        return weekColor.Color;
    }
    // To Format the weekly data
    const OperatorOverloadAvailabilityBudget = (data, key, target, weekColor, operator) => {
        data[key] = String(data[key]).replace(/[(\)]/gi, "");
        weekColor.Color = SetColorForWeekAvailabilityBudget(data[key], target, operator);
    }
    const SetColorForWeekAvailabilityBudget = (weekVal, target, operator) => {

        var color = "";
        var wk1 = String(weekVal).split("/");
        for (var i = 0; i < wk1.length; i++) {
            if (wk1[i] == "") {
                color = "grey";
            } else if (wk1[i] != "NA" && !isNaN(parseFloat(wk1[i]))) { // IF not a number or NA
                if (operator == ">=") {
                    if (parseFloat(wk1[i]) > target) {
                        color = "red";
                        break;
                    } else {
                        color = "green";
                    }
                } else if (operator == "<=") {
                    if (parseFloat(wk1[i]) < target) {
                        color = "red";
                        break;
                    } else {
                        color = "green";
                    }
                } else if (operator == "<") {
                    if (parseFloat(wk1[i]) <= target) {
                        color = "red";
                        break;
                    } else {
                        color = "green";
                    }
                }
            }
            if (/[sec]{3}/.test(wk1[i])) {
                break;
            }
        }

        return color;
    }

    var tempactionItemsData = [...MSERHeatmapTrendData];
    const { result: dailyResult, requestSort: dailyRequestSort, sortConfig: dailySortConfig } = applySortOn(tempactionItemsData);
    MSERHeatmapTrendData = [...dailyResult];
   
    //This is for styles to indicate order
    const getClassNamesForDaily = (name) => {
        if (!dailySortConfig) {
            return;
        }
        return dailySortConfig.key === name ? dailySortConfig.direction : undefined;
    };

    return (
        <div>
            <Card className="cardstyle">
                <CardBody className="cardbodyhederstyle">
                    <Row>

                        <Col lg="12">
                            <h3 className="headertext"> MSER Heatmap Trend - Preview</h3>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <br/>
            <div className="boxShadow" style={{ width: '100%' }}>
                <Row>
                    <Col lg='2'>
                        <div className="p-field p-col-10 p-md-4 trendcolumns">
                            <span className="p-float-label">
                                <MultiSelect id="mlservicegroup" filter={true}  optionLabel="name" value={servicegroupval} options={serviceGroups} onChange={(e) => servicegroupvalchange(e.value)} />
                                <label htmlFor="mlservicegroup" className="fontcolor">Gem</label>
                            </span>
                        </div>
                    </Col>
                    <Col lg='2'>
                        <div className="p-field p-col-10 p-md-4 trendcolumns">
                            <span className="p-float-label">
                                <MultiSelect id="mlservice" filter={true} optionLabel="name" value={serviceval} options={services} onChange={(e) => serviceschange(e.value)} />
                                <label htmlFor="mlservice" className="fontcolor">Service </label>
                            </span>
                        </div>
                    </Col>
                    <Col lg='2'>
                        <div className="p-field p-col-10 p-md-4 trendcolumns">
                            <span className="p-float-label">
                                <Dropdown id="classification"  style={{ width: '90%' }} value={selectedidentityServiceClassification} options={identityServiceClassification} onChange={(e) => serviceidentityclassificationchange(e.target.value)} />
                                <label htmlFor="classification" className="fontcolor">Identity Service Classification</label>
                            </span>
                        </div>
                    </Col>

                    <Col lg="2">
                        <div style={{ paddingTop: '1.5rem' }}>
                            <input type="text" id="searchheight" className="form-control" value={globalsearch} onChange={e => searchtextboxchange(e)} placeholder="Search" />
                        </div>

                    </Col>
                    <Col lg="3">
                        <div style={{ paddingTop: '1.4rem' }}>
                            <button className="btn btn-primary btn-md filter-hmbutton btnwidth150" onClick={FilterTrendData}> <FontAwesomeIconMemo  title="Filter the data" icon={'filter'}  />&nbsp;Filter</button>&nbsp;&nbsp;
                            <button className="btn btn-primary btn-md filter-hmbutton btnwidth150" onClick={clearfilterdata}> <FontAwesomeIconMemo title="Filter the data" icon={'ban'} />&nbsp;Clear Filters</button>
                        </div>

                    </Col>
                </Row>
            </div>           
            
            <div className="aligntable">
                <table style={{ width: '100%', display:'none' }}>
                    <tr>
                        <td style={{ width: '80%' }}></td>
                        <td style={{ width: '15%' }}>
                            <div id="DateSelectorDiv" className="btn btn-primary btn-sm filter-button monthlytd">
                                <table>
                                    <tr>
                                        <td style={{ width: '15%' }}></td>
                                        <td style={{ width: '15%' }}>
                                            <div>
                                                <label style={{ fontSize: '20px' }} htmlFor='fromdate'> <FontAwesomeIconMemo icon={'calendar'} className='icon-format' /></label>&nbsp;
                                        </div>
                                        </td>
                                        <td style={{ width: '40%' }}>

                                            <DatePicker className='InputStyle'
                                                id={'fromdate'}
                                                selected={mserHeatmapTrendStartDate}
                                                onChange={onmserHeatmapTrendStartDateChange}
                                                maxDate={new Date()}
                                                minDate={new Date(2018, 11, 1)}
                                                dateFormat="MM/yyyy"
                                                showMonthYearPicker
                                            //customInput={<button className="datePickerInput"> {moment(monthlyStartDate).format("MM/YYYY")} <FontAwesomeIconMemo icon={'table'} className="datePickerCalendarIcon" /></button>}
                                            />  &nbsp;&nbsp;

                                        </td>
                                        <td style={{ width: '15%' }}> <div title='Clear' onClick={ClearMSERHeatmapTrendDate} style={{ paddingTop: '5px', cursor: 'pointer' }}>CLEAR</div></td>
                                        <td style={{ width: '15%' }}></td>
                                    </tr>
                                </table>
                            </div>
                        </td>
                        <td style={{ width: '5%' }}></td>
                    </tr>
                </table>
            </div>
            <div id='table_wrapper aligntable'>               
                {
                    (isArrayWithLength(MSERHeatmapTrendData))
                        ?
                        <React.Fragment> 
                            <table id='tdMSERdata' style={{ width: '95vw', backgroundColor: '#f5ffff !important', overflow: 'auto', display: 'block', height: '75vh' }}>
                                <thead className='thead-inverse'>
                                    <tr>
                                        <th colSpan={2} className="borderleftbottom tdtextaligncenter">
                                         
                                        </th>
                                        {isArrayWithLength(uniquec) && uniquec.map((dh, key) => (
                                            (dh === 'Ever Sev 1') ?
                                                <th colSpan={3} className="borderleftbottom tdtextaligncenter" > 
                                                    <a style={{ textDecoration: 'none', color: 'black' }} title='Click to View the Ever Sev-1 Report' href='https://msit.powerbi.com/groups/me/reports/a72a43b9-c714-4558-b5f3-a95d8315b601/ReportSection1' target='_blank'>
                                                        {dh}  <FontAwesomeIconMemo icon={'paper-plane'} /> </a>                                              
                                                </th>
                                                : (dh === 'Ever Sev 2') ?                                                       
                                                    <th colSpan={3} className="borderleftbottom tdtextaligncenter"  >                                                                                              
                                                        <a style={{ textDecoration: 'none', color: 'black' }} title='Click to View the Ever Sev-2 Report' href='https://msit.powerbi.com/groups/me/reports/a72a43b9-c714-4558-b5f3-a95d8315b601/ReportSection04cb1bf5986c8b2bc535' target='_blank'>
                                                            {dh}  <FontAwesomeIconMemo icon={'paper-plane'} /> </a>          
                                                    </th>
                                                    : (dh === 'Unique CCL Bridges') ?
                                                        <th colSpan={3} className="borderleftbottom tdtextaligncenter"  >                                                          
                                                            <a style={{ textDecoration: 'none', color: 'black' }} title='Click to View the Unique CCL Bridges Report' href='https://msit.powerbi.com/groups/me/reports/eaeeb172-4fe6-4db4-ade8-39da33b239b1/ReportSectiona09a70d40c0166d60c02' target='_blank'>
                                                                {dh}  <FontAwesomeIconMemo icon={'paper-plane'} /> </a>          
                                                        </th>
                                                        :
                                                        <th colSpan={3} className="borderleftbottom tdtextaligncenter"  >  {dh}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <thead className='thead-inverse'>
                                    <tr className='monthlydatatr'>
                                        <th className='stickyth' onClick={() => dailyRequestSort('serviceName')}>
                                            <div className='serviceHeader'>Service</div></th>
                                        <th className='stickythCol2'>
                                            <div className='viewHeader'>Heatmap View Name </div></th>                                        
                                        {isArrayWithLength(uniquec) && uniquec.map((dk, key) => (
                                            <React.Fragment>
                                                {isArrayWithLength(uniquev) && uniquev.map((dh, key) => (
                                                    <th className="tdtextaligncenter" style={{ width: '145px'}}> {dh}</th>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {isArrayWithLength(MSERHeatmapTrendData) &&
                                        MSERHeatmapTrendData
                                        .filter((item: IMSERHeatmapTrendReport) => {
                                            //if (filterBasedSearch(item.serviceName) || filterBasedSearch(item.viewName) || filterBasedSearch(item)) {
                                             if (filterBasedSearch(item)) {
                                                return true
                                              }
                                            })
                                            .map((dhs: IMSERHeatmapTrendReport, key) => (
                                        <React.Fragment key={key}>
                                            {
                                                <tr style={{ fontSize: '14px' }}>
                                                    {
                                                        (dhs.spanKpi > 0) ?
                                                            <td className='stickytd kpi data textalignleft' rowSpan={dhs.spanKpi > 0 ? dhs.spanKpi : 0}
                                                                style={{ backgroundColor: (dhs.viewName == "" || dhs.viewName == undefined || dhs.viewName == null) ? '#dde0ee' : '', border: '1px solid rgba(18, 73, 109, 0.4)', textAlign: "center" }}> {dhs.serviceName}</td>
                                                            : <React.Fragment></React.Fragment>
                                                    }
                                                    <td className='ml01 stickytdCol2 data tabletdVal textalignleft'>
                                                        <Link rel='tooltip' title='Click to View the Service Health Data' to={'/ServiceHealth?serviceid=' + dhs.serviceId + '&viewId=' + dhs.viewId} target='_blank' >
                                                            {dhs.viewName}
                                                        </Link>
                                                        
                                                    </td>                                                    
                                                    {isArrayWithLength(liKPIValueKeys) && liKPIValueKeys.map((dk, kpiArrkey) => (
                                                        (dhs.serviceName === 'Azure MFA') ?
                                                        <React.Fragment key={kpiArrkey}>
                                                            {
                                                                    ((dk.split('~')[0] === 'Unique CCL Bridges') || (dk.split('~')[0] === 'Ever Sev 1') || (dk.split('~')[0] === 'Ever Sev 2') || (dk.split('~')[0] === 'Open Repair Item')) ?
                                                                    (dhs.spanKpi > 0) ? <td className="MSERtdData" style={{ verticalAlign: 'middle' }} key={kpiArrkey} rowSpan={dhs.spanKpi > 0 ? dhs.spanKpi : 0}>{dhs.liKPIValues[dk]}</td> : <React.Fragment></React.Fragment>
                                                                    : <td className={(kpiArrkey === 0 || kpiArrkey === 1 || kpiArrkey === 2) ? AddColorForFactAvailabilityBudget(dhs.liKPIValues, dk, dhs.targetName) : 'MSERtdData'} style={{ textAlign: 'center', border: '1px solid rgba(18, 73, 109, 0.4)' }} key={kpiArrkey}>{dhs.liKPIValues[dk]}</td>
                                                            }   
                                                            </React.Fragment>
                                                        : <React.Fragment key={kpiArrkey}>
                                                            {
                                                              <td className={(kpiArrkey === 0 || kpiArrkey === 1 || kpiArrkey === 2) ? AddColorForFactAvailabilityBudget(dhs.liKPIValues, dk, dhs.targetName) : 'MSERtdData'} style={{ textAlign: 'center', border: '1px solid rgba(18, 73, 109, 0.4)' }} key={kpiArrkey}>{dhs.liKPIValues[dk]}</td>
                                                            }
                                                        </React.Fragment>
                                                    ))}
                                                </tr>
                                            }
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {
                                (isDataloaded)
                                    ? <div style={{ color: 'Red', fontWeight: 'bold', textAlign: 'center' }}><br />No Data Availble for MSER Heatmap Trend View</div>
                                    : <React.Fragment></React.Fragment>
                            }
                            </React.Fragment>      
                }
            </div>

        </div>
    )
});

MSERHeatmapTrendTable.displayName = 'MSERHeatmapTrendTable';
export default MSERHeatmapTrendTable;