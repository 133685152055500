import React, { useState, Fragment, useRef } from 'react';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
//import { IPropPlatformPMTools } from '../../../store/PlatformTools';
import { DropdownOption } from '../../../store/InternalEscalation';
import { ReduxAction, IAppThunkAction } from '../../../store';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FontAwesomeIconMemo } from '../../../components';
import { Workbook } from 'exceljs';
import * as FileSaver from 'file-saver';
import {
    Card, Row, Col, Container, CardBody
} from 'reactstrap';
type InternalEscalationTableProps = {
    selectedCloud: DropdownOption;
    serviceLifeCycle: any;
    selectedServiceLifeCycle: DropdownOption;
    classificationList: any;
    selectedClassification: DropdownOption;
    serviceGroupNameList: any;
    selectedServiceGroupName: DropdownOption;
    tenantList: any;
    teamGroupNameList: any;
    selectedTeamGroupName: DropdownOption;
    selectedTenant: any;
    selectedImorOce: DropdownOption;
    selectedIdentityServiceClassificationType: DropdownOption;
    readonly handleCloudChange: (selectedCloud: DropdownOption) => IAppThunkAction<ReduxAction>;
    readonly handleServiceLifeCycleChange: (selectedServiceLifeCycle: DropdownOption) => IAppThunkAction<ReduxAction>;
    readonly handleClassificationChange: (selectedClassification: DropdownOption) => IAppThunkAction<ReduxAction>;
    readonly handleServiceGroupChange: (selectedServiceGroupName: DropdownOption) => IAppThunkAction<ReduxAction>;
    readonly handleTenantChange: (selectedTenant: DropdownOption) => IAppThunkAction<ReduxAction>;
    readonly handleImorOceChange: (selectedImorOce: DropdownOption) => IAppThunkAction<ReduxAction>;
    readonly handleIdentityServiceClassificationTypeChange: (selectedIdentityServiceClassificationType: DropdownOption) => IAppThunkAction<ReduxAction>;
    readonly handleTeamGroupChange: (selectedTeamGroupName: DropdownOption) => IAppThunkAction<ReduxAction>;
    clearDropdownFilter: any;
    onCallData: any;
    filteredonCallData: any;
    getOnCallContactList: any;
    defaultexpandrows: any;
    OpenEmailPOPUP: any;
};
const InternalEscalationTable = React.memo<InternalEscalationTableProps>(({
    selectedCloud,
    serviceLifeCycle,
    selectedServiceLifeCycle,
    classificationList,
    selectedClassification,
    serviceGroupNameList,
    selectedServiceGroupName,
    tenantList,
    selectedTenant,
    selectedImorOce,
    selectedIdentityServiceClassificationType,
    handleCloudChange,
    handleServiceLifeCycleChange,
    handleClassificationChange,
    handleServiceGroupChange,
    handleTenantChange,
    handleImorOceChange,
    handleIdentityServiceClassificationTypeChange,
    clearDropdownFilter,
    onCallData,
    filteredonCallData,
    getOnCallContactList,
    defaultexpandrows,
    OpenEmailPOPUP,
    teamGroupNameList,
    selectedTeamGroupName,
    handleTeamGroupChange
}) => {

    const [expandedRows, setExpandedRows] = useState(defaultexpandrows);
    const [globalFilter, setGlobalFilter] = useState('');
    const [globalFilteredData, setglobalFilteredData] = useState([]);
    const [expandCollapseAll, setexpandCollapseAll] = useState(true);

    const cloudFiltertypes = [
        { name: 'All', value: 'All' },
        { name: 'Public', value: 'public' },
        { name: 'China/Gallatin/MoonCake', value: 'gallatin' },
        { name: 'Blackforest/Germany', value: 'blackforest' },
        { name: 'Fairfax/ITAR/Gov', value: 'fairfax' }
    ];

    const identityServiceClassificationTypes = [
        { name: 'All', value: 'All' },
        { name: 'Mission Critical', value: 'Mission Critical' },
        { name: 'Business Critical', value: 'Business Critical' }
    ];

    const imoceFiltertypes = [
        { name: 'All', value: 'All' },
        { name: 'IM', value: 'im' },
        { name: 'OCE', value: 'oce' }
    ];

    const isParent = (row: any) => {
        return 'PublicTeamId' in row.data ? true : false;
    }

    const exportInternalEscalationDetails = (filteredonCallData) => {
        /*Updated the excel download function to support Styling and filters*/
        const header = ['Component', 'Owning Tenant\Team', 'Team Group Name', 'Primary IM/OCE', 'Backup IM/OCE', 'ST Short Name', 'EAP Short Name','Area Path'];
        const dataList = [];
        if (isArrayWithLength(filteredonCallData)) {
            filteredonCallData.map((internalData: any) => {
                if (internalData.children !== undefined) {
                    internalData.children.map((r: any) => {
                        dataList.push([
                            r.data.Name, r.data.OwningTenantName, r.data.TeamGroupName, r.data.OnCallAlias, r.data.BackupOnCallName, r.data.ShortName, r.data.EAPShortName, r.data.AreaPath
                        ]);
                    });
                }
            });
        }

        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('InternalEscalation');

        const headerRow = worksheet.addRow(header);

        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '5879FF' },
                bgColor: { argb: 'FFFFFF' }
            };
            cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            cell.font = {
                bold: true,
                color: { argb: 'FFFFFF' }
            };
        });

        for (let d of dataList) {
            worksheet.addRow(d);
        }

        worksheet.autoFilter = 'A1:G1';
        const fileTypes = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtensions = '.xlsx';

        workbook.xlsx.writeBuffer().then((dataList: any) => {
            const blob = new Blob([dataList], { type: fileTypes });
            FileSaver.saveAs(blob, 'IndentityInternalEscalationDetails' + fileExtensions);
        });
    }

    const expandAllRows = () => {
        setexpandCollapseAll(!expandCollapseAll);
        let _expandedRows = [];
        filteredonCallData.forEach(p => _expandedRows[`${p.data.Name}`] = true);
        setExpandedRows(_expandedRows);
    }

    const collapseAllRows = () => {
        setexpandCollapseAll(!expandCollapseAll);
        setExpandedRows(null);
    }

    const componentStyling = (Value) => {
        return (
            <div style={{ color: ((isParent(Value) && Value.data.OnCallName == 'Not Defined') ? 'red' : (Value.data.CallAgain === '0' && (Value.data.IsExist || Value.data.IsExist == 1)) ? 'green' : (Value.data.CallAgain == '1') ? 'blue' : '') }}>
                {Value.data.Name}
            </div >
        );
    }

    const OwningTeam_TenantStyling = (Value) => {
        return (
            <div style={{ color: ((Value.data.OnCallName == 'Not Defined') ? 'red' : (Value.data.CallAgain === '0' && (Value.data.IsExist || Value.data.IsExist == 1)) ? 'green' : (Value.data.CallAgain == '1') ? 'blue' : '') }}>
                {Value.data.OwningTenantName}
                {
                    (Value.data.OwningTeamName) ?
                        <span>\</span>
                        : <React.Fragment></React.Fragment>
                }
                {Value.data.OwningTeamName}
            </div >
        );
    }

    const OnCallStyling = (Value) => {
        return (
            <div style={{ color: ((Value.data.OnCallName == 'Not Defined') ? 'red' : (Value.data.CallAgain === '0' && (Value.data.IsExist || Value.data.IsExist == 1)) ? 'green' : (Value.data.CallAgain == '1') ? 'blue' : '') }}>
                {Value.data.OnCallName}
                {
                    (Value.data.OnCallName && Value.data.OnCallName !== 'Not Defined') ?
                        <span> ({Value.data.OnCallAlias})</span>
                        : <React.Fragment></React.Fragment>
                }
            </div >
        );
    }

    const BackupOnCallStyling = (Value) => {
        return (
            <div style={{ color: ((Value.data.BackupOnCallName == 'Not Defined') ? 'red' : (Value.data.CallAgain === '0' && (Value.data.IsExist || Value.data.IsExist == 1)) ? 'green' : (Value.data.CallAgain == '1') ? 'blue' : '') }}>
                {Value.data.BackupOnCallName}
                {
                    (Value.data.OnCallName && Value.data.BackupOnCallName !== 'Not Defined') ?
                        <span> ({Value.data.BackupOnCallAlias})</span>
                        : <React.Fragment></React.Fragment>
                }
            </div >
        );
    }

    const AreaPathSyling = (Value) => {
        let colorKey = '';
        if (Value.data.AreaPath !== undefined) {
            if (Value.data.AreaPath.startsWith('Engineering')) {
                if (Value.data.IsServicePath === 1) {
                    colorKey = 'purple';
                }                        
            }
            else {
                colorKey = 'red';
            }
        }
        if (Value.data.IsAreaPathManual === true) {
            return (
                <div>
                    <a style={{ textDecoration: 'underline', color: '#0080D5', opacity: '0.8' }} title='Manually edited AP'>{Value.data.AreaPath}</a>
                </div>
            );
        }
        else {
            return (
                <div style={{ color: colorKey }}>
                    {Value.data.AreaPath}
                </div >
            );
        }
    }

    const rowExpansionTemplate = (data) => {
        return (
            <div className="datatableinternalEscalationExpanded">
                <DataTable value={data.children}>
                    <Column style={{ width: '5.3vw' }} ></Column>
                    <Column style={{ width: '11.3vw' }} body={componentStyling}></Column>
                    <Column style={{ width: '17.9vw' }} body={OwningTeam_TenantStyling}></Column>
                    <Column style={{ width: '8.1vw' }} field="data.TeamGroupName"></Column>
                    <Column style={{ width: '8.2vw' }} body={OnCallStyling}></Column>
                    <Column style={{ width: '8.3vw' }} body={BackupOnCallStyling}></Column>
                    <Column style={{ width: '8.5vw' }} field="data.ShortName"></Column>
                    <Column style={{ width: '8vw' }} field="data.EAPShortName"></Column>
                    <Column style={{ width: '8vw' }} body={AreaPathSyling} ></Column>
                </DataTable>
            </div>
        );
    }

    const expandCollapse = (
        <div style={{ width: '6em', textAlign: 'center' }}>
            {
                (expandCollapseAll === true) ?
                    <React.Fragment><FontAwesomeIcon title='Collapse All' onClick={collapseAllRows} icon={'minus-circle'} className='icon-format-collapseinternalEscalation' />&nbsp;</React.Fragment>
                    : <FontAwesomeIcon title='Expand All' onClick={expandAllRows} icon={'plus-circle'} className='icon-format-expandinternalEscalation' />
            }
        </div>
    );

    const setGlobalFilterValue = (event) => {
        setGlobalFilter(event.target.value);
        let tempData = [];
        let newtempdata = [];
        tempData = filteredonCallData.filter(obj => obj.data.Name.toLowerCase().indexOf((event.target.value).toLowerCase()) !== -1);
        tempData.map((row: any) => {
            newtempdata.push(row.data.Name);
        });
        setglobalFilteredData(newtempdata);
    }

    const clearDropdownFilterVal = () => {
        setGlobalFilter('');
        setexpandCollapseAll(true);
        clearDropdownFilter();
        setExpandedRows(defaultexpandrows);
    }

    return (
        <div>
            <Card className="cardstyle">
                <CardBody className="cardbodyhederstyle">
                    <Row>
                        <Col lg="4"></Col>
                        <Col lg="4" style={{ textAlign: 'center' }}>
                            <div>
                                <h3 className="headertext"> Identity Internal Escalation</h3>
                            </div>
                        </Col>
                        <Col lg="3" style={{ textAlign: 'right' }}>
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" style={{ verticalAlign: 'middle' }} />&nbsp;
                                <InputText style={{ width: '240px', verticalAlign: 'top' }} type="search" value={globalFilter} onChange={(e) => setGlobalFilterValue(e)} placeholder="Search By Component" />&ensp;
                            </span>
                        </Col>
                        <Col lg="1">
                            <Button title="Clear the filter" icon="pi pi-times" onClick={() => clearDropdownFilterVal()} className="p-button-rounded p-button-warning" />&nbsp;
                            <FontAwesomeIconMemo onClick={(e) => exportInternalEscalationDetails(filteredonCallData)} title="Download Internal Escalation Details" icon={'download'} className='icon-format-Download' />&nbsp;
                            <FontAwesomeIcon title='Copy Email' onClick={() => OpenEmailPOPUP(globalFilteredData, globalFilter)} icon={'envelope'} className='icon-format-internalEscalation' />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <div>
                <div className="card">
                    <div className="p-fluid p-grid">
                        <Row>
                            <div className="p-field p-col-4 dropdownstylingInternalEscalation" style={{ width: '8vw', paddingLeft: '1.5rem' }}>
                                <span className="p-float-label">
                                    <Dropdown id={'cloud'} value={selectedCloud} options={cloudFiltertypes} onChange={(e) => handleCloudChange(e.value)} optionLabel="name" />
                                    <label htmlFor="cloud" className="fontcolor">Cloud</label>
                                </span>
                            </div>
                            <div className="p-field p-col-4 dropdownstylingInternalEscalation" style={{ width: '11.5vw' }}>
                                <span className="p-float-label">
                                    <MultiSelect id={'lifecycle'} value={selectedServiceLifeCycle} options={serviceLifeCycle} onChange={(e) => handleServiceLifeCycleChange(e.value)} optionLabel="name" filter />
                                    <label htmlFor="lifecycle" className="fontcolor">Life Cycle</label>
                                </span>
                            </div>
                            <div className="p-field p-col-4 dropdownstylingInternalEscalation" style={{ width: '14vw' }}>
                                <span className="p-float-label">
                                    <MultiSelect id={'classificationType'} value={selectedClassification} options={classificationList} onChange={(e) => handleClassificationChange(e.value)} optionLabel="name" filter />
                                    <label htmlFor="classificationType" className="fontcolor">Classification Type</label>
                                </span>
                            </div>
                            <div className="p-field p-col-4 dropdownstylingInternalEscalation" style={{ width: '12vw' }}>
                                <span className="p-float-label">
                                    <MultiSelect id={'teamGroupName'} value={selectedTeamGroupName} options={teamGroupNameList} onChange={(e) => handleTeamGroupChange(e.value)} optionLabel="name" filter />
                                    <label htmlFor="teamGroupName" className="fontcolor">Team Group Name</label>
                                </span>
                            </div>
                            <div className="p-field p-col-4 dropdownstylingInternalEscalation" style={{ width: '12vw' }}>
                                <span className="p-float-label">
                                    <MultiSelect id={'group'} value={selectedServiceGroupName} options={serviceGroupNameList} onChange={(e) => handleServiceGroupChange(e.value)} optionLabel="name" filter />
                                    <label htmlFor="group" className="fontcolor">Group</label>
                                </span>
                            </div>
                            <div className="p-field p-col-4 dropdownstylingInternalEscalation" style={{ width: '13vw' }}>
                                <span className="p-float-label">
                                    <MultiSelect id={'tenant'} value={selectedTenant} options={tenantList} onChange={(e) => handleTenantChange(e.value)} optionLabel="name" filter />
                                    <label htmlFor="tenant" className="fontcolor">Tenant</label>
                                </span>
                            </div>
                            <div className="p-field p-col-4 dropdownstylingInternalEscalation" style={{ width: '12vw' }}>
                                <span className="p-float-label">
                                    <Dropdown id={'identityserviceclassificationtype'} value={selectedIdentityServiceClassificationType} options={identityServiceClassificationTypes} onChange={(e) => handleIdentityServiceClassificationTypeChange(e.value)} optionLabel="name" />
                                    <label htmlFor="identityserviceclassificationtype" className="fontcolor">Identity Service Classification</label>
                                </span>
                            </div>
                            <div className="p-field p-col-4 dropdownstylingInternalEscalation" style={{ width: '7vw' }}>
                                <span className="p-float-label">
                                    <Dropdown id={'imoce'} value={selectedImorOce} options={imoceFiltertypes} onChange={(e) => handleImorOceChange(e.value)} optionLabel="name" />
                                    <label htmlFor="imoce" className="fontcolor">IM/OCE</label>
                                </span>
                            </div>
                            <div style={{ paddingTop: '1.5rem' }}>
                                <Button label="Filter" style={{ width: '5vw' }} title="Filter the records based on the filter selection and display the records to make Request Assistance for team/person not yet called" icon="pi pi-filter" className="p-button-text" onClick={() => getOnCallContactList(0)} />&ensp;
                            </div>
                        </Row>
                    </div>
                </div>
          
                <Row className="fontsize14">
                    <Col xs='4'>
                    </Col>
                    <Col xs='8' style={{ textAlign: 'right' }}>
                        <label className='legend-format'>&nbsp;<b>AreaPath Legend </b> </label>&nbsp;
                        <label className='legend-format'><FontAwesomeIconMemo icon={'stop'} className='icon-format-areapth-legend-red' />&nbsp;Outside of Engineering AP</label>&nbsp;
                        <label className='legend-format'> <FontAwesomeIconMemo icon={'stop'} className='icon-format-icon-areapth-legend-purple' />&nbsp;No AP for this component: defaulting to Service AP</label>&nbsp;
                        <label className='legend-format-hyperlink'> HYPERLINKED:</label>&nbsp;<label className='legend-format'> this AP has been manually edited</label>
                    </Col>
                </Row>
   
                <div className="datatable-rowexpansion-demo">
                    <div className="card">
                        <DataTable className="datatableinternalEscalation" value={filteredonCallData} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                            rowExpansionTemplate={rowExpansionTemplate} dataKey="data.Name" globalFilter={globalFilter}>
                            <Column expander style={{ width: '6vw' }} header={expandCollapse} />
                            <Column field="data.Name" header="Component" style={{ width: '12vw', verticalAlign: 'middle' }} sortable />
                            <Column header="Owning Tenant\Team" style={{ width: '19vw', verticalAlign: 'middle' }} />
                            <Column header="Team Group Name" style={{ width: '9vw', verticalAlign: 'middle' }} />
                            <Column header="Primary IM/OCE" style={{ width: '9vw', verticalAlign: 'middle' }} />
                            <Column header="Backup IM/OCE" style={{ width: '9vw', verticalAlign: 'middle' }} />
                            <Column header="ST Short Name" style={{ width: '9vw', verticalAlign: 'middle' }} />
                            <Column header="EAP Short Name" style={{ width: '9vw', verticalAlign: 'middle' }} />
                            <Column header="Area Path" style={{ width: '9vw', verticalAlign: 'middle' }} />
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    )
});

InternalEscalationTable.displayName = 'InternalEscalationTable';
export default InternalEscalationTable;