import React, { useEffect, useRef } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import { actionCreators, reducer } from '../../store/Checklist';
import { ChecklistTable } from '../Checklist/child-components';
import LoadingOverlay from 'react-loading-overlay';
import { isArrayWithLength, renderToastifyMsg, isNullOrUndefined } from '../../utils';
import { toast } from 'react-toastify';
import { ChecklistAPI } from '../../api';
import moment from 'moment';
import { NewFunctionalityBanner } from '../../components';

type ChecklistProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly username: string }
    & { readonly history: History }
    & { readonly isAdmin: boolean }
    & typeof useParams;

const Checklist: React.FC<ChecklistProps> = ({
    isLoading,
    username,
    addUserVisit,
    isAuthenticated,    
    setloadingtrue,
    setloadingfalse,
    GetOnCallDashboardChecklistData,
    checklistData,
    OpenchecklistPopup,
    ChecklistEdit,
    MoveToArchive,
    showchecklistPopup,
    hideCHECKLISTPopup,
    questioncontroltypeoptions,
    sourcetypes,
    AuthenticationTypes,
    ischecklistsavebuttondisabled,
    servicelist,
    templatelist,
    checklist,
    handlechecklistChange,
    question,
    ShowQuestionList,
    AddQuestionstoChecklist,
    QuestionarrieList,
    disableaddbuttonforquestion,
    handlequestionChange,
    showquestionlist,
    ChecklistquestionEdit,
    questionlistvalueschange,
    DependsOnItems,
    AddNewquestiontolist,
    questioncontroltypedependsonoptions,
    isAdmin,
    LoginuserId,
    EditLockforChecklist,
    UpdateChecklistQuestionDisplayOrder,
    templateTypes,
    dynamicValidationTypes
}) => {
    const toastIdRef = useRef(null);
    useEffect(() => {
        if (isAuthenticated) {
            addUserVisit(0, 'Checklist.tsx_v2');
            GetOnCallDashboardChecklistData();
        }
    }, [isAuthenticated]);
    const ValidateURL = (url) => {
        var valid = false;
        var regex = /^(?:(?:https):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
        var m;
        m = regex.exec(url);
        if (m !== null) {
            valid = true;
        }
        return valid;
    }
    const checkURL = (url) => {
        if (url !== null && url !== '') {
            if (!ValidateURL(url)) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Please enter a Valid URL starting with 'https://'",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );

            }
        }
    }
    const SaveQuestionnarie = () => {
        let existdisplayorder = false;
        let isdisplayorderzero = false;
        let qqlist = [];
        let validurl = true;

        // Logic for Display order save and max Display Order if Questions already exist
        let newQuestionDisplayOrder = 1;
        if (isArrayWithLength(QuestionarrieList)) {
            for (var i = 0; i < QuestionarrieList.length; i++) {
                if (QuestionarrieList[i].id !== -1 && QuestionarrieList[i].id !== undefined && QuestionarrieList[i].id !== '' && QuestionarrieList[i].id !== null) {
                    newQuestionDisplayOrder = QuestionarrieList[i].DisplayOrder >= newQuestionDisplayOrder ? QuestionarrieList[i].DisplayOrder + 1 : newQuestionDisplayOrder;
                }
            }
        }

        if (isArrayWithLength(QuestionarrieList)) {
            QuestionarrieList.map((obj) => {
                qqlist.push(obj);
            });
        }
        if (question.ControlType === "CheckBox" || question.ControlType === "Radiobutton" || question.ControlType === "Dropdown") {
            question.controlValue = question.controlValue.split(",").map((item) => item.trim()).join();
        }
        if (question.sourceType === 'URL') {
            if (!ValidateURL(question.dataurl)) {
                validurl = false;
            }
        }
        if (!validurl) {
            toastIdRef.current = toast.error(
                renderToastifyMsg("URL should start with 'https://'",
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        
        else {
            let Isvaluelistempty = false;
            if (question.ControlType === "CheckBox" || question.ControlType === "Radiobutton" || question.ControlType === "Dropdown") {
                if (!isNullOrUndefined(question.controlValue)) {
                    var CV_values = question.controlValue.split(',');
                    for (var cv = 0; cv < CV_values.length; cv++) {
                        if (CV_values[cv].trim() == '') {
                            Isvaluelistempty = true;
                            cv = CV_values.length;
                        }
                    }

                }
            }
            if (Isvaluelistempty === true) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Please enter valid control Values with comma seperated",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            }
            else {
                let index = qqlist.length;
                let message = '';
                let ispreviosselected = 0;
                let matchIndex = -1;
                if (question.DependsOn != 'No' && index > 0 && question.Questionindex===-1) {
                    for (var i = 0; i < qqlist.length; i++) {
                        if (qqlist[i].Question == question.DependsOn && qqlist[i].IsActive == true && question.Questionindex != i) {
                            if (question.ControlType == 'ICMPicker') {
                                if (qqlist[i].DependentValues.ICMPicker == true) {
                                    message = 'Incident Loader already selected for Previos Question';
                                    ispreviosselected = 1;
                                    i = qqlist.length;
                                }
                                else {
                                    qqlist[i].DependentValues.ICMPicker = true;

                                }

                            }
                            else {
                                if (qqlist[i].DependentValues.ActionItems == true) {
                                    message = 'Action Item already selected for Previos Question';
                                    ispreviosselected = 1;
                                    i = qqlist.length;
                                }
                                else {
                                    qqlist[i].DependentValues.ActionItems = true;

                                }

                            }
                        }
                    }

                }
                if (ispreviosselected == 1) {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg(message,
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );

                }
                else {
                    let dependentvalues = 'No';
                    if (index > 0 && question.Questionindex === -1)  {
                        if (qqlist[index - 1].DependsOn == 'No' && qqlist[index - 1].TemplateID == 0 && qqlist[index - 1].IsActive == true) {
                            //Add previous question as dependent option only if it has ControlType as Checkbox, Radiobutton or dropdown
                            if (qqlist[index - 1].ControlType == 'CheckBox' || qqlist[index - 1].ControlType == 'Dropdown' || qqlist[index - 1].ControlType == 'Radiobutton') {
                                if (qqlist[index - 1].Question == question.DependsOn) {
                                    dependentvalues = dependentvalues + "," + qqlist[index - 1].Question;
                                    matchIndex = qqlist[index - 1].IndexValue;
                                }
                                else if (qqlist[index - 1].DependentValues.ICMPicker == false || qqlist[index - 1].DependentValues.ActionItems == false) {
                                    dependentvalues = dependentvalues + "," + qqlist[index - 1].Question;
                                    matchIndex = qqlist[index - 1].IndexValue;
                                }
                            }
                            //i = -1;
                        }
                        else if (index > 1 && qqlist[index - 1].DependsOn != 'No' && qqlist[index - 1].DependsOn != '' && qqlist[index - 1].DependsOn != undefined) {
                            if (qqlist[index - 2].DependsOn == 'No' && qqlist[index - 2].TemplateID == 0 && qqlist[index - 2].IsActive == true) {
                                //Add [index-2] question as dependent option only if it has ControlType as Checkbox, Radiobutton or dropdown
                                if (qqlist[index - 2].ControlType == 'CheckBox' || qqlist[index - 2].ControlType == 'Dropdown' || qqlist[index - 2].ControlType == 'Radiobutton') {
                                    if (qqlist[index - 2].Question == question.DependsOn) {
                                        dependentvalues = dependentvalues + "," + qqlist[index - 2].Question;
                                        matchIndex = qqlist[index - 2].IndexValue;
                                    }
                                    else if (qqlist[index - 2].DependentValues.ICMPicker == false || qqlist[index - 2].DependentValues.ActionItems == false) {
                                        dependentvalues = dependentvalues + "," + qqlist[index - 2].Question;
                                        matchIndex = qqlist[index - 2].IndexValue;
                                    }
                                }
                            }
                        }
                    }
                    if (question.Questionindex !== -1) {
                        dependentvalues = question.DependsOnItems;
                        matchIndex = question.matchIndex;
                    }
                    let obj={
                        "QuestionID": question.QuestionID,
                        "DisplayOrder": question.QuestionID === 0 ? newQuestionDisplayOrder : question.displayOrder,
                        "DataUrl": question.dataurl,
                        "Question": question.question,
                        "ControlType": question.ControlType,
                        "ControlValues": question.controlValue == null ? "" : question.controlValue,
                        "IsActive": true,
                        "LastUpdatedBy": question.CreatedBy,
                        "isChecked": true,
                        "Ischanged": true,
                        "TemplateID": 0,
                        "DependsOn": question.DependsOn,
                        "DependsOnValue": question.DependsOnValue,
                        "SourceType": question.sourceType,
                        "DynamicValidation": question.dynamicValidation,
                        "ServerName": question.serverName,
                        "Database": question.Database,
                        "Query": question.Query,
                        "AuthenticationType": question.kustoauthenticationtype,
                        "IsActionRequired":false,
                        "ActionItemValue": '',
                        "DependsOnItems": dependentvalues,
                        'DependentValues': { 'ICMPicker': false, 'ActionItems': false },
                        'IndexValue': (qqlist.length == 0) ? 0 : qqlist.length - 1,
                        'matchIndex': matchIndex,
                        'IsdisplayDependsonValue': question.IsdisplayDependsonValue,
                        'DependsOnValueslist': question.DependsOnValueslist,
                        'IsInvalidQuestion':false
                    };
                    AddNewquestiontolist(obj, question.Questionindex, qqlist);

                }

            }
        }
    };

    const FindDuplicateDisplayOrdersForChecklist=(questionsList)=> {       
        var duplicateDisplayOrders = [];
        var mapDisplayOrders = {};

        var displayOrders = questionsList.map(function (item) { return item.DisplayOrder });

        for (var i = 0; i < displayOrders.length; i++) {
            if (mapDisplayOrders[displayOrders[i]]) {
                duplicateDisplayOrders.push(displayOrders[i]);
            }
            else {
                mapDisplayOrders[displayOrders[i]] = 1;
            }
        }

        return duplicateDisplayOrders;
    }
    const escapeChar =  (item) =>{
        if (item == "" || item == null) {
            return "";
        }
        else {
            return item.toString().replace("&", "&amp;").replace("<", "&lt;").replace(">", "&gt;").replace("%", "&#37;").replace(/"/g, "&quot;").trim();
        }
    };
    const SaveOnCallDashboardCheckListData = () => {
        //If 2 child questions both have 'Incident loader' or 'Action Item'
        let Iserror = false;
        let message = '';
        for (var i = 0; i < QuestionarrieList.length; i++) {
            if (QuestionarrieList[i].id !== -1) {
                if (i >= 0 && (i + 1) < QuestionarrieList.length) {
                    if (QuestionarrieList[i].DependsOn != "No" && (QuestionarrieList[i].DependsOn == QuestionarrieList[i + 1].DependsOn)) {
                        if (QuestionarrieList[i].ControlType == QuestionarrieList[i + 1].ControlType) {
                            message = 'Both Dependant questions for "' + QuestionarrieList[i].DependsOn + ' cannot have same Control Type ' + QuestionarrieList[i].ControlType + '"';
                            i = QuestionarrieList.length;
                            Iserror = true;
                        }
                    }
                }
            }
        }
        if (Iserror) {
            toastIdRef.current = toast.error(
                renderToastifyMsg(message,
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        else {
            let SaveQuestionId = "";
            if (isNullOrUndefined(checklist.checkListId)) {
                checklist.checkListId = 0;
            }
            let Isexist = false;
            if (isNullOrUndefined(checklist.templateId)) {
                checklist.templateId = 0;
            }
            if (isArrayWithLength(checklistData)) {
                if (checklist.selectedService.length > 0) {
                    checklist.selectedService.map((obj) => {
                        checklistData.map((row) => {
                            if (row.checkListName.toLowerCase() == checklist.checkListName.toLowerCase() && obj === parseInt(row.serviceID) && checklist.checkListId != row.checkListID) {
                                Isexist = true;
                            }
                        });
                    });
                }
            }
            if (Isexist) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Checklist Name already Exist",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            }
            else if (checklist.DataUrl !== '' && checklist.DataUrl !== null && checklist.DataUrl !== undefined && !ValidateURL(checklist.DataUrl)) {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("URL Should start with 'https://'",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
            }
            else {
                var xml = "<List>";
                var count = 1;
                var Displayorders = [];
                var questions = [];
                var duplicateorders = false;
                var duplicatequestions = false;
                for (var i = 0; i < QuestionarrieList.length; i++) {
                    if (QuestionarrieList[i].id !== -1) {
                        if (i == 0) {
                            Displayorders.push(QuestionarrieList[i].DisplayOrder);
                            questions.push(QuestionarrieList[i].Question);
                        }
                        else {
                            for (var j = 0; j < Displayorders.length; j++) {
                                if (QuestionarrieList[i].DisplayOrder == Displayorders[j]) {
                                    duplicateorders = true;
                                    j = Displayorders.length;
                                    i = QuestionarrieList.length;
                                }

                            }
                            if (duplicateorders == false) {
                                Displayorders.push(QuestionarrieList[i].DisplayOrder);
                                for (var j = 0; j < questions.length; j++) {
                                    if (QuestionarrieList[i].Question.toLowerCase() == questions[j].toLowerCase()) {
                                        duplicatequestions = true;
                                        j = questions.length;
                                        i = QuestionarrieList.length;
                                    }

                                }
                                if (!duplicatequestions)
                                    questions.push(QuestionarrieList[i].Question);
                            }
                        }
                        if (duplicateorders) {
                            let duplicateDisplayOrders = FindDuplicateDisplayOrdersForChecklist(QuestionarrieList);
                            message = "Duplicate Display Orders: " + duplicateDisplayOrders;
                            Iserror = true;

                        }
                        else if (duplicatequestions) {
                            message = "Duplicate Questions";
                            Iserror = true;

                        }
                        else {
                            let invalidurl = false;
                            if (QuestionarrieList[i].SourceType == 'URL') {
                                if (!ValidateURL(QuestionarrieList[i].DataUrl)) {
                                    invalidurl = true;
                                }
                            }
                            if (invalidurl) {
                                message = "URL Should start with 'https://' for Question No.";
                                Iserror = true;

                            }
                            else {
                                let Isvaluelistempty = false;
                                if (QuestionarrieList[i].ControlValues !== '' && QuestionarrieList[i].ControlValues !== undefined) {
                                    var CV_values = QuestionarrieList[i].ControlValues.split(',');
                                    for (var cv = 0; cv < CV_values.length; cv++) {
                                        if (CV_values[cv].trim() == '') {
                                            Isvaluelistempty = true;
                                            cv = CV_values.length;
                                        }
                                    }
                                }
                                if (Isvaluelistempty) {
                                    message = "Please enter valid control Values with comma seperated  for Question ' " + QuestionarrieList[i].Question + " '";
                                    Iserror = true;
                                    i = QuestionarrieList.length;

                                }
                                else {
                                    if (QuestionarrieList[i].ActionItemValue != '' && QuestionarrieList[i].ActionItemValue != undefined && QuestionarrieList[i].ControlType == 'CheckBox') {
                                        var CV_values = QuestionarrieList[i].ActionItemValue.split(',');
                                        for (var cv = 0; cv < CV_values.length; cv++) {
                                            if (CV_values[cv].trim() == '') {
                                                Isvaluelistempty = true;
                                                cv = CV_values.length;
                                            }
                                        }
                                    }
                                    if (Isvaluelistempty == true) {
                                        message = "Please enter valid Action Item Value with comma seperated  for Question ' " + QuestionarrieList[i].Question + " '" + QuestionarrieList[i].Question + " '";
                                        Iserror = true;
                                        i = QuestionarrieList.length;
                                    }
                                    else {
                                        let toDate = new Date();
                                        let fromDate = new Date(
                                            toDate.getFullYear(),
                                            toDate.getMonth(),
                                            toDate.getDate()
                                        );
                                        let query = QuestionarrieList[i].Query;
                                        if (query != undefined && query != '') {
                                            query = query.replace('todatetime(<<datetime>>)', 'datetime(' + moment(fromDate).format("MM/DD/YYYY") + ')');
                                            query = query.replace('<<datacenter>>', "'" + QuestionarrieList[i].AuthenticationType + "'");
                                            query = query.replace(new RegExp('<', "g"), '^');
                                            query = query.replace(new RegExp('>', "g"), '$');
                                            query = query.replace(new RegExp('"', "g"), "'");

                                        }
                                        if (QuestionarrieList[i].TemplateID > 0 && QuestionarrieList[i].IsActive == true) {
                                            xml = xml + "<Row QuestionID=\"" + QuestionarrieList[i].QuestionID + "\" Question=\"" + escapeChar(QuestionarrieList[i].Question).replace(",", ";") + "\" ControlType= \"" + escapeChar(QuestionarrieList[i].ControlType) +
                                                "\" ControlValues=\"" + escapeChar(QuestionarrieList[i].ControlValues) + "\" DisplayOrder = \"" + escapeChar(QuestionarrieList[i].DisplayOrder) +
                                                "\" DependsOn=\"" + escapeChar(QuestionarrieList[i].DependsOn) + "\" DependentValue = \"" + escapeChar(QuestionarrieList[i].DependsOnValue) +
                                                "\" SourceType=\"" + escapeChar(QuestionarrieList[i].SourceType) + "\" DynamicValidation=\"" + escapeChar(QuestionarrieList[i].DynamicValidation) + "\" ServerName = \"" + escapeChar(QuestionarrieList[i].ServerName) + "\" Query = \"" + query +
                                                "\" Database=\"" + escapeChar(QuestionarrieList[i].Database) + "\" AuthenticationType = \"" + escapeChar(QuestionarrieList[i].AuthenticationType) +
                                                "\" IsActionRequired=\"" + escapeChar(QuestionarrieList[i].IsActionRequired) + "\" ActionItemValue = \"" + escapeChar(QuestionarrieList[i].ActionItemValue) +
                                                "\" DataURL=\"" + escapeChar(QuestionarrieList[i].DataUrl) + "\" " +
                                                " ActionFlag='U'  />";
                                            count = count + 1;
                                        }
                                        else if ((QuestionarrieList[i].QuestionID == 0) && QuestionarrieList[i].IsActive == true) {

                                            xml = xml + "<Row QuestionID=\"" + 0 + "\" Question=\"" + escapeChar(QuestionarrieList[i].Question).replace(",", ";") + "\" ControlType= \"" + escapeChar(QuestionarrieList[i].ControlType) +
                                                "\" ControlValues=\"" + escapeChar(QuestionarrieList[i].ControlValues) + "\" DisplayOrder = \"" + escapeChar(QuestionarrieList[i].DisplayOrder) +
                                                "\" DependsOn=\"" + escapeChar(QuestionarrieList[i].DependsOn) + "\" DependentValue = \"" + escapeChar(QuestionarrieList[i].DependsOnValue) +
                                                "\" SourceType=\"" + escapeChar(QuestionarrieList[i].SourceType) + "\" DynamicValidation=\"" + escapeChar(QuestionarrieList[i].DynamicValidation) + "\" ServerName = \"" + escapeChar(QuestionarrieList[i].ServerName) + "\" Query = \"" + query +
                                                "\" Database=\"" + escapeChar(QuestionarrieList[i].Database) + "\" AuthenticationType = \"" + escapeChar(QuestionarrieList[i].AuthenticationType) +
                                                "\" IsActionRequired=\"" + escapeChar(QuestionarrieList[i].IsActionRequired) + "\" ActionItemValue = \"" + escapeChar(QuestionarrieList[i].ActionItemValue) +
                                                "\" DataURL=\"" + escapeChar(QuestionarrieList[i].DataUrl) + "\" " +
                                                " ActionFlag='C'   />";
                                            count = count + 1;
                                        }
                                        else if ((QuestionarrieList[i].QuestionID > 0 && QuestionarrieList[i].IsActive == true) && (QuestionarrieList[i].TemplateID == 0 && QuestionarrieList[i].IsActive == true)) {
                                            xml = xml + "<Row QuestionID=\"" + QuestionarrieList[i].QuestionID + "\" Question=\"" + escapeChar(QuestionarrieList[i].Question).replace(",", ";") + "\" ControlType= \"" + escapeChar(QuestionarrieList[i].ControlType) +
                                                "\" ControlValues=\"" + escapeChar(QuestionarrieList[i].ControlValues) + "\" DisplayOrder = \"" + escapeChar(QuestionarrieList[i].DisplayOrder) +
                                                "\" DependsOn=\"" + escapeChar(QuestionarrieList[i].DependsOn) + "\" DependentValue = \"" + escapeChar(QuestionarrieList[i].DependsOnValue) +
                                                "\" SourceType=\"" + escapeChar(QuestionarrieList[i].SourceType) + "\" DynamicValidation=\"" + escapeChar(QuestionarrieList[i].DynamicValidation) + "\" ServerName = \"" + escapeChar(QuestionarrieList[i].ServerName) + "\" Query = \"" + query +
                                                "\" Database=\"" + escapeChar(QuestionarrieList[i].Database) + "\" AuthenticationType = \"" + escapeChar(QuestionarrieList[i].AuthenticationType) +
                                                "\" IsActionRequired=\"" + escapeChar(QuestionarrieList[i].IsActionRequired) + "\" ActionItemValue = \"" + escapeChar(QuestionarrieList[i].ActionItemValue) +
                                                "\" DataURL=\"" + escapeChar(QuestionarrieList[i].DataUrl) + "\" " +
                                                " ActionFlag='U'   />";
                                            count = count + 1;
                                        }
                                        else if (QuestionarrieList[i].TemplateID > 0 && QuestionarrieList[i].IsActive == false) {
                                            xml = xml + "<Row QuestionID=\"" + QuestionarrieList[i].QuestionID + "\" Question=\"" + escapeChar(QuestionarrieList[i].Question).replace(",", ";") + "\" ControlType= \"" + escapeChar(QuestionarrieList[i].ControlType) +
                                                "\" ControlValues=\"" + escapeChar(QuestionarrieList[i].ControlValues) + "\" DisplayOrder = \"" + escapeChar(QuestionarrieList[i].DisplayOrder) +
                                                "\" DependsOn=\"" + escapeChar(QuestionarrieList[i].DependsOn) + "\" DependentValue = \"" + escapeChar(QuestionarrieList[i].DependsOnValue) +
                                                "\" SourceType=\"" + escapeChar(QuestionarrieList[i].SourceType) + "\" DynamicValidation=\"" + escapeChar(QuestionarrieList[i].DynamicValidation) + "\" ServerName = \"" + escapeChar(QuestionarrieList[i].ServerName) + "\" Query = \"" + query +
                                                "\" Database=\"" + escapeChar(QuestionarrieList[i].Database) + "\" AuthenticationType = \"" + escapeChar(QuestionarrieList[i].AuthenticationType) +
                                                "\" IsActionRequired=\"" + escapeChar(QuestionarrieList[i].IsActionRequired) + "\" ActionItemValue = \"" + escapeChar(QuestionarrieList[i].ActionItemValue) +
                                                "\" DataURL=\"" + escapeChar(QuestionarrieList[i].DataUrl) + "\" " +
                                                " ActionFlag='D'  />";                                            
                                        }
                                        else if ((QuestionarrieList[i].QuestionID > 0 && QuestionarrieList[i].IsActive == false) && (QuestionarrieList[i].TemplateID == 0 && QuestionarrieList[i].IsActive == false)){
                                            xml = xml + "<Row QuestionID=\"" + QuestionarrieList[i].QuestionID + "\" Question=\"" + escapeChar(QuestionarrieList[i].Question).replace(",", ";") + "\" ControlType= \"" + escapeChar(QuestionarrieList[i].ControlType) +
                                                "\" ControlValues=\"" + escapeChar(QuestionarrieList[i].ControlValues) + "\" DisplayOrder = \"" + escapeChar(QuestionarrieList[i].DisplayOrder) +
                                                "\" DependsOn=\"" + escapeChar(QuestionarrieList[i].DependsOn) + "\" DependentValue = \"" + escapeChar(QuestionarrieList[i].DependsOnValue) +
                                                "\" SourceType=\"" + escapeChar(QuestionarrieList[i].SourceType) + "\" DynamicValidation=\"" + escapeChar(QuestionarrieList[i].DynamicValidation) + "\" ServerName = \"" + escapeChar(QuestionarrieList[i].ServerName) + "\" Query = \"" + query +
                                                "\" Database=\"" + escapeChar(QuestionarrieList[i].Database) + "\" AuthenticationType = \"" + escapeChar(QuestionarrieList[i].AuthenticationType) +
                                                "\" IsActionRequired=\"" + escapeChar(QuestionarrieList[i].IsActionRequired) + "\" ActionItemValue = \"" + escapeChar(QuestionarrieList[i].ActionItemValue) +
                                                "\" DataURL=\"" + escapeChar(QuestionarrieList[i].DataUrl) + "\" " +
                                                " ActionFlag='D'   />";
                                        }
                                    }

                                }

                            }
                        }

                    }
                }
                if (Iserror) {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg(message,
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
                else if (count == 1) {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Checklist should have atleast one Active Question",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );

                }
                else {
                    // Added below line of Code, if there are multiple services selected for Checklist
                    let serviceList = checklist.selectedService.join(",");
                    xml = xml + "</List>";
                    username = username.split('@')[0];
                    let data = {
                        CheckListID: checklist.checkListId,
                        CheckListName: checklist.checkListName,
                        TemplateType: checklist.templateType,
                        sourceType: '',
                        Notes: checklist.notes,
                        ServiceID: serviceList,  //$scope.serviceIDs.serviceID, , //$scope.selectedrow.serviceID,
                        LastUpdatedBy: username,
                        IsLocked: checklist.isLocked,
                        IsActive: (checklist.IsActive == true ? 1 : 0),
                        TemplateID: (checklist.templateId === '') ? -1 : parseInt(checklist.templateId),
                        QuestionListxml: xml,
                        DataUrl: checklist.DataUrl
                    }
                    setloadingtrue();
                    ChecklistAPI.SaveOnCallDashboardCheckListData(data)
                        .then((result: number) => {
                            setloadingfalse();
                            if (result !== -1) {
                                if (checklist.checkListId == 0) {
                                    toastIdRef.current = toast.success(
                                        renderToastifyMsg("CheckList Saved successfully",
                                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                                    );

                                }
                                else {
                                    toastIdRef.current = toast.success(
                                        renderToastifyMsg("CheckList Updated successfully",
                                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                                    );
                                }

                            } else {
                                toastIdRef.current = toast.error(
                                    renderToastifyMsg("Error while Adding/Updating CheckList",
                                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                                );

                            }
                            GetOnCallDashboardChecklistData();
                            hideCHECKLISTPopup();

                        }).catch(error => {
                            setloadingfalse();
                            toastIdRef.current = toast.error(
                                renderToastifyMsg("Error while Adding/Updating CheckList",
                                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                            );
                        });
                }
              
            }

        }
       
    }
    const downloadChecklistuserguide = () => {
        let filename = "Checklist_Tool_UserGuide.docx";
        setloadingtrue();
        ChecklistAPI.GetFile(filename)
            .then((result: any) => {
                setloadingfalse();
                toastIdRef.current = toast.success(
                    renderToastifyMsg('File downloaded successfully',
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while download",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });
    }
    return (
        <div>
            <NewFunctionalityBanner />
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        <ChecklistTable
                            checklistData={checklistData}
                            OpenchecklistPopup={OpenchecklistPopup}
                            ChecklistEdit={ChecklistEdit}
                            MoveToArchive={MoveToArchive}
                            showchecklistPopup={showchecklistPopup}
                            hideCHECKLISTPopup={hideCHECKLISTPopup}
                            questioncontroltypeoptions={questioncontroltypeoptions}
                            sourcetypes={sourcetypes}
                            dynamicValidationTypes={dynamicValidationTypes}
                            AuthenticationTypes={AuthenticationTypes}
                            ischecklistsavebuttondisabled={ischecklistsavebuttondisabled}
                            SaveOnCallchecklistData={SaveOnCallDashboardCheckListData}
                            servicelist={servicelist}
                            templatelist={templatelist}
                            checklist={checklist}
                            handlechecklistChange={handlechecklistChange}
                            question={question}
                            ShowQuestionList={ShowQuestionList}
                            AddQuestionstoChecklist={AddQuestionstoChecklist}
                            QuestionarrieList={QuestionarrieList}
                            disableaddbuttonforquestion={disableaddbuttonforquestion}
                            handlequestionChange={handlequestionChange}
                            showquestionlist={showquestionlist}
                            SaveQuestionnarie={SaveQuestionnarie}
                            ChecklistquestionEdit={ChecklistquestionEdit}
                            questionlistvalueschange={questionlistvalueschange}
                            checkURL={checkURL}
                            DependsOnItems={DependsOnItems}
                            questioncontroltypedependsonoptions={questioncontroltypedependsonoptions}
                            downloadChecklistuserguide={downloadChecklistuserguide}
                            isAdmin={isAdmin}
                            LoginuserId={LoginuserId}
                            EditLockforChecklist={EditLockforChecklist}
                            UpdateChecklistQuestionDisplayOrder={UpdateChecklistQuestionDisplayOrder}
                            templateTypes={templateTypes}
                        />


                    </div>
                </section>
            </LoadingOverlay>

        </div>
    );

};
const mapStateToProps = (state: IApplicationState) => ({
    ...state.Checklist,
    isAuthenticated: state.authUser.isAuthenticated,
    username: state.authUser.login,
    isAdmin: state.authUser.isAdmin

})

export default connect(mapStateToProps, actionCreators)(Checklist);
