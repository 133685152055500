import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import { actionCreators, reducer, IPropHeatmapAdminData } from '../../store/HeatmapAdmin';
import { HeatmapAdminTable } from '../HeatmapAdmin/child-components';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap'
import { Row, Col, Card, CardBody } from 'reactstrap';
import RichTextEditor from 'react-rte';
import { DailyDataApi } from '../../api';
import { toast } from 'react-toastify';
import { isNullOrUndefined, isArrayWithLength, renderToastifyMsg } from '../../utils';
import bxloader from '../../assets/images/bxloader.gif';
import { NewFunctionalityBanner } from '../../components';

type HeatmapAdminProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly username: string }
    & typeof useParams;

const HeatmapAdmin: React.FC<HeatmapAdminProps> = ({
    heatmapAdminData,
    isAuthenticated,
    requestHeatmapAdminData,
    isLoading,
    setloadingtrue,
    setloadingfalse,
    username,
    addUserVisit,
    searchText,
    ChangedSearchText,
    checkIsPirRereviewUser,
    IsLivesitePMOrUser,
    newsLetterTeamGroups,
    GetnewsLetterTeamGroups
}) => {
    useEffect(() => {
        if (isAuthenticated)
            addUserVisit(0, 'HeatmapAdmin.tsx_v2');
    }, [isAuthenticated]);
    useEffect(() => {
        requestHeatmapAdminData();
        checkIsPirRereviewUser(username);
        GetnewsLetterTeamGroups();
    }, [requestHeatmapAdminData]);
    useEffect(() => {
        if (!isNullOrUndefined(username)) {
            checkIsPirRereviewUser(username);
        }

    }, [username]);
    const toastIdRef = useRef(null);

    //============== function to edit MSER VIEW (Checkbox onchange) for selected row ======================//
    const UpdateMserViewData = (e, row) => {
        setloadingtrue();
        row.isMserView = e.target.checked;
        // Logic to set MSERPresentationView flag to false if MSERView flag is Unchecked
        if (row.isMserView === false) {
            row.isMserPresentationView = row.isMserPresentationView === true ? false : row.isMserPresentationView;
            DailyDataApi.HM_SaveIsMserPresentationView(row.isMserPresentationView, row.viewId, username)
                .then((result: number) => {
                    if (result == -1) {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                })
        }
        DailyDataApi.HM_SaveIsMserView(row.isMserView, row.viewId, username)
            .then((result: number) => {
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg('MSER View flag Updated succesfully',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    requestHeatmapAdminData();
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });

    }
    //============== function to edit Monthly Availability (Checkbox onchange) for selected row ======================//
    const UpdateMonthlyAvailabilityData = (e, row) => {
        setloadingtrue();
        row.isMonthlyAvailability = e.target.checked;
        DailyDataApi.HM_SaveIsMonthlyAvailability(row.isMonthlyAvailability, row.viewId, username)
            .then((result: number) => {
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg('Monthly Availability flag Updated succesfully',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    requestHeatmapAdminData();
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });

    }
    //============== function to edit MSER Heatmap trend View (Checkbox onchange) for selected row ======================//
    const UpdateMserHeatmapTrendViewData = (e, row) => {
        setloadingtrue();
        row.isMserHeatmapTrendView = e.target.checked;

       
        DailyDataApi.SaveIsHeatmapTrendView(row.isMserHeatmapTrendView, row.viewId, username)
            .then((result: number) => {
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg('MSER Heatmap Trend View flag Updated succesfully',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    requestHeatmapAdminData();
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });
    }

    const UpdateMserHeatmapStatus = (e, row) => {
        setloadingtrue();
        row.IsActiveStatus = e.target.checked;
        DailyDataApi.UpdateHeatmapActiveInactive(row.IsActiveStatus, row.viewId, username)
            .then((result: number) => {
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg((row.IsActiveStatus == true) ? 'Heatmap View Activated succesfully' : 'Heatmap View Deactivated succesfully',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    requestHeatmapAdminData();
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });
    }

    //============== function to edit MSER Presentation View (Checkbox onchange) for selected row ======================//
    const UpdateMserPresentationViewData = (e, row) => {
        setloadingtrue();
        row.isMserPresentationView = e.target.checked;

        // Logic to set MSERView flag to true if MSERPresentationView flag is checked 
        if (row.isMserPresentationView === true) {
            row.isMserView = row.isMserView === false ? true : row.isMserView;
            DailyDataApi.HM_SaveIsMserView(row.isMserView, row.viewId, username)
                .then((result: number) => {
                    if (result == -1) {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                })
        }
        DailyDataApi.HM_SaveIsMserPresentationView(row.isMserPresentationView, row.viewId, username)
            .then((result: number) => {
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg('MSER Presentation View flag Updated succesfully',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    requestHeatmapAdminData();
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });

    }

    //============== function to edit NewsLetter View (Checkbox onchange) for selected row ======================//
    const UpdateIdentityWeeklyNewsLetter = (isIdentityWeeklyNewsLetterView, viewId, selectednewsLetterGroup) => {
        setloadingtrue();
        DailyDataApi.UpdateHeatmapIdentityWeeklyNewsLetter(isIdentityWeeklyNewsLetterView, viewId, selectednewsLetterGroup , username)
            .then((result: number) => {
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg('Newsletter View updated succesfully',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    requestHeatmapAdminData();
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });
    }

    return (
        <div>
            <NewFunctionalityBanner />
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
            <section className='section'>
                    <div className='container'>
                        <HeatmapAdminTable
                        heatmapAdminData={heatmapAdminData}
                        searchText={searchText}
                        ChangedSearchText={ChangedSearchText}
                        UpdateMserViewData={(e, row) => UpdateMserViewData(e, row)}
                        UpdateMonthlyAvailabilityData={(e, row) => UpdateMonthlyAvailabilityData(e, row)}
                        UpdateMserPresentationViewData={(e, row) => UpdateMserPresentationViewData(e, row)}
                        UpdateMserHeatmapTrendViewData={(e, row) => UpdateMserHeatmapTrendViewData(e, row)}
                        IsLivesitePMOrUser={IsLivesitePMOrUser}
                        UpdateMserHeatmapStatus={(e, row) => UpdateMserHeatmapStatus(e, row)}
                        UpdateIdentityWeeklyNewsLetter={UpdateIdentityWeeklyNewsLetter}
                        newsLetterTeamGroups={newsLetterTeamGroups}
                        />
                    </div>
                </section>
            </LoadingOverlay>
        </div>
    );
};

const mapStateToProps = (state: IApplicationState) => ({
    ...state.HeatmapAdmin,
    isAuthenticated: state.authUser.isAuthenticated,
    username: state.authUser.login
})

export default connect(mapStateToProps, actionCreators)(HeatmapAdmin);
