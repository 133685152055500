import React, { Fragment } from 'react';
import { NavbarMenu, Settings, Footer } from './components';

const Layout: React.FC = ({ children }) => (
    <Fragment>
        <NavbarMenu/>       
        {children}
        <Footer />
    </Fragment>

);

export default Layout;