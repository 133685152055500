import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import { actionCreators, reducer, IPropIdentityObjectives, IPropIdentityKeyResults } from '../../store/DivisionalOKRs';
import { DivisionalOKRsTable } from '../IdentityWeeklyNewsLetter/child-components';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap'
import { Row, Col, Card, CardBody } from 'reactstrap';
import RichTextEditor from 'react-rte';
import { toast } from 'react-toastify';
import moment from 'moment';
import { IdentityWeeklyNewsLetterAPI } from '../../api';
import { isNullOrUndefined, isArrayWithLength, renderToastifyMsg } from '../../utils';

type DivisionalOKRsProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly username: string }
    & typeof useParams;

const DivisionalOKRs: React.FC<DivisionalOKRsProps> = ({
    isAuthenticated,
    isLoading,
    setloadingtrue,
    setloadingfalse,
    username,
    addUserVisit,
    newsLetterDivisionalObjectivesData,
    newsLetterDivisionalKeyResultsData,
    requestObjectivesAndKeyResultsData,
    newsLetterOKRsObjectives,
    selectedObjective,
    handleOKRObjectiveChange,
    ObjectiveStartDate,
    handleObjectiveStartDateChange,
    ObjectiveEndDate,
    handleObjectiveEndDateChange,
    WeekStartDate,
    GetWeekStartDate,
    selectedKeyResults,
    newObjective,
    addNewObjective,
    ObjectiveKeyResultValueChange,
    EditObjectiveKeyResultRow,
    EnableAddNewforObjectiveKeyResult,
    cancelEditObjectiveKeyResultRow,
    filterKeyResults,
    getkeyResultsData,
    isAddNewObjective,
    refreshObjectivesDataAfterSave,
    CancelAddNewObjectiveData
}) => {
    useEffect(() => {
        if (isAuthenticated)
            addUserVisit(0, 'IdentityWeeklyNewsLetterOKRs.tsx_v2');
    }, [isAuthenticated]);
    useEffect(() => {
        requestObjectivesAndKeyResultsData();
    }, [requestObjectivesAndKeyResultsData]);
    useEffect(() => {
        if (WeekStartDate === null) {
            GetWeekStartDate(null);
        }
    }, [GetWeekStartDate]);

    //========== Call Load filterKeyResults method when WeekStartDate is changed ======//
    useEffect(() => {
        if (WeekStartDate !== null) {
            filterKeyResults();
        }
    }, [WeekStartDate]);

    const toastIdRef = useRef(null);

    //=======================function on textbox change while Editing a row in Objective Key Results==============//
    const ObjectiveKeyResultvalueschange = (e, value) => {
        let val: number = value.keyResultID;
        ObjectiveKeyResultValueChange(e, val);
    }

    //================ function to enable the Edit for Objective Key Results Row =====================//
    const EditObjectiveKeyResultRows = (e, value) => {
        let val: number = value.keyResultID;
        EditObjectiveKeyResultRow(val);
    }

    //=============== function to enable Add new row for Objective Key Results ====================================//
    const EnableAddNewforObjectiveKeyResultRow = () => {
        EnableAddNewforObjectiveKeyResult();
    }

    //==================function to Cancel the Edit for Objective Key results Row ==================================//
    const cancelEditObjectiveKeyResultRows = (e, value) => {
        if (window.confirm("Are you sure you want to Cancel this Edit?")) {
            let val: number = value.keyResultID;
            cancelEditObjectiveKeyResultRow();
        }

    }

    //================= function to delete the selected Row for Objective Key Results =======================//
    const DeleteObjectiveKeyResultRow = (e, row) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            setloadingtrue();
            IdentityWeeklyNewsLetterAPI.DeleteobjectiveKeyResultsData(row.keyResultID, username)
                .then((result: number) => {
                    if (result > 0) {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg("OKRs Data for Key Result " + row.keyResults + " Deleted successfully",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                        // To Reload only the Key Results for that corresponding Objective and Week
                        getkeyResultsData();
                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while deleting",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
    }

    //=============function to Save for OKRs Objective Data====================//
    const SaveAddNewObjectiveData = () => {
        if (isNullOrUndefined(ObjectiveStartDate)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please Select the  Objective StartDate',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        else if (isNullOrUndefined(ObjectiveEndDate)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please Select the  Objective EndDate',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        else if (isNullOrUndefined(newObjective) || !/\S/.test(newObjective)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please enter the Objective Value',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        else {
            setloadingtrue();
            let message = newObjective + "Objective Saved successfully";
            let ObjectiveStDate = moment(ObjectiveStartDate).format('MM/DD/YYYY');
            let ObjectiveEdDate = moment(ObjectiveEndDate).format('MM/DD/YYYY');
            IdentityWeeklyNewsLetterAPI.AddObjectivesData(newObjective, ObjectiveStDate, ObjectiveEdDate, username)
                .then((result: number) => {
                    if (result != 0) {
                        // To Reload the Objective
                        refreshObjectivesDataAfterSave(result);
                        toastIdRef.current = toast.success(
                            renderToastifyMsg(message,
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                    else {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Error while adding the Special Topics Data",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while adding the Special Topics Data",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
    }

    //=============function to Save for Edit/Add the  values for Objectives Key Results====================//
    const SaveObjectiveKeyResultData = (e, row) => {
        if (row.keyResultID === 0) {
            row.createdBy = username;
        }
        else {
            row.updatedBy = username;
        }

        row.weekDate = moment(WeekStartDate).format('MM/DD/YYYY');
        if (isNullOrUndefined(row.objectiveID) || row.objectiveID === 0) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please select the Objective to add the Key Result',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        else if (isNullOrUndefined(row.keyResultsEdit) || !/\S/.test(row.keyResultsEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please enter the Objective Key Result',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        else if (isNullOrUndefined(row.targetValueEdit) || !/\S/.test(row.targetValueEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please enter the Target Value',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );

        }
        else if (isNullOrUndefined(row.progressValueEdit) || !/\S/.test(row.progressValueEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please enter the progress Value',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );

        }
        else {
            setloadingtrue();
            let message = (row.keyResultID === 0) ? "OKRs Data for Key Result " + row.keyResultsEdit + " Saved successfully" : "OKRs Data updated successfully";
            IdentityWeeklyNewsLetterAPI.AddorupdateObjectivesKeyResultsData(row)
                .then((result: number) => {
                    if (result != -1) {
                        // To Reload only the Key Results for that corresponding Objective and Week
                        getkeyResultsData();
                        toastIdRef.current = toast.success(
                            renderToastifyMsg(message,
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                    else {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Error while adding the Special Topics Data",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while adding the Special Topics Data",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
    }

    return (
        <LoadingOverlay active={isLoading}
            spinner
            text='Loading your content...'
        >
            <section className='section'>
                <div className='container'>
                    <DivisionalOKRsTable
                        newsLetterOKRsObjectives={newsLetterOKRsObjectives}
                        selectedObjective={selectedObjective}
                        handleOKRObjectiveChange={(selectedObjective) => handleOKRObjectiveChange(selectedObjective)}
                        ObjectiveStartDate={ObjectiveStartDate}
                        handleObjectiveStartDateChange={handleObjectiveStartDateChange}
                        ObjectiveEndDate={ObjectiveEndDate}
                        handleObjectiveEndDateChange={handleObjectiveEndDateChange}
                        WeekStartDate={WeekStartDate}
                        GetWeekStartDate={GetWeekStartDate}
                        selectedKeyResults={selectedKeyResults}
                        newObjective={newObjective}
                        addNewObjective={(event) => addNewObjective(event)}
                        EnableAddNewforObjectiveKeyResultRow={EnableAddNewforObjectiveKeyResultRow}
                        EditObjectiveKeyResultRows={(e, row) => EditObjectiveKeyResultRows(e, row)}
                        DeleteObjectiveKeyResultRow={(e, row) => DeleteObjectiveKeyResultRow(e, row)}
                        cancelEditObjectiveKeyResultRows={(e, row) => cancelEditObjectiveKeyResultRows(e, row)}
                        ObjectiveKeyResultvalueschange={(e, row) => ObjectiveKeyResultvalueschange(e, row)}
                        SaveObjectiveKeyResultData={(e, row) => SaveObjectiveKeyResultData(e, row)}
                        SaveAddNewObjectiveData={SaveAddNewObjectiveData}
                        CancelAddNewObjectiveData={CancelAddNewObjectiveData}
                        isAddNewObjective={isAddNewObjective}
                     />
                </div>
            </section>
        </LoadingOverlay>

    );
};

const mapStateToProps = (state: IApplicationState) => ({
    ...state.DivisionalOKRs,
    isAuthenticated: state.authUser.isAuthenticated,
    username: state.authUser.login
})

export default connect(mapStateToProps, actionCreators)(DivisionalOKRs);
