import {getAppInsightsInstance} from "./ApplicationInsights";

const appInsights = await getAppInsightsInstance();

// Handle uncaught exceptions
window.onerror = (message, source, lineno, colno, error) => {
    
    if (!appInsights) {
        return;
    }
    appInsights.trackException({
        exception: new Error(`Uncaught Exception (frontend): ${error}`),
        severityLevel: 3, // (0 - Verbose, 1 - Information, 2 - Warning, 3 - Error, 4 - Critical)
        properties: {
            message,
            source,
            lineno,
            colno,
        },
    });

    // Return true to prevent the browser from displaying the error
    return true;
};

// Handle unhandled promise rejections
window.onunhandledrejection = (event) => {
    
    if (!appInsights) {
        return;
    }
    appInsights.trackException({
        exception: new Error(`Unhandled Rejection (frontend): ${event.reason}`),
        severityLevel: 3,
    });
};