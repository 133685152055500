import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, ILastWeekIdNewsLetterState, TileDescription } from './types';

const initialState = Object.freeze<ILastWeekIdNewsLetterState>({
    isLoading: false,
    WeekStartDate: null,
    StartDate: null,
    EndDate: null,
    appSessionToken: null,
    DateRange: null,
    newsLettertopOutagesDataTable: [],
    originalNewsLettertopOutagesData: [],
    newsLettertopOutagesData: [],
    NewsLetterTilesData: [],
    NewsLetterTilesRawData: [],
    NewsLetterTilesCount: 0,
    OoslaPIRs: [],
    OoslaSTRI: [],
    PirCompletionData: [],
    pirHTMLContent: '',
    repairItemshtmlContent: '',
    STRepairItemsData: [],
    lastWeekOnCallData: []
});

export const reducer = (
    state: ILastWeekIdNewsLetterState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false
            };
        case ActionType.WEEKLY_START_DATE_CHANGE:
            return {
                ...state,
                WeekStartDate: action.WeekStartDate,
                StartDate: action.StartDate,
                EndDate: action.EndDate,
                DateRange: action.DateRange,
                appSessionToken: action.sessionToken
            };
        case ActionType.GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_TABLE_SUCCESS:  // === for IDENTITY WEEKLY NEWS LETTER TopOutages Data success ===============//
            return {
                ...state,
                newsLettertopOutagesDataTable: action.newsLettertopOutagesDataTable,
                isLoading: (action.count >= 2) ? false : state.isLoading
            };
        case ActionType.GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_TABLE_FAILURE:  // === for IDENTITY WEEKLY NEWS LETTER TopOutages Data failure ===============//
            return {
                ...state,
                newsLettertopOutagesDataTable: [],
                isLoading: (action.count >= 2) ? false : state.isLoading
            };
        case ActionType.GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_SUCCESS:  // === for IDENTITY WEEKLY NEWS LETTER TopOutages Data success ===============//
            return {
                ...state,
                newsLettertopOutagesData: action.newsLettertopOutagesData,
                originalNewsLettertopOutagesData: action.originalNewsLettertopOutagesData,
                isLoading: (action.count >= 2) ? false : state.isLoading
            };
        case ActionType.GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_FAILURE:  // === for IDENTITY WEEKLY NEWS LETTER TopOutages Data failure ===============//
            return {
                ...state,
                newsLettertopOutagesData: [],
                originalNewsLettertopOutagesData: [],
                isLoading: (action.count >= 2) ? false : state.isLoading
            };
        case ActionType.DELETE_TOPOUTAGE_DATA_FAIL:  // === for IDENTITY WEEKLY NEWS LETTER TopOutages Delete Data failure ===============//
            return {
                ...state,
                newsLettertopOutagesData: [],
                NewsLetterTilesRawData: [],
                originalNewsLettertopOutagesData: [],
                isLoading: false
            };
        case ActionType.TOPOUTAGES_INPUT_CHANGE:
            return {
                ...state,
                newsLettertopOutagesData: action.newsLettertopOutagesData
            };
        case ActionType.GET_IDENTITYNEWSLTTERTILESDATA_SUCCESS:
            return {
                ...state,
                NewsLetterTilesCount: action.NewsLetterTilesCount,
                NewsLetterTilesData: action.NewsLetterTilesData,
                NewsLetterTilesRawData: action.NewsLetterTilesRawData,
                OoslaPIRs: action.OoslaPIRs,
                OoslaSTRI: action.OoslaSTRI,
                pirHTMLContent: action.pirHTMLContent,
                repairItemshtmlContent: action.repairItemshtmlContent,
                isLoading: (action.count >= 2) ? false : state.isLoading,
            };
        case ActionType.GET_IDENTITYNEWSLTTERTILESDATA_FAILURE:
            return {
                ...state,
                NewsLetterTilesCount: 0,
                NewsLetterTilesData: [],
                OoslaPIRs: [],
                OoslaSTRI: [],
                pirHTMLContent: '',
                repairItemshtmlContent: '',
                isLoading: (action.count >= 2) ? false : state.isLoading,
            };
        case ActionType.TILESINPUT_CHANGE:
            return {
                ...state,
                NewsLetterTilesData: action.NewsLetterTilesData
            };
        case ActionType.TILESINPUT_CLEAR:
            return {
                ...state,
                NewsLetterTilesData: action.NewsLetterTilesRawData
            };
        case ActionType.UPDATETILE_SUCCESS:
            return {
                ...state,
                isLoading: false
            };
        case ActionType.UPDATETILE_FAILURE:
            return {
                ...state,
                isLoading: false,
                NewsLetterTilesData: action.NewsLetterTilesRawData
            };
        case ActionType.GET_PIRCOMPLETIONDATA_SUCESS:
            return {
                ...state,
                isLoading: (action.count >= 2) ? false : state.isLoading,
                PirCompletionData: action.PirCompletionData
            };
        case ActionType.GET_PIRCOMPLETIONDATA_FAILURE:
            return {
                ...state,
                isLoading: (action.count >= 2) ? false : state.isLoading,
                PirCompletionData: []
            };
        case ActionType.GET_LASTWEEKONCALLDATA_SUCCESS:
            return {
                ...state,
                isLoading: (action.count >= 2) ? false : state.isLoading,
                lastWeekOnCallData: action.lastWeekOnCallData
            };
        case ActionType.GET_LASTWEEKONCALLDATA_FAILURE:
            return {
                ...state,
                isLoading: (action.count >= 2) ? false : state.isLoading,
                lastWeekOnCallData: []
            };

        case ActionType.SEND_EMAIL_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.SEND_EMAIL_REQUEST_FAIL:
            return {
                ...state,
                isLoading: false
            };
        case ActionType.SEND_EMAIL_REQUEST_PASS:
            return {
                ...state,
                isLoading: false
            };
        case ActionType.SET_EMAIL_LOADING_TRUE:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.GET_STREPAIRITEMS_SUCCESS:
            return {
                ...state,
                isLoading: (action.count >= 2) ? false : state.isLoading,
                STRepairItemsData: action.STRepairItemsData
            };
        case ActionType.GET_STREPAIRITEMS_FAILURE:
            return {
                ...state,
                isLoading: (action.count >= 2) ? false : state.isLoading,
                STRepairItemsData: []
            };
        case ActionType.REFRESH_TOPOUTAGE_DATA_REQUEST: 
            return {
                ...state
              //  isLoading: true
            };
        case ActionType.REFRESH_TOPOUTAGE_DATA_FAIL: 
            return {
                ...state,
                newsLettertopOutagesData: [],
                NewsLetterTilesRawData: [],
                originalNewsLettertopOutagesData: [],
                isLoading: false
            };
        case '@@router/LOCATION_CHANGE': {
            const { search } = action.payload.location;
            const params = new URLSearchParams(search);
            var newurl = window.location.pathname;
            //Below conditions only for direct url access like bookmarks
            if (newurl.indexOf("LastWeekIdNewsLetter") !== -1) {
                return {
                    ...state,
                    WeekStartDate: null,
                    StartDate: null,
                    EndDate: null
                }
            }
        };
        case ActionType.RESET_STATE:
            return initialState;

        default:
            return state;
    }
};
