import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import { actionCreators, reducer } from '../../store/Configuration';
import { ConfigurationTable } from '../Configuration/child-components';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap'
import { Row, Col, Card, CardBody } from 'reactstrap';
import { ConfigurationAPI } from '../../api';
import { toast } from 'react-toastify';
import { isNullOrUndefined, isArrayWithLength, renderToastifyMsg } from '../../utils';
import RedButtonConfig1 from '../../assets/images/ToolsConfigRedButton/RedButtonConfig1.png';
import RedButtonConfig2 from '../../assets/images/ToolsConfigRedButton/RedButtonConfig2.png';
import { DeprecatedFunctionalityBanner } from '../../components';

type ConfigurationProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly username: string }
    & typeof useParams;

const Configuration: React.FC<ConfigurationProps> = ({
    isAuthenticated,
    isLoading,
    setloadingtrue,
    setloadingfalse,
    username,
    addUserVisit,
    selectedService,
    services,
    GetConfigurationData,
    GetHeatMapTabServicesData,
    serviceChange,
    serviceSelected,
    tenantshide,
    displayCCLSuccessMessage,
    selectedTenant,
    tenantChange,
    tenants,
    questionarehighlevelInfo,
    UpdatequestionarehighlevelInfo,
    questionareOncall,
    UpdatequestionareOncall,
    questionarecclFiltering,
    setquestionarecclFiltering,
    questionarecommDL,
    updatequestionarecommDL,
    questionarecclPM,
    updatequestionarecclPM,
    questionarecustomerImpacting,
    updatequestionarecustomerImpacting,
    tenantsWithOnboarded,
    OnBoard_Service_CCL,
    isEnabled,
    addNewService,
    showMessage,
    HeatMapServiceInsert,
    currentserviceId,
    isRBLValidated,
    showRBLMessage,
    showRBLOnboardedMessage,
    HeatmapValidate,
    RBLValidate,
    questionareGPMName,
    questionareIsTenantOrSubscriptionIdBased,
    questionarePartners,
    updatequestionareGPMName,
    updatequestionareIsTenantOrSubscriptionIdBased,
    questionareComments,
    UpdatequestionareComments,
    updatequestionarePartners
}) => {
    useEffect(() => {
        if (isAuthenticated)
            addUserVisit(0, 'ToolsConfiguration.tsx_v2');
    }, [isAuthenticated]);
    useEffect(() => {
        GetHeatMapTabServicesData();
        GetConfigurationData();
    }, [GetConfigurationData]);
    const toastIdRef = useRef(null);
    const [showconfigurationImagesPopup, setshowconfigurationImagesPopup] = useState(false);

    //=============== function to close RedButton Configuration Images popup =========================//
    const closeconfigurationImagesPopup = () => {
        setshowconfigurationImagesPopup(false);
    }

    //=============== function to close RedButton Configuration Images popup =========================//
    const openconfigurationImagesPopup = () => {
        setshowconfigurationImagesPopup(true);
    }

    return (
        <div>
            <DeprecatedFunctionalityBanner />
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        <ConfigurationTable
                            selectedService={selectedService}
                            services={services}
                            serviceChange={serviceChange}
                            serviceSelected={serviceSelected}
                            tenantshide={tenantshide}
                            displayCCLSuccessMessage={displayCCLSuccessMessage}
                            selectedTenant={selectedTenant}
                            tenantChange={tenantChange}
                            tenants={tenants}
                            questionarehighlevelInfo={questionarehighlevelInfo}
                            UpdatequestionarehighlevelInfo={(event) => UpdatequestionarehighlevelInfo(event)}
                            questionareOncall={questionareOncall}
                            UpdatequestionareOncall={(event) => UpdatequestionareOncall(event)}
                            questionarecclFiltering={questionarecclFiltering}
                            setquestionarecclFiltering={setquestionarecclFiltering}
                            questionarecommDL={questionarecommDL}
                            updatequestionarecommDL={(event) => updatequestionarecommDL(event)}
                            questionarecclPM={questionarecclPM}
                            updatequestionarecclPM={(event) => updatequestionarecclPM(event)}
                            questionarecustomerImpacting={questionarecustomerImpacting}
                            updatequestionarecustomerImpacting={(event) => updatequestionarecustomerImpacting(event)}
                            tenantsWithOnboarded={tenantsWithOnboarded}
                            OnBoard_Service_CCL={OnBoard_Service_CCL}
                            isEnabled={isEnabled}
                            addNewService={addNewService}
                            showMessage={showMessage}
                            HeatMapServiceInsert={HeatMapServiceInsert}
                            currentserviceId={currentserviceId}
                            isRBLValidated={isRBLValidated}
                            openconfigurationImagesPopup={openconfigurationImagesPopup}
                            showRBLMessage={showRBLMessage}
                            showRBLOnboardedMessage={showRBLOnboardedMessage}
                            HeatmapValidate={HeatmapValidate}
                            RBLValidate={RBLValidate}
                            questionareGPMName={questionareGPMName}
                            questionareIsTenantOrSubscriptionIdBased={questionareIsTenantOrSubscriptionIdBased}
                            questionarePartners={questionarePartners}
                            updatequestionareGPMName={(event) => updatequestionareGPMName(event)}
                            updatequestionareIsTenantOrSubscriptionIdBased={(event) => updatequestionareIsTenantOrSubscriptionIdBased(event)}
                            questionareComments={questionareComments}
                            UpdatequestionareComments={(event) => UpdatequestionareComments(event)}
                            updatequestionarePartners={(event) => updatequestionarePartners(event)}
                        />
                    </div>
                </section>
            </LoadingOverlay>
            <Modal show={showconfigurationImagesPopup} onHide={closeconfigurationImagesPopup} size='lg' backdrop='static' >
                <Modal.Header closeButton>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='12'>
                                <h4><b> Steps to follow </b></h4>
                            </Col>
                        </Row>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ paddingTop: '15px', height: '600px' }}>
                        <Col lg='12'>
                            <img className='userGuideImage' src={RedButtonConfig1} style={{ width: '900px' }}></img>
                            &emsp; &emsp;
                            <img className='userGuideImage' src={RedButtonConfig2} style={{ width: '900px' }}></img>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    );
};


const mapStateToProps = (state: IApplicationState) => ({
    ...state.Configuration,
    isAuthenticated: state.authUser.isAuthenticated,
    username: state.authUser.login,
    isProd: state.authUser.isProd
})


export default connect(mapStateToProps, actionCreators)(Configuration);
