import React, { useState, Fragment } from 'react';
import DatePicker, { getDay } from 'react-datepicker'
import { ReduxAction, IAppThunkAction } from '../../../store';
import moment from 'moment';
import { Row, Col, Card, CardBody } from 'reactstrap';
type DublinSHRDataTableProps = {
    MeetingDate: any;
    MaxDate: any;
    readonly handleMeetingDateChange: (MeetingDate: Date) => IAppThunkAction<ReduxAction>;
};

const DublinSHRDataTable = React.memo<DublinSHRDataTableProps>(({
    MeetingDate,
    MaxDate,
    handleMeetingDateChange
}) => {
    const isThursday = (date) => {
        var day = new Date(moment(date).format('MM/DD/YYYY')).getDay();
        return day == 4;
    };

    const getMidWed = (year, month) => {
        var d = new Date(year, month - 1);
        d.setDate(d.getDate() + ((10 - d.getDay() + 1) % 7) + 7);
        return d;
    }

    const getSecondThursday = () => {
        let secweekDisable = [];
        const yearArray = Array.from({ length: 7 }, (item, index) => index + 2018);
        const monthsArray = Array.from({ length: 13 }, (item, index) => index + 1);
        let yearMonthArray = [];
        for (let year = 1; year < yearArray.length; year++) {
            for (let months = 1; months < monthsArray.length; months++) {
                yearMonthArray.push([yearArray[year], months]);
            }
        }
        yearMonthArray.forEach(
            function (a) {
                secweekDisable.push(getMidWed(a[0], a[1]));
            }
        );
        return secweekDisable;
    };

    return (
        <React.Fragment>
            <Card className="cardstyle">
                <CardBody className="cardbodystyle">
                    <Row>
                        <Col lg="1"></Col>
                        <Col lg="5">
                            <h3 className="headertext"> Weekly Dublin Service Health Review </h3>
                        </Col>
                        <Col lg="4">
                            <span>Select Meeting Date : </span>
                            <DatePicker className='InputStyle'
                                selected={MeetingDate}
                                onChange={handleMeetingDateChange}
                                calendarIcon={true}
                                calendarAriaLabel={true}
                                excludeDates={getSecondThursday()}
                                filterDate={isThursday}
                                maxDate={MaxDate}
                            />
                        </Col>
                        <Col lg="2"></Col>
                    </Row>
                </CardBody>
            </Card>
            <div className="divpaddingtop">
                <Card>
                    <CardBody className="cardbodytext">
                        <Row>
                            <Col lg="6">
                                Identity PIR guidelines, read the wiki <a href="https://identitydivision.visualstudio.com/IdentityWiki/_wiki/wikis/IdentityWiki.wiki?pagePath=%2FIdentityWiki%2FFundamentals%2FLive%20Site%2FIdentity%20Postmortem%20Guidelines&pageId=2507&wikiVersion=GBwikiMaster" target="_blank">here</a><br />
                                Before presenting in the review read the presentation requirements <a href="https://identitydivision.visualstudio.com/IdentityWiki/_wiki/wikis/IdentityWiki.wiki?pagePath=%2FIdentityWiki%2FFundamentals%2FLive%20Site%2FIdentity%20Postmortem%20Guidelines%2FPIR%20Presentations%20in%20the%20Weekly%20Ideneity%20Service%20Health%20Review&pageId=2933&wikiVersion=GBwikiMaster" target="_blank">here</a>
                            </Col>
                            <Col lg="6">
                                <span className="label-info">Note: IcM data pulled from IcM Kusto DB which has up to 15 min data propagation delay.</span>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
});

DublinSHRDataTable.displayName = 'DublinSHRDataTable';
export default DublinSHRDataTable;