import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import { actionCreators, IADOApprovalTaskDetails, reducer } from '../../store/ADOTaskCreation';
import LoadingOverlay from 'react-loading-overlay';
import "../../assets/style/scss/components/ADOTaskCreation.scss";
import { ADOTaskCreationTable } from './child-components';
import { Modal } from 'react-bootstrap';
import { Row, Col } from 'reactstrap';
import { isArrayWithLength, renderToastifyMsg } from '../../utils';
import { DeprecatedFunctionalityBanner } from '../../components';

type ADOTaskCreationProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly history: History }
    & typeof useParams;

const ADOTaskCreation: React.FC<ADOTaskCreationProps> = ({
    isLoading,
    isAuthenticated,
    addUserVisit,
    eta,
    description,
    title,
    assignedTo,
    areaPath,
    setdescription,
    settitle,
    setAssignedTo,
    setareaPath,
    setEta,
    approvalTasksDetails,
    getApprovalTasksDetails,
    checkAllValues,
    updatecheck,
    approvalDisabled,
    approveTask,
    CreateTask,
    previousTitle,
    id,
    errorMsg,
    tags,
    handleTagsChange,
    getAdoTags,
    getAreaPath,
    tagsValue,
    areaPathList,
    areaPathDropdown,
    areaPathSelected
}) => {
    useEffect(() => {
        if (isAuthenticated)
            addUserVisit(0, 'ADOTaskCreation.tsx_v2');
        getAdoTags();
        getAreaPath();
    }, [isAuthenticated]);

    const [showModal, setModal] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const setcheckAllValues = () => {
        setCheckAll(!checkAll);
        checkAllValues(!checkAll);
    }
    const closeApprovalPopup = () => {
        setModal(false);
    }

    return (
        <div>
            <DeprecatedFunctionalityBanner />
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        <div className='PageContent adotaskcreationpage'>
                            <Row style={{ display: id == '' ? 'none' : 'block' }}>
                                <Col>
                                    <label>ADO # <a target="_blank" href={"https://identitydivision.visualstudio.com/DefaultCollection/Engineering/_workitems/edit/" + id}><b>{id}</b></a> for previous created task - title <b>{previousTitle}</b></label>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ display: errorMsg == '' ? 'none' : 'block' }}>
                                    <label style={{ color: 'red' }}>There is error while creating the ADO and here is the error.<b> {errorMsg}</b></label>
                                </Col>
                            </Row>
                            <br></br>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr className="PageHeader">
                                        <td style={{ paddingLeft: '10px' }}>
                                            <span className="PageHeaderContent">Create ADO Task</span>
                                        </td>
                                        <td>
                                            <button style={{ float: 'right' }} className="btn btn-info" onClick={e => { setModal(true); getApprovalTasksDetails() }}>Approve Task</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ padding: '0px' }}>
                                            <ADOTaskCreationTable eta={eta} description={description} title={title} assignedTo={assignedTo} areaPath={areaPath} setdescription={setdescription} settitle={settitle} setAssignedTo={setAssignedTo} setareaPath={setareaPath} setEta={setEta} CreateTask={CreateTask} tags={tags} handleTagsChange={handleTagsChange} tagsValue={tagsValue}
                                                areaPathList={areaPathList}
                                                areaPathDropdown={areaPathDropdown}
                                                areaPathSelected={areaPathSelected} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
                <Modal show={showModal} onHide={closeApprovalPopup} size='lg' backdrop='static' className="AdoModal" style={{ minWidth: '100% !important' }}>
                    <Modal.Header className="commonModalHeader" closeButton>
                        <div style={{ width: '100%' }}>
                            <Row style={{ textAlign: 'center' }}>
                                <Col lg='12'>
                                    <h4><b> Task Approval </b></h4>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col lg='12'>
                                <table className="approveTask">
                                    <tbody>

                                        <tr>
                                            <th style={{ width: '5%' }}>Task #</th>
                                            <th style={{ width: '8%' }}>Area Path</th>
                                            <th style={{ width: '9%' }}>Assigned To</th>
                                            <th style={{ width: '15%' }}>Title</th>
                                            <th style={{ width: '25%' }}>Description</th>
                                            <th style={{ width: '8%' }}>Tags</th>
                                            <th style={{ width: '8%' }}>Created By</th>
                                            <th style={{ width: '8%' }}>Created Date</th>
                                            <th style={{ width: '5%' }}>Is Approved</th>
                                            <th style={{ width: '5%' }}>Is Email Sent</th>
                                            <th style={{ verticalAlign: 'middle', width: '4%', textAlign: 'center' }}><input type="checkbox" onChange={e => { setcheckAllValues() }} checked={checkAll} /></th>
                                        </tr>
                                        {
                                            isArrayWithLength(approvalTasksDetails) && approvalTasksDetails.map((val: IADOApprovalTaskDetails, index) => (
                                                <React.Fragment key={index}>
                                                    {
                                                        <tr>
                                                            <td><a target="_blank" href={"https://identitydivision.visualstudio.com/DefaultCollection/Engineering/_workitems/edit/" + val.taskId}>{val.taskId}</a></td>
                                                            <td>{val.areaPath}</td>
                                                            <td>{val.assigendTo}</td>
                                                            <td>{val.title}</td>
                                                            <td className='RTELIdots' dangerouslySetInnerHTML={{ __html: val.description }}></td>
                                                            <td>{val.tags}</td>
                                                            <td>{val.createdBy}</td>
                                                            <td>{val.createdDate}</td>
                                                            <td>{val.isApproved}</td>
                                                            <td>{val.isEmailSent}</td>
                                                            <td style={{ width: '4%', textAlign: 'center' }}><input type="checkbox" onChange={e => { updatecheck(val) }} checked={val.isSelected} /></td>
                                                        </tr>
                                                    }
                                                </React.Fragment>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer style={{ textAlign: 'center' }}>
                        <button type="button" className="checkquestionbuttons btn btn-default" disabled={approvalDisabled} onClick={(e) => { approveTask(); setModal(false); }}>Approve</button>
                        <button type="button" className="checkquestionbuttons btn btn-default" onClick={e => { setModal(false) }}>Close</button>
                    </Modal.Footer>
                </Modal>
            </LoadingOverlay>
        </div>
    );
};

const mapStateToProps = (state: IApplicationState) => ({
    ...state.ADOTaskCreation,
    isAuthenticated: state.authUser.isAuthenticated
})

export default connect(mapStateToProps, actionCreators)(ADOTaskCreation);