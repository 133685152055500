import { BaseService } from './base.service';
import { IADOApprovalTaskDetails } from '../store/ADOTaskCreation';

class ADOTaskCreationService extends BaseService {

    private static _adotaskcreationService: ADOTaskCreationService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): ADOTaskCreationService {
        return (this._adotaskcreationService || (this._adotaskcreationService = new this('ADOTaskCreation')));
    }

    public async ADOApprovalTaskDetails(): Promise<IADOApprovalTaskDetails[]> {        
        const { data } = await this.$http.get<IADOApprovalTaskDetails[]>(`GetTaskPendingApproval`);
        return data;
    }

    public async ApproveADOTask(id, taskId, assignedTo, isApproved, title, emailcontent, loginUser): Promise<string> {        
        const { data } = await this.$http.post<string>(`ApproveADOTask?id=${id}&taskId=${taskId}&assignedTo=${assignedTo}&isApproved=${isApproved}&title=${title}&emailcontent=${emailcontent}&loginUserAlias=${loginUser}`);
        return data;
    }

    public async CreateTask(areaPath, title, description, assignedTo, loginUser, eta, tagsValue): Promise<string[]> {
        var createTaskProp = { "AreaPath": areaPath, "AssigendTo": assignedTo, "Title": title, "Description": description, "LoginUser": loginUser, "Eta": eta, "Tags": tagsValue };
        const { data } = await this.$http.post<any>(`CreateTask`, createTaskProp);
        return data;
    }
    public async GetAdoTags(): Promise<object[]> {
        const { data } = await this.$http.get<object[]>(`GetAdoTags`);
        return data;
    }
    public async GetAdoAreaPaths(): Promise<object[]> {
        const { data } = await this.$http.get<object[]>(`GetAdoAreaPaths`);
        return data;
    }
}

export const ADOTaskCreationAPI = ADOTaskCreationService.Instance;