import React, {  useState, useRef, Fragment } from 'react';
import { isArrayWithLength, renderToastifyMsg, isNullOrUndefined } from '../../../utils';
import { IWeeklyReport } from '../../../store/service-health';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignal, faPencilAlt, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import cancel from '../../../assets/images/cancel.png';
import savebuttonicon from '../../../assets/images/save-buttonicon.png';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {  WeeklyAPI } from '../../../api';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Row,
    Col
} from 'reactstrap';
type WeeklyPerformanceReportProps = {
    serviceId,
    weeklyStartDate,
    Performance_data,
    GetPerformanceData,
    weekDetailsData,
    ExternalLink,
    ShowExternalPerformanceLink,
    NoPerfData,
    MainPerformanceData,
    envactive,
    subenvactive,
    CurrentService,
    hideperformancePopupData,
    LoginuserId,
    setloadingtrue,
    setloadingfalse

};

const WeeklyPerformanceReport = React.memo<WeeklyPerformanceReportProps>(({
    serviceId,
    weeklyStartDate,
    Performance_data,
    GetPerformanceData,
    weekDetailsData,   
    MainPerformanceData,
    envactive,
    subenvactive,
    CurrentService,
    hideperformancePopupData,
    LoginuserId,
    setloadingtrue,
    setloadingfalse

}) => {
    const toastIdRef = useRef(null);
    const [activeTab, setActiveTab] = useState(envactive);
    const [Performancedata, setPerformancedata] = useState(Performance_data);
    const [activesubTab, setactivesubTab] = useState(subenvactive);
    const [IsPerfPopupEdit, setIsPerfPopupEdit] = useState(false);
    const [GraphOptions, setGraphOptions] = useState(null);
    const [IsVolumeGraphExist, setIsVolumeGraphExist] = useState(false);
    const [CheckIfGraphExist, setCheckIfGraphExist] = useState(false);
    const [IsperfermanceRed, setIsperfermanceRed] = useState(false);
    const changeIsPerfPopupEdit = (e, val) => {
        if (val === true) {
            Performancedata.map((obj: IWeeklyReport)=>{
                obj.week5Edit = obj.week5;
                obj.cmtcolor = "lightcyan";
                obj.valuecolor = "lightcyan";
                obj.comments='';
            })
        }
        setIsPerfPopupEdit(val);
    }
    const Performancevaluechange = (e, index) => {
        var copy = [];
        Performancedata.map((obj: IWeeklyReport, key) => {
            if (key === index) {
                obj.week5Edit = e.target.value;
            }
            copy.push(obj);  
        })
        setPerformancedata(copy);
        
    }
    const PerformanceCommentschange = (e, index) => {
        var copy = [];
        Performancedata.map((obj: IWeeklyReport, key) => {
            if (key === index) {
                obj.comments = e.target.value;
            }
            copy.push(obj);  
        })
        setPerformancedata(copy);

    }
    const toggle = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        };
    };
    const togglesubenv = tab => {
        if (activesubTab !== tab) {
            setactivesubTab(tab)
        };
    };
    //Load Performance Graphs
    function LoadPerformanceGraphs(details, group, volumeLabel) {
        var data = details.PerformanceData;
        var series = "";
        var _series = [];
        if (isArrayWithLength(data)) {
            data.map((obj) => {
                if (obj.environment === group && (obj.config === 95 || volumeLabel === "Volume")) {
                    series = obj.metricName;
                    // $scope.AvailabilityData.push(data[counter]);
                    _series.push({
                        "name": series,
                        "data": [(obj.week1 === '') ? 0 : parseFloat(obj.week1.replace(new RegExp(",", "g"), "")),
                        (obj.week2 === '') ? 0 : parseFloat(obj.week2.replace(new RegExp(",", "g"), "")),
                        (obj.week3 === '') ? 0 : parseFloat(obj.week3.replace(new RegExp(",", "g"), "")),
                        (obj.week4 === '') ? 0 : parseFloat(obj.week4.replace(new RegExp(",", "g"), "")),
                        (obj.week5 === '') ? 0 : parseFloat(obj.week5.replace(new RegExp(",", "g"), ""))],
                        connectNulls: true
                    });
                }
            })
        }
        const GraphOptions = {
            chart: {
                type: "spline",
                width: 900,
                height: 600
            },
            title: {
                style: {
                    "fontSize": "1.48em",
                    "top": "-10px",
                    "margin-left": "-35px",
                    "margin-top": "-10px"
                },
                useHTML: true,
                x: -27,
                y: 8,
                text: group + " Graph"
            },
            subtitle: {
                text: ""
            },
            credits: {
                enabled: false
            },
            yAxis: {
                type: "logarithmic",
                minorTickInterval: 0.1,
                title: {
                    text: "Time in MS "
                }
            },
            series: _series,
            xAxis: {
                categories: [details.Weeks.week1, details.Weeks.week2, details.Weeks.week3, details.Weeks.week4, details.Weeks.week5]
            }
        };

        setGraphOptions(GraphOptions);


    }

    const LoadPerformanceGraph = (group, volumeLabel) => {
        var details = {};
        setGraphOptions([]);
        var bool = false;
        var GraphExist = false;
        if (isArrayWithLength(Performancedata)) {
            Performancedata.map((obj) => {
                var metricName = obj.metricName.split(" ").splice(-1).toString();
                if (metricName.toLowerCase() === 'volume' || metricName.toLowerCase() === 'count') {
                    bool = true;
                }
                if (obj.config && obj.config != null) {
                    GraphExist = true;
                }
            })
        }
        setIsVolumeGraphExist(bool);
        setCheckIfGraphExist(GraphExist);


        if (volumeLabel === 'Volume') {
            var volumeData = [];
            if (isArrayWithLength(Performancedata)) {
                Performancedata.map((obj) => {
                    var metricName = obj.metricName.split(" ").splice(-1).toString();
                    if (metricName.toLowerCase() === 'volume' || metricName.toLowerCase() === 'count') {
                        volumeData.push(obj);
                    }
                })
            }

            details = {
                PerformanceData: volumeData, Weeks: weekDetailsData.weeks
            };
        }
        else {
            details = {
                "PerformanceData": Performancedata, "Weeks": weekDetailsData.weeks
            };
        }

        LoadPerformanceGraphs(details, group, volumeLabel);
    }   
    const CheckIsVolumeGraphExist = (env) => {
        if (isArrayWithLength(Performancedata)) {
            var bool = false;
            Performancedata.map((obj: IWeeklyReport) => {
                if (obj.environment === env) {
                    var metricName = obj.metricName.split(" ").splice(-1).toString();
                    if (metricName.toLowerCase() == 'volume' || metricName.toLowerCase() == 'count') {
                        bool = true;
                    }
                }
            })
        }
        return bool;
    }
    const CheckIsGraphExist = (env) => {
        if (isArrayWithLength(Performancedata)) {
            var bool = false;
            Performancedata.map((obj: IWeeklyReport) => {
                if (obj.environment === env) {
                    if (obj.config && obj.config != null) {
                        bool = true;
                    }
                }
            })
        }
        return bool;
    }
    const togglevolume = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        };
        LoadPerformanceGraph(tab.split('~')[0], '');
    };
    const togglevolume1 = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        };
        LoadPerformanceGraph(tab.split('!')[0], 'Volume');
    };
    const [subactiveTab, setsubActiveTab] = useState('Identity Authentication and Authorization (AuthNZ)<br/>Nadim Abdo');
    const subtoggle = tab => {
        if (subactiveTab !== tab) {
            setsubActiveTab(tab)
        };
    };
    var Perfenvironment = '';
    const setPerfenvironment = (name) => {
        Perfenvironment = name;
    }
    var Perf13environment = [];
    const set13Perfenvironment = (name) => {
        Perf13environment.push(name);
    }
    var Perfgraphenvironment = '';
    const setPerfgraphenvironment = (name) => {
        Perfgraphenvironment = name;
    }
    var Perfvolgraphenvironment = '';
    const setPerfvolgraphenvironment = (name) => {
        Perfvolgraphenvironment = name;
    }
    var Perftabcontenvironment = '';
    const settabcontPerfenvironment = (name) => {
        Perftabcontenvironment = name;
    }

    var Perftabcont13environment = [];
    const settabcontPerf13environment = (name) => {
        Perftabcont13environment.push(name);
    }

    var Perftabcontgraphenvironment = '';
    const settabcontgraphPerfenvironment = (name) => {
        Perftabcontgraphenvironment = name;
    }
    var Perftabvolcontgraphenvironment = '';
    const settabvolcontgraphPerfenvironment = (name) => {
        Perftabvolcontgraphenvironment = name;
    }
    // To compare with Uptime target values in Performance grid
    const ColorUptime = (target, value) => {
        value = (parseFloat(String(value))).toFixed(4);
        if (value >= parseFloat(String(target))) {
            return { background: "#CEF8C1" }; //LightGreen
        } else if (value < parseFloat(String(target))) {
            return { background: "#FFD1D3" };//LightPink
        }
    };
    //======================= Color Logic for Performance ========================//
    // To compare with latency target values in Performance grid
    const ColorWeek = (value, target, metricname) => {
        target = target.replace(/,/g, "");
        value = value.replace(/,/g, "");
        var metricData = metricname.split(" ").splice(-1).toString();
        if (metricData.toLowerCase() == 'volume' || metricData.toLowerCase() == 'count') {
            return { background: '#EBEBEB' }   //LightGrey
        }
        if (target.indexOf('%') > 0) {
            value = (parseFloat(String(value)));
            if (value >= parseFloat(String(target))) {

                return { background: '#CEF8C1' }  //LightGreen
            }
            else if (value < parseFloat(String(target))) {
                return { background: '#FFD1D3' }   //LightPink
            }
        }

        //If Target containt character remove using regex
        if (!isNaN(target)) {
            target = (parseFloat(String(target)));

        }
        else {
            target = target.match(/\d+\.?\d*/g);
        }

        //Compare value with Target
        if (value && value <= parseFloat(String(target))) {

            return { background: '#CEF8C1' }
        }
        else if (value && value > parseFloat(String(target))) {
            return { background: '#FFD1D3' }
        }


    }
   
    //============ function to save bulk edit values to DB in [performance]===============//
    const Edit_Service_Perf_Popup_ToHistory = () => {
        const jsonData = [];
        var counter = 1;
        var cnt = 0;

        var isValidationFail = false;
        const copy = [];
        Performancedata.map((obj: IWeeklyReport) => {
            var txtpopupWeek5 = null;
            var txtpopupcomment = null;
            var Isvalidation = true;
            if (!isValidationFail) {
                var newval = (obj.week5Edit === '' || obj.week5Edit === undefined || obj.week5Edit === null) ? '' : obj.week5Edit;
                var oldval = (obj.week5 === '' || obj.week5 === undefined || obj.week5 === null) ? '' : obj.week5;
                newval = newval.replace(/,/g, "");
                var cmnts = (obj.comments === '' || obj.comments === undefined || obj.comments === null) ? '' : obj.comments;
                if (newval !== oldval.replace(/,/g, "")) {
                    if (newval !== '') {

                        if (cmnts === "" || (obj.entrySource === true && (serviceId === 3 || serviceId === 17 || serviceId === 18))) {
                            toastIdRef.current = toast.error(
                                renderToastifyMsg("Not Updating:\t Please provide valid data or comments",
                                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 }
                            );
                            obj.cmtcolor = "red";
                            obj.valuecolor = "red";
                            isValidationFail = true;
                        }
                        else {
                            obj.cmtcolor = "lightcyan";
                            obj.valuecolor = "lightcyan";
                        }
                        if (obj.validationExpr) {
                            var re = new RegExp(obj.validationExpr, "g");//new RegExp("^\d+\\\d+\%$", "g");
                            if (newval.search(re) != 0 && newval != "") {
                                isValidationFail = true;
                                obj.valuecolor = "red";
                                toastIdRef.current = toast.error(
                                    renderToastifyMsg("Not Updating:\t Value is not in correct format.",
                                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 }
                                );

                            }
                            else {
                                obj.valuecolor = "lightcyan";
                            }
                        }
                        else {
                            obj.valuecolor = "lightcyan";
                        }
                        if (!isValidationFail) {
                            jsonData.push({
                                ServiceId: serviceId,
                                WeekId: weekDetailsData.weeks.week5ID,
                                MetricGroupName: '',
                                MetricId: obj.ml03Id,
                                KpiValue: (newval),
                                OldVal: oldval,
                                Comments: cmnts,
                                UOM: obj.uom,
                                UpdatedBy: LoginuserId
                            });
                            counter++;
                        }
                    }
                }
            }
            copy.push(obj);


        })

        setPerformancedata(copy);
        if (counter == 1 && !isValidationFail) {
            toastIdRef.current = toast.error(
                renderToastifyMsg("No changes observed to Update. If you wish to cancel the update operation please click on the Cancel icon.",
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 }
            );

        }

        if (!isValidationFail && jsonData.length > 0) {
            setloadingtrue();
            WeeklyAPI.AddPerformanceHistory(jsonData)
                .then((result: string) => {
                    const group = [];
                    if (result != "-1") {

                        toastIdRef.current = toast.success(
                            renderToastifyMsg(result + " Record of Performance Data Updated succesfully",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 }
                        );
                        GetPerformanceData(serviceId, weeklyStartDate);


                    } else {
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Data Can not be saved succesfully, please contact to Administrator",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 5000 }
                        );
                        setloadingfalse();
                    }
                });


        }
    }
    //============== function to filter the color logic data in performance ==================//
    const filterPerformanceData_Red = () => {
        if (IsperfermanceRed == false) {
            var jsonRedData = [];
            var index = 0;
            Performancedata.map((obj, key) => {
                if (obj.targetName == '') {
                    index = index + 1;
                    obj.index = index;
                }
                else {
                    obj.index = index;
                }
            });
            Performancedata.map((obj, key) => {
                if (obj.isMetricRed == true) {
                    Performancedata.map((indexedData, index) => {
                        if (indexedData.index == obj.index && indexedData.targetName == '') {
                            var flag = 0;
                            if (jsonRedData) {
                                jsonRedData.map((item, key) => {
                                    if (item.index == indexedData.index) {
                                        flag = 1;
                                    }
                                })

                            }
                            else {
                                jsonRedData.push(indexedData);
                            }
                            if (flag == 0) {
                                jsonRedData.push(indexedData);
                            }
                        }
                    })


                    jsonRedData.push(obj);
                }
            })

            setPerformancedata(jsonRedData);

        }
        else {

            setPerformancedata(MainPerformanceData);
        }
        setIsperfermanceRed(!IsperfermanceRed);
    }
   
    return (
        <div style={{ width: '100%' }}>
            <br />
           
          
            <div style={{ width: '100%' }}>
                <Row>
                    <Col lg='2'>
                        <Button className='DailydataModelbuttons' onClick={hideperformancePopupData} >Back to Weekly</Button>
                        </Col>
                    <Col lg='8' style={{ textAlign: 'center' }}>
                        <h4><b>  Service Performance Details </b></h4>
                    </Col>
                    <Col lg='2'>
                        <div style={{ float: 'right' }}>
                            {
                                (IsPerfPopupEdit)
                                    ?
                                    <button type="button" style={{ height: '45px', width: '80px' }} className="btn btn-primary btn-sm filter-button"
                                        title="Save the Edit Changes to Database" onClick={Edit_Service_Perf_Popup_ToHistory}>
                                        <table>
                                            <tr>
                                                <td>
                                                    <img className="imgSave" src={savebuttonicon} />
                                                </td>
                                                <td>
                                                    <h6> <b> Save </b></h6>
                                                </td>
                                            </tr>
                                        </table>
                                    </button>
                                    : <React.Fragment></React.Fragment>
                            }

                                &nbsp;&nbsp;
                            
                                    <button type="button" style={{ height: '45px', width: '80px' }} className="btn btn-primary btn-sm filter-button" title="Click to Filter Metrics that are Red for the week" onClick={filterPerformanceData_Red}>
                                        <div>
                                            <table>
                                                <tr>
                                                    <td>
                                                        <FontAwesomeIcon title='Click to edit the last week data' icon={faTimesCircle} style={{ color: 'orange' }} />
                                                    </td>
                                                    <td>
                                                        <h6> <b> Red </b> </h6>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </button>
                                    
                                
                        </div>
                    </Col>


                </Row>
            </div>
            <br />
            <Row>
                <Col lg='12'>
                    <Nav tabs style={{ borderBottom: '0' }}>
                        {isArrayWithLength(Performancedata) && Performancedata.map((pr: IWeeklyReport, index) => (
                            <React.Fragment key={index}>
                                {

                                    (Perfenvironment !== pr.environment)
                                        ?
                                        <Fragment>
                                            <NavItem style={{ color: 'rgba(0,0,0,0.54)' }}>
                                                <NavLink
                                                    className={classnames({ active: activeTab === pr.environment })}
                                                    onClick={() => {
                                                        toggle(pr.environment);
                                                    }}
                                                >
                                                    <React.Fragment>{pr.environment}  {setPerfenvironment(pr.environment)}</React.Fragment>
                                                </NavLink>
                                            </NavItem>
                                        </Fragment>
                                        : <React.Fragment></React.Fragment>

                                }

                            </React.Fragment>

                        ))}
                        {isArrayWithLength(Performancedata) && Performancedata.map((pr: IWeeklyReport, index) => (
                            <React.Fragment key={index}>
                                {
                                    (Perfgraphenvironment !== pr.environment)
                                        ?
                                        (CheckIsGraphExist(pr.environment))
                                        ?
                                        <Fragment>
                                            <NavItem style={{ color: 'rgba(0,0,0,0.54)' }}>
                                                <NavLink
                                                    className={classnames({ active: activeTab === (pr.environment + '~') })}
                                                    onClick={() => {
                                                        togglevolume((pr.environment + '~'));
                                                    }}
                                                >
                                                    {pr.environment} <FontAwesomeIcon title='Click to edit the last week data' icon={faSignal} />
                                                    {setPerfgraphenvironment(pr.environment)}
                                                </NavLink>

                                            </NavItem>
                                        </Fragment>
                                            : <React.Fragment></React.Fragment>
                                        : <React.Fragment></React.Fragment>

                                }

                            </React.Fragment>

                        ))}
                        {isArrayWithLength(Performancedata) && Performancedata.map((pr: IWeeklyReport, index) => (
                            <React.Fragment key={index}>
                                {
                                    (Perfvolgraphenvironment !== pr.environment)
                                        ?
                                        (CheckIsVolumeGraphExist(pr.environment))
                                            ?
                                            <Fragment>
                                                <NavItem style={{ color: 'rgba(0,0,0,0.54)' }}>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === (pr.environment + '!') })}
                                                        onClick={() => {
                                                            togglevolume1((pr.environment + '!'));
                                                        }}
                                                    >
                                                        {pr.environment}-volume <FontAwesomeIcon title='Click to edit the last week data' icon={faSignal} />
                                                        {setPerfvolgraphenvironment(pr.environment)}
                                                    </NavLink>

                                                </NavItem>
                                            </Fragment>
                                            : <React.Fragment></React.Fragment>
                                        : <React.Fragment></React.Fragment>

                                }

                            </React.Fragment>

                        ))}



                    </Nav>
                    <TabContent activeTab={activeTab}>
                        {isArrayWithLength(Performancedata) && Performancedata.map((pr: IWeeklyReport, index) => (
                            <React.Fragment key={index}>
                                {

                                    (Perftabcontenvironment !== pr.environment)
                                        ?
                                        <Fragment>
                                            <TabPane tabId={pr.environment}>
                                                {
                                                    (serviceId !== 13)
                                                        ?
                                                        <table className="table-striped" id="Performancedata">
                                                            <thead style={{ display: 'block' }}>
                                                                {!isNullOrUndefined(weekDetailsData)
                                                                    ? <React.Fragment>
                                                                        <tr>
                                                                            <th className="ppt-def perfth">Definition</th>
                                                                            {
                                                                                (serviceId === 3)
                                                                                    ?
                                                                                    <React.Fragment>
                                                                                        <th className="ppt-100 perfth">Uptime Target</th>
                                                                                        <th className="ppt-100 perfth">Service Uptime</th>
                                                                                        <th className="ppt-100 perfth">QoS Target</th>
                                                                                        <th className="ppt-100 perfth">QoS</th>
                                                                                    </React.Fragment>
                                                                                    : <React.Fragment></React.Fragment>
                                                                            }

                                                                            <th className="ppt-100 perfth">Latency Target</th>
                                                                            <th className="ppt-dim perfth">{weekDetailsData.weeksDetails.weekDet1}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week1}</span></th>
                                                                            <th className="ppt-dim perfth">{weekDetailsData.weeksDetails.weekDet2}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week2}</span></th>
                                                                            <th className="ppt-dim perfth">{weekDetailsData.weeksDetails.weekDet3}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week3}</span></th>
                                                                            <th className="ppt-dim perfth">{weekDetailsData.weeksDetails.weekDet4}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week4}</span></th>
                                                                            <th className={IsPerfPopupEdit ? 'ppt-week5-edit perfth' : 'ppt-week5 perfth'}>
                                                                                {weekDetailsData.weeksDetails.weekDet5}<br />
                                                                                <span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week5}&nbsp;</span>
                                                                                {
                                                                                    (IsPerfPopupEdit)
                                                                                        ? <span onClick={e => changeIsPerfPopupEdit(e, false)} style={{ cursor: 'pointer' }}>
                                                                                            <img title='Click to Cancel Edit' src={cancel} style={{ width: '20px', height: '20px' }} />

                                                                                        </span>
                                                                                        : <span onClick={e => changeIsPerfPopupEdit(e, true)} style={{ cursor: 'pointer' }}>
                                                                                            <FontAwesomeIcon title='Click to edit the last week data' className="Editiconsfontsize" icon={faPencilAlt} />
                                                                                        </span>
                                                                                }

                                                                            </th>
                                                                            {
                                                                                (IsPerfPopupEdit)
                                                                                    ? <th className="ppt-comment perfth">Comments</th>
                                                                                    : <React.Fragment></React.Fragment>
                                                                            }

                                                                        </tr>
                                                                    </React.Fragment>
                                                                    : <React.Fragment></React.Fragment>
                                                                    }
                                                                
                                                            </thead>
                                                            <tbody style={{ display: 'block', overflow: 'auto', height: '67vh' }}>
                                                                {isArrayWithLength(Performancedata) && Performancedata.map((prsub: IWeeklyReport, index) => (
                                                                    (prsub.environment == pr.environment)
                                                                        ?
                                                                        <tr>

                                                                            <td className="ppt-def Perfsubtd" style={{ textAlign: 'left', backgroundColor: prsub.color }}>{prsub.metricName}</td>
                                                                            {
                                                                                (serviceId === 3)
                                                                                    ?
                                                                                    <React.Fragment>
                                                                                        <td className="ppt-100 Perfsubtd" style={{ textAlign: 'center' }}>{prsub.uptimeTarget}</td>
                                                                                        <td className="ppt-100 textaligncenter Perfsubtd" style={ColorUptime(prsub.uptimeTarget, prsub.serviceUptime)}>{prsub.serviceUptime}</td>
                                                                                        <td className="ppt-100 Perfsubtd" style={{ textAlign: 'center' }}>{prsub.qoS_Target}</td>
                                                                                        <td className="ppt-100 textaligncenter Perfsubtd" style={ColorUptime(prsub.qoS_Target, prsub.qoS)}>{prsub.qoS}</td>
                                                                                    </React.Fragment>
                                                                                    : <React.Fragment></React.Fragment>
                                                                            }


                                                                            <td className="ppt-100 Perfsubtd" style={{ textAlign: 'center', backgroundColor: prsub.color }}>{prsub.targetName}</td>
                                                                            <td className="ppt-dim textalignright Perfsubtd" style={ColorWeek(prsub.week1, prsub.targetName, prsub.metricName)}>{prsub.week1}</td>
                                                                            <td className="ppt-dim textalignright Perfsubtd" style={ColorWeek(prsub.week2, prsub.targetName, prsub.metricName)}>{prsub.week2}</td>
                                                                            <td className="ppt-dim textalignright Perfsubtd" style={ColorWeek(prsub.week3, prsub.targetName, prsub.metricName)}>{prsub.week3}</td>
                                                                            <td className="ppt-dim textalignright Perfsubtd" style={ColorWeek(prsub.week4, prsub.targetName, prsub.metricName)}>{prsub.week4}</td>
                                                                            <td className={IsPerfPopupEdit ? 'ppt-week5-edit textalignright Perfsubtd' : 'ppt-week5 textalignright Perfsubtd'} style={ColorWeek(prsub.week5, prsub.targetName, prsub.metricName)}>
                                                                                {
                                                                                    (IsPerfPopupEdit)
                                                                                        ? <input className="form-control" style={{ borderColor: prsub.valuecolor.toString() }} type='text' value={prsub.week5Edit} onChange={e => Performancevaluechange(e, index)}  />
                                                                                        : <p>{prsub.week5}</p>
                                                                                }


                                                                            </td>
                                                                            {
                                                                                (IsPerfPopupEdit)
                                                                                    ? <td className='data ppt-comment Perfsubtd'><input type='text' className="form-control" style={{ borderColor: prsub.cmtcolor.toString() }}  onChange={e => PerformanceCommentschange(e, index)}  /></td>
                                                                                    : <React.Fragment></React.Fragment>
                                                                            }

                                                                        </tr>
                                                                        : <React.Fragment></React.Fragment>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                        :
                                                        <React.Fragment>
                                                            <React.Fragment>
                                                                <Nav tabs style={{ borderBottom: '0' }}>
                                                                    {isArrayWithLength(Performancedata) && Performancedata.map((prsub: IWeeklyReport, index) => (
                                                                        <React.Fragment>
                                                                            {
                                                                                (Perf13environment.indexOf(prsub.subEnv.toString()) === -1)
                                                                                    ?
                                                                                    <Fragment>
                                                                                        <NavItem style={{ color: 'rgba(0,0,0,0.54)' }}>
                                                                                            <NavLink
                                                                                                className={classnames({ active: activesubTab === prsub.subEnv.toString() })}
                                                                                                onClick={() => {
                                                                                                    togglesubenv(prsub.subEnv.toString());
                                                                                                }}
                                                                                            >
                                                                                                {prsub.subEnv === 80 ? 'CPIM Overall Performance' : (prsub.subEnv === 81 ? 'AAD Request By Type' : (prsub.subEnv === 82 ? 'Admin Resource ID Performance' : (prsub.subEnv === 83 ? 'Admin Performance' : 'Admin Controller Action')))}
                                                                                                {set13Perfenvironment(prsub.subEnv.toString())}
                                                                                            </NavLink>
                                                                                        </NavItem>
                                                                                    </Fragment>
                                                                                    : <React.Fragment></React.Fragment>
                                                                            }
                                                                        </React.Fragment>

                                                                    ))}
                                                                </Nav>
                                                                <TabContent activeTab={activesubTab}>
                                                                    {isArrayWithLength(Performancedata) && Performancedata.map((prsub: IWeeklyReport, index) => (
                                                                        <React.Fragment>
                                                                            {
                                                                                (Perftabcont13environment.indexOf(prsub.subEnv.toString()) === -1)
                                                                                    ?
                                                                                    <Fragment>
                                                                                        <TabPane tabId={prsub.subEnv.toString()}>
                                                                                            <table id="Performancedata" className="perf">
                                                                                                <thead style={{ display: 'block' }}>
                                                                                                    <tr>
                                                                                                        <th className="ppt-def perfth">Definition</th>
                                                                                                        {
                                                                                                            (serviceId === 3)
                                                                                                                ?
                                                                                                                <React.Fragment>
                                                                                                                    <th className="ppt-100 perfth">Uptime Target</th>
                                                                                                                    <th className="ppt-100 perfth">Service Uptime</th>
                                                                                                                    <th className="ppt-100 perfth">QoS Target</th>
                                                                                                                    <th className="ppt-100 perfth">QoS</th>
                                                                                                                </React.Fragment>
                                                                                                                : <React.Fragment></React.Fragment>
                                                                                                        }

                                                                                                        <th className="ppt-100 perfth">Latency Target</th>
                                                                                                        <th className="ppt-dim perfth">{weekDetailsData.weeksDetails.weekDet1}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week1}</span></th>
                                                                                                        <th className="ppt-dim perfth">{weekDetailsData.weeksDetails.weekDet2}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week2}</span></th>
                                                                                                        <th className="ppt-dim perfth">{weekDetailsData.weeksDetails.weekDet3}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week3}</span></th>
                                                                                                        <th className="ppt-dim perfth">{weekDetailsData.weeksDetails.weekDet4}<br /><span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week4}</span></th>
                                                                                                        <th className={IsPerfPopupEdit ? 'ppt-week5-edit perfth' : 'ppt-week5 perfth'}>
                                                                                                            {weekDetailsData.weeksDetails.weekDet5}<br />
                                                                                                            <span style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week5}&nbsp;</span>
                                                                                                            {
                                                                                                                (IsPerfPopupEdit)
                                                                                                                    ? <span onClick={e => changeIsPerfPopupEdit(e, false)} style={{ cursor: 'pointer' }}>
                                                                                                                        <img title='Click to Cancel Edit' src={cancel} style={{ width: '20px', height: '20px' }} />

                                                                                                                    </span>
                                                                                                                    : <span onClick={e => changeIsPerfPopupEdit(e, true)} style={{ cursor: 'pointer' }}>
                                                                                                                        <FontAwesomeIcon title='Click to edit the last week data' className="Editiconsfontsize" icon={faPencilAlt} />
                                                                                                                    </span>
                                                                                                            }

                                                                                                        </th>
                                                                                                        {
                                                                                                            (IsPerfPopupEdit)
                                                                                                                ? <th className="ppt-comment perfth">Comments</th>
                                                                                                                : <React.Fragment></React.Fragment>
                                                                                                        }

                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody style={{ display: 'block', overflow: 'auto', height: '67vh' }}>
                                                                                                    {isArrayWithLength(Performancedata) && Performancedata.map((prsub1: IWeeklyReport, index) => (
                                                                                                        (prsub1.environment == pr.environment && prsub.subEnv == prsub1.subEnv)
                                                                                                            ?
                                                                                                            <tr>

                                                                                                                <td className="ppt-def Perfsubtd" style={{ textAlign: 'left', backgroundColor: prsub1.color }}>{prsub1.metricName}</td>
                                                                                                                {
                                                                                                                    (serviceId === 3)
                                                                                                                        ?
                                                                                                                        <React.Fragment>
                                                                                                                            <td className="ppt-100 Perfsubtd" style={{ textAlign: 'center' }}>{prsub1.uptimeTarget}</td>
                                                                                                                            <td className="ppt-100 textaligncenter Perfsubtd" style={ColorUptime(prsub1.uptimeTarget, prsub1.serviceUptime)}>{prsub1.serviceUptime}</td>
                                                                                                                            <td className="ppt-100 Perfsubtd" style={{ textAlign: 'center' }}>{prsub1.qoS_Target}</td>
                                                                                                                            <td className="ppt-100 textaligncenter Perfsubtd" style={ColorUptime(prsub1.qoS_Target, prsub1.qoS)}>{prsub1.qoS}</td>
                                                                                                                        </React.Fragment>
                                                                                                                        : <React.Fragment></React.Fragment>
                                                                                                                }


                                                                                                                <td className="ppt-100 Perfsubtd" style={{ textAlign: 'center', backgroundColor: prsub1.color }}>{prsub1.targetName}</td>
                                                                                                                <td className="ppt-dim textalignright Perfsubtd" style={ColorWeek(prsub1.week1, prsub1.targetName, prsub1.metricName)}>{prsub1.week1}</td>
                                                                                                                <td className="ppt-dim textalignright Perfsubtd" style={ColorWeek(prsub1.week2, prsub1.targetName, prsub1.metricName)}>{prsub1.week2}</td>
                                                                                                                <td className="ppt-dim textalignright Perfsubtd" style={ColorWeek(prsub1.week3, prsub1.targetName, prsub1.metricName)}>{prsub1.week3}</td>
                                                                                                                <td className="ppt-dim textalignright Perfsubtd" style={ColorWeek(prsub1.week4, prsub1.targetName, prsub1.metricName)}>{prsub1.week4}</td>
                                                                                                                <td className={IsPerfPopupEdit ? 'ppt-week5-edit textalignright Perfsubtd' : 'ppt-week5 textalignright Perfsubtd'} style={ColorWeek(prsub1.week5, prsub1.targetName, prsub1.metricName)}>
                                                                                                                    {
                                                                                                                        (IsPerfPopupEdit)
                                                                                                                            ? <input className="form-control" style={{ borderColor: prsub.valuecolor.toString() }} type='text' value={prsub1.week5Edit} onChange={e => Performancevaluechange(e, index)}  />
                                                                                                                            : <p>{prsub1.week5}</p>
                                                                                                                    }


                                                                                                                </td>
                                                                                                                {
                                                                                                                    (IsPerfPopupEdit)
                                                                                                                        ? <td className='data ppt-comment Perfsubtd'><input className="form-control" style={{ borderColor: prsub.cmtcolor.toString() }} type='text' value={prsub1.comments} onChange={e => PerformanceCommentschange(e, index)} /></td>
                                                                                                                        : <React.Fragment></React.Fragment>
                                                                                                                }

                                                                                                            </tr>
                                                                                                            : <React.Fragment></React.Fragment>
                                                                                                    ))}
                                                                                                </tbody>
                                                                                            </table>
                                                                                            {settabcontPerf13environment(prsub.subEnv.toString())}
                                                                                        </TabPane>
                                                                                    </Fragment>
                                                                                    : <React.Fragment></React.Fragment>

                                                                            }
                                                                        </React.Fragment>

                                                                    ))}
                                                                </TabContent>
                                                            </React.Fragment>
                                                        </React.Fragment>
                                                }

                                                {settabcontPerfenvironment(pr.environment)}
                                            </TabPane>
                                        </Fragment>


                                        : <React.Fragment></React.Fragment>

                                }

                            </React.Fragment>

                        ))}
                        {isArrayWithLength(Performancedata) && Performancedata.map((pr: IWeeklyReport, index) => (
                            <React.Fragment key={index}>
                                {

                                    (Perftabcontgraphenvironment !== pr.environment)
                                        ?
                                        <Fragment>
                                            <TabPane tabId={pr.environment + '~'}>
                                                <div>
                                                    {pr.environment}
                                                    <HighchartsReact highcharts={Highcharts} options={GraphOptions} />
                                                </div>
                                                {settabcontgraphPerfenvironment(pr.environment)}

                                            </TabPane>
                                        </Fragment>
                                        : <React.Fragment></React.Fragment>

                                }

                            </React.Fragment>

                        ))}
                        {isArrayWithLength(Performancedata) && Performancedata.map((pr: IWeeklyReport, index) => (
                            <React.Fragment key={index}>
                                {

                                    (Perftabvolcontgraphenvironment !== pr.environment)
                                        ?
                                        <Fragment>
                                            <TabPane tabId={pr.environment + '!'}>
                                                <div>
                                                    {pr.environment}
                                                    <HighchartsReact highcharts={Highcharts} options={GraphOptions} />
                                                </div>
                                                {settabvolcontgraphPerfenvironment(pr.environment)}

                                            </TabPane>
                                        </Fragment>
                                        : <React.Fragment></React.Fragment>

                                }

                            </React.Fragment>

                        ))}
                    </TabContent>
                </Col>
            </Row>
            {
                (!isArrayWithLength(Performancedata))
                ?
            <Row>
                <Col lg="12">
                            <div style={{ color: 'Red', fontWeight: 'bold', textAlign: 'center' }}> <br />                                
                                No {(IsperfermanceRed) ? 'Red' : 'Performance'}  data available for the {isArrayWithLength(CurrentService) ? CurrentService[0].serviceName : ''} Service
                            </div>
                </Col>
                    </Row>
                    : <React.Fragment></React.Fragment>
            }
           
        </div>
    );
});

WeeklyPerformanceReport.displayName = 'WeeklyPerformanceReport';

export default WeeklyPerformanceReport;