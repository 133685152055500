import React, { useEffect, useState } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { Spinner } from '../../components';
import { IApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import { actionCreators, reducer } from '../../store/MSERPresentationView';
import { MSERPresentationViewTable } from '../MSERHeatmapPresentationview/child-components';
import { IPropService } from '../../store/MSERPresentationView';
import moment from 'moment';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../utils';
import LoadingOverlay from 'react-loading-overlay';
import { Accordion } from 'react-bootstrap'
import {
    IComments, IDailyData, HmMetric,

} from '../../store/service-health';
import { Row, Col, CardTitle, Card} from 'reactstrap';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignal, faFire, faAsterisk, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { NewFunctionalityBanner } from '../../components';

type MSERPresentationViewProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly history: History }
    & typeof useParams;

const MSERPresentationView: React.FC<MSERPresentationViewProps> = ({
    isLoading,
    isAuthenticated,
    GetMserPresentationHeatViewList,
    currentMSERPresentationVIEW,
    currentindex,
    lastindex,
    startDate,
    endDate,
    handleStartDateChange,
    handleEndDateChange,    
    addUserVisit,
    requestPresentationDailyData,
    HeatmapPresentationData,
    PresentationuniqueDateHeaders,
    uniqueFixedHeaderColumns,
    datacentersList,
    additionalInfoData,
    Presentationviewdetailsdata,
    PresentationSelectedView,
    Presentationcomments,
    PresentationcolorScheme,
    IsdataLoaded,
    volumeMeasure,
    failurevolumeMeasure,
    showMSERComments,
    checkedFilterYellowDailyData,
    checkedFilterRedDailyData,
    onFilterYellowDailyData,
    onFilterRedDailyData,
    toggleMSERCommentsView,
    getPreviousService,
    getNextService,
    IsPresentationviewsloaded,
    showdashboradpopup,
    GetHeatMapServicesOnboardedData,
    HeatMapOnboarded,
    HeatMapNotOnboarded,
    handledashboardClose,
    MSEROnboarded,
    MSERNotOnboarded,
    GetMSERServicesOnboardedData,
    dcMaxLength
}) => {
    const [onboardedtoggle, setonboardedtoggle] = useState(false);
    const [notonboardedtoggle, setnotonboardedtoggle] = useState(false);
    useEffect(() => {
        if (isAuthenticated)
            addUserVisit(0, 'MSERHeatmapPresentationview.tsx_v2');
    }, [isAuthenticated]);
    useEffect(() => {
        GetMserPresentationHeatViewList();
    }, []);
   
    useEffect(() => {
        if (currentMSERPresentationVIEW !== null) {
            const momentStDate = moment(startDate, 'MM/DD/YYYY');
            const sStartDate = momentStDate.format('MM/DD/YYYY');

            const momentEnDate = moment(endDate, 'MM/DD/YYYY');
            const sEndDate = momentEnDate.format('MM/DD/YYYY');
            requestPresentationDailyData(currentMSERPresentationVIEW.serviceId, sStartDate, sEndDate, currentMSERPresentationVIEW.viewId);
        }
    }, [currentMSERPresentationVIEW]);
    //=========== filter heatmap MSER Data=========================//
    const filterHeatmapMSERData = () => {
        const momentStDate = moment(startDate, 'MM/DD/YYYY');
        const sStartDate = momentStDate.format('MM/DD/YYYY');

        const momentEnDate = moment(endDate, 'MM/DD/YYYY');
        const sEndDate = momentEnDate.format('MM/DD/YYYY');
        requestPresentationDailyData(currentMSERPresentationVIEW.serviceId, sStartDate, sEndDate, currentMSERPresentationVIEW.viewId);
    }
    const CommentSummary = (metric, key) => {

        if (metric !== undefined) {
            const commentId = metric.commentId;
            var result: any;
            if (commentId > 0) {
                const filterByKey = [];
                if (isArrayWithLength(HeatmapPresentationData)) {
                    HeatmapPresentationData.map((DD: IDailyData, val) => {
                        if (DD.key === key) {
                            filterByKey.push(DD);

                        }
                    })
                    // if (filterByKey !== undefined && filterByKey !== null) {
                    if (isArrayWithLength(filterByKey)) {
                        filterByKey[0].metricList.map((mt: HmMetric, val) => {
                            if (moment(mt.dateKey, 'YYYY-MM-DD').isSame(moment(metric.dateKey).add(-1, 'days'), 'day') && mt.commentId === commentId) {
                                return true;
                            }

                        })
                    }
                    Presentationcomments.map((com: IComments, val) => {
                        if (com.commentId === commentId) {
                            result = val + 1;

                        }
                    })
                    return result;
                }
            }
        }
    }
    const openPresentationView = () => {
        GetHeatMapServicesOnboardedData();
        GetMSERServicesOnboardedData();
    }
    //=================== function to close the history Popup =================//
   
    return (
        <div>
            <NewFunctionalityBanner />
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        <MSERPresentationViewTable
                            currentMSERPresentationVIEW={currentMSERPresentationVIEW}
                            currentindex={currentindex}
                            lastindex={lastindex}
                            startDate={startDate}
                            endDate={endDate}
                            onStartDateChange={handleStartDateChange}
                            onEndDateChange={handleEndDateChange}
                            HeatmapPresentationData={HeatmapPresentationData}
                            PresentationuniqueDateHeaders={PresentationuniqueDateHeaders}
                            uniqueFixedHeaderColumns={uniqueFixedHeaderColumns}
                            datacentersList={datacentersList}
                            additionalInfoData={additionalInfoData}
                            Presentationviewdetailsdata={Presentationviewdetailsdata}
                            PresentationSelectedView={PresentationSelectedView}
                            Presentationcomments={Presentationcomments}
                            PresentationcolorScheme={PresentationcolorScheme}
                            IsdataLoaded={IsdataLoaded}
                            volumeMeasure={volumeMeasure}
                            failurevolumeMeasure={failurevolumeMeasure}
                            showMSERComments={showMSERComments}
                            CommentSummary={CommentSummary}
                            checkedFilterYellowDailyData={checkedFilterYellowDailyData}
                            checkedFilterRedDailyData={checkedFilterRedDailyData}
                            filterHeatmapMSERData={filterHeatmapMSERData}
                            onFilterYellowDailyData={onFilterYellowDailyData}
                            onFilterRedDailyData={onFilterRedDailyData}
                            toggleMSERCommentsView={toggleMSERCommentsView}
                            getPreviousService={getPreviousService}
                            getNextService={getNextService}
                            IsPresentationviewsloaded={IsPresentationviewsloaded}
                            openPresentationView={openPresentationView}
                            dcMaxLength={dcMaxLength}
                        />

                    </div>
                </section>
            </LoadingOverlay>      
            <Modal show={showdashboradpopup} onHide={handledashboardClose} size='lg' backdrop='static'>

                    <Modal.Header closeButton>
                            <div style={{ width: '100%' }}>
                                <Row>
                                    <Col lg='12' style={{ textAlign: 'center' }}>
                                    <h4><b> Dashboard View </b></h4>
                                    </Col>

                                </Row>
                            </div>

                        </Modal.Header>
                <Modal.Body>
                    <div style={{ minHeight:'400px' }}>
                        <table style={{ width: '100%' }}>
                        <tr>
                            <td style={{ width: '50%' }}>
                                <Accordion defaultActiveKey="1" className="onboardAccordian">
                                        <table className="MSERHeaddings">
                                            <tr>
                                                <td>
                                                    <span > Services Not Onboarded to HeatMap
                                                    {isArrayWithLength(HeatMapOnboarded) ? <span>&nbsp;-&nbsp;{HeatMapOnboarded.length}</span> : <React.Fragment></React.Fragment>}
                                                    </span>
                                                </td>
                                                <td style={{ width: '10%' }}>
                                                    <Accordion.Toggle as={Button} eventKey="1" className="onboardedtoggle" onClick={e => setonboardedtoggle(!onboardedtoggle)}>
                                                        {(onboardedtoggle) ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />
                                                        }
                                                    </Accordion.Toggle>
                                                </td>
                                                </tr>
                                                </table>                                           
                                 
                                    <Accordion.Collapse eventKey="1">

                                        {
                                            isArrayWithLength(HeatMapOnboarded)
                                                ?
                                                <React.Fragment>
                                                    <div className="onboarddiv">
                                                        {HeatMapOnboarded.map((dhT: IPropService, key2) => (
                                                            <React.Fragment key={key2}>
                                                                <div className="onboarddata">
                                                                    <Row className="onboardtext">
                                                                        <Col lg="12">{dhT.serviceName}</Col>
                                                                    </Row>
                                                                </div>
                                                            </React.Fragment>

                                                        ))}
                                                    </div>

                                                </React.Fragment>
                                                : <React.Fragment></React.Fragment>
                                        }
                                    </Accordion.Collapse>
                                </Accordion>
                            </td>
                            <td style={{ width: '50%' }}>
                                <Accordion defaultActiveKey="2" className="onboardAccordian">

                                        <table className="MSERHeaddings">
                                            <tr style={{ backgroundColor: '#c7e3ff !important', fontSize: '12px !important' }}>
                                               
                                                <td style={{ width: '90%' }}>
                                                    <span> Services Not Onboarded to MSER
                                                      {isArrayWithLength(MSERNotOnboarded) ? <span>&nbsp;-&nbsp;{MSERNotOnboarded.length}</span> : <React.Fragment></React.Fragment>}
                                                    </span>
                                                </td>
                                                <td style={{ width: '10%' }}>
                                                    <Accordion.Toggle as={Button} eventKey="2" className="onboardedtoggle" onClick={e => setonboardedtoggle(!onboardedtoggle)}>
                                                        {(onboardedtoggle) ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />
                                                        }
                                                    </Accordion.Toggle>
                                                        </td>
                                                        </tr>
                                                </table>                                            
            
                                    <Accordion.Collapse eventKey="2">

                                        {
                                            isArrayWithLength(MSERNotOnboarded)
                                                ?
                                                <React.Fragment>
                                                    <div className="onboarddiv">
                                                        {MSERNotOnboarded.map((dhT: IPropService, key2) => (
                                                            <React.Fragment key={key2}>
                                                                <div className="onboarddata">
                                                                    <Row className="onboardtext">
                                                                        <Col lg="12">{dhT.serviceName}</Col>
                                                                    </Row>
                                                                </div>
                                                            </React.Fragment>

                                                        ))}
                                                    </div>

                                                </React.Fragment>
                                                : <React.Fragment></React.Fragment>
                                        }
                                    </Accordion.Collapse>
                                </Accordion>
                            </td>
                       </tr>
                        </table>
                        </div>
                        </Modal.Body>
                    </Modal>
        </div>

    );
};
const mapStateToProps = (state: IApplicationState) => ({
    ...state.MSERPresentationView,
    isAuthenticated: state.authUser.isAuthenticated

})
export default connect(mapStateToProps, actionCreators)(MSERPresentationView);
