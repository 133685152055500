import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IMSERHeatmapPreviewState } from './types';

const initialState = Object.freeze<IMSERHeatmapPreviewState>({   
    isLoading: false,
    MSERHeatmapServicesData: [],
    MSERHeatmapViews: [],
    currentService: '',
    currentView: '',
    currentindex: 0,
    lastindex: 0,
    startDate: new Date(),
    endDate: new Date(),
    currentMSERVIEW: null,
    checkedFilterYellowDailyData: false,
    checkedFilterRedDailyData: false,
    showMSERComments: false,
    checkPresentationView: false,
    chkpresentationviewcheck: false,
    HeatmapdailyData: [],
    uniqueDateHeaders: [],
    uniqueFixedHeaderColumns: [],
    datacentersList: [],
    additionalInfoData: [],
    viewdetailsdata: [],
    SelectedView: [],
    comments: [],
    colorScheme: [],
    IsdataLoaded: false,
    volumeMeasure: "M",
    failurevolumeMeasure: "M",
    Isviewsloaded: false,
    dcMaxLength: 0

});

export const reducer = (
    state: IMSERHeatmapPreviewState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) { 
        case ActionType.MSER_HEATMAPDATA_FETCH:
            return {
                ...state,
                isLoading: true,
                IsdataLoaded: false,
                HeatmapdailyData: [],
                colorScheme:[]
            };
        case ActionType.SET_LOADING:
            return {
                ...state,
                isLoading: true,
                Isviewsloaded:false,
                HeatmapdailyData: [],
                colorScheme: []
              
            };
        case ActionType.MSER_HEATMAPDATA_RECEIVE:
            let { dailyData, uniqueFixedHeaderColumns, uniqueDateHeaders, SelectedView, comments, colorScheme, datacentersList, additionalInfoData } = action;
            return {
                ...state,
                isLoading: false,
                IsdataLoaded: true,
                HeatmapdailyData: dailyData,
                uniqueFixedHeaderColumns: uniqueFixedHeaderColumns,
                uniqueDateHeaders: uniqueDateHeaders,
                SelectedView: SelectedView,
                comments: comments,
                colorScheme: colorScheme,
                datacentersList: datacentersList,
                additionalInfoData: additionalInfoData,
                volumeMeasure: action.volumeMeasure,
                failurevolumeMeasure: action.failurevolumeMeasure,
                dcMaxLength: action.longestDC
            };
        case ActionType.GET_MSER_HEATMAP_SERVICES_REQUEST:
            return {
                ...state,
                isLoading: true,              
                MSERHeatmapServicesData: []
            };
        case ActionType.GET_MSER_HEATMAP_SERVICES_SUCCESS:
            return {
                ...state,               
                MSERHeatmapServicesData: action.MSERHeatmapServicesData,
               
            };
        case ActionType.GET_MSER_HEATMAP_SERVICES_FAIL:
            return {
                ...state,             
                MSERHeatmapServicesData: []
            };      
        case ActionType.GET_MSER_HEATMAP_VIEWS_SUCCESS:
            return {
                ...state,               
                MSERHeatmapViews: action.MSERHeatmapViews,
                currentService: action.currentService,
                currentView: action.currentView,
                currentindex: action.currentindex,
                lastindex: action.lastindex,
                currentMSERVIEW: action.currentMSERVIEW,
                startDate: action.fromDate,
                endDate: action.toDate, 
                isLoading: (action.MSERHeatmapViews.length > 0) ? true : false,
                Isviewsloaded:true,
                chkpresentationviewcheck: action.chkpresentationviewcheck
            };
        case ActionType.GET_MSER_HEATMAP_VIEWS_FAIL:
            return {
                ...state,
                isLoading: false,
                Isviewsloaded: true,
                startDate: action.fromDate,
                endDate: action.toDate,
                MSERHeatmapViews: []
            };
        case ActionType.START_DATE_CHANGE:
            return {
                ...state,
                startDate: action.startDate
            };
        case ActionType.END_DATE_CHANGE:
            return {
                ...state,
                endDate: action.endDate
            };
        case ActionType.CHECK_PRESENTATIONVIEW_CHECK:
            return {
                ...state,
                chkpresentationviewcheck: action.chkpresentationviewcheck
            };
        case ActionType.DAILY_FILTER_YELLOW:
            return {
                ...state,
                HeatmapdailyData: action.dailyData,               
                checkedFilterYellowDailyData: !!action.checked
            };
        case ActionType.DAILY_FILTER_RED:
            return {
                ...state,
                HeatmapdailyData: action.dailyData,
                checkedFilterRedDailyData: !!action.checked
            };
        case ActionType.SET_MSERCOMMENTS:
            return {
                ...state,
                showMSERComments: action.showMSERComments
            };
        case ActionType.LOAD_VIEWS:
            return {
                ...state,
                MSERHeatmapViews: action.MSERHeatmapViews
            };
        case ActionType.RESET_STATE:
            return initialState;

        default:
            return state;
    }
};
