import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IOnCallChecklistState } from './types';
import moment from 'moment';

const initialState = Object.freeze<IOnCallChecklistState>({
    isLoading: false,
    serviceId: 0,
    checklistID: 0,
    searchText: '',
    allServicesdata: [],
    checklists: [],
    mainIncidentIdFilter: 0,
    mainIncidentIdFilterList: [],
    submittedbylist: [],
    sortColumn: '',
    sortOrder: false,
    oceList: [],
    fromDate: null,
    toDate: null,
    createdby: '',
    isIncidents: false,
    isPIR: false,
    incidentIds: '',
    incidents: [],
    allPIRData: [],
    iFrameKustoObj: {},
    iFrameDataUrl: '',
    meetingTitle: '',
    meetingId: -1,
    icmMainIncident: '',
    filteredIncidents: [],
    incidentFilter: {},
    incidentFilterSeverityOptions: [],
    incidentFilterSeverityModel: [],
    clMeetingitems: [],
    actionItemTitle: '',
    qualitySpends: '',
    actionItemDescription: '',
    participants: '',
    meetingStatus: '',
    owner: '',
    allowDateChange: true,
    isVisibleContent: false,
    currDate: new Date,
    minDate: new Date,
    q_Index: 0,
    Throttling: false,
    ests: false,
    filterparams: {},
    questionincidents: [],
    actionitems: [],
    questionsList: [],
    question: [],
    actionDataTobeSaved: false,
    urlParam: '',
    headerText: '',
    allServices: [],
    url: '',
    showExpand: null,
    isEditable: false,
    isreadonly: false,
    filterparameters: {},
    questionURL: '',
    URLOCE: '',
    LoginuserId: '',
    isExpanded: null,
    isnotExpanded: null,
    incidentFilterFromDate: moment().subtract(1, 'day').toDate(),
    incidentFilterToDate: new Date(),
    service: null,
    ChecklistQuestions: {},
    filename: ''
});

export const reducer = (
    state: IOnCallChecklistState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true

            };
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false

            };
        case ActionType.GET_CHECKLISTDATA_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.LOGIN_USERNAME_OCE: 
            return {
                ...state,
                LoginuserId: action.LoginuserId
            };
        case ActionType.GET_MEETINGMAININCIDENTIDS_SUCCESS:
            return {
                ...state,
                mainIncidentIdFilterList: action.mainIncidentIdFilterList,
                mainIncidentIdFilter: action.mainIncidentIdFilter,
                isLoading: (action.count >= 4) ? false : state.isLoading
            };
        case ActionType.GET_MEETINGMAININCIDENTIDS_FAIL:
            return {
                ...state,
                mainIncidentIdFilterList: [],
                mainIncidentIdFilter: null,
                isLoading: (action.count >= 4) ? false : state.isLoading
            };
        case ActionType.GET_CHECKLISTSERVICES_SUCCESS: 
            return {
                ...state,
                isLoading: (action.count >= 4) ? false : state.isLoading,
                allServicesdata: action.allServicesdata,
                selectedService: action.selectedService,
                serviceId: action.serviceId
            };
        case ActionType.GET_CHECKLISTSERVICES_SUCCESS_OCEVIEW:
            return {
                ...state,
                allServices: action.allServices,
                selectedService: action.selectedService,
                serviceId: action.serviceId
            };
        case ActionType.GET_CHECKLISTSERVICES_FAIL: 
            return {
                ...state,
                isLoading: (action.count >= 4) ? false : state.isLoading,
                allServicesdata: [],
                selectedService: null
            };
        case ActionType.FILE_UPLOAD_FILENAME_SUCCESS:
            return {
                ...state,
                filename: action.filename
            };
        case ActionType.GET_CHECKLISTSERVICES_OCEVIEW_FAIL:
            return {
                ...state,
                isLoading: false,
                allServices: [],
                selectedService: null
            };
        case ActionType.GET_SUBMITTEDLIST_SUCCESS:
            return {
                ...state,
                isLoading: (action.count >= 4) ? false : state.isLoading,
                submittedbylist: action.submittedbylist,
                createdby: ''
            };
        case ActionType.GET_SUBMITTEDLIST_FAIL: 
            return {
                ...state,
                isLoading: (action.count >= 4) ? false : state.isLoading,
                submittedbylist: []
            };
        case ActionType.GET_CHECKLIST_SUCCESS: 
            return {
                ...state,
                isLoading: (action.count >= 4) ? false : state.isLoading,
                checklists: action.checklists,
                serviceId: action.serviceId,
                selectedchecklist: action.selectedchecklist,
                checklistID: action.checklistID
            };
        case ActionType.GET_CHECKLIST_SUCCESS_OCEVIEW:
            return {
                ...state,
                checklists: action.checklists,
                question: action.question,
                isVisibleContent: action.isVisibleContent,
                selectedchecklist: action.selectedchecklist,
                checklistID: action.checklistID,
                serviceId: action.serviceId
            };    
        case ActionType.GET_CHECKLIST_FAIL: 
            return {
                ...state,
                isLoading: (action.count >= 4) ? false : state.isLoading,
                checklists: [],
                selectedchecklist: null,
                checklistID: 0
            };
        case ActionType.CHECKLISTDASHBOARD_CLEARFILTER:
            return {
                ...state,
                isLoading: false,
                serviceId : 0,
                checklistID : 0,
                fromDate : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7),
                toDate : new Date(),
                createdby : '',
                mainIncidentIdFilter : 0,
                searchText : '',
                oceList:  [],
                sortColumn : '',
                sortOrder : false
            };
        case ActionType.GET_OCELIST_SUCCESS:
            return {
                ...state,
                oceList: action.oceList,
                sortColumn: action.sortColumn,
                sortOrder: action.sortOrder,
                isLoading: false
            };
        case ActionType.GET_OCELIST_FAIL:
            return {
                ...state,
                oceList: []
            };
        case ActionType.SORT_OCELIST:
            return {
                ...state,
                sortColumn: action.sortColumn,
                sortOrder: action.sortOrder,
                oceList: action.oceList
            };
        case ActionType.OCELIST_FROMDATE_CHANGE:
            return {
                ...state,
                fromDate: action.fromDate
            };
        case ActionType.OCELIST_TODATE_CHANGE:
            return {
                ...state,
                toDate: action.toDate
            };
        case ActionType.INCIDENTFILTER_FROMDATE_CHANGE:
            return {
                ...state,
                incidentFilterFromDate: action.incidentFilterFromDate
            };    
        case ActionType.INCIDENTFILTER_TODATE_CHANGE:
            return {
                ...state,
                incidentFilterToDate: action.incidentFilterToDate
            };    
        case ActionType.SET_CHECKLISTDASHBOARDDATE:
            return {
                ...state,
                toDate: action.toDate,
                fromDate: action.fromDate
            };    
        case ActionType.CHECKLIST_DROPDOWN_CHANGE:
            return {
                ...state,
                checklistID: action.checklistID
            };
        case ActionType.INCIDENT_DROPDOWN_CHANGE:
            return {
                ...state,
                mainIncidentIdFilter: action.mainIncidentIdFilter
            };
        case ActionType.SUBMITTEDBY_DROPDOWN_CHANGE:
            return {
                ...state,
                createdby: action.createdby
            };    
        case ActionType.OCEINCIDENTSPOPUP_CLEARFILTER:
            return {
                ...state,
                filteredIncidents: action.filteredIncidents,
                incidentFilterFromDate: action.incidentFilterFromDate,
                incidentFilterToDate: action.incidentFilterToDate
            };        
        case ActionType.OCELIST_SEARCHTEXT_CHANGE:
            return {
                ...state,
                searchText: action.searchText
            };
        case ActionType.OCELIST_MEETINGTITLE_CHANGE:
            return {
                ...state,
                meetingTitle: action.meetingTitle
            };
        case ActionType.GET_CHECKLISTSERVICES_SUCCESS_READONLY:
            return {
                ...state,
                allServicesdata: action.allServicesdata,
                selectedService: action.selectedService
            };
        case ActionType.OCELIST_ICMINCIDENT_CHANGE:
            return {
                ...state,
                icmMainIncident: action.icmMainIncident
            };
        case ActionType.OCEVIEW_SHOWINCIDENTS:
            return {
                ...state,
                filteredIncidents: action.filteredIncidents,
                incidentFilterFromDate: action.incidentFilterFromDate,
                incidentFilterToDate: action.incidentFilterToDate,
                incidentFilter: action.incidentFilter,
                incidentFilterSeverityModel: action.incidentFilterSeverityModel,
                question: action.question,
                questionURL: action.questionURL,
                q_Index: action.q_Index,
                isLoading: false
            };
        case ActionType.CHECKCHANGE_OCEVIEW_QUESTIONS:
            return {
                ...state,
                OCEquestion: action.question,
                questionURL: action.questionURL,
                q_Index: action.q_Index
            };
        case ActionType.OCEVIEW_BACKWARD_FORWARD_QUESTION:
            return {
                ...state,
                incidentIDs: action.incidentIDs,
                incidents: action.incidents
            };
        case ActionType.GET_CHECKLISTINCIDENTS_OCE_REQUEST:
            return {
                ...state,
                filteredIncidents: [],
                isLoading: true
            };    
        case ActionType.GET_CHECKLISTINCIDENTS_OCE_FAIL:
            return {
                ...state,
                filteredIncidents: action.filteredIncidents,
                isLoading: false
            };    
        case ActionType.CHECKLIST_INCIDENTS_OCE:
            return {
                ...state,
                filteredIncidents: action.filteredIncidents,
                isIncidents: action.isIncidents,
                service: action.service,
                isLoading: false
            };
        case ActionType.UPDATE_ACTIONITEMS_OCEVIEW:
            return {
                ...state,
                clMeetingitems: action.clMeetingitems
            };
        case ActionType.UPDATE_ACTIONITEMS_OCEVIEW_QUESTIONAAIRE:
            return {
                ...state,
                clMeetingitems: action.clMeetingitems,
                question: action.question
            };  
        case ActionType.CONTROLTYPE_VALUES_CHANGED:
            return {
                ...state,
                question: action.question,
                q_Index: action.q_Index,
                questionURL: action.questionURL
            };      
        case ActionType.SAVE_INCIDENTS_OCEVIEW:
            return {
                ...state,
                OCEquestion: action.question,
                filteredIncidents: action.filteredIncidents
            };
        case ActionType.UPDATE_QUESTION_ACTIONITEM:
            return {
                ...state,
                question: action.question
            };    
        case ActionType.GET_FILTERED_INCIDENTS_OCEVIEW:
            return {
                ...state,
                filteredIncidents: action.filteredIncidents,
                isLoading: false
            };
        case ActionType.SET_SEVERITY_INCIDENTSPOPUP_OCEVIEW:
            return {
                ...state,
                isLoading: true,
                incidentFilterSeverityModel: action.incidentFilterSeverityModel
            };    
        case ActionType.GET_MEETINGITEMS_SUCCESS_OCEVIEW:
            return {
                ...state,
                clMeetingitems: action.clMeetingitems
            };
        case ActionType.ADD_ACTIONITEM_OCEVIEW:
            return {
                ...state,
                actionDataTobeSaved: action.actionDataTobeSaved
            };
        case ActionType.GET_IFRAME_DATAURL:
            return {
                ...state,
                iFrameKustoObj: action.iFrameKustoObj,
                iFrameDataUrl: action.iFrameDataUrl
            };
        case ActionType.OCEVIEW_EXPAND_COLLAPSE_QUESTION:
            return {
                ...state,
                showExpand: action.showExpand,
                question: action.question
            };
        case ActionType.ONQUESTIONS_SAVE_OCEVIEW_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.ONQUESTIONS_SAVE_OCEVIEW_SUCCESS:
            return {
                ...state,
                question: action.question,
                actionitems: action.actionitems,
                ChecklistQuestions: action.ChecklistQuestions,
                meetingTitle: action.meetingTitle,
                icmMainIncident: action.icmMainIncident,
                questionincidents: action.questionincidents
            };
        case ActionType.ONQUESTIONS_SAVE_ANSWERS_OCEVIEW_SUCCESS:
            return {
                ...state,
                meetingId: action.meetingId,
                allowDateChange: action.allowDateChange,
                url: action.url,
                isLoading: false
            };
        case ActionType.ONQUESTIONS_SAVE_ANSWERS_OCEVIEW_FAIL:
            return {
                ...state,
                isLoading: false
            };
        case ActionType.SERVICECHANGE_ESTS_SERVICE_QUESTIONNAIRE:
            return {
                ...state,
                isLoading: false,
                question: action.question,
                questionsList: action.questionsList,
                ests: action.ests,
                filterparams: action.filterparams
            };
        case ActionType.GET_CHECKLIST_OCEVIEW_LOADDATA:
            return {
                ...state,
                showExpand: action.showExpand,
                checklistID: action.checklistID,
                iFrameKustoObj: action.iFrameKustoObj,
                iFrameDataUrl: action.iFrameDataUrl,
                isVisibleContent: action.isVisibleContent,
                actionDataTobeSaved: action.actionDataTobeSaved,
                isEditable: action.isEditable,
                isreadonly: action.isreadonly,
                headerText: action.headerText,
                incidents: action.incidents,
                allPIRData: action.allPIRData,
                isIncidents: action.isIncidents,
                isPIR: action.isPIR,
                questionsList: action.questionsList,
                filterparameters: action.filterparameters
            };
        case ActionType.LOADQUESTIONS_OCEVIEW_SUCCESS:
            return {
                ...state,
                question: action.question,
                q_Index: action.q_Index,
                isVisibleContent: action.isVisibleContent,
                allowDateChange: action.allowDateChange,
                URLOCE: action.URLOCE,
                showExpand: action.showExpand,
                questionURL: action.questionURL,
                isLoading: false
            };
        case ActionType.CHANGE_EXPANDEDCOL_OCEVIEW:
            return {
                ...state,
                showExpand: action.showExpand,
                isExpanded: action.isExpanded,
                isnotExpanded: action.isnotExpanded,
                question: action.question
            };
        case ActionType.ACTIONITEM_INPUTCHANGED_OCEVIEW:
            return {
                ...state,
                actionItemTitle: action.actionItemTitle,
                qualitySpends: action.qualitySpends,
                actionItemDescription: action.actionItemDescription,
                participants: action.participants,
                meetingStatus: action.meetingStatus,
                owner: action.owner
            };    
        case ActionType.ROWCANCEL_ACTIONITEM_OCEVIEW:
            return {
                ...state,
                actionDataTobeSaved: action.actionDataTobeSaved,
                clMeetingitems: action.clMeetingitems,
                actionItemTitle: action.actionItemTitle,
                qualitySpends: action.qualitySpends,
                actionItemDescription: action.actionItemDescription,
                participants: action.participants,
                meetingStatus: action.meetingStatus,
                owner: action.owner
            };
        case '@@router/LOCATION_CHANGE': {
            const { search } = action.payload.location;
            var newurl = window.location.pathname;
            const params = new URLSearchParams(search);
            let urlParam;
            let toDate;
            let meetingTitle;
            let meetingId;
            let icmMainIncident;
            let headerText;
            let incidentFilterSeverityOptions;
            let incidentFilterSeverityModel;
            var fromDate;
            var currDate;
            var minDate;
            var url;
            //Below conditions only for direct url access like bookmarks
            if (newurl.indexOf("UIforOCEView") !== -1) {
                urlParam = 'UIforOCEView';
                toDate = new Date();
                meetingTitle = '';
                meetingId = -1;
                icmMainIncident = '';
                headerText = '';
                fromDate = new Date(
                    toDate.getFullYear(),
                    toDate.getMonth(),
                    toDate.getDate()
                );
                currDate = new Date(
                    toDate.getFullYear(),
                    toDate.getMonth(),
                    toDate.getDate()
                );

                //To enable today and future dates only for ETA field
                minDate = new Date(
                    toDate.getFullYear(),
                    toDate.getMonth(),
                    toDate.getDate()
                );
                url = {
                    SId: params.get('SId'),
                    CId: params.get('CId'),
                    Md: params.get('Md'),
                    meetingId: params.get('meetingId'),
                    meetingTitle: params.get('meetingTitle'),
                    icmMainIncident: params.get('icmMainIncident'),
                    isNew: params.get('IsNew') === undefined || params.get('IsNew') === '' || params.get('IsNew') === null || params.get('IsNew') === 'false' ? false : true
                };

                if (url.Md !== undefined && url.Md !== '' && url.Md !== null) {
                    fromDate = new Date(url.Md);
                }
                if (url.meetingTitle !== undefined && url.meetingTitle !== '' && url.meetingTitle !== null) {
                    meetingTitle = url.meetingTitle;
                }
                if (url.meetingId !== undefined && url.meetingId !== '' && url.meetingId !== null) {
                    meetingId = parseInt(url.meetingId);
                }
                if (url.icmMainIncident !== undefined && url.icmMainIncident !== '' && url.icmMainIncident !== null) {
                    icmMainIncident = url.icmMainIncident;
                }
                if (moment(fromDate).format("MM/DD/YYYY") === moment(currDate).format("MM/DD/YYYY")) {
                    headerText = 'OCE View';
                }
                else {
                    headerText = 'OCE View Readonly ';
                }

                incidentFilterSeverityOptions = [{
                    id: "1",
                    label: "Sev 1"
                }, {
                    id: "2",
                    label: "Sev 2"
                }, {
                    id: "3",
                    label: "Sev 3"
                }];
                incidentFilterSeverityModel = [];
                incidentFilterSeverityModel.push(incidentFilterSeverityOptions[0], incidentFilterSeverityOptions[1]);
                return {
                    ...state,
                    urlParam: urlParam,
                    fromDate: fromDate,
                    currDate: currDate,
                    minDate: minDate,
                    meetingTitle: meetingTitle,
                    meetingId: meetingId,
                    icmMainIncident: icmMainIncident,
                    headerText: headerText,
                    incidentFilterSeverityOptions: incidentFilterSeverityOptions,
                    incidentFilterSeverityModel: incidentFilterSeverityModel,
                    url: url
                }
            }
            else if (newurl.indexOf("OCEList") !== -1) {
                urlParam = 'OCEList';
                return {
                    ...state,
                    urlParam: urlParam,
                    checklistID: 0,
                    serviceId: 0,
                    createdby: '',
                    mainIncidentIdFilter: 0,
                    searchText: ''
                }
            }
            else {
                return {
                    ...state
                }
            }
        }
        case ActionType.RESET_STATE:
            return initialState;
        default:
            return state;
    }
};