import { BaseService } from './base.service';
import { IWeeklyDataReport, IWeeklyAgg, ITargetJson } from '../store/WeeklyAvailability';

class WeeklyDataService extends BaseService {

    private static _weeklydataService: WeeklyDataService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): WeeklyDataService {
        return (this._weeklydataService || (this._weeklydataService = new this('WeeklyData')));
    }

    public async GetWeeklyAvailabilityData(weekStartDate: string, serviceids: string): Promise<IWeeklyDataReport[]> {
        const { data } = await this.$http.get<IWeeklyDataReport[]>(`GetWeeklyAvailbilityData?weekStartDate=${weekStartDate}&serviceids=${serviceids}`);        
        return data;
    }
    public async UpdateWeeklyAggregateValues(AggregateUpdatedRecords: IWeeklyAgg[]): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateWeeklyAggregateValues`, AggregateUpdatedRecords);
        return data;
    }
    public async UndoKpiEdited(serviceId: number, viewId: number, periodIndex: string): Promise<string> {
        const { data } = await this.$http.post<string>(`UndoKpiEdited?serviceId=${serviceId}&viewId=${viewId}&period=${periodIndex}`);
        return data;
    }

    public async SubmitHeatmapTargetApprovalRequest(requestobj): Promise<number> {
        const { data } = await this.$http.post<number>("SubmitHeatmapTargetApprovalRequest", requestobj);
        return data;
    }

    public async SendHeatmapUpdateRequestEmail(requestDetails): Promise<any> {
        const { data } = await this.$http.post<any>(`SendHeatmapUpdateRequestEmail`, requestDetails);
        return data;
    }
}

export const WeeklyDataAPI = WeeklyDataService.Instance;