import React, { useState, Fragment, useRef } from 'react';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import { IPropOnCallDashboardChecklist, OCEView, IPropOnCallChecklistService, MeetingEntityForOce } from '../../../store/Checklist';
import { FontAwesomeIconMemo } from '../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faCheck, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { ToastId, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
    Card, Row, Col, Container, CardBody, Input
} from 'reactstrap';
type ReadOnlyOCEViewTableProps = {
    allServicesdata: any;
    checklists: any;
    fromDate: any;
    meetingTitle: any;
    icmMainIncident: any;
    url: any;
    question: any;
    clMeetingitems: any;
    URLOCE: any;
    templateType: any;
    onCallDate: any;
    updatedBy: any;
    selectedServiceName: string;
    selectedChecklistName: string
};
const ReadOnlyOCEViewTable = React.memo<ReadOnlyOCEViewTableProps>(({
    allServicesdata,
    checklists,
    fromDate,
    meetingTitle,
    icmMainIncident,
    url,
    question,
    clMeetingitems,
    URLOCE,
    templateType,
    onCallDate,
    updatedBy,
    selectedServiceName,
    selectedChecklistName
}) => {
    const toastIdRef = useRef<ToastId>('');
    const ChecklistGuideURL = "https://identitydivision.visualstudio.com/IdentityWiki/_wiki/wikis/IdentityWiki.wiki/16549/Checklist-UserGuide";
    const filterBasedService = (item) => {
        if (item.value === parseInt(url.SId)) {
            return true
        }
        else {
            return false;
        }
    };

    const filterBasedChecklist = (item) => {
        if (item.value === parseInt(url.CId)) {
            return true
        }
        else {
            return false;
        }
    };


    const movescrollbar = () => {
        window.scrollTo(0, 0);

    }

    //Pop out URL in new tab; for jarvis, kusto and data URLs
    const openUrlInNewTab = (url) => {
        if (url !== '') {
            window.open(url, "_blank");
        }
    }

    return (
        <div>
            <div>
            <table className="OCEViewTable" style={{ width: '95vw', color: '#148', textAlign: 'center' }}>
                <tbody>
                    <tr>
                        <td className="checklistheaderstyleReadOnly" rowSpan={2}>
                                <h3 style={{ color: '#6399cd' }}>Checklist ReadOnly View</h3>
                        </td>
                        <td className='tdstyleOCE'><label>Service: </label></td>
                            <td className='tdstyleOCE'><label>Checklist: </label></td>                            
                            <td className='tdstyleOCE'><label>Updated By: </label></td>
                            {
                                (templateType === 'Checklist Only') ?
                                    <React.Fragment><td className='tdstyleOCE'><label>OnCall Date: </label></td>
                                    </React.Fragment>
                                    : <React.Fragment></React.Fragment>
                            }
                            {
                                (templateType === 'Incident Investigation') ?
                                    <React.Fragment>
                                        <td className='tdstyleOCE'><label>Meeting Date: </label></td>
                                        <td className='tdstyleOCE'><label>Title: </label></td>
                                        <td className='tdstyleOCE'><label>Main Incident ID: </label></td>
                                    </React.Fragment>
                                    : <React.Fragment></React.Fragment>
                            }
                        <td rowSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                <Link className='OCEViewbutton btn btn-primary' onClick={movescrollbar} style={{ cursor: 'pointer' }} title='Close'
                                    to={"/OCEList"}>Close
                            </Link>
                        </td>
                    </tr>
                    <tr>
                            <td style={{ textAlign: 'center', width: templateType === 'Checklist Only' ? '400px' : '200px' }}>
                            <label><strong className="OCEListLabelstyle">
                                {/*    {isArrayWithLength(allServicesdata) ?*/}
                                {/*        allServicesdata*/}
                                {/*            .filter((item) => {*/}
                                {/*                if (filterBasedService(item)) {*/}
                                {/*                    return true*/}
                                {/*                }*/}
                                {/*            })*/}
                                {/*            .map((row, serviceIndex) => (*/}
                                {/*                <React.Fragment key={serviceIndex}>*/}
                                {/*                    {row.name}*/}
                                {/*                </React.Fragment>*/}
                                {/*        ))*/}
                                {/*    : <React.Fragment></React.Fragment>*/}
                                    {/*}*/}
                                    {selectedServiceName}
                            </strong></label>
                        </td>
                            <td style={{ textAlign: 'center', width: templateType === 'Checklist Only' ? '400px' : '200px' }}>
                                <label><strong className="OCEListLabelstyle stylingwordingOCE" style={{ maxWidth: templateType === 'Checklist Only' ? '400px' : '200px' }}>
                                {/*    {isArrayWithLength(checklists) ?*/}
                                {/*        checklists*/}
                                {/*            .filter((item) => {*/}
                                {/*                if (filterBasedChecklist(item)) {*/}
                                {/*                    return true*/}
                                {/*                }*/}
                                {/*            })*/}
                                {/*            .map((row, chklistIndex) => (*/}
                                {/*                <React.Fragment key={chklistIndex}>*/}
                                {/*                    {row.name}*/}
                                {/*                    &nbsp;&nbsp;*/}
                                {/*                    {(URLOCE === null || URLOCE === '') ?*/}
                                {/*                        <React.Fragment></React.Fragment>*/}
                                {/*                        : <React.Fragment>*/}
                                {/*                            <FontAwesomeIcon style={{ cursor: 'pointer', fontWeight: 'bolder', fontSize: '15px' }} title='Open Checklist URL' icon={'external-link-alt'} onClick={e => openUrlInNewTab(URLOCE)} />*/}
                                {/*                        </React.Fragment>*/}
                                {/*                    }*/}
                                {/*                </React.Fragment>*/}
                                {/*        ))*/}
                                {/*    : <React.Fragment></React.Fragment>*/}
                                    {/*}*/}
                                    {selectedChecklistName}
                                </strong></label>
                               
                            </td>
                            <td style={{ textAlign: 'center', width: templateType === 'Checklist Only' ? '150px' : '100px' }}>
                                <label><strong className="OCEListLabelstyle">{updatedBy}</strong></label> 
                            </td>
                            {
                                (templateType === 'Checklist Only') ?
                                    <React.Fragment>
                                        <td style={{ textAlign: 'center' }}>
                                            <label><strong className="OCEListLabelstyle">{onCallDate !== null && onCallDate !== '' ? moment(onCallDate).format("MM/DD/YYYY") : ''}</strong></label>
                                        </td>
                                    </React.Fragment>
                                    : <React.Fragment></React.Fragment>
                            }
                            {
                                (templateType === 'Incident Investigation') ?
                                    <React.Fragment>
                                        <td style={{ textAlign: 'center' }}>
                                            <label><strong className="OCEListLabelstyle">{moment(fromDate).format("MM/DD/YYYY")}</strong></label>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <label><strong className="OCEListLabelstyle">{meetingTitle}</strong></label>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <label><strong className="OCEListLabelstyle">{icmMainIncident}</strong></label>
                                        </td>
                                    </React.Fragment>
                                    : <React.Fragment></React.Fragment>
                            }
                    </tr>
                </tbody>
            </table>
            </div>
            <div className="row-bottom-margin">
                <Row >
                    <Col xs='7'>
                    </Col>
                    <Col xs='5' style={{ textAlign: 'right' }}>
                        <React.Fragment><span className='ConfigGuide' onClick={e => openUrlInNewTab(ChecklistGuideURL)}>
                            CheckList User Guide&nbsp;
                            <FontAwesomeIcon style={{ cursor: 'pointer', fontWeight: 'bolder', fontSize: '15px', verticalAlign: 'middle' }} onClick={e => openUrlInNewTab(ChecklistGuideURL)} title='CheckList User Guide' className='icon-format-config' icon={'question-circle'} />
                        </span></React.Fragment>
                    </Col>
                </Row>
            </div>
            <div>
                <Row> 
                    <Col lg="12">
                        <table id="tablefilter" className="actionItemtableocereadview" style={{ width: '95vw', color: '#148', textAlign: 'center', tableLayout: 'fixed' }}>
                            <thead>
                                <tr>
                                    <th style={{ width: '50px' }} className="thstyleOnCallReadOnlyHead"></th>
                                    <th style={{ width: '400px' }} className="thstyleOnCallReadOnlyHead">Questions</th>
                                    <th style={{ width: templateType === 'Checklist Only' ? '400px' : '200px' }} className="thstyleOnCallReadOnlyHead">Answers</th>
                                    {
                                        (templateType === 'Incident Investigation') ?
                                            <React.Fragment>
                                                <th style={{ width: '200px' }} className="thstyleOnCallReadOnlyHead">Depends on Question</th>
                                                <th style={{ width: '200px' }} className="thstyleOnCallReadOnlyHead">Depends on Value</th>
                                            </React.Fragment>
                                        : <React.Fragment></React.Fragment>
                                     }
                                    <th style={{ width: templateType === 'Checklist Only' ? '400px' : '600px' }} className="thstyleOnCallReadOnlyHead">Source</th>
                                    {
                                        (templateType === 'Incident Investigation') ?
                                            <React.Fragment>
                                                <th style={{ width: '200px' }} className="thstyleOnCallReadOnlyHead">Action Item / Incidents</th>
                                            </React.Fragment>
                                            : <React.Fragment></React.Fragment>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {isArrayWithLength(question) ?
                                    question.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <tr>
                                                <td>
                                                    {
                                                        (row.isChanged === true) ?
                                                            <FontAwesomeIcon style={{ cursor: 'pointer', fontWeight: 'bolder', fontSize: '20px', color: 'green', width: '0.85em' }} icon={'check'} />
                                                            : (row.isChanged === false && row.oceControlValue !== '') ?
                                                                <FontAwesomeIconMemo style={{ fontWeight: 'bolder', fontSize: '20px', color: 'red', width: '0.85em' }} icon={'minus-circle'} />
                                                                : <React.Fragment></React.Fragment>
                                                    }
                                                </td>
                                                <td>{row.questionName}</td>
                                                <td>{row.oceControlValue}</td>
                                                {
                                                    (templateType === 'Incident Investigation') ?
                                                        <React.Fragment>
                                                            <td>{row.dependsOn}</td>
                                                            <td>{row.dependentValue}</td>
                                                        </React.Fragment>
                                                        : <React.Fragment></React.Fragment>
                                                }
                                                <td>{
                                                    row.sourceType === "None" ? <React.Fragment>None</React.Fragment> :
                                                        row.sourceType === "URL" ? <React.Fragment><strong className="OCEListLabelstyle">Data URL :</strong> <span className='ConfigGuide stylingwordingOCE' onClick={e => openUrlInNewTab(row.questionUrl)}>{row.questionUrl}</span></React.Fragment> :
                                                            row.sourceType === "Kusto" ?
                                                                <React.Fragment>
                                                                    <Row>
                                                                        <Col lg="12" className="stylingwordingOCE">
                                                                            <strong className="OCEListLabelstyle">Cluster :</strong> {row.serverName}
                                                                        </Col>
                                                                    </Row>
                                                                    <br />
                                                                    <Row>
                                                                        <Col lg="12" className="stylingwordingOCE">
                                                                            <strong className="OCEListLabelstyle">Database :</strong> {row.dbname}
                                                                        </Col>
                                                                    </Row>
                                                                    <br />
                                                                    <Row>
                                                                        <Col lg="12" className="stylingwordingOCE">
                                                                            <strong className="OCEListLabelstyle">Query :</strong> {row.query}
                                                                        </Col>
                                                                    </Row>
                                                                    <br />
                                                                </React.Fragment>
                                                        : <React.Fragment>None</React.Fragment>
                                                }</td>
                                                {
                                                    (templateType === 'Incident Investigation') ?
                                                        <React.Fragment>
                                                        <td>
                                                        {
                                                            (row.controlType === "ICMPicker") ?
                                                            <React.Fragment>{row.incidentIds}</React.Fragment>
                                                            : (row.controlType === "ActionItems") ?
                                                                <React.Fragment>{row.comment}</React.Fragment>
                                                                : <React.Fragment></React.Fragment>
                                                        }
                                                            </td>
                                                        </React.Fragment>
                                                        : <React.Fragment></React.Fragment>
                                                }
                                            </tr>
                                        </React.Fragment>
                                    ))
                                    : <React.Fragment></React.Fragment>}
                        </tbody>
                    </table>
                </Col>
                </Row>
                {
                    (templateType === 'Incident Investigation') ?
                        <React.Fragment>
                            <br />
                            <Row>
                    <Col lg="12">
                        <span className="oce-title" style={{ textAlign: 'left', color: '#6399cd' }}>
                            Action Items
                        </span>
                        <table id="tablefilter" className="actionItemtableocereadview" style={{ width: '95vw', color: '#148', textAlign: 'center', tableLayout: 'fixed' }}>
                            <thead>
                                <tr>
                                    <th style={{ width: '200px' }} className="thstyleOnCallReadOnlyHead">Title</th>
                                    <th style={{ width: '200px' }} className="thstyleOnCallReadOnlyHead">Quality Spends</th>
                                    <th style={{ width: '200px' }} className="thstyleOnCallReadOnlyHead">Action Items</th>
                                    <th style={{ width: '120px' }} className="thstyleOnCallReadOnlyHead">Participants</th>
                                    <th style={{ width: '150px' }} className="thstyleOnCallReadOnlyHead">Meeting Status</th>
                                    <th style={{ width: '100px' }} className="thstyleOnCallReadOnlyHead">Owner</th>
                                    <th style={{ width: '100px' }} className="thstyleOnCallReadOnlyHead">Created By</th>
                                    <th style={{ width: '100px' }} className="thstyleOnCallReadOnlyHead">Modified By</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isArrayWithLength(clMeetingitems) ?
                                    clMeetingitems.map((rowMtItm: MeetingEntityForOce, MtItmindex) => (
                                        <React.Fragment key={MtItmindex}>
                                            <tr>
                                                <td className="actionItemstdstyletable" style={{ width: '200px' }}>
                                                    <span>{rowMtItm.title}</span>
                                                </td>
                                                <td className="actionItemstdstyletable" style={{ width: '100px' }}>
                                                    <span>{rowMtItm.qualitySpends}</span>
                                                </td>
                                                <td className="actionItemstdstyletable" style={{ width: '100px' }}>
                                                    <span>{rowMtItm.actionItems}</span>
                                                </td>
                                                <td className="actionItemstdstyletable" style={{ width: '100px' }}>
                                                    <span>{rowMtItm.participants}</span>
                                                </td>
                                                <td className="actionItemstdstyletable" style={{ width: '100px' }}>
                                                    <span>{rowMtItm.meetingStatus}</span>
                                                </td>
                                                <td className="actionItemstdstyletable" style={{ width: '100px' }}>
                                                    <span>{rowMtItm.owner}</span>
                                                </td>
                                                <td style={{ textAlign: 'center' }}>{rowMtItm.createdBy}</td>
                                                <td style={{ textAlign: 'center' }}>{rowMtItm.updatedBy}</td>
                                            </tr>
                                        </React.Fragment>
                                    ))
                                    : <React.Fragment></React.Fragment>
                                }
                                {
                                    (clMeetingitems === null || clMeetingitems.length === 0) ?
                                        <tr>
                                            <td colSpan={8}>
                                                No Records Found
                                            </td>
                                        </tr>
                                        : <React.Fragment></React.Fragment>
                                }
                            </tbody>
                        </table>
                    </Col>
                            </Row>
                        </React.Fragment>
                        : <React.Fragment></React.Fragment>
                }
            </div>
        </div>
    )
});

ReadOnlyOCEViewTable.displayName = 'ReadOnlyOCEViewTable';
export default ReadOnlyOCEViewTable;