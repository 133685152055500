import { IAppThunkAction, ReduxAction } from '../';
import { ActionType, IPropidentityNewsLetterGroupData, IdentityWeeklyNewsLetterState, IPropIdentityKeyResults, IPropIdentityWeeklyAvailability, IPropIdentityObjectives, IPropIdentityOKRs, IPropIdentityTopOutages, IPropIdentityTopOutagesTable, IPropIdentitySev12Table, IStylesDataList } from './types';
import { CommonAPI, IdentityWeeklyNewsLetterAPI } from '../../api';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../utils';
import { toast } from "react-toastify";
import moment from 'moment';
import Highcharts from 'highcharts';
import html2canvas from 'html2canvas';
import { getToken } from '../../adalConfig';

export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    setloadingtrue: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
    },
    setloadingfalse: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_FALSE
        });
    },
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }
    },
    getCSSByComponent: (pagename: string, componentName: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        CommonAPI.GetCSSByComponent(pagename, componentName)
            .then((stylesData: IStylesDataList[]) => {
                if (isArrayWithLength(stylesData)) {
                    dispatch({
                        stylesData,
                        type: ActionType.GET_CSS_BY_COMPONENT
                    });
                }
            })
    },
   
    GetWeekStartDate: (WeekDate: Date): IAppThunkAction<ReduxAction> => (dispatch) => {
        let WeekStartDate;
        let todaysDate = new Date();

        let sessionToken = getToken();

        // On Page Load the WeekDate is Null hence setting it to Saturday else the selected Date as WeekStartDate.
        if (isNullOrUndefined(WeekDate)) {
            if (todaysDate.getDay() === 6) {
                WeekStartDate = todaysDate;
            }
            else {
                WeekStartDate = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate() - (todaysDate.getDay() + 1));
            }
        }
        else {
            WeekStartDate = WeekDate;
        }

        const today = new Date(WeekStartDate);

        const pMonday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 1);
        const StartDate = new Date(pMonday.getFullYear(), pMonday.getMonth(), pMonday.getDate() - 2);
        const EndDate = new Date(pMonday.getFullYear(), pMonday.getMonth(), pMonday.getDate() + 4);

        dispatch({
            WeekStartDate: WeekStartDate,
            StartDate: StartDate,
            EndDate: EndDate,
            sessionToken,
            type: ActionType.WEEKLY_START_DATE_CHANGE
        });
    },
    UpdatetopOutagedata: (event, inputType): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let topOutageData = getState().IdentityWeeklyNewsLetter.newsLettertopOutagesData;
        let newsLettertopOutagesData = {
            topOutageID: topOutageData.topOutageID,
            numberOfSev1: topOutageData.numberOfSev1,
            numberOfSev2: topOutageData.numberOfSev2,
            numberOfUniqueCCL: topOutageData.numberOfUniqueCCL,
            weekDate: topOutageData.weekDate,
            weekId: topOutageData.weekId,
            sev1Customer: topOutageData.sev1Customer,
            sev2Customer: topOutageData.sev2Customer,
            outageswithExternal: topOutageData.outageswithExternal,
            incidentNotes: topOutageData.incidentNotes,
            topOutageTable: topOutageData.topOutageTable,
            notes: topOutageData.notes,
            isActive: topOutageData.isActive
        };
        if (inputType === 'incidentNotes') {
            newsLettertopOutagesData.incidentNotes = event.htmlValue;
        }
        if (inputType === 'topOutageTable') {
            newsLettertopOutagesData.topOutageTable = event.htmlValue;
        }
        else if (inputType === 'sev1Customer') {
            newsLettertopOutagesData.sev1Customer = event.htmlValue;
        }
        else if (inputType === 'sev2Customer') {
            newsLettertopOutagesData.sev2Customer = event.htmlValue;
        }
        else if (inputType === 'outageswithExternal') {
            newsLettertopOutagesData.outageswithExternal = event.htmlValue;
        }
        else if (inputType === 'numberOfSev1') {
            newsLettertopOutagesData.numberOfSev1 = event.target.value;
        }
        else if (inputType === 'numberOfSev2') {
            newsLettertopOutagesData.numberOfSev2 = event.target.value;
        }
        else if (inputType === 'numberOfUniqueCCL') {
            newsLettertopOutagesData.numberOfUniqueCCL = event.target.value;
        }
        dispatch({
            newsLettertopOutagesData,
            type: ActionType.TOPOUTAGES_INPUT_CHANGE
        });
    },
    TopOutageDataEditCancel: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let originalNewsLettertopOutagesData = getState().IdentityWeeklyNewsLetter.originalNewsLettertopOutagesData;
        let newsLettertopOutagesData = originalNewsLettertopOutagesData;
        dispatch({
            newsLettertopOutagesData,
            type: ActionType.TOPOUTAGES_INPUT_CHANGE
        });
    },
    TopOutageDataDelete: (username): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let WeekStartDate = getState().IdentityWeeklyNewsLetter.WeekStartDate;
        let newsLettertopOutagesData = getState().IdentityWeeklyNewsLetter.newsLettertopOutagesData;
        let TopOutageID = newsLettertopOutagesData.topOutageID;
        if (TopOutageID === null || TopOutageID === undefined || TopOutageID === "") {
            toast.error(renderToastifyMsg('No TopOutage Data is Present !!',
                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
            return;
        }
        dispatch({
            type: ActionType.DELETE_TOPOUTAGE_DATA_REQUEST
        });
        IdentityWeeklyNewsLetterAPI.DeleteTopOutageData(TopOutageID, username)
            .then((isSuccess: number) => {
                toast.success(renderToastifyMsg('Data deleted Successfully',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
                callrequestIdentityNewsLetterData(dispatch, WeekStartDate)
            }).catch(error => {
                toast.error(renderToastifyMsg('Delete TopOutage Data Failed. Please Try Again.',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
                dispatch({
                    type: ActionType.DELETE_TOPOUTAGE_DATA_FAIL
                });
            });
    },
    requestIdentityNewsLetterData: (Callcount, WeekStartDate): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let StartDate = getState().IdentityWeeklyNewsLetter.StartDate;
        let EndDate = getState().IdentityWeeklyNewsLetter.EndDate;
        // Dispatch request
        dispatch({
            type: ActionType.GET_IDENTITYWEEKLYNEWSLETTER_REQUEST
        });
        let count = 0;
        if (Callcount != 12) {
            count = 12;
        }
        if (Callcount === 1 || Callcount === 12) {
            //==================== to get Identity Newsletter Groups Data=================//
            IdentityWeeklyNewsLetterAPI.GetIdentityWeeklyNewsLetterGroups(WeekStartDate)
                .then((newsLetterGroupsDataRaw: IPropidentityNewsLetterGroupData[]) => {
                    const newsLetterGroupsData = [];
                    if (isArrayWithLength(newsLetterGroupsDataRaw)) {
                        newsLetterGroupsDataRaw.map((obj: IPropidentityNewsLetterGroupData) => {
                            newsLetterGroupsData.push(obj);
                        });
                    }
                    count = count + 1;
                    dispatch({
                        count: count,
                        newsLetterGroupsData,
                        type: ActionType.GET_IDENTITYWEEKLYNEWSLETTERGROUPSDATA_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_IDENTITYWEEKLYNEWSLETTERGROUPSDATA_FAILURE
                    });
                });
        }
        if (Callcount === 2 || Callcount === 12) {
            //==================== to get the Weekly availability for Identity weekly Newsletter Views =================//
            IdentityWeeklyNewsLetterAPI.GetIdentityWeeklyAvailabilityData(WeekStartDate)
                .then((newsLetterWeeklyAvailabilityDataRaw: IPropIdentityWeeklyAvailability[]) => {
                    const newsLetterWeeklyAvailabilityData = [];
                    if (isArrayWithLength(newsLetterWeeklyAvailabilityDataRaw)) {
                        newsLetterWeeklyAvailabilityDataRaw.map((obj: IPropIdentityWeeklyAvailability) => {
                            newsLetterWeeklyAvailabilityData.push(obj);
                        });
                    }
                    count = count + 1;
                    dispatch({
                        count: count,
                        newsLetterWeeklyAvailabilityData,
                        type: ActionType.GET_IDENTITYWEEKLYNEWSLETTERWEEKLYAVAILABILITYDATA_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_IDENTITYWEEKLYNEWSLETTERWEEKLYAVAILABILITYDATA_FAILURE
                    });
                });
        }
        if (Callcount === 3 || Callcount === 12) {
            let formatStartDate = moment(StartDate).format('MM/DD/YYYY');
            let formatEndDate = moment(EndDate, 'MM/DD/YYYY').add(1, 'days').format('MM/DD/YYYY');
            //==================== to get Change Requests Data For Safefly metrics  =================//
            IdentityWeeklyNewsLetterAPI.GetIdentityNewsletterSafeflyMetrics(formatStartDate, formatEndDate)
                .then((newsLetterWeeklySafeflyMetricsDataRaw) => {
                    const newsLetterWeeklySafeflyData = [];
                    let SafeflyMetricsTotal = 0;
                    if (isArrayWithLength(newsLetterWeeklySafeflyMetricsDataRaw)) {
                        newsLetterWeeklySafeflyMetricsDataRaw.map((obj: any) => {
                            newsLetterWeeklySafeflyData.push(obj);
                            let newData = (obj.New !== undefined && obj.New !== null && obj.New !== "") ? parseInt(obj.New) : 0;
                            let EdApproved = (obj.EdApproved !== undefined && obj.EdApproved !== null && obj.EdApproved !== "") ? parseInt(obj.EdApproved) : 0;
                            let Draft = (obj.Draft !== undefined && obj.Draft !== null && obj.Draft !== "") ? parseInt(obj.Draft) : 0;
                            let Deployed = (obj.Deployed !== undefined && obj.Deployed !== null && obj.Deployed !== "") ? parseInt(obj.Deployed) : 0;
                            let Completed = (obj.Completed !== undefined && obj.Completed !== null && obj.Completed !== "") ? parseInt(obj.Completed) : 0;
                            let EdRejected = (obj.EdRejected !== undefined && obj.EdRejected !== null && obj.EdRejected !== "") ? parseInt(obj.EdRejected) : 0;
                            let Rejected = (obj.Rejected !== undefined && obj.Rejected !== null && obj.Rejected !== "") ? parseInt(obj.Rejected) : 0;
                            let ReadyforReview = (obj.ReadyforReview !== undefined && obj.ReadyforReview !== null && obj.ReadyforReview !== "") ? parseInt(obj.ReadyforReview) : 0;
                            let Approved = (obj.Approved !== undefined && obj.Approved !== null && obj.Approved !== "") ? parseInt(obj.Approved) : 0;
                            let Pending = (obj.Pending !== undefined && obj.Pending !== null && obj.Pending !== "") ? parseInt(obj.Pending) : 0;
                            let Abandoned = (obj.Abandoned !== undefined && obj.Abandoned !== null && obj.Abandoned !== "") ? parseInt(obj.Abandoned) : 0;
                            let ServiceManagerApproved = (obj.ServiceManagerApproved !== undefined && obj.ServiceManagerApproved !== null && obj.ServiceManagerApproved !== "") ? parseInt(obj.ServiceManagerApproved) : 0;
                            let ServiceManagerApprovedWithSuggestions = (obj.ServiceManagerApprovedWithSuggestions !== undefined && obj.ServiceManagerApprovedWithSuggestions !== null && obj.ServiceManagerApprovedWithSuggestions !== "") ? parseInt(obj.ServiceManagerApprovedWithSuggestions) : 0;
                            let CompletedWithSuggestion = (obj.CompletedWithSuggestion !== undefined && obj.CompletedWithSuggestion !== null && obj.CompletedWithSuggestion !== "") ? parseInt(obj.CompletedWithSuggestion) : 0;

                            SafeflyMetricsTotal = SafeflyMetricsTotal + newData + EdApproved + Draft + Completed + EdRejected + Rejected + ReadyforReview + Approved + Pending + Abandoned + ServiceManagerApproved + ServiceManagerApprovedWithSuggestions + Deployed + CompletedWithSuggestion;
                        })
                    }
                    count = count + 1;
                    const newsLetterWeeklySafeflyDataOptions = LoadIdentityNewsletterSafeflyServices(newsLetterWeeklySafeflyData);
                    dispatch({
                        count: count,
                        newsLetterWeeklySafeflyData,
                        newsLetterWeeklySafeflyDataOptions,
                        SafeflyMetricsTotal,
                        type: ActionType.GET_IDENTITYWEEKLYNEWSLETTERSAFEFLYDATA_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_IDENTITYWEEKLYNEWSLETTERSAFEFLYDATA_FAILURE
                    });
                });

            IdentityWeeklyNewsLetterAPI.GetIdentityNewsletterOutages(formatStartDate)
                .then((newsLetterWeeklyOutagesData) => {
                    let outagesRawData = Object.values(newsLetterWeeklyOutagesData[0]);
                    let weekRangeRawData = Object.values(newsLetterWeeklyOutagesData[1]);
                    let weekRanges = [];
                    let uniqueServieList = [];
                    let serviceListName= [];
                    let serviceListWeekRange = [];
                    let weeksascolumns = [];

                    if (isArrayWithLength(weekRangeRawData) && isArrayWithLength(outagesRawData)) {
                        uniqueServieList = [...new Set(outagesRawData.map(obj => obj.owningTenantName))];
                        weekRanges = [...new Set(weekRangeRawData.map(obj => obj.weekDetail.replace('(', '').replace(')', '')))];
                        serviceListName = uniqueServieList.map(x => x = "serviceName:" + x);
                        serviceListWeekRange = serviceListName.map(item => item = item.concat(",", weekRanges.map(x => x = x.concat(":0")).join(", ")));
                        
                        for (let i = 0; i < serviceListWeekRange.length; i++) {
                            const string = serviceListWeekRange[i];
                            const obj = {}, re = new RegExp('(.*?):(.*?)(?:,|$)', 'g')
                            string.replace(re, (_, key, value) => obj[key.trim()] = value.trim());
                            weeksascolumns.push(obj);
                        }
                        outagesRawData.forEach(x => x.mitigateDate = moment(x.mitigateDate).format('MM/DD/YYYY'));
                        weekRangeRawData.forEach(x => x.weekStartDate = moment(x.weekStartDate).format('MM/DD/YYYY'));
                        weekRangeRawData.forEach(x => x.weekEndDate = moment(x.weekEndDate).format('MM/DD/YYYY'));
                        for (let i = 0; i < weeksascolumns.length; i++) {
                            let servicefilteredData = outagesRawData.filter(x => x.owningTenantName == weeksascolumns[i].serviceName);
                            if (servicefilteredData.length > 0) {                                
                                for (let ccount = 0; ccount < servicefilteredData.length; ccount++) {                                   
                                    let columnMatch = weekRangeRawData.find(x => x.weekStartDate <= servicefilteredData[ccount].mitigateDate && x.weekEndDate >= servicefilteredData[ccount].mitigateDate).weekDetail;
                                    let columnName = columnMatch.replace('(', '').replace(')', '');
                                    var JSON_Obj = weeksascolumns[i];                                    
                                    for (let key in JSON_Obj) {
                                        if (key == columnName) {
                                            JSON_Obj[key] = parseInt(JSON_Obj[key])+ 1;
                                        }
                                    }
                                }
                            }                            
                        }
                        const newsLetterWeeklyOutagesDataOptions = LoadIdentityNewsletterOutagesServices(weeksascolumns, weekRanges);
                        weekRanges.push('Total');
                        weekRanges.unshift('OwningTenantName');
                        const outagesWeeksHeaderList = weekRanges;  
                        let outagesFinalDate = weeksascolumns.map(v => Object.assign(v, { Total: 0 }));
                        for (let i = 0; i < outagesFinalDate.length; i++) {                            
                            outagesFinalDate[i].Total = (parseInt(Object.values(outagesFinalDate[i])[1].toString()) + parseInt(Object.values(outagesFinalDate[i])[2].toString()) + parseInt(Object.values(outagesFinalDate[i])[3].toString()) + parseInt(Object.values(outagesFinalDate[i])[4].toString()) +
                                parseInt(Object.values(outagesFinalDate[i])[5].toString()) + parseInt(Object.values(outagesFinalDate[i])[6].toString()) + parseInt(Object.values(outagesFinalDate[i])[7].toString()) + parseInt(Object.values(outagesFinalDate[i])[8].toString()) + parseInt(Object.values(outagesFinalDate[i])[9].toString()) +
                                parseInt(Object.values(outagesFinalDate[i])[10].toString()) + parseInt(Object.values(outagesFinalDate[i])[11].toString()) + parseInt(Object.values(outagesFinalDate[i])[12].toString())).toString();
                        }
                        let TotalRow = outagesFinalDate.reduce((sums, obj) => Object.keys(obj).reduce((s, k) => {
                            k === 'id' || (s[k] = (s[k] || 0) + +obj[k]);
                            return s;
                        }, sums), {});
                        TotalRow.serviceName = 'Total';
                        outagesFinalDate.push(TotalRow);
                        dispatch({
                            outagesWeeksHeaderList: outagesWeeksHeaderList,
                            newsLetterWeeklyOutagesData: newsLetterWeeklyOutagesData,
                            outagesWeeklySortedData: outagesFinalDate,
                            newsLetterWeeklyOutagesDataOptions,
                            type: ActionType.GET_IDENTITYNINTYDAYSOUTAGESLDATA_SUCCESS
                        });
                    }                   
                }).catch(error => {
                    dispatch({
                        type: ActionType.GET_IDENTITYNINTYDAYSOUTAGESLDATA_FAILURE
                    });
                });

            IdentityWeeklyNewsLetterAPI.GetIdentityNewsletterComms(formatStartDate)
                .then((newsLetterWeeklyCommsData) => {
                    let commsIncidentsRawData = Object.values(newsLetterWeeklyCommsData[0]);
                    commsIncidentsRawData.forEach(x => x.cxp_impactedservices = x.cxp_impactedservices.replace(',', '/'));
                    let weekRangeRawData = Object.values(newsLetterWeeklyCommsData[1]);
                    let weekDates = [];
                    let uniqueServieList = [];
                    let serviceListName = [];
                    let serviceListWeekRange = [];
                    let weeksascolumns = [];
                    if (isArrayWithLength(weekRangeRawData) && isArrayWithLength(commsIncidentsRawData)) {
                        uniqueServieList = [...new Set(commsIncidentsRawData.map(obj => obj.cxp_impactedservices.replace(/"|'/g, '')))];
                        uniqueServieList = uniqueServieList.map(i => i.replace(',', '/'));
                        weekDates = [...new Set(weekRangeRawData.map(obj => obj.weekDetail.replace('(', '').replace(')', '')))];                        
                        serviceListName = uniqueServieList.map(x => x = "serviceName:" + x);
                        serviceListWeekRange = serviceListName.map(item => item = item.concat(",", weekDates.map(x => x = x.concat(":0")).join(", ")));
                        for (let i = 0; i < serviceListWeekRange.length; i++) {
                            const string = serviceListWeekRange[i];
                            const obj = {}, re = new RegExp('(.*?):(.*?)(?:,|$)', 'g')
                            string.replace(re, (_, key, value) => obj[key.trim()] = value.trim());
                            weeksascolumns.push(obj);
                        }
                        commsIncidentsRawData.forEach(x => x.cxp_icmcreateddate = moment(x.cxp_icmcreateddate).format('MM/DD/YYYY'));
                        weekRangeRawData.forEach(x => x.weekStartDate = moment(x.weekStartDate).format('MM/DD/YYYY'));
                        weekRangeRawData.forEach(x => x.weekEndDate = moment(x.weekEndDate).format('MM/DD/YYYY'));
                        commsIncidentsRawData.forEach(x => x.cxp_impactedservices = x.cxp_impactedservices.replace(/"|'/g, ''));                        
                        for (let i = 0; i < weeksascolumns.length; i++) {
                            let servicefilteredData = commsIncidentsRawData.filter(x => x.cxp_impactedservices == weeksascolumns[i].serviceName);
                            if (servicefilteredData.length > 0) {
                                for (let ccount = 0; ccount < servicefilteredData.length; ccount++) {                                    
                                    let columnMatch = weekRangeRawData.find(x => new Date(x.weekStartDate) <= new Date(servicefilteredData[ccount].cxp_icmcreateddate) && new Date(x.weekEndDate) >= new Date(servicefilteredData[ccount].cxp_icmcreateddate)).weekDetail;
                                    let columnName = columnMatch.replace('(', '').replace(')', '');
                                    var JSON_Obj = weeksascolumns[i];
                                    for (let key in JSON_Obj) {
                                        if (key == columnName) {
                                            JSON_Obj[key] = parseInt(JSON_Obj[key]) + 1;
                                        }
                                    }
                                }
                            }
                        }
                        const newsLetterWeeklyCommsDataOptions = LoadIdentityNewsletterCommsServices(weeksascolumns, weekDates);                        
                        weekDates.push('Total');
                        weekDates.unshift('Owning Tenant Name at Time of Send');
                        const commsWeeksHeaderList = weekDates;                      
                        let commsFinalData = weeksascolumns.map(v => Object.assign(v, { Total: 0 }));
                        for (let i = 0; i < commsFinalData.length; i++) {
                            commsFinalData[i].Total = (parseInt(Object.values(commsFinalData[i])[1].toString()) + parseInt(Object.values(commsFinalData[i])[2].toString()) + parseInt(Object.values(commsFinalData[i])[3].toString()) + parseInt(Object.values(commsFinalData[i])[4].toString()) +
                                parseInt(Object.values(commsFinalData[i])[5].toString()) + parseInt(Object.values(commsFinalData[i])[6].toString()) + parseInt(Object.values(commsFinalData[i])[7].toString()) + parseInt(Object.values(commsFinalData[i])[8].toString()) + parseInt(Object.values(commsFinalData[i])[9].toString()) +
                                parseInt(Object.values(commsFinalData[i])[10].toString()) + parseInt(Object.values(commsFinalData[i])[11].toString()) + parseInt(Object.values(commsFinalData[i])[12].toString())).toString();
                        }
                        let TotalRow = commsFinalData.reduce((sums, obj) => Object.keys(obj).reduce((s, k) => {
                            k === 'id' || (s[k] = (s[k] || 0) + +obj[k]);
                            return s;
                        }, sums), {});
                        TotalRow.serviceName = 'Total';
                        commsFinalData.push(TotalRow);                          
                        dispatch({
                            commsWeeksHeaderList: commsWeeksHeaderList,
                            newsLetterWeeklyCommsData: newsLetterWeeklyCommsData,
                            commsWeeklySortedData: commsFinalData,
                            newsLetterWeeklyCommsDataOptions,
                            type: ActionType.GET_IDENTITYNINTYDAYSCOMMSDATA_SUCCESS
                        });
                    }
                }).catch(error => {
                    dispatch({
                        type: ActionType.GET_IDENTITYNINTYDAYSCOMMSDATA_FAILURE
                    });
                });

        }
        if (Callcount === 4 || Callcount === 12) {
            let formatStartDate = moment(StartDate).format('MM/DD/YYYY');
            let formatEndDate = moment(EndDate, 'MM/DD/YYYY').add(1, 'days').format('MM/DD/YYYY');
            //==================== to get data for Change Tool Metrics =================//
            IdentityWeeklyNewsLetterAPI.GetIdentityNewsletterChangeToolMetrics(formatStartDate, formatEndDate)
                .then((newsLetterWeeklyChangetoolMetricsDataRaw) => {
                    const newsLetterWeeklyChangeToolData = [];
                    let ChangeToolMetricsTotal = 0;
                    if (isArrayWithLength(newsLetterWeeklyChangetoolMetricsDataRaw)) {
                        newsLetterWeeklyChangetoolMetricsDataRaw.map((obj: any) => {
                            newsLetterWeeklyChangeToolData.push(obj);
                            let newData = (obj.New !== undefined && obj.New !== null && obj.New !== "") ? parseInt(obj.New) : 0;
                            let EdApproved = (obj.EdApproved !== undefined && obj.EdApproved !== null && obj.EdApproved !== "") ? parseInt(obj.EdApproved) : 0;
                            let Draft = (obj.Draft !== undefined && obj.Draft !== null && obj.Draft !== "") ? parseInt(obj.Draft) : 0;
                            let Deployed = (obj.Deployed !== undefined && obj.Deployed !== null && obj.Deployed !== "") ? parseInt(obj.Deployed) : 0;
                            let Completed = (obj.Completed !== undefined && obj.Completed !== null && obj.Completed !== "") ? parseInt(obj.Completed) : 0;
                            let EdRejected = (obj.EdRejected !== undefined && obj.EdRejected !== null && obj.EdRejected !== "") ? parseInt(obj.EdRejected) : 0;
                            let Rejected = (obj.Rejected !== undefined && obj.Rejected !== null && obj.Rejected !== "") ? parseInt(obj.Rejected) : 0;
                            let ReadyforReview = (obj.ReadyforReview !== undefined && obj.ReadyforReview !== null && obj.ReadyforReview !== "") ? parseInt(obj.ReadyforReview) : 0;
                            let Approved = (obj.Approved !== undefined && obj.Approved !== null && obj.Approved !== "") ? parseInt(obj.Approved) : 0;
                            let Pending = (obj.Pending !== undefined && obj.Pending !== null && obj.Pending !== "") ? parseInt(obj.Pending) : 0;
                            let Abandoned = (obj.Abandoned !== undefined && obj.Abandoned !== null && obj.Abandoned !== "") ? parseInt(obj.Abandoned) : 0;
                            let ServiceManagerApproved = (obj.ServiceManagerApproved !== undefined && obj.ServiceManagerApproved !== null && obj.ServiceManagerApproved !== "") ? parseInt(obj.ServiceManagerApproved) : 0;
                            let ServiceManagerApprovedWithSuggestions = (obj.ServiceManagerApprovedWithSuggestions !== undefined && obj.ServiceManagerApprovedWithSuggestions !== null && obj.ServiceManagerApprovedWithSuggestions !== "") ? parseInt(obj.ServiceManagerApprovedWithSuggestions) : 0;
                            let CompletedWithSuggestion = (obj.CompletedWithSuggestion !== undefined && obj.CompletedWithSuggestion !== null && obj.CompletedWithSuggestion !== "") ? parseInt(obj.CompletedWithSuggestion) : 0;

                            ChangeToolMetricsTotal = ChangeToolMetricsTotal + newData + EdApproved + Draft + Completed + EdRejected + Rejected + ReadyforReview + Approved + Pending + Abandoned + ServiceManagerApproved + ServiceManagerApprovedWithSuggestions + Deployed + CompletedWithSuggestion;

                        });
                    }
                    count = count + 1;
                    const newsLetterWeeklyChangeToolDataOptions = LoadIdentityNewsletterSafeflyServices(newsLetterWeeklyChangeToolData);
                    dispatch({
                        count: count,
                        newsLetterWeeklyChangeToolData,
                        newsLetterWeeklyChangeToolDataOptions,
                        ChangeToolMetricsTotal,
                        type: ActionType.GET_IDENTITYWEEKLYNEWSLETTERCHANGETOOLDATA_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_IDENTITYWEEKLYNEWSLETTERCHANGETOOLDATA_FAILURE
                    });
                });
        }
        if (Callcount === 5 || Callcount === 12) {
            let formatStartDate = moment(StartDate).format('MM/DD/YYYY');
            let formatEndDate = moment(EndDate).format('MM/DD/YYYY');
            //==================== to get data for Change Exception Metrics =================//
            IdentityWeeklyNewsLetterAPI.GetIdentityNewsletterChangeExceptionMetrics(formatStartDate, formatEndDate)
                .then((newsLetterWeeklyChangeExceptionMetricsDataRaw) => {
                    const newsLetterWeeklyChangeExceptionData = [];
                    let ChangeExceptionMetricsTotal = 0;
                    if (isArrayWithLength(newsLetterWeeklyChangeExceptionMetricsDataRaw)) {
                        newsLetterWeeklyChangeExceptionMetricsDataRaw.map((obj: any) => {
                            newsLetterWeeklyChangeExceptionData.push(obj);
                            ChangeExceptionMetricsTotal = ChangeExceptionMetricsTotal + parseInt(obj.exceptionCount);
                        })
                    }
                    count = count + 1;
                    const newsLetterWeeklyChangeExceptionDataOptions = LoadIdentityNewsletterChangeExceptionServices(newsLetterWeeklyChangeExceptionData);
                    dispatch({
                        count: count,
                        newsLetterWeeklyChangeExceptionData,
                        newsLetterWeeklyChangeExceptionDataOptions,
                        ChangeExceptionMetricsTotal,
                        type: ActionType.GET_IDENTITYWEEKLYNEWSLETTERCHANGEEXCEPTIONDATA_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_IDENTITYWEEKLYNEWSLETTERCHANGEEXCEPTIONDATA_FAILURE
                    });
                });
        }
        if (Callcount === 6 || Callcount === 12) {
            //==================== to get data for Newsletter Objectives Data =================//
            IdentityWeeklyNewsLetterAPI.GetIdentityNewsletterObjectivesData()
                .then((newsLetterWeeklyObjectivesDataRaw: IPropIdentityObjectives[]) => {
                    let newsLetterObjectivesData = {};
                    if (isArrayWithLength(newsLetterWeeklyObjectivesDataRaw)) {
                        newsLetterWeeklyObjectivesDataRaw.map((obj: IPropIdentityObjectives) => {
                            newsLetterObjectivesData = {
                                objectiveID: obj.objectiveID,
                                name: obj.name,
                                startDate: moment(obj.startDate).format('MM/DD/YYYY'),
                                endDate: moment(obj.endDate).format('MM/DD/YYYY'),
                                status: obj.status,
                                notes: obj.notes,
                                isActive: obj.isActive
                            };
                        });
                    }
                    count = count + 1;
                    dispatch({
                        count: count,
                        newsLetterObjectivesData,
                        type: ActionType.GET_IDENTITYWEEKLYNEWSLETTEROBJECTIVESDATA_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_IDENTITYWEEKLYNEWSLETTEROBJECTIVESDATA_FAILURE
                    });
                });
        }
        if (Callcount === 7 || Callcount === 12) {
            //==================== to get data for Newsletter OKRs Data =================//
            IdentityWeeklyNewsLetterAPI.GetIdentityNewsletterOKRsData(WeekStartDate)
                .then((newsLetterWeeklyOKRsDataRaw: IPropIdentityOKRs[]) => {
                    let newsLetterOKRsData = [];
                    if (isArrayWithLength(newsLetterWeeklyOKRsDataRaw)) {
                        newsLetterWeeklyOKRsDataRaw.map((item: IPropIdentityOKRs) => {
                            newsLetterOKRsData.push(item);
                        })
                    }
                    count = count + 1;
                    dispatch({
                        count: count,
                        newsLetterOKRsData,
                        type: ActionType.GET_IDENTITYWEEKLYNEWSLETTEROKRSDATA_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_IDENTITYWEEKLYNEWSLETTEROKRSDATA_FAILURE
                    });
                });
        }
        if (Callcount === 8 || Callcount === 12) {
            //==================== to get data for Newsletter TopOutages First table Data=================//
            IdentityWeeklyNewsLetterAPI.GetIdentityNewsletterTopOutagesTable(WeekStartDate)
                .then((newsLetterWeeklytopOutagesDataRawTable: IPropIdentityTopOutagesTable[]) => {
                    const newsLettertopOutagesDataTable = [];
                    if (isArrayWithLength(newsLetterWeeklytopOutagesDataRawTable)) {
                        newsLetterWeeklytopOutagesDataRawTable.map((obj: IPropIdentityTopOutagesTable) => {
                            newsLettertopOutagesDataTable.push(obj);
                        });
                    }
                    count = count + 1;
                    dispatch({
                        count: count,
                        newsLettertopOutagesDataTable,
                        type: ActionType.GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_TABLE_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_TABLE_FAILURE
                    });
                });
        }
        if (Callcount === 9 || Callcount === 12) {
            //==================== to get data for Newsletter TopOutages Data=================//
            IdentityWeeklyNewsLetterAPI.GetIdentityNewsletterTopOutages(WeekStartDate)
                .then((newsLetterWeeklytopOutagesDataRaw: IPropIdentityTopOutages[]) => {
                    let newsLettertopOutagesData = {};
                    let originalNewsLettertopOutagesData = {};
                    if (isArrayWithLength(newsLetterWeeklytopOutagesDataRaw)) {
                        newsLetterWeeklytopOutagesDataRaw.map((obj: IPropIdentityTopOutages) => {
                            newsLettertopOutagesData = {
                                topOutageID: obj.topOutageID,
                                numberOfSev1: obj.numberOfSev1,
                                numberOfSev2: obj.numberOfSev2,
                                numberOfUniqueCCL: obj.numberOfUniqueCCL,
                                weekDate: moment(obj.weekDate).format('MM/DD/YYYY'),
                                weekId: obj.weekId,
                                sev1Customer: obj.sev1Customer,
                                sev2Customer: obj.sev2Customer,
                                outageswithExternal: obj.outageswithExternal,
                                incidentNotes: obj.incidentNotes,
                                topOutageTable: obj.topOutageTable,
                                notes: obj.notes,
                                isActive: obj.isActive
                            };
                        });
                        originalNewsLettertopOutagesData = newsLettertopOutagesData;
                    }
                    count = count + 1;
                    dispatch({
                        count: count,
                        newsLettertopOutagesData,
                        originalNewsLettertopOutagesData,
                        type: ActionType.GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_FAILURE
                    });
                });
        }
        if (Callcount === 10 || Callcount === 12) {
            //==================== to get Divisional OKRs data for Newsletter Objectives Data =================//
            IdentityWeeklyNewsLetterAPI.GetIdentityNewsletterOKRsObjectivesData()
                .then((newsLetterWeeklyObjectivesDataRaw: IPropIdentityObjectives[]) => {
                    let newsLetterDivisionalObjectivesData = [];
                    let newsLetterOKRsObjectives = [];
                    let selectedObjective = 0;
                    newsLetterOKRsObjectives.push({ 'name': 'Select Objective / Add New', 'value': 0 });
                    if (isArrayWithLength(newsLetterWeeklyObjectivesDataRaw)) {
                        newsLetterWeeklyObjectivesDataRaw.map((obj: IPropIdentityObjectives) => {
                            newsLetterDivisionalObjectivesData.push(obj);
                            newsLetterOKRsObjectives.push({ name: obj.name, value: obj.objectiveID });
                        });
                        newsLetterOKRsObjectives.push({ name: 'Add New Objective', value: 'New' });
                    }
                    count = count + 1;
                    dispatch({
                        count: count,
                        newsLetterDivisionalObjectivesData,
                        newsLetterOKRsObjectives,
                        selectedObjective,
                        type: ActionType.GET_IDENTITYNEWSLETTERDIVSIONALOBJECTIVES_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_IDENTITYNEWSLETTERDIVSIONALOBJECTIVES_FAILURE
                    });
                });
        }
        if (Callcount === 11 || Callcount === 12) {
            //==================== to get data for Newsletter OKRs Data =================//
            IdentityWeeklyNewsLetterAPI.GetIdentityNewsletterOKRsKeyResultsData()
                .then((newsLetterWeeklyOKRsDataRaw: IPropIdentityKeyResults[]) => {
                    let newsLetterDivisionalKeyResultsData = [];
                    if (isArrayWithLength(newsLetterWeeklyOKRsDataRaw)) {
                        newsLetterWeeklyOKRsDataRaw.map((item: IPropIdentityKeyResults) => {
                            newsLetterDivisionalKeyResultsData.push(item);
                        })
                    }
                    count = count + 1;
                    dispatch({
                        count: count,
                        newsLetterDivisionalKeyResultsData,
                        type: ActionType.GET_IDENTITYNEWSLETTERDIVSIONALOKRS_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_IDENTITYNEWSLETTERDIVSIONALOKRS_FAILURE
                    });
                });
        }
        if (Callcount === 12 || Callcount === 13) {
            //==================== to get data for Sev1 and Sev2 Incidents=================//
            IdentityWeeklyNewsLetterAPI.GetIdentityNewsletterSev1Sev2IncidentsFromKusto(WeekStartDate)
                .then((newsLetterSev1Sev2IncidentsDataRaw: IPropIdentitySev12Table[]) => { 
                    let sev1Total = 0;
                    let sev2Total = 0;
                    if (!isNullOrUndefined(newsLetterSev1Sev2IncidentsDataRaw)) {
                        newsLetterSev1Sev2IncidentsDataRaw.map((obj: IPropIdentitySev12Table) => {
                            if (obj.severity == 1)
                                sev1Total = sev1Total + obj.totalCount;
                            else if (obj.severity == 2)
                                sev2Total = sev2Total + obj.totalCount;
                        });
                    }
                    count = count + 1;                 
                    dispatch({
                        count: count,
                        sev1Total,
                        sev2Total,                      
                        type: ActionType.GET_IDENTITYWEEKLYNEWSLETTERSEV1SEV2INCIDENTSDATA_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_IDENTITYWEEKLYNEWSLETTERSEV1SEV2INCIDENTSDATA_FAILURE
                    });
                });
        }
    },
    SendMailIdentityNewsletterDashboard: (TopOutageTableImage, WeelyAvailabilityImage, TopOutageimageCircle, OKRImage, ChangeMgmtImage, Outages, OutagesComms): IAppThunkAction<ReduxAction> => (dispatch, getState) => {

        dispatch({
            type: ActionType.SEND_EMAIL_REQUEST
        });

        let newsLettertopOutagesData = getState().IdentityWeeklyNewsLetter.newsLettertopOutagesData;
        let sev1TotalCount = getState().IdentityWeeklyNewsLetter.sev1TotalCount;
        let sev2TotalCount = getState().IdentityWeeklyNewsLetter.sev2TotalCount;
        let newsLetterWeeklyAvailabilityDataTable = getState().IdentityWeeklyNewsLetter.newsLetterWeeklyAvailabilityData;
        let newsLetterWeeklySafeflyDataOptionsDataTable = getState().IdentityWeeklyNewsLetter.newsLetterWeeklySafeflyDataOptions;
        let newsLetterWeeklyChangeToolDataOptionsDataTable = getState().IdentityWeeklyNewsLetter.newsLetterWeeklyChangeToolDataOptions;
        let newsLetterGroupsDataTable = getState().IdentityWeeklyNewsLetter.newsLetterGroupsData;
        let newsLetterOKRsData = getState().IdentityWeeklyNewsLetter.newsLetterOKRsData;
        let newsLettertopOutagesDataTable = getState().IdentityWeeklyNewsLetter.newsLettertopOutagesDataTable;
        let stylesDataForHTML = getState().IdentityWeeklyNewsLetter.stylesData;

        let imgAutoSize = 'height: auto; max-width: 100%; width: auto;object-fit: contain; margin: auto;position:absolute;display: block;background-repeat:no-repeat; background-size:100%;';
        if (isArrayWithLength(stylesDataForHTML)) {
            imgAutoSize = stylesDataForHTML[0].styleValue;
        }

        let outableTableDatahtml = '';
        newsLettertopOutagesDataTable.map((obj: IPropIdentityTopOutagesTable, index) => {
            outableTableDatahtml = outableTableDatahtml +
                '<tr style="background-color: white !important;color: black!important;border: 1px solid black;font-size: 16px;font-family: Calibri">' +
                '<td style="text-align: center !important;border: 1px solid black;color: black;width: 5%;font-size: 16px;font-family: Calibri">' + obj.severity + '</td>' +
                '<td style="text-align: center !important;border: 1px solid black;color: black;width: 5%;font-size: 16px;font-family: Calibri">' + obj.incidentId + '</td>' +
                '<td style="text-align: center !important;border: 1px solid black;color: black;width: 10%;font-size: 16px;font-family: Calibri">' + obj.tenantteam + '</td>' +
                '<td style="text-align: center !important;border: 1px solid black;color: black;width: 10%;font-size: 16px;font-family: Calibri">' + obj.teamName + '</td>' +                
                '<td style="text-align: center !important;border: 1px solid black;color: black;width: 7%;font-size: 16px;font-family: Calibri">' + obj.impactStartTimeUTC + '</td>' +
                '<td style="text-align: center !important;border: 1px solid black;color: black;width: 7%;font-size: 16px;font-family: Calibri">' + obj.incidentCreateDateUTC + '</td>' +
                '<td style="text-align: center !important;border: 1px solid black;color: black;width: 7%;font-size: 16px;font-family: Calibri">' + obj.outageDeclaredDateUTC + '</td>' +
                '<td style="text-align: center !important;border: 1px solid black;color: black;width: 7%;font-size: 16px;font-family: Calibri">' + obj.notifiedTimeUTC + '</td>' +
                '<td style="text-align: center !important;border: 1px solid black;color: black;width: 7%;font-size: 16px;font-family: Calibri">' + obj.mitigateDateUTC + '</td>' +
                '<td style="text-align: center !important;border: 1px solid black;color: black;width: 7%;font-size: 16px;font-family: Calibri">' + obj.ttm + '</td>' +
                '<td style="text-align: center !important;border: 1px solid black;color: black;width: 7%;font-size: 16px;font-family: Calibri">' + obj.ttn + '</td>' +
                '<td style="text-align: center !important;border: 1px solid black;color: black;width: 7%;font-size: 16px;font-family: Calibri">' + obj.impactedtenants + '</td>' +
                '<td style="text-align: center !important;border: 1px solid black;color: black;width: 7%;font-size: 16px;font-family: Calibri">' + obj.subCount + '</td>' +
                '<td style="text-align: center !important;border: 1px solid black;color: black;width: 7%;font-size: 16px;font-family: Calibri">' + obj.pirStatus + '</td>' + '</tr>'
        });

        let topOutageCircleHTML = '<table style = "width: 100%; height: 100%"><tbody><div style= "width: 100%, paddingLeft:30%">' + 
            '<tr className="topOutageTableDataRow"><td className="topOutageAddInfoTableTD"><div className="topOutagescircle">' +
            '<text className="topOutagesCircletext"># of Sev1<br />Incidents<br /><span className="ValueClass">' + (newsLettertopOutagesData.numberOfSev1 == '0' ? sev1TotalCount : newsLettertopOutagesData.numberOfSev1) + '</span></text>' +
            '</div></td><td className = "topOutageAddInfoTableTD"><div className="topOutagescircle">' +
            '<text className="topOutagesCircletext"># of Sev2<br />Incidents<br /><span className="ValueClass">' + (newsLettertopOutagesData.numberOfSev2 == '0' ? sev2TotalCount : newsLettertopOutagesData.numberOfSev2) + '</span></text>' +
            '</div></td><td className = "topOutageAddInfoTableTD"><div className="topOutagescircle">' +
            '<text className="topOutagesCircletext"># of Unique<br />CCL Bridges<br /><span className="ValueClass">' + newsLettertopOutagesData.numberOfUniqueCCL  + '</span></text>' +
            '</div></td></tr></div></tbody></table>'; 

        
        var tab_text = '<html>' +
                            '<head>' +
                                '<style>' +
                                    '.topOutagessquare {width: 200px !important; background-color:##f2f2f2 !important;vertical-align: top;}' +
                                    '.topOutageTables span{text-align: center !important; font-weight: bold; background-color: gray !important;color: White !important;border: 1px solid black;font-size: 11px;font-family: Calibri;}' +
                                    '.imgAutoSize {height: auto; max-width: 100%; width: auto;object-fit: contain; margin: auto;position:absolute;display: block;background-repeat:no-repeat; background-size:100%;}' +   
                                    '.weeklyavailabilitytable {table-layout: fixed; width: 100 % !important; background - color: lightsteelblue; border: 1px solid grey;}' +
                                    '.weeklyAvailabilityflexcontainer { display: flex; flex - wrap: wrap;}' +
                                    '.changerequestsTable {width: 100%;}' +
                                    '.safeflylegendtrstyle {background-color: white !important; color: black !important;}' +
                                    '.legendthstyle {text-align: center !important;border: 2px solid black;color: black;}' +
                                    '.safeflysecondrow {background-color: white !important;color: black !important;border: 2px solid black;}' +
                                    '.divtablechangeRequests{display: flex;}' +
                                    '.safeflylegendtdweight {font-weight: bold;width: 100px;}' +
                                    '.legendtdalign {width: 200px;text-align: center}' +
                                    '.safeflyfirstrow {background-color: black !important;color: white !important;border: 2px solid black;}' +
                                    '.topOutageTableDataRow {margin: auto; text - align: left!important;  color: black!important; font - size: 12px; font - family: "Segoe UI",Tahoma, Geneva, Verdana, sans - serif; border - top: none!important;}' +
                                    '.topOutageAddInfoTableTD {min-height: 66px;min-width: 235px;margin: auto;border-left: none !important;border-right: none !important;border-bottom: none !important; vertical - align: central!important; text - align: center!important; }' + 
                                    '.topOutagescircle {display: flex;width: 225px;height: 225px;background-color: #f2f2f2 !important;border-radius: 50%;border-color: #cce5bb !important;    border:solid;}' +
                                    '.topOutagesCircletext {margin: auto;text-align: center !important;color: black !important;font-size: 18px;font-weight: 700;font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;}' +
                                    '.ValueClass {font-size: 25px;font-weight: 500;font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;}' +                                    
                                '</style>' +
                            '</head>' +
                            '<body>' +
                                '<table style="font-family: Calibri">' +
                                    '<tr>' +
                                        '<td style="font-size: 16px; color: red;" align = "left";>** Note : Internal Use Only.Microsoft Confidential - Not meant for external distribution.' +
                                        '</td>' +
                                    '</tr>' +
                                '</table><br/><br/>' +
                                '<table style="font-family: Calibri">' + 
                                    '<tr>' +                              
                                        '<td>' +
                                            '<table style = "width: 100%;border: 1px solid black">' +
                                                '<thead>' +    
                                                    '<tr>' +
                                                        '<td style="font-size: 14px; color: lightskyblue" align="left">Identity Team Weekly Newsletter</td>' +
                                                    '</tr>' +
                                                    '<tr style="background-color:#2e6da4; color: #E9AB82;font-size: 42px;font-weight: bold; height: 50px;">' +
                                                        '<td style="font-size: 36px; font-weight: bold; height: 50px;border: 1px solid black" align="center";>Last week in Identity Livesite</td>' +
                                                    '</tr>' +
                                                    '<tr style="background-color:lightsteelblue; height: 30px;border: 1px solid black;">' +            
                                                         '<td style="text-align:center; font-size: 22px; font-weight: bold;border: 1px solid black">Top outages</td>' +
                                                    '</tr>' +
                                                '</thead>' +
                                                '<tbody>' +
                                                    '<tr>' +
                                                        '<td>' +
                                                        '<table style="width:100%">' +
                                                            '<br>' +
                                                                '<tr>' +
                                                                '<td style="font-size: 16px !important;font-family: Calibri !important">' + newsLettertopOutagesData.topOutageTable + '</td>' +
                                                                '</tr>' +
                                                         '</table > ' +
                                                        '</td>' +
                                                    '</tr> ' +  
                                                '</tbody>' +
                                            '</table>' +
                                        '</td>' +                               
                                    '</tr>' +                                   
                                    '<tr>' + 
                                        '<td>' + 
                                            '<table style="width:100%">' +          
                                                '<br>' +
                                                '<tr>' +
                                                    '<td style="font-size: 16px !important;font-family: Calibri !important">' + newsLettertopOutagesData.incidentNotes + '</td>' +
                                                '</tr>' +
                                            '</table > ' +
                                        '</td>' + 
                                    '</tr> ' +  
                                        '<tr style="background-color:lightsteelblue; height: 20px;">' +
                                    '<td  style="text-align:center; font-size: 22px; font-weight: bold;border: 1px solid black">Additional Info</td>' +
                                    '</tr><br>' +
                                    '<tr>' +
                                        '<td>' +
                                            '<table style="width:100%">' +     
                                                '<tr>' +
                                                    '<td  style="width:100%;text-align:center;align=center;">' +                                                      
                                                        '<img style="' + imgAutoSize + '" src = ' + TopOutageimageCircle + '/>' +                                                        
                                                    '</td>' +
                                                '</tr>' +
                                            '</table>' +
                                        '</td>' +
                                    '</tr><br>' +                                    
                                    '<tr style="background-color:#2e6da4; height: 20px;">' +
                                        '<td style="border: 1px solid black" >&nbsp;</td>' +
                                    '</tr>' +
                                    '<tr style="background-color:lightsteelblue; height: 30px;">' +
                                        '<td  style="text-align:center; font-size: 22px; font-weight: bold;border: 1px solid black">Mission Critical Services - Weekly Availability</td>' +
                                    '</tr>' +
                                    '<tr>' +
                                        '<td>' +
                                            '<table style="width:100%">' +
                                                '<tr>' +
                                                    '<td  style="width:100%;text-align:center;align=center;">' +                                                       
                                                          '<img style="' + imgAutoSize + '" src = ' + WeelyAvailabilityImage + '/>' +                                                        
                                                    '</td>' +
                                                '</tr>' +
                                            '</table>' +
                                        '</td>' +
                                    '</tr><br>' +                                               
                                    '<tr style="background-color:#2e6da4; height: 20px;">' +
                                        '<td  style="border: 1px solid black;">&nbsp;</td>' +
                                    '</tr>' +
                                    '<tr style="background-color:lightsteelblue; height: 30px;border-bottom: 1px solid black;">' +
                                        '<td  style="text-align:center; font-size: 22px; font-weight: bold;border: 1px solid black">Divisional KRs</td>' +
                                    '</tr>' +
                                    '<tr>' +
                                        '<td>' +
                                            '<table style="width:100%">' +
                                                '<tr>' +
                                                    '<td  style="width:100%;text-align:center;align=center;">' +    
                                                         '<img style="' + imgAutoSize + '" src = ' + OKRImage + '/>' +                                                        
                                                    '</td>' +
                                                '</tr>' +
                                            '</table>' +
                                        '</td>' +
                                    '</tr><br>' +                                           
                                    '<tr style="background-color:#2e6da4; height: 20px;">' +
                                        '<td  style="border: 1px solid black;">&nbsp;</td>' +
                                    '</tr>' +
                                    '<tr style="background-color:lightsteelblue; height: 30px;style="border-bottom: 1px solid black;"">' +
                                        '<td  style="text-align:center; font-size: 22px; font-weight: bold;border: 1px solid black">90 Day Outage Count</td>' +
                                    '</tr>' +
                                    '<tr>' +
                                        '<td>' +
                                            '<table style="width:100%">' +
                                                '<tr>' +
                                                    '<td  style="width:100%;text-align:center;align=center;">' +                                                        
                                                          '<img style="' + imgAutoSize + '" src = ' + Outages + '/>' +                                                        
                                                    '</td>' +
                                                '</tr>' +
                                            '</table>' +
                                        '</td>' +
                                    '</tr><br>' +                                           
                                    '<tr style="background-color:#2e6da4; height: 20px;">' +
                                        '<td  style="border: 1px solid black;">&nbsp;</td>' +
                                    '</tr>' +
                                    '<tr style="background-color:lightsteelblue; height: 30px;style="border-bottom: 1px solid black;"">' +
                                        '<td  style="text-align:center; font-size: 22px; font-weight: bold;border: 1px solid black">90 Day Comms Count</td>' +
                                    '</tr>' +
                                    '<tr>' +
                                        '<td>' +
                                            '<table style="width:100%">' +
                                                '<tr>' +
                                                    '<td  style="width:100%;text-align:center;align=center;">' +                                                        
                                                          '<img style="' + imgAutoSize + '" src = ' + OutagesComms + '/>' +                                                        
                                                    '</td>' +
                                                '</tr>' +
                                            '</table>' +
                                        '</td>' +
                                    '</tr><br>' +                                               
                                    '<tr style="background-color:#2e6da4; height: 20px;">' +
                                        '<td  style="border: 1px solid black;">&nbsp;</td>' +
                                    '</tr>' +
                                    '<tr style="background-color:lightsteelblue; height: 30px;">' +
                                        '<td  style="text-align:center; font-size: 22px; font-weight: bold;border: 1px solid black;">Number of sdp and non-sdp Approved for the Week</td>' +
                                    '</tr>' +
                                    '<tr>' +
                                        '<td>' +
                                            '<table style="width:100%">' +
                                                '<tr>' +
                                                    '<td  style="width:100%;text-align:center;align=center";">' +                                                        
                                                         '<img style="' + imgAutoSize + '" src = ' + ChangeMgmtImage + '/>' +                                                        
                                                    '</td>' +
                                                '</tr>' +
                                            '</table>' +
                                        '</td>' +
                                    '</tr><br>' +                                                              
                                '</table>' +
                                '<table style="font-family: Calibri">' +
                                    '<tr>' +
                                        '<td style="font-size: 16px; color: red;" align = "left";>** Note : Internal Use Only.Microsoft Confidential - Not meant for external distribution.' +
                                        '</td>' +
                                    '</tr>' +
                                '</table><br/><br/>' +
                            '</tbody>' +
                       '</html>';                       
        let LoginuserId = getState().authUser.login;
        let StartDate = getState().IdentityWeeklyNewsLetter.StartDate;
        let EndDate = getState().IdentityWeeklyNewsLetter.EndDate;
        let dateRange = moment(StartDate).format("MM/DD") + "-" + moment(EndDate).format("MM/DD");
        IdentityWeeklyNewsLetterAPI.NewsletterIdentity_SendEmail(LoginuserId, dateRange, (tab_text))
            .then((result: number) => {
                if (result != -1) {
                    dispatch({
                        type: ActionType.SEND_EMAIL_REQUEST_PASS
                    });
                    toast.success(
                        renderToastifyMsg("Email sent succesfully!! ",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                    );
                } else {
                    dispatch({
                        type: ActionType.SEND_EMAIL_REQUEST_FAIL
                    });
                    toast.error(
                        renderToastifyMsg("There is error While sending email, Please contact Admin!",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                    );
                }

            }).catch(error => {
                dispatch({
                    type: ActionType.SEND_EMAIL_REQUEST_FAIL
                });
                toast.error(
                    renderToastifyMsg("There is error While sending email, Please contact Admin!",
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                );
            });
    },
    // Divisional OKRs Action methods

    refreshObjectivesDataAfterSave: (selectedObjective): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        // Dispatch request
        dispatch({
            type: ActionType.GET_IDENTITYNEWSLETTEROBJECTIVES_REQUEST
        });
        //==================== to get data for Newsletter Objectives Data =================//
        IdentityWeeklyNewsLetterAPI.GetIdentityNewsletterOKRsObjectivesData()
            .then((newsLetterWeeklyObjectivesDataRaw: IPropIdentityObjectives[]) => {
                let newsLetterDivisionalObjectivesData = [];
                let newsLetterOKRsObjectives = [];
                newsLetterOKRsObjectives.push({ 'name': 'Select Objective / Add New', 'value': 0 });
                if (isArrayWithLength(newsLetterWeeklyObjectivesDataRaw)) {
                    newsLetterWeeklyObjectivesDataRaw.map((obj: IPropIdentityObjectives) => {
                        newsLetterDivisionalObjectivesData.push(obj);
                        newsLetterOKRsObjectives.push({ name: obj.name, value: obj.objectiveID });
                    });
                    newsLetterOKRsObjectives.push({ name: 'Add New Objective', value: 'New' });
                    dispatch({
                        newsLetterDivisionalObjectivesData,
                        newsLetterOKRsObjectives,
                        selectedObjective,
                        isAddNewObjective: false,
                        newObjective: '',
                        type: ActionType.GET_IDENTITYNEWSLETTEROBJECTIVES_SUCCESS
                    });
                }
            }).catch(error => {
                dispatch({
                    type: ActionType.GET_IDENTITYNEWSLETTEROBJECTIVES_FAILURE
                });
            });
    },
    getkeyResultsData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let selectedObjective = getState().IdentityWeeklyNewsLetter.selectedObjective;
        let OKRWeekStartDate = getState().IdentityWeeklyNewsLetter.OKRWeekStartDate;
        // Dispatch request
        dispatch({
            type: ActionType.GET_IDENTITYNEWSLETTERKEYRESULTS_REQUEST
        });
        //==================== to get data for Newsletter OKRs Data =================//
        IdentityWeeklyNewsLetterAPI.GetIdentityNewsletterOKRsKeyResultsData()
            .then((newsLetterWeeklyOKRsDataRaw: IPropIdentityKeyResults[]) => {
                let newsLetterDivisionalKeyResultsData = [];
                if (isArrayWithLength(newsLetterWeeklyOKRsDataRaw)) {
                    newsLetterWeeklyOKRsDataRaw.map((item: IPropIdentityKeyResults) => {
                        newsLetterDivisionalKeyResultsData.push(item);
                    });
                    let selectedKeyResults = [];
                    if (!isNullOrUndefined(newsLetterDivisionalKeyResultsData)) {
                        selectedKeyResults = newsLetterDivisionalKeyResultsData.filter(item => ((item.objectiveID === selectedObjective) && (moment(OKRWeekStartDate).format('MM/DD/YYYY') === moment(item.weekDate).format('MM/DD/YYYY'))));
                    }
                    dispatch({
                        newsLetterDivisionalKeyResultsData,
                        selectedKeyResults,
                        type: ActionType.GET_IDENTITYNEWSLETTERKEYRESULTS_SUCCESS
                    });
                }
            }).catch(error => {
                dispatch({
                    type: ActionType.GET_IDENTITYNEWSLETTERKEYRESULTS_FAILURE
                });
            });
    },

    handleLoadingProcess: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_EMAIL_LOADING_TRUE
        });
    },

    handleOKRObjectiveChange: (selectedObjective): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let newsLetterDivisionalKeyResultsData = getState().IdentityWeeklyNewsLetter.newsLetterDivisionalKeyResultsData;
        let newsLetterDivisionalObjectivesData = getState().IdentityWeeklyNewsLetter.newsLetterDivisionalObjectivesData;
        let ObjectiveStartDate = getState().IdentityWeeklyNewsLetter.ObjectiveStartDate;
        let ObjectiveEndDate = getState().IdentityWeeklyNewsLetter.ObjectiveEndDate;
        let OKRWeekStartDate = getState().IdentityWeeklyNewsLetter.OKRWeekStartDate;
        let isAddNewObjective = getState().IdentityWeeklyNewsLetter.isAddNewObjective;
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        if (selectedObjective === "New") {
            isAddNewObjective = true
        }
        else {
            isAddNewObjective = false;
        }
        let selectedKeyResults = [];
        let selectedobjectivefilter = [];
        if (!isNullOrUndefined(newsLetterDivisionalObjectivesData)) {
            selectedobjectivefilter = newsLetterDivisionalObjectivesData.filter(item => item.objectiveID === selectedObjective);
        }
        if (!isNullOrUndefined(newsLetterDivisionalKeyResultsData)) {
            selectedKeyResults = newsLetterDivisionalKeyResultsData.filter(item => ((item.objectiveID === selectedObjective) && (moment(OKRWeekStartDate).format('MM/DD/YYYY') === moment(item.weekDate).format('MM/DD/YYYY'))));
        }

        if (!isNullOrUndefined(selectedobjectivefilter) && selectedobjectivefilter.length !== 0) {
            ObjectiveStartDate = new Date(selectedobjectivefilter[0].startDate);
            ObjectiveEndDate = new Date(selectedobjectivefilter[0].endDate);
        }
        else {
            ObjectiveStartDate = ObjectiveEndDate = null;
        }
        dispatch({
            selectedObjective,
            selectedKeyResults,
            ObjectiveStartDate,
            ObjectiveEndDate,
            isAddNewObjective,
            type: ActionType.IDENTITYNEWSLETTER_OKROBJECTIVE_CHANGE
        });
    },
    handleObjectiveStartDateChange: (ObjectiveStartDate): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            ObjectiveStartDate,
            type: ActionType.IDENTITYNEWSLETTER_OBJECTIVES_STARTDATE_CHANGE
        });
    },
    handleObjectiveEndDateChange: (ObjectiveEndDate): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            ObjectiveEndDate,
            type: ActionType.IDENTITYNEWSLETTER_OBJECTIVES_ENDDATE_CHANGE
        });
    },
    filterKeyResults: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let newsLetterDivisionalKeyResultsData = getState().IdentityWeeklyNewsLetter.newsLetterDivisionalKeyResultsData;
        let selectedObjective = getState().IdentityWeeklyNewsLetter.selectedObjective;
        let OKRWeekStartDate = getState().IdentityWeeklyNewsLetter.OKRWeekStartDate;
        let selectedKeyResults = [];
        if (!isNullOrUndefined(newsLetterDivisionalKeyResultsData)) {
            selectedKeyResults = newsLetterDivisionalKeyResultsData.filter(item => ((item.objectiveID === selectedObjective) && (moment(OKRWeekStartDate).format('MM/DD/YYYY') === moment(item.weekDate).format('MM/DD/YYYY'))));
        }
        dispatch({
            selectedKeyResults: selectedKeyResults,
            type: ActionType.NEWSLETTER_KEYRESULTS_CONTROLS_CHANGE
        });
    },
    GetOKRWeekStartDate: (WeekDate: Date): IAppThunkAction<ReduxAction> => (dispatch) => {
        let OKRWeekStartDate;
        if (WeekDate === null || WeekDate === undefined) {
            OKRWeekStartDate = new Date();
        }
        else {
            OKRWeekStartDate = WeekDate;
        }
        const today = new Date(OKRWeekStartDate);
        // If the Present Day is not Saturday fetch the very previous Saturday as the OKRWeekStartDate for KeyResults Calendar Control
        if (!(today.getDay() === 6)) {
            OKRWeekStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - (today.getDay() + 1));
        }
        dispatch({
            OKRWeekStartDate: OKRWeekStartDate,
            type: ActionType.IDENTITYWEEKLY_OKRS_START_DATE_CHANGE
        });
    },
    ObjectiveKeyResultValueChange: (e, id): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const selectedKeyResults = getState().IdentityWeeklyNewsLetter.selectedKeyResults;
        const keyResult = [];
        let { value, name } = e.target;
        if (value === undefined)
            value = '';
        selectedKeyResults.map((obj: IPropIdentityKeyResults) => {
            if (obj.keyResultID === id) {
                obj[name] = value;
            }
            keyResult.push(obj);
        })
        dispatch({
            selectedKeyResults: keyResult,
            type: ActionType.NEWSLETTER_KEYRESULTS_CONTROLS_CHANGE
        });
    },
    EditObjectiveKeyResultRow: (val: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const selectedKeyResults = getState().IdentityWeeklyNewsLetter.selectedKeyResults;
        const keyResult = [];
        selectedKeyResults.map((obj: IPropIdentityKeyResults) => {
            if (obj.keyResultID === val) {
                obj.isEdit = true;
                obj.keyResultsEdit = obj.keyResults;
                obj.progressValueEdit = obj.progressValue;
                obj.targetValueEdit = obj.targetValue;
            }
            else {
                obj.isEdit = false;
            }
            if (obj.keyResultID > 0)
                keyResult.push(obj);
        })
        dispatch({
            selectedKeyResults: keyResult,
            type: ActionType.NEWSLETTER_KEYRESULTS_CONTROLS_CHANGE
        });
    },
    EnableAddNewforObjectiveKeyResult: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const selectedKeyResults = getState().IdentityWeeklyNewsLetter.selectedKeyResults;
        const selectedObjective = getState().IdentityWeeklyNewsLetter.selectedObjective;
        let OKRWeekStartDate = getState().IdentityWeeklyNewsLetter.OKRWeekStartDate;
        const keyResult = [];
        selectedKeyResults.map((obj: IPropIdentityKeyResults) => {
            if (obj.keyResultID > 0) {
                if (obj.isEdit = true)
                    obj.isEdit = false;
                keyResult.push(obj);
            }
        })
        let NewCOKR = {
            keyResultID: 0,
            keyResultsEdit: '',
            progressValueEdit: '',
            targetValueEdit: '',
            objectiveID: selectedObjective,
            weekDate: OKRWeekStartDate,
            isActive: 1,
            isEdit: true
        }
        keyResult.push(NewCOKR);
        dispatch({
            selectedKeyResults: keyResult,
            type: ActionType.NEWSLETTER_KEYRESULTS_CONTROLS_CHANGE
        });
    },
    cancelEditObjectiveKeyResultRow: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const selectedKeyResults = getState().IdentityWeeklyNewsLetter.selectedKeyResults;
        const keyResult = [];
        selectedKeyResults.map((obj) => {
            obj.isEdit = false;
            obj.keyResultsEdit = obj.keyResults;
            obj.progressValueEdit = obj.progressValue;
            obj.targetValueEdit = obj.targetValue;
            if (obj.keyResultID > 0)
                keyResult.push(obj);
        })
        dispatch({
            selectedKeyResults: keyResult,
            type: ActionType.NEWSLETTER_KEYRESULTS_CONTROLS_CHANGE
        });
    },
    CancelAddNewObjectiveData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            isAddNewObjective: false,
            newObjective: '',
            ObjectiveStartDate: null,
            ObjectiveEndDate: null,
            selectedObjective: 0,
            type: ActionType.NEWSLETTER_OBJECTIVES_CANCEL_CHANGE
        });
    },
    addNewObjective: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const newObjective = event.target.value;
        dispatch({
            newObjective,
            type: ActionType.IDENTITYNEWSLETTER_OBJECTIVE_ADD
        });
    },
    resetOKRsValues: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            OKRWeekStartDate: null,
            selectedKeyResults: [],
            ObjectiveStartDate: null,
            ObjectiveEndDate: null,
            type: ActionType.IDENTITYNEWSLETTER_RESET_OKRS_INPUTS
        });
    }
}

const LoadIdentityNewsletterSev1Sev2Incidents = (data) => {
    var series = "";
    var _series = [];
    var weeks = [];
    var sev1CountData = [];
    var sev2CountData = [];
    if (data.length > 0) {
        let weeksList = data[0].newsletterSev1Sev2WeeksList;
        let sev1List = data[0].newsletterSev1List;
        let sev2list = data[0].newsletterSev2List;
        if (weeksList.length !== 0 && !(isNullOrUndefined(weeksList))) {
            const sortedWeeks = weeksList.sort((a, b) => a.periodOrder - b.periodOrder);
            sortedWeeks.map((obj) => {
                weeks.push(obj.period);
            });
        }
        if (sev1List.length !== 0 && !(isNullOrUndefined(sev1List))) {
            weeks.map((obj) => {
                var ele = sev1List.filter(item => item.weekDateRange.toString() === obj.toString());
                // Pushing Sev1 Count Value as 0 for corresponding week if there is no Data
                if (ele.length === 0) {
                    sev1CountData.push(0);
                }
                else {
                    // If there are multiple Sev1Count Values corresponding to different Services for that week then sum of them is assigned for that week
                    let sev1TotalCount = 0;
                    ele.map((obj) => {
                        sev1TotalCount = sev1TotalCount + obj.sev1Count;
                    });
                    sev1CountData.push(sev1TotalCount);
                }
            });
        }
        if (sev2list.length !== 0 && !(isNullOrUndefined(sev2list))) {
            weeks.map((obj) => {
                var ele = sev2list.filter(item => item.weekDateRange.toString() === obj.toString());
                // Pushing Sev2 Count Value as 0 for corresponding week if there is no Data
                if (ele.length === 0) {
                    sev2CountData.push(0);
                }
                else {
                    // If there are multiple Sev2Count Values corresponding to different Services for that week then sum of them is assigned for that week
                    let sev2TotalCount = 0;
                    ele.map((obj) => {
                        sev2TotalCount = sev2TotalCount + obj.sev2Count;
                    });
                    sev2CountData.push(sev2TotalCount);
                }
            });
        }
    }
    _series.push({
        "name": "Severity 1",
        "data": sev1CountData,
        fill: false,
        color: '#003d99'
    });

    _series.push({
        "name": "Severity 2",
        "data": sev2CountData,
        fill: false,
        color: '#ff8c00'
    });

    const options =
    {
        labels: [],
        title: {
            text: 'Created Incidents End of Week Count(SEV1 and SEV2) (Last 90 Days)'
        },
        xAxis: {
            title: {
                text: "Created Date (End of the Week)"
            },
            categories: weeks
        },
        yAxis: {
            title: {
                text: "Count of Incidents"
            },
            min: 0
        },
        series: _series
    }
    return options;
};

const LoadIdentityNewsletterOutagesServices = (weeksascolumns, weekRanges) => {
    var series = "";
    var _series = [];
    var services = [];
    var weekRange = [];
    var colorArray = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
        '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
        '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
        '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
        '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
        '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
        '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
        '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
        '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
        '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];
    for (let serviceCount = 0; serviceCount < weeksascolumns.length; serviceCount++) {
        var JSON_Obj = weeksascolumns[serviceCount];
        let dataArray = [];
        for (let key in JSON_Obj) {
            if (key != 'serviceName') {
                dataArray.push(JSON_Obj[key]);
            }
        }
        _series.push({
            "name": weeksascolumns[serviceCount].serviceName,
            "data": dataArray,
            type: "column",
            color: (weeksascolumns[serviceCount].serviceName == 'AAD Access Reviews' ? '#FF6633' :
                weeksascolumns[serviceCount].serviceName == 'AAD Connect Health' ? '#FFB399' :
                    weeksascolumns[serviceCount].serviceName == 'AAD First Party Apps' ? '#FF33FF' :
                        weeksascolumns[serviceCount].serviceName == 'AAD Gateway' ? '#FFFF99' :
                            weeksascolumns[serviceCount].serviceName == 'AAD SyncFabric' ? '#00B3E6' :
                                weeksascolumns[serviceCount].serviceName == 'ADRS' ? '#E6B333' :
                                    weeksascolumns[serviceCount].serviceName == 'Azure Key Vault' ? '#3366E6' :
                                        weeksascolumns[serviceCount].serviceName == 'Azure MFA' ? '#999966' :
                                            weeksascolumns[serviceCount].serviceName == 'Azure Privileged Identity Management' ? '#99FF99' :
                                                weeksascolumns[serviceCount].serviceName == 'Cloud Infrastructure Entitlement Management Service (MCIEM)' ? '#B34D4D' :
                                                    weeksascolumns[serviceCount].serviceName == 'Conditional Access UX' ? '#80B300' :
                                                        weeksascolumns[serviceCount].serviceName == 'CPIM' ? '#809900' :
                                                            weeksascolumns[serviceCount].serviceName == 'Credentials Management UX' ? '#E6B3B3' :
                                                                weeksascolumns[serviceCount].serviceName == 'dSMS' ? '#6680B3' :
                                                                    weeksascolumns[serviceCount].serviceName == 'Enterprise App Management' ? '#66991A' :
                                                                        weeksascolumns[serviceCount].serviceName == 'ESTS' ? '#FF99E6' :
                                                                            weeksascolumns[serviceCount].serviceName == 'IAM Services' ? '#CCFF1A' :
                                                                                weeksascolumns[serviceCount].serviceName == 'Identity Fundamentals' ? '#FF1A66' :
                                                                                    weeksascolumns[serviceCount].serviceName == 'IDX' ? '#E6331A' :
                                                                                        weeksascolumns[serviceCount].serviceName == 'Invitation Manager' ? '#33FFCC' :
                                                                                            weeksascolumns[serviceCount].serviceName == 'JIT' ? '#66994D' :
                                                                                                weeksascolumns[serviceCount].serviceName == 'Managed Service Identity' ? '#B366CC' :
                                                                                                    weeksascolumns[serviceCount].serviceName == 'Microsoft Account' ? '#4D8000' :
                                                                                                        weeksascolumns[serviceCount].serviceName == 'Microsoft Graph Service' ? '#B33300' :
                                                                                                            weeksascolumns[serviceCount].serviceName == 'Policy Administration Service' ? '#CC80CC' :
                                                                                                                weeksascolumns[serviceCount].serviceName == 'Microsoft Graph Service' ? '#66664D' :
                                                                                                                    weeksascolumns[serviceCount].serviceName == 'Production Management Identities' ? '#991AFF' :
                                                                                                                        weeksascolumns[serviceCount].serviceName == 'Auth Reach - Hybrid Authentication' ? '#E666FF' :
                                                                                                                            weeksascolumns[serviceCount].serviceName == 'CPIM/ MSI' ? '#4DB3FF' :
                                                                                                                                weeksascolumns[serviceCount].serviceName == 'Identity Health Experiences' ? '#1AB399' :
                                                                                                                                    weeksascolumns[serviceCount].serviceName == 'Protection Signals and Intelligence' ? '#E666B3' :
                                                                                                                                        weeksascolumns[serviceCount].serviceName == 'Protection Signals and Intelligence' ? '#33991A' : '#CC9999')
        });
    }
    for (let count = 0; count < weekRanges.length; count++) {
        weekRange.push(weekRanges[count]);
    }
 const options =
    {
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: weekRange,
            labels: {
                style: {                    
                    fontSize: '15px',
                    fontFamily: 'calibri'
                }
            }
        },
        yAxis: {
            min: 0,
            labels: {
                style: {
                    fontSize: '15px',
                    fontFamily: 'calibri'
                }
            }
     },
     legend: {
         itemStyle: {
             color: '#000000',
             fontWeight: 'bold',
             fontSize: '15px',
             fontFamily: 'calibri'
         }
     },
    plotOptions: {
        series: {
            stacking: 'normal'
        }
    },
        series: _series
    }
    return options;
};

const LoadIdentityNewsletterCommsServices = (weeksascolumns, weekDates) => {
    var series = "";
    var _series = [];
    var services = [];
    var weekRange = [];
    var colorArray = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
        '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
        '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
        '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
        '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
        '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
        '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
        '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
        '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
        '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];

    for (let serviceCount = 0; serviceCount < weeksascolumns.length; serviceCount++) {
        var JSON_Obj = weeksascolumns[serviceCount];
        let dataArray = [];
        for (let key in JSON_Obj) {
            if (key != 'serviceName') {
                dataArray.push(JSON_Obj[key]);
            }
        }
        _series.push({
            "name": weeksascolumns[serviceCount].serviceName,
            "data": dataArray,
            type: "column",
            color: (weeksascolumns[serviceCount].serviceName == 'AAD Access Reviews' ? '#FF6633' :
                weeksascolumns[serviceCount].serviceName == 'AAD Connect Health' ? '#FFB399' :
                    weeksascolumns[serviceCount].serviceName == 'AAD First Party Apps' ? '#FF33FF' :
                        weeksascolumns[serviceCount].serviceName == 'AAD Gateway' ? '#FFFF99' :
                            weeksascolumns[serviceCount].serviceName == 'AAD SyncFabric' ? '#00B3E6' :
                                weeksascolumns[serviceCount].serviceName == 'ADRS' ? '#E6B333' :
                                    weeksascolumns[serviceCount].serviceName == 'Azure Key Vault' ? '#3366E6' :
                                        weeksascolumns[serviceCount].serviceName == 'Azure MFA' ? '#999966' :
                                            weeksascolumns[serviceCount].serviceName == 'Azure Privileged Identity Management' ? '#99FF99' :
                                                weeksascolumns[serviceCount].serviceName == 'Cloud Infrastructure Entitlement Management Service (MCIEM)' ? '#B34D4D' :
                                                    weeksascolumns[serviceCount].serviceName == 'Conditional Access UX' ? '#80B300' :
                                                        weeksascolumns[serviceCount].serviceName == 'CPIM' ? '#809900' :
                                                            weeksascolumns[serviceCount].serviceName == 'Credentials Management UX' ? '#E6B3B3' :
                                                                weeksascolumns[serviceCount].serviceName == 'dSMS' ? '#6680B3' :
                                                                    weeksascolumns[serviceCount].serviceName == 'Enterprise App Management' ? '#66991A' :
                                                                        weeksascolumns[serviceCount].serviceName == 'ESTS' ? '#FF99E6' :
                                                                            weeksascolumns[serviceCount].serviceName == 'IAM Services' ? '#CCFF1A' :
                                                                                weeksascolumns[serviceCount].serviceName == 'Identity Fundamentals' ? '#FF1A66' :
                                                                                    weeksascolumns[serviceCount].serviceName == 'IDX' ? '#E6331A' :
                                                                                        weeksascolumns[serviceCount].serviceName == 'Invitation Manager' ? '#33FFCC' :
                                                                                            weeksascolumns[serviceCount].serviceName == 'JIT' ? '#66994D' :
                                                                                                weeksascolumns[serviceCount].serviceName == 'Managed Service Identity' ? '#B366CC' :
                                                                                                    weeksascolumns[serviceCount].serviceName == 'Microsoft Account' ? '#4D8000' :
                                                                                                        weeksascolumns[serviceCount].serviceName == 'Microsoft Graph Service' ? '#B33300' :
                                                                                                            weeksascolumns[serviceCount].serviceName == 'Policy Administration Service' ? '#CC80CC' :
                                                                                                                weeksascolumns[serviceCount].serviceName == 'Microsoft Graph Service' ? '#66664D' :
                                                                                                                    weeksascolumns[serviceCount].serviceName == 'Production Management Identities' ? '#991AFF' :
                                                                                                                        weeksascolumns[serviceCount].serviceName == 'Auth Reach - Hybrid Authentication' ? '#E666FF' :
                                                                                                                            weeksascolumns[serviceCount].serviceName == 'CPIM/ MSI' ? '#4DB3FF' :
                                                                                                                                weeksascolumns[serviceCount].serviceName == 'Identity Health Experiences' ? '#1AB399' :
                                                                                                                                    weeksascolumns[serviceCount].serviceName == 'Protection Signals and Intelligence' ? '#E666B3' :
                                                                                                                                        weeksascolumns[serviceCount].serviceName == 'Protection Signals and Intelligence' ? '#33991A' : '#CC9999')
        });
       
    }
    for (let count = 0; count < weekDates.length; count++) {
        weekRange.push(weekDates[count]);
    }
    const options =
    {
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: weekRange,
             labels: {
                style: {
                     fontSize: '15px',
                     fontFamily: 'calibri'
                }
            }
        },
        yAxis: {
            min: 0,
            labels: {
                style: {
                    fontSize: '15px',
                    fontFamily: 'calibri'
                }
            }
        },
        legend: {
            itemStyle: {
                color: '#000000',
                fontWeight: 'bold',
                fontSize: '15px',
                fontFamily: 'calibri'
            }
        },
        plotOptions: {
            series: {
                stacking: 'normal'
            }
        },
        series: _series
    }
    return options;
};

const LoadIdentityNewsletterSafeflyServices = (data) => {
    var series = "";
    var _series = [];
    var services = [];
    var newd = [];
    var pending = [];
    var edApproved = [];
    var deployed = [];
    var completed = [];
    var approved = [];
    var abandoned = [];
    var buddyrejected = [];
    var serviceManagerApprovedWithSuggestion = [];
    var completedWithSuggestion = [];
    var serviceManagerApproved = [];
    var draft = [];
    for (let counter = 0; counter < data.length; counter++) {
        services.push(data[counter].ServiceName);
       
        let newData = (data[counter].New !== undefined && data[counter].New !== null && data[counter].New !== "") ? parseInt(data[counter].New) : 0;
        let pendingData = (data[counter].Pending !== undefined && data[counter].Pending !== null && data[counter].Pending !== "") ? parseInt(data[counter].Pending) : 0;
        let EdApprovedData = (data[counter].EdApproved !== undefined && data[counter].EdApproved !== null && data[counter].EdApproved !== "") ? parseInt(data[counter].EdApproved) : 0;
        let Deployed = (data[counter].Deployed !== undefined && data[counter].Deployed !== null && data[counter].Deployed !== "") ? parseInt(data[counter].Deployed) : 0;
        let Completed = (data[counter].Completed !== undefined && data[counter].Completed !== null && data[counter].Completed !== "") ? parseInt(data[counter].Completed) : 0;
        let approvedData = (data[counter].Approved !== undefined && data[counter].Approved !== null && data[counter].Approved !== "") ? parseInt(data[counter].Approved) : 0;
        let abandonedData = (data[counter].Abandoned !== undefined && data[counter].Abandoned !== null && data[counter].Abandoned !== "") ? parseInt(data[counter].Abandoned) : 0;
        let EdRejected = (data[counter].EdRejected !== undefined && data[counter].EdRejected !== null && data[counter].EdRejected !== "") ? parseInt(data[counter].EdRejected) : 0;
        let ServiceMgrApprovedWithSuggestion = (data[counter].ServiceManagerApprovedWithSuggestion !== undefined && data[counter].ServiceManagerApprovedWithSuggestion !== null && data[counter].ServiceManagerApprovedWithSuggestion !== "") ? parseInt(data[counter].ServiceManagerApprovedWithSuggestion) : 0;
        let CompletedWithSuggestion = (data[counter].CompletedWithSuggestion !== undefined && data[counter].CompletedWithSuggestion !== null && data[counter].CompletedWithSuggestion !== "") ? parseInt(data[counter].CompletedWithSuggestion) : 0;
        let ServiceMgrApproved = (data[counter].ServiceManagerApproved !== undefined && data[counter].ServiceManagerApproved !== null && data[counter].ServiceManagerApproved !== "") ? parseInt(data[counter].ServiceManagerApproved) : 0;
        let DraftData = (data[counter].Draft !== undefined && data[counter].Draft !== null && data[counter].Draft !== "") ? parseInt(data[counter].Draft) : 0;
        newd.push(newData);
        pending.push(pendingData);
        edApproved.push(EdApprovedData);
        deployed.push(Deployed);
        completed.push(Completed);
        approved.push(approvedData);
        abandoned.push(abandonedData);
        buddyrejected.push(EdRejected);
        serviceManagerApprovedWithSuggestion.push(ServiceMgrApprovedWithSuggestion);
        completedWithSuggestion.push(CompletedWithSuggestion);
        serviceManagerApproved.push(ServiceMgrApproved);
        draft.push(DraftData);
    }
    _series.push({
        "name": "Draft",
        "data": draft,
        type: "column",
        color: "#dcbdca"
    });
    _series.push({
        "name": "New",
        "data": newd,
        type: "column",
        color: "#a4c639"
    });
    _series.push({
        "name": "Pending",
        "data": pending,
        type: "column",
        color: "#2a52be"
    });
    _series.push({
        "name": "EdApproved",
        "data": edApproved,
        type: "column",
        color: "#9bddff"
    });
    _series.push({
        "name": "Deployed",
        "data": deployed,
        type: "column",
        color: "#ffe135"
    });
    _series.push({
        "name": "Completed",
        "data": completed,
        type: "column",
        color: "#8c92ac"
    });
    _series.push({
        "name": "Approved",
        "data": approved,
        type: "column",
        color: "#ed872d"
    });
    _series.push({
        "name": "Abandoned",
        "data": abandoned,
        type: "column",
        color: "#4997d0"
    });
    _series.push({
        "name": "Buddy Rejected",
        "data": buddyrejected,
        type: "column",
        color: "#b31b1b"
    });
    _series.push({
        "name": "ServiceManagerApproved",
        "data": serviceManagerApproved,
        type: "column",
        color: "#d2c289"
    });
    _series.push({
        "name": "ServiceManagerApprovedWithSuggestion",
        "data": serviceManagerApprovedWithSuggestion,
        type: "column",
        color: "#ecb57f"
    });
    _series.push({
        "name": "CompletedWithSuggestion",
        "data": completedWithSuggestion,
        type: "column",
        color: "#7fecb5"
    });
    const options =
    {
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: services,
            labels: {
                style: {
                    fontSize: '15px',
                    fontFamily: 'calibri'
                }
            }
        },
        yAxis: {
            min: 0,
            labels: {
                style: {
                    fontSize: '15px',
                    fontFamily: 'calibri'
                }
            }
        },        
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle',
            itemMarginTop: 10,
            itemMarginBottom: 10,
            itemStyle: {
                color: '#000000',
                fontWeight: 'bold',
                fontSize: '15px',
                fontFamily: 'calibri'
            }
        },
        plotOptions: {
            series: {
                stacking: 'normal'
            }
        },
        series: _series
    }
    return options;
}

const LoadIdentityNewsletterChangeToolServices = (data) => {
    var series = "";
    var _series = [];
    var services = [];
    var rejected = [];
    var readyforreviewd = [];
    var completed = [];
    var approved = [];
    var abandoned = [];
    var pending = [];
    var newd = [];
    var deployed = [];
    var serviceManagerApproved = [];
    var serviceManagerApprovedWithSuggestion = [];
    var completedWithSuggestion = [];
    var draft = [];

    for (let counter = 0; counter < data.length; counter++) {
        services.push(data[counter].Service);

        let Rejected = (data[counter].Rejected !== undefined && data[counter].Rejected !== null && data[counter].Rejected !== "") ? parseInt(data[counter].Rejected) : 0;
        let ReadyforReview = (data[counter].ReadyforReview !== undefined && data[counter].ReadyforReview !== null && data[counter].ReadyforReview !== "") ? parseInt(data[counter].ReadyforReview) : 0;
        let Complete = (data[counter].Complete !== undefined && data[counter].Complete !== null && data[counter].Complete !== "") ? parseInt(data[counter].Complete) : 0;
        let Approved = (data[counter].Approved !== undefined && data[counter].Approved !== null && data[counter].Approved !== "") ? parseInt(data[counter].Approved) : 0;
        let Abandoned = (data[counter].Abandoned !== undefined && data[counter].Abandoned !== null && data[counter].Abandoned !== "") ? parseInt(data[counter].Abandoned) : 0;
        let pendingData = (data[counter].Pending !== undefined && data[counter].Pending !== null && data[counter].Pending !== "") ? parseInt(data[counter].Pending) : 0;
        let ServiceMgrApproved = (data[counter].ServiceManagerApproved !== undefined && data[counter].ServiceManagerApproved !== null && data[counter].ServiceManagerApproved !== "") ? parseInt(data[counter].ServiceManagerApproved) : 0;
        let newData = (data[counter].New !== undefined && data[counter].New !== null && data[counter].New !== "") ? parseInt(data[counter].New) : 0;
        let DraftData = (data[counter].Draft !== undefined && data[counter].Draft !== null && data[counter].Draft !== "") ? parseInt(data[counter].Draft) : 0;
        let ServiceMgrApprovedWithSuggestion = (data[counter].ServiceManagerApprovedWithSuggestion !== undefined && data[counter].ServiceManagerApprovedWithSuggestion !== null && data[counter].ServiceManagerApprovedWithSuggestion !== "") ? parseInt(data[counter].ServiceManagerApprovedWithSuggestion) : 0;
        let CompletedWithSuggestion = (data[counter].CompletedWithSuggestion !== undefined && data[counter].CompletedWithSuggestion !== null && data[counter].CompletedWithSuggestion !== "") ? parseInt(data[counter].CompletedWithSuggestion) : 0;
        let Deployed = (data[counter].Deployed !== undefined && data[counter].Deployed !== null && data[counter].Deployed !== "") ? parseInt(data[counter].Deployed) : 0;

        rejected.push(Rejected);
        readyforreviewd.push(ReadyforReview);
        completed.push(Complete);
        approved.push(Approved);
        abandoned.push(Abandoned);
        pending.push(pendingData);
        newd.push(newData);
        serviceManagerApproved.push(ServiceMgrApproved);
        serviceManagerApprovedWithSuggestion.push(ServiceMgrApprovedWithSuggestion);
        completedWithSuggestion.push(CompletedWithSuggestion);
        draft.push(DraftData);
        deployed.push(Deployed);
    }
    _series.push({
        "name": "Draft",
        "data": draft,
        type: "column",
        color: "#dcbdca"
    });
    _series.push({
        "name": "New",
        "data": newd,
        type: "column",
        color: "#a4c639"
    });
    _series.push({
        "name": "Pending",
        "data": pending,
        type: "column",
        color: "#2a52be"
    });
    _series.push({
        "name": "Rejected",
        "data": rejected,
        type: "column",
        color: "#c37fe6"
    });
    _series.push({
        "name": "Ready for Review",
        "data": readyforreviewd,
        type: "column",
        color: "lightgrey"
    });

    _series.push({
        "name": "Complete",
        "data": completed,
        type: "column",
        color: "#c7e5d2"
    });
    _series.push({
        "name": "Approved",
        "data": approved,
        type: "column",
        color: "#3a92bb"
    });
    _series.push({
        "name": "Abandoned",
        "data": abandoned,
        type: "column",
        color: "#eded94"
    });
    _series.push({
        "name": "ServiceManagerApproved",
        "data": serviceManagerApproved,
        type: "column",
        color: "#d2c289"
    });
    _series.push({
        "name": "Deployed",
        "data": deployed,
        type: "column",
        color: "#ffe135"
    });
    _series.push({
        "name": "ServiceManagerApprovedWithSuggestion",
        "data": serviceManagerApprovedWithSuggestion,
        type: "column",
        color: "#ecb57f"
    });
    _series.push({
        "name": "CompletedWithSuggestion",
        "data": completedWithSuggestion,
        type: "column",
        color: "#7fecb5"
    });
    const options =
    {
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        xAxis: {
            title: {
                text: "Services"
            },
            categories: services,
            labels: {
                style: {
                    fontSize: '15px',
                    fontFamily: 'calibri'
                }
            }
        },
        yAxis: {
            min: 0,
            labels: {
                style: {
                    fontSize: '15px',
                    fontFamily: 'calibri'
                }
            }
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle',
            itemMarginTop: 10,
            itemMarginBottom: 10,
            itemStyle: {
                color: '#000000',
                fontWeight: 'bold',
                fontSize: '15px',
                fontFamily: 'calibri'
            }
        },
        plotOptions: {
            series: {
                stacking: 'normal'
            }
        },
        series: _series
    }
    return options;
}

const LoadIdentityNewsletterChangeExceptionServices = (data) => {
    var _series = [];
    var temp = [];
    for (let counter = 0; counter < data.length; counter++) {
        temp.push([data[counter].serviceName, data[counter].exceptionCount]);
    }

    _series.push({
        "name": "",
        "data": temp
    });

    const options =
    {
        chart: {
            type: 'column'
        },
        title: {
            text: 'Change Exceptions'
        },
        xAxis: {
            type: 'category',
            labels: {
                rotation: -45,
                style: {
                    fontSize: '13px',
                    fontFamily: 'Verdana, sans-serif'
                }
            }
        },
        yAxis: {
            min: 0,
            labels: {
                style: {
                    fontSize: '15px',
                    fontFamily: 'calibri'
                }
            }
        },
        legend: {
            enabled: false
        },
        tooltip: {
            pointFormat: '<b>{point.y}</b>'
        },
        series: _series
    }

    return options;
};

// function to call requestIdentityNewsLetterData action method
const callrequestIdentityNewsLetterData = (dispatch, WeekStartDate) => {
    // To reload the Top Outages Data once data is deleted succesfully
    dispatch(actionCreators.requestIdentityNewsLetterData(9, moment(WeekStartDate).format('MM/DD/YYYY')));
}


