import React, { useState, Fragment, useRef } from 'react';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import { IPropOnCallDashboardChecklist, OCEView } from '../../../store/Checklist';
import { FontAwesomeIconMemo, Checkbox } from '../../../components';
import { Button, Accordion } from 'react-bootstrap';
import { toast } from "react-toastify";
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListAlt, faQuestionCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import {
    Card, Row, Col, Container, CardBody
} from 'reactstrap';
type OnCallChecklistTableProps = {
    serviceId: any;
    checklistID: any;
    allServicesdata: any;
    checklists: any;
    fromDate: any;
    toDate: any;
    mainIncidentIdFilter: any;
    mainIncidentIdFilterList: any;
    searchText: any;
    createdby: any;
    submittedbylist: any;
    GetcheckList: any;
    CheckListchange: any;
    onfromDateChange: any;
    ontoDateChange: any;
    ChangedSearchText: any;
    GetOCEList: any;
    ClearFilter: any;
    IncidentIdchange: any;
    submittedbyChange: any;
    sortOCEList: any;
    sortColumn: any;
    sortOrder: any;
    oceList: any;
    LoginuserId: any;
    DeleteChecklist: any;
    isAdmin: any;
    templateType: any;
    TemplateTypechange: any;
};
const OnCallChecklistTable = React.memo<OnCallChecklistTableProps>(({
    serviceId,
    checklistID,
    allServicesdata,
    checklists,
    fromDate,
    toDate,
    mainIncidentIdFilter,
    mainIncidentIdFilterList,
    searchText,
    createdby,
    submittedbylist,
    GetcheckList,
    CheckListchange,
    onfromDateChange,
    ontoDateChange,
    ChangedSearchText,
    GetOCEList,
    ClearFilter,
    IncidentIdchange,
    submittedbyChange,
    sortOCEList,
    sortColumn,
    sortOrder,
    oceList,
    LoginuserId,
    DeleteChecklist,
    isAdmin,
    templateType,
    TemplateTypechange
}) => {
    const ChecklistGuideURL = "https://identitydivision.visualstudio.com/IdentityWiki/_wiki/wikis/IdentityWiki.wiki/16549/Checklist-UserGuide";

    const templateTypes = [{ name: 'Select Template Type', value: '' },
                           { name: 'All', value: 'All' },
                           { name: 'Checklist Only', value: 'Checklist Only' },
                           { name: 'Incident Investigation', value: 'Incident Investigation' }];

    const filterBasedSearch = (item) => {
        if (item.serviceName.toLowerCase().includes(searchText.toLowerCase())
            || item.checkListName.toLowerCase().includes(searchText.toLowerCase())
            || item.templateName.toLowerCase().includes(searchText.toLowerCase())
            || item.icmMainIncident.toLowerCase().includes(searchText.toLowerCase())
            || item.meetingTitle.toLowerCase().includes(searchText.toLowerCase())
            || item.onCallDate.toLowerCase().includes(searchText.toLowerCase())
            || item.onCallStatus.includes(searchText.toLowerCase())
            || item.onCallCreatedDate.toLowerCase().includes(searchText.toLowerCase())
            || item.onCallCreatedBy.toLowerCase().includes(searchText.toLowerCase())
            || item.onCallLastUpdatedDate.toLowerCase().includes(searchText.toLowerCase())
            || item.onCallLastUpdatedBy.toLowerCase().includes(searchText.toLowerCase())) {
            return true
        }
        else {
            return false;
        }
    };

    const movescrollbar = () => {
        window.scrollTo(0, 0);

    }

    //Pop out URL in new tab; for jarvis, kusto and data URLs
    const openUrlInNewTab = (url) => {
        if (url !== '') {
            window.open(url, "_blank");
        }
    }

    const displayToasterError = () => {
        if (serviceId === 0 || serviceId === undefined) {
            toast.error(renderToastifyMsg('Please select the Service Name to create new Checklist.',
                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
        }
        else if (checklistID === 0 || checklistID === undefined) {
            toast.error(renderToastifyMsg('Please select the List Name to create new Checklist.',
                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
        }
    }

    return (
        <div>
            <Card className="cardstyle">
                <CardBody className="cardbodyhederstyle">
                    <Row>
                        <Col lg="4"></Col>
                        <Col lg="4" style={{ textAlign: 'center' }}>
                            <div>
                                <h3 className="headertext">CheckList Dashboard</h3>
                            </div>
                        </Col>
                        <Col lg="4" style={{ textAlign: 'right' }}>
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" />&nbsp;
                                <InputText style={{ width: '340px', verticalAlign: 'top' }} type="search" placeholder="Global Search" value={searchText} onChange={(e) => ChangedSearchText(e)} />&ensp;&ensp;&ensp;                               
                            </span>                           
                            </Col>

                    </Row>
                </CardBody>
            </Card>
            <br />
            <div>
                <table id="tablefilter" className="checklisttable" style={{ width: '95vw', color: '#148', textAlign: 'center' }}>
                <tbody>
                    <tr>
                        <td style={{ textAlign: 'center' }}>
                            <label className="lblheader" id="Service">Service Name : </label>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <label className="lblheader" id="CheckList">List Name: </label>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <label className="lblheader" id="TemplateName">Template Type : </label>
                        </td>
                        <td style={{ paddingLeft: '5vw' }}>
                            <label className="lblheader" id="FromDate">From Date : </label>
                        </td>
                        <td style={{ paddingLeft: '5vw' }}>
                            <label className="lblheader" id="ToDate">To Date : </label>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <label className="lblheader" id="IncidentId">Main Incident ID: </label>
                        </td>                       
                        <td style={{ textAlign: 'center' }}>
                        <label className="lblheader" >Updated By : </label>
                        </td>
                        <td>
                            {
                                (serviceId === 0 || checklistID === 0 || serviceId === undefined || checklistID === undefined) ?
                                    <Button className='OncallChecklistbutton' style={{ width: "100px!important", borderRadius: "8px" }} onClick={displayToasterError} ><FontAwesomeIconMemo title='New' icon={'plus'} /> &ensp;New</Button>
                                    : <Link className='OncallChecklistbutton btn btn-primary' style={{ width: "100px!important", borderRadius: "8px", cursor: 'pointer' }}
                                        to={"/UIforOCEView?SId=" + serviceId + "&CId=" + checklistID + "&IsNew=true"}>
                                        <FontAwesomeIconMemo icon={'plus'} title='New' />&ensp;New
                            </Link>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>
                                <Dropdown className="sereviceNamedropdownchecklist" style={{ width: '200px' }} value={serviceId} options={allServicesdata} onChange={(e) => GetcheckList(e.value)} optionLabel="name" />
                        </td>
                        <td>
                            <Dropdown className="serevicedropdownchecklist" style={{ width: '200px' }} value={checklistID} options={checklists} onChange={(e) => CheckListchange(e.value)} optionLabel="name" />
                        </td>
                        <td>
                            <Dropdown className="serevicedropdownchecklist" style={{ width: '200px' }} value={templateType} options={templateTypes} onChange={(e) => TemplateTypechange(e.value)} optionLabel="name" />
                        </td>
                            <td style={{ textAlign: 'left', width: '15vw' }}>
                            <Calendar readOnlyInput showIcon value={fromDate} onChange={(e) => onfromDateChange(e.value)}></Calendar>
                        </td>

                        <td style={{ textAlign: 'left', width: '15vw' }}>
                            <Calendar readOnlyInput showIcon value={toDate} onChange={(e) => ontoDateChange(e.value)}></Calendar>
                        </td>
                        <td>
                            <Dropdown style={{ width: '135px' }} value={mainIncidentIdFilter} options={mainIncidentIdFilterList} onChange={(e) => IncidentIdchange(e.value)} optionLabel="name" />
                        </td>                       
                        <td style={{ textAlign: 'left' }}>
                            <Dropdown style={{ width: '135px' }} value={createdby} options={submittedbylist} onChange={(e) => submittedbyChange(e.value)} optionLabel="name" />
                        </td>
                        <td>
                            <Button className='OncallChecklistbutton' style={{ width: "100px!important", display: "list-item", marginBottom: '5px' }} onClick={GetOCEList} ><FontAwesomeIconMemo title='Filter' icon={'filter'} /> Filter</Button>
                            <Button className='OncallChecklistbutton' style={{ width: "100px!important", display: "list-item" }} onClick={ClearFilter} >Clear Filters</Button>
                        </td>
                    </tr>
                </tbody>
                </table>
            </div>
            <div className="row-bottom-margin">
                <Row>
                    <Col xs='7'>
                    </Col>
                    <Col xs='5' style={{ textAlign: 'right' }}>
                        <React.Fragment><span className='ConfigGuide' onClick={e => openUrlInNewTab(ChecklistGuideURL)}>
                            CheckList User Guide&nbsp;
                            <FontAwesomeIcon style={{ cursor: 'pointer', fontWeight: 'bolder', fontSize: '15px', verticalAlign: 'middle' }} onClick={e => openUrlInNewTab(ChecklistGuideURL)} title='CheckList User Guide' className='icon-format-config' icon={faQuestionCircle} />
                        </span></React.Fragment>
                    </Col>
                </Row>
            </div>
            <div style={{ width: '100vw'}}>
                <table className="table-bordered table-hover" id="oncalldashboard" style={{ alignSelf: 'center', width: '95vw' }}>
                    <thead>
                        <tr>
                            <th className="thSort thstyleOnCall" onClick={e => sortOCEList(e, 'serviceName')}>
                                Service Name
                            <div style={{ float: 'right' }}>
                                    <div className={`iconSort ${sortColumn == 'serviceName' && !sortOrder ? 'active' : ''}`}>&#x25B2;</div>
                                    <div className={`iconSort ${sortColumn == 'serviceName' && sortOrder ? 'active' : ''}`}>&#x25BC;</div>                                   
                                </div>
                            </th>
                            <th className="thSort thstyleOnCall" onClick={e => sortOCEList(e, 'checkListName')}>
                                List Name
                            <div style={{ float: 'right' }}>
                                    <div className={`iconSort ${sortColumn == 'checkListName' && !sortOrder ? 'active' : ''}`}>&#x25B2;</div>
                                    <div className={`iconSort ${sortColumn == 'checkListName' && sortOrder ? 'active' : ''}`}>&#x25BC;</div>                                    
                                </div>
                            </th>
                            <th className="thSort thstyleOnCall" onClick={e => sortOCEList(e, 'templateName')}>
                                Template Name
                            <div style={{ float: 'right' }}>
                                    <div className={`iconSort ${sortColumn == 'templateName' && !sortOrder ? 'active' : ''}`}>&#x25B2;</div>
                                    <div className={`iconSort ${sortColumn == 'templateName' && sortOrder ? 'active' : ''}`}>&#x25BC;</div>
                                </div>
                            </th>
                            <th className="thSort thstyleOnCall" onClick={e => sortOCEList(e, 'templateRole')}>
                                Template Role
                                <div style={{ float: 'right' }}>
                                    <div className={`iconSort ${sortColumn == 'templateRole' && !sortOrder ? 'active' : ''}`}>&#x25B2;</div>
                                    <div className={`iconSort ${sortColumn == 'templateRole' && sortOrder ? 'active' : ''}`}>&#x25BC;</div>
                                </div>
                            </th>
                            <th className="thSort thstyleOnCall" onClick={e => sortOCEList(e, 'icmMainIncident')}>
                                ICM Link
                            <div style={{ float: 'right' }}>
                                    <div className={`iconSort ${sortColumn == 'icmMainIncident' && !sortOrder ? 'active' : ''}`}>&#x25B2;</div>
                                    <div className={`iconSort ${sortColumn == 'icmMainIncident' && sortOrder ? 'active' : ''}`}>&#x25BC;</div>                                   
                                </div>
                            </th>
                            <th className="thSort thstyleOnCall" onClick={e => sortOCEList(e, 'meetingTitle')}>
                                Meeting Title
                            <div style={{ float: 'right' }}>
                                    <div className={`iconSort ${sortColumn == 'meetingTitle' && !sortOrder ? 'active' : ''}`}>&#x25B2;</div>
                                    <div className={`iconSort ${sortColumn == 'meetingTitle' && sortOrder ? 'active' : ''}`}>&#x25BC;</div>                                    
                                </div>
                            </th>
                            <th className="thSort thstyleOnCall" onClick={e => sortOCEList(e, 'onCallDateForSort')}>
                                OnCall Date
                            <div style={{ float: 'right' }}>
                                    <div className={`iconSort ${sortColumn == 'onCallDateForSort' && !sortOrder ? 'active' : ''}`}>&#x25B2;</div>
                                    <div className={`iconSort ${sortColumn == 'onCallDateForSort' && sortOrder ? 'active' : ''}`}>&#x25BC;</div>                                   
                                </div>
                            </th>
                            <th className="thSort thstyleOnCall" onClick={e => sortOCEList(e, 'onCallStatus')}>
                                Checklist Status
                            <div style={{ float: 'right' }}>
                                    <div className={`iconSort ${sortColumn == 'onCallStatus' && !sortOrder ? 'active' : ''}`}>&#x25B2;</div>
                                    <div className={`iconSort ${sortColumn == 'onCallStatus' && sortOrder ? 'active' : ''}`}>&#x25BC;</div>                                   
                                </div>
                            </th>
                            <th className="thSort thstyleOnCall" onClick={e => sortOCEList(e, 'onCallCreatedDate')}>
                                Created Date
                            <div style={{ float: 'right' }}>
                                    <div className={`iconSort ${sortColumn == 'onCallCreatedDate' && !sortOrder ? 'active' : ''}`}>&#x25B2;</div>
                                    <div className={`iconSort ${sortColumn == 'onCallCreatedDate' && sortOrder ? 'active' : ''}`}>&#x25BC;</div>
                                </div>
                            </th>
                            <th className="thSort thstyleOnCall" onClick={e => sortOCEList(e, 'onCallCreatedBy')}>
                                Created By
                            <div style={{ float: 'right' }}>
                                    <div className={`iconSort ${sortColumn == 'onCallCreatedBy' && !sortOrder ? 'active' : ''}`}>&#x25B2;</div>
                                    <div className={`iconSort ${sortColumn == 'onCallCreatedBy' && sortOrder ? 'active' : ''}`}>&#x25BC;</div>
                                </div>
                            </th>
                            <th className="thSort thstyleOnCall" onClick={e => sortOCEList(e, 'onCallLastUpdatedDate')}>
                                Updated Date
                            <div style={{ float: 'right' }}>
                                    <div className={`iconSort ${sortColumn == 'onCallLastUpdatedDate' && !sortOrder ? 'active' : ''}`}>&#x25B2;</div>
                                    <div className={`iconSort ${sortColumn == 'onCallLastUpdatedDate' && sortOrder ? 'active' : ''}`}>&#x25BC;</div>
                                </div>
                            </th>
                            <th className="thSort thstyleOnCall" onClick={e => sortOCEList(e, 'onCallLastUpdatedBy')}>
                                Updated By
                            <div style={{ float: 'right' }}>
                                    <div className={`iconSort ${sortColumn == 'onCallLastUpdatedBy' && !sortOrder ? 'active' : ''}`}>&#x25B2;</div>
                                    <div className={`iconSort ${sortColumn == 'onCallLastUpdatedBy' && sortOrder ? 'active' : ''}`}>&#x25BC;</div>
                                </div>
                            </th>
                            <th className="thstyleOnCall" style={{width: '100px'}} >Modify</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            isArrayWithLength(oceList) ?
                                <React.Fragment>
                                    {isArrayWithLength(oceList) ?
                                        oceList
                                            .filter((item: OCEView) => {
                                                if (filterBasedSearch(item)) {
                                                    return true
                                                }
                                            })
                                            .map((row: OCEView, index) => (
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <td style={{ minHeight: '40px', color: '#212529' }} className="textalign_wordwrap">{row.serviceName}</td>
                                                        <td style={{ color: '#212529' }} className="textalign_wordwrap">{row.checkListName}</td>
                                                        <td style={{ color: '#212529' }} className="textalign_wordwrap">{row.templateName}</td>
                                                        <td style={{ color: '#212529' }} className="textalign_wordwrap">{row.templateRole}</td>
                                                        <td className="textalignleft">
                                                            <span>
                                                                <a href={"https://portal.microsofticm.com/imp/v3/incidents/details/" + row.icmMainIncident + "/home"} target="_blank"> {row.icmMainIncident}</a>
                                                            </span>
                                                        </td>
                                                        <td className="textalignleft">{row.meetingTitle}</td>
                                                        <td className="textalignleft">{row.onCallDate}</td>
                                                        <td className="textalignleft">{row.onCallStatus}</td>
                                                        <td className="textalignleft">{row.onCallCreatedDate}</td>
                                                        <td className="textalignleft">{row.onCallCreatedBy}</td>
                                                        <td className="textalignleft">{row.onCallLastUpdatedDate}</td>
                                                        <td className="textalignleft">{row.onCallLastUpdatedBy}</td>
                                                        <td style={{ paddingLeft: '22px', width: '100px' }}>
                                                            {((row.createdBy === LoginuserId) || (isAdmin === true)) ?
                                                                <Link onClick={movescrollbar} style={{ cursor: 'pointer' }} title='Modify Checklist'
                                                                    to={"/UIforOCEView?SId=" + row.serviceId + "&CId=" + row.checkListId + "&Md=" + row.meetingDate + "&OnCalld=" + row.onCallDate + "&meetingId=" + row.meetingId + "&meetingTitle=" + row.meetingTitle + "&icmMainIncident=" + row.icmMainIncident + "&IsNew=false"}>
                                                                <FontAwesomeIconMemo icon={'edit'} style={{ color: '#337ab7' }} />
                                                                </Link>
                                                                : <React.Fragment></React.Fragment>
                                                            }
                                                            &nbsp;&nbsp;<Link onClick={movescrollbar} style={{ cursor: 'pointer' }} title='View Checklist Data'
                                                                to={"/UIforReadOnlyOCEView?SId=" + row.serviceId + "&CId=" + row.checkListId + "&Md=" + row.meetingDate + "&OnCalld=" + row.onCallDate + "&meetingId=" + row.meetingId + "&meetingTitle=" + row.meetingTitle + "&icmMainIncident=" + row.icmMainIncident + "&IsNew=false" + "&updatedBy=" + row.onCallLastUpdatedBy}>
                                                                <FontAwesomeIcon style={{ cursor: 'pointer', color: '#337ab7' }} icon={faListAlt} />
                                                            </Link>
                                                            &ensp;&nbsp;
                                                            {((row.createdBy === LoginuserId) || (isAdmin === true)) ?
                                                                <FontAwesomeIcon style={{ cursor: 'pointer', color: '#337ab7' }} onClick={() => DeleteChecklist(row)} title='Delete Checklist' icon={faTrashAlt} />
                                                                : <React.Fragment></React.Fragment>
                                                            }
                                                            &nbsp;
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))
                                        : <React.Fragment></React.Fragment>
                                    }

                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <tr>
                                        <td colSpan={10} style={{ textAlign: 'center' }} >
                                            No Records Found
                                        </td>
                                    </tr>
                                </React.Fragment>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
});

OnCallChecklistTable.displayName = 'OnCallChecklistTable';
export default OnCallChecklistTable;