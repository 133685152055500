import { BaseService } from './base.service';
import { IPropidentityNewsLetterGroupData, IPropIdentityWeeklyAvailability, IPropIdentityTopOutages, IPropIdentityObjectives, IPropIdentityOKRs, IPropIdentityTopOutagesTable, IPropIdentitySev12Table } from '../store/IdentityWeeklyNewsLetter';
import { IPropIdentityKeyResults } from '../store/DivisionalOKRs';

/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class IdentityWeeklyNewsLetterService extends BaseService {
    private static _identityweeklynewsletterService: IdentityWeeklyNewsLetterService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): IdentityWeeklyNewsLetterService {
        return (this._identityweeklynewsletterService || (this._identityweeklynewsletterService = new this('IdentityWeeklyNewsLetter')));
    }

    public async GetIdentityWeeklyNewsLetterGroups(weekStartDate: string): Promise<IPropidentityNewsLetterGroupData[]> {
        const { data } = await this.$http.get<IPropidentityNewsLetterGroupData[]>(`GetIdentityWeeklyNewsLetterGroups?weekStartDate=${weekStartDate}`);
        return data;
    }

    public async GetIdentityWeeklyAvailabilityData(weekStartDate: string): Promise<IPropIdentityWeeklyAvailability[]> {
        const { data } = await this.$http.get<IPropIdentityWeeklyAvailability[]>(`GetIdentityWeeklyAvailabilityData?weekStartDate=${weekStartDate}`);
        return data;
    }

    public async GetIdentityNewsletterSafeflyMetrics(StartDate: string, EndDate: string): Promise<object[]> {
        const { data } = await this.$http.get<object[]>(`GetIdentityNewsletterSafeflyMetrics?StartDate=${StartDate}&EndDate=${EndDate}`);
        return data;
    }
    public async GetIdentityNewsletterChangeToolMetrics(StartDate: string, EndDate: string): Promise<object[]> {
        const { data } = await this.$http.get<object[]>(`GetIdentityNewsletterChangeToolMetrics?StartDate=${StartDate}&EndDate=${EndDate}`);
        return data;
    }
    public async GetIdentityNewsletterChangeExceptionMetrics(StartDate: string, EndDate: string): Promise<object[]> {
        const { data } = await this.$http.get<object[]>(`GetIdentityNewsletterChangeExceptionMetrics?StartDate=${StartDate}&EndDate=${EndDate}`);
        return data;
    }
    public async GetIdentityNewsletterObjectivesData(): Promise<IPropIdentityObjectives[]> {
        const { data } = await this.$http.get<IPropIdentityObjectives[]>(`GetIdentityNewsletterObjectivesData`);
        return data;
    }
    public async GetIdentityNewsletterOKRsData(weekStartDate: string): Promise<IPropIdentityOKRs[]> {
        const { data } = await this.$http.get<IPropIdentityOKRs[]>(`GetIdentityNewsletterOKRsData?weekStartDate=${weekStartDate}`);
        return data;
    }
    public async GetIdentityNewsletterTopOutages(weekStartDate: string): Promise<IPropIdentityTopOutages[]> {
        const { data } = await this.$http.get<IPropIdentityTopOutages[]>(`GetIdentityNewsletterTopOutages?weekStartDate=${weekStartDate}`);
        return data;
    }

    public async GetIdentityNewsletterTopOutagesTable(weekStartDate: string): Promise<IPropIdentityTopOutagesTable[]> {
        const { data } = await this.$http.get<IPropIdentityTopOutagesTable[]>(`GetIdentityNewsletterTopOutagesTable?weekStartDate=${weekStartDate}`);
        return data;
    }

    public async GetIdentityNewsletterSev1Sev2IncidentsFromKusto(weekStartDate: string): Promise<IPropIdentitySev12Table[]> {
        const { data } = await this.$http.get<IPropIdentitySev12Table[]>(`GetIdentityNewsletterSev1Sev2IncidentsFromKusto?weekStartDate=${weekStartDate}`);
        return data;
    }

    public async GetIdentityNewsletterSev1Sev2Incidents(weekStartDate: string): Promise<any> {
        const { data } = await this.$http.get<any>(`GetIdentityNewsletterSev1Sev2Incidents?weekStartDate=${weekStartDate}`);
        return data;
    }
    public async DeleteTopOutageData(TopOutageID: number, updatedby: string): Promise<number> {
        const { data } = await this.$http.get<number>(`DeleteTopOutageData?TopOutageID=${TopOutageID}&updatedby=${updatedby}`);
        return data;
    }
    public async SaveTopOutagesData(objTopOutage: IPropIdentityTopOutages): Promise<number> {
        const { data } = await this.$http.post<number>(`SaveTopOutagesData`, objTopOutage);
        return data
    }
    public async GetIdentityNewsletterOKRsObjectivesData(): Promise<IPropIdentityObjectives[]> {
        const { data } = await this.$http.get<IPropIdentityObjectives[]>(`GetIdentityNewsletterOKRsObjectivesData`);
        return data;
    }
    public async GetIdentityNewsletterOKRsKeyResultsData(): Promise<IPropIdentityOKRs[]> {
        const { data } = await this.$http.get<IPropIdentityOKRs[]>(`GetIdentityNewsletterOKRsKeyResultsData`);
        return data;
    }

    public async NewsletterIdentity_SendEmail(RequestorMail, dateRange, tab_text): Promise<any> {
        var sendEmailData = { "Requestor": RequestorMail, "DateRange": dateRange,"EmailContent": tab_text };
        const { data } = await this.$http.post<any>(`IdentityNewsletterSendEmail`, sendEmailData);
        return data;
    }

    public async DeleteobjectiveKeyResultsData(id: number, userName: string): Promise<number> {
        const { data } = await this.$http.get<number>(`DeleteobjectiveKeyResultsData?id=${id}&userName=${userName}`);
        return data;
    } 
    
    public async AddObjectivesData(objective, startDate, endDate, userName): Promise<number> {
        const { data } = await this.$http.post<number>(`AddObjectivesData?objective=${objective}&startDate=${startDate}&endDate=${endDate}&userName=${userName}`);
        return data;
    }
    
    public async AddorupdateObjectivesKeyResultsData(row: IPropIdentityKeyResults): Promise<number> {
        const metric = {
            KeyResultID: row.keyResultID,
            ObjectiveID: row.objectiveID,
            WeekDate: row.weekDate,
            WeekId: row.weekId === undefined || null ? 0 : row.weekId,
            KeyResults: row.keyResultsEdit === undefined || null ? row.keyResults : row.keyResultsEdit,
            ProgressValue: row.progressValueEdit === undefined || null ? row.progressValue : row.progressValueEdit,
            TargetValue: row.targetValueEdit === undefined || null ? row.targetValue : row.targetValueEdit,
            IsActive: row.isActive === undefined || null ? 1 : row.isActive,
            Notes: row.notes === undefined || null ? '' : row.notes,
            CreatedBy: row.createdBy === undefined || null ? '' : row.createdBy,
            UpdatedBy: row.updatedBy === undefined || null ? '' : row.updatedBy
        };
        if (row.keyResultID === 0) {
            const { data } = await this.$http.post<number>("AddObjectivesKeyResultsData", metric);
            return data;
        }
        else {
            const { data } = await this.$http.post<number>("UpdateObjectivesKeyResultsData", metric);
            return data
        }
    }

    public async GetIdentityNewsletterOutages(StartDate: string): Promise<object[]> {
        const { data } = await this.$http.get<object[]>(`GetIdentityNewsletterOutages?StartDate=${StartDate}`);
        return data;
    }

    public async GetIdentityNewsletterComms(StartDate: string): Promise<object[]> {
        const { data } = await this.$http.get<object[]>(`GetIdentityNewsletterComms?StartDate=${StartDate}`);
        return data;
    }
}

export const IdentityWeeklyNewsLetterAPI = IdentityWeeklyNewsLetterService.Instance;
