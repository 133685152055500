import React, { useState, useRef, useEffect, Fragment } from 'react';
import { isArrayWithLength, renderToastifyMsg, isNullOrUndefined } from '../../../utils';
import { WeeklyAPI } from '../../../api';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { PickList } from 'primereact/picklist';
import { ConfigDropdownOption, IViewDetails, PropEmailConfigDataSource, PropEmailConfigNames, PropEmailConfigDataSourceName } from '../../../store/EmailConfiguration';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { AutoComplete } from 'primereact/autocomplete';
import { Checkbox } from 'primereact/checkbox';
import { TabView, TabPanel } from 'primereact/tabview';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Fieldset } from 'primereact/fieldset';
import 'primeicons/primeicons.css';
import { toast } from 'react-toastify';
import LoadingOverlay from 'react-loading-overlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPencilAlt, faPlus, faSave, faEraser } from '@fortawesome/free-solid-svg-icons';
import { SplitButton } from 'primereact/splitbutton';

import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Row,
    Col, Card, CardBody, Button
} from 'reactstrap';
import { ReduxAction, IAppThunkAction } from '../../../store';
import { parse } from 'querystring';

type EmailConfigurationTableProps = {
    LoginuserId,
    setloadingtrue,
    setloadingfalse,
    serviceGroups: any,
    selectedServicegroupval: ConfigDropdownOption;
    services: any,
    selectedServiceval: ConfigDropdownOption;
    viewSourceDetailsByService: any;
    viewTargetDetailsByService: any;
    emailConfigNames: any;
    selectedEmailConfigTypes: ConfigDropdownOption;
    readonly handleServiceGroupChange: (selectedServicegroupval: ConfigDropdownOption) => IAppThunkAction<ReduxAction>;
    readonly handleServicesChange: (selectedServiceval: ConfigDropdownOption) => IAppThunkAction<ReduxAction>;
    readonly handleEmailConfigTypesChange: (selectedEmailConfigTypes: ConfigDropdownOption) => IAppThunkAction<ReduxAction>;
    clearConfigDataFilter: any;
    emailConfigDataSourceDetails: any;
    emailConfigDataSourceInactive: any;

    toEmailList: any;
    ccEmailList: any;

    serverNamePRI: any;
    databasePRI: any;
    authenticationTypePRI: any;
    commentsPRI: any;
    queryOrUrlPRI: any;

    addEmailConfigDataSourceData: any;

    clearEmailConfigDataSourceDataHeatmap: any;

    clearEmailConfigDataSourceDataPRI: any;
    EditEmailConfigDataSourceRowPRI: any;

    serverNameCRI: any;
    databaseCRI: any;
    authenticationTypeCRI: any;
    commentsCRI: any;
    queryOrUrlCRI: any;

    clearEmailConfigDataSourceDataCRI: any;
    EditEmailConfigDataSourceRowCRI: any;

    serverNameDRI: any;
    databaseDRI: any;
    authenticationTypeDRI: any;
    commentsDRI: any;
    queryOrUrlDRI: any;

    clearEmailConfigDataSourceDataDRI: any;
    EditEmailConfigDataSourceRowDRI: any;

    serverNameSEV12: any;
    databaseSEV12: any;
    authenticationTypeSEV12: any;
    commentsSEV12: any;
    queryOrUrlSEV12: any;

    IsValidPRIQuery: any;
    IsPRIDataSaved: any;

    IsQueryFailed: any;
    IsDataSaved: any;

    IsClearAll: any;

    clearEmailConfigDataSourceDataSEV12: any;
    EditEmailConfigDataSourceRowSEV12: any;

    readonly changeToEmailList: (toEmailList) => IAppThunkAction<ReduxAction>;
    readonly changeCCEmailList: (ccEmailList) => IAppThunkAction<ReduxAction>;

    datasource: any;
    selectedDatasourcePRI: PropEmailConfigDataSourceName;
    readonly handledatasourceChangePRI: (selectedDatasourcePRI: PropEmailConfigDataSourceName) => IAppThunkAction<ReduxAction>;
    readonly serverNameUpdatePRI: (serverNamePRI) => IAppThunkAction<ReduxAction>;
    readonly databaseUpdatePRI: (databasePRI) => IAppThunkAction<ReduxAction>;
    readonly authenticationTypeUpdatePRI: (authenticationTypePRI) => IAppThunkAction<ReduxAction>;
    readonly queryOrUrlUpdatePRI: (queryOrUrlPRI) => IAppThunkAction<ReduxAction>;
    readonly commentsUpdatePRI: (commentsPRI) => IAppThunkAction<ReduxAction>;

    selectedDatasourceCRI: PropEmailConfigDataSourceName;
    readonly handledatasourceChangeCRI: (selectedDatasourceCRI: PropEmailConfigDataSourceName) => IAppThunkAction<ReduxAction>;
    readonly serverNameUpdateCRI: (serverNameCRI) => IAppThunkAction<ReduxAction>;
    readonly databaseUpdateCRI: (databaseCRI) => IAppThunkAction<ReduxAction>;
    readonly authenticationTypeUpdateCRI: (authenticationTypeCRI) => IAppThunkAction<ReduxAction>;
    readonly queryOrUrlUpdateCRI: (queryOrUrlCRI) => IAppThunkAction<ReduxAction>;
    readonly commentsUpdateCRI: (commentsCRI) => IAppThunkAction<ReduxAction>;

    selectedDatasourceDRI: PropEmailConfigDataSourceName;
    readonly handledatasourceChangeDRI: (selectedDatasourceDRI: PropEmailConfigDataSourceName) => IAppThunkAction<ReduxAction>;
    readonly serverNameUpdateDRI: (serverNameDRI) => IAppThunkAction<ReduxAction>;
    readonly databaseUpdateDRI: (databaseDRI) => IAppThunkAction<ReduxAction>;
    readonly authenticationTypeUpdateDRI: (authenticationTypeDRI) => IAppThunkAction<ReduxAction>;
    readonly queryOrUrlUpdateDRI: (queryOrUrlDRI) => IAppThunkAction<ReduxAction>;
    readonly commentsUpdateDRI: (commentsDRI) => IAppThunkAction<ReduxAction>;

    selectedDatasourceSEV12: PropEmailConfigDataSourceName;
    readonly handledatasourceChangeSEV12: (selectedDatasourceSEV12: PropEmailConfigDataSourceName) => IAppThunkAction<ReduxAction>;
    readonly serverNameUpdateSEV12: (serverNameSEV12) => IAppThunkAction<ReduxAction>;
    readonly databaseUpdateSEV12: (databaseSEV12) => IAppThunkAction<ReduxAction>;
    readonly authenticationTypeUpdateSEV12: (authenticationTypeSEV12) => IAppThunkAction<ReduxAction>;
    readonly queryOrUrlUpdateSEV12: (queryOrUrlSEV12) => IAppThunkAction<ReduxAction>;
    readonly commentsUpdateSEV12: (commentsSEV12) => IAppThunkAction<ReduxAction>;


    readonly SaveEmailConfigDataSource: (validatePRIData: PropEmailConfigDataSource) => IAppThunkAction<ReduxAction>;

    SaveEmailConfigData: any;
    SaveEmailIds: any;
    DeleteEmailConfigData: any;
    setonboardNewEmailConfigHeatmap: any;
    onboardNewEmailConfigHeatmap: any;

    setonboardNewEmailConfigPRI: any;
    onboardNewEmailConfigPRI: any;

    setonboardNewEmailConfigCRI: any;
    onboardNewEmailConfigCRI: any;


    setonboardNewEmailConfigDRI: any;
    onboardNewEmailConfigDRI: any;


    setonboardNewEmailConfigSEV: any;
    onboardNewEmailConfigSEV: any;

    setViewEmailConfigDataSourceEnabled: any;
    setViewEmailConfigDataSourceDisabled: any;
    setAddEmailConfigDataSourceEnabled: any;
    setAddEmailConfigDataSourceDisabled: any;
    ViewEmailConfigDataSourceOpen: boolean;
    AddEmailConfigDataSourceOpen: boolean;

    setAddEmailConfigPRIDataSourceEnabled: any;
    setAddEmailConfigPRIDataSourceDisabled: any;
    AddEmailConfigPRIDataSourceOpen: boolean;
};

const EmailConfigurationTable = React.memo<EmailConfigurationTableProps>(({
    LoginuserId,
    setloadingtrue,
    setloadingfalse,
    serviceGroups,
    selectedServicegroupval,
    services,

    selectedServiceval,
    viewSourceDetailsByService,
    viewTargetDetailsByService,
    emailConfigNames,

    handleServiceGroupChange,
    handleServicesChange,
    clearConfigDataFilter,
    selectedEmailConfigTypes,
    handleEmailConfigTypesChange,
    emailConfigDataSourceDetails,
    emailConfigDataSourceInactive,


    toEmailList,
    ccEmailList,

    handledatasourceChangePRI,
    serverNamePRI,
    databasePRI,
    authenticationTypePRI,
    commentsPRI,
    queryOrUrlPRI,

    clearEmailConfigDataSourceDataHeatmap,

    clearEmailConfigDataSourceDataPRI,
    EditEmailConfigDataSourceRowPRI,

    handledatasourceChangeCRI,

    serverNameCRI,
    databaseCRI,
    authenticationTypeCRI,
    commentsCRI,
    queryOrUrlCRI,

    clearEmailConfigDataSourceDataCRI,
    EditEmailConfigDataSourceRowCRI,

    handledatasourceChangeDRI,
    serverNameDRI,
    databaseDRI,
    authenticationTypeDRI,
    commentsDRI,
    queryOrUrlDRI,

    clearEmailConfigDataSourceDataDRI,
    EditEmailConfigDataSourceRowDRI,

    handledatasourceChangeSEV12,
    serverNameSEV12,
    databaseSEV12,
    authenticationTypeSEV12,
    commentsSEV12,
    queryOrUrlSEV12,

    clearEmailConfigDataSourceDataSEV12,
    EditEmailConfigDataSourceRowSEV12,

    selectedDatasourcePRI,
    authenticationTypeUpdatePRI,
    serverNameUpdatePRI,
    databaseUpdatePRI,
    queryOrUrlUpdatePRI,
    commentsUpdatePRI,

    selectedDatasourceCRI,
    authenticationTypeUpdateCRI,
    serverNameUpdateCRI,
    databaseUpdateCRI,
    queryOrUrlUpdateCRI,
    commentsUpdateCRI,

    selectedDatasourceDRI,
    authenticationTypeUpdateDRI,
    serverNameUpdateDRI,
    databaseUpdateDRI,
    queryOrUrlUpdateDRI,
    commentsUpdateDRI,

    selectedDatasourceSEV12,
    authenticationTypeUpdateSEV12,
    serverNameUpdateSEV12,
    databaseUpdateSEV12,
    queryOrUrlUpdateSEV12,
    commentsUpdateSEV12,

    SaveEmailConfigDataSource,
    SaveEmailConfigData,
    SaveEmailIds,
    DeleteEmailConfigData,

    addEmailConfigDataSourceData,


    setonboardNewEmailConfigHeatmap,
    onboardNewEmailConfigHeatmap,

    setonboardNewEmailConfigPRI,
    onboardNewEmailConfigPRI,

    setonboardNewEmailConfigCRI,
    onboardNewEmailConfigCRI,

    setonboardNewEmailConfigDRI,
    onboardNewEmailConfigDRI,

    setonboardNewEmailConfigSEV,
    onboardNewEmailConfigSEV,


    setViewEmailConfigDataSourceEnabled,
    setViewEmailConfigDataSourceDisabled,
    ViewEmailConfigDataSourceOpen,

    setAddEmailConfigDataSourceEnabled,
    setAddEmailConfigDataSourceDisabled,
    AddEmailConfigDataSourceOpen,

    setAddEmailConfigPRIDataSourceEnabled,
    setAddEmailConfigPRIDataSourceDisabled,
    AddEmailConfigPRIDataSourceOpen,

    IsValidPRIQuery,
    IsPRIDataSaved,

    IsQueryFailed,
    IsDataSaved,

    changeToEmailList,
    changeCCEmailList,
    datasource

}) => {
    useEffect(() => {
        setSource(viewSourceDetailsByService);
        setTarget(viewTargetDetailsByService);
    }, [viewSourceDetailsByService, viewTargetDetailsByService]);

    const toastIdRef = useRef(null);
    const [confirmDialog, setconfirmDialog] = useState(null);
    const [selectedEmailConfigDatasource, setSelectedEmailConfigDatasource] = useState(null);
    const [rowData, setrowData] = useState(null);
    const [filteredTargets, setfilteredTargets] = useState([]);
    const [filteredAuths, setfilteredAuths] = useState([]);
    const [emailConfigDataSourceAddorUpdatetext, setEmailConfigDataSourceAddorUpdatetext] = useState("ADD");
    const [source, setSource] = useState([]);
    const [target, setTarget] = useState([]);
    const [selectedViewIds, setSelectedviewIds] = useState('');
    const [activeIndex, setActiveIndex] = useState(0);
    const [deleteEmailConfigDialog, setDeleteEmailConfigDialog] = useState(false); 
    const [deletedConfigName, setDeletedConfigName] = useState('');
    const [deletedConfigId, setDeletedConfigId] = useState(0);

    const handleServiceGroupChangeNew = (value) => {
        handleServiceGroupChange(value);
    }

    const deleteEmailConfigDialogFooter = (
        <div>
        <React.Fragment>
            <Button className="p-button-text btn-primary" onClick={() => hideDeleteEmailConfigDialog()} >No</Button>
            <Button className="p-button-text btn-primary" onClick={() => ValidateAndSaveConfig(deletedConfigId, deletedConfigName, false)}>Yes</Button>
            </React.Fragment>
            </div>
    );
    //const emailConfigTypes = [
    //    { name: 'DRI', value: 'DRI' },
    //    { name: 'Heatmap', value: 'Heatmap' },
    //    { name: 'Partner Reported Incidents (PRI)', value: 'Partner Reported Incidents (PRI)' },
    //    { name: 'Customer Repoted Incidents (CRI)', value: 'Customer Repoted Incidents (CRI)' },        
    //    { name: 'Recent Sev1/2s', value: 'Recent Sev1/2s' }
    //];
    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div style={{ verticalAlign: 'center' }}>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editEmailConfigDataSourcePRI(rowData)} /> &nbsp;
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteEmailConfigDataSource(rowData)} />
                </div>
            </>
        );
    };

    const deleteEmailConfigDataSourceFooter = (
        <>
            <div style={{ float: 'right' }}>
                <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => emailConfigDataSourceInactiveFunc()} />
                <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteEmailConfigDataSourceDialog()} />
            </div>
        </>
    );

    const itemTemplate = (item) => {
        return (
            <span>{item.viewName}</span>
        );
    }

    const onChangePickList = (event) => {
        let heatmapIds = '';
        setSource(event.source);
        setTarget(event.target);
        if (event.target.length > 0) {
            for (var i = 0; i < event.target.length; i++) {
                if (heatmapIds == '')
                    heatmapIds = event.target[i].viewId;
                else
                    heatmapIds = heatmapIds + ',' + event.target[i].viewId;
            }
        }
        setSelectedviewIds(heatmapIds);
    }

    const confirmDeleteEmailConfigDataSource = (row) => {
        setrowData(row);
        setconfirmDialog(true);
    }

    const editEmailConfigDataSourcePRI = (row) => {
        setrowData(row);
        setAddEmailConfigDataSourceEnabled();
        setViewEmailConfigDataSourceDisabled();
        setEmailConfigDataSourceAddorUpdatetext("UPDATE");
        EditEmailConfigDataSourceRowPRI(row);
    }

    const KustoAuthtypes = [
        { name: 'AAD AppId', value: 'AAD AppId' }
        //Commented out the below code as only AAD AppId is Configured as of now WeeklYSelfServe 
        //,{ name: 'DSTS Blackforest', value: 'Dsts Blackforest' },
        //{ name: 'DSTS Fairfax', value: 'Dsts Fairfax' },
        //{ name: 'DSTS Mooncake', value: 'Dsts Mooncake' },
        //{ name: 'UserId', value: 'UserId' },
        //{ name: 'Certificate', value: 'Certificate' }
    ];

    const searchAuthtype = (event) => {
        setTimeout(() => {
            let filterAuths;
            if (!event.query.trim().length) {
                filterAuths = KustoAuthtypes;
            }
            else {
                filterAuths = KustoAuthtypes.filter((auth) => {
                    return auth.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setfilteredAuths(filterAuths);
        }, 250);
    }

    const emailConfigDataSourceInactiveFunc = () => {
        emailConfigDataSourceInactive(rowData);
        setconfirmDialog(false);
    }

    const hideDeleteEmailConfigDataSourceDialog = () => {
        setconfirmDialog(false);
    }

    const handleServicesChangeNew = (value) => {
        setTarget([]);      
        setAddEmailConfigDataSourceDisabled();
        setViewEmailConfigDataSourceEnabled();
        handleServicesChange(value);
    }

    const UpdateEmailIds = () => {       
        SaveEmailIds();
    }

    const ValidateAndSaveConfig = (emailConfigId, emailConfigName, isActive) => {
        const selectedServiceId = parseInt(selectedServiceval.toString());              

        let sdataSourceId = 0;
        let sfixedQuery = '';
        let squeryOrUrl = '';
        let sauthenicationType = '';
        let sclusterName = '';
        let sdbName = '';
        let scomments = '';
        let sViewIds = '';

        if (emailConfigName === "Heatmap") {
            sViewIds = selectedViewIds;
            sdataSourceId = 1;
        }
        else if (emailConfigName === "PRI") {
            sdataSourceId = parseInt(selectedDatasourcePRI.toString());
            sfixedQuery = queryOrUrlPRI;
            squeryOrUrl = queryOrUrlPRI;
            sauthenicationType = authenticationTypePRI;
            sclusterName = serverNamePRI;
            sdbName = databasePRI;
            scomments = commentsPRI;
        }
        else if (emailConfigName === "CRI") {
            sdataSourceId = parseInt(selectedDatasourceCRI.toString());
            sfixedQuery = queryOrUrlCRI;
            squeryOrUrl = queryOrUrlCRI;
            sauthenicationType = authenticationTypeCRI;
            sclusterName = serverNameCRI;
            sdbName = databaseCRI;
            scomments = commentsCRI;
        }
        else if (emailConfigName === "DRI") {
            sdataSourceId = parseInt(selectedDatasourceDRI.toString());
            sfixedQuery = queryOrUrlDRI;
            squeryOrUrl = queryOrUrlDRI;
            sauthenicationType = authenticationTypeDRI;
            sclusterName = serverNameDRI;
            sdbName = databaseDRI;
            scomments = commentsDRI;
        }
        else if (emailConfigName === "SEV12s") {
            sdataSourceId = parseInt(selectedDatasourceSEV12.toString());
            sfixedQuery = queryOrUrlSEV12;
            squeryOrUrl = queryOrUrlSEV12;
            sauthenicationType = authenticationTypeSEV12;
            sclusterName = serverNameSEV12;
            sdbName = databaseSEV12;
            scomments = commentsSEV12;
        }

        const obj = {
            emailConfigId: emailConfigId,
            emailConfigName: emailConfigName,
            serviceId: selectedServiceId,
            viewIds: sViewIds,
            dataSourceId: sdataSourceId,
            toEmailList: toEmailList,
            ccEmailList: ccEmailList,
            fixedQuery: sfixedQuery,
            queryOrUrl: squeryOrUrl,
            authenicationType: sauthenicationType,
            clusterName: sclusterName,
            dbName: sdbName,
            comments: scomments,
            updatedBy: LoginuserId,
            isActive: isActive,
            isAutomated: isActive,
            emailConfigTitle: '',
            dataSourceName: ''
        };


        //SaveEmailConfigDataSource(obj);
        if (isActive) {
            SaveEmailConfigData(obj);
        }
        else {
            setDeleteEmailConfigDialog(false);
            DeleteEmailConfigData(obj);
        }
    }

    const closeEmailConfigDataSourceData = () => {
        setonboardNewEmailConfigHeatmap(false);
        setAddEmailConfigDataSourceDisabled();
        setViewEmailConfigDataSourceEnabled();
        clearEmailConfigDataSourceDataPRI();
    }

    const onTabSelect = (value) => {
        setActiveIndex(value.index)
        if (value.originalEvent.target.innerText == 'Heatmap') {
            // setSource(viewDetailsByService);
        }
        else if (value.originalEvent.target.innerText === 'PRI') {
            let selectedRow = emailConfigDataSourceDetails.find(x => x.emailConfigName.trim() == 'PRI')
            if (!isNullOrUndefined(selectedRow)) {
                EditEmailConfigDataSourceRowPRI(selectedRow);
            }
        }
        else if (value.originalEvent.target.innerText === 'CRI') {
            let selectedRow = emailConfigDataSourceDetails.find(x => x.emailConfigName.trim() == 'CRI')
            if (!isNullOrUndefined(selectedRow)) {
                EditEmailConfigDataSourceRowCRI(selectedRow);
            }
        }
        else if (value.originalEvent.target.innerText === 'DRI') {
            let selectedRow = emailConfigDataSourceDetails.find(x => x.emailConfigName.trim() == 'DRI')
            if (!isNullOrUndefined(selectedRow)) {
                EditEmailConfigDataSourceRowDRI(selectedRow);
            }
        }
        else if (value.originalEvent.target.innerText === 'SEV12s') {
            let selectedRow = emailConfigDataSourceDetails.find(x => x.emailConfigName.trim() == 'SEV12s')
            if (!isNullOrUndefined(selectedRow)) {
                EditEmailConfigDataSourceRowSEV12(selectedRow);
            }
        }

        //setSelectedEmailConfigDatasource();
    }

    const newEmailConfigOnboardHeatmap = (value) => {
        setonboardNewEmailConfigHeatmap(value);
        // setSource(viewDetailsByService);
        if (value === true) {
            setAddEmailConfigDataSourceEnabled();
            setViewEmailConfigDataSourceDisabled();
            setEmailConfigDataSourceAddorUpdatetext("ADD");
            clearEmailConfigDataSourceDataHeatmap();
        }
        else {
            setAddEmailConfigDataSourceDisabled();
            setViewEmailConfigDataSourceEnabled();
            clearEmailConfigDataSourceDataHeatmap();
        }
    }

    const newEmailConfigOnboardPRI = (value) => {
        setonboardNewEmailConfigPRI(value);
        if (value === true) {
            setAddEmailConfigPRIDataSourceEnabled();
            setViewEmailConfigDataSourceDisabled();
            setEmailConfigDataSourceAddorUpdatetext("ADD");
            clearEmailConfigDataSourceDataPRI();
        }
        else {
            setAddEmailConfigPRIDataSourceDisabled();
            setViewEmailConfigDataSourceEnabled();
            clearEmailConfigDataSourceDataPRI();
        }

    }

    const newEmailConfigOnboardCRI = (value) => {
        setonboardNewEmailConfigCRI(value);
        if (value === true) {
            setAddEmailConfigDataSourceEnabled();
            setViewEmailConfigDataSourceDisabled();
            setEmailConfigDataSourceAddorUpdatetext("ADD");
            clearEmailConfigDataSourceDataCRI();
        }
        else {
            setAddEmailConfigDataSourceDisabled();
            setViewEmailConfigDataSourceEnabled();
            clearEmailConfigDataSourceDataCRI();
        }

    }

    const newEmailConfigOnboardDRI = (value) => {
        setonboardNewEmailConfigDRI(value);
        if (value === true) {
            setAddEmailConfigDataSourceEnabled();
            setViewEmailConfigDataSourceDisabled();
            setEmailConfigDataSourceAddorUpdatetext("ADD");
            clearEmailConfigDataSourceDataDRI();
        }
        else {
            setAddEmailConfigDataSourceDisabled();
            setViewEmailConfigDataSourceEnabled();
            clearEmailConfigDataSourceDataDRI();
        }

    }

    const newEmailConfigOnboardSEV = (value) => {
        setonboardNewEmailConfigSEV(value);
        if (value === true) {
            setAddEmailConfigDataSourceEnabled();
            setViewEmailConfigDataSourceDisabled();
            setEmailConfigDataSourceAddorUpdatetext("ADD");
            clearEmailConfigDataSourceDataSEV12();
        }
        else {
            setAddEmailConfigDataSourceDisabled();
            setViewEmailConfigDataSourceEnabled();
            clearEmailConfigDataSourceDataSEV12();
        }

    }

    const checkRequiredFields = (configName) => {

        if ((configName == 'Heatmap') &&
            (!isArrayWithLength(target) || !isArrayWithLength(source) || toEmailList == '')) {
            return true;
        }
        else if ((configName == 'PRI') &&
            (serverNamePRI == '' || authenticationTypePRI == '' || databasePRI == '' || queryOrUrlPRI == '' || toEmailList == '' || selectedDatasourcePRI == null)) {
            return true;
        }
        else if ((configName == 'CRI') &&
            (serverNameCRI == '' || authenticationTypeCRI == '' || databaseCRI == '' || queryOrUrlCRI == '' || toEmailList == '' || selectedDatasourceCRI == null)) {
            return true;
        }
        else if ((configName == 'DRI') &&
            (serverNameDRI == '' || authenticationTypeDRI == '' || databaseDRI == '' || queryOrUrlDRI == '' || toEmailList == '' || selectedDatasourceDRI == null)) {
            return true;
        }
        else if ((configName == 'SEV12s') &&
            (serverNameSEV12 == '' || authenticationTypeSEV12 == '' || databaseSEV12 == '' || queryOrUrlSEV12 == '' || toEmailList == '' || selectedDatasourceSEV12 == null)) {
            return true;
        }
        return false;
    }

    const clearFilter = () => {
        setTarget([]);
        setSource([]);       
    }
    const deleteEmailConfigRecordConfirm = (cId,cName) => {       
        setDeleteEmailConfigDialog(true);
        setDeletedConfigId(cId);
        setDeletedConfigName(cName);
    }
    const hideDeleteEmailConfigDialog = () => {        
        setDeleteEmailConfigDialog(false);
        setDeletedConfigId(0);
        setDeletedConfigName('');
    }
    const items = [
        {
            label: 'Update Email',
            icon: 'pi pi-save',                
            command: (e) => { UpdateEmailIds() }
        },
        {
            label: 'Clear Filter',
            icon: 'pi pi-times',            
            command: (e) => { clearConfigDataFilter() }                
        }                
    ];  
    return (
        <div>
            <Card className="cardstyle">
                <CardBody className="cardbodyhederstyle">
                    <Row>
                        <Col lg="4"></Col>
                        <Col lg="4" style={{ textAlign: 'center' }}>
                            <div>
                                <h3 className="headertext"> Daily Status - Email Configuration </h3>
                            </div>
                        </Col>
                        <Col lg="2"></Col>   
                        <Col lg="2" style={{ textAlign: 'left' }}>
                            <SplitButton disabled={selectedServiceval == null ? true: false} label="Other Actions" className="p-button-info p-mr-2" model={items}></SplitButton>
                        </Col> 
                    </Row>
                </CardBody>
            </Card>
            <br />
            <div className="card">
                <div className="p-fluid p-grid">
                    <Row>
                        <Col lg='3'>
                            <div className="p-field p-col-4 p-md-4 dropdown_email_config">
                                <span className="p-float-label">
                                    <Dropdown id={'servicegroup'} className="searchstyleEmailconfig" value={selectedServicegroupval} filter filterBy="name" options={serviceGroups} onChange={(e) => handleServiceGroupChangeNew(e.value)} optionLabel="name" />
                                    <label htmlFor="servicegroup" className="fontcolor">Service Group</label>
                                </span>
                            </div>
                        </Col>
                        <Col lg='3'>
                            <div className="p-field p-col-4 p-md-4 dropdown_email_config">
                                <span className="p-float-label">
                                    <Dropdown id={'service'} className="searchstyleEmailconfig" value={selectedServiceval} filter filterBy="name" options={services} onChange={(e) => handleServicesChangeNew(e.value)} optionLabel="name" />
                                    <label htmlFor="service" className="fontcolor">Service Name</label>
                                </span>
                            </div>
                        </Col>                      
                        <Col lg='3'>
                            <div className="p-field p-col-4 p-md-4" style={{ width: '450px' }}>
                                <span className="p-float-label" style={{ paddingTop: '5px' }}>
                                    <InputText id="toEmailList" value={toEmailList ? toEmailList : ""} onChange={(e) => changeToEmailList(e)} />
                                    <label htmlFor="toEmailList" className="fontcolor">To Email List<span className="spnmandatoryIcons">*</span></label>
                                </span>
                            </div>
                        </Col>
                        <Col lg='3'>
                            <div className="p-field p-col-4 p-md-4" style={{ width: '450px' }}>
                                <span className="p-float-label" style={{ paddingTop: '5px' }}>
                                    <InputText id="ccEmailList" value={ccEmailList ? ccEmailList : ""} onChange={(e) => changeCCEmailList(e)} />
                                    <label htmlFor="ccEmailList" className="fontcolor">CC Email List<span className="spnmandatoryIcons"></span></label>
                                </span>
                            </div>
                        </Col>                       
                    </Row>
                </div>
            </div>
            <br />
            {
                (!isNullOrUndefined(selectedServiceval)) ?
            <div className="EmailConfigBoxShadow" style={{ width: '100%' }}>
                <div className="p-fluid p-grid">
                    <Row>                        
                       
                                <React.Fragment>
                                    <Col sm='1'>
                                    </Col>
                                    <Col sm="2">
                                        <div className="p-field p-col-10 p-sm-1">
                                            <div className="divAddEmailConfig">
                                                &emsp;
                                     <Checkbox id="newMetric" onChange={e => newEmailConfigOnboardHeatmap(e.checked)} checked={onboardNewEmailConfigHeatmap}></Checkbox>
                                                <label htmlFor="newMetric" className="metricnew fontcolor">&nbsp;&nbsp;Heatmap</label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm='2'>
                                        <div className="p-field p-col-10 p-sm-1">
                                            <div className="divAddEmailConfig">
                                                &emsp;
                                     <Checkbox id="newMetric" onChange={e => newEmailConfigOnboardPRI(e.checked)} checked={onboardNewEmailConfigPRI}></Checkbox>
                                                <label htmlFor="newMetric" className="metricnew fontcolor">&nbsp;&nbsp;PRI</label>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col sm='2'>
                                        <div className="p-field p-col-10 p-sm-1">
                                            <div className="divAddEmailConfig">
                                                &emsp;
                                     <Checkbox id="newMetric" onChange={e => newEmailConfigOnboardCRI(e.checked)} checked={onboardNewEmailConfigCRI}></Checkbox>
                                                <label htmlFor="newMetric" className="metricnew fontcolor">&nbsp;&nbsp;CRI</label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm='2'>
                                        <div className="p-field p-col-10 p-sm-1">
                                            <div className="divAddEmailConfig">
                                                &emsp;
                                     <Checkbox id="newMetric" onChange={e => newEmailConfigOnboardDRI(e.checked)} checked={onboardNewEmailConfigDRI}></Checkbox>
                                                <label htmlFor="newMetric" className="metricnew fontcolor">&nbsp;&nbsp;DRI</label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm='2'>
                                        <div className="p-field p-col-10 p-sm-1">
                                            <div className="divAddEmailConfig">
                                                &emsp;
                                     <Checkbox id="newMetric" onChange={e => newEmailConfigOnboardSEV(e.checked)} checked={onboardNewEmailConfigSEV}></Checkbox>
                                                <label htmlFor="newMetric" className="metricnew fontcolor">&nbsp;&nbsp;SEV 1/2s</label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm='1'>
                                    </Col>
                                </React.Fragment>
                               
                       
                    </Row>
                </div>
                    </div>
                    : <React.Fragment></React.Fragment>
                     }
            <br />
            <Accordion multiple activeIndex={activeIndex} onTabChange={(e) => onTabSelect(e)}>
                {
                    (onboardNewEmailConfigHeatmap === true) ?
                        <AccordionTab header="Heatmap" >
                            <Row>
                                <Col lg='9'>
                                    <div className="picklist-emailConfig">
                                        <div className="card-emailConfig">
                                            <PickList source={source} target={target} itemTemplate={itemTemplate}
                                                sourceHeader="Available Heatmaps" targetHeader="Selected Heatmaps"
                                                sourceStyle={{ height: '342px' }} targetStyle={{ height: '342px' }}
                                                onChange={onChangePickList}></PickList>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg='1'>
                                </Col>
                                <Col lg='1'>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <Row>
                                        <Button disabled={checkRequiredFields('Heatmap')} className="Emailbuttons btn" onClick={() => ValidateAndSaveConfig(1, 'Heatmap', true)}>
                                            <FontAwesomeIcon cursor='pointer' icon={faSave} />&nbsp;Save
                                        </Button>
                                      
                                    </Row>
                                    <br />
                                    <Row>
                                        <Button disabled={checkRequiredFields('Heatmap')} className="Emailbuttons btn" onClick={() => deleteEmailConfigRecordConfirm(1,'Heatmap')}>
                                            <FontAwesomeIcon cursor='pointer' icon={faTrash} />&nbsp;Delete
                                        </Button>                                      
                                    </Row>
                                    <br />
                                    <Row>
                                        <Button disabled={checkRequiredFields('Heatmap')} className="Emailbuttons btn" onClick={() => clearEmailConfigDataSourceDataHeatmap()}>
                                            <FontAwesomeIcon cursor='pointer' icon={faEraser} />&nbsp;Clear
                                        </Button>                                          
                                    </Row>
                                </Col>
                                <Col lg='1'>
                                </Col>
                            </Row>
                          
                        </AccordionTab>
                        : <AccordionTab disabled header="Heatmap"></AccordionTab>
                }
                {
                    (onboardNewEmailConfigPRI === true) ?

                        <AccordionTab header="Partner Reported Incidents (PRI)">
                            <React.Fragment>
                                <div className="card addmetricdatasource">
                                    <div className="p-fluid p-grid">
                                        <div>
                                            <Row style={{ paddingLeft: '15px' }}>
                                                <div className="p-field p-col-12 p-md-4" style={{ width: '200px', float: 'left' }}>
                                                    <span className="p-float-label">
                                                        <Dropdown id={'datasource'} value={selectedDatasourcePRI} options={datasource} onChange={(e) => handledatasourceChangePRI(e.value)} optionLabel="name" />
                                                        <label htmlFor="datasource" className="fontcolor">Data Source<span className="spnmandatoryIcons">*</span></label>
                                                    </span>
                                                </div>  
                                                <div className="p-field p-col-12 p-md-4" style={{ width: '350px' }}>
                                                    <span className="p-float-label" style={{ paddingTop: '5px' }}>
                                                        <InputText id="serverNamePRI" value={serverNamePRI ? serverNamePRI : ""} onChange={(e) => serverNameUpdatePRI(e)} />
                                                        <label htmlFor="serverNamePRI" className="fontcolor">Server Name<span className="spnmandatoryIcons">*</span></label>
                                                    </span>
                                                </div>
                                                <div className="p-field p-col-12 p-md-4 " style={{ width: '350px' }}>
                                                    <span className="p-float-label" style={{ paddingTop: '5px' }}>
                                                        <Dropdown id={'authenticationTypePRI'} className="searchstyleEmailconfig" value={authenticationTypePRI} options={KustoAuthtypes}
                                                            onChange={(e) => authenticationTypeUpdatePRI(e.value)} optionLabel="name" />

                                                        <label htmlFor="authenticationTypePRI" className="fontcolor">Authentication Type<span className="spnmandatoryIcons">*</span></label>

                                                    </span>
                                                </div>
                                                <div className="p-field p-col-12 p-md-4 " style={{ width: '313px' }}>
                                                    <span className="p-float-label" style={{ paddingTop: '5px' }}>
                                                        <InputText id="databasePRI" value={databasePRI ? databasePRI : ""} onChange={(e) => databaseUpdatePRI(e)} />
                                                        <label htmlFor="databasePRI" className="fontcolor">Database<span className="spnmandatoryIcons">*</span></label>
                                                    </span>
                                                </div>

                                            </Row>
                                            <Row>
                                                <Col lg='9'>
                                                    <Row>
                                                        <div className="p-field p-col-10 p-md-4 field">
                                                            <label className="fontcolor">Query<span className="spnmandatoryIcons">*</span></label>
                                                            <InputTextarea rows={5} cols={120} value={queryOrUrlPRI} onChange={(event) => queryOrUrlUpdatePRI(event)} />
                                                            <label className="fontcolor">Comments</label>
                                                            <InputTextarea rows={5} cols={120} value={commentsPRI} onChange={(event) => commentsUpdatePRI(event)} />
                                                        </div>
                                                    </Row>
                                                </Col>
                                                <Col lg='1'>
                                                </Col>
                                                <Col lg='1'>
                                                    <br />
                                                    <br />

                                                    <Row>
                                                        <Button disabled={checkRequiredFields('PRI')} className="Emailbuttons btn" onClick={() => ValidateAndSaveConfig(2, 'PRI', true)}>
                                                            <FontAwesomeIcon cursor='pointer' icon={faSave} />&nbsp;Save
                                                        </Button>

                                                    </Row>
                                                    <br />
                                                    <Row>
                                                        <Button disabled={checkRequiredFields('PRI')} className="Emailbuttons btn" onClick={() => deleteEmailConfigRecordConfirm(2,'PRI')}>
                                                            <FontAwesomeIcon cursor='pointer' icon={faTrash} />&nbsp;Delete
                                                        </Button>
                                                    </Row>
                                                    <br />
                                                    <Row>
                                                        <Button disabled={checkRequiredFields('PRI')} className="Emailbuttons btn" onClick={() => clearEmailConfigDataSourceDataPRI()}>
                                                            <FontAwesomeIcon cursor='pointer' icon={faEraser} />&nbsp;Clear
                                                        </Button>
                                                    </Row>
                                                </Col>
                                                <Col lg='1'>
                                                </Col>
                                            </Row>                                            
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        </AccordionTab>
                        : <AccordionTab disabled header="Partner Reported Incidents (PRI)"></AccordionTab>
                }
                {
                    (onboardNewEmailConfigCRI === true) ?
                        <AccordionTab header="Customer Reported Incidents (CRI)">
                            <React.Fragment>
                                <div className="card addmetricdatasource">
                                    <div className="p-fluid p-grid">
                                        <div>
                                            <Row style={{ paddingLeft: '15px' }}>
                                                <div className="p-field p-col-12 p-md-4" style={{ width: '200px', float: 'left' }}>
                                                    <span className="p-float-label">
                                                        <Dropdown id={'datasource'} value={selectedDatasourceCRI} options={datasource} onChange={(e) => handledatasourceChangeCRI(e.value)} optionLabel="name" />
                                                        <label htmlFor="datasource" className="fontcolor">Data Source<span className="spnmandatoryIcons">*</span></label>
                                                    </span>
                                                </div>  
                                                <div className="p-field p-col-12 p-md-4" style={{ width: '350px' }}>
                                                    <span className="p-float-label" style={{ paddingTop: '5px' }}>
                                                        <InputText id="serverNameCRI" value={serverNameCRI ? serverNameCRI : ""} onChange={(e) => serverNameUpdateCRI(e)} />
                                                        <label htmlFor="serverNameCRI" className="fontcolor">Server Name<span className="spnmandatoryIcons">*</span></label>
                                                    </span>
                                                </div>
                                                <div className="p-field p-col-12 p-md-4 " style={{ width: '350px' }}>
                                                    <span className="p-float-label" style={{ paddingTop: '5px' }}>
                                                        <Dropdown id={'authenticationTypeCRI'} className="searchstyleEmailconfig" value={authenticationTypeCRI} options={KustoAuthtypes}
                                                            onChange={(e) => authenticationTypeUpdateCRI(e.value)} optionLabel="name" />
                                                        <label htmlFor="authenticationTypeCRI" className="fontcolor">Authentication Type<span className="spnmandatoryIcons">*</span></label>
                                                    </span>
                                                </div>
                                                <div className="p-field p-col-12 p-md-4 " style={{ width: '313px' }}>
                                                    <span className="p-float-label" style={{ paddingTop: '5px' }}>
                                                        <InputText id="databaseCRI" value={databaseCRI ? databaseCRI : ""} onChange={(e) => databaseUpdateCRI(e)} />
                                                        <label htmlFor="databaseCRI" className="fontcolor">Database<span className="spnmandatoryIcons">*</span></label>
                                                    </span>
                                                </div>

                                            </Row>
                                            <Row>
                                                <Col lg='9'>
                                                    <Row>
                                                        <div className="p-field p-col-10 p-md-4 field">
                                                            <label className="fontcolor">Query<span className="spnmandatoryIcons">*</span></label>
                                                            <InputTextarea rows={5} cols={120} value={queryOrUrlCRI} onChange={(event) => queryOrUrlUpdateCRI(event)} />
                                                            <label className="fontcolor">Notes</label>
                                                            <InputTextarea rows={5} cols={120} value={commentsCRI} onChange={(event) => commentsUpdateCRI(event)} />
                                                        </div>
                                                    </Row>
                                                </Col>
                                                <Col lg='1'>

                                                </Col>
                                                <Col lg='1'>
                                                    <br />
                                                    <br />
                                                    <Row>
                                                        <Button disabled={checkRequiredFields('CRI')} className="Emailbuttons btn" onClick={() => ValidateAndSaveConfig(3, 'CRI', true)}>
                                                            <FontAwesomeIcon cursor='pointer' icon={faSave} />&nbsp;Save
                                        </Button>

                                                    </Row>
                                                    <br />
                                                    <Row>
                                                        <Button disabled={checkRequiredFields('CRI')} className="Emailbuttons btn" onClick={() => deleteEmailConfigRecordConfirm(3,'CRI')}>
                                                            <FontAwesomeIcon cursor='pointer' icon={faTrash} />&nbsp;Delete
                                                        </Button>
                                                    </Row>
                                                    <br />
                                                    <Row>
                                                        <Button disabled={checkRequiredFields('CRI')} className="Emailbuttons btn" onClick={() => clearEmailConfigDataSourceDataCRI()}>
                                                            <FontAwesomeIcon cursor='pointer' icon={faEraser} />&nbsp;Clear
                                                        </Button>
                                                    </Row>
                                                    
                                                    <br />
                                                </Col>
                                                <Col lg='1'>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        </AccordionTab>
                        : <AccordionTab disabled header="Customer Reported Incidents (CRI)"></AccordionTab>
                }
                {
                    (onboardNewEmailConfigDRI === true) ?
                        <AccordionTab header="DRI">
                            <React.Fragment>
                                <div className="card addmetricdatasource">
                                    <div className="p-fluid p-grid">
                                        <div>
                                            <Row style={{ paddingLeft: '15px' }}>
                                                <div className="p-field p-col-12 p-md-4" style={{ width: '200px', float: 'left' }}>
                                                    <span className="p-float-label">
                                                        <Dropdown id={'datasource'} value={selectedDatasourceDRI} options={datasource} onChange={(e) => handledatasourceChangeDRI(e.value)} optionLabel="name" />
                                                        <label htmlFor="datasource" className="fontcolor">Data Source<span className="spnmandatoryIcons">*</span></label>
                                                    </span>
                                                </div> 
                                                <div className="p-field p-col-12 p-md-4" style={{ width: '350px' }}>
                                                    <span className="p-float-label" style={{ paddingTop: '5px' }}>
                                                        <InputText id="serverNameDRI" value={serverNameDRI ? serverNameDRI : ""} onChange={(e) => serverNameUpdateDRI(e)} />
                                                        <label htmlFor="serverNameDRI" className="fontcolor">Server Name<span className="spnmandatoryIcons">*</span></label>
                                                    </span>
                                                </div>
                                                <div className="p-field p-col-12 p-md-4 " style={{ width: '350px' }}>
                                                    <span className="p-float-label" style={{ paddingTop: '5px' }}>
                                                        <Dropdown id={'authenticationTypeDRI'} className="searchstyleEmailconfig" value={authenticationTypeDRI} options={KustoAuthtypes}
                                                            onChange={(e) => authenticationTypeUpdateDRI(e.value)} optionLabel="name" />
                                                        <label htmlFor="authenticationTypeDRI" className="fontcolor">Authentication Type<span className="spnmandatoryIcons">*</span></label>
                                                    </span>
                                                </div>
                                                <div className="p-field p-col-12 p-md-4 " style={{ width: '313px' }}>
                                                    <span className="p-float-label" style={{ paddingTop: '5px' }}>
                                                        <InputText id="databaseDRI" value={databaseDRI ? databaseDRI : ""} onChange={(e) => databaseUpdateDRI(e)} />
                                                        <label htmlFor="databaseDRI" className="fontcolor">Database<span className="spnmandatoryIcons">*</span></label>
                                                    </span>
                                                </div>

                                            </Row>
                                            <Row>
                                                <Col lg='9'>
                                                    <Row>
                                                        <div className="p-field p-col-10 p-md-4 field">
                                                            <label className="fontcolor">Query<span className="spnmandatoryIcons">*</span></label>
                                                            <InputTextarea rows={5} cols={120} value={queryOrUrlDRI} onChange={(event) => queryOrUrlUpdateDRI(event)} />
                                                            <label className="fontcolor">Notes</label>
                                                            <InputTextarea rows={5} cols={120} value={commentsDRI} onChange={(event) => commentsUpdateDRI(event)} />
                                                        </div>
                                                    </Row>
                                                </Col>
                                                <Col lg='1'>

                                                </Col>
                                                <Col lg='1'>
                                                    <br />
                                                    <br />
                                                    <Row>
                                                        <Button disabled={checkRequiredFields('DRI')} className="Emailbuttons btn" onClick={() => ValidateAndSaveConfig(4, 'DRI', true)}>
                                                            <FontAwesomeIcon cursor='pointer' icon={faSave} />&nbsp;Save
                                                        </Button>

                                                    </Row>
                                                    <br />
                                                    <Row>
                                                        <Button disabled={checkRequiredFields('DRI')} className="Emailbuttons btn" onClick={() => deleteEmailConfigRecordConfirm(4,'DRI')}>
                                                            <FontAwesomeIcon cursor='pointer' icon={faTrash} />&nbsp;Delete
                                                        </Button>
                                                    </Row>
                                                    <br />
                                                    <Row>
                                                        <Button disabled={checkRequiredFields('DRI')} className="Emailbuttons btn" onClick={() => clearEmailConfigDataSourceDataDRI()}>
                                                            <FontAwesomeIcon cursor='pointer' icon={faEraser} />&nbsp;Clear
                                                        </Button>
                                                    </Row>
                                                    
                                                </Col>
                                                <Col lg='1'>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        </AccordionTab>
                        : <AccordionTab disabled header="DRI"></AccordionTab>
                }
                {
                    (onboardNewEmailConfigSEV === true) ?
                        <AccordionTab header="SEV 1/2s">
                            <React.Fragment>
                                <div className="card addmetricdatasource">
                                    <div className="p-fluid p-grid">
                                        <div>
                                            <Row style={{ paddingLeft: '15px' }}>
                                                <div className="p-field p-col-12 p-md-4" style={{ width: '200px', float: 'left' }}>
                                                    <span className="p-float-label">
                                                        <Dropdown id={'datasource'} value={selectedDatasourceSEV12} options={datasource} onChange={(e) => handledatasourceChangeSEV12(e.value)} optionLabel="name" />
                                                        <label htmlFor="datasource" className="fontcolor">Data Source<span className="spnmandatoryIcons">*</span></label>
                                                    </span>
                                                </div> 
                                                <div className="p-field p-col-12 p-md-4" style={{ width: '350px' }}>
                                                    <span className="p-float-label" style={{ paddingTop: '5px' }}>
                                                        <InputText id="serverNameSEV12" value={serverNameSEV12 ? serverNameSEV12 : ""} onChange={(e) => serverNameUpdateSEV12(e)} />
                                                        <label htmlFor="serverNameSEV12" className="fontcolor">Server Name<span className="spnmandatoryIcons">*</span></label>
                                                    </span>
                                                </div>
                                                <div className="p-field p-col-12 p-md-4 " style={{ width: '350px' }}>
                                                    <span className="p-float-label" style={{ paddingTop: '5px' }}>
                                                        <Dropdown id={'authenticationTypeSEV12'} className="searchstyleEmailconfig" value={authenticationTypeSEV12} options={KustoAuthtypes}
                                                            onChange={(e) => authenticationTypeUpdateSEV12(e.value)} optionLabel="name" />
                                                        <label htmlFor="authenticationTypeSEV12" className="fontcolor">Authentication Type<span className="spnmandatoryIcons">*</span></label>
                                                    </span>
                                                </div>
                                                <div className="p-field p-col-12 p-md-4 " style={{ width: '313px' }}>
                                                    <span className="p-float-label" style={{ paddingTop: '5px' }}>
                                                        <InputText id="databaseSEV12" value={databaseSEV12 ? databaseSEV12 : ""} onChange={(e) => databaseUpdateSEV12(e)} />
                                                        <label htmlFor="databaseSEV12" className="fontcolor">Database<span className="spnmandatoryIcons">*</span></label>
                                                    </span>
                                                </div>

                                            </Row>
                                            <Row>
                                                <Col lg='9'>
                                                    <Row>
                                                        <div className="p-field p-col-10 p-md-4 field">
                                                            <label className="fontcolor">Query<span className="spnmandatoryIcons">*</span></label>
                                                            <InputTextarea rows={5} cols={120} value={queryOrUrlSEV12} onChange={(event) => queryOrUrlUpdateSEV12(event)} />
                                                            <label className="fontcolor">Notes</label>
                                                            <InputTextarea rows={5} cols={120} value={commentsSEV12} onChange={(event) => commentsUpdateSEV12(event)} />
                                                        </div>
                                                    </Row>
                                                </Col>
                                                <Col lg='1'>

                                                </Col>
                                                <Col lg='1'>
                                                    <br />
                                                    <br />
                                                    <Row>
                                                        <Button disabled={checkRequiredFields('SEV12s')} className="Emailbuttons btn" onClick={() => ValidateAndSaveConfig(5, 'SEV12s', true)}>
                                                            <FontAwesomeIcon cursor='pointer' icon={faSave} />&nbsp;Save
                                                    </Button>

                                                    </Row>
                                                    <br />
                                                    <Row>
                                                        <Button disabled={checkRequiredFields('SEV12s')} className="Emailbuttons btn" onClick={() => deleteEmailConfigRecordConfirm(5,'SEV12s')}>
                                                            <FontAwesomeIcon cursor='pointer' icon={faTrash} />&nbsp;Delete
                                                         </Button>
                                                    </Row>
                                                    <br />
                                                    <Row>
                                                        <Button disabled={checkRequiredFields('SEV12s')} className="Emailbuttons btn" onClick={() => clearEmailConfigDataSourceDataSEV12()}>
                                                            <FontAwesomeIcon cursor='pointer' icon={faEraser} />&nbsp;Clear
                                                        </Button>
                                                    </Row>
                                                    
                                                </Col>
                                                <Col lg='1'>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        </AccordionTab>
                        : <AccordionTab disabled header="SEV 1/2s"></AccordionTab>
                }
            </Accordion>
            <Dialog visible={deleteEmailConfigDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteEmailConfigDialogFooter} onHide={hideDeleteEmailConfigDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', color: 'red' }} />
                    <span>Are you sure you want to delete?</span>
                </div>
            </Dialog>
        </div>
    )
});
EmailConfigurationTable.displayName = 'EmailConfigurationTable';

export default EmailConfigurationTable;