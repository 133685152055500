export interface Span {
    span: number;
};

export interface SpanColumnContext {
    span: Span;
    spannedRow: Object;
}

export type PropMetricGroups = {
    metricGroupId: number;
    metricGroupName: string;
};

export type PropAdminMetrics = {
    metricId: number;
    metricGroupName: string;
    metricName: string;
    isEditable: boolean;
    isMetricGraph: boolean;
    minRange: string;
    maxRange: string;
    interval: string;
    targetName: string;
    targetId: number;
    validationExpr: string;
    metricGroupLength: number;
    metricDetails: [];

};
export type PropConfig = {
    configId: number;
    configName: string;
    configValue: string;
}
export type PropTarget = {
    targetName: string;
    targetId: number;
    min: string;
    max: string;
}
export type PropUsers = {
    id: number;
    userName: string;
    roleId: number;
    roleName: string;
    isActive: boolean;
}
export type PropEditHistory = {
    serviceName: string;
    serviceId: number;
    weekId: number;
    metricName: string;
    kpiName: string;
    metricId: number;
    prevValue: string;
    currentValue: string;
    updatedBy: string;
    environment: string;
    weekName: string;
    updatedTime: Date;
    uom: string;
}
export type PropMetricsChangeHistory = {
    id: number;
    title: string;
    metric: string;
    service: string;
    type: string;
    requirmentId: number;
    requirmentUrl: string;
    comments: string;
    created: Date;
    createdBy: string;
    requestedBy: string;
}
export type PropMetricChanges = {
    key: number;
    value: string;
}
export type PropCapacityManagement = {
    serviceName: string;
    serviceId: number;
    cmMetricId: number;
    cmMetricGroupId: number;
    envName: string;
    cmMetricName: string;
    minMaxLimit: string;
    score: number;
    metricValue: number;
    updatedBy: string;
    updatedDate: Date;
    weekId: number;
    enabled: boolean;
}
export type PropCmMetric = {
    cmMetricId: number;
    cmMetricName: string;
    cmMetricDisplayName: string;
    cmMetricGroupId: string;
    envId: string;
}
export interface ITargetJson {
    readonly serviceId: number;
    readonly serviceName: string;
    readonly viewName: string;
    readonly orginalTarget: string;
    readonly heatmapViewId: number;
    readonly newTarget: string;
    readonly justification: string;
    readonly viewId: number;
    readonly requestedBy: string;
    readonly approvalStatus: string;
    readonly approvedBy: string;
    readonly approvedDate: string;
    readonly reviewerComments: string;
}

export type PropHeatMapMissingViews = {
    serviceDisplayName: string;
    viewName: string;
    missingDate: Date;
    source: string;
}

export type IServiceGroup = {
    readonly directorName: string;
    readonly teamGroupName: string;
    readonly groupName: string;
    readonly groupId: string;
    readonly serviceName: string;
    readonly serviceId: string;
    readonly ringValue: string;
    readonly ringId: string;
    isActive: boolean;
    readonly isDSE: string;
    readonly isHeatMap: boolean;
    readonly isDailyReport: string;
    readonly isWeeklyReport: string;
    readonly parentReachabilityId: string;
    readonly reachabilityAvailable: string;
    readonly colValue: string;
    IsDirectorChecked: boolean;
    IsTeamGroupChecked: boolean;
    IServiceGroupChecked: boolean;
    IsToggle: boolean;
    enableservicescount: number;
};


export type IAdminSettingsState = {
    readonly services: [];
    readonly isLoading: boolean;
    readonly adminSettingsData: [];
    readonly Isdataloaded: boolean;
    readonly activeDailyDataTab: string;
    readonly selectedServicesval: ServiceDropdownOption;
    readonly selectedCrServicesval: ServiceDropdownOption;
    readonly selectedCMMetricGroupsval: CMDropdownOption;
    readonly selectedEnvironmentval: CMDropdownOption;
    readonly selectedHeatmapServiceval: ServiceDropdownOption;
    readonly selectedhistoryServicesval: ServiceDropdownOption;
    readonly selectedhistoryMetricGroupsval: ServiceDropdownOption;
    readonly serviceNames: [];
    readonly metricGroups: [];
    readonly CMMetricGroups: [];
    readonly CMEnvirnoments: [];
    readonly CMData: PropCmMetric[];
    readonly CMScoreCardData: [];
    readonly selectedMetricGroupsval: ServiceDropdownOption;
    readonly adminMetrics: PropAdminMetrics[];
    readonly adminConfig: [];
    readonly missingViewInfo: [];
    readonly heatMapHistoryData: [];
    readonly editHistory: PropEditHistory[];
    readonly metricChange: PropMetricChanges[];
    readonly selectedCrMetricsval: CMDropdownOption;
    readonly targetDetails: PropTarget[];
    readonly selectedtargetDetailsval: ServiceDropdownOption;
    readonly crservice: string;
    readonly crcomments: string;
    readonly crrequestedBy: string;
    readonly crcreatedBy: string;
    readonly crtitle: string;
    readonly crrequirment_Id: string;
    readonly crnewValue: string;
    readonly historyFrmCalVal: string;
    readonly historyToCalVal: string;
    readonly heatmapFromCalVal: string;
    readonly heatmapToCalVal: string;
    readonly newMetricName: string;
    readonly newMetricDisplayName: string;
    readonly newMetricGroup: string;
    readonly newMetricEnvironment: string;
    readonly metricConfigMetricId: number;
    readonly metricConfigGroupName: string;
    readonly isEditableMetricConfig: boolean;
    readonly isMetricGraphMetricConfig: boolean;
    readonly minRangeMetricConfig: string;
    readonly maxRangeMetricConfig: string;
    readonly intervalMetricConfig: string;
    readonly validationExprMetricConfig: string;
    readonly targetUpdateApprovalRequestData: ITargetJson[];
};

export type ServiceDropdownOption = {
    name: string;
    value: number;
    id: number;
}
export type CMDropdownOption = {
    key: number;
    value: string;
}

export type metricObject = {
    service: string;
    comments: string;
    requestedBy: string;
    createdBy: string;
    title: string;
    requirment_Id: string;
    newValue: string;
}

const _namespace = 'AdminSettings';
export interface IActionType {
    readonly RESET_STATE: string;
    readonly SET_LOADING_TRUE: string;
    readonly SET_LOADING_FALSE: string;
    readonly GET_SERVICE_GROUP: string;
    readonly ADDED_VISITED_USER: string;
    readonly SET_ADMINDATA_ACTIVETAB: string;
    readonly GET_SERVICE_CONFIGURATION: string;
    readonly GET_METRIC_GROUPS: string;
    readonly GET_ADMIN_METRICS: string;
    readonly GET_ADMIN_CONFIG: string;
    readonly GET_TARGET_DETAILS: string;
    readonly GET_USERS: string;
    readonly GET_EDIT_HISTORY: string;
    readonly GET_METRIC_CHANGES: string;
    readonly GET_CAPACITY_METRICS: string;
    readonly GET_CAPACITY_METRIC_GROUP: string;
    readonly GET_SCORECARD_DATA: string;
    readonly GET_METRIC_ENVIRONMENT: string;
    readonly GET_MISSINGHEATMAP_DATA: string;
    readonly SERVICE_CHANGE: string;
    readonly CR_SERVICE_CHANGE: string;
    readonly CR_METRICGROUP_CHANGE: string;
    readonly CM_METRICGROUP_CHANGE: string;
    readonly CM_ENVIRONMENT_CHANGE: string;
    readonly GET_HEATMAPHISTORY_DATA: string;
    readonly HEATMAP_SERVICE_CHANGE: string;
    readonly HISTORY_SERVICE_CHANGE: string;
    readonly HISTORY_METRICGROUP_CHANGE: string;
    readonly CR_METRIC_CHANGE: string;
    readonly CR_TARGET_CHANGE: string;
    readonly UPDATE_REQUSTEDBY_VALUES: string;
    readonly UPDATE_TITLE_VALUES: string;
    readonly UPDATE_REQUIREMENT_VALUES: string;
    readonly UPDATE_COMMENT_VALUES: string;
    readonly HISTORY_FROM_CHANGE: string;
    readonly HISTORY_TO_CHANGE: string;
    readonly HEATMAP_FROM_CHANGE: string;
    readonly HEATMAP_TO_CHANGE: string;
    readonly UPDATED_CM_METRICS: string;
    readonly DELETE_CM_METRICS: string;
    readonly CM_NEWMETRIC_ADD: string;
    readonly CM_INSERT_METRIC: string;
    readonly METRIC_ISEDIT_CHANGE: string;
    readonly METRIC_ISGRAPH_CHANGE: string;
    readonly METRIC_VALIDEXP_CHANGE: string;
    readonly METRIC_MINRANGE_CHANGE: string;
    readonly METRIC_MAXRANGE_CHANGE: string;
    readonly METRIC_INTERVAL_CHANGE: string;
    readonly METRIC_MINRANGE_UPDATE: string;
    readonly GET_UPDATEDADMIN_CONFIG: string;
    readonly HEATMAPCHANGEREQUESTDATA: string;
    readonly EMAILCONFIRMATION: string;
}

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/resetState`,
    SET_LOADING_TRUE: `${_namespace}/loadingtrue`,
    SET_LOADING_FALSE: `${_namespace}/loadingfalse`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    GET_SERVICE_GROUP: `${_namespace}/getServiceGroup`,
    SET_ADMINDATA_ACTIVETAB: `${_namespace}/setAdminDataActivetab`,
    GET_SERVICE_CONFIGURATION: `${_namespace}/getServiceConfiguration`,
    GET_METRIC_GROUPS: `${_namespace}/getMetricGroups`,
    GET_ADMIN_METRICS: `${_namespace}/getAdminMetrics`,
    GET_ADMIN_CONFIG: `${_namespace}/getAdminConfig`,
    GET_TARGET_DETAILS: `${_namespace}/getTargetDetails`,
    GET_USERS: `${_namespace}/getUsers`,
    GET_EDIT_HISTORY: `${_namespace}/getEditHistory`,
    GET_METRIC_CHANGES: `${_namespace}/getMetricChanges`,
    GET_CAPACITY_METRICS: `${_namespace}/getCapacityMetric`,
    GET_CAPACITY_METRIC_GROUP: `${_namespace}/getCapacityMetricGroup`,
    GET_SCORECARD_DATA: `${_namespace}/getScorecardData`,
    GET_METRIC_ENVIRONMENT: `${_namespace}/getMetricEnvironment`,
    GET_MISSINGHEATMAP_DATA: `${_namespace}/getMissingheatmapData`,
    SERVICE_CHANGE: '${_namespace}/serviceChange',
    CR_SERVICE_CHANGE: '${_namespace}/crServiceChange',
    CR_METRICGROUP_CHANGE: '${_namespace}/crMetricgroupChange',
    CM_METRICGROUP_CHANGE: '${_namespace}/cmMetricgroupChange',
    CM_ENVIRONMENT_CHANGE: '${_namespace}/cmMEnvironmentChange',
    GET_HEATMAPHISTORY_DATA: '${_namespace}/getHeatmapHistoryData',
    HEATMAP_SERVICE_CHANGE: '${_namespace}/heatmapServiceChange',
    HISTORY_SERVICE_CHANGE: '${_namespace}/historyServiceChange',
    HISTORY_METRICGROUP_CHANGE: '${_namespace}/historyMetricGroupChange',
    CR_METRIC_CHANGE: '${_namespace}/crMetricChange',
    CR_TARGET_CHANGE: '${_namespace}/crTargetChange',
    UPDATE_REQUSTEDBY_VALUES: '${_namespace}/updateRequestedbyValues',
    UPDATE_TITLE_VALUES: '${_namespace}/updateTitleValues',
    UPDATE_REQUIREMENT_VALUES: '${_namespace}/updateRequirementValues',
    UPDATE_COMMENT_VALUES: '${_namespace}/updateCommentValues',
    HISTORY_FROM_CHANGE: '${_namespace}/historyFromChange',
    HISTORY_TO_CHANGE: '${_namespace}/historyToChange',
    HEATMAP_FROM_CHANGE: '${_namespace}/heatmapFromChange',
    HEATMAP_TO_CHANGE: '${_namespace}/heatmapToChange',
    UPDATED_CM_METRICS: '${_namespace}/updatedCmMetrics',
    DELETE_CM_METRICS: '${_namespace}/deleteCmMetrics',
    CM_NEWMETRIC_ADD: '${_namespace}/cmNewMetricAdd',
    CM_INSERT_METRIC: '${_namespace}/cmInsertMetric',
    METRIC_ISEDIT_CHANGE: '${_namespace}/metricIsEditChange',
    METRIC_ISGRAPH_CHANGE: '${_namespace}/metricIsGraphChange',
    METRIC_VALIDEXP_CHANGE: '${_namespace}/metricValiExpChange',
    METRIC_MINRANGE_CHANGE: '${_namespace}/metricMinRangeChange',
    METRIC_MAXRANGE_CHANGE: '${_namespace}/metricMaxRangeChange',
    METRIC_INTERVAL_CHANGE: '${_namespace}/metricIntervalChange',
    METRIC_MINRANGE_UPDATE: '${_namespace}/minrangeUpdate',
    GET_UPDATEDADMIN_CONFIG: '${_namespace}/getUpdatedAdminConfig',
    HEATMAPCHANGEREQUESTDATA: '${_namespace}/heatmapchangerequestdata',
    EMAILCONFIRMATION: `${_namespace}/emailconfirmation`
})

