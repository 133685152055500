
export interface IActionType {
    readonly RESET_STATE: string;
    readonly SET_LOADING_TRUE: string;
    readonly SET_LOADING_FALSE: string;
    readonly ADDED_VISITED_USER: string;
    readonly GET_INTERNALESCALATION_REQUEST: string;
    readonly GET_INTERNALESCALATION_SUCCESS: string;
    readonly GET_INTERNALESCALATION_FAIL: string;
    readonly CLOUD_CHANGE: string;
    readonly SERVICELIFECYCLE_CHANGE: string;
    readonly CLASSIFICATION_CHANGE: string;
    readonly SERVICEGROUP_CHANGE: string;
    readonly TEAMGROUP_CHANGE: string;
    readonly TENANT_CHANGE: string;
    readonly IMOROCE_CHANGE: string;
    readonly CLEAR_INTERNALESCALATION_DROPDOWNFILTERVALUES: string;
    readonly SELECTEDIDENTITYSERVICECLASSIFICATIONTYPE_CHANGE: string;
    readonly UPDATE_ONCALLDATA_SUCCESS: string;
    readonly UPDATE_RBREQUESTASSISTANCELIST_SUCCESS: string;
    readonly OPEN_EMAIL_POPUP: string;
    readonly EMAIL_POPUP_CLOSE: string;
}
export type IInternalEscalationState = {
    readonly isLoading: boolean;
    readonly onCallData: any;
    readonly filteredonCallData: any;
    readonly rbRequestAssistanceList: any;
    readonly selectedCloud: any;
    readonly serviceLifeCycle: [];
    readonly selectedServiceLifeCycle: any;
    readonly classificationList: [];
    readonly selectedClassification: any;
    readonly serviceGroupNameList: [];
    readonly selectedServiceGroupName: any;
    readonly selectedTeamGroupName: any;
    readonly tenantList: [];
    readonly teamGroupNameList: [];
    readonly selectedTenant: any;
    readonly selectedImorOce: any;
    readonly copyEmailselectedImorOce: any;
    readonly selectedService: any;
    readonly selectedIdentityServiceClassificationType: any;
    readonly defaultexpandrows: any;
    readonly Isopenemailpopup: boolean;
    readonly OnCallAliasEmailIDs: string;
    readonly BackupOnCallAliasEmailIDs: string;
};

export type DropdownOption = {
    name: string;
    value: number;
};

const _namespace = 'InternalEscalation';

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/reset`,
    SET_LOADING_TRUE: `${_namespace}/setloadingtrue`,
    SET_LOADING_FALSE: `${_namespace}/setloadingfalse`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    GET_INTERNALESCALATION_REQUEST: `${_namespace}/getinternalescalationrequest`,
    GET_INTERNALESCALATION_SUCCESS: `${_namespace}/getinternalescalationsuccess`,
    GET_INTERNALESCALATION_FAIL: `${_namespace}/getinternalescalationfail`,
    CLOUD_CHANGE: `${_namespace}/cloudchange`,
    SERVICELIFECYCLE_CHANGE: `${_namespace}/servicelifecyclechange`,
    CLASSIFICATION_CHANGE: `${_namespace}/classificationchange`,
    SERVICEGROUP_CHANGE: `${_namespace}/servicegroupchange`,
    TEAMGROUP_CHANGE: `${_namespace}/teamgroupchange`,
    TENANT_CHANGE: `${_namespace}/tenantchange`,
    IMOROCE_CHANGE: `${_namespace}/imorocechange`,
    CLEAR_INTERNALESCALATION_DROPDOWNFILTERVALUES: `${_namespace}/clearinternalescalationdropdownfiltervalues`,
    UPDATE_ONCALLDATA_SUCCESS: `${_namespace}/updateoncalldatasuccess`,
    UPDATE_RBREQUESTASSISTANCELIST_SUCCESS: `${_namespace}/updaterbrequestassistancelistsuccess`,
    SELECTEDIDENTITYSERVICECLASSIFICATIONTYPE_CHANGE: `${_namespace}/selectedidentityserviceclassificationtypechange`,
    OPEN_EMAIL_POPUP: `${_namespace}/openemailpopup`,
    EMAIL_POPUP_CLOSE: `${_namespace}/emailpopupclose`
});

