import { IAppThunkAction, ReduxAction } from '../';
import { ActionType, IPropRepairItemsAllServices, IPropTicketReport, IGraphRow, IPropTicketDetails } from './types';
import { ActionItemsAPI, CommonAPI} from '../../api';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../utils';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import drilldown from "highcharts/modules/drilldown.js";
drilldown(Highcharts);


export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }

    },
    getWeekstartdate: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        const date = getWeeklyStartDate();  
        dispatch({           
            weeklyStartDate: date,          
            type: ActionType.GET_WEEK_START_DATE
        });
    },
    checkallservices: (e): IAppThunkAction<ReduxAction> => (dispatch) => {
        const date = getWeeklyStartDate();
        dispatch({
            weeklyStartDate: date,
            type: ActionType.GET_WEEK_START_DATE
        });
    },
    checksingleservices: (row, val, index, all): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
       
        let allRepairItemsData = getState().ActionItems.allRepairItemsData;       
        const allRepairItemsGraphData = getState().ActionItems.allRepairItemsGraphData;
        const tableheader = getState().ActionItems.allRepairItemsDatatableheader;
        let checkallservice = getState().ActionItems.checkAllService;
        let consdata = [];
        let grapghoptions;
        const allRepairItemsGraphDataAggregated2 = [];
        let RepairItemGraphServicesoptions;
        let isvalfalse = true;
        if (isArrayWithLength(allRepairItemsData)) {
            allRepairItemsData.map((obj, key) => {
                if (all) {
                    obj.checked = val;
                }
                else {
                    if (index === key) {
                        obj.checked = val;
                    }
                }  
                if (!obj.checked) {
                    isvalfalse = false;
                }
                consdata.push(obj);
            })
            grapghoptions = LoadRepairItemPie(consdata, "Action Item Status");
        }
        if (!all) {
            if (isvalfalse) {
                checkallservice = true; // =============if all service is checked as true in table then make all checkbox as true
            }
            else {
                checkallservice = false;// =============if any service is checked false in table then make all checkbox as false
            }
        }
        dispatch({
            allRepairItemsData:consdata,
            grapghoptions: grapghoptions,
            tableheader,
            checkAllService: (all) ? val : checkallservice,
            type: ActionType.GET_ACTIONITEMS_SUCCESS
        });

        let sameRow = false;
        let lastRow = {} as IGraphRow;
        if (isArrayWithLength(allRepairItemsGraphData)) {
            allRepairItemsGraphData.map((graph, index) => {
                consdata.map((row, index) => {
                    if (row.checked && row.serviceId == graph.userSk) {
                        if (!lastRow.week_Name || lastRow.week_Name != graph.weekName) {
                            lastRow = {
                                week_Name: graph.weekName,
                                totalOpened: graph.totalOpened,
                                opened: graph.opened,
                                closed: graph.closed,
                                outOfSla: graph.outOfSla,
                            };

                            allRepairItemsGraphDataAggregated2.push(lastRow);
                        } else if (lastRow.week_Name == graph.weekName) {
                            lastRow.totalOpened = graph.totalOpened != undefined ? lastRow.totalOpened + graph.totalOpened : lastRow.totalOpened;
                            lastRow.opened = graph.opened != undefined ? lastRow.opened + graph.opened : lastRow.opened;
                            lastRow.closed = graph.closed != undefined ? lastRow.closed + graph.closed : lastRow.closed;
                            lastRow.outOfSla = graph.outOfSla != undefined ? lastRow.outOfSla + graph.outOfSla : lastRow.outOfSla;
                        }
                    }
                })

            })
        }
        RepairItemGraphServicesoptions = LoadRepairItemGraphServices(allRepairItemsGraphDataAggregated2, "Identity Action Item Status (All 5 weeks)");
        dispatch({
            allRepairItemsGraphDataAggregated: allRepairItemsGraphDataAggregated2,
            RepairItemGraphServicesoptions,
            allRepairItemsGraphData,
            type: ActionType.GET_REPAIRITEMS_SUCCESS
        });
        
    },
    Get_IdentityactionitemController_Item_Details: (weekstartdate: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({           
            type: ActionType.GET_ACTIONITEMS_REQUEST
        });
        const allRepairItemsData = [];
        const allRepairItemsGraphDataAggregated = [];
        let grapghoptions;        
        let RepairItemGraphServicesoptions;
        ActionItemsAPI.GetItemDetails(weekstartdate)
            .then((weeklyIDSHRData: IPropRepairItemsAllServices[]) => {
                if (isArrayWithLength(weeklyIDSHRData)) {
                    weeklyIDSHRData.map((data: IPropRepairItemsAllServices,count1) => {
                        let kpiData = String(data.kpiValues).split("/");
                        allRepairItemsData.push({
                            serviceId: data.userSk,
                            serviceName: data.serviceName,
                            totalopen: (!isNaN(parseFloat(kpiData[0])) ? parseFloat(kpiData[0]) : 0),
                            opened: (!isNaN(parseFloat(kpiData[1])) ? parseFloat(kpiData[1]) : 0),
                            closed: (!isNaN(parseFloat(kpiData[2])) ? parseFloat(kpiData[2]) : 0),
                            outofSLA: (!isNaN(parseFloat(kpiData[3])) ? parseFloat(kpiData[3]) : 0),
                            weekDisplayName: data.weekDisplayName,
                            checked: true
                        });
                    })
                    grapghoptions= LoadRepairItemPie(allRepairItemsData,"Action Item Status");
                }
                const tableheader = isArrayWithLength(allRepairItemsData) ? allRepairItemsData[0] : null;
                const dates = allRepairItemsData.sort((a, b) => b.totalopen-a.totalopen);
                dispatch({
                    allRepairItemsData: dates,
                    grapghoptions: grapghoptions,
                    tableheader,
                    checkAllService:true,
                    type: ActionType.GET_ACTIONITEMS_SUCCESS
                });
                ActionItemsAPI.GetItemDetailsGraph(weekstartdate)
                    .then((allRepairItemsGraphData: IPropTicketReport[]) => {                       
                        let sameRow = false;
                        let lastRow = {} as IGraphRow;
                        if (isArrayWithLength(allRepairItemsGraphData)) {
                            allRepairItemsGraphData.map((graph, index) => {
                                allRepairItemsData.map((row, index) => {
                                    if (row.checked && row.serviceId == graph.userSk) {
                                        if (!lastRow.week_Name || lastRow.week_Name != graph.weekName) {
                                            lastRow = {
                                                week_Name: graph.weekName,
                                                totalOpened: graph.totalOpened,
                                                opened: graph.opened,
                                                closed: graph.closed,
                                                outOfSla: graph.outOfSla,
                                            };

                                            allRepairItemsGraphDataAggregated.push(lastRow);
                                        } else if (lastRow.week_Name == graph.weekName) {
                                            lastRow.totalOpened = graph.totalOpened != undefined ? lastRow.totalOpened + graph.totalOpened : lastRow.totalOpened;
                                            lastRow.opened = graph.opened != undefined ? lastRow.opened + graph.opened : lastRow.opened;
                                            lastRow.closed = graph.closed != undefined ? lastRow.closed + graph.closed : lastRow.closed;
                                            lastRow.outOfSla = graph.outOfSla != undefined ? lastRow.outOfSla + graph.outOfSla : lastRow.outOfSla;
                                        }
                                    }
                                })

                            })
                        }
                        RepairItemGraphServicesoptions = LoadRepairItemGraphServices(allRepairItemsGraphDataAggregated, "Identity Action Item Status (All 5 weeks)");
                        dispatch({
                            allRepairItemsGraphDataAggregated,
                            RepairItemGraphServicesoptions,
                            allRepairItemsGraphData,
                            type: ActionType.GET_REPAIRITEMS_SUCCESS
                        });

                }).catch(error => {

                    dispatch({                      
                        type: ActionType.GET_REPAIRITEMS_FAILURE
                    });
                });

            }).catch(error => {
             
                dispatch({                   
                    type: ActionType.GET_ACTIONITEMS_FAILURE
                });
            });
    },
    Get_IdentityactionitemController_Item_Details_Data: (weekstartdate: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const allRepairItemsData = [];
        ActionItemsAPI.GetItemDetailsData(weekstartdate)
            .then((allRepairItemsData: IPropTicketDetails[]) => {
                if (isArrayWithLength(allRepairItemsData)) {
                    allRepairItemsData.map((obj: IPropTicketDetails) => {
                        obj.serviceName = obj.serviceName.toString().trim();
                        if (obj.dueDate!==null) {
                            obj.sortdueDate = new Date(obj.dueDate);
                        }
                        else {
                            obj.sortdueDate = null;
                        }
                        if (obj.createdDate !== null) {
                            obj.sortcreatedDate = new Date(obj.createdDate);
                        }
                        else {
                            obj.sortcreatedDate = null;
                        }
                        //obj.sortcreatedDate = new Date(obj.createdDate);
                        //obj.sortdueDate = new Date(obj.dueDate);
                    })
                }              
                dispatch({
                    actionItemsData: allRepairItemsData,
                    type: ActionType.GET_ACTIONITEMS_DATA_SUCCESS
                });
            }).catch(error => {

                dispatch({
                    type: ActionType.GET_ACTIONITEMS_DATA_FAILURE
                });
            });
    },
};
const getWeeklyStartDate = () => {
    const today = new Date(); // "07/23/2016"   
    if (!today.getDay()) {
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 15);
    }

    const pMonday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 6);
    const date = new Date(pMonday.getFullYear(), pMonday.getMonth(), pMonday.getDate() - 2);
    return date;
}

const LoadRepairItemPie = (data, graphLabel) =>{
    var series = "";
    var _series = [];
    var totalopen = 0, opened = 0, closed = 0, osla = 0;
    var arrtotalopen = [];
    var arrOpened = [];
    var arrClosed = [];
    var arrOsla = [];
    for (var counter = 0; counter < data.length; counter++) {
        if (data[counter].checked) {
            totalopen += !isNaN(parseFloat(data[counter].totalopen)) ? parseFloat(data[counter].totalopen) : 0;
            opened += !isNaN(parseFloat(data[counter].opened)) ? parseFloat(data[counter].opened) : 0;
            closed += !isNaN(parseFloat(data[counter].closed)) ? parseFloat(data[counter].closed) : 0;
            osla += !isNaN(parseFloat(data[counter].outofSLA)) ? parseFloat(data[counter].outofSLA) : 0;

            if (parseFloat(data[counter].totalopen) && parseFloat(data[counter].totalopen) != 0) {
                arrtotalopen.push({ name: data[counter].serviceName, y: parseFloat(data[counter].totalopen) });
            }

            if (parseFloat(data[counter].opened) && parseFloat(data[counter].opened) != 0) {
                arrOpened.push({ name: data[counter].serviceName, y: parseFloat(data[counter].opened) });
            }

            if (parseFloat(data[counter].closed) && parseFloat(data[counter].closed) != 0) {
                arrClosed.push({ name: data[counter].serviceName, y: parseFloat(data[counter].closed) });
            }

            if (parseFloat(data[counter].outofSLA) && parseFloat(data[counter].outofSLA) != 0) {
                arrOsla.push({ name: data[counter].serviceName, y: parseFloat(data[counter].outofSLA) });
            }
        }
    }
    let _series_data: any = [];   
    if (arrOsla.length > 0) {
        _series_data.push({
            name: "Out of SLA",
            y: osla,
            color: "#F4CB26",
            drilldown: "Out of SLA"
        });
    }
    else {
        _series_data.push({
            name: "Out of SLA",
            y: osla,
            color: "#F4CB26"            
        });
    }
    if (arrtotalopen.length > 0) {
        _series_data.push({
            name: "Total Open",
            y: totalopen,
            color: "#888",
            drilldown: "Total Open"
        });
    }
    else {
        _series_data.push({
            name: "Total Open",
            y: totalopen,
            color: "#888"
            
        });
    }
    if (arrOpened.length > 0) {
        _series_data.push({
            name: "Opened",
            y: opened,
            color: "#33FF74",
            drilldown: "Opened"
        });
    }
    else {
        _series_data.push({
            name: "Opened",
            y: opened,
            color: "#33FF74",           
        });
    }
    if (arrClosed.length > 0) {
        _series_data.push({
            name: "Closed",
            y: closed,
            color: "#F48D26",
            drilldown: "Closed"
        });
    }
    else {
        _series_data.push({
            name: "Closed",
            y: closed,
            color: "#F48D26"
           
        });
    }
    const options =
    {
        chart: {
            type: "pie",           
        },
        title: {
            style: {
                "fontSize": "1.48em",
                "top": "-10px",
                "margin-left": "-35px",
                "margin-top": "-10px"
            },
            useHTML: true,
            x: -27,
            y: 8,
            text: graphLabel + " <span style='font-size:small'>" + data[0].weekDisplayName + "</span>"
        },
        subtitle: {
            text: "Click the slices to view Service Level Data"
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    format: "{point.name}: {point.y}"
                }
            }
        },
        series: [{
            name: "Status",
            colorByPoint: true,
            data: _series_data
        }],
        drilldown: {
            series: [{
                name: "Out of SLA",
                id: "Out of SLA",
                data: arrOsla
            },
            {
                name: "Total Open",
                id: "Total Open",
                data: arrtotalopen
            },
            {
                name: "Opened",
                id: "Opened",
                data: arrOpened
            },
            {
                name: "Closed",
                id: "Closed",
                data: arrClosed
            }]
        }
      
    }
       
    return options;
}


const LoadRepairItemGraphServices=(data, graphLabel)=> {
    var series = "";
    var _series = [];
    var months = [];
    var totalOpened = [];
    var opened = [];
    var closed = [];
    var osla = [];

    for (var counter = 0; counter < data.length; counter++) {
        months.push(data[counter].week_Name);
        totalOpened.push(data[counter].totalOpened);
        opened.push(data[counter].opened);
        closed.push(data[counter].closed);
        osla.push(data[counter].outOfSla);
    }
    _series.push({
        "name": "Total Open",
        "data": totalOpened,
        type: "column",
        color: "#888"
    });

    _series.push({
        "name": "Item Opened",
        "data": opened,
        type: "column",
        color: "#33FF74"
    });

    _series.push({
        "name": "Item Closed",
        "data": closed,
        type: "column",
        color: "#F48D26"
    });
    _series.push({
        "name": "Out of SLA",
        "data": osla,
        type: "spline",
        color: "#F4CB26",
        connectNulls: true
    });

    const options =
    {
        xAxis: {
            categories: months,
            plotLines: [{ zIndex: 10 }]
        },
        chart: {
           
        },
        min: 0,
        title: {
            style: {
                "fontSize": "1.48em",
                "top": "-10px",
                "margin-left": "-35px",
                "margin-top": "-10px",
                "z-index": "3"
            },
            useHTML: true,
            x: -27,
            y: 8,
            text: graphLabel
        },
        subtitle: {
            text: ""
        },
        credits: {
            enabled: false,
        },
        series: _series
    }
    return options;
}



