import { CommonAPI, PlatformToolAPI } from '../../api';
import { IAppThunkAction, ReduxAction } from '../';
import { ActionType, IPropPlatformPMTools} from './types';
import { isNullOrUndefined } from '../../utils';
export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }

    },
    GetPlatformPMToolsData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.GET_PLATFORMTOOLS_REQUEST
        });
        PlatformToolAPI.GetPlatformPMToolsData()
            .then((platformtooldata: IPropPlatformPMTools[]) => {
                dispatch({
                    platformtooldata,
                    type: ActionType.GET_PLATFORMTOOLS_SUCCESS
                });
            }).catch(error => {
                dispatch({                  
                    type: ActionType.GET_PLATFORMTOOLS_FAIL
                });
            });       
    },
  
};

