
export type IPropCriticalAdvisoryData = {
    readonly advisoryId: number;
    readonly criticalAdvisoryName: string;
    readonly occuredOn: string;
    readonly azureLocation: string;
    readonly environment: string;
    readonly isInactive?: boolean;
    readonly guidelines: string;
    readonly lastUpdatedBy: string;
    readonly lastUpdatedDate: Date;
    readonly noFlyZoneInfoId: number;
    readonly noFlyZoneInfo: string;
    readonly updatedBy: string;
    readonly updatedDate: Date;
    readonly displayOrder: number;
    readonly advisoryType: string;
};

export type IPropCriticalAdvisoryState = {
    readonly CriticalAdvisoryData: IPropCriticalAdvisoryData[];
    readonly AzureHighPriorityLinksData: PropAzureHighPriorityLink[];
    readonly CriticalAdvisoryFlyZoneData: IPropCriticalAdvisoryData[];
    readonly noFlyZoneInfo: any;
    readonly noFlyZoneInfoData: any;
    readonly noFlyZoneInfoId: any;
    readonly isLoading: boolean;
    readonly CriticalAdvisoryName: string;
    readonly CriticalAdvisoryId: number;
    readonly GuidelineInfo: string;
    readonly IsLivesitePMOrUser: boolean;
    readonly AzureLocationDropdown: [];
    readonly AzureEnvironmentDropdown: [];
};

export type PropAzureHighPriorityLink = {
    readonly AzureHighPrioritySiteLink: string;
}

export type Dropdowntype = {
    key: number;
    value: string;
}

const _namespace = 'CriticalAdvisory';
export interface IActionType {
    readonly RESET_STATE: string;
    readonly GET_CRITICALADVISORY_REQUEST: string;
    readonly GET_CRITICALADVISORY_SUCCESS: string;
    readonly GET_CRITICALADVISORY_FAILURE: string;
    readonly SET_LOADING_TRUE: string;
    readonly SET_LOADING_FALSE: string;
    readonly ADDED_VISITED_USER: string;
    readonly GET_CRITICALADVISORY_GUIDELINE_SUCCESS: string;
    readonly GET_CRITICALADVISORY_GUIDELINE_FAILURE: string;
    readonly GET_AZURELOCATIONS: string;
    readonly GET_AZUREENVIRONMENTS: string;
    readonly USER_CHECK: string;
}

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/criticaladvisoryreset`,
    GET_CRITICALADVISORY_REQUEST: `${_namespace}/getcriticaladvisoryrequest`,
    GET_CRITICALADVISORY_SUCCESS: `${_namespace}/getcriticaladvisorysuccess`,
    GET_CRITICALADVISORY_FAILURE: `${_namespace}/getcriticaladvisoryfailure`,
    SET_LOADING_TRUE: `${_namespace}/loadingtrue`,
    SET_LOADING_FALSE: `${_namespace}/loadingfalse`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    GET_CRITICALADVISORY_GUIDELINE_SUCCESS: `${_namespace}/getcriticaladvisoryguidelinesuccess`,
    GET_CRITICALADVISORY_GUIDELINE_FAILURE: `${_namespace}/getcriticaladvisoryguidelinefailure`,
    USER_CHECK: `${_namespace}/UserCheck`,
    GET_AZURELOCATIONS: `${_namespace}/getAzureLocations`,
    GET_AZUREENVIRONMENTS: `${_namespace}/getAzureEnvironments`
});

