import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IAckTrackingState } from './types';

const initialState = Object.freeze<IAckTrackingState>({
    isLoading: false,
    mainAcktrackingData: [],
    notificationACKId: '0',
    headerdata: [],
    IdentityAckData: [],
    ServiceManagerList: [],
    ServiceList: [],
    ComponentList: [],
    ServiceTypeList: [],
    ServiceManagervalue: '',
    Servicevalue: '',
    Componentvalue: '',
    ServiceTypevalue: '',
    ServiceRinglist: [],
    ServiceRingvalue: '',
    ServiceLifeCyclelist: [],
    ServiceLifeCyclevalue: '',
    IDClassificationList: [],
    IDClassificationvalue: '',
    globalsearch: '',
    IDAckTypeList: [],
    AcktrackingFiltervalue: '0',
    AcktrackingFilterList: [],
    NewFiltervalue: '',
    Iscontact_DevOwner: false,
    Iscontact_PMOwner: false,
    IslastUpdatedBy: false,
    DevOwnerEmailIDs: '',
    PMOwnerEmailIDs: '',
    UpdatedUserIDEmailIDs: '',
    Isopenemailpopup: false,
    urlparam: '',
    EditAckPopup: false,
    EditObject: null,
    editdropdownlist: [],
    EditObjectDate: null,
    checkboxlist: null,
    savebuttondisabled: true,
    counttofilterdatawhilepageload: 0,
    title: '',
    IsServiceSpecificAck: false,
    IsFilterDisabled: false
});

export const reducer = (
    state: IAckTrackingState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {
       
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true,

            };
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false,

            };       
        case ActionType.ACKTRACKING_DATA_FETCH:
            return {
                ...state,
                mainAcktrackingData: [],
                isLoading: true

            }; 
        case ActionType.ACKTRACKING_DATA_SUCCESS:
            return {
                ...state,
                mainAcktrackingData: action.AcktrackingData,
                isLoading: false,
                headerdata: action.headerdata,
                IdentityAckData: action.IdentityAckData,
                Iscontact_DevOwner: action.Iscontact_DevOwner,
                Iscontact_PMOwner: action.Iscontact_PMOwner,
                IslastUpdatedBy: action.IslastUpdatedBy,
                IsFilterDisabled: false,
                counttofilterdatawhilepageload: action.counttofilterdatawhilepageload
            };  
        case ActionType.ACKTRACKING_DATA_FAIL:
            return {
                ...state,
                mainAcktrackingData: [],
                isLoading: false

            }; 
        case ActionType.FETCH_SERVICEMANGERLIST_SUCCESS:
            return {
                ...state,
                ServiceManagerList: action.managrslist,
                ServiceManagervalue: action.ServiceManagervalue,
                counttofilterdatawhilepageload: action.counttofilterdatawhilepageload
            }; 
        case ActionType.FETCHSERVICEMANGERLIST_FAIL:
            return {
                ...state,
                ServiceManagerList: []
            }; 
        case ActionType.FETCH_SERVICELIST_SUCCESS:
            return {
                ...state,
                ServiceList: action.serlist,
                Servicevalue: action.Servicevalue,
                counttofilterdatawhilepageload: action.counttofilterdatawhilepageload
            };
        case ActionType.FETCH_COMPONENTLIST_SUCCESS:
            return {
                ...state,
                ComponentList: action.Complist,
                Componentvalue: action.Componentvalue,
                counttofilterdatawhilepageload: action.counttofilterdatawhilepageload
            };
        case ActionType.FETCHSERVICELIST_FAIL:
            return {
                ...state,
                ServicesList: []
            }; 
        case ActionType.FETCH_SERVICETYPELIST_SUCCESS:
            return {
                ...state,
                ServiceTypeList: action.sertypelist,
                ServiceTypevalue: action.ServiceTypevalue,
                counttofilterdatawhilepageload: action.counttofilterdatawhilepageload
            };
        case ActionType.FETCHSERVICETYPELIST_FAIL:
            return {
                ...state,
                ServiceTypeList: []
            };
        case ActionType.FETCH_SERVICERINGLIST_SUCCESS:
            return {
                ...state,
                ServiceRinglist: action.ServiceRinglist,
                ServiceRingvalue: action.ServiceRingvalue,
                counttofilterdatawhilepageload: action.counttofilterdatawhilepageload
            };
        case ActionType.FETCHSERVICERINGLIST_FAIL:
            return {
                ...state,
                ServiceRinglist: []
            };
        case ActionType.FETCH_SERVICELIFECYCLELIST_SUCCESS:
            return {
                ...state,
                ServiceLifeCyclelist: action.ServiceLifeCyclelist,
                ServiceLifeCyclevalue: action.ServiceLifeCyclevalue,
                counttofilterdatawhilepageload: action.counttofilterdatawhilepageload
            };
        case ActionType.FETCHSERVICELIFECYCLELIST_FAIL:
            return {
                ...state,
                ServiceLifeCyclelist: []
            };
        case ActionType.FETCH_IDCLASSIFICATION_SUCCESS:
            return {
                ...state,
                IDClassificationList: action.IDClassificationList,
                IDClassificationvalue: action.IDClassificationvalue,
                counttofilterdatawhilepageload: action.counttofilterdatawhilepageload
            };
        case ActionType.FETCH_IDCLASSIFICATION_FAIL:
            return {
                ...state,
                IDClassificationList: []
            };
        case ActionType.FETCH_IDACKTYPE_SUCCESS:
            return {
                ...state,
                title: action.title,
                IDAckTypeList: action.IDAckTypeList
            };
        case ActionType.FETCH_IDACKTYPE_FAIL:
            return {
                ...state,
                IDAckTypeList: []
            };
        case ActionType.FETCH_IDACKFILTERLIST_SUCCESS:
            return {
                ...state,
                AcktrackingFilterList: action.AcktrackingFilterList
            };
        case ActionType.FETCH_IDACKFILTERLIST_FAIL:
            return {
                ...state,
                AcktrackingFilterList: []
            };
        case ActionType.ACKTYPE_VALUE_CHANGE:
            return {
                ...state,
                notificationACKId: action.notificationACKId,
                title: action.title,
                ServiceManagervalue: action.ServiceManagervalue,
                Servicevalue: action.Servicevalue,
                Componentvalue: action.Componentvalue,
                IDClassificationvalue: action.IDClassificationvalue,
                ServiceLifeCyclevalue: action.ServiceLifeCyclevalue,
                ServiceTypevalue: action.ServiceTypevalue,
                ServiceRingvalue: action.ServiceRingvalue,
                AcktrackingFiltervalue: action.AcktrackingFiltervalue,                
                ServiceManagerList: action.ServiceManagerList,
                serviceslist: action.serviceslist,
                IDClassificationList: action.IDClassificationList,
                ServiceLifeCyclelist: action.ServiceLifeCyclelist,
                ServiceTypeList: action.ServiceTypeList,
                ServiceRinglist: action.ServiceRinglist,
                NewFiltervalue: '',
                globalsearch: '',
                IsServiceSpecificAck: action.isServiceSpecificAckType
            };
        case ActionType.GLOBAL_SEARCH_VALUE_CHANGE:
            return {
                ...state,
                globalsearch: action.globalsearch
            };
        case ActionType.FILTER_TEXT_VALUE_CHANGE:
            return {
                ...state,
                NewFiltervalue: action.NewFiltervalue
            };
        case ActionType.FILTER_DROPDOWN_CHANGE:
            return {
                ...state,

                AcktrackingFiltervalue: action.AcktrackingFiltervalue,
                isLoading: true
            };
        case ActionType.FILTER_DATA_BASEDON_FILTERID:
            return {
                ...state,
                title: action.title,
                ServiceManagervalue: action.selectServiceManagerList,
                Servicevalue: action.selectServicesList,
                Componentvalue: action.selectComponentList,
                ServiceLifeCyclevalue: action.selectServiceLifeCycleList,
                ServiceRingvalue: action.selectServiceRingList,
                IDClassificationvalue: action.selectIDClassificationList,
                ServiceTypevalue: action.selectServiceTypeList,
                IdentityAckData: action.ackdata,
                notificationACKId: action.notificationACKId,
                headerdata: action.headerdata,
                isLoading: false,
                Iscontact_DevOwner: action.Iscontact_DevOwner,
                Iscontact_PMOwner: action.Iscontact_PMOwner,
                IslastUpdatedBy: action.IslastUpdatedBy,
                NewFiltervalue: action.filterName,
                IsFilterDisabled: action.IsFilterDisabled,
                IsServiceSpecificAck: action.isServiceSpecificAckType
            };
        case ActionType.CLEAR_FILTERS:
            return {
                ...state,
                ServiceManagervalue: action.ServiceManagervalue,
                Servicevalue: action.Servicevalue,
                Componentvalue: action.Componentvalue,
                IDClassificationvalue: action.IDClassificationvalue,
                ServiceLifeCyclevalue: action.ServiceLifeCyclevalue,
                ServiceTypevalue: action.ServiceTypevalue,
                ServiceRingvalue: action.ServiceRingvalue,
                AcktrackingFiltervalue: action.AcktrackingFiltervalue,
                IdentityAckData: action.mainIdentityAckData,
                ServiceManagerList: action.ServiceManagerList,
                serviceslist: action.serviceslist,
                IDClassificationList: action.IDClassificationList,
                ServiceLifeCyclelist: action.ServiceLifeCyclelist,
                ServiceTypeList: action.ServiceTypeList,
                ServiceRinglist: action.ServiceRinglist,
                NewFiltervalue: '',
                globalsearch:''
                
            };
        case ActionType.FILTER_DATA:
            return {
                ...state,
                ServiceManagervalue: action.selectServiceManagerList,
                Servicevalue: action.selectServicesList,
                Componentvalue: action.selectComponentList,
                ServiceLifeCyclevalue: action.selectServiceLifeCycleList,
                ServiceRingvalue: action.selectServiceRingList,
                IDClassificationvalue: action.selectIDClassificationList,
                ServiceTypevalue: action.selectServiceTypeList,
                IdentityAckData: action.ackdata,               
                headerdata: action.headerdata,
                Iscontact_DevOwner: action.Iscontact_DevOwner,
                Iscontact_PMOwner: action.Iscontact_PMOwner,
                IslastUpdatedBy: action.IslastUpdatedBy,
                isLoading: false

            };
        case ActionType.SERVICEMANAGER_CHANGE:
            return {
                ...state,
                ServiceManagervalue: action.ServiceManagervalue,
                Servicevalue: action.Servicevalue,
                Componentvalue: action.Componentvalue,
                IDClassificationvalue: action.IDClassificationvalue,
                ServiceLifeCyclevalue: action.ServiceLifeCyclevalue,
                ServiceTypevalue: action.ServiceTypevalue,
                ServiceRingvalue: action.ServiceRingvalue,
                ServiceList: action.servicesList,
                ComponentList: action.componentList,
                ServiceLifeCyclelist:action.ServiceLifeCycleList,
                ServiceTypeList:action.ServiceTypeList,
                ServiceRinglist: action.ServiceRingList,
                IDClassificationList:action.IDClassificationList,

            };
        case ActionType.SERVICES_CHANGE:
            return {
                ...state,
                ServiceManagervalue: action.ServiceManagervalue,
                Servicevalue: action.Servicevalue,
                Componentvalue: action.Componentvalue,
                IDClassificationvalue: action.IDClassificationvalue,
                ServiceLifeCyclevalue: action.ServiceLifeCyclevalue,
                ServiceTypevalue: action.ServiceTypevalue,
                ServiceRingvalue: action.ServiceRingvalue,
                ServiceManagerList: action.ServiceManagerList,
                ComponentList: action.componentList,
                ServiceLifeCyclelist: action.ServiceLifeCycleList,
                ServiceTypeList: action.ServiceTypeList,
                ServiceRinglist: action.ServiceRingList,
                IDClassificationList: action.IDClassificationList,

            };
        case ActionType.COMPONENT_CHANGE:
            return {
                ...state,                               
                ServiceManagervalue: action.ServiceManagervalue,
                Servicevalue: action.Servicevalue,
                Componentvalue: action.Componentvalue,
                IDClassificationvalue: action.IDClassificationvalue,
                ServiceLifeCyclevalue: action.ServiceLifeCyclevalue,
                ServiceTypevalue: action.ServiceTypevalue,
                ServiceRingvalue: action.ServiceRingvalue,
                ServiceList: action.servicesList,               
                ServiceManagerList: action.ServiceManagerList,
                ServiceLifeCyclelist: action.ServiceLifeCycleList,    
                ServiceTypeList: action.ServiceTypeList,
                ServiceRinglist: action.ServiceRingList,
                IDClassificationList: action.IDClassificationList

            };
        case ActionType.SERVICE_LIFECYCLE_CHANGE:
            return {
                ...state,
                ServiceManagervalue: action.ServiceManagervalue,
                Servicevalue: action.Servicevalue,
                Componentvalue: action.Componentvalue,
                IDClassificationvalue: action.IDClassificationvalue,
                ServiceLifeCyclevalue: action.ServiceLifeCyclevalue,
                ServiceTypevalue: action.ServiceTypevalue,
                ServiceRingvalue: action.ServiceRingvalue,
                ServiceList: action.servicesList,
                ComponentList: action.componentList,
                ServiceManagerList: action.ServiceManagerList,
                ServiceTypeList: action.ServiceTypeList,
                ServiceRinglist: action.ServiceRingList,
                IDClassificationList: action.IDClassificationList
            };
        case ActionType.SERVICE_TYPE_CHANGE:
            return {
                ...state,
                ServiceManagervalue: action.ServiceManagervalue,
                Servicevalue: action.Servicevalue,
                Componentvalue: action.Componentvalue,
                IDClassificationvalue: action.IDClassificationvalue,
                ServiceLifeCyclevalue: action.ServiceLifeCyclevalue,
                ServiceTypevalue: action.ServiceTypevalue,
                ServiceRingvalue: action.ServiceRingvalue,
                ServiceList: action.servicesList,
                ComponentList: action.componentList,
                ServiceManagerList: action.ServiceManagerList,
                ServiceLifeCyclelist: action.ServiceLifeCyclelist,
                ServiceRinglist: action.ServiceRingList,
                IDClassificationList: action.IDClassificationList
            };
        case ActionType.SERVICE_IDCLASSIFICATION_CHANGE:
            return {
                ...state,
                ServiceManagervalue: action.ServiceManagervalue,
                Servicevalue: action.Servicevalue,
                Componentvalue: action.Componentvalue,
                IDClassificationvalue: action.IDClassificationvalue,
                ServiceLifeCyclevalue: action.ServiceLifeCyclevalue,
                ServiceTypevalue: action.ServiceTypevalue,
                ServiceRingvalue: action.ServiceRingvalue,
                ServiceList: action.servicesList,
                ComponentList: action.componentList,
                ServiceManagerList: action.ServiceManagerList,
                ServiceLifeCyclelist: action.ServiceLifeCyclelist,
                ServiceRinglist: action.ServiceRingList,
                ServiceTypeList: action.ServiceTypeList
            };
        case ActionType.SERVICE_RING_CHANGE:
            return {
                ...state,
                ServiceManagervalue: action.ServiceManagervalue,
                Servicevalue: action.Servicevalue,
                Componentvalue: action.Componentvalue,
                IDClassificationvalue: action.IDClassificationvalue,
                ServiceLifeCyclevalue: action.ServiceLifeCyclevalue,
                ServiceTypevalue: action.ServiceTypevalue,
                ServiceRingvalue: action.ServiceRingvalue,
                ServiceList: action.ServiceList,
                ComponentList: action.ComponentList,
                ServiceManagerList: action.ServiceManagerList,
                ServiceLifeCyclelist: action.ServiceLifeCyclelist,
                IDClassificationList: action.IDClassificationList,
                ServiceTypeList: action.ServiceTypeList

            };
        case ActionType.GET_FILTER_REFRESH:
            return {
                ...state,
                AcktrackingFilterList: action.AcktrackingFilterList,
                AcktrackingFiltervalue: action.filterid,
                isLoading: false
            };
        case ActionType.OPEN_EMAIL_POPUP:
            return {
                ...state,
                DevOwnerEmailIDs: action.DevOwnerEmailIDs,
                PMOwnerEmailIDs: action.PMOwnerEmailIDs,
                UpdatedUserIDEmailIDs: action.UpdatedUserIDEmailIDs,
                Isopenemailpopup:true
            };
        case ActionType.EMAIL_POPUP_CLOSE:
            return {
                ...state,
                DevOwnerEmailIDs: '',
                PMOwnerEmailIDs: '',
                UpdatedUserIDEmailIDs: '',
                Isopenemailpopup: false
            };
        case ActionType.SET_URL_PARAM_EMPTY:
            return {
                ...state,
                urlparam:''
            };
        case '@@router/LOCATION_CHANGE': {
            const { search } = action.payload.location;
            const params = new URLSearchParams(search);
            const ackid = parseInt(params.get('ID'));
            let urlparam = '';
            if (isNaN(ackid)) {
                urlparam = '';
            }
            else {
                urlparam = String(ackid);
            }
            return {
                ...state,
                urlparam: urlparam,
                AcktrackingFiltervalue: '0',
                NewFiltervalue: '',
                notificationACKId:'0'
            }
        }
        case ActionType.OPEN_ACKTRACKING_POPUP:
            return {
                ...state,
                EditObject: action.EditObject,
                EditAckPopup: true,
                editdropdownlist: action.editdropdownlist,
                checkboxlist: action.checkboxlist,
                savebuttondisabled: action.savebuttondisabled,
                EditObjectDate: action.EditObjectDate
            };
        case ActionType.ACKEDIT_POPUP_CLOSE:
            return {
                ...state,
                EditAckPopup: false,
                editdropdownlist: [],
                EditObject: null,
                EditObjectDate:null
            };
        case ActionType.ACKTRACKING_CONTROLS_CHANGE:
            return {
                ...state,
                EditObject: action.EditObject,
                checkboxlist: action.chklist,
                savebuttondisabled: action.savebuttondisabled
              
            };
        case ActionType.ACKTRACKING_DATE_CHANGE:
            return {
                ...state,
                EditObjectDate: action.EditObjectDate,
                EditObject: action.EditObject,
                savebuttondisabled: action.savebuttondisabled

            };
        case ActionType.RESET_STATE:
            return initialState;

        default:
            return state;
    }
};
