import { IAppThunkAction, ReduxAction } from '../';
import { ActionType, IPropPirLearningsData, IPIRLearningsState, IDropdownOption } from './types';
import { PIRLearningsAPI, CommonAPI} from '../../api';
import moment from 'moment';
import { isNullOrUndefined } from '../../utils';
export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    setloadingtrue: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
    },
    setloadingfalse: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_FALSE
        });
    },
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }

    },
    GetPIRLearningsDate: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        const PIRLearningsDate = getPirLearningsDate();
        dispatch({
            PIRLearningsDate,
            type: ActionType.SET_PIRLEARNINGS_DATE
        });
    },
    handlePIRLearningsDateChange: (PIRLearningsDate: Date): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            PIRLearningsDate,
            type: ActionType.PIRLEARNINGS_DATE_CHANGE
        });
    },
    requestPIRLearningsData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        // Dispatch request
        dispatch({
            type: ActionType.GET_PIRLEARNINGS_REQUEST
        });
        var allPIRData = [];
        var allPIRDataFiltered = [];

        var OwningService = [];
        var RouteCauseCategory = [];
        var PIRFormattedDate;
        var PIRLearningsDate = getState().PIRLearnings.PIRLearningsDate;
        if (PIRLearningsDate === null) {
            PIRFormattedDate = getPirLearningsDate();
        }
        else {
            PIRFormattedDate = moment(PIRLearningsDate).format('MM/DD/YYYY');
        }
        PIRLearningsAPI.GetAllPirLearningsData(PIRFormattedDate)
            .then((pirLearningsData: IPropPirLearningsData[]) => {
                pirLearningsData.map((obj: IPropPirLearningsData) => {
                    allPIRData.push(obj);
                    allPIRDataFiltered.push(obj);
                });
                //----------getting Distinct owning services for filter dropdown ---------//
                OwningService.push({ 'label': 'Select All', 'value': 0 })
                RouteCauseCategory.push({ 'label': 'Select All', 'value': 0 });
                var selectedItemForRootLabel = OwningService[0];
                var selectedItemForLabel = RouteCauseCategory[0];

                var tempService = [];
                var tempRootCause = [];
                var uniqueService = [];
                var uniqueRootCause = [];
                allPIRDataFiltered.map((obj: IPropPirLearningsData) => {
                    tempService.push(obj.serviceName);
                    tempRootCause.push(obj.rootCauseCategory);
                    allPIRDataFiltered["IsMore"] = false;
                });

                tempService.map((serValue, servindex) => {
                    if (uniqueService.indexOf(serValue) === -1) {
                        uniqueService.push({ 'label': serValue, 'value': servindex+1 });
                    }
                });

                tempRootCause.map((rootValue, rootindex) => {
                    if (uniqueRootCause.indexOf(rootValue) === -1) {
                        uniqueRootCause.push({ 'label': rootValue, 'value': rootindex+1 });
                    }
                });


                OwningService = uniqueService;
                RouteCauseCategory = uniqueRootCause;

                OwningService.splice(0, 0, { 'label': 'Select All', 'value': 0 });
                RouteCauseCategory.splice(0, 0, { 'label': 'Select All', 'value': 0 });
               
                //--------Display only 5 lines of text in Shared learning column-----------//
               
                for (var i = 0; i < allPIRDataFiltered.length; i++) {
                    try {
                        allPIRDataFiltered[i]["LearningArray"] = allPIRDataFiltered[i].sharedLearning.split('<br>');
                        allPIRDataFiltered[i]["Count"] = (allPIRDataFiltered[i]["LearningArray"] == null || allPIRDataFiltered[i]["LearningArray"].length <= 5) ? 0 : 5;
                        allPIRDataFiltered[i]["btnText"] = "Show More";
                        allPIRDataFiltered[i]["flag"] = false;
                    }
                    catch (e) {
                        dispatch({
                            type: ActionType.GET_PIRLEARNINGS_FAILURE
                        });
                    }
                }
                //--------Display only 5 lines of text in Shared learning column-----------//

                dispatch({
                    pirLearningsData: allPIRData,
                    selectedItemForLabel,
                    OwningService,
                    selectedItemForRootLabel,
                    RouteCauseCategory,
                    allPIRDataFiltered,
                    type: ActionType.GET_PIRLEARNINGS_SUCCESS
                });
            })
            .catch(error => {
                dispatch({
                    type: ActionType.GET_PIRLEARNINGS_FAILURE
                });
            });

    },
    GetOwnedServices: (serviceDropdownData: IDropdownOption): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var title = serviceDropdownData.label;
        let selectedItemForLabel = serviceDropdownData;
        let allPIRDataFiltered = [];
        let allPIRData = getState().PIRLearnings.pirLearningsData;
        let selectedItemForRootLabel = getState().PIRLearnings.selectedItemForRootLabel;
        allPIRData.map((obj: IPropPirLearningsData) => {
            if (selectedItemForRootLabel.label === 'Select All') {
                if (title === 'Select All') {
                    allPIRDataFiltered = allPIRData;

                }
                else if (obj.serviceName === title) {
                    allPIRDataFiltered.push(obj);
                }
            }
            else if (selectedItemForRootLabel !== null) {
                if (selectedItemForRootLabel.label === obj.rootCauseCategory) {
                    if (obj.serviceName === title || title === 'Select All') {
                        allPIRDataFiltered.push(obj);
                    }
                }
            }
        });
        dispatch({
            selectedItemForLabel,
            allPIRDataFiltered,
            type: ActionType.PIRLEARNINGS_SERVICE_FILTER
        });
    },
    GetRouteCauseFilter: (rootCauseDropdownData: IDropdownOption): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let selectedItemForRootLabel = rootCauseDropdownData;
        var title = rootCauseDropdownData.label;
        let allPIRDataFiltered = [];
        let allPIRData = getState().PIRLearnings.pirLearningsData;
        let selectedItemForLabel = getState().PIRLearnings.selectedItemForLabel;
        allPIRData.map((obj: IPropPirLearningsData) => {
            if (selectedItemForLabel !== null) {
                if (selectedItemForLabel.label === 'Select All') {
                    if (title === 'Select All') {
                        allPIRDataFiltered = allPIRData;

                    }
                    else if (obj.rootCauseCategory === title) {
                        allPIRDataFiltered.push(obj);
                    }
                }
                else if (selectedItemForLabel.label === obj.serviceName) {
                    if (obj.rootCauseCategory === title || title === 'Select All') {
                        allPIRDataFiltered.push(obj);
                    }
                }
            }
        });
        dispatch({
            selectedItemForRootLabel,
            allPIRDataFiltered,
            type: ActionType.PIRLEARNINGS_ROOTCAUSE_FILTER
        });
    },
    togglePIR: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var flag = getState().PIRLearnings.flag;
        var displayText = getState().PIRLearnings.displayText;
        const allPIRDataFiltered = getState().PIRLearnings.allPIRDataFiltered;
        if (flag) {
            flag = false;
            displayText = "Expand All";
            allPIRDataFiltered.map((obj: IPropPirLearningsData) => {
                obj.IsMore = false;
            });
        }
        else {
            flag = true;
            displayText = "Collapse All";
            allPIRDataFiltered.map((obj: IPropPirLearningsData) => {
                obj.IsMore = true;
            });
        }
        dispatch({
            allPIRDataFiltered,
            displayText,
            flag,
            type: ActionType.TOGGLE_PIR
        });
    },
    ChangedDisplayText: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var search = e.target.value;
        var displayText = getState().PIRLearnings.displayText;
        displayText = "Collapse All";
        const allPIRDataFiltered = getState().PIRLearnings.allPIRDataFiltered;
        if (search === '' || search === undefined) {
            displayText = "Expand All";
            allPIRDataFiltered.map((obj: IPropPirLearningsData) => {
                obj.IsMore = false;
            });
        }
        else {
            allPIRDataFiltered.map((obj: IPropPirLearningsData) => {
                obj.IsMore = true;
            });
        }
        dispatch({
            allPIRDataFiltered,
            searchText: search,
            displayText,
            type: ActionType.CHANGE_SEARCHTEXT_PIR
        });
    },
    showdisplayText: (e, row): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var flag = getState().PIRLearnings.flag;
        const allPIRDataFiltered = getState().PIRLearnings.allPIRDataFiltered;
        let newArr = [];
            allPIRDataFiltered.map((obj: IPropPirLearningsData) => {
                    newArr.push(obj);
            });
        if (row["IsMore"] !== null && row["IsMore"] === true) {
            newArr.filter(item => item.pir === row.pir)[0]["IsMore"] = false;
            flag = true;
        }
        else {
            newArr.filter(item => item.pir === row.pir)[0]["IsMore"] = true;
            flag = false;
        }
        dispatch({
            allPIRDataFiltered: newArr,
            flag: flag,
            type: ActionType.PIRLEARNINGS_SHOWMORE_TEXT
        });

    }
}
const getPirLearningsDate = () => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(),
        date.getMonth(), 1); 
    return firstDay;
}



