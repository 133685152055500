import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import { actionCreators, reducer, IPropPirLearningsData } from '../../store/PIRLearnings';
import { PIRLearningsTable } from '../PIRLearnings/child-components';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap'
import { Row, Col, Card, CardBody } from 'reactstrap';
import RichTextEditor from 'react-rte';
import { PIRLearningsAPI } from '../../api';
import { toast } from 'react-toastify';
import { isNullOrUndefined, isArrayWithLength, renderToastifyMsg } from '../../utils';
import bxloader from '../../assets/images/bxloader.gif'

type PIRLearningsProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly username: string }
    & typeof useParams;

const PIRLearnings: React.FC<PIRLearningsProps> = ({
    pirLearningsData,
    isAuthenticated,
    allPIRDataFiltered,
    requestPIRLearningsData,
    isLoading,
    togglePIR,
    displayText,
    ChangedDisplayText,
    PIRLearningsDate,
    GetPIRLearningsDate,
    handlePIRLearningsDateChange,
    selectedItemForLabel,
    OwningService,
    selectedItemForRootLabel,
    RouteCauseCategory,
    setloadingtrue,
    setloadingfalse,
    pirtextLength,
    showdisplayText,
    searchText,
    GetOwnedServices,
    GetRouteCauseFilter,
    username,
    addUserVisit
}) => {
    useEffect(() => { 
        if (isAuthenticated)
            addUserVisit(0, 'PIRLearnings.tsx_v2');       
    }, [isAuthenticated]);
    useEffect(() => {
        if (PIRLearningsDate === null) {
            GetPIRLearningsDate();
        }
        else {
            requestPIRLearningsData();
        }

    }, [PIRLearningsDate]);
    const toastIdRef = useRef(null);
    const [showeditpopup, setshoweditpopup] = useState(false);
    const [sharedLearning, setsharedLearning] = useState(RichTextEditor.createEmptyValue());
    const [selectedRow, setselectedRow] = useState(null);
    const [Disablesavebutton, setDisablesavebutton] = useState(false);
    const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
            { label: 'Italic', style: 'ITALIC' },
            { label: 'Underline', style: 'UNDERLINE' }
        ],
        BLOCK_TYPE_DROPDOWN: [
            { label: 'Normal', style: 'unstyled' },
            { label: 'Heading Large', style: 'header-one' },
            { label: 'Heading Medium', style: 'header-two' },
            { label: 'Heading Small', style: 'header-three' }
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item' },
            { label: 'OL', style: 'ordered-list-item' }
        ]
    };
    
    //=============== function to close Edit PIRLearnings popup =========================//
    const closeEditPIRlearningsPopup = () => {
        setshoweditpopup(false);
    }

    //=============function to Refresh  the  values for PIR Learnings====================//
    const PIRLearningsRowRefresh = (e, row, index) => {
        setloadingtrue();
        var row = pirLearningsData[index];
        row.userName = username;
        PIRLearningsAPI.AddPIRLearningsData(row)
            .then((result: number) => {
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg('PIR Data for service ' + row.serviceName + ' refreshed successfully',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    requestPIRLearningsData();
                    GetOwnedServices(selectedItemForLabel);
                    GetRouteCauseFilter(selectedItemForRootLabel);
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Entered PIR " + row.pir + " does not exist in IcM kusto DB",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });

    }
    //=============function to open Modal popup when clicked Edit for PIR Learnings====================//
    const PIRLearningsRowEdit = (e, row) => {
        setDisablesavebutton(false);
        setshoweditpopup(true);
        setselectedRow({
            id: row.id, isAddedToAgenda: row.isAddedToAgenda, isAzure: row.isAzure, isValidPir: row.isValidPir, kpiDate: row.kpiDate,
            occuredRing: row.occuredRing, pir: row.pir, pirRereview: row.pirRereview, rootCauseCategory: row.rootCauseCategory, serviceName: row.serviceName, servicesImpacted: row.servicesImpacted,
            sharedLearning: row.sharedLearning, summary: row.summary, ttd: row.ttd, tte: row.tte, ttm: row.ttm, ttn: row.ttn, resources: row.resources
        });
        setsharedLearning(RichTextEditor.createValueFromString(row.sharedLearning, "html"));
    }

    //=============function to close Modal popup when clicked Cancel in Modal Popup for Edit PIR Learnings====================//
    const PIRLearningsRowCancel = () => {
        setsharedLearning(RichTextEditor.createEmptyValue());
        setshoweditpopup(false);
        setDisablesavebutton(false);
    }

    //================ function to set Shared Learning Value ========//
    const UpdatesharedLearning = (e) => {
            setsharedLearning(e);
    }

    //=============function to Save the  values for PIR Learnings====================//
    const PIRLearningsRowSave = () => {
        setDisablesavebutton(true);
        var sharedLearningtext = sharedLearning.toString("html");
        if (sharedLearningtext === null || sharedLearningtext === undefined || sharedLearningtext === '') {
            setDisablesavebutton(false);
            toastIdRef.current = toast.error(
                renderToastifyMsg("Please enter the Shared Learning",
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
            return false;
        }
        else if (sharedLearningtext.replace("<p><br></p>", "") === "") {
            setDisablesavebutton(false);
            toastIdRef.current = toast.error(
                renderToastifyMsg("Please enter the Shared Learning",
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
            return false;
        }
        setloadingtrue();
        if (selectedRow.id !== undefined && selectedRow.id !== null && selectedRow.id !== '' && selectedRow.id !== -1) {
            selectedRow.userName = username;
            selectedRow.sharedLearning = (sharedLearningtext === undefined || sharedLearningtext === null) ? "" : sharedLearning.toString("html");
            PIRLearningsAPI.AddPIRLearningsData(selectedRow)
                .then((result: number) => {
                    if (result != -1) {
                        setshoweditpopup(false);
                        toastIdRef.current = toast.success(
                            renderToastifyMsg('PIR Data for service ' + selectedRow.serviceName + ' Updated successfully',
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                    else {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Entered PIR " + selectedRow.pir + " does not exist in IcM kusto DB",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                    requestPIRLearningsData();
                    GetOwnedServices(selectedItemForLabel);
                    GetRouteCauseFilter(selectedItemForRootLabel);
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while updating",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
            
        }
        

    }

    return (
        <div>
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        <PIRLearningsTable
                            PIRLearnings={allPIRDataFiltered}
                            togglePIR={togglePIR}
                            displayText={displayText}
                            ChangedDisplayText={ChangedDisplayText}
                            PIRLearningsDate={PIRLearningsDate}
                            handlePIRLearningsDateChange={handlePIRLearningsDateChange}
                            selectedItemForLabel={selectedItemForLabel}
                            OwningService={OwningService}
                            selectedItemForRootLabel={selectedItemForRootLabel}
                            RouteCauseCategory={RouteCauseCategory}
                            setshoweditpopup={(e, row) => PIRLearningsRowEdit(e, row)}
                            RowRefresh={(e, row, index) => PIRLearningsRowRefresh(e, row, index)}
                            pirtextLength={pirtextLength}
                            showdisplayText={(e,row) => showdisplayText(e, row)}
                            searchText={searchText}
                            GetOwnedServices={GetOwnedServices}
                            GetRouteCauseFilter={GetRouteCauseFilter}
                        />
                    </div>
                </section>
            </LoadingOverlay>
                <Modal show={showeditpopup} onHide={closeEditPIRlearningsPopup} size='lg' backdrop='static' >
                <Modal.Header closeButton>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='12'>
                                <h4><b> Shared Learning </b></h4>
                            </Col>

                        </Row>
                    </div>

                </Modal.Header>
                <Modal.Body>
                    <Row style={{ paddingTop: '15px' }}>
                        <Col lg='12'>
                            {
                                    <RichTextEditor
                                        value={sharedLearning}
                                        onChange={UpdatesharedLearning}
                                        toolbarConfig={toolbarConfig}
                                        editorClassName="trix-editorpir"
                                        type="string"
                                        multiline
                                        variant="filled"
                                    />
                            }
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ width: '100%' }}>
                        <Row>
                                <Col lg='3'></Col>
                                <Col lg='6'>

                                    {
                                        (isLoading) ?
                                            <div style={{ 'textAlign': "center" }}>
                                                <img src={bxloader} style={{ width: '80px', height: '40px' }} />
                                            </div>
                                            :
                                            <div>
                                                <Button className='pirlearningssaveModelbutton' onClick={PIRLearningsRowSave} disabled={Disablesavebutton}>Save</Button>&emsp;
                                                <Button className='pirlearningssaveModelbutton' onClick={PIRLearningsRowCancel}>Cancel</Button>
                                            </div>

                                    }
                            </Col>
                            <Col lg='3'></Col>
                        </Row>
                    </div>
                </Modal.Footer>
                </Modal>
            
         </div>
        
    );
};


const mapStateToProps = (state: IApplicationState) => ({
    ...state.PIRLearnings,
    isAuthenticated: state.authUser.isAuthenticated,
    username: state.authUser.login

})


export default connect(mapStateToProps, actionCreators)(PIRLearnings);
