import * as  React from 'react';
import { isArrayWithLength } from './isArrayWithLength';
const SortLogic = (dataArray, config = null) => {
    //Setting up the configuration {column, order}
    const [sortConfig, setSortConfig] = React.useState(null);

    const requestSort = key => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    }

    let sortedArray = React.useMemo(() => {
        let tempArray = [...dataArray];
        let finalarray = [];
        if (sortConfig !== null) {
            let nullItems = [];

            let dummyarray = [];

            for (var i = 0; i < tempArray.length; i++) {
                var item = tempArray[i];
                var checkActualKey = sortConfig.key;
                let isValExists = false;
                Object.keys(item).map((key, i) => {
                    if (!isValExists) {
                        if (key != null && key != undefined) {
                            if (key.toString().toLowerCase() === sortConfig.key.toString().toLowerCase()) {
                                isValExists = true;
                                checkActualKey = key;
                            }
                        }
                     }
                });

                if (!tempArray[i][checkActualKey]) {
                    
                    nullItems.push(tempArray[i]);
                }
                else {
                    dummyarray.push(tempArray[i]);
                }
            }
            dummyarray.sort((a, b) => {
                if (a[checkActualKey].toLowerCase() < b[checkActualKey].toLowerCase()) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[checkActualKey].toLowerCase() > b[checkActualKey].toLowerCase()) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });

            dummyarray.forEach(i => {

                finalarray.push(i)
            });
            nullItems.forEach(i => {

                finalarray.push(i)
            });
            return finalarray;
        }
        else {
            return tempArray;
        }
        
    }, [dataArray, sortConfig]);

    return { result: sortedArray, requestSort, sortConfig };
}

export default SortLogic;
