import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IdentityWeeklyNewsLetterState } from './types';

const initialState = Object.freeze<IdentityWeeklyNewsLetterState>({
    newsLetterGroupsData: [],
    isLoading: false,
    IsLivesitePMOrUser: false,
    WeekStartDate: null,
    StartDate: null,
    EndDate: null,
    newsLetterWeeklyAvailabilityData: [],
    newsLetterWeeklySafeflyData: [],
    newsLetterWeeklySafeflyDataOptions: [],
    SafeflyMetricsTotal: 0,
    newsLetterWeeklyChangeToolData: [],
    newsLetterWeeklyChangeToolDataOptions: [],
    ChangeToolMetricsTotal: 0,
    newsLetterWeeklyChangeExceptionData: [],
    newsLetterWeeklyChangeExceptionDataOptions: [],
    ChangeExceptionMetricsTotal: 0,
    newsLetterObjectivesData: [],
    newsLetterOKRsData: [],
    newsLettertopOutagesData: [],
    originalNewsLettertopOutagesData: [],
    newsLettertopOutagesDataTable: [],    
    newsLetterDivisionalObjectivesData: [],
    newsLetterOKRsObjectives: [],
    selectedObjective: null,
    selectedKeyResults: [],
    newsLetterDivisionalKeyResultsData: [],
    ObjectiveStartDate: null,
    ObjectiveEndDate: null,
    OKRWeekStartDate: null,
    newObjective: '',
    isAddNewObjective: false,
    newsLetterSev1Sev2IncidentsData: [],
    newsLetterSev1Sev2IncidentsDataOptions: [],
    appSessionToken: null,
    sev1TotalCount: 0,
    sev2TotalCount: 0,
    newsLetterWeeklyOutagesData: [],
    newsLetterWeeklyOutagesDataOptions: [],
    newsLetterWeeklyCommsData: [],
    newsLetterWeeklyCommsDataOptions: [],
    outagesWeeksHeaderList: [],
    outagesWeeklySortedData: [],
    commsWeeklySortedData: [],
    commsWeeksHeaderList: [],
    stylesData:[]
});

export const reducer = (
    state: IdentityWeeklyNewsLetterState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true

            };
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false

            };
        case ActionType.WEEKLY_START_DATE_CHANGE:
            return {
                ...state,
                WeekStartDate: action.WeekStartDate,
                StartDate: action.StartDate,
                EndDate: action.EndDate,
                appSessionToken: action.sessionToken
            };
        case ActionType.TOPOUTAGES_INPUT_CHANGE:
            return {
                ...state,
                newsLettertopOutagesData: action.newsLettertopOutagesData
            };
        case ActionType.GET_IDENTITYWEEKLYNEWSLETTER_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.GET_IDENTITYWEEKLYNEWSLETTERGROUPSDATA_SUCCESS:  // === for IDENTITY WEEKLY NEWS LETTER GROUPS Data success ===============//
            return {
                ...state,
                newsLetterGroupsData: action.newsLetterGroupsData,
                isLoading: (action.count >= 11) ? false : state.isLoading
            };
        case ActionType.GET_IDENTITYWEEKLYNEWSLETTERGROUPSDATA_FAILURE:  // === for IDENTITY WEEKLY NEWS LETTER GROUPS Data failure ===============//
            return {
                ...state,
                newsLetterGroupsData: [],
                isLoading: (action.count >= 11) ? false : state.isLoading
            };
        case ActionType.GET_IDENTITYWEEKLYNEWSLETTERWEEKLYAVAILABILITYDATA_SUCCESS:  // === for IDENTITY WEEKLY NEWS LETTER WEEKLY AVAILABILITY Data success ===============//
            return {
                ...state,
                newsLetterWeeklyAvailabilityData: action.newsLetterWeeklyAvailabilityData,
                isLoading: (action.count >= 11) ? false : state.isLoading
            };
        case ActionType.GET_IDENTITYWEEKLYNEWSLETTERWEEKLYAVAILABILITYDATA_FAILURE:  // === for IDENTITY WEEKLY NEWS LETTER WEEKLY AVAILABILITY Data failure ===============//
            return {
                ...state,
                newsLetterWeeklyAvailabilityData: [],
                isLoading: (action.count >= 11) ? false : state.isLoading
            };
        case ActionType.GET_IDENTITYWEEKLYNEWSLETTERSAFEFLYDATA_SUCCESS:  // === for IDENTITY WEEKLY NEWS LETTER WEEKLY SAFEFLY Data success ===============//
            return {
                ...state,
                newsLetterWeeklySafeflyData: action.newsLetterWeeklySafeflyData,
                newsLetterWeeklySafeflyDataOptions: action.newsLetterWeeklySafeflyDataOptions,
                SafeflyMetricsTotal: action.SafeflyMetricsTotal,
                isLoading: (action.count >= 11) ? false : state.isLoading
            };
        case ActionType.GET_IDENTITYWEEKLYNEWSLETTERSAFEFLYDATA_FAILURE:  // === for IDENTITY WEEKLY NEWS LETTER WEEKLY SAFEFLY Data failure ===============//
            return {
                ...state,
                newsLetterWeeklySafeflyData: [],
                newsLetterWeeklySafeflyDataOptions: [],
                SafeflyMetricsTotal: 0,
                isLoading: (action.count >= 11) ? false : state.isLoading
            };
        case ActionType.GET_IDENTITYWEEKLYNEWSLETTERCHANGETOOLDATA_SUCCESS:  // === for IDENTITY WEEKLY NEWS LETTER CHANGE TOOL METRICS Data success ===============//
            return {
                ...state,
                newsLetterWeeklyChangeToolData: action.newsLetterWeeklyChangeToolData,
                newsLetterWeeklyChangeToolDataOptions: action.newsLetterWeeklyChangeToolDataOptions,
                ChangeToolMetricsTotal: action.ChangeToolMetricsTotal,
                isLoading: (action.count >= 11) ? false : state.isLoading
            };
        case ActionType.GET_IDENTITYWEEKLYNEWSLETTERCHANGETOOLDATA_FAILURE:  // === for IDENTITY WEEKLY NEWS LETTER CHANGE TOOL METRICS Data failure ===============//
            return {
                ...state,
                newsLetterWeeklyChangeToolData: [],
                newsLetterWeeklyChangeToolDataOptions: [],
                ChangeToolMetricsTotal: 0,
                isLoading: (action.count >= 11) ? false : state.isLoading
            };
        case ActionType.GET_IDENTITYWEEKLYNEWSLETTERCHANGEEXCEPTIONDATA_SUCCESS:  // === for IDENTITY WEEKLY NEWS LETTER CHANGE EXCEPTION METRICS Data success ===============//
            return {
                ...state,
                newsLetterWeeklyChangeExceptionData: action.newsLetterWeeklyChangeExceptionData,
                newsLetterWeeklyChangeExceptionDataOptions: action.newsLetterWeeklyChangeExceptionDataOptions,
                ChangeExceptionMetricsTotal: action.ChangeExceptionMetricsTotal,
                isLoading: (action.count >= 11) ? false : state.isLoading
            };
        case ActionType.GET_IDENTITYWEEKLYNEWSLETTERCHANGEEXCEPTIONDATA_FAILURE:  // === for IDENTITY WEEKLY NEWS LETTER CHANGE EXCEPTION METRICS Data failure ===============//
            return {
                ...state,
                newsLetterWeeklyChangeExceptionData: [],
                newsLetterWeeklyChangeExceptionDataOptions: [],
                ChangeExceptionMetricsTotal: 0,
                isLoading: (action.count >= 11) ? false : state.isLoading
            };
        case ActionType.GET_IDENTITYWEEKLYNEWSLETTEROBJECTIVESDATA_SUCCESS:  // === for IDENTITY WEEKLY NEWS LETTER Objectives Data success ===============//
            return {
                ...state,
                newsLetterObjectivesData: action.newsLetterObjectivesData,
                isLoading: (action.count >= 11) ? false : state.isLoading
            };
        case ActionType.GET_IDENTITYWEEKLYNEWSLETTEROBJECTIVESDATA_FAILURE:  // === for IDENTITY WEEKLY NEWS LETTER Objectives Data failure ===============//
            return {
                ...state,
                newsLetterObjectivesData: [],
                isLoading: (action.count >= 11) ? false : state.isLoading
            };
        case ActionType.GET_IDENTITYWEEKLYNEWSLETTEROKRSDATA_SUCCESS:  // === for IDENTITY WEEKLY NEWS LETTER OKRs Data success ===============//
            return {
                ...state,
                newsLetterOKRsData: action.newsLetterOKRsData,
                isLoading: (action.count >= 11) ? false : state.isLoading
            };
        case ActionType.GET_IDENTITYWEEKLYNEWSLETTEROKRSDATA_FAILURE:  // === for IDENTITY WEEKLY NEWS LETTER OKRs Data failure ===============//
            return {
                ...state,
                newsLetterOKRsData: [],
                isLoading: (action.count >= 11) ? false : state.isLoading
            };
        case ActionType.GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_SUCCESS:  // === for IDENTITY WEEKLY NEWS LETTER TopOutages Data success ===============//
            return {
                ...state,
                newsLettertopOutagesData: action.newsLettertopOutagesData,
                originalNewsLettertopOutagesData: action.originalNewsLettertopOutagesData,
                isLoading: (action.count >= 11) ? false : state.isLoading
            };
        case ActionType.GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_FAILURE:  // === for IDENTITY WEEKLY NEWS LETTER TopOutages Data failure ===============//
            return {
                ...state,
                newsLettertopOutagesData: [],
                originalNewsLettertopOutagesData: [],
                isLoading: (action.count >= 11) ? false : state.isLoading
            };
        case ActionType.GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_TABLE_SUCCESS:  // === for IDENTITY WEEKLY NEWS LETTER TopOutages Data success ===============//
            return {
                ...state,
                newsLettertopOutagesDataTable: action.newsLettertopOutagesDataTable,                
                isLoading: (action.count >= 11) ? false : state.isLoading
            };
        case ActionType.GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_TABLE_FAILURE:  // === for IDENTITY WEEKLY NEWS LETTER TopOutages Data failure ===============//
            return {
                ...state,
                newsLettertopOutagesDataTable: [],                
                isLoading: (action.count >= 11) ? false : state.isLoading
            };      
        case ActionType.DELETE_TOPOUTAGE_DATA_REQUEST:  // === for IDENTITY WEEKLY NEWS LETTER TopOutages Delete Data Request ===============//
            return {
                ...state,
                isLoading: true
            };
        case ActionType.DELETE_TOPOUTAGE_DATA_FAIL:  // === for IDENTITY WEEKLY NEWS LETTER TopOutages Delete Data failure ===============//
            return {
                ...state,
                newsLettertopOutagesData: [],
                originalNewsLettertopOutagesData: [],
                isLoading: false
            };
        case ActionType.GET_IDENTITYWEEKLYNEWSLETTERSEV1SEV2INCIDENTSDATA_SUCCESS:  // === for IDENTITY WEEKLY NEWS LETTER SEV1 SEV2 INCIDENTS Data success ===============//
            return {
                ...state,
                sev1TotalCount: action.sev1Total,                
                sev2TotalCount: action.sev2Total,
                isLoading: (action.count >= 11) ? false : state.isLoading
            };
        case ActionType.GET_IDENTITYWEEKLYNEWSLETTERCHANGETOOLDATA_FAILURE:  // === for IDENTITY WEEKLY NEWS LETTER SEV1 SEV2 INCIDENTS Data failure ===============//
            return {
                ...state,
                newsLetterSev1Sev2IncidentsData: [],
                newsLetterSev1Sev2IncidentsDataOptions: [],
                isLoading: (action.count >= 11) ? false : state.isLoading
            };
        case '@@router/LOCATION_CHANGE': {
            const { search } = action.payload.location;
            const params = new URLSearchParams(search);
            var newurl = window.location.pathname;
            //Below conditions only for direct url access like bookmarks
            if (newurl.indexOf("IdentityWeeklyNewsLetter") !== -1) {
                return {
                    ...state,
                    WeekStartDate: null,
                    StartDate: null,
                    EndDate: null
                }
            }
        };

            // Divisional OKRs
        case ActionType.GET_IDENTITYNEWSLETTERDIVSIONALOBJECTIVES_SUCCESS:  // === for IDENTITY WEEKLY NEWS LETTER Objectives Data success ===============//
            return {
                ...state,
                newsLetterDivisionalObjectivesData: action.newsLetterDivisionalObjectivesData,
                newsLetterOKRsObjectives: action.newsLetterOKRsObjectives,
                selectedObjective: action.selectedObjective,
                isLoading: (action.count >= 11) ? false : state.isLoading
            };
        case ActionType.GET_IDENTITYNEWSLETTERDIVSIONALOBJECTIVES_FAILURE:  // === for IDENTITY WEEKLY NEWS LETTER Objectives Data Failure ===============//
            return {
                ...state,
                newsLetterDivisionalObjectivesData: [],
                newsLetterOKRsObjectives: [],
                selectedObjective: null,
                isLoading: (action.count >= 11) ? false : state.isLoading
            };
        case ActionType.GET_IDENTITYNEWSLETTERDIVSIONALOKRS_SUCCESS:  // === for IDENTITY WEEKLY NEWS LETTER Key Results Data success ===============//
            return {
                ...state,
                newsLetterDivisionalKeyResultsData: action.newsLetterDivisionalKeyResultsData,
                isLoading: (action.count >= 11) ? false : state.isLoading
            };
        case ActionType.GET_IDENTITYNEWSLETTERDIVSIONALOKRS_FAILURE:  // === for IDENTITY WEEKLY NEWS LETTER Key Results Data failure ===============//
            return {
                ...state,
                newsLetterDivisionalKeyResultsData: [],
                isLoading: (action.count >= 11) ? false : state.isLoading
            };
        case ActionType.GET_IDENTITYNEWSLETTEROBJECTIVES_REQUEST:
            return {
                ...state,
                isLoading: true,
                newsLetterDivisionalObjectivesData: [],
                newsLetterOKRsObjectives: [],
                selectedObjective: null
            };
        case ActionType.GET_IDENTITYNEWSLETTEROBJECTIVES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                newsLetterDivisionalObjectivesData: action.newsLetterDivisionalKeyResultsData,
                newsLetterOKRsObjectives: action.newsLetterOKRsObjectives,
                selectedObjective: action.selectedObjective,
                isAddNewObjective: action.isAddNewObjective,
                newObjective: action.newObjective
            };
        case ActionType.GET_IDENTITYNEWSLETTEROBJECTIVES_FAILURE:
            return {
                ...state,
                isLoading: false,
                newsLetterDivisionalObjectivesData: [],
                newsLetterOKRsObjectives: [],
                selectedObjective: null
            };
        case ActionType.GET_IDENTITYNINTYDAYSOUTAGESLDATA_SUCCESS:
            return {
                ...state,
                outagesWeeksHeaderList: action.outagesWeeksHeaderList,
                outagesWeeklySortedData: action.outagesWeeklySortedData,
                newsLetterWeeklyOutagesDataOptions: action.newsLetterWeeklyOutagesDataOptions,
                newsLetterWeeklyOutagesData: action.newsLetterWeeklyOutagesData
            };
        case ActionType.GET_IDENTITYNINTYDAYSCOMMSDATA_SUCCESS:
            return {
                ...state,
                commsWeeksHeaderList: action.commsWeeksHeaderList,
                commsWeeklySortedData: action.commsWeeklySortedData,
                newsLetterWeeklyCommsDataOptions: action.newsLetterWeeklyCommsDataOptions,
                newsLetterWeeklyCommsData: action.newsLetterWeeklyCommsData
            };
        case ActionType.GET_IDENTITYNINTYDAYSCOMMSDATA_FAILURE:
            return {
                ...state,
                commsWeeksHeaderList: [],
                commsWeeklySortedData: [],
                newsLetterWeeklyCommsDataOptions: [],
                newsLetterWeeklyCommsData: []
            };
        case ActionType.GET_IDENTITYNINTYDAYSOUTAGESLDATA_FAILURE:
            return {
                ...state,
                outagesWeeksHeaderList: [],
                outagesWeeklySortedData: [],
                newsLetterWeeklyOutagesDataOptions: [],
                newsLetterWeeklyOutagesData: []
            };
        case ActionType.GET_IDENTITYNEWSLETTERKEYRESULTS_REQUEST:
            return {
                ...state,
                isLoading: true,
                newsLetterDivisionalKeyResultsData: [],
                selectedKeyResults: []
            };
        case ActionType.GET_IDENTITYNEWSLETTERKEYRESULTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                newsLetterDivisionalKeyResultsData: action.newsLetterDivisionalKeyResultsData,
                selectedKeyResults: action.selectedKeyResults
            };
        case ActionType.GET_IDENTITYNEWSLETTERKEYRESULTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                newsLetterDivisionalKeyResultsData: [],
                selectedKeyResults: []
            };
        case ActionType.IDENTITYNEWSLETTER_OKROBJECTIVE_CHANGE:  // === for IDENTITY WEEKLY NEWS LETTER OKR Objective Change===============//
            return {
                ...state,
                selectedObjective: action.selectedObjective,
                selectedKeyResults: action.selectedKeyResults,
                ObjectiveStartDate: action.ObjectiveStartDate,
                ObjectiveEndDate: action.ObjectiveEndDate,
                isAddNewObjective: action.isAddNewObjective,
                isLoading: false
            };
        case ActionType.IDENTITYNEWSLETTER_OBJECTIVES_STARTDATE_CHANGE:  // === for IDENTITY WEEKLY NEWS LETTER OKR Objective Start Date Change===============//
            return {
                ...state,
                ObjectiveStartDate: action.ObjectiveStartDate
            };
        case ActionType.IDENTITYNEWSLETTER_OBJECTIVES_ENDDATE_CHANGE:  // === for IDENTITY WEEKLY NEWS LETTER OKR Objective End Date Change===============//
            return {
                ...state,
                ObjectiveEndDate: action.ObjectiveEndDate
            };
        case ActionType.NEWSLETTER_KEYRESULTS_CONTROLS_CHANGE:
            return {
                ...state,
                selectedKeyResults: action.selectedKeyResults
            };
        case ActionType.IDENTITYWEEKLY_OKRS_START_DATE_CHANGE:  // === for IDENTITY WEEKLY NEWS LETTER OKR Start Date Change===============//
            return {
                ...state,
                OKRWeekStartDate: action.OKRWeekStartDate
            };
        case ActionType.NEWSLETTER_OBJECTIVES_CANCEL_CHANGE:
            return {
                ...state,
                isAddNewObjective: action.isAddNewObjective,
                newObjective: action.newObjective,
                ObjectiveStartDate: action.ObjectiveStartDate,
                ObjectiveEndDate: action.ObjectiveEndDate,
                selectedObjective: action.selectedObjective
            };
        case ActionType.IDENTITYNEWSLETTER_OBJECTIVE_ADD:
            return {
                ...state,
                newObjective: action.newObjective
            };
        case ActionType.IDENTITYNEWSLETTER_RESET_OKRS_INPUTS:
            return {
                ...state,
                OKRWeekStartDate: action.OKRWeekStartDate,
                selectedKeyResults: action.selectedKeyResults,
                ObjectiveStartDate: action.ObjectiveStartDate,
                ObjectiveEndDate: action.ObjectiveEndDate
            };  
        case ActionType.GET_CSS_BY_COMPONENT:
            return {
                ...state,
                stylesData: action.stylesData
            };
        case '@@router/LOCATION_CHANGE': {
            const { search } = action.payload.location;
            const params = new URLSearchParams(search);
            var newurl = window.location.pathname;
            //Below conditions only for direct url access like bookmarks
            if (newurl.indexOf("DivisionalOKRs") !== -1) {
                return {
                    ...state,
                    OKRWeekStartDate: null,
                    selectedKeyResults: [],
                    ObjectiveStartDate: null,
                    ObjectiveEndDate: null
                }
            }
        };
        case ActionType.USER_CHECK:
            return {
                ...state,
                IsLivesitePMOrUser: action.IsLivesitePMOrUser
            };
        case ActionType.RESET_STATE:
            return initialState;
        case ActionType.SEND_EMAIL_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.SEND_EMAIL_REQUEST_FAIL:
            return {
                ...state,
                isLoading: false
            };
        case ActionType.SEND_EMAIL_REQUEST_PASS:
            return {
                ...state,
                isLoading: false
            };
        case ActionType.SET_EMAIL_LOADING_TRUE:
            return {
                ...state,
                isLoading: true
            };
        default:
            return state;
    }
};