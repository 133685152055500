import { FunctionReturnTypes, ReduxAction } from '../';
import { actionCreators } from './actions';
import { ActionType, IFilterServiceHealthState } from './types';
import { REPORT_TYPE_DROPDOWN } from '../../config/constants';

const initialState = Object.freeze<IFilterServiceHealthState>({
    isLoading: false,
    checkboxValue: false,
    selectedDropdownOption: REPORT_TYPE_DROPDOWN[0],
  //  selectedViewDropdownOption: REPORT_TYPE_DROPDOWN[0],
    dailyViewData:[]
});

export const reducer = (
    state: IFilterServiceHealthState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {

    const action = incomingAction as ReduxAction;

    switch (action.type) {
        
        case ActionType.DROPDOWN_SELECT:
            return {
                ...state,
                selectedDropdownOption: action.option || REPORT_TYPE_DROPDOWN[0]
            };
        case ActionType.CHECK_SAMPLE_BOX:
            return {
                ...state,
                checkboxValue: !!action.checked
            };
        case ActionType.REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.RECEIVE:
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly handle out-of-order responses.
            const { dailyViewData } = action;
            return {
                ...state,
                dailyViewData,
                isLoading: false
            };
             
        default:
            return state;
    }
};


