import React, { useEffect, useState } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import { actionCreators, reducer } from '../../store/ReachabilityMetrics';
import { ReachabilityMetricsTable } from '../ReachabilityMetrics/child-components';
import LoadingOverlay from 'react-loading-overlay';
import { isNullOrUndefined, isArrayWithLength, renderToastifyMsg } from '../../utils';
import moment from 'moment';
import {
    Row,
    Col, Card, CardText
} from 'reactstrap';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap'

type ReachabilityMetricsProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly history: History }
    & typeof useParams;

const ReachabilityMetrics: React.FC<ReachabilityMetricsProps> = ({
    isLoading,
    addUserVisit,
    isAuthenticated,
    GetReachabilityMetricsSelectedDate,
    onselectedDateChange,
    GetReachabilityData,
    selectedDate,
    reachabilityData,
    FilterreachabilityData,
    reachabilityErrorData,
    searchText,
    UpdateSearchText,
    filterReachabilityData_Red,
    checkedFilterRedDailyData,
    checkHistory,
    onCheckHistory,
    Errorcolumn,
    sortColumnError,
    sortOrder,
    GetDateBackwardData,
    GetDateForwardData,
    IsEdit,
    disableSave,
    AddToHistory,
    reachabilitysortOrder,
    Reachabilitycolumn,
    sortReachabilityColumn,
    ShowHistory1,
    filterweekdata,
    weekfilter,
    options,
    enableEditData,
    CancelEdit,
    GetConfigValues,
    weekDetailsData,
    ShowHistory,
    inputValueChanged,
    updateComments,
    KPI_History,
    serviceId,
    handleRetainPageOnCheck,
    chkRetainPage
}) => {

    useEffect(() => {
        if (isAuthenticated)
            addUserVisit(0, 'ReachabilityMetrics.tsx_v2');
    }, [isAuthenticated]);

    useEffect(() => {
        if (selectedDate === null) {
            GetReachabilityMetricsSelectedDate();
        }
        else {
            GetConfigValues();
            GetReachabilityData(3);
        }
    }, [selectedDate, serviceId]);

    const [Historyshow, SetHistoryshow] = useState(false);

    //============== function to open history Popup ===========================//
    const showHistorypopup = (count, row, weekid) => {
        ShowHistory(count, row, weekid);
        SetHistoryshow(true);
    }
    //=================== function to close the history Popup =================//
    const handlehistoryClose = () => {
        SetHistoryshow(false);
    }

    const RetainPageOnCheck = (e) => {
        handleRetainPageOnCheck(e);
    }

    return (
        <div>
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        <ReachabilityMetricsTable
                            onselectedDateChange={onselectedDateChange}
                            reachabilityData={reachabilityData}
                            FilterreachabilityData={FilterreachabilityData}
                            reachabilityErrorData={reachabilityErrorData}
                            selectedDate={selectedDate}
                            searchText={searchText}
                            UpdateSearchText={UpdateSearchText}
                            filterReachabilityData_Red={filterReachabilityData_Red}
                            checkedFilterRedDailyData={checkedFilterRedDailyData}
                            onCheckHistory={onCheckHistory}
                            checkHistory={checkHistory}
                            Errorcolumn={Errorcolumn}
                            sortColumnError={sortColumnError}
                            sortOrder={sortOrder}
                            GetDateBackwardData={GetDateBackwardData}
                            GetDateForwardData={GetDateForwardData}
                            IsEdit={IsEdit}
                            disableSave={disableSave}
                            AddToHistory={AddToHistory}
                            envactive={(isArrayWithLength(FilterreachabilityData) ? FilterreachabilityData[0].environment : '')}
                            sortReachabilityColumn={sortReachabilityColumn}
                            Reachabilitycolumn={Reachabilitycolumn}
                            reachabilitysortOrder={reachabilitysortOrder}
                            ShowHistory1={ShowHistory1}
                            filterweekdata={filterweekdata}
                            weekfilter={weekfilter}
                            options={options}
                            enableEditData={enableEditData}
                            CancelEdit={CancelEdit}
                            weekDetailsData={weekDetailsData}
                            ShowHistory={ShowHistory}
                            inputValueChanged={(e, index) => inputValueChanged(e, index)}
                            updateComments={(e, index) => updateComments(e, index)}
                            showHistorypopup={showHistorypopup}
                            checkedRetainPage={chkRetainPage}
                            onRetainPageCheck={RetainPageOnCheck}
                        />
                    </div>
                </section>
                <Modal show={Historyshow} onHide={handlehistoryClose} size='lg' backdrop='static'>
                    <Modal.Header closeButton className="commonModalHeader">
                        <div style={{ width: '100%' }}>
                            <Row>
                                <Col lg='12' style={{ textAlign: 'center' }}>
                                    <h4><b> Updated History </b></h4>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col lg='12'>
                                <table style={{ width: '100 %' }} className="table  table-striped  tblPIR tblborder">
                                    <thead>
                                        <tr>
                                            <th className="wklhisth">Metric Name</th>
                                            <th className="wklhisth">Value</th>
                                            <th className="wklhisth">Comments</th>
                                            <th className="wklhisth">Updated Date</th>
                                            <th className="wklhisth">Updated By</th>
                                            <th className="wklhisth">#Version</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isArrayWithLength(KPI_History) && KPI_History.map((his, key) => (
                                            <tr key={key}>
                                                <td>{his.metricName}</td>
                                                <td>{his.kpiValue}</td>
                                                <td className="txtcmnt">{his.comments}</td>
                                                <td>{moment(his.updatedDate).format('MM/DD/YYYY @ h:mma')}</td>
                                                <td>{his.updatedBy}</td>
                                                <td>{his.versionId}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </LoadingOverlay>

        </div>
    );
};
const mapStateToProps = (state: IApplicationState) => ({
    ...state.ReachabilityMetrics,
    isAuthenticated: state.authUser.isAuthenticated

})

export default connect(mapStateToProps, actionCreators)(ReachabilityMetrics);
