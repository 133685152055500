import React, { useState, Fragment, useRef, useEffect } from 'react';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import { IDailyData, UniqueDateHeaders, UniqueFixedHeaderColumns, HmMetric, IComments, IColorScheme, IAllViewDetails, IMSERAdditionalInfo, PropFleetutilizationHM, DateLabels, DailyFleet, IFleetcolorSchemes } from '../../../store/service-health';
import moment from 'moment';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink, Card, Row, Col, Button
} from 'reactstrap';
import { FontAwesomeIconMemo } from '../../../components';
import { ToggleButtonRadioProps } from 'react-bootstrap'
import "../../../assets/style/scss/components/daily.scss"
import applySortOn from "../../../utils/customSortMethod"
import classnames from 'classnames';
import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { DailyDataApi } from '../../../api';
import { toast } from 'react-toastify';

type DailyDataProps = {
    readonly dailyData?: IDailyData[];
    readonly uniqueDateHeaders?: UniqueDateHeaders[];
    readonly uniqueFixedHeaderColumns?: UniqueFixedHeaderColumns[];
    readonly fleetheatmapData?: PropFleetutilizationHM[];
    readonly Datelabels?: DateLabels;
    IsfleetDataloaded: any;
    handleShow: any;
    comments: IComments[];
    colorScheme: IColorScheme[];
    SelectedView: IAllViewDetails[];
    CommentSummary: any;
    valchange: any;
    serviceId: number;
    BulkEdit: boolean;
    IsdataLoaded: any;
    DailyDataFailure: any;
    IsReachabilitydataLoaded: any;
    GetHeatmapConfigurationGuide: any;
    activeDailyDataTab: any;
    DailyDataTabstoggle: any;
    ColorLogicsData: any;
    ColorshemesData: any;
    noofdecimalsdata: any;
    ConfigurationView: any;
    colorSchemeValue: any,
    coloringLogicValue: any,
    noOfDecimalValue: any,
    volumeDisplayTypeValue: any,
    authenticationTypeValue: any,
    ChangeConfigViewValues: any;
    setColorSchemevalue: any;
    setColorlogic: any;
    setnoofdecimalsvalue: any;
    setDisplayvolumevalue: any;
    setAuthenticationTypevalue: any;
    disableupdatebutton: boolean;
    UpdateViewConfigurationData: any;
    readonly additionalInfoData?: IMSERAdditionalInfo[];
    showMSERComments: any;
    ReachabilityData: any;
    ReachabilityuniqueDateHeaders: any;
    ReachabilityuniqueFixedHeaderColumns: any;
    Reachabilitycomments: any;
    ReachabilitycolorScheme: any;
    ReachabilitySelectedView: any;
    checkedFilterYellowDailyData: boolean;
    checkedFilterRedDailyData: boolean;
    OpenDetailedQuery: any;
    drilldown: boolean;
    checkedFilterYellowFleetData: boolean;
    checkedFilterRedFleetData: boolean;
    volumeLabel: string;
    dcMaxLength: number;
    fleetDatacolorscheme: IFleetcolorSchemes[];
    UpdateLabelValues: any;
    UpdateViewName: any;
};
const tdvalue = {
    paddingRight: '0px',
    fontSize: '11px',
    cursor: 'pointer',
    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans - serif!important'
}

const DailyData = React.memo<DailyDataProps>((
    {
        dailyData,
        uniqueDateHeaders,
        uniqueFixedHeaderColumns,
        handleShow,
        comments,
        colorScheme,
        SelectedView,
        CommentSummary,
        GetHeatmapConfigurationGuide,
        valchange,
        serviceId,
        BulkEdit,
        IsdataLoaded,
        DailyDataFailure,
        IsReachabilitydataLoaded,
        activeDailyDataTab,
        DailyDataTabstoggle,
        ColorLogicsData,
        ColorshemesData,
        noofdecimalsdata,
        ConfigurationView,
        colorSchemeValue,
        coloringLogicValue,
        noOfDecimalValue,
        volumeDisplayTypeValue,
        authenticationTypeValue,
        ChangeConfigViewValues,
        setColorSchemevalue,
        setColorlogic,
        setnoofdecimalsvalue,
        setDisplayvolumevalue,
        setAuthenticationTypevalue,
        disableupdatebutton,
        UpdateViewConfigurationData,
        showMSERComments,
        additionalInfoData,
        ReachabilityData,
        ReachabilityuniqueDateHeaders,
        ReachabilityuniqueFixedHeaderColumns,
        Reachabilitycomments,
        ReachabilitycolorScheme,
        ReachabilitySelectedView,
        fleetheatmapData,
        Datelabels,
        IsfleetDataloaded,
        checkedFilterYellowDailyData,
        checkedFilterRedDailyData,
        OpenDetailedQuery,
        drilldown,
        checkedFilterYellowFleetData,
        checkedFilterRedFleetData,
        volumeLabel,
        dcMaxLength,
        fleetDatacolorscheme,
        UpdateLabelValues,
        UpdateViewName
    }) => {
    let tabname = '';
    const settabname = (name) => {
        tabname = name;
    }
    const toastIdRef = useRef(null);
  
    const [VolumeValue, setVolumeValue] = useState("Volume");

    const tabslist = [{ 'Name': 'heatmap', 'DisplayName': 'HEAT MAP' }, { 'Name': 'reachability', 'DisplayName': 'REACHABILITY' }, { 'Name': 'Fleetutilizationheatmap', 'DisplayName': 'FLEET UTILIZATION HEAT MAP' }, { 'Name': 'ViewConfiguration', 'DisplayName': 'SELF SERVE CONFIG' }];
    const Displayvolume = [{ 'id': 'd', 'name': 'Default (K/M)' }, { 'id': 'k', 'name': 'Thousands (K)' }, { 'id': 'm', 'name': 'Millions (M)' }];
    const AuthenticationType = [{ 'id': 'AAD AppId', 'name': 'AAD AppId' }, { 'id': 'Dsts Blackforest', 'name': 'Dsts Blackforest' }, { 'id': 'Dsts FairFax', 'name': 'Dsts FairFax' }, { 'id': 'Dsts Mooncake', 'name': 'Dsts Mooncake' }];

      const SetStyleFUHM = (color) => {
        return { "backgroundColor": color };
        //if (color === 'L') {
        //    return { "backgroundColor": "LightGrey" };
        //}
        //if (color === 'R') {
        //    return { "backgroundColor": "#f3afa1" };
        //}
        //else if (color === 'Y') {
        //    return { "backgroundColor": "#ffe599" };
        //}
        //else if (color === 'G') {
        //    return { "backgroundColor": "#c5e0b3" };
        //}
    }
    const ValidateURL = (url) => {
        var valid = false;
        var regex = /^(?:(?:https):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
        var m;
        m = regex.exec(url);
        if (m !== null) {
            valid = true;
        }
        return valid;
    }

    const checkURL = (url) => {
        if (url !== null && url !== '') {
            if (!ValidateURL(url)) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Please enter a Valid TSG starting with 'https://'",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );

            }
        }
    }

    const Isfailurevolume = (val: any) => {
        if (val.length > 0) {
            for (var i = 0; i < val.length; i++) {
                if (val[i].isFailureVolume) {
                    return true;
                }
            }
            return false;
        }
    }

    const IsAvg = (val: any) => {
        if (val.length > 0) {
            if (val[0].avg) {
                return true;
            }
            else {
                return false;
            }
        }

    }

    const IsVolume = (val: any) => {
        if (val.length > 0) {
            if (val[0].volume) {
                return true;
            }
            else {
                return false;
            }
        }

    }
    // Sort Logic Start

    //Removing Total Volume and Daily Agg. from the sorting
    var tempDailyData = [...dailyData];

    for (var i = 0; i < tempDailyData.length; i++) {
        if (tempDailyData[i].key == "Total Volume") {
            var dailyTotal = tempDailyData.splice(i, 1)[0]
        }
        if (tempDailyData[i].key == "Daily Agg.") {
            var dailyAvg = tempDailyData.splice(i, 1)[0]
        }
    }

    const { result : dailyResult, requestSort : dailyRequestSort, sortConfig : dailySortConfig} = applySortOn(tempDailyData)
    if (dailyTotal && dailyAvg) {
        dailyResult.push(dailyTotal, dailyAvg)
    }
    dailyData = [...dailyResult];



    //This is for styles to indicate order
    const getClassNamesForDaily = (name) => {
        if (!dailySortConfig) {
            return;
        }
        return dailySortConfig.key === name ? dailySortConfig.direction : undefined;
    };


    //Reachability Start

    var tempReachabilityData = [...ReachabilityData];

    for (var i = 0; i < tempReachabilityData.length; i++) {
        if (tempReachabilityData[i].key == "Total Volume") {
            var reachabilityTotal = tempReachabilityData.splice(i, 1)[0]
        }
        if (tempReachabilityData[i].key == "Daily Agg.") {
            var reachabilityAvg = tempReachabilityData.splice(i, 1)[0]
        }
    }

    const { result:reachabilityResult, requestSort : reachabilityRequestSort, sortConfig:reachabilitySortConfig } = applySortOn(tempReachabilityData)
    if (reachabilityTotal && reachabilityAvg) {
        reachabilityResult.push(reachabilityTotal, reachabilityAvg)
    }
    ReachabilityData = [...reachabilityResult];

    const getClassNamesForReachability = (name) => {
        if (!reachabilitySortConfig) {
            return;
        }
        return reachabilitySortConfig.key === name ? reachabilitySortConfig.direction : undefined;
    };

    //Reachability End


    // Sort Logic End

    return (
        <div>

            <React.Fragment>
                <div>
                    <Card>
                        <Row>
                            <Col lg='5' style={{ textAlign: 'center' }}>
                            </Col>
                        </Row>
                    </Card>
                </div>
                <Row>
                    <Col xs='7'>
                    </Col>
                    <Col xs='5' style={{ textAlign: 'right' }}>
                        <React.Fragment><span className='ConfigGuide' onClick={GetHeatmapConfigurationGuide}>
                            Heatmap Configuration User Guide&nbsp;
                         <FontAwesomeIconMemo style={{ verticalAlign: 'middle' }} onClick={GetHeatmapConfigurationGuide} title='Heatmap Configuration User Guide' icon={'info-circle'} className='icon-format-config' />
                        </span></React.Fragment>
                    </Col>
                </Row>

                <Nav tabs style={{ borderBottom: '0' }}>
                    {isArrayWithLength(tabslist) && tabslist.map((dh, index) => (
                        <React.Fragment key={index}>
                            {
                                (tabname !== dh.Name)
                                    ?
                                    <Fragment>
                                        <NavItem style={{ color: 'rgba(0,0,0,0.54)' }}>
                                            <NavLink
                                                className={classnames({ active: activeDailyDataTab === dh.Name })}
                                                onClick={() => {
                                                    DailyDataTabstoggle(dh.Name);
                                                }}
                                            >
                                                {dh.DisplayName}
                                                {settabname(dh.Name)}
                                            </NavLink>
                                        </NavItem>
                                    </Fragment>
                                    : <React.Fragment></React.Fragment>
                            }
                        </React.Fragment>

                    ))}
                </Nav>
                <TabContent activeTab={activeDailyDataTab}>
                    {isArrayWithLength(tabslist) && tabslist.map((dh, key) => (
                        <React.Fragment key={key}>
                            {
                                (tabname !== dh.Name)
                                    ?
                                    <Fragment>
                                        <TabPane tabId={dh.Name}>
                                            {(activeDailyDataTab === 'heatmap')
                                                ?
                                                (showMSERComments) ?
                                                    <div>
                                                        {isArrayWithLength(additionalInfoData) ?
                                                            <table className='table is-fullwidth' id="msercomments">
                                                                <thead>
                                                                    <tr style={{ backgroundColor: '#3875b2', fontSize: '12px', padding: '3px' }}>
                                                                        <th className='headerdisplay' style={{ width: '10%' }}>Availability Issue</th>
                                                                        <th className='headerdisplay' style={{ width: '20%' }}>Impact(Minor/Major)</th>
                                                                        <th className='headerdisplay' style={{ width: '35%' }}>Root Cause</th>
                                                                        <th className='headerdisplay' style={{ width: '15%' }}>Mitigation</th>
                                                                        <th className='headerdisplay' style={{ width: '20%' }}>Learnings and Repair Items</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={{ backgroundColor: 'rgba(234, 216, 128, 0.31)' }}>
                                                                    {
                                                                        isArrayWithLength(additionalInfoData) && additionalInfoData.map((aid: IMSERAdditionalInfo, val) => (
                                                                            <React.Fragment key={val}>
                                                                                <tr>
                                                                                    <td className='msercomments RTELIdots'>{aid.keyDate}</td>
                                                                                    <td className='msercomments RTELIdots' dangerouslySetInnerHTML={{ __html: aid.impact }} />
                                                                                    <td className='msercomments RTELIdots' dangerouslySetInnerHTML={{ __html: aid.rootCause }} />
                                                                                    <td className='msercomments RTELIdots' dangerouslySetInnerHTML={{ __html: aid.mitigation }} />
                                                                                    <td className='msercomments RTELIdots' dangerouslySetInnerHTML={{ __html: aid.learningsAndRepairItems }} />
                                                                                </tr>
                                                                            </React.Fragment>
                                                                        ))}
                                                                </tbody>
                                                            </table>
                                                            :
                                                            <React.Fragment>
                                                                <div style={{ color: 'Red', fontWeight: 'bold', textAlign: 'center' }}> <br />No MSER Comments available!! </div>
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                    :
                                                    <React.Fragment>
                                                        {
                                                            (isArrayWithLength(dailyData))
                                                                ?
                                                                <React.Fragment>
                                                                    <div style={{ overflowX: 'auto' }} className='sticky-wrapper'>
                                                                        <div className={(dcMaxLength < 20) ? ((volumeLabel.length > 6) ? 'sticky-scroller_vol':'sticky-scroller' )
                                                                            : (dcMaxLength > 20 && dcMaxLength < 50) ? ((volumeLabel.length > 6) ? 'sticky-scroller-20_vol':'sticky-scroller-20')
                                                                                : ((volumeLabel.length > 6) ? 'sticky-scroller-50_vol':'sticky-scroller-50')}>
                                                                            <table className='sticky-table' id='tdDailydata'>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className={dcMaxLength < 20 ? 'Dailydatath datacenterwidth sticky-fixed-col-th ' + getClassNamesForDaily('key') : (dcMaxLength > 20 && dcMaxLength < 50) ? 'Dailydatath datacenterwidth sticky-fixed-col-th-20 ' + getClassNamesForDaily('key') : 'Dailydatath datacenterwidth sticky-fixed-col-th-50 ' + getClassNamesForDaily('key')} onClick={() => dailyRequestSort('key')}>Data Center</th>
                                                                                        <th className={(dcMaxLength < 20) ?(volumeLabel.length > 6 ? 'Dailydatath sticky-fixed-col2-th_vol ' + getClassNamesForDaily('sortVolume'):'Dailydatath sticky-fixed-col2-th ' + getClassNamesForDaily('sortVolume') )
                                                                                            : (dcMaxLength > 20 && dcMaxLength < 50) ? ((volumeLabel.length > 6) ? 'Dailydatath sticky-fixed-col2-th-20_vol ' + getClassNamesForDaily('sortVolume') : 'Dailydatath sticky-fixed-col2-th-20 ' + getClassNamesForDaily('sortVolume'))
                                                                                                : ((volumeLabel.length > 6) ? 'Dailydatath sticky-fixed-col2-th-50_vol ' + getClassNamesForDaily('sortVolume'):'Dailydatath sticky-fixed-col2-th-50 ' + getClassNamesForDaily('sortVolume')) }
                                                                                            onClick={() => dailyRequestSort('sortVolume')}>{volumeLabel} </th>
                                                                                        {
                                                                                            (Isfailurevolume(dailyData) === true) ? <th className={'Dailydatath ' + getClassNamesForDaily('sortFailureVolume')} onClick={() => dailyRequestSort('sortFailureVolume')}>Failure Volume </th> : <React.Fragment></React.Fragment>
                                                                                        }
                                                                                        {isArrayWithLength(uniqueFixedHeaderColumns) && uniqueFixedHeaderColumns.map((fhc: UniqueFixedHeaderColumns, key) => (
                                                                                            <th className='Dailydatath' key={key}>{fhc}</th>
                                                                                        ))}


                                                                                        <th className='Dailydatath'>Agg.</th>
                                                                                        {isArrayWithLength(uniqueDateHeaders) && uniqueDateHeaders.map((dh: UniqueDateHeaders, key) => (

                                                                                            <th className='Dailydatath' key={key}>{moment(dh.key).format('MM/DD')}</th>
                                                                                        ))}
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {

                                                                                        isArrayWithLength(dailyData) && dailyData.map((hmd: IDailyData, val) => (
                                                                                            <React.Fragment key={val}>
                                                                                                {
                                                                                                    (!hmd.isOutOfSlaUptime && !hmd.isDailyVolume) ? <tr style={{ border: 'none' }}>
                                                                                                        <td className={dcMaxLength < 20 ? "datacenterwidth sticky-fixed-col" : (dcMaxLength > 20 && dcMaxLength < 50) ? "datacenterwidth sticky-fixed-col-20" : "datacenterwidth sticky-fixed-col-50"} style={{ fontSize: '14px', textAlign: 'left' }} key={val}><span style={{ paddingLeft: '4px', wordBreak: 'break-word' }}>{hmd.key}</span></td>
                                                                                                        <td className={(dcMaxLength < 20) ? ((volumeLabel.length > 6) ? "sticky-fixed-col2_vol" : "sticky-fixed-col2")
                                                                                                            : (dcMaxLength > 20 && dcMaxLength < 50) ? ((volumeLabel.length > 6) ? "sticky-fixed-col2-20_vol" : "sticky-fixed-col2-20")
                                                                                                                : ((volumeLabel.length > 6) ? "sticky-fixed-col2-50_vol" : "sticky-fixed-col2-50")} style={{ fontSize: '14px' }}>{hmd.volume}</td>
                                                                                                        {(Isfailurevolume(dailyData) === true) ? <td className="textalignright" style={{ fontSize: '12px' }}>{hmd.failureVolume}</td> : <React.Fragment></React.Fragment>}
                                                                                                        {isArrayWithLength(hmd.fixedColumns) && hmd.fixedColumns.map((fc: UniqueDateHeaders, key) =>
                                                                                                            < td className="textalignleft" style={{ fontSize: '14px' }} key={key}>{fc.value}</td>
                                                                                                        )}
                                                                                                        {
                                                                                                            (hmd.key !== 'Total Volume' && hmd.key !== 'Daily Agg.') ?
                                                                                                                (hmd.avg === null) ? <td></td> :
                                                                                                                    <td className="textAgg" style={JSON.parse((hmd.avg.colorType != null) ? hmd.avg.colorType.replace('#c6e0b4', '#CEF8C1') : hmd.avg.colorType)} ><span style={tdvalue}>{hmd.avg.percentage}</span></td> : <td></td>
                                                                                                        }
                                                                                                        {
                                                                                                            hmd.metricList.sort().map((hmm: HmMetric, key) =>
                                                                                                                <td className={dcMaxLength > 50 ? "tdvalue-50" : "tdvalue"} style={JSON.parse((hmm.value.colorType != null) ? hmm.value.colorType.replace('#c6e0b4', '#CEF8C1') : hmm.value.colorType)} key={key} >
                                                                                                                    <div className={`rowdisplay ${hmm.comment ? 'note' : ''} ${hmm.hasMserComment ? 'mser' : ''}`} >

                                                                                                                        {(hmm.comment)
                                                                                                                            ?
                                                                                                                            <div className='commentSummary' summary-content={CommentSummary(hmm, hmd.key)}>

                                                                                                                            </div>
                                                                                                                            :
                                                                                                                            <React.Fragment></React.Fragment>}
                                                                                                                        <React.Fragment>
                                                                                                                            {
                                                                                                                                (BulkEdit && hmm.IsshowBulk) ? <input type='checkbox' checked={hmm.IsshowBulk} onChange={e => valchange(e, key, val)} /> : <React.Fragment></React.Fragment>
                                                                                                                            }
                                                                                                                        </React.Fragment>

                                                                                                                        {
                                                                                                                            (hmd.key !== 'Total Volume' && hmd.key !== 'Daily Agg.') ?
                                                                                                                                <span style={tdvalue} title={hmm.tooltip} >
                                                                                                                                    {
                                                                                                                                        (hmm.value.percentage === '' || hmm.value.percentage === undefined || hmm.value.percentage === null || hmm.value.percentage === 'NaN')
                                                                                                                                            ? <React.Fragment>
                                                                                                                                                <span style={{ textAlign: 'center' }} onClick={e => handleShow(e, hmm, hmd.key, BulkEdit)}>--</span>
                                                                                                                                        &nbsp;&nbsp;{(drilldown) ? <span onClick={e => OpenDetailedQuery(e, hmd, hmm)}><FontAwesomeIcon icon={faExternalLinkSquareAlt} className="blackicon" title="See Detailed Query in Web Kusto Explorer" /></span> : <React.Fragment></React.Fragment>}</React.Fragment>
                                                                                                                                            : <React.Fragment><span onClick={e => handleShow(e, hmm, hmd.key, BulkEdit)}>{hmm.value.percentage}</span> {(drilldown) ? <span onClick={e => OpenDetailedQuery(e, hmd, hmm)}><FontAwesomeIcon icon={faExternalLinkSquareAlt} className="blackicon" title="See Detailed Query in Web Kusto Explorer" /></span> : <React.Fragment></React.Fragment>}</React.Fragment>
                                                                                                                                    }
                                                                                                                                </span>
                                                                                                                                : <span style={tdvalue}>
                                                                                                                                    {
                                                                                                                                        (hmm.value.percentage === '' || hmm.value.percentage === undefined || hmm.value.percentage === null || hmm.value.percentage === 'NaN')
                                                                                                                                            ? <React.Fragment>
                                                                                                                                                <span style={{ textAlign: 'center' }}>--</span>
                                                                                                                                            </React.Fragment>
                                                                                                                                            : <React.Fragment><span>{hmm.value.percentage}</span>
                                                                                                                                            </React.Fragment>
                                                                                                                                    }
                                                                                                                                </span>

                                                                                                                        }

                                                                                                                    </div>

                                                                                                                </td>

                                                                                                            )}

                                                                                                    </tr> : <React.Fragment></React.Fragment>
                                                                                                }
                                                                                            </React.Fragment>
                                                                                        ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    <Row style={{ margin: '5px 0px 5px 5px', fontSize: 'small' }}>
                                                                        <React.Fragment>
                                                                            {
                                                                                (isArrayWithLength(comments))
                                                                                    ?
                                                                                    <Col lg='2'>
                                                                                        <div style={{ position: 'absolute', borderLeft: '15px solid transparent', borderTop: '15px solid #f00' }}></div>
                                                                                        <span style={{ marginLeft: '20px' }}>Daily comments</span>
                                                                                    </Col>
                                                                                    :
                                                                                    <React.Fragment></React.Fragment>
                                                                            }
                                                                        </React.Fragment>
                                                                        <React.Fragment>
                                                                            {
                                                                                (isArrayWithLength(additionalInfoData))
                                                                                    ?
                                                                                    <Col lg='2'>
                                                                                        <div style={{ position: 'absolute', borderLeft: '15px solid transparent', borderBottom: '15px solid #0089ff' }}></div><span style={{ marginLeft: '20px' }}>MSER comments</span>
                                                                                    </Col>
                                                                                    :
                                                                                    <React.Fragment></React.Fragment>
                                                                            }
                                                                        </React.Fragment>
                                                                    </Row>
                                                                    <Row>
                                                                        {
                                                                            (isArrayWithLength(comments))
                                                                                ?
                                                                                <Col lg='8'>
                                                                                    <table className="tblCommentsDaily">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th style={{width:'2%'}}>Id</th>
                                                                                                        <th style={{width:'16%'}}>Comments</th>
                                                                                                        <th style={{ width: '10%' }}>Impacted DCs</th>
                                                                                                        <th style={{width:'8%'}}>Incident# / Workitem#</th>
                                                                                                        <th style={{width:'8%'}}>Status</th>
                                                                                                        <th style={{width:'9%'}}>Problem statement</th>
                                                                                                        <th style={{width:'9%'}}>Root Cause</th>
                                                                                                        <th style={{width:'9%'}}>Team owning root cause</th>
                                                                                                        <th style={{width:'9%'}}>Impact</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                    <tbody>
                                                                                                        {comments.map((item: IComments, index) => (
                                                                                                            <React.Fragment key={index}>
                                                                                                            {                   
                                                                                                                    <tr>
                                                                                                                        <td style={{width:'2%'}}>{index+1}</td>
                                                                                                                        <td style={{width:'16%'}}>{item.comment}</td>
                                                                                                                        <td style={{width:'10%'}}>{item.keyDate}</td>
                                                                                                                        <td style={{ width: '8%' }}>
                                                                                                                            {
                                                                                                                                (item.incident && (item.incident != '0') && item.incident.length > 8)
                                                                                                                                    ?
                                                                                                                                    <span>
                                                                                                                                        <a href={'https://portal.microsofticm.com/imp/v3/incidents/details/' + item.incident + '/home'} target="_blank"> {item.incident}</a>
                                                                                                                                    </span>
                                                                                                                                    : (item.incident && (item.incident != '0') && item.incident.length < 8)
                                                                                                                                        ?
                                                                                                                                        <span>
                                                                                                                                            <a href={'https://identitydivision.visualstudio.com/Engineering/_workitems/edit/' + item.incident} target="_blank"> {item.incident}</a>
                                                                                                                                        </span>
                                                                                                                                        :
                                                                                                                                        <span></span>
                                                                                                                            }
                                                                                                                        </td>
                                                                                                                        <td style={{width:'8%'}}>{item.status}</td>
                                                                                                                        <td style={{width:'9%'}}>{item.problemStatement}</td>
                                                                                                                        <td style={{width:'9%'}}>{item.rootCause}</td>
                                                                                                                        <td style={{width:'9%'}}>{item.owningTeam}</td>
                                                                                                                        <td style={{width:'9%'}}>{item.impact}</td>
                                                                                                                    </tr>
                                                                                                            }
                                                                                                            </React.Fragment>
                                                                                                        ))}
                                                                                                    </tbody>
                                                                                                </table>
                                                                                </Col>
                                                                                :
                                                                                <React.Fragment></React.Fragment>

                                                                        }
                                                                        <React.Fragment>
                                                                            {
                                                                                (isArrayWithLength(colorScheme))
                                                                                    ?
                                                                                    <Col lg='2'>
                                                                                        <div>
                                                                                            {isArrayWithLength(colorScheme) && colorScheme.map((clr: IColorScheme, index) => (
                                                                                                <React.Fragment key={index}>
                                                                                                    {
                                                                                                        <Row>
                                                                                                            <Col lg='2' key={index}><div style={{ paddingBottom: '5px' }}><div className='DailyColrLogic' style={JSON.parse(clr.colorType)}>&nbsp;</div></div></Col>
                                                                                                            <Col lg='10'>{clr.description}</Col>
                                                                                                        </Row>
                                                                                                    }
                                                                                                </React.Fragment>


                                                                                            ))}


                                                                                        </div>
                                                                                    </Col>
                                                                                    : <React.Fragment></React.Fragment>
                                                                            }
                                                                        </React.Fragment>


                                                                        <Col lg='2'>
                                                                            {
                                                                                (SelectedView !== undefined && SelectedView !== null)
                                                                                    ?
                                                                                    isArrayWithLength(SelectedView) && SelectedView.map((view: IAllViewDetails, index) => (
                                                                                        <div key={index}>
                                                                                            <Row>
                                                                                                <Col lg='12'>  Display no. of Decimals :{view.noOfDecimalText}</Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col lg='12'>   Coloring logic :{view.colorLogicText} </Col>
                                                                                            </Row>
                                                                                        </div>


                                                                                    ))
                                                                                    :
                                                                                    <div></div>}

                                                                        </Col>
                                                                    </Row>
                                                                </React.Fragment>
                                                                : <React.Fragment>
                                                                    {
                                                                        (IsdataLoaded === true) ? <div style={{ color: 'Red', fontWeight: 'bold', textAlign: 'center' }}> <br />
                                                                            {(checkedFilterYellowDailyData === true && checkedFilterRedDailyData === true) ? <React.Fragment>No Red & Yellow Filter data available for the selected service</React.Fragment>
                                                                                : (checkedFilterYellowDailyData === true) ? <React.Fragment>No Yellow Filter data available for the selected service</React.Fragment>
                                                                                    : (checkedFilterRedDailyData === true) ? <React.Fragment>No Red Filter data available for the selected service</React.Fragment>
                                                                                        : <React.Fragment>No data available for the selected service</React.Fragment>}
                                                                        </div>      : (DailyDataFailure === true) ? <div style={{ color: 'Red', fontWeight: 'bold', textAlign: 'center' }}> <br /> Error while loading Data !!</div>
                                                                                : <React.Fragment></React.Fragment>
                                                                    }
                                                                </React.Fragment>
                                                        }
                                                    </React.Fragment>

                                                : (activeDailyDataTab === 'reachability')
                                                    ? <React.Fragment>
                                                        {
                                                            (isArrayWithLength(ReachabilityData))
                                                                ?
                                                                <React.Fragment>
                                                                    <div style={{ overflowX: 'auto' }} className="sticky-wrapper">
                                                                        <div className={dcMaxLength < 30 ? 'sticky-reachability-scroller' : (dcMaxLength > 30 && dcMaxLength < 50) ? 'sticky-reachability-scroller-20' : 'sticky-reachability-scroller-50'} >
                                                                            <table className='tblborder sticky-table' id='tdDailydata'>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className={dcMaxLength < 30 ? 'Dailydatath datacenterwidth sticky-reachability-fixed-col-th ' + getClassNamesForReachability('key') : (dcMaxLength > 30 && dcMaxLength < 50) ? 'Dailydatath datacenterwidth sticky-reachability-fixed-col-th-20 ' + getClassNamesForReachability('key') : 'Dailydatath datacenterwidth sticky-reachability-fixed-col-th-50 ' + getClassNamesForReachability('key')} onClick={() => reachabilityRequestSort('key')}>Data Center</th>
                                                                                        {
                                                                                            (IsVolume(ReachabilityData) === true) ? <th className={'Dailydatath ' + getClassNamesForReachability('sortVolume')}>Volume</th> : <React.Fragment></React.Fragment>
                                                                                        }
                                                                                        {isArrayWithLength(ReachabilityuniqueFixedHeaderColumns) && ReachabilityuniqueFixedHeaderColumns.map((fhc: UniqueFixedHeaderColumns, key) => (
                                                                                            <th className='Dailydatath' key={key}>{fhc}</th>
                                                                                        ))}

                                                                                        {
                                                                                            (IsAvg(ReachabilityData) === true) ? <th className='Dailydatath'>Agg.</th> : <React.Fragment></React.Fragment>
                                                                                        }
                                                                                        {isArrayWithLength(ReachabilityuniqueDateHeaders) && ReachabilityuniqueDateHeaders.map((dh: UniqueDateHeaders, key) => (

                                                                                            <th className='Dailydatath' key={key}>{moment(dh.key).format('MM/DD')}</th>
                                                                                        ))}
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        isArrayWithLength(ReachabilityData) && ReachabilityData.map((hmd: IDailyData, val) => (
                                                                                            <React.Fragment key={val}>
                                                                                                {
                                                                                                    (!hmd.isOutOfSlaUptime && !hmd.isDailyVolume) ? <tr style={{ border: "none" }}>
                                                                                                        <td className={dcMaxLength < 30 ? "sticky-reachability-fixed-col " : (dcMaxLength > 30 && dcMaxLength < 50) ? "sticky-reachability-fixed-col-20 " : "sticky-reachability-fixed-col-50 "} key={val}>{hmd.key}</td>

                                                                                                        {(hmd.volume) ?
                                                                                                            <td className="textaligncenter " style={{ fontSize: '14px' }}>{hmd.volume}</td>
                                                                                                            : <React.Fragment></React.Fragment>
                                                                                                        }
                                                                                                        {isArrayWithLength(hmd.fixedColumns) && hmd.fixedColumns.map((fc: UniqueDateHeaders, key) =>
                                                                                                            < td className="textaligncenter" style={{ fontSize: '14px' }} key={key}>{fc.value}</td>
                                                                                                        )}
                                                                                                        {
                                                                                                            (hmd.avg) ? <td></td> : <React.Fragment></React.Fragment>
                                                                                                        }
                                                                                                        {

                                                                                                            hmd.metricList.sort().map((hmm: HmMetric, key) =>
                                                                                                                < td className={dcMaxLength > 50 ? "textaligncenter tdvalue-50" : "textaligncenter tdvalue"} style={JSON.parse((hmm.value.colorType != null) ? hmm.value.colorType.replace('#c6e0b4', '#CEF8C1') : hmm.value.colorType)} key={key} >
                                                                                                                    <div className={hmm.comment ? 'note rowdisplay' : 'rowdisplay'}>
                                                                                                                        {(hmm.comment)
                                                                                                                            ?
                                                                                                                            <div className='commentSummary' summary-content={CommentSummary(hmm, hmd.key)}>

                                                                                                                            </div>
                                                                                                                            :
                                                                                                                            <React.Fragment></React.Fragment>}
                                                                                                                        {
                                                                                                                            (hmd.key !== 'Total Volume' && hmd.key !== 'Daily Agg.') ?
                                                                                                                                <span style={tdvalue} title={hmm.tooltip} >
                                                                                                                                    {
                                                                                                                                        (hmm.value.percentage === '' || hmm.value.percentage === undefined || hmm.value.percentage === null || hmm.value.percentage === 'NaN')
                                                                                                                                            ? <React.Fragment>
                                                                                                                                                <span style={{ textAlign: 'center' }} onClick={e => handleShow(e, hmm, hmd.key, BulkEdit)}>--</span>
                                                                                                                                        &nbsp;&nbsp;{(drilldown) ? <span onClick={e => OpenDetailedQuery(e, hmd, hmm)}><FontAwesomeIcon icon={faExternalLinkSquareAlt} className="blackicon" title="See Detailed Query in Web Kusto Explorer" /></span> : <React.Fragment></React.Fragment>}</React.Fragment>
                                                                                                                                            : <React.Fragment><span onClick={e => handleShow(e, hmm, hmd.key, BulkEdit)}>{hmm.value.percentage}</span> {(drilldown) ? <span onClick={e => OpenDetailedQuery(e, hmd, hmm)}><FontAwesomeIcon icon={faExternalLinkSquareAlt} className="blackicon" title="See Detailed Query in Web Kusto Explorer" /></span> : <React.Fragment></React.Fragment>}</React.Fragment>
                                                                                                                                    }
                                                                                                                                </span>
                                                                                                                                : <span style={tdvalue}>
                                                                                                                                    {
                                                                                                                                        (hmm.value.percentage === '' || hmm.value.percentage === undefined || hmm.value.percentage === null || hmm.value.percentage === 'NaN')
                                                                                                                                            ? <React.Fragment>
                                                                                                                                                <span style={{ textAlign: 'center' }}>--</span>
                                                                                                                                            </React.Fragment>
                                                                                                                                            : <React.Fragment><span>{hmm.value.percentage}</span>
                                                                                                                                            </React.Fragment>
                                                                                                                                    }
                                                                                                                                </span>

                                                                                                                        }

                                                                                                                    </div>

                                                                                                                </td>

                                                                                                            )}

                                                                                                    </tr> : <React.Fragment></React.Fragment>
                                                                                                }
                                                                                            </React.Fragment>
                                                                                        ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>

                                                                    </div>
                                                                    <br />
                                                                    <Row>
                                                                        {
                                                                            (isArrayWithLength(Reachabilitycomments))
                                                                                ?
                                                                                <Col lg='8'>
                                                                                    <table className="tblCommentsDaily">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th style={{ width: '2%' }}>Id</th>
                                                                                                <th style={{ width: '16%' }}>Comments</th>
                                                                                                <th style={{ width: '11%' }}>Impacted DCs</th>
                                                                                                <th style={{ width: '7%' }}>Incident# / Workitem#</th>
                                                                                                <th style={{ width: '8%' }}>Status</th>
                                                                                                <th style={{ width: '9%' }}>Problem statement</th>
                                                                                                <th style={{ width: '9%' }}>Root Cause</th>
                                                                                                <th style={{ width: '9%' }}>Team owning root cause</th>
                                                                                                <th style={{ width: '9%' }}>Impact</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {Reachabilitycomments.map((item: IComments, index) => (
                                                                                                <React.Fragment key={index}>
                                                                                                    {
                                                                                                        <tr>
                                                                                                            <td style={{ width: '2%' }}>{index + 1}</td>
                                                                                                            <td style={{ width: '16%' }}>{item.comment}</td>
                                                                                                            <td style={{ width: '11%' }}>{item.keyDate}</td>
                                                                                                            <td style={{ width: '7%' }}>
                                                                                                                {
                                                                                                                    (item.incident && (item.incident != '0') && item.incident.length > 8)
                                                                                                                        ?
                                                                                                                        <span>
                                                                                                                            <a href={'https://portal.microsofticm.com/imp/v3/incidents/details/' + item.incident + '/home'} target="_blank"> {item.incident}</a>
                                                                                                                        </span>
                                                                                                                        :  (item.incident && (item.incident != '0') && item.incident.length < 8)
                                                                                                                            ?
                                                                                                                              <span>
                                                                                                                                <a href={'https://identitydivision.visualstudio.com/Engineering/_workitems/edit/' + item.incident} target="_blank"> {item.incident}</a>
                                                                                                                        </span>
                                                                                                                            :
                                                                                                                        <span></span>
                                                                                                                }
                                                                                                            </td>
                                                                                                            <td style={{ width: '8%' }}>{item.status}</td>
                                                                                                            <td style={{ width: '9%' }}>{item.problemStatement}</td>
                                                                                                            <td style={{ width: '9%' }}>{item.rootCause}</td>
                                                                                                            <td style={{ width: '9%' }}>{item.owningTeam}</td>
                                                                                                            <td style={{ width: '9%' }}>{item.impact}</td>
                                                                                                        </tr>
                                                                                                    }
                                                                                                </React.Fragment>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Col>
                                                                                :
                                                                                <React.Fragment> </React.Fragment>
                                                                        }
                                                                        <React.Fragment>
                                                                            {
                                                                                (isArrayWithLength(ReachabilitycolorScheme))
                                                                                    ?
                                                                                    <Col lg='2'>
                                                                                        <div>
                                                                                            {ReachabilitycolorScheme.map((clr: IColorScheme, index) => (
                                                                                                <React.Fragment key={index}>
                                                                                                    {
                                                                                                        <Row>
                                                                                                            <Col lg='2' key={index}><div style={{ paddingBottom: '5px' }}><div className='DailyColrLogic' style={JSON.parse(clr.colorType)}>&nbsp;</div></div></Col>
                                                                                                            <Col lg='10'>{clr.description}</Col>
                                                                                                        </Row>
                                                                                                    }
                                                                                                </React.Fragment>


                                                                                            ))}


                                                                                        </div>
                                                                                    </Col>
                                                                                    :
                                                                                    <React.Fragment> </React.Fragment>
                                                                            }
                                                                        </React.Fragment>
                                                                        <Col lg='2'>
                                                                            {
                                                                                (ReachabilitySelectedView !== undefined && ReachabilitySelectedView !== null)
                                                                                    ?
                                                                                    isArrayWithLength(ReachabilitySelectedView) && ReachabilitySelectedView.map((view: IAllViewDetails, index) => (
                                                                                        <div key={index}>
                                                                                            <Row>
                                                                                                <Col lg='12'>  Display no. of Decimals :{view.noOfDecimalText}</Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col lg='12'>   Coloring logic :{view.colorLogicText} </Col>
                                                                                            </Row>
                                                                                        </div>


                                                                                    ))
                                                                                    :
                                                                                    <div></div>}

                                                                        </Col>
                                                                    </Row>
                                                                </React.Fragment>
                                                                : <React.Fragment>
                                                                    {
                                                                        (IsReachabilitydataLoaded === true) ? <div style={{ color: 'Red', fontWeight: 'bold', textAlign: 'center' }}> <br />
                                                                            {(checkedFilterYellowDailyData === true && checkedFilterRedDailyData === true) ? <React.Fragment>No Red & Yellow Filter Reachability data available for the selected service</React.Fragment>
                                                                                : (checkedFilterYellowDailyData === true) ? <React.Fragment>No Yellow Filter Reachability data  available for the selected service</React.Fragment>
                                                                                    : (checkedFilterRedDailyData === true) ? <React.Fragment>No Red Filter Reachability data available for the selected service</React.Fragment>
                                                                                        : <React.Fragment>No Reachability data available for the selected service</React.Fragment>}
                                                                        </div> : <React.Fragment></React.Fragment>
                                                                    }
                                                                </React.Fragment>
                                                        }
                                                    </React.Fragment>
                                                    : (activeDailyDataTab === 'ViewConfiguration')
                                                        ? <React.Fragment>
                                                            {/*ViewConfiguration*/}
                                                            <br/>
                                                            <Row className="alert alert-info">
                                                                <Col lg="12">
                                                                    <div>
                                                                        Add our <b>AME Tenant</b> App ID&nbsp;
                                                                            <b>"12a0025a-c621-428f-87bd-fc2e5214cced"</b>
                                                                        to your Public Kusto Cluster with viewers permission, before creating a new view.
                                                                        For National Clouds, please provide
                                                                        <b>"Data Viewer Role DSTS Application"</b>&nbsp;access to our dSTS application&nbsp;
                                                                        <i>
                                                                            <b>
                                                                                Mooncake : ID_Fundamentals_Dashboard.chinacloudapi.cn &nbsp;
                                                                                Fairfax : ID_Fundamentals_Dashboard.usgovcloudapi.net
                                                                            </b>
                                                                            <br />
                                                                            The jobs are running every 6 hours to pull the data from Kusto Source. After changes done to view,
                                                                            it will take ~6 hours to reflect in Heatmap view.
                                                                        </i>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="12">
                                                                    <div style={{ float: 'right' }}>
                                                                        <a href="https://microsoft.sharepoint.com/:w:/t/adpsoteam/PSOSREMT/ESlpc2BL5mtMpXptqHQAQEcBfbQ2Ae7OYSSm8-_AJeHgIA?e=75x3ub" target="_blank" className="anchtextdecoration" title="Help"><FontAwesomeIcon title='Help' icon={faQuestionCircle} className="styhelpicon" /></a>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            {
                                                                (ConfigurationView != null && ConfigurationView != undefined)
                                                                    ?
                                                                    <React.Fragment>


                                                                        <Row className="viewConfigRowstyle">
                                                                            <Col lg="12">
                                                                                <Card className="viewcard">
                                                                                    <Card body className="viewcardbody">
                                                                                        <h5>Display Configuration</h5>
                                                                                    </Card>
                                                                                    <div>
                                                                                        <Row className="viewConfigRowstyle">
                                                                                            <Col lg="2">
                                                                                                <label className="divhelpicon"><span style={{ color: 'red' }}>*</span> Color Scheme &nbsp;&nbsp;:</label>
                                                                                            </Col>
                                                                                            <Col lg="2">
                                                                                                <Picky id='picky'
                                                                                                    options={ColorshemesData}
                                                                                                    valueKey='id'
                                                                                                    labelKey='name'
                                                                                                    multiple={false}
                                                                                                    value={colorSchemeValue}
                                                                                                    includeSelectAll={false}
                                                                                                    onChange={setColorSchemevalue}
                                                                                                    dropdownHeight={300}
                                                                                                    numberDisplayed={1}
                                                                                                    placeholder='Select Color Scheme'
                                                                                                    includeFilter={true}
                                                                                                    keepOpen={false}

                                                                                                />
                                                                                            </Col>
                                                                                            <Col lg="2">
                                                                                                <label className="divhelpicon"> Val1 Label (Uptime) &nbsp;&nbsp;:</label>
                                                                                            </Col>
                                                                                            <Col lg="2">                                                                                                
                                                                                                <input type="text" placeholder="Uptime" className="form-control viewconfiginputstyle" value={ConfigurationView.val1Label} name="Val1" onChange={e => UpdateLabelValues(e, ConfigurationView)} />
                                                                                            </Col>
                                                                                            <Col lg="2">
                                                                                                <label className="divhelpicon"><span style={{ color: 'red' }}>*</span> Max value for Val1 &nbsp;&nbsp;:</label>
                                                                                            </Col>
                                                                                            <Col lg="2">
                                                                                                <input type="text" name="val1Cap" placeholder="Max value for Val1" className="form-control viewconfiginputstyle" value={ConfigurationView.val1Cap} onChange={e=>ChangeConfigViewValues(e, ConfigurationView)} />
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row className="viewConfigRowstyle">
                                                                                            <Col lg="2">
                                                                                                <label className="divhelpicon"><span style={{ color: 'red' }}>*</span> Coloring Logic &nbsp;&nbsp;:</label>
                                                                                            </Col>
                                                                                            <Col lg="2">
                                                                                                <Picky
                                                                                                    id='picky'
                                                                                                    options={ColorLogicsData}
                                                                                                    valueKey='id'
                                                                                                    labelKey='name'
                                                                                                    multiple={false}
                                                                                                    value={coloringLogicValue}
                                                                                                    includeSelectAll={false}
                                                                                                    onChange={setColorlogic}
                                                                                                    dropdownHeight={300}
                                                                                                    numberDisplayed={1}
                                                                                                    placeholder='Select Color Logic'
                                                                                                    includeFilter={true}
                                                                                                    keepOpen={false}
                                                                                                />
                                                                                            </Col>
                                                                                            <Col lg="2">
                                                                                                <label className="divhelpicon"> Val2 Label &nbsp;&nbsp;:</label>
                                                                                            </Col>
                                                                                            <Col lg="2">                                                                                                
                                                                                                <input type="text" placeholder="Val2 Label" value={ConfigurationView.val2Label}  className="form-control viewconfiginputstyle" name="Val2" onChange={e => UpdateLabelValues(e, ConfigurationView)} />
                                                                                            </Col>
                                                                                            <Col lg="2">
                                                                                                <label className="divhelpicon"><span style={{ color: 'red' }}>*</span> Max value for Val2 &nbsp;&nbsp;:</label>
                                                                                            </Col>
                                                                                            <Col lg="2">
                                                                                                <input type="text" name="val2Cap" placeholder="Max value for Val2" className="form-control viewconfiginputstyle" value={ConfigurationView.val2Cap} onChange={e => ChangeConfigViewValues(e, ConfigurationView)}/>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row className="viewConfigRowstyle">
                                                                                            <Col lg="2">
                                                                                                <label className="divhelpicon"><span style={{ color: 'red' }}>*</span> Display No. of Decimals &nbsp;&nbsp;:</label>
                                                                                            </Col>
                                                                                            <Col lg="2">
                                                                                                <Picky
                                                                                                    id='picky'
                                                                                                    options={noofdecimalsdata}
                                                                                                    valueKey='id'
                                                                                                    labelKey='name'
                                                                                                    multiple={false}
                                                                                                    value={noOfDecimalValue}
                                                                                                    includeSelectAll={false}
                                                                                                    onChange={setnoofdecimalsvalue}
                                                                                                    dropdownHeight={300}
                                                                                                    numberDisplayed={1}
                                                                                                    placeholder='Select No.of Decimals'
                                                                                                    includeFilter={true}
                                                                                                    keepOpen={false}
                                                                                                />
                                                                                            </Col>
                                                                                            <Col lg="2">
                                                                                                <label className="divhelpicon"> Volume &nbsp;&nbsp;:</label>
                                                                                            </Col>
                                                                                            <Col lg="2">                                                                                                
                                                                                                <input type="text" placeholder="Volume" value="Volume" readOnly className="form-control viewconfiginputstyle" name="Volume" />
                                                                                            </Col>
                                                                                            <Col lg="2">
                                                                                                <label className="divhelpicon"><span style={{ color: 'red' }}>*</span> Display Volume In &nbsp;&nbsp;:</label>
                                                                                            </Col>
                                                                                            <Col lg="2">
                                                                                                <Picky
                                                                                                    id='picky'
                                                                                                    options={Displayvolume}
                                                                                                    valueKey='id'
                                                                                                    labelKey='name'
                                                                                                    multiple={false}
                                                                                                    value={volumeDisplayTypeValue}
                                                                                                    includeSelectAll={false}
                                                                                                    onChange={setDisplayvolumevalue}
                                                                                                    dropdownHeight={300}
                                                                                                    numberDisplayed={1}
                                                                                                    placeholder='Select Display Volume'
                                                                                                    includeFilter={true}
                                                                                                    keepOpen={false}
                                                                                                />
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row className="viewConfigRowstyle viewConfigRowbtmstyle row">
                                                                                                {(ConfigurationView.viewId > 0) ?
                                                                                                <React.Fragment>
                                                                                                    <Col lg="2">
                                                                                                        <label className="divhelpicon"> Rename View &nbsp;&nbsp;:</label>
                                                                                                    </Col>
                                                                                                    <Col lg="2">
                                                                                                        <input type="text" placeholder="View Name" className="form-control viewconfiginputstyle" value={ConfigurationView.RenameViewLabel} name="renameView" onChange={e => UpdateViewName(e, ConfigurationView)} />
                                                                                                    </Col>
                                                                                                    </React.Fragment>
                                                                                                : <React.Fragment></React.Fragment>
                                                                                                }
                                                                                                {(ConfigurationView.viewId <= 0) ?
                                                                                                    <React.Fragment>
                                                                                                    <Col lg="4">&nbsp;&nbsp;</Col>
                                                                                                    </React.Fragment>
                                                                                                    : <React.Fragment></React.Fragment>
                                                                                                }
                                                                                            <Col lg="2">
                                                                                                <Row>
                                                                                                    <Col lg='12'><label className="divhelpicon">TSG &nbsp;&nbsp;:</label></Col>
                                                                                                </Row>                                                                                               
                                                                                            </Col>
                                                                                            <Col lg="6">
                                                                                                <Row>
                                                                                                    <Col lg='12'><input type="text" className="form-control viewconfiginputnewstyle" value={ConfigurationView.tsg} name="tsg" onChange={e => ChangeConfigViewValues(e, ConfigurationView)} onBlur={e => checkURL(ConfigurationView.tsg)} /></Col>
                                                                                                </Row>
                                                                                                <Row>
                                                                                                    <Col lg='12'><div className="errotextstyle labelpaddingTSG">( TSG must start with 'https://' ) </div></Col>
                                                                                                </Row>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </div>
                                                                                </Card>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="viewConfigRowstyle">
                                                                            <Col lg="12">
                                                                                <Card className="viewcard">
                                                                                    <Card body className="viewcardbody">
                                                                                        <h5>Data Configuration</h5>
                                                                                    </Card>
                                                                                    <div>
                                                                                        <Row className="viewConfigRowstyle">
                                                                                            <Col lg="2">
                                                                                                <label className="divhelpicon"> <span style={{ color: 'red' }}>*</span>Data Source &nbsp;&nbsp;:</label>
                                                                                            </Col>
                                                                                            <Col lg="2">
                                                                                                <form onChange={e => ChangeConfigViewValues(e, ConfigurationView)} >
                                                                                                    <input type="radio" name="source" value="Kusto" checked={ConfigurationView.source === 'Kusto'} />Kusto
                                                                                                              <input disabled={true} type="radio" name="source" value="Cosmos" checked={ConfigurationView.source === 'Cosmos'} />Cosmos

                                                                                                         </form>

                                                                                            </Col>
                                                                                            <Col lg="2">
                                                                                                <label className="divhelpicon"><span style={{ color: 'red' }}>*</span> Server Name &nbsp;&nbsp;:</label>
                                                                                            </Col>
                                                                                            <Col lg="2">
                                                                                                <input type="url" name="kustoServer" placeholder="Kusto server" className="form-control viewconfiginputstyle" value={ConfigurationView.kustoServer} onChange={e => ChangeConfigViewValues(e, ConfigurationView)} />
                                                                                            </Col>
                                                                                            <Col lg="2">
                                                                                                <label className="divhelpicon"><span style={{ color: 'red' }}>*</span> Database &nbsp;&nbsp;:</label>
                                                                                            </Col>
                                                                                            <Col lg="2">
                                                                                                <input type="text" name="kustoDbName" placeholder="Kusto Database" className="form-control viewconfiginputstyle" value={ConfigurationView.kustoDbName} onChange={e => ChangeConfigViewValues(e, ConfigurationView)} />
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row className="viewConfigRowstyle">
                                                                                            <Col lg="2">
                                                                                                <label className="divhelpicon"><span style={{ color: 'red' }}>*</span> Kusto Authentication Type &nbsp;&nbsp;:</label>
                                                                                            </Col>
                                                                                            <Col lg="2">
                                                                                                <Picky
                                                                                                    id='picky'
                                                                                                    options={AuthenticationType}
                                                                                                    valueKey='id'
                                                                                                    labelKey='name'
                                                                                                    multiple={false}
                                                                                                    value={authenticationTypeValue}
                                                                                                    includeSelectAll={false}
                                                                                                    onChange={setAuthenticationTypevalue}
                                                                                                    dropdownHeight={300}
                                                                                                    numberDisplayed={1}
                                                                                                    placeholder='Select Authentication Type'
                                                                                                    includeFilter={false}
                                                                                                    keepOpen={false}
                                                                                                />
                                                                                            </Col>
                                                                                            <Col lg="8">
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row className="viewConfigRowstyle viewConfigRowbtmstyle">
                                                                                            <Col lg="2">
                                                                                                <label className="divhelpicon"><span style={{ color: 'red' }}>*</span> Fixed column Query &nbsp;&nbsp;:</label>
                                                                                            </Col>
                                                                                            <Col lg="4">
                                                                                                <textarea placeholder="Fixed columns query" name="fixedColQuery" className="form-control txtareaheight" value={ConfigurationView.fixedColQuery} onChange={e => ChangeConfigViewValues(e, ConfigurationView)}></textarea>
                                                                                            </Col>
                                                                                            <Col lg="6">
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row className="viewConfigRowstyle viewConfigRowbtmstyle">
                                                                                            <Col lg="2">
                                                                                                <label className="divhelpicon"><span style={{ color: 'red' }}>*</span> Value Query &nbsp;&nbsp;:</label>
                                                                                            </Col>
                                                                                            <Col lg="4">
                                                                                                <textarea placeholder="Value Query" name="valQuery" className="form-control txtareaheight" value={ConfigurationView.valQuery} onChange={e => ChangeConfigViewValues(e, ConfigurationView)}></textarea>
                                                                                            </Col>
                                                                                            <Col lg="6">
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row className="viewConfigRowstyle viewConfigRowbtmstyle">
                                                                                            <Col lg="2">
                                                                                                <label className="divhelpicon"> Drill Down Query &nbsp;&nbsp;:</label>
                                                                                            </Col>
                                                                                            <Col lg="4">
                                                                                                <textarea value={ConfigurationView.drillDownQuery} name="drillDownQuery" placeholder="Sample: ServiceQoS_Daily | where PreciseTimeStamp == todatetime(<<datetime>>) and DataCenter == <<datacenter>>" className="form-control txtareaheight" onChange={e => ChangeConfigViewValues(e, ConfigurationView)}></textarea>
                                                                                            </Col>
                                                                                            <Col lg="6">
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </div>
                                                                                </Card>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="viewConfigRowstyle">
                                                                            <Col lg="12">
                                                                                <Card className="viewcard">
                                                                                    <Card body className="viewcardbody">
                                                                                        <h5>Email Configuration</h5>
                                                                                    </Card>
                                                                                    <div>
                                                                                        <Row className="viewConfigRowstyle">
                                                                                            <Col lg="2">
                                                                                                <label className="divhelpicon"> To Email List &nbsp;&nbsp;:</label>
                                                                                            </Col>
                                                                                            <Col lg="2">
                                                                                                <input type="text" placeholder="To Email List" name="toEmailList" className="form-control viewconfiginputstyle" value={ConfigurationView.toEmailList} onChange={e => ChangeConfigViewValues(e, ConfigurationView)} />
                                                                                            </Col>
                                                                                            <Col lg="2">
                                                                                                <label className="divhelpicon"> CC Email List &nbsp;&nbsp;:</label>
                                                                                            </Col>
                                                                                            <Col lg="2">
                                                                                                <input type="text" placeholder="CC Email List" name="ccEmailList" className="form-control viewconfiginputstyle" value={ConfigurationView.ccEmailList} onChange={e => ChangeConfigViewValues(e, ConfigurationView)} />
                                                                                            </Col>
                                                                                            <Col lg="4"> </Col>
                                                                                        </Row>
                                                                                        <Row className="viewConfigRowstyle viewConfigRowbtmstyle">
                                                                                        </Row>
                                                                                    </div>
                                                                                </Card>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="viewConfigRowstyle">
                                                                            <Col lg="5">
                                                                            </Col>
                                                                            <Col lg="2">
                                                                                <Button className='DailydataModelbuttons' disabled={disableupdatebutton} onClick={UpdateViewConfigurationData}>{(ConfigurationView.viewId > 0) ? 'Update View' : 'Create View'}</Button>
                                                                            </Col>
                                                                            <Col lg="5">
                                                                                {
                                                                                    (ConfigurationView.viewId > 0)
                                                                                        ? <div className="divconfigupdatedetails">
                                                                                            Updated By : {ConfigurationView.updatedBy}<br />
								                                                                    Last Updated On : {moment(ConfigurationView.updatedOn).format('MM-DD-YYYY')}
                                                                                        </div>
                                                                                        :<div></div>
                                                                                }

                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                    : <React.Fragment></React.Fragment>
                                                            }

                                                        </React.Fragment>
                                                        : (activeDailyDataTab === 'Fleetutilizationheatmap')
                                                            ? < React.Fragment >
                                                                {/*Fleetutilizationheatmap*/}
                                                                {
                                                                    (isArrayWithLength(fleetheatmapData))
                                                                        ?
                                                                        <React.Fragment>
                                                                            <br />
                                                                            <div style={{ overflowX: 'auto' }}>
                                                                                <table className='tblborder' id='tdfleetHeatmapData'>
                                                                                    <thead>
                                                                                        <tr className='rowborder'>
                                                                                            <th className='Dailyfleetdatath' colSpan={2}></th>
                                                                                            <th className='Dailyfleetdatath' colSpan={4}>Weekly Fleet Utilization</th>
                                                                                            <th className='Dailyfleetdatath' colSpan={fleetheatmapData[0].dailyFleetHM.length}>Daily Fleet Utilization</th>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th className='Dailyfleetdatath'>Cloud</th>
                                                                                            <th className='Dailyfleetdatath'>Data Center</th>
                                                                                            <th className='Dailyfleetdatath'>Fleet Size<br />({Datelabels.startwd} to {Datelabels.enddt})</th>
                                                                                            <th className='Dailyfleetdatath'>Fleet Utilization<br />({Datelabels.startwd} to {Datelabels.endwd})</th>
                                                                                            <th className='Dailyfleetdatath'>Fleet Cores</th>
                                                                                            <th className='Dailyfleetdatath'>Recommended <br />Fleet Cores</th>
                                                                                            {isArrayWithLength(fleetheatmapData) && fleetheatmapData[0].dailyFleetHM.map((dh: DailyFleet, key) => (
                                                                                                <th className='Dailyfleetdatath' key={key}>{moment(dh.date).format('MM/DD') + '-' + moment(dh.date).format('ddd').toUpperCase()}</th>
                                                                                            ))}
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            isArrayWithLength(fleetheatmapData) && fleetheatmapData.map((fhmd: PropFleetutilizationHM, val) => (
                                                                                                <React.Fragment key={val}>
                                                                                                    {
                                                                                                        <tr>
                                                                                                            <td className='textaligncenter fleettd'>{fhmd.cloud }</td>
                                                                                                            <td className='textaligncenter fleettd'>{fhmd.dataCentername}</td>
                                                                                                            <td className='textalignright fleettd'>{fhmd.fleetSize}</td>
                                                                                                            <td className='textalignright fleettd' style={SetStyleFUHM(fhmd.color)}>{fhmd.fleetUtlization}%</td>
                                                                                                            <td className='textalignright fleettd'>{fhmd.fleetCores}</td>
                                                                                                            <td className='textalignright tdsperator fleettd'>{fhmd.recommendedFleetSize}</td>
                                                                                                            {
                                                                                                                isArrayWithLength(fhmd.dailyFleetHM) && fhmd.dailyFleetHM.map((flt: DailyFleet, key) =>
                                                                                                                    <td key={key} style={SetStyleFUHM(flt.color)} className='fleettd'>
                                                                                                                        {(flt.daliyFleetHMValue) ?
                                                                                                                            <div className="textaligncenter">
                                                                                                                                {flt.daliyFleetHMValue}%
                                                                                                                                </div>
                                                                                                                            :<div className="textaligncenter">-</div>
                                                                                                                        }
                                                                                                                    </td>
                                                                                                                )}
                                                                                                        </tr>
                                                                                                    }
                                                                                                </React.Fragment>
                                                                                            ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            <br/>
                                                                            <React.Fragment>

                                                                                {
                                                                                    isArrayWithLength(fleetDatacolorscheme) && fleetDatacolorscheme.map((flt: IFleetcolorSchemes, key) =>
                                                                                        <Row>
                                                                                            <Col lg='1'><div className="row-bottom-color-legend" style={{ backgroundColor: flt.Colour }}>&nbsp;</div></Col>
                                                                                            <Col lg='11'><div>{flt.Rangevalue}</div></Col>
                                                                                        </Row>
                                                                                    )}
                                                                            </React.Fragment>
                                                                        </React.Fragment>
                                                                        :
                                                                        <React.Fragment>
                                                                            {
                                                                                (IsfleetDataloaded === true) ? <div style={{ color: 'Red', fontWeight: 'bold', textAlign: 'center' }}> <br />
                                                                                    {(checkedFilterYellowFleetData === true && checkedFilterRedFleetData === true) ? <React.Fragment>No Red & Yellow Filter Fleet data available for the selected service</React.Fragment>
                                                                                        : (checkedFilterYellowFleetData === true) ? <React.Fragment>No Yellow Filter Fleet data  available for the selected service</React.Fragment>
                                                                                            : (checkedFilterRedFleetData === true) ? <React.Fragment>No Red Filter Fleet data available for the selected service</React.Fragment>
                                                                                                : <React.Fragment>No Fleet data available for the selected service</React.Fragment>}
                                                                                </div> : <React.Fragment></React.Fragment>
                                                                            }
                                                                        </React.Fragment>
                                                                }
                                                            </React.Fragment>
                                                            : < React.Fragment > </React.Fragment>
                                            }
                                        </TabPane>
                                    </Fragment>
                                    : <React.Fragment></React.Fragment>
                            }
                            {settabname(dh.Name)}
                        </React.Fragment>
                    ))}
                </TabContent>
            </React.Fragment>
        </div>
    )
});


DailyData.displayName = 'DailyDataTable';
export default DailyData;
