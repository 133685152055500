import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IReachabilityMetricsState } from './types';

const initialState = Object.freeze<IReachabilityMetricsState>({
    isLoading: false,
    reachabilityData: [],
    reachabilityDataTemp: [],
    FilterreachabilityData: [],
    reachabilityErrorData: [],
    reachabilityErrorDataTemp: [],
    environmentkey: 'Public',
    selectedDate: null,
    disableSave: false,
    serviceId: null,
    weekDetailsData: null,
    searchText: "",
    checkedFilterRedDailyData: false,
    checkHistory: false,
    Errorcolumn: 'serviceName',
    sortOrder: false,
    IsEdit: "",
    LoginuserId: '',
    selectedWeekId: null,
    Updated: false,
    weekfilter: {
        'week1': '', 'week2': '', 'week3': '', 'week4': '', 'week5': '',
        'week1selected': 0, 'week2selected': 0, 'week3selected': 0, 'week4selected': 0, 'week5selected': 0
    },
    Reachabilitycolumn: '',
    reachabilitysortOrder: false,
    ShowHistory1: false,
    options: [{ value: 0, label: 'All' }, { value: 1, label: '<' }, { value: 2, label: '>' }, { value: 3, label: '>=' }, { value: 4, label: '<=' }],
    ConfigValues: [],
    KPI_History: null,
    chkRetainPage: false,
});

export const reducer = (
    state: IReachabilityMetricsState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {
        case ActionType.SET_REACHABILITYMETRICS_SELECTEDDATE:
            return {
                ...state,
                selectedDate: action.selectedDate
            };
        case ActionType.LOGIN_USERNAME_REACHABILITY:
            return {
                ...state,
                LoginuserId: action.LoginuserId
            };
        case ActionType.SAVEBUTTON_DISABLE_REACHABILITY:
            return {
                ...state,
                disableSave: action.disableSave
            };
        case ActionType.GET_REACHABILITYDATA_REQUEST:
            return {
                ...state,
                isLoading: true,
                reachabilityData: [],
                reachabilityDataTemp: [],
                FilterreachabilityData: [],
                weekDetailsData: null,
                reachabilityErrorData: [],
                reachabilityErrorDataTemp: []
            };
        case ActionType.GET_REACHABILITYDATA_SUCCESS:
            return {
                ...state,
                isLoading: (action.count >= 3) ? false : state.isLoading,
                reachabilityData: action.reachabilityData,
                reachabilityDataTemp: action.reachabilityDataTemp,
                FilterreachabilityData: action.FilterreachabilityData                
            };
        case ActionType.GET_REACHABILITYDATA_FAIL:
            return {
                ...state,
                isLoading: (action.count >= 3) ? false : state.isLoading,
                reachabilityData: [],
                reachabilityDataTemp: [],
                FilterreachabilityData: []
            };
        case ActionType.GET_REACHABILITYERRORDATA_SUCCESS:
            return {
                ...state,
                isLoading: (action.count >= 3) ? false : state.isLoading,
                reachabilityErrorData: action.reachabilityErrorData,
                reachabilityErrorDataTemp: action.reachabilityErrorDataTemp
            };
        case ActionType.GET_REACHABILITYERRORDATA_FAIL:
            return {
                ...state,
                isLoading: (action.count >= 3) ? false : state.isLoading,
                reachabilityErrorData: [],
                reachabilityErrorDataTemp: []
            };
        case ActionType.WEEKLY_DETAILS_DATA_RECEIVE:
            return {
                ...state,
                weekDetailsData: action.weekDetailsData,
                isLoading: (action.count >= 3) ? false : state.isLoading
            };
        case ActionType.CONFIGVALUES_RECEIVE:
            return {
                ...state,
                ConfigValues: action.ConfigValues
            };
        case ActionType.CONFIGVALUES_RECEIVE_FAIL:
            return {
                ...state,
                ConfigValues: []
            };
        case ActionType.IS_RETAIN_PAGE:
            return {
                ...state,
                chkRetainPage: action.checked
            };
        case ActionType.KPI_HISTORY_REACHABILITY:
            return {
                ...state,
                KPI_History: action.KPI_History
            };    
        case ActionType.WEEKLY_DETAILS_DATA_RECEIVE_FAIL:
            return {
                ...state,
                weekDetailsData: null,
                isLoading: (action.count >= 3) ? false : state.isLoading
            };
        case ActionType.FILTER_WEEKDATA_REACHABILITY:
            return {
                ...state,
                reachabilityData: action.reachabilityData,
                weekfilter: action.weekfilter
            };
        case ActionType.CANCEL_EDITDATA_REACHABILITY:
            return {
                ...state,
                Updated: action.Updated,
                IsEdit: action.IsEdit,
                reachabilityData: action.reachabilityData
            };    
        case ActionType.CHANGE_SEARCHTEXT_REACHABILITY:
            return {
                ...state,
                searchText: action.searchText
            };
        case ActionType.FILTER_REACHABILITYDATA_RED: 
            return {
                ...state,
                reachabilityData: action.reachabilityData,
                checkedFilterRedDailyData: action.checkedFilterRedDailyData
            };
        case ActionType.SAVE_SUCCESSFULL_REACHABILITY:
            return {
                ...state,
                disableSave: action.disableSave,
                Updated: action.Updated,
                IsEdit: action.IsEdit,
                weekfilter: action.weekfilter,
                searchText: action.searchText
            };
        case ActionType.CHECK_HISTORY_REACHABILITY:
            return {
                ...state,
                checkHistory: action.checkHistory,
                ShowHistory1: action.ShowHistory1
            };
        case ActionType.ENABLE_EDITDATA_REACHABILITY:
            return {
                ...state,
                selectedWeekId: action.selectedWeekId,
                IsEdit: action.IsEdit,
                reachabilityData: action.reachabilityData
            };
        case ActionType.INPUT_VALUE_CHANGED_REACHABILITY:
            return {
                ...state,
                reachabilityData: action.reachabilityData
            };    
        case ActionType.SORT_REACHABILITY_ERRORDATA:
            return {
                ...state,
                Errorcolumn: action.Errorcolumn,
                sortOrder: action.sortOrder,
                reachabilityErrorData: action.reachabilityErrorData
            };
        case ActionType.SORT_REACHABILITY_DATA:
            return {
                ...state,
                Reachabilitycolumn: action.Reachabilitycolumn,
                reachabilitysortOrder: action.reachabilitysortOrder,
                reachabilityData: action.reachabilityData
            };
        case ActionType.CHECK_SAVE_RETAIN_PAGE:
            return {
                ...state,
                chkRetainPage: !!action.checked
            };

        case '@@router/LOCATION_CHANGE': {
            const { search } = action.payload.location;
            const params = new URLSearchParams(search);
            let servId = params.get('serviceid');
            const serviceId = (servId !== null) ? parseInt(servId) : null;
            var newurl = window.location.pathname;
            let weekfilter;
            //Below conditions only for direct url access like bookmarks
            if (newurl.indexOf("Reachability") !== -1) {
                weekfilter = {
                    'week1': '', 'week2': '', 'week3': '', 'week4': '', 'week5': '',
                    'week1selected': 0, 'week2selected': 0, 'week3selected': 0, 'week4selected': 0, 'week5selected': 0
                };
                return {
                    ...state,
                    weekfilter: weekfilter,
                    serviceId: serviceId
                }
            }
        };
        case ActionType.RESET_STATE:
            return initialState;

        default:
            return state;
    }
};
