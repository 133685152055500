import React, { useState, createContext, useContext } from 'react';
import bannerEmailTemplate from '../data/bannerEmailTemplate.json';

type BannerProps = {
    message: React.ReactNode;
    backgroundColor?: string;
    textColor?: string;
    topPosition?: string;
    scrollSpeed?: number;
};

const generateMailtoLink = (emailTemplate: typeof bannerEmailTemplate): string => {
    const currentURL = window.location.href;
    const emailBodyWithURL = emailTemplate.body.replace('{livesitePortalURL}', currentURL);

    const to = encodeURIComponent(emailTemplate.to);
    const subject = encodeURIComponent(emailTemplate.subject);
    const body = encodeURIComponent(emailBodyWithURL);

    return `mailto:${to}?subject=${subject}&body=${body}`;
};

const BannerContext = createContext({ mailtoLink: '' });

const BannerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const mailtoLink = generateMailtoLink(bannerEmailTemplate);

    return (
        <BannerContext.Provider value={{ mailtoLink }}>
            {children}
        </BannerContext.Provider>
    );
};

const useBannerContext = () => useContext(BannerContext);

const BannerTemplate: React.FC<BannerProps> = ({
    message,
    backgroundColor = '#ff6f61',
    textColor = 'white',
    topPosition = '11%',
    scrollSpeed = 30
}) => {
    const [isPaused, setIsPaused] = useState(false);

    const bannerStyle: React.CSSProperties = {
        color: textColor,
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: 'bold',
        padding: '10px',
        width: '100%',
        position: 'fixed',
        top: topPosition,
        left: '0',
        zIndex: 1000,
        backgroundColor,
        overflow: 'hidden',
        animation: `colorChange 7s infinite alternate`
    };

    const textContainerStyle: React.CSSProperties = {
        display: 'flex',
        whiteSpace: 'nowrap',
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
    };

    const textInnerStyle: React.CSSProperties = {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        animation: `scrollText ${scrollSpeed}s linear infinite`,
        animationPlayState: isPaused ? 'paused' : 'running'
    };

    return (
        <div>
            <main style={{ marginTop: '5%' }}>
                <div
                    style={bannerStyle}
                    className="banner"
                    onMouseEnter={() => setIsPaused(true)}
                    onMouseLeave={() => setIsPaused(false)}
                >
                    <div style={textContainerStyle}>
                        <div style={textInnerStyle} className="scrollingText">
                            {message} &nbsp;&nbsp;&nbsp; {message} &nbsp;&nbsp;&nbsp; {message}
                        </div>
                    </div>
                    <style>
                        {`
                             @keyframes colorChange {
                                0% {
                                    background-color: #f25022; /* Microsoft Red */
                                }
                                25% {
                                    background-color: #7fba00; /* Microsoft Green */
                                }
                                50% {
                                    background-color: #00a4ef; /* Microsoft Blue */
                                }
                                75% {
                                    background-color: #ffb900; /* Microsoft Yellow */
                                }
                                100% {
                                    background-color: #f25022; /* Microsoft Red */
                                }
                             }
                             
                             @keyframes scrollText {
                                0% {
                                    transform: translateX(0); /* Start fully visible */
                                }
                                100% {
                                    transform: translateX(-100%); /* Move off the screen to the left */
                                }
                             }

                             .scrollingText {
                                display: inline-block;
                                padding-left: 0; /* No unnecessary padding */
                                white-space: nowrap; /* Ensure text doesn't wrap */
                             }
                        `}
                    </style>
                </div>
            </main>
        </div>
    );
};

export const NewFunctionalityBanner: React.FC = () => (
    <BannerProvider>
        <NewFunctionalityBannerContent />
    </BannerProvider>
);

const NewFunctionalityBannerContent: React.FC = () => {
    const { mailtoLink } = useBannerContext();

    return (
        <BannerTemplate
            message={
                <>
                    We are replacing this with something new and want{' '}
                    <a href={mailtoLink} style={{ color: 'yellow', textDecoration: 'underline' }}>
                        your feedback
                    </a>.
                </>
            }
            scrollSpeed={40}
        />
    );
};

export const DeprecatedFunctionalityBanner: React.FC = () => (
    <BannerProvider>
        <DeprecatedFunctionalityBannerContent />
    </BannerProvider>
);

const DeprecatedFunctionalityBannerContent: React.FC = () => {
    const { mailtoLink } = useBannerContext();

    return (
        <BannerTemplate
            message={
                <>
                    This current functionality is{' '}
                    <span style={{
                        color: 'red',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        fontSize: '24px',
                        textShadow: '2px 2px 8px black'
                    }}>
                        DEPRECATED
                    </span>. And will be removed soon. If you are affected, please contact us{' '}
                    <a href={mailtoLink} style={{ color: 'yellow', textDecoration: 'underline' }}>
                        here
                    </a>.
                </>
            }
            scrollSpeed={50}
        />
    );
};
