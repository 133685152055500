import { BaseService } from './base.service';
import { IPropPirLearningsData } from '../store/PIRLearnings';

/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class PIRLearningsService extends BaseService {
    private static _serviceGroupService: PIRLearningsService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): PIRLearningsService {
        return (this._serviceGroupService || (this._serviceGroupService = new this('PIRLearnings')));
    }

    public async GetAllPirLearningsData(pirDate: string): Promise<IPropPirLearningsData[]> {
        const { data } = await this.$http.get<IPropPirLearningsData[]>(`GetPIRLearnings?selectedDate=${pirDate}`);
        return data;
    }

    public async AddPIRLearningsData(row: IPropPirLearningsData): Promise<number> {
            const { data } = await this.$http.post<number>(`UpdatePIRLearningsData?selectedDate=${row.id}`, row);
            return data
    }

}

export const PIRLearningsAPI = PIRLearningsService.Instance;
