import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import { actionCreators, reducer, IPropCriticalAdvisoryData } from '../../store/CriticalAdvisory';
import { CriticalAdvisoryTable } from '../CriticalAdvisory/child-components';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap'
import { Row, Col, Card, CardBody } from 'reactstrap';
import RichTextEditor from 'react-rte';
import { CriticalAdvisoryAPI } from '../../api';
import { toast } from 'react-toastify';
import { isNullOrUndefined, isArrayWithLength, renderToastifyMsg } from '../../utils';
import bxloader from '../../assets/images/bxloader.gif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faPencilAlt, faTimes, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIconMemo } from '../../components';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import Switch from 'react-switch'
import classNames from 'classnames';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';

type CriticalAdvisoryProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly username: string }
    & typeof useParams;

const CriticalAdvisory: React.FC<CriticalAdvisoryProps> = ({
    CriticalAdvisoryData,
    AzureHighPriorityLinksData,
    CriticalAdvisoryFlyZoneData,
    noFlyZoneInfo,
    noFlyZoneInfoData,
    noFlyZoneInfoId,
    GuidelineInfo,
    CriticalAdvisoryName,
    CriticalAdvisoryId,
    requestGuidelineInfo,
    isAuthenticated,
    requestCriticalAdvisoryData,
    isLoading,
    setloadingtrue,
    setloadingfalse,
    username,
    addUserVisit,
    IsLivesitePMOrUser,
    checkIsPirRereviewUser,
    AzureEnvironmentDropdown,
    AzureLocationDropdown
}) => {
    useEffect(() => {
        if (isAuthenticated)
            addUserVisit(0, 'CriticalAdvisory.tsx_v2');
    }, [isAuthenticated]);
    useEffect(() => {
        requestCriticalAdvisoryData();
        checkIsPirRereviewUser(username);
    }, [requestCriticalAdvisoryData]);
    useEffect(() => {
        if (!isNullOrUndefined(username)) {
            checkIsPirRereviewUser(username);
        }
    }, [username]);


    let emptyAdvisory = {
        advisoryId: 0,
        criticalAdvisoryName: '',
        occuredOn: '',
        azureLocation: '',
        environment: '',
        isInactive: false,
        isExpired: false,
        guidelines: '',
        displayOrder: '',
        advisoryType: '',
        lockDownFrom: '',
        lockDownTo: '',
        pstTime: '',
        utcTime: '',
        lockdownFrompst: '',
        lockdownTopst: '',
        lockdownFromutc: '',
        lockdownToutc: '',
        timezone: false
    };

    const [savedisable, setsavedisable] = useState(true);
    const [savedisablefrom, setsavedisablefrom] = useState(true);
    const [savedisableto, setsavedisableto] = useState(true);
    const [savedisableloc, setsavedisableloc] = useState(true);
    const [savedisableenv, setsavedisableenv] = useState(true);
    const [deletebtndisable, setdeletebtndisable] = useState(false);
    const toastIdRef = useRef(null);
    const [showflyZonepopup, setshowflyZonepopup] = useState(false);
    const [Disablesavebutton, setDisablesavebutton] = useState(false);
    const [noFlyZoneInfoPopup, setnoFlyZoneInfoPopup] = useState(RichTextEditor.createEmptyValue());
    const [Disableguidelinesavebutton, setDisableguidelinesavebutton] = useState(false);
    const [GuidelineEditInfoPopup, setGuidelineEditInfoPopup] = useState({ value: RichTextEditor.createEmptyValue(), popup: false });
    const [GuidelineviewInfoPopup, setGuidelineviewInfoPopup] = useState({ value: RichTextEditor.createEmptyValue(), popup: false });

    const dt = useRef(null);
    const [AdvisoryDialog, setAdvisoryDialog] = useState(false);
    const [deleteAdvisoryDialog, setDeleteAdvisoryDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [Advisory, setAdvisory] = useState(emptyAdvisory);
    const [AzureAdvisoryDialog, setAzureAdvisoryDialog] = useState(false);
    const [PlannedAzureEventsDialog, setPlannedAzureEventsDialog] = useState(false);
    const [rowData, setrowData] = useState(null);
    const [occuredonDate, SetoccuredonDate] = useState(RichTextEditor.createEmptyValue());
    const [occuredon, Setoccuredon] = useState(RichTextEditor.createEmptyValue());
    const [guiderich, Setguiderich] = useState(RichTextEditor.createEmptyValue());
    const [selectedLocations, setSelectedLocations] = useState(null);
    const [selectedEnvironment, setSelectedEnvironment] = useState(null);
    const [date1, setDate1] = useState(null);
    const [date2, setDate2] = useState(null);
    const [azDate1, setazDate1] = useState(null);
    const [azDate2, setazDate2] = useState(null);
    const [pazDate1, setpazDate1] = useState(null);
    const [pazDate2, setpazDate2] = useState(null);
    const [toPst, settoPst] = useState(true);
    const [aztoPst, setaztoPst] = useState(true);
    const [paztoPst, setpaztoPst] = useState(true);
    const [PlannedAdvisory, setPlannedAdvisory] = useState(emptyAdvisory);
    const [plannedoccuredonDate, SetplannedoccuredonDate] = useState(RichTextEditor.createEmptyValue());
    const [plannedoccuredon, Setplannedoccuredon] = useState(RichTextEditor.createEmptyValue());
    const [selectedPlannedAzureLocations, setPlannedAzureSelectedLocations] = useState(null);
    const [selectedPlannedEnvironment, setSelectedPlannedEnvironment] = useState(null);
    const [saveplanneddisable, setsaveplanneddisable] = useState(true);
    const [saveplanneddisablefrom, setplannedsavedisablefrom] = useState(true);
    const [saveplanneddisableto, setplannedsavedisableto] = useState(true);
    const [saveplanneddisableloc, setplannedsavedisableloc] = useState(true);
    const [saveplanneddisableenv, setplannedsavedisableenv] = useState(true);
    const [deleteplannedbtndisable, setplanneddeletebtndisable] = useState(false);
    const [deletePlannedAdvisoryDialog, setDeletePlannedAdvisoryDialog] = useState(false);
    const re = /^[0-9\b]+$/;

    const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
            { label: 'Italic', style: 'ITALIC' },
            { label: 'Underline', style: 'UNDERLINE' }
        ],
        BLOCK_TYPE_DROPDOWN: [
            { label: 'Normal', style: 'unstyled' },
            { label: 'Heading Large', style: 'header-one' },
            { label: 'Heading Medium', style: 'header-two' },
            { label: 'Heading Small', style: 'header-three' }
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item' },
            { label: 'OL', style: 'ordered-list-item' }
        ]
    };
    //=============function to open Modal popup when clicked on No flyZone Info for Banner Button====================//
    const NoFlyZoneInfoEdit = (e, row) => {
        setDisablesavebutton(false);
        setshowflyZonepopup(true);
        setnoFlyZoneInfoPopup(RichTextEditor.createValueFromString(noFlyZoneInfoData, "html"));
    }
    //=============== function to close No FlyZone Info popup =========================//
    const closeflyZonepopup = () => {
        setshowflyZonepopup(false);
    }

    //=============function to open Modal popup when clicked on Edit Guidelines for Critical advisory Data====================//
    const GuidelinesEdit = (e, row) => {
        requestGuidelineInfo(row.criticalAdvisoryName, row.advisoryId)
        setDisableguidelinesavebutton(false);
        setGuidelineEditInfoPopup({ value: RichTextEditor.createValueFromString(row.guidelines, "html"), popup: true });
    }
    //=============== function to close guidelines Edit popup =========================//
    const closeGuidelinesEditpopup = () => {
        setGuidelineEditInfoPopup({ value: RichTextEditor.createEmptyValue(), popup: false });
    }

    //=============function to open Modal popup when clicked on View Guidelines for Critical advisory Data====================//
    const GuidelinesView = (e, row) => {
        requestGuidelineInfo(row.criticalAdvisoryName, row.advisoryId)
        setGuidelineviewInfoPopup({ value: RichTextEditor.createValueFromString(row.guidelines, "html"), popup: true });
    }
    //=============== function to close Guidelines View popup =========================//
    const closeGuidelinesViewpopup = () => {
        setGuidelineviewInfoPopup({ value: RichTextEditor.createEmptyValue(), popup: false });
    }

    // Function to Validate the No Fly Zone Banner Data
    const FlyZoneInfoValidate = function () {
        var disbaled = false;
        if (noFlyZoneInfoPopup.toString("html") !== undefined) {
            if (noFlyZoneInfoPopup.toString("html").replace(/<(.|\n)*?>/g, '').length !== 0) {
                var str = noFlyZoneInfoPopup.toString("html").replace(/<(.|\n)*?>/g, '').replace(/ /g, '').replace(/^(&nbsp;)+/g, '').replace(/^(<br>)+/g, '').replace(/^(<br\/>)+/g, '');
                if (str.length == 0) {
                    disbaled = true;
                }
                var docDesc = noFlyZoneInfoPopup.toString("html").replace(/<(.|\n)*?>/g, '').replace(/[&]nbsp[;]/gi, " "); // removes all occurrences of &nbsp;
                docDesc = docDesc.replace(/[<]br[^>]*[>]/gi, "");
                if (docDesc.length > 250) {
                    disbaled = true;
                }
            }
        }
        return disbaled;
    };

    //================ function to set No FlyZone Info Value ========//
    const UpdatenoFlyZoneInfoPopup = (e) => {
        setnoFlyZoneInfoPopup(e);
    }

    //================ function to set Guidelines Value ========//
    const UpdateGuidelineInfoPopup = (e) => {
        setGuidelineEditInfoPopup({ value: e, popup: true });
    }

    //=============function to close Modal popup when clicked Cancel in Modal Popup for Edit Guidelines====================//
    const NoFlyZoneInfoBannerCancel = () => {
        setshowflyZonepopup(false);
        setnoFlyZoneInfoPopup(RichTextEditor.createEmptyValue());
        setDisablesavebutton(false);
    }

    //=============function to close Modal popup when clicked Cancel in Modal Popup for Edit guidelines Data====================//
    const GuidelinesCACancel = () => {
        setGuidelineEditInfoPopup({ value: RichTextEditor.createEmptyValue(), popup: false });
        setDisableguidelinesavebutton(false);
    }

    //============== function to edit Critical Advisory Data (Checkbox onchange) for selected row ======================//
    const UpdateCriticalAdvisoryData = (e, row) => {
        if (window.confirm("Are you sure you want to make " + row.criticalAdvisoryName + " Advisory Inactive?")) {
            setloadingtrue();
            CriticalAdvisoryAPI.UpdateCriticalAdvisoryData(row.advisoryId)
                .then((result: number) => {
                    if (result != -1) {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg('Is Inactive flag Updated succesfully for Advisory ' + row.criticalAdvisoryName,
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                        requestCriticalAdvisoryData();
                    }
                    else {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while updating",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
    }
    //============== function to Save the No Fly Zone Banner Data ======================//
    const saveFlyZoneInfo = () => {
        setloadingtrue();
        setDisablesavebutton(true);
        var noFlyZoneInfoDataUpdated = noFlyZoneInfoPopup.toString("html");
        var noFlyZoneInfodata = {
            noFlyZoneInfoId: (noFlyZoneInfoId === undefined || noFlyZoneInfoId === null) ? 0 : noFlyZoneInfoId,
            noFlyZoneInfo: (noFlyZoneInfoDataUpdated === undefined || noFlyZoneInfoDataUpdated === null) ? "" : noFlyZoneInfoDataUpdated,
            isExpired: false,
            updatedBy: username
        };
        CriticalAdvisoryAPI.UpdateCriticalAdvisoryNoFlyZoneData(noFlyZoneInfodata)
            .then((result: number) => {
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg('No Fly Zone Information Updated succesfully',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    setloadingfalse();
                    setshowflyZonepopup(false);
                    requestCriticalAdvisoryData();
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });

    }
    //============== function to Save the Guidelines Info for selected row ======================//
    const saveGuidelinesInfo = () => {
        setloadingtrue();
        setDisableguidelinesavebutton(true);
        var guidelineInfoUpdated = GuidelineEditInfoPopup.value.toString("html");
        var guidelineInfo = {
            advisoryId: (CriticalAdvisoryId === undefined || CriticalAdvisoryId === null) ? 0 : CriticalAdvisoryId,
            guidelines: (guidelineInfoUpdated === undefined || guidelineInfoUpdated === null) ? "" : guidelineInfoUpdated,
            lastUpdatedBy: username
        };
        CriticalAdvisoryAPI.UpdateCriticalAdvisoryGuidelineData(guidelineInfo)
            .then((result: number) => {
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg("Guideline Information Updated succesfully for " + CriticalAdvisoryName,
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    requestGuidelineInfo(CriticalAdvisoryName, CriticalAdvisoryId);
                    requestCriticalAdvisoryData();
                    setloadingfalse();
                    setGuidelineEditInfoPopup({ value: RichTextEditor.createEmptyValue(), popup: false });
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });

    }

    // function to Validate the Guideline Info Disable the Href if no data Present for Normal user
    const GuidelineInfoValidate = (Id) => {
        var disabled = false;
        CriticalAdvisoryData.map((obj: IPropCriticalAdvisoryData) => {
            if (obj.advisoryId == Id) {
                let guidecheck = obj.guidelines.toString().replace(/<(.|\n)*?>/g, '').replace(/ /g, '').replace(/^(&nbsp;)+/g, '').replace(/^(<br>)+/g, '').replace(/^(<br\/>)+/g, '');
                disabled = guidecheck === null || guidecheck.length === 0 ? true : false;
            }
        });
        return disabled;
    };

    const openNew = () => {
        emptyAdvisory.advisoryType = "Identity";
        setDate1('');
        setDate2('');
        setAdvisory(emptyAdvisory);
        setSubmitted(false);
        setAdvisoryDialog(true);
        setSelectedLocations([]);
        setSelectedEnvironment([]);
        setsavedisableloc(true);
        setsavedisableenv(true);
        SetoccuredonDate(RichTextEditor.createEmptyValue());
        Setoccuredon(RichTextEditor.createEmptyValue());
        Setguiderich(RichTextEditor.createEmptyValue());
        settoPst(true);
    }

    const openAzureNew = () => {
        emptyAdvisory.advisoryType = "Azure";
        setazDate1('');
        setazDate2('');
        setAdvisory(emptyAdvisory);
        setSubmitted(false);
        setSelectedLocations([]);
        setSelectedEnvironment([]);
        setAzureAdvisoryDialog(true);
        setsavedisableloc(true);
        setsavedisableenv(true);
        SetoccuredonDate(RichTextEditor.createEmptyValue());
        Setoccuredon(RichTextEditor.createEmptyValue());
        Setguiderich(RichTextEditor.createEmptyValue());
        settoPst(true);
        setaztoPst(true);
    }
    const openPlannedAzureItems = () => {
        emptyAdvisory.advisoryType = "PlannedAzure";
        setpazDate1('');
        setpazDate2('');
        setPlannedAdvisory(emptyAdvisory);
        setPlannedAzureEventsDialog(true);
        setSubmitted(false);
        setPlannedAzureSelectedLocations([]);
        setSelectedPlannedEnvironment([]);
        setplannedsavedisableloc(true);
        setSelectedPlannedEnvironment(true);
        SetplannedoccuredonDate(RichTextEditor.createEmptyValue());
        Setplannedoccuredon(RichTextEditor.createEmptyValue());
        Setguiderich(RichTextEditor.createEmptyValue());
        settoPst(true);
        setaztoPst(true);
        setpaztoPst(true);
    }

    const SetoccuredonValue = (e) => {
        setsavedisable(false);
        Setoccuredon(e);
    }

    const SetoccuredonValueDate = (e) => {
        SetoccuredonDate(e);
        if (occuredonDate.toString("html") == '<p><br></p>' || occuredonDate.toString("html") == '<p>&nbsp;</p>') {
            setsavedisable(true);
        }
        else {
            setsavedisable(false);
        }
    }

    const SetguiderichValue = (e) => {
        Setguiderich(e)
    }

    const changeLocation = (e) => {
        setSelectedLocations(e.value);
        if (e.value.length > 0) {
            setsavedisableloc(false);
        }
        else {
            setsavedisableloc(true);
        }
    }

    const changeEnv = (e) => {
        setSelectedEnvironment(e.value);
        if (e.value.length > 0) {
            setsavedisableenv(false);
        }
        else {
            setsavedisableenv(true);
        }
    }

    const hideDialog = () => {
        setAdvisory(emptyAdvisory);
        setSubmitted(false);
        setAdvisoryDialog(false);
        setAzureAdvisoryDialog(false);
        setPlannedAzureEventsDialog(false);
    }

    const hideAzureDialog = () => {
        setAdvisory(emptyAdvisory);
        setSubmitted(false);
        setAzureAdvisoryDialog(false);
    }

    const hidePlannedAzureDialog = () => {
        setPlannedAdvisory(emptyAdvisory);
        setSubmitted(false);
        setPlannedAzureEventsDialog(false);
    }

    const hideDeleteAdvisoryDialog = () => {
        setDeleteAdvisoryDialog(false);
    }

    const diff_hours = (dt2, dt1) => {
        var diff = (new Date(dt2).getHours() - new Date(dt1).getHours());
        return Math.abs(Math.round(diff));
    }

    const saveAdvisory = (e, rowData) => {
        if (rowData == null) {
            rowData = Advisory;
        }
        if (occuredonDate.toString("html") == '<p><br></p>' || occuredonDate.toString("html") == '<p>&nbsp;</p>') {
            toastIdRef.current = toast.error(
                renderToastifyMsg("When (PT/UTC) should not be empty",
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
            return false;
        }

        setSubmitted(true);
        setsavedisable(true);
        let AddCritAdvisoryData = Advisory;
        AddCritAdvisoryData.azureLocation = selectedLocations.toString();
        AddCritAdvisoryData.environment = selectedEnvironment.toString();
        if (occuredon.toString("html") == '<p><br></p>' || occuredon.toString("html") == '<p>&nbsp;</p>') {
            AddCritAdvisoryData.occuredOn = occuredonDate.toString("html") + "~!@#~";
        }
        else {
            AddCritAdvisoryData.occuredOn = occuredonDate.toString("html") + "~!@#~" + occuredon.toString("html") + "~!@#~";
        }
        AddCritAdvisoryData.guidelines = guiderich.toString("html");
        if (rowData.advisoryType == 'Azure') {
            AddCritAdvisoryData.timezone = aztoPst
        }
        if (rowData.advisoryType == 'Identity') {
            AddCritAdvisoryData.timezone = toPst
        }
        if (Advisory.advisoryId != 0) {
            var advisoryObj = {
                advisoryId: AddCritAdvisoryData.advisoryId,
                criticalAdvisoryName: AddCritAdvisoryData.criticalAdvisoryName,
                occuredOn: AddCritAdvisoryData.occuredOn,
                azureLocation: AddCritAdvisoryData.azureLocation,
                environment: AddCritAdvisoryData.environment,
                isInactive: AddCritAdvisoryData.isInactive,
                isExpired: AddCritAdvisoryData.isExpired,
                guidelines: AddCritAdvisoryData.guidelines,
                displayOrder: AddCritAdvisoryData.displayOrder,
                advisoryType: AddCritAdvisoryData.advisoryType,
                lockDownFrom: AddCritAdvisoryData.lockDownFrom.toLocaleString().replace(',', ''),
                lockDownTo: AddCritAdvisoryData.lockDownTo.toLocaleString().replace(',', ''),
                timezone: AddCritAdvisoryData.timezone
            }
            CriticalAdvisoryAPI.UpdateAdRecord(advisoryObj)
                .then((result: number) => {
                    if (result != -1) {
                        setAdvisoryDialog(false);
                        setAzureAdvisoryDialog(false);
                        toastIdRef.current = toast.success(
                            renderToastifyMsg("Critical Advisory Updated",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                        requestCriticalAdvisoryData();
                        setloadingfalse();
                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while updating Advisory",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });

        }
        else {
            var advisoryObj = {
                advisoryId: AddCritAdvisoryData.advisoryId,
                criticalAdvisoryName: AddCritAdvisoryData.criticalAdvisoryName,
                occuredOn: AddCritAdvisoryData.occuredOn,
                azureLocation: AddCritAdvisoryData.azureLocation,
                environment: AddCritAdvisoryData.environment,
                isInactive: AddCritAdvisoryData.isInactive,
                isExpired: AddCritAdvisoryData.isExpired,
                guidelines: AddCritAdvisoryData.guidelines,
                displayOrder: AddCritAdvisoryData.displayOrder,
                advisoryType: AddCritAdvisoryData.advisoryType,
                lockDownFrom: AddCritAdvisoryData.lockDownFrom.toLocaleString().replace(',', ''),
                lockDownTo: AddCritAdvisoryData.lockDownTo.toLocaleString().replace(',', ''),
                timezone: AddCritAdvisoryData.timezone
            }
            CriticalAdvisoryAPI.AddNewCriticalAdvisory(advisoryObj)
                .then((result: number) => {
                    if (result == -1) {
                        setAdvisoryDialog(false);
                        setAzureAdvisoryDialog(false);
                        toastIdRef.current = toast.success(
                            renderToastifyMsg("New Critical Advisory Added" + CriticalAdvisoryName,
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                        requestCriticalAdvisoryData();
                        setloadingfalse();
                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while Adding new Advisory",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }

    }
    const pstChange = (value) => {
        if (value) {
            settoPst(true);
            if (!isNullOrUndefined(Advisory.lockDownTo)) {
                //converting Advisory from date from pst to utc
                let pstfrmTime = new Date(Advisory.lockDownFrom.toLocaleString());
                let utcfrmTime = new Date(Advisory.lockDownFrom.toLocaleString());
                utcfrmTime.setHours(utcfrmTime.getHours() + getDiffPstUtc());
                Advisory.lockdownFrompst = moment(pstfrmTime).format("dddd, MMMM Do YYYY hh:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                Advisory.lockdownFromutc = moment(utcfrmTime).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");

                //converting Advisory To date from Pst to utc
                let psttoTime = new Date(Advisory.lockDownTo.toLocaleString());
                let utctoTime = new Date(Advisory.lockDownTo.toLocaleString());
                utctoTime.setHours(utctoTime.getHours() + getDiffPstUtc());
                Advisory.lockdownTopst = moment(psttoTime).format("dddd, MMMM Do YYYY hh:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                Advisory.lockdownToutc = moment(utctoTime).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");

                //converted pst to UTC date assignment to accured on 
                if (isNullOrUndefined(Advisory.lockdownFrompst) && isNullOrUndefined(Advisory.lockdownFromutc)) {
                    Advisory.lockdownFrompst = moment(Advisory.lockDownFrom).format("dddd, MMMM Do YYYY hh:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                    Advisory.lockdownFromutc = moment(Advisory.lockDownFrom).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                }
                if (occuredonDate.toString("html").replace(/<(.|\n)*?>/g, '').replace(/ /g, '').replace(/^(&nbsp;)+/g, '').replace(/^(<br>)+/g, '').replace(/^(<br\/>)+/g, '') == "") {
                    let Lockdownperiod = Advisory.lockdownFrompst + " - " + Advisory.lockdownTopst + " PT <br> " + " " + Advisory.lockdownFromutc + " UTC - " + Advisory.lockdownToutc + " UTC";
                    SetoccuredonDate(RichTextEditor.createValueFromString(Lockdownperiod, "html"));
                }
                else {
                    SetoccuredonDate(RichTextEditor.createEmptyValue());
                    let Lockdownperiod = occuredonDate.toString("html").replace(occuredonDate.toString("html").split(";")[0], Advisory.lockdownFrompst + " - " + Advisory.lockdownTopst + " PT <br> " + " " + Advisory.lockdownFromutc + " - " + Advisory.lockdownToutc + " UTC");
                    SetoccuredonDate(RichTextEditor.createValueFromString(Lockdownperiod, "html"));
                }
            }
        }
        else {
            settoPst(false);
            if (!isNullOrUndefined(Advisory.lockDownTo)) {
                //Converting Advisory from date UTC-PT
                let pstfrmTime = new Date(Advisory.lockDownFrom.toLocaleString());
                let utcfrmTime = new Date(Advisory.lockDownFrom.toLocaleString());
                pstfrmTime.setHours(pstfrmTime.getHours() - getDiffPstUtc());
                Advisory.lockdownFrompst = moment(pstfrmTime).format("dddd, MMMM Do YYYY hh:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                Advisory.lockdownFromutc = moment(utcfrmTime).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");

                //converting Advisory To date UTC-PT
                let psttoTime = new Date(Advisory.lockDownTo.toLocaleString());
                let utctoTime = new Date(Advisory.lockDownTo.toLocaleString());
                psttoTime.setHours(psttoTime.getHours() - getDiffPstUtc());
                Advisory.lockdownTopst = moment(psttoTime).format("dddd, MMMM Do YYYY hh:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                Advisory.lockdownToutc = moment(utctoTime).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                if (isNullOrUndefined(Advisory.lockdownFrompst) && isNullOrUndefined(Advisory.lockdownFromutc)) {
                    Advisory.lockdownFrompst = moment(Advisory.lockDownFrom).format("dddd, MMMM Do YYYY hh:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                    Advisory.lockdownFromutc = moment(Advisory.lockDownFrom).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                }
                if (occuredonDate.toString("html").replace(/<(.|\n)*?>/g, '').replace(/ /g, '').replace(/^(&nbsp;)+/g, '').replace(/^(<br>)+/g, '').replace(/^(<br\/>)+/g, '') == "") {
                    let Lockdownperiod = Advisory.lockdownFrompst + " - " + Advisory.lockdownTopst + " PT <br> " + " " + Advisory.lockdownFromutc + " UTC - " + Advisory.lockdownToutc + " UTC";
                    SetoccuredonDate(RichTextEditor.createValueFromString(Lockdownperiod, "html"));
                }
                else {
                    SetoccuredonDate(RichTextEditor.createEmptyValue());
                    let Lockdownperiod = occuredonDate.toString("html").replace(occuredonDate.toString("html").split(";")[0], Advisory.lockdownFrompst + " - " + Advisory.lockdownTopst + " PT <br> " + " " + Advisory.lockdownFromutc + " - " + Advisory.lockdownToutc + " UTC");
                    SetoccuredonDate(RichTextEditor.createValueFromString(Lockdownperiod, "html"));
                }
            }
        }
    }

    const azpstChange = (value) => {
        if (value) {
            setaztoPst(true);
            if (!isNullOrUndefined(Advisory.lockDownTo)) {
                //converting Advisory from date from pst to utc
                let pstfrmTime = new Date(Advisory.lockDownFrom.toLocaleString());
                let utcfrmTime = new Date(Advisory.lockDownFrom.toLocaleString());
                utcfrmTime.setHours(utcfrmTime.getHours() + getDiffPstUtc());
                Advisory.lockdownFrompst = moment(pstfrmTime).format("dddd, MMMM Do YYYY hh:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                Advisory.lockdownFromutc = moment(utcfrmTime).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");

                //converting Advisory To date from Pst to utc
                let psttoTime = new Date(Advisory.lockDownTo.toLocaleString());
                let utctoTime = new Date(Advisory.lockDownTo.toLocaleString());
                utctoTime.setHours(utctoTime.getHours() + getDiffPstUtc());
                Advisory.lockdownTopst = moment(psttoTime).format("dddd, MMMM Do YYYY hh:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                Advisory.lockdownToutc = moment(utctoTime).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");

                //converted pst to UTC date assignment to accured on 
                if (isNullOrUndefined(Advisory.lockdownFrompst) && isNullOrUndefined(Advisory.lockdownFromutc)) {
                    Advisory.lockdownFrompst = moment(Advisory.lockDownFrom).format("dddd, MMMM Do YYYY hh:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                    Advisory.lockdownFromutc = moment(Advisory.lockDownFrom).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                }
                if (occuredonDate.toString("html").replace(/<(.|\n)*?>/g, '').replace(/ /g, '').replace(/^(&nbsp;)+/g, '').replace(/^(<br>)+/g, '').replace(/^(<br\/>)+/g, '') == "") {
                    let Lockdownperiod = Advisory.lockdownFrompst + " - " + Advisory.lockdownTopst + " PT <br> " + " " + Advisory.lockdownFromutc + " UTC - " + Advisory.lockdownToutc + " UTC";
                    SetoccuredonDate(RichTextEditor.createValueFromString(Lockdownperiod, "html"));
                }
                else {
                    SetoccuredonDate(RichTextEditor.createEmptyValue());
                    let Lockdownperiod = Advisory.lockdownFrompst + " - " + Advisory.lockdownTopst + " PT <br> " + " " + Advisory.lockdownFromutc + " - " + Advisory.lockdownToutc + " UTC";
                    SetoccuredonDate(RichTextEditor.createValueFromString(Lockdownperiod, "html"));
                }
            }
        }
        else {
            setaztoPst(false);
            if (!isNullOrUndefined(Advisory.lockDownTo)) {
                //Converting Advisory from date UTC-PT
                let pstfrmTime = new Date(Advisory.lockDownFrom.toLocaleString());
                let utcfrmTime = new Date(Advisory.lockDownFrom.toLocaleString());
                pstfrmTime.setHours(pstfrmTime.getHours() - getDiffPstUtc());
                Advisory.lockdownFrompst = moment(pstfrmTime).format("dddd, MMMM Do YYYY hh:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                Advisory.lockdownFromutc = moment(utcfrmTime).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");

                //converting Advisory To date UTC-PT
                let psttoTime = new Date(Advisory.lockDownTo.toLocaleString());
                let utctoTime = new Date(Advisory.lockDownTo.toLocaleString());
                psttoTime.setHours(psttoTime.getHours() - getDiffPstUtc());
                Advisory.lockdownTopst = moment(psttoTime).format("dddd, MMMM Do YYYY hh:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                Advisory.lockdownToutc = moment(utctoTime).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                if (isNullOrUndefined(Advisory.lockdownFrompst) && isNullOrUndefined(Advisory.lockdownFromutc)) {
                    Advisory.lockdownFrompst = moment(Advisory.lockDownFrom).format("dddd, MMMM Do YYYY hh:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                    Advisory.lockdownFromutc = moment(Advisory.lockDownFrom).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                }
                if (occuredonDate.toString("html").replace(/<(.|\n)*?>/g, '').replace(/ /g, '').replace(/^(&nbsp;)+/g, '').replace(/^(<br>)+/g, '').replace(/^(<br\/>)+/g, '') == "") {
                    let Lockdownperiod = Advisory.lockdownFrompst + " - " + Advisory.lockdownTopst + " PT <br> " + " " + Advisory.lockdownFromutc + " UTC - " + Advisory.lockdownToutc + " UTC";
                    SetoccuredonDate(RichTextEditor.createValueFromString(Lockdownperiod, "html"));
                }
                else {
                    SetoccuredonDate(RichTextEditor.createEmptyValue());
                    let Lockdownperiod = occuredonDate.toString("html").replace(occuredonDate.toString("html").split(";")[0], Advisory.lockdownFrompst + " - " + Advisory.lockdownTopst + " PT <br> " + " " + Advisory.lockdownFromutc + " - " + Advisory.lockdownToutc + " UTC");
                    SetoccuredonDate(RichTextEditor.createValueFromString(Lockdownperiod, "html"));
                }
            }
        }
    }
    const deleteAdvisory = () => {
        setrowData(rowData);
        setdeletebtndisable(true);
        CriticalAdvisoryAPI.DeleteCriticalAdvisoryRecord(rowData.advisoryId)
            .then((result: number) => {
                setDeleteAdvisoryDialog(false);
                if (result != -1) {
                    requestCriticalAdvisoryData();
                    toastIdRef.current = toast.success(
                        renderToastifyMsg("Deleted successfully",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    setdeletebtndisable(false);
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while deleting",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
                setdeletebtndisable(false);
            });
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Advisory = { ...Advisory };
        if (name == 'displayOrder') {
            if (e.target.value === '' || re.test(e.target.value)) {
                _Advisory[`${name}`] = val;
                setAdvisory(_Advisory);
            }
        }
        else if (name == 'criticalAdvisoryName') {
            if (e.target.value.trim() === '') {
                _Advisory[`${name}`] = val;
                setAdvisory(_Advisory);
                setsavedisable(true);
            }
            else {
                _Advisory[`${name}`] = val;
                setAdvisory(_Advisory);
                setsavedisable(false);
            }
        }
        else {
            _Advisory[`${name}`] = val;
            setAdvisory(_Advisory);
        }
    }

    const fromDateChange = (value, name) => {
        if (toPst) {
            if (!isNullOrUndefined(value)) {
                let pstTime = new Date(value.toLocaleString());
                let utcTime = new Date(value.toLocaleString());
                utcTime.setHours(utcTime.getHours() + getDiffPstUtc());
                Advisory.lockdownFrompst = moment(pstTime).format("dddd, MMMM Do YYYY hh:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                Advisory.lockdownFromutc = moment(utcTime).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                setDate1(value);
                Advisory.lockDownFrom = value; //value.toUTCString();
                setDate2('');
                Advisory.lockDownTo = '';
                setsavedisableto(true);
                setsavedisablefrom(false);
            }
            else {
                setsavedisablefrom(true);
            }
        }
        else {
            if (!isNullOrUndefined(value)) {
                let utcTime = new Date(value.toLocaleString());
                let pstTime = new Date(value.toLocaleString());
                pstTime.setHours(pstTime.getHours() - getDiffPstUtc());
                Advisory.lockdownFrompst = moment(pstTime).format("dddd, MMMM Do YYYY hh:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                Advisory.lockdownFromutc = moment(utcTime).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                setDate1(value);
                Advisory.lockDownFrom = value; //value.toUTCString();
                setDate2('');
                Advisory.lockDownTo = '';
                setsavedisableto(true);
                setsavedisablefrom(false);
            }
            else {
                setsavedisablefrom(true);
            }
        }
    }
    const toDateChange = (value, name) => {
        if (toPst) {
            if (!isNullOrUndefined(value)) {
                setDate2(value);
                Advisory.lockDownTo = value;//value.toUTCString();
                let pstTime = new Date(value.toLocaleString());
                let utcTime = new Date(value.toLocaleString());
                utcTime.setHours(utcTime.getHours() + getDiffPstUtc());
                /*let selectedDate = new Date(value);*/
                /*let pstaddseven = new Date(selectedDate.setHours(selectedDate.getHours() - 7));*/
                Advisory.lockdownTopst = moment(pstTime).format("dddd, MMMM Do YYYY hh:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                Advisory.lockdownToutc = moment(utcTime).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                if (isNullOrUndefined(Advisory.lockdownFrompst) && isNullOrUndefined(Advisory.lockdownFromutc)) {
                    Advisory.lockdownFrompst = moment(Advisory.lockDownFrom).format("dddd, MMMM Do YYYY hh:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                    Advisory.lockdownFromutc = moment(Advisory.lockDownFrom).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                }
                if (occuredonDate.toString("html").replace(/<(.|\n)*?>/g, '').replace(/ /g, '').replace(/^(&nbsp;)+/g, '').replace(/^(<br>)+/g, '').replace(/^(<br\/>)+/g, '') == "") {
                    let Lockdownperiod = Advisory.lockdownFrompst + " PT - " + Advisory.lockdownTopst + " PT <br> " + " " + Advisory.lockdownFromutc + " UTC - " + Advisory.lockdownToutc + " UTC";
                    SetoccuredonDate(RichTextEditor.createValueFromString(Lockdownperiod, "html"));
                }
                else {
                    SetoccuredonDate(RichTextEditor.createEmptyValue());
                    let Lockdownperiod = Advisory.lockdownFrompst + " - " + Advisory.lockdownTopst + " PT <br> " + " " + Advisory.lockdownFromutc + " - " + Advisory.lockdownToutc + " UTC";
                    SetoccuredonDate(RichTextEditor.createValueFromString(Lockdownperiod, "html"));
                }
                setsavedisableto(false);
                setsavedisable(false);
            }
            else {
                setsavedisableto(true);
            }
        }
        else {
            if (!isNullOrUndefined(value)) {
                setDate2(value);
                Advisory.lockDownTo = value;//value.toUTCString();
                let utcTime = new Date(value.toLocaleString());
                let pstTime = new Date(value.toLocaleString());
                pstTime.setHours(pstTime.getHours() - getDiffPstUtc());
                Advisory.lockdownTopst = moment(pstTime).format("dddd, MMMM Do YYYY hh:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                Advisory.lockdownToutc = moment(utcTime).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                if (isNullOrUndefined(Advisory.lockdownFrompst) && isNullOrUndefined(Advisory.lockdownFromutc)) {
                    Advisory.lockdownFrompst = moment(Advisory.lockDownFrom).format("dddd, MMMM Do YYYY hh:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                    Advisory.lockdownFromutc = moment(Advisory.lockDownFrom).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                }
                if (occuredonDate.toString("html").replace(/<(.|\n)*?>/g, '').replace(/ /g, '').replace(/^(&nbsp;)+/g, '').replace(/^(<br>)+/g, '').replace(/^(<br\/>)+/g, '') == "") {
                    let Lockdownperiod = Advisory.lockdownFrompst + " - " + Advisory.lockdownTopst + " PT <br> " + " " + Advisory.lockdownFromutc + " UTC - " + Advisory.lockdownToutc + " UTC";
                    SetoccuredonDate(RichTextEditor.createValueFromString(Lockdownperiod, "html"));
                }
                else {
                    SetoccuredonDate(RichTextEditor.createEmptyValue());
                    let Lockdownperiod = occuredonDate.toString("html").replace(occuredonDate.toString("html").split(";")[0], Advisory.lockdownFrompst + " - " + Advisory.lockdownTopst + " PT <br> " + " " + Advisory.lockdownFromutc + " - " + Advisory.lockdownToutc + " UTC");
                    SetoccuredonDate(RichTextEditor.createValueFromString(Lockdownperiod, "html"));
                }
                setsavedisableto(false);
                setsavedisable(false);
            }
            else {
                setsavedisableto(true);
            }
        }
    }

    const plannedInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Advisory = { ...PlannedAdvisory };
        if (name == 'displayOrder') {
            if (e.target.value === '' || re.test(e.target.value)) {
                _Advisory[`${name}`] = val;
                setPlannedAdvisory(_Advisory);
            }
        }
        else if (name == 'criticalAdvisoryName') {
            if (e.target.value.trim() === '') {
                _Advisory[`${name}`] = val;
                setPlannedAdvisory(_Advisory);
                setsaveplanneddisable(true);
            }
            else {
                _Advisory[`${name}`] = val;
                setPlannedAdvisory(_Advisory);
                setsaveplanneddisable(false);
            }
        }
        else {
            _Advisory[`${name}`] = val;
            setPlannedAdvisory(_Advisory);
        }
    }

    const azFromDateChange = (value, name) => {
        if (aztoPst) {
            if (!isNullOrUndefined(value)) {
                let pstTime = new Date(value.toLocaleString());
                let utcTime = new Date(value.toLocaleString());
                utcTime.setHours(utcTime.getHours() + getDiffPstUtc());
                Advisory.lockdownFrompst = moment(pstTime).format("dddd, MMMM Do YYYY h:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                Advisory.lockdownFromutc = moment(utcTime).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                setazDate1(value);
                Advisory.lockDownFrom = value;//value.toUTCString();
                setsavedisablefrom(false);
                setazDate2('');
                setsavedisableto(true);
            }
            else {
                setsavedisablefrom(true);
            }
        }
        else {
            if (!isNullOrUndefined(value)) {
                /*let pstTime = new Date(value.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }));*/
                let utcTime = new Date(value.toLocaleString());
                let pstTime = new Date(value.toLocaleString());
                pstTime.setHours(pstTime.getHours() - getDiffPstUtc());
                Advisory.lockdownFrompst = moment(pstTime).format("dddd, MMMM Do YYYY h:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                Advisory.lockdownFromutc = moment(utcTime).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                setazDate1(value);
                Advisory.lockDownFrom = value;//value.toUTCString();
                setsavedisablefrom(false);
                setazDate2('');
                setsavedisableto(true);
            }
            else {
                setsavedisablefrom(true);
            }
        }
    }
    const azToDateChange = (value, name) => {
        if (aztoPst) {
            if (!isNullOrUndefined(value)) {
                setazDate2(value);
                Advisory.lockDownTo = value;//value.toUTCString();
                let pstTime = new Date(value.toLocaleString());
                let utcTime = new Date(value.toLocaleString());
                utcTime.setHours(utcTime.getHours() + getDiffPstUtc());
                //let selectedDate = new Date(value);
                //let pstaddseven = new Date(selectedDate.setHours(selectedDate.getHours() - 7));
                Advisory.lockdownTopst = moment(pstTime).format("dddd, MMMM Do YYYY h:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                Advisory.lockdownToutc = moment(utcTime).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                if (isNullOrUndefined(Advisory.lockdownFrompst) && isNullOrUndefined(Advisory.lockdownFromutc)) {
                    Advisory.lockdownFrompst = moment(Advisory.lockDownFrom).format("dddd, MMMM Do YYYY h:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                    Advisory.lockdownFromutc = moment(Advisory.lockDownFrom).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                }
                if (occuredonDate.toString("html") == "<p><br></p>") {
                    let Lockdownperiod = Advisory.lockdownFrompst + " - " + Advisory.lockdownTopst + " PT <br> " + " " + Advisory.lockdownFromutc + " - " + Advisory.lockdownToutc + " UTC";
                    SetoccuredonDate(RichTextEditor.createValueFromString(Lockdownperiod, "html"));
                }
                else {
                    SetoccuredonDate(RichTextEditor.createEmptyValue());
                    let Lockdownperiod = occuredonDate.toString("html").replace(occuredonDate.toString("html").split(";")[0], Advisory.lockdownFrompst + " - " + Advisory.lockdownTopst + " PT <br> " + " " + Advisory.lockdownFromutc + " - " + Advisory.lockdownToutc + " UTC");
                    SetoccuredonDate(RichTextEditor.createValueFromString(Lockdownperiod, "html"));
                }
                setsavedisableto(false);
                setsavedisable(false);
            }
            else {
                setsavedisableto(true);
            }
        }
        else {
            if (!isNullOrUndefined(value)) {
                setazDate2(value);
                Advisory.lockDownTo = value;//value.toUTCString();
                let utcTime = new Date(value.toLocaleString());
                let pstTime = new Date(value.toLocaleString());
                pstTime.setHours(pstTime.getHours() - getDiffPstUtc());
                Advisory.lockdownTopst = moment(pstTime).format("dddd, MMMM Do YYYY h:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                Advisory.lockdownToutc = moment(utcTime).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                if (isNullOrUndefined(Advisory.lockdownFrompst) && isNullOrUndefined(Advisory.lockdownFromutc)) {
                    Advisory.lockdownFrompst = moment(Advisory.lockDownFrom).format("dddd, MMMM Do YYYY h:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                    Advisory.lockdownFromutc = moment(Advisory.lockDownFrom).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                }
                if (occuredonDate.toString("html") == "<p><br></p>") {
                    let Lockdownperiod = Advisory.lockdownFrompst + " - " + Advisory.lockdownTopst + " PT <br> " + " " + Advisory.lockdownFromutc + " - " + Advisory.lockdownToutc + " UTC";
                    SetoccuredonDate(RichTextEditor.createValueFromString(Lockdownperiod, "html"));
                }
                else {
                    SetoccuredonDate(RichTextEditor.createEmptyValue());
                    let Lockdownperiod = Advisory.lockdownFrompst + " - " + Advisory.lockdownTopst + " PT <br> " + " " + Advisory.lockdownFromutc + " - " + Advisory.lockdownToutc + " UTC";
                    SetoccuredonDate(RichTextEditor.createValueFromString(Lockdownperiod, "html"));
                }
                setsavedisableto(false);
                setsavedisable(false);
            }
            else {
                setsavedisableto(true);
            }
        }
    }

    const pazFromDateChange = (value, name) => {
        if (paztoPst) {
            if (!isNullOrUndefined(value)) {
                let pstTime = new Date(value.toLocaleString());
                let utcTime = new Date(value.toLocaleString());
                utcTime.setHours(utcTime.getHours() + getDiffPstUtc());
                PlannedAdvisory.lockdownFrompst = moment(pstTime).format("dddd, MMMM Do YYYY h:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                PlannedAdvisory.lockdownFromutc = moment(utcTime).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                setpazDate1(value);
                PlannedAdvisory.lockDownFrom = value;//value.toUTCString();
                setplannedsavedisablefrom(false);
                setpazDate2('');
                setplannedsavedisableto(true);
            }
            else {
                setplannedsavedisablefrom(true);
            }
        }
        else {
            if (!isNullOrUndefined(value)) {
                let utcTime = new Date(value.toLocaleString());
                let pstTime = new Date(value.toLocaleString());
                pstTime.setHours(pstTime.getHours() - getDiffPstUtc());
                PlannedAdvisory.lockdownFrompst = moment(pstTime).format("dddd, MMMM Do YYYY h:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                PlannedAdvisory.lockdownFromutc = moment(utcTime).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                setpazDate1(value);
                PlannedAdvisory.lockDownFrom = value;//value.toUTCString();
                setplannedsavedisablefrom(false);
                setpazDate2('');
                setplannedsavedisableto(true);
            }
            else {
                setplannedsavedisablefrom(true);
            }
        }
    }

    const pazToDateChange = (value, name) => {
        if (paztoPst) {
            if (!isNullOrUndefined(value)) {
                setpazDate2(value);
                PlannedAdvisory.lockDownTo = value;//value.toUTCString();
                let pstTime = new Date(value.toLocaleString());
                let utcTime = new Date(value.toLocaleString());
                utcTime.setHours(utcTime.getHours() + getDiffPstUtc());
                PlannedAdvisory.lockdownTopst = moment(pstTime).format("dddd, MMMM Do YYYY h:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                PlannedAdvisory.lockdownToutc = moment(utcTime).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                if (isNullOrUndefined(PlannedAdvisory.lockdownFrompst) && isNullOrUndefined(PlannedAdvisory.lockdownFromutc)) {
                    PlannedAdvisory.lockdownFrompst = moment(PlannedAdvisory.lockDownFrom).format("dddd, MMMM Do YYYY h:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                    PlannedAdvisory.lockdownFromutc = moment(PlannedAdvisory.lockDownFrom).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                }
                if (plannedoccuredon.toString("html") == "<p><br></p>") {
                    let Lockdownperiod = PlannedAdvisory.lockdownFrompst + " - " + PlannedAdvisory.lockdownTopst + " PT <br> " + " " + PlannedAdvisory.lockdownFromutc + " - " + PlannedAdvisory.lockdownToutc + " UTC";
                    SetplannedoccuredonDate(RichTextEditor.createValueFromString(Lockdownperiod, "html"));
                }
                else {
                    SetplannedoccuredonDate(RichTextEditor.createEmptyValue());
                    let Lockdownperiod = plannedoccuredonDate.toString("html").replace(plannedoccuredonDate.toString("html").split(";")[0], PlannedAdvisory.lockdownFrompst + " - " + PlannedAdvisory.lockdownTopst + " PT <br> " + " " + PlannedAdvisory.lockdownFromutc + " - " + PlannedAdvisory.lockdownToutc + " UTC");
                    SetplannedoccuredonDate(RichTextEditor.createValueFromString(Lockdownperiod, "html"));
                }
                setplannedsavedisableto(false);
                setsaveplanneddisable(false);
            }
            else {
                setplannedsavedisableto(true);
            }
        }
        else {
            if (!isNullOrUndefined(value)) {
                setpazDate2(value);
                PlannedAdvisory.lockDownTo = value;//value.toUTCString();
                let utcTime = new Date(value.toLocaleString());
                let pstTime = new Date(value.toLocaleString());
                pstTime.setHours(pstTime.getHours() - getDiffPstUtc());
                PlannedAdvisory.lockdownTopst = moment(pstTime).format("dddd, MMMM Do YYYY h:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                PlannedAdvisory.lockdownToutc = moment(utcTime).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                if (isNullOrUndefined(PlannedAdvisory.lockdownFrompst) && isNullOrUndefined(PlannedAdvisory.lockdownFromutc)) {
                    PlannedAdvisory.lockdownFrompst = moment(PlannedAdvisory.lockDownFrom).format("dddd, MMMM Do YYYY h:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                    PlannedAdvisory.lockdownFromutc = moment(PlannedAdvisory.lockDownFrom).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                }
                if (occuredonDate.toString("html") == "<p><br></p>") {
                    let Lockdownperiod = PlannedAdvisory.lockdownFrompst + " - " + PlannedAdvisory.lockdownTopst + " PT <br> " + " " + PlannedAdvisory.lockdownFromutc + " - " + PlannedAdvisory.lockdownToutc + " UTC";
                    SetplannedoccuredonDate(RichTextEditor.createValueFromString(Lockdownperiod, "html"));
                }
                else {
                    SetplannedoccuredonDate(RichTextEditor.createEmptyValue());
                    let Lockdownperiod = PlannedAdvisory.lockdownFrompst + " - " + PlannedAdvisory.lockdownTopst + " PT <br> " + " " + PlannedAdvisory.lockdownFromutc + " - " + PlannedAdvisory.lockdownToutc + " UTC";
                    SetplannedoccuredonDate(RichTextEditor.createValueFromString(Lockdownperiod, "html"));
                }
                setplannedsavedisableto(false);
                setsaveplanneddisable(false);
            }
            else {
                setplannedsavedisableto(true);
            }
        }
    }

    const pazpstChange = (value) => {
        if (value) {
            setpaztoPst(true);
            if (!isNullOrUndefined(PlannedAdvisory.lockDownTo)) {
                //converting Advisory from date from pst to utc
                let pstfrmTime = new Date(PlannedAdvisory.lockDownFrom.toLocaleString());
                let utcfrmTime = new Date(PlannedAdvisory.lockDownFrom.toLocaleString());
                utcfrmTime.setHours(utcfrmTime.getHours() + getDiffPstUtc());
                PlannedAdvisory.lockdownFrompst = moment(pstfrmTime).format("dddd, MMMM Do YYYY hh:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                PlannedAdvisory.lockdownFromutc = moment(utcfrmTime).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");

                //converting Advisory To date from Pst to utc
                let psttoTime = new Date(PlannedAdvisory.lockDownTo.toLocaleString());
                let utctoTime = new Date(PlannedAdvisory.lockDownTo.toLocaleString());
                utctoTime.setHours(utctoTime.getHours() + getDiffPstUtc());
                PlannedAdvisory.lockdownTopst = moment(psttoTime).format("dddd, MMMM Do YYYY hh:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                PlannedAdvisory.lockdownToutc = moment(utctoTime).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");

                //converted pst to UTC date assignment to accured on 
                if (isNullOrUndefined(PlannedAdvisory.lockdownFrompst) && isNullOrUndefined(Advisory.lockdownFromutc)) {
                    PlannedAdvisory.lockdownFrompst = moment(PlannedAdvisory.lockDownFrom).format("dddd, MMMM Do YYYY hh:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                    PlannedAdvisory.lockdownFromutc = moment(PlannedAdvisory.lockDownFrom).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                }
                if (plannedoccuredonDate.toString("html").replace(/<(.|\n)*?>/g, '').replace(/ /g, '').replace(/^(&nbsp;)+/g, '').replace(/^(<br>)+/g, '').replace(/^(<br\/>)+/g, '') == "") {
                    let Lockdownperiod = PlannedAdvisory.lockdownFrompst + " - " + PlannedAdvisory.lockdownTopst + " PT <br> " + " " + PlannedAdvisory.lockdownFromutc + " UTC - " + PlannedAdvisory.lockdownToutc + " UTC";
                    SetplannedoccuredonDate(RichTextEditor.createValueFromString(Lockdownperiod, "html"));
                }
                else {
                    SetplannedoccuredonDate(RichTextEditor.createEmptyValue());
                    let Lockdownperiod = PlannedAdvisory.lockdownFrompst + " - " + PlannedAdvisory.lockdownTopst + " PT <br> " + " " + PlannedAdvisory.lockdownFromutc + " - " + PlannedAdvisory.lockdownToutc + " UTC";
                    SetplannedoccuredonDate(RichTextEditor.createValueFromString(Lockdownperiod, "html"));
                }
            }
        }
        else {
            setpaztoPst(false);
            if (!isNullOrUndefined(PlannedAdvisory.lockDownTo)) {
                //Converting Advisory from date UTC-PT
                let pstfrmTime = new Date(PlannedAdvisory.lockDownFrom.toLocaleString());
                let utcfrmTime = new Date(PlannedAdvisory.lockDownFrom.toLocaleString());
                pstfrmTime.setHours(pstfrmTime.getHours() - getDiffPstUtc());
                PlannedAdvisory.lockdownFrompst = moment(pstfrmTime).format("dddd, MMMM Do YYYY hh:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                PlannedAdvisory.lockdownFromutc = moment(utcfrmTime).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");

                //converting Advisory To date UTC-PT
                let psttoTime = new Date(PlannedAdvisory.lockDownTo.toLocaleString());
                let utctoTime = new Date(PlannedAdvisory.lockDownTo.toLocaleString());
                psttoTime.setHours(psttoTime.getHours() - getDiffPstUtc());
                PlannedAdvisory.lockdownTopst = moment(psttoTime).format("dddd, MMMM Do YYYY hh:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                PlannedAdvisory.lockdownToutc = moment(utctoTime).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                if (isNullOrUndefined(PlannedAdvisory.lockdownFrompst) && isNullOrUndefined(PlannedAdvisory.lockdownFromutc)) {
                    PlannedAdvisory.lockdownFrompst = moment(PlannedAdvisory.lockDownFrom).format("dddd, MMMM Do YYYY hh:mm A").replace(/(\d+)(st|nd|rd|th)/, "$1");
                    PlannedAdvisory.lockdownFromutc = moment(PlannedAdvisory.lockDownFrom).format("dddd, MMMM Do YYYY HH:mm").replace(/(\d+)(st|nd|rd|th)/, "$1");
                }
                if (plannedoccuredonDate.toString("html").replace(/<(.|\n)*?>/g, '').replace(/ /g, '').replace(/^(&nbsp;)+/g, '').replace(/^(<br>)+/g, '').replace(/^(<br\/>)+/g, '') == "") {
                    let Lockdownperiod = PlannedAdvisory.lockdownFrompst + " - " + PlannedAdvisory.lockdownTopst + " PT <br> " + " " + PlannedAdvisory.lockdownFromutc + " UTC - " + PlannedAdvisory.lockdownToutc + " UTC";
                    SetplannedoccuredonDate(RichTextEditor.createValueFromString(Lockdownperiod, "html"));
                }
                else {
                    SetplannedoccuredonDate(RichTextEditor.createEmptyValue());
                    let Lockdownperiod = plannedoccuredonDate.toString("html").replace(plannedoccuredonDate.toString("html").split(";")[0], PlannedAdvisory.lockdownFrompst + " - " + PlannedAdvisory.lockdownTopst + " PT <br> " + " " + PlannedAdvisory.lockdownFromutc + " - " + PlannedAdvisory.lockdownToutc + " UTC");
                    SetplannedoccuredonDate(RichTextEditor.createValueFromString(Lockdownperiod, "html"));
                }
            }
        }
    }

    const SetplannedoccuredonValueDate = (e) => {
        SetplannedoccuredonDate(e);
        if (plannedoccuredonDate.toString("html") == '<p><br></p>' || plannedoccuredonDate.toString("html") == '<p>&nbsp;</p>') {
            setsaveplanneddisable(true);
        }
        else {
            setsaveplanneddisable(false);
        }
    }

    const SetplannedoccuredonValue = (e) => {
        setsaveplanneddisable(false);
        Setplannedoccuredon(e);
    }

    const changePlannedAzureLocation = (e) => {
        setPlannedAzureSelectedLocations(e.value);
        if (e.value.length > 0) {
            setplannedsavedisableloc(false);
        }
        else {
            setplannedsavedisableloc(true);
        }
    }

    const changePlannedEnv = (e) => {
        setSelectedPlannedEnvironment(e.value);
        if (e.value.length > 0) {
            setplannedsavedisableenv(false);
        }
        else {
            setplannedsavedisableenv(true);
        }
    }

    const savePlannedAdvisory = (e, rowData) => {
        if (plannedoccuredonDate.toString("html") == '<p><br></p>' || plannedoccuredonDate.toString("html") == '<p>&nbsp;</p>') {
            toastIdRef.current = toast.error(
                renderToastifyMsg("When (PT/UTC) should not be empty",
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
            return false;
        }

        setSubmitted(true);
        setsaveplanneddisable(true);
        let AddCritAdvisoryData = PlannedAdvisory;
        AddCritAdvisoryData.azureLocation = selectedPlannedAzureLocations.toString();
        AddCritAdvisoryData.environment = selectedPlannedEnvironment.toString();
        if (plannedoccuredon.toString("html") == '<p><br></p>' || plannedoccuredon.toString("html") == '<p>&nbsp;</p>') {
            AddCritAdvisoryData.occuredOn = plannedoccuredonDate.toString("html") + "~!@#~";
        }
        else {
            AddCritAdvisoryData.occuredOn = plannedoccuredonDate.toString("html") + "~!@#~" + plannedoccuredon.toString("html") + "~!@#~";
        }
        AddCritAdvisoryData.guidelines = guiderich.toString("html");
        AddCritAdvisoryData.timezone = paztoPst
        if (PlannedAdvisory.advisoryId != 0) {
            var plannedAdvisoryObj = {
                advisoryId: AddCritAdvisoryData.advisoryId,
                criticalAdvisoryName: AddCritAdvisoryData.criticalAdvisoryName,
                occuredOn: AddCritAdvisoryData.occuredOn,
                azureLocation: AddCritAdvisoryData.azureLocation,
                environment: AddCritAdvisoryData.environment,
                isInactive: AddCritAdvisoryData.isInactive,
                isExpired: AddCritAdvisoryData.isExpired,
                guidelines: AddCritAdvisoryData.guidelines,
                displayOrder: AddCritAdvisoryData.displayOrder,
                advisoryType: AddCritAdvisoryData.advisoryType,
                lockDownFrom: AddCritAdvisoryData.lockDownFrom.toLocaleString().replace(',', ''),
                lockDownTo: AddCritAdvisoryData.lockDownTo.toLocaleString().replace(',', ''),
                timezone: AddCritAdvisoryData.timezone
            }
            CriticalAdvisoryAPI.UpdateAdRecord(plannedAdvisoryObj)
                .then((result: number) => {
                    if (result != -1) {
                        setAdvisoryDialog(false);
                        setAzureAdvisoryDialog(false);
                        setPlannedAzureEventsDialog(false);
                        toastIdRef.current = toast.success(
                            renderToastifyMsg("Critical Advisory Updated",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                        requestCriticalAdvisoryData();
                        setloadingfalse();
                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while updating Advisory",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });

        }
        else {
            var plannedAdvisoryObj = {
                advisoryId: AddCritAdvisoryData.advisoryId,
                criticalAdvisoryName: AddCritAdvisoryData.criticalAdvisoryName,
                occuredOn: AddCritAdvisoryData.occuredOn,
                azureLocation: AddCritAdvisoryData.azureLocation,
                environment: AddCritAdvisoryData.environment,
                isInactive: AddCritAdvisoryData.isInactive,
                isExpired: AddCritAdvisoryData.isExpired,
                guidelines: AddCritAdvisoryData.guidelines,
                displayOrder: AddCritAdvisoryData.displayOrder,
                advisoryType: AddCritAdvisoryData.advisoryType,
                lockDownFrom: AddCritAdvisoryData.lockDownFrom.toLocaleString().replace(',', ''),
                lockDownTo: AddCritAdvisoryData.lockDownTo.toLocaleString().replace(',', ''),
                timezone: AddCritAdvisoryData.timezone
            }
            CriticalAdvisoryAPI.AddNewCriticalAdvisory(plannedAdvisoryObj)
                .then((result: number) => {
                    if (result == -1) {
                        setAdvisoryDialog(false);
                        setAzureAdvisoryDialog(false);
                        setPlannedAzureEventsDialog(false);
                        toastIdRef.current = toast.success(
                            renderToastifyMsg("New Critical Advisory Added" + CriticalAdvisoryName,
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                        requestCriticalAdvisoryData();
                        setloadingfalse();
                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while Adding new Advisory",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
    }

    const deletePlannedAdvisory = () => {
        setrowData(rowData);
        setplanneddeletebtndisable(true);
        CriticalAdvisoryAPI.DeleteCriticalAdvisoryRecord(rowData.advisoryId)
            .then((result: number) => {
                setDeletePlannedAdvisoryDialog(false);
                if (result != -1) {
                    requestCriticalAdvisoryData();
                    toastIdRef.current = toast.success(
                        renderToastifyMsg("Deleted successfully",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    setplanneddeletebtndisable(false);
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while deleting",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
                setplanneddeletebtndisable(false);
            });
    }

    const hideDeletePlannedAdvisoryDialog = () => {
        setDeletePlannedAdvisoryDialog(false);
    }

    const editPlannedAzureAdvisory = (rowData) => {
        let frDate = new Date(rowData.lockDownFrom);
        let toDate = new Date(rowData.lockDownTo);

        let selectedAdvisory = "";
        let selectedDate = "";
        let selectedLockdowText = "";
        if ((rowData.occuredOn.toString("html") != '<p><br></p>') && rowData.occuredOn.toString("html").includes("~!@#")) {
            selectedAdvisory = rowData.occuredOn.toString("html").split("~!@#~")
            if (!isNullOrUndefined(selectedAdvisory) && selectedAdvisory.length > 0) {
                selectedDate = selectedAdvisory[0];
                selectedLockdowText = selectedAdvisory[1];
            }
        }
        setpazDate1(frDate);
        setpazDate2(toDate);
        setrowData(rowData);
        setPlannedAdvisory(rowData);
        setPlannedAzureSelectedLocations(rowData.azureLocation.split(","));
        setSelectedPlannedEnvironment(rowData.environment);
        SetplannedoccuredonDate(RichTextEditor.createValueFromString(selectedDate, "html"));
        Setplannedoccuredon(RichTextEditor.createValueFromString(selectedLockdowText, "html"));
        Setguiderich(RichTextEditor.createValueFromString(rowData.guidelines, "html"));
        setPlannedAzureEventsDialog(true);
        setpaztoPst(rowData.timezone);
        if (isNullOrUndefined(rowData.criticalAdvisoryName) || (rowData.occuredOn.toString("html") != '<p><br></p>') || (!isNullOrUndefined(PlannedAdvisory.lockDownFrom)) || (!isNullOrUndefined(PlannedAdvisory.lockDownTo)) || (rowData.azureLocation.length > 0) || (rowData.environment > 0)) {
            setsaveplanneddisable(false);
            setplannedsavedisablefrom(false);
            setplannedsavedisableto(false);
            setplannedsavedisableloc(false);
            setplannedsavedisableenv(false);
        }
        else {
            setsaveplanneddisable(true);
            setplannedsavedisablefrom(true);
            setplannedsavedisableto(true);
            setplannedsavedisableloc(true);
            setplannedsavedisableenv(true);
        }
    }

    const confirmPlannedDeleteAdvisory = (rowData) => {
        setrowData(rowData);
        setPlannedAdvisory(PlannedAdvisory);
        setDeletePlannedAdvisoryDialog(true);
    }

    const editAdvisory = (rowData) => {
        let frDate = new Date(rowData.lockDownFrom);
        let toDate = new Date(rowData.lockDownTo);
        let selectedAdvisory = "";
        let selectedDate = "";
        let selectedLockdowText = "";
        if ((rowData.occuredOn.toString("html") != '<p><br></p>') && rowData.occuredOn.toString("html").includes("~!@#~")) {
            selectedAdvisory = rowData.occuredOn.toString("html").split("~!@#~")
            if (!isNullOrUndefined(selectedAdvisory) && selectedAdvisory.length > 0) {
                selectedDate = selectedAdvisory[0];
                selectedLockdowText = selectedAdvisory[1];
            }
        }
        setDate1(frDate);
        setDate2(toDate);
        setrowData(rowData);
        setAdvisory(rowData);
        setSelectedLocations(rowData.azureLocation.split(","));
        setSelectedEnvironment(rowData.environment);
        SetoccuredonDate(RichTextEditor.createValueFromString(selectedDate, "html"));
        Setoccuredon(RichTextEditor.createValueFromString(selectedLockdowText, "html"));
        Setguiderich(RichTextEditor.createValueFromString(rowData.guidelines, "html"));
        setAdvisoryDialog(true);
        settoPst(rowData.timezone);
        if (isNullOrUndefined(rowData.criticalAdvisoryName) || (rowData.occuredOn.toString("html") != '<p><br></p>') || (!isNullOrUndefined(Advisory.lockDownFrom)) || (!isNullOrUndefined(Advisory.lockDownTo)) || (rowData.azureLocation.length > 0) || (rowData.environment > 0)) {
            setsavedisable(false);
            setsavedisablefrom(false);
            setsavedisableto(false);
            setsavedisableloc(false);
            setsavedisableenv(false);
        }
        else {
            setsavedisable(true);
            setsavedisablefrom(true);
            setsavedisableto(true);
            setsavedisableloc(true);
            setsavedisableenv(true);
        }
    }

    const editAzureAdvisory = (rowData) => {
        let frDate = new Date(rowData.lockDownFrom);
        let toDate = new Date(rowData.lockDownTo);

        let selectedAdvisory = "";
        let selectedDate = "";
        let selectedLockdowText = "";
        if ((rowData.occuredOn.toString("html") != '<p><br></p>') && rowData.occuredOn.toString("html").includes("~!@#")) {
            selectedAdvisory = rowData.occuredOn.toString("html").split("~!@#~")
            if (!isNullOrUndefined(selectedAdvisory) && selectedAdvisory.length > 0) {
                selectedDate = selectedAdvisory[0];
                selectedLockdowText = selectedAdvisory[1];
            }
        }
        setazDate1(frDate);
        setazDate2(toDate);
        setrowData(rowData);
        setAdvisory(rowData);
        setSelectedLocations(rowData.azureLocation.split(","));
        setSelectedEnvironment(rowData.environment);
        SetoccuredonDate(RichTextEditor.createValueFromString(selectedDate, "html"));
        Setoccuredon(RichTextEditor.createValueFromString(selectedLockdowText, "html"));
        Setguiderich(RichTextEditor.createValueFromString(rowData.guidelines, "html"));
        setAzureAdvisoryDialog(true);
        setaztoPst(rowData.timezone);
        if (isNullOrUndefined(rowData.criticalAdvisoryName) || (rowData.occuredOn.toString("html") != '<p><br></p>') || (!isNullOrUndefined(Advisory.lockDownFrom)) || (!isNullOrUndefined(Advisory.lockDownTo)) || (rowData.azureLocation.length > 0) || (rowData.environment > 0)) {
            setsavedisable(false);
            setsavedisablefrom(false);
            setsavedisableto(false);
            setsavedisableloc(false);
            setsavedisableenv(false);
        }
        else {
            setsavedisable(true);
            setsavedisablefrom(true);
            setsavedisableto(true);
            setsavedisableloc(true);
            setsavedisableenv(true);
        }
    }

    const confirmDeleteAdvisory = (rowData) => {
        setrowData(rowData);
        setAdvisory(Advisory);
        setDeleteAdvisoryDialog(true);
    }

    const RemovePlannedEvent = (e, row) => {
        let updatedBy = username;
        CriticalAdvisoryAPI.MoveEventToPlannedLockdown(row.advisoryId, updatedBy)
            .then((result: number) => {
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg('Moved item succesfully To Planned Lockdown ' + row.criticalAdvisoryName,
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    requestCriticalAdvisoryData();
                    setloadingfalse();
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("No Change in Data or Error While Updating the Data, Please contact Admin!",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });
    }

    const ApproveToCurrentEvent = (e, row) => {
        let updatedBy = username;
        CriticalAdvisoryAPI.MoveEventToCurrentLockdown(row.advisoryId, updatedBy)
            .then((result: number) => {
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg('Moved item succesfully To Current Lockdown ' + row.criticalAdvisoryName,
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    requestCriticalAdvisoryData();
                    setloadingfalse();
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("No Change in Data or Error While Updating the Data, Please contact Admin!",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });
    }

    const getDiffPstUtc = () => {
        const now = new Date();
        const time1 = now.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
        const time2 = now.toLocaleString('en-US', { timeZone: 'UTC' });
        const diffMilliseconds = +new Date(time2) - +new Date(time1);
        const diffHours = diffMilliseconds / (1000 * 60 * 60);
        return Number(diffHours);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span ><span >
                    <FontAwesomeIcon title='Click to edit the Row' className="bluecoloricons Iconsstyles" icon={faPencilAlt} onClick={() => editAdvisory(rowData)} />
                </span> </span> &nbsp;
                <span>
                    <FontAwesomeIcon title='Click to Delete the Row' className="redcolorIcons Iconsstyles" icon={faTrash} onClick={() => confirmDeleteAdvisory(rowData)} /></span> &nbsp;
            </React.Fragment>
        );
    }

    const movedtoPlannedTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span style={{ cursor: 'pointer' }} onClick={(e) => RemovePlannedEvent(e, rowData)} ><FontAwesomeIcon title='Move to Planned Lockdown' style={{ color: 'green'}} icon={faCheckCircle} className="checklistclosefonticon" /></span> &nbsp;
            </React.Fragment>
        );
    }

    const movedtoCurrentTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button className="btn moveplnbtn" onClick={(e) => ApproveToCurrentEvent(e, rowData)} ><strong style={{ color: 'white' }}>Approve</strong></Button> &nbsp;
            </React.Fragment>
        );
    }

    const planedAzureTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span ><span >
                    <FontAwesomeIcon title='Click to edit the Row' className="bluecoloricons Iconsstyles" icon={faPencilAlt} onClick={() => editPlannedAzureAdvisory(rowData)} />
                </span> </span> &nbsp;
                <span>
                    <FontAwesomeIcon title='Click to Delete the Row' className="redcolorIcons Iconsstyles" icon={faTrash} onClick={() => confirmPlannedDeleteAdvisory(rowData)} /></span> &nbsp;
            </React.Fragment>
        );
    }

    const azurActionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span ><span >
                    <FontAwesomeIcon title='Click to edit the Row' className="bluecoloricons Iconsstyles" icon={faPencilAlt} onClick={() => editAzureAdvisory(rowData)} />
                </span> </span> &nbsp;
                <span>
                    <FontAwesomeIcon title='Click to Delete the Row' className="redcolorIcons Iconsstyles" icon={faTrash} onClick={() => confirmDeleteAdvisory(rowData)} /></span> &nbsp;
            </React.Fragment>
        );
    }

    const CriticalADNameBodyTemplate = (rowData) => {
        return (
            <div>
                <span><b>{rowData.criticalAdvisoryName}&nbsp;
                    {IsLivesitePMOrUser ?
                        <FontAwesomeIconMemo onClick={(e) => GuidelinesEdit(e, rowData)} title="Edit the Guideline" icon={'edit'} style={{ color: 'black' }} className='icon-format' />
                        : <React.Fragment></React.Fragment>
                    }
                </b></span>
            </div>

        )
    }

    const occuredOnBodyTemplate = (rowData) => {
        return (
            <div>
                <React.Fragment>
                    <div className="RTELIdots">
                        <div dangerouslySetInnerHTML={{ __html: rowData.occuredOn.replaceAll("~!@#~", "") }} style={{ wordBreak: 'break-word' }} ></div>
                    </div>
                </React.Fragment>
            </div>
        )
    }

    const guidelineBodyTemplate = (rowData) => {
        return (
            <div>
                <React.Fragment>
                    <div className={GuidelineInfoValidate(rowData.advisoryId) ? "disabled" : "guidelinestext"} onClick={(e) => GuidelinesView(e, rowData)}>
                        Guidelines
                    </div>
                </React.Fragment>
            </div>
        )
    }

    const isInactiveBodyTemplate = (rowData) => {
        return (
            <div>
                <React.Fragment>
                    {IsLivesitePMOrUser ?
                        <div style={{ textAlign: 'center' }}>
                            <input type="checkbox" onChange={e => UpdateCriticalAdvisoryData(e, rowData)} checked={rowData.isInactive} />
                        </div>
                        : <React.Fragment></React.Fragment>
                    }
                </React.Fragment>
            </div>
        );
    }



    return (
        <div>
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        <CriticalAdvisoryTable
                            CriticalAdvisoryData={CriticalAdvisoryData}
                            AzureHighPriorityLinksData={AzureHighPriorityLinksData}
                            CriticalAdvisoryFlyZoneData={CriticalAdvisoryFlyZoneData}
                            noFlyZoneInfo={noFlyZoneInfo}
                            noFlyZoneInfoId={noFlyZoneInfoId}
                            noFlyZoneInfoData={noFlyZoneInfoData}
                            UpdateCriticalAdvisoryData={(e, row) => UpdateCriticalAdvisoryData(e, row)}
                            saveFlyZoneInfo={saveFlyZoneInfo}
                            saveGuidelinesInfo={saveGuidelinesInfo}
                            GuidelineInfoValidate={(Id) => GuidelineInfoValidate(Id)}
                            IsLivesitePMOrUser={IsLivesitePMOrUser}
                            requestGuidelineInfo={(criticalAdvisoryName, advisoryId) => requestGuidelineInfo(criticalAdvisoryName, advisoryId)}
                            NoFlyZoneInfoEdit={NoFlyZoneInfoEdit}
                            GuidelinesEdit={(e, row) => GuidelinesEdit(e, row)}
                            GuidelinesView={(e, row) => GuidelinesView(e, row)}
                            openNew={openNew}
                            CriticalADNameBodyTemplate={CriticalADNameBodyTemplate}
                            occuredOnBodyTemplate={occuredOnBodyTemplate}
                            isInactiveBodyTemplate={isInactiveBodyTemplate}
                            guidelineBodyTemplate={guidelineBodyTemplate}
                            actionBodyTemplate={actionBodyTemplate}
                            azurActionBodyTemplate={azurActionBodyTemplate}
                            openAzureNew={openAzureNew}
                            openPlannedAzureItems={openPlannedAzureItems}
                            AzureEnvironmentDropdown={AzureEnvironmentDropdown}
                            AzureLocationDropdown={AzureLocationDropdown}
                            planedAzureTemplate={planedAzureTemplate}
                            movedtoPlannedTemplate={movedtoPlannedTemplate}
                            RemovePlannedEvent={(e, row) => RemovePlannedEvent(e, row)}
                            movedtoCurrentTemplate={movedtoCurrentTemplate}
                            ApproveToCurrentEvent={(e, row) => ApproveToCurrentEvent(e, row)}
                        />
                    </div>
                </section>
            </LoadingOverlay>

            <Modal show={showflyZonepopup} onHide={closeflyZonepopup} size='lg' backdrop='static' >
                <Modal.Header closeButton>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='12'>
                                <span>No Fly Zone Information <small>(upto 250 Characters)</small></span>
                            </Col>

                        </Row>
                    </div>

                </Modal.Header>
                <Modal.Body>
                    <Row style={{ paddingTop: '15px' }}>
                        <Col lg='12'>
                            {
                                <RichTextEditor
                                    value={noFlyZoneInfoPopup}
                                    onChange={UpdatenoFlyZoneInfoPopup}
                                    toolbarConfig={toolbarConfig}
                                    editorClassName="trix-editorCritical"
                                    className="customRichEdit"
                                    placeholder="No Fly Zone Info accepts maximum of 250 Characters excluding Html Tags."
                                    type="string"
                                    multiline
                                    variant="filled"
                                />
                            }
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='3'></Col>
                            <Col lg='6'>

                                {
                                    (isLoading) ?
                                        <div style={{ 'textAlign': "center" }}>
                                            <img src={bxloader} style={{ width: '80px', height: '40px' }} />
                                        </div>
                                        :
                                        <div>
                                        </div>

                                }
                            </Col>
                            <Col lg='3'>
                                {(isLoading) ?
                                    <div></div> :
                                    <div>
                                        <Button className='pirlearningssaveModelbutton' onClick={saveFlyZoneInfo} disabled={FlyZoneInfoValidate()}>Save</Button>&emsp;
                                        <Button className='pirlearningssaveModelbutton' onClick={NoFlyZoneInfoBannerCancel}>Close</Button>
                                    </div>
                                }
                            </Col>
                        </Row>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal show={GuidelineEditInfoPopup.popup} onHide={closeGuidelinesEditpopup} size='xl' backdrop='static' >
                <Modal.Header closeButton>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='12'>
                                <span>Guidelines - {CriticalAdvisoryName}</span>
                            </Col>

                        </Row>
                    </div>

                </Modal.Header>
                <Modal.Body>
                    <Row style={{ paddingTop: '10px' }}>
                        <Col lg='12'>
                            {
                                <RichTextEditor
                                    value={GuidelineEditInfoPopup.value}
                                    onChange={UpdateGuidelineInfoPopup}
                                    toolbarConfig={toolbarConfig}
                                    editorClassName="trix-editorCritical"
                                    className="customRichEdit"
                                    type="string"
                                    multiline
                                    variant="filled"
                                />
                            }
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='3'></Col>
                            <Col lg='6'>
                                {
                                    (isLoading) ?
                                        <div style={{ 'textAlign': "center" }}>
                                            <img src={bxloader} style={{ width: '80px', height: '40px' }} />
                                        </div>
                                        :
                                        <div>
                                        </div>
                                }
                            </Col>
                            <Col lg='3'>
                                {(isLoading) ?
                                    <div></div> :
                                    <div style={{ float: 'right' }}>
                                        <Button className='pirlearningssaveModelbutton' onClick={saveGuidelinesInfo} disabled={Disableguidelinesavebutton}>Save</Button>&emsp;
                                        <Button className='pirlearningssaveModelbutton' onClick={GuidelinesCACancel}>Close</Button>
                                    </div>
                                }
                            </Col>
                        </Row>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal show={GuidelineviewInfoPopup.popup} onHide={closeGuidelinesViewpopup} size='xl' backdrop='static' >
                <Modal.Header closeButton>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='12'>
                                <span>Guidelines - {CriticalAdvisoryName}</span>
                            </Col>
                        </Row>
                    </div>

                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg='12'>
                            <RichTextEditor
                                value={GuidelineviewInfoPopup.value}
                                className="EditorGuideline customRichEdit"
                                editorClassName="trix-editorCritical"
                                type="string"
                                readonly
                                multiline
                                variant="filled"
                            />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal show={AdvisoryDialog} onHide={hideDialog} size='xl' backdrop='static' >
                <Modal.Header className="criticaladaddpopup" closeButton>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='12'>
                                <span>Identity Critical Change Advisories</span>
                            </Col>
                        </Row>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row divpad">
                        <div className="col-lg-3"><span className="mandatorycolor">* </span><label htmlFor="criticalAdvisoryName">Critical Changes Only Advisories</label></div>
                        <div className="col-lg-1">:</div>
                        <div className="col-lg-8"> <InputText id="criticalAdvisoryName" value={Advisory.criticalAdvisoryName} onChange={(e) => onInputChange(e, 'criticalAdvisoryName')} required autoFocus className="canameInput" />
                            {submitted && !Advisory.criticalAdvisoryName && <small className="p-error">Name is required.</small>}</div>
                    </div>
                    <div className="row divpad">
                        <div className="col-lg-3"><span className="mandatorycolor">* </span><label>Lockdown Duration</label></div>
                        <div className="col-lg-1">:</div>
                        <div className="col-lg-3">{toPst ? <label>From (PT): </label> : <label>From (UTC) : </label>}  &nbsp;<Calendar showTime hourFormat="24" value={date1} onChange={(e) => fromDateChange(e.value, 'from')} hideOnDateTimeSelect={true}></Calendar></div>
                        <div className="col-lg-3">{toPst ? <label>To (PT): </label> : <label>To (UTC) : </label>} &nbsp;<Calendar showTime hourFormat="24" value={date2} minDate={date1} onChange={(e) => toDateChange(e.value, 'to')} disabled={isNullOrUndefined(date1)} hideOnDateTimeSelect={true}></Calendar></div>
                        <div className="col-lg-2"><span style={{ fontWeight: toPst ? 'inherit' : 'bold' }}>UTC</span> <Switch onChange={e => pstChange(e)} checked={toPst} uncheckedIcon={false} checkedIcon={true} offColor='#008800' boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                            activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                            height={20}
                            width={35} className="swtch" /> <span style={{ fontWeight: toPst ? 'bold' : 'inherit' }}> PT </span>
                        </div>
                    </div>
                    <div className="row divpad">
                        <div className="col-lg-3"><span className="mandatorycolor">*</span> <label htmlFor="occuredOnDate">When (PT/UTC)</label></div>
                        <div className="col-lg-1">:</div>
                        <div className="col-lg-8">
                            {
                                <RichTextEditor
                                    value={occuredonDate}
                                    onChange={SetoccuredonValueDate}
                                    toolbarConfig={toolbarConfig}
                                    editorClassName="trix-addeditorCritical-date"
                                    className="customRichEdit"
                                    type="string"
                                    multiline
                                    variant="filled"
                                />
                            }
                        </div>
                    </div>
                    <div className="row divpad">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-8">
                            {
                                <RichTextEditor
                                    value={occuredon}
                                    onChange={SetoccuredonValue}
                                    toolbarConfig={toolbarConfig}
                                    editorClassName="trix-addeditorCritical"
                                    className="customRichEdit"
                                    type="string"
                                    multiline
                                    variant="filled"
                                />
                            }
                        </div>
                    </div>
                    <div className="row divpad">
                        <div className="col-lg-3"><span className="mandatorycolor">* </span><label htmlFor="azureLocation">Azure Location</label></div>
                        <div className="col-lg-1">:</div>
                        <div className="col-lg-8">
                            <div className="col-lg-3 nopaddingleft">
                                <MultiSelect id="adlocation" filter={true} optionLabel="label" value={selectedLocations} options={AzureLocationDropdown} onChange={(e) => changeLocation(e)} maxSelectedLabels={4} optionValue="value" />
                            </div>
                        </div>
                    </div>
                    <div className="row divpad">
                        <div className="col-lg-3"><span className="mandatorycolor">* </span><label htmlFor="environment">Environment</label></div>
                        <div className="col-lg-1">:</div>
                        <div className="col-lg-8">
                            <div className="col-lg-3 nopaddingleft">
                                <Dropdown className="envlist" id="envlist" value={selectedEnvironment} options={AzureEnvironmentDropdown} onChange={(e) => changeEnv(e)} optionLabel="label" optionValue="value" required={true} placeholder="Select Environment" />
                            </div>
                        </div>
                    </div>
                    <div className="row divpad">
                        <div className="col-lg-3"><label htmlFor="guidelines">Guidelines</label></div>
                        <div className="col-lg-1">:</div>
                        <div className="col-lg-8">
                            {
                                <RichTextEditor
                                    value={guiderich}
                                    onChange={SetguiderichValue}
                                    toolbarConfig={toolbarConfig}
                                    editorClassName="trix-guideitorCritical"
                                    className="customRichEdit"
                                    type="string"
                                    multiline
                                    variant="filled"
                                />
                            }
                        </div>
                    </div>
                    <div className="row divpad">
                        <div className="col-lg-3"><span className="mandatorycolor">* </span><label htmlFor="cb1">Display Order</label></div>
                        <div className="col-lg-1">:</div>
                        <div className="col-lg-2"><input type="number" min={0} id="displayOrder" value={Advisory.displayOrder} onChange={(e) => onInputChange(e, 'displayOrder')} /></div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col lg='6' style={{ textAlign: 'right' }}>
                            <Button disabled={(isNullOrUndefined(Advisory.criticalAdvisoryName) && (Advisory.criticalAdvisoryName === "")) || savedisable || isNullOrUndefined(Advisory.displayOrder) || savedisablefrom || savedisableto || savedisableloc || savedisableenv} className='DailydataModelbuttons' onClick={e => saveAdvisory(e, rowData)}>Save</Button>
                        </Col>
                        <Col lg='6' style={{ textAlign: 'left' }}>
                            <Button className='DailydataModelbuttons checklistcloseicon' onClick={hideDialog} id="cklclose"><FontAwesomeIcon title='Help' icon={faTimes} className="checklistclosefonticon" /> Cancel</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>

            <Modal show={AzureAdvisoryDialog} onHide={hideAzureDialog} size='xl' backdrop='static' >
                <Modal.Header className="criticaladaddpopup" closeButton>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='12'>
                                <span>Azure High Priority Events Program</span>
                            </Col>
                        </Row>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row divpad">
                        <div className="col-lg-3"><span className="mandatorycolor">*</span> <label htmlFor="name">Critical Changes Only Advisories</label></div>
                        <div className="col-lg-1">:</div>
                        <div className="col-lg-8"><InputText id="criticalAdvisoryName" value={Advisory.criticalAdvisoryName} onChange={(e) => onInputChange(e, 'criticalAdvisoryName')} required autoFocus className="canameInput" />
                            {submitted && !Advisory.criticalAdvisoryName && <small className="p-error">Name is required.</small>}</div>
                    </div>
                    <div className="row divpad">
                        <div className="col-lg-3"><span className="mandatorycolor">* </span><label>Lockdown Duration</label></div>
                        <div className="col-lg-1">:</div>
                        <div className="col-lg-3">{aztoPst ? <label>From (PT): </label> : <label>From (UTC) : </label>}&nbsp;<Calendar showTime hourFormat="24" value={azDate1} onChange={(e) => azFromDateChange(e.value, 'from')} hideOnDateTimeSelect={true}></Calendar></div>
                        <div className="col-lg-3">{aztoPst ? <label>To (PT): </label> : <label>To (UTC) : </label>}&nbsp;<Calendar showTime hourFormat="24" value={azDate2} minDate={azDate1} onChange={(e) => azToDateChange(e.value, 'to')} disabled={isNullOrUndefined(azDate1)} hideOnDateTimeSelect={true}></Calendar></div>
                        <div className="col-lg-2"> <span style={{ fontWeight: aztoPst ? 'inherit' : 'bold' }}>UTC</span> <Switch onChange={e => azpstChange(e)} checked={aztoPst} uncheckedIcon={false} checkedIcon={true} offColor='#008800' boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                            activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                            height={20}
                            width={35} className="swtch" /> <span style={{ fontWeight: aztoPst ? 'bold' : 'inherit' }}> PT </span>
                        </div>
                    </div>
                    <div className="row divpad">
                        <div className="col-lg-3"><span className="mandatorycolor">*</span> <label htmlFor="occuredOnDate">When (PT/UTC)</label></div>
                        <div className="col-lg-1">:</div>
                        <div className="col-lg-8">
                            {
                                <RichTextEditor
                                    value={occuredonDate}
                                    onChange={SetoccuredonValueDate}
                                    toolbarConfig={toolbarConfig}
                                    editorClassName="trix-addeditorCritical-date"
                                    className="customRichEdit"
                                    type="string"
                                    multiline
                                    variant="filled"
                                />
                            }
                        </div>
                    </div>
                    <div className="row divpad">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-8">
                            {
                                <RichTextEditor
                                    value={occuredon}
                                    onChange={SetoccuredonValue}
                                    toolbarConfig={toolbarConfig}
                                    editorClassName="trix-addeditorCritical"
                                    className="customRichEdit"
                                    type="string"
                                    multiline
                                    variant="filled"
                                />
                            }
                        </div>
                    </div>
                    <div className="row divpad">
                        <div className="col-lg-3"><span className="mandatorycolor">* </span><label htmlFor="azureLocation">Azure Location</label></div>
                        <div className="col-lg-1">:</div>
                        <div className="col-lg-8">
                            <div className="col-lg-3 nopaddingleft">
                                <MultiSelect id="azadlocation" filter={true} optionLabel="value" value={selectedLocations} options={AzureLocationDropdown} onChange={(e) => changeLocation(e)} maxSelectedLabels={4} />
                            </div>
                        </div>
                    </div>
                    <div className="row divpad">
                        <div className="col-lg-3"><span className="mandatorycolor">* </span><label htmlFor="environment">Environment</label></div>
                        <div className="col-lg-1">:</div>
                        <div className="col-lg-8">
                            <div className="col-lg-3 nopaddingleft">
                                <Dropdown className="envlist" id="azenvlist" value={selectedEnvironment} options={AzureEnvironmentDropdown} onChange={(e) => changeEnv(e)} optionLabel="value" optionValue="value" placeholder="Select Environment" />
                            </div>
                        </div>
                    </div>
                    <div className="row divpad">
                        <div className="col-lg-3"><span className="mandatorycolor">* </span><label htmlFor="cb1">Display Order</label></div>
                        <div className="col-lg-1">:</div>
                        <div className="col-lg-8"><input type="number" min={0} id="displayOrder" value={Advisory.displayOrder} onChange={(e) => onInputChange(e, 'displayOrder')} /></div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col lg='6' style={{ textAlign: 'right' }}>
                            <Button disabled={(isNullOrUndefined(Advisory.criticalAdvisoryName) && (Advisory.criticalAdvisoryName === "")) || savedisable || isNullOrUndefined(Advisory.displayOrder) || savedisablefrom || savedisableto || savedisableloc || savedisableenv} className='DailydataModelbuttons' onClick={e => saveAdvisory(e, rowData)}>Save</Button>
                        </Col>
                        <Col lg='6' style={{ textAlign: 'left' }}>
                            <Button className='DailydataModelbuttons checklistcloseicon' onClick={hideDialog} id="cklclose"><FontAwesomeIcon title='Help' icon={faTimes} className="checklistclosefonticon" /> Cancel</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>

            <Modal show={PlannedAzureEventsDialog} onHide={hidePlannedAzureDialog} size='xl' backdrop='static' >
                <Modal.Header className="criticaladaddpopup" closeButton>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='12'>
                                <span>Planned ID Lockdowns Events</span>
                            </Col>
                        </Row>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row divpad">
                        <div className="col-lg-3"><span className="mandatorycolor">*</span> <label htmlFor="name">Critical Changes Only Advisories</label></div>
                        <div className="col-lg-1">:</div>
                        <div className="col-lg-8"><InputText id="criticalAdvisoryName" value={PlannedAdvisory.criticalAdvisoryName} onChange={(e) => plannedInputChange(e, 'criticalAdvisoryName')} required autoFocus className="canameInput" />
                            {submitted && !PlannedAdvisory.criticalAdvisoryName && <small className="p-error">Name is required.</small>}</div>
                    </div>
                    <div className="row divpad">
                        <div className="col-lg-3"><span className="mandatorycolor">* </span><label>Lockdown Duration</label></div>
                        <div className="col-lg-1">:</div>
                        <div className="col-lg-3">{paztoPst ? <label>From (PT): </label> : <label>From (UTC) : </label>}&nbsp;<Calendar showTime hourFormat="24" value={pazDate1} onChange={(e) => pazFromDateChange(e.value, 'from')} hideOnDateTimeSelect={true}></Calendar></div>
                        <div className="col-lg-3">{paztoPst ? <label>To (PT): </label> : <label>To (UTC) : </label>}&nbsp;<Calendar showTime hourFormat="24" value={pazDate2} minDate={pazDate1} onChange={(e) => pazToDateChange(e.value, 'to')} disabled={isNullOrUndefined(pazDate1)} hideOnDateTimeSelect={true}></Calendar></div>
                        <div className="col-lg-2"> <span style={{ fontWeight: aztoPst ? 'inherit' : 'bold' }}>UTC</span> <Switch onChange={e => pazpstChange(e)} checked={paztoPst} uncheckedIcon={false} checkedIcon={true} offColor='#008800' boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                            activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                            height={20}
                            width={35} className="swtch" /> <span style={{ fontWeight: paztoPst ? 'bold' : 'inherit' }}> PT </span>
                        </div>
                    </div>
                    <div className="row divpad">
                        <div className="col-lg-3"><span className="mandatorycolor">*</span> <label htmlFor="plannedoccuredonDate">When (PT/UTC)</label></div>
                        <div className="col-lg-1">:</div>
                        <div className="col-lg-8">
                            {
                                <RichTextEditor
                                    value={plannedoccuredonDate}
                                    onChange={SetplannedoccuredonValueDate}
                                    toolbarConfig={toolbarConfig}
                                    editorClassName="trix-addeditorCritical-date"
                                    className="customRichEdit"
                                    type="string"
                                    multiline
                                    variant="filled"
                                />
                            }
                        </div>
                    </div>
                    <div className="row divpad">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-8">
                            {
                                <RichTextEditor
                                    value={plannedoccuredon}
                                    onChange={SetplannedoccuredonValue}
                                    toolbarConfig={toolbarConfig}
                                    editorClassName="trix-addeditorCritical"
                                    className="customRichEdit"
                                    type="string"
                                    multiline
                                    variant="filled"
                                />
                            }
                        </div>
                    </div>
                    <div className="row divpad">
                        <div className="col-lg-3"><span className="mandatorycolor">* </span><label htmlFor="azureLocation">Azure Location</label></div>
                        <div className="col-lg-1">:</div>
                        <div className="col-lg-8">
                            <div className="col-lg-3 nopaddingleft">
                                <MultiSelect id="azadlocation" filter={true} optionLabel="value" value={selectedPlannedAzureLocations} options={AzureLocationDropdown} onChange={(e) => changePlannedAzureLocation(e)} maxSelectedLabels={4} />
                            </div>
                        </div>
                    </div>
                    <div className="row divpad">
                        <div className="col-lg-3"><span className="mandatorycolor">* </span><label htmlFor="environment">Environment</label></div>
                        <div className="col-lg-1">:</div>
                        <div className="col-lg-8">
                            <div className="col-lg-3 nopaddingleft">
                                <Dropdown className="envlist" id="azenvlist" value={selectedPlannedEnvironment} options={AzureEnvironmentDropdown} onChange={(e) => changePlannedEnv(e)} optionLabel="value" optionValue="value" placeholder="Select Environment" />
                            </div>
                        </div>
                    </div>
                    <div className="row divpad">
                        <div className="col-lg-3"><span className="mandatorycolor">* </span><label htmlFor="cb1">Display Order</label></div>
                        <div className="col-lg-1">:</div>
                        <div className="col-lg-8"><input type="number" min={0} id="displayOrder" value={PlannedAdvisory.displayOrder} onChange={(e) => plannedInputChange(e, 'displayOrder')} /></div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col lg='6' style={{ textAlign: 'right' }}>
                            <Button disabled={(isNullOrUndefined(PlannedAdvisory.criticalAdvisoryName) && (PlannedAdvisory.criticalAdvisoryName === "")) || saveplanneddisable || isNullOrUndefined(PlannedAdvisory.displayOrder) || saveplanneddisablefrom || saveplanneddisableto || saveplanneddisableloc || saveplanneddisableenv} className='DailydataModelbuttons' onClick={e => savePlannedAdvisory(e, rowData)}>Save</Button>
                        </Col>
                        <Col lg='6' style={{ textAlign: 'left' }}>
                            <Button className='DailydataModelbuttons checklistcloseicon' onClick={hideDialog} id="cklclose"><FontAwesomeIcon title='Help' icon={faTimes} className="checklistclosefonticon" /> Cancel</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>

            <Modal show={deletePlannedAdvisoryDialog} onHide={hideDeletePlannedAdvisoryDialog} size='lg' backdrop='static'>
                <Modal.Header className="criticaladaddpopup" closeButton>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='12'>
                                <span>Confirm</span>
                            </Col>
                        </Row>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', color: 'red' }} />
                        <span>Are you sure you want to delete?</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <React.Fragment>
                        <Button className="p-button-text" onClick={() => hideDeletePlannedAdvisoryDialog()} >No</Button>
                        <Button className="p-button-text" disabled={deleteplannedbtndisable} onClick={() => deletePlannedAdvisory()}>Yes</Button>
                    </React.Fragment>
                </Modal.Footer>
            </Modal>

            <Modal show={deleteAdvisoryDialog} onHide={hideDeleteAdvisoryDialog} size='lg' backdrop='static'>
                <Modal.Header className="criticaladaddpopup" closeButton>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='12'>
                                <span>Confirm</span>
                            </Col>
                        </Row>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', color: 'red' }} />
                        <span>Are you sure you want to delete?</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <React.Fragment>
                        <Button className="p-button-text" onClick={() => hideDeleteAdvisoryDialog()} >No</Button>
                        <Button className="p-button-text" disabled={deletebtndisable} onClick={() => deleteAdvisory()}>Yes</Button>
                    </React.Fragment>
                </Modal.Footer>
            </Modal>
        </div>
    );
};


const mapStateToProps = (state: IApplicationState) => ({
    ...state.CriticalAdvisory,
    isAuthenticated: state.authUser.isAuthenticated,
    username: state.authUser.login

})


export default connect(mapStateToProps, actionCreators)(CriticalAdvisory);
