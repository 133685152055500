import { BaseService } from './base.service';
import {
    IWeeklyReport, IPropDraft, IYear, PropKPICalculationDetails, PropKpiCalculationLogic,
    PropConfigData, PropTicketDetails, PropBgm, PropExternalLinks, PropMetricGroups, PropTicketReport,
    PropKpiComments, PropTarget, PropPullRequestDetails, PropStackOverFlowDetails, PropOneDashHistoricalData, PropDeploymentBuildDetails
} from '../store/service-health';
import { IPropCalender,IServiceGroup } from '../store/home-tiles';
/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class WeeklyService extends BaseService {
    private static _weeklyService: WeeklyService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): WeeklyService {
        return (this._weeklyService || (this._weeklyService = new this('WeeklyData')));
    }


    public async GetWeeklyData(serviceId: number, weekStartDate: string, metricSource: string, date: string): Promise<IWeeklyReport[]> {
        metricSource = '';
        const { data } = await this.$http.get<IWeeklyReport[]>(`GetWeeklyReportData?serviceId=${serviceId}&weekStartDate=${weekStartDate}`);
        return data;
    }
    public async GetCalendarData(): Promise<IYear[]> {
        const { data } = await this.$http.get<IYear[]>("GetCalendarData");
        return data;
    }
    public async GetDraftsData(serviceId: number, weekStartDate: string, defaultDate: string): Promise<IPropDraft> {
        const { data } = await this.$http.get<IPropDraft>(`GetDraftsData?serviceId=${serviceId}&weekStartDate=${weekStartDate}&defaultDate=${defaultDate}`);
        return data;
    }

    public async GetConfigValues(): Promise<IPropCalender[]> {
        const { data } = await this.$http.get<IPropCalender[]>("GetConfigurationValues");
        return data;
    }
    public async AddItemToKpiHistory(xmlData): Promise<string> {
      
        const { data } = await this.$http.post<string>(`AddItemToKpiHistory`, xmlData);
        return data;
    }
    public async LoadKPICalculationData(MetricGrpId: number, serviceId: number, weekStartDate:string): Promise<PropKPICalculationDetails[]> {
        const { data } = await this.$http.get<PropKPICalculationDetails[]>(`GetKPICalculationDetails?MetricGrpId=${MetricGrpId}&serviceId=${serviceId}&weekStartDate=${weekStartDate}`);
        return data;
    }
    public async GetKPICalculationLogic(serviceId: number, metricgroupname: string): Promise<PropKpiCalculationLogic[]> {
        const { data } = await this.$http.get<PropKpiCalculationLogic[]>(`GetKPICalculationLogic?serviceId=${serviceId}&metricgroupname=${metricgroupname}`);
        return data;
    }
    public async GetDateForRefreshedData(serviceid: number, viewId: string): Promise<IPropCalender[]> {
        const { data } = await this.$http.get<IPropCalender[]>(`GetDateForRefreshedData?serviceId=${serviceid}&viewId=${viewId}`);
        return data;
    }
    public async GetConfigData(): Promise<PropConfigData> {
        const { data } = await this.$http.get<PropConfigData>("GetConfigData");
        return data;
    }
    public async GetTFSDetails(metricid: number, weekStartDate: string, serviceId: number, envid: number): Promise<PropTicketDetails[]> {
        const { data } = await this.$http.get<PropTicketDetails[]>(`GetTFSDetails?metricid=${metricid}&weekStartDate=${weekStartDate}&serviceId=${serviceId}&envid=${envid}`);
        return data;
    }
    public async GetPRDetails(metricid: number, weekStartDate: string, serviceId: number, envid: number): Promise<PropTicketDetails[]> {
        const { data } = await this.$http.get<PropTicketDetails[]>(`GetPRDetails?metricid=${metricid}&weekStartDate=${weekStartDate}&serviceId=${serviceId}&envid=${envid}`);
        return data;
    }
    public async GetTFSTicketDetails(metricid: number, weekStartDate: string, serviceId: number, envid: number): Promise<PropTicketDetails[]> {
        const { data } = await this.$http.get<PropTicketDetails[]>(`GetLSITicketDetails?metricid=${metricid}&weekStartDate=${weekStartDate}&serviceId=${serviceId}&envid=${envid}`);
        return data;
    }

    public async RemoveRepairItems(ticketId, weekStratDate:string): Promise<string> {
        const { data } = await this.$http.post<string>(`RemoveRepairItems?weekStratDate=${weekStratDate}`, ticketId);
        return data;
    }
    public async GetLSITicketDetails(metricid: number, weekStartDate: string, serviceId: number, envid: number): Promise<PropTicketDetails[]> {
        const { data } = await this.$http.get<PropTicketDetails[]>(`GetTFSTicketDetails?metricid=${metricid}&weekStartDate=${weekStartDate}&serviceId=${serviceId}&envid=${envid}`);
        return data;
    }
    public async Get_BGM_Data(serviceId: number, weekStartDate: string): Promise<PropBgm[]> {
        const { data } = await this.$http.get<PropBgm[]>(`Get_BGM_Data?serviceId=${serviceId}&weekStartDate=${weekStartDate}`);
        return data;
    }
    public async Get_NewBGM_Data(serviceId: number, weekStartDate: string): Promise<PropBgm[]> {
        const { data } = await this.$http.get<PropBgm[]>(`Get_New_BGM_Data?serviceId=${serviceId}&weekStartDate=${weekStartDate}`);
        return data;
    }
    public async GetTicketReportGraph(serviceId: number, weekDate: string): Promise<PropTicketReport[]> {
        const { data } = await this.$http.get<PropTicketReport[]>(`GetTicketReportGraph?weekDate=${weekDate}&serviceId=${serviceId}`);
        return data;
    }
    public async GetExternal_Links(): Promise<PropExternalLinks[]> {
        const { data } = await this.$http.get<PropExternalLinks[]>("GetExternal_Links");
        return data;
    }
    public async GetServices(ServiceTreeId: string): Promise<IServiceGroup[]> {
        const { data } = await this.$http.get<IServiceGroup[]>(`GetServices?ServiceTreeId=${ServiceTreeId}`);
        return data;
    }
    public async GetServiceRing(): Promise<IServiceGroup[]> {
        const { data } = await this.$http.get<IServiceGroup[]>(`GetServiceRing`);
        return data;
    }
    public async GetTargetTypes(): Promise<PropTarget[]> {
        const { data } = await this.$http.get<PropTarget[]>(`GetTargetTypes`);
        return data;
    }
    public async GetserviceGroupWeeklySelfServe(serviceId: number): Promise<object[]> {
        const { data } = await this.$http.get<object[]>(`GetserviceGroupWeeklySelfServe?serviceId=${serviceId}`);
        return data;
    }
    public async GetMetricGroups(): Promise<PropMetricGroups[]> {
        const { data } = await this.$http.get<PropMetricGroups[]>("GetMetricGroups");
        return data;
    }
    public async GetActiveMetricsWeeklySelfServe(serviceId: number): Promise<object[]> {
        const { data } = await this.$http.get<object[]>(`GetActiveMetricsWeeklySelfServe?serviceId=${serviceId}`);
        return data;
    }
    public async GetMetricEnvirnoments(): Promise<object[]> {
        const { data } = await this.$http.get<object[]>(`GetMetricEnvirnoments`);
        return data;
    }
    public async GetDataSources(): Promise<object[]> {
        const { data } = await this.$http.get<object[]>(`GetDataSources`);
        return data;
    }
    public async GetAuthenticationType(): Promise<object[]> {
        const { data } = await this.$http.get<object[]>(`GetAuthenticationType`);
        return data;
    }
    public async GetMetricDataSourceDetails(serviceId: number): Promise<object[]> {
        const { data } = await this.$http.get<object[]>(`GetMetricDataSourceDetails?serviceId=${serviceId}`);
        return data;
    }
    public async SaveMetricDataSourceDetails(obj): Promise<number> {
        const { data } = await this.$http.post<number>("SaveMetricDataSourceDetails", obj);
        return data;
    }
    public async SaveICMDataSourceDetails(obj): Promise<number> {
        const { data } = await this.$http.post<number>("SaveICMDataSourceDetails", obj);
        return data;
    }
    public async SaveTargetReferenceDetails(obj, insertorUpdateflag): Promise<number> {
        const { data } = await this.$http.post<number>("SaveTargetReferenceDetails", obj, insertorUpdateflag);
        return data;
    }
    public async AddNewMetricGroup(metricGroupName: string): Promise<number> {
        const { data } = await this.$http.post<number>(`AddNewMetricGroup?metricGroupName=${metricGroupName}`);
        return data;
    }
    public async AddNewMetric(obj): Promise<number> {
        const { data } = await this.$http.post<number>("AddNewMetric", obj);
        return data;
    }
    public async GetComments(serviceId: number, weekStartDate: string): Promise<PropKpiComments[]> {
        const { data } = await this.$http.get<PropKpiComments[]>(`GetComments?serviceId=${serviceId}&weekStartDate=${weekStartDate}`);
        return data;
    }
    public async AddComments(comments, weekStratDate: string): Promise<string> {
        const { data } = await this.$http.post<string>(`AddComments?weekStratDate=${weekStratDate}`, comments);
        return data;
    }
    public async DeleteComments(serviceId: number, weekStartDate: string, kpiId: number): Promise<string> {
        const { data } = await this.$http.get<string>(`DeleteComments?serviceId=${serviceId}&kpiId=${kpiId}&weekStartDate=${weekStartDate}`);
        return data;
    }
    public async AddKPIHistory(bgm): Promise<string> {
        const { data } = await this.$http.post<string>(`AddKPIHistory`, bgm);
        return data;
    }
    public async WeeklyPerformanceData(serviceId: number, weekStartDate: string): Promise<IWeeklyReport[]> {
        const { data } = await this.$http.get<IWeeklyReport[]>(`WeeklyPerformanceData?serviceId=${serviceId}&weekStartDate=${weekStartDate}`);
        return data;
    }
    public async updateMetricDataSourceInactive(serviceId: string, metricId: string, updatedBy: string): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateMetricDataSourceInActive?serviceId=${serviceId}&metricId=${metricId}&updatedBy=${updatedBy}`);
        return data;
    }
    public async updateIcmMetricDataSourceInactive(serviceId: string, metricId: string, updatedBy: string): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateIcmMetricDataSourceInactive?serviceId=${serviceId}&metricId=${metricId}&updatedBy=${updatedBy}`);
        return data;
    }
    public async AddLsiChangesToKpiHistory(selectedMetrics): Promise<string> {
        const { data } = await this.$http.post<string>(`AddLsiChangesToKpiHistory`, selectedMetrics);
        return data;
    }
    public async AddPerformanceHistory(performancedata): Promise<string> {
        const { data } = await this.$http.post<string>(`AddPerformanceHistory`, performancedata);
        return data;
    }
    public async GetSchemaDeploymentDetails(metricid: number, weekStartDate: string, serviceId: number, envid: number): Promise<PropPullRequestDetails[]> {
        const { data } = await this.$http.get<PropPullRequestDetails[]>(`GetPullRequestDetails?metricid=${metricid}&weekStartDate=${weekStartDate}&serviceId=${serviceId}&envid=${envid}`);
        return data;
    }

    public async GetDeploymentBuildDetails(metricid: number, weekStartDate: string, serviceId: number, envid: number): Promise<PropDeploymentBuildDetails[]> {
        const { data } = await this.$http.get<PropDeploymentBuildDetails[]>(`GetDeploymentBuildDetails?metricid=${metricid}&weekStartDate=${weekStartDate}&serviceId=${serviceId}&envid=${envid}`);
        return data;
    }

    public async GetStackOverFlowDetails(metricid: number, weekStartDate: string, serviceId: number, envid: number): Promise<PropStackOverFlowDetails[]> {
        const { data } = await this.$http.get<PropStackOverFlowDetails[]>(`GetStackOverFlowDetails?metricid=${metricid}&weekStartDate=${weekStartDate}&serviceId=${serviceId}&envid=${envid}`);
        return data;
    } 
    public async GetS360Tickets(weekStartDate: string, endDate: string): Promise<PropOneDashHistoricalData[]> {
        const { data } = await this.$http.get<PropOneDashHistoricalData[]>(`GetS360Tickets?weekStartDate=${weekStartDate}&weekEndDate=${endDate}`);
        return data;
    }
}

export const WeeklyAPI = WeeklyService.Instance;
