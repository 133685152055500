
export type IResourceDetail = {
    readonly link: string;
    readonly description: string;
}

export type IPropPirLearningsData = {
    readonly id: number;
    readonly serviceName: string;
    readonly servicesImpacted: string;
    readonly summary: string;
    readonly ttd: string;
    readonly ttm: string;
    readonly ttn: string;
    readonly pir: string;
    readonly isAzure?: boolean;
    readonly kpiDate: string;
    readonly userName: string;
    readonly tte: string;
    readonly occuredRing: string;
    readonly pirRereview: boolean;
    readonly isAddedToAgenda: boolean;
    readonly isValidPir: boolean;
    readonly resources: IResourceDetail[];
    readonly rootCauseCategory: string;
    readonly sharedLearning: string;
    readonly incidentId: string;
    IsMore: boolean;
};

export type IDropdownOption = {
    value: number;
    label: string;
};

export type IPIRLearningsState = {
    readonly pirLearningsData: IPropPirLearningsData[];
    readonly allPIRDataFiltered: IPropPirLearningsData[];
    readonly isLoading: boolean;
    readonly flag: boolean;
    readonly displayText: string;
    readonly PIRLearningsDate: Date;
    readonly selectedItemForLabel: IDropdownOption;
    readonly selectedItemForRootLabel: IDropdownOption;
    readonly OwningService: IDropdownOption[];
    readonly RouteCauseCategory: IDropdownOption[];
    readonly pirtextLength: string;
    readonly searchText: string;
};

const _namespace = 'PIRLearnings';
export interface IActionType {
    readonly RESET_STATE: string;
    readonly SET_PIRLEARNINGS_DATE: string;
    readonly PIRLEARNINGS_DATE_CHANGE: string;
    readonly GET_PIRLEARNINGS_REQUEST: string;
    readonly GET_PIRLEARNINGS_SUCCESS: string;
    readonly GET_PIRLEARNINGS_FAILURE: string;
    readonly GET_PIRLEARNINGS_PIRTEXT: string;
    readonly PIRLEARNINGS_SERVICE_FILTER: string;
    readonly PIRLEARNINGS_ROOTCAUSE_FILTER: string;
    readonly TOGGLE_PIR: string;
    readonly CHANGE_SEARCHTEXT_PIR: string;
    readonly PIRLEARNINGS_SHOWMORE_TEXT: string;
    readonly SET_LOADING_TRUE: string;
    readonly SET_LOADING_FALSE: string;
    readonly ADDED_VISITED_USER: string;
}

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/pirlearningsreset`,
    SET_PIRLEARNINGS_DATE: `${_namespace}/setpirlearningsdate`,
    PIRLEARNINGS_DATE_CHANGE: `${_namespace}/pirlearningsdatechange`,
    GET_PIRLEARNINGS_REQUEST: `${_namespace}/getpirlearningsrequest`,
    GET_PIRLEARNINGS_SUCCESS: `${_namespace}/getpirlearningssuccess`,
    GET_PIRLEARNINGS_FAILURE: `${_namespace}/getpirlearningsfailure`,
    GET_PIRLEARNINGS_PIRTEXT: `${_namespace}/getpirlearningspirtext`,
    PIRLEARNINGS_SERVICE_FILTER: `${_namespace}/pirlearningsservicefilter`,
    PIRLEARNINGS_ROOTCAUSE_FILTER: `${_namespace}/pirlearningsrootcausefilter`,
    TOGGLE_PIR: `${_namespace}/togglepirlearnings`,
    CHANGE_SEARCHTEXT_PIR: `${_namespace}/changesearchtextpirlearnings`,
    PIRLEARNINGS_SHOWMORE_TEXT: `${_namespace}/pirlearningsshowmoretext`,
    SET_LOADING_TRUE: `${_namespace}/loadingtrue`,
    SET_LOADING_FALSE: `${_namespace}/loadingfalse`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`
});

