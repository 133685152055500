import React from 'react';
import { IServiceGroup } from '../../../store/home-tiles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignal, faFire, faAsterisk, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import Switch from 'react-switch'
import { Card, CardTitle, Button, Row, Col } from 'reactstrap';
import { Accordion } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { CallbackFunction } from '../../../types';

type HomeTilesTableProps = {
     serviceGroup?: IServiceGroup[];
     handledChange: any;
     ToggleChange: any;
    readonly handleOnSuccess: CallbackFunction;
    IsProd: boolean;
};

const HomeTilesTable = React.memo<HomeTilesTableProps>(({ serviceGroup, handledChange, ToggleChange, IsProd }) => {
    const count: number = 0
    let tmpDirector: any = '';
    let tmpTeamGroup: any = '';
    let tmpGroup: any = '';
    let column: any = '';

    const setDirectorName = (Name: any) => {
        tmpDirector = Name;
    };
    const setTeamGroupName = (Name: any) => {
        tmpTeamGroup = Name;
    };
    const setGroupName = (Name: any) => {
        tmpGroup = Name;
    };
    const setColumn = (columnName: any) => {
        column = columnName;
    };

    const isArrayWithLength = (test: any): boolean => (Array.isArray(test) && !!test.length);
    const ReplaceBRInNames = (Name: any) => {
        return Name.replace(new RegExp('<br/>', 'g'), '-');

    }
    const cellColorFormat = (colorName: any) => {      
        return { background: colorName, borderRadius: '4px', width: '100%', padding: '2px', color: '#212529' };      
    }

    const ServiceIcons = {
        paddingRight: '4px'
    }
    //---- function for converting Groupname to html code -----//
    const createMarkup = (name: string) => {
        return { __html: name };
    }
    const movescrollbar = () => {
        window.scrollTo(0, 0);
       
    }
    return (

        <div>

            {isArrayWithLength(serviceGroup) && serviceGroup.map((dh: IServiceGroup, index) => (
                <React.Fragment key={index}>
                    {
                         (tmpDirector !== dh.directorName)
                    ? <div>
                        {setDirectorName(dh.directorName)}
                                <Accordion defaultActiveKey={count.toString()} style={{ width: '100%' }}>
                                    <Card body style={{ borderRadius: '8px', width: '100%', padding: '0', background: 'yellowgreen', maxHeight:'40px' }}>
                                <CardTitle className='HeaderText' >
                                    <Row>
                                        <Col lg='1'>
                                        </Col>
                                                <Col lg='10 my-auto'>
                                                    <span className="homepagetogglestyle"> {ReplaceBRInNames(dh.directorName)}</span>
                                            <span> &nbsp;&nbsp;
                                                        <Switch aria-label="teamGroupSwitch" onChange={e => handledChange(e, dh.directorName, dh.teamGroupName, dh.groupName, dh.serviceName, 0)} checked={dh.IsDirectorChecked} uncheckedIcon={false} boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                                                    activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                                                    height={20}
                                                    width={35}
                                                    checkedIcon={false} />
                                            </span>
                                        </Col>
                                        <Col lg='1'>
                                                    <Accordion.Toggle aria-label="teamGroupToggle" as={Button} eventKey={count.toString()} onClick={e => ToggleChange(e, dh.directorName)} style={{background:'none'}}>
                                                {(dh.IsToggle) ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />
                                                } 
                                            </Accordion.Toggle>
                                        </Col>
                                    </Row>


                                </CardTitle>

                            </Card>
                            <Accordion.Collapse eventKey={count.toString()}>
                                <Row>

                                    {isArrayWithLength(serviceGroup) && serviceGroup.map((dhC: IServiceGroup, key1) => (
                                        <React.Fragment key={key1}>
                                            {
                                                (dhC.directorName === dh.directorName)
                                                    ?
                                                    <React.Fragment>
                                                        {
                                                            (column !== dhC.colValue)
                                                                ?
                                                                <Col lg='4' >
                                                                    {setColumn(dhC.colValue)}
                                                                    {isArrayWithLength(serviceGroup) && serviceGroup.map((dhT: IServiceGroup, key2) => (
                                                                        <React.Fragment key={key2}>
                                                                            {
                                                                                (dhT.directorName === dh.directorName && dhT.colValue === dhC.colValue)
                                                                                    ?
                                                                                    <div>
                                                                                        {
                                                                                            (tmpTeamGroup !== dhT.teamGroupName)
                                                                                                ? <div style={{ paddingBottom: '1px', paddingTop: '4px' }}>
                                                                                                    
                                                                                                        {setTeamGroupName(dhT.teamGroupName)}                                                                                                      
                                                                                                        {isArrayWithLength(serviceGroup) && serviceGroup.map((dhTG: IServiceGroup, key3) => (
                                                                                                            <React.Fragment key={key3}>
                                                                                                                {
                                                                                                                    (dhT.teamGroupName === dhTG.teamGroupName && dhT.directorName === dhTG.directorName)
                                                                                                                        ?
                                                                                                                        <div>
                                                                                                                            {
                                                                                                                                (tmpGroup !== dhTG.groupName)
                                                                                                                                    ?
                                                                                                                                    <div style={{ paddingBottom: '8px', paddingTop:'3px' }}>
                                                       
                                                                                                                                        {setGroupName(dhTG.groupName)}
                                                                                                                                        <div>
                                                                                                                                            <Card body style={cellColorFormat(dhTG.backgroundColor)}>
                                                                                                                                                <CardTitle className='Subheader'>
                                                                                                                                                    <Row>
                                                                                                                                                        <Col lg='10' style={{ textAlign: 'center' }}><span className='GroupTextcss' dangerouslySetInnerHTML={createMarkup(dhTG.groupName)}></span></Col>
                                                                                                                                                        <Col lg='2 my-auto'>
                                                                                                                                                            <div>
                                                                                                                                                            <Switch aria-label="teamGroupSwitch" onChange={e => handledChange(e, dhTG.directorName, dhTG.teamGroupName, dhTG.groupName, dhTG.serviceName, 1)} checked={dhTG.IsTeamGroupChecked} uncheckedIcon={false} boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                                                                                                                                                                activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                                                                                                                                                                height={20}
                                                                                                                                                                width={35}
                                                                                                                                                                    checkedIcon={false} />
                                                                                                                                                                </div>
                                                                                                                                                        </Col>
                                                                                                                                                    </Row>
                                                                                                                                                </CardTitle>
                                                                                                                                            </Card>
                                                                                                                                        </div>
                                                                                                                                        <div style={{ paddingTop: '4px' }}>
                                                                                                                                            <Card body style={cellColorFormat(dhTG.backgroundColor)}>
                                                                                                                                                {isArrayWithLength(serviceGroup) && serviceGroup.map((dhTGS: IServiceGroup, key4) => (
                                                                                                                                                    <React.Fragment key={key4}>
                                                                                                                                                        {
                                                                                                                                                            (dhTGS.groupName === dhTG.groupName)
                                                                                                                                                                ?
                                                                                                                                                                <div className='ServiceNames'>
                                                                                                                                                                    <Row>
                                                                                                                                                                        <Col lg='7' style={{ color: '#212529' }}><span className='ServiceNameText'> {dhTGS.serviceName}</span></Col>
                                                                                                                                                                        <Col lg='1'>
                                                                                                                                                                            {(dhTGS.isWeeklyReport === 'True') ?
                                                                                                                                                                             
                                                                                                                                                                                <Link onClick={movescrollbar} className='btn btn-primary btn-sm Reportbutton' rel='tooltip' title='Click to View the Service Health Data' to={'/ServiceHealth?serviceid=' + dhTGS.serviceId + '&name=Weekly'}>
                                                                                                                                                                                    <FontAwesomeIcon icon={faSignal} className="Orange" />
                                                                                                                                                                                </Link>
                                                                                                                                                                               
                                                                                                                                                                                : <React.Fragment></React.Fragment>}
                                                                                                                                                                        </Col>
                                                                                                                                                                        <Col lg='1'>
                                                                                                                                                                            {(dhTGS.isHeatMap === true) ?
                                                                                                                                                                                <Link onClick={movescrollbar} className='btn btn-primary btn-sm Reportbutton' rel='tooltip' title='Click to View the Heatmap Data' to={'/ServiceHealth?serviceid=' + dhTGS.serviceId}>  <FontAwesomeIcon icon={faFire} className="RedColor"/></Link>
                                                                                                                                                                              
                                                                                                                                                                                : <React.Fragment></React.Fragment>}
                                                                                                                                                                        </Col>
                                                                                                                                                                        <Col lg='1'>
                                                                                                                                                                            {(dhTGS.reachabilityAvailable === '1') ?
                                                                                                                                                                                <Link onClick={movescrollbar} className='btn btn-primary btn-sm Reportbutton' rel='tooltip' title='Navigate to Reachability page' to={'/Reachability?serviceid=' + dhTGS.serviceId}>  <FontAwesomeIcon icon={faAsterisk} className="RedColor" /></Link>
                                                                                                                                                                                : <React.Fragment></React.Fragment>}
                                                                                                                                                                        </Col>
                                                                                                                                                                        <Col lg='2 my-auto'>
                                                                                                                                                                            <div className="switchpadding">
                                                                                                                                                                                <Switch aria-label="teamGroupSwitch" onChange={e => handledChange(e, dhTGS.directorName, dhTGS.teamGroupName, dhTGS.groupName, dhTGS.serviceName, 2)} disabled={dhTGS.isWeeklyReport == 'False' && dhTGS.isHeatMap == false} checked={dhTGS.IServiceGroupChecked} uncheckedIcon={false} boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                                                                                                                                                                                    activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                                                                                                                                                                                    height={20}
                                                                                                                                                                                    width={35}
                                                                                                                                                                                    checkedIcon={false} />
                                                                                                                                                                                </div>
                                                                                                                                                                          

                                                                                                                                                                        </Col>



                                                                                                                                                                    </Row>



                                                                                                                                                                </div>
                                                                                                                                                                : <React.Fragment></React.Fragment>
                                                                                                                                                        }
                                                                                                                                                    </React.Fragment>

                                                                                                                                                ))}
                                                                                                                                            </Card>                                                                                                                             
                                                                                                                                        </div>
                                                                                                                                
                                                                                                                                    </div>
                                                                                                                                    :
                                                                                                                                    <React.Fragment></React.Fragment>
                                                                                                                            }

                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        <React.Fragment></React.Fragment>
                                                                                                                }
                                                                                                            </React.Fragment>
                                                                                                        ))}

                                                                                                  
                                                                                                </div>
                                                                                                :
                                                                                                <React.Fragment></React.Fragment>
                                                                                        }
                                                                                    </div>
                                                                                    :
                                                                                    <React.Fragment></React.Fragment>
                                                                            }
                                                                        </React.Fragment>
                                                                    ))}
                                                                </Col>
                                                                :
                                                                <React.Fragment></React.Fragment>
                                                        }
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment></React.Fragment>
                                            }
                                        </React.Fragment>
                                    ))}



                                </Row>
                            </Accordion.Collapse>
                        </Accordion>
                        <br/>
                    </div>
                    : <React.Fragment></React.Fragment>
                    }
                </React.Fragment>
               



            ))}
        </div>
    );
});

HomeTilesTable.displayName = 'HomeTiles';

export default HomeTilesTable;