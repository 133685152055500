import React, { useEffect, useRef } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import { actionCreators, reducer } from '../../store/checklistTemplate';
import { ChecklistTemplateTable } from '../Checklist/child-components';
import LoadingOverlay from 'react-loading-overlay';
import { isArrayWithLength, renderToastifyMsg,isNullOrUndefined } from '../../utils';
import { toast } from 'react-toastify';
import { ChecklistAPI } from '../../api';
import moment from 'moment';
import { NewFunctionalityBanner } from '../../components';

type ChecklistTemplateProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly username: string }
    & { readonly history: History }
    & { readonly isAdmin: boolean }
    & typeof useParams;

const ChecklistTemplate: React.FC<ChecklistTemplateProps> = ({
    isLoading,
    username,
    addUserVisit,
    isAuthenticated,
    GetOnCallDashboardTemplateData,
    checklisttemplatedata,
    OpentemplatePopup,
    showtemplatePopup,
    hidetemplatePopup,
    template,
    templatedatachange,
    AddQuestionstoTemplate,
    ChecklistTemplateEdit,
    MoveChecklistTemplateToArchive,
    questioncontroltypeoptions,
    sourcetypes,
    AuthenticationTypes,
    question,
    handlequestionChange,
    disableaddbuttonforquestion,
    ShowQuestionList,
    QuestionarrieList,
    AddNewquestiontolist,
    showquestionlist,
    ChecklistquestionEdit,
    questionlistvalueschange,
    istemplatesavebuttondisabled,
    setloadingtrue,
    setloadingfalse,
    templateTypes,
    UpdateQuestionaireDisplayOrder,
    isAdmin,
    LoginuserId,
    EditLockforChecklistTemplate,
    templateNameList,
    copiedTemplateQues,
    copyTempQuestiondrpChange,
    copyTempQuestions,
    isCopyTempQuesCheckVal,
    isCopyTemplateQuestion,
    copyTempQuesSelect,
    dynamicValidationTypes,
    templateRoles
}) => {
    const toastIdRef = useRef(null);
    useEffect(() => {
        if (isAuthenticated)
            addUserVisit(0, 'ChecklistTemplate.tsx_v2');
        GetOnCallDashboardTemplateData();
    }, [isAuthenticated]);
    const ValidateURL = (url) => {
        var valid = false;
        var regex = /^(?:(?:https):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
        var m;
        m = regex.exec(url);
        if (m !== null) {
            valid = true;
        }
        return valid;
    }
    
   const escapeChar =  (item)=> {
        if (item == "" || item == null) {
            return "";
        }
        else {
            return item.toString().replace("&", "&amp;").replace("<", "&lt;").replace(">", "&gt;").replace("%", "&#37;").trim().replace(/"/g, "&quot;");
        }
    };
   
    const SaveQuestionnarie = () => {
        let existdisplayorder = false;
        let isdisplayorderzero = false;
        let validurl = true;
        // Logic for Display order save and max Display Order if Questions already exist
        let newQuestionDisplayOrder = 1;
        if (isArrayWithLength(QuestionarrieList)) {
            for (var i = 0; i < QuestionarrieList.length; i++) {
                if (QuestionarrieList[i].id !== -1 && QuestionarrieList[i].id !== undefined && QuestionarrieList[i].id !== '' && QuestionarrieList[i].id !== null) {
                    newQuestionDisplayOrder = QuestionarrieList[i].DisplayOrder >= newQuestionDisplayOrder ? QuestionarrieList[i].DisplayOrder + 1 : newQuestionDisplayOrder;
                }
            }
        }
        if (question.ControlType === "CheckBox" || question.ControlType === "Radiobutton" || question.ControlType === "Dropdown") {
            question.controlValue = question.controlValue.split(",").map((item) => item.trim()).join();
        }
        if (question.sourceType === 'URL') {
            if (!ValidateURL(question.dataurl)) {
                validurl = false;
            }
        }
        if (!validurl) {
            toastIdRef.current = toast.error(
                renderToastifyMsg("URL should start with 'https://'",
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        
        // Commented below code block as the display order condition is handled above
        //else if (isArrayWithLength(QuestionarrieList)) {
        //    for (var i = 0; i < QuestionarrieList.length; i++) {
        //        if (parseInt(question.displayOrder) <= 0) {
        //            isdisplayorderzero = true;
        //            i = QuestionarrieList.length;
        //        }
        //        else if (parseInt(question.displayOrder) === parseInt(QuestionarrieList[i].DisplayOrder) && question.Questionindex != i) {
        //            existdisplayorder = true;
        //            i = QuestionarrieList.length;
        //        }

        //    }

        //}
        //else {
        //    if (parseInt(question.displayOrder) <= 0) {
        //        isdisplayorderzero = true;             
        //    }
        //}
        //if (isdisplayorderzero === true) {
        //    toastIdRef.current = toast.error(
        //        renderToastifyMsg("Display Order should start with '1'",
        //            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
        //    );
        //}
        //else if (existdisplayorder === true) {
        //    toastIdRef.current = toast.error(
        //        renderToastifyMsg("Display Order already exist",
        //            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
        //    );
        //}
        // Commented above code block such that as part of new CR display order is no more entered by User, it has to be handled in code.
        else {
            let Isvaluelistempty = false;
            if (question.ControlType === "CheckBox" || question.ControlType === "Radiobutton" || question.ControlType === "Dropdown") {
                if (!isNullOrUndefined(question.controlValue)) {
                    var CV_values = question.controlValue.split(',');
                    for (var cv = 0; cv < CV_values.length; cv++) {
                        if (CV_values[cv].trim() == '') {
                            Isvaluelistempty = true;
                            cv = CV_values.length;
                        }
                    }

                }
            }            
            if (Isvaluelistempty === true) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Please enter valid control Values with comma seperated",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            }
            else {
               let obj={
                   "QuestionID": question.QuestionID,
                   "DisplayOrder": (question.QuestionID === 0 && question.displayOrder == '')? newQuestionDisplayOrder : question.displayOrder,
                   "DataUrl": question.dataurl,
                   "Question": question.question,
                   "ControlType": question.ControlType,
                   "ControlValues": (isNullOrUndefined(question.controlValue)) ? "" : question.controlValue,
                    "IsActive": true,
                   "LastUpdatedBy": username,
                    "isChecked": true,
                    "Ischanged": true,
                    "Isdeleted": 0,
                   "SourceType": question.sourceType,
                   "DynamicValidation": question.dynamicValidation,
                   "ServerName": question.serverName,
                   "Database": question.Database,
                   "Query": question.Query,
                   "AuthenticationType": question.kustoauthenticationtype,
                   "IsActionRequired": false,
                   "ActionItemValue": '',

                };
                AddNewquestiontolist(obj, question.Questionindex);
            }
        }

       
    };
    const SaveOnCallDashboardData =  ()=> {
        let QuestionId = "";
        if (template.templateID == undefined) {
            template.templateID = 0;
        }
        let iserror = false;
        if (template.CheckListCount > 0) {
            if (template.IsActive == 0 || template.IsActive == false) {
                iserror = true;
                toastIdRef.current = toast.error(
                    renderToastifyMsg("This Template is already mapped to checklist, Please make sure Isactive flag is checked",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );              
            }

        }
        if (!iserror) {
            let Isexist = false;
            if (isArrayWithLength(checklisttemplatedata)) {
                checklisttemplatedata.map((row, key) => {
                    if (row.templateName.toLowerCase() == template.templateName.toLowerCase() && template.templateID != row.templateId) {
                        Isexist = true;
                    }

                })
            }
            if (Isexist) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Template Name already Exist",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            }
            else {
                let xml = "<List>";
                let count = 1;
                let Displayorders = [];
                let questions = [];
                let duplicateorders = false;
                let duplicatequestions = false;
                for (var i = 0; i < QuestionarrieList.length; i++) {
                    if (QuestionarrieList[i].id !== -1) {
                        for (var j = 0; j < questions.length; j++) {
                            if (QuestionarrieList[i].Question.toLowerCase() == questions[j].toLowerCase()) {
                                duplicatequestions = true;
                                j = questions.length;
                                i = QuestionarrieList.length;
                            }

                        }
                        if (duplicatequestions == false)
                            questions.push(QuestionarrieList[i].Question);

                        if (!duplicatequestions) {
                            let toDate = new Date();
                            let fromDate = new Date(
                                toDate.getFullYear(),
                                toDate.getMonth(),
                                toDate.getDate()
                            );
                            var query = QuestionarrieList[i].Query;
                            if (QuestionarrieList[i].Query != undefined && QuestionarrieList[i].Query != '') {
                                query = QuestionarrieList[i].Query.replace('todatetime(<<datetime>>)', 'datetime(' + moment(fromDate).format("MM/DD/YYYY") + ')');
                                query = query.replace('<<datacenter>>', "'" + QuestionarrieList[i].AuthenticationType + "'");
                                query = query.replace(new RegExp('<', "g"), '^');
                                query = query.replace(new RegExp('>', "g"), '$');
                                query = query.replace(new RegExp('"', "g"), "'");
                            }
                            if (QuestionarrieList[i].QuestionID == 0 && QuestionarrieList[i].IsActive == true) {

                                xml = xml + "<Row Question=\"" + escapeChar(QuestionarrieList[i].Question) + "\" ControlType= \"" + escapeChar(QuestionarrieList[i].ControlType) +
                                    "\" ControlValues=\"" + escapeChar(QuestionarrieList[i].ControlValues) + "\" DisplayOrder = \"" + escapeChar(QuestionarrieList[i].DisplayOrder) +
                                    "\" SourceType=\"" + escapeChar(QuestionarrieList[i].SourceType) + "\" DynamicValidation=\"" + escapeChar(QuestionarrieList[i].DynamicValidation) + "\" ServerName = \"" + escapeChar(QuestionarrieList[i].ServerName) +
                                    "\" Database=\"" + escapeChar(QuestionarrieList[i].Database) + "\" AuthenticationType = \"" + escapeChar(QuestionarrieList[i].AuthenticationType) + "\" Query = \"" + query +
                                    "\" IsActionRequired=\"" + escapeChar(QuestionarrieList[i].IsActionRequired) + "\" ActionItemValue = \"" + escapeChar(QuestionarrieList[i].ActionItemValue) +
                                    "\" DataURL=\"" + escapeChar(QuestionarrieList[i].DataUrl) + "\" " +
                                    " ActionFlag='C'   />";
                                count = count + 1;

                            }
                            else if (QuestionarrieList[i].QuestionID > 0 && QuestionarrieList[i].IsActive == true) {
                                xml = xml + "<Row QuestionID=\"" + escapeChar(QuestionarrieList[i].QuestionID) + "\" Question=\"" + escapeChar(QuestionarrieList[i].Question) + "\" ControlType= \"" + escapeChar(QuestionarrieList[i].ControlType) +
                                    "\" ControlValues=\"" + escapeChar(QuestionarrieList[i].ControlValues) + "\" DisplayOrder = \"" + escapeChar(QuestionarrieList[i].DisplayOrder) +
                                    "\" SourceType=\"" + escapeChar(QuestionarrieList[i].SourceType) + "\" DynamicValidation=\"" + escapeChar(QuestionarrieList[i].DynamicValidation) + "\" ServerName = \"" + escapeChar(QuestionarrieList[i].ServerName) +
                                    "\" Database=\"" + escapeChar(QuestionarrieList[i].Database) + "\" AuthenticationType = \"" + escapeChar(QuestionarrieList[i].AuthenticationType) + "\" Query = \"" + query +
                                    "\" IsActionRequired=\"" + escapeChar(QuestionarrieList[i].IsActionRequired) + "\" ActionItemValue = \"" + escapeChar(QuestionarrieList[i].ActionItemValue) +
                                    "\" DataURL=\"" + escapeChar(QuestionarrieList[i].DataUrl) + "\" " +
                                    " ActionFlag='U'   />";
                                count = count + 1;
                            }
                            else if (QuestionarrieList[i].QuestionID > 0 && QuestionarrieList[i].IsActive == false) {
                                xml = xml + "<Row QuestionID=\"" + escapeChar(QuestionarrieList[i].QuestionID) + "\" Question=\"" + escapeChar(QuestionarrieList[i].Question) + "\" ControlType= \"" + escapeChar(QuestionarrieList[i].ControlType) +
                                    "\" ControlValues=\"" + escapeChar(QuestionarrieList[i].ControlValues) + "\" DisplayOrder = \"" + escapeChar(QuestionarrieList[i].DisplayOrder) +
                                    "\" SourceType=\"" + escapeChar(QuestionarrieList[i].SourceType) + "\" DynamicValidation=\"" + escapeChar(QuestionarrieList[i].DynamicValidation) + "\" ServerName = \"" + escapeChar(QuestionarrieList[i].ServerName) +
                                    "\" Database=\"" + escapeChar(QuestionarrieList[i].Database) + "\" AuthenticationType = \"" + escapeChar(QuestionarrieList[i].AuthenticationType) + "\" Query = \"" + query +
                                    "\" IsActionRequired=\"" + escapeChar(QuestionarrieList[i].IsActionRequired) + "\" ActionItemValue = \"" + escapeChar(QuestionarrieList[i].ActionItemValue) +
                                    "\" DataURL=\"" + escapeChar(QuestionarrieList[i].DataUrl) + "\" " +
                                    " ActionFlag='D'   />";
                            }

                        }
                    }
                }
                if (duplicatequestions) {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Duplicate Questions",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
                else if (count == 1) {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Template should have atleast one Active Question",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
                else {
                    xml = xml + "</List>";
                    username = username.split('@')[0];
                    var data = {
                        TemplateId: template.templateID,
                        TemplateName: template.templateName,
                        TemplateDescription: template.templateDescription,
                        TemplateNotes: template.templateNotes,
                        TemplateType: template.templateType,
                        IsLocked: template.isLocked,
                        IsActive: (template.IsActive),
                        CreatedBy: username,
                        LastUpdatedBy: username,
                        NewQuestionList: xml,
                        TemplateRole: template.templateRole
                    }
                    setloadingtrue();
                    ChecklistAPI.SaveOnCallDashboardQuestionnarie(data)
                        .then((result: number) => {
                            setloadingfalse();                            
                            if (result !== -1) {
                                if (template.templateID == 0) {
                                    toastIdRef.current = toast.success(
                                        renderToastifyMsg("Template Saved successfully",
                                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                                    );

                                }
                                else {
                                    toastIdRef.current = toast.success(
                                        renderToastifyMsg("Template Updated successfully",
                                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                                    );
                                }

                            } else {
                                toastIdRef.current = toast.error(
                                    renderToastifyMsg("Error while Adding/Updating Template",
                                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                                );

                            }
                            GetOnCallDashboardTemplateData();
                            hidetemplatePopup();

                        }).catch(error => {
                            setloadingfalse();
                            toastIdRef.current = toast.error(
                                renderToastifyMsg("Error while Adding/Updating Template",
                                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                            );
                        });
                }
            }
        }
    }
    const checkURL = (url) => {
        if (!ValidateURL(url)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg("URL should start with 'https://'",
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );

        }
    }
    return (
        <div>
            <NewFunctionalityBanner />
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        <ChecklistTemplateTable 
                            checklisttemplatedata={checklisttemplatedata}
                            OpentemplatePopup={OpentemplatePopup}
                            showtemplatePopup={showtemplatePopup}
                            hidetemplatePopup={hidetemplatePopup}
                            template={template}
                            templatedatachange={templatedatachange}
                            AddQuestionstoTemplate={AddQuestionstoTemplate}
                            ChecklistTemplateEdit={ChecklistTemplateEdit}
                            MoveChecklistTemplateToArchive={MoveChecklistTemplateToArchive}
                            questioncontroltypeoptions={questioncontroltypeoptions}
                            sourcetypes={sourcetypes}
                            dynamicValidationTypes={dynamicValidationTypes}
                            templateTypes={templateTypes}
                            templateNameList={templateNameList}
                            copiedTemplateQues={copiedTemplateQues}
                            copyTempQuestiondrpChange={(copiedTemplateQues) => copyTempQuestiondrpChange(copiedTemplateQues)}
                            copyTempQuestions={copyTempQuestions}
                            AuthenticationTypes={AuthenticationTypes}
                            question={question}
                            handlequestionChange={handlequestionChange}
                            disableaddbuttonforquestion={disableaddbuttonforquestion}
                            ShowQuestionList={ShowQuestionList}
                            SaveQuestionnarie={SaveQuestionnarie}
                            showquestionlist={showquestionlist}
                            QuestionarrieList={QuestionarrieList}
                            ChecklistquestionEdit={ChecklistquestionEdit}
                            questionlistvalueschange={questionlistvalueschange}
                            istemplatesavebuttondisabled={istemplatesavebuttondisabled}
                            SaveOnCallDashboardData={SaveOnCallDashboardData}
                            checkURL={checkURL}
                            UpdateQuestionaireDisplayOrder={UpdateQuestionaireDisplayOrder}
                            isAdmin={isAdmin}
                            LoginuserId={LoginuserId}
                            EditLockforChecklistTemplate={EditLockforChecklistTemplate}
                            isCopyTempQuesCheckVal={isCopyTempQuesCheckVal}
                            isCopyTemplateQuestion={isCopyTemplateQuestion}
                            copyTempQuesSelect={copyTempQuesSelect}
                            templateRoles={templateRoles}
                        />


                    </div>
                </section>
            </LoadingOverlay>

        </div>
    );

};
const mapStateToProps = (state: IApplicationState) => ({
    ...state.checklistTemplate,
    isAuthenticated: state.authUser.isAuthenticated,
    username: state.authUser.login,
    isAdmin: state.authUser.isAdmin
})

export default connect(mapStateToProps, actionCreators)(ChecklistTemplate);
