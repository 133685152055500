import { BaseService } from './base.service';
import { IPropCriticalAdvisoryData, PropAzureHighPriorityLink } from '../store/CriticalAdvisory';

/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class CriticalAdvisoryService extends BaseService {
    private static _serviceGroupService: CriticalAdvisoryService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): CriticalAdvisoryService {
        return (this._serviceGroupService || (this._serviceGroupService = new this('CriticalAdvisory')));
    }

    public async GetCriticalAdvisoryData(): Promise<IPropCriticalAdvisoryData[]> {
        const { data } = await this.$http.get<IPropCriticalAdvisoryData[]>(`GetCriticalAdvisories`);
        return data;
    }

    public async GetCriticalAdvisoryNoFlyZoneData(): Promise<IPropCriticalAdvisoryData[]> {
        const { data } = await this.$http.get<IPropCriticalAdvisoryData[]>(`GetCriticalAdvisoryNoFlyZoneData`);
        return data;
    }

    public async GetAzureHighPriorityLinksData(): Promise<PropAzureHighPriorityLink[]> {
        const { data } = await this.$http.get<PropAzureHighPriorityLink[]>(`GetAzureHighPriorityLinksData`);
        return data;
    }
    public async GetAzureLocations(): Promise<object[]> {
        const { data } = await this.$http.get<object[]>(`GetAzureLocations`);
        return data;
    }
    public async GetAzureEnvironments(): Promise<object[]> {
        const { data } = await this.$http.get<object[]>(`GetAzureEnvironments`);
        return data;
    }

    public async UpdateCriticalAdvisoryData(advisoryId: number): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateCriticalAdvisoryData?advisoryID=${advisoryId}`);
        return data;
    }

    public async UpdateCriticalAdvisoryNoFlyZoneData(noFlyZoneInfodata): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateCriticalAdvisoryNoFlyZoneData`, noFlyZoneInfodata);
        return data;
    }

    public async UpdateCriticalAdvisoryGuidelineData(guidelineInfo): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateCriticalAdvisoryGuidelineData`, guidelineInfo);
        return data;
    }

    public async AddNewCriticalAdvisory(AddCritAdvisoryData): Promise<number> {
        const { data } = await this.$http.post<number>(`AddNewCriticalAdvisory`, AddCritAdvisoryData);
        return data;
    }

    public async UpdateAdRecord(AddCritAdvisoryData): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateCriticalAdvisoryRecord`, AddCritAdvisoryData);
        return data;
    }
    public async DeleteCriticalAdvisoryRecord(id: number): Promise<number> {
        const { data } = await this.$http.get<number>(`DeleteCriticalAdvisoryRecord?id=${id}`);
        return data;
    }
    public async MoveEventToPlannedLockdown(advisoryId: number, updatedBy: string): Promise<number> {
        const { data } = await this.$http.post<number>(`MoveEventToPlannedLockdown?advisoryID=${advisoryId}&updatedBy=${updatedBy}`);
        return data;
    }
    public async MoveEventToCurrentLockdown(advisoryId: number, updatedBy: string): Promise<number> {
        const { data } = await this.$http.post<number>(`MoveEventToCurrentLockdown?advisoryID=${advisoryId}&updatedBy=${updatedBy}`);
        return data;
    }
}

export const CriticalAdvisoryAPI = CriticalAdvisoryService.Instance;
