import React, { useState, Fragment } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { isArrayWithLength } from '../../../utils';
import { IPropActionItemsReviewWishr } from '../../../store/WIP-SHR/types';
import { faCopy, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion } from 'react-bootstrap'

type ActionItemsReviewProps = {
    ActionItemsReviewData: any,
    isAdmin: any,
    CopyTableData: any,
    accordiontoggle: any,
    setaccordiontoggledata: any
};

const ActionItemsReview = React.memo<ActionItemsReviewProps>(({
    ActionItemsReviewData,
    isAdmin,
    CopyTableData,
    accordiontoggle,
    setaccordiontoggledata
}) => {

    return (
        <React.Fragment>
            <br />
            <Accordion>
                <Row style={{ paddingBottom: "14px" }}>
                    <Col lg="7"><h5 className="headertext">Action Items Review (5 minutes)</h5></Col>
                    <Col lg="5">
                        {(isAdmin === true) ?
                            <div className="divaddnew">
                                <Button className='PIRAddbuttons' hidden={(ActionItemsReviewData.length > 1 ? false : true)} onClick={() => CopyTableData('actionItems')}> <FontAwesomeIcon icon={faCopy} /> &nbsp;Copy Table</Button>&nbsp;
                                <Accordion.Toggle as={Button} eventKey="0" onClick={e => setaccordiontoggledata(!accordiontoggle)} style={{ background: 'none' }}>
                                    <span style={{ float: 'right', padding: '10px' }}>
                                        {(accordiontoggle) ? <FontAwesomeIcon title='Collapse All' icon={'minus-circle'} className='icon-format-collapseinternalEscalation' /> : <FontAwesomeIcon title='Expand All' icon={'plus-circle'} className='icon-format-expandinternalEscalation' />
                                        }
                                    </span>
                                </Accordion.Toggle>
                            </div>
                            : <React.Fragment></React.Fragment>
                        }
                    </Col>
                </Row>
                <div id="acReview" style={{ display: (accordiontoggle) ? 'block' : 'none' }}>
                    {
                        <Row className="tblpadding">
                            <Col lg="12">
                                <table id="tblPIR" className="tblPIR tblborder">
                                    <thead>
                                        <tr>
                                            <th className="thwidth280">Presenter</th>
                                            <th className="thwidth200">Task ID</th>
                                            <th className="thwidth300">Title</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!isArrayWithLength(ActionItemsReviewData)
                                            ?
                                            <React.Fragment>
                                                <tr>
                                                    <td colSpan={7} className="clsnodatamessgefortable">
                                                        No Data Available
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                            : ActionItemsReviewData.map((row: IPropActionItemsReviewWishr, index) => (
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <td>
                                                            <div>{row.presenter}</div>
                                                        </td>
                                                        <td>
                                                            <div><a title="Task Id" href={"https://identitydivision.visualstudio.com/DefaultCollection/Engineering/_workitems/edit/" + row.id} target="_blank">{row.id}</a></div>

                                                        </td>
                                                        <td>
                                                            <div>{row.topicName}</div>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    }
                </div>
            </Accordion>
        </React.Fragment>
    );
});

ActionItemsReview.displayName = 'Action Items Review';

export default ActionItemsReview;