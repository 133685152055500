import { CommonAPI, MSERHeatmapPreviewAPI, ServiceGroupApi, WeeklyAPI } from '../../api';
import { IAppThunkAction, ReduxAction } from '../';
import { ActionType, IMSERHeatmapTrendReport } from './types';
import {  IServiceGroup } from '../home-tiles';
import moment from 'moment';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../utils';
const identityServiceClassification = [
    { name: "All", value: "All" },
    { name: "Mission Critical", value: 'Mission Critical' },
    { ame: "Business Critical", value: 'Business Critical' }
]
export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }

    },
    onmserHeatmapTrendStartDateChange: (mserHeatmapTrendStartDate: Date): ReduxAction => ({

        mserHeatmapTrendStartDate: mserHeatmapTrendStartDate,
        type: ActionType.MSER_HEATMAP_TREND_START_DATE_CHANGE
    }),
    ClearMSERHeatmapTrendDate: (): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {
        dispatch({
            mserHeatmapTrendStartDate: new Date(),
            type: ActionType.MSER_HEATMAP_TREND_START_DATE_CHANGE
        });
    },
    GetServicegroups: (): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        MSERHeatmapPreviewAPI.GetServiceGroupsHeatmapTrend()
            .then((serviceGroupRaw: IServiceGroup[]) => {
                const serviceGroups = [];
                const servicegroupval = []; 
                let servicegroupvalstring = '';             
                if (isArrayWithLength(serviceGroupRaw)) {
                    serviceGroupRaw.map((item: IServiceGroup, key) => {                       
                        var element = serviceGroups.filter(serGroup => serGroup.name === item.groupName.replace(new RegExp('<br/>', 'g'), ' – '));
                        if (element.length === 0) {
                            serviceGroups.push({ name: item.groupName.replace(new RegExp('<br/>', 'g'), ' – '), value: item.groupId });
                            servicegroupval.push(item.groupId);
                            servicegroupvalstring = servicegroupvalstring + ',' +  item.groupId;
                        }

                    })
                }
              
                dispatch({   
                    identityServiceClassification,
                    servicegroupval,
                    serviceGroups,
                    servicegroupvalstring,
                    type: ActionType.GET_SERVICEGROUPS_SUCCESS
                });
            }).catch(error => {
               
                dispatch({                  
                    type: ActionType.GET_SERVICEGROUPS_FAIL
                });
            });
    },
   
    GetServices: (): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {       
        MSERHeatmapPreviewAPI.GetHeatmapTrendServices()
            .then((serviceRaw: IServiceGroup[]) => {
                const services = [];               
                const serviceval = []; 
                let servicevalstring = '';
                if (isArrayWithLength(serviceRaw)) {
                    serviceRaw.map((item: IServiceGroup) => {
                        serviceval.push(item.serviceId);
                        services.push({ name: item.serviceName, value: item.serviceId, groupName: item.groupName });
                        servicevalstring = servicevalstring + ',' +  item.serviceId;
                    })
                }
                dispatch({
                    serviceval,
                    services,    
                    servicevalstring,
                    type: ActionType.GET_SERVICELISTS_SUCCESS
                });
               
            }).catch(error => {
                dispatch({
                    type: ActionType.GET_SERVICELISTS_FAIL
                });
            });
    },
    servicegroupvalchange: (value): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {
        let services=getSate().MSERHeatmapTrend.services;
        let servicegroupvalstring = '';
        let serviceval = [];
        let servicevalstring = '';
        if (isArrayWithLength(value)) {
            value.map((obj) => {
                if (isArrayWithLength(services)) {
                    services.map((service) => {
                        if (obj === service.groupName) {
                            serviceval.push(service.value);
                            servicevalstring = servicevalstring + ',' + service.value;
                        }
                        
                    })
                }
                servicegroupvalstring = servicegroupvalstring +','+ obj;
            })
        }
        dispatch({
            servicegroupval: value,
            servicegroupvalstring,
            serviceval,
            servicevalstring,
            type: ActionType.SERVICE_GROUPVAL_CHANGE
        });
    },
    serviceschange: (value): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {
        let servicegroupval = [];
        let servicegroupvalstring = '';
        let services=getSate().MSERHeatmapTrend.services;
        let servicevalstring = ''
        if (isArrayWithLength(value)) {
            value.map((obj) => {
                services.map((service) => {
                    if (service.value === obj) {                        
                       if (servicegroupval.indexOf(service.groupName) === -1) {
                           servicegroupval.push(service.groupName);
                           servicegroupvalstring = servicegroupvalstring + ',' + service.groupName;
                        }
                    }
                });
               
                servicevalstring = servicevalstring + ',' +  obj;
            })
        }
        dispatch({
            servicegroupval: servicegroupval,
            servicegroupvalstring: servicegroupvalstring,
            serviceval: value,
            servicevalstring,
            type: ActionType.SERVICE_VAL_CHANGE
        });
    },
    serviceidentityclassificationchange: (value): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {       
        dispatch({
            selectedidentityServiceClassification: value,
            type: ActionType.SERVICE_IDENTITY_CLASIFICATION_CHANGE
        });
    },
    searchtextboxchange: (value): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {
        dispatch({
            globalsearch: value.target.value,
            type: ActionType.SEARCH_TEXTBOX_CHANGE
        });
    },
    clearfilters: (servicegroupval, servicegroupvalstring, serviceval, servicevalstring, selectedidentityServiceClassification): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {
        dispatch({
            servicegroupval: servicegroupval,
            servicegroupvalstring: servicegroupvalstring,
            serviceval: serviceval,
            servicevalstring: servicevalstring,
            selectedidentityServiceClassification: selectedidentityServiceClassification,
            type: ActionType.CLEAR_FILTERS
        });
    },

    getMSERHeatmapTrendData: (monthstartdate: string, selectedidentityServiceClassification: string, servicegroups: string, serviceids: string): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {
        monthstartdate = moment(monthstartdate).format('MM/DD/YYYY');
        let sid = getSate().serviceHealth.selectedServiceId;
        dispatch({
            type: ActionType.MSER_HEATMAP_TRENDDATA_FETCH
        });
        // Build http request and success handler in Promise<void> wrapper      
        MSERHeatmapPreviewAPI.GetMSERHeatmapDataTrend(monthstartdate, selectedidentityServiceClassification, servicegroups, serviceids)
            .then((mserHeatmapTrendData: IMSERHeatmapTrendReport[]) => {                
                var liKPIValueKeys = [];
                if (isArrayWithLength(mserHeatmapTrendData)) {                 
                    mserHeatmapTrendData[0].MonthStartDate = monthstartdate;
                }
                liKPIValueKeys = mserHeatmapTrendData.length > 0 ? Object.keys(mserHeatmapTrendData[0].liKPIValues) : [];
                var tempC = [];
                var tempV = [];
                for (var j = 0; j < liKPIValueKeys.length; j++) {
                    if (liKPIValueKeys[j].toLowerCase() != 'displayorder') {
                        tempC.push(liKPIValueKeys[j].split('~')[0]);
                        tempV.push(liKPIValueKeys[j].split('~')[1]);
                    }
                }
              
                let uniquec = tempC.filter(function (elem, index, self) {
                    return index === self.indexOf(elem);
                });

                let uniquev = tempV.filter(function (elem, index, self) {
                    return index === self.indexOf(elem);
                });
              
                const MSERHeatmapTrendheaderArr = [];
                var header = '';
                for (var countKPI = 0; countKPI < liKPIValueKeys.length; countKPI++) {
                    header = liKPIValueKeys[countKPI];
                    if (countKPI == liKPIValueKeys.length) {
                        MSERHeatmapTrendheaderArr.push({ width: '145px', val: header.charAt(0).toUpperCase() + header.slice(1) });
                    }
                    else {
                        MSERHeatmapTrendheaderArr.push({ width: '5%', val: header.charAt(0).toUpperCase() + header.slice(1) });
                    }
                }
                const MSERHeatmapTrendKPiArr = [];
                dispatch({
                    mserHeatmapTrendData,
                    MSERHeatmapTrendheaderArr,
                    MSERHeatmapTrendKPiArr,
                    liKPIValueKeys,
                    uniquec,
                    uniquev,
                    selectedidentityServiceClassification,
                    type: ActionType.MSER_HEATMAP_TRENDDATA_RECEIVE
                });


            });
    },

};

