import React, { useState, useRef, Fragment } from 'react';
import { Button, Accordion } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Fieldset } from 'primereact/fieldset';
import excel_1 from '../../../assets/images/excel_1.png';
import tes from '../../../assets/images/bxloader.gif';
import newTemplate from '../../../assets/images/IdAckTracking/newTemplate.png';
import processFlow from '../../../assets/images/IdAckTracking/processFlow.png';
import createTemplate from '../../../assets/images/IdAckTracking/createTemplate.png';
import useTemplate from '../../../assets/images/IdAckTracking/useTemplate.png';
import reuseTemplate from '../../../assets/images/IdAckTracking/reuseTemplate.png';
import ckTrackingHome from '../../../assets/images/IdAckTracking/ackTrackingHome.png';
import selectAckType from '../../../assets/images/IdAckTracking/selectAckType.png';
import newAckType from '../../../assets/images/IdAckTracking/newAckType.png';
import editAck from '../../../assets/images/IdAckTracking/editAck.png';
import updateAck from '../../../assets/images/IdAckTracking/updateAck.png';
import modifiedAck from '../../../assets/images/IdAckTracking/modifiedAck.png';
import ackColumns from '../../../assets/images/IdAckTracking/ackColumns.png';
import editAckTemplate from '../../../assets/images/IdAckTracking/editAckTemplate.png';
import addremoveAckColumn from '../../../assets/images/IdAckTracking/addremoveAckColumn.png';
import updateAckType from '../../../assets/images/IdAckTracking/updateAckType.png';
import ackFilter from '../../../assets/images/IdAckTracking/ackFilter.png';
import viewFilter from '../../../assets/images/IdAckTracking/viewFilter.png';
import ackUpdateFilter from '../../../assets/images/IdAckTracking/ackUpdateFilter.png';
import ackCopyURL from '../../../assets/images/IdAckTracking/ackCopyURL.png';
import copyEmail from '../../../assets/images/IdAckTracking/copyEmail.png';
import exportExcel from '../../../assets/images/IdAckTracking/exportExcel.png';
import globaSearch from '../../../assets/images/IdAckTracking/globaSearch.png';
import emailCopy from '../../../assets/images/IdAckTracking/emailCopy.png';
import standardColumn from '../../../assets/images/IdAckTracking/standardColumn.png';
import dynamicColumn from '../../../assets/images/IdAckTracking/dynamicColumn.png';
import {
    Card, Row, Col, Container, CardBody
} from 'reactstrap';

type AckTrackingUserGuideProps = {
    hideAckTrackingUserGuide
};

const AckTrackingUserGuide = React.memo<AckTrackingUserGuideProps>(({
    hideAckTrackingUserGuide

}) => {
    return (
        <div style={{ width: '100%' }}>
            <Card className="cardstyle">
                <CardBody className="cardbodyhederstyle">
                    <Row>
                        <Col lg="1">
                            <Button className='ackDailydataModelbuttons' onClick={hideAckTrackingUserGuide} >Back to Ack Tracking</Button>
                        </Col>
                        <Col lg="10">
                            <h3 className="headertext">Identity ACK Tracking</h3>
                            <h5 className="ackHeaderText">User Guide</h5>
                        </Col>
                        <Col lg="1">
                            
                        </Col>


                    </Row>
                </CardBody>
            </Card>
            <br />            
            <div>
                <Fieldset collapsed legend="SCOPE AND PURPOSE" toggleable>
                    <p className='userGuideFontContent' >Ack tracking tool is a generic and standardized way for anyone in Identity to create their own tracking sheet. It is very general and very templatized, as in can be made as specific as the user wants. It contains some standard columns, and many other generic columns can be added. All the data can be filtered to the users needs and color coded based on user’s definition.</p>                    
                </Fieldset>
                <Fieldset collapsed legend="PROCESS OVERVIEW" toggleable>
                    <div className='userGuideFontContent'>
                    <p>Ack tracking is following 3 steps process</p>
                        <li><b>Create a Ack Tracking Template:</b> Create own customized template. In this template, user can select fixed columns from service Tree and add own customized columns.</li>
                         <li><b>Create a Ack Type:</b> Create a Ack Type and map it to ID Ack tracking Template.</li>
                        <li><b>ID Ack Tracking:</b> Dashboard to view the actual ID Ack tracking data based on the selected Ack Type and edit option to update the each Ack Tracking</li>    
                        
                        <br/><h5><b>Process Flow</b></h5>
                        <br/>
                        <img src={processFlow} width="1000" height="400"></img>
                   </div>
                </Fieldset>
               
                <Fieldset collapsed legend="CREATE A NEW TEMPLATE" toggleable>
                    <div className='userGuideFontContent'>
                    <p><b>STEP 1:</b> click on <b>ID Ack Tracking - ID AckTracking Template</b> from top menu bar. You’ll be redirected to ID Ack tracking Create template page then click the “New Template”</p>
                    <br/>
                    <img className='userGuideImage' src={newTemplate}></img>
                        <br />
                        <p><b>STEP 2:</b> Fill in the ID Ack tracking required fields and hit SAVE</p>
                            <img className='userGuideImage' src={createTemplate}></img><br />
                     </div>                       
                </Fieldset>
                <Fieldset collapsed legend="CREATE A NEW ACK TYPE" toggleable>
                    <div className='userGuideFontContent'>
                        <p><b>STEP 1:</b> click on <b>ID Ack Tracking - ID AckTracking Template</b> from top menu bar. You’ll be redirected to ID Ack tracking Create template page then click the “Ack Type”</p>
                        <br /><img className='userGuideImage' src={useTemplate}></img><br />
                        <p><b>STEP 2:</b> Fill in the Ack Type required fields and hit SAVE</p>
                        <br/><img className='userGuideImage' src={newAckType}></img><br />                        
                    </div>
                </Fieldset>
                <Fieldset legend="ID ACK TRACKING" toggleable>
                    <Fieldset collapsed legend="ACCESS ID ACK TRACKING" toggleable>
                        <div className='userGuideFontContent'>                       
                        <p><b>STEP 1:</b> click on <b>ID Ack Tracking - ID AckTracking</b> from the top menu bar. You’ll be redirected to ID Ack tracking home page.</p>
                        <br /><img className='userGuideImage' src={ckTrackingHome}></img><br />
                        <p><b>STEP 2:</b> click on <b>Ack Type</b> dropdown and select your created new ack type to view the Ack</p>
                        <br /><img className='userGuideImage' src={selectAckType}></img><br />                            
                        <p><b>STEP 3:</b> Make sure ID Ack has the columns you want </p>
                        <br /><img className='userGuideImage' src={ackColumns}></img><br />
                    </div>
                    </Fieldset>
                    <Fieldset collapsed legend="ADD/REMOVE COLUMNS FROM ACK TRACKING" toggleable>
                        <div className='userGuideFontContent'>
                            <p><b>STEP 1:</b> click on <b>ID Ack Tracking - ID AckTracking Template</b> from the top menu bar. You’ll be redirected to ID Ack tracking template page.</p>
                            <br /><p><b>STEP 2:</b> click on edit icon to modify the template</p><br />
                        <Fieldset collapsed legend="STANDARD COLUMN" toggleable>                           
                            <div className='userGuideFontContent'>                                
                                <br /><p><b>STEP 3:</b>Add/Remove standard columns per given steps.</p><br />
                                <p>If user Add/Remove the Ack template Service tree standard column then changes will automatically updated in all the Ack type which are using the same Ack template</p><br />
                                <br /><img className='userGuideImage' src={standardColumn}></img><br />                                
                                <br /> <p><b>STEP 4:</b>click <b>Ack Tracking </b>from top menu to view your changes</p><br />
                                <br /> <p><b>STEP 5:</b> Once Saved click <b>Ack Tracking </b>from top menu to view your changes</p><br /> 
                                <br /><img className='userGuideImage' src={selectAckType}></img><br />
                                <p><b>STEP 6:</b> Make sure your columns changes reflect in your Ack</p>
                                <br /><img className='userGuideImage' src={ackColumns}></img><br />
                            </div>
                        </Fieldset>
                        <Fieldset collapsed legend="DYNAMIC COLUMN" toggleable>
                        <div className='userGuideFontContent'>                            
                            <br /><p><b>STEP 3:</b>Add/Remove dynamic columns per given steps</p><br />                            
                            <br /><img className='userGuideImage' src={dynamicColumn}></img><br />
                            <p><b>STEP 4:</b>click on “Ack Type”. It is mandatory to update the Ack type and Template mapping, if you ADD/REMOVE dynamic column in ID Ack tracking template otherwise template changes will not reflect in ID Ack tracking page.</p><br />  
                            <br /><img className='userGuideImage' src={useTemplate}></img>   
                            <p><b>STEP 5:</b> Select the Ack type you want to update your template changes</p> 
                            <br /><img className='userGuideImage' src={updateAckType}></img><br /> 
                            <br /> <p><b>STEP 6:</b> Once Saved click <b>Ack Tracking </b>from top menu to view your changes</p><br /> 
                            <p><b>STEP 7:</b> click on <b>Ack Type</b> dropdown and select your modified ack type to view the Ack changes</p>
                            <br /><img className='userGuideImage' src={selectAckType}></img><br />
                            <p><b>STEP 8:</b> Make sure your columns changes reflect in your Ack</p>
                            <br /><img className='userGuideImage' src={ackColumns}></img><br />
                            </div>
                            </Fieldset>
                          </div>
                    </Fieldset>
                    <Fieldset collapsed  legend="HOW TO SAVE FILTER" toggleable>
                        <div className='userGuideFontContent'>
                            <p><b>STEP 1:</b> Select Ack Type from dropdown</p>
                            <br /><p><b>STEP 2:</b> Select required filters from dropdown (if required)</p>
                            <br /><p><b>STEP 3:</b> Hit Filter icon </p>
                            <br /><p><b>STEP 4:</b> Keep Filter Names dropdown values selected as "Select Filter" </p>
                            <br /><p><b>STEP 5:</b> Enter the Filter name in the textbox </p>
                            <br /><p><b>STEP 6:</b> Hit Save icon</p>
                            <br /><img className='userGuideImage' src={ackFilter}></img><br />
                        </div>
                    </Fieldset>  
                    <Fieldset collapsed legend="HOW TO VIEW FILTER" toggleable>
                        <div className='userGuideFontContent'>
                            <p><b>STEP 1:</b> Select Ack Type from dropdown</p>
                            <br /><p><b>STEP 2:</b> Select the Filter name from the dropdown.</p>       
                            <br /><img className='userGuideImage' src={viewFilter}></img><br />
                        </div>
                    </Fieldset>
                    <Fieldset collapsed legend="HOW TO UPDATE THE FILTER" toggleable>
                        <div className='userGuideFontContent'>
                            <p><b>STEP 1:</b> Select Ack Type from dropdown</p>
                            <br /><p><b>STEP 2:</b> Select the Filter name from the dropdown.</p>
                            <br /><p><b>STEP 3:</b> Select required filter values from dropdown.</p>
                            <br /><p><b>STEP 4:</b> Hit Filter icon </p>
                            <br /><p><b>STEP 5:</b> Hit Save icon</p>
                            <br /><p><b>STEP 6:</b> Confirmation message will appear and hit OK to continue the changes</p>
                            <br /><img className='userGuideImage' src={ackUpdateFilter}></img><br />
                        </div>
                    </Fieldset>
                    <Fieldset collapsed legend="HOW TO COPY ULR TO GET THE INDIVIDUALIZED FILTER" toggleable>
                        <div className='userGuideFontContent'>
                            <p><b>STEP 1:</b> Select Ack Type from dropdown</p>
                            <br /><p><b>STEP 2:</b> Select the Filter name from the dropdown.</p>
                            <br /><p><b>STEP 3:</b> Click copy URL icon.</p>
                            <br /><p><b>STEP 4:</b> Popup shows the URL to copy. Ctrl + C</p>
                            <br /><p><b>STEP 5:</b> Use copied link to send to GEM’s for actual acking</p>
                            <br /><img className='userGuideImage' src={ackCopyURL}></img><br />
                        </div>
                    </Fieldset>
                    <Fieldset collapsed legend="HOW TO COPY EMAIL" toggleable>
                        <div className='userGuideFontContent'>
                            <p><b>STEP 1:</b> Select Ack Type from dropdown</p>
                            <br /><p><b>STEP 2:</b> Select the Filter name from the dropdown.</p>
                            <br /><p><b>STEP 3:</b> Click Email icon.</p>
                            <br /><p><b>STEP 4:</b> Copy Email popup will appears then Click clipboard copy icon.</p>                        
                            <br /><p>Only when Dev owner or PM owner service tree columns are present in Ack template only then email alias are present inside the copy email popup</p>                        
                            <br /><img className='userGuideImage' src={copyEmail}></img><br />
                            <br /><img className='userGuideImage' src={emailCopy}></img><br />
                        </div>
                    </Fieldset>  
                    <Fieldset collapsed legend="HOW TO EXPORT TO EXCEL" toggleable>
                        <div className='userGuideFontContent'>
                            <p><b>STEP 1:</b> Select Ack Type from dropdown</p>
                            <br /><p><b>STEP 2:</b> Select the Filter name from the dropdown.</p>
                            <br /><p><b>STEP 3:</b> Click Export to Excel icon </p>                            
                            <br /><img className='userGuideImage' src={exportExcel}></img><br />
                        </div>
                    </Fieldset> 
                    <Fieldset collapsed legend="HOW SEARCH AND CLEAR FILTER" toggleable>
                        <div className='userGuideFontContent'>
                            <p><b>STEP 1:</b> Enter the keyword in global search textbox to search Ack from below data table</p>
                            <br /><p><b>STEP 2:</b> Hit Clear filter icon to reset the filter values to default selection.</p>                     
                            <br /><img className='userGuideImage' src={globaSearch}></img><br />
                        </div>
                    </Fieldset> 
                    <Fieldset collapsed legend="FILL IN THE ID ACK TRACKING" toggleable>
                        <div className='userGuideFontContent'>
                            <p><b>STEP 1:</b> click edit icon from the table first column</p>
                            <br /><img className='userGuideImage' src={editAck}></img><br />
                            <p><b>STEP 2:</b>Fill in the ID Ack tracking required fields and hit SAVE</p>
                            <br /><img className='userGuideImage' src={updateAck}></img><br />
                            <p><b>STEP 3:</b> Once updated the modified user information will be shown in the grid.</p>
                            <br /><img className='userGuideImage' src={modifiedAck}></img><br />
                        </div>
                    </Fieldset>  
                </Fieldset>
                <Fieldset collapsed legend="STEP BY STEP PROCESS TO USE EXISTING TEMPLATE" toggleable>
                    <div className='userGuideFontContent'>
                        <p><b>STEP 1:</b> click on <b>ID Ack Tracking - ID AckTracking Template</b> from top menu bar. You’ll be redirected to ID Ack tracking Create template page then click the “Ack Type”</p>
                        <br /><img className='userGuideImage' src={useTemplate}></img><br />
                        <p><b>STEP 2:</b> Fill in the Ack Type required fields and hit SAVE</p>
                        <img className='userGuideImage' src={reuseTemplate}></img><br />
                        <p><b>STEP 3:</b> click on <b>ID Ack Tracking - ID AckTracking</b> from the top menu bar. You’ll be redirected to ID Ack tracking home page.</p>
                        <br /><img className='userGuideImage' src={ckTrackingHome}></img><br />
                        <p><b>STEP 4:</b> click on <b>Ack Type</b> dropdown and select your created new ack type to view the Ack</p>
                        <br /><img className='userGuideImage' src={selectAckType}></img><br />
                    </div>
                </Fieldset>
                <Fieldset collapsed legend="UPDATE ACK TYPE" toggleable>
                    <div className='userGuideFontContent'>
                        <p><b>STEP 1:</b> click on <b>ID Ack Tracking - ID AckTracking Template</b> from top menu bar. You’ll be redirected to ID Ack tracking Create template page then click the “Ack Type”</p>
                        <br /><img className='userGuideImage' src={useTemplate}></img><br />
                        <p><b>STEP 2:</b> Fill in the Ack Type required fields and hit SAVE</p>
                        <img className='userGuideImage' src={newAckType}></img><br />
                        <p><b>STEP 3:</b> click on <b>ID Ack Tracking - ID AckTracking</b> from the top menu bar. You’ll be redirected to ID Ack tracking home page.</p>
                        <br /><img className='userGuideImage' src={ckTrackingHome}></img><br />
                        <p><b>STEP 4:</b> click on <b>Ack Type</b> dropdown and select your created new ack type to view the Ack</p>
                        <br /><img className='userGuideImage' src={selectAckType}></img><br />
                    </div>
                </Fieldset>
            </div>
        </div>                   
    );
});

AckTrackingUserGuide.displayName = 'AckTrackingUserGuide';

export default AckTrackingUserGuide;