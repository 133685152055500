import { BaseService } from './base.service';
import { IGemServiceClassificationData } from '../store/PIRRiskTool';


/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class PIRRiskToolService extends BaseService {
    private static _serviceGroupService: PIRRiskToolService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): PIRRiskToolService {
        return (this._serviceGroupService || (this._serviceGroupService = new this('PIRRiskTool')));
    }

    public async GetServiceTreeGemListData(): Promise<IGemServiceClassificationData[]> {
        const { data } = await this.$http.get<IGemServiceClassificationData[]>(`GetServiceGemClassificationList`);
        return data;
    }

    public async GetWorkItemDetails(workitemId: string): Promise<object[]> {
        const { data } = await this.$http.post<object[]>(`GetWorkItemDetails?workitemId=${workitemId}`);
        return data;
    }

    public async CreateWorkItemCopy(inData): Promise<any> {
        const { data } = await this.$http.post(`CreateWorkItemCopy`, inData);
        return data;
    }

    public async GetIDClassificationList(): Promise<any> {
        const { data } = await this.$http.get<any>("GetIDClassificationList");
        return data;
    }
}

export const PIRRiskToolAPI = PIRRiskToolService.Instance;
