import React, { useEffect, useCallback, useState } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { Spinner } from '../../components';
import { HomeTilesTable } from './child-components';
import { isNullOrUndefined } from '../../utils';
import { IApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import { actionCreators, reducer } from '../../store/home-tiles';
import { RoutesConfig } from '../../config/routes.config';

type ServiceGroupProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly history: History }
    & {readonly isAuthenticated: boolean}
    & {readonly isProd: boolean}
    & typeof useParams;


const ServiceGroup: React.FC<ServiceGroupProps> = ({
    isLoading,
    history,
    isAuthenticated,
    serviceGroup,
    startGroupIndex,
    requestServiceGroup,
    changetoggle,
    addUserVisit,
    isProd
}) => {
    useEffect(() => {
        if (isAuthenticated)
            addUserVisit(0, 'Hometiles.tsx_v2');
        }, [isAuthenticated]);
    useEffect(() => {
        requestServiceGroup(0);          
        }, [requestServiceGroup]);

        const onSuccessfulAuth = useCallback((): void => history.push(RoutesConfig.HomeTiles.path), [history]);

        const [ServiceGroup_name, setservicegroup] = useState(serviceGroup);

        const handledChange = async (e, Director, TemapGroup, Group, Service, Level) => {
            changetoggle(e,Director, TemapGroup, Group, Service, Level);            
        };
        const ToggleChange = async (e, Director) => {
            const group: any = [];
            serviceGroup.map((dhT) => {
                if (dhT.directorName === Director) {
                    dhT.IsToggle = !dhT.IsToggle;
                }
                group.push(dhT);

            })
            setservicegroup(group);
    }
    if (isAuthenticated) {
        return (
            <section className='section'>
                <div className='container'>
                    <Spinner isLoading={isLoading} />
                    <HomeTilesTable serviceGroup={serviceGroup}
                        handledChange={(e, Director, TemapGroup, Group, Service, Level) => handledChange(e, Director, TemapGroup, Group, Service, Level)}
                        ToggleChange={(e, Director) => ToggleChange(e, Director)}
                        handleOnSuccess={onSuccessfulAuth}
                        IsProd={isProd}
                    />

                </div>
            </section>
        );
    }
    else {
        return (<div></div>);
    }
};

const mapStateToProps = (state: IApplicationState) => ({
    ...state.serviceGroup,
    isAuthenticated: state.authUser.isAuthenticated,
    isProd: state.authUser.isProd

})

export default connect(mapStateToProps, actionCreators)(ServiceGroup);
