import { BaseService } from './base.service';
import { IPropAllPirData, IPropCommsOnlyPir, IPropSpecialTopicsWishr, IPropAllPirDataReReview,  IPIRAdminLock, IPropFundamentalsSpotlightWishr, IPropActionItemsReviewWishr } from '../store/WIP-SHR';
/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class WIPSHRService extends BaseService {
    private static _serviceGroupService: WIPSHRService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): WIPSHRService {
        return (this._serviceGroupService || (this._serviceGroupService = new this('WIPSHR')));
    }
    public async IsPirRereviewUser(loginUser: string): Promise<boolean> {
        const { data } = await this.$http.get<boolean>(`IsPirRereviewUser?loginUser=${loginUser}`);
        return data;
    }
    public async GetWeeklySHRData(Meetingdate: string): Promise<IPropAllPirData[]> {
        const { data } = await this.$http.get<IPropAllPirData[]>(`GetWeeklySHRData?selectedDate=${Meetingdate}`);
        return data;
    }
    public async GetSpecialTopicsWishrData(Meetingdate: string): Promise<IPropSpecialTopicsWishr[]> {
        const { data } = await this.$http.get<IPropSpecialTopicsWishr[]>(`GetSpecialTopicsWishrData?selectedDate=${Meetingdate}`);
        return data;
    }
    public async GetAllPirReReviewData(): Promise<IPropAllPirDataReReview[]> {
        const { data } = await this.$http.get<IPropAllPirDataReReview[]>("GetAllPirReReviewData");
        return data;
    }
    public async AddPirData(row: IPropAllPirData): Promise<number> {
        const metric = {
            Presenter: row.presenterEdit === undefined || null ? row.presenter : row.presenterEdit,
            Id: row.id,
            ServiceName: row.serviceName,
            ServicesImpacted: row.servicesImpacted,
            Summary: row.summary,
            Ttd: row.ttd,
            Ttm: row.ttm,
            Ttn: row.ttn,
            Tte: row.tte,
            Pir: row.pirEdit === undefined || null ? row.pir : row.pirEdit,
            IsAzure: row.isAzure,
            KpiDate: row.kpiDate,
            UserName: row.userName,
            OccuredRing: row.occuredRingEdit === undefined || null ? row.occuredRing : row.occuredRingEdit,
            PirRereview: row.pirRereview,
            IsAddedToAgenda: row.isAddedToAgenda,
            IsValidPir: row.isValidPir,
            RootCauseCategory: row.rootCauseCategory,
            IncidentId: row.incidentId,
            Resources: row.resources,           
            SharedLearning: row.sharedLearning,
            DisplayOrder: row.displayOrderEdit === null ? null : parseInt(row.displayOrderEdit.toString())
        };
        if (row.id === 0) {
            const { data } = await this.$http.post<number>("AddPirData", metric);
            return data;
        }
        else {
            const { data } = await this.$http.post<number>(`UpdatePirData?selectedDate=${row.id}`, metric);
            return data
        }      
    } 
    public async DeletePirData(id: number, user: string): Promise<number> {
        const { data } = await this.$http.get<number>(`DeletePirData?id=${id}&user=${user}`);
        return data;
    }
    public async EditPirReviewData(row: IPropAllPirData): Promise<number> {
        const metric = {
            Id: row.id,
            ServiceName: row.serviceName,
            ServicesImpacted: row.servicesImpacted,
            Summary: row.summary,
            Ttd: row.ttd,
            Ttm: row.ttm,
            Ttn: row.ttn,
            Tte: row.tte,
            Pir: row.pirEdit,
            IsAzure: row.isAzure,
            KpiDate: row.kpiDate,
            UserName: row.userName,
            OccuredRing: row.occuredRingEdit,
            PirRereview: row.pirRereview,
            IsAddedToAgenda: row.isAddedToAgenda,
            IsValidPir: row.isValidPir,
            RootCauseCategory: row.rootCauseCategory,
            IncidentId: row.incidentId,
            Resources: row.resources,
            SharedLearning: row.sharedLearning

        };       
        const { data } = await this.$http.post<number>("EditPirReviewData", metric);
            return data; 
    }
    public async savePIRMeetingDate(id, KPIDate, user): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateDateForPIR?id=${id}&dateKpi=${KPIDate}&user=${user}`);
        return data;
    }
    public async saveCommsPIRMeetingDate(id, KPIDate, user): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateDateForCommsPIR?id=${id}&dateKpi=${KPIDate}&user=${user}`);
        return data;
    }
    public async saveSpecialTopicsMeetingDate(id, KPIDate, userName): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateDateForSpecialTopics?id=${id}&dateKpi=${KPIDate}&UserName=${userName}`);
        return data;
    }
    public async saveSupportabilityMeetingDate(id, KPIDate): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateDateForSupportability?id=${id}&dateKpi=${KPIDate}`);
        return data;
    }
    public async DeletePirRereviewData(id: number): Promise<number> {
        const { data } = await this.$http.get<number>(`DeletePirRereviewData?id=${id}`);
        return data;
    }
    public async UpdatePirReReviewData(row: IPropAllPirDataReReview): Promise<number> {
        const metric = {
            Id: row.id,
            ServiceName: row.serviceName,
            ServicesImpacted: row.servicesImpacted,
            Summary: row.summary,
            Ttd: row.ttd,
            Ttm: row.ttm,
            Ttn: row.ttn,
            Tte: row.tte,
            Pir: row.pirEdit,
            IsAzure: row.isAzure,
            KpiDate: row.kpiDate,
            UserName: row.userName,  
            IncidentId: row.incidentId,
            DueForReReviewOn: row.dueForReReviewOn,
            AddToAgenda: row.addToAgenda
           

        };
        const { data } = await this.$http.post<number>("UpdatePirReReviewData", metric);
        return data;
    }
    public async AddPirReviewDataToAgenda(row: IPropAllPirDataReReview): Promise<number> {
        const metric = {
            Id: row.id,
            ServiceName: row.serviceName,
            ServicesImpacted: row.servicesImpacted,
            Summary: row.summary,
            Ttd: row.ttd,
            Ttm: row.ttm,
            Ttn: row.ttn,
            Tte: row.tte,
            Pir: row.pirEdit,
            IsAzure: row.isAzure,
            KpiDate: row.kpiDate,
            UserName: row.userName,
            IncidentId: row.incidentId,
            DueForReReviewOn: row.dueForReReviewOnEdit,
            AddToAgenda: row.addToAgenda,
            PIRDataID: row.pirDataID


        };
        const { data } = await this.$http.post<number>("AddPirReviewDataToAgenda", metric);
        return data;
    }

    public async AddorupdateSpecialTopicsWishrData(row: IPropSpecialTopicsWishr): Promise<number> {  
        const metric = {
            Id: row.id,
            Presenter: row.presenterEdit === undefined || null ? row.presenter : row.presenterEdit,
            TopicName: row.topicNameEdit === undefined || null ? row.topicName : row.topicNameEdit,
            TimeNeeded: 0,
            PresentationLink: row.presentationLinkEdit === undefined || null ? row.presentationLink : row.presentationLinkEdit,
            IsTopicApproved: row.isTopicApprovedEdit === undefined || null ? row.isTopicApproved : row.isTopicApprovedEdit,            
            KpiDate: row.kpiDate,
            DisplayOrder: row.displayOrderEdit === null ? null : parseInt(row.displayOrderEdit.toString()),
            UserName: row.userName
        };
        if (row.id === 0) {
            const { data } = await this.$http.post<number>("AddSpecialTopicsWishrData", metric);
            return data;
        }
        else {
            const { data } = await this.$http.post<number>("UpdateSpecialTopicsWishrData", metric);
            return data
        }

    } 
    public async DeleteSpecialTopicsWishrData(id: number, userName: string): Promise<number> {
        const { data } = await this.$http.get<number>(`DeleteSpecialTopicsWishrData?id=${id}&userName=${userName}`);
        return data;
    }
    public async AddrecurrenceForSpecialTopics(recNumber, selectedDate, id, userName: string): Promise<number> {
        const { data } = await this.$http.get<number>(`AddrecurrenceForSpecialTopics?selectedDate=${selectedDate}&recNumber=${recNumber}&id=${id}&userName=${userName}`);
        return data;
    }
    public async DeleterecurrenceForSpecialTopics(recNumber: number, selectedDate, id, userName: string): Promise<number> {
        const { data } = await this.$http.get<number>(`DeleterecurrenceForSpecialTopics?selectedDate=${selectedDate}&recNumber=${recNumber}&id=${id}&userName=${userName}`);
        return data;
    }
    public async GetPirAdminLock(): Promise<IPIRAdminLock[]> {
        const { data } = await this.$http.get<IPIRAdminLock[]>(`GetPirAdminLock`);
        return data;
    }
    public async PostPirAdminLock(lockType: number, lockedWeek: string, lockValue: number, userName: string): Promise<IPIRAdminLock[]> {         
        const { data } = await this.$http.get<IPIRAdminLock[]>(`PostPirAdminLock?lockType=${lockType}&lockedWeek=${lockedWeek}&lockValue=${lockValue}&userName=${userName}`);
        return data;
    }
    public async GetFundamentalsSpotlightWishrData(Meetingdate: string): Promise<IPropFundamentalsSpotlightWishr[]> {
        const { data } = await this.$http.get<IPropFundamentalsSpotlightWishr[]>(`GetFundamentalsSpotlightWishrData?selectedDate=${Meetingdate}`);
        return data;
    }
    public async saveFundamentalsSpotlightMeetingDate(id, KPIDate, userName): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateDateForFundamentalsSpotlight?id=${id}&dateKpi=${KPIDate}&userName=${userName}`);
        return data;
    }
    public async AddorupdateFundamentalsSpotlightWishrData(row: IPropFundamentalsSpotlightWishr): Promise<number> {
        const metric = {
            Id: row.id,
            Presenter: row.presenterEdit === undefined || null ? row.presenter : row.presenterEdit,
            TopicName: row.topicNameEdit === undefined || null ? row.topicName: row.topicNameEdit,
            TimeNeeded: 0,
            PresentationLink: row.presentationLinkEdit === undefined || null ? row.presentationLink : row.presentationLinkEdit,
            IsTopicApproved: row.isTopicApprovedEdit === undefined || null ? row.isTopicApproved : row.isTopicApprovedEdit,
            KpiDate: row.kpiDate,
            DisplayOrder: row.displayOrderEdit === null ? null : parseInt(row.displayOrderEdit.toString()),
            UserName: row.userName
        };
        if (row.id === 0) {
            const { data } = await this.$http.post<number>("AddFundamentalsSpotlightWishrData", metric);
            return data;
        }
        else {
            const { data } = await this.$http.post<number>("UpdateFundamentalsSpotlightWishrData", metric);
            return data
        }
    }
    public async DeleteFundamentalsSpotlightWishrData(id: number, userName: string): Promise<number> {
        const { data } = await this.$http.get<number>(`DeleteFundamentalsSpotlightWishrData?id=${id}&userName=${userName}`);
        return data;
    }    
    public async GetActionItemsReviewWipshrData(Meetingdate: string): Promise<IPropActionItemsReviewWishr[]> {
        const { data } = await this.$http.get<IPropActionItemsReviewWishr[]>(`GetActionItemsReviewWipshrData?selectedDate=${Meetingdate}`);
        return data;
    }    
}

export const WIPSHRAPI = WIPSHRService.Instance;
