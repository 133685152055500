import { BaseService } from './base.service';
import { IDublinAdminLock, IPropAllPirData, IPropSpecialTopicsWishr, IPropActionItemsDublinshr } from '../store/Dublin-SHR';
/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class DublinSHRService extends BaseService {
    private static _serviceGroupService: DublinSHRService;
    private constructor(controllerName: string) {
        super(controllerName);
    }
    public static get Instance(): DublinSHRService {
        return (this._serviceGroupService || (this._serviceGroupService = new this('DublinSHR')));
    }
    public async GetDublinAdminLock(): Promise<IDublinAdminLock[]> {
        const { data } = await this.$http.get<IDublinAdminLock[]>(`GetDublinAdminLock`);
        return data;
    }
    public async PostDublinAdminLock(lockType: number, lockedWeek: string, lockValue: number, userName: string): Promise<IDublinAdminLock[]> {
        const { data } = await this.$http.get<IDublinAdminLock[]>(`PostDublinAdminLock?lockType=${lockType}&lockedWeek=${lockedWeek}&lockValue=${lockValue}&userName=${userName}`);
        return data;
    }
    public async GetDublinActionItemsData(Meetingdate: string): Promise<IPropActionItemsDublinshr[]> {
        const { data } = await this.$http.get<IPropActionItemsDublinshr[]>(`GetDublinActionItemsData?selectedDate=${Meetingdate}`);
        return data;
    }
    public async GetDublinPirData(Meetingdate: string): Promise<IPropAllPirData[]> {
        const { data } = await this.$http.get<IPropAllPirData[]>(`GetDublinPirData?selectedDate=${Meetingdate}`);
        return data;
    }
    public async GetDublinSpecialTopicsData(Meetingdate: string): Promise<IPropSpecialTopicsWishr[]> {
        const { data } = await this.$http.get<IPropSpecialTopicsWishr[]>(`GetDublinSpecialTopicsData?selectedDate=${Meetingdate}`);
        return data;
    }
    public async saveSpecialTopicsMeetingDate(id, KPIDate, userName): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateDateForDublinSpecialTopics?id=${id}&dateKpi=${KPIDate}&UserName=${userName}`);
        return data;
    }
    public async AddorupdateDublinSpecialTopicsData(row: IPropSpecialTopicsWishr): Promise<number> {
        const metric = {
            Id: row.id,
            Presenter: row.presenterEdit === undefined || null ? row.presenter : row.presenterEdit,
            TopicName: row.topicNameEdit === undefined || null ? row.topicName : row.topicNameEdit,
            TimeNeeded: 0,
            PresentationLink: row.presentationLinkEdit === undefined || null ? row.presentationLink : row.presentationLinkEdit,
            IsTopicApproved: row.isTopicApprovedEdit === undefined || null ? row.isTopicApproved : row.isTopicApprovedEdit,
            KpiDate: row.kpiDate,
            DisplayOrder: row.displayOrderEdit === null ? null : parseInt(row.displayOrderEdit.toString()),
            UserName: row.userName
        };
        if (row.id === 0) {
            const { data } = await this.$http.post<number>("AddDublinSpecialTopicsData", metric);
            return data;
        }
        else {
            const { data } = await this.$http.post<number>("UpdateDublinSpecialTopicsData", metric);
            return data
        }

    }
    public async DeleteDublinSpecialTopicsData(id: number, userName: string): Promise<number> {
        const { data } = await this.$http.get<number>(`DeleteDublinSpecialTopicsData?id=${id}&userName=${userName}`);
        return data;
    }
    public async AddDublinPirData(row: IPropAllPirData): Promise<number> {
        const metric = {
            Presenter: row.presenterEdit === undefined || null ? row.presenter : row.presenterEdit,
            Id: row.id,
            ServiceName: row.serviceName,
            ServicesImpacted: row.servicesImpacted,
            Summary: row.summary,
            Ttd: row.ttd,
            Ttm: row.ttm,
            Ttn: row.ttn,
            Tte: row.tte,
            Pir: row.pirEdit === undefined || null ? row.pir : row.pirEdit,
            IsAzure: row.isAzure,
            KpiDate: row.kpiDate,
            UserName: row.userName,
            OccuredRing: row.occuredRingEdit === undefined || null ? row.occuredRing : row.occuredRingEdit,
            PirRereview: row.pirRereview,
            IsAddedToAgenda: row.isAddedToAgenda,
            IsValidPir: row.isValidPir,
            RootCauseCategory: row.rootCauseCategory,
            IncidentId: row.incidentId,
            Resources: row.resources,
            SharedLearning: row.sharedLearning,
            DisplayOrder: row.displayOrderEdit === null ? null : parseInt(row.displayOrderEdit.toString())
        };
        if (row.id === 0) {
            const { data } = await this.$http.post<number>("AddDublinPirData", metric);
            return data;
        }
        else {
            const { data } = await this.$http.post<number>(`UpdateDublinPirData?selectedDate=${row.id}`, metric);
            return data
        }

    }
    public async DeleteDublinPirData(id: number, user: string): Promise<number> {
        const { data } = await this.$http.get<number>(`DeleteDublinPirData?id=${id}&user=${user}`);
        return data;
    }
    public async savePIRMeetingDate(id, KPIDate, user): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateDateForDublinPIR?id=${id}&dateKpi=${KPIDate}&user=${user}`);
        return data;
    }
}

export const DublinSHRAPI = DublinSHRService.Instance;
