import { DailyDataApi, ServiceGroupApi, WeeklyAPI, MonthlyAPI, CommonAPI} from '../../api';
import { IAppThunkAction, ReduxAction } from '../';
import {
    ActionType,
    IDailyData,
    IDropdownOption,
    IViewDetails,
    IAllViewDetails,
    IMultiViewDetails,
    IColorScheme,
    IBulkViewDetails,
    IServiceNavInfo,
    IWeeklyReport,
    IYear,
    IPropDraft,
    PropBgm,
    PropExternalLinks,
    PropKpiComments,
    IMonthlyReport,
    IMSERAdditionalInfo,
    IViewConfigColorLogics, IViewConfigNoofDecimals,
    IViewConfigColorSchemes,
    HmMetric,
    LookupData,
    PropFleetutilizationHMList,
    PropFleetutilizationHM,
    DateLabels,
    DailyFleet,
    IServices,
    WeeklyDropdownOption,
    PropMetricGroups,
    PropMetricType,
    PropEnvironment,
    PropDataSource,
    PropAuthenticationType,
    PropMetricDataSource,
    PropServiceDetail,
    PropTarget,
    IFleetcolorSchemes
} from './types';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../utils';
import moment from 'moment';
import { IServiceGroup, IPropCalender } from '../home-tiles';
import { IApplicationState } from '../../store';
import { toast } from "react-toastify";
import { object } from 'prop-types';

let dailyDataRaw: IDailyData[] = [];
let reachabilityDataRaw: IDailyData[] = [];
let fleetheatMapDataRaw: PropFleetutilizationHM[] = [];

export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),

    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }

    },
    seturlparamempty: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SETURL_PARAM_EMPTY
        });
    },
    setselectedServiceIdempty: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_SELECTEDSERVICEID_EMPTY
        });
    },
    setViewMetricDataSourceEnabled: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.METRICDATASOURCE_VIEW_ENABLE
        });
    },
    setViewMetricDataSourceDisabled: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.METRICDATASOURCE_VIEW_DISABLE
        });
    },
    setAddMetricDataSourceEnabled: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.METRICDATASOURCE_ADD_ENABLE
        });
    },
    setAddMetricDataSourceDisabled: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.METRICDATASOURCE_ADD_DISABLE
        });
    },
    changeshowdrilldown: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let drilldown=getState().serviceHealth.drilldown;
        dispatch({
            drilldown: !drilldown,
            type: ActionType.SET_DRILLDOWN_VALUE
        });
    },
    requestServiceGroupNav: (currentServiceId: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let serviceGroupRaw = [];
        let servicelist = getState().serviceGroup.serviceGroup;
        const previousService = [];
        const currentService = [];
        const nextService = [];
        let LoginuserId;
        if (isArrayWithLength(servicelist)) {
            servicelist.map((obj) => {
                if (currentServiceId === parseInt(obj.serviceId)) { // add current service 
                    serviceGroupRaw.push(obj);
                }
                else if (obj.IServiceGroupChecked) { // == add services only when they are active in Home page
                    serviceGroupRaw.push(obj);
                }
            })
            LoginuserId = getState().authUser.login;
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            for (var i = 0; i < serviceGroupRaw.length; i++) {
                if (parseInt(serviceGroupRaw[i].serviceId) === currentServiceId) {
                    const pObj = {} as IServiceNavInfo;
                    pObj.serviceId = (i > 0) ? parseInt(serviceGroupRaw[i - 1].serviceId) : 0;
                    pObj.serviceName = (i > 0) ? serviceGroupRaw[i - 1].serviceName : '';
                    previousService.push(pObj);
                    const cObj = {} as IServiceNavInfo;
                    cObj.serviceId = parseInt(serviceGroupRaw[i].serviceId);
                    cObj.serviceName = serviceGroupRaw[i].serviceName;
                    currentService.push(cObj);
                    const nObj = {} as IServiceNavInfo;
                    nObj.serviceId = ((serviceGroupRaw.length - 1 >= i + 1)) ? parseInt(serviceGroupRaw[i + 1].serviceId) : 0;
                    nObj.serviceName = ((serviceGroupRaw.length - 1 >= i + 1)) ? serviceGroupRaw[i + 1].serviceName : '';
                    nextService.push(nObj);
                }
            }
            if (!isArrayWithLength(currentService)) {
                const pObj = {} as IServiceNavInfo;
                pObj.serviceId =  parseInt(serviceGroupRaw[0].serviceId) ;
                pObj.serviceName =  serviceGroupRaw[0].serviceName ;
                previousService.push(pObj);
                const cObj = {} as IServiceNavInfo;
                cObj.serviceId = parseInt(serviceGroupRaw[1].serviceId);
                cObj.serviceName = serviceGroupRaw[1].serviceName;
                currentService.push(cObj);
                const nObj = {} as IServiceNavInfo;
                nObj.serviceId =  parseInt(serviceGroupRaw[2].serviceId);
                nObj.serviceName =  serviceGroupRaw[2].serviceName;
                nextService.push(nObj);
            }
            if (isArrayWithLength(currentService)) {
                dispatch({
                    previousService,
                    currentService,
                    nextService,
                    LoginuserId,
                    selectedServiceId: currentService[0].serviceId,
                    type: ActionType.CURRENT_SERVICE_NAVIGATOR
                });
            }
        }
        else {
            ServiceGroupApi.getServiceGroupAsyn(0)
                .then((serviceGroupRaw: IServiceGroup[]) => {
                    LoginuserId = getState().authUser.login;
                    LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
                    if (isArrayWithLength(serviceGroupRaw)) {
                        for (var i = 0; i < serviceGroupRaw.length; i++) {
                            if (parseInt(serviceGroupRaw[i].serviceId) === currentServiceId) {
                                const pObj = {} as IServiceNavInfo;
                                pObj.serviceId = (i > 0) ? parseInt(serviceGroupRaw[i - 1].serviceId) : 0;
                                pObj.serviceName = (i > 0) ? serviceGroupRaw[i - 1].serviceName : '';
                                previousService.push(pObj);
                                const cObj = {} as IServiceNavInfo;
                                cObj.serviceId = parseInt(serviceGroupRaw[i].serviceId);
                                cObj.serviceName = serviceGroupRaw[i].serviceName;
                                currentService.push(cObj);
                                const nObj = {} as IServiceNavInfo;
                                nObj.serviceId = ((serviceGroupRaw.length - 1 >= i + 1)) ? parseInt(serviceGroupRaw[i + 1].serviceId) : 0;
                                nObj.serviceName = ((serviceGroupRaw.length - 1 >= i + 1)) ? serviceGroupRaw[i + 1].serviceName : '';
                                nextService.push(nObj);
                            }
                        }
                    }
                    if (isArrayWithLength(currentService)) {
                        dispatch({
                            previousService,
                            currentService,
                            nextService,
                            LoginuserId,
                            selectedServiceId: currentService[0].serviceId,
                            type: ActionType.CURRENT_SERVICE_NAVIGATOR
                        });
                    }
                });
        }

    },

    setColorSchemevalue: (option): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let view = getState().serviceHealth.ConfigurationView;
        view.colorSchemeId = option.id;
        const Disableviewconfigbutton = DisableViewconfigUpdatebutton(view);
        dispatch({
            option: option,
            ConfigurationView: view,
            Disableviewconfigbutton: Disableviewconfigbutton,
            type: ActionType.COLOR_SCHEME_SELECT
        });
    },
    setColorlogic: (option): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let view = getState().serviceHealth.ConfigurationView;
        view.coloringLogicId = option.id;
        const Disableviewconfigbutton = DisableViewconfigUpdatebutton(view);
        dispatch({
            option: option,
            ConfigurationView: view,
            Disableviewconfigbutton: Disableviewconfigbutton,
            type: ActionType.COLORLOGIC_SELECT
        });
    },
    setnoofdecimalsvalue: (option): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let view = getState().serviceHealth.ConfigurationView;
        view.noOfDecimalId = option.id;
        const Disableviewconfigbutton = DisableViewconfigUpdatebutton(view);
        dispatch({
            option: option,
            ConfigurationView: view,
            Disableviewconfigbutton: Disableviewconfigbutton,
            type: ActionType.NOOFDECIMALS_SELECT
        });
    },
    setDisplayvolumevalue: (option): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let view = getState().serviceHealth.ConfigurationView;
        view.volumeType = option.id;
        const Disableviewconfigbutton = DisableViewconfigUpdatebutton(view);
        dispatch({
            option: option,
            ConfigurationView: view,
            Disableviewconfigbutton: Disableviewconfigbutton,
            type: ActionType.VOLUMEDISPLAY_SELECT
        });
    },    
    ChangeNewView: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const state = getState();
        let dailyViewList = state.serviceHealth.dailyViewList;
        let allview = state.serviceHealth.AllviewsData;
        let selectedViewDropdownOption = state.serviceHealth.selectedViewDropdownOption;
        let colorschemeval = '';
        let viewdetailsdata = [];
        let ColorshemesData = state.serviceHealth.ColorshemesData;
        let serviceid = 0;

        if (!isNullOrUndefined(state.serviceHealth.currentService)) {
            serviceid = state.serviceHealth.currentService[0].serviceId;
        }
        else {
            const search = window.location.search;
            const params = new URLSearchParams(search);
            serviceid = parseInt(params.get('serviceid'));
        }
        
        if (selectedViewDropdownOption != null || selectedViewDropdownOption != undefined) {
            allview.map((obj) => {
                if (obj.viewId === selectedViewDropdownOption.value) {
                    viewdetailsdata.push(obj);
                }
            })
        }
        for (let i = 0; i < ColorshemesData.length; i++) {
            if (e === true) {
                if (ColorshemesData[i].id === 3) {
                    colorschemeval = ColorshemesData[i].name;

                }
            }
            else if (viewdetailsdata.length > 0) {
                if (ColorshemesData[i].id === viewdetailsdata[0].colorSchemeId) {
                    colorschemeval = ColorshemesData[i].name;
                }
            }

        }
        if (e) {
            let view = {
                'authenticationType': 'AAD AppId',
                'ccEmailList': '',
                'colorLogicText': '',
                'colorSchemeId': 3,
                'coloringLogicId': 1,
                'drillDownQuery': '',
                'fixedColQuery': '',
                'hasVal2': false,
                'isMonthlyAvailability': false,
                'isMserPresentationView': false,
                'isMserView': false,
                'isReachability': false,
                'kustoDbName': '',
                'kustoServer': '',
                'noOfDecimalId': 4,
                'noOfDecimalText': '',
                'serviceId': serviceid,
                'serviceName': '',
                'source': 'Kusto',
                'toEmailList': '',
                'totalsLabel': '',
                'updatedBy': '',
                'updatedOn': '',
                'val1Cap': '',
                'val1Label': '',
                'val2Cap': '',
                'val2Label': '',
                'valQuery': '',
                'viewId': 0,
                'viewName': '',
                'volumeType': "k",
                'RenameViewLabel': '',
                'tsg': ''
            }
            dispatch({
                Newview: e,
                ConfigurationView: view,
                Disableviewconfigbutton: true,
                coloringLogicValue: { 'id': 1, 'name':'Aggregate volume'},
                noOfDecimalValue: { 'id': 4,'name': "Auto" },
                authenticationTypeValue: {'id': 'AAD AppId', 'name': 'AAD AppId'},
                volumeDisplayTypeValue: { 'id': 'k', 'name': 'Thousands (K)' },
                chkMonltyAvailability: false,
                chkMSER: false,
                checkedReachability: false,
                TSGValue: null,
                selectedViewDropdownOption: { 'value': 0, 'lable': 'New' },
                colorSchemeValue: { 'id': 3, 'name': colorschemeval },
                type: ActionType.CREATE_NEW_VIEW_CHANGE
            });
        }
        else {
            const Disableviewconfigbutton = (isArrayWithLength(viewdetailsdata) ? DisableViewconfigUpdatebutton(viewdetailsdata[0]):true);
            dispatch({
                Newview: e,
                ConfigurationView: (viewdetailsdata.length > 0) ? viewdetailsdata[0] : null,
                Disableviewconfigbutton: Disableviewconfigbutton,
                coloringLogicValue: (viewdetailsdata.length > 0) ? (viewdetailsdata[0].coloringLogicId != null && viewdetailsdata[0].coloringLogicId != '') ? { 'id': viewdetailsdata[0].coloringLogicId, 'name': viewdetailsdata[0].colorLogicText } : [] : [],
                noOfDecimalValue: (viewdetailsdata.length > 0) ? (viewdetailsdata[0].noOfDecimalId != null && viewdetailsdata[0].noOfDecimalId != '') ? { 'id': viewdetailsdata[0].noOfDecimalId, 'name': viewdetailsdata[0].noOfDecimalText } : [] : [],
                colorSchemeValue: (viewdetailsdata.length > 0) ? (viewdetailsdata[0].colorSchemeId != null && viewdetailsdata[0].colorSchemeId != '') ? { 'id': viewdetailsdata[0].colorSchemeId, 'name': colorschemeval } : [] : [],
                authenticationTypeValue: (viewdetailsdata.length > 0) ? (viewdetailsdata[0].authenticationType != null && viewdetailsdata[0].authenticationType != '') ? { 'id': viewdetailsdata[0].authenticationType, 'name': viewdetailsdata[0].authenticationType } : [] : [],
                volumeDisplayTypeValue: (viewdetailsdata.length > 0) ? (viewdetailsdata[0].volumeType != null && viewdetailsdata[0].volumeType != '') ? (viewdetailsdata[0].volumeType === 'd' ? { 'id': 'd', 'name': 'Default (K/M)' } : (viewdetailsdata[0].volumeType === 'k' ? { 'id': 'k', 'name': 'Thousands(K)' } : { 'id': 'm', 'name': 'Millions(M)' })) : [] : [],
                chkMonltyAvailability: (viewdetailsdata.length > 0) ? viewdetailsdata[0].isMonthlyAvailability : false,
                chkMSER: (viewdetailsdata.length > 0) ? viewdetailsdata[0].isMserView : false,
                TSGValue: (viewdetailsdata.length > 0) ? viewdetailsdata[0].tsg : null,
                selectedViewDropdownOption: selectedViewDropdownOption,
                checkedReachability: (viewdetailsdata.length > 0) ?viewdetailsdata[0].isReachability : false,
                type: ActionType.CREATE_NEW_VIEW_CHANGE
            });
        }

    },
    SetViewconfigvalues: (e, ConfigurationView): IAppThunkAction<ReduxAction> => (dispatch) => {
        const { name, value, type, checked } = e.target;
        let view = setview(ConfigurationView);
        if (value === undefined) {
            view[name] = '';
        }
        else {
            view[name] = value;
        }

        const Disableviewconfigbutton = DisableViewconfigUpdatebutton(view);
        dispatch({
            Disableviewconfigbutton: Disableviewconfigbutton,
            view,
            type: ActionType.SET_DISABLE_VIEWCONFIG_BUTTON
        });
    },
    UpdateLabelValues: (e, ConfigurationView): IAppThunkAction<ReduxAction> => (dispatch) => {
        const { name, value, type } = e.target;
        let view = setview(ConfigurationView);
        if (name === 'Val1') {
            view.val1Label = value;
        }
        else if (name === 'Val2') {
            view.val2Label = value;
        }
        dispatch({
            view,
            type: ActionType.SET_DAILYDATA_UPDATELABELVALUES
        })
    },
    UpdateViewName: (e, ConfigurationView): IAppThunkAction<ReduxAction> => (dispatch) => {
        const { name, value, type } = e.target;
        let view = setview(ConfigurationView);
        if (name === 'renameView') {
            view.RenameViewLabel = value;
        }
        dispatch({
            view,
            type: ActionType.SET_VIEWNAME_UPDATEVALUE
        })
    },
    setAuthenticationTypevalue: (option): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let view = getState().serviceHealth.ConfigurationView;
        view.authenticationType = option.id;
        const Disableviewconfigbutton=DisableViewconfigUpdatebutton(view);
        dispatch({
            option: option,
            ConfigurationView: view,
            Disableviewconfigbutton: Disableviewconfigbutton,
            type: ActionType.AUTHENTOCATIONTYPE_SELECT
        });
    },
    // Function to toggle MSER Comments // 
    toggleMSERCommentsView: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let state = getState();
        let showMSERComments = state.serviceHealth.showMSERComments;
        showMSERComments = !showMSERComments;
        dispatch({
            showMSERComments: showMSERComments,
            type: ActionType.SET_MSERCOMMENTS
        });
    },

    onFilterYellowDailyData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let dailyDataRedYellow: IDailyData[] = [];
        let reachabilityDataRedYellow: IDailyData[] = [];
        let selectedTab = getState().serviceHealth.activeDailyDataTab;
        let checkedFilterYellowDailyData = getState().serviceHealth.checkedFilterYellowDailyData;
        let checkedFilterRedDailyData = getState().serviceHealth.checkedFilterRedDailyData;
        if (isArrayWithLength(dailyDataRaw) || isArrayWithLength(reachabilityDataRaw)) {
            checkedFilterYellowDailyData = !checkedFilterYellowDailyData;
            if (checkedFilterYellowDailyData && !checkedFilterRedDailyData) {
                if (selectedTab === 'heatmap') {
                    dailyDataRaw.map((obj: IDailyData) => {
                        if (obj.isYellowRow && !obj.isTotalVolume && obj.key !== 'Daily Agg.') {
                            dailyDataRedYellow.push(obj);
                        }
                    });
                    dispatch({
                        dailyData: dailyDataRedYellow,
                        ReachabilityData: reachabilityDataRaw,
                        checked: checkedFilterYellowDailyData,
                        type: ActionType.DAILY_FILTER_YELLOW
                    });
                }
                if (selectedTab === 'reachability') {
                    reachabilityDataRaw.map((obj: IDailyData) => {
                        if (obj.isYellowRow && !obj.isTotalVolume && obj.key !== 'Daily Agg.') {
                            reachabilityDataRedYellow.push(obj);
                        }
                    });
                    dispatch({
                        dailyData: dailyDataRaw,
                        ReachabilityData: reachabilityDataRedYellow,
                        checked: checkedFilterYellowDailyData,
                        type: ActionType.DAILY_FILTER_YELLOW
                    });
                }
            }
            else if (!checkedFilterYellowDailyData && checkedFilterRedDailyData) {
                if (selectedTab === 'heatmap') {
                    dailyDataRaw.map((obj: IDailyData) => {
                        if (obj.isRedRow && !obj.isTotalVolume && obj.key !== 'Daily Agg.') {
                            dailyDataRedYellow.push(obj);
                        }
                    });
                    dispatch({
                        dailyData: dailyDataRedYellow,
                        ReachabilityData: reachabilityDataRaw,
                        checked: checkedFilterYellowDailyData,
                        type: ActionType.DAILY_FILTER_YELLOW
                    });
                }
                if (selectedTab === 'reachability') {
                    reachabilityDataRaw.map((obj: IDailyData) => {
                        if (obj.isRedRow && !obj.isTotalVolume && obj.key !== 'Daily Agg.') {
                            reachabilityDataRedYellow.push(obj);
                        }
                    });
                    dispatch({
                        dailyData: dailyDataRaw,
                        ReachabilityData: reachabilityDataRedYellow,
                        checked: checkedFilterYellowDailyData,
                        type: ActionType.DAILY_FILTER_YELLOW
                    });
                }
            }
            else if (checkedFilterYellowDailyData && checkedFilterRedDailyData) {
                if (selectedTab === 'heatmap') {
                    dailyDataRaw.map((obj: IDailyData) => {
                        if ((obj.isYellowRow || obj.isRedRow) && !obj.isTotalVolume && obj.key !== 'Daily Agg.') {
                            dailyDataRedYellow.push(obj);
                        }
                    });
                    dispatch({
                        dailyData: dailyDataRedYellow,
                        ReachabilityData: reachabilityDataRaw,
                        checked: checkedFilterYellowDailyData,
                        type: ActionType.DAILY_FILTER_YELLOW
                    });
                }
                if (selectedTab === 'reachability') {
                    reachabilityDataRaw.map((obj: IDailyData) => {
                        if ((obj.isYellowRow || obj.isRedRow) && !obj.isTotalVolume && obj.key !== 'Daily Agg.') {
                            reachabilityDataRedYellow.push(obj);
                        }
                    });
                    dispatch({
                        dailyData: dailyDataRaw,
                        ReachabilityData: reachabilityDataRedYellow,
                        checked: checkedFilterYellowDailyData,
                        type: ActionType.DAILY_FILTER_YELLOW
                    });
                }
            }
            else if (!checkedFilterYellowDailyData && !checkedFilterRedDailyData) {
                dispatch({
                    dailyData: dailyDataRaw,
                    ReachabilityData: reachabilityDataRaw,
                    checked: checkedFilterYellowDailyData,
                    type: ActionType.DAILY_FILTER_YELLOW
                });
            }
        }
    },
    onFilterRedDailyData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let dailyDataRedYellow: IDailyData[] = [];
        let reachabilityDataRedYellow: IDailyData[] = [];
        let selectedTab = getState().serviceHealth.activeDailyDataTab;
        let checkedFilterYellowDailyData = getState().serviceHealth.checkedFilterYellowDailyData;
        let checkedFilterRedDailyData = getState().serviceHealth.checkedFilterRedDailyData;
        if (isArrayWithLength(dailyDataRaw) || isArrayWithLength(reachabilityDataRaw)) {
            checkedFilterRedDailyData = !checkedFilterRedDailyData;
            if (checkedFilterRedDailyData && !checkedFilterYellowDailyData) {
                if (selectedTab === 'heatmap') {
                    dailyDataRaw.map((obj: IDailyData) => {
                        if (obj.isRedRow && !obj.isTotalVolume && obj.key !== 'Daily Agg.') {
                            dailyDataRedYellow.push(obj);
                        }
                    });
                    dispatch({
                        dailyData: dailyDataRedYellow,
                        ReachabilityData: reachabilityDataRaw,
                        checked: checkedFilterRedDailyData,
                        type: ActionType.DAILY_FILTER_RED
                    });
                }
                if (selectedTab === 'reachability') {
                    reachabilityDataRaw.map((obj: IDailyData) => {
                        if (obj.isRedRow && !obj.isTotalVolume && obj.key !== 'Daily Agg.') {
                            reachabilityDataRedYellow.push(obj);
                        }
                    });
                    dispatch({
                        dailyData: dailyDataRaw,
                        ReachabilityData: reachabilityDataRedYellow,
                        checked: checkedFilterRedDailyData,
                        type: ActionType.DAILY_FILTER_RED
                    });
                }
            }
            else if (!checkedFilterRedDailyData && checkedFilterYellowDailyData) {
                if (selectedTab === 'heatmap') {
                    dailyDataRaw.map((obj: IDailyData) => {
                        if (obj.isYellowRow && !obj.isTotalVolume && obj.key !== 'Daily Agg.') {
                            dailyDataRedYellow.push(obj);
                        }
                    });
                    dispatch({
                        dailyData: dailyDataRedYellow,
                        ReachabilityData: reachabilityDataRaw,
                        checked: checkedFilterRedDailyData,
                        type: ActionType.DAILY_FILTER_RED
                    });
                }
                if (selectedTab === 'reachability') {
                    reachabilityDataRaw.map((obj: IDailyData) => {
                        if (obj.isYellowRow && !obj.isTotalVolume && obj.key !== 'Daily Agg.') {
                            reachabilityDataRedYellow.push(obj);
                        }
                    });
                    dispatch({
                        dailyData: dailyDataRaw,
                        ReachabilityData: reachabilityDataRedYellow,
                        checked: checkedFilterRedDailyData,
                        type: ActionType.DAILY_FILTER_RED
                    });
                }
            }
            else if (checkedFilterRedDailyData && checkedFilterYellowDailyData) {
                if (selectedTab === 'heatmap') {
                    dailyDataRaw.map((obj: IDailyData) => {
                        if ((obj.isRedRow || obj.isYellowRow) && !obj.isTotalVolume && obj.key !== 'Daily Agg.') {
                            dailyDataRedYellow.push(obj);
                        }
                    });
                    dispatch({
                        dailyData: dailyDataRedYellow,
                        ReachabilityData: reachabilityDataRaw,
                        checked: checkedFilterRedDailyData,
                        type: ActionType.DAILY_FILTER_RED
                    });
                }
                if (selectedTab === 'reachability') {
                    reachabilityDataRaw.map((obj: IDailyData) => {
                        if ((obj.isRedRow || obj.isYellowRow) && !obj.isTotalVolume && obj.key !== 'Daily Agg.') {
                            reachabilityDataRedYellow.push(obj);
                        }
                    });
                    dispatch({
                        dailyData: dailyDataRaw,
                        ReachabilityData: reachabilityDataRedYellow,
                        checked: checkedFilterRedDailyData,
                        type: ActionType.DAILY_FILTER_RED
                    });
                }
            }
            else if (!checkedFilterRedDailyData && !checkedFilterYellowDailyData) {
                dispatch({
                    dailyData: dailyDataRaw,
                    ReachabilityData: reachabilityDataRaw,
                    checked: checkedFilterRedDailyData,
                    type: ActionType.DAILY_FILTER_RED
                });
            }
        }
    },
    onFilterYellowFleetData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let fleetDataRedYellow: PropFleetutilizationHM[] = [];
        let flag = false;
        let checkedFilterYellowFleetData = getState().serviceHealth.checkedFilterYellowFleetData;
        let checkedFilterRedFleetData = getState().serviceHealth.checkedFilterRedFleetData;
        if (isArrayWithLength(fleetheatMapDataRaw)) {
            checkedFilterYellowFleetData = !checkedFilterYellowFleetData;
            checkedFilterRedFleetData = false;
            if (checkedFilterYellowFleetData) {
                fleetheatMapDataRaw.map((obj: PropFleetutilizationHM) => {
                    flag = false;
                    if (obj.color === "#ffe599") { //"Y"
                        fleetDataRedYellow.push(obj);
                    }
                    else {
                        obj.dailyFleetHM.map((item: DailyFleet) => {
                            if (item.color === "#ffe599" && !flag) {
                                fleetDataRedYellow.push(obj);
                                flag = true;
                            }
                        });
                    }
                });
                dispatch({
                    fleetheatmapData: fleetDataRedYellow,
                    checked: checkedFilterYellowFleetData,
                    checkedFilterRedFleetData: checkedFilterRedFleetData,
                    type: ActionType.FLEET_FILTER_YELLOW
                });
            }
            else {
                dispatch({
                    fleetheatmapData: fleetheatMapDataRaw,
                    checked: checkedFilterYellowFleetData,
                    checkedFilterRedFleetData: checkedFilterRedFleetData,
                    type: ActionType.FLEET_FILTER_YELLOW
                });
            }
        }
    },
    onFilterRedFleetData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let fleetDataRedYellow: PropFleetutilizationHM[] = [];
        let flag = false;
        let checkedFilterYellowFleetData = getState().serviceHealth.checkedFilterYellowFleetData;
        let checkedFilterRedFleetData = getState().serviceHealth.checkedFilterRedFleetData;
        if (isArrayWithLength(fleetheatMapDataRaw)) {
            checkedFilterRedFleetData = !checkedFilterRedFleetData;
            checkedFilterYellowFleetData = false;
            if (checkedFilterRedFleetData) {
                fleetheatMapDataRaw.map((obj: PropFleetutilizationHM) => {
                    flag = false;
                    if (obj.color === "#f3afa1") { //"R"
                        fleetDataRedYellow.push(obj);
                    }
                    else {
                        obj.dailyFleetHM.map((item: DailyFleet) => {
                            if (item.color === "#f3afa1" && !flag) {
                                fleetDataRedYellow.push(obj);
                                flag = true;
                            }
                        });
                    }
                });
                dispatch({
                    fleetheatmapData: fleetDataRedYellow,
                    checked: checkedFilterRedFleetData,
                    checkedFilterYellowFleetData: checkedFilterYellowFleetData,
                    type: ActionType.FLEET_FILTER_RED
                });
            }
            else {
                dispatch({
                    fleetheatmapData: fleetheatMapDataRaw,
                    checked: checkedFilterRedFleetData,
                    checkedFilterYellowFleetData: checkedFilterYellowFleetData,
                    type: ActionType.FLEET_FILTER_RED
                });
            }
        }
    },
    DailyDataTabstogglerechabilityData: (tab): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let Currentview = getState().serviceHealth.ConfigurationView;
        dispatch({
            tab: tab,
            ConfigurationView: Currentview,
            type: ActionType.SET_DAILYDATA_ACTIVETAB
        })
    },
    DailyDataTabstoggleData: (tab): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let state = getState();
        let activeDailyDataTab = state.serviceHealth.activeDailyDataTab;
        //let allviews = state.serviceHealth.AllviewsData;
        let allviews = state.serviceHealth.AllviewsData;
        let Currentview = state.serviceHealth.ConfigurationView;
        let selectedViewDropdownOption = state.serviceHealth.selectedViewDropdownOption;
        let SelectedView = state.serviceHealth.SelectedView;
        let ReachabilitySelectedView = state.serviceHealth.ReachabilitySelectedView;
        let ColorshemesData = state.serviceHealth.ColorshemesData;
        const dailyViewList = [];
        const viewdetailsdata = [];
        let view = null;
        //SET VIEW Dropdown based ont the tab selection.
        if (tab === 'reachability' && activeDailyDataTab === 'heatmap') {
            allviews = state.serviceHealth.AllviewsData;
        }

        if (activeDailyDataTab !== tab) {
            if (isArrayWithLength(allviews)) {
                allviews.map((item: IViewDetails) => {
                    const lstObj = {} as IDropdownOption;
                    lstObj.value = parseInt(item.viewId);
                    lstObj.label = item.viewName;
                    if (dailyViewList.indexOf(lstObj.value) === -1) {
                        if (tab === 'heatmap') {
                            if (!item.isReachability) {
                                viewdetailsdata.push(item);
                                dailyViewList.push(lstObj)
                            }
                        }
                        if (tab === 'reachability') {
                            if (item.isReachability) {
                                viewdetailsdata.push(item);
                                dailyViewList.push(lstObj)
                            }
                        }
                        else if (tab === 'ViewConfiguration') {
                            viewdetailsdata.push(item);
                            dailyViewList.push(lstObj)
                        }
                    }

                });
            }
            dispatch({
                viewdetailsdata,
                dailyViewList,
                type: ActionType.SET_VIEW_DROPDOWN_BASEDON_TAB_SELECTION
            });
            if (tab === 'heatmap' || tab === 'reachability' || tab === 'ViewConfiguration') {
                if (isArrayWithLength(allviews)) {
                    let viewid = 0;
                    let viewname = isNullOrUndefined(selectedViewDropdownOption) ? '' : selectedViewDropdownOption.label;
                    const Dailyviews = [];
                    const Reachabilityview = [];
                    let PreviousViewId = (tab === 'heatmap' || tab === 'ViewConfiguration') ? (isArrayWithLength(SelectedView) ? SelectedView[0].viewId : 0) : (isArrayWithLength(ReachabilitySelectedView)) ? (ReachabilitySelectedView[0].viewId) : 0;
                    allviews.map((obj) => {
                        if (tab === 'heatmap') {
                            if (!obj.isReachability) {
                                Dailyviews.push(obj);
                                if (!isNullOrUndefined(selectedViewDropdownOption) && (parseInt(obj.viewId) === selectedViewDropdownOption.value)) {
                                    viewid = obj.viewId;
                                }
                            }
                            else {
                                Reachabilityview.push(obj);
                            }
                        }
                        else if (tab === 'reachability') {
                            if (obj.isReachability) {
                                Dailyviews.push(obj);
                                Reachabilityview.push(obj);
                                if (!isNullOrUndefined(selectedViewDropdownOption) && (parseInt(obj.viewId) === selectedViewDropdownOption.value)) {
                                    viewid = obj.viewId;
                                }
                            }
                        }
                        else {
                            if (!isNullOrUndefined(selectedViewDropdownOption) && (parseInt(obj.viewId) === selectedViewDropdownOption.value)) {
                                viewid = obj.viewId;
                            }
                            Dailyviews.push(obj);
                        }
                    })
                    if (tab === 'heatmap' && activeDailyDataTab === 'reachability') {
                        if (!isArrayWithLength(Reachabilityview)) {
                            viewid = isArrayWithLength(SelectedView) ? SelectedView[0].viewId : 0;
                        }
                        else {

                            viewid = 0;
                        }

                    }
                    if ((tab === 'reachability' && activeDailyDataTab === 'heatmap') || (tab === 'heatmap' && activeDailyDataTab === 'reachability') ) {
                        viewid = 0;
                    }
                    if (tab === 'reachability' && activeDailyDataTab === 'ViewConfiguration') {
                        viewid = 0;
                    }
                    let finalview = [];
                    if (viewid === 0 || ((!isNullOrUndefined(Currentview)) && Currentview.viewId === 0)) { // ========= If view is not matches then set the first object of Viewslist =====================//
                        if (isArrayWithLength(Dailyviews)) {
                            viewid = Dailyviews[0].viewId;
                            viewname = Dailyviews[0].viewName;
                            finalview.push({ 'value': viewid, 'label': viewname });
                        }

                    }
                    else if ((((tab === 'ViewConfiguration' && activeDailyDataTab === 'heatmap') || (tab === 'heatmap' && activeDailyDataTab === 'ViewConfiguration') || (tab === 'ViewConfiguration' && activeDailyDataTab === 'Fleetutilizationheatmap')) && viewid === PreviousViewId) || viewid != PreviousViewId ) {
                        finalview.push({ 'value': viewid, 'label': viewname }); // ===== set the view dropdown value ================//
                    }
                    if (isArrayWithLength(finalview)) {
                        //let views = getState().serviceHealth.viewdetailsdata;                        

                        let colorschemeval = '';
                        for (var i = 0; i < allviews.length; i++) {
                            if (allviews[i].viewId === finalview[0].value) {
                                view = allviews[i];
                            }
                        }
                        const Disableviewconfigbutton = DisableViewconfigUpdatebutton(view);
                        if (isArrayWithLength(ColorshemesData)) {
                            ColorshemesData.map((obj) => {
                                if (obj.id === view.colorSchemeId) {
                                    colorschemeval = obj.name;
                                }
                            })
                        }

                        dispatch({
                            viewData: finalview[0],
                            ConfigurationView: view,
                            chkMonltyAvailability: view.isMonthlyAvailability,
                            chkMSER: view.isMserView,
                            TSGValue: view.tsg,
                            Disableviewconfigbutton: Disableviewconfigbutton,
                            checkedReachability: view.isReachability,
                            coloringLogicValue: (view.coloringLogicId != null && view.coloringLogicId != '') ? { 'id': view.coloringLogicId, 'name': view.colorLogicText } : [],
                            noOfDecimalValue: (view.noOfDecimalId != null && view.noOfDecimalId != '') ? { 'id': view.noOfDecimalId, 'name': view.noOfDecimalText } : [],
                            colorSchemeValue: (view.colorSchemeId != null && view.colorSchemeId != '') ? { 'id': view.colorSchemeId, 'name': colorschemeval } : [],
                            authenticationTypeValue: (view.authenticationType != null && view.authenticationType != '') ? { 'id': view.authenticationType, 'name': view.authenticationType } : [],
                            volumeDisplayTypeValue: (view.volumeType != null && view.volumeType != '') ? (view.volumeType === 'd' ? { 'id': 'd', 'name': 'Default (K/M)' } : (view.volumeType === 'k' ? { 'id': 'k', 'name': 'Thousands(K)' } : { 'id': 'm', 'name': 'Millions(M)' })) : [],
                            type: ActionType.VIEW_DROPDOWN_SELECT
                        });
                    }
                    else {
                        dispatch({
                            type: ActionType.SET_NOVIEWS
                        });
                    }

                }
            }
            if (((!isNullOrUndefined(Currentview)) && Currentview.viewId === 0) || view===null) {
                if (selectedViewDropdownOption != null || selectedViewDropdownOption != undefined) {
                    allviews.map((obj) => {
                        if (obj.viewId === selectedViewDropdownOption.value) {
                            Currentview = obj;
                        }
                    })
                }
                else {
                    Currentview = view;
                }
            }
            else {
                Currentview = view;
            }
            dispatch({
                tab: tab,
                ConfigurationView: Currentview,
                type: ActionType.SET_DAILYDATA_ACTIVETAB
            })
        }


    },


    handleOnCheck: (checked: boolean): ReduxAction => ({
        checked: checked,
        type: ActionType.CHECK_SAMPLE_BOX
    }),

    handleBulkEditCheck: (checkBulkEdit: boolean): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        if (isArrayWithLength(getState().serviceHealth.dailyData)) {
            getState().serviceHealth.dailyData.map((obj: IDailyData) => {
                obj.metricList.map((hmm: HmMetric) => {
                    if (hmm.IsshowBulk) {
                        hmm.IsshowBulk = false;
                    }
                })
            });
            dispatch({
                checked: checkBulkEdit,
                type: ActionType.CHECK_BULK_EDIT
            })
        }
    },

    handleMSEROCheck: (chkMSER: boolean): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        if (!isNullOrUndefined(getState().authUser) && !isNullOrUndefined(getState().serviceHealth.SelectedView)) {

            let userId = getState().authUser.login;
            userId = userId.substr(0, userId.indexOf('@'));
            const view = getState().serviceHealth.ConfigurationView;
            let tab = getState().serviceHealth.activeDailyDataTab;
            let viewId;// 
            if (tab === 'reachability') {
                viewId = getState().serviceHealth.selectedViewDropdownOption.value;
            }
            else {
                viewId = view.viewId;
            }
            view.isMserView = chkMSER;
            if (viewId > 0) {
                DailyDataApi.SaveIsMserView(chkMSER, viewId.toString(), userId)
                    .then((cnt: number) => {
                        if (cnt > 0) {
                            dispatch({
                                checked: chkMSER,
                                view: view,
                                type: ActionType.CHECK_SAVE_MSER
                            });
                            toast.success(renderToastifyMsg('MSER View flag updated successfully!',
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
                        }
                    }).catch(error => {
                        dispatch({
                            type: ActionType.SET_LOADING_FALSE
                        });
                    });
            }
            else {
                dispatch({
                    checked: chkMSER,
                    view: view,
                    type: ActionType.CHECK_SAVE_MSER
                });
            }

        }
    },

    handleMonthlyAvailabilityOnCheck: (chkMonthly: boolean): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        if (!isNullOrUndefined(getState().authUser) && !isNullOrUndefined(getState().serviceHealth.SelectedView)) {
            let userId = getState().authUser.login;
            let tab = getState().serviceHealth.activeDailyDataTab;
            userId = userId.substr(0, userId.indexOf('@'));
            const view = getState().serviceHealth.ConfigurationView;
            let viewId;// 
            if (tab === 'reachability') {
                viewId = getState().serviceHealth.selectedViewDropdownOption.value;
            }
            else {
                viewId=view.viewId;
            }
            view.isMonthlyAvailability = chkMonthly;
            if (viewId > 0) {
                DailyDataApi.SaveIsMonthlyAvailability(chkMonthly, viewId.toString(), userId)
                    .then((cnt: number) => {
                        if (cnt > 0) {
                            dispatch({
                                checked: chkMonthly,
                                view: view,
                                type: ActionType.CHECK_SAVE_MONTHLY_AVAILABILITY
                            });
                            toast.success(renderToastifyMsg("Monthly Availability flag updated successfully!",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
                        }
                    }).catch(error => {
                        dispatch({
                            type: ActionType.SET_LOADING_FALSE
                        });
                    });
            }
            else {
                dispatch({
                    checked: chkMonthly,
                    view: view,
                    type: ActionType.CHECK_SAVE_MONTHLY_AVAILABILITY
                });
            }

        }
    },
    onReachabilityCheck: (chkreachability: boolean): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const view = getState().serviceHealth.ConfigurationView;
        let colorSchemeValue = getState().serviceHealth.colorSchemeValue;
        let coloringLogicValue = getState().serviceHealth.coloringLogicValue;
        let ColorLogicsData = getState().serviceHealth.ColorLogicsData;
        let ColorshemesData = getState().serviceHealth.ColorshemesData;
        view.isReachability = chkreachability;
        if (chkreachability != undefined && chkreachability === true) {
            coloringLogicValue = ColorLogicsData.filter(item => item.id === 2);
            colorSchemeValue = ColorshemesData.filter(item => item.id === 2);
            view.coloringLogicId = 2;
            view.colorSchemeId = 2
        }
        else {
            coloringLogicValue = ColorLogicsData.filter(item => item.id === 1);
            colorSchemeValue = ColorshemesData.filter(item => item.id === 3);
            view.coloringLogicId = 1;
            view.colorSchemeId = 3;
        }
        dispatch({
            checked: chkreachability,
            view: view,
            colorSchemeValue: colorSchemeValue,
            coloringLogicValue: coloringLogicValue,
            type: ActionType.CHECK__REACHABILITY_CHANGE
        });

    },
    handleREDOnCheck: (checked: boolean): ReduxAction => ({
        checked: checked,
        type: ActionType.CHECK_RED
    }),

    handleYELLOWOnCheck: (checked: boolean): ReduxAction => ({
        checked: checked,
        type: ActionType.CHECK_YELLOW
    }),

    handleOnCheckHistory: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let chkHistory = getState().serviceHealth.chkHistory;
        if (!chkHistory) {
            chkHistory = true;
        }
        else {
            chkHistory = false;
        }
        dispatch({
            checked: chkHistory,
            type: ActionType.CHECK_HISTORY
        });
    },

    handleOnPrevious: (): ReduxAction => ({
        type: ActionType.PREVIOUS_SERVICE_NAVIGATOR
    }),

    handleOnNext: (): ReduxAction => ({

        type: ActionType.NEXT_SERVICE_NAVIGATOR
    }),

    handleOnFilterApplied: (): ReduxAction => ({
        type: ActionType.DAILY_FILTER_APPLIED
    }),

    handleStartDateChange: (startDate: Date): ReduxAction => ({
        startDate: startDate,
        type: ActionType.START_DATE_CHANGE
    }),
    onweeklyStartDateChange: (weeklyStartDate: Date): ReduxAction => ({

        weeklyStartDate: weeklyStartDate,
        type: ActionType.WEEKLY_START_DATE_CHANGE
    }),

    onmonthlyStartDateChange: (monthlyStartDate: Date): ReduxAction => ({

        monthlyStartDate: monthlyStartDate,
        type: ActionType.MONTHLY_START_DATE_CHANGE
    }),
    handleEndDateChange: (endDate: Date): ReduxAction => ({
        endDate: endDate,
        type: ActionType.END_DATE_CHANGE
    }),
    handleOnFleetFilterApplied: (): ReduxAction => ({
        type: ActionType.DAILY_FLEET_FILTER_APPLIED
    }),

    handleFleetStartDateChange: (fleetStartDate: Date): ReduxAction => ({
        fleetStartDate: fleetStartDate,
        type: ActionType.FLEET_START_DATE_CHANGE
    }),
    handleFleetEndDateChange: (fleetEndDate: Date): ReduxAction => ({
        fleetEndDate: fleetEndDate,
        type: ActionType.FLEET_END_DATE_CHANGE
    }),
    MultipleViewSelectedChange: (MultipleViewSelected: boolean): ReduxAction => ({
        MultipleViewSelected: MultipleViewSelected,
        type: ActionType.MULTIPLEVIEW_SELECT_CHANGE
    }),
    reportSelectOption: (option: IDropdownOption): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const date = getWeeklyStartDate();
        const dateMonth = getMonthlyStartDate();
        if (option.label === 'Daily') {
            let serviceId = getState().serviceHealth.selectedServiceId;
            let viewId = isArrayWithLength(getState().serviceHealth.SelectedView) ? getState().serviceHealth.SelectedView[0].viewId : 0;
            WeeklyAPI.GetDateForRefreshedData(serviceId, viewId.toString())
                .then((Datarefershedate: IPropCalender[]) => {
                    dispatch({
                        Datarefershedate,
                        type: ActionType.SET_REFRESHDATE_SUCCESS
                    });

                }).catch(error => {
                    dispatch({
                        type: ActionType.SET_REFRESHDATE_FAIL
                    });
                });
        }
        dispatch({
            option: option,
            weeklyStartDate: date,
            weeklyDefaultDate: moment(date).format('MM/DD/YYYY'),
            monthlyStartDate: dateMonth,
            monthlyDefaultDate: moment(dateMonth).format('MM/DD/YYYY'),
            dailyData: dailyDataRaw,
            ReachabilityData: reachabilityDataRaw,
            type: ActionType.DROPDOWN_SELECT
        });
    },
    multipleviewOnSelectOption: (multipleviewSelectedOption: IDropdownOption): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            multipleviewSelectedOption: multipleviewSelectedOption,
            type: ActionType.MULTIPLEVIEW_DROPDOWN_SELECT
        });
    },
    viewSelectOption: (viewData: IDropdownOption): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let views = getState().serviceHealth.viewdetailsdata;
        let ColorshemesData = getState().serviceHealth.ColorshemesData;
        let view = null;
        let colorschemeval = '';
        if (viewData.label.length > 21) {

        }
        for (var i = 0; i < views.length; i++) {
            if (views[i].viewId === viewData.value) {
                view = views[i];
            }
        }
        const Disableviewconfigbutton = DisableViewconfigUpdatebutton(view);
        if (isArrayWithLength(ColorshemesData)) {
            ColorshemesData.map((obj) => {
                if (obj.id === view.colorSchemeId) {
                    colorschemeval = obj.name;
                }
            })
        }
        view.RenameViewLabel = '';
        dispatch({
            viewData: viewData,
            ConfigurationView: view,
            chkMonltyAvailability: view.isMonthlyAvailability ,
            chkMSER: view.isMserView,
            TSGValue: view.tsg,
            Disableviewconfigbutton: Disableviewconfigbutton,
            checkedReachability: view.isReachability,
            coloringLogicValue: (view.coloringLogicId != null && view.coloringLogicId != '') ? { 'id': view.coloringLogicId, 'name': view.colorLogicText } : [] ,
            noOfDecimalValue: (view.noOfDecimalId != null && view.noOfDecimalId != '') ? { 'id': view.noOfDecimalId, 'name': view.noOfDecimalText } : [] ,
            colorSchemeValue: (view.colorSchemeId != null && view.colorSchemeId != '') ? { 'id': view.colorSchemeId, 'name': colorschemeval } : [] ,
            authenticationTypeValue: (view.authenticationType != null && view.authenticationType != '') ? { 'id': view.authenticationType, 'name': view.authenticationType } : [] ,
            volumeDisplayTypeValue: (view.volumeType != null && view.volumeType != '') ? (view.volumeType === 'd' ? { 'id': 'd', 'name': 'Default (K/M)' } : (view.volumeType === 'k' ? { 'id': 'k', 'name': 'Thousands(K)' } : { 'id': 'm', 'name': 'Millions(M)' })) : [] ,
            type: ActionType.VIEW_DROPDOWN_SELECT
        });
    },
    GetViewDataForBulkEdit: (serviceID: number, datacenter: string): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            //startGroupIndex,
            type: ActionType.VIEW_REQUEST
        });
        DailyDataApi.HmGetViewDetailsForBulkEdit(serviceID, datacenter)
            .then((BulkViewDatas: IBulkViewDetails[]) => {

                if (isArrayWithLength(BulkViewDatas)) {
                    const BulkViewData = [];
                    BulkViewDatas.map((obj: IBulkViewDetails) => {
                        BulkViewData.push({ id: obj.Id, name: obj.Name })
                    });
                    dispatch({
                        BulkViewData,
                        type: ActionType.BULKVIEWS_RECEIVE
                    });
                }
            }).catch(error => {
                dispatch({
                    type: ActionType.BULKVIEWS_FAIL
                });
            });
    },
    GetColoringLogic: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const ColorLogicsData = [];
        DailyDataApi.GetColoringLogic()
            .then((colorlogic: IViewConfigColorLogics[]) => {
                if (isArrayWithLength(colorlogic)) {
                    colorlogic.map((obj: IViewConfigColorLogics) => {
                        ColorLogicsData.push({ 'id': obj.colorLogicId, 'name': obj.colorLogicText })
                    });
                }
                dispatch({
                    ColorLogicsData,
                    type: ActionType.VIEWCONFIGURATION_COLORLOGIC
                });
            }).catch(error => {
                dispatch({
                    ColorLogicsData,
                    type: ActionType.VIEWCONFIGURATION_COLORLOGIC
                });
            });
    },
    GetColorSchemes: (val): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const ColorshemesData = [];
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let view = getState().serviceHealth.ConfigurationView;
        let colorSchemeValue = null;
        DailyDataApi.GetColorSchemes()
            .then((colorscheme: IViewConfigColorSchemes[]) => {
                if (isArrayWithLength(colorscheme)) {
                    colorscheme.map((obj: IViewConfigColorSchemes) => {
                        if ((view!==null) && view.colorSchemeId === obj.colorSchemeId) {
                            colorSchemeValue = { 'id': obj.colorSchemeId, 'name': obj.name };
                        }
                        ColorshemesData.push({ 'id': obj.colorSchemeId, 'name': obj.name })
                    });
                }
                dispatch({
                    ColorshemesData,
                    colorSchemeValue: colorSchemeValue,
                    val,
                    type: ActionType.VIEWCONFIGURATION_COLORSCHEMES
                });
            }).catch(error => {
                dispatch({
                    ColorshemesData,
                    colorSchemeValue: [],
                    type: ActionType.VIEWCONFIGURATION_COLORSCHEMES
                });
            });
    },
    Get_NoOfDecimals: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const noofdecimalsdata = [];
        DailyDataApi.Get_NoOfDecimals()
            .then((noofdecimals: IViewConfigNoofDecimals[]) => {
                if (isArrayWithLength(noofdecimals)) {
                    noofdecimals.map((obj: IViewConfigNoofDecimals) => {

                        noofdecimalsdata.push({ 'id': obj.NoOfDecimalId, 'name': obj.NoOfDecimalText })
                    });
                }
                dispatch({
                    noofdecimalsdata,
                    type: ActionType.VIEWCONFIGURATION_NOOFDECIMALS
                });
            }).catch(error => {
                dispatch({
                    noofdecimalsdata: [],
                    type: ActionType.VIEWCONFIGURATION_NOOFDECIMALS
                });
            });
    },
    getHeatmapView: (serviceId: number, tab: string, IscallforUpdate, copyurlviewid): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        // Build http request and success handler in Promise<void> wrapper
        let Currentview = null;
        let coloringLogicValue;
        let noOfDecimalValue;
        let authenticationTypeValue;
        let volumeDisplayTypeValue;
        let chkMonltyAvailability = false;
        let chkMSER = false;
        let TSGValue = '';
        let checkedReachability = false;
        let selectedViewDropdownOption = null;
        let Disableviewconfigbutton = true;
        const AllviewsData = [];
        Currentview = getState().serviceHealth.ConfigurationView;
        DailyDataApi.getHeatmapView(serviceId)
            .then((dailyViewData: IViewDetails[]) => {
                const dailyViewList = [];
                const viewdetailsdata = [];
                if (isArrayWithLength(dailyViewData)) {
                    dailyViewData.map((item,key) => {
                        const lstObj = {} as IDropdownOption;
                        lstObj.value = parseInt(item.viewId);
                        lstObj.label = item.viewName;
                        // ==== set the current view (If user creates new View then viewid is '0', so at that time we can set the current view is based on  view name)
                        if (copyurlviewid > 0) {
                            if (parseInt(copyurlviewid) === parseInt(item.viewId))
                                Currentview = setview(item);
                        }
                        else {
                            if (Currentview !== null) {
                                if (parseInt(Currentview.viewId) === 0) {
                                    if (Currentview.viewName === item.viewName)
                                        Currentview = setview(item);
                                }
                                if (Currentview.viewId === parseInt(item.viewId))
                                    Currentview = setview(item);
                            }
                            else {
                                if (key === 0) {
                                    Currentview = setview(item);
                                    Currentview.viewId = parseInt(item.viewId);
                                }
                            }
                        }

                        AllviewsData.push(item);
                        if (dailyViewList.indexOf(lstObj.value) === -1) {
                            if (tab === 'heatmap') {
                                if (!item.isReachability) {
                                    viewdetailsdata.push(item);
                                    dailyViewList.push(lstObj)
                                }
                            }
                            else if (tab === 'ViewConfiguration') {
                                viewdetailsdata.push(item);
                                dailyViewList.push(lstObj)
                            }
                            else if (tab === 'reachability') {
                                if (item.isReachability) {
                                    viewdetailsdata.push(item);
                                    dailyViewList.push(lstObj)
                                }
                            }
                        }
                    })
                }

                if (IscallforUpdate === 0 && copyurlviewid===0) {

                    if (viewdetailsdata.length > 0) {
                        Currentview = viewdetailsdata[0];
                    }
                    else {
                        Currentview = null;
                        coloringLogicValue = [];
                        authenticationTypeValue = [];
                        noOfDecimalValue = [];
                        chkMonltyAvailability = false;
                        chkMSER = false;
                        TSGValue = null;
                        checkedReachability = false;
                        selectedViewDropdownOption = null;
                    }

                }
                if (Currentview != null) {
                    coloringLogicValue = (viewdetailsdata[0].coloringLogicId != null && Currentview.coloringLogicId != '') ? { 'id': Currentview.coloringLogicId, 'name': Currentview.colorLogicText } : [];
                    authenticationTypeValue = (Currentview.authenticationType != null && Currentview.authenticationType != '') ? { 'id': Currentview.authenticationType, 'name': Currentview.authenticationType } : [];
                    noOfDecimalValue = (Currentview.noOfDecimalId != null && Currentview.noOfDecimalId != '') ? { 'id': Currentview.noOfDecimalId, 'name': Currentview.noOfDecimalText } : [];
                    volumeDisplayTypeValue = (Currentview.volumeType != null && Currentview.volumeType != '') ? (Currentview.volumeType === 'd' ? { 'id': 'd', 'name': 'Default (K/M)' } : (Currentview.volumeType === 'k' ? { 'id': 'k', 'name': 'Thousands(K)' } : { 'id': 'm', 'name': 'Millions(M)' })) : [];
                    chkMonltyAvailability = Currentview.isMonthlyAvailability;
                    chkMSER = Currentview.isMserView;
                    TSGValue = Currentview.tsg;
                    checkedReachability = Currentview.isReachability;
                    selectedViewDropdownOption = { 'label': Currentview.viewName, 'value': Currentview.viewId }
                    Disableviewconfigbutton = (DisableViewconfigUpdatebutton(Currentview));
                }

                dispatch({
                    dailyViewList,
                    viewdetailsdata,
                    AllviewsData: AllviewsData,
                    Currentview: Currentview,
                    IscallforUpdate,
                    selectedViewDropdownOption: selectedViewDropdownOption,
                    coloringLogicValue: coloringLogicValue,
                    noOfDecimalValue: noOfDecimalValue,
                    authenticationTypeValue: authenticationTypeValue,
                    volumeDisplayTypeValue: volumeDisplayTypeValue,
                    Disableviewconfigbutton: Disableviewconfigbutton,
                    chkMonltyAvailability:chkMonltyAvailability,
                    chkMSER: chkMSER,
                    TSGValue: TSGValue,
                    checkedReachability: checkedReachability,
                    type: ActionType.VIEW_RECEIVE
                });


            }).catch(error => {
                dispatch({
                    IscallforUpdate,
                    type: ActionType.VIEW_RECEIVE_FAIL
                });
            });
    },
    BindViewsDropdownbasedonTabselection: (tab): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let state = getState();
        let allviews = state.serviceHealth.AllviewsData;
        let Currentview = state.serviceHealth.ConfigurationView;
        const dailyViewList = [];
        const viewdetailsdata = [];
        if (isArrayWithLength(allviews)) {
            allviews.map((item: IViewDetails) => {
                const lstObj = {} as IDropdownOption;
                lstObj.value = parseInt(item.viewId);
                lstObj.label = item.viewName;
                if (dailyViewList.indexOf(lstObj.value) === -1) {
                    if (tab === 'heatmap') {
                        if (!item.isReachability) {
                            viewdetailsdata.push(item);
                            dailyViewList.push(lstObj)
                        }
                    }
                    if (tab === 'reachability') {
                        if (item.isReachability) {
                            viewdetailsdata.push(item);
                            dailyViewList.push(lstObj)
                        }
                    }
                    else if (tab === 'ViewConfiguration') {
                        viewdetailsdata.push(item);
                        dailyViewList.push(lstObj)
                    }
                }

            });
        }
        dispatch({
            viewdetailsdata,
            dailyViewList,
            type: ActionType.SET_VIEW_DROPDOWN_BASEDON_TAB_SELECTION
        });


    },
    setloadingtrue: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
    },
    setloadingfalse: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_FALSE
        });
    },
    setfleetDataloadedtrue: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_FLEETDATALOADED_TRUE
        });
    },
    getfleetDropdownData: (stDate: string, enDate: string, currentservice: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.FLEET_DROPDOWN_DATA_FETCH
        });
        const fleetTenantList = [];
        var selectedTenant = '';
        const fleetRoleList = [];
        var selectedRole = '';
        const fleetCloudList = [];
        var selectedCloud = '';
        const fleetTrafficGroupList = [];
        var selectedTrafficGroup = '';
        // To Load the Fleet Utilization Dropdown data
        DailyDataApi.getFleetLookUpData(1, null, null, null).then((tenantData: LookupData[]) => {
            if (isArrayWithLength(tenantData)) {
                tenantData.map((obj: LookupData) => {
                    fleetTenantList.push({ 'label': obj.value, 'value': obj.value });
                });
                // Logic to set the tenant default to current service if fleet heatmap exists for the corresponding service else 
                // to set with the first item of the array by default
                var element = fleetTenantList.filter(item => item.value === currentservice);
                selectedTenant = element.length !== 0 ? currentservice : fleetTenantList[0].value;

            }
            DailyDataApi.getFleetLookUpData(2, selectedTenant, null, null).then((roleData: LookupData[]) => {
                if (isArrayWithLength(roleData)) {
                    roleData.map((obj: LookupData) => {
                        fleetRoleList.push({ 'label': obj.value, 'value': obj.value })
                    });
                    selectedRole = fleetRoleList[0].value;
                }
                DailyDataApi.getFleetLookUpData(3, selectedTenant, selectedRole, null).then((cloudData: LookupData[]) => {
                    if (isArrayWithLength(cloudData)) {
                        cloudData.map((obj: LookupData) => {
                            fleetCloudList.push({ 'label': obj.value, 'value': obj.value })
                        });
                        fleetCloudList.unshift({ label: "All", value: "All" });
                        selectedCloud = fleetCloudList[0].value;
                    }
                    DailyDataApi.getFleetLookUpData(4, selectedTenant, selectedRole, selectedCloud).then((trafficGroupData: LookupData[]) => {
                        if (isArrayWithLength(trafficGroupData)) {
                            trafficGroupData.map((obj: LookupData) => {
                                fleetTrafficGroupList.push({ 'label': obj.value, 'value': obj.value })
                            });
                            var index = -1;
                            for (var j = 0; j < fleetTrafficGroupList.length; j++) {
                                if (fleetTrafficGroupList[j].value === "prod") {
                                    index = j;
                                }
                            }
                            selectedTrafficGroup = fleetTrafficGroupList[index != -1 ? index : 0].value;
                        }
                        dispatch({
                            fleetTenantList,
                            selectedTenant: { 'label': selectedTenant, 'value': selectedTenant },
                            fleetRoleList,
                            selectedRole: { 'label': selectedRole, 'value': selectedRole },
                            fleetCloudList,
                            selectedCloud: { 'label': selectedCloud, 'value': selectedCloud },
                            fleetTrafficGroupList,
                            selectedTrafficGroup: { 'label': selectedTrafficGroup, 'value': selectedTrafficGroup },
                            dailyFleetDataFilter: { tenant: selectedTenant, cloud: selectedCloud, role: selectedRole, trafficGroup: selectedTrafficGroup },
                            fleetStartDate: stDate,
                            fleetEndDate: enDate,
                            fleetMaxDate: enDate,
                            type: ActionType.FLEET_DROPDOWN_DATA_RECEIVE
                        });
                    });
                });
            });
        });

    },
    getfleetHeatmapData: (stDate: string, enDate: string, selectedTenant: string, selectedCloud: string, selectedTrafficGroup: string, selectedRole: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.FLEET_DATA_FETCH
        });
        const fleetheatmapData = [];
        fleetheatMapDataRaw = [];
        // load the Fleet Data
        DailyDataApi.GetFleetUtilizationData(stDate, enDate, selectedTenant, selectedCloud, selectedTrafficGroup, selectedRole).then((fleetData: PropFleetutilizationHMList) => {
            if (!isNullOrUndefined(fleetData)) {
                const tempfleetdata = fleetData.objPropFleetutilizationHM.slice();
                tempfleetdata.map((obj: PropFleetutilizationHM) => {
                    fleetheatmapData.push(obj);
                    fleetheatMapDataRaw.push(obj);
                });
                const tempdatalabeldata = fleetData.objDateLabels;
                // Formatting Date Labels as required for UI
                const Datelabels = {
                    startdt: moment(tempdatalabeldata.startdt).format("MM/DD"),
                    enddt: moment(tempdatalabeldata.enddt).format("MM/DD"),
                    startwd: moment(tempdatalabeldata.startwd).format("MM/DD"),
                    endwd: moment(tempdatalabeldata.endwd).format("MM/DD")
                };
                fleetheatmapData.map((obj: PropFleetutilizationHM) => {
                    //  If at least one volume is > 1B
                    if (parseInt(obj.requestVolume) >= 1000000000) {
                        obj.requestVolume = (parseFloat(obj.requestVolume) / 1000000000).toFixed(2) + "B";
                    } // If at least one volume is > 1M
                    else if (parseInt(obj.requestVolume) >= 1000000) {
                        obj.requestVolume = (parseFloat(obj.requestVolume) / 1000000).toFixed(2) + "M";
                    } // If at least one volume is > 1K
                    else if (parseInt(obj.requestVolume) >= 1000) {
                        obj.requestVolume = (parseFloat(obj.requestVolume) / 1000).toFixed(2) + "K";
                    }
                });
                dispatch({
                    fleetheatmapData,
                    Datelabels: Datelabels,
                    type: ActionType.FLEET_DATA_RECEIVE
                });
            }
        }).catch(error => {
            dispatch({
                type: ActionType.SET_LOADING_FALSE
            });
        });
    },
    getfleetColorLogic: (stDate: string, enDate: string, selectedTenant: string, selectedCloud: string, selectedTrafficGroup: string, selectedRole: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        DailyDataApi.GetFleetUtilizationColourLogic(stDate, enDate, selectedTenant, selectedCloud, selectedTrafficGroup, selectedRole).then((fleetDatacolorscheme: IFleetcolorSchemes) => {
            dispatch({
                fleetDatacolorscheme,
                type: ActionType.COLORSCHEME_SUCCESS
            });
        }).catch(error => {
            dispatch({
                type: ActionType.SET_LOADING_FALSE
            });
        });
    },
    tenantSelectOption: (tenantData: IDropdownOption): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        var selectedTenant = tenantData.label;
        var selectedRole = (getState().serviceHealth.selectedRole !== null && getState().serviceHealth.selectedRole.value !== null) ? getState().serviceHealth.selectedRole.value : null;
        var selectedCloud = (getState().serviceHealth.selectedCloud !== null && getState().serviceHealth.selectedCloud.value !== null) ? getState().serviceHealth.selectedCloud.value : null;
        DailyDataApi.getFleetLookUpData(2, selectedTenant, selectedRole, selectedCloud).then((responseData: LookupData[]) => {
            if (isArrayWithLength(responseData)) {
                const fleetRoleList = [];
                responseData.map((obj: LookupData) => {
                    fleetRoleList.push({ 'label': obj.value, 'value': obj.value })
                });
                selectedRole = fleetRoleList[0].value;
                const fleetCloudList = [];
                selectedCloud = '';
                const fleetTrafficGroupList = [];
                var selectedTrafficGroup = '';
                DailyDataApi.getFleetLookUpData(3, selectedTenant, selectedRole, selectedCloud).then((response: LookupData[]) => {
                    if (isArrayWithLength(response)) {
                        response.map((obj: LookupData) => {
                            fleetCloudList.push({ 'label': obj.value, 'value': obj.value })
                        });
                        fleetCloudList.unshift({ label: "All", value: "All" });
                        selectedCloud = fleetCloudList[0].value;
                        DailyDataApi.getFleetLookUpData(4, selectedTenant, selectedRole, selectedCloud).then((response: LookupData[]) => {
                            if (isArrayWithLength(response)) {
                                response.map((obj: LookupData) => {
                                    fleetTrafficGroupList.push({ 'label': obj.value, 'value': obj.value })
                                });
                                var index = -1;
                                for (var j = 0; j < fleetTrafficGroupList.length; j++) {
                                    if (fleetTrafficGroupList[j].value === "prod") {
                                        index = j;
                                    }
                                }
                                selectedTrafficGroup = fleetTrafficGroupList[index != -1 ? index : 0].value;
                            }
                            dispatch({
                                selectedTenant: { 'label': tenantData.label, 'value': tenantData.value },
                                fleetRoleList,
                                selectedRole: { 'label': selectedRole, 'value': selectedRole },
                                fleetCloudList,
                                selectedCloud: { 'label': selectedCloud, 'value': selectedCloud },
                                fleetTrafficGroupList,
                                selectedTrafficGroup: { 'label': selectedTrafficGroup, 'value': selectedTrafficGroup },
                                dailyFleetDataFilter: { tenant: tenantData.label, cloud: selectedCloud, role: selectedRole, trafficGroup: selectedTrafficGroup },
                                type: ActionType.TENANT_DROPDOWN_SELECT
                            });
                        });
                    }
                });
            }
        }).catch(error => {
            dispatch({
                type: ActionType.SET_LOADING_FALSE
            });
        });
    },
    roleSelectOption: (roleData: IDropdownOption): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        var selectedTenant = (getState().serviceHealth.selectedTenant !== null && getState().serviceHealth.selectedTenant.value !== null) ? getState().serviceHealth.selectedTenant.value : null;
        var selectedCloud = (getState().serviceHealth.selectedCloud !== null && getState().serviceHealth.selectedCloud.value !== null) ? getState().serviceHealth.selectedCloud.value : null;
        var selectedRole = roleData.label;
        DailyDataApi.getFleetLookUpData(3, selectedTenant, selectedRole, selectedCloud).then((responseData: LookupData[]) => {
            if (isArrayWithLength(responseData)) {
                const fleetCloudList = [];
                responseData.map((obj: LookupData) => {
                    fleetCloudList.push({ 'label': obj.value, 'value': obj.value })
                });
                fleetCloudList.unshift({ label: "All", value: "All" });
                selectedCloud = fleetCloudList[0].value;
                const fleetTrafficGroupList = [];
                var selectedTrafficGroup = '';
                DailyDataApi.getFleetLookUpData(4, selectedTenant, selectedRole, selectedCloud).then((response: LookupData[]) => {
                    if (isArrayWithLength(response)) {
                        response.map((obj: LookupData) => {
                            fleetTrafficGroupList.push({ 'label': obj.value, 'value': obj.value })
                        });
                        var index = -1;
                        for (var j = 0; j < fleetTrafficGroupList.length; j++) {
                            if (fleetTrafficGroupList[j].value === "prod") {
                                index = j;
                            }
                        }
                        selectedTrafficGroup = fleetTrafficGroupList[index != -1 ? index : 0].value;
                    }
                    dispatch({
                        selectedRole: { 'label': roleData.label, 'value': roleData.value },
                        fleetCloudList,
                        selectedCloud: { 'label': selectedCloud, 'value': selectedCloud },
                        fleetTrafficGroupList,
                        selectedTrafficGroup: { 'label': selectedTrafficGroup, 'value': selectedTrafficGroup },
                        dailyFleetDataFilter: { tenant: selectedTenant, cloud: selectedCloud, role: roleData.label, trafficGroup: selectedTrafficGroup },
                        type: ActionType.ROLE_DROPDOWN_SELECT
                    });
                });
            }
        }).catch(error => {
            dispatch({
                type: ActionType.SET_LOADING_FALSE
            });
        });
    },
    cloudSelectOption: (cloudData: IDropdownOption): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        var selectedTenant = (getState().serviceHealth.selectedTenant !== null && getState().serviceHealth.selectedTenant.value !== null) ? getState().serviceHealth.selectedTenant.value : null;
        var selectedCloud = cloudData.label;
        var selectedRole = (getState().serviceHealth.selectedRole !== null && getState().serviceHealth.selectedRole.value !== null) ? getState().serviceHealth.selectedRole.value : null;
        DailyDataApi.getFleetLookUpData(4, selectedTenant, selectedRole, selectedCloud).then((responseData: LookupData[]) => {
            if (isArrayWithLength(responseData)) {
                const fleetTrafficGroupList = [];
                var selectedTrafficGroup = '';
                responseData.map((obj: LookupData) => {
                    fleetTrafficGroupList.push({ 'label': obj.value, 'value': obj.value })
                });
                var index = -1;
                for (var j = 0; j < fleetTrafficGroupList.length; j++) {
                    if (fleetTrafficGroupList[j].value === "prod") {
                        index = j;
                    }
                }
                selectedTrafficGroup = fleetTrafficGroupList[index != -1 ? index : 0].value;
            }
            dispatch({
                selectedCloud: { 'label': cloudData.label, 'value': cloudData.value },
                fleetTrafficGroupList: getState().serviceHealth.fleetTrafficGroupList,
                selectedTrafficGroup: { 'label': selectedTrafficGroup, 'value': selectedTrafficGroup },
                dailyFleetDataFilter: { tenant: selectedTenant, cloud: cloudData.label, role: selectedRole, trafficGroup: selectedTrafficGroup},
                type: ActionType.CLOUD_DROPDOWN_SELECT
            });
        }).catch(error => {
            dispatch({
                type: ActionType.SET_LOADING_FALSE
            });
        });
    },
    trafficGroupSelectOption: (trafficGroupData: IDropdownOption): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        var selectedTenant = (getState().serviceHealth.selectedTenant !== null && getState().serviceHealth.selectedTenant.value !== null) ? getState().serviceHealth.selectedTenant.value : null;
        var selectedCloud = (getState().serviceHealth.selectedCloud !== null && getState().serviceHealth.selectedCloud.value !== null) ? getState().serviceHealth.selectedCloud.value : null;
        var selectedRole = (getState().serviceHealth.selectedRole !== null && getState().serviceHealth.selectedRole.value !== null) ? getState().serviceHealth.selectedRole.value : null;
        dispatch({
            selectedTrafficGroup: { 'label': trafficGroupData.label, 'value': trafficGroupData.value },
            dailyFleetDataFilter: { tenant: selectedTenant, cloud: selectedCloud, role: selectedRole, trafficGroup: trafficGroupData.label },
            type: ActionType.TRAFFICGROUP_DROPDOWN_SELECT
        });
    },
    getWeekDetailsData: (WeekStatrtDate: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        WeekStatrtDate = moment(WeekStatrtDate).format('MM/DD/YYYY');
        // Build http request and success handler in Promise<void> wrapper
        WeeklyAPI.GetCalendarData()
            .then((calendarlsit: IYear[]) => {
                const weekDetailsData = helper_Get_Week_Values_Grid(calendarlsit, WeekStatrtDate);
                dispatch({
                    weekDetailsData,
                    type: ActionType.WEEKLY_DETAILS_DATA_RECEIVE
                });

            }).catch(error => {
                dispatch({
                    type: ActionType.WEEKLY_DETAILS_DATA_RECEIVE_FAIL
                });
            });
    },
    GetDateForRefreshedData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var daterefreshed = getState().authUser.DataRefreshed;
        const serviceId = getState().serviceHealth.selectedServiceId != 0 ? getState().serviceHealth.selectedServiceId : getState().authUser.serviceId;
        const viewId = 0;
        WeeklyAPI.GetDateForRefreshedData(serviceId, viewId.toString())
            .then((Datarefershedate: IPropCalender[]) => {
                dispatch({
                    Datarefershedate,
                    type: ActionType.SET_REFRESHDATE_SUCCESS
                });

            }).catch(error => {
                dispatch({
                    type: ActionType.SET_REFRESHDATE_FAIL
                });
            });
    },
    GetComments: (serviceId: number, weekStartDate: string,val:number): IAppThunkAction<ReduxAction> => (dispatch) => {
        weekStartDate = moment(weekStartDate).format('MM/DD/YYYY');
        WeeklyAPI.GetComments(serviceId, weekStartDate)
            .then((comments: PropKpiComments[]) => {
                dispatch({
                    comments,
                    val,
                    type: ActionType.COMMENTS_RECEIVE
                });

            }).catch(error => {
                dispatch({
                    type: ActionType.COMMENTS_RECEIVE_FAIL
                });
            });
    },

    AddItemToKpiHistory: (xmldata, WeekStatrtDate, serviceId): IAppThunkAction<ReduxAction> => (dispatch) => {
        WeekStatrtDate = moment(WeekStatrtDate).format('MM/DD/YYYY');
        WeeklyAPI.AddItemToKpiHistory(xmldata)
            .then((result: string) => {
                if (result !== "-1") {
                    WeeklyAPI.GetWeeklyData(serviceId, WeekStatrtDate, '', '')
                        .then((weeklyData: IWeeklyReport[]) => {
                            if (isArrayWithLength(weeklyData)) {
                                weeklyData.map((subItem, subkey) => {
                                    subItem.week5Edit = (subItem.week5 !== '' && subItem.week5 !== undefined && subItem.week5 !== null) ? subItem.week5.split('%')[0] : '';
                                    subItem.week5Sort = subItem.week5;
                                })
                            }
                            dispatch({
                                weeklyData,
                                type: ActionType.WEEKLYDATA_RECEIVE
                            });


                        }).catch(error => {
                            dispatch({
                                type: ActionType.SET_LOADING_FALSE
                            });
                        });
                }
                else {
                    dispatch({
                        type: ActionType.WEEKDATA_UPDATE_FAIL
                    });
                }


            }).catch(error => {
                dispatch({
                    type: ActionType.WEEKDATA_UPDATE_FAIL
                });
            });

    },
    getDraftData: (serviceId: number, WeekStatrtDate: string, weeklyDefaultDate: string): IAppThunkAction<ReduxAction> => (dispatch) => {
        WeekStatrtDate = moment(WeekStatrtDate).format('MM/DD/YYYY'); 
        weeklyDefaultDate = moment(weeklyDefaultDate).format('MM/DD/YYYY'); 
        // Build http request and success handler in Promise<void> wrapper
        WeeklyAPI.GetDraftsData(serviceId, WeekStatrtDate, weeklyDefaultDate)
            .then((draftData: IPropDraft) => {
                dispatch({
                    draftData,
                    type: ActionType.DRAFTS_DATA_RECEIVE
                });

            }).catch(error => {
                dispatch({
                    type: ActionType.DRAFTS_DATA_RECEIVE_FAIL
                });
            });

    },
    GetConfigValues: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        WeeklyAPI.GetConfigValues()
            .then((ConfigValues: IPropCalender[]) => {
                dispatch({
                    ConfigValues,
                    type: ActionType.CONFIGVALUES_RECEIVE
                });

            }).catch(error => {
                dispatch({
                    type: ActionType.CONFIGVALUES_RECEIVE_FAIL
                });
            });

    },
    getWeeklyData: (serviceId: number, WeekStatrtDate: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let sid = getState().serviceHealth.selectedServiceId == 0 ? getState().authUser.serviceId : getState().serviceHealth.selectedServiceId;
        let isWeeklyDataDisabled = false;
        WeekStatrtDate = moment(WeekStatrtDate).format('MM/DD/YYYY');       
        dispatch({
            isWeeklyDataDisabled,
            type: ActionType.WEEKLYDATA_FETCH
        });
        const serviceId = sid;
        const viewId = 0;
        WeeklyAPI.GetDateForRefreshedData(serviceId, viewId.toString())
            .then((Datarefershedate: IPropCalender[]) => {
                dispatch({
                    Datarefershedate,
                    type: ActionType.SET_REFRESHDATE_SUCCESS
                });

            }).catch(error => {
                dispatch({
                    type: ActionType.SET_REFRESHDATE_FAIL
                });
            });
        // Build http request and success handler in Promise<void> wrapper      
        WeeklyAPI.GetWeeklyData(serviceId, WeekStatrtDate, '', '')
            .then((weeklyData: IWeeklyReport[]) => {
                if (isArrayWithLength(weeklyData)) {
                    if (weeklyData[0].kpi === "WeeklyDataDisabled") {
                        isWeeklyDataDisabled = true;
                    }
                    weeklyData.map((subItem, subkey) => {
                        if (subkey === 0) {
                            subItem.WeekDate = WeekStatrtDate;
                            subItem.serviceId = sid;
                        }

                        subItem.week5Edit = (subItem.week5 !== '' && subItem.week5 !== undefined && subItem.week5 !== null) ? subItem.week5.split('%')[0] : '';
                        subItem.week5Sort = subItem.week5;
                        var week5ToolTip = '';
                        if (subItem.history != null && subItem.history.length > 1) {
                            week5ToolTip = "Previous Value:\t\t" + subItem.history[1].kpiValue + "\nLast Updated By:\t\t" + subItem.history[0].updatedBy + "\nLast Updated Date:\t" + moment(subItem.history[0].updatedDate).format("MM/DD/YYYY @ h:mma");

                        }
                        subItem.week5ToolTip = week5ToolTip;
                    })
                }
                dispatch({
                    weeklyData,
                    isWeeklyDataDisabled,
                    type: ActionType.WEEKLYDATA_RECEIVE
                });


            }).catch(error => {
                dispatch({
                    isWeeklyDataDisabled,
                    type: ActionType.WEEKLYDATA_RECEIVE_FAIL
                });
            });
    },
    getBGMData: (serviceId: number, WeekStatrtDate: string): IAppThunkAction<ReduxAction> => (dispatch) => {
        WeekStatrtDate = moment(WeekStatrtDate).format('MM/DD/YYYY');
        // Build http request and success handler in Promise<void> wrapper      
        WeeklyAPI.Get_BGM_Data(serviceId, WeekStatrtDate)
            .then((BGMData: PropBgm[]) => {
                dispatch({
                    BGMData,
                    type: ActionType.BGM_RECEIVE
                });


            }).catch(error => {
                const BGMData = [];
                dispatch({
                    BGMData,
                    type: ActionType.BGM_RECEIVE_FAIL
                });
            });
    },
    getExternalLinks: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        // Build http request and success handler in Promise<void> wrapper 
        WeeklyAPI.GetExternal_Links()
            .then((links: PropExternalLinks[]) => {
                dispatch({
                    links,
                    type: ActionType.EXTERNALLINKS_RECEIVE
                });


            }).catch(error => {
                dispatch({
                    type: ActionType.EXTERNALLINKS_RECEIVE_FAIL
                });
            });

    },
    setDailydataFilterviewid: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_DAILYDAT_FILTER_VIEWID
        });
    },
    GetPerformanceData: (selectedServiceId: number, weeklyStartDate: string): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.PERFORMANCE_REQUEST
        });
        WeeklyAPI.WeeklyPerformanceData(selectedServiceId, moment(weeklyStartDate).format('MM/DD/YYYY'))
            .then((Perforamncedata: IWeeklyReport[]) => {
                var Mainlist = [];
                if (isArrayWithLength(Perforamncedata)) {
                    Perforamncedata.map((obj: IWeeklyReport, key) => {
                        obj.index = key;
                        Mainlist.push(obj);
                        if (obj.perfPopupGrpId === 0) {
                            obj.color = '#20B2AA';
                        }
                        if (obj.perfPopupGrpId === 1) {
                            obj.color = '#75a6d0';
                        }
                        if (obj.serviceUptime !== "")
                            obj.serviceUptime = (((parseFloat(obj.serviceUptime)) * 100).toFixed(4)).toString() + "%";
                        if (obj.qoS !== "")
                            obj.qoS = (parseFloat(obj.qoS) * 100).toFixed(4) + "%";
                    })
                }
                dispatch({
                    WeeklyPerformancedata: Perforamncedata,
                    type: ActionType.WEEKLY_PERFORMANCE_RECEIVE
                });

            }).catch(error => {
                const WeeklyPerformancedata = [];
                dispatch({
                    WeeklyPerformancedata,
                    type: ActionType.WEEKLY_PERFORMANCE_RECEIVE_FAIL
                });
            });

    },
    getMonthlyData: (monthstartdate: string, serviceId: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        monthstartdate = moment(monthstartdate).format('MM/DD/YYYY');
        let sid = getState().serviceHealth.selectedServiceId;
        if (sid != 0) {
            let newurl = window.location.origin + window.location.pathname + '?serviceid=' + sid + '&name=Monthly';          
            window.history.pushState({ path: newurl }, '', newurl);
        }
        dispatch({
            type: ActionType.MONTHLYDATA_FETCH
        });
        const lastRefreshedDate = getState().authUser.DataRefreshed;
        // Build http request and success handler in Promise<void> wrapper      
        MonthlyAPI.GetMonthlyData(monthstartdate, serviceId)
            .then((monthlyData: IMonthlyReport[]) => {
                var liKPIValueKeys = [];
                if (isArrayWithLength(monthlyData)) {
                    monthlyData[0].serviceId = sid;
                    monthlyData[0].MonthStartDate = monthstartdate;
                }
                liKPIValueKeys = monthlyData.length > 0 ? Object.keys(monthlyData[0].liKPIValues) : [];
                const MonthlyheaderArr = [];
                var header = '';
                for (var countKPI = 0; countKPI < liKPIValueKeys.length; countKPI++) {
                    header = liKPIValueKeys[countKPI];
                    if (countKPI == liKPIValueKeys.length) {
                        MonthlyheaderArr.push({ width: '145px', val: header.charAt(0).toUpperCase() + header.slice(1) });
                    }
                    else {
                        MonthlyheaderArr.push({ width: '5%', val: header.charAt(0).toUpperCase() + header.slice(1) });
                    }
                }
                const MonthlyKPiArr = [];
                dispatch({
                    monthlyData,
                    MonthlyheaderArr,
                    MonthlyKPiArr,
                    liKPIValueKeys,
                    Datarefershedate: lastRefreshedDate,
                    type: ActionType.MONTHLYDATA_RECEIVE
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.SET_LOADING_FALSE
                });
            });
    },
    requestMultipleViewData: (serviceId: number, stDate: string, enDate: string, multipleviewId: any): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
            dispatch({
                mvdailyData: [],
                mvcolorScheme: [],
                type: ActionType.MULTIPLEVIEW_DATA_REFRESH
            });
            WeeklyAPI.GetDateForRefreshedData(serviceId, multipleviewId.toString())
            .then((Datarefershedate: IPropCalender[]) => {
                dispatch({
                    Datarefershedate,
                    type: ActionType.SET_REFRESHDATE_SUCCESS
                });

            }).catch(error => {
                dispatch({
                    type: ActionType.SET_REFRESHDATE_FAIL
                });
            });
            DailyDataApi.getAllViewDataAsyn(serviceId)
                .then((Allviewdetails_data: IMultiViewDetails[]) => {
                    const Multipleviewdetailsdata = [];
                    if (isArrayWithLength(Allviewdetails_data)) {
                        Allviewdetails_data.map(item => {
                            multipleviewId.map(viewId => {
                                if (viewId !== 0) {
                                    if (!item.isReachability && parseInt(item.viewId.toString()) === parseInt(viewId.toString())) {
                                        Multipleviewdetailsdata.push(item);
                                    }
                                }
                                else {
                                    if (!item.isReachability) {
                                        Multipleviewdetailsdata.push(item);
                                    }
                                }
                            });
                        });
                    }
                    if (isArrayWithLength(Multipleviewdetailsdata)) {
                        const mvcolorScheme = [];
                        Multipleviewdetailsdata.map(item => {
                            const SelectedView = [];
                            const viewdetails = item;
                            SelectedView.push(viewdetails);
                            if (isArrayWithLength(SelectedView)) {
                                DailyDataApi.getColorSchemeAsyn(SelectedView[0].colorSchemeId)
                                    .then((colorlogic: IColorScheme[]) => {
                                        colorlogic.map(item => {
                                            item.viewId = SelectedView[0].viewId;
                                            mvcolorScheme.push(item);
                                        });
                                        dispatch({
                                            Multipleviewdetailsdata: Multipleviewdetailsdata,
                                            mvcolorScheme: mvcolorScheme,
                                            type: ActionType.MULTIVIEW_COLORSCHEME_RECEIVE
                                        });
                                    }).catch(error => {
                                        dispatch({
                                            type: ActionType.SET_LOADING_FALSE
                                        });
                                    });
                            }
                        });
                        //// Build http request and success handler in Promise<void> wrapper
                        DailyDataApi.getMultiViewDailyDataAsyn(serviceId, stDate, enDate, multipleviewId)
                            .then((mvdailyData: IDailyData[]) => {
                                let volume = 0;
                                let failureVolume = 0;
                                const autoIncrementIndex = 0;
                                const index = 0;
                                const mvcomments = [];
                                const sortedCommentsList = [];
                                //let mvuniqueFixedHeaderColumns = [];
                                //const mvuniqueDateHeaders = [];
                                //const CHECKuniqueDateHeaders = [];
                                const originaldatacentersList = [];
                                mvdailyData.map((item, key) => {
                                    // To get the List of Data Centers for displaying it in MSER Comments
                                    if (item.key !== 'Total Volume' && item.key !== 'Daily Agg.') {
                                        originaldatacentersList.push({ id: item.key, name: item.key, viewId: item.viewId })
                                    }
                                });

                                // Logic to remove the Duplicates from an Object Array and Sort Alphabatically
                                let mvdatacentersList = originaldatacentersList.filter((ele, ind) => ind === originaldatacentersList.findIndex(elem => elem.id === ele.id && elem.name === ele.name && elem.viewId === ele.viewId))

                                mvdatacentersList.sort((a, b) => (a.id > b.id) ? 1 : -1);

                                Multipleviewdetailsdata.map(mvitem => {
                                    const SelectedView = [];
                                    const viewdetails = mvitem;
                                    let mvuniqueFixedHeaderColumns = [];
                                    let mvuniqueDateHeaders = [];
                                    let CHECKuniqueDateHeaders = [];
                                    SelectedView.push(viewdetails);
                                    mvitem.mvVolumeLabel = isArrayWithLength(SelectedView) ? (isNullOrUndefined(SelectedView[0].totalsLabel) ? 'Volume' : SelectedView[0].totalsLabel) : 'Volume';
                                    if (isArrayWithLength(SelectedView)) {
                                        //Get longest Datacenter name to set the fixed column width dynamically
                                        let mvlongestDC = mvdatacentersList.filter(mvdatacenter => parseInt(mvdatacenter.viewId.toString()) === parseInt(SelectedView[0].viewId.toString())).reduce((a, b) => a > b.name.length ? a : b.name.length, 0);

                                        // setting the Dc Length for the View Details Item itself so that we can set the fixed column width dynamically
                                        mvitem.mvdclength = mvlongestDC;

                                        mvdailyData.map((item, parentkey) => {
                                            if (parseInt(item.viewId.toString()) === parseInt(SelectedView[0].viewId.toString())) {
                                                let datacenter = item.key;
                                                volume = 0;
                                                failureVolume = 0;
                                                if (item.fixedColumns !== undefined && item.fixedColumns !== null) {
                                                    item.fixedColumns.map(subItem => {
                                                        if (mvuniqueFixedHeaderColumns.indexOf(subItem.key) === -1) {
                                                            mvuniqueFixedHeaderColumns.push(subItem.key)
                                                        }
                                                    })
                                                }

                                                if (!item.isOutOfSlaUptime && !item.isDailyVolume) {
                                                    item.metricList.map((subItem, subkey) => {
                                                        //Flag for MSER Comment indicator
                                                        subItem.hasMserComment = false;

                                                        if (CHECKuniqueDateHeaders.indexOf(subItem.dateKey) === -1) {
                                                            mvuniqueDateHeaders.push({
                                                                'key': subItem.dateKey, 'value': subItem.dateKey, 'sortkey': (isNullOrUndefined(subItem.dateKey) ? '' : new Date(subItem.dateKey))
                                                            });
                                                            CHECKuniqueDateHeaders.push(subItem.dateKey);
                                                        }
                                                        subItem.SortdateKey = new Date(subItem.dateKey.toString());

                                                        if (subItem.volume) {
                                                            volume += subItem.volume;
                                                        }

                                                        if (subItem.failureVolume) {
                                                            failureVolume += subItem.failureVolume;
                                                        }
                                                        //// Define the toolbar for each cell

                                                        subItem.value.value1 = Calc2Decimal(subItem.value.value1);
                                                        subItem.updatedValue1 = Calc2Decimal(subItem.updatedValue1);

                                                        subItem.tooltip = '';
                                                        subItem.IsshowBulk = false;
                                                        if (subItem.volume && subItem.volume > 0) {
                                                            subItem.tooltip = viewdetails.totalsLabel + ': ' + subItem.volume + '\n';
                                                        }
                                                        const noOfDecimals = subItem.value.displayDecimals;
                                                        let val1 = isNullOrUndefined(subItem.value.value1) ? 0 : (displayDecimals(subItem.value.value1, noOfDecimals));
                                                        subItem.tooltip += 'Original ' + viewdetails.val1Label + ': ' + val1;
                                                        //$scope.selectedView.hasVal2 --> set value as true

                                                        if (SelectedView[0].hasVal2) {
                                                            let val2 = isNullOrUndefined(subItem.value.value2) ? 0 : (displayDecimals(subItem.value.value2, noOfDecimals));
                                                            subItem.tooltip += '\nOriginal ' + viewdetails.val2Label + ': ' + val2;
                                                        }
                                                        if (subItem.value.value1 !== subItem.updatedValue1 && subItem.updatedValue1) {
                                                            let updatedValue1 = isNullOrUndefined(subItem.updatedValue1) ? 0 : (displayDecimals(subItem.updatedValue1, noOfDecimals));
                                                            subItem.tooltip += '\nModified  ' + viewdetails.val1Label + ': ' + updatedValue1;
                                                        }

                                                        if (subItem.value.value2 !== subItem.updatedValue2 && subItem.updatedValue2 && true) {
                                                            let updatedValue2 = isNullOrUndefined(subItem.updatedValue2) ? 0 : (displayDecimals(subItem.updatedValue2, noOfDecimals));
                                                            subItem.tooltip += '\nModified  ' + viewdetails.val2Label + ': ' + updatedValue2;
                                                        }

                                                        if (subItem.comment) {
                                                            //subItem.lastUpdated = moment(subItem.lastUpdated).format('Do MMM YYYY').toString();
                                                            subItem.incident = (subItem.incident === undefined || subItem.incident === null) ? '' : subItem.incident;
                                                            subItem.tooltip += '\nComment:  ' + subItem.comment + '\nIncident:  ' + subItem.incident + '\nAuthor:    ' + subItem.author + '\nLast Updated:    ' + moment(subItem.lastUpdated).format('Do MMM YYYY').toString();
                                                        }
                                                        if (!subItem.updatedValue1) {
                                                            subItem.updatedValue1 = subItem.value.value1;
                                                        }
                                                        if (!subItem.updatedValue2) {
                                                            subItem.updatedValue2 = subItem.value.value2;
                                                        }

                                                        if (subItem.comment) {
                                                            var value: any = null;
                                                            mvcomments.map(item => {
                                                                item.viewId = (item.viewId === null || item.viewId == '') ? SelectedView[0].viewId : item.viewId;
                                                                if (item.commentId === subItem.commentId) {
                                                                    value = item;
                                                                }
                                                            })
                                                            if (value != undefined) {
                                                                value.keyDate += ", ( " + item.key + " - " + moment(subItem.dateKey).format("MM/DD") + " )";
                                                                value.lastUpdated = subItem.lastUpdated;
                                                            } else {
                                                                mvcomments.push({
                                                                    comment: subItem.comment,
                                                                    incident: subItem.incident,
                                                                    commentId: subItem.commentId,
                                                                    keyDate: "( " + item.key + " - " + moment(subItem.dateKey).format("MM/DD") + " )",
                                                                    lastUpdated: subItem.lastUpdated,
                                                                    viewId: item.viewId,
                                                                    lastUpdatedDate: new Date(subItem.lastUpdated)
                                                                });
                                                            }

                                                        }

                                                        //})
                                                    })
                                                    const sortedActivities12 = item.metricList.sort((a, b) => a.SortdateKey - b.SortdateKey);
                                                }
                                                if (item.avg !== undefined && item.avg !== null) {
                                                    item.avg.percentage = parseFloat(item.avg.percentage).toFixed(item.avg.displayDecimals);
                                                }
                                                item.sortVolume = item.volume != null ? item.volume : item.failureVolume;
                                                item.sortFailureVolume = (!isNullOrUndefined(failureVolume)) ? item.failureVolume : 0;
                                                item.volume = converttoKMB(item.volume);
                                                item.failureVolume = converttoKMB(item.failureVolume);
                                                item.metricList.map(subItem => {
                                                    subItem.value.percentage = (subItem.value.percentage !== '' && subItem.value.percentage !== undefined) ? parseFloat(subItem.value.percentage).toFixed(subItem.value.displayDecimals) : '';
                                                });

                                                if (item.isTotalVolume) {
                                                    item.metricList.map(subItem => {
                                                        subItem.value.percentage = converttoKMB(subItem.value.percentage);
                                                    });

                                                }
                                            }
                                        });
                                        var element = mvdailyData.filter(mvData => parseInt(mvData.viewId.toString()) === parseInt(SelectedView[0].viewId.toString()));
                                        if (element.length === 0) {
                                            mvitem.mvdailydataPresent = false;
                                        }
                                        else {
                                            mvitem.mvdailydataPresent = true;
                                        }
                                        var ele = mvcomments.filter(comment => parseInt(comment.viewId.toString()) === parseInt(SelectedView[0].viewId.toString()));
                                        if (ele.length === 0) {
                                            mvitem.mvcommentPresent = false;
                                        }
                                        else {
                                            mvitem.mvcommentPresent = true;
                                        }
                                        const dates = mvuniqueDateHeaders.sort((a, b) => a.sortkey - b.sortkey);
                                        mvitem.mvuniqueFixedHeaderColumns = mvuniqueFixedHeaderColumns;
                                        mvitem.mvuniqueDateHeaders = mvuniqueDateHeaders;

                                    }
                                });
                                const sortedActivities1 = mvcomments.sort((a, b) => a.lastUpdatedDate - b.lastUpdatedDate);
                                const sortedData = mvdailyData.sort((a, b) => a.key > b.key ? 1 : -1);
                                dispatch({
                                    mvdailyData: mvdailyData,
                                    Multipleviewdetailsdata: Multipleviewdetailsdata,
                                    mvcomments: mvcomments,
                                    mvcolorScheme: mvcolorScheme,
                                    mvdatacentersList,
                                    multipleviewSelectedOption: multipleviewId,
                                    type: ActionType.MULTIVIEW_RECEIVE
                                });
                            }).catch(error => {
                                dispatch({
                                    type: ActionType.SET_LOADING_FALSE
                                });
                            });

                    }
                });
    },
    requestDailyData: (serviceId: number, stDate: string, enDate: string, viewId: number, selectedTab: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {        
        const state = getState().serviceHealth;
        //dailyDataRaw = [];
        //reachabilityDataRaw = [];
        dispatch({
            dailyData: (selectedTab === 'heatmap') ? [] : state.dailyData,
            ReachabilityData: (selectedTab === 'reachability') ? [] : state.ReachabilityData,
            checkedFilterYellowDailyData: state.checkedFilterYellowDailyData,
            checkedFilterRedDailyData: state.checkedFilterRedDailyData,
            type: ActionType.DATA_REFRESH

        });
        DailyDataApi.getAllViewDataAsyn(serviceId)
            .then((Allviewdetails_data: IAllViewDetails[]) => {
                const Allviewdetailsdata = [];
                const additionalInfoData = [];
                if (isArrayWithLength(Allviewdetails_data)) {
                    Allviewdetails_data.map(item => {
                        if (viewId !== 0) {
                            if (selectedTab !== 'reachability') {
                                if (!item.isReachability && parseInt(item.viewId.toString()) === parseInt(viewId.toString())) {
                                    Allviewdetailsdata.push(item);
                                }
                            }
                            else if (selectedTab === 'reachability') {
                                if (item.isReachability && parseInt(item.viewId.toString()) === parseInt(viewId.toString())) {
                                    Allviewdetailsdata.push(item);
                                }
                            }
                        }
                        else {
                            if (selectedTab !== 'reachability') {
                                if (!item.isReachability) {
                                    Allviewdetailsdata.push(item);
                                }
                            }
                            else if (selectedTab === 'reachability') {
                                if (item.isReachability) {
                                    Allviewdetailsdata.push(item);
                                }
                            }
                        }


                    });
                }
                if (isArrayWithLength(Allviewdetailsdata)) {
                    const SelectedView = [];
                    const colorScheme = [];
                    const viewdetails = Allviewdetailsdata[0];
                    SelectedView.push(viewdetails);
                    if (isArrayWithLength(SelectedView)) {
                        DailyDataApi.getColorSchemeAsyn(SelectedView[0].colorSchemeId)
                            .then((colorlogic: IColorScheme[]) => {
                                colorlogic.map(item => {
                                    colorScheme.push(item);
                                });
                            });
                    }

                    if (isArrayWithLength(SelectedView)) {
                        DailyDataApi.getMSERCommentsAdditionalInfo(viewdetails.viewId, stDate, enDate)
                            .then((mserAdditionalInfo: IMSERAdditionalInfo[]) => {
                                if (isArrayWithLength(mserAdditionalInfo)) {
                                    mserAdditionalInfo.map(row => {
                                        additionalInfoData.push({
                                            additionInfo: row.dataCenter,
                                            additionInfoId: row.additionInfoId,
                                            keyDate: row.dataCenter + " - " + moment(row.effectiveDate).format("MM/DD"),
                                            impact: row.impact,
                                            rootCause: row.rootCause,
                                            mitigation: row.mitigation,
                                            learningsAndRepairItems: row.learningsAndRepairItems,
                                            lastUpdated: row.updatedDate,
                                            effectiveDate: moment(row.effectiveDate).format("MM/DD/YYYY")
                                        });
                                    });
                                }
                                WeeklyAPI.GetDateForRefreshedData(serviceId, viewdetails.viewId.toString())
                                    .then((Datarefershedate: IPropCalender[]) => {
                                        dispatch({
                                            Datarefershedate,
                                            type: ActionType.SET_REFRESHDATE_SUCCESS
                                        });

                                    }).catch(error => {
                                        dispatch({
                                            type: ActionType.SET_REFRESHDATE_FAIL
                                        });
                                    });

                                // Build http request and success handler in Promise<void> wrapper
                                DailyDataApi.getDailyDataAsyn(serviceId, stDate, enDate, viewdetails.viewId)
                                    .then((dailyData: IDailyData[]) => {
                                        let volume = 0;
                                        let failureVolume = 0;
                                        const autoIncrementIndex = 0;
                                        const index = 0;
                                        const comments = [];
                                        const sortedCommentsList = [];
                                        let uniqueFixedHeaderColumns = [];
                                        const uniqueDateHeaders = [];
                                        const CHECKuniqueDateHeaders = [];
                                        let chkMSER = false;
                                        let TSGValue = '';
                                        let chkMonltyAvailability = false;
                                        const originaldatacentersList = [];
                                        dailyData.map((item, key) => {
                                            // To get the List of Data Centers for displaying it in MSER Comments
                                            if (item.key !== 'Total Volume' && item.key !== 'Daily Agg.') {
                                                originaldatacentersList.push({ id: item.key, name: item.key })
                                            }
                                        })
                                        // Logic to remove the Duplicates from an Object Array and Sort Alphabatically
                                        let datacentersList = originaldatacentersList.filter((ele, ind) => ind === originaldatacentersList.findIndex(elem => elem.id === ele.id && elem.name === ele.name))

                                        datacentersList.sort((a, b) => (a.id > b.id) ? 1 : -1);

                                        //Get longest Datacenter name to set the fixed column width dynamically
                                        let longestDC = datacentersList.reduce((a, b) => a > b.name.length ? a : b.name.length, 0);

                                        // MSER and IsMonthlyAvaialability Data will not be updated if there is no Daily Data.. To overcome placed the block here
                                        if (!isNullOrUndefined(SelectedView[0])) {
                                            chkMSER = SelectedView[0].isMserView;
                                            chkMonltyAvailability = SelectedView[0].isMonthlyAvailability;
                                            TSGValue = SelectedView[0].tsg;
                                        }
                                        dailyData.map((item, parentkey) => {
                                            let datacenter = item.key;
                                            volume = 0;
                                            failureVolume = 0;
                                            if (item.fixedColumns !== undefined && item.fixedColumns !== null) {
                                                item.fixedColumns.map(subItem => {
                                                    if (uniqueFixedHeaderColumns.indexOf(subItem.key) === -1) {
                                                        uniqueFixedHeaderColumns.push(subItem.key)
                                                    }
                                                })
                                            }


                                            if (!item.isOutOfSlaUptime && !item.isDailyVolume) {
                                                item.metricList.map((subItem, subkey) => {
                                                    //Flag for MSER Comment indicator
                                                    subItem.hasMserComment = false;
                                                    additionalInfoData.map((row, rowkey) => {
                                                        if (row.additionInfo.split(',').includes(datacenter) && moment(row.effectiveDate).format("MM/DD/YYYY") == moment(subItem.dateKey).format("MM/DD/YYYY")) {
                                                            subItem.hasMserComment = true;
                                                        }
                                                    });

                                                    if (CHECKuniqueDateHeaders.indexOf(subItem.dateKey) === -1) {
                                                        uniqueDateHeaders.push({
                                                            'key': subItem.dateKey, 'value': subItem.dateKey, 'sortkey': (isNullOrUndefined(subItem.dateKey)?'': new Date(subItem.dateKey))
                                                        });
                                                        CHECKuniqueDateHeaders.push(subItem.dateKey);
                                                    }
                                                    subItem.SortdateKey = new Date(subItem.dateKey.toString());

                                                    if (subItem.volume) {
                                                        volume += subItem.volume;
                                                    }

                                                    if (subItem.failureVolume) {
                                                        failureVolume += subItem.failureVolume;
                                                    }
                                                    //// Define the toolbar for each cell

                                                    subItem.value.value1 = Calc2Decimal(subItem.value.value1);
                                                    subItem.updatedValue1 = Calc2Decimal(subItem.updatedValue1);

                                                    subItem.tooltip = '';
                                                    subItem.IsshowBulk = false;
                                                    if (subItem.volume && subItem.volume > 0) {
                                                        subItem.tooltip = viewdetails.totalsLabel + ': ' + subItem.volume + '\n';
                                                    }
                                                    const noOfDecimals = subItem.value.displayDecimals;
                                                    let val1 = isNullOrUndefined(subItem.value.value1) ? 0 : (displayDecimals(subItem.value.value1, noOfDecimals));
                                                    if (subItem.updatedValue1 == undefined && subItem.updatedValue2 == null) {
                                                        if (isNullOrUndefined(subItem.value.value1)) {
                                                            subItem.tooltip += 'Original ' + viewdetails.val1Label + ': ' + 'No request available';
                                                        }
                                                        else if (Number(subItem.value.percentage) == 0) {
                                                            subItem.tooltip += 'Original ' + viewdetails.val1Label + ': ' + 'Very low traffic';
                                                        }
                                                        else {
                                                            subItem.tooltip += 'Original ' + viewdetails.val1Label + ': ' + subItem.value.percentage;
                                                        }
                                                    }
                                                    else {
                                                        if (isNullOrUndefined(subItem.value.value1)) {
                                                            subItem.tooltip += 'Original ' + viewdetails.val1Label + ': ' + 'No request available';
                                                        }
                                                        else if (Number(subItem.value.value1) == 0) {
                                                            subItem.tooltip += 'Original ' + viewdetails.val1Label + ': ' + 'Very low traffic';
                                                        }
                                                        else {
                                                            subItem.tooltip += 'Original ' + viewdetails.val1Label + ': ' + val1;
                                                        }
                                                    }
                                                    
                                                    //$scope.selectedView.hasVal2 --> set value as true

                                                    if (SelectedView[0].hasVal2) {
                                                        let val2 = isNullOrUndefined(subItem.value.value2) ? 0 : (displayDecimals(subItem.value.value2, noOfDecimals));
                                                        subItem.tooltip += '\nOriginal ' + viewdetails.val2Label + ': ' + val2;
                                                    }
                                                    if (subItem.value.value1 !== subItem.updatedValue1 && subItem.updatedValue1) {
                                                        let updatedValue1 = isNullOrUndefined(subItem.updatedValue1) ? 0 : (displayDecimals(subItem.updatedValue1, noOfDecimals));
                                                        if (Number(subItem.updatedValue1) == 0) {
                                                            subItem.tooltip += '\nModified  ' + viewdetails.val1Label + ': ' + 'Very low traffic';
                                                        }
                                                        else {
                                                            subItem.tooltip += '\nModified  ' + viewdetails.val1Label + ': ' + updatedValue1;
                                                        }
                                                    }
                                                    
                                                    if (subItem.value.value2 !== subItem.updatedValue2 && subItem.updatedValue2 && true) {
                                                        let updatedValue2 = isNullOrUndefined(subItem.updatedValue2) ? 0 : (displayDecimals(subItem.updatedValue2, noOfDecimals));
                                                        subItem.tooltip += '\nModified  ' + viewdetails.val2Label + ': ' + updatedValue2;
                                                    }
                                                    
                                                    if (subItem.comment) {
                                                        //subItem.lastUpdated = moment(subItem.lastUpdated).format('Do MMM YYYY').toString();
                                                        subItem.incident = (subItem.incident === undefined || subItem.incident === null) ? '' : subItem.incident;
                                                        let formattedStatus = isNullOrUndefined(subItem.status) ? '' : subItem.status;
                                                        let formattedProblemStatement = isNullOrUndefined(subItem.problemStatement) ? '' : subItem.problemStatement;
                                                        let formattedRootCause = isNullOrUndefined(subItem.rootCause) ? '' : subItem.rootCause;
                                                        let formattedOwningTeam = isNullOrUndefined(subItem.owningTeam) ? '' : subItem.owningTeam;
                                                        let formattedImpact = isNullOrUndefined(subItem.impact) ? '' : subItem.impact;
                                                        subItem.tooltip += '\nComment:  ' + subItem.comment + '\nStatus:  ' + formattedStatus + '\nProblem statement:  ' + formattedProblemStatement + '\nRoot Cause:  ' + formattedRootCause + '\nTeam owning root cause:  ' + formattedOwningTeam + '\nImpact:  ' + formattedImpact + '\nIncident:  ' + subItem.incident + '\nAuthor:    ' + subItem.author + '\nLast Updated:    ' + moment(subItem.lastUpdated).format('Do MMM YYYY').toString();
                                                    }
                                                    if (!subItem.updatedValue1) {
                                                        subItem.updatedValue1 = subItem.value.value1;
                                                    }
                                                    if (!subItem.updatedValue2) {
                                                        subItem.updatedValue2 = subItem.value.value2;
                                                    }
                                                    //subItem.updatedValue1 = subItem.value.value1;
                                                    //subItem.updatedValue2 = subItem.value.value2;
                                       

                                                    if (subItem.comment) {
                                                        var value: any = null;
                                                        comments.map(item => {
                                                            if (item.commentId === subItem.commentId) {
                                                                value = item;
                                                            }
                                                        })
                                                        if (value != undefined) {
                                                            value.keyDate += "," + item.key + " - " + moment(subItem.dateKey).format("MM/DD");
                                                            value.lastUpdated = subItem.lastUpdated;
                                                        } else {
                                                            comments.push({
                                                                comment: subItem.comment,
                                                                incident: subItem.incident,
                                                                // index: autoIncrementIndex,
                                                                commentId: subItem.commentId,
                                                                keyDate: item.key + " - " + moment(subItem.dateKey).format("MM/DD"),
                                                                lastUpdated: subItem.lastUpdated,
                                                                lastUpdatedDate: new Date(subItem.lastUpdated),
                                                                status: subItem.status,
                                                                problemStatement: subItem.problemStatement,
                                                                rootCause: subItem.rootCause,
                                                                owningTeam: subItem.owningTeam,
                                                                impact: subItem.impact
                                                            });
                                                        }

                                                    }

                                                    if (subItem.updatedColorRules !== null) {
                                                        let UpdatatedColorRule = '';

                                                        subItem.updatedColorRules.forEach((item, index) => {
                                                            const text = buildDescription(item);
                                                            UpdatatedColorRule += `${text} \n`;
                                                        });
                                                        subItem.tooltip += '\nUpdated Rules:  ' + UpdatatedColorRule;
                                                    }
                                                })
                                                const sortedActivities12 = item.metricList.sort((a, b) => a.SortdateKey - b.SortdateKey);
                                            }
                                            if (item.avg !== undefined && item.avg !== null) {
                                                item.avg.percentage = parseFloat(item.avg.percentage).toFixed(item.avg.displayDecimals);
                                            }
                                            item.sortVolume = item.volume != null ? item.volume : item.failureVolume;
                                            item.sortFailureVolume = (!isNullOrUndefined(failureVolume)) ? item.failureVolume : 0;
                                            item.volume = converttoKMB(item.volume);
                                            item.failureVolume = converttoKMB(item.failureVolume);
                                            item.metricList.map(subItem => {
                                                subItem.value.percentage = (subItem.value.percentage !== '' && subItem.value.percentage !== undefined) ? parseFloat(subItem.value.percentage).toFixed(subItem.value.displayDecimals) : '';
                                            });


                                            if (item.isTotalVolume) {
                                                item.metricList.map(subItem => {
                                                    subItem.value.percentage = converttoKMB(subItem.value.percentage);
                                                });

                                            }

                                        })

                                        const sortedActivities1 = comments.sort((a, b) => a.lastUpdatedDate - b.lastUpdatedDate);
                                        //const additionalInfoData = [];
                                        //if (isArrayWithLength(SelectedView)) {
                                        //    DailyDataApi.getMSERCommentsAdditionalInfo(viewdetails.viewId, stDate, enDate)
                                        //        .then((mserAdditionalInfo: IMSERAdditionalInfo[]) => {
                                        //            if(isArrayWithLength(mserAdditionalInfo)){
                                        //                mserAdditionalInfo.map(row => {
                                        //                    additionalInfoData.push({
                                        //                        additionInfo: row.dataCenter,
                                        //                        additionInfoId: row.additionInfoId,
                                        //                        keyDate: row.dataCenter + " - " + moment(row.effectiveDate).format("MM/DD"),
                                        //                        impact: row.impact,
                                        //                        rootCause: row.rootCause,
                                        //                        mitigation: row.mitigation,
                                        //                        learningsAndRepairItems: row.learningsAndRepairItems,
                                        //                        lastUpdated: row.updatedDate,
                                        //                        effectiveDate: moment(row.effectiveDate).format("MM/DD/YYYY")
                                        //                    });
                                        //                });
                                        //            }

                                        // Sorting the Additional Info based on Effective Date Descendingly
                                        additionalInfoData.sort((a, b) => {
                                            a = a.effectiveDate.split('/').reverse().join('');
                                            b = b.effectiveDate.split('/').reverse().join('');
                                            return b.localeCompare(a);
                                        });
                                        //        });
                                        //}
                                        let IsshowDrilldownIcon = false;
                                        let IsshowEmail = false;
                                        if (isArrayWithLength(dailyData)) {
                                            if (dailyData[0].queryForDrillDown !== null && dailyData[0].queryForDrillDown !== '') {
                                                IsshowDrilldownIcon = true;
                                            }
                                            if (dailyData[0].toEmailList !== null && dailyData[0].toEmailList !== '') {
                                                IsshowEmail = true;
                                            }
                                        }
                                        const dates = uniqueDateHeaders.sort((a, b) => a.sortkey - b.sortkey);
                                        dispatch({
                                            dailyData: (selectedTab === 'heatmap') ? dailyData : state.dailyData,
                                            uniqueFixedHeaderColumns: (selectedTab === 'heatmap') ? uniqueFixedHeaderColumns : state.uniqueFixedHeaderColumns,
                                            uniqueDateHeaders: (selectedTab === 'heatmap') ? uniqueDateHeaders : state.uniqueDateHeaders,
                                            SelectedView: (selectedTab === 'heatmap') ? SelectedView : state.SelectedView,
                                            comments: (selectedTab === 'heatmap') ? comments : state.comments,
                                            colorScheme: (selectedTab === 'heatmap') ? colorScheme : state.colorScheme,
                                            ReachabilityData: (selectedTab === 'reachability') ? dailyData : state.ReachabilityData,
                                            ReachabilityuniqueFixedHeaderColumns: (selectedTab === 'reachability') ? uniqueFixedHeaderColumns : state.ReachabilityuniqueFixedHeaderColumns,
                                            ReachabilityuniqueDateHeaders: (selectedTab === 'reachability') ? uniqueDateHeaders : state.ReachabilityuniqueDateHeaders,
                                            ReachabilitySelectedView: (selectedTab === 'reachability') ? SelectedView : state.ReachabilitySelectedView,
                                            Reachabilitycomments: (selectedTab === 'reachability') ? comments : state.Reachabilitycomments,
                                            ReachabilitycolorScheme: (selectedTab === 'reachability') ? colorScheme : state.ReachabilitycolorScheme,
                                            IsshowDrilldownIcon: IsshowDrilldownIcon,
                                            IsshowEmail: IsshowEmail,
                                            //viewdetailsdata,                                      
                                            chkMSER,
                                            TSGValue,
                                            chkMonltyAvailability,
                                            datacentersList,
                                            additionalInfoData,
                                            longestDC,
                                            type: ActionType.RECEIVE
                                        });
                                        dailyDataRaw = (selectedTab === 'heatmap') ? dailyData : state.dailyData;
                                        reachabilityDataRaw = (selectedTab === 'reachability') ? dailyData : state.ReachabilityData;
                                    }).catch(error => {
                                        dispatch({
                                            type: ActionType.HEATMAP_DAILYDATA_FAILURE
                                        });
                                    });

                            });
                    }
                }
                else {
                    dispatch({
                        dailyData: (selectedTab === 'heatmap') ? [] : state.dailyData,
                        uniqueFixedHeaderColumns: (selectedTab === 'heatmap') ? [] : state.uniqueFixedHeaderColumns,
                        uniqueDateHeaders: (selectedTab === 'heatmap') ? [] : state.uniqueDateHeaders,
                        SelectedView: (selectedTab === 'heatmap') ? [] : state.SelectedView,
                        comments: (selectedTab === 'heatmap') ? [] : state.comments,
                        colorScheme: (selectedTab === 'heatmap') ? [] : state.colorScheme,
                        ReachabilityData: (selectedTab === 'reachability') ? [] : state.ReachabilityData,
                        ReachabilityuniqueFixedHeaderColumns: (selectedTab === 'reachability') ? [] : state.ReachabilityuniqueFixedHeaderColumns,
                        ReachabilityuniqueDateHeaders: (selectedTab === 'reachability') ? [] : state.ReachabilityuniqueDateHeaders,
                        ReachabilitySelectedView: (selectedTab === 'reachability') ? [] : state.ReachabilitySelectedView,
                        Reachabilitycomments: (selectedTab === 'reachability') ? [] : state.Reachabilitycomments,
                        ReachabilitycolorScheme: (selectedTab === 'reachability') ? [] : state.ReachabilitycolorScheme,
                        type: ActionType.DAILYDATAFAIL
                    });
                    dailyDataRaw = (selectedTab === 'heatmap') ? [] : state.dailyData;
                    reachabilityDataRaw = (selectedTab === 'reachability') ? [] : state.ReachabilityData;
                }
            })
            .catch(error => {
                dispatch({
                    type: ActionType.DAILYDATAFAIL
                });
            })
        // });

    },
    getReachabilityServices: (currentservice: string,val): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let rechabilityServices = [];
        let selectedServiceLabel = '';
        let selectedServiceValue = '';
        // let selectedServiceDropdownOption = null;
        rechabilityServices = getState().serviceHealth.reachabilityServices;
        if (val===0 && isArrayWithLength(rechabilityServices)) {
            // SET Selected service to dropdown list.       
            var element = [];
            rechabilityServices.map((item) => {
                if (parseInt(item.value) === parseInt(currentservice)) {
                    element.push(item);
                }
            });
            selectedServiceLabel = element.length !== 0 ? element[0].label : rechabilityServices[0].label;
            selectedServiceValue = element.length !== 0 ? element[0].value : rechabilityServices[0].value;

            // Dispatch request
            dispatch({
                rechabilityServices,
                selectedReachabilityService: { 'label': selectedServiceLabel, 'value': selectedServiceValue },
                type: ActionType.FETCH_REACHABILITY_SERVICES
            });
        }
        else {

            DailyDataApi.GetHeatmapServices()
                .then((allServices: IServices[]) => {
                    if (isArrayWithLength(allServices)) {
                        for (var i = 0; i < allServices.length; i++) {
                            if (allServices[i].isReachability) {
                                const obj = {} as IDropdownOption;
                                obj.value = allServices[i].key;
                                obj.label = allServices[i].value;
                                rechabilityServices.push(obj);
                            }
                        }
                        // SET Selected service to dropdown list.
                        if (isArrayWithLength(rechabilityServices)) {
                            var element = [];
                            rechabilityServices.map((item) => {
                                if (parseInt(item.value) === parseInt(currentservice)) {
                                    element.push(item);
                                }
                            });
                            selectedServiceLabel = element.length !== 0 ? element[0].label : rechabilityServices[0].label;
                            selectedServiceValue = element.length !== 0 ? element[0].value : rechabilityServices[0].value;
                        }
                    }
                    if (isArrayWithLength(rechabilityServices)) {
                        dispatch({
                            rechabilityServices,
                            selectedReachabilityService: { 'label': selectedServiceLabel, 'value': selectedServiceValue },
                            type: ActionType.FETCH_REACHABILITY_SERVICES
                        });

                    }

                })
                .catch(error => {
                    dispatch({
                        type: ActionType.REACHABILITY_SERVICE_RECEIVE_FAIL
                    });
                });
        }
    },

    reachabilityServiceOnSelectOption: (reachabilityServiceData: IDropdownOption): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const allReachabilityviewsData = [];
        let Currentview = null;
        let selectedViewDropdownOption = null;
        DailyDataApi.getHeatmapView(reachabilityServiceData.value)
            .then((dailyViewData: IViewDetails[]) => {
                const dailyViewList = [];
                const viewdetailsdata = [];
                if (isArrayWithLength(dailyViewData)) {
                    dailyViewData.map((item, key) => {
                        const lstObj = {} as IDropdownOption;
                        lstObj.value = parseInt(item.viewId);
                        lstObj.label = item.viewName;
                        allReachabilityviewsData.push(item);
                        if (dailyViewList.indexOf(lstObj.value) === -1) {
                            if (item.isReachability) {
                                viewdetailsdata.push(item);
                                dailyViewList.push(lstObj)
                            }
                        }
                    })
                }

                if (isArrayWithLength(dailyViewData)) {
                    Currentview = viewdetailsdata[0];
                }
                else {
                    Currentview = null;
                    selectedViewDropdownOption = null;
                }

                if (Currentview != null) {
                    selectedViewDropdownOption = { 'label': Currentview.viewName, 'value': Currentview.viewId }
                }

                dispatch({
                    dailyViewList,
                    viewdetailsdata,
                    allReachabilityviewsData: allReachabilityviewsData,
                    Currentview: Currentview,
                    selectedViewDropdownOption: selectedViewDropdownOption,
                    selectedReachabilityService: { 'label': reachabilityServiceData.label, 'value': reachabilityServiceData.value },
                    type: ActionType.REACHABILITY_SERVICE_DROPDOWN_SELECT
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.REACHABILITY_SERVICE_VIEW_FAIL
                });
            });
    },
    GetDropDownDataWeeklySelfServe: (Callcount, selectedServiceval): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.GET_WEEKLYSELFSERVE_REQUEST
        });
        let count = 0;
        if (Callcount != 6) {
            count = 6;
        }
        if (Callcount === 1 || Callcount === 6) {
             //==================== to get data for Service Groups =================//
            ServiceGroupApi.GetServiceGroupsWeeklySelfServe()
                .then((serviceGroupRaw: IServiceGroup[]) => {
                    const serviceGroups=[];
                    if (isArrayWithLength(serviceGroupRaw)) {
                        serviceGroupRaw.map((item: IServiceGroup, key) => {
                            var element = serviceGroups.filter(serGroup => serGroup.name === item.groupName.replace(new RegExp('<br/>', 'g'), ' – '));
                            if (element.length === 0) {
                                serviceGroups.push({ name: item.groupName.replace(new RegExp('<br/>', 'g'), ' – '), value: item.groupId });
                            }
                        })
                    }
                    count = count + 1;
                    dispatch({
                        count: count,
                        serviceGroups,
                        type: ActionType.GET_SERVICEGROUPS_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_SERVICEGROUPS_FAIL
                    });
                });
        }
        if (Callcount === 2 || Callcount === 6) {
            //==================== to get data for Services =================//
            //passing empty string to fetch all the services irrespective of Group
            WeeklyAPI.GetServices('')
                .then((serviceRaw: IServiceGroup[]) => {
                    const services = [];
                    if (isArrayWithLength(serviceRaw)) {
                        serviceRaw.map((item: IServiceGroup) => {
                            services.push({ name: item.serviceName, value: item.serviceId });
                        })
                    }
                    count = count + 1;
                    dispatch({
                        count: count,
                        services,
                        selectedServiceval: selectedServiceval,
                        type: ActionType.GET_SERVICELISTS_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_SERVICELISTS_FAIL
                    });
                });
        }
        if (Callcount === 3 || Callcount === 6) {
            //==================== to get data for Target types =================//
            WeeklyAPI.GetTargetTypes()
                .then((targetTypesRaw: PropTarget[]) => {
                    const targetTypes = [];
                    if (isArrayWithLength(targetTypesRaw)) {
                        targetTypesRaw.map((obj: PropTarget) => {
                            targetTypes.push({ name: obj.targetName, value: obj.targetId });
                        })
                    }
                    count = count + 1;
                    dispatch({
                        count: count,
                        targetTypes,
                        type: ActionType.GET_TARGETTYPE_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_TARGETTYPE_FAIL
                    });
                });

            //==================== to get data for Authentication types =================//
            WeeklyAPI.GetAuthenticationType()
                .then((authenticationSource: PropAuthenticationType[]) => {
                    const authenticationTypes = [];
                    if (isArrayWithLength(authenticationSource)) {
                        authenticationSource.map((item: PropAuthenticationType) => {
                            authenticationTypes.push({ name: item.AuthenticationType, value: item.Id });
                        })
                    }
                    /*count = count + 1;*/
                    dispatch({
                        /*count: count,*/
                        authenticationTypes,
                        type: ActionType.GET_AUTHENTICATIONTYPE_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        type: ActionType.GET_AUTHENTICATIONTYPE_FAIL
                    });
                });

        }
        if (Callcount === 4 || Callcount === 6) {
            //==================== to get data for Metric Groups =================//
            WeeklyAPI.GetMetricGroups()
                .then((metricGroupRaw: PropMetricGroups[]) => {
                    const metricGroups = [];
                    if (isArrayWithLength(metricGroupRaw)) {
                        metricGroupRaw.map((obj: PropMetricGroups) => {
                            //Filtering only Availability Metric Group ,since it is only Automated                            
                            if (obj.metricGroupId != 4 && obj.metricGroupId != 5 && obj.metricGroupId != 6 && obj.metricGroupId != 7 && obj.metricGroupId != 12) {
                                metricGroups.push({ name: obj.metricGroupName, value: obj.metricGroupId });
                            }
                        })
                    }
                    count = count + 1;
                    metricGroups.unshift({ name: 'Add New Metric Group', value: 'New'});
                    dispatch({
                        count: count,
                        metricGroups,
                        type: ActionType.GET_METRICGROUPS_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_METRICGROUPS_FAIL
                    });
                });
        }
        if (Callcount === 5 || Callcount === 6) {
            //==================== to get data for Metric Environments =================//
            WeeklyAPI.GetMetricEnvirnoments()
                .then((environmentRaw: PropEnvironment[]) => {
                    const environments = [];
                    if (isArrayWithLength(environmentRaw)) {
                        environmentRaw.map((item: PropEnvironment) => {
                            environments.push({ name: item.value, value: item.key });
                        })
                    }
                    count = count + 1;
                    dispatch({
                        count: count,
                        environments,
                        type: ActionType.GET_ENVIRONMENT_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_ENVIRONMENT_FAIL
                    });
                });
        }
        if (Callcount === 6 || Callcount === 7) {
            //==================== to get data for Metric Data Sources =================//
            WeeklyAPI.GetDataSources()
                .then((dataSourceRaw: PropDataSource[]) => {
                    const datasource = [];
                    if (isArrayWithLength(dataSourceRaw)) {
                        dataSourceRaw.map((item: PropDataSource) => {
                            //Filtering only Kusto Metric Data Source ,since it is only Automated
                            //if (item.Id === 1 || item.Id === 6) {
                                datasource.push({ name: item.SourceName, value: item.Id });
                            //}
                        })
                    }
                    count = count + 1;
                    dispatch({
                        count: count,
                        datasource,
                        type: ActionType.GET_DATASOURCE_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_DATASOURCE_FAIL
                    });
                });
        }
    },
    GetMetricDataSources: (selectedServiceval): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.GET_METRICDATASOURCE_REQUEST
        });
        const activeMetrics = [];
        const metricsRaw = [];
        const metricsRawService = [];
        var Servicegroupval;
        var selectedServicegroupval = null;
        const onboardNewMetric = getState().serviceHealth.onboardNewMetric;
        WeeklyAPI.GetActiveMetricsWeeklySelfServe(parseInt(selectedServiceval.toString()))
            .then((metricRaw: PropMetricType[]) => {
                if (isArrayWithLength(metricRaw)) {
                    metricRaw.map((obj: PropMetricType) => {
                        metricsRaw.push({ name: obj.metricDisplayName, value: obj.MetricId, metricGroupId: obj.metricGroupId, envId: obj.envId });                        
                    })
                }

                WeeklyAPI.GetserviceGroupWeeklySelfServe(parseInt(selectedServiceval.toString()))
                    .then((metricRaw: PropServiceDetail[]) => {
                        if (isArrayWithLength(metricRaw)) {
                            metricRaw.map((obj: PropServiceDetail) => {
                                Servicegroupval = obj.GroupName.replace(new RegExp('<br/>', 'g'), ' – ');
                            })

                            let serviceGroups = getState().serviceHealth.serviceGroups;

                            // Logic to set the Service Group default to selected service if Service Group exists for the corresponding service else 
                            // to set with the null by default
                            var element = serviceGroups.filter(item => item.name === Servicegroupval);
                            selectedServicegroupval = element.length !== 0 ? element[0].value : "";

                            dispatch({
                                selectedServiceval,
                                selectedServicegroupval,
                                activeMetrics,
                                metricsRaw,
                                metricsRawService,
                                selectedDatasource : '',
                                type: ActionType.SERVICES_CHANGE
                            });
                        }
                     WeeklyAPI.GetMetricDataSourceDetails(parseInt(selectedServiceval.toString()))
                         .then((metricDataSourceRaw: PropMetricDataSource[]) => {
                             const metricDataSourceDetails = [];
                             const metricDataSourceDetailsRaw = [];    
                        if (isArrayWithLength(metricDataSourceRaw)) {
                            metricDataSourceRaw.map((mdsobj: PropMetricDataSource) => {
                               //Filtering only Availability Metric Group and Kusto Data Source related queries ,since it is only Automated
                                //Need to update this logic for the current requirement we are bringing all the metricGroups data. So remove MetricID=1 condition                           
                                if (mdsobj.serviceId === parseInt(selectedServiceval.toString())) {
                                    metricDataSourceDetails.push(mdsobj);
                                    metricDataSourceDetailsRaw.push(mdsobj);
                                }
                            })
                        }
                        if (onboardNewMetric === false) {
                            // Logic to filter only Metric Id's corresponding to selected service
                            metricDataSourceDetailsRaw.map((mdsobj: PropMetricDataSource) => {
                                metricsRaw.map((obj) => {
                                    if (mdsobj.metricId === obj.value) {
                                        // Logic to remove to Duplicate Metrics 
                                        var element = metricsRawService.filter(item => item.value === mdsobj.metricId);
                                        if (element.length === 0) {
                                            activeMetrics.push({ name: obj.name, value: obj.value, metricGroupId: obj.metricGroupId, envId: obj.envId });
                                            metricsRawService.push({ name: obj.name, value: obj.value, metricGroupId: obj.metricGroupId, envId: obj.envId });
                                        }
                                    }
                                })
                            })
                        }
                        else {
                            metricsRaw.map((obj) => {
                                activeMetrics.push({ name: obj.name, value: obj.value, metricGroupId: obj.metricGroupId, envId: obj.envId });
                            })
                        }
                        activeMetrics.unshift({ name: 'Add New Metric', value: 'New', metricGroupId: 0, envId: 0 });
                             dispatch({
                            selectedServiceval,
                            metricDataSourceDetails,
                            metricDataSourceDetailsRaw,
                            type: ActionType.GET_METRICDATASOURCE_SUCCESS
                        });
                        dispatch({
                            selectedServiceval,
                            selectedServicegroupval,
                            activeMetrics,
                            metricsRaw,
                            metricsRawService,
                            selectedDatasource: '',
                            type: ActionType.SERVICES_CHANGE
                        });
                    }).catch(error => {
                        dispatch({
                            type: ActionType.GET_METRICDATASOURCE_FAIL
                        });
                    });
                    }).catch(error => {
                        dispatch({
                            type: ActionType.SET_LOADING_FALSE
                        });
                    });
            }).catch(error => {
                dispatch({
                    type: ActionType.SET_LOADING_FALSE
                });
            });
    },
    handleServiceGroupChange: (selectedServicegroupval: WeeklyDropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        dispatch({
            type: ActionType.RESET_FILTER // To Reset all other Control Values
        });
        dispatch({
            selectedServicegroupval,
            type: ActionType.SERVICEGROUP_CHANGE
        });
        //==================== to get data for Services corresponding to selected Service Group=================//
        WeeklyAPI.GetServices(selectedServicegroupval.toString())
            .then((serviceRaw: IServiceGroup[]) => {
                const services = [];
                if (isArrayWithLength(serviceRaw)) {
                    serviceRaw.map((item: IServiceGroup) => {
                        services.push({ name: item.serviceName, value: item.serviceId });
                    })
                }
                dispatch({
                    services,
                    selectedServiceval: null,
                    type: ActionType.GET_SERVICELISTS_SUCCESS
                });
                dispatch({
                    type: ActionType.SET_LOADING_FALSE
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.GET_SERVICELISTS_FAIL
                });
            });
    },
    handleServicesChange: (selectedServiceval: WeeklyDropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.RESET_FILTER // To Reset all other Control Values
        });
        dispatch({
            selectedServiceval,
            type: ActionType.SERVICES_CHANGE
        });
    },
    handleMetricGroupChange: (selectedMetricgroupval: WeeklyDropdownOption): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        var metricsRaw = getState().serviceHealth.metricsRaw;
        var metricsRawService = getState().serviceHealth.metricsRawService;
        const onboardNewMetric = getState().serviceHealth.onboardNewMetric;
        var selectedMetricgroupvalue = selectedMetricgroupval === null ? '' : selectedMetricgroupval;
        var selectedEnvironment = getState().serviceHealth.selectedEnvironment === null ? '' : getState().serviceHealth.selectedEnvironment;
        var metricDataSourceDetails = getState().serviceHealth.metricDataSourceDetails;
        const selectedDatasource = getState().serviceHealth.selectedDatasource === null ? '' : getState().serviceHealth.selectedDatasource;
        var filterMetrics = [];

        if (onboardNewMetric === true) {
            metricsRaw.map((row) => {
                if ((selectedMetricgroupvalue === '' || row.metricGroupId === parseInt(selectedMetricgroupvalue.toString()))
                    && (selectedEnvironment === '' || row.envId === parseInt(selectedEnvironment.toString()))) {
                    var datasourceelement = metricDataSourceDetails.filter(item => item.metricId === row.value);
                    if (datasourceelement.length === 0) {
                        filterMetrics.push(row);
                    }
                }
            })
        }
        else {
            metricsRawService.map((row: PropMetricType) => {
                if ((selectedMetricgroupvalue === '' || row.metricGroupId === parseInt(selectedMetricgroupvalue.toString()))
                    && (selectedEnvironment === '' || row.envId === parseInt(selectedEnvironment.toString()))) {
                    filterMetrics.push(row);
                }
            })
        }
        let groupValue = parseInt(selectedMetricgroupvalue.toString());
        if (groupValue != 5 && groupValue != 6 && groupValue != 7 && groupValue != 12) {
            filterMetrics.unshift({ name: 'Add New Metric', value: 'New', metricGroupId: 0, envId: 0 });
        }       
        dispatch({
            activeMetrics: filterMetrics,
            selectedMetricgroupval,
            selectedDatasource: selectedDatasource,
            type: ActionType.METRICGROUP_CHANGE
        });
        dispatch({
            type: ActionType.SET_LOADING_FALSE
        });
    },
    handleMetricChange: (selectedMetric: WeeklyDropdownOption): IAppThunkAction<ReduxAction> => (dispatch, getState) => {        
        var metricsRaw = getState().serviceHealth.metricsRaw;
        var metricsRawService = getState().serviceHealth.metricsRawService;
        var selectedEnv = '';
        const onboardNewMetric = getState().serviceHealth.onboardNewMetric;
        const selectedDatasource = getState().serviceHealth.selectedDatasource === null ? '' : getState().serviceHealth.selectedDatasource;
        
        // Logic to set the Environment value based on selected Metric
        if (onboardNewMetric === true) {
            var selectedEnvElem = metricsRaw.filter(item => item.value === selectedMetric);
            if (selectedEnvElem.length !== 0) {
                selectedEnv = selectedEnvElem[0].envId;
            }            
        }
        else {
            var selectedEnvElem = metricsRawService.filter(item => item.value === selectedMetric);
            if (selectedEnvElem.length !== 0) {
                selectedEnv = selectedEnvElem[0].envId;
            }
        }

        dispatch({
            selectedMetric,
            selectedEnvironment: selectedEnv,
            selectedDatasource: selectedDatasource,
            type: ActionType.METRIC_CHANGE
        });
    },
    handleEnvironmentChange: (selectedEnvironment: WeeklyDropdownOption): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        var selectedMetricgroupval = getState().serviceHealth.selectedMetricgroupval === null ? '' : getState().serviceHealth.selectedMetricgroupval;
        var selectedEnv = selectedEnvironment === null ? '' : selectedEnvironment;
        var metricsRaw = getState().serviceHealth.metricsRaw;
        var metricsRawService = getState().serviceHealth.metricsRawService;
        const onboardNewMetric = getState().serviceHealth.onboardNewMetric;
        var metricDataSourceDetails = getState().serviceHealth.metricDataSourceDetails;
        const selectedDatasource = getState().serviceHealth.selectedDatasource === null ? '' : getState().serviceHealth.selectedDatasource;

        var filterMetrics = [];
        var selectedMetricVal;

        if (onboardNewMetric === true) {
            metricsRaw.map((row) => {
                if ((selectedMetricgroupval === '' || row.metricGroupId === parseInt(selectedMetricgroupval.toString()))
                    && (selectedEnv === '' || row.envId === parseInt(selectedEnv.toString()))) {
                    var datasourceelement = metricDataSourceDetails.filter(item => item.metricId === row.value);
                    if (datasourceelement.length === 0) {
                        filterMetrics.push(row);
                    }
                }
            })
        }
        else {
            metricsRawService.map((row: PropMetricType) => {
                if ((selectedMetricgroupval === '' || row.metricGroupId === parseInt(selectedMetricgroupval.toString()))
                    && (selectedEnv === '' || row.envId === parseInt(selectedEnv.toString()))) {
                    filterMetrics.push(row);
                }
            })
        }

        let groupValue = parseInt(selectedMetricgroupval.toString());
        if (groupValue != 5 && groupValue != 6 && groupValue != 7 && groupValue != 12) {
            filterMetrics.unshift({ name: 'Add New Metric', value: 'New', metricGroupId: 0, envId: 0 });
        }
        
        if (onboardNewMetric) {
            selectedMetricVal = getState().serviceHealth.selectedMetric
        }
        else {
            selectedMetricVal = null;
        }
        dispatch({
            activeMetrics: filterMetrics,
            selectedEnvironment: selectedEnv ,
            selectedMetric: selectedMetricVal,
            selectedDatasource: selectedDatasource,
            type: ActionType.ENVIRONMENT_CHANGE
        });
        dispatch({
            type: ActionType.SET_LOADING_FALSE
        });
    },
    handledatasourceChange: (selectedDatasource: WeeklyDropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            selectedDatasource,
            type: ActionType.DATASOURCE_CHANGE
        });
    },
    FilterMetricDataSources: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.FILTER_METRICDATASOURCE_REQUEST
        });

        var metricDataSourceDetailsRaw = getState().serviceHealth.metricDataSourceDetailsRaw;
        var selectedMetricgroupval = getState().serviceHealth.selectedMetricgroupval === null ? '' : getState().serviceHealth.selectedMetricgroupval;
        var selectedMetric = getState().serviceHealth.selectedMetric === null ? '' : getState().serviceHealth.selectedMetric;
        const selectedDatasource = getState().serviceHealth.selectedDatasource === null ? '' : getState().serviceHealth.selectedDatasource;
        var selectedEnvironment = getState().serviceHealth.selectedEnvironment === null ? '' : getState().serviceHealth.selectedEnvironment;

        var filtermetricDataSourceDetails = [];

        metricDataSourceDetailsRaw.map((row: PropMetricDataSource) => {
            if (!isNaN(parseInt(selectedMetricgroupval.toString()))) {
                if ((selectedMetricgroupval === '' || row.metricGroupId === parseInt(selectedMetricgroupval.toString()))
                    && (selectedMetric === '' || row.metricId === parseInt(selectedMetric.toString()))
                    && (selectedDatasource === '' || row.dataSourceId === parseInt(selectedDatasource.toString()))
                    && (selectedEnvironment === '' || row.environmentId === parseInt(selectedEnvironment.toString()))) {
                    filtermetricDataSourceDetails.push(row);
                }
            }
        })

        dispatch({
            metricDataSourceDetails: filtermetricDataSourceDetails,
            type: ActionType.FILTER_METRICDATASOURCE_SUCCESS
        });
        
    },
    EditMetricDataSourceRow: (row): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var targetTypes = getState().serviceHealth.targetTypes;
        var authenticationTypesList = getState().serviceHealth.authenticationTypes;        
        // Logic to set the Target
        var element = targetTypes.filter(item => item.value === row.targetId);
        var Target = element.length !== 0 ? element[0].name : "";

        dispatch({
            serverName: row.kustoClusterName,
            database: row.kustoDbName,
            mdmMetricName: row.mdmMetricName,
            selectedMetric: row.metricId,
            selectedMetricgroupval: row.metricGroupId,
            authenticationType: authenticationTypesList.find(x => x.name == row.authenicationType).value,
            queryOrUrl: row.queryOrUrl,
            target: { name: Target, value: row.targetId },
            selectedEnvironment: row.environmentId,
            notes: row.notes,
            selectedDatasource: row.dataSourceId,
            type: ActionType.ENABLE_EDITORADD_METRICDATASOURCE
        });
    },
    addNewMetric: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const newMetricName = event.target.value;
        dispatch({
            newMetricName,
            type: ActionType.METRICDATASOURCE_METRICNAME_ADD
        });
    },
    addNewMetricGroup: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const newMetricGroupName = event.target.value;
        dispatch({
            newMetricGroupName,
            type: ActionType.METRICDATASOURCE_METRICGROUP_ADD
        });
    },
    serverNameUpdate: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const serverName = event.target.value;
        dispatch({
            serverName,
            type: ActionType.METRICDATASOURCE_SERVER_CHANGE
        });
    },
    databaseUpdate: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const database = event.target.value;
        dispatch({
            database,
            type: ActionType.METRICDATASOURCE_DATABASE_CHANGE
        });
    },
    mdmMetricNameUpdate: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const mdmMetricName = event.target.value;
        dispatch({
            mdmMetricName,
            type: ActionType.MDM_METRICNAME_CHANGE
        });
    },
    setonboardNewMetric: (value): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const onboardNewMetric = value;
        const metricsRaw = getState().serviceHealth.metricsRaw;
        const metricsRawService = getState().serviceHealth.metricsRawService;
        var selectedMetricgroupval = getState().serviceHealth.selectedMetricgroupval === null ? '' : getState().serviceHealth.selectedMetricgroupval;
        var selectedEnvironment = getState().serviceHealth.selectedEnvironment === null ? '' : getState().serviceHealth.selectedEnvironment;
        var metricDataSourceDetails = getState().serviceHealth.metricDataSourceDetails;
        const selectedDatasource = getState().serviceHealth.selectedDatasource === null ? '' : getState().serviceHealth.selectedDatasource;

        let filterMetrics = [];
        if (onboardNewMetric === true) {
            /*selectedMetricgroupval = selectedMetricgroupval;  */       // When Checked Default Value is set to Availability (1)
            metricsRaw.map((row) => {
                if ((selectedMetricgroupval === '' || row.metricGroupId === parseInt(selectedMetricgroupval.toString()))
                    && (selectedEnvironment === '' || row.envId === parseInt(selectedEnvironment.toString()))) {
                    var datasourceelement = metricDataSourceDetails.filter(item => item.metricId === row.value);
                    if (datasourceelement.length === 0) {
                        filterMetrics.push(row);
                    }
                }
            });
        }
        else {
            metricsRawService.map((row: PropMetricType) => {
                if ((selectedMetricgroupval === '' || row.metricGroupId === parseInt(selectedMetricgroupval.toString()))
                    && (selectedEnvironment === '' || row.envId === parseInt(selectedEnvironment.toString()))) {
                    filterMetrics.push(row);
                }
            })
            selectedMetricgroupval = 1;
        }
        filterMetrics = [...new Set(filterMetrics.map(obj => obj.name))].map(name => { return filterMetrics.find(obj => obj.name === name) });
        filterMetrics.push({ name: 'Add New Metric', value: 'New', metricGroupId: 0, envId: 0 });
        dispatch({
            onboardNewMetric,
            selectedMetricgroupval: selectedMetricgroupval,
            activeMetrics: filterMetrics,
            selectedMetric: null,
            selectedEnvironment: null,
            selectedDatasource: selectedDatasource,
            type: ActionType.METRICDATASOURCE_ONBOARD_CHANGE
        });
    },
    authenticationTypeUpdate: (authenticationType: WeeklyDropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            authenticationType,
            type: ActionType.METRICDATASOURCE_AUTHENTICATIONTYPE_CHANGE
        });
    },
    UpdatequeryOrUrl: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const queryOrUrl = event.target.value;
        dispatch({
            queryOrUrl,
            type: ActionType.METRICDATASOURCE_QUERYORURL_CHANGE
        });
    },
    Updatetarget: (target: WeeklyDropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            target,
            type: ActionType.METRICDATASOURCE_TARGET_CHANGE
        });
    },
    Updatenotes: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const notes = event.target.value;
        dispatch({
            notes,
            type: ActionType.METRICDATASOURCE_NOTES_CHANGE
        });
    },
    clearMetricDataSourceData: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.CLEAR_METRICDATASOURCE_SECTION
        });
    },
    resetFilters: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.RESET_FILTER
        });
    },
};

const Calc2Decimal = (value: any) => {
    if (value) {
        const num = value;
        const with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
        return with2Decimals;
    }
};
const displayDecimals = (value: any, vals: any) => {
    return parseFloat(value).toFixed(parseInt(vals));
};
const converttoKMB = (val: any) => {
    if (val === 0) {
        val = 0;
    }
    else {
        if (val < 0) {
            val = (val).toFixed(2);
        }
        else if (val < 10 && val >= 1) {
            val = parseInt(val).toFixed(2);
        }
        else if (val >= 10 && val < 100) {
            val = parseInt(val).toFixed(1);
        }
        else if (val <= 999 && val >= 100) {// hundreds
            val = parseInt(val);
        }
        else if (val >= 1000 && val <= 999999) {// thousands
            val = (val / 1000);
            const length = parseInt(val).toString().length;
            if (length > 2) {
                val = parseInt(val) + 'K';
            }
            else if (length === 2) {
                val = parseInt(val).toFixed(1) + 'K';
            }
            else {
                val = parseInt(val).toFixed(2) + 'K';
            }

        }
        else if (val >= 1000000 && val <= 999999999) { // millions
            val = (val / 1000000);
            const length = parseInt(val).toString().length;
            if (length > 2) {
                val = parseInt(val) + 'M';
            }
            else if (length === 2) {
                val = val.toFixed(1) + 'M';
            }
            else {
                val = val.toFixed(2) + 'M';
            }

        }
        else if (val >= 1000000000 && val <= 999999999999) {// billions
            val = (val / 1000000000);
            const length = parseInt(val).toString().length;
            if (length > 2) {
                val = parseInt(val) + 'B';
            }
            else if (length === 2) {
                val = val.toFixed(1) + 'B';
            }
            else {
                val = val.toFixed(2) + 'B';
            }
        }
        else if (val >= 1000000000000) {// trillions
            val = (val / 1000000000000);
            const length = parseInt(val).toString().length;
            if (length > 2) {
                val = parseInt(val) + 'T';
            }
            else if (length === 2) {
                val = val.toFixed(1) + 'T';
            }
            else {
                val = val.toFixed(2) + 'T';
            }
        }
        else {
            val = val;
        }
    }
    return val;
}

const getWeeklyStartDate = () => {
    const today = new Date(); // "07/23/2016"   
    if (!today.getDay()) {
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 15);
    }

    const pMonday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 6);
    const date = new Date(pMonday.getFullYear(), pMonday.getMonth(), pMonday.getDate() - 2);
    return date;
}
const buildDescription = (item)=> {
    return `${item.colorText} - Greater than ${item.value1Min} and less than ${item.value1Max}`;
}
const helper_Get_Week_Values_Grid = (calendarlist, selectedDate)=> {
    var weekArray = [];   
    var selectedWeekId = 0;
    calendarlist.map((calendar, index) => {
        calendar.month.map((month, index) => {
            month.week.map((week, index) => {
                weekArray.push(week);
            });
        });
    });    
    var index;
    for (var count = 0; count < weekArray.length; count++) {
        var oldDate = moment(weekArray[count].weekStartDate).format('MM-DD-YYYY');
        var newDate = moment(selectedDate, 'MM-DD-YYYY');// new Date(String($scope.selectedDate));


        // newDate = newDate.toDateString();
        if (newDate.isSame(oldDate)) {
            index = count;
        }
    }

    if (index != undefined) {
        selectedWeekId = weekArray[index].weekId;
    }

    const weeks = {
        week5: weekArray[index].weekDisplayName,
        week5ID: weekArray[index].weekId,
        week4: weekArray[index - 1].weekDisplayName,
        week4ID: weekArray[index - 1].weekId,
        week3: weekArray[index - 2].weekDisplayName,
        week3ID: weekArray[index - 2].weekId,
        week2: weekArray[index - 3].weekDisplayName,
        week2ID: weekArray[index - 3].weekId,
        week1: weekArray[index - 4].weekDisplayName,
        week1ID: weekArray[index - 4].weekId
    };

    const weeksDetails = {
        weekDet5: weekArray[index].weekDetail,
        weekDet4: weekArray[index - 1].weekDetail,
        weekDet3: weekArray[index - 2].weekDetail,
        weekDet2: weekArray[index - 3].weekDetail,
        weekDet1: weekArray[index - 4].weekDetail
    };
    return {'selectedWeekId': selectedWeekId, 'weeks': weeks, 'weeksDetails': weeksDetails}
}
const getMonthlyStartDate = () => {
    const today = new Date(); // "07/23/2016"   
    return today;
}
const DisableViewconfigUpdatebutton = (view) => {
    var val = false;
    if (isNullOrUndefined(view.authenticationType) || view.authenticationType === '') {
        val = true;
    }
    else if (isNullOrUndefined(view.volumeType) || view.volumeType === '') {
        val = true;
    }
    else if (isNullOrUndefined(view.coloringLogicId) || view.coloringLogicId === '') {
        val = true;
    }
    else if (isNullOrUndefined(view.colorSchemeId) || view.colorSchemeId === '') {
        val = true;
    }
    else if (isNullOrUndefined(view.noOfDecimalId) || view.noOfDecimalId === '') {
        val = true;
    }
    else if (isNullOrUndefined(view.fixedColQuery) || view.fixedColQuery === '') {
        val = true;
    }
    else if (isNullOrUndefined(view.valQuery) || view.valQuery === '') {
        val = true;
    }
    else if (isNullOrUndefined(view.kustoDbName) || view.kustoDbName === '') {
        val = true;
    }   
    else if (isNullOrUndefined(view.kustoServer) || view.kustoServer === '') {
        val = true;
    }
    else if (isNullOrUndefined(view.val1Cap) || view.val1Cap === '') {
        val = true;
    }
    else if (isNullOrUndefined(view.val2Cap) || view.val2Cap === '') {
        val = true;
    }
    else if (isNullOrUndefined(view.val2Cap) || view.val2Cap === '') {
        val = true;
    }
    else if (view.viewId === 0 && view.viewName === '') {
        val = true;
    }
    else if (!isNullOrUndefined(view.tsg)) {
        if (!ValidateURL(view.tsg)) {
            val = true;
        }
    }
    else {
        val = false;
    }
    return val;
}

const ValidateURL = (url) => {
    var valid = false;
    var regex = /^(?:(?:https):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    var m;
    m = regex.exec(url);
    if (m !== null) {
        valid = true;
    }
    return valid;
}

const setview = (ConfigurationView) => {
    let view = {
        'authenticationType': ConfigurationView.authenticationType,
        'ccEmailList': ConfigurationView.ccEmailList,
        'colorLogicText': ConfigurationView.colorLogicText,
        'colorSchemeId': ConfigurationView.colorSchemeId,
        'coloringLogicId': ConfigurationView.coloringLogicId,
        'drillDownQuery': ConfigurationView.drillDownQuery,
        'fixedColQuery': ConfigurationView.fixedColQuery,
        'hasVal2': ConfigurationView.hasVal2,
        'isMonthlyAvailability': ConfigurationView.isMonthlyAvailability,
        'isMserPresentationView': ConfigurationView.isMserPresentationView,
        'isMserView': ConfigurationView.isMserView,
        'isReachability': ConfigurationView.isReachability,
        'kustoDbName': ConfigurationView.kustoDbName,
        'kustoServer': ConfigurationView.kustoServer,
        'noOfDecimalId': ConfigurationView.noOfDecimalId,
        'noOfDecimalText': ConfigurationView.noOfDecimalText,
        'serviceId': ConfigurationView.serviceId,
        'serviceName': ConfigurationView.serviceName,
        'source': ConfigurationView.source,
        'toEmailList': ConfigurationView.toEmailList,
        'totalsLabel': ConfigurationView.totalsLabel,
        'updatedBy': ConfigurationView.updatedBy,
        'updatedOn': ConfigurationView.updatedOn,
        'val1Cap': ConfigurationView.val1Cap,
        'val1Label': ConfigurationView.val1Label,
        'val2Cap': ConfigurationView.val2Cap,
        'val2Label': ConfigurationView.val2Label,
        'valQuery': ConfigurationView.valQuery,
        'viewId': ConfigurationView.viewId,
        'viewName': ConfigurationView.viewName,
        'volumeType': ConfigurationView.volumeType,
        'RenameViewLabel': ConfigurationView.RenameViewLabel,
        'tsg': ConfigurationView.tsg
    }
    return view;
}
