import { IAppThunkAction, ReduxAction } from '../';
import { ActionType, IDataCenter } from './types';
import { CommonAPI, DCMappingAPI } from '../../api';
import { isNullOrUndefined, renderToastifyMsg } from '../../utils';
import { toast } from 'react-toastify';

export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId).
                then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }
    },
    GetDataCenters: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        DCMappingAPI.GetDataCenters()
            .then((datacenters: IDataCenter[]) => {
                let dataCenterNameOptions = [];
                let azureRegionNameOptions = [];
                let locationOptions = [];
                let countryOptions = [];
                datacenters.forEach((row) => {
                    if (!dataCenterNameOptions.some(e => e.name === row.dataCenterName)) {
                        dataCenterNameOptions.push({ name: row.dataCenterName})
                    }
                    if (!azureRegionNameOptions.some(e => e.name === row.azureRegionName)) {
                        azureRegionNameOptions.push({ name: row.azureRegionName })
                    }
                    if (!locationOptions.some(e => e.name === row.location)) {
                        locationOptions.push({ name: row.location })
                    }
                    if (!countryOptions.some(e => e.name === row.country)) {
                        countryOptions.push({ name: row.country })
                    }
                });
                dispatch({
                    datacenters: datacenters,
                    dataCenterNameOptions: dataCenterNameOptions,
                    azureRegionNameOptions: azureRegionNameOptions,
                    locationOptions: locationOptions,
                    countryOptions: countryOptions,
                    type: ActionType.GET_DATACENTERS
                });
            }).catch(error => {
                toast.error(renderToastifyMsg('Unable to fetch data. Please reload the page. ' + error,
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 7000 });
                dispatch({
                    approvalTasksDetails: [],
                    dataCenterNameOptions: [],
                    azureRegionNameOptions: [],
                    locationOptions: [],
                    countryOptions: [],
                    type: ActionType.GET_DATACENTERS
                });
            });
    },
    updateRecord: (rowData: IDataCenter, insertBool: boolean): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        rowData.updatedby = getState().authUser.login;
        DCMappingAPI.UpdateRow(rowData, insertBool)
            .then((datacenters: IDataCenter[]) => {
                let dataCenterNameOptions = [];
                let azureRegionNameOptions = [];
                let locationOptions = [];
                let countryOptions = [];
                datacenters.forEach((row) => {
                    if (!dataCenterNameOptions.some(e => e.name === row.dataCenterName)) {
                        dataCenterNameOptions.push({ name: row.dataCenterName })
                    }
                    if (!azureRegionNameOptions.some(e => e.name === row.azureRegionName)) {
                        azureRegionNameOptions.push({ name: row.azureRegionName })
                    }
                    if (!locationOptions.some(e => e.name === row.location)) {
                        locationOptions.push({ name: row.location })
                    }
                    if (!countryOptions.some(e => e.name === row.country)) {
                        countryOptions.push({ name: row.country })
                    }
                });
                if (insertBool) {
                    toast.success(renderToastifyMsg('Record successfully Inserted',
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4000 });
                }
                else {
                    toast.success(renderToastifyMsg('Record successfully Updated',
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4000 });
                }
                dispatch({
                    datacenters: datacenters,
                    dataCenterNameOptions: dataCenterNameOptions,
                    azureRegionNameOptions: azureRegionNameOptions,
                    locationOptions: locationOptions,
                    countryOptions: countryOptions,
                    type: ActionType.GET_DATACENTERS
                });
            }).catch(error => {
                if (insertBool) {
                    toast.error(renderToastifyMsg('Unable to insert record. Please try again. ' + error,
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 7000 });
                }
                else {
                    toast.error(renderToastifyMsg('Unable to update record. Please try again. ' + error,
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 7000 });
                }
                dispatch({
                    datacenters: getState().DCMapping.datacenters,
                    dataCenterNameOptions: getState().DCMapping.dataCenterNameOptions,
                    azureRegionNameOptions: getState().DCMapping.azureRegionNameOptions,
                    locationOptions: getState().DCMapping.locationOptions,
                    countryOptions: getState().DCMapping.countryOptions,
                    type: ActionType.GET_DATACENTERS
                });
            });
    },
    deleteRecord: (rowData: IDataCenter): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        rowData.updatedby = getState().authUser.login;
        DCMappingAPI.DeleteRecord(rowData)
            .then((datacenters: IDataCenter[]) => {
                let dataCenterNameOptions = [];
                let azureRegionNameOptions = [];
                let locationOptions = [];
                let countryOptions = [];
                datacenters.forEach((row) => {
                    if (!dataCenterNameOptions.some(e => e.name === row.dataCenterName)) {
                        dataCenterNameOptions.push({ name: row.dataCenterName })
                    }
                    if (!azureRegionNameOptions.some(e => e.name === row.azureRegionName)) {
                        azureRegionNameOptions.push({ name: row.azureRegionName })
                    }
                    if (!locationOptions.some(e => e.name === row.location)) {
                        locationOptions.push({ name: row.location })
                    }
                    if (!countryOptions.some(e => e.name === row.country)) {
                        countryOptions.push({ name: row.country })
                    }
                });
                toast.success(renderToastifyMsg('Record successfully Deleted',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4000 });
                dispatch({
                    datacenters: datacenters,
                    dataCenterNameOptions: dataCenterNameOptions,
                    azureRegionNameOptions: azureRegionNameOptions,
                    locationOptions: locationOptions,
                    countryOptions: countryOptions,
                    type: ActionType.GET_DATACENTERS
                });
            }).catch(error => {
                toast.error(renderToastifyMsg('Unable to delete the record. Please try again. ' + error,
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 7000 });
                dispatch({
                    datacenters: getState().DCMapping.datacenters,
                    dataCenterNameOptions: getState().DCMapping.dataCenterNameOptions,
                    azureRegionNameOptions: getState().DCMapping.azureRegionNameOptions,
                    locationOptions: getState().DCMapping.locationOptions,
                    countryOptions: getState().DCMapping.countryOptions,
                    type: ActionType.GET_DATACENTERS
                });
            });
    },
    updateCurrentRecord: (rowData: IDataCenter): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        dispatch({
            currentRow: rowData,
            type: ActionType.UPDATE_CURRENTRECORD
        });
    }
}