import { AvailabilityAPI, CommonAPI } from '../../api';
import { IAppThunkAction, ReduxAction } from '../';
import { ActionType,IAvailabilitySummary } from './types';
import {  isNullOrUndefined } from '../../utils';
export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }

    },
    requestAvailabilitySummary: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        // Dispatch request
        dispatch({
            type: ActionType.AVAILABILITY_SUMMARY_REQUEST
        });     
        const pMonday = getPreviousMonday();
        const date = new Date(pMonday.getFullYear(), pMonday.getMonth(), pMonday.getDate() - 2);
        const selectdate = String((date.getMonth() + 1)) + '/' + String(date.getDate()) + '/' + String(date.getFullYear());
        AvailabilityAPI.GetAvailabilitySummaryData(selectdate)
            .then((availabilitySummary: IAvailabilitySummary[]) => {
                dispatch({
                    availabilitySummary,
                    type: ActionType.AVAILABILITY_SUMMARY_RECEIVE
                });
            });
    },

};
function getPreviousMonday() {
   
    const today = new Date(); // '07/23/2016'   
    if (!today.getDay()) {
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 13);
    }

    return new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 6);
}
