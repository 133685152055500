export type IWIPSHRState = {
    readonly isLoading: boolean;
    readonly userId: string;
    readonly MeetingDate: Date;
    readonly MaxDate: Date;
    readonly DefaultDate: Date;
    readonly weeklyIDSHRData: IPropAllPirData[];
    readonly CommosonlyPIRData: IPropCommsOnlyPir[];
    readonly SpecialTopicsData: IPropSpecialTopicsWishr[];
    readonly SupportabilityData: IPropSupportabilityWishr[];
    readonly PIRRereviewData: IPropAllPirDataReReview[];
    readonly FundamentalsSpotlightData: IPropFundamentalsSpotlightWishr[];
    readonly ActionItemsReviewData: IPropActionItemsReviewWishr[];
    readonly PIRAdminLock: IPIRAdminLock[];
    readonly isPIREditenabled: boolean;
    readonly isComosEditenabled: boolean;
    readonly isPIRReviewEditenabled: boolean;
    readonly isSpecialTopicEditorAddEnabled: boolean;
    readonly isSupportabilityEditorAddEnabled: boolean;
    readonly isFundamentalsSpotlightEditorAddEnabled: boolean;
    readonly isFunLockDisabledOnEdit: boolean;
    readonly isSpecialTopicLockDisabledOnEdit: boolean;
    readonly isPIRLockDisabledOnEdit: boolean;
    readonly IsPirRereviewUser: boolean;
    readonly isLockedPIR: boolean;
    readonly isLockedCommsPIR: boolean;
    readonly isLockedTopic: boolean;
    readonly isLockedFundamentalsSpotlight: boolean;    
    readonly isLockedPIRArray: Date[];
    readonly isLockedCommsPIRArray: Date[];
    readonly isLockedTopicArray: Date[];
    readonly isLockedFundamentalsSpotlightArray: Date[];
    readonly accordiontoggle: boolean;
    readonly funaccordiontoggle: boolean;
    readonly specialaccordiontoggle: boolean;
    readonly piraccordiontoggle: boolean;
};
export type IResourceDetail = {
    readonly link: string;
    readonly description: string;
}
export type IPropAllPirData = {
    readonly id: number;
    readonly serviceName: string;
    readonly servicesImpacted: string;
    readonly summary: string;
    readonly ttd: string;
    readonly ttm: string;
    readonly ttn: string;
    readonly pir: string;
    readonly pirEdit: string;
    readonly isAzure: boolean;
    kpiDate: string;
    userName: string;
    readonly tte: string;
    readonly occuredRing: string;
    readonly occuredRingEdit: string;
    readonly pirRereview: boolean;
    readonly isAddedToAgenda: boolean;
    readonly isValidPir: boolean;
    readonly resources: IResourceDetail[];
    readonly rootCauseCategory: string;
    readonly sharedLearning: string;
    readonly incidentId: string;
    isEdit: boolean;
    readonly displayOrder: string;
    displayOrderEdit: string;
    readonly presenter: string;
    presenterEdit: string;
};
export type IPropCommsOnlyPir = {
    readonly id: number;
    readonly serviceName: string;
     serviceNameEdit: string;
    readonly title: string;
     titleEdit: string;
    readonly ttn: string;  
     ttnEdit: string;   
    readonly commsPirLink: string;
     commsPirLinkEdit: string;
    readonly pirNumber?: number;
    pirNumberEdit?: number;
    readonly displayOrder: string;
     displayOrderEdit: string;
    readonly kpiDate: string;
    isEdit: boolean;
    readonly presenter: string;
    presenterEdit: string;
};
export type IPropSpecialTopicsWishr = {
    readonly id: number;
    readonly presenter: string;
     presenterEdit: string;
    readonly topicName: string;
     topicNameEdit: string;
    readonly timeNeeded: number;
     timeNeededEdit: number;
    readonly presentationLink: string;
     presentationLinkEdit: string;
    readonly isTopicApproved?: boolean;
     isTopicApprovedEdit?: boolean;
    kpiDate: string;
    readonly displayOrder: string;
     displayOrderEdit: string;
    isEdit: boolean;
    readonly occuredRingEdit: string;
    userName: string;

};

export type IPropFundamentalsSpotlightWishr = {
    readonly id: number;
    readonly presenter: string;
    presenterEdit: string;
    readonly topicName: string;
    topicNameEdit: string;
    readonly timeNeeded: number;
    timeNeededEdit: number;
    readonly presentationLink: string;
    presentationLinkEdit: string;
    readonly isTopicApproved?: boolean;
    isTopicApprovedEdit?: boolean;
    kpiDate: string;
    readonly displayOrder: string;
    displayOrderEdit: string;
    isEdit: boolean;
    readonly occuredRingEdit: string;
    userName: string;
};

export type IPropActionItemsReviewWishr = {
    readonly id: number;
    readonly presenter: string;
    presenterEdit: string;
    readonly topicName: string;
    topicNameEdit: string;
    readonly timeNeeded: number;
    timeNeededEdit: number;
    readonly presentationLink: string;
    presentationLinkEdit: string;
    readonly isTopicApproved?: boolean;
    isTopicApprovedEdit?: boolean;
    readonly kpiDate: string;
    readonly displayOrder: string;
    displayOrderEdit: string;
    isEdit: boolean;
    readonly occuredRingEdit: string;
    userName: string;
};

export type IPropSupportabilityWishr = {
    readonly id: number;
    readonly presenter: string;
    presenterEdit: string;
    readonly topicName: string;
    topicNameEdit: string;
    readonly presentationLink: string;
    presentationLinkEdit: string;
    readonly displayOrder: string;
    displayOrderEdit: string;
    readonly kpiDate: string;
    isEdit: boolean;
};

export type IPropAllPirDataReReview = {
    readonly id: number;
    readonly pirDataID: number;
    readonly serviceName: string;
    readonly servicesImpacted: string;
    readonly summary: string;
    readonly ttd: string;
    readonly ttm: string;
    readonly ttn: string;
    readonly pir: string;   
    readonly isAzure?: boolean;
    readonly kpiDate: string;
    readonly userName: string;
    readonly tte: string;
    readonly occuredRing: string;
    readonly dueForReReviewOn: string;
     dueForReReviewOnEdit: string;
    readonly addToAgenda: boolean;
    readonly addToAgendaEdit: boolean;
    readonly isPastDue: boolean;
    readonly resourceLink: string;
    readonly resourceDescription: string;   
    readonly incidentId: string; 
    isEdit: boolean;
    readonly pirEdit: string;

};

export type IPIRAdminLock = {
    readonly id: number;
    readonly lock_Type: number;
    readonly locked_Week: string;  
}

const _namespace = 'WIPSHR';
export interface IActionType {
    readonly POST_INCIDENTS_REQUEST: string;
    readonly POST_INCIDENTS_RECEIVE: string;
    readonly RESET_STATE: string;
    readonly SET_MEETINGDATE_DATE: string;
    readonly MEETINGDATE_DATE_CHANGE: string;
    readonly GET_WEEKLYIDSHR_REQUEST: string;
    readonly GET_WEEKLYIDSHR_SUCCESS: string;
    readonly GET_WEEKLYIDSHR_FAILURE: string;
    readonly GET_COOMOSONLYPIR_REQUEST: string;
    readonly GET_COOMOSONLYPIR_SUCCESS: string;
    readonly GET_COOMOSONLYPIR_FAILURE: string;
    readonly GET_SPECIALTOPIC_REQUEST: string;
    readonly GET_SPECIALTOPIC_SUCCESS: string;
    readonly GET_SPECIALTOPIC_FAILURE: string;
    readonly GET_PIRREREVIEW_REQUEST: string;
    readonly GET_PIRREREVIEW_SUCCESS: string;
    readonly GET_PIRREREVIEW_FAILURE: string;
    readonly PIRREREVIEW_CHANGE: string;
    readonly SET_LOADING_TRUE: string;
    readonly SET_LOADING_FALSE: string;
    readonly ENABLE_EDITORADD_PIR: string;
    readonly ENABLE_EDITORADD_COMMOSONLYPIR: string;
    readonly COMMOSONLY_PIRREREVIEW_CHANGE: string;
    readonly ENABLE_EDITORADD_PIRREVIEW: string;
    readonly PIR_REREVIEW_CHANGE: string;
    readonly ENABLE_EDITORADD_SPECIALTOPIC: string;
    readonly SPECIALTOPIC_CHANGE: string;
    readonly REREVIEWER_CHECK: string;
    readonly ENABLE_EDITORADD_SUPPORTABILITY: string;
    readonly GET_SUPPORTABILITY_SUCCESS: string;
    readonly GET_SUPPORTABILITY_FAILURE: string;
    readonly SUPPORTABILITY_CHANGE: string;
    readonly ADDED_VISITED_USER: string;
    readonly SET_PIRLOCK: string;
    readonly SET_PIRCOMMSLOCK: string;
    readonly SET_SPECIALTOPICLOCK: string;
    readonly GET_PIRADMINLOCK: string;
    readonly FUNDAMENTALS_SPOTLIGHT_CHANGE: string;    
    readonly ENABLE_EDITORADD_FUNDAMENTALS_SPOTLIGHT: string;    
    readonly GET_FUNDAMENTALS_SPOTLIGHT_SUCCESS: string;
    readonly GET_ACTION_ITEMS_REVIEW_SUCCESS: string;
    readonly GET_FUNDAMENTALS_SPOTLIGHT_FAILURE: string;
    readonly GET_ACTION_ITEMS_REVIEW_FAILURE: string;
    readonly ACTIONREVIEWACCORDION_STATUS: string;
    readonly FUNDAMENTALACCORDION_STATUS: string;
    readonly SPECIALTOPICACCORDION_STATUS: string;
    readonly PIRACCORDION_STATUS: string;
}

export const ActionType = Object.freeze<IActionType>({
    POST_INCIDENTS_REQUEST: `${_namespace}/postincidentsrequest`,
    POST_INCIDENTS_RECEIVE: `${_namespace}/postincidentsreceive`,
    RESET_STATE: `${_namespace}/reset`,
    SET_MEETINGDATE_DATE: `${_namespace}/setmeetingdate`,
    MEETINGDATE_DATE_CHANGE: `${_namespace}/meetingdatechange`,
    GET_WEEKLYIDSHR_REQUEST: `${_namespace}/getweelyidshrrequest`,
    GET_WEEKLYIDSHR_SUCCESS: `${_namespace}/getweelyidshrsuccess`,
    GET_WEEKLYIDSHR_FAILURE: `${_namespace}/getweelyidshrfailure`,
    GET_COOMOSONLYPIR_REQUEST: `${_namespace}/getweelycomsonlyrequest`,
    GET_COOMOSONLYPIR_SUCCESS: `${_namespace}/getcomsonlysuccess`,
    GET_COOMOSONLYPIR_FAILURE: `${_namespace}/getcomsonlyfailure`,
    GET_SPECIALTOPIC_REQUEST: `${_namespace}/getspecialtopicrequest`,
    GET_SPECIALTOPIC_SUCCESS: `${_namespace}/getspecialtopicsuccess`,
    GET_SPECIALTOPIC_FAILURE: `${_namespace}/getspecialtopicfailure`,
    GET_PIRREREVIEW_REQUEST: `${_namespace}/getpirrereviewrequest`,
    GET_PIRREREVIEW_SUCCESS: `${_namespace}/getgetpirrereviewsuccess`,
    GET_PIRREREVIEW_FAILURE: `${_namespace}/getpirrereviewfailure`,
    PIRREREVIEW_CHANGE: `${_namespace}/pirreviewchange`,
    SET_LOADING_TRUE: `${_namespace}/loadingtrue`,
    SET_LOADING_FALSE: `${_namespace}/loadingfalse`,
    ENABLE_EDITORADD_PIR: `${_namespace}/enableeditoradd`,
    ENABLE_EDITORADD_COMMOSONLYPIR: `${_namespace}/enablecommosonlypir`,
    COMMOSONLY_PIRREREVIEW_CHANGE: `${_namespace}/commosonlypirchange`,
    ENABLE_EDITORADD_PIRREVIEW: `${_namespace}/enableeditoraddpirrereview`,
    PIR_REREVIEW_CHANGE: `${_namespace}/pirrereviewchange`,
    ENABLE_EDITORADD_SPECIALTOPIC: `${_namespace}/enableeditoraddspecialtopic`,
    SPECIALTOPIC_CHANGE: `${_namespace}/specialtopicchange`,
    REREVIEWER_CHECK: `${_namespace}/REREVIEWERCHECK`,
    ENABLE_EDITORADD_SUPPORTABILITY: `${_namespace}/enableeditoraddsupportability`,
    GET_SUPPORTABILITY_SUCCESS: `${_namespace}/getsupportabilitysuccess`,
    GET_SUPPORTABILITY_FAILURE: `${_namespace}/getsupportabilityfailure`,
    SUPPORTABILITY_CHANGE: `${_namespace}/supportabilitychange`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    SET_PIRLOCK: `${_namespace}/setpirlock`,
    SET_PIRCOMMSLOCK: `${_namespace}/setpircommslock`,
    SET_SPECIALTOPICLOCK: `${_namespace}/setspecialtopic`,
    GET_PIRADMINLOCK: `${_namespace}/getpiradminlock`,
    FUNDAMENTALS_SPOTLIGHT_CHANGE: `${_namespace}/fundamentalsspotlightchange`,    
    ENABLE_EDITORADD_FUNDAMENTALS_SPOTLIGHT: `${_namespace}/enableeditoraddfundamentalsspotlight`,    
    GET_FUNDAMENTALS_SPOTLIGHT_SUCCESS: `${_namespace}/getfundamentalsspotlightsuccess`,
    GET_ACTION_ITEMS_REVIEW_SUCCESS: `${_namespace}/getactionitemsreviewsuccess`,
    GET_FUNDAMENTALS_SPOTLIGHT_FAILURE: `${_namespace}/getfundamentalsspotlightfailure`,
    GET_ACTION_ITEMS_REVIEW_FAILURE: `${_namespace}/getactionitemsreviewfailure`,
    ACTIONREVIEWACCORDION_STATUS: `${_namespace}/actionreviewaccordionstatus`,
    FUNDAMENTALACCORDION_STATUS: `${_namespace}/fundamentalaccordionstatus`,
    SPECIALTOPICACCORDION_STATUS: `${_namespace}/specialaccordionstatus`,
    PIRACCORDION_STATUS: `${_namespace}/piraccordionstatus`
});

