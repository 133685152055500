
export interface IActionType {
    readonly RESET_STATE: string;
    readonly ADDED_VISITED_USER: string;
    readonly SET_LOADING_TRUE: string;
    readonly SET_LOADING_FALSE: string;
    readonly ACKTRACKING_DATA_FETCH: string;
    readonly ACKTRACKING_DATA_SUCCESS: string;
    readonly ACKTRACKING_DATA_FAIL: string;
    readonly FETCH_SERVICEMANGERLIST_SUCCESS: string;
    readonly FETCHSERVICEMANGERLIST_FAIL: string;
    readonly FETCH_SERVICELIST_SUCCESS: string;
    readonly FETCHSERVICELIST_FAIL: string;
    readonly FETCH_COMPONENTLIST_SUCCESS: string;
    readonly FETCHCOMPONENTLIST_FAIL: string;
    readonly FETCH_SERVICETYPELIST_SUCCESS: string;
    readonly FETCHSERVICETYPELIST_FAIL: string;
    readonly FETCH_SERVICERINGLIST_SUCCESS: string;
    readonly FETCHSERVICERINGLIST_FAIL: string;
    readonly FETCH_SERVICELIFECYCLELIST_SUCCESS: string;
    readonly FETCHSERVICELIFECYCLELIST_FAIL: string;
    readonly FETCH_IDCLASSIFICATION_SUCCESS: string;
    readonly FETCH_IDCLASSIFICATION_FAIL: string;
    readonly FETCH_IDACKTYPE_SUCCESS: string;
    readonly FETCH_IDACKTYPE_FAIL: string;
    readonly FETCH_IDACKFILTERLIST_SUCCESS: string;
    readonly FETCH_IDACKFILTERLIST_FAIL: string;
    readonly ACKTYPE_VALUE_CHANGE: string;
    readonly FILTER_TEXT_VALUE_CHANGE: string;
    readonly GLOBAL_SEARCH_VALUE_CHANGE: string;
    readonly FILTER_DROPDOWN_CHANGE: string;
    readonly FILTER_DATA_BASEDON_FILTERID: string;
    readonly FILTER_DATA_BASEDON_FILTERID_FAIL: string;
    readonly CLEAR_FILTERS: string;
    readonly SERVICEMANAGER_CHANGE: string;
    readonly FILTER_DATA: string;
    readonly SERVICES_CHANGE: string;
    readonly COMPONENT_CHANGE: string;
    readonly SERVICE_LIFECYCLE_CHANGE: string;
    readonly SERVICE_TYPE_CHANGE: string;
    readonly SERVICE_IDCLASSIFICATION_CHANGE: string;
    readonly SERVICE_RING_CHANGE: string;
    readonly GET_FILTER_REFRESH: string;
    readonly OPEN_EMAIL_POPUP: string;
    readonly EMAIL_POPUP_CLOSE: string;
    readonly SET_URL_PARAM_EMPTY: string;
    readonly ACKEDIT_POPUP_CLOSE: string;
    readonly OPEN_ACKTRACKING_POPUP: string;
    readonly ACKTRACKING_CONTROLS_CHANGE: string;
    readonly ACKTRACKING_DATE_CHANGE: string;
    
}
export type IAckTrackingState = {
    readonly isLoading: boolean;
    readonly mainAcktrackingData: [];
    readonly notificationACKId: string;
    readonly title: string;
    readonly headerdata: [];
    readonly IdentityAckData: [];
    readonly ServiceManagerList: [];
    readonly ServiceList: [];
    readonly ComponentList: [];
    readonly ServiceTypeList: [];
    readonly ServiceManagervalue: '';
    readonly Servicevalue: '';
    readonly Componentvalue: '';
    readonly ServiceTypevalue: '';
    readonly ServiceRinglist: [];
    readonly ServiceRingvalue: '';
    readonly ServiceLifeCyclelist: [];
    readonly ServiceLifeCyclevalue: '';
    readonly IDClassificationList: [];
    readonly IDClassificationvalue: '';
    readonly globalsearch: '';
    readonly IDAckTypeList: [];
    readonly AcktrackingFiltervalue: string;
    readonly AcktrackingFilterList: [];
    readonly NewFiltervalue: '';
    readonly Iscontact_DevOwner: boolean;
    readonly Iscontact_PMOwner: boolean;
    readonly IslastUpdatedBy: boolean;
    readonly DevOwnerEmailIDs: string;
    readonly PMOwnerEmailIDs: string;
    readonly UpdatedUserIDEmailIDs: string;
    readonly Isopenemailpopup: boolean;
    readonly urlparam: string;
    readonly EditAckPopup: boolean;
    readonly EditObject: any;
    readonly editdropdownlist: [];
    readonly EditObjectDate: Date;
    readonly checkboxlist: any;
    readonly savebuttondisabled: boolean;
    readonly counttofilterdatawhilepageload: number;
    readonly IsServiceSpecificAck: boolean;
    readonly IsFilterDisabled: boolean;
      
};

const _namespace = 'IdAcktracking ';

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/reset`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    SET_LOADING_TRUE: `${_namespace}/loadingtrue`,
    SET_LOADING_FALSE: `${_namespace}/loadingfalse`,
    ACKTRACKING_DATA_FETCH: `${_namespace}/acktrackingdatafetch`,
    ACKTRACKING_DATA_SUCCESS: `${_namespace}/acktrackingdatasuccess`,
    ACKTRACKING_DATA_FAIL: `${_namespace}/acktrackingdatafail`,
    FETCH_SERVICEMANGERLIST_SUCCESS: `${_namespace}/fetchservicemanagerlistsuccess`,
    FETCHSERVICEMANGERLIST_FAIL: `${_namespace}/fetchservicemanagerlistfail`,
    FETCH_SERVICELIST_SUCCESS: `${_namespace}/fetchservicelistsuccess`,
    FETCHSERVICELIST_FAIL: `${_namespace}/fetchservicelistfail`,
    FETCH_COMPONENTLIST_SUCCESS: `${_namespace}/fetchcomponentlistsuccess`,
    FETCHCOMPONENTLIST_FAIL: `${_namespace}/fetchcomponentlistfail`,
    FETCH_SERVICETYPELIST_SUCCESS: `${_namespace}/fetchservicetypelistsuccess`,
    FETCHSERVICETYPELIST_FAIL: `${_namespace}/fetchservicetypelistfail`,
    FETCH_SERVICERINGLIST_SUCCESS: `${_namespace}/fetchserviceringlistsuccess`,
    FETCHSERVICERINGLIST_FAIL: `${_namespace}/fetchserviceringlistfail`,
    FETCH_SERVICELIFECYCLELIST_SUCCESS: `${_namespace}/fetchservicelifecyclelistsuccess`,
    FETCHSERVICELIFECYCLELIST_FAIL: `${_namespace}/fetchservicelifecyclelistfail`,
    FETCH_IDCLASSIFICATION_SUCCESS: `${_namespace}/fetchidclassificationsuccess`,
    FETCH_IDCLASSIFICATION_FAIL: `${_namespace}/fetchidclassificationfail`,
    FETCH_IDACKTYPE_SUCCESS: `${_namespace}/fetchidacktypesuccess`,
    FETCH_IDACKTYPE_FAIL: `${_namespace}/fetchidacktypefail`,
    FETCH_IDACKFILTERLIST_SUCCESS: `${_namespace}/fetchfilterlistsuccess`,
    FETCH_IDACKFILTERLIST_FAIL: `${_namespace}/fetchfilterlistfail`,
    ACKTYPE_VALUE_CHANGE: `${_namespace}/acktypevaluechange`,
    FILTER_TEXT_VALUE_CHANGE: `${_namespace}/filtertextvaluechange`,
    GLOBAL_SEARCH_VALUE_CHANGE: `${_namespace}/globalsearchvaluechange`,
    FILTER_DROPDOWN_CHANGE: `${_namespace}/filterdropdownchange`,
    FILTER_DATA_BASEDON_FILTERID: `${_namespace}/filterdatabasedfilterid`,
    FILTER_DATA_BASEDON_FILTERID_FAIL: `${_namespace}/filterdatabasedonfilteridfail`,
    CLEAR_FILTERS: `${_namespace}/clearfilters`,
    SERVICEMANAGER_CHANGE: `${_namespace}/servicemanagerchange`,
    FILTER_DATA: `${_namespace}/filterdata`,
    SERVICES_CHANGE: `${_namespace}/serviceschange`,
    COMPONENT_CHANGE: `${_namespace}/componentchange`,
    SERVICE_LIFECYCLE_CHANGE: `${_namespace}/servicelifecyclechange`,
    SERVICE_TYPE_CHANGE: `${_namespace}/servicetypechange`,
    SERVICE_IDCLASSIFICATION_CHANGE: `${_namespace}/serviceidclassificationchange`,
    SERVICE_RING_CHANGE: `${_namespace}/serviceringchange`,
    GET_FILTER_REFRESH: `${_namespace}/getfilterrefresh`,
    OPEN_EMAIL_POPUP: `${_namespace}/openemailpopup`,
    EMAIL_POPUP_CLOSE: `${_namespace}/emailpopupclose`,
    SET_URL_PARAM_EMPTY: `${_namespace}/seturlparamempty`,
    ACKEDIT_POPUP_CLOSE: `${_namespace}/ackeditpopupclose`,
    OPEN_ACKTRACKING_POPUP: `${_namespace}/openacktrackingpopup`,
    ACKTRACKING_CONTROLS_CHANGE: `${_namespace}/acktrackingcontrolschange`,
    ACKTRACKING_DATE_CHANGE: `${_namespace}/acktrackingdatechange`,

   
});

