import React, { useState, Fragment, useRef } from 'react';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import { IDAcktrackingTemplate } from '../../../store/AckTrackingTemplate';
import {
    Card, Row, Col, Container, CardBody
} from 'reactstrap';
import { Modal, Button } from 'react-bootstrap'
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faPencilAlt, faTrash, } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import applySortOn from "../../../utils/AckTrackingCustomSort"
import { FontAwesomeIconMemo } from '../../../components';
import Copy_URL from '../../../assets/images/Copy_URL.png';
import copyemail from '../../../assets/images/copyemail.png';
import excel_1 from '../../../assets/images/excel_1.png';
import { title } from 'process';
import { Pointer } from 'highcharts';
import { faDownload, faTrashAlt, faSave, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
type IDAcktrackingTableProps = {
    headerdata: any;
    IdentityAckData: any;
    ServiceManagerList: any;
    ServiceList: any;
    ComponentList: any;
    ServiceTypeList: any;
    ServiceManagervalue: any;
    Servicevalue: any;
    Componentvalue: any;
    ServiceTypevalue: any;
    ServiceManagerChange: any;
    ServicesChange: any;
    ComponentChange: any;
    ServiceTypeChange: any;
    ServiceRinglist: any;
    ServiceRingvalue: any;
    ServiceLifeCyclelist: any;
    ServiceLifeCyclevalue: any;
    IDClassificationList: any;
    IDClassificationvalue: any;
    ServiceLifecyclechange: any;
    ServiceRingchange: any;
    IDClassificationchange: any;
    FilterAckData: any;
    clearfilterdata: any;
    globalsearch: any;
    searchtextboxchange: any;
    notificationACKId: any;
    IDAckTypeList: any;
    IdAcktypeChange: any;
    AcktrackingFilterList: any;
    AcktrackingFiltervalue: any;
    IdAckFilterListChange: any;
    NewFiltervalue: any;
    NewFiltertextboxchange: any;
    saveFilter: any;
    clearallfilters: any;
    exportAcktrackingReport: any;
    Iscontact_DevOwner: boolean;
    Iscontact_PMOwner: boolean;
    IslastUpdatedBy: boolean;
    OpenEmailPOPUP: any;
    CopyURL: any;
    openacktrackingpopup: any;
    title: any;
    showAckTrackingUserGuide: any;
    IsServiceSpecificAck: any;
    IsFilterDisabled: any;
    isAdmin: any;
};
const IDAcktrackingTable = React.memo<IDAcktrackingTableProps>(({
    headerdata,
    IdentityAckData,
    ServiceManagerList,
    ServiceList,
    ComponentList,
    ServiceTypeList,
    ServiceManagervalue,
    Servicevalue,
    Componentvalue,
    ServiceTypevalue,
    ServiceManagerChange,
    ServicesChange,
    ComponentChange,
    ServiceTypeChange,
    ServiceRinglist,
    ServiceRingvalue,
    ServiceLifeCyclelist,
    ServiceLifeCyclevalue,
    IDClassificationList,
    IDClassificationvalue,
    ServiceLifecyclechange,
    ServiceRingchange,
    IDClassificationchange,
    FilterAckData,
    clearfilterdata,
    globalsearch,
    searchtextboxchange,
    notificationACKId,
    IDAckTypeList,
    IdAcktypeChange,
    AcktrackingFilterList,
    AcktrackingFiltervalue,
    IdAckFilterListChange,
    NewFiltervalue,
    NewFiltertextboxchange,
    saveFilter,
    clearallfilters,
    exportAcktrackingReport,
    Iscontact_DevOwner,
    Iscontact_PMOwner,
    IslastUpdatedBy,
    OpenEmailPOPUP,
    CopyURL,
    openacktrackingpopup,
    title,
    showAckTrackingUserGuide,
    IsServiceSpecificAck,
    IsFilterDisabled,
    isAdmin
}) => {

    const [TableRowColor, setTableRowColor] = useState();
    const ColorLuminance = (hexcolor) => {
        hexcolor = hexcolor.replace("#", "");
        var r = parseInt(hexcolor.substr(0, 2), 16);
        var g = parseInt(hexcolor.substr(2, 2), 16);
        var b = parseInt(hexcolor.substr(4, 2), 16);
        var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return (yiq >= 128) ? 'black' : 'white';
    }
    const applyColoringlogic = (value, key) => {
        var $index = -1;
        var Valdata = [];
        var Coldata = [];
        var TrimValdata = [];
        for (var i = 0; i < headerdata.length; i++) {
            // var val = headerdata[i].field.substr(0, 2).toLowerCase() + headerdata[i].field.substr(2);
            var val = headerdata[i].field;
            if (key.toString().toLowerCase() == val.toString().toLowerCase() && headerdata[i].coloringValue != undefined && headerdata[i].coloringValue != null) {
                TrimValdata = headerdata[i].controlValue.split(',');
                Coldata = headerdata[i].coloringValue.split(',');
            }
        }

        if (isArrayWithLength(TrimValdata)) {
            Valdata = TrimValdata.map(s => s.trim())
        }

        $index = Valdata.indexOf(value);
        if ($index != -1) {
            var textcolor = Coldata[$index] == '#000000' ? '#ffffff' : '#ffffff' ? '#000000' : ColorLuminance(Coldata[$index]);
            return {
                'backgroundColor': Coldata[$index],
                'color': textcolor
            };
        }
        return { 'backgroundColor': '' };
    };
    const tableHeight = window.outerHeight - 415;
    const tableWidth = window.outerWidth - 60;

    // Sort Logic Start
    //Removing Total Volume and Daily Agg. from the sorting
    var tempIdentityAckData = [...IdentityAckData];
    const { result: dailyResult, requestSort: dailyRequestSort, sortConfig: dailySortConfig } = applySortOn(tempIdentityAckData)
    IdentityAckData = [...dailyResult];
    //This is for styles to indicate order
    const getClassNamesForDaily = (name) => {
        if (!dailySortConfig) {
            return;
        }
        return dailySortConfig.key === name ? dailySortConfig.direction : undefined;
    };
    const filterBasedSearch = (item) => {
        if (globalsearch.toString() === '') {
            return true;
        }
        else {
            let val = false;
            Object.keys(item).map((key, i) => {
                if (!val) {
                    if (item[key] != null && item[key] != undefined) {
                        if (item[key].toString().toLowerCase().includes(globalsearch.toString().toLowerCase())) {
                            val = true;
                        }
                    }
                }
            });
            return val;
        }
    };

    const IdAckTemplateGuideURL = "https://identitydivision.visualstudio.com/IdentityWiki/_wiki/wikis/IdentityWiki.wiki/18590/ID-Ack-Tracking-User-Guide";
    const openUrlInNewTab = (url) => {
        if (url !== '') {
            window.open(url, "_blank");
        }
    }

    const setRowColor = (selectedRow) => e => {
        if (selectedRow) {
            setTableRowColor(selectedRow);
        }
    }


    return (
        <div>
            <Card className="cardstyle">
                <CardBody className="cardbodyhederstyle">
                    <Row>
                        <Col lg="2">
                        </Col>
                        <Col lg="8">
                            <h3 className="headertext">Identity ACK Tracking</h3>
                            {title}
                        </Col>
                        <Col lg="2">
                            <input type="text" id="searchheight" className="form-control" value={globalsearch} onChange={e => searchtextboxchange(e)} placeholder="Global Search" />
                        </Col>


                    </Row>
                </CardBody>
            </Card>
            <br />
            <React.Fragment>
                <Row style={{ marginTop: '-20px' }}>
                    <Col xs='7'>
                    </Col>
                    <Col xs='5' style={{ textAlign: 'right' }}>
                        <React.Fragment><span className='ConfigGuide' onClick={e => openUrlInNewTab(IdAckTemplateGuideURL)}>
                            ID Ack Tracking User Guide&nbsp; </span>
                            <FontAwesomeIconMemo style={{ verticalAlign: 'middle' }} onClick={e => openUrlInNewTab(IdAckTemplateGuideURL)} title='Ack Tracking User Guide' icon={'question-circle'} className='icon-format-config' />
                        </React.Fragment>
                    </Col>
                </Row>
            </React.Fragment>
            <div className="IDAckboxShadow" style={{ width: '100%' }}>
                <div className="p-fluid p-grid">
                    <Row>
                        <Col lg='1'>
                            <div className="p-field p-col-10 p-md-4 trendcolumns multiselect-filter" >
                                <span className="p-float-label">
                                    <MultiSelect id="mlservicegroup" disabled={!isArrayWithLength(headerdata)} filter={true} optionLabel="name" value={ServiceManagervalue} options={ServiceManagerList} onChange={(e) => ServiceManagerChange(e.value)} maxSelectedLabels={0} />
                                    <label htmlFor="mlservicegroup" className="fontcolor">GEM</label>
                                </span>
                            </div>
                        </Col>

                        <Col lg={IsServiceSpecificAck ? '2' : '1'}>
                            <div className="p-field p-col-10 p-md-4 trendcolumns">
                                <span className="p-float-label">
                                    <MultiSelect id="mlservice" disabled={!isArrayWithLength(headerdata)} filter={true} optionLabel="name" value={Servicevalue} options={ServiceList} onChange={(e) => ServicesChange(e.value)} maxSelectedLabels={0} />
                                    <label htmlFor="mlservice" className="fontcolor">Service Name </label>
                                </span>
                            </div>
                        </Col>
                        <Col lg={!IsServiceSpecificAck ? '1' : '0'}>
                            <div className="p-field p-col-10 p-md-4 trendcolumns">
                                {
                                    (!isArrayWithLength(headerdata))
                                        ?
                                        <span className="p-float-label">
                                            <MultiSelect id="mlservice" disabled={true} filter={true} optionLabel="name" value={Componentvalue} options={ComponentList} onChange={(e) => ComponentChange(e.value)} maxSelectedLabels={0} />
                                            <label htmlFor="mlservice" className="fontcolor">Component </label>
                                        </span>
                                        : (!IsServiceSpecificAck && isArrayWithLength(headerdata)) ?
                                            <span className="p-float-label">
                                                <MultiSelect id="mlservice" disabled={false} filter={true} optionLabel="name" value={Componentvalue} options={ComponentList} onChange={(e) => ComponentChange(e.value)} maxSelectedLabels={0} />
                                                <label htmlFor="mlservice" className="fontcolor">Component </label>
                                            </span>
                                            : <React.Fragment></React.Fragment>
                                }

                            </div>
                        </Col>
                        <Col lg='1'>
                            <div className="p-field p-col-10 p-md-4 trendcolumns multiselect-filter">
                                <span className="p-float-label">
                                    <MultiSelect id="mllifecycletype" disabled={!isArrayWithLength(headerdata)} filter={true} optionLabel="name" value={ServiceLifeCyclevalue} options={ServiceLifeCyclelist} onChange={(e) => ServiceLifecyclechange(e.value)} maxSelectedLabels={0} />
                                    <label htmlFor="mllifecycletype" className="fontcolor">LifeCycle Type</label>
                                </span>
                            </div>
                        </Col>
                        <Col lg='1'>
                            <div className="p-field p-col-10 p-md-4 trendcolumns multiselect-filter">
                                <span className="p-float-label">
                                    <MultiSelect id="mlservicetype" disabled={!isArrayWithLength(headerdata)} filter={true} optionLabel="name" value={ServiceTypevalue} options={ServiceTypeList} onChange={(e) => ServiceTypeChange(e.value)} maxSelectedLabels={0} />
                                    <label htmlFor="mlservicetype" className="fontcolor">Service Type</label>
                                </span>
                            </div>
                        </Col>
                        <Col lg='1'>
                            <div className="p-field p-col-10 p-md-4 trendcolumns multiselect-filter">
                                <span className="p-float-label">
                                    <MultiSelect id="mlservicering" disabled={!isArrayWithLength(headerdata)} filter={true} optionLabel="name" value={ServiceRingvalue} options={ServiceRinglist} onChange={(e) => ServiceRingchange(e.value)} maxSelectedLabels={0} />
                                    <label htmlFor="mlservicering" className="fontcolor">Service Ring</label>
                                </span>
                            </div>
                        </Col>
                        <Col lg='1'>
                            <div className="p-field p-col-10 p-md-4 trendcolumns multiselect-filter">
                                <span className="p-float-label">
                                    <MultiSelect id="mlidclassification" disabled={!isArrayWithLength(headerdata)} filter={true} optionLabel="name" value={IDClassificationvalue} options={IDClassificationList} onChange={(e) => IDClassificationchange(e.value)} maxSelectedLabels={0} />
                                    <label htmlFor="mlidclassification" className="fontcolor">IDClassification</label>
                                </span>
                            </div>
                        </Col>
                        <Col lg='.5'>
                            {isArrayWithLength(headerdata) ?
                                <div className="p-field p-col-10 p-md-4 trendcolumns">
                                    <FontAwesomeIconMemo onClick={FilterAckData} title="Apply Filter" icon={'filter'} className='iconfont' />
                                    &nbsp;
                                    <FontAwesomeIconMemo onClick={clearallfilters} title="Clear Filter" icon={'minus-circle'} className='iconfont' />
                                </div>
                                :
                                <div></div>
                            }
                        </Col>
                        <Col lg='1'>
                            <div className="p-field p-col-10 p-md-4 trendcolumns dropdown-ack">
                                <span className="p-float-label">
                                    <Dropdown id={'servicegroup'} value={notificationACKId} options={IDAckTypeList} onChange={(e) => IdAcktypeChange(e.value)} optionLabel="name" />
                                    <label htmlFor="servicegroup" className="fontcolor">Ack Type</label>
                                </span>
                            </div>
                        </Col>
                        <Col lg='1'>
                            <div className="p-field p-col-10 p-md-4 trendcolumns dropdown-ack">
                                <span className="p-float-label">
                                    <Dropdown id="mlacktype" optionLabel="name" value={AcktrackingFiltervalue} options={AcktrackingFilterList} onChange={(e) => IdAckFilterListChange(e.value)} />
                                    <label htmlFor="mlacktype" className="fontcolor">Filter Names</label>
                                </span>
                            </div>
                        </Col>
                        <Col lg='1'>
                            <div className="p-field p-col-10 p-md-4 trendcolumns">
                                <input type="text" id="searchheight" disabled={!isArrayWithLength(headerdata)} className="form-control" value={NewFiltervalue} onChange={e => NewFiltertextboxchange(e)} placeholder="Filter Name" />

                            </div>
                        </Col>
                        <Col lg='1.5'>
                            {isArrayWithLength(headerdata) ?
                                <div className="p-field p-col-10 p-md-4 trendcolumns">
                                    {(IsFilterDisabled && (!isAdmin)) ?
                                        <React.Fragment></React.Fragment> :
                                        <React.Fragment>
                                            <FontAwesomeIconMemo onClick={saveFilter} title="Save Filter" icon={'save'} className='iconfont' />
                                            &nbsp;
                                        </React.Fragment>
                                    }

                                    <FontAwesomeIconMemo onClick={CopyURL} title="Copy the URL" icon={'link'} className='iconfont' />
                                    &nbsp;
                                    <FontAwesomeIconMemo onClick={exportAcktrackingReport} title="Download Excel" icon={'file-excel'} className='iconfont' />
                                    &nbsp;
                                    <FontAwesomeIconMemo onClick={OpenEmailPOPUP} title="Copy Email" icon={'envelope'} className='iconfont' />
                                </div>
                                : <div></div>
                            }
                        </Col>
                    </Row>
                </div>
            </div>
            <br />
            <Row>
                <Col lg="12">


                    <div style={{ width: tableWidth + 'px', height: tableHeight + 'px', overflowX: 'auto' }}>

                        {isArrayWithLength(headerdata) ?
                            <table id="tblAcktemplate" className="tblAcktemplate acktrackingsticky" style={{ width: '98%' }}>
                                <thead>
                                    <tr className="acktrackingheadtr">
                                        <th className="IDAck-sticky-col thackFixedcolstyle-first-col" style={{ fontSize: '16px' }} id="IsEditbutton">Edit</th>
                                        {headerdata.map((n, key) => (
                                            <React.Fragment key={key}>
                                                {
                                                    (n.field === 'service_Manager')
                                                        ? <th className="IDAck-sticky-col thackFixedcolstyle-second-col" style={{ fontSize: '16px' }} onClick={() => dailyRequestSort('service_Manager')}>GEM
                                                            {
                                                                (getClassNamesForDaily('service_Manager') === 'ascending')
                                                                    ? <React.Fragment>&nbsp;<FontAwesomeIcon icon={faSortDown} style={{ fontSize: '16px' }} /></React.Fragment>
                                                                    : (getClassNamesForDaily('service_Manager') === 'descending')
                                                                        ? <React.Fragment>&nbsp;<FontAwesomeIcon icon={faSortUp} style={{ fontSize: '16px' }} /></React.Fragment>
                                                                        : <React.Fragment></React.Fragment>
                                                            }
                                                        </th>
                                                        : (n.field === 'contact_DevOwner')
                                                            ? <th className="thackPMDEVOwnerFixedcolstyle" onClick={() => dailyRequestSort('contact_DevOwner')}>Dev Owner
                                                                {
                                                                    (getClassNamesForDaily('contact_DevOwner') === 'ascending')
                                                                        ? <React.Fragment>&nbsp;<FontAwesomeIcon icon={faSortDown} style={{ fontSize: '16px' }} /></React.Fragment>
                                                                        : (getClassNamesForDaily('contact_DevOwner') === 'descending')
                                                                            ? <React.Fragment>&nbsp;<FontAwesomeIcon icon={faSortUp} style={{ fontSize: '16px' }} /></React.Fragment>
                                                                            : <React.Fragment></React.Fragment>
                                                                }
                                                            </th>
                                                            : (n.field === 'componentName')
                                                                ? <th className="thackFixedcolstyle-forth-col" onClick={() => dailyRequestSort('componentName')}>Component Name
                                                                    {
                                                                        (getClassNamesForDaily('componentName') === 'ascending')
                                                                            ? <React.Fragment>&nbsp;<FontAwesomeIcon icon={faSortDown} style={{ fontSize: '16px' }} /></React.Fragment>
                                                                            : (getClassNamesForDaily('componentName') === 'descending')
                                                                                ? <React.Fragment>&nbsp;<FontAwesomeIcon icon={faSortUp} style={{ fontSize: '16px' }} /></React.Fragment>
                                                                                : <React.Fragment></React.Fragment>
                                                                    }
                                                                </th>
                                                                : (n.field === 'contact_PMOwner')
                                                                    ? <th className="thackPMDEVOwnerFixedcolstyle" onClick={() => dailyRequestSort('contact_PMOwner')}>
                                                                        PM Owner
                                                                        {
                                                                            (getClassNamesForDaily('contact_PMOwner') === 'ascending')
                                                                                ? <React.Fragment>&nbsp;<FontAwesomeIcon icon={faSortDown} style={{ fontSize: '16px' }} /></React.Fragment>
                                                                                : (getClassNamesForDaily('contact_PMOwner') === 'descending')
                                                                                    ? <React.Fragment>&nbsp;<FontAwesomeIcon icon={faSortUp} style={{ fontSize: '16px' }} /></React.Fragment>
                                                                                    : <React.Fragment></React.Fragment>
                                                                        }
                                                                    </th>
                                                                    : (n.field === 'serviceName')
                                                                        ? <th className="IDAck-sticky-col thackFixedcolstyle-third-col" style={{ fontSize: '16px' }} onClick={() => dailyRequestSort('serviceName')}>Service Name
                                                                            {
                                                                                (getClassNamesForDaily('serviceName') === 'ascending')
                                                                                    ? <React.Fragment>&nbsp;<FontAwesomeIcon icon={faSortDown} style={{ fontSize: '16px' }} /></React.Fragment>
                                                                                    : (getClassNamesForDaily('serviceName') === 'descending')
                                                                                        ? <React.Fragment>&nbsp;<FontAwesomeIcon icon={faSortUp} style={{ fontSize: '16px' }} /></React.Fragment>
                                                                                        : <React.Fragment></React.Fragment>
                                                                            }
                                                                        </th>
                                                                        : (n.field === 'serviceType')
                                                                            ? <th className="thackOtherFixedcolstyle" onClick={() => dailyRequestSort('serviceType')}>Service Type
                                                                                {
                                                                                    (getClassNamesForDaily('serviceType') === 'ascending')
                                                                                        ? <React.Fragment>&nbsp;<FontAwesomeIcon icon={faSortDown} style={{ fontSize: '16px' }} /></React.Fragment>
                                                                                        : (getClassNamesForDaily('serviceType') === 'descending')
                                                                                            ? <React.Fragment>&nbsp;<FontAwesomeIcon icon={faSortUp} style={{ fontSize: '16px' }} /></React.Fragment>
                                                                                            : <React.Fragment></React.Fragment>
                                                                                }
                                                                            </th>
                                                                            : (n.field === 'deploymentInfrastructure')
                                                                                ? <th className="thackOtherFixedcolstyle" onClick={() => dailyRequestSort('deploymentInfrastructure')}>Deployment Infrastructure
                                                                                    {
                                                                                        (getClassNamesForDaily('deploymentInfrastructure') === 'ascending')
                                                                                            ? <React.Fragment>&nbsp;<FontAwesomeIcon icon={faSortDown} style={{ fontSize: '16px' }} /></React.Fragment>
                                                                                            : (getClassNamesForDaily('deploymentInfrastructure') === 'descending')
                                                                                                ? <React.Fragment>&nbsp;<FontAwesomeIcon icon={faSortUp} style={{ fontSize: '16px' }} /></React.Fragment>
                                                                                                : <React.Fragment></React.Fragment>
                                                                                    }
                                                                                </th>
                                                                                : (n.field === 'serviceLifeCycle')
                                                                                    ? <th className="thackOtherFixedcolstyle" onClick={() => dailyRequestSort('serviceLifeCycle')}>Service LifeCycle Type
                                                                                        {
                                                                                            (getClassNamesForDaily('serviceLifeCycle') === 'ascending')
                                                                                                ? <React.Fragment>&nbsp;<FontAwesomeIcon icon={faSortDown} style={{ fontSize: '16px' }} /></React.Fragment>
                                                                                                : (getClassNamesForDaily('serviceLifeCycle') === 'descending')
                                                                                                    ? <React.Fragment>&nbsp;<FontAwesomeIcon icon={faSortUp} style={{ fontSize: '16px' }} /></React.Fragment>
                                                                                                    : <React.Fragment></React.Fragment>
                                                                                        }
                                                                                    </th>
                                                                                    : (n.field === 'serviceRing')
                                                                                        ? <th className="thackRingFixedcolstyle" onClick={() => dailyRequestSort('serviceRing')}>Service Ring
                                                                                            {
                                                                                                (getClassNamesForDaily('serviceRing') === 'ascending')
                                                                                                    ? <React.Fragment>&nbsp;<FontAwesomeIcon icon={faSortDown} style={{ fontSize: '16px' }} /></React.Fragment>
                                                                                                    : (getClassNamesForDaily('serviceRing') === 'descending')
                                                                                                        ? <React.Fragment>&nbsp;<FontAwesomeIcon icon={faSortUp} style={{ fontSize: '16px' }} /></React.Fragment>
                                                                                                        : <React.Fragment></React.Fragment>
                                                                                            }
                                                                                        </th>
                                                                                        : (n.field === 'identityServiceClassification')
                                                                                            ? <th className="thackOtherFixedcolstyle" onClick={() => dailyRequestSort('identityServiceClassification')}>ID Classification
                                                                                                {
                                                                                                    (getClassNamesForDaily('identityServiceClassification') === 'ascending')
                                                                                                        ? <React.Fragment>&nbsp;<FontAwesomeIcon icon={faSortDown} style={{ fontSize: '16px' }} /></React.Fragment>
                                                                                                        : (getClassNamesForDaily('identityServiceClassification') === 'descending')
                                                                                                            ? <React.Fragment>&nbsp;<FontAwesomeIcon icon={faSortUp} style={{ fontSize: '16px' }} /></React.Fragment>
                                                                                                            : <React.Fragment></React.Fragment>
                                                                                                }
                                                                                            </th>
                                                                                            :
                                                                                            <th className={((n.colLableSize < 10) && (n.colValueSize < 10)) ? "thackcustomcolstyle-60"
                                                                                                : ((n.colLableSize > 10) && (n.colValueSize > 10) && (n.colLableSize < 20) && (n.colValueSize < 20)) ? "thackcustomcolstyle-70"
                                                                                                    : ((n.colLableSize > 20) && (n.colValueSize > 20) && (n.colLableSize < 30) && (n.colValueSize < 30)) ? "thackcustomcolstyle-90"
                                                                                                        : ((n.colLableSize > 30) && (n.colValueSize > 30) && (n.colLableSize < 40) && (n.colValueSize < 40)) ? "thackcustomcolstyle-110"
                                                                                                            : "thackcustomcolstyle-130"} onClick={() => dailyRequestSort(n.field.toLowerCase())}>{n.field}

                                                                                                {
                                                                                                    (getClassNamesForDaily(n.field.toLowerCase()) === 'ascending')
                                                                                                        ? <React.Fragment>&nbsp;<FontAwesomeIcon icon={faSortDown} style={{ fontSize: '16px' }} /></React.Fragment>
                                                                                                        : (getClassNamesForDaily(n.field.toLowerCase()) === 'descending')
                                                                                                            ? <React.Fragment>&nbsp;<FontAwesomeIcon icon={faSortUp} style={{ fontSize: '16px' }} /></React.Fragment>
                                                                                                            : <React.Fragment></React.Fragment>
                                                                                                }
                                                                                            </th>

                                                }



                                            </React.Fragment>
                                        ))}
                                        <th className="thackOtherFixedcolstyle">Modified By</th>
                                        <th className="thackOtherFixedcolstyle">Modified Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isArrayWithLength(IdentityAckData)
                                        ?
                                        <React.Fragment>
                                            {
                                                IdentityAckData
                                                    .filter((item) => {
                                                        if (filterBasedSearch(item)) {
                                                            return true
                                                        }
                                                    })
                                                    .map((obj, index) => (
                                                        <tr className={TableRowColor === index ? "IDAckTableRowColor" : ""} onClick={setRowColor(index)} key={index}>
                                                            {
                                                                Object.keys(obj).map((key, i) => (
                                                                    <React.Fragment key={i}>
                                                                        {
                                                                            (key != 'notificationAckId' && key != 'isChanged' && key != 'serviceOid' && key != 'title' && key != 'geM_Filter' && key != 'componentOid' && key != 'componentName_Filter' && key != 'serviceType_Filter' && key != 'serviceLifeCycleType_Filter' && key != 'serviceRing_Filter' && key != 'identityServiceClassification_Filter')
                                                                                ?
                                                                                (key === 'isEdit')
                                                                                    ? <React.Fragment>
                                                                                        <td className="IDAck-sticky-col tdackFixedcolstyle-first-col"> <FontAwesomeIcon icon={faPencilAlt} className="ackediticon" onClick={e => openacktrackingpopup(obj)} /></td>
                                                                                    </React.Fragment>
                                                                                    :
                                                                                    (key === 'service_Manager')
                                                                                        ? <React.Fragment>
                                                                                            <td className="IDAck-sticky-col tdackFixedcolstyle-second-col">{obj[key]}</td>
                                                                                        </React.Fragment>
                                                                                        :
                                                                                        (key === 'serviceName')
                                                                                            ? <React.Fragment>
                                                                                                <td className="IDAck-sticky-col tdackFixedcolstyle-third-col">{obj[key]}</td>
                                                                                            </React.Fragment>
                                                                                            :
                                                                                            (key === 'componentName')
                                                                                                ? <React.Fragment>
                                                                                                    <td className="tdackFixedcolstyle-forth-col">{obj[key]}</td>
                                                                                                </React.Fragment>
                                                                                                :
                                                                                                (key === 'serviceRing')
                                                                                                    ? <React.Fragment>
                                                                                                        <td className="tdackRingFixedcolstyle">{obj[key]}</td>
                                                                                                    </React.Fragment>
                                                                                                    :
                                                                                                    (key === 'serviceType' || key === 'deploymentInfrastructure' || key === 'serviceLifeCycle' || key === 'identityServiceClassification')
                                                                                                        ? <React.Fragment>
                                                                                                            <td className="tdackOtherFixedcolstyle">{obj[key]}</td>
                                                                                                        </React.Fragment>
                                                                                                        :
                                                                                                        (key === 'contact_DevOwner' || key === 'contact_PMOwner')
                                                                                                            ? <React.Fragment>
                                                                                                                <td className="tdackPMDEVOwnerFixedcolstyle">{obj[key]}</td>
                                                                                                            </React.Fragment>
                                                                                                            :
                                                                                                            (key === 'modifiedBy')
                                                                                                                ? <React.Fragment>
                                                                                                                    <td className="tdackOtherFixedcolstyle">{obj[key]}</td>
                                                                                                                </React.Fragment>
                                                                                                                :
                                                                                                                (key === 'modifiedDate')
                                                                                                                    ? <td className="tdackOtherFixedcolstyle">{(obj[key] === null) ? '' :
                                                                                                                        (obj[key] === '1900-01-01T00:00:00') ? ''
                                                                                                                            : moment(obj[key]).format('MM/DD/YYYY hh:mm A')}</td>
                                                                                                                    :
                                                                                                                    (key !== 'isEdit' && key !== 'modifiedDate')
                                                                                                                        ? <React.Fragment>
                                                                                                                            <td><div style={applyColoringlogic(obj[key], key)}>{obj[key]}</div></td>
                                                                                                                        </React.Fragment>
                                                                                                                        :
                                                                                                                        <React.Fragment>
                                                                                                                            <td>{obj[key]}</td>
                                                                                                                        </React.Fragment>
                                                                                : <React.Fragment></React.Fragment>
                                                                        }

                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                        </tr>
                                                    ))
                                            }
                                        </React.Fragment>
                                        : <React.Fragment></React.Fragment>

                                    }
                                </tbody>
                            </table>
                            :
                            <Row>
                                <Col lg='3'>
                                </Col>
                                <Col lg='6'>
                                    <div style={{ color: 'Red', fontWeight: 'bold', textAlign: 'center' }}><br />Please select the AckType / AckFilter from the above dropdown to view the data..</div>
                                </Col>
                                <Col lg='3'>
                                </Col>
                            </Row>
                        }
                    </div>



                </Col>
            </Row>



        </div>
    )
});

IDAcktrackingTable.displayName = 'ID Ack tracking';
export default IDAcktrackingTable;