import { BaseService } from './base.service';
import { IGemServiceMappingData } from '../store/CreateAdoHierarchy';

class CreateAdoHierarchyService extends BaseService {

    private static _createadohierarchyService: CreateAdoHierarchyService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): CreateAdoHierarchyService {
        return (this._createadohierarchyService || (this._createadohierarchyService = new this('CreateADO')));
    }

    public async GetServiceTreeGemListData(): Promise<IGemServiceMappingData[]> {
        const { data } = await this.$http.get<IGemServiceMappingData[]>(`GetServiceTreeGemList`);
        return data;
    }
    public async CreateADOTreeTask(inData): Promise<any> {
        const { data } = await this.$http.post(`CreateADOTreeTask`, inData);
        return data;
    }    
}

export const CreateAdoHierarchyAPI = CreateAdoHierarchyService.Instance;