import * as  React from 'react';

const SortLogic = (dataArray, config = null) => {
    //Setting up the configuration {column, order}
    const [sortConfig, setSortConfig] = React.useState(null);

    const requestSort = key => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    }

    let sortedArray = React.useMemo(() => {
        let tempArray = [...dataArray];
        if (sortConfig !== null) {
            let nullItems = [];
            for (var i = 0; i < tempArray.length; i++) {
                if (!tempArray[i][sortConfig.key]) {
                    var tempItem = tempArray.splice(i, 1)
                    nullItems.push(tempItem);
                }
            }
            tempArray.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
            nullItems.forEach(i => tempArray.push(i[0]));
        }
        return tempArray;
    }, [dataArray, sortConfig]);

    return { result: sortedArray, requestSort, sortConfig };
}

export default SortLogic;
