import { BaseService } from './base.service';
//import { IPropCriticalAdvisoryData, PropAzureHighPriorityLink } from '../store/CriticalAdvisory';

/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class ConfigurationService extends BaseService {
    private static _serviceGroupService: ConfigurationService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): ConfigurationService {
        return (this._serviceGroupService || (this._serviceGroupService = new this('Configuration')));
    }

    public async ServicesFromServiceTree(): Promise<any> {
        const { data } = await this.$http.get<any>(`GetServices`);
        return data;
    }

    public async TenantFromServiceTree(serviceOid): Promise<any> {
        const { data } = await this.$http.get<any>(`GetTenants?serviceOid=${serviceOid}`);
        return data;
    }

    public async QuestionaireInfoCCL(serviceId, tenantId): Promise<any> {
        const { data } = await this.$http.get<any>(`QuestionaireInfoCCL?serviceId=${serviceId}&tenantId=${tenantId}`);
        return data;
    }

    public async ValidateCCLSelfConfiguration(tenantName): Promise<any> {
        const { data } = await this.$http.get<any>(`ValidateCCLSelfConfiguration?tenantName=${tenantName}`);
        return data;
    }

    public async InsertUpdateCCLService(serviceCCLDetails): Promise<any> {
        const { data } = await this.$http.post<any>(`ServiceOnboard`, serviceCCLDetails);
        return data;
    }

    public async CCL_SendEmail(RequestorMail, TenantName, selectedService, tab_text): Promise<any> {
        var sendEmailData = { "Requestor": RequestorMail, "TenantName": TenantName, "ServiceName": selectedService, "EmailContent": tab_text };
        const { data } = await this.$http.post<any>(`CCLSendEmail`, sendEmailData);
        return data;
    }
    
};

export const ConfigurationAPI = ConfigurationService.Instance;
