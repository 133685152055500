import React, { useState, Fragment } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { isArrayWithLength } from '../../../utils';
import { IPropOutageReviewWishr } from '../../../store/Weekly-ID-SHR/types';
import { faDownload, faTrashAlt, faPencilAlt, faEdit, faTrash, faSave, faBan, faUnlock, faLock, faCopy, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion } from 'react-bootstrap'

type OutageReviewProps = {
    OutageReviewData: any,
    isAdmin: any,
    CopyTableData: any,
    valchange: any,
    isOutageditenabled: boolean,
    SaveOutageReviewData: any,
    CancelEditOutageRow: any,
    OnOutagePresnterTextchange: any,
    isLocked: boolean,
    isOutageLockDisabledOnEdit: boolean,
    EditLockforOutage: any,
    outageaccordiontoggle: any,
    setoutageaccordiontoggledata: any
};

const OutageReview = React.memo<OutageReviewProps>(({
    OutageReviewData,
    isAdmin,
    CopyTableData,
    valchange,
    isOutageditenabled,
    SaveOutageReviewData,
    CancelEditOutageRow,
    OnOutagePresnterTextchange,
    isLocked,
    isOutageLockDisabledOnEdit,
    EditLockforOutage,
    outageaccordiontoggle,
    setoutageaccordiontoggledata
}) => {

    const [presenterEdit, setpresenterEdit] = useState('')
 
    const callvalchange = (e, row) => {
        setpresenterEdit(row.presenter)
        valchange(e, row)
    }

    return (
        <React.Fragment>
            <br />
            <Accordion>

                <Row style={{ paddingBottom: "14px" }}>
                    <Col lg="7" style={{ textAlign: 'left' }}><h5 className="headertext" title="Service owners must come prepared to speak to what occurred, root cause if known, the risk of reoccurrence moving forward, and provide an ETA to complete your PIR.">Outage Review (1 minute each)</h5></Col>
                    <Col lg="5">
                        {(isAdmin === true) ?
                            <div className="divaddnew">
                                <Button className='PIRAddbuttons' hidden={(OutageReviewData.length > 1 ? false : true)} onClick={() => CopyTableData('outagereview')}> <FontAwesomeIcon icon={faCopy} /> &nbsp;Copy Table</Button>&nbsp;
                                <Accordion.Toggle as={Button} eventKey="0" onClick={e => setoutageaccordiontoggledata(!outageaccordiontoggle)} style={{ background: 'none' }}>
                                    <span style={{ float: 'right', padding: '10px' }}>
                                        {(outageaccordiontoggle) ? <FontAwesomeIcon title='Collapse All' icon={'minus-circle'} className='icon-format-collapseinternalEscalation' /> : <FontAwesomeIcon title='Expand All' icon={'plus-circle'} className='icon-format-expandinternalEscalation' />
                                        }
                                    </span>
                                </Accordion.Toggle>
                            </div>
                            : <React.Fragment></React.Fragment>
                        }
                    </Col>
                </Row>
                <div style={{ display: (outageaccordiontoggle) ? 'block' : 'none' }}>
                    {
                        <Row className="tblpadding">
                            <Col lg="12">
                                <table id="tblPIR" className="tblPIR tblborder">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '10%' }}>Presenter</th>
                                            <th style={{ width: '5%' }}>Incident ID</th>
                                            <th style={{ width: '10%' }}>Owning Tenant</th>
                                            <th style={{ width: '10%' }}>Impacted Tenants</th>
                                            <th style={{ width: '50%' }}>Title</th>
                                            <th style={{ width: '5%', textAlign: 'center' }}><b>Actions</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!isArrayWithLength(OutageReviewData)
                                            ?
                                            <React.Fragment>
                                                <tr>
                                                    <td colSpan={7} className="clsnodatamessgefortable">
                                                        No Data Available
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                            : OutageReviewData.map((row: IPropOutageReviewWishr, index) => (
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <td>
                                                            <div>{(row.isEdit) ? <textarea className="form-control" onChange={e => OnOutagePresnterTextchange(e, row)} value={row.presenterEdit} name="presenterEdit" rows={1} cols={50} /> : row.presenter}</div>
                                                        </td>
                                                        <td>
                                                            <div><a title="Incident Id" href={'https://portal.microsofticm.com/imp/v3/incidents/details/' + row.outageId + '/home'} target="_blank">{row.outageId}</a></div>

                                                        </td>
                                                        <td>
                                                            <div>{row.owningTenantName}</div>
                                                        </td>
                                                        <td>
                                                            <div>{row.impactedTenants}</div>
                                                        </td>
                                                        <td>
                                                            <div>{row.title}</div>
                                                        </td>
                                                        <td style={{ textAlign: 'center' }}>
                                                            {
                                                                (!row.isEdit)
                                                                    ?
                                                                    <React.Fragment>
                                                                        <span style={{ paddingLeft: '20px' }}><span onClick={e => callvalchange(e, row)}>
                                                                            <FontAwesomeIcon title='Click to edit the item' className="bluecoloricons Iconsstyles" icon={faPencilAlt} /></span> </span> &nbsp;
                                                                    </React.Fragment>
                                                                    :
                                                                    <React.Fragment>
                                                                        <FontAwesomeIcon title='Save Changes' className="bluecoloricons Iconsstyles" onClick={e => SaveOutageReviewData(e, row)} icon={faSave} /> &nbsp;
                                                                        <FontAwesomeIcon title='Cancel Edit' className="Iconsstyles" onClick={e => CancelEditOutageRow(e, row)} icon={faBan} />
                                                                    </React.Fragment>
                                                            }
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                    </tbody>
                                </table>
                            </Col>
                        </Row >
                    }
                </div>
            </Accordion>
        </React.Fragment >
    );
});

OutageReview.displayName = 'Outage Review';

export default OutageReview;