import { BaseService } from './base.service';
import { IAvailabilitySummary } from '../store/availability';

/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class AvailabilityService extends BaseService {
    private static _serviceGroupService: AvailabilityService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): AvailabilityService {
        return (this._serviceGroupService || (this._serviceGroupService = new this('Availability')));
    }

    public async GetAvailabilitySummaryData(Weekstartdate: string): Promise<IAvailabilitySummary[]> {
        const { data } = await this.$http.get<IAvailabilitySummary[]>(`GetMsodsGridSummaryData?weekStartDate=${Weekstartdate}`);
        return data;
    }
}

export const AvailabilityAPI = AvailabilityService.Instance;
