

export type IPropHeatmapAdminData = {
    readonly viewId: number;
    readonly serviceId: number;
    viewName: string;
    fixedColQuery: string;
    readonly valQuery: string;
    readonly colorSchemeId: number;
    readonly totalsLabel: string;
    val1Label: string;
    readonly val2Label: string;
    readonly val1Cap: string;
    readonly val2Cap: string;
    readonly source: string;
    readonly hasVal2: Boolean;
    readonly kustoDbName: string;
    readonly kustoServer: string;
    readonly updatedBy: string;
    readonly updatedOn: string;
    readonly isReachability?: Boolean;
    readonly isActive: string;
    readonly serviceName: string;
    readonly coloringLogicId: number;
    readonly colorLogicText: string;
    readonly noOfDecimalId: number;
    readonly noOfDecimalText: string;
    readonly volumeType: string;
    readonly isMserView: boolean;
    readonly mserViewUpdatedBy: string;
    readonly mserViewUpdatedDate: string;
    readonly isMonthlyAvailability: boolean;
    readonly monthlyAvailabilityUpdatedBy: string;
    readonly monthlyAvailabilityUpdatedDate: string;
    readonly drillDownQuery: string;
    readonly toEmailList: string;
    readonly ccEmailList: string;
    readonly authenticationType: string;
    readonly isMserPresentationView: boolean;
    readonly isMonthlyAvailability_Qos: string;
    readonly isMserHeatmapTrendView: boolean;
    readonly isIdentityWeeklyNewsLetterView: boolean;
    readonly identityNewsLetterGroupId: number;
    isActiveStatus: boolean;
    RenameViewLabel: string;
};

export type IHeatmapAdminState = {
    readonly heatmapAdminData: IPropHeatmapAdminData[];
    readonly isLoading: boolean;    
    readonly searchText: string;
    readonly IsLivesitePMOrUser: boolean;
    readonly newsLetterTeamGroups: [];
};

const _namespace = 'HEATMAPADMIN';
export interface IActionType {
    readonly RESET_STATE: string;
    readonly GET_HEATMAPADMIN_REQUEST: string;
    readonly GET_HEATMAPADMIN_SUCCESS: string;
    readonly GET_HEATMAPADMIN_FAILURE: string;
    readonly SET_LOADING_TRUE: string;
    readonly SET_LOADING_FALSE: string;
    readonly ADDED_VISITED_USER: string;
    readonly CHANGE_SEARCHTEXT_HEATMAP_ADMIN: string;
    readonly GET_IDENTITYNEWSLETTER_TEAMGROUPS_SUCCESS: string;
    readonly GET_IDENTITYNEWSLETTER_TEAMGROUPS_FAILURE: string;
    readonly USER_CHECK: string;
}

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/heatmapadminreset`,
    GET_HEATMAPADMIN_REQUEST: `${_namespace}/getheatmapadminrequest`,
    GET_HEATMAPADMIN_SUCCESS: `${_namespace}/getheatmapadminsuccess`,
    GET_HEATMAPADMIN_FAILURE: `${_namespace}/getheatmapadminfailure`,
    SET_LOADING_TRUE: `${_namespace}/loadingtrue`,
    SET_LOADING_FALSE: `${_namespace}/loadingfalse`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    CHANGE_SEARCHTEXT_HEATMAP_ADMIN: `${_namespace}/changesearchtextheatmapadmin`,
    GET_IDENTITYNEWSLETTER_TEAMGROUPS_SUCCESS: `${_namespace}/getidentitynewsletterteamgroupsuccess`,
    GET_IDENTITYNEWSLETTER_TEAMGROUPS_FAILURE: `${_namespace}/getidentitynewsletterteamgroupfailure`,
    USER_CHECK: `${ _namespace }/UserCheck`,
});

