import { BaseService } from './base.service';
import { IPropOnCallDashboardTemplate, IPropOnCallDashboardQuestionnaire } from '../store/checklistTemplate';
import {
    IPropOnCallDashboardChecklist, PropOnCallDashboardService, Incidents, OCEView,
    MeetingEntityForOce, ChecklistQuestionsByChoiceForOce, ChecklistQuestionsForOce} from './../store/Checklist'


/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class ChecklistService extends BaseService {
    private static _serviceGroupService: ChecklistService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): ChecklistService {
        return (this._serviceGroupService || (this._serviceGroupService = new this('Checklist')));
    }

    public async GetOnCallDashboardTemplateData(): Promise<IPropOnCallDashboardTemplate[]> {
        const { data } = await this.$http.get<IPropOnCallDashboardTemplate[]>("GetOnCallDashboardTemplateData");
        return data;
    }
    public async MoveCheckListTemplateToArchive(TemplateID: number): Promise<number> {
        const { data } = await this.$http.get<number>(`MoveCheckListTemplateToArchive?TemplateID=${TemplateID}`);
        return data;
    }
    public async SaveOnCallDashboardQuestionnarie(row): Promise<number> {
        const { data } = await this.$http.post<number>("SaveOnCallDashboardQuestionnarie", row);
            return data
       
    }
    public async GetOnCallDashboardQuestionnaire(templateid: number): Promise<IPropOnCallDashboardQuestionnaire[]> {
        const { data } = await this.$http.get<IPropOnCallDashboardQuestionnaire[]>(`GetOnCallDashboardQuestionnaire?templateID=${templateid}`);
        return data;
    }
    public async GetOnCallDashboardCheckListData(): Promise<IPropOnCallDashboardChecklist[]> {
        const { data } = await this.$http.get<IPropOnCallDashboardChecklist[]>("GetOnCallDashboardCheckListData");
        return data;
    }
    public async MoveCheckListToArchive(CheckListID: number): Promise<number> {
        const { data } = await this.$http.get<number>(`MoveCheckListToArchive?CheckListID=${CheckListID}`);
        return data;
    }
    public async GetOnCallDashboardServices(): Promise<PropOnCallDashboardService[]> {
        const { data } = await this.$http.get<PropOnCallDashboardService[]>("GetOnCallDashboardServices");
        return data;
    }
    public async GetOnCallDashboardQuestionsByTemplate(templateID: number): Promise<IPropOnCallDashboardQuestionnaire[]> {
        const { data } = await this.$http.get<IPropOnCallDashboardQuestionnaire[]>(`GetOnCallDashboardQuestionsByTemplate?templateID=${templateID}`);
        return data;
    }
    public async SaveOnCallDashboardCheckListData(row): Promise<number> {
        const { data } = await this.$http.post<number>("SaveOnCallDashboardCheckListData", row);
        return data

    }
    public async GetOnCallCheckListQuestions(checkListID: number): Promise<IPropOnCallDashboardQuestionnaire[]> {
        const { data } = await this.$http.get<IPropOnCallDashboardQuestionnaire[]>(`GetOnCallCheckListQuestions?checkListID=${checkListID}`);
        return data;
    }
    public async GetFile(fileName: string): Promise<any> {
        const { data } = await this.$http.get<any>(`GetFile?fileName=${fileName}`);
        return data;
    }
    public async GetMeetingMainIncidentIds(): Promise<any> {
        const { data } = await this.$http.get<any>(`GetMeetingMainIncidentIds`);
        return data;
    }

    public async GetChecklistServicesforOCEView(): Promise<any> {
        const { data } = await this.$http.get<any>(`GetChecklistServicesforOCEView`);
        return data;
    }

    public async GetChecklistTemplateType(checklistId): Promise<any> {
        const { data } = await this.$http.get<any>(`GetChecklistTemplateType?checklistId=${checklistId}`);
        return data;
    }

    public async GetChecklistTemplateTypeByTemplateId(templateId, checklistId): Promise<any> {
        const { data } = await this.$http.get<any>(`GetChecklistTemplateTypeByTemplateId?templateId=${templateId}&checklistId=${checklistId}`);
        return data;
    }

    public async GetChecklistServicesforOCEViewReadonly(): Promise<any> {
        const { data } = await this.$http.get<any>(`GetChecklistServicesforOCEViewReadonly`);
        return data;
    }

    public async GetSubmittedByList(): Promise<any> {
        const { data } = await this.$http.get<any>(`GetSubmittedByList`);
        return data;
    }

    public async GetChecklistSforOCEView(serviceId): Promise<any> {
        const { data } = await this.$http.get<any>(`GetChecklistSforOCEView?serviceId=${serviceId}`);
        return data;
    }

    public async GetOCEList(serviceId, checklistID, fromDate, toDate, createdby, mainIncidentIdFilter, templateType): Promise<OCEView[]> {
        var mainIncidentId = mainIncidentIdFilter === 0 ? '' : mainIncidentIdFilter;
        const { data } = await this.$http.get<OCEView[]>(`GetOCEList?serviceId=${serviceId}&checklistID=${checklistID}&fromDate=${fromDate}&toDate=${toDate}&submittedBy=${createdby}&mainIncidentId=${mainIncidentId}&templateType=${templateType}`);
        return data;
    }

    public async GetMeetingItemsForOCE(serviceId, date, checklistID, meetingId): Promise<MeetingEntityForOce[]> {
        const { data } = await this.$http.get<MeetingEntityForOce[]>(`GetMeetingItemsForOCE?serviceId=${serviceId}&date=${date}&checklistID=${checklistID}&meetingId=${meetingId}`);
        return data;
    }

    public async DeleteChecklistOnCallEntry(serviceId, meetingId, checkListId, LoginuserId): Promise<number> {
        const { data } = await this.$http.get<number>(`DeleteChecklistOnCallEntry?serviceId=${serviceId}&meetingId=${meetingId}&checkListId=${checkListId}&LoginuserId=${LoginuserId}`);
        return data;
    }

    public async ValidateIncidentId(incidentId): Promise<boolean> {
        const { data } = await this.$http.get<boolean>(`ValidateIncidentId?incidentId=${incidentId}`);
        return data;
    }

    public async GetChecklistQuestionsByChoiceForOCE(objChecklistQuestionsByChoice: ChecklistQuestionsForOce): Promise<ChecklistQuestionsByChoiceForOce[]> {
        const { data } = await this.$http.post<ChecklistQuestionsByChoiceForOce[]>("GetChecklistQuestionsByChoiceForOCE", objChecklistQuestionsByChoice);
        return data;
    }

    public async InsertChecklistAnswersForOCE(objActionitems, serviceId, checklistId, createdBy, meetingDate, onCallDate, meetingId, meetingTitle, icmMainIncident): Promise<number> {
        const { data } = await this.$http.post<number>(`InsertChecklistAnswersForOCE?serviceId=${serviceId}&checklistId=${checklistId}&createdBy=${createdBy}&meetingDate=${meetingDate}&onCallDate=${onCallDate}&meetingId=${meetingId}&meetingTitle=${meetingTitle}&icmMainIncident=${icmMainIncident}`, objActionitems);
        return data;
    }

    public async InsertUpdateChecklistActionItemsForOCE(objMeetingEntity): Promise<number> {
        const { data } = await this.$http.post<number>("InsertUpdateChecklistActionItemsForOCE", objMeetingEntity);
        return data;
    }

    public async GetQuestionsForESTSService(obj): Promise<ChecklistQuestionsByChoiceForOce[]> {
        const { data } = await this.$http.post<ChecklistQuestionsByChoiceForOce[]>("GetQuestionsForESTSService", obj);
        return data;
    }

    public async GetChecklistIncidentsForOCE(servicename, fromDate, toDate): Promise<Incidents[]> {
        const { data } = await this.$http.get<Incidents[]>(`GetChecklistIncidentsForOCE?serviceName=${servicename}&fromDate=${fromDate}&toDate=${toDate}`);
        return data;
    }

    public async DeleteChecklistFile(meetingId, fileName): Promise<boolean> {
        const { data } = await this.$http.get<boolean>(`DeleteChecklistFile?meetingId=${meetingId}&fileName=${fileName}`);
        return data;
    }

    public async GetChecklistFile(meetingId, fileName): Promise<boolean> {
        const { data } = await this.$http.post<boolean>(`GetChecklistFile?meetingId=${meetingId}&fileName=${fileName}`);
        return data;
    }

    public async UploadFile(meetingId, formData): Promise<boolean> {
        let fileData = {
            MeetingID: meetingId, FileToUpload: formData.Files
        };
        const { data } = await this.$http.post<boolean>(`UploadFile`, fileData);
        return data;
    }

    public async GetOnCallDashboardTemplateAndChecklistQuestionsByIds(templateID: number, checklistID: number): Promise<IPropOnCallDashboardQuestionnaire[]> {
        const { data } = await this.$http.get<IPropOnCallDashboardQuestionnaire[]>(`GetOnCallDashboardTemplateAndChecklistQuestionsByIds?templateID=${templateID}&checklistID=${checklistID}`);
        return data;
    }

    public async CheckNextOnCallDate(): Promise<string> {
        const { data } = await this.$http.get<string>("CheckNextOnCallDate");
        return data;
    }

    public async ValidateOnCallCertification(): Promise<string> {
        const { data } = await this.$http.get<string>(`ValidateOnCallCertification`);
        return data;
    }
    public async ValidateOnCallWikiDate(wikiId: number): Promise<string> {
        const { data } = await this.$http.get<string>(`ValidateOnCallWikiDate?wikiId=${wikiId}`);
        return data;
    }
    public async GetTemplateRoles(): Promise<object[]> {
        const { data } = await this.$http.get<object[]>(`GetTemplateRoles`);
        return data;
    }
}

export const ChecklistAPI = ChecklistService.Instance;
