
export type IPropfutureOnCallData = {
    readonly teanantId: number;
    readonly tenantName: string;
    readonly teamId: number;
    readonly teamName: string;
    readonly teamEmailAddress: string;
    readonly startTime: string;
    readonly endTime: string;
    readonly rotationStartTime: string;
    readonly rotationEndTime: string;
    readonly shiftStartTime: string;
    readonly shiftEndTime: string;
    readonly name: string;
    readonly workPhoneNo: string;
    readonly cellNo: string;
    readonly emailAddress: string;
    readonly backupName: string;
    readonly bWorkPhoneNo: string;
    readonly bCellNo: string;
    readonly bEmailAddress: string;
}
export type IFutureOnCallState = {
    readonly isLoading: boolean;
    readonly futureOnCallData: IPropfutureOnCallData[];
    readonly futureOnCallDataFiltered: IPropfutureOnCallData[];
    readonly searchText: string;
    readonly isactive: string;
    readonly sortcolumn: string;
    readonly reverseSort: boolean;
    readonly TenantTeamNamesList: [];
    readonly CloudNamesList: [];
    readonly Isopenemailpopup: boolean;
    readonly OnCallAliasEmailIDs: string;
    readonly BackupOnCallAliasEmailIDs: string;
    readonly copyEmailselectedImorOce: any;
    readonly tenantList: [];
    readonly selectedTenant: any;
    readonly teamList: [];
    readonly selectedTeam: any;
    readonly selectedCloud: any;
    readonly ServiceLifeCycleList: [];
    readonly selectedServiceLifeCycle: any;
};
export type DropdownOption = {
    name: string;
    value: number;
};
const _namespace = 'FutureOnCall';

export interface IActionType {
    readonly RESET_STATE: string;
    readonly SET_LOADING_TRUE: string;
    readonly SET_LOADING_FALSE: string;
    readonly GET_FUTUREONCALL_REQUEST: string;
    readonly GET_FUTUREONCALL_SUCCESS: string;
    readonly GET_FUTUREONCALL_FAILURE: string;
    readonly ADDED_VISITED_USER: string;
    readonly CHANGE_SORTING_FUTUREONCALL: string;
    readonly GET_TENANTTEAMNAME_DATA: string;
    readonly OPEN_EMAIL_POPUP: string;
    readonly EMAIL_POPUP_CLOSE: string;
    readonly TENANT_CHANGE: string;
    readonly TEAM_CHANGE: string;
    readonly CLEAR_FILTER: string;
    readonly GET_CLOUDNAMES_DATA: string;
    readonly CLOUD_CHANGE: string;
    readonly GET_SERVICELIFECYCLE_LIST: string;
    readonly SERVICELIFECYCLE_CHANGE: string;
}

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/reset`,
    SET_LOADING_TRUE: `${_namespace}/setloadingtrue`,
    SET_LOADING_FALSE: `${_namespace}/setloadingfalse`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    GET_FUTUREONCALL_REQUEST: `${_namespace}/getfutureoncallrequest`,
    GET_FUTUREONCALL_SUCCESS: `${_namespace}/getfutureoncallsuccess`,
    GET_FUTUREONCALL_FAILURE: `${_namespace}/getfutureoncallfailure`,
    CHANGE_SORTING_FUTUREONCALL: `${_namespace}/sortingChange`,
    GET_TENANTTEAMNAME_DATA: `${_namespace}/getTenantTeamName`,
    OPEN_EMAIL_POPUP: `${_namespace}/openemailpopup`,
    EMAIL_POPUP_CLOSE: `${_namespace}/emailpopupclose`,
    TENANT_CHANGE: `${_namespace}/tenantChange`,
    TEAM_CHANGE: `${_namespace}/teamChange`,
    CLEAR_FILTER: `${_namespace}/clearFilter`,
    GET_CLOUDNAMES_DATA: `${_namespace}/getCloudNamesData`,
    CLOUD_CHANGE: `${_namespace}/cloudChange`,
    GET_SERVICELIFECYCLE_LIST: `${_namespace}/getservicelifecyclelist`,
    SERVICELIFECYCLE_CHANGE: `${_namespace}/servicelifecyclechange`
});

