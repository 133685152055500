import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faCog,
    faExclamationTriangle,
    faEye,
    faEyeSlash,
    faFile,
    faHeart,
    faInfoCircle,
    faLock,
    faMinus,
    faPlus,
    faSignInAlt,
    faSignOutAlt,
    faUser,
    faExternalLinkSquareAlt,
    faUserEdit,
    faHistory,
    faHome,
    faFilter,
    faDownload,
    faLink,
    faStop,
    faEdit,
    faTable,
    faChartBar,
    faFire,
    faEllipsisH,
    faEllipsisV,
    faClock,
    faSlash,
    faSignal,
    faCheckSquare,
    faSave,
    faTachometerAlt,
    faCalendar,
    faTimesCircle,
    faPaperPlane,
    faBan,
    faFileExcel,
    faMailBulk,
    faEraser,
    faEnvelope,
    faMinusCircle,
    faQuestionCircle,  
    faComment,
    faWindowClose,
    faPencilAlt,
    faUndo,
    faArchive,
    faAsterisk,
    faChevronDown,
    faArrowAltCircleLeft,
    faRecycle,
    faRing,
    faRedo,
    faRedoAlt,
    faPlusCircle,
    faAngleLeft,
    faAngleRight,
    faChevronUp,
    faShareSquare,
    faCheck,
    faExternalLinkAlt,
    faPaste,
    faCloudUploadAlt,
    faTrash,
    faCloudDownloadAlt,
    faArrowLeft,
    faArrowRight,
    faCaretUp,
    faCaretDown,
    faTimes,
    faInfo,
    faHeading,
    faChartLine,
    faClosedCaptioning,
    faPenSquare,
    faCheckCircle,
    faUsers
} from '@fortawesome/free-solid-svg-icons';

import {
    faGithub,
    faMediumM,
    faTwitter
} from '@fortawesome/free-brands-svg-icons';

library.add(
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faCog,
    faExclamationTriangle,
    faEye,
    faEyeSlash,
    faFile,
    faHeart,
    faInfoCircle,
    faLock,
    faMinus,
    faPlus,
    faSignInAlt,
    faSignOutAlt,
    faUser,
    faExternalLinkSquareAlt,
    faUserEdit,
    faHistory,
    faHome,
    faFilter,
    faDownload,
    faLink,
    faStop,
    faEdit,
    faTable,
    faChartBar,
    faFire,
    faEllipsisH,
    faEllipsisV,
    faClock,
    faSlash,
    faSignal,
    faCheckSquare,
    faSave,
    faTachometerAlt,
    faCalendar,
    faTimesCircle,
    faPaperPlane,
    faBan,
    faFileExcel,
    faMailBulk,
    faEraser,
    faEnvelope,
    faMinusCircle,
    faQuestionCircle,
    faComment,
    faWindowClose,
    faPencilAlt,
    faUndo,
    faArchive,
    faAsterisk,
    faChevronDown,
    faArrowAltCircleLeft,
    faRecycle,
    faRing,
    faRedo,
    faRedoAlt,
    faPlusCircle,
    faAngleLeft,
    faAngleRight,
    faChevronUp,
    faShareSquare,
    faCheck,
    faExternalLinkAlt,
    faPaste,
    faCloudUploadAlt,
    faTrash,
    faCloudDownloadAlt,
    faArrowLeft,
    faArrowRight,
    faCaretUp,
    faCaretDown,
    faTimes,
    faInfo,
    faHeading,
    faChartLine,
    faClosedCaptioning,
    faPenSquare,
    faCheckCircle,
    faUsers
);
