import { MSERHeatmapPreviewAPI, CommonAPI, ChecklistAPI } from '../../api';
import { IAppThunkAction, ReduxAction } from '../';
import { ActionType, IPropOnCallDashboardTemplate, IPropOnCallDashboardQuestionnaire, IDropdownOptions } from './types';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../utils';
import moment from 'moment';
import IdleTimer from 'react-idle-timer';
import { toast } from "react-toastify";
const questioncontroltypeoptions = [{ name: 'Select Control Type', value: '' },

{ name: 'CheckBox', value: 'CheckBox' },
{ name: 'Date', value: 'Date' },
{ name: 'Dropdown', value: 'Dropdown' },
{ name: 'FileUpload', value: 'FileUpload' },
{ name: 'Radiobutton', value: 'Radiobutton' },
{ name: 'TextArea', value: 'TextArea' },
{ name: 'TextBox', value: 'TextBox' }
];
const sourcetypes = [{ name: 'Select Source Type', value: '' },
{ name: 'Kusto', value: 'Kusto' },
{ name: 'URL', value: 'URL' },
{ name: 'None', value: 'None' }
];
const dynamicValidationTypes = [{ name: 'Select Validation Type', value: '' },
{ name: 'On-Call Certification', value: 'OnCallCertification' },
{ name: 'Wiki Updated Date', value: 'WikiUpdatedDate' },
{ name: 'None', value: 'None' }
];
const templateTypes = [{ name: 'Checklist Only', value: 'Checklist Only' },
    { name: 'Incident Investigation', value: 'Incident Investigation' }
];
const AuthenticationTypes = [{ name: 'Select Value', value: '' },
{ name: 'AAD AppId', value: 'AAD AppId' },
{ name: 'Dsts Blackforest', value: 'Dsts Blackforest' },
{ name: 'Dsts FairFax', value: 'Dsts FairFax' },
{ name: 'Dsts Mooncake', value: 'Dsts Mooncake' }
];
const tempNameList = [];
export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                });
            dispatch({
                LoginuserId: LoginuserId,
                type: ActionType.LOGIN_USERNAME_TEMPLATE
            });
        }

    },
    setloadingtrue: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
    },
    setloadingfalse: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_FALSE
        });
    },
    isCopyTempQuesCheckVal: (value): IAppThunkAction<ReduxAction> => (dispatch) => {
        const isCopyTemplateQuestion = value;
        const copyTempQuesSelect = value;
        dispatch({
            copyTempQuesSelect: copyTempQuesSelect,
            isCopyTemplateQuestion: isCopyTemplateQuestion,
            type: ActionType.IS_COPY_TEMP_CHECKVAL
        });
    },
    GetOnCallDashboardTemplateData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let templateRolesVal = [];
        dispatch({
            type: ActionType.FETCH_CHECKLIST_TEMPLATE_DATA
        });
        ChecklistAPI.GetOnCallDashboardTemplateData()
            .then((checklisttemplatedata: IPropOnCallDashboardTemplate[]) => {
                for (var i = 0; i < checklisttemplatedata.length; i++) {
                    tempNameList.push({ name: checklisttemplatedata[i].templateName, value: checklisttemplatedata[i].templateId })
                }
                dispatch({
                    checklisttemplatedata,
                    templateNameList: tempNameList,
                    type: ActionType.CHECKLIST_TEMPLATE_DATA_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.CHECKLIST_TEMPLATE_DATA_FAIL
                });
            });
        ChecklistAPI.GetTemplateRoles()
            .then((templateRoles: object[]) => {                                
                templateRoles.unshift({ value: '', name: 'Select Value' });
                dispatch({
                    templateRoles: templateRoles,
                    type: ActionType.CHECKLIST_GETTEMPLATE_ROLES_SUCCESS
                });
            }).catch(error => {
                templateRolesVal.unshift({ name: 'Select Value', value: '' });
                dispatch({
                    templateRoles: templateRolesVal,
                    type: ActionType.CHECKLIST_GETTEMPLATE_ROLES_FAILURE
                });
            });

    },
    OpentemplatePopup: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const templateRoles = getState().checklistTemplate.templateRoles;
        let template = {
            'templateID': 0, 'templateName': '', 'templateDescription': '', 'templateNotes': '', 'templateType': 'Checklist Only', CheckListCount: 0, IsActive: true, 'isLocked': false, 'templateRole': ''
        };
        let question = { QuestionID: 0, question: '', ControlType: '', displayOrder: '', sourceType: '', dynamicValidation: '', controlValue: '', serverName: '', Database: '', Query: '', kustoauthenticationtype: '', dataurl: '', Questionindex: -1 }

        dispatch({
            template,
            questioncontroltypeoptions,
            sourcetypes,
            dynamicValidationTypes,
            templateTypes,
            AuthenticationTypes,
            copyTempQuesSelect: false,
            isCopyTemplateQuestion: false,
            question,
            templateRoles: templateRoles,
            type: ActionType.OPEN_TEMPLATE_POPUP
        });
    },
    hidetemplatePopup: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let showtemplatePopup = getState().checklistTemplate.showtemplatePopup;
        dispatch({
            showtemplatePopup: !showtemplatePopup,
            type: ActionType.SHOWORHIDE_TEMPLATE_POPUP
        });
    },
    copyTempQuestiondrpChange: (copiedTemplateQues: IDropdownOptions): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            copiedTemplateQues: copiedTemplateQues,
            type: ActionType.COPY_TEMPLATE_SELECT
        });
    },
    copyTempQuestions: (copiedTemplateQues): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
       const templateRoles = getState().checklistTemplate.templateRoles;
       if (copiedTemplateQues) {
           dispatch({
               type: ActionType.SET_LOADING_TRUE
           });

           ChecklistAPI.GetOnCallDashboardQuestionnaire(copiedTemplateQues)
               .then((checklisttemplatequestions: IPropOnCallDashboardQuestionnaire[]) => {
                   const QuestionarrieList = [];
                   if (isArrayWithLength(checklisttemplatequestions)) {
                       checklisttemplatequestions.map((obj) => {
                           QuestionarrieList.push({
                               "QuestionID": 0,
                               "DisplayOrder": obj.displayOrder,
                               "DataUrl": obj.dataUrl,
                               "Question": obj.question,
                               "ControlType": obj.controlType,
                               "ControlValues": obj.controlValues,
                               "IsActive": obj.isActive,
                               "LastUpdatedBy": obj.lastUpdatedBy,
                               "SourceType": obj.sourceType,
                               "DynamicValidation": obj.dynamicValidation,
                               "ServerName": obj.serverName,
                               "Database": obj.database,
                               "AuthenticationType": obj.authenticationType,
                               "IsActionRequired": obj.isActionRequired,
                               "ActionItemValue": obj.actionItemValue,
                               "isChecked": true,
                               "Ischanged": false,
                               "Isdeleted": 0,
                               "Query": obj.query
                           });
                       });
                       // Sorting the Questionnaire List based on the Display Order
                       let sortColumn = "DisplayOrder";
                       QuestionarrieList.sort((a, b) => (parseInt(a[sortColumn]) > parseInt(b[sortColumn])) ? 1 : -1);
                   }
                   dispatch({
                       QuestionarrieList,
                       questioncontroltypeoptions,
                       sourcetypes,
                       dynamicValidationTypes,
                       templateTypes,
                       AuthenticationTypes,
                       templateRoles,
                       type: ActionType.COPY_QUESTION_TEMPLATE
                   });
               }).catch(error => {
                   dispatch({
                       QuestionarrieList: [],
                       questioncontroltypeoptions,
                       sourcetypes,
                       dynamicValidationTypes,
                       templateTypes,
                       AuthenticationTypes,
                       templateRoles,
                       type: ActionType.COPY_QUESTION_TEMPLATE
                   });
               });
       }
       else {
           dispatch({
               QuestionarrieList: [],
               questioncontroltypeoptions,
               sourcetypes,
               dynamicValidationTypes,
               templateTypes,
               AuthenticationTypes,
               templateRoles,
               type: ActionType.COPY_QUESTION_TEMPLATE_FAILURE
           });
       }
    },
    templatedatachange: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let { value, name, checked } = e.target;
        let oldtemplate = getState().checklistTemplate.template;
        let qlist = getState().checklistTemplate.QuestionarrieList;
        let template = {
            'templateID': oldtemplate.templateID, 'templateName': oldtemplate.templateName, 'templateDescription': oldtemplate.templateDescription,
            'templateNotes': oldtemplate.templateNotes, 'templateType': oldtemplate.templateType, 'isLocked': oldtemplate.isLocked, CheckListCount: oldtemplate.CheckListCount, IsActive: oldtemplate.IsActive, 'templateRole': oldtemplate.templateRole
        };
        if (name === 'IsActive') {
            template[name] = checked;
        }
        else {
            template[name] = value;
        }

        let istemplatesavebuttondisabled = Checkvalidtemplatedata(template, qlist);
        dispatch({
            template,
            istemplatesavebuttondisabled,
            type: ActionType.TEMPLATE_DATA_CHANGFE
        });
    },
    EditLockforChecklistTemplate: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let oldtemplate = getState().checklistTemplate.template;
        let istemplatesavebuttondisabled = getState().checklistTemplate.istemplatesavebuttondisabled;
        let template = {
            'templateID': oldtemplate.templateID, 'templateName': oldtemplate.templateName, 'templateDescription': oldtemplate.templateDescription,
            'templateNotes': oldtemplate.templateNotes, 'templateType': oldtemplate.templateType, 'isLocked': oldtemplate.isLocked, CheckListCount: oldtemplate.CheckListCount, IsActive: oldtemplate.IsActive, 'templateRole': oldtemplate.templateRole
        };

        template.isLocked = !template.isLocked;
        dispatch({
            template,
            istemplatesavebuttondisabled,
            type: ActionType.TEMPLATE_DATA_CHANGFE
        });
    },
    AddQuestionstoTemplate: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let question = { QuestionID: 0, question: '', ControlType: '', displayOrder: '', sourceType: '', dynamicValidation: '', controlValue: '', serverName: '', Database: '', Query: '', kustoauthenticationtype: '', dataurl: '', Questionindex: -1 }
        dispatch({
            question,
            type: ActionType.ENABLE_ADD_NEW_QUESTION
        });

    },
    ChecklistTemplateEdit: (template): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const templateRoles = getState().checklistTemplate.templateRoles;
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        //let template = {
        //    'templateID': oldtemplate.templateId, 'templateName': oldtemplate.templateName, 'templateDescription': oldtemplate.templateDescription,
        //    'templateNotes': oldtemplate.templateNotes, 'templateType': oldtemplate.templateType, 'isLocked': oldtemplate.isLocked, CheckListCount: oldtemplate.checklistCount, IsActive: oldtemplate.isActive, createdBy: oldtemplate.createdBy
        //};
        let question = { QuestionID: 0, question: '', ControlType: '', displayOrder: '', sourceType: '', dynamicValidation: '', controlValue: '', serverName: '', Database: '', Query: '', kustoauthenticationtype: '', dataurl: '', Questionindex: -1 }


        ChecklistAPI.GetOnCallDashboardQuestionnaire(template.templateID)
            .then((checklisttemplatequestions: IPropOnCallDashboardQuestionnaire[]) => {
                const QuestionarrieList = [];
                if (isArrayWithLength(checklisttemplatequestions)) {
                    checklisttemplatequestions.map((obj) => {
                        QuestionarrieList.push({
                            "QuestionID": obj.questionID,
                            "DisplayOrder": obj.displayOrder,
                            "DataUrl": obj.dataUrl,
                            "Question": obj.question,
                            "ControlType": obj.controlType,
                            "ControlValues": obj.controlValues,
                            "IsActive": obj.isActive,
                            "LastUpdatedBy": obj.lastUpdatedBy,
                            "SourceType": obj.sourceType,
                            "DynamicValidation": obj.dynamicValidation,
                            "ServerName": obj.serverName,
                            "Database": obj.database,
                            "AuthenticationType": obj.authenticationType,
                            "IsActionRequired": obj.isActionRequired,
                            "ActionItemValue": obj.actionItemValue,
                            "isChecked": true,
                            "Ischanged": false,
                            "Isdeleted": 0,
                            "Query": obj.query
                        });
                    });
                    // Sorting the Questionnaire List based on the Display Order
                    let sortColumn = "DisplayOrder";
                    QuestionarrieList.sort((a, b) => (parseInt(a[sortColumn]) > parseInt(b[sortColumn])) ? 1 : -1);
                }
                let istemplatesavebuttondisabled = Checkvalidtemplatedata(template, QuestionarrieList);
                dispatch({
                    QuestionarrieList,
                    template,
                    istemplatesavebuttondisabled,
                    questioncontroltypeoptions,
                    sourcetypes,
                    dynamicValidationTypes,
                    templateTypes,
                    AuthenticationTypes,
                    question,
                    templateRoles,
                    type: ActionType.TEMPLATE_EDIT
                });
            }).catch(error => {
                let template = {
                    'templateID': 0, 'templateName': '', 'templateDescription': '', 'templateNotes': '', 'templateType': '', 'isLocked': false, CheckListCount: 0, IsActive: true, 'templateRole':''
                };
                dispatch({
                    QuestionarrieList: [],
                    template: template,
                    istemplatesavebuttondisabled: true,
                    questioncontroltypeoptions,
                    sourcetypes,
                    dynamicValidationTypes,
                    templateTypes,
                    AuthenticationTypes,
                    question,
                    templateRoles,
                    type: ActionType.TEMPLATE_EDIT
                });
            });
    },
    ChecklistquestionEdit: (lst, index): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let qlist = getState().checklistTemplate.QuestionarrieList;
        let question = {};
        if (isArrayWithLength(qlist)) {
            qlist.map((obj, key) => {
                if (index === key) {
                    question = {
                        QuestionID: obj.QuestionID, question: obj.Question, ControlType: obj.ControlType, displayOrder: obj.DisplayOrder, sourceType: obj.SourceType, dynamicValidation: obj.DynamicValidation,
                        controlValue: obj.ControlValues, serverName: obj.ServerName, Database: obj.Database, Query: obj.Query, kustoauthenticationtype: obj.AuthenticationType, dataurl: obj.DataUrl,
                        Questionindex: index
                    };
                }
            });
        }
        dispatch({
            question,
            type: ActionType.ENABLE_ADD_NEW_QUESTION
        });
    },
    // Function Used to Update the Display Order for the Questionnaire Used in Template Questionnaire Section
    UpdateQuestionaireDisplayOrder: (data): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let istemplatesavebuttondisabled = getState().checklistTemplate.istemplatesavebuttondisabled;
        data.forEach((row, index) => {
            row.DisplayOrder = (index + 1).toString();
        });
        dispatch({
            istemplatesavebuttondisabled,
            QuestionarrieList: data,
            type: ActionType.ADD_QUESTIONS_TOLIST
        });
    },
    questionlistvalueschange: (e, index): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let template = getState().checklistTemplate.template;
        const { name, value, checked } = e.target;
        let qlist = getState().checklistTemplate.QuestionarrieList;
        const QuestionarrieList = [];
        if (isArrayWithLength(qlist)) {
            qlist.map((obj, key) => {
                if (key === index) {
                    if (name === 'Question') {
                        obj[name] = value;
                    }
                    else {
                        obj[name] = checked;
                    }
                }
                QuestionarrieList.push(obj);
            })
        }
        let istemplatesavebuttondisabled = Checkvalidtemplatedata(template, QuestionarrieList);
        dispatch({
            istemplatesavebuttondisabled,
            QuestionarrieList,
            type: ActionType.ADD_QUESTIONS_TOLIST
        });
    },
    showquestionlist: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SHOW_QUESTIONLIST
        });

    },
    handlequestionChange: (obj): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let oldquestion = getState().checklistTemplate.question;
        let { name, value } = obj.target;
        let question = {
            QuestionID: oldquestion.QuestionID, question: oldquestion.question, ControlType: oldquestion.ControlType, displayOrder: oldquestion.displayOrder, sourceType: oldquestion.sourceType, dynamicValidation: oldquestion.dynamicValidation, controlValue: oldquestion.controlValue,
            serverName: oldquestion.serverName, Database: oldquestion.Database, Query: oldquestion.Query, kustoauthenticationtype: oldquestion.kustoauthenticationtype, dataurl: oldquestion.dataurl,
            Questionindex: oldquestion.Questionindex
        }
        if (name === 'ControlType' || name === 'sourceType' || name === 'kustoauthenticationtype') {
            question[name] = obj.value;
        }
        else {
            question[name] = value;
        }
        if (name === 'sourceType') {
            question.serverName = '';
            question.Database = '';
            question.Query = '';
            question.kustoauthenticationtype = '';
            question.dataurl = '';

        }
        else if (name === 'ControlType') {
            question.controlValue = '';
        }
        let disableaddbuttonforquestion = Checknewquestiondata(question);
        dispatch({
            disableaddbuttonforquestion,
            question,
            type: ActionType.QUESTION_CONTROLS_CHANGFE
        });

    },
    AddNewquestiontolist: (lst, Questionindex): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let qlist = getState().checklistTemplate.QuestionarrieList;
        let template = getState().checklistTemplate.template;
        const QuestionarrieList = [];
        if (isArrayWithLength(qlist)) {
            qlist.map((obj, key) => {
                if (key === Questionindex) {
                    QuestionarrieList.push(lst);
                }
                else {
                    QuestionarrieList.push(obj);
                }
            });
        }
        if (Questionindex === -1) {
            QuestionarrieList.push(lst);
        }
        let istemplatesavebuttondisabled = Checkvalidtemplatedata(template, QuestionarrieList);
        dispatch({
            istemplatesavebuttondisabled: istemplatesavebuttondisabled,
            QuestionarrieList,
            type: ActionType.ADD_QUESTIONS_TOLIST
        });

    },
    MoveChecklistTemplateToArchive: (TemplateID): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.MOVECHECKLISTTEMPLATETOARCHIVE
        });
        ChecklistAPI.MoveCheckListTemplateToArchive(TemplateID)
            .then((isSuccess: number) => {
                toast.success(renderToastifyMsg('Archived Successfully. Loading Data Please Wait',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
                callGetOnCallDashboardTemplateData(dispatch)
            }).catch(error => {
                toast.error(renderToastifyMsg('Archived Process Failed. Please Try Again.',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
                dispatch({
                    type: ActionType.MOVECHECKLISTTEMPLATETOARCHIVE_FAIL
                });
            });
    },
};


// function to call GetOnCallDashboardTemplateData action method
const callGetOnCallDashboardTemplateData = (dispatch) => {
    dispatch(actionCreators.GetOnCallDashboardTemplateData());
}

const Checknewquestiondata = (question) => {

    if (isNullOrUndefined(question.question)) {
        return true;
    }
    else if (isNullOrUndefined(question.ControlType)) {
        return true;
    }
    // Handled the Display Order in Questionaire Save Function hence commented
    //if (isNullOrUndefined(question.displayOrder)) {
    //    return true;
    //}
    if (isNullOrUndefined(question.sourceType)) {
        return true;
    }
    if (question.sourceType === 'Kusto') {
        if (isNullOrUndefined(question.serverName)) {
            return true;
        }
        if (isNullOrUndefined(question.Database)) {
            return true;
        }
        if (isNullOrUndefined(question.Query)) {
            return true;
        }
        if (isNullOrUndefined(question.kustoauthenticationtype)) {
            return true;
        }
    }
    else if (question.sourceType === 'URL') {
        if (isNullOrUndefined(question.dataurl)) {
            return true;
        }
        else if (!ValidateURL(question.dataurl)) {
            return true;
        }
    }
    if ((question.ControlType === "CheckBox" || question.ControlType === "Radiobutton" || question.ControlType === "Dropdown")) {
        if (isNullOrUndefined(question.controlValue)) {
            return true;
        }
    }
    if (isNullOrUndefined(question.dynamicValidation)) {
        return true;
    }
    return false;
}

const ValidateURL = (url) => {
    var valid = false;
    var regex = /^(?:(?:https):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    var m;
    m = regex.exec(url);
    if (m !== null) {
        valid = true;
    }
    return valid;
}
const Checkvalidtemplatedata = (template, Questionarrie) => {
    if (isNullOrUndefined(template.templateName)) {
        return true;
    }
    else if (isNullOrUndefined(template.templateDescription)) {
        return true;
    }
    else if (isNullOrUndefined(template.templateNotes)) {
        return true;
    }
    else if (isNullOrUndefined(template.templateType)) {
        return true;
    }
    else if (!isArrayWithLength(Questionarrie)) {
        return true;
    }
    else if (isNullOrUndefined(template.templateRole)) {
        return true;
    }
    else if (isArrayWithLength(Questionarrie)) {

        var isvaliddata = true;
        for (var i = 0; i < Questionarrie.length; i++) {
            if (Questionarrie[i].id !== -1) {
                if (Questionarrie[i].Question == undefined || Questionarrie[i].Question == "") {
                    isvaliddata = false;
                    i = Questionarrie.length;
                }
                else if (Questionarrie[i].ControlType == undefined || Questionarrie[i].ControlType == 0) {
                    isvaliddata = false;
                    i = Questionarrie.length;
                }
                else if (Questionarrie[i].DisplayOrder == undefined || Questionarrie[i].DisplayOrder == 0 || Questionarrie[i].DisplayOrder == "") {
                    isvaliddata = false;
                    i = Questionarrie.length;
                }
                else if (Questionarrie[i].ControlType == "CheckBox" || Questionarrie[i].ControlType == "Radiobutton" || Questionarrie[i].ControlType == "Dropdown") {
                    if (Questionarrie[i].ControlValues == undefined || Questionarrie[i].ControlValues == 0 || Questionarrie[i].ControlValues == "") {
                        isvaliddata = false;
                        i = Questionarrie.length;
                    }
                }
                if (isvaliddata == true) {
                    if (Questionarrie[i].IsActionRequired == true) {
                        if ((Questionarrie[i].ControlType == "CheckBox" || Questionarrie[i].ControlType == "Radiobutton" || Questionarrie[i].ControlType == "Dropdown") && Questionarrie[i].ControlValues != '') {
                            if (Questionarrie[i].ActionItemValue == undefined || Questionarrie[i].ActionItemValue == 0 || Questionarrie[i].ActionItemValue == "") {
                                isvaliddata = false;
                                i = Questionarrie.length;
                            }
                        }

                    }
                }
                if (isvaliddata == true) {
                    if (Questionarrie[i].SourceType == undefined || Questionarrie[i].SourceType == 0 || Questionarrie[i].SourceType == "") {
                        isvaliddata = false;
                        i = Questionarrie.length;
                    }
                    else if (Questionarrie[i].SourceType == 'Kusto') {
                        if (Questionarrie[i].ServerName == undefined || Questionarrie[i].ServerName == 0 || Questionarrie[i].ServerName == "") {
                            isvaliddata = false;
                            i = Questionarrie.length;
                        }
                        else if (Questionarrie[i].Database == undefined || Questionarrie[i].Database == 0 || Questionarrie[i].Database == "") {
                            isvaliddata = false;
                            i = Questionarrie.length;
                        }
                        else if (Questionarrie[i].Query == undefined || Questionarrie[i].Query == 0 || Questionarrie[i].Query == "") {
                            isvaliddata = false;
                            i = Questionarrie.length;
                        }
                        else if (Questionarrie[i].AuthenticationType == undefined || Questionarrie[i].AuthenticationType == 0 || Questionarrie[i].AuthenticationType == "") {
                            isvaliddata = false;
                            i = Questionarrie.length;
                        }
                    }
                    else if (Questionarrie[i].SourceType == 'URL') {
                        if (Questionarrie[i].DataUrl == undefined || Questionarrie[i].DataUrl == 0 || Questionarrie[i].DataUrl == '') {
                            isvaliddata = false;
                            i = Questionarrie.length;
                        }
                        else if (!ValidateURL(Questionarrie[i].DataUrl)) {
                            isvaliddata = false;
                            i = Questionarrie.length;
                        }
                    }
                }
            }

        }
        if (isvaliddata == false) {
            return true;
        }
    }
    return false;
}