import React, { useState, Fragment, useRef } from 'react';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import { IDailyData, UniqueDateHeaders, UniqueFixedHeaderColumns, HmMetric, IComments, IColorScheme, IAllViewDetails, IMultiViewDetails, IMSERAdditionalInfo, PropFleetutilizationHM, DateLabels, DailyFleet, IFleetcolorSchemes } from '../../../store/service-health';
import moment from 'moment';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink, Card, Row, Col, Button
} from 'reactstrap';
import { FontAwesomeIconMemo } from '../../../components';
import { ToggleButtonRadioProps } from 'react-bootstrap'
import "../../../assets/style/scss/components/daily.scss"
import applySortOn from "../../../utils/customSortMethod"
import classnames from 'classnames';
import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { DailyDataApi } from '../../../api';
import { toast, ToastId } from 'react-toastify';

type MultipleHeatMapViewProps = {
    GetHeatmapConfigurationGuide: any;
    mvdailyData?: IDailyData[];
    mvComments: IComments[];
    mvcolorScheme: IColorScheme[];
    mvDailyDataFailure: any;
    mvIsdataLoaded: any;
    Multipleviewdetailsdata: any;
    multipleviewSelectedOption: any;
    CommentSummaryMultiView: any;
};

const tdvalue = {
    paddingRight: '0px',
    fontSize: '11px',
    cursor: 'pointer',
    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans - serif!important'
}

const MultipleHeatMapViewData = React.memo<MultipleHeatMapViewProps>(({
    GetHeatmapConfigurationGuide,
    mvdailyData,
    mvComments,
    mvcolorScheme,
    mvDailyDataFailure,
    mvIsdataLoaded,
    Multipleviewdetailsdata,
    multipleviewSelectedOption,
    CommentSummaryMultiView
}) => {

    const Displayvolume = [{ 'id': 'd', 'name': 'Default (K/M)' }, { 'id': 'k', 'name': 'Thousands (K)' }, { 'id': 'm', 'name': 'Millions (M)' }];
    const AuthenticationType = [{ 'id': 'AAD AppId', 'name': 'AAD AppId' }, { 'id': 'Dsts Blackforest', 'name': 'Dsts Blackforest' }, { 'id': 'Dsts FairFax', 'name': 'Dsts FairFax' }, { 'id': 'Dsts Mooncake', 'name': 'Dsts Mooncake' }];

    const SetStyleFUHM = (color) => {
        return { "backgroundColor": color };
        //if (color === 'L') {
        //    return { "backgroundColor": "LightGrey" };
        //}
        //if (color === 'R') {
        //    return { "backgroundColor": "#f3afa1" };
        //}
        //else if (color === 'Y') {
        //    return { "backgroundColor": "#ffe599" };
        //}
        //else if (color === 'G') {
        //    return { "backgroundColor": "#c5e0b3" };
        //}
    }
    const Isfailurevolume = (val: any) => {
        if (val.length > 0) {
            for (var i = 0; i < val.length; i++) {
                if (val[i].isFailureVolume) {
                    return true;
                }
            }
            return false;
        }
    }

    // Sort Logic Start

    //Removing Total Volume and Daily Agg. from the sorting
    var tempDailyData = [...mvdailyData];
    let tempdailyTotal = [];
    let tempdailyAvg = [];

    var i = 0;
    while (i < tempDailyData.length) {
        if (tempDailyData[i].key === "Total Volume") {
            tempdailyTotal.push(tempDailyData[i]);
        }
        else if (tempDailyData[i].key === "Daily Agg.") {
            tempdailyAvg.push(tempDailyData[i]);
        }
        i++;
    }

    tempDailyData = tempDailyData.filter(i => (i.key !== 'Total Volume' && i.key !== 'Daily Agg.'))

    const { result: dailyResult, requestSort: dailyRequestSort, sortConfig: dailySortConfig } = applySortOn(tempDailyData)
    if (tempdailyTotal.length > 0 && tempdailyAvg.length > 0) {
        for (var i = 0; i < tempdailyTotal.length; i++) {
            dailyResult.push(tempdailyTotal[i]);
        }
        for (var i = 0; i < tempdailyAvg.length; i++) {
            dailyResult.push(tempdailyAvg[i]);
        }
    }
    mvdailyData = [...dailyResult];


    //This is for styles to indicate order
    const getClassNamesForDaily = (name) => {
        if (!dailySortConfig) {
            return;
        }
        return dailySortConfig.key === name ? dailySortConfig.direction : undefined;
    };

    return (
        <div>
            <Row>
                <Col xs='7'>
                </Col>
                <Col xs='5' style={{ textAlign: 'right' }}>
                    <React.Fragment><span className='ConfigGuide' onClick={GetHeatmapConfigurationGuide}>
                        Heatmap Configuration User Guide&nbsp;
                        <FontAwesomeIconMemo style={{ verticalAlign: 'middle' }} onClick={GetHeatmapConfigurationGuide} title='Heatmap Configuration User Guide' icon={'info-circle'} className='icon-format-config' />
                    </span></React.Fragment>
                </Col>
            </Row>

            <React.Fragment>
                {
                    (isArrayWithLength(mvdailyData))
                        ?
                        <React.Fragment>
                            {Multipleviewdetailsdata.map((viewitem: IMultiViewDetails, viewindex) => (
                                <React.Fragment key={viewindex}>
                                    <Row>
                                        &ensp;&ensp;
                                        <span style={{ fontSize: 'medium', fontWeight: 'bold', color: '#0080D5', opacity: '0.7', float: 'left' }}>{viewitem.viewName}</span>
                                        <br />
                                    </Row>
                                    {(viewitem.mvdailydataPresent === true) ?
                                        <React.Fragment>
                                            <Row>
                                                <Col lg='9'>
                                                    <div style={{ overflowX: 'auto' }} className='sticky-wrapper'>
                                                        <div className={(viewitem.mvdclength < 20) ? ((viewitem.mvVolumeLabel.length > 6) ? 'sticky-scroller_vol' : 'sticky-scroller')
                                                            : (viewitem.mvdclength > 20 && viewitem.mvdclength < 50) ? ((viewitem.mvVolumeLabel.length > 6) ? 'sticky-scroller-20_vol' : 'sticky-scroller-20')
                                                                : ((viewitem.mvVolumeLabel.length > 6) ? 'sticky-scroller-50_vol' : 'sticky-scroller-50')}>
                                                            <table className='sticky-table' id='tdDailydata'>
                                                                <thead>
                                                                    <tr>
                                                                        <th className={viewitem.mvdclength < 20 ? 'Dailydatath datacenterwidth sticky-fixed-col-th ' + getClassNamesForDaily('key') : (viewitem.mvdclength > 20 && viewitem.mvdclength < 50) ? 'Dailydatath datacenterwidth sticky-fixed-col-th-20 ' + getClassNamesForDaily('key') : 'Dailydatath datacenterwidth sticky-fixed-col-th-50 ' + getClassNamesForDaily('key')} onClick={() => dailyRequestSort('key')}>Data Center</th>
                                                                        <th className={(viewitem.mvdclength < 20) ? (viewitem.mvVolumeLabel.length > 6 ? 'Dailydatath sticky-fixed-col2-th_vol ' + getClassNamesForDaily('sortVolume') : 'Dailydatath sticky-fixed-col2-th ' + getClassNamesForDaily('sortVolume'))
                                                                            : (viewitem.mvdclength > 20 && viewitem.mvdclength < 50) ? ((viewitem.mvVolumeLabel.length > 6) ? 'Dailydatath sticky-fixed-col2-th-20_vol ' + getClassNamesForDaily('sortVolume') : 'Dailydatath sticky-fixed-col2-th-20 ' + getClassNamesForDaily('sortVolume'))
                                                                                : ((viewitem.mvVolumeLabel.length > 6) ? 'Dailydatath sticky-fixed-col2-th-50_vol ' + getClassNamesForDaily('sortVolume') : 'Dailydatath sticky-fixed-col2-th-50 ' + getClassNamesForDaily('sortVolume'))}
                                                                            onClick={() => dailyRequestSort('sortVolume')}>{viewitem.mvVolumeLabel} </th>
                                                                        {
                                                                            (Isfailurevolume(mvdailyData) === true) ? <th className={'Dailydatath ' + getClassNamesForDaily('sortFailureVolume')} onClick={() => dailyRequestSort('sortFailureVolume')}>Failure Volume </th> : <React.Fragment></React.Fragment>
                                                                        }
                                                                        {isArrayWithLength(viewitem.mvuniqueFixedHeaderColumns) &&
                                                                            viewitem.mvuniqueFixedHeaderColumns.map((fhc: UniqueFixedHeaderColumns, key) => (
                                                                                <th className='Dailydatath' key={key}>{fhc}</th>
                                                                            ))}


                                                                        <th className='Dailydatath'>Agg.</th>
                                                                        {isArrayWithLength(viewitem.mvuniqueDateHeaders) &&
                                                                            viewitem.mvuniqueDateHeaders.map((dh: UniqueDateHeaders, key) => (
                                                                                <th className='Dailydatath' key={key}>{moment(dh.key).format('MM/DD')}</th>
                                                                            ))}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {

                                                                        isArrayWithLength(mvdailyData) &&
                                                                        mvdailyData
                                                                            .filter((ele) => {
                                                                                if (parseInt(ele.viewId) === parseInt(viewitem.viewId)) {
                                                                                    return true
                                                                                }
                                                                            })
                                                                            .map((hmd: IDailyData, val) => (
                                                                                <React.Fragment key={val}>
                                                                                    {
                                                                                        (!hmd.isOutOfSlaUptime && !hmd.isDailyVolume) ? <tr style={{ border: 'none' }}>
                                                                                            <td className={viewitem.mvdclength < 20 ? "datacenterwidth sticky-fixed-col" : (viewitem.mvdclength > 20 && viewitem.mvdclength < 50) ? "datacenterwidth sticky-fixed-col-20" : "datacenterwidth sticky-fixed-col-50"} style={{ fontSize: '14px', textAlign: 'left' }} key={val}><span style={{ paddingLeft: '4px', wordBreak: 'break-word' }}>{hmd.key}</span></td>
                                                                                            <td className={(viewitem.mvdclength < 20) ? ((viewitem.mvVolumeLabel.length > 6) ? "sticky-fixed-col2_vol" : "sticky-fixed-col2")
                                                                                                : (viewitem.mvdclength > 20 && viewitem.mvdclength < 50) ? ((viewitem.mvVolumeLabel.length > 6) ? "sticky-fixed-col2-20_vol" : "sticky-fixed-col2-20")
                                                                                                    : ((viewitem.mvVolumeLabel.length > 6) ? "sticky-fixed-col2-50_vol" : "sticky-fixed-col2-50")} style={{ fontSize: '14px' }}>{hmd.volume}</td>
                                                                                            {(Isfailurevolume(mvdailyData) === true) ? <td className="textalignright" style={{ fontSize: '12px' }}>{hmd.failureVolume}</td> : <React.Fragment></React.Fragment>}
                                                                                            {isArrayWithLength(hmd.fixedColumns) && hmd.fixedColumns.map((fc: UniqueDateHeaders, key) =>
                                                                                                < td className="textalignleft" style={{ fontSize: '14px' }} key={key}>{fc.value}</td>
                                                                                            )}
                                                                                            {
                                                                                                (hmd.key !== 'Total Volume' && hmd.key !== 'Daily Agg.') ?
                                                                                                    (hmd.avg === null) ? <td></td> :
                                                                                                        <td className="textAgg" style={JSON.parse((hmd.avg.colorType != null) ? hmd.avg.colorType.replace('#c6e0b4', '#CEF8C1') : hmd.avg.colorType)} ><span style={tdvalue}>{hmd.avg.percentage}</span></td> : <td></td>
                                                                                            }
                                                                                            {
                                                                                                hmd.metricList.sort().map((hmm: HmMetric, key) =>
                                                                                                    <td className={viewitem.mvdclength > 50 ? "tdvalue-50" : "tdvalue"} style={JSON.parse((hmm.value.colorType != null) ? hmm.value.colorType.replace('#c6e0b4', '#CEF8C1') : hmm.value.colorType)} key={key} >
                                                                                                        <div className={`rowdisplay ${hmm.comment ? 'note' : ''} ${hmm.hasMserComment ? 'mser' : ''}`} >

                                                                                                            {(hmm.comment)
                                                                                                                ?
                                                                                                                <div className='commentSummary' summary-content={CommentSummaryMultiView(hmm, hmd.key, viewitem)}>

                                                                                                                </div>
                                                                                                                :
                                                                                                                <React.Fragment></React.Fragment>}
                                                                                                            {
                                                                                                                (hmd.key !== 'Total Volume' && hmd.key !== 'Daily Agg.') ?
                                                                                                                    <span style={tdvalue} title={hmm.tooltip}>
                                                                                                                        {
                                                                                                                            (hmm.value.percentage === '' || hmm.value.percentage === undefined || hmm.value.percentage === null || hmm.value.percentage === 'NaN')
                                                                                                                                ? <React.Fragment>
                                                                                                                                    <span style={{ textAlign: 'center' }}>--</span>
                                                                                                                                    &nbsp;&nbsp;</React.Fragment>
                                                                                                                                : <React.Fragment>{hmm.value.percentage}</React.Fragment>
                                                                                                                        }
                                                                                                                    </span>
                                                                                                                    : <span style={tdvalue}>
                                                                                                                        {
                                                                                                                            (hmm.value.percentage === '' || hmm.value.percentage === undefined || hmm.value.percentage === null || hmm.value.percentage === 'NaN')
                                                                                                                                ? <React.Fragment>
                                                                                                                                    <span style={{ textAlign: 'center' }}>--</span>
                                                                                                                                </React.Fragment>
                                                                                                                                : <React.Fragment><span>{hmm.value.percentage}</span>
                                                                                                                                </React.Fragment>
                                                                                                                        }
                                                                                                                    </span>

                                                                                                            }

                                                                                                        </div>

                                                                                                    </td>

                                                                                                )}

                                                                                        </tr> : <React.Fragment></React.Fragment>
                                                                                    }
                                                                                </React.Fragment>
                                                                            ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg='3'>
                                                    <Row style={{ margin: '5px 0px 5px 5px', fontSize: 'small' }}>
                                                        <React.Fragment>
                                                            {
                                                                (viewitem.mvcommentPresent === true)
                                                                    ?
                                                                    <Col lg='4'>
                                                                        <div style={{ position: 'absolute', borderLeft: '15px solid transparent', borderTop: '15px solid #f00' }}></div>
                                                                        <span style={{ marginLeft: '20px' }}>Daily comments</span>
                                                                    </Col>
                                                                    :
                                                                    <React.Fragment></React.Fragment>
                                                            }
                                                        </React.Fragment>
                                                    </Row>
                                                    <Row>
                                                        {
                                                            (isArrayWithLength(mvComments))
                                                                ?
                                                                <Col lg='12'>
                                                                    {mvComments
                                                                        .filter((ele) => {
                                                                            if (parseInt(ele.viewId) === parseInt(viewitem.viewId)) {
                                                                                return true
                                                                            }
                                                                        })
                                                                        .map((item: IComments, index) => (
                                                                            <React.Fragment key={index}>
                                                                                {
                                                                                    <li style={{ listStyle: 'none' }} key={index}>{index + 1}. {item.comment} - {item.keyDate}
                                                                                        {(item.incident && (item.incident != '0'))
                                                                                            ?
                                                                                            <span>
                                                                                                <a>ICM#</a>
                                                                                                <a href={'https://portal.microsofticm.com/imp/v3/incidents/details/' + item.incident + '/home'} target="_blank"> {item.incident}</a>
                                                                                            </span>
                                                                                            :
                                                                                            <span></span>
                                                                                        }
                                                                                    </li>
                                                                                }
                                                                            </React.Fragment>
                                                                        ))}
                                                                    <br />
                                                                </Col>
                                                                :
                                                                <React.Fragment></React.Fragment>
                                                        }
                                                    </Row>

                                                    <React.Fragment>
                                                        <Row>
                                                            <Col lg='12'>
                                                                {
                                                                    (isArrayWithLength(mvcolorScheme))
                                                                        ?
                                                                        <div>
                                                                            {mvcolorScheme
                                                                                .filter((ele) => {
                                                                                    if (parseInt(ele.viewId) === parseInt(viewitem.viewId)) {
                                                                                        return true
                                                                                    }
                                                                                })
                                                                                .map((clr: IColorScheme, index) => (
                                                                                    <React.Fragment key={index}>
                                                                                        {
                                                                                            <Row>
                                                                                                <Col lg='2' key={index}><div style={{ paddingBottom: '5px' }}><div className='DailyColrLogic' style={JSON.parse(clr.colorType)}>&nbsp;</div></div></Col>
                                                                                                <Col lg='10'>{clr.description}</Col>
                                                                                            </Row>
                                                                                        }
                                                                                    </React.Fragment>


                                                                                ))}
                                                                            <br />
                                                                        </div>
                                                                        : <React.Fragment></React.Fragment>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg='12'>
                                                                {
                                                                    (viewitem !== undefined && viewitem !== null) ?
                                                                        <div key={viewindex}>
                                                                            <Row>
                                                                                <Col lg='12'>  Display no. of Decimals :&nbsp;{viewitem.noOfDecimalText}</Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col lg='12'>   Coloring logic :&nbsp;{viewitem.colorLogicText} </Col>
                                                                            </Row>
                                                                        </div>
                                                                        :
                                                                        <div></div>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                        : <React.Fragment>
                                            <div style={{ color: 'Red', fontWeight: 'bold' }}> <br />
                                                <React.Fragment>No data available for the view &nbsp;{viewitem.viewName}</React.Fragment>
                                            </div>
                                        </React.Fragment>}
                                    <br />
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                        : <React.Fragment>
                            {(multipleviewSelectedOption === null) ? <div style={{ color: 'Red', fontWeight: 'bold', textAlign: 'center' }}> <br />
                                <React.Fragment>Please select the view to load the Data !!</React.Fragment>
                            </div> : (mvIsdataLoaded === true) ? <div style={{ color: 'Red', fontWeight: 'bold', textAlign: 'center' }}> <br />
                                <React.Fragment>No data available for the selected service</React.Fragment>
                            </div> : (mvDailyDataFailure === true) ? <div style={{ color: 'Red', fontWeight: 'bold', textAlign: 'center' }}> <br /> Error while loading Data !!</div>
                                : <React.Fragment></React.Fragment>
                            }
                        </React.Fragment>
                }
            </React.Fragment>

        </div>
    );
});

MultipleHeatMapViewData.displayName = 'MultipleHeatMapViewData';
export default MultipleHeatMapViewData;