import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IPIRLearningsState } from './types';

const initialState = Object.freeze<IPIRLearningsState>({
    pirLearningsData: [],
    allPIRDataFiltered: [],
    isLoading: false,
    flag: false,
    displayText: "Expand All",
    PIRLearningsDate: null,
    selectedItemForLabel: null,
    selectedItemForRootLabel: null,
    OwningService: [],
    RouteCauseCategory: [],
    pirtextLength: "",
    searchText: ""
});

export const reducer = (
    state: IPIRLearningsState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {
        case ActionType.SET_PIRLEARNINGS_DATE:
            return {
                ...state,
                PIRLearningsDate: action.PIRLearningsDate

            };
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true

            };
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false

            };
        case ActionType.PIRLEARNINGS_DATE_CHANGE:
            return {
                ...state,
                PIRLearningsDate: action.PIRLearningsDate

            };
        case ActionType.GET_PIRLEARNINGS_REQUEST:
            return {
                ...state,
                isLoading: true,
                pirLearningsData: [],
                allPIRDataFiltered: [],
                selectedItemForRootLabel: null,
                selectedItemForLabel: null,
                OwningService: [],
                RouteCauseCategory:[]
            };
        case ActionType.GET_PIRLEARNINGS_SUCCESS:  // === for PIR Learnings Data success ===============//
            return {
                ...state,
                pirLearningsData: action.pirLearningsData,
                selectedItemForLabel: action.selectedItemForLabel,
                OwningService: action.OwningService,
                RouteCauseCategory: action.RouteCauseCategory,
                selectedItemForRootLabel: action.selectedItemForRootLabel,
                allPIRDataFiltered: action.allPIRDataFiltered,
                isLoading: false
            };
        case ActionType.GET_PIRLEARNINGS_FAILURE:  // === for PIR Learnings Data failure ===============//
            return {
                ...state,
                pirLearningsData: [],
                isLoading: false
            };
        case ActionType.PIRLEARNINGS_SERVICE_FILTER:
            return {
                ...state,
                selectedItemForLabel: action.selectedItemForLabel,
                allPIRDataFiltered: action.allPIRDataFiltered
            };
        case ActionType.PIRLEARNINGS_ROOTCAUSE_FILTER:
            return {
                ...state,
                selectedItemForRootLabel: action.selectedItemForRootLabel,
                allPIRDataFiltered: action.allPIRDataFiltered
            };
        case ActionType.TOGGLE_PIR:
            return {
                ...state,
                allPIRDataFiltered: action.allPIRDataFiltered,
                displayText: action.displayText,
                flag: action.flag
            };
        case ActionType.CHANGE_SEARCHTEXT_PIR:
            return {
                ...state,
                allPIRDataFiltered: action.allPIRDataFiltered,
                displayText: action.displayText,
                searchText: action.searchText
            };
        case ActionType.PIRLEARNINGS_SHOWMORE_TEXT:
            return {
                ...state,
                allPIRDataFiltered: action.allPIRDataFiltered,
                flag: action.flag
            };
        case ActionType.RESET_STATE:
            return initialState;
        default:
            return state;
    }
};