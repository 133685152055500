import React, { useEffect } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import { actionCreators, reducer } from '../../store/PlatformTools';
import { PlatformToolTable } from '../Platformtools/child-components';
import LoadingOverlay from 'react-loading-overlay';
import { DeprecatedFunctionalityBanner } from '../../components';

type PlatformToolProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly history: History }
    & typeof useParams;

const PlatformTool: React.FC<PlatformToolProps> = ({
    isLoading,
    addUserVisit,
    isAuthenticated,
    GetPlatformPMToolsData,
    platformToolData,
    IsplatformToolDataloaded

}) => {
    useEffect(() => {
        if (isAuthenticated)
            addUserVisit(0, 'PlatformTool.tsx_v2');
    }, [isAuthenticated]);
    useEffect(() => { 
        GetPlatformPMToolsData();
    }, [GetPlatformPMToolsData]);
    return (
        <div>
            <DeprecatedFunctionalityBanner />
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                <PlatformToolTable
                    platformToolData={platformToolData}
                    IsplatformToolDataloaded={IsplatformToolDataloaded}
                />
                    </div>
                </section>
            </LoadingOverlay>

        </div>
    );
};
const mapStateToProps = (state: IApplicationState) => ({
    ...state.PlatformTools,
    isAuthenticated: state.authUser.isAuthenticated

})

export default connect(mapStateToProps, actionCreators)(PlatformTool);
