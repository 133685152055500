import { IMonthlyReport } from '../service-health/types';
export interface IActionType {
    readonly RESET_STATE: string;
    readonly ADDED_VISITED_USER: string;
    readonly MONTHLY_START_DATE_CHANGE: string;
    readonly MONTHLYDATA_QOS_FETCH: string;
    readonly MONTHLYDATA_QOS_RECEIVE: string;
}
export type IMonthlyAvailbilityQOSState = {
    readonly isLoading: boolean;
    monthlyStartDate: Date;
    readonly monthlyQOSData: IMonthlyReport[];  
    IsMonthlyDataLoaded: boolean;  
    readonly uniquev: [];
    readonly uniquec: [];
    readonly liKPIValueKeys: [];
    
};

const _namespace = 'MonthlyAvailbilityQOS ';

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/reset`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    MONTHLY_START_DATE_CHANGE: `${_namespace}/monthlystartDateChange`,
    MONTHLYDATA_QOS_RECEIVE: `${_namespace}/monthlydataqosreceive`,
    MONTHLYDATA_QOS_FETCH: `${_namespace}/monthlydataqosfetech`,

});

