import { actionCreators } from './action';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IDCMappingState, IDataCenter } from './types';

const initialState = Object.freeze<IDCMappingState>({
    isLoading: false,
    datacenters: [],
    dataCenterNameOptions: [],
    azureRegionNameOptions: [],
    locationOptions: [],
    countryOptions: [],
    currentRow: {
        id: 0,
        dataCenterName: '',
        azureRegionName: '',
        location: '',
        country: '',
        referenceInfo: '',
        regionID: 0,
        regionOid: '',
        regionCode: '',
        regionLifeCycle: '',
        regionType: '',
        geographyId: 0,
        updatedby: ''
    }
});

export const reducer = (
    state: IDCMappingState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {
        case ActionType.RESET_STATE:
            return initialState;
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false
            };
        case ActionType.GET_DATACENTERS:
            return {
                ...state,
                datacenters: action.datacenters,
                dataCenterNameOptions: action.dataCenterNameOptions,
                azureRegionNameOptions: action.azureRegionNameOptions,
                locationOptions: action.locationOptions,
                countryOptions: action.countryOptions,
                isLoading: false
            };
        case ActionType.UPDATE_CURRENTRECORD:
            return {
                ...state,
                currentRow: action.currentRow,
                isLoading: false
            };
        default:
            return state;
    }
}