import { IAppThunkAction, ReduxAction } from '../';
import { ActionType, IdentityWeeklyNewsLetterOKRState, IPropIdentityObjectives, IPropIdentityKeyResults, OKRObjectiveDropdownOption } from './types';
import { CommonAPI, IdentityWeeklyNewsLetterAPI } from '../../api';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../utils';
import { toast } from "react-toastify";
import moment from 'moment';

export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    setloadingtrue: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
    },
    setloadingfalse: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_FALSE
        });
    },
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }
    },
    requestObjectivesAndKeyResultsData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        // Dispatch request
        dispatch({
            type: ActionType.GET_IDENTITYNEWSLETTERDIVSIONALOKRS_REQUEST
        });
            //==================== to get data for Newsletter Objectives Data =================//
        IdentityWeeklyNewsLetterAPI.GetIdentityNewsletterOKRsObjectivesData()
            .then((newsLetterWeeklyObjectivesDataRaw: IPropIdentityObjectives[]) => {
                let newsLetterDivisionalObjectivesData = [];
                let newsLetterOKRsObjectives = [];
                let selectedObjective = 0;
                newsLetterOKRsObjectives.push({ 'name': 'Select Objective / Add New', 'value': 0 });
                if (isArrayWithLength(newsLetterWeeklyObjectivesDataRaw)) {
                    newsLetterWeeklyObjectivesDataRaw.map((obj: IPropIdentityObjectives) => {
                        newsLetterDivisionalObjectivesData.push(obj);
                        newsLetterOKRsObjectives.push({ name: obj.name, value: obj.objectiveID });
                    });
                    newsLetterOKRsObjectives.push({ name: 'Add New Objective', value: 'New' });
                    //==================== to get data for Newsletter OKRs Data =================//
                    IdentityWeeklyNewsLetterAPI.GetIdentityNewsletterOKRsKeyResultsData()
                    .then((newsLetterWeeklyOKRsDataRaw: IPropIdentityKeyResults[]) => {
                        let newsLetterDivisionalKeyResultsData = [];
                        if (isArrayWithLength(newsLetterWeeklyOKRsDataRaw)) {
                            newsLetterWeeklyOKRsDataRaw.map((item: IPropIdentityKeyResults) => {
                                newsLetterDivisionalKeyResultsData.push(item);
                            })
                            dispatch({
                                newsLetterDivisionalObjectivesData,
                                newsLetterOKRsObjectives,
                                newsLetterDivisionalKeyResultsData,
                                selectedObjective,
                                type: ActionType.GET_IDENTITYNEWSLETTERDIVSIONALOKRS_SUCCESS
                            });
                        }
                    }).catch(error => {
                        dispatch({
                            type: ActionType.GET_IDENTITYNEWSLETTERDIVSIONALOKRS_FAILURE
                        });
                    });
                    }
                }).catch(error => {
                    dispatch({
                        type: ActionType.GET_IDENTITYNEWSLETTERDIVSIONALOKRS_FAILURE
                    });
                });
    },
    refreshObjectivesDataAfterSave: (selectedObjective): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        // Dispatch request
        dispatch({
            type: ActionType.GET_IDENTITYNEWSLETTEROBJECTIVES_REQUEST
        });
        //==================== to get data for Newsletter Objectives Data =================//
        IdentityWeeklyNewsLetterAPI.GetIdentityNewsletterOKRsObjectivesData()
            .then((newsLetterWeeklyObjectivesDataRaw: IPropIdentityObjectives[]) => {
                let newsLetterDivisionalObjectivesData = [];
                let newsLetterOKRsObjectives = [];
                newsLetterOKRsObjectives.push({ 'name': 'Select Objective / Add New', 'value': 0 });
                if (isArrayWithLength(newsLetterWeeklyObjectivesDataRaw)) {
                    newsLetterWeeklyObjectivesDataRaw.map((obj: IPropIdentityObjectives) => {
                        newsLetterDivisionalObjectivesData.push(obj);
                        newsLetterOKRsObjectives.push({ name: obj.name, value: obj.objectiveID });
                    });
                    newsLetterOKRsObjectives.push({ name: 'Add New Objective', value: 'New' });
                    dispatch({
                        newsLetterDivisionalObjectivesData,
                        newsLetterOKRsObjectives,
                        selectedObjective,
                        isAddNewObjective: false,
                        type: ActionType.GET_IDENTITYNEWSLETTEROBJECTIVES_SUCCESS
                    });
                }
            }).catch(error => {
                dispatch({
                    type: ActionType.GET_IDENTITYNEWSLETTEROBJECTIVES_FAILURE
                });
            });
    },
    getkeyResultsData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let selectedObjective = getState().DivisionalOKRs.selectedObjective;
        let WeekStartDate = getState().DivisionalOKRs.WeekStartDate;
        // Dispatch request
        dispatch({
            type: ActionType.GET_IDENTITYNEWSLETTERKEYRESULTS_REQUEST
        });
        //==================== to get data for Newsletter OKRs Data =================//
        IdentityWeeklyNewsLetterAPI.GetIdentityNewsletterOKRsKeyResultsData()
            .then((newsLetterWeeklyOKRsDataRaw: IPropIdentityKeyResults[]) => {
                let newsLetterDivisionalKeyResultsData = [];
                if (isArrayWithLength(newsLetterWeeklyOKRsDataRaw)) {
                    newsLetterWeeklyOKRsDataRaw.map((item: IPropIdentityKeyResults) => {
                        newsLetterDivisionalKeyResultsData.push(item);
                    });
                    let selectedKeyResults = newsLetterDivisionalKeyResultsData.filter(item => ((item.objectiveID === selectedObjective) && (+(new Date(item.weekDate)) === +(new Date(WeekStartDate)))));
                    dispatch({
                        newsLetterDivisionalKeyResultsData,
                        selectedKeyResults,
                        type: ActionType.GET_IDENTITYNEWSLETTERKEYRESULTS_SUCCESS
                    });
                }
            }).catch(error => {
                dispatch({
                    type: ActionType.GET_IDENTITYNEWSLETTERKEYRESULTS_FAILURE
                });
            });
    },
    handleOKRObjectiveChange: (selectedObjective): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let newsLetterDivisionalKeyResultsData = getState().DivisionalOKRs.newsLetterDivisionalKeyResultsData;
        let newsLetterDivisionalObjectivesData = getState().DivisionalOKRs.newsLetterDivisionalObjectivesData;
        let ObjectiveStartDate = getState().DivisionalOKRs.ObjectiveStartDate;
        let ObjectiveEndDate = getState().DivisionalOKRs.ObjectiveEndDate;
        let WeekStartDate = getState().DivisionalOKRs.WeekStartDate;
        let isAddNewObjective = getState().DivisionalOKRs.isAddNewObjective;
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        if (selectedObjective === "New") {
            isAddNewObjective = true
        }
        else {
            isAddNewObjective = false;
        }
        let selectedobjectivefilter = newsLetterDivisionalObjectivesData.filter(item => item.objectiveID === selectedObjective);
        let selectedKeyResults = newsLetterDivisionalKeyResultsData.filter(item => ((item.objectiveID === selectedObjective) && (+(new Date(item.weekDate)) === +(new Date(WeekStartDate)))));
        
        if (!isNullOrUndefined(selectedobjectivefilter) && selectedobjectivefilter.length !== 0) {
            ObjectiveStartDate = new Date(selectedobjectivefilter[0].startDate);
            ObjectiveEndDate = new Date(selectedobjectivefilter[0].endDate);
        }
        else {
            ObjectiveStartDate = ObjectiveEndDate = null;
        }
        dispatch({
            selectedObjective,
            selectedKeyResults,
            ObjectiveStartDate,
            ObjectiveEndDate,
            isAddNewObjective,
            type: ActionType.IDENTITYNEWSLETTER_OKROBJECTIVE_CHANGE
        }); 
    },
    handleObjectiveStartDateChange: (ObjectiveStartDate): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            ObjectiveStartDate,
            type: ActionType.IDENTITYNEWSLETTER_OBJECTIVES_STARTDATE_CHANGE
        });
    },
    handleObjectiveEndDateChange: (ObjectiveEndDate): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            ObjectiveEndDate,
            type: ActionType.IDENTITYNEWSLETTER_OBJECTIVES_ENDDATE_CHANGE
        });
    },
    filterKeyResults: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let newsLetterDivisionalKeyResultsData = getState().DivisionalOKRs.newsLetterDivisionalKeyResultsData;
        let selectedObjective = getState().DivisionalOKRs.selectedObjective;
        let WeekStartDate = getState().DivisionalOKRs.WeekStartDate;
        let selectedKeyResults = newsLetterDivisionalKeyResultsData.filter(item => ((item.objectiveID === selectedObjective) && (+(new Date(item.weekDate)) === +(new Date(WeekStartDate)))));

        dispatch({
            selectedKeyResults: selectedKeyResults,
            type: ActionType.NEWSLETTER_KEYRESULTS_CONTROLS_CHANGE
        });
    },
    GetWeekStartDate: (WeekDate: Date): IAppThunkAction<ReduxAction> => (dispatch) => {
        let WeekStartDate;
        if (WeekDate === null || WeekDate === undefined) {
            WeekStartDate = new Date();
        }
        else {
            WeekStartDate = WeekDate;
        }
        const today = new Date(WeekStartDate);
        // If the Present Day is not Saturday fetch the very previous Saturday as the WeekStartDate for KeyResults Calendar Control
        if (!(today.getDay() === 6)) {
            WeekStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - (today.getDay() + 1));
        }
        dispatch({
            WeekStartDate: WeekStartDate,
            type: ActionType.IDENTITYWEEKLY_OKRS_START_DATE_CHANGE
        });
    },
    ObjectiveKeyResultValueChange: (e, id): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const selectedKeyResults = getState().DivisionalOKRs.selectedKeyResults;
        const keyResult = [];
        let { value, name } = e.target;
        if (value === undefined)
            value = '';
        selectedKeyResults.map((obj: IPropIdentityKeyResults) => {
            if (obj.keyResultID === id) {
                    obj[name] = value;
            }
            keyResult.push(obj);
        })
        dispatch({
            selectedKeyResults: keyResult,
            type: ActionType.NEWSLETTER_KEYRESULTS_CONTROLS_CHANGE
        });
    },
    EditObjectiveKeyResultRow: (val: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const selectedKeyResults = getState().DivisionalOKRs.selectedKeyResults;
        const keyResult = [];
        selectedKeyResults.map((obj: IPropIdentityKeyResults) => {
            if (obj.keyResultID === val) {
                obj.isEdit = true;
                obj.keyResultsEdit = obj.keyResults;
                obj.progressValueEdit = obj.progressValue;
                obj.targetValueEdit = obj.targetValue;
            }
            else {
                obj.isEdit = false;
            }
            if (obj.keyResultID > 0)
                keyResult.push(obj);
        })
        dispatch({
            selectedKeyResults: keyResult,
            type: ActionType.NEWSLETTER_KEYRESULTS_CONTROLS_CHANGE
        });
    },
    EnableAddNewforObjectiveKeyResult: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const selectedKeyResults = getState().DivisionalOKRs.selectedKeyResults;
        const selectedObjective = getState().DivisionalOKRs.selectedObjective;
        let WeekStartDate = getState().DivisionalOKRs.WeekStartDate;
        const keyResult = [];
        selectedKeyResults.map((obj: IPropIdentityKeyResults) => {
            if (obj.keyResultID > 0) {
                if (obj.isEdit = true)
                    obj.isEdit = false;
                keyResult.push(obj);
            }
        })
        let NewCOKR = {
            keyResultID: 0,
            keyResultsEdit: '',
            progressValueEdit: '',
            targetValueEdit: '',
            objectiveID: selectedObjective,
            weekDate: WeekStartDate,
            isActive: 1,
            isEdit: true
        }
        keyResult.push(NewCOKR);
        dispatch({
            selectedKeyResults: keyResult,
            type: ActionType.NEWSLETTER_KEYRESULTS_CONTROLS_CHANGE
        });
    },
    cancelEditObjectiveKeyResultRow: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const selectedKeyResults = getState().DivisionalOKRs.selectedKeyResults;
        const keyResult = [];
        selectedKeyResults.map((obj) => {
            obj.isEdit = false;
            obj.keyResultsEdit = obj.keyResults;
            obj.progressValueEdit = obj.progressValue;
            obj.targetValueEdit = obj.targetValue;
            if (obj.keyResultID > 0)
                keyResult.push(obj);
        })
        dispatch({
            selectedKeyResults: keyResult,
            type: ActionType.NEWSLETTER_KEYRESULTS_CONTROLS_CHANGE
        });
    },
    CancelAddNewObjectiveData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            isAddNewObjective: false,
            newObjective: '',
            ObjectiveStartDate: null,
            ObjectiveEndDate: null,
            selectedObjective: 0,
            type: ActionType.NEWSLETTER_OBJECTIVES_CANCEL_CHANGE
        });
    },
    addNewObjective: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const newObjective = event.target.value;
        dispatch({
            newObjective,
            type: ActionType.IDENTITYNEWSLETTER_OBJECTIVE_ADD
        });
    },
}