import React, { useState, Fragment, useRef } from 'react';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import {
    Card, Row, Col, Container, CardBody
} from 'reactstrap';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';

type UserScenarioCompletionTableProps = {
    scenarioCompletionData: any;
    maxDate: any;
    endDate: any;
    onendDateChange: any;
    userScenario: any;
    selectedScenario: any;
    handleUserScenarioChange: any;
    scenarioDescription: any;
    GetUserScenarioData: any;
    scenario: any;
    tableHeader: any;
    originalHeader: any;
};
const UserScenarioCompletionTable = React.memo<UserScenarioCompletionTableProps>(({
    scenarioCompletionData,
    maxDate,
    endDate,
    onendDateChange,
    userScenario,
    selectedScenario,
    handleUserScenarioChange,
    scenarioDescription,
    GetUserScenarioData,
    scenario,
    tableHeader,
    originalHeader
}) => {

    const JarvisUrlCompute = (index) => {
        var startTime = getStartTime(tableHeader[index]);
        var endTime = getEndTime(tableHeader[index]);
        var hr = "https://jarvis-west.dc.ad.msft.net/dashboard/share/A979311E?globalStartTime=" + startTime + "&globalEndTime=" + endTime + "&pinGlobalTimeRange=true";
        window.open(hr);
    }

    const JarvisUrlComputeCompletedRequests = (index, value) => {
        var startTime = getStartTime(tableHeader[index]);
        var endTime = getEndTime(tableHeader[index]);
        var hr = "https://jarvis-west.dc.ad.msft.net/dashboard/share/272BACC?globalStartTime=" + startTime + "&globalEndTime=" + endTime + "&pinGlobalTimeRange=true&overrides=[{%22query%22:%22//*[id=%27TenantId%27]%22,%22key%22:%22value%22,%22replacement%22:%22" + value + "%22}]%20";
        window.open(hr);
    }

    const JarvisUrlComputeInCompletedRequests = (index, value) => {
        var startTime = getStartTime(tableHeader[index]);
        var endTime = getEndTime(tableHeader[index]);
        var hr = "https://jarvis-west.dc.ad.msft.net/dashboard/share/272BACC?globalStartTime=" + startTime + "&globalEndTime=" + endTime + "&pinGlobalTimeRange=true&overrides=[{%22query%22:%22//*[id=%27TenantId%27]%22,%22key%22:%22value%22,%22replacement%22:%22" + value + "%22}]%20";
        window.open(hr);
    }

    const getStartTime = (date) => {
        return Date.parse(date);
    };

    const getEndTime = (date) => {
        var datenow = date + " 23:59:59";
        return Date.parse(datenow);
    };

    const formatText = (val, size) => {
        if (val !== undefined) {
            return val.toLocaleString(navigator.language, { maximumFractionDigits: size , minimumFractionDigits: size});
        }
    }
    return (
        <div>
            <Row>
                <Col lg="2"></Col>
                <Col lg="8">
                    <div className="divpaddingtopuser"></div>
                    <Card className="cardstyleuser">
                        <CardBody className="cardbodystyleuser">
                            <h3 className="headertextuser UserScenarioHeader">  User Scenario Completion (beta)</h3>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="2"></Col>
            </Row>
            <hr className="hrstyle" />
            <Row>
                <Col lg='2'>
                    <div className="p-field p-col-10 p-md-4">
                        <span className="p-float-label">
                            <Dropdown id={'userscenario'} style={{width: '200px'}} value={selectedScenario} options={userScenario} onChange={(e) => handleUserScenarioChange(e.value)} optionLabel="userScenarioName" />
                            <label htmlFor="userscenario" className="fontcolor">User Scenario</label>
                        </span>
                    </div>
                </Col>
                <Col lg='2'>
                    <div className="p-field p-col-10 p-md-4">
                        <span className="p-float-label">
                            <Calendar id={'endDate'} maxDate={maxDate} readOnlyInput showIcon value={endDate} onChange={(e) => onendDateChange(e.value)}></Calendar>
                            <label htmlFor="endDate" className="fontcolor">End Date</label>
                        </span>
                    </div>
                </Col>
                <Col lg='2'>
                    <div className="p-field p-col-10 p-md-4">
                        <Button label="Submit" icon="pi pi-filter" iconPos="left" onClick={GetUserScenarioData} />
                    </div>
                </Col>
                <Col lg="6"></Col>
            </Row>
            <div>
                <b>Scenario Description: </b> 
                {scenarioDescription}
            </div>
            <div>
                <Row className="tblpaddinguser">
                    <Col lg="12">
                        <table id="tblUserScenario" className="tblUserScenario tblborder">
                                {!isArrayWithLength(scenarioCompletionData)
                                    ?
                                <React.Fragment>
                                    <tbody>
                                        <tr>
                                            <td>
                                                No Data for selected User Scenario
                                            </td>
                                        </tr>
                                    </tbody>
                                    </React.Fragment>
                                :
                                <React.Fragment>
                                    <thead>
                                        <tr>
                                            <th><h3 >{scenario}</h3></th>
                                            {isArrayWithLength(tableHeader) && tableHeader.map((dh, key) => (
                                                <React.Fragment key={key}>
                                                    <th colSpan={3} className='tableheaderVal'>{dh}</th>
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                        <tr>
                                            <th><h6 style={{ textAlign: 'left', fontSize: '14px' }}>Average scenario completion rate and total requests</h6></th>
                                            {isArrayWithLength(tableHeader) && tableHeader.map((percent, index) => {
                                                return [
                                                    <React.Fragment key={index}>
                                                    <th className="rowstyle1">{formatText(originalHeader[0][0][percent + '~Completion Rate'], 1)}%</th>
                                                    <th className="rowstyle1">{formatText(originalHeader[0][0][percent + '~TotalRequests'], 0)}</th>
                                                    <th className="rowstyle2">
                                                        <span style={{ textAlign: 'left', fontSize: '11px' }} className="hreflink" onClick={() => JarvisUrlCompute(index)}>All Tenants</span>
                                                    </th>
                                                    </React.Fragment>
                                                ]
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan={23}><h6>Instrumented tenants with the largest count of completed requests</h6></td>
                                        </tr>
                                        {isArrayWithLength(scenarioCompletionData) && scenarioCompletionData.map((obj, index) => (
                                            <React.Fragment key={index}>
                                                    <tr>
                                                        {
                                                            (index <= 4) ? <td></td>
                                                                : <React.Fragment></React.Fragment>
                                                        }
                                                        {isArrayWithLength(tableHeader) && tableHeader.map((row, indexval) => {
                                                            return [
                                                                <React.Fragment key={indexval}>
                                                                    {
                                                                        (index <= 4) ?
                                                                            <React.Fragment>
                                                                                <td className="rowstyle1">{formatText(obj[row + '~Completion Rate'], 1)}%</td>
                                                                                <td className="rowstyle1">{formatText(obj[row + '~TotalRequests'], 0)}</td>
                                                                                <td className="rowstyle2">
                                                                                    <span style={{ textAlign: 'left', fontSize: '11px' }} className="hreflink" onClick={() => JarvisUrlComputeCompletedRequests(indexval, obj[row + '~Tenant Id'])}>{obj[row + '~Tenant Name']}</span>
                                                                                </td>
                                                                            </React.Fragment>
                                                                            : <React.Fragment></React.Fragment>
                                                                    }
                                                                </React.Fragment>
                                                            ]
                                                        })}
                                                    </tr>
                                            </React.Fragment>
                                        ))}
                                        <tr>
                                            <td colSpan={23}><h6>Instrumented tenants with the largest count of incompleted requests</h6></td>
                                        </tr>
                                        {isArrayWithLength(scenarioCompletionData) && scenarioCompletionData.map((obj, index) => (
                                            <React.Fragment key={index}>
                                                {
                                                    <tr>
                                                        {
                                                            (index > 4) ? <td></td>
                                                                : <React.Fragment></React.Fragment>
                                                        }
                                                        {isArrayWithLength(tableHeader) && tableHeader.map((row, indexval) => {
                                                            return [
                                                                <React.Fragment key={indexval}>
                                                                    {
                                                                        (index > 4) ?
                                                                            <React.Fragment>
                                                                                <td className="rowstyle1">{formatText(obj[row + '~Completion Rate'], 1)}%</td>
                                                                                <td className="rowstyle1">{formatText(obj[row + '~TotalRequests'], 0)}</td>
                                                                                <td className="rowstyle2">
                                                                                    <span style={{ textAlign: 'left', fontSize: '11px' }} className="hreflink" onClick={() => JarvisUrlComputeInCompletedRequests(indexval, obj[row + '~Tenant Id'])}>{obj[row + '~Tenant Name']}</span>
                                                                                </td>
                                                                            </React.Fragment>
                                                                            : <React.Fragment></React.Fragment>
                                                                    }
                                                                </React.Fragment>
                                                            ]
                                                        })}
                                                    </tr>
                                                }
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </React.Fragment>
                                }
                        </table>
                    </Col>
                </Row>
            </div>
        </div>
    )
});

UserScenarioCompletionTable.displayName = 'UserScenarioCompletionTable';
export default UserScenarioCompletionTable;