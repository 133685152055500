import React, { useState, Fragment } from 'react';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import { IPropSupportabilityWishr } from '../../../store/Weekly-ID-SHR/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faTrashAlt, faPencilAlt, faEdit, faTrash, faTruckLoading, faArrowAltCircleLeft, faPlus, faMinus, faSave, faBan, faCalendar } from '@fortawesome/free-solid-svg-icons';
import DatePicker, { getDay } from 'react-datepicker';
import moment from 'moment';

import { Row, Col, Button } from 'reactstrap';
type SupportabilityProps = {
    SupportabilityData: IPropSupportabilityWishr[],
    isSupportabilityEditorAddEnabled: boolean,
    EditSupportabilityRows: any,
    DeleteSupportabilityRow: any,
    cancelEditSupportabilityRows: any,
    Supportabilityvalueschange: any,
    handleKeypress: any,
    SaveSupportabilityData: any,
    EnableAddNewforSupportabilityRow: any,
    setshowaddrecurrencesprt: any,
    deleterecurrencesupportability: any,
    DefaultDate: any,
    MeetingDate: any,
    SupportabilityDate: any,
    MaxDate: any,
    isAdmin: any,
    handleSupportabilityDateChange: any
};

const Supportability = React.memo<SupportabilityProps>(({
    SupportabilityData,
    EditSupportabilityRows,
    cancelEditSupportabilityRows,
    Supportabilityvalueschange,
    isSupportabilityEditorAddEnabled,
    DeleteSupportabilityRow,
    handleKeypress,
    SaveSupportabilityData,
    EnableAddNewforSupportabilityRow,
    setshowaddrecurrencesprt,
    deleterecurrencesupportability,
    DefaultDate,
    MeetingDate,
    SupportabilityDate,
    MaxDate,
    handleSupportabilityDateChange,
    isAdmin
}) => {

    const isThursday = (date) => {
        var day = new Date(moment(date).format('MM/DD/YYYY')).getDay();
        return day == 4;
    };

    return (
        <React.Fragment>
            <br />
            <Row>
                <Col lg="10"><h5 className="headertext">Supportability (15 minutes)</h5></Col>
                <Col lg="2">
                    <div className="divaddnew">
                        <Button className='PIRAddbuttons' onClick={EnableAddNewforSupportabilityRow}> <FontAwesomeIcon icon={faPlus} /> &nbsp;Add New Supportability</Button>
                    </div>
                </Col>
            </Row>
            <Row className="tblpadding">
                <Col lg="12">
                    <table id="tblPIR" className="tblPIR tblborder">
                        <thead>
                            <tr>
                                <th className="thwidth280">{(isSupportabilityEditorAddEnabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Presenter</th>
                                <th className="thwidth200">{(isSupportabilityEditorAddEnabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Topic Name</th>
                                <th className="thwidth300">{(isSupportabilityEditorAddEnabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Link to presentation</th>
                                {(isAdmin === true) ?
                                    <th className="thwidth120">{(isSupportabilityEditorAddEnabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Order</th>
                                    : <React.Fragment></React.Fragment>
                                }
                                <th className="thwidth200">Actions</th>
                                <th className="thwidth200">Recurrence</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isArrayWithLength(SupportabilityData)
                                ?
                                <React.Fragment>
                                    <tr>
                                        <td colSpan={6} className="clsnodatamessgefortable">
                                            No Data Available
                                        </td>
                                    </tr>
                                </React.Fragment>
                                : SupportabilityData.map((row: IPropSupportabilityWishr, index) => (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td>
                                                <div>{(row.isEdit) ? <textarea className="form-control" onChange={e => Supportabilityvalueschange(e, row)} value={row.presenterEdit} name="presenterEdit" rows={1} cols={50} /> : row.presenter}</div>
                                            </td>
                                            <td>
                                                <div>{(row.isEdit) ? <textarea className="form-control" onChange={e => Supportabilityvalueschange(e, row)} value={row.topicNameEdit} name="topicNameEdit" rows={1} cols={50} /> : row.topicName}</div>
                                            </td>
                                            <td className="tdwordbreak">
                                                <div>{(row.isEdit) ? <textarea className="form-control" onChange={e => Supportabilityvalueschange(e, row)} value={row.presentationLinkEdit} name="presentationLinkEdit" rows={1} cols={50} /> : <a title="All links need to be accessible for all Identity viewers" href={row.presentationLink} target="_blank">{row.presentationLink}</a>}</div>

                                            </td>
                                            {
                                             (isAdmin === true) ?
                                            <td>
                                                <div>{(row.isEdit) ? <input type="number" min="0" max="32000" className="form-control" value={row.displayOrderEdit} name="displayOrderEdit" onKeyDown={handleKeypress} onChange={e => Supportabilityvalueschange(e, row)} /> :
                                                    <div>{row.displayOrder}</div>
                                                }</div>
                                                    </td>
                                                : <React.Fragment></React.Fragment>
                                            }
                                            <td className="centericons">
                                                {
                                                    (!row.isEdit)
                                                        ?
                                                        <React.Fragment>
                                                            <span onClick={e => EditSupportabilityRows(e, row)}>
                                                                <FontAwesomeIcon title='Click to edit the Row' className="bluecoloricons Iconsstyles" icon={faPencilAlt} /></span> &nbsp;
                                                     <FontAwesomeIcon title='Click to Delete the Row' className="redcolorIcons Iconsstyles" icon={faTrash} onClick={e => DeleteSupportabilityRow(e, row)} /> &nbsp;
                                                    </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            <FontAwesomeIcon title='Save Changes' className="bluecoloricons Iconsstyles" onClick={e => SaveSupportabilityData(e, row)} icon={faSave} /> &nbsp;
                                                            <FontAwesomeIcon title='Cancel Edit' className="Iconsstyles" icon={faBan} onClick={e => cancelEditSupportabilityRows(e, row)} />
                                                        </React.Fragment>
                                                }
                                            </td>
                                            <td className="centericons">
                                                {
                                                    (row.isEdit)
                                                        ? <React.Fragment></React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            <button title='Click to Add Recurrence' className="recbutton" onClick={e => setshowaddrecurrencesprt(e, row)} disabled={DefaultDate != MeetingDate}> <FontAwesomeIcon className={(DefaultDate != MeetingDate) ? 'greyicins Iconsstyles' : 'bluecoloricons Iconsstyles'} icon={faPlus} /></button> &nbsp;
                                                       <button title='Click to Delete Recurrence' onClick={e => deleterecurrencesupportability(e, row)} className="recbutton" style={{ cursor: 'Pointer' }} disabled={DefaultDate != MeetingDate}> <FontAwesomeIcon className={(DefaultDate != MeetingDate) ? 'greyicins Iconsstyles' : 'redcolorIcons Iconsstyles'} icon={faMinus} />  </button>
                                                        </React.Fragment>
                                                }
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                        </tbody>
                    </table>
                </Col>
            </Row>

        </React.Fragment>
    );
});

Supportability.displayName = 'Supportability';

export default Supportability;