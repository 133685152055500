import React, { useState, Fragment, useRef } from 'react';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import { IPropMsodsGridChart, IPropReachabilityError } from '../../../store/ReachabilityMetrics';
import DatePicker, { getDay } from 'react-datepicker';
import classnames from 'classnames';
import { FontAwesomeIconMemo, Checkbox } from '../../../components';
import { ReduxAction, IAppThunkAction } from '../../../store';
import hinew from '../../../assets/images/hinew.png';
import cancel from '../../../assets/images/cancel.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ToastId, toast } from 'react-toastify';
import moment from 'moment';
import {
    TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Card, CardBody, Button
} from 'reactstrap';
type ReachabilityMetricsTableProps = {
    onselectedDateChange: any;
    reachabilityData: any;
    FilterreachabilityData: any;
    reachabilityErrorData: any;
    selectedDate: any;
    searchText: any;
    UpdateSearchText: any;
    filterReachabilityData_Red: any;
    checkedFilterRedDailyData: any;
    onCheckHistory: any;
    checkHistory: any;
    Errorcolumn: any;
    sortColumnError: any;
    sortOrder: any;
    GetDateForwardData: any;
    GetDateBackwardData: any;
    IsEdit: any;
    disableSave: any;
    AddToHistory: any;
    envactive: any;
    sortReachabilityColumn: any;
    Reachabilitycolumn: any;
    reachabilitysortOrder: any;
    ShowHistory1: any;
    filterweekdata: any;
    weekfilter: any;
    options: any;
    enableEditData: any;
    CancelEdit: any;
    weekDetailsData: any;
    ShowHistory: any;
    inputValueChanged: any;
    updateComments: any;
    showHistorypopup: any;
    readonly checkedRetainPage: boolean;
    //readonly onRetainPageCheck: (checkedRetainPage: boolean) => IAppThunkAction<ReduxAction>;
    readonly onRetainPageCheck: any;
};
const ReachabilityMetricsTable = React.memo<ReachabilityMetricsTableProps>(({
    onselectedDateChange,
    reachabilityData,
    FilterreachabilityData,
    reachabilityErrorData,
    selectedDate,
    searchText,
    UpdateSearchText,
    filterReachabilityData_Red,
    checkedFilterRedDailyData,
    onCheckHistory,
    checkHistory,
    Errorcolumn,
    sortColumnError,
    sortOrder,
    sortReachabilityColumn,
    Reachabilitycolumn,
    reachabilitysortOrder,
    GetDateForwardData,
    GetDateBackwardData,
    IsEdit,
    disableSave,
    AddToHistory,
    envactive,
    ShowHistory1,
    filterweekdata,
    weekfilter,
    options,
    enableEditData,
    CancelEdit,
    weekDetailsData,
    ShowHistory,
    inputValueChanged,
    updateComments,
    showHistorypopup,
    checkedRetainPage,
    onRetainPageCheck,
}) => {
    
    const toastIdRef = useRef<ToastId>('');
    const [activeTab, setActiveTab] = useState('Public');

    var Perfenvironment = '';
    const setPerfenvironment = (name) => {
        Perfenvironment = name;
    }

    let d = new Date();

    // Logic to enable last to last week saturday
    const MaxDate = d.setDate(d.getDate() - (d.getDay() + 8));

    const toggle = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        };
    };

    const filterBasedSearch = (item) => {
        var serviceName = (item.serviceName === undefined || item.serviceName === null) ? "" : item.serviceName;
        var environment = (item.environment === undefined || item.environment === null) ? "" : item.environment;
        var targetName = (item.targetName === undefined || item.targetName === null) ? "" : item.targetName;
        var sslName = (item.sslName === undefined || item.sslName === null) ? "" : item.sslName;
        var week1 = (item.week1 === undefined || item.week1 === null) ? "" : item.week1;
        var week2 = (item.week2 === undefined || item.week2 === null) ? "" : item.week2;
        var week3 = (item.week3 === undefined || item.week3 === null) ? "" : item.week3;
        var week4 = (item.week4 === undefined || item.week4 === null) ? "" : item.week4;
        var week5 = (item.week5 === undefined || item.week5 === null) ? "" : item.week5;
        var week2 = (item.week2 === undefined || item.week2 === null) ? "" : item.week2;
        if (serviceName.toLowerCase().includes(searchText.toLowerCase()) || environment.toLowerCase().includes(searchText.toLowerCase()) || targetName.includes(searchText) || sslName.toLowerCase().includes(searchText.toLowerCase()) ||
            week1.includes(searchText) || week2.includes(searchText) || week3.includes(searchText) || week4.includes(searchText) || week5.includes(searchText)) {
            return true;  
        }
        else {
            return false;
        }
    };

    const filterBasedSearchError = (item) => {
        var serviceName = (item.serviceName === undefined || item.serviceName === null) ? "" : item.serviceName;
        var environment = (item.environment === undefined || item.environment === null) ? "" : item.environment;
        var target = (item.target === undefined || item.target === null) ? "" : item.target;
        var totalErrors = (item.totalErrors === undefined || item.totalErrors === null) ? "" : item.totalErrors;
        var dnsError = (item.dnsError === undefined || item.dnsError === null) ? "" : item.dnsError;
        var connectError = (item.connectError === undefined || item.connectError === null) ? "" : item.connectError;
        var sslError = (item.sslError === undefined || item.sslError === null) ? "" : item.sslError;
        var sendError = (item.sendError === undefined || item.sendError === null) ? "" : item.sendError;
        var receiveError = (item.receiveError === undefined || item.receiveError === null) ? "" : item.receiveError;
        var httpError = (item.httpError === undefined || item.httpError === null) ? "" : item.httpError;
        var contentError = (item.contentError === undefined || item.contentError === null) ? "" : item.contentError;
        if (serviceName.toLowerCase().includes(searchText.toLowerCase()) || environment.toLowerCase().includes(searchText.toLowerCase()) || target.includes(searchText) ||
            totalErrors.toString().includes(searchText) || dnsError.toString().includes(searchText) || connectError.toString().includes(searchText) ||
            sslError.toString().includes(searchText) || sendError.toString().includes(searchText) || receiveError.toString().includes(searchText) ||
            httpError.toString().includes(searchText) || contentError.toString().includes(searchText)) {
                return true;
        }
        else {
            return false;
        }
    };

    //Copy URL to clipboard; 
    const copyToClipBoard = (text) => {
        if (text !== '') {
            var textField = document.createElement('textarea');
            textField.innerText = text;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
            if (!toast.isActive(toastIdRef.current)) {
                toastIdRef.current = toast.success(renderToastifyMsg('Copied to Clipboard',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
            }
        }
    };

    const AddColorForReachability = (target, value) => {
        var bckgndcolor = '';
        if (target && value) {
            if (parseFloat(target) > parseFloat(value)) {
                bckgndcolor = "#ffbbbb";
            } else {
                bckgndcolor = "#8df295";
            }
        } else if (target) {
            bckgndcolor = "lightgray";
        }
        return bckgndcolor;
    };

    const isWeekday = (date) => {
        var day = new Date(moment(date).format('MM/DD/YYYY')).getDay();
        return day == 6;
    };

    return (
        <div>
            <div>
            <table style={{ width: '100%' }}>
                <tr>
                    <td style={{ textAlign: 'left', width: '90%' }}>
                        <div id="marquee">
                                <div id="text">Weekly Reachability metrics page will be deprecated soon. Please check the Retain page checkbox if you need the Weekly metrics to be continued.</div>
                        </div>
                    </td>
                        <td className= "RetainCheckbox">
                            <Checkbox
                                label="Retain Page"
                                checked={checkedRetainPage}
                                onCheck={onRetainPageCheck}
                            />
                    </td>
                </tr>
                </table>
                </div>
            <React.Fragment>
                <br />
                <div>
                    <Card className="cardstyle">
                        <CardBody className="cardbodystyle">
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: 'left', paddingLeft: '5vw', width: '35vw' }}>
                                            <h3 className="headertext Reachabilityheader"> Reachability Metrics</h3>
                                        </td>
                                        <td style={{width: '25vw', paddingRight: '1vw'}}>
                                            <input type="text" placeholder="Search" className="form-control" name="Val2" value={searchText} onChange={e => UpdateSearchText(e)} />
                                        </td>
                                        <td style={{ width: '7vw', paddingLeft: '0px', paddingTop: '6px' }}>
                                            <Button style={{ width: '120px' }} title="Click to Filter Metrics that are Red for the week" className='btn btn-primary btn-sm headerbutton Reachabilitybutton' onClick={() => filterReachabilityData_Red()}>
                                                <FontAwesomeIcon icon={'times-circle'} style={{ color: 'lightpink', fontSize: '20px' }} />&nbsp;
                                                    &nbsp;<span style={{ verticalAlign: 'super', fontWeight: 'bold' }}>Red</span>
                                            </Button>
                                        </td>
                                        <td style={{ width: '8vw' }}>
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div id="DateSelectorDiv" className="btn btn-sm Reachabilitybutton filter-button">
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ width: '10%' }}><FontAwesomeIconMemo title="Move to Previous week" icon={'angle-left'} className="leftrighticonsReachability" onClick={() => GetDateBackwardData()} />&ensp;&ensp;</td>
                                                                            <td style={{ width: '15%' }}>
                                                                                <div>
                                                                                    <FontAwesomeIcon icon={'calendar'} style={{ color: 'lightpink', fontSize: '20px', verticalAlign: 'middle' }} />&ensp;
                                                                                </div>
                                                                            </td>
                                                                            <td style={{ width: '30%' }}><span style={{ width: '80px', fontWeight: 'bold', verticalAlign: 'middle' }}>Date&nbsp;:</span>&nbsp;</td>
                                                                            <td style={{ width: '35%' }}>
                                                                                <DatePicker className='InputStyle'
                                                                                    selected={selectedDate}
                                                                                    onChange={onselectedDateChange}
                                                                                    filterDate={isWeekday}
                                                                                    maxDate={MaxDate}
                                                                                />&ensp;&ensp;
                                                                                </td>
                                                                            <td style={{ width: '10%' }}> <FontAwesomeIconMemo title="Move to Next week" icon={'angle-right'} className="leftrighticonsReachability" onClick={() => GetDateForwardData()} /></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td style={{ width: '9vw', paddingTop: '6px' }}>
                                            <Button title="To View History" style={{ width: '120px' }} className='btn btn-primary btn-sm headerbutton Reachabilitybutton' onClick={() => onCheckHistory()}>
                                                <FontAwesomeIconMemo title="Show History" icon={'history'} style={{ color: 'lightpink', fontSize: '20px' }} />&nbsp;
                                                &nbsp;<span style={{ width: '120px', verticalAlign: 'super', fontWeight: 'bold' }}>History</span>
                                            </Button>
                                        </td>
                                        {
                                            (IsEdit !== "") ?
                                                <React.Fragment>
                                                    <td style={{ width: '15vw', paddingLeft: '0px', paddingTop: '5px' }}>
                                                        <Button title="Save all the changes to Database" className='btn btn-primary btn-sm headerbutton Reachabilitybutton' onClick={() => AddToHistory(-1)} disabled={disableSave}>
                                                            <FontAwesomeIconMemo icon={'save'} className="fa-2x" style={{ color: '#4CAF50' }} />&nbsp;
                                                            &nbsp;<span style={{ width: '120px', verticalAlign: 'super', fontWeight: 'bold' }}>SaveAll Changes</span>
                                                        </Button>
                                                    </td>
                                                </React.Fragment>
                                                : <React.Fragment></React.Fragment>
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                    <hr style={{ color: '#99CC99', margin: '2px' }} />
                    {
                        (isArrayWithLength(FilterreachabilityData)) ?
                            <Row>
                                <Col lg='12'>
                                    <Nav tabs style={{ borderBottom: '0' }}>
                                        {isArrayWithLength(FilterreachabilityData) && FilterreachabilityData.map((pr: IPropMsodsGridChart, index) => (
                                            <React.Fragment key={index}>
                                                {
                                                    (Perfenvironment !== pr.environment)
                                                        ?
                                                        <Fragment>
                                                            <NavItem style={{ color: 'rgba(0,0,0,0.54)' }}>
                                                                <NavLink
                                                                    className={classnames({ active: activeTab === pr.environment })}
                                                                    onClick={() => {
                                                                        toggle(pr.environment);
                                                                    }}
                                                                >
                                                                    <React.Fragment>{pr.environment}  {setPerfenvironment(pr.environment)}</React.Fragment>
                                                                </NavLink>
                                                            </NavItem>
                                                        </Fragment>
                                                        : <React.Fragment></React.Fragment>

                                                }
                                            </React.Fragment>
                                        ))}
                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                        <div>
                                            <table id="tablefilter" className="ReachabilityErrortable tblborder" style={{ width: '95vw' }}>
                                                <thead>
                                                    <tr><td className="thstyleOnCall" colSpan={20}><h6 style={{ textAlign: 'center', color: 'black', verticalAlign:'center' }}>Reachability</h6></td></tr>
                                                    <tr><th className="thstyleOnCall" style={{ width: '270px', cursor: 'pointer', verticalAlign: 'middle' }} onClick={() => sortReachabilityColumn("serviceName")}>Service Name &nbsp;
                                                        <span style={{ verticalAlign: 'middle' }}>
                                                            {
                                                                (Reachabilitycolumn === 'serviceName' && reachabilitysortOrder)
                                                                    ? <span>&#x25BC;</span>
                                                                    : (Reachabilitycolumn === 'serviceName' && !reachabilitysortOrder)
                                                                        ? <span>&#x25B2;</span>
                                                                        : <React.Fragment></React.Fragment>
                                                            }
                                                        </span>
                                                    </th>
                                                        <th className="thstyleOnCall" style={{ width: '140px', cursor: 'pointer', verticalAlign: 'middle' }} onClick={() => sortReachabilityColumn("environment")}>Environment &nbsp;
                                                        <span style={{ verticalAlign: 'middle' }}>
                                                                {
                                                                    (Reachabilitycolumn === 'environment' && reachabilitysortOrder)
                                                                        ? <span>&#x25BC;</span>
                                                                        : (Reachabilitycolumn === 'environment' && !reachabilitysortOrder)
                                                                            ? <span>&#x25B2;</span>
                                                                            : <React.Fragment></React.Fragment>
                                                                }
                                                            </span>
                                                        </th>
                                                        <th className="thstyleOnCall" style={{ width: '100px', cursor: 'pointer', verticalAlign: 'middle' }} onClick={() => sortReachabilityColumn("target")}>Target &nbsp;
                                                        <span style={{ verticalAlign: 'middle' }}>
                                                                {
                                                                    (Reachabilitycolumn === 'target' && reachabilitysortOrder)
                                                                        ? <span>&#x25BC;</span>
                                                                        : (Reachabilitycolumn === 'target' && !reachabilitysortOrder)
                                                                            ? <span>&#x25B2;</span>
                                                                            : <React.Fragment></React.Fragment>
                                                                }
                                                            </span>
                                                        </th>
                                                        {
                                                            (weekDetailsData !== undefined && weekDetailsData !== null)
                                                                ?
                                                                <React.Fragment>
                                                                    <th className="thstyleOnCall" style={{ width: '130px', cursor: 'pointer' }}>
                                                                        <span onClick={() => sortReachabilityColumn("week1Sort")}> {weekDetailsData.weeksDetails.weekDet1} &nbsp;
                                                                    <span style={{ verticalAlign: 'middle' }}>
                                                                                {
                                                                                    (Reachabilitycolumn === 'week1Sort' && reachabilitysortOrder)
                                                                                        ? <span>&#x25BC;</span>
                                                                                        : (Reachabilitycolumn === 'week1Sort' && !reachabilitysortOrder)
                                                                                            ? <span>&#x25B2;</span>
                                                                                            : <React.Fragment></React.Fragment>
                                                                                }
                                                                            </span>
                                                                        </span>
                                                                        <div>
                                                                            <span onClick={() => sortReachabilityColumn("week1Sort")} style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week1}</span>
                                                                            {
                                                                                (IsEdit === 'week1') ?
                                                                                    <span onClick={e => CancelEdit("week1")} style={{ cursor: 'pointer', float: 'right' }}>
                                                                                        <img title='Click to Cancel Edit' src={cancel} style={{ width: '20px', height: '20px' }} />
                                                                                    </span>
                                                                                    :
                                                                                    <span onClick={e => enableEditData(0, "week1", weekDetailsData.weeks.week1ID)} style={{ cursor: 'pointer', float: 'right' }}>
                                                                                        <FontAwesomeIcon title='Click to edit the last week data' className="Editiconsfontsize" icon={'pencil-alt'} />
                                                                                    </span>
                                                                            }
                                                                        </div>
                                                                        <div style={{ paddingTop: '6px' }}>
                                                                            <div>
                                                                                <select style={{ width: '100px', borderRadius: '0.25rem', border: '1px solid #ced4da', height: '24px' }} value={weekfilter.week1selected} onChange={e => filterweekdata(e, 'week1')} name="dropdown">
                                                                                    {isArrayWithLength(options) && options.map((e, key) => {
                                                                                        return <option key={key} value={e.value}>{e.label}</option>;
                                                                                    })}

                                                                                </select>
                                                                            </div>
                                                                            <input placeholder="Number" style={{ maxWidth: '100px', borderRadius: '0.25rem', border: '1px solid #ced4da' }} value={weekfilter.week1 == "" ? "" : weekfilter.week1} onChange={(e) => filterweekdata(e, 'week1')} name="inputnumber" />
                                                                        </div>
                                                                    </th>
                                                                    {ShowHistory1 ?
                                                                        <th style={{ borderLeft: '2px solid #c7e3ff' }}></th>
                                                                        : <React.Fragment></React.Fragment>
                                                                    }
                                                                    <th className="thstyleOnCall" style={{ width: '130px', cursor: 'pointer' }}>
                                                                        <span onClick={() => sortReachabilityColumn("week2Sort")}> {weekDetailsData.weeksDetails.weekDet2} &nbsp;
                                                                    <span style={{ verticalAlign: 'middle' }}>
                                                                                {
                                                                                    (Reachabilitycolumn === 'week2Sort' && reachabilitysortOrder)
                                                                                        ? <span>&#x25BC;</span>
                                                                                        : (Reachabilitycolumn === 'week2Sort' && !reachabilitysortOrder)
                                                                                            ? <span>&#x25B2;</span>
                                                                                            : <React.Fragment></React.Fragment>
                                                                                }
                                                                            </span>
                                                                        </span>
                                                                        <div>
                                                                            <span onClick={() => sortReachabilityColumn("week2Sort")} style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week2}</span>
                                                                            {
                                                                                (IsEdit === 'week2') ?
                                                                                    <span onClick={e => CancelEdit("week2")} style={{ cursor: 'pointer', float: 'right' }}>
                                                                                        <img title='Click to Cancel Edit' src={cancel} style={{ width: '20px', height: '20px' }} />
                                                                                    </span>
                                                                                    :
                                                                                    <span onClick={e => enableEditData(0, "week2", weekDetailsData.weeks.week2ID)} style={{ cursor: 'pointer', float: 'right' }}>
                                                                                        <FontAwesomeIcon title='Click to edit the last week data' className="Editiconsfontsize" icon={'pencil-alt'} />
                                                                                    </span>
                                                                            }
                                                                        </div>
                                                                        <div style={{ paddingTop: '6px' }}>
                                                                            <div>
                                                                                <select style={{ width: '100px', borderRadius: '0.25rem', border: '1px solid #ced4da', height: '24px' }} value={weekfilter.week2selected} onChange={e => filterweekdata(e, 'week2')} name="dropdown">
                                                                                    {isArrayWithLength(options) && options.map((e, key) => {
                                                                                        return <option key={key} value={e.value}>{e.label}</option>;
                                                                                    })}

                                                                                </select>
                                                                            </div>
                                                                            <input placeholder="Number" style={{ maxWidth: '100px', borderRadius: '0.25rem', border: '1px solid #ced4da' }} value={weekfilter.week2 == "" ? "" : weekfilter.week2} onChange={(e) => filterweekdata(e, 'week2')} name="inputnumber" />
                                                                        </div>
                                                                    </th>
                                                                    {ShowHistory1 ?
                                                                        <th style={{ borderLeft: '2px solid #c7e3ff' }}></th>
                                                                        : <React.Fragment></React.Fragment>
                                                                    }
                                                                    <th className="thstyleOnCall" style={{ width: '130px', cursor: 'pointer' }}>
                                                                        <span onClick={() => sortReachabilityColumn("week3Sort")}> {weekDetailsData.weeksDetails.weekDet3} &nbsp;
                                                                    <span style={{ verticalAlign: 'middle' }}>
                                                                                {
                                                                                    (Reachabilitycolumn === 'week3Sort' && reachabilitysortOrder)
                                                                                        ? <span>&#x25BC;</span>
                                                                                        : (Reachabilitycolumn === 'week3Sort' && !reachabilitysortOrder)
                                                                                            ? <span>&#x25B2;</span>
                                                                                            : <React.Fragment></React.Fragment>
                                                                                }
                                                                            </span>
                                                                        </span>
                                                                        <div>
                                                                            <span onClick={() => sortReachabilityColumn("week3Sort")} style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week3}</span>
                                                                            {
                                                                                (IsEdit === 'week3') ?
                                                                                    <span onClick={e => CancelEdit("week3")} style={{ cursor: 'pointer', float: 'right' }}>
                                                                                        <img title='Click to Cancel Edit' src={cancel} style={{ width: '20px', height: '20px' }} />
                                                                                    </span>
                                                                                    :
                                                                                    <span onClick={e => enableEditData(0, "week3", weekDetailsData.weeks.week3ID)} style={{ cursor: 'pointer', float: 'right' }}>
                                                                                        <FontAwesomeIcon title='Click to edit the last week data' className="Editiconsfontsize" icon={'pencil-alt'} />
                                                                                    </span>
                                                                            }
                                                                        </div>
                                                                        <div style={{ paddingTop: '6px' }}>
                                                                            <div>
                                                                                <select style={{ width: '100px', borderRadius: '0.25rem', border: '1px solid #ced4da', height: '24px' }} value={weekfilter.week3selected} onChange={e => filterweekdata(e, 'week3')} name="dropdown">
                                                                                    {isArrayWithLength(options) && options.map((e, key) => {
                                                                                        return <option key={key} value={e.value}>{e.label}</option>;
                                                                                    })}

                                                                                </select>
                                                                            </div>
                                                                            <input placeholder="Number" style={{ maxWidth: '100px', borderRadius: '0.25rem', border: '1px solid #ced4da' }} value={weekfilter.week3 == "" ? "" : weekfilter.week3} onChange={(e) => filterweekdata(e, 'week3')} name="inputnumber" />
                                                                        </div>
                                                                    </th>
                                                                    {ShowHistory1 ?
                                                                        <th style={{ borderLeft: '2px solid #c7e3ff' }}></th>
                                                                        : <React.Fragment></React.Fragment>
                                                                    }
                                                                    <th className="thstyleOnCall" style={{ width: '130px', cursor: 'pointer' }}>
                                                                        <span onClick={() => sortReachabilityColumn("week4Sort")}> {weekDetailsData.weeksDetails.weekDet4} &nbsp;
                                                                    <span style={{ verticalAlign: 'middle' }}>
                                                                                {
                                                                                    (Reachabilitycolumn === 'week4Sort' && reachabilitysortOrder)
                                                                                        ? <span>&#x25BC;</span>
                                                                                        : (Reachabilitycolumn === 'week4Sort' && !reachabilitysortOrder)
                                                                                            ? <span>&#x25B2;</span>
                                                                                            : <React.Fragment></React.Fragment>
                                                                                }
                                                                            </span>
                                                                        </span>
                                                                        <div>
                                                                            <span onClick={() => sortReachabilityColumn("week4Sort")} style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week4}</span>
                                                                            {
                                                                                (IsEdit === 'week4') ?
                                                                                    <span onClick={e => CancelEdit("week4")} style={{ cursor: 'pointer', float: 'right' }}>
                                                                                        <img title='Click to Cancel Edit' src={cancel} style={{ width: '20px', height: '20px' }} />
                                                                                    </span>
                                                                                    :
                                                                                    <span onClick={e => enableEditData(0, "week4", weekDetailsData.weeks.week4ID)} style={{ cursor: 'pointer', float: 'right' }}>
                                                                                        <FontAwesomeIcon title='Click to edit the last week data' className="Editiconsfontsize" icon={'pencil-alt'} />
                                                                                    </span>
                                                                            }
                                                                        </div>
                                                                        <div style={{ paddingTop: '6px' }}>
                                                                            <div>
                                                                                <select style={{ width: '100px', borderRadius: '0.25rem', border: '1px solid #ced4da', height: '24px' }} value={weekfilter.week4selected} onChange={e => filterweekdata(e, 'week4')} name="dropdown">
                                                                                    {isArrayWithLength(options) && options.map((e, key) => {
                                                                                        return <option key={key} value={e.value}>{e.label}</option>;
                                                                                    })}

                                                                                </select>
                                                                            </div>
                                                                            <input placeholder="Number" style={{ maxWidth: '100px', borderRadius: '0.25rem', border: '1px solid #ced4da' }} value={weekfilter.week4 == "" ? "" : weekfilter.week4} onChange={(e) => filterweekdata(e, 'week4')} name="inputnumber" />
                                                                        </div>
                                                                    </th>
                                                                    {ShowHistory1 ?
                                                                        <th style={{ borderLeft: '2px solid #c7e3ff' }}></th>
                                                                        : <React.Fragment></React.Fragment>
                                                                    }
                                                                    <th className="thstyleOnCall" style={{ width: '130px', cursor: 'pointer' }}>
                                                                        <span onClick={() => sortReachabilityColumn("week5Sort")}> {weekDetailsData.weeksDetails.weekDet5} &nbsp;
                                                                    <span style={{ verticalAlign: 'middle' }}>
                                                                                {
                                                                                    (Reachabilitycolumn === 'week5Sort' && reachabilitysortOrder)
                                                                                        ? <span>&#x25BC;</span>
                                                                                        : (Reachabilitycolumn === 'week5Sort' && !reachabilitysortOrder)
                                                                                            ? <span>&#x25B2;</span>
                                                                                            : <React.Fragment></React.Fragment>
                                                                                }
                                                                            </span>
                                                                        </span>
                                                                        <div>
                                                                            <span onClick={() => sortReachabilityColumn("week5Sort")} style={{ fontSize: 'small' }}>{weekDetailsData.weeks.week5}</span>
                                                                            {
                                                                                (IsEdit === 'week5') ?
                                                                                    <span onClick={e => CancelEdit("week5")} style={{ cursor: 'pointer', float: 'right' }}>
                                                                                        <img title='Click to Cancel Edit' src={cancel} style={{ width: '20px', height: '20px' }} />
                                                                                    </span>
                                                                                    :
                                                                                    <span onClick={e => enableEditData(0, "week5", weekDetailsData.weeks.week5ID)} style={{ cursor: 'pointer', float: 'right' }}>
                                                                                        <FontAwesomeIcon title='Click to edit the last week data' className="Editiconsfontsize" icon={'pencil-alt'} />
                                                                                    </span>
                                                                            }
                                                                        </div>
                                                                        <div style={{ paddingTop: '6px' }}>
                                                                            <div>
                                                                                <select style={{ width: '100px', borderRadius: '0.25rem', border: '1px solid #ced4da', height: '24px' }} value={weekfilter.week5selected} onChange={e => filterweekdata(e, 'week5')} name="dropdown">
                                                                                    {isArrayWithLength(options) && options.map((e, key) => {
                                                                                        return <option key={key} value={e.value}>{e.label}</option>;
                                                                                    })}

                                                                                </select>
                                                                            </div>
                                                                            <input placeholder="Number" style={{ maxWidth: '100px', borderRadius: '0.25rem', border: '1px solid #ced4da' }} value={weekfilter.week5 == "" ? "" : weekfilter.week5} onChange={(e) => filterweekdata(e, 'week5')} name="inputnumber" />
                                                                        </div>
                                                                    </th>
                                                                    {ShowHistory1 ?
                                                                        <th style={{ borderLeft: '2px solid #c7e3ff' }}></th>
                                                                        : <React.Fragment></React.Fragment>
                                                                    }
                                                                    {(IsEdit !== "") ?
                                                                        <th style={{ width: '100px', verticalAlign: 'middle' }}>Comments</th>
                                                                        : <React.Fragment></React.Fragment>
                                                                    }
                                                                </React.Fragment>
                                                                : <React.Fragment></React.Fragment>
                                                        }

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        isArrayWithLength(reachabilityData) ?
                                                            reachabilityData
                                                                .filter((item: IPropMsodsGridChart) => {
                                                                    if (filterBasedSearch(item)) {
                                                                        return true
                                                                    }
                                                                })
                                                                .map((row: IPropMsodsGridChart, index) => (
                                                                    <React.Fragment key={index}>
                                                                        <tr><td>
                                                                            <div title={row.sslName}>{row.serviceName} &nbsp;
                                                                            <FontAwesomeIconMemo style={{ cursor: 'pointer', color: 'green' }} title='Click to copy Thousand Eyes Test Name' onClick={e => copyToClipBoard(row.sslName)} icon={'paste'} /></div>
                                                                        </td>
                                                                            <td>{row.environment}</td>
                                                                            <td style={{ textAlign: 'center' }}>{row.targetName}</td>
                                                                            <td style={{ textAlign: 'center', backgroundColor: AddColorForReachability(row.targetName, row.week1) }}>
                                                                                {
                                                                                    (IsEdit === 'week1') ?
                                                                                        <input className="inputelemReachability" type="text" style={{ maxWidth: '120px', border: '1px solid #ced4da' }} id={"txtweek1" + row.rowIndex} value={row.week1Edit} onChange={e => inputValueChanged(e, row.rowIndex)} />
                                                                                        : <span style={{ color: (row.week1Hiscount > 0) ? 'rgb(0, 0, 255)' : 'black' }}>{row.week1}</span>
                                                                                }
                                                                            </td>
                                                                            {
                                                                                ShowHistory1 ?
                                                                                    <td style={{ textAlign: 'center', width: '22px' }}>
                                                                                        {
                                                                                            (row.week1Hiscount > 0) ?
                                                                                                <img title='View History' src={hinew} style={{ width: '15px', height: '15px' }} onClick={() => showHistorypopup(row.rowIndex, row, weekDetailsData.weeks.week1ID)} />
                                                                                                : <React.Fragment></React.Fragment>
                                                                                        }
                                                                                    </td>
                                                                                    : <React.Fragment></React.Fragment>
                                                                            }
                                                                            <td style={{ textAlign: 'center', backgroundColor: AddColorForReachability(row.targetName, row.week2) }}>
                                                                                {
                                                                                    (IsEdit === 'week2') ?
                                                                                        <input className="inputelemReachability" type="text" style={{ maxWidth: '120px', border: '1px solid #ced4da' }} id={"txtweek2" + row.rowIndex} value={row.week2Edit} onChange={e => inputValueChanged(e, row.rowIndex)} />
                                                                                        : <span style={{ color: (row.week2Hiscount > 0) ? 'rgb(0, 0, 255)' : 'black' }}>{row.week2}</span>
                                                                                }
                                                                            </td>
                                                                            {
                                                                                ShowHistory1 ?
                                                                                    <td style={{ textAlign: 'center', width: '22px' }}>
                                                                                        {
                                                                                            (row.week2Hiscount > 0) ?
                                                                                                <img title='View History' src={hinew} style={{ width: '15px', height: '15px' }} onClick={() => showHistorypopup(row.rowIndex, row, weekDetailsData.weeks.week2ID)} />
                                                                                                : <React.Fragment></React.Fragment>
                                                                                        }
                                                                                    </td>
                                                                                    : <React.Fragment></React.Fragment>
                                                                            }
                                                                            <td style={{ textAlign: 'center', backgroundColor: AddColorForReachability(row.targetName, row.week3) }}>
                                                                                {
                                                                                    (IsEdit === 'week3') ?
                                                                                        <input className="inputelemReachability" type="text" style={{ maxWidth: '120px', border: '1px solid #ced4da' }} id={"txtweek3" + row.rowIndex} value={row.week3Edit} onChange={e => inputValueChanged(e, row.rowIndex)} />
                                                                                        : <span style={{ color: (row.week3Hiscount > 0) ? 'rgb(0, 0, 255)' : 'black' }}>{row.week3}</span>
                                                                                }
                                                                            </td>
                                                                            {
                                                                                ShowHistory1 ?
                                                                                    <td style={{ textAlign: 'center', width: '22px' }}>
                                                                                        {
                                                                                            (row.week3Hiscount > 0) ?
                                                                                                <img title='View History' src={hinew} style={{ width: '15px', height: '15px' }} onClick={() => showHistorypopup(row.rowIndex, row, weekDetailsData.weeks.week3ID)} />
                                                                                                : <React.Fragment></React.Fragment>
                                                                                        }
                                                                                    </td>
                                                                                    : <React.Fragment></React.Fragment>
                                                                            }
                                                                            <td style={{ textAlign: 'center', backgroundColor: AddColorForReachability(row.targetName, row.week4) }}>
                                                                                {
                                                                                    (IsEdit === 'week4') ?
                                                                                        <input className="inputelemReachability" type="text" style={{ maxWidth: '120px', border: '1px solid #ced4da' }} id={"txtweek4" + row.rowIndex} value={row.week4Edit} onChange={e => inputValueChanged(e, row.rowIndex)} />
                                                                                        : <span style={{ color: (row.week4Hiscount > 0) ? 'rgb(0, 0, 255)' : 'black' }}>{row.week4}</span>
                                                                                }
                                                                            </td>
                                                                            {
                                                                                ShowHistory1 ?
                                                                                    <td style={{ textAlign: 'center', width: '22px' }}>
                                                                                        {
                                                                                            (row.week4Hiscount > 0) ?
                                                                                                <img title='View History' src={hinew} style={{ width: '15px', height: '15px' }} onClick={() => showHistorypopup(row.rowIndex, row, weekDetailsData.weeks.week4ID)} />
                                                                                                : <React.Fragment></React.Fragment>
                                                                                        }
                                                                                    </td>
                                                                                    : <React.Fragment></React.Fragment>
                                                                            }
                                                                            <td style={{ textAlign: 'center', backgroundColor: AddColorForReachability(row.targetName, row.week5) }}>
                                                                                {
                                                                                    (IsEdit === 'week5') ?
                                                                                        <input className="inputelemReachability" type="text" style={{ maxWidth: '120px', border: '1px solid #ced4da' }} id={"txtweek5" + row.rowIndex} value={row.week5Edit} onChange={e => inputValueChanged(e, row.rowIndex)} />
                                                                                        : <span style={{ color: (row.week5Hiscount > 0) ? 'rgb(0, 0, 255)' : 'black' }}>{row.week5}</span>
                                                                                }
                                                                            </td>
                                                                            {
                                                                                ShowHistory1 ?
                                                                                    <td style={{ textAlign: 'center', width: '22px' }}>
                                                                                        {
                                                                                            (row.week5Hiscount > 0) ?
                                                                                                <img title='View History' src={hinew} style={{ width: '15px', height: '15px' }} onClick={() => showHistorypopup(row.rowIndex, row, weekDetailsData.weeks.week5ID)} />
                                                                                                : <React.Fragment></React.Fragment>
                                                                                        }
                                                                                    </td>
                                                                                    : <React.Fragment></React.Fragment>
                                                                            }
                                                                            {
                                                                                (IsEdit !== "") ?
                                                                                    <td style={{ textAlign: 'center' }}><input className="inputelemReachability" type='text' id={"txtComments" + row.rowIndex} style={{ border: '1px solid #ced4da' }} value={row.comments} onChange={e => updateComments(e, row.rowIndex)} /></td>
                                                                                    : <React.Fragment></React.Fragment>
                                                                            }
                                                                        </tr>
                                                                    </React.Fragment>
                                                                ))
                                                            :
                                                            <React.Fragment>
                                                                <tr><
                                                                    td colSpan={11} style={{ textAlign: 'center' }} >
                                                                        <div style={{ color: 'Red', fontWeight: 'bold', textAlign: 'center' }}> <br />
                                                                             No Data Found!
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <br />
                                        <div>
                                                    <table id="tablefilter" className="ReachabilityErrortable tblborder" style={{ width: '95vw' }}>
                                                        <thead style={{ width: '95vw' }}>
                                                            <tr><td className="thstyleOnCall" colSpan={20}><h6 style={{ textAlign: 'center', color: 'black' }}>Reachability Error</h6></td></tr>
                                                            <tr style={{ height: '50px' }}>
                                                                <th className="thstyleOnCall" style={{ width: '200px', cursor: 'pointer' }} onClick={() => sortColumnError("serviceName")}>Service Name &nbsp;
                                                        <span style={{ verticalAlign: 'middle' }}>
                                                                        {
                                                                            (Errorcolumn === 'serviceName' && sortOrder)
                                                                                ? <span>&#x25BC;</span>
                                                                                : (Errorcolumn === 'serviceName' && !sortOrder)
                                                                                    ? <span>&#x25B2;</span>
                                                                                    : <React.Fragment></React.Fragment>
                                                                        }
                                                                    </span>
                                                                </th>
                                                                <th className="thstyleOnCall" style={{ width: '50px', cursor: 'pointer' }} onClick={() => sortColumnError("environment")}>Environment &nbsp;
                                                        <span style={{ verticalAlign: 'middle' }}>
                                                                        {
                                                                            (Errorcolumn === 'environment' && sortOrder)
                                                                                ? <span>&#x25BC;</span>
                                                                                : (Errorcolumn === 'environment' && !sortOrder)
                                                                                    ? <span>&#x25B2;</span>
                                                                                    : <React.Fragment></React.Fragment>
                                                                        }
                                                                    </span>
                                                                </th>
                                                                <th className="thstyleOnCall" style={{ width: '10px', cursor: 'pointer' }} onClick={() => sortColumnError("target")}>Target &nbsp;
                                                        <span style={{ verticalAlign: 'middle' }}>
                                                                        {
                                                                            (Errorcolumn === 'target' && sortOrder)
                                                                                ? <span>&#x25BC;</span>
                                                                                : (Errorcolumn === 'target' && !sortOrder)
                                                                                    ? <span>&#x25B2;</span>
                                                                                    : <React.Fragment></React.Fragment>
                                                                        }
                                                                    </span>
                                                                </th>
                                                                <th className="thstyleOnCall" style={{ width: '30px', cursor: 'pointer' }} onClick={() => sortColumnError("totalErrors")}>Total Errors &nbsp;
                                                        <span style={{ verticalAlign: 'middle' }}>
                                                                        {
                                                                            (Errorcolumn === 'totalErrors' && sortOrder)
                                                                                ? <span>&#x25BC;</span>
                                                                                : (Errorcolumn === 'totalErrors' && !sortOrder)
                                                                                    ? <span>&#x25B2;</span>
                                                                                    : <React.Fragment></React.Fragment>
                                                                        }
                                                                    </span>
                                                                </th>
                                                                <th className="thstyleOnCall" style={{ width: '30px', cursor: 'pointer' }} onClick={() => sortColumnError("dnsError")}>DNS Error % &nbsp;
                                                        <span style={{ verticalAlign: 'middle' }}>
                                                                        {
                                                                            (Errorcolumn === 'dnsError' && sortOrder)
                                                                                ? <span>&#x25BC;</span>
                                                                                : (Errorcolumn === 'dnsError' && !sortOrder)
                                                                                    ? <span>&#x25B2;</span>
                                                                                    : <React.Fragment></React.Fragment>
                                                                        }
                                                                    </span>
                                                                </th>
                                                                <th className="thstyleOnCall" style={{ width: '40px', cursor: 'pointer' }} onClick={() => sortColumnError("connectError")}>Connect Error % &nbsp;
                                                        <span style={{ verticalAlign: 'middle' }}>
                                                                        {
                                                                            (Errorcolumn === 'connectError' && sortOrder)
                                                                                ? <span>&#x25BC;</span>
                                                                                : (Errorcolumn === 'connectError' && !sortOrder)
                                                                                    ? <span>&#x25B2;</span>
                                                                                    : <React.Fragment></React.Fragment>
                                                                        }
                                                                    </span>
                                                                </th>
                                                                <th className="thstyleOnCall" style={{ width: '30px', cursor: 'pointer' }} onClick={() => sortColumnError("sslError")}>SSL Error % &nbsp;
                                                        <span style={{ verticalAlign: 'middle' }}>
                                                                        {
                                                                            (Errorcolumn === 'sslError' && sortOrder)
                                                                                ? <span>&#x25BC;</span>
                                                                                : (Errorcolumn === 'sslError' && !sortOrder)
                                                                                    ? <span>&#x25B2;</span>
                                                                                    : <React.Fragment></React.Fragment>
                                                                        }
                                                                    </span>
                                                                </th>
                                                                <th className="thstyleOnCall" style={{ width: '30px', cursor: 'pointer' }} onClick={() => sortColumnError("sendError")}>Send Error % &nbsp;
                                                        <span style={{ verticalAlign: 'middle' }}>
                                                                        {
                                                                            (Errorcolumn === 'sendError' && sortOrder)
                                                                                ? <span>&#x25BC;</span>
                                                                                : (Errorcolumn === 'sendError' && !sortOrder)
                                                                                    ? <span>&#x25B2;</span>
                                                                                    : <React.Fragment></React.Fragment>
                                                                        }
                                                                    </span>
                                                                </th>
                                                                <th className="thstyleOnCall" style={{ width: '30px', cursor: 'pointer' }} onClick={() => sortColumnError("receiveError")}>Receive Error % &nbsp;
                                                        <span style={{ verticalAlign: 'middle' }}>
                                                                        {
                                                                            (Errorcolumn === 'receiveError' && sortOrder)
                                                                                ? <span>&#x25BC;</span>
                                                                                : (Errorcolumn === 'receiveError' && !sortOrder)
                                                                                    ? <span>&#x25B2;</span>
                                                                                    : <React.Fragment></React.Fragment>
                                                                        }
                                                                    </span>
                                                                </th>
                                                                <th className="thstyleOnCall" style={{ width: '30px', cursor: 'pointer' }} onClick={() => sortColumnError("httpError")}>Http Error % &nbsp;
                                                        <span style={{ verticalAlign: 'middle' }}>
                                                                        {
                                                                            (Errorcolumn === 'httpError' && sortOrder)
                                                                                ? <span>&#x25BC;</span>
                                                                                : (Errorcolumn === 'httpError' && !sortOrder)
                                                                                    ? <span>&#x25B2;</span>
                                                                                    : <React.Fragment></React.Fragment>
                                                                        }
                                                                    </span>
                                                                </th>
                                                                <th className="thstyleOnCall" style={{ width: '30px', cursor: 'pointer' }} onClick={() => sortColumnError("contentError")}>Content Error % &nbsp;
                                                        <span style={{ verticalAlign: 'middle' }}>
                                                                        {
                                                                            (Errorcolumn === 'contentError' && sortOrder)
                                                                                ? <span>&#x25BC;</span>
                                                                                : (Errorcolumn === 'contentError' && !sortOrder)
                                                                                    ? <span>&#x25B2;</span>
                                                                                    : <React.Fragment></React.Fragment>
                                                                        }
                                                                    </span>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                isArrayWithLength(reachabilityErrorData) ?
                                                                    <React.Fragment>
                                                                        {isArrayWithLength(reachabilityErrorData) ?
                                                                            reachabilityErrorData
                                                                                .filter((item: IPropReachabilityError) => {
                                                                                    if (filterBasedSearchError(item)) {
                                                                                        return true
                                                                                    }
                                                                                })
                                                                                .map((errorrow: IPropReachabilityError, index) => (
                                                                                    <React.Fragment key={index}>
                                                                                        <tr style={{ border: '2px solid #666666 !important' }}>
                                                                                            <td>{errorrow.serviceName}</td>
                                                                                            <td>{errorrow.environment}</td>
                                                                                            <td className="tdwidth100">{errorrow.target}</td>
                                                                                            <td className="tdwidth100">{errorrow.totalErrors}</td>
                                                                                            <td className="tdwidth100">{errorrow.dnsError}</td>
                                                                                            <td className="tdwidth100">{errorrow.connectError}</td>
                                                                                            <td className="tdwidth100">{errorrow.sslError}</td>
                                                                                            <td className="tdwidth100">{errorrow.sendError}</td>
                                                                                            <td className="tdwidth100">{errorrow.receiveError}</td>
                                                                                            <td className="tdwidth100">{errorrow.httpError}</td>
                                                                                            <td className="tdwidth100">{errorrow.contentError}</td>
                                                                                        </tr>
                                                                                    </React.Fragment>
                                                                                ))
                                                                            : <React.Fragment></React.Fragment>
                                                                        }

                                                                    </React.Fragment>
                                                                    :
                                                                    <React.Fragment>
                                                                        <tr>
                                                                            <td colSpan={11} style={{ textAlign: 'center' }} >
                                                                                <div style={{ color: 'Red', fontWeight: 'bold', textAlign: 'center' }}> <br />
                                                                                    No Data Found!
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </React.Fragment>

                                                            }
                                                        </tbody>
                                                    </table>
                                        </div>
                                    </TabContent>
                                </Col>
                            </Row>
                            : <React.Fragment></React.Fragment>
                    }
                    {
                        (!isArrayWithLength(reachabilityData))
                            ?
                            <Row>
                                <Col lg="12">
                                    <div style={{ color: 'Red', fontWeight: 'bold', textAlign: 'center' }}> <br />
                                     No Data Found!
                            </div>
                                </Col>
                            </Row>
                            : <React.Fragment></React.Fragment>
                    }
                </div>
            </React.Fragment>
        </div>
    )
});

ReachabilityMetricsTable.displayName = 'ReachabilityMetricsTable';
export default ReachabilityMetricsTable;