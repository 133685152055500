
import { IAppThunkAction, ReduxAction } from '../';
import { ActionType, IDropdownOption } from './types';

export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    handleOnCheck: (checked: boolean): ReduxAction => ({
        checked: checked,
        type: ActionType.CHECK_SAMPLE_BOX
    }),

    reportSelectOption: (option: IDropdownOption): ReduxAction => ({
        option: option,
        type: ActionType.DROPDOWN_SELECT
    }),

    //viewSelectOption: (viewData: IViewDropdownOption): ReduxAction => ({
    //    viewData: viewData,
    //    type: ActionType.VIEW_DROPDOWN_SELECT
    //}),

    getHeatmapView: (serviceId = 17): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        // Dispatch request
        dispatch({
            //startGroupIndex,
            type: ActionType.REQUEST
        });

    
    },
}
