import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IWeeklyAvailabilityState, ITargetJson } from './types';
import { isNullOrUndefined } from 'util';
import moment from 'moment';

const initialState = Object.freeze<IWeeklyAvailabilityState>({
    isLoading: false,
    weeklyStartDate: new Date(),
    weeklyData: [],
    weeklyheaderArr: [],
    WeeklyKPiArr: [],
    IsWeeklyDataLoaded: false,
    IsWeeklyDataDisabled: false,
    liKPIValueKeys: [],
    serviceGroups: [],
    services: [],
    servicegroupval: [],
    serviceval: [],
    globalsearch: '',
    servicegroupvalstring: '',
    servicevalstring: '',
    isDataloaded: false,
    IsEdit: false,
    WeeklyAggUpdate: [],
    EditKeyValue: '',
    urlparam: '',
    editedTargetData: [],
    TargetEditPop: false,
    originalTargetValue:'',
    updatedTargetValue: '',
    justificationDetails:''
});

export const reducer = (
    state: IWeeklyAvailabilityState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {
        case ActionType.WEEKLY_START_DATE_CHANGE:
            return {
                ...state,
                weeklyStartDate: action.weeklyStartDate
            };
        case ActionType.WEEKLYDATA_FETCH:
            return {
                ...state,
                weeklyData: [],
                isLoading: true,
                IsWeeklyDataLoaded: false,
                IsWeeklyDataDisabled: false,
                isDataloaded: false
            };
        case ActionType.WEEKLYDATA_RECEIVE:
            return {
                ...state,
                weeklyData: action.weekData,
                weeklyheaderArr: action.WeeklyheaderArr,
                WeeklyKPiArr: action.WeeklyKPiArr,
                WeeklyAggUpdate: action.WeeklyAggUpdate,
                IsWeeklyDataLoaded: true,
                IsWeeklyDataDisabled: true,
                liKPIValueKeys: action.liKPIValueKeys,
                isLoading: false,
                isDataloaded: true
            };
        case ActionType.GET_SERVICEGROUPS_SUCCESS:
            return {
                ...state,
                serviceGroups: action.serviceGroups,
                servicegroupval: action.servicegroupval,
                servicegroupvalstring: action.servicegroupvalstring
            };
        case ActionType.GET_SERVICEGROUPS_FAIL:
            return {
                ...state,
                serviceGroups: []
            };
        case ActionType.GET_SERVICELISTS_SUCCESS:
            return {
                ...state,
                services: action.services,
                serviceval: action.serviceval,
                servicevalstring: action.servicevalstring
            };
        case ActionType.GET_SERVICELISTS_FAIL:
            return {
                ...state,
                services: action.services
            };
        case ActionType.SERVICE_GROUPVAL_CHANGE:
            return {
                ...state,
                servicegroupval: action.servicegroupval,
                servicegroupvalstring: action.servicegroupvalstring,
                serviceval: action.serviceval,
                servicevalstring: action.servicevalstring
            };
        case ActionType.SERVICE_VAL_CHANGE:
            return {
                ...state,
                serviceval: action.serviceval,
                servicevalstring: action.servicevalstring,
                servicegroupval: action.servicegroupval,
                servicegroupvalstring: action.servicegroupvalstring
            };
        case ActionType.SEARCH_TEXTBOX_CHANGE:
            return {
                ...state,
                globalsearch: action.globalsearch
            };
        case ActionType.SET_TARGET_POPUP:
            return {
                ...state,
                editedTargetData: action.editedTargetData,
                TargetEditPop: action.TargetEditPop,
                originalTargetValue: action.originalTargetValue
            };
        case ActionType.SET_TARGET_POPUP_CLOSE:
            return {
                ...state,
                TargetEditPop: action.TargetEditPop,
                updatedTargetValue: '',
                justificationDetails: '',
                isLoading: false
            };
        case ActionType.EMAILCONFIRMATION:
            return {
                ...state,
            };
        case ActionType.UPDATEDTARGETDATASET:
             return {
                ...state,
                 editedTargetData: action.editedTargetData
            };
        case ActionType.UPDATEDTARGET:
            return {
                ...state,
                updatedTargetValue: action.updatedTargetValue
            };
        case ActionType.UPDATEJUSTIFICATION:
            return {
                ...state,
                justificationDetails: action.justificationDetails
            };
        case ActionType.CLEARTARGET:
            return {
                ...state,
                updatedTargetValue: action.updatedTargetValue,
                justificationDetails: action.justificationDetails
            };
        case ActionType.CLEAR_FILTERS:
            return {
                ...state,
                servicegroupval: action.servicegroupval,
                servicegroupvalstring: action.servicegroupvalstring,
                serviceval: action.serviceval,
                servicevalstring: action.servicevalstring,
                globalsearch: ''
            };
        case ActionType.SET_URL_PARAM_EMPTY:
            return {
                ...state,
                urlparam: window.history.replaceState(null, null, window.location.pathname)
            };
        case '@@router/LOCATION_CHANGE': {
            const { search } = action.payload.location;
            let URLParam;
            let copyUrlFrDate = null;
            const params = new URLSearchParams(search);
            let serviceIds = params.get('sid') == null ? action.servicevalstring : params.get('sid');
            let frDate = params.get('frDate');
            let serviceIdArray = [];
            var sDate = new Date();

            if (!isNullOrUndefined(serviceIds)) {
                var serviceid = serviceIds.split(',');
                serviceid.map((val) => {
                    serviceIdArray.push(parseInt(val));
                });
            }
           
            if (!isNullOrUndefined(frDate)) {
                var fDays = parseInt(frDate.substr(frDate.lastIndexOf("-") + 1, (frDate.length - frDate.lastIndexOf("-"))));
                copyUrlFrDate = moment(sDate).subtract(fDays, 'day').toDate(); // date object
            }
            copyUrlFrDate = (copyUrlFrDate !== null ? copyUrlFrDate : moment(new Date()).subtract(15, 'day').toDate());

            let urlparam = '';
            if (serviceIdArray.length === 0) {
                urlparam = '';
            }
            else {
                urlparam = String(serviceIds);
            }
            return {
                ...state,
                urlparam: urlparam,
                servicevalstring: serviceIds,
                weeklyStartDate: copyUrlFrDate
            }

        };        
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false
            };
        case ActionType.RESET_STATE:
            return initialState;

        default:
            return state;
    }
};