import React, { useEffect, useRef, useState, Fragment } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { Spinner } from '../../components';
import { IApplicationState } from '../../store';
import { RouteComponentProps } from 'react-router-dom';
import { actionCreators, reducer } from '../../store/AdminSettings';
import { AdminSettingsTable } from '../AdminSettings/child-components';
import LoadingOverlay from 'react-loading-overlay';
import { AdminSettingsAPI } from '../../api';
import { ToastId, toast } from 'react-toastify';
import { renderToastifyMsg } from '../../utils';

type AdminSettingsProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly username: string }
    & { readonly history: History }
    & { readonly isAdmin: boolean }
    & RouteComponentProps<{}>;

const AdminSettings: React.FC<AdminSettingsProps> = ({
    services,
    isLoading,
    username,
    isAuthenticated,
    isAdmin,
    adminSettingsData,
    Isdataloaded,
    getServiceGroups,
    addUserVisit,
    activeDailyDataTab,
    AdminDataTabsToggle,
    servicechange,
    DailyDataTabstoggleData,
    selectedServicesval,
    selectedCrServicesval,
    selectedMetricGroupsval,
    selectedCMMetricGroupsval,
    selectedEnvironmentval,
    serviceNameSelect,
    serviceNameSelectCr,
    metricGroupSelect,
    cmmetricGroupSelect,
    cmEnvironmentSelect,
    serviceNames,
    CMData,
    adminMetrics,
    metricGroups,
    CMMetricGroups,
    CMEnvirnoments,
    loadMetrics,
    CMScoreCardData,
    adminConfig,
    missingViewInfo,
    heatMapHistoryData,
    selectedHeatmapServiceval,
    heatmapServiceNameSelect,
    editHistory,
    selectedhistoryServicesval,
    selectedhistoryMetricGroupsval,
    historyserviceNameSelect,
    historymetricGroupSelect,
    metricChange,
    selectedCrMetricsval,
    crMetricSelect,
    targetDetails,
    selectedtargetDetailsval,
    crTargetSelect,
    crservice,
    crcomments,
    crrequestedBy,
    crcreatedBy,
    crtitle,
    crrequirment_Id,
    crnewValue,
    UpdateRequestedByValue,
    UpdateTitleValue,
    UpdateRequirementIdValue,
    UpdateCommentValue,
    SaveConfiguration,
    historyFrmCalVal,
    historyFrmValChange,
    historyToCalVal,
    historyToValChange,
    heatmapFromCalVal,
    heatmapFromCalValChange,
    heatmapToCalVal,
    heatmapToCalValChange,
    changetoggle,
    UpdateCMData,
    newMetricName,
    newMetricDisplayName,
    newMetricGroup,
    newMetricEnvironment,
    onInputChange,
    SaveNewCMMetric,
    handledMetricEditChange,
    handledMetricGraphChange,
    onInputValidExpChange,
    onInputMinRangeChange,
    onInputMaxRangeChange,
    onInputIntervalChange,
    metricConfigMetricId,
    metricConfigGroupName,
    isEditableMetricConfig,
    isMetricGraphMetricConfig,
    minRangeMetricConfig,
    maxRangeMetricConfig,
    intervalMetricConfig,
    validationExprMetricConfig,
    onInputMetricConfig,
    saveMetricConfig,
    targetUpdateApprovalRequestData,
    approveHmRequest,
    rejectHmRequest
}) => {
    useEffect(() => {
        if (isAuthenticated)
            getServiceGroups();
        addUserVisit(0, 'AdminSettings.tsx_v2');
    }, [isAuthenticated]);

    const toastIdRef = useRef<ToastId>('');
    const [targetApprovalRequest, settargetApprovalRequest] = useState(targetUpdateApprovalRequestData);
    //========== function to changes tabs in Daily Data=================//
    const DailyDataTabstoggle = tab => {
        DailyDataTabstoggleData(tab);

        if (activeDailyDataTab !== tab) {
            //============= function for binding the views based on the tab selection =============================================//
            // BindViewsDropdownbasedonTabselection(tab);
            if (tab === 'serviceConfiguration') {

            }
            if (tab === 'metricConfiguration') {

            }
            if (tab === 'crTracking') {

            }
            if (tab === 'heatmapTargetApproval') {

            }
            if (tab === 'capacityManagement') {

            }
            if (tab === 'otherConfiguration') {

            }
            if (tab === 'historyData') {

            }
            if (tab === 'heatMapHistoryData') {

            }
            if (tab === 'missingHeatmapData') {

            }
        };
    };

    const changeServiceStatus = async (e, isActive, serviceId) => {
        isActive = e;
        AdminSettingsAPI.SaveService(serviceId, isActive)
            .then((result: number) => {
                if (result == 1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg("Service Configuration updated successfully",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
                else {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Failed to update Service Configuration ",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            })
        changetoggle();
    };
    const SaveConfig = (e) => {
        let updateConfigValue = e;
        AdminSettingsAPI.UpdateConfig(updateConfigValue).then((result: number) => {
            toastIdRef.current = toast.success(
                renderToastifyMsg("Configuration updated successfully",
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        });
        SaveConfiguration(e);
    }
    const saveMetricConfigTab = (serviceId, metricId, isEditable, isMetricgraph, targetId, minRange, maxRange, interval, validationExpr) => {
        AdminSettingsAPI.UpdateMetricsforAdminPage(serviceId, metricId, isEditable, isMetricgraph, targetId, minRange, maxRange, interval, validationExpr)
            .then((result: number) => {
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg("Metric Configuration updated successfully",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    saveMetricConfig(serviceId);
                }
                else {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Failed to update Metric Configuration",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }

            });
    }

    const SaveCrMetricDetails = (metricObject, metricChangeDetails) => {
        var queryObj = {
            service: selectedCrServicesval.id,
            comments: metricObject.comments,
            requestedBy: metricObject.requestedBy,
            createdBy: username,
            metric: 0,
            type: metricObject.type === "Query" ? 1 : 0,
            title: metricObject.title,
            requirmentId: metricObject.requirmentId
        };
        if (metricObject.type != "Query") {
            var targetObj = {
                service: selectedCrServicesval.id,
                comments: metricObject.comments,
                requestedBy: metricObject.requestedBy,
                createdBy: username,
                metric: 0,
                newValue: selectedtargetDetailsval.name
            };
        }
        let metricValSelected = selectedCrMetricsval;
        let metricGroups = metricChangeDetails;
        if (metricValSelected == null || metricValSelected == "") {
            for (var i = 0; i < metricGroups.length; i++) {
                if (metricObject.type == "Query") {
                    queryObj.metric = metricGroups[i].key;
                    AdminSettingsAPI.PostQueryChange(queryObj).then((result: string) => {
                        if (result == "-1") {
                            toastIdRef.current = toast.success(
                                renderToastifyMsg("Record for metric is Added succesfully",
                                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                            );
                        }
                        else {
                            toastIdRef.current = toast.error(
                                renderToastifyMsg("Failed for adding metric record",
                                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                            );
                        }
                    });
                }
                else {
                    targetObj.metric = metricGroups[i].key;
                    AdminSettingsAPI.PostTargetChange(targetObj).then((result: string) => {
                        if (result == "-1") {
                            toastIdRef.current = toast.success(
                                renderToastifyMsg("Record for metric is Added succesfully",
                                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                            );
                        }
                        else {
                            toastIdRef.current = toast.error(
                                renderToastifyMsg("Failed for adding metric",
                                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                            );
                        }
                    });
                };
            }
        }
        else {
            if (metricObject.type == "Query") {
                queryObj.metric = metricChangeDetails.find(function (o) { return o.value === selectedCrMetricsval }).key;
                AdminSettingsAPI.PostQueryChange(queryObj).then((result: string) => {
                    if (result == "-1") {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg("Record for metric is Added succesfully",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                    else {
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Failed for adding metric",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                });
            }
            else {
                targetObj.metric = metricChangeDetails.find(function (o) { return o.value === selectedCrMetricsval }).key;
                AdminSettingsAPI.PostTargetChange(targetObj).then((result: string) => {
                    if (result == "-1") {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg("Record for metric is Added succesfully",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                    else {
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Failed for adding metric",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                });
            };
        }
    }

    const NewMetricSavefunction = (CMMetricGroupsDetails, CMEnvirnomentsDetails) => {
        let metricGrpDetails = CMMetricGroupsDetails;
        let metricEvnDetails = CMEnvirnomentsDetails;
        let metricgrpId = metricGrpDetails.find(o => o.value === newMetricGroup).key;
        let envId = metricEvnDetails.find(o => o.value === newMetricEnvironment).key;
        let cmMetric = {
            cmMetricDisplayName: newMetricName,
            cmMetricGroupId: metricgrpId,
            cmMetricName: newMetricDisplayName,
            envId: envId
        }
        AdminSettingsAPI.InsertCMMertic(cmMetric).then((result: number) => {
            if (result[0].id > 0) {
                toastIdRef.current = toast.success(
                    renderToastifyMsg("Capacity Management New record is Added successfully",
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
                SaveNewCMMetric();
            }

        });
    }

    const UpdateCMRecord = (e) => {
        let cmMetric = {
            cmMetricDisplayName: e.cmMetricDisplayName,
            cmMetricGroupId: e.cmMetricGroupId.key,
            cmMetricId: e.cmMetricId,
            cmMetricName: e.cmMetricName,
            envId: e.envId.key
        }
        AdminSettingsAPI.UpdateCMMertic(cmMetric)
            .then((result: number) => {
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg("Capacity Management record updated successfully",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    UpdateCMData();
                }
            });

    }
    const CMDeleteMetric = (e) => {
        let cmMetric = {
            cmMetricDisplayName: e.cmMetricDisplayName,
            cmMetricGroupId: e.cmMetricGroupId.key,
            cmMetricId: e.cmMetricId,
            cmMetricName: e.cmMetricName,
            envId: e.envId.key
        }
        AdminSettingsAPI.CMDeleteMetric(cmMetric)
            .then((result: number) => {
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg("Capacity Management record Deleted successfully",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    UpdateCMData();
                }
            });
    }
    
    const reviewerCommentsChange = (e, row) => {
        const targetCopy = targetUpdateApprovalRequestData;
        const approvalRequest: any = [];
        for (var i = 0; i < targetCopy.length; i++) {
            if (row.viewId == targetCopy[i].viewId) {
                targetUpdateApprovalRequestData[i].reviewerComments = e.target.value;
            }
            approvalRequest.push(targetUpdateApprovalRequestData[i]);
        }
        settargetApprovalRequest(approvalRequest);
    }
    return (
        <div>
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        <AdminSettingsTable
                            services={services}
                            isLoading={isLoading}
                            adminMetrics={adminMetrics}
                            serviceNames={serviceNames}
                            metricChange={metricChange}
                            CMData={CMData}
                            isAdmin={isAdmin}
                            CMScoreCardData={CMScoreCardData}
                            adminConfig={adminConfig}
                            heatMapHistoryData={heatMapHistoryData}
                            missingViewInfo={missingViewInfo}
                            editHistory={editHistory}
                            selectedServicesval={selectedServicesval}
                            selectedCrServicesval={selectedCrServicesval}
                            selectedMetricGroupsval={selectedMetricGroupsval}
                            selectedCMMetricGroupsval={selectedCMMetricGroupsval}
                            selectedEnvironmentval={selectedEnvironmentval}
                            selectedHeatmapServiceval={selectedHeatmapServiceval}
                            selectedhistoryServicesval={selectedhistoryServicesval}
                            selectedhistoryMetricGroupsval={selectedhistoryMetricGroupsval}
                            adminSettingsData={adminSettingsData}
                            Isdataloaded={Isdataloaded}
                            activeDailyDataTab={activeDailyDataTab}
                            DailyDataTabstoggle={DailyDataTabstoggle}
                            servicechange={servicechange}
                            metricGroups={metricGroups}
                            CMMetricGroups={CMMetricGroups}
                            CMEnvirnoments={CMEnvirnoments}
                            loadMetrics={loadMetrics}
                            serviceNameSelect={(selectedServicesval) => serviceNameSelect(selectedServicesval)}
                            serviceNameSelectCr={(selectedCrServicesval) => serviceNameSelectCr(selectedCrServicesval)}
                            metricGroupSelect={(selectedMetricGroupsval) => metricGroupSelect(selectedMetricGroupsval)}
                            cmmetricGroupSelect={cmmetricGroupSelect}
                            cmEnvironmentSelect={cmEnvironmentSelect}
                            heatmapServiceNameSelect={(selectedHeatmapServiceval) => heatmapServiceNameSelect(selectedHeatmapServiceval)}
                            historyserviceNameSelect={(selectedhistoryServicesval) => historyserviceNameSelect(selectedhistoryServicesval)}
                            historymetricGroupSelect={(selectedhistoryMetricGroupsval) => historymetricGroupSelect(selectedhistoryMetricGroupsval)}
                            selectedCrMetricsval={selectedCrMetricsval}
                            crMetricSelect={(selectedCrMetricsval) => crMetricSelect(selectedCrMetricsval)}
                            changeServiceStatus={(e, isActive, serviceId) => changeServiceStatus(e, isActive, serviceId)}
                            targetDetails={targetDetails}
                            selectedtargetDetailsval={selectedtargetDetailsval}
                            crTargetSelect={(selectedtargetDetailsval) => crTargetSelect(selectedtargetDetailsval)}
                            crservice={crservice}
                            crcomments={crcomments}
                            crrequestedBy={crrequestedBy}
                            crcreatedBy={crcreatedBy}
                            crtitle={crtitle}
                            crrequirment_Id={crrequirment_Id}
                            crnewValue={crnewValue}
                            UpdateRequestedByValue={UpdateRequestedByValue}
                            UpdateTitleValue={UpdateTitleValue}
                            UpdateRequirementIdValue={UpdateRequirementIdValue}
                            UpdateCommentValue={UpdateCommentValue}
                            SaveCrMetricDetails={SaveCrMetricDetails}
                            SaveConfiguration={SaveConfiguration}
                            historyFrmCalVal={historyFrmCalVal}
                            historyFrmValChange={historyFrmValChange}
                            historyToCalVal={historyToCalVal}
                            historyToValChange={historyToValChange}
                            heatmapFromCalVal={heatmapFromCalVal}
                            heatmapFromCalValChange={heatmapFromCalValChange}
                            heatmapToCalVal={heatmapToCalVal}
                            heatmapToCalValChange={heatmapToCalValChange}
                            SaveConfig={SaveConfig}
                            UpdateCMRecord={UpdateCMRecord}
                            CMDeleteMetric={CMDeleteMetric}
                            newMetricName={newMetricName}
                            newMetricDisplayName={newMetricDisplayName}
                            newMetricGroup={newMetricGroup}
                            newMetricEnvironment={newMetricEnvironment}
                            NewMetricSavefunction={NewMetricSavefunction}
                            onInputChange={(e, name) => onInputChange(e, name)}
                            SaveNewCMMetric={SaveNewCMMetric}
                            handledMetricEditChange={handledMetricEditChange}
                            handledMetricGraphChange={(status, metricgrpName, metricid, serviceId) => handledMetricGraphChange(status, metricgrpName, metricid, serviceId)}
                            onInputValidExpChange={(e, metricgrpName, metricid) => onInputValidExpChange(e, metricgrpName, metricid)}
                            onInputMinRangeChange={(e, metricgrpName, metricid) => onInputMinRangeChange(e, metricgrpName, metricid)}
                            onInputMaxRangeChange={(e, metricgrpName, metricid) => onInputMaxRangeChange(e, metricgrpName, metricid)}
                            onInputIntervalChange={(e, metricgrpName, metricid) => onInputIntervalChange(e, metricgrpName, metricid)}
                            metricConfigMetricId={metricConfigMetricId}
                            metricConfigGroupName={metricConfigGroupName}
                            isEditableMetricConfig={isEditableMetricConfig}
                            isMetricGraphMetricConfig={isMetricGraphMetricConfig}
                            minRangeMetricConfig={minRangeMetricConfig}
                            maxRangeMetricConfig={maxRangeMetricConfig}
                            intervalMetricConfig={intervalMetricConfig}
                            validationExprMetricConfig={validationExprMetricConfig}
                            onInputMetricConfig={(e, name) => onInputMetricConfig(e, name)}
                            saveMetricConfig={saveMetricConfig}
                            saveMetricConfigTab={saveMetricConfigTab}
                            targetUpdateApprovalRequestData={targetUpdateApprovalRequestData}
                            reviewerCommentsChange={(e, row) => reviewerCommentsChange(e, row)}
                            approveHmRequest={(e, row) => approveHmRequest(e, row)}
                            rejectHmRequest={(e, row) => rejectHmRequest(e, row)}
                        />

                    </div>
                </section>
            </LoadingOverlay>

        </div>

    );
};
const mapStateToProps = (state: IApplicationState) => ({
    ...state.AdminSettings,
    isAuthenticated: state.authUser.isAuthenticated,
    username: state.authUser.login,
    isAdmin: state.authUser.isAdmin

})
export default connect(mapStateToProps, actionCreators)(AdminSettings);
