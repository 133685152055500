import { AuthApi, ServiceGroupApi } from '../../api';
import { IAppThunkAction, ReduxAction } from '../';
import { ActionType, IAuthUser, IPropCalender, IServiceGroup } from './types';
import { CallbackFunction } from '../../types';

export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    setAuthStatus: (login: IAuthUser): ReduxAction => ({
        login,
        type: ActionType.SET_AUTH_STATUS
    }),
    loginUserRequest: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        AuthApi.loginAsync()
            .then((authUser: IAuthUser) => {
                const { login } = authUser;
                if (login !== '') {
                    dispatch({
                        authUser,
                        type: ActionType.LOGIN_SUCCESS
                    });
                } else {
                    dispatch({ type: ActionType.LOGIN_FAIL });
                }
            });
    },

    getDateRefreshed: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const serviceid = getState().authUser.DataRefreshed.length == 0 ? 0 : getState().authUser.serviceId;
        const viewId = getState().authUser.viewId;
        ServiceGroupApi.GetDateForRefreshedData(serviceid, viewId.toString())
            .then((DataRefreshed: IPropCalender[]) => {
                dispatch({
                    DataRefreshed,
                    type: ActionType.DATE_REFRESHED
                });
            });
    },

    getServiceGroups: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        // Build http request and success handler in Promise<void> wrapper
        ServiceGroupApi.getServiceGroupAsyn(0)
            .then((serviceGroup: IServiceGroup[]) => {
                for (var i = 0; i < serviceGroup.length; i++) {
                    serviceGroup[i].IsDirectorChecked = true;
                    serviceGroup[i].IsTeamGroupChecked = true;
                    serviceGroup[i].IServiceGroupChecked = (serviceGroup[i].isWeeklyReport === 'False') ? false : true;
                    serviceGroup[i].IsToggle = true;
                    serviceGroup[i].enableservicescount = 2;
                }
                dispatch({
                    serviceGroup,
                    type: ActionType.GET_SERVICE_GROUP
                });
            });
    },

    logoutUserRequest: (handleRouteCallback: CallbackFunction): IAppThunkAction<ReduxAction> => (dispatch) => {
        //AuthApi.logoutAsync()
        //    .then(() => {
        //        handleRouteCallback();
        //        dispatch({ type: ActionType.RESET_STATE });
        //    });

        dispatch({
            type: ActionType.RESET_STATE
        });
    }
};
