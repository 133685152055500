import React, { useEffect } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import { actionCreators, reducer } from '../../store/MonthlyAvailbility';
import { MonthlyAvailbilityTable } from '../MonthlyAvailbility/child-components';
import LoadingOverlay from 'react-loading-overlay';
import { isArrayWithLength, renderToastifyMsg } from '../../utils';
import { Row, Col, Card, CardText } from 'reactstrap';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import { NewFunctionalityBanner } from '../../components';

type MonthlyAvailbilityProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly isProd: boolean }
    & { readonly history: History }
    & typeof useParams;

const MonthlyAvailbility: React.FC<MonthlyAvailbilityProps> = ({
    isLoading,
    addUserVisit,
    isAuthenticated,
    monthlyStartDate,
    onmonthlyStartDateChange,
    getMonthlyData,
    monthlyData,
    MonthlyheaderArr,
    MonthlyKPiArr,
    IsMonthlyDataLoaded,
    ClearMonthlyDate,
    liKPIValueKeys,
    serviceGroups,
    services,
    clearfilters,
    GetServicegroups,
    GetServices,
    servicegroupval,
    servicegroupvalchange,
    serviceval,
    serviceschange,
    globalsearch,
    searchtextboxchange,
    servicegroupvalstring,
    servicevalstring,
    isDataloaded,
    CopyURL,
    urlparam,
    seturlparamempty,
    isProd,
    getMonthlyDataFilter,
    EditTarget,
    editedTargetData,
    TargetEditPop,
    TargetEditPopClose,
    ChangeTargetValue,
    ChangeJustification,
    originalTargetValue,
    justificationDetails,
    updatedTargetValue,
    SubmitforApproval,
    ClearTarget
}) => {
    useEffect(() => {
        if (isAuthenticated)
            GetServicegroups();
        GetServices();
        addUserVisit(0, 'MonthlyAvailbility.tsx_v2');
        document.querySelectorAll('.react-datepicker-wrapper input').forEach(a => { a.setAttribute('aria-label', 'calender') });
    }, [isAuthenticated]);
    useEffect(() => {
        if (monthlyStartDate) {
            getMonthlyData(monthlyStartDate);
        }
    }, [monthlyStartDate]);
    const FilterMonthlyAvailabilityData = () => {
        getMonthlyDataFilter(monthlyStartDate, servicevalstring);
    }
    const clearfilterdata = () => {
        const servicegroupvals = [];
        let servicegroupvalstrings = '';
        if (isArrayWithLength(serviceGroups)) {
            serviceGroups.map((item, key) => {
                servicegroupvals.push(item.value);
                servicegroupvalstrings = servicegroupvalstrings + ',' + item.value;

            })
        }
        const servicevals = [];
        let servicevalstrings = '';
        if (isArrayWithLength(services)) {
            services.map((item) => {
                servicevals.push(item.value);
                servicevalstrings = servicevalstrings + ',' + item.value;
            })
        }
        clearfilters(servicegroupvals, servicegroupvalstrings, servicevals, servicevalstrings);
        getMonthlyData(monthlyStartDate);
    }

    return (
        <div>
            <NewFunctionalityBanner />
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        <MonthlyAvailbilityTable
                            monthlyStartDate={monthlyStartDate}
                            onmonthlyStartDateChange={onmonthlyStartDateChange}
                            MonthlyData={monthlyData}
                            MonthlyheaderArr={MonthlyheaderArr}
                            MonthlyKPiArr={MonthlyKPiArr}
                            IsMonthlyDataLoaded={IsMonthlyDataLoaded}
                            ClearMonthlyDate={ClearMonthlyDate}
                            liKPIValueKeys={liKPIValueKeys}
                            clearfilterdata={clearfilterdata}
                            serviceGroups={serviceGroups}
                            services={services}
                            servicegroupval={servicegroupval}
                            servicegroupvalchange={servicegroupvalchange}
                            serviceval={serviceval}
                            serviceschange={serviceschange}
                            globalsearch={globalsearch}
                            searchtextboxchange={searchtextboxchange}
                            isDataloaded={isDataloaded}
                            FilterMonthlyAvailabilityData={FilterMonthlyAvailabilityData}
                            CopyURL={CopyURL}
                            urlparam={urlparam}
                            isProd={isProd}
                            editedTargetData={editedTargetData}
                            TargetEditPop={TargetEditPop}
                            TargetEditPopClose={TargetEditPopClose}
                            ChangeTargetValue={(val) => ChangeTargetValue(val)}
                            ChangeJustification={(val) => ChangeJustification(val)}
                            originalTargetValue={originalTargetValue}
                            updatedTargetValue={updatedTargetValue}
                            justificationDetails={justificationDetails}
                            SubmitforApproval={SubmitforApproval}
                            ClearTarget={ClearTarget}
                            EditTarget={(e, TargetName) => EditTarget(e, TargetName)}
                        />
                    </div>
                </section>
                <Modal show={TargetEditPop} onHide={TargetEditPopClose} size='lg' backdrop='static'>
                    <Modal.Header closeButton className="commonModalHeader">
                        <div style={{ width: '100%' }}>
                            <Row>
                                <Col lg='12' style={{ textAlign: 'center' }}>
                                    <h4><b> Update target Value </b></h4>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col lg='12'>
                                <table className="table">
                                    <thead style={{ backgroundColor: 'darkcyan' }}>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan={2} style={{ height: '10px' }}><b>Orginal Target(%)</b></td>
                                            <td colSpan={2} style={{ height: '10px' }}>:</td>
                                            <td colSpan={2} style={{ height: '10px' }}><b> <input type="text" id="originalTarget" value={originalTargetValue} className="form-control" readOnly={true} /></b></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} style={{ height: '10px' }}><span style={{ fontWeight: 'bold', color: 'red' }}>* </span><b>New Target(%)</b></td>
                                            <td colSpan={2} style={{ height: '10px' }}>:</td>
                                            <td colSpan={2} style={{ height: '10px' }}><b> <input type="text" id="newTarget" placeholder='Enter value 99.99%'  value={updatedTargetValue} onChange={e => ChangeTargetValue(e.target.value)} className="form-control" /></b></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} style={{ height: '10px' }}><b>Justification/Comments</b></td>
                                            <td colSpan={2} style={{ height: '10px' }}>:</td>
                                            <td colSpan={2} style={{ height: '10px' }}><b><textarea placeholder='Enter Justification/Comments' className='form-control' value={justificationDetails} onChange={e => ChangeJustification(e.target.value)} /></b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='DailydataModelbuttons btn btn-primary' onClick={SubmitforApproval}>Submit for Approval</Button>&emsp;
                        <Button className='DailydataModelbuttons checklistcloseicon btn btn-primary' onClick={ClearTarget}>Clear</Button>
                    </Modal.Footer>
                </Modal>
            </LoadingOverlay>

        </div>
    );

};
const mapStateToProps = (state: IApplicationState) => ({
    ...state.MonthlyAvailbility,
    isAuthenticated: state.authUser.isAuthenticated,
    isProd: state.authUser.isProd,
})

export default connect(mapStateToProps, actionCreators)(MonthlyAvailbility);
