import React, { useState, Fragment, useRef } from 'react';
import { IPropLastWeekIdNewsLetterData, DropdownOption, IPropLastWeekIdentityTiles, TileDescription, IPropPirCompletion, IPropLastWeekOnCall } from '../../../store/LastWeekIdNewsLetter';
import { faPencilAlt, faTrash, faSave, faBan, faStreetView } from '@fortawesome/free-solid-svg-icons';
import { DataTable } from 'primereact/datatable';
import DatePicker, { getDay } from 'react-datepicker'
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { ReduxAction, IAppThunkAction } from '../../../store';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FontAwesomeIconMemo, Checkbox } from '../../../components';
import { isNullOrUndefined, isArrayWithLength, renderToastifyMsg } from '../../../utils';
import moment from 'moment';
import { Editor } from 'primereact/editor';
import RichTextEditor from 'react-rte';
import { Calendar } from 'primereact/calendar';
import { Card, Row, Col, Container, CardBody } from 'reactstrap';

type LastWeekIdNewsLetterTableProps = {
    WeekStartDate: any;
    readonly GetWeekStartDate: any;
    appSessionToken: any;
    DateRange: any;
    newsLettertopOutagesDataTable: any;
    IdentitynewsletterTopOutagesDataSave: any;
    TopOutageDataEditCancel: any;
    newsLettertopOutagesData: any;
    UpdatetopOutagedata: any;
    RefreshTopOutagesData: any;
    NewsLetterTilesData: any;
    NewsLetterTilesRawData: any;
    NewsLetterTilesCount: any;
    OoslaPIRs: any;
    OoslaSTRI: any;
    titleInputChange: any;
    SaveTileData: any;
    clearTilesEditData: any;
    PirCompletionData: any;
    pirHTMLContent: any;
    SendMailLastWeekIdNewsletterDashboard: any;
    repairItemshtmlContent: any;
    STRepairItemsData: any;
    isAdmin: any;
    lastWeekOnCallData: any;
};

const LastWeekIdNewsLetterTable = React.memo<LastWeekIdNewsLetterTableProps>(({
    WeekStartDate,
    GetWeekStartDate,
    appSessionToken,
    DateRange,
    newsLettertopOutagesDataTable,
    IdentitynewsletterTopOutagesDataSave,
    TopOutageDataEditCancel,
    newsLettertopOutagesData,
    UpdatetopOutagedata,
    RefreshTopOutagesData,
    NewsLetterTilesData,
    NewsLetterTilesRawData,
    NewsLetterTilesCount,
    OoslaPIRs,
    OoslaSTRI,
    titleInputChange,
    SaveTileData,
    clearTilesEditData,
    PirCompletionData,
    pirHTMLContent,
    SendMailLastWeekIdNewsletterDashboard,
    repairItemshtmlContent,
    STRepairItemsData,
    isAdmin,
    lastWeekOnCallData
}) => {

    const refTopOutageTable = useRef(null);
    const [topOutageEdit, settopOutageEdit] = useState(false);
    const [tileEdit, settileEdit] = useState(false);
    const [selectedKey, setSelectedKey] = useState(null);
    const [tilesRawData, settilesRawData] = useState(NewsLetterTilesRawData);
    const [tilesData, settilesData] = useState(NewsLetterTilesData);
    const [newsletterdeleteTopOutageDialog, setnewsletterdeleteTopOutageDialog] = useState(false);

    const isWeekday = (date) => {
        var day = new Date(moment(date).format('MM/DD/YYYY')).getDay();
        return day == 6;
    };

    const TopOutageEdit = () => {
        settopOutageEdit(true);
    };

    const TopOutageDeleteCheck = () => {
        setnewsletterdeleteTopOutageDialog(true);
    };

    const savetopOutageData = () => {
        IdentitynewsletterTopOutagesDataSave();
        settopOutageEdit(false);
    }

    const hideRefreshTopOutageDialog = () => {
        setnewsletterdeleteTopOutageDialog(false);
    }
    const refreshTopOutageConfirmed = () => {
        RefreshTopOutagesData();
        setnewsletterdeleteTopOutageDialog(false);
    }

    const RefreshTopOutageDialogFooter = (
        <React.Fragment>
            <span style={{ display: 'flex', justifyContent: 'center' }}>
                <button className="p-button-text btn-primary" onClick={() => refreshTopOutageConfirmed()}>Yes</button>
                <button className="p-button-text btn-primary" onClick={() => hideRefreshTopOutageDialog()} >No</button>
            </span>
        </React.Fragment>
    );

    const CancelEditTopOutageData = () => {
        settopOutageEdit(false);
        TopOutageDataEditCancel();
    };

    const TileEditClick = (key, dhs) => {
        settileEdit(true);
        setSelectedKey(key);
    }

    const TileEditCancel = () => {
        setSelectedKey(null);
        settileEdit(false);
        settilesData(NewsLetterTilesRawData);
        clearTilesEditData();
    };

    const saveeditedTileData = (key, dhs) => {
        SaveTileData(key, dhs);
        settileEdit(false);
    }

    const sendMail = () => {
        SendMailLastWeekIdNewsletterDashboard();
    }

    const ooslaPIRsBlock = document.querySelector('#ooslaPIRsBlock');
    const ooslaSTRIBlock = document.querySelector('#ooslaSTRIBlock');
    const TOP = document.querySelector('#top');
    const MovetoBlock = (scrollPos) => {
        if (scrollPos == 'ooslaPIRsBlock') {
            ooslaPIRsBlock.scrollIntoView({ block: 'start', behavior: "smooth" });
        }
        else if (scrollPos == 'ooslaSTRIBlock') {
            ooslaSTRIBlock.scrollIntoView({ block: 'start', behavior: "smooth" });
        }
        else if (scrollPos == 'top') {
            TOP.scrollIntoView({ block: 'end', behavior: "smooth" });
        }
    }

    const header = (
        <span className="ql-formats">
            <button className="ql-bold" aria-label="Bold"></button>
            <button className="ql-italic" aria-label="Italic"></button>
            <button className="ql-underline" aria-label="Underline"></button>
            <button className="ql-link" aria-label="Insert Link"></button>
            <button type="button" className="ql-list" value="ordered" aria-label="Ordered List"></button>
            <button type="button" className="ql-list" value="bullet" aria-label="UnOrdered List"></button>
            <span className="ql-align ql-picker ql-icon-picker" style={{ display: 'none' }}></span>
            <select className="ql-align"></select>
            <span className="ql-header ql-picker" style={{ display: 'none' }}></span>
            <select className="ql-header"></select>
            <span className="ql-font ql-picker" style={{ display: 'none' }}></span>
            <select className="ql-font"></select>
        </span>
    );

    return (
        <div>
            <div id="top">
                <Card className="cardstyleAdmin">
                    <CardBody className="cardbodystyleAdmin">
                        <Row>
                            <Col lg="3">
                            </Col>
                            <Col lg="4">
                                <h3 className="newsHeadertext newsheader"> Identity Weekly NewsLetter</h3>
                            </Col>
                            <Col lg="3">
                                <div className="aligntable">
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '60%' }}></td>
                                                <td style={{ width: '40%' }}>
                                                    <div id="DateSelectorDiv" className="btn btn-primary btn-sm filter-button weeklytd">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ width: '15%' }}></td>
                                                                    <td style={{ width: '15%' }}>
                                                                        <div>
                                                                            <label style={{ fontSize: '20px' }} htmlFor='fromdate'> <FontAwesomeIconMemo icon={'calendar'} className='icon-format' /></label>&nbsp;
                                                                        </div>
                                                                    </td>
                                                                    <td style={{ width: '40%' }}>
                                                                        <DatePicker className='InputStyle'
                                                                            id={'fromdate'}
                                                                            selected={WeekStartDate}
                                                                            onChange={GetWeekStartDate}
                                                                            filterDate={isWeekday}
                                                                            maxDate={new Date()}
                                                                            minDate={new Date(2018, 11, 1)}
                                                                        />  &nbsp;&nbsp;
                                                                    </td>
                                                                    <td style={{ width: '30%' }}></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                            <Col lg="1" className="alignmailbtn"><button type="button" className="btn btn-primary btn-sm filter-button sendmailbtn" onClick={() => sendMail()}>Send Mail</button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
            <div>
                <Row className="idnacardheader">
                    <Col lg="6" style={{ textAlign: 'left' }}>
                        <div style={{ marginTop: '25px' }}>
                            <h1 className="headertitle"> Last week in Identity Livesite </h1>
                        </div>
                    </Col>
                    <Col lg="6" style={{ textAlign: 'right' }}>
                        <FontAwesomeIcon style={{ color: 'white' }} title='users' icon={'users'} className='usersicon' />
                    </Col>
                </Row>
                <Row className="idnacardsubheader">
                    <Col lg="12" className="idnacardnavdetails">
                        IDNA | Entra Core Services | LiveSite
                    </Col>
                </Row>
            </div>
            <br />
            <div>
                <div className="newsContentHeader">
                    <h3 className="newsContentHeader">Identity Team weekly newsletter: {DateRange}</h3>
                </div>
            </div>
            <br />
            <div>
                <Row>
                    <Col sm="12">
                        <div className="redirectLinks" style={{ float: 'left' }}>
                            <a onClick={() => MovetoBlock('ooslaPIRsBlock')}><strong>OOSLA PIRs |</strong></a>&nbsp;
                            <a onClick={() => MovetoBlock("ooslaSTRIBlock")}><strong>OOSLA STRI</strong></a>&nbsp;
                        </div>
                    </Col>
                </Row>
            </div>
            <div>
                {
                    isArrayWithLength(NewsLetterTilesData) ?
                        <React.Fragment>
                            <div>
                                <Row className="tileTopRow">
                                    <Col className="emptydiv">&ensp;&ensp;</Col>
                                    {
                                        NewsLetterTilesData.map((dhs: IPropLastWeekIdentityTiles, key) => (
                                            <Col lg="3" className="tileTopCol" key={key}>
                                                <div>
                                                    <div>
                                                        {tileEdit && selectedKey === key ? (
                                                            <React.Fragment>
                                                                <span>
                                                                    <input placeholder="Header Title" value={dhs.kpI_Header} onChange={(e) => titleInputChange(key, 'kpI_Header', e.target.value)} /> &ensp;
                                                                    <br />
                                                                </span>
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
                                                                <span className="tileHeader"> {dhs.kpI_Header}&ensp;&ensp;</span>
                                                            </React.Fragment>
                                                        )}
                                                        {tileEdit && selectedKey === key ? (
                                                            <React.Fragment>
                                                                <span style={{ textAlign: 'right' }}>
                                                                    <span>
                                                                        <FontAwesomeIcon title='Save Changes' onClick={e => saveeditedTileData(key, dhs)} className=" IconsstyleTopOutages" icon={faSave} />
                                                                    </span>&ensp;
                                                                    <span>
                                                                        <FontAwesomeIcon title='Cancel Edit' onClick={() => TileEditCancel()} className=" IconsstyleTopOutages" icon={faBan} />
                                                                    </span>&ensp;
                                                                </span>
                                                            </React.Fragment>
                                                        ) : (isAdmin === true) ?
                                                            <React.Fragment>
                                                                <span onClick={() => TileEditClick(key, dhs)} style={{ float: 'right' }}>
                                                                    <FontAwesomeIcon title="Click to edit the Top Outages" className="" icon={faPencilAlt} />
                                                                </span>
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                            </React.Fragment>
                                                        }
                                                    </div><br />
                                                    <div>
                                                        <div className="tileBox">
                                                            {tileEdit && selectedKey === key ? (
                                                                <React.Fragment>
                                                                    <div>
                                                                        <input placeholder="Section1 Value" value={NewsLetterTilesData[key].kpI_Section1_Value} onChange={(e) => titleInputChange(key, 'kpI_Section1_Value', e.target.value)} />
                                                                        <input placeholder="Section1 Title" style={{ marginTop: '10px' }} onChange={(e) => titleInputChange(key, 'kpI_Section1', e.target.value)} value={NewsLetterTilesData[key].kpI_Section1} />
                                                                        <input placeholder="Section1 Link" style={{ marginTop: '10px' }} onChange={(e) => titleInputChange(key, 'kpI_Section1_Links', e.target.value)} value={NewsLetterTilesData[key].kpI_Section1_Links} />
                                                                    </div>
                                                                </React.Fragment>
                                                            ) : (
                                                                <div>
                                                                    <div className="tileValue">{dhs.kpI_Section1_Value}<br /></div>
                                                                    <div className="tileInfo">{dhs.kpI_Section1}<br /></div>
                                                                    <div>
                                                                        {!isNullOrUndefined(dhs.kpI_Section1_DIFF) ? <React.Fragment>
                                                                                {(dhs.kpI_Id != 5 && dhs.kpI_Id != 4) ? <React.Fragment>
                                                                                <div style={{ display: 'inline-flex', alignItems: 'center', height: '50px' }}>
                                                                                    {(dhs.kpI_Section1_SIGN == 'Positive') ? <React.Fragment>
                                                                                        <div style={{ width: '0', height: '0', borderLeft: '10px solid transparent', borderRight: '10px solid transparent', borderBottom: '10px solid green' }}></div> &nbsp;&nbsp;
                                                                                    </React.Fragment> : <React.Fragment></React.Fragment>}
                                                                                    {(dhs.kpI_Section1_SIGN == 'Negative') ? <React.Fragment>
                                                                                        <div style={{ width: '0', height: '0', borderLeft: '10px solid transparent', borderRight: '10px solid transparent', borderTop: '10px solid red' }}></div> &nbsp;&nbsp;
                                                                                    </React.Fragment> : <React.Fragment></React.Fragment>}
                                                                                    <div>{dhs.kpI_Section1_DIFF}</div>
                                                                                </div>
                                                                            </React.Fragment> : <React.Fragment>
                                                                                <div style={{ display: 'inline-flex', alignItems: 'center', height: '50px' }}>
                                                                                    {(dhs.kpI_Section1_SIGN == 'Positive') ? <React.Fragment>
                                                                                        <div style={{ width: '0', height: '0', borderLeft: '10px solid transparent', borderRight: '10px solid transparent', borderBottom: '10px solid red' }}></div> &nbsp;&nbsp;
                                                                                    </React.Fragment> : <React.Fragment></React.Fragment>}
                                                                                    {(dhs.kpI_Section1_SIGN == 'Negative') ? <React.Fragment>
                                                                                        <div style={{ width: '0', height: '0', borderLeft: '10px solid transparent', borderRight: '10px solid transparent', borderTop: '10px solid green' }}></div> &nbsp;&nbsp;
                                                                                    </React.Fragment> : <React.Fragment></React.Fragment>}
                                                                                    <div>{dhs.kpI_Section1_DIFF}</div>
                                                                                </div>
                                                                            </React.Fragment>}
                                                                        </React.Fragment> : <React.Fragment></React.Fragment>}
                                                                    </div>
                                                                    <div className="tileLink">
                                                                        {!isNullOrUndefined(dhs.kpI_Section1_Links) ? <React.Fragment><a href={dhs.kpI_Section1_Links} target="_blank"> Viva Goals </a></React.Fragment> : <React.Fragment></React.Fragment>}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <br />
                                                        <div className="tileBox">
                                                            {tileEdit && selectedKey === key ? (
                                                                <React.Fragment>
                                                                    <div>
                                                                        <input placeholder="Section2 Value" value={dhs.kpI_Section2_Value} onChange={(e) => titleInputChange(key, 'kpI_Section2_Value', e.target.value)} />
                                                                        <input placeholder="Section2 Title" style={{ marginTop: '10px' }} onChange={(e) => titleInputChange(key, 'kpI_Section2', e.target.value)} value={dhs.kpI_Section2} />
                                                                        <input placeholder="Section2 Link" style={{ marginTop: '10px' }} onChange={(e) => titleInputChange(key, 'kpI_Section2_Links', e.target.value)} value={dhs.kpI_Section2_Links} />
                                                                    </div>
                                                                </React.Fragment>
                                                            ) : (
                                                                <div>
                                                                    <div className="tileValue">{dhs.kpI_Section2_Value}<br /></div>
                                                                    <div className="tileInfo">{dhs.kpI_Section2}<br /></div>
                                                                    <div>
                                                                        {!isNullOrUndefined(dhs.kpI_Section2_DIFF) ? <React.Fragment>
                                                                                {(dhs.kpI_Id != 4) ? <React.Fragment>
                                                                                    <div style={{ display: 'inline-flex', alignItems: 'center', height: '50px' }}>
                                                                                        {(dhs.kpI_Section2_SIGN == 'Positive') ? <React.Fragment>
                                                                                            <div style={{ width: '0', height: '0', borderLeft: '10px solid transparent', borderRight: '10px solid transparent', borderBottom: '10px solid green' }}></div> &nbsp;&nbsp;
                                                                                        </React.Fragment> : <React.Fragment></React.Fragment>}
                                                                                        {(dhs.kpI_Section2_SIGN == 'Negative') ? <React.Fragment>
                                                                                            <div style={{ width: '0', height: '0', borderLeft: '10px solid transparent', borderRight: '10px solid transparent', borderTop: '10px solid red' }}></div> &nbsp;&nbsp;
                                                                                        </React.Fragment> : <React.Fragment></React.Fragment>}
                                                                                        <div>{dhs.kpI_Section2_DIFF}</div>
                                                                                    </div>
                                                                                </React.Fragment> : <React.Fragment>
                                                                                    <div style={{ display: 'inline-flex', alignItems: 'center', height: '50px' }}>
                                                                                        {(dhs.kpI_Section2_SIGN == 'Positive') ? <React.Fragment>
                                                                                            <div style={{ width: '0', height: '0', borderLeft: '10px solid transparent', borderRight: '10px solid transparent', borderBottom: '10px solid red' }}></div> &nbsp;&nbsp;
                                                                                        </React.Fragment> : <React.Fragment></React.Fragment>}
                                                                                        {(dhs.kpI_Section2_SIGN == 'Negative') ? <React.Fragment>
                                                                                            <div style={{ width: '0', height: '0', borderLeft: '10px solid transparent', borderRight: '10px solid transparent', borderTop: '10px solid green' }}></div> &nbsp;&nbsp;
                                                                                        </React.Fragment> : <React.Fragment></React.Fragment>}
                                                                                        <div>{dhs.kpI_Section2_DIFF}</div>
                                                                                    </div>
                                                                                </React.Fragment>}
                                                                        </React.Fragment> : <React.Fragment></React.Fragment>}
                                                                    </div>
                                                                    <div className="tileLink">
                                                                        {!isNullOrUndefined(dhs.kpI_Section2_Links) ? <React.Fragment><a href={dhs.kpI_Section2_Links} target="_blank"> Viva Goals </a></React.Fragment> : <React.Fragment></React.Fragment>}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <br />
                                                        <div>
                                                            {!isNullOrUndefined(dhs.kpI_Section3_Value) && (
                                                                <React.Fragment>
                                                                    {tileEdit && selectedKey === key ? (
                                                                        <React.Fragment>
                                                                            <div>
                                                                                <input placeholder="Section3m Value" value={dhs.kpI_Section3_Value} onChange={(e) => titleInputChange(key, 'kpI_Section3_Value', e.target.value)} />
                                                                                <input placeholder="Section3m Title" style={{ marginTop: '10px' }} onChange={(e) => titleInputChange(key, 'kpI_Section3', e.target.value)} value={dhs.kpI_Section3} />
                                                                                <input placeholder="Section3m Link" style={{ marginTop: '10px' }} onChange={(e) => titleInputChange(key, 'kpI_Section3_Links', e.target.value)} value={dhs.kpI_Section3_Links} />
                                                                            </div>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        <div>
                                                                            <div className="tileValue">{dhs.kpI_Section3_Value}<br /></div>
                                                                            <div className="tileInfo">{dhs.kpI_Section3}<br /></div>
                                                                            <div className="tileLink">{dhs.kpI_Section3_Links}<br /></div>
                                                                        </div>
                                                                    )}
                                                                </React.Fragment>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        ))
                                    }

                                </Row>
                                <Row className="noDays">
                                    <Col><span> Above OKR includes 90 days rolling data </span> </Col>
                                </Row>
                            </div>
                        </React.Fragment>
                        :
                        <React.Fragment>No Data Found !!</React.Fragment>
                }
            </div>


            <div>
                <Row className="topOutageNewsHeader">
                    <Col lg="10">
                        <h4 style={{ textAlign: 'center' }}> Top Outages</h4>
                    </Col>

                    <Col lg="2" className="paddingEditSectionTopOutages">

                        {(isAdmin === true) ?
                            (!topOutageEdit)
                                ?
                                <div style={{ textAlign: 'right' }}>
                                    <React.Fragment>
                                        <button type="button" className="btn btn-primary btn-sm filter-button refreshBtn" onClick={(e) => TopOutageDeleteCheck()}>Refresh Latest Kusto Data</button>
                                        &ensp;&ensp;
                                    </React.Fragment>
                                    <span onClick={e => TopOutageEdit()}>
                                        <FontAwesomeIcon title='Click to edit the Top Outages' className=" IconsstyleTopOutages" icon={faPencilAlt} />
                                    </span>&ensp;
                                </div>
                                :
                                <div style={{ textAlign: 'right' }}>
                                    <span onClick={e => savetopOutageData()}>
                                        <FontAwesomeIcon title='Save Changes' className=" IconsstyleTopOutages" icon={faSave} />
                                    </span>&ensp;
                                    <span onClick={e => CancelEditTopOutageData()}>
                                        <FontAwesomeIcon title='Cancel Edit' className=" IconsstyleTopOutages" icon={faBan} />
                                    </span>
                                </div>
                            : <React.Fragment></React.Fragment>
                        }

                    </Col>
                </Row>
                <div id="TopOutages" style={{ width: '100%' }}>
                    {
                        !(isNullOrUndefined(newsLettertopOutagesDataTable)) ?
                            <React.Fragment>
                                <div>
                                    <Row style={{ width: '100%' }}>
                                        <Col lg="12">                                       
                                            <div ref={refTopOutageTable}></div>
                                        </Col>
                                    </Row>

                                    <Row style={{ width: '100%' }}>
                                        <Col lg="12">                                        
                                            <Row>
                                                {
                                                    (!topOutageEdit)
                                                        ?
                                                        <React.Fragment>
                                                            <Col lg="12">
                                                                <table className="topOutageTableOuter">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                < br />
                                                                                <div className="NewsLetterRTELIdots topOutageTableIncidentsFormat" dangerouslySetInnerHTML={{ __html: newsLettertopOutagesData.topOutageTable }}></div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="topOutageTableSummaryTD">
                                                                                < br />
                                                                                <div className="NewsLetterRTELIdots topOutageTableIncidentsFormat" dangerouslySetInnerHTML={{ __html: newsLettertopOutagesData.incidentNotes }}></div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                < br />
                                                                                <div className="NewsLetterRTELIdots topOutageTableIncidentsFormat" dangerouslySetInnerHTML={{ __html: newsLettertopOutagesData.oncallTeamTable }}></div>
                                                                            </td>
                                                                        </tr>                                                                
                                                                        <tr>
                                                                            <td>
                                                                                < br />
                                                                                <div className="NewsLetterRTELIdots topOutageTableIncidentsFormat" dangerouslySetInnerHTML={{ __html: newsLettertopOutagesData.topOutageOpen }}></div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </Col>
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            <Col lg="12">
                                                                <table className="topOutageTable">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td >
                                                                                < br />
                                                                                <Editor placeholder="Incident Notes" value={newsLettertopOutagesData.incidentNotes} onTextChange={(event) => UpdatetopOutagedata(event, 'incidentNotes')} headerTemplate={header} />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </Col>
                                                        </React.Fragment>
                                                }
                                            </Row>                                     
                                        </Col>
                                    </Row>
                                </div>
                            </React.Fragment>
                            : <React.Fragment>No Data Found !!</React.Fragment>
                    }
                </div>     
                <div id="pirClosedBlock">
                    <Row className="topOutageNewsHeader">
                        <Col lg="12">
                            <h4 style={{ textAlign: 'left' }}> PIRs Closed</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <div>
                                {
                                    (!isNullOrUndefined(newsLettertopOutagesData.pirClosed)) ?
                                        <React.Fragment>                                                                                        
                                            <div className="NewsLetterRTELIdots topOutageTableIncidentsFormat" dangerouslySetInnerHTML={{ __html: newsLettertopOutagesData.pirClosed }}></div>
                                        </React.Fragment>
                                        : <React.Fragment>No Data Found !!</React.Fragment>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
                <br />
                <div id="ooslaPIRsBlock">
                    <Row className="topOutageNewsHeader">
                        <Col lg="12">
                            <h4 style={{ textAlign: 'left' }}> OOSLA PIRs</h4>
                        </Col>
                    </Row>
                    {
                        !(isNullOrUndefined(OoslaPIRs)) ?
                            <React.Fragment>
                                <div>
                                    {
                                        OoslaPIRs.map((dhs: TileDescription, key) => (
                                            <div key={key}>
                                                <div>{dhs.title}</div>
                                                <br />
                                                <div>{dhs.description}</div>
                                                <br />
                                                <div>Report Link : <a href={dhs.reportlink} target="_blank"> PIR Completion </a></div>
                                            </div>

                                        ))
                                    }
                                </div>
                            </React.Fragment>
                            : <React.Fragment>No Data Found !!</React.Fragment>
                    }
                    <br />
                    <Row>
                        <Col lg="12">
                            <div>
                                {
                                    (!isNullOrUndefined(pirHTMLContent)) ?
                                        <React.Fragment>
                                            <div className="NewsLetterRTELIdots topOutageTableIncidentsFormat" dangerouslySetInnerHTML={{ __html: pirHTMLContent }}></div>
                                        </React.Fragment>
                                        : <React.Fragment>No Data Found !!</React.Fragment>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
                < br />
                <div id="ooslaSTRIBlock">
                    <Row className="topOutageNewsHeader">
                        <Col lg="12">
                            <h4 style={{ textAlign: 'left' }}> OOSLA Short Term Repair Items (STRI)</h4>
                        </Col>
                    </Row>
                    {
                        !(isNullOrUndefined(OoslaSTRI)) ?
                            <React.Fragment>
                                <div>
                                    {
                                        OoslaSTRI.map((dhs: TileDescription, key) => (
                                            <div key={key}>
                                                <div>{dhs.title}</div>
                                                <br />
                                                <div>{dhs.description}</div>
                                                <br />
                                                <div>Report Link: <a href={dhs.reportlink} target="_blank"> Repair Items Overview </a></div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </React.Fragment>
                            : <React.Fragment>No Data Found !!</React.Fragment>
                    }
                    <br />
                    <br />
                    <Row>
                        <Col lg="12">
                            <div>
                                {
                                    (!isNullOrUndefined(repairItemshtmlContent)) ?
                                        <React.Fragment>
                                            <div className="NewsLetterRTELIdots topOutageTableIncidentsFormat" dangerouslySetInnerHTML={{ __html: repairItemshtmlContent }}></div>
                                        </React.Fragment>
                                        : <React.Fragment>No Data Found !!</React.Fragment>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            < br />
            <Row>
                <Col sm="12">
                    <div className="redirectLinks" style={{ float: 'right' }}>
                        &nbsp;&nbsp;
                        <a className="btn btn-primary btn-sm filter-hmbutton pirscrollbuttons" onClick={() => MovetoBlock('top')}><strong>Move Top</strong></a>&nbsp;&nbsp;
                    </div>
                </Col>
            </Row>
            <Dialog visible={newsletterdeleteTopOutageDialog} style={{ width: '450px' }} header="Confirm" modal footer={RefreshTopOutageDialogFooter} onHide={hideRefreshTopOutageDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', color: 'orange' }} />
                    <span>Are you sure you want to Refresh the Top Outage Data?</span>
                </div>
            </Dialog>
        </div >
    )
});

LastWeekIdNewsLetterTable.displayName = 'LastWeekIdNewsLetterTable';
export default LastWeekIdNewsLetterTable;