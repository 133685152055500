import { BaseService } from './base.service';
import { IPropRepairItemsAllServices, IPropTicketReport, IPropTicketDetails } from '../store/actionItems';

/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class ActionItemsService extends BaseService {
    private static _serviceGroupService: ActionItemsService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): ActionItemsService {
        return (this._serviceGroupService || (this._serviceGroupService = new this('ActionItems')));
    }

    public async GetItemDetails(weekStartDate: string): Promise<IPropRepairItemsAllServices[]> {
        const { data } = await this.$http.get<IPropRepairItemsAllServices[]>(`GetItemDetails?weekStartDate=${weekStartDate}`);
        return data;
    }

    public async GetItemDetailsGraph(weekStartDate: string): Promise<IPropTicketReport[]> {
        const { data } = await this.$http.get<IPropTicketReport[]>(`GetItemDetailsGraph?weekStartDate=${weekStartDate}`);
        return data;
    }
    public async GetItemDetailsData(weekStartDate: string): Promise<IPropTicketDetails[]> {
        const { data } = await this.$http.get<IPropTicketDetails[]>(`GetItemDetailsData?weekStartDate=${weekStartDate}`);
        return data;
    }

   
}

export const ActionItemsAPI = ActionItemsService.Instance;
