import { BaseService } from './base.service';
import { IPropEscalationDirectoryData } from '../store/EscalationDirectory';

/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class EscalationDirectoryService extends BaseService {
    private static _serviceGroupService: EscalationDirectoryService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): EscalationDirectoryService {
        return (this._serviceGroupService || (this._serviceGroupService = new this('EscalationDirectory')));
    }

    public async GetEscalationDirectory(isActive): Promise<IPropEscalationDirectoryData[]> {
        const { data } = await this.$http.get<IPropEscalationDirectoryData[]>(`GetEscalationDirectory?isActive=${isActive}`);
        return data;
    }

    public async SaveEscalationDirectory(objEscalation: IPropEscalationDirectoryData): Promise<number> {
        const { data } = await this.$http.post<number>(`SaveEscalationDirectory`, objEscalation);
        return data
    }

}

export const EscalationDirectoryAPI = EscalationDirectoryService.Instance;
