import { CommonAPI, InternalEscalationAPI } from '../../api';
import { IAppThunkAction, ReduxAction } from '../';
import { ActionType, DropdownOption } from './types';
import { isNullOrUndefined, isArrayWithLength } from '../../utils';
export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }

    },
    handleCloudChange: (selectedCloud: DropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            selectedCloud,
            type: ActionType.CLOUD_CHANGE
        });
    },
    handleServiceLifeCycleChange: (selectedServiceLifeCycle: DropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            selectedServiceLifeCycle,
            type: ActionType.SERVICELIFECYCLE_CHANGE
        });
    },
    handleClassificationChange: (selectedClassification: DropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            selectedClassification,
            type: ActionType.CLASSIFICATION_CHANGE
        });
    },
    handleServiceGroupChange: (selectedServiceGroupName: DropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            selectedServiceGroupName,
            type: ActionType.SERVICEGROUP_CHANGE
        });
    },
    handleTenantChange: (selectedTenant: DropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            selectedTenant,
            type: ActionType.TENANT_CHANGE
        });
    },
    handleImorOceChange: (selectedImorOce: DropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            selectedImorOce,
            type: ActionType.IMOROCE_CHANGE
        });
    },
    handleIdentityServiceClassificationTypeChange: (selectedIdentityServiceClassificationType: DropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            selectedIdentityServiceClassificationType,
            type: ActionType.SELECTEDIDENTITYSERVICECLASSIFICATIONTYPE_CHANGE
        });
    },
    handleTeamGroupChange: (selectedTeamGroupName: DropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            selectedTeamGroupName,
            type: ActionType.TEAMGROUP_CHANGE
        });
    },
    GetDropdownListDataInternalEscalation: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.GET_INTERNALESCALATION_REQUEST
        });

        InternalEscalationAPI.GetRbAlerts()
            .then((RbAlertsRawdata: any) => {
                let rbRequestAssistanceList = [];
                let rbAlertList = [];
                if (RbAlertsRawdata.table.length > 0) {
                    rbAlertList = RbAlertsRawdata.table;   
                }

                if (rbAlertList.length > 0) {
                    rbRequestAssistanceList = RbAlertsRawdata.table1;

                    rbRequestAssistanceList.map((row) => {
                        row.Changed = false;
                    })
                }
                dispatch({
                    rbRequestAssistanceList: rbRequestAssistanceList,
                    type: ActionType.UPDATE_RBREQUESTASSISTANCELIST_SUCCESS
                });
            });

        InternalEscalationAPI.GetRequestAssistanceFilterClassificationLifeCycle()
            .then((InternalEsctemplatedata: any) => {
                let classificationListRaw = [];
                let classificationList = [];
                let serviceLifeCycleRaw = [];
                let serviceLifeCycle = [];
                let serviceGroupNameListRaw = [];
                let serviceGroupNameList = [];
                let tenantListRaw = [];
                let tenantList = [];
                let teamGroupNameListRaw = [];
                let teamGroupNameList = [];
                if (!isNullOrUndefined(InternalEsctemplatedata)) {
                    classificationListRaw = InternalEsctemplatedata.table;
                    serviceLifeCycleRaw = InternalEsctemplatedata.table1
                    serviceGroupNameListRaw = InternalEsctemplatedata.table2;
                    tenantListRaw = InternalEsctemplatedata.table3;
                    teamGroupNameListRaw = InternalEsctemplatedata.table4;
                }

                if (isArrayWithLength(classificationListRaw)) {
                    classificationListRaw.map((item) => {
                        classificationList.push({ name: item.classificationType, value: item.classificationType });
                    })
                }

                if (isArrayWithLength(serviceLifeCycleRaw)) {
                    serviceLifeCycleRaw.map((item) => {
                        serviceLifeCycle.push({ name: item.serviceLifeCycle, value: item.serviceLifeCycle });
                    })
                }

                if (isArrayWithLength(serviceGroupNameListRaw)) {
                    serviceGroupNameListRaw.map((item) => {
                        serviceGroupNameList.push({ name: item.serviceGroupName, value: item.serviceGroupName });
                    })
                }

                if (isArrayWithLength(tenantListRaw)) {
                    tenantListRaw.map((item) => {
                        tenantList.push({ name: item.tenantName, value: item.tenantName });
                    })
                }

                if (isArrayWithLength(teamGroupNameListRaw)) {
                    teamGroupNameListRaw.map((item) => {
                        teamGroupNameList.push({ name: item.teamGroupName, value: item.teamGroupName });
                    })
                }
                // Calling GetOnCallContactList Action Method To load the OnCall Data
                CallGetOnCallContactList(dispatch, 0);
                dispatch({
                    classificationList: classificationList,
                    serviceLifeCycle: serviceLifeCycle,
                    serviceGroupNameList: serviceGroupNameList,
                    tenantList: tenantList,
                    teamGroupNameList: teamGroupNameList,
                    type: ActionType.GET_INTERNALESCALATION_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.GET_INTERNALESCALATION_FAIL
                });
            });
    },
    clearDropdownFilter: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let selectedCloud = 'All';
        let selectedServiceLifeCycle = [];
        let selectedClassification = [];
        let selectedServiceGroupName = [];
        let selectedTeamGroupName = [];
        let selectedTenant = [];
        let selectedImorOce = 'All';
        let selectedIdentityServiceClassificationType = 'All';
        dispatch({
            selectedCloud: selectedCloud,
            selectedServiceLifeCycle: selectedServiceLifeCycle,
            selectedClassification: selectedClassification,
            selectedServiceGroupName: selectedServiceGroupName,
            selectedTeamGroupName: selectedTeamGroupName,
            selectedTenant: selectedTenant,
            selectedImorOce: selectedImorOce,
            selectedIdentityServiceClassificationType: selectedIdentityServiceClassificationType,
            type: ActionType.CLEAR_INTERNALESCALATION_DROPDOWNFILTERVALUES
        });
        // Calling GetOnCallContactList Action Method To load the OnCall Data
        CallGetOnCallContactList(dispatch, 0);

    },
    getOnCallContactList: (option: any): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let selectedCloud = getState().InternalEscalation.selectedCloud;
        let selectedServiceLifeCycle = getState().InternalEscalation.selectedServiceLifeCycle;
        let selectedClassification = getState().InternalEscalation.selectedClassification;
        let selectedServiceGroupName = getState().InternalEscalation.selectedServiceGroupName;
        let selectedTeamGroupName = getState().InternalEscalation.selectedTeamGroupName;
        let selectedTenant = getState().InternalEscalation.selectedTenant;
        let selectedImorOce = getState().InternalEscalation.selectedImorOce;
        let copyEmailselectedImorOce = getState().InternalEscalation.copyEmailselectedImorOce;
        let selectedIdentityServiceClassificationType = getState().InternalEscalation.selectedIdentityServiceClassificationType;
        let onCallData = getState().InternalEscalation.onCallData;
        let filteredonCallData = getState().InternalEscalation.filteredonCallData;
        let rbRequestAssistanceList = getState().InternalEscalation.rbRequestAssistanceList;
        let defaultexpandrows = getState().InternalEscalation.defaultexpandrows;
        let classification = '';
        let lifeCycle = '';
        let groupName = '';
        let tenantName = '';
        let teamGroupName = '';
        // Update the label only upon filter click
        copyEmailselectedImorOce = selectedImorOce;
        if (selectedServiceLifeCycle.length > 0) {
            selectedServiceLifeCycle.map((obj) => {
                lifeCycle = lifeCycle + obj + '~';
            })
            lifeCycle = lifeCycle.trim().substring(0, lifeCycle.trim().length - 1);
        }

        if (selectedClassification.length > 0) {
            selectedClassification.map((obj) => {
                classification = classification + obj + '~';
            })
            classification = classification.trim().substring(0, classification.trim().length - 1);
        }

        if (selectedServiceGroupName.length > 0) {
            selectedServiceGroupName.map((obj) => {
                groupName = groupName + obj + '~';
            })
            groupName = groupName.trim().substring(0, groupName.trim().length - 1);
        }

        if (selectedTenant.length > 0) {
            selectedTenant.map((obj) => {
                tenantName = tenantName + obj + '~';
            })
            tenantName = tenantName.trim().substring(0, tenantName.trim().length - 1);
        }

        if (selectedTeamGroupName.length > 0) {
            selectedTeamGroupName.map((obj) => {
                teamGroupName = teamGroupName + obj + '~';
            })
            teamGroupName = teamGroupName.trim().substring(0, teamGroupName.trim().length - 1);
        }
        let depInfra = "";
        var obj = {
            'selectedCloud': selectedCloud,
            'selectedImorOce': selectedImorOce,
            'classification': classification,
            'lifeCycle': lifeCycle,
            'groupName': groupName,
            'tenantName': tenantName,
            'depInfra': depInfra,
            'IdentityServiceClassification': selectedIdentityServiceClassificationType,
            'teamGroupName': teamGroupName
        };
        InternalEscalationAPI.GetOnCallContactList(obj)
            .then((InternalEscTempdata: any) => {
                let temp: any = [];
                let onCallData = [];
                temp = InternalEscTempdata;
                if (option === 0) {
                    temp.map((contact: any) => {
                        if (contact.children !== undefined) {
                            contact.children.map((r: any) => {
                                rbRequestAssistanceList.map((row: any) => {
                                    if ((r.data.OnCallAlias === row.OnCallAlias) && r.data.OnCallName !== 'Not Defined') {
                                        r.data.IsExist = true;
                                        r.data.selectable = false;
                                    }
                                });
                            });

                        }
                    });
                } else if (option == 1) {
                    temp.map((contact: any) => {
                        if (contact.children !== undefined) {
                            contact.children.map((r: any) => {
                                if (r.data.OnCallName !== 'Not Defined') {
                                    r.data.IsExist = true;
                                }
                                r.data.CallAgain = '2';
                                rbRequestAssistanceList.map((row: any) => {
                                    if ((r.data.OnCallAlias === row.OnCallAlias) && r.data.OnCallName !== 'Not Defined') {
                                        r.data.IsExist = false;
                                        r.data.selectable = true;
                                        r.data.CallAgain = '1';
                                    }
                                });
                            });

                        }
                    });
                }
                onCallData = temp;
                onCallData.forEach(p => defaultexpandrows[`${p.data.Name}`] = true);
                dispatch({
                    onCallData: onCallData,
                    filteredonCallData: onCallData,
                    defaultexpandrows: defaultexpandrows,
                    copyEmailselectedImorOce: copyEmailselectedImorOce,
                    type: ActionType.UPDATE_ONCALLDATA_SUCCESS
                });
                dispatch({
                    type: ActionType.SET_LOADING_FALSE
                });
            });

    },
    OpenEmailPOPUP: (globalFilteredData, globalFilter): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let OnCallAliasEmailIDs = "";
        let BackupOnCallAliasEmailIDs = "";
        let filteredonCallData = getState().InternalEscalation.filteredonCallData;
        if (isArrayWithLength(globalFilteredData)) {
            filteredonCallData.map((contact: any) => {
                globalFilteredData.map((item: any) => {
                    if (item === contact.data.Name) {
                        if (contact.children !== undefined) {
                            contact.children.map((r: any) => {
                                //============= for getting OnCall Alias======================// 
                                if (r.data.OnCallName && r.data.OnCallName !== 'Not Defined') {
                                    if (OnCallAliasEmailIDs === "") {
                                        OnCallAliasEmailIDs = r.data.OnCallEmailAddress;
                                    }
                                    else if (OnCallAliasEmailIDs.indexOf(r.data.OnCallEmailAddress) === -1) {
                                        OnCallAliasEmailIDs = OnCallAliasEmailIDs + " ; " + r.data.OnCallEmailAddress;
                                    }
                                }
                                //================= for getting BackUp OnCall Alias ==================//
                                if (r.data.BackupOnCallName && r.data.BackupOnCallName !== 'Not Defined') {
                                    if (BackupOnCallAliasEmailIDs === "") {
                                        BackupOnCallAliasEmailIDs = r.data.BOnCallEmailAddress;
                                    }
                                    else if (BackupOnCallAliasEmailIDs.indexOf(r.data.BOnCallEmailAddress) === -1) {
                                        BackupOnCallAliasEmailIDs = BackupOnCallAliasEmailIDs + " ; " + r.data.BOnCallEmailAddress;
                                    }
                                }
                            });
                        }
                    }
                });
            });
        }
        else if (globalFilter !== "" && !(isArrayWithLength(globalFilteredData))) {
            OnCallAliasEmailIDs = "";
            BackupOnCallAliasEmailIDs = "";
        }
        else {
            filteredonCallData.map((contact: any) => {
                        if (contact.children !== undefined) {
                            contact.children.map((r: any) => {
                                //============= for getting OnCall Alias======================// 
                                if (r.data.OnCallName && r.data.OnCallName !== 'Not Defined') {
                                    if (OnCallAliasEmailIDs === "") {
                                        OnCallAliasEmailIDs = r.data.OnCallEmailAddress;
                                    }
                                    else if (OnCallAliasEmailIDs.indexOf(r.data.OnCallEmailAddress) === -1) {
                                        OnCallAliasEmailIDs = OnCallAliasEmailIDs + " ; " + r.data.OnCallEmailAddress;
                                    }
                                }
                                //================= for getting BackUp OnCall Alias ==================//
                                if (r.data.BackupOnCallName && r.data.BackupOnCallName !== 'Not Defined') {
                                    if (BackupOnCallAliasEmailIDs === "") {
                                        BackupOnCallAliasEmailIDs = r.data.BOnCallEmailAddress;
                                    }
                                    else if (BackupOnCallAliasEmailIDs.indexOf(r.data.BOnCallEmailAddress) === -1) {
                                        BackupOnCallAliasEmailIDs = BackupOnCallAliasEmailIDs + " ; " + r.data.BOnCallEmailAddress;
                                    }
                                }
                            });
                        }
            });
        }
        dispatch({
            OnCallAliasEmailIDs,
            BackupOnCallAliasEmailIDs,
            type: ActionType.OPEN_EMAIL_POPUP
        });
    },
    handleemailpopupClose: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.EMAIL_POPUP_CLOSE
        });
    }
};


// function to call getOnCallContactList action method
const CallGetOnCallContactList = (dispatch, option) => {
    dispatch(actionCreators.getOnCallContactList(option));
}
