import { actionCreators } from './action';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IPropCriticalAdvisoryState } from './types';

const initialState = Object.freeze<IPropCriticalAdvisoryState>({
    CriticalAdvisoryData: [],
    AzureHighPriorityLinksData: [],
    CriticalAdvisoryFlyZoneData: [],
    noFlyZoneInfo: '',
    noFlyZoneInfoData: '',
    noFlyZoneInfoId: 0,
    isLoading: false,
    CriticalAdvisoryName: '',
    CriticalAdvisoryId: null,
    GuidelineInfo: '',
    IsLivesitePMOrUser: false,
    AzureLocationDropdown: [],
    AzureEnvironmentDropdown: []
});

export const reducer = (
    state: IPropCriticalAdvisoryState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true

            };
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false

            };
        case ActionType.GET_CRITICALADVISORY_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.USER_CHECK:
            return {
                ...state,
                IsLivesitePMOrUser: action.IsLivesitePMOrUser
            };
        case ActionType.GET_CRITICALADVISORY_SUCCESS:  // === for Critical Advisory Data success ===============//
            return {
                ...state,
                CriticalAdvisoryData: action.CriticalAdvisoryData,
                AzureHighPriorityLinksData: action.AzureHighPriorityLinksData,
                CriticalAdvisoryFlyZoneData: action.CriticalAdvisoryFlyZoneData,
                noFlyZoneInfo: action.noFlyZoneInfo,
                noFlyZoneInfoData: action.noFlyZoneInfoData,
                noFlyZoneInfoId: action.noFlyZoneInfoId,
                isLoading: false
            };
        case ActionType.GET_CRITICALADVISORY_FAILURE:  // === for Critical Advisory Data failure ===============//
            return {
                ...state,
                CriticalAdvisoryData: [],
                AzureHighPriorityLinksData: [],
                CriticalAdvisoryFlyZoneData: [],
                noFlyZoneInfo: '',
                noFlyZoneInfoData: '',
                noFlyZoneInfoId: 0,
                isLoading: false
            };
        case ActionType.GET_CRITICALADVISORY_GUIDELINE_SUCCESS:// === for Critical Advisory Guidelines Data success ===============//
            return {
                ...state,
                CriticalAdvisoryName: action.CriticalAdvisoryName,
                CriticalAdvisoryId: action.CriticalAdvisoryId,
                GuidelineInfo: action.GuidelineInfo,
                isLoading: false
            };
        case ActionType.GET_CRITICALADVISORY_GUIDELINE_FAILURE:  // === for Critical Advisory Guidelines Data failure ===============//
            return {
                ...state,
                CriticalAdvisoryName: '',
                CriticalAdvisoryId: null,
                GuidelineInfo: '',
                isLoading: false
            };
        case ActionType.GET_AZURELOCATIONS:
            return {
                ...state,
                AzureLocationDropdown: action.AzureLocationDropdown
            };
        case ActionType.GET_AZUREENVIRONMENTS:
            return {
                ...state,
                AzureEnvironmentDropdown: action.AzureEnvironmentDropdown
            };
        case ActionType.RESET_STATE:
            return initialState;
        default:
            return state;
    }
};