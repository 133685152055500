import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IWeeklyIDSHRState } from './types';

const initialState = Object.freeze<IWeeklyIDSHRState>({   
    isLoading: false,
    userId:'',
    MeetingDate: null,
    DefaultDate: null,
    weeklyIDSHRData: [],
    CommosonlyPIRData: [],
    SpecialTopicsData: [],
    SupportabilityData: [],
    PIRRereviewData: [],
    FundamentalsSpotlightData: [],
    ActionItemsReviewData: [],
    PIRAdminLock: [],
    PublicServiceAnnouncementData: [],
    SuccessCaseStudiesData: [],
    SuccessStoriesData: [],
    isPIREditenabled: false,
    isComosEditenabled: false,
    isPIRReviewEditenabled: false,
    isSpecialTopicEditorAddEnabled: false,
    isPubServiceAccEditorAddEnabled: false,
    isSuccessCaseStudiesEditorAddEnabled: false,
    isSuccessStoriesEditorAddEnabled: false,
    isSupportabilityEditorAddEnabled: false,
    isFundamentalsSpotlightEditorAddEnabled: false,    
    MaxDate: new Date(),
    IsPirRereviewUser: false,
    isLockedPIR: true,
    isLockedCommsPIR: true,
    isLockedTopic: true,
    isLockedFundamentalsSpotlight: true,    
    isLockedPIRArray: [],
    isLockedCommsPIRArray: [],
    isLockedTopicArray: [],
    isLockedPubServiceAccArray: [],
    isLockedSuccessCaseStudiesArray: [],
    isLockedSuccessStoriesArray: [],
    isLockedFundamentalsSpotlightArray: [],
    isLockedPubServiceAcc: true,
    isLockedSuccessCaseStudies: true,
    isLockedSuccessStories: true,
    isPSALockDisabledOnEdit: false,
    isSSLockDisabledOnEdit: false,
    isSCSLockDisabledOnEdit: false,
    isSpecialTopicLockDisabledOnEdit: false,
    isPIRLockDisabledOnEdit: false,
    OutageReviewData: [],
    isOutageditenabled: false,
    isLockedOutage: true,
    isOutageLockDisabledOnEdit: false,
    isLockedOutageArray: [],
    accordiontoggle: true,
    ssaccordiontoggle: true,
    scsaccordiontoggle: true,
    outageaccordiontoggle: true,
    psaaccordiontoggle: true,
    specialaccordiontoggle: true,
    piraccordiontoggle: true,
    AppendixMaterialsData: [],
    isAppendixMaterialEditorAddEnabled: false,
    isAppendixMaterialLockDisabledOnEdit: false,
    isLockedAppendix: true,
    isLockedAppendixArray: [],
    appendixaccordiontoggle: true
});

export const reducer = (
    state: IWeeklyIDSHRState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {      
        case ActionType.SET_MEETINGDATE_DATE:
            return {
                ...state,
                userId: action.userId,
                MeetingDate: action.meetingdate,
                DefaultDate: action.meetingdate,
                MaxDate: action.maxdate
                
            };
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true              

            };
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false

            };
        case ActionType.MEETINGDATE_DATE_CHANGE:
            return {
                ...state,
                isLockedPIR: action.isLockedPIR,
                isLockedCommsPIR: action.isLockedCommsPIR,
                isLockedTopic: action.isLockedTopic,
                isLockedFundamentalsSpotlight: action.isLockedFundamentalsSpotlight,
                isLockedPubServiceAcc: action.isLockedPubServiceAcc,
                isLockedSuccessCaseStudies: action.isLockedSuccessCaseStudies,
                isLockedSuccessStories: action.isLockedSuccessStories,
                isLockedOutage: action.isLockedOutage,
                isLockedAppendix: action.isLockedAppendix,
                MeetingDate: action.MeetingDate

            };
        case ActionType.GET_WEEKLYIDSHR_REQUEST: 
            return {
                ...state,              
                isLoading:true
            };
        case ActionType.GET_WEEKLYIDSHR_SUCCESS:  // === for Post Incident Reviews success ===============//
            return {
                ...state,
                weeklyIDSHRData: action.weeklyIDSHRData,
                isPIREditenabled: false,
                isPIRLockDisabledOnEdit: action.isPIRLockDisabledOnEdit,
                isLoading: (action.count >= 4) ? false : state.isLoading,
                piraccordiontoggle: action.piraccordiontoggle
            };
        case ActionType.ENABLE_EDITORADD_PIR:  // === Enable add or Edit for Post Incident Reviews ===============//
            return {
                ...state,
                weeklyIDSHRData: action.weeklyIDSHRData,
                isPIREditenabled: true,
                isPIRLockDisabledOnEdit: action.isPIRLockDisabledOnEdit,
                piraccordiontoggle: true
            };
        case ActionType.PIRREREVIEW_CHANGE:  // === for Post Incident Reviews success ===============//
            return {
                ...state,
                weeklyIDSHRData: action.weeklyIDSHRData                
            };
        case ActionType.GET_COOMOSONLYPIR_SUCCESS:  // === for Commosonly PIR success ===============//
            return {
                ...state,
                CommosonlyPIRData: action.CommosonlyPIRData,
                isComosEditenabled:false,
                isLoading: (action.count >= 4) ? false : state.isLoading,
            };
        case ActionType.ENABLE_EDITORADD_COMMOSONLYPIR:  // === for Commosonly PIR success ===============//
            return {
                ...state,
                isComosEditenabled:true,
                CommosonlyPIRData: action.CommosonlyPIRData                
            };
        case ActionType.COMMOSONLY_PIRREREVIEW_CHANGE:  // === for Commosonly PIR values changes on Edit ===============//
            return {
                ...state,
                CommosonlyPIRData: action.CommosonlyPIRData   
            };
        case ActionType.GET_SPECIALTOPIC_SUCCESS:  // === for Special Topic success ===============//
            return {
                ...state,
                SpecialTopicsData: action.SpecialTopicsData,
                isSpecialTopicEditorAddEnabled: false,
                isLoading: (action.count >= 4) ? false : state.isLoading,
                specialaccordiontoggle: action.specialaccordiontoggle,
                isSpecialTopicLockDisabledOnEdit: action.isSpecialTopicLockDisabledOnEdit
            };
        case ActionType.SPECIALTOPIC_CHANGE:  // === for Special topic value changes ===============//
            return {
                ...state,
                SpecialTopicsData: action.SpecialTopicsData,
            };
        case ActionType.ENABLE_EDITORADD_SPECIALTOPIC:  // === Enable add or Edit for Post Incident Reviews ===============//
            return {
                ...state,
                SpecialTopicsData: action.SpecialTopicsData,
                isSpecialTopicEditorAddEnabled: true,
                isSpecialTopicLockDisabledOnEdit: action.isSpecialTopicLockDisabledOnEdit,
                specialaccordiontoggle: true
            };
        case ActionType.GET_PIRREREVIEW_SUCCESS:  // === for PIR Rereview success ===============//
            return {
                ...state,
                PIRRereviewData: action.PIRRereviewData,
                isPIRReviewEditenabled: false,
                isLoading: (action.count >= 4) ? false : state.isLoading,
            };
        case ActionType.ENABLE_EDITORADD_PIRREVIEW:  // === Enable add or Edit for Post Incident Reviews ===============//
            return {
                ...state,
                PIRRereviewData: action.PIRRereviewData,
                isPIRReviewEditenabled: true
            };
        case ActionType.PIR_REREVIEW_CHANGE:  // === for Post Incident Reviews success ===============//
            return {
                ...state,
                PIRRereviewData: action.PIRRereviewData,
            };
        case ActionType.GET_WEEKLYIDSHR_FAILURE:  // === for Post Incident Reviews failure ===============//
            return {
                ...state,
                weeklyIDSHRData: [],
                isLoading: (action.count >= 4) ? false : state.isLoading,
            };
        case ActionType.GET_COOMOSONLYPIR_FAILURE:  // === forcommosonly PIR failure ===============//
            return {
                ...state,
                CommosonlyPIRData: [],
                isLoading: (action.count >= 4) ? false : state.isLoading,
            };
        case ActionType.GET_SPECIALTOPIC_FAILURE:  // === for special topic failure ===============//
            return {
                ...state,
                SpecialTopicsData: [],
                isLoading: (action.count >= 4) ? false : state.isLoading,
            };
      
        case ActionType.GET_PIRREREVIEW_FAILURE:  // === for PIR Rereview failure ===============//
            return {
                ...state,
                PIRRereviewData: [],
                isLoading: (action.count >= 4) ? false : state.isLoading,
            };
        case ActionType.ENABLE_EDITORADD_SUPPORTABILITY:  // === Enable add or Edit for Supportability ===============//
            return {
                ...state,
                SupportabilityData: action.SupportabilityData,
                isSupportabilityEditorAddEnabled: true
            };
        case ActionType.GET_SUPPORTABILITY_SUCCESS:  // === for Supportability success ===============//
            return {
                ...state,
                SupportabilityData: action.SupportabilityData,
                isSupportabilityEditorAddEnabled: false
             //   isLoading: (action.count >= 5) ? false : state.isLoading,
            };
        case ActionType.GET_SUPPORTABILITY_FAILURE:  // === for Supportability failure ===============//
            return {
                ...state,
                SupportabilityData: []
               // isLoading: (action.count >= 5) ? false : state.isLoading,
            };
        case ActionType.SUPPORTABILITY_CHANGE:  // === for Supportability value changes ===============//
            return {
                ...state,
                SupportabilityData: action.SupportabilityData,
            };
        case ActionType.SET_MEETINGDATE_DATE:
            return {
                ...state,
                MeetingDate: action.MeetingDate
            };
        case ActionType.REREVIEWER_CHECK:
            return {
                ...state,
                IsPirRereviewUser: action.IsPirRereviewUser
            };        
        case ActionType.GET_PIRADMINLOCK:            
            return {
                ...state,
                isLockedPIR: action.isLockedPIR,
                isLockedCommsPIR: action.isLockedCommsPIR,
                isLockedTopic: action.isLockedTopic,
                isLockedFundamentalsSpotlight: action.isLockedFundamentalsSpotlight,
                isLockedPIRArray: action.isLockedPIRArray,
                isLockedCommsPIRArray: action.isLockedCommsPIRArray,
                isLockedTopicArray: action.isLockedTopicArray,
                isLockedFundamentalsSpotlightArray: action.isLockedFundamentalsSpotlightArray,
                isLockedPubServiceAcc: action.isLockedPubServiceAcc,
                isLockedPubServiceAccArray: action.isLockedPubServiceAccArray,
                isLockedSuccessCaseStudies: action.isLockedSuccessCaseStudies,
                isLockedSuccessCaseStudiesArray: action.isLockedSuccessCaseStudiesArray,
                isLockedSuccessStories: action.isLockedSuccessStories,
                isLockedSuccessStoriesArray: action.isLockedSuccessStoriesArray,
                isLockedOutage: action.isLockedOutage,
                isLockedOutageArray: action.isLockedOutageArray,
                isLockedAppendix: action.isLockedAppendix,
                isLockedAppendixArray: action.isLockedAppendixArray,
                isLoading: false
            }        
        case ActionType.GET_FUNDAMENTALS_SPOTLIGHT_SUCCESS:  // === for Fundamentals Spotlight success ===============//
            return {
                ...state,
                FundamentalsSpotlightData: action.FundamentalsSpotlightData,
                isFundamentalsSpotlightEditorAddEnabled: false,
                isLoading: (action.count >= 4) ? false : state.isLoading,
            };
        case ActionType.FUNDAMENTALS_SPOTLIGHT_CHANGE:  // === for Fundamentals Spotlight value changes ===============//
            return {
                ...state,
                FundamentalsSpotlightData: action.FundamentalsSpotlightData,
            };
        case ActionType.ENABLE_EDITORADD_FUNDAMENTALS_SPOTLIGHT:  // === Enable add or Edit for Fundamentals Spotlight ===============//
            return {
                ...state,
                FundamentalsSpotlightData: action.FundamentalsSpotlightData,
                isFundamentalsSpotlightEditorAddEnabled: true,
            };
        case ActionType.GET_FUNDAMENTALS_SPOTLIGHT_FAILURE:  // === for Fundamentals Spotlight failure ===============//
            return {
                ...state,
                FundamentalsSpotlightData: [],
                isLoading: (action.count >= 4) ? false : state.isLoading,
            };
        case ActionType.GET_ACTION_ITEMS_REVIEW_FAILURE:  // === Enable add or Edit for Action Items Review ===============//
            return {
                ...state,
                ActionItemsReviewData: [],
                isLoading: (action.count >= 4) ? false : state.isLoading,
            };
        case ActionType.GET_ACTION_ITEMS_REVIEW_SUCCESS:  // === for Action Items Review success ===============//
            return {
                ...state,
                ActionItemsReviewData: action.ActionItemsReviewData,                
                isLoading: (action.count >= 4) ? false : state.isLoading,
                accordiontoggle: action.accordiontoggle
            };
        case ActionType.ENABLE_EDITORADD_PUBLICSERVICEACC:  // === Enable add or Edit for Public Service Announcement ===============//
            return {
                ...state,
                PublicServiceAnnouncementData: action.PublicServiceAnnouncementData,
                isPubServiceAccEditorAddEnabled: true,
                isPSALockDisabledOnEdit: action.isPSALockDisabledOnEdit,
                psaaccordiontoggle: true
            };
        case ActionType.GET_PUBLICSERVICEACC_SUCCESS:  // === for Public Service Announcement success ===============//
            return {
                ...state,
                PublicServiceAnnouncementData: action.PublicServiceAnnouncementData,
                isPubServiceAccEditorAddEnabled: false,
                isLoading: (action.count >= 4) ? false : state.isLoading,
                isPSALockDisabledOnEdit: action.isPSALockDisabledOnEdit,
                psaaccordiontoggle: action.psaaccordiontoggle
            };
        case ActionType.GET_PUBLICSERVICEACC_FAILURE:  // === for Public Service Announcement failure ===============//
            return {
                ...state,
                PublicServiceAnnouncementData: [],
                isLoading: (action.count >= 4) ? false : state.isLoading,
            };
        case ActionType.PUBLICSERVICEANNOUNCEMENT_CHANGE:  // === for Public Service Announcement value changes ===============//
            return {
                ...state,
                PublicServiceAnnouncementData: action.PublicServiceAnnouncementData,
            };
        case ActionType.ENABLE_EDITORADD_SUCCESSCASESTUDIES:  // === Enable add or Edit for Success Case Studies ===============//
            return {
                ...state,
                SuccessCaseStudiesData: action.SuccessCaseStudiesData,
                isSuccessCaseStudiesEditorAddEnabled: true,
                isSCSLockDisabledOnEdit: action.isSCSLockDisabledOnEdit,
                scsaccordiontoggle: true
            };
        case ActionType.GET_SUCCESSCASESTUDIES_SUCCESS:  // === for Success Case Studies success ===============//
            return {
                ...state,
                SuccessCaseStudiesData: action.SuccessCaseStudiesData,
                isSuccessCaseStudiesEditorAddEnabled: false,
                isLoading: (action.count >= 4) ? false : state.isLoading,
                isSCSLockDisabledOnEdit: action.isSCSLockDisabledOnEdit,
                scsaccordiontoggle: action.scsaccordiontoggle
            };
        case ActionType.GET_SUCCESSCASESTUDIES_FAILURE:  // === for Success Case Studies failure ===============//
            return {
                ...state,
                SuccessCaseStudiesData: [],
                isLoading: (action.count >= 4) ? false : state.isLoading,
            };
        case ActionType.SUCCESSCASESTUDIES_CHANGE:  // === for Success Case Studies value changes ===============//
            return {
                ...state,
                SuccessStoriesData: action.SuccessStoriesData,
            };
        case ActionType.ENABLE_EDITORADD_SUCCESSSTORIES:  // === Enable add or Edit for Success Stories ===============//
            return {
                ...state,
                SuccessStoriesData: action.SuccessStoriesData,
                isSuccessStoriesEditorAddEnabled: true,
                isSSLockDisabledOnEdit: action.isSSLockDisabledOnEdit,
                ssaccordiontoggle: true
            };
        case ActionType.GET_SUCCESSSTORIES_SUCCESS:  // === for Success Stories success ===============//
            return {
                ...state,
                SuccessStoriesData: action.SuccessStoriesData,
                isSuccessStoriesEditorAddEnabled: false,
                isLoading: (action.count >= 4) ? false : state.isLoading,
                isSSLockDisabledOnEdit: action.isSSLockDisabledOnEdit,
                ssaccordiontoggle: action.ssaccordiontoggle
            };
        case ActionType.GET_SUCCESSSTORIES_FAILURE:  // === for Success Stories failure ===============//
            return {
                ...state,
                SuccessStoriesData: [],
                isLoading: (action.count >= 4) ? false : state.isLoading,
            };
        case ActionType.SUCCESSSTORIES_CHANGE:  // === for Success Stories value changes ===============//
            return {
                ...state,
                SuccessStoriesData: action.SuccessStoriesData,
            };
        case ActionType.GET_OUTAGEREVIEW_SUCCESS:  // === for Outage review success ===============//
            return {
                ...state,
                OutageReviewData: action.OutageReviewData,
                isLoading: (action.count >= 4) ? false : state.isLoading,
                outageaccordiontoggle: action.outageaccordiontoggle
            };
        case ActionType.GET_OUTAGEREVIEW_FAILURE:  // === for Outage review Failure ===============//
            return {
                ...state,
                OutageReviewData: [],
                isLoading: (action.count >= 4) ? false : state.isLoading,
            };
        case ActionType.ENABLE_EDITORADD_OUTAGE:  // === Enable Edit OUTAGE ===============//
            return {
                ...state,
                OutageReviewData: action.OutageReviewData,
                isOutageditenabled: true,
                outageaccordiontoggle: true
            };
        case ActionType.OUTAGEREVIEW_CHANGE:  // === for Outage Review Presenter Change ===============//
            return {
                ...state,
                OutageReviewData: action.OutageReviewData
            };
        case ActionType.ACTIONREVIEWACCORDION_STATUS:  // === Toggle Accordion  Action Items Review ===============//
            return {
                ...state,
                accordiontoggle: action.accordiontoggle
            };
        case ActionType.PSAACCORDION_STATUS:  // === Toggle Accordion Public announcement ===============//
            return {
                ...state,
                psaaccordiontoggle: action.psaaccordiontoggle
            };
        case ActionType.SSACCORDION_STATUS:  // === Toggle Accordion success stories ===============//
            return {
                ...state,
                ssaccordiontoggle: action.ssaccordiontoggle
            };
        case ActionType.SCSACCORDION_STATUS:  // === Toggle Accordion success case studies ===============//
            return {
                ...state,
                scsaccordiontoggle: action.scsaccordiontoggle
            };
        case ActionType.OUTAGEACCORDION_STATUS:  // === Toggle Accordion  Outage Review===============//
            return {
                ...state,
                outageaccordiontoggle: action.outageaccordiontoggle
            };
        case ActionType.SPECIALTOPICACCORDION_STATUS:  // === Toggle Accordion Special topics ===============//
            return {
                ...state,
                specialaccordiontoggle: action.specialaccordiontoggle
            };
        case ActionType.PIRACCORDION_STATUS:  // === Toggle Accordion  PIR ===============//
            return {
                ...state,
                piraccordiontoggle: action.piraccordiontoggle
            };
        case ActionType.GET_APPENDIXMATERIAL_SUCCESS:  // === for Appendix Material success ===============//
            return {
                ...state,
                AppendixMaterialsData: action.AppendixMaterialsData,
                isAppendixMaterialEditorAddEnabled: false,
                isLoading: (action.count >= 4) ? false : state.isLoading,
                appendixaccordiontoggle: action.appendixaccordiontoggle,
                isAppendixMaterialLockDisabledOnEdit: action.isAppendixMaterialLockDisabledOnEdit
            };
        case ActionType.APPENDIXMATERIAL_CHANGE:  // === for Appendix Material value changes ===============//
            return {
                ...state,
                AppendixMaterialsData: action.AppendixMaterialsData,
            };
        case ActionType.ENABLE_EDITORADD_APPENDIXMATERIAL:  // === Enable add or Edit for Appendix Materials ===============//
            return {
                ...state,
                AppendixMaterialsData: action.AppendixMaterialsData,
                isAppendixMaterialEditorAddEnabled: true,
                isAppendixMaterialLockDisabledOnEdit: action.isAppendixMaterialLockDisabledOnEdit,
                appendixaccordiontoggle: true
            };
        case ActionType.GET_APPENDIXMATERIAL_FAILURE:  // === for Appendix Materials failure ===============//
            return {
                ...state,
                AppendixMaterialsData: [],
                isLoading: (action.count >= 4) ? false : state.isLoading,
            };
        case ActionType.APPENDIXMATERIALACCORDION_STATUS:  // === Toggle Accordion Appendix Materials ===============//
            return {
                ...state,
                appendixaccordiontoggle: action.appendixaccordiontoggle
            };
        case ActionType.RESET_STATE:
            return initialState;
        default:
            return state;
    }
};
