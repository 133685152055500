import React, { useState, Fragment, useRef } from 'react';
import DatePicker, { getDay } from 'react-datepicker'
import { ReduxAction, IAppThunkAction } from '../../../store';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignal, faFire, faAsterisk, faChevronDown, faPencilAlt, faArrowAltCircleLeft, faRecycle, faRing, faRedo, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import { IPropHeatmapAdminData } from '../../../store/HeatmapAdmin';
import { Picky } from 'react-picky';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { toast } from 'react-toastify';

type HeatmapAdminProps = {
    readonly heatmapAdminData: IPropHeatmapAdminData[];
    searchText: any;
    ChangedSearchText: any;
    UpdateMserViewData: any;
    UpdateMonthlyAvailabilityData: any;
    UpdateMserPresentationViewData: any;
    IsLivesitePMOrUser: boolean;
    UpdateMserHeatmapTrendViewData: any;
    UpdateMserHeatmapStatus: any;
    UpdateIdentityWeeklyNewsLetter: any;
    newsLetterTeamGroups: any;
};

const HeatmapAdminTable = React.memo<HeatmapAdminProps>(({
    heatmapAdminData,
    searchText,
    ChangedSearchText,
    UpdateMserViewData,
    UpdateMonthlyAvailabilityData,
    UpdateMserPresentationViewData,
    IsLivesitePMOrUser,
    UpdateMserHeatmapTrendViewData,
    UpdateMserHeatmapStatus,
    UpdateIdentityWeeklyNewsLetter,
    newsLetterTeamGroups
}) => {

    const toastIdRef = useRef(null);
    const [newsletterGroupDialog, setnewsletterGroupDialog] = useState(false);
    const [newsletterViewEnabledName, setnewsletterViewEnabledName] = useState(null);
    const [newsletterViewEnabledId, setnewsletterViewEnabledId] = useState(null);
    const [newsletterViewEnabled, setnewsletterViewEnabled] = useState(false);
    const [selectednewsLetterGroup, setselectednewsLetterGroup] = useState(null);

    const showNewsletterViewDialog = (e, row) => {
        setnewsletterViewEnabled(e.target.checked);
        setnewsletterViewEnabledName(row.viewName);
        setnewsletterViewEnabledId(row.viewId);
        setselectednewsLetterGroup(row.identityNewsLetterGroupId);
        setnewsletterGroupDialog(true);
    }

    const hidenewsletterViewDialog = () => {
        setnewsletterGroupDialog(false);
        setnewsletterViewEnabled(false);
        setnewsletterViewEnabledName(null);
        setnewsletterViewEnabledId(null);
        setselectednewsLetterGroup(null);
    }

    const submitNewsLetterViewConfirmed = () => {
        if (newsletterViewEnabled) {
            if (selectednewsLetterGroup === null || selectednewsLetterGroup === undefined || selectednewsLetterGroup === "" || selectednewsLetterGroup === 0) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Identity Weekly Newsletter Team Group cannot be Empty if NewsLetterview is enabled",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            }
            else {
                UpdateIdentityWeeklyNewsLetter(newsletterViewEnabled, newsletterViewEnabledId, selectednewsLetterGroup);
                hidenewsletterViewDialog();
            }
        }
        else {
            UpdateIdentityWeeklyNewsLetter(newsletterViewEnabled, newsletterViewEnabledId, selectednewsLetterGroup);
            hidenewsletterViewDialog();
        }
    }

    const newsletterViewDialogFooter = (
        <React.Fragment>
            <span style={{ display: 'flex', justifyContent: 'center' }}>
                <Button className="p-button-text btn-primary" style={{width: '150px'}} onClick={() => submitNewsLetterViewConfirmed()}>Submit</Button>&emsp;&emsp;&emsp;
                <Button className="p-button-text btn-primary" style={{width: '150px'}} onClick={() => hidenewsletterViewDialog()} >Cancel</Button>
            </span>
        </React.Fragment>
    );

    const filterBasedSearch = (item) => {
        if (item.serviceName.toLowerCase().includes(searchText.toLowerCase())) {
            return true
        }
        else {
            return false;
        }
    };

    if (IsLivesitePMOrUser) {
        return (
            <React.Fragment>
                <br />

                <div style={{ width: '100%' }}>
                    <Card className="cardstyleAdmin">
                        <CardBody className="cardbodystyleAdmin">
                            <Row>
                                <Col lg="9">
                                    <h3 className="headertext PIRheader"> HeatMap Admin Management</h3>
                                </Col>
                                <Col lg="3">
                                    <input type="text" placeholder="Search By ServiceName" className="form-control" name="Val2" value={searchText} onChange={e => ChangedSearchText(e)} />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <div>
                        <br />
                        <Row className="tblpadding">
                            <Col lg="12">
                                <table id="tblHeatmapAdmin" className="tblHeatmapAdmin tblborder">
                                    <thead>
                                        <tr>
                                            <th className="thwidth300 textaligncenter">Service Name</th>
                                            <th className="thwidth300 textaligncenter">HeatMaps</th>
                                            <th className="thwidth120 textaligncenter">MSER View</th>
                                            <th className="thwidth150 textaligncenter">Monthly Availability</th>
                                            <th className="thwidth200 textaligncenter">MSER Presentation View</th>
                                            <th className="thwidth200 textaligncenter">MSER Heatmap Trend View</th>
                                            <th className="thwidth120 textaligncenter">IsActive</th>
                                            <th className="thwidth120 textaligncenter">Newsletter View</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isArrayWithLength(heatmapAdminData) ?
                                            <React.Fragment>
                                                {isArrayWithLength(heatmapAdminData) ?
                                                    heatmapAdminData
                                                        .filter((item: IPropHeatmapAdminData) => {
                                                            if (filterBasedSearch(item)) {
                                                                return true
                                                            }
                                                        })
                                                        .map((row: IPropHeatmapAdminData, index, admindata) => (
                                                            <React.Fragment key={index}>
                                                                <tr >
                                                                    <td className="thwidth300">
                                                                        {index == 0 ? < span><b>{row.serviceName}</b></span>
                                                                            : admindata[index - 1].serviceName != admindata[index].serviceName
                                                                                ? < span><b>{row.serviceName}</b></span>
                                                                                : <span></span>
                                                                        }

                                                                    </td>
                                                                    <td className="thwidth300">
                                                                        <span><b>{row.viewName}</b></span>
                                                                    </td>
                                                                    <td className="textaligncenter thwidth120">
                                                                        <input type="checkbox" onChange={e => UpdateMserViewData(e, row)} checked={row.isMserView} />
                                                                    </td>
                                                                    <td className="textaligncenter thwidth150">
                                                                        <input type="checkbox" onChange={e => UpdateMonthlyAvailabilityData(e, row)} checked={row.isMonthlyAvailability} />
                                                                    </td>
                                                                    <td className="textaligncenter thwidth200">
                                                                        <input type="checkbox" onChange={e => UpdateMserPresentationViewData(e, row)} checked={row.isMserPresentationView} />
                                                                    </td>
                                                                    <td className="textaligncenter thwidth200">
                                                                        <input type="checkbox" onChange={e => UpdateMserHeatmapTrendViewData(e, row)} checked={row.isMserHeatmapTrendView} />
                                                                    </td>
                                                                    <td className="textaligncenter thwidth120">
                                                                        <input type="checkbox" onChange={e => UpdateMserHeatmapStatus(e, row)} checked={row.isActiveStatus} />
                                                                    </td>
                                                                    <td className="textaligncenter thwidth120">
                                                                        <input type="checkbox" onChange={e => showNewsletterViewDialog(e, row)} checked={row.isIdentityWeeklyNewsLetterView} />
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        ))
                                                    : <React.Fragment></React.Fragment>
                                                }
                                            </React.Fragment>
                                            :
                                            <React.Fragment></React.Fragment>
                                        }
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Dialog visible={newsletterGroupDialog} style={{ width: '50vw' }} header="Newsletter Configuration" modal footer={newsletterViewDialogFooter} onHide={hidenewsletterViewDialog}>
                    <div>
                        <div>&emsp;&emsp;&emsp;<b>View Name : </b> {newsletterViewEnabledName}</div>
                        <br />
                        <Row>
                            <Col lg='4'>
                                <div className="p-field p-col-10 p-md-4">
                                    <span>
                                        <label htmlFor="newsLetterView" className="metricnew fontcolor">&nbsp;&nbsp;Newsletter View</label>&emsp;
                                        <Checkbox id="newsLetterView" onChange={e => setnewsletterViewEnabled(e.checked)} checked={newsletterViewEnabled}></Checkbox>
                                    </span>
                                </div>
                            </Col>
                            <Col lg='8'>
                                <div className="p-field p-col-10 p-md-4">
                                    <span>
                                        <label htmlFor="newsLetterGroup" className="fontcolor">&nbsp;&nbsp;Group Name</label>&emsp;&emsp;
                                        <Dropdown id="newsLetterGroup" placeholder="Select Team Group" className="newsLetterGroupDropdown" value={selectednewsLetterGroup} options={newsLetterTeamGroups} onChange={(e) => setselectednewsLetterGroup(e.value)} optionLabel="name" />
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Dialog>
            </React.Fragment>
        )
    }
    else {
        return (
            <div></div>
        );
    }
    
});

HeatmapAdminTable.displayName = 'HeatmapAdminTable';
export default HeatmapAdminTable;