import React, { useState, Fragment, useEffect, useRef } from 'react';
import DatePicker, { getDay } from 'react-datepicker'
import { ReduxAction, IAppThunkAction } from '../../../store';
import { FontAwesomeIconMemo } from '../../../components';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash, faPencilAlt, faTimes } from '@fortawesome/free-solid-svg-icons'
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import { IPropCriticalAdvisoryData, PropAzureHighPriorityLink } from '../../../store/CriticalAdvisory';
import { Picky } from 'react-picky';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


type CriticalAdvisoryProps = {
    readonly CriticalAdvisoryData: IPropCriticalAdvisoryData[];
    AzureHighPriorityLinksData: any;
    CriticalAdvisoryFlyZoneData: any;
    noFlyZoneInfo: any;
    noFlyZoneInfoId: any;
    noFlyZoneInfoData: any;
    UpdateCriticalAdvisoryData: any;
    saveFlyZoneInfo: any;
    saveGuidelinesInfo: any;
    GuidelineInfoValidate: any;
    IsLivesitePMOrUser: boolean;
    NoFlyZoneInfoEdit: any;
    requestGuidelineInfo: any;
    GuidelinesEdit: any;
    GuidelinesView: any;
    openNew: any;
    CriticalADNameBodyTemplate: any;
    occuredOnBodyTemplate: any;
    isInactiveBodyTemplate: any;
    guidelineBodyTemplate: any;
    actionBodyTemplate: any;
    azurActionBodyTemplate: any;
    openAzureNew: any;
    openPlannedAzureItems: any;
    AzureEnvironmentDropdown: any;
    AzureLocationDropdown: any;
    planedAzureTemplate: any;
    movedtoPlannedTemplate: any;
    RemovePlannedEvent: any;
    movedtoCurrentTemplate: any;
    ApproveToCurrentEvent: any;
};

const CriticalAdvisoryTable = React.memo<CriticalAdvisoryProps>(({
    CriticalAdvisoryData,
    AzureHighPriorityLinksData,
    CriticalAdvisoryFlyZoneData,
    noFlyZoneInfo,
    noFlyZoneInfoId,
    noFlyZoneInfoData,
    UpdateCriticalAdvisoryData,
    saveFlyZoneInfo,
    saveGuidelinesInfo,
    GuidelineInfoValidate,
    IsLivesitePMOrUser,
    NoFlyZoneInfoEdit,
    requestGuidelineInfo,
    GuidelinesEdit,
    GuidelinesView,
    openNew,
    CriticalADNameBodyTemplate,
    occuredOnBodyTemplate,
    isInactiveBodyTemplate,
    guidelineBodyTemplate,
    actionBodyTemplate,
    azurActionBodyTemplate,
    openAzureNew,
    openPlannedAzureItems,
    AzureEnvironmentDropdown,
    AzureLocationDropdown,
    planedAzureTemplate,
    movedtoPlannedTemplate,
    RemovePlannedEvent,
    movedtoCurrentTemplate,
    ApproveToCurrentEvent
}) => {

    const BannerShow = () => {
        var show = true;
        if (noFlyZoneInfo !== undefined || noFlyZoneInfo !== '') {
            if (noFlyZoneInfo.replace(/<(.|\n)*?>/g, '').length == 0) {
                show = false;
            }
        }
        return show;
    };

    const filterBased = (item, searchItem) => {
        if (item.advisoryType == 'Identity' || item.advisoryType == 'Azure' || item.advisoryType == 'PlannedEvent') {
            return true
        }
        else {
            return false;
        }
    };
    return (
        <React.Fragment>
            <div>
                <div style={{ paddingLeft: '5vw', width: '90vw' }}>
                    {isArrayWithLength(CriticalAdvisoryFlyZoneData) && BannerShow() ?
                        <Row style={{ paddingLeft: '15px' }}>
                            <div className="alert alert-primary fade show RTELIdots" role="alert" style={{ minWidth: '85vw', maxWidth: '85vw' }}>
                                <div dangerouslySetInnerHTML={{ __html: noFlyZoneInfo }} className="FlyZoneData"></div>
                            </div>
                        </Row>
                        : <React.Fragment></React.Fragment>
                    }
                </div>
                <div>
                    {isArrayWithLength(CriticalAdvisoryData) ?
                        <div>
                            <br />
                            <div className="identitycriticalchangeadvisory">
                                <div>
                                    <br />
                                    {isArrayWithLength(CriticalAdvisoryData) ?
                                        <div>
                                            <Row>
                                                <Col lg='8'>
                                                    <h5 style={{ color: '#3875b2' }} >IDNA Critical Change Advisories – Approved</h5>
                                                </Col>
                                                
                                                <Col lg='4' className="noflybtn" style={{ marginBottom: '15px' }}>
                                                    {IsLivesitePMOrUser ?
                                                        <Button className="btn filternoflybutton" onClick={NoFlyZoneInfoEdit} ><strong>No Fly Zone Information For Banner</strong></Button>
                                                        : <React.Fragment></React.Fragment>
                                                    }
                                                </Col>
                                            </Row>
                                            {IsLivesitePMOrUser ?
                                                <Row>
                                                    <Col lg='10'>                                                        
                                                    </Col>
                                                    <Col lg='2' style={{ textAlign: 'right' }}>
                                                        <div>
                                                            <Button className="btn btn-primary btn-sm filter-hmbutton btnwidth150" onClick={openNew}><FontAwesomeIcon icon={faPlus} style={{ color: 'white', backgroundColor: 'None', border: 'none' }} /><strong className="txtcolorwhite"> Add</strong></Button>
                                                        </div>
                                                    </Col>
                                                    <br />
                                                </Row>

                                                : <React.Fragment></React.Fragment>
                                            }
                                            <br />
                                        </div>
                                        : <React.Fragment></React.Fragment>
                                    }
                                </div>
                                <div className="card">
                                    <DataTable value={CriticalAdvisoryData
                                        .filter((item: IPropCriticalAdvisoryData) => {
                                            if (item.advisoryType == 'Identity') {
                                                return true
                                            }
                                        })} dataKey="id">
                                        <Column style={{ width: '20%', textAlign: 'left', verticalAlign: 'middle' }} header="Critical Changes Only Advisories" body={CriticalADNameBodyTemplate}></Column>
                                        <Column style={{ width: '40%', textAlign: 'left', verticalAlign: 'middle' }} header="When (PT/UTC)" body={occuredOnBodyTemplate}></Column>
                                        <Column style={{ width: '15%', textAlign: 'left', verticalAlign: 'middle' }} field="azureLocation" header="Location"></Column>
                                        <Column style={{ width: '10%', textAlign: 'left', verticalAlign: 'middle' }} field="environment" header="Environment"></Column>
                                        <Column style={{ width: '8%', verticalAlign: 'middle' }} body={guidelineBodyTemplate}></Column>
                                        {IsLivesitePMOrUser ?
                                            <Column className="thwidth100 textaligncenter" header="Action" style={{ verticalAlign: 'middle' }} body={actionBodyTemplate}></Column>
                                            : <React.Fragment></React.Fragment>
                                        }
                                        {IsLivesitePMOrUser ?
                                            <Column className="thwidth100 textaligncenter" header="Move to Planned Lockdown" style={{ verticalAlign: 'middle' }} body={movedtoPlannedTemplate}></Column>
                                            : <React.Fragment></React.Fragment>
                                        }
                                    </DataTable>
                                </div>
                            </div>

                            <div className="plannedazureevents">
                                <div>
                                    <br /><br />
                                    <span>
                                            <div>
                                                <Row>
                                                    <Col lg='10' className='criticalHeaders'>
                                                    <span>IDNA Critical Change Advisories – Planned</span>
                                                    </Col>
                                                    {IsLivesitePMOrUser ?
                                                        <Col lg='2' style={{ textAlign: 'right' }}>
                                                            <Button className="btn btn-primary btn-sm filter-hmbutton btnwidth150" onClick={openPlannedAzureItems}><FontAwesomeIcon icon={faPlus} style={{ color: 'white', backgroundColor: 'None', border: 'none' }} /><strong className="txtcolorwhite"> Add</strong></Button>
                                                        </Col>
                                                        : <React.Fragment></React.Fragment>
                                                    }
                                                </Row>
                                            </div>
                                    </span>
                                    <br />
                                </div>
                                <div className="card">
                                    <DataTable value={CriticalAdvisoryData
                                        .filter((item: IPropCriticalAdvisoryData) => {
                                            if (item.advisoryType == 'PlannedAzure') {
                                                return true
                                            }
                                        })} dataKey="id">
                                        <Column style={{ width: '30%', textAlign: 'left', verticalAlign: 'middle' }} field="criticalAdvisoryName" header="Critical Changes Only Advisories"></Column>
                                        <Column style={{ width: '30%', textAlign: 'left', verticalAlign: 'middle' }} header="When (PT/UTC)" body={occuredOnBodyTemplate}></Column>
                                        <Column style={{ width: '15%', textAlign: 'left', verticalAlign: 'middle' }} field="azureLocation" header="Azure Location"></Column>
                                        <Column style={{ width: '10%', textAlign: 'left', verticalAlign: 'middle' }} field="environment" header="Environment"></Column>
                                        {IsLivesitePMOrUser ?
                                            <Column className="thwidth100 textaligncenter" header="Action" style={{ verticalAlign: 'middle' }} body={planedAzureTemplate}></Column>
                                            : <React.Fragment></React.Fragment>
                                        }
                                        {IsLivesitePMOrUser ?
                                            <Column className="thwidth100 textaligncenter" header="Move To Current Lockdown" style={{ verticalAlign: 'middle' }} body={movedtoCurrentTemplate}></Column>
                                            : <React.Fragment></React.Fragment>
                                        }
                                    </DataTable>
                                </div>
                            </div>
                            <div className="azurehighpriorityeventProg">
                                <div>
                                    <br /><br />
                                    <span>
                                        {isArrayWithLength(AzureHighPriorityLinksData) ?
                                            <div>
                                                <Row>
                                                    <Col lg='10' className='criticalHeaders'>
                                                        <a href={AzureHighPriorityLinksData[0].azureHighPrioritySiteLink} target="_blank">Azure High Priority Events Program </a><span>(Azure CCOA – Azure approved lockdowns only)</span>
                                                    </Col>
                                                    {IsLivesitePMOrUser ?
                                                        <Col lg='2' style={{ textAlign: 'right' }}>
                                                            <Button className="btn btn-primary btn-sm filter-hmbutton btnwidth150" onClick={openAzureNew}><FontAwesomeIcon icon={faPlus} style={{ color: 'white', backgroundColor: 'None', border: 'none' }} /><strong className="txtcolorwhite"> Add</strong></Button>
                                                        </Col>
                                                        : <React.Fragment></React.Fragment>
                                                    }
                                                </Row>
                                            </div>

                                            : <React.Fragment></React.Fragment>
                                        }
                                    </span>
                                    <br />
                                </div>
                                <div className="card">
                                    <DataTable value={CriticalAdvisoryData
                                        .filter((item: IPropCriticalAdvisoryData) => {
                                            if (item.advisoryType == 'Azure') {
                                                return true
                                            }
                                        })} dataKey="id">
                                        <Column style={{ width: '30%', textAlign: 'left', verticalAlign: 'middle' }} field="criticalAdvisoryName" header="Critical Changes Only Advisories"></Column>
                                        <Column style={{ width: '30%', textAlign: 'left', verticalAlign: 'middle' }} header="When (PT/UTC)" body={occuredOnBodyTemplate}></Column>
                                        <Column style={{ width: '15%', textAlign: 'left', verticalAlign: 'middle' }} field="azureLocation" header="Azure Location"></Column>
                                        <Column style={{ width: '10%', textAlign: 'left', verticalAlign: 'middle' }} field="environment" header="Environment"></Column>
                                        {IsLivesitePMOrUser ?
                                            <Column className="thwidth100 textaligncenter" header="Action" style={{ verticalAlign: 'middle' }} body={azurActionBodyTemplate}></Column>
                                            : <React.Fragment></React.Fragment>
                                        }
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                        : <React.Fragment></React.Fragment>
                    }
                </div>
            </div>
        </React.Fragment>
    )

});

CriticalAdvisoryTable.displayName = 'CriticalAdvisoryTable';
export default CriticalAdvisoryTable;