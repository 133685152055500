import React, { useState, Fragment } from 'react';
import { IAvailabilitySummary } from '../../../store/availability';
import {  Link } from 'react-router-dom';
import { isArrayWithLength } from '../../../utils';
import classnames from 'classnames';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Row,
    Col
} from 'reactstrap';

type AvailabilitySummaryProps = {
    readonly availability: IAvailabilitySummary[];
};

const AvailabilitySummaryTable = React.memo<AvailabilitySummaryProps>(({
    availability 
}) => {
    
    let tmpDirector1: any = '';
    const setDirector1: any = (name) => {
        tmpDirector1 = name;
    }
    let tmpDirector2: any = '';
    const setDirector2: any = (name) => {
        tmpDirector2 = name;
    }
    const ColorSummData =  (value, target)=> {
        target = target.replace(/,/g, '');
        if (value) {
            value = value.replace(/,/g, '');
        }

        if (target.indexOf('%') > 0) {
            value = (parseFloat(String(value)));
            if (value >= parseFloat(String(target))) {
                return { background: '#CEF8C1' };
            } else if (value < parseFloat(String(target))) {
                return { background: '#FFD1D3' };
            }
        }

        // If Target contain character remove using regex
        if (!isNaN(target)) {
            target = (parseFloat(String(target)));
        } else {
            target = target.match(/\d+\.?\d*/g);
        }

        if (value && value <= parseFloat(String(target))) {
            return { background: '#CEF8C1' };
        } else if (value && value > parseFloat(String(target))) {
            return { background: '#FFD1D3' };
        }

       
    };
   
    const [activeTab, setActiveTab] = useState('Identity Authentication and Authorization (AuthNZ)<br/>Nadim Abdo');  
    const toggle = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        };
    };
    const ReplaceBRInNames = (Name: any) => {
        return Name.replace(new RegExp('<br/>', 'g'), '-');

    }
    return (
        <div>
            <div style={{ paddingTop: '10px', marginLeft: '20vw', }}>
                <table className='head4' style={{ width: '65vw' }} >
                    <tbody>
                    <tr>
                        <td align='left' className='AvailbilitysummaryHeaddingtr'>
                            <h3 className='AvailbilitysummaryHeadding'>Identity Service Health Review - Weekly Availability Summary Data</h3>
                        </td>
                        </tr>
                        </tbody>
                </table>
            </div>
            <div className='modal-body modal-fit-win' style={{ width: '65vw', marginTop: '1vw', marginLeft: '20vw' }}>
                <Nav tabs style={{borderBottom:'0'}}>
                    {isArrayWithLength(availability) && availability.map((dh: IAvailabilitySummary, index) => (
                        <React.Fragment key={index}>
                            {
                                (tmpDirector1 !== dh.groupName)
                                    ?
                                    <Fragment>
                                        <NavItem style={{ color: 'rgba(0,0,0,0.54)' }}>
                                            <NavLink
                                                className={classnames({ active: activeTab === dh.groupName })}
                                                onClick={() => {
                                                    toggle(dh.groupName);
                                                }}
                                            >
                                                {ReplaceBRInNames(dh.groupName)}
                                                {setDirector1(dh.groupName)}
                                            </NavLink>
                                        </NavItem>
                                    </Fragment>
                                    : <React.Fragment></React.Fragment>
                            }
                        </React.Fragment>

                    ))}
                </Nav>
                <TabContent activeTab={activeTab}>
                    {isArrayWithLength(availability) && availability.map((dh: IAvailabilitySummary, key) => (
                        <React.Fragment key={key}>
                            {
                                (tmpDirector2 !== dh.groupName)
                                    ?
                                    <Fragment>
                                        <TabPane tabId={dh.groupName}>
                                            <Row>
                                                <Col sm='12'>
                                                    <table className='table-bordered availabilitysummarytable' id='Availbilitysummary'>
                                                        <thead>
                                                            <tr>
                                                                <th className='availabilitysummaryserviceheader'>Service Name</th>
                                                                <th className='availabilitysummaryth'>Uptime (99.99%)</th>
                                                                <th className='availabilitysummaryth'>Customer SLA/SLA Attainment (99.9%)</th>
                                                                <th className='availabilitysummaryth'>LSIs</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {isArrayWithLength(availability) && availability.map((dhs: IAvailabilitySummary, subindex) => (
                                                                <React.Fragment key={subindex}>
                                                                    {
                                                                        (dhs.groupName === dh.groupName)
                                                                            ?
                                                                            <tr style={{ fontSize:'14px' }}>

                                                                                <td className='availabilitysummaryServicetd'>
                                                                                    <Link to={'/ServiceHealth?serviceid=' + dhs.serviceId} target="_blank" className='serviceheader'> {dhs.serviceName}</Link>
                                                                                </td>
                                                                                <td className='Availblesummarytdcenter' style={ColorSummData(dhs.serviceUptime, '99.9900%')}> {dhs.serviceUptime}</td>
                                                                                <td className='Availblesummarytdcenter' style={ColorSummData(dhs.customerSla, '99.9000%')}> {dhs.customerSla}</td>
                                                                                <td className='Availblesummarytdcenter' style={ColorSummData(dhs.lsi, '0.0')}> {dhs.lsi}</td>
                                                                            </tr>
                                                                            :
                                                                            <React.Fragment>
                                                                            </React.Fragment>
                                                                    }

                                                                </React.Fragment>

                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    {setDirector2(dh.groupName)}
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </Fragment>
                                    : <React.Fragment></React.Fragment>

                            }
                        </React.Fragment>

                    ))}
                </TabContent>
           
          </div>
        </div>
    )
});

AvailabilitySummaryTable.displayName = 'AvailabilitySummaryTable';
export default AvailabilitySummaryTable;