import React, { useState, Fragment } from 'react';
import {  Link } from 'react-router-dom';
import DatePicker, { getDay } from 'react-datepicker'
import { ReduxAction, IAppThunkAction } from '../../../store';
import moment from 'moment';
import { IPropAllPirData, IPropCommsOnlyPir, IPropSpecialTopicsWishr, IPropAllPirDataReReview, IPropSuccessCaseStudiesWishr, IPropSuccessStoriesWishr} from '../../../store/Weekly-ID-SHR/types';
import {Row, Col,Card,CardBody} from 'reactstrap';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import { PostIncidentReviews, CommosonlyPIR, SpecialTopics, PostIncidentReReviews, SuccessCaseStudies} from '../child-components';
type WeeklyIDSHRDataTableProps = {
    MeetingDate: any;
    MaxDate: any;
    readonly handleMeetingDateChange: (MeetingDate: Date) => IAppThunkAction<ReduxAction>;
    
};

const WeeklyIDSHRDataTable = React.memo<WeeklyIDSHRDataTableProps>(({
    MeetingDate,
    MaxDate,
    handleMeetingDateChange   
}) => {   
    const isThursday = (date) => {
        let checkDate = new Date(moment("06/01/2023").format('MM/DD/YYYY'));
        let selectedDate = new Date(moment(date).format('MM/DD/YYYY'));
        let day = new Date(moment(date).format('MM/DD/YYYY')).getDay();
        //Added below condition to get past WISHR date based on Thursday meetings
        if (selectedDate > checkDate) {
            return day == 2;
        }
        else {
            return day == 4;
        }
    };   
    const getMaxdate = () => {
        let today = new Date(moment().clone().endOf('week').format('MM/DD/YYYY'));
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 5);
       
    };
    const getMidWed = (year, month) => {
        var d = new Date(year, month - 1);
        d.setDate(d.getDate() + ((8 - d.getDay()+1) % 7) + 7);
        return d;
    }

    const getSecondThursday = () => {
        let secweekDisable = [];
        const yearArray = Array.from({ length: 7 }, (item, index) => index + 2018);
        const monthsArray = Array.from({ length: 13 }, (item, index) => index + 1);
        let yearMonthArray = [];
        for (let year = 1; year < yearArray.length; year++) {
            for (let months = 1; months < monthsArray.length; months++) {
                yearMonthArray.push([yearArray[year], months]);
            }
        }
        yearMonthArray.forEach(
            function (a) {
                secweekDisable.push(getMidWed(a[0], a[1]));
            }
        );
        return secweekDisable;
    }
  
    return (
        <React.Fragment>
            <Card className="cardstyle">
                <CardBody className="cardbodystyle">
                    <Row>
                        <Col lg="1"></Col>
                        <Col lg="5">
                            <h3 className="headertext"> Weekly Identity Service Health Review (WISHR)</h3>
                        </Col>

                        <Col lg="4">
                            <span>Select Meeting Date : </span>
                            <DatePicker className='InputStyle'
                                selected={MeetingDate}
                                onChange={handleMeetingDateChange}                                
                                calendarIcon={true}
                                calendarAriaLabel={true}
                                excludeDates={getSecondThursday()}
                                filterDate={isThursday}
                                maxDate={MaxDate}
                            />
                        </Col>
                        <Col lg="2"></Col>
                        </Row>
                    </CardBody>
            </Card>
            <div className="divpaddingtop">
                <Card>
                    <CardBody className="cardbodytext">
                        <Row>
                            <Col lg="6">
                                Identity PIR guidelines, read the wiki <a href="https://identitydivision.visualstudio.com/IdentityWiki/_wiki/wikis/IdentityWiki.wiki?pagePath=%2FIdentityWiki%2FFundamentals%2FLive%20Site%2FIdentity%20Postmortem%20Guidelines&pageId=2507&wikiVersion=GBwikiMaster" target="_blank">here</a><br />
						    Criteria to add a PIR to the agenda is <a href="https://identitydivision.visualstudio.com/IdentityWiki/_wiki/wikis/IdentityWiki.wiki?pagePath=%2FIdentityWiki%2FFundamentals%2FLive%20Site%2FIdentity%20Postmortem%20Guidelines%2FCriteria%20for%20adding%20PIRs%20to%20the%20Weekly%20Identity%20Service%20Health%20Review&pageId=2931&wikiVersion=GBwikiMaster" target="_blank">here</a><br />
						    Before presenting in the review read the presentation requirements <a href="https://identitydivision.visualstudio.com/IdentityWiki/_wiki/wikis/IdentityWiki.wiki?pagePath=%2FIdentityWiki%2FFundamentals%2FLive%20Site%2FIdentity%20Postmortem%20Guidelines%2FPIR%20Presentations%20in%20the%20Weekly%20Ideneity%20Service%20Health%20Review&pageId=2933&wikiVersion=GBwikiMaster" target="_blank">here</a>
                            </Col>
                            <Col lg="6">
                                <span className="label-info">Note: IcM data pulled from IcM Kusto DB which has up to 15 min data propagation delay.</span>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
       </React.Fragment>
    )
});

WeeklyIDSHRDataTable.displayName = 'WeeklyIDSHRDataTable';
export default WeeklyIDSHRDataTable;