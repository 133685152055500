import { IAppThunkAction, ReduxAction } from '../';
import { ActionType, IPropEscalationDirectoryData, IEscalationDirectoryState } from './types';
import { EscalationDirectoryAPI, CommonAPI } from '../../api';
import { isNullOrUndefined } from '../../utils';
export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    setloadingtrue: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
    },
    setloadingfalse: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_FALSE
        });
    },
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }

    },
    requestEscalationDirectoryData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        // Dispatch request
        dispatch({
            type: ActionType.GET_ESCALATIONDIRECTORY_REQUEST
        });
        var escalationDirectoryData = [];
        var escalationDirectoryDataFiltered = [];
        var reverseSort = getState().EscalationDirectory.reverseSort;
        var sortcolumn = getState().EscalationDirectory.sortcolumn;
        var isactive = getState().EscalationDirectory.isactive;
        EscalationDirectoryAPI.GetEscalationDirectory(isactive)
            .then((escalationDirectoryDataRaw: IPropEscalationDirectoryData[]) => {
                escalationDirectoryDataRaw.map((obj: IPropEscalationDirectoryData) => {
                    escalationDirectoryData.push(obj);
                });
                if (sortcolumn === "description" || sortcolumn === "escalationPath" || sortcolumn === "remarks") {
                    // Logic to sort the array based on selected column and order having Html Content
                    escalationDirectoryDataFiltered = (reverseSort === true)
                        ? escalationDirectoryData.sort((a, b) => (a[sortcolumn].replace(/<(.|\n)*?>/g, '').toLowerCase() > b[sortcolumn].replace(/<(.|\n)*?>/g, '').toLowerCase()) ? -1 : 1)
                        : escalationDirectoryData.sort((a, b) => (a[sortcolumn].replace(/<(.|\n)*?>/g, '').toLowerCase() > b[sortcolumn].replace(/<(.|\n)*?>/g, '').toLowerCase()) ? 1 : -1)
                }
                else {
                    // Logic to sort the array based on selected column and order
                    escalationDirectoryDataFiltered = (reverseSort === true)
                        ? escalationDirectoryData.sort((a, b) => (a[sortcolumn].toLowerCase() > b[sortcolumn].toLowerCase()) ? -1 : 1)
                        : escalationDirectoryData.sort((a, b) => (a[sortcolumn].toLowerCase() > b[sortcolumn].toLowerCase()) ? 1 : -1)
                }

                dispatch({
                    escalationDirectoryData: escalationDirectoryData,
                    escalationDirectoryDataFiltered: escalationDirectoryDataFiltered,
                    type: ActionType.GET_ESCALATIONDIRECTORY_SUCCESS
                });
            })
            .catch(error => {
                dispatch({
                    type: ActionType.GET_ESCALATIONDIRECTORY_FAILURE
                });
            });

    },
    UpdateSearchText: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var search = e.target.value;
        dispatch({
            searchText: search,
            type: ActionType.CHANGE_SEARCHTEXT_ESCALATIONDIRECTORY
        });
    },
    sortData: (e, column): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var reverseSort = getState().EscalationDirectory.reverseSort;
        var sortcolumn = getState().EscalationDirectory.sortcolumn;
        var escalationDirectoryData = getState().EscalationDirectory.escalationDirectoryData;
        reverseSort = sortcolumn == column ? !reverseSort : false;
        sortcolumn = column;
        var escalationDirectoryDataFiltered = [];
        if (sortcolumn === "description" || sortcolumn === "escalationPath" || sortcolumn ==="remarks") {
            // Logic to sort the array based on selected column and order having Html Content
             escalationDirectoryDataFiltered = (reverseSort === true)
                ? escalationDirectoryData.sort((a, b) => (a[column].replace(/<(.|\n)*?>/g, '').toLowerCase() > b[column].replace(/<(.|\n)*?>/g, '').toLowerCase()) ? -1 : 1)
                : escalationDirectoryData.sort((a, b) => (a[column].replace(/<(.|\n)*?>/g, '').toLowerCase() > b[column].replace(/<(.|\n)*?>/g, '').toLowerCase()) ? 1 : -1)
        }
        else {
            // Logic to sort the array based on selected column and order
             escalationDirectoryDataFiltered = (reverseSort === true)
                ? escalationDirectoryData.sort((a, b) => (a[column].toLowerCase() > b[column].toLowerCase()) ? -1 : 1)
                : escalationDirectoryData.sort((a, b) => (a[column].toLowerCase() > b[column].toLowerCase()) ? 1 : -1)
        }
        dispatch({
            reverseSort: reverseSort,
            sortcolumn: sortcolumn,
            escalationDirectoryDataFiltered: escalationDirectoryDataFiltered,
            type: ActionType.CHANGE_SORTING_ESCALATIONDIRECTORY
        });
    }
}




