import React, { useEffect, useState, useRef } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { RouteComponentProps } from 'react-router-dom';
import { actionCreators, reducer } from '../../store/WeeklyAvailability';
import { isArrayWithLength, renderToastifyMsg } from '../../utils';
import { WeeklyAvailabilityTable } from '../WeeklyAvailability/child-components';
import LoadingOverlay from 'react-loading-overlay';
import { ToastId, toast } from 'react-toastify';
import { CommonAPI, WeeklyDataAPI } from '../../api';
import { isNullOrUndefined } from 'util';
import {
    Row,
    Col, Card, CardText
} from 'reactstrap';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import { NewFunctionalityBanner } from '../../components';

type WeeklyAvailabilityProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly isProd: boolean }
    & { readonly username: string }
    & { readonly history: History }
    & RouteComponentProps<{}>;

const WeeklyAvailability: React.FC<WeeklyAvailabilityProps> = ({
    isLoading,
    username,
    addUserVisit,
    isAuthenticated,
    weeklyStartDate,
    onweeklyStartDateChange,
    getWeeklyData,
    weeklyData,
    weeklyheaderArr,
    WeeklyKPiArr,
    IsWeeklyDataLoaded,
    IsWeeklyDataDisabled,
    ClearWeeklyDate,
    liKPIValueKeys,
    serviceGroups,
    services,
    clearfilters,
    GetServicegroups,
    GetServices,
    servicegroupval,
    servicegroupvalchange,
    serviceval,
    serviceschange,
    globalsearch,
    searchtextboxchange,
    servicegroupvalstring,
    servicevalstring,
    isDataloaded,
    isProd,
    WeeklyAggUpdate,
    CopyURL,
    urlparam,
    seturlparamempty,
    EditTarget,
    editedTargetData,
    TargetEditPop,
    TargetEditPopClose,
    ChangeTargetValue,
    ChangeJustification,
    originalTargetValue,
    justificationDetails,
    updatedTargetValue,
    SubmitforApproval,
    ClearTarget
}) => {
    useEffect(() => {
        if (isAuthenticated)
            GetServices();
        GetServicegroups();
        addUserVisit(0, 'WeeklyAvailability.tsx_v2');
        document.querySelectorAll('.p-multiselect input').forEach(a => { a.setAttribute('aria-label', 'multiselect') });
        document.querySelectorAll('.react-datepicker-wrapper input').forEach(a => { a.setAttribute('aria-label', 'calender') });
        document.querySelectorAll('.p-multiselect-items').forEach(a => { a.setAttribute('aria-label', 'multiselect') });
        document.querySelectorAll('.pi-check').forEach(a => { a.setAttribute('title', 'checkbox') });
        document.querySelectorAll('.p-multiselect-close').forEach(a => { a.setAttribute('aria-label', 'close') });
        getWeeklyData(weeklyStartDate, servicevalstring);
    }, [isAuthenticated]);

    const toastIdRef = useRef<ToastId>('');
    const [IsEdit, SetIsEdit] = useState(false);
    const [EditKeyValue, SetEditKeyValue] = useState('');
    const FilterWeeklyAvailabilityData = () => {
        getWeeklyData(weeklyStartDate, servicevalstring);
    }
    const [weekly_Data, setweeklyData] = useState(weeklyData);
    const [Weekly_AggUpdate, setWeeklyAggUpdate] = useState(WeeklyAggUpdate);
    const saveAllWeeklyAvailChanges = () => {
        WeeklyDataAPI.UpdateWeeklyAggregateValues(WeeklyAggUpdate)
            .then((response: number) => {
                if (response !== -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg('Updated successfully',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                    );
                } else {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg('No Change in Data or Error While Saving the Data, Please contact Admin!',
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                    );
                }
                getWeeklyData(weeklyStartDate, servicevalstring);
                SetIsEdit(false);
            })
    }
    const clearfilterdata = () => {
        const servicegroupvals = [];
        let servicegroupvalstrings = '';
        if (isArrayWithLength(serviceGroups)) {
            serviceGroups.map((item, key) => {
                servicegroupvals.push(item.value);
                servicegroupvalstrings = servicegroupvalstrings + ',' + item.value;

            })
        }
        const servicevals = [];
        let servicevalstrings = '';
        if (isArrayWithLength(services)) {
            services.map((item) => {
                servicevals.push(item.value);
                servicevalstrings = servicevalstrings + ',' + item.value;
            })
        }
        clearfilters(servicegroupvals, servicegroupvalstrings, servicevals, servicevalstrings);
        getWeeklyData(weeklyStartDate, servicevalstrings);
    }
    const ChangeIsEditval = (e, val, rowKey) => {
        getWeeklyData(weeklyStartDate, servicevalstring);
        SetIsEdit(val);
        if (val) {
            SetEditKeyValue(rowKey);
        }
        else {
            SetEditKeyValue('');
        }
    }
    const UndoKpiEdit = (serviceId, viewId, period) => {
        const periodIndex = period;
        WeeklyDataAPI.UndoKpiEdited(serviceId, viewId, periodIndex)
            .then((response: string) => {
                if (response !== '1') {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg('Successfully Reverted changes ',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                    );
                } else {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg('No Change in Data or Error While Saving the Data, Please contact Admin!',
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                    );
                }
                getWeeklyData(weeklyStartDate, servicevalstring);
            })
    }
    const ChangeWeekAggvalue = (serviceId, viewId, index, val, fieldType) => {
        let changeType = (fieldType == 'kpiValue') ? true : false;
        const weeksData = weeklyData;
        const WeeklyAggUpdateTemp: any = WeeklyAggUpdate;
        const tempWeeklyData: any = [];
        for (var i = 0; i < weeksData.length; i++) {
            if (changeType) {
                if ((weeksData[i].serviceId == serviceId) && (weeksData[i].viewId == viewId) && (weeksData[i].liKPIValues[index].value != val.target.value)) {
                    weeksData[i].liKPIValues[index].value = val.target.value;
                    var duplicateRecord = WeeklyAggUpdateTemp.find(x => x.serviceId == serviceId && x.viewId == viewId && x.Period == index);
                    if (duplicateRecord) {
                        WeeklyAggUpdateTemp[WeeklyAggUpdateTemp.findIndex(x => x.serviceId == serviceId && x.viewId == viewId)].MonthNewValue = val.target.value
                    }
                    else {
                        WeeklyAggUpdateTemp.push({ 'serviceId': weeksData[i].serviceId, 'serviceName': weeksData[i].serviceName, 'viewName': weeksData[i].viewName, 'viewId': weeksData[i].viewId, 'Period': index, 'MonthNewValue': weeksData[i].liKPIValues[index].value, 'UpdatedBy': username, 'Comments': weeksData[i].liKPIValues[index].comments });
                    }
                }
            }
            else {
                if ((weeksData[i].serviceId == serviceId) && (weeksData[i].viewId == viewId) && (weeksData[i].liKPIValues[index].comments != val.target.value)) {
                    weeksData[i].liKPIValues[index].comments = val.target.value;
                    var duplicateRecord = WeeklyAggUpdateTemp.find(x => x.serviceId == serviceId && x.viewId == viewId && x.Period == index);
                    if (duplicateRecord) {
                        WeeklyAggUpdateTemp[WeeklyAggUpdateTemp.findIndex(x => x.serviceId == serviceId && x.viewId == viewId)].Comments = val.target.value
                    }
                    else {
                        WeeklyAggUpdateTemp.push({ 'serviceId': weeksData[i].serviceId, 'serviceName': weeksData[i].serviceName, 'viewName': weeksData[i].viewName, 'viewId': weeksData[i].viewId, 'Period': index, 'MonthNewValue': weeksData[i].liKPIValues[index].value, 'UpdatedBy': username, 'Comments': weeksData[i].liKPIValues[index].comments });
                    }
                }
            }
            tempWeeklyData.push(weeksData[i]);
        }
        setWeeklyAggUpdate(WeeklyAggUpdateTemp);
        setweeklyData(tempWeeklyData);
    }

    return (
        <div>
            <NewFunctionalityBanner />
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        <WeeklyAvailabilityTable
                            weeklyStartDate={weeklyStartDate}
                            onweeklyStartDateChange={onweeklyStartDateChange}
                            WeeklyData={weeklyData}
                            WeeklyheaderArr={weeklyheaderArr}
                            WeeklyKPiArr={WeeklyKPiArr}
                            IsWeeklyDataLoaded={IsWeeklyDataLoaded}
                            IsWeeklyDataDisabled={IsWeeklyDataDisabled}
                            ClearWeeklyDate={ClearWeeklyDate}
                            liKPIValueKeys={liKPIValueKeys}
                            FilterWeeklyAvailabilityData={FilterWeeklyAvailabilityData}
                            clearfilterdata={clearfilterdata}
                            serviceGroups={serviceGroups}
                            services={services}
                            isProd={isProd}
                            servicegroupval={servicegroupval}
                            servicegroupvalchange={servicegroupvalchange}
                            serviceval={serviceval}
                            serviceschange={serviceschange}
                            globalsearch={globalsearch}
                            searchtextboxchange={searchtextboxchange}
                            EditTarget={(e, TargetName) => EditTarget(e, TargetName)}
                            isDataloaded={isDataloaded}
                            IsEdit={IsEdit}
                            ChangeIsEditval={(e, val, rowKey) => ChangeIsEditval(e, val, rowKey)}
                            UndoKpiEdit={(serviceId, viewId, period) => UndoKpiEdit(serviceId, viewId, period)}
                            saveAllWeeklyAvailChanges={saveAllWeeklyAvailChanges}
                            EditKeyValue={EditKeyValue}
                            ChangeWeekAggvalue={(serviceId, viewId, index, val, fieldType) => ChangeWeekAggvalue(serviceId, viewId, index, val, fieldType)}
                            WeeklyAggUpdate={WeeklyAggUpdate}
                            CopyURL={CopyURL}
                            urlparam={urlparam}
                            editedTargetData={editedTargetData}
                            TargetEditPop={TargetEditPop}
                            TargetEditPopClose={TargetEditPopClose}
                            ChangeTargetValue={(val) => ChangeTargetValue(val)}
                            ChangeJustification={(val) => ChangeJustification(val)}
                            originalTargetValue={originalTargetValue}
                            updatedTargetValue={updatedTargetValue}
                            justificationDetails={justificationDetails}
                            SubmitforApproval={SubmitforApproval}
                            ClearTarget={ClearTarget}
                        />
                    </div>
                </section>

                <Modal show={TargetEditPop} onHide={TargetEditPopClose} size='lg' backdrop='static'>
                    <Modal.Header closeButton className="commonModalHeader">
                        <div style={{ width: '100%' }}>
                            <Row>
                                <Col lg='12' style={{ textAlign: 'center' }}>
                                    <h4><b> Update target Value </b></h4>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col lg='12'>
                                <table className="table">
                                    <thead style={{ backgroundColor: 'darkcyan' }}>
                                    </thead>
                                    <tbody style={{ backgroundColor: '#f5ffff' }}>
                                        <tr>
                                            <td colSpan={2} style={{ height: '10px' }}><b>Orginal Target(%)</b></td>
                                            <td colSpan={2} style={{ height: '10px' }}>:</td>
                                            <td colSpan={2} style={{ height: '10px' }}><b> <input type="text" id="originalTarget" value={originalTargetValue} className="form-control" readOnly={true} /></b></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} style={{ height: '10px' }}><span style={{ fontWeight: 'bold', color: 'red' }}>* </span><b>New Target(%)</b></td>
                                            <td colSpan={2} style={{ height: '10px' }}>:</td>
                                            <td colSpan={2} style={{ height: '10px' }}><b> <input type="text" id="newTarget" placeholder='Enter value 99.99%' value={updatedTargetValue} onChange={e => ChangeTargetValue(e.target.value)} className="form-control" /></b></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} style={{ height: '10px' }}><b>Justification/Comments</b></td>
                                            <td colSpan={2} style={{ height: '10px' }}>:</td>
                                            <td colSpan={2} style={{ height: '10px' }}><b><textarea placeholder='Enter Justification/Comments' className='form-control' value={justificationDetails} onChange={e => ChangeJustification(e.target.value)} /></b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='DailydataModelbuttons btn btn-primary' onClick={SubmitforApproval}>Submit request</Button>&emsp;
                        <Button className='DailydataModelbuttons checklistcloseicon btn btn-primary' onClick={ClearTarget}>Clear</Button>
                    </Modal.Footer>
                </Modal>

            </LoadingOverlay>

        </div>
    );

};


const mapStateToProps = (state: IApplicationState) => ({
    ...state.WeeklyAvailability,
    isAuthenticated: state.authUser.isAuthenticated,
    username: state.authUser.login,
    isProd: state.authUser.isProd,

})
export default connect(mapStateToProps, actionCreators)(WeeklyAvailability);