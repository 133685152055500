import { IAppThunkAction, ReduxAction } from '../';
import { ActionType, IPropCriticalAdvisoryData, IPropCriticalAdvisoryState, PropAzureHighPriorityLink } from './types';
import { CriticalAdvisoryAPI, CommonAPI, WeeklyIDSHRAPI } from '../../api';
import { isNullOrUndefined } from '../../utils';
export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    setloadingtrue: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
    },
    setloadingfalse: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_FALSE
        });
    },
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }

    },
    requestCriticalAdvisoryData: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        // Dispatch request
        dispatch({
            type: ActionType.GET_CRITICALADVISORY_REQUEST
        });
        var CriticalAdvisoryInfo = [];
        var AzureHighPriorityLinksInfo = [];
        var CriticalAdvisoryFlyZoneInfo = [];
        var noFlyZoneInfoData = '';
        var noFlyZoneInfo = '';
        var noFlyZoneInfoId = 0;

        CriticalAdvisoryAPI.GetAzureLocations()
            .then((azureLocations: object) => {
                dispatch({
                    AzureLocationDropdown: azureLocations,
                    type: ActionType.GET_AZURELOCATIONS
                });
            });
        CriticalAdvisoryAPI.GetAzureEnvironments()
            .then((azureEnvironments: object) => {
                dispatch({
                    AzureEnvironmentDropdown: azureEnvironments,
                    type: ActionType.GET_AZUREENVIRONMENTS
                });
            });
        CriticalAdvisoryAPI.GetCriticalAdvisoryData()
            .then((AdvisoryData: IPropCriticalAdvisoryData[]) => {
                AdvisoryData.map((obj: IPropCriticalAdvisoryData) => {
                    CriticalAdvisoryInfo.push(obj);
                });          

                CriticalAdvisoryAPI.GetAzureHighPriorityLinksData()
                    .then((AzureHighPriLinksData: PropAzureHighPriorityLink[]) => {
                        AzureHighPriLinksData.map((obj: PropAzureHighPriorityLink) => {
                            AzureHighPriorityLinksInfo.push(obj);
                        });
                        CriticalAdvisoryAPI.GetCriticalAdvisoryNoFlyZoneData()
                            .then((NoFlyZoneData: IPropCriticalAdvisoryData[]) => {
                                NoFlyZoneData.map((obj: IPropCriticalAdvisoryData) => {
                                    CriticalAdvisoryFlyZoneInfo.push(obj);
                                });
                                if (CriticalAdvisoryFlyZoneInfo.length > 0) {
                                    noFlyZoneInfo = CriticalAdvisoryFlyZoneInfo[0].noFlyZoneInfo;
                                    noFlyZoneInfoData = CriticalAdvisoryFlyZoneInfo[0].noFlyZoneInfo;
                                    noFlyZoneInfoId = CriticalAdvisoryFlyZoneInfo[0].noFlyZoneInfoId;
                                }
                                dispatch({
                                    CriticalAdvisoryData: CriticalAdvisoryInfo,
                                    AzureHighPriorityLinksData: AzureHighPriorityLinksInfo,
                                    CriticalAdvisoryFlyZoneData: CriticalAdvisoryFlyZoneInfo,
                                    noFlyZoneInfo: noFlyZoneInfo,
                                    noFlyZoneInfoId: noFlyZoneInfoId,
                                    noFlyZoneInfoData: noFlyZoneInfoData,
                                    type: ActionType.GET_CRITICALADVISORY_SUCCESS
                                });
                            }).catch(error => {
                                dispatch({
                                    type: ActionType.GET_CRITICALADVISORY_FAILURE
                                });
                            });
                    }).catch(error => {
                        dispatch({
                            type: ActionType.GET_CRITICALADVISORY_FAILURE
                        });
                    });
            })
            .catch(error => {
                dispatch({
                    type: ActionType.GET_CRITICALADVISORY_FAILURE
                });
            });

    },
    requestGuidelineInfo: (AdvisoryName, Id): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        var CriticalAdvisoryName = AdvisoryName;
        var CriticalAdvisoryId = Id;
        var GuidelineInfo = '';
        var CriticalAdvisoryInfo = [];
        CriticalAdvisoryAPI.GetCriticalAdvisoryData()
            .then((AdvisoryData: IPropCriticalAdvisoryData[]) => {
                AdvisoryData.map((obj: IPropCriticalAdvisoryData) => {
                    CriticalAdvisoryInfo.push(obj);
                });
                CriticalAdvisoryInfo.map((obj: IPropCriticalAdvisoryData) => {
                    if (obj.advisoryId == Id) {
                        GuidelineInfo = obj.guidelines;
                    }
                });
                dispatch({
                    CriticalAdvisoryName: CriticalAdvisoryName,
                    CriticalAdvisoryId: CriticalAdvisoryId,
                    GuidelineInfo: GuidelineInfo,
                    type: ActionType.GET_CRITICALADVISORY_GUIDELINE_SUCCESS
                });
                dispatch({
                    type: ActionType.SET_LOADING_FALSE
                });
            })
            .catch(error => {
                dispatch({
                    type: ActionType.GET_CRITICALADVISORY_GUIDELINE_FAILURE
                });
                dispatch({
                    type: ActionType.SET_LOADING_FALSE
                });
            });

    },
    checkIsPirRereviewUser: (username): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        WeeklyIDSHRAPI.IsPirRereviewUser(username)
            .then((IsLivesitePMOrUser: boolean) => {
                dispatch({
                    IsLivesitePMOrUser: IsLivesitePMOrUser,
                    type: ActionType.USER_CHECK
                });
            }).catch(error => {
                dispatch({
                    IsLivesitePMOrUser: false,
                    type: ActionType.USER_CHECK
                });
            });
    },
}




