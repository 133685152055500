
import {
    IAllViewDetails, IDailyData, UniqueDateHeaders, UniqueFixedHeaderColumns, IMSERAdditionalInfo, IViewDetails,
    IComments, IColorScheme
} from '../service-health/types';
export interface IActionType {
    readonly RESET_STATE: string;
    readonly ADDED_VISITED_USER: string;
    readonly FETCH_CHECKLIST_TEMPLATE_DATA: string;
    readonly CHECKLIST_TEMPLATE_DATA_SUCCESS: string;
    readonly CHECKLIST_TEMPLATE_DATA_FAIL: string;
    readonly OPEN_TEMPLATE_POPUP: string; 
    readonly SHOWORHIDE_TEMPLATE_POPUP: string; 
    readonly TEMPLATE_DATA_CHANGFE: string;
    readonly TEMPLATE_COPY_QUESTION_CHANGE: string;
    readonly QUESTION_CONTROLS_CHANGFE: string; 
    readonly ADD_QUESTIONS_TOLIST: string; 
    readonly SHOW_QUESTIONLIST: string;
    readonly ENABLE_ADD_NEW_QUESTION: string;
    readonly SET_LOADING_TRUE: string;
    readonly SET_LOADING_FALSE: string;
    readonly TEMPLATE_EDIT: string;
    readonly COPY_QUESTION_TEMPLATE: string;
    readonly COPY_QUESTION_TEMPLATE_FAILURE: string;
    readonly LOGIN_USERNAME_TEMPLATE: string;
    readonly MOVECHECKLISTTEMPLATETOARCHIVE: string;    
    readonly MOVECHECKLISTTEMPLATETOARCHIVE_FAIL: string;
    readonly COPY_TEMPLATE_SELECT: string;
    readonly IS_COPY_TEMP_CHECKVAL: string;
    readonly CHECKLIST_GETTEMPLATE_ROLES_SUCCESS: string;
    readonly CHECKLIST_GETTEMPLATE_ROLES_FAILURE: string;
}



export type ICheckListTemplateState = {
    readonly isLoading: boolean;
    readonly checklisttemplatedata: IPropOnCallDashboardTemplate[];
    readonly showtemplatePopup: boolean;
    readonly template: {};
    readonly questioncontroltypeoptions: [];
    readonly sourcetypes: [];
    readonly dynamicValidationTypes: [];
    readonly templateTypes: [];
    readonly templateRoles: [];
    readonly AuthenticationTypes: [];
    readonly question: {};
    readonly disableaddbuttonforquestion: boolean;
    readonly ShowQuestionList: boolean;
    readonly QuestionarrieList: [];
    readonly istemplatesavebuttondisabled: boolean;
    readonly LoginuserId: string;
    readonly templateNameList: [];
    readonly copiedTemplateQues: IDropdownOptions;
    readonly isCopyTemplateQuestion: boolean;
    readonly copyTempQuesSelect: boolean;
};
export type IDropdownOptions = {
    readonly name: string;
    readonly value: number;
}
export type ITemplateQuestion = {
    readonly questionID: number;
    readonly displayOrder: number;
    readonly effectiveFrom: Date;
    readonly effectiveTo: Date;
}
export type IPropOnCallDashboardTemplate = {
    readonly templateId: number;
    readonly templateName: string;
    readonly templateDescription: string;
    readonly templateNotes: string;
    readonly questionId: string;
    readonly isActive: boolean;
    readonly lastUpdatedBy: string;
    readonly lastUpdatedDate: Date;
    readonly createdBy: string;
    readonly createdDate: Date;
    readonly question: string;
    readonly objPropTemplateQuestion: ITemplateQuestion[];
    readonly effectiveFrom: Date;
    readonly effectiveTo: Date;
    readonly dataUrl: string;
    readonly displayOrder: number;
    readonly checklistCount: number;
    readonly newQuestionList: string;
    readonly templateRole: string;
}

export type IOncallDependentValues= {   
    readonly ICMPicker: boolean;
    readonly ActionItems: boolean;
    }
export type IPropOnCallDashboardQuestionnaire = {
    readonly questionID: number;
    readonly question: string;
    readonly controlType: string;
    readonly controlValues: string;
    readonly isActive: boolean;
    readonly lastUpdatedBy: string;
    readonly lastUpdatedDate: Date;
    readonly isChecked: boolean;
    readonly dataUrl: string;
    readonly effectiveFrom: Date;
    readonly effectiveTo: Date;
    readonly displayOrder: number;
    readonly templateID: number;
    readonly checkListID: number;
    readonly sourceType: string;
    readonly dynamicValidation: string;
    readonly serverName: string;
    readonly database: string;
    readonly authenticationType: string;
    readonly isActionRequired: boolean;
    readonly actionItemValue: string;
    readonly dependsOn: string;
    readonly dependentValue: string;
    readonly matchIndex: number;
    readonly indexValue: number;
    readonly isdisplayDependsonValue: boolean;
    readonly dependsOnValueslist: string;
    readonly dependentValues: IOncallDependentValues[];
    readonly query: string;
    readonly isAnsweredBefore: string;
}

const _namespace = 'ChecklistTemplate ';

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/reset`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    FETCH_CHECKLIST_TEMPLATE_DATA: `${_namespace}/fetchchecklisttemplatedata`,
    CHECKLIST_TEMPLATE_DATA_SUCCESS: `${_namespace}/checklisttemplatedatasuccess`,
    CHECKLIST_TEMPLATE_DATA_FAIL: `${_namespace}/checklisttemplatedatafail`,
    OPEN_TEMPLATE_POPUP: `${_namespace}/opentemplatepopup`,
    SHOWORHIDE_TEMPLATE_POPUP: `${_namespace}/showorhidetemplatepopup`,
    TEMPLATE_DATA_CHANGFE: `${_namespace}/templatedatachange`,
    TEMPLATE_COPY_QUESTION_CHANGE: `${_namespace}/copyTempQuestiondrpChange`,
    QUESTION_CONTROLS_CHANGFE: `${_namespace}/questioncontrolschange`,
    ADD_QUESTIONS_TOLIST: `${_namespace}/addquestionslist`,
    SHOW_QUESTIONLIST: `${_namespace}/showquestionlist`,
    ENABLE_ADD_NEW_QUESTION: `${_namespace}/enableaddnewquestion`,
    SET_LOADING_TRUE: `${_namespace}/loadingtrue`,
    SET_LOADING_FALSE: `${_namespace}/loadingfalse`,
    TEMPLATE_EDIT: `${_namespace}/templateedit`,
    COPY_QUESTION_TEMPLATE: `${_namespace}/copyQuestionTemplate`,
    COPY_QUESTION_TEMPLATE_FAILURE: `${_namespace}/copyQuestionTemplateFailure`,
    LOGIN_USERNAME_TEMPLATE: `${_namespace}/loginUserNameTemplate`,
    MOVECHECKLISTTEMPLATETOARCHIVE: `${_namespace}/movechecklisttemplatetoarchive`,    
    MOVECHECKLISTTEMPLATETOARCHIVE_FAIL: `${_namespace}/movechecklisttemplatetoarchive_fail`,
    COPY_TEMPLATE_SELECT: `${_namespace}/copyTemplateSelect`,
    IS_COPY_TEMP_CHECKVAL: `${_namespace}/isCopyTempCheckVal`,
    CHECKLIST_GETTEMPLATE_ROLES_SUCCESS: `${_namespace}/checklistgettemplaterolessuccess`,
    CHECKLIST_GETTEMPLATE_ROLES_FAILURE: `${_namespace}/checklistgettemplaterolesFailure`
});

