import React, { useState, Fragment, useRef, useEffect } from 'react';
import { useScreenshot } from "use-react-screenshot";
import DatePicker, { getDay } from 'react-datepicker'
import { ReduxAction, IAppThunkAction } from '../../../store';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import { IPropidentityNewsLetterGroupData, IPropIdentityWeeklyAvailability, IPropIdentityTopOutages, IPropIdentityOKRs, IPropIdentityTopOutagesTable } from '../../../store/IdentityWeeklyNewsLetter';
import { faPencilAlt, faTrash, faSave, faBan, faStreetView } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIconMemo } from '../../../components';
import { Picky } from 'react-picky';
import Highcharts from 'highcharts';
import { Editor } from 'primereact/editor';
import { Dialog } from 'primereact/dialog';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { Link } from 'react-router-dom';
import RichTextEditor from 'react-rte';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models, Report, Embed, service, } from 'powerbi-client';
import 'powerbi-report-authoring';

type IdentityWeeklyNewsLetterProps = {
    readonly newsLetterGroupsData: IPropidentityNewsLetterGroupData[];
    IsLivesitePMOrUser: boolean;
    WeekStartDate: any;
    readonly GetWeekStartDate: any;
    newsLetterWeeklyAvailabilityData: any;
    newsLetterWeeklySafeflyData: any;
    newsLetterWeeklySafeflyDataOptions: any;
    SafeflyMetricsTotal: any;
    newsLetterWeeklyChangeToolData: any;
    newsLetterWeeklyChangeToolDataOptions: any;
    ChangeToolMetricsTotal: any;
    newsLetterWeeklyChangeExceptionData: any;
    newsLetterWeeklyChangeExceptionDataOptions: any;
    newsLetterSev1Sev2IncidentsData: any;
    newsLetterSev1Sev2IncidentsDataOptions: any;
    ChangeExceptionMetricsTotal: any;
    newsLetterObjectivesData: any;
    newsLetterOKRsData: any;
    newsLettertopOutagesData: any;
    newsLettertopOutagesDataTable: any;
    UpdatetopOutagedata: any;
    TopOutageDataEditCancel: any;
    DeleteTopOutagesData: any;
    IdentitynewsletterTopOutagesDataSave: any;
    SendMailIdentityNewsletterDashboard: any;
    openOKRsViewpopup: any;
    sendEmailLoading: any;
    appSessionToken: any;
    sev1TotalCount: any;
    sev2TotalCount: any;
    newsLetterWeeklyOutagesData: any;
    newsLetterWeeklyOutagesDataOptions: any;
    newsLetterWeeklyCommsData: any;
    newsLetterWeeklyCommsDataOptions: any;
    outagesWeeksHeaderList: any;
    outagesWeeklySortedData: any;
    commsWeeklySortedData: any;
    commsWeeksHeaderList: any;
};

const IdentityWeeklyNewsLetterTable = React.memo<IdentityWeeklyNewsLetterProps>(({
    newsLetterGroupsData,
    IsLivesitePMOrUser,
    WeekStartDate,
    GetWeekStartDate,
    newsLetterWeeklyAvailabilityData,
    newsLetterWeeklySafeflyData,
    newsLetterWeeklySafeflyDataOptions,
    SafeflyMetricsTotal,
    newsLetterWeeklyChangeToolData,
    newsLetterWeeklyChangeToolDataOptions,
    ChangeToolMetricsTotal,
    newsLetterWeeklyChangeExceptionData,
    newsLetterWeeklyChangeExceptionDataOptions,
    newsLetterSev1Sev2IncidentsData,
    newsLetterSev1Sev2IncidentsDataOptions,
    ChangeExceptionMetricsTotal,
    newsLetterObjectivesData,
    newsLetterOKRsData,
    newsLettertopOutagesData,
    newsLettertopOutagesDataTable,
    UpdatetopOutagedata,
    TopOutageDataEditCancel,
    DeleteTopOutagesData,
    IdentitynewsletterTopOutagesDataSave,
    SendMailIdentityNewsletterDashboard,
    openOKRsViewpopup,
    sendEmailLoading,
    appSessionToken,
    sev1TotalCount,
    sev2TotalCount,
    newsLetterWeeklyOutagesData,
    newsLetterWeeklyOutagesDataOptions,
    newsLetterWeeklyCommsData,
    newsLetterWeeklyCommsDataOptions,
    outagesWeeksHeaderList,
    outagesWeeklySortedData,
    commsWeeklySortedData,
    commsWeeksHeaderList
}) => {
    const [token, setToken] = useState(sessionStorage.getItem("adal.idtoken"));
    const [isPicloadingLoaded, setisPicloadingLoaded] = useState(1);
    const [outagesev1Customer, setOutagesev1Customer] = useState(RichTextEditor.createValueFromString(newsLettertopOutagesData.sev1Customer, "html"));
    const [outagesev2Customer, setOutagesev2Customer] = useState(RichTextEditor.createValueFromString(newsLettertopOutagesData.sev2Customer, "html"));
    const [outagewithExternal, setoutagewithExternal] = useState(RichTextEditor.createValueFromString(newsLettertopOutagesData.outageswithExternal, "html"));
    const [outageIncident, setOutageIncident] = useState(RichTextEditor.createValueFromString(newsLettertopOutagesData.incident, "html"));
    const [outagetopOutageTableIncident, settopOutageTableOutageIncident] = useState(RichTextEditor.createValueFromString(newsLettertopOutagesData.topOutageTable, "html"));
    useEffect(() => {
        if ((previousValues.current.WeelyAvailabilityImage != WeelyAvailabilityImage || refWeekly.current.innerText == '') &&
            (previousValues.current.TopOutageTableImage != TopOutageTableImage || refTopOutageTable.current.innerText == '') &&
            (previousValues.current.TopOutageimageCircle != TopOutageimageCircle || refTopOutageCircle.current.innerText == '') &&
            (previousValues.current.ChangeMgmtImage != ChangeMgmtImage || refChangeMgmt.current.innerText == '') &&
            (previousValues.current.OKRImage != OKRImage || refOKR.current.innerText == '') &&
            (previousValues.current.Outages != Outages || ref90Outage.current.innerText == '') &&
            (previousValues.current.OutagesComms != OutagesComms || ref90Comms.current.innerText == '') &&
            previousValues.current.isPicloadingLoaded != isPicloadingLoaded || (previousValues.current.isPicloadingLoaded != isPicloadingLoaded && isPicloadingLoaded > 2)) {
            previousValues.current = { TopOutageTableImage, WeelyAvailabilityImage, TopOutageimageCircle, OKRImage, ChangeMgmtImage, isPicloadingLoaded, Outages, OutagesComms }
            SendMailIdentityNewsletterDashboard(TopOutageTableImage,WeelyAvailabilityImage, TopOutageimageCircle, OKRImage, ChangeMgmtImage, Outages, OutagesComms);
        }
    })

    const sendMailLoadingContent = () => {
        sendEmailLoading();       
    }

    const sendMail = () => {        
        WeelyAvailabilityScreenshot(refWeekly.current);
        TopOutageTableScreenshot(refTopOutageTable.current);
        TopOutageScreenshotCircle(refTopOutageCircle.current);
        OKRScreenshot(refOKR.current);
        ChangeMgmtScreenshot(refChangeMgmt.current);
        OutagesScreenshot(ref90Outage.current);
        OutagesCommsScreenshot(ref90Comms.current);
        setisPicloadingLoaded(isPicloadingLoaded + 1);
    }


    const [topOutageEdit, settopOutageEdit] = useState(false);
    const [newsletterdeleteTopOutageDialog, setnewsletterdeleteTopOutageDialog] = useState(false);
    const [report, setReport] = useState<Report>();
    const refWeekly = useRef(null);
    const refTopOutageTable = useRef(null);
    const refTopOutageCircle = useRef(null);
    const refOKR = useRef(null);
    const refChangeMgmt = useRef(null);
    const ref90Outage = useRef(null);
    const ref90Comms = useRef(null);

    const [WeelyAvailabilityImage, WeelyAvailabilityScreenshot] = useScreenshot();
    const [TopOutageTableImage, TopOutageTableScreenshot] = useScreenshot();
    const [TopOutageimageCircle, TopOutageScreenshotCircle] = useScreenshot();
    const [OKRImage, OKRScreenshot] = useScreenshot();
    const [ChangeMgmtImage, ChangeMgmtScreenshot] = useScreenshot();
    const [Outages, OutagesScreenshot] = useScreenshot();
    const [OutagesComms, OutagesCommsScreenshot] = useScreenshot();

    const previousValues = useRef({ TopOutageTableImage, WeelyAvailabilityImage, TopOutageimageCircle, OKRImage, ChangeMgmtImage, isPicloadingLoaded, Outages, OutagesComms });

    const isWeekday = (date) => {
        var day = new Date(moment(date).format('MM/DD/YYYY')).getDay();
        return day == 6;
    };

    const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
            { label: 'Italic', style: 'ITALIC' },
            { label: 'Underline', style: 'UNDERLINE' }
        ],
        BLOCK_TYPE_DROPDOWN: [
            { label: 'Normal', style: 'unstyled' },
            { label: 'Heading Large', style: 'header-one' },
            { label: 'Heading Medium', style: 'header-two' },
            { label: 'Heading Small', style: 'header-three' }
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item' },
            { label: 'OL', style: 'ordered-list-item' }
        ]
    };

    const movescrollbar = () => {
        window.scrollTo(0, 0);
    }

    //const setOutageIncidentValue = (e) => {
    //    setOutageIncident(e);
    //}

    //================ function to set Outage Incident Info Value ========//
    const UpdatenoFlyZoneInfoPopup = (e) => {
        //setnoFlyZoneInfoPopup(e);
        setOutageIncident(e);
    }

    const header = (
        <span className="ql-formats">
            <button className="ql-bold" aria-label="Bold"></button>
            <button className="ql-italic" aria-label="Italic"></button>
            <button className="ql-underline" aria-label="Underline"></button>
            <button className="ql-link" aria-label="Insert Link"></button>
            <button type="button" className="ql-list" value="ordered" aria-label="Ordered List"></button>
            <button type="button" className="ql-list" value="bullet" aria-label="UnOrdered List"></button>
            <span className="ql-align ql-picker ql-icon-picker" style={{ display: 'none' }}></span>
            <select className="ql-align"></select>
            <span className="ql-header ql-picker" style={{ display: 'none' }}></span>
            <select className="ql-header"></select>
            <span className="ql-font ql-picker" style={{ display: 'none' }}></span>
            <select className="ql-font"></select>
        </span>
    );

    const TopOutageEdit = () => {
        settopOutageEdit(true);
        setOutagesev1Customer(RichTextEditor.createValueFromString(newsLettertopOutagesData.sev1Customer, "html"));
        setOutagesev2Customer(RichTextEditor.createValueFromString(newsLettertopOutagesData.sev2Customer, "html"));
        setoutagewithExternal(RichTextEditor.createValueFromString(newsLettertopOutagesData.outageswithExternal, "html"));
        setOutageIncident(RichTextEditor.createValueFromString(newsLettertopOutagesData.incidentNotes, "html"));
        settopOutageTableOutageIncident(RichTextEditor.createValueFromString(newsLettertopOutagesData.topOutageTable, "html"));
    };

    const deleteTopOutageDialogFooter = (
        <React.Fragment>
            <span style={{ display: 'flex', justifyContent: 'center' }}>
                <Button className="p-button-text btn-primary" onClick={() => deletetopOutageConfirmed()}>Yes</Button>
                <Button className="p-button-text btn-primary" onClick={() => hideDeleteTopOutageDialog()} >No</Button>
            </span>
        </React.Fragment>
    );

    const TopOutageDeleteCheck = () => {
        setnewsletterdeleteTopOutageDialog(true);
    };

    const hideDeleteTopOutageDialog = () => {
        setnewsletterdeleteTopOutageDialog(false);
    }

    const savetopOutageData = () => {
        IdentitynewsletterTopOutagesDataSave();
        settopOutageEdit(false);
    }

    const deletetopOutageConfirmed = () => {
        DeleteTopOutagesData();
        setnewsletterdeleteTopOutageDialog(false);
    }

    const CancelEditTopOutageData = () => {
        settopOutageEdit(false);
        TopOutageDataEditCancel();
    };

    // To Compare the identity weekly data with target values
    const AddColorForWeeklyAvailability = (data, target) => {
        var weekColor = { Color: "" };
        data = (parseFloat(data) < 1 && parseFloat(data) > 0) ? (data * 100).toFixed(4) + "%" : data;
        var reg = /[0-9][0-9]\.[0-9][%]/;
        var availabilityTarget = parseFloat(target);
        if (availabilityTarget != null) {
            OperatorOverloadAvailabilityBudget(data, availabilityTarget, weekColor, "<=");
        } else if (/[0-9]+/.test(target)) {
            OperatorOverloadAvailabilityBudget(data, target, weekColor, ">=");
        }
        return weekColor.Color;
    }
    // To Format the weekly data
    const OperatorOverloadAvailabilityBudget = (data, target, weekColor, operator) => {
        data = String(data).replace(/[(\)]/gi, "");
        weekColor.Color = SetColorForWeekAvailabilityBudget(data, target, operator);
    }
    const SetColorForWeekAvailabilityBudget = (weekVal, target, operator) => {
        var color = "";
        var wk1 = String(weekVal).split("/");
        for (var i = 0; i < wk1.length; i++) {
            if (wk1[i] == "") {
                color = "newslettergrey";
            } else if (wk1[i] != "NA" && !isNaN(parseFloat(wk1[i]))) { // IF not a number or NA
                if (operator == ">=") {
                    if (parseFloat(wk1[i]) > target) {
                        color = "newsletterred";
                        break;
                    } else {
                        color = "newslettergreen";
                    }
                } else if (operator == "<=") {
                    if (parseFloat(wk1[i]) < target) {
                        color = "newsletterred";
                        break;
                    } else {
                        color = "newslettergreen";
                    }
                } else if (operator == "<") {
                    if (parseFloat(wk1[i]) <= target) {
                        color = "newsletterred";
                        break;
                    } else {
                        color = "newslettergreen";
                    }
                }
            }
            if (/[sec]{3}/.test(wk1[i])) {
                break;
            }
        }

        return color;
    }

    // API end-point url to get embed config for a sample report
    const sampleReportUrl = 'https://playgroundbe-bck-1.azurewebsites.net/Reports/SampleReport';

    // Report config useState hook
    // Values for properties like embedUrl, accessToken and settings will be set on click of buttons below
    const [sampleReportConfig, setReportConfig] = useState<models.IReportEmbedConfiguration>({
        type: 'report',
        embedUrl: undefined,
        tokenType: models.TokenType.Embed,
        accessToken: undefined,
        settings: undefined,
    });

    // Map of event handlers to be applied to the embedding report
    const eventHandlersMap = new Map([
        ['loaded', function () {
            console.log('Report has loaded');
        }],
        ['rendered', function () {
            console.log('Report has rendered');

            // Update display message
            setMessage('The report is rendered')
        }],
        ['error', function (event?: service.ICustomEvent<any>) {
            if (event) {
                console.error(event.detail);
            }
        }]
    ]);

    // Fetch sample report's config (eg. embedUrl and AccessToken) for embedding
    const mockSignIn = async () => {

        // Fetch sample report's embed config
        const reportConfigResponse = await fetch(sampleReportUrl);
        if (!reportConfigResponse.ok) {
            console.error(`Failed to fetch config for report. Status: ${reportConfigResponse.status} ${reportConfigResponse.statusText}`);
            return;
        }

        const reportConfig = await reportConfigResponse.json();

        // Update display message
        setMessage('The access token is successfully set. Loading the Power BI report')

        // Set the fetched embedUrl and embedToken in the report config
        setReportConfig({
            ...sampleReportConfig,
            embedUrl: reportConfig.EmbedUrl,
            accessToken: reportConfig.EmbedToken.Token
        });
    };
    const [displayMessage, setMessage] = useState(`The report is bootstrapped. Click the Embed Report button to set the access token`);
    return (
        <React.Fragment>
            <br />
            <div style={{ width: '100%' }}>
                <Card className="cardstyleAdmin">
                    <CardBody className="cardbodystyleAdmin">
                        <Row>
                            <Col lg="3">
                            </Col>
                            <Col lg="4">
                                <h3 className="newsHeadertext newsheader"> Identity Weekly NewsLetter</h3>
                            </Col>
                            <Col lg="3">
                                <div className="aligntable">
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '60%' }}></td>
                                                <td style={{ width: '40%' }}>
                                                    <div id="DateSelectorDiv" className="btn btn-primary btn-sm filter-button weeklytd">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ width: '15%' }}></td>
                                                                    <td style={{ width: '15%' }}>
                                                                        <div>
                                                                            <label style={{ fontSize: '20px' }} htmlFor='fromdate'> <FontAwesomeIconMemo icon={'calendar'} className='icon-format' /></label>&nbsp;
                                                                        </div>
                                                                    </td>
                                                                    <td style={{ width: '40%' }}>
                                                                        <DatePicker className='InputStyle'
                                                                            id={'fromdate'}
                                                                            selected={WeekStartDate}
                                                                            onChange={GetWeekStartDate}
                                                                            filterDate={isWeekday}
                                                                            maxDate={new Date()}
                                                                            minDate={new Date(2018, 11, 1)}
                                                                        />  &nbsp;&nbsp;
                                                                    </td>
                                                                    <td style={{ width: '30%' }}></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                            <Col lg="1" className="alignmailbtn"><button type="button" className="btn btn-primary btn-sm filter-button sendmailbtn"
                                onClick={() => sendMail()}>Send Mail</button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
            < br />
            <div style={{ width: '100%' }}>
                <Row>
                    <Col lg="12" >

                        <div>
                            <Row className="weeklyNewsHeader">
                                <Col lg="12">
                                    <h4 style={{ textAlign: 'center' }}>Weekly Availability</h4>
                                </Col>
                            </Row>
                        </div>
                        <div ref={refWeekly}>
                            <div id='weeklyNewsID' style={{ backgroundColor: 'azure' }}>
                                <table id='weeklyavailabilityNewsletter'>
                                    <tbody>
                                        {isArrayWithLength(newsLetterGroupsData) ?
                                            <React.Fragment>
                                                {newsLetterGroupsData.map((groupitem: IPropidentityNewsLetterGroupData, groupindex) => (
                                                    <React.Fragment key={groupindex}>
                                                        <tr>
                                                            <td>
                                                                <table className="weeklyavailabilitytable">
                                                                    <tbody>
                                                                        <tr style={{ fontSize: '14px' }}>
                                                                            <td style={{ paddingTop: '10px' }}>
                                                                                <div className="weeklyAvailabilityflexcontainer">
                                                                                    {
                                                                                        isArrayWithLength(newsLetterWeeklyAvailabilityData) &&
                                                                                        newsLetterWeeklyAvailabilityData
                                                                                            .filter((ele: IPropIdentityWeeklyAvailability) => {
                                                                                                if (ele.identityNewsLetterGroup === groupitem.groupName) {
                                                                                                    return true
                                                                                                }
                                                                                            })
                                                                                            .map((dhs: IPropIdentityWeeklyAvailability, key) => (
                                                                                                <React.Fragment key={key}>
                                                                                                    <span className="weekavailabilitysquarepadding">
                                                                                                        <div className={`weeklyAvailabilityflexcontainerView weekavailabilitysquare ${AddColorForWeeklyAvailability(dhs.weeklyAvailability, dhs.targetName)}`}>
                                                                                                            <div className="textalignmentweeklyavailability">
                                                                                                                <span>{dhs.serviceName}<br /></span>
                                                                                                                {
                                                                                                                    (dhs.weeklyAvailability === null || dhs.weeklyAvailability === "") ?
                                                                                                                        <React.Fragment></React.Fragment>
                                                                                                                        : <React.Fragment><span>{dhs.weeklyAvailability}%<br /></span></React.Fragment>
                                                                                                                }
                                                                                                                <Link className="fontstyleview" rel='tooltip' title='Click to View the Service Health Daily Data' to={'/ServiceHealth?serviceid=' + dhs.serviceID + '&viewId=' + dhs.viewId} target='_blank' >
                                                                                                                    {dhs.viewName}
                                                                                                                </Link>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </span>
                                                                                                </React.Fragment>
                                                                                            ))
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr style={{ color: 'Black', fontWeight: 'bold' }}>
                                                                            <td>{groupitem.groupName} - {groupitem.weeklyAggAvailability}%</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                ))}</React.Fragment>
                                            : <React.Fragment>

                                            </React.Fragment>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br />
                        <Row className="topOutageNewsHeader">
                            <Col lg="10">
                                <h4 style={{ textAlign: 'center' }}> Top Outages</h4>
                            </Col>
                            <Col lg="2" className="paddingEditSectionTopOutages">

                                {
                                    (!topOutageEdit)
                                        ?
                                        <div style={{ textAlign: 'right' }}>
                                            <span onClick={e => TopOutageEdit()}>
                                                <FontAwesomeIcon title='Click to edit the Top Outages' className=" IconsstyleTopOutages" icon={faPencilAlt} />
                                            </span>&ensp;
                                            <span onClick={e => TopOutageDeleteCheck()}>
                                                <FontAwesomeIcon title='Click to Delete the Top Outages' className=" IconsstyleTopOutages" icon={faTrash} />
                                            </span> &ensp;&ensp;
                                        </div>
                                        :
                                        <div style={{ textAlign: 'right' }}>
                                            <span onClick={e => savetopOutageData()}>
                                                <FontAwesomeIcon title='Save Changes' className=" IconsstyleTopOutages" icon={faSave} />
                                            </span>&ensp;
                                            <span onClick={e => CancelEditTopOutageData()}>
                                                <FontAwesomeIcon title='Cancel Edit' className=" IconsstyleTopOutages" icon={faBan} />
                                            </span>
                                        </div>
                                }

                            </Col>
                        </Row>
                        <div id="TopOutages" style={{ width: '100%' }}>
                            {
                                !(isNullOrUndefined(newsLettertopOutagesDataTable)) ?
                                    <React.Fragment>
                                        <div>
                                            <Row style={{ width: '100%' }}>
                                                <Col lg="12">
                                                    <br />
                                                    <div ref={refTopOutageTable}>
                                                        {/*<table className="outageTableSection">*/}
                                                        {/*    <thead>*/}
                                                        {/*        <tr className="topOutageFristTableHeader">*/}
                                                        {/*            <th className="topOutageFirstColthstyle">Severity</th>*/}
                                                        {/*            <th className="topOutageSecondColthstyle">IncidentId</th>*/}
                                                        {/*            <th className="topOutageThirdColthstyle">TenantTeam</th>*/}
                                                        {/*            <th className="topOutageThirdColthstyle">TeamName</th>*/}
                                                        {/*            <th className="topOutageSecondColthstyle">ImpactStart UTC</th>*/}
                                                        {/*            <th className="topOutageForthtoNinethColthstyle">Incident Created UTC</th>*/}
                                                        {/*            <th className="topOutageForthtoNinethColthstyle">Outage Declared UTC</th>*/}
                                                        {/*            <th className="topOutageForthtoNinethColthstyle">Notified UTC</th>*/}
                                                        {/*            <th className="topOutageForthtoNinethColthstyle">Mitigate UTC</th>*/}
                                                        {/*            <th className="topOutageForthtoNinethColthstyle">TTM</th>*/}
                                                        {/*            <th className="topOutageRestOfColthstyle">TTN</th>*/}
                                                        {/*            <th className="topOutageRestOfColthstyle">Impacted Tenants</th>*/}
                                                        {/*            <th className="topOutageRestOfColthstyle">Sub Count</th>*/}
                                                        {/*            <th className="topOutageRestOfColthstyle">PIR Status</th>*/}
                                                        {/*        </tr>*/}
                                                        {/*    </thead>*/}
                                                        {/*    <tbody>*/}
                                                        {/*        {*/}
                                                        {/*            newsLettertopOutagesDataTable.map((row: IPropIdentityTopOutagesTable, index) => (*/}
                                                        {/*                <React.Fragment key={index}>*/}
                                                        {/*                    <tr className="topOutageDataRows">*/}
                                                        {/*                        <td className="topOutageFirstColthstyle"><div >{row.severity}</div></td>*/}
                                                        {/*                        <td className="topOutageSecondColthstyle"><div >{row.incidentId}</div></td>*/}
                                                        {/*                        <td className="topOutageThirdColthstyle"><div >{row.tenantteam}</div></td>*/}
                                                        {/*                        <td className="topOutageThirdColthstyle"><div >{row.teamName}</div></td>*/}
                                                        {/*                        <td className="topOutageSecondColthstyle"><div >{row.impactStartTimeUTC}</div></td>*/}
                                                        {/*                        <td className="topOutageForthtoNinethColthstyle"><div >{row.incidentCreateDateUTC}</div></td>*/}
                                                        {/*                        <td className="topOutageForthtoNinethColthstyle"><div >{row.outageDeclaredDateUTC}</div></td>*/}
                                                        {/*                        <td className="topOutageForthtoNinethColthstyle"><div >{row.notifiedTimeUTC}</div></td>*/}
                                                        {/*                        <td className="topOutageForthtoNinethColthstyle"><div >{row.mitigateDateUTC}</div></td>*/}
                                                        {/*                        <td className="topOutageForthtoNinethColthstyle"><div >{row.ttm}</div></td>*/}
                                                        {/*                        <td className="topOutageRestOfColthstyle"><div >{row.ttn}</div></td>*/}
                                                        {/*                        <td className="topOutageRestOfColthstyle"><div >{row.impactedtenants}</div></td>*/}
                                                        {/*                        <td className="topOutageRestOfColthstyle"><div >{row.subCount}</div></td>*/}
                                                        {/*                        <td className="topOutageRestOfColthstyle"><div >{row.pirStatus}</div></td>*/}
                                                        {/*                    </tr>*/}
                                                        {/*                </React.Fragment>*/}
                                                        {/*            ))}*/}
                                                        {/*    </tbody>*/}
                                                        {/*</table>*/}
                                                    </div>
                                                    {/*<table className="outageTableSection">*/}
                                                    {/*    <tr>*/}
                                                    {/*        <td style={{ width: '500px' }}></td>*/}
                                                    {/*        <td style={{ width: '100px' }}>*/}
                                                    {/*            <a href="https://icmdocs.azurewebsites.net/reporting/outage-definition-ttx.html" target="_blank">Outage definition</a>*/}
                                                    {/*        </td>*/}
                                                    {/*    </tr>*/}
                                                    {/*</table>*/}
                                                </Col>
                                            </Row>

                                            <Row style={{ width: '100%' }}>
                                                <Col lg="12">
                                                    < br />
                                                    <Row>
                                                        {
                                                            (!topOutageEdit)
                                                                ?
                                                                <React.Fragment>
                                                                    <Col lg="12">
                                                                        <table className="topOutageTableOuter">
                                                                            <tr>
                                                                                <td>
                                                                                    < br />
                                                                                    <div className="NewsLetterRTELIdots topOutageTableIncidentsFormat" dangerouslySetInnerHTML={{ __html: newsLettertopOutagesData.topOutageTable }}></div>
                                                                                </td>
                                                                            </tr>
                                                                            {/*<tr>*/}
                                                                            {/*    <table className="outageTableSection">*/}
                                                                            {/*        <tr>*/}
                                                                            {/*            <td style={{ width: '650px' }}></td>*/}
                                                                            {/*            <td style={{ width: '130px' }}><label className="legend-format"><FontAwesomeIconMemo icon={"stop"} className="icon-newsletter-legend-orangered" />&nbsp;Detected Deployment Ring</label></td>*/}
                                                                            {/*            <td style={{ width: '130px' }}><label className="legend-format"><FontAwesomeIconMemo icon={"stop"} className="icon-newsletter-legend-green" />&nbsp;Total Deployment Rings</label></td>*/}
                                                                            {/*            <td style={{ width: '100px' }}>*/}
                                                                            {/*                <a href="https://icmdocs.azurewebsites.net/reporting/outage-definition-ttx.html" target="_blank">Outage definition</a>*/}
                                                                            {/*            </td>*/}
                                                                            {/*        </tr>*/}
                                                                            {/*    </table>*/}
                                                                            {/*</tr>*/}
                                                                            <tr>
                                                                                <td className="topOutageTableSummaryTD">
                                                                                    < br />
                                                                                    <div className="NewsLetterRTELIdots topOutageTableIncidentsFormat" dangerouslySetInnerHTML={{ __html: newsLettertopOutagesData.incidentNotes }}></div>
                                                                                </td>
                                                                            </tr>
                                                                            <br />
                                                                            <tr >
                                                                                <td>
                                                                                    <table className="topOutageAddInfoTable">
                                                                                        <thead>
                                                                                            <tr className="topOutageTableHeaderRow">
                                                                                                <th className="topOutageAddInfoTableTH">Additional Info</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <br />
                                                                                        <tbody>
                                                                                            <div id="TopOutages" style={{ width: '100%', paddingLeft: '30%' }} ref={refTopOutageCircle}>
                                                                                                <tr className="topOutageTableDataRow">                                                                                                   
                                                                                                    <td className="topOutageAddInfoTableTD">
                                                                                                        <div className="topOutagescircle">
                                                                                                            <text className="topOutagesCircletext"># of Sev1<br />Incidents<br /><span className="ValueClass">{(newsLettertopOutagesData.numberOfSev1 == '0' ? sev1TotalCount : newsLettertopOutagesData.numberOfSev1)}</span></text>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td className="topOutageAddInfoTableTD">
                                                                                                        <div className="topOutagescircle">
                                                                                                            <text className="topOutagesCircletext"># of Sev2<br />Incidents<br /><span className="ValueClass">{(newsLettertopOutagesData.numberOfSev2 == '0' ? sev2TotalCount : newsLettertopOutagesData.numberOfSev2)}</span></text>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td className="topOutageAddInfoTableTD">
                                                                                                        <div className="topOutagescircle">
                                                                                                            <text className="topOutagesCircletext"># of Unique<br />CCL Bridges<br /><span className="ValueClass">{newsLettertopOutagesData.numberOfUniqueCCL}</span></text>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </div>

                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>

                                                                        </table>
                                                                    </Col>
                                                                </React.Fragment>
                                                                :
                                                                <React.Fragment>
                                                                    <Col lg="12">
                                                                        <table className="topOutageTable">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td >
                                                                                        < br />
                                                                                        <Editor placeholder="Incident Notes" value={newsLettertopOutagesData.incidentNotes} onTextChange={(event) => UpdatetopOutagedata(event, 'incidentNotes')} headerTemplate={header} />
                                                                                    </td>
                                                                                </tr>                                                                              
                                                                                <tr >
                                                                                    <td>
                                                                                        <table className="topOutageTable">
                                                                                            <thead>
                                                                                                <tr className="topOutageTableHeaderRow">
                                                                                                    <th className="topOutageAddInfoTableTH">Additional Info</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <div id="TopOutages" style={{ width: '100%', paddingLeft: '30%' }} ref={refTopOutageCircle}>
                                                                                                    <tr className="topOutageTableDataRow">                                                                                                       
                                                                                                        <td className="topOutageAddInfoTableTD">
                                                                                                            <input className="form-control inputTypeTopOutage" value={newsLettertopOutagesData.numberOfSev1} name="numberOfSev1" onChange={(event) => UpdatetopOutagedata(event, 'numberOfSev1')} />
                                                                                                        </td>
                                                                                                        <td className="topOutageAddInfoTableTD">
                                                                                                            <input className="form-control inputTypeTopOutage" value={newsLettertopOutagesData.numberOfSev2} name="numberOfSev2" onChange={(event) => UpdatetopOutagedata(event, 'numberOfSev2')} />
                                                                                                        </td>
                                                                                                        <td className="topOutageAddInfoTableTD">
                                                                                                            <input className="form-control inputTypeTopOutage" value={newsLettertopOutagesData.numberOfUniqueCCL} name="numberOfUniqueCCL" onChange={(event) => UpdatetopOutagedata(event, 'numberOfUniqueCCL')} />
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </div>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </Col>
                                                                    < br />
                                                                </React.Fragment>
                                                        }
                                                    </Row>
                                                    <br />

                                                </Col>
                                            </Row>
                                        </div>
                                    </React.Fragment>
                                    : <React.Fragment>No Data Found !!</React.Fragment>
                            }
                            <br />

                        </div>
                        <br />

                        <Row className="OKRNewsheader">
                            <Col lg="10">
                                <h4 style={{ textAlign: 'center' }}> Divisional Objectives and Key Results (OKR)</h4>
                            </Col>
                            <Col lg="2" className="paddingEditSectionTopOutages">
                                <span style={{ color: 'white', fontSize: '13px' }} className="fontstyleview" onClick={openOKRsViewpopup} title='Click to Add/Edit the OKRs Data'>
                                    OKRs View&nbsp;
                                    <FontAwesomeIcon title='Click to Add/Edit the OKRs Data' onClick={openOKRsViewpopup} className=" IconsstyleTopOutages" icon={faStreetView} />
                                </span>
                            </Col>
                        </Row>

                        {
                            !(isNullOrUndefined(newsLetterObjectivesData)) ?
                                <React.Fragment>
                                    <div>
                                        <Row className="KeyResultsHeader">
                                            <Col lg="12">
                                                <h5 style={{ textAlign: 'center' }}>O : {newsLetterObjectivesData.name}</h5>
                                            </Col>
                                        </Row>
                                        <br />
                                        <div id="OKRs" style={{ width: '100%', paddingLeft: '30%' }} ref={refOKR}>
                                            <Row style={{ textAlign: 'center' }}>
                                                {isArrayWithLength(newsLetterOKRsData) &&
                                                    newsLetterOKRsData
                                                        .filter((ele: IPropIdentityOKRs) => {
                                                            if (ele.objectiveID === newsLetterObjectivesData.objectiveID) {
                                                                return true
                                                            }
                                                        })
                                                        .map((dhs: IPropIdentityOKRs, key) => (
                                                            <React.Fragment key={key}>
                                                                <div style={{ margin: '10px 5px 10px 5px' }}>
                                                                    <span className="OKRscircle">
                                                                        <text className={`okrstextalignment OKRstext`}>{dhs.keyResults}<br /><span className="ValueClass">{dhs.progressValue}</span></text>
                                                                    </span>&ensp;&ensp;
                                                                </div>
                                                            </React.Fragment>
                                                        ))}
                                            </Row>
                                        </div>
                                    </div>
                                </React.Fragment>
                                : <React.Fragment>No Data Found !!</React.Fragment>
                        }
                        <br />
                        <Row className="changeMgmtsNewsHeader">
                            <Col lg="12">
                                <h4 style={{ textAlign: 'center' }}>Number of sdp and non-sdp Approved for the Week</h4>
                            </Col>
                        </Row>
                        <br />
                        <div id="ChangeRequests" ref={refChangeMgmt}>
                            <Row>
                                <Col className="sdpTitle"  lg="12">
                                    <div><b>Safe fly metrics</b> consists of sdp and non-sdp metrics for all IDNA services</div>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col lg="2">

                                </Col>
                                <Col lg="8">
                                    <table className="changerequestsTable">
                                        <thead><tr className="safeflylegendtrstyle"><th colSpan={2} className="legendthstyle">Safe Fly Tool (SDP) </th></tr></thead>
                                        <tbody>
                                            <tr className="safeflysecondrow">
                                                <td><div className="divtablechangeRequests"><div className="safeflylegendtdweight">Total</div><div className="legendtdalign">{SafeflyMetricsTotal}</div></div></td>
                                            </tr>
                                            <tr className="safeflyfirstrow">
                                                <td><div className="divtablechangeRequests"><div className="safeflylegendtdweight">New</div><div className="legendtdalign">New/Draft </div></div></td>
                                            </tr>
                                            <tr className="safeflysecondrow">
                                                <td><div className="divtablechangeRequests"><div className="safeflylegendtdweight">Completed</div><div className="legendtdalign">Approved/ApprovedWithSuggestion /ApprovedWithLease/Completed /CompletedWithSuggestion/CompletedWithLease
                                                </div></div></td>
                                            </tr>
                                            <tr className="safeflyfirstrow">
                                                <td><div className="divtablechangeRequests"><div className="safeflylegendtdweight">Abandoned</div><div className="legendtdalign">Abandoned</div></div></td>
                                            </tr>
                                            <tr className="safeflysecondrow">
                                                <td><div className="divtablechangeRequests"><div className="safeflylegendtdweight">SMApproved</div><div className="legendtdalign">BuddyApproved/BuddyApprovedWithSuggestion /ServiceManagerApproved/ServiceManagerApprovedWithSuggestion</div></div></td>
                                            </tr>
                                            <tr className="safeflyfirstrow">
                                                <td><div className="divtablechangeRequests"><div className="safeflylegendtdweight">SMRejected</div><div className="legendtdalign">BuddyRejected/ServiceManagerRejected
                                                </div></div></td>
                                            </tr>
                                            <tr className="safeflysecondrow">
                                                <td><div className="divtablechangeRequests"><div className="safeflylegendtdweight">EDApproved</div><div className="legendtdalign">EdApproved/EdApprovedWithSuggestion</div></div></td>
                                            </tr>
                                            <tr className="safeflyfirstrow">
                                                <td><div className="divtablechangeRequests"><div className="safeflylegendtdweight">Rejected</div><div className="legendtdalign">EDRejected/Rejected</div></div></td>
                                            </tr>
                                            <tr className="safeflysecondrow">
                                                <td><div className="divtablechangeRequests"><div className="safeflylegendtdweight">Deployed</div><div className="legendtdalign">Changes in Deployed</div></div></td>
                                            </tr>
                                            <tr>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                                <Col lg="2">

                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <HighchartsReact highcharts={Highcharts} options={newsLetterWeeklySafeflyDataOptions}
                                        containerProps={{ className: 'chartContaineridsentitynewsletter' }}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col lg="2">

                                </Col>
                                <Col lg="8">
                                    <table className="changerequestsTable">
                                        <thead><tr className="legendtrstyle"><th colSpan={2} className="legendthstyle">Safe Fly Tool (non-SDP)</th></tr></thead>
                                        <tbody>
                                            <tr className="safeflysecondrow">
                                                <td><div className="divtablechangeRequests"><div className="safeflylegendtdweight">Total</div><div className="legendtdalign">{ChangeToolMetricsTotal}</div></div></td>
                                            </tr>
                                            <tr className="safeflyfirstrow">
                                                <td><div className="divtablechangeRequests"><div className="safeflylegendtdweight">New</div><div className="legendtdalign">New/Draft </div></div></td>
                                            </tr>
                                            <tr className="safeflysecondrow">
                                                <td><div className="divtablechangeRequests"><div className="safeflylegendtdweight">Completed</div><div className="legendtdalign">Approved/ApprovedWithSuggestion /ApprovedWithLease/Completed /CompletedWithSuggestion/CompletedWithLease
                                                </div></div></td>
                                            </tr>
                                            <tr className="safeflyfirstrow">
                                                <td><div className="divtablechangeRequests"><div className="safeflylegendtdweight">Abandoned</div><div className="legendtdalign">Abandoned</div></div></td>
                                            </tr>
                                            <tr className="safeflysecondrow">
                                                <td><div className="divtablechangeRequests"><div className="safeflylegendtdweight">SMApproved</div><div className="legendtdalign">BuddyApproved/BuddyApprovedWithSuggestion /ServiceManagerApproved/ServiceManagerApprovedWithSuggestion</div></div></td>
                                            </tr>
                                            <tr className="safeflyfirstrow">
                                                <td><div className="divtablechangeRequests"><div className="safeflylegendtdweight">SMRejected</div><div className="legendtdalign">BuddyRejected/ServiceManagerRejected
                                                </div></div></td>
                                            </tr>
                                            <tr className="safeflysecondrow">
                                                <td><div className="divtablechangeRequests"><div className="safeflylegendtdweight">EDApproved</div><div className="legendtdalign">EdApproved/EdApprovedWithSuggestion</div></div></td>
                                            </tr>
                                            <tr className="safeflyfirstrow">
                                                <td><div className="divtablechangeRequests"><div className="safeflylegendtdweight">Rejected</div><div className="legendtdalign">EDRejected/Rejected</div></div></td>
                                            </tr>
                                            <tr className="safeflysecondrow">
                                                <td><div className="divtablechangeRequests"><div className="safeflylegendtdweight">Deployed</div><div className="legendtdalign">Changes in Deployed</div></div></td>
                                            </tr>
                                            <tr>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                                <Col lg="2">

                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <HighchartsReact highcharts={Highcharts} options={newsLetterWeeklyChangeToolDataOptions}
                                        containerProps={{ className: 'chartContaineridsentitynewsletter' }}
                                    />

                                </Col>

                            </Row>
                            <br />

                        </div>
                        <br />
                        <div>
                            <Row className="PowerBiReport-Outage">
                                <Col lg="12">
                                    <h4 style={{ textAlign: 'center' }}>90 Days Outage Count</h4>
                                </Col>
                            </Row>
                        </div>
                        <div ref={ref90Outage}>
                            <Row>
                                <Col lg="12">
                                    <HighchartsReact highcharts={Highcharts} options={newsLetterWeeklyOutagesDataOptions}
                                        containerProps={{ className: 'chartContaineridsentitynewsletter' }}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col lg="12">
                                    <div>
                                        {
                                            (!isNullOrUndefined(newsLetterWeeklyOutagesData)) ?
                                                <React.Fragment>
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                {
                                                                    outagesWeeksHeaderList.map((index) => (
                                                                        <React.Fragment key={index}>
                                                                            <th>{index}</th>
                                                                        </React.Fragment>
                                                                    ))}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                outagesWeeklySortedData.map((row: any, index) => (
                                                                    <React.Fragment key={index}>
                                                                        <tr className="topOutageDataRowsTopTable">
                                                                            <td style={{ border: '1px solid black' }}>{row.serviceName}</td>
                                                                            <td style={{ border: '1px solid black' }}>{(Object.values(row).map(x => x)[1] == 0) ? '' : Object.values(row).map(x => x)[1]}</td>
                                                                            <td style={{ border: '1px solid black' }}>{(Object.values(row).map(x => x)[2] == 0) ? '' : Object.values(row).map(x => x)[2]}</td>
                                                                            <td style={{ border: '1px solid black' }}>{(Object.values(row).map(x => x)[3] == 0) ? '' : Object.values(row).map(x => x)[3]}</td>
                                                                            <td style={{ border: '1px solid black' }}>{(Object.values(row).map(x => x)[4] == 0) ? '' : Object.values(row).map(x => x)[4]}</td>
                                                                            <td style={{ border: '1px solid black' }}>{(Object.values(row).map(x => x)[5] == 0) ? '' : Object.values(row).map(x => x)[5]}</td>
                                                                            <td style={{ border: '1px solid black' }}>{(Object.values(row).map(x => x)[6] == 0) ? '' : Object.values(row).map(x => x)[6]}</td>
                                                                            <td style={{ border: '1px solid black' }}>{(Object.values(row).map(x => x)[7] == 0) ? '' : Object.values(row).map(x => x)[7]}</td>
                                                                            <td style={{ border: '1px solid black' }}>{(Object.values(row).map(x => x)[8] == 0) ? '' : Object.values(row).map(x => x)[8]}</td>
                                                                            <td style={{ border: '1px solid black' }}>{(Object.values(row).map(x => x)[9] == 0) ? '' : Object.values(row).map(x => x)[9]}</td>
                                                                            <td style={{ border: '1px solid black' }}>{(Object.values(row).map(x => x)[10] == 0) ? '' : Object.values(row).map(x => x)[10]}</td>
                                                                            <td style={{ border: '1px solid black' }}>{(Object.values(row).map(x => x)[11] == 0) ? '' : Object.values(row).map(x => x)[11]}</td>
                                                                            <td style={{ border: '1px solid black' }}>{(Object.values(row).map(x => x)[12] == 0) ? '' : Object.values(row).map(x => x)[12]}</td>
                                                                            <td style={{ border: '1px solid black' }}>{row.Total}</td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                </React.Fragment>
                                                : <React.Fragment>No Data Found !!</React.Fragment>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <br />
                        <div>
                            <Row className="PowerBiReport-Comms">
                                <Col lg="12">
                                    <h4 style={{ textAlign: 'center' }}>90 Days Comms Count</h4>
                                </Col>
                            </Row>
                        </div>
                        <div ref={ref90Comms}>
                            <Row>
                                <Col lg="12">
                                    <HighchartsReact highcharts={Highcharts} options={newsLetterWeeklyCommsDataOptions}
                                        containerProps={{ className: 'chartContaineridsentitynewsletter' }}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col lg="12">
                                    <div>
                                        {
                                            (!isNullOrUndefined(newsLetterWeeklyCommsData)) ?
                                                <React.Fragment>
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                {
                                                                    commsWeeksHeaderList.map((index) => (
                                                                        <React.Fragment key={index}>
                                                                            <th>{index}</th>
                                                                        </React.Fragment>
                                                                    ))}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                commsWeeklySortedData.map((row: any, index) => (
                                                                    <React.Fragment key={index}>
                                                                        <tr className="topOutageDataRowsTopTable">
                                                                            <td style={{ border: '1px solid black' }}>{row.serviceName}</td>
                                                                            <td style={{ border: '1px solid black' }}>{(Object.values(row).map(x => x)[1] == 0) ? '' : Object.values(row).map(x => x)[1]}</td>
                                                                            <td style={{ border: '1px solid black' }}>{(Object.values(row).map(x => x)[2] == 0) ? '' : Object.values(row).map(x => x)[2]}</td>
                                                                            <td style={{ border: '1px solid black' }}>{(Object.values(row).map(x => x)[3] == 0) ? '' : Object.values(row).map(x => x)[3]}</td>
                                                                            <td style={{ border: '1px solid black' }}>{(Object.values(row).map(x => x)[4] == 0) ? '' : Object.values(row).map(x => x)[4]}</td>
                                                                            <td style={{ border: '1px solid black' }}>{(Object.values(row).map(x => x)[5] == 0) ? '' : Object.values(row).map(x => x)[5]}</td>
                                                                            <td style={{ border: '1px solid black' }}>{(Object.values(row).map(x => x)[6] == 0) ? '' : Object.values(row).map(x => x)[6]}</td>
                                                                            <td style={{ border: '1px solid black' }}>{(Object.values(row).map(x => x)[7] == 0) ? '' : Object.values(row).map(x => x)[7]}</td>
                                                                            <td style={{ border: '1px solid black' }}>{(Object.values(row).map(x => x)[8] == 0) ? '' : Object.values(row).map(x => x)[8]}</td>
                                                                            <td style={{ border: '1px solid black' }}>{(Object.values(row).map(x => x)[9] == 0) ? '' : Object.values(row).map(x => x)[9]}</td>
                                                                            <td style={{ border: '1px solid black' }}>{(Object.values(row).map(x => x)[10] == 0) ? '' : Object.values(row).map(x => x)[10]}</td>
                                                                            <td style={{ border: '1px solid black' }}>{(Object.values(row).map(x => x)[11] == 0) ? '' : Object.values(row).map(x => x)[11]}</td>
                                                                            <td style={{ border: '1px solid black' }}>{(Object.values(row).map(x => x)[12] == 0) ? '' : Object.values(row).map(x => x)[12]}</td>
                                                                            <td style={{ border: '1px solid black' }}>{row.Total}</td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                </React.Fragment>
                                                : <React.Fragment>No Data Found !!</React.Fragment>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                   
                    < br />
                </Row>
            </div>
            < br />
            <Dialog visible={newsletterdeleteTopOutageDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteTopOutageDialogFooter} onHide={hideDeleteTopOutageDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', color: 'red' }} />
                    <span>Are you sure you want to Refresh the Top Outage Data?</span>
                </div>
            </Dialog>
        </React.Fragment>
    )


});

IdentityWeeklyNewsLetterTable.displayName = 'IdentityWeeklyNewsLetterTable';
export default IdentityWeeklyNewsLetterTable;