export type IGemServiceMappingData = {
    gem: string,
    serviceName: string,
    teamGroupName: string,
    areaPath: string,
    gemEmail: string
};

export type ICreateAdoHierarchyState = {
    readonly isLoading: boolean;
    gemServiceMappingData: IGemServiceMappingData[];
    nodeData: INodeType[];
    checkedServices: [];
    description: any;
    selectedServices: [];
    chapterID: string; 
    epicTitle: string; 
    epicID: string;
    iterationPath: string,
    previousTitle: string,
    id: string,
    errorMsg: string
};

export type INodeType = {
    value: string,
    label: string,
    checked: boolean,
    children?: [{
        value: string,
        label: string,
        checked: boolean
    }]
}

const _namespace = 'CreateAdoHierarchy';

export interface IActionType {
    readonly RESET_STATE: string;
    readonly ADDED_VISITED_USER: string;
    readonly GET_GEMSERVICE_SUCCESS: string;
    readonly GET_GEMSERVICE_FAILURE: string;
    readonly SET_CHECKED_VALUES: string;
    readonly SET_DESCRIPTION_VALUES: string;
    readonly SET_CHAPTERID_VALUES: string;
    readonly SET_EPICTITLE_VALUES: string;
    readonly SET_EPICID_VALUES: string;
    readonly SET_ITERATIONPATH_VALUES: string;
    readonly SET_NODEDATA_VALUES: string;
    readonly CREATE_ADOTREE: string;
    readonly CREATE_ADOTREE_FAILED: string;
}

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}createadohierarchyreset`,
    GET_GEMSERVICE_SUCCESS: `${_namespace}getgemservicesuccess`,
    GET_GEMSERVICE_FAILURE: `${_namespace}getgemservicefailure`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    SET_CHECKED_VALUES: `${_namespace}/setcheckedvalues`,
    SET_DESCRIPTION_VALUES: `${_namespace}/setdescriptionvalues`,
    SET_CHAPTERID_VALUES: `${_namespace}/setchapteridvalues`,
    SET_EPICTITLE_VALUES: `${_namespace}/setepictitlevalues`,   
    SET_EPICID_VALUES: `${_namespace}/setepicidvalues`,
    SET_ITERATIONPATH_VALUES: `${_namespace}/setiterationpathvalues`, 
    SET_NODEDATA_VALUES: `${_namespace}/setnodedatavalues`, 
    CREATE_ADOTREE: `${_namespace}/setcreateadotree`, 
    CREATE_ADOTREE_FAILED: `${_namespace}/setcreateadotreefailed`, 
});
