import { BaseService } from './base.service';

class CommonService extends BaseService {
    private static _commonService: CommonService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): CommonService {
        return (this._commonService || (this._commonService = new this('Common')));
    }

    public async AddUserVisitInfo(vid:any, pageName:string, serviceId:number): Promise<string> {
        var json = {
            vid: vid,
            pageName: pageName,
            servID: serviceId
        };
        const { data } = await this.$http.post<string>(`AddUserVisitInfo`, json);
        return data;
    }

    public async AddFeedbackData(json: any): Promise<string> {
        const { data } = await this.$http.post<string>(`AddFeedbackData`, json);
        return data;
    }

    public async GetCustomerFeedbackComponentData(): Promise<any[]> {
        const { data } = await this.$http.get<any[]>("GetCustomerFeedbackComponentData");
        return data;
    }

    public async GetCustomerFeedbackDifficultylevelData(): Promise<any[]> {
        const { data } = await this.$http.get<any[]>("GetCustomerFeedbackDifficultylevelData");
        return data;
    }

    public async GetCustomerFeedbackExperienceData(): Promise<any[]> {
        const { data } = await this.$http.get<any[]>("GetCustomerFeedbackExperienceData");
        return data;
    }

    public async GetCustomerSurveyData(): Promise<any> {
        const { data } = await this.$http.get<any>("GetCustomerSurveyData");
        return data;
    }

    public async AddMenuStatisticsData(menuCountArray): Promise<number> {
        const { data } = await this.$http.post<number>(`AddMenuStatisticsData`, menuCountArray);
        return data;
    }

    public async GetCSSByComponent(pageName: string, componentName: string): Promise<any[]> {
        const { data } = await this.$http.get<any[]>(`GetCSSByComponent?pageName=${pageName}&componentName=${componentName}`);
        return data;
    }
}

export const CommonAPI = CommonService.Instance;