import React, { useState, Fragment, useRef } from 'react';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import { IPropPlatformPMTools } from '../../../store/PlatformTools';
import { ReduxAction, IAppThunkAction } from '../../../store';
import { IMonthlyReport } from '../../../store/service-health';
import { FontAwesomeIconMemo } from '../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import {
    Card, Row, Col, Container, CardBody
} from 'reactstrap';
import DatePicker, { getDay } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';
type MonthlyAvailbilityQOSTableProps = {
    readonly monthlyStartDate: Date;
    readonly onmonthlyStartDateChange: (monthlyStartDate: Date) => ReduxAction;   
    IsMonthlyDataLoaded: boolean,
    ClearMonthlyDate: any,
    uniquev: any,
    uniquec: any,
    liKPIValueKeys: any,
    monthlyQOSData:any
};
const MonthlyAvailbilityQOSTable = React.memo<MonthlyAvailbilityQOSTableProps>(({
    monthlyStartDate,
    onmonthlyStartDateChange,  
    IsMonthlyDataLoaded,
    ClearMonthlyDate,
    uniquev,
    uniquec,
    liKPIValueKeys,
    monthlyQOSData
   
}) => {
    // To Compare the weekly data with target values
    const AddColorForFactAvailabilityBudget=(data, key, target)=> {
        var weekColor = { Color: "" };
        data[key] = (parseFloat(data[key]) < 1 && parseFloat(data[key]) > 0) ? (data[key] * 100).toFixed(4) + "%" : data[key];
        var reg = /[0-9][0-9]\.[0-9][%]/;
        var availabilityTarget = parseFloat(target);
        if (availabilityTarget != null) {
            OperatorOverloadAvailabilityBudget(data, key, availabilityTarget, weekColor, "<=");
        } else if (/[0-9]+/.test(data.targetName)) {
            OperatorOverloadAvailabilityBudget(data, key, target, weekColor, ">=");
        }
        return weekColor.Color;
    }

    // To Format the weekly data
    const OperatorOverloadAvailabilityBudget=(data, key, target, weekColor, operator)=> {
        data[key] = String(data[key]).replace(/[(\)]/gi, "");
        weekColor.Color = "white";
    }
    // To Compare the weekly data with target values
    const capitalizeFirstLetter = (string) => {
        return (string === undefined)?'':string.charAt(0).toUpperCase() + string.slice(1);
    }
    return (
        <div>
            <Card className="cardstyle">
                <CardBody className="cardbodyhederstyle">
                    <Row>
                        <Col lg="12">
                            <h3 className="headertext"> Monthly Availability Qos By Heatmap</h3>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <div className="aligntable">
                <table style={{ width: '100%' }}>
                    <tr>
                        <td style={{ width: '80%' }}></td>
                        <td style={{ width: '15%' }}>
                            <div id="DateSelectorDiv" className="btn btn-primary btn-sm filter-button monthlytd">
                                <table>
                                    <tr>
                                        <td style={{ width: '15%' }}></td>
                                        <td style={{ width: '15%' }}>
                                            <div>
                                                <label style={{ fontSize: '20px' }} htmlFor='fromdate'> <FontAwesomeIconMemo icon={'calendar'} className='icon-format' /></label>&nbsp;
                                        </div>
                                        </td>
                                        <td style={{ width: '40%' }}>
                                            <DatePicker className='InputStyle'
                                                id={'fromdate'}
                                                selected={monthlyStartDate}
                                                onChange={onmonthlyStartDateChange}
                                                maxDate={new Date()}
                                                minDate={new Date(2018, 11, 1)}
                                                dateFormat="MM/yyyy"
                                                showMonthYearPicker
                                            //customInput={<button className="datePickerInput"> {moment(monthlyStartDate).format("MM/YYYY")} <FontAwesomeIconMemo icon={'table'} className="datePickerCalendarIcon" /></button>}
                                            />  &nbsp;&nbsp;
                                        </td>
                                        <td style={{ width: '15%' }}> <div title='Clear' onClick={ClearMonthlyDate} style={{ paddingTop: '5px', cursor: 'pointer' }}>CLEAR</div></td>
                                        <td style={{ width: '15%' }}></td>
                                    </tr>
                                </table>
                            </div>
                        </td>
                        <td style={{ width: '5%' }}></td>
                    </tr>
                </table>
            </div>
            <br/>
            <div id='table_wrapper aligntable'>
                <div>

                </div>
                {
                    (isArrayWithLength(monthlyQOSData))
                        ?
                        <React.Fragment>
                            <table id='tdqosdata' className='table tableGrid stickyQOSTable monthlydatatable qostable'>
                                <thead className='thead-inverse'>
                                    <tr>
                                        <th colSpan={1} className="borderleftbottom"></th>
                                    {isArrayWithLength(uniquec) && uniquec.map((dh, key) => (
                                        <th colSpan={3} className="borderleftbottom tdtextaligncenter"  > { capitalizeFirstLetter(dh)}</th>
                                    ))} 
                                    </tr>
                                     
                                </thead>
                                <thead className='thead-inverse'>
                                    <tr>
                                        <th className='stickyQOSth tdtextaligncenter'><div style={{ display: 'block', width: '260px' }}>Name</div></th>
                                        {isArrayWithLength(uniquec) && uniquec.map((dk, key) => (
                                            <React.Fragment>
                                                {isArrayWithLength(uniquev) && uniquev.map((dh, key) => (
                                                    <th className="tdtextaligncenter" style={{ width: '145px' }}> {capitalizeFirstLetter(dh)}</th>
                                                ))} 
                                            </React.Fragment>

                                           
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>                                    
                                    {isArrayWithLength(monthlyQOSData) && monthlyQOSData.map((dhs: IMonthlyReport, key) => (
                                        <React.Fragment key={key}>
                                            <tr>
                                                <td className='ml01 stickyQOStd borderbottom' style={{ borderLeft: '0px' }} >{dhs.viewName}</td>
                                                {isArrayWithLength(liKPIValueKeys) && liKPIValueKeys.map((dk, kpiArrkey) => (
                                                    <React.Fragment key={kpiArrkey}>
                                                        {
                                                            (kpiArrkey > 0)
                                                                ?
                                                                (dk.indexOf('Volume') == -1)
                                                                ?
                                                                <td className={AddColorForFactAvailabilityBudget(dhs.liKPIValues, dk, dhs.targetName)} style={{ textAlign: 'center', border: '1px solid rgba(18, 73, 109, 0.4)' }} key={kpiArrkey}>
                                                                    {dhs.liKPIValues[dk]}
                                                                </td>
                                                                    : <td className={AddColorForFactAvailabilityBudget(dhs.liKPIValues, dk, dhs.targetName)} style={{ textAlign: 'right', border: '1px solid rgba(18, 73, 109, 0.4)' }} key={kpiArrkey}>
                                                                        {new Intl.NumberFormat().format(Number(dhs.liKPIValues[dk]))}
                                                                    </td>
                                                                : <React.Fragment></React.Fragment>
                                                        }
                                                    </React.Fragment>
                                                ))}
                                            </tr>
                                        </React.Fragment>
                                    ))}                                   
                                </tbody>                                
                            </table>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div style={{ textAlign: 'center', verticalAlign: 'middle !important', fontWeight: 'bold', color: 'red', fontSize: '20px' }}>No Data Available</div>
                        </React.Fragment>
                }
            </div>           

        </div>
    )
});

MonthlyAvailbilityQOSTable.displayName = 'MonthlyAvailbilityQOSTable';
export default MonthlyAvailbilityQOSTable;