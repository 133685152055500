import React, { useState, Fragment } from 'react';
import { IAvailabilitySummary } from '../../../store/availability';
import {  Link } from 'react-router-dom';
import { isArrayWithLength, isNullOrUndefined } from '../../../utils';
import classnames from 'classnames';
import moment from 'moment';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Row,
    Col, Card, CardBody
} from 'reactstrap';
import { Tabs, Tab } from 'react-bootstrap'
import { IAllRepairItemsData, IPropTicketDetails } from '../../../store/actionItems/types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import drilldown from "highcharts/modules/drilldown.js";
import applySortOn from "../../../utils/customSortMethod"
drilldown(Highcharts);
type ActionItemsProps = {
    allRepairItemsData: any,
    allRepairItemsDataGraphoptions: any,
    allRepairItemsGraphDataAggregated: any,
    RepairItemGraphServicesoptions: any,
    allRepairItemsGraphData: any,
    checkAllService: boolean,
    changeallservices: any,
    allRepairItemsDatatableheader: any,
    checkservicesforeachrow: any,
    actionItemsData: any,
    Isdataloaded: boolean
};

const ActionItemsTable = React.memo<ActionItemsProps>(({
    allRepairItemsData,
    allRepairItemsDataGraphoptions,
    allRepairItemsGraphDataAggregated,
    RepairItemGraphServicesoptions,
    allRepairItemsGraphData,
    checkAllService,
    changeallservices,
    allRepairItemsDatatableheader,
    checkservicesforeachrow,
    actionItemsData,
    Isdataloaded
}) => { 
    // Sort Logic Start

    //Removing Total Volume and Daily Agg. from the sorting
    var tempactionItemsData = [...actionItemsData];
    const { result: dailyResult, requestSort: dailyRequestSort, sortConfig: dailySortConfig } = applySortOn(tempactionItemsData);
    actionItemsData = [...dailyResult];

    //This is for styles to indicate order
    const getClassNamesForDaily = (name) => {
        if (!dailySortConfig) {
            return;
        }
        return dailySortConfig.key === name ? dailySortConfig.direction : undefined;
    };
    return (
        <div>
            <Card className="cardstyle">
                <CardBody className="cardbodyhederstyle">
                    <Row>
                       
                        <Col lg="12">
                            <h3 className="headertext"> Action Item Status</h3>
                        </Col>                        
                    </Row>
                </CardBody>
            </Card>
            <br />
            <Tabs defaultActiveKey="overallsummary" id="uncontrolled-tab-example">
                <Tab eventKey="overallsummary" title="OVERALL SUMMARY">
                    <br/>
                    <Row>
                        <Col lg="4">
                            {
                                (isArrayWithLength(allRepairItemsData))
                                    ?
                                    <table className="tblActionItems">
                                        <thead>
                                            <tr>
                                                <th className="tdwidth"><input type="checkbox" onChange={e => checkservicesforeachrow(null, e, 0, true)} checked={checkAllService} />&nbsp;All</th>
                                                <th className="colstyle">Assigned To</th>
                                                <th className="colstyle">Total Open <br /><span className="smallfontheader"> {allRepairItemsDatatableheader.weekDisplayName}</span></th>
                                                <th className="colstyle">Closed <br /><span className="smallfontheader"> {allRepairItemsDatatableheader.weekDisplayName}</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isArrayWithLength(allRepairItemsData) && allRepairItemsData.map((row: IAllRepairItemsData, index) => (
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <td className="tdwidth tdtextaligncenter"><input type="checkbox" onChange={e => checkservicesforeachrow(row, e, index,false)} checked={row.checked} /></td>
                                                        <td className="colstyle">{row.serviceName}</td>
                                                        <td className="colstyle">{row.totalopen}</td>
                                                        <td className="colstyle">{row.closed}</td>

                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                        </tbody>
                                    </table>
                                    : <React.Fragment></React.Fragment>
                            }
                        </Col>
                        <React.Fragment>
                            {
                                (isArrayWithLength(allRepairItemsGraphDataAggregated))
                                    ?
                                    <React.Fragment>
                                        <Col lg="4">
                                            <HighchartsReact highcharts={Highcharts}
                                                options={allRepairItemsDataGraphoptions}
                                                containerProps={{ className: 'chartContainer' }}
                                            />
                                        </Col>
                                        <Col lg="4">
                                            <HighchartsReact highcharts={Highcharts} options={RepairItemGraphServicesoptions}
                                                containerProps={{ className: 'chartContainer' }}
                                            />
                                        </Col>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <Col lg="8"> <div className="errormessagegraph"><br />
                                            {(Isdataloaded) ? <React.Fragment> No data available for graphs!</React.Fragment>:<React.Fragment></React.Fragment>} 
                                                </div></Col>
                                    </React.Fragment>
                            }
                            </React.Fragment>                       
                    </Row>
                </Tab>
                <Tab eventKey="actionitemdata" title="ACTION ITEM DATA">
                    <div>
                        <br/>
                        <Row>
                            <Col lg="12">
                                {
                                    (isArrayWithLength(actionItemsData))
                                        ?
                                        <table className="tblActionItems">
                                            <thead>
                                                <tr className="tblItemstatus">
                                                    <th className={'colstyledetails ' + getClassNamesForDaily('tfsId')}  onClick={() => dailyRequestSort('tfsId')}>ID</th>
                                                    <th className="colstyledetails830">Ticket Title</th>
                                                    <th className={'colstyledetails ' + getClassNamesForDaily('ticketStatus')} onClick={() => dailyRequestSort('ticketStatus')}>Ticket Status</th>
                                                    <th className={'colstyledetails ' + getClassNamesForDaily('slaStatus')} onClick={() => dailyRequestSort('slaStatus')}>SLA Status</th>
                                                    <th className="colstyledetails" onClick={() => dailyRequestSort('sortcreatedDate')}>Created Date</th>
                                                    <th className="colstyledetails" onClick={() => dailyRequestSort('sortdueDate')}>Due Date</th>
                                                    <th className={'colstyledetails ' + getClassNamesForDaily('serviceName')} onClick={() => dailyRequestSort('serviceName')}>Assigned To</th>                                       
                                                </tr>
                                            </thead>
                                            <tbody className="tbodyheight">
                                                {isArrayWithLength(actionItemsData) && actionItemsData.map((row: IPropTicketDetails, index) => (
                                                    <React.Fragment key={index}>
                                                        <tr className="tblItemstatus">
                                                            <td className="colstyledetails tdtextaligncenter"><a href={'https://identitydivision.visualstudio.com/Engineering/_workitems/edit/' + row.tfsId} target="_blank">{ row.tfsId }</a></td>
                                                            <td className="colstyledetails830">{row.ticketTitle}</td>
                                                            <td className="colstyledetails tdtextaligncenter">{row.ticketStatus}</td>
                                                            <td className="colstyledetails tdtextaligncenter">{row.slaStatus}</td>
                                                            <td className="colstyledetails tdtextaligncenter">{(row.createdDate === null) ? '' : moment(row.createdDate).format('Do MMM YYYY')}</td>
                                                            <td className="colstyledetails tdtextaligncenter">{(row.dueDate === null) ? '' : moment(row.dueDate).format('Do MMM YYYY')}</td>
                                                            <td className="colstyledetails">{row.serviceName}</td>                                                           
                                                        </tr>
                                                    </React.Fragment>
                                                ))}
                                            </tbody>
                                        </table>
                                        : <React.Fragment><div style={{ color: 'Red', fontWeight: 'bold', textAlign: 'center' }}><br/>No Data Availble for Action Items</div></React.Fragment>                                }

                            </Col>                           
                        </Row>
                    </div>
                </Tab>
            </Tabs>            
        </div>
    )
});

ActionItemsTable.displayName = 'ActionItemsTable';
export default ActionItemsTable;