import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IMonthlyAvailbilityQOSState } from './types';

const initialState = Object.freeze<IMonthlyAvailbilityQOSState>({
    isLoading: false,
    monthlyStartDate: new Date(),
    monthlyQOSData: [],  
    IsMonthlyDataLoaded: false,
    uniquev: [],
    uniquec: [],
    liKPIValueKeys:[]
 
});

export const reducer = (
    state: IMonthlyAvailbilityQOSState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {
        case ActionType.MONTHLY_START_DATE_CHANGE:
            return {
                ...state,
                monthlyStartDate: action.monthlyStartDate
            };    
        case ActionType.MONTHLYDATA_QOS_FETCH:
            return {
                ...state,
                monthlyData: [],
                isLoading: true,
                IsMonthlyDataLoaded: false,
                uniquev: [],
                uniquec: [],
                liKPIValueKeys: [],
                monthlyQOSData:[]
            };
        case ActionType.MONTHLYDATA_QOS_RECEIVE:
            return {
                ...state,
                monthlyQOSData: action.monthlyData,
                uniquev: action.uniquev,
                uniquec: action.uniquec,
                IsMonthlyDataLoaded: true,
                liKPIValueKeys: action.liKPIValueKeys,
                isLoading: false
            };
        case ActionType.RESET_STATE:
            return initialState;

        default:
            return state;
    }
};
