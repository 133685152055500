import React, { useState, useMemo, Fragment, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { IDataCenter } from '../../../store/DCMapping';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faEraser } from '@fortawesome/free-solid-svg-icons';
import "../../../assets/style/scss/components/DCMapping.scss";
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { FontAwesomeIconMemo, Checkbox } from '../../../components';

type DCMappingInsertUpdateTableProps = {
    currentRow: IDataCenter,
    updateRecord: any,
    cancel: any,
    insertBool: any
};
const DCMappingInsertUpdateTable = React.memo<DCMappingInsertUpdateTableProps>(({
    currentRow,
    updateRecord,
    cancel,
    insertBool
}) => {
    const [dataCenterName, setdataCenterName] = useState(currentRow.dataCenterName);
    const [azureRegionName, setazureRegionName] = useState(currentRow.azureRegionName);
    const [country, setcountry] = useState(currentRow.country);
    const [geographyId, setgeographyId] = useState(currentRow.geographyId);
    const [location, setlocation] = useState(currentRow.location);
    const [referenceInfo, setreferenceInfo] = useState(currentRow.referenceInfo);
    const [regionCode, setregionCode] = useState(currentRow.regionCode);
    const [regionID, setregionID] = useState(currentRow.regionID);
    const [regionLifeCycle, setregionLifeCycle] = useState(currentRow.regionLifeCycle);
    const [regionOid, setregionOid] = useState(currentRow.regionOid);
    const [regionType, setregionType] = useState(currentRow.regionType);

    const re = /^[0-9\b]+$/;
    const setdataCenterNameValue = (e) => {
        setdataCenterName(e.target.value)
    }
    const setazureRegionNameValue = (e) => {
        setazureRegionName(e.target.value)
    }
    const setcountryValue = (e) => {
        setcountry(e.target.value)
    }
    const setgeographyIdValue = (e) => {
        if (e.target.value === '' || re.test(e.target.value)) {
            setgeographyId(e.target.value)
        }
    }

    const setlocationValue = (e) => {
        setlocation(e.target.value)
    }
    const setreferenceInfoValue = (e) => {
        setreferenceInfo(e.target.value)
    }
    const setregionCodeValue = (e) => {
        setregionCode(e.target.value)
    }
    const setregionIDValue = (e) => {
        if (e.target.value === '' || re.test(e.target.value)) {
            setregionID(e.target.value)
        }
    }
    const setregionLifeCycleValue = (e) => {
        setregionLifeCycle(e.target.value)
    }
    const setregionOidValue = (e) => {
        setregionOid(e.target.value)
    }
    const setregionTypeValue = (e) => {
        setregionType(e.target.value)
    }
    const updateRow = () => {
        let row: IDataCenter = {
            azureRegionName: azureRegionName,
            country: country,
            dataCenterName: dataCenterName,
            geographyId: geographyId,
            id: currentRow.id,
            location: location,
            referenceInfo: referenceInfo,
            regionCode: regionCode,
            regionID: regionID,
            regionLifeCycle: regionLifeCycle,
            regionOid: regionOid,
            regionType: regionType,
            updatedby: ''
        }
        console.log(row)
        updateRecord(row, insertBool);
    }
    return (
        <div>

            <Card className="cardstyle">
                <CardBody className="cardbodyhederstyle">

                    <Row>
                        <Col lg="2"></Col>
                        <Col lg="8" style={{ textAlign: 'center' }}>
                            <div>
                                <h3 className="headertext"> Data Centers to Azure Region mapping </h3>
                            </div>
                        </Col>
                        <Col lg="2" style={{ textAlign: 'right' }}>
                            <Button className="DCbuttons btn"
                                style={{ marginLeft: '20%' }} disabled={dataCenterName == '' || azureRegionName == ''} onClick={(e) => updateRow()} ><FontAwesomeIcon icon={faSave} />&nbsp;Save</Button>&nbsp;&nbsp;
                            <Button className="DCbuttons btn" onClick={cancel}>Cancel</Button>
                        </Col>

                    </Row>
                </CardBody>
            </Card>

            <br />
            <table className="DCboxShadow">
                <tbody>
                    <tr>
                        <td style={{ width: "50%", textAlign: 'center', verticalAlign: 'middle' }}>
                            <table>
                                <tbody>
                                    <tr style={{ fontSize: '18px' }}>
                                        <td>
                                            Data Center Name&nbsp;<span style={{ color: "red" }}>*</span>&ensp;&ensp;
                                        </td>
                                        <td>
                                            <InputText style={{ width: '340px', verticalAlign: 'top', marginBottom: '20px' }} type="search" value={dataCenterName} onChange={(e) => setdataCenterNameValue(e)} placeholder="Enter Data Center Name" />
                                        </td>
                                    </tr>
                                    <tr style={{ fontSize: '18px' }}>
                                        <td>
                                            Reference Info&ensp;&ensp;
                                        </td>
                                        <td>
                                            <InputText style={{ width: '340px', verticalAlign: 'top', marginBottom: '20px' }} type="search" value={referenceInfo} onChange={(e) => setreferenceInfoValue(e)} placeholder="Enter Reference Info" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br></br>
                        </td>
                        <td style={{ width: "50%", textAlign: 'center', borderLeft: '1px solid lightblue', verticalAlign: 'middle', fontSize: '18px' }}>
                            <table>
                                <tbody>
                                    <tr style={{ fontSize: '18px' }}>
                                        <td>
                                            Azure Region Name&nbsp;<span style={{ color: "red" }}>*</span>&ensp;&ensp;
                                        </td>
                                        <td>
                                            <InputText style={{ width: '340px', verticalAlign: 'top', marginBottom: '20px' }} type="search" value={azureRegionName} onChange={(e) => setazureRegionNameValue(e)} placeholder="Enter Azure Region Name" />
                                        </td>
                                    </tr>
                                    <tr style={{ fontSize: '18px' }}>
                                        <td>
                                            Location&ensp;&ensp;
                                        </td>
                                        <td>
                                            <InputText style={{ width: '340px', verticalAlign: 'top', marginBottom: '20px' }} type="search" value={location} onChange={(e) => setlocationValue(e)} placeholder="Enter Location" />
                                        </td>
                                    </tr>
                                    <tr style={{ fontSize: '18px' }}>
                                        <td>
                                            Region ID&ensp;&ensp;
                                        </td>
                                        <td>
                                            <InputText style={{ width: '340px', verticalAlign: 'top', marginBottom: '20px' }} type="search" value={regionID} onChange={(e) => setregionIDValue(e)} placeholder="Enter Region ID" />
                                        </td>
                                    </tr>
                                    <tr style={{ fontSize: '18px' }}>
                                        <td>
                                            Region Oid&ensp;&ensp;
                                        </td>
                                        <td>
                                            <InputText style={{ width: '340px', verticalAlign: 'top', marginBottom: '20px' }} type="search" value={regionOid} onChange={(e) => setregionOidValue(e)} placeholder="Enter Region Oid" />
                                        </td>
                                    </tr>
                                    <tr style={{ fontSize: '18px' }}>
                                        <td>
                                            Region Code&ensp;&ensp;
                                        </td>
                                        <td>
                                            <InputText style={{ width: '340px', verticalAlign: 'top', marginBottom: '20px' }} type="search" value={regionCode} onChange={(e) => setregionCodeValue(e)} placeholder="Enter Region Code" />
                                        </td>
                                    </tr>
                                    <tr style={{ fontSize: '18px' }}>
                                        <td>
                                            RegionType&ensp;&ensp;
                                        </td>
                                        <td>
                                            <InputText style={{ width: '340px', verticalAlign: 'top', marginBottom: '20px' }} type="search" value={regionType} onChange={(e) => setregionTypeValue(e)} placeholder="Enter RegionType" />
                                        </td>
                                    </tr>
                                    <tr style={{ fontSize: '18px' }}>
                                        <td>
                                            RegionLifecycle&ensp;&ensp;
                                        </td>
                                        <td>
                                            <InputText style={{ width: '340px', verticalAlign: 'top', marginBottom: '20px' }} type="search" value={regionLifeCycle} onChange={(e) => setregionLifeCycleValue(e)} placeholder="Enter RegionLifecycle" />
                                        </td>
                                    </tr>
                                    <tr style={{ fontSize: '18px' }}>
                                        <td>
                                            GeographyId&ensp;&ensp;
                                        </td>
                                        <td>
                                            <InputText style={{ width: '340px', verticalAlign: 'top', marginBottom: '20px' }} type="search" value={geographyId} onChange={(e) => setgeographyIdValue(e)} placeholder="Enter GeographyId" />
                                        </td>
                                    </tr>
                                    <tr style={{ fontSize: '18px' }}>
                                        <td>
                                            Country&ensp;&ensp;
                                        </td>
                                        <td>
                                            <InputText style={{ width: '340px', verticalAlign: 'top', marginBottom: '20px' }} type="search" value={country} onChange={(e) => setcountryValue(e)} placeholder="Enter Country" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
});

DCMappingInsertUpdateTable.displayName = 'DCMappingInsertUpdateTable';
export default DCMappingInsertUpdateTable;