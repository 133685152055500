import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IHeatmapAdminState } from './types';

const initialState = Object.freeze<IHeatmapAdminState>({
    heatmapAdminData: [],
    isLoading: false,
    searchText: "",
    IsLivesitePMOrUser: false,
    newsLetterTeamGroups: []
});

export const reducer = (
    state: IHeatmapAdminState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true

            };
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false

            };
        case ActionType.GET_HEATMAPADMIN_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.GET_HEATMAPADMIN_SUCCESS:  // === for HEATMAP ADMIN Management Data success ===============//
            return {
                ...state,
                heatmapAdminData: action.heatmapAdminData,
                isLoading: false
            };
        case ActionType.GET_HEATMAPADMIN_FAILURE:  // === for HEATMAP ADMIN Management Data failure ===============//
            return {
                ...state,
                heatmapAdminData: [],
                isLoading: false
            };
        case ActionType.GET_IDENTITYNEWSLETTER_TEAMGROUPS_SUCCESS:  // === for HEATMAP ADMIN Management Identity NewsLetter View TeamGroup Data success ===============//
            return {
                ...state,
                newsLetterTeamGroups: action.newsLetterTeamGroups
            };
        case ActionType.GET_IDENTITYNEWSLETTER_TEAMGROUPS_FAILURE:  // === for HEATMAP ADMIN Management Identity NewsLetter View TeamGroup Data failure ===============//
            return {
                ...state,
                newsLetterTeamGroups: [],
                isLoading: false
            };
        case ActionType.CHANGE_SEARCHTEXT_HEATMAP_ADMIN:
            return {
                ...state,
                searchText: action.searchText
            };
        case ActionType.USER_CHECK:
            return {
                ...state,
                IsLivesitePMOrUser: action.IsLivesitePMOrUser
            };
        case ActionType.RESET_STATE:
            return initialState;
        default:
            return state;
    }
};