import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { RouteComponentProps } from 'react-router-dom';
import { actionCreators, reducer, IPropidentityNewsLetterGroupData, IPropIdentityKeyResults, IPropIdentityTopOutages } from '../../store/IdentityWeeklyNewsLetter';
import { IdentityWeeklyNewsLetterTable } from '../IdentityWeeklyNewsLetter/child-components';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap'
import { Row, Col, Card, CardBody } from 'reactstrap';
import { ToastId, toast } from 'react-toastify';
import moment from 'moment';
import { IdentityWeeklyNewsLetterAPI } from '../../api';
import { isNullOrUndefined, isArrayWithLength, renderToastifyMsg } from '../../utils';
import { FontAwesomeIconMemo } from '../../components';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import DatePicker, { getDay } from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DeprecatedFunctionalityBanner } from '../../components';

type IdentityWeeklyNewsLetterProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly username: string }
    & RouteComponentProps<{}>;

const IdentityWeeklyNewsLetter: React.FC<IdentityWeeklyNewsLetterProps> = ({
    newsLetterGroupsData,
    isAuthenticated,
    requestIdentityNewsLetterData,
    isLoading,
    setloadingtrue,
    setloadingfalse,
    username,
    addUserVisit,
    IsLivesitePMOrUser,
    WeekStartDate,
    GetWeekStartDate,
    newsLetterWeeklyAvailabilityData,
    newsLetterWeeklySafeflyData,
    newsLetterWeeklySafeflyDataOptions,
    SafeflyMetricsTotal,
    newsLetterWeeklyChangeToolData,
    newsLetterWeeklyChangeToolDataOptions,
    ChangeToolMetricsTotal,
    newsLetterWeeklyChangeExceptionData,
    newsLetterWeeklyChangeExceptionDataOptions,
    ChangeExceptionMetricsTotal,
    newsLetterObjectivesData,
    newsLetterOKRsData,
    newsLettertopOutagesData,
    newsLettertopOutagesDataTable,
    UpdatetopOutagedata,
    TopOutageDataEditCancel,
    TopOutageDataDelete,
    SendMailIdentityNewsletterDashboard,
    OKRWeekStartDate,
    GetOKRWeekStartDate,
    newsLetterOKRsObjectives,
    selectedObjective,
    handleOKRObjectiveChange,
    handleLoadingProcess,
    ObjectiveStartDate,
    handleObjectiveStartDateChange,
    ObjectiveEndDate,
    handleObjectiveEndDateChange,
    selectedKeyResults,
    newObjective,
    addNewObjective,
    ObjectiveKeyResultValueChange,
    EditObjectiveKeyResultRow,
    EnableAddNewforObjectiveKeyResult,
    cancelEditObjectiveKeyResultRow,
    filterKeyResults,
    getkeyResultsData,
    isAddNewObjective,
    refreshObjectivesDataAfterSave,
    CancelAddNewObjectiveData,
    resetOKRsValues,
    newsLetterSev1Sev2IncidentsData,
    newsLetterSev1Sev2IncidentsDataOptions,
    appSessionToken,
    sev1TotalCount,
    sev2TotalCount,
    newsLetterWeeklyOutagesData,
    newsLetterWeeklyOutagesDataOptions,
    newsLetterWeeklyCommsData,
    newsLetterWeeklyCommsDataOptions,
    outagesWeeksHeaderList,
    outagesWeeklySortedData,
    commsWeeksHeaderList,
    commsWeeklySortedData,
    getCSSByComponent
}) => {
    useEffect(() => {
        if (isAuthenticated) {
            addUserVisit(0, 'IdentityWeeklyNewsLetter.tsx_v2');
            getCSSByComponent('IdentityWeeklyNewsLetter', 'Email');
        }
        document.querySelectorAll('.react-datepicker-wrapper input').forEach(a => { a.setAttribute('aria-label', 'calender') });
    }, [isAuthenticated]);
    useEffect(() => {
        if (WeekStartDate === null) {
            GetWeekStartDate(null);
        }
        else {
            requestIdentityNewsLetterData(12, moment(WeekStartDate).format('MM/DD/YYYY'));
        }       
    }, [WeekStartDate]);

    useEffect(() => {
        if (OKRWeekStartDate === null) {
            GetOKRWeekStartDate(null);
        }
    }, [GetOKRWeekStartDate]);

    //========== Call Load filterKeyResults method when WeekStartDate is changed ======//
    useEffect(() => {
        if (OKRWeekStartDate !== null) {
            filterKeyResults();
        }
    }, [OKRWeekStartDate]);

    const toastIdRef = useRef<ToastId>('');

    const [showOKRsViewpopup, setshowOKRsViewpopup] = useState(false);

    //=======================function on textbox change while Editing a row in Objective Key Results==============//
    const ObjectiveKeyResultvalueschange = (e, value) => {
        let val: number = value.keyResultID;
        ObjectiveKeyResultValueChange(e, val);
    }

    //================ function to enable the Edit for Objective Key Results Row =====================//
    const EditObjectiveKeyResultRows = (e, value) => {
        let val: number = value.keyResultID;
        EditObjectiveKeyResultRow(val);
    }

    //=============== function to enable Add new row for Objective Key Results ====================================//
    const EnableAddNewforObjectiveKeyResultRow = () => {
        EnableAddNewforObjectiveKeyResult();
    }

    //==================function to Cancel the Edit for Objective Key results Row ==================================//
    const cancelEditObjectiveKeyResultRows = (e, value) => {
        if (window.confirm("Are you sure you want to Cancel this Edit?")) {
            let val: number = value.keyResultID;
            cancelEditObjectiveKeyResultRow();
        }

    }

    //================= function to delete the selected Row for Objective Key Results =======================//
    const DeleteObjectiveKeyResultRow = (e, row) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            setloadingtrue();
            IdentityWeeklyNewsLetterAPI.DeleteobjectiveKeyResultsData(row.keyResultID, username)
                .then((result: number) => {
                    if (result > 0) {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg("OKRs Data for Key Result " + row.keyResults + " Deleted successfully",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                        // To Reload only the Key Results for that corresponding Objective and Week
                        getkeyResultsData();
                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while deleting",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
    }

    //=============function to Save for OKRs Objective Data====================//
    const SaveAddNewObjectiveData = () => {
        if (isNullOrUndefined(ObjectiveStartDate)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please Select the  Objective StartDate',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        else if (isNullOrUndefined(ObjectiveEndDate)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please Select the  Objective EndDate',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        else if (isNullOrUndefined(newObjective) || !/\S/.test(newObjective)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please enter the Objective Value',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        else {
            setloadingtrue();
            let message = newObjective + "Objective Saved successfully";
            let ObjectiveStDate = moment(ObjectiveStartDate).format('MM/DD/YYYY');
            let ObjectiveEdDate = moment(ObjectiveEndDate).format('MM/DD/YYYY');
            IdentityWeeklyNewsLetterAPI.AddObjectivesData(newObjective, ObjectiveStDate, ObjectiveEdDate, username)
                .then((result: number) => {
                    if (result != 0) {
                        // To Reload the Objective
                        refreshObjectivesDataAfterSave(result);
                        toastIdRef.current = toast.success(
                            renderToastifyMsg(message,
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                    else {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Error while adding the Special Topics Data",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while adding the Special Topics Data",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
    }

    //=============function to Save for Edit/Add the  values for Objectives Key Results====================//
    const SaveObjectiveKeyResultData = (e, row) => {
        if (row.keyResultID === 0) {
            row.createdBy = username;
        }
        else {
            row.updatedBy = username;
        }

        row.weekDate = moment(OKRWeekStartDate).format('MM/DD/YYYY');
        if (isNullOrUndefined(row.objectiveID) || row.objectiveID === 0) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please select the Objective to add the Key Result',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        else if (isNullOrUndefined(row.keyResultsEdit) || !/\S/.test(row.keyResultsEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please enter the Objective Key Result',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        else if (isNullOrUndefined(row.targetValueEdit) || !/\S/.test(row.targetValueEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please enter the Target Value',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );

        }
        else if (isNullOrUndefined(row.progressValueEdit) || !/\S/.test(row.progressValueEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please enter the progress Value',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );

        }
        else {
            setloadingtrue();
            let message = (row.keyResultID === 0) ? "OKRs Data for Key Result " + row.keyResultsEdit + " Saved successfully" : "OKRs Data updated successfully";
            IdentityWeeklyNewsLetterAPI.AddorupdateObjectivesKeyResultsData(row)
                .then((result: number) => {
                    if (result != -1) {
                        // To Reload only the Key Results for that corresponding Objective and Week
                        getkeyResultsData();
                        toastIdRef.current = toast.success(
                            renderToastifyMsg(message,
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                    else {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Error while adding the Special Topics Data",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while adding the Special Topics Data",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
    }
    const DeleteTopOutagesData = () => {
        TopOutageDataDelete(username);
    }

    const isWeekday = (date) => {
        var day = new Date(moment(date).format('MM/DD/YYYY')).getDay();
        return day == 6;
    };

    const openOKRsViewpopup = () => {
        // By Default load the OKR's View Page with Selected Week Date and Panel Objective
        handleOKRObjectiveChange(newsLetterObjectivesData.objectiveID);
        GetOKRWeekStartDate(WeekStartDate);
        setshowOKRsViewpopup(true);
    }

    const sendEmailLoading = () => {        
        handleLoadingProcess();
    }


    const closeOKRsViewpopup = () => {
        setshowOKRsViewpopup(false);
        resetOKRsValues();
        // After Save to reload the Data
        requestIdentityNewsLetterData(12, moment(WeekStartDate).format('MM/DD/YYYY'));
    }

    const rtrim = (str) => {
        if (!str) return str;
        let repstring = "<p><span style=" + '"' + "color: rgb(51, 51, 51);" + '"' + "><span class=" + '"' + "ql-cursor" + '"' + ">﻿</span></span></p>";
        //var incidentNoteFormated = str.replaceAll(/<p\s*\/*>/gi, '');
        //incidentNoteFormated = incidentNoteFormated.replaceAll(/<br\s*\/*>/gi, '');
        //incidentNoteFormated = incidentNoteFormated.replaceAll("?", '');
        //incidentNoteFormated = incidentNoteFormated.replaceAll("</span><a", "</span></p><br/><br/><p><a");        
        //incidentNoteFormated = incidentNoteFormated.replaceAll("</span></p><p><a", "</span></p><br/><br/><p><a");
        //return "<p>" + incidentNoteFormated; 
        var incidentNoteFormated = str.replaceAll(repstring, '');

        return incidentNoteFormated;
    }


    const IdentitynewsletterTopOutagesDataSave = () => {
        //=============function to Save the  values for Identity Newsletter TopOutages====================
        setloadingtrue();
        let LoginuserId = username;
        let obj = newsLettertopOutagesData;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
        }
        var objTopOutage: IPropIdentityTopOutages = {
            topOutageID: obj.topOutageID,
            numberOfSev1: obj.numberOfSev1,
            numberOfSev2: obj.numberOfSev2,
            numberOfUniqueCCL: obj.numberOfUniqueCCL,
            weekDate: moment(WeekStartDate).format('MM/DD/YYYY'),
            weekId: obj.weekId,
            sev1Customer: rtrim(obj.sev1Customer),
            sev2Customer: rtrim(obj.sev2Customer),
            outageswithExternal: rtrim(obj.outageswithExternal),
            incidentNotes: rtrim(obj.incidentNotes),
            notes: obj.notes,
            isActive: obj.isActive == 1 ? true : false,
            updatedBy: LoginuserId,
            createdBy: obj.createdBy,
            createdDate: obj.createdDate,
            updatedDate: obj.updatedDate,
            topOutageTable: rtrim(obj.topOutageTable)
        };
        IdentityWeeklyNewsLetterAPI.SaveTopOutagesData(objTopOutage)
            .then((result: number) => {
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg("Identity Newsletter Top Outage Data Saved Successfully",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    // Reload only Top Outages Data
                    requestIdentityNewsLetterData(9, moment(WeekStartDate).format('MM/DD/YYYY'));
                    setloadingfalse();
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });
    }

    return (
        <div>
            <DeprecatedFunctionalityBanner />
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'>
                <section className='section'>
                    <div className='container'>
                        <IdentityWeeklyNewsLetterTable
                            newsLetterGroupsData={newsLetterGroupsData}
                            IsLivesitePMOrUser={IsLivesitePMOrUser}
                            WeekStartDate={WeekStartDate}
                            GetWeekStartDate={GetWeekStartDate}
                            newsLetterWeeklyAvailabilityData={newsLetterWeeklyAvailabilityData}
                            newsLetterWeeklySafeflyData={newsLetterWeeklySafeflyData}
                            newsLetterWeeklySafeflyDataOptions={newsLetterWeeklySafeflyDataOptions}
                            SafeflyMetricsTotal={SafeflyMetricsTotal}
                            newsLetterWeeklyChangeToolData={newsLetterWeeklyChangeToolData}
                            newsLetterWeeklyChangeToolDataOptions={newsLetterWeeklyChangeToolDataOptions}
                            ChangeToolMetricsTotal={ChangeToolMetricsTotal}
                            newsLetterWeeklyChangeExceptionData={newsLetterWeeklyChangeExceptionData}
                            newsLetterWeeklyChangeExceptionDataOptions={newsLetterWeeklyChangeExceptionDataOptions}
                            ChangeExceptionMetricsTotal={ChangeExceptionMetricsTotal}
                            newsLetterObjectivesData={newsLetterObjectivesData}
                            newsLetterOKRsData={newsLetterOKRsData}
                            newsLettertopOutagesData={newsLettertopOutagesData}
                            newsLettertopOutagesDataTable={newsLettertopOutagesDataTable}
                            UpdatetopOutagedata={UpdatetopOutagedata}
                            TopOutageDataEditCancel={TopOutageDataEditCancel}
                            DeleteTopOutagesData={DeleteTopOutagesData}
                            IdentitynewsletterTopOutagesDataSave={IdentitynewsletterTopOutagesDataSave}
                            SendMailIdentityNewsletterDashboard={SendMailIdentityNewsletterDashboard}
                            openOKRsViewpopup={openOKRsViewpopup}
                            sendEmailLoading={sendEmailLoading}
                            newsLetterSev1Sev2IncidentsData={newsLetterSev1Sev2IncidentsData}
                            newsLetterSev1Sev2IncidentsDataOptions={newsLetterSev1Sev2IncidentsDataOptions}
                            appSessionToken={appSessionToken}
                            sev1TotalCount={sev1TotalCount}
                            sev2TotalCount={sev2TotalCount}
                            newsLetterWeeklyOutagesData={newsLetterWeeklyOutagesData}
                            newsLetterWeeklyOutagesDataOptions={newsLetterWeeklyOutagesDataOptions}
                            newsLetterWeeklyCommsData={newsLetterWeeklyCommsData}
                            newsLetterWeeklyCommsDataOptions={newsLetterWeeklyCommsDataOptions}
                            outagesWeeksHeaderList={outagesWeeksHeaderList}
                            outagesWeeklySortedData={outagesWeeklySortedData}
                            commsWeeksHeaderList={commsWeeksHeaderList}
                            commsWeeklySortedData={commsWeeklySortedData}
                        />
                    </div>
                </section>
            </LoadingOverlay>

            <Modal show={showOKRsViewpopup} onHide={closeOKRsViewpopup} size='xl' backdrop='static'>
                <Modal.Header closeButton>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='2'>
                            </Col>
                            <Col lg='8' style={{ textAlign: 'center' }}>
                                <h4><b>Divisional Objectives and Key Results (OKR)</b></h4>
                            </Col>
                            <Col lg='2'>
                            </Col>
                        </Row>
                    </div>
                </Modal.Header>
                <Modal.Body className="OKRspopheight">
                    <div>
                        <div className="card">
                            <div className="p-fluid p-grid">
                                <Row>
                                    <Col lg='6'>
                                        <div className="p-field p-col-10 p-md-4-objective ObjectiveInput">
                                            <label className="fontcolor">Objectives</label> &ensp;&ensp;
                                            <Dropdown className="ObjectivesStyle" id={'objective'} value={selectedObjective} options={newsLetterOKRsObjectives} onChange={(e) => handleOKRObjectiveChange(e.value)} optionLabel="name" />
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="p-field p-col-10 p-md-4-objective" style={{ display: 'flex' }}>
                                            <div style={{ width: '100%' }}>
                                                <Row>
                                                    <Col lg="2"><span style={{ fontWeight: 'bold' }}>Date Range</span></Col>
                                                    <Col lg="5">
                                                        <div style={{ display: 'flex' }}>
                                                            <DatePicker className='InputStyleObjectiveDate'
                                                                id={'ObjectiveStartDate'}
                                                                selected={ObjectiveStartDate}
                                                                onChange={handleObjectiveStartDateChange}
                                                                maxDate={ObjectiveEndDate}
                                                            />  &nbsp;&nbsp;
                                                            <div>
                                                                <label style={{ fontSize: '20px' }} htmlFor='ObjectiveStartDate'> <FontAwesomeIconMemo icon={'calendar'} className='icon-format' /></label>&nbsp;
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg="5">
                                                        <div style={{ display: 'flex' }}>
                                                            <DatePicker className='InputStyleObjectiveDate'
                                                                id={'ObjectiveEndDate'}
                                                                selected={ObjectiveEndDate}
                                                                onChange={handleObjectiveEndDateChange}
                                                                minDate={ObjectiveStartDate}
                                                            />  &nbsp;&nbsp;
                                                            <div>
                                                                <label style={{ fontSize: '20px' }} htmlFor='ObjectiveEndDate'> <FontAwesomeIconMemo icon={'calendar'} className='icon-format' /></label>&nbsp;
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {
                                (isAddNewObjective) ?
                                    <React.Fragment>
                                        <div>
                                            <Row>
                                                <Col lg="10">
                                                    <div className="p-field p-col-12 p-md-4-objective">
                                                        <span className="p-float-label" style={{ paddingTop: '10px' }}>
                                                            <InputText style={{ width: '96%' }} id="newObjective" value={newObjective} onChange={(e) => addNewObjective(e)} />
                                                            <label htmlFor="newObjective" className="fontcolor">Add New Objective<span className="spnmandatoryIcons">*</span></label>
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col lg="2">
                                                    <div className="p-field p-col-12 p-md-4-objective">
                                                        <Button title="Save Changes" className='PIRAddbuttons' onClick={SaveAddNewObjectiveData}> <FontAwesomeIcon icon={'save'} style={{ backgroundColor: 'None', border: 'none' }} />&nbsp;Submit</Button>&ensp;
                                                        <Button title="Cancel Changes" className='PIRAddbuttons' onClick={CancelAddNewObjectiveData}> <FontAwesomeIcon icon={'ban'} style={{ backgroundColor: 'None', border: 'none' }} />&nbsp;Cancel</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment></React.Fragment>
                            }
                        </div>
                        <br />
                        <div style={{ width: '100%' }}>
                            <Row>
                                <Col lg='2'>
                                </Col>
                                <Col lg='8' style={{ textAlign: 'center' }}>
                                    <div className="aligntable objectiveskeyResultsDate">
                                        <h4 style={{ paddingTop: '5px' }}><b>Key Results progress by Week</b></h4>&ensp;
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <DatePicker className='InputStyleObjectiveDate'
                                                            id={'OKRWeekStartDate'}
                                                            selected={OKRWeekStartDate}
                                                            onChange={GetOKRWeekStartDate}
                                                            filterDate={isWeekday}
                                                            maxDate={new Date()}
                                                            minDate={new Date(2018, 11, 1)}
                                                        />  &nbsp;&nbsp;
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <label style={{ fontSize: '20px' }} htmlFor='weekstartDate'> <FontAwesomeIconMemo icon={'calendar'} className='icon-format' /></label>&nbsp;
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                                <Col lg='2'>
                                </Col>
                            </Row>
                        </div>
                        <br />
                        <Row>
                            <Col lg='8'></Col>
                            <Col lg="4">
                                <div className="divaddnew">
                                    <Button disabled={isAddNewObjective} title="Add Key Result" className='PIRAddbuttons' onClick={EnableAddNewforObjectiveKeyResultRow}> <FontAwesomeIcon icon={'plus'} style={{ backgroundColor: 'None', border: 'none' }} />&nbsp;Add Key Result</Button>
                                </div>
                            </Col>
                        </Row>
                        <Row className="tblpadding">
                            <Col lg="12">
                                <table id="tblPIRLearnings" className="tblPIRLearnings">
                                    <thead>
                                        <tr>
                                            <th className="thwidth600">Key Results</th>
                                            <th className="thwidth300">Progress</th>
                                            <th className="thwidth300">Target</th>
                                            <th className="thwidth100">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            isArrayWithLength(selectedKeyResults) ?
                                                <React.Fragment>
                                                    {isArrayWithLength(selectedKeyResults) ?
                                                        selectedKeyResults
                                                            .map((row: IPropIdentityKeyResults, index) => (
                                                                <React.Fragment key={index}>
                                                                    <tr>
                                                                        <td className="tdwordbreak">
                                                                            <div>
                                                                                {(row.isEdit) ?
                                                                                    <input type="text" className="form-control" onChange={e => ObjectiveKeyResultvalueschange(e, row)} value={row.keyResultsEdit} name="keyResultsEdit" />
                                                                                    : <React.Fragment>{row.keyResults}</React.Fragment>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td className="tdwordbreak">
                                                                            <div>
                                                                                {(row.isEdit) ?
                                                                                    <input type="text" className="form-control" onChange={e => ObjectiveKeyResultvalueschange(e, row)} value={row.progressValueEdit} name="progressValueEdit" />
                                                                                    : <React.Fragment>{row.progressValue}</React.Fragment>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td className="tdwordbreak">
                                                                            <div>
                                                                                {(row.isEdit) ?
                                                                                    <input type="text" className="form-control" onChange={e => ObjectiveKeyResultvalueschange(e, row)} value={row.targetValueEdit} name="targetValueEdit" />
                                                                                    : <React.Fragment>{row.targetValue}</React.Fragment>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td className="centericons">
                                                                            {
                                                                                (!row.isEdit)
                                                                                    ?
                                                                                    <React.Fragment>
                                                                                        <span onClick={e => EditObjectiveKeyResultRows(e, row)}>
                                                                                            <FontAwesomeIcon title='Click to edit the Row' className="bluecoloricons Iconsstyles" icon={'pencil-alt'} /></span> &nbsp;
                                                                                        <FontAwesomeIcon title='Click to Delete the Row' className="redcolorIcons Iconsstyles" icon={'trash'} onClick={e => DeleteObjectiveKeyResultRow(e, row)} /> &nbsp;
                                                                                    </React.Fragment>
                                                                                    :
                                                                                    <React.Fragment>
                                                                                        <FontAwesomeIcon title='Save Changes' className="bluecoloricons Iconsstyles" onClick={e => SaveObjectiveKeyResultData(e, row)} icon={'save'} /> &nbsp;
                                                                                        <FontAwesomeIcon title='Cancel Edit' className="Iconsstyles" icon={'ban'} onClick={e => cancelEditObjectiveKeyResultRows(e, row)} />
                                                                                    </React.Fragment>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            ))
                                                        : <React.Fragment></React.Fragment>
                                                    }

                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <tr>
                                                        <td colSpan={11} style={{ textAlign: 'center' }} >
                                                            No Key Results
                                                        </td>
                                                    </tr>
                                                </React.Fragment>

                                        }
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: IApplicationState) => ({
    ...state.IdentityWeeklyNewsLetter,
    isAuthenticated: state.authUser.isAuthenticated,
    username: state.authUser.login
})

export default connect(mapStateToProps, actionCreators)(IdentityWeeklyNewsLetter);
