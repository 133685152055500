import React, { ReactNode } from 'react';
import Layout from './Layout';
import { Route, Switch } from 'react-router-dom';
import { RoutesConfig } from './config/routes.config';
import {
    ServiceHealth, HomeTiles, AvailabilitySummary, WeeklyIDSHRData, ActionItems, AdminSettings, PIRLearnings, HeatmapAdmin, MSERHeatmapPreview, MSERPresentationView, PlatformTool,
    MonthlyAvailbility, WeeklyAvailability, MonthlyAvailbilityQOS, CriticalAdvisory, MSERHeatmapTrend, EscalationDirectory,
    UserScenarioCompletion, AckTemplate, ChecklistTemplate, Checklist, OnCallChecklist, ReachabilityMetrics, IDAcktracking,
    InternalEscalation, FutureOnCall, CreateAdoHierarchy, ADOTaskCreation, EmailConfiguration, DCMapping, Configuration, IdentityWeeklyNewsLetter, DivisionalOKRs, PIRRiskTool, WIPSHRData, WZTNASHRData, SecuritySHRData, DublinSHRData,
    LastWeekIdNewsLetter
} from './containers';

export const routes: ReactNode = (
    <Layout>
        <Route exact path={RoutesConfig.HomeTiles.path} component={HomeTiles} />
        <Route path={RoutesConfig.ServiceHealth.path} component={ServiceHealth} />
        <Route path={RoutesConfig.AvailabilitySummary.path} component={AvailabilitySummary} />
        <Route path={RoutesConfig.AdminSettings.path} component={AdminSettings} />

        <Route path={RoutesConfig.HomeTiles.subMenu[0].href} component={WeeklyIDSHRData} />
        <Route path={RoutesConfig.HomeTiles.subMenu[1].href} component={WIPSHRData} />
        <Route path={RoutesConfig.HomeTiles.subMenu[2].href} component={WZTNASHRData} />
        <Route path={RoutesConfig.HomeTiles.subMenu[3].href} component={SecuritySHRData} />
        <Route path={RoutesConfig.HomeTiles.subMenu[4].href} component={DublinSHRData} />

        <Route exact path={RoutesConfig.ServiceHealth.subMenu[1].href} component={WeeklyAvailability} />
        <Route exact path={RoutesConfig.ServiceHealth.subMenu[2].href} component={MonthlyAvailbility} />
        <Route exact path={RoutesConfig.ServiceHealth.subMenu[3].href} component={MonthlyAvailbilityQOS} />
        <Route exact path={RoutesConfig.ServiceHealth.subMenu[6].href} component={DivisionalOKRs} />
        <Route exact path={RoutesConfig.ServiceHealth.subMenu[7].href} component={LastWeekIdNewsLetter} />

        <Route exact path={RoutesConfig.MSER.subMenu[0].href} component={MSERHeatmapPreview} />
        <Route exact path={RoutesConfig.MSER.subMenu[1].href} component={MSERPresentationView} />
        <Route exact path={RoutesConfig.MSER.subMenu[2].href} component={MSERHeatmapTrend} />
        <Route exact path={RoutesConfig.MSER.subMenu[3].href} component={HeatmapAdmin} />
        <Route exact path={RoutesConfig.MSER.subMenu[4].href} component={ADOTaskCreation} />

        <Route exact path={RoutesConfig.CriticalChange.subMenu[0].href} component={CriticalAdvisory} />

        <Route exact path={RoutesConfig.OnCall.subMenu[0].href} component={OnCallChecklist} />
        <Route exact path={RoutesConfig.OnCall.subMenu[1].href} component={Checklist} />
        <Route exact path={RoutesConfig.OnCall.subMenu[2].href} component={ChecklistTemplate} />
        <Route exact path={RoutesConfig.OnCall.subMenu[5].href} component={EscalationDirectory} />
        <Route exact path={RoutesConfig.OnCall.subMenu[6].href} component={InternalEscalation} />
        <Route exact path={RoutesConfig.OnCall.subMenu[7].href} component={FutureOnCall} />
        <Route exact path={RoutesConfig.OnCall.subMenu[9].href} component={OnCallChecklist} />
        <Route exact path={RoutesConfig.OnCall.subMenu[10].href} component={OnCallChecklist} />

        <Route exact path={RoutesConfig.PlatformPMTools.subMenu[0].href} component={IDAcktracking} />
        <Route exact path={RoutesConfig.PlatformPMTools.subMenu[1].href} component={AckTemplate} />
        <Route exact path={RoutesConfig.PlatformPMTools.subMenu[3].href} component={PIRRiskTool} />
        <Route exact path={RoutesConfig.PlatformPMTools.subMenu[4].href} component={CreateAdoHierarchy} />
        <Route exact path={RoutesConfig.PlatformPMTools.subMenu[5].href} component={PlatformTool} />
        <Route exact path={RoutesConfig.PlatformPMTools.subMenu[7].href} component={EmailConfiguration} />
        <Route exact path={RoutesConfig.PlatformPMTools.subMenu[9].href} component={DCMapping} />
        <Route exact path={RoutesConfig.PlatformPMTools.subMenu[6].href} component={Configuration} />
    </Layout>
);


