import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IFutureOnCallState } from './types';

const initialState = Object.freeze<IFutureOnCallState>({
    isLoading: false,
    futureOnCallData: [],
    futureOnCallDataFiltered: [],
    searchText: "",
    isactive: '1',
    sortcolumn: "title",
    reverseSort: false,
    TenantTeamNamesList: [],
    CloudNamesList: [],
    Isopenemailpopup: false,
    OnCallAliasEmailIDs: '',
    BackupOnCallAliasEmailIDs: '',
    copyEmailselectedImorOce: 'All',
    tenantList: [],
    selectedTenant: [],
    teamList: [],
    selectedTeam: [],
    selectedCloud: [],
    selectedServiceLifeCycle: [],
    ServiceLifeCycleList: []
});

export const reducer = (
    state: IFutureOnCallState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false
            };
        case ActionType.GET_TENANTTEAMNAME_DATA:
            return {
                ...state,
                TenantTeamNamesList: action.TenantTeamNamesList
            };
        case ActionType.GET_CLOUDNAMES_DATA:
            return {
                ...state,
                CloudNamesList: action.CloudNamesList
            };
        case ActionType.GET_SERVICELIFECYCLE_LIST:
            return {
                ...state,
                ServiceLifeCycleList: action.ServiceLifeCycleList
            };            
        case ActionType.CLOUD_CHANGE:
            return {
                ...state,
                selectedCloud: action.selectedCloud
            };
        case ActionType.SERVICELIFECYCLE_CHANGE:
            return {
                ...state,
                selectedServiceLifeCycle: action.selectedServiceLifeCycle
            };
        case ActionType.GET_FUTUREONCALL_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.GET_FUTUREONCALL_SUCCESS:  // === for Future on call Data success ===============//
            return {
                ...state,
                futureOnCallData: action.futureOnCallData,
                futureOnCallDataFiltered: action.futureOnCallDataFiltered,
                copyEmailselectedImorOce: action.copyEmailselectedImorOce,
                isLoading: false
            };
        case ActionType.GET_FUTUREONCALL_FAILURE:  // === for Future on call Data failure ===============//
            return {
                ...state,
                futureOnCallData: [],
                futureOnCallDataFiltered: [],
                isLoading: false
            };
        case ActionType.CHANGE_SORTING_FUTUREONCALL:
            return {
                ...state,
                reverseSort: action.reverseSort,
                sortcolumn: action.sortcolumn,
                futureOnCallDataFiltered: action.futureOnCallDataFiltered
            };
        case ActionType.OPEN_EMAIL_POPUP:
            return {
                ...state,
                OnCallAliasEmailIDs: action.OnCallAliasEmailIDs,
                BackupOnCallAliasEmailIDs: action.BackupOnCallAliasEmailIDs,
                Isopenemailpopup: true
            };
        case ActionType.EMAIL_POPUP_CLOSE:
            return {
                ...state,
                OnCallAliasEmailIDs: '',
                BackupOnCallAliasEmailIDs: '',
                Isopenemailpopup: false
            };
        case ActionType.TENANT_CHANGE:
            return {
                ...state,
                selectedTenant: action.selectedTenant
            };
        case ActionType.TEAM_CHANGE:
            return {
                ...state,
                selectedTeam: action.selectedTeam
            };
        case ActionType.CLEAR_FILTER:
            return {
                ...state,
                selectedTeam: action.selectedTeam,
                selectedTenant: action.selectedTenant,
                selectedCloud: action.selectedCloud,
                selectedServiceLifeCycle: action.selectedServiceLifeCycle
            };
        case ActionType.RESET_STATE:
            return initialState;

        default:
            return state;
    }
};
