import React, { useEffect, useRef } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import { actionCreators, reducer } from '../../store/InternalEscalation';
import { InternalEscalationTable } from '../InternalEscalation/child-components';
import { isArrayWithLength, renderToastifyMsg } from '../../utils';
import LoadingOverlay from 'react-loading-overlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import {
    Row,
    Col, Card, CardText
} from 'reactstrap';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import { NewFunctionalityBanner } from '../../components';

type InternalEscalationProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly history: History }
    & typeof useParams;

const InternalEscalation: React.FC<InternalEscalationProps> = ({
    isLoading,
    addUserVisit,
    isAuthenticated,
    selectedCloud,
    serviceLifeCycle,
    selectedServiceLifeCycle,
    classificationList,
    selectedClassification,
    serviceGroupNameList,
    selectedServiceGroupName,
    teamGroupNameList,
    selectedTeamGroupName,
    tenantList,
    selectedTenant,
    selectedImorOce,
    handleCloudChange,
    handleServiceLifeCycleChange,
    handleClassificationChange,
    handleServiceGroupChange,
    handleTenantChange,
    handleImorOceChange,
    handleTeamGroupChange,
    GetDropdownListDataInternalEscalation,
    clearDropdownFilter,
    onCallData,
    getOnCallContactList,
    defaultexpandrows,
    OpenEmailPOPUP,
    Isopenemailpopup,
    handleemailpopupClose,
    OnCallAliasEmailIDs,
    BackupOnCallAliasEmailIDs,
    filteredonCallData,
    selectedIdentityServiceClassificationType,
    handleIdentityServiceClassificationTypeChange,
    copyEmailselectedImorOce
}) => {
    const toastIdRef = useRef(null);
    useEffect(() => {
        if (isAuthenticated)
            addUserVisit(0, 'InternalEscalation.tsx_v2');
    }, [isAuthenticated]);

    useEffect(() => {
        GetDropdownListDataInternalEscalation();
    }, [GetDropdownListDataInternalEscalation]);

    const copyToClipBoard = (email) => {
        var textField = document.createElement('textarea');
        textField.innerText = email;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        if (!toast.isActive(toastIdRef.current)) {
            toastIdRef.current = toast.success(
                renderToastifyMsg('copied to clipboard',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
            );
        }

    }

    return (
        <div>
            <NewFunctionalityBanner />
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        <InternalEscalationTable
                            selectedCloud={selectedCloud}
                            handleCloudChange={(selectedCloud) => handleCloudChange(selectedCloud)}
                            serviceLifeCycle={serviceLifeCycle}
                            handleServiceLifeCycleChange={(selectedServiceLifeCycle) => handleServiceLifeCycleChange(selectedServiceLifeCycle)}
                            selectedServiceLifeCycle={selectedServiceLifeCycle}
                            classificationList={classificationList}
                            handleClassificationChange={(selectedClassification) => handleClassificationChange(selectedClassification)}
                            selectedClassification={selectedClassification}
                            serviceGroupNameList={serviceGroupNameList}
                            handleServiceGroupChange={(selectedServiceGroupName) => handleServiceGroupChange(selectedServiceGroupName)}
                            selectedServiceGroupName={selectedServiceGroupName}
                            tenantList={tenantList}
                            handleTenantChange={(selectedTenant) => handleTenantChange(selectedTenant)}
                            selectedTenant={selectedTenant}
                            selectedImorOce={selectedImorOce}
                            handleImorOceChange={(selectedImorOce) => handleImorOceChange(selectedImorOce)}
                            clearDropdownFilter={clearDropdownFilter}
                            onCallData={onCallData}
                            filteredonCallData={filteredonCallData}
                            getOnCallContactList={getOnCallContactList}
                            defaultexpandrows={defaultexpandrows}
                            OpenEmailPOPUP={OpenEmailPOPUP}
                            selectedIdentityServiceClassificationType={selectedIdentityServiceClassificationType}
                            handleIdentityServiceClassificationTypeChange={(selectedIdentityServiceClassificationType) => handleIdentityServiceClassificationTypeChange(selectedIdentityServiceClassificationType)}
                            teamGroupNameList={teamGroupNameList}
                            selectedTeamGroupName={selectedTeamGroupName}
                            handleTeamGroupChange={(selectedTeamGroupName) => handleTeamGroupChange(selectedTeamGroupName)}
                        />
                    </div>
                </section>
                <Modal show={Isopenemailpopup} onHide={handleemailpopupClose} size='lg' backdrop='static'>
                    <Modal.Header closeButton className="commonModalHeader">
                        <div style={{ width: '100%' }}>
                            <Row>
                                <Col lg='12' style={{ textAlign: 'center' }}>
                                    <h4><b> Copy Email  </b></h4>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col lg='12'>
                                <table className="tblwidth100" id="TblSendEmails">
                                    <tbody>
                                        {
                                            (BackupOnCallAliasEmailIDs === "" && OnCallAliasEmailIDs === "")
                                                ? <tr style={{ paddingBottom: '40px' }}>
                                                    <td style={{ color: 'red', fontSize: '18px' }}>No Email Address Found</td>
                                                </tr>
                                                : <React.Fragment></React.Fragment>
                                        }
                                    {
                                        (OnCallAliasEmailIDs !== "")
                                                ? <tr style={{ paddingBottom: '40px' }}>
                                                    {
                                                        (copyEmailselectedImorOce === "All") ?
                                                            <td className="tdwidth15">Both IM and OCE Alias</td>
                                                            : (copyEmailselectedImorOce === "im") ?
                                                                <td className="tdwidth15">IM On Call Alias</td>
                                                                : (copyEmailselectedImorOce === "oce") ?
                                                                    <td className="tdwidth15">On Call Engineer Alias</td>
                                                                    : <React.Fragment></React.Fragment>
                                                    }
                                                <td className="tdwidth5">:</td>
                                                <td className="tdwidth80">

                                                    {OnCallAliasEmailIDs} &nbsp;
                                                    <FontAwesomeIcon title="click to copy" onClick={e => copyToClipBoard(OnCallAliasEmailIDs)} className="copyemailicons" icon={faCopy} />
                                                </td>
                                            </tr>
                                            : <React.Fragment></React.Fragment>
                                    }

                                    <tr className="tdheight20"></tr>
                                    {
                                        (BackupOnCallAliasEmailIDs !== "")
                                            ? <tr style={{ paddingBottom: '40px' }}>
                                                    {
                                                        (copyEmailselectedImorOce === "All") ?
                                                            <td>Both IM and OCE Backup Alias</td>
                                                            : (copyEmailselectedImorOce === "im") ?
                                                                <td>Backup IM On Call Alias</td>
                                                                : (copyEmailselectedImorOce === "oce") ?
                                                                    <td>Backup On Call Engineer Alias</td>
                                                                    : <React.Fragment></React.Fragment>
                                                    }
                                                <td>:</td>
                                                <td>{BackupOnCallAliasEmailIDs} &nbsp;
                                                    <FontAwesomeIcon title="click to copy" onClick={e => copyToClipBoard(BackupOnCallAliasEmailIDs)} className="copyemailicons" icon={faCopy} />
                                                </td>
                                            </tr>
                                            : <React.Fragment></React.Fragment>
                                        }
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </LoadingOverlay>

        </div>
    );
};
const mapStateToProps = (state: IApplicationState) => ({
    ...state.InternalEscalation,
    isAuthenticated: state.authUser.isAuthenticated

})

export default connect(mapStateToProps, actionCreators)(InternalEscalation);
