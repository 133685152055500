import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import { actionCreators, reducer, IPropEscalationDirectoryData } from '../../store/EscalationDirectory';
import { EscalationDirectoryTable } from '../Escalation_Directory/child-components';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap'
import { Row, Col, Card, CardBody } from 'reactstrap';
import { EscalationDirectoryAPI } from '../../api';
import { toast } from 'react-toastify';
import { isNullOrUndefined, isArrayWithLength, renderToastifyMsg } from '../../utils';
import bxloader from '../../assets/images/bxloader.gif'
import { Editor } from 'primereact/editor';
import { NewFunctionalityBanner } from '../../components';

type EscalationDirectoryProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly username: string }
    & typeof useParams;

const EscalationDirectory: React.FC<EscalationDirectoryProps> = ({
    escalationDirectoryDataFiltered,
    isAuthenticated,
    requestEscalationDirectoryData,
    isLoading,
    setloadingtrue,
    setloadingfalse,
    searchText,
    username,
    UpdateSearchText,
    addUserVisit,
    sortData,
    reverseSort,
    sortcolumn
}) => {
    useEffect(() => {
        if (isAuthenticated)
            addUserVisit(0, 'EscalationDirectory.tsx_v2');
    }, [isAuthenticated]);
    useEffect(() => {
        requestEscalationDirectoryData();
    }, []);
    const toastIdRef = useRef(null);
    const [showeditpopup, setshoweditpopup] = useState(false);
    const [showaddpopup, setshowaddpopup] = useState(false);
    const [teamName, setteamName] = useState(null);
    const [contactPerson, setcontactPerson] = useState(null);
    const [issueDetails, setissueDetails] = useState("");
    const [escalationPath, setescalationPath] = useState("");
    const [remarks, setremarks] = useState("");
    const [rowValue, setrowValue] = useState(null);
    const [Disablesavebutton, setDisablesavebutton] = useState(false);
    const pattern = /[A-Za-z0-9.>, -=+_|<*'\[\]{}?#!~$%@]/;
    
    const header = (
        <span className="ql-formats">
            <button className="ql-bold" aria-label="Bold"></button>
            <button className="ql-italic" aria-label="Italic"></button>
            <button className="ql-underline" aria-label="Underline"></button>
            <button className="ql-link" aria-label="Insert Link"></button>
            <button type="button" className="ql-list" value="ordered" aria-label="Ordered List"></button>
            <button type="button" className="ql-list" value="bullet" aria-label="UnOrdered List"></button>
            <span className="ql-align ql-picker ql-icon-picker" style={{ display: 'none' }}></span>
            <select className="ql-align"></select>
            <span className="ql-header ql-picker" style={{ display: 'none' }}></span>
            <select className="ql-header"></select>
            <span className="ql-font ql-picker" style={{ display: 'none' }}></span>
            <select className="ql-font"></select>
        </span>
    );

    //=============== function to close Edit Escalation Directory popup =========================//
    const closeEditEscalationDirectoryPopup = () => {
        setshoweditpopup(false);
    }

    //=============function to open Modal popup when clicked Edit for Escalation Directory====================//
    const EscalationDirectoryRowEdit = (e, row) => {
        setDisablesavebutton(false);
        setrowValue(row);
        setshoweditpopup(true);
        setteamName(row.title);
        setcontactPerson(row.contactPerson);
        setissueDetails(row.description);
        setescalationPath(row.escalationPath);
        setremarks(row.remarks);
    }

    //=============function to close Modal popup when clicked Cancel in Modal Popup for Edit Escalation Directory====================//
    const escalationDirectoryRowCancel = () => {
        setteamName("");
        setcontactPerson("");
        setissueDetails("");
        setescalationPath("");
        setremarks("");
        setshoweditpopup(false);
        setDisablesavebutton(false);
    }

    //=============== function to close Edit Escalation Directory popup =========================//
    const closeAddEscalationDirectoryPopup = () => {
        setshowaddpopup(false);
    }

    //=============function to open Modal popup when clicked Edit for Escalation Directory====================//
    const EscalationDirectoryRowAdd = () => {
        var editEscalationData = { cloud: '', description: '', id: 0, isActive: true, link: '', link2: '', link3: '', service: '', title: '', updatedBy: '', escalationPath: '' };
        setDisablesavebutton(false);
        setteamName("");
        setcontactPerson("");
        setissueDetails("");
        setescalationPath("");
        setremarks("");
        setrowValue(editEscalationData);
        setshowaddpopup(true);
    }

    //=============function to close Modal popup when clicked Cancel in Modal Popup for Edit Escalation Directory====================//
    const escalationDirectoryAddRowCancel = () => {
        setDisablesavebutton(false);
        setshowaddpopup(false);
        setteamName("");
        setcontactPerson("");
        setissueDetails("");
        setescalationPath("");
        setremarks("");
    }

    //================ function to set Issue Details Value ========//
    const UpdateissueDetails = (e) => {
        setissueDetails(e.htmlValue);
    }

    //================ function to set Escalation Path Value ========//
    const UpdateescalationPath = (e) => {
        setescalationPath(e.htmlValue);
    }

    //================ function to set Remarks Value ========//
    const Updateremarks = (e) => {
        setremarks(e.htmlValue);
    }

   //================ function to set Team Value ========//
    const teamchange = (e) => {
        setteamName(e.target.value);
    }

    //================ function to set Contact Person Value ========//
    const contactPersonchange = (e) => {
        setcontactPerson(e.target.value);
    }

    //=============function to Save the  values for PIR Learnings====================//
    const escalationDirectoryRowSave = () => {
        setDisablesavebutton(true);
        if (teamName === "") {
            setDisablesavebutton(false);
            toastIdRef.current = toast.error(
                renderToastifyMsg("Please enter the Team Name",
                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
            return false;
        } if (escalationPath === null || escalationPath === undefined || escalationPath === '') {
            setDisablesavebutton(false);
            toastIdRef.current = toast.error(
                renderToastifyMsg("Please enter the Escalation Path",
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
            return false;
        }
        else if (escalationPath.replace("<p><br></p>", "") === "") {
            setDisablesavebutton(false);
            toastIdRef.current = toast.error(
                renderToastifyMsg("Please enter the Escalation Path",
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
            return false;
        }
        setloadingtrue();
        let LoginuserId = username;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
        }
        var objEscalation = {
            id: (rowValue.id == undefined || rowValue.id == null) ? 0 : rowValue.id,
            title: (teamName == undefined || teamName == null) ? '' : encodeURI(teamName),
            description: (issueDetails == undefined || issueDetails == null) ? '' : encodeURI(issueDetails),
            link: (rowValue.link == undefined || rowValue.link == null) ? '' : rowValue.link,
            link2: (rowValue.link2 == undefined || rowValue.link2 == null) ? '' : rowValue.link2,
            link3: (rowValue.link3 == undefined || rowValue.link3 == null) ? '' : rowValue.link3,
            escalationPath: (escalationPath == undefined || escalationPath == null) ? '' : escalationPath,
            service: (rowValue.service == undefined || rowValue.service == null) ? '' : rowValue.service,
            cloud: (rowValue.cloud == undefined || rowValue.cloud == null) ? '' : rowValue.cloud,
            contactPerson: (contactPerson == undefined || contactPerson == null) ? '' : contactPerson,
            remarks: (remarks == undefined || remarks == null) ? '' : remarks,
            isActive: rowValue.isActive == true ? 1 : 0,
            updatedBy: LoginuserId,
            updatedDate: new Date()
        };
        EscalationDirectoryAPI.SaveEscalationDirectory(objEscalation)
            .then((result: number) => {
                if (result != -1) {
                    setshoweditpopup(false);
                    setshowaddpopup(false);
                    toastIdRef.current = toast.success(
                        renderToastifyMsg("Escalation Directory Saved",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    requestEscalationDirectoryData();
                    setloadingfalse();
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });
    }

    return (
        <div>
            <NewFunctionalityBanner />
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        <EscalationDirectoryTable
                            escalationDirectoryDataFiltered={escalationDirectoryDataFiltered}
                            searchText={searchText}
                            UpdateSearchText={UpdateSearchText}
                            setshoweditpopup={(e, row) => EscalationDirectoryRowEdit(e, row)}
                            setshowaddpopup={EscalationDirectoryRowAdd}
                            sortData={(e, value) => sortData(e, value)}
                            reverseSort={reverseSort}
                            sortcolumn={sortcolumn}
                        />
                    </div>
                </section>
            </LoadingOverlay>
            <Modal show={showeditpopup} onHide={closeEditEscalationDirectoryPopup} size='lg' backdrop='static' >
                <Modal.Header closeButton>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='12'>
                                <h2>Edit Escalation Directory</h2>
                            </Col>

                        </Row>
                    </div>

                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg='3'>Team Name <span style={{ color: 'red' }}>*</span></Col>
                        <Col lg='9'>
                            <input type="text" style={{ width: '600px' }} required className="form-control" value={teamName} onChange={e => teamchange(e)} onKeyPress={(event) => {
                                if (!pattern.test(String.fromCharCode(event.charCode))) {
                                    event.preventDefault();
                                }
                            }} />
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: '15px' }}>
                        <Col lg='3'>Issue Details</Col>
                        <Col lg='9'>
                            <Editor style={{ height: '80px' }} value={issueDetails} onTextChange={UpdateissueDetails} headerTemplate={header}/>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: '15px' }}>
                        <Col lg='3'>Team DL/Escalation Path <span style={{ color: 'red' }}>*</span></Col>
                        <Col lg='9'>
                            <Editor style={{ height: '80px' }} value={escalationPath} onTextChange={UpdateescalationPath} headerTemplate={header}/>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: '15px' }}>
                        <Col lg='3'>Frequent Contacted Person in IM</Col>
                        <Col lg='9'>
                            <input type="text" style={{ width: '600px' }} required className="form-control" value={contactPerson} onChange={e => contactPersonchange(e)}
                                onKeyPress={(event) => {
                                    if (!pattern.test(String.fromCharCode(event.charCode))) {
                                        event.preventDefault();
                                    }
                                }} />
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: '15px' }}>
                        <Col lg='3'>
                            <div className="issuedetailsstyle">Remarks</div>
                        </Col>
                        <Col lg='9'>
                            {
                                <Editor style={{ height: '80px' }} value={remarks} onTextChange={Updateremarks} headerTemplate={header}/>
                            }
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='3'></Col>
                            <Col lg='6'>

                                {
                                    (isLoading) ?
                                        <div style={{ 'textAlign': "center" }}>
                                            <img src={bxloader} style={{ width: '80px', height: '40px' }} />
                                        </div>
                                        :
                                        <div>
                                        </div>

                                }
                            </Col>
                            <Col lg='3'>
                                {(isLoading) ?
                                    <div></div> :
                                    <div style={{ float: 'right' }}>
                                        <Button className='escalationsaveModelbutton' onClick={escalationDirectoryRowSave} disabled={Disablesavebutton}>Save</Button>&emsp;
                                        <Button className='escalationsaveModelbutton' onClick={escalationDirectoryRowCancel}>Close</Button>
                                    </div>
                                }
                            </Col>
                        </Row>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal show={showaddpopup} onHide={closeAddEscalationDirectoryPopup} size='lg' backdrop='static' >
                <Modal.Header closeButton>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='12'>
                                <h2>Add Escalation Directory</h2>
                            </Col>

                        </Row>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg='3'>Team Name <span style={{ color: 'red' }}>*</span></Col>
                        <Col lg='9'>
                            <input type="text" style={{ width: '600px' }} className="form-control" value={teamName} onChange={e => teamchange(e)}
                                onKeyPress={(event) => {
                                    if (!pattern.test(String.fromCharCode(event.charCode))) {
                                        event.preventDefault();
                                    }
                                }} />
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: '15px' }}>
                        <Col lg='3'>Issue Details</Col>
                        <Col lg='9'>
                            <Editor style={{ height: '80px' }} value={issueDetails} onTextChange={UpdateissueDetails} headerTemplate={header}/>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: '15px' }}>
                        <Col lg='3'>Team DL/Escalation Path <span style={{ color: 'red' }}>*</span></Col>
                        <Col lg='9'>
                            <Editor style={{ height: '80px' }} value={escalationPath} onTextChange={UpdateescalationPath} headerTemplate={header}/>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: '15px' }}>
                        <Col lg='3'>Frequent Contacted Person in IM</Col>
                        <Col lg='9'>
                            <input type="text" style={{ width: '600px' }} required className="form-control" value={contactPerson} onChange={e => contactPersonchange(e)}
                                onKeyPress={(event) => {
                                    if (!pattern.test(String.fromCharCode(event.charCode))) {
                                        event.preventDefault();
                                    }
                                }} />
                        </Col>
                       </Row>
                    <Row style={{ paddingTop: '15px' }}>
                        <Col lg='3'>
                            <div className="issuedetailsstyle">Remarks</div>
                        </Col>
                        <Col lg='9'>
                            <Editor style={{ height: '80px' }} value={remarks} onTextChange={Updateremarks} headerTemplate={header}/>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='3'></Col>
                            <Col lg='6'>

                                {
                                    (isLoading) ?
                                        <div style={{ 'textAlign': "center" }}>
                                            <img src={bxloader} style={{ width: '80px', height: '40px' }} />
                                        </div>
                                        :
                                        <div>
                                        </div>

                                }
                            </Col>
                            <Col lg='3'>
                                {(isLoading) ?
                                    <div></div> :
                                    <div style={{ float: 'right' }}>
                                        <Button className='escalationsaveModelbutton' onClick={escalationDirectoryRowSave} disabled={Disablesavebutton}>Save</Button>&emsp;
                                        <Button className='escalationsaveModelbutton' onClick={escalationDirectoryAddRowCancel}>Close</Button>
                                    </div>
                                }
                            </Col>
                        </Row>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>

    );
};


const mapStateToProps = (state: IApplicationState) => ({
    ...state.EscalationDirectory,
    isAuthenticated: state.authUser.isAuthenticated,
    username: state.authUser.login

})


export default connect(mapStateToProps, actionCreators)(EscalationDirectory);
