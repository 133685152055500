import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IUserScenarioCompletionState } from './types';

const initialState = Object.freeze<IUserScenarioCompletionState>({
    isLoading: false,
    scenarioCompletionData: [],
    selectedScenario: null,
    endDate: null,
    maxDate: new Date(),
    userScenario: null,
    scenarioDescription: "",
    scenario: "",
    tableHeader: [],
    originalHeader: [],
    countVal: 0
});

export const reducer = (
    state: IUserScenarioCompletionState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {
        case ActionType.SET_USERSCENARIODATE:
            return {
                ...state,
                endDate: action.endDate,
                maxDate: action.maxDate
            };
        case ActionType.USERSCENARIO_ENDDATE_CHANGE:
            return {
                ...state,
                endDate: action.endDate
            };
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false
            };
        case ActionType.USERSCENARIO_VALUE_CHANGE:
            return {
                ...state,
                selectedScenario: action.selectedScenario
            };    
        case ActionType.GET_USERSCENARIOCOMPLETION_REQUEST:
            return {
                ...state,
                isLoading: true,
                scenarioCompletionData: []
            };
        case ActionType.GET_USERSCENARIOCOMPLETION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                originalHeader: action.originalHeader,
                tableHeader: action.tableHeader,
                scenario: action.scenario,
                scenarioDescription: action.scenarioDescription,
                scenarioCompletionData: action.scenarioCompletionData,
                countVal: action.countVal
            };
        case ActionType.GET_USERSCENARIOCOMPLETION_FAIL:
            return {
                ...state,
                isLoading: false,
                scenarioCompletionData: [],
                countVal: 0
            };
        case ActionType.GET_USERSCENARIO_SUCCESS:
            return {
                ...state,
                userScenario: action.userScenario,
                selectedScenario: action.selectedScenario,
                scenarioDescription: action.scenarioDescription
            };
        case ActionType.GET_USERSCENARIO_FAIL:
            return {
                ...state,
                userScenario: null,
                selectedScenario: null,
                scenarioDescription: "",
                isLoading: false
            };
        case '@@router/LOCATION_CHANGE': {
            // On Page Load setting Default values in UserScenariocompletion Page
            var url = window.location.pathname;
            if (url.indexOf("UserScenarioCompletion") !== -1) {
                return {
                    ...state,
                    endDate: null,
                    countVal: 0,
                    selectedScenario: null,
                    scenarioCompletionData: []
                }
            }
        }
        case ActionType.RESET_STATE:
            return initialState;

        default:
            return state;
    }
};
