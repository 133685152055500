import React from 'react';
import { ReduxAction } from '../../../store';
import {  NavLink } from 'reactstrap';
import { FontAwesomeIconMemo } from '../../../components';
import { IServiceNavInfo } from '../../../store/service-health';
import { isArrayWithLength } from '../../../utils';
import {
    Card, Row, Col
} from 'reactstrap';

type ServiceNavigatorProps = {
    previousService: IServiceNavInfo;
    currentService: IServiceNavInfo;
    nextService: IServiceNavInfo;

    readonly onPreClick: () => ReduxAction;
    readonly onNextClick: () => ReduxAction;
};

const ServiceNavigator = React.memo<ServiceNavigatorProps>(({
    previousService,
    currentService,
    nextService,
    onPreClick,
    onNextClick
}) => {
  return(
      <div>
        <header id='header'>
            <nav>
                <div className='content'>
                   
                      {(isArrayWithLength(currentService)) ?
                          <div>
                              <Card>
                                  <Row> 
                                        <Col lg='4'>
                                          {
                                            (previousService[0].serviceName !== '') ?
                                                  <NavLink onClick={onNextClick} style={{ fontSize: 'medium', fontWeight: 'bold', color: '#0080D5', opacity: '0.7', float: 'left' }} to={'/ServiceHealth?serviceid=' + previousService[0].serviceId}>
                                                      <FontAwesomeIconMemo icon={'angle-left'} style={{ fontSize: '20px', fontWeight: 'bold', color: '#0080D5', verticalAlign: 'middle' }} />&nbsp;&nbsp;{previousService[0].serviceName}</NavLink>
                                                : <React.Fragment></React.Fragment>
                                          }                                          
                                        </Col>
                                      <Col lg='4'>
                                          <NavLink style={{ cursor: 'default', fontSize: 'large', fontWeight: 'bold', color: '#000000', opacity: '0.7',textAlign: 'center' }}>{currentService[0].serviceName}</NavLink>                              
                                        </Col>
                                        <Col lg='4'>
                                            {
                                                (nextService[0].serviceName !== '') ?
                                                  <NavLink onClick={onPreClick} style={{ fontSize: 'medium', fontWeight: 'bold', color: '#0080D5', opacity: '0.7',float: 'right' }} to={'/ServiceHealth?serviceid=' + nextService[0].serviceId}>{nextService[0].serviceName}&nbsp;&nbsp;
                                                        <FontAwesomeIconMemo icon={'angle-right'} style={{ fontSize: '20px', fontWeight: 'bold', color: '#0080D5', verticalAlign: 'middle' }} /></NavLink>
                                                    : <React.Fragment></React.Fragment>
                                            }
                                        </Col>
                                    </Row>
                              </Card>
                              
                          </div>
                          : <div></div>}
                </div>
            </nav>
        </header>
    </div>
    )
});

ServiceNavigator.displayName = 'ServiceNavigator';
export default ServiceNavigator;