import { CommonAPI, ReachabilityMetricsAPI, WeeklyAPI } from '../../api';
import { IAppThunkAction, ReduxAction } from '../';
import { ActionType, IPropMsodsGridChart, IPropReachabilityError, IYear } from './types';
import { isNullOrUndefined, renderToastifyMsg } from '../../utils';
import moment from 'moment';
import { toast } from "react-toastify";
import { IPropCalender } from '../home-tiles';

export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
            dispatch({
                LoginuserId: LoginuserId,
                type: ActionType.LOGIN_USERNAME_REACHABILITY
            });

            //------------------------------ This Will be temporary. we will remove once the survey is all over -----------------------------
            ReachabilityMetricsAPI.IsUserWantToRetainPage(Pagename, LoginuserId)
                .then((cnt: number) => {
                    if (cnt > 0) {
                        dispatch({
                            checked: true,
                            type: ActionType.IS_RETAIN_PAGE
                        });
                    }
                }).catch(error => {
                    dispatch({
                        type: ActionType.SET_LOADING_FALSE
                    });
                });
        }
    },
    GetReachabilityMetricsSelectedDate: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        const pMonday = getPreviousMonday();
        const date = new Date(pMonday.getFullYear(), pMonday.getMonth(), pMonday.getDate() - 2);
        dispatch({
            selectedDate: date,
            type: ActionType.SET_REACHABILITYMETRICS_SELECTEDDATE
        });
    },
    onselectedDateChange: (selectedDate: Date): ReduxAction => ({
        selectedDate: selectedDate,
        type: ActionType.SET_REACHABILITYMETRICS_SELECTEDDATE
    }),
    UpdateSearchText: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var search = e.target.value;
        dispatch({
            searchText: search,
            type: ActionType.CHANGE_SEARCHTEXT_REACHABILITY
        });
    },

    handleRetainPageOnCheck: (isRetain): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        if (!isNullOrUndefined(getState().authUser)) {
            let userId = getState().authUser.login;
            userId = userId.substr(0, userId.indexOf('@'));
            ReachabilityMetricsAPI.UpdateRetainPage(isRetain, 'ReachabilityMetrics.tsx_v2', userId)
                .then((cnt: number) => {
                    if (cnt > 0) {
                        dispatch({
                            checked: isRetain,
                            type: ActionType.CHECK_SAVE_RETAIN_PAGE
                        });
                        toast.success(renderToastifyMsg("Retain page updated successfully!",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
                    }
                }).catch(error => {
                    dispatch({
                        type: ActionType.SET_LOADING_FALSE
                    });
                });
        }
        else {
            dispatch({
                checked: isRetain,
                type: ActionType.CHECK_SAVE_RETAIN_PAGE
            });
        }
    },

    filterReachabilityData_Red: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        // To filter red color metrics data
        var checkedFilterRedDailyData = getState().ReachabilityMetrics.checkedFilterRedDailyData;
        var reachabilityData = getState().ReachabilityMetrics.reachabilityData;
        checkedFilterRedDailyData = !checkedFilterRedDailyData;
        if (checkedFilterRedDailyData) {
            var jsonRedData = [];
            reachabilityData.map((obj: IPropMsodsGridChart) => {
                if (obj.isMetricRed === true) {
                    jsonRedData.push(obj);
                }
            });
            
            var cnt = 0;
            var tempReachabilityData = [];
            ReachabilityMetricsAPI.GetFilteredRedDataUi(jsonRedData)
                .then((reachabilityRawData: IPropMsodsGridChart[]) => {
                    // Adding one column for index
                    reachabilityRawData.map((obj: IPropMsodsGridChart) => {
                        cnt += 1;
                        obj.rowIndex = cnt - 1;
                    });

                    reachabilityRawData.map((obj: IPropMsodsGridChart) => {
                        tempReachabilityData.push(obj);
                    });

                    dispatch({
                        reachabilityData: tempReachabilityData,
                        checkedFilterRedDailyData: checkedFilterRedDailyData,
                        type: ActionType.FILTER_REACHABILITYDATA_RED
                    });
                });
        } else {
            
            var weekfilter = getState().ReachabilityMetrics.weekfilter;
            var environmentkey = getState().ReachabilityMetrics.environmentkey;
            var FilterreachabilityData = getState().ReachabilityMetrics.FilterreachabilityData;

            var selectedFilter = '';
            var val = '';
            var weekvalue = '';
            if (weekfilter.week1 !== '' || weekfilter.week1selected !== 0) {
                weekvalue = "week1";
                val = weekfilter.week1; 
                selectedFilter = weekfilter.week1selected;
                val = (val === undefined) ? '' : val;
            }
            else if (weekfilter.week2 !== '' || weekfilter.week2selected !== 0) {
                weekvalue = "week2";
                val = weekfilter.week2;
                selectedFilter = weekfilter.week2selected;
                val = (val === undefined) ? '' : val;
            }
            else if (weekfilter.week3 !== '' || weekfilter.week3selected !== 0) {
                weekvalue = "week3";
                val = weekfilter.week3;
                val = (val === undefined) ? '' : val;
                selectedFilter = weekfilter.week3selected;
            }
            else if (weekfilter.week4 !== '' || weekfilter.week4selected !== 0) {
                weekvalue = "week4";
                val = weekfilter.week4;
                selectedFilter = weekfilter.week4selected;
                val = (val === undefined) ? '' : val;
            }
            else if (weekfilter.week5 !== '' || weekfilter.week5selected !== 0) {
                weekvalue = "week5";
                val = weekfilter.week5;
                selectedFilter = weekfilter.week5selected;
                val = (val === undefined) ? '' : val;
            }
            var tempData = [];
            FilterreachabilityData.map((r: IPropMsodsGridChart) => {
                if (r.environment === environmentkey) {
                    tempData.push(r);
                }
            });

            if (weekvalue === "") {
                dispatch({
                    checkedFilterRedDailyData: checkedFilterRedDailyData,
                    reachabilityData: tempData,
                    type: ActionType.FILTER_REACHABILITYDATA_RED
                });
            }
            else {
                reachabilityData = [];
                FilterreachabilityData.map((r: IPropMsodsGridChart) => {
                    var sorttext = '';
                    if (r.environment === environmentkey) {
                        if (weekvalue === 'week1') {
                            sorttext = r.week1Sort;
                        }
                        else if (weekvalue === 'week2') {
                            sorttext = r.week2Sort;
                        }
                        else if (weekvalue === 'week3') {
                            sorttext = r.week3Sort;
                        }
                        else if (weekvalue === 'week4') {
                            sorttext = r.week4Sort;
                        }
                        else if (weekvalue === 'week5') {
                            sorttext = r.week5Sort;
                        }
                        if (val === '') {
                            reachabilityData.push(r);
                        }
                        else if (parseInt(selectedFilter) === 0) {
                            reachabilityData.push(r);
                        }
                        else if (parseInt(selectedFilter) === 1) {
                            if (parseFloat(sorttext) < parseFloat(val)) {
                                reachabilityData.push(r);
                            }
                        }
                        else if (parseInt(selectedFilter) === 2) {
                            if (parseFloat(sorttext) > parseFloat(val)) {
                                reachabilityData.push(r);
                            }
                        }
                        else if (parseInt(selectedFilter) === 3) {
                            if (parseFloat(sorttext) >= parseFloat(val)) {
                                reachabilityData.push(r);
                            }
                        }
                        else if (parseInt(selectedFilter) === 4) {
                            if (parseFloat(sorttext) <= parseFloat(val)) {
                                reachabilityData.push(r);
                            }

                        }
                    }
                });
                dispatch({
                    checkedFilterRedDailyData: checkedFilterRedDailyData,
                    reachabilityData: reachabilityData,
                    type: ActionType.FILTER_REACHABILITYDATA_RED
                });
            }
        }
    },
    onCheckHistory: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var checkHistory = getState().ReachabilityMetrics.checkHistory;
        var ShowHistory1 = getState().ReachabilityMetrics.ShowHistory1;
        checkHistory = !checkHistory;
        ShowHistory1 = !ShowHistory1;
        dispatch({
            checkHistory: checkHistory,
            ShowHistory1: ShowHistory1,
            type: ActionType.CHECK_HISTORY_REACHABILITY
        });
    },
    GetReachabilityData: (Callcount): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.GET_REACHABILITYDATA_REQUEST
        });
        let count = 0;
        if (Callcount !== 3) {
            count = 3;
        }
        var serviceId = getState().ReachabilityMetrics.serviceId;
        var environmentkey = getState().ReachabilityMetrics.environmentkey;
        var selectedDate = getState().ReachabilityMetrics.selectedDate;
        selectedDate = moment(selectedDate).format('MM/DD/YYYY');
        var reachabilityData = [];
        var FilterreachabilityData = [];
        var reachabilityDataTemp = [];
        var reachabilityErrorData = [];
        var reachabilityErrorDataTemp = [];
        var tempToConvert = '';

        if (Callcount === 1 || Callcount === 3) {
            ReachabilityMetricsAPI.GetCalendarData()
                .then((calendarlsit: IYear[]) => {
                    const weekDetailsData = helper_Get_Week_Values_Grid(calendarlsit, selectedDate);
                    count = count + 1;
                    dispatch({
                        count: count,
                        weekDetailsData,
                        type: ActionType.WEEKLY_DETAILS_DATA_RECEIVE
                    });

                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.WEEKLY_DETAILS_DATA_RECEIVE_FAIL
                    });
                });
        }
        if (Callcount === 2 || Callcount === 3) {
            ReachabilityMetricsAPI.GetReachabilityData(selectedDate)
                .then((reachabilityRawData: IPropMsodsGridChart[]) => {
                    var cnt = 0;
                    // Adding one column for index
                    reachabilityRawData.map((obj: IPropMsodsGridChart) => {
                        cnt += 1;
                        obj.rowIndex = cnt - 1;
                    });

                    if (serviceId !== null) {
                        reachabilityRawData.map((obj: IPropMsodsGridChart) => {
                            if (parseInt(obj.parentReachabilityServiceId) === serviceId) {
                                reachabilityDataTemp.push(obj);
                            }
                        });
                    }
                    else {
                        reachabilityDataTemp = reachabilityRawData;
                    }

                    reachabilityDataTemp.map((row: IPropMsodsGridChart) => {
                        tempToConvert = row.week1;
                        row.week1Sort = (tempToConvert !== '') ? parseFloat(tempToConvert.substring(0, (tempToConvert.length - 1))) : 0;

                        tempToConvert = row.week2;
                        row.week2Sort = (tempToConvert !== '') ? parseFloat(tempToConvert.substring(0, (tempToConvert.length - 1))) : 0;

                        tempToConvert = row.week3;
                        row.week3Sort = (tempToConvert !== '') ? parseFloat(tempToConvert.substring(0, (tempToConvert.length - 1))) : 0;

                        tempToConvert = row.week4;
                        row.week4Sort = (tempToConvert !== '') ? parseFloat(tempToConvert.substring(0, (tempToConvert.length - 1))) : 0;

                        tempToConvert = row.week5;
                        row.week5Sort = (tempToConvert !== '') ? parseFloat(tempToConvert.substring(0, (tempToConvert.length - 1))) : 0;

                        row.week1Edit = row.week1;
                        row.week2Edit = row.week2;
                        row.week3Edit = row.week3;
                        row.week4Edit = row.week4;
                        row.week5Edit = row.week5;

                        if (row.environment === environmentkey) {
                            reachabilityData.push(row);
                        }
                        FilterreachabilityData.push(row);
                    });
                    count = count + 1;
                    dispatch({
                        count: count,
                        reachabilityData,
                        reachabilityDataTemp,
                        FilterreachabilityData,
                        type: ActionType.GET_REACHABILITYDATA_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_REACHABILITYDATA_FAIL
                    });
                });

        }
        if (Callcount === 3 || Callcount === 4) {
            ReachabilityMetricsAPI.GetReachabilityErrorData(selectedDate)
                .then((reachabilityErrorRawData: IPropReachabilityError[]) => {
                    var cnt = 0;
                    // Adding one column for index
                    reachabilityErrorRawData.map((obj: IPropReachabilityError) => {
                        cnt += 1;
                        obj.rowIndex = cnt - 1;
                    });

                    if (serviceId !== null) {
                        reachabilityErrorRawData.map((obj: IPropReachabilityError) => {
                            if (parseInt(obj.parentReachabilityServiceId) === serviceId) {
                                reachabilityErrorDataTemp.push(obj);
                            }
                        });
                    }
                    else {
                        reachabilityErrorDataTemp = reachabilityErrorRawData;
                    }

                    reachabilityErrorDataTemp.map((errorrow: IPropReachabilityError) => {
                        errorrow.totalErrors = (errorrow.totalErrors === '') ? 0 : parseFloat(errorrow.totalErrors);
                        errorrow.dnsError = (errorrow.dnsError === '') ? 0 : parseFloat(errorrow.dnsError);
                        errorrow.connectError = (errorrow.connectError === '') ? 0 : parseFloat(errorrow.connectError);
                        errorrow.sslError = (errorrow.sslError === '') ? 0 : parseFloat(errorrow.sslError);
                        errorrow.sendError = (errorrow.sendError === '') ? 0 : parseFloat(errorrow.sendError);
                        errorrow.receiveError = (errorrow.receiveError === '') ? 0 : parseFloat(errorrow.receiveError);
                        errorrow.httpError = (errorrow.httpError === '') ? 0 : parseFloat(errorrow.httpError);
                        errorrow.contentError = (errorrow.contentError === '') ? 0 : parseFloat(errorrow.contentError);

                        reachabilityErrorData.push(errorrow);
                    });
                    // Default Sorting by ServiceName column
                    var column = 'serviceName';
                    var sortedReachabilityErrorData = reachabilityErrorData.sort((a, b) => (a[column].toLowerCase() > b[column].toLowerCase()) ? 1 : -1);
                    count = count + 1;
                    dispatch({
                        count: count,
                        reachabilityErrorData: sortedReachabilityErrorData,
                        reachabilityErrorDataTemp,
                        type: ActionType.GET_REACHABILITYERRORDATA_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_REACHABILITYERRORDATA_FAIL
                    });
                });
        }
    },
    sortColumnError: (column): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var Errorcolumn = getState().ReachabilityMetrics.Errorcolumn;
        var sortOrder = getState().ReachabilityMetrics.sortOrder;
        var reachabilityErrorData = getState().ReachabilityMetrics.reachabilityErrorData;
        var reachabilityErrorDataFiltered = [];
        if (Errorcolumn === column) {
            sortOrder = !sortOrder;
        } else {
            Errorcolumn = column;
            sortOrder = false;
        }
        // Logic to sort the array based on selected column and order

        if (Errorcolumn === "serviceName" || Errorcolumn === "environment") {
            reachabilityErrorDataFiltered = (sortOrder === true)
                ? reachabilityErrorData.sort((a, b) => (a[column].toLowerCase() > b[column].toLowerCase()) ? -1 : 1)
                : reachabilityErrorData.sort((a, b) => (a[column].toLowerCase() > b[column].toLowerCase()) ? 1 : -1)
        }
        else {
            reachabilityErrorDataFiltered = (sortOrder === true)
                ? reachabilityErrorData.sort((a, b) => (a[column] > b[column]) ? -1 : 1)
                : reachabilityErrorData.sort((a, b) => (a[column] > b[column]) ? 1 : -1)
        }

        dispatch({
            Errorcolumn,
            sortOrder,
            reachabilityErrorData: reachabilityErrorDataFiltered,
            type: ActionType.SORT_REACHABILITY_ERRORDATA
        });
    },
    sortReachabilityColumn: (column): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var Reachabilitycolumn = getState().ReachabilityMetrics.Reachabilitycolumn;
        var reachabilitysortOrder = getState().ReachabilityMetrics.reachabilitysortOrder;
        var reachabilityData = getState().ReachabilityMetrics.reachabilityData;
        var reachabilityDataFiltered = [];
        if (Reachabilitycolumn === column) {
            reachabilitysortOrder = !reachabilitysortOrder;
        } else {
            Reachabilitycolumn = column;
            reachabilitysortOrder = false;
        }
        // Logic to sort the array based on selected column and order

        if (Reachabilitycolumn === "serviceName" || Reachabilitycolumn === "environment") {
            reachabilityDataFiltered = (reachabilitysortOrder === true)
                ? reachabilityData.sort((a, b) => (a[column].toLowerCase() > b[column].toLowerCase()) ? -1 : 1)
                : reachabilityData.sort((a, b) => (a[column].toLowerCase() > b[column].toLowerCase()) ? 1 : -1)
        }
        else {
            reachabilityDataFiltered = (reachabilitysortOrder === true)
                ? reachabilityData.sort((a, b) => (a[column] > b[column]) ? -1 : 1)
                : reachabilityData.sort((a, b) => (a[column] > b[column]) ? 1 : -1)
        }

        dispatch({
            Reachabilitycolumn,
            reachabilitysortOrder,
            reachabilityData: reachabilityDataFiltered,
            type: ActionType.SORT_REACHABILITY_DATA
        });
    },
    GetDateForwardData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var selectedDate = getState().ReachabilityMetrics.selectedDate;
        if (moment().diff(moment(selectedDate).add(14, 'day'), 'day') < 0) {
            return;
        }
        if (moment().diff(moment(selectedDate).add(7, 'day'), 'day') > 0) {
            selectedDate = moment(selectedDate).add(7, 'day').toDate();
        }
        else {
            return;
        }
        dispatch({
            selectedDate: selectedDate,
            type: ActionType.SET_REACHABILITYMETRICS_SELECTEDDATE
        });

    },
    GetDateBackwardData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var selectedDate = getState().ReachabilityMetrics.selectedDate;
        selectedDate = moment(selectedDate).subtract(7, 'day').toDate();

        dispatch({
            selectedDate: selectedDate,
            type: ActionType.SET_REACHABILITYMETRICS_SELECTEDDATE
        });
    },
    GetConfigValues: (): IAppThunkAction<ReduxAction> => (dispatch, getSate) => {
        WeeklyAPI.GetConfigValues()
            .then((ConfigValues: IPropCalender[]) => {
                dispatch({
                    ConfigValues,
                    type: ActionType.CONFIGVALUES_RECEIVE
                });

            }).catch(error => {
                dispatch({
                    type: ActionType.CONFIGVALUES_RECEIVE_FAIL
                });
            });

    },
    enableEditData: (isEdit, Editvalue, weekid): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var selectedWeekId = getState().ReachabilityMetrics.selectedWeekId;
        var IsEdit = getState().ReachabilityMetrics.IsEdit;
        var reachabilityData = getState().ReachabilityMetrics.reachabilityData;
        var selectedDate = getState().ReachabilityMetrics.selectedDate;
        var ConfigValues = getState().ReachabilityMetrics.ConfigValues;
        selectedWeekId = weekid;
        IsEdit = Editvalue;
        dispatch({
            selectedWeekId: selectedWeekId,
            IsEdit: IsEdit,
            reachabilityData: reachabilityData,
            type: ActionType.ENABLE_EDITDATA_REACHABILITY
        });
        var preMonday = getPreviousMonday();
        var preFriday = new Date(preMonday.getFullYear(), preMonday.getMonth(), preMonday.getDate() - 3);
        const ConfigData_Weeks = ConfigValues[1].configValue;
        const ConfigData_value = ConfigValues[0].configValue;
        preFriday.setDate(preFriday.getDate() - (7 * ConfigData_Weeks));  // To Allow Past Number of Weeks for Edit from Selected Dates
        var date = new Date(selectedDate);
        if (date < preFriday) {
            toast.error(renderToastifyMsg('History Data Can not be Edited.',
                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 });
            return false;
        }

        var currentDate = new Date();  // Current Date
        var targetDate = new Date(selectedDate);
        targetDate.setDate(targetDate.getDate() + 12); // Thursday of Current Week
        targetDate.setMinutes(targetDate.getMinutes() + (10 * 60)); // No Edit Before 10 AM on Thursday
        var diff = 0;
        if (targetDate > currentDate) {
            diff = Math.abs(targetDate.getTime() - currentDate.getTime()) / 3600000;
        }

        if (diff < parseInt(ConfigData_value) && diff !== 0) {
            toast.error(renderToastifyMsg("Data Can not be edited with in " + parseInt(ConfigData_value) + " hrs of Thursday Live Site Meeting.",
                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 });
            return false;
        }

        var count1;
        reachabilityData.map((r: IPropMsodsGridChart, count1) => {
            var val = '';
            if (Editvalue === 'week1') {
                val = reachabilityData[count1].week1;
            }
            else if (Editvalue === 'week2') {
                val = reachabilityData[count1].week2;
            }
            else if (Editvalue === 'week3') {
                val = reachabilityData[count1].week3;
            }
            else if (Editvalue === 'week4') {
                val = reachabilityData[count1].week4;
            }
            else if (Editvalue === 'week5') {
                val = reachabilityData[count1].week5;
            }
            if (/^\d*\/\d*\%$/.test(val)) {
                if (val !== "") {
                    var nums : any = val.split("/");
                    var value : any = 0;
                    if (nums[1].substring(nums[1].length - 1) === "%") {
                        nums[1] = nums[1].substring(0, nums[1].length - 1);
                        value = (nums[0] * nums[1]) / 100;
                    } else {
                        value = nums[1];
                    }
                    if (Editvalue === 'week1') {
                        reachabilityData[count1].week1 = (nums[0].trim() + "/" + Math.ceil(value)).trim();
                    }
                    else if (Editvalue === 'week2') {
                        reachabilityData[count1].week2 = (nums[0].trim() + "/" + Math.ceil(value)).trim();
                    }
                    else if (Editvalue === 'week3') {
                        reachabilityData[count1].week3 = (nums[0].trim() + "/" + Math.ceil(value)).trim();
                    }
                    else if (Editvalue === 'week4') {
                        reachabilityData[count1].week4 = (nums[0].trim() + "/" + Math.ceil(value)).trim();
                    }
                    else if (Editvalue === 'week5') {
                        reachabilityData[count1].week5 = (nums[0].trim() + "/" + Math.ceil(value)).trim();
                    }
                }
            }
        });

        dispatch({
            selectedWeekId: selectedWeekId,
            IsEdit: IsEdit,
            reachabilityData: reachabilityData,
            type: ActionType.ENABLE_EDITDATA_REACHABILITY
        });

    },
    CancelEdit: (value): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var edit = 1;
        var flag = 0;
        var reachabilityData = getState().ReachabilityMetrics.reachabilityData;
        var IsEdit = getState().ReachabilityMetrics.IsEdit;
        var Updated = getState().ReachabilityMetrics.Updated;
        reachabilityData.map((r: IPropMsodsGridChart, count1) => {
            var val = '';
            var newval = '';
            if (IsEdit === 'week1') {
                val = reachabilityData[count1].week1;
                newval = reachabilityData[count1].week1Edit;
            }
            else if (IsEdit === 'week2') {
                val = reachabilityData[count1].week2;
                newval = reachabilityData[count1].week2Edit;
            }
            else if (IsEdit === 'week3') {
                val = reachabilityData[count1].week3;
                newval = reachabilityData[count1].week3Edit;
            }
            else if (IsEdit === 'week4') {
                val = reachabilityData[count1].week4;
                newval = reachabilityData[count1].week4Edit;
            }
            else if (IsEdit === 'week5') {
                val = reachabilityData[count1].week5;
                newval = reachabilityData[count1].week5Edit;
            }
            if (newval !== val) {
                flag = 1;
            }
            else if (reachabilityData[count1].comments === '' || reachabilityData[count1].comments === undefined) {
                flag = 1;
            }
        });

        if (flag === 1) {
            if (window.confirm("Do you want to cancel without saving the changes?")) {
                if (Updated) {
                    CallGetReachabilityData(dispatch);
                }

                reachabilityData.map((r: IPropMsodsGridChart, count1) => {
                    var val = '';
                    var newval = '';
                    if (IsEdit === 'week1') {
                        val = reachabilityData[count1].week1;
                        newval = reachabilityData[count1].week1Edit;
                    }
                    else if (IsEdit === 'week2') {
                        val = reachabilityData[count1].week2;
                        newval = reachabilityData[count1].week2Edit;
                    }
                    else if (IsEdit === 'week3') {
                        val = reachabilityData[count1].week3;
                        newval = reachabilityData[count1].week3Edit;
                    }
                    else if (IsEdit === 'week4') {
                        val = reachabilityData[count1].week4;
                        newval = reachabilityData[count1].week4Edit;
                    }
                    else if (IsEdit === 'week5') {
                        val = reachabilityData[count1].week5;
                        newval = reachabilityData[count1].week5Edit;
                    }
                    if ((newval !== val) || (reachabilityData[count1].comments !== '' && reachabilityData[count1].comments !== undefined)) {
                        reachabilityData[count1].comments = "";
                        document.getElementById("txt" + value + reachabilityData[count1].rowIndex).style.borderColor = "";
                        document.getElementById("txtComments" + reachabilityData[count1].rowIndex).style.borderColor = "";
                    }

                    if (IsEdit === 'week1') {
                        reachabilityData[count1].week1Edit = reachabilityData[count1].week1;
                    }
                    else if (IsEdit === 'week2') {
                        reachabilityData[count1].week2Edit = reachabilityData[count1].week2;
                    }
                    else if (IsEdit === 'week3') {
                        reachabilityData[count1].week3Edit = reachabilityData[count1].week3;
                    }
                    else if (IsEdit === 'week4') {
                        reachabilityData[count1].week4Edit = reachabilityData[count1].week4;
                    }
                    else if (IsEdit === 'week5') {
                       reachabilityData[count1].week5Edit = reachabilityData[count1].week5;
                    }
                });

            } else {
                edit = 0;
            }
        } else if (Updated === true) {

            CallGetReachabilityData(dispatch);
            reachabilityData.map((r: IPropMsodsGridChart, count1) => {
                reachabilityData[count1].week5Edit = reachabilityData[count1].week5;
                document.getElementById("txt" + value + reachabilityData[count1].rowIndex).style.borderColor = "";
                reachabilityData[count1].comments = "";
                document.getElementById("txtComments" + reachabilityData[count1].rowIndex).style.borderColor = "";
            });
        }
        if (edit === 1) {
            IsEdit = "";
        }
        Updated = false;

        dispatch({
            Updated: Updated,
            IsEdit: IsEdit,
            reachabilityData: reachabilityData,
            type: ActionType.CANCEL_EDITDATA_REACHABILITY
        });
    },
    updateComments: (e, index): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var reachabilityData = getState().ReachabilityMetrics.reachabilityData;
        const stateCopy = reachabilityData;
        const group: any = [];
        for (var i = 0; i < stateCopy.length; i++) {
            if (i === index) {
                    reachabilityData[i].comments = e.target.value;
            }
            group.push(reachabilityData[i]);
        }

        dispatch({
            reachabilityData: group,
            type: ActionType.INPUT_VALUE_CHANGED_REACHABILITY
        });
    },
    inputValueChanged: (e, index): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var reachabilityData = getState().ReachabilityMetrics.reachabilityData;
        var IsEdit = getState().ReachabilityMetrics.IsEdit;
        const stateCopy = reachabilityData;
        const group: any = [];
        var value = e.target.value;
        for (var i = 0; i < stateCopy.length; i++) {
            if (i === index) {
                if (IsEdit === 'week1') {
                    if (value.match(/^\d*(\.\d*)?[%]?$/)) {
                        reachabilityData[i].week1Edit = value;
                    }
                }
                else if (IsEdit === 'week2') {
                    if (value.match(/^\d*(\.\d*)?[%]?$/)) {
                        reachabilityData[i].week2Edit = value;
                    }
                }
                else if (IsEdit === 'week3') {
                    if (value.match(/^\d*(\.\d*)?[%]?$/)) {
                        reachabilityData[i].week3Edit = value;
                    }
                }
                else if (IsEdit === 'week4') {
                    if (value.match(/^\d*(\.\d*)?[%]?$/)) {
                        reachabilityData[i].week4Edit = value;
                    }
                }
                else if (IsEdit === 'week5') {
                    if (value.match(/^\d*(\.\d*)?[%]?$/)) {
                        reachabilityData[i].week5Edit = value;
                    }
                }
            }
            group.push(reachabilityData[i]);
        }

        dispatch({
            reachabilityData: group,
            type: ActionType.INPUT_VALUE_CHANGED_REACHABILITY
        });
    },
    filterweekdata: (e, weekvalue): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var selectedFilter = '';
        var val = '';
        var reachabilityData = getState().ReachabilityMetrics.reachabilityData;
        var FilterreachabilityData = getState().ReachabilityMetrics.FilterreachabilityData;
        var environmentkey = getState().ReachabilityMetrics.environmentkey;
        var weekfilter = getState().ReachabilityMetrics.weekfilter;
        reachabilityData = [];
        const { name, value } = e.target;

        if (weekvalue === 'week1') {
            if (name === "dropdown") {
                weekfilter.week1selected = value;
            }
            else if (name === "inputnumber") {
                if (value.match(/^[-+]?\d*(\.\d*)?$/)) {
                    weekfilter.week1 = value;
                }
            }
            weekfilter =  weeksdataFilter(weekfilter.week1, '', '', '', '', weekfilter.week1selected, 0, 0, 0, 0);
            val = weekfilter.week1;
            selectedFilter = weekfilter.week1selected;
            val = (val === undefined) ? '' : val;
        }
        else if (weekvalue === 'week2') {
            if (name === "dropdown") {
                weekfilter.week2selected = value;
            }
            else if (name === "inputnumber") {
                if (value.match(/^[-+]?\d*(\.\d*)?$/)) {
                    weekfilter.week2 = value;
                }
            }
            weekfilter =  weeksdataFilter('', weekfilter.week2, '', '', '', 0, weekfilter.week2selected, 0, 0, 0);
            val = weekfilter.week2;
            selectedFilter = weekfilter.week2selected;
            val = (val === undefined) ? '' : val;
        }
        else if (weekvalue === 'week3') {
            if (name === "dropdown") {
                weekfilter.week3selected = value;
            }
            else if (name === "inputnumber") {
                if (value.match(/^[-+]?\d*(\.\d*)?$/)) {
                    weekfilter.week3 = value;
                }
            }
            weekfilter = weeksdataFilter('', '', weekfilter.week3, '', '', 0, 0, weekfilter.week3selected, 0, 0);
            val = weekfilter.week3;
            val = (val === undefined) ? '' : val;
            selectedFilter = weekfilter.week3selected;
        }
        else if (weekvalue === 'week4') {
            if (name === "dropdown") {
                weekfilter.week4selected = value;
            }
            else if (name === "inputnumber") {
                if (value.match(/^[-+]?\d*(\.\d*)?$/)) {
                    weekfilter.week4 = value;
                }
            }
            weekfilter = weeksdataFilter('', '', '', weekfilter.week4, '', 0, 0, 0, weekfilter.week4selected, 0);
            val = weekfilter.week4;
            selectedFilter = weekfilter.week4selected;
            val = (val === undefined) ? '' : val;
        }
        else if (weekvalue === 'week5') {
            if (name === "dropdown") {
                weekfilter.week5selected = value;
            }
            else if (name === "inputnumber") {
                if (value.match(/^[-+]?\d*(\.\d*)?$/)) {
                    weekfilter.week5 = value;
                }
            }
            weekfilter = weeksdataFilter('', '', '', '', weekfilter.week5, 0, 0, 0, 0, weekfilter.week5selected);
            val = weekfilter.week5;
            selectedFilter = weekfilter.week5selected;
            val = (val === undefined) ? '' : val;
        }
        FilterreachabilityData.map((r: IPropMsodsGridChart) => {
            var sorttext = '';
            if (r.environment === environmentkey) {
                if (weekvalue === 'week1') {
                    sorttext = r.week1Sort;
                }
                else if (weekvalue === 'week2') {
                    sorttext = r.week2Sort;
                }
                else if (weekvalue === 'week3') {
                    sorttext = r.week3Sort;
                }
                else if (weekvalue === 'week4') {
                    sorttext = r.week4Sort;
                }
                else if (weekvalue === 'week5') {
                    sorttext = r.week5Sort;
                }
                if (val === '') {
                    reachabilityData.push(r);
                }
                else if (parseInt(selectedFilter) === 0) {
                    reachabilityData.push(r);
                }
                else if (parseInt(selectedFilter) === 1) {
                    if (parseFloat(sorttext) < parseFloat(val)) {
                        reachabilityData.push(r);
                    }
                }
                else if (parseInt(selectedFilter) === 2) {
                    if (parseFloat(sorttext) > parseFloat(val)) {
                        reachabilityData.push(r);
                    }
                }
                else if (parseInt(selectedFilter) === 3) {
                    if (parseFloat(sorttext) >= parseFloat(val)) {
                        reachabilityData.push(r);
                    }
                }
                else if (parseInt(selectedFilter) === 4) {
                    if (parseFloat(sorttext) <= parseFloat(val)) {
                        reachabilityData.push(r);
                    }

                }
            }
        });

        dispatch({
            reachabilityData: reachabilityData,
            weekfilter: weekfilter,
            type: ActionType.FILTER_WEEKDATA_REACHABILITY
        });
    },
    ShowHistory: (count, row, weekid): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var reachabilityData = getState().ReachabilityMetrics.reachabilityData;
        var KPI_History = getState().ReachabilityMetrics.KPI_History;
        if (row.serviceId === 27) {
            var dt : any;
            reachabilityData.map((obj: IPropMsodsGridChart) => {
                if (obj.serviceId === row.serviceId && obj.environment === row.environment) {
                    dt = obj;
                }
            });
            
            if (dt) {
                var item = [];
                dt.history.map((val) => {
                    if (val.weekId === weekid) {
                        item.push(val);
                    }
                });
            }
            KPI_History = dt ? item : null;
        }
        else {
            var temp = [];
            row.history.map((rowObj) => {
                if (rowObj.weekId === weekid) {
                    temp.push(rowObj);
                }
            });
            KPI_History = temp;
        }
        dispatch({
            KPI_History: KPI_History,
            type: ActionType.KPI_HISTORY_REACHABILITY
        });
    },
    AddToHistory: (count): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        var isValidationPass = true;
        var disableSave = getState().ReachabilityMetrics.disableSave;
        var reachabilityData = getState().ReachabilityMetrics.reachabilityData;
        var LoginuserId = getState().ReachabilityMetrics.LoginuserId;
        var searchText = getState().ReachabilityMetrics.searchText;
        var IsEdit = getState().ReachabilityMetrics.IsEdit;
        var serviceId = getState().ReachabilityMetrics.serviceId;
        var selectedWeekId = getState().ReachabilityMetrics.selectedWeekId;
        var Updated = getState().ReachabilityMetrics.Updated;
        var weekfilter = getState().ReachabilityMetrics.weekfilter;
        disableSave = true;
        dispatch({
            disableSave: disableSave,
            type: ActionType.SAVEBUTTON_DISABLE_REACHABILITY
        });
        var jsonData = [];
        var counter = 1;

        const stateCopy = reachabilityData;
        const group: any = [];
        for (var count1 = 0; count1 < stateCopy.length; count1++) {
            isValidationPass = true;
            var newval = '';
            var cmnts = reachabilityData[count1].comments;
            var oldval = reachabilityData[count1].week5;
            if (searchText === '' || searchText === undefined) {
              document.getElementById("txt" + IsEdit + reachabilityData[count1].rowIndex).style.borderColor = "";
              document.getElementById("txtComments" + reachabilityData[count1].rowIndex).style.borderColor = "";
            }

            var val = '';
            if (IsEdit === 'week1') {
                val = reachabilityData[count1].week1;
                newval = reachabilityData[count1].week1Edit;
            }
            else if (IsEdit === 'week2') {
                val = reachabilityData[count1].week2;
                newval = reachabilityData[count1].week2Edit;
            }
            else if (IsEdit === 'week3') {
                val = reachabilityData[count1].week3;
                newval = reachabilityData[count1].week3Edit;
            }
            else if (IsEdit === 'week4') {
                val = reachabilityData[count1].week4;
                newval = reachabilityData[count1].week4Edit;
            }
            else if (IsEdit === 'week5') {
                val = reachabilityData[count1].week5;
                newval = reachabilityData[count1].week5Edit;
            }
            // If value changed
            if (newval !== val || (cmnts !== '' && cmnts !== undefined)) {
                //split data if % exists on the given value
                var splitVal = newval.split("%");
                if (newval === '' || newval === undefined) {
                    document.getElementById("txt" + IsEdit + reachabilityData[count1].rowIndex).style.borderColor = "red";
                    toast.error(renderToastifyMsg('Values should not be empty. ',
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 });
                    disableSave = false;
                    dispatch({
                        disableSave: disableSave,
                        type: ActionType.SAVEBUTTON_DISABLE_REACHABILITY
                    });
                    return false;
                }
                
                //Below length conditon to validate NO entry after % symbol
                if (splitVal.length >= 1 && (splitVal[1] !== '' && splitVal[1] !== undefined)) {
                    document.getElementById("txt" + IsEdit + reachabilityData[count1].rowIndex).style.borderColor = "red";
                    toast.error(renderToastifyMsg('Please provide valid data ',
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 });
                    disableSave = false;
                    dispatch({
                        disableSave: disableSave,
                        type: ActionType.SAVEBUTTON_DISABLE_REACHABILITY
                    });
                    return false;
                }
                else if (splitVal.length >= 1 && isNaN(parseInt(splitVal[0]))) {
                    document.getElementById("txt" + IsEdit + reachabilityData[count1].rowIndex).style.borderColor = "red";
                    toast.error(renderToastifyMsg('Please provide valid data ',
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 });
                    disableSave = false;
                    dispatch({
                        disableSave: disableSave,
                        type: ActionType.SAVEBUTTON_DISABLE_REACHABILITY
                    });
                    return false;
                }

                if (parseFloat(newval) < 1) {
                    document.getElementById("txt" + IsEdit + reachabilityData[count1].rowIndex).style.borderColor = "red";
                    toast.error(renderToastifyMsg('Values should be greater than "1" ',
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 });
                    disableSave = false;
                    dispatch({
                        disableSave: disableSave,
                        type: ActionType.SAVEBUTTON_DISABLE_REACHABILITY
                    });
                    return false;
                }

                if (parseFloat(newval) > 100) {
                    document.getElementById("txt" + IsEdit + reachabilityData[count1].rowIndex).style.borderColor = "red";
                    toast.error(renderToastifyMsg('Values should not exceed 100 ',
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 });
                    disableSave = false;
                    dispatch({
                        disableSave: disableSave,
                        type: ActionType.SAVEBUTTON_DISABLE_REACHABILITY
                    });
                    return false;
                }

                if (newval !== '' && newval !== undefined && (cmnts === '' || cmnts === undefined)) {
                    document.getElementById("txtComments" + reachabilityData[count1].rowIndex).style.borderColor = "red";
                    toast.error(renderToastifyMsg('Comments should not be empty. ',
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 });
                    disableSave = false;
                    dispatch({
                        disableSave: disableSave,
                        type: ActionType.SAVEBUTTON_DISABLE_REACHABILITY
                    });
                    return false;
                }

                // If Comment is blank
                if ((cmnts === "") && (reachabilityData[count1].entrySource && (serviceId === 3 || serviceId === 17 || serviceId === 18))) {
                    isValidationPass = false;
                    toast.error(renderToastifyMsg('Not Updating!! Please provide valid data or comments',
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 });
                      document.getElementById("txt" + IsEdit + count1).style.borderColor = "red";
                      document.getElementById("txtComments" + count1).style.borderColor = "red";
                    disableSave = false;
                    dispatch({
                        disableSave: disableSave,
                        type: ActionType.SAVEBUTTON_DISABLE_REACHABILITY
                    });
                }
                // If validation passed
                if (isValidationPass) {
                    // If Metric have a Regular expression Validation
                    if (reachabilityData[count1].validationExpr) {
                        var re = new RegExp(reachabilityData[count1].validationExpr, "g");
                        if (newval.search(re) !== 0 && newval !== "") {
                            isValidationPass = false;
                            document.getElementById("txtWeek5" + reachabilityData[count1].rowIndex).style.borderColor = "red";
                            toast.error(renderToastifyMsg('Not Updating!! Value is not in correct format.',
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 });
                            disableSave = false;
                            dispatch({
                                disableSave: disableSave,
                                type: ActionType.SAVEBUTTON_DISABLE_REACHABILITY
                            });
                        }
                    }

                    if (isValidationPass) {
                        jsonData.push({
                            serviceId: reachabilityData[count1].serviceId,
                            weekId: selectedWeekId,
                            metricGroupName: reachabilityData[count1].groupName,
                            metricId: reachabilityData[count1].ml03Id,
                            kpiValue: newval,
                            oldVal: oldval,
                            comments: cmnts,
                            UOM: reachabilityData[count1].uom,
                            updatedBy: LoginuserId
                        });

                        counter++;
                    }
                }
            }

            if (!isValidationPass) {
                return false;
            }

        }

        if (counter === 1) {
            toast.error(renderToastifyMsg('No changes observed to Update. If you wish to cancel the update operation please click on the Cancel icon. ',
                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 });
            disableSave = false;
            dispatch({
                disableSave: disableSave,
                type: ActionType.SAVEBUTTON_DISABLE_REACHABILITY
            });
            return false;
        }

        ReachabilityMetricsAPI.AddKPIHistory(jsonData)
            .then((responceData: string) => {
                if (responceData !== '-1') {
                    toast.success(renderToastifyMsg('Data saved successfully',
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
                    Updated = true;
                    IsEdit = "";
                    disableSave = false;
                    weekfilter = weeksdataFilter('', '', '', '', '', 0, 0, 0, 0, 0);
                    searchText = '';
                    dispatch({
                        disableSave: disableSave,
                        Updated: Updated,
                        IsEdit: IsEdit,
                        weekfilter: weekfilter,
                        searchText: searchText,
                        type: ActionType.SAVE_SUCCESSFULL_REACHABILITY
                    });
                    CallGetReachabilityData(dispatch);
                } else {
                    toast.error(renderToastifyMsg('Data Can not be saved succesfully, please contact to Administrator',
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 });
                }
            });

    },
};

// function to call GetReachabilityData action method
const CallGetReachabilityData = (dispatch) => {
    dispatch(actionCreators.GetReachabilityData(3));
}

function getPreviousMonday() {

    const today = new Date(); // '07/23/2016'   
    if (!today.getDay()) {
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 13);
    }

    return new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 6);
}

function weeksdataFilter(week1, week2, week3, week4, week5, week1selected, week2selected, week3selected, week4selected, week5selected) {
    return {
        'week1': week1, 'week2': week2, 'week3': week3, 'week4': week4, 'week5': week5,
        'week1selected': week1selected, 'week2selected': week2selected, 'week3selected': week3selected, 'week4selected': week4selected, 'week5selected': week5selected
    }
}

const helper_Get_Week_Values_Grid = (calendarlist, selectedDate) => {
    var weekArray = [];
    var selectedWeekId = 0;
    calendarlist.map((calendar, index) => {
        calendar.month.map((month, index) => {
            month.week.map((week, index) => {
                weekArray.push(week);
            });
        });
    });
    var index;
    for (var count = 0; count < weekArray.length; count++) {
        var oldDate = moment(weekArray[count].weekStartDate).format('MM-DD-YYYY');
        var newDate = moment(selectedDate, 'MM-DD-YYYY');// new Date(String($scope.selectedDate));


        // newDate = newDate.toDateString();
        if (newDate.isSame(oldDate)) {
            index = count;
        }
    }

    if (index != undefined) {
        selectedWeekId = weekArray[index].weekId;
    }

    const weeks = {
        week5: weekArray[index].weekDisplayName,
        week5ID: weekArray[index].weekId,
        week4: weekArray[index - 1].weekDisplayName,
        week4ID: weekArray[index - 1].weekId,
        week3: weekArray[index - 2].weekDisplayName,
        week3ID: weekArray[index - 2].weekId,
        week2: weekArray[index - 3].weekDisplayName,
        week2ID: weekArray[index - 3].weekId,
        week1: weekArray[index - 4].weekDisplayName,
        week1ID: weekArray[index - 4].weekId
    };

    const weeksDetails = {
        weekDet5: weekArray[index].weekDetail,
        weekDet4: weekArray[index - 1].weekDetail,
        weekDet3: weekArray[index - 2].weekDetail,
        weekDet2: weekArray[index - 3].weekDetail,
        weekDet1: weekArray[index - 4].weekDetail
    };
    return { 'selectedWeekId': selectedWeekId, 'weeks': weeks, 'weeksDetails': weeksDetails }
}