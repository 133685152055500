import { History } from 'history';
import { IApplicationState } from './index';
import { combineReducers, Reducer } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as ServiceHealthReducer } from './service-health';
import { reducer as HomeTileReducer } from './home-tiles';
import { reducer as FilterServiceHealthReducer } from './filter-service-health';
import { reducer as AuthReducer } from './auth';
import { reducer as AvailabilityReducer } from './availability';
import { reducer as WeeklyIDSHRReducer } from './Weekly-ID-SHR';
import { reducer as ActionItemsReducer } from './actionItems';
import { reducer as AdminSettingsReducer } from './AdminSettings';
import { reducer as PIRLearningsReducer } from './PIRLearnings';
import { reducer as MSERHeatmapPreviewReducer } from './MSERHeatmapPreview';
import { reducer as MSERPresentationViewReducer } from './MSERPresentationView'
import { reducer as HeatmapAdminReducer } from './HeatmapAdmin';
import { reducer as CriticalAdvisoryReducer } from './CriticalAdvisory';
import { reducer as PlatformToolsReducer } from './PlatformTools';
import { reducer as MonthlyAvailbilityReducer } from './MonthlyAvailbility';
import { reducer as WeeklyAvailabilityReducer } from './WeeklyAvailability';
import { reducer as MonthlyAvailbilityQOSReducer } from './MonthlyAvailbilityQOS';
import { reducer as MSERHeatmapTrendReducer } from './MSERHeatmapTrend';
import { reducer as EscalationDirectoryReducer } from './EscalationDirectory';
import { reducer as UserScenarioCompletionReducer } from './UserScenarioCompletion';
import { reducer as AckTrackingTemplateReducer } from './AckTrackingTemplate';
import { reducer as checklistTemplateReducer } from './checklistTemplate';
import { reducer as ChecklistReducer } from './Checklist';
import { reducer as OnCallChecklistReducer } from './OnCallChecklist';
import { reducer as ReachabilityMetricsReducer } from './ReachabilityMetrics';
import { reducer as IdentityWeeklyNewsLetterReducer } from './IdentityWeeklyNewsLetter';
import { reducer as DivisionalOKRsReducer } from './DivisionalOKRs';
import { reducer as IDAcktrackingReducer } from './IDAcktracking';
import { reducer as InternalEscalationReducer } from './InternalEscalation';
import { reducer as CreateAdoHierarchyReducer } from './CreateAdoHierarchy';
import { reducer as ADOTaskCreationReducer } from './ADOTaskCreation';
import { reducer as ConfigurationReducer } from './Configuration';
import { reducer as EmailConfigurationReducer } from './EmailConfiguration';
import { reducer as DCMappingReducer } from './DCMapping';
import { reducer as FutureOnCallReducer } from './FutureOnCall';
import { reducer as PIRRiskToolReducer } from './PIRRiskTool';
import { reducer as WIPSHRReducer } from './WIP-SHR';
import { reducer as WZTNASHRReducer } from './WZTNA-SHR';
import { reducer as SecuritySHRReducer } from './Security-SHR';
import { reducer as DublinSHRReducer } from './Dublin-SHR';
import { reducer as LastWeekIdNewsLetterReducer } from './LastWeekIdNewsLetter';

/**
 * Takes all the individual reducers and creates a single state object by combining them.
 */
export const createRootReducer = (history: History): Reducer<IApplicationState> => (
    combineReducers<IApplicationState>({
        serviceGroup: HomeTileReducer,
        serviceHealth: ServiceHealthReducer,
        filterServiceHealth: FilterServiceHealthReducer,
        authUser: AuthReducer,
        availability: AvailabilityReducer,
        WeeklyIDSHR: WeeklyIDSHRReducer,
        ActionItems: ActionItemsReducer,
        AdminSettings: AdminSettingsReducer,
        PIRLearnings: PIRLearningsReducer,
        MSERHeatmapPreview: MSERHeatmapPreviewReducer,
        MSERPresentationView: MSERPresentationViewReducer,
        HeatmapAdmin: HeatmapAdminReducer,
        CriticalAdvisory: CriticalAdvisoryReducer,
        PlatformTools: PlatformToolsReducer,
        MonthlyAvailbility: MonthlyAvailbilityReducer,
        WeeklyAvailability: WeeklyAvailabilityReducer,
        MonthlyAvailbilityQOS: MonthlyAvailbilityQOSReducer,
        MSERHeatmapTrend: MSERHeatmapTrendReducer,
        EscalationDirectory: EscalationDirectoryReducer,
        AckTrackingTemplate: AckTrackingTemplateReducer,
        UserScenarioCompletion: UserScenarioCompletionReducer,
        checklistTemplate: checklistTemplateReducer,
        Checklist: ChecklistReducer,
        OnCallChecklist: OnCallChecklistReducer,
        ReachabilityMetrics: ReachabilityMetricsReducer,
        IdentityWeeklyNewsLetter: IdentityWeeklyNewsLetterReducer,
        DivisionalOKRs: DivisionalOKRsReducer,
        IDAcktracking: IDAcktrackingReducer,
        InternalEscalation: InternalEscalationReducer,
        CreateAdoHierarchy: CreateAdoHierarchyReducer,
        ADOTaskCreation: ADOTaskCreationReducer,
        EmailConfiguration: EmailConfigurationReducer,
        DCMapping: DCMappingReducer,
        Configuration: ConfigurationReducer,
        router: connectRouter(history),
        FutureOnCall: FutureOnCallReducer,
        PIRRiskTool: PIRRiskToolReducer,
        WIPSHR: WIPSHRReducer,
        WZTNASHR: WZTNASHRReducer,
        SecuritySHR: SecuritySHRReducer,
        DublinSHR: DublinSHRReducer,
        LastWeekIdNewsLetter: LastWeekIdNewsLetterReducer
    })
);