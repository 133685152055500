import { BaseService } from './base.service';
import { IPropAllPirData, IPropCommsOnlyPir, IPropSpecialTopicsWishr, IPropAllPirDataReReview, IPropSupportabilityWishr, IPIRAdminLock, IPropFundamentalsSpotlightWishr, IPropActionItemsReviewWishr, IPropPublicServiceAnnouncementWishr, IPropSuccessCaseStudiesWishr, IPropSuccessStoriesWishr, IPropOutageReviewWishr, IPropAppendixMaterialsWishr } from '../store/Weekly-ID-SHR';
/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class WeeklyIDSHRService extends BaseService {
    private static _serviceGroupService: WeeklyIDSHRService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): WeeklyIDSHRService {
        return (this._serviceGroupService || (this._serviceGroupService = new this('WeeklySHR')));
    }
    public async GetCommsOnlyPirData(Meetingdate: string): Promise<IPropCommsOnlyPir[]> {
        const { data } = await this.$http.get<IPropCommsOnlyPir[]>(`GetCommsOnlyPirData?selectedDate=${Meetingdate}`);
        return data; 
    }
    public async IsPirRereviewUser(loginUser: string): Promise<boolean> {
        const { data } = await this.$http.get<boolean>(`IsPirRereviewUser?loginUser=${loginUser}`);
        return data;
    }
    public async GetWeeklySHRData(Meetingdate: string): Promise<IPropAllPirData[]> {
        const { data } = await this.$http.get<IPropAllPirData[]>(`GetWeeklySHRData?selectedDate=${Meetingdate}`);
        return data;
    }
    public async GetSpecialTopicsWishrData(Meetingdate: string): Promise<IPropSpecialTopicsWishr[]> {
        const { data } = await this.$http.get<IPropSpecialTopicsWishr[]>(`GetSpecialTopicsWishrData?selectedDate=${Meetingdate}`);
        return data;
    }
    public async GetWeeklySHRSupportabilityData(Meetingdate: string): Promise<IPropSupportabilityWishr[]> {
        const { data } = await this.$http.get<IPropSupportabilityWishr[]>(`GetSupportabilityWishrData?selectedDate=${Meetingdate}`);
        return data;
    }
    public async GetAllPirReReviewData(): Promise<IPropAllPirDataReReview[]> {
        const { data } = await this.$http.get<IPropAllPirDataReReview[]>("GetAllPirReReviewData");
        return data;
    }
    public async AddPirData(row: IPropAllPirData): Promise<number> {
        const metric = {
            Presenter: row.presenterEdit === undefined || null ? row.presenter : row.presenterEdit,
            Id: row.id,
            ServiceName: row.serviceName,
            ServicesImpacted: row.servicesImpacted,
            Summary: row.summary,
            Ttd: row.ttd,
            Ttm: row.ttm,
            Ttn: row.ttn,
            Tte: row.tte,
            Pir: row.pirEdit === undefined || null ? row.pir : row.pirEdit,
            IsAzure: row.isAzure,
            KpiDate: row.kpiDate,
            UserName: row.userName,
            OccuredRing: row.occuredRingEdit === undefined || null ? row.occuredRing : row.occuredRingEdit,
            PirRereview: row.pirRereview,
            IsAddedToAgenda: row.isAddedToAgenda,
            IsValidPir: row.isValidPir,
            RootCauseCategory: row.rootCauseCategory,
            IncidentId: row.incidentId,
            Resources: row.resources,           
            SharedLearning: row.sharedLearning,
            DisplayOrder: row.displayOrderEdit === null ? null : parseInt(row.displayOrderEdit.toString())
        };
        if (row.id === 0) {
            const { data } = await this.$http.post<number>("AddPirData", metric);
            return data;
        }
        else {
            const { data } = await this.$http.post<number>(`UpdatePirData?selectedDate=${row.id}`, metric);
            return data
        }
      
    } 
    public async DeletePirData(id: number, user: string): Promise<number> {
        const { data } = await this.$http.get<number>(`DeletePirData?id=${id}&user=${user}`);
        return data;
    }
    public async EditPirReviewData(row: IPropAllPirData): Promise<number> {
        const metric = {
            Id: row.id,
            ServiceName: row.serviceName,
            ServicesImpacted: row.servicesImpacted,
            Summary: row.summary,
            Ttd: row.ttd,
            Ttm: row.ttm,
            Ttn: row.ttn,
            Tte: row.tte,
            Pir: row.pirEdit,
            IsAzure: row.isAzure,
            KpiDate: row.kpiDate,
            UserName: row.userName,
            OccuredRing: row.occuredRingEdit,
            PirRereview: row.pirRereview,
            IsAddedToAgenda: row.isAddedToAgenda,
            IsValidPir: row.isValidPir,
            RootCauseCategory: row.rootCauseCategory,
            IncidentId: row.incidentId,
            Resources: row.resources,
            SharedLearning: row.sharedLearning

        };       
        const { data } = await this.$http.post<number>("EditPirReviewData", metric);
            return data; 
    }
    public async savePIRMeetingDate(id, KPIDate, user): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateDateForPIR?id=${id}&dateKpi=${KPIDate}&user=${user}`);
        return data;
    }
    public async saveCommsPIRMeetingDate(id, KPIDate, user): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateDateForCommsPIR?id=${id}&dateKpi=${KPIDate}&user=${user}`);
        return data;
    }
    public async saveSpecialTopicsMeetingDate(id, KPIDate, userName): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateDateForSpecialTopics?id=${id}&dateKpi=${KPIDate}&UserName=${userName}`);
        return data;
    }
    public async saveSupportabilityMeetingDate(id, KPIDate): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateDateForSupportability?id=${id}&dateKpi=${KPIDate}`);
        return data;
    }
    public async AddCommsOnlyPirData(row: IPropCommsOnlyPir, user: string): Promise<number> {
        const metric = {
            Presenter: row.presenterEdit,
            Id: row.id,
            ServiceName: row.serviceNameEdit,
            Title: row.titleEdit,
            Ttn: row.ttnEdit,
            CommsPirLink: row.commsPirLinkEdit,
            PirNumber: row.pirNumberEdit,
            KpiDate: row.kpiDate,
            DisplayOrder: row.displayOrderEdit === null ? null : parseInt(row.displayOrderEdit.toString()),
            User: user

        };
        if (row.id === 0) {
            const { data } = await this.$http.post<number>("AddCommsOnlyPirData", metric);
            return data;
        }
        else {
            const { data } = await this.$http.post<number>("UpdateCommsOnlyPirData", metric);
            return data
        }

    } 
    public async DeleteCommsOnlyPirData(id: number, user: string): Promise<number> {
        const { data } = await this.$http.get<number>(`DeleteCommsOnlyPirData?id=${id}&user=${user}`);
        return data;
    }
    public async DeletePirRereviewData(id: number): Promise<number> {
        const { data } = await this.$http.get<number>(`DeletePirRereviewData?id=${id}`);
        return data;
    }
    public async UpdatePirReReviewData(row: IPropAllPirDataReReview): Promise<number> {
        const metric = {
            Id: row.id,
            ServiceName: row.serviceName,
            ServicesImpacted: row.servicesImpacted,
            Summary: row.summary,
            Ttd: row.ttd,
            Ttm: row.ttm,
            Ttn: row.ttn,
            Tte: row.tte,
            Pir: row.pirEdit,
            IsAzure: row.isAzure,
            KpiDate: row.kpiDate,
            UserName: row.userName,  
            IncidentId: row.incidentId,
            DueForReReviewOn: row.dueForReReviewOn,
            AddToAgenda: row.addToAgenda
           

        };
        const { data } = await this.$http.post<number>("UpdatePirReReviewData", metric);
        return data;
    }
    public async AddPirReviewDataToAgenda(row: IPropAllPirDataReReview): Promise<number> {
        const metric = {
            Id: row.id,
            ServiceName: row.serviceName,
            ServicesImpacted: row.servicesImpacted,
            Summary: row.summary,
            Ttd: row.ttd,
            Ttm: row.ttm,
            Ttn: row.ttn,
            Tte: row.tte,
            Pir: row.pirEdit,
            IsAzure: row.isAzure,
            KpiDate: row.kpiDate,
            UserName: row.userName,
            IncidentId: row.incidentId,
            DueForReReviewOn: row.dueForReReviewOnEdit,
            AddToAgenda: row.addToAgenda,
            PIRDataID: row.pirDataID


        };
        const { data } = await this.$http.post<number>("AddPirReviewDataToAgenda", metric);
        return data;
    }

    public async AddorupdateSpecialTopicsWishrData(row: IPropSpecialTopicsWishr): Promise<number> {  
        const metric = {
            Id: row.id,
            Presenter: row.presenterEdit === undefined || null ? row.presenter : row.presenterEdit,
            TopicName: row.topicNameEdit === undefined || null ? row.topicName : row.topicNameEdit,
            TimeNeeded: 0,
            PresentationLink: row.presentationLinkEdit === undefined || null ? row.presentationLink : row.presentationLinkEdit,
            IsTopicApproved: row.isTopicApprovedEdit === undefined || null ? row.isTopicApproved : row.isTopicApprovedEdit,            
            KpiDate: row.kpiDate,
            DisplayOrder: row.displayOrderEdit === null ? null : parseInt(row.displayOrderEdit.toString()),
            UserName: row.userName
        };
        if (row.id === 0) {
            const { data } = await this.$http.post<number>("AddSpecialTopicsWishrData", metric);
            return data;
        }
        else {
            const { data } = await this.$http.post<number>("UpdateSpecialTopicsWishrData", metric);
            return data
        }

    } 
    public async DeleteSpecialTopicsWishrData(id: number, userName: string): Promise<number> {
        const { data } = await this.$http.get<number>(`DeleteSpecialTopicsWishrData?id=${id}&userName=${userName}`);
        return data;
    }
    public async AddrecurrenceForSpecialTopics(recNumber, selectedDate, id, userName: string): Promise<number> {
        const { data } = await this.$http.get<number>(`AddrecurrenceForSpecialTopics?selectedDate=${selectedDate}&recNumber=${recNumber}&id=${id}&userName=${userName}`);
        return data;
    }
    public async DeleterecurrenceForSpecialTopics(recNumber: number, selectedDate, id, userName: string): Promise<number> {
        const { data } = await this.$http.get<number>(`DeleterecurrenceForSpecialTopics?selectedDate=${selectedDate}&recNumber=${recNumber}&id=${id}&userName=${userName}`);
        return data;
    }
    public async AddorupdateSupportabilityWishrData(row: IPropSupportabilityWishr): Promise<number> {
        const metric = {
            Id: row.id,
            Presenter: row.presenterEdit,
            TopicName: row.topicNameEdit,
            PresentationLink: row.presentationLinkEdit,
            KpiDate: row.kpiDate,
            DisplayOrder: row.displayOrderEdit === null ? null : parseInt(row.displayOrderEdit.toString())
        };
        if (row.id === 0) {
            const { data } = await this.$http.post<number>("AddSupportabilityWishrData", metric);
            return data;
        }
        else {
            const { data } = await this.$http.post<number>("UpdateSupportabilityWishrData", metric);
            return data
        }

    }
    public async DeleteSupportabilityWishrData(id: number): Promise<number> {
        const { data } = await this.$http.get<number>(`DeleteSupportabilityWishrData?id=${id}`);
        return data;
    }
    public async AddrecurrenceForSupportability(recNumber, selectedDate, id): Promise<number> {
        const { data } = await this.$http.get<number>(`AddrecurrenceForSupportability?selectedDate=${selectedDate}&recNumber=${recNumber}&id=${id}`);
        return data;
    }
    public async DeleterecurrenceForSupportability(recNumber: number, selectedDate, id): Promise<number> {
        const { data } = await this.$http.get<number>(`DeleterecurrenceForSupportability?selectedDate=${selectedDate}&recNumber=${recNumber}&id=${id}`);
        return data;
    }
    public async GetPirAdminLock(): Promise<IPIRAdminLock[]> {
        const { data } = await this.$http.get<IPIRAdminLock[]>(`GetPirAdminLock`);
        return data;
    }
    public async PostPirAdminLock(lockType: number, lockedWeek: string, lockValue: number, userName: string): Promise<IPIRAdminLock[]> {         
        const { data } = await this.$http.get<IPIRAdminLock[]>(`PostPirAdminLock?lockType=${lockType}&lockedWeek=${lockedWeek}&lockValue=${lockValue}&userName=${userName}`);
        return data;
    }
    public async GetFundamentalsSpotlightWishrData(Meetingdate: string): Promise<IPropFundamentalsSpotlightWishr[]> {
        const { data } = await this.$http.get<IPropFundamentalsSpotlightWishr[]>(`GetFundamentalsSpotlightWishrData?selectedDate=${Meetingdate}`);
        return data;
    }
    public async saveFundamentalsSpotlightMeetingDate(id, KPIDate, userName): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateDateForFundamentalsSpotlight?id=${id}&dateKpi=${KPIDate}&userName=${userName}`);
        return data;
    }
    public async AddorupdateFundamentalsSpotlightWishrData(row: IPropFundamentalsSpotlightWishr): Promise<number> {
        const metric = {
            Id: row.id,
            Presenter: row.presenterEdit === undefined || null ? row.presenter : row.presenterEdit,
            TopicName: row.topicNameEdit === undefined || null ? row.topicName: row.topicNameEdit,
            TimeNeeded: 0,
            PresentationLink: row.presentationLinkEdit === undefined || null ? row.presentationLink : row.presentationLinkEdit,
            IsTopicApproved: row.isTopicApprovedEdit === undefined || null ? row.isTopicApproved : row.isTopicApprovedEdit,
            KpiDate: row.kpiDate,
            DisplayOrder: row.displayOrderEdit === null ? null : parseInt(row.displayOrderEdit.toString()),
            UserName: row.userName
        };
        if (row.id === 0) {
            const { data } = await this.$http.post<number>("AddFundamentalsSpotlightWishrData", metric);
            return data;
        }
        else {
            const { data } = await this.$http.post<number>("UpdateFundamentalsSpotlightWishrData", metric);
            return data
        }

    }
    public async DeleteFundamentalsSpotlightWishrData(id: number, userName: string): Promise<number> {
        const { data } = await this.$http.get<number>(`DeleteFundamentalsSpotlightWishrData?id=${id}&userName=${userName}`);
        return data;
    }    
    public async GetActionItemsReviewWishrData(Meetingdate: string): Promise<IPropActionItemsReviewWishr[]> {
        const { data } = await this.$http.get<IPropActionItemsReviewWishr[]>(`GetActionItemsReviewWishrData?selectedDate=${Meetingdate}`);        
        return data;
    } 
    //Public Service Announcement
    public async GetPubServiceAccWishrData(Meetingdate: string): Promise<IPropPublicServiceAnnouncementWishr[]> {
        const { data } = await this.$http.get<IPropPublicServiceAnnouncementWishr[]>(`GetPubServiceAccWishrData?selectedDate=${Meetingdate}`);
        return data;
    }
    public async DeletePubServiceAccWishrData(id: number, userName: string): Promise<number> {
        const { data } = await this.$http.get<number>(`DeletePubServiceAccWishrData?id=${id}&userName=${userName}`);
        return data;
    }
    public async savePubServiceAccMeetingDate(id, KPIDate, userName): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateDateForPubServiceAcc?id=${id}&dateKpi=${KPIDate}&UserName=${userName}`);
        return data;
    }
    public async AddorUpdatePubServiceAccWishrData(row: IPropPublicServiceAnnouncementWishr): Promise<number> {
        const metric = {
            Id: row.id,
            Presenter: row.presenterEdit === undefined || null ? row.presenter : row.presenterEdit,
            TopicName: row.topicNameEdit === undefined || null ? row.topicName : row.topicNameEdit,
            TimeNeeded: 0,
            PresentationLink: row.presentationLinkEdit === undefined || null ? row.presentationLink : row.presentationLinkEdit,
            IsTopicApproved: row.isTopicApprovedEdit === undefined || null ? row.isTopicApproved : row.isTopicApprovedEdit,
            KpiDate: row.kpiDate,
            DisplayOrder: row.displayOrderEdit === null ? null : parseInt(row.displayOrderEdit.toString()),
            UserName: row.userName
        };
        if (row.id === 0) {
            const { data } = await this.$http.post<number>("AddPubServiceAccWishrData", metric);
            return data;
        }
        else {
            const { data } = await this.$http.post<number>("UpdatePubServiceAccWishrData", metric);
            return data
        }
    }
     //Success Case Studies
    public async GetSuccessCaseStudiesWishrData(Meetingdate: string): Promise<IPropSuccessCaseStudiesWishr[]> {
        const { data } = await this.$http.get<IPropSuccessCaseStudiesWishr[]>(`GetSuccessCaseStudiesWishrData?selectedDate=${Meetingdate}`);
        return data;
    }
    public async DeleteSuccessCaseStudiesWishrData(id: number, userName: string): Promise<number> {
        const { data } = await this.$http.get<number>(`DeleteSuccessCaseStudiesWishrData?id=${id}&userName=${userName}`);
        return data;
    }
    public async saveSuccessCaseStudiesMeetingDate(id, KPIDate, userName): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateDateForSuccessCaseStudies?id=${id}&dateKpi=${KPIDate}&UserName=${userName}`);
        return data;
    }
    public async AddorUpdateSuccessCaseStudiesWishrData(row: IPropSuccessCaseStudiesWishr): Promise<number> {
        const metric = {
            Id: row.id,
            Presenter: row.presenterEdit === undefined || null ? row.presenter : row.presenterEdit,
            TopicName: row.topicNameEdit === undefined || null ? row.topicName : row.topicNameEdit,
            TimeNeeded: 0,
            PresentationLink: row.presentationLinkEdit === undefined || null ? row.presentationLink : row.presentationLinkEdit,
            IsTopicApproved: row.isTopicApprovedEdit === undefined || null ? row.isTopicApproved : row.isTopicApprovedEdit,
            KpiDate: row.kpiDate,
            DisplayOrder: row.displayOrderEdit === null ? null : parseInt(row.displayOrderEdit.toString()),
            UserName: row.userName
        };
        if (row.id === 0) {
            const { data } = await this.$http.post<number>("AddSuccessCaseStudiesWishrData", metric);
            return data;
        }
        else {
            const { data } = await this.$http.post<number>("UpdateSuccessCaseStudiesWishrData", metric);
            return data
        }
    }
     //SuccessStories
    public async GetSuccessStoriesWishrData(Meetingdate: string): Promise<IPropSuccessStoriesWishr[]> {
        const { data } = await this.$http.get<IPropSuccessStoriesWishr[]>(`GetSuccessStoriesWishrData?selectedDate=${Meetingdate}`);
        return data;
    }
    public async DeleteSuccessStoriesWishrData(id: number, userName: string): Promise<number> {
        const { data } = await this.$http.get<number>(`DeleteSuccessStoriesWishrData?id=${id}&userName=${userName}`);
        return data;
    }
    public async saveSuccessStoriesMeetingDate(id, KPIDate, userName): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateDateForSuccessStories?id=${id}&dateKpi=${KPIDate}&UserName=${userName}`);
        return data;
    }
    public async AddorUpdateSuccessStoriesWishrData(row: IPropSuccessStoriesWishr): Promise<number> {
        const metric = {
            Id: row.id,
            Presenter: row.presenterEdit === undefined || null ? row.presenter : row.presenterEdit,
            TopicName: row.topicNameEdit === undefined || null ? row.topicName : row.topicNameEdit,
            TimeNeeded: 0,
            PresentationLink: row.presentationLinkEdit === undefined || null ? row.presentationLink : row.presentationLinkEdit,
            IsTopicApproved: row.isTopicApprovedEdit === undefined || null ? row.isTopicApproved : row.isTopicApprovedEdit,
            KpiDate: row.kpiDate,
            DisplayOrder: row.displayOrderEdit === null ? null : parseInt(row.displayOrderEdit.toString()),
            UserName: row.userName
        };
        if (row.id === 0) {
            const { data } = await this.$http.post<number>("AddSuccessStoriesWishrData", metric);
            return data;
        }
        else {
            const { data } = await this.$http.post<number>("UpdateSuccessStoriesWishrData", metric);
            return data
        }
    }
    public async GetOutageReviewData(Meetingdate: string): Promise<IPropOutageReviewWishr[]> {
        const { data } = await this.$http.get<IPropOutageReviewWishr[]>(`GetOutageReviewData?selectedDate=${Meetingdate}`);
        return data;
    }
    public async SaveOutagePresenter(row: IPropOutageReviewWishr): Promise<number> {
        row.presenter = row.presenterEdit === undefined || null ? row.presenter : row.presenterEdit;
        const { data } = await this.$http.post<number>("SaveOutageDetails", row);
        return data;
    }
    //AppendixMaterials
    public async GetAppendixMaterialsWishrData(Meetingdate: string): Promise<IPropAppendixMaterialsWishr[]> {
        const { data } = await this.$http.get<IPropAppendixMaterialsWishr[]>(`GetAppendixMaterialsWishrData?selectedDate=${Meetingdate}`);
        return data;
    }
    public async DeleteAppendixMaterialsWishrData(id: number, userName: string): Promise<number> {
        const { data } = await this.$http.get<number>(`DeleteAppendixMaterialsWishrData?id=${id}&userName=${userName}`);
        return data;
    }
    public async saveAppendixMaterialsMeetingDate(id, KPIDate, userName): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateDateForAppendixMaterials?id=${id}&dateKpi=${KPIDate}&UserName=${userName}`);
        return data;
    }
    public async AddorupdateAppendixMaterialsWishrData(row: IPropAppendixMaterialsWishr): Promise<number> {
        const metric = {
            Id: row.id,
            Presenter: row.presenterEdit === undefined || null ? row.presenter : row.presenterEdit,
            TopicName: row.topicNameEdit === undefined || null ? row.topicName : row.topicNameEdit,
            PresentationLink: row.presentationLinkEdit === undefined || null ? row.presentationLink : row.presentationLinkEdit,
            IsTopicApproved: row.isTopicApprovedEdit === undefined || null ? row.isTopicApproved : row.isTopicApprovedEdit,
            KpiDate: row.kpiDate,
            DisplayOrder: row.displayOrderEdit === null ? null : parseInt(row.displayOrderEdit.toString()),
            UserName: row.userName
        };
        if (row.id === 0) {
            const { data } = await this.$http.post<number>("AddAppendixMaterialsWishrData", metric);
            return data;
        }
        else {
            const { data } = await this.$http.post<number>("UpdateAppendixMaterialsWishrData", metric);
            return data
        }

    }
}

export const WeeklyIDSHRAPI = WeeklyIDSHRService.Instance;
