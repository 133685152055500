import { IAppThunkAction, ReduxAction } from '../index';
import { Span, SpanColumnContext, ActionType, ServiceDropdownOption, CMDropdownOption, IServiceGroup, PropMetricGroups, PropAdminMetrics, PropMetricChanges, PropConfig, PropTarget, PropUsers, PropEditHistory, PropMetricsChangeHistory, PropCapacityManagement, PropCmMetric, PropHeatMapMissingViews, ITargetJson } from './types';
import { AdminSettingsAPI, CommonAPI } from '../../api/index';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../utils/index';
import { object } from 'prop-types';
import { toast } from 'react-toastify';

export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    setloadingtrue: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
    },
    setloadingfalse: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_FALSE
        });
    },
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }
    },
    AdminDataTabsToggle: (tab): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let Currentview = getState().serviceHealth.ConfigurationView;
        dispatch({
            tab: tab,
            activeDailyDataTab: Currentview,
            type: ActionType.SET_ADMINDATA_ACTIVETAB
        })
    },
    changetoggle: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        AdminSettingsAPI.getServiceGroupAsyn(0)
            .then((serviceGroup: IServiceGroup[]) => {
                const serviceNames = [];
                for (var i = 0; i < serviceGroup.length; i++) {
                    serviceGroup[i].isActive = serviceGroup[i].isActive ? true : false;
                }
                serviceGroup.map((obj: IServiceGroup) => {
                    serviceNames.push({ 'id': obj.serviceId, 'name': obj.serviceName });
                })
                dispatch({
                    serviceGroup: serviceGroup,
                    serviceNames: serviceNames,
                    type: ActionType.GET_SERVICE_CONFIGURATION
                });
            });
    },
    saveMetricConfig: (serviceId): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        AdminSettingsAPI.Get_Metrics_Admin(serviceId)
            .then((adminMetrics: PropAdminMetrics[]) => {
                dispatch({
                    adminMetrics: adminMetrics,
                    type: ActionType.GET_ADMIN_METRICS
                });
            });
    },
    onInputMetricConfig: (e, name): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let minRangeMetricConfig;
        let maxRangeMetricConfig;
        let intervalMetricConfig;
        let validationExprMetricConfig;
        let isEditableMetricConfig;
        let isMetricGraphMetricConfig;

        if (name === 'isEdit') {
            isEditableMetricConfig = e;
        }
        else if (name === 'isGraph') {
            isMetricGraphMetricConfig = e;
        }
        else if (name === 'minRange') {
            minRangeMetricConfig = e;
        }
        else if (name === 'maxRange') {
            maxRangeMetricConfig = e;
        }
        else if (name === 'intervalConfig') {
            intervalMetricConfig = e;
        }
        else if (name === 'validExp') {
            validationExprMetricConfig = e;
        }
        dispatch({
            isEditableMetricConfig: isEditableMetricConfig,
            isMetricGraphMetricConfig: isMetricGraphMetricConfig,
            minRangeMetricConfig: minRangeMetricConfig,
            maxRangeMetricConfig: maxRangeMetricConfig,
            intervalMetricConfig: intervalMetricConfig,
            validationExprMetricConfig: validationExprMetricConfig,
            type: ActionType.METRIC_MINRANGE_UPDATE
        });
    },
    handledMetricEditChange: (status, metricgrpName, metricid): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let adminMetrics = getState().AdminSettings.adminMetrics;
        let copyAdmin = [...adminMetrics];

        for (var i = 0; i < copyAdmin.length; i++) {
            if (copyAdmin[i].metricGroupName == metricgrpName) {
                for (var j = 0; j < copyAdmin[i].metricDetails.length; j++) {
                    if (copyAdmin[i].metricDetails[j].metricId == metricid) {
                        copyAdmin[i].metricDetails[j].isEditable = status;

                    }
                }
            }
        }
        dispatch({
            adminMetrics: copyAdmin,
            type: ActionType.METRIC_ISEDIT_CHANGE
        });
    },
    handledMetricGraphChange: (status, metricgrpName, metricid, serviceId): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        console.log(status, metricgrpName, metricid, serviceId);
        let adminMetrics = getState().AdminSettings.adminMetrics;
        for (var i = 0; i < adminMetrics.length; i++) {
            if (adminMetrics[i].metricGroupName == metricgrpName) {
                for (var j = 0; j < adminMetrics[i].metricDetails.length; j++) {
                    if (adminMetrics[i].metricDetails[j].metricId == metricid) {
                        adminMetrics[i].metricDetails[j].isMetricGraph = status;
                    }
                }
            }
        }
        console.log(adminMetrics);
        dispatch({
            adminMetrics: adminMetrics,
            type: ActionType.METRIC_ISGRAPH_CHANGE
        });
    },
    onInputValidExpChange: (e, metricgrpName, metricid): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        console.log(e);
        let valiExpData = e.target.value;
        let adminMetrics = getState().AdminSettings.adminMetrics;
        let copyAdmin = [...adminMetrics];

        for (var i = 0; i < copyAdmin.length; i++) {
            if (copyAdmin[i].metricGroupName == metricgrpName) {
                for (var j = 0; j < copyAdmin[i].metricDetails.length; j++) {
                    if (adminMetrics[i].metricDetails[j].metricId == metricid) {
                        adminMetrics[i].metricDetails[j].validationExpr = valiExpData;
                    }
                }
            }
        }

        dispatch({
            adminMetrics: copyAdmin,
            type: ActionType.METRIC_VALIDEXP_CHANGE
        });
    },
    onInputMinRangeChange: (e, metricgrpName, metricid): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        console.log(e);
        let minRange = e.target.value;
        let adminMetrics = getState().AdminSettings.adminMetrics;
        for (var i = 0; i < adminMetrics.length; i++) {
            if (adminMetrics[i].metricGroupName == metricgrpName) {
                for (var j = 0; j < adminMetrics[i].metricDetails.length; j++) {
                    if (adminMetrics[i].metricDetails[j].metricId == metricid) {
                        adminMetrics[i].metricDetails[j].minRange = minRange;
                    }
                }
            }
        }
        console.log(adminMetrics);
        dispatch({
            adminMetrics: adminMetrics,
            type: ActionType.METRIC_MINRANGE_CHANGE
        });
    },
    onInputMaxRangeChange: (e, metricgrpName, metricid): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        console.log(e);
        let maxRange = e.target.value;
        let adminMetrics = getState().AdminSettings.adminMetrics;
        for (var i = 0; i < adminMetrics.length; i++) {
            if (adminMetrics[i].metricGroupName == metricgrpName) {
                for (var j = 0; j < adminMetrics[i].metricDetails.length; j++) {
                    if (adminMetrics[i].metricDetails[j].metricId == metricid) {
                        adminMetrics[i].metricDetails[j].maxRange = maxRange;
                    }
                }
            }
        }
        console.log(adminMetrics);
        dispatch({
            adminMetrics: adminMetrics,
            type: ActionType.METRIC_MAXRANGE_CHANGE
        });
    },
    onInputIntervalChange: (e, metricgrpName, metricid): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        console.log(e);
        let interval = e.target.value;
        let adminMetrics = getState().AdminSettings.adminMetrics;
        for (var i = 0; i < adminMetrics.length; i++) {
            if (adminMetrics[i].metricGroupName == metricgrpName) {
                for (var j = 0; j < adminMetrics[i].metricDetails.length; j++) {
                    if (adminMetrics[i].metricDetails[j].metricId == metricid) {
                        adminMetrics[i].metricDetails[j].interval = interval;
                    }
                }
            }
        }
        console.log(adminMetrics);
        dispatch({
            adminMetrics: adminMetrics,
            type: ActionType.METRIC_INTERVAL_CHANGE
        });
    },
    serviceNameSelect: (selectedServicesval: ServiceDropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        dispatch({
            selectedServicesval: selectedServicesval,
            type: ActionType.SERVICE_CHANGE
        });
        AdminSettingsAPI.Get_Metrics_Admin(selectedServicesval.id)
            .then((adminMetrics: PropAdminMetrics[]) => {
                // console.log(compute(adminMetrics, Object.keys(adminMetrics[0])));
                /*console.log(_.sortBy(adminMetrics, ['metricGroupName']));*/
                dispatch({
                    adminMetrics: adminMetrics,
                    type: ActionType.GET_ADMIN_METRICS
                });
            });
    },
    serviceNameSelectCr: (selectedCrServicesval: ServiceDropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            selectedCrServicesval: selectedCrServicesval,
            type: ActionType.CR_SERVICE_CHANGE
        });
    },
    //heatmapServiceNameSelect: (selectedHeatmapServiceval: ServiceDropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
    //    dispatch({
    //        selectedHeatmapServiceval: selectedHeatmapServiceval,
    //        type: ActionType.HEATMAP_SERVICE_CHANGE
    //    });
    //},

    crMetricSelect: (selectedCrMetricsval: CMDropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            selectedCrMetricsval: selectedCrMetricsval,
            type: ActionType.CR_METRIC_CHANGE
        });
    },
    cmmetricGroupSelect: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let metricName = e.target.name;
        let upMetricGroup = e.target.value;
        let CMData = getState().AdminSettings.CMData;
        let copyofCMData = [...CMData];
        let cmMetricGroup = getState().AdminSettings.CMMetricGroups;
        let upMtrGrp = cmMetricGroup.find(o => o.value === upMetricGroup);
        for (var i = 0; i < copyofCMData.length; i++) {
            let metricId = copyofCMData.find(o => o.cmMetricName === metricName).cmMetricId;
            if (copyofCMData[i].cmMetricId == metricId) {
                copyofCMData[i].cmMetricGroupId = upMtrGrp;
            }
        }
        dispatch({
            CMData: copyofCMData,
            type: ActionType.CM_METRICGROUP_CHANGE
        });
    },
    cmEnvironmentSelect: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let metricName = e.target.name;
        let upEnvironment = e.target.value;
        let CMData = getState().AdminSettings.CMData;
        let copyofCMData = [...CMData];
        let cmEnvironmentGroup = getState().AdminSettings.CMEnvirnoments;
        let upEnvMtrGrp = cmEnvironmentGroup.find(o => o.value === upEnvironment);
        for (var i = 0; i < copyofCMData.length; i++) {
            let metricId = copyofCMData.find(o => o.cmMetricName === metricName).cmMetricId;
            if (copyofCMData[i].cmMetricId == metricId) {
                copyofCMData[i].envId = upEnvMtrGrp;
            }
        }
        dispatch({
            CMData: copyofCMData,
            type: ActionType.CM_ENVIRONMENT_CHANGE
        });
    },
    UpdateCMData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        AdminSettingsAPI.GetCapacityMetrics()
            .then((CMData: PropCmMetric[]) => {
                let CMMetricGroups = getState().AdminSettings.CMMetricGroups;
                let CMEnvirnoments = getState().AdminSettings.CMEnvirnoments;
                for (var i = 0; i < CMData.length; i++) {
                    CMData[i].cmMetricGroupId = CMMetricGroups.find(function (o) { return o.key === CMData[i].cmMetricGroupId });
                    CMData[i].envId = CMEnvirnoments.find(function (o) { return o.key === CMData[i].envId });
                }
                dispatch({
                    CMData: CMData,
                    type: ActionType.UPDATED_CM_METRICS
                });
            });
    },
    onInputChange: (e, name): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const val = (e.target && e.target.value) || '';
        let newMetricName;
        let newMetricDisplayName;
        let newMetricEnvironment;
        let newMetricGroup;
        if (name == 'cmNewMetricName') {
            newMetricName = val;
        }
        else if (name == 'cmNewMetricDisplayName') {
            newMetricDisplayName = val;
        }
        else if (name == 'newMetricGroup') {
            newMetricGroup = val;
        }
        else if (name == 'newMetricEnvironment') {
            newMetricEnvironment = val;
        }
        dispatch({
            newMetricName: newMetricName ? newMetricName : getState().AdminSettings.newMetricName,
            newMetricDisplayName: newMetricDisplayName ? newMetricDisplayName : getState().AdminSettings.newMetricDisplayName,
            newMetricGroup: newMetricGroup ? newMetricGroup : getState().AdminSettings.newMetricGroup,
            newMetricEnvironment: newMetricEnvironment ? newMetricEnvironment : getState().AdminSettings.newMetricEnvironment,
            type: ActionType.CM_NEWMETRIC_ADD
        });
    },
    SaveNewCMMetric: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        AdminSettingsAPI.GetCapacityMetrics()
            .then((CMData: PropCmMetric[]) => {
                let CMMetricGroups = getState().AdminSettings.CMMetricGroups;
                let CMEnvirnoments = getState().AdminSettings.CMEnvirnoments;
                for (var i = 0; i < CMData.length; i++) {
                    CMData[i].cmMetricGroupId = CMMetricGroups.find(function (o) { return o.key === CMData[i].cmMetricGroupId });
                    CMData[i].envId = CMEnvirnoments.find(function (o) { return o.key === CMData[i].envId });
                }
                dispatch({
                    CMData: CMData,
                    type: ActionType.CM_INSERT_METRIC
                });
            });
    },
    metricGroupSelect: (selectedMetricGroupsval: ServiceDropdownOption): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            selectedMetricGroupsval: selectedMetricGroupsval,
            type: ActionType.CR_METRICGROUP_CHANGE
        });
    },
    historyserviceNameSelect: (selectedhistoryServicesval: ServiceDropdownOption): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let serviceId = selectedhistoryServicesval.id;
        const fromDate = new Date(getState().AdminSettings.historyFrmCalVal);
        const toDate = new Date(getState().AdminSettings.historyToCalVal);
        let fdate = new Date(fromDate);
        let fyear = fdate.getFullYear();
        let fmonth = fdate.getMonth() + 1;
        let fday = fdate.getDate();
        let fromdate = '2017-10-09';//String(fyear) + "-" + String(fmonth) + "-" + String(fday);
        let tdate = new Date(toDate);
        let tyear = tdate.getFullYear();
        let tmonth = tdate.getMonth() + 1;
        let tday = tdate.getDate();
        let todate = String(tyear) + "-" + String(tmonth) + "-" + String(tday);

        AdminSettingsAPI.GetEditHistoryData(serviceId, fromdate, todate)
            .then((editHistory: PropEditHistory[]) => {
                //REFER LIVESITE FUNCTION

                dispatch({
                    editHistory: editHistory,
                    selectedhistoryServicesval: selectedhistoryServicesval,
                    type: ActionType.HISTORY_SERVICE_CHANGE
                });
            });
    },
    historymetricGroupSelect: (selectedhistoryMetricGroupsval: ServiceDropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            selectedhistoryMetricGroupsval: selectedhistoryMetricGroupsval,
            type: ActionType.HISTORY_METRICGROUP_CHANGE
        });
    },
    crTargetSelect: (selectedtargetDetailsval: ServiceDropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            selectedtargetDetailsval: selectedtargetDetailsval,
            type: ActionType.CR_TARGET_CHANGE
        });
    },
    UpdateRequestedByValue: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            crrequestedBy: e.target.value,
            type: ActionType.UPDATE_REQUSTEDBY_VALUES
        });
    },
    UpdateTitleValue: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            crtitle: e.target.value,
            type: ActionType.UPDATE_TITLE_VALUES
        });
    },
    UpdateRequirementIdValue: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            crrequirment_Id: e.target.value,
            type: ActionType.UPDATE_REQUIREMENT_VALUES
        });
    },
    UpdateCommentValue: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            crcomments: e.target.value,
            type: ActionType.UPDATE_COMMENT_VALUES
        });
    },
    historyFrmValChange: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let toDate = new Date();
        let serviceId = getState().AdminSettings.selectedhistoryServicesval.id;
        let fdate = new Date(e);
        let fyear = fdate.getFullYear();
        let fmonth = fdate.getMonth() + 1;
        let fday = fdate.getDate();
        let fromdate = String(fyear) + "-" + String(fmonth) + "-" + String(fday);
        let tdate = new Date(toDate);
        let tyear = tdate.getFullYear();
        let tmonth = tdate.getMonth() + 1;
        let tday = tdate.getDate();
        let todate = String(tyear) + "-" + String(tmonth) + "-" + String(tday);
        let editHistory;


        AdminSettingsAPI.GetEditHistoryData(serviceId, fromdate, todate)
            .then((editHistory: PropEditHistory[]) => {
                //REFER LIVESITE FUNCTION
                dispatch({
                    historyFrmCalVal: e,
                    editHistory: editHistory,
                    type: ActionType.HISTORY_FROM_CHANGE
                });
            });
    },
    historyToValChange: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let serviceId = getState().AdminSettings.selectedhistoryServicesval.id;
        let fromDate = getState().AdminSettings.historyFrmCalVal;
        let fdate = new Date(e);
        let fyear = fdate.getFullYear();
        let fmonth = fdate.getMonth() + 1;
        let fday = fdate.getDate();
        let fromdate = String(fyear) + "-" + String(fmonth) + "-" + String(fday);
        let tdate = new Date(e);
        let tyear = tdate.getFullYear();
        let tmonth = tdate.getMonth() + 1;
        let tday = tdate.getDate();
        let todate = String(tyear) + "-" + String(tmonth) + "-" + String(tday);
        let editHistory;

        AdminSettingsAPI.GetEditHistoryData(serviceId, fromdate, todate)
            .then((editHistory: PropEditHistory[]) => {
                //REFER LIVESITE FUNCTION
                dispatch({
                    historyToCalVal: e,
                    editHistory: editHistory,
                    type: ActionType.HISTORY_TO_CHANGE
                });
            });

    },
    heatmapServiceNameSelect: (selectedHeatmapServiceval: ServiceDropdownOption): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let serviceId = selectedHeatmapServiceval.id;
        let fromDate;
        let toDate;
        let heatMapHistoryData;
        let heatmapFromDate = getState().AdminSettings.heatmapFromCalVal;
        let heatmapToDate = getState().AdminSettings.heatmapToCalVal;

        if (heatmapFromDate == null || heatmapFromDate == "") {
            fromDate = new Date(new Date().getFullYear(), (new Date().getMonth()) - 1, new Date().getDate());
        } else {
            fromDate = getState().AdminSettings.heatmapFromCalVal;
        }
        if (heatmapToDate == null || heatmapToDate == "") {
            toDate = new Date();
        }
        else {
            toDate = getState().AdminSettings.heatmapToCalVal;
        }

        var fdate = new Date(fromDate);
        var fyear = fdate.getFullYear();
        var fmonth = fdate.getMonth() + 1;
        var fday = fdate.getDate();
        var fromdate = String(fyear) + "-" + String(fmonth) + "-" + String(fday);
        var tdate = new Date(toDate);
        var tyear = tdate.getFullYear();
        var tmonth = tdate.getMonth() + 1;
        var tday = tdate.getDate();
        var todate = String(tyear) + "-" + String(tmonth) + "-" + String(tday);

        AdminSettingsAPI.GetHeatMapKUSTOHistoryData(serviceId, fromdate, todate)
            .then((heatMapHistoryData: object) => {
                dispatch({
                    heatMapHistoryData: heatMapHistoryData,
                    selectedHeatmapServiceval: selectedHeatmapServiceval,
                    type: ActionType.HEATMAP_SERVICE_CHANGE
                });
            });
    },
    heatmapFromCalValChange: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let fromDate = new Date(e);
        let toDate;
        let heatMapHistoryData;
        let heatmapToDate = getState().AdminSettings.heatmapToCalVal;
        if (heatmapToDate == null || heatmapToDate == "") {
            toDate = new Date();
        }
        else {
            toDate = getState().AdminSettings.heatmapToCalVal;
        }

        var fdate = new Date(fromDate);
        var fyear = fdate.getFullYear();
        var fmonth = fdate.getMonth() + 1;
        var fday = fdate.getDate();
        var fromdate = String(fyear) + "-" + String(fmonth) + "-" + String(fday);
        var tdate = new Date(toDate);
        var tyear = tdate.getFullYear();
        var tmonth = tdate.getMonth() + 1;
        var tday = tdate.getDate();
        var todate = String(tyear) + "-" + String(tmonth) + "-" + String(tday);
        var serviceId = getState().AdminSettings.selectedHeatmapServiceval.id;
        let heatmapFromCalVal;

        AdminSettingsAPI.GetHeatMapKUSTOHistoryData(serviceId, fromdate, todate)
            .then((heatMapHistoryData: object) => {
                dispatch({
                    heatmapFromCalVal: e,
                    heatMapHistoryData: heatMapHistoryData,
                    type: ActionType.HEATMAP_FROM_CHANGE
                });
            });

    },
    heatmapToCalValChange: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let fromDate;
        let toDate = new Date(e);
        let heatMapHistoryData;
        let heatmapFromDate = getState().AdminSettings.heatmapFromCalVal;
        if (heatmapFromDate == null || heatmapFromDate == "") {
            fromDate = new Date(new Date().getFullYear(), (new Date().getMonth()) - 1, new Date().getDate());
        } else {
            fromDate = getState().AdminSettings.heatmapFromCalVal;
        }

        var fdate = new Date(fromDate);
        var fyear = fdate.getFullYear();
        var fmonth = fdate.getMonth() + 1;
        var fday = fdate.getDate();
        var fromdate = String(fyear) + "-" + String(fmonth) + "-" + String(fday);
        var tdate = new Date(toDate);
        var tyear = tdate.getFullYear();
        var tmonth = tdate.getMonth() + 1;
        var tday = tdate.getDate();
        var todate = String(tyear) + "-" + String(tmonth) + "-" + String(tday);
        var serviceId = getState().AdminSettings.selectedHeatmapServiceval.id;
        let heatmapToCalVal;

        AdminSettingsAPI.GetHeatMapKUSTOHistoryData(serviceId, fromdate, todate)
            .then((heatMapHistoryData: object) => {
                dispatch({
                    heatmapToCalVal: e,
                    heatMapHistoryData: heatMapHistoryData,
                    type: ActionType.HEATMAP_TO_CHANGE
                });
            });

    },
    SaveConfiguration: (e): IAppThunkAction<ReduxAction> => (dispatch, getstate) => {
        AdminSettingsAPI.Get_Config(1)
            .then((adminConfig: PropConfig[]) => {
                dispatch({
                    adminConfig: adminConfig,
                    type: ActionType.GET_UPDATEDADMIN_CONFIG
                });
            });
    },
    loadMetrics: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        const crserviceval = getState().AdminSettings.selectedCrServicesval;
        const crMetricGroupVal = getState().AdminSettings.selectedMetricGroupsval;

        AdminSettingsAPI.Get_MetricsFromServiceMetricGroup(crserviceval.id, crMetricGroupVal.id)
            .then((metricChange: PropMetricChanges[]) => {
                //REFER LIVESITE FUNCTION
                dispatch({
                    metricChange: metricChange,
                    type: ActionType.GET_METRIC_CHANGES
                });
            });
    },
    DailyDataTabstoggleData: (tab): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const selectedServicegroupval = null;
        if (tab === 'serviceConfiguration') {
            dispatch({
                type: ActionType.SET_LOADING_TRUE
            });
            AdminSettingsAPI.getServiceGroupAsyn(0)
                .then((serviceGroup: IServiceGroup[]) => {
                    const serviceNames = [];
                    for (var i = 0; i < serviceGroup.length; i++) {
                        serviceGroup[i].isActive = serviceGroup[i].isActive ? true : false;
                    }
                    serviceGroup.map((obj: IServiceGroup) => {
                        serviceNames.push({ 'id': obj.serviceId, 'name': obj.serviceName });
                    })
                    dispatch({
                        serviceGroup: serviceGroup,
                        serviceNames: serviceNames,
                        tab: tab,
                        type: ActionType.GET_SERVICE_GROUP
                    });
                });
        }
        else if (tab === 'metricConfiguration') {
            dispatch({
                type: ActionType.SET_LOADING_TRUE
            });
            const serviceGroup = getState().AdminSettings.services;
            const serviceNames = getState().AdminSettings.serviceNames;
            dispatch({
                serviceGroup: serviceGroup,
                serviceNames: serviceNames,
                tab: tab,
                type: ActionType.GET_SERVICE_GROUP
            });

        }
        else if (tab === 'crTracking') {
            dispatch({
                type: ActionType.SET_LOADING_TRUE
            });
            AdminSettingsAPI.GetMetricGroups()
                .then((metricgroups: PropMetricGroups[]) => {
                    const metricGroups = [];
                    metricgroups.map((obj: PropMetricGroups) => {
                        metricGroups.push({ 'id': obj.metricGroupId, 'name': obj.metricGroupName });
                    })

                    dispatch({
                        metricGroups: metricGroups,
                        tab: tab,
                        type: ActionType.GET_METRIC_GROUPS
                    });
                });

            AdminSettingsAPI.Get_Targets()
                .then((targetdetails: PropTarget[]) => {
                    const targetDetails = [];
                    targetdetails.map((obj: PropTarget) => {
                        targetDetails.push({ 'id': obj.targetId, 'name': obj.targetName });
                    })
                    dispatch({
                        targetDetails: targetDetails,
                        type: ActionType.GET_TARGET_DETAILS
                    });
                });
        }
        else if (tab === 'heatmapTargetApproval') {
            dispatch({
                type: ActionType.SET_LOADING_TRUE
            });
            AdminSettingsAPI.GetHeatmapTargetUpdateRequests()
                .then((targetRequests: ITargetJson[]) => {
                    dispatch({
                        targetUpdateApprovalRequestData: targetRequests,
                        tab: tab,
                        type: ActionType.HEATMAPCHANGEREQUESTDATA
                    });

                });
        }
        else if (tab === 'capacityManagement') {
            dispatch({
                type: ActionType.SET_LOADING_TRUE
            });
            let cmMetricGroups = [];
            let cmEnvironments = [];

            AdminSettingsAPI.GetAllScoreCardDetails()
                .then((CMScoreCardData: object) => {

                    dispatch({
                        CMScoreCardData: CMScoreCardData,
                        type: ActionType.GET_SCORECARD_DATA
                    });
                });

            AdminSettingsAPI.GetCapacityMetrics()
                .then((CMData: PropCmMetric[]) => {
                    AdminSettingsAPI.GetCapacityMetricGroup()
                        .then((CMMetricGroups: object) => {
                            let CMData = getState().AdminSettings.CMData;
                            cmMetricGroups.push(CMMetricGroups);
                            for (var i = 0; i < CMData.length; i++) {
                                CMData[i].cmMetricGroupId = cmMetricGroups[0].find(function (o) { return o.key === CMData[i].cmMetricGroupId });
                            }

                            dispatch({
                                CMData: CMData,
                                CMMetricGroups: CMMetricGroups,
                                type: ActionType.GET_CAPACITY_METRIC_GROUP
                            });
                        });
                    AdminSettingsAPI.GetMetricEnvirnoments()
                        .then((CMEnvirnoments: object) => {
                            let CMData = getState().AdminSettings.CMData;
                            cmEnvironments.push(CMEnvirnoments);
                            for (var i = 0; i < CMData.length; i++) {
                                CMData[i].envId = cmEnvironments[0].find(function (o) { return o.key === CMData[i].envId });
                            }
                            dispatch({
                                CMData: CMData,
                                CMEnvirnoments: CMEnvirnoments,
                                type: ActionType.GET_METRIC_ENVIRONMENT
                            });
                        });
                    dispatch({
                        CMData: CMData,
                        tab: tab,
                        type: ActionType.GET_CAPACITY_METRICS
                    });
                });

        }
        else if (tab === 'otherConfiguration') {
            dispatch({
                type: ActionType.SET_LOADING_TRUE
            });
            AdminSettingsAPI.Get_Config(1)
                .then((adminConfig: PropConfig[]) => {
                    dispatch({
                        adminConfig: adminConfig,
                        tab: tab,
                        type: ActionType.GET_ADMIN_CONFIG
                    });
                });
        }
        else if (tab === 'historyData') {
            dispatch({
                type: ActionType.SET_LOADING_TRUE
            });
            let serviceId;
            const metricGroups = (getState().AdminSettings.metricGroups) ? getState().AdminSettings.metricGroups : [];
            let serviceVal = getState().AdminSettings.selectedServicesval;
            let selectedhistoryServicesval;
            let selectedhistoryMetricGroupsval;
            if (serviceVal != null) {
                serviceId = getState().AdminSettings.selectedServicesval.id;
                selectedhistoryServicesval = getState().AdminSettings.selectedServicesval;
            }
            else {
                serviceId = 23;
                selectedhistoryServicesval = getState().AdminSettings.serviceNames[0];
            }
            if (metricGroups.length == 0) {
                AdminSettingsAPI.GetMetricGroups()
                    .then((metricgroups: PropMetricGroups[]) => {
                        metricgroups.map((obj: PropMetricGroups) => {
                            metricGroups.push({ 'id': obj.metricGroupId, 'name': obj.metricGroupName });
                        })
                        selectedhistoryMetricGroupsval = metricGroups[0];
                    });
            }
            if (selectedhistoryMetricGroupsval == null && getState().AdminSettings.selectedMetricGroupsval == null) {
                selectedhistoryMetricGroupsval = metricGroups[0];
            }
            else if (selectedhistoryMetricGroupsval == null || getState().AdminSettings.selectedMetricGroupsval != null) {
                selectedhistoryMetricGroupsval = getState().AdminSettings.selectedMetricGroupsval;
            }
            const fromDate = new Date(new Date().getFullYear(), (new Date().getMonth()) - 1, new Date().getDate());
            const toDate = new Date();
            let fdate = new Date(fromDate);
            let fyear = fdate.getFullYear();
            let fmonth = fdate.getMonth() + 1;
            let fday = fdate.getDate();
            let fromdate = '2017-10-09';//String(fyear) + "-" + String(fmonth) + "-" + String(fday);
            let tdate = new Date(toDate);
            let tyear = tdate.getFullYear();
            let tmonth = tdate.getMonth() + 1;
            let tday = tdate.getDate();
            let todate = String(tyear) + "-" + String(tmonth) + "-" + String(tday);
            let historyFrmCalVal = new Date(fromdate);
            let historyToCalVal = new Date(todate);

            AdminSettingsAPI.GetEditHistoryData(serviceId, fromdate, todate)
                .then((editHistory: PropEditHistory[]) => {
                    dispatch({
                        editHistory: editHistory,
                        metricGroups: metricGroups,
                        historyFrmCalVal: historyFrmCalVal,
                        historyToCalVal: historyToCalVal,
                        selectedhistoryServicesval: selectedhistoryServicesval,
                        selectedhistoryMetricGroupsval: selectedhistoryMetricGroupsval,
                        tab: tab,
                        type: ActionType.GET_EDIT_HISTORY
                    });
                });
        }
        else if (tab === 'heatMapHistoryData') {
            dispatch({
                type: ActionType.SET_LOADING_TRUE
            });
            let serviceId;
            let selectedHeatmapServiceval;
            let fromDate;
            let toDate;
            let heatmapFromCalVal;
            let heatmapToCalVal;
            let serviceVal = getState().AdminSettings.selectedServicesval;
            if (serviceVal != null) {
                serviceId = getState().AdminSettings.selectedServicesval.id;
                selectedHeatmapServiceval = getState().AdminSettings.selectedServicesval;
            }
            else {
                serviceId = 23;
                selectedHeatmapServiceval = getState().AdminSettings.serviceNames[0];
            }
            let historyFromDate = getState().AdminSettings.historyFrmCalVal;
            let historyToDate = getState().AdminSettings.historyToCalVal;

            if (historyFromDate == null || historyFromDate == "") {
                fromDate = new Date(new Date().getFullYear(), (new Date().getMonth()) - 1, new Date().getDate());
            } else {
                fromDate = getState().AdminSettings.historyFrmCalVal;
            }

            if (historyToDate == null || historyToDate == "") {
                toDate = new Date();
            }
            else {
                toDate = getState().AdminSettings.historyToCalVal;
            }

            var fdate = new Date(fromDate);
            var fyear = fdate.getFullYear();
            var fmonth = fdate.getMonth() + 1;
            var fday = fdate.getDate();
            var fromdate = String(fyear) + "-" + String(fmonth) + "-" + String(fday);
            var tdate = new Date(toDate);
            var tyear = tdate.getFullYear();
            var tmonth = tdate.getMonth() + 1;
            var tday = tdate.getDate();
            var todate = String(tyear) + "-" + String(tmonth) + "-" + String(tday);

            AdminSettingsAPI.GetHeatMapKUSTOHistoryData(serviceId, fromdate, todate)
                .then((heatMapHistoryData: object) => {
                    dispatch({
                        heatmapFromCalVal: new Date(fromdate),
                        heatmapToCalVal: new Date(todate),
                        selectedHeatmapServiceval: selectedHeatmapServiceval,
                        heatMapHistoryData: heatMapHistoryData,
                        tab: tab,
                        type: ActionType.GET_HEATMAPHISTORY_DATA
                    });
                });
        }
        else if (tab === 'missingHeatmapData') {
            dispatch({
                type: ActionType.SET_LOADING_TRUE
            });
            AdminSettingsAPI.GetHeatMapMissingViewData()
                .then((missingViewInfo: PropHeatMapMissingViews[]) => {
                    dispatch({
                        missingViewInfo: missingViewInfo,
                        tab: tab,
                        type: ActionType.GET_MISSINGHEATMAP_DATA
                    });
                });
        }
        else {
            dispatch({
                tab: tab,
                type: ActionType.SET_ADMINDATA_ACTIVETAB
            })
        }
    },
    getServiceGroups: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        // Build http request and success handler in Promise<void> wrapper
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        AdminSettingsAPI.getServiceGroupAsyn(0)
            .then((serviceGroup: IServiceGroup[]) => {
                const serviceNames = [];
                /*$scope.isLoadMore = false;*/
                for (var i = 0; i < serviceGroup.length; i++) {
                    serviceGroup[i].isActive = serviceGroup[i].isActive ? true : false;
                }
                serviceGroup.map((obj: IServiceGroup) => {
                    serviceNames.push({ 'id': obj.serviceId, 'name': obj.serviceName });
                })
                dispatch({
                    serviceGroup: serviceGroup,
                    serviceNames: serviceNames,
                    type: ActionType.GET_SERVICE_CONFIGURATION
                });
            });
    },
    servicechange: (serviceId: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        AdminSettingsAPI.Get_Metrics_Admin(serviceId)
            .then((adminMetrics: PropAdminMetrics[]) => {
                dispatch({
                    adminMetrics: adminMetrics,
                    type: ActionType.GET_ADMIN_METRICS
                });
            });
    },
    getUsers: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        AdminSettingsAPI.Get_Users()
            .then((userDetails: PropUsers[]) => {
                //$scope.Get_Users = function () {
                //    LiveSiteService.Get_Users().then(function (response) {
                //        $scope.users = response.data;
                //    });
                //};
                dispatch({
                    userDetails: userDetails,
                    type: ActionType.GET_USERS
                });
            });
    },
    approveHmRequest: (e, row): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        row.approvedBy = getState().authUser.login;
        row.approvalStatus = 'Approved';
        row.approvedDate = new Date();

        AdminSettingsAPI.ApproveTargetChangeRequest(row).then((result: number) => {
            if (result >= 1) {
                dispatch({
                    type: ActionType.SET_LOADING_FALSE
                });
                toast.success(
                    renderToastifyMsg('Heatmap Target Change request has been approved and Email is being processed',
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                );
                AdminSettingsAPI.GetHeatmapTargetUpdateRequests()
                    .then((targetRequests: ITargetJson[]) => {
                        dispatch({
                            targetUpdateApprovalRequestData: targetRequests,
                            tab: 'heatmapTargetApproval',
                            type: ActionType.HEATMAPCHANGEREQUESTDATA
                        });

                    });
                const requestDetails = {
                    viewName: row.viewName,
                    serviceName: row.serviceName,
                    OrginalTarget: row.orginalTarget,
                    NewTarget: row.newTarget,
                    RequestedBy: row.requestedBy
                }
                AdminSettingsAPI.HmTargetApproveEmail(requestDetails)
                    .then((response: number) => {
                        toast.success(
                            renderToastifyMsg('Heatmap Target changes approval confirmation email is sent',
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                        );
                        dispatch({
                            type: ActionType.EMAILCONFIRMATION
                        });
                    }).catch(error => {
                        toast.error(
                            renderToastifyMsg("Failed to send email notification",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                        );
                    });
            }
        });

    },
    rejectHmRequest: (e, row): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        row.approvedBy = getState().authUser.login;
        row.approvalStatus = 'Rejected';
        row.approvedDate = new Date();
        let requesterEmail = getState().authUser.login;


        AdminSettingsAPI.ApproveTargetChangeRequest(row).then((result: number) => {
            if (result >= 1) {
                dispatch({
                    type: ActionType.SET_LOADING_FALSE
                });
                toast.success(
                    renderToastifyMsg('Heatmap Target Change request has been Rejected and Email is being processed',
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                );
                AdminSettingsAPI.GetHeatmapTargetUpdateRequests()
                    .then((targetRequests: ITargetJson[]) => {
                        dispatch({
                            targetUpdateApprovalRequestData: targetRequests,
                            tab: 'heatmapTargetApproval',
                            type: ActionType.HEATMAPCHANGEREQUESTDATA
                        });

                    });
                const requestDetails = {
                    viewName: row.viewName,
                    serviceName: row.serviceName,
                    OrginalTarget: row.orginalTarget,
                    NewTarget: row.newTarget,
                    RequestedBy: row.requestedBy
                }
                AdminSettingsAPI.HmTargetRejectEmail(requestDetails)
                    .then((response: number) => {
                        toast.success(
                            renderToastifyMsg('Heatmap Target Change request has been rejected',
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                        );
                        dispatch({
                            type: ActionType.EMAILCONFIRMATION
                        });
                    }).catch(error => {
                        toast.error(
                            renderToastifyMsg("Failed to send email notification to admin",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                        );
                    });
            }
        });
    }
}





