import React, { useEffect } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { Spinner } from '../../components';
import { IApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import { actionCreators, reducer } from '../../store/actionItems';
import { ActionItemsTable } from '../ActionItems/child-components';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
type ActionItemsProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly history: History }
    & typeof useParams;

const ActionItems: React.FC<ActionItemsProps> = ({
    isLoading,
    isAuthenticated,
    weeklyStartDate,
    getWeekstartdate,
    allRepairItemsData,
    allRepairItemsDataGraphoptions,
    allRepairItemsGraphDataAggregated,
    RepairItemGraphServicesoptions,
    allRepairItemsGraphData,
    Get_IdentityactionitemController_Item_Details,
    checkAllService,
    checkallservices,
    allRepairItemsDatatableheader,
    actionItemsData,
    Get_IdentityactionitemController_Item_Details_Data,
    checksingleservices,
    Isdataloaded,
    addUserVisit
}) => {

    useEffect(() => {
        if (isAuthenticated)
        addUserVisit(0, 'ActionItems.tsx_v2');
    }, [isAuthenticated]);
    useEffect(() => {     
        if (weeklyStartDate === null) {
            getWeekstartdate();
        }
        else {
            Get_IdentityactionitemController_Item_Details(moment(weeklyStartDate).format('MM/DD/YYYY'));
            Get_IdentityactionitemController_Item_Details_Data(moment(weeklyStartDate).format('MM/DD/YYYY'));
        }
        

    }, [getWeekstartdate, weeklyStartDate]);
    //===================== function to open recurrence popup ===================================//
    const checkservicesforeachrow = (row, e, index, all) => {
        checksingleservices(row, e.target.checked, index, all);       
    }
    return (
        <div>

            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        <ActionItemsTable
                            allRepairItemsData={allRepairItemsData}
                            allRepairItemsDataGraphoptions={allRepairItemsDataGraphoptions}
                            allRepairItemsGraphDataAggregated={allRepairItemsGraphDataAggregated}
                            RepairItemGraphServicesoptions={RepairItemGraphServicesoptions}
                            allRepairItemsGraphData={allRepairItemsGraphData}
                            checkAllService={checkAllService}
                            changeallservices={checkallservices}
                            allRepairItemsDatatableheader={allRepairItemsDatatableheader}
                            checkservicesforeachrow={(row, e, index, all) => checkservicesforeachrow(row, e, index, all)}
                            actionItemsData={actionItemsData}
                            Isdataloaded={Isdataloaded}
                        />

                    </div>
                </section>
            </LoadingOverlay>
           
        </div>     
      
    );
};
const mapStateToProps = (state: IApplicationState) => ({
    ...state.ActionItems,
    isAuthenticated: state.authUser.isAuthenticated

})
export default connect(mapStateToProps, actionCreators)(ActionItems);
