import React, { useEffect } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { RouteComponentProps } from 'react-router-dom';
import { actionCreators, reducer } from '../../store/PIRRiskTool';
import { FontAwesomeIconMemo } from '../../components';
import "../../../src/assets/style/scss/components/PIRRiskTool.scss";
import { PIRRiskToolTable } from '../PIRRiskTool/child-components';
import { GEMTree } from './child-components';
import { MultiSelect } from 'primereact/multiselect';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { Row, Col, Button, Card, CardBody } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import { InputText } from 'primereact/inputtext';
import { DeprecatedFunctionalityBanner } from '../../components';

type PIRRiskToolProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly history: History }
    & RouteComponentProps<{}>;

const PIRRiskTool: React.FC<PIRRiskToolProps> = ({
    isLoading,
    isAuthenticated,
    addUserVisit,
    nodeData,
    UpdateNodeData,
    UpdateSelectAll,
    gemServiceMappingData,
    GetServiceTreeGemListData,
    checkedServices,
    workItemID,
    UpdateworkItemID,
    searchWorkItem,
    clearWorkItem,
    titleValue,
    UpdateTitle,
    IterationPathValue,
    UpdateIterationPath,
    workItemTypeValue,
    UpdateworkItemType,
    tagsValue,
    UpdateTags,
    stateValue,
    UpdateState,
    includeLinks,
    includeLinkschange,
    includeattachments,
    includeAttachmentchange,
    createCopy,
    relations,
    isLinksActive,
    isAttachmentActive,
    errorMsg,
    errorMsgList,
    descriptionValue,
    IDClassificationchange,
    IDClassificationvalue,
    IDClassificationList,
    customFields
}) => {

    useEffect(() => {
        if (isAuthenticated)
            addUserVisit(0, 'PIRRiskTool.tsx_v2');
        GetServiceTreeGemListData();
    }, [isAuthenticated]);

    return (
        <div>
            <DeprecatedFunctionalityBanner />
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        <Card className="cardstyle">
                            <CardBody className="cardbodyhederstyle">
                                <Row>

                                    <Col lg="12">
                                        <h3 className="headertext"> Post Incident Reviews (PIRs) Risk Tool</h3>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <br />

                        <Row>
                            <Col lg="4">
                                <h5>GEM & Services</h5>
                                <GEMTree nodeData={nodeData} UpdateNodeData={UpdateNodeData} UpdateSelectAll={UpdateSelectAll} />
                            </Col>
                            <Col lg="2">
                                <h5> Classification </h5>
                                <MultiSelect id="mlidclassification" filter={true} optionLabel="name" value={IDClassificationvalue} options={IDClassificationList} onChange={(e) => IDClassificationchange(e.value)} maxSelectedLabels={0} />
                            </Col>
                            <Col lg="6">
                                <div style={{ marginTop: '2%' }}>
                                    <span style={{ fontSize: '20px', marginLeft: '4%' }}> ADO Work item Id# </span>
                                    <span> <InputText style={{ width: '22%' }} value={workItemID} onChange={(e) => UpdateworkItemID(e)} /></span>
                                    <span style={{ marginLeft: '2%' }}> <Button className="searchWorkItemButton" disabled={workItemID === ''} onClick={searchWorkItem}>Get Work item details</Button></span>
                                    <span style={{ marginLeft: '2%' }}> <Button className="clearWorkItemButton btn btn-primary" onClick={clearWorkItem}> <FontAwesomeIconMemo title="Clear" icon={'ban'} />&nbsp; Clear</Button></span>
                                </div>
                            </Col>
                        </Row>

                        <PIRRiskToolTable
                            titleValue={titleValue}
                            UpdateTitle={UpdateTitle}
                            IterationPathValue={IterationPathValue}
                            UpdateIterationPath={UpdateIterationPath}
                            workItemTypeValue={workItemTypeValue}
                            UpdateworkItemType={UpdateworkItemType}
                            tagsValue={tagsValue}
                            UpdateTags={UpdateTags}
                            stateValue={stateValue}
                            UpdateState={UpdateState}
                            includeLinks={includeLinks}
                            includeLinkschange={includeLinkschange}
                            includeattachments={includeattachments}
                            includeAttachmentchange={includeAttachmentchange}
                            createCopy={createCopy}
                            relations={relations}
                            isLinksActive={isLinksActive}
                            isAttachmentActive={isAttachmentActive}
                            errorMsg={errorMsg}
                            errorMsgList={errorMsgList}
                            descriptionValue={descriptionValue}
                            IDClassificationvalue={IDClassificationvalue}
                            IDClassificationList={IDClassificationList}
                            customFields={customFields}
                        />


                    </div>
                </section>
            </LoadingOverlay>

        </div>

    );
};
const mapStateToProps = (state: IApplicationState) => ({
    ...state.PIRRiskTool,
    isAuthenticated: state.authUser.isAuthenticated

})
export default connect(mapStateToProps, actionCreators)(PIRRiskTool);
