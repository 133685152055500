import React, { useState, Fragment } from 'react';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import { IPropCommsOnlyPir} from '../../../store/Weekly-ID-SHR/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faTrashAlt, faPencilAlt, faEdit, faTrash, faTruckLoading, faArrowAltCircleLeft, faPlus, faSave, faBan, faCalendar, faUnlock, faLock} from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Button } from 'reactstrap';
import DatePicker, { getDay } from 'react-datepicker';
import moment from 'moment';

type CommosonlyPIRProps = {
    CommosonlyPIRData: IPropCommsOnlyPir[],   
    isComosEditenabled: boolean,
    EditComosonlyRows: any,
    CancelEditComosOnlyPIR: any,
    OnCommoslonyPIRTextboxchange: any,
    SaveCommosonlyPIRData: any,
    DeleteCommosonlyPIRData: any
    EnableAddforComosonlyPIRData: any,
    handleKeypress: any,
    CommsPIRDate: any,
    MaxDate: any,
    MeetingDate: any,
    isAdmin: any,
    handleCommsPIRDateChange: any,
    isLocked: boolean,
    EditLockforCommsPIR: any
};
const CommosonlyPIR = React.memo<CommosonlyPIRProps>(({
    CommosonlyPIRData,  
    isComosEditenabled,
    EditComosonlyRows,
    CancelEditComosOnlyPIR,
    OnCommoslonyPIRTextboxchange,
    SaveCommosonlyPIRData,
    DeleteCommosonlyPIRData,
    EnableAddforComosonlyPIRData,
    handleKeypress,
    CommsPIRDate,
    MaxDate,
    MeetingDate,
    handleCommsPIRDateChange,
    isAdmin,
    isLocked,
    EditLockforCommsPIR
}) => {

    const isThursday = (date) => {
        var day = new Date(moment(date).format('MM/DD/YYYY')).getDay();
        return day == 4;
    };

    return (        
        <React.Fragment>
            <br />
            <Row>
                <Col lg="8"><h5 className="headertext">Comms Only PIRs</h5></Col>
                <Col lg="4">
                    <div className="divaddnew">
                        <span style={{ display: isLocked ? 'inline' : 'none', color:'red' }}>Locked By Admin  </span>
                        <Button className='PIRAddbuttons' disabled={isLocked} onClick={EnableAddforComosonlyPIRData}> <FontAwesomeIcon icon={faPlus} /> &nbsp;Add New Comms Only PIR</Button>&nbsp;
                        <Button className='PIRAddbuttons' hidden={!isLocked} disabled={!isAdmin} onClick={EditLockforCommsPIR}><FontAwesomeIcon icon={faLock} /></Button>
                        <Button className='PIRAddbuttons' hidden={isLocked} disabled={!isAdmin} onClick={EditLockforCommsPIR}><FontAwesomeIcon icon={faUnlock} /></Button>
                    </div>
                </Col>
            </Row>
            <Row className="tblpadding">
                <Col lg="12">
                    <table id="tblPIR" className="tblPIR tblborder">
                        <thead>
                            <tr>
                                <th className="thwidth120">{(isComosEditenabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Presenter</th>
                                <th className="tdwidth16">{(isComosEditenabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Owning Service</th>
                                <th className="tdwidth16">{(isComosEditenabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}PIR Title</th>
                                <th className="thwidth100">{(isComosEditenabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}TTN</th>
                                <th className="tdwidth16">{(isComosEditenabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Comms PIR Link</th>
                                <th className="tdwidth16">PIR Number</th> 
                                {(isAdmin === true) ?
                                    <th className="thwidth100">{(isComosEditenabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Order</th>
                                    : <React.Fragment></React.Fragment>
                                }
                                <th className="tdwidth10">Actions</th>
                               
                            </tr>
                        </thead>
                        <tbody>
                            {!isArrayWithLength(CommosonlyPIRData)
                                ?
                                <React.Fragment>
                                    <tr>
                                        <td colSpan={8} className="clsnodatamessgefortable">
                                            No Data Available
                                        </td>
                                    </tr>
                                </React.Fragment>
                                :
                                CommosonlyPIRData.map((row: IPropCommsOnlyPir, index) => (
                                <React.Fragment key={index}>
                                        <tr>
                                            <td>
                                                <div>{(row.isEdit) ? <textarea className="form-control" onChange={e => OnCommoslonyPIRTextboxchange(e, row)} value={row.presenterEdit} name="presenterEdit" rows={1} cols={50} /> : row.presenter}</div>
                                            </td>
                                        <td>
                                            <div>{(row.isEdit) ? <input className="form-control" onChange={e => OnCommoslonyPIRTextboxchange(e, row)} value={row.serviceNameEdit} name="serviceNameEdit" /> : row.serviceName}</div>
                                        </td>
                                        <td>
                                            <div>{(row.isEdit) ? <textarea className="form-control" onChange={e => OnCommoslonyPIRTextboxchange(e, row)} value={row.titleEdit} name="titleEdit" rows={1} cols={50} /> : row.title}</div>
                                        </td>                                        
                                        <td>
                                            <div>{(row.isEdit) ? <input className="form-control" onChange={e => OnCommoslonyPIRTextboxchange(e, row)} value={row.ttnEdit} name="ttnEdit" /> : row.ttn}</div>
                                          
                                        </td>
                                        <td className="tdwordbreak">
                                            <div>{(row.isEdit) ? <textarea className="form-control" onChange={e => OnCommoslonyPIRTextboxchange(e, row)} rows={1} cols={50} value={row.commsPirLinkEdit} name="commsPirLinkEdit" /> : <a href={row.commsPirLink} target="_blank">{row.commsPirLink}</a>}</div>
                                        </td>
                                        <td>
                                            <div>{(row.isEdit) ? <input className="form-control" onChange={e => OnCommoslonyPIRTextboxchange(e, row)} value={row.pirNumberEdit} name="pirNumberEdit" onKeyDown={handleKeypress} /> : row.pirNumber}</div>                                           
                                            </td>
                                            {(isAdmin === true) ?
                                            <td>
                                                <div>{(row.isEdit) ? <input type="number" min="0" max="32000" className="form-control" value={row.displayOrderEdit} name="displayOrderEdit" onKeyDown={handleKeypress} onChange={e => OnCommoslonyPIRTextboxchange(e, row)} /> :
                                                <div>{row.displayOrder}</div>
                                                }</div>
                                                </td>
                                                : <React.Fragment></React.Fragment>
                                            }
                                        <td className="centericons">
                                            {
                                                (!row.isEdit)
                                                    ?
                                                    <React.Fragment>
                                                        <span onClick={e => EditComosonlyRows(e, row)}> <FontAwesomeIcon title='Click to edit the Row' className="bluecoloricons Iconsstyles" icon={faPencilAlt} /></span>
                                                            &nbsp;
                                                     <FontAwesomeIcon title='Click to Delete the Row' className="redcolorIcons Iconsstyles" icon={faTrash} onClick={e => DeleteCommosonlyPIRData(e, row)}/>&nbsp;
                                                             {(isAdmin === true) ?
                                                                <span>
                                                                    <label htmlFor={'CommsDate' + index}><FontAwesomeIcon title='Click to Change the  Meeting Date' className="bluecoloricons Iconsstyles" icon={faCalendar} /></label>
                                                                    <DatePicker
                                                                        id={"CommsDate" + index}
                                                                        selected={CommsPIRDate}
                                                                        onChange={CommsPIRDate => handleCommsPIRDateChange(row, CommsPIRDate)}
                                                                        filterDate={isThursday}
                                                                        maxDate={MaxDate}
                                                                        className="datepickerCalendar"
                                                                        popperPlacement="bottom-end"
                                                                        popperModifiers={{
                                                                            flip: {
                                                                                enabled: false
                                                                            },
                                                                            offset: {
                                                                                enabled: true,
                                                                                offset: "55px, 5px"
                                                                            },
                                                                            preventOverflow: {
                                                                                enabled: true,
                                                                                escapeWithReference: false
                                                                            }
                                                                        }}
                                                                    />
                                                                </span>
                                                                : <React.Fragment></React.Fragment>
                                                             }
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <FontAwesomeIcon title='Save Changes' className="bluecoloricons Iconsstyles" icon={faSave} onClick={e => SaveCommosonlyPIRData(e, row)}/> &nbsp;
                                             <FontAwesomeIcon title='Cancel Edit' onClick={e => CancelEditComosOnlyPIR(e, row)} icon={faBan}  className="Iconsstyles"/>
                                                    </React.Fragment>
                                            }
                                        </td>                                       
                                    </tr>
                                </React.Fragment>
                            ))}
                           
                        </tbody>
                    </table>
                </Col>
            </Row>
        </React.Fragment>
    );
});

CommosonlyPIR.displayName = 'PostIncidentReviews';

export default CommosonlyPIR;