export type IDCMappingState = {
    readonly isLoading: boolean;
    datacenters: IDataCenter[];
    dataCenterNameOptions: [];
    azureRegionNameOptions: [];
    locationOptions: [];
    countryOptions: [];
    currentRow: IDataCenter;
}

const _namespace = 'DCMapping';

export type IDataCenter = {
    id: number;    
    dataCenterName: any,
    azureRegionName: any,
    location: any,
    country: any,
    referenceInfo: any,
    regionID: number,
    regionOid: any,
    regionCode: any,
    regionLifeCycle: any,
    regionType: any,
    geographyId: number,    
    updatedby: any,
}

export interface IActionType {
    readonly RESET_STATE: string;
    readonly SET_LOADING_TRUE: string;
    readonly SET_LOADING_FALSE: string;
    readonly ADDED_VISITED_USER: string;
    readonly GET_DATACENTERS: string;
    readonly UPDATE_CURRENTRECORD: string;
    readonly UPDATE_RECORD: string;    
}

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}DCMappingreset`,
    SET_LOADING_TRUE: `${_namespace}setloadingtrue`,
    SET_LOADING_FALSE: `${_namespace}setloadingfalse`, 
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    GET_DATACENTERS: `${_namespace}/getdatacenter`,
    UPDATE_CURRENTRECORD: `${_namespace}/updatecurrentrecord`,
    UPDATE_RECORD: `${_namespace}/updaterecord`
});