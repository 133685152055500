import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { RouteComponentProps } from 'react-router-dom';
import { actionCreators, reducer } from '../../store/WZTNA-SHR';
import { ActionItemsReview, FundamentalsSpotlight, WZTNASHRDataTable } from '../WZTNA-SHR/child-components';
import { isNullOrUndefined, isArrayWithLength, renderToastifyMsg } from '../../utils';
import { ToastId, toast } from 'react-toastify';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { WZTNASHRAPI } from '../../api';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap'
import {
    IPropAllPirData
} from '../../store/WZTNA-SHR/types';
import { PostIncidentReviews, SpecialTopics, PostIncidentReReviews, PublicServiceAnnouncement } from './child-components';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { DeprecatedFunctionalityBanner } from '../../components';

type WZTNASHRDataProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly username: string }
    & { readonly isAdmin: boolean }
    & RouteComponentProps<{}>;

const WZTNASHRData: React.FC<WZTNASHRDataProps> = ({
    isLoading,
    MeetingDate,
    DefaultDate,
    GetMeetingDate,
    handleMeetingDateChange,
    weeklyIDSHRData,
    GetWeeklyIDSHRPostIncidentsData,
    SpecialTopicsData,
    PIRRereviewData,
    PublicServiceAnnouncementData,
    FundamentalsSpotlightData,
    ActionItemsReviewData,
    EditPIRRow,
    CancelEditPIRRow,
    OnPIRChangevalues,
    setloadingtrue,
    setloadingfalse,
    isAuthenticated,
    isAdmin,
    username,
    EnableAddNewRowforPIR,
    isPIREditenabled,
    EditSpecialTopicRow,
    CancelEditSpecialTopicRow,
    isSpecialTopicEditorAddEnabled,
    OnPIRSpecialTopicChangevalues,
    EnableAddNewRowforSpecialTopics,
    MaxDate,
    IsPirRereviewUser,
    checkIsPirRereviewUser,
    addUserVisit,
    isLockedPIR,
    EditLockforPIR,
    isLockedTopic,
    EditLockforSpecificTopic,
    GetPirAdminLock,
    isLockedFundamentalsSpotlight,
    EditLockforFundamentalsSpotlight,
    EnableAddforFundamentalsSpotlight,
    OnFundamentalsSpotlightChangevalues,
    isFundamentalsSpotlightEditorAddEnabled,
    CancelEditFundamentalsSpotlightRow,
    EditFundamentalsSpotlightRow,
    UpdateFundamentalsSpotlightOrder,
    UpdateweeklyIDSHRDataOrder,
    UpdateSpecialTopicsDataOrder,
    isFunLockDisabledOnEdit,
    isSpecialTopicLockDisabledOnEdit,
    isPIRLockDisabledOnEdit,
    EditPubServiceAccRow,
    CancelEditPubServiceAccRow,
    OnPIRPubServiceChangevalues,
    EditLockforPubServiceAcc,
    EnableAddNewRowforPubServiceAcc,
    isPubServiceAccEditorAddEnabled,
    isLockedPubServiceAcc,
    UpdatePubServiceAccDataOrder,
    isPSALockDisabledOnEdit,
    accordiontoggle,
    setaccordiontoggledata,
    funaccordiontoggle,
    setfunaccordiontoggledata,
    psaaccordiontoggle,
    setpsaccordiontoggledata,
    specialaccordiontoggle,
    setspecialaccordiontoggledata,
    piraccordiontoggle,
    setpiraccordiontoggledata
}) => {
    useEffect(() => {
        if (isAuthenticated)
            addUserVisit(0, 'WZTNASHR.tsx_v2');

    }, [isAuthenticated]);
    useEffect(() => {
        if (!isNullOrUndefined(username)) {
            checkIsPirRereviewUser(username);
        }

    }, [username]);
    useEffect(() => {
        GetPirAdminLock();
    }, [GetPirAdminLock]);
    useEffect(() => {
        if (MeetingDate === null) {
            GetMeetingDate();
        }
        else {
            GetWeeklyIDSHRPostIncidentsData(moment(MeetingDate).format('MM/DD/YYYY'), 4);
        }

    }, [GetMeetingDate, MeetingDate]);
    const toastIdRef = useRef<ToastId>('');
    const [showaddrecurrence, setshowaddrecurrence] = useState(false);
    const [recid, setrecid] = useState(0);
    const [recurrenceno, setrecurrenceno] = useState('');
    const [Disablesubmitbutton, setDisablesubmitbutton] = useState(true);

    //===================== function to close the recurrence popup ===================================//
    const closeshowaddrecurrence = () => {
        setshowaddrecurrence(false);
    }
    //===================== function to open recurrence popup ===================================//
    const openaddrecurrencepopup = (e, row) => {
        setshowaddrecurrence(true);
        setrecid(row.id);
        setrecurrenceno('');
    }
    //===================== function to change the recurrence no ===================================//
    const changerecurrenceno = (e) => {
        setrecurrenceno(e.target.value);
        if (!isNullOrUndefined(e.target.value)) {
            setDisablesubmitbutton(false);
        }
        else {
            setDisablesubmitbutton(true);
        }
    }
    //================ function to save the recurrence =====================//
    const saveRecurrence = () => {
        let recccno = parseInt(recurrenceno);
        setloadingtrue();
        WZTNASHRAPI.AddrecurrenceForSpecialTopics(recccno, moment(MeetingDate).format('MM/DD/YYYY'), recid, username)
            .then((result: number) => {
                setshowaddrecurrence(false);
                setloadingfalse();
                if (result == -1) {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while adding",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
                else if (recccno == 1) {

                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Recurrence Already Present for selected  Special Topic",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
                else if (result == 0) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg("Special Topics added successfully based on Recurrence",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
                else if (result == recccno) {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Recurrence Already Present for selected  Special Topic",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
                else if (result != recccno) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg("Already " + result + " Recurrence exist and remaining Recurrences added successfully",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }

            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });
    }
    //============ function to delete recurrence ==============================//
    const deleterecurrence = (e, row) => {
        if (window.confirm("Are you sure you want to Delete this recurrence?")) {
            WZTNASHRAPI.DeleterecurrenceForSpecialTopics(0, moment(MeetingDate).format('MM/DD/YYYY'), row.id, username)
                .then((result: number) => {
                    GetWeeklyIDSHRPostIncidentsData(moment(MeetingDate).format('MM/DD/YYYY'), 3);
                    setshowaddrecurrence(false);
                    setloadingfalse();
                    if (result !== -1) {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg("Special Topics Deleted successfully based on Recurrence",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );

                    } else {
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Error while deleting the Special Topics Data",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );

                    }


                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while deleting",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }

    }
    //**************************************************************************** function Block for Public Service Announcement  ****************************************************************************//

    //================ function to enable the Edit for Public Service Announcement Row =====================//
    const EditPubServiceAccRows = (e, value) => {
        let val: number = value.id;
        EditPubServiceAccRow(val);
    }

    //================= function to delete the selected Row for Public Service Announcement =======================//
    const DeletePubServiceAccRow = (e, row) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            setloadingtrue();
            WZTNASHRAPI.DeletePubServiceAccWishrData(row.id, username)
                .then((result: number) => {
                    if (result > 0) {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg("Public Service Announcement Data for presenter " + row.presenter + " Deleted successfully",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                        GetWeeklyIDSHRPostIncidentsData(moment(MeetingDate).format('MM/DD/YYYY'), 2);
                    }

                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while deleting",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
    }

    //================= function to modify the Meeting Date for Public Service Announcement =======================//
    const handlePubServiceAccDateChange = (row, KPIDate) => {
        setloadingtrue();
        WZTNASHRAPI.savePubServiceAccMeetingDate(row.id, moment(KPIDate).format('MM/DD/YYYY'), username)
            .then((result: number) => {
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg("Public Service Announcement Data for Presenter " + row.presenter + " Updated successfully",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    GetWeeklyIDSHRPostIncidentsData(moment(MeetingDate).format('MM/DD/YYYY'), 2);
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while updating the Public Service Announcement Data",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });

    }

    //=============function to Save for Edit/Add   the  values for Public Service Announcement ====================//
    const SavePubServiceAccData = (e, row) => {
        row.userName = username;
        var existdisplayorder = false;
        // Logic for Duplicate DisplayOrder
        if (isAdmin === true && (row.id == 0)) {
            let filteredData = PublicServiceAnnouncementData.filter(function (x) { return x.id !== -1; });
            let highestDisplayOrder = Math.max(...filteredData.map(o => o.displayOrderEdit));
            PublicServiceAnnouncementData.find(x => x.id == 0).displayOrderEdit = highestDisplayOrder + 1;
        }
        row.kpiDate = moment(MeetingDate).format('MM/DD/YYYY');
        if (isNullOrUndefined(row.presenterEdit) || !/\S/.test(row.presenterEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please enter the Presenter Details',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );

        }
        else if (isNullOrUndefined(row.topicNameEdit) || !/\S/.test(row.topicNameEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please enter the Topic Name',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );

        }
        else if (!isNullOrUndefined(row.presentationLinkEdit) && !validateLink(row.presentationLinkEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg("Please enter the valid Presentation Link",
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        else {
            if (!isNullOrUndefined(row.presentationLinkEdit) && row.presentationLinkEdit.toLowerCase().indexOf("http") === -1) {
                row.presentationLinkEdit = "https://" + row.presentationLinkEdit;
            }
            if (isNullOrUndefined(row.displayOrderEdit)) {
                row.displayOrderEdit = null;
            }
            else {
                row.displayOrderEdit = parseInt(row.displayOrderEdit);
            }
            setloadingtrue();
            let message = (row.id === 0) ? "Public Service Announcement Data for Presenter " + row.presenterEdit + " Saved successfully" : "Public Service Announcement Data updated successfully";
            WZTNASHRAPI.AddorUpdatePubServiceAccWishrData(row)
                .then((result: number) => {
                    if (result != -1) {
                        GetWeeklyIDSHRPostIncidentsData(moment(MeetingDate).format('MM/DD/YYYY'), 2);
                        toastIdRef.current = toast.success(
                            renderToastifyMsg(message,
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                    else {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Error while adding Public Service Announcement Data",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while adding the Public Service Announcement Data",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
    }

    //==================function to Cancel the Edit for Public Service Announcement  Row ==================================//
    const cancelEditPubServiceAccRows = (e, value) => {
        if (window.confirm("Are you sure you want to Cancel this Edit?")) {
            let val: number = value.id;
            CancelEditPubServiceAccRow();
        }
    }

    //=======================function on textbox change while Editing arow in Public Service Announcement==============//
    const PubServiceAccvalueschange = (e, value) => {
        let val: number = value.id;
        OnPIRPubServiceChangevalues(e, val);
    }

    const EditLockforPubServiceAccData = () => {
        if (isAdmin) {
            EditLockforPubServiceAcc();
        }
    }

    //=============== function to enable Add new row for Public Service Announcement ====================================//
    const EnableAddNewforPubServiceAccRow = () => {
        EnableAddNewRowforPubServiceAcc();
    }

    //**************************************************************************** functios Block for  FundamentalsSpotlight ****************************************************************************//

    const EditFundamentalsSpotlightRows = (e, value) => {
        let val: number = value.id;
        EditFundamentalsSpotlightRow(val);
    }
    //==================function to Cancel the Edit for Fundamentals Spotlight Row ==================================//
    const cancelEditFundamentalsSpotlightRows = (e, value) => {
        if (window.confirm("Are you sure you want to Cancel this Edit?")) {
            let val: number = value.id;
            CancelEditFundamentalsSpotlightRow();
        }

    }
    //=======================function on textbox change while Editing arow in Fundamentals Spotlight==============//
    const FundamentalsSpotlightvalueschange = (e, value) => {
        let val: number = value.id;
        OnFundamentalsSpotlightChangevalues(e, val);
    }
    //================= function to delete the selected Row for Fundamentals Spotlight =======================//
    const DeleteFundamentalsSpotlightRow = (e, row) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            setloadingtrue();
            WZTNASHRAPI.DeleteFundamentalsSpotlightWishrData(row.id, username)
                .then((result: number) => {
                    if (result > 0) {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg("Fundamentals Spotlight Data for presenter " + row.presenter + " Deleted successfully",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                        GetWeeklyIDSHRPostIncidentsData(moment(MeetingDate).format('MM/DD/YYYY'), 6);
                    }

                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while deleting",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
    }
    //=============function to Save for Edit/Add   the  values for Commos only PIR====================//
    const SaveFundamentalsSpotlightData = (e, row) => {
        row.userName = username;
        var existdisplayorder = false;
        // Logic for Duplicate DisplayOrder
        if (isAdmin === true) {
            FundamentalsSpotlightData.map((obj) => {
                if (obj.id !== row.id) {
                    if (parseInt(obj.displayOrder) === parseInt(row.displayOrderEdit)) {
                        existdisplayorder = true;
                    }
                }
            })
        }
        row.kpiDate = moment(MeetingDate).format('MM/DD/YYYY');
     
        if (isNullOrUndefined(row.topicNameEdit) || !/\S/.test(row.topicNameEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please enter the Topic Name',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );

        }
        else if (isNullOrUndefined(row.presentationLinkEdit) || !/\S/.test(row.presentationLinkEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please enter the Presentation Link',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );

        }
        else if (!validateLink(row.presentationLinkEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg("Please enter the valid Presentation Link",
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        else {
            if (row.presentationLinkEdit.toLowerCase().indexOf("http") === -1) {
                row.presentationLinkEdit = "https://" + row.presentationLinkEdit;
            }
            if (isNullOrUndefined(row.displayOrderEdit)) {
                row.displayOrderEdit = null;
            }
            else {
                row.displayOrderEdit = parseInt(row.displayOrderEdit);
            }
            setloadingtrue();
            let message = (row.id === 0) ? "Fundamentals Spotlight Data for Presenter " + row.presenterEdit + " Saved successfully" : "Fundamentals Spotlight Data updated successfully";
            WZTNASHRAPI.AddorupdateFundamentalsSpotlightWishrData(row)
                .then((result: number) => {
                    if (result != -1) {
                        GetWeeklyIDSHRPostIncidentsData(moment(MeetingDate).format('MM/DD/YYYY'), 6);
                        toastIdRef.current = toast.success(
                            renderToastifyMsg(message,
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                    else {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Error while adding the Fundamentals Spotlight Data",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while adding the Fundamentals Spotlight Data",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
    }
    //================= function to modify the Meeting Date for Fundamentals Spotlight =======================//
    const handleFundamentalsSpotlightDateChange = (row, KPIDate) => {
        setloadingtrue();
        WZTNASHRAPI.saveFundamentalsSpotlightMeetingDate(row.id, moment(KPIDate).format('MM/DD/YYYY'), username)
            .then((result: number) => {
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg("Fundamentals Spotlight Data for Presenter " + row.presenter + " Updated successfully",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    GetWeeklyIDSHRPostIncidentsData(moment(MeetingDate).format('MM/DD/YYYY'), 6);
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while updating the Fundamentals Spotlight Data",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });

    }
    //=============== function to enable Add new row for Fundamentals Spotlight ====================================//
    const EnableAddNewforFundamentalsSpotlightRow = () => {
        EnableAddforFundamentalsSpotlight();
    }

    //**************************************************************************** End Block for Fundamentals Spotlight ****************************************************************************//  

    //**************************************************************************** functios Block for  PIR ****************************************************************************//
    //================ function to enable the Edit for selected PIR Row =====================//
    const EditPIRRows = (e, value) => {
        let val: number = value.id;
        EditPIRRow(val);
    }
    //==================function to Cancel the Edit for PIR Row ==================================//
    const CancelEditPIR = (e, value) => {
        if (window.confirm("Are you sure you want to Cancel this Edit?")) {
            let val: number = value.id;
            CancelEditPIRRow(val);
        }

    }
    //=======================function on textbox change while Editing arow in PIR ==============//
    const OnPIRTextboxchange = (e, value) => {
        let val: number = value.id;
        OnPIRChangevalues(e, val);
    }
    //=============function to Save for Edit/Add & Refresh  the  values for PIR====================//
    const SavePostIncidentData = (e, row, Isrefresh) => {
        row.userName = username;
        var existdisplayorder = false;
        // Logic for Duplicate DisplayOrder
        if (isAdmin === true && (row.id == 0)) {
            let filteredData = weeklyIDSHRData.filter(function (x) { return x.id !== -1; });
            let highestDisplayOrder = Math.max(...filteredData.map(o => o.displayOrderEdit));
            weeklyIDSHRData.find(x => x.id == 0).displayOrderEdit = highestDisplayOrder + 1;
        }
        if (Isrefresh === 'Refreshed') {
            row.pirEdit = row.pir;
            row.occuredRingEdit = row.occuredRing;
            row.displayOrderEdit = row.displayOrder;
            row.presenterEdit = row.presenter;
            row.displayOrderEdit = row.displayOrder;
            setloadingtrue();
            WZTNASHRAPI.AddPirData(row)
                .then((result: number) => {
                    if (result === 2 && row.id === 0) {
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Error in adding to agenda, PIR" + row.pir + " already added to agenda",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                        GetWeeklyIDSHRPostIncidentsData(moment(MeetingDate).format('MM/DD/YYYY'), 1);
                        GetWeeklyIDSHRPostIncidentsData(moment(MeetingDate).format('MM/DD/YYYY'), 4);
                    }
                    else if (result != -1) {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg('PIR Data for service ' + row.serviceName + ' ' + Isrefresh + '  successfully',
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                        GetWeeklyIDSHRPostIncidentsData(moment(MeetingDate).format('MM/DD/YYYY'), 1);
                        GetWeeklyIDSHRPostIncidentsData(moment(MeetingDate).format('MM/DD/YYYY'), 4);
                    }
                    else {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Entered PIR " + row.pirEdit + " does not exist in IcM kusto DB",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while updating",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
        else {
            if (isNullOrUndefined(row.presenterEdit)) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg('Please enter the Presenter Details',
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );

            }
            else if (isNullOrUndefined(row.occuredRingEdit)) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg('Please enter the Occured Ring',
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );

            }
            else if (isNullOrUndefined(row.pirEdit)) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg('Please enter the PIR number',
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );

            }
            else if (isAdmin === true && isNullOrUndefined(row.displayOrderEdit)) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg('Please enter the Display Order',
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );

            }
            else if (isAdmin === true && parseInt(row.displayOrderEdit) === 0) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg('Display order starts from 1',
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );

            }
            else if (isAdmin === true && parseInt(row.displayOrderEdit) > 32000) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg('Display order Exceeding the Limit',
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );

            }
            else if (isAdmin === true && existdisplayorder) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg('Display Order already exist',
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );

            }
            else {
                if (row.id === 0) {
                    row.kpiDate = moment(MeetingDate).format('MM/DD/YYYY');
                }
                setloadingtrue();
                WZTNASHRAPI.AddPirData(row)
                    .then((result: number) => {
                        if (result === 2 && row.id === 0) {
                            toastIdRef.current = toast.error(
                                renderToastifyMsg("Error in adding to agenda, PIR" + row.pir + " already added to agenda",
                                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                            );
                            GetWeeklyIDSHRPostIncidentsData(moment(MeetingDate).format('MM/DD/YYYY'), 1);
                            GetWeeklyIDSHRPostIncidentsData(moment(MeetingDate).format('MM/DD/YYYY'), 4);
                        }
                        else if (result != -1) {
                            toastIdRef.current = toast.success(
                                renderToastifyMsg('PIR Data for service ' + row.serviceName + ' ' + Isrefresh + '  successfully',
                                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                            );
                            GetWeeklyIDSHRPostIncidentsData(moment(MeetingDate).format('MM/DD/YYYY'), 1);
                            GetWeeklyIDSHRPostIncidentsData(moment(MeetingDate).format('MM/DD/YYYY'), 4);
                        }
                        else {
                            setloadingfalse();
                            toastIdRef.current = toast.error(
                                renderToastifyMsg("Entered PIR " + row.pirEdit + " does not exist in IcM kusto DB",
                                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                            );
                        }
                    }).catch(error => {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Error while updating",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    });
            }
        }
    }
    //================= function to modify the Meeting Date for PIR =======================//
    const handlePIRDateChange = (row, KPIDate) => {
        setloadingtrue();
        WZTNASHRAPI.savePIRMeetingDate(row.id, moment(KPIDate).format('MM/DD/YYYY'), username)
            .then((result: number) => {
                if (result === -2) {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error in PIR!! PIR already present in Selected Meeting Date.",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
                else if (result === -3) {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error in Display Order!! Display Order already present in Selected Meeting Date. Change the Display Order and then Perform the Meeting Date Change Operation",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 4500 }
                    );
                }
                else if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg('PIR Data for service ' + row.serviceName + ' Updated successfully',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    GetWeeklyIDSHRPostIncidentsData(moment(MeetingDate).format('MM/DD/YYYY'), 1);
                    GetWeeklyIDSHRPostIncidentsData(moment(MeetingDate).format('MM/DD/YYYY'), 4);
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Entered PIR " + row.pirEdit + " does not exist in IcM kusto DB",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });

    }
    //================= function to delete the selected Row for PIR =======================//
    const DeletePIRData = (e, row) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            let pirId = null;
            if (isArrayWithLength(PIRRereviewData)) {
                PIRRereviewData.map((obj) => {
                    if (obj.pirDataID === row.id) {
                        pirId = obj;
                    }
                })

            }
            if (isArrayWithLength(PIRRereviewData) && pirId !== null) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg('Unable to delete PIR data as it is added for re-review',
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            }
            else {
                setloadingtrue();
                WZTNASHRAPI.DeletePirData(row.id, username)
                    .then((result: number) => {
                        if (result > 0) {
                            toastIdRef.current = toast.success(
                                renderToastifyMsg("PIR Data for service " + row.serviceName + " Deleted successfully",
                                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                            );
                            GetWeeklyIDSHRPostIncidentsData(moment(MeetingDate).format('MM/DD/YYYY'), 1);
                            GetWeeklyIDSHRPostIncidentsData(moment(MeetingDate).format('MM/DD/YYYY'), 4);
                        }

                    }).catch(error => {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Error while updating",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    });
            }
        }
    }
    //=============== function to enable Add new row for PIR ====================================//
    const EnableAddforPIRData = () => {
        EnableAddNewRowforPIR();
    }

    const EditLockforPIRData = () => {
        if (isAdmin) {
            EditLockforPIR();
        }
    }

    const EditLockforSpecificTopicData = () => {
        if (isAdmin) {
            EditLockforSpecificTopic();
        }
    }

    //============== finction to edit PIR Review (Checkbox onchange) for selected row ======================//
    const EditPirReviewData = (e, row) => {
        setloadingtrue();
        row.pirRereview = e.target.checked;
        row.kpiDate = moment(MeetingDate).format('MM/DD/YYYY');
        WZTNASHRAPI.EditPirReviewData(row)
            .then((result: number) => {
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg('Selected PIR Review Data Updated successfully',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    GetWeeklyIDSHRPostIncidentsData(moment(MeetingDate).format('MM/DD/YYYY'), 1);
                    GetWeeklyIDSHRPostIncidentsData(moment(MeetingDate).format('MM/DD/YYYY'), 5);
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error in updating PIR review data",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });
    }
 
    const validateLink = (link) => {
        var valid = false;
        var regex = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
        var m;
        m = regex.exec(link);
        if (m !== null) {
            valid = true;
        }
        return valid;
    };

    const validateTTN = (ttn) => {
        var valid = false;
        var regex = /^(?=.*[hmd]$)(?:\d+d\s*)?(?:\d+h\s*)?(?:\d+m\s*)?$/gm;
        var m;
        m = regex.exec(ttn);
        if (m !== null) {
            valid = true;
        }
        return valid;
    };
    const isValidDate = (str) => {
        // STRING FORMAT yyyy-mm-dd
        if (str == "" || str == null) { return false; }

        // m[1] is year 'YYYY' * m[2] is month 'MM' * m[3] is day 'DD'					
        var m = str.match(/(\d{2})(\/)(\d{2})(\/)(\d{4})/);

        // STR IS NOT FIT m IS NOT OBJECT
        if (m === null || typeof m !== 'object') { return false; }

        // CHECK m TYPE
        if (typeof m !== 'object' && m !== null && m.size !== 3) { return false; }

        var ret = true; //RETURN VALUE						
        var thisYear = 2100
        var minYear = 1999; //MIN YEAR

        // YEAR CHECK
        if ((m[5].length < 4) || m[5] < minYear || m[5] > thisYear) { ret = false; }
        // MONTH CHECK			
        if ((m[1].length < 2) || m[1] < 1 || m[1] > 12) { ret = false; }
        // DAY CHECK
        if ((m[3].length < 2) || m[3] < 1 || m[3] > 31) { ret = false; }

        return ret;
    };

    //**************************************************************************** functios Block for Special Topics ****************************************************************************//
    //================ function to enable the Edit for Special Topics Row =====================//
    const EditSpecialTopicsRows = (e, value) => {
        let val: number = value.id;
        EditSpecialTopicRow(val);
    }
    //==================function to Cancel the Edit for Special Topics Row ==================================//
    const cancelEditSpecialTopicRows = (e, value) => {
        if (window.confirm("Are you sure you want to Cancel this Edit?")) {
            let val: number = value.id;
            CancelEditSpecialTopicRow();
        }

    }
    //=======================function on textbox change while Editing arow in Special Topics==============//
    const Specialtopicvalueschange = (e, value) => {
        let val: number = value.id;
        OnPIRSpecialTopicChangevalues(e, val);
    }
    //================= function to delete the selected Row for Special Topics =======================//
    const DeleteSpecialTopicsRow = (e, row) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            setloadingtrue();
            WZTNASHRAPI.DeleteSpecialTopicsWishrData(row.id, username)
                .then((result: number) => {
                    if (result > 0) {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg("Special Topics Data for presenter " + row.presenter + " Deleted successfully",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                        GetWeeklyIDSHRPostIncidentsData(moment(MeetingDate).format('MM/DD/YYYY'), 3);
                    }

                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while deleting",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
    }
    //=============function to Save for Edit/Add   the  values for Commos only PIR====================//
    const SaveSpecialTopicData = (e, row) => {
        row.userName = username;
        var existdisplayorder = false;
        // Logic for Duplicate DisplayOrder
        if (isAdmin === true && (row.id == 0)) {
            let filteredData = SpecialTopicsData.filter(function (x) { return x.id !== -1; });
            let highestDisplayOrder = Math.max(...filteredData.map(o => o.displayOrderEdit));
            SpecialTopicsData.find(x => x.id == 0).displayOrderEdit = highestDisplayOrder + 1;
        }
        row.kpiDate = moment(MeetingDate).format('MM/DD/YYYY');
        if (isNullOrUndefined(row.presenterEdit) || !/\S/.test(row.presenterEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please enter the Presenter Details',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );

        }
        else if (isNullOrUndefined(row.topicNameEdit) || !/\S/.test(row.topicNameEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please enter the Topic Name',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );

        }
        else if (isNullOrUndefined(row.presentationLinkEdit) || !/\S/.test(row.presentationLinkEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please enter the Presentation Link',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );

        }
        else if (!validateLink(row.presentationLinkEdit)) {
            toastIdRef.current = toast.error(
                renderToastifyMsg("Please enter the valid Presentation Link",
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        else {
            if (row.presentationLinkEdit.toLowerCase().indexOf("http") === -1) {
                row.presentationLinkEdit = "https://" + row.presentationLinkEdit;
            }
            if (isNullOrUndefined(row.displayOrderEdit)) {
                row.displayOrderEdit = null;
            }
            else {
                row.displayOrderEdit = parseInt(row.displayOrderEdit);
            }
            setloadingtrue();
            let message = (row.id === 0) ? "special Topics Data for Presenter " + row.presenterEdit + " Saved successfully" : "Special Topics Data updated successfully";
            WZTNASHRAPI.AddorupdateSpecialTopicsWishrData(row)
                .then((result: number) => {
                    if (result != -1) {
                        GetWeeklyIDSHRPostIncidentsData(moment(MeetingDate).format('MM/DD/YYYY'), 3);
                        toastIdRef.current = toast.success(
                            renderToastifyMsg(message,
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                    else {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Error while adding the Special Topics Data",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while adding the Special Topics Data",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
    }
    //================= function to modify the Meeting Date for Special Topics =======================//
    const handleSpecialTopicsDateChange = (row, KPIDate) => {
        setloadingtrue();
        WZTNASHRAPI.saveSpecialTopicsMeetingDate(row.id, moment(KPIDate).format('MM/DD/YYYY'), username)
            .then((result: number) => {
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg("special Topics Data for Presenter " + row.presenter + " Updated successfully",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    GetWeeklyIDSHRPostIncidentsData(moment(MeetingDate).format('MM/DD/YYYY'), 3);
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while updating the Special Topics Data",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });

    }
    //=============== function to enable Add new row for PIR ====================================//
    const EnableAddNewforSpecialTopicsRow = () => {
        EnableAddNewRowforSpecialTopics();
    }
    //**************************************************************************** End Block for Special Topics ****************************************************************************//

    //============== function to accept only numbers for textbox keydown ==============================//
    const handleKeypress = (e) => {
        var isModifierkeyPressed = (e.metaKey || e.ctrlKey || e.shiftKey);
        var isCursorMoveOrDeleteAction = ([46, 8, 37, 38, 39, 40, 9].indexOf(e.keyCode) != -1);
        var isNumKeyPressed = (e.keyCode >= 48 && e.keyCode <= 58) || (e.keyCode >= 96 && e.keyCode <= 105);
        var vKey = 86, cKey = 67, aKey = 65;
        switch (true) {
            case isCursorMoveOrDeleteAction:
            case isModifierkeyPressed == false && isNumKeyPressed:
            case (e.metaKey || e.ctrlKey) && ([vKey, cKey, aKey].indexOf(e.keyCode) != -1):
                break;
            default:
                e.preventDefault();
        }
    }

    const createFunSpotLightTable = (tableName) => {
        const IDSHRDiv = document.querySelector("div.IDWSHRDiv") // Find the scoreboard div in our html
        while (IDSHRDiv.firstChild) IDSHRDiv.removeChild(IDSHRDiv.firstChild) // Remove all children from IDSHRs div (if any)
        let tableHeaders = [];

        if (tableName === 'funSpotLights') {
            tableHeaders = ["Topic Name", "Link to presentation"];
        }
        else if (tableName === 'publicServiceAnnouncement') {
            tableHeaders = ["Presenter", "Brief overview of the topic", "Optional Link"];
        }
        else if (tableName === 'actionItems') {
            tableHeaders = ["Presenter", "Task ID", "Title"];
        }
        else if (tableName === 'postIncidents') {
            tableHeaders = ["Presenter (must be GEM/EM)", "Owning Service", "Services Impacted", "Incident Id", "PIR Title", "Occurred in what Ring?", "TTD", "TTE", "TTM", "TTN", "Document Link", "PIR Number"];
        }
        else if (tableName === 'specialTopics') {
            tableHeaders = ["Presenter", "Topic Name", "Link to presentation"];
        }

        let IDSHRTable = document.createElement('table'); // Create the table itself
        IDSHRTable.setAttribute('id', 'IDSHRMainTable');
        IDSHRTable.className = 'IDSHRTable';
        let IDSHRTableHead = document.createElement('thead'); // Creates the table header group element
        IDSHRTableHead.className = 'IDSHRTable';
        let IDSHRTableHeaderRow = document.createElement('tr'); // Creates the row that will contain the headers
        IDSHRTableHeaderRow.className = 'IDSHRTableHeaderRow';
        // Will iterate over all the strings in the tableHeader array and will append the header cells to the table header row
        tableHeaders.forEach(header => {
            let scoreHeader = document.createElement('th') // Creates the current header cell during a specific iteration
            scoreHeader.innerText = header
            IDSHRTableHeaderRow.append(scoreHeader) // Appends the current header cell to the header row
        });
        IDSHRTableHead.append(IDSHRTableHeaderRow); // Appends the header row to the table header group element
        IDSHRTable.append(IDSHRTableHead);
        let IDSHRTableBody = document.createElement('tbody'); // Creates the table body group element
        IDSHRTableBody.className = "IDSHRTable-Body";
        IDSHRTable.append(IDSHRTableBody); // Appends the table body group element to the table
        IDSHRDiv.append(IDSHRTable); // Appends the table to the IDSHR div
    }
    // The function below will accept a single score and its index to create the global ranking
    const appendFunSpotLight = (singleWSHR, tableName) => {
        if (singleWSHR.id !== -1 && singleWSHR.id !== -2) {
            const IDSHRTable = document.querySelector('.IDSHRTable');// Find the table we created
            let IDSHRTableBodyRow = document.createElement('tr'); // Create the current table row
            IDSHRTableBodyRow.className = 'IDSHRTableBodyRow';

            if (tableName === 'funSpotLights') {
                // create the 3 column cells that will be appended to the current table row
                let topicNameData = document.createElement('td');
                topicNameData.innerText = singleWSHR.topicName;
                let presentationLinkData = document.createElement('td');
                presentationLinkData.innerText = singleWSHR.presentationLink;

                IDSHRTableBodyRow.append(topicNameData, presentationLinkData); // Append all 3 cells to the table row
            }
            else if (tableName === 'actionItems') {
                // create the 3 column cells that will be appended to the current table row
                let presenterData = document.createElement('td');
                presenterData.innerText = singleWSHR.presenter;

                let createTaskIdAnchor = document.createElement('a');
                createTaskIdAnchor.href = "https://identitydivision.visualstudio.com/DefaultCollection/Engineering/_workitems/edit/" + singleWSHR.id;
                createTaskIdAnchor.innerText = singleWSHR.id;

                let topicId = document.createElement('td');
                topicId.appendChild(createTaskIdAnchor);

                let topicNameData = document.createElement('td');
                topicNameData.innerText = singleWSHR.topicName;

                IDSHRTableBodyRow.append(presenterData, topicId, topicNameData); // Append all 3 cells to the table row
            }
            else if (tableName === 'postIncidents') {
                // create the 12 column cells that will be appended to the current table row
                let presenterData = document.createElement('td');
                presenterData.innerText = singleWSHR.presenter;

                let serviceName = document.createElement('td');
                serviceName.innerText = singleWSHR.serviceName;

                let servicesImpacted = document.createElement('td');
                servicesImpacted.innerText = singleWSHR.servicesImpacted;

                let createincidentIdAnchor = document.createElement('a');
                createincidentIdAnchor.href = "https://portal.microsofticm.com/imp/v3/incidents/details/" + singleWSHR.incidentId + "/home"
                createincidentIdAnchor.innerText = "ICM# " + singleWSHR.incidentId;

                let incidentId = document.createElement('td');
                incidentId.appendChild(createincidentIdAnchor);

                let summary = document.createElement('td');
                summary.innerText = singleWSHR.summary;

                let occuredRing = document.createElement('td');
                occuredRing.innerText = singleWSHR.occuredRing;

                let ttd = document.createElement('td');
                ttd.innerText = singleWSHR.ttd;

                let tte = document.createElement('td');
                tte.innerText = singleWSHR.tte;

                let ttm = document.createElement('td');
                ttm.innerText = singleWSHR.ttm;

                let ttn = document.createElement('td');
                ttn.innerText = singleWSHR.ttn;

                let createDescriptionAnchor = document.createElement('a');
                createDescriptionAnchor.href = singleWSHR.resources[0].link;
                createDescriptionAnchor.innerText = singleWSHR.resources[0].description;

                let description = document.createElement('td');
                description.appendChild(createDescriptionAnchor);

                let createPIRAnchor = document.createElement('a');
                createPIRAnchor.href = "https://portal.microsofticm.com/imp/v3/incidents/postmortem/" + singleWSHR.pir;
                createPIRAnchor.innerText = singleWSHR.pir;

                let pir = document.createElement('td');
                pir.appendChild(createPIRAnchor);

                IDSHRTableBodyRow.append(presenterData, serviceName, servicesImpacted, incidentId, summary, occuredRing, ttd, tte, ttm, ttn, description, pir); // Append all 3 cells to the table row

            }
            else if (tableName === 'specialTopics') {
                // create the 3 column cells that will be appended to the current table row
                let presenterData = document.createElement('td');
                presenterData.innerText = singleWSHR.presenter;
                let topicNameData = document.createElement('td');
                topicNameData.innerText = singleWSHR.topicName;
                let presentationLinkData = document.createElement('td');
                presentationLinkData.innerText = singleWSHR.presentationLink;

                IDSHRTableBodyRow.append(presenterData, topicNameData, presentationLinkData); // Append all 3 cells to the table row
            }
            else if (tableName === 'publicServiceAnnouncement') {
                // create the 3 column cells that will be appended to the current table row
                let presenterData = document.createElement('td');
                presenterData.innerText = singleWSHR.presenter;
                let topicNameData = document.createElement('td');
                topicNameData.innerText = singleWSHR.topicName;
                let presentationLinkData = document.createElement('td');
                presentationLinkData.innerText = singleWSHR.presentationLink;

                IDSHRTableBodyRow.append(presenterData, topicNameData, presentationLinkData); // Append all 3 cells to the table row
            }
            IDSHRTable.append(IDSHRTableBodyRow); // Append the current row to the IDSHR table body
        }
    }

    const CopyTableData = (tableName) => {
        createFunSpotLightTable(tableName); //Creates tables
        if (tableName === 'funSpotLights') {
            if (isArrayWithLength(FundamentalsSpotlightData)) {
                for (const item of FundamentalsSpotlightData) {
                    if (item.id != 0) {
                        appendFunSpotLight(item, tableName); // Creates and appends each row to the table body.
                    }
                }
            }
        }
        else if (tableName === 'actionItems') {
            if (isArrayWithLength(ActionItemsReviewData)) {
                for (const item of ActionItemsReviewData) {
                    if (item.id != 0) {
                        appendFunSpotLight(item, tableName); // Creates and appends each row to the table body.
                    }
                }
            }
        }
        else if (tableName === 'postIncidents') {
            if (isArrayWithLength(weeklyIDSHRData)) {
                for (const item of weeklyIDSHRData) {
                    if (item.id != 0) {
                        appendFunSpotLight(item, tableName); // Creates and appends each row to the table body.
                    }
                }
            }
        }
        else if (tableName === 'specialTopics') {
            if (isArrayWithLength(SpecialTopicsData)) {
                for (const item of SpecialTopicsData) {
                    if (item.id != 0) {
                        appendFunSpotLight(item, tableName); // Creates and appends each row to the table body.
                    }
                }
            }
        }

        else if (tableName === 'publicServiceAnnouncement') {
            if (isArrayWithLength(PublicServiceAnnouncementData)) {
                for (const item of PublicServiceAnnouncementData) {
                    if (item.id != 0) {
                        appendFunSpotLight(item, tableName); // Creates and appends each row to the table body.
                    }
                }
            }
        }
        var el = document.getElementById('IDSHRMainTable');
        // var el = document.getElementById('WeeklyIDSHRTableid');
        var body = document.body, range, sel;
        if (document.createRange && window.getSelection) {
            range = document.createRange();
            sel = window.getSelection();
            sel.removeAllRanges();
            try {
                range.selectNodeContents(el);
                sel.addRange(range);
            } catch (e) {
                range.selectNode(el);
                sel.addRange(range);
            }
            document.execCommand("copy");
            if (el) el.parentNode.removeChild(el);
        }
        if (!toast.isActive(toastIdRef.current)) {
            toastIdRef.current = toast.success(
                renderToastifyMsg('Table content copied to clipboard',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
            );
        }
    };

    if (isAuthenticated) {
        return (
            <div>
                <DeprecatedFunctionalityBanner />
                <LoadingOverlay active={isLoading}
                    spinner
                    text='Loading your content...'
                >
                    <section className='section'>
                        <div className='container'>
                            <WZTNASHRDataTable
                                MeetingDate={MeetingDate}
                                handleMeetingDateChange={handleMeetingDateChange}
                                MaxDate={MaxDate}
                            />
                            <br />
                            <Row>
                                <Col lg="12">
                                    <h5 className="headertext">Livesite Dashboard Review (10 minutes):</h5>
                                    <a href="https://aka.ms/IDLivesite-Review" target="_blank">Report</a> for review of Repair Items, IcM Trends, DRI Health, etc.
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col lg="12">
                                    <ActionItemsReview
                                        ActionItemsReviewData={ActionItemsReviewData}
                                        isAdmin={isAdmin}
                                        CopyTableData={CopyTableData}
                                        accordiontoggle={accordiontoggle}
                                        setaccordiontoggledata={(accordiontoggle) => setaccordiontoggledata(accordiontoggle)}
                                    />
                                </Col>
                            </Row>
                            <br /> <br />
                            <Row>
                                <Col lg="12">
                                    <PublicServiceAnnouncement
                                        PublicServiceAnnouncementData={PublicServiceAnnouncementData}
                                        handleKeypress={handleKeypress}
                                        EditPubServiceAccRows={(e, row) => EditPubServiceAccRows(e, row)}
                                        DeletePubServiceAccRow={(e, row) => DeletePubServiceAccRow(e, row)}
                                        cancelEditPubServiceAccRows={(e, row) => cancelEditPubServiceAccRows(e, row)}
                                        PubServiceAccvalueschange={(e, row) => PubServiceAccvalueschange(e, row)}
                                        isPubServiceAccEditorAddEnabled={isPubServiceAccEditorAddEnabled}
                                        SavePubServiceAccData={(e, row) => SavePubServiceAccData(e, row)}
                                        EnableAddNewforPubServiceAccRow={EnableAddNewforPubServiceAccRow}
                                        setshowaddrecurrence={(e, row) => openaddrecurrencepopup(e, row)}
                                        deleterecurrence={(e, row) => deleterecurrence(e, row)}
                                        DefaultDate={moment(DefaultDate).format('MM/DD/YYYY')}
                                        MeetingDate={moment(MeetingDate).format('MM/DD/YYYY')}
                                        PubServiceAccDate={MeetingDate}
                                        MaxDate={MaxDate}
                                        IsPirRereviewUser={IsPirRereviewUser}
                                        handlePubServiceAccDateChange={handlePubServiceAccDateChange}
                                        isAdmin={isAdmin}
                                        isLocked={isLockedPubServiceAcc}
                                        EditLockforPubServiceAcc={EditLockforPubServiceAccData}
                                        UpdatePubServiceAccDataOrder={UpdatePubServiceAccDataOrder}
                                        CopyTableData={CopyTableData}
                                        isPSALockDisabledOnEdit={isPSALockDisabledOnEdit}
                                        psaaccordiontoggle={psaaccordiontoggle}
                                        setpsaccordiontoggledata={(psaaccordiontoggle) => setpsaccordiontoggledata(psaaccordiontoggle)}
                                    />
                                </Col>
                            </Row>
                            <br /> <br />
                            <Row>
                                <Col lg="12">
                                    <FundamentalsSpotlight
                                        FundamentalsSpotlightData={FundamentalsSpotlightData}
                                        isAdmin={isAdmin}
                                        isLocked={isLockedFundamentalsSpotlight}
                                        EditLockforFundamentalsSpotlight={EditLockforFundamentalsSpotlight}
                                        EnableAddforFundamentalsSpotlight={EnableAddNewforFundamentalsSpotlightRow}
                                        FundamentalsSpotlightDate={MeetingDate}
                                        MaxDate={MaxDate}
                                        MeetingDate={MeetingDate}
                                        FundamentalsSpotlightvalueschange={FundamentalsSpotlightvalueschange}
                                        handleKeypress={handleKeypress}
                                        isFundamentalsSpotlightEditorAddEnabled={isFundamentalsSpotlightEditorAddEnabled}
                                        EditFundamentalsSpotlightRows={(e, row) => EditFundamentalsSpotlightRows(e, row)}
                                        DeleteFundamentalsSpotlightRow={(e, row) => DeleteFundamentalsSpotlightRow(e, row)}
                                        SaveFundamentalsSpotlightData={(e, row) => SaveFundamentalsSpotlightData(e, row)}
                                        cancelEditFundamentalsSpotlightRows={(e, row) => cancelEditFundamentalsSpotlightRows(e, row)}
                                        handleFundamentalsSpotlightDateChange={handleFundamentalsSpotlightDateChange}
                                        UpdateFundamentalsSpotlightOrder={UpdateFundamentalsSpotlightOrder}
                                        CopyTableData={CopyTableData}
                                        isFunLockDisabledOnEdit={isFunLockDisabledOnEdit}
                                        funaccordiontoggle={funaccordiontoggle}
                                        setfunaccordiontoggledata={(funaccordiontoggle) => setfunaccordiontoggledata(funaccordiontoggle)}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col lg="12">
                                    <SpecialTopics
                                        SpecialTopicsData={SpecialTopicsData}
                                        handleKeypress={handleKeypress}
                                        EditSpecialTopicsRows={(e, row) => EditSpecialTopicsRows(e, row)}
                                        DeleteSpecialTopicsRow={(e, row) => DeleteSpecialTopicsRow(e, row)}
                                        cancelEditSpecialTopicRows={(e, row) => cancelEditSpecialTopicRows(e, row)}
                                        Specialtopicvalueschange={(e, row) => Specialtopicvalueschange(e, row)}
                                        isSpecialTopicEditorAddEnabled={isSpecialTopicEditorAddEnabled}
                                        SaveSpecialTopicData={(e, row) => SaveSpecialTopicData(e, row)}
                                        EnableAddNewforSpecialTopicsRow={EnableAddNewforSpecialTopicsRow}
                                        setshowaddrecurrence={(e, row) => openaddrecurrencepopup(e, row)}
                                        deleterecurrence={(e, row) => deleterecurrence(e, row)}
                                        DefaultDate={moment(DefaultDate).format('MM/DD/YYYY')}
                                        MeetingDate={moment(MeetingDate).format('MM/DD/YYYY')}
                                        SpecialTopicsDate={MeetingDate}
                                        MaxDate={MaxDate}
                                        IsPirRereviewUser={IsPirRereviewUser}
                                        handleSpecialTopicsDateChange={handleSpecialTopicsDateChange}
                                        isAdmin={isAdmin}
                                        isLocked={isLockedTopic}
                                        EditLockforSpecificTopic={EditLockforSpecificTopicData}
                                        UpdateSpecialTopicsDataOrder={UpdateSpecialTopicsDataOrder}
                                        CopyTableData={CopyTableData}
                                        isSpecialTopicLockDisabledOnEdit={isSpecialTopicLockDisabledOnEdit}
                                        specialaccordiontoggle={specialaccordiontoggle}
                                        setspecialaccordiontoggledata={(specialaccordiontoggle) => setspecialaccordiontoggledata(specialaccordiontoggle)}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col lg="12">
                                    <PostIncidentReviews
                                        weeklyIDSHRData={weeklyIDSHRData}
                                        valchange={(e, value) => EditPIRRows(e, value)}
                                        CancelEditPIRRow={(e, value) => CancelEditPIR(e, value)}
                                        OnPIRTextboxchange={(e, value) => OnPIRTextboxchange(e, value)}
                                        SavePostIncidentData={(e, row, Isrefresh) => SavePostIncidentData(e, row, Isrefresh)}
                                        DeletePIRData={(e, row) => DeletePIRData(e, row)}
                                        EnableAddforPIRData={EnableAddforPIRData}
                                        EditPirReviewData={(e, row) => EditPirReviewData(e, row)}
                                        isPIREditenabled={isPIREditenabled}
                                        handleKeypress={handleKeypress}
                                        PIRDate={MeetingDate}
                                        MaxDate={MaxDate}
                                        MeetingDate={MeetingDate}
                                        handlePIRDateChange={handlePIRDateChange}
                                        isAdmin={isAdmin}
                                        IsPirRereviewUser={IsPirRereviewUser}
                                        isLocked={isLockedPIR}
                                        EditLockforPIR={EditLockforPIRData}
                                        UpdateweeklyIDSHRDataOrder={UpdateweeklyIDSHRDataOrder}
                                        CopyTableData={CopyTableData}
                                        isPIRLockDisabledOnEdit={isPIRLockDisabledOnEdit}
                                        piraccordiontoggle={piraccordiontoggle}
                                        setpiraccordiontoggledata={(piraccordiontoggle) => setpiraccordiontoggledata(piraccordiontoggle)}
                                    />
                                </Col>
                            </Row>
                            <br /> <br />
                            <br /> <br />
                            <div className='IDWSHRDiv'></div>
                        </div>
                    </section>
                </LoadingOverlay>
                <Modal show={showaddrecurrence} onHide={closeshowaddrecurrence} size='lg' backdrop='static' >

                    <Modal.Header closeButton>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <b>Add Recurrence</b>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row style={{ paddingTop: '15px' }}>
                            <Col lg='2'>Recurrence No.</Col>
                            <Col lg='5'>
                                <input type="text" value={recurrenceno} onChange={changerecurrenceno} className="form-control" />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <div style={{ width: '100%' }}>
                            <Row>
                                <Col lg='9'></Col>
                                <Col lg='3'>
                                    <Button className='DailydataModelbuttons' disabled={Disablesubmitbutton} onClick={saveRecurrence}>Submit</Button>
                                </Col>

                            </Row>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>


        );
    }
    else {
        return (
            <div></div>
        );
    }
};

const mapStateToProps = (state: IApplicationState) => ({
    ...state.WZTNASHR,
    isAuthenticated: state.authUser.isAuthenticated,
    username: state.authUser.login,
    isAdmin: state.authUser.isAdmin
})

export default connect(mapStateToProps, actionCreators)(WZTNASHRData);



