
export type IPropIdentityObjectives = {
    readonly objectiveID: number;
    readonly name: string;
    readonly startDate: string;
    readonly endDate: string;
    readonly status: string;
    readonly notes: string;
    readonly isActive: boolean;
    readonly createdBy: string;
    readonly createdDate: string;
    readonly updatedBy: string;
    readonly updatedDate: string;
};

export type IPropIdentityKeyResults = {
    readonly keyResultID: number;
    readonly objectiveID: number;
    readonly keyResults: string;
    keyResultsEdit: string;
    readonly weekDate: string;
    readonly weekId: number;
    readonly targetValue: string;
    targetValueEdit: string;
    readonly progressValue: string;
    progressValueEdit: string;
    readonly notes: string;
    readonly isActive: boolean;
    readonly createdBy: string;
    readonly createdDate: string;
    readonly updatedBy: string;
    readonly updatedDate: string;
    isEdit: boolean;
};

export type OKRObjectiveDropdownOption = {
    name: string;
    value: any;
};

export type IdentityWeeklyNewsLetterOKRState = {
    readonly newsLetterDivisionalObjectivesData: IPropIdentityObjectives[];
    newsLetterOKRsObjectives: any;
    selectedObjective: OKRObjectiveDropdownOption;
    readonly newsLetterDivisionalKeyResultsData: IPropIdentityKeyResults[];
    selectedKeyResults: any;
    readonly isLoading: boolean;
    readonly ObjectiveStartDate: string;
    readonly ObjectiveEndDate: string;
    WeekStartDate: string;
    newObjective: string;
    isAddNewObjective: boolean;
};

const _namespace = 'IDENTITYWEEKLYNEWSLETTEROKRS';
export interface IActionType {
    readonly RESET_STATE: string;
    readonly GET_IDENTITYNEWSLETTERDIVSIONALOKRS_REQUEST: string;
    readonly GET_IDENTITYNEWSLETTERDIVSIONALOKRS_SUCCESS: string;
    readonly GET_IDENTITYNEWSLETTERDIVSIONALOKRS_FAILURE: string;
    readonly IDENTITYNEWSLETTER_OKROBJECTIVE_CHANGE: string;
    readonly IDENTITYNEWSLETTER_OBJECTIVES_STARTDATE_CHANGE: string;
    readonly IDENTITYNEWSLETTER_OBJECTIVES_ENDDATE_CHANGE: string;
    readonly IDENTITYWEEKLY_OKRS_START_DATE_CHANGE: string;
    readonly IDENTITYNEWSLETTER_OBJECTIVE_ADD: string;
    readonly NEWSLETTER_KEYRESULTS_CONTROLS_CHANGE: string;
    readonly GET_IDENTITYNEWSLETTERKEYRESULTS_REQUEST: string;
    readonly GET_IDENTITYNEWSLETTERKEYRESULTS_SUCCESS: string;
    readonly GET_IDENTITYNEWSLETTERKEYRESULTS_FAILURE: string;
    readonly GET_IDENTITYNEWSLETTEROBJECTIVES_REQUEST: string;
    readonly GET_IDENTITYNEWSLETTEROBJECTIVES_SUCCESS: string;
    readonly GET_IDENTITYNEWSLETTEROBJECTIVES_FAILURE: string;
    readonly NEWSLETTER_OBJECTIVES_CANCEL_CHANGE: string;
    readonly SET_LOADING_TRUE: string;
    readonly SET_LOADING_FALSE: string;
    readonly ADDED_VISITED_USER: string;
    readonly USER_CHECK: string;
}

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/identitynewsletterreset`,
    GET_IDENTITYNEWSLETTERDIVSIONALOKRS_REQUEST: `${_namespace}/getidentityweeklynewsletterdivisionalobjectivesrequest`,
    GET_IDENTITYNEWSLETTERDIVSIONALOKRS_SUCCESS: `${_namespace}/getidentityweeklynewsletterdivisionalobjectivesdatasuccess`,
    GET_IDENTITYNEWSLETTERDIVSIONALOKRS_FAILURE: `${_namespace}/getidentityweeklynewsletterdivisionalobjectivesdatafailure`,
    IDENTITYNEWSLETTER_OKROBJECTIVE_CHANGE: `${_namespace}/identitynewsletterokrobjectivechange`,
    IDENTITYNEWSLETTER_OBJECTIVES_STARTDATE_CHANGE: `${_namespace}/identitynewsletterokrobjectivestartdatechange`,
    IDENTITYNEWSLETTER_OBJECTIVES_ENDDATE_CHANGE: `${_namespace}/identitynewsletterokrobjectiveenddatechange`,
    IDENTITYWEEKLY_OKRS_START_DATE_CHANGE: `${_namespace}/identitynewsletterokrobjectiveweekstartdatechange`,
    IDENTITYNEWSLETTER_OBJECTIVE_ADD: `${_namespace}/identitynewsletterokrobjectiveadd`,
    NEWSLETTER_KEYRESULTS_CONTROLS_CHANGE: `${_namespace}/newsletterkeyresultscontrolschange`,
    GET_IDENTITYNEWSLETTERKEYRESULTS_REQUEST: `${_namespace}/getidentitynewsletterkeyresultsrequest`,
    GET_IDENTITYNEWSLETTERKEYRESULTS_SUCCESS: `${_namespace}/getidentitynewsletterkeyresultssuccess`,
    GET_IDENTITYNEWSLETTERKEYRESULTS_FAILURE: `${_namespace}/getidentitynewsletterkeyresultsfailure`,
    GET_IDENTITYNEWSLETTEROBJECTIVES_REQUEST: `${_namespace}/getidentitynewsletterobjectivesrequest`,
    GET_IDENTITYNEWSLETTEROBJECTIVES_SUCCESS: `${_namespace}/getidentitynewsletterobjectivessuccess`,
    GET_IDENTITYNEWSLETTEROBJECTIVES_FAILURE: `${_namespace}/getidentitynewsletterobjectivesfailure`,
    NEWSLETTER_OBJECTIVES_CANCEL_CHANGE: `${_namespace}/newsletterobjectivescancelchange`,
    SET_LOADING_TRUE: `${_namespace}/loadingtrue`,
    SET_LOADING_FALSE: `${_namespace}/loadingfalse`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    USER_CHECK: `${_namespace}/UserCheck`
});

