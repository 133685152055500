import { IServiceGroup } from '../home-tiles';
import {
    IAllViewDetails, IDailyData, UniqueDateHeaders, UniqueFixedHeaderColumns, IMSERAdditionalInfo, IViewDetails,
    IComments, IColorScheme} from '../service-health/types';
export interface IActionType {
    readonly RESET_STATE: string;
    readonly ADDED_VISITED_USER: string;
    readonly GET_MSER_HEATMAP_SERVICES_REQUEST: string;
    readonly GET_MSER_HEATMAP_SERVICES_SUCCESS: string;
    readonly GET_MSER_HEATMAP_SERVICES_FAIL: string;
    readonly GET_MSER_HEATMAP_VIEWS_SUCCESS: string;
    readonly GET_MSER_HEATMAP_VIEWS_FAIL: string;
    readonly START_DATE_CHANGE: string;
    readonly END_DATE_CHANGE: string;
    readonly CHECK_PRESENTATIONVIEW_CHECK: string;
    readonly MSER_HEATMAPDATA_FETCH: string;
    readonly MSER_HEATMAPDATA_RECEIVE: string;
    readonly MSER_HEATMAPDATA_FAIL: string;
    readonly DAILY_FILTER_YELLOW: string;
    readonly DAILY_FILTER_RED: string;
    readonly SET_MSERCOMMENTS: string;
    readonly SET_LOADING: string;
    readonly LOAD_VIEWS: string;
}



export type IMSERHeatmapPreviewState = {
    readonly isLoading: boolean;
    readonly MSERHeatmapServicesData: IServiceGroup[];
    readonly MSERHeatmapViews: IAllViewDetails[];
    readonly currentMSERVIEW: IAllViewDetails;
    readonly currentService: string;
    readonly currentView: string;
    readonly currentindex: number;
    readonly lastindex: number;
    readonly startDate: Date;
    readonly endDate: Date;
    checkedFilterYellowDailyData: boolean;
    checkedFilterRedDailyData: boolean;
    showMSERComments: boolean;
    checkPresentationView: boolean;
    chkpresentationviewcheck: boolean;
    readonly HeatmapdailyData: IDailyData[];
    readonly uniqueDateHeaders: UniqueDateHeaders[];
    readonly uniqueFixedHeaderColumns: UniqueFixedHeaderColumns[];
    readonly datacentersList: [];
    readonly additionalInfoData: IMSERAdditionalInfo[];
    viewdetailsdata: IViewDetails[];
    readonly SelectedView: IAllViewDetails[];
    readonly comments: IComments[];
    readonly colorScheme: IColorScheme[];
    readonly IsdataLoaded: boolean;
    readonly volumeMeasure: string;
    readonly failurevolumeMeasure: string;
    readonly Isviewsloaded: boolean;
    readonly dcMaxLength: number;
};

const _namespace = 'MSERHeatmapPreview ';

export const ActionType = Object.freeze<IActionType>({  
    RESET_STATE: `${_namespace}/reset`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    GET_MSER_HEATMAP_SERVICES_REQUEST: `${_namespace}/getmserheatmapservicesrequest`,
    GET_MSER_HEATMAP_SERVICES_SUCCESS: `${_namespace}/getmserheatmapservicessuccess`,
    GET_MSER_HEATMAP_SERVICES_FAIL: `${_namespace}/getmserheatmapservicesfail`,
    GET_MSER_HEATMAP_VIEWS_SUCCESS: `${_namespace}/getmserheatmapviewssuccess`,
    GET_MSER_HEATMAP_VIEWS_FAIL: `${_namespace}/getmserheatmapviewsfail`,
    START_DATE_CHANGE: `${_namespace}/startDateChange`,
    END_DATE_CHANGE: `${_namespace}/endDateChange`,
    CHECK_PRESENTATIONVIEW_CHECK: `${_namespace}/checkpresentationviewcheck`,
    MSER_HEATMAPDATA_FETCH: `${_namespace}/mserheatmapdatafetch`,
    MSER_HEATMAPDATA_RECEIVE: `${_namespace}/mserheatmapdatareceive`,
    MSER_HEATMAPDATA_FAIL: `${_namespace}/mserheatmapdatafail`,
    DAILY_FILTER_YELLOW: `${_namespace}/dailyfilteryellow`,
    DAILY_FILTER_RED: `${_namespace}/dailyfilterred`,
    SET_MSERCOMMENTS: `${_namespace}/sermsercomments`,
    SET_LOADING: `${_namespace}/setloading`,
    LOAD_VIEWS: `${_namespace}/loadviews`,
});

