import React, { useState, useRef, Fragment } from 'react';
import { Button, Accordion } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import {
    Row,
    Col
} from 'reactstrap';

type HeatmapConfigurationGuideProps = {
    hideheatMapConfigUserGuideData
};

const HeatmapConfigurationGuide = React.memo<HeatmapConfigurationGuideProps>(({
    hideheatMapConfigUserGuideData

}) => {

    const [AggVolHighlightsAlgOpen, setAggVolHighlightsAlgOpen] = useState(false);
    const [AggVolumeAlgOpen, setAggVolumeAlgOpen] = useState(false);
    const [DailyVolumeAlgOpen, setDailyVolumeAlgOpen] = useState(false);

    const expandCollapse = (algorithmOption) => {
        if (algorithmOption == 'AggVolumeHighlights') {
            setAggVolHighlightsAlgOpen(!AggVolHighlightsAlgOpen);
        }
        else if (algorithmOption == 'AggVolume') {
            setAggVolumeAlgOpen(!AggVolumeAlgOpen);
        } 
        else if (algorithmOption == 'DailyVolume') {
            setDailyVolumeAlgOpen(!DailyVolumeAlgOpen);
        }
    }

    return (
        <div style={{ width: '100%' }}>
            <br />

            <div style={{ width: '100%' }}>
                <Row>
                    <Col lg='2'>
                        <Button className='DailydataModelbuttons' onClick={hideheatMapConfigUserGuideData} >Back to Daily</Button>
                    </Col>
                    <Col lg='10'>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                            <div>
                            <h4 style={{ color: 'darkblue' }}>Available coloring logics</h4>
                            <br />
                            <Accordion>
                            <div className='Content'>
                                    <ul>
                                        <li>a. Aggregate volume  <br />
                                            <div>
                                          &emsp;Aggregate the volume (based on selected date range) and calculate coloring rules dynamically based on aggregated volume<Accordion.Toggle className="btnUserGuide btnStyle btn-link" eventKey="0" onClick={() => expandCollapse('AggVolume')}>(Algorithm&nbsp;{AggVolumeAlgOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />})
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="0">
                                                <div>
                                                    <b><span style={{ color: 'darkblue' }}>&emsp;(Aggregate Volume) Algorithm 1: </span>Ability to “scale” the coloring rules based on volume.</b> Let’s assume we have the following color scheme:
                                                    <div className='Content'>
                                                        <ul>
                                                            <li>&emsp;&emsp;a. X {'>='} 99.99, color red</li>
                                                            <li>&emsp;&emsp;b. 99.99 {'>'} X {'>'} 99.9, color yellow</li>
                                                            <li>&emsp;&emsp;c. 99.9 {'>'} X, color red</li>
                                                            <li>&emsp;&emsp;d. Before you apply every color rule (for every cell), you “scale” the parameters of that rule (val1_min, val2_min, val1_max, val2_max). Scaling is done based on an input parameter, F, using the formulas:</li>
                                                            <li>&emsp;&emsp;e. Val1_min = (100 – val1_min) *F</li>
                                                            <li>&emsp;&emsp;f. Val2_min = (100 – val2_min) *F</li>
                                                            <li>&emsp;&emsp;g. Val1_max = (100 – val1_max) *F</li>
                                                            <li>&emsp;&emsp;h. Val2_max = (100 – val2_max) *F</li>
                                                            <li>&emsp;&emsp;i. The parameter F is calculated before being passed in to the “scale” function in the following way:</li>
                                                            <li>&emsp;&emsp;j. Float GetScalingFactor (float rowVolume) {'{'} if (volume {'<'} 10000) return 100 else if (volume {'<'} 100000 return 10 else return 1{'}'} </li>
                                                            <li>&emsp;&emsp;k. Where rowVolume is calculated in the following way: rowVolume = sum(vol_i); where vol_i is the volume for every day shown in that row </li>
                                                            <li>&emsp;&emsp;l. End to end example of how this works: </li>
                                                            <li>&emsp;&emsp;m. Let’s say we are showing data for 5 days, and we have the following values: </li>
                                                            <li>&emsp;&emsp;
                                                            <Row>
                                                                    <Col lg='1'></Col>
                                                                    <Col lg='5'>
                                                                        <table className="tblPIR tblborder">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{ textAlign: "left" }}>key</th>
                                                                                    <th style={{ textAlign: "left" }}>day</th>
                                                                                    <th style={{ textAlign: "left" }}>volume</th>
                                                                                    <th style={{ textAlign: "left" }}>val1</th>
                                                                                    <th style={{ textAlign: "left" }}>val2</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>WST</td>
                                                                                    <td>2/1</td>
                                                                                    <td>10000</td>
                                                                                    <td>99.91</td>
                                                                                    <td>NULL</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>WST</td>
                                                                                    <td>2/2</td>
                                                                                    <td>10000</td>
                                                                                    <td>99.93</td>
                                                                                    <td>NULL</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>WST</td>
                                                                                    <td>2/3</td>
                                                                                    <td>15000</td>
                                                                                    <td>99.94</td>
                                                                                    <td>NULL</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>WST</td>
                                                                                    <td>2/4</td>
                                                                                    <td>12000</td>
                                                                                    <td>99.92</td>
                                                                                    <td>NULL</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>WST</td>
                                                                                    <td>2/5</td>
                                                                                    <td>12000</td>
                                                                                    <td>99.97</td>
                                                                                    <td>NULL</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </Col>
                                                                    <Col lg='6'></Col>
                                                                    </Row>
                                                                    <br/>
                                                            </li>
                                                            <li>&emsp;&emsp;n. Let’s say we have the following color scheme:</li>
                                                            <li>&emsp;&emsp;
                                                            <Row>
                                                                    <Col lg='1'></Col>
                                                                    <Col lg='5'>
                                                                        <table className="tblPIR tblborder">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{ textAlign: "left" }}>val1_min </th>
                                                                                    <th style={{ textAlign: "left" }}>val1_max </th>
                                                                                    <th style={{ textAlign: "left" }}>val2_min </th>
                                                                                    <th style={{ textAlign: "left" }}>val2_max </th>
                                                                                    <th style={{ textAlign: "left" }}>color</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>99.99</td>
                                                                                    <td>NULL</td>
                                                                                    <td>NULL</td>
                                                                                    <td>NULL</td>
                                                                                    <td>green</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>99.9</td>
                                                                                    <td>99.99</td>
                                                                                    <td>NULL</td>
                                                                                    <td>NULL</td>
                                                                                    <td>yellow</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>NULL</td>
                                                                                    <td>99.9</td>
                                                                                    <td>NULL</td>
                                                                                    <td>NULL</td>
                                                                                    <td>red</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </Col>
                                                                    <Col lg='6'></Col>
                                                                    </Row>
                                                                    <br />
                                                            </li>
                                                            <li>&emsp;&emsp;o. Steps</li>
                                                            <li>
                                                                <div className='Content'>
                                                                    <ul>
                                                                        <li>&emsp;&emsp;&emsp;1. We calculate the volume for the period we’re showing for WST row (rowVolume = 59000) </li>
                                                                        <li>&emsp;&emsp;&emsp;2. The corresponding scaling factor based on rowVolume = 59000 is F = 10 </li>
                                                                        <li>&emsp;&emsp;&emsp;3. For every cell in the row we are showing, we apply the “scaled” rules, which become, based on F:</li>
                                                                        <li>&emsp;&emsp;
                                                                    <Row>
                                                                                <Col lg='1'></Col>
                                                                                <Col lg='5'>
                                                                                    <table className="tblPIR tblborder">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th style={{ textAlign: "left" }}>val1_min </th>
                                                                                                <th style={{ textAlign: "left" }}>val1_max </th>
                                                                                                <th style={{ textAlign: "left" }}>val2_min </th>
                                                                                                <th style={{ textAlign: "left" }}>val2_max </th>
                                                                                                <th style={{ textAlign: "left" }}>color</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>99.99</td>
                                                                                                <td>NULL</td>
                                                                                                <td>NULL</td>
                                                                                                <td>NULL</td>
                                                                                                <td>green</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>99</td>
                                                                                                <td>99.9</td>
                                                                                                <td>NULL</td>
                                                                                                <td>NULL</td>
                                                                                                <td>yellow</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>NULL</td>
                                                                                                <td>99</td>
                                                                                                <td>NULL</td>
                                                                                                <td>NULL</td>
                                                                                                <td>red</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Col>
                                                                                <Col lg='6'></Col>
                                                                                </Row>
                                                                                <br />
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                </li>
                                                                <li>&emsp;&emsp;p. We will therefore render the row as (incl. the new avg. metric column) </li>
                                                                <li>
                                                                    <br/>
                                                                    <Row>
                                                                        <Col lg='1'></Col>
                                                                        <Col lg='6'>
                                                                            <table className="tblPIR tblborder">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th style={{ textAlign: "left" }}>key </th>
                                                                                        <th style={{ textAlign: "left" }}>volume(K) </th>
                                                                                        <th style={{ textAlign: "left" }}>avg. </th>
                                                                                        <th style={{ textAlign: "left" }}>2/1</th>
                                                                                        <th style={{ textAlign: "left" }}>2/2</th>
                                                                                        <th style={{ textAlign: "left" }}>2/3</th>
                                                                                        <th style={{ textAlign: "left" }}>2/4</th>
                                                                                        <th style={{ textAlign: "left" }}>2/5</th>

                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>WST</td>
                                                                                        <td>59</td>
                                                                                        <td>99.93/green</td>
                                                                                        <td>99.91/green</td>
                                                                                        <td>99.93/green</td>
                                                                                        <td>99.94/green</td>
                                                                                        <td>99.92/green</td>
                                                                                        <td>99.97/green</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </Col>
                                                                        <Col lg='5'></Col>
                                                                    </Row>
                                                                    <br />
                                                                </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                </Accordion.Collapse>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Accordion>
                            <Accordion>
                                <div className='Content'>
                                    <ul>
                                        <li>b. Daily Volume  <br />
                                            <div>
                                              &emsp;Calculate the coloring rules dynamically for each cell based on volume (for each day and data center)<Accordion.Toggle className="btnUserGuide btnStyle btn-link" eventKey="0" onClick={() => expandCollapse('DailyVolume')}>(Algorithm&nbsp;{DailyVolumeAlgOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />})
                                            </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <div>
                                                        <b><span style={{ color: 'darkblue' }}>&emsp;(Daily Volume) Algorithm 2: </span></b>
                                                        <div className='Content'>
                                                        <ul>
                                                                <li>&emsp;&emsp;a. The rules are recalculated for every cell</li>
                                                                <li>&emsp;&emsp;b. The “volume” in every cell determines F (so you call GetScalingFactor for every cell with the volume from that cell)</li>
                                                                <li>&emsp;&emsp;c. The coloring rules are applied to the cell using F calculated for that cell</li>
                                                        </ul>
                                                        </div>
                                                    </div>
                                                </Accordion.Collapse>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Accordion>
                            <Accordion>
                                <div className='Content'>
                                    <ul>
                                        <li>c. Aggregate volume w/ highlights <br />
                                            <div>
                                                &emsp;We calculate both Aggregate Volume and Daily Volume for each row and based on Agg. for that datacenter, we apply either Aggregate volume or daily volume coloring rules to the row<Accordion.Toggle className="btnUserGuide btnStyle btn-link" eventKey="0" onClick={() => expandCollapse('AggVolumeHighlights')}>(Algorithm&nbsp;&nbsp;{AggVolHighlightsAlgOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />})
                                            </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <div>
                                                        <b><span style={{ color: 'darkblue' }}>&emsp;(Aggregate volume w/ highlights) Algorithm 3: </span></b>
                                                        <div className='Content'>
                                                            <ul>
                                                                <li>&emsp;&emsp;a. Processing is done row by row rather than cell by cell.</li>
                                                                <li>&emsp;&emsp;b. For every row, do the following: <br />
                                                                    &emsp;&emsp;&emsp;1: Run Algorithm 1 to get A1 = array of (color, meetsGoal) tuples for all the cells in the row, including “Agg.” Cell <br />
                                                                    &emsp;&emsp;&emsp;2: Run Algorithm 2 to get A2 = array of (color, meetsGoal) tuples for all the cells in the row, including “Agg.” Cell < br />
                                                                    &emsp;&emsp;&emsp;3. If for “Agg.” cell meetsGoal == 1 in A1 array, then apply color array A2 to the row <br />
                                                                    &emsp;&emsp;&emsp;4. If for “Agg.” cell meetsGoal == 0 in <b>A1 array</b>: <br />
                                                                        &emsp;&emsp;&emsp;Count in <b>A2 array</b> the number of cells for which meetsGoal == 0, excluding “Agg.” cell.  <br />
                                                                        &emsp;&emsp;&emsp;&emsp; a. If the count is {'>'} 0 (we have at least one yellow/red cell even with the relaxed rule), then apply <b>color array A2 to the row</b> (we want to highlight the outliers)  <br />
                                                                        &emsp;&emsp;&emsp;&emsp; b. If the count is == 0 (no yellow/red cell with the relaxed rule, but the aggregate is still yellow/red), then apply <b>color array A1 to the row</b> (we want to highlight a systemic problem) <br />
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </Accordion.Collapse>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Accordion>
                            <div className='Content'>
                                <p>We get f factor based on the volume </p>
                                <ul style={{ listStyleType: 'circle', paddingLeft: '25px' }}>
                                    <li>When volume {'>'} 0 and volume {'<'} 1000 then factor is 1000</li>
                                    <li>When volume {'>'} 0 and volume {'<'} 10000 then factor is 100 </li>
                                    <li>When volume {'>'} 0 and volume {'<'} 100000 then factor is 10 </li>
                                    <li>Else factor is 1 </li>
                                </ul>
                                <p>Based on the F factor we display no. of decimals and calculate coloring rules dynamically (aggregate volume and daily volume coloring logic algorithms) </p>
                                <ul style={{ listStyleType: 'circle', paddingLeft: '25px' }}>
                                    <li>F = 1, display 3 decimals </li>
                                    <li>F = 10, display 2 decimals </li>
                                    <li>F = 100, display 1 decimal </li>
                                    <li>F = 1000, display 0 decimals </li>
                                </ul>
                            </div>
                        </div>
                            <div>
                            <h4 style={{ color: 'darkblue' }}>Available no. of decimals</h4>
                            <br />
                            <div className='Content'>
                                <ul>
                                    <li>a. 2 decimals <br />
                                     &emsp;The data in the heatmap report will round to two decimal points
                                    </li>
                                    <li>b. 3 decimals <br />
                                    &emsp;The data will round to three decimal points 
                                    </li>
                                    <li>c. 4 decimals <br />
                                    &emsp;The data will round to four decimal points
                                    </li>
                                    <li>d. Auto (based on volume) </li>
                                </ul>
                            </div>
                            </div>
                            <div style={{ top: '-100px', left: '0' }}>
                            <h4 style={{ color: 'darkblue' }}>Available Color Schemes </h4>
                                <br />
                            <div>
                                <span>1. Default Availability</span>
                                <br />
                                <br />
                                <Row>
                                    <Col lg='8'>
                                        <table className="tblPIR tblborder">
                                            <thead>
                                                <tr>
                                                    <th>Color Scheme Id</th>
                                                    <th>Order</th>
                                                    <th>Description</th>
                                                    <th>Value1 Min</th>
                                                    <th>Value1 Max</th>
                                                    <th>Value2 Min</th>
                                                    <th>Value2 Max</th>
                                                    <th>Background Color</th>
                                                    <th>Foreground Color</th>
                                                    <th>Border</th>
                                                    <th>Border Color</th>
                                                    <th>Percentage Field</th>
                                                    <th>Meets Goal</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>Uptime {'<'} 99.9% </td>
                                                    <td>NULL</td>
                                                    <td>99.8999999</td>
                                                    <td>NULL</td>
                                                    <td>NULL</td>
                                                    <td>#ffc7ce </td>
                                                    <td>#9c0006 </td>
                                                    <td>1</td>
                                                    <td>1px solid red </td>
                                                    <td>1</td>
                                                    <td>0</td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>2</td>
                                                    <td>Uptime {'<'} 99.99% </td>
                                                    <td>99.9</td>
                                                    <td>99.9899999</td>
                                                    <td>NULL</td>
                                                    <td>NULL</td>
                                                    <td>#ffc000 </td>
                                                    <td>#9c5700 </td>
                                                    <td>1</td>
                                                    <td>1px solid red </td>
                                                    <td>1</td>
                                                    <td>0</td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>3</td>
                                                    <td>QoS {'<'} 99.5% (uptime OK)</td>
                                                    <td>99.99</td>
                                                    <td>100</td>
                                                    <td>0</td>
                                                    <td>99.4999999</td>
                                                    <td>#ffc7ce </td>
                                                    <td>#9c0006 </td>
                                                    <td>0</td>
                                                    <td>none </td>
                                                    <td>2</td>
                                                    <td>0</td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>4</td>
                                                    <td>QoS {'<'} 99.9% (uptime OK)</td>
                                                    <td>99.99</td>
                                                    <td>100</td>
                                                    <td>99.5</td>
                                                    <td>99.8999999</td>
                                                    <td>#e1eb9c </td>
                                                    <td>#9c5700 </td>
                                                    <td>0</td>
                                                    <td>none </td>
                                                    <td>2</td>
                                                    <td>0</td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>5</td>
                                                    <td>QoS {'<'} 99.95% (uptime OK)</td>
                                                    <td>99.99</td>
                                                    <td>100</td>
                                                    <td>99.9</td>
                                                    <td>99.9499999</td>
                                                    <td>#c6efce </td>
                                                    <td>#006100 </td>
                                                    <td>0</td>
                                                    <td>none </td>
                                                    <td>2</td>
                                                    <td>1</td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>6</td>
                                                    <td>QoS {'>='} 99.95% (uptime OK)</td>
                                                    <td>99.99</td>
                                                    <td>100</td>
                                                    <td>99.95</td>
                                                    <td>100</td>
                                                    <td>#c6e0b4 </td>
                                                    <td>#006100 </td>
                                                    <td>0</td>
                                                    <td>none </td>
                                                    <td>2</td>
                                                    <td>1</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col lg='4'></Col>
                                </Row>
                                <br />
                                <br />
                            </div>
                            <div>
                                <span>2. Reachability</span>
                                <br />
                                <br />
                                <Row>
                                    <Col lg='8'>
                                        <table className="tblPIR tblborder">
                                            <thead>
                                                <tr>
                                                    <th>Color Scheme Id</th>
                                                    <th>Order</th>
                                                    <th>Description</th>
                                                    <th>Value1 Min</th>
                                                    <th>Value1 Max</th>
                                                    <th>Value2 Min</th>
                                                    <th>Value2 Max</th>
                                                    <th>Background Color</th>
                                                    <th>Foreground Color</th>
                                                    <th>Border</th>
                                                    <th>Border Color</th>
                                                    <th>Percentage Field</th>
                                                    <th>Meets Goal</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>2</td>
                                                    <td>1</td>
                                                    <td>Less than 99% </td>
                                                    <td>0</td>
                                                    <td>98.9999999</td>
                                                    <td>NULL</td>
                                                    <td>NULL</td>
                                                    <td>#ffc7ce </td>
                                                    <td>#9c0006 </td>
                                                    <td>0</td>
                                                    <td>none </td>
                                                    <td>1</td>
                                                    <td>0</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>2</td>
                                                    <td>Between 99 and 99.9% </td>
                                                    <td>99</td>
                                                    <td>99.8999999</td>
                                                    <td>NULL</td>
                                                    <td>NULL</td>
                                                    <td>#ffc000 </td>
                                                    <td>#9c5700 </td>
                                                    <td>0</td>
                                                    <td>none </td>
                                                    <td>1</td>
                                                    <td>0</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>3</td>
                                                    <td>Above 99.9% </td>
                                                    <td>99.9</td>
                                                    <td>100</td>
                                                    <td>NULL</td>
                                                    <td>NULL</td>
                                                    <td>#c6e0b4 </td>
                                                    <td>#006100 </td>
                                                    <td>0</td>
                                                    <td>none </td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                </tr> 
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col lg='4'></Col>
                                </Row>
                                <br />
                                <br />
                            </div>
                            <div>
                                <span>3. Availability-4-9s </span>
                                <br />
                                <br />
                                <Row>
                                    <Col lg='8'>
                                        <table className="tblPIR tblborder">
                                            <thead>
                                                <tr>
                                                    <th>Color Scheme Id</th>
                                                    <th>Order</th>
                                                    <th>Description</th>
                                                    <th>Value1 Min</th>
                                                    <th>Value1 Max</th>
                                                    <th>Value2 Min</th>
                                                    <th>Value2 Max</th>
                                                    <th>Background Color</th>
                                                    <th>Foreground Color</th>
                                                    <th>Border</th>
                                                    <th>Border Color</th>
                                                    <th>Percentage Field</th>
                                                    <th>Meets Goal</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>3</td>
                                                    <td>1</td>
                                                    <td>Less than 99.9% </td>
                                                    <td>0</td>
                                                    <td>99.8999999</td>
                                                    <td>NULL</td>
                                                    <td>NULL</td>
                                                    <td>#ffc7ce </td>
                                                    <td>#9c0006 </td>
                                                    <td>0</td>
                                                    <td>none </td>
                                                    <td>1</td>
                                                    <td>0</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>2</td>
                                                    <td>Between 99.9 and 99.99% </td>
                                                    <td>99.9</td>
                                                    <td>99.9899999</td>
                                                    <td>NULL</td>
                                                    <td>NULL</td>
                                                    <td>#ffc000 </td>
                                                    <td>#9c5700 </td>
                                                    <td>0</td>
                                                    <td>none </td>
                                                    <td>1</td>
                                                    <td>0</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>3</td>
                                                    <td>Above 99.99% </td>
                                                    <td>99.9</td>
                                                    <td>100</td>
                                                    <td>NULL</td>
                                                    <td>NULL</td>
                                                    <td>#c6e0b4 </td>
                                                    <td>#006100 </td>
                                                    <td>0</td>
                                                    <td>none </td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col lg='4'></Col>
                                </Row>
                                <br />
                                <br />
                            </div>
                            <div>
                                <span>4. AuthN Apps </span>
                                <br />
                                <br />
                                <Row>
                                    <Col lg='8'>
                                        <table className="tblPIR tblborder">
                                            <thead>
                                                <tr>
                                                    <th>Color Scheme Id</th>
                                                    <th>Order</th>
                                                    <th>Description</th>
                                                    <th>Value1 Min</th>
                                                    <th>Value1 Max</th>
                                                    <th>Value2 Min</th>
                                                    <th>Value2 Max</th>
                                                    <th>Background Color</th>
                                                    <th>Foreground Color</th>
                                                    <th>Border</th>
                                                    <th>Border Color</th>
                                                    <th>Percentage Field</th>
                                                    <th>Meets Goal</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>4</td>
                                                    <td>1</td>
                                                    <td>Less than 99% </td>
                                                    <td>0</td>
                                                    <td>98.9999999</td>
                                                    <td>NULL</td>
                                                    <td>NULL</td>
                                                    <td>#ffaaff </td>
                                                    <td>#ff00ff </td>
                                                    <td>0</td>
                                                    <td>none </td>
                                                    <td>1</td>
                                                    <td>0</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>2</td>
                                                    <td>Between 99 and 99.5% </td>
                                                    <td>99</td>
                                                    <td>99.4999999</td>
                                                    <td>NULL</td>
                                                    <td>NULL</td>
                                                    <td>#ffc000 </td>
                                                    <td>#9c5700 </td>
                                                    <td>0</td>
                                                    <td>none </td>
                                                    <td>1</td>
                                                    <td>0</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>3</td>
                                                    <td>Above 99.5% </td>
                                                    <td>99.5</td>
                                                    <td>100</td>
                                                    <td>NULL</td>
                                                    <td>NULL</td>
                                                    <td>#c6e0b4 </td>
                                                    <td>#006100 </td>
                                                    <td>0</td>
                                                    <td>none </td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col lg='4'></Col>
                                </Row>
                                <br />
                                <br />
                            </div>
                            <div>
                                <span>5. Availability-99.97  </span>
                                <br />
                                <br />
                                <Row>
                                    <Col lg='8'>
                                        <table className="tblPIR tblborder">
                                            <thead>
                                                <tr>
                                                    <th>Color Scheme Id</th>
                                                    <th>Order</th>
                                                    <th>Description</th>
                                                    <th>Value1 Min</th>
                                                    <th>Value1 Max</th>
                                                    <th>Value2 Min</th>
                                                    <th>Value2 Max</th>
                                                    <th>Background Color</th>
                                                    <th>Foreground Color</th>
                                                    <th>Border</th>
                                                    <th>Border Color</th>
                                                    <th>Percentage Field</th>
                                                    <th>Meets Goal</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>5</td>
                                                    <td>1</td>
                                                    <td>Less than 99.9% </td>
                                                    <td>0</td>
                                                    <td>99.9899999</td>
                                                    <td>NULL</td>
                                                    <td>NULL</td>
                                                    <td>#ffc7ce </td>
                                                    <td>#9c0006 </td>
                                                    <td>0</td>
                                                    <td>none </td>
                                                    <td>1</td>
                                                    <td>0</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>2</td>
                                                    <td>Between 99.9 and 99.97% </td>
                                                    <td>99.9</td>
                                                    <td>99.9699999</td>
                                                    <td>NULL</td>
                                                    <td>NULL</td>
                                                    <td>#ffc000 </td>
                                                    <td>#9c5700 </td>
                                                    <td>0</td>
                                                    <td>none </td>
                                                    <td>1</td>
                                                    <td>0</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>3</td>
                                                    <td>Above 99.97% </td>
                                                    <td>99.97</td>
                                                    <td>100</td>
                                                    <td>NULL</td>
                                                    <td>NULL</td>
                                                    <td>#c6e0b4 </td>
                                                    <td>#006100 </td>
                                                    <td>0</td>
                                                    <td>none </td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col lg='4'></Col>
                                </Row>
                                <br />
                                <br />
                            </div>
                            <div>
                                <span>6. Availability-99.95  </span>
                                <br />
                                <br />
                                <Row>
                                    <Col lg='8'>
                                        <table className="tblPIR tblborder">
                                            <thead>
                                                <tr>
                                                    <th>Color Scheme Id</th>
                                                    <th>Order</th>
                                                    <th>Description</th>
                                                    <th>Value1 Min</th>
                                                    <th>Value1 Max</th>
                                                    <th>Value2 Min</th>
                                                    <th>Value2 Max</th>
                                                    <th>Background Color</th>
                                                    <th>Foreground Color</th>
                                                    <th>Border</th>
                                                    <th>Border Color</th>
                                                    <th>Percentage Field</th>
                                                    <th>Meets Goal</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>6</td>
                                                    <td>1</td>
                                                    <td>Less than 99.9% </td>
                                                    <td>0</td>
                                                    <td>99.8999999</td>
                                                    <td>NULL</td>
                                                    <td>NULL</td>
                                                    <td>#ffc7ce </td>
                                                    <td>#9c0006 </td>
                                                    <td>0</td>
                                                    <td>none </td>
                                                    <td>1</td>
                                                    <td>0</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>2</td>
                                                    <td>Between 99.9 and 99.95% </td>
                                                    <td>99.9</td>
                                                    <td>99.9499999</td>
                                                    <td>NULL</td>
                                                    <td>NULL</td>
                                                    <td>#ffc000 </td>
                                                    <td>#9c5700 </td>
                                                    <td>0</td>
                                                    <td>none </td>
                                                    <td>1</td>
                                                    <td>0</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>3</td>
                                                    <td>Above 99.95% </td>
                                                    <td>99.95</td>
                                                    <td>100</td>
                                                    <td>NULL</td>
                                                    <td>NULL</td>
                                                    <td>#c6e0b4 </td>
                                                    <td>#006100 </td>
                                                    <td>0</td>
                                                    <td>none </td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col lg='4'></Col>
                                </Row>
                                <br />
                                <br />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
});

HeatmapConfigurationGuide.displayName = 'HeatmapConfigurationGuide';

export default HeatmapConfigurationGuide;