import { IAppThunkAction, ReduxAction } from '../';
import { ActionType, IGemServiceClassificationData, INodeType } from './types';
import { PIRRiskToolAPI, CommonAPI } from '../../api';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../utils';
import { toast } from 'react-toastify';

export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    setloadingtrue: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
    },
    setloadingfalse: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_FALSE
        });
    },
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }

    },
    searchWorkItem: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        const workitemId = getState().PIRRiskTool.workItemID;
        PIRRiskToolAPI.GetWorkItemDetails(workitemId)
            .then((workitemDetails: []) => {
                let isLink = false;
                let isAttachment = false;

                if (workitemDetails['relations'] != null && workitemDetails['relations'].length > 0) {
                    isAttachment = workitemDetails['relations'].some(x => x.rel.includes("Attach"));

                    if (workitemDetails['relations'].some(x => x.rel.includes("System.LinkTypes"))) {
                        for (let i = 0; i < workitemDetails['relations'].length; i++) {
                            workitemDetails['relations'][i].rel = workitemDetails['relations'][i].rel.toString().replace("Hierarchy", "Duplicate");
                        }
                        isLink = true;
                    }
                    else if ((workitemDetails['relations'].some(x => x.rel.includes("Attach")) == false) && (workitemDetails['relations'].length > 0)) {
                        isLink = true;
                    }
                }

                dispatch({
                    titleValue: workitemDetails['title'],
                    IterationPathValue: workitemDetails['iterationPath'],
                    workItemTypeValue: workitemDetails['workItemType'],
                    tagsValue: workitemDetails['tags'] != null ? workitemDetails['tags'] : "",
                    stateValue: workitemDetails['state'],
                    relations: workitemDetails['relations'] != null ? workitemDetails['relations'] : "",
                    isLinksActive: isLink,
                    descriptionValue: workitemDetails['description'] != null ? workitemDetails['description'] : "",
                    isAttachmentActive: isAttachment,
                    customFields: workitemDetails['customFields'],
                    type: ActionType.GET_WORKITEM_DETAILS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.SET_LOADING_FALSE
                });
                if (error.message == "Request failed with status code 500") {
                    toast.error(renderToastifyMsg("Requested workitem is not exist",
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 7000 });
                }
            });

    },
    createCopy: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let errorMsg = '';
        let id = '';
        let message = '';
        let workItemId = getState().PIRRiskTool.workItemID;
        let gemdata = getState().PIRRiskTool.gemServiceMappingData;
        let checkedservices = getState().PIRRiskTool.checkedServices;
        let finalList = [];
        let iterationPath = getState().PIRRiskTool.IterationPathValue;
        let title = getState().PIRRiskTool.titleValue;
        let workItemType = getState().PIRRiskTool.workItemTypeValue;
        let tags = getState().PIRRiskTool.tagsValue;
        let state = getState().PIRRiskTool.stateValue;
        let relations = getState().PIRRiskTool.relations;
        let includeLinks = getState().PIRRiskTool.includeLinks;
        let includeAttachments = getState().PIRRiskTool.includeattachments;
        let description = getState().PIRRiskTool.descriptionValue;
        let icmCustomeFields = getState().PIRRiskTool.customFields;

        let success = 0;
        gemdata.forEach((row) => {         
            if (checkedservices.includes(row.gem) || checkedservices.includes(row.serviceName) || checkedservices.includes(row.directorName)) {
                finalList.push({
                    'ServiceName': row.serviceName, 'TeamGroupName': row.teamGroupName, 'AreaPath': row.areaPath, 'GemEmail': row.gemEmail
                });
            }
            
        });
        let inData = { 'WorkItemId': workItemId, 'Title': title, 'IterationPath': iterationPath, 'WorkItemType': workItemType, 'Slist': finalList, 'relationList': relations, 'Tags': tags, 'State': state, 'includeLinks': includeLinks, 'includeAttachments': includeAttachments, 'description': description, 'fieldsList': icmCustomeFields };
        if (state == 'Done') {
            dispatch({
                type: ActionType.SET_LOADING_FALSE
            });

            toast.error(renderToastifyMsg("You can't create Work Item which is already completed",
                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 7000 });
        }
        else if (checkedservices.length == 0) {
            dispatch({
                type: ActionType.SET_LOADING_FALSE
            });

            toast.warn(
                renderToastifyMsg("Please select atlease one GEM/Service",
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 }
            );
        }
        else {
            PIRRiskToolAPI.CreateWorkItemCopy(inData).then((response) => {
                let responseData = response;

                dispatch({
                    errorMsgList: responseData,
                    type: ActionType.CREATE_ADOWORKITEM_SUCCESS
                });

            }).catch(error => {
                dispatch({
                    errorMsgList: [],
                    errorMsg: "Please Retry Unable to Create Work Item Copy",
                    type: ActionType.CREATE_ADOWORKITEM_FAILED
                });
            });
        }
    },
    clearWorkItem: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        PIRRiskToolAPI.GetServiceTreeGemListData()
            .then((gemservicedata: IGemServiceClassificationData[]) => {
                let nodeData: INodeType[] = [{
                    value: "SelectAll",
                    label: "SelectAll",
                    checked: true,
                    classification: "SelectAll",
                }];
                gemservicedata.forEach((aa) => {
                    if (!nodeData.some(x => x.value == aa.directorName)) {
                        nodeData.push({
                            value: aa.directorName,
                            label: aa.directorName,
                            checked: true,
                            classification: aa.classification,
                            children: [
                                {
                                    value: aa.gem,
                                    label: aa.gem,
                                    checked: true,
                                    classification: aa.classification,
                                    children: [
                                        {
                                            value: aa.serviceName,
                                            label: aa.serviceName,
                                            checked: true,
                                            classification: aa.classification
                                        }]
                                }
                            ]

                        })
                    }
                    else {
                        if (nodeData[nodeData.map(e => e.value).indexOf(aa.directorName)].children.some(x => x.value == aa.gem)) {
                            nodeData[nodeData.map(e => e.value).indexOf(aa.directorName)].children.find(x => x.value == aa.gem).children.push({
                                value: aa.serviceName,
                                label: aa.serviceName,
                                checked: true,
                                classification: aa.classification
                            })
                        }
                        else {
                            nodeData[nodeData.map(e => e.value).indexOf(aa.directorName)].children.push({
                                value: aa.gem,
                                label: aa.gem,
                                checked: true,
                                classification: aa.classification,
                                children: [
                                    {
                                        value: aa.serviceName,
                                        label: aa.serviceName,
                                        checked: true,
                                        classification: aa.classification
                                    }]
                            })
                        }
                    }
                });
                let node = [];
                nodeData.forEach((row) => {
                    row.checked = true;
                    if (row.children != undefined) {
                        row.children.forEach((r) => {
                            r.checked = true;
                        })
                    }
                    node.push(row.value);
                });

                dispatch({
                    gemServiceMappingData: gemservicedata,
                    nodeData: nodeData,
                    checkedServices: node,
                    type: ActionType.GET_GEMSERVICE_SUCCESS
                });

            }).catch(error => {
                dispatch({
                    type: ActionType.GET_GEMSERVICE_FAILURE
                });
            });

        PIRRiskToolAPI.GetIDClassificationList()
            .then((classificationlist: any) => {
                let IDClassificationList = [];
                let IDClassificationvalue = [];
                if (isArrayWithLength(classificationlist)) {
                    classificationlist.map((obj) => {
                        IDClassificationvalue.push(obj.label);
                        IDClassificationList.push({ name: obj.label, value: obj.label });
                    })
                }
                dispatch({
                    IDClassificationList,
                    IDClassificationvalue,
                    type: ActionType.FETCH_IDCLASSIFICATION_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.FETCH_IDCLASSIFICATION_FAIL
                });
            });

        dispatch({
            type: ActionType.CLEAR_ADOWORKITEM_FIELDS
        });
    },
    IDClassificationchange: (value): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let gemNode = getState().PIRRiskTool.gemServiceMappingData;
        let nodeData = JSON.parse(JSON.stringify(getState().PIRRiskTool.nodeData));
        let checkedServiceNode = [];

        if (value.length == 3) {

            nodeData.forEach((row) => {
                row.checked = true;
                if (row.children != undefined) {
                    row.children.forEach((r) => {
                        r.checked = true;
                        r.children.forEach((b) => {
                            b.checked = true;
                            checkedServiceNode.push(b.value);
                        })
                    })
                }
            });

        }
        else if (value.length == 0) {
            nodeData.forEach((row) => {
                row.checked = false;
                if (row.children != undefined) {
                    row.children.forEach((r) => {
                        r.checked = false;
                        r.children.forEach((b) => {
                            b.checked = false;
                        })
                    })
                }
            });
        }
        else {
            nodeData.forEach((noderow) => {
                if (noderow.children != undefined) {
                    noderow.children.forEach((nr) => {
                        nr.children.forEach((b) => {
                            if (value.some(x => x == gemNode.filter(y => y.serviceName == b.label)[0].classification)) {
                                b.checked = true;
                                checkedServiceNode.push(b.value);
                            }
                            else {
                                b.checked = false;
                            }
                        })
                    });
                }
            });

            nodeData.forEach((noderow) => {
                if (noderow.children != undefined) {
                    noderow.children.forEach((nr) => {
                        if (nr.children.some(x => x.checked == true)) {
                            nr.checked = true;
                            checkedServiceNode.push(nr.value);
                        }
                        else {
                            nr.checked = false;
                        }
                    });
                }
            });
        }

        dispatch({
            nodeData: nodeData,
            gemServiceMappingData: gemNode,
            checkedServices: checkedServiceNode,
            IDClassificationvalue: value,
            type: ActionType.IDCLASSIFICATION_CHANGE
        });
    },
    UpdateTitle: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {

    },
    UpdateIterationPath: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {

    },
    UpdateworkItemType: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {

    },
    UpdateTags: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {

    },
    UpdateState: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {

    },
    includeLinkschange: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            includeLinks: e.target.checked,
            type: ActionType.SET_INCLUDE_LINKS
        });

    },
    includeAttachmentchange: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            includeattachments: e.target.checked,
            type: ActionType.SET_INCLUDE_ATTACHMENTS
        });
    },
    GetServiceTreeGemListData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        PIRRiskToolAPI.GetIDClassificationList()
            .then((classificationlist: any) => {
                let IDClassificationList = [];
                let IDClassificationvalue = [];
                if (isArrayWithLength(classificationlist)) {
                    classificationlist.map((obj) => {
                        IDClassificationvalue.push(obj.label);
                        IDClassificationList.push({ name: obj.label, value: obj.label });
                    })
                }
                dispatch({
                    IDClassificationList,
                    IDClassificationvalue,
                    type: ActionType.FETCH_IDCLASSIFICATION_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.FETCH_IDCLASSIFICATION_FAIL
                });
            });

        PIRRiskToolAPI.GetServiceTreeGemListData()
            .then((gemservicedata: IGemServiceClassificationData[]) => {
                let nodeData: INodeType[] = [{
                    value: "SelectAll",
                    label: "SelectAll",
                    checked: true,
                    classification: "SelectAll",
                }];
                gemservicedata.forEach((aa) => {
                    if (!nodeData.some(x => x.value == aa.directorName)) {
                        nodeData.push({
                            value: aa.directorName,
                            label: aa.directorName,
                            checked: true,
                            classification: aa.classification,
                            children: [
                                {
                                    value: aa.gem,
                                    label: aa.gem,
                                    checked: true,
                                    classification: aa.classification,
                                    children: [
                                        {
                                            value: aa.serviceName,
                                            label: aa.serviceName,
                                            checked: true,
                                            classification: aa.classification
                                        }],

                                }
                            ]

                        })
                    }
                    else {
                        if (nodeData[nodeData.map(e => e.value).indexOf(aa.directorName)].children.some(x => x.value == aa.gem)) {
                            nodeData[nodeData.map(e => e.value).indexOf(aa.directorName)].children.find(x => x.value == aa.gem).children.push({
                                value: aa.serviceName,
                                label: aa.serviceName,
                                checked: true,
                                classification: aa.classification
                            })
                        }
                        else {
                            nodeData[nodeData.map(e => e.value).indexOf(aa.directorName)].children.push({
                                value: aa.gem,
                                label: aa.gem,
                                checked: true,
                                classification: aa.classification,
                                children: [
                                    {
                                        value: aa.serviceName,
                                        label: aa.serviceName,
                                        checked: true,
                                        classification: aa.classification
                                    }]
                            })
                        }
                    }
                });
                let node = [];
                nodeData.forEach((row) => {
                    row.checked = true;
                    if (row.children != undefined) {
                        row.children.forEach((r) => {
                            r.checked = true;
                        })
                    }
                    node.push(row.value);
                });

                dispatch({
                    gemServiceMappingData: gemservicedata,
                    nodeData: nodeData,
                    checkedServices: node,
                    type: ActionType.GET_GEMSERVICE_SUCCESS
                });

            }).catch(error => {
                dispatch({
                    type: ActionType.GET_GEMSERVICE_FAILURE
                });
            });
    },
    UpdateNodeData: (value): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let gemNode = getState().PIRRiskTool.gemServiceMappingData;
        let classify = [];
        let checkedServiceNode = [];

        value.forEach((r) => {
            gemNode.forEach((row) => {
                if (row.directorName == r.value) {
                    classify.push(row.classification)
                    if (r.checked) {
                        checkedServiceNode.push(row.serviceName);
                    }
                }
                else if (row.serviceName == r.value) {
                    classify.push(row.classification)
                    if (r.checked) {
                        checkedServiceNode.push(row.serviceName);
                    }
                }
                else if (row.gem == r.value) {
                    classify.push(row.classification)
                    if (r.checked) {
                        checkedServiceNode.push(row.serviceName);
                    }
                }
            })
        });

        classify = [...new Set(classify)];

        dispatch({
            checkedServices: checkedServiceNode,
            IDClassificationvalue: classify,
            type: ActionType.SET_CHECKED_VALUES
        });
    },
    UpdateworkItemID: (e): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const re = /^[0-9\b]+$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            dispatch({
                workItemID: e.target.value,
                type: ActionType.SET_WORKITEMID_VALUE
            });
        }
    },
    UpdateSelectAll: (isSelect): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let node = [];
        let nodeData = getState().PIRRiskTool.nodeData;
        let newnodeData = [];
        let classify = [];
        let classificationList = getState().PIRRiskTool.IDClassificationList;

        if (isSelect) {
            nodeData.forEach((row) => {
                row.checked = true;
                if (row.children != undefined) {
                    row.children.forEach((r) => {
                        r.checked = true;
                        r.children.forEach((b) => {
                            b.checked = true;
                        })
                    })
                }
                newnodeData.push(row);
                node.push(row.value);
            });
            if (isArrayWithLength(classificationList)) {
                classificationList.map((obj) => {
                    classify.push(obj.value);
                })
            }
        }
        else {
            nodeData = nodeData.forEach((row) => {
                row.checked = false;
                if (row.children != undefined) {
                    row.children.forEach((r) => {
                        r.checked = false;
                        r.children.forEach((b) => {
                            b.checked = false;
                        })
                    })
                }
                newnodeData.push(row);
            });
            classify = [];
        }
        dispatch({
            nodeData: newnodeData,
            checkedServices: node,
            IDClassificationvalue: classify,
            type: ActionType.SET_NODEDATA_VALUES
        });
    }
}



