export interface IActionType {
    readonly LOGIN: string;
    readonly LOGIN_SUCCESS: string;
    readonly LOGIN_FAIL: string;
    readonly LOGOUT: string;
    readonly RESET_STATE: string;
    readonly SET_AUTH_STATUS: string;
    readonly DATE_REFRESHED: string;
    readonly GET_SERVICE_GROUP: string;
}

export type AuthStatus = 'none' | 'process' | 'success' | 'fail';

export type IAuthUser = {
    id: string;
    login: string;
    isAuthenticated: boolean;
    isAdmin: boolean;
    isProd: boolean;
};

export type ICredentials = {
    userName?: string;
    password?: string;
    rememberMe?: boolean;
};

export type IServiceGroup = {
    readonly directorName: string;
    readonly teamGroupName: string;
    readonly groupName: string;
    readonly serviceName: string;
    readonly serviceId: string;
    readonly isActive: boolean;
    readonly isDSE: string;
    readonly isHeatMap: boolean;
    readonly isDailyReport: string;
    readonly isWeeklyReport: string;
    readonly parentReachabilityId: string;
    readonly reachabilityAvailable: string;
    readonly colValue: string;
    IsDirectorChecked: boolean;
    IsTeamGroupChecked: boolean;
    IServiceGroupChecked: boolean;
    IsToggle: boolean;
    enableservicescount: number;

};

export type IPropCalender = {
    readonly Year: string;
    readonly Month: string;
    readonly WeekId: string;
    readonly WeekDisplayName: string;
    readonly WeekStartDate: string;
    readonly WeekEndDate: boolean;
    readonly WeekDetail: string;
    readonly displayDateAvailableData: string;
    readonly ConfigName: string;
    readonly ConfigValue: string;
};

//export type IAuthUser = {
//  token?: string;
//  userName?: string;
//  status?: AuthStatus;
//};

export type IAuthUserState = {
    readonly authUser: IAuthUser[];
    readonly DataRefreshed: IPropCalender[];
    readonly serviceGroup: IServiceGroup[];
    readonly serviceId: number;
    readonly viewId: number;
};

const _namespace = 'auth';

export const ActionType = Object.freeze<IActionType>({
  LOGIN: `${_namespace}/login`,
  LOGIN_SUCCESS: `${_namespace}/loginSuccess`,
  LOGIN_FAIL: `${_namespace}/loginFail`,
  LOGOUT: `${_namespace}/logout`,
  RESET_STATE: `${_namespace}/resetState`,
  SET_AUTH_STATUS: `${_namespace}/setAuthStatus`,
  DATE_REFRESHED: `${_namespace}/dateRefreshed`,
  GET_SERVICE_GROUP: `${_namespace}/getServiceGroup`
});

export const AuthStatusEnum = Object.freeze<{ [key: string]: AuthStatus }>({
  NONE: 'none',
  PROCESS: 'process',
  SUCCESS: 'success',
  FAIL: 'fail'
});