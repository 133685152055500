import React, { useState, Fragment } from 'react';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import {
    IPropAllPirDataReReview
} from '../../../store/WZTNA-SHR/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash, faPlus, faArrowCircleLeft, faSave, faBan } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Button } from 'reactstrap';
type PostIncidentReviewsProps = {
    PIRRereviewData: IPropAllPirDataReReview[],   
    DeletePostmortenrereviewData: any,
    onChangeAgendaforpostmortemrereview: any,
    isPIRReviewEditenabled: boolean,
    EditPIRRereviewRows: any,
    CancelEditPostmortemRereview: any,
    PostmortemrereviewTextboxchange: any,
    SavePostmortemrereviewData: any
    IsPirRereviewUser:boolean

};

const PostIncidentReReviews = React.memo<PostIncidentReviewsProps>(({
    PIRRereviewData,  
    DeletePostmortenrereviewData,
    onChangeAgendaforpostmortemrereview,
    isPIRReviewEditenabled,
    EditPIRRereviewRows,
    CancelEditPostmortemRereview,
    PostmortemrereviewTextboxchange,
    SavePostmortemrereviewData,
    IsPirRereviewUser

}) => {   
    return (
      
        <React.Fragment>
            {
                (IsPirRereviewUser)
                    ? <React.Fragment>
                        <br />
                        <Row>
                            <Col lg="10"><h5 className="headertext">Postmortems for Re-Review</h5></Col>
                            <Col lg="2">
                                <div className="divaddnew">

                                </div>
                            </Col>
                        </Row>
                        <Row className="tblpadding">
                            <Col lg="12">
                                <table id="tblPIR" className="tblPIR tblborder">
                                    <thead>
                                        <tr>
                                            <th className="thwidth280">Owning Service</th>
                                            <th className="thwidth200">Services Impacted</th>
                                            <th className="thwidth200">Incident Id</th>
                                            <th className="thwidth300">PIR Title</th>
                                            <th className="thwidth120">Occurred in what Ring?</th>
                                            <th className="thwidth100">TTD</th>
                                            <th className="thwidth100">TTE</th>
                                            <th className="thwidth100">TTM</th>
                                            <th className="thwidth100">TTN</th>
                                            <th className="thwidth200">Document Link</th>
                                            <th className="thwidth120">PIR Number</th>
                                            <th className="thwidth200">{(isPIRReviewEditenabled) ? <span className="spnmandatoryIcons">*</span> : <React.Fragment></React.Fragment>}Date for re-review on</th>
                                            <th className="thwidth120">Actions</th>
                                            <th className="thwidth200">Add To Agenda</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!isArrayWithLength(PIRRereviewData)
                                            ?
                                            <React.Fragment>
                                                <tr>
                                                    <td colSpan={14} className="clsnodatamessgefortable">
                                                        No Data Available
                                        </td>
                                                </tr>
                                            </React.Fragment>
                                            : PIRRereviewData.map((row: IPropAllPirDataReReview, index) => (
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <td>{row.serviceName}</td>
                                                        <td>{row.servicesImpacted}</td>
                                                        <td>
                                                            <span>
                                                                <a>ICM#</a>
                                                                <a href={"https://portal.microsofticm.com/imp/v3/incidents/details/" + row.incidentId + "/home"} target="_blank"> {row.incidentId}</a>
                                                            </span>
                                                        </td>
                                                        <td>{row.summary}</td>

                                                        <td>
                                                            {row.occuredRing}
                                                        </td>
                                                        <td>{row.ttd} </td>
                                                        <td>{row.tte}</td>
                                                        <td>{row.ttm}</td>
                                                        <td>{row.ttn}</td>
                                                        <td>
                                                            <a href={row.resourceLink} target="_blank">{row.resourceDescription}</a>
                                                        </td>
                                                        <td>
                                                            <div>{row.pir}</div>
                                                        </td>
                                                        <td>
                                                            <div>{(row.isEdit) ? <input className="form-control" onChange={e => PostmortemrereviewTextboxchange(e, row)} value={row.dueForReReviewOnEdit} name="dueForReReviewOnEdit" placeholder="mm/dd/yyyy" />
                                                                :
                                                                <React.Fragment>
                                                                    {(row.isPastDue)
                                                                        ? <span className="redcolorIcons" >{row.dueForReReviewOn}</span>
                                                                        : <span>{row.dueForReReviewOn}</span>}
                                                                </React.Fragment>

                                                            }</div>

                                                        </td>
                                                        <td className="centericons">
                                                            {
                                                                (!row.isEdit)
                                                                    ?
                                                                    <React.Fragment>
                                                                        <span onClick={e => EditPIRRereviewRows(e, row)}> <FontAwesomeIcon title='Click to edit the Row' className="bluecoloricons Iconsstyles" icon={faPencilAlt} /></span> &nbsp;
                                                     <FontAwesomeIcon title='Click to Delete the Row' className="redcolorIcons Iconsstyles" icon={faTrash} onClick={e => DeletePostmortenrereviewData(e, row)} />
                                                                    </React.Fragment>
                                                                    :
                                                                    <React.Fragment>
                                                                        <FontAwesomeIcon title='Save Changes' className="bluecoloricons Iconsstyles" icon={faSave} onClick={e => SavePostmortemrereviewData(e, row)} /> &nbsp;
                                                        <FontAwesomeIcon title='Cancel Edit' className="Iconsstyles" icon={faBan} onClick={e => CancelEditPostmortemRereview(e, row)} />
                                                                    </React.Fragment>
                                                            }
                                                        </td>
                                                        <td className="centericons">
                                                            <div><input type="checkbox" checked={row.addToAgendaEdit} onChange={e => onChangeAgendaforpostmortemrereview(e, row)} /></div>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </React.Fragment>
                    : <React.Fragment> </React.Fragment>
            }
           

        </React.Fragment>
    );
});

PostIncidentReReviews.displayName = 'PostIncidentReReviews';

export default PostIncidentReReviews;