import React from 'react';
import styled, { keyframes } from 'styled-components';

type SpinnerProps = {
    readonly isLoading?: boolean;
};

const SPIN_KEYFRAMES = keyframes`
  0% {
    transform: rotate(0deg);
  } 100% {
    transform: rotate(360deg);
  }
`;

const StyledSpinner = styled.div<SpinnerProps>`
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  height: 64px;
  z-index: 10;
  border-radius: 5px;
  display: ${({ isLoading }) => isLoading ? 'inline-block' : 'none'};

  > div {
    width: 4.75em;
    height: 4.75em;
    position: absolute;
    border-radius: 50%;
    border: 0.35em solid;
    display: inline-block;
    box-sizing: border-box;
    border-color: #09d3ac transparent transparent transparent;
    animation: ${SPIN_KEYFRAMES} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

    :nth-child(1) {
       animation-delay: -0.45s;
    }

    :nth-child(2) {
      animation-delay: -0.3s;
    }

    :nth-child(3) {
      animation-delay: -0.15s;
    }
  }
`;

const Spinner = React.memo<SpinnerProps>(({ isLoading }) => (
    <StyledSpinner isLoading={isLoading}>
        <div />
        <div />
        <div />
        <div />
    </StyledSpinner>
));

Spinner.displayName = 'Spinner';

export default Spinner;