import { FunctionReturnTypes, ReduxAction } from '../';
import { actionCreators } from './actions';
import { ActionType, IAuthUserState } from './types';

const initialState = Object.freeze<IAuthUserState>({
    authUser: [],
    DataRefreshed: [],
    serviceGroup: [],
    serviceId: 0,
    viewId: 0
});

export const reducer = (
    state: IAuthUserState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {
        case ActionType.LOGIN:
            return state;
        case ActionType.SET_AUTH_STATUS:
            return {
                ...state,
                status: action.status
            };
        case ActionType.LOGIN_SUCCESS:
            return {
                ...state,
                ...action.authUser
            };
        case ActionType.GET_SERVICE_GROUP:
            return {
                ...state,
                serviceGroup: action.serviceGroup
            };
        case ActionType.DATE_REFRESHED:
            return {
                ...state,
                DataRefreshed: action.DataRefreshed
            };
        case '@@router/LOCATION_CHANGE': {
            const { search } = action.payload.location;
            const params = new URLSearchParams(search);
            const serviceId = isNaN(parseInt(params.get('serviceid'))) ? 0: parseInt(params.get('serviceid'));
            const viewId = isNaN(parseInt(params.get('viewId'))) ? 0 : parseInt(params.get('viewId'));
            return {
                ...state,
                serviceId: serviceId,
                viewId: viewId
            }
        };
        case ActionType.LOGOUT:
        case ActionType.LOGIN_FAIL:
        case ActionType.RESET_STATE:
            return initialState;
        default:
            return state;
    }
};
