import React, { useEffect } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { Spinner } from '../../components';
import { IApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import { actionCreators, reducer } from '../../store/MSERHeatmapPreview';
import { MSERHeatmapPreviewtable } from '../MSER_HeatmapPreview/child-components';
import moment from 'moment';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../utils';
import LoadingOverlay from 'react-loading-overlay';
import {
    IComments, IDailyData, HmMetric,
   
} from '../../store/service-health';
import { NewFunctionalityBanner } from '../../components';

type MSERHeatmapPreviewProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly history: History }
    & typeof useParams;

const MSERHeatmapPreview: React.FC<MSERHeatmapPreviewProps> = ({
    isLoading,
    isAuthenticated,
    MSERHeatmapServicesData,
    GetMSERHeatmapServices,
    GetMserHeatViewList,
    MSERHeatmapViews,
    currentMSERVIEW,
    currentindex,
    lastindex,
    startDate,
    endDate,
    handleStartDateChange,
    handleEndDateChange,
    checkedFilterYellowDailyData,
    checkedFilterRedDailyData,
    showMSERComments,
    handlePresentationViewOnCheck,
    chkpresentationviewcheck,
    requestDailyData,
    HeatmapdailyData,
    uniqueDateHeaders,
    uniqueFixedHeaderColumns,
    datacentersList,
    additionalInfoData,
    viewdetailsdata,
    SelectedView,
    comments,
    colorScheme,
    onFilterYellowDailyData,
    onFilterRedDailyData,
    IsdataLoaded,
    toggleMSERCommentsView,
    volumeMeasure,
    failurevolumeMeasure,
    getNextService,
    getPreviousService,
    addUserVisit,
    Isviewsloaded,
    dcMaxLength
}) => {  
    useEffect(() => {
        if (isAuthenticated)
            addUserVisit(0, 'MSERHeatmapPreview.tsx_v2');
    }, [isAuthenticated]);
    useEffect(() => {     
        GetMserHeatViewList();
    }, [GetMSERHeatmapServices]);
    useEffect(() => {
        if (currentMSERVIEW !== null) {
            const momentStDate = moment(startDate, 'MM/DD/YYYY');
            const sStartDate = momentStDate.format('MM/DD/YYYY');

            const momentEnDate = moment(endDate, 'MM/DD/YYYY');
            const sEndDate = momentEnDate.format('MM/DD/YYYY');
            requestDailyData(currentMSERVIEW.serviceId, sStartDate,sEndDate,currentMSERVIEW.viewId);
        }
    }, [currentMSERVIEW]);
    //=========== filter heatmap MSER Data=========================//
    const filterHeatmapMSERData = () => {
        const momentStDate = moment(startDate, 'MM/DD/YYYY');
        const sStartDate = momentStDate.format('MM/DD/YYYY');

        const momentEnDate = moment(endDate, 'MM/DD/YYYY');
        const sEndDate = momentEnDate.format('MM/DD/YYYY');
        requestDailyData(currentMSERVIEW.serviceId, sStartDate, sEndDate, currentMSERVIEW.viewId);
    }
    const CommentSummary = (metric, key) => {

        if (metric !== undefined) {
            const commentId = metric.commentId;
            var result: any;
            if (commentId > 0) {
                const filterByKey = [];             
                if (isArrayWithLength(HeatmapdailyData)) {
                    HeatmapdailyData.map((DD: IDailyData, val) => {
                            if (DD.key === key) {
                                filterByKey.push(DD);

                            }
                        })
                        // if (filterByKey !== undefined && filterByKey !== null) {
                        if (isArrayWithLength(filterByKey)) {
                            filterByKey[0].metricList.map((mt: HmMetric, val) => {
                                if (moment(mt.dateKey, 'YYYY-MM-DD').isSame(moment(metric.dateKey).add(-1, 'days'), 'day') && mt.commentId === commentId) {
                                    return true;
                                }

                            })
                        }
                        comments.map((com: IComments, val) => {
                            if (com.commentId === commentId) {
                                result = val + 1;

                            }
                        })
                        return result;
                    }               
            }
        }
    }
    return (
        <div>
            <NewFunctionalityBanner />
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        <MSERHeatmapPreviewtable   
                            currentMSERVIEW={currentMSERVIEW}
                            currentindex={currentindex}
                            lastindex={lastindex}
                            startDate={startDate}
                            endDate={endDate}
                            onStartDateChange={handleStartDateChange}
                            onEndDateChange={handleEndDateChange}
                            checkedFilterYellowDailyData={checkedFilterYellowDailyData}
                            checkedFilterRedDailyData={checkedFilterRedDailyData}
                            showMSERComments={showMSERComments}
                            handlePresentationViewOnCheck={handlePresentationViewOnCheck}
                            chkpresentationviewcheck={chkpresentationviewcheck}
                            HeatmapdailyData={HeatmapdailyData}
                            uniqueDateHeaders={uniqueDateHeaders}
                            uniqueFixedHeaderColumns={uniqueFixedHeaderColumns}
                            datacentersList={datacentersList}
                            additionalInfoData={additionalInfoData}
                            viewdetailsdata={viewdetailsdata}
                            SelectedView={SelectedView}
                            CommentSummary={CommentSummary}
                            comments={comments}
                            colorScheme={colorScheme}
                            filterHeatmapMSERData={filterHeatmapMSERData}
                            onFilterYellowDailyData={onFilterYellowDailyData}
                            onFilterRedDailyData={onFilterRedDailyData}
                            IsdataLoaded={IsdataLoaded}
                            toggleMSERCommentsView={toggleMSERCommentsView}
                            volumeMeasure={volumeMeasure}
                            failurevolumeMeasure={failurevolumeMeasure}
                            getNextService={getNextService}
                            getPreviousService={getPreviousService}
                            Isviewsloaded={Isviewsloaded}
                            dcMaxLength={dcMaxLength}
                            />

                    </div>
                </section>
            </LoadingOverlay>

        </div>

    );
};
const mapStateToProps = (state: IApplicationState) => ({
    ...state.MSERHeatmapPreview,
    isAuthenticated: state.authUser.isAuthenticated

})
export default connect(mapStateToProps, actionCreators)(MSERHeatmapPreview);
