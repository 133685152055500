import React, { useState, Fragment, useRef } from 'react';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import { IPropPlatformPMTools } from '../../../store/PlatformTools';
import { ReduxAction, IAppThunkAction } from '../../../store';
import { IMonthlyReport } from '../../../store/service-health';
import { ITargetJson } from '../../../store/MonthlyAvailbility';
import { FontAwesomeIconMemo } from '../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import {
    Card, Row, Col, Container, CardBody
} from 'reactstrap';
import DatePicker, { getDay } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';

type MonthlyAvailbilityTableProps = {
    readonly monthlyStartDate: Date;
    readonly onmonthlyStartDateChange: (monthlyStartDate: Date) => ReduxAction;
    MonthlyData: IMonthlyReport[];
    MonthlyheaderArr: any,
    MonthlyKPiArr: any,
    IsMonthlyDataLoaded: boolean,
    ClearMonthlyDate: any,
    liKPIValueKeys: any
    FilterMonthlyAvailabilityData: any,
    clearfilterdata: any,
    serviceGroups: any,
    services: any;
    servicegroupval: any;
    servicegroupvalchange: any;
    serviceval: any;
    serviceschange: any;
    globalsearch: string;
    searchtextboxchange: any;
    isDataloaded: boolean;
    CopyURL: any;
    urlparam: any;
    isProd: boolean;
    EditTarget: any;
    editedTargetData: ITargetJson[];
    TargetEditPop: boolean;
    TargetEditPopClose: any;
    ChangeTargetValue: any;
    ChangeJustification: any;
    originalTargetValue: any;
    updatedTargetValue: any;
    justificationDetails: any;
    SubmitforApproval: any;
    ClearTarget: any;
};
const MonthlyAvailbilityTable = React.memo<MonthlyAvailbilityTableProps>(({
    monthlyStartDate,
    onmonthlyStartDateChange,
    MonthlyData,
    MonthlyheaderArr,
    MonthlyKPiArr,
    IsMonthlyDataLoaded,
    ClearMonthlyDate,
    liKPIValueKeys,
    FilterMonthlyAvailabilityData,
    clearfilterdata,
    serviceGroups,
    services,
    servicegroupval,
    servicegroupvalchange,
    serviceval,
    serviceschange,
    globalsearch,
    searchtextboxchange,
    isDataloaded,
    CopyURL,
    isProd,
    urlparam,
    EditTarget,
    editedTargetData,
    TargetEditPop,
    TargetEditPopClose,
    ChangeTargetValue,
    ChangeJustification,
    originalTargetValue,
    updatedTargetValue,
    justificationDetails,
    SubmitforApproval,
    ClearTarget
}) => {

    const filterBasedSearch = (item) => {
        let val: any = false;
        Object.values(item.liKPIValues).map((value, i) => {
            if (value !== undefined && value !== '' && value !== null) {
                if (value.toString().toLowerCase().includes(globalsearch.toString().toLowerCase())) {
                    val = true;
                }
            }
        });

        if (val === true || item.serviceName.toLowerCase().includes(globalsearch.toLowerCase())
            || item.targetName.toLowerCase().includes(globalsearch.toLowerCase())) {
            return true;
        }
        else {
            return false;
        }
    };

    // To Compare the weekly data with target values
    const AddColorForFactAvailabilityBudget = (data, key, target) => {
        var weekColor = { Color: "" };
        data[key] = (parseFloat(data[key]) < 1 && parseFloat(data[key]) > 0) ? (data[key] * 100).toFixed(4) + "%" : data[key];
        var reg = /[0-9][0-9]\.[0-9][%]/;
        var availabilityTarget = parseFloat(target);
        if (availabilityTarget != null) {
            OperatorOverloadAvailabilityBudget(data, key, availabilityTarget, weekColor, "<=");
        } else if (/[0-9]+/.test(data.targetName)) {
            OperatorOverloadAvailabilityBudget(data, key, target, weekColor, ">=");
        }
        return weekColor.Color;
    }
    // To Format the Monthly data
    const OperatorOverloadAvailabilityBudget = (data, key, target, weekColor, operator) => {
        data[key] = String(data[key]).replace(/[(\)]/gi, "");
        weekColor.Color = SetColorForWeekAvailabilityBudget(data[key], target, operator);
    }
    const SetColorForWeekAvailabilityBudget = (weekVal, target, operator) => {

        var color = "";
        var wk1 = String(weekVal).split("/");
        for (var i = 0; i < wk1.length; i++) {
            if (wk1[i] == "") {
                color = "grey";
            } else if (wk1[i] != "NA" && !isNaN(parseFloat(wk1[i]))) { // IF not a number or NA
                if (operator == ">=") {
                    if (parseFloat(wk1[i]) > target) {
                        color = "red";
                        break;
                    } else {
                        color = "green";
                    }
                } else if (operator == "<=") {
                    if (parseFloat(wk1[i]) < target) {
                        color = "red";
                        break;
                    } else {
                        color = "green";
                    }
                } else if (operator == "<") {
                    if (parseFloat(wk1[i]) <= target) {
                        color = "red";
                        break;
                    } else {
                        color = "green";
                    }
                }
            }
            if (/[sec]{3}/.test(wk1[i])) {
                break;
            }
        }

        return color;
    }
    return (
        <div>
            <Card className="cardstyle">
                <CardBody className="cardbodyhederstyle">
                    <Row>

                        <Col lg="12">
                            <h3 className="headertext"> Monthly Availability</h3>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <div className="padRight" style={{ width: '100%' }}>
                <Row>
                    <Col lg='2'>
                        <div className="p-field p-col-10 p-md-4 trendcolumns">
                            <span className="p-float-label">
                                <MultiSelect id="mlservicegroup" filter={true} optionLabel="name" value={servicegroupval} options={serviceGroups} onChange={(e) => servicegroupvalchange(e.value)} />
                                <label htmlFor="mlservicegroup" className="fontcolor">Gem</label>
                            </span>
                        </div>
                    </Col>
                    <Col lg='2'>
                        <div className="p-field p-col-10 p-md-4 trendcolumns">
                            <span className="p-float-label">
                                <MultiSelect id="mlservice" filter={true} optionLabel="name" value={serviceval} options={services} onChange={(e) => serviceschange(e.value)} />
                                <label htmlFor="mlservice" className="fontcolor">Service </label>
                            </span>
                        </div>
                    </Col>
                    <Col lg="2">
                        <div style={{ paddingTop: '1.5rem' }}>
                            <input type="text" id="searchheight" className="form-control" value={globalsearch} onChange={e => searchtextboxchange(e)} placeholder="Search" />
                        </div>
                    </Col>
                    <Col lg="4">
                        <div style={{ paddingTop: '1.4rem' }}>
                            <button className="btn btn-primary btn-md filter-hmbutton btnwidth150" onClick={() => FilterMonthlyAvailabilityData()}> <FontAwesomeIconMemo title="Filter the data" icon={'filter'} />&nbsp;Filter</button>&nbsp;&nbsp;
                            <button className="btn btn-primary btn-md filter-hmbutton btnwidth150" onClick={() => clearfilterdata()}> <FontAwesomeIconMemo title="Clear Filter" icon={'ban'} />&nbsp;Clear Filters</button>&nbsp;&nbsp;
                            <button className="btn btn-primary btn-md filter-hmbutton btnwidth150" onClick={() => CopyURL()}> <FontAwesomeIconMemo title="Copy the URL" icon={'link'} />&nbsp;Copy URL</button>&nbsp;&nbsp;
                        </div>
                    </Col>
                    <Col lg="1" style={{ paddingTop: '0.5rem' }}>
                        <div className="aligntable">
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        {/*<td style={{ width: '80%' }}></td>*/}
                                        <td style={{ width: '15%' }}>
                                            <div id="DateSelectorDiv" className="btn btn-primary btn-sm filter-button weeklytd">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: '15%' }}>
                                                                <div>
                                                                    <label style={{ fontSize: '20px' }} htmlFor='fromdate'> <FontAwesomeIconMemo icon={'calendar'} className='icon-format' /></label>&nbsp;
                                                                </div>
                                                            </td>
                                                            <td style={{ width: '20%' }}>
                                                                <DatePicker className='InputStyle'
                                                                    id={'fromdate'}
                                                                    selected={monthlyStartDate}
                                                                    onChange={onmonthlyStartDateChange}
                                                                    maxDate={new Date()}
                                                                    minDate={new Date(2018, 11, 1)}
                                                                    dateFormat="MM/yyyy"
                                                                    showMonthYearPicker
                                                                //customInput={<button className="datePickerInput"> {moment(monthlyStartDate).format("MM/YYYY")} <FontAwesomeIconMemo icon={'table'} className="datePickerCalendarIcon" /></button>}
                                                                />  &nbsp;&nbsp;
                                                            </td>
                                                            <td style={{ width: '15%' }}> <div title='Clear' onClick={ClearMonthlyDate} style={{ paddingTop: '5px', cursor: 'pointer' }}>CLEAR</div></td>
                                                            <td style={{ width: '15%' }}></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                        <td style={{ width: '5%' }}></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
            </div>
            <div id='table_wrapper aligntable'>
                <div>

                </div>
                {
                    (isArrayWithLength(MonthlyData))
                        ?
                        <React.Fragment>
                            <table className='table tableGrid stickyTable monthlydatatable' id='tdDailydata' style={{ width: '95vw', overflow: 'auto', display: 'block', height: '75vh' }}>
                                <thead className='thead-inverse'>
                                    <tr className='monthlydatatr'>
                                        <th className='stickyth'>
                                            <div className='serviceHeader'>Service</div></th>
                                        <th className='stickythCol2'>
                                            <div className='viewHeader'>Heatmap View Name </div></th>
                                        <th className='stickythCol3'>
                                            <div className='targetHeader'>Target</div></th>
                                        {isArrayWithLength(MonthlyheaderArr) && MonthlyheaderArr.map((dh, key) => (

                                            <th style={{ width: dh.width }} className='tableheaderVal' key={key}>{dh.val}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {isArrayWithLength(MonthlyData) && MonthlyData.filter((item) => {
                                        if (filterBasedSearch(item)) {
                                            return true
                                        }
                                    }).map((dhs: IMonthlyReport, key) => (
                                        <React.Fragment key={key}>
                                            {
                                                <tr style={{ fontSize: '14px' }}>
                                                    {
                                                        (dhs.spanKpi > 0) ?
                                                            <td className='stickytd kpi data textalignleft' rowSpan={dhs.spanKpi > 0 ? dhs.spanKpi : 0}
                                                                style={{ backgroundColor: (dhs.viewName == "" || dhs.viewName == undefined || dhs.viewName == null) ? '#dde0ee' : '', border: '1px solid rgba(18, 73, 109, 0.4)', textAlign: "center" }}> {dhs.serviceName}</td>
                                                            : <React.Fragment></React.Fragment>
                                                    }                                                 
                                                    {
                                                        (dhs.isReachability === true) ?
                                                            <td className='ml01 stickytdWeeklyCol2 data tabletdVal textalignleft'>
                                                                <a href={isProd ? 'https://idshr.idfun.msidentity.com/ServiceHealth?serviceid=' + dhs.serviceId + '&name=Daily&frDate=@Today-15&toDate=@Today-0&viewId=' + dhs.viewId + '&tab=reachability' : 'https://idshr.idfun-test.msidentity.com/ServiceHealth?serviceid=' + dhs.serviceId + '&name=Daily&frDate=@Today-15&toDate=@Today-0&viewId=' + dhs.viewId + '&tab=reachability'} target='_self' title='Click for Reachability Heatmap view'>
                                                                    {dhs.viewName}
                                                                </a>
                                                            </td>
                                                            : <td className='ml01 stickytdWeeklyCol2 data tabletdVal textalignleft'>
                                                                <a href={isProd ? 'https://idshr.idfun.msidentity.com/ServiceHealth?serviceid=' + dhs.serviceId + '&name=Daily&frDate=@Today-15&toDate=@Today-0&viewId=' + dhs.viewId + '&tab=heatmap' : 'https://idshr.idfun-test.msidentity.com/ServiceHealth?serviceid=' + dhs.serviceId + '&name=Daily&frDate=@Today-15&toDate=@Today-0&viewId=' + dhs.viewId + '&tab=heatmap'} target='_self' title='Click for Heatmap view'>
                                                                    {dhs.viewName}
                                                                </a>
                                                            </td>
                                                    }
                                                    <td className='ml01 stickytdCol3 data tabletdVal'> <a style={{ textDecoration: 'underline', color: '#0080D5' }} onClick={e => EditTarget(e, dhs)} >{dhs.targetName}</a></td>
                                                    {isArrayWithLength(liKPIValueKeys) && liKPIValueKeys.map((dk, kpiArrkey) => (
                                                        <React.Fragment key={kpiArrkey}>
                                                            {
                                                                <td className={AddColorForFactAvailabilityBudget(dhs.liKPIValues, dk, dhs.targetName)} style={{ textAlign: 'center', border: '1px solid rgba(18, 73, 109, 0.4)' }} key={kpiArrkey}>{dhs.liKPIValues[dk]}</td>
                                                            }
                                                        </React.Fragment>
                                                    ))}
                                                </tr>
                                            }

                                        </React.Fragment>

                                    ))}
                                </tbody>
                            </table>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {
                                (isDataloaded)
                                    ? <div style={{ color: 'Red', fontWeight: 'bold', textAlign: 'center' }}><br />No Data Available</div>
                                    : <React.Fragment></React.Fragment>
                            }
                        </React.Fragment>
                }
            </div>

        </div>
    )
});

MonthlyAvailbilityTable.displayName = 'MonthlyAvailbilityTable';
export default MonthlyAvailbilityTable;