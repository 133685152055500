import React, { useEffect, useRef } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import { actionCreators, reducer, IPropfutureOnCallData } from '../../store/FutureOnCall';
import { FutureOnCallTable } from '../FutureOnCall/child-components';
import { isArrayWithLength, renderToastifyMsg } from '../../utils';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import {
    Row,
    Col, Card, CardText
} from 'reactstrap';
import { DeprecatedFunctionalityBanner } from '../../components';

type FutureOnCallProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & typeof useParams;

const FutureOnCall: React.FC<FutureOnCallProps> = ({
    isLoading,
    addUserVisit,
    isAuthenticated,
    GetFutureOncallList,
    futureOnCallDataFiltered,
    searchText,
    sortData,
    reverseSort,
    sortcolumn,
    TenantTeamNamesList,
    CloudNamesList,
    OpenEmailPOPUP,
    Isopenemailpopup,
    handleemailpopupClose,
    OnCallAliasEmailIDs,
    BackupOnCallAliasEmailIDs,
    copyEmailselectedImorOce,
    handleTenantChange,
    handleTeamChange,
    selectedTenant,
    selectedTeam,
    teamList,
    tenantList,
    getFilteredOnCallList,
    clearDropdownValues,
    handleCloudChange,
    selectedCloud,
    ServiceLifeCycleList,
    selectedServiceLifeCycle,
    handleServiceLifeCycleChange
}) => {
    const toastIdRef = useRef(null);
    useEffect(() => {
        if (isAuthenticated)
            addUserVisit(0, 'FutureOnCall.tsx_v2');
    }, [isAuthenticated]);

    useEffect(() => {
        GetFutureOncallList();
        //GetTenantTeamNameList();
    }, [GetFutureOncallList]);

    const copyToClipBoard = (email) => {
        var textField = document.createElement('textarea');
        textField.innerText = email;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        if (!toast.isActive(toastIdRef.current)) {
            toastIdRef.current = toast.success(
                renderToastifyMsg('copied to clipboard',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
            );
        }

    }

    return (
        <div>
            <DeprecatedFunctionalityBanner />
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        <FutureOnCallTable
                            futureOnCallDataFiltered={futureOnCallDataFiltered}
                            TenantTeamNamesList={TenantTeamNamesList}
                            CloudNamesList={CloudNamesList}
                            searchText={searchText}
                            sortData={(e, value) => sortData(e, value)}
                            reverseSort={reverseSort}
                            sortcolumn={sortcolumn}
                            OpenEmailPOPUP={OpenEmailPOPUP}
                            handleTenantChange={(selectedTenant) => handleTenantChange(selectedTenant)}
                            selectedTenant={selectedTenant}
                            handleTeamChange={(selectedTeam) => handleTeamChange(selectedTeam)}
                            handleCloudChange={(selectedCloud) => handleCloudChange(selectedCloud)}
                            handleServiceLifeCycleChange={(selectedServiceLifeCycle) => handleServiceLifeCycleChange(selectedServiceLifeCycle)}
                            selectedCloud={selectedCloud}
                            selectedTeam={selectedTeam}
                            tenantList={tenantList}
                            teamList={teamList}
                            getFilteredOnCallList={getFilteredOnCallList}
                            clearDropdownValues={clearDropdownValues}
                            ServiceLifeCycleList={ServiceLifeCycleList}
                            selectedServiceLifeCycle={selectedServiceLifeCycle}
                        />
                    </div>
                </section>


                <Modal show={Isopenemailpopup} onHide={handleemailpopupClose} size='lg' backdrop='static'>
                    <Modal.Header closeButton className="commonModalHeader">
                        <div style={{ width: '100%' }}>
                            <Row>
                                <Col lg='12' style={{ textAlign: 'center' }}>
                                    <h4><b> Copy Email  </b></h4>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col lg='12'>
                                <table className="tblwidth100" id="TblSendEmails">
                                    <tbody>
                                        {
                                            (BackupOnCallAliasEmailIDs === "" && OnCallAliasEmailIDs === "")
                                                ? <tr style={{ paddingBottom: '40px' }}>
                                                    <td style={{ color: 'red', fontSize: '18px' }}>No Email Address Found</td>
                                                </tr>
                                                : <React.Fragment></React.Fragment>
                                        }
                                        {
                                            (OnCallAliasEmailIDs !== "")
                                                ? <tr style={{ paddingBottom: '40px' }}>
                                                    {
                                                        (copyEmailselectedImorOce === "All") ?
                                                            <td className="tdwidth15">Primary OnCall Alias</td>
                                                            : <React.Fragment></React.Fragment>
                                                    }
                                                    <td className="tdwidth5">:</td>
                                                    <td className="tdwidth80">

                                                        {OnCallAliasEmailIDs} &nbsp;
                                                    <FontAwesomeIcon title="click to copy" onClick={e => copyToClipBoard(OnCallAliasEmailIDs)} className="copyemailicons" icon={faCopy} />
                                                    </td>
                                                </tr>
                                                : <React.Fragment></React.Fragment>
                                        }

                                        <tr className="tdheight20"></tr>
                                        {
                                            (BackupOnCallAliasEmailIDs !== "")
                                                ? <tr style={{ paddingBottom: '40px' }}>
                                                    {
                                                        (copyEmailselectedImorOce === "All") ?
                                                            <td>Backup OnCall Alias</td>
                                                            : <React.Fragment></React.Fragment>
                                                    }
                                                    <td>:</td>
                                                    <td>{BackupOnCallAliasEmailIDs} &nbsp;
                                                    <FontAwesomeIcon title="click to copy" onClick={e => copyToClipBoard(BackupOnCallAliasEmailIDs)} className="copyemailicons" icon={faCopy} />
                                                    </td>
                                                </tr>
                                                : <React.Fragment></React.Fragment>
                                        }
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

            </LoadingOverlay>

        </div>
    );
};
const mapStateToProps = (state: IApplicationState) => ({
    ...state.FutureOnCall,
    isAuthenticated: state.authUser.isAuthenticated

})

export default connect(mapStateToProps, actionCreators)(FutureOnCall);
