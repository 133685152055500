export type IPropidentityNewsLetterGroupData = {
    readonly groupID: number;
    readonly groupName: string;
    readonly weeklyAggAvailability: string;
};

export type IPropIdentityWeeklyAvailability = {
    readonly serviceID: number;
    readonly serviceName: string;
    readonly viewId: number;
    readonly viewName: string;
    readonly identityNewsLetterGroup: string;
    readonly weeklyAvailability: string;
    readonly targetName: string;
};

export type IPropIdentityTopOutages = {
    readonly topOutageID: number;
    readonly numberOfSev1: string;
    readonly numberOfSev2: string;
    readonly numberOfUniqueCCL: string;
    readonly weekDate: string;
    readonly weekId: number;
    readonly sev1Customer: string;
    readonly sev2Customer: string;
    readonly outageswithExternal: string;
    readonly incidentNotes: string;
    readonly notes: string;
    readonly isActive: boolean;
    readonly createdBy: string;
    readonly createdDate: string;
    readonly updatedBy: string;
    readonly updatedDate: string;
    readonly topOutageTable: string;
};

export type IPropIdentityTopOutagesTable = {
    readonly severity: string;
    readonly incidentId: string;
    readonly tenantteam: string;
    readonly teamName: string;
    readonly impactStartTimeUTC: string;
    readonly incidentCreateDateUTC: string;
    readonly outageDeclaredDateUTC: string;
    readonly notifiedTimeUTC: string;
    readonly mitigateDateUTC: string;
    readonly ttm: string;
    readonly ttn: string;
    readonly impactedtenants: string;
    readonly subCount: string;
    readonly pirStatus: string;
};

export type IPropIdentitySev12Table = {
    readonly severity: number;
    readonly totalCount: number;   
};


export type IPropIdentityObjectives = {
    readonly objectiveID: number;
    readonly name: string;
    readonly startDate: string;
    readonly endDate: string;
    readonly status: string;
    readonly notes: string;
    readonly isActive: boolean;
    readonly createdBy: string;
    readonly createdDate: string;
    readonly updatedBy: string;
    readonly updatedDate: string;
};

export type IPropIdentityOKRs = {
    readonly keyResultID: number;
    readonly objectiveID: number;
    readonly keyResults: string;
    readonly weekDate: string;
    readonly weekId: number;
    readonly targetValue: string;
    readonly progressValue: string;
    readonly notes: string;
    readonly isActive: boolean;
    readonly createdBy: string;
    readonly createdDate: string;
    readonly updatedBy: string;
    readonly updatedDate: string;
};

export type IPropIdentityKeyResults = {
    readonly keyResultID: number;
    readonly objectiveID: number;
    readonly keyResults: string;
    keyResultsEdit: string;
    readonly weekDate: string;
    readonly weekId: number;
    readonly targetValue: string;
    targetValueEdit: string;
    readonly progressValue: string;
    progressValueEdit: string;
    readonly notes: string;
    readonly isActive: boolean;
    readonly createdBy: string;
    readonly createdDate: string;
    readonly updatedBy: string;
    readonly updatedDate: string;
    isEdit: boolean;
};

export type OKRObjectiveDropdownOption = {
    name: string;
    value: any;
};

export type IStylesDataList = {
    styleName: string;
    stypleValue: string;
};

export type IdentityWeeklyNewsLetterState = {
    readonly newsLetterGroupsData: IPropidentityNewsLetterGroupData[];
    readonly isLoading: boolean;
    readonly IsLivesitePMOrUser: boolean;
    readonly WeekStartDate: string;
    readonly StartDate: string;
    readonly EndDate: string;
    readonly newsLetterWeeklyAvailabilityData: IPropIdentityWeeklyAvailability[];
    readonly newsLetterWeeklySafeflyData: [];
    readonly newsLetterWeeklySafeflyDataOptions: [];
    readonly newsLetterWeeklyOutagesData: [];
    readonly newsLetterWeeklyOutagesDataOptions: [];
    readonly newsLetterWeeklyCommsData: [];
    readonly newsLetterWeeklyCommsDataOptions: [];
    readonly outagesWeeksHeaderList: [];
    readonly outagesWeeklySortedData: [];
    readonly commsWeeklySortedData: [];
    readonly commsWeeksHeaderList: [];
    readonly SafeflyMetricsTotal: number;
    readonly newsLetterWeeklyChangeToolData: [];
    readonly newsLetterWeeklyChangeToolDataOptions: [];
    readonly ChangeToolMetricsTotal: number;
    readonly newsLetterWeeklyChangeExceptionData: [];
    readonly newsLetterWeeklyChangeExceptionDataOptions: [];
    readonly ChangeExceptionMetricsTotal: number;
    readonly newsLetterObjectivesData: IPropIdentityObjectives[];
    readonly stylesData: IStylesDataList[];
    readonly newsLetterOKRsData: IPropIdentityOKRs[];
    readonly newsLettertopOutagesData: [];
    readonly originalNewsLettertopOutagesData: [];
    readonly newsLettertopOutagesDataTable: [];    
    // Divisional OKRs related
    readonly newsLetterDivisionalObjectivesData: IPropIdentityObjectives[];
    newsLetterOKRsObjectives: any;
    selectedObjective: OKRObjectiveDropdownOption;
    readonly newsLetterDivisionalKeyResultsData: IPropIdentityKeyResults[];
    selectedKeyResults: any;
    readonly ObjectiveStartDate: string;
    readonly ObjectiveEndDate: string;
    OKRWeekStartDate: string;
    newObjective: string;
    isAddNewObjective: boolean;
    readonly newsLetterSev1Sev2IncidentsData: [];
    readonly newsLetterSev1Sev2IncidentsDataOptions: [];
    readonly appSessionToken: string;
    readonly sev1TotalCount: number;
    readonly sev2TotalCount: number;
};

const _namespace = 'IDENTITYWEEKLYNEWSLETTER';
export interface IActionType {
    readonly RESET_STATE: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTER_REQUEST: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTERGROUPSDATA_SUCCESS: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTERGROUPSDATA_FAILURE: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTERWEEKLYAVAILABILITYDATA_SUCCESS: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTERWEEKLYAVAILABILITYDATA_FAILURE: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTERSAFEFLYDATA_SUCCESS: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTERSAFEFLYDATA_FAILURE: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTERCHANGETOOLDATA_SUCCESS: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTERCHANGETOOLDATA_FAILURE: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTERCHANGEEXCEPTIONDATA_SUCCESS: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTERCHANGEEXCEPTIONDATA_FAILURE: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTEROBJECTIVESDATA_SUCCESS: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTEROBJECTIVESDATA_FAILURE: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTEROKRSDATA_SUCCESS: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTEROKRSDATA_FAILURE: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_SUCCESS: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_FAILURE: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_TABLE_SUCCESS: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_TABLE_FAILURE: string;
    readonly DELETE_TOPOUTAGE_DATA_REQUEST: string;
    readonly DELETE_TOPOUTAGE_DATA_FAIL: string;
    readonly SET_LOADING_TRUE: string;
    readonly SET_LOADING_FALSE: string;
    readonly WEEKLY_START_DATE_CHANGE: string;
    readonly TOPOUTAGES_INPUT_CHANGE: string;
    // Divisional OKRs 
    readonly GET_IDENTITYNEWSLETTERDIVSIONALOBJECTIVES_SUCCESS: string;
    readonly GET_IDENTITYNEWSLETTERDIVSIONALOBJECTIVES_FAILURE: string;
    readonly GET_IDENTITYNEWSLETTERDIVSIONALOKRS_SUCCESS: string;
    readonly GET_IDENTITYNEWSLETTERDIVSIONALOKRS_FAILURE: string;
    readonly IDENTITYNEWSLETTER_OKROBJECTIVE_CHANGE: string;
    readonly IDENTITYNEWSLETTER_OBJECTIVES_STARTDATE_CHANGE: string;
    readonly IDENTITYNEWSLETTER_OBJECTIVES_ENDDATE_CHANGE: string;
    readonly IDENTITYWEEKLY_OKRS_START_DATE_CHANGE: string;
    readonly IDENTITYNEWSLETTER_OBJECTIVE_ADD: string;
    readonly NEWSLETTER_KEYRESULTS_CONTROLS_CHANGE: string;
    readonly GET_IDENTITYNEWSLETTERKEYRESULTS_REQUEST: string;
    readonly GET_IDENTITYNEWSLETTERKEYRESULTS_SUCCESS: string;
    readonly GET_IDENTITYNEWSLETTERKEYRESULTS_FAILURE: string;
    readonly GET_IDENTITYNEWSLETTEROBJECTIVES_REQUEST: string;
    readonly GET_IDENTITYNEWSLETTEROBJECTIVES_SUCCESS: string;
    readonly GET_IDENTITYNEWSLETTEROBJECTIVES_FAILURE: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTERSEV1SEV2INCIDENTSDATA_SUCCESS: string;
    readonly GET_IDENTITYWEEKLYNEWSLETTERSEV1SEV2INCIDENTSDATA_FAILURE: string;
    readonly NEWSLETTER_OBJECTIVES_CANCEL_CHANGE: string;
    readonly IDENTITYNEWSLETTER_RESET_OKRS_INPUTS: string;
    readonly ADDED_VISITED_USER: string;
    readonly USER_CHECK: string;
    readonly SEND_EMAIL_REQUEST: string;
    readonly SEND_EMAIL_REQUEST_FAIL: string;
    readonly SEND_EMAIL_REQUEST_PASS: string;
    readonly GET_IDENTITYNINTYDAYSOUTAGESLDATA_SUCCESS: string;
    readonly GET_IDENTITYNINTYDAYSOUTAGESLDATA_FAILURE: string;
    readonly GET_IDENTITYNINTYDAYSCOMMSDATA_SUCCESS: string;
    readonly GET_IDENTITYNINTYDAYSCOMMSDATA_FAILURE: string;
    readonly SET_EMAIL_LOADING_TRUE: string;
    readonly GET_CSS_BY_COMPONENT: string;
}

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/identitynewsletterreset`,
    GET_IDENTITYWEEKLYNEWSLETTER_REQUEST: `${_namespace}/getidentityweeklynewsletterrequest`,
    GET_IDENTITYWEEKLYNEWSLETTERGROUPSDATA_SUCCESS: `${_namespace}/getidentityweeklynewslettergroupsdatasuccess`,
    GET_IDENTITYWEEKLYNEWSLETTERGROUPSDATA_FAILURE: `${_namespace}/getidentityweeklynewslettergroupsdatafailure`,
    GET_IDENTITYWEEKLYNEWSLETTERWEEKLYAVAILABILITYDATA_SUCCESS: `${_namespace}/getidentityweeklynewsletterweeklyavailabilitydatasuccess`,
    GET_IDENTITYWEEKLYNEWSLETTERWEEKLYAVAILABILITYDATA_FAILURE: `${_namespace}/getidentityweeklynewsletterweeklyavailabilitydatafailure`,
    GET_IDENTITYWEEKLYNEWSLETTERSAFEFLYDATA_SUCCESS: `${_namespace}/getidentityweeklynewslettersafeflydatasuccess`,
    GET_IDENTITYWEEKLYNEWSLETTERSAFEFLYDATA_FAILURE: `${_namespace}/getidentityweeklynewslettersafeflydatafailure`,
    GET_IDENTITYWEEKLYNEWSLETTERCHANGETOOLDATA_SUCCESS: `${_namespace}/getidentityweeklynewsletterchangetooldatasuccess`,
    GET_IDENTITYWEEKLYNEWSLETTERCHANGETOOLDATA_FAILURE: `${_namespace}/getidentityweeklynewsletterchangetooldatafailure`,
    GET_IDENTITYWEEKLYNEWSLETTERCHANGEEXCEPTIONDATA_SUCCESS: `${_namespace}/getidentityweeklynewsletterchangeexceptiondatasuccess`,
    GET_IDENTITYWEEKLYNEWSLETTERCHANGEEXCEPTIONDATA_FAILURE: `${_namespace}/getidentityweeklynewsletterchangeexceptiondatafailure`,
    GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_SUCCESS: `${_namespace}/getidentityweeklynewslettertopoutagesdatasuccess`,
    GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_FAILURE: `${_namespace}/getidentityweeklynewslettertopoutagesdatafailure`,
    GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_TABLE_SUCCESS: `${_namespace}/getidentityweeklynewslettertopoutagesdatatablesuccess`,
    GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_TABLE_FAILURE: `${_namespace}/getidentityweeklynewslettertopoutagesdatatablefailure`,
    GET_IDENTITYWEEKLYNEWSLETTEROBJECTIVESDATA_SUCCESS: `${_namespace}/getidentityweeklynewsletterobjectivesdatasuccess`,
    GET_IDENTITYWEEKLYNEWSLETTEROBJECTIVESDATA_FAILURE: `${_namespace}/getidentityweeklynewsletterobjectivesdatafailure`,
    GET_IDENTITYWEEKLYNEWSLETTEROKRSDATA_SUCCESS: `${_namespace}/getidentityweeklynewsletterokrsdatasuccess`,
    GET_IDENTITYWEEKLYNEWSLETTEROKRSDATA_FAILURE: `${_namespace}/getidentityweeklynewsletterokrsdatafailure`,
    GET_IDENTITYWEEKLYNEWSLETTERSEV1SEV2INCIDENTSDATA_SUCCESS: `${_namespace}/getidentityweeklynewslettersev1sev2incidentsdatafailure`,
    GET_IDENTITYWEEKLYNEWSLETTERSEV1SEV2INCIDENTSDATA_FAILURE: `${_namespace}/getidentityweeklynewslettersev1sev2incidentsdatafailure`,
    DELETE_TOPOUTAGE_DATA_REQUEST: `${_namespace}/deletetopoutagedatarequest`,
    DELETE_TOPOUTAGE_DATA_FAIL: `${_namespace}/deletetopoutagedatafail`,
    SET_LOADING_TRUE: `${_namespace}/loadingtrue`,
    SET_LOADING_FALSE: `${_namespace}/loadingfalse`,
    WEEKLY_START_DATE_CHANGE: `${_namespace}/weeklystartDateChange`,
    TOPOUTAGES_INPUT_CHANGE: `${_namespace}/topoutagesinputChange`,
    GET_IDENTITYNEWSLETTERDIVSIONALOBJECTIVES_SUCCESS: `${_namespace}/getidentityweeklynewsletterdivisionalobjectivessuccess`,
    GET_IDENTITYNEWSLETTERDIVSIONALOBJECTIVES_FAILURE: `${_namespace}/getidentityweeklynewsletterdivisionalobjectivesfailure`,
    GET_IDENTITYNEWSLETTERDIVSIONALOKRS_SUCCESS: `${_namespace}/getidentityweeklynewsletterdivisionalkeyresultsdatasuccess`,
    GET_IDENTITYNEWSLETTERDIVSIONALOKRS_FAILURE: `${_namespace}/getidentityweeklynewsletterdivisionalkeyresultsdatafailure`,
    IDENTITYNEWSLETTER_OKROBJECTIVE_CHANGE: `${_namespace}/identitynewsletterokrobjectivechange`,
    IDENTITYNEWSLETTER_OBJECTIVES_STARTDATE_CHANGE: `${_namespace}/identitynewsletterokrobjectivestartdatechange`,
    IDENTITYNEWSLETTER_OBJECTIVES_ENDDATE_CHANGE: `${_namespace}/identitynewsletterokrobjectiveenddatechange`,
    IDENTITYWEEKLY_OKRS_START_DATE_CHANGE: `${_namespace}/identitynewsletterokrobjectiveweekstartdatechange`,
    IDENTITYNEWSLETTER_OBJECTIVE_ADD: `${_namespace}/identitynewsletterokrobjectiveadd`,
    NEWSLETTER_KEYRESULTS_CONTROLS_CHANGE: `${_namespace}/newsletterkeyresultscontrolschange`,
    GET_IDENTITYNEWSLETTERKEYRESULTS_REQUEST: `${_namespace}/getidentitynewsletterkeyresultsrequest`,
    GET_IDENTITYNEWSLETTERKEYRESULTS_SUCCESS: `${_namespace}/getidentitynewsletterkeyresultssuccess`,
    GET_IDENTITYNEWSLETTERKEYRESULTS_FAILURE: `${_namespace}/getidentitynewsletterkeyresultsfailure`,
    GET_IDENTITYNEWSLETTEROBJECTIVES_REQUEST: `${_namespace}/getidentitynewsletterobjectivesrequest`,
    GET_IDENTITYNEWSLETTEROBJECTIVES_SUCCESS: `${_namespace}/getidentitynewsletterobjectivessuccess`,
    GET_IDENTITYNEWSLETTEROBJECTIVES_FAILURE: `${_namespace}/getidentitynewsletterobjectivesfailure`,
    NEWSLETTER_OBJECTIVES_CANCEL_CHANGE: `${_namespace}/newsletterobjectivescancelchange`,
    IDENTITYNEWSLETTER_RESET_OKRS_INPUTS: `${_namespace}/identitynewsletterresetokrsinputs`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    USER_CHECK: `${_namespace}/UserCheck`,
    SEND_EMAIL_REQUEST: `${_namespace}/send_email_request`,
    SEND_EMAIL_REQUEST_FAIL: `${_namespace}/send_email_request_fail`,
    SEND_EMAIL_REQUEST_PASS: `${_namespace}/send_email_request_pass`,
    GET_IDENTITYNINTYDAYSOUTAGESLDATA_SUCCESS: `${_namespace}/getnintydaysoutagesuccess`,
    GET_IDENTITYNINTYDAYSOUTAGESLDATA_FAILURE: `${_namespace}/getnintydaysoutagefailure`,
    GET_IDENTITYNINTYDAYSCOMMSDATA_SUCCESS: `${_namespace}/getnintydayscommssuccess`,
    GET_IDENTITYNINTYDAYSCOMMSDATA_FAILURE: `${_namespace}/getnintydayscommsfailure`,
    SET_EMAIL_LOADING_TRUE: `${_namespace}/set_email_loading_true`,
    GET_CSS_BY_COMPONENT: `${_namespace}/get_css_by_component`
});

