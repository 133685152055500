import { IAppThunkAction, ReduxAction } from '../';
import { SecuritySHRAPI, CommonAPI } from '../../api';
import { isNullOrUndefined } from '../../utils';
import { ActionType, ISecurityAdminLock, IPropPresenterTable, IPropSuccessStoriesWishr, IPropPublicServiceAnnouncementSecuritySHR } from './types';
import moment from 'moment';

export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    setloadingtrue: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
    },
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }

    },
    setloadingfalse: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_FALSE
        });
    },
    GetMeetingDate: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let userId = getState().authUser.login;
        const meetingdate = getMeetingDate();
        const maxdate = getMaxDate();
        dispatch({
            meetingdate,
            maxdate,
            userId,
            type: ActionType.SET_MEETINGDATE_DATE
        });
    },

    setpsaccordiontoggledata: (status): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            psaaccordiontoggle: status,
            type: ActionType.PSAACCORDION_STATUS
        });
    },

    GetWeeklySecurityData: (Meetingdate: string, Callcount): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let userId = getState().authUser.login;
        dispatch({
            userId,
            type: ActionType.GET_WEEKLYIDSHR_REQUEST
        });
        let count = 0;
        if (Callcount != 4) {
            count = 4;
        }

        if (Callcount === 1 || Callcount === 4) {
            //==================== to get data for PUBLIC SERVICE ANNOUNCEMENT =================//
            SecuritySHRAPI.GetSecurityPubServiceAccData(Meetingdate)
                .then((PublicServiceAnnouncementData: IPropPublicServiceAnnouncementSecuritySHR[]) => {
                    count = count + 1;
                    let psaaccordiontoggleStatus = PublicServiceAnnouncementData.length > 0 ? true : false;
                    dispatch({
                        PublicServiceAnnouncementData,
                        count: count,
                        psaaccordiontoggle: true,
                        type: ActionType.GET_PUBLICSERVICEACC_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_PUBLICSERVICEACC_FAILURE
                    });
                });
        }
        if (Callcount === 4 || Callcount === 2) {
            //==================== to get data for Success Stories =================//
            SecuritySHRAPI.GetSecuritySuccessStorieData(Meetingdate)
                .then((SuccessStoriesData: IPropSuccessStoriesWishr[]) => {
                    count = count + 1;
                    let ssaccordiontoggleStatus = SuccessStoriesData.length > 0 ? true : false;
                    dispatch({
                        SuccessStoriesData,
                        count: count,
                        ssaccordiontoggle: true,
                        type: ActionType.GET_SUCCESSSTORIES_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_SUCCESSSTORIES_FAILURE
                    });
                });
        }
    },

    handleMeetingDateChange: (MeetingDate: Date): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            MeetingDate: MeetingDate,
            isLockedSecurity: getState().SecuritySHR.isLockedSecurityArray.filter((e) => { if (new Date(e).toUTCString() == new Date(MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
            isLockedSuccessStories: getState().SecuritySHR.isLockedSuccessStoriesArray.filter((e) => { if (new Date(e).toUTCString() == new Date(MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
            type: ActionType.MEETINGDATE_DATE_CHANGE
        })
    },


    EditLockforPubServiceAcc: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let meetingDate = moment(getState().SecuritySHR.MeetingDate).format('YYYY-MM-DD');
        let lockValue = getState().SecuritySHR.isLockedSecurity == true ? 0 : 1;
        SecuritySHRAPI.PostSecurityAdminLock(1, meetingDate, lockValue, getState().authUser.login).
            then((value: ISecurityAdminLock[]) => {
                dispatch({
                    isLockedSecurity: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().SecuritySHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSuccessStories: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().SecuritySHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSecurityArray: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedSuccessStoriesArray: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }),
                    type: ActionType.GET_SECURITYADMINLOCK
                });
            });
    },
    EditLockforSuccessStories: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let meetingDate = moment(getState().SecuritySHR.MeetingDate).format('YYYY-MM-DD');
        let lockValue = getState().SecuritySHR.isLockedSuccessStories == true ? 0 : 1;
        SecuritySHRAPI.PostSecurityAdminLock(2, meetingDate, lockValue, getState().authUser.login).
            then((value: ISecurityAdminLock[]) => {
                dispatch({
                    isLockedSecurity: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().SecuritySHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSuccessStories: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().SecuritySHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSecurityArray: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedSuccessStoriesArray: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }),
                    type: ActionType.GET_SECURITYADMINLOCK
                });
            });
    },

    EditPubServiceAccRow: (val: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const PublicServiceAnnouncementData = getState().SecuritySHR.PublicServiceAnnouncementData;
        const publicServiceAcc = [];
        PublicServiceAnnouncementData.map((obj: IPropPublicServiceAnnouncementSecuritySHR) => {
            if (obj.id === val) {
                obj.isEdit = true;
                obj.presenterEdit = obj.presenter;
                obj.topicNameEdit = obj.topicName;
                obj.timeNeededEdit = obj.timeNeeded;
                obj.presentationLinkEdit = obj.presentationLink;
                obj.isTopicApprovedEdit = obj.isTopicApproved;
                obj.displayOrderEdit = obj.displayOrder;
            }
            else {
                obj.isEdit = false;
            }
            if (obj.id > 0)
                publicServiceAcc.push(obj);
        })
        dispatch({
            PublicServiceAnnouncementData: publicServiceAcc,
            isPSALockDisabledOnEdit: true,
            type: ActionType.ENABLE_EDITORADD_PUBLICSERVICEACC
        });
    },

    CancelEditPubServiceAccRow: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const PublicServiceAnnouncementData = getState().SecuritySHR.PublicServiceAnnouncementData;
        const publicServiceAcc = [];
        PublicServiceAnnouncementData.map((obj) => {
            obj.isEdit = false;
            obj.presenterEdit = obj.presenter;
            obj.topicNameEdit = obj.topicName;
            obj.timeNeededEdit = obj.timeNeeded;
            obj.presentationLinkEdit = obj.presentationLink;
            obj.isTopicApprovedEdit = obj.isTopicApproved;
            obj.displayOrderEdit = obj.displayOrder;
            if (obj.id > 0)
                publicServiceAcc.push(obj);
        })

        let psaaccordiontoggleStatus = PublicServiceAnnouncementData.length > 0 ? true : false;
        dispatch({
            PublicServiceAnnouncementData: publicServiceAcc,
            count: 4,
            isPSALockDisabledOnEdit: false,
            specialaccordiontoggle: true,
            type: ActionType.GET_PUBLICSERVICEACC_SUCCESS
        });
    },

    EnableAddNewRowforPubServiceAcc: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const PublicServiceAnnouncementData = getState().SecuritySHR.PublicServiceAnnouncementData;
        const publicServiceAcc = [];
        PublicServiceAnnouncementData.map((obj: IPropPresenterTable) => {
            if (obj.id > 0) {
                if (obj.isEdit = true)
                    obj.isEdit = false;
                publicServiceAcc.push(obj);
            }
        })
        let NewCOPIR = {
            id: 0,
            presenterEdit: '',
            topicNameEdit: '',
            timeNeededEdit: '',
            presentationLinkEdit: '',
            isTopicApprovedEdit: false,
            displayOrderEdit: publicServiceAcc.length + 1,
            isEdit: true
        }
        publicServiceAcc.push(NewCOPIR);
        dispatch({
            PublicServiceAnnouncementData: publicServiceAcc,
            isPSALockDisabledOnEdit: true,
            type: ActionType.ENABLE_EDITORADD_PUBLICSERVICEACC
        });
    },

    OnPIRPubServiceChangevalues: (e, id): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const PublicServiceAnnouncementData = getState().SecuritySHR.PublicServiceAnnouncementData;
        const publicSerAcc = [];
        let { value, name, checked, type } = e.target;
        if (value === undefined)
            value = '';
        PublicServiceAnnouncementData.map((obj: IPropPresenterTable) => {

            if (obj.id === id) {
                if (type === 'checkbox') {
                    obj[name] = checked;
                }
                else {
                    obj[name] = value;
                }
            }

            publicSerAcc.push(obj);
        })
        dispatch({
            PublicServiceAnnouncementData: publicSerAcc,
            type: ActionType.PUBLICSERVICEANNOUNCEMENT_CHANGE
        });
    },

    UpdatePubServiceAccDataOrder: (data: IPropPublicServiceAnnouncementSecuritySHR[]): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const PublicServiceAnnouncementData = getState().SecuritySHR.PublicServiceAnnouncementData;
        let callActualData: number = 0;
        data.forEach((row, index) => {
            if (PublicServiceAnnouncementData.some(x => x.id === row.id && x.displayOrder !== index + 1)) {
                Callsetloadingtrue(dispatch);
                callActualData = callActualData + 1
                row.displayOrderEdit = (index + 1).toString()
                row.kpiDate = moment(getState().SecuritySHR.MeetingDate).format('MM/DD/YYYY');
                row.userName = getState().authUser.login;
                SecuritySHRAPI.AddorUpdateSecurityPubServiceAccData(row)
                    .then((result: number) => {
                        callActualData = callActualData - 1;
                        if (callActualData == 0) {
                            CallGetWeeklySecurityData(dispatch, moment(getState().SecuritySHR.MeetingDate).format('MM/DD/YYYY'), 1)
                        }
                    });
            }
        })
    },
    GetSecurityAdminLock: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        SecuritySHRAPI.GetSecurityAdminLock().
            then((value: ISecurityAdminLock[]) => {
                dispatch({
                    isLockedSecurity: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().SecuritySHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSuccessStories: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }).filter((e) => { if (new Date(e).toUTCString() == new Date(getState().SecuritySHR.MeetingDate).toUTCString()) { return e } }).length > 0 ? 1 : 0,
                    isLockedSecurityArray: value.filter((e) => { if (e.lock_Type === 1) { return e } }).map((e) => { return e.locked_Week }),
                    isLockedSuccessStoriesArray: value.filter((e) => { if (e.lock_Type === 2) { return e } }).map((e) => { return e.locked_Week }),
                    type: ActionType.GET_SECURITYADMINLOCK
                });
            });
    },
    EditSuccessStoriesRow: (val: number): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SuccessStoriesData = getState().SecuritySHR.SuccessStoriesData;
        const successStories = [];
        SuccessStoriesData.map((obj: IPropSuccessStoriesWishr) => {
            if (obj.id === val) {
                obj.isEdit = true;
                obj.presenterEdit = obj.presenter;
                obj.topicNameEdit = obj.topicName;
                obj.timeNeededEdit = obj.timeNeeded;
                obj.presentationLinkEdit = obj.presentationLink;
                obj.isTopicApprovedEdit = obj.isTopicApproved;
                obj.displayOrderEdit = obj.displayOrder;
            }
            else {
                obj.isEdit = false;
            }
            if (obj.id > 0)
                successStories.push(obj);
        })
        dispatch({
            SuccessStoriesData: successStories,
            isSSLockDisabledOnEdit: true,
            type: ActionType.ENABLE_EDITORADD_SUCCESSSTORIES
        });
    },
    CancelEditSuccessStoriesRow: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SuccessStoriesData = getState().SecuritySHR.SuccessStoriesData;
        const successStories = [];
        SuccessStoriesData.map((obj) => {
            obj.isEdit = false;
            obj.presenterEdit = obj.presenter;
            obj.topicNameEdit = obj.topicName;
            obj.timeNeededEdit = obj.timeNeeded;
            obj.presentationLinkEdit = obj.presentationLink;
            obj.isTopicApprovedEdit = obj.isTopicApproved;
            obj.displayOrderEdit = obj.displayOrder;
            if (obj.id > 0)
                successStories.push(obj);
        })
        let ssaccordiontoggleStatus = SuccessStoriesData.length > 0 ? true : false;
        dispatch({
            SuccessStoriesData: successStories,
            count: 4,
            isSSLockDisabledOnEdit: false,
            ssaccordiontoggle: true,
            type: ActionType.GET_SUCCESSSTORIES_SUCCESS
        });
    },
    EnableAddNewRowforSuccessStories: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SuccessStoriesData = getState().SecuritySHR.SuccessStoriesData;
        const successStories = [];
        SuccessStoriesData.map((obj: IPropPresenterTable) => {
            if (obj.id > 0) {
                if (obj.isEdit = true)
                    obj.isEdit = false;
                successStories.push(obj);
            }
        })
        let NewCOPIR = {
            id: 0,
            presenterEdit: '',
            topicNameEdit: '',
            timeNeededEdit: '',
            presentationLinkEdit: '',
            isTopicApprovedEdit: false,
            displayOrderEdit: successStories.length + 1,
            isEdit: true
        }
        successStories.push(NewCOPIR);
        dispatch({
            SuccessStoriesData: successStories,
            isSSLockDisabledOnEdit: true,
            type: ActionType.ENABLE_EDITORADD_SUCCESSSTORIES
        });
    },
    OnPIRSuccessStoriesChangevalues: (e, id): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SuccessStoriesData = getState().SecuritySHR.SuccessStoriesData;
        const successStories = [];
        let { value, name, checked, type } = e.target;
        if (value === undefined)
            value = '';
        SuccessStoriesData.map((obj: IPropPresenterTable) => {

            if (obj.id === id) {
                if (type === 'checkbox') {
                    obj[name] = checked;
                }
                else {
                    obj[name] = value;
                }
            }
            successStories.push(obj);
        })
        dispatch({
            SuccessStoriesData: successStories,
            type: ActionType.SUCCESSSTORIES_CHANGE
        });
    },
    UpdateSuccessStoriesDataOrder: (data: IPropSuccessStoriesWishr[]): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const SuccessStoriesData = getState().SecuritySHR.SuccessStoriesData;
        let callActualData: number = 0;
        data.forEach((row, index) => {
            if (SuccessStoriesData.some(x => x.id === row.id && x.displayOrder !== index + 1)) {
                Callsetloadingtrue(dispatch);
                callActualData = callActualData + 1
                row.displayOrderEdit = (index + 1).toString()
                row.kpiDate = moment(getState().SecuritySHR.MeetingDate).format('MM/DD/YYYY');
                row.userName = getState().authUser.login
                SecuritySHRAPI.AddorUpdateSecuritySuccessStoriesData(row)
                    .then((result: number) => {
                        callActualData = callActualData - 1;
                        if (callActualData == 0) {
                            CallGetWeeklySecurityData(dispatch, moment(getState().SecuritySHR.MeetingDate).format('MM/DD/YYYY'), 2)
                        }
                    });
            }
        })
    },
    setssaccordiontoggledata: (status): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            ssaccordiontoggle: status,
            type: ActionType.SSACCORDION_STATUS
        });
    },
};
const getMeetingDate = () => {
    const today = new Date(); // "07/23/2016"   
    if (!today.getDay()) {
        let sundaydate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 15);  // change number 8 to 15 when current day is sunday      
        sundaydate.setDate(sundaydate.getDate() + 12);
        return sundaydate;
    }
    const pMonday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 6);
    const date = new Date(pMonday.getFullYear(), pMonday.getMonth(), pMonday.getDate() - 2);
    date.setDate(date.getDate() + 10);
    return date;

}
const getMaxDate = () => {
    const today = new Date(); // "07/23/2016"   
    if (!today.getDay()) {
        let sundaydate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 15);  // change number 8 to 15 when current day is sunday      
        sundaydate.setDate(sundaydate.getDate() + 27);
        return sundaydate;
    }
    const pMonday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 6);
    const date = new Date(pMonday.getFullYear() + 3, pMonday.getMonth(), pMonday.getDate() - 2);
    date.setDate(date.getDate() + 27);
    return date;

}
// function to call setloadingtrue action method
const Callsetloadingtrue = (dispatch) => {
    dispatch(actionCreators.setloadingtrue());
}
// function to call setloadingtrue action method
const CallGetWeeklySecurityData = (dispatch, Meetingdate: string, Callcount) => {
    dispatch(actionCreators.GetWeeklySecurityData(Meetingdate, Callcount));
}

