import { BaseService } from './base.service';
import { IDataCenter } from '../store/DCMapping';

class DCMappingService extends BaseService {

    private static _dcmappingService: DCMappingService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): DCMappingService {
        return (this._dcmappingService || (this._dcmappingService = new this('DCMapping')));
    }

    public async GetDataCenters(): Promise<IDataCenter[]> {
        const { data } = await this.$http.get<IDataCenter[]>(`GetDataCenters`);
        return data;
    }

    public async UpdateRow(rowData: IDataCenter, insert: boolean): Promise<IDataCenter[]> {
        console.log(rowData)
        const Row = {
            Id: rowData.id,
            DataCenterName: rowData.dataCenterName,
            AzureRegionName: rowData.azureRegionName,
            Location: rowData.location,
            Country: rowData.country,
            ReferenceInfo: rowData.referenceInfo,
            RegionID: rowData.regionID,
            RegionOid: rowData.regionOid,
            RegionCode: rowData.regionCode,
            RegionLifeCycle: rowData.regionLifeCycle,
            RegionType: rowData.regionType,
            GeographyId: rowData.geographyId,
            updatedby: rowData.updatedby,
        };
        console.log(Row)
        console.log(insert)
        const { data } = await this.$http.post<IDataCenter[]>(`UpdateOrInsertDataCenter?insert=${insert}`, Row);        
        return data;
    }

    public async DeleteRecord(rowData: IDataCenter) {
        const Row = {
            Id: rowData.id,
            DataCenterName: rowData.dataCenterName,
            AzureRegionName: rowData.azureRegionName,
            Location: rowData.location,
            Country: rowData.country,
            ReferenceInfo: rowData.referenceInfo,
            RegionID: rowData.regionID,
            RegionOid: rowData.regionOid,
            RegionCode: rowData.regionCode,
            RegionLifeCycle: rowData.regionLifeCycle,
            RegionType: rowData.regionType,
            GeographyId: rowData.geographyId,
            updatedby: rowData.updatedby,
        };
        const { data } = await this.$http.post<IDataCenter[]>(`DeleteDataCenters`, Row);        
        return data;
    }
}

export const DCMappingAPI = DCMappingService.Instance;