import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import { actionCreators, reducer } from '../../store/CreateAdoHierarchy';
import LoadingOverlay from 'react-loading-overlay';
import "../../assets/style/scss/components/CreateAdoHierarchy.scss";
import { Row, Col, Button } from 'reactstrap';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
//import DropdownTreeSelect from 'react-dropdown-tree-select'
//import 'react-dropdown-tree-select/dist/styles.css'
//import RichTextEditor from 'react-rte';
//import { InputText } from 'primereact/inputtext';
import { GEMTree } from './child-components';
import { CreateAdoHierarchyTable } from './child-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { DeprecatedFunctionalityBanner } from '../../components';

type CreateAdoHierarchyProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly history: History }
    & typeof useParams;

const CreateAdoHierarchy: React.FC<CreateAdoHierarchyProps> = ({
    isLoading,
    isAuthenticated,
    gemServiceMappingData,
    addUserVisit,
    GetServiceTreeGemListData,
    nodeData,
    UpdateNodeData,
    checkedServices,
    description,
    UpdateDescriptionValue,
    chapterID,
    UpdateChapterIDValue,
    epicTitle,
    epicID,
    UpdateEpicTitleValue,
    UpdateEpicIDValue,
    iterationPath,
    UpdateIterationPathValue,
    createAdoTree,
    UpdateSelectAll,
    previousTitle,
    id,
    errorMsg
}) => {

    useEffect(() => {
        if (isAuthenticated)
            addUserVisit(0, 'CreateAdoHierarchy.tsx_v2');
        GetServiceTreeGemListData();
    }, [isAuthenticated]);


    const userGuideURL = "https://identitydivision.visualstudio.com/IdentityWiki/_wiki/wikis/IdentityWiki.wiki/18589/Create-ADO-Hierarchy-User-Guide";

    const openUrlInNewTab = (url) => {
        if (url !== '') {
            window.open(url, "_blank");
        }
    }

    return (
        <div>
            <DeprecatedFunctionalityBanner />
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        <div className='PageContent'>
                            <Row className="PageHeader">
                                <Col lg="12">
                                    <span className="PageHeaderContent">Create ADO Epic and Feature in Hierarchy</span>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col lg="12">
                                    <div className="alignrightitems">
                                        <React.Fragment><span className='ConfigGuide' onClick={e => openUrlInNewTab(userGuideURL)}>
                                            Create ADO Hierarchy User Guide
                                         </span>
                                            <FontAwesomeIcon style={{ cursor: 'pointer', fontWeight: 'bolder', fontSize: '15px', verticalAlign: 'top' }} onClick={e => openUrlInNewTab(userGuideURL)} title='Create ADO Hierarchy User Guide' className='icon-format-config' icon={faQuestionCircle} />&ensp;
                                        </React.Fragment>   
                                    </div>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col lg="3">
                                    <h5>GEM & Services</h5>
                                </Col>
                                <Col lg="9">
                                    <GEMTree nodeData={nodeData} UpdateNodeData={UpdateNodeData} UpdateSelectAll={UpdateSelectAll} />
                                </Col>
                            </Row>
                            <br></br>
                            <Row style={{ display: previousTitle == '' ? 'none' : 'block' }}>
                                <Col>
                                <label>ADO # <b>{id}</b> for previous created EPIC - title <b>{previousTitle}</b></label>
                                </Col>
                                <Col style={{ display: errorMsg == '' ? 'none' : 'block' }}>
                                    <label style={{ color: 'red' }}>There is error while creating the ADO Feature for few services and here is the list.<b> {errorMsg}</b></label>
                                </Col>
                            </Row>
                            <br></br>                           
                            <CreateAdoHierarchyTable description={description} UpdateDescriptionValue={UpdateDescriptionValue} chapterID={chapterID}
                                epicTitle={epicTitle} epicID={epicID} iterationPath={iterationPath} UpdateChapterIDValue={UpdateChapterIDValue} UpdateEpicTitleValue={UpdateEpicTitleValue}
                                UpdateIterationPathValue={UpdateIterationPathValue} UpdateEpicIDValue={UpdateEpicIDValue}
                            />
                            <br></br>
                            <br></br>
                            <Row >
                                <Col className="createTask">
                                    <Button className="createTaskButton btn btn-primary" disabled={(epicTitle === '' && epicID === '') || iterationPath === ''} onClick={createAdoTree}>Create Task</Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </section>
            </LoadingOverlay>
        </div>
    );
};

const mapStateToProps = (state: IApplicationState) => ({
    ...state.CreateAdoHierarchy,
    isAuthenticated: state.authUser.isAuthenticated
})

export default connect(mapStateToProps, actionCreators)(CreateAdoHierarchy);