import {
    IPropOnCallDashboardQuestionnaire, IPropOnCallDashboardTemplate
} from '../checklistTemplate/types';
export interface IActionType {
    readonly RESET_STATE: string;
    readonly ADDED_VISITED_USER: string;
    readonly FETCH_CHECKLIST_DATA: string;
    readonly CHECKLIST_DATA_SUCCESS: string;
    readonly CHECKLIST_DATA_FAIL: string;
    readonly OPEN_CHECKLIST_POPUP: string;
    readonly SHOWORHIDE_CHECKLIST_POPUP: string;
    readonly CHECKLIST_DATA_CHANGFE: string;
    readonly QUESTION_CONTROLS_CHANGFE: string;
    readonly ADD_QUESTIONS_TOLIST: string;
    readonly SHOW_QUESTIONLIST: string;
    readonly ENABLE_ADD_NEW_QUESTION: string;
    readonly SET_LOADING_TRUE: string;
    readonly SET_LOADING_FALSE: string;
    readonly CHECKLIST_EDIT: string;
    readonly CHECKLIST_SERVICES_SUCCESS: string;
    readonly CHECKLIST_SERVICES_FAIL: string;
    readonly CHECKLIST_TEMPLATE_DATA_SUCCESS: string;
    readonly CHECKLIST_TEMPLATE_DATA_FAIL: string;
    readonly CHECKLIST_TEMPLATE_QUESTIONS_SUCCESS: string;
    readonly CHECKLIST_TEMPLATE_QUESTIONS_FAIL: string;
    readonly GET_CHECKLISTDATA_REQUEST: string;
    readonly GET_MEETINGMAININCIDENTIDS_SUCCESS: string;
    readonly GET_MEETINGMAININCIDENTIDS_FAIL: string;
    readonly GET_CHECKLISTSERVICES_SUCCESS: string;
    readonly GET_CHECKLISTSERVICES_FAIL: string;
    readonly GET_SUBMITTEDLIST_SUCCESS: string;
    readonly GET_SUBMITTEDLIST_FAIL: string;
    readonly GET_CHECKLIST_SUCCESS: string;
    readonly GET_CHECKLIST_FAIL: string;
    readonly GET_OCELIST_SUCCESS: string;
    readonly GET_OCELIST_FAIL: string;
    readonly CHECKLISTDASHBOARD_CLEARFILTER: string;
    readonly OCELIST_FROMDATE_CHANGE: string;
    readonly OCELIST_TODATE_CHANGE: string;
    readonly CHECKLIST_DROPDOWN_CHANGE: string;
    readonly OCELIST_SEARCHTEXT_CHANGE: string;
    readonly OCELIST_MEETINGTITLE_CHANGE: string;
    readonly OCELIST_ICMINCIDENT_CHANGE: string;
    readonly INCIDENT_DROPDOWN_CHANGE: string;
    readonly SUBMITTEDBY_DROPDOWN_CHANGE: string;
    readonly SET_CHECKLISTDASHBOARDDATE: string;
    readonly GET_CHECKLIST_SUCCESS_OCEVIEW: string;
    readonly GET_CHECKLISTSERVICES_SUCCESS_READONLY: string;
    readonly GET_CHECKLIST_OCEVIEW_LOADDATA: string;
    readonly GET_MEETINGITEMS_SUCCESS_OCEVIEW: string;
    readonly GET_IFRAME_DATAURL: string;
    readonly OCEVIEW_EXPAND_COLLAPSE_QUESTION: string;
    readonly LOADQUESTIONS_OCEVIEW_SUCCESS: string;
    readonly GET_CHECKLISTSERVICES_SUCCESS_OCEVIEW: string;
    readonly CHANGE_EXPANDEDCOL_OCEVIEW: string;
    readonly OCEVIEW_SHOWINCIDENTS: string;
    readonly OCEVIEW_BACKWARD_FORWARD_QUESTION: string;
    readonly CHECKLIST_INCIDENTS_OCE: string;
    readonly GET_FILTERED_INCIDENTS_OCEVIEW: string;
    readonly SAVE_INCIDENTS_OCEVIEW: string;
    readonly UPDATE_ACTIONITEMS_OCEVIEW: any;
    readonly INCIDENTFILTER_FROMDATE_CHANGE: string;
    readonly INCIDENTFILTER_TODATE_CHANGE: string;
    readonly CHECKCHANGE_OCEVIEW_QUESTIONS: string;
    readonly ADD_ACTIONITEM_OCEVIEW: string;
    readonly ROWCANCEL_ACTIONITEM_OCEVIEW: string;
    readonly SET_SEVERITY_INCIDENTSPOPUP_OCEVIEW: string;
    readonly GET_CHECKLISTINCIDENTS_OCE_FAIL: string;
    readonly SORT_OCELIST: string;
    readonly GET_CHECKLISTINCIDENTS_OCE_REQUEST: string;
    readonly GET_CHECKLISTSERVICES_OCEVIEW_FAIL: string;
    readonly UPDATE_QUESTION_ACTIONITEM: string;
    readonly OCEINCIDENTSPOPUP_CLEARFILTER: string;
    readonly ONQUESTIONS_SAVE_OCEVIEW_REQUEST: string;
    readonly ONQUESTIONS_SAVE_OCEVIEW_SUCCESS: string;
    readonly ONQUESTIONS_SAVE_ANSWERS_OCEVIEW_SUCCESS: string;
    readonly ONQUESTIONS_SAVE_ANSWERS_OCEVIEW_FAIL: string;
    readonly LOGIN_USERNAME_OCE: string;
    readonly UPDATE_ACTIONITEMS_OCEVIEW_QUESTIONAAIRE: string;
    readonly SERVICECHANGE_ESTS_SERVICE_QUESTIONNAIRE: string;
    readonly ACTIONITEM_INPUTCHANGED_OCEVIEW: string;
    readonly CONTROLTYPE_VALUES_CHANGED: string;
    readonly FILE_UPLOAD_FILENAME_SUCCESS: string;
    readonly DOWNLOAD_FILENAME_SUCCESS: string;
    readonly GET_CHECKLISTS_TEMPLATETYPE_OCEVIEW: string;
    readonly OCELIST_ONCALLDATE_CHANGE: string;
    readonly TEMPLATETYPE_DROPDOWN_CHANGE: string;
    readonly ADD_CHECKLIST_QUESTIONS_DISPLAYORDER_TOLIST: string;
    readonly MOVETOARCHIVE: string;
    readonly MOVETOARCHIVE_SUCCESS: string;
    readonly MOVETOARCHIVE_FAIL: string;
    readonly OCELIST_ONCALLDATE_DISABLE: string;
    readonly GET_UIFOROCE_DATA: string;
    readonly ACCESS_DENIED: string;
}
export type TemplateQuestion = {
    readonly questionID: number;
    readonly displayOrder: number;
    readonly effectiveFrom: Date;
    readonly effectiveTo: Date;
}

export type OncallDependentValues = {
    readonly iCMPicker: boolean;
    readonly actionItems: boolean;
}

export type IPropOnCallChecklistTemplate = {
    readonly templateId: number;
    readonly templateName: string;
    readonly templateDescription: string;
    readonly templateNotes: string;
    readonly questionId: number;
    readonly isActive: boolean;
    readonly lastUpdatedBy: string;
    readonly lastUpdatedDate: Date;
    readonly createdBy: string;
    readonly createdDate: Date;
    readonly question: string;
    readonly objPropTemplateQuestion: TemplateQuestion[];
    readonly effectiveFrom: Date;
    readonly effectiveTo: Date;
    readonly dataUrl: string;
    readonly displayOrder: number;
    readonly checklistCount: number;
    readonly newQuestionList: string;
};

export type IPropOnCallChecklistQuestionnaire = {
    readonly questionID: number;
    readonly question: string;
    readonly controlType: string;
    readonly controlValues: string;
    readonly isActive: number;
    readonly lastUpdatedBy: string;
    readonly lastUpdatedDate: Date;
    readonly isChecked: boolean;
    readonly dataUrl: string;
    readonly effectiveFrom: Date;
    readonly effectiveTo: Date;
    readonly displayOrder: number;
    readonly templateID: number;
    readonly checkListID: number;
    readonly sourceType: string;
    readonly serverName: string;
    readonly database: string;
    readonly authenticationType: string;
    readonly isActionRequired: boolean;
    readonly actionItemValue: string;
    readonly dependsOn: string;
    readonly dependentValue: string;
    readonly matchIndex: number;
    readonly indexValue: number;
    readonly isdisplayDependsonValue: boolean;
    readonly dependsOnValueslist: string;
    readonly dependentValues: OncallDependentValues;
    readonly query: string;
    readonly isAnsweredBefore: boolean;
};


export type IPropOnCallChecklistService = {
    readonly serviceID: number;
    readonly serviceDisplayName: string;
};

export type MeetingEntityForOce = {
    meetingItemId: number;
    meetingDate: string;
    title: string;
    qualitySpends: string;
    actionItems: string;
    participants: string;
    serviceId: number;
    serviceDisplayName: string;
    meetingStatus: string;
    owner: string;
    createdBy: string;
    createdDate: string;
    updatedBy: string;
    updatedDate: string;
    isEdit: boolean;
    incidentIds: string;
    questionID: number;
    checkListId: number;
    meetingId: number;
}

export type ChecklistQuestionsForOce = {
    serviceId: number;
    cloudId: number;
    questionId: number;
    choiceId: number;
    meetingDate: string;
    isFristRequest: boolean;
    isLastQues: boolean;
    checklistId: string;
    meetingId: number;
    pageName: string;
}

export type OCEView = {
    readonly serviceId: string;
    readonly checkListId: string;
    readonly serviceName: string;
    readonly checkListName: string;
    readonly meetingDate: string;
    readonly createdBy: string;
    readonly meetingId: string;
    readonly meetingTitle: string;
    readonly updatedOn: string;
    readonly icmMainIncident: string;
    readonly checkListCreatedBy: string;
    meetingDateForSort: string;
    readonly templateType: any;
    readonly templateName: any;
    readonly templateRole: any;
    readonly onCallStatus: any;
    readonly onCallDate: string;
    onCallDateForSort: string;
    readonly onCallCreatedBy: string;
    readonly onCallCreatedDate: string;
    readonly onCallLastUpdatedBy: string;
    readonly onCallLastUpdatedDate: string;

};

export type Incidents = {
    readonly severity: number;
    readonly incidentId: string;
    readonly sourceOrigin: string;
    readonly status: string;
    readonly createDate: string;
    readonly title: string;
    readonly selected: boolean;
};

export type ChecklistQuestionsByChoiceForOce = {
    readonly answerId: number;
    readonly questionId: number;
    readonly questionName: string;
    readonly type: string;
    readonly isActionRequired: boolean;
    readonly actionItemValue: string;
    readonly selected?: number;
    readonly choiceId?: number;
    readonly cloudId?: number;
    readonly cloudName: string;
    readonly eTADate: string;
    readonly comment: string;
    readonly isShow: boolean;
    readonly incidentIds: string;
    readonly controlType: string;
    readonly controlValues: string;
    readonly selectedYes?: boolean;
    readonly dropdownSelection: string;
    readonly filePath: string;
    readonly checklistID: string;
    readonly dataUrl: string;
    readonly oCEControlValue: string;
    readonly chkvalue: string;
    readonly checkListQuestionId: string;
    readonly questionUrl: string;
    readonly sourceType: string;
    readonly serverName: string;
    readonly dbname: string;
    readonly isChanged: boolean;
    readonly isExpanded: boolean;
    readonly dependsOn: string;
    readonly dependentValue: string;
    readonly isSelected: number;
    readonly headerClass: string;
    readonly panelclass: string;
    readonly query: string;
    readonly authentication: string;
    readonly isReadonly: boolean;
    readonly color: string;
    readonly dynamicValidationStatus: boolean;
    readonly checklistDashboardCreatedBy: string;
    readonly errorMsg: string;
    readonly oceCertificateCompletionDate: string;
};
export type IPropOnCallDashboardChecklist = {
    readonly checkListID: number;
    readonly checkListName: string;
    readonly notes: string;
    readonly serviceID: number;
    readonly questionID: string;
    readonly dataUrl: string;
    readonly displayOrder: number;
    readonly effectiveFrom: Date;
    readonly effectiveTo: Date;
    readonly lastUpdatedBy: string;
    readonly lastUpdatedDate: Date;
    readonly isActive: boolean;
    readonly templateID: number;
    readonly objListQuestionnaireData: IPropOnCallDashboardQuestionnaire[];
    readonly serviceName: string;
    readonly questionListxml: string;
    readonly sourceType: string;
    readonly templateName: string;
    readonly templateType: string;

} 
export type PropOnCallDashboardService = {
    readonly serviceID: number;
    readonly serviceDisplayName: string;
}
export type ICheckListState = {
    readonly isLoading: boolean;  
    readonly checklistData: IPropOnCallDashboardChecklist[];  
    readonly showchecklistPopup: boolean;
    readonly questioncontroltypeoptions: [];
    readonly sourcetypes: [];
    readonly AuthenticationTypes: [];
    readonly ischecklistsavebuttondisabled: boolean;
    readonly servicelist: PropOnCallDashboardService[];
    readonly templatelist: IPropOnCallDashboardTemplate[];
    readonly checklist: {};
    readonly question: {};
    readonly ShowQuestionList: boolean; 
    readonly QuestionarrieList: [];
    readonly disableaddbuttonforquestion: boolean; 
    readonly DependsOnItems: [];
    readonly questioncontroltypedependsonoptions: [];
    readonly InvalidQuestionList: [];
    readonly serviceId: number;
    readonly checklistID: number;
    readonly searchText: string;
    readonly allServicesdata: IPropOnCallChecklistService[];
    readonly checklists: any;
    readonly mainIncidentIdFilter: number;
    readonly mainIncidentIdFilterList: any;
    readonly submittedbylist: any;
    readonly sortColumn: string;
    readonly sortOrder: boolean;
    readonly oceList: OCEView[];
    readonly fromDate: Date;
    readonly toDate: Date;
    readonly createdby: string;
    readonly isIncidents: boolean;
    readonly isPIR: boolean;
    readonly incidentIds: any;
    readonly incidents: any;
    readonly allPIRData: any;
    readonly iFrameKustoObj: any;
    readonly iFrameDataUrl: any;
    readonly meetingTitle: any;
    readonly meetingId: any;
    readonly icmMainIncident: any;
    readonly filteredIncidents: any;
    readonly incidentFilterFromDate: Date;
    readonly incidentFilterToDate: Date;
    readonly incidentFilter: any;
    readonly incidentFilterSeverityOptions: any;
    readonly incidentFilterSeverityModel: any;
    readonly clMeetingitems: any;
    readonly actionItemTitle: any;
    readonly qualitySpends: any;
    readonly LoginuserId: string;
    readonly actionItemDescription: any;
    readonly participants: any;
    readonly meetingStatus: any;
    readonly owner: any;
    readonly allowDateChange: any;
    readonly isVisibleContent: any;
    readonly currDate: Date;
    readonly minDate: Date;
    readonly q_Index: number;
    readonly Throttling: boolean;
    readonly ests: boolean;
    readonly filterparams: any;
    readonly questionincidents: any;
    readonly actionitems: any;
    readonly questionsList: any;
    readonly OCEquestion: any;
    readonly actionDataTobeSaved: boolean;
    readonly urlParam: string;
    readonly headerText: string;
    readonly allServices: any;
    readonly url: any;
    readonly showExpand: boolean;
    readonly isEditable: boolean;
    readonly isreadonly: boolean;
    readonly filterparameters: any;
    readonly questionURL: any;
    readonly URLOCE: any;
    readonly isExpanded: any;
    readonly isnotExpanded: any;
    readonly service: any;
    readonly ChecklistQuestions: any;
    readonly filename: any;
    readonly templateType: any;
    readonly onCallDate: string;
    readonly updatedBy: string;
    readonly templateTypes: [];
    readonly dynamicValidationTypes: [];
    readonly checklistDashboardCreatedBy: string;
    readonly errorMsg: string;
    readonly selectedServiceName: string;
    readonly selectedChecklistName: string;
};
const _namespace = 'Checklist ';

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/reset`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    FETCH_CHECKLIST_DATA: `${_namespace}/fetchchecklistdata`,
    CHECKLIST_DATA_SUCCESS: `${_namespace}/checklistdatasuccess`,
    CHECKLIST_DATA_FAIL: `${_namespace}/checklistdatafail`,
    OPEN_CHECKLIST_POPUP: `${_namespace}/openchecklistpopup`,
    SHOWORHIDE_CHECKLIST_POPUP: `${_namespace}/showorhidetemplatepopup`,
    CHECKLIST_DATA_CHANGFE: `${_namespace}/templatedatachange`,
    QUESTION_CONTROLS_CHANGFE: `${_namespace}/questioncontrolschange`,
    ADD_QUESTIONS_TOLIST: `${_namespace}/addquestionslist`,
    SHOW_QUESTIONLIST: `${_namespace}/showquestionlist`,
    ENABLE_ADD_NEW_QUESTION: `${_namespace}/enableaddnewquestion`,
    SET_LOADING_TRUE: `${_namespace}/loadingtrue`,
    SET_LOADING_FALSE: `${_namespace}/loadingfalse`,
    CHECKLIST_EDIT: `${_namespace}/checklistedit`,
    CHECKLIST_SERVICES_SUCCESS: `${_namespace}/checklistservicessuccess`,
    CHECKLIST_SERVICES_FAIL: `${_namespace}/checklistservicesfail`,
    CHECKLIST_TEMPLATE_DATA_SUCCESS: `${_namespace}/checklisttemplatedatasuccess`,
    CHECKLIST_TEMPLATE_DATA_FAIL: `${_namespace}/checklisttemplatedatafail`,
    CHECKLIST_TEMPLATE_QUESTIONS_SUCCESS: `${_namespace}/checklisttemplatequestionssuccess`,
    CHECKLIST_TEMPLATE_QUESTIONS_FAIL: `${_namespace}/checklisttemplatequestionsfail`,
    GET_CHECKLISTDATA_REQUEST: `${_namespace}/getchecklistdatarequest`,
    GET_MEETINGMAININCIDENTIDS_SUCCESS: `${_namespace}/getmeetingmainincidentidssuccess`,
    GET_MEETINGMAININCIDENTIDS_FAIL: `${_namespace}/getmeetingmainincidentidsfail`,
    GET_CHECKLISTSERVICES_SUCCESS: `${_namespace}/getchecklistservicessuccess`,
    GET_CHECKLISTSERVICES_FAIL: `${_namespace}/getchecklistservicesfail`,
    GET_SUBMITTEDLIST_SUCCESS: `${_namespace}/getsubmittedlistsuccess`,
    GET_SUBMITTEDLIST_FAIL: `${_namespace}/getsubmittedlistfail`,
    GET_CHECKLIST_SUCCESS: `${_namespace}/getchecklistsuccess`,
    GET_CHECKLIST_FAIL: `${_namespace}/getchecklistfail`,
    CHECKLISTDASHBOARD_CLEARFILTER: `${_namespace}/checklistdashboardclearfilter`,
    GET_OCELIST_SUCCESS: `${_namespace}/getocelistsuccess`,
    GET_OCELIST_FAIL: `${_namespace}/getocelistfail`,
    SORT_OCELIST: `${_namespace}/sortocelist`,
    OCELIST_FROMDATE_CHANGE: `${_namespace}/ocelistfromdatechange`,
    OCELIST_TODATE_CHANGE: `${_namespace}/ocelisttodatechange`,
    CHECKLIST_DROPDOWN_CHANGE: `${_namespace}/checklistdropdownchange`,
    OCELIST_SEARCHTEXT_CHANGE: `${_namespace}/ocelistsearchtextchange`,
    OCELIST_MEETINGTITLE_CHANGE: `${_namespace}/ocelistmeetingtitlechange`,
    OCELIST_ICMINCIDENT_CHANGE: `${_namespace}/ocelisticmincidentchange`,
    SET_CHECKLISTDASHBOARDDATE: `${_namespace}/ocelistsetchecklistdashboarddate`,
    INCIDENT_DROPDOWN_CHANGE: `${_namespace}/ocelistincidentdropdownchange`,
    SUBMITTEDBY_DROPDOWN_CHANGE: `${_namespace}/ocelistsubmittedbydropdownchange`,
    GET_CHECKLIST_SUCCESS_OCEVIEW: `${_namespace}/getchecklistsuccessoceview`,
    GET_CHECKLISTSERVICES_SUCCESS_READONLY: `${_namespace}/getchecklistsuccessoceviewreadonly`,
    GET_CHECKLIST_OCEVIEW_LOADDATA: `${_namespace}/getchecklistsuccessoceviewloaddata`,
    GET_MEETINGITEMS_SUCCESS_OCEVIEW: `${_namespace}/getmeetingitemssuccessoceview`,
    GET_IFRAME_DATAURL: `${_namespace}/getiframedataurl`,
    OCEVIEW_EXPAND_COLLAPSE_QUESTION: `${_namespace}/expandcollapseoceviewquestion`,
    LOADQUESTIONS_OCEVIEW_SUCCESS: `${_namespace}/loadquestionsoceviewsuccess`,
    CHANGE_EXPANDEDCOL_OCEVIEW: `${_namespace}/changeexpandedcoloceview`,
    GET_CHECKLISTSERVICES_SUCCESS_OCEVIEW: `${_namespace}/getchecklistservicessuccessoceview`,
    OCEVIEW_SHOWINCIDENTS: `${_namespace}/oceviewshowincidents`,
    OCEVIEW_BACKWARD_FORWARD_QUESTION: `${_namespace}/oceviewbackwardforwardquestion`,
    CHECKLIST_INCIDENTS_OCE: `${_namespace}/checklistincidentsoce`,
    GET_FILTERED_INCIDENTS_OCEVIEW: `${_namespace}/getfilteredincidentsoceview`,
    SAVE_INCIDENTS_OCEVIEW: `${_namespace}/saveincidentsoceview`,
    CHECKCHANGE_OCEVIEW_QUESTIONS: `${_namespace}/checkchangeoceviewquestions`,
    UPDATE_ACTIONITEMS_OCEVIEW: `${_namespace}/updateactionitemsoceview`,
    ADD_ACTIONITEM_OCEVIEW: `${_namespace}/addactionitemoceview`,
    ROWCANCEL_ACTIONITEM_OCEVIEW: `${_namespace}/rowcancelactionitemoceview`,
    SET_SEVERITY_INCIDENTSPOPUP_OCEVIEW: `${_namespace}/setincidentsseveritypopupoceview`,
    INCIDENTFILTER_FROMDATE_CHANGE: `${_namespace}/incidentfilterfromdatechange`,
    INCIDENTFILTER_TODATE_CHANGE: `${_namespace}/incidentfiltertodatechange`,
    GET_CHECKLISTINCIDENTS_OCE_FAIL: `${_namespace}/getchecklistincidentsocefail`,
    GET_CHECKLISTINCIDENTS_OCE_REQUEST: `${_namespace}/getchecklistincidentsocerequest`,
    GET_CHECKLISTSERVICES_OCEVIEW_FAIL: `${_namespace}/getchecklistservicesocefail`,
    UPDATE_QUESTION_ACTIONITEM: `${_namespace}/updatequestionactionitemoce`,
    OCEINCIDENTSPOPUP_CLEARFILTER: `${_namespace}/oceincidentspopupclearfilter`,
    ONQUESTIONS_SAVE_OCEVIEW_REQUEST: `${_namespace}/onquestionssaveoceviewrequest`,
    ONQUESTIONS_SAVE_OCEVIEW_SUCCESS: `${_namespace}/onquestionssaveoceviewsuccess`,
    ONQUESTIONS_SAVE_ANSWERS_OCEVIEW_SUCCESS: `${_namespace}/onquestionssaveanswersoceviewsuccess`,
    ONQUESTIONS_SAVE_ANSWERS_OCEVIEW_FAIL: `${_namespace}/onquestionssaveanswersoceviewfail`,
    LOGIN_USERNAME_OCE: `${_namespace}/loginusernameoce`,
    CONTROLTYPE_VALUES_CHANGED: `${_namespace}/controltypevalueschanged`,
    ACTIONITEM_INPUTCHANGED_OCEVIEW: `${_namespace}/actioniteminputchangedoceview`,
    UPDATE_ACTIONITEMS_OCEVIEW_QUESTIONAAIRE: `${_namespace}/updateactionitemsoceviewquestionnaire`,
    SERVICECHANGE_ESTS_SERVICE_QUESTIONNAIRE: `${_namespace}/servicechangeestsservicequestioonaire`,
    FILE_UPLOAD_FILENAME_SUCCESS: `${_namespace}/fileuploadfilenamesuccess`,
    DOWNLOAD_FILENAME_SUCCESS: `${_namespace}/downloadfilenamesuccess`,
    GET_CHECKLISTS_TEMPLATETYPE_OCEVIEW: `${_namespace}/getcheckliststemplatetypeoceview`,
    OCELIST_ONCALLDATE_CHANGE: `${_namespace}/ocelistoncalldatechange`,
    TEMPLATETYPE_DROPDOWN_CHANGE: `${_namespace}/templatetypedropdownchange`,
    ADD_CHECKLIST_QUESTIONS_DISPLAYORDER_TOLIST: `${_namespace}/add_checklist_questions_displayorder_tolist`,
    MOVETOARCHIVE: `${_namespace}/movetoarchive`,
    MOVETOARCHIVE_SUCCESS: `${_namespace}/movetoarchive_success`,
    MOVETOARCHIVE_FAIL: `${_namespace}/movetoarchive_fail`,
    OCELIST_ONCALLDATE_DISABLE: `${_namespace}/ocelistoncalldatedisable`,
    GET_UIFOROCE_DATA: `${_namespace}/getuiforocedata`,
    ACCESS_DENIED: `${_namespace}/access_denied`
});





