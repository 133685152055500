import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IdentityWeeklyNewsLetterOKRState } from './types';

const initialState = Object.freeze<IdentityWeeklyNewsLetterOKRState>({
    newsLetterDivisionalObjectivesData: [],
    newsLetterOKRsObjectives: [],
    selectedObjective: null,
    selectedKeyResults: [],
    newsLetterDivisionalKeyResultsData: [],
    isLoading: false,
    ObjectiveStartDate: null,
    ObjectiveEndDate: null,
    WeekStartDate: null,
    newObjective: '',
    isAddNewObjective: false
});

export const reducer = (
    state: IdentityWeeklyNewsLetterOKRState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true

            };
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false

            };
        case ActionType.GET_IDENTITYNEWSLETTERDIVSIONALOKRS_REQUEST:
            return {
                ...state,
                isLoading: true,
                newsLetterDivisionalObjectivesData: [],
                newsLetterOKRsObjectives: [],
                newsLetterDivisionalKeyResultsData: [],
                selectedObjective: null
            };
        case ActionType.GET_IDENTITYNEWSLETTERDIVSIONALOKRS_SUCCESS:  // === for IDENTITY WEEKLY NEWS LETTER Objectives and Key Results Data success ===============//
            return {
                ...state,
                newsLetterDivisionalObjectivesData: action.newsLetterDivisionalObjectivesData,
                newsLetterOKRsObjectives: action.newsLetterOKRsObjectives,
                newsLetterDivisionalKeyResultsData: action.newsLetterDivisionalKeyResultsData,
                selectedObjective: action.selectedObjective,
                isLoading: false
            };
        case ActionType.GET_IDENTITYNEWSLETTERDIVSIONALOKRS_FAILURE:  // === for IDENTITY WEEKLY NEWS LETTER Objectives and Key Results Data failure ===============//
            return {
                ...state,
                newsLetterDivisionalObjectivesData: [],
                newsLetterOKRsObjectives: [],
                newsLetterDivisionalKeyResultsData: [],
                selectedObjective: null,
                isLoading: false
            };
        case ActionType.IDENTITYNEWSLETTER_OKROBJECTIVE_CHANGE:  // === for IDENTITY WEEKLY NEWS LETTER OKR Objective Change===============//
            return {
                ...state,
                selectedObjective: action.selectedObjective,
                selectedKeyResults: action.selectedKeyResults,
                ObjectiveStartDate: action.ObjectiveStartDate,
                ObjectiveEndDate: action.ObjectiveEndDate,
                isAddNewObjective: action.isAddNewObjective,
                isLoading: false
            }; 
        case ActionType.IDENTITYWEEKLY_OKRS_START_DATE_CHANGE:  // === for IDENTITY WEEKLY NEWS LETTER OKR Objective Change===============//
            return {
                ...state,
                WeekStartDate: action.WeekStartDate
            };     
        case ActionType.IDENTITYNEWSLETTER_OBJECTIVES_STARTDATE_CHANGE:  // === for IDENTITY WEEKLY NEWS LETTER OKR Objective Start Date Change===============//
            return {
                ...state,
                ObjectiveStartDate: action.ObjectiveStartDate
            };
        case ActionType.IDENTITYNEWSLETTER_OBJECTIVES_ENDDATE_CHANGE:  // === for IDENTITY WEEKLY NEWS LETTER OKR Objective End Date Change===============//
            return {
                ...state,
                ObjectiveEndDate: action.ObjectiveEndDate
            }; 
        case ActionType.IDENTITYNEWSLETTER_OBJECTIVE_ADD:  
            return {
                ...state,
                newObjective: action.newObjective
            };    
        case ActionType.NEWSLETTER_KEYRESULTS_CONTROLS_CHANGE:
            return {
                ...state,
                selectedKeyResults: action.selectedKeyResults
            };  
        case ActionType.GET_IDENTITYNEWSLETTERKEYRESULTS_REQUEST:
            return {
                ...state,
                isLoading: true,
                newsLetterDivisionalKeyResultsData: [],
                selectedKeyResults: []
            }; 
        case ActionType.GET_IDENTITYNEWSLETTERKEYRESULTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                newsLetterDivisionalKeyResultsData: action.newsLetterDivisionalKeyResultsData,
                selectedKeyResults: action.selectedKeyResults
            };  
        case ActionType.GET_IDENTITYNEWSLETTERKEYRESULTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                newsLetterDivisionalKeyResultsData: [],
                selectedKeyResults: []
            };
        case ActionType.GET_IDENTITYNEWSLETTEROBJECTIVES_REQUEST:
            return {
                ...state,
                isLoading: true,
                newsLetterDivisionalObjectivesData: [],
                newsLetterOKRsObjectives: [],
                selectedObjective: null
            };
        case ActionType.GET_IDENTITYNEWSLETTEROBJECTIVES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                newsLetterDivisionalObjectivesData: action.newsLetterDivisionalKeyResultsData,
                newsLetterOKRsObjectives: action.newsLetterOKRsObjectives,
                selectedObjective: action.selectedObjective,
                isAddNewObjective: action.isAddNewObjective
            };
        case ActionType.GET_IDENTITYNEWSLETTEROBJECTIVES_FAILURE:
            return {
                ...state,
                isLoading: false,
                newsLetterDivisionalObjectivesData: [],
                newsLetterOKRsObjectives: [],
                selectedObjective: null
            };
        case ActionType.NEWSLETTER_OBJECTIVES_CANCEL_CHANGE:
            return {
                ...state,
                isAddNewObjective: action.isAddNewObjective,
                newObjective: action.newObjective,
                ObjectiveStartDate: action.ObjectiveStartDate,
                ObjectiveEndDate: action.ObjectiveEndDate,
                selectedObjective: action.selectedObjective
            };    
        case '@@router/LOCATION_CHANGE': {
            const { search } = action.payload.location;
            const params = new URLSearchParams(search);
            var newurl = window.location.pathname;
            //Below conditions only for direct url access like bookmarks
            if (newurl.indexOf("DivisionalOKRs") !== -1) {
                return {
                    ...state,
                    WeekStartDate: null,
                    selectedKeyResults: [],
                    ObjectiveStartDate: null,
                    ObjectiveEndDate: null
                }
            }
        };
        case ActionType.USER_CHECK:
            return {
                ...state,
                IsLivesitePMOrUser: action.IsLivesitePMOrUser
            };
        case ActionType.RESET_STATE:
            return initialState;
        default:
            return state;
    }
};