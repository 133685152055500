import { BaseService } from './base.service';
import { IDAcktrackingTemplate, IDAcktrackingNotification, IDAcktrackingFilter } from '../store/AckTrackingTemplate';

/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class AcktrackingService extends BaseService {
    private static _serviceGroupService: AcktrackingService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): AcktrackingService {
        return (this._serviceGroupService || (this._serviceGroupService = new this('AckTracking')));
    }

    public async GetAckTrackingTemplateData(): Promise<IDAcktrackingTemplate[]> {
        const { data } = await this.$http.get<IDAcktrackingTemplate[]>("GetAckTrackingTemplateData");
        return data;
    }
    public async GetAckTrackingNotificationData(): Promise<IDAcktrackingNotification[]> {
        const { data } = await this.$http.get<IDAcktrackingNotification[]>("GetAckTrackingNotificationData");
        return data;
    }
    public async GetAckTrackingTemplateDetails(): Promise<IDAcktrackingTemplate[]> {
        const { data } = await this.$http.get<IDAcktrackingTemplate[]>("GetAckTrackingTemplateDetails");
        return data;
    }
    public async GetAckTrackingFilterDetails(notificationACKId: number): Promise<IDAcktrackingFilter[]> {
        const { data } = await this.$http.get<IDAcktrackingFilter[]>(`GetAckFilterByAckTypeID?notificationACKId=${notificationACKId}`);
        return data;
    }
    public async MoveAckTemplateToArchive(TemplateID: number): Promise<number> {
        const { data } = await this.$http.get<number>(`MoveAckTemplateToArchive?TemplateID=${TemplateID}`);
        return data;
    }
    public async SaveIDAckNotificationsData(obj): Promise<number> {
        const { data } = await this.$http.post<number>(`SaveIDAckNotificationsData`, obj);
        return data;
    }
    public async SaveIDAckTrackTemplate(obj): Promise<number> {
        const { data } = await this.$http.post<number>(`SaveIDAckTrackTemplate`, obj);
        return data;
    }
    public async GetIdentityAckTrainingData(notificationACKId: number): Promise<any> {
        const { data } = await this.$http.get<any>(`GetIdentityAckTrainingData?notificationACKId=${notificationACKId}`);
        return data;
    }
    public async GetServiceManagers(): Promise<any> {
        const { data } = await this.$http.get<any>("GetServiceManagers");
        return data;
    }
    public async GetServiceNameList(): Promise<any> {
        const { data } = await this.$http.get<any>("GetServiceNameList");
        return data;
    }
    public async GetComponentNameList(): Promise<any> {
        const { data } = await this.$http.get<any>("GetComponentNameList");
        return data;
    }
    public async GetServiceTypeList(): Promise<any> {
        const { data } = await this.$http.get<any>("GetServiceTypes");
        return data;
    }
    public async GetServiceRingList(): Promise<any> {
        const { data } = await this.$http.get<any>("GetServiceRingList");
        return data;
    }
    public async GetServiceLifeCycleList(): Promise<any> {
        const { data } = await this.$http.get<any>("GetServiceLifeCycleList");
        return data;
    }
    public async GetIDClassificationList(): Promise<any> {
        const { data } = await this.$http.get<any>("GetIDClassificationList");
        return data;
    }
    public async GetTrackingType(): Promise<any> {
        const { data } = await this.$http.get<any>("GetTrackingType");
        return data;
    }
    public async GetFilterData(): Promise<any> {
        const { data } = await this.$http.get<any>("GetFilterData");
        return data;
    }
    public async GetFilterByID(id:number): Promise<any> {
        const { data } = await this.$http.get<any>(`GetFilterByID?id=${id}`);
        return data;
    }
    public async SaveIdentityAckTrackingFilterData(filterURL,filterID, name: string, updatedby: string): Promise<number> {
        const URL = {
            URL: filterURL.URL            
        };
        const { data } = await this.$http.post<number>(`SaveIdentityAckTrackingFilterData?name=${name}&updatedby=${updatedby}&filterID=${filterID}`, URL);
        return data;
    }
    public async SaveIdentityAckTrackingData(obj): Promise<number> {
        const { data } = await this.$http.post<number>(`SaveIdentityAckTrackingData`, obj);
        return data;
    }
    public async SaveFilterIsActiveFlag(isActive, id, updatedby): Promise<number> {
        const { data } = await this.$http.post<number>(`SaveFilterIsActiveFlag?isActive=${isActive}&id=${id}&updatedBy=${updatedby}`);
        return data
    }
  

}

export const AcktrackingAPI = AcktrackingService.Instance;
