import React, { useEffect, useRef, useState} from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { RouteComponentProps } from 'react-router-dom';
import { actionCreators, reducer } from '../../store/AckTrackingTemplate';
import { AckTemplateTable, AckTrackingUserGuide } from '../AckTrackingTemplate/child-components';
import LoadingOverlay from 'react-loading-overlay';
import { isArrayWithLength, renderToastifyMsg, isNullOrUndefined } from '../../utils';
import { ToastId, toast } from 'react-toastify';
import { AcktrackingAPI } from '../../api';
import { DeprecatedFunctionalityBanner } from '../../components';

type AckTemplateProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly username: string }
    & { readonly history: History }
    & { readonly isAdmin: boolean }
    & RouteComponentProps<{}>;

const AckTemplate: React.FC<AckTemplateProps> = ({
    isLoading,
    addUserVisit,
    isAuthenticated,
    GetAcktrackingTemplateData,
    ackTemplateData,
    OpenorhideAckTypePopup,
    OpenorhideAckFilterPopup,
    showAckFilterPopup,
    hideAckFilterPopup,
    showackTypePopup,
    hideAckTypePopup,
    AckType,
    ackNotificationData,
    ackFilterNameData,
    handleAcktypeChange,
    handleAcktypeChangeOnFilters,
    handleTemplateChange,
    templatedropdowndata,
    templateid,
    templateDescription,
    ackTypevalue,
    isAckTypeActive,
    isTemplateActive,
    changeacktypetext,
    Enableacktypesavebutton,
    Templatedescriptionchange,
    MoveAckTemplateToArchive,
    username,
    setloadingtrue,
    setloadingfalse,
    showtemplatePopup,
    hidetemplatePopup,
    OpentemplatePopup,
    template,
    servicecolsList,
    templatedatachange,
    changeActiveStatus,
    AddcolumnstoTemplate,
    changedColumns,
    columncontroltypeoptions,
    templatecolumnvalueschange,
    DeleteTemplateColumn,
    columncoloring,
    colorsData,
    colourpalletecolourchane,
    templatesavebuttondisable,
    customcolourchange,
    TemplateEdit,
    isAdmin
}) => {
    const toastIdRef = useRef<ToastId>('');
    const [IsAckTrackingUserGuideSelected, setIsAckTrackingUserGuideSelected] = useState(false);
    useEffect(() => {
        if (isAuthenticated)
            addUserVisit(0, 'Acktemplate.tsx_v2');
                GetAcktrackingTemplateData();
    }, [isAuthenticated]);
    const SaveAckTypeData = () => {
        let isacktypeexist = false;
        if (isArrayWithLength(ackNotificationData)) {
            ackNotificationData.map(obj => {
                if (obj.name.toLowerCase() === ackTypevalue.toLowerCase() && obj.value != AckType) {                  
                    isacktypeexist = true;
                }
            })
        }
        if (!isacktypeexist) {
            var data = {
                NotificationACKId: AckType,
                Title: ackTypevalue,
                LastUpdatedBy: username,
                TemplateID: templateid,
                Description: templateDescription,
                IsActive: isAckTypeActive
            };
            setloadingtrue();
            AcktrackingAPI.SaveIDAckNotificationsData(data)
                .then((result: number) => {
                    setloadingfalse();
                    if (result !== -1) {
                        if (AckType === 0) {
                            toastIdRef.current = toast.success(
                                renderToastifyMsg("Ack Type Created successfully",
                                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                            );
                        }
                        else {
                            toastIdRef.current = toast.success(
                                renderToastifyMsg("Ack Type Updated successfully",
                                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                            );
                        }

                    } else {
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Error while Adding/Updating Ack Type",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );

                    }
                    hideAckTypePopup();

                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while Adding/Updating Ack Type",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
        else {
            toastIdRef.current = toast.error(
                renderToastifyMsg("Ack Type already Exist",
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
       
    }
    const SaveIDAckTrackTemplate = () => {
        let exist = false;
        if (isArrayWithLength(ackTemplateData)) {
            ackTemplateData.map(obj => {
                if (obj.templateName.toLowerCase() === template.templateName.toLowerCase() && obj.templateID != template.templateID) {
                    exist = true;
                }
            })
        }

        if (exist) {
            toastIdRef.current = toast.error(
                renderToastifyMsg("Template Name already Exist",
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
        }
        else {
            let servicenames = '';
            let colsList = '';
            let TemplateJSON = [];
            if (isNullOrUndefined(template.templateID)) {
                template.templateID = 0;
            }
            let isserviceSelected = false;
            if (isArrayWithLength(template.serviceTreeColsList)) {
                template.serviceTreeColsList.map((obj, index) => {
                    if (index == 0) {
                        servicenames = obj.id;
                    }
                    else {
                        servicenames = servicenames + ',' + obj.id;
                    }
                    if (obj.id == 'serviceName') {
                        isserviceSelected = true;
                    }
                });
            }

            if (isserviceSelected == false) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Service Name selection should be mandatory in Servicetree Cols List",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            }
            else {
                let isvalidcoumn = true;
                let Isvaluelistempty = false;
                let message = '';
                let existdisplayorder = false;
                let negitivedisplayorder = false;
                let colorpalletenotselected = false;
                if (isArrayWithLength(changedColumns)) {
                    for (var i = 0; i < changedColumns.length; i++) {
                        message = '';
                        let regexname = /[a-zA-Z]+/g;
                        if (!regexname.test(changedColumns[i].ColumnName)) {
                            isvalidcoumn = false;
                            message = 'Column Name should start with letter';
                            i = changedColumns.length;
                        }
                        else if (changedColumns[i].ColumnName.indexOf(',') != -1) {
                            isvalidcoumn = false;
                            message = 'Column Name should not contain special character ","';
                            i = changedColumns.length;
                        }
                       else {
                            if (i == 0) {
                                colsList = changedColumns[i].ColumnName;
                            }
                            else {
                                colsList = colsList + ',' + changedColumns[i].ColumnName;
                            }
                            let ValuesList = changedColumns[i].ControlValue.split(',');
                            if ((changedColumns[i].ControlType == 'CheckBox' || changedColumns[i].ControlType == 'Radio Button' || changedColumns[i].ControlType == 'Dropdown')) {
                                for (var cv = 0; cv < ValuesList.length; cv++) {
                                    if (ValuesList[cv].trim() == '') {
                                        Isvaluelistempty = true;
                                        cv = ValuesList.length;
                                    }
                                }
                            }
                                if (Isvaluelistempty == true) {
                                    message = "Please enter valid control Values with comma seperated for " + changedColumns[i].ColumnName.toLowerCase();
                                    i = changedColumns.length;
                                }
                                else {
                                    let displayOrderColumns = [];
                                    if (changedColumns != undefined) {
                                        for (var j = 0; j < changedColumns.length; j++) {
                                            if (parseInt(changedColumns[j].DisplayOrder) <= 0) {
                                                negitivedisplayorder = true;
                                                i = changedColumns.length;
                                            }
                                            else if (displayOrderColumns.indexOf(parseInt(changedColumns[j].DisplayOrder)) == -1) {
                                                displayOrderColumns.push(parseInt(changedColumns[j].DisplayOrder));
                                            }
                                            else {
                                                existdisplayorder = true;
                                                i = changedColumns.length;
                                            }
                                        }
                                    }
                                    if (negitivedisplayorder) {
                                        message = "Display Order should start with '1'";
                                        i = changedColumns.length;
                                    }
                                    else if (existdisplayorder) {
                                        message = "Display Order already exist";
                                        i = changedColumns.length;
                                    }
                                    else {
                                        let columnName = '';
                                        let colVal = '';
                                        if (isArrayWithLength(columncoloring)) {
                                            for (let k = 0; k < columncoloring.length; k++) {

                                                if (columncoloring[k].colorpalettecolor === '' || columncoloring[k].colorpalettecolor=== undefined) {
                                                    colorpalletenotselected = true;
                                                    columnName = columncoloring[k].ColumnName;
                                                    colVal = columncoloring[k].ControlValue;
                                                    k = columncoloring.length;
                                                }
                                            }
                                            if (colorpalletenotselected) {
                                                message="Please Select the Color from the Color Pallete for " + columnName + " Column and " + colVal + " Control value";
                                               
                                            }
                                        }
                                        if (colorpalletenotselected) {
                                            i = changedColumns.length;
                                        }
                                        else {
                                            let val = '';
                                            if (isArrayWithLength(columncoloring)) {
                                                for (let k = 0; k < columncoloring.length; k++) {                                                   
                                                    if (i == columncoloring[k].val) {
                                                        if (val === '') {
                                                            val = columncoloring[k].colorpalettecolor;
                                                        }
                                                        else {
                                                            val = val +","+ columncoloring[k].colorpalettecolor;
                                                        }                                                       
                                                    }
                                                }
                                            }
                                            TemplateJSON.push({ "ColumnName": changedColumns[i].ColumnName, "ControlType": changedColumns[i].ControlType, "ControlValue": changedColumns[i].ControlValue, "DisplayOrder": parseInt(changedColumns[i].DisplayOrder), "IsRequired": changedColumns[i].IsRequired, "IsColoringRequired": changedColumns[i].IsColoringRequired, "ColoringValue": val });
                                        }
                                       
                                    }
                                }
                           
                        }
                           
                    }
                }
                if (message != '') {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg(message,
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
                else {
                    let Isduplicatecolumn = false;
                    for (let i = 0; i < changedColumns.length; i++) {
                        let list = colsList.split(',');
                        for (var j = 0; j < list.length; j++) {
                            if (changedColumns[i].ColumnName.toLowerCase() == list[j].toLowerCase() && i != j) {

                                j = list.length;
                                i = changedColumns.length;
                                Isduplicatecolumn = true;
                            }
                        }
                    }

                    if (Isduplicatecolumn == true) {
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Duplicate Columns are not allowed",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }    
                    else if (template.ackcount > 0 && !template.isActive) {
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("This Template already mapped to Ack type, Please make sure IsActive flag is checked",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                    else {
                        username = username.split('@')[0];
                        let data = {
                            TemplateID: template.templateID ,
                            TemplateName: template.templateName ,
                            ServiceTreeColsList: servicenames ,
                            ColsList: colsList,
                            IsActive: template.isActive,
                            IsServiceSpecific: template.isServiceSpecific,
                            IsUsed: false,
                            TemplateJSON: JSON.stringify(TemplateJSON),
                            CreatedBy: username,
                            UpdateFilterComponent: false
                        };
                        setloadingtrue();
                        if (template.mappedAckCount > 0 && template.isServiceSpecific === true) {
                            let cnt = 0;
                            if (template.originalServiceTreeColsList !== undefined && template.originalServiceTreeColsList !== null) {
                                template.originalServiceTreeColsList.map((item, key) => {
                                    if ((item.id === "componentName")) {
                                        cnt = cnt + 1;
                                    }
                                });
                            }
                            if (cnt === 1) {
                                if (window.confirm("This Template already mapped with Ack type, Changing the Ack category from Component to Service might remove the component specific data.\n\nAre you sure you want to Proceed ?")) {
                                    data.UpdateFilterComponent = true;
                                    saveTemplateData(data);
                                }
                                else {
                                    setloadingfalse();
                                }
                            }
                            else {
                                saveTemplateData(data);
                            }
                        }
                        else {
                            saveTemplateData(data);
                        }
                    }

                }
               
            }
        }
    }

    const saveTemplateData = (data) => {
        AcktrackingAPI.SaveIDAckTrackTemplate(data)
            .then((result: number) => {
                setloadingfalse();
                if (result !== -1) {
                    if (template.templateID == 0) {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg("Template Saved successfully",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );

                    }
                    else {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg("Template Updated successfully",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }

                } else {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while Adding/Updating Template",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );

                }
                GetAcktrackingTemplateData();
                hidetemplatePopup();

            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while Adding/Updating Template",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });

    }

    //============== function to activate/deactivate filterName (checkbox onchange) for selected row ======================//
    const changeFilterActiveStatus = (e, row) => {
        setloadingtrue();
        row.isActive = e.target.checked;
        AcktrackingAPI.SaveFilterIsActiveFlag(row.isActive, row.id, username)
            .then((result: number) => {
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg(((row.isActive) == true ? 'FilterName : ' + row.filterName +  ' has been activated succesfully' 
                            : 'Filter Name : ' + row.filterName + ' has been deactivated succesfully'),
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    setloadingfalse();
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });

    }

    //==================== function to show and hide Ack tracking user Guide =====================//
    const showAckTrackingUserGuide = () => {
        setIsAckTrackingUserGuideSelected(true);
    }
    const hideAckTrackingUserGuide = () => {
        setIsAckTrackingUserGuideSelected(false);
    }
   
    return (
                <div>
                    <DeprecatedFunctionalityBanner />
                    <LoadingOverlay active={isLoading}
                        spinner
                        text='Loading your content...'
                    >
                        <section className='section'>
                            <div className='container'>
                                {
                                    (IsAckTrackingUserGuideSelected)
                                        ?
                                        <AckTrackingUserGuide
                                            hideAckTrackingUserGuide={hideAckTrackingUserGuide} />
                                        :
                                    <AckTemplateTable
                                        ackTemplateData={ackTemplateData}
                                        OpenorhideAckTypePopup={OpenorhideAckTypePopup}
                                        OpenorhideAckFilterPopup={OpenorhideAckFilterPopup}
                                        showackTypePopup={showackTypePopup}
                                        showAckFilterPopup={showAckFilterPopup}
                                        hideAckFilterPopup={hideAckFilterPopup}
                                        AckType={AckType}
                                        ackNotificationData={ackNotificationData}
                                        ackFilterNameData={ackFilterNameData}
                                        handleAcktypeChange={handleAcktypeChange}
                                        handleAcktypeChangeOnFilters={handleAcktypeChangeOnFilters}
                                        handleTemplateChange={handleTemplateChange}
                                        templatedropdowndata={templatedropdowndata}
                                        templateid={templateid}
                                        MoveAckTemplateToArchive={MoveAckTemplateToArchive}
                                        hideAckTypePopup={hideAckTypePopup}
                                        templateDescription={templateDescription}
                                        ackTypevalue={ackTypevalue}
                                        isAckTypeActive={isAckTypeActive}
                                        isTemplateActive={isTemplateActive}
                                        changeacktypetext={changeacktypetext}
                                        Enableacktypesavebutton={Enableacktypesavebutton}
                                        Templatedescriptionchange={Templatedescriptionchange}
                                        SaveAckTypeData={SaveAckTypeData}
                                        showtemplatePopup={showtemplatePopup}
                                        hidetemplatePopup={hidetemplatePopup}
                                        OpentemplatePopup={OpentemplatePopup}
                                        template={template}
                                        servicecolsList={servicecolsList}
                                        templatedatachange={templatedatachange}
                                        changeActiveStatus={changeActiveStatus}                 
                                        changeFilterActiveStatus={(e, row) => changeFilterActiveStatus(e, row)}
                                        AddcolumnstoTemplate={AddcolumnstoTemplate}
                                        changedColumns={changedColumns}
                                        columncontroltypeoptions={columncontroltypeoptions}
                                        templatecolumnvalueschange={templatecolumnvalueschange}
                                        DeleteTemplateColumn={DeleteTemplateColumn}
                                        columncoloring={columncoloring}
                                        colorsData={colorsData}
                                        colourpalletecolourchane={colourpalletecolourchane}
                                        customcolourchange={customcolourchange}
                                        templatesavebuttondisable={templatesavebuttondisable}
                                        SaveIDAckTrackTemplate={SaveIDAckTrackTemplate}
                                        TemplateEdit={TemplateEdit}
                                        showAckTrackingUserGuide={showAckTrackingUserGuide}
                                        isAdmin={isAdmin}
                                    />

                                }
                            </div>
                        </section>
                    </LoadingOverlay>

            </div>
            );
 };
const mapStateToProps = (state: IApplicationState) => ({
    ...state.AckTrackingTemplate,
    isAuthenticated: state.authUser.isAuthenticated,
    username: state.authUser.login,
    isAdmin: state.authUser.isAdmin

})

export default connect(mapStateToProps, actionCreators)(AckTemplate);
