import React, { useState, Fragment, useRef, useEffect } from 'react';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import { IPropOnCallDashboardChecklist } from '../../../store/Checklist';
import {
    Card, Row, Col, Container, CardBody, CardTitle
} from 'reactstrap';
import { Modal, Button } from 'react-bootstrap'
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faPencilAlt, faTrash, faTimes, faArchive } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { faChevronDown, faChevronUp, faQuestionCircle, faUnlock, faLock } from '@fortawesome/free-solid-svg-icons'
import { Accordion } from 'react-bootstrap'
import { OrderList } from 'primereact/orderlist';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';

type ChecklistTableProps = {
    checklistData: IPropOnCallDashboardChecklist[];
    OpenchecklistPopup: any;
    ChecklistEdit: any;
    MoveToArchive: any;
    showchecklistPopup: boolean;
    hideCHECKLISTPopup: any;
    questioncontroltypeoptions: any;
    sourcetypes: any;
    AuthenticationTypes: any;
    ischecklistsavebuttondisabled: boolean;
    SaveOnCallchecklistData: any;
    servicelist: any;
    templatelist: any;
    checklist: any;
    handlechecklistChange: any;
    question: any;
    ShowQuestionList: boolean;
    AddQuestionstoChecklist: any;
    QuestionarrieList: any;
    disableaddbuttonforquestion: boolean;
    handlequestionChange: any;
    showquestionlist: any;
    SaveQuestionnarie: any;
    ChecklistquestionEdit: any;
    questionlistvalueschange: any;
    checkURL: any;
    DependsOnItems: any;
    questioncontroltypedependsonoptions: any;
    downloadChecklistuserguide: any;
    isAdmin: any;
    LoginuserId: any;
    EditLockforChecklist: any;
    UpdateChecklistQuestionDisplayOrder: any;
    templateTypes: any;
    dynamicValidationTypes: any;
};
const ChecklistTable = React.memo<ChecklistTableProps>(({
    checklistData,
    OpenchecklistPopup,
    ChecklistEdit,
    MoveToArchive,
    showchecklistPopup,
    hideCHECKLISTPopup,
    questioncontroltypeoptions,
    sourcetypes,
    AuthenticationTypes,
    ischecklistsavebuttondisabled,
    SaveOnCallchecklistData,
    servicelist,
    templatelist,
    checklist,
    handlechecklistChange,
    question,
    ShowQuestionList,
    AddQuestionstoChecklist,
    QuestionarrieList,
    disableaddbuttonforquestion,
    handlequestionChange,
    showquestionlist,
    SaveQuestionnarie,
    ChecklistquestionEdit,
    questionlistvalueschange,
    checkURL,
    DependsOnItems,
    questioncontroltypedependsonoptions,
    downloadChecklistuserguide,
    isAdmin,
    LoginuserId,
    EditLockforChecklist,
    UpdateChecklistQuestionDisplayOrder,
    templateTypes,
    dynamicValidationTypes
}) => {
    useEffect(() => {
        dt.current.filter(selectedisActive, 'isActive', 'equals');
    }, [checklistData]);
    const count = 1;
    const [questiontoggle, setquestiontoggle] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const ChecklistGuideURL = "https://identitydivision.visualstudio.com/IdentityWiki/_wiki/wikis/IdentityWiki.wiki/16549/Checklist-UserGuide";
    const dt = useRef(null);
    const setGlobalFilterValue = (event) => {
        setGlobalFilter(event.target.value);
    }
    //Pop out URL in new tab; for jarvis, kusto and data URLs
    const openUrlInNewTab = (url) => {
        if (url !== '') {
            window.open(url, "_blank");
        }
    }

    const UpdateBodyTemplate = (rowData) => {    
        let checklist = {
            'checkListId': rowData.checkListID, 'selectedService': [parseInt(rowData.serviceID)], 'checkListName': rowData.checkListName, 'notes': rowData.notes,
            DataUrl: rowData.dataUrl, IsActive: rowData.isActive, templateId: rowData.templateID, 'isLocked': rowData.isLocked, templateType: rowData.templateType
        };
        return (
            <React.Fragment>
                <FontAwesomeIcon cursor='pointer' title='Click to Edit the record' icon={faPencilAlt} onClick={e => ChecklistEdit(checklist)} />&nbsp;
                {!rowData.isActive && <FontAwesomeIcon cursor='pointer' title='Click to Archive the record' className="redcolorIcons Iconsstyles" icon={faArchive} onClick={e => deleteArchiveConfirm(rowData.checkListID)} />}
            </React.Fragment>
        );
    }    

    const displayLastUpdatedDate = (rowData) => {
        return (
            <div>{(rowData.lastUpdatedDate === null) ? '' : moment(rowData.lastUpdatedDate).format('YYYY-MM-DD hh:mm A')}</div>
        );
    } 

    const displayIsActive = (rowData) => {
        return (
            <div>{rowData.isActive == true ? 'True' : 'False'}</div>
        );
    } 

    const [selectedisActive, setSelectedisActive] = useState('True');
    const [archiveDialog, setArchiveDialog] = useState(false); 
    const [selectedCheckListToArchive, setCheckListToArchive] = useState(null); 
    
    const isActiveOptions = [
        'True', 'False'
    ];
    
    const onisActiveFilterChange = (event) => {
        dt.current.filter(event.value, 'isActive', 'equals');
        setSelectedisActive(event.value);
    }

    
    const isActiveFilterElements = () => {
        return (
            <Dropdown value={selectedisActive} options={isActiveOptions} itemTemplate={isActiveOptionTemplate} className="p-column-filter"
                onChange={onisActiveFilterChange} placeholder="Select a isActive" />
            );
    }
    const isActiveOptionTemplate = (option) => {
        return (
            <span>{option}</span>
        );
    }
    const isActiveFilterElement = isActiveFilterElements();

    const hideArchiveDialog = () => {
        setCheckListToArchive(null);
        setArchiveDialog(false);
    }
    const deleteArchiveConfirm = (ChecklistID) => {
        setCheckListToArchive(ChecklistID);
        setArchiveDialog(true);
    }
    const deleteArchiveConfirmed = () => {
        MoveToArchive(selectedCheckListToArchive);
        setArchiveDialog(false);
    }

    const archiveDialogFooter = (
        <React.Fragment>
            <span style={{ display: 'flex', justifyContent: 'center' }}>
                <Button className="p-button-text btn-primary" onClick={() => deleteArchiveConfirmed()}>Yes</Button>
                <Button className="p-button-text btn-primary" onClick={() => hideArchiveDialog()} >No</Button>
            </span>
        </React.Fragment>
    );

    const setquestiontoggledata = (flag) => {
        setquestiontoggle(flag);

    }

    const ValidateControlType = (templateType, question) => {
        let Index = question.Questionindex;
        let QuestionID = question.QuestionID;
        if (templateType === 'Checklist Only' && Index !== -1 && QuestionID !== 0) {
            return true;
        }
        else {
            return false;
        }
    }

    if (QuestionarrieList.filter(x => x.id === -1).length <= 0) {
        QuestionarrieList.splice(0, 0, { id: -1 });
    }

    // Logic to add id column for Questionnaire List as we are avoiding map in OrderedList Template 
    QuestionarrieList.map((obj, index) => {
        if (obj.id !== -1) {
            obj.id = index;
        }
    });

    const itemTemplate = (item) => {
        return (
            <React.Fragment>
                <table className="tblAcktemplate">
                    {(item.id === -1) ?
                        <thead>
                            <tr>
                                <th style={{ width: '12%' }} className="textaligncenter">Update</th>
                                <th style={{ width: '76%' }} className="textaligncenter">Question</th>
                                <th style={{ width: '12%' }} className="textaligncenter">IsActive</th>
                            </tr>
                        </thead>
                        :
                        <React.Fragment>
                            <tbody>
                                <tr className={(item.IsInvalidQuestion) ? 'rowWarning' : ''}>
                                    <td style={{ width: '12%' }} className="editiconstyle">
                                        {
                                            (item.TemplateID > 0) ?
                                                <span title="Cannot Update Template Questions"><FontAwesomeIcon icon={faPencilAlt} className="ackediticon" style={{ cursor: 'not - allowed', color: '#788080' }} />
                                                </span>
                                                :
                                                <span onClick={e => ChecklistquestionEdit(item, item.id)}>
                                                    <FontAwesomeIcon icon={faPencilAlt} className="checlistEditicon" />
                                                </span>
                                        }
                                    </td>
                                    <td style={{ width: '76%' }} className="acktextalignment">{item.Question}
                                        {/*<input  type="text" className="form-control" placeholder="Control Values" value={item.Question} onChange={(e) => questionlistvalueschange(e, item.id)} name="Question" />*/}
                                    </td>
                                    <td style={{ width: '12%' }} className="editiconstyle">
                                        <input type="checkbox" checked={item.IsActive} disabled={(item.TemplateID > 0) ? true : false } onChange={(e) => questionlistvalueschange(e, item.id)} name="IsActive" />
                                    </td>
                                </tr>

                                <tr></tr>
                            </tbody>
                        </React.Fragment>
                    }
                </table>
            </React.Fragment>
        );
    };

    return (
        <div>
            <Card className="cardstyle">
                <CardBody className="cardbodyhederstyle">
                    <Row>
                        <Col lg="4"></Col>
                        <Col lg="4" style={{ textAlign: 'center' }}>
                            <div>
                                <h3 className="headertext">Manage Service Checklists</h3>
                            </div>
                        </Col>
                        <Col lg="4" style={{ textAlign: 'right' }}>
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" />&nbsp;
                                <InputText style={{ width: '340px', verticalAlign: 'top' }} type="search" placeholder="Global Search" value={globalFilter} onChange={(e) => setGlobalFilterValue(e)} />&ensp;&ensp;&ensp;
                            </span>                     
                            </Col>
                    </Row>
                </CardBody>
            </Card>
            <br />
            <Row>
                <Col lg="12">
                    <div className="alignrightitems">
                        <React.Fragment><span className='ConfigGuide' onClick={e => openUrlInNewTab(ChecklistGuideURL)}>
                            CheckList User Guide
                            </span>
                            <FontAwesomeIcon style={{ cursor: 'pointer', fontWeight: 'bolder', fontSize: '15px', verticalAlign: 'top' }} onClick={e => openUrlInNewTab(ChecklistGuideURL)} title='CheckList User Guide' className='icon-format-config' icon={faQuestionCircle} />&ensp;
                        </React.Fragment>
                        <button className="btn btn-primary btn-sm filter-hmbutton btnwidth150" onClick={OpenchecklistPopup}><FontAwesomeIcon icon={faPlus} style={{ color: 'white', backgroundColor: 'None', border: 'none' }} /><strong className="txtcolorwhite"> Add New Checklist</strong></button>
                    </div>
                </Col>
            </Row>
            <br />
            <div>
                <DataTable ref={dt} value={checklistData} className="tblListDisplay tblborder"
                    globalFilter={globalFilter} emptyMessage="No Data found.">                    
                    <Column className="thListwidth12" style={{ wordBreak: 'break-word' }} field="serviceName" sortable header="Service Name" filter filterMatchMode="contains" filterPlaceholder="Search by Service Name" />
                    <Column className="thListwidth20" style={{ wordBreak: 'break-word' }} field="checkListName" sortable  header="Checklist Name" filter filterMatchMode="contains" filterPlaceholder="Search by Checklist Name" />
                    <Column className="thListwidth20" style={{ wordBreak:'break-word' }} field="notes" sortable header="Notes" filter filterMatchMode="contains" filterPlaceholder="Search by Notes" />
                    <Column className="thListwidth20" style={{ wordBreak: 'break-word' }} field="templateName" sortable header="Template Name" filter filterMatchMode="contains" filterPlaceholder="Search by TemplateName" />
                    <Column className="thListwidth8" field="lastUpdatedBy" sortable header="Last Updated By" filter filterMatchMode="contains" filterPlaceholder="Search by Last Updated By" />
                    <Column className="thListwidth11" field="lastUpdatedDate" sortable header="Last Updated Date" body={displayLastUpdatedDate} filter filterMatchMode="contains" filterPlaceholder="Search by Date" />
                    <Column className="thListwidthCenter8" field="isActive" sortable header="Is Active" body={displayIsActive} filter filterElement={isActiveFilterElement}/>
                    <Column className="thListEditwidth4" field="checkListID" header="Edit" body={UpdateBodyTemplate} />
                </DataTable>
            </div>
            <Modal show={showchecklistPopup} onHide={hideCHECKLISTPopup} size='xl' backdrop='static' id="Ackmodelpopup">
                <Modal.Header closeButton className="commonModalHeader">
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='12' style={{ textAlign: 'center' }}>
                                <h4><b>  Create/Update Service Checklist </b></h4>
                            </Col>
                        </Row>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ width: '100%' }}>
                    {
                        !isNullOrUndefined(checklist)
                            ?
                            <React.Fragment>
                                {(((!isAdmin) && !(checklist.createdBy === LoginuserId) && (checklist.isLocked))) ?
                                    <React.Fragment>
                                        <div style={{ textAlign: 'center', paddingBottom: '5px' }}>
                                            <span className="checklistwarntextstyle">This page has been locked by Checklist Initiator/Admin. So, you cannot perform any Add/Update operations.</span>
                                        </div>
                                    </React.Fragment>
                                    : <React.Fragment></React.Fragment>
                                }
                                <div>                                   
                                    <Row className="rowpaddingbottom">
                                        <Col lg="4">
                                            <span className="mandatorycolor">*</span>&nbsp;<span>Select Service</span>
                                        </Col>
                                        <Col lg="1">
                                            :
                                        </Col>
                                        <Col lg="6">
                                            <span className="p-float-label acklables">
                                                <MultiSelect id="checklistServiceDropdown" name="selectedService" placeholder="Select Service" filter={true} optionLabel="name" value={checklist.selectedService} options={servicelist} onChange={(e) => handlechecklistChange(e)} maxSelectedLabels={3} />
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className="rowpaddingbottom">
                                        <Col lg="4">
                                            <span className="mandatorycolor">*</span>&nbsp;<span>Checklist Name</span>
                                        </Col>
                                        <Col lg="1">
                                            :
                                        </Col>
                                        <Col lg="6">
                                            <input type="text" className="form-control" placeholder="Checklist Name" value={checklist.checkListName} name="checkListName" onChange={(e) => handlechecklistChange(e)} />
                                        </Col>
                                    </Row>                                   
                                    <Row className="rowpaddingbottom">
                                        <Col lg="4">
                                            <span className="mandatorycolor">*</span>&nbsp;<span>Checklist Notes</span>
                                        </Col>
                                        <Col lg="1">
                                            :
                                        </Col>
                                        <Col lg="6">
                                            <textarea className="form-control" placeholder="Checklist Notes" value={checklist.notes} onChange={(e) => handlechecklistChange(e)} rows={2} cols={80} name="notes" />
                                        </Col>
                                    </Row>
                                    <Row className="rowpaddingbottom">
                                        <Col lg="4">
                                            <span>&nbsp;&nbsp;Data URL</span>
                                            <div className="errotextstyle labelpadding">( URL must start with 'https://' ) </div>
                                        </Col>
                                        <Col lg="1">
                                            :
                                        </Col>
                                        <Col lg="6">
                                            <textarea className="form-control" placeholder="Data URL" value={checklist.DataUrl} onChange={(e) => handlechecklistChange(e)} onBlur={e => checkURL(checklist.DataUrl)}  rows={2} cols={80} name="DataUrl" />
                                        </Col>
                                    </Row>
                                    {
                                        (checklist.checkListId > 0)
                                            ?
                                            <Row className="rowpaddingbottom">
                                                <Col lg="4">
                                                    &nbsp;<span>&nbsp;IsActive</span>
                                                </Col>
                                                <Col lg="1">
                                                    :
                                                </Col>
                                                <Col lg="6">
                                                    <input type="checkbox" checked={checklist.IsActive} onChange={(e) => handlechecklistChange(e)} name="IsActive" />

                                                </Col>
                                            </Row>
                                            :
                                            <React.Fragment></React.Fragment>
                                    }
                                    <Row className="rowpaddingbottom">
                                        <Col lg="4">
                                            &nbsp;<span>&nbsp;Select Template</span>
                                        </Col>
                                        <Col lg="1">
                                            :
                                        </Col>
                                        <Col lg="6">

                                            <span className="p-float-label acklables">
                                                <Dropdown id={'ddlidacktype'} value={checklist.templateId} options={templatelist} onChange={(e) => handlechecklistChange(e)} optionLabel="name" name="templateId" />
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className="rowpaddingbottom">
                                        <Col lg="4">
                                            <span className="mandatorycolor">*</span>&nbsp;<span>Template Type</span>
                                        </Col>
                                        <Col lg="1">
                                            :
                                        </Col>
                                        <Col lg="6">
                                            <span className="p-float-label acklables">
                                                <Dropdown id={'ddlidacktype'} disabled={checklist.templateId !== -1 ? true : false } value={checklist.templateType} options={templateTypes} onChange={(e) => handlechecklistChange(e)} optionLabel="name" name="templateType" />
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className="columnsheader rowpaddingbottom">
                                        <Col lg='4'>
                                            {((isAdmin === true) || (checklist.createdBy === LoginuserId)) ?
                                                <div style={{ float: 'left', paddingBottom: '5px', paddingTop: '5px' }}>
                                                    <Button className='ChecklistLockbutton' hidden={!checklist.isLocked} title="Locked By Admin/Checklist Creator" disabled={(!isAdmin) && !(checklist.createdBy === LoginuserId)} onClick={EditLockforChecklist}><FontAwesomeIcon icon={faLock} /></Button>
                                                    <Button className='ChecklistLockbutton' hidden={checklist.isLocked} disabled={(!isAdmin) && !(checklist.createdBy === LoginuserId)} onClick={EditLockforChecklist}><FontAwesomeIcon icon={faUnlock} /></Button>
                                                </div>
                                                : <React.Fragment></React.Fragment>
                                            }
                                        </Col>
                                        <Col lg='8'>
                                            <div className="addnewquestionchecklist" >
                                                <span className="errotextstyle">( Template Questions cannot be edited )&nbsp;&nbsp;</span><span title="Add Question" onClick={AddQuestionstoChecklist}> <FontAwesomeIcon icon={faPlus} style={{ backgroundColor: 'None', border: 'none' }} />&nbsp;Add Question</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Accordion defaultActiveKey={count.toString()} className="tempaccordianstyle">
                                        <table className="questiontable">
                                            <tbody>
                                                <tr>
                                                <td style={{ width: '90%' }}>
                                                    <h5 className="addquestiontitle"> {ShowQuestionList ? 'Questionnaire' : question.QuestionID > 0 ? 'Update Question' : 'Add New Question'}</h5>
                                                </td>
                                                <td style={{ width: '10%' }}>
                                                    <div className="floatright divprimary">
                                                        <Accordion.Toggle as={Button} eventKey={count.toString()} onClick={e => setquestiontoggledata(!questiontoggle)} style={{ background: 'none' }} className="btnaddquestionicon">
                                                        {(questiontoggle) ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />
                                                        }
                                                        </Accordion.Toggle>
                                                    </div>

                                                </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <Accordion.Collapse eventKey={count.toString()}>
                                            {
                                                (ShowQuestionList)
                                                    ?
                                                    <React.Fragment>  
                                                        <div className="ChecklistQuestionaireTableOuter">
                                                            <OrderList
                                                                value={QuestionarrieList}
                                                                id="ChecklistQuestionaireTable"
                                                                listStyle={{ height: "auto" }}
                                                                itemTemplate={itemTemplate}                                                                
                                                                onChange={(e) => { UpdateChecklistQuestionDisplayOrder(e.value.filter((x) => { return x.id != -1 })) }}>
                                                            </OrderList>
                                                        </div>
                                                        <br />
                                                    </React.Fragment>
                                                    :
                                                    <Row className="rowpaddingbottom">
                                                        <Col lg="12">
                                                            <br />
                                                            <Row className="rowpaddingbottom">
                                                                <Col lg="4">
                                                                    <span className="mandatorycolor labelpadding">*</span>&nbsp;<span>Question</span>
                                                                </Col>
                                                                <Col lg="1">
                                                                    :
                                                                </Col>
                                                                <Col lg="5">
                                                                    <textarea className="form-control" placeholder="Question" value={question.question} onChange={(e) => handlequestionChange(e)} rows={4} cols={5} name="question" />
                                                                </Col>
                                                            </Row>
                                                            {
                                                                (checklist.templateType !== 'Checklist Only')
                                                                    ?
                                                                        <Row className="rowpaddingbottom">
                                                                        <Col lg="4">
                                                                            <span className="mandatorycolor labelpadding">*</span>&nbsp;<span>Depends On</span>
                                                                        </Col>
                                                                        <Col lg="1">
                                                                            :
                                                                        </Col>
                                                                        <Col lg="5">
                                                                            <span className="p-float-label acklables">
                                                                                <Dropdown id={'ddlidacktype'} value={question.DependsOn} options={DependsOnItems} onChange={(e) => handlequestionChange(e)} optionLabel="name" name="DependsOn" />
                                                                            </span>
                                                                        </Col>
                                                                    </Row>
                                                                    : <React.Fragment></React.Fragment>
                                                            }
                                                            {
                                                                (question.DependsOn !== 'No' && !(isNullOrUndefined(question.DependsOn)))
                                                                    ?
                                                                    <React.Fragment>
                                                                        <Row className="rowpaddingbottom">
                                                                            <Col lg="4">
                                                                                <span className="mandatorycolor labelpadding">*</span>&nbsp;<span> Depends On Value</span>
                                                                            </Col>
                                                                            <Col lg="1">
                                                                                :
                                                                         </Col>
                                                                            <Col lg="5">
                                                                                <span className="p-float-label acklables">
                                                                                    <Dropdown id={'ddlidacktype'} value={question.DependsOnValue} options={question.ddldependsonvaluelistarray} onChange={(e) => handlequestionChange(e)} optionLabel="name" name="DependsOnValue" />
                                                                                </span>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="rowpaddingbottom">
                                                                            <Col lg="4">
                                                                                <span className="mandatorycolor labelpadding">*</span>&nbsp;<span> Control Type</span>
                                                                            </Col>
                                                                            <Col lg="1">
                                                                                :
                                                                 </Col>
                                                                            <Col lg="5">
                                                                                <span className="p-float-label acklables">
                                                                                    <Dropdown id={'ddlidacktype'} disabled={ValidateControlType(checklist.templateType, question)} value={question.ControlType} options={questioncontroltypedependsonoptions} onChange={(e) => handlequestionChange(e)} optionLabel="name" name="ControlType" />
                                                                                </span>
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                    
                                                                    : <React.Fragment>
                                                                        <Row className="rowpaddingbottom">
                                                                            <Col lg="4">
                                                                                <span className="mandatorycolor labelpadding">*</span>&nbsp;<span> Control Type</span>
                                                                            </Col>
                                                                            <Col lg="1">
                                                                                :
                                                                 </Col>
                                                                            <Col lg="5">
                                                                                <span className="p-float-label acklables">
                                                                                    <Dropdown id={'ddlidacktype'} disabled={ValidateControlType(checklist.templateType, question)} value={question.ControlType} options={questioncontroltypeoptions} onChange={(e) => handlequestionChange(e)} optionLabel="name" name="ControlType" />
                                                                                </span>
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                            }

                                                            
                                                            {
                                                                (question.ControlType === 'CheckBox' || question.ControlType === 'Radiobutton' || question.ControlType === 'Dropdown')
                                                                    ?
                                                                    <Row className="rowpaddingbottom">
                                                                        <Col lg="4">
                                                                            <span className="mandatorycolor labelpadding">*</span>&nbsp;<span> Control Values</span>
                                                                            <div className="errotextstyle labelpadding">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Enter Values as Comma seperated)</div>
                                                                        </Col>
                                                                        <Col lg="1">
                                                                            :
                                                                         </Col>
                                                                        <Col lg="5">
                                                                            <input type="text" className="form-control" disabled={ValidateControlType(checklist.templateType, question)} placeholder="Control Values" value={question.controlValue} onChange={(e) => handlequestionChange(e)} name="controlValue" />
                                                                        </Col>
                                                                    </Row>
                                                                    : <React.Fragment></React.Fragment>
                                                            }

                                                            <Row className="rowpaddingbottom">
                                                                <Col lg="4">
                                                                    <span className="mandatorycolor labelpadding">*</span>&nbsp;<span> Source Type</span>
                                                                </Col>
                                                                <Col lg="1">
                                                                    :
                                                        </Col>
                                                                <Col lg="5">
                                                                    <span className="p-float-label acklables">
                                                                        <Dropdown id={'ddlidacktype'} value={question.sourceType} options={sourcetypes} onChange={(e) => handlequestionChange(e)} optionLabel="name" name="sourceType" />
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                            {
                                                                (question.sourceType == 'Kusto')
                                                                    ?
                                                                    <React.Fragment>
                                                                        <Row className="rowpaddingbottom">
                                                                            <Col lg="4">
                                                                                <span className="mandatorycolor labelpadding">*</span>&nbsp;<span> Server Name</span>
                                                                            </Col>
                                                                            <Col lg="1">
                                                                                :
                                                                    </Col>
                                                                            <Col lg="5">
                                                                                <input type="text" className="form-control" placeholder="Server Name" value={question.serverName} onChange={(e) => handlequestionChange(e)} name="serverName" />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="rowpaddingbottom">
                                                                            <Col lg="4">
                                                                                <span className="mandatorycolor labelpadding">*</span>&nbsp;<span> Database</span>
                                                                            </Col>
                                                                            <Col lg="1">
                                                                                :
                                                                    </Col>
                                                                            <Col lg="5">
                                                                                <input type="text" className="form-control" placeholder="Database" value={question.Database} onChange={(e) => handlequestionChange(e)} name="Database" />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="rowpaddingbottom">
                                                                            <Col lg="4">
                                                                                <span className="mandatorycolor labelpadding">*</span>&nbsp;<span>Query</span>
                                                                            </Col>
                                                                            <Col lg="1">
                                                                                :
                                                                    </Col>
                                                                            <Col lg="5">
                                                                                <textarea className="form-control" placeholder="Query" value={question.Query} onChange={(e) => handlequestionChange(e)} rows={5} cols={5} name="Query" />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="rowpaddingbottom">
                                                                            <Col lg="4">
                                                                                <span className="mandatorycolor labelpadding">*</span>&nbsp;<span> Kusto Authentication Type</span>
                                                                            </Col>
                                                                            <Col lg="1">
                                                                                :
                                                                    </Col>
                                                                            <Col lg="5">
                                                                                <span className="p-float-label acklables">
                                                                                    <Dropdown id={'ddlidacktype'} value={question.kustoauthenticationtype} options={AuthenticationTypes} onChange={(e) => handlequestionChange(e)} optionLabel="name" name="kustoauthenticationtype" />
                                                                                </span>
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                    : (question.sourceType == 'URL')
                                                                        ? <React.Fragment>
                                                                            <Row className="rowpaddingbottom">
                                                                                <Col lg="4">
                                                                                    <span className="mandatorycolor labelpadding">*</span>&nbsp;<span> Data URL</span>
                                                                                    <div className="errotextstyle labelpadding">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;( URL must start with 'https://' ) </div>
                                                                                </Col>
                                                                                <Col lg="1">
                                                                                    :
                                                                            </Col>
                                                                                <Col lg="5">
                                                                                    <input type="text" className="form-control" onBlur={e => checkURL(question.dataurl)}  placeholder="Data URL" value={question.dataurl} onChange={(e) => handlequestionChange(e)} name="dataurl" />
                                                                                </Col>
                                                                            </Row>
                                                                        </React.Fragment>
                                                                        : <React.Fragment></React.Fragment>
                                                            }


                                                            <br />
                                                            <Row className="rowpaddingbottom">
                                                                <Col lg="4">
                                                                    <span className="mandatorycolor labelpadding">*</span>&nbsp;<span> Dynamic Validation</span>
                                                                </Col>
                                                                <Col lg="1">
                                                                    :
                                                                </Col>
                                                                <Col lg="5">
                                                                    <span className="p-float-label acklables">
                                                                        <Dropdown id={'ddlidacktype'} value={question.dynamicValidation} options={dynamicValidationTypes} onChange={(e) => handlequestionChange(e)} optionLabel="name" name="dynamicValidation" />
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                            <br/>
                                                            <Row className="rowpaddingbottom">
                                                                <Col lg='8'></Col>
                                                                <Col lg='3'>
                                                                    <Button className='checkquestionbuttons' onClick={SaveQuestionnarie} disabled={disableaddbuttonforquestion}>{(question.Questionindex !== -1) ? 'Update' : 'Add'}</Button>&nbsp;&nbsp;
                                                                    <Button className='checkquestionbuttons' onClick={showquestionlist} >Cancel</Button>                                              
                                                                </Col>
                                                                <Col lg='1'></Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>

                                            }


                                        </Accordion.Collapse>
                                    </Accordion>
                               </div>
                            </React.Fragment>
                            : <React.Fragment></React.Fragment>
                    }
                    <br/>
                    <Row>
                        <Col lg='6' style={{ textAlign: 'right' }}>
                            {
                                (ischecklistsavebuttondisabled)
                                    ? <span className="errotextstyle">Please correct the errors to Save</span>
                                    : <React.Fragment></React.Fragment>
                            }
                                &nbsp;<Button title="Save" className='DailydataModelbuttons' disabled={ischecklistsavebuttondisabled || ((!isAdmin) && !(checklist.createdBy === LoginuserId) && (checklist.isLocked))} onClick={SaveOnCallchecklistData}>Save</Button>
                        </Col>
                        <Col lg='6'>
                            <Button title="Cancel" className='DailydataModelbuttons checklistcloseicon' onClick={hideCHECKLISTPopup} id="cklclose">Cancel</Button>
                        </Col>
                    </Row>

                </Modal.Body>
               
            </Modal>
            <Dialog visible={archiveDialog} style={{ width: '450px' }} header="Confirm" modal footer={archiveDialogFooter} onHide={hideArchiveDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', color: 'red' }} />
                    <span>Archiving hides this item permanently.<br></br> Are you sure you want to archive?</span>
                </div>
            </Dialog>
        </div>
    )
});

ChecklistTable.displayName = 'ChecklistTable';
export default ChecklistTable;