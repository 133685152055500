import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import { actionCreators, reducer, IDataCenter } from '../../store/DCMapping';
import LoadingOverlay from 'react-loading-overlay';
import "../../assets/style/scss/components/DCMapping.scss";
import { DCMappingTable, DCMappingInsertUpdateTable } from './child-components';
import { DeprecatedFunctionalityBanner } from '../../components';

type DCMappingProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly history: History }
    & typeof useParams;

const DCMapping: React.FC<DCMappingProps> = ({
    isLoading,
    isAuthenticated,
    addUserVisit,
    GetDataCenters,
    datacenters,
    dataCenterNameOptions,
    azureRegionNameOptions,
    locationOptions,
    countryOptions,
    updateRecord,
    deleteRecord,
    currentRow,
    updateCurrentRecord
}) => {
    useEffect(() => {
        if (isAuthenticated)
            addUserVisit(0, 'DCMapping.tsx_v2');
        GetDataCenters()
    }, [isAuthenticated]);
    const [insertBool, setinsertBool] = useState(false);
    const [showUpdateInsertPage, setUpdateInsertPage] = useState(false);    
    const updateRecordOpenModal = (rowData, insertBool) => {
        setinsertBool(insertBool)
        updateCurrentRecord(rowData)  
        setUpdateInsertPage(true)        
    }    
    const closeUpdatePage = () => {
        updateCurrentRecord({
            id: 0,
            dataCenterName: '',
            azureRegionName: '',
            location: '',
            country: '',
            referenceInfo: '',
            regionID: 0,
            regionOid: '',
            regionCode: '',
            regionLifeCycle: '',
            regionType: '',
            geographyId: 0,
            updatedby: ''
        })
        setUpdateInsertPage(false)
    }
    const updateRecordCloseModal = (row: IDataCenter, insertBool: boolean) => {
        updateRecord(row, insertBool)
        setUpdateInsertPage(false)        
    }
    return (
        <div>
            <DeprecatedFunctionalityBanner />
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        {!showUpdateInsertPage &&
                            <DCMappingTable datacenters={datacenters} azureRegionNameOptions={azureRegionNameOptions} countryOptions={countryOptions}
                                dataCenterNameOptions={dataCenterNameOptions} locationOptions={locationOptions} updateRecordOpenModal={updateRecordOpenModal} deleteRecord={deleteRecord} />
                        }
                        {showUpdateInsertPage &&
                            <DCMappingInsertUpdateTable currentRow={currentRow} updateRecord={updateRecordCloseModal} cancel={closeUpdatePage} insertBool={insertBool }
                            />
                        }
                    </div>
                </section>
            </LoadingOverlay>
        </div>
    );
};

const mapStateToProps = (state: IApplicationState) => ({
    ...state.DCMapping,
    isAuthenticated: state.authUser.isAuthenticated
})

export default connect(mapStateToProps, actionCreators)(DCMapping);