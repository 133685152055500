import React, { useState, useMemo, Fragment, useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import "../../../assets/style/scss/components/DCMapping.scss";
import { MultiSelect } from 'primereact/multiselect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPencilAlt, faPlus, faEraser } from '@fortawesome/free-solid-svg-icons';
import { IDataCenter } from '../../../store/DCMapping';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { FontAwesomeIconMemo, Checkbox } from '../../../components';
import { Dialog } from 'primereact/dialog';

type DCMappingTableProps = {
    datacenters: [],
    dataCenterNameOptions: [],
    azureRegionNameOptions: [],
    locationOptions: [],
    countryOptions: [],
    updateRecordOpenModal: any,
    deleteRecord: any
};
const DCMappingTable = React.memo<DCMappingTableProps>(({
    datacenters,
    dataCenterNameOptions,
    azureRegionNameOptions,
    locationOptions,
    countryOptions,
    updateRecordOpenModal,
    deleteRecord
}) => {
    useEffect(() => { 
        setDataCenterName(dataCenterNameOptions.map((e: { name: '' }) => { return e.name }))
        setAzureRegionName(azureRegionNameOptions.map((e: { name: '' }) => { return e.name }))
        setLocation(locationOptions.map((e: { name: '' }) => { return e.name }))
        setCountry(countryOptions.map((e: { name: '' }) => { return e.name }))           
    }, [dataCenterNameOptions]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedDataCenterName, setDataCenterName] = useState(dataCenterNameOptions.map((e: { name: '' }) => { return e.name }));
    const [selectedAzureRegionName, setAzureRegionName] = useState(azureRegionNameOptions.map((e: { name: '' }) => { return e.name }));
    const [selectedLocation, setLocation] = useState(locationOptions.map((e: { name: '' }) => { return e.name }));
    const [selectedCountry, setCountry] = useState(countryOptions.map((e: { name: '' }) => { return e.name }));            
    const dt = useRef(null);
    const setGlobalFilterValue = (event) => {
        setGlobalFilter(event.target.value);
    }
    const [deleteDialog, setdeleteDialog] = useState(false);    
    const emptyRow: IDataCenter = {
        id: 0,
        dataCenterName: '',
        azureRegionName: '',
        location: '',
        country: '',
        referenceInfo: '',
        regionID: 0,
        regionOid: '',
        regionCode: '',
        regionLifeCycle: '',
        regionType: '',
        geographyId: 0,
        updatedby: ''
    }
    const [currentdeleteRecord, setcurrentdeleteRecord] = useState(emptyRow);
    const clearFilter = () => {   
        setDataCenterName([]);
        setAzureRegionName([]);
        setLocation([]);
        setCountry([]);
        setGlobalFilter('');        
        dt.current.reset();               
    }
    const deleteRecordConfirm = (rowData) => {
        setcurrentdeleteRecord(rowData)
        setdeleteDialog(true);        
    }
    const hideDeleteDialog = () => {
        setcurrentdeleteRecord(emptyRow)
        setdeleteDialog(false);
    }
    const deleteRecordConfirmed = () => {
        deleteRecord(currentdeleteRecord);
        setdeleteDialog(false);
    }
    const UpdateDeletBodyTemplate = (rowData) => {
        return (           
             <React.Fragment>
                <span ><span >
                    <FontAwesomeIcon title='Click to edit the record' className="bluecoloricons Iconsstyles" icon={faPencilAlt} onClick={e => updateRecordOpenModal(rowData,false)} />
                </span> </span> &nbsp;
                <span>
                    <FontAwesomeIcon title='Click to Delete the record' className="redcolorIcons Iconsstyles" icon={faTrash} onClick={e => deleteRecordConfirm(rowData)} /></span> &nbsp;
            </React.Fragment>

        );
    }    
    const onFilterDataCenterName = (e) => {        
        dt.current.filter(e.value, 'dataCenterName', 'in');
        setDataCenterName(e.value);
    }
    const onFilterAzureRegionName = (e) => {
        dt.current.filter(e.value, 'azureRegionName', 'in');
        setAzureRegionName(e.value);
    }
    const onFilterLocation = (e) => {
        dt.current.filter(e.value, 'location', 'in');
        setLocation(e.value);
    }
    const onFilterCountry = (e) => {
        dt.current.filter(e.value, 'country', 'in');
        setCountry(e.value);
    }
    const deleteDialogFooter = (
        <React.Fragment>
            <Button className="p-button-text btn-primary" onClick={() => hideDeleteDialog()} >No</Button>
            <Button className="p-button-text btn-primary" onClick={() => deleteRecordConfirmed()}>Yes</Button>
        </React.Fragment>
    );

    const DataCenterNameFilter = <MultiSelect value={selectedDataCenterName} options={dataCenterNameOptions} onChange={onFilterDataCenterName} optionLabel="name" optionValue="name" placeholder="All" filter className="p-column-filter" />;
    const AzureRegionNameFilter = <MultiSelect value={selectedAzureRegionName} options={azureRegionNameOptions} onChange={onFilterAzureRegionName} optionLabel="name" optionValue="name" placeholder="All" filter className="p-column-filter" />;
    const LocationFilter = <MultiSelect value={selectedLocation} options={locationOptions} onChange={onFilterLocation} optionLabel="name" optionValue="name" placeholder="All" filter className="p-column-filter" />;
    const CountryFilter = <MultiSelect value={selectedCountry} options={countryOptions} onChange={onFilterCountry} optionLabel="name" optionValue="name" placeholder="All" filter className="p-column-filter" />;    
   
    return (

        <div>
   
            <Card className="cardstyle">
                <CardBody className="cardbodyhederstyle">
                    <Row>
                        <Col lg="4"></Col>
                        <Col lg="4" style={{ textAlign: 'center' }}>
                            <div>
                                <h3 className="headertext"> Data Center to Azure Region</h3>
                            </div>
                        </Col>
                        <Col lg="4" style={{ textAlign: 'right' }}>
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" style={{ verticalAlign: 'middle' }} />&nbsp;
                                <InputText style={{ width: '240px', verticalAlign: 'top' }} type="search"
                                    value={globalFilter} onChange={(e) => setGlobalFilterValue(e)} placeholder="Global Search" />&ensp;&ensp;            
                            </span>
                            <Button className="DCbuttons btn" onClick={e => updateRecordOpenModal(emptyRow, true)}>
                                <FontAwesomeIcon cursor='pointer' icon={faPlus} />&nbsp;&nbsp;New</Button>&ensp;   
                            <Button className="DCbuttons btn" onClick={e => clearFilter()}>
                                <FontAwesomeIcon cursor='pointer' icon={faEraser} />&nbsp;Clear</Button>
                        </Col>
                       
                    </Row>
                </CardBody>
            </Card>
            <br />
            <div className="datatable-filter-demo">
                <div className="card">
                    <DataTable ref={dt} value={datacenters} paginator rows={15}
                        className="p-datatable-customers"
                        globalFilter={globalFilter} emptyMessage="No Data Centers found.">
                        <Column field="dataCenterName" header="Data Center Name" filter filterElement={DataCenterNameFilter} />
                        <Column field="azureRegionName" header="Azure Region Name" filter filterElement={AzureRegionNameFilter}/>
                        <Column field="location" header="Location" filter filterElement={LocationFilter}/>
                        <Column field="country" header="Country" filter filterElement={CountryFilter} />
                        <Column field="referenceInfo" header="Reference Info" filter filterMatchMode="contains" filterPlaceholder="Search by Reference" />
                        <Column className="thwidth100 textaligncenter" field="id" header="Actions" body={UpdateDeletBodyTemplate} />                        
                    </DataTable> 
                </div>
            </div>
            <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDialogFooter} onHide={hideDeleteDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', color: 'red' }} />
                    <span>Are you sure you want to delete?</span>
                </div>
            </Dialog>
        </div>
    )
});

DCMappingTable.displayName = 'DCMappingTable';
export default DCMappingTable;