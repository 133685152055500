import React, { useState } from 'react';
import { IMonthlyReport } from '../../../store/service-health';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'react-datepicker/dist/react-datepicker.css';
import { isNullOrUndefined, isArrayWithLength, renderToastifyMsg } from '../../../utils';
import { Card, Row, Col } from 'reactstrap';
import FontAwesomeIconMemo from '../../../components/FontAwesomeIconMemo';
import { faChartBar, faFire, faStop } from '@fortawesome/free-solid-svg-icons';

import moment from 'moment';
type MonthlyReportProps = {
    MonthlyData?: IMonthlyReport[];
    MonthlyheaderArr: any;
    MonthlyKPiArr: any;
    monthlyStartDate: any;
    liKPIValueKeys: any;
};

const MonthlyReport = React.memo<MonthlyReportProps>(({
    MonthlyData,
    MonthlyheaderArr,
    MonthlyKPiArr,
    monthlyStartDate,
    liKPIValueKeys
}) => {
    const datediff = () => {
        var now = moment();
        var selected = moment(monthlyStartDate);
        var diff = now.diff(selected, "day");
        return diff;
    }
    const [ShowHistory, SetShowHistory] = useState(false);
    const ChangeHistory = (e) => {
        SetShowHistory(e.target.checked);
    }
    // To Compare the weekly data with target values
    const AddColorForFactAvailabilityBudget = (data, key, target) => {
        var weekColor = { Color: "" };
        data[key] = (parseFloat(data[key]) < 1 && parseFloat(data[key]) > 0) ? (data[key] * 100).toFixed(4) + "%" : data[key];
        var reg = /[0-9][0-9]\.[0-9][%]/;
        var availabilityTarget = parseFloat(target);
        if (availabilityTarget != null) {
            OperatorOverloadAvailabilityBudget(data, key, availabilityTarget, weekColor, "<=");
        } else if (/[0-9]+/.test(data.targetName)) {
            OperatorOverloadAvailabilityBudget(data, key, target, weekColor, ">=");
        }
        return weekColor.Color;
    }
    // To Format the weekly data
    const OperatorOverloadAvailabilityBudget = (data, key, target, weekColor, operator) => {
        data[key] = String(data[key]).replace(/[(\)]/gi, "");
        weekColor.Color = SetColorForWeekAvailabilityBudget(data[key], target, operator);
    }
    const SetColorForWeekAvailabilityBudget = (weekVal, target, operator) => {

        var color = "";
        var wk1 = String(weekVal).split("/");
        for (var i = 0; i < wk1.length; i++) {
            if (wk1[i] == "") {
                color = "grey";
            } else if (wk1[i] != "NA" && !isNaN(parseFloat(wk1[i]))) { // IF not a number or NA
                if (operator == ">=") {
                    if (parseFloat(wk1[i]) > target) {
                        color = "red";
                        break;
                    } else {
                        color = "green";
                    }
                } else if (operator == "<=") {
                    if (parseFloat(wk1[i]) < target) {
                        color = "red";
                        break;
                    } else {
                        color = "green";
                    }
                } else if (operator == "<") {
                    if (parseFloat(wk1[i]) <= target) {
                        color = "red";
                        break;
                    } else {
                        color = "green";
                    }
                }
            }
            if (/[sec]{3}/.test(wk1[i])) {
                break;
            }
        }

        return color;
    }
    return (
        <div id='table_wrapper'>
            <div>

            </div>
            {
                (isArrayWithLength(MonthlyData))
                    ?
                    <React.Fragment>
                        <table className='table tableGrid stickyTable monthlydatatable' id='tdDailydata'>
                            <thead className='thead-inverse'>
                                <tr className='monthlydatatr'>
                                    <th className='stickyth'>
                                        <div className='serviceHeader'>Service</div></th>
                                    <th className='stickythCol2'>
                                        <div className='viewHeader'>Heatmap View Name </div></th>
                                    <th className='stickythCol3'>
                                        <div className='targetHeader'>Target</div></th>
                                    {isArrayWithLength(MonthlyheaderArr) && MonthlyheaderArr.map((dh, key) => (

                                        <th style={{ width: dh.width}} className='tableheaderVal' key={key}>{dh.val}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {isArrayWithLength(MonthlyData) && MonthlyData.map((dhs: IMonthlyReport, key) => (
                            <React.Fragment key={key}>
                                {
                                            <tr style={{ fontSize: '14px' }}>
                                                {
                                                    (dhs.spanKpi > 0) ?
                                                        <td className='stickytd kpi data' rowSpan={dhs.spanKpi > 0 ? dhs.spanKpi : 0}
                                                            style={{backgroundColor: (dhs.viewName == "" || dhs.viewName == undefined || dhs.viewName == null) ? '#dde0ee' : '', border: '1px solid rgba(18, 73, 109, 0.4)' }}> {dhs.serviceName}</td>
                                                        : <React.Fragment></React.Fragment>
                                                }
                                                <td className='ml01 stickytdCol2 data tabletdVal'> {dhs.viewName}</td>
                                                <td className='ml01 stickytdCol3 data tabletdVal'> {dhs.targetName}</td>
                                                {isArrayWithLength(liKPIValueKeys) && liKPIValueKeys.map((dk, kpiArrkey) => (
                                                    <React.Fragment key={kpiArrkey}>
                                                        {
                                                            <td className={AddColorForFactAvailabilityBudget(dhs.liKPIValues, dk, dhs.targetName)} style={{ textAlign: 'center', border: '1px solid rgba(18, 73, 109, 0.4)' }} key={kpiArrkey}>{dhs.liKPIValues[dk]}</td>
                                                        }
                                                    </React.Fragment>
                                                ))}
                                            </tr>
                                }

                            </React.Fragment>

                        ))}
                    </tbody>
                            </table>
                    </React.Fragment>
                    :
                    <React.Fragment></React.Fragment>
            }    
        </div>
    );
});

MonthlyReport.displayName = 'MonthlyReport';

export default MonthlyReport;