import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Select } from 'react-functional-select';
import { IApplicationState, ReduxAction } from '../store';
import { useParams } from 'react-router-dom';
import { actionCreators, reducer } from '../store/filter-service-health';
import { REPORT_TYPE_DROPDOWN } from '../config/constants';
import { FontAwesomeIconMemo, Spinner } from '../components';
import 'react-datepicker/dist/react-datepicker.css';
import {
    Card,
    CardTitle, Row, Col
} from 'reactstrap';
import { Checkbox } from '.';

type FilterServiceHealthProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & typeof useParams;

let startDate = new Date();
startDate.setDate(startDate.getDate() - 15);
let endDate = new Date();

const handleStartDateChange = (stDate: any) => {
    startDate = stDate
};
const handleEndDateChange = (edDate: any) => {
    endDate = edDate
};


const FilterServiceHealth: React.FC<FilterServiceHealthProps> = ({
    isLoading,
    checkboxValue,
    selectedDropdownOption,
    dailyViewData,
    reportSelectOption,
    getHeatmapView,
    handleOnCheck
}) => {
    
    useEffect(() => {
        getHeatmapView();
    }, [getHeatmapView]);

    return (
        <section className='section'>
            <div className='container'>
                <Spinner isLoading={isLoading} />
               
            </div>

            <div>
                <div className='column'>

                </div>
                <div>
                    <Card body style={{ borderRadius: '8px', width: '100%', padding: '0'  }}>
                        <CardTitle style={{ marginTop: '0.75rem' }}>
                            <Row>
                                <Col lg='1'>
                                    <Select
                                        options={REPORT_TYPE_DROPDOWN}
                                        initialValue={reportSelectOption}
                                        onOptionChange={selectedDropdownOption}
                                    />
                                </Col>
                                <Col lg='2'>
                                    <Checkbox
                                        checked={checkboxValue}
                                        onCheck={handleOnCheck}
                                    />
                                </Col>
                                

                                <Col lg='5'>
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        <div className='inlinedivs'>
                                            <span className='icon'>
                                                <FontAwesomeIconMemo icon={'external-link-square-alt'} style={{ backgroundColor: 'None', border: 'none' }} />&nbsp; Performance
                                         </span>
                                        </div>
                                        <div className='inlinedivs'>
                                            <span>

                                                <FontAwesomeIconMemo icon={'user-edit'} style={{ backgroundColor: 'None', border: 'none' }} />&nbsp; Comments
                                        </span>
                                        </div>
                                        <div className='inlinedivs'>
                                            <span>

                                                <FontAwesomeIconMemo icon={'history'} style={{ backgroundColor: 'None', border: 'none' }} />&nbsp; History

                                        </span>
                                        </div>
                                        <div className='inlinedivs'>
                                            <span>

                                                <FontAwesomeIconMemo icon={'info-circle'} style={{ backgroundColor: 'None', border: 'none' }} />&nbsp; Additional Info

                                        </span>
                                        </div>
                                    </div>

                                </Col>
                            </Row>

                        </CardTitle>

                    </Card>
                </div>
            </div>




        </section>
    );
};

const mapStateToProps = (state: IApplicationState) => state.filterServiceHealth;

export default connect(mapStateToProps, actionCreators)(FilterServiceHealth)
