import React, { useEffect } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import { actionCreators, reducer } from '../../store/MonthlyAvailbilityQOS';
import { MonthlyAvailbilityQOSTable } from '../MonthlyAvailbilityQOS/child-components';
import LoadingOverlay from 'react-loading-overlay';
import { NewFunctionalityBanner } from '../../components';

type MonthlyAvailbilityQOSProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly history: History }
    & typeof useParams;

const MonthlyAvailbilityQOS: React.FC<MonthlyAvailbilityQOSProps> = ({
    isLoading,
    addUserVisit,
    isAuthenticated,
    monthlyStartDate,
    onmonthlyStartDateChange,    
    IsMonthlyDataLoaded,
    ClearMonthlyDate,
    getMonthlyQOSData,
    uniquev,
    uniquec,
    liKPIValueKeys,
    monthlyQOSData


}) => {
    useEffect(() => {
        if (isAuthenticated)
            addUserVisit(0, 'MonthlyAvailbilityQOS.tsx_v2');
    }, [isAuthenticated]);
    useEffect(() => {
        if (monthlyStartDate) {
            getMonthlyQOSData(monthlyStartDate);
        }
    }, [monthlyStartDate]);
    return (
        <div>
            <NewFunctionalityBanner />
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        <MonthlyAvailbilityQOSTable
                            monthlyStartDate={monthlyStartDate}
                            onmonthlyStartDateChange={onmonthlyStartDateChange}
                            IsMonthlyDataLoaded={IsMonthlyDataLoaded}
                            ClearMonthlyDate={ClearMonthlyDate}
                            uniquev={uniquev}
                            uniquec={uniquec}
                            liKPIValueKeys={liKPIValueKeys}
                            monthlyQOSData={monthlyQOSData}
                          
                        />
                    </div>
                </section>
            </LoadingOverlay>

        </div>
    );

};
const mapStateToProps = (state: IApplicationState) => ({
    ...state.MonthlyAvailbilityQOS,
    isAuthenticated: state.authUser.isAuthenticated

})

export default connect(mapStateToProps, actionCreators)(MonthlyAvailbilityQOS);
