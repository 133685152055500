import React, { useState, Fragment } from 'react';
import DatePicker, { getDay } from 'react-datepicker'
import { ReduxAction, IAppThunkAction } from '../../../store';
import { IDropdownOption } from '../../../store/PIRLearnings';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignal, faFire, faAsterisk, faChevronDown, faPencilAlt, faArrowAltCircleLeft, faRecycle, faRing, faRedo, faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import { IPropPirLearningsData } from '../../../store/PIRLearnings';
import { Picky } from 'react-picky';

type PIRLearningsProps = {
    readonly PIRLearnings: IPropPirLearningsData[];
    togglePIR: any;
    displayText: any;
    ChangedDisplayText: any;
    PIRLearningsDate: any;
    readonly handlePIRLearningsDateChange: any;
   
    readonly setshoweditpopup: any;
    readonly RowRefresh: any;
    pirtextLength: any;
    showdisplayText: any;
    searchText: any;

    readonly OwningService: IDropdownOption[];
    readonly selectedItemForLabel: IDropdownOption;
    readonly GetOwnedServices: (option: IDropdownOption) => IAppThunkAction<ReduxAction>;

    readonly RouteCauseCategory: IDropdownOption[];
    readonly selectedItemForRootLabel: IDropdownOption;
    readonly GetRouteCauseFilter: (option: IDropdownOption) => IAppThunkAction<ReduxAction>;
};

const PIRLearningsTable = React.memo<PIRLearningsProps>(({
    PIRLearnings,
    togglePIR,
    displayText,
    ChangedDisplayText,
    PIRLearningsDate,
    handlePIRLearningsDateChange,
    selectedItemForLabel,
    OwningService,
    selectedItemForRootLabel,
    RouteCauseCategory,
    setshoweditpopup,
    RowRefresh,
    pirtextLength,
    showdisplayText,
    searchText,
    GetOwnedServices,
    GetRouteCauseFilter
}) => {
    const isfirstDay = (date) => {
        if (date.getDate() === 1) {
            return true;
        }
        return false;
    };   

    const filterBasedSearch = (item) => {
        if (item.serviceName.toLowerCase().includes(searchText.toLowerCase())
            || item.servicesImpacted.toLowerCase().includes(searchText.toLowerCase())
            || item.summary.toLowerCase().includes(searchText.toLowerCase())
            || item.pir.toLowerCase().includes(searchText.toLowerCase())
            || item.resources[0].description.toLowerCase().includes(searchText.toLowerCase())
            || item.resources[0].link.toLowerCase().includes(searchText.toLowerCase())
            || item.rootCauseCategory.toLowerCase().includes(searchText.toLowerCase())
            || item.sharedLearning.toLowerCase().includes(searchText.toLowerCase())) {
            return true
        }
        else {
            return false;
        }
    };

    const getPirText = (pirText, row) => {
        if (row["IsMore"] != null && row["IsMore"] == true) {
            pirtextLength = pirText.length;
            return pirText;
        }
        if (row["IsMore"] == null)
            row["IsMore"] = false;
        pirtextLength = pirText.length;
        return pirText.length > 600 ? pirText.substr(0, 600 - 1) : pirText;
    };

    return (
        <React.Fragment>
            <br />
            <div>
            <Card className="cardstyle">
                <CardBody className="cardbodystyle">
                    <Row>
                        <Col lg="3">
                            <h3 className="headertext PIRheader"> PIRs Learnings</h3>
                        </Col>
                        <Col lg="2" className="buttondiv">
                            <Button className='btn btn-primary btn-sm headerbutton PIRbutton' onClick={togglePIR}>
                                <span>{
                                    displayText
                                }</span>&nbsp;
                                <FontAwesomeIcon icon={faChevronDown} /> 
                            </Button>
                        </Col>
                            <Col lg="4">
                                <input type="text" placeholder="Search" className="form-control" name="Val2" value={searchText} onChange={e => ChangedDisplayText(e)} />
                        </Col>
                        <Col lg="3">
                            <span>Select Date : </span>
                            <DatePicker className='InputStyle'
                                selected={PIRLearningsDate}
                                onChange={handlePIRLearningsDateChange}
                                filterDate={isfirstDay}
                                calendarIcon={true}
                                calendarAriaLabel={true}
                            />
                        </Col>
                        <Col lg="1"></Col>
                        
                    </Row>
                </CardBody>
            </Card>
            <div className="divpaddingtop">
                        <Row>
                            <Col lg="6">           
                            </Col>
                            <Col lg="6">
                                <span className="label-info fontpir">Note: IcM data pulled from IcM Kusto DB which has up to 15 min data propagation delay.</span>
                            </Col>
                        </Row>
                </div>
                <div>
                    <br/>
                    <Row className="tblpadding">              
                        <Col lg="12">
                            <table id="tblPIRLearnings" className="tblPIRLearnings tblborder">
                                <thead>
                                    <tr>
                                        <th className="thwidth100">PIR #</th>
                                        <th className="thwidth300">PIR Title</th>
                                        <th className="thwidth300">
                                            Owning Service
                                            <div className="pirpickydiv">
                                                <Picky
                                                    id='picky'
                                                    options={OwningService}
                                                    valueKey='value'
                                                    labelKey='label'
                                                    multiple={false}
                                                    value={selectedItemForLabel}
                                                    includeSelectAll={true}
                                                    includeFilter={true}
                                                    onChange={GetOwnedServices}
                                                    dropdownHeight={300}
                                                    numberDisplayed={1}
                                                    placeholder='Select All'
                                                    className='pickyPIR'
                                                    keepOpen={false}
                                                />
                                            </div>
                                         </th>
                                        <th className="thwidth120">
                                            Root Cause Category
                                            <div className="pirpickydiv">
                                                <Picky
                                                    id='picky'
                                                    options={RouteCauseCategory}
                                                    valueKey='value'
                                                    labelKey='label'
                                                    multiple={false}
                                                    value={selectedItemForRootLabel}
                                                    includeSelectAll={true}
                                                    includeFilter={true}
                                                    onChange={GetRouteCauseFilter}
                                                    dropdownHeight={300}
                                                    numberDisplayed={1}
                                                    placeholder='Select All'
                                                    className='pickyPIR'
                                                    keepOpen={false}
                                                />
                                            </div>
                                         </th>
                                        <th className="thwidth300">Document Link</th>
                                        <th className="thwidth100">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        isArrayWithLength(PIRLearnings) ?
                                            <React.Fragment>
                                                {isArrayWithLength(PIRLearnings) ?
                                                    PIRLearnings
                                                        .filter((item: IPropPirLearningsData) => {
                                                            if (filterBasedSearch(item)) {
                                                                return true
                                                            }
                                                        })
                                                        .map((row: IPropPirLearningsData, index) => (
                                                    <React.Fragment key={index}>
                                                        <tr style={{ backgroundColor: (index % 2 === 0) ? 'rgba(0,0,0,.05)' : 'white', border: '2px solid #666666 !important' }}>
                                                                    <td rowSpan={2} style={{ border: '2px solid #666666 !important' }}>
                                                                <span>
                                                                    <a href={"https://portal.microsofticm.com/imp/v3/incidents/postmortem/" + row.pir + "/home"} target="_blank"> {row.pir}</a>
                                                                </span>
                                                            </td>
                                                            <td>{row.summary}</td>
                                                            <td>{row.serviceName}</td>
                                                            <td>{row.rootCauseCategory}</td>
                                                            <td>
                                                                <React.Fragment>{isArrayWithLength(row.resources) ?
                                                                    <React.Fragment><a href={row.resources[0].link} target="_blank">{row.resources[0].description}</a></React.Fragment> : <React.Fragment></React.Fragment>}</React.Fragment>
                                                            </td>
                                                            <td className="centericons">
                                                                        <span onClick={e => setshoweditpopup(e, row)}><FontAwesomeIcon title='Edit' className="Iconsstyles" icon={faPencilAlt} /></span> &nbsp;
                                                                        <span onClick={e => RowRefresh(e, row, index)}><FontAwesomeIcon title='Refresh' className="Iconsstyles" icon={faRedoAlt}  /></span>
                                                            </td>
                                                        </tr>
                                                        <tr style={{ backgroundColor: (index % 2 === 0) ? 'rgba(0,0,0,.05)' : 'white' }}>
                                                                    <td colSpan={6} className="RTELIdots">

                                                                <div dangerouslySetInnerHTML={{ __html: getPirText(row.sharedLearning, row) }}></div>
                                                                {
                                                                            (pirtextLength > 600 && row.IsMore !== true) ? 
                                                                                (
                                                                                    <span onClick={(e) => showdisplayText(e, row)} className="moreless" >
                                                                                        ...More
                                                                                    </span>
                                                                                )
                                                                                : (pirtextLength > 600 && row.IsMore === true) ?
                                                                                    (
                                                                                        <span onClick={(e) => showdisplayText(e, row)} className="moreless" >
                                                                                            Less
                                                                                        </span>
                                                                                    )
                                                                            : <React.Fragment></React.Fragment>
                                                                }
                                                            </td>
                                                        </tr>
                                                        </React.Fragment>
                                                    ))
                                                    : <React.Fragment></React.Fragment>
                                                }

                                            </React.Fragment>
                                            : 
                                            <React.Fragment>
                                                <tr>
                                                    <td colSpan={11} style={{ textAlign: 'center' }} >
                                                        No Records
                                                    </td>
                                                </tr>
                                            </React.Fragment>

                                    }
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
});

PIRLearningsTable.displayName = 'PIRLearningsTable';
export default PIRLearningsTable;