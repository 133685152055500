import { BaseService } from './base.service';
import {
    IDailyData, IViewDetails, IAllViewDetails, IViewConfigColorLogics, IViewConfigNoofDecimals,
    IViewConfigColorSchemes, IColorScheme, HmMetric, IBulkViewDetails, IWeeklyReport, IYear, IPropDraft, IMSERAdditionalInfo, LookupData, PropFleetutilizationHMList, IServices, IFleetcolorSchemes
} from '../store/service-health';
import { IPropCalender, IServiceGroup } from '../store/home-tiles';
import { string } from 'prop-types';
import { IPropHeatmapAdminData } from '../store/HeatmapAdmin';

/**
 * SampleData API abstraction layer communication via Axios (typescript singleton pattern)
 */
class DailyService extends BaseService {
    private static _dailyService: DailyService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): DailyService {
        return (this._dailyService || (this._dailyService = new this('DailyData')));
    }

    public async getDailyDataAsyn(Serviceid: number, fromDate: string, toDate: string, viewId: number): Promise<IDailyData[]> {
        const { data } = await this.$http.get<IDailyData[]>(`GetHeatmapData?serviceid=${Serviceid}&fromDate=${fromDate}&toDate=${toDate}&viewId=${viewId}`);
        return data;
    }

    public async getMultiViewDailyDataAsyn(Serviceid: number, fromDate: string, toDate: string, viewId: string): Promise<IDailyData[]> {
        const { data } = await this.$http.get<IDailyData[]>(`GetHeatmapMultiViewData?serviceid=${Serviceid}&fromDate=${fromDate}&toDate=${toDate}&viewId=${viewId}`);
        return data;
    }

    public async getMSERCommentsAdditionalInfo(viewId: number, fromDate: string, toDate: string): Promise<IMSERAdditionalInfo[]> {
        const { data } = await this.$http.get<IMSERAdditionalInfo[]>(`GetHeatMapAdditionInfo?viewId=${viewId}&fromDate=${fromDate}&toDate=${toDate}`);
        return data;
    }
    
    public async UpdateHeatmapAdditionInfoData(additionInfodata, effectiveDate: string): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateHeatmapAdditionalInfoData?effectiveDate=${effectiveDate}`, additionInfodata);
        return data;
    }

    public async DeleteMSERComments(AdditionInfoId: number): Promise<number> {
        const { data } = await this.$http.post<number>(`DeleteMSERComments?AdditionInfoId=${AdditionInfoId}`);
        return data;
    }

    public async getViewDataAsyn(serviceid: number, viewId: number): Promise<IViewDetails[]> {
        const { data } = await this.$http.get<IViewDetails[]>(`GetViewDetails?serviceid=${serviceid}&viewId=${viewId}`);
        return data;
    }
    
    public async ValidateLiveSiteService(serviceid: string): Promise<boolean> {
        const { data } = await this.$http.get<boolean>(`ValidateLiveSiteService?serviceid=${serviceid}`);
        return data;
    }

    public async AddLiveSiteService(serviceId, serviceName, GroupId): Promise<number> {
        const { data } = await this.$http.post<number>(`AddLiveSiteService?serviceId=${serviceId}&serviceName=${serviceName}&GroupId=${GroupId}`);
        return data;
    }

    public async getHeatmapView(serviceid: number): Promise<IViewDetails[]> {
        const { data } = await this.$http.get<IViewDetails[]>(`GetHeatmapView?serviceid=${serviceid}`);
        return data;
    }

    public async GetHeatMapAdminDataList(): Promise<IPropHeatmapAdminData[]> {
        const { data } = await this.$http.get<IPropHeatmapAdminData[]>("GetHeatMap_Admin");
        return data;
    }

    public async HM_SaveIsMonthlyAvailability(isMonthlyAvailability, viewId, updatedby): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateIsMonthlyAvailability?isMonthlyAvailability=${isMonthlyAvailability}&viewId=${viewId}&UpdatedBy=${updatedby}`);
        return data
    }

    public async HM_SaveIsMserView(isMserView, viewId, updatedby): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateIsMserView?isMserView=${isMserView}&viewId=${viewId}&UpdatedBy=${updatedby}`);
        return data
    }

    public async HM_SaveIsMserPresentationView(isMserPresentationView, viewId, updatedby): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateIsMserPresentationView?isMserPresentationView=${isMserPresentationView}&viewId=${viewId}&UpdatedBy=${updatedby}`);
        return data
    }

    public async SaveIsHeatmapTrendView(isHeatmapTrend, viewId, updatedby): Promise<number> {
        const { data } = await this.$http.post<number>(`SaveIsHeatmapTrendView?isHeatmapTrend=${isHeatmapTrend}&viewId=${viewId}&UpdatedBy=${updatedby}`);
        return data
    }

    public async getAllViewDataAsyn(serviceid: number): Promise<IAllViewDetails[]> {
        const { data } = await this.$http.get<IAllViewDetails[]>(`GetHeatmapView?serviceid=${serviceid}`);
        return data;
    }
    public async getColorSchemeAsyn(colorSchemeId: number): Promise<IColorScheme[]> {
        const { data } = await this.$http.get<IColorScheme[]>(`GetColorRule?colorSchemeId=${colorSchemeId}`);
        return data;
    }

    public async AddHeapmapCommentDataAsyn(hmMetric, serviceid: number, viewId: number, dataCenter: string, LoginuserId): Promise<number> {
        const metric = {
            DateKey: hmMetric.dateKey,
            Id: hmMetric.id,
            UpdatedValue1: hmMetric.umodifieduptime,
            UpdatedValue2: hmMetric.uModifiedQos,
            Comment: hmMetric.comment,
            Author: LoginuserId,
            CommentId: hmMetric.commentId,
            Incident: hmMetric.incident,
            LastUpdated: new Date()
        };
        const { data } = await this.$http.post<number>(`AddHeapmapCommentData?serviceId=${serviceid}&dataCenter=${dataCenter}&viewId=${viewId}`, metric);
        return data;
    }

    public async UpdateCommentsForBulkViews(serviceid: number, comment: string, commentID: number, ViewsIds: string, Datacenters: string, author: string): Promise<number> {

        const { data } = await this.$http.post<number>(`UpdateCommentsForBulkViews?serviceId=${serviceid}&comment=${comment}&commentID=${commentID}&viewsIds=${ViewsIds}&datacenters=${Datacenters}&author=${author}`);
        return data;
    }
    public async HeatmapSendEmail(objhmSendEmail): Promise<number> {

        const { data } = await this.$http.post<number>("HeatmapSendEmail",objhmSendEmail);
        return data;
    }
    public async DeleteCommentsForBulkViews(serviceid: number, comment: string, commentID: number, ViewsIds: string, Datacenters: string, author: string): Promise<number> {
        const { data } = await this.$http.post<number>(`DeleteCommentsForBulkViews?serviceId=${serviceid}&viewsIds=${ViewsIds}&datacenters=${Datacenters}&author=${author}`);
        return data;
    }

    public async UpdateHeatmapCommentDataAsyn(hmMetric, LoginuserId): Promise<number> {
        const metric = {
            DateKey: hmMetric.dateKey,
            Id: hmMetric.id,
            UpdatedValue1: hmMetric.umodifieduptime,
            UpdatedValue2: hmMetric.uModifiedQos,
            Comment: hmMetric.comment,
            Author: LoginuserId,
            CommentId: hmMetric.commentId,
            Incident: hmMetric.incident,
            LastUpdated: new Date(),
            Status: hmMetric.status,
            RootCause: hmMetric.rootCause,
            OwningTeam: hmMetric.owningTeam,
            ProblemStatement: hmMetric.problemStatement,
            Impact: hmMetric.impact
        };
        const { data } = await this.$http.post<number>(`UpdateHeapmatCommentData`, metric);
        return data;
    }
    public async HmGetViewDetailsForBulkEdit(serviceid: number, dataCenter: string): Promise<IBulkViewDetails[]> {
        const { data } = await this.$http.get<IBulkViewDetails[]>(`GetBulkEditViewDetails?serviceId=${serviceid}&datacenter=${dataCenter}`);
        return data;
    }
    public async HeatmapIncidentTitleData(hmMetric, LoginuserId): Promise<HmMetric> {
        const metric = {
            DateKey: hmMetric.dateKey,
            Id: hmMetric.id,
            UpdatedValue1: hmMetric.umodifieduptime,
            UpdatedValue2: hmMetric.uModifiedQos,
            Comment: hmMetric.comment,
            Author: LoginuserId,
            CommentId: hmMetric.commentId,
            Incident: hmMetric.incident,
            LastUpdated: new Date(),
            Status: hmMetric.status,
            RootCause: hmMetric.rootCause,
            OwningTeam: hmMetric.owningTeam,
            ProblemStatement: hmMetric.problemStatement,
            Impact: hmMetric.impact
        };
        const { data } = await this.$http.post<HmMetric>(`HeatmapIncidentTitleData`, metric);
        return data;
    }
    public async HeatmapIncidentRelatedData(hmMetric, LoginuserId): Promise<HmMetric> {
        const metric = {
            DateKey: hmMetric.dateKey,
            Id: hmMetric.id,
            UpdatedValue1: hmMetric.umodifieduptime,
            UpdatedValue2: hmMetric.uModifiedQos,
            Comment: hmMetric.comment,
            Author: LoginuserId,
            CommentId: hmMetric.commentId,
            Incident: hmMetric.incident,
            LastUpdated: new Date(),
            Status: hmMetric.status,
            RootCause: hmMetric.rootCause,
            OwningTeam: hmMetric.owningTeam,
            ProblemStatement: hmMetric.problemStatement,
            Impact: hmMetric.impact
        };
        const { data } = await this.$http.post<HmMetric>(`HeatmapIncidentRelatedData`, metric);
        return data;
    }
    public async DeleteHeatmapUpdatedData(hmMetric, LoginuserId): Promise<number> {
        const metric = {
            DateKey: hmMetric.dateKey,
            Id: hmMetric.id,
            UpdatedValue1: hmMetric.umodifieduptime,
            UpdatedValue2: hmMetric.uModifiedQos,
            Comment: hmMetric.comment,
            Author: LoginuserId,
            CommentId: hmMetric.commentId,
            Incident: hmMetric.incident,
            LastUpdated: new Date(),
            Status: hmMetric.status,
            RootCause: hmMetric.rootCause,
            OwningTeam: hmMetric.owningTeam,
            ProblemStatement: hmMetric.problemStatement,
            Impact: hmMetric.impact
        };
        const { data } = await this.$http.post<number>(`DeleteHeatmapUpdatedData`, metric);
        return data;
    }

    public async SaveIsMserView(isMserView: boolean, viewId: string, updatedBy: string): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateIsMserView?viewId=${viewId}&isMserView=${isMserView}&updatedBy=${updatedBy}`);
        return data;
    }

    public async SaveIsMserPresentationView(isMserPresentationView: boolean, viewId: string, updatedBy: string): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateIsMserPresentationView?viewId=${viewId}&isMserPresentationView=${isMserPresentationView}&updatedBy=${updatedBy}`);
        return data;
    }

    public async SaveIsMonthlyAvailability(isMonthlyAvailability: boolean, viewId: string, updatedBy: string): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateIsMonthlyAvailability?viewId=${viewId}&isMonthlyAvailability=${isMonthlyAvailability}&updatedBy=${updatedBy}`);
        return data;
    }

    public async GetHeatmapKustoData(serviceId: number, viewName: string): Promise<IAllViewDetails> {
        const { data } = await this.$http.get<IAllViewDetails>(`GetHeatmapKustoData?serviceId=${serviceId}&viewName=${viewName}`);
        return data;
    }
    public async GetServiceGroups(): Promise<IServiceGroup[]> {
        const { data } = await this.$http.get<IServiceGroup[]>(`GetServiceGroups`);
        return data;
    }
    public async GetColoringLogic(): Promise<IViewConfigColorLogics[]> {
        const { data } = await this.$http.get<IViewConfigColorLogics[]>(`Get_ColoringLogic`);
        return data;
    }
    public async Get_NoOfDecimals(): Promise<IViewConfigNoofDecimals[]> {
        const { data } = await this.$http.get<IViewConfigNoofDecimals[]>(`Get_NoOfDecimals`);
        return data;
    }
    public async GetColorSchemes(): Promise<IViewConfigColorSchemes[]> {
        const { data } = await this.$http.get<IViewConfigColorSchemes[]>(`Get_ColorSchemes`);
        return data;
    }
    public async AddUpdateHmView(view): Promise<number> {
       
        const { data } = await this.$http.post<number>(`AddUpdateHmView`, view);
        return data;
    }
    public async ValidateKustoQuery(kustodata): Promise<string> {

        const { data } = await this.$http.post<string>(`ValidateKustoQuery`, kustodata);
        return data;
    }
    public async getFleetLookUpData(flag: number, tenant: string, role: string, cloud: string): Promise<LookupData[]> {
        const { data } = await this.$http.get<LookupData[]>(`GetTATLookUpData?flag=${flag}&tenant=${encodeURIComponent(tenant)}&role=${encodeURIComponent(role)}&cloud=${cloud}`);
        return data;
    }
    public async GetFleetUtilizationData(fromDate: string, toDate: string, tenant: string, cloud: string, trafficGroup: string, role: string): Promise<PropFleetutilizationHMList> {
        const { data } = await this.$http.get<PropFleetutilizationHMList>(`GetFleetUtilization?fromDate=${fromDate}&toDate=${toDate}&tenant=${encodeURIComponent(tenant)}&cloud=${cloud}&trafficGroup=${trafficGroup}&role=${encodeURIComponent(role)}`);
        return data;
    }
    public async GetFleetUtilizationColourLogic(fromDate: string, toDate: string, tenant: string, cloud: string, trafficGroup: string, role: string): Promise<IFleetcolorSchemes> {
        const { data } = await this.$http.get<IFleetcolorSchemes>(`GetFleetColorLogic?fromDate=${fromDate}&toDate=${toDate}&tenant=${encodeURIComponent(tenant)}&cloud=${cloud}&trafficGroup=${trafficGroup}&role=${encodeURIComponent(role)}`);
        return data;
    }
    public async GetHeatmapServices(): Promise<IServices[]> {
        const { data } = await this.$http.get<IServices[]>(`GetHeatmapServices`);
        return data;
    }
    public async GetHeatMapTrendDataList(): Promise<IPropHeatmapAdminData[]> {
        const { data } = await this.$http.get<IPropHeatmapAdminData[]>("GetHeatMap_Admin");
        return data;
    }
    public async UpdateHeatmapActiveInactive(IsActiveStatus, viewId, updatedby): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateHeatmapActiveInactive?IsActiveStatus=${IsActiveStatus}&viewId=${viewId}&UpdatedBy=${updatedby}`);
        return data
    }

    public async UpdateHeatmapIdentityWeeklyNewsLetter(IsIdentityWeeklyNewsLetter, viewId, selectednewsLetterGroupId, updatedby): Promise<number> {
        const { data } = await this.$http.post<number>(`UpdateHeatmapIdentityWeeklyNewsLetter?IsIdentityWeeklyNewsLetter=${IsIdentityWeeklyNewsLetter}&viewId=${viewId}&selectednewsLetterGroupId=${selectednewsLetterGroupId}&UpdatedBy=${updatedby}`);
        return data
    }

    public async HideHeatmap(isActiveStatus: boolean, viewId: string, updatedBy: string): Promise<number> {
        const { data } = await this.$http.post<number>(`HideHeatmapStatus?viewId=${viewId}&isActiveStatus=${isActiveStatus}&updatedBy=${updatedBy}`);
        return data;
    }

}

export const DailyDataApi = DailyService.Instance;
