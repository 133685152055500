import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, ICheckListTemplateState } from './types';

const initialState = Object.freeze<ICheckListTemplateState>({
    isLoading: false,
    checklisttemplatedata: [],
    showtemplatePopup: false,
    template: null,
    questioncontroltypeoptions:[],
    sourcetypes: [],
    templateTypes: [],
    templateRoles: [],
    AuthenticationTypes: [],
    question: null,
    disableaddbuttonforquestion: true,
    ShowQuestionList: false,
    QuestionarrieList: [],
    istemplatesavebuttondisabled: true,
    LoginuserId: '',
    templateNameList: [],
    copiedTemplateQues: null,
    isCopyTemplateQuestion: false,
    copyTempQuesSelect: false,
    dynamicValidationTypes: [],
});

export const reducer = (
    state: ICheckListTemplateState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {   
        case ActionType.FETCH_CHECKLIST_TEMPLATE_DATA:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.LOGIN_USERNAME_TEMPLATE:
            return {
                ...state,
                LoginuserId: action.LoginuserId
            };
        case ActionType.CHECKLIST_TEMPLATE_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                templateNameList: action.templateNameList,
                checklisttemplatedata: action.checklisttemplatedata
            };
        case ActionType.CHECKLIST_TEMPLATE_DATA_FAIL:
            return {
                ...state,
                isLoading: false,
                checklisttemplatedata: []
            };
        case ActionType.OPEN_TEMPLATE_POPUP:
            return {
                ...state,                
                showtemplatePopup: true,
                template: action.template,
                questioncontroltypeoptions: action.questioncontroltypeoptions,
                sourcetypes: action.sourcetypes,
                dynamicValidationTypes: action.dynamicValidationTypes,
                templateTypes: action.templateTypes,
                AuthenticationTypes: action.AuthenticationTypes,
                question: action.question,
                disableaddbuttonforquestion: true,
                ShowQuestionList: false,
                QuestionarrieList: [],
                istemplatesavebuttondisabled: true,
                copiedTemplateQues: '',
                isCopyTemplateQuestion: false,
                copyTempQuesSelect: action.copyTempQuesSelect,
                templateRoles: action.templateRoles
            };
        case ActionType.TEMPLATE_DATA_CHANGFE:
            return {
                ...state,   
                istemplatesavebuttondisabled: action.istemplatesavebuttondisabled,
                template: action.template
            };
        case ActionType.SHOWORHIDE_TEMPLATE_POPUP:
            return {
                ...state,
                showtemplatePopup: action.showtemplatePopup,

            }; 
        case ActionType.QUESTION_CONTROLS_CHANGFE:
            return {
                ...state,
                question: action.question,
                disableaddbuttonforquestion: action.disableaddbuttonforquestion

            }; 
        case ActionType.ADD_QUESTIONS_TOLIST:
            return {
                ...state,
                istemplatesavebuttondisabled: action.istemplatesavebuttondisabled,
                QuestionarrieList: action.QuestionarrieList,
                ShowQuestionList:true
               
            }; 
        case ActionType.ENABLE_ADD_NEW_QUESTION:
            return {
                ...state,
                question: action.question,                
                ShowQuestionList: false

            }; 
        case ActionType.SHOW_QUESTIONLIST:
            return {
                ...state,               
                ShowQuestionList: true

            }; 
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true,

            };
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false,

            };
        case ActionType.TEMPLATE_EDIT:
            return {
                ...state,
                isLoading: false,
                QuestionarrieList: action.QuestionarrieList,
                template: action.template,
                istemplatesavebuttondisabled: action.istemplatesavebuttondisabled,
                ShowQuestionList: true,
                showtemplatePopup: true,
                questioncontroltypeoptions: action.questioncontroltypeoptions,
                sourcetypes: action.sourcetypes,
                dynamicValidationTypes: action.dynamicValidationTypes,
                templateTypes: action.templateTypes,
                AuthenticationTypes: action.AuthenticationTypes,
                question: action.question,
                templateRoles: action.templateRoles
            };
        case ActionType.COPY_TEMPLATE_SELECT:
            return {
                ...state,
                copiedTemplateQues: action.copiedTemplateQues
            };
        case ActionType.IS_COPY_TEMP_CHECKVAL:
            return {
                ...state,
                copyTempQuesSelect: action.copyTempQuesSelect,
                isCopyTemplateQuestion: action.isCopyTemplateQuestion
            };
        case ActionType.COPY_QUESTION_TEMPLATE:
            return {
                ...state,
                isLoading: false,
                QuestionarrieList: action.QuestionarrieList,
                questioncontroltypeoptions: action.questioncontroltypeoptions,
                sourcetypes: action.sourcetypes,
                dynamicValidationTypes: action.dynamicValidationTypes,
                ShowQuestionList: true,
                templateTypes: action.templateTypes,
                AuthenticationTypes: action.AuthenticationTypes,
                templateRoles: action.templateRoles
            };
        case ActionType.COPY_QUESTION_TEMPLATE_FAILURE:
            return {
                ...state,
                isLoading: false,
                QuestionarrieList: action.QuestionarrieList,
                questioncontroltypeoptions: action.questioncontroltypeoptions,
                sourcetypes: action.sourcetypes,
                dynamicValidationTypes: action.dynamicValidationTypes,
                ShowQuestionList: false,
                templateTypes: action.templateTypes,
                AuthenticationTypes: action.AuthenticationTypes,
                templateRoles: action.templateRoles
            };
        case ActionType.CHECKLIST_GETTEMPLATE_ROLES_SUCCESS:
            return {
                ...state,
                templateRoles: action.templateRoles
            };
        case ActionType.CHECKLIST_GETTEMPLATE_ROLES_FAILURE:
            return {
                ...state,
                templateRoles: action.templateRoles
            }
        case ActionType.RESET_STATE:
            return initialState;

        case ActionType.MOVECHECKLISTTEMPLATETOARCHIVE:
            return {
                ...state,
                isLoading: true
            };        
        case ActionType.MOVECHECKLISTTEMPLATETOARCHIVE_FAIL:
            return {
                ...state,
                isLoading: false
            };
        default:
            return state;
    }
};
