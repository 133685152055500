
export type IPropEscalationDirectoryData = {
    readonly id: number;
    readonly title: string;
    readonly description: string;
    readonly escalationPath: string;
    readonly contactPerson: string;
    readonly remarks: string;
    readonly link: string;
    readonly link2: string;
    readonly link3: string;
    readonly cloud: string;
    readonly service: string;
    readonly isActive: number;
    readonly updatedBy: string;
    readonly updatedDate: Date;
};

export type IEscalationDirectoryState = {
    readonly escalationDirectoryData: IPropEscalationDirectoryData[];
    readonly escalationDirectoryDataFiltered: IPropEscalationDirectoryData[];
    readonly isLoading: boolean;
    readonly searchText: string;
    readonly isactive: string;
    readonly sortcolumn: string;
    readonly reverseSort: boolean;
};

const _namespace = 'EscalationDirectory';
export interface IActionType {
    readonly RESET_STATE: string;
    readonly GET_ESCALATIONDIRECTORY_REQUEST: string;
    readonly GET_ESCALATIONDIRECTORY_SUCCESS: string;
    readonly GET_ESCALATIONDIRECTORY_FAILURE: string;
    readonly SET_LOADING_TRUE: string;
    readonly SET_LOADING_FALSE: string;
    readonly CHANGE_SEARCHTEXT_ESCALATIONDIRECTORY: string;
    readonly CHANGE_SORTING_ESCALATIONDIRECTORY: string;
    readonly ADDED_VISITED_USER: string;
}

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/escalationdirectoryreset`,
    GET_ESCALATIONDIRECTORY_REQUEST: `${_namespace}/getescalationdirectoryrequest`,
    GET_ESCALATIONDIRECTORY_SUCCESS: `${_namespace}/getescalationdirectorysuccess`,
    GET_ESCALATIONDIRECTORY_FAILURE: `${_namespace}/getescalationdirectoryfailure`,
    SET_LOADING_TRUE: `${_namespace}/loadingtrue`,
    SET_LOADING_FALSE: `${_namespace}/loadingfalse`,
    CHANGE_SEARCHTEXT_ESCALATIONDIRECTORY: `${_namespace}/searchtextUpdate`,
    CHANGE_SORTING_ESCALATIONDIRECTORY: `${_namespace}/sortingChange`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`
});

