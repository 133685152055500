import { IServiceGroup } from '../home-tiles';
export interface IMSERHeatmapTrendReport {
    readonly ServiceId: number;
    readonly serviceName: string;
    readonly viewName: string;
    readonly targetName: string;
    readonly viewId: string;
    readonly spanKpi?: number;
    readonly spanMetric?: number;
    readonly kpiIndex?: number;
    readonly liKPIValues: Record<string, object>;
    readonly LiUpdatedKPIs: Record<string, boolean>;
    serviceId: number;
    MonthStartDate: string;
}

export interface IActionType {
    readonly RESET_STATE: string;
    readonly ADDED_VISITED_USER: string;  
    readonly MSER_HEATMAP_TREND_START_DATE_CHANGE: string;
    readonly MSER_HEATMAP_TRENDDATA_FETCH: string;
    readonly MSER_HEATMAP_TRENDDATA_RECEIVE: string;
    readonly GET_SERVICEGROUPS_SUCCESS: string;
    readonly GET_SERVICEGROUPS_FAIL: string;
    readonly GET_SERVICELISTS_SUCCESS: string;
    readonly GET_SERVICELISTS_FAIL: string;
    readonly SERVICE_GROUPVAL_CHANGE: string;
    readonly SERVICE_VAL_CHANGE: string;
    readonly SERVICE_IDENTITY_CLASIFICATION_CHANGE: string;
    readonly SEARCH_TEXTBOX_CHANGE: string;
    readonly CLEAR_FILTERS: string;
    readonly SET_LOADING_TRUE: string;
}
export type IMSERHeatmapTrendState = {
    readonly isLoading: boolean;  
    mserHeatmapTrendStartDate: Date;
    readonly mserHeatmapTrendData: IMSERHeatmapTrendReport[];
    readonly MSERHeatmapTrendheaderArr: [];
    readonly MSERHeatmapTrendKPiArr: [];
    IsMSERHeatmapTrendDataLoaded: boolean;
    liKPIValueKeys: [];
    readonly uniquev: [];
    readonly uniquec: [];
    selectedidentityServiceClassification: string;
    serviceGroups: IServiceGroup[];
    services: IServiceGroup[];
    servicegroupval: [];
    serviceval: [];
    selectedidentity_ServiceClassification: [];
    globalsearch: string;
    identityServiceClassification: [];
    servicegroupvalstring: string;
    servicevalstring: string;
    readonly isDataloaded: boolean; 
};

const _namespace = 'MSERHeatmapTrend';

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/reset`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    MSER_HEATMAP_TREND_START_DATE_CHANGE: `${_namespace}/mserHeatmapTrendstartDateChange`,
    MSER_HEATMAP_TRENDDATA_RECEIVE: `${_namespace}/mserHeatmapTrenddatareceive`,
    MSER_HEATMAP_TRENDDATA_FETCH: `${_namespace}/mserHeatmapTrenddatafetech`,
    GET_SERVICEGROUPS_SUCCESS: `${_namespace}/getservicegroupsuccess`,
    GET_SERVICEGROUPS_FAIL: `${_namespace}/getservicegroupfail`,
    GET_SERVICELISTS_SUCCESS: `${_namespace}/getservicelistsuccess`,
    GET_SERVICELISTS_FAIL: `${_namespace}/getservicefail`,
    SERVICE_GROUPVAL_CHANGE: `${_namespace}/servicegroupvalchange`,
    SERVICE_VAL_CHANGE: `${_namespace}/servicevalchange`,
    SERVICE_IDENTITY_CLASIFICATION_CHANGE: `${_namespace}/serviceidentityclassificationchange`,
    SEARCH_TEXTBOX_CHANGE: `${_namespace}/searchtextboxchange`,
    CLEAR_FILTERS: `${_namespace}/clearfilters`,
    SET_LOADING_TRUE: `${_namespace}/loadingtrue`,
});

