import React, { useEffect, useState, useRef } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { RouteComponentProps } from 'react-router-dom';
import { actionCreators, reducer, IPropLastWeekIdNewsLetterData, IPropIdentityTopOutages } from '../../store/LastWeekIdNewsLetter';
import { LastWeekIdNewsLetterTable } from '../LastWeekIdNewsLetter/child-components';
import { LastWeekIdNewsLetterAPI } from '../../api';
import { isArrayWithLength, renderToastifyMsg } from '../../utils';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { ToastId, toast } from 'react-toastify';
import moment from 'moment';
import {
    Row,
    Col, Card, CardText
} from 'reactstrap';
import { DeprecatedFunctionalityBanner } from '../../components';

type LastWeekIdNewsLetterProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly username: string }
    & { readonly isAdmin: boolean }
    & RouteComponentProps<{}>;

const LastWeekIdNewsLetter: React.FC<LastWeekIdNewsLetterProps> = ({
    isLoading,
    addUserVisit,
    isAuthenticated,
    WeekStartDate,
    GetWeekStartDate,
    appSessionToken,
    DateRange,
    newsLettertopOutagesDataTable,
    GetIdentityNewsLetterData,
    TopOutageDataEditCancel,
    newsLettertopOutagesData,
    UpdatetopOutagedata,
    setloadingtrue,
    username,
    setloadingfalse,
    TopOutageDataRefresh,
    NewsLetterTilesData,
    NewsLetterTilesRawData,
    NewsLetterTilesCount,
    OoslaPIRs,
    OoslaSTRI,
    titleInputChange,
    SaveTileData,
    clearTilesEditData,
    PirCompletionData,
    pirHTMLContent,
    SendMailLastWeekIdNewsletterDashboard,
    repairItemshtmlContent,
    STRepairItemsData,
    isAdmin,
    lastWeekOnCallData
}) => {
    const toastIdRef = useRef<ToastId>('');
    useEffect(() => {
        if (WeekStartDate === null) {
            GetWeekStartDate(null);
        }
        else {
            GetIdentityNewsLetterData(4, moment(WeekStartDate).format('MM/DD/YYYY'));
        }
    }, [WeekStartDate]);

    useEffect(() => {
        if (isAuthenticated)
            addUserVisit(0, 'LastWeekIdNewsLetter.tsx_v2');
    }, [isAuthenticated]);

    const rtrim = (str) => {
        if (!str) return str;
        let repstring = "<p><span style=" + '"' + "color: rgb(51, 51, 51);" + '"' + "><span class=" + '"' + "ql-cursor" + '"' + ">﻿</span></span></p>";
        var incidentNoteFormated = str.replaceAll(repstring, '');

        return incidentNoteFormated;
    }

    const IdentitynewsletterTopOutagesDataSave = () => {
        //=============function to Save the  values for Identity Newsletter TopOutages====================
        setloadingtrue();
        let LoginuserId = username;
        let obj = newsLettertopOutagesData;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
        }
        var objTopOutage: IPropIdentityTopOutages = {
            topOutageID: obj.topOutageID,
            numberOfSev1: obj.numberOfSev1,
            numberOfSev2: obj.numberOfSev2,
            numberOfUniqueCCL: obj.numberOfUniqueCCL,
            weekDate: moment(WeekStartDate).format('MM/DD/YYYY'),
            weekId: obj.weekId,
            sev1Customer: rtrim(obj.sev1Customer),
            sev2Customer: rtrim(obj.sev2Customer),
            outageswithExternal: rtrim(obj.outageswithExternal),
            incidentNotes: rtrim(obj.incidentNotes),
            notes: obj.notes,
            isActive: obj.isActive == 1 ? true : false,
            updatedBy: LoginuserId,
            createdBy: obj.createdBy,
            createdDate: obj.createdDate,
            updatedDate: obj.updatedDate,
            topOutageTable: rtrim(obj.topOutageTable),
            oncallTeamTable: rtrim(obj.oncallTeamTable),
            topOutageOpen: rtrim(obj.topOutageOpen),
            pirClosed: rtrim(obj.pirClosed)
        };
        LastWeekIdNewsLetterAPI.SaveTopOutagesData(objTopOutage)
            .then((result: number) => {
                if (result != -1) {
                    toastIdRef.current = toast.success(
                        renderToastifyMsg("Identity Newsletter Top Outage Data Saved Successfully",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                    // Reload only Top Outages Data
                    GetIdentityNewsLetterData(4, moment(WeekStartDate).format('MM/DD/YYYY'));
                    setloadingfalse();
                }
                else {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("No Change in Data or Error While Saving the Data, Please contact Admin!",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                }
            }).catch(error => {
                setloadingfalse();
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Error while updating",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            });
    }

    const RefreshTopOutagesData = () => {
        setloadingtrue();
        let TopOutageID = newsLettertopOutagesData.topOutageID;
        if (TopOutageID !== null || TopOutageID !== undefined || TopOutageID !== "") {
            LastWeekIdNewsLetterAPI.RefreshTopOutageData(TopOutageID, username)
                .then((result: number) => {
                    if (result != -1) {
                        // Reload only Top Outages Data
                        GetIdentityNewsLetterData(4, moment(WeekStartDate).format('MM/DD/YYYY'));                    
                        toastIdRef.current = toast.success(
                            renderToastifyMsg("Data has been Refreshed Successfully",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );      
                        //setloadingfalse();
                    }
                    else {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Refresh TopOutage Data Failed. Please Try Again.",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while Refreshing the Kusto Data",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }
        else {
            toast.error(renderToastifyMsg('No TopOutage Data is Present !!',
                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
            setloadingfalse();
        }
    }
    return (
        <div>
            <DeprecatedFunctionalityBanner />
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        <LastWeekIdNewsLetterTable
                            WeekStartDate={WeekStartDate}
                            GetWeekStartDate={GetWeekStartDate}
                            appSessionToken={appSessionToken}
                            DateRange={DateRange}
                            newsLettertopOutagesDataTable={newsLettertopOutagesDataTable}
                            IdentitynewsletterTopOutagesDataSave={IdentitynewsletterTopOutagesDataSave}
                            TopOutageDataEditCancel={TopOutageDataEditCancel}
                            newsLettertopOutagesData={newsLettertopOutagesData}
                            UpdatetopOutagedata={UpdatetopOutagedata}
                            RefreshTopOutagesData={RefreshTopOutagesData}
                            NewsLetterTilesData={NewsLetterTilesData}
                            NewsLetterTilesRawData={NewsLetterTilesRawData}
                            NewsLetterTilesCount={NewsLetterTilesCount}
                            OoslaPIRs={OoslaPIRs}
                            OoslaSTRI={OoslaSTRI}
                            titleInputChange={(key, section, value) => titleInputChange(key, section, value)}
                            SaveTileData={SaveTileData}
                            clearTilesEditData={clearTilesEditData}
                            PirCompletionData={PirCompletionData}
                            pirHTMLContent={pirHTMLContent}
                            SendMailLastWeekIdNewsletterDashboard={SendMailLastWeekIdNewsletterDashboard}
                            repairItemshtmlContent={repairItemshtmlContent}
                            STRepairItemsData={STRepairItemsData}
                            isAdmin={isAdmin}
                            lastWeekOnCallData={lastWeekOnCallData}
                        />
                    </div>
                </section>
            </LoadingOverlay>

        </div>
    );
};
const mapStateToProps = (state: IApplicationState) => ({
    ...state.LastWeekIdNewsLetter,
    isAuthenticated: state.authUser.isAuthenticated,
    username: state.authUser.login,
    isAdmin: state.authUser.isAdmin
})

export default connect(mapStateToProps, actionCreators)(LastWeekIdNewsLetter);
