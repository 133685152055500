export type IPIRRiskToolState = {
    readonly isLoading: boolean;
    nodeData: INodeType[];
    gemServiceMappingData: IGemServiceClassificationData[];
    checkedServices: [];
    workItemID: any;
    readonly titleValue: string;
    readonly IterationPathValue: string;
    readonly workItemTypeValue: string;
    readonly tagsValue: string;
    readonly stateValue: string;
    readonly includeLinks: boolean;
    readonly includeattachments: boolean;
    readonly relations: relationsModel[];
    readonly isLinksActive: boolean;
    readonly isAttachmentActive: boolean;
    readonly errorMsg: string;
    readonly errorMsgList: IErrorMsg[];
    readonly descriptionValue: string;
    readonly IDClassificationList: [];
    readonly IDClassificationvalue: '';
    readonly customFields: fieldsModel[];
};

export type IGemServiceClassificationData = {
    gem: string,
    serviceName: string,
    teamGroupName: string,
    areaPath: string,
    gemEmail: string,
    classification: string,
    directorName: string
};

export type INodeType = {
    value: string,
    label: string,
    checked: boolean,
    classification: string,
    children?: [{
        value: string,
        label: string,
        checked: boolean,
        classification: string,
        children?: [{
            value: string,
            label: string,
            checked: boolean,
            classification: string,
        }]
    }]
}

export type IErrorMsg = {
    ServiceName: string;
    ErrorMessage: string;
    Status: string;
}

export type WorkItemInfo = {
    readonly title: string;
    readonly iterationPath: string;
    readonly workItemType: string;
    readonly tags: string;
    readonly state: string;
    readonly relations: relationsModel[];
    readonly customFields: fieldsModel[];
}

export type relationsModel = {
    rel: string;
    url: string;
    attributes: attributes[];
}

export type fieldsModel = {
    icMIncidentCount: number;
    icMIncidentIDs: number;
    icMRepairItemType: string;
    icMDeliveryType: string;
    icMIncidentSeverity: string;
}

export type attributes = {
    authorizedDate: string;
    resourceCreatedDate: string;
    resourceModifiedDate: string;
    id: number;
    revisedDate: string;
    resourceSize: string;
    isLocked: string;
}


const _namespace = 'PIRRiskTool';
export interface IActionType {
    readonly RESET_STATE: string;
    readonly ADDED_VISITED_USER: string;
    readonly SET_NODEDATA_VALUES: string;
    readonly GET_GEMSERVICE_SUCCESS: string;
    readonly GET_GEMSERVICE_FAILURE: string;
    readonly SET_CHECKED_VALUES: string;
    readonly SET_WORKITEMID_VALUE: string;
    readonly GET_WORKITEM_DETAILS: string;
    readonly SET_INCLUDE_LINKS: string;
    readonly SET_INCLUDE_ATTACHMENTS: string;
    readonly SET_LOADING_TRUE: string;
    readonly SET_LOADING_FALSE: string;
    readonly CREATE_ADOWORKITEM_FAILED: string;
    readonly CREATE_ADOWORKITEM_SUCCESS: string;
    readonly CLEAR_ADOWORKITEM_FIELDS: string;
    readonly FETCH_IDCLASSIFICATION_SUCCESS: string;
    readonly FETCH_IDCLASSIFICATION_FAIL: string;
    readonly IDCLASSIFICATION_CHANGE: string;
}

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/resetState`,
    SET_LOADING_TRUE: `${_namespace}/loadingtrue`,
    SET_LOADING_FALSE: `${_namespace}/loadingfalse`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    SET_NODEDATA_VALUES: `${_namespace}/setnodedatavalues`,
    GET_GEMSERVICE_SUCCESS: `${_namespace}getgemservicesuccess`,
    GET_GEMSERVICE_FAILURE: `${_namespace}getgemservicefailure`,
    SET_CHECKED_VALUES: `${_namespace}/setcheckedvalues`,
    SET_WORKITEMID_VALUE: `${_namespace}/setworkitenidvalue`,
    GET_WORKITEM_DETAILS: `${_namespace}/getworkitemdetails`,
    SET_INCLUDE_LINKS: `${_namespace}/setincludelinks`,
    SET_INCLUDE_ATTACHMENTS: `${_namespace}/setincludeattachments`,
    CREATE_ADOWORKITEM_FAILED: `${_namespace}/createadoworkitemfailed`,
    CREATE_ADOWORKITEM_SUCCESS: `${_namespace}/createadoworkitemsuccess`,
    CLEAR_ADOWORKITEM_FIELDS: `${_namespace}/clearadoworkitemfields`,
    FETCH_IDCLASSIFICATION_FAIL: `${_namespace}/fetchidclassificationfail`,
    FETCH_IDCLASSIFICATION_SUCCESS: `${_namespace}/fetchidclassificationsuccess`,
    IDCLASSIFICATION_CHANGE: `${_namespace}/idclassificationchange`
});

