import React, { useEffect } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { Spinner } from '../../components';
import { IApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import { actionCreators, reducer } from '../../store/availability';
import { AvailabilitySummaryTable } from '../Availability/child-components';

type AvailabilitySummaryProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly history: History }
    & typeof useParams;

const AvailabilitySummary: React.FC<AvailabilitySummaryProps> = ({
    availabilitySummary,
    requestAvailabilitySummary,
    isLoading,
    addUserVisit,
    isAuthenticated

}) => {
    useEffect(() => {
        if (isAuthenticated)
        addUserVisit(0, 'Availbilitysummary.tsx_v2');
    }, [isAuthenticated]);
    useEffect(() => {
        requestAvailabilitySummary();
    }, [requestAvailabilitySummary]);

    return (
        <section className='section'>
            <Spinner isLoading={isLoading} />
            <div className='container'>
                <AvailabilitySummaryTable
                    availability={availabilitySummary}
                />
            </div>
        </section>
    );
};
const mapStateToProps = (state: IApplicationState) => ({
    ...state.availability,
    isAuthenticated: state.authUser.isAuthenticated

})

export default connect(mapStateToProps, actionCreators)(AvailabilitySummary);
