export interface IActionType {
    readonly REQUEST: string;
    readonly RECEIVE: string;
    readonly RESET_STATE: string;
    readonly DATA_REFRESHED: string;
    readonly TOGGLE_CHANGE: string;
    readonly ADDED_VISITED_USER: string;
}

export type IServiceGroup = {
    readonly directorName: string;
    readonly teamGroupName: string;
    readonly groupName: string;
    readonly groupId: string;
    readonly serviceName: string;
    readonly serviceId: string;
    readonly ringValue: string;
    readonly ringId: string;
    readonly isActive: boolean;
    readonly isDSE: string;
    readonly isHeatMap: boolean;
    readonly isDailyReport: string;
    readonly isWeeklyReport: string;
    readonly parentReachabilityId: string;
    readonly reachabilityAvailable: string;
    readonly colValue: string;
    IsDirectorChecked: boolean;
    IsTeamGroupChecked: boolean;
    IServiceGroupChecked: boolean;
    IsToggle: boolean;
    enableservicescount: number;
    readonly backgroundColor: string;

};

export type IPropCalender = {
    readonly Year: string;
    readonly Month: string;
    readonly WeekId: string;
    readonly WeekDisplayName: string;
    readonly WeekStartDate: string;
    readonly WeekEndDate: boolean;
    readonly WeekDetail: string;
    readonly displayDateAvailableData: string;
    readonly ConfigName: string;
    readonly ConfigValue: string;
};

export type IServiceGroupState = {
   // readonly DataRefreshed: IPropCalender[];
    readonly isLoading: boolean;
    readonly startGroupIndex?: number;
    readonly serviceGroup: IServiceGroup[];
};

const _namespace = 'servicegroup';

export const ActionType = Object.freeze<IActionType>({
    REQUEST: `${_namespace}/fetch`,
    RECEIVE: `${_namespace}/receive`,
    RESET_STATE: `${_namespace}/resetState`,
    DATA_REFRESHED: `${_namespace}/daterefreshed`,
    TOGGLE_CHANGE: `${_namespace}/togglechange`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`
});

