import { BaseService } from './base.service';
import { IPropPlatformPMTools } from '../store/PlatformTools';

/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class PlatformToolService extends BaseService {
    private static _serviceGroupService: PlatformToolService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): PlatformToolService {
        return (this._serviceGroupService || (this._serviceGroupService = new this('PlatformTool')));
    }

    public async GetPlatformPMToolsData(): Promise<IPropPlatformPMTools[]> {
        const { data } = await this.$http.get<IPropPlatformPMTools[]>("GetPlatformPMToolsData");
        return data;
    }

}

export const PlatformToolAPI = PlatformToolService.Instance;
