import React from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'

type GEMTreeProps = {
    nodeData: any,     
    UpdateNodeData: any,
    UpdateSelectAll: any
};
const GEMTree = React.memo<GEMTreeProps>(({
    nodeData,    
    UpdateNodeData,
    UpdateSelectAll
}) => {
    const onChange = (currentNode, selectedNodes) => {
        var data = selectedNodes;                
        if (currentNode.value == 'SelectAll' || (selectedNodes.length == 1 && selectedNodes[0].value == 'SelectAll')) {
            UpdateSelectAll(currentNode.checked)
        }
        else {
            UpdateNodeData(data)
        }        
    }

    return (
        <div>
            <DropdownTreeSelect className="dropdownTree" data={nodeData} onChange={onChange} />
        </div>
    )
});

GEMTree.displayName = 'GEMTree';
export default GEMTree;