export type IADOTaskCreationState = {
    readonly isLoading: boolean;
    id: string;
    eta: Date;
    description: any;
    title: string;
    assignedTo: string;
    areaPath: string;
    approvalTasksDetails: IADOApprovalTaskDetails[];
    approvalDisabled: boolean;
    previousTitle: string;
    errorMsg: string;
    tags: string;
    tagsValue: any;
    areaPathList: any;
    areaPathDropdown: IDropdownOption[];
    areaPathSelected: string;
}

export type IADOApprovalTaskDetails = {
    id: any;
    assigendTo: any;
    createdBy: any;
    createdDate: any;
    isApproved: any;
    isEmailSent: any;
    title: any;
    areaPath: any;
    description: any;
    taskId: any;
    isSelected: any;
    emailcontent: any;
    tags: any;
}

export type IADOAreaPaths = {
    areaPathId: number;
    areaPathShortName: string;
    areaPathFull: string;
    isActive: boolean;
}

export type IDropdownOption = {
    value: number;
    label: string;
};

const _namespace = 'ADOTaskCreation';

export interface IActionType {
    readonly RESET_STATE: string;
    readonly SET_LOADING_TRUE: string;
    readonly SET_LOADING_FALSE: string;
    readonly SET_AREAPATH: string; 
    readonly SET_ASSIGNEDTO: string; 
    readonly SET_TITLE: string; 
    readonly SET_DESCRIPTION: string; 
    readonly SET_ETA: string;
    readonly ADDED_VISITED_USER: string;
    readonly GET_APPROVALTASKSDETAILS: string;
    readonly SET_CHECKALL: string;
    readonly UPDATE_CHECKVALUE: string;
    readonly CREATE_ADOTASK: string;
    readonly SET_TAGS: string;
    readonly GET_ADOTAGS_SUCCESS: string;
    readonly ADO_TASKCREATION_FAILURE: string;
    readonly GET_AREAPATH_SUCCESS: string;
}

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}adotaskcreationreset`, 
    SET_LOADING_TRUE: `${_namespace}setloadingtrue`, 
    SET_LOADING_FALSE: `${_namespace}setloadingfalse`, 
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    SET_AREAPATH: `${_namespace}/setareapath`,
    SET_ASSIGNEDTO: `${_namespace}/setassignedto`,
    SET_TITLE: `${_namespace}/settitle`,
    SET_DESCRIPTION: `${_namespace}/setdescription`,
    SET_ETA: `${_namespace}/seteta`,
    GET_APPROVALTASKSDETAILS: `${_namespace}/getapprovaltasksdetails`,
    SET_CHECKALL: `${_namespace}/setcheckall`,
    UPDATE_CHECKVALUE: `${_namespace}/updatecheckvalue`,
    CREATE_ADOTASK: `${_namespace}/createadotask`,
    SET_TAGS: `${_namespace}/settags`,
    GET_ADOTAGS_SUCCESS: `${_namespace}/getadotags`,
    ADO_TASKCREATION_FAILURE: `${_namespace}/adotaskcreationfailure`,
    GET_AREAPATH_SUCCESS: `${_namespace}/getareapath`
});