import { IServiceGroup } from '../home-tiles';
import {
    IAllViewDetails, IDailyData, UniqueDateHeaders, UniqueFixedHeaderColumns, IMSERAdditionalInfo, IViewDetails,
    IComments, IColorScheme
} from '../service-health/types';
export interface IActionType {
    readonly RESET_STATE: string;
    readonly ADDED_VISITED_USER: string;
    readonly GET_MSER_HEATMAP_SERVICES_REQUEST: string;
    readonly GET_MSER_HEATMAP_SERVICES_SUCCESS: string;
    readonly GET_MSER_HEATMAP_SERVICES_FAIL: string;
    readonly GET_MSER_HEATMAPPRESENTATION_VIEWS_SUCCESS: string;
    readonly GET_MSER_HEATMAP_VIEWS_FAIL: string;
    readonly START_DATE_CHANGE: string;
    readonly END_DATE_CHANGE: string;
    readonly CHECK_PRESENTATIONVIEW_CHECK: string;
    readonly MSER_HEATMAPDATA_PRESENTATION_FETCH: string;
    readonly MSER_HEATMAP_PRESENTATION_DATA_RECEIVE: string;
    readonly MSER_HEATMAPDATA_FAIL: string;
    readonly DAILY_FILTER_YELLOW: string;
    readonly DAILY_FILTER_RED: string;
    readonly SET_MSERCOMMENTS: string;
    readonly SET_LOADING: string;
    readonly HEATMAP_ONBOARD_FETCH: string;
    readonly HEATMAP_ONBOARD_SUCCESS: string;
    readonly HEATMAP_MSER_ONBOARD_FETCH: string;
    readonly HEATMAP_MSER_ONBOARD_SUCCESS: string;
    readonly DASHBOARD_POPUP_CLOSE: string;
}



export type IMSERPresentationviewState = {
    readonly isLoading: boolean; 
    readonly MSERPresentationViews: IAllViewDetails[];
    readonly currentMSERPresentationVIEW: IAllViewDetails; 
    readonly currentindex: number;
    readonly lastindex: number;
    readonly startDate: Date;
    readonly endDate: Date; 
    readonly HeatmapPresentationData: IDailyData[];
    readonly PresentationuniqueDateHeaders: UniqueDateHeaders[];
    readonly uniqueFixedHeaderColumns: UniqueFixedHeaderColumns[];
    readonly datacentersList: [];
    readonly additionalInfoData: IMSERAdditionalInfo[];
    Presentationviewdetailsdata: IViewDetails[];
    readonly PresentationSelectedView: IAllViewDetails[];
    readonly Presentationcomments: IComments[];
    readonly PresentationcolorScheme: IColorScheme[];
    readonly IsdataLoaded: boolean;
    readonly volumeMeasure: string;
    readonly failurevolumeMeasure: string;
    showMSERComments: boolean;
    checkedFilterYellowDailyData: boolean;
    checkedFilterRedDailyData: boolean;
    IsPresentationviewsloaded: boolean;
    readonly HeatMapOnboarded: IPropService[];
    readonly HeatMapNotOnboarded: IPropService[];
    readonly MSEROnboarded: IPropService[];
    readonly MSERNotOnboarded: IPropService[];
    readonly showdashboradpopup: boolean;
    readonly dcMaxLength: number;
};
export type IPropOnBoardedHMService = {
    readonly objHeatMapOnboarded: IPropService[];
    readonly objHeatMapNotOnboarded: IPropService[];
};
export type IPropOnBoardedMSERService = {
    readonly objMSEROnboarded: IPropService[];
    readonly objMSERNotOnboarded: IPropService[];
};
export type IPropService = {
    readonly serviceName: string;
    readonly serviceID: number;
}

const _namespace = 'MSERHeatmapPreview ';

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/reset`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    GET_MSER_HEATMAP_SERVICES_REQUEST: `${_namespace}/getmserheatmapservicesrequest`,
    GET_MSER_HEATMAP_SERVICES_SUCCESS: `${_namespace}/getmserheatmapservicessuccess`,
    GET_MSER_HEATMAP_SERVICES_FAIL: `${_namespace}/getmserheatmapservicesfail`,
    GET_MSER_HEATMAPPRESENTATION_VIEWS_SUCCESS: `${_namespace}/getmserheatmapPRESENTATIONviewssuccess`,
    GET_MSER_HEATMAP_VIEWS_FAIL: `${_namespace}/getmserheatmapviewsfail`,
    START_DATE_CHANGE: `${_namespace}/startDateChange`,
    END_DATE_CHANGE: `${_namespace}/endDateChange`,
    CHECK_PRESENTATIONVIEW_CHECK: `${_namespace}/checkpresentationviewcheck`,
    MSER_HEATMAPDATA_PRESENTATION_FETCH: `${_namespace}/mserheatmapdatafetch`,
    MSER_HEATMAP_PRESENTATION_DATA_RECEIVE: `${_namespace}/mserheatmapdatareceive`,
    MSER_HEATMAPDATA_FAIL: `${_namespace}/mserheatmapdatafail`,
    DAILY_FILTER_YELLOW: `${_namespace}/dailyfilteryellow`,
    DAILY_FILTER_RED: `${_namespace}/dailyfilterred`,
    SET_MSERCOMMENTS: `${_namespace}/sermsercomments`,
    SET_LOADING: `${_namespace}/setloading`,
    HEATMAP_ONBOARD_FETCH: `${_namespace}/heatmaponboardfetch`,
    HEATMAP_ONBOARD_SUCCESS: `${_namespace}/heatmaponboardsuceess`,
    HEATMAP_MSER_ONBOARD_FETCH: `${_namespace}/heatmapmseronboardfetch`,
    HEATMAP_MSER_ONBOARD_SUCCESS: `${_namespace}/heatmapmseronboardsuceess`,
    DASHBOARD_POPUP_CLOSE: `${_namespace}/dashboardpopupclose`,
});

