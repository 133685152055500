import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IDublinSHRState } from './types';

const initialState = Object.freeze<IDublinSHRState>({
    isLoading: false,
    userId: '',
    MeetingDate: null,
    MaxDate: new Date(),
    DefaultDate: null,    
    DublinAdminLock: [],
    ActionItemsReviewData: [],
    accordiontoggle: true,
    isLockedPIR: true,
    isLockedPIRArray: [],
    isLockedTopic: true,
    isLockedTopicArray: [],
    PIRdata: [],
    SpecialTopicsData: [],
    isSpecialTopicLockDisabledOnEdit: false,
    piraccordiontoggle: true,
    isPIRLockDisabledOnEdit: false,
    isPIREditenabled: false,
    isSpecialTopicEditorAddEnabled: false,
    specialaccordiontoggle: true,
});

export const reducer = (
    state: IDublinSHRState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {
        case ActionType.SET_MEETINGDATE_DATE:
            return {
                ...state,
                userId: action.userId,
                MeetingDate: action.meetingdate,
                DefaultDate: action.meetingdate,
                MaxDate: action.maxdate
            };
        case ActionType.SET_LOADING_TRUE:
            return {
                ...state,
                isLoading: true

            };
        case ActionType.SET_LOADING_FALSE:
            return {
                ...state,
                isLoading: false

            };
        case ActionType.GET_WEEKLYIDSHR_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.GET_WEEKLYIDSHR_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ActionType.GET_WEEKLYIDSHR_SUCCESS:  // === for Post Incident Reviews success ===============//
            return {
                ...state,
                PIRdata: action.PIRdata,
                isPIREditenabled: false,
                isPIRLockDisabledOnEdit: action.isPIRLockDisabledOnEdit,
                isLoading: false,
                piraccordiontoggle: action.piraccordiontoggle
            };
        case ActionType.ENABLE_EDITORADD_PIR:  // === Enable add or Edit for Post Incident Reviews ===============//
            return {
                ...state,
                PIRdata: action.PIRdata,
                isPIREditenabled: true,
                isPIRLockDisabledOnEdit: action.isPIRLockDisabledOnEdit,
                piraccordiontoggle: true
            };
        case ActionType.GET_SPECIALTOPIC_SUCCESS:  // === for Special Topic success ===============//
            return {
                ...state,
                SpecialTopicsData: action.SpecialTopicsData,
                isSpecialTopicEditorAddEnabled: false,
                isLoading: false,
                specialaccordiontoggle: action.specialaccordiontoggle,
                isSpecialTopicLockDisabledOnEdit: action.isSpecialTopicLockDisabledOnEdit
            };
        case ActionType.SPECIALTOPIC_CHANGE:  // === for Special topic value changes ===============//
            return {
                ...state,
                SpecialTopicsData: action.SpecialTopicsData,
            };
        case ActionType.ENABLE_EDITORADD_SPECIALTOPIC:  // === Enable add or Edit for Post Incident Reviews ===============//
            return {
                ...state,
                SpecialTopicsData: action.SpecialTopicsData,
                isSpecialTopicEditorAddEnabled: true,
                isSpecialTopicLockDisabledOnEdit: action.isSpecialTopicLockDisabledOnEdit,
                specialaccordiontoggle: true
            };
        case ActionType.GET_WEEKLYIDSHR_FAILURE:  // === for Post Incident Reviews failure ===============//
            return {
                ...state,
                PIRdata: [],
                isLoading: (action.count >= 4) ? false : state.isLoading,
            };
        case ActionType.GET_SPECIALTOPIC_FAILURE:  // === for special topic failure ===============//
            return {
                ...state,
                SpecialTopicsData: [],
                isLoading: (action.count >= 4) ? false : state.isLoading,
            };
        case ActionType.GET_ACTION_ITEMS_REVIEW_FAILURE:  // === Enable add or Edit for Action Items Review ===============//
            return {
                ...state,
                ActionItemsReviewData: [],
                isLoading: (action.count >= 4) ? false : state.isLoading,
            };
        case ActionType.GET_ACTION_ITEMS_REVIEW_SUCCESS:  // === for Action Items Review success ===============//
            return {
                ...state,
                ActionItemsReviewData: action.ActionItemsReviewData,
                isLoading: (action.count >= 4) ? false : state.isLoading,
                accordiontoggle: action.accordiontoggle
            };
        case ActionType.ACTIONREVIEWACCORDION_STATUS:  // === Toggle Accordion  Action Items Review ===============//
            return {
                ...state,
                accordiontoggle: action.accordiontoggle
            };
        case ActionType.SPECIALTOPICACCORDION_STATUS:  // === Toggle Accordion Special topics ===============//
            return {
                ...state,
                specialaccordiontoggle: action.specialaccordiontoggle
            };
        case ActionType.PIRACCORDION_STATUS:  // === Toggle Accordion  PIR ===============//
            return {
                ...state,
                piraccordiontoggle: action.piraccordiontoggle
            };
        case ActionType.MEETINGDATE_DATE_CHANGE:
            return {
                ...state,
                MeetingDate: action.MeetingDate,
                isLockedPIR: action.isLockedPIR,
                isLockedTopic: action.isLockedTopic
            };
        case ActionType.SET_MEETINGDATE_DATE:
            return {
                ...state,
                MeetingDate: action.MeetingDate
            };
        case ActionType.GET_DUBLINADMINLOCK:
            return {
                ...state,
                isLockedTopic: action.isLockedTopic,
                isLockedPIR: action.isLockedPIR,
                isLockedTopicArray: action.isLockedTopicArray,
                isLockedPIRArray: action.isLockedPIRArray,
                isLoading: false
            }
        case ActionType.RESET_STATE:
            return initialState;
        default:
            return state;
    }
};
