import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IActionItemsState } from './types';

const initialState = Object.freeze<IActionItemsState>({   
    isLoading: false,
    weeklyStartDate: null,
    allRepairItemsData: [],
    allRepairItemsDataGraphoptions: '',
    allRepairItemsGraphDataAggregated:[],
    RepairItemGraphServicesoptions: '',
    allRepairItemsGraphData: [],
    checkAllService: true,
    allRepairItemsDatatableheader: null,
    actionItemsData: [],
    Isdataloaded:true
   
});

export const reducer = (
    state: IActionItemsState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {      
        case ActionType.GET_WEEK_START_DATE:   // ============ for getting week start Date         
            return {
                ...state,
                weeklyStartDate: action.weeklyStartDate
              
            };   
        case ActionType.GET_ACTIONITEMS_REQUEST:  // ============ Request for getting Action Item overall summary Data  (to bind for First tab)   
            return {
                ...state,
                allRepairItemsDataGraphoptions: null,
                allRepairItemsData: [],
                allRepairItemsGraphDataAggregated:[],
                isLoading: true,
                Isdataloaded:false

            };  
        case ActionType.GET_ACTIONITEMS_SUCCESS: // ============ Success Case for getting Action Item overall summary Data (to bind for First tab) 
            return {
                ...state,
                allRepairItemsData: action.allRepairItemsData,
                allRepairItemsDataGraphoptions: action.grapghoptions,
                checkAllService: action.checkAllService,
                allRepairItemsDatatableheader: action.tableheader
            };
        case ActionType.GET_ACTIONITEMS_FAILURE: // ============ Failuer Case for getting Action Item overall summary Data (to bind for First tab) 
            return {
                ...state,
                allRepairItemsData: [],
                allRepairItemsDataGraphoptions: null,
                isLoading: false

            };
        case ActionType.GET_REPAIRITEMS_SUCCESS: // ============ success Case for getting Graph Data 
            return {
                ...state,
                allRepairItemsGraphDataAggregated: action.allRepairItemsGraphDataAggregated,
                RepairItemGraphServicesoptions: action.RepairItemGraphServicesoptions,
                allRepairItemsGraphData: action.allRepairItemsGraphData,
                isLoading: false,
                Isdataloaded:true

            };
        case ActionType.GET_ACTIONITEMS_FAILURE: // ============ Failure Case for getting Graph Data 
            return {
                ...state,
                allRepairItemsGraphDataAggregated: [],
                RepairItemGraphServicesoptions: null,
                isLoading: false

            };
        case ActionType.GET_ACTIONITEMS_DATA_SUCCESS: // ============ success Case of getting Action Items  Data for second tab 
            return {
                ...state,
                actionItemsData: action.actionItemsData               
            };
        case ActionType.GET_ACTIONITEMS_DATA_FAILURE: // ============ failure Case of getting Action Items  Data for second tab 
            return {
                ...state,
                actionItemsData: []
            };
        case ActionType.RESET_STATE:
            return initialState;
        default:
            return state;
    }
};
