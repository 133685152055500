import React, { useState, useMemo, Fragment, useRef } from 'react';
import RichTextEditor from 'react-rte';
import { InputText } from 'primereact/inputtext';
import { Row, Col, Button } from 'reactstrap';

type CreateAdoHierarchyTableProps = {
    description: any;
    UpdateDescriptionValue: any;
    chapterID: any;
    UpdateChapterIDValue: any;
    epicTitle: any;
    epicID: any;
    UpdateEpicIDValue: any;
    UpdateEpicTitleValue: any;
    iterationPath: any;
    UpdateIterationPathValue: any;
};
const CreateAdoHierarchyTable = React.memo<CreateAdoHierarchyTableProps>(({
    description,
    UpdateDescriptionValue,
    chapterID,
    UpdateChapterIDValue,
    epicTitle,
    epicID,
    UpdateEpicIDValue,
    UpdateEpicTitleValue,
    iterationPath,
    UpdateIterationPathValue
}) => {
    const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
            { label: 'Italic', style: 'ITALIC' },
            { label: 'Underline', style: 'UNDERLINE' }
        ],
        BLOCK_TYPE_DROPDOWN: [
            { label: 'Normal', style: 'unstyled' },
            { label: 'Heading Large', style: 'header-one' },
            { label: 'Heading Medium', style: 'header-two' },
            { label: 'Heading Small', style: 'header-three' }
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item' },
            { label: 'OL', style: 'ordered-list-item' }
        ]
    };
    const re = /^[0-9\b]+$/;
    //const [descriptionValue, setdescriptionValue] = useState(RichTextEditor.createEmptyValue());
    //const [chapterIDValue, setChapterIDValue] = useState("");
    //const [epicTitleValue, setEpicTitleValue] = useState("");
    //const [iterationPathValue, setIterationPathValue] = useState(""); 

    const UpdateEpicID = (e) => {
        if (e.target.value === '' || re.test(e.target.value)) {
            UpdateEpicIDValue(e);
        }        
    }
    const UpdateChapterID = (e) => {
        if (e.target.value === '' || re.test(e.target.value)) {
            UpdateChapterIDValue(e)
        }
        //setEpicTitleValue(e.target.value)
    }
    //const UpdateIterationPathValue = (e) => {

    //    //setIterationPathValue(e.target.value)
    //}

    return (
        <div>
            <Row>
                <Col lg="3">
                    <h5>Chapter Work Item Id#</h5>
                </Col>
                <Col lg="6">
                    <h5>Epic Title <span style={{ color: "red" }}>*</span></h5>
                </Col>
                <Col lg="3">
                    <h5>Epic Work Item Id# <span style={{ color:"red" }}>*</span></h5>
                </Col>
            </Row>
            <Row>
                <Col lg="3">
                    <InputText style={{ width: '100%' }} value={chapterID} onChange={(e) => UpdateChapterID(e)} />
                </Col>
                <Col lg="6">
                    <InputText style={{ width: '100%' }} disabled={epicID != ''?true:false} value={epicTitle} onChange={(e) => UpdateEpicTitleValue(e)} />
                </Col>
                <Col lg="3">
                    <InputText style={{ width: '100%' }} disabled={epicTitle != '' ? true : false}  value={epicID} onChange={(e) => UpdateEpicID(e)} />
                </Col>
            </Row>
            <br></br>
            <Row>
                <Col lg="3">
                        <h5>Iteration Path <span style={{ color: "red" }}>*</span></h5>
                </Col>
                <Col lg="9">
                    <h5>Description</h5>
                </Col>
            </Row>
            <Row>
                <Col lg="3">
                    <InputText style={{ width: '100%' }} value={iterationPath} onChange={(e) => UpdateIterationPathValue(e)} />
                </Col>
                <Col lg="9">
                    <RichTextEditor
                        value={description}
                        onChange={(e) => { UpdateDescriptionValue(e) }}
                        toolbarConfig={toolbarConfig}
                        editorClassName="trix-editorADO"
                        className="customRichEdit"
                        placeholder="Description..."
                        type="string"
                        multiline
                        variant="filled"
                    />
                </Col>
            </Row>
        </div>
    )
});

CreateAdoHierarchyTable.displayName = 'CreateAdoHierarchyTable';
export default CreateAdoHierarchyTable;