import React, { useState, Fragment, useRef } from 'react';
import DatePicker, { getDay } from 'react-datepicker'
import { ReduxAction, IAppThunkAction } from '../../../store';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import { IPropEscalationDirectoryData } from '../../../store/EscalationDirectory';
import { FontAwesomeIconMemo, Checkbox } from '../../../components';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

import { Picky } from 'react-picky';

type EscalationDirectoryProps = {
    readonly escalationDirectoryDataFiltered: IPropEscalationDirectoryData[];
    searchText: any;
    UpdateSearchText: any;
    readonly setshoweditpopup: any;
    readonly setshowaddpopup: any;
    readonly sortData: any;
    readonly sortcolumn: any;
    readonly reverseSort: any;
};

const EscalationDirectoryTable = React.memo<EscalationDirectoryProps>(({
    escalationDirectoryDataFiltered,
    searchText,
    UpdateSearchText,
    setshoweditpopup,
    setshowaddpopup,
    sortData,
    sortcolumn,
    reverseSort
}) => {

    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);
    const setGlobalFilterValue = (event) => {
        setGlobalFilter(event.target.value);
    }
    const filterBasedSearch = (item) => {
        if (item.title.toLowerCase().includes(searchText.toLowerCase())
            || item.description.toLowerCase().includes(searchText.toLowerCase())
            || item.escalationPath.toLowerCase().includes(searchText.toLowerCase())
            || item.contactPerson.toLowerCase().includes(searchText.toLowerCase())
            || item.remarks.toLowerCase().includes(searchText.toLowerCase())
            || item.updatedBy.includes(searchText.toLowerCase())) {
            return true
        }
        else {
            return false;
        }
    };
    const UpdateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>              
                <FontAwesomeIcon cursor='pointer' icon={'pencil-alt'} onClick={e => setshoweditpopup(e, rowData)} />&nbsp;&nbsp;                
            </React.Fragment>
        );
    }    

    

    const displyDescription = (rowData) => {
        return (
            <div className="RTELIdots" dangerouslySetInnerHTML={{ __html: rowData.description }}></div>
        );
    } 
    const displyEscalationPath = (rowData) => {
        return (
            <div className="RTELIdots" dangerouslySetInnerHTML={{ __html: rowData.escalationPath }}></div>
        );
    } 
    const displyRemarks = (rowData) => {
        return (
            <div className="RTELIdots" dangerouslySetInnerHTML={{ __html: rowData.remarks }}></div>
        );
    } 

    return (
        <React.Fragment>
    
            <div>
                <Card className="cardstyle">
                    <CardBody className="cardbodyhederstyle">
                        <Row>
                            <Col lg="4"></Col>
                            <Col lg="4" style={{ textAlign: 'center' }}>
                                <div>
                                    <h3 className="headertext"> Partner Escalation </h3>
                                </div>
                            </Col>
                            <Col lg="4" style={{ textAlign: 'right' }}>
                                <span className="p-input-icon-left">
                                    <i className="pi pi-search" />&nbsp;
                                <InputText style={{ width: '340px', verticalAlign: 'top' }} type="search" placeholder="Global Search"  value={globalFilter} onChange={(e) => setGlobalFilterValue(e)} />&ensp;&ensp;&ensp;
                            </span>
                                <Button className="DCbuttons btn" onClick={setshowaddpopup}>
                                    <FontAwesomeIcon cursor='pointer' icon={'plus'} />&nbsp;&nbsp;Add New</Button>&ensp;                           
                            </Col>

                        </Row>
                    </CardBody>
                </Card>
                <br />
                <div>
                        <DataTable ref={dt} value={escalationDirectoryDataFiltered} className="tblIDEsclation tblborder"
                             globalFilter={globalFilter} emptyMessage="No Data Centers found.">
                        <Column className= "thwidth50 tdstyle textaligncenter"  field="id" header="Edit" body={UpdateBodyTemplate} />
                        <Column className="thwidth200 tdstyle"  field="title" sortable header="Team Name" filter filterMatchMode="contains" filterPlaceholder="Search by Team Name" />
                        <Column className="thwidth200 tdstyle"  field="description" sortable  body={displyDescription} header="Issue Details" filter filterMatchMode="contains" filterPlaceholder="Search by Issue Details" />
                        <Column className="thwidth550 tdstyle"  field="escalationPath" sortable body={displyEscalationPath } header="Team DL/Escalation Path" filter filterMatchMode="contains" filterPlaceholder="Search by Team DL/Escalation Path" />
                        <Column className="thwidth150 tdstyle"  field="contactPerson" sortable header="Contact Person" filter filterMatchMode="contains" filterPlaceholder="Search by Contact Person" />
                        <Column className="thwidth150 tdstyle"  field="remarks" sortable body={displyRemarks } header="Remarks" filter filterMatchMode="contains" filterPlaceholder="Search by Remarks" />
                        <Column className="thwidth100 tdstyle"  field="updatedBy" sortable header="Updated By" filter filterMatchMode="contains" filterPlaceholder="Search by Updated By" />                           
                        </DataTable>
                </div>
            </div>
            <br />   
        </React.Fragment>
    )
});

EscalationDirectoryTable.displayName = 'EscalationDirectoryTable';
export default EscalationDirectoryTable;