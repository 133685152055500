
export type IPropConfigurationState = {
    readonly isLoading: boolean;
    readonly services: any;
    readonly tenants: any;
    readonly tenantsalreadyOnboarded: any;
    readonly selectedService: any;
    readonly servicesdata: any;
    readonly service_ids: any;
    readonly currentserviceId: any;
    readonly selectedTenant: any;
    readonly serviceSelected: boolean;
    readonly isEnabled: boolean;
    readonly tenantshide: boolean;
    readonly UpdatedBy: string;
    readonly tenantsWithOnboarded: boolean;
    readonly showMessage: boolean;
    readonly addNewService: boolean;
    readonly isheatMapValidated: boolean;
    readonly showRBLMessage: boolean;
    readonly showRBLOnboardedMessage: boolean;
    readonly isRBLValidated: boolean;
    readonly displayCCLSuccessMessage: boolean;
    readonly isProd: boolean;
     //CCL UI Data
    questionarecclSelfConfiguration: any;
    questionarehighlevelInfo: any;
    questionareOncall: any;
    questionarecclFiltering: any;
    questionarecommDL: any;
    questionarecclPM: any;
    questionarecustomerImpacting: any;
    questionareGPMName: any;
    questionareIsTenantOrSubscriptionIdBased: any;
    questionarePartners: any;
    questionareComments: any;
};

export type IPropServiceToolsConfig = {
    readonly serviceName: string;
    readonly serviceID: number;
    readonly serviceTreeID: string;
}

export type IPropServiceGroupToolsConfig = {
    readonly groupId: string;
    readonly groupName: string;
    readonly teamGroupName: string;
}

const _namespace = 'Configuration';
export interface IActionType {
    readonly RESET_STATE: string;
    readonly GET_SERVICEGROUPS_REQUEST: string;
    readonly GET_SERVICEGROUPS_SUCCESS: string;
    readonly GET_SERVICEGROUPS_FAIL: string;
    readonly GET_CONFIGURATION_REQUEST: string;
    readonly GET_CONFIGURATION_SUCCESS: string;
    readonly GET_CONFIGURATION_FAILURE: string;
    readonly GET_CONFIGURATIONTENANT_SUCCESS: string;
    readonly UPDATE_CONFIGURATIONSERVICE_CHANGE: string;
    readonly SET_LOADING_TRUE: string;
    readonly SET_LOADING_FALSE: string;
    readonly ADDED_VISITED_USER: string;
    readonly UPDATE_LOGGEDIN_USER: string;
    readonly HEATMAP_VALIDATE: string;
    readonly RBL_VALIDATE: string;
    readonly SERVICE_SELECTED: string;
    readonly TENANT_SELECTED: string;
    readonly TENANT_CHANGED: string;
    readonly QUESTIONNAIRE_INFO_CCL: string;
    readonly VALIDATE_CCL_SELFCONFIGURATION: string;
    readonly ONBOARD_SERVICEHEATMAP_VALIDATED: string;
    readonly ONBOARD_SERVICEHEATMAP_NOTVALIDATED: string;
    readonly ONBOARD_SERVICEHEATMAP_VALIDATE_FAIL: string;
    readonly CONFIGURATION_QUESTIONAREHIGHLEVELINFO_CHANGE: string;
    readonly CONFIGURATION_QUESTIONAREONCALL_CHANGE: string;
    readonly CONFIGURATION_QUESTIONARECCLFILTERING_CHANGE: string;
    readonly CONFIGURATION_QUESTIONARECOMMDL_CHANGE: string;
    readonly CONFIGURATION_QUESTIONARECCLPM_CHANGE: string;
    readonly CONFIGURATION_QUESTIONARECUSTOMERIMPACTING_CHANGE: string;
    readonly RESET_FILTERS_CONFIGURATION: string;
    readonly ONBOARD_CCL_SUCCESS: string;
    readonly ONBOARD_HEATMAP_SUCCESS: string;
    readonly CONFIGURATION_QUESTIONAREGPMNAME_CHANGE: string;
    readonly CONFIGURATION_QUESTIONAREISTENANTORSUBSCRIPTIONIDBASED_CHANGE: string;
    readonly CONFIGURATION_COMMENTS_CHANGE: string;
    readonly CONFIGURATION_PARTNERS_CHANGE: string;
}

export const ActionType = Object.freeze<IActionType>({
    RESET_STATE: `${_namespace}/criticaladvisoryreset`,
    GET_SERVICEGROUPS_REQUEST: `${_namespace}/getservicegroupsrequest`,
    GET_SERVICEGROUPS_SUCCESS: `${_namespace}/getservicegroupssuccess`,
    GET_SERVICEGROUPS_FAIL: `${_namespace}/getservicegroupsfailure`,
    GET_CONFIGURATION_REQUEST: `${_namespace}/getcriticaladvisoryrequest`,
    GET_CONFIGURATION_SUCCESS: `${_namespace}/getcriticaladvisorysuccess`,
    GET_CONFIGURATION_FAILURE: `${_namespace}/getcriticaladvisoryfailure`,
    GET_CONFIGURATIONTENANT_SUCCESS: `${_namespace}/getconfigurationtenantsuccess`,
    UPDATE_CONFIGURATIONSERVICE_CHANGE: `${_namespace}/updateconfigurationservicechange`,
    SET_LOADING_TRUE: `${_namespace}/loadingtrue`,
    SET_LOADING_FALSE: `${_namespace}/loadingfalse`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    UPDATE_LOGGEDIN_USER: `${_namespace}/updateLoggedInUser`,
    HEATMAP_VALIDATE: `${_namespace}/heatmapvalidate`,
    RBL_VALIDATE: `${_namespace}/rblvalidate`,
    SERVICE_SELECTED: `${_namespace}/serviceselected`,
    TENANT_SELECTED: `${_namespace}/tenantselected`,
    TENANT_CHANGED: `${_namespace}/tenantchanged`,
    QUESTIONNAIRE_INFO_CCL: `${_namespace}/questionnaireinfoccl`,
    VALIDATE_CCL_SELFCONFIGURATION: `${_namespace}/validatecclselfconfiguration`,
    ONBOARD_SERVICEHEATMAP_VALIDATED: `${_namespace}/onboardserviceheatmapvalidated`,
    ONBOARD_SERVICEHEATMAP_NOTVALIDATED: `${_namespace}/onboardserviceheatmapnotvalidated`,
    ONBOARD_SERVICEHEATMAP_VALIDATE_FAIL: `${_namespace}/onboardserviceheatmapvalidatefail`,
    CONFIGURATION_QUESTIONAREHIGHLEVELINFO_CHANGE: `${_namespace}/configurationquestionairehighlevelinfochange`,
    CONFIGURATION_QUESTIONAREONCALL_CHANGE: `${_namespace}/configurationquestionaireoncallchange`,
    CONFIGURATION_QUESTIONARECCLFILTERING_CHANGE: `${_namespace}/configurationquestionairecclfilteringchange`,
    CONFIGURATION_QUESTIONARECOMMDL_CHANGE: `${_namespace}/configurationquestionairecommdlchange`,
    CONFIGURATION_QUESTIONARECCLPM_CHANGE: `${_namespace}/configurationquestionairecclpmchange`,
    CONFIGURATION_QUESTIONARECUSTOMERIMPACTING_CHANGE: `${_namespace}/configurationquestionairecustomerimpactingchange`,
    RESET_FILTERS_CONFIGURATION: `${_namespace}/resetfiltersconfiguration`,
    ONBOARD_CCL_SUCCESS: `${_namespace}/onboardcclsuccess`,
    ONBOARD_HEATMAP_SUCCESS: `${_namespace}/onboardheatmapsuccess`,
    CONFIGURATION_QUESTIONAREGPMNAME_CHANGE: `${_namespace}/congurationquestionariegpmname`,
    CONFIGURATION_QUESTIONAREISTENANTORSUBSCRIPTIONIDBASED_CHANGE: `${_namespace}/configurationquestionarietenantorsub`,
    CONFIGURATION_COMMENTS_CHANGE: `${_namespace}/congurationcommentschange`,
    CONFIGURATION_PARTNERS_CHANGE: `${_namespace}/congurationpartnerschange`
});

