import { ServiceGroupApi, WeeklyAPI, CommonAPI, EmailConfigAPI, DailyDataApi } from '../../api';
import { IAppThunkAction, ReduxAction } from '../';
import {
    ActionType,
    ConfigDropdownOption,
    PropServiceDetail,
    PropEmailConfigDataSource,
    IViewDetails,
    PropEmailConfigNames,
    PropDataSource,
    PropEmailConfigDataSourceName
} from './types';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../utils';
import moment from 'moment';
import { IServiceGroup, IPropCalender } from '../home-tiles';
import { IApplicationState } from '../../store';
import { toast } from "react-toastify";
import { object } from 'prop-types';

export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),

    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }

    },

    setloadingtrue: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
    },
    setloadingfalse: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_FALSE
        });
    },
    GetDropDownDataForEmailConfig: (Callcount, selectedServiceval): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.GET_ALL_DAILY_EMAIL_REQUEST
        });
        let count = 0;
        if (Callcount != 6) {
            count = 6;
        }
        if (Callcount === 1 || Callcount === 6) {
            //==================== to get data for Service Groups =================//
            ServiceGroupApi.GetServiceGroupsWeeklySelfServe()
                .then((serviceGroupRaw: IServiceGroup[]) => {
                    const serviceGroups = [];
                    if (isArrayWithLength(serviceGroupRaw)) {
                        serviceGroupRaw.map((item: IServiceGroup, key) => {
                            var element = serviceGroups.filter(serGroup => serGroup.name === item.groupName.replace(new RegExp('<br/>', 'g'), ' – '));
                            if (element.length === 0) {
                                serviceGroups.push({ name: item.groupName.replace(new RegExp('<br/>', 'g'), ' – '), value: item.groupId });
                            }
                        })
                    }
                    count = count + 1;
                    dispatch({
                        count: count,
                        serviceGroups,
                        type: ActionType.GET_SERVICEGROUPS_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_SERVICEGROUPS_FAIL
                    });
                });
        }
        if (Callcount === 2 || Callcount === 6) {
            //==================== to get data for Services =================//
            //passing empty string to fetch all the services irrespective of Group
            WeeklyAPI.GetServices('')
                .then((serviceRaw: IServiceGroup[]) => {
                    const services = [];
                    if (isArrayWithLength(serviceRaw)) {
                        serviceRaw.map((item: IServiceGroup) => {
                            services.push({ name: item.serviceName, value: item.serviceId });
                        })
                    }
                    count = count + 1;
                    dispatch({
                        count: count,
                        services,
                        selectedServiceval: selectedServiceval,
                        type: ActionType.GET_SERVICELISTS_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_SERVICELISTS_FAIL
                    });
                });
        }
    },

    GetEmailConfigNames: (selectedServiceval): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.GET_EMAIL_CONFIG_DATASOURCE_REQUEST
        });
        var Servicegroupval;
        var selectedServicegroupval = null;

        EmailConfigAPI.GetEmailConfigNames(selectedServiceval)
            .then((names: PropEmailConfigNames[]) => {
                const emailConfigNames = [];
                let onboardNewEmailConfigHeatmap = false;
                let onboardNewEmailConfigPRI = false;
                let onboardNewEmailConfigCRI = false;
                let onboardNewEmailConfigDRI = false;
                let onboardNewEmailConfigSEV = false;
                if (isArrayWithLength(names)) {
                    names.map((obj: PropEmailConfigNames) => {
                        emailConfigNames.push(obj);
                        if (obj.emailConfigName === 'Heatmap') {
                            onboardNewEmailConfigHeatmap = true;
                        }
                        else if (obj.emailConfigName === 'PRI') {
                            onboardNewEmailConfigPRI = true;
                        }
                        else if (obj.emailConfigName === 'CRI') {
                            onboardNewEmailConfigCRI = true;
                        }
                        else if (obj.emailConfigName === 'DRI') {
                            onboardNewEmailConfigDRI = true;
                        }
                        else if (obj.emailConfigName === 'SEV12s') {
                            onboardNewEmailConfigSEV = true;
                        }
                    })
                }
                dispatch({
                    emailConfigNames,
                    onboardNewEmailConfigHeatmap,
                    onboardNewEmailConfigPRI,
                    onboardNewEmailConfigCRI,
                    onboardNewEmailConfigDRI,
                    onboardNewEmailConfigSEV,
                    type: ActionType.GET_EMAIL_CONFIG_NAMES_SUCCESS
                });

            }).catch(error => {
                dispatch({
                    type: ActionType.GET_EMAIL_CONFIG_NAMES_FAIL
                });
            });
    },

    GetEmailConfigDataSourceNames: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.GET_EMAIL_CONFIG_DATASOURCE_NAME_REQUEST
        });
        WeeklyAPI.GetDataSources()
            .then((dataSourceRaw: PropEmailConfigDataSourceName[]) => {
                const datasource = [];
                if (isArrayWithLength(dataSourceRaw)) {
                    dataSourceRaw.map((item: PropEmailConfigDataSourceName) => {
                        //Filtering only Kusto Metric Data Source ,since it is only Automated
                        if (item.Id === 1) {
                            datasource.push({ name: item.SourceName, value: item.Id });
                        }
                    })
                }               
                dispatch({
                    datasource,
                    type: ActionType.GET_EMAIL_CONFIG_DATASOURCE_NAME_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.GET_EMAIL_CONFIG_DATASOURCE_NAME_FAIL
                });
            });
    },

    GetEmailConfigDataSources: (selectedServiceval): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.GET_EMAIL_CONFIG_DATASOURCE_REQUEST
        });
        var Servicegroupval;
        var selectedServicegroupval = null;

        WeeklyAPI.GetserviceGroupWeeklySelfServe(parseInt(selectedServiceval.toString()))
            .then((gemRaw: PropServiceDetail[]) => {
                if (isArrayWithLength(gemRaw)) {
                    gemRaw.map((obj: PropServiceDetail) => {
                        Servicegroupval = obj.GroupName.replace(new RegExp('<br/>', 'g'), ' – ');
                    })

                    let serviceGroups = getState().EmailConfiguration.serviceGroups;

                    // Logic to set the Service Group default to selected service if Service Group exists for the corresponding service else 
                    // to set with the null by default
                    var element = serviceGroups.filter(item => item.name === Servicegroupval);
                    selectedServicegroupval = element.length !== 0 ? element[0].value : "";

                    dispatch({
                        selectedServiceval,
                        selectedServicegroupval,
                        type: ActionType.SERVICES_CHANGE
                    });
                }

                EmailConfigAPI.GetEmailConfigDataSourceDetails(parseInt(selectedServiceval.toString()))
                    .then((emailConfigDataSourceRaw: PropEmailConfigDataSource[]) => {
                        const emailConfigDataSourceDetails = [];
                        const emailConfigDataSourceDetailsRaw = [];                       

                        let toEmailList = '';
                        let ccEmailList = '';

                        var selectedDatasourcePRI = null;

                        let serverNamePRI = '';
                        let databasePRI = '';
                        let authenticationTypePRI = '';
                        let queryOrUrlPRI = '';
                        let commentsPRI = '';

                        var selectedDatasourceCRI = null;
                        let serverNameCRI = '';
                        let databaseCRI = '';
                        let authenticationTypeCRI = '';
                        let queryOrUrlCRI = '';
                        let commentsCRI = '';

                        var selectedDatasourceDRI = null;
                        let serverNameDRI = '';
                        let databaseDRI = '';
                        let authenticationTypeDRI = '';
                        let queryOrUrlDRI = '';
                        let commentsDRI = '';

                        var selectedDatasourceSEV12 = null;
                        let serverNameSEV12 = '';
                        let databaseSEV12 = '';
                        let authenticationTypeSEV12 = '';
                        let queryOrUrlSEV12 = '';
                        let commentsSEV12 = '';

                        if (isArrayWithLength(emailConfigDataSourceRaw)) {
                            emailConfigDataSourceRaw.map((ecobj: PropEmailConfigDataSource) => {
                                //Filtering only Kusto Data Source related queries ,since it is only Automated
                                if (ecobj.dataSourceId === 1) {
                                    emailConfigDataSourceDetails.push(ecobj);
                                    emailConfigDataSourceDetailsRaw.push(ecobj);
                                    toEmailList = ecobj.toEmailList;
                                    ccEmailList = ecobj.ccEmailList;


                                    if (ecobj.emailConfigName === 'PRI') {
                                        selectedDatasourcePRI = ecobj.dataSourceId;
                                        serverNamePRI = ecobj.clusterName;
                                        databasePRI = ecobj.dbName;
                                        authenticationTypePRI = ecobj.authenicationType;
                                        queryOrUrlPRI = ecobj.queryOrUrl;
                                        commentsPRI = ecobj.comments;
                                    }
                                    else if (ecobj.emailConfigName === 'CRI') {
                                        selectedDatasourceCRI = ecobj.dataSourceId;
                                        serverNameCRI = ecobj.clusterName;
                                        databaseCRI = ecobj.dbName;
                                        authenticationTypeCRI = ecobj.authenicationType;
                                        queryOrUrlCRI = ecobj.queryOrUrl;
                                        commentsCRI = ecobj.comments;
                                    }
                                    else if (ecobj.emailConfigName === 'DRI') {
                                        selectedDatasourceDRI = ecobj.dataSourceId;
                                        serverNameDRI = ecobj.clusterName;
                                        databaseDRI = ecobj.dbName;
                                        authenticationTypeDRI = ecobj.authenicationType;
                                        queryOrUrlDRI = ecobj.queryOrUrl;
                                        commentsDRI = ecobj.comments;
                                    }
                                    else if (ecobj.emailConfigName === 'SEV12s') {
                                        selectedDatasourceSEV12 = ecobj.dataSourceId;
                                        serverNameSEV12 = ecobj.clusterName;
                                        databaseSEV12 = ecobj.dbName;
                                        authenticationTypeSEV12 = ecobj.authenicationType;
                                        queryOrUrlSEV12 = ecobj.queryOrUrl;
                                        commentsSEV12 = ecobj.comments;
                                    }
                                }
                            })

                            dispatch({
                                emailConfigDataSourceDetails,
                                emailConfigDataSourceDetailsRaw,

                                toEmailList,
                                ccEmailList,

                                selectedDatasourcePRI,

                                serverNamePRI,
                                databasePRI,
                                authenticationTypePRI,
                                queryOrUrlPRI,
                                commentsPRI,

                                selectedDatasourceCRI,
                                serverNameCRI,
                                databaseCRI,
                                authenticationTypeCRI,
                                queryOrUrlCRI,
                                commentsCRI,

                                selectedDatasourceDRI,
                                serverNameDRI,
                                databaseDRI,
                                authenticationTypeDRI,
                                queryOrUrlDRI,
                                commentsDRI,

                                selectedDatasourceSEV12,
                                serverNameSEV12,
                                databaseSEV12,
                                authenticationTypeSEV12,
                                queryOrUrlSEV12,
                                commentsSEV12,

                                type: ActionType.GET_EMAIL_CONFIG_DATASOURCE_SUCCESS
                            });
                        }
                        DailyDataApi.getHeatmapView(parseInt(selectedServiceval.toString()))
                            .then((viewDetails: IViewDetails[]) => {
                                const viewSourceDetailsByService = [];
                                const viewTargetDetailsByService = [];
                                if (isArrayWithLength(viewDetails)) {
                                    viewDetails.map((obj: IViewDetails) => {
                                        let isTargetExists = false;
                                        if (!obj.isReachability) {
                                            const pObj = {} as IViewDetails;
                                            pObj.viewId = obj.viewId;
                                            pObj.viewName = obj.viewName;
                                            if (isArrayWithLength(emailConfigDataSourceDetailsRaw)) {
                                                emailConfigDataSourceDetailsRaw.map((ecobj: PropEmailConfigDataSource) => {
                                                    if (ecobj.emailConfigName === 'Heatmap') {
                                                        var viewSplit = ecobj.viewIds.split(",")
                                                        if (isArrayWithLength(viewSplit)) {
                                                            for (var i = 0; i < viewSplit.length; i++) {
                                                                if (viewSplit[i] == obj.viewId) {
                                                                    viewTargetDetailsByService.push(pObj);
                                                                    isTargetExists = true;
                                                                    toEmailList = ecobj.toEmailList;
                                                                    ccEmailList = ecobj.ccEmailList;

                                                                }
                                                            }

                                                        }
                                                    }

                                                })
                                            }
                                            if (!isTargetExists) {
                                                viewSourceDetailsByService.push(pObj);
                                            }
                                        }
                                    })
                                    dispatch({
                                        toEmailList,
                                        ccEmailList,
                                        viewSourceDetailsByService,
                                        viewTargetDetailsByService,
                                        type: ActionType.GET_VIEW_DETAILS_SUCCESS
                                    });
                                }



                            }).catch(error => {
                                dispatch({
                                    type: ActionType.GET_EMAIL_CONFIG_DATASOURCE_FAIL
                                });
                            });

                    }).catch(error => {
                        dispatch({
                            type: ActionType.GET_VIEW_DETAILS_FAIL
                        });
                    });
            });
    },

    clearConfigDataFilter: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let IsClearAll = getState().EmailConfiguration.IsClearAll;
        if (!IsClearAll)
            IsClearAll = true;
        dispatch({
            IsClearAll,
            type: ActionType.CLEAR_CONFIG_DATA_SECTION
        });
    },

    handleEmailConfigTypesChange: (selectedEmailConfigTypes: ConfigDropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            selectedEmailConfigTypes,
            type: ActionType.EMAIL_CONFIG_DATA_TYPE
        });
    },

    handleServiceGroupChange: (selectedServicegroupval: ConfigDropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        dispatch({
            type: ActionType.RESET_FILTER // To Reset all other Control Values
        });
        dispatch({
            selectedServicegroupval,
            type: ActionType.SERVICEGROUP_CHANGE
        });
        //==================== to get data for Services corresponding to selected Service Group=================//
        WeeklyAPI.GetServices(selectedServicegroupval.toString())
            .then((serviceRaw: IServiceGroup[]) => {
                const services = [];
                if (isArrayWithLength(serviceRaw)) {
                    serviceRaw.map((item: IServiceGroup) => {
                        services.push({ name: item.serviceName, value: item.serviceId });
                    })
                }
                dispatch({
                    services,
                    selectedServiceval: null,
                    type: ActionType.GET_SERVICELISTS_SUCCESS
                });
                dispatch({
                    type: ActionType.SET_LOADING_FALSE
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.GET_SERVICELISTS_FAIL
                });
            });
    },
    handleServicesChange: (selectedServiceval: ConfigDropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.RESET_FILTER // To Reset all other Control Values
        });
        dispatch({
            selectedServiceval,
            type: ActionType.SERVICES_CHANGE
        });
    },

    EditEmailConfigDataSourceRowPRI: (row): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            serverNamePRI: row.clusterName,
            databasePRI: row.dbName,
            authenticationTypePRI: row.authenicationType,
            queryOrUrlPRI: row.queryOrUrl,
            notesPRI: row.comments,
            type: ActionType.ENABLE_EDIT_OR_ADD_EMAIL_CONFIG_DATASOURCE_PRI
        });
    },

    clearEmailConfigDataSourceDataPRI: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.CLEAR_EMAIL_CONFIG_DATASOURCE_SECTION_PRI
        });
    },

    handledatasourceChangePRI: (selectedDatasourcePRI: PropEmailConfigDataSourceName): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            selectedDatasourcePRI,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_CHANGE_PRI
        });
    },

    handledatasourceChangeCRI: (selectedDatasourceCRI: PropEmailConfigDataSourceName): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            selectedDatasourceCRI,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_CHANGE_CRI
        });
    },

    handledatasourceChangeDRI: (selectedDatasourceDRI: PropEmailConfigDataSourceName): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            selectedDatasourceDRI,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_CHANGE_DRI
        });
    },

    handledatasourceChangeSEV12: (selectedDatasourceSEV12: PropEmailConfigDataSourceName): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            selectedDatasourceSEV12,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_CHANGE_SEV12
        });
    },

    clearEmailConfigDataSourceDataHeatmap: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let viewSourceDetailsByService = getState().EmailConfiguration.viewSourceDetailsByService;
        dispatch({
            viewSourceDetailsByService,
            type: ActionType.CLEAR_EMAIL_CONFIG_DATASOURCE_SECTION_HEATMAP
        });
    },

    EditEmailConfigDataSourceRowCRI: (row): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            serverNameCRI: row.clusterName,
            databaseCRI: row.dbName,
            authenticationTypeCRI: row.authenicationType,
            queryOrUrlCRI: row.queryOrUrl,
            notesCRI: row.comments,
            type: ActionType.ENABLE_EDIT_OR_ADD_EMAIL_CONFIG_DATASOURCE_CRI
        });
    },

    clearEmailConfigDataSourceDataCRI: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.CLEAR_EMAIL_CONFIG_DATASOURCE_SECTION_CRI
        });
    },

    EditEmailConfigDataSourceRowDRI: (row): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            serverNameDRI: row.clusterName,
            databaseDRI: row.dbName,
            authenticationTypeDRI: row.authenicationType,
            queryOrUrlDRI: row.queryOrUrl,
            notesDRI: row.comments,
            type: ActionType.ENABLE_EDIT_OR_ADD_EMAIL_CONFIG_DATASOURCE_DRI
        });
    },

    clearEmailConfigDataSourceDataDRI: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.CLEAR_EMAIL_CONFIG_DATASOURCE_SECTION_DRI
        });
    },

    EditEmailConfigDataSourceRowSEV12: (row): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            serverNameSEV12: row.clusterName,
            databaseSEV12: row.dbName,
            authenticationTypeSEV12: row.authenicationType,
            queryOrUrlSEV12: row.queryOrUrl,
            notesSEV12: row.comments,
            type: ActionType.ENABLE_EDIT_OR_ADD_EMAIL_CONFIG_DATASOURCE_SEV12
        });
    },

    clearEmailConfigDataSourceDataSEV12: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.CLEAR_EMAIL_CONFIG_DATASOURCE_SECTION_SEV12
        });
    },

    serverNameUpdatePRI: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const serverNamePRI = event.target.value;
        dispatch({
            serverNamePRI,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_SERVER_CHANGE_UPDATE_PRI
        });
    },

    changeToEmailList: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const toEmailList = event.target.value;
        dispatch({
            toEmailList,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_TO_EMAIL_CHANGE_UPDATE
        });
    },

    changeCCEmailList: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const ccEmailList = event.target.value;
        dispatch({
            ccEmailList,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_CC_EMAIL_CHANGE_UPDATE
        });
    },

    databaseUpdatePRI: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const databasePRI = event.target.value;
        dispatch({
            databasePRI,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_DATABASE_CHANGE_UPDATE_PRI
        });
    },

    authenticationTypeUpdatePRI: (authenticationTypePRI: ConfigDropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            authenticationTypePRI,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_AUTHENTICATIONTYPE_CHANGE_UPDATE_PRI
        });
    },

    queryOrUrlUpdatePRI: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const queryOrUrlPRI = event.target.value;
        dispatch({
            queryOrUrlPRI,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_QUERYORURL_CHANGE_UPDATE_PRI
        });
    },

    commentsUpdatePRI: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const commentsPRI = event.target.value;
        dispatch({
            commentsPRI,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_COMMENTS_CHANGE_UPDATE_PRI
        });
    },


    serverNameUpdateCRI: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const serverNameCRI = event.target.value;
        dispatch({
            serverNameCRI,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_SERVER_CHANGE_UPDATE_CRI
        });
    },
    databaseUpdateCRI: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const databaseCRI = event.target.value;
        dispatch({
            databaseCRI,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_DATABASE_CHANGE_UPDATE_CRI
        });
    },

    authenticationTypeUpdateCRI: (authenticationTypeCRI: ConfigDropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            authenticationTypeCRI,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_AUTHENTICATIONTYPE_CHANGE_UPDATE_CRI
        });
    },

    queryOrUrlUpdateCRI: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const queryOrUrlCRI = event.target.value;
        dispatch({
            queryOrUrlCRI,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_QUERYORURL_CHANGE_UPDATE_CRI
        });
    },

    commentsUpdateCRI: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const commentsCRI = event.target.value;
        dispatch({
            commentsCRI,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_COMMENTS_CHANGE_UPDATE_CRI
        });
    },

    serverNameUpdateDRI: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const serverNameDRI = event.target.value;
        dispatch({
            serverNameDRI,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_SERVER_CHANGE_UPDATE_DRI
        });
    },
    databaseUpdateDRI: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const databaseDRI = event.target.value;
        dispatch({
            databaseDRI,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_DATABASE_CHANGE_UPDATE_DRI
        });
    },

    authenticationTypeUpdateDRI: (authenticationTypeDRI: ConfigDropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            authenticationTypeDRI,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_AUTHENTICATIONTYPE_CHANGE_UPDATE_DRI
        });
    },

    queryOrUrlUpdateDRI: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const queryOrUrlDRI = event.target.value;
        dispatch({
            queryOrUrlDRI,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_QUERYORURL_CHANGE_UPDATE_DRI
        });
    },

    commentsUpdateDRI: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const commentsDRI = event.target.value;
        dispatch({
            commentsDRI,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_COMMENTS_CHANGE_UPDATE_DRI
        });
    },

    serverNameUpdateSEV12: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const serverNameSEV12 = event.target.value;
        dispatch({
            serverNameSEV12,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_SERVER_CHANGE_UPDATE_SEV12
        });
    },
    databaseUpdateSEV12: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const databaseSEV12 = event.target.value;
        dispatch({
            databaseSEV12,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_DATABASE_CHANGE_UPDATE_SEV12
        });
    },

    authenticationTypeUpdateSEV12: (authenticationTypeSEV12: ConfigDropdownOption): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            authenticationTypeSEV12,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_AUTHENTICATIONTYPE_CHANGE_UPDATE_SEV12
        });
    },

    queryOrUrlUpdateSEV12: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const queryOrUrlSEV12 = event.target.value;
        dispatch({
            queryOrUrlSEV12,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_QUERYORURL_CHANGE_UPDATE_SEV12
        });
    },

    commentsUpdateSEV12: (event): IAppThunkAction<ReduxAction> => (dispatch) => {
        const commentsSEV12 = event.target.value;
        dispatch({
            commentsSEV12,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_COMMENTS_CHANGE_UPDATE_SEV12
        });
    },

    SaveEmailConfigDataSource: (sObj): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.VALIDATE_DATASOURCE_REQUEST
        });

        if (sObj.emailConfigName === "Heatmap") {
            let IsDataSaved = false;
            EmailConfigAPI.SaveEmailConfigDataSourceDetails(sObj)
                .then((result: number) => {
                    if (result != -1) {
                        IsDataSaved = true;
                    }
                    else {
                        IsDataSaved = false;
                    }
                    dispatch({
                        IsDataSaved,
                        type: ActionType.DATASOURCE_SAVE_SUCCESS
                    });

                }).catch(error => {
                    dispatch({
                        IsDataSaved,
                        type: ActionType.DATASOURCE_SAVE_FAIL
                    });
                });
        }
        else {
            let IsQueryFailed = false;
            let IsDataSaved = false;
            let kustoquery = JSON.stringify({ "csl": sObj.queryOrUrl, "db": sObj.dbName });
            DailyDataApi.ValidateKustoQuery({ "json": kustoquery, "apiName": sObj.clusterName })
                .then((result: string) => {
                    if (result.length > 0) {
                        IsQueryFailed = true;
                    }
                    else {                        
                        EmailConfigAPI.SaveEmailConfigDataSourceDetails(sObj)
                            .then((result: number) => {
                                if (result != -1) {
                                    IsDataSaved = true;
                                }
                                else {
                                    IsDataSaved = false;
                                }
                                dispatch({
                                    IsDataSaved,
                                    type: ActionType.DATASOURCE_SAVE_SUCCESS
                                });

                            }).catch(error => {
                                dispatch({
                                    IsDataSaved,
                                    type: ActionType.DATASOURCE_SAVE_FAIL
                                });
                            });
                    }
                    dispatch({
                        IsDataSaved,
                        type: ActionType.VALIDATE_DATASOURCE_SUCCESS
                    });

                }).catch(error => {
                    dispatch({
                        IsQueryFailed,
                        type: ActionType.VALIDATE_DATASOURCE_FAIL
                    });
                });
        }
        
    },

    setonboardNewEmailConfigHeatmap: (value): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const onboardNewEmailConfigHeatmap = value;
        dispatch({
            onboardNewEmailConfigHeatmap,            
            type: ActionType.EMAIL_CONFIG_DATASOURCE_ONBOARD_CHANGE
        });        
    },

    setonboardNewEmailConfigPRI: (value): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const onboardNewEmailConfigPRI = value;
        dispatch({
            onboardNewEmailConfigPRI,            
            type: ActionType.EMAIL_CONFIG_DATASOURCE_ONBOARD_PRI_CHANGE
        });        
    },

    setonboardNewEmailConfigCRI: (value): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const onboardNewEmailConfigCRI = value;
        dispatch({
            onboardNewEmailConfigCRI,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_ONBOARD_CRI_CHANGE
        });
    },

    setonboardNewEmailConfigDRI: (value): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const onboardNewEmailConfigDRI = value;
        dispatch({
            onboardNewEmailConfigDRI,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_ONBOARD_DRI_CHANGE
        });
    },

    setonboardNewEmailConfigSEV: (value): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const onboardNewEmailConfigSEV = value;
        dispatch({
            onboardNewEmailConfigSEV,
            type: ActionType.EMAIL_CONFIG_DATASOURCE_ONBOARD_SEV_CHANGE
        });
    },

    
    resetFilters: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.RESET_FILTER
        });
    },
    setViewEmailConfigDataSourceEnabled: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.EMAIL_CONFIG_DATASOURCE_VIEW_ENABLE
        });
    },
    setViewEmailConfigDataSourceDisabled: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.EMAIL_CONFIG_DATASOURCE_VIEW_DISABLE
        });
    },
    setAddEmailConfigDataSourceEnabled: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.EMAIL_CONFIG_DATASOURCE_ADD_ENABLE
        });
    },
    setAddEmailConfigDataSourceDisabled: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.EMAIL_CONFIG_DATASOURCE_ADD_DISABLE
        });
    },
    setAddEmailConfigPRIDataSourceEnabled: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.EMAIL_CONFIG_PRI_DATASOURCE_ADD_ENABLE
        });
    },
    setAddEmailConfigPRIDataSourceDisabled: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.EMAIL_CONFIG_PRI_DATASOURCE_ADD_DISABLE
        });
    },
   
};

