import React, { useEffect, useRef, useState, Fragment } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import info_icon from '../../../assets/images/info_icon.png';
import { faPlus, faTrash, faPencilAlt, faTimes } from '@fortawesome/free-solid-svg-icons'
import { IServiceGroup, PropMetricGroups, PropAdminMetrics, PropConfig, PropTarget, PropEditHistory, PropMetricsChangeHistory, PropCmMetric, ServiceDropdownOption, CMDropdownOption, ITargetJson } from '../../../store/AdminSettings';
import Switch from 'react-switch'
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { Slider } from 'primereact/slider';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import classnames from 'classnames';
import moment from 'moment';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Row,
    Col, Card, CardBody, CardTitle
} from 'reactstrap';
import { Modal, Tabs, Tab, Accordion } from 'react-bootstrap'
import { ReduxAction, IAppThunkAction } from '../../../store';

type AdminSettingsProps = {
    isLoading: any,
    readonly services: [],
    adminSettingsData: any,
    isAdmin: any,
    Isdataloaded: boolean,
    activeDailyDataTab: any,
    DailyDataTabstoggle: any,
    selectedServicesval: any,
    selectedCrServicesval: any,
    selectedMetricGroupsval: any,
    selectedCMMetricGroupsval: any,
    selectedEnvironmentval: any,
    selectedHeatmapServiceval: any,
    selectedhistoryServicesval: any,
    selectedhistoryMetricGroupsval: any,
    readonly serviceNameSelect: (selectedServicesval: ServiceDropdownOption) => IAppThunkAction<ReduxAction>,
    readonly serviceNameSelectCr: (selectedCrServicesval: ServiceDropdownOption) => IAppThunkAction<ReduxAction>,
    readonly metricGroupSelect: (selectedMetricGroupsval: ServiceDropdownOption) => IAppThunkAction<ReduxAction>,
    readonly cmmetricGroupSelect: any,
    readonly cmEnvironmentSelect: any, /*(selectedEnvironmentval: CMDropdownOption) => IAppThunkAction<ReduxAction>,*/
    readonly heatmapServiceNameSelect: (selectedHeatmapServiceval: ServiceDropdownOption) => IAppThunkAction<ReduxAction>,
    readonly historyserviceNameSelect: (selectedhistoryServicesval: ServiceDropdownOption) => IAppThunkAction<ReduxAction>,
    readonly historymetricGroupSelect: (selectedhistoryMetricGroupsval: ServiceDropdownOption) => IAppThunkAction<ReduxAction>,
    serviceNames: any,
    readonly CMData: PropCmMetric[],
    readonly CMScoreCardData: [],
    servicechange: any,
    readonly metricGroups: any,
    readonly CMMetricGroups: any,
    readonly CMEnvirnoments: any,
    readonly adminMetrics: PropAdminMetrics[],
    loadMetrics: any,
    readonly adminConfig: any,
    readonly missingViewInfo: any,
    readonly heatMapHistoryData: any,
    readonly editHistory: PropEditHistory[],
    readonly metricChange: any,
    selectedCrMetricsval: any,
    readonly crMetricSelect: (selectedCrMetricsval: CMDropdownOption) => IAppThunkAction<ReduxAction>,
    changeServiceStatus: any,
    readonly targetDetails: any,
    readonly selectedtargetDetailsval: any,
    readonly crTargetSelect: (selectedtargetDetailsval: ServiceDropdownOption) => IAppThunkAction<ReduxAction>,
    crservice: any,
    crcomments: any,
    crrequestedBy: any,
    crcreatedBy: any,
    crtitle: any,
    crrequirment_Id: any,
    crnewValue: any,
    UpdateRequestedByValue: any,
    UpdateTitleValue: any,
    UpdateRequirementIdValue: any,
    UpdateCommentValue: any,
    SaveCrMetricDetails: any,
    SaveConfiguration: any,
    historyFrmCalVal: any,
    historyFrmValChange: any,
    historyToCalVal: any,
    historyToValChange: any,
    heatmapFromCalVal: any,
    heatmapFromCalValChange: any,
    heatmapToCalVal: any,
    heatmapToCalValChange: any,
    SaveConfig: any,
    UpdateCMRecord: any,
    CMDeleteMetric: any,
    newMetricName: any,
    newMetricDisplayName: any,
    newMetricGroup: any
    newMetricEnvironment: any,
    onInputChange: any,
    SaveNewCMMetric: any,
    handledMetricEditChange: any,
    handledMetricGraphChange: any,
    onInputValidExpChange: any,
    onInputMinRangeChange: any,
    onInputMaxRangeChange: any,
    onInputIntervalChange: any,
    metricConfigMetricId: any,
    metricConfigGroupName: any,
    isEditableMetricConfig: any,
    isMetricGraphMetricConfig: any,
    minRangeMetricConfig: any,
    maxRangeMetricConfig: any,
    intervalMetricConfig: any,
    validationExprMetricConfig: any,
    onInputMetricConfig: any,
    saveMetricConfig: any,
    saveMetricConfigTab: any,
    NewMetricSavefunction: any;
    targetUpdateApprovalRequestData: ITargetJson[],
    reviewerCommentsChange: any,
    approveHmRequest: any,
    rejectHmRequest: any
};

let tabname = '';
const settabname = (name) => {
    tabname = name;
}

const tabslist = [{ 'Name': 'serviceConfiguration', 'DisplayName': 'Service Configurations' },
{ 'Name': 'metricConfiguration', 'DisplayName': 'Metrics Configurations' },
{ 'Name': 'crTracking', 'DisplayName': 'CR Tracking' },
{ 'Name': 'heatmapTargetApproval', 'DisplayName': 'Heatmap Target Approval' },
{ 'Name': 'capacityManagement', 'DisplayName': 'Capacity Management' },
{ 'Name': 'otherConfiguration', 'DisplayName': 'Other Configurations' },
{ 'Name': 'historyData', 'DisplayName': 'History Data' },
{ 'Name': 'heatMapHistoryData', 'DisplayName': 'Heat Map History Data' },
{ 'Name': 'missingHeatmapData', 'DisplayName': 'Missing Data for HeatMap' }];
let heatmapViewPopData = { Updatedon: '', val: '' }
let addNewMetricObj = {
    cmMetricDisplayName: '',
    cmMetricGroupId: 0,
    cmMetricId: 0,
    cmMetricName: '',
    envId: 0
}

const AdminSettingsTable = React.memo<AdminSettingsProps>(({
    isLoading,
    services,
    adminSettingsData,
    Isdataloaded,
    isAdmin,
    activeDailyDataTab,
    DailyDataTabstoggle,
    selectedServicesval,
    selectedCrServicesval,
    metricGroups,
    selectedMetricGroupsval,
    servicechange,
    serviceNames,
    adminMetrics,
    serviceNameSelect,
    serviceNameSelectCr,
    metricGroupSelect,
    selectedCMMetricGroupsval,
    selectedEnvironmentval,
    CMMetricGroups,
    CMEnvirnoments,
    cmmetricGroupSelect,
    cmEnvironmentSelect,
    loadMetrics,
    CMData,
    CMScoreCardData,
    adminConfig,
    missingViewInfo,
    heatMapHistoryData,
    selectedHeatmapServiceval,
    heatmapServiceNameSelect,
    editHistory,
    selectedhistoryServicesval,
    selectedhistoryMetricGroupsval,
    historyserviceNameSelect,
    historymetricGroupSelect,
    metricChange,
    selectedCrMetricsval,
    crMetricSelect,
    changeServiceStatus,
    targetDetails,
    selectedtargetDetailsval,
    crTargetSelect,
    crservice,
    crcomments,
    crrequestedBy,
    crcreatedBy,
    crtitle,
    crrequirment_Id,
    crnewValue,
    UpdateRequestedByValue,
    UpdateTitleValue,
    UpdateRequirementIdValue,
    UpdateCommentValue,
    SaveCrMetricDetails,
    SaveConfiguration,
    historyFrmCalVal,
    historyFrmValChange,
    historyToCalVal,
    historyToValChange,
    heatmapFromCalVal,
    heatmapFromCalValChange,
    heatmapToCalVal,
    heatmapToCalValChange,
    SaveConfig,
    UpdateCMRecord,
    CMDeleteMetric,
    newMetricName,
    newMetricDisplayName,
    newMetricGroup,
    newMetricEnvironment,
    onInputChange,
    SaveNewCMMetric,
    handledMetricEditChange,
    handledMetricGraphChange,
    onInputValidExpChange,
    onInputMinRangeChange,
    onInputMaxRangeChange,
    onInputIntervalChange,
    metricConfigMetricId,
    metricConfigGroupName,
    isEditableMetricConfig,
    isMetricGraphMetricConfig,
    minRangeMetricConfig,
    maxRangeMetricConfig,
    intervalMetricConfig,
    validationExprMetricConfig,
    onInputMetricConfig,
    saveMetricConfig,
    saveMetricConfigTab,
    NewMetricSavefunction,
    targetUpdateApprovalRequestData,
    reviewerCommentsChange,
    approveHmRequest,
    rejectHmRequest
}) => {

    const toastIdRef = useRef(null);
    let tmpDirector: any = '';
    const [rowData, setrowData] = useState(null);
    const [date1, setDate1] = useState(null);
    const [date2, setDate2] = useState(null);
    const [historyfromdate, sethistoryfromdate] = useState(null);
    const [historytodate, sethistorytodate] = useState(null);
    const [heatmapViewDialog, setheatmapViewDialog] = useState(false);
    const [newMetricDialog, setnewMetricDialog] = useState(false);
    const [type, settype] = useState('Query');
    const [slideVal, setslideVal] = useState(adminConfig.configValue);
    const [saveConfigBtn, setsaveConfigBtn] = useState(false);
    const [AddMetric, setAddMetric] = useState(addNewMetricObj);
    const [metricDetails, setmetricDetails] = useState(adminMetrics);
    const [metricConfigDialog, setmetricConfigDialog] = useState(false);
    const [isEdit, setisEdit] = useState(false);
    const [isGraph, setisGraph] = useState(false);
    const [minRange, setminRange] = useState(null);
    const [maxRange, setmaxRange] = useState(null);
    const [intervalConfig, setintervalConfig] = useState(null);
    const [validExp, setvalidExp] = useState(null);
    const [mMetricId, setmMetricId] = useState(null);
    const [mTargetId, setmTargetId] = useState(null);

    const setDirectorName = (Name: any) => {
        tmpDirector = Name;
    };
    const [value, setradiovalue] = useState(null);
    const ReplaceBRInNames = (Name: any) => {
        return Name.replace(new RegExp('<br/>', 'g'), '-');
    }
    const fromDateChange = (value) => {
    }
    const toDateChange = (value) => {

    }
    const historyFromDateChange = (value) => {
    }
    const historyToDateChange = (value) => {

    }
    const setValue = (value, checked) => {
        if (checked) {
            setradiovalue(value);
        }
        else {
            setradiovalue('');
        }
    }
    const hideheatmapViewDialog = () => {
        setheatmapViewDialog(false);
    }
    const viewdailog = (updatedon, value) => {
        heatmapViewPopData.Updatedon = updatedon;
        heatmapViewPopData.val = value;
        setheatmapViewDialog(true);
    }
    const viewAddNewMetric = () => {
        setnewMetricDialog(true);
    }
    const hidenewMetricDialog = () => {
        setnewMetricDialog(false);
    }
    const re = /^[0-9\b]+$/;
    const UpdateRequestedBy = (e) => {
        UpdateRequestedByValue(e);
    }
    const UpdateTitle = (e) => {
        UpdateTitleValue(e);
    }
    const UpdateRequirementId = (e) => {
        UpdateRequirementIdValue(e);
    }
    const UpdateComment = (e) => {
        UpdateCommentValue(e);
    }
    const historyFrmCalValChange = (e) => {
        historyFrmValChange(e);
    }
    const historyToCalValChange = (e) => {
        historyToValChange(e);
    }
    const heatmapFromCalValSelect = (e) => {
        heatmapFromCalValChange(e);
    }
    const heatmapToCalValSelect = (e) => {
        heatmapToCalValChange(e);
    }
    const typeChange = (e) => {
        settype(e);
    }
    const saveCrMetric = (e, metricChangeDetails) => {
        const metricObject = {
            type: type,
            metricValue: selectedCrMetricsval,
            requestedBy: crrequestedBy,
            title: crtitle,
            requirmentId: crrequirment_Id,
            comments: crcomments,
            metric: 0
        }
        if (metricObject.requestedBy == "" || metricObject.requestedBy == null) {
            toastIdRef.current = toast.error(
                renderToastifyMsg("Please enter Requested By",
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
            return false;
        }
        if (metricObject.type === 'Query') {
            if (metricObject.title == "" || metricObject.title == null) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Please enter Title",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
                return false;
            }
            if (metricObject.requirmentId == "" || metricObject.requirmentId == null) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Please enter Required Id",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
                return false;
            }
        }
        if (metricObject.type === 'Target') {
            if (selectedtargetDetailsval == "" || selectedtargetDetailsval == null || selectedtargetDetailsval == 'Undefined') {
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Please select Target",
                        'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
                return false;
            }
        }
        if (metricObject.comments == "" || metricObject.comments == null) {
            toastIdRef.current = toast.error(
                renderToastifyMsg("Please enter comments",
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
            return false;
        }
        SaveCrMetricDetails(metricObject, metricChangeDetails);
    }
    const getMtricGrpValue = (e) => {
        if (CMMetricGroups.length > 0) {
            return CMMetricGroups.find(function (o) { return o.key === e }).value;
        }
    }
    const getEnvValue = (e) => {
        if (CMEnvirnoments.length > 0) {
            return CMEnvirnoments.find(function (o) { return o.key === e }).value;
        }
    }

    const addNewMetricSave = (CMMetricGroupsDetails, CMEnvirnomentsDetails) => {
        if (newMetricName == "" || newMetricName == null) {
            toastIdRef.current = toast.error(
                renderToastifyMsg("Please enter Metric Name",
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
            return false;
        }
        else if (newMetricDisplayName == "" || newMetricDisplayName == null) {
            toastIdRef.current = toast.error(
                renderToastifyMsg("Please enter Metric Display Name",
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
            return false;
        }
        else if (newMetricGroup == "" || newMetricGroup == null || newMetricGroup == 'undefined') {
            toastIdRef.current = toast.error(
                renderToastifyMsg("Please select any Metric Group",
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
            return false;
        }
        else if (newMetricEnvironment == "" || newMetricEnvironment == null || newMetricEnvironment == 'undefined') {
            toastIdRef.current = toast.error(
                renderToastifyMsg("Please select any Metric Environment",
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
            return false;
        }
        NewMetricSavefunction(CMMetricGroupsDetails, CMEnvirnomentsDetails);
        hidenewMetricDialog();

    }

    const getHeatmapServiceName = (e) => {
        return e.name;
    }
    const getHistoryServName = (e) => {
        return e.name;
    }
    const changeSliderValue = (e) => {
        setslideVal(e);
        setsaveConfigBtn(true);
        adminConfig.configValue = e;
    }

    const handledEditSwitchChange = (e, metricgrpName, metricid) => {
        handledMetricEditChange(e, metricgrpName, metricid);
    }
    const editMetricConfiguration = (rowData) => {
        setmMetricId(rowData.metricId);
        setmTargetId(rowData.targetId);
        setisEdit(rowData.isEditable);
        setisGraph(rowData.isMetricGraph);
        setminRange(rowData.minRange);
        setmaxRange(rowData.maxRange);
        setintervalConfig(rowData.interval);
        setvalidExp(rowData.validationExpr);
        setmetricConfigDialog(true);
    }
    const hideMetricConfigDialog = () => {
        setmMetricId('');
        setmTargetId('');
        setisEdit(false);
        setisGraph(false);
        setminRange('');
        setmaxRange('');
        setintervalConfig('');
        setvalidExp('');
        setmetricConfigDialog(false);
    }
    const inputMetricConfig = (e, name) => {
        if (name === 'isEdit') {
            setisEdit(e);
        }
        else if (name === 'isGraph') {
            setisGraph(e);
        }
        else if (name === 'minRange') {
            setminRange(e.target.value);
        }
        else if (name === 'maxRange') {
            setmaxRange(e.target.value);
        }
        else if (name === 'intervalConfig') {
            setintervalConfig(e.target.value)
        }
        else if (name === 'validExp') {
            setvalidExp(e.target.value)
        }
    }
    const addMetricConfig = (metricId, isEditable, isMetricgraph, targetId, minRange, maxRange, interval, validationExpr) => {
        let serviceId = selectedServicesval.id;
        saveMetricConfigTab(serviceId, metricId, isEditable, isMetricgraph, targetId, minRange, maxRange, interval, validationExpr);
        hideMetricConfigDialog();
    }
   
    return (
        <React.Fragment>
            {(isAdmin === true) ?
                <div>
                    <Nav tabs style={{ borderBottom: '0' }}>
                        {isArrayWithLength(tabslist) && tabslist.map((dh, index) => (
                            <React.Fragment key={index}>
                                {
                                    (tabname !== dh.Name)
                                        ?
                                        <Fragment>
                                            <NavItem style={{ color: 'rgba(0,0,0,0.54)' }}>
                                                <NavLink
                                                    className={classnames({ active: activeDailyDataTab === dh.Name })}
                                                    onClick={() => {
                                                        DailyDataTabstoggle(dh.Name);
                                                    }}
                                                >
                                                    {dh.DisplayName}
                                                    {settabname(dh.Name)}
                                                </NavLink>
                                            </NavItem>
                                        </Fragment>
                                        : <React.Fragment></React.Fragment>
                                }
                            </React.Fragment>

                        ))}
                    </Nav>
                    <TabContent activeTab={activeDailyDataTab}>
                        {isArrayWithLength(tabslist) && tabslist.map((dh, key) => (
                            <React.Fragment key={key}>
                                {
                                    (tabname !== dh.Name)
                                        ?
                                        <Fragment>
                                            <TabPane tabId={dh.Name}>
                                                {(activeDailyDataTab === 'serviceConfiguration')
                                                    ?
                                                    <React.Fragment>

                                                        {isArrayWithLength(services) && services.map((dhd: IServiceGroup, index) => (
                                                            <React.Fragment key={index}>
                                                                {
                                                                    (tmpDirector !== dhd.directorName)
                                                                        ? <div style={{ width: '40%', float: 'left', marginRight: '10%' }}>
                                                                            {setDirectorName(dhd.directorName)}
                                                                            <div>
                                                                                <br />
                                                                                <Card body style={{ backgroundColor: 'darksalmon', textAlign: 'center' }}>
                                                                                    <CardTitle>
                                                                                        <Row>
                                                                                            <Col>
                                                                                                <span className="homepagetogglestyle" >{ReplaceBRInNames(dhd.directorName)} </span>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </CardTitle>
                                                                                </Card>
                                                                            </div>
                                                                            <div style={{ paddingTop: '4px' }}>
                                                                                <Card body>
                                                                                    {isArrayWithLength(services) && services.map((dhTGS: IServiceGroup, key4) => (
                                                                                        <React.Fragment key={key4}>
                                                                                            {
                                                                                                (dhd.directorName == dhTGS.directorName)
                                                                                                    ?
                                                                                                    <div className='ServiceNames'>
                                                                                                        <Row>
                                                                                                            <Col lg='7' style={{ color: '#212529' }}><span className='ServiceNameText'> {dhTGS.serviceName}</span></Col>

                                                                                                            <Col lg='2 my-auto'>
                                                                                                                <div className="switchpadding">
                                                                                                                    <Switch onChange={e => changeServiceStatus(e, dhTGS.isActive, dhTGS.serviceId)} checked={dhTGS.isActive} boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                                                                                                                        activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                                                                                                                        height={20}
                                                                                                                        width={35}
                                                                                                                        checkedIcon={false} />
                                                                                                                </div>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </div>
                                                                                                    : <React.Fragment></React.Fragment>
                                                                                            }
                                                                                        </React.Fragment>
                                                                                    ))}
                                                                                </Card>
                                                                            </div>
                                                                            <br />
                                                                        </div>
                                                                        : <React.Fragment></React.Fragment>
                                                                }
                                                            </React.Fragment>
                                                        ))}

                                                    </React.Fragment>
                                                    : (activeDailyDataTab === 'metricConfiguration')
                                                        ? <React.Fragment>
                                                            <br />
                                                            <div>
                                                                <Dropdown id={'serviceNames'} value={selectedServicesval} options={serviceNames} onChange={(e) => serviceNameSelect(e.value)} optionLabel="name" placeholder="Service" />
                                                            </div>
                                                            <React.Fragment>
                                                                {isArrayWithLength(adminMetrics) ?
                                                                    <div className="mctablediv">
                                                                        <table className="mctable">
                                                                            <thead className="mchead">
                                                                                <tr>
                                                                                    <th className="mcamgroup">Metric Group</th>
                                                                                    <th className="mcaname">Metric Name</th>
                                                                                    <th className="mcaisedit">Is Editable</th>
                                                                                    <th className="mcaisgraph">Is Graph Available</th>
                                                                                    <th className="mcatraget">Target <img title='To change the Target Value please go to CR TRACKING tab' src={info_icon} style={{ width: '18px' }} /></th>
                                                                                    <th className="mcaminrange">Min Range</th>
                                                                                    <th className="mcamaxrange">Max Range</th>
                                                                                    <th className="mcainterval">Interval</th>
                                                                                    <th className="mcavalidaexp">Validation Expression</th>
                                                                                    <th></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {isArrayWithLength(adminMetrics) && adminMetrics.map((dhd, index) => (
                                                                                    <React.Fragment key={index}>
                                                                                        {isArrayWithLength(dhd.metricDetails) && dhd.metricDetails.map((dhTGS, key4) => (
                                                                                            <React.Fragment key={key4}>
                                                                                                {
                                                                                                    <React.Fragment>
                                                                                                        <tr>
                                                                                                            {key4 == 0 && <td className="mctd" rowSpan={dhd.metricGroupLength}>{dhd.metricGroupName}</td>}
                                                                                                            <td className="mctd">{dhTGS["metricName"]}</td>
                                                                                                            <td className="mctd">
                                                                                                                <Switch onChange={e => handledEditSwitchChange(e, dhTGS["metricGroupName"], dhTGS["metricId"])} disabled={true} checked={dhTGS["isEditable"]} boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                                                                                                                    activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                                                                                                                    height={20}
                                                                                                                    width={35}
                                                                                                                    checkedIcon={false} />
                                                                                                            </td>
                                                                                                            <td className="mctd"><Switch onChange={e => handledMetricGraphChange(e, dhTGS["metricGroupName"], dhTGS["metricId"], selectedServicesval)} disabled={true} checked={dhTGS["isMetricGraph"]} boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                                                                                                                activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                                                                                                                height={20}
                                                                                                                width={35}
                                                                                                                checkedIcon={false} /></td>
                                                                                                            <td className="mctd">
                                                                                                                {/*<InputText id="targetName" value={dhTGS["targetName"] ? dhTGS["targetName"] : ""} disabled onChange={(e) => onInputChange(e, 'targetName')} />*/}
                                                                                                                <span>{dhTGS["targetName"] ? dhTGS["targetName"] : ""}</span>
                                                                                                            </td>
                                                                                                            <td className="mctd">
                                                                                                                {/*<InputText id="minRange" value={dhTGS["minRange"] ? dhTGS["minRange"] : ""} disabled={!dhTGS["isMetricGraph"] ? true : false} onChange={(e) => onInputMinRangeChange(e, dhTGS["metricGroupName"], dhTGS["metricId"])} />*/}
                                                                                                                <span>{dhTGS["minRange"] ? dhTGS["minRange"] : ""}</span>
                                                                                                            </td>
                                                                                                            <td className="mctd">
                                                                                                                {/*<InputText id="maxRange" value={dhTGS["maxRange"] ? dhTGS["maxRange"] : ""} disabled={!dhTGS["isMetricGraph"] ? true : false} onChange={(e) => onInputMaxRangeChange(e, dhTGS["metricGroupName"], dhTGS["metricId"])} />*/}
                                                                                                                <span>{dhTGS["maxRange"] ? dhTGS["maxRange"] : ""}</span>
                                                                                                            </td>
                                                                                                            <td className="mctd">
                                                                                                                {/*<InputText id="interval" value={dhTGS["interval"] ? dhTGS["interval"] : ""} disabled={!dhTGS["isMetricGraph"] ? true : false} onChange={(e) => onInputIntervalChange(e, dhTGS["metricGroupName"], dhTGS["metricId"])} />*/}
                                                                                                                <span>{dhTGS["interval"] ? dhTGS["interval"] : ""}</span>
                                                                                                            </td>
                                                                                                            <td className="mctd">
                                                                                                                {/*<InputText id="validationExpr" value={dhTGS["validationExpr"] ? dhTGS["validationExpr"] : ""} onChange={(e) => onInputValidExpChange(e, dhTGS["metricGroupName"], dhTGS["metricId"])} />*/}
                                                                                                                <span>{dhTGS["validationExpr"] ? dhTGS["validationExpr"] : ""}</span>
                                                                                                            </td>
                                                                                                            <td><span >
                                                                                                                <FontAwesomeIcon title='Click to edit the Row' className="bluecoloricons Iconsstyles" icon={faPencilAlt} onClick={(e) => editMetricConfiguration(dhTGS)} />
                                                                                                            </span> </td>
                                                                                                        </tr>
                                                                                                    </React.Fragment>
                                                                                                }
                                                                                            </React.Fragment>
                                                                                        ))}

                                                                                    </React.Fragment>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    : <React.Fragment>
                                                                    </React.Fragment>
                                                                }
                                                            </React.Fragment>
                                                        </React.Fragment>
                                                        : (activeDailyDataTab === 'crTracking')
                                                            ? <React.Fragment>
                                                                <div>
                                                                    <div style={{ width: '100% !important', paddingTop: '10px' }}>
                                                                        <div className="crservicedrop"><Dropdown id={'crServiceNames'} value={selectedCrServicesval} options={serviceNames} onChange={(e) => serviceNameSelectCr(e.value)} optionLabel="name" placeholder="Service" /></div>
                                                                        <div className="crmetricdrop"><Dropdown id={'metricGroups'} value={selectedMetricGroupsval} options={metricGroups} onChange={(e) => metricGroupSelect(e.value)} optionLabel="name" placeholder="Metric Group" /></div>
                                                                        <div className="crloadmorebtn"><Button disabled={isNullOrUndefined(selectedCrServicesval) || isNullOrUndefined(selectedMetricGroupsval)} label="LOAD METRICS" className="loadmetrics" onClick={() => loadMetrics()} /></div>
                                                                    </div>
                                                                    <div className="crmetricdetails">
                                                                        {metricChange.length > 0 &&
                                                                            <table className="crdatatable">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td style={{ width: '10%' }}>Metric Name:</td>
                                                                                        <td style={{ width: '49%' }}>
                                                                                            <Dropdown id={'metrics'} value={selectedCrMetricsval} options={metricChange} onChange={(e) => crMetricSelect(e.value)} optionLabel="value" />
                                                                                        </td>
                                                                                        <td style={{ width: '7%' }}>Type:</td>
                                                                                        <td>
                                                                                            {/*<RadioButton value='Query' name="source" onChange={(e) => setValue(e.value, e.checked)} />Query &nbsp;&nbsp;&nbsp;*/}
                                                                                            {/*<RadioButton value='Target' name="source" onChange={(e) => setValue(e.value, e.checked)} />Target*/}

                                                                                            <RadioButton inputId="type1" name="source" value="Query" onChange={(e) => typeChange(e.value)} checked={type === 'Query'} /> Query &nbsp;&nbsp;&nbsp;
                                                                                            <RadioButton inputId="type2" name="source" value="Target" onChange={(e) => typeChange(e.value)} checked={type === 'Target'} /> Target
                                                                                        </td>
                                                                                    </tr>

                                                                                    <tr>
                                                                                        <td style={{ width: '10%' }}>Requested By:</td>
                                                                                        <td style={{ width: '49%' }}>
                                                                                            <InputText id="requestedBy" value={crrequestedBy} onChange={(e) => UpdateRequestedBy(e)} /><span className="inputRequired">*</span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    {type == "Query" &&
                                                                                        <tr>
                                                                                            <td>Title:</td>
                                                                                            <td style={{ width: '49%' }}>
                                                                                                <InputText id="title" value={crtitle} onChange={(e) => UpdateTitle(e)} /><span className="inputRequired">*</span>

                                                                                            </td>
                                                                                            <td style={{ width: '7%' }}>Requirment Id:</td>
                                                                                            <td>
                                                                                                <InputText id="requestedID" value={crrequirment_Id} onChange={(e) => UpdateRequirementId(e)} /><span className="inputRequired">*</span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    }
                                                                                    {type != "Query" &&
                                                                                        <tr>
                                                                                            <td>New Value:</td>
                                                                                            <td style={{ width: '49%' }}>
                                                                                                <div>
                                                                                                    <Dropdown id={'targetDetails'} value={selectedtargetDetailsval} options={targetDetails} onChange={(e) => crTargetSelect(e.value)} optionLabel="name" placeholder="Target" /><span className="inputRequired">*</span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    }
                                                                                    <tr>
                                                                                        <td style={{ width: '10%' }}>Comment:</td>
                                                                                        <td style={{ width: '49%' }}>
                                                                                            <InputTextarea value={crcomments} onChange={(e) => UpdateComment(e)} rows={5} cols={100} /><span className="inputRequired">*</span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td></td>
                                                                                        <td><Button label="SAVE" className="loadmetrics" onClick={(e) => saveCrMetric(e, metricChange)} /></td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                            : (activeDailyDataTab === 'heatmapTargetApproval')
                                                                ? <React.Fragment>
                                                                    <div>
                                                                        <div>
                                                                            <table className="historydtable">
                                                                                <thead className="historythead">
                                                                                    <tr>
                                                                                        <th className="hmServiceName"> <span>Service Name</span></th>
                                                                                        <th className="hmName"> <span>Heatmap Name</span></th>
                                                                                        <th className="hmJustify"> <span>Justification</span></th>
                                                                                        <th className="hmOriginalTarget"> <span>Original Target</span></th>
                                                                                        <th className="hmNewTarget"> <span>New Target</span></th>
                                                                                        <th className="hmReviewComts"> <span>Reviewer's Comment</span></th>
                                                                                        <th className="hmbtns"> <span></span></th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {isArrayWithLength(targetUpdateApprovalRequestData) && targetUpdateApprovalRequestData.map((his, key) => (
                                                                                        <tr key={key}>
                                                                                            <td className="cmtd">{his.serviceName}</td>
                                                                                            <td className="cmtd">{his.viewName}</td>
                                                                                            <td className="cmtd">{his.justification}</td>
                                                                                            <td className="cmtd">{his.orginalTarget}</td>
                                                                                            <td className="cmtd">{his.newTarget}</td>
                                                                                            <td className="cmtd">
                                                                                                <textarea placeholder='Enter Justification/Comments' className='form-control' value={his.reviewerComments} onChange={(e) => reviewerCommentsChange(e, his)} /></td>
                                                                                            <td className="cmtd">
                                                                                                <Button label="Approve" onClick={(e) => approveHmRequest(e, his)} className="approveBtn" />
                                                                                                <Button label="Reject" onClick={(e) => rejectHmRequest(e, his)} className="rejectBtn" />
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                                : (activeDailyDataTab === 'capacityManagement')
                                                                    ? < React.Fragment >
                                                                        <React.Fragment>
                                                                            {isArrayWithLength(CMData) ?
                                                                                <table className="cmtable">
                                                                                    <thead className="cmthead">
                                                                                        <tr>
                                                                                            <th className="cmmetricname">Metric Name</th>
                                                                                            <th className="cmdisplayname">Metric Display Name</th>
                                                                                            <th className="cmmetricgroup">Metric Group</th>
                                                                                            <th className="cmenvironment">Environment</th>
                                                                                            <th className="cmlastth"></th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {isArrayWithLength(CMData) && CMData.map((his, key) => (
                                                                                            <tr key={key}>
                                                                                                <td className="cmtd">{his.cmMetricName}</td>
                                                                                                <td className="cmtd">{his.cmMetricDisplayName}</td>
                                                                                                <td className="cmtd"><Dropdown id={'cmmetricGroups'} value={his.cmMetricGroupId["value"]} options={CMMetricGroups} onChange={(e) => cmmetricGroupSelect(e)} optionLabel="value" name={his.cmMetricName} /></td>
                                                                                                <td className="cmtd"><Dropdown id={'cmenvironment'} value={his.envId["value"]} options={CMEnvirnoments} onChange={(e) => cmEnvironmentSelect(e)} optionLabel="value" name={his.cmMetricName} /></td>
                                                                                                <td className="cmtdbtn">
                                                                                                    <Button label="Update" onClick={(e) => UpdateCMRecord(his)} className="cmupdate" />
                                                                                                    <Button label="Delete" onClick={(e) => CMDeleteMetric(his)} className="cmdelete" />
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                        <tr>
                                                                                            <Button label="Add New Metric" onClick={(e) => viewAddNewMetric()} className="cmAddNew" />
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                                : <React.Fragment>
                                                                                </React.Fragment>
                                                                            }
                                                                        </React.Fragment>

                                                                        <React.Fragment>
                                                                            {isArrayWithLength(CMScoreCardData) ?
                                                                                <div>
                                                                                    <h4>Score card details:</h4>
                                                                                    <table className="cmscoretable">
                                                                                        <thead className="cmscorehead">
                                                                                            <tr>
                                                                                                <th className="cmscoremetric">Metric Group Name</th>
                                                                                                <th className="cmscorered">Red</th>
                                                                                                <th className="cmscoreyellow">Yellow</th>
                                                                                                <th className="cmscoregreen">Green</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {isArrayWithLength(CMScoreCardData) && CMScoreCardData.map((his, key) => (
                                                                                                <tr>
                                                                                                    <td className="cmtd">{his["MetricGroup"]}</td>
                                                                                                    <td className="cmtd">{his["Red"]}</td>
                                                                                                    <td className="cmtd">{his["Yellow"]}</td>
                                                                                                    <td className="cmtd">{his["Green"]}</td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                                : <React.Fragment>
                                                                                </React.Fragment>
                                                                            }
                                                                        </React.Fragment>

                                                                    </React.Fragment>
                                                                    : (activeDailyDataTab === 'otherConfiguration')
                                                                        ? <React.Fragment>
                                                                            <div className="sliderprdiv">
                                                                                <div className="sliderName">{adminConfig.configName}</div>
                                                                                <div className="slidercls">
                                                                                    <Slider className="slider" min={0} max={78} value={slideVal} onChange={(e) => changeSliderValue(e.value)} />
                                                                                </div>
                                                                                <div className="sliderval">{adminConfig.configValue}</div>
                                                                                <div><Button disabled={!saveConfigBtn} label="SAVE" className="sliderSave" onClick={() => SaveConfig(adminConfig.configValue)} /></div>
                                                                            </div>
                                                                        </React.Fragment>
                                                                        : (activeDailyDataTab === 'historyData')
                                                                            ? <React.Fragment>
                                                                                <div>
                                                                                    <h3> {getHistoryServName(selectedhistoryServicesval)} history data of Metrics</h3>
                                                                                    <table className="historydatatable">
                                                                                        <tbody>
                                                                                            <tr className="historydatatr">
                                                                                                <td className="historydataservice"><Dropdown id={'historyServiceNames'} value={selectedhistoryServicesval} options={serviceNames} onChange={(e) => historyserviceNameSelect(e.value)} optionLabel="name" /></td>
                                                                                                <td className="historydatagroups"><Dropdown id={'historymetricGroups'} value={selectedhistoryMetricGroupsval} options={metricGroups} onChange={(e) => historymetricGroupSelect(e.value)} optionLabel="name" /></td>
                                                                                                <td className="historydatafrom">
                                                                                                    {/*<Calendar value={historyfromdate} onChange={(e) => historyFromDateChange(e.value)} hideOnDateTimeSelect={true} showIcon></Calendar>*/}
                                                                                                    <Calendar value={historyFrmCalVal} onChange={(e) => historyFrmCalValChange(e.value)} hideOnDateTimeSelect={true} showIcon></Calendar>
                                                                                                </td>
                                                                                                <td className="historydatato">
                                                                                                    {/*<Calendar value={historytodate} minDate={historyfromdate} onChange={(e) => historyToDateChange(e.value)} hideOnDateTimeSelect={true} showIcon></Calendar>*/}
                                                                                                    <Calendar value={historyToCalVal} onChange={(e) => historyToCalValChange(e.value)} hideOnDateTimeSelect={true} showIcon></Calendar>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                    <div>
                                                                                        <table className="historydtable">
                                                                                            <thead className="historythead">
                                                                                                <tr>
                                                                                                    <th className="hsitorymetric"> <span>Metric Name</span></th>
                                                                                                    <th className="hsitoryenv"> <span>Environment</span></th>
                                                                                                    <th className="historyweek"> <span>Week Name</span></th>
                                                                                                    <th className="historymodified"> <span>Modified By</span></th>
                                                                                                    <th className="historyprv"> <span>Previous Value</span></th>
                                                                                                    <th className="historycurrent"> <span>Current Value</span></th>
                                                                                                    <th className="historymodifby"> <span>Modified Date</span></th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {isArrayWithLength(editHistory) && editHistory.map((his, key) => (
                                                                                                    <tr>
                                                                                                        <td className="historytdmetric">{his.metricName}</td>
                                                                                                        <td className="historytd">{his.environment}</td>
                                                                                                        <td className="historytd">{his.weekName}</td>
                                                                                                        <td className="historytd">{his.updatedBy}</td>
                                                                                                        <td className="historytd">{his.prevValue}</td>
                                                                                                        <td className="historytd">{his.currentValue}</td>
                                                                                                        <td className="historytd">{his.updatedTime}</td>
                                                                                                    </tr>
                                                                                                ))}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </React.Fragment>
                                                                            : (activeDailyDataTab === 'heatMapHistoryData')
                                                                                ? <React.Fragment>
                                                                                    <div>
                                                                                        <h3>{getHeatmapServiceName(selectedHeatmapServiceval)} KUSTO Query History Data</h3>
                                                                                        <div>
                                                                                            <table className="heatmaptab">
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td className="heatmapsrv">
                                                                                                            <Dropdown id={'heatmapServiceNames'} value={selectedHeatmapServiceval} options={serviceNames} onChange={(e) => heatmapServiceNameSelect(e.value)} optionLabel="name" />
                                                                                                        </td>
                                                                                                        <td className="heatmapfrm">
                                                                                                            {/*<Calendar value={date1} onChange={(e) => fromDateChange(e.value)} hideOnDateTimeSelect={true}></Calendar>*/}
                                                                                                            <Calendar value={heatmapFromCalVal} onChange={(e) => heatmapFromCalValSelect(e.value)} hideOnDateTimeSelect={true} showIcon></Calendar>
                                                                                                        </td>
                                                                                                        <td className="heatmapto">
                                                                                                            {/*<Calendar showTime hourFormat="24" value={date2} minDate={date1} onChange={(e) => toDateChange(e.value)} hideOnDateTimeSelect={true}></Calendar>*/}
                                                                                                            <Calendar value={heatmapToCalVal} onChange={(e) => heatmapToCalValSelect(e.value)} hideOnDateTimeSelect={true} showIcon></Calendar>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                        <div className="heatmapparentdiv">
                                                                                            <table className="heatmaptable">
                                                                                                <thead className="heatmapthead">
                                                                                                    <tr className="heatmaptr">
                                                                                                        <th><span>View Name</span></th>
                                                                                                        <th><span>Source</span></th>
                                                                                                        <th><span>Old KUSTO DB</span></th>
                                                                                                        <th><span>New KUSTO DB</span></th>
                                                                                                        <th><span>Old Server Name</span></th>
                                                                                                        <th><span>New Server Name</span></th>
                                                                                                        <th><span>Old Fix Query</span></th>
                                                                                                        <th><span>New Fix Query</span></th>
                                                                                                        <th><span>Old Val Query</span></th>
                                                                                                        <th><span>New Val Query</span></th>
                                                                                                        <th><span>Old Val1 Label</span></th>
                                                                                                        <th><span>New Val1 Label</span></th>
                                                                                                        <th><span>Old Val2 Label</span></th>
                                                                                                        <th><span>New Val2 Label</span></th>
                                                                                                        <th><span>Old Totals Label</span></th>
                                                                                                        <th><span>New Totals Label</span></th>
                                                                                                        <th><span>Old Coloring Logic</span></th>
                                                                                                        <th><span>New Coloring Logic</span></th>
                                                                                                        <th><span>Old No. of Decimals</span></th>
                                                                                                        <th><span>New No. of Decimals</span></th>
                                                                                                        <th><span>User</span></th>
                                                                                                        <th><span>Date</span></th>
                                                                                                        <th><span>IsMserView</span></th>
                                                                                                        <th><span>MserView Updated by</span></th>
                                                                                                        <th><span>MserView Updated Date</span></th>
                                                                                                        <th><span>IsMonthlyAvailability</span></th>
                                                                                                        <th><span>MonthlyAvailability Updated by</span></th>
                                                                                                        <th><span>MonthlyAvailability Updated Date</span></th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {isArrayWithLength(heatMapHistoryData) && heatMapHistoryData.map((his, key) => (
                                                                                                        <tr>
                                                                                                            <td className="heatmaphistd">{his.ViewName} </td>
                                                                                                            <td className="heatmaphistd">{his.NewSource}</td>
                                                                                                            <td className="heatmaphistd">{his.OldDBName}</td>
                                                                                                            <td className="heatmaphistd">{his.NewDBName}</td>
                                                                                                            <td className="heatmaphistd">{his.OldServerName}</td>
                                                                                                            <td className="heatmaphistd">{his.NewServerName}</td>
                                                                                                            <td className="heatmaphistd"><a className="hyptextdecor" onClick={() => viewdailog(his.Updatedon, his.OldQFFixedCol)}>View</a></td>
                                                                                                            <td className="heatmaphistd"><a className="hyptextdecor" onClick={() => viewdailog(his.Updatedon, his.NewQFFixedCol)}>View</a></td>
                                                                                                            <td className="heatmaphistd"><a className="hyptextdecor" onClick={() => viewdailog(his.Updatedon, his.OldQForVal)}>View</a></td>
                                                                                                            <td className="heatmaphistd"><a className="hyptextdecor" onClick={() => viewdailog(his.Updatedon, his.NewQForVal)}>View</a></td>
                                                                                                            <td className="heatmaphistd">{his.oldval1Label}</td>
                                                                                                            <td className="heatmaphistd">{his.val1Label}</td>
                                                                                                            <td className="heatmaphistd">{his.oldval2Label}</td>
                                                                                                            <td className="heatmaphistd">{his.val2Label}</td>
                                                                                                            <td className="heatmaphistd">{his.oldtotalsLabel}</td>
                                                                                                            <td className="heatmaphistd">{his.totalsLabel}</td>
                                                                                                            <td className="heatmaphistd">{his.OldColoringLogicText}</td>
                                                                                                            <td className="heatmaphistd">{his.NewColoringLogicText}</td>
                                                                                                            <td className="heatmaphistd">{his.OldNoOfDecimalText}</td>
                                                                                                            <td className="heatmaphistd">{his.NewNoOfDecimalText}</td>
                                                                                                            <td className="heatmaphistd">{his.UpdatedBy}</td>
                                                                                                            <td className="heatmaphistd">{his.Updatedon} </td>
                                                                                                            <td className="heatmaphistd"> {his.OldIsMserView == true ? 'Yes' : 'No'}</td>
                                                                                                            <td className="heatmaphistd">{his.OldMserViewUpdatedBy}</td>
                                                                                                            <td className="heatmaphistd">{his.OldMserViewUpdatedDate} </td>
                                                                                                            <td className="heatmaphistd"> {his.OldIsMonthlyAvailability == true ? 'Yes' : 'No'}</td>
                                                                                                            <td className="heatmaphistd">{his.OldMonthlyAvailabilityUpdatedBy}</td>
                                                                                                            <td className="heatmaphistd">{his.OldMonthlyAvailabilityUpdatedDate} </td>
                                                                                                        </tr>
                                                                                                    ))}
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                                : (activeDailyDataTab === 'missingHeatmapData')
                                                                                    ? <React.Fragment>
                                                                                        <br />
                                                                                        {isArrayWithLength(missingViewInfo) ?
                                                                                            <table className="missingdatatable">
                                                                                                <thead className="missingdatatablehead">
                                                                                                    <tr>
                                                                                                        <th className="missingdatatableth">Service Display Name</th>
                                                                                                        <th className="missingdatatableth">View Name</th>
                                                                                                        <th className="missingdatatableth">Date</th>
                                                                                                        <th className="missingdatatableth">Source</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {isArrayWithLength(missingViewInfo) && missingViewInfo.map((his, key) => (
                                                                                                        <tr key={key}>
                                                                                                            <td className="missingdatatabletd">{his.serviceDisplayName}</td>
                                                                                                            <td className="missingdatatabletd">{his.viewName}</td>
                                                                                                            <td className="missingdatatabletd">{his.missingDate}</td>
                                                                                                            <td className="missingdatatabletd">{his.source}</td>
                                                                                                        </tr>
                                                                                                    ))}
                                                                                                </tbody>
                                                                                            </table>
                                                                                            : <React.Fragment>
                                                                                            </React.Fragment>
                                                                                        }
                                                                                    </React.Fragment>
                                                                                    : <React.Fragment> </React.Fragment>
                                                }
                                            </TabPane>
                                        </Fragment>
                                        : <React.Fragment></React.Fragment>
                                }
                                {settabname(dh.Name)}
                            </React.Fragment>
                        ))}
                    </TabContent>

                    <Modal show={heatmapViewDialog} onHide={hideheatmapViewDialog} size='lg' backdrop='static'>
                        <Modal.Header className="viewHeapmap" closeButton>
                            <div style={{ width: '100%' }}>
                                <Row>
                                    <Col lg='12'>
                                        <span></span>
                                    </Col>
                                </Row>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="confirmation-content">
                                <table className="viewpoptable">
                                    <thead>
                                        <tr>
                                            <th colSpan={2} className="viewpopthead">Query Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="heatviewpoptd" style={{ height: '10px' }}><b>Date</b></td>
                                            <td className="heatviewpoptd" style={{ height: '10px' }}><span>{heatmapViewPopData.Updatedon}</span></td>
                                        </tr>
                                        <tr>
                                            <td className="heatviewpoptd" style={{ height: '10px' }}><b>Query</b></td>
                                            <td className="heatviewpoptd"><span>{heatmapViewPopData.val}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={newMetricDialog} onHide={hidenewMetricDialog} size='lg' backdrop='static'>
                        <Modal.Header className="viewHeapmap" closeButton>
                            <div style={{ width: '100%' }}>
                                <Row>
                                    <Col lg='12'>
                                        <span></span>
                                    </Col>
                                </Row>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="confirmation-content">
                                <table className="viewpoptable">
                                    <thead>
                                        <tr>
                                            <th colSpan={2} className="viewpopthead">Add New Metrics</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="heatviewpoptd" style={{ height: '10px' }}><b>Metric Name</b></td>
                                            <td className="heatviewpoptd" style={{ height: '10px' }}>
                                                <InputText id="newMetricName" value={newMetricName} onChange={(e) => onInputChange(e, 'cmNewMetricName')} /><span className="inputRequired">*</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="heatviewpoptd" style={{ height: '10px' }}><b>Metric Display Name</b></td>
                                            <td className="heatviewpoptd"> <InputText id="newMetricDisplayName" value={newMetricDisplayName} onChange={(e) => onInputChange(e, 'cmNewMetricDisplayName')} /><span className="inputRequired">*</span></td>
                                        </tr>
                                        <tr>
                                            <td className="heatviewpoptd" style={{ height: '10px' }}><b>Metric Group</b></td>
                                            <td className="heatviewpoptd"><Dropdown id={'cmNewMetricGroups'} value={newMetricGroup} options={CMMetricGroups} onChange={(e) => onInputChange(e, "newMetricGroup")} optionLabel="value" /></td>
                                        </tr>
                                        <tr>
                                            <td className="heatviewpoptd" style={{ height: '10px' }}><b>Environment</b></td>
                                            <td className="heatviewpoptd"><Dropdown id={'cmNewEnvironment'} value={newMetricEnvironment} options={CMEnvirnoments} onChange={(e) => onInputChange(e, "newMetricEnvironment")} optionLabel="value" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button label="Save" onClick={(e) => addNewMetricSave(CMMetricGroups, CMEnvirnoments)} className="updateMetricConfig" />
                            <Button label="Cancel" onClick={(e) => hidenewMetricDialog()} className="cancelMetricConfig" />
                        </Modal.Footer>
                    </Modal>

                    <Modal show={metricConfigDialog} onHide={hideMetricConfigDialog} size='lg' backdrop='static'>
                        <Modal.Header className="viewHeapmap" closeButton>
                            <div style={{ width: '100%' }}>
                                <Row>
                                    <Col lg='12'>
                                        <span></span>
                                    </Col>
                                </Row>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="confirmation-content">
                                <table className="viewpoptable">
                                    <thead>
                                        <tr>
                                            <th colSpan={2} className="viewpopthead">EDIT METRIC CONFIGURATION</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr><td className="metricconfigpoptd" style={{ height: '10px' }}><b>Is Editable</b></td><td className="metricconfigpoptd" style={{ height: '10px' }}>
                                            <Switch onChange={e => inputMetricConfig(e, "isEdit")} checked={isEdit} boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                                                activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                                                height={20}
                                                width={35}
                                                checkedIcon={false} />
                                        </td></tr>
                                        <tr><td className="metricconfigpoptd" style={{ height: '10px' }}><b>Is Graph Available</b></td><td className="metricconfigpoptd" style={{ height: '10px' }}>
                                            <Switch onChange={e => inputMetricConfig(e, "isGraph")} checked={isGraph} boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                                                activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                                                height={20}
                                                width={35}
                                                checkedIcon={false} />
                                        </td></tr>
                                        <tr>
                                            <td className="metricconfigpoptd" style={{ height: '10px' }}><b>Min Range</b></td>
                                            <td className="metricconfigpoptd" style={{ height: '10px' }}>
                                                <InputText id="editMinRange" value={minRange} onChange={(e) => inputMetricConfig(e, "minRange")} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="metricconfigpoptd" style={{ height: '10px' }}><b>Max Range</b></td>
                                            <td className="metricconfigpoptd"> <InputText id="editMaxRanage" value={maxRange} onChange={(e) => inputMetricConfig(e, "maxRange")} /></td>
                                        </tr>
                                        <tr>
                                            <td className="metricconfigpoptd" style={{ height: '10px' }}><b>Interval</b></td>
                                            <td className="metricconfigpoptd"><InputText id="editInterval" value={intervalConfig} onChange={(e) => inputMetricConfig(e, "intervalConfig")} /></td>
                                        </tr>
                                        <tr>
                                            <td className="metricconfigpoptd" style={{ height: '10px' }}><b>Valid Expression</b></td>
                                            <td className="metricconfigpoptd"><InputText id="editValiExp" value={validExp} onChange={(e) => inputMetricConfig(e, "validExp")} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button label="Save" onClick={(e) => addMetricConfig(mMetricId, isEdit, isGraph, mTargetId, minRange, maxRange, intervalConfig, validExp)} className="updateMetricConfig" />
                            <Button label="Cancel" onClick={(e) => hideMetricConfigDialog()} className="cancelMetricConfig" />
                        </Modal.Footer>
                    </Modal>

                </div >
                : <React.Fragment></React.Fragment>
            }
            {(isAdmin === false) ?
                <div className="adminrestriction"><h2>You do not have access to Admin page</h2></div>
                : <React.Fragment></React.Fragment>
            }
        </React.Fragment >
    )
});

AdminSettingsTable.displayName = 'AdminSettingsTable';
export default AdminSettingsTable;