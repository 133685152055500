import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IAvailabilitySummaryState } from './types';

const initialState = Object.freeze<IAvailabilitySummaryState>({
    availabilitySummary : [],
    isLoading: false
});

export const reducer = (
    state: IAvailabilitySummaryState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {       
        case ActionType.AVAILABILITY_SUMMARY_REQUEST:
            return {
                ...state,
                isLoading: (state.availabilitySummary.length === 0) ? true : false
            };
        case ActionType.AVAILABILITY_SUMMARY_RECEIVE:
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly handle out-of-order responses.
            const { availabilitySummary } = action;
            return {
                availabilitySummary,
                isLoading: false
            };
        case ActionType.RESET_STATE:
            return initialState;
        
        default:
            return state;
    }
};
