import { CommonAPI, LastWeekIdNewsLetterAPI } from '../../api';
import { IAppThunkAction, ReduxAction } from '../';
import { ActionType, IPropLastWeekIdNewsLetterData, ILastWeekIdNewsLetterState, DropdownOption, IPropIdentityTopOutages, IPropIdentityTopOutagesTable, IPropLastWeekIdentityTiles, TileDescription, IPropPirCompletion, IPropLastWeekOnCall } from './types';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../utils';
import { toast } from "react-toastify";
import { getToken } from '../../adalConfig';
import moment from 'moment';
import { text } from '@fortawesome/fontawesome-svg-core';

export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    setloadingtrue: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
    },
    setloadingfalse: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_FALSE
        });
    },
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }
    },
    GetWeekStartDate: (WeekDate: Date): IAppThunkAction<ReduxAction> => (dispatch) => {
        let WeekStartDate;
        let todaysDate = new Date();

        let sessionToken = getToken();
        const monthNames = [" January", " February", " March", " April", " May", " June", " July", " August", " September", " October", " November", " December"];
        // On Page Load the WeekDate is Null hence setting it to Saturday else the selected Date as WeekStartDate.
        if (isNullOrUndefined(WeekDate)) {
            if (todaysDate.getDay() === 6) {
                WeekStartDate = todaysDate;
            }
            else {
                WeekStartDate = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate() - (todaysDate.getDay() + 1));
            }
        }
        else {
            WeekStartDate = WeekDate;
        }

        const today = new Date(WeekStartDate);

        const pMonday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 1);
        const StartDate = new Date(pMonday.getFullYear(), pMonday.getMonth(), pMonday.getDate() - 1);
        const EndDate = new Date(pMonday.getFullYear(), pMonday.getMonth(), pMonday.getDate() + 5);
        let DateRange;

        if (StartDate.getMonth() === EndDate.getMonth() && StartDate.getFullYear() === EndDate.getFullYear()) {
            DateRange = monthNames[StartDate.getMonth()] + ' ' + StartDate.getDate() + '-' + EndDate.getDate() + ', ' + StartDate.getFullYear();
        } else if (StartDate.getMonth() !== EndDate.getMonth() && StartDate.getFullYear() === EndDate.getFullYear()) {
            DateRange = monthNames[StartDate.getMonth()] + ' ' + StartDate.getDate() + '-' + monthNames[EndDate.getMonth()] + ' ' + EndDate.getDate() + ', ' + StartDate.getFullYear();
        } else {
            DateRange = monthNames[StartDate.getMonth()] + ' ' + StartDate.getDate() + ',' + StartDate.getFullYear() + '-' + monthNames[EndDate.getMonth()] + ' ' + EndDate.getDate() + ', ' + EndDate.getFullYear();
        }

        dispatch({
            WeekStartDate: WeekStartDate,
            StartDate: StartDate,
            EndDate: EndDate,
            DateRange: DateRange,
            sessionToken,
            type: ActionType.WEEKLY_START_DATE_CHANGE
        });
    },
    GetIdentityNewsLetterData: (Callcount, WeekStartDate): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let count = 0;
        if (Callcount !== 4) {
            count = 2;
        }
        if (Callcount === 1 || Callcount === 4) {
            dispatch({
                type: ActionType.SET_LOADING_TRUE
            });

            LastWeekIdNewsLetterAPI.GetIdentityNewsLettersTiles(WeekStartDate)
                .then((identityNewsLetterTilesRawData: IPropLastWeekIdentityTiles[]) => {
                    const ooslaPIRdata = identityNewsLetterTilesRawData.find(x => x.title == 'OOSLA PIRs');
                    const ooslaSTRdata = identityNewsLetterTilesRawData.find(x => x.title == 'OOSLA STRI');
                    const pirHTMLContent = identityNewsLetterTilesRawData.find(x => x.kpI_Id == 1).htmlContent;
                    const repairItemsHtml = identityNewsLetterTilesRawData.find(x => x.kpI_Id == 2).htmlContent;
                    let ooslaPIR: TileDescription[];
                    let ooslaSTR: TileDescription[];
                    if (ooslaPIRdata) {
                        ooslaPIR = [{
                            title: ooslaPIRdata.title,
                            description: ooslaPIRdata.description,
                            reportlink: ooslaPIRdata.reportLink,
                        }];
                    }

                    if (ooslaSTRdata) {
                        ooslaSTR = [{
                            title: ooslaSTRdata.title,
                            description: ooslaSTRdata.description,
                            reportlink: ooslaSTRdata.reportLink,
                        }];
                    }
                    count = count + 1;
                    dispatch({
                        count: count,
                        NewsLetterTilesCount: identityNewsLetterTilesRawData.length,
                        NewsLetterTilesRawData: identityNewsLetterTilesRawData,
                        NewsLetterTilesData: identityNewsLetterTilesRawData,
                        OoslaPIRs: ooslaPIR,
                        OoslaSTRI: ooslaSTR,
                        pirHTMLContent: pirHTMLContent,
                        repairItemshtmlContent: repairItemsHtml,
                        type: ActionType.GET_IDENTITYNEWSLTTERTILESDATA_SUCCESS
                    });

                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        NewsLetterTilesCount: 0,
                        NewsLetterTilesData: [],
                        OoslaPIRs: [],
                        OoslaSTRI: [],
                        pirHTMLContent: '',
                        repairItemshtmlContent: '',
                        type: ActionType.GET_IDENTITYNEWSLTTERTILESDATA_FAILURE
                    });
                });
        }
        if (Callcount === 2 || Callcount === 4) {
            dispatch({
                type: ActionType.SET_LOADING_TRUE
            });
            //==================== to get data for Newsletter TopOutages Data=================//
            LastWeekIdNewsLetterAPI.GetIdentityNewsletterTopOutages(WeekStartDate)
                .then((newsLetterWeeklytopOutagesDataRaw: IPropIdentityTopOutages[]) => {
                    let newsLettertopOutagesData = {};
                    let originalNewsLettertopOutagesData = {};
                    if (isArrayWithLength(newsLetterWeeklytopOutagesDataRaw)) {
                        newsLetterWeeklytopOutagesDataRaw.map((obj: IPropIdentityTopOutages) => {
                            newsLettertopOutagesData = {
                                topOutageID: obj.topOutageID,
                                numberOfSev1: obj.numberOfSev1,
                                numberOfSev2: obj.numberOfSev2,
                                numberOfUniqueCCL: obj.numberOfUniqueCCL,
                                weekDate: moment(obj.weekDate).format('MM/DD/YYYY'),
                                weekId: obj.weekId,
                                sev1Customer: obj.sev1Customer,
                                sev2Customer: obj.sev2Customer,
                                outageswithExternal: obj.outageswithExternal,
                                incidentNotes: obj.incidentNotes,
                                topOutageTable: obj.topOutageTable,
                                oncallTeamTable: obj.oncallTeamTable,
                                topOutageOpen: obj.topOutageOpen,
                                pirClosed: obj.pirClosed,
                                notes: obj.notes,
                                isActive: obj.isActive
                            };
                        });
                        originalNewsLettertopOutagesData = newsLettertopOutagesData;
                    }
                    count = count + 1;
                    dispatch({
                        count: count,
                        newsLettertopOutagesData,
                        originalNewsLettertopOutagesData,
                        type: ActionType.GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_SUCCESS
                    });
                }).catch(error => {
                    count = count + 1;
                    dispatch({
                        count: count,
                        type: ActionType.GET_IDENTITYWEEKLYNEWSLETTERTOPOUTAGESDATA_FAILURE
                    });
                });
        }
    },
    TopOutageDataEditCancel: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let originalNewsLettertopOutagesData = getState().LastWeekIdNewsLetter.originalNewsLettertopOutagesData;
        let newsLettertopOutagesData = originalNewsLettertopOutagesData;
        dispatch({
            newsLettertopOutagesData,
            type: ActionType.TOPOUTAGES_INPUT_CHANGE
        });
    },
    UpdatetopOutagedata: (event, inputType): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let topOutageData = getState().IdentityWeeklyNewsLetter.newsLettertopOutagesData;
        let newsLettertopOutagesData = {
            topOutageID: topOutageData.topOutageID,
            numberOfSev1: topOutageData.numberOfSev1,
            numberOfSev2: topOutageData.numberOfSev2,
            numberOfUniqueCCL: topOutageData.numberOfUniqueCCL,
            weekDate: topOutageData.weekDate,
            weekId: topOutageData.weekId,
            sev1Customer: topOutageData.sev1Customer,
            sev2Customer: topOutageData.sev2Customer,
            outageswithExternal: topOutageData.outageswithExternal,
            incidentNotes: topOutageData.incidentNotes,
            topOutageTable: topOutageData.topOutageTable,
            oncallTeamTable: topOutageData.oncallTeamTable,
            topOutageOpen: topOutageData.topOutageOpen,
            pirClosed: topOutageData.pirClosed,
            notes: topOutageData.notes,
            isActive: topOutageData.isActive
        };
        if (inputType === 'incidentNotes') {
            newsLettertopOutagesData.incidentNotes = event.htmlValue;
        }
        if (inputType === 'topOutageTable') {
            newsLettertopOutagesData.topOutageTable = event.htmlValue;
        }
        if (inputType === 'oncallTeamTable') {
            newsLettertopOutagesData.oncallTeamTable = event.htmlValue;
        }
        else if (inputType === 'sev1Customer') {
            newsLettertopOutagesData.sev1Customer = event.htmlValue;
        }
        else if (inputType === 'sev2Customer') {
            newsLettertopOutagesData.sev2Customer = event.htmlValue;
        }
        else if (inputType === 'outageswithExternal') {
            newsLettertopOutagesData.outageswithExternal = event.htmlValue;
        }
        else if (inputType === 'numberOfSev1') {
            newsLettertopOutagesData.numberOfSev1 = event.target.value;
        }
        else if (inputType === 'numberOfSev2') {
            newsLettertopOutagesData.numberOfSev2 = event.target.value;
        }
        else if (inputType === 'numberOfUniqueCCL') {
            newsLettertopOutagesData.numberOfUniqueCCL = event.target.value;
        }
        dispatch({
            newsLettertopOutagesData,
            type: ActionType.TOPOUTAGES_INPUT_CHANGE
        });
    },
    TopOutageDataRefresh: (username): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let WeekStartDate = getState().IdentityWeeklyNewsLetter.WeekStartDate;
        let newsLettertopOutagesData = getState().IdentityWeeklyNewsLetter.newsLettertopOutagesData;
        let TopOutageID = newsLettertopOutagesData.topOutageID;
        if (TopOutageID === null || TopOutageID === undefined || TopOutageID === "") {
            toast.error(renderToastifyMsg('No TopOutage Data is Present !!',
                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
            return;
        }
        dispatch({
            type: ActionType.REFRESH_TOPOUTAGE_DATA_REQUEST
        });
        LastWeekIdNewsLetterAPI.RefreshTopOutageData(TopOutageID, username)
            .then((isSuccess: number) => {
                toast.success(renderToastifyMsg('Data has been Refreshed Successfully',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
                callrequestIdentityNewsLetterData(dispatch, WeekStartDate)
            }).catch(error => {
                toast.error(renderToastifyMsg('Refresh TopOutage Data Failed. Please Try Again.',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
                dispatch({
                    type: ActionType.REFRESH_TOPOUTAGE_DATA_FAIL
                });
            });
    },
    titleInputChange: (key, section, value): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let newsLetterActualData = getState().LastWeekIdNewsLetter.NewsLetterTilesData.map((dhs, index) => {
            if (index === key) {
                return { ...dhs, [section]: value };
            }
            return dhs;
        });
        dispatch({
            NewsLetterTilesData: newsLetterActualData,
            type: ActionType.TILESINPUT_CHANGE
        });
    },
    SaveTileData: (key, editedTileObject): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        const updatedObj = editedTileObject;
        const initialList = getState().LastWeekIdNewsLetter.NewsLetterTilesRawData;
        let updatedDetails: any[] = [];
        let hasUpdate = false;
        const initialObj = initialList.find(obj => obj.kpI_Id === updatedObj.kpI_Id);
        if (initialObj) {
            let updatedValues: any = {};
            for (const key in updatedObj) {
                if (updatedObj.hasOwnProperty(key) && initialObj.hasOwnProperty(key)) {
                    if (initialObj[key] !== updatedObj[key]) {
                        updatedValues[key] = updatedObj[key];
                    }
                }
            }

            if (Object.keys(updatedValues.length > 0)) {
                updatedValues.kpi_Id = initialObj.kpI_Id;
                updatedDetails.push(updatedValues);
                hasUpdate = true;
            }
        }
        if (hasUpdate) {
            var updatedTilesObj: IPropLastWeekIdentityTiles = {
                kpI_Id: initialObj.kpI_Id,
                weekDate: initialObj.weekDate,
                weekId: initialObj.weekId,
                kpI_Section1_Value: Object.keys(updatedDetails[0]).some((keys) => keys == ('kpI_Section1_Value')) ? updatedDetails[0].kpI_Section1_Value : initialObj.kpI_Section1_Value,
                kpI_Section2_Value: Object.keys(updatedDetails[0]).some((keys) => keys == ('kpI_Section2_Value')) ? updatedDetails[0].kpI_Section2_Value : initialObj.kpI_Section2_Value,
                kpI_Section3_Value: Object.keys(updatedDetails[0]).some((keys) => keys == ('kpI_Section3_Value')) ? updatedDetails[0].kpI_Section3_Value : initialObj.kpI_Section3_Value,
                title: '',
                description: '',
                reportLink: '',
                kpI_Section1: Object.keys(updatedDetails[0]).some((keys) => keys == ('kpI_Section1')) ? updatedDetails[0].kpI_Section1 : initialObj.kpI_Section1,
                kpI_Section2: Object.keys(updatedDetails[0]).some((keys) => keys == ('kpI_Section2')) ? updatedDetails[0].kpI_Section2 : initialObj.kpI_Section2,
                kpI_Section3: Object.keys(updatedDetails[0]).some((keys) => keys == ('kpI_Section3')) ? updatedDetails[0].kpI_Section3 : initialObj.kpI_Section3,
                kpI_Section1_Links: Object.keys(updatedDetails[0]).some((keys) => keys == ('kpI_Section1_Links')) ? updatedDetails[0].kpI_Section1_Links : initialObj.kpI_Section1_Links,
                kpI_Section2_Links: Object.keys(updatedDetails[0]).some((keys) => keys == ('kpI_Section2_Links')) ? updatedDetails[0].kpI_Section2_Links : initialObj.kpI_Section2_Links,
                kpI_Section3_Links: Object.keys(updatedDetails[0]).some((keys) => keys == ('kpI_Section3_Links')) ? updatedDetails[0].kpI_Section3_Links : initialObj.kpI_Section3_Links,
                htmlContent: '',
                updatedBy: getState().authUser.login,
                updatedDate: moment(new Date()).format("MM/DD/YYYY"),
                kpI_Header: Object.keys(updatedDetails[0]).some((keys) => keys == ('kpI_Header')) ? updatedDetails[0].kpI_Header : initialObj.kpI_Header,
                kpI_Footer: Object.keys(updatedDetails[0]).some((keys) => keys == ('kpI_Footer')) ? updatedDetails[0].kpI_Footer : initialObj.kpI_Footer,
                kpI_Section1_DIFF: '',
                kpI_Section2_DIFF: '',
                kpI_Section1_SIGN: '',
                kpI_Section2_SIGN: ''
            }

            LastWeekIdNewsLetterAPI.SaveUpdatedTiles(updatedTilesObj)
                .then((result: number) => {
                    if (result >= 1) {
                        let WeekStartDate = getState().LastWeekIdNewsLetter.WeekStartDate;
                        callrequestIdentityNewsLetterData(dispatch, WeekStartDate);
                        dispatch({
                            type: ActionType.UPDATETILE_SUCCESS
                        });
                        toast.success(renderToastifyMsg('Data save Successfully',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
                    }
                    else {
                        dispatch({
                            type: ActionType.UPDATETILE_FAILURE
                        });
                        toast.error(renderToastifyMsg('Saving Tiles data Failed. Please Try Again.',
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2000 });
                    }
                });
        }


    },
    clearTilesEditData: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        const rawData = getState().LastWeekIdNewsLetter.NewsLetterTilesRawData;
        dispatch({
            NewsLetterTilesRawData: rawData,
            type: ActionType.TILESINPUT_CLEAR
        });
    },
    SendMailLastWeekIdNewsletterDashboard: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
        let dateRangeFull = getState().LastWeekIdNewsLetter.DateRange;
        let newsLetterTilesData = getState().LastWeekIdNewsLetter.NewsLetterTilesData;
        let ooslaPIRs = getState().LastWeekIdNewsLetter.OoslaPIRs;
        let ooslaSTRI = getState().LastWeekIdNewsLetter.OoslaSTRI;
        let newsLettertopOutagesData = getState().LastWeekIdNewsLetter.newsLettertopOutagesData;
        let incidentNotes = (!isNullOrUndefined(newsLettertopOutagesData.incidentNotes)) ? newsLettertopOutagesData.incidentNotes : "";
        let pirHTMLContent = getState().LastWeekIdNewsLetter.pirHTMLContent;
        let repairItemshtmlContent = getState().LastWeekIdNewsLetter.repairItemshtmlContent;
        let outagesTable = (!isNullOrUndefined(newsLettertopOutagesData.topOutageTable)) ? newsLettertopOutagesData.topOutageTable : "";
        let oncallTeamTable = (!isNullOrUndefined(newsLettertopOutagesData.oncallTeamTable)) ? newsLettertopOutagesData.oncallTeamTable : "";
        let topOutageOpen = (!isNullOrUndefined(newsLettertopOutagesData.topOutageOpen)) ? newsLettertopOutagesData.topOutageOpen : "";
        let pirClosed = (!isNullOrUndefined(newsLettertopOutagesData.pirClosed)) ? newsLettertopOutagesData.pirClosed : "";
        let oncallData = getState().LastWeekIdNewsLetter.lastWeekOnCallData;

        let emailContent = "<html><head></head><body link=\"#467886\" vlink=\"#96607D\" style='tab-interval:.5in;word-wrap:break-word'><div><p><span>&nbsp;</span></p><p><span>&nbsp;</span></p><table style='border-collapse:collapse;border:none;'><tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'><td style='width:1000pt;padding:  0in 5.4pt 0in 5.4pt'><p><span style='font-size:10.5pt;font-family:\"Segoe UI\",sans-serif;  color:red'>** <span class=GramE>Note :</span> Internal Use Only. Microsoft Confidential - Not meant for external distribution.</span></p><table style='width:100%;border-collapse:collapse;mso-yfti-tbllook:1184; mso-padding-alt:0in 0in 0in 0in'><tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:51.25pt'><td width=1486 valign=top style='width:100%;background:#243A5E;padding:0in 5.4pt 0in 5.4pt;height:51.25pt'><p><b><span style='font-size:24.0pt;font-family:\"Segoe UI\",sans-serif;color:white'>Last week in Identity Livesite </span></b><b><span\r\n    style='font-size:24.0pt;font-family:\"Segoe UI\",sans-serif'></span></b></p></td></tr><tr style='mso-yfti-irow:1;height:26.05pt'><td width=1486 valign=top style='width:100%;background:#0078D4;padding:0in 5.4pt 0in 5.4pt;height:26.05pt'> <p ><b><span style='font-size:16.0pt;font-family:\"Segoe UI\",sans-serif;color:#E8E8E8'>IDNA | Entra Core Services | LiveSite</span></b><b><span style='font-size:16.0pt;font-family:\"Segoe UI\",sans-serif'></span></b></p></td></tr><tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes;height:16.2pt'><td width=1486 valign=top style='width:1114.5pt;padding:0in 5.4pt 0in 5.4pt;height:16.2pt'><p><b><span style='font-size:16.0pt;font-family:\"Segoe UI\",sans-serif;color:#0078D4'>Identity Team Weekly newsletter" + dateRangeFull + "</span></b></p></td></tr></table><p ><span></span></p></td></tr>" +
            "<tr style='mso-yfti-irow:1'><td width=1506 valign=top style='width:1000pt;padding:0in 5.4pt 0in 5.4pt'><p><span>&nbsp;</span></p>";
        let newsLetterTilesDatahtml = "<table Table border=0 cellspacing=0 cellpadding=0 width=1380 style='width:1035.3pt;margin-left:30.3pt;margin-right:30.3pt;border-collapse:collapse; mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>";

        let kpiHeaderHtml = "<tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:.7in'>";
        let kpiSectionSection1ValueHtml = "<tr style='mso-yfti-irow:1;mso-yfti-firstrow:yes;height:.7in'>";
        let kpiSectionSection1Html = "<tr style='mso-yfti-irow:2;mso-yfti-firstrow:yes;height:.7in'>";
        let kpiSectionSection2ValueHtml = "<tr style='mso-yfti-irow:3;mso-yfti-firstrow:yes;height:.7in'>";
        let kpiSectionSection2Html = "<tr style='mso-yfti-irow:4;mso-yfti-firstrow:yes;height:.7in'>";

        //Adding the Title Row
        for (var i = 0; i < newsLetterTilesData.length; i++) {
            kpiHeaderHtml = kpiHeaderHtml +
                "<td width=294 valign=top style='width:220.8pt;border:solid windowtext 1.0pt;border-bottom:none;background:#E7E6E6;padding:0in 5.4pt 0in 5.4pt;height:.7in'><p align=center style='text-align:center'><span><b><span>&nbsp;</span></b></span></p><p align=center style='text-align:center'><span><b><span style='font-size:14.0pt;font-family:\"Segoe UI\",sans-serif;color:#212529'>"
                + newsLetterTilesData[i].kpI_Header;
            if (i < newsLetterTilesData.length - 1) {
                kpiHeaderHtml = kpiHeaderHtml + "</span></b></span><span style='font-size:14.0pt'></span></p></td><td width=67 valign=top style='width:49.9pt;border:none;border-right:solid windowtext 1.0pt;padding:0in 5.4pt 0in 5.4pt;height:.7in'><p><span style='mso-ligatures:none'>&nbsp;</span></p></td>";
            }
            else {
                kpiHeaderHtml = kpiHeaderHtml + "</span></b></span><span style='font-size:14.0pt'></span></p></td>";
            }
        }
        kpiHeaderHtml = kpiHeaderHtml + "</tr>";

        for (var i = 0; i < newsLetterTilesData.length; i++) {
            kpiSectionSection1ValueHtml = kpiSectionSection1ValueHtml +
                "<td width=294 valign=top style='width:220.8pt;border-top:none;border-left: solid windowtext 1.0pt;border-bottom:none;border-right:solid windowtext 1.0pt; background:#E7E6E6;padding:0in 5.4pt 0in 5.4pt;height:.7in'><p align=center style='text-align:center'><b><span style='font-size:30.0pt;font-family:\"Segoe UI\",sans-serif;color:#212529;mso-ligatures:none'>"
                + newsLetterTilesData[i].kpI_Section1_Value;
            if (i < newsLetterTilesData.length - 1) {
                kpiSectionSection1ValueHtml = kpiSectionSection1ValueHtml + "</span></b></p></td><td width=67 valign=top style='width:49.9pt;border:none;border-right:solid windowtext 1.0pt;padding:0in 5.4pt 0in 5.4pt;height:.7in'><p ><span style='mso-ligatures:none'>&nbsp;</span></p></td>";
            }
            else {
                kpiSectionSection1ValueHtml = kpiSectionSection1ValueHtml + "</span></b></p></td>";
            }
        }
        kpiSectionSection1ValueHtml = kpiSectionSection1ValueHtml + "</tr>";

        for (var i = 0; i < newsLetterTilesData.length; i++) {
            kpiSectionSection1Html = kpiSectionSection1Html +
                "<td width=294 valign=top style='width:220.8pt;border-top:none;border-left:solid windowtext 1.0pt;border-bottom:none;border-right:solid windowtext 1.0pt;background:#E7E6E6;padding:0in 5.4pt 0in 5.4pt;height:.7in'><p align=center style='text-align:center'><span style='font-size:11.5pt;font-family:\"Segoe UI\",sans-serif;color:#212529; mso-ligatures:none'>"
                + newsLetterTilesData[i].kpI_Section1;
            if (newsLetterTilesData[i].kpI_Section1_DIFF !== "") {
                if (newsLetterTilesData[i].kpI_Id != 5 && newsLetterTilesData[i].kpI_Id != 4) {
                    if (newsLetterTilesData[i].kpI_Section1_SIGN == "Negative") {
                        kpiSectionSection1Html = kpiSectionSection1Html + "</span></p><p align=center style='text-align:center'><span style='color:red;font-size:14pt'>&#9660;</span>"
                    }
                    else if (newsLetterTilesData[i].kpI_Section1_SIGN == "Positive") {
                        kpiSectionSection1Html = kpiSectionSection1Html + "</span></p><p align=center style='text-align:center'><span style='color:green;font-size:14pt'>&#9650;</span>"
                    }
                    else if (newsLetterTilesData[i].kpI_Section1_SIGN == "Neutral") {
                        kpiSectionSection1Html = kpiSectionSection1Html + "</span></p><p align=center style='text-align:center'>"
                    }
                }
                else {
                    if (newsLetterTilesData[i].kpI_Section1_SIGN == "Negative") {
                        kpiSectionSection1Html = kpiSectionSection1Html + "</span></p><p align=center style='text-align:center'><span style='color:green;font-size:14pt'>&#9660;</span>"
                    }
                    else if (newsLetterTilesData[i].kpI_Section1_SIGN == "Positive") {
                        kpiSectionSection1Html = kpiSectionSection1Html + "</span></p><p align=center style='text-align:center'><span style='color:red;font-size:14pt'>&#9650;</span>"
                    }
                    else if (newsLetterTilesData[i].kpI_Section1_SIGN == "Neutral") {
                        kpiSectionSection1Html = kpiSectionSection1Html + "</span></p><p align=center style='text-align:center'>"
                    }
                }
                kpiSectionSection1Html = kpiSectionSection1Html + "<span style='text-align:center;font-family: Segoe UI;'>" + newsLetterTilesData[i].kpI_Section1_DIFF + "</span></p><p><span>";
            }
            if (newsLetterTilesData[i].kpI_Section1_Links !== "") {
                kpiSectionSection1Html = kpiSectionSection1Html + "</span></p><p  align=center style='text-align:center'><span style='color:black;mso-color-alt:windowtext'><a  href=" +
                    newsLetterTilesData[i].kpI_Section1_Links + " target=\"_blank\"><span style='font-family:\"Segoe UI\",sans-serif;color:#007AD9; mso-ligatures:none'>Viva Goals</span></a></span></p></td>";
            }
            else {
                kpiSectionSection1Html = kpiSectionSection1Html + "</span></p></td>";
            }
            if (i < newsLetterTilesData.length - 1) {
                kpiSectionSection1Html = kpiSectionSection1Html + "<td width=67 valign=top style='width:49.9pt;border:none;border-right:solid windowtext 1.0pt;padding:0in 5.4pt 0in 5.4pt;height:.7in'><p><span style='mso-ligatures:none'>&nbsp;</span></p></td>";
            }
        }
        kpiSectionSection1Html = kpiSectionSection1Html + "</tr>";

        for (var i = 0; i < newsLetterTilesData.length; i++) {
            kpiSectionSection2ValueHtml = kpiSectionSection2ValueHtml +
                "<td width=294 valign=top style='width:220.8pt;border-top:none;border-left: solid windowtext 1.0pt;border-bottom:none;border-right:solid windowtext 1.0pt;background:#E7E6E6;padding:0in 5.4pt 0in 5.4pt;height:.7in'><p align=center style='text-align:center'><b><span style='font-size:30.0pt;font-family:\"Segoe UI\",sans-serif; color:#212529;mso-ligatures:none'>"
                + newsLetterTilesData[i].kpI_Section2_Value;
            if (i < newsLetterTilesData.length - 1) {
                kpiSectionSection2ValueHtml = kpiSectionSection2ValueHtml + "</span></b></p></td><td width=67 valign=top style='width:49.9pt;border:none;border-right:solid windowtext 1.0pt; padding:0in 5.4pt 0in 5.4pt;height:.7in'><p ><span style='mso-ligatures:none'>&nbsp;</span></p></td>";
            }
            else {
                kpiSectionSection2ValueHtml = kpiSectionSection2ValueHtml + "</span></b></p></td>";
            }
        }
        kpiSectionSection2ValueHtml = kpiSectionSection2ValueHtml + "</tr>";

        for (var i = 0; i < newsLetterTilesData.length; i++) {
            kpiSectionSection2Html = kpiSectionSection2Html +
                " <td width=294 valign=top style='width:220.8pt;border:solid windowtext 1.0pt;border-top:none;background:#E7E6E6;padding:0in 5.4pt 0in 5.4pt;height:1.5in'><p align=center style='text-align:center'><span style='font-size:11.5pt;font-family:\"Segoe UI\",sans-serif;color:#212529;mso-ligatures:none'>"
                + newsLetterTilesData[i].kpI_Section2;
            if (newsLetterTilesData[i].kpI_Section2_DIFF !== "") {
                if (newsLetterTilesData[i].kpI_Id != 4) {
                    if (newsLetterTilesData[i].kpI_Section2_SIGN == "Negative") {
                        kpiSectionSection2Html = kpiSectionSection2Html + "</span></p><p align=center style='text-align:center'><span style='color:red;font-size:14pt'>&#9660;</span>"
                    }
                    else if (newsLetterTilesData[i].kpI_Section2_SIGN == "Positive") {
                        kpiSectionSection2Html = kpiSectionSection2Html + "</span></p><p align=center style='text-align:center'><span style='color:green;font-size:14pt'>&#9650;</span>"
                    }
                    else if (newsLetterTilesData[i].kpI_Section2_SIGN == "Neutral") {
                        kpiSectionSection2Html = kpiSectionSection2Html + "</span></p><p align=center style='text-align:center'>"
                    }
                }
                else {
                    if (newsLetterTilesData[i].kpI_Section2_SIGN == "Negative") {
                        kpiSectionSection2Html = kpiSectionSection2Html + "</span></p><p align=center style='text-align:center'><span style='color:green;font-size:14pt'>&#9660;</span>"
                    }
                    else if (newsLetterTilesData[i].kpI_Section2_SIGN == "Positive") {
                        kpiSectionSection2Html = kpiSectionSection2Html + "</span></p><p align=center style='text-align:center'><span style='color:red;font-size:14pt'>&#9650;</span>"
                    }
                    else if (newsLetterTilesData[i].kpI_Section2_SIGN == "Neutral") {
                        kpiSectionSection2Html = kpiSectionSection2Html + "</span></p><p align=center style='text-align:center'>"
                    }
                }
                kpiSectionSection2Html = kpiSectionSection2Html + "<span style='text-align:center;font-family: Segoe UI;'>" + newsLetterTilesData[i].kpI_Section2_DIFF + "</span></p><p><span>";
            }

            if (newsLetterTilesData[i].kpI_Section2_Links !== "") {
                kpiSectionSection2Html = kpiSectionSection2Html + "</span></p><p  align=center style='text-align:center'><span style='color:black;mso-color-alt:windowtext'><a href=" +
                    newsLetterTilesData[i].kpI_Section2_Links + " target=\"_blank\"><span style='font-family:\"Segoe UI\",sans-serif;color:#007AD9;mso-ligatures:none'>Viva Goals</span></a></span></p></td>";
            }
            else {
                kpiSectionSection2Html = kpiSectionSection2Html + "</span></p></td>";
            }
            if (i < newsLetterTilesData.length - 1) {
                kpiSectionSection2Html = kpiSectionSection2Html + "<td width=67 valign=top style='width:49.9pt;border:none;border-right:solid windowtext 1.0pt;padding:0in 5.4pt 0in 5.4pt;height:.7in'><p><span style='mso-ligatures:none'>&nbsp;</span></p><br/><p align=center style='text-align:center'><span><b><span>&nbsp;</span></b></span></p></td>";
            }
        }
        kpiSectionSection2Html = kpiSectionSection2Html + "</tr>";

        let lastweekoncalltable = '';
        if (oncallData.length != 0) {
            lastweekoncalltable = "<tr><td><html><head><style>.Oncall_Table_header {}.Oncall_Table_header thead th {top: 0px;text-align: center !important;font-family: Segoe UI;color: black !important;font-size: 10.5pt;border:1px solid black;padding: 5px}.Oncall_Table_header td {padding: 5px;text-align: center; width: 3%;border: 1px solid #797878;background-color:white;}</style></head><body><div class='Oncall_Table_header'><table style='font-family: \"Segoe UI\";width: 700pt;border: 1px solid black;'><thead><tr><th> On call ICM Team </th><th> Primary <th> Backup </th></tr></thead><tbody>";
            oncallData.map((obj: any, index) => {
                lastweekoncalltable = lastweekoncalltable + "<tr><td>Exec on Call</td><td>" + obj.execOnCall + "<td>" + obj.execOnCallBackup + "</td></tr>" +
                    "<tr><td>Crisis Manager</td><td>" + obj.crisisManager + "<td>" + obj.crisisManagerBackup + "</td></tr>" +
                    "<tr><td>SR IM</td><td>" + obj.srimManager + "<td>" + obj.srimManagerBackup + "</td></tr></tbody></table></div></body></html></tr></td>"
            });
        }

        let ooslaPIRshtml = '';
        ooslaPIRs.map((obj: any, index) => {
            ooslaPIRshtml = ooslaPIRshtml +
                "<tr style='mso-yfti-irow:2'>  <td width=1506 valign=top style='width:1000pt;border-top:none;font-size:11.5pt;font-family:\"Segoe UI\",sans-serif;color:#212529;  padding:0in 5.4pt 0in 5.4pt'>"
                + obj.title +
                "</td></tr><tr style='mso-yfti-irow:2'>  <td width=1506 valign=top style='width:1000pt;border-top:none;font-size:11.5pt;font-family:\"Segoe UI\",sans-serif;color:#212529;  padding:0in 5.4pt 0in 5.4pt'>&nbsp;</td></tr> <tr style='mso-yfti-irow:2'>  <td width=1506 valign=top style='width:1000pt;border-top:none;font-size:11.5pt;font-family:\"Segoe UI\",sans-serif;padding:0in 5.4pt 0in 5.4pt'>"
                + obj.description +
                "</td></tr><tr style='mso-yfti-irow:2'>  <td width=1506 valign=top style='width:1000pt;border-top:none;font-size:11.5pt;font-family:\"Segoe UI\",sans-serif;color:#212529;  padding:0in 5.4pt 0in 5.4pt'>&nbsp;</td></tr><tr style='mso-yfti-irow:2'>  <td width=1506 valign=top style='width:1000pt;border-top:none;font-size:11.5pt;font-family:\"Segoe UI\",sans-serif; padding:0in 5.4pt 0in 5.4pt'>Report Link: <a href="
                + obj.reportlink + " target = \"_blank\"> PIR Completion </a></td></tr><tr style='mso-yfti-irow:2'>  <td width=1506 valign=top style='width:1000pt;border-top:none;font-size:11.5pt;font-family:\"Segoe UI\",sans-serif;color:#212529;  padding:0in 5.4pt 0in 5.4pt'>&nbsp;</td></tr>";
        });
        let ooslaSTRIhtml = '';
        ooslaSTRI.map((obj: any, index) => {
            ooslaSTRIhtml = ooslaSTRIhtml +
                "<tr style='mso-yfti-irow:2'>  <td width=1506 valign=top style='width:1000pt;border-top:none;font-size:11.5pt;font-family:\"Segoe UI\",sans-serif;padding:0in 5.4pt 0in 5.4pt'>"
                + obj.title +
                "</td></tr><tr style='mso-yfti-irow:2'>  <td width=1506 valign=top style='width:1000pt;border-top:none;font-size:11.5pt;font-family:\"Segoe UI\",sans-serif;color:#212529;  padding:0in 5.4pt 0in 5.4pt'>&nbsp;</td></tr><tr style='mso-yfti-irow:2'>  <td width=1506 valign=top style='width:1000pt;border-top:none;font-size:11.5pt;font-family:\"Segoe UI\",sans-serif; padding:0in 5.4pt 0in 5.4pt'>"
                + obj.description +
                "</td></tr><tr style='mso-yfti-irow:2'>  <td width=1506 valign=top style='width:1000pt;border-top:none;font-size:11.5pt;font-family:\"Segoe UI\",sans-serif;color:#212529;  padding:0in 5.4pt 0in 5.4pt'>&nbsp;</td></tr><tr style='mso-yfti-irow:2'>  <td width=1506 valign=top style='width:1000pt;border-top:none;font-size:11.5pt;font-family:\"Segoe UI\",sans-serif; padding:0in 5.4pt 0in 5.4pt'>Report Link: <a href="
                + obj.reportlink + " target = \"_blank\"> Repair Items Overview </a></td></tr><tr style='mso-yfti-irow:2'>  <td width=1506 valign=top style='width:1000pt;border-top:none;font-size:11.5pt;font-family:\"Segoe UI\",sans-serif;color:#212529;  padding:0in 5.4pt 0in 5.4pt'>&nbsp;</td></tr>";
        });

        newsLetterTilesDatahtml = newsLetterTilesDatahtml + kpiHeaderHtml + kpiSectionSection1ValueHtml + kpiSectionSection1Html + kpiSectionSection2ValueHtml + kpiSectionSection2Html;
        newsLetterTilesDatahtml = newsLetterTilesDatahtml + "</table><p ><span>&nbsp;</span></p><p align=center style='margin-left:10.0in;text-align:center'><b><span style='font-size:11.5pt;font-family:\"Segoe UI\",sans-serif; color:#0078D4'><span style='mso-spacerun:yes'> </span>Above OKR includes 90 days rolling data</span></b><span style='mso-ansi-language:EN-US'></span></p> </td></tr>";

        newsLetterTilesDatahtml = newsLetterTilesDatahtml + "<tr style='mso-yfti-irow:2'><td width=1506 valign=top style='width:1000pt;padding:0in 5.4pt 0in 5.4pt'>\ <p class=MsoNormal><span lang=EN-IN><o:p>&nbsp;</o:p></span></p><table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 width=1486 style='width:100%;border-collapse:collapse;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'><tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;height:27.9pt'><td width=1486 valign=top style='width:1114.5pt;background:#0078D4;padding:0in 5.4pt 0in 5.4pt;height:27.9pt'><p class=MsoNormal><b><span lang=EN-IN style='font-size:16.0pt;font-family:\"Segoe UI\",sans-serif;color:#E8E8E8'>Top Outages</span></b><span style='font-size:16.0pt;mso-ansi-language:EN-US'><o:p></o:p></span></p></td></tr></table><p class=MsoNormal><span style='mso-ansi-language:EN-US'><o:p>&nbsp;</o:p></span></p></tr>";
        newsLetterTilesDatahtml = newsLetterTilesDatahtml + "<tr style='mso-yfti-irow:2'>  <td width=1506 valign=top style='width:1000pt;border-top:none;  padding:0in 5.4pt 0in 5.4pt'>"
            + outagesTable + " <p class=MsoNormal><span style='mso-ansi-language:EN-US'><o:p>&nbsp;</o:p></span></p></tr>";

        newsLetterTilesDatahtml = newsLetterTilesDatahtml + "<tr style='mso-yfti-irow:2'>  <td width=1506 valign=top style='width:1000pt;border-top:none;  padding:0in 5.4pt 0in 5.4pt'></p>"
            + incidentNotes + " <p class=MsoNormal><span style='mso-ansi-language:EN-US'><o:p>&nbsp;</o:p></span></p></tr>";

        newsLetterTilesDatahtml = newsLetterTilesDatahtml + "<tr style='mso-yfti-irow:2'>  <td width=1506 valign=top style='width:1000pt;border-top:none;  padding:0in 5.4pt 0in 5.4pt'></p>"
            + oncallTeamTable + " <p class=MsoNormal><span style='mso-ansi-language:EN-US'><o:p>&nbsp;</o:p></span></p></tr>";

        newsLetterTilesDatahtml = newsLetterTilesDatahtml + "<tr style='mso-yfti-irow:2'>  <td width=1506 valign=top style='width:1000pt;border-top:none;  padding:0in 5.4pt 0in 5.4pt'></p>"
            + topOutageOpen + " <p class=MsoNormal><span style='mso-ansi-language:EN-US'><o:p>&nbsp;</o:p></span></p></tr>";

        newsLetterTilesDatahtml = newsLetterTilesDatahtml + "<tr style='mso-yfti-irow:2'><td width=1506 valign=top style='width:1000pt;padding:0in 5.4pt 0in 5.4pt'><p class=MsoNormal><span lang=EN-IN><o:p>&nbsp;</o:p></span></p><table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 width=1486 style='width:100%;border-collapse:collapse;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'><tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes; height:27.9pt'><td width=1486 valign=top style='width:1114.5pt;background:#0078D4;padding:0in 5.4pt 0in 5.4pt;height:27.9pt'><p class=MsoNormal><b><span lang=EN-IN style='font-size:16.0pt;font-family:\"Segoe UI\",sans-serif;color:#E8E8E8'>PIRs Closed</span></b><span style='font-size:16.0pt;mso-ansi-language:EN-US'><o:p></o:p></span></p></td></tr></table></tr>";
        newsLetterTilesDatahtml = newsLetterTilesDatahtml + pirClosed;

        newsLetterTilesDatahtml = newsLetterTilesDatahtml + "<tr style='mso-yfti-irow:2'><td width=1506 valign=top style='width:1000pt;padding:0in 5.4pt 0in 5.4pt'><p class=MsoNormal><span lang=EN-IN><o:p>&nbsp;</o:p></span></p><table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 width=1486 style='width:100%;border-collapse:collapse;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'><tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes; height:27.9pt'><td width=1486 valign=top style='width:1114.5pt;background:#0078D4;padding:0in 5.4pt 0in 5.4pt;height:27.9pt'><p class=MsoNormal><b><span lang=EN-IN style='font-size:16.0pt;font-family:\"Segoe UI\",sans-serif;color:#E8E8E8'>OOSLA PIRs</span></b><span style='font-size:16.0pt;mso-ansi-language:EN-US'><o:p></o:p></span></p></td></tr></table><p class=MsoNormal><span style='mso-ansi-language:EN-US'><o:p>&nbsp;</o:p></span></p></tr>";
        newsLetterTilesDatahtml = newsLetterTilesDatahtml + ooslaPIRshtml;

        newsLetterTilesDatahtml = newsLetterTilesDatahtml + "<tr style='mso-yfti-irow:2'>  <td width=1506 valign=top style='width:1000pt;border-top:none;  padding:0in 5.4pt 0in 5.4pt'></p>"
            + pirHTMLContent + " <p class=MsoNormal><span style='mso-ansi-language:EN-US'><o:p>&nbsp;</o:p></span></p></tr>";

        newsLetterTilesDatahtml = newsLetterTilesDatahtml + "<tr style='mso-yfti-irow:2'><td width=1506 valign=top style='width:1000pt;padding:0in 5.4pt 0in 5.4pt'><p class=MsoNormal><span lang=EN-IN><o:p>&nbsp;</o:p></span></p><table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 width=1486  style='width:100%;border-collapse:collapse;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'><tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes; height:27.9pt'><td width=1486 valign=top style='width:1114.5pt;background:#0078D4; padding:0in 5.4pt 0in 5.4pt;height:27.9pt'><p class=MsoNormal><b><span lang=EN-IN style='font-size:16.0pt;font-family:\"Segoe UI\",sans-serif;color:#E8E8E8'>OOSLA Short Term Repair Items (STRI)</span></b><span style='font-size:16.0pt;mso-ansi-language:EN-US'><o:p></o:p></span></p></td></tr></table><p class=MsoNormal><span style='mso-ansi-language:EN-US'><o:p>&nbsp;</o:p></span></p></tr>";
        newsLetterTilesDatahtml = newsLetterTilesDatahtml + ooslaSTRIhtml;

        newsLetterTilesDatahtml = newsLetterTilesDatahtml + "<tr style='mso-yfti-irow:2'>  <td width=1506 valign=top style='width:1000pt;border-top:none;  padding:0in 5.4pt 0in 5.4pt'></p>"
            + repairItemshtmlContent + " <p class=MsoNormal><span style='mso-ansi-language:EN-US'><o:p>&nbsp;</o:p></span></p></tr>";

        newsLetterTilesDatahtml = newsLetterTilesDatahtml + "<tr style='mso-yfti-irow:5;mso-yfti-lastrow:yes'><td width=1506 valign=top style='width:1000pt;padding:0in 5.4pt 0in 5.4pt'><p ><span style='font-size:10.5pt;font-family:\"Segoe UI\",sans-serif;color:red'>&nbsp;</span></p><p ><span style='font-size:10.5pt;font-family:\"Segoe UI\",sans-serif; color:red'>** <span class=GramE>Note :</span> Internal Use Only. Microsoft Confidential - Not meant for external distribution.</span><span style='mso-ansi-language:EN-US'></span></p><p ><span>&nbsp;</span></p></td></tr></table><p><span>&nbsp;</span></p></div></body></html>";

        emailContent = emailContent + newsLetterTilesDatahtml;
        let LoginuserId = getState().authUser.login;
        let StartDate = getState().LastWeekIdNewsLetter.StartDate;
        let EndDate = getState().LastWeekIdNewsLetter.EndDate;
        let dateRange = moment(StartDate).format("MM/DD") + "-" + moment(EndDate).format("MM/DD");
        LastWeekIdNewsLetterAPI.NewsletterIdentity_SendEmail(LoginuserId, dateRange, (emailContent))
            .then((result: number) => {
                if (result != -1) {
                    dispatch({
                        type: ActionType.SEND_EMAIL_REQUEST_PASS
                    });
                    toast.success(
                        renderToastifyMsg("Email sent succesfully!! ",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                    );
                } else {
                    dispatch({
                        type: ActionType.SEND_EMAIL_REQUEST_FAIL
                    });
                    toast.error(
                        renderToastifyMsg("There is error While sending email, Please contact Admin!",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                    );
                }
            }).catch(error => {
                dispatch({
                    type: ActionType.SEND_EMAIL_REQUEST_FAIL
                });
                toast.error(
                    renderToastifyMsg("There is error While sending email, Please contact Admin!",
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                );
            });
    },
};

// function to call requestIdentityNewsLetterData action method
const callrequestIdentityNewsLetterData = (dispatch, WeekStartDate) => {
    dispatch(actionCreators.GetIdentityNewsLetterData(4, moment(WeekStartDate).format('MM/DD/YYYY')));
}


