import React, { useState, Fragment } from 'react';
import DatePicker, { getDay } from 'react-datepicker'
import { ReduxAction, IAppThunkAction } from '../../../store';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../../utils';
import { IPropIdentityObjectives, IPropIdentityKeyResults, OKRObjectiveDropdownOption } from '../../../store/DivisionalOKRs';
import { FontAwesomeIconMemo } from '../../../components';
import { InputText } from 'primereact/inputtext';
import { Picky } from 'react-picky';
import Highcharts from 'highcharts';
import { Editor } from 'primereact/editor';
import { Dialog } from 'primereact/dialog';
import HighchartsReact from 'highcharts-react-official';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment';

type DivisionalOKRsProps = {
    newsLetterOKRsObjectives: any;
    selectedKeyResults: any;
    selectedObjective: OKRObjectiveDropdownOption;
    readonly handleOKRObjectiveChange: (selectedObjective: OKRObjectiveDropdownOption) => IAppThunkAction<ReduxAction>;
    ObjectiveStartDate: any;
    handleObjectiveStartDateChange: any;
    ObjectiveEndDate: any;
    handleObjectiveEndDateChange: any;
    WeekStartDate: any;
    readonly GetWeekStartDate: any;
    newObjective: any;
    readonly addNewObjective: (newObjective) => IAppThunkAction<ReduxAction>;
    EnableAddNewforObjectiveKeyResultRow: any;
    ObjectiveKeyResultvalueschange: any;
    EditObjectiveKeyResultRows: any;
    SaveObjectiveKeyResultData: any;
    cancelEditObjectiveKeyResultRows: any;
    DeleteObjectiveKeyResultRow: any;
    SaveAddNewObjectiveData: any;
    CancelAddNewObjectiveData: any;
    isAddNewObjective: any;
};

const DivisionalOKRsTable = React.memo<DivisionalOKRsProps>(({
    newsLetterOKRsObjectives,
    selectedKeyResults,
    selectedObjective,
    handleOKRObjectiveChange,
    ObjectiveStartDate,
    handleObjectiveStartDateChange,
    ObjectiveEndDate,
    handleObjectiveEndDateChange,
    WeekStartDate,
    GetWeekStartDate,
    newObjective,
    addNewObjective,
    EnableAddNewforObjectiveKeyResultRow,
    ObjectiveKeyResultvalueschange,
    EditObjectiveKeyResultRows,
    SaveObjectiveKeyResultData,
    cancelEditObjectiveKeyResultRows,
    DeleteObjectiveKeyResultRow,
    SaveAddNewObjectiveData,
    CancelAddNewObjectiveData,
    isAddNewObjective
}) => {

    const isWeekday = (date) => {
        var day = new Date(moment(date).format('MM/DD/YYYY')).getDay();
        return day == 6;
    };


    return (
        <React.Fragment>
            <br />
            <div style={{ width: '100%' }}>
                <Row>
                    <Col lg='2'>
                    </Col>
                    <Col lg='8' style={{ textAlign: 'center' }}>
                        <h4><b>Divisional Objectives and Key Results (OKR)</b></h4>
                    </Col>
                    <Col lg='2'>
                    </Col>
                </Row>
            </div>
            <br />
            <div className="card">
                <div className="p-fluid p-grid">
                    <Row>
                        <Col lg='6'>
                            <div className="p-field p-col-10 p-md-4-objective ObjectiveInput">
                                <label className="fontcolor">Objectives</label> &ensp;&ensp;
                                    <Dropdown className="ObjectivesStyle" id={'objective'} value={selectedObjective} options={newsLetterOKRsObjectives} onChange={(e) => handleOKRObjectiveChange(e.value)} optionLabel="name" />
                                </div>
                        </Col>
                        <Col lg="6">
                            <div className="p-field p-col-10 p-md-4-objective" style={{ display: 'flex' }}>
                                <div style={{ width: '100%' }}>
                                    <Row>
                                        <Col lg="2"><span style={{fontWeight: 'bold'}}>Date Range</span></Col>
                                        <Col lg="5">
                                            <div style={{ display: 'flex' }}>
                                                <DatePicker className='InputStyleObjectiveDate'
                                                    id={'ObjectiveStartDate'}
                                                    selected={ObjectiveStartDate}
                                                    onChange={handleObjectiveStartDateChange}
                                                />  &nbsp;&nbsp;
                                                <div>
                                                    <label style={{ fontSize: '20px' }} htmlFor='ObjectiveStartDate'> <FontAwesomeIconMemo icon={'calendar'} className='icon-format' /></label>&nbsp;
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg="5">
                                            <div style={{ display: 'flex' }}>
                                                <DatePicker className='InputStyleObjectiveDate'
                                                    id={'ObjectiveEndDate'}
                                                    selected={ObjectiveEndDate}
                                                    onChange={handleObjectiveEndDateChange}
                                                />  &nbsp;&nbsp;
                                                <div>
                                                    <label style={{ fontSize: '20px' }} htmlFor='ObjectiveEndDate'> <FontAwesomeIconMemo icon={'calendar'} className='icon-format' /></label>&nbsp;
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                {
                    (isAddNewObjective) ?
                        <React.Fragment>
                            <div>
                                <Row>
                                    <Col lg="10">
                                        <div className="p-field p-col-12 p-md-4-objective">
                                            <span className="p-float-label" style={{ paddingTop: '10px' }}>
                                                <InputText style={{width: '96%'}} id="newObjective" value={newObjective} onChange={(e) => addNewObjective(e)} />
                                                <label htmlFor="newObjective" className="fontcolor">Add New Objective<span className="spnmandatoryIcons">*</span></label>
                                            </span>
                                        </div>
                                    </Col>
                                    <Col lg="2">
                                        <div className="p-field p-col-12 p-md-4-objective">
                                            <Button title="Save Changes" className='PIRAddbuttons' onClick={SaveAddNewObjectiveData}> <FontAwesomeIcon icon={'save'} style={{ backgroundColor: 'None', border: 'none' }} />&nbsp;Submit</Button>&ensp;
                                            <Button title="Cancel Changes" className='PIRAddbuttons' onClick={CancelAddNewObjectiveData}> <FontAwesomeIcon icon={'ban'} style={{ backgroundColor: 'None', border: 'none' }} />&nbsp;Cancel</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </React.Fragment>
                        :
                        <React.Fragment></React.Fragment>
                }
            </div>
            <br />
            <div style={{ width: '100%' }}>
                <Row>
                    <Col lg='2'>
                    </Col>
                    <Col lg='8' style={{ textAlign: 'center' }}>
                        <div className="aligntable objectiveskeyResultsDate">
                            <h4 style={{paddingTop: '5px'}}><b>Key Results progress by Week</b></h4>&ensp;
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <DatePicker className='InputStyleObjectiveDate'
                                                id={'weekstartDate'}
                                                selected={WeekStartDate}
                                                onChange={GetWeekStartDate}
                                                filterDate={isWeekday}
                                                maxDate={new Date()}
                                                minDate={new Date(2018, 11, 1)}
                                            />  &nbsp;&nbsp;
                                        </td>
                                        <td>
                                            <div>
                                                <label style={{ fontSize: '20px' }} htmlFor='weekstartDate'> <FontAwesomeIconMemo icon={'calendar'} className='icon-format' /></label>&nbsp;
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                    <Col lg='2'>
                    </Col>
                </Row>
            </div>
            <br />
            <Row>
                <Col lg='8'></Col>
                <Col lg="4">
                    <div className="divaddnew">
                        <Button disabled={isAddNewObjective} title="Add Key Result" className='PIRAddbuttons' onClick={EnableAddNewforObjectiveKeyResultRow}> <FontAwesomeIcon icon={'plus'} style={{ backgroundColor: 'None', border: 'none' }} />&nbsp;Add Key Result</Button>
                    </div>
                </Col>
            </Row>
            <Row className="tblpadding">
                <Col lg="12">
                    <table id="tblPIRLearnings" className="tblPIRLearnings">
                        <thead>
                            <tr>
                                <th className="thwidth600">Key Results</th>
                                <th className="thwidth300">Progress</th>
                                <th className="thwidth300">Target</th>
                                <th className="thwidth100">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                isArrayWithLength(selectedKeyResults) ?
                                    <React.Fragment>
                                        {isArrayWithLength(selectedKeyResults) ?
                                            selectedKeyResults
                                                .map((row: IPropIdentityKeyResults, index) => (
                                                    <React.Fragment key={index}>
                                                        <tr>
                                                            <td className="tdwordbreak">
                                                                <div>
                                                                    {(row.isEdit) ?
                                                                        <input type="text" className="form-control" onChange={e => ObjectiveKeyResultvalueschange(e, row)} value={row.keyResultsEdit} name="keyResultsEdit" />
                                                                        : <React.Fragment>{row.keyResults}</React.Fragment>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td className="tdwordbreak">
                                                                <div>
                                                                    {(row.isEdit) ?
                                                                        <input type="text" className="form-control" onChange={e => ObjectiveKeyResultvalueschange(e, row)} value={row.progressValueEdit} name="progressValueEdit" />
                                                                        : <React.Fragment>{row.progressValue}</React.Fragment>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td className="tdwordbreak">
                                                                <div>
                                                                    {(row.isEdit) ?
                                                                        <input type="text" className="form-control" onChange={e => ObjectiveKeyResultvalueschange(e, row)} value={row.targetValueEdit} name="targetValueEdit" />
                                                                        : <React.Fragment>{row.targetValue}</React.Fragment>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td className="centericons">
                                                                {
                                                                    (!row.isEdit)
                                                                        ?
                                                                        <React.Fragment>
                                                                            <span onClick={e => EditObjectiveKeyResultRows(e, row)}>
                                                                                <FontAwesomeIcon title='Click to edit the Row' className="bluecoloricons Iconsstyles" icon={'pencil-alt'} /></span> &nbsp;
                                                                                <FontAwesomeIcon title='Click to Delete the Row' className="redcolorIcons Iconsstyles" icon={'trash'} onClick={e => DeleteObjectiveKeyResultRow(e, row)} /> &nbsp;
                                                                        </React.Fragment>
                                                                        :
                                                                        <React.Fragment>
                                                                            <FontAwesomeIcon title='Save Changes' className="bluecoloricons Iconsstyles" onClick={e => SaveObjectiveKeyResultData(e, row)} icon={'save'} /> &nbsp;
                                                            <FontAwesomeIcon title='Cancel Edit' className="Iconsstyles" icon={'ban'} onClick={e => cancelEditObjectiveKeyResultRows(e, row)} />
                                                                        </React.Fragment>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                ))
                                            : <React.Fragment></React.Fragment>
                                        }

                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <tr>
                                            <td colSpan={11} style={{ textAlign: 'center' }} >
                                                No Key Results
                                            </td>
                                        </tr>
                                    </React.Fragment>

                            }
                        </tbody>
                    </table>
                </Col>
            </Row>
        </React.Fragment>
    )


});

DivisionalOKRsTable.displayName = 'DivisionalOKRsTable';
export default DivisionalOKRsTable;