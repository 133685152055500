import React, { useEffect } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import { actionCreators, reducer, UserScenarioType } from '../../store/UserScenarioCompletion';
import { UserScenarioCompletionTable } from '../UserScenarioCompletion/child-components';
import LoadingOverlay from 'react-loading-overlay';
type UserScenarioCompletionProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly history: History }
    & typeof useParams;

const UserScenarioCompletion: React.FC<UserScenarioCompletionProps> = ({
    isLoading,
    addUserVisit,
    isAuthenticated,
    GetUserScenario,
    GetUserScenarioData,
    scenarioCompletionData,
    endDate,
    GetUserScenarioEndDate,
    selectedScenario,
    maxDate,
    onendDateChange,
    userScenario,
    handleUserScenarioChange,
    scenarioDescription,
    scenario,
    tableHeader,
    originalHeader,
    countVal
}) => {
    useEffect(() => {
        if (isAuthenticated)
            addUserVisit(0, 'UserScenarioCompletion.tsx_v2');
    }, [isAuthenticated]);
    useEffect(() => {
        if (endDate === null) {
            GetUserScenarioEndDate();
        }
    }, [endDate]);
    useEffect(() => {
        if (selectedScenario === null) {
            GetUserScenario();
        }
        else if (countVal === 0 && selectedScenario !== null && endDate !== null) {
            GetUserScenarioData();
        }
    }, [selectedScenario]);
    
    return (
        <div>
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        <UserScenarioCompletionTable
                            scenarioCompletionData={scenarioCompletionData}
                            maxDate={maxDate}
                            endDate={endDate}
                            onendDateChange={onendDateChange}
                            userScenario={userScenario}
                            selectedScenario={selectedScenario}
                            handleUserScenarioChange={handleUserScenarioChange}
                            scenarioDescription={scenarioDescription}
                            scenario={scenario}
                            GetUserScenarioData={GetUserScenarioData}
                            tableHeader={tableHeader}
                            originalHeader={originalHeader}
                        />
                    </div>
                </section>
            </LoadingOverlay>

        </div>
    );
};
const mapStateToProps = (state: IApplicationState) => ({
    ...state.UserScenarioCompletion,
    isAuthenticated: state.authUser.isAuthenticated

})

export default connect(mapStateToProps, actionCreators)(UserScenarioCompletion);
