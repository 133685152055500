import React, { useState, useRef, AnchorHTMLAttributes,Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../store';
import { RoutesConfig, Route } from '../config/routes.config';
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { ProgressBar } from 'primereact/progressbar';
import { TabView, TabPanel } from 'primereact/tabview';
import { FontAwesomeIconMemo, Spinner } from '../components';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown
} from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { isArrayWithLength, renderToastifyMsg, isNullOrUndefined } from '../utils';
import { Modal} from 'react-bootstrap';
import {
    Row,
    Col, Button
} from 'reactstrap';
import { CommonAPI } from '../api';
import { ToastId, toast } from 'react-toastify';
import { RadioButton } from 'primereact/radiobutton';

type MenuLinkAttributes = AnchorHTMLAttributes<HTMLAnchorElement>;

type NavbarProps = {
    readonly isAuthenticated: boolean;
    readonly username: string;
    serviceGroup: any;
    dataRefreshed: any;
    isProd: boolean;
    isAdmin: boolean;
    isNavBarEnable: boolean;
};
let GroupName = '';
const setGroupName = (columnName: any) => {
    GroupName = columnName;
};

const NavbarMenu: React.FC<NavbarProps> = ({ isAuthenticated, username, serviceGroup, dataRefreshed, isProd, isAdmin, isNavBarEnable}) => {
    const navRoutes = Object.keys(RoutesConfig)
        .reduce((acc: Route[], key: string) => {
            const route = RoutesConfig[key];
            route.showInNav && acc.push(route);
            return acc;
        }, []);

    const toastIdRef = useRef<ToastId>('');
    const [navRoutes_data, setnavRoutes_data] = useState(navRoutes);
    const [showfeedBackPopup, setshowfeedBackPopup] = useState(false);
    const [title, settitle] = useState('');
    const [description, setdescription] = useState('');
    const [feedbackExperience, setfeedbackExperience] = useState('');
    const [Disablesubmitbutton, setDisablesubmitbutton] = useState(true);
    const [componentSubComponentData, setcomponentSubComponentData] = useState([]);
    const [selectedComponent, setselectedComponent] = useState(null);
    const [SubComponentData, setSubComponentData] = useState([]);
    const [selectedSubComponent, setselectedSubComponent] = useState(null);
    const [feedbackCategory, setfeedbackCategory] = useState('');
    const [difficultLevelData, setdifficultLevelData] = useState([]);
    const [selectedDifficulty, setselectedDifficulty] = useState('');
    const [experienceData, setexperienceData] = useState([]);
    const [selectedExperience, setselectedExperience] = useState('');
    const [showcustomersurveyPopup, setshowcustomersurveyPopup] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [totalVisitedUser, settotalVisitedUser] = useState(null);
    const [mostVisitedPage, setmostVisitedPage] = useState(null);
    const [customerSurveyExperienceData, setcustomerSurveyExperienceData] = useState([]);
    const [customerSurveyDifficultyLevelData, setcustomerSurveyDifficultyLevelData] = useState([]);
    const [feedbackCategoryBasedData, setfeedbackCategoryBasedData] = useState([]);
    const [menuCountArray, setmenuCountArray] = useState([]);

    const getCustomerFeedbackComponentData = () => {
        let tempcomponentSubComponentData = [];
        if (!isArrayWithLength(componentSubComponentData)) {
            CommonAPI.GetCustomerFeedbackComponentData()
                .then((componentSubComponentRawData) => {
                    if (isArrayWithLength(componentSubComponentRawData)) {
                        componentSubComponentRawData.map((obj) => {
                            tempcomponentSubComponentData.push(obj);
                        });
                        setcomponentSubComponentData(tempcomponentSubComponentData);
                    }
                })
        }
    }

    const GetCustomerFeedbackDifficultylevelData = () => {
        let tempdifficultLevelData = [];
        if (!isArrayWithLength(difficultLevelData)) {
            CommonAPI.GetCustomerFeedbackDifficultylevelData()
                .then((difficultLevelDataRawData) => {
                    if (isArrayWithLength(difficultLevelDataRawData)) {
                        difficultLevelDataRawData.map((obj) => {
                            tempdifficultLevelData.push(obj);
                        });
                        setdifficultLevelData(tempdifficultLevelData);
                    }
                })
        }
    }

    const GetCustomerFeedbackExperienceData = () => {
        let tempexperienceData = [];
        if (!isArrayWithLength(experienceData)) {
            CommonAPI.GetCustomerFeedbackExperienceData()
                .then((experienceRawData) => {
                    if (isArrayWithLength(experienceRawData)) {
                        experienceRawData.map((obj) => {
                            tempexperienceData.push(obj);
                        });
                        setexperienceData(tempexperienceData);
                    }
                })
        }
    }

    const GetCustomerSurveyData = () => {
        let TempfeedbackDifficultData = [];
        let TempfeedbackExperienceData = [];
        TempfeedbackDifficultData.push({ DifficultLevelID: 5, DifficultLevel: 'Very easy', value: 0 });
        TempfeedbackDifficultData.push({ DifficultLevelID: 4, DifficultLevel: 'Somewhat easy', value: 0 });
        TempfeedbackDifficultData.push({ DifficultLevelID: 3, DifficultLevel: 'Neutral', value: 0 });
        TempfeedbackDifficultData.push({ DifficultLevelID: 2, DifficultLevel: 'Somewhat difficult', value: 0 });
        TempfeedbackDifficultData.push({ DifficultLevelID: 1, DifficultLevel: 'Very difficult', value: 0 });
        TempfeedbackExperienceData.push({ ExperienceID: 1, Experience: 'Very satisfied', value: 0 });
        TempfeedbackExperienceData.push({ ExperienceID: 2, Experience: 'Satisfied', value: 0 });
        TempfeedbackExperienceData.push({ ExperienceID: 3, Experience: 'Neither agree nor disagree', value: 0 });
        TempfeedbackExperienceData.push({ ExperienceID: 4, Experience: 'Dissatisfied', value: 0 });
        TempfeedbackExperienceData.push({ ExperienceID: 5, Experience: 'Very dissatisfied', value: 0 });

        CommonAPI.GetCustomerSurveyData()
            .then((surveyRawData: any) => {
                let VisitedUserDataRaw = [];
                let VisitedPageDataRaw = [];
                let feedbackExperienceDataRaw = [];
                let feedbackDifficultDataRaw = [];
                let feedbackCategoryDataRaw = [];
                if (!isNullOrUndefined(surveyRawData)) {
                    VisitedUserDataRaw = surveyRawData.table;
                    VisitedPageDataRaw = surveyRawData.table1
                    feedbackDifficultDataRaw = surveyRawData.table2;
                    feedbackExperienceDataRaw = surveyRawData.table3;
                    feedbackCategoryDataRaw = surveyRawData.table4;
                }

                if (isArrayWithLength(VisitedUserDataRaw)) {
                    VisitedUserDataRaw.map((item) => {
                        settotalVisitedUser(item.numberOfUsers)
                    })
                }

                if (isArrayWithLength(VisitedPageDataRaw)) {
                    VisitedPageDataRaw.map((item) => {
                        let visitedPage = item.mostVisitedPage.substr(0, item.mostVisitedPage.indexOf('.'));
                        setmostVisitedPage(visitedPage)
                    })
                }

                if (isArrayWithLength(feedbackDifficultDataRaw)) {
                    feedbackDifficultDataRaw.map((item) => {
                        TempfeedbackDifficultData.map((row) => {
                            if (row.DifficultLevelID === item.difficultLevelID) {
                                row.value = item.difficultLevelPercentage;
                            }
                        });
                    });
                    setcustomerSurveyDifficultyLevelData(TempfeedbackDifficultData);
                }

                if (isArrayWithLength(feedbackExperienceDataRaw)) {
                    feedbackExperienceDataRaw.map((item) => {
                        TempfeedbackExperienceData.map((row) => {
                            if (row.ExperienceID === item.experienceID) {
                                row.value = item.experienceLevelPercentage;
                            }
                        });
                    })
                    setcustomerSurveyExperienceData(TempfeedbackExperienceData);
                }

                if (isArrayWithLength(feedbackCategoryDataRaw)) {
                    let TempData = [];
                    feedbackCategoryDataRaw.map((item) => {
                        TempData.push(item);
                    })
                    TempData.map((item) => {
                        item.Index = (item.feedbackType === "Feedback") ? 0 : ((item.feedbackType === "Bug") ? 1 : (item.feedbackType === "DataIssue") ? 2 : 3);
                        item.reportedBy = item.reportedBy.substr(0, item.reportedBy.indexOf('@'));
                        item.reportedDate = moment(item.reportedDate).format('MM/DD/YYYY');
                    });
                    setfeedbackCategoryBasedData(TempData);
                }
            })
    }

    const valParse = (Value) => {
        if (Math.ceil(parseFloat(Value)) === Value) {
            return Value;
        }
        else {
            return Value.toFixed(2);
        }
    }

    useEffect(() => {
        getCustomerFeedbackComponentData();
        GetCustomerFeedbackDifficultylevelData();
        GetCustomerFeedbackExperienceData();
        GetCustomerSurveyData();
    }, []);

    const menuEventTrack = () => {
        CommonAPI.AddMenuStatisticsData(menuCountArray)
            .then((result: any) => {
                if (result != "-1") {
                    // Refreshing the menu mouse hover Data Upon Succesfull Save
                    setmenuCountArray([]);
                }
            });
    }

    const returnTableBody = () => {
        let table= (
            <Row>
                <Col>
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th className="PanelTableHeaderFeedback" style={{width:'15%'}}>&ensp;Title</th>
                                <th className="PanelTableHeaderFeedback" style={{width:'40%'}}>&ensp;Description</th>
                                {(activeIndex === 1 || activeIndex === 2) ?
                                    <th className="PanelTableHeaderFeedback" style={{ width: '5%' }}>&ensp;Status</th>
                                    : <React.Fragment></React.Fragment>
                                }
                                <th className="PanelTableHeaderFeedback" style={{width:'7%'}}>&ensp;Reported By</th>
                                <th className="PanelTableHeaderFeedback" style={{width:'7%'}}>&ensp;Reported Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                isArrayWithLength(feedbackCategoryBasedData) ?
                                    <React.Fragment>
                                        {
                                            feedbackCategoryBasedData
                                                .filter((item) => {
                                                    if (item.Index === activeIndex) {
                                                        return true
                                                    }
                                                })
                                                .map((row, index) => (
                                                    <React.Fragment key={index}>
                                                        <tr>
                                                            <td style={{border: '1px solid grey'}}>{row.title}</td>
                                                            <td style={{border: '1px solid grey'}}>{row.description}</td>
                                                            {(activeIndex === 1 || activeIndex === 2) ?
                                                                <td style={{ border: '1px solid grey' }}>{row.status}</td>
                                                                :<React.Fragment></React.Fragment>
                                                            }
                                                            <td style={{border: '1px solid grey'}}>{row.reportedBy}</td>
                                                            <td style={{border: '1px solid grey'}}>{row.reportedDate}</td>
                                                        </tr>
                                                    </React.Fragment>
                                                ))
                                        }
                                    </React.Fragment>
                                    : <React.Fragment>
                                        <tr><td colSpan={5} style={{ textAlign: 'center' }} >
                                            No Data Found!!
                                        </td>
                                        </tr>
                                    </React.Fragment>
                            }
                        </tbody>
                    </table>
                </Col>
            </Row>
        );
        return table;
    }
    //=============== function to set the Component =========================//
    const componentSelected = (value) => {
        setselectedSubComponent(null);
        setselectedComponent(value);
        if (value.componentName === 'Others') {
            // Only for 'Others' component enabling save button though there is no sub component selected
            setSubComponentData([]);
        }
        else {
            let subcomponents = value.subComponents;
            var subcomponentValues = subcomponents.split(',');
            setSubComponentData(subcomponentValues);
        }
        if (value === undefined || value === '' || value === null || title == '' || title.replace(/\s/g, "") === "" || title == undefined || feedbackCategory === '' || selectedDifficulty === '' || selectedExperience === '' || description === '' || description === undefined || description.replace(/\s/g, "") === "") {
            setDisablesubmitbutton(true);
        }
        else {
            setDisablesubmitbutton(false);
        }
    }

    //=============== function to set the SubComponent =========================//
    const setselectedSubComponentVal = (val, checked) => {
        if (checked) {
            setselectedSubComponent(val);
        }
        else {
            setselectedSubComponent('');
        }
        if (val === undefined || val === '' || val === null || checked === false || title == '' || title.replace(/\s/g, "") === "" || title == undefined || selectedComponent === null || feedbackCategory === '' || selectedDifficulty === '' || selectedExperience === '' || description === '' || description === undefined || description.replace(/\s/g, "") === "") {
            setDisablesubmitbutton(true);
        }
        else {
            setDisablesubmitbutton(false);
        }
    }

    //=============== function to navigate back to Feedback Page =========================//
    const BacktoFeedbackForm = () => {
        setshowcustomersurveyPopup(false);
    }

    //=============== function to set the Feedback Category =========================//
    const setfeedbackCategoryVal = (val) => {
        if (val === undefined || val === '' || val === null || selectedComponent === null || title == '' || title.replace(/\s/g, "") === "" || title == undefined || selectedExperience === '' || selectedDifficulty === '' || description === '' || description === undefined || description.replace(/\s/g, "") === "") {
            setDisablesubmitbutton(true);
        }
        else {
            setDisablesubmitbutton(false);
        }
        setfeedbackCategory(val);
    }

    //=============== function to set the Difficulty Level =========================//
    const setselectedDifficultyVal = (val) => {
        if (val === undefined || val === '' || val === null || selectedComponent === null || title == '' || title.replace(/\s/g, "") === "" || title == undefined || feedbackCategory === '' || selectedExperience === '' || description === '' || description === undefined || description.replace(/\s/g, "") === "") {
            setDisablesubmitbutton(true);
        }
        else {
            setDisablesubmitbutton(false);
        }
        setselectedDifficulty(val);
    }

    //=============== function to set the Experience Level =========================//
    const setselectedExperienceVal = (val) => {
        if (val === undefined || val === '' || val === null || selectedComponent === null || title == '' || title.replace(/\s/g, "") === "" || title == undefined || feedbackCategory === '' || selectedDifficulty === '' || description === '' || description === undefined || description.replace(/\s/g, "") === "") {
            setDisablesubmitbutton(true);
        }
        else {
            setDisablesubmitbutton(false);
        }
        setselectedExperience(val);
    }

    //=============== function to open the Feedback popup =========================//
    const openfeedBackPopup = (e) => {
        setshowfeedBackPopup(true);
        // Setting the Tab to "Feedback" by Default in Survey Report
        setActiveIndex(0);
        // Logic to Set the Component and SubComponent Value when Navigated in different page and Clicked on Feedback
        var newurl = window.location.pathname;
        componentSubComponentData.map((obj) => {
            if (newurl === "/") {
                if (obj.componentName.indexOf('Home') !== -1 && obj.mappedSubComponentURI === "") {
                    setselectedComponent(obj);
                    let subcomponents = obj.subComponents;
                    var subcomponentValues = subcomponents.split(',');
                    setSubComponentData(subcomponentValues);
                    setselectedSubComponent(null);
                }
            }
            else if (newurl === "/ServiceHealth") {
                if (obj.mappedSubComponentURI.indexOf('ServiceHealth') !== -1) {
                    setselectedComponent(obj);
                    let subcomponents = obj.subComponents;
                    var subcomponentValues = subcomponents.split(',');
                    setSubComponentData(subcomponentValues);
                    setselectedSubComponent(null);
                }
            }
            else if (obj.mappedSubComponentURI !== "") {
                let URI = newurl.slice(1);
                if (obj.mappedSubComponentURI.indexOf(URI) !== -1) {
                    // Setting the Component Value
                    setselectedComponent(obj);
                    // Setting the SubComponent Value
                    let mappedSubComponentURIVal = obj.mappedSubComponentURI;
                    var mappedSubComponentURIVales = mappedSubComponentURIVal.split(',');
                    mappedSubComponentURIVales.map((val, index) => {
                        if (val === URI) {
                            let subcomponents = obj.subComponents;
                            var subcomponentValues = subcomponents.split(',');
                            setSubComponentData(subcomponentValues);
                            setselectedSubComponent(subcomponentValues[index]);
                        }
                    });
                }
            }
        });
    }

    //=============== function to close the Feedback popup =========================//
    const closefeedBackPopup = () => {
        setshowfeedBackPopup(false);
        setDisablesubmitbutton(true);
        setshowcustomersurveyPopup(false);
        setselectedComponent(null);
        setselectedSubComponent(null);
        setSubComponentData([]);
        setfeedbackCategory('');
        settitle('');
        setdescription('');
        setselectedDifficulty('');
        setselectedExperience('');
        setfeedbackExperience('');
    }

    //=============== function to set the Feedback Title =========================//
    const handleFeedbackTitleChange = (e) => {
        if (e.target.value === undefined || e.target.value === '' || e.target.value.replace(/\s/g, "") === "" || selectedComponent === null || feedbackCategory === '' || selectedDifficulty === '' || selectedExperience === '' || description === '' || description === undefined || description.replace(/\s/g, "") === "") {
            setDisablesubmitbutton(true);
        }
        else {
            setDisablesubmitbutton(false);
        }
        settitle(e.target.value);
    }

    //=============== function to set the Feedback Description =========================//
    const handleFeedbackDescriptionChange = (e) => {
        if (e.target.value === undefined || e.target.value === '' || e.target.value.replace(/\s/g, "") === "" || title == '' || title.replace(/\s/g, "") === "" || title == undefined || selectedComponent === null || feedbackCategory === '' || selectedDifficulty === '' || selectedExperience === '') {
            setDisablesubmitbutton(true);
        }
        else {
            setDisablesubmitbutton(false);
        }
        setdescription(e.target.value);
    }

    const saveDisable = () => {
        if (selectedComponent !== null) {
            if (selectedComponent.componentName !== "Others") {
                if (selectedSubComponent === null) {
                    return true;
                }
                else{
                    return Disablesubmitbutton;
                }
            }
            else {
                return Disablesubmitbutton;
            }
        }
        else {
            return true;
        }
    }

    //=============== function to set the Feedback Experience =========================//
    const handleFeedbackExperienceChange = (e) => {
        setfeedbackExperience(e.target.value);
    }

    //================ function to submit the Feedback Data========//
    const submitFeedbackData = () => {
        var status = (feedbackCategory === "Bug" || feedbackCategory === "DataIssue") ? "Open" : "";
        var json = {
            Title: title,
            FeedbackType: feedbackCategory,
            Description: description,
            Component: selectedComponent.componentName,
            SubComponent: selectedSubComponent,
            DifficultLevelID: parseInt(selectedDifficulty),
            ExperienceID: parseInt(selectedExperience),
            OverallExperience: feedbackExperience,
            Status: status,
            CreatedBy: username
        };
        closefeedBackPopup();
        CommonAPI.AddFeedbackData(json)
            .then((result: string) => {
                if (result != "-1") {
                    // Refreshing the Survey Data Upon Succesfull Save
                    GetCustomerSurveyData();
                    toastIdRef.current = toast.success(
                        renderToastifyMsg("Feedback has been recorded succesfully",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                    );
                } else {
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Data Cannot be saved succesfully, Please provide Feedback",
                            'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                    );

                }
            });

    }
    const toggle = (e, toggle,index) => {
        const stateCopy = navRoutes;
        const group: any = [];
        navRoutes.map((dhT, id) => {
            if (id === index) {
                dhT.Istoggle = !dhT.Istoggle;
            }
            group.push(dhT);
        })
        setnavRoutes_data(stateCopy);
    }
    const [collapsed, setCollapsed] = useState(true);
    const onMouseEnter = (e, toggle, index)=>{
        const stateCopy = navRoutes;
        const group: any = [];
        navRoutes.map((dhT,id) => {
            if (id === index) {
                dhT.Istoggle = true;
            }
            group.push(dhT);
        })
        setnavRoutes_data(stateCopy);
    }

    const onMouseLeave = (e, toggle, index)=> {
        const stateCopy = navRoutes;
        const group: any = [];
        navRoutes.map((dhT, id) => {
            if (id === index) {
                dhT.Istoggle = false;
            }
            group.push(dhT);
        })
        setnavRoutes_data(stateCopy);
    }
    const ref = useRef(null);
    const toggleNavbar = (e) => {
        if (collapsed == true) {
            document.addEventListener('click', handleClick, false);
            if (menuCountArray.some(x => x.Category == 'Side Nav')) {
                menuCountArray.filter(x => x.pageName == 'SideBarNav')[0].count = menuCountArray.filter(x => x.pageName == 'SideBarNav')[0].count + 1;
            }
            else {
                menuCountArray.push({ 'vid': username.substr(0, username.indexOf('@')), 'pageName': 'SideBarNav', 'count': 1, 'Category': 'Side Nav' })
            }
        }
        else {
            document.removeEventListener('click', handleClick, false)
        }
        setCollapsed(!collapsed);
    }
    const handleClick = (refval) => {
        if (ref.current && !ref.current.contains(refval.target)) {
            setCollapsed(true);
        }
    }
    const onMainMenuMouseEnter = (e, displayName) => {
        if (menuCountArray.some(x => x.pageName == displayName)) {
            menuCountArray.filter(x => x.pageName == displayName)[0].count = menuCountArray.filter(x => x.pageName == displayName)[0].count + 1;
        }
        else {
            menuCountArray.push({ 'vid': username.substr(0, username.indexOf('@')), 'pageName': displayName, 'count': 1, 'Category': 'Main Menu' });
        }
    }

    const onSubMenuMouseEnter = (e, displayName) => {
        if (menuCountArray.some(x => x.pageName == displayName)) {
            menuCountArray.filter(x => x.pageName == displayName)[0].count = menuCountArray.filter(x => x.pageName == displayName)[0].count + 1;
        }
        else {
            menuCountArray.push({ 'vid': username.substr(0, username.indexOf('@')),'pageName': displayName, 'count': 1, 'Category': 'Sub Menu' });
        }
    }
    const closeHambugerMenu = () => {
        setCollapsed(true);
        menuEventTrack();
    }
    let Groups: any = [];
    var subMenu: any = [];
    let date = '';

    if (isArrayWithLength(dataRefreshed)) {
        date = dataRefreshed[0].displayDateAvailableData;
    }

    //Set the Redirect url origin for livesite and changemanagement
    let livesiteUrlOrgin = "https://livesite.idfun-test.msidentity.com";
    let changeMgmtUrlOrgin = "https://changemanagement.idfun-test.msidentity.com";
    let CommsPortalOrgin = "https://comsportal.idfun-test.msidentity.com/";
    if (isProd) {
        livesiteUrlOrgin = "https://livesite.idfun.msidentity.com";
        changeMgmtUrlOrgin = "https://changemanagement.idfun.msidentity.com";
        CommsPortalOrgin = "https://comsportal.idfun.msidentity.com/";
    }

    if (isAuthenticated) {
        return (
            <div>
                <header>
                    <Navbar color='light' light expand='md' fixed='top' className='Menufont'>

                        <NavbarBrand style={{ paddingTop: '10px' }} tag={Link} to='/'><FontAwesomeIconMemo icon={'home'} style={{ fontSize: '17px' }} /> Identity Livesite Portal</NavbarBrand>
                        <NavbarToggler className='mr-2' />
                        <Collapse className={isNavBarEnable ? 'd-sm-inline-flex' : 'd-sm-inline-flex-hide'} navbar>
                            <ul className='navbar-nav flex-grow navbarfontsize'>
                                {navRoutes.map(({ displayName, subMenu, Istoggle }, index) => (
                                    <Dropdown key={index} onMouseOver={e => onMouseEnter(e, Istoggle, index)} onMouseLeave={e => onMouseLeave(e, Istoggle, index)} isOpen={Istoggle} toggle={e => toggle(e, Istoggle, index)}>
                                        <DropdownToggle nav caret onMouseOver={e => onMainMenuMouseEnter(e, displayName)}>
                                            {displayName}
                                        </DropdownToggle>
                                        <DropdownMenu left="true">
                                            {subMenu.map(({ displayName, href }, index) => (
                                                <React.Fragment key={index}>
                                                    {
                                                        (displayName === 'Weekly ID SHR Agenda'
                                                            || displayName === 'Platform BU SHR Agenda'
                                                            || displayName === 'WZTNA-SHR Agenda'
                                                            || displayName === 'Security SHR'
                                                            || displayName === 'Weekly Dublin SHR'
                                                            || displayName === 'MSER Heatmap Preview'
                                                            || displayName === 'MSER Presentation View'
                                                            || displayName === 'Platform Team Tools'
                                                            || displayName === 'Monthly Availability'
                                                            || displayName === 'Monthly Availability Qos'
                                                            || displayName === 'Weekly Availability'
                                                            || displayName === 'Self Serve Config'
                                                            || displayName === 'Critical Change Advisories'
                                                            || displayName === 'MSER Heatmap Trend Preview'
                                                            || displayName === 'Partner Escalation'
                                                            || displayName === 'User Scenario Completion'
                                                            || displayName === 'ID Acktracking Template'
                                                            || displayName === 'Manage Templates'
                                                            || displayName === 'Manage Service Checklists'
                                                            || displayName === 'CheckList Dashboard'
                                                            || displayName === 'Reachability Metrics'
                                                            || displayName === 'ID Acktracking'
                                                            || displayName === 'Future OnCall Details'
                                                            || displayName === 'ID (Internal) Escalation'
                                                            || displayName === 'DC to Azure Region mapping'
                                                            || displayName === 'Create ADO Hierarchy'
                                                            || displayName === 'ADO Task Creation'
                                                            || displayName === 'Tools Configuration'
                                                            || displayName === 'PIR Risk Tool')
                                                            ?
                                                            <DropdownItem tag={Link} to={href} key={index} onMouseOver={e => onSubMenuMouseEnter(e, displayName)} onClick={e => menuEventTrack()}>
                                                                {displayName}
                                                            </DropdownItem>
                                                            :
                                                            // to hide it in Dropdown menu Item
                                                            (displayName === "UI for OCE View" || displayName === "UI for ReadOnly OCE View" || displayName === "Email Configuration" || displayName === "Divisional Objectives and Key Results (OKR)" || displayName === "Service KPI Queries")
                                                                ?
                                                                <React.Fragment></React.Fragment>
                                                                : (displayName === "Last week in Identity Livesite")
                                                                    ?
                                                                    (isAdmin)
                                                                        ?
                                                                        <DropdownItem tag={Link} to={href} key={index} onClick={e => menuEventTrack()}>
                                                                            {displayName}
                                                                        </DropdownItem>
                                                                        : <React.Fragment> </React.Fragment>
                                                                    :
                                                                    // add divider for Dropdown menu Item
                                                                    (displayName === "divider")
                                                                        ?
                                                                        <DropdownItem className='topMenu_dropdown_divider' divider />
                                                                        : (displayName === 'Heatmap Admin Management')
                                                                            ?
                                                                            (isAdmin)
                                                                                ?
                                                                                <DropdownItem tag={Link} to={href} key={index} onClick={e => menuEventTrack()}>
                                                                                    {displayName}
                                                                                </DropdownItem>
                                                                                : <React.Fragment> </React.Fragment>
                                                                            :
                                                                            (displayName === 'ADO Task Creation')
                                                                                ?
                                                                                (isAdmin)
                                                                                    ?
                                                                                    <DropdownItem href={livesiteUrlOrgin + href} target='_self' key={index} onClick={e => menuEventTrack()}>
                                                                                        {displayName}
                                                                                    </DropdownItem>
                                                                                    : <React.Fragment> </React.Fragment>
                                                                                :
                                                                                ((displayName === 'ICM PIR Repair ITems') ||
                                                                                    (displayName === 'Supportability') ||
                                                                                    (displayName === 'CheckList User Guide') ||
                                                                                    (displayName === 'On-Call Wiki') ||
                                                                                    (displayName === 'Identity Scenario Completion') ||
                                                                                    (displayName === 'Identity KPI Report (OneDash)') ||
                                                                                    (displayName === 'ID Safe Fly') ||
                                                                                    (displayName === 'CR Lockdowns'))
                                                                                    ?
                                                                                    <DropdownItem href={href} target='_blank' key={index} onClick={e => menuEventTrack()}>
                                                                                        {displayName}
                                                                                    </DropdownItem>
                                                                                    : (displayName === 'Identity Sev 1 Bridge Details')
                                                                                        ?
                                                                                        <DropdownItem href={CommsPortalOrgin + href} target='_blank' key={index}>
                                                                                            {displayName}
                                                                                        </DropdownItem>
                                                                                        :
                                                                                        <DropdownItem href={livesiteUrlOrgin + href} target='_self' key={index}>
                                                                                            {displayName}
                                                                                        </DropdownItem>

                                                    }
                                                </React.Fragment>
                                            ))}

                                        </DropdownMenu>
                                    </Dropdown>
                                ))}
                                <NavItem className="customNavlink">
                                    <NavLink href="https://msit.powerbi.com/groups/d9ea93ad-da2a-4b36-a6e9-ada897de91cd/reports/33be6543-0398-4405-8d59-6800a99ffba3/ReportSectionc233f5128b10a017c586" target='_blank'>Risk management</NavLink>
                                </NavItem>
                            </ul>
                        </Collapse>

                    </Navbar>
                    <div
                        ref={ref}
                    >
                        <Navbar color="faded" light style={{ backgroundColor: 'transparent', position: 'fixed', marginTop: '1%', width: '3%' }} >

                            <NavbarToggler onClick={toggleNavbar} className='mr-2' />
                            <Collapse isOpen={!collapsed} navbar>
                                <Nav navbar style={{ backgroundColor: '#F2F2F2', width: '22%', position: 'fixed', height: '87%', overflowY: 'scroll', paddingLeft: '10px' }}>
                                    {isArrayWithLength(serviceGroup) && serviceGroup.map(({ groupName, enableservicescount }, index) => (
                                        <React.Fragment key={index}>
                                            {
                                                (GroupName !== groupName)
                                                    ?
                                                    < NavItem>

                                                        <React.Fragment>{setGroupName(groupName)} {(enableservicescount > 0) ? <React.Fragment><div style={{ fontSize: '12px', color: 'black' }}>  {(groupName.split('<br/>').length > 1) ? groupName.split('<br/>')[1] : groupName.split('<br/>')[0]}</div></React.Fragment> : <React.Fragment></React.Fragment>}</React.Fragment>

                                                        <ul style={{ paddingLeft: '20px' }}>{serviceGroup.map(({ groupName, serviceName, serviceId, IServiceGroupChecked }, index) => (
                                                            <React.Fragment key={index}>
                                                                {
                                                                    (groupName == GroupName)
                                                                        ?
                                                                        (IServiceGroupChecked)
                                                                            ?
                                                                            <li className="ServiceNameStyle" key={index} ><span onClick={closeHambugerMenu}> <Link style={{ color: 'inherit', textDecoration: 'none' }} to={'/ServiceHealth?serviceid=' + serviceId}>{serviceName}</Link></span></li>
                                                                            : <React.Fragment></React.Fragment>
                                                                        : <React.Fragment></React.Fragment>
                                                                }
                                                            </React.Fragment>
                                                        ))}</ul>

                                                    </NavItem>
                                                    :
                                                    <React.Fragment></React.Fragment>
                                            }

                                        </React.Fragment>
                                    ))}
                                </Nav>
                            </Collapse>
                        </Navbar>
                    </div>
                    <br />
                    <table className='navtable'>
                        <tbody>
                            <tr style={{ height: '50px' }}>
                                <td style={{ width: '5%' }}></td>
                                <td style={{ width: '25%', verticalAlign: 'middle' }}>
                                    <Link title='Click for Availability Summary of Current Week' className='btn btn-primary btn-sm headerbutton' id='manualbtn' style={{ backgroundColor: '#7f7f7f' }} to='/AvailabilitySummary'>
                                        <b> Availability Summary </b>
                                    </Link>
                                </td>
                                <td style={{ width: '35%', verticalAlign: 'middle', fontSize: '14px' }}>
                                    Date Refreshed :
                                    {(date !== '') ? <span>
                                        <b> {moment(date).format('DD-MMM (YYYY) @ h:mma')}</b>
                                    </span> : <span></span>}
                                </td>

                                <td style={{ width: '10%' }} className={isAdmin ? 'adminFormat' : 'nonAdminFormat'}>
                                    {/*<a style={{ textDecoration: 'none', color: 'white' }} href={'/AdminSettings'}>  <FontAwesomeIconMemo icon={faCog} /> Admin Setting </a>*/}
                                    <Link title='Click for Admin Setting' to='/AdminSettings' style={{ textDecoration: 'none', color: 'white' }}>
                                        <FontAwesomeIconMemo icon={'cog'} /> <b> Admin Settings </b>
                                    </Link>
                                </td>
                                <td style={{ width: '12%' }} className="headerfontsize">
                                    <a style={{ color: 'white' }} href='mailto:livesiteauto@microsoft.com?Subject=Livesite Weekly report automation Issue ' target='_top'><FontAwesomeIconMemo icon={'info-circle'} /> Report an Issue</a>
                                </td>
                                <td style={{ width: '8%' }} className="headerfontsize">
                                    <span onClick={e => openfeedBackPopup(e)} className="feedback" style={{ color: 'white' }}><FontAwesomeIconMemo icon={'comment'} /> Feedback</span>
                                </td>
                                <td style={{ width: '5%' }}>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </header>
                <Modal show={showfeedBackPopup} onHide={closefeedBackPopup} size='xl' backdrop='static'>
                    <Modal.Body>
                        {
                            showcustomersurveyPopup === true ?
                                <React.Fragment>
                                    <div style={{ width: '100%' }}>
                                        <div className="FeedbackBoxShadowHeader" style={{ width: '100%' }}>
                                            <Row style={{ height: '50px' }}>
                                                <Col lg='3'>
                                                </Col>
                                                <Col lg='6'>
                                                    <h4 style={{ textAlign: 'center', paddingTop: '8px' }}><b>Customer Satisfaction Survey</b></h4>
                                                </Col>
                                                <Col lg='3' style={{ paddingTop: '5px', paddingBottom: '5px', paddingRight: '25px', float: 'right' }} >
                                                    <Button className='FeedbackModelbuttons' onClick={() => BacktoFeedbackForm()}>Back</Button>&ensp;
                                                    <Button className='FeedbackModelbuttons' onClick={() => closefeedBackPopup()}>Close</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row className='feedbackFormPad'>
                                                <Col lg='6'>
                                                    <div style={{ fontSize: 'medium', fontWeight: 'bold' }} ><label>&ensp;&ensp; TOTAL VISITED USER: </label>&ensp;&ensp;{totalVisitedUser}</div>
                                                </Col>
                                                <Col lg='6'>
                                                    <div style={{ fontSize: 'medium', fontWeight: 'bold' }}><label>&ensp;&ensp; MOST VISITED PAGE: </label>&ensp;&ensp;{mostVisitedPage}</div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="FeedbackBoxShadow" style={{ width: '100%' }}>

                                            <Row className='feedbackFormPad'>
                                                <Col lg='6'>
                                                    <div style={{ paddingBottom: '10px' }}>
                                                        <Row style={{ backgroundColor: 'lightcyan', borderRight: '1px solid white', fontSize: 'medium', fontWeight: 'bold' }}> <label>&ensp;&ensp;How difficult or easy was it to use this tool in meeting your Identity engineering needs?</label></Row>
                                                    </div>
                                                    {customerSurveyDifficultyLevelData.map((row, index) => (
                                                        <React.Fragment key={index}>
                                                            {
                                                                <div>
                                                                    <div style={{ display: 'inline-block', width: '12vw' }}><label>{row.DifficultLevel}</label>&ensp;&ensp;</div>
                                                                    <div style={{ display: 'inline-block', width: '20vw' }}><ProgressBar className="ProgressBarFeedback" value={valParse(row.value)}></ProgressBar></div>
                                                                </div>
                                                            }
                                                        </React.Fragment>
                                                    ))}
                                                </Col>
                                                <Col lg='6'>
                                                    <div style={{ paddingBottom: '10px'}}>
                                                        <Row style={{ backgroundColor: 'lightcyan', fontSize: 'medium', fontWeight: 'bold' }}>
                                                            <label>&ensp;&ensp;How would you rate your experience with our tool?</label>
                                                        </Row>
                                                    </div>
                                                    {customerSurveyExperienceData.map((row, index) => (
                                                        <React.Fragment key={index}>
                                                            {
                                                                <div>
                                                                    <div style={{ display: 'inline-block', width: '15vw' }}><label>{row.Experience}</label>&ensp;&ensp;</div>
                                                                    <div style={{ display: 'inline-block', width: '20vw' }}><ProgressBar className="ProgressBarFeedback" value={valParse(row.value)}></ProgressBar></div>
                                                                </div>
                                                            }
                                                        </React.Fragment>
                                                    ))}
                                                </Col>
                                            </Row>

                                            <br/>
                                            <Row className='feedbackFormPad'>
                                                <TabView style={{ width: '100%' }} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                                                    <TabPanel header="Feedback" headerClassName="PanelHeaderColoring" contentClassName="PanelHeightFeedback">
                                                        {returnTableBody()}
                                                    </TabPanel>
                                                    <TabPanel header="Bug" contentClassName="PanelHeightFeedback">
                                                        {returnTableBody()}
                                                    </TabPanel>
                                                    <TabPanel header="Data Issue" contentClassName="PanelHeightFeedback">
                                                        {returnTableBody()}
                                                    </TabPanel>
                                                    <TabPanel header="Others" contentClassName="PanelHeightFeedback">
                                                        {returnTableBody()}
                                                    </TabPanel>
                                                </TabView>
                                            </Row>
                                        </div>
                                    </div>
                                </React.Fragment>
                                : <React.Fragment>
                                    <div>
                                        <div style={{ width: '100%' }}>
                                            <div className="FeedbackBoxShadowHeader" style={{ width: '100%' }}>
                                                <Row style={{ height: '50px' }}>
                                                    <Col lg='2'>
                                                    </Col>
                                                    <Col lg='8'>
                                                        <h4 style={{ textAlign: 'center', paddingTop: '8px' }}><b>Customer Feedback Form</b></h4>

                                                    </Col>
                                                    <Col lg='2' style={{paddingTop: '5px', paddingBottom: '5px', paddingRight: '25px'}}>
                                                        <Button className='FeedbackModelbuttons' style={{ float: 'right' }} onClick={() => setshowcustomersurveyPopup(true)}>Survey Report</Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Row className='feedbackFormPad'>
                                                <Col lg='3' style={{ paddingLeft: '0px' }}>
                                                    <div className="p-field p-col-10" >
                                                        <span className="p-float-label">
                                                            <PrimeDropdown id='component' className="ComponentContent" style={{ width: '200px' }} value={selectedComponent} options={componentSubComponentData} onChange={(e) => componentSelected(e.value)} optionLabel="componentName" />
                                                            <label htmlFor="component" className="fontcolor">Select Component</label>
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col lg='9'>
                                                    <Row className='feedbackFormPad'>
                                                        {SubComponentData.map((displayName, index) => (
                                                            <React.Fragment key={index}>
                                                                {
                                                                    <div className="p-field p-col-10">
                                                                        <div>
                                                                            <RadioButton id="subcomponent" name={displayName} value={displayName} onChange={e => setselectedSubComponentVal(e.value, e.checked)} checked={selectedSubComponent === displayName}></RadioButton>&ensp;{displayName}&ensp;&ensp;
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </React.Fragment>
                                                        ))}
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row style={{ paddingTop: '15px' }}>
                                                <Col lg='12'></Col>
                                            </Row>
                                        </div>
                                        <div className="FeedbackBoxShadow" style={{ width: '100%' }}>

                                            <Row className='feedbackFormPad'>
                                                <Col lg='6' style={{ borderRight: '1px solid grey' }}>
                                                    <div>
                                                        <div><label> 1. Feedback Category&nbsp;<span style={{ fontWeight: 'bold', color: 'red' }}>*</span></label></div>
                                                        <div className="divcontentpaddingFeedback"><input type="radio" value="Bug" name="Bug" onChange={(e) => setfeedbackCategoryVal(e.target.value)} checked={feedbackCategory === 'Bug'} />&nbsp; Bug&nbsp;</div>
                                                        <div className="divcontentpaddingFeedback"><input type="radio" value="DataIssue" name="DataIssue" onChange={(e) => setfeedbackCategoryVal(e.target.value)} checked={feedbackCategory === 'DataIssue'} />&nbsp; Data Issue&nbsp;</div>
                                                        <div className="divcontentpaddingFeedback"><input type="radio" value="Feedback" name="Feedback" onChange={(e) => setfeedbackCategoryVal(e.target.value)} checked={feedbackCategory === 'Feedback'} />&nbsp; Feedback&nbsp;</div>
                                                        <div className="divcontentpaddingFeedback"><input type="radio" value="Others" name="Others" onChange={(e) => setfeedbackCategoryVal(e.target.value)} checked={feedbackCategory === 'Others'} />&nbsp; Others&nbsp;</div>
                                                    </div>
                                                    <div style={{ paddingTop: '10px' }}>
                                                        <div><label> 2. Feedback/Issue Title&nbsp;<span style={{ fontWeight: 'bold', color: 'red' }}>*</span></label></div>
                                                        <div className="divcontentpaddingFeedback">
                                                            <input type="text" placeholder="Enter Text" name="title" className="form-control" value={title} onChange={e => handleFeedbackTitleChange(e)} />
                                                        </div>
                                                    </div>
                                                    <div style={{ paddingTop: '10px' }}>
                                                        <div><label> 3. Feedback/Issue Description&nbsp;<span style={{ fontWeight: 'bold', color: 'red' }}>*</span></label></div>
                                                        <div className="divcontentpaddingFeedback">
                                                            <textarea placeholder="Enter Text" name="FeedbackDescription" className="form-control txtareaheightfeedback" value={description} onChange={e => handleFeedbackDescriptionChange(e)}></textarea>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg='6'>
                                                    <div>
                                                        <div><label> 4. How difficult or easy was it to use this tool in meeting your Identity engineering needs?&nbsp;<span style={{ fontWeight: 'bold', color: 'red' }}>*</span></label></div>
                                                        {difficultLevelData.map((row, index) => (
                                                            <React.Fragment key={index}>
                                                                {
                                                                    <div className="divcontentpaddingFeedback"><input type="radio" value={row.difficultLevelID} name={row.difficultLevel} onChange={(e) => setselectedDifficultyVal(e.target.value)} checked={selectedDifficulty === (row.difficultLevelID).toString()} />&nbsp;{row.difficultLevel}</div>
                                                                }
                                                            </React.Fragment>
                                                        ))}
                                                    </div>
                                                    <div style={{ paddingTop: '10px' }}>
                                                        <div><label> 5. How would you rate your experience with our tool?&nbsp;<span style={{ fontWeight: 'bold', color: 'red' }}>*</span></label></div>
                                                        {experienceData.map((row, index) => (
                                                            <React.Fragment key={index}>
                                                                {
                                                                    <div className="divcontentpaddingFeedback"><input type="radio" value={row.experienceID} name={row.experience} onChange={(e) => setselectedExperienceVal(e.target.value)} checked={selectedExperience === (row.experienceID).toString()} />&nbsp; {row.experience}</div>
                                                                }
                                                            </React.Fragment>
                                                        ))}

                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className='feedbackFormPad'>
                                                <Col lg='12'>
                                                    <div>
                                                        <div><label>Please tell us more about your experience with this tool (what do you like and/or what can be improved)</label></div>
                                                        <div>
                                                            <textarea placeholder="Enter Text" name="FeedbackExperience" className="form-control txtareaheightfeedback" value={feedbackExperience} onChange={e => handleFeedbackExperienceChange(e)}></textarea>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <br />
                                            <div style={{ width: '100%' }}>
                                                <Row>
                                                    <Col lg='4'></Col>
                                                    <Col lg='4' style={{ textAlign: 'center' }}>
                                                        <Button className='FeedbackModelbuttons' onClick={submitFeedbackData} disabled={saveDisable()}>Submit</Button>&ensp;&ensp;
                                                        <Button className='FeedbackModelbuttons' onClick={closefeedBackPopup}>Cancel</Button>
                                                    </Col>
                                                    <Col lg='4'></Col>
                                                </Row>
                                                <Row style={{ paddingTop: '15px' }}>
                                                    <Col lg='12'></Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                        }
                    </Modal.Body>
                </Modal>
            </div>
        );
    } else { return (<div></div>);}
};

const mapStateToProps = (state: IApplicationState) => ({
    isAuthenticated: state.authUser.isAuthenticated,
    isProd: state.authUser.isProd,
    username: state.authUser.login,
    dataRefreshed: ((state.serviceHealth.selectedServiceId == 0) ? state.authUser.DataRefreshed : state.serviceHealth.DateRefreshedByDataLoader),
    serviceGroup: (isArrayWithLength(state.serviceGroup.serviceGroup) ? state.serviceGroup.serviceGroup : state.authUser.serviceGroup),
    isAdmin: state.authUser.isAdmin,
    isNavBarEnable: state.authUser.isNavBarEnable
});

export default connect(mapStateToProps)(NavbarMenu);
