import { actionCreators } from './action';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, ICreateAdoHierarchyState } from './types';
import RichTextEditor from 'react-rte';

const initialState = Object.freeze<ICreateAdoHierarchyState>({   
    isLoading: true,
    gemServiceMappingData: [],
    nodeData: [],
    checkedServices: [],
    description: RichTextEditor.createEmptyValue(),
    selectedServices: [],
    chapterID: "",
    epicTitle: "",
    epicID: "",
    iterationPath: "",
    previousTitle: "",
    id: "",
    errorMsg: ""
});

export const reducer = (
    state: ICreateAdoHierarchyState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type)
    {
        case ActionType.RESET_STATE:
            return initialState;
        case ActionType.GET_GEMSERVICE_SUCCESS:
            return {
                ...state,               
                gemServiceMappingData: action.gemServiceMappingData,
                nodeData: action.nodeData,
                isLoading: false
            };
        case ActionType.GET_GEMSERVICE_FAILURE:
            return {
                ...state,
                gemServiceMappingData: [],
                nodeData: []
            };
        case ActionType.SET_CHECKED_VALUES:
            return {
                ...state,
                checkedServices: action.checkedServices
            };
        case ActionType.SET_DESCRIPTION_VALUES:
            return {
                ...state,
                description: action.description
            };
        case ActionType.SET_CHAPTERID_VALUES:
            return {
                ...state,
                chapterID: action.chapterID
            };
        case ActionType.SET_EPICTITLE_VALUES:
            return {
                ...state,
                epicTitle: action.epicTitle
            };
        case ActionType.SET_EPICID_VALUES:
            return {
                ...state,
                epicID: action.epicID
            };
        case ActionType.SET_ITERATIONPATH_VALUES:
            return {
                ...state,
                iterationPath: action.iterationPath
            };
        case ActionType.SET_NODEDATA_VALUES:
            return {
                ...state,
                nodeData: action.nodeData,
                checkedServices: action.checkedServices
            };
        case ActionType.CREATE_ADOTREE:            
            return {
                ...state,
                previousTitle: action.previousTitle,
                id: action.id,
                errorMsg: action.errorMsg,
                iterationPath: "",
                epicTitle: "",
                epicID: "",
                chapterID: "",
                description: RichTextEditor.createEmptyValue()
            }
        case ActionType.CREATE_ADOTREE_FAILED:            
            return {
                ...state,
                previousTitle: "",
                id: "",
                errorMsg: action.errorMsg,
                iterationPath: "",
                epicTitle: "",
                epicID: "",
                chapterID: "",
                description: RichTextEditor.createEmptyValue()
            }            
        default:
            return state;
    }
};