import { BaseService } from './base.service';
import { IPropOnCallDashboardChecklist, IPropOnCallChecklistTemplate, IPropOnCallChecklistQuestionnaire, IPropOnCallChecklistService, OCEView, MeetingEntityForOce, ChecklistQuestionsForOce, ChecklistQuestionsByChoiceForOce, Incidents } from '../store/OnCallChecklist';

/**
 * API abstraction layer communication via Axios (typescript singleton pattern)
 */
class OnCallChecklistService extends BaseService {
    private static _serviceGroupService: OnCallChecklistService;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): OnCallChecklistService {
        return (this._serviceGroupService || (this._serviceGroupService = new this('OnCallChecklist')));
    }

    public async GetMeetingMainIncidentIds(): Promise<any> {
        const { data } = await this.$http.get<any>(`GetMeetingMainIncidentIds`);
        return data;
    }

    public async GetChecklistServicesforOCEView(): Promise<any> {
        const { data } = await this.$http.get<any>(`GetChecklistServicesforOCEView`);
        return data;
    }

    public async GetChecklistServicesforOCEViewReadonly(): Promise<any> {
        const { data } = await this.$http.get<any>(`GetChecklistServicesforOCEViewReadonly`);
        return data;
    }
    
    public async GetSubmittedByList(): Promise<any> {
        const { data } = await this.$http.get<any>(`GetSubmittedByList`);
        return data;
    }
    
    public async GetChecklistSforOCEView(serviceId): Promise<any> {
        const { data } = await this.$http.get<any>(`GetChecklistSforOCEView?serviceId=${serviceId}`);
        return data;
    }

    public async GetOCEList(serviceId, checklistID, fromDate, toDate, createdby, mainIncidentIdFilter, templateType): Promise<OCEView[]> {
        var mainIncidentId = mainIncidentIdFilter === 0 ? '' : mainIncidentIdFilter; 
        const { data } = await this.$http.get<OCEView[]>(`GetOCEList?serviceId=${serviceId}&checklistID=${checklistID}&fromDate=${fromDate}&toDate=${toDate}&submittedBy=${createdby}&mainIncidentId=${mainIncidentId}&templateType=${templateType}`);
        return data;
    }

    public async GetMeetingItemsForOCE(serviceId, date, checklistID, meetingId): Promise<MeetingEntityForOce[]> {
        const { data } = await this.$http.get<MeetingEntityForOce[]>(`GetMeetingItemsForOCE?serviceId=${serviceId}&date=${date}&checklistID=${checklistID}&meetingId=${meetingId}`);
        return data;
    }

    public async ValidateIncidentId(incidentId): Promise<boolean> {
        const { data } = await this.$http.get<boolean>(`ValidateIncidentId?incidentId=${incidentId}`);
        return data;
    }
    
    public async GetChecklistQuestionsByChoiceForOCE(objChecklistQuestionsByChoice: ChecklistQuestionsForOce): Promise<ChecklistQuestionsByChoiceForOce[]> {
        const { data } = await this.$http.post<ChecklistQuestionsByChoiceForOce[]>("GetChecklistQuestionsByChoiceForOCE", objChecklistQuestionsByChoice);
        return data;
    }

    public async InsertChecklistAnswersForOCE(objActionitems, serviceId, checklistId, createdBy, meetingDate, onCallDate, meetingId, meetingTitle, icmMainIncident): Promise<number> {
        const { data } = await this.$http.post<number>(`InsertChecklistAnswersForOCE?serviceId=${serviceId}&checklistId=${checklistId}&createdBy=${createdBy}&meetingDate=${meetingDate}&onCallDate=${onCallDate}&meetingId=${meetingId}&meetingTitle=${meetingTitle}&icmMainIncident=${icmMainIncident}`, objActionitems);
        return data;
    }
    
    public async InsertUpdateChecklistActionItemsForOCE(objMeetingEntity): Promise<number> {
        const { data } = await this.$http.post<number>("InsertUpdateChecklistActionItemsForOCE", objMeetingEntity);
        return data;
    }

    public async GetQuestionsForESTSService(obj): Promise<ChecklistQuestionsByChoiceForOce[]> {
        const { data } = await this.$http.post<ChecklistQuestionsByChoiceForOce[]>("GetQuestionsForESTSService", obj);
        return data;
    }
    
    public async GetChecklistIncidentsForOCE(servicename, fromDate, toDate): Promise<Incidents[]> {
        const { data } = await this.$http.get<Incidents[]>(`GetChecklistIncidentsForOCE?serviceName=${servicename}&fromDate=${fromDate}&toDate=${toDate}`);
        return data;
    }

    public async DeleteChecklistFile(meetingId, fileName): Promise<boolean> {
        const { data } = await this.$http.get<boolean>(`DeleteChecklistFile?meetingId=${meetingId}&fileName=${fileName}`);
        return data;
    }

    public async GetChecklistFile(meetingId, fileName): Promise<boolean> {
        const { data } = await this.$http.get<boolean>(`GetChecklistFile?meetingId=${meetingId}&fileName=${fileName}`);
        return data;
    }
    
    public async GetOnCallCheckListQuestions(checkListID): Promise<IPropOnCallChecklistQuestionnaire[]> {
        const { data } = await this.$http.get<IPropOnCallChecklistQuestionnaire[]>(`GetOnCallCheckListQuestions?checkListID=${checkListID}`);
        return data;
    }

}

export const OnCallChecklistAPI = OnCallChecklistService.Instance;
