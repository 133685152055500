import { CommonAPI, AcktrackingAPI } from '../../api';
import { IAppThunkAction, ReduxAction } from '../';
import { ActionType } from './types';
import moment from 'moment';
import { IMonthlyReport } from '../service-health/types';
import { isArrayWithLength, isNullOrUndefined, renderToastifyMsg } from '../../utils';
import { debug } from 'console';
let mainIdentityAckData = [];
let MainServiceManagerList = [];
let Mainserviceslist = [];
let MainComponentlist = [];
let MainIDClassificationList = [];
let MainServiceLifeCyclelist = [];
let MainServiceTypeList = [];
let MainServiceRinglist = [];
let IsOldAckDeploymentData = false;

export const actionCreators = {
    resetState: (): ReduxAction => ({
        type: ActionType.RESET_STATE
    }),
    setloadingtrue: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_TRUE
        });
    },
    setloadingfalse: (): IAppThunkAction<ReduxAction> => (dispatch) => {
        dispatch({
            type: ActionType.SET_LOADING_FALSE
        });
    },
    addUserVisit: (serviceId: number, Pagename: string): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let LoginuserId;
        LoginuserId = getState().authUser.login;
        if (LoginuserId !== undefined) {
            LoginuserId = LoginuserId.substr(0, LoginuserId.indexOf('@'));
            CommonAPI.AddUserVisitInfo(LoginuserId, Pagename, serviceId)
                .then((id: string) => {
                    if (!isNullOrUndefined(id)) {
                        dispatch({
                            type: ActionType.ADDED_VISITED_USER
                        });
                    }
                })
        }

    },
    GetAcktrackingData: (notificationACKId:number,Isreload): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.ACKTRACKING_DATA_FETCH
        });
        let ackdata = [];
        AcktrackingAPI.GetIdentityAckTrainingData(notificationACKId)
            .then((AcktrackingData: any) => {
                let headerdata = [];
                let IdentityAckData = [];
                IsOldAckDeploymentData = checkAckExists(notificationACKId);

                if (!isNullOrUndefined(AcktrackingData)) {
                    if (AcktrackingData.table.length > 0) {
                        headerdata = AcktrackingData.table;
                        IdentityAckData = AcktrackingData.table1
                        mainIdentityAckData = IdentityAckData;
                    }
                    else {
                        mainIdentityAckData = [];
                    }
                }
                let result = CheckEmailPOPup(headerdata);
                let state = getState().IDAcktracking;
                let ServiceManagervalue = state.ServiceManagervalue;
                let Servicevalue = state.Servicevalue;
                let Componentvalue = state.Componentvalue;
                let ServiceLifeCyclevalue = state.ServiceLifeCyclevalue;
                let ServiceTypevalue = state.ServiceTypevalue;
                let IDClassificationvalue = state.IDClassificationvalue;
                let ServiceRingvalue = state.ServiceRingvalue;
                let counttofilterdatawhilepageload = state.counttofilterdatawhilepageload;
                if (Isreload === 1) {
                    ackdata = filterData(ServiceTypevalue, mainIdentityAckData, Servicevalue, Componentvalue, ServiceManagervalue, ServiceLifeCyclevalue, ServiceRingvalue, IDClassificationvalue);
                }


                dispatch({
                    AcktrackingData,
                    headerdata,
                    IdentityAckData: (Isreload === 1) ? ackdata: IdentityAckData,
                    Iscontact_DevOwner: result.Iscontact_DevOwner,
                    Iscontact_PMOwner: result.Iscontact_PMOwner,
                    IslastUpdatedBy: result.IslastUpdatedBy,
                    counttofilterdatawhilepageload: counttofilterdatawhilepageload+1,
                    type: ActionType.ACKTRACKING_DATA_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.ACKTRACKING_DATA_FAIL
                });
            });

    },
    GetDataforAlldropdowns: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        AcktrackingAPI.GetServiceManagers()
            .then((ServiceManagerList: any) => {
                let managrslist = [];
                let ServiceManagervalue = [];
                if (isArrayWithLength(ServiceManagerList)) {
                    MainServiceManagerList = [];
                    ServiceManagerList.map((obj) => {
                        managrslist.push({ name: obj.label, value: obj.label });
                        MainServiceManagerList.push({ name: obj.label, value: obj.label });
                        ServiceManagervalue.push(obj.label);
                    })
                }
                let counttofilterdatawhilepageload = getState().IDAcktracking.counttofilterdatawhilepageload;
                dispatch({
                    managrslist,
                    ServiceManagervalue,
                    counttofilterdatawhilepageload: counttofilterdatawhilepageload + 1,
                    type: ActionType.FETCH_SERVICEMANGERLIST_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.FETCHSERVICEMANGERLIST_FAIL
                });
            });
        AcktrackingAPI.GetServiceNameList()
            .then((ServicesList: any) => {
                let serlist = [];
                let Servicevalue = [];
                if (isArrayWithLength(ServicesList)) {
                    Mainserviceslist = [];
                    ServicesList.map((obj) => {
                        Servicevalue.push(obj.serviceName );
                        serlist.push({ name: obj.serviceName, value: obj.serviceName });
                        Mainserviceslist.push({ name: obj.serviceName, value: obj.serviceName });
                    })
                }
                let counttofilterdatawhilepageload = getState().IDAcktracking.counttofilterdatawhilepageload;
                dispatch({
                    serlist,
                    Servicevalue,
                    counttofilterdatawhilepageload: counttofilterdatawhilepageload + 1,
                    type: ActionType.FETCH_SERVICELIST_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.FETCHSERVICELIST_FAIL
                });
            });
        AcktrackingAPI.GetComponentNameList()
            .then((ComponentList: any) => {
                let Complist = [];
                let Componentvalue = [];
                if (isArrayWithLength(ComponentList)) {
                    MainComponentlist = [];
                    ComponentList.map((obj) => {
                        Componentvalue.push(obj.label);
                        Complist.push({ name: obj.label, value: obj.label });
                        MainComponentlist.push({ name: obj.label, value: obj.label });
                    })
                }
                let counttofilterdatawhilepageload = getState().IDAcktracking.counttofilterdatawhilepageload;
                dispatch({
                    Complist,
                    Componentvalue,
                    counttofilterdatawhilepageload: counttofilterdatawhilepageload + 1,
                    type: ActionType.FETCH_COMPONENTLIST_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.FETCHCOMPONENTLIST_FAIL
                });
            });

        AcktrackingAPI.GetServiceTypeList()
            .then((ServiceTypeList: any) => {
                let sertypelist = [];
                let ServiceTypevalue = [];
                if (isArrayWithLength(ServiceTypeList)) {
                    MainServiceTypeList = [];
                    ServiceTypeList.map((obj) => {
                        ServiceTypevalue.push(obj.label );
                        sertypelist.push({ name: obj.label, value: obj.label });
                        MainServiceTypeList.push({ name: obj.label, value: obj.label });


                    })
                }
                let counttofilterdatawhilepageload = getState().IDAcktracking.counttofilterdatawhilepageload;
                dispatch({
                    sertypelist,
                    ServiceTypevalue,
                    counttofilterdatawhilepageload: counttofilterdatawhilepageload + 1,
                    type: ActionType.FETCH_SERVICETYPELIST_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.FETCHSERVICETYPELIST_FAIL
                });
            });

        AcktrackingAPI.GetServiceRingList()
            .then((srlist: any) => {
                let ServiceRinglist = [];
                let ServiceRingvalue = [];
                if (isArrayWithLength(srlist)) {
                    MainServiceRinglist = [];
                    srlist.map((obj) => {
                        ServiceRingvalue.push(obj.label);
                        ServiceRinglist.push({ name: obj.label, value: obj.label });
                        MainServiceRinglist.push({ name: obj.label, value: obj.label });
                    })
                }
                let counttofilterdatawhilepageload = getState().IDAcktracking.counttofilterdatawhilepageload;
                dispatch({
                    ServiceRinglist,
                    ServiceRingvalue,
                    counttofilterdatawhilepageload: counttofilterdatawhilepageload + 1,
                    type: ActionType.FETCH_SERVICERINGLIST_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.FETCHSERVICERINGLIST_FAIL
                });
            });
        AcktrackingAPI.GetServiceLifeCycleList()
            .then((sllist: any) => {
                let ServiceLifeCyclelist = [];
                let ServiceLifeCyclevalue = [];
                if (isArrayWithLength(sllist)) {
                    MainServiceLifeCyclelist = [];
                    sllist.map((obj) => {
                        ServiceLifeCyclevalue.push(obj.label);
                        ServiceLifeCyclelist.push({ name: obj.label, value: obj.label });
                        MainServiceLifeCyclelist.push({ name: obj.label, value: obj.label });
                    })
                }
                let counttofilterdatawhilepageload = getState().IDAcktracking.counttofilterdatawhilepageload;
                dispatch({
                    ServiceLifeCyclelist,
                    ServiceLifeCyclevalue,
                    counttofilterdatawhilepageload: counttofilterdatawhilepageload + 1,
                    type: ActionType.FETCH_SERVICELIFECYCLELIST_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.FETCHSERVICELIFECYCLELIST_FAIL
                });
            });

        AcktrackingAPI.GetIDClassificationList()
            .then((classificationlist: any) => {
                let IDClassificationList = [];
                let IDClassificationvalue = [];
                if (isArrayWithLength(classificationlist)) {
                    MainIDClassificationList = [];
                    classificationlist.map((obj) => {
                        IDClassificationvalue.push(obj.label);
                        IDClassificationList.push({ name: obj.label, value: obj.label });
                        MainIDClassificationList.push({ name: obj.label, value: obj.label });
                    })
                }
                let counttofilterdatawhilepageload = getState().IDAcktracking.counttofilterdatawhilepageload;
                dispatch({
                    IDClassificationList,
                    IDClassificationvalue,
                    counttofilterdatawhilepageload: counttofilterdatawhilepageload + 1,
                    type: ActionType.FETCH_IDCLASSIFICATION_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.FETCH_IDCLASSIFICATION_FAIL
                });
            });
        AcktrackingAPI.GetTrackingType()
            .then((Acktypedata: any) => {
                let IDAckTypeList = [];
                let title = '';
                IDAckTypeList.push({ name: 'Select AckType', value: '0', title: '', isServiceSpecific: '' });
                if (isArrayWithLength(Acktypedata)) {
                    Acktypedata.map((obj) => {
                        //if (obj.notificationACKId.toString() === '10') {
                        //    title = obj.description;
                        //}
                        IDAckTypeList.push({ name: obj.title, value: obj.notificationACKId.toString(), title: obj.description, isServiceSpecific: obj.isServiceSpecific });
                    })
                }
                dispatch({
                    IDAckTypeList,
                    title,
                    type: ActionType.FETCH_IDACKTYPE_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.FETCH_IDACKTYPE_FAIL
                });
            });
        AcktrackingAPI.GetFilterData()
            .then((fliterdata: any) => {
                let AcktrackingFilterList = [];
                AcktrackingFilterList.push({ name: 'Select Filter', value:'0', filters: '' });
                if (isArrayWithLength(fliterdata)) {
                    fliterdata.map((obj) => {
                        AcktrackingFilterList.push({ name: obj.label, value: obj.id, filters: obj.filters });
                    })
                }
                dispatch({
                    AcktrackingFilterList,
                    type: ActionType.FETCH_IDACKFILTERLIST_SUCCESS
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.FETCH_IDACKFILTERLIST_FAIL
                });
            });
    },
    ServiceManagerChange: (value): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let servicesList = [];
        let selectServicesList = [];

        let componentList = [];
        let selectComponentList = [];

        let ServiceLifeCycleList = [];
        let selectServiceLifeCycleList = [];
        let ServiceTypeList = [];
        let selectServiceTypeList = [];

        let ServiceRingList = [];
        let selectServiceRingList = [];

        let IDClassificationList = [];
        let selectIDClassificationList = [];
        if (isArrayWithLength(value)) {
            value.map((row) => {
                if (isArrayWithLength(mainIdentityAckData)) {
                    mainIdentityAckData.map((r) => {
                        let servicematch = false;
                        let componentmatch = false;
                        let lifecyclematch = false;
                        let servicetypematch = false;
                        let serviceringmatch = false;
                        let idclassificationmatch = false;
                        if (r.geM_Filter !== undefined && row.toLowerCase() === r.geM_Filter.toLowerCase()) {
                            //================ for servicename insert =====================//
                            if (servicesList != undefined) {
                                for (var i = 0; i < servicesList.length; i++) {
                                    servicematch = false;
                                    servicesList.map((rs) => {
                                        if (rs.value === r.serviceName) {
                                            servicematch = true;
                                            i = servicesList.length;
                                        }
                                    })
                                }
                            }
                            else {
                                servicesList = [];
                            }
                            if (!servicematch) {
                                servicesList.push({ value: r.serviceName, name: r.serviceName });
                                selectServicesList.push( r.serviceName);
                            }
                            //================ for componentName insert =====================//
                            if (componentList !== undefined) {
                                for (var i = 0; i < componentList.length; i++) {
                                    componentmatch = false;
                                    componentList.map((rs) => {
                                        if (rs.value === r.componentName_Filter) {
                                            componentmatch = true;
                                            i = componentList.length;
                                        }
                                    })

                                }
                            }

                            if (!componentmatch) {
                                if (r.componentName_Filter !== 'null' && r.componentName_Filter !== '') {
                                    componentList.push({ value: r.componentName_Filter, name: r.componentName_Filter });
                                    selectComponentList.push(r.componentName_Filter);
                                }

                            }


                            //================ for serviceLifeCycle insert =====================//
                            if (ServiceLifeCycleList !== undefined) {
                                for (var i = 0; i < ServiceLifeCycleList.length; i++) {
                                    lifecyclematch = false;
                                    ServiceLifeCycleList.map((rs) => {
                                        if (rs.value === r.serviceLifeCycleType_Filter) {
                                            lifecyclematch = true;
                                            i = ServiceLifeCycleList.length;
                                        }
                                    })
                                }
                            }
                            else {
                                ServiceLifeCycleList = [];
                            }
                            if (!lifecyclematch) {
                                ServiceLifeCycleList.push({ value: r.serviceLifeCycleType_Filter, name: r.serviceLifeCycleType_Filter });
                                selectServiceLifeCycleList.push( r.serviceLifeCycleType_Filter);
                            }
                            //================ for service type insert =====================//
                            if (ServiceTypeList !== undefined) {
                                for (var i = 0; i < ServiceTypeList.length; i++) {
                                    servicetypematch = false;
                                    ServiceTypeList.map((rs) => {
                                        if (rs.value === r.serviceType_Filter) {
                                            servicetypematch = true;
                                            i = ServiceTypeList.length;
                                        }
                                    })

                                }
                            }

                            if (!servicetypematch) {
                                ServiceTypeList.push({ value: r.serviceType_Filter, name: r.serviceType_Filter });
                                selectServiceTypeList.push( r.serviceType_Filter);
                            }
                            //================ for service ring insert =====================//
                            if (ServiceRingList !== undefined) {
                                for (var i = 0; i < ServiceRingList.length; i++) {
                                    serviceringmatch = false;
                                    ServiceRingList.map((rs) => {
                                        if (rs.value === r.serviceRing_Filter) {
                                            serviceringmatch = true;
                                            i = ServiceRingList.length;
                                        }
                                    })

                                }
                            }

                            if (!serviceringmatch) {
                                ServiceRingList.push({ value: r.serviceRing_Filter, name: r.serviceRing_Filter });
                                selectServiceRingList.push(r.serviceRing_Filter);
                            }

                            //================ for service Id Classification insert =====================//
                            if (IDClassificationList !== undefined) {
                                for (var i = 0; i < IDClassificationList.length; i++) {
                                    idclassificationmatch = false;
                                    IDClassificationList.map((rs) => {
                                        if (rs.value === r.identityServiceClassification_Filter) {
                                            idclassificationmatch = true;
                                            i = IDClassificationList.length;
                                        }
                                    })

                                }
                            }

                            if (!idclassificationmatch) {
                                IDClassificationList.push({ value: r.identityServiceClassification_Filter, name: r.identityServiceClassification_Filter });
                                selectIDClassificationList.push(r.identityServiceClassification_Filter);
                            }

                        }
                    })
                }
            })
        }
        dispatch({
            Servicevalue: (!isArrayWithLength(selectServicesList)) ? '' : selectServicesList,
            Componentvalue: (!isArrayWithLength(selectComponentList)) ? '' : selectComponentList,
            ServiceLifeCyclevalue: (!isArrayWithLength(selectServiceLifeCycleList)) ? '' : selectServiceLifeCycleList,
            ServiceRingvalue: (!isArrayWithLength(selectServiceRingList)) ? '' : selectServiceRingList,
            IDClassificationvalue: (!isArrayWithLength(selectIDClassificationList)) ? '' : selectIDClassificationList,
            ServiceTypevalue: (!isArrayWithLength(selectServiceTypeList)) ? '' : selectServiceTypeList,
            servicesList: getState().IDAcktracking.ServiceList,
            componentList: getState().IDAcktracking.ComponentList,
            ServiceLifeCycleList: getState().IDAcktracking.ServiceLifeCyclelist,
            ServiceTypeList: getState().IDAcktracking.ServiceTypeList,
            ServiceRingList: getState().IDAcktracking.ServiceRinglist,
            IDClassificationList: getState().IDAcktracking.IDClassificationList,
            ServiceManagervalue: value,
            type: ActionType.SERVICEMANAGER_CHANGE
        });

    },
    ServicesChange: (value): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let ServiceManagerList = [];
        let selectServiceManagerList = [];

        let componentList = [];
        let selectComponentList = [];

        let ServiceLifeCycleList = [];
        let selectServiceLifeCycleList = [];

        let ServiceTypeList = [];
        let selectServiceTypeList = [];

        let ServiceRingList = [];
        let selectServiceRingList = [];

        let IDClassificationList = [];
        let selectIDClassificationList = [];
        if (isArrayWithLength(value)) {
            value.map((row) => {
                if (isArrayWithLength(mainIdentityAckData)) {
                    mainIdentityAckData.map((r) => {
                        if (r.serviceName !== "" && r.serviceName !== undefined && row === r.serviceName) {
                            let servicetypematch = false;
                            let gemmatch = false;
                            let componentmatch = false;
                            let lifecyclematch = false;
                            let serviceringmatch = false;
                            let idclassificationmatch = false;
                            //================ for serviceManager insert =====================//
                            if (ServiceManagerList !== undefined) {
                                for (var i = 0; i < ServiceManagerList.length; i++) {
                                    gemmatch = false;
                                    ServiceManagerList.map((rs) => {
                                        if (rs.value === r.geM_Filter) {
                                            gemmatch = true;
                                            i = ServiceManagerList.length;
                                        }
                                    })
                                }
                            }
                            if (!gemmatch) {
                                if (r.geM_Filter != 'null') {
                                    ServiceManagerList.push({value: r.geM_Filter,name: r.geM_Filter });
                                    selectServiceManagerList.push(r.geM_Filter);
                                }

                            }
                            //================ for componentName insert =====================//
                            if (componentList !== undefined) {
                                for (var i = 0; i < componentList.length; i++) {
                                    componentmatch = false;
                                    componentList.map((rs) => {
                                        if (rs.value === r.componentName_Filter) {
                                            componentmatch = true;
                                            i = componentList.length;
                                        }
                                    })

                                }
                            }

                            if (!componentmatch) {
                                if (r.componentName_Filter !== 'null' && r.componentName_Filter !== '') {
                                    componentList.push({ value: r.componentName_Filter, name: r.componentName_Filter });
                                    selectComponentList.push(r.componentName_Filter);
                                }

                            }

                            //================ for serviceLifeCycle insert =====================//
                            if (ServiceLifeCycleList !== undefined) {
                                for (var i = 0; i < ServiceLifeCycleList.length; i++) {
                                    lifecyclematch = false;
                                    ServiceLifeCycleList.map((rs) => {
                                        if (rs.value === r.serviceLifeCycleType_Filter) {
                                            lifecyclematch = true;
                                            i = ServiceLifeCycleList.length;
                                        }
                                    })

                                }
                            }
                            else {
                                ServiceLifeCycleList = [];
                            }

                            if (!lifecyclematch) {
                                ServiceLifeCycleList.push({ value: r.serviceLifeCycleType_Filter,name: r.serviceLifeCycleType_Filter });
                                selectServiceLifeCycleList.push( r.serviceLifeCycleType_Filter);
                            }

                            //================ for service type insert =====================//
                            if (ServiceTypeList !== undefined) {
                                for (var i = 0; i < ServiceTypeList.length; i++) {
                                    servicetypematch = false;
                                    ServiceTypeList.map((rs) => {
                                        if (rs.value === r.serviceType_Filter) {
                                            servicetypematch = true;
                                            i = ServiceTypeList.length;
                                        }
                                    })
                                }
                            }

                            if (!servicetypematch) {
                                ServiceTypeList.push({ value: r.serviceType_Filter, name: r.serviceType_Filter });
                                selectServiceTypeList.push( r.serviceType_Filter);
                            }
                            //================ for service ring insert =====================//
                            if (ServiceRingList !== undefined) {
                                for (var i = 0; i < ServiceRingList.length; i++) {
                                    serviceringmatch = false;
                                    ServiceRingList.map((rs) => {
                                        if (rs.value === r.serviceRing_Filter) {
                                            serviceringmatch = true;
                                            i = ServiceRingList.length;
                                        }
                                    })
                                }
                            }

                            if (!serviceringmatch) {
                                ServiceRingList.push({ value: r.serviceRing_Filter, name: r.serviceRing_Filter });
                                selectServiceRingList.push( r.serviceRing_Filter);
                            }
                            //================ for service Id Classification insert =====================//
                            if (IDClassificationList !== undefined) {
                                for (var i = 0; i < IDClassificationList.length; i++) {
                                    idclassificationmatch = false;
                                    IDClassificationList.map((rs) => {
                                        if (rs.value === r.identityServiceClassification_Filter) {
                                            idclassificationmatch = true;
                                            i = IDClassificationList.length;
                                        }
                                    })

                                }
                            }

                            if (!idclassificationmatch) {
                                IDClassificationList.push({ value: r.identityServiceClassification_Filter, name: r.identityServiceClassification_Filter });
                                selectIDClassificationList.push( r.identityServiceClassification_Filter);
                            }

                        }
                    })
                }
            })
        }
        dispatch({
            Servicevalue: (!isArrayWithLength(value)) ? '' : value,
            Componentvalue: (!isArrayWithLength(selectComponentList)) ? '' : selectComponentList,
            ServiceLifeCyclevalue: (!isArrayWithLength(selectServiceLifeCycleList)) ? '' : selectServiceLifeCycleList,
            ServiceRingvalue: (!isArrayWithLength(selectServiceRingList)) ? '' : selectServiceRingList,
            IDClassificationvalue: (!isArrayWithLength(selectIDClassificationList)) ? '' : selectIDClassificationList,
            ServiceTypevalue: (!isArrayWithLength(selectServiceTypeList)) ? '' : selectServiceTypeList,
            ServiceManagerList: getState().IDAcktracking.ServiceManagerList,
            componentList: getState().IDAcktracking.ComponentList,
            ServiceLifeCycleList: getState().IDAcktracking.ServiceLifeCyclelist,
            ServiceTypeList: getState().IDAcktracking.ServiceTypeList,
            ServiceRingList: getState().IDAcktracking.ServiceRinglist,
            IDClassificationList: getState().IDAcktracking.IDClassificationList,
            ServiceManagervalue: selectServiceManagerList,
            type: ActionType.SERVICES_CHANGE
        });

    },
    ComponentChange: (value): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let ServiceManagerList = [];
        let selectServiceManagerList = [];

        let servicesList = [];
        let selectServicesList = [];

        let ServiceLifeCycleList = [];
        let selectServiceLifeCycleList = [];

        let ServiceTypeList = [];
        let selectServiceTypeList = [];

        let ServiceRingList = [];
        let selectServiceRingList = [];

        let IDClassificationList = [];
        let selectIDClassificationList = [];

        if (isArrayWithLength(value)) {
            value.map((row) => {
                if (isArrayWithLength(mainIdentityAckData)) {
                    mainIdentityAckData.map((r) => {
                        if (r.componentName_Filter != "" && r.componentName_Filter != undefined && row == r.componentName_Filter) {
                            let servicematch = false;
                            let gemmatch = false;
                            let lifecyclematch = false;
                            let serviceringmatch = false;
                            let idclassificationmatch = false;
                            let servicetypematch = false;
                            //================ for serviceManager insert =====================//
                            if (ServiceManagerList !== undefined) {
                                for (var i = 0; i < ServiceManagerList.length; i++) {
                                    gemmatch = false;
                                    ServiceManagerList.map((rs) => {
                                        if (rs.value === r.geM_Filter) {
                                            gemmatch = true;
                                            i = ServiceManagerList.length;
                                        }
                                    })
                                }
                            }

                            if (!gemmatch) {
                                if (r.geM_Filter !== 'null') {
                                    ServiceManagerList.push({ value: r.geM_Filter, name: r.geM_Filter });
                                    selectServiceManagerList.push(r.geM_Filter);
                                }

                            }
                            //================ for servicename insert =====================//
                            if (servicesList !== undefined) {
                                for (var i = 0; i < servicesList.length; i++) {
                                    servicematch = false;
                                    servicesList.map((rs) => {
                                        if (rs.value === r.serviceName) {
                                            servicematch = true;
                                            i = servicesList.length;
                                        }
                                    })

                                }
                            }

                            if (!servicematch) {
                                if (r.serviceName !== 'null' && r.serviceName !== '') {
                                    servicesList.push({ value: r.serviceName, name: r.serviceName });
                                    selectServicesList.push(r.serviceName);
                                }

                            }
                            //================ for serviceLifeCycle insert =====================//
                            if (ServiceLifeCycleList != undefined) {
                                for (var i = 0; i < ServiceLifeCycleList.length; i++) {
                                    lifecyclematch = false;
                                    ServiceLifeCycleList.map((rs) => {
                                        if (rs.value === r.serviceLifeCycleType_Filter) {
                                            lifecyclematch = true;
                                            i = ServiceLifeCycleList.length;
                                        }
                                    })

                                }
                            }

                            if (!lifecyclematch) {
                                ServiceLifeCycleList.push({ value: r.serviceLifeCycleType_Filter, name: r.serviceLifeCycleType_Filter });
                                selectServiceLifeCycleList.push(r.serviceLifeCycleType_Filter);
                            }
                            //================ for service type insert =====================//
                            if (ServiceTypeList !== undefined) {
                                for (var i = 0; i < ServiceTypeList.length; i++) {
                                    servicetypematch = false;
                                    ServiceTypeList.map((rs) => {
                                        if (rs.value === r.serviceType_Filter) {
                                            servicetypematch = true;
                                            i = ServiceTypeList.length;
                                        }
                                    })
                                }
                            }

                            if (!servicetypematch) {
                                ServiceTypeList.push({ value: r.serviceType_Filter, name: r.serviceType_Filter });
                                selectServiceTypeList.push(r.serviceType_Filter);
                            }

                            //================ for service ring insert =====================//
                            if (ServiceRingList !== undefined) {
                                for (var i = 0; i < ServiceRingList.length; i++) {
                                    serviceringmatch = false;
                                    ServiceRingList.map((rs) => {
                                        if (rs.value === r.serviceRing_Filter) {
                                            serviceringmatch = true;
                                            i = ServiceRingList.length;
                                        }
                                    })

                                }
                            }

                            if (!serviceringmatch) {
                                ServiceRingList.push({ value: r.serviceRing_Filter, name: r.serviceRing_Filter });
                                selectServiceRingList.push(r.serviceRing_Filter);
                            }

                            //================ for service Id Classification insert =====================//
                            if (IDClassificationList !== undefined) {
                                for (var i = 0; i < IDClassificationList.length; i++) {
                                    idclassificationmatch = false;
                                    IDClassificationList.map((rs) => {
                                        if (rs.value === r.identityServiceClassification_Filter) {
                                            idclassificationmatch = true;
                                            i = IDClassificationList.length;
                                        }
                                    })

                                }
                            }

                            if (!idclassificationmatch) {
                                IDClassificationList.push({ value: r.identityServiceClassification_Filter, name: r.identityServiceClassification_Filter });
                                selectIDClassificationList.push(r.identityServiceClassification_Filter);
                            }


                        }
                    })
                }
            })
        }
        dispatch({
            ServiceManagervalue: (!isArrayWithLength(selectServiceManagerList)) ? '' : selectServiceManagerList,
            Servicevalue: (!isArrayWithLength(selectServicesList)) ? '' : selectServicesList,
            Componentvalue: (!isArrayWithLength(value)) ? '' : value,
            ServiceTypevalue: (!isArrayWithLength(selectServiceTypeList)) ? '' : selectServiceTypeList,
            ServiceLifeCyclevalue: (!isArrayWithLength(selectServiceLifeCycleList)) ? '' : selectServiceLifeCycleList,
            ServiceRingvalue: (!isArrayWithLength(selectServiceRingList)) ? '' : selectServiceRingList,
            IDClassificationvalue: (!isArrayWithLength(selectIDClassificationList)) ? '' : selectIDClassificationList,
            ServiceManagerList: getState().IDAcktracking.ServiceManagerList,
            servicesList: getState().IDAcktracking.ServiceList,
            ServiceTypeList: getState().IDAcktracking.ServiceTypeList,
            ServiceLifeCycleList: getState().IDAcktracking.ServiceLifeCyclelist,
            ServiceRingList: getState().IDAcktracking.ServiceRinglist,
            IDClassificationList: getState().IDAcktracking.IDClassificationList,
            type: ActionType.COMPONENT_CHANGE
        });


    },
    ServiceTypeChange: (value): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let ServiceManagerList = [];
        let selectServiceManagerList = [];

        let servicesList = [];
        let selectServicesList = [];

        let componentList = [];
        let selectComponentList = [];

        let ServiceLifeCycleList = [];
        let selectServiceLifeCycleList = [];

        let ServiceRingList = [];
        let selectServiceRingList = [];

        let IDClassificationList = [];
        let selectIDClassificationList = [];
        if (isArrayWithLength(value)) {
            value.map((row) => {
                if (isArrayWithLength(mainIdentityAckData)) {
                    mainIdentityAckData.map((r) => {
                        if (r.serviceType_Filter != "" && r.serviceType_Filter != undefined && row == r.serviceType_Filter) {
                            let servicematch = false;
                            let componentmatch = false;
                            let gemmatch = false;
                            let lifecyclematch = false;
                            let serviceringmatch = false;
                            let idclassificationmatch = false;
                            //================ for serviceManager insert =====================//
                            if (ServiceManagerList !== undefined) {
                                for (var i = 0; i < ServiceManagerList.length; i++) {
                                    gemmatch = false;
                                    ServiceManagerList.map((rs) => {
                                        if (rs.value === r.geM_Filter) {
                                            gemmatch = true;
                                            i = ServiceManagerList.length;
                                        }
                                    })
                                }
                            }

                            if (!gemmatch) {
                                if (r.geM_Filter !== 'null') {
                                    ServiceManagerList.push({ value: r.geM_Filter, name: r.geM_Filter });
                                    selectServiceManagerList.push(r.geM_Filter);
                                }

                            }
                            //================ for servicename insert =====================//
                            if (servicesList !== undefined) {
                                for (var i = 0; i < servicesList.length; i++) {
                                    servicematch = false;
                                    servicesList.map((rs) => {
                                        if (rs.value === r.serviceName) {
                                            servicematch = true;
                                            i = servicesList.length;
                                        }
                                    })

                                }
                            }

                            if (!servicematch) {
                                if (r.serviceName !== 'null' && r.serviceName !== '') {
                                    servicesList.push({ value: r.serviceName, name: r.serviceName });
                                    selectServicesList.push(r.serviceName);
                                }

                            }
                            //================ for componentName insert =====================//
                            if (componentList !== undefined) {
                                for (var i = 0; i < componentList.length; i++) {
                                    componentmatch = false;
                                    componentList.map((rs) => {
                                        if (rs.value === r.componentName_Filter) {
                                            componentmatch = true;
                                            i = componentList.length;
                                        }
                                    })

                                }
                            }

                            if (!componentmatch) {
                                if (r.componentName_Filter !== 'null' && r.componentName_Filter !== '') {
                                    componentList.push({ value: r.componentName_Filter, name: r.componentName_Filter });
                                    selectComponentList.push(r.componentName_Filter);
                                }

                            }
                            //================ for serviceLifeCycle insert =====================//
                            if (ServiceLifeCycleList != undefined) {
                                for (var i = 0; i < ServiceLifeCycleList.length; i++) {
                                    lifecyclematch = false;
                                    ServiceLifeCycleList.map((rs) => {
                                        if (rs.value === r.serviceLifeCycleType_Filter) {
                                            lifecyclematch = true;
                                            i = ServiceLifeCycleList.length;
                                        }
                                    })

                                }
                            }

                            if (!lifecyclematch) {
                                ServiceLifeCycleList.push({ value: r.serviceLifeCycleType_Filter, name: r.serviceLifeCycleType_Filter });
                                selectServiceLifeCycleList.push(r.serviceLifeCycleType_Filter);
                            }
                            //================ for service ring insert =====================//
                            if (ServiceRingList !== undefined) {
                                for (var i = 0; i < ServiceRingList.length; i++) {
                                    serviceringmatch = false;
                                    ServiceRingList.map((rs) => {
                                        if (rs.value === r.serviceRing_Filter) {
                                            serviceringmatch = true;
                                            i = ServiceRingList.length;
                                        }
                                    })

                                }
                            }

                            if (!serviceringmatch) {
                                ServiceRingList.push({ value: r.serviceRing_Filter, name: r.serviceRing_Filter });
                                selectServiceRingList.push(r.serviceRing_Filter);
                            }

                            //================ for service Id Classification insert =====================//
                            if (IDClassificationList !== undefined) {
                                for (var i = 0; i < IDClassificationList.length; i++) {
                                    idclassificationmatch = false;
                                    IDClassificationList.map((rs) => {
                                        if (rs.value === r.identityServiceClassification_Filter) {
                                            idclassificationmatch = true;
                                            i = IDClassificationList.length;
                                        }
                                    })

                                }
                            }

                            if (!idclassificationmatch) {
                                IDClassificationList.push({ value: r.identityServiceClassification_Filter, name: r.identityServiceClassification_Filter });
                                selectIDClassificationList.push(r.identityServiceClassification_Filter);
                            }


                        }
                    })
                }
            })
        }
        dispatch({
            Servicevalue: (!isArrayWithLength(selectServicesList)) ? '' : selectServicesList,
            Componentvalue: (!isArrayWithLength(selectComponentList)) ? '' : selectComponentList,
            ServiceLifeCyclevalue: (!isArrayWithLength(selectServiceLifeCycleList)) ? '' : selectServiceLifeCycleList,
            ServiceRingvalue: (!isArrayWithLength(selectServiceRingList)) ? '' : selectServiceRingList,
            IDClassificationvalue: (!isArrayWithLength(selectIDClassificationList)) ? '' : selectIDClassificationList,
            ServiceManagervalue: (!isArrayWithLength(selectServiceManagerList)) ? '' : selectServiceManagerList,
            servicesList: getState().IDAcktracking.ServiceList,
            componentList: getState().IDAcktracking.ComponentList,
            ServiceLifeCyclelist: getState().IDAcktracking.ServiceLifeCyclelist,
            ServiceManagerList: getState().IDAcktracking.ServiceManagerList,
            ServiceRingList: getState().IDAcktracking.ServiceRinglist,
            IDClassificationList: getState().IDAcktracking.IDClassificationList,
            ServiceTypevalue: value,
            type: ActionType.SERVICE_TYPE_CHANGE
        });


    },
    ServiceLifecyclechange: (value): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let ServiceManagerList = [];
        let selectServiceManagerList = [];

        let servicesList = [];
        let selectServicesList = [];

        let componentList = [];
        let selectComponentList = [];

        let ServiceTypeList = [];
        let selectServiceTypeList = [];

        let ServiceRingList = [];
        let selectServiceRingList = [];

        let IDClassificationList = [];
        let selectIDClassificationList = [];
        if (isArrayWithLength(value)) {
            value.map((row) => {
                if (isArrayWithLength(mainIdentityAckData)) {
                    mainIdentityAckData.map((r) => {
                        if (r.serviceLifeCycleType_Filter !== "" && r.serviceLifeCycleType_Filter !== undefined && row === r.serviceLifeCycleType_Filter) {
                            let servicetypematch = false;
                            let componentmatch = false;
                            let gemmatch = false;
                            let servicematch = false;
                            let serviceringmatch = false;
                            let idclassificationmatch = false;
                            //================ for serviceManager insert =====================//
                            if (ServiceManagerList !== undefined) {
                                for (var i = 0; i < ServiceManagerList.length; i++) {
                                    gemmatch = false;
                                    ServiceManagerList.map((rs) => {
                                        if (rs.value === r.geM_Filter) {
                                            gemmatch = true;
                                            i = ServiceManagerList.length;
                                        }
                                    })
                                }
                            }

                            if (!gemmatch) {
                                if (r.geM_Filter != 'null') {
                                    ServiceManagerList.push({ value: r.geM_Filter, name: r.geM_Filter });
                                    selectServiceManagerList.push( r.geM_Filter);
                                }
                            }

                            //================ for servicename insert =====================//
                            if (servicesList !== undefined) {
                                for (var i = 0; i < servicesList.length; i++) {
                                    servicematch = false;
                                    servicesList.map((rs) => {
                                        if (rs.value === r.serviceName) {
                                            servicematch = true;
                                            i = servicesList.length;
                                        }
                                    })

                                }
                            }

                            if (!servicematch) {
                                servicesList.push({ value: r.serviceName, name: r.serviceName });
                                selectServicesList.push(r.serviceName);
                            }
                            //================ for componentName insert =====================//
                            if (componentList !== undefined) {
                                for (var i = 0; i < componentList.length; i++) {
                                    componentmatch = false;
                                    componentList.map((rs) => {
                                        if (rs.value === r.componentName_Filter) {
                                            componentmatch = true;
                                            i = componentList.length;
                                        }
                                    })

                                }
                            }

                            if (!componentmatch) {
                                if (r.componentName_Filter !== 'null' && r.componentName_Filter !== '') {
                                    componentList.push({ value: r.componentName_Filter, name: r.componentName_Filter });
                                    selectComponentList.push(r.componentName_Filter);
                                }

                            }

                            //================ for service type insert =====================//
                            if (ServiceTypeList !== undefined) {
                                for (var i = 0; i < ServiceTypeList.length; i++) {
                                    servicetypematch = false;
                                    ServiceTypeList.map((rs) => {
                                        if (rs.value === r.serviceType_Filter) {
                                            servicetypematch = true;
                                            i = ServiceTypeList.length;
                                        }
                                    })
                                }
                            }

                            if (!servicetypematch) {
                                ServiceTypeList.push({ value: r.serviceType_Filter, name: r.serviceType_Filter });
                                selectServiceTypeList.push( r.serviceType_Filter);
                            }
                            //================ for service ring insert =====================//
                            if (ServiceRingList !== undefined) {
                                for (var i = 0; i < ServiceRingList.length; i++) {
                                    serviceringmatch = false;
                                    for (var i = 0; i < ServiceRingList.length; i++) {
                                        serviceringmatch = false;
                                        ServiceRingList.map((rs) => {
                                            if (rs.value === r.serviceRing_Filter) {
                                                serviceringmatch = true;
                                                i = ServiceRingList.length;
                                            }
                                        })
                                    }
                                }
                            }

                            if (!serviceringmatch) {
                                ServiceRingList.push({ value: r.serviceRing_Filter, name: r.serviceRing_Filter });
                                selectServiceRingList.push( r.serviceRing_Filter);
                            }

                            //================ for service Id Classification insert =====================//
                            if (IDClassificationList !== undefined) {
                                for (var i = 0; i < IDClassificationList.length; i++) {
                                    idclassificationmatch = false;
                                    IDClassificationList.map((rs) => {
                                        if (rs.value === r.identityServiceClassification_Filter) {
                                            idclassificationmatch = true;
                                            i = IDClassificationList.length;
                                        }
                                    })

                                }
                            }

                            if (!idclassificationmatch) {
                                IDClassificationList.push({ value: r.identityServiceClassification_Filter, name: r.identityServiceClassification_Filter });
                                selectIDClassificationList.push( r.identityServiceClassification_Filter);
                            }

                        }
                    })
                }
            })
        }
        dispatch({
            Servicevalue: (!isArrayWithLength(selectServicesList)) ? '' : selectServicesList,
            Componentvalue: (!isArrayWithLength(selectComponentList)) ? '' : selectComponentList,
            ServiceManagervalue: (!isArrayWithLength(selectServiceManagerList)) ? '' : selectServiceManagerList,
            ServiceRingvalue: (!isArrayWithLength(selectServiceRingList)) ? '' : selectServiceRingList,
            IDClassificationvalue: (!isArrayWithLength(selectIDClassificationList)) ? '' : selectIDClassificationList,
            ServiceTypevalue: (!isArrayWithLength(selectServiceTypeList)) ? '' : selectServiceTypeList,
            servicesList: getState().IDAcktracking.ServiceList,
            componentList: getState().IDAcktracking.ComponentList,
            ServiceManagerList: getState().IDAcktracking.ServiceManagerList,
            ServiceTypeList: getState().IDAcktracking.ServiceTypeList,
            ServiceRingList: getState().IDAcktracking.ServiceRinglist,
            IDClassificationList: getState().IDAcktracking.IDClassificationList,
            ServiceLifeCyclevalue: value,
            type: ActionType.SERVICE_LIFECYCLE_CHANGE
        });

    },
    ServiceRingchange: (value): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let ServiceManagerList = [];
        let selectServiceManagerList = [];

        let servicesList = [];
        let selectServicesList = [];

        let componentList = [];
        let selectComponentList = [];

        let ServiceTypeList = [];
        let selectServiceTypeList = [];

        let ServiceLifeCycleList = [];
        let selectServiceLifeCycleList = [];

        let IDClassificationList = [];
        let selectIDClassificationList = [];
        if (isArrayWithLength(value)) {
            value.map((row) => {
                if (isArrayWithLength(mainIdentityAckData)) {
                    mainIdentityAckData.map((r) => {
                        if (r.serviceRing_Filter !== undefined && row === r.serviceRing_Filter) {
                            let servicetypematch = false;
                            let componentmatch = false;
                            let gemmatch = false;
                            let servicematch = false;
                            let lifecyclematch = false;
                            let idclassificationmatch = false;
                            //================ for serviceManager insert =====================//
                            if (ServiceManagerList != undefined) {
                                for (var i = 0; i < ServiceManagerList.length; i++) {
                                    gemmatch = false;
                                    ServiceManagerList.map((rs) => {
                                        if (rs.value === r.geM_Filter) {
                                            gemmatch = true;
                                            i = ServiceManagerList.length;
                                        }
                                    })

                                }
                            }

                            if (!gemmatch) {
                                if (r.geM_Filter !== 'null') {
                                    ServiceManagerList.push({ value: r.geM_Filter, name: r.geM_Filter });
                                    selectServiceManagerList.push(r.geM_Filter);
                                }

                            }
                            //================ for servicename insert =====================//
                            if (servicesList != undefined) {
                                for (var i = 0; i < servicesList.length; i++) {
                                    servicematch = false;
                                    servicesList.map((rs) => {
                                        if (rs.value === r.serviceName) {
                                            servicematch = true;
                                            i = servicesList.length;
                                        }
                                    })
                                }
                            }

                            if (!servicematch) {
                                servicesList.push({ value: r.serviceName, name: r.serviceName });
                                selectServicesList.push(r.serviceName);
                            }
                            //================ for componentName insert =====================//
                            if (componentList !== undefined) {
                                for (var i = 0; i < componentList.length; i++) {
                                    componentmatch = false;
                                    componentList.map((rs) => {
                                        if (rs.value === r.componentName_Filter) {
                                            componentmatch = true;
                                            i = componentList.length;
                                        }
                                    })

                                }
                            }

                            if (!componentmatch) {
                                if (r.componentName_Filter !== 'null' && r.componentName_Filter !== '') {
                                    componentList.push({ value: r.componentName_Filter, name: r.componentName_Filter });
                                    selectComponentList.push(r.componentName_Filter);
                                }

                            }
                            //================ for service type insert =====================//
                            if (ServiceTypeList != undefined) {
                                for (var i = 0; i < ServiceTypeList.length; i++) {
                                    servicetypematch = false;
                                    ServiceTypeList.map((rs) => {
                                        if (rs.value === r.serviceType_Filter) {
                                            servicetypematch = true;
                                            i = ServiceTypeList.length;
                                        }
                                    })
                                }
                            }

                            if (!servicetypematch) {
                                ServiceTypeList.push({ value: r.serviceType_Filter, name: r.serviceType_Filter });
                                selectServiceTypeList.push(r.serviceType_Filter);
                            }
                            //================ for serviceLifeCycle insert =====================//
                            if (ServiceLifeCycleList != undefined) {
                                for (var i = 0; i < ServiceLifeCycleList.length; i++) {
                                    lifecyclematch = false;
                                    ServiceLifeCycleList.map((rs) => {
                                        if (rs.value === r.serviceLifeCycleType_Filter) {
                                            lifecyclematch = true;
                                            i = ServiceLifeCycleList.length;
                                        }
                                    })

                                }
                            }

                            if (lifecyclematch == false) {
                                ServiceLifeCycleList.push({ value: r.serviceLifeCycleType_Filter, name: r.serviceLifeCycleType_Filter });
                                selectServiceLifeCycleList.push(r.serviceLifeCycleType_Filter);
                            }
                            //================ for service Id Classification insert =====================//
                            if (IDClassificationList != undefined) {
                                for (var i = 0; i < IDClassificationList.length; i++) {
                                    idclassificationmatch = false;
                                    IDClassificationList.map((rs) => {
                                        if (rs.value === r.identityServiceClassification_Filter) {
                                            idclassificationmatch = true;
                                            i = IDClassificationList.length;
                                        }
                                    })

                                }
                            }

                            if (idclassificationmatch == false) {
                                IDClassificationList.push({ value: r.identityServiceClassification_Filter, name: r.identityServiceClassification_Filter });
                                selectIDClassificationList.push(r.identityServiceClassification_Filter);
                            }
                        }
                    })
                }
            })
        }
        dispatch({
            Servicevalue: (!isArrayWithLength(selectServicesList)) ? '' : selectServicesList,
            Componentvalue: (!isArrayWithLength(selectComponentList)) ? '' : selectComponentList,
            ServiceLifeCyclevalue: (!isArrayWithLength(selectServiceLifeCycleList)) ? '' : selectServiceLifeCycleList,
            ServiceTypevalue: (!isArrayWithLength(selectServiceTypeList)) ? '' : selectServiceTypeList,
            IDClassificationvalue: (!isArrayWithLength(selectIDClassificationList)) ? '' : selectIDClassificationList,
            ServiceManagervalue: (!isArrayWithLength(selectServiceManagerList)) ? '' : selectServiceManagerList,
            ServiceList: getState().IDAcktracking.ServiceList,
            ComponentList: getState().IDAcktracking.ComponentList,
            ServiceLifeCyclelist: getState().IDAcktracking.ServiceLifeCyclelist,
            ServiceManagerList: getState().IDAcktracking.ServiceManagerList,
            ServiceTypeList: getState().IDAcktracking.ServiceTypeList,
            IDClassificationList: getState().IDAcktracking.IDClassificationList,
            ServiceRingvalue: value,
            type: ActionType.SERVICE_RING_CHANGE
        });


    },
    IDClassificationchange: (value): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let ServiceManagerList = [];
        let selectServiceManagerList = [];

        let servicesList = [];
        let selectServicesList = [];

        let componentList = [];
        let selectComponentList = [];

        let ServiceTypeList = [];
        let selectServiceTypeList = [];

        let ServiceLifeCycleList = [];
        let selectServiceLifeCycleList = [];

        let ServiceRingList = [];
        let selectServiceRingList = [];
        if (isArrayWithLength(value)) {
            value.map((row) => {
                if (isArrayWithLength(mainIdentityAckData)) {
                    mainIdentityAckData.map((r) => {
                        if (r.identityServiceClassification_Filter !== undefined && row === r.identityServiceClassification_Filter) {
                            let servicetypematch = false;
                            let componentmatch = false;
                            let gemmatch = false;
                            let servicematch = false;
                            let lifecyclematch = false;
                            let serviceringmatch = false;
                            //================ for serviceManager insert =====================//
                            if (ServiceManagerList != undefined) {
                                for (var i = 0; i < ServiceManagerList.length; i++) {
                                    gemmatch = false;
                                    ServiceManagerList.map((rs) => {
                                        if (rs.value === r.geM_Filter) {
                                            gemmatch = true;
                                            i = ServiceManagerList.length;
                                        }
                                    })
                                }
                            }

                            if (!gemmatch) {
                                if (r.geM_Filter != 'null') {
                                    ServiceManagerList.push({value: r.geM_Filter, name: r.geM_Filter });
                                    selectServiceManagerList.push(r.geM_Filter);
                                }

                            }
                            //================ for servicename insert =====================//
                            if (servicesList != undefined) {
                                for (var i = 0; i < servicesList.length; i++) {
                                    servicematch = false;
                                    servicesList.map((rs) => {
                                        if (rs.value === r.serviceName) {
                                            servicematch = true;
                                            i = servicesList.length;
                                        }
                                    })

                                }
                            }

                            if (!servicematch) {
                                servicesList.push({ value: r.serviceName, name: r.serviceName });
                                selectServicesList.push(r.serviceName);
                            }
                            //================ for componentName insert =====================//
                            if (componentList !== undefined) {
                                for (var i = 0; i < componentList.length; i++) {
                                    componentmatch = false;
                                    componentList.map((rs) => {
                                        if (rs.value === r.componentName_Filter) {
                                            componentmatch = true;
                                            i = componentList.length;
                                        }
                                    })

                                }
                            }

                            if (!componentmatch) {
                                if (r.componentName_Filter !== 'null' && r.componentName_Filter !== '') {
                                    componentList.push({ value: r.componentName_Filter, name: r.componentName_Filter });
                                    selectComponentList.push(r.componentName_Filter);
                                }

                            }

                            //================ for service type insert =====================//
                            if (ServiceTypeList != undefined) {
                                for (var i = 0; i < ServiceTypeList.length; i++) {
                                    servicetypematch = false;
                                    ServiceTypeList.map((rs) => {
                                        if (rs.value === r.serviceType_Filter) {
                                            servicetypematch = true;
                                            i = ServiceTypeList.length;
                                        }
                                    })

                                }
                            }

                            if (!servicetypematch) {
                                ServiceTypeList.push({ value: r.serviceType_Filter, name: r.serviceType_Filter });
                                selectServiceTypeList.push(r.serviceType_Filter);
                            }
                            //================ for serviceLifeCycle insert =====================//
                            if (ServiceLifeCycleList != undefined) {
                                for (var i = 0; i < ServiceLifeCycleList.length; i++) {
                                    lifecyclematch = false;
                                    ServiceLifeCycleList.map((rs) => {
                                        if (rs.value === r.serviceLifeCycleType_Filter) {
                                            lifecyclematch = true;
                                            i = ServiceLifeCycleList.length;
                                        }
                                    })

                                }
                            }

                            if (!lifecyclematch) {
                                ServiceLifeCycleList.push({ value: r.serviceLifeCycleType_Filter, name: r.serviceLifeCycleType_Filter });
                                selectServiceLifeCycleList.push(r.serviceLifeCycleType_Filter);
                            }
                            //================ for service ring insert =====================//
                            if (ServiceRingList != undefined) {
                                for (var i = 0; i < ServiceRingList.length; i++) {
                                    serviceringmatch = false;
                                    ServiceRingList.map((rs) => {
                                        if (rs.value === r.serviceRing_Filter) {
                                            serviceringmatch = true;
                                            i = ServiceRingList.length;
                                        }
                                    })

                                }
                            }

                            if (serviceringmatch == false) {
                                ServiceRingList.push({ value: r.serviceRing_Filter, name: r.serviceRing_Filter });
                                selectServiceRingList.push(r.serviceRing_Filter);
                            }
                        }
                    })
                }
            })
        }
        dispatch({
            Servicevalue: (!isArrayWithLength(selectServicesList)) ? '' : selectServicesList,
            Componentvalue: (!isArrayWithLength(selectComponentList)) ? '' : selectComponentList,
            ServiceLifeCyclevalue: (!isArrayWithLength(selectServiceLifeCycleList)) ? '' : selectServiceLifeCycleList,
            ServiceRingvalue: (!isArrayWithLength(selectServiceRingList)) ? '' : selectServiceRingList,
            ServiceManagervalue: (!isArrayWithLength(selectServiceManagerList)) ? '' : selectServiceManagerList,
            ServiceTypevalue: (!isArrayWithLength(selectServiceTypeList)) ? '' : selectServiceTypeList,
            servicesList: getState().IDAcktracking.ServiceList,
            componentList: getState().IDAcktracking.ComponentList,
            ServiceLifeCyclelist: getState().IDAcktracking.ServiceLifeCyclelist,
            ServiceManagerList: getState().IDAcktracking.ServiceManagerList,
            ServiceRingList: getState().IDAcktracking.ServiceRinglist,
            ServiceTypeList: getState().IDAcktracking.ServiceTypeList,
            IDClassificationvalue: value,
            type: ActionType.SERVICE_IDCLASSIFICATION_CHANGE
        });
    },
    clearallfilters: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let state = getState().IDAcktracking;
        let ServiceManagerList = [];
        let serviceslist = [];
        let componentlist = [];
        let IDClassificationList = [];
        let ServiceLifeCyclelist = [];
        let ServiceTypeList = [];
        let ServiceRinglist = [];
        let ServiceManagervalue = [];
        let Servicevalue = [];
        let Componentvalue = [];
        let ServiceLifeCyclevalue = [];
        let ServiceTypevalue = [];
        let ServiceRingvalue = [];
        let IDClassificationvalue = [];

        if (isArrayWithLength(MainServiceManagerList)) {
            MainServiceManagerList.map((obj) => {
                ServiceManagervalue.push(obj.value);
                ServiceManagerList.push({value:obj.value,name:obj.name});
            })
        }
        if (isArrayWithLength(Mainserviceslist)) {
            Mainserviceslist.map((obj) => {
                Servicevalue.push(obj.value);
                serviceslist.push({ value: obj.value, name: obj.name });
            })
        }
        if (isArrayWithLength(MainComponentlist)) {
            MainComponentlist.map((obj) => {
                Componentvalue.push(obj.value);
                componentlist.push({ value: obj.value, name: obj.name });
            })
        }
        if (isArrayWithLength(MainIDClassificationList)) {
            MainIDClassificationList.map((obj) => {
                IDClassificationvalue.push(obj.value);
                IDClassificationList.push({ value: obj.value, name: obj.name });
            })
        }
        if (isArrayWithLength(MainServiceLifeCyclelist)) {
            MainServiceLifeCyclelist.map((obj) => {
                ServiceLifeCyclevalue.push(obj.value);
                ServiceLifeCyclelist.push({ value: obj.value, name: obj.name });
            })
        }
        if (isArrayWithLength(MainServiceTypeList)) {
            MainServiceTypeList.map((obj) => {
                ServiceTypevalue.push(obj.value);
                ServiceTypeList.push({ value: obj.value, name: obj.name });
            })
        }
        if (isArrayWithLength(MainServiceRinglist)) {
            MainServiceRinglist.map((obj) => {
                ServiceRingvalue.push(obj.value);
                ServiceRinglist.push({ value: obj.value, name: obj.name });
            })
        }
        let ackdata = filterData(ServiceTypevalue, mainIdentityAckData, Servicevalue, Componentvalue, ServiceManagervalue, ServiceLifeCyclevalue, ServiceRingvalue, IDClassificationvalue);
        dispatch({
            ServiceManagervalue,
            Servicevalue,
            Componentvalue,
            IDClassificationvalue,
            ServiceLifeCyclevalue,
            ServiceTypevalue,
            ServiceRingvalue,
            AcktrackingFiltervalue: '0',
            mainIdentityAckData: ackdata,
            ServiceManagerList,
            serviceslist,
            IDClassificationList,
            ServiceLifeCyclelist,
            ServiceTypeList,
            ServiceRinglist,
            type: ActionType.CLEAR_FILTERS
        });

    },
    searchtextboxchange: (value): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            globalsearch: value.target.value,
            type: ActionType.GLOBAL_SEARCH_VALUE_CHANGE
        });
    },
    IdAcktypeChange: (value): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let IDAckTypeList = getState().IDAcktracking.IDAckTypeList;
        let ServiceManagerList = [];
        let serviceslist = [];
        let componentlist = [];
        let IDClassificationList = [];
        let ServiceLifeCyclelist = [];
        let ServiceTypeList = [];
        let ServiceRinglist = [];
        let ServiceManagervalue = [];
        let Servicevalue = [];
        let Componentvalue = [];
        let ServiceLifeCyclevalue = [];
        let ServiceTypevalue = [];
        let ServiceRingvalue = [];
        let IDClassificationvalue = [];

        if (isArrayWithLength(MainServiceManagerList)) {
            MainServiceManagerList.map((obj) => {
                ServiceManagervalue.push(obj.value);
                ServiceManagerList.push({ value: obj.value, name: obj.name });
            })
        }
        if (isArrayWithLength(Mainserviceslist)) {
            Mainserviceslist.map((obj) => {
                Servicevalue.push(obj.value);
                serviceslist.push({ value: obj.value, name: obj.name });
            })
        }
        if (isArrayWithLength(MainComponentlist)) {
            MainComponentlist.map((obj) => {
                Componentvalue.push(obj.value);
                componentlist.push({ value: obj.value, name: obj.name });
            })
        }
        if (isArrayWithLength(MainIDClassificationList)) {
            MainIDClassificationList.map((obj) => {
                IDClassificationvalue.push(obj.value);
                IDClassificationList.push({ value: obj.value, name: obj.name });
            })
        }
        if (isArrayWithLength(MainServiceLifeCyclelist)) {
            MainServiceLifeCyclelist.map((obj) => {
                ServiceLifeCyclevalue.push(obj.value);
                ServiceLifeCyclelist.push({ value: obj.value, name: obj.name });
            })
        }
        if (isArrayWithLength(MainServiceTypeList)) {
            MainServiceTypeList.map((obj) => {
                ServiceTypevalue.push(obj.value);
                ServiceTypeList.push({ value: obj.value, name: obj.name });
            })
        }
        if (isArrayWithLength(MainServiceRinglist)) {
            MainServiceRinglist.map((obj) => {
                ServiceRingvalue.push(obj.value);
                ServiceRinglist.push({ value: obj.value, name: obj.name });
            })
        }
        let title = '';
        let isServiceSpecificAckType = false;
        if (isArrayWithLength(IDAckTypeList)) {
            IDAckTypeList.map((obj) => {
                if (obj.value.toString() === value.toString()) {
                    title = obj.title;
                    if (obj.isServiceSpecific == 1) {
                        isServiceSpecificAckType = true;
                    }
                    else {
                        isServiceSpecificAckType = false;
                    }
                }
            })
        }
        dispatch({
            notificationACKId: value,
            title,
            ServiceManagervalue,
            Servicevalue,
            Componentvalue,
            IDClassificationvalue,
            ServiceLifeCyclevalue,
            ServiceTypevalue,
            ServiceRingvalue,
            AcktrackingFiltervalue: '0',
            ServiceManagerList,
            serviceslist,
            componentlist,
            IDClassificationList,
            ServiceLifeCyclelist,
            ServiceTypeList,
            ServiceRinglist,
            isServiceSpecificAckType,
            type: ActionType.ACKTYPE_VALUE_CHANGE
        });
    },
    IdAckFilterListChange: (value): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let notificationACKId = getState().IDAcktracking.notificationACKId;
        let headerdata = getState().IDAcktracking.headerdata;
        let IDAckTypeList = getState().IDAcktracking.IDAckTypeList;
        let username = getState().authUser.login;
        let IsFilterDisabledStatus = getState().IDAcktracking.IsFilterDisabled;
        dispatch({
            AcktrackingFiltervalue: value,
            type: ActionType.FILTER_DROPDOWN_CHANGE
        });
        AcktrackingAPI.GetFilterByID(value)
            .then((fliterdata: any) => {
                let url = { Stype: '', Mtype: '', Ltype: '', ACKId: '', SName: '', Rtype: '', IDtype: '', CName: '' }
                let filterName = fliterdata[0].filterName;
                let FilterURL = fliterdata[0].filters.split('^');
                url.Stype = FilterURL[1].split('Stype=')[1];
                url.Mtype = FilterURL[0].split('Mtype=')[1];
                url.ACKId = FilterURL[2].split('ACKId=')[1];
                url.SName = FilterURL[3].split('SName=')[1];

                if (FilterURL.length > 4) {
                    url.Ltype = FilterURL[4].split('Ltype=')[1];
                }
                else {
                    url.Ltype = "";
                }
                if (FilterURL.length > 5) {
                    url.Rtype = FilterURL[5].split('Rtype=')[1];
                }
                else {
                    url.Rtype = "";
                }
                if (FilterURL.length > 6) {
                    url.IDtype = FilterURL[6].split('IDtype=')[1];
                }
                else {
                    url.IDtype = "";
                }
                if (FilterURL.length > 7) {
                    url.CName = FilterURL[7].split('CName=')[1];
                }
                else {
                    url.CName = "";
                }


                let selectServiceManagerList = [];
                let selectServicesList = [];
                let selectComponentList = [];
                let selectServiceLifeCycleList = [];
                let selectServiceRingList = [];
                let selectIDClassificationList = [];
                let selectServiceTypeList = [];
                const GEM = [] = url.Mtype.split(",");
                for (var i = 0; GEM.length > i; i++) {
                    if (GEM[i] == "''") {
                        selectServiceManagerList.push('');
                    }
                    else {
                        selectServiceManagerList.push(GEM[i]);
                    }
                }
                var sname = (url.SName.trim() === '') ? [] : url.SName.split(",");
                for (var i = 0; sname.length > i; i++) {
                    // UnFormatting the services with display name having ','
                    sname[i] = sname[i].replace(/%/g, ",");
                    selectServicesList.push(sname[i]);
                }

                if (url.Ltype != "") {
                    var Ltype = [] = url.Ltype.split(",");
                    for (var i = 0; Ltype.length > i; i++) {
                        selectServiceLifeCycleList.push(Ltype[i]);
                    }
                }
                if (url.Rtype != "") {
                    let Rtype = [] = url.Rtype.split(",");
                    for (var i = 0; Rtype.length > i; i++) {
                        if (Rtype[i] == "''") {
                            selectServiceRingList.push('');
                        }
                        else {
                            selectServiceRingList.push(Rtype[i]);
                        }
                    }
                }
                else {
                    selectServiceRingList.push('');
                }

                if (url.IDtype != "") {
                    let IDtype = [] = url.IDtype.split(",");
                    for (var i = 0; IDtype.length > i; i++) {
                        if (IDtype[i] == "''") {
                            selectIDClassificationList.push('');
                        }
                        else {
                            selectIDClassificationList.push(IDtype[i]);
                        }
                    }
                }
                else {
                    selectIDClassificationList.push('');
                }
                let Stype = (url.Stype.trim() === '') ? [] : url.Stype.split(",");
                for (var i = 0; Stype.length > i; i++) {
                    selectServiceTypeList.push(Stype[i]);
                }

                var cname = (url.CName.trim() === '') ? [] : url.CName.split(",");
                for (var i = 0; cname.length > i; i++) {
                    // UnFormatting the services with display name having ','
                    cname[i] = cname[i].replace(/%/g, ",");
                    selectComponentList.push(cname[i]);
                }


                let IDAckTypeList = getState().IDAcktracking.IDAckTypeList;
                let title = '';
                if (isArrayWithLength(IDAckTypeList)) {
                    IDAckTypeList.map((obj) => {
                        if (obj.value.toString() === url.ACKId.toString()) {
                            title = obj.title;
                        }
                    })
                }

                if (fliterdata.UpdatedBy != username) {
                    IsFilterDisabledStatus = true;
                }

                //find ack category to enable and disable the component dropdown control
                let isServiceSpecificAckType = false;
                let findAckId = notificationACKId;
                if (notificationACKId != url.ACKId) {
                    findAckId = url.ACKId;
                }

                if (isArrayWithLength(IDAckTypeList)) {
                    IDAckTypeList.map((obj) => {
                        if (obj.value.toString() === findAckId.toString()) {
                            if (obj.isServiceSpecific == 1) {
                                isServiceSpecificAckType = true;
                            }
                            else {
                                isServiceSpecificAckType = false;
                            }
                        }

                    })
                }

                if (notificationACKId != url.ACKId) {
                    AcktrackingAPI.GetIdentityAckTrainingData(parseInt(url.ACKId))
                        .then((AcktrackingData: any) => {
                            headerdata = AcktrackingData.table;
                            let IdentityAckData = AcktrackingData.table1
                            IsOldAckDeploymentData = checkAckExists(notificationACKId);
                            mainIdentityAckData = [];
                            if (isArrayWithLength(IdentityAckData)) {
                                IdentityAckData.map((obj) => {
                                    mainIdentityAckData.push(obj);
                                })

                            }
                            let ackdata = filterData(selectServiceTypeList, mainIdentityAckData, selectServicesList, selectComponentList, selectServiceManagerList, selectServiceLifeCycleList, selectServiceRingList, selectIDClassificationList);
                            let result = CheckEmailPOPup(headerdata);
                            dispatch({
                                title,
                                selectServiceManagerList,
                                selectServicesList: (!isArrayWithLength(selectServicesList)) ? '' : selectServicesList,
                                selectComponentList: (!isArrayWithLength(selectComponentList)) ? '' : selectComponentList,
                                selectServiceLifeCycleList,
                                selectServiceRingList,
                                selectIDClassificationList,
                                selectServiceTypeList,
                                ackdata,
                                notificationACKId: url.ACKId,
                                Iscontact_DevOwner: result.Iscontact_DevOwner,
                                Iscontact_PMOwner: result.Iscontact_PMOwner,
                                IslastUpdatedBy: result.IslastUpdatedBy,
                                headerdata,
                                filterName,
                                isServiceSpecificAckType,
                                IsFilterDisabled: IsFilterDisabledStatus,
                                type: ActionType.FILTER_DATA_BASEDON_FILTERID
                            });

                        });
                }
                else {
                    let ackdata = filterData(selectServiceTypeList, mainIdentityAckData, selectServicesList, selectComponentList, selectServiceManagerList, selectServiceLifeCycleList, selectServiceRingList, selectIDClassificationList);
                    let result = CheckEmailPOPup(headerdata);
                    dispatch({
                        title,
                        selectServiceManagerList,
                        selectServicesList: (!isArrayWithLength(selectServicesList)) ? '' : selectServicesList,
                        selectComponentList: (!isArrayWithLength(selectComponentList)) ? '' : selectComponentList,
                        selectServiceLifeCycleList,
                        selectServiceRingList,
                        selectIDClassificationList,
                        selectServiceTypeList,
                        ackdata,
                        notificationACKId: url.ACKId,
                        headerdata,
                        Iscontact_DevOwner: result.Iscontact_DevOwner,
                        Iscontact_PMOwner: result.Iscontact_PMOwner,
                        IslastUpdatedBy: result.IslastUpdatedBy,
                        filterName,
                        isServiceSpecificAckType,
                        IsFilterDisabled: IsFilterDisabledStatus,
                        type: ActionType.FILTER_DATA_BASEDON_FILTERID
                    });
                }

            }).catch(error => {
                dispatch({
                    type: ActionType.FILTER_DATA_BASEDON_FILTERID_FAIL
                });
            });
    },
    getackfiltertypefilterdata: (name): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        AcktrackingAPI.GetFilterData()
            .then((fliterdata: any) => {
                let AcktrackingFilterList = [];
                let filterid = "0";
                AcktrackingFilterList.push({ name: 'Select Filter', value: '0', filters: '' });
                if (isArrayWithLength(fliterdata)) {
                    fliterdata.map((obj) => {
                        if (name === obj.label) {
                            filterid = obj.id;
                        }
                        AcktrackingFilterList.push({ name: obj.label, value: obj.id, filters: obj.filters });
                    })
                }
                dispatch({
                    AcktrackingFilterList,
                    filterid,
                    type: ActionType.GET_FILTER_REFRESH
                });
            }).catch(error => {
                dispatch({
                    type: ActionType.SET_LOADING_FALSE
                });
                dispatch({
                    type: ActionType.FETCH_IDACKFILTERLIST_FAIL
                });
            });
    },
    OpenEmailPOPUP: (name): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let Dev_OwnerEmailID = [];
        let PM_OwnerEmailID = [];
        let Updated_UseridEmail = [];
        let DevOwnerEmailIDs = "";
        let PMOwnerEmailIDs = "";
        let UpdatedUserIDEmailIDs = "";
        let Iscontact_DevOwner = getState().IDAcktracking.Iscontact_DevOwner;
        let Iscontact_PMOwner = getState().IDAcktracking.Iscontact_PMOwner;
        let IslastUpdatedBy = getState().IDAcktracking.IslastUpdatedBy;
        let IdentityAckData = getState().IDAcktracking.IdentityAckData;
        if (isArrayWithLength(IdentityAckData)) {
            IdentityAckData.map((row) => {
                //============= for getting dev owners======================// 

                if(Iscontact_DevOwner) {
                    var contact_DevOwner = row.contact_DevOwner.split(";");

                    for (var i = 0; i < contact_DevOwner.length; i++) {
                        var match = false;
                        if (isArrayWithLength(Dev_OwnerEmailID)) {
                            Dev_OwnerEmailID.map((r) => {
                                if (r.Email === contact_DevOwner[i]) {
                                    match = true;
                                }
                            })
                        }
                        if (!match) {
                            Dev_OwnerEmailID.push({ 'Email': contact_DevOwner[i] });
                            DevOwnerEmailIDs = (DevOwnerEmailIDs == "") ? contact_DevOwner[i] : DevOwnerEmailIDs + " ; " + contact_DevOwner[i];
                        }
                    }
                }
                //================= for getting PM Owners ==================//
                if (Iscontact_PMOwner) {
                    var contact_PMOwner = row.contact_PMOwner.split(";");
                    for (var i = 0; i < contact_PMOwner.length; i++) {
                        match = false;
                        if (isArrayWithLength(PM_OwnerEmailID)) {
                            PM_OwnerEmailID.map((r) => {
                                if (r.Email === contact_PMOwner[i]) {
                                    match = true;
                                }
                            })
                        }
                        if (match == false) {
                            PM_OwnerEmailID.push({ 'Email': contact_PMOwner[i] });
                            PMOwnerEmailIDs = (PMOwnerEmailIDs === "") ? contact_PMOwner[i] : PMOwnerEmailIDs + " ; " + contact_PMOwner[i];
                        }
                    }
                }

                //================= for getting Updted Usres ==================//
                if (IslastUpdatedBy) {
                    var UpdatedUserID = [];
                    UpdatedUserID = row.lastUpdatedBy != undefined ? row.lastUpdatedBy.split(";") : [];
                    for (var i = 0; i < UpdatedUserID.length; i++) {
                        match = false;
                        if (isArrayWithLength(Updated_UseridEmail)) {
                            Updated_UseridEmail.map((r) => {
                                if (r.Email === UpdatedUserID[i]) {
                                    match = true;
                                }
                            })
                        }
                        if (match == false) {
                            Updated_UseridEmail.push({ 'Email': UpdatedUserID[i] });
                            UpdatedUserIDEmailIDs = (UpdatedUserIDEmailIDs == "") ? UpdatedUserID[i] : UpdatedUserIDEmailIDs + " ; " + UpdatedUserID[i];
                        }
                    }
                }
            })
        }
        dispatch({
            DevOwnerEmailIDs,
            PMOwnerEmailIDs,
            UpdatedUserIDEmailIDs,
            type: ActionType.OPEN_EMAIL_POPUP
        });
    },
    handleemailpopupClose: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({

            type: ActionType.EMAIL_POPUP_CLOSE
        });
    },
    handleEditAcktrackingpopupClose: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({

            type: ActionType.ACKEDIT_POPUP_CLOSE
        });
    },
    openacktrackingpopup: (obj): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let headerdata = getState().IDAcktracking.headerdata;
        let EditObject: any = {} ;
        let chkval = [];
        let editdropdownlist = [];
        let checkboxlist = [];
        let checkboxlistData = [];
        let EditObjectDate = [];
        let item = obj; //Not sure the unable to iterate the obj hence copy to new object.

        for (var i = 0; i < headerdata.length; i++) {
            //var val = headerdata[i].field.substr(0, 2).toLowerCase() + headerdata[i].field.substr(2);
            var val = '';
            var valActualColName = headerdata[i].field;

            let isKeyFound = false;
            Object.keys(item).map((key, i) => {
                if (!isKeyFound) {
                    if (key != null && key != undefined) {
                        if (key.toString().toLowerCase() === valActualColName.toString().toLowerCase()) {
                            val = key;
                            isKeyFound = true;
                        }
                    }
                }
            });

            EditObject[valActualColName] = obj[val];
            let resultvalues = obj[val].split(',');
            var chekeditems = [];
            let editobjDate = null;
            if (headerdata[i].controlType == 'Date') {
                editobjDate = obj[val] !== '' ? new Date(obj[val]) : null;
                EditObjectDate.push({ 'ColName': valActualColName, 'name': editobjDate, 'value': editobjDate });
            }
            else if (headerdata[i].controlType == 'CheckBox') {
                var data = headerdata[i].controlValue.split(',');
                if (isArrayWithLength(data)) {
                    data.map((objChk) => {
                        let checkdata = {};
                        checkdata[objChk] = false;
                        if (isArrayWithLength(resultvalues)) {
                            resultvalues.map((ck1) => {
                                if (objChk.trim() === ck1.trim()) {
                                    checkdata[objChk] = true;
                                }
                            })
                        }
                        checkboxlist.push({ 'ColName': valActualColName, 'Key': objChk, 'Value': checkdata[objChk]});
                    })
                }
            }
            if (headerdata[i].controlType == 'Dropdown') {
                let ddlist = headerdata[i].controlValue.split(',');
                if (isArrayWithLength(ddlist)) {
                    ddlist.map((objDp) => {
                        editdropdownlist.push({ 'ColName': valActualColName, 'name': objDp.trim(), 'value': objDp.trim() });
                    })
                }


            }
        }

        EditObject.notificationAckId = obj.notificationAckId;
        EditObject.isChanged = 0;
        EditObject.isEdit = obj.isEdit;
        EditObject.serviceOid = obj.serviceOid;
        EditObject.componentOid = (obj.componentOid == '' ? '0': obj.componentOid);
        EditObject.title = obj.title;
        let savebuttondisabled= checkAllData(headerdata, EditObject, checkboxlist);
        dispatch({
            EditObject,
            editdropdownlist,
            checkboxlist,
            savebuttondisabled,
            EditObjectDate,
            type: ActionType.OPEN_ACKTRACKING_POPUP
        });
    },
    acktrackingcontrolsedit: (e,type): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let EditObject = {};
        let EditObjectdata = getState().IDAcktracking.EditObject;
        let editdropdownlist = getState().IDAcktracking.editdropdownlist;
        let checkboxlist = getState().IDAcktracking.checkboxlist;
        let headerdata = getState().IDAcktracking.headerdata;
        let chklist = [];
        EditObjectdata.isChanged = 1;
        Object.keys(EditObjectdata).map((key, i) => {
            EditObject[key] = EditObjectdata[key];
        });
        let checkedvalues = '';
        const { name, value, checked } = e.target;
        if (type === 'checkbox') {
            if (isArrayWithLength(checkboxlist)) {

                for (var i = 0; i < checkboxlist.length; i++) {
                    if (checkboxlist[i]['ColName'] == name) {
                        if (checkboxlist[i]['Key'] == value) {
                            checkboxlist[i]['Value'] = checked
                        }
                        if (checkboxlist[i]['Value']) {
                            if (checkedvalues === '') {
                                checkedvalues = checkboxlist[i]['Key'];
                            }
                            else {
                                checkedvalues = checkedvalues + ',' + checkboxlist[i]['Key'];
                            }
                        }
                    }
                    chklist.push(checkboxlist[i]);
                }

            }
            EditObject[name] = checkedvalues;
        }
        else {
            EditObject[name] = value;

        }
        let savebuttondisabled = checkAllData(headerdata, EditObject, (type === 'checkbox') ? chklist : checkboxlist);
        dispatch({
            EditObject,
            chklist: (type === 'checkbox') ? chklist : checkboxlist,
            savebuttondisabled,
            type: ActionType.ACKTRACKING_CONTROLS_CHANGE
        });
    },

    handleSelectedAckDateChange: (key): IAppThunkAction<ReduxAction> => (dispatch, getState) => {

        let headerdata = getState().IDAcktracking.headerdata;
        let EditObject = {};
        let EditObjectdata = getState().IDAcktracking.EditObject;
        let columnname = '';
        let checkboxlist = getState().IDAcktracking.checkboxlist;
        let EditObjectDate = getState().IDAcktracking.EditObjectDate;
        EditObjectdata.isChanged = 1;

        Object.keys(EditObjectdata).map((key, i) => {
            EditObject[key] = EditObjectdata[key];
        });

        if (isArrayWithLength(headerdata)) {
            headerdata.map((obj) => {
                if (obj.controlType == 'Date') {
                    //columnname = (obj.field.charAt(0,2).toLowerCase() + obj.field.substr(2));
                    columnname = obj.field;

                    if (isArrayWithLength(EditObjectDate)) {
                        for (var i = 0; i < EditObjectDate.length; i++) {
                            if (EditObjectDate[i]['ColName'] == columnname) {
                                EditObject[columnname] = ((EditObjectDate[i]['name'] !== null) ? moment(EditObjectDate[i]['name']).format('MM/DD/YYYY') : '');
                            }
                        }
                    }
                }

            })
        }

        let savebuttondisabled = checkAllData(headerdata, EditObject, checkboxlist);
        dispatch({
            EditObjectDate,
            EditObject,
            savebuttondisabled,
            type: ActionType.ACKTRACKING_DATE_CHANGE
        });
    },

    filterAckdata: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        let state = getState().IDAcktracking;
        let selectServiceTypeList = state.ServiceTypevalue;
        let selectServicesList = state.Servicevalue;
        let selectComponentList = state.Componentvalue;
        let selectServiceManagerList = state.ServiceManagervalue;
        let selectServiceLifeCycleList = state.ServiceLifeCyclevalue;
        let selectServiceRingList = state.ServiceRingvalue;
        let selectIDClassificationList = state.IDClassificationvalue;
        let headerdata = state.headerdata;
        let result = CheckEmailPOPup(headerdata);
        let ackdata = filterData(selectServiceTypeList, mainIdentityAckData, selectServicesList, selectComponentList, selectServiceManagerList, selectServiceLifeCycleList, selectServiceRingList, selectIDClassificationList);
        dispatch({
            selectServiceManagerList,
            selectServicesList: (!isArrayWithLength(selectServicesList)) ? '' : selectServicesList,
            selectComponentList: (!isArrayWithLength(selectComponentList)) ? '' : selectComponentList,
            selectServiceLifeCycleList,
            selectServiceRingList,
            selectIDClassificationList,
            selectServiceTypeList,
            ackdata,
            headerdata,
            Iscontact_DevOwner: result.Iscontact_DevOwner,
            Iscontact_PMOwner: result.Iscontact_PMOwner,
            IslastUpdatedBy: result.IslastUpdatedBy,
            type: ActionType.FILTER_DATA
        });
    },
    NewFiltertextboxchange: (value): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            NewFiltervalue: value.target.value,
            type: ActionType.FILTER_TEXT_VALUE_CHANGE
        });
    },
    seturlparamempty: (): IAppThunkAction<ReduxAction> => (dispatch, getState) => {
        dispatch({
            type: ActionType.SET_URL_PARAM_EMPTY
        });
    },
};

const filterData = function (selectServiceTypeList, IdentityAckData, selectServicesList, selectComponentList, selectServiceManagerList, selectServiceLifeCycleList, selectServiceRingList, selectIDClassificationList) {
    let IdentityAckDataFiltered = [];

    let tempCol = [];
    let tempMasterCol = [];

    //**************************** New Logic BEGIN ****************************************************************

    if (isArrayWithLength(selectServicesList)) {
        selectServicesList.map((row) => {
            if (isArrayWithLength(IdentityAckData)) {
                IdentityAckData.map((r) => {
                    if ((selectServiceManagerList == 0 && selectServiceTypeList.length == 0 && selectComponentList.length == 0 && selectServiceLifeCycleList.length == 0 && selectServiceRingList.length == 0 && selectIDClassificationList.length == 0) && row.id == r.serviceName) {
                        IdentityAckDataFiltered.push(r);
                    }
                    else if (row == r.serviceName && r.isAck == true && IsOldAckDeploymentData) {
                        tempMasterCol.push(r);
                    }
                    else if ((row == r.serviceName) && (!IsOldAckDeploymentData)) {
                        tempMasterCol.push(r);
                    }
                })
            }
        });

    }


    if (isArrayWithLength(selectServiceManagerList)) {
        selectServiceManagerList.map((row) => {
            if (isArrayWithLength(tempMasterCol)) {
                tempMasterCol.map((r) => {
                    if ((selectServicesList.length == 0 && selectServiceTypeList.length == 0 && selectComponentList.length == 0 && selectServiceLifeCycleList.length == 0 && selectServiceRingList.length == 0 && selectIDClassificationList.length == 0) && row.id == r.geM_Filter) {
                        IdentityAckDataFiltered.push(r);
                    }
                    else if (row == r.geM_Filter && r.isAck == true && IsOldAckDeploymentData) {
                        tempCol.push(r);
                    }
                    else if ((row == r.geM_Filter) && (!IsOldAckDeploymentData)) {
                        tempCol.push(r);
                    }
                })
            }
        });
        tempMasterCol = [];
        tempMasterCol = tempCol;
        tempCol = [];
    }


    if (isArrayWithLength(selectServiceTypeList)) {
        selectServiceTypeList.map((row) => {
            if (isArrayWithLength(tempMasterCol)) {
                tempMasterCol.map((r) => {
                    if ((selectServiceManagerList == 0 && selectServicesList.length == 0 && selectComponentList.length == 0 && selectServiceLifeCycleList.length == 0 && selectServiceRingList.length == 0 && selectIDClassificationList.length == 0) && row.id == r.serviceType_Filter) {
                        IdentityAckDataFiltered.push(r);
                    }
                    else if (row == r.serviceType_Filter && r.isAck == true && IsOldAckDeploymentData) {
                        tempCol.push(r);
                    }
                    else if ((row == r.serviceType_Filter) && (!IsOldAckDeploymentData)) {
                        tempCol.push(r);
                    }
                })
            }
        });
        tempMasterCol = [];
        tempMasterCol = tempCol;
        tempCol = [];
    }


    if (isArrayWithLength(selectServiceLifeCycleList)) {
        selectServiceLifeCycleList.map((row) => {
            if (isArrayWithLength(tempMasterCol)) {
                tempMasterCol.map((r) => {
                    if ((selectServicesList.length == 0 && selectServiceManagerList == 0 && selectComponentList.length == 0 && selectServiceTypeList.length == 0 && selectServiceRingList.length == 0 && selectIDClassificationList.length == 0) && row.id == r.serviceLifeCycleType_Filter) {
                        IdentityAckDataFiltered.push(r);
                    }
                    else if (row == r.serviceLifeCycleType_Filter && r.isAck == true && IsOldAckDeploymentData) {
                        tempCol.push(r);
                    }
                    else if (row == r.serviceLifeCycleType_Filter && !IsOldAckDeploymentData) {
                        tempCol.push(r);
                    }
                })
            }
        });
        tempMasterCol = [];
        tempMasterCol = tempCol;
        tempCol = [];
    }


    if (isArrayWithLength(selectServiceRingList)) {
        selectServiceRingList.map((row) => {
            if (isArrayWithLength(tempMasterCol)) {
                tempMasterCol.map((r) => {
                    if ((selectServicesList.length == 0 && selectServiceManagerList == 0 && selectComponentList.length == 0 && selectServiceTypeList.length == 0 && selectServiceLifeCycleList.length == 0 && selectIDClassificationList.length == 0) && row.id == r.serviceRing_Filter) {
                        IdentityAckDataFiltered.push(r);
                    }
                    else if (row == r.serviceRing_Filter && r.isAck == true && IsOldAckDeploymentData) {
                        tempCol.push(r);
                    }
                    else if (row == r.serviceRing_Filter && !IsOldAckDeploymentData) {
                        tempCol.push(r);
                    }
                });
            }
        });
        tempMasterCol = [];
        tempMasterCol = tempCol;
        tempCol = [];
    }


    if (isArrayWithLength(selectIDClassificationList)) {
        selectIDClassificationList.map((row) => {
            if (isArrayWithLength(tempMasterCol)) {
                tempMasterCol.map((r) => {
                    if ((selectServicesList.length == 0 && selectServiceManagerList == 0 && selectComponentList.length == 0 && selectServiceTypeList.length == 0 && selectServiceLifeCycleList.length == 0 && selectServiceRingList.length == 0) && row.id == r.identityServiceClassification_Filter) {
                        IdentityAckDataFiltered.push(r);
                    }
                    else if (row == r.identityServiceClassification_Filter && r.isAck == true && IsOldAckDeploymentData) {
                        tempCol.push(r);
                    }
                    else if (row == r.identityServiceClassification_Filter && !IsOldAckDeploymentData) {
                        tempCol.push(r);
                    }
                });
            }

        });
        tempMasterCol = [];
        tempMasterCol = tempCol;
        tempCol = [];
    }

    if (isArrayWithLength(selectComponentList)) {
        selectComponentList.map((row) => {
            if (isArrayWithLength(tempMasterCol)) {
                tempMasterCol.map((r) => {
                    if ((selectServicesList.length == 0 && selectServiceManagerList == 0 && selectServiceTypeList.length == 0 && selectServiceLifeCycleList.length == 0 && selectServiceRingList.length == 0) && row.id == r.componentName_Filter) {
                        IdentityAckDataFiltered.push(r);
                    }
                    else if (row == r.componentName_Filter && r.isAck == true && IsOldAckDeploymentData) {
                        tempCol.push(r);
                    }
                    else if (row == r.componentName_Filter && !IsOldAckDeploymentData) {
                        tempCol.push(r);
                    }
                });
            }

        });
        tempMasterCol = [];
        tempMasterCol = tempCol;
        tempCol = [];
    }

    if (isArrayWithLength(tempMasterCol)) {
        tempMasterCol.sort((a, b) => (a.geM_Filter < b.geM_Filter ? -1 : 1));
    }

    return tempMasterCol;
    //**************************** New Logic END ****************************************************************
};
const CheckEmailPOPup = function (headerdata) {
    let result = { Iscontact_DevOwner: false, Iscontact_PMOwner: false, IslastUpdatedBy:false};

    for (var i = 0; i < headerdata.length; i++) {
        if (headerdata[i].field == 'contact_DevOwner') {
            result.Iscontact_DevOwner = true;
        }
        if (headerdata[i].field == 'contact_PMOwner') {
            result.Iscontact_PMOwner = true;
        }
        if (headerdata[i].field == 'lastUpdatedBy') {
            result.IslastUpdatedBy = true;
        }
    }
    return result;
};
const checkAllData = (headerdata, EditObject, checkboxlist) =>{
    var isrequireddata = true;
    if (isArrayWithLength(headerdata)) {
        for (var i = 0; i < headerdata.length; i++) {
            //let val = headerdata[i].field.substr(0, 2).toLowerCase() + headerdata[i].field.substr(2);
            var val = headerdata[i].field;
            if (headerdata[i].controlType != '') {
                if (headerdata[i].isRequired == true) {
                    if(headerdata[i].controlType !== 'CheckBox') {
                        if (EditObject[val] == '' || EditObject[val] == undefined) {
                            isrequireddata = false;
                            i = headerdata.length;

                        }
                    }
                    else if (headerdata[i].controlType == 'CheckBox') {
                        if (showrequired(checkboxlist, val)) {
                            i = headerdata.length;
                            isrequireddata = false;
                        }
                    }
                }
            }
            if (isrequireddata == false) {
                return true;
            }
        }
    }
    return false;
};
const showrequired = function (checkboxlist,col) {
    let ischecked = 0;
    for (var i = 0; i < checkboxlist.length; i++) {
        if (checkboxlist[i]['ColName'] == col) {
            if (checkboxlist[i]['Value']) {
                ischecked = ischecked + 1;
            }
        }
    }
    //for (var i = 0; i < checkboxlist.length; i++) {
    //    Object.keys(checkboxlist[i]).map((key, j) => {
    //        if (checkboxlist[i][key]) {
    //            ischecked = ischecked + 1;
    //        }
    //    });
    //}
    if (ischecked === 0) {
        return true;
    }
    else {
        return false;
    }
};

const checkAckExists = function (id) {
    if (id == '1' || id == '2' || id == '3' || id == '6' || id == '7') {
        return true;
    }
    else {
        return false;
    }
};

