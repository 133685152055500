import React, { useEffect, useRef, useState } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { RouteComponentProps } from 'react-router-dom';
import { actionCreators, reducer } from '../../store/Checklist';
import { OnCallChecklistTable, OCEViewTable, ReadOnlyOCEViewTable } from '../Checklist/child-components';
import { Calendar } from 'primereact/calendar';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, Button } from 'react-bootstrap';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MultiSelect } from 'primereact/multiselect';
import { isArrayWithLength, renderToastifyMsg } from '../../utils';
import { ChecklistAPI } from '../../api';
import { ToastId, toast } from 'react-toastify';
import { NewFunctionalityBanner } from '../../components';

type OnCallChecklistProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly history: History }
    & { readonly isAdmin: boolean }
    & RouteComponentProps<{}>;

const OnCallChecklist: React.FC<OnCallChecklistProps> = ({
    isLoading,
    addUserVisit,
    isAuthenticated,
    setloadingtrue,
    setloadingfalse,
    checklistID,
    serviceId,
    allServicesdata,
    checklists,
    fromDate,
    toDate,
    mainIncidentIdFilter,
    mainIncidentIdFilterList,
    searchText,
    createdby,
    submittedbylist,
    CheckListchange,
    onfromDateChange,
    ontoDateChange,
    ChangedSearchText,
    GetOCEList,
    ClearFilter,
    GetChecklistDashboardData,
    IncidentIdchange,
    submittedbyChange,
    GetChecklistDashboardDate,
    GetcheckList,
    sortOCEList,
    sortColumn,
    sortOrder,
    oceList,
    urlParam,
    currDate,
    minDate,
    meetingTitle,
    meetingId,
    icmMainIncident,
    headerText,
    incidentFilterSeverityOptions,
    incidentFilterSeverityModel,
    UpdateActionItemOfTable,
    url,
    GetChecklistServicesforOCEView,
    GetChecklistSforOCEView,
    Load_Data_OCEView,
    allowDateChange,
    ChangedmeetingTitle,
    updateIcmMainIncident,
    OnQuestionsSave,
    isreadonly,
    incidents,
    OCEquestion,
    q_Index,
    showExpand,
    Expandall,
    ColapseIcons,
    ChangeIsExpandedCol,
    showIframeDataUrl,
    showIframeKustoQuery,
    showIncidents,
    IsQuestionChanged,
    chkchange,
    iFrameKustoObj,
    iFrameDataUrl,
    copyKustoLinkToClipboard,
    OpenDetailedQuery,
    actionDataTobeSaved,
    AddActionItem,
    clMeetingitems,
    RowSave,
    RowCancel,
    RowEdit,
    UpdateActionItemOfQuestion,
    filterIncidentsBySeverity,
    ChecklistIncidentsForOCE,
    incidentFilterFromDateChange,
    incidentFilterFromDate,
    incidentFilterToDate,
    incidentFilterToDateChange,
    filteredIncidents,
    isEditable,
    SaveIncidents,
    incidentFilter,
    clearIncidentspopupdata,
    URLOCE,
    fileuplaod,
    authorizeXhr,
    onSuccess,
    onFailure,
    DeleteFile,
    downloadFile,
    IsQuestionChanges,
    LoginuserId,
    actionItemTitle,
    qualitySpends,
    actionItemDescription,
    participants,
    meetingStatus,
    owner,
    ActionItemInputChanged,
    ActionItemInputChangedEditMode,
    fileuplaodValidation,
    isAdmin,
    templateType,
    onCallDate,
    onCallDateChange,
    TemplateTypechange,
    updatedBy,
    checklistDashboardCreatedBy,
    errorMsg,
    selectedServiceName,
    selectedChecklistName
}) => {
    useEffect(() => {
        if (isAuthenticated)
            addUserVisit(0, 'OnCallChecklist.tsx_v2');
    }, [isAuthenticated]);
    useEffect(() => {
        if (urlParam === 'UIforOCEView') {
            GetChecklistServicesforOCEView();
        }
        else if (urlParam === 'OCEList') {
            GetChecklistDashboardDate();
            GetChecklistDashboardData(4);
            GetOCEList();
        }
        else if (urlParam === 'UIforReadOnlyOCEView') {
            let newurl = window.location.origin + "/ChecklistView";
            window.history.pushState({ path: newurl }, '', newurl);
            GetChecklistServicesforOCEView();
        }
    }, []);
    const [showincidentspopup, setshowincidentspopup] = useState(false);
    const toastIdRef = useRef<ToastId>('');

    //=============== function to close OCE Incidents popup =========================//
    const closeIncidentsOCEViewPopup = () => {
        setshowincidentspopup(false);
        clearIncidentspopupdata();
    }

    const ClearFilterOCE = () => {
        ClearFilter();
        GetOCEList();
    }
    //=============== function to Open OCE Incidents popup =========================//
    const showIncidentsOCEViewModalPopup = (index) => {
        setshowincidentspopup(true);
        showIncidents(index);
    }

    //============ function to delete OnCall Prep Entry ==============================//
    const DeleteChecklist = (row) => {
        if (window.confirm("Are you sure you want to Delete the OnCall Checklist Entry?")) {
            setloadingtrue();
            ChecklistAPI.DeleteChecklistOnCallEntry(row.serviceId, row.meetingId, row.checkListId, LoginuserId)
                .then((result: number) => {
                    setloadingfalse();
                    if (result !== -1) {
                        toastIdRef.current = toast.success(
                            renderToastifyMsg("OnCall Checklist Entry Deleted successfully",
                                'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                        GetOCEList();
                    } else {
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Error while deleting the OnCall Checklist Entry",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );

                    }
                }).catch(error => {
                    setloadingfalse();
                    toastIdRef.current = toast.error(
                        renderToastifyMsg("Error while deleting",
                            'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                    );
                });
        }

    }

    return (
        <div>
            <NewFunctionalityBanner />
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        {(urlParam === 'UIforOCEView')
                            ?
                            <OCEViewTable
                                allowDateChange={allowDateChange}
                                allServicesdata={allServicesdata}
                                checklists={checklists}
                                fromDate={fromDate}
                                onfromDateChange={onfromDateChange}
                                meetingTitle={meetingTitle}
                                ChangedmeetingTitle={ChangedmeetingTitle}
                                icmMainIncident={icmMainIncident}
                                updateIcmMainIncident={updateIcmMainIncident}
                                OnQuestionsSave={OnQuestionsSave}
                                isreadonly={isreadonly}
                                url={url}
                                question={OCEquestion}
                                showExpand={showExpand}
                                Expandall={Expandall}
                                ColapseIcons={ColapseIcons}
                                ChangeIsExpandedCol={ChangeIsExpandedCol}
                                showIframeKustoQuery={showIframeKustoQuery}
                                showIframeDataUrl={showIframeDataUrl}
                                showIncidentsOCEViewModalPopup={showIncidentsOCEViewModalPopup}
                                UpdateActionItemOfTable={UpdateActionItemOfTable}
                                IsQuestionChanged={IsQuestionChanged}
                                minDate={minDate}
                                chkchange={chkchange}
                                iFrameDataUrl={iFrameDataUrl}
                                iFrameKustoObj={iFrameKustoObj}
                                copyKustoLinkToClipboard={copyKustoLinkToClipboard}
                                OpenDetailedQuery={OpenDetailedQuery}
                                actionDataTobeSaved={actionDataTobeSaved}
                                AddActionItem={AddActionItem}
                                clMeetingitems={clMeetingitems}
                                URLOCE={URLOCE}
                                UpdateActionItemOfQuestion={UpdateActionItemOfQuestion}
                                RowCancel={RowCancel}
                                RowEdit={RowEdit}
                                RowSave={RowSave}
                                fileuplaod={fileuplaod}
                                authorizeXhr={authorizeXhr}
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                                DeleteFile={DeleteFile}
                                downloadFile={downloadFile}
                                IsQuestionChanges={IsQuestionChanges}
                                LoginuserId={LoginuserId}
                                actionItemTitle={actionItemTitle}
                                qualitySpends={qualitySpends}
                                actionItemDescription={actionItemDescription}
                                participants={participants}
                                meetingStatus={meetingStatus}
                                owner={owner}
                                ActionItemInputChanged={ActionItemInputChanged}
                                ActionItemInputChangedEditMode={ActionItemInputChangedEditMode}
                                fileuplaodValidation={fileuplaodValidation}
                                templateType={templateType}
                                onCallDate={onCallDate}
                                onCallDateChange={onCallDateChange}
                                isAdmin={isAdmin}
                                checklistDashboardCreatedBy={checklistDashboardCreatedBy}
                                errorMsg={errorMsg}
                                selectedServiceName={selectedServiceName}
                                selectedChecklistName={selectedChecklistName}
                            />
                            : (urlParam === 'OCEList') ?
                                <OnCallChecklistTable
                                    serviceId={serviceId}
                                    checklistID={checklistID}
                                    allServicesdata={allServicesdata}
                                    checklists={checklists}
                                    templateType={templateType}
                                    TemplateTypechange={TemplateTypechange}
                                    fromDate={fromDate}
                                    toDate={toDate}
                                    mainIncidentIdFilter={mainIncidentIdFilter}
                                    mainIncidentIdFilterList={mainIncidentIdFilterList}
                                    searchText={searchText}
                                    createdby={createdby}
                                    submittedbylist={submittedbylist}
                                    GetcheckList={GetcheckList}
                                    CheckListchange={CheckListchange}
                                    onfromDateChange={onfromDateChange}
                                    ontoDateChange={ontoDateChange}
                                    ChangedSearchText={ChangedSearchText}
                                    GetOCEList={GetOCEList}
                                    ClearFilter={ClearFilterOCE}
                                    IncidentIdchange={IncidentIdchange}
                                    submittedbyChange={submittedbyChange}
                                    sortOCEList={sortOCEList}
                                    sortColumn={sortColumn}
                                    sortOrder={sortOrder}
                                    oceList={oceList}
                                    LoginuserId={LoginuserId}
                                    DeleteChecklist={DeleteChecklist}
                                    isAdmin={isAdmin}
                                />
                                : <ReadOnlyOCEViewTable
                                    allServicesdata={allServicesdata}
                                    checklists={checklists}
                                    fromDate={fromDate}
                                    meetingTitle={meetingTitle}
                                    icmMainIncident={icmMainIncident}
                                    url={url}
                                    question={OCEquestion}
                                    clMeetingitems={clMeetingitems}
                                    URLOCE={URLOCE}
                                    templateType={templateType}
                                    onCallDate={onCallDate}
                                    updatedBy={updatedBy}
                                    selectedServiceName={selectedServiceName}
                                    selectedChecklistName={selectedChecklistName}
                                />
                        }
                    </div>
                </section>
            </LoadingOverlay>

            <Modal show={showincidentspopup} onHide={closeIncidentsOCEViewPopup} size='lg' backdrop='static'>
                <Modal.Header>
                    <div style={{ width: '100%' }}>
                        <Row>
                            <Col lg='1'>
                                <span style={{ lineHeight: '2.5' }}><b>Incidents</b></span>
                            </Col>
                            <Col lg='11'>
                                <table className="OCEViewIncidenttable" style={{ width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'center', display: 'inline-flex' }}>
                                                <span style={{ fontWeight: 'bold', textAlign: 'center', width: '100px', paddingTop: '6px' }}>Severity : </span>
                                                <MultiSelect style={{ height: '35px' }} scrollHeight="300px" id={'incidentFilterSeverity'} value={incidentFilterSeverityModel} options={incidentFilterSeverityOptions} onChange={(e) => filterIncidentsBySeverity(e)} name="incidentFilterSeverity" />
                                            </td>
                                            <td style={{ display: 'inline-flex', verticalAlign: 'middle' }}>
                                                <span style={{ fontWeight: 'bold', textAlign: 'center', width: '60px', paddingTop: '6px' }}>From : </span>
                                                <Calendar readOnlyInput showIcon className='calendarstyle' value={incidentFilterFromDate} maxDate={currDate} onChange={(e) => incidentFilterFromDateChange(e.value)}></Calendar>
                                            </td>
                                            <td style={{ display: 'inline-flex', verticalAlign: 'middle' }}>
                                                <span style={{ fontWeight: 'bold', textAlign: 'center', width: '70px', paddingTop: '6px', paddingLeft: '20px' }}>To : </span>
                                                <Calendar readOnlyInput showIcon className='calendarstyle' value={incidentFilterToDate} maxDate={currDate} onChange={(e) => incidentFilterToDateChange(e.value)}></Calendar>
                                            </td>
                                            <td style={{ float: 'right', paddingTop: '5px' }}>
                                                <Button className='OncallChecklistOCEIncidentbutton' onClick={e => ChecklistIncidentsForOCE(serviceId)}>
                                                    <FontAwesomeIcon title='Filter' icon={'filter'} /> Filter
                                                    </Button>&emsp;
                                                <Button className='OncallChecklistOCEIncidentbutton' onClick={closeIncidentsOCEViewPopup}>Close</Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ width: '100%' }}>
                        <Row id="OCEIncidentsPopup">
                            <Col lg="12">
                                <table id="tblIncidentsOCE" className="tblHeatmapAdmin tblborder">
                                    <thead>
                                        <tr>
                                            <th className="textaligncenter"></th>
                                            <th className="textaligncenter">Incident Id</th>
                                            <th className="textaligncenter">Title</th>
                                            <th className="textaligncenter">Status</th>
                                            <th className="textaligncenter">Created On</th>
                                            <th className="textaligncenter">Severity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isArrayWithLength(filteredIncidents) ?
                                            <React.Fragment>
                                                {isArrayWithLength(filteredIncidents) ?
                                                    filteredIncidents
                                                        .sort((a, b) => ((a.createDate < b.createDate) || (a.title.toLowerCase() > b.title.toLowerCase()) ? 1 : -1))
                                                        .map((row, index) => (
                                                            <React.Fragment key={index}>
                                                                <tr >
                                                                    <td className="textaligncenter">
                                                                        <input type="checkbox" checked={row.selected || ''} disabled={!isEditable} onChange={(e) => SaveIncidents(e, index)} id="check-one" name="check-one" />
                                                                    </td>
                                                                    <td>
                                                                        <span>{row.incidentId}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{row.title}</span>
                                                                    </td>
                                                                    <td className="textaligncenter">
                                                                        <span>{row.status}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{row.createDate}</span>
                                                                    </td>
                                                                    <td className="textaligncenter">
                                                                        <span>{row.severity}</span>
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        ))
                                                    : <React.Fragment></React.Fragment>
                                                }
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                {
                                                    (filteredIncidents === null || filteredIncidents.length === 0 || filteredIncidents === undefined) ?
                                                        <tr>
                                                            <td colSpan={7}>
                                                                No Records Found
                                                         </td>
                                                        </tr>
                                                        : <React.Fragment></React.Fragment>
                                                }
                                            </React.Fragment>
                                        }
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};
const mapStateToProps = (state: IApplicationState) => ({
    ...state.Checklist,
    isAuthenticated: state.authUser.isAuthenticated,
    isAdmin: state.authUser.isAdmin
})

export default connect(mapStateToProps, actionCreators)(OnCallChecklist);
