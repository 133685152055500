import { actionCreators } from './actions';
import { FunctionReturnTypes, ReduxAction } from '../';
import { ActionType, IMSERPresentationviewState } from './types';

const initialState = Object.freeze<IMSERPresentationviewState>({
    isLoading: false,   
    MSERPresentationViews: [],
    currentMSERPresentationVIEW:null,   
    currentindex: 0,
    lastindex: 0,
    startDate: new Date(),
    endDate: new Date(),
    HeatmapPresentationData: [],
    PresentationuniqueDateHeaders: [],
    uniqueFixedHeaderColumns: [],
    datacentersList: [],
    additionalInfoData: [],
    Presentationviewdetailsdata: [],
    PresentationSelectedView: [],
    Presentationcomments: [],
    PresentationcolorScheme: [],
    IsdataLoaded: false,
    volumeMeasure: '',
    failurevolumeMeasure: '',
    showMSERComments: false,
    checkedFilterYellowDailyData: false,
    checkedFilterRedDailyData: false,
    IsPresentationviewsloaded: false,
    HeatMapOnboarded: [],
    HeatMapNotOnboarded: [],
    MSEROnboarded: [],
    MSERNotOnboarded: [],
    showdashboradpopup: false,
    dcMaxLength:0

});

export const reducer = (
    state: IMSERPresentationviewState = initialState,
    incomingAction: FunctionReturnTypes<typeof actionCreators>
) => {
    const action = incomingAction as ReduxAction;

    switch (action.type) {
        case ActionType.SET_LOADING:
            return {
                ...state,
                isLoading: true,
                IsPresentationviewsloaded:false,
                HeatmapPresentationData: [],
                PresentationcolorScheme: []

            };
        case ActionType.START_DATE_CHANGE:
            return {
                ...state,
                startDate: action.startDate
            };
        case ActionType.END_DATE_CHANGE:
            return {
                ...state,
                endDate: action.endDate
            };
        case ActionType.MSER_HEATMAPDATA_PRESENTATION_FETCH:
            return {
                ...state,
                isLoading: true,
                IsdataLoaded: false,
                HeatmapPresentationData: [],
                PresentationcolorScheme: []
            };
        case ActionType.MSER_HEATMAP_PRESENTATION_DATA_RECEIVE:
            let { dailyData, uniqueFixedHeaderColumns, uniqueDateHeaders, SelectedView, comments, colorScheme, datacentersList, additionalInfoData } = action;
            return {
                ...state,
                isLoading: false,
                IsdataLoaded: true,
                HeatmapPresentationData: dailyData,
                uniqueFixedHeaderColumns: uniqueFixedHeaderColumns,
                PresentationuniqueDateHeaders: uniqueDateHeaders,
                PresentationSelectedView: SelectedView,
                Presentationcomments: comments,
                PresentationcolorScheme: colorScheme,
                datacentersList: datacentersList,
                additionalInfoData: additionalInfoData,
                volumeMeasure: action.volumeMeasure,
                failurevolumeMeasure: action.failurevolumeMeasure,
                dcMaxLength: action.longestDC
            };
        case ActionType.GET_MSER_HEATMAPPRESENTATION_VIEWS_SUCCESS:
            return {
                ...state,
                MSERPresentationViews: action.MSERHeatmapViews, 
                currentindex: action.currentindex,
                lastindex: action.lastindex,
                currentMSERPresentationVIEW: action.currentMSERVIEW,
                startDate: action.fromDate,
                endDate: action.toDate,
                isLoading: (action.MSERHeatmapViews.length > 0) ? true : false,
                IsPresentationviewsloaded: true,
            };
        case ActionType.DAILY_FILTER_YELLOW:
            return {
                ...state,
                HeatmapPresentationData: action.dailyData,
                checkedFilterYellowDailyData: !!action.checked
            };
        case ActionType.DAILY_FILTER_RED:
            return {
                ...state,
                HeatmapPresentationData: action.dailyData,
                checkedFilterRedDailyData: !!action.checked
            };
        case ActionType.SET_MSERCOMMENTS:
            return {
                ...state,
                showMSERComments: action.showMSERComments
            };
        case ActionType.HEATMAP_ONBOARD_FETCH:
            return {
                ...state,
                HeatMapOnboarded: [],
                HeatMapNotOnboarded: [],
                isLoading: true,
            };
        case ActionType.HEATMAP_ONBOARD_SUCCESS:
            return {
                ...state,
                HeatMapOnboarded: action.HeatMapNotOnboarded,
                HeatMapNotOnboarded: action.HeatMapOnboarded,
                isLoading: false,
                showdashboradpopup:true
            };
        case ActionType.HEATMAP_MSER_ONBOARD_FETCH:
            return {
                ...state,
                MSEROnboarded: [],
                MSERNotOnboarded: [],
                
            };
        case ActionType.HEATMAP_MSER_ONBOARD_SUCCESS:
            return {
                ...state,
                MSEROnboarded: action.objMSEROnboarded,
                MSERNotOnboarded: action.objMSERNotOnboarded,
                isLoading: false
            };
        case ActionType.DASHBOARD_POPUP_CLOSE:
            return {
                ...state,
                showdashboradpopup:false,
              
            };
        case ActionType.RESET_STATE:
            return initialState;

        default:
            return state;
    }
};
