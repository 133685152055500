
export interface IActionType {
    readonly REQUEST: string;
    readonly RECEIVE: string;
    readonly RESET_STATE: string;
    readonly ADDED_VISITED_USER: string;
    readonly GET_ALL_DAILY_EMAIL_REQUEST: string;
    readonly GET_SERVICEGROUPS_SUCCESS: string;
    readonly GET_SERVICEGROUPS_FAIL: string;
    readonly GET_SERVICELISTS_SUCCESS: string;
    readonly GET_SERVICELISTS_FAIL: string;
    readonly SERVICEGROUP_CHANGE: string;
    readonly SERVICES_CHANGE: string;

    readonly GET_VIEW_DETAILS_SUCCESS: string;
    readonly GET_VIEW_DETAILS_FAIL: string;

    readonly GET_EMAIL_CONFIG_NAMES_SUCCESS: string;
    readonly GET_EMAIL_CONFIG_NAMES_FAIL: string;
    readonly GET_EMAIL_CONFIG_NAMES_REQUEST: string;

    readonly SET_LOADING_TRUE: string;
    readonly SET_LOADING_FALSE: string;
    readonly RESET_FILTER: string;

    readonly GET_EMAIL_CONFIG_DATASOURCE_REQUEST: string;
    readonly GET_EMAIL_CONFIG_DATASOURCE_SUCCESS: string;
    readonly GET_EMAIL_CONFIG_DATASOURCE_FAIL: string;
    readonly CLEAR_CONFIG_DATA_SECTION: string;

    readonly EMAIL_CONFIG_DATA_TYPE: string;


    readonly EMAIL_CONFIG_DATASOURCE_ONBOARD_CHANGE: string;
    readonly EMAIL_CONFIG_DATASOURCE_ONBOARD_PRI_CHANGE: string;
    readonly EMAIL_CONFIG_DATASOURCE_ONBOARD_CRI_CHANGE: string;
    readonly EMAIL_CONFIG_DATASOURCE_ONBOARD_DRI_CHANGE: string;
    readonly EMAIL_CONFIG_DATASOURCE_ONBOARD_SEV_CHANGE: string;

    readonly EMAIL_CONFIG_DATASOURCE_TO_EMAIL_CHANGE_UPDATE: string;
    readonly EMAIL_CONFIG_DATASOURCE_CC_EMAIL_CHANGE_UPDATE: string;
    
    readonly EMAIL_CONFIG_DATASOURCE_CHANGE_PRI: string;
    readonly EMAIL_CONFIG_DATASOURCE_SERVER_CHANGE_UPDATE_PRI: string;
    readonly EMAIL_CONFIG_DATASOURCE_DATABASE_CHANGE_UPDATE_PRI: string;
    readonly EMAIL_CONFIG_DATASOURCE_AUTHENTICATIONTYPE_CHANGE_UPDATE_PRI: string;
    readonly EMAIL_CONFIG_DATASOURCE_QUERYORURL_CHANGE_UPDATE_PRI: string;
    readonly EMAIL_CONFIG_DATASOURCE_COMMENTS_CHANGE_UPDATE_PRI: string;

    readonly EMAIL_CONFIG_DATASOURCE_CHANGE_CRI: string;
    readonly EMAIL_CONFIG_DATASOURCE_SERVER_CHANGE_UPDATE_CRI: string;
    readonly EMAIL_CONFIG_DATASOURCE_DATABASE_CHANGE_UPDATE_CRI: string;
    readonly EMAIL_CONFIG_DATASOURCE_AUTHENTICATIONTYPE_CHANGE_UPDATE_CRI: string;
    readonly EMAIL_CONFIG_DATASOURCE_QUERYORURL_CHANGE_UPDATE_CRI: string;
    readonly EMAIL_CONFIG_DATASOURCE_COMMENTS_CHANGE_UPDATE_CRI: string;

    readonly EMAIL_CONFIG_DATASOURCE_CHANGE_DRI: string;
    readonly EMAIL_CONFIG_DATASOURCE_SERVER_CHANGE_UPDATE_DRI: string;
    readonly EMAIL_CONFIG_DATASOURCE_DATABASE_CHANGE_UPDATE_DRI: string;
    readonly EMAIL_CONFIG_DATASOURCE_AUTHENTICATIONTYPE_CHANGE_UPDATE_DRI: string;
    readonly EMAIL_CONFIG_DATASOURCE_QUERYORURL_CHANGE_UPDATE_DRI: string;
    readonly EMAIL_CONFIG_DATASOURCE_COMMENTS_CHANGE_UPDATE_DRI: string;

    readonly EMAIL_CONFIG_DATASOURCE_CHANGE_SEV12: string;
    readonly EMAIL_CONFIG_DATASOURCE_SERVER_CHANGE_UPDATE_SEV12: string;
    readonly EMAIL_CONFIG_DATASOURCE_DATABASE_CHANGE_UPDATE_SEV12: string;
    readonly EMAIL_CONFIG_DATASOURCE_AUTHENTICATIONTYPE_CHANGE_UPDATE_SEV12: string;
    readonly EMAIL_CONFIG_DATASOURCE_QUERYORURL_CHANGE_UPDATE_SEV12: string;
    readonly EMAIL_CONFIG_DATASOURCE_COMMENTS_CHANGE_UPDATE_SEV12: string;

    readonly VALIDATE_PRI_DATASOURCE_REQUEST: string;
    readonly VALIDATE_PRI_DATASOURCE_SUCCESS: string;
    readonly VALIDATE_PRI_DATASOURCE_FAIL: string;

    readonly PRI_DATASOURCE_SAVE_SUCCESS: string;
    readonly PRI_DATASOURCE_SAVE_FAIL: string;

    readonly VALIDATE_DATASOURCE_REQUEST: string;
    readonly VALIDATE_DATASOURCE_SUCCESS: string;
    readonly VALIDATE_DATASOURCE_FAIL: string;

    readonly DATASOURCE_SAVE_SUCCESS: string;
    readonly DATASOURCE_SAVE_FAIL: string;



    readonly CLEAR_EMAIL_CONFIG_DATASOURCE_SECTION_HEATMAP: string;
    readonly ENABLE_EDIT_OR_ADD_EMAIL_CONFIG_DATASOURCE_HEATMAP: string;

    readonly CLEAR_EMAIL_CONFIG_DATASOURCE_SECTION_PRI: string;
    readonly ENABLE_EDIT_OR_ADD_EMAIL_CONFIG_DATASOURCE_PRI: string;

    readonly CLEAR_EMAIL_CONFIG_DATASOURCE_SECTION_CRI: string;
    readonly ENABLE_EDIT_OR_ADD_EMAIL_CONFIG_DATASOURCE_CRI: string;

    readonly CLEAR_EMAIL_CONFIG_DATASOURCE_SECTION_DRI: string;
    readonly ENABLE_EDIT_OR_ADD_EMAIL_CONFIG_DATASOURCE_DRI: string;

    readonly CLEAR_EMAIL_CONFIG_DATASOURCE_SECTION_SEV12: string;
    readonly ENABLE_EDIT_OR_ADD_EMAIL_CONFIG_DATASOURCE_SEV12: string;

    readonly GET_EMAIL_CONFIG_DATASOURCE_NAME_REQUEST: string;
    readonly GET_EMAIL_CONFIG_DATASOURCE_NAME_SUCCESS: string;
    readonly GET_EMAIL_CONFIG_DATASOURCE_NAME_FAIL: string;




    readonly SET_SELECTEDSERVICEID_EMPTY: string;
    readonly EMAIL_CONFIG_DATASOURCE_VIEW_ENABLE: string;
    readonly EMAIL_CONFIG_DATASOURCE_VIEW_DISABLE: string;
    readonly EMAIL_CONFIG_DATASOURCE_ADD_ENABLE: string;
    readonly EMAIL_CONFIG_DATASOURCE_ADD_DISABLE: string;
    readonly EMAIL_CONFIG_PRI_DATASOURCE_ADD_ENABLE: string;
    readonly EMAIL_CONFIG_PRI_DATASOURCE_ADD_DISABLE: string;
}

const _namespace = 'EmailConfiguration';

export type ConfigDropdownOption = {
    name: string;
    value: number;
};

export type IEmailConfigState = {
    readonly isLoading: boolean;
    readonly selectedServiceId: number;
    IsdataLoaded: boolean;
    readonly serviceGroups: [];
    readonly selectedServicegroupval: ConfigDropdownOption;
    readonly services: [];
    readonly selectedServiceval: ConfigDropdownOption; 
    readonly LoginuserId: string; 
    readonly selectedEmailConfigTypes: ConfigDropdownOption; 
    readonly emailConfigDataSourceDetails: [];
    readonly emailConfigDataSourceDetailsRaw: [];
    readonly viewSourceDetailsByService: [];
    readonly viewTargetDetailsByService: [];
    readonly emailConfigNames: [];
    onboardNewEmailConfigHeatmap: boolean;


    toEmailList: string;
    ccEmailList: string;

    readonly datasource: [];
    selectedDatasourcePRI: string;
    serverNamePRI: string;
    databasePRI: string;
    authenticationTypePRI: string;
    commentsPRI: string;
    queryOrUrlPRI: string;    
    onboardNewEmailConfigPRI: boolean;

    selectedDatasourceCRI: string;
    serverNameCRI: string;
    databaseCRI: string;
    authenticationTypeCRI: string;
    commentsCRI: string;
    queryOrUrlCRI: string;    
    onboardNewEmailConfigCRI: boolean;

    selectedDatasourceDRI: string;
    serverNameDRI: string;
    databaseDRI: string;
    authenticationTypeDRI: string;
    commentsDRI: string;
    queryOrUrlDRI: string;    
    onboardNewEmailConfigDRI: boolean;

    selectedDatasourceSEV12: string;
    serverNameSEV12: string;
    databaseSEV12: string;
    authenticationTypeSEV12: string;
    commentsSEV12: string;
    queryOrUrlSEV12: string;    
    onboardNewEmailConfigSEV: boolean;

    IsValidPRIQuery: boolean;
    IsPRIDataSaved: boolean;
    IsClearAll: boolean;

    IsQueryFailed: boolean;
    IsDataSaved: boolean;

    readonly ViewEmailConfigDataSourceOpen: boolean;
    readonly AddEmailConfigDataSourceOpen: boolean;
    readonly AddEmailConfigPRIDataSourceOpen: boolean;
};

export type IViewDetails = {
    viewId: string;
    viewName: string;
    isReachability: string;
}

export type PropEmailConfigDataSource = {
    emailConfigId: string;
    emailConfigName: string;
    emailConfigTitle: string;    
    serviceId: number;
    viewIds: string;
    dataSourceId: number;
    dataSourceName: string;
    fixedQuery: string;
    queryOrUrl: string;
    authenicationType: string;
    clusterName: string;
    dbName: string;
    comments: string;   
    updatedBy: string;
    isActive: boolean;
    isAutomated: boolean;
    toEmailList: string;
    ccEmailList: string;
};

export type PropDataSource = {
    emailConfigId: string;  
    serviceId: string;
    viewIds: string;
    dataSourceId: number;    
    fixedQuery: string;
    queryOrUrl: string;
    authenicationType: string;
    clusterName: string;
    dbName: string;
    comments: string;
    updatedBy: string;    
    isActive: boolean;
    isAutomated: boolean;
};

export type PropEmailConfigNames = {
    emailConfigId: string;
    emailConfigName: string;
    emailConfigTitle: string;
    description: string;
    isChecked: boolean;    
};

export type PropServiceDetail = {
    ServiceRingId: number;
    ServiceOwners_Email: string;
    ServiceGroupId: number;
    GroupName: string;
};

export type PropEmailConfigDataSourceName = {
    SourceName: string;
    Id: number;
};

export const ActionType = Object.freeze<IActionType>({
    REQUEST: `${_namespace}/fetch`,
    RECEIVE: `${_namespace}/receive`,
    RESET_STATE: `${_namespace}/resetState`,
    ADDED_VISITED_USER: `${_namespace}/addedVisitedUser`,
    GET_ALL_DAILY_EMAIL_REQUEST: `${_namespace}/getAllDailyEmailRequest`,
    GET_SERVICEGROUPS_SUCCESS: `${_namespace}/getservicegroupsuccess`,
    GET_SERVICEGROUPS_FAIL: `${_namespace}/getservicegroupfail`,
    GET_SERVICELISTS_SUCCESS: `${_namespace}/getservicelistsuccess`,
    GET_SERVICELISTS_FAIL: `${_namespace}/getservicefail`,
    SERVICEGROUP_CHANGE: '${_namespace}/servicegroupchange',
    SERVICES_CHANGE: '${_namespace}/serviceschange',

    GET_VIEW_DETAILS_SUCCESS: '${_namespace}/get_view_details_success',
    GET_VIEW_DETAILS_FAIL: '${_namespace}/get_view_details_fail',

    GET_EMAIL_CONFIG_NAMES_SUCCESS: '${_namespace}/get_email_config_names_success',
    GET_EMAIL_CONFIG_NAMES_FAIL: '${_namespace}/get_email_config_names_fail',
    GET_EMAIL_CONFIG_NAMES_REQUEST: '${_namespace}/get_email_config_names_request',

    SET_LOADING_TRUE: `${_namespace}/setloadingtrue`,
    SET_LOADING_FALSE: `${_namespace}/setloadingfalse`,
    RESET_FILTER: `${_namespace}/resetfilter`,

    GET_EMAIL_CONFIG_DATASOURCE_REQUEST: `${_namespace}/getemailconfigdatasource`,
    GET_EMAIL_CONFIG_DATASOURCE_SUCCESS: `${_namespace}/getemailconfigdatasourcesuccess`,
    GET_EMAIL_CONFIG_DATASOURCE_FAIL: `${_namespace}/getemailconfigdatasourcefail`,
    CLEAR_CONFIG_DATA_SECTION: `${_namespace}/clearconfigdatasection`,

    EMAIL_CONFIG_DATA_TYPE: `${_namespace}/emailconfigdatatype`,

    EMAIL_CONFIG_DATASOURCE_TO_EMAIL_CHANGE_UPDATE: `${_namespace}/email_config_datasource_to_email_change_update`,
    EMAIL_CONFIG_DATASOURCE_CC_EMAIL_CHANGE_UPDATE: `${_namespace}/email_config_datasource_cc_email_change_update`,

    EMAIL_CONFIG_DATASOURCE_CHANGE_PRI: `${_namespace}/email_config_datasource_change_pri`,
    EMAIL_CONFIG_DATASOURCE_SERVER_CHANGE_UPDATE_PRI: `${_namespace}/email_config_datasource_server_change_update_pri`,
    EMAIL_CONFIG_DATASOURCE_DATABASE_CHANGE_UPDATE_PRI: `${_namespace}/email_config_datasource_database_change_update_pri`,
    EMAIL_CONFIG_DATASOURCE_AUTHENTICATIONTYPE_CHANGE_UPDATE_PRI: `${_namespace}/email_config_datasource_authenticationtype_change_update_pri`,
    EMAIL_CONFIG_DATASOURCE_QUERYORURL_CHANGE_UPDATE_PRI: `${_namespace}/email_config_datasource_queryorurl_change_update_pri`,
    EMAIL_CONFIG_DATASOURCE_COMMENTS_CHANGE_UPDATE_PRI: `${_namespace}/email_config_datasource_comments_change_update_pri`,

    EMAIL_CONFIG_DATASOURCE_CHANGE_CRI: `${_namespace}/email_config_datasource_change_cri`,
    EMAIL_CONFIG_DATASOURCE_SERVER_CHANGE_UPDATE_CRI: `${_namespace}/email_config_datasource_server_change_update_cri`,
    EMAIL_CONFIG_DATASOURCE_DATABASE_CHANGE_UPDATE_CRI: `${_namespace}/email_config_datasource_database_change_update_cri`,
    EMAIL_CONFIG_DATASOURCE_AUTHENTICATIONTYPE_CHANGE_UPDATE_CRI: `${_namespace}/email_config_datasource_authenticationtype_change_update_cri`,
    EMAIL_CONFIG_DATASOURCE_QUERYORURL_CHANGE_UPDATE_CRI: `${_namespace}/email_config_datasource_queryorurl_change_update_cri`,
    EMAIL_CONFIG_DATASOURCE_COMMENTS_CHANGE_UPDATE_CRI: `${_namespace}/email_config_datasource_comments_change_update_cri`,

    EMAIL_CONFIG_DATASOURCE_CHANGE_DRI: `${_namespace}/email_config_datasource_change_dri`,
    EMAIL_CONFIG_DATASOURCE_SERVER_CHANGE_UPDATE_DRI: `${_namespace}/email_config_datasource_server_change_update_dri`,
    EMAIL_CONFIG_DATASOURCE_DATABASE_CHANGE_UPDATE_DRI: `${_namespace}/email_config_datasource_database_change_update_dri`,
    EMAIL_CONFIG_DATASOURCE_AUTHENTICATIONTYPE_CHANGE_UPDATE_DRI: `${_namespace}/email_config_datasource_authenticationtype_change_update_dri`,
    EMAIL_CONFIG_DATASOURCE_QUERYORURL_CHANGE_UPDATE_DRI: `${_namespace}/email_config_datasource_queryorurl_change_update_dri`,
    EMAIL_CONFIG_DATASOURCE_COMMENTS_CHANGE_UPDATE_DRI: `${_namespace}/email_config_datasource_comments_change_update_dri`,

    EMAIL_CONFIG_DATASOURCE_CHANGE_SEV12: `${_namespace}/email_config_datasource_change_sev12`,
    EMAIL_CONFIG_DATASOURCE_SERVER_CHANGE_UPDATE_SEV12: `${_namespace}/email_config_datasource_server_change_update_sev12`,
    EMAIL_CONFIG_DATASOURCE_DATABASE_CHANGE_UPDATE_SEV12: `${_namespace}/email_config_datasource_database_change_update_sev12`,
    EMAIL_CONFIG_DATASOURCE_AUTHENTICATIONTYPE_CHANGE_UPDATE_SEV12: `${_namespace}/email_config_datasource_authenticationtype_change_update_sev12`,
    EMAIL_CONFIG_DATASOURCE_QUERYORURL_CHANGE_UPDATE_SEV12: `${_namespace}/email_config_datasource_queryorurl_change_update_sev12`,
    EMAIL_CONFIG_DATASOURCE_COMMENTS_CHANGE_UPDATE_SEV12: `${_namespace}/email_config_datasource_comments_change_update_sev12`,

    VALIDATE_PRI_DATASOURCE_REQUEST: `${_namespace}/validate_pri_datasource_request`,
    VALIDATE_PRI_DATASOURCE_SUCCESS: `${_namespace}/validate_pri_datasource_success`,
    VALIDATE_PRI_DATASOURCE_FAIL: `${_namespace}/validate_pri_datasource_fail`,

    PRI_DATASOURCE_SAVE_SUCCESS: `${_namespace}/pri_datasource_save_success`,
    PRI_DATASOURCE_SAVE_FAIL: `${_namespace}/pri_datasource_save_fail`,

    VALIDATE_DATASOURCE_REQUEST: `${_namespace}/validate_datasource_request`,
    VALIDATE_DATASOURCE_SUCCESS: `${_namespace}/validate_datasource_success`,
    VALIDATE_DATASOURCE_FAIL: `${_namespace}/validate_datasource_fail`,

    DATASOURCE_SAVE_SUCCESS: `${_namespace}/datasource_save_success`,
    DATASOURCE_SAVE_FAIL: `${_namespace}/datasource_save_fail`,

    EMAIL_CONFIG_DATASOURCE_ONBOARD_CHANGE: `${_namespace}/email_config_datasource_onboard_change`,
    EMAIL_CONFIG_DATASOURCE_ONBOARD_PRI_CHANGE: `${_namespace}/email_config_datasource_onboard_PRI_change`,
    EMAIL_CONFIG_DATASOURCE_ONBOARD_CRI_CHANGE: `${_namespace}/email_config_datasource_onboard_CRI_change`,
    EMAIL_CONFIG_DATASOURCE_ONBOARD_DRI_CHANGE: `${_namespace}/email_config_datasource_onboard_DRI_change`,
    EMAIL_CONFIG_DATASOURCE_ONBOARD_SEV_CHANGE: `${_namespace}/email_config_datasource_onboard_SEV_change`,

    CLEAR_EMAIL_CONFIG_DATASOURCE_SECTION_HEATMAP: `${_namespace}/clear_email_config_datasource_section_heatmap`,
    ENABLE_EDIT_OR_ADD_EMAIL_CONFIG_DATASOURCE_HEATMAP: `${_namespace}/enable_edit_or_add_email_config_datasource_heatmap`,

    CLEAR_EMAIL_CONFIG_DATASOURCE_SECTION_PRI: `${_namespace}/clear_email_config_datasource_section_pri`,       
    ENABLE_EDIT_OR_ADD_EMAIL_CONFIG_DATASOURCE_PRI: `${_namespace}/enable_edit_or_add_email_config_datasource_pri`,

    CLEAR_EMAIL_CONFIG_DATASOURCE_SECTION_CRI: `${_namespace}/clear_email_config_datasource_section_cri`,
    ENABLE_EDIT_OR_ADD_EMAIL_CONFIG_DATASOURCE_CRI: `${_namespace}/enable_edit_or_add_email_config_datasource_cri`,

    CLEAR_EMAIL_CONFIG_DATASOURCE_SECTION_DRI: `${_namespace}/clear_email_config_datasource_section_dri`,
    ENABLE_EDIT_OR_ADD_EMAIL_CONFIG_DATASOURCE_DRI: `${_namespace}/enable_edit_or_add_email_config_datasource_dri`,

    CLEAR_EMAIL_CONFIG_DATASOURCE_SECTION_SEV12: `${_namespace}/clear_email_config_datasource_section_sev12`,
    ENABLE_EDIT_OR_ADD_EMAIL_CONFIG_DATASOURCE_SEV12: `${_namespace}/enable_edit_or_add_email_config_datasource_sev12`,
  
    SET_SELECTEDSERVICEID_EMPTY: `${_namespace}/set_selectedserviceid_empty`,

    EMAIL_CONFIG_DATASOURCE_VIEW_ENABLE: `${_namespace}/email_config_datasource_view_enable`,
    EMAIL_CONFIG_DATASOURCE_VIEW_DISABLE: `${_namespace}/email_config_datasource_view_disable`,

    EMAIL_CONFIG_DATASOURCE_ADD_ENABLE: `${_namespace}/email_config_datasource_add_enable`,
    EMAIL_CONFIG_DATASOURCE_ADD_DISABLE: `${_namespace}/email_config_datasource_add_disable`,

    EMAIL_CONFIG_PRI_DATASOURCE_ADD_ENABLE: `${_namespace}/email_config_pri_datasource_add_enable`,
    EMAIL_CONFIG_PRI_DATASOURCE_ADD_DISABLE: `${_namespace}/email_config_pri_datasource_add_disable`,

    GET_EMAIL_CONFIG_DATASOURCE_NAME_REQUEST: `${_namespace}/get_email_config_datasource_name_request`,
    GET_EMAIL_CONFIG_DATASOURCE_NAME_SUCCESS: `${_namespace}/get_email_config_datasource_name_success`,
    GET_EMAIL_CONFIG_DATASOURCE_NAME_FAIL: `${_namespace}/get_email_config_datasource_name_fail`
});