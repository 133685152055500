import {ApplicationInsights} from '@microsoft/applicationinsights-web';


let appInsightsInstance: ApplicationInsights | undefined = undefined;

async function fetchConnectionString(): Promise<string> {
    try {
        const response: Response = await fetch("/api/ApplicationInsightsConnString/GetAppInsightsConnectionString");
        if (!response.ok) {
            console.error("Failed to fetch connection string.");
            return "";
        }
        return await response.text();
    } catch (error) {
        console.error("Failed to fetch connection string: ", error);
        return "";
    }
}

const getAppInsightsInstance = async (): Promise<ApplicationInsights | null> => {
    const connectionString = await fetchConnectionString();
    // Check if the connection string was retrieved successfully.
    if (!connectionString) {
        // Logging to application insights will be disabled due to non-existent connection string.
        console.log("No connection string!")
        return null;
    }

    if (!appInsightsInstance) {
        appInsightsInstance = new ApplicationInsights({
            config: {
                connectionString: connectionString,
                enableAutoRouteTracking: true,
            }
        });

        appInsightsInstance.loadAppInsights();
    }

    return appInsightsInstance;
}

export { getAppInsightsInstance };