import { AdalConfig, AuthenticationContext, adalFetch, withAdalLogin, adalGetToken  } from 'react-adal';

export const adalConfig: AdalConfig = {

    tenant: '72f988bf-86f1-41af-91ab-2d7cd011db47',
    clientId: '9565d7e3-f810-428e-8670-394a2a044db1',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    cacheLocation: 'sessionStorage',
    endpoints: {
        api: ''
    },    
};

export const authContext = new AuthenticationContext({ ...adalConfig, cacheLocation: 'sessionStorage' });

//export const authContext = new AuthenticationContext(adalConfig);

export const adalDemoApiFetch = (fetch, url, options) =>
    adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const adalTokenFetch = () =>
    adalGetToken(authContext, adalConfig.endpoints.api);

export const getToken = () => authContext.getCachedToken(adalConfig.clientId);

export const authenticateUser = () => {
    if (authContext.isCallback(window.location.hash)) {
        authContext.handleWindowCallback();
        const err = authContext.getLoginError();
        if (err) {
            throw err;
        }
    }

    const cachedToken = authContext.getCachedToken(adalConfig.clientId);
    if (cachedToken) {
        const tokenData = convertToken(cachedToken);
        if (tokenData.exp < Date.now() / 1000) {
            console.warn('Request a new token...');
        }
    }

    const user = authContext.getCachedUser();

    if (user) {
        return user;
    } else {
        return false;
    }
};

export const adalApiPost = (resource: string, token: string, payload: string = '') => {
    return adalFetch(authContext, adalConfig.endpoints._apis, fetch, `${resource}`, {
        method: 'POST',
        body: payload,
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json-patch+json',
            Accept: 'application/json'
        }
    });
};

export const adalApiFetch = (resource: string, token: string, endpoint: string) =>
    adalFetch(authContext, endpoint, fetch, `${resource}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
    });

export const graphImageFetch = (resource: string, token: string, endpoint: string) =>
    adalFetch(authContext, endpoint, fetch, `${resource}`, {
        method: 'GET',
        headers: { 'Content-Type': 'image/jpg', Authorization: `Bearer ${token}` }
    });

export const convertToken = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const tokenData = JSON.parse(window.atob(base64));
    return tokenData;
};

export const withAdalLoginApi = () => withAdalLogin(authContext, adalConfig.endpoints.api);