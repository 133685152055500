import React, { useEffect, useRef, useState } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { RouteComponentProps } from 'react-router-dom';
import { actionCreators, reducer } from '../../store/IDAcktracking';
import { IDAcktrackingTable, AckTrackingUserGuide } from '../AckTrackingTemplate/child-components';
import LoadingOverlay from 'react-loading-overlay';
import { isArrayWithLength, renderToastifyMsg, isNullOrUndefined } from '../../utils';
import { ToastId, toast } from 'react-toastify';
import { AcktrackingAPI } from '../../api';
import * as FileSaver from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'primereact/dropdown';
import DatePicker, { getDay } from 'react-datepicker'
import moment from 'moment';
import {
    Row,
    Col, Card, CardText
} from 'reactstrap';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import { DeprecatedFunctionalityBanner } from '../../components';

type IDAcktrackingProps = ReturnType<typeof reducer>
    & typeof actionCreators
    & { readonly isAuthenticated: boolean }
    & { readonly username: string }
    & { readonly history: History }
    & { readonly isAdmin: boolean }
    & RouteComponentProps<{}>;

const IDAcktracking: React.FC<IDAcktrackingProps> = ({
    username,
    isLoading,
    addUserVisit,
    isAuthenticated,
    mainAcktrackingData,
    GetAcktrackingData,
    headerdata,
    IdentityAckData,
    ServiceManagerList,
    ServiceList,
    ComponentList,
    ServiceTypeList,
    ServiceManagervalue,
    Servicevalue,
    Componentvalue,
    ServiceTypevalue,
    ServiceManagerChange,
    ServicesChange,
    ComponentChange,
    ServiceTypeChange,
    GetDataforAlldropdowns,
    ServiceRinglist,
    ServiceRingvalue,
    ServiceLifeCyclelist,
    ServiceLifeCyclevalue,
    IDClassificationList,
    IDClassificationvalue,
    ServiceLifecyclechange,
    ServiceRingchange,
    IDClassificationchange,
    globalsearch,
    searchtextboxchange,
    notificationACKId,
    IDAckTypeList,
    IdAcktypeChange,
    AcktrackingFilterList,
    AcktrackingFiltervalue,
    IdAckFilterListChange,
    NewFiltervalue,
    NewFiltertextboxchange,
    clearallfilters,
    filterAckdata,
    getackfiltertypefilterdata,
    setloadingtrue,
    setloadingfalse,
    Iscontact_DevOwner,
    Iscontact_PMOwner,
    IslastUpdatedBy,
    DevOwnerEmailIDs,
    PMOwnerEmailIDs,
    UpdatedUserIDEmailIDs,
    Isopenemailpopup,
    handleemailpopupClose,
    OpenEmailPOPUP,
    urlparam,
    seturlparamempty,
    EditAckPopup,
    openacktrackingpopup,
    handleEditAcktrackingpopupClose,
    EditObject,
    editdropdownlist,
    acktrackingcontrolsedit,
    handleSelectedAckDateChange,
    EditObjectDate,
    checkboxlist,
    savebuttondisabled,
    counttofilterdatawhilepageload,
    title,
    IsServiceSpecificAck,
    IsFilterDisabled,
    isAdmin
}) => {
    const toastIdRef = useRef<ToastId>('');
    const [IsAckTrackingUserGuideSelected, setIsAckTrackingUserGuideSelected] = useState(false);
    useEffect(() => {
        if (isArrayWithLength(IdentityAckData)) {
            if (urlparam !== '') {
                IdAckFilterListChange(parseInt(urlparam));
                seturlparamempty();
            }
        }
    }, [IdentityAckData, urlparam]);
    useEffect(() => {
        if (isAuthenticated) {
            addUserVisit(0, 'IDAcktracking.tsx_v2');
            GetDataforAlldropdowns();
            if (urlparam !== '') {
                IdAckFilterListChange(parseInt(urlparam));
                seturlparamempty();
            }
            else {
                GetAcktrackingData(0, 0);
            }
        }
    }, [isAuthenticated]);
     useEffect(() => {
         if (counttofilterdatawhilepageload===8) {
             FilterAckData();
        }
     }, [counttofilterdatawhilepageload]);
    const FilterAckData = () => {
        filterAckdata();
    }
    const clearfilterdata = () => {

    }
    const GenerateFilterData = function () {
        let ManagerIDs = '';
        let ServiceTypes = '';
        let ServiceLifeCycle = '';
        let serviceName = '';
        let componentName = '';
        let ServiceRing = '';
        let IDClassification = '';
        let URL = '';
        if (isArrayWithLength(ServiceManagervalue)) {
            ServiceManagervalue.map((r) => {
                // Formating empty GEM Result
                ManagerIDs = (ManagerIDs == '') ? (r === '' ? "''" : r) : ManagerIDs + ',' + (r === '' ? "''" : r);
            })
        }
        if (isArrayWithLength(Servicevalue)) {
            Servicevalue.map((r) => {
                // Formating empty GEM Result
                serviceName = (serviceName == '') ? (r === '' ? "''" : r) : serviceName + ',' + (r === '' ? "''" : r);
            })
        }
        if (!IsServiceSpecificAck) {
            if (isArrayWithLength(Componentvalue)) {
                Componentvalue.map((r) => {
                    // Formating empty GEM Result
                    componentName = (componentName == '') ? (r === '' ? "''" : r) : componentName + ',' + (r === '' ? "''" : r);
                })
            }
        }
        if (isArrayWithLength(ServiceLifeCyclevalue)) {
            ServiceLifeCyclevalue.map((r) => {               
                ServiceLifeCycle = (ServiceLifeCycle === '') ? r : ServiceLifeCycle + ',' + r;
            })
        }
        if (isArrayWithLength(ServiceTypevalue)) {
            ServiceTypevalue.map((r) => {
                ServiceTypes = (ServiceTypes === '') ? r : ServiceTypes + ',' + r;
            })
        }
        if (isArrayWithLength(ServiceRingvalue)) {
            ServiceRingvalue.map((r) => {
                ServiceRing = (ServiceRing === '') ? (r === '' ? "''" : r) : ServiceRing + ',' + (r === '' ? "''" : r);
            })
        }
        if (isArrayWithLength(IDClassificationvalue)) {
            IDClassificationvalue.map((r) => {
                IDClassification = (IDClassification === '') ? (r === '' ? "''" : r) : IDClassification + ',' + (r === '' ? "''" : r);
            })
        }

      
        URL += "Mtype=" + encodeURIComponent(ManagerIDs);
        URL += "^Stype=" + encodeURIComponent(ServiceTypes);
        URL += "^ACKId=" + encodeURIComponent(notificationACKId);
        //if ($scope.selectServicesList.length <= 30) {
        URL += "^SName=" + encodeURIComponent(serviceName);      
        URL += "^Ltype=" + encodeURIComponent(ServiceLifeCycle);
        URL += "^Rtype=" + encodeURIComponent(ServiceRing);
        URL += "^IDtype=" + encodeURIComponent(IDClassification);
        URL += "^CName=" + encodeURIComponent(componentName);
        return URL;
       
    };
    const saveFilter = () => {
        let FilterID;
        if (NewFiltervalue==='') {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please Enter filter Name',
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
            );
        }
        else {
            let Message = (AcktrackingFiltervalue == "0") ? "Are you sure want to add Filter" : "Are you sure want to Update Filter";
            if (window.confirm(Message)) {
                let URL=GenerateFilterData();
                if (AcktrackingFiltervalue == undefined || AcktrackingFiltervalue == '0' || AcktrackingFiltervalue === '') {
                    FilterID = 0;
                }
                else {
                    FilterID = AcktrackingFiltervalue;
                }
                setloadingtrue();
                /*Added below due to the query string max limit so passing in request body*/
                const obj: any = [];
                obj.push({ 'URL': URL });

                AcktrackingAPI.SaveIdentityAckTrackingFilterData(obj[0],FilterID, NewFiltervalue, username)
                    .then((result: number) => {
                        if (result != -1) {
                            toastIdRef.current = toast.success(
                                renderToastifyMsg((AcktrackingFiltervalue == "0") ? "Filter saved successfully" : "Filter Updated successfully",
                                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                            );

                        } else {
                            toastIdRef.current = toast.error(
                                renderToastifyMsg('Error while saving the data',
                                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                            );
                           
                        }
                        getackfiltertypefilterdata(NewFiltervalue);
                    }).catch(error => {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg('Error while saving the data',
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
                        );
                    });
               
            }
        }

    }
    const IdAcktype_Change = (value) => {
        GetAcktrackingData(parseInt(value),1);
        IdAcktypeChange(value);
    }
    const IdAckFilterListChangeData = (value) => {
        if (parseInt(value) === 0) {
            clearallfilters();
        }
        else {
            IdAckFilterListChange(value);
        }
       
    }
    const exportAcktrackingReport = () => {
        let name = '';
        if (isArrayWithLength(IDAckTypeList)) {
            IDAckTypeList.map((obj) => {
                if (obj.value === notificationACKId) {
                    name = obj.name;
                }
            })

        }       
        if (isArrayWithLength(IdentityAckData)) {
            var tab_text = '<html xmlns:x="urn:schemas-microsoft-com:office:excel">';
            tab_text = tab_text + '<head><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>';
            tab_text = tab_text + '<x:Name>Sheet1</x:Name>';
            tab_text = tab_text + '<x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet>';
            tab_text = tab_text + '</x:ExcelWorksheets></x:ExcelWorkbook></xml></head><body>';
            tab_text = tab_text + "<table border='1px'>";
            var exportTable = document.body.querySelector('#tblAcktemplate').cloneNode(true);
            //exportTable.childNodes.forEach(function (node) { exportTable.removeChild(node); }, node => node.nodeName == 'input');
            var divElement = document.createElement('div');
            divElement.appendChild(exportTable);
            var table = divElement.innerHTML;
            tab_text = tab_text + table;
            //setTimeout(function () { }, 500);
            tab_text = tab_text + '</table></body></html>';
            let fileName = name + '.xls';

            // Save the file
            var blob = new Blob([tab_text], { type: "application/vnd.ms-excel;charset=utf-8" });

            FileSaver.saveAs(blob, fileName);
        }
        else {
            toastIdRef.current = toast.error(
                renderToastifyMsg('No Data Availble to download the Excel',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
            );
        }

    };
    const copyToClipBoard = (email) => {
        var textField = document.createElement('textarea');
        textField.innerText = email;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        if (!toast.isActive(toastIdRef.current)) {
            toastIdRef.current = toast.success(
                renderToastifyMsg('copied to clipboard',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
            );
        }

    }
    const CopyURL = () => {
        let url = window.location.href;
        let index = url.indexOf("?");
        if (index >= 0) {
            url = url.substring(0, index);
        }
        if (AcktrackingFiltervalue === undefined || AcktrackingFiltervalue === '0' || AcktrackingFiltervalue === 0) {
            toastIdRef.current = toast.error(
                renderToastifyMsg('Please select filter',
                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 1500 }
            );
        }
        else {
            url += "?ID=" + encodeURIComponent(AcktrackingFiltervalue);
            let copyText = prompt("Copy to clipboard: Ctrl+C >> Enter", url);
            if (copyText != null) {
                var textField = document.createElement('textarea');
                textField.innerText = url;
                document.body.appendChild(textField);
                textField.select();
                document.execCommand('copy');
                textField.remove();
            }
        }
    }
    const checkdata =  (val)=> {
        return val.substr(0, 2).toLowerCase() + val.substr(2);
    };
    
    const showrequired = (key) => {
        let ischecked = 0;
        for (var i = 0; i < checkboxlist.length; i++) {
            if (checkboxlist[i]['ColName'] == key) {
                if (checkboxlist[i]['Value']) {
                    ischecked = ischecked + 1;
                }
            }
        }       
        if (ischecked === 0) {
            return true;
        }
        else {
            return false;
        }
    };
    const escapeChar =(item)=> {
        if (item == '' || item == null) {
            return '';
        }
        else {
            return item.replace('<', '&lt;').replace('>', '&gt;').replace('&', '&amp;').replace('%', '&#37;').trim();
        }
    };

    //const replaceAll =  (s, r)=> {
    //    return this.replace(new RegExp(s, 'g'), r);
    //};

    const handleSelectedAckDate = (key, cnt, selectedDate) => {       
        let columnname = '';        
        if (isArrayWithLength(headerdata)) {
            headerdata.map((obj) => {
                if (obj.controlType == 'Date') {
                   // columnname = (obj.field.charAt(0,2).toLowerCase() + obj.field.substr(2));
                    columnname = obj.field;
                    if (key == columnname) {
                        EditObjectDate[cnt]['value'] = selectedDate;
                        EditObjectDate[cnt]['name'] = selectedDate;
                    }
                }
            })
        }
        handleSelectedAckDateChange(key);
    };


    const SaveIDAckTrackdata = () => {
        var obj = EditObject;
        if (obj.isChanged == 1) {
            var xml = '<Input>';
            var isrequireddata = true;
            var Datatosave = '';
            for (var i = 0; i < headerdata.length; i++) {
                //var val = headerdata[i].field.substr(0, 2).toLowerCase() + headerdata[i].field.substr(2)
                var val = headerdata[i].field;
                if (headerdata[i].controlType != '') {
                    if (headerdata[i].isRequired) {
                        if (EditObject[val] == '' || EditObject[val] == undefined) {
                            isrequireddata = false;
                            i = headerdata.length;
                        }
                    }
                }
              
                if (isrequireddata) {
                    if (headerdata[i].controlType === 'TextBox' || headerdata[i].controlType === 'Multiple Line Textbox') {
                       EditObject[val] = EditObject[val].replace(new RegExp("'", 'g'), "''")
                        //$scope.EditObject[val] = $scope.EditObject[val].replaceAll("'", "''");
                    }
                    xml = xml + '<Row Col=\"' + escapeChar(headerdata[i].field) + '\" Val= \"' +
                        escapeChar(EditObject[val]) + '\" />';
                }
               
            }
            if (!isrequireddata) {
                toastIdRef.current = toast.success(
                    renderToastifyMsg("Please enter data for required fields",
                        'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                );
            }
            else {
                xml = xml + '</Input>';
                let objAckData={
                    NotificationAckId: String(notificationACKId),
                    ServiceOid: String(EditObject['serviceOid']),
                    ComponentOid: String(EditObject['componentOid']),
                    ServiceType: EditObject['serviceType'],
                    ModifiedBy: username.split('@')[0],
                    InputXml: xml
                };
                setloadingtrue();
                AcktrackingAPI.SaveIdentityAckTrackingData(objAckData)
                    .then((result: number) => {
                        setloadingfalse();
                        if (result != -1) {
                            toastIdRef.current = toast.success(
                                renderToastifyMsg("Ack Data modified successfully",
                                    'check-circle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                            );

                        }
                        GetAcktrackingData(notificationACKId,1);
                        handleEditAcktrackingpopupClose();

                    }).catch(error => {
                        setloadingfalse();
                        toastIdRef.current = toast.error(
                            renderToastifyMsg("Error while save/update",
                                'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
                        );
                    });
            }
        }
        else {
            toastIdRef.current = toast.error(
                renderToastifyMsg("No changes observed",
                    'exclamation-triangle'), { position: toast.POSITION.TOP_RIGHT, autoClose: 2500 }
            );
           
        }
    };
    //==================== function to show and hide Ack tracking user Guide =====================//
    const showAckTrackingUserGuide = () => {
        setIsAckTrackingUserGuideSelected(true);
    }
    const hideAckTrackingUserGuide = () => {
        setIsAckTrackingUserGuideSelected(false);
    }

    return (
        <div>
            <DeprecatedFunctionalityBanner />
            <LoadingOverlay active={isLoading}
                spinner
                text='Loading your content...'
            >
                <section className='section'>
                    <div className='container'>
                        {
                            (IsAckTrackingUserGuideSelected)
                                ?
                                <AckTrackingUserGuide
                                    hideAckTrackingUserGuide={hideAckTrackingUserGuide} />
                                :
                            <IDAcktrackingTable
                                    headerdata={headerdata}
                                    IdentityAckData={IdentityAckData}
                                    ServiceManagerList={ServiceManagerList}
                                    ServiceList={ServiceList}
                                    ComponentList={ComponentList}
                                    ServiceTypeList={ServiceTypeList}
                                    ServiceManagervalue={ServiceManagervalue}
                                    Servicevalue={Servicevalue}
                                    Componentvalue={Componentvalue}
                                    ServiceTypevalue={ServiceTypevalue}
                                    ServiceManagerChange={ServiceManagerChange}
                                    ServicesChange={ServicesChange}
                                    ComponentChange={ComponentChange}
                                    ServiceTypeChange={ServiceTypeChange}
                                    ServiceRinglist={ServiceRinglist}
                                    ServiceRingvalue={ServiceRingvalue}
                                    ServiceLifeCyclelist={ServiceLifeCyclelist}
                                    ServiceLifeCyclevalue={ServiceLifeCyclevalue}
                                    IDClassificationList={IDClassificationList}
                                    IDClassificationvalue={IDClassificationvalue}
                                    ServiceLifecyclechange={ServiceLifecyclechange}
                                    ServiceRingchange={ServiceRingchange}
                                    IDClassificationchange={IDClassificationchange}
                                    FilterAckData={FilterAckData}
                                    clearfilterdata={clearfilterdata}
                                    globalsearch={globalsearch}
                                    searchtextboxchange={searchtextboxchange}
                                    notificationACKId={notificationACKId}
                                    IDAckTypeList={IDAckTypeList}
                                    IdAcktypeChange={IdAcktype_Change}
                                    AcktrackingFilterList={AcktrackingFilterList}
                                    AcktrackingFiltervalue={AcktrackingFiltervalue}
                                    IdAckFilterListChange={IdAckFilterListChangeData}
                                    NewFiltervalue={NewFiltervalue}
                                    NewFiltertextboxchange={NewFiltertextboxchange}
                                    saveFilter={saveFilter}
                                    clearallfilters={clearallfilters}
                                    exportAcktrackingReport={exportAcktrackingReport}
                                    Iscontact_DevOwner={Iscontact_DevOwner}
                                    Iscontact_PMOwner={Iscontact_PMOwner}
                                    IslastUpdatedBy={IslastUpdatedBy}
                                    OpenEmailPOPUP={OpenEmailPOPUP}
                                    CopyURL={CopyURL}
                                    openacktrackingpopup={openacktrackingpopup}
                                    title={title}
                                    showAckTrackingUserGuide={showAckTrackingUserGuide}
                                    IsServiceSpecificAck={IsServiceSpecificAck}
                                    IsFilterDisabled={IsFilterDisabled}
                                    isAdmin={isAdmin}
                            />
                        }
                    </div>
                </section>
                <Modal show={Isopenemailpopup} onHide={handleemailpopupClose} size='lg' backdrop='static'>
                    <Modal.Header closeButton className="commonModalHeader">
                        <div style={{ width: '100%' }}>
                            <Row>
                                <Col lg='12' style={{ textAlign: 'center' }}>
                                    <h4><b> Copy Email  </b></h4>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col lg='12'>
                                <table className="tblwidth100" id="TblSendEmails">   
                                    {
                                        (DevOwnerEmailIDs === "" && PMOwnerEmailIDs === "" && UpdatedUserIDEmailIDs === "")
                                            ? <tr style={{ paddingBottom: '40px' }}>
                                                <td style={{ color: 'red', fontSize: '18px' }}>No Email Address Found</td>
                                            </tr>
                                            : <React.Fragment></React.Fragment>
                                    }
                                    {
                                        (Iscontact_DevOwner)
                                            ? <tr style={{ paddingBottom: '40px' }}>
                                                <td className="tdwidth15">Dev Owner</td>
                                                <td className="tdwidth5">:</td>
                                                <td className="tdwidth80">

                                                    {DevOwnerEmailIDs} &nbsp;
                                                    <FontAwesomeIcon title="click to copy" onClick={e => copyToClipBoard(DevOwnerEmailIDs)} className="copyemailicons" icon={faCopy} />                                                    
                                                </td>
                                            </tr>
                                            : <React.Fragment></React.Fragment>
                                    }
                                    
                                    <tr className="tdheight20"></tr>
                                    {
                                        (Iscontact_PMOwner)
                                            ? <tr style={{ paddingBottom: '40px' }}>
                                                <td>PM Owner</td>
                                                <td>:</td>
                                                <td>{PMOwnerEmailIDs} &nbsp;
                                                    <FontAwesomeIcon title="click to copy" onClick={e => copyToClipBoard(PMOwnerEmailIDs)} className="copyemailicons" icon={faCopy} />
                                                </td>
                                            </tr>
                                            : <React.Fragment></React.Fragment>
                                    }
                                    <tr className="tdheight20"></tr>
                                 {
                                    (IslastUpdatedBy)
                                        ? <tr style={{ paddingBottom: '40px' }}>
                                            <td>Updated User</td>
                                            <td>:</td>
                                                <td>{UpdatedUserIDEmailIDs} &nbsp;
                                                    <FontAwesomeIcon title="click to copy" onClick={e => copyToClipBoard(UpdatedUserIDEmailIDs)} className="copyemailicons" icon={faCopy} />
                                                </td>
                                            </tr>
                                            : <React.Fragment></React.Fragment>
                                    }
                                  
                                   
                                    
                                </table>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <Modal show={EditAckPopup} onHide={handleEditAcktrackingpopupClose} size='lg' backdrop='static'>
                    <Modal.Header closeButton className="commonModalHeader">
                        <div style={{ width: '100%' }}>
                            <Row>
                                <Col lg='12' style={{ textAlign: 'center' }}>
                                    <h4><b> Update ID Ack Tracking  </b></h4>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col lg='12'>
                                <table style={{ width: '80 %' }} className="table table-bordered">
                                    <tbody>
                                        {
                                            !isNullOrUndefined(EditObject) && Object.keys(EditObject).map((key, i) => (
                                                <React.Fragment>
                                                    {
                                                        (key.indexOf('service_Manager') != -1)
                                                            ?
                                                            <tr>
                                                                <th className="tdwidth30">GEM</th>
                                                                <td className="tdwidth50">
                                                                    <span>
                                                                        { EditObject[key]}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            : (key.indexOf('serviceName') != -1)
                                                                ?
                                                                <tr>
                                                                    <th className="tdwidth30">Service Name</th>
                                                                    <td className="tdwidth50">
                                                                        <span>
                                                                            {EditObject[key]}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                : (key.indexOf('componentName') != -1)
                                                                    ?
                                                                    <tr>
                                                                        <th className="tdwidth30">Component Name</th>
                                                                        <td className="tdwidth50">
                                                                            <span>
                                                                                {EditObject[key]}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    : (!(key.indexOf('notificationAckId') != -1 || key.indexOf('serviceOid') != -1
                                                                        || key.indexOf('componentOid') != -1 || key.indexOf('title') != -1
                                                                        || key.indexOf('isChanged') != -1 || key.indexOf('isEdit') != -1
                                                                        || key.indexOf('service_Manager') != -1 || key.indexOf('serviceName') != -1
                                                                        || key.indexOf('serviceType') != -1 || key.indexOf('contact_DevOwner') != -1
                                                                        || key.indexOf('contact_PMOwner') != -1 || key.indexOf('deploymentInfrastructure') != -1
                                                                        || key.indexOf('serviceLifeCycle') != -1 || key.indexOf('serviceRing') != -1
                                                                        || key.indexOf('identityServiceClassification') != -1))
                                                                    ?
                                                                    <tr>
                                                                        <th className="tdwidth30">{key}</th>
                                                                        <td className="tdwidth50">
                                                                            {
                                                                                isArrayWithLength(headerdata) && headerdata.map((val, subkey) => (
                                                                                    < React.Fragment >
                                                                                        {
                                                                                            /*(checkdata(val.field) == key)*/
                                                                                             (val.field == key)
                                                                                                ?
                                                                                                <span>
                                                                                                    {
                                                                                                        (val.controlType == 'TextBox')
                                                                                                            ? <input type="text" className='ackInputStyle' size={600} value={EditObject[key]} name={key} onChange={e => acktrackingcontrolsedit(e, 'text')}/>
                                                                                                            : (val.controlType == 'Date')
                                                                                                                ? < React.Fragment >
                                                                                                                    {isArrayWithLength(EditObjectDate) && EditObjectDate.map((dtColKey, dtCntr) => (
                                                                                                                        <React.Fragment>
                                                                                                                            {  
                                                                                                                                (EditObjectDate[dtCntr]['ColName'] == key)
                                                                                                                                    ? < React.Fragment>                                                                                                                                       
                                                                                                                                        <DatePicker className='ackInputStyle'
                                                                                                                                            selected={EditObjectDate[dtCntr]['value']}
                                                                                                                                            
                                                                                                                                            onChange={dt => handleSelectedAckDate(EditObjectDate[dtCntr]['ColName'], dtCntr, dt)}
                                                                                                                                 
                                                                                                                                                                                                                                                                                        
                                                                                                                                            calendarIcon={true}
                                                                                                                                            calendarAriaLabel={true}
                                                                                                                                            name={key}  
                                                                                                                                            popperPlacement="bottom-end"
                                                                                                                                            popperModifiers={{
                                                                                                                                                flip: {
                                                                                                                                                    enabled: false
                                                                                                                                                },
                                                                                                                                                offset: {
                                                                                                                                                    enabled: true,
                                                                                                                                                    offset: "55px, 5px"
                                                                                                                                                },
                                                                                                                                                preventOverflow: {
                                                                                                                                                    enabled: true,
                                                                                                                                                    escapeWithReference: false
                                                                                                                                                }
                                                                                                                                            }}
                                                                                                                                        />
                                                                                                                                       </React.Fragment>
                                                                                                                                    : < React.Fragment>
                                                                                                                                      </React.Fragment>                                                                                                                    
                                                                                                                            }
                                                                                                                        </React.Fragment>
                                                                                                                    ))}
                                                                                                                </React.Fragment>                                                                                                                
                                                                                                                : (val.controlType == 'Multiple Line Textbox')
                                                                                                                    ? <textarea rows={4} cols={100} value={EditObject[key]} name={key} onChange={e => acktrackingcontrolsedit(e,'text')}/>
                                                                                                                    : (val.controlType == 'Dropdown')
                                                                                                                        ? <Dropdown id={'ddlidackdropdowntype'} value={EditObject[key].trim()} options={editdropdownlist.filter(x=> x.ColName == key)} optionLabel='name' name={key} onChange={e => acktrackingcontrolsedit(e, 'text')} />
                                                                                                                       
                                                                                                                        : (val.controlType == 'CheckBox')
                                                                                                                            ? < React.Fragment >
                                                                                                                                {isArrayWithLength(checkboxlist) && checkboxlist.map((colKey, cntr) => (
                                                                                                                                    //{isArrayWithLength(val.controlValue.split(',')) && val.controlValue.split(',').map((obj, checkkey) => ( 
                                                                                                                                    
                                                                                                                                     <React.Fragment>
                                                                                                                                        {                                                                                              
                                                                                                                                            (checkboxlist[cntr]['ColName'] == key )
                                                                                                                                                ? < React.Fragment>
                                                                                                                                                    <input type="checkbox" name={key} value={checkboxlist[cntr]['Key']} checked={checkboxlist[cntr]['Value']} onChange={e => acktrackingcontrolsedit(e, 'checkbox')} /> {checkboxlist[cntr]['Key']} &nbsp;
                                                                                                                                                </React.Fragment>
                                                                                                                                                : < React.Fragment>

                                                                                                                                                </React.Fragment>
                                                                                                                                        }
                                                                                                                                    </React.Fragment>


                                                                                                                                    
                                                                                                                                    //))}
                                                                                                                                ))}
                                                                                                                            </React.Fragment>
                                                                                                                            : (val.controlType == 'Radio Button')
                                                                                                                                ? < React.Fragment >

                                                                                                                                    {isArrayWithLength(val.controlValue.split(',')) && val.controlValue.split(',').map((obj, radiokey) => (
                                                                                                                                        < React.Fragment >
                                                                                                                                           
                                                                                                                                            <input type="radio" checked={EditObject[key].trim() === obj.trim()} name={key} onChange={e => acktrackingcontrolsedit(e, 'radio')} value={obj}/> { obj} &nbsp;
                                                                                                                                        </React.Fragment>
                                                                                                                                    ))}

                                                                                                                                </React.Fragment>
                                                                                                                                :< React.Fragment > </React.Fragment>
                                                                                                    }
                                                                                                    {
                                                                                                        (val.controlType !== 'CheckBox')
                                                                                                            ? <React.Fragment>
                                                                                                                {
                                                                                                                    (val.isRequired === true && (EditObject[key] === '' || EditObject[key] === undefined))
                                                                                                                        ? < React.Fragment>
                                                                                                                            &nbsp;&nbsp;<span className="errotextrequiredstyle">Required</span>
                                                                                                                        </React.Fragment>
                                                                                                                        : < React.Fragment> </React.Fragment>
                                                                                                                }
                                                                                                            </React.Fragment>
                                                                                                               : (val.controlType === 'CheckBox')
                                                                                                                    ? < React.Fragment>
                                                                                                                        {
                                                                                                                            (val.isRequired === true && (showrequired(key)))
                                                                                                                                ? < React.Fragment>
                                                                                                                                    &nbsp;&nbsp;<span className="errotextrequiredstyle">Required</span>
                                                                                                                                </React.Fragment>
                                                                                                                                : < React.Fragment> </React.Fragment>
                                                                                                                        }
                                                                                                                    </React.Fragment>
                                                                                                                    : < React.Fragment>
                                                                                                                    </React.Fragment>
                                                                                                       
                                                                                                    }
                                                                                                </span>
                                                                                                : < React.Fragment> </React.Fragment>
                                                                                        }
                                                                                        
                                                                                    </React.Fragment>
                                                                                 ))
                                                                            }
                                                                            
                                                                        </td>
                                                                    </tr>
                                                                    :< React.Fragment> </React.Fragment>
                                                    }
                                                </React.Fragment>
                                                ))
                                        }
                                     </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <div style={{ width: '100%' }}>
                            <Row>
                                <Col lg='9'></Col>
                                
                                <Col lg='3'>
                                    <Button className='DailydataModelbuttons' disabled={savebuttondisabled} onClick={SaveIDAckTrackdata}>Save</Button>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Footer>
                </Modal>
            </LoadingOverlay>

        </div>
    );

};
const mapStateToProps = (state: IApplicationState) => ({
    ...state.IDAcktracking,
    isAuthenticated: state.authUser.isAuthenticated,
    username: state.authUser.login,
    isAdmin: state.authUser.isAdmin
})

export default connect(mapStateToProps, actionCreators)(IDAcktracking);
